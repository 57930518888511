// IMPORTS
import { Dispatch, SetStateAction, useContext } from 'react';
import {
    Checkbox,
    Grid,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../global/Paper';
import VehicleCharges from './FinaliseComponents/VehicleCharges';
import GovernmentCharges from './FinaliseComponents/GovernmentCharges';
import PurchaseDetails from './FinaliseComponents/PurchaseDetails';
import VehicleDetails from './FinaliseComponents/VehicleDetails';
import TotalsForm from './TotalsForm';
import RoleCheck from '../../../global/RoleCheck';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';
import {
    TradeIn,
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { SiteContext } from '../../../../main/Main';
import { Site } from '../../../global/interfaces/GeneralInterface';

interface FinaliseProps {
    readOnly: boolean;
    setIsFinalised: Dispatch<SetStateAction<boolean>>;
    saleDetails: VehicleSale;
    setSaleDetails: Dispatch<SetStateAction<VehicleSale>>;
    oldSale: VehicleSale;
    selectedVehicle: Vehicle;
    addedCostsTotal: any;
    linkedSales: Order[];
    linkedVouchers: any[];
    linkedTrades: TradeIn[];
    setLinkedTrades: Dispatch<SetStateAction<TradeIn[]>>;
    isFinalised: boolean;
    gstType: string;
}
const Finalise = ({
    gstType,
    readOnly,
    setIsFinalised,
    saleDetails,
    setSaleDetails,
    oldSale,
    selectedVehicle,
    linkedSales,
    linkedVouchers,
    linkedTrades,
    addedCostsTotal,
    isFinalised
}: FinaliseProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const handleStatusChange = (status: string) => {
        if (status != null) {
            if (status === 'Finalised' && !saleDetails.finalisedDate) {
                setSaleDetails({
                    ...saleDetails,
                    saleStatus: status,
                    finalisedDate: String(new Date())
                });
            } else if (status === 'Finalised') {
                setSaleDetails({ ...saleDetails, saleStatus: status });
            } else {
                setIsFinalised(false);
                setSaleDetails({ ...saleDetails, saleStatus: status });
            }
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    fullWidth
                                    disabled={readOnly}
                                    value={saleDetails.saleStatus}
                                    onChange={(e, newType) =>
                                        handleStatusChange(newType)
                                    }
                                    size="small"
                                >
                                    <ToggleButton
                                        value="Quote"
                                        disabled={
                                            oldSale.saleStatus === 'Sale' ||
                                            oldSale.saleStatus === 'Finalised'
                                        }
                                    >
                                        Quote
                                    </ToggleButton>
                                    <ToggleButton value="Sale">
                                        Sale
                                    </ToggleButton>
                                    {saleDetails.manufacturerOrder ? (
                                        <Tooltip
                                            title="Cannot finalise sale for a manufacturer order. You must select a vehicle currently in stock."
                                            placement="top"
                                        >
                                            <ToggleButton value={null}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: 'rgb(100,100,100)',
                                                        fontSize: 14
                                                    }}
                                                >
                                                    Finalised
                                                </Typography>
                                            </ToggleButton>
                                        </Tooltip>
                                    ) : (
                                        <ToggleButton value="Finalised">
                                            Finalised
                                        </ToggleButton>
                                    )}
                                    <ToggleButton value="Lost">
                                        Lost
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                {saleDetails.saleStatus === 'Lost' ? (
                                    <Grid item xs={12}>
                                        <br />
                                        <TextField
                                            fullWidth
                                            disabled={readOnly || isFinalised}
                                            InputLabelProps={{ shrink: true }}
                                            multiline
                                            rows={3}
                                            label="Reason Lost"
                                            value={saleDetails.reasonLost}
                                            onChange={(e) =>
                                                setSaleDetails({
                                                    ...saleDetails,
                                                    reasonLost: e.target.value
                                                })
                                            }
                                        />
                                    </Grid>
                                ) : null}
                            </Paper>
                        </Grid>
                        {!selectedVehicle?.id ? (
                            <Grid item xs={12}>
                                <Paper>
                                    <Typography
                                        variant="body1"
                                        textAlign="center"
                                    >
                                        Please select a vehicle to continue with
                                        the contract details.
                                    </Typography>
                                </Paper>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={6}>
                                    <Paper>
                                        <VehicleDetails
                                            selectedVehicle={selectedVehicle}
                                            addedCostsTotal={addedCostsTotal}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper>
                                        <Typography variant="h6">
                                            Vehicle Charges
                                        </Typography>
                                        <br />
                                        <VehicleCharges
                                            readOnly={readOnly || isFinalised}
                                            saleDetails={saleDetails}
                                            setSaleDetails={setSaleDetails}
                                        />
                                    </Paper>
                                </Grid>
                                {siteDetails.vehicleTypeSold ===
                                'Motorcycles' ? (
                                    <Grid item xs={12}>
                                        <Paper>
                                            <Typography variant="h6">
                                                <Checkbox
                                                    disabled={
                                                        readOnly || isFinalised
                                                    }
                                                    checked={
                                                        saleDetails.includesOnRoadCosts
                                                    }
                                                    onChange={(e) =>
                                                        setSaleDetails({
                                                            ...saleDetails,
                                                            includesOnRoadCosts:
                                                                e.target.checked
                                                        })
                                                    }
                                                    size="small"
                                                    sx={{
                                                        paddingTop: 0,
                                                        paddingBottom: 0
                                                    }}
                                                />
                                                Government Charges
                                            </Typography>
                                            <br />
                                            {saleDetails.includesOnRoadCosts ? (
                                                <GovernmentCharges
                                                    readOnly={
                                                        readOnly || isFinalised
                                                    }
                                                    saleDetails={saleDetails}
                                                    setSaleDetails={
                                                        setSaleDetails
                                                    }
                                                    selectedVehicle={
                                                        selectedVehicle
                                                    }
                                                />
                                            ) : (
                                                <Typography variant="body1">
                                                    Government charges not
                                                    applicable.
                                                </Typography>
                                            )}
                                        </Paper>
                                    </Grid>
                                ) : null}

                                <Grid item xs={12}>
                                    <Paper>
                                        <Typography variant="h6">
                                            Purchase Details
                                        </Typography>
                                        <br />
                                        <PurchaseDetails
                                            readOnly={readOnly || isFinalised}
                                            saleDetails={saleDetails}
                                            setSaleDetails={setSaleDetails}
                                        />
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    {saleDetails.saleStatus === 'Finalised' ? (
                        <>
                            <Paper>
                                <RoleCheck
                                    permission="edit_finaliseDateVehicleSale"
                                    component={
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="en-gb"
                                        >
                                            <DatePicker
                                                disabled={readOnly}
                                                format="DD/MM/YYYY"
                                                label="Finalised Date"
                                                value={dayjs(
                                                    saleDetails?.finalisedDate
                                                )}
                                                onChange={(newValue) =>
                                                    setSaleDetails({
                                                        ...saleDetails,
                                                        finalisedDate:
                                                            dayjs(
                                                                newValue
                                                            ).toISOString()
                                                    })
                                                }
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: false,
                                                        size: 'small',
                                                        InputLabelProps: {
                                                            shrink: true
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    }
                                    nullComponent={
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="en-gb"
                                        >
                                            <DatePicker
                                                disabled
                                                format="DD/MM/YYYY"
                                                label="Finalised Date"
                                                value={dayjs(
                                                    saleDetails?.finalisedDate
                                                )}
                                                onChange={(newValue) =>
                                                    setSaleDetails({
                                                        ...saleDetails,
                                                        finalisedDate:
                                                            dayjs(
                                                                newValue
                                                            ).toISOString()
                                                    })
                                                }
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: false,
                                                        size: 'small',
                                                        InputLabelProps: {
                                                            shrink: true
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    }
                                />
                            </Paper>
                            <br />
                        </>
                    ) : null}
                    <Paper>
                        <TotalsForm
                            saleDetails={saleDetails}
                            linkedSales={linkedSales}
                            linkedVouchers={linkedVouchers}
                            linkedTrades={linkedTrades}
                            gstType={gstType}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default Finalise;
