// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Customer } from '../interfaces/GeneralInterface';

const GetAllCustomers = (
    setCustomers: Dispatch<SetStateAction<Customer[]>>,
    isDebtor?: boolean
) => {
    let query = '';
    if (isDebtor === true) {
        query = 'isDebtor=true';
    } else if (isDebtor === false) {
        query = 'isDebtor=false';
    }

    api.get(`allCustomers?${query}`).then((res) => {
        setCustomers(res.data);
    });
};

export default GetAllCustomers;
