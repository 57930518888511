import { Step } from '../../interface';

export const RegoConciliatorSteps: Step[] = [
    {
        label: 'In the sidebar, navigate to Admin -> Reconciliators -> Rego Reconciliation to open the Rego Reconciliation page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/regoReconciliator/rego-recon-01.png'
    },
    {
        label: 'The Rego Reconciliation page features two tables: Reconciled Rego and Unreconciled Rego. The Reconciled table displays regos ready for reconciliation with matching admin and sale amounts. The Unreconciled table lists regos with differing amounts, not yet ready for reconciliation.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/regoReconciliator/rego-recon-02.png'
    },
    {
        label: 'To move a rego from the Reconciled to the Unreconciled table, click the down arrow icon on the right side of the row.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/regoReconciliator/rego-recon-03.png'
    },
    {
        label: 'To move a rego from the Unreconciled Rego table to the Reconciled Rego table, click the up arrow icon on the right side of the row. This action checks if the rego is ready to be reconciled (Sale Amount and Admin Amount must match).',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/regoReconciliator/rego-recon-04.png'
    },
    {
        label: 'If the admin amount and sale amount are not equal, the rego cannot be moved to the Reconciled Rego table. If equal, the rego will be moved to the Reconciled Rego table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/regoReconciliator/rego-recon-05.png'
    },
    {
        label: (
            <>
                If the amounts are not equal but you still wish to reconcile the
                rego, edit either the linked vehicle sale or the vehicle
                registration invoice. Refer to{' '}
                <a
                    href="/support/admin/enterInvoice/#enter-registration-invoice"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Edit Vehicle Registration Invoice
                </a>{' '}
                and
                <a
                    href="/support/vehicles/newContract/#add-vehicle-sale-contract"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Add New Vehicle Sale Contract
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/regoReconciliator/rego-recon-06.png'
    },
    {
        label: 'Click Save to submit the reconciled regos to the system. The regos will be marked as reconciled and will no longer appear in the Reconciled Rego table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/regoReconciliator/rego-recon-07.png'
    }
];
