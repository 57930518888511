// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';

interface Params {
  specific: string;
  site?: number;
  setSpecificList: Dispatch<SetStateAction<any>>;
}

const GetStockDetails = (params: Params) => {
  api
    .get(
      `specifics/${params.specific}${params.site ? '?siteId=' + params.site : ''}`
    )
    .then((res) => {
      params.setSpecificList(res.data);
    });
};

export default GetStockDetails;
