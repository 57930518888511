// IMPORTS
import { TableRow, Typography, Divider, Chip } from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import {
    DateFormatter,
    CurrencyFormatter
} from '../../../../global/logic/Formatters';
import CalculatePartTotal from '../../logic/CalculatePartTotal';
import CalculateMiscTotal from '../../logic/CalculateMiscTotal';
// INTERFACES
import { Service } from '../../../../global/interfaces/ServiceInterface';

interface ServicesProps {
    services: Service[];
}

const Services = ({ services }: ServicesProps) => {
    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Status' },
        { id: 2, label: 'Date' },
        { id: 3, label: 'Labour' },
        { id: 4, label: 'Parts' },
        { id: 5, label: 'Misc' },
        { id: 6, label: 'Row Total' },
        { id: 7, label: 'Location' }
    ];

    return (
        <Paper>
            <Typography variant="h5" sx={{ fontSize: '25px' }}>
                Services
            </Typography>
            <br />
            <Divider />
            {!services || services.length === 0 ? (
                <Typography variant="body1">
                    <br />
                    No services have been allocated to this vehicle.
                </Typography>
            ) : (
                <DataTable columns={columns}>
                    {services.map((service) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/service/viewBooking/${service.id}`
                                    )
                                }
                            >
                                {service.id}
                            </DataCellColoured>
                            <DataCell>
                                <Chip
                                    variant="outlined"
                                    label={service?.serviceComplete}
                                    color={
                                        service?.serviceComplete === 'Finalised'
                                            ? 'success'
                                            : service?.serviceComplete ===
                                                'Complete'
                                              ? 'warning'
                                              : 'error'
                                    }
                                />
                            </DataCell>
                            <DataCell>
                                {service.serviceComplete === 'Finalised'
                                    ? DateFormatter(service.finalisedDate)
                                    : DateFormatter(service.createdAt)}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(service.labourCost)}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(CalculatePartTotal(service))}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(CalculateMiscTotal(service))}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    service.totalPrice
                                        ? service.totalPrice
                                        : service.labourCost +
                                              CalculatePartTotal(service) +
                                              CalculateMiscTotal(service)
                                )}
                            </DataCell>
                            <DataCell>{service?.Site?.name}</DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            )}
        </Paper>
    );
};

export default Services;
