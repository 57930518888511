// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IconButton, TableRow } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
// COMPONENTS
import TableSearch from '../../../../global/tableComponents/TableSearch';
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
// INTERFACES
import { User } from '../../../../global/interfaces/GeneralInterface';
interface SelectTechFormProps {
    readOnly: boolean;
    preSelectedTechId: number;
    setPreSelectedTechId: Dispatch<SetStateAction<number>>;
    selectedTech: User;
    setSelectedTech: Dispatch<SetStateAction<User>>;
}

const clearedSort = ['id', 'DESC'];

const SelectTechForm = ({
    readOnly,
    preSelectedTechId,
    setPreSelectedTechId,
    selectedTech,
    setSelectedTech
}: SelectTechFormProps) => {
    const [resultsList, setResultsList] = useState<User[]>([]);
    const [resultsListAltered, setResultsListAltered] = useState<User[]>([]);

    // Columns for the data table
    const columns = [
        { id: 0, label: '', width: 50 },
        { id: 1, label: 'ID', name: 'id', width: 100 },
        { id: 2, label: 'Full Name', width: 200 },
        { id: 3, label: 'Username', width: 200 },
        { id: 4, label: 'Staff Email', width: 300 },
        { id: 5, label: 'Site', name: 'SiteId', width: 100 }
    ];

    useEffect(() => {
        if (preSelectedTechId) {
            const selectedTechRes = resultsList.filter(
                (item) => item.id === preSelectedTechId
            );
            if (selectedTechRes) {
                setResultsListAltered(selectedTechRes);
                setSelectedTech(selectedTechRes[0]);
            }
        } else if (selectedTech?.id) {
            let singleRow = [];
            singleRow.push(selectedTech);
            setResultsListAltered(singleRow);
        } else {
            setResultsListAltered(resultsList);
        }
        // eslint-disable-next-line
    }, [preSelectedTechId, selectedTech, resultsList]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let apiString = `&department=service,&employed=true,&site=${localStorage.getItem('SiteId')},`;
        return apiString;
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Users"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={clearedSort}
                handleRequestCreate={handleRequestCreate}
                route="userSearchNextGen"
                setResultsList={setResultsList}
            />

            <Paper>
                <DataTable columns={columns}>
                    {resultsListAltered.map((row) => (
                        <TableRow key={row.id}>
                            <DataCell>
                                {preSelectedTechId || selectedTech?.id ? (
                                    <IconButton
                                        onClick={() => {
                                            setSelectedTech(null);
                                            setPreSelectedTechId(null);
                                        }}
                                        disabled={readOnly}
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => {
                                            setSelectedTech(row);
                                            setPreSelectedTechId(row.id);
                                        }}
                                        disabled={readOnly}
                                    >
                                        <RadioButtonUnchecked />
                                    </IconButton>
                                )}
                            </DataCell>
                            <DataCell>{row.id}</DataCell>
                            <DataCell>
                                {`${row.firstName} ${row.lastName}`}{' '}
                            </DataCell>
                            <DataCell>{row.username}</DataCell>
                            <DataCell>{row.staffEmail}</DataCell>
                            <DataCell>{row.Site?.name}</DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </>
    );
};

export default SelectTechForm;
