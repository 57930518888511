import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    Typography
} from '@mui/material';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import ClearIcon from '@mui/icons-material/Clear';

interface CustomerPersonalisedPlateFormProps {
    onVendorFormSubmit: (formData: any, submitType: any) => Promise<void>;
    buttonLoading: boolean;
}

const CustomerPersonalisedPlateForm = ({
    onVendorFormSubmit,
    buttonLoading
}: CustomerPersonalisedPlateFormProps) => {
    const { control, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            firstOwnerSigDataURL: null,
            secondOwnerSigDataURL: null,
            vendorSigDataURL: null
        }
    });

    let firstOwnerSigCanvas = useRef<SignatureCanvas>(null);
    let secondOwnerSigCanvas = useRef<SignatureCanvas>(null);
    let vendorSigCanvas = useRef<SignatureCanvas>(null);

    const clearSignature = (canvasName: string) => {
        if (
            canvasName === 'firstOwnerSigCanvas' &&
            firstOwnerSigCanvas.current
        ) {
            firstOwnerSigCanvas.current.clear();
            setValue('firstOwnerSigDataURL', null);
        } else if (
            canvasName === 'secondOwnerSigCanvas' &&
            secondOwnerSigCanvas.current
        ) {
            secondOwnerSigCanvas.current.clear();
            setValue('secondOwnerSigDataURL', null);
        } else if (
            canvasName === 'vendorSigCanvas' &&
            vendorSigCanvas.current
        ) {
            vendorSigCanvas.current.clear();
            setValue('vendorSigDataURL', null);
        }
    };

    const saveSignature = (canvasName: string) => {
        if (
            canvasName === 'firstOwnerSigCanvas' &&
            firstOwnerSigCanvas.current
        ) {
            const dataURL = firstOwnerSigCanvas.current.toDataURL();
            setValue('firstOwnerSigDataURL', dataURL);
        } else if (
            canvasName === 'secondOwnerSigCanvas' &&
            secondOwnerSigCanvas.current
        ) {
            const dataURL = secondOwnerSigCanvas.current.toDataURL();
            setValue('secondOwnerSigDataURL', dataURL);
        } else if (
            canvasName === 'vendorSigCanvas' &&
            vendorSigCanvas.current
        ) {
            const dataURL = vendorSigCanvas.current.toDataURL();
            setValue('vendorSigDataURL', dataURL);
        }
    };

    const handlePreviewChanges = (data) => {
        onVendorFormSubmit(data, 'preview');
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Paper
                elevation={3}
                sx={{
                    padding: 3,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <form>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: 'bold', width: '50vw' }}
                    >
                        I declare that the information provided is true and
                        correct. I consent to TMR verifying my EOI documents or
                        information with the issuing authority or their
                        agencies.
                    </Typography>
                    <Grid item xs={12}>
                        First plate owner’s signature:{' '}
                        <IconButton
                            onClick={() =>
                                clearSignature('firstOwnerSigCanvas')
                            }
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Controller
                        name="firstOwnerSigDataURL"
                        control={control}
                        render={({ field }) => (
                            <>
                                <SignatureCanvas
                                    penColor="black"
                                    disabled={false}
                                    canvasProps={{
                                        className: 'firstOwnerSigCanvas',
                                        style: {
                                            border: '1px solid black',
                                            minWidth: 300,
                                            width: '50vw',
                                            minHeight: 100,
                                            height: '15vw'
                                        }
                                    }}
                                    ref={firstOwnerSigCanvas}
                                    // save user signature dataURL to form data
                                    onEnd={() =>
                                        saveSignature('firstOwnerSigCanvas')
                                    }
                                />
                            </>
                        )}
                    />
                    <Grid item xs={12}>
                        Second plate owner’s signature (if applicable):{' '}
                        <IconButton
                            onClick={() =>
                                clearSignature('secondOwnerSigCanvas')
                            }
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Controller
                        name="secondOwnerSigDataURL"
                        control={control}
                        render={({ field }) => (
                            <>
                                <SignatureCanvas
                                    penColor="black"
                                    disabled={false}
                                    canvasProps={{
                                        className: 'secondOwnerSigCanvas',
                                        style: {
                                            border: '1px solid black',
                                            minWidth: 300,
                                            width: '50vw',
                                            minHeight: 100,
                                            height: '15vw'
                                        }
                                    }}
                                    ref={secondOwnerSigCanvas}
                                    // save user signature dataURL to form data
                                    onEnd={() =>
                                        saveSignature('secondOwnerSigCanvas')
                                    }
                                />
                            </>
                        )}
                    />
                    <Grid item xs={12}>
                        VENDOR SIGNATURE:{' '}
                        <IconButton
                            onClick={() => clearSignature('vendorSigCanvas')}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Controller
                        name="vendorSigDataURL"
                        control={control}
                        render={({ field }) => (
                            <>
                                <SignatureCanvas
                                    penColor="black"
                                    disabled={false}
                                    canvasProps={{
                                        className: 'vendorSigCanvas',
                                        style: {
                                            border: '1px solid black',
                                            minWidth: 300,
                                            width: '50vw',
                                            minHeight: 100,
                                            height: '15vw'
                                        }
                                    }}
                                    ref={vendorSigCanvas}
                                    // save user signature dataURL to form data
                                    onEnd={() =>
                                        saveSignature('vendorSigCanvas')
                                    }
                                />
                            </>
                        )}
                    />
                    {/* // buttons to preview and email the form data */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Button
                            onClick={handleSubmit(handlePreviewChanges)}
                            variant="contained"
                            disabled={
                                watch('vendorSigDataURL') === null ||
                                watch('firstOwnerSigDataURL') === null
                            }
                        >
                            Preview Signature
                        </Button>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
};

export default CustomerPersonalisedPlateForm;
