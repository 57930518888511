import { Table, TableRow, TableCell, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Ctp } from '../../../../global/interfaces/VehicleSaleInterface';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import GetQLDCtp from '../../../../global/databaseLogic/GetQLDCtp';

const QLDCtp = () => {
    const [ctpPrices, setCtpPrices] = useState<Ctp[]>([]);

    useEffect(() => {
        GetQLDCtp(setCtpPrices);
    }, []);

    const getCtpPrice = (length: string, seats: string) => {
        let filteredCtpPrice = ctpPrices.filter(
            (x) => x.length === length && x.className === seats
        );
        return filteredCtpPrice[0]?.prices[0];
    };

    return (
        <>
            <Typography align="center" variant="h6">
                QLD CTP Prices
            </Typography>
            <Table>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle1">
                            <b>6 Months</b>
                        </Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle1">
                            <b>12 Months</b>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        <Typography variant="subtitle1">
                            <b>Single Seat</b>
                        </Typography>
                    </TableCell>
                    <TableCell align="center">
                        {`Personal: ${CurrencyFormatter(getCtpPrice('6', '1 Seat Motorcycle')?.personal)}`}
                        <br />
                        {`Business: ${CurrencyFormatter(getCtpPrice('6', '1 Seat Motorcycle')?.business)}`}
                        <br />
                    </TableCell>
                    <TableCell align="center">
                        {`Personal: ${CurrencyFormatter(getCtpPrice('12', '1 Seat Motorcycle')?.personal)}`}
                        <br />
                        {`Business: ${CurrencyFormatter(getCtpPrice('12', '1 Seat Motorcycle')?.business)}`}
                        <br />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        <Typography variant="subtitle1">
                            <b>Dual Seats</b>
                        </Typography>
                    </TableCell>
                    <TableCell align="center">
                        {`Personal: ${CurrencyFormatter(getCtpPrice('6', '2 Seat Motorcycle')?.personal)}`}
                        <br />
                        {`Business: ${CurrencyFormatter(getCtpPrice('6', '2 Seat Motorcycle')?.business)}`}
                        <br />
                    </TableCell>
                    <TableCell align="center">
                        {`Personal: ${CurrencyFormatter(getCtpPrice('12', '2 Seat Motorcycle')?.personal)}`}
                        <br />
                        {`Business: ${CurrencyFormatter(getCtpPrice('12', '2 Seat Motorcycle')?.business)}`}
                        <br />
                    </TableCell>
                </TableRow>
            </Table>
        </>
    );
};

export default QLDCtp;
