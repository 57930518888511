// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Vehicle } from '../interfaces/VehicleSaleInterface';

const GetAllVehicles = (setVehicles: Dispatch<SetStateAction<Vehicle[]>>) => {
  api.get('allVehicles').then((res) => {
    setVehicles(res.data);
  });
};

export default GetAllVehicles;
