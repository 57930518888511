// IMPORTS
import { Dispatch, SetStateAction } from "react";

// LOGIC
import api from "../../../../api";
import { Category } from "../interfaces/PartsInterface";

/**
 * GetCategories
 * Get all categories from the server
 * @author Pierre
 * @param setCategories a state setter for the retrieved categories
 */
const GetCategories = (setCategories: Dispatch<SetStateAction<Category[]>>) => {
    api.get("categories").then((res) => {
        setCategories(res.data);
    });
};

export default GetCategories;
