import { Avatar, Box, Menu, MenuItem, Tooltip } from '@mui/material';
import { Signer, Tag, TAG_ACTIONS, TAG_TYPES } from '../../EsignInterface';
import { ResizableBox } from 'react-resizable';
import {
    forwardRef,
    MouseEvent,
    SyntheticEvent,
    TouchEvent,
    useEffect,
    useState
} from 'react';
import TextfieldTag from './TextfieldTag';
import SignTag from './SignTag';
import SignDateTag from './SignDateTag';
import useMediaQuery from '@mui/material/useMediaQuery';

// do not remove
import 'react-resizable/css/styles.css';
import CheckboxTag from './CheckboxTag';
import TextTag from './TextTag';
import MySignatureTag from './MySignatureTag';

interface ResizableTagProps {
    index: number;
    action: string;
    tag: Tag;
    tags: Tag[];
    setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
    width: number;
    height: number;
    x: number;
    y: number;

    currentTagIndex?: number;
    setCurrentTagIndex?: React.Dispatch<React.SetStateAction<number>>;
    signatureDataUrl?: any;
    selectedSigners?: Signer[];
}

const ResizableTag = forwardRef<HTMLDivElement, ResizableTagProps>(
    (
        {
            index,
            action,
            tag,
            tags,
            setTags,
            width,
            height,
            x,
            y,
            currentTagIndex,
            signatureDataUrl,
            selectedSigners
        },
        ref
    ) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

        // use media query: mobile, tablet, laptop, desktop, largeDesktop
        const mobile = useMediaQuery('(max-width:600px)');
        const tablet = useMediaQuery('(max-width:960px)');
        const laptop = useMediaQuery('(max-width:1280px)');
        const desktop = useMediaQuery('(max-width:1920px)');
        const largeDesktop = useMediaQuery('(max-width:2560px)');

        const [size, setSize] = useState<{ width: number; height: number }>({
            width,
            height
        });

        useEffect(() => {
            setSize({ width, height });
        }, [width, height]);

        let iconSize: string;
        let fontSize: string;

        if (mobile) {
            iconSize = action === TAG_ACTIONS.MODIFY ? '0.3em' : '0.4em';
            fontSize = action === TAG_ACTIONS.MODIFY ? '0.2em' : '0.3em';
        } else if (tablet) {
            iconSize = action === TAG_ACTIONS.MODIFY ? '0.8em' : '1.2em';
            fontSize = action === TAG_ACTIONS.MODIFY ? '0.6em' : '0.8em';
        } else if (laptop) {
            iconSize = action === TAG_ACTIONS.MODIFY ? '1.2em' : '1.5em';
            fontSize = action === TAG_ACTIONS.MODIFY ? '0.8em' : '1em';
        } else if (desktop) {
            iconSize = action === TAG_ACTIONS.MODIFY ? '1.4em' : '2em';
            fontSize = action === TAG_ACTIONS.MODIFY ? '0.9em' : '1.3em';
        } else if (largeDesktop) {
            iconSize = action === TAG_ACTIONS.MODIFY ? '1.8em' : '2.5em';
            fontSize = action === TAG_ACTIONS.MODIFY ? '1.3em' : '1.5em';
        } else {
            iconSize = '3em';
            fontSize = action === TAG_ACTIONS.MODIFY ? '1.5em' : '2em';
        }

        const [hasLabel, setHasLabel] = useState<boolean>(true);

        // handle resize tag on canvas
        const onResizeStop = (
            e: SyntheticEvent,
            data: { size: { width: number; height: number } }
        ) => {
            const newSize = {
                width: data.size.width,
                height: data.size.height
            };
            setSize(newSize);

            // Update the tag's size in the tags array
            setTags((prevTags) => {
                const updatedTags = [...prevTags];
                updatedTags[index].width = newSize.width;
                updatedTags[index].height = newSize.height;
                return updatedTags;
            });

            // If the new width is smaller than the original width, hide the label, else show it
            if (newSize.width < width) {
                setHasLabel(false);
            } else {
                setHasLabel(true);
            }
        };

        const removeTag = () => {
            setTags((prevTags) => prevTags.filter((_, idx) => idx !== index));
        };

        // when onclick tag, display all selectedSigners in dropdown, allow user to select signer
        const handleClickInitial = (
            event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>
        ) => {
            event.preventDefault();
            if (action === TAG_ACTIONS.MODIFY) {
                setAnchorEl(event.currentTarget);
            }
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const currentSigner = selectedSigners?.find(
            (signer) => signer.externalId === tag.SignerId
        );

        const getSignerInitials = (signer: Signer) => {
            const name = signer.fullName.split(' ');
            return (
                name[0].charAt(0) + name[name.length - 1].charAt(0)
            ).toUpperCase();
        };

        const handleSignerSelect = (signer: Signer) => {
            setTags((prevTags) => {
                const updatedTags = [...prevTags];
                updatedTags[index] = {
                    ...updatedTags[index],
                    SignerId: signer.externalId
                };
                return updatedTags;
            });

            handleClose();
        };

        const isContentTag =
            tag.type === TAG_TYPES.TEXT || tag.type === TAG_TYPES.MYSIGNATURE;

        return (
            <Box
                ref={ref}
                sx={{
                    width: `${size.width}px`,
                    height: `${size.height}px`,
                    position: 'absolute',
                    left: `${x}px`,
                    top: `${y}px`
                }}
            >
                {action === TAG_ACTIONS.MODIFY && !isContentTag && (
                    <Tooltip
                        title={
                            currentSigner
                                ? `${currentSigner.fullName}: ${currentSigner.email}`
                                : 'Select Signer'
                        }
                    >
                        <Avatar
                            sx={{
                                position: 'absolute',
                                top: -10,
                                left: -10,
                                width: 20,
                                height: 20,
                                fontSize: '0.5rem',
                                backgroundColor: 'primary.main',
                                cursor: 'pointer',
                                zIndex: 1
                            }}
                            onClick={handleClickInitial}
                        >
                            {currentSigner
                                ? getSignerInitials(currentSigner)
                                : '?'}
                        </Avatar>
                    </Tooltip>
                )}
                <ResizableBox
                    width={size.width}
                    height={size.height}
                    onResizeStop={(e, data) => onResizeStop(e, data)}
                    // can resize from the bottom right corner only
                    resizeHandles={action === TAG_ACTIONS.MODIFY ? ['se'] : []}
                    // when tag type is checkbox, make ratio fixed to 1
                    lockAspectRatio={
                        tag.type === TAG_TYPES.CHECKBOX ||
                        tag.type === TAG_TYPES.SIGNATURE
                    }
                >
                    {tag.type === TAG_TYPES.SIGNATURE ? (
                        <SignTag
                            action={action}
                            tag={tag}
                            fontSize={fontSize}
                            iconSize={iconSize}
                            hasLabel={hasLabel}
                            removeTag={removeTag}
                            setTags={setTags}
                        />
                    ) : tag.type === TAG_TYPES.TEXTFIELD ? (
                        <TextfieldTag
                            action={action}
                            tag={tag}
                            fontSize={fontSize}
                            iconSize={iconSize}
                            hasLabel={hasLabel}
                            removeTag={removeTag}
                            setTags={setTags}
                        />
                    ) : tag.type === TAG_TYPES.DATESIGNED ? (
                        <SignDateTag
                            action={action}
                            tag={tag}
                            fontSize={fontSize}
                            iconSize={iconSize}
                            hasLabel={hasLabel}
                            removeTag={removeTag}
                            setTags={setTags}
                        />
                    ) : tag.type === TAG_TYPES.CHECKBOX ? (
                        <CheckboxTag
                            action={action}
                            tag={tag}
                            fontSize={fontSize}
                            iconSize={iconSize}
                            hasLabel={hasLabel}
                            removeTag={removeTag}
                            setTags={setTags}
                        />
                    ) : tag.type === TAG_TYPES.TEXT ? (
                        <TextTag
                            action={action}
                            tag={tag}
                            fontSize={fontSize}
                            removeTag={removeTag}
                            setTags={setTags}
                        />
                    ) : tag.type === TAG_TYPES.MYSIGNATURE ? (
                        <MySignatureTag
                            action={action}
                            tag={tag}
                            fontSize={fontSize}
                            removeTag={removeTag}
                            signatureDataUrl={signatureDataUrl}
                        />
                    ) : null}
                </ResizableBox>
                {selectedSigners &&
                selectedSigners.length > 0 &&
                action === TAG_ACTIONS.MODIFY &&
                !isContentTag ? (
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {selectedSigners.map((signer, idx) => (
                            <MenuItem
                                key={signer.externalId}
                                onClick={() => handleSignerSelect(signer)}
                                selected={
                                    (idx === 0 && !tag.SignerId) ||
                                    tag.SignerId === signer.externalId
                                }
                            >
                                {`${signer.fullName}: ${signer.email}`}
                            </MenuItem>
                        ))}
                    </Menu>
                ) : null}
            </Box>
        );
    }
);

export default ResizableTag;
