// IMPORTS
import {
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
// COMPONENTS
import RoleCheck from '../../global/RoleCheck';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { SubMenuProps } from '../../global/interfaces/GlobalInterface';

const AdminSubMenu = ({ isSupportPage }: SubMenuProps) => {
    const [reconciliatorDropdownOpen, setReconciliatorDropdownOpen] =
        useState<boolean>(false);
    const [manualInvoiceDropdownOpen, setManualInvoiceDropdownOpen] =
        useState<boolean>(false);
    const currentMode = useTheme().palette.mode;

    return (
        <>
            <List
                sx={{
                    backgroundColor:
                        currentMode === 'dark' ? '#3b3b3b' : '#dddddd'
                }}
            >
                {/* ACCOUNTS */}
                <RoleCheck
                    permission={'access_accounts'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/accounts'
                                    : '/admin/accounts'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Accounts
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                {/* REPORTING */}
                <RoleCheck //! TO BE CONVERTED TO TYPESCRIPT
                    permission={'access_reporting'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/reporting'
                                    : '/admin/reporting'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Reporting
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                {/* CREDITORS */}
                <RoleCheck
                    permission={'access_creditors'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/creditors'
                                    : '/creditors'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Creditors
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                {/* ENTER INVOICE */}
                <RoleCheck
                    permission={'access_invoice'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/enterInvoice'
                                    : '/admin/enterInvoice'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Enter Invoice
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                {/* CREDIT INVOICE */}
                <RoleCheck
                    permission={'access_creditInvoice'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/creditInvoice'
                                    : '/admin/creditInvoice'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Credit Invoice
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                {/* GENERATE INVOICE */}
                {/* hide manual invoice and dropdown for support page */}
                {!isSupportPage && (
                    <RoleCheck
                        permission={'generate_Invoice'}
                        component={
                            <ListItemButton
                                sx={{ fontSize: '13px' }}
                                onClick={() =>
                                    setManualInvoiceDropdownOpen(
                                        !manualInvoiceDropdownOpen
                                    )
                                }
                            >
                                <ListItemText
                                    inset
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                >
                                    Manual Invoices
                                </ListItemText>
                                {manualInvoiceDropdownOpen ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItemButton>
                        }
                    />
                )}

                {!isSupportPage && (
                    <Collapse
                        in={manualInvoiceDropdownOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/generateInvoiceTable'
                                    : '/admin/generateInvoiceTable'
                            }
                            sx={{ pl: 4, fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Table
                            </ListItemText>
                        </ListItemButton>

                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/generateInvoice'
                                    : '/admin/generateInvoice'
                            }
                            sx={{ pl: 4, fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Generate Invoice
                            </ListItemText>
                        </ListItemButton>
                    </Collapse>
                )}

                {/* GENERAL JOURNAL */}
                <RoleCheck
                    permission={'access_GJE'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/generalAccountingEntry'
                                    : '/admin/generalAccountingEntry'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                General Journal
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                <RoleCheck
                    permission={'access_bankRec'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/manualTransaction'
                                    : '/admin/manualTransaction'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Manual Transaction
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                <RoleCheck
                    permission={
                        'access_bankRec, access_credRec, access_debtRec, access_rebateRec, access_regoRec'
                    }
                    component={
                        <ListItemButton
                            sx={{ fontSize: '13px' }}
                            onClick={() =>
                                setReconciliatorDropdownOpen(
                                    !reconciliatorDropdownOpen
                                )
                            }
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Reconciliators
                            </ListItemText>
                            {reconciliatorDropdownOpen ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </ListItemButton>
                    }
                />

                <Collapse
                    in={reconciliatorDropdownOpen}
                    timeout="auto"
                    unmountOnExit
                >
                    <RoleCheck
                        permission={'access_bankRec'}
                        component={
                            <ListItemButton
                                component={Link}
                                to={
                                    isSupportPage
                                        ? '/support/admin/bankReconciliation'
                                        : '/admin/bankReconciliation'
                                }
                                sx={{ pl: 4, fontSize: '13px' }}
                            >
                                <ListItemText
                                    inset
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                >
                                    Bank Reconciliation
                                </ListItemText>
                            </ListItemButton>
                        }
                    />
                    <RoleCheck
                        permission={'access_credRec'}
                        component={
                            <ListItemButton
                                component={Link}
                                to={
                                    isSupportPage
                                        ? '/support/admin/creditorReconciliation'
                                        : '/admin/creditorReconciliation'
                                }
                                sx={{ pl: 4, fontSize: '13px' }}
                            >
                                <ListItemText
                                    inset
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                >
                                    Creditor Reconciliation
                                </ListItemText>
                            </ListItemButton>
                        }
                    />

                    <RoleCheck
                        permission={'access_debtRec'}
                        component={
                            <ListItemButton
                                component={Link}
                                to={
                                    isSupportPage
                                        ? '/support/admin/debtorReconciliation'
                                        : '/admin/debtorReconciliation'
                                }
                                sx={{ pl: 4, fontSize: '13px' }}
                            >
                                <ListItemText
                                    inset
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                >
                                    Debtor Reconciliation
                                </ListItemText>
                            </ListItemButton>
                        }
                    />
                    <RoleCheck
                        permission={'access_rebateRec'}
                        component={
                            <ListItemButton
                                component={Link}
                                to={
                                    isSupportPage
                                        ? '/support/admin/rebateReconciliation'
                                        : '/admin/rebateReconciliation'
                                }
                                sx={{ pl: 4, fontSize: '13px' }}
                            >
                                <ListItemText
                                    inset
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                >
                                    Rebate Reconciliator
                                </ListItemText>
                            </ListItemButton>
                        }
                    />
                    <RoleCheck
                        permission={'access_regoRec'}
                        component={
                            <ListItemButton
                                component={Link}
                                to={
                                    isSupportPage
                                        ? '/support/admin/registrationReconciliation'
                                        : '/admin/registrationReconciliation'
                                }
                                sx={{ pl: 4, fontSize: '13px' }}
                            >
                                <ListItemText
                                    inset
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                >
                                    Rego Reconciliator
                                </ListItemText>
                            </ListItemButton>
                        }
                    />
                </Collapse>

                <RoleCheck
                    permission={'access_timeclock'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/timeclock'
                                    : '/admin/timeclock'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{
                                    variant: 'caption'
                                }}
                            >
                                Time Clock
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                {/* DASHBOARD */}
                <RoleCheck
                    permission={'access_admin_dashboard'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/admin/dashboard'
                                    : '/admin/dashboard'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Dashboard
                            </ListItemText>
                        </ListItemButton>
                    }
                />
            </List>
        </>
    );
};

export default AdminSubMenu;
