// IMPORTS
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import {
    Customer,
    Settings,
    Site
} from '../../../global/interfaces/GeneralInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';
import Header from './ServiceInvoiceComponents/Header';

const PDFJobCard = async (
    serviceDetails: Service,
    selectedCustomer: Customer,
    vehicleType: string,
    selectedVehicle: Vehicle
) => {
    let settings: Settings;
    let site: Site;
    let image = null;

    // Get the settings in order to get company details and logo
    await api
        .get(`settingsNextGen/${serviceDetails.SiteId}?includeImage=true`)
        .then((res) => {
            settings = res.data.settings;
            site = res.data.site;

            // Create and format an image object
            let newImg = document.createElement('img');
            newImg.src = res.data.image;
            newImg.className = 'logo';
            image = newImg;
        });

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    Header(
        doc,
        serviceDetails,
        selectedCustomer,
        vehicleType,
        selectedVehicle,
        image,
        settings,
        site
    );

    doc.setFont('helvetica', 'bold').setFontSize(15);
    doc.text('Technicians Instructions', 105, 120, { align: 'center' });

    doc.setFont('helvetica', 'normal').setFontSize(10);
    let split = doc.splitTextToSize(
        serviceDetails.jobDescription ? serviceDetails.jobDescription : '',
        170
    );
    let lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;
    let nbOfLine = split.length;

    doc.text(split, 20, 125);
    let nextY = 125 + nbOfLine * lineHeight;

    if (nextY >= 200) {
        doc.addPage();
        doc.setPage(2);
        Header(
            doc,
            serviceDetails,
            selectedCustomer,
            vehicleType,
            selectedVehicle,
            image,
            settings,
            site
        );
        nextY = 120;
    } else {
        nextY = 200;
    }

    doc.setDrawColor('black');
    doc.rect(10, nextY - 2, 189, 87);
    doc.setFont('helvetica', 'normal').setFontSize(10);

    doc.setFontSize(8);
    doc.text(
        `I hereby authorise the above mentioned repairs to be executed as soon as possible. I agree to / understand: `,
        11,
        nextY + 2
    );
    doc.text(
        '- Pay the necessary costs upon completion of the repairs.',
        20,
        nextY + 5
    );
    doc.text(
        '- Should any further repairs be necessary I will be contacted prior to commencement for permission to proceed.',
        20,
        nextY + 8
    );
    doc.text(
        '- ' +
            settings.companyName +
            ' is not responsible for loss or damage to vehicles or articles left in the vehicle in case of',
        20,
        nextY + 11
    );
    doc.text('fire, theft or any other causes beyond control.', 22, nextY + 14);
    doc.text(
        '- ' +
            settings.companyName +
            ' is not responsible for any delays caused by unavailability of parts or delays in part shipments',
        20,
        nextY + 17
    );
    doc.text('by the supplier and/or transporter.', 22, nextY + 20);
    doc.text(
        '- The provisions of the Disposal of Uncollected Goods Act 1967.',
        20,
        nextY + 23
    );
    doc.text(
        '- All Goods and Services remain the property of ' +
            settings.companyName +
            ' until the necessary costs have been paid in full.',
        20,
        nextY + 26
    );
    doc.text(
        '- Goods containing user-generated data may result in loss of data during the course of the repair.',
        20,
        nextY + 29
    );
    doc.text(
        '- Goods presented for repair may be replaced by refurbished goods of the same type rather than being repaired.',
        20,
        nextY + 32
    );
    doc.text(
        '- Our goods and services come with guarantees that cannot be excluded under the Australian Consumer Law.',
        20,
        nextY + 35
    );
    doc.text(
        'For major failures with the service, you are entitled:',
        22,
        nextY + 38
    );
    doc.text('- To cancel your service contract with us; and', 26, nextY + 41);
    doc.text(
        '- To a refund for the unused portion, or to compensation for its reduced value.',
        26,
        nextY + 44
    );
    doc.text(
        '- You are also entitled to choose a refund or replacement for major failures with goods.',
        20,
        nextY + 47
    );
    doc.text(
        '- If a failure with the goods or a service does not amount to a major failure, you are entitled to have the failure rectified in a reasonable time.',
        20,
        nextY + 50
    );
    doc.text(
        '- You are also entitled to choose a refund or replacement for major failures with goods.',
        20,
        nextY + 53
    );

    doc.setFont('helvetica', 'bold');
    doc.text('Privacy Collection Statement', 11, nextY + 57);

    doc.setFont('helvetica', 'normal');
    split = doc.splitTextToSize(
        `The customer gives permission for ${settings.companyName} to retain personal information and authorises ${settings.companyName} to use that information for the purpose of advertising future marking information about products and services offered to customers. For more details about out privacy policy please visit our website at ${settings?.websiteURL}.`,
        185
    );
    doc.text(split, 11, nextY + 60);

    doc.setFont('helvetica', 'bold');
    doc.text(
        'Date: _____ / _____ / _________    Customer Signature:',
        11,
        nextY + 73
    );
    doc.text(
        'Date: _____ / _____ / _________    Service Advisor:',
        11,
        nextY + 80
    );

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFJobCard;
