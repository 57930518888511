// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
import SliceAndSet from './SliceAndSet';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { VehicleSale } from '../../../global/interfaces/VehicleSaleInterface';

/**
 * GetSales
 * Front-end API request for a customer's sales information
 * @param id  the customer's ID
 * @param setPartSales setter for part sales
 * @param setPartsPage setter for current part sales page
 * @param setNumParts setter for total number of parts
 * @param setServices setter for services
 * @param setServicesPage setter for current services page
 * @param setNumServices setter for total number of services
 * @param setVehicleSales setter for vehicle sales
 * @param setVehicleSalesPage setter for current vehicle sales page
 * @param setNumVehicleSales setter for total number of sales
 */
const GetSales = (
  id: string,
  setPartSales: Dispatch<SetStateAction<any>>,
  setPartsPage: Dispatch<SetStateAction<Order[]>>,
  setNumParts: Dispatch<SetStateAction<number>>,
  setServices: Dispatch<SetStateAction<any>>,
  setServicesPage: Dispatch<
    SetStateAction<[{ service: Service; orderData: Order[] }]>
  >,
  setNumServices: Dispatch<SetStateAction<number>>,
  setVehicleSales: Dispatch<SetStateAction<any>>,
  setVehicleSalesPage: Dispatch<SetStateAction<VehicleSale[]>>,
  setNumVehicleSales: Dispatch<SetStateAction<number>>,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  setLoading(true);
  api.get(`allCustomerSales/${id}`).then((res) => {
    SliceAndSet(
      res.data.partSales,
      10,
      setPartSales,
      setPartsPage,
      setNumParts
    );
    SliceAndSet(
      res.data.services,
      10,
      setServices,
      setServicesPage,
      setNumServices
    );
    SliceAndSet(
      res.data.vehicleSales,
      10,
      setVehicleSales,
      setVehicleSalesPage,
      setNumVehicleSales
    );
    setLoading(false);
  });
};

export default GetSales;
