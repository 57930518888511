import './App.css';
import { StylesProvider } from '@material-ui/core';
import Main from './components/main/Main.js';
import { BrowserRouter } from 'react-router-dom';

function App() {
    return (
        <StylesProvider injectFirst={true}>
            <BrowserRouter>
                <Main />
            </BrowserRouter>
        </StylesProvider>
    );
}

export default App;
