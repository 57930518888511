// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { StockTransfer } from '../../../global/interfaces/PartsInterface';
interface Movement {
  id: number;
  type: string;
  quantity: number;
  Customer?: Customer;
  date: string;
  link: string;
  Transfer?: StockTransfer;
}

const GetMovement = (
  partId: number,
  selectedSite: number,
  setMovement: Dispatch<SetStateAction<Movement[]>>
) => {
  api.get(`getMovementNextGen/${partId}?SiteId=${selectedSite}`).then((res) => {
    setMovement(res.data);
  });
};

export default GetMovement;
