// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Checkbox,
    DialogContentText,
    FormControlLabel,
    Grid
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';

// LOGIC
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site, User } from '../../../global/interfaces/GeneralInterface';
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import GetAllUsers from '../../../global/databaseLogic/GetAllUsers';

interface StockOrderFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const typeOptions = [
    { label: 'Stock', value: 'stock' },
    { label: 'Daily', value: 'daily' },
    { label: 'Customer Backorder', value: 'customer' }
];

const StockOrderTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: StockOrderFilterProps) => {
    const [sitesUnaltered, setSitesUnaltered] = useState<Site[]>([]);
    const [sites, setSites] = useState<Site[]>([]);
    const [usersUnaltered, setUsersUnaltered] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [types, setTypes] = useState(typeOptions);

    useEffect(() => {
        GetAllSites(setSites);
        GetAllSites(setSitesUnaltered);
        GetAllUsers(setUsers);
        GetAllUsers(setUsersUnaltered);
    }, []);

    useEffect(() => {
        if (!isFilterActive()) {
            setSites(sitesUnaltered);
            setUsers(usersUnaltered);
            setTypes(typeOptions);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.stockOrderFilter;
        newFilter[property] = value;
        setFilter({ ...filter, stockOrderFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    {/* Sale Type Filter */}
                    <AutocompleteMultiple
                        options={types}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Stock Order Type"
                        selectedValues={filter.stockOrderFilter.type}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = typeOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setTypes(currentOptions);
                            handleUpdateFilter('type', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    {/* Site Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.stockOrderFilter.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sitesUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    {/* User Filter */}
                    <AutocompleteMultiple
                        options={users}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="User"
                        selectedValues={filter.stockOrderFilter.user}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = usersUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setUsers(currentOptions);
                            handleUpdateFilter('user', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Stock Order Start Range"
                            value={filter.stockOrderFilter.startDate}
                            onChange={(newValue) =>
                                handleUpdateFilter('startDate', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Stock Order End Range"
                            value={filter.stockOrderFilter.endDate}
                            onChange={(newValue) =>
                                handleUpdateFilter('endDate', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.stockOrderFilter.invoiced}
                                onChange={(e) =>
                                    handleUpdateFilter(
                                        'invoiced',
                                        e.target.checked
                                    )
                                }
                            />
                        }
                        label="Show Invoiced Stock Order"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default StockOrderTableFilter;
