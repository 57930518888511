import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { CurrencyFormatter, ToFixed } from "../../../global/logic/Formatters";
import { Snack } from "../../../global/interfaces/GlobalInterface";

interface CreditingPartsLine {
    partNumber: string;
    name: string;
    unitId: number;
    costPriceDaily: number;
    quantity: number;
    lineTotal: number;
    qtyError: boolean;
    lineTotalError: boolean;
}

export const handleQuantityChange = (value: string, index: number, creditingParts: CreditingPartsLine[], setCreditingParts: Dispatch<SetStateAction<CreditingPartsLine[]>>) => {
    let tempArray = JSON.parse(JSON.stringify(creditingParts));
    tempArray[index].quantity = parseInt(value);
    setCreditingParts(tempArray);
};

export const handleLineTotalChange = (value: string, index: number, creditingParts: CreditingPartsLine[], setCreditingParts: Dispatch<SetStateAction<CreditingPartsLine[]>>) => {
    let tempArray = JSON.parse(JSON.stringify(creditingParts));
    tempArray[index].lineTotal = parseFloat(value);
    setCreditingParts(tempArray);
};

export const removePart = (index: number, creditingParts: CreditingPartsLine[], setCreditingParts: Dispatch<SetStateAction<CreditingPartsLine[]>>) => {
    let tempArray = JSON.parse(JSON.stringify(creditingParts));
    tempArray.splice(index, 1);
    setCreditingParts(tempArray);
};

export const deleteInvoice = (id: number, selectedSupplier: number, setSnackbar: Dispatch<SetStateAction<Snack>>) => {
    api.delete("/creditInvoiceNextGen/" + id).then((res) => {
        if (res.status === 200) {
            window.location.href = '/viewCreditor/' + selectedSupplier
        } else {
            setSnackbar({ open: true, severity: 'error', message: 'Something went wrong. Please try again later.' })
        }
    });
};

export const partsTotal = (type: string, isGSTIncluded: boolean, creditingParts, freight: number) => {
    if (isGSTIncluded && type === "inc")
        return CurrencyFormatter(
            ToFixed(
                creditingParts.reduce((a, part) => {
                    return part.lineTotal + a;
                }, 0) + freight
            )
        );
    if (isGSTIncluded && type === "exc")
        return CurrencyFormatter(
            ToFixed(
                creditingParts.reduce((a, part) => {
                    return part.lineTotal / 1.1 + a;
                }, 0) +
                freight / 1.1
            )
        ); // add gst 2
    if (!isGSTIncluded && type === "exc")
        return CurrencyFormatter(
            ToFixed(
                creditingParts.reduce((a, part) => {
                    return part.lineTotal + a;
                }, 0) + freight
            )
        );
    if (!isGSTIncluded && type === "inc")
        return CurrencyFormatter(
            ToFixed(
                creditingParts.reduce((a, part) => {
                    return part.lineTotal * 1.1 + a;
                }, 0)
            ) +
            freight * 1.1
        );
    return 0;
};
