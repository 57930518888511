// IMPORTS
import { useEffect, useState } from 'react';
import {
    DialogContent,
    DialogTitle,
    TableRow,
    DialogActions,
    Button,
    Typography,
    TextField,
    IconButton
} from '@mui/material';
import { Close } from '@mui/icons-material';
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import dayjs from 'dayjs';

// COMPONENTS
import RoleCheck from '../../global/RoleCheck';
import Autocomplete from '../../global/Autocomplete';

// LOGIC
import { DateFormatter } from '../../global/logic/Formatters';
import GetTechTimeClocks from '../logic/GetTechTimeClocks';
import SubmitTechTimeClock from '../logic/SubmitTechTimeClock';
import GetAllUsers from '../../global/databaseLogic/GetAllUsers';
import UpdateTechTimeClock from '../logic/UpdateTechTimeClock';
import DeleteTechTimeClock from '../logic/DeleteTechTimeClock';

// INTERFACES
import { TimeClock } from '../../global/interfaces/AdminInterface';
import { User } from '../../global/interfaces/GeneralInterface';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Vehicle } from '../../global/interfaces/VehicleSaleInterface';
import { Service } from '../../global/interfaces/ServiceInterface';

interface TechTimeClocksProps {
    service: Service;
    ServiceId: number;
    selectedTech: User;
    vehicle: Vehicle;
}

const TechTimeClocks = ({
    service,
    selectedTech,
    vehicle,
    ServiceId
}: TechTimeClocksProps) => {
    const [readOnly, setReadOnly] = useState(true);
    const [clockStatus, setClockStatus] = useState<boolean>(true); // false is clock off, true is clock on
    const [timeClocks, setTimeClocks] = useState<TimeClock[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [allTechs, setAllTechs] = useState<User[]>([]);

    const columns = [
        { id: 0, label: 'Date', width: 250 },
        { id: 0, label: 'Time On', width: 250 },
        { id: 1, label: 'Time Off', width: 250 },
        { id: 2, label: 'Tech', width: 300 },
        { id: 3, label: 'Total', width: 100 },
        { id: 4, label: '', width: 50 }
    ];

    useEffect(() => {
        GetTechTimeClocks(ServiceId, setTimeClocks);
        GetAllUsers(setAllTechs, '?department=service');
    }, [refresh, ServiceId]);

    useEffect(() => {
        if (timeClocks.length > 0) {
            if (timeClocks[timeClocks.length - 1].clockOff) {
                setClockStatus(true);
            } else {
                setClockStatus(false);
            }
        }
    }, [timeClocks]);

    const handleUpdateTimeClock = (index, selector, newValue) => {
        let currentTimeClocks = [...timeClocks];
        currentTimeClocks[index][selector] = newValue;
        setTimeClocks(currentTimeClocks);
    };

    const CalculateTime = (row) => {
        let timeBetween = '';
        if (row?.clockOn && row?.clockOff) {
            let clockOn = dayjs(row.clockOn);
            let clockOff = dayjs(row.clockOff);

            // We are only storing the time and not the date and the time are stored in UTC
            // That means that we can have a time of 00:40 GMT +10 for clock OFF and 21:40 GMT +10 for clock on
            // Which Ora would interpret as a negative difference
            if (clockOff.isBefore(clockOn)) {
                clockOff = clockOff.clone().add(1, 'day');
                timeBetween = dayjs(clockOff)
                    .diff(dayjs(clockOn), 'minute')
                    .toString();
            } else {
                timeBetween = dayjs(clockOff)
                    .diff(dayjs(clockOn), 'minute')
                    .toString();
            }
        }
        return timeBetween;
    };

    return (
        <>
            <DialogTitle>
                Time Clocks - {service.jobType} Service #{ServiceId}
            </DialogTitle>
            <DialogContent>
                <Typography>{`Vehicle: ${vehicle?.condition ? vehicle?.condition?.toUpperCase() : ''} ${vehicle.year.format('YYYY')} ${vehicle?.Make?.name.toUpperCase()} ${vehicle?.Model?.name.toUpperCase()}`}</Typography>
                {vehicle?.stockNumber ? (
                    <Typography>Stock Number: {vehicle.stockNumber}</Typography>
                ) : null}

                <Typography>VIN: {vehicle.vin}</Typography>
                <DataTable columns={columns}>
                    {timeClocks.map((row, index) => (
                        <TableRow>
                            <DataCell>
                                {readOnly ? (
                                    DateFormatter(row.date)
                                ) : (
                                    <RoleCheck
                                        permission="edit_techTimeClock"
                                        component={
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="date"
                                                variant="standard"
                                                value={row?.date}
                                                onChange={(e) =>
                                                    handleUpdateTimeClock(
                                                        index,
                                                        'date',
                                                        e.target.value
                                                    )
                                                }
                                                disabled={readOnly}
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }}
                                            />
                                        }
                                        nullComponent={DateFormatter(row.date)}
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                {readOnly ? (
                                    dayjs(row.clockOn).format('hh:mma')
                                ) : (
                                    <RoleCheck
                                        permission="edit_techTimeClock"
                                        component={
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <TimePicker
                                                    label="Controlled picker"
                                                    value={row?.clockOn}
                                                    onChange={(newValue) =>
                                                        handleUpdateTimeClock(
                                                            index,
                                                            'clockOn',
                                                            newValue
                                                        )
                                                    }
                                                />
                                            </LocalizationProvider>
                                        }
                                        nullComponent={row?.clockOn?.format(
                                            'hh:mma'
                                        )}
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                {row.clockOff ? (
                                    readOnly ? (
                                        dayjs(row.clockOff).format('hh:mma')
                                    ) : (
                                        <RoleCheck
                                            permission="edit_techTimeClock"
                                            component={
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <TimePicker
                                                        label="Controlled picker"
                                                        value={row?.clockOff}
                                                        onChange={(newValue) =>
                                                            handleUpdateTimeClock(
                                                                index,
                                                                'clockOff',
                                                                newValue
                                                            )
                                                        }
                                                    />
                                                </LocalizationProvider>
                                            }
                                            nullComponent={row.clockOff.format(
                                                'hh:mma'
                                            )}
                                        />
                                    )
                                ) : (
                                    <Typography
                                        sx={{ color: 'red' }}
                                        variant="body2"
                                    >
                                        {dayjs().format('hh:mma')}
                                    </Typography>
                                )}
                            </DataCell>
                            <DataCell>
                                {readOnly ? (
                                    row.User?.firstName +
                                    ' ' +
                                    row.User?.lastName
                                ) : (
                                    <RoleCheck
                                        permission="edit_techTimeClock"
                                        component={
                                            <Autocomplete
                                                size="small"
                                                options={allTechs}
                                                textfieldLabel=""
                                                useTwoOptionLabels={false}
                                                primaryOptionLabel="name"
                                                textfieldVariant="standard"
                                                isDisabled={readOnly}
                                                selectedValue={row.User}
                                                handleSelectedValueChange={(
                                                    newValue
                                                ) => {
                                                    handleUpdateTimeClock(
                                                        index,
                                                        'UserId',
                                                        newValue.id
                                                    );
                                                    handleUpdateTimeClock(
                                                        index,
                                                        'User',
                                                        newValue
                                                    );
                                                }}
                                                handleInputValueChange={(
                                                    newValue
                                                ) => null}
                                            />
                                        }
                                        nullComponent={row.clockOn.format(
                                            'hh:mma'
                                        )}
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                {CalculateTime(row).length > 0
                                    ? CalculateTime(row) + ' minutes'
                                    : ''}
                            </DataCell>
                            <DataCell>
                                {readOnly ? null : (
                                    <IconButton
                                        onClick={() =>
                                            DeleteTechTimeClock(
                                                row.id,
                                                refresh,
                                                setRefresh,
                                                setReadOnly
                                            )
                                        }
                                    >
                                        <Close />
                                    </IconButton>
                                )}
                            </DataCell>
                        </TableRow>
                    ))}
                    {clockStatus && readOnly ? (
                        <TableRow>
                            <DataCell>
                                <Typography
                                    sx={{ color: 'red' }}
                                    variant="body2"
                                >
                                    {DateFormatter(String(new Date()))}
                                </Typography>
                            </DataCell>
                            <DataCell>
                                <Typography
                                    sx={{ color: 'red' }}
                                    variant="body2"
                                >
                                    {dayjs().format('hh:mma')}
                                </Typography>
                            </DataCell>
                            <DataCell></DataCell>
                            <DataCell>
                                <Typography
                                    sx={{ color: 'red' }}
                                    variant="body2"
                                >
                                    {selectedTech?.firstName +
                                        ' ' +
                                        selectedTech?.lastName}
                                </Typography>
                            </DataCell>
                            <DataCell>
                                <Typography></Typography>
                            </DataCell>
                            <DataCell></DataCell>
                        </TableRow>
                    ) : null}
                </DataTable>
            </DialogContent>
            <DialogActions>
                <RoleCheck
                    permission="edit_techTimeClock"
                    component={
                        <Button onClick={() => setReadOnly(false)}>
                            Edit Previous Times
                        </Button>
                    }
                />
                {readOnly ? (
                    <Button
                        variant="contained"
                        onClick={() =>
                            SubmitTechTimeClock(
                                new Date().toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }),
                                ServiceId,
                                selectedTech?.id,
                                refresh,
                                setRefresh
                            )
                        }
                    >
                        {clockStatus ? 'Clock On' : 'Clock Off'}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        onClick={() =>
                            UpdateTechTimeClock(
                                timeClocks,
                                refresh,
                                setRefresh,
                                setReadOnly
                            )
                        }
                    >
                        Update Time Clocks
                    </Button>
                )}
            </DialogActions>
        </>
    );
};

export default TechTimeClocks;
