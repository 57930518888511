import { Typography } from '@mui/material';
import { PaymentLine } from '../../interfaces/GeneralInterface';
import { CurrencyFormatter } from '../../logic/Formatters';
import DataCell from '../../tableComponents/DataCell';

interface ReceivedAmountProps {
    payment: PaymentLine;
}
const ReceivedAmount = ({ payment }: ReceivedAmountProps) => {
    return (
        <>
            <DataCell textDecoration={payment.removed ? 'line-through' : ''}>
                <Typography
                    variant="body2"
                    sx={{ color: payment.inactive ? '#808080' : '' }}
                >
                    {/* Added check for payment amount if amountReceived is not present (to handle legacy data) */}
                    {CurrencyFormatter(
                        Object.hasOwn(payment, 'amountReceived')
                            ? payment.amountReceived
                            : Object.hasOwn(payment, 'amount')
                              ? payment.amount
                              : payment.amountReceived
                    )}
                    {payment.type === 'cash' ? (
                        <>
                            <Typography
                                variant="body2"
                                sx={{ color: '#808080' }}
                            >
                                Change: {CurrencyFormatter(payment.change)}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ color: '#808080' }}
                            >
                                Tilled: {CurrencyFormatter(payment.amount)}
                            </Typography>
                        </>
                    ) : null}
                </Typography>
            </DataCell>
        </>
    );
};

export default ReceivedAmount;
