// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
interface StocktakeDetails {
    countedParts: any[];
    uncountedParts: any[];
    movedBins: any[];
    notes: string;
    review: boolean;
}

/*
    When a row is removed or restored this handles the state change as well as the position change
*/
const HandleRemoveRow = (
    stocktakeDetails: StocktakeDetails,
    setStocktakeDetails: Dispatch<SetStateAction<StocktakeDetails>>,
    UnitId: number,
    removing: boolean
) => {
    // Get the current line to edit
    let row = stocktakeDetails.countedParts.filter((x) => x.id === UnitId)[0];

    // Save a version of the lines where the line being latered has been filtered out
    // This is so we can place the line at the top or bottom of the array depending on
    // it's removed status.
    let filteredLines = stocktakeDetails.countedParts.filter(
        (x) => x.id !== UnitId
    );

    // Save the removed status
    row.removed = removing;

    // If we have removed the part put it at the bottom of the list
    // If we have restored the part put it at the top of the list
    if (removing) {
        filteredLines.push(row);
    } else {
        filteredLines.unshift(row);
    }

    // Set the states
    setStocktakeDetails({ ...stocktakeDetails, countedParts: filteredLines });
};

export default HandleRemoveRow;
