// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { BinLocation } from '../../../global/interfaces/PartsInterface';

interface StocktakeDetails {
    countedParts: any[];
    uncountedParts: any[];
    movedBins: any[];
    notes: string;
    review: boolean;
}

const HandleSubmitStocktake = (
    stocktakeDetails: StocktakeDetails,
    binLocationDetails: BinLocation,
    showSnackbar: showSnackbar
) => {
    let obj = {
        countedParts: stocktakeDetails.countedParts.filter((x) => !x.removed),
        uncountedParts: stocktakeDetails.uncountedParts,
        movedBins: stocktakeDetails.movedBins,
        notes: stocktakeDetails.notes,
        BinLocationId: binLocationDetails.id,
        BinLocationName: binLocationDetails.name
    };

    api.post(`newStocktake?SiteId=${localStorage.getItem('SiteId')}`, obj).then(
        (res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Stocktake completed.',
                    'The page will now refresh'
                );
                window.location.href = '/inventory/stocktake';
            } else {
                showSnackbar(
                    'Whoops! Something has gone wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        }
    );
};

export default HandleSubmitStocktake;
