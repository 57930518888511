// IMPORTS
import {
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    ReactNode
} from 'react';
import {
    Paper,
    Menu,
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogTitle,
    Badge,
    DialogContent,
    CircularProgress,
    MenuItem,
    Pagination
} from '@mui/material';
import { Search, FilterList, SortByAlpha } from '@mui/icons-material';

// LOGIC
import api from '../../../../../api';

// INTERFACES
import { Site, User } from '../../../global/interfaces/GeneralInterface';
import { Lead, Make } from '../../../global/interfaces/VehicleSaleInterface';
import { Dayjs } from 'dayjs';
interface FilterProps {
    assignedUser: User[];
    enquiryStatus: { type: string }[];
    make: Make[];
    sites: Site[];
    startDate: Dayjs;
    endDate: Dayjs;
}
interface LeadTableHeaderProps {
    route: string;
    searchLabel: string;
    searchString: string;
    setSearchString: Dispatch<SetStateAction<string>>;
    setDataList: Dispatch<SetStateAction<Lead[]>>;
    SiteId: number;
    filter: FilterProps;
    handleRequestCreate: () => string;
    isEnquirySelected: boolean;
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
    children: ReactNode;
}

const LeadTableHeader = (props: LeadTableHeaderProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [anchorElSettings, setAnchorElSettings] = useState(null);

    const [order, setOrder] = useState<string[]>(['id', 'DESC']);

    const options = [
        { id: 0, label: 'ID Ascending', value: ['id', 'ASC'] },
        { id: 1, label: 'ID Descending', value: ['id', 'DESC'] },
        {
            id: 2,
            label: 'Submitted Date Ascending',
            value: ['createdAt', 'ASC']
        },
        {
            id: 3,
            label: 'Submitted Date Descending',
            value: ['createdAt', 'DESC']
        },
        {
            id: 4,
            label: 'Last Update Date Ascending',
            value: ['updatedAt', 'ASC']
        },
        {
            id: 5,
            label: 'Last Update Date Descending',
            value: ['updatedAt', 'DESC']
        },
        {
            id: 6,
            label: 'Ideal Pickup Date Ascending',
            value: ['idealPickupDate', 'ASC']
        },
        {
            id: 7,
            label: 'Ideal Pickup Date Descending',
            value: ['idealPickupDate', 'DESC']
        },
        { id: 8, label: 'Customer Name A->Z', value: ['name', 'ASC'] },
        { id: 9, label: 'Customer Name Z->A', value: ['name', 'DESC'] }
    ];

    useEffect(() => {
        setLoading(true);
        var searchStringFilter = props.searchString
            ? `&keywords=${props.searchString}`
            : '';
        var filterString = props.handleRequestCreate();
        var apiString = `${props.route}?limit=20${searchStringFilter}&page=${page - 1}${filterString}&order=${order}`;

        api.get(apiString).then((res) => {
            setTotalPages(res.data.count);
            setPage(page);
            props.setDataList(res.data.rows);

            setLoading(false);
        });
        // eslint-disable-next-line
    }, [props.searchString, props.filter, page, order]);

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };

    const handleOpenSettings = (e) => {
        setAnchorElSettings(e.currentTarget);
    };

    const handleCloseSettings = () => {
        setAnchorElSettings(null);
    };
    return (
        <div>
            <Paper className="paper-padding">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            size="small"
                            label={props.searchLabel}
                            variant="outlined"
                            value={props.searchString}
                            onChange={(e) => {
                                props.setSearchString(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: <Search color="disabled" />
                            }}
                        />
                    </Grid>

                    <Grid textAlign="left" item xs={2}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <IconButton onClick={() => setOpen(true)}>
                                <FilterList />
                            </IconButton>
                        )}

                        <IconButton onClick={(e) => handleOpenSettings(e)}>
                            <Badge
                                invisible={order.length === 0 ? true : false}
                                color="primary"
                                variant="dot"
                            >
                                <SortByAlpha />
                            </Badge>
                        </IconButton>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElSettings}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open={Boolean(anchorElSettings)}
                            onClose={handleCloseSettings}
                        >
                            {options.map((option) => (
                                <MenuItem
                                    selected={order === option.value}
                                    key={option.id}
                                    value={option.value}
                                    onClick={() => {
                                        setOrder(option.value);
                                        handleCloseSettings();
                                    }}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>

                    <Grid textAlign="right" item xs={totalPages > 0 ? 6 : 1}>
                        <Pagination
                            count={Math.ceil(totalPages / 20)}
                            page={page}
                            onChange={handlePageChange}
                            sx={{ display: 'flex', justifyContent: 'right' }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="m-dialog"
            >
                <DialogTitle>Filter Results</DialogTitle>
                <DialogContent>{props.children}</DialogContent>
            </Dialog>
        </div>
    );
};

export default LeadTableHeader;
