import { Step } from '../../interface';

export const SubmitTillReconciliationSteps: Step[] = [
    {
        label: "Click 'Till Reconciliation' in the sidebar to access the Till Reconciliation page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-01.png'
    },
    {
        label: 'In the Reconciliation tab, select the Till you wish to reconcile.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-02.png'
    },
    {
        label: 'After selecting a Till, the display will updated to show the expected cash totals, other payment totals, and the discrepancy between the expected and counted totals.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-03.png'
    },
    {
        label: 'To examine the specific transactions in the Till, click the Till icon. This action will open the transactions drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-04.png'
    },
    {
        label: 'Choose a payment method to view all transactions created with that method for the current day.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-05.png'
    },
    {
        label: 'For transaction details, click on the transaction ID. You will be redirected to the Sale/Service/Vehicle Salee details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-06.png'
    },
    {
        label: 'Fill in the counted totals of each denomination in the Cash Denominations section, and add how many of each denominations is going into Banking (this will automatically calculate your float). You can also include a cashbag number for cash transactions and an envelope number for cheque transactions. Simply add the total amount for Other Payment Method. ',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-07.png'
    },
    {
        label: 'Optionally, you can add a note for each Payment Method.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-08.png'
    },
    {
        label: 'Click the Save button to finalize the Till Reconciliation. A confirmation message will be displayed, and the page will refresh.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-09.png'
    },
    {
        label: 'If a Till Reconciliation has been submitted for the current date, returning to the page will let you update the previously submitted Till Reconciliation.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-10.png'
    },
    {
        label: "If you were in the process of submitting a Till Reconciliation but another transactions was added, you don't need to refresh the page and restart from the beginning. Simply click the 'Refresh' icon to update the transaction list and expected totals.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-11.png'
    },
    {
        label: "To export the Till Reconciliation report, click the 'Export' button. The report will be generated in a PDF format and opened in a new tab.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-12.png'
    }
];

export const CheckTillReconciliationHistorySteps: Step[] = [
    {
        label: "Click 'Till Reconciliation' in the sidebar to navigate to the Till Reconciliation page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/till-rec-01.png'
    },
    {
        label: 'In the History tab, the calendar will display dates where some tills are missing a reconciliation in orange, while dates without any till reconciliation will be marked red (Sundays are ignored). Select the date of the Till Reconciliation you wish to view.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/check-till-02.png'
    },
    {
        label: 'You can edit the Till Reconciliation by clicking the Edit button. The rest of the process is the same as submitting a Till Reconciliation.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/check-till-03.png'
    },
    {
        label: 'Click the dial icon to view the transactions associated with the selected date’s Till Reconciliation.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/tillReconciliation/check-till-04.png'
    }
];
