import api from '../../../../../../api';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Dayjs } from 'dayjs';

const ReconcileAccountTransaction = (
    accountId: number,
    reference: string,
    selectedDate: Dayjs,
    gstType: string,
    bankFile,
    bankTransaction,
    showSnackbar: showSnackbar
) => {
    api.post('/reconcileAccountTransaction', {
        accountId: accountId,
        reference: reference,
        date: selectedDate.toISOString(),
        gstType: gstType,
        bankFile: bankFile,
        bankTransaction: bankTransaction
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Transaction Reconciled');
            setTimeout(() => window.location.reload(), 1000);
        } else {
            showSnackbar(
                'Something went wrong',
                'Please contact the Ora Development Team.',
                'error'
            );
        }
    });
};

export default ReconcileAccountTransaction;
