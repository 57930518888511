import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';

const initialCash = [
    {
        name: 'oneHundredDollars',
        value: 100,
        count: '',
        quantity: '',
        banking: ''
    },
    { name: 'fiftyDollars', value: 50, count: '', quantity: '', banking: '' },
    { name: 'twentyDollars', value: 20, count: '', quantity: '', banking: '' },
    { name: 'tenDollars', value: 10, count: '', quantity: '', banking: '' },
    { name: 'fiveDollars', value: 5, count: '', quantity: '', banking: '' },
    { name: 'twoDollars', value: 2, count: '', quantity: '', banking: '' },
    { name: 'oneDollar', value: 1, count: '', quantity: '', banking: '' },
    { name: 'fiftyCents', value: 0.5, count: '', quantity: '', banking: '' },
    { name: 'twentyCents', value: 0.2, count: '', quantity: '', banking: '' },
    { name: 'tenCents', value: 0.1, count: '', quantity: '', banking: '' },
    { name: 'fiveCents', value: 0.05, count: '', quantity: '', banking: '' }
];

const GetTillTransactions = (
    selectedTill: number,
    tillReconciliation: any,
    setTillReconciliation: Dispatch<SetStateAction<any>>,
    selectedDate?
) => {
    let query = '';

    if (selectedDate) {
        query = `&date=${selectedDate}`;
    }

    api.get(
        `tillTransactions/${selectedTill}?SiteId=${localStorage.getItem('SiteId')}${query}`
    ).then((res) => {
        let cash = res.data.cash ? res.data.cash : initialCash;
        let till = res.data.Till ? res.data.Till : null;

        setTillReconciliation({
            ...tillReconciliation,
            tillLines: res.data.tillLines,
            cash: [...cash],
            Till: till,
            cashbagNumber: res.data.cashbagNumber,
            envelopeNumber: res.data.envelopeNumber
        });
    });
};

export default GetTillTransactions;
