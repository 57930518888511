import { Step } from '../../interface';

export const AddVehicleInvoiceSteps: Step[] = [
    {
        label: "Open the 'Vehicle Sales' > 'Invoice Vehicles' from the sidebar to access the Invoice Vehicles page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/invoice-vehicles-01.png'
    },
    {
        label: 'Select a Creditor and click the "+" button to open the Add Vehicle dialog.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/invoice-vehicles-02.png'
    },
    {
        label: "Fill in all required fields. Note: Entering a Trust Receipt here is recommended to enable Trust Receipt invoicing for this vehicle; however, it can be added later. Click 'Add Vehicle' to proceed.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/invoice-vehicles-03.png'
    },
    {
        label: 'Repeat previous steps to add more vehicles as needed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/invoice-vehicles-04.png'
    },
    {
        label: "Once all vehicles are added, enter the document reference and date, ensuring the document total matches the entered total. Click 'Save' to submit the invoice.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/invoice-vehicles-05.png'
    },
    {
        label: 'A success message will confirm the successful submission of the invoice.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/invoice-vehicles-06.png'
    },
    {
        label: 'The invoice is now created and the vehicles can be found in the Vehicle Stock page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/invoice-vehicles-07.png'
    }
];

export const EditVehicleInvoiceSteps: Step[] = [
    {
        label: 'Access the submitted invoice under the Creditor Details page. Locate the creditor, go to the View Creditor page, and click on the Invoices tab to find all linked invoices, including the newly created Vehicle Invoice #26585 from the Add new Vehicle Invoice example. Click on the invoice ID to open the Invoice Details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/edit-invoice-01.png'
    },
    {
        label: 'On the Vehicle Invoice page, review the invoice details, including the list of vehicles on this invoice and any associated payments. To modify the Vehicle Invoice, click the Edit button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/edit-invoice-02.png'
    },
    {
        label: 'Remember to click the Save button to finalize and save all changes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/edit-invoice-03.png'
    },
    {
        label: 'To adjust the invoice lines, click on the Stock Number of a vehicle to access its Vehicle Details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/edit-invoice-04.png'
    },
    {
        label: 'In the Vehicle Details page, you can edit the Vehicle Details by clicking Edit Vehicle in the speed dial menu.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/edit-invoice-05.png'
    },
    {
        label: 'Add a Trust Receipt if it was not added during the initial invoice creation.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/edit-invoice-06.png'
    }
];

export const PayVehicleInvoiceSteps: Step[] = [
    {
        label: 'Navigate to the Admin -> Enter Invoice page and select the Trust Receipts tab. Locate the vehicle you wish to pay for using the stock number or vehicle VIN. For instance, search for stock number #1030737.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/pay-invoice-01.png'
    },
    {
        label: 'Upon selecting the vehicle, a payment details table will appear, showing the Vehicle Invoice summary and payment options. Enter the amount you are paying in the "Amount Paying" field, which can be either the full amount or a partial payment of Balance Due.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/pay-invoice-02.png'
    },
    {
        label: 'To make a payment, enter the document reference, document date, and the total amount on the document. Ensure that the document total matches the trust receipt totals. Click "Save" to submit the payment.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/pay-invoice-03.png'
    },
    {
        label: 'After submitting the payment, you can view the payment details on the vehicle details page. Clicking the "Unpaid" button will open the Trust Payment Details drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/pay-invoice-04.png'
    },
    {
        label: 'In the Trust Payment Details drawer, you can review all payment history associated with this vehicle.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/invoiceVehicles/pay-invoice-05.png'
    }
];
