//IMPORTS
import { useState, useEffect } from 'react';
import { TableRow, Typography } from '@material-ui/core';
import { TimestampFormatter } from '../../../global/logic/Formatters';
// COMPONENTS
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import PDFStocktakeReport from '../../../stock/stocktake/logic/PDFStocktakeReport';
// LOGIC
import GetStocktakes from '../logic/GetStocktakes';
import { Stocktake } from '../../../global/interfaces/PartsInterface';

interface StocktakeProps {
  partId: number;
  selectedSite: number;
}

const StocktakeHistory = ({ partId, selectedSite }: StocktakeProps) => {
  const [stocktakes, setStocktakes] = useState<Stocktake[]>([]);

  useEffect(() => {
    GetStocktakes(partId, selectedSite, setStocktakes);
  }, [partId, selectedSite]);

  const columns = [
    { id: 0, label: 'Stocktake ID' },
    { id: 1, label: 'Stocktake Date' },
    { id: 2, label: 'Bin Location' },
    { id: 3, label: 'Number of Parts' },
    { id: 4, label: 'User' }
  ];

  return (
    <>
      <Paper>
        {stocktakes && stocktakes.length > 0 ? (
          <DataTable columns={columns}>
            {stocktakes?.map((row) => (
              <TableRow key={row.id}>
                <DataCellColoured handleClick={() => PDFStocktakeReport(row)}>
                  {row.id}
                </DataCellColoured>
                <DataCell>{TimestampFormatter(row.createdAt)}</DataCell>
                <DataCell>{row.BinLocation?.name}</DataCell>
                <DataCell>{row.stocktakeLines.length}</DataCell>
                <DataCell>
                  {row.User?.firstName} {row.User?.lastName}
                </DataCell>
              </TableRow>
            ))}
          </DataTable>
        ) : (
          <Typography variant="body1" align="center">
            No stocktakes recorded for this part.
          </Typography>
        )}
      </Paper>
    </>
  );
};

export default StocktakeHistory;
