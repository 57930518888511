// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { CustomerVehicle } from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleAddCustomerVehicle = (
    selectedCustomerVehicle: CustomerVehicle,
    showSnackbar: showSnackbar,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    setSelectedCustomerVehicle: Dispatch<SetStateAction<CustomerVehicle>>,
    setVehicleAdded: Dispatch<SetStateAction<boolean>>
) => {
    if (!selectedCustomerVehicle.MakeId) {
        showSnackbar('You must select a make for the vehicle.', '', 'error');
        return;
    }
    if (!selectedCustomerVehicle.ModelId) {
        showSnackbar('You must select a model for the vehicle.', '', 'error');
        return;
    }
    if (!selectedCustomerVehicle.year) {
        showSnackbar(
            'You must select a year model for the vehicle.',
            '',
            'error'
        );
        return;
    }

    api.post(`addCustomerVehicle`, selectedCustomerVehicle).then((res) => {
        setSelectedCustomerVehicle({ ...res.data });
        showSnackbar('Customer vehicle successfully created.');
        setReadOnly(true);
        setVehicleAdded(true);
    });
};

export default HandleAddCustomerVehicle;
