import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import {
    DailyTaskList,
    User
} from '../../../global/interfaces/GeneralInterface';

interface GetDailyTaskListProps {
    currentSiteId: string;
    currentUser: User;
    setDailyTaskList: Dispatch<SetStateAction<DailyTaskList>>;
}

const GetDailyTaskList = (props: GetDailyTaskListProps) => {
    const { currentSiteId, currentUser, setDailyTaskList } = props;

    api.post('/setUpDailyTaskList', { currentSiteId, currentUser }).then(
        (res) => {
            setDailyTaskList(res.data);
        }
    );
};

export default GetDailyTaskList;