// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    TradeIn,
    Vehicle,
    VehicleSale,
    VehicleTransfer
} from '../../../global/interfaces/VehicleSaleInterface';
import { Invoice } from '../../../global/interfaces/PartsInterface';

interface VehicleMovement {
    invoices: Invoice[];
    trades: TradeIn[];
    transfers: VehicleTransfer[];
    sales: VehicleSale[];
}

const HandleDeleteVehicle = (
    VehicleId: number,
    vehicle: Vehicle,
    vehicleMovement: VehicleMovement,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    if (vehicle.trustPaid) {
        showSnackbar(
            'Unable to delete vehicle.',
            'Trust receipt has been paid.',
            'error'
        );
        setButtonLoading(false);
        return;
    }

    if (vehicleMovement.sales.length > 0) {
        showSnackbar(
            'Unable to delete vehicle.',
            'Vehicle is on a sale.',
            'error'
        );
        setButtonLoading(false);
        return;
    }

    if (vehicleMovement.transfers.length > 0) {
        let anyIncomplete = false;
        vehicleMovement.transfers.forEach((transfer) => {
            if (transfer.status !== 'COMPLETE') {
                anyIncomplete = true;
            }
        });

        if (anyIncomplete) {
            showSnackbar(
                'Unable to delete vehicle.',
                'Vehicle is on an incomplete transfer.',
                'error'
            );
            setButtonLoading(false);
            return;
        }
    }

    api.delete(`deleteVehicle/${VehicleId}`).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Vehicle Deleted Successfully',
                'The page will now refresh'
            );
            window.location.href = '/vehicles/stock';
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default HandleDeleteVehicle;
