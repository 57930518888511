// IMPORTS
import { TableRow, Typography, Divider, Chip } from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import {
    DateFormatter,
    CurrencyFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import { Order } from '../../../../global/interfaces/PartsInterface';

interface SalesProps {
    sales: Order[];
}

const Sales = ({ sales }: SalesProps) => {
    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Status' },
        { id: 2, label: 'Date' },
        { id: 3, label: 'Parts' },
        { id: 4, label: 'Location' },
        { id: 5, label: 'User' }
    ];

    const calculatePartTotal = (service) => {
        let total = 0;

        // Go through the added costs on the service first
        service.orderLines.forEach((cost) => {
            total += parseFloat(cost.totalPrice);
        });

        return total;
    };

    return (
        <Paper>
            <Typography variant="h5" sx={{ fontSize: '25px' }}>
                Sales
            </Typography>
            <br />
            <Divider />
            {!sales || sales.length === 0 ? (
                <Typography variant="body1">
                    <br />
                    No sales have been allocated to this vehicle.
                </Typography>
            ) : (
                <DataTable columns={columns}>
                    {sales.map((sale) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/inventory/viewSale/${sale.id}`
                                    )
                                }
                            >
                                {sale.id}
                            </DataCellColoured>
                            <DataCell>
                                <Chip
                                    variant="outlined"
                                    label={sale?.status}
                                    color={
                                        sale?.status === 'Sale'
                                            ? 'success'
                                            : sale?.status === 'Proforma'
                                              ? 'warning'
                                              : 'error'
                                    }
                                />
                            </DataCell>
                            <DataCell>
                                {sale.status === 'Sale'
                                    ? DateFormatter(sale.finalisedAt)
                                    : DateFormatter(sale.createdAt)}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(calculatePartTotal(sale))}
                            </DataCell>
                            <DataCell>{sale?.Site?.name}</DataCell>
                            <DataCell>
                                {sale?.User?.firstName} {sale?.User?.lastName}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            )}
        </Paper>
    );
};

export default Sales;
