import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const ImportCustomerFromCSV = (
    importedData,
    headers,
    ignoreErrors,
    showSnackbar: showSnackbar
) => {
    let proceed = true;
    let formattedArray = [];
    let counter = 0;
    // Go through each line of the CSV
    for (let [id, item] of importedData.entries()) {
        let tempObject = {
            firstName: null,
            lastName: null,
            companyName: null
        };

        // Go through each header/columns
        for (let [index, header] of headers.entries()) {
            tempObject[header] = item[index];
        }

        // Check that we have a first name last name combo or a company name
        // If user ticked ignore errors checkbox, ignore incorrect lines
        if (
            (tempObject.firstName.length > 0 &&
                tempObject.lastName.length > 0) ||
            tempObject.companyName.length > 0
        ) {
            formattedArray.push(tempObject);
        } else if (ignoreErrors) {
            formattedArray.push(tempObject);
        } else {
            proceed = false;
            showSnackbar(
                'A Customer has invalid data. Each Customer must have: a first name and a last name OR a company name ',
                `Found at line ${id}. First Name: ${tempObject.firstName ?? ''}, Last Name: ${tempObject.lastName ?? ''}, Company Name: ${tempObject.companyName ?? ''}`,
                'error'
            );
            return;
        }
    }

    if (proceed) {
        api.post('importCustomerCSV', formattedArray).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Customer(s) successfully imported. Ignored ' +
                        counter +
                        ' lines.',
                    'The page will now refresh.'
                );
                setTimeout(() => window.location.reload(), 1500);
            }
        });
    }
};
