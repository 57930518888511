import dayjs from 'dayjs';
import { useState } from 'react';
import { Grid, Stack, TextField, useTheme, Typography } from '@mui/material';
import { Build, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
// LOGIC
import GetCalendarData from '../testLogic/GetCalendarData';

const CalendarToolbar = (props) => {
    const [tempString, setTempString] = useState<string>(null);
    const currentTheme = useTheme();

    const keywordSearch = (string: string) => {
        props.setSearchString(string);
        GetCalendarData(
            props.view,
            props.date,
            props.selectedStatus,
            string,
            props.setListOfJobs,
            props.techList,
            props.setTechArray
        );
    };

    const changeStatus = (newStatus: string[]) => {
        props.setSelectedStatus(newStatus);
        GetCalendarData(
            props.view,
            props.date,
            newStatus,
            '',
            props.setListOfJobs,
            props.techList,
            props.setTechArray
        );
    };

    const navigate = (action: string) => {
        if (action === 'PREVIOUS') {
            if (props.view === 'month') {
                let newDate = dayjs(props.date).subtract(1, 'month');
                GetCalendarData(
                    props.view,
                    newDate,
                    props.selectedStatus,
                    '',
                    props.setListOfJobs
                );
                props.setDate(newDate.toDate());
            } else if (props.view === 'fortnight' || props.view === 'tech') {
                let newDate = dayjs(props.date).subtract(14, 'days');
                GetCalendarData(
                    props.view,
                    newDate,
                    props.selectedStatus,
                    '',
                    props.setListOfJobs,
                    props.techList,
                    props.setTechArray
                );
                props.setDate(newDate.toDate());
            }
        } else if (action === 'NEXT') {
            if (props.view === 'month') {
                let newDate = dayjs(props.date).add(1, 'month');
                GetCalendarData(
                    props.view,
                    newDate,
                    props.selectedStatus,
                    '',
                    props.setListOfJobs
                );
                props.setDate(newDate.toDate());
            } else if (props.view === 'fortnight' || props.view === 'tech') {
                let newDate = dayjs(props.date).add(14, 'days');
                GetCalendarData(
                    props.view,
                    newDate,
                    props.selectedStatus,
                    '',
                    props.setListOfJobs,
                    props.techList,
                    props.setTechArray
                );
                props.setDate(newDate.toDate());
            }
        }
    };

    const handleViewChange = (view: string) => {
        props.setSelectedView(view);
        GetCalendarData(
            view,
            props.date,
            props.selectedStatus,
            '',
            props.setListOfJobs,
            props.techList,
            props.setTechArray
        );
    };

    return (
        <div className="rbc-toolbar">
            <Grid container spacing={1} textAlign="center">
                <Grid item xs={3}>
                    <TextField
                        // size="small"
                        fullWidth
                        sx={{
                            margin: 0.5,
                            '& .MuiOutlinedInput-root': {
                                backgroundColor:
                                    currentTheme.palette.mode === 'dark'
                                        ? '#656565'
                                        : 'white'
                            }
                        }}
                        placeholder="Customer name, phone number, job description"
                        value={tempString}
                        onChange={(e) => {
                            setTempString(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                keywordSearch(tempString);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid container item xs={4}>
                    <Grid item xs={12}>
                        <button
                            type="button"
                            onClick={() => navigate('PREVIOUS')}
                        >
                            Previous
                        </button>
                        <span className="rbc-toolbar-label">{props.label}</span>
                        <button type="button" onClick={() => navigate('NEXT')}>
                            Next
                        </button>
                    </Grid>
                    <Grid item xs={12}>
                        <span className="rbc-btn-group">
                            {props.views.map((view) => (
                                <button
                                    type="button"
                                    key={view}
                                    className={
                                        view === props.view ? 'rbc-active' : ''
                                    }
                                    onClick={() => handleViewChange(view)}
                                >
                                    {`${view.charAt(0).toUpperCase()}${view.substring(1)}`}
                                </button>
                            ))}
                        </span>
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    {props.view === 'tech' ? (
                        <button
                            type="button"
                            onClick={() => {
                                props.setOpenDrawer(true);
                            }}
                        >
                            <Build />
                        </button>
                    ) : null}
                </Grid>
                <Grid item xs={3}>
                    <Stack
                        alignItems={'center'}
                        direction={'row'}
                        sx={{ fontSize: 15, marginLeft: 3 }}
                    >
                        {/* INCOMPLETE STATUS CHECKBOX */}
                        {props.selectedStatus.includes('Incomplete') ? (
                            <CheckBox
                                sx={{
                                    fontSize: 18,
                                    color: 'rgb(102, 127, 161)',
                                    cursor: 'pointer',
                                    marginRight: '3px'
                                }}
                                onClick={() => {
                                    let newStatus = [...props.selectedStatus];
                                    newStatus = newStatus.filter(
                                        (x) => x !== 'Incomplete'
                                    );

                                    changeStatus(newStatus);
                                }}
                            />
                        ) : (
                            <CheckBoxOutlineBlank
                                sx={{
                                    fontSize: 18,
                                    color: 'rgb(102, 127, 161)',
                                    cursor: 'pointer',
                                    marginRight: '3px'
                                }}
                                onClick={() => {
                                    let newStatus = [...props.selectedStatus];
                                    newStatus.push('Incomplete');

                                    changeStatus(newStatus);
                                }}
                            />
                        )}
                        <Typography
                            variant="body1"
                            sx={{
                                cursor: 'pointer',
                                color:
                                    currentTheme.palette.mode === 'dark'
                                        ? 'white'
                                        : 'black'
                            }}
                            onClick={() => {
                                let newStatus = [...props.selectedStatus];
                                if (
                                    props.selectedStatus.includes('Incomplete')
                                ) {
                                    newStatus = newStatus.filter(
                                        (x) => x !== 'Incomplete'
                                    );
                                } else {
                                    newStatus.push('Incomplete');
                                }
                                changeStatus(newStatus);
                            }}
                        >
                            Incomplete
                        </Typography>
                        {/* COMPLETE STATUS CHECKBOX */}
                        {props.selectedStatus.includes('Complete') ? (
                            <CheckBox
                                sx={{
                                    fontSize: 18,
                                    color: '#f57c00',
                                    cursor: 'pointer',
                                    marginLeft: '6px',
                                    marginRight: '3px'
                                }}
                                onClick={() => {
                                    let newStatus = [...props.selectedStatus];
                                    newStatus = newStatus.filter(
                                        (x) => x !== 'Complete'
                                    );

                                    changeStatus(newStatus);
                                }}
                            />
                        ) : (
                            <CheckBoxOutlineBlank
                                sx={{
                                    fontSize: 18,
                                    color: '#f57c00',
                                    cursor: 'pointer',
                                    marginLeft: '6px',
                                    marginRight: '3px'
                                }}
                                onClick={() => {
                                    let newStatus = [...props.selectedStatus];
                                    newStatus.push('Complete');

                                    changeStatus(newStatus);
                                }}
                            />
                        )}
                        <Typography
                            variant="body1"
                            sx={{
                                cursor: 'pointer',
                                color:
                                    currentTheme.palette.mode === 'dark'
                                        ? 'white'
                                        : 'black'
                            }}
                            onClick={() => {
                                let newStatus = [...props.selectedStatus];
                                if (props.selectedStatus.includes('Complete')) {
                                    newStatus = newStatus.filter(
                                        (x) => x !== 'Complete'
                                    );
                                } else {
                                    newStatus.push('Complete');
                                }
                                changeStatus(newStatus);
                            }}
                        >
                            Complete
                        </Typography>
                        {/* FINALISED STATUS CHECKBOX */}
                        {props.selectedStatus.includes('Finalised') ? (
                            <CheckBox
                                sx={{
                                    fontSize: 18,
                                    color: '#3fb73f',
                                    cursor: 'pointer',
                                    marginLeft: '6px',
                                    marginRight: '3px'
                                }}
                                onClick={() => {
                                    let newStatus = [...props.selectedStatus];
                                    newStatus = newStatus.filter(
                                        (x) => x !== 'Finalised'
                                    );

                                    changeStatus(newStatus);
                                }}
                            />
                        ) : (
                            <CheckBoxOutlineBlank
                                sx={{
                                    fontSize: 18,
                                    color: '#3fb73f',
                                    cursor: 'pointer',
                                    marginLeft: '6px',
                                    marginRight: '3px'
                                }}
                                onClick={() => {
                                    let newStatus = [...props.selectedStatus];
                                    newStatus.push('Finalised');

                                    changeStatus(newStatus);
                                }}
                            />
                        )}
                        <Typography
                            variant="body1"
                            sx={{
                                cursor: 'pointer',
                                color:
                                    currentTheme.palette.mode === 'dark'
                                        ? 'white'
                                        : 'black'
                            }}
                            onClick={() => {
                                let newStatus = [...props.selectedStatus];
                                if (
                                    props.selectedStatus.includes('Finalised')
                                ) {
                                    newStatus = newStatus.filter(
                                        (x) => x !== 'Finalised'
                                    );
                                } else {
                                    newStatus.push('Finalised');
                                }
                                changeStatus(newStatus);
                            }}
                        >
                            Finalised
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    );
};

export default CalendarToolbar;
