// IMPORTS
import { useEffect, useState } from 'react';
import { Chip, Grid, Pagination, TableRow, Typography } from '@mui/material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import Paper from '../../../global/Paper';
import DataCell from '../../../global/tableComponents/DataCell';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import GetAllInvoices from '../logic/GetAllInvoices';
// INTERFACES
import { Invoice } from '../../../global/interfaces/PartsInterface';

interface CreditInvoicesProps {
    CreditorId: number;
}

// Constant rows per page variable
const rowsPerPage = 10;

const CreditInvoices = ({ CreditorId }: CreditInvoicesProps) => {
    const [creditInvoices, setCreditInvoices] = useState<Invoice[]>([]);
    const [page, setPage] = useState(1);

    const columns = [
        { id: 0, label: 'Invoice ID', width: 100 },
        { id: 1, label: 'Status', width: 100 },
        { id: 2, label: 'Document Reference', width: 200 },
        { id: 3, label: 'Document Date', width: 100 },
        { id: 4, label: 'Document Total', width: 150 },
        { id: 5, label: 'User', width: 200 },
        { id: 6, label: 'Last Payment Date', width: 100 }
    ];

    useEffect(() => {
        GetAllInvoices(CreditorId, 'credit', setCreditInvoices);
    }, [CreditorId]);

    // Handle changing the page
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Typography variant="h5">Credit Invoices</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Pagination
                        color="primary"
                        count={
                            creditInvoices.length <= rowsPerPage
                                ? 1
                                : Math.ceil(creditInvoices.length / rowsPerPage)
                        }
                        page={page}
                        onChange={handleChange}
                        sx={{ display: 'flex', justifyContent: 'right' }}
                    />
                </Grid>
            </Grid>
            <br />
            <DataTable columns={columns}>
                {creditInvoices
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((invoice) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/inventory/creditInvoice/view/${invoice.AssociatedInvoice?.id}`,
                                        '_blank'
                                    )
                                }
                            >
                                {invoice.id}
                            </DataCellColoured>
                            <DataCell>
                                <Chip
                                    variant="outlined"
                                    label={invoice.status}
                                    color={
                                        invoice.status === 'Paid'
                                            ? 'success'
                                            : 'error'
                                    }
                                />
                            </DataCell>
                            <DataCell>
                                {invoice.AssociatedInvoice?.documentReference}
                            </DataCell>
                            <DataCell>
                                {invoice.AssociatedInvoice?.invoiceDate
                                    ? DateFormatter(
                                          invoice.AssociatedInvoice?.invoiceDate
                                      )
                                    : DateFormatter(invoice.createdAt)}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    invoice.AssociatedInvoice?.documentTotal
                                )}
                            </DataCell>
                            <DataCell>
                                {invoice.User?.firstName}{' '}
                                {invoice.User?.lastName}
                            </DataCell>
                            <DataCell>
                                {invoice.paymentDate
                                    ? DateFormatter(invoice.paymentDate)
                                    : ''}
                            </DataCell>
                        </TableRow>
                    ))}
            </DataTable>
        </Paper>
    );
};

export default CreditInvoices;
