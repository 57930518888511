import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleEditOnlinePayment = (
    paymentManagementData,
    showSnackbar: showSnackbar
) => {
    api.put('onlinePayment', paymentManagementData).then((res) => {
        if (res.status === 200) {
            showSnackbar('Data successfully updated.');
            setTimeout(() => window.location.reload(), 1000);
        } else {
            showSnackbar(
                'Something went wrong.',
                'Please contact the Ora development team.',
                'error'
            );
        }
    });
};

export default HandleEditOnlinePayment;
