// IMPORTS
import { Typography } from '@mui/material';
import { Launch } from '@mui/icons-material';
import moment from 'moment';
// COMPONENTS
import Fab from '../../../../../global/Fab';
// LOGIC
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';

interface ServiceDirectDepositContentProps {
  selectedObject;
}

const ServiceDirectDepositContent = ({
  selectedObject
}: ServiceDirectDepositContentProps) => {
  return (
    <>
      <Typography variant="body1">
        <b>Customer:</b> {selectedObject.reference}
      </Typography>
      <Typography variant="body1">
        <b>Vehicle:</b> {selectedObject.vehicleName}
      </Typography>
      <Typography variant="body1">
        <b>VIN:</b> {selectedObject.vehicleVin}
      </Typography>
      <br />
      <Typography variant="body1">
        <b>Amount:</b> {CurrencyFormatter(selectedObject.amount)}
      </Typography>
      <Typography variant="body1">
        <b>Date Paid:</b> {selectedObject.date}{' '}
        {moment(selectedObject.timestamp).format('h:MM A')}
      </Typography>
      {selectedObject.submittedBy && (
        <Typography variant="body1">
          <b>Submitted by:</b> {selectedObject.submittedBy}
        </Typography>
      )}

      <Fab
        noSession={true}
        customIcon={<Launch />}
        onClick={() =>
          window.open(
            `/service/viewBooking/${selectedObject.ServiceId}`,
            '_blank'
          )
        }
      />
    </>
  );
};

export default ServiceDirectDepositContent;
