// IMPORTS
import {
    Badge,
    Checkbox,
    Grid,
    Pagination,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Balance, CheckBox } from '@mui/icons-material';
// COMPONENTS
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import Paper from '../../../global/Paper';
import TableSearch from '../../../global/tableComponents/TableSearch';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import HandleReadyInvoices from '../logic/HandleReadyInvoices';
import PDFCreditorBalanceReport from '../logic/PDFCreditorBalanceReport';
import {
    CurrencyFormatter,
    DateFormatter,
    TitleCaseFormatter,
    ToFixed
} from '../../../global/logic/Formatters';
import {
    CalculateAmountRemaining,
    GroupInvoices,
    FilterInvoices,
    PaginateInvoices,
    FilterAndPaginate
} from '../logic/InvoiceSorting';

interface SelectedCreditor {
    CreditorId: number;
    name: string;
    rows: any[];
}

interface CreditorReconciliationProps {
    setReadyInvoices: Dispatch<SetStateAction<any[]>>;
    readyInvoices?: any[];
    paymentStatus: string;
    showSnackbar: showSnackbar;
}

/**
 * SelectInvoices
 * All content shown in both Unconfirmed and Ready Invoices tabs
 * @author Estienne
 * @param CreditorReconciliationProps
 */
const SelectInvoices = ({
    setReadyInvoices,
    readyInvoices,
    paymentStatus,
    showSnackbar
}: CreditorReconciliationProps) => {
    const [sort, setSort] = useState(['date', 'ASC']);
    const [resultsList, setResultsList] = useState([]);
    const [selectedSite, setSelectedSite] = useState<string>('');
    const [groupedInvoices, setGroupedInvoices] = useState([]); // Invoices grouped by site
    const [paginatedInvoices, setPaginatedInvoices] = useState([]); // Paginated filtered invoices
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [selectedCreditor, setSelectedCreditor] = useState<SelectedCreditor>({
        CreditorId: null,
        name: null,
        rows: []
    });
    const [totalForFoundInvoices, setTotalForFoundInvoices] =
        useState<number>(0);
    const [numberOfCreditors, setNumberOfCreditors] = useState<number>(0);

    const creditorColumns = [
        { id: 0, label: 'Creditor Name' },
        { id: 1, label: 'Total Amount' },
        { id: 2, label: 'Number of Invoices' }
    ];

    const invoiceColumns = [
        {
            id: 0,
            label: selectedCreditor.CreditorId ? (
                <Checkbox
                    checked={allChecked}
                    onChange={(e) => handleCheckAll(e.target.checked)}
                />
            ) : null
        },
        { id: 1, label: 'Invoice ID' },
        { id: 2, label: 'Invoice Date', name: 'date', sort: true },
        { id: 3, label: 'Document Reference' },
        { id: 4, label: 'Submitted By' },
        { id: 5, label: 'Invoice Total' },
        { id: 6, label: 'Payment Remaining' }
    ];

    const actionsList = [
        {
            icon: <CheckBox />,
            name:
                paymentStatus === 'notready'
                    ? 'Ready Selected Invoices'
                    : 'Un-ready Selected Invoices',
            onClick: () =>
                HandleReadyInvoices(
                    selectedInvoices,
                    paymentStatus,
                    showSnackbar
                )
        },
        {
            icon: <Balance />,
            name: 'Generate Creditor Balance Report',
            onClick: () => PDFCreditorBalanceReport()
        }
    ];

    // If navigating back to Ready Invoices from Finalise & Pay
    // or Creditor Payments, make sure any previously-selected
    // invoices are still selected
    useEffect(() => {
        if (paymentStatus === 'ready') {
            setSelectedInvoices(readyInvoices);
        }
        // eslint-disable-next-line
    }, []);

    // Upon retrieving all creditors and their invoices,
    // set selectedCreditor to the first one in the list
    useEffect(() => {
        if (selectedCreditor.name == null && resultsList?.length > 0) {
            setSelectedCreditor(resultsList[0]);
        } else if (resultsList.length > 0) {
            let index = resultsList.findIndex(
                (x) => x.CreditorId === selectedCreditor.CreditorId
            );
            let creditor = index !== -1 ? resultsList[index] : resultsList[0];
            setSelectedCreditor(creditor);
        }
    }, [resultsList, selectedCreditor]);

    // When a new creditor is selected, group their invoices by site
    useEffect(() => {
        setGroupedInvoices(GroupInvoices([...selectedCreditor.rows], 'SiteId'));
        setSelectedSite(''); // Reset the site
    }, [selectedCreditor]);

    // When invoices are grouped, set the selected
    // site to the first one in the group
    useEffect(() => {
        // Filter out all sites with no invoices
        if (groupedInvoices.length > 0) {
            setSelectedSite(
                groupedInvoices.filter((arg) => arg != null)[0][0].Site.name
            );
        }
    }, [groupedInvoices]);

    // When a site is selected, filter and paginate the invoices
    useEffect(() => {
        if (selectedSite !== '') {
            setCurrentPage(1); // Reset the current page
            FilterAndPaginate(
                selectedSite,
                groupedInvoices,
                1,
                setPageCount,
                setPaginatedInvoices
            );
        }
        // eslint-disable-next-line
    }, [selectedSite]);

    // When a site is selected, filter and paginate the invoices
    useEffect(() => {
        checkAllChecked();
        // eslint-disable-next-line
    }, [selectedInvoices, selectedCreditor, selectedSite]);

    /**
     * getPaymentAmount
     * Get the payment amount for a paginated invoice
     * @author Estienne
     * @param invoice a given invoice
     * @returns the invoice's payment amount
     */
    const getPaymentAmount = (invoice: any) => {
        let readiedInvoice = selectedInvoices.filter(
            (x) => x.id === invoice.id
        );
        if (readiedInvoice.length === 1) {
            return readiedInvoice[0].paymentAmount;
        }

        // Otherwise, paymentAmount is likely null - return 0
        else {
            return 0;
        }
    };

    /**
     * checkRowChecked
     * Check if a single invoice line is checked
     * @author Estienne
     * @param invoice the invoice line
     * @returns non-null boolean
     */
    const checkRowChecked = (invoice: any) => {
        let foundInvoices = [...selectedInvoices].filter(
            (x) => x.id === invoice.id
        );
        if (foundInvoices.length === 0) {
            return false;
        } else {
            return true;
        }
    };

    /**
     * checkAllChecked
     * Check if all invoices for a site are checked
     * @author Estienne
     * @returns non-null boolean
     */
    const checkAllChecked = () => {
        let siteInvoices = FilterInvoices(selectedSite, groupedInvoices);
        let checkedInvoices = [];
        siteInvoices.forEach((invoice) => {
            if (checkRowChecked(invoice) === true) {
                checkedInvoices.push(invoice);
            }
        });

        if (
            checkedInvoices.length === siteInvoices.length &&
            siteInvoices.length !== 0
        ) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
    };

    /**
     * badgeCheck
     * Check if a creditor has any checked invoice lines
     * @author Estienne
     * @param creditor a single creditor
     * @returns non-null boolean
     */
    const badgeCheck = (creditor: SelectedCreditor) => {
        let creditorBadge = true;
        creditor?.rows.forEach((invoice) => {
            let checkedRow = [...selectedInvoices].filter(
                (x) => x.id === invoice.id
            );
            if (checkedRow.length > 0) {
                creditorBadge = false;
            }
        });
        return creditorBadge;
    };

    /**
     * handleCheckRow
     * Add/remove an invoice from unconfirmed invoices
     * @author Estienne
     * @param checkValue the state of the checkbox
     * @param invoiceId the invoice being checked/unchecked
     */
    const handleCheckRow = (checkValue: boolean, invoice: any) => {
        let tempInvoices = [...selectedInvoices];
        // Select the invoice if it isn't already selected
        if (checkValue === true && checkRowChecked(invoice) === false) {
            // If the ready invoice is being selected via checkbox,
            // set paymentAmount to the invoice total
            if (paymentStatus === 'ready') {
                if (
                    invoice.paymentAmount === 0 ||
                    invoice.paymentAmount === null
                ) {
                    invoice.paymentAmount =
                        CalculateAmountRemaining(
                            invoice.Invoice.documentTotal,
                            0,
                            invoice.paymentHistory
                        ) > 0
                            ? CalculateAmountRemaining(
                                  invoice.Invoice.documentTotal,
                                  0,
                                  invoice.paymentHistory
                              )
                            : invoice.Invoice.documentTotal;
                }
            }
            tempInvoices.push(invoice);
        }

        // Unselect the invoice if it is already selected
        else if (checkValue === false && checkRowChecked(invoice) === true) {
            tempInvoices = tempInvoices.filter((x) => x.id !== invoice.id);

            // Set the paymentAmount back to 0 if on 'Ready Invoices' tab
            if (paymentStatus === 'ready') {
                invoice.paymentAmount = 0;
            }
        }

        tempInvoices[tempInvoices.findIndex((x) => x.id === invoice.id)] =
            invoice;
        setSelectedInvoices(tempInvoices);
        if (paymentStatus === 'ready') {
            setReadyInvoices(tempInvoices);
        }
    };

    /**
     * handleCheckAll
     * Check all invoices for a site
     * @author Estienne
     * @param checkValue the state of the checkbox
     */
    const handleCheckAll = (checkValue: boolean) => {
        let siteInvoices = FilterInvoices(selectedSite, groupedInvoices);
        let tempInvoices = [...selectedInvoices];

        siteInvoices.forEach((invoice) => {
            if (checkValue === true) {
                if (paymentStatus === 'ready') {
                    invoice.paymentAmount = invoice.Invoice.documentTotal;
                }

                // Check if current invoice is already in the selectedInvoice
                let invoiceIsIn = selectedInvoices.filter(
                    (x) => x.id === invoice.id
                );
                if (invoiceIsIn.length === 0) {
                    tempInvoices.push(invoice);
                }
            } else {
                if (paymentStatus === 'ready') {
                    invoice.paymentAmount = 0;
                }
                tempInvoices = tempInvoices.filter((x) => x.id !== invoice.id);
            }
        });

        setSelectedInvoices(tempInvoices);
        if (paymentStatus === 'ready') {
            setReadyInvoices(tempInvoices);
        }
    };

    /**
     * handleSiteChange
     * Change the selected site when a ToggleButton is clicked
     * @author Estienne
     * @param event the recorded interaction with the ToggleButton
     * @param site the site being selected
     */
    const handleSiteChange = (
        event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        site: string | null
    ) => {
        setSelectedSite(site);
    };

    /**
     * handlePageChange
     * Change the page of results
     * @author Estienne
     * @param event the page change event
     * @param value the new page number
     */
    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value);
        PaginateInvoices(
            FilterInvoices(selectedSite, groupedInvoices),
            value,
            setPageCount,
            setPaginatedInvoices
        );
    };

    /**
     * handlePaymentAmount
     * Change the payment amount
     * @author Estienne
     * @param event the text input action from the user
     * @param invoice the affected invoice
     */
    const handlePaymentAmount = (event: any, invoice: any) => {
        invoice.paymentAmount = event.target.value;

        // Check the row if we are
        // paying a valid amount
        if (invoice.paymentAmount === 0) {
            handleCheckRow(false, invoice);
        } else {
            handleCheckRow(true, invoice);
        }
    };

    /**
     * showSiteButtons
     * Render toggle buttons for each site that
     * a creditor has invoices for
     * @author Estienne
     * @returns {JSX} the toggle buttons
     */
    const showSiteButtons = () => {
        return (
            <>
                <ToggleButtonGroup
                    color="primary"
                    size="small"
                    exclusive
                    fullWidth
                    value={selectedSite}
                    onChange={handleSiteChange}
                >
                    {groupedInvoices.map((row) => (
                        <ToggleButton value={row[0].Site.name}>
                            {row[0].Site.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                <br />
            </>
        );
    };

    /**
     * showRows
     * Render all unpaid invoices that
     * a creditor has for a site
     * @author Estienne
     * @returns {JSX} the site's unconfirmed invoices
     */
    const showRows = () => {
        return (
            <DataTable
                columns={
                    paymentStatus === 'notready'
                        ? invoiceColumns
                        : [
                              ...invoiceColumns,
                              { id: 7, label: 'Payment Amount' }
                          ]
                }
                sort={sort}
                setSort={setSort}
            >
                {paginatedInvoices.map((line) => (
                    <TableRow>
                        <DataCell>
                            <Checkbox
                                size="small"
                                checked={checkRowChecked(line)}
                                onChange={(e) =>
                                    handleCheckRow(e.target.checked, line)
                                }
                            />
                        </DataCell>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(
                                    line.CreditInvoiceId
                                        ? `inventory/creditInvoice/view/${line.CreditInvoiceId}`
                                        : `inventory/viewInvoice/${line.id}`
                                )
                            }
                        >
                            {line.id}
                        </DataCellColoured>
                        <DataCell>{DateFormatter(line.invoiceDate)}</DataCell>
                        <DataCell>{line.Invoice.documentReference}</DataCell>
                        <DataCell>{`${line.User.firstName} ${line.User.lastName}`}</DataCell>
                        <DataCell>
                            {CurrencyFormatter(line.Invoice.documentTotal)}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(
                                CalculateAmountRemaining(
                                    parseFloat(line.Invoice.documentTotal),
                                    line.paymentAmount,
                                    line.paymentHistory
                                )
                            )}
                        </DataCell>
                        {paymentStatus === 'ready' ? (
                            <DataCell>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="number"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'center' }
                                    }}
                                    value={getPaymentAmount(line)}
                                    onChange={(e) =>
                                        handlePaymentAmount(e, line)
                                    }
                                />
                            </DataCell>
                        ) : null}
                    </TableRow>
                ))}
            </DataTable>
        );
    };

    /**
     * Returns the total for the selected invoices
     * @author Pierre
     * @returns {number} the total of all selected invoices
     */
    const getTotalSelectedInvoices = () => {
        let total = 0;
        for (let line of selectedInvoices) {
            total += parseFloat(line.Invoice.documentTotal);
        }
        return total;
    };

    /**
     * Returns the total payment for the selected invoices
     * @author Pierre
     * @returns {number} the total of all payment for selected invoices
     */
    const getTotalPaymentForSelectedInvoices = () => {
        let total = 0;
        for (let line of selectedInvoices) {
            total += parseFloat(line.paymentAmount);
        }
        return total;
    };

    /**
     * Returns the total for the left table rows
     * @author Pierre
     * @returns {number}
     */
    const getTotalForSingleCreditor = (row) => {
        let total = 0;
        for (let line of row.rows) {
            total +=
                CalculateAmountRemaining(
                    parseFloat(line.Invoice.documentTotal),
                    line.paymentAmount,
                    line.paymentHistory
                ) > 0
                    ? CalculateAmountRemaining(
                          parseFloat(line.Invoice.documentTotal),
                          line.paymentAmount,
                          line.paymentHistory
                      )
                    : parseFloat(line.Invoice.documentTotal);
        }
        return ToFixed(total);
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Invoices"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                clearedFilter={{}}
                sort={sort}
                customLimit={15}
                handleRequestCreate={() => {
                    if (paymentStatus === 'notready') {
                        return '&paymentStatus=notready';
                    } else {
                        return '&paymentStatus=ready';
                    }
                }}
                route="searchCreditorInvoices"
                setResultsList={setResultsList}
                setTotal={setTotalForFoundInvoices}
                setNumberOfItems={setNumberOfCreditors}
            />

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Paper>
                        <DataTable
                            columns={creditorColumns}
                            showSpeedDial={true}
                            actionsList={actionsList}
                        >
                            {resultsList.map((result) => (
                                <TableRow
                                    selected={
                                        result.CreditorId ===
                                        selectedCreditor.CreditorId
                                    }
                                >
                                    <DataCellColoured
                                        handleClick={() =>
                                            setSelectedCreditor(result)
                                        }
                                    >
                                        {TitleCaseFormatter(result.name)}
                                    </DataCellColoured>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            getTotalForSingleCreditor(result)
                                        )}
                                    </DataCell>
                                    <DataCell>
                                        {result.rows.length}
                                        &nbsp;&nbsp;
                                        <Badge
                                            color="primary"
                                            variant="dot"
                                            invisible={badgeCheck(result)}
                                        />
                                    </DataCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <DataCell colSpan={2}>
                                    Total for the {numberOfCreditors} creditors
                                    in this list:
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(totalForFoundInvoices)}
                                </DataCell>
                            </TableRow>
                        </DataTable>
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} alignItems="top">
                            <Paper>
                                <Grid container>
                                    <Grid item xs={7}>
                                        <Typography variant="h5">
                                            {selectedCreditor.name}
                                        </Typography>
                                        {getTotalSelectedInvoices() > 0
                                            ? `Total for selected invoices: ${CurrencyFormatter(getTotalSelectedInvoices())}`
                                            : null}
                                        <br />
                                        {getTotalPaymentForSelectedInvoices() >
                                        0
                                            ? `Total for payment selected invoices: ${CurrencyFormatter(getTotalPaymentForSelectedInvoices())}`
                                            : null}
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Pagination
                                            count={pageCount}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            color="primary"
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'right'
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                {showSiteButtons()}
                                <br />
                                {showRows()}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SelectInvoices;
