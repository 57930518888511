// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';

const UpdateTechTimeClock = (
  timeClocks,
  refresh: boolean,
  setRefresh: Dispatch<SetStateAction<boolean>>,
  setReadOnly: Dispatch<SetStateAction<boolean>>
) => {
  api.put(`updateTechTimeClock`, timeClocks).then((res) => {
    if (res.status === 200) {
      setRefresh(!refresh);
      setReadOnly(true);
    }
  });
};

export default UpdateTechTimeClock;
