// LOGIC
import {
    ValidateType,
    ValidateParts,
    ValidateExtraCosts,
    ValidatePayments,
    ValidateSaleSplitting,
    ValidateAllCollected
} from '../SaleDataValidation';
import SubmitGuestSale from './SubmitGuestSale';
import SubmitCustomerSale from './SubmitCustomerSale';
import SubmitOnlineSale from './SubmitOnlineSale';
import SubmitContractSale from './SubmitContractSale';
import SubmitServiceSale from './SubmitServiceSale';
import SubmitVehicleSale from './SubmitVehicleSale';
import SubmitWorkshopSale from './SubmitWorkshopSale';
import SubmitSalesSale from './SubmitSalesSale';
// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import {
    OnlineOrder,
    Order
} from '../../../../global/interfaces/PartsInterface';
import { PaymentLine } from '../../../../global/interfaces/GeneralInterface';

/**
 * HandleSubmitSale
 * Submit a sale
 * @author Sienna
 * @param selectedType the type of sale (e.g. customer sale, vehicle sale, parts sale, etc.)
 * @param selectedEntity who/what the sale is going towards
 * @param saleDetails the order
 * @param extraCosts any additional costs (e.g. freight)
 * @param paymentLines the order lines
 * @param associatedOnlineOrder an Ecommerce order associated with the given order
 * @param handlePrintInvoice functionality for printing the invoice
 * @param setSnackbar state setter for a snackbar to show success/error messages
 */
const HandleSubmitSale = (
    selectedType: string,
    selectedEntity: any,
    saleDetails: Order,
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[],
    paymentLines: PaymentLine[],
    associatedOnlineOrder: OnlineOrder,
    handlePrintInvoice: (
        hasCustomer: boolean,
        saleData: Order,
        redirectId?: number | boolean,
        vouchers?: any[]
    ) => void,
    showSnackbar: showSnackbar
) => {
    // Validate that the entity is correct
    let validEntity = ValidateType(selectedType, selectedEntity);
    if (validEntity !== 'valid') {
        showSnackbar(validEntity, '', 'error');
        return;
    }

    // Validate the parts
    let validParts = ValidateParts(
        saleDetails.orderLines,
        selectedType,
        saleDetails.status
    );
    if (validParts !== 'valid') {
        showSnackbar(validParts, '', 'error');
        return;
    }

    // Validate the extra costs
    let validExtraCosts = ValidateExtraCosts(extraCosts);
    if (validExtraCosts !== 'valid') {
        showSnackbar(validExtraCosts, '', 'error');
        return;
    }

    // Validate the payment lines if it is not an online order
    if (selectedType === 'customer' || selectedType === 'guest') {
        let validPaymentLines = ValidatePayments(
            paymentLines,
            saleDetails.status,
            saleDetails,
            extraCosts
        );

        if (validPaymentLines !== 'valid') {
            showSnackbar(validPaymentLines, '', 'error');
            return;
        }
    }

    if (selectedType === 'online' && !associatedOnlineOrder?.OnlineOrderId) {
        showSnackbar(
            'Shipping details are missing!',
            'These are required in an online order.',
            'error'
        );
        return;
    }

    // Sale splitting validation
    if (saleDetails.status === 'Proforma' && selectedType === 'customer') {
        let validSplitSale = ValidateSaleSplitting(
            paymentLines,
            saleDetails,
            selectedType
        );

        if (validSplitSale !== 'valid') {
            showSnackbar(validSplitSale, '', 'error');
            return;
        }

        let allCollected = ValidateAllCollected(saleDetails.orderLines);
        if (allCollected) {
            showSnackbar(
                'You can not mark all items as collected.',
                'If the customer is taking all the parts, mark the order as a sale.',
                'error'
            );
            return;
        }
    }

    if (selectedType === 'customer')
        SubmitCustomerSale(
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'contract')
        SubmitContractSale(
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'vehicle')
        SubmitVehicleSale(
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'service')
        SubmitServiceSale(
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'workshop')
        SubmitWorkshopSale(
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'sales')
        SubmitSalesSale(
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'online')
        SubmitOnlineSale(
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            associatedOnlineOrder,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'guest')
        SubmitGuestSale(
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
};

export default HandleSubmitSale;
