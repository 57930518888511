// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';
interface PaymentProps {
  creditorDetails: Creditor;
  setCreditorDetails: Dispatch<SetStateAction<Creditor>>;
  readOnly: boolean;
}

const Payment = ({
  creditorDetails,
  setCreditorDetails,
  readOnly
}: PaymentProps) => {
  const paymentTermOptions = [
      { value: 'EOM' },
      { value: '60DAYS' },
      { value: '21DAYS' },
      { value: '15DAYS' },
      { value: '14DAYS' },
      { value: '7DAYS' },
      { value: 'CASH' },
      { value: 'DIRECT DEBIT' }
  ];

  // Handle changing details in the payment details object
  const handlePaymentDetailChange = (selector: string, value: string) => {
    let currentPaymentDetails = creditorDetails?.paymentDetails;
    currentPaymentDetails[selector] = value;
    setCreditorDetails({
      ...creditorDetails,
      paymentDetails: currentPaymentDetails
    });
  };

  return (
    <Paper>
      <Typography variant="h6">Payment Details</Typography>
      <Divider />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            size="small"
            label="Default Reference"
            value={creditorDetails?.paymentDetails?.defaultReference}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handlePaymentDetailChange(
                'defaultPaymentReference',
                e.target.value
              )
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={readOnly}
            fullWidth
            select
            size="small"
            label="Payment Terms"
            value={creditorDetails?.paymentTerms}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCreditorDetails({
                ...creditorDetails,
                paymentTerms: e.target.value
              })
            }
          >
            {paymentTermOptions.map((option) => (
              <MenuItem value={option.value}>{option.value}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={readOnly}
            fullWidth
            size="small"
            label="Account Name"
            value={creditorDetails?.paymentDetails?.accountName}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handlePaymentDetailChange('accountName', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={readOnly}
            fullWidth
            size="small"
            label="BSB"
            value={creditorDetails?.paymentDetails?.bsb}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handlePaymentDetailChange('bsb', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={readOnly}
            fullWidth
            size="small"
            label="Account Number"
            value={creditorDetails?.paymentDetails?.accountNumber}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handlePaymentDetailChange('accountNumber', e.target.value)
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Payment;
