// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleAddBinLocation = (
    name: string,
    setNameError: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    setSort: Dispatch<SetStateAction<string[]>>,
    setAddBinLocationDialogOpen: Dispatch<SetStateAction<boolean>>,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    if (!name || name === '') {
        setNameError(true);
        showSnackbar(
            'Whoops! It looks like you have missed some data.',
            'Please enter a valid name for the new bin location.',
            'error'
        );
        setButtonLoading(false);
    } else {
        api.post(`addBinLocation?SiteId=${localStorage.getItem('SiteId')}`, {
            name: name
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar('Bin Location was successfully added.');
                setSort(['id', 'DESC']);
                setAddBinLocationDialogOpen(false);
                setButtonLoading(false);
            } else {
                showSnackbar(
                    'Whoops! It looks like something went wrong on our end.',
                    'Please contact you IT department.',
                    'error'
                );
                setButtonLoading(false);
            }
        });
    }
};

export default HandleAddBinLocation;
