// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Service } from '../../../global/interfaces/ServiceInterface';

const GetCustomerServiceHistory = (
  id: number,
  setCustomerHistory: Dispatch<SetStateAction<Service[]>>
) => {
  api.get(`customerServiceHistory/${id}`).then((res) => {
    setCustomerHistory(res.data);
  });
};

export default GetCustomerServiceHistory;
