// IMPORTS
import { useEffect, useState } from 'react';
import { Typography, Dialog, Paper, CircularProgress } from '@mui/material';
import { Edit, Lock, Password } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import StandardDetails from './components/StandardDetails';
import RoleCheck from '../../global/RoleCheck';
import EmploymentDetails from './components/EmploymentDetails';
import EmploymentSpecifics from './components/EmploymentSpecifics';
import PersonalDetails from './components/PersonalDetails';
import SpeedDial from '../../global/SpeedDial';
import Fab from '../../global/Fab';
import { withSnackbar } from '../../global/WrappingSnackbar';
import PermissionDialogContent from './components/PermissionDialogContent';
import PageDoesNotExist from '../../global/PageDoesNotExist';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import UserSignature from './components/UserSignature';
// LOGIC
import GetUser from '../../global/databaseLogic/GetUser';
import ResetUserPassword from './logic/ResetUserPassword';
import HandleEditUser from './logic/HandleEditUser';
import HandleAddUser from './logic/HandleAddUser';
// INTERFACES
import { User } from '../../global/interfaces/GeneralInterface';

const ViewUser = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    let { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [responseCode, setResponseCode] = useState(200);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [permissionDialogOpen, setPermissionDialogOpen] = useState(false);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<User>({
        Site: {},
        employed: true,
        isGod: false,
        department: '',
        employmentType: '',
        dateOfBirth: null,
        taxFileNumber: {
            number: '',
            dateProvided: null
        },
        superannuation: {
            company: '',
            accountNumber: ''
        },
        bankAccount: {
            name: '',
            bsb: '',
            number: ''
        },
        emergencyContact: {
            fullName: '',
            relation: '',
            phoneNumber: ''
        },
        signature: ''
    });

    const actionsList = [
        {
            icon: <Edit />,
            name: 'Edit User',
            onClick: () => setReadOnly(false),
            cypressLabel: 'editUserButton'
        },
        {
            icon: <Lock />,
            name: 'User Permissions',
            onClick: () => setPermissionDialogOpen(true)
        },
        {
            icon: <Password />,
            name: 'Reset Password',
            onClick: () =>
                ResetUserPassword(
                    parseInt(id),
                    userDetails?.firstName,
                    userDetails?.staffEmail,
                    showSnackbar
                )
        }
    ];

    useEffect(() => {
        if (id) {
            GetUser(parseInt(id), setUserDetails, setPageLoading, setInvalid);
        } else {
            setReadOnly(false);
        }
    }, [id]);

    useEffect(() => {
        if (userDetails?.id) {
            if (userDetails?.hourlyRate) {
                setSelectedPaymentType('hourly');
            } else {
                setSelectedPaymentType('salary');
            }
        }
    }, [userDetails]);

    return (
        <PageWrapper>
            {pageLoading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4">
                        {id
                            ? userDetails?.firstName +
                              ' ' +
                              userDetails?.lastName
                            : 'New User'}
                    </Typography>
                    <br />
                    <StandardDetails
                        userDetails={userDetails}
                        setUserDetails={setUserDetails}
                        readOnly={readOnly}
                        responseCode={responseCode}
                        setResponseCode={setResponseCode}
                    />
                    <br />

                    <RoleCheck
                        permission="configure_personal_users"
                        component={
                            <>
                                <PersonalDetails
                                    userId={id}
                                    userDetails={userDetails}
                                    setUserDetails={setUserDetails}
                                    readOnly={readOnly}
                                />
                                <br />
                            </>
                        }
                        nullComponent={
                            <>
                                <UserSignature
                                    userId={id}
                                    userDetails={userDetails}
                                    setUserDetails={setUserDetails}
                                    showSnackbar={showSnackbar}
                                />
                            </>
                        }
                    />

                    <RoleCheck
                        permission="assign_superuser"
                        component={
                            <>
                                <EmploymentDetails
                                    userDetails={userDetails}
                                    setUserDetails={setUserDetails}
                                    readOnly={readOnly}
                                    selectedPaymentType={selectedPaymentType}
                                    setSelectedPaymentType={
                                        setSelectedPaymentType
                                    }
                                />
                                <br />
                                <EmploymentSpecifics
                                    userDetails={userDetails}
                                    setUserDetails={setUserDetails}
                                    readOnly={readOnly}
                                    selectedPaymentType={selectedPaymentType}
                                    setSelectedPaymentType={
                                        setSelectedPaymentType
                                    }
                                />
                            </>
                        }
                    />

                    <RoleCheck
                        permission="assign_superuser, configure_personal_users, admin_manager, parts_manager, service_manager, sales_manager"
                        component={
                            <>
                                {readOnly ? (
                                    <SpeedDial
                                        actionsList={actionsList}
                                        cypressLabel="viewUserSpeedDial"
                                    />
                                ) : (
                                    <Fab
                                        cypressLabel="viewUserSubmitFAB"
                                        editIcon={false}
                                        loading={loading}
                                        onClick={() => {
                                            id
                                                ? HandleEditUser(
                                                      userDetails,
                                                      responseCode,
                                                      setLoading,
                                                      showSnackbar
                                                  )
                                                : HandleAddUser(
                                                      userDetails,
                                                      responseCode,
                                                      setLoading,
                                                      showSnackbar
                                                  );
                                        }}
                                    />
                                )}
                            </>
                        }
                    />
                </>
            )}

            <Dialog
                open={permissionDialogOpen}
                onClose={() => setPermissionDialogOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <PermissionDialogContent
                    UserId={parseInt(id)}
                    setPermissionDialogOpen={setPermissionDialogOpen}
                    showSnackbar={showSnackbar}
                />
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(ViewUser, 'viewUserSnackbar');
