/**
 * ValidateABN
 * Check for a valid ABN
 * @author Estienne
 * @param abn An ABN
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateABN = (abn: string) => {
    /**
     * To verify an ABN:
     *  1. Subtract 1 from the first (left-most) digit of the ABN to give a new 11-digit number
     *  2. Multiply each of the digits in this new number by a "weighting factor" based on its position as shown in the table below
     *  3. Sum the resulting 11 products
     *  4. Divide the sum total by 89, noting the remainder
     *  5. If the remainder is 0, then the ABN is valid
     *
     * For example, to check if 51 824 753 556 is a valid ABN:
     *  1. Subtract 1 from the first (left-most) digit (5) to give 41 824 753 556
     *  2. Multiply each of the digits in 41 824 753 556 by their "weighting factor"
     *  3. Sum (Digit * Weight) to give a total of 534
     *  4. Divide 534 by 89 giving 6 with zero remainder
     *  5. As the remainder is 0, 51 824 753 556 is a valid ABN
     *
     *  WEIGHTING FACTORS
     *  Position    Weighting
     *  1           10
     *  2           1
     *  3           3
     *  4           5
     *  5           7
     *  6           9
     *  7           11
     *  8           13
     *  9           15
     *  10          17
     *  11          19
     *
     * Other ABN verification factors:
     *  - an ABN has 11 digits
     *  - an ABN only contains numeric characters
     *  - the first digit of an ABN will never be 0
     */
    if (abn != null) {
        // PRE-VALIDATION: Remove any whitespaces from the ABN
        abn = abn.replace(/\s/g, '');

        // PRE-VALIDATION CHECKS: Check that the input satisfies the basic ABN characteristics
        // Is the ABN 11 characters long?
        if (abn.length !== 11) {
            return {
                verified: false,
                message: 'Invalid ABN.',
                subMessage: 'ABN must contain exactly 11 digits.'
            };
        }

        // Is the ABN a number?
        if (!Number(abn)) {
            return {
                verified: false,
                message: 'Invalid ABN.',
                subMessage: 'ABN must only contain numerical characters.'
            };
        }

        // Is the first digit NOT 0?
        let firstCharacter = abn.slice(0, 1);
        if (firstCharacter === '0') {
            return {
                verified: false,
                message: 'Invalid ABN.',
                subMessage: 'The first digit of an ABN cannot be 0.'
            };
        }

        // 1. Subtract 1 from the first character in the string
        let newFirstCharacter = Number(firstCharacter) - 1;

        // 2. Form the new ABN
        let theRest = abn.slice(1);
        let newABN = newFirstCharacter + theRest;

        // 3. Multiply each number in the new ABN by its weighting factor
        // and then sum it
        let weightingFactors = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
        let summedNumbers = 0;

        for (let i = 0; i < newABN.length; i++) {
            let multipliedDigit =
                Number(newABN.charAt(i)) * weightingFactors[i];
            summedNumbers += multipliedDigit;
        }

        // 4. Check if the summed numbers / 89 gives a remainder of 0
        if (summedNumbers % 89 !== 0) {
            return {
                verified: false,
                message: 'Please enter a valid ABN.',
                subMessage: 'ABN failed verification.'
            };
        }

        return { verified: true, message: '', subMessage: '' };
    }
};

/**
 * ValidateEmail
 * Check for a valid email address
 * @author Estienne
 * @param email An email address
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateEmail = (email: string) => {
    if (email) {
        let formattedEmail = email.replaceAll(' ', '').toLowerCase();

        // Check for @ symbol
        if (!formattedEmail.includes('@')) {
            return {
                verified: false,
                message: 'Invalid email address.',
                subMessage: "Email address must contain '@' character"
            };
        }

        if (
            !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(
                formattedEmail
            )
        ) {
            return {
                verified: false,
                message: 'Invalid email address.',
                subMessage: 'Email format is invalid.'
            };
        } else {
            return { verified: true, message: '', subMessage: '' };
        }
    } else {
        return {
            verified: false,
            message: 'Invalid email address.',
            subMessage: 'An email must be provided.'
        };
    }
};

export const ValidateCustomerType = (customerType: string) => {
    if (customerType === 'Retail' || customerType === 'Trade') {
        return { verified: true, message: '', subMessage: '' };
    } else {
        return {
            verified: false,
            message: 'Missing Customer Type.',
            subMessage: 'Please select a customer type.'
        };
    }
};

/**
 * ValidateLicence
 * Check for a valid driver licence number
 * @author Estienne
 * @param licence a customer's driver licence number
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateLicence = (licence: string) => {
    if (licence != null) {
        licence = licence.replace(/\s/g, ''); // Strip spaces

        // All Australian licence
        // numbers are over 5 characters
        if (licence.length < 6) {
            return {
                verified: false,
                message: 'Invalid licence.',
                subMessage:
                    'All valid Australian licence numbers contain at least 6 characters.'
            };
        }

        return { verified: true, message: '', subMessage: '' };
    }
};

/**
 * ValidatePaymentTerms
 * Validate the payment terms of a debtor customer
 * @author Estienne
 * @param paymentTerms the customer's payment terms
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidatePaymentTerms = (paymentTerms: string) => {
    if (!parseInt(paymentTerms)) {
        return {
            verified: false,
            message: 'Invalid payment terms.',
            subMessage: 'A debtor must have a valid payment terms value.'
        };
    } else if (parseInt(paymentTerms) < 7) {
        return {
            verified: false,
            message: 'Invalid payment terms.',
            subMessage: "A debtor's payment terms must be at least 7 days."
        };
    }

    return { verified: true, message: '', subMessage: '' };
};

/**
 * ValidatePhone
 * Check for a valid phone number
 * @author Estienne
 * @param phoneNumber A customer's phone number
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidatePhone = (phoneNumber: string) => {
    phoneNumber = phoneNumber.replace(/\s/g, ''); // Strip spaces
    phoneNumber = phoneNumber.replace(/-/g, ''); //  Strip hyphens

    // Is area code present?
    if (phoneNumber.includes('+')) {
        // Correct number of characters?
        if (phoneNumber.length !== 12) {
            return {
                verified: false,
                message: 'Invalid phone number.',
                subMessage:
                    'AU phone numbers prefixed with area code must be 12 characters long.'
            };
        }

        // Only numbers (besides '+')?
        else if (phoneNumber.match(/[^+\d]/)) {
            return {
                verified: false,
                message: 'Invalid phone number.',
                subMessage: 'Phone number must only contain numeric characters.'
            };
        }
    } else {
        // Correct number of characters?
        if (phoneNumber.length !== 10) {
            return {
                verified: false,
                message: 'Invalid phone number.',
                subMessage: 'AU phone numbers must be 10 characters long.'
            };
        }

        // Valid number?
        else if (!Number(phoneNumber)) {
            return {
                verified: false,
                message: 'Invalid phone number.',
                subMessage: 'Phone number must only contain numeric characters.'
            };
        }
    }

    // Regex validation just in case
    if (
        !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(
            phoneNumber
        )
    ) {
        return {
            verified: false,
            message: 'Invalid phone number.',
            subMessage: 'Phone number failed validation.'
        };
    }

    return { verified: true, message: '', subMessage: '' };
};

/**
 * ValidatePostcode
 * Check for a valid postcode
 * @author Estienne
 * @param postcode A customer's postcode
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidatePostcode = (postcode: string) => {
    if (postcode != null) {
        if (!Number(postcode)) {
            return {
                verified: false,
                message: 'Invalid postcode.',
                subMessage: 'Postcode must be a string of numbers.'
            };
        } else {
            return { verified: true, message: '', subMessage: '' };
        }
    }
};

/**
 * ValidateState
 * Check for a valid state or country code
 * @author Estienne
 * @param state the provided state
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateStateCountry = (code: string) => {
    if (code != null) {
        // All state/country codes are 2-3 characters
        if (code.length !== 2 && code.length !== 3) {
            return {
                verified: false,
                message: 'Invalid state/country code.',
                subMessage:
                    'All state/country codes must be 2-3 characters in length.'
            };
        } else if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(code)) {
            return {
                verified: false,
                message: 'Invalid state/country code.',
                subMessage:
                    'State/country codes cannot contain special characters.'
            };
        } else {
            return { verified: true, message: '', subMessage: '' };
        }
    }
};

/**
 * ValidateTradeDiscount
 * Check for a valid trade discount
 * @author Estienne
 * @param discount the provided discount
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateTradeDiscount = (discount: number) => {
    if (discount < 0) {
        return {
            verified: false,
            message: 'Invalid trade discount.',
            subMessage: 'Discount percentage cannot be negative.'
        };
    } else if (discount > 100) {
        return {
            verified: false,
            message: 'Invalid trade discount.',
            subMessage: 'Discount percentage cannot exceed 100%.'
        };
    } else {
        return { verified: true, message: '', subMessage: '' };
    }
};
