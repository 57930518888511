// IMPORTS
import { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Refresh, Save } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
import Header from './Header';
import Tables from './Tables';
import Fab from '../../../../global/Fab';
import SpeedDial from '../../../../global/SpeedDial';
// LOGIC
import GetTransactions from '../../logic/Reconciliator/GetTransactions';
import RefreshTransactions from '../../logic/Reconciliator/RefreshTransactions';
import SubmitBankReconciliation from '../../logic/Reconciliator/SubmitBankReconciliation';
// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const Reconciliator = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
    const [reconciliationDetailsUnltered, setReconciliationDetailsUnaltered] =
        useState({
            oraTransactionsReconciled: [],
            bankTransactionsReconciled: [],
            oraTransactionsUnreconciled: [],
            bankTransactionsUnreconciled: []
        });
    const [reconciliationDetails, setReconciliationDetails] = useState({
        oraTransactionsReconciled: [],
        bankTransactionsReconciled: [],
        oraTransactionsUnreconciled: [],
        bankTransactionsUnreconciled: []
    });

    const actionsList = [
        {
            id: 0,
            name: 'Save',
            icon: <Save />,
            onClick: () =>
                SubmitBankReconciliation(
                    reconciliationDetails,
                    setRefreshLoading,
                    showSnackbar
                )
        },
        {
            id: 1,
            name: 'Refresh Transactions',
            icon: <Refresh />,
            onClick: () =>
                RefreshTransactions(
                    reconciliationDetails,
                    reconciliationDetailsUnltered,
                    setReconciliationDetailsUnaltered,
                    setRefreshLoading
                )
        }
    ];

    useEffect(() => {
        GetTransactions(
            reconciliationDetailsUnltered,
            setReconciliationDetailsUnaltered,
            setLoading
        );
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        GetTransactions(
            reconciliationDetailsUnltered,
            setReconciliationDetailsUnaltered,
            setLoading
        );
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setReconciliationDetails(reconciliationDetailsUnltered);
    }, [reconciliationDetailsUnltered]);
    useEffect(() => {
        setReconciliationDetails(reconciliationDetailsUnltered);
    }, [reconciliationDetailsUnltered]);

    return (
        <>
            {loading ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                        <Paper>
                            <CircularProgress />
                        </Paper>
                    </Grid>
                </Grid>
            ) : (
                <>
                    {refreshLoading ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12} textAlign="center">
                                <Paper>
                                    <CircularProgress />
                                </Paper>
                                <br />
                            </Grid>
                        </Grid>
                    ) : null}
                    <Header
                        reconciliationDetails={reconciliationDetails}
                        setReconciliationDetails={setReconciliationDetails}
                        reconciliationDetailsUnltered={
                            reconciliationDetailsUnltered
                        }
                        refreshLoading={refreshLoading}
                        readOnly={readOnly}
                    />
                    <br />
                    <Tables
                        reconciliationDetails={reconciliationDetails}
                        setReconciliationDetails={setReconciliationDetails}
                        refreshLoading={refreshLoading}
                        readOnly={readOnly}
                    />
                    {!refreshLoading ? (
                        readOnly ? (
                            <Fab
                                editIcon={true}
                                onClick={() => setReadOnly(false)}
                            />
                        ) : (
                            <SpeedDial actionsList={actionsList} />
                        )
                    ) : null}
                </>
            )}
        </>
    );
};

export default withSnackbar(Reconciliator);
