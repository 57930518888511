// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import {
    Settings,
    Site,
    Till
} from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { ShippingMethod } from '../../../global/interfaces/PartsInterface';
import ValidateSiteFields from '../../../global/logic/generalValidation/ValidateSiteFields';

const HandleAddSite = async (
    site: Site,
    settings: Settings[],
    tills: Till[],
    shippingMethods: ShippingMethod[],
    setLoading: Dispatch<SetStateAction<boolean>>,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setLoading(true);
    setReadOnly(true);

    let { verified, message, subMessage } = await ValidateSiteFields(
        site,
        tills,
        shippingMethods
    );

    if (verified === false) {
        showSnackbar(message, subMessage, 'error');
        setLoading(false);
        setReadOnly(false);
    } else {
        api.post(`addNewSite/`, {
            site,
            settings,
            tills,
            shippingMethods
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Site successfully created.',
                    'The page will now reload.'
                );
                setTimeout(
                    () =>
                        (window.location.href =
                            '/viewSite/' + res.data.site.id),
                    1000
                );
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
                setLoading(false);
                setReadOnly(false);
            }
        });
    }
};

export default HandleAddSite;
