// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import HandleVerifyCustomer from '../logic/HandleVerifyCustomer';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '../../../../api';
import { useHistory } from 'react-router-dom';

interface LoginForm {
    documentId: number; // vehicleSaleId for signContract, vehicleId for signVendorStatement
    driverLicenseNo: string;
}

interface Props {
    type: string;
    setToken: Dispatch<SetStateAction<string | null>>;
}

const schema = yup
    .object({
        documentId: yup
            .number()
            .typeError('Document ID must be a number')
            .integer('Document ID must be an integer')
            .required('Document ID is required'),
        driverLicenseNo: yup
            .string()
            .required('Driver License Number is required')
    })
    .required();

const VerifyCustomer = ({ setToken }: Props) => {
    const history = useHistory();

    // Redirect to the page where the customer was before logging in by reading the redirect query parameter
    const searchParams = new URLSearchParams(window.location.search);
    const redirectUrl = searchParams.get('redirect');

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onTouched'
    });

    const [backendError, setBackendError] = useState('');

    const onSubmit = async (data: LoginForm) => {
        let type;

        if (redirectUrl.includes('vendor-statement')) {
            type = 'vehicle-trade';
        } else  if (redirectUrl.includes('vehicle-contract')) {
            type = 'vehicle-sale-contract';
        } else if (redirectUrl.includes('loa')) {
            type = 'vehicle-sale-loa';
        }

        const { error, verified, token } = await HandleVerifyCustomer(
            type,
            data.documentId,
            data.driverLicenseNo
        );

        if (verified) {
            setToken(token);
            api.defaults.headers.common['Authorization'] =
                'Bearer ' + localStorage.getItem('token');

            if (redirectUrl) {
                history.push(decodeURIComponent(redirectUrl));
            }
        }

        if (error) {
            setBackendError(error);
        }
    };

    return (
        <Grid
            container
            spacing={2}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <Grid item xs={10} sm={6}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" textAlign={'left'}>
                        To ensure ease of use, please access this page on a
                        computer. It is not optimized for mobile devices.
                    </Typography>
                </Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        {...register('documentId')}
                        error={errors.documentId ? true : false}
                        helperText={errors.documentId?.message}
                        required
                        fullWidth
                        label="Document ID"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        {...register('driverLicenseNo')}
                        error={errors.driverLicenseNo ? true : false}
                        helperText={errors.driverLicenseNo?.message}
                        required
                        fullWidth
                        label="Driver License Number"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    {backendError && (
                        <Typography color="error" variant="body1">
                            {backendError}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Proceed
                    </Button>
                </form>
            </Grid>
        </Grid>
    );
};

export default VerifyCustomer;
