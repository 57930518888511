// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';


const DeleteTechTimeClock = (
  TimeClockId: number,
  refresh: boolean,
  setRefresh: Dispatch<SetStateAction<boolean>>,
  setReadOnly: Dispatch<SetStateAction<boolean>>
) => {
  api.delete(`deleteTechTimeClock/${TimeClockId}`).then((res) => {
    if (res.status === 200) {
      setRefresh(!refresh);
      setReadOnly(true);
    }
  });
};

export default DeleteTechTimeClock;
