import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Ctp } from '../../../global/interfaces/VehicleSaleInterface';
import moment from 'moment';

const CreateCtpPrice = (
    state: string,
    current: string,
    setCtp: Dispatch<SetStateAction<Ctp[]>>
) => {
    if (current === 'current') {
        let tempCtp = [];
        if (state === 'QLD') {
            tempCtp = [
                {
                    state: state,
                    class: '12',
                    className: '1 Seat Motorcycle',
                    length: '6',
                    prices: [
                        {
                            business: '1',
                            personal: '1',
                            businessError: false,
                            personalError: false
                        }
                    ],
                    effectiveDate: null,
                    outdated: null,
                    endDate: null
                },
                {
                    state: state,
                    class: '12',
                    className: '1 Seat Motorcycle',
                    length: '12',
                    prices: [
                        {
                            business: '1',
                            personal: '1'
                        }
                    ],
                    effectiveDate: null,
                    outdated: null,
                    endDate: null
                },
                {
                    state: state,
                    class: '13',
                    className: '2 Seat Motorcycle',
                    length: '6',
                    prices: [
                        {
                            business: '1',
                            personal: '1'
                        }
                    ],
                    effectiveDate: null,
                    outdated: null,
                    endDate: null
                },
                {
                    state: state,
                    class: '13',
                    className: '2 Seat Motorcycle',
                    length: '12',
                    prices: [
                        {
                            business: '1',
                            personal: '1'
                        }
                    ],
                    effectiveDate: null,
                    outdated: null,
                    endDate: null
                }
            ];
        } else if (state === 'NSW') {
            let lengthArray = [3, 6, 12];
            for (let length of lengthArray) {
                tempCtp.push([
                    {
                        class: '10d',
                        className: '225cc or less / Electric',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: null
                    },
                    {
                        class: '10e',
                        className: '226cc - 725cc',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: null
                    },
                    {
                        class: '10f',
                        className: '726cc - 1125cc',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: null
                    },
                    {
                        class: '10g',
                        className: '1126cc - 1325cc',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: null
                    },
                    {
                        class: '10h',
                        className: '1325cc and greater',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: null
                    }
                ]);
            }
        }
        api.post('ctp', { ctp: tempCtp, state: state }).then((res) => {
            setCtp(tempCtp);
        });
    } else {
        let tempCtp = [];
        if (state === 'QLD') {
            tempCtp = [
                {
                    state: state,
                    class: '12',
                    className: '1 Seat Motorcycle',
                    length: '6',
                    prices: [
                        {
                            business: '1',
                            personal: '1',
                            businessError: false,
                            personalError: false
                        }
                    ],
                    effectiveDate: moment().add(1, 'year').toISOString(),
                    outdated: null,
                    endDate: null
                },
                {
                    state: state,
                    class: '12',
                    className: '1 Seat Motorcycle',
                    length: '12',
                    prices: [
                        {
                            business: '1',
                            personal: '1'
                        }
                    ],
                    effectiveDate: moment().add(1, 'year').toISOString(),
                    outdated: null,
                    endDate: null
                },
                {
                    state: state,
                    class: '13',
                    className: '2 Seat Motorcycle',
                    length: '6',
                    prices: [
                        {
                            business: '1',
                            personal: '1'
                        }
                    ],
                    effectiveDate: moment().add(1, 'year').toISOString(),
                    outdated: null,
                    endDate: null
                },
                {
                    state: state,
                    class: '13',
                    className: '2 Seat Motorcycle',
                    length: '12',
                    prices: [
                        {
                            business: '1',
                            personal: '1'
                        }
                    ],
                    effectiveDate: moment().add(1, 'year').toISOString(),
                    outdated: null,
                    endDate: null
                }
            ];
        } else if (state === 'NSW') {
            let lengthArray = [3, 6, 12];
            for (let length of lengthArray) {
                tempCtp.push([
                    {
                        class: '10d',
                        className: '225cc or less / Electric',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: moment().add(1, 'year').toISOString(),
                        outdated: null,
                        endDate: null
                    },
                    {
                        class: '10e',
                        className: '226cc - 725cc',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: moment().add(1, 'year').toISOString(),
                        outdated: null,
                        endDate: null
                    },
                    {
                        class: '10f',
                        className: '726cc - 1125cc',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: moment().add(1, 'year').toISOString(),
                        outdated: null,
                        endDate: null
                    },
                    {
                        class: '10g',
                        className: '1126cc - 1325cc',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: moment().add(1, 'year').toISOString(),
                        outdated: null,
                        endDate: null
                    },
                    {
                        class: '10h',
                        className: '1325cc and greater',
                        state: state,
                        length: length,
                        prices: [
                            {
                                '2015 & prior': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                },
                                '2016 - 2022': {
                                    business: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    },
                                    personal: {
                                        Metro: 1,
                                        'Outer Metro': 1,
                                        'Newcastle / Central Coast': 1,
                                        Wollongong: 1,
                                        Country: 1
                                    }
                                }
                            }
                        ],
                        effectiveDate: moment().add(1, 'year').toISOString(),
                        outdated: null,
                        endDate: null
                    }
                ]);
            }
        }
        api.post('ctp', { ctp: tempCtp, state: state }).then((res) => {
            setCtp(tempCtp);
        });
    }
};

export default CreateCtpPrice;
