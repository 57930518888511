// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Template } from '../../../global/interfaces/GeneralInterface';

// This function is called when a code is clicked at the bottom
// It takes the current body and appends the code onto the end
// If the current body is empty it will just become the first part of the body
// If read only is active, it does not do anything
const HandeAddCodeToBody = (
    readOnly: boolean,
    code: string,
    template: Template,
    setTemplate: Dispatch<SetStateAction<Template>>
) => {
    if (readOnly) {
        return;
    } // Read only, do not do anything

    let currentBody = template.messageBody;

    if (currentBody && currentBody.length > 0) {
        // If there is a current message body
        let lastCharacter = currentBody.charAt(currentBody.length - 1);

        if (lastCharacter === ' ' || lastCharacter === `\n`) {
            currentBody = currentBody + code;
        } else {
            currentBody = currentBody + ' ' + code;
        }
    } else {
        // If there is no current body
        currentBody = code;
    }

    setTemplate({ ...template, messageBody: currentBody });
};

export default HandeAddCodeToBody;
