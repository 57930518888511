import { InputAdornment, TableRow, TextField } from '@mui/material';
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import { CurrencyFormatter } from '../../../global/logic/Formatters';

const SupplierSummary = ({ partDetails, siteId, setPartDetails, readOnly }) => {
    const columns = [
        { id: 0, label: 'Supplier' },
        { id: 1, label: 'Cost Price Daily' },
        { id: 2, label: 'Cost Price Stock' },
        { id: 3, label: 'RRP' },
        { id: 4, label: 'Trade RRP' }
    ];

    const handleUpdateValue = (supplierId, type, value) => {
        let currentDetails = [...partDetails.SupplierIds];
        let index = currentDetails.findIndex((x) => x.id === supplierId);
        currentDetails[index][type] = value;
        setPartDetails({ ...partDetails, SupplierIds: currentDetails });
    };

    return (
        <Paper>
            <DataTable columns={columns}>
                {partDetails.SupplierIds.filter((x) => x.SiteId === siteId).map(
                    (supplier) => (
                        <TableRow>
                            <DataCell>{supplier.name}</DataCell>
                            <DataCell>
                                {readOnly ? (
                                    CurrencyFormatter(supplier.CPD)
                                ) : (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            )
                                        }}
                                        type="number"
                                        value={supplier.CPD}
                                        onChange={(e) =>
                                            handleUpdateValue(
                                                supplier.id,
                                                'CPD',
                                                parseFloat(e.target.value)
                                            )
                                        }
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                {readOnly ? (
                                    CurrencyFormatter(supplier.CPS)
                                ) : (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            )
                                        }}
                                        type="number"
                                        value={supplier.CPS}
                                        onChange={(e) =>
                                            handleUpdateValue(
                                                supplier.id,
                                                'CPS',
                                                parseFloat(e.target.value)
                                            )
                                        }
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                {readOnly ? (
                                    CurrencyFormatter(supplier.RRP)
                                ) : (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            )
                                        }}
                                        type="number"
                                        value={supplier.RRP}
                                        onChange={(e) =>
                                            handleUpdateValue(
                                                supplier.id,
                                                'RRP',
                                                parseFloat(e.target.value)
                                            )
                                        }
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                {readOnly ? (
                                    CurrencyFormatter(supplier.tradePriceRRP)
                                ) : (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            )
                                        }}
                                        type="number"
                                        value={supplier.tradePriceRRP}
                                        onChange={(e) =>
                                            handleUpdateValue(
                                                supplier.id,
                                                'tradePriceRRP',
                                                parseFloat(e.target.value)
                                            )
                                        }
                                    />
                                )}
                            </DataCell>
                        </TableRow>
                    )
                )}
            </DataTable>
        </Paper>
    );
};

export default SupplierSummary;
