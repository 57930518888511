import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Till } from '../../../global/interfaces/GeneralInterface';

const GetAllSiteTillsCustom = (
  SiteId: number,
  setTills: Dispatch<SetStateAction<Till[]>>,
  selectedDate?
) => {
  let query = '';
  if (selectedDate) {
    query = `&date=${selectedDate}`;
  }
  api.get(`tillsNextGen/${SiteId}?includeTillRec=true${query}`).then((res) => {
    setTills(res.data);
  });
};

export default GetAllSiteTillsCustom;
