// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Divider, Grid, TextField, Typography } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { Site } from '../../../global/interfaces/GeneralInterface';

interface SiteProps {
    siteInfo: Site;
    setSiteInfo: Dispatch<SetStateAction<Site>>;
    readOnly: boolean;
}

const BusinessHours = ({ siteInfo, setSiteInfo, readOnly }: SiteProps) => {
    return (
        <Paper>
            <Typography variant="h6" align="left">
                Business Hours
            </Typography>
            <Divider /> <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        inputProps={{
                            'data-cy': 'saleBusinessHours'
                        }}
                        disabled={readOnly}
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        label="Sales Business Hours"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.salesBusinessHours}
                        onChange={(e) =>
                            setSiteInfo({
                                ...siteInfo,
                                salesBusinessHours: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        inputProps={{
                            'data-cy': 'serviceBusinessHours'
                        }}
                        disabled={readOnly}
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        label="Service Business Hours"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.serviceBusinessHours}
                        onChange={(e) =>
                            setSiteInfo({
                                ...siteInfo,
                                serviceBusinessHours: e.target.value
                            })
                        }
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default BusinessHours;
