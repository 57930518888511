// IMPORTS
import { List, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { SubMenuProps } from '../../global/interfaces/GlobalInterface';
import { useContext } from 'react';
import { Site } from '../../global/interfaces/GeneralInterface';
import { SiteContext } from '../../../main/Main';

const VehicleSubMenu = ({ isSupportPage }: SubMenuProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const currentMode = useTheme().palette.mode;
    return (
        <>
            <List
                sx={{
                    backgroundColor:
                        currentMode === 'dark' ? '#3b3b3b' : '#dddddd'
                }}
            >
                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/vehicles/invoiceVehicles'
                            : '/vehicles/invoiceVehicles'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Invoice{' '}
                        {siteDetails.vehicleTypeSold === 'Motorcycles'
                            ? 'Vehicles'
                            : 'Bicycles'}
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/vehicles/stock'
                            : '/vehicles/stock'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        {siteDetails.vehicleTypeSold === 'Motorcycles'
                            ? 'Vehicle'
                            : 'Bicycle'}{' '}
                        Stock
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/vehicles/sales'
                            : '/vehicles/sales'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Sales
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/vehicles/newContract'
                            : '/vehicles/newContract'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        New Sale
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/vehicles/newTrade'
                            : '/vehicles/newTrade'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        New Trade
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/vehicles/vehicletransfers'
                            : '/vehicles/vehicletransfers'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Transfers
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/vehicles/leadsmanagement'
                            : '/vehicles/leadsmanagement'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Leads Management
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/vehicles/dashboard'
                            : '/vehicles/dashboard'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Dashboard
                    </ListItemText>
                </ListItemButton>
            </List>
        </>
    );
};

export default VehicleSubMenu;
