// IMPORTS
import { useState } from 'react';
import {
    Typography,
    TableRow,
    TableCell,
    IconButton,
    Drawer,
    Box,
    Table,
    TableHead,
    Divider
} from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import { Dayjs } from 'dayjs';

interface ServiceProps {
    serviceData: {
        ServiceId: number;
        customerName: string;
        serviceType: string;
        totalCost: number;
        totalSale: number;
        vehicleStockNumber: string;
    }[];
    wrongServiceData: {
        ServiceId: number;
        costAverage: number;
        costDaily: number;
        message: string;
        orderType: string;
        serviceType: string;
        submessage: string;
    }[];
    serviceValues: {
        insuranceCost: number;
        insuranceRevenue: number;
        insuranceTotal: number;
        internalCost: number;
        internalRevenue: number;
        internalTotal: number;
        retailCost: number;
        retailRevenue: number;
        retailTotal: number;
        warrantyCost: number;
        warrantyRevenue: number;
        warrantyTotal: number;
    };
    queryDate: { startDate: Dayjs; endDate: Dayjs };
}

const Service = (props: ServiceProps) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState('');

    let serviceTotal =
        props.serviceValues.retailTotal +
        props.serviceValues.internalTotal +
        props.serviceValues.warrantyTotal +
        props.serviceValues.insuranceTotal;
    let serviceCost =
        props.serviceValues.retailCost +
        props.serviceValues.internalCost +
        props.serviceValues.warrantyCost +
        props.serviceValues.insuranceCost;
    let serviceRevenue =
        props.serviceValues.retailRevenue +
        props.serviceValues.internalRevenue +
        props.serviceValues.warrantyRevenue +
        props.serviceValues.insuranceRevenue;
    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        <b>Service</b>
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {serviceTotal}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {CurrencyFormatter(serviceCost)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {CurrencyFormatter(serviceRevenue)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {CurrencyFormatter(serviceRevenue - serviceCost)}
                    </Typography>
                </TableCell>
                <TableCell>
                    {props.wrongServiceData.length > 0 ? (
                        <IconButton
                            onClick={() => {
                                setDrawerOpen(true);
                                setDrawerTitle('Missing Service Data');
                            }}
                        >
                            <PriorityHigh color="warning" fontSize="small" />
                        </IconButton>
                    ) : null}
                </TableCell>
            </TableRow>

            {serviceTotal > 0 ? (
                <>
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                Retail
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.serviceValues.retailTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.serviceValues.retailTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Retail Service Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.serviceValues.retailTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.retailCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.retailRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.retailRevenue -
                                        props.serviceValues.retailCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                Internal
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.serviceValues.internalTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.serviceValues.internalTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Internal Service Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.serviceValues.internalTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.internalCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.internalRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.internalRevenue -
                                        props.serviceValues.internalCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                Warranty
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.serviceValues.warrantyTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.serviceValues.warrantyTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Warranty Service Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.serviceValues.warrantyTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.warrantyCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.warrantyRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.warrantyRevenue -
                                        props.serviceValues.warrantyCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                Insurance
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.serviceValues.insuranceTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.serviceValues.insuranceTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Insurance Service Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.serviceValues.insuranceTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.insuranceCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.insuranceRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.serviceValues.insuranceRevenue -
                                        props.serviceValues.insuranceCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </>
            ) : null}
            <Drawer
                anchor={'right'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                style={{ zIndex: 1200 }}
            >
                <Box
                    sx={{ width: '35vw', padding: '20px' }}
                    role="presentation"
                >
                    <Typography variant="h4">{drawerTitle}</Typography>
                    <Typography>
                        {props.queryDate.startDate?.format('DD MMMM YYYY')} -{' '}
                        {props.queryDate.endDate?.format('DD MMMM YYYY')}
                    </Typography>
                    <Box height={'10px'}></Box>
                    <Divider></Divider>
                    <Box height="20px"></Box>
                    {drawerTitle === 'Retail Service Data' ||
                    drawerTitle === 'Internal Service Data' ||
                    drawerTitle === 'Warranty Service Data' ||
                    drawerTitle === 'Insurance Service Data' ? (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="15%">
                                            <strong>Service ID</strong>
                                        </TableCell>
                                        {drawerTitle ===
                                        'Internal Service Data' ? (
                                            <TableCell width="15%">
                                                <strong>Stock Number</strong>
                                            </TableCell>
                                        ) : (
                                            <TableCell width="15%">
                                                <strong>Customer Name</strong>
                                            </TableCell>
                                        )}
                                        <TableCell width="35%">
                                            <strong>Total Cost (Ex GST)</strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>
                                                Total Revenue (Ex GST)
                                            </strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>Gross Profits</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {props.serviceData.map((service) => {
                                    if (
                                        drawerTitle === 'Retail Service Data' &&
                                        service.serviceType === 'Retail'
                                    ) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/service/viewBooking/${service.ServiceId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {service.ServiceId}
                                                </TableCell>
                                                <TableCell>
                                                    {service.customerName}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalSale
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalSale -
                                                            service.totalCost
                                                    )}{' '}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    } else if (
                                        drawerTitle ===
                                            'Internal Service Data' &&
                                        service.serviceType === 'Internal'
                                    ) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/service/viewBooking/${service.ServiceId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {service.ServiceId}
                                                </TableCell>
                                                <TableCell>
                                                    {service.vehicleStockNumber}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalSale
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalSale -
                                                            service.totalCost
                                                    )}{' '}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    } else if (
                                        drawerTitle ===
                                            'Warranty Service Data' &&
                                        service.serviceType === 'Warranty'
                                    ) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/service/viewBooking/${service.ServiceId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {service.ServiceId}
                                                </TableCell>
                                                <TableCell>
                                                    {service.customerName}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalSale
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalSale -
                                                            service.totalCost
                                                    )}{' '}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    } else if (
                                        drawerTitle ===
                                            'Insurance Service Data' &&
                                        service.serviceType === 'Insurance'
                                    ) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/service/viewBooking/${service.ServiceId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {service.ServiceId}
                                                </TableCell>
                                                <TableCell>
                                                    {service.customerName}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalSale
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        service.totalSale -
                                                            service.totalCost
                                                    )}{' '}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Table>
                        </>
                    ) : (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="15%">
                                            <strong>Service ID</strong>
                                        </TableCell>
                                        <TableCell width="15%">
                                            <strong>Service Type</strong>
                                        </TableCell>
                                        <TableCell width="70%">
                                            <strong>Information</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {props.wrongServiceData.map((service) => (
                                    <TableRow>
                                        <TableCell
                                            className="table-link"
                                            onClick={() =>
                                                window.open(
                                                    `/service/viewBooking/${service.ServiceId}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {service.ServiceId}
                                        </TableCell>
                                        <TableCell>
                                            {service.serviceType}
                                        </TableCell>
                                        <TableCell>
                                            {service.message}
                                            <br />
                                            <Typography variant="caption">
                                                {service.submessage}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default Service;
