import DocumentationPage from '../DocumentationPage';
import {
    EnterCreditInvoiceSteps,
    ViewEditCreditInvoiceSteps
} from '../data/admin/creditInvoiceSteps';

const CreditInvoiceSupportPage = () => {
    const contentSections = [
        {
            id: 'enter-credit-invoice',
            title: 'Enter New Credit Invoice',
            href: '/support/admin/creditInvoice/#enter-credit-invoice',
            description: 'To enter a new credit invoice, follow these steps:',
            steps: EnterCreditInvoiceSteps
        },
        {
            id: 'view-edit-credit-invoice',
            title: 'View And Edit Credit Invoice',
            href: '/support/admin/creditInvoice/#view-edit-credit-invoice',
            description: 'To view and edit credit invoice, follow these steps:',
            steps: ViewEditCreditInvoiceSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Enter Credit Invoice"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default CreditInvoiceSupportPage;
