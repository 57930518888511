// LOGIC
import { ToFixed } from '../../../global/logic/Formatters';
import { PaymentTotal, SaleTotal } from './CalculateTotals';
// INTERFACES
import {
    Customer,
    PaymentLine
} from '../../../global/interfaces/GeneralInterface';
import {
    Order,
    PartOrderline
} from '../../../global/interfaces/PartsInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';
import {
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';

function CheckIfCustomer(selectedEntity: any): selectedEntity is Customer {
    if (selectedEntity?.id) {
        return true;
    } else {
        return false;
    }
}

function CheckIfVehicleSale(
    selectedEntity: any
): selectedEntity is VehicleSale {
    if (selectedEntity?.id) {
        return true;
    } else {
        return false;
    }
}

function CheckIfVehicle(selectedEntity: any): selectedEntity is Vehicle {
    if (selectedEntity?.id) {
        return true;
    } else {
        return false;
    }
}

function CheckIfService(selectedEntity: any): selectedEntity is Service {
    if (selectedEntity?.id) {
        return true;
    } else {
        return false;
    }
}

// Confirms that the selected entity matches they selected type
// Although it should be nearly impossible due to frontend changes
// This is the last resort if they somehow manage to eg. select a customer
// for a sale type of contract
export const ValidateType = (selectedType: string, selectedEntity: any) => {
    if (selectedType === 'customer' && !CheckIfCustomer(selectedEntity)) {
        return 'You must select a customer to continue.';
    } else if (
        selectedType === 'contract' &&
        !CheckIfVehicleSale(selectedEntity)
    ) {
        return 'You must select a contract to continue.';
    } else if (selectedType === 'vehicle' && !CheckIfVehicle(selectedEntity)) {
        return 'You must select a vehicle to continue.';
    } else if (selectedType === 'service' && !CheckIfService(selectedEntity)) {
        return 'You must select a booking to continue.';
    } else {
        return 'valid';
    }
};

export const ValidateParts = (
    orderlines: PartOrderline[],
    selectedType: string,
    status: string
) => {
    let returnMessage = 'valid';
    if (orderlines.length > 0) {
        orderlines.forEach((line) => {
            // If it is a guest sale and there is no quantity then return error
            if (selectedType === 'guest') {
                if (
                    parseInt(line.quantity) === 0 ||
                    !line.quantity ||
                    line.quantity === '' ||
                    parseFloat(line.quantity) % 1 !== 0
                ) {
                    returnMessage = `You must provide a quantity for the part ${line.partNumber}.`;
                    return;
                }
            } else {
                // If no quantity and no backorder quantity has been entered then return error
                if (
                    (parseInt(line.quantity) === 0 &&
                        parseInt(line.backorderQuantity) === 0) ||
                    line.quantity == null ||
                    line.backorderQuantity == null ||
                    (line.quantity === '' && line.backorderQuantity === '')
                ) {
                    returnMessage = `You must provide either a quantity or backorder quantity for the part ${line.partNumber}.`;
                    return;
                }

                // Checking if the float of the number modulo 1 does not equal 0 helps us determine if it is a decimal number
                if (parseFloat(line.quantity) % 1 !== 0) {
                    returnMessage = `You can not have decimals in the quantity for ${line.partNumber}.`;
                    return;
                }

                if (parseFloat(line.backorderQuantity) % 1 !== 0) {
                    returnMessage = `You can not have decimals in the backorder quantity for ${line.partNumber}.`;
                    return;
                }

                // If it is a sale and a backorder quantity has been enetered return error
                if (status === 'Sale') {
                    if (parseInt(line.backorderQuantity) > 0) {
                        returnMessage = `You cannot create backorders in a sale, please check part ${line.partNumber}.`;
                        return;
                    }
                }
            }

            // A unit price has not been entered return error
            if (
                parseFloat(line.pricePaid) <= 0 ||
                !line.pricePaid ||
                line.pricePaid === ''
            ) {
                returnMessage = `You must provide a unit price for the part ${line.partNumber}.`;
                return;
            }
        });
    }

    return returnMessage;
};

export const ValidateExtraCosts = (
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[]
) => {
    let returnMessage = 'valid';

    extraCosts.forEach((line) => {
        // If no type has been selected return error
        if (!line.type || line.type === '') {
            returnMessage = `You must select a type for each of the extra costs.`;
            return;
        }

        // If no amount has been selected return error
        else if (!line.amount || parseFloat(line.amount) <= 0) {
            returnMessage = `You must provide an amount greater than 0 for each of the extra costs.`;
            return;
        }
    });

    return returnMessage;
};

export const ValidatePayments = (
    paymentLines: PaymentLine[],
    status: string,
    saleDetails: Order,
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[]
) => {
    let returnMessage = 'valid';
    let saleTotal = SaleTotal(saleDetails, extraCosts);

    let paymentTotal = 0;
    paymentLines.forEach((line) => {
        if (!line.removed) {
            const amount =
                typeof line.amount === 'string' ? +line.amount : line.amount;
            paymentTotal += amount;
        }
    });

    // If it is a sale and the sale total is greater than the payment total return error
    if (status === 'Sale' && saleTotal > ToFixed(paymentTotal)) {
        returnMessage = 'There is not enough payment to cover the sale total.';
    }

    return returnMessage;
};

export const ValidateSaleSplitting = (
    paymentLines: PaymentLine[],
    saleDetails: Order,
    selectedType: string
) => {
    let paymentTotal = 0;

    paymentTotal = PaymentTotal(paymentLines);

    if (selectedType === 'contract') {
        paymentTotal = saleDetails.amountPaid;
    } else if (selectedType !== 'customer') {
        paymentTotal = saleDetails.totalPrice;
    }

    let collectionTotal = 0;
    saleDetails.orderLines.forEach((line) => {
        if (!line.inactive && line.collecting) {
            collectionTotal +=
                parseInt(line.quantity) * parseFloat(line.pricePaid);
        }
    });

    if (collectionTotal > paymentTotal) {
        return 'There is not enough payment to cover the split sale total.';
    }

    return 'valid';
};

export const ValidateAllCollected = (orderLines: PartOrderline[]) => {
    let allCollected = true;

    orderLines.forEach((line) => {
        if (!line.collecting || parseInt(line.backorderQuantity) > 0) {
            allCollected = false;
        }
    });

    return allCollected;
};
