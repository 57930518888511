import { Dispatch, SetStateAction } from 'react';
import {
    TradeIn,
    Vehicle
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    ValidateVin,
    ValidateYear
} from '../../../global/logic/vehicleValidation/ValidateVehicleFields';
import { Site } from '../../../global/interfaces/GeneralInterface';

const AddVehicleToList = (
    newTrade: Vehicle,
    showSnackbar: showSnackbar,
    trades: TradeIn[],
    setTrades: Dispatch<SetStateAction<TradeIn[]>>,
    setVehicleFormOpen: Dispatch<SetStateAction<boolean>>,
    siteDetails: Site
) => {
    if (!newTrade.MakeId) {
        showSnackbar(
            'Missing required fields!',
            'You must enter a make for the vehicle.',
            'error'
        );
        return;
    }
    if (!newTrade.ModelId) {
        showSnackbar(
            'Missing required fields!',
            'You must enter a model for the vehicle.',
            'error'
        );
        return;
    }
    if (!newTrade.year) {
        showSnackbar(
            'Missing required fields!',
            'You must enter a year model for the vehicle.',
            'error'
        );
        return;
    }
    if (siteDetails.vehicleTypeSold === 'Motorcycles') {
        if (!newTrade.vin) {
            showSnackbar(
                'Missing required fields!',
                'You must enter a VIN for the vehicle.',
                'error'
            );
            return;
        }
        let validateVin = ValidateVin(newTrade.vin);
        if (validateVin !== 'valid') {
            showSnackbar(validateVin, '', 'error');
            return;
        }
        if (!newTrade.FuelTypeId) {
            showSnackbar(
                'Missing required fields!',
                'You must enter a fuel type for the vehicle.',
                'error'
            );
            return;
        }
        if (!newTrade.cylinders) {
            showSnackbar(
                'Missing required fields!',
                'You must enter the cylinders for the vehicle.',
                'error'
            );
            return;
        }
    }
    if (!newTrade.costPrice) {
        showSnackbar(
            'Missing required fields!',
            'You must enter a base cost for the vehicle.',
            'error'
        );
        return;
    }

    let validateYear = ValidateYear(newTrade.year);
    if (validateYear !== 'valid') {
        showSnackbar(validateYear, '', 'error');
        return;
    }

    let currentTrades = [...trades];

    let newTradeLine = {
        Vehicle: newTrade,
        tradeInPrice: newTrade.costPrice
    };
    currentTrades.push(newTradeLine);

    setTrades(currentTrades);
    setVehicleFormOpen(false);
};

export default AddVehicleToList;
