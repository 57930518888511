// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Creditor, Site } from '../../../global/interfaces/GeneralInterface';
import { Account } from '../../../global/interfaces/AdminInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { ToFixed } from '../../../global/logic/Formatters';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface InvoiceObject {
    id?: number;
    Creditor?: Creditor;
    Site?: Site;
    Service?: Service;
    Account: Account;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
    documentReference: string;
}

interface InvoiceLine {
    id: number;
    Service?: Service;
    amount: string;
    description: string;
}

function SubmitServiceWarranty(
    invoiceObject: InvoiceObject,
    invoiceLines: InvoiceLine[],
    invoiceTotal: number,
    responseCode: number,
    selectedSite: number,
    showSnackbar: showSnackbar,
    setIsSubmittingInvoice: Dispatch<SetStateAction<boolean>>
) {
    setIsSubmittingInvoice(true);

    if (!invoiceObject.Creditor && !invoiceObject.Account) {
        showSnackbar(
            'There is Missing Data!',
            'Please select a Creditor or an Account on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (!invoiceObject.documentDate) {
        showSnackbar(
            'There is Missing Data!',
            'Please enter the document date on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (!invoiceObject.GSTtype) {
        showSnackbar(
            'There is Missing Data!',
            'Please select the GST Type used on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (!invoiceObject.documentTotal) {
        showSnackbar(
            'There is Missing Data!',
            'Please enter the document total on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (invoiceLines.length === 0) {
        showSnackbar(
            'There is Missing Data!',
            'Please enter at least one invoice line.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (
        ToFixed(invoiceTotal) !== parseFloat(invoiceObject.documentTotal)
    ) {
        showSnackbar(
            'There is Mismatching Data!',
            'Your line total and document total do not match.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (!invoiceObject.documentReference) {
        showSnackbar(
            'There is Missing Data!',
            'Please enter the document reference on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (responseCode === 400) {
        showSnackbar(
            'There is Invalid Data!',
            'The document reference already exists on another invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    }

    // if invoice object has an id, we are updating exsiting invoice; otherwiese create new invoice
    const method = invoiceObject.id ? 'put' : 'post';

    api[method]('serviceWarranty', {
        invoiceObject: invoiceObject,
        invoiceLines: invoiceLines,
        selectedSite: selectedSite
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Invoice Submitted Successfully.',
                'The page will now refresh.',
                'success'
            );

            // Set a timeout to reload the page after 3 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact the IT department.',
                    'error'
                );
            } else {
                showSnackbar(
                    res.data.split('|')[0],
                    res.data.split('|')[1],
                    'error'
                );
            }
            setIsSubmittingInvoice(false);
        }
    });
}

export default SubmitServiceWarranty;
