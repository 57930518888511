import jsPDF from "jspdf";
import { CurrencyFormatter } from "../../../../../../global/logic/Formatters"

interface NetDealershipProfitParams {
    (
        doc: jsPDF,
        totalNOE: { current: number; ytd: number; previousYear: number; previousYearYtd: number },
        netDivisionalProfit: {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        },
        afterNOE: number
    ): [
        number,
        {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        }
    ];
}

const NetDealershipProfit: NetDealershipProfitParams = (doc, totalNOE, netDivisionalProfit, afterNOE) => {
    let netDealershipProfit = {
        current: netDivisionalProfit.current + totalNOE.current,
        ytd: netDivisionalProfit.ytd + totalNOE.ytd,
        previousYear: netDivisionalProfit.previousYear + totalNOE.previousYear,
        previousYearYtd: netDivisionalProfit.previousYearYtd + totalNOE.previousYearYtd
    }
    // doc.setFont('helvetica', 'bold').text('TOTAL NON OPERATING EXPENSES', 10, afterNOE)
    // doc.text(CurrencyFormatter(totalNOE.current), 74, afterNOE, {align:'right'})
    // doc.text(CurrencyFormatter(totalNOE.ytd), 107, afterNOE, {align:'right'})
    // doc.text(CurrencyFormatter(totalNOE.previousYear), 140, afterNOE, {align:'right'})
    // doc.text(CurrencyFormatter(totalNOE.previousYearYtd ), 173, afterNOE, {align:'right'})

    doc.setFont('helvetica', 'bold').text('NET DEALERSHIP PROFIT', 10, afterNOE + 4)
    doc.text(CurrencyFormatter(netDealershipProfit.current), 74, afterNOE + 4, {align:'right'})
    doc.text(CurrencyFormatter(netDealershipProfit.ytd), 107, afterNOE + 4, {align:'right'})
    doc.text(CurrencyFormatter(netDealershipProfit.previousYear), 140, afterNOE + 4, {align:'right'})
    doc.text(CurrencyFormatter(netDealershipProfit.previousYearYtd), 173, afterNOE + 4, {align:'right'})

    return [afterNOE + 8, netDealershipProfit]
}

export default NetDealershipProfit