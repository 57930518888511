const CalculateCashTotals = (
  cash: {
    name: string;
    value: number;
    count: string;
    quantity: string;
    banking: string;
  }[]
) => {
  let currentCash = [...cash];

  let countedTotal = 0;
  let bankingTotal = 0;
  let floatTotal = 0;

  currentCash.forEach((cash) => {
    if (cash.count) {
      countedTotal += parseFloat(cash.count);
    }
    if (cash.banking) {
      bankingTotal += parseFloat(cash.banking) * cash.value;
    }
  });

  floatTotal = countedTotal - bankingTotal;

  return {
    countedTotal: countedTotal,
    bankingTotal: bankingTotal,
    floatTotal: floatTotal
  };
};

export default CalculateCashTotals;
