// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Grid } from '@mui/material';
// COMPONENTS
import TotalsForm from './TotalsForm';
import Parts from './chargesComponents/Parts';
import Sublets from './chargesComponents/Sublets';
import Miscellaneous from './chargesComponents/Miscellaneous';
import Prices from './chargesComponents/Prices';
import PrintButtons from './PrintButtons';
// INTERFACES
import {
    CustomerVehicle,
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
interface ChargesProps {
    id: number;
    readOnly: boolean;
    serviceDetails: Service;
    setServiceDetails: Dispatch<SetStateAction<Service>>;
    sublets: PurchaseOrder[];
    setSublets: Dispatch<SetStateAction<PurchaseOrder[]>>;
    partSales: Order[];
    setPartSales: Dispatch<SetStateAction<Order[]>>;
    selectedCustomer: Customer;
    vehicleType: string;
    selectedVehicle: Vehicle | CustomerVehicle;
    selectedInsurer: Customer;
    showSnackbar: showSnackbar;
}
const Charges = ({
    id,
    readOnly,
    serviceDetails,
    setServiceDetails,
    sublets,
    partSales,
    setPartSales,
    selectedCustomer,
    vehicleType,
    selectedVehicle,
    setSublets,
    selectedInsurer,
    showSnackbar
}: ChargesProps) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Parts
                        readOnly={readOnly}
                        serviceDetails={serviceDetails}
                        setServiceDetails={setServiceDetails}
                        partSales={partSales}
                        setPartSales={setPartSales}
                        showSnackbar={showSnackbar}
                    />
                    <br />
                    <Sublets
                        id={id}
                        readOnly={readOnly}
                        serviceDetails={serviceDetails}
                        setServiceDetails={setServiceDetails}
                        sublets={sublets}
                        setSublets={setSublets}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Miscellaneous
                                readOnly={readOnly}
                                serviceDetails={serviceDetails}
                                setServiceDetails={setServiceDetails}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Prices
                                readOnly={readOnly}
                                serviceDetails={serviceDetails}
                                setServiceDetails={setServiceDetails}
                                partSales={partSales}
                                sublets={sublets}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <TotalsForm
                        serviceDetails={serviceDetails}
                        sublets={sublets}
                        partSales={partSales}
                    />
                    <br />
                    {readOnly && (
                        <PrintButtons
                            serviceDetails={serviceDetails}
                            selectedCustomer={selectedCustomer}
                            vehicleType={vehicleType}
                            selectedVehicle={selectedVehicle}
                            partSales={partSales}
                            selectedInsurer={selectedInsurer}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default Charges;
