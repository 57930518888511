import { useTheme } from '@mui/material';
import { Pie } from '@nivo/pie';

const PieChart = ({ data, height = 250, width = 600 }) => {
    let currentMode = useTheme().palette.mode;

    const theme = {
        text: {
            fontSize: 11,
            fill: '#ffffff',
            outlineWidth: 0,
            outlineColor: '#ffffff'
        },
        axis: {
            domain: {
                line: {
                    stroke: '#ffffff',
                    strokeWidth: 1
                }
            },
            legend: {
                text: {
                    fontSize: 12,
                    fill: '#ffffff',
                    outlineWidth: 0,
                    outlineColor: '#ffffff'
                }
            },
            ticks: {
                line: {
                    stroke: '#ffffff',
                    strokeWidth: 1
                },
                text: {
                    fontSize: 11,
                    fill: '#ffffff',
                    outlineWidth: 1,
                    outlineColor: '#ffffff'
                }
            }
        },
        labels: {
            text: {
                fill: '#ffffff'
            }
        },
        grid: {
            line: {
                stroke: '#ffffff',
                strokeWidth: 1
            }
        },
        legends: {
            title: {
                text: {
                    fontSize: 11,
                    fill: '#ffffff',
                    outlineWidth: 0,
                    outlineColor: '#ffffff'
                }
            },
            text: {
                fontSize: 11,
                fill: '#ffffff',
                outlineWidth: 0,
                outlineColor: '#ffffff'
            },
            ticks: {
                line: {},
                text: {
                    fontSize: 10,
                    fill: '#ffffff',
                    outlineWidth: 0,
                    outlineColor: '#ffffff'
                }
            }
        },
        annotations: {
            text: {
                fontSize: 13,
                fill: '#ffffff',
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1
            },
            link: {
                stroke: '#ffffff',
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1
            },
            outline: {
                stroke: '#ffffff',
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1
            },
            symbol: {
                fill: '#ffffff',
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1
            }
        },
        tooltip: {
            container: {
                background: '#000000',
                fontSize: 12
            },
            basic: {},
            chip: {},
            table: {},
            tableCell: {},
            tableCellValue: {}
        }
    };

    return (
        <Pie
            data={data}
            height={height}
            width={width}
            margin={{ top: 20, bottom: 20 }}
            sortByValue={true}
            innerRadius={0.7}
            colors={{ scheme: 'nivo' }}
            enableArcLabels={true}
            arcLabelsRadiusOffset={1.5}
            enableArcLinkLabels={false}
            theme={currentMode === 'dark' ? theme : null}
            legends={[
                {
                    anchor: 'left',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemsSpacing: 0,
                    symbolSize: 20,
                    itemDirection: 'left-to-right'
                }
            ]}
        />
    );
};

export default PieChart;
