import { Step } from '../../interface';

export const AddGeneralJournalEntrySteps: Step[] = [
    {
        label: "In the sidebar, click 'Admin' > 'General Journal' to navigate to the General Journal Entry page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/generalJournal/generalJournal-01.png'
    },
    {
        label: "Select an account from the 'Account' dropdown list.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/generalJournal/generalJournal-02.png'
    },
    {
        label: 'Add a description, date received, GST type, and either a debit or credit amount. Ensure to clear the debit field before entering a credit, and vice versa.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/generalJournal/generalJournal-03.png'
    },
    {
        label: "Click 'Add Line' to include it in the General Journal Entry. Repeat steps 2 and 3 to add more lines.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/generalJournal/generalJournal-04.png'
    },
    {
        label: 'Ensure that the total debit amount equals the total credit amount; otherwise, you will receive an error message.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/generalJournal/generalJournal-05.png'
    },
    {
        label: "Click 'Save' to submit the General Journal Entry.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/generalJournal/generalJournal-06.png'
    },
    {
        label: 'You will see a success message if the General Journal Entry is saved successfully.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/generalJournal/generalJournal-07.png'
    },
    {
        label: "Find the newly created General Journal Entry in the Account's transactions list. Click its ID to navigate to the details page where you can update or delete the General Journal Entry.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/generalJournal/generalJournal-08.png'
    }
];
