import { Dispatch, SetStateAction } from 'react';
import { ManualTransaction } from '../../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleAddItem = (
    selectedType: string,
    manualTransaction: ManualTransaction,
    manualTransactions: ManualTransaction[],
    setManualTransactions: Dispatch<SetStateAction<ManualTransaction[]>>,
    showSnackbar: showSnackbar
) => {
    if (selectedType === 'account' && !manualTransaction.AccountId) {
        showSnackbar(
            'No account selected.',
            'Please select an account to continue.',
            'error'
        );
        return;
    } else if (selectedType === 'vehicle' && !manualTransaction.VehicleId) {
        showSnackbar(
            'No vehicle selected.',
            'Please select a vehicle to continue.',
            'error'
        );
        return;
    }

    if (!manualTransaction.reference) {
        showSnackbar(
            'No reference entered.',
            'Please enter a reference to continue.',
            'error'
        );
        return;
    } else if (!manualTransaction.dateReceived) {
        showSnackbar(
            'No date received selected.',
            'Please select a date received to continue.',
            'error'
        );
        return;
    } else if (
        (!manualTransaction.debit && !manualTransaction.credit) ||
        (manualTransaction.debit === 0 && manualTransaction.credit === 0)
    ) {
        showSnackbar(
            'No debit or credit amount entered.',
            'Please enter a debit or credit amount to continue.',
            'error'
        );
        return;
    }

    let currentManualTransactions = [...manualTransactions];
    let highestId = 0;
    for (let manualT of currentManualTransactions) {
        if (manualT.id > highestId) {
            highestId = manualT.id;
        }
    }

    currentManualTransactions.push({ ...manualTransaction, id: highestId + 1 });
    setManualTransactions(currentManualTransactions);
};

export default HandleAddItem;
