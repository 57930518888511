import { Typography } from '@mui/material';

const ForbiddenPage = () => {
    return (
        <>
            <Typography textAlign={'center'}>
                <img
                    src={window.location.origin + '/403_background.png'}
                    style={{ height: '80vh' }}
                    alt="You don't have access to this page."
                />
            </Typography>
        </>
    );
};

export default ForbiddenPage;
