// IMPORTS
import { ReactNode, Dispatch, SetStateAction } from 'react';
import {
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton
} from '@mui/material';
import { Straight, SwapVert } from '@mui/icons-material';
import MuiTable from '@mui/material/Table';

// COMPONENTS
import SpeedDial from '../SpeedDial';

interface Column {
  id: number;
  label;
  align?: string;
  width?: number;
  sort?: boolean;
  name?: string;
  colSpan?: number;
  textAlign?: string;
}

interface ActionsList {
  icon: JSX.Element;
  name: string;
  onClick: () => any;
  permission?: string;
  cypressLabel?: string
}
interface PaperProps {
  columns: Column[];
  children: ReactNode;
  sort?: string[] | [];
  setSort?: Dispatch<SetStateAction<string[]>>;
  showSpeedDial?: boolean;
  actionsList?: ActionsList[];
  cypressLabel?: string
}

// Formatted table that handles column sorting
const DataTable = ({
    columns,
    children,
    sort,
    setSort,
    showSpeedDial,
    actionsList,
    cypressLabel
}: PaperProps) => {
    return (
        <>
            <MuiTable size="small" sx={{ minWidth: '1000' }} data-cy={cypressLabel ? cypressLabel : ''}>
                <TableHead>
                    <TableRow>
                        {columns?.map((col) => (
                            <TableCell
                                key={col.id}
                                width={col.width}
                                colSpan={col.colSpan ?? 0}
                                sx={{
                                    textAlign: col.textAlign
                                        ? col.textAlign
                                        : 'center',
                                    fontWeight: 'bold'
                                }}
                            >
                                {col.label}
                                {col.sort ? ( // If the column can be sorted
                                    <>
                                        {
                                            sort[0] === col.name ? ( // Check if the current state for sort matches the column name
                                                sort[1] === 'DESC' ? ( // Check what direction the column is being sorted, options are DESC or ASC
                                                    <IconButton
                                                        onClick={() =>
                                                            setSort([
                                                                col.name,
                                                                'ASC'
                                                            ])
                                                        }
                                                    >
                                                        <Straight
                                                            fontSize="small"
                                                            sx={{
                                                                transform:
                                                                    'rotate(180deg)'
                                                            }}
                                                        />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        onClick={() =>
                                                            setSort([
                                                                col.name,
                                                                'DESC'
                                                            ])
                                                        }
                                                    >
                                                        <Straight fontSize="small" />
                                                    </IconButton>
                                                )
                                            ) : (
                                                <IconButton
                                                    onClick={() =>
                                                        setSort([
                                                            col.name,
                                                            'DESC'
                                                        ])
                                                    }
                                                >
                                                    <SwapVert fontSize="small" />
                                                </IconButton>
                                            ) // If the column can be sorted but isn't currently
                                        }
                                    </>
                                ) : null}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{children}</TableBody>
            </MuiTable>

            {showSpeedDial ? <SpeedDial actionsList={actionsList} cypressLabel={"dataTableFAB"} /> : null}
        </>
    );
};

export default DataTable;
