import { Step } from '../../../interface';

export const StaffSalesReportsSteps: Step[] = [
    {
        label: 'In the sidebar, navigate to Admin -> Reporting to open the Ora Generated Reports page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-01.png'
    },
    {
        label: 'Under the Staff Sales Reports tab, select one or multiple sites from the Site dropdown list.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/sales-02.png'
    },
    {
        label: 'Select a reporting start date and end date for your desired report period.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/sales-03.png'
    },
    {
        label: 'The "Generate Report" button will be disabled until at least one site and both dates are selected. Once these are set, click the button to generate the sales report.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/sales-04.png'
    },
    {
        label: 'The report will be displayed total sales number and profits of each staff.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/sales-05.png'
    }
];
