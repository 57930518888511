// Handles removing a part from the transfer
const HandleRemoveParts = (index, transferData, setTransferData) => {
    // Get the current transfer data
    let newTransferData = JSON.parse(JSON.stringify(transferData))

    // Splice out the line being removed
    newTransferData.transferLines.splice(index, 1)

    setTransferData(newTransferData)
}

export default HandleRemoveParts