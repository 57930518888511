import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

export default function FortnightView({
    date,
    localizer,
    max = localizer.endOf(new Date(), 'day'),
    min = localizer.startOf(new Date(), 'day'),
    scrollToTime = localizer.startOf(new Date(), 'day'),
    ...props
}) {
    const currRange = useMemo(
        () => FortnightView.range(date, { localizer }),
        [date, localizer]
    );

    return (
        <TimeGrid
            date={date}
            eventOffset={15}
            localizer={localizer}
            max={max}
            min={min}
            range={currRange}
            scrollToTime={scrollToTime}
            {...props}
        />
    );
}

FortnightView.range = (date, { localizer }) => {
    let start = localizer.startOf(date, 'week');
    start = localizer.add(start, 1, 'day');

    let end = localizer.add(start, 7, 'day');
    end = localizer.endOf(end, 'week');

    let current = start;
    const range = [];

    while (localizer.lte(current, end, 'day')) {
        if (dayjs(current).day() !== 0) {
            range.push(current);
        }
        current = localizer.add(current, 1, 'day');
    }

    return range;
};

FortnightView.navigate = (date, action, { localizer }) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return localizer.add(date, -14, 'day');

        case Navigate.NEXT:
            return localizer.add(date, 14, 'day');

        default:
            return date;
    }
};

FortnightView.title = (date, { localizer }) => {
    const [start, ...rest] = FortnightView.range(date, { localizer });
    return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
};
