import { Table, TableRow, TableCell, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Registration } from '../../../../global/interfaces/VehicleSaleInterface';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import GetQLDRegsitration from '../../../../global/databaseLogic/GetQLDRegistration';

const NSWRegistration = () => {
    const [registrationPrices, setRegistrationPrices] = useState<
        Registration[]
    >([]);

    useEffect(() => {
        GetQLDRegsitration(setRegistrationPrices);
    }, []);

    const getRegistrationPrice = (length: string) => {
        let filteredRegsitrationPrice = registrationPrices.filter(
            (x) => x.length === length
        );
        return filteredRegsitrationPrice[0]?.prices[0];
    };
    return (
        <>
            <Table>
                <TableRow>
                    <TableCell align="center">
                        <Typography variant="subtitle1">
                            <b>6 Months</b>
                        </Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="subtitle1">
                            <b>12 Months</b>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        {`Motor Vehicle Fee: ${CurrencyFormatter(getRegistrationPrice('6')?.motorVehicleFee)}`}
                        <br />
                        {`Traffic Fee: ${CurrencyFormatter(getRegistrationPrice('6')?.trafficFee)}`}
                        <br />
                        {`Plate Fee: ${CurrencyFormatter(getRegistrationPrice('6')?.plateFee)}`}
                        <br />
                        <b>{`Total: ${CurrencyFormatter(getRegistrationPrice('6')?.total)}`}</b>
                    </TableCell>
                    <TableCell align="center">
                        {`Motor Vehicle Fee: ${CurrencyFormatter(getRegistrationPrice('12')?.motorVehicleFee)}`}
                        <br />
                        {`Traffic Fee: ${CurrencyFormatter(getRegistrationPrice('12')?.trafficFee)}`}
                        <br />
                        {`Plate Fee: ${CurrencyFormatter(getRegistrationPrice('12')?.plateFee)}`}
                        <br />
                        <b>{`Total: ${CurrencyFormatter(getRegistrationPrice('12')?.total)}`}</b>
                    </TableCell>
                </TableRow>
            </Table>
        </>
    );
};

export default NSWRegistration;
