// LOGIC
import {
    ValidateType,
    ValidateParts,
    ValidateExtraCosts,
    ValidatePayments,
    ValidateSaleSplitting,
    ValidateAllCollected
} from '../SaleDataValidation';
import UpdateCustomerSale from './UpdateCustomerSale';
import UpdateContractSale from './UpdateContractSale';
import UpdateServiceSale from './UpdateServiceSale';
import UpdateWorkshopSale from './UpdateWorkshopSale';
import UpdateSalesSale from './UpdateSalesSale';
import UpdateOnlineSale from './UpdateOnlineSale';
import UpdateVehicleSale from './UpdateVehicleSale';
// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import {
    OnlineOrder,
    Order
} from '../../../../global/interfaces/PartsInterface';
import { PaymentLine } from '../../../../global/interfaces/GeneralInterface';
import UpdateGuestSale from './UpdateGuestSale';
import { safeCalculateAmountDiff } from '../../../../global/logic/Operators';

const calculateLeftToPay = (saleDetails) => {
    let total = 0;
    for (let line of saleDetails.orderLines) {
        if (!line.inactive) {
            total += line.totalPrice;
        }
    }
    return total;
};

const HandleUpdateSale = (
    id: number,
    selectedType: string,
    selectedEntity: any,
    saleDetails: Order,
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[],
    paymentLines: PaymentLine[],
    associatedOnlineOrder: OnlineOrder,
    handlePrintInvoice: (
        hasCustomer: boolean,
        saleData: Order,
        redirectId?: number | boolean,
        vouchers?: any[]
    ) => void,
    showSnackbar: showSnackbar
) => {
    // Validate that the entity is correct
    let validEntity = ValidateType(selectedType, selectedEntity);
    if (validEntity !== 'valid') {
        showSnackbar(validEntity, '', 'error');
        return;
    }

    // Validate the parts
    let partOrderLines = saleDetails.orderLines?.filter(
        (line) => line.UnitId != null
    );

    let validParts = ValidateParts(
        partOrderLines,
        selectedType,
        saleDetails.status
    );

    if (validParts !== 'valid') {
        showSnackbar(validParts, '', 'error');
        return;
    }

    // Validate the extra costs
    let validExtraCosts = ValidateExtraCosts(extraCosts);
    if (validExtraCosts !== 'valid') {
        showSnackbar(validExtraCosts, '', 'error');
        return;
    }

    // Validate the payment lines if it is not an online order
    if (selectedType === 'customer' || selectedType === 'guest') {
        let validPaymentLines = ValidatePayments(
            paymentLines,
            saleDetails.status,
            saleDetails,
            extraCosts
        );
        if (validPaymentLines !== 'valid') {
            showSnackbar(validPaymentLines, '', 'error');
            return;
        }
    }

    // Shipping details for an online order
    if (selectedType === 'online' && !associatedOnlineOrder?.OnlineOrderId) {
        showSnackbar(
            'Shipping details are missing!',
            'These are required in an online order.',
            'error'
        );
        return;
    }

    // Sale splitting validation
    if (saleDetails.status === 'Proforma') {
        let validSplitSale = ValidateSaleSplitting(
            paymentLines,
            saleDetails,
            selectedType
        );
        if (validSplitSale !== 'valid') {
            showSnackbar(validSplitSale, '', 'error');
            return;
        }

        let allCollected = ValidateAllCollected(saleDetails.orderLines);
        if (allCollected) {
            showSnackbar(
                'You can not mark all items as collected.',

                'If the customer is taking all the parts, mark the order as a sale.',
                'error'
            );
            return;
        }
    }

    if (
        selectedType === 'contract' &&
        saleDetails.status === 'Sale' &&
        safeCalculateAmountDiff(
            saleDetails.amountPaid,
            calculateLeftToPay(saleDetails)
        ) < 0
    ) {
        showSnackbar(
            'CSM add-ons have not been paid!',

            'Add-ons must be paid through the contract before converting to a sale.',
            'error'
        );
        return;
    }

    if (selectedType === 'customer')
        UpdateCustomerSale(
            id,
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'contract')
        UpdateContractSale(
            id,
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'vehicle')
        UpdateVehicleSale(
            id,
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'service')
        UpdateServiceSale(
            id,
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'workshop')
        UpdateWorkshopSale(
            id,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'sales')
        UpdateSalesSale(
            id,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'online')
        UpdateOnlineSale(
            id,
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
    else if (selectedType === 'guest')
        UpdateGuestSale(
            id,
            selectedEntity,
            saleDetails,
            extraCosts,
            paymentLines,
            handlePrintInvoice,
            showSnackbar
        );
};

export default HandleUpdateSale;
