import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { Ctp } from '../interfaces/VehicleSaleInterface';

const GetNSWCtp = (setCtps: Dispatch<SetStateAction<Ctp[]>>) => {
  api.get('nswCtp').then((res) => {
    setCtps(res.data);
  });
};

export default GetNSWCtp;
