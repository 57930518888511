// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { DialogContentText, Grid } from '@mui/material';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';

interface TemplateTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const departmentOptions = [
    { id: 0, label: 'Parts and Accessories', value: 'Parts and Accessories' },
    { id: 1, label: 'Service', value: 'Service' },
    { id: 2, label: 'Sales', value: 'Sales' },
    { id: 3, label: 'Online', value: 'Online' },
    { id: 4, label: 'General', value: 'General' }
];

const typeOptions = [
    { id: 0, label: 'Text Message', value: 'Text Message' },
    { id: 1, label: 'Email', value: 'Email' }
];

const TemplateTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: TemplateTableFilterProps) => {
    const [departments, setDepartments] = useState(departmentOptions);
    const [types, setTypes] = useState(typeOptions);

    // If filter is not active ensure all options are reset
    useEffect(() => {
        if (!isFilterActive()) {
            setDepartments(departmentOptions);
            setTypes(typeOptions);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.templateFilter;
        newFilter[property] = value;
        setFilter({ ...filter, templateFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {/* Department Filter */}
                    <AutocompleteMultiple
                        options={departments}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Department"
                        selectedValues={filter.templateFilter.department}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = departmentOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setDepartments(currentOptions);
                            handleUpdateFilter('department', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteMultiple
                        options={types}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Template Type"
                        selectedValues={filter.templateFilter.type}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = typeOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setTypes(currentOptions);
                            handleUpdateFilter('type', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default TemplateTableFilter;
