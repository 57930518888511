import api from '../../../../../../api';
import CalculateStockOrderTotalCost from '../CalculateStockOrderTotalCost';
import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Api } from '../../../../global/interfaces/GeneralInterface';
interface Table {
    OrderType: string;
    orderLines: TableOrderLine[];
    orderReference: string;
    supplierId: number;
    supplierName: string;
    tableId: number;
    totalCost: number;
}

interface TableOrderLine {
    OrderType: string; // probably not useful
    costPrice: number;
    gst: number;
    id: number;
    maxStock: number; // probably not used anymore
    minStock: number; // probably not used anymore
    name: string;
    partNumber: string;
    qtyOnHand: number;
    quantity: number;
    rowId: number;
    rowTotal: number;
    rrp: number;
    selected: boolean;
    supplier: any; // don't know what it's for

    linkedOrders?: number[];
    linkedOrdersQuantity?: number[];
    orderId?: number;
    costPriceDaily?: number;
    costPriceAverage?: number;
    supplierName?: string;
}

const GenerateHondaApiOrder = (
    obj: { generator: Api; table: Table },
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    showSnackbar: showSnackbar
) => {
    var postObj = {
        table: obj.table,
        totalCost: CalculateStockOrderTotalCost([obj.table]).toFixed(2),
        SiteId: obj.generator.SiteId
    };

    // Remove the generated order, so that the user can still submit the others orders without redoing the whole stock order
    let newTables = [];
    tables.forEach((table) => {
        // Check that current table in the loop is not the table we just ordered
        if (
            table.supplierName !== obj.table.supplierName ||
            table.OrderType !== obj.table.OrderType
        ) {
            newTables.push(table);
        }
    });
    setTables(newTables);

    api.post(`postHondaOrder`, postObj).then((res) => {
        if (res.status === 200) {
            showSnackbar('Honda stock order submitted successfully.');
        } else {
            showSnackbar(
                'Order not submitted. Please check your data or try again later.',
                '',
                'error'
            );
        }
    });
};

export default GenerateHondaApiOrder;
