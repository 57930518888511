import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { Registration } from '../interfaces/VehicleSaleInterface';

const GetQLDRegistration = (
  setRegistrations: Dispatch<SetStateAction<Registration[]>>
) => {
  api.get('qldRegistration').then((res) => {
    setRegistrations(res.data);
  });
};

export default GetQLDRegistration;
