import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { showSnackbar } from '../interfaces/GlobalInterface';
import { TrackingInformation } from '../interfaces/PartsInterface';

interface GetTrackingInformationProps {
    shipmentId: number;
    setTrackingInformation: Dispatch<SetStateAction<TrackingInformation>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const GetTrackingInformation = ({
    shipmentId,
    setTrackingInformation,
    setLoading,
    showSnackbar
}: GetTrackingInformationProps) => {
    setLoading(true);
    api.get(
        `trackingInformation/${shipmentId}?SiteId=${localStorage.getItem('SiteId')}`
    ).then((res) => {
        if (res.status === 200) {
            setTrackingInformation(res.data.tracking_results[0]);
            setLoading(false);
        } else {
            showSnackbar('Error in GetTrackingInformation', '', 'error');
            setLoading(false);
        }
    });
};

export default GetTrackingInformation;
