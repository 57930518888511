import { Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { calculateNSWStampDuty } from '../../logic/CalculateStampDuty';
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';

interface NSWStampDutyProps {
    saleDetails: VehicleSale;
    setSaleDetails: Dispatch<SetStateAction<VehicleSale>>;
}

const NSWStampDuty = ({ saleDetails, setSaleDetails }: NSWStampDutyProps) => {
    return (
        <>
            <Typography align="center" variant="h6">
                Estimate Stamp Duty:{' '}
                {calculateNSWStampDuty(saleDetails.vehicleSalePrice)}
            </Typography>
            <br />
            <Typography align="center">
                * This stamp duty estimate is for passenger vehicle only.
            </Typography>
            <Typography align="center">
                * Excludes motorcycles, buses for more than nine people, hearses
                and disability vehicles.{' '}
            </Typography>
        </>
    );
};

export default NSWStampDuty;
