import jsPDF from "jspdf";
import { CurrencyFormatter } from "../../../../../../global/logic/Formatters"

interface NetOperatingProfitParams {
    (
        doc: jsPDF,
        totalOI: { current: number; ytd: number; previousYear: number; previousYearYtd: number },
        operatingProfit: {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        },
        afterOI: number
    ): [
        number,
        {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        }
    ];
}

const NetOperatingProfit: NetOperatingProfitParams = (doc, totalOI, operatingProfit, afterOI) => {
    let netOperationalProfit = {
        current: operatingProfit.current + totalOI.current,
        ytd: operatingProfit.ytd + totalOI.ytd,
        previousYear: operatingProfit.previousYear + totalOI.previousYear,
        previousYearYtd: operatingProfit.previousYearYtd + totalOI.previousYearYtd
    }
    doc.setFont('helvetica', 'bold').text('TOTAL OTHER INCOME', 10, afterOI)
    doc.text(CurrencyFormatter(totalOI.current), 74, afterOI, {align:'right'})
    doc.text(CurrencyFormatter(totalOI.ytd), 107, afterOI, {align:'right'})
    doc.text(CurrencyFormatter(totalOI.previousYear), 140, afterOI, {align:'right'})
    doc.text(CurrencyFormatter(totalOI.previousYearYtd ), 173, afterOI, {align:'right'})

    doc.setFont('helvetica', 'bold').text('NET OPERATING PROFIT', 10, afterOI + 4)
    doc.text(CurrencyFormatter(netOperationalProfit.current), 74, afterOI + 4, {align:'right'})
    doc.text(CurrencyFormatter(netOperationalProfit.ytd), 107, afterOI + 4, {align:'right'})
    doc.text(CurrencyFormatter(netOperationalProfit.previousYear), 140, afterOI + 4, {align:'right'})
    doc.text(CurrencyFormatter(netOperationalProfit.previousYearYtd), 173, afterOI + 4, {align:'right'})

    doc.line(10, afterOI + 5, 200, afterOI + 5)

    return [afterOI + 8, netOperationalProfit]
}

export default NetOperatingProfit