// IMPORTS
import { useState } from 'react';
import { Typography, TableRow } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Site } from '../../global/interfaces/GeneralInterface';
import { Add } from '@mui/icons-material';

// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';

// LOGIC
import SpeedDial from '../../global/SpeedDial';

// Default values for the filter and sort for initial set state and clear filter
const clearedSort = ['name', 'ASC'];

const SiteTable = () => {
    const history = useHistory();
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<Site[]>([]);

    // Columns for the data table
    const columns = [
        { id: 0, label: 'Code', name: 'code', sort: true, width: 100 },
        { id: 1, label: 'Name', name: 'name', sort: true, width: 200 },
        { id: 2, label: 'Address', width: 300 },
        { id: 3, label: 'Phone Number', width: 100 },
        { id: 4, label: 'Email', width: 200 },
        { id: 5, label: 'ID', width: 50 }
    ];

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let apiString = ``;
        return apiString;
    };

    const actions = [
        {
            icon: <Add />,
            name: 'Add Site',
            onClick: () => history.push('/addSite'),
            cypressLabel: 'addSiteSpeedDialAction'
        }
    ];

    return (
        <PageWrapper>
            <Typography variant="h4">Site</Typography>
            <br />
            <TableSearch
                searchTitle="Search Site"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="siteSearchNextGen"
                setResultsList={setResultsList}
            ></TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={false}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                cypressLabel={`siteTableRedirectButton-${row.name}`}
                                handleClick={() =>
                                    history.push(`/viewSite/${row.id}`)
                                }
                            >
                                {row.code}
                            </DataCellColoured>
                            <DataCell>{row.name}</DataCell>
                            <DataCell>
                                {row.address} {row.suburb} {row.state}{' '}
                                {row.country} {row.postcode}
                            </DataCell>
                            <DataCell>{row.phoneNumber}</DataCell>
                            <DataCell>{row.email}</DataCell>
                            <DataCell>{row.id}</DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
            <SpeedDial actionsList={actions} cypressLabel='siteTableSpeedDial' />
        </PageWrapper>
    );
};

export default SiteTable;
