// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';

const SubmitTechTimeClock = (
  currentTime,
  ServiceId: number,
  TechId: number,
  refresh: boolean,
  setRefresh: Dispatch<SetStateAction<boolean>>
) => {
  api
    .post(`submitTechTimeClock?TechId=${TechId}&ServiceId=${ServiceId}`)
    .then((res) => {
      if (res.status === 200) {
        setRefresh(!refresh);
      }
    });
};

export default SubmitTechTimeClock;
