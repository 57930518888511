import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Model, Vehicle } from "../../../global/interfaces/VehicleSaleInterface";

const HandleModelIdClick = (model: Model, setSelectedMake: Dispatch<SetStateAction<Model>>, setOpenDialog: Dispatch<SetStateAction<boolean>>, setListofVehicles: Dispatch<SetStateAction<Vehicle[]>>, setFilterListOfVehicles: Dispatch<SetStateAction<Vehicle[]>>, setFilterValue: Dispatch<SetStateAction<string>>) => {
    api.get('vehiclesFromModel/' + model.id).then(res => {
        setListofVehicles(res.data)
        setFilterListOfVehicles(res.data)
    })
    setSelectedMake(model)
    setFilterValue('')
    setOpenDialog(true)
}

export default HandleModelIdClick;