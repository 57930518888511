interface ConvertArrayToFilterProps {
    array: any[];
    filterStart: string;
    selector: string;
    useId?: boolean;
}

/*  ConvertArrayToFilter
    Converts a JSON object to a query string for an api call
    params:
        - array: an array containing your filter
        - filterStart: the starting string of your qpi query
        - selector: the object in the array you wish to filter by
        - useId: if true will use the item id rather than the selector
    returns:
        - A string able to be used in an api query
*/
const ConvertArrayToFilter = ({
    array,
    filterStart,
    selector,
    useId
}: ConvertArrayToFilterProps) => {
    let filterString = filterStart;
    array.forEach((item) => {
        if (useId) {
            filterString +=
                typeof item.id === 'number'
                    ? item.id.toString() + ','
                    : item.id + ',';
        } else {
            filterString += item[selector] + ',';
        }
    });

    return filterString;
};

export default ConvertArrayToFilter;
