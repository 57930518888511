import DocumentationPage from '../DocumentationPage';
import { StockTransferSteps } from '../data/stockControl/stockTransfers';

const StockTransfersSupportPage = () => {
    const contentSections = [
        {
            id: 'stock-transfer',
            title: 'Manage Stock Transfer',
            href: '/support/inventory/stocktransfers/#stock-transfer',
            description:
                'To manage existing stock Transfers and create new ones, follow these outlined steps:',
            steps: StockTransferSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Stock Transfers"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default StockTransfersSupportPage;
