import api from '../../../../../api';
import moment from 'moment';

const HandleOpenROReport = (sites) => {
    let siteIds = '';
    if (sites.length > 0) {
        for (let site of sites) {
            if (siteIds === '') {
                siteIds = site.id.toString();
            } else {
                siteIds = siteIds + ',' + site.id.toString();
            }
        }
    }

    const calculateTotalPaid = (paymentLines) => {
        let total = 0;

        paymentLines.forEach((line) => {
            if (!line.removed) {
                total += parseFloat(line.amount);
            }
        });

        return total;
    };

    api.get(`/openROReport?SiteId=${siteIds}`).then((res) => {
        let data = res.data;

        let rows = [];
        let filename = 'OpenROReport_' + moment().format('DD/MM/YYYY');

        rows.push([
            'ID',
            'Type',
            'Date',
            'Customer',
            ' Stock Number',
            'Age (days)',
            'Total Price',
            'Total Paid',
            'Status',
            'Assigned Tech',
            'Location'
        ]);

        data.forEach((service) => {
            let startDay = moment(service.jobStart);
            let today = moment();
            let row = [
                service.id,
                service.jobType,
                moment(service.jobStart).format('DD/MM/YYYY'),
                service?.Customer?.companyName
                    ? service?.Customer?.companyName
                    : `${service?.Customer?.firstName} ${service?.Customer?.lastName}`,
                service?.Vehicle?.stockNumber,
                today.diff(startDay, 'days') + 1,
                service.totalPrice,
                calculateTotalPaid(service.paymentLines),
                service.serviceComplete,
                service.TechId
                    ? `${service?.Tech?.firstName} ${service?.Tech?.lastName}`
                    : '',
                service.Site.name
            ];
            rows.push(row);
        });

        let csvContent = 'data:text/csv;charset=utf-8,';
        rows.forEach(function (rowArray) {
            let row = rowArray.join(',');
            csvContent += row + '\r\n';
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    });
};

export default HandleOpenROReport;
