import {
    Creditor,
    Customer
} from '../../../global/interfaces/GeneralInterface';

interface ValidationManualInvoiceParams {
    (
        body: [string, number, number, number, number][],
        creditorData: Creditor,
        customerData: Customer,
        SiteId: number
    ): [
        validationData: {
            customerOrCreditorError: boolean;
            siteError: boolean;
            descriptionError: boolean;
            quantityError: boolean;
            amountError: boolean;
        },
        errorMessage: string
    ];
}

const ValidationManualInvoice: ValidationManualInvoiceParams = (
    body,
    creditorData,
    customerData,
    SiteId
) => {
    let validationData = {
        customerOrCreditorError: false,
        siteError: false,
        descriptionError: false,
        quantityError: false,
        amountError: false
    };
    let errorMessage = '';

    // Validate that there is a Customer or a Creditor selected
    if (
        (creditorData === undefined || creditorData.id == null) &&
        customerData.id == null
    ) {
        validationData.customerOrCreditorError = true;
        errorMessage = 'Please select a Creditor or a Customer.';
    }

    // Validate that a site has been selected
    if (SiteId === undefined || SiteId == null) {
        validationData.siteError = true;
        errorMessage =
            errorMessage +
            (errorMessage.length === 0
                ? 'Please select a Site.'
                : '\nPlease select a Site.');
    }

    // Validate body
    for (let line of body) {
        if (line[0].length === 0) {
            validationData.descriptionError = true;
            errorMessage =
                errorMessage +
                (errorMessage.length === 0
                    ? 'Please enter a description for each invoice lines.'
                    : '\nPlease enter a description for each invoice lines.');
        }
        if (line[1] === 0 || line[1] < 0 || typeof line[1] !== 'number') {
            validationData.quantityError = true;
            errorMessage =
                errorMessage +
                (errorMessage.length === 0
                    ? 'Please enter a positive quantity for each invoice lines.'
                    : '\nPlease enter a positive quantity for each invoice lines.');
        }
        if (line[2] === 0 || line[2] < 0 || typeof line[2] !== 'number') {
            validationData.amountError = true;
            errorMessage =
                errorMessage +
                (errorMessage.length === 0
                    ? 'Please enter a positive amount for each invoice lines.'
                    : '\nPlease enter a positive amount for each invoice lines.');
        }
    }

    return [validationData, errorMessage];
};

export default ValidationManualInvoice;
