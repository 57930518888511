import {
    Button,
    Table,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import Paper from '../../../global/Paper';
import { useState } from 'react';
import TransactionDrawer from './TransactionDrawer';
import { BankTransaction } from '../../../global/interfaces/AdminInterface';

const UnreconciledTransactionOverview = ({
    unreconciledTransactionsSelectedMonth
}: {
    unreconciledTransactionsSelectedMonth: BankTransaction[];
}) => {
    const currentMode = useTheme().palette.mode;

    // click on transaction count cell to open drawer
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [drawerTitle, setDrawerTitle] = useState<string>('');
    const [drawerData, setDrawerData] = useState<BankTransaction[]>([]);

    const handleOpenTransactionDrawer = (title, data) => {
        setDrawerOpen(true);
        setDrawerTitle(title);
        setDrawerData(data);
    };

    return (
        <Paper>
            <Table size="small">
                <TableRow>
                    <TableCell>
                        <Typography variant="h6" align="center">
                            <b>Total Unreconciled Transactions</b>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Tooltip title="This only counts in transactions that date received is in the current month. It does not include transactions from previous months.">
                            <Button
                                onClick={() =>
                                    handleOpenTransactionDrawer(
                                        'Unreconciled Transactions',
                                        unreconciledTransactionsSelectedMonth
                                    )
                                }
                            >
                                <Typography
                                    variant="h6"
                                    style={{
                                        color:
                                            currentMode === 'dark'
                                                ? '#a88ce2'
                                                : '#9370DB'
                                    }}
                                >
                                    {
                                        unreconciledTransactionsSelectedMonth.length
                                    }
                                </Typography>
                            </Button>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </Table>
            <TransactionDrawer
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                drawerTitle={drawerTitle}
                drawerData={drawerData}
            />
        </Paper>
    );
};

export default UnreconciledTransactionOverview;
