// IMPROTS
import { ReactNode } from 'react';
import TableCell from '@mui/material/TableCell';
import { useTheme } from '@mui/material';

interface PaperProps {
    children: ReactNode;
    textDecoration?: string;
    textAlign?: string;
    handleClick?: () => any;
    cypressLabel?: string;
}

// The same as a normal datacell except it is highlighted in the primary colour to represent a link
// and changes the cursor to a pointer
const DataCellColoured = ({
    handleClick,
    textDecoration,
    children,
    textAlign,
    cypressLabel
}: PaperProps) => {
    const currentMode = useTheme().palette.mode;
    return (
        <TableCell
            data-cy={cypressLabel ? cypressLabel : ''}
            sx={{
                textDecoration: textDecoration ? textDecoration : 'none',
                textAlign: textAlign ? textAlign : 'center',
                cursor: 'pointer',
                color: currentMode === 'dark' ? '#a88ce2' : '#9370DB'
            }}
            onClick={handleClick}
        >
            {children}
        </TableCell>
    );
};

export default DataCellColoured;
