import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';

import Papa from 'papaparse';
import DataTable from '../../../global/tableComponents/DataTable';
import { Clear } from '@mui/icons-material';
import { CustomerCSVExplanation } from './CustomerCSVExplanation';
import { ImportCustomerFromCSV } from '../logic/ImportCustomerFromCSV';
import {
    checkCustomerType,
    checkDOB,
    checkEmail,
    checkPostcode
} from '../logic/ImportCustomerValidation';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const ImportCustomerCSVContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [importedData, setImportedData] = useState([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [importedDataFilename, setImportedDataFilename] = useState([]);
    const [ignoreErrors, setIgnoreErrors] = useState<boolean>(false);

    var headerOptions = [
        { id: 0, label: 'First Name', value: 'firstName' },
        { id: 1, label: 'Last Name', value: 'lastName' },
        { id: 2, label: 'Company Name', value: 'companyName' },
        { id: 3, label: 'ABN', value: 'abn' },
        { id: 4, label: 'Customer Type', value: 'customerType' },
        { id: 5, label: 'Phone Number', value: 'phoneNumber' },
        { id: 6, label: 'Email', value: 'email' },
        { id: 7, label: 'Date of Birth', value: 'dateOfBirth' },
        { id: 8, label: 'Driver Licence Number', value: 'driverLicenceNumber' },
        { id: 9, label: 'Address Line 1', value: 'addressLine1' },
        { id: 10, label: 'Address Line 2', value: 'addressLine2' },
        { id: 11, label: 'Suburb', value: 'suburb' },
        { id: 12, label: 'Postcode', value: 'postcode' },
        { id: 13, label: 'State', value: 'state' },
        { id: 14, label: 'Country', value: 'country' }
    ];

    const parseCSV = (e) => {
        Papa.parse(e, {
            header: false,
            skipEmptyLines: 'greedy',
            complete: (results) => {
                var newData = [];
                let maxLength = 0;
                results.data.forEach((row, i) => {
                    newData.push(row);
                    maxLength = maxLength < row.length ? row.length : maxLength;
                });
                setImportedData(newData);

                let newHeaders = new Array(maxLength);
                newHeaders.fill('');
                setHeaders(newHeaders);
            }
        });
    };

    const importFile = (e) => {
        setImportedDataFilename(e.target.files[0].name);
        parseCSV(e.target.files[0]);
    };

    const deleteColumn = (index) => {
        let currentHeaders = [...headers];
        let currentLines = [...importedData];

        currentHeaders.splice(index, 1);
        for (let item of currentLines) {
            item.splice(index, 1);
        }

        setHeaders(currentHeaders);
        setImportedData(currentLines);
    };

    const deleteLine = (index) => {
        let currentLines = [...importedData];
        currentLines.splice(index, 1);
        setImportedData(currentLines);
    };

    const updateColumnsHeaders = (value, index) => {
        if (value === 'email') {
            let validEmails = checkEmail(index, importedData, showSnackbar);
            if (validEmails.verified) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'customerType') {
            let validCustomerType = checkCustomerType(
                index,
                importedData,
                showSnackbar
            );
            if (validCustomerType.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'postcode') {
            let validPostcode = checkPostcode(
                index,
                importedData,
                showSnackbar
            );
            if (validPostcode.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'dateOfBirth') {
            let validDOB = checkDOB(index, importedData, showSnackbar);
            if (validDOB.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else {
            let currentHeaders = [...headers];
            currentHeaders[index] = value;
            setHeaders(currentHeaders);
        }
    };

    const checkDisabled = () => {
        if (
            headers.includes('companyName') ||
            (headers.includes('firstName') && headers.includes('lastName'))
        ) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <DialogTitle>Import Customer CSV</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign={'center'}>
                        {importedData.length > 0 ? (
                            importedDataFilename + ' uploaded.'
                        ) : (
                            <>
                                <input
                                    onChange={(e) => importFile(e)}
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="outlined" component="span">
                                        Upload CSV
                                    </Button>
                                </label>
                            </>
                        )}
                    </Grid>
                    {importedData.length > 0 ? (
                        <Grid item xs={12}>
                            <Grid container spacing={0} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Use the below interface to select which
                                        columns in the CSV correspond to which
                                        Customer's property. You must at least
                                        select: First Name, Last Name or Company
                                        Name. The import button will be greyed
                                        out until you have at least one of the
                                        combination selected.
                                    </Typography>
                                    <Typography>
                                        Using the{' '}
                                        <Clear
                                            style={{ verticalAlign: 'middle' }}
                                        />{' '}
                                        button will delete the corresponding
                                        row/column from the imported data.
                                    </Typography>
                                    <Typography>
                                        This can be used to delete headers for
                                        example.
                                    </Typography>
                                    <Typography>
                                        By default Ora will check that each
                                        Customer has a first name and last name
                                        OR a company name. You can ignore this
                                        validation by checking the "Ignore Name
                                        Errors" checkbox at the bottom.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <CustomerCSVExplanation />
                    )}

                    <Grid item xs={12}>
                        <DataTable columns={[]}>
                            {importedData.length > 0 ? (
                                <>
                                    <TableRow>
                                        {headers.map((item, index) => (
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        deleteColumn(index)
                                                    }
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        {headers.map((item, index) => (
                                            <TableCell>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    value={item}
                                                    onChange={(e) => {
                                                        updateColumnsHeaders(
                                                            e.target.value,
                                                            index
                                                        );
                                                    }}
                                                >
                                                    {headerOptions.map(
                                                        (option) => (
                                                            <MenuItem
                                                                value={
                                                                    option.value
                                                                }
                                                                disabled={headers.includes(
                                                                    option.value
                                                                )}
                                                            >
                                                                {option.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    {importedData
                                        .slice(0, 5)
                                        .map((line, index) => (
                                            <TableRow>
                                                {line.map((item) => (
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                ))}
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteLine(index)
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    <TableRow>
                                        {headers.map((item) => (
                                            <TableCell>...</TableCell>
                                        ))}
                                        <TableCell>...</TableCell>
                                    </TableRow>
                                    {importedData
                                        .slice(
                                            importedData.length - 5,
                                            importedData.length
                                        )
                                        .map((line) => (
                                            <TableRow>
                                                {line.map((item) => (
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                ))}
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteLine(
                                                                importedData.findIndex(
                                                                    (x) =>
                                                                        x[0] ===
                                                                            line[0] &&
                                                                        x[1] ===
                                                                            line[1]
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </>
                            ) : null}
                        </DataTable>
                    </Grid>
                </Grid>
            </DialogContent>
            {importedData.length > 0 ? (
                <DialogActions>
                    Ignore Name Errors{' '}
                    <Checkbox
                        checked={ignoreErrors}
                        onChange={() => setIgnoreErrors(!ignoreErrors)}
                    />
                    <Button
                        variant="contained"
                        disabled={checkDisabled()}
                        onClick={() =>
                            ImportCustomerFromCSV(
                                importedData,
                                headers,
                                ignoreErrors,
                                showSnackbar
                            )
                        }
                    >
                        Import Customer(s)
                    </Button>
                </DialogActions>
            ) : null}
        </>
    );
};
