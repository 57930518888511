import dayjs, { Dayjs } from 'dayjs';

import api from '../../../../../api';

import { Customer } from '../../../global/interfaces/GeneralInterface';
import { Make, Model } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const SubmitLead = (
    customerDetails: Customer,
    bikeCondition: string,
    selectedMake: Make,
    selectedModel: Model,
    weeklyBudget: number,
    enquirySource: string,
    pickupDate: Dayjs,
    reason: string,
    setCustomerErrors,
    financeDetails,
    tradeInMake: Make,
    tradeInModel: Model,
    tradeInDetails,
    setTradeInErrors,
    notes: string,
    newNotes: string,
    showSnackbar: showSnackbar,
    selectedEnquiryId: number,
    status: string
) => {
    let customerErrorObj = {
        nameError: false,
        contactDetailsError: false,
        enquirySourceError: false,
        makeError: false
    };
    let tradeInErrorObj = {
        makeError: false,
        modelError: false,
        yearError: false
    };
    // Check that a customer has been selected, and that it has a phone number and/or an email
    if (!customerDetails.id) {
        customerErrorObj.nameError = true;
    }
    if (!customerDetails.phoneNumber && !customerDetails.email) {
        customerErrorObj.contactDetailsError = true;
    }
    // Check that an enquiry source is selected
    if (!enquirySource) {
        customerErrorObj.enquirySourceError = true;
    }
    // Check that at least a make is selected
    if (!selectedMake) {
        customerErrorObj.makeError = true;
    }

    // If there is a trade in, make sur to get make, model and year
    if (tradeInMake?.name || tradeInModel?.name) {
        if (!tradeInMake) {
            tradeInErrorObj.makeError = true;
        }
        if (!tradeInModel) {
            tradeInErrorObj.modelError = true;
        }
        if (!tradeInDetails.year) {
            tradeInErrorObj.yearError = true;
        }
    }

    // If we didn't find any error, proceed
    if (
        Object.values(customerErrorObj).every((value) => value === false) &&
        Object.values(tradeInErrorObj).every((value) => value === false)
    ) {
        let financeObject = {
            currentWeeklyRepayment: financeDetails.weeklyRepayment,
            amountOwing: financeDetails.amountOwing,
            remainingTerms: financeDetails.remainingTerms
        };
        let tradeInObject = {};
        if (tradeInMake || tradeInModel || tradeInDetails.year) {
            tradeInObject = {
                makeId: tradeInMake.id,
                modelId: tradeInModel.id,
                year: parseInt(dayjs(tradeInDetails.year).format('YYYY')),
                registration: tradeInDetails.registration,
                registrationExpiration: tradeInDetails.registrationExpiration,
                colour: tradeInDetails.colour,
                odometer: tradeInDetails.odometer,
                hours: tradeInDetails.hours,
                vin: tradeInDetails.vin,
                engineNumber: tradeInDetails.engineNumber
            };
        }

        let queryObj = {
            Customer: customerDetails,
            bikeCondition: bikeCondition,
            selectedMake: selectedMake,
            selectedModel: selectedModel,
            enquirySource: enquirySource,
            weeklyBudget: weeklyBudget,
            pickupDate: pickupDate,
            reason: reason,
            financeData: financeObject,
            tradeInData: tradeInObject,
            notes: newNotes,
            SiteId: parseInt(localStorage.getItem('SiteId'))
        };
        api.post('/leadNextGen', queryObj).then((res) => {
            if (res.status === 200) {
                showSnackbar('Enquiry submitted successfully.');
                setTimeout(() => window.location.reload(), 1500);
            } else {
                showSnackbar('Enquiry not submitted.', '', 'error');
            }
        });
    } else {
        if (
            customerErrorObj.nameError ||
            customerErrorObj.contactDetailsError
        ) {
            showSnackbar('Must select a customer.', '', 'error');
        } else {
            showSnackbar(
                "Some data are missing, please check the different tab to see what's missing.",
                '',
                'error'
            );
        }
        setCustomerErrors(customerErrorObj);
        setTradeInErrors(tradeInErrorObj);
        return;
    }
};

export default SubmitLead;
