import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const CreateSeries = (
    selectedMake,
    selectedModel,
    name: string,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    api.post('series', {
        name: name,
        MakeId: selectedMake.id,
        ModelId: selectedModel.id
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Successfully created serie.');
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar('Serie already exists.', '', 'error');
        } else {
            showSnackbar(
                'Something went wrong, refresh the page and try again.',
                '',
                'erro'
            );
        }
        setButtonLoading(false);
    });
};

export default CreateSeries;
