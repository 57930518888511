// COMPONENTS
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import Autocomplete from '../../../../global/Autocomplete';
// LOGIC
import GetAllAccounts from '../../../../global/databaseLogic/GetAllAccounts';
import GetAllCustomers from '../../../../global/databaseLogic/GetAllCustomers';
import GetAllVehiclesNoFilter from '../../../../global/databaseLogic/GetAllVehiclesNoFilter';
// INTERFACES
import { Account } from '../../../../global/interfaces/AdminInterface';
import {
    Creditor,
    Customer,
    Site
} from '../../../../global/interfaces/GeneralInterface';
import { PurchaseOrder } from '../../../../global/interfaces/ServiceInterface';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import GetAllPurchaseOrders from '../../../../global/databaseLogic/GetAllPurchaseOrders';
interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Account?: Account;
    Debtor?: Customer;
    Vehicle?: Vehicle;
    PurchaseOrder?: PurchaseOrder;
    type: string;
    documentReference: string;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
}
interface InvoiceTypeSelectorProps {
    invoiceType: string;
    invoiceObject: InvoiceObject;
    setInvoiceObject: Dispatch<SetStateAction<InvoiceObject>>;
}

const InvoiceTypeSelector = ({
    invoiceType,
    invoiceObject,
    setInvoiceObject
}: InvoiceTypeSelectorProps) => {
    const [accounts, setAccounts] = useState<Account[]>();
    const [debtors, setDebtors] = useState<Customer[]>();
    const [vehicles, setVehicles] = useState<Vehicle[]>();
    const [services, setServices] = useState<PurchaseOrder[]>();

    // Whenever the invoice type changes get the appropiate fields to fill the dropdowns
    useEffect(() => {
        if (invoiceObject.type === 'Account') {
            GetAllAccounts(setAccounts);
        } else if (invoiceObject.type === 'Debtor') {
            GetAllCustomers(setDebtors, true);
        } else if (invoiceObject.type === 'Vehicle') {
            GetAllVehiclesNoFilter(setVehicles);
        } else if (invoiceObject.type === 'Service') {
            GetAllPurchaseOrders(setServices);
        }
    }, [invoiceObject.type]);

    return (
        <>
            {invoiceType === 'Account' ? (
                <Autocomplete
                    size="small"
                    options={accounts}
                    useTwoOptionLabels={true}
                    primaryOptionLabel="name"
                    secondaryOptionLabel="siteName"
                    textfieldLabel="Account *"
                    selectedValue={invoiceObject.Account}
                    includeBar={true}
                    handleSelectedValueChange={(newValue) =>
                        setInvoiceObject({
                            ...invoiceObject,
                            Account: newValue
                        })
                    }
                    handleInputValueChange={() => null}
                />
            ) : null}
            {invoiceType === 'Debtor' ? (
                <Autocomplete
                    size="small"
                    options={debtors}
                    useTwoOptionLabels={true}
                    primaryOptionLabel="fullName"
                    secondaryOptionLabel="id"
                    textfieldLabel="Debtor *"
                    selectedValue={invoiceObject.Debtor}
                    includeBar={true}
                    handleSelectedValueChange={(newValue) =>
                        setInvoiceObject({ ...invoiceObject, Debtor: newValue })
                    }
                    handleInputValueChange={() => null}
                />
            ) : null}
            {invoiceType === 'Vehicle' ? (
                <Autocomplete
                    size="small"
                    options={vehicles}
                    useTwoOptionLabels={true}
                    primaryOptionLabel="stockNumber"
                    secondaryOptionLabel="vehicleName"
                    textfieldLabel="Vehicle *"
                    selectedValue={invoiceObject.Vehicle}
                    includeBar={true}
                    handleSelectedValueChange={(newValue) =>
                        setInvoiceObject({
                            ...invoiceObject,
                            Vehicle: newValue
                        })
                    }
                    handleInputValueChange={() => null}
                />
            ) : null}
            {invoiceType === 'Service' ? (
                <Autocomplete
                    size="small"
                    options={services}
                    useTwoOptionLabels={true}
                    primaryOptionLabel="id"
                    secondaryOptionLabel="ServiceId"
                    textfieldLabel="Purchase Order *"
                    selectedValue={invoiceObject.PurchaseOrder}
                    includeBar={true}
                    handleSelectedValueChange={(newValue) =>
                        setInvoiceObject({
                            ...invoiceObject,
                            PurchaseOrder: newValue
                        })
                    }
                    handleInputValueChange={() => null}
                />
            ) : null}
        </>
    );
};
export default InvoiceTypeSelector;
