import { BaseSyntheticEvent, Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Table } from '../interfaces/StockOrderInterface';

const HandleQuantityChange = (
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    id: number,
    OrderType: string,
    rowid: number,
    e: BaseSyntheticEvent,
    showSnackbar: showSnackbar,
    setUpdating
) => {
    let currentTables = tables;
    var suppindex = currentTables.findIndex(
        (x) => x.supplierId === id && x.OrderType === OrderType
    );
    var rowindex = currentTables[suppindex].orderLines.findIndex(
        (x) => x.rowId === rowid
    );
    let rows = [...currentTables];
    let row = { ...rows[suppindex].orderLines[rowindex] };
    const inputValue = e.target.value;
    row.quantity = inputValue === '' ? null : parseInt(inputValue) || 0;
    row.rowTotal = row.quantity * row.costPrice;
    rows[suppindex].orderLines[rowindex] = row;

    setUpdating(true);
    let aboveMax = row.maxStock && row.quantity + row.qtyOnHand > row.maxStock;
    let belowMin = row.minStock && row.quantity + row.qtyOnHand < row.minStock;

    if (aboveMax) {
        showSnackbar(
            'WARNING: AMOUNT ORDERED WILL PUT STOCK OVER MAXIMUM.',
            '',
            'warning'
        );
    }
    if (belowMin) {
        showSnackbar(
            'WARNING: AMOUNT ORDERED WILL PUT STOCK UNDER MINIMUM.',
            '',
            'warning'
        );
    }

    setTables(rows);
};

export default HandleQuantityChange;
