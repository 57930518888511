// LOGIC
import api from '../../../../api';

const GetAllSeries = (setSeries, models?, ModelId?) => {
  if (models && models.length > 0) {
    let formattedModels = [];

    models.forEach((make) => {
      formattedModels.push(make.id);
    });

    api.get(`allSeries?${formattedModels}`).then((res) => {
      setSeries(res.data);
    });
  } else if (ModelId) {
    api.get(`allSeries?MakeId=${ModelId}`).then((res) => {
      setSeries(res.data);
    });
  } else {
    api.get(`allSeries`).then((res) => {
      setSeries(res.data);
    });
  }
};

export default GetAllSeries;
