import { ToFixed } from './Formatters';

const CalculateMargin = (totalPrice: number, totalCost: number) => {
  if (totalPrice <= 0) {
    return 0;
  }

  let margin = ((totalPrice - totalCost) / totalPrice) * 100;
  return ToFixed(margin);
};

export default CalculateMargin;
