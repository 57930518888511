import {
    TableRow,
    TableCell,
    IconButton,
    TextField,
    Typography,
    MenuItem
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';

import DataTable from '../../../OraNextGen/global/tableComponents/DataTable';
import { Site } from '../../../OraNextGen/global/interfaces/GeneralInterface';
import { Dispatch, SetStateAction } from 'react';

interface Step2Props {
    sites: Site[];
    setSites: Dispatch<SetStateAction<Site[]>>;
    stepValidation: any[];
    setStepValidation: Dispatch<SetStateAction<any[]>>;
}

const Step2 = (props: Step2Props) => {
    const columns = [
        { id: 1, label: 'Code (max 3 char.)' },
        { id: 2, label: 'Name' },
        { id: 3, label: 'Vehicle Type' },
        { id: 4, label: 'Phone Number' },
        { id: 5, label: 'Email Address' },
        { id: 6, label: 'Address' },
        { id: 7, label: 'Suburb' },
        { id: 8, label: 'State' },
        { id: 9, label: 'Country' },
        { id: 10, label: 'Postcode' },
        { id: 11, label: '' }
    ];

    const AddSite = () => {
        let currentSites = [...props.sites];
        let id =
            props.sites.length > 0
                ? props.sites[props.sites.length - 1].id + 1
                : 1;

        let newSite = {
            id: id,
            code: null,
            name: null,
            phoneNumber: null,
            email: null,
            address: null,
            suburb: null,
            state: null,
            country: null,
            postcode: null,
            vehicleTypeSold: null
        };

        let newStepValidation = [...props.stepValidation];
        newStepValidation.push({
            code: {
                error: false,
                message: ''
            },
            name: {
                error: false,
                message: ''
            },
            phoneNumber: {
                error: false,
                message: ''
            },
            email: {
                error: false,
                message: ''
            },
            address: {
                error: false,
                message: ''
            },
            suburb: {
                error: false,
                message: ''
            },
            state: {
                error: false,
                message: ''
            },
            country: {
                error: false,
                message: ''
            },
            postcode: {
                error: false,
                message: ''
            },
            vehicleTypeSold: {
                error: false,
                message: ''
            }
        });
        props.setStepValidation(newStepValidation);
        currentSites.push(newSite);
        props.setSites(currentSites);
    };

    const EditSite = (rowId, name, value) => {
        var index = props.sites.findIndex((x) => x.id === rowId);
        let rows = [...props.sites];
        let row = { ...rows[index] };
        if (name === 'code' && value.length > 3) {
            row[name] = value.substring(0, 3);
        } else {
            row[name] = value;
        }

        rows[index] = row;
        props.setSites(rows);
    };

    return (
        <>
            <Typography variant="button">
                <b>
                    Please enter at least one site/store location for your
                    business.
                </b>
            </Typography>
            <br />
            <br />
            <DataTable columns={columns}>
                {props.sites.map((site, index) => (
                    <TableRow>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                error={props.stepValidation[index].code.error}
                                helperText={
                                    props.stepValidation[index].code.error
                                        ? props.stepValidation[index].code
                                              .message
                                        : ''
                                }
                                value={site.code}
                                onChange={(e) =>
                                    EditSite(site.id, 'code', e.target.value)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                error={props.stepValidation[index].name.error}
                                helperText={
                                    props.stepValidation[index].name.error
                                        ? props.stepValidation[index].name
                                              .message
                                        : ''
                                }
                                value={site.name}
                                onChange={(e) =>
                                    EditSite(site.id, 'name', e.target.value)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                select
                                variant="standard"
                                error={
                                    props.stepValidation[index].vehicleTypeSold
                                        .error
                                }
                                helperText={
                                    props.stepValidation[index].vehicleTypeSold
                                        .error
                                        ? props.stepValidation[index]
                                              .vehicleTypeSold.message
                                        : ''
                                }
                                value={site.vehicleTypeSold}
                                onChange={(e) =>
                                    EditSite(
                                        site.id,
                                        'vehicleTypeSold',
                                        e.target.value
                                    )
                                }
                            >
                                <MenuItem value={'Motorcycles'}>
                                    Motorcycles
                                </MenuItem>
                                <MenuItem value={'Bicycles'}>Bicyles</MenuItem>
                            </TextField>
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                error={
                                    props.stepValidation[index].phoneNumber
                                        .error
                                }
                                helperText={
                                    props.stepValidation[index].phoneNumber
                                        .error
                                        ? props.stepValidation[index]
                                              .phoneNumber.message
                                        : ''
                                }
                                value={site.phoneNumber}
                                onChange={(e) =>
                                    EditSite(
                                        site.id,
                                        'phoneNumber',
                                        e.target.value
                                    )
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                error={props.stepValidation[index].email.error}
                                helperText={
                                    props.stepValidation[index].email.error
                                        ? props.stepValidation[index].email
                                              .message
                                        : ''
                                }
                                value={site.email}
                                onChange={(e) =>
                                    EditSite(site.id, 'email', e.target.value)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                error={
                                    props.stepValidation[index].address.error
                                }
                                helperText={
                                    props.stepValidation[index].address.error
                                        ? props.stepValidation[index].address
                                              .message
                                        : ''
                                }
                                value={site.address}
                                onChange={(e) =>
                                    EditSite(site.id, 'address', e.target.value)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                error={props.stepValidation[index].suburb.error}
                                helperText={
                                    props.stepValidation[index].suburb.error
                                        ? props.stepValidation[index].suburb
                                              .message
                                        : ''
                                }
                                value={site.suburb}
                                onChange={(e) =>
                                    EditSite(site.id, 'suburb', e.target.value)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                error={props.stepValidation[index].state.error}
                                helperText={
                                    props.stepValidation[index].state.error
                                        ? props.stepValidation[index].state
                                              .message
                                        : ''
                                }
                                value={site.state}
                                onChange={(e) =>
                                    EditSite(site.id, 'state', e.target.value)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                error={
                                    props.stepValidation[index].country.error
                                }
                                helperText={
                                    props.stepValidation[index].country.error
                                        ? props.stepValidation[index].country
                                              .message
                                        : ''
                                }
                                value={site.country}
                                onChange={(e) =>
                                    EditSite(site.id, 'country', e.target.value)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                error={
                                    props.stepValidation[index].postcode.error
                                }
                                helperText={
                                    props.stepValidation[index].postcode.error
                                        ? props.stepValidation[index].postcode
                                              .message
                                        : ''
                                }
                                value={site.postcode}
                                onChange={(e) =>
                                    EditSite(
                                        site.id,
                                        'postcode',
                                        e.target.value
                                    )
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <IconButton
                                onClick={() =>
                                    props.setSites(
                                        props.sites.filter(
                                            (row) => row.id !== site.id
                                        )
                                    )
                                }
                            >
                                <Close />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell colSpan={10} align="center">
                        <IconButton onClick={() => AddSite()}>
                            <Add />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </DataTable>
        </>
    );
};

export default Step2;
