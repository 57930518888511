// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Typography,
    Divider,
    MenuItem,
    TextField,
    Table,
    TableRow,
    TableCell,
    Grid
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../../global/Paper';

// INTERFACES
import { Customer } from '../../../../global/interfaces/GeneralInterface';
import Dayjs from 'dayjs';
import CheckCustomerPhoneNumber from '../../logic/CheckCustomerPhoneNumber';

/**
 * BasicProps
 * Parameters sent through from Details.tsx
 */
interface BasicProps {
    customerInfo: Customer;
    setCustomerInfo: Dispatch<SetStateAction<Customer>>;
    disabled: boolean;
}

/**
 * BasicDetails
 * UI for a customer's basic details in ViewCustomer's 'DETAILS' tab
 * @author Estienne
 * @param BasicProps
 * @returns The customer's basic details
 */
const BasicDetails = ({
    customerInfo,
    setCustomerInfo,
    disabled
}: BasicProps) => {
    const [responseCode, setResponseCode] = useState<number>();

    return (
        <Paper>
            <Typography variant="h6">Basic Details</Typography>
            <Divider />
            <Table>
                <TableRow>
                    <TableCell width="100">Customer Type</TableCell>
                    <TableCell width="200">
                        <TextField
                            disabled={disabled}
                            fullWidth
                            select
                            size="small"
                            variant="standard"
                            value={customerInfo?.customerType}
                            InputProps={{ style: { textAlign: 'center' } }}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                setCustomerInfo({
                                    ...customerInfo,
                                    customerType: e.target.value
                                });
                            }}
                        >
                            <MenuItem value={'Retail'}>Retail</MenuItem>
                            <MenuItem value={'Trade'}>Trade</MenuItem>
                        </TextField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell>
                        {disabled && customerInfo.id ? (
                            <TextField
                                disabled={disabled}
                                fullWidth
                                variant="standard"
                                size="small"
                                value={`${customerInfo?.firstName} ${customerInfo?.lastName}`}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                            />
                        ) : (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="standard"
                                            label="First Name"
                                            value={customerInfo?.firstName}
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: 'center' }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => {
                                                setCustomerInfo({
                                                    ...customerInfo,
                                                    firstName: e.target.value
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="standard"
                                            label="Last Name"
                                            value={customerInfo?.lastName}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: 'center' }
                                            }}
                                            onChange={(e) => {
                                                setCustomerInfo({
                                                    ...customerInfo,
                                                    lastName: e.target.value
                                                });
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>
                        <TextField
                            disabled={disabled}
                            fullWidth
                            variant="standard"
                            size="small"
                            helperText={
                                responseCode === 400
                                    ? 'Customer Phone Number Already Exists.'
                                    : ''
                            }
                            value={customerInfo?.phoneNumber}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setCustomerInfo({
                                    ...customerInfo,
                                    phoneNumber: e.target.value
                                })
                            }
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'center' }
                            }}
                            onBlur={(e) =>
                                CheckCustomerPhoneNumber(
                                    e.target.value,
                                    setResponseCode
                                )
                            }
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Email Address</TableCell>
                    <TableCell>
                        <TextField
                            disabled={disabled}
                            fullWidth
                            variant="standard"
                            size="small"
                            value={customerInfo?.email}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setCustomerInfo({
                                    ...customerInfo,
                                    email: e.target.value
                                })
                            }
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'center' }
                            }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Company Name</TableCell>
                    <TableCell>
                        <TextField
                            disabled={disabled}
                            fullWidth
                            variant="standard"
                            size="small"
                            value={customerInfo?.companyName}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setCustomerInfo({
                                    ...customerInfo,
                                    companyName: e.target.value
                                })
                            }
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'center' }
                            }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>ABN</TableCell>
                    <TableCell>
                        <TextField
                            disabled={disabled}
                            fullWidth
                            variant="standard"
                            size="small"
                            value={customerInfo?.abn}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setCustomerInfo({
                                    ...customerInfo,
                                    abn: e.target.value
                                })
                            }
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'center' }
                            }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Driver Licence</TableCell>
                    <TableCell>
                        <TextField
                            disabled={disabled}
                            fullWidth
                            variant="standard"
                            size="small"
                            value={customerInfo?.driverLicenceNumber}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setCustomerInfo({
                                    ...customerInfo,
                                    driverLicenceNumber: e.target.value
                                })
                            }
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'center' }
                            }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Date of Birth</TableCell>
                    <TableCell>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={disabled}
                                format="DD/MM/YYYY"
                                value={Dayjs(customerInfo?.dateOfBirth)}
                                onChange={(newValue) =>
                                    setCustomerInfo({
                                        ...customerInfo,
                                        dateOfBirth: newValue.toISOString()
                                    })
                                }
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </TableCell>
                </TableRow>
            </Table>
        </Paper>
    );
};
export default BasicDetails;
