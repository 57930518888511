import dayjs from 'dayjs';

const renderCustomerBankPDF = async (pdfDocCopy, formData) => {
    const pages = pdfDocCopy.getPages();
    const firstPage = pages[0];

    if (formData.nameOnCard !== '') {
        firstPage.drawText(formData.nameOnCard, {
            x: 200,
            y: 670,
            size: 12
        });
    }

    if (formData.bankName !== '') {
        firstPage.drawText(formData.bankName, {
            x: 200,
            y: 640,
            size: 12
        });
    }

    if (formData.bsb !== '') {
        firstPage.drawText(formData.bsb, {
            x: 200,
            y: 610,
            size: 12
        });
    }

    if (formData.accountNo !== '') {
        firstPage.drawText(formData.accountNo, {
            x: 200,
            y: 580,
            size: 12
        });
    }

    if (formData.fullName !== '') {
        firstPage.drawText(formData.fullName, {
            x: 110,
            y: 515,
            size: 12
        });
    }

    if (formData.vendorSigDataURL) {
        const vendorSigImage = await pdfDocCopy.embedPng(
            formData.vendorSigDataURL
        );
        firstPage.drawImage(vendorSigImage, {
            x: 130,
            y: 370,
            width: 100,
            height: 30
        });
    }

    // set the date as today in DD/MM/YYYY format
    firstPage.drawText(dayjs().format('DD/MM/YYYY'), {
        x: 380,
        y: 370,
        size: 12
    });

    const pdfBytes = await pdfDocCopy.save();
    return pdfBytes;
};

export default renderCustomerBankPDF;
