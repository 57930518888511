import dayjs from 'dayjs';
import { ValidateVin } from '../../../global/logic/vehicleValidation/ValidateVehicleFields';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const checkCondition = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (
            item[index] !== 'Used' &&
            item[index] !== 'New' &&
            item[index] !== 'Demo'
        ) {
            showSnackbar(
                `Invalid Vehicle Condition found at line: ${id + 1}`,
                'Vehicle Condition must be "Used", "New" or "Demo"',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkOdometer = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (Number.isNaN(parseInt(item[index]))) {
            showSnackbar(
                `Invalid Odometer value found at line: ${id + 1}`,
                'Odometer value must be a number',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkYear = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (
            Number.isNaN(parseInt(item[index])) ||
            String(item[index]).length !== 4
        ) {
            showSnackbar(
                `Invalid Model Year value found at line: ${id + 1}`,
                'Model Year value must be a 4 digit number',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkCostPrice = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (Number.isNaN(parseFloat(item[index]))) {
            showSnackbar(
                `Invalid Cost Price value found at line: ${id + 1}`,
                'Cost Price value must be a number',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkVin = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        let validVin = ValidateVin(item[index]);
        if (validVin !== 'valid') {
            showSnackbar(
                `Invalid VIN found at line: ${id + 1}`,
                validVin,
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkCylinders = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (Number.isNaN(parseInt(item[index]))) {
            showSnackbar(
                `Invalid Cost Price value found at line: ${id + 1}`,
                'Cost Price value must be a number',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkBodyType = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    let validBodyType = ['SCOOTER', 'MOTORCYCLE', 'QUAD', 'SXS'];
    for (let [id, item] of importedData.entries()) {
        if (!validBodyType.includes(item[index])) {
            showSnackbar(
                `Invalid Body Type found at line: ${id + 1}`,

                'Body Type must be "SCOOTER", "MOTORCYCLE", "QUAD" or "SXS".',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkDriveType = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    let validDriveType = [
        'REAR WHEEL',
        'CHAIN',
        '4 WHEEL',
        'ALL WHEEL',
        'FRONT WHEEL'
    ];
    for (let [id, item] of importedData.entries()) {
        if (!validDriveType.includes(item[index])) {
            showSnackbar(
                `Invalid Drive Type found at line: ${id + 1}`,

                'Drive Type must be "REAR WHEEL", "CHAIN", "4 WHEEL", "ALL WHEEL" or "FRONT WHEEL".',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkFuelType = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    let validFuelType = ['UNLEADED', 'ELECTRIC'];
    for (let [id, item] of importedData.entries()) {
        if (!validFuelType.includes(item[index])) {
            showSnackbar(
                `Invalid Fuel Type found at line: ${id + 1}`,
                'Fuel Type must be "UNLEADED" or "ELECTRIC".',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkRRP = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (Number.isNaN(parseFloat(item[index]))) {
            showSnackbar(
                `Invalid RRP value found at line: ${id + 1}`,
                'RRP value must be a number',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkEngineSize = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (Number.isNaN(parseInt(item[index]))) {
            showSnackbar(
                `Invalid Engine Size value found at line: ${id + 1}`,
                'Engine Size value must be a number',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkRegistrationDueDate = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        let currentDate = item[index];
        if (currentDate) {
            let date = item[index].split('/');
            if (date.length !== 3) {
                showSnackbar(
                    `Invalid Registration Due Date found at line: ${id + 1}`,
                    'Registration Due Date must be in the following format: DD/MM/YYYY',
                    'error'
                );
                return { valid: false, index: index };
            } else {
                let day = date[0].length === 1 ? '0' + date[0] : date[0];
                let month = date[1].length === 1 ? '0' + date[1] : date[1];
                let year = date[2];
                if (year.length !== 4) {
                    showSnackbar(
                        `Invalid Registration Due Date found at line: ${id + 1}`,
                        'Year must be 4 digits',
                        'error'
                    );
                    return { valid: false, index: index };
                }
                if (!dayjs(`${day}/${month}/${year}`).isValid()) {
                    showSnackbar(
                        `Invalid Registration Due Date found at line: ${id + 1}`,
                        `Registration Due Date found is: ${day}/${month}/${year}. If this is a the correct format, please contact the Ora Team.`,
                        'error'
                    );
                    return { valid: false, index: index };
                }
            }
        }
    }
    return { valid: true };
};

export const checkLearnerApproved = (
    index: number,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        let value = parseInt(item[index]);
        if (value !== 1 && value !== 0) {
            showSnackbar(
                `Invalid Learner Approved value found at line: ${id + 1}`,
                'Learner Approved can only be 1 or 0',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};
