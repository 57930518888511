// IMPORTS
import {
    BaseSyntheticEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState
} from 'react';
import {
    Collapse,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// COMPONENTS
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';

// LOGIC
import CreateCtpPrice from '../logic/CreateCtpPrice';

// INTERFACES
import { Ctp } from '../../../global/interfaces/VehicleSaleInterface';

interface CtpProps {
    ctp: Ctp[];
    setCtp: Dispatch<SetStateAction<Ctp[]>>;
    setFutureCtpChange: Dispatch<SetStateAction<boolean>>;
    state: string;
    disabled: boolean;
}

const FutureCtpPrice = ({
    ctp,
    setCtp,
    setFutureCtpChange,
    state,
    disabled
}: CtpProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedLength, setSelectedLength] = useState<string>('0');

    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (state === 'QLD') {
            setColumns([
                { id: 0, label: 'Seats', width: 50 },
                { id: 1, label: 'Type', width: 50 },
                { id: 2, label: '6 Months', width: 50 },
                { id: 3, label: '12 Months', width: 50 }
            ]);
        } else if (state === 'NSW') {
            setColumns([
                { id: 0, label: 'Class', width: 50 },
                { id: 1, label: 'Vehicle Type', width: 50 },
                { id: 2, label: 'Year', width: 50 },
                { id: 3, label: 'Metro', width: 50 },
                { id: 3, label: 'Outer Metro', width: 50 },
                { id: 3, label: 'Newcastle / Central Coast', width: 50 },
                { id: 3, label: 'Wollongong', width: 50 },
                { id: 3, label: 'Country', width: 50 }
            ]);
            setSelectedLength('3');
        }
    }, [state]);

    const getCtp = (classCode: string, length: string, label: string) => {
        for (let line of ctp) {
            if (line.class === classCode && line.length === length) {
                return line.prices[0][label];
            }
        }
    };

    const handleChangePrice = (
        classCode: string,
        length: string,
        label: string,
        newValue: string
    ) => {
        let tempCtp = JSON.parse(JSON.stringify(ctp));
        for (let line of tempCtp) {
            if (line.class === classCode && line.length === length) {
                line.prices[0][label] = parseFloat(newValue);
            }
        }
        setCtp(tempCtp);
        setFutureCtpChange(true);
    };

    const getEffectiveDate = () => {
        return ctp[0]?.effectiveDate;
    };

    const handleDateChange = (newDate) => {
        let tempCtps = JSON.parse(JSON.stringify(ctp));
        tempCtps.forEach((currentCtp) => {
            currentCtp.effectiveDate = dayjs(newDate).toISOString();
        });
        setCtp(tempCtps);
        setFutureCtpChange(true);
    };

    const handleLengthChange = (e: BaseSyntheticEvent) => {
        setSelectedLength(e.target.value);
    };

    const handleNSWPriceChange = (id, key, type, innerKey, value) => {
        let ctpTemp = JSON.parse(JSON.stringify(ctp));
        for (let ctpLine of ctpTemp) {
            if (ctpLine.id === id) {
                ctpLine.prices[0][key][type][innerKey] = parseFloat(value);
            }
        }
        setCtp(ctpTemp);
        setFutureCtpChange(true);
    };

    return (
        <Paper>
            <Typography variant="h6" align="left" display="inline">
                Future CTP Prices
            </Typography>
            <IconButton
                size="small"
                onClick={() => setOpen(!open)}
                sx={{ marginBottom: 0.5 }}
            >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Collapse in={open}>
                {ctp.length === 0 ? (
                    <Grid container textAlign={'center'}>
                        <Grid item xs={12}>
                            <IconButton
                                onClick={() => {
                                    CreateCtpPrice(state, 'future', setCtp);
                                }}
                            >
                                <Add />{' '}
                            </IconButton>
                        </Grid>
                    </Grid>
                ) : state === 'QLD' ? (
                    <>
                        {disabled ? (
                            <Typography variant="body1" align="left">
                                Effective Date:{' '}
                                {DateFormatter(getEffectiveDate())}
                            </Typography>
                        ) : (
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    label="Effective Date"
                                    disabled={disabled}
                                    value={dayjs(ctp[0]?.effectiveDate)}
                                    onChange={(newValue) =>
                                        handleDateChange(newValue)
                                    }
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                            size: 'small',
                                            InputLabelProps: { shrink: true },
                                            inputProps: {
                                                style: { textAlign: 'center' }
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                        <Divider /> <br />
                        <Grid container spacing={2}>
                            <DataTable columns={columns}>
                                {ctp.map((line) => (
                                    <>
                                        {line.length === '12' ? null : (
                                            <TableRow>
                                                <DataCell rowSpan={3}>
                                                    {line.className}
                                                </DataCell>
                                            </TableRow>
                                        )}
                                        {line.length === '12' &&
                                        line.class === '12' ? (
                                            <TableRow>
                                                <DataCell>Personal</DataCell>
                                                {disabled ? (
                                                    <DataCell>
                                                        {CurrencyFormatter(
                                                            getCtp(
                                                                '12',
                                                                '6',
                                                                'business'
                                                            )
                                                        )}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell>
                                                        <TextField
                                                            size="small"
                                                            variant="standard"
                                                            type="number"
                                                            value={getCtp(
                                                                '12',
                                                                '6',
                                                                'business'
                                                            )}
                                                            InputProps={{
                                                                disableUnderline:
                                                                    true,
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                inputProps: {
                                                                    style: {
                                                                        textAlign:
                                                                            'right'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleChangePrice(
                                                                    '12',
                                                                    '6',
                                                                    'business',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </DataCell>
                                                )}
                                                {disabled ? (
                                                    <DataCell>
                                                        {CurrencyFormatter(
                                                            getCtp(
                                                                '12',
                                                                '12',
                                                                'business'
                                                            )
                                                        )}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell>
                                                        <TextField
                                                            size="small"
                                                            variant="standard"
                                                            type="number"
                                                            value={getCtp(
                                                                '12',
                                                                '12',
                                                                'business'
                                                            )}
                                                            InputProps={{
                                                                disableUnderline:
                                                                    true,
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                inputProps: {
                                                                    style: {
                                                                        textAlign:
                                                                            'right'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleChangePrice(
                                                                    '12',
                                                                    '12',
                                                                    'business',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </DataCell>
                                                )}
                                            </TableRow>
                                        ) : line.length === '12' &&
                                          line.class === '13' ? (
                                            <TableRow>
                                                <DataCell>Personal</DataCell>
                                                {disabled ? (
                                                    <DataCell>
                                                        {CurrencyFormatter(
                                                            getCtp(
                                                                '13',
                                                                '6',
                                                                'business'
                                                            )
                                                        )}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell>
                                                        <TextField
                                                            size="small"
                                                            variant="standard"
                                                            type="number"
                                                            value={getCtp(
                                                                '13',
                                                                '6',
                                                                'business'
                                                            )}
                                                            InputProps={{
                                                                disableUnderline:
                                                                    true,
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                inputProps: {
                                                                    style: {
                                                                        textAlign:
                                                                            'right'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleChangePrice(
                                                                    '13',
                                                                    '6',
                                                                    'business',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </DataCell>
                                                )}
                                                {disabled ? (
                                                    <DataCell>
                                                        {CurrencyFormatter(
                                                            getCtp(
                                                                '13',
                                                                '12',
                                                                'business'
                                                            )
                                                        )}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell>
                                                        <TextField
                                                            size="small"
                                                            variant="standard"
                                                            type="number"
                                                            value={getCtp(
                                                                '13',
                                                                '12',
                                                                'business'
                                                            )}
                                                            InputProps={{
                                                                disableUnderline:
                                                                    true,
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                inputProps: {
                                                                    style: {
                                                                        textAlign:
                                                                            'right'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleChangePrice(
                                                                    '13',
                                                                    '12',
                                                                    'business',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </DataCell>
                                                )}
                                            </TableRow>
                                        ) : line.length === '6' &&
                                          line.class === '12' ? (
                                            <TableRow>
                                                <DataCell>Business</DataCell>
                                                {disabled ? (
                                                    <DataCell>
                                                        {CurrencyFormatter(
                                                            getCtp(
                                                                '12',
                                                                '6',
                                                                'personal'
                                                            )
                                                        )}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell>
                                                        <TextField
                                                            size="small"
                                                            variant="standard"
                                                            type="number"
                                                            value={getCtp(
                                                                '12',
                                                                '6',
                                                                'personal'
                                                            )}
                                                            InputProps={{
                                                                disableUnderline:
                                                                    true,
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                inputProps: {
                                                                    style: {
                                                                        textAlign:
                                                                            'right'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleChangePrice(
                                                                    '12',
                                                                    '6',
                                                                    'personal',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </DataCell>
                                                )}
                                                {disabled ? (
                                                    <DataCell>
                                                        {CurrencyFormatter(
                                                            getCtp(
                                                                '12',
                                                                '12',
                                                                'personal'
                                                            )
                                                        )}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell>
                                                        <TextField
                                                            size="small"
                                                            variant="standard"
                                                            type="number"
                                                            value={getCtp(
                                                                '12',
                                                                '12',
                                                                'personal'
                                                            )}
                                                            InputProps={{
                                                                disableUnderline:
                                                                    true,
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                inputProps: {
                                                                    style: {
                                                                        textAlign:
                                                                            'right'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleChangePrice(
                                                                    '12',
                                                                    '12',
                                                                    'personal',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </DataCell>
                                                )}
                                            </TableRow>
                                        ) : (
                                            <TableRow>
                                                <DataCell>Business</DataCell>
                                                {disabled ? (
                                                    <DataCell>
                                                        {CurrencyFormatter(
                                                            getCtp(
                                                                '13',
                                                                '6',
                                                                'personal'
                                                            )
                                                        )}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell>
                                                        <TextField
                                                            size="small"
                                                            variant="standard"
                                                            type="number"
                                                            value={getCtp(
                                                                '13',
                                                                '6',
                                                                'personal'
                                                            )}
                                                            InputProps={{
                                                                disableUnderline:
                                                                    true,
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                inputProps: {
                                                                    style: {
                                                                        textAlign:
                                                                            'right'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleChangePrice(
                                                                    '13',
                                                                    '6',
                                                                    'personal',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </DataCell>
                                                )}
                                                {disabled ? (
                                                    <DataCell>
                                                        {CurrencyFormatter(
                                                            getCtp(
                                                                '13',
                                                                '12',
                                                                'personal'
                                                            )
                                                        )}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell>
                                                        <TextField
                                                            size="small"
                                                            variant="standard"
                                                            type="number"
                                                            value={getCtp(
                                                                '13',
                                                                '12',
                                                                'personal'
                                                            )}
                                                            InputProps={{
                                                                disableUnderline:
                                                                    true,
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                inputProps: {
                                                                    style: {
                                                                        textAlign:
                                                                            'right'
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                handleChangePrice(
                                                                    '13',
                                                                    '12',
                                                                    'personal',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </DataCell>
                                                )}
                                            </TableRow>
                                        )}
                                    </>
                                ))}
                            </DataTable>
                        </Grid>
                    </>
                ) : state === 'NSW' ? (
                    <>
                        {disabled ? (
                            <Typography variant="body1" align="left">
                                Effective Date:{' '}
                                {DateFormatter(getEffectiveDate())}
                            </Typography>
                        ) : (
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    label="Effective Date"
                                    disabled={disabled}
                                    value={dayjs(ctp[0]?.effectiveDate)}
                                    onChange={(newValue) =>
                                        handleDateChange(newValue)
                                    }
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                            size: 'small',
                                            InputLabelProps: { shrink: true },
                                            inputProps: {
                                                style: { textAlign: 'center' }
                                            }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                        <Divider /> <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    size="small"
                                    color="primary"
                                    exclusive
                                    fullWidth
                                    value={selectedLength}
                                    onChange={handleLengthChange}
                                >
                                    <ToggleButton value="3">
                                        3 Months
                                    </ToggleButton>
                                    <ToggleButton value="6">
                                        6 Months
                                    </ToggleButton>
                                    <ToggleButton value="12">
                                        12 Months
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <DataTable columns={columns}>
                                    {ctp
                                        .filter(
                                            (x) => x.length === selectedLength
                                        )
                                        .map((line) => (
                                            <>
                                                <TableRow>
                                                    <DataCell rowSpan={7}>
                                                        {line.class}
                                                    </DataCell>
                                                    <DataCell rowSpan={7}>
                                                        {line.className}
                                                    </DataCell>
                                                </TableRow>

                                                {Object.keys(
                                                    line.prices[0]
                                                ).map((key) => (
                                                    <>
                                                        <TableRow>
                                                            <DataCell
                                                                rowSpan={3}
                                                            >
                                                                {key}
                                                            </DataCell>
                                                        </TableRow>
                                                        {Object.keys(
                                                            line.prices[0][key]
                                                        ).map((type) => (
                                                            <>
                                                                <TableRow>
                                                                    <DataCell>
                                                                        {type
                                                                            .slice(
                                                                                0,
                                                                                1
                                                                            )
                                                                            .toUpperCase() +
                                                                            type.slice(
                                                                                1
                                                                            )}
                                                                    </DataCell>
                                                                    {Object.keys(
                                                                        line
                                                                            .prices[0][
                                                                            key
                                                                        ][type]
                                                                    ).map(
                                                                        (
                                                                            innerKey
                                                                        ) => {
                                                                            if (
                                                                                disabled
                                                                            ) {
                                                                                return (
                                                                                    <DataCell>
                                                                                        {CurrencyFormatter(
                                                                                            line
                                                                                                .prices[0][
                                                                                                key
                                                                                            ][
                                                                                                type
                                                                                            ][
                                                                                                innerKey
                                                                                            ]
                                                                                        )}
                                                                                    </DataCell>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <DataCell>
                                                                                        <TextField
                                                                                            size="small"
                                                                                            variant="standard"
                                                                                            type="number"
                                                                                            value={
                                                                                                line
                                                                                                    .prices[0][
                                                                                                    key
                                                                                                ][
                                                                                                    type
                                                                                                ][
                                                                                                    innerKey
                                                                                                ]
                                                                                            }
                                                                                            InputProps={{
                                                                                                startAdornment:
                                                                                                    (
                                                                                                        <InputAdornment position="start">
                                                                                                            $
                                                                                                        </InputAdornment>
                                                                                                    ),
                                                                                                inputProps:
                                                                                                    {
                                                                                                        style: {
                                                                                                            textAlign:
                                                                                                                'right'
                                                                                                        }
                                                                                                    }
                                                                                            }}
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                handleNSWPriceChange(
                                                                                                    line.id,
                                                                                                    key,
                                                                                                    type,
                                                                                                    innerKey,
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </DataCell>
                                                                                );
                                                                            }
                                                                        }
                                                                    )}
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                    </>
                                                ))}
                                            </>
                                        ))}
                                </DataTable>
                            </Grid>
                        </Grid>
                    </>
                ) : null}
            </Collapse>
        </Paper>
    );
};

export default FutureCtpPrice;
