// IMPORTS
import { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    TextField,
    DialogActions,
    Button,
    CircularProgress,
    Backdrop
} from '@mui/material';
import { Add, BrowserUpdated, Delete, GetApp, Save } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import InvoiceHeader from './components/InvoiceHeader';
import InvoiceFooter from './components/InvoiceFooter';
import InvoiceLines from './components/InvoiceLines';
import Paper from '../../global/Paper';
import SpeedDial from '../../global/SpeedDial';
import Drawer from '../../global/Drawer';
import PartNotFoundDrawerContent from './components/PartNotFoundDrawerContent';
import ImportStockOrderDialogContent from './components/ImportStockOrderDialogContent';
import MultipleStockOrderInvoiceLines from './components/MultipleStockOrderInvoiceLines';

// LOGIC
import GetStockOrder from './logic/GetStockOrder';
import SubmitStockInvoice from './logic/SubmitStockInvoice';
import ImportHondaInvoice from './logic/ImportHondaInvoice';
import GetStockOrderFromSupplier from './logic/GetStockOrderFromSupplier';
import ImportMultipleStockOrder from './logic/ImportMultipleStockOrder';

// INTERFACES
import {
    HondaInvoicePartLine,
    MultipleStockInvoice,
    StockInvoice,
    StockOrder
} from '../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Api } from '../../global/interfaces/GeneralInterface';
import { GetHondaApiList } from '../../global/databaseLogic/GetHondaApiList';
import { withSnackbar } from '../../global/WrappingSnackbar';
interface ActionList {
    id: number;
    icon;
    name: string;
    permission?: string;
    noDisplay?: boolean;
    onClick?: () => void;
}

const NewStockInvoice = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [responseCode, setResponseCode] = useState<number>();

    const [hondaApiList, setHondaApiList] = useState<Api[]>([]);
    const [importHondaInvoiceOpen, setImportHondaInvoiceOpen] =
        useState<boolean>(false);
    const [hondaInvoiceId, setHondaInvoiceId] = useState<string>('');
    const [invoiceError, setInvoiceError] = useState<string>('');
    const [selectedSite, setSelectedSite] = useState<number>(
        parseInt(localStorage.getItem('SiteId'))
    );
    const [invoiceData, setInvoiceData] = useState<StockInvoice>({
        SiteId: selectedSite,
        Supplier: null,
        SupplierId: null,
        documentReference: null,
        gstMethod: 'including',
        invoiceDate: null,
        documentTotal: null,
        freight: null,
        orderLines: [],
        linkedOrders: []
    });
    const [reverseOrder, setReverseOrder] = useState<string[]>(['', 'ASC']);

    const [partNotFound, setPartNotFound] = useState<HondaInvoicePartLine[]>(
        []
    );
    const [partNotFoundDrawerOpen, setPartNotFoundDrawerOpen] =
        useState<boolean>(false);

    // Import Stock Order Dialog State
    const [importStockOrderDialogOpen, setImportStockOrderDialogOpen] =
        useState<boolean>(false);
    const [listOfStockOrder, setListOfStockOrder] = useState<StockOrder[]>([]);
    const [selectedStockOrders, setSelectedStockOrders] = useState<number[]>(
        []
    );
    const [page, setPage] = useState<number>(1);
    const [isMultipleInvoice, setIsMultipleInvoice] = useState<boolean>(false);
    const [multipleInvoiceData, setMultipleInvoiceData] =
        useState<MultipleStockInvoice>({
            SiteId: selectedSite,
            Supplier: null,
            SupplierId: null,
            documentReference: null,
            gstMethod: 'including',
            invoiceDate: null,
            documentTotal: null,
            freight: null,
            orderLines: [],
            linkedOrders: []
        });
    const [actionsList, setActionsList] = useState<ActionList[]>([
        {
            id: 0,
            icon: <BrowserUpdated />,
            name: 'Import Honda Invoice',
            onClick: () => setImportHondaInvoiceOpen(true)
        }
    ]);

    const handleRestoreLines = () => {
        setInvoiceData(JSON.parse(localStorage.getItem('stockInvoiceData')));
    };

    const handleLoadingStockOrder = () => {
        GetStockOrderFromSupplier(
            parseInt(id),
            invoiceData,
            setListOfStockOrder,
            setImportStockOrderDialogOpen,
            setLoading
        );
    };

    const handleClearStorage = () => {
        localStorage.removeItem('stockInvoiceData');
        window.location.reload();
    };

    useEffect(() => {
        if (id) {
            GetStockOrder(parseInt(id), invoiceData, setInvoiceData);
        }
        GetHondaApiList(setHondaApiList);
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (id) {
            let tempActionList = [];

            if (hondaApiList.length > 0) {
                tempActionList.push({
                    id: 0,
                    icon: <BrowserUpdated />,
                    name: 'Import Honda Invoice',
                    onClick: () => setImportHondaInvoiceOpen(true)
                });
            }

            if (localStorage.getItem('stockInvoiceData')) {
                tempActionList.push({
                    id: 1,
                    icon: <Add />,
                    name: 'Import Previous Lines',
                    onClick: () => handleRestoreLines()
                });
            }

            tempActionList.push({
                id: 2,
                icon: <Delete />,
                name: 'Clear Line History',
                onClick: () => handleClearStorage()
            });
            tempActionList.push({
                id: 3,
                icon: <GetApp />,
                name: 'Import Another Stock Order',
                onClick: () => handleLoadingStockOrder(),
                permission: 'invoice_multipleSO'
            });
            tempActionList.push({
                id: 4,
                icon: <Save />,
                name: 'Submit',
                onClick: () =>
                    SubmitStockInvoice(
                        parseInt(id),
                        isMultipleInvoice,
                        invoiceData,
                        multipleInvoiceData,
                        setInvoiceData,
                        setMultipleInvoiceData,
                        setInvoiceError,
                        showSnackbar
                    )
            });
            setActionsList(tempActionList);
        } else {
            let tempActionList = [];
            if (hondaApiList.length > 0) {
                tempActionList.push({
                    id: 0,
                    icon: <BrowserUpdated />,
                    name: 'Import Honda Invoice',
                    onClick: () => setImportHondaInvoiceOpen(true)
                });
            }

            if (localStorage.getItem('stockInvoiceData')) {
                tempActionList.push({
                    id: 1,
                    icon: <Add />,
                    name: 'Import Previous Lines',
                    onClick: () => handleRestoreLines()
                });
            }

            tempActionList.push({
                id: 2,
                icon: <Delete />,
                name: 'Clear Line History',
                onClick: () => handleClearStorage()
            });
            tempActionList.push({
                id: 4,
                icon: <Save />,
                name: 'Submit',
                onClick: () =>
                    SubmitStockInvoice(
                        parseInt(id),
                        isMultipleInvoice,
                        invoiceData,
                        multipleInvoiceData,
                        setInvoiceData,
                        setMultipleInvoiceData,
                        setInvoiceError,
                        showSnackbar
                    )
            });
            setActionsList(tempActionList);
        }
        // eslint-disable-next-line
    }, [invoiceData, multipleInvoiceData, hondaApiList]);

    return (
        <PageWrapper>
            <Typography variant="h4">
                {id ? `Invoice Order #${id}` : 'New Stock Invoice'}
            </Typography>
            <br />
            <Paper>
                <InvoiceHeader
                    id={id}
                    invoiceData={invoiceData}
                    isMultipleInvoice={isMultipleInvoice}
                    multipleInvoiceData={multipleInvoiceData}
                    setMultipleInvoiceData={setMultipleInvoiceData}
                    selectedSite={selectedSite}
                    setInvoiceData={setInvoiceData}
                    setSelectedSite={setSelectedSite}
                    reverseOrder={reverseOrder}
                />
                <br />
                {isMultipleInvoice ? (
                    <MultipleStockOrderInvoiceLines
                        invoiceData={multipleInvoiceData}
                        setInvoiceData={setMultipleInvoiceData}
                    />
                ) : (
                    <InvoiceLines
                        invoiceData={invoiceData}
                        setInvoiceData={setInvoiceData}
                        reverseOrder={reverseOrder}
                        setReverseOrder={setReverseOrder}
                    />
                )}
            </Paper>
            <br />
            <InvoiceFooter
                invoiceData={invoiceData}
                setInvoiceData={setInvoiceData}
                isMultipleInvoice={isMultipleInvoice}
                multipleInvoiceData={multipleInvoiceData}
                setMultipleInvoiceData={setMultipleInvoiceData}
                invoiceError={invoiceError}
                responseCode={responseCode}
                setResponseCode={setResponseCode}
            />

            <Dialog
                open={importHondaInvoiceOpen}
                onClose={() => setImportHondaInvoiceOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Import Honda Invoice</DialogTitle>
                <DialogContent>
                    <br />
                    <TextField
                        disabled={loading}
                        fullWidth
                        size="small"
                        label="Invoice ID"
                        InputLabelProps={{ shrink: true }}
                        value={hondaInvoiceId}
                        onChange={(e) => setHondaInvoiceId(e.target.value)}
                        helperText="This is found on the Honda invoice"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter')
                                ImportHondaInvoice(
                                    hondaInvoiceId,
                                    invoiceData,
                                    setInvoiceData,
                                    setLoading,
                                    setImportHondaInvoiceOpen,
                                    setPartNotFound,
                                    setPartNotFoundDrawerOpen,
                                    showSnackbar
                                );
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    {loading ? (
                        <CircularProgress sx={{ margin: 1 }} />
                    ) : (
                        <>
                            <Button
                                onClick={() => setImportHondaInvoiceOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    ImportHondaInvoice(
                                        hondaInvoiceId,
                                        invoiceData,
                                        setInvoiceData,
                                        setLoading,
                                        setImportHondaInvoiceOpen,
                                        setPartNotFound,
                                        setPartNotFoundDrawerOpen,
                                        showSnackbar
                                    )
                                }
                            >
                                Import Invoice
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                open={importStockOrderDialogOpen}
                onClose={() => setImportStockOrderDialogOpen(false)}
                fullWidth
                maxWidth="md"
                sx={{ zIndex: 1200 }}
            >
                <DialogTitle>
                    Stock Order for {invoiceData?.Supplier?.name}
                </DialogTitle>
                <DialogContent>
                    <ImportStockOrderDialogContent
                        listOfStockOrder={listOfStockOrder}
                        page={page}
                        setPage={setPage}
                        selectedStockOrders={selectedStockOrders}
                        setSelectedStockOrders={setSelectedStockOrders}
                        setIsMultipleInvoice={setIsMultipleInvoice}
                        setMultipleInvoiceData={setMultipleInvoiceData}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            ImportMultipleStockOrder(
                                id,
                                invoiceData,
                                selectedStockOrders,
                                selectedSite,
                                setIsMultipleInvoice,
                                setMultipleInvoiceData,
                                setImportStockOrderDialogOpen
                            )
                        }
                    >
                        Import Selected Stock Order(s)
                    </Button>
                </DialogActions>
            </Dialog>

            <Drawer
                title={'Parts not found in Honda Invoice: ' + hondaInvoiceId}
                subTitle={'Click on a part number to create it in Ora'}
                openDrawer={partNotFoundDrawerOpen}
                setOpenDrawer={setPartNotFoundDrawerOpen}
            >
                <PartNotFoundDrawerContent
                    partList={partNotFound}
                    setPartList={setPartNotFound}
                    setPartNotFoundDrawerOpen={setPartNotFoundDrawerOpen}
                    hondaInvoiceId={hondaInvoiceId}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    setLoading={setLoading}
                    setImportHondaInvoiceOpen={setImportHondaInvoiceOpen}
                    showSnackbar={showSnackbar}
                />
            </Drawer>

            <SpeedDial actionsList={actionsList} />
        </PageWrapper>
    );
};

export default withSnackbar(NewStockInvoice);
