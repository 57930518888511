// IMPORTS
import { Dispatch, SetStateAction } from 'react';

// INTERFACES
import { Customer } from '../interfaces/GeneralInterface';

// LOGIC
import api from '../../../../api';
import { showSnackbar } from '../interfaces/GlobalInterface';

/**
 * GetCustomer
 * Front-end API request for a single customer
 * @author Estienne
 * @param id the customer's ID
 * @param setCustomerInfo setter for Customer data
 */
const GetCustomer = (
    id: string,
    setCustomerInfo: Dispatch<SetStateAction<Customer>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar?: showSnackbar,
    setInvalid?: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    api.get(`customerNextGen/${id}`).then((res) => {
        if (res.status === 204 && setInvalid) {
            showSnackbar(
                'Sale does not exist.',
                'Please double check your URL and try again.',
                'warning'
            );
            setLoading(false);
            setInvalid(true);
        } else {
            if (res.data.discountType == null) res.data.discountType = 'Retail';
            setCustomerInfo(res.data);
            setLoading(false);
        }
    });
};

export default GetCustomer;
