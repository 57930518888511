import api from '../../../../../api';

const PrepareData = (params) => {
    // Check if the user has selected a supplier, if not return error message.
    if (!params.selectedSupplier.id) {
        params.showSnackbar('Please select a Supplier.', '', 'error');
        return;
    }

    let isPartNumber = false;
    let isName = false;
    let isCostDaily = false;
    let isPriceRRP = false;
    let additionalHeader = false;

    // Go through the selected headers and check that at least two (one being part number) has actually been mapped
    params.selectedHeaders.forEach((header) => {
        if (header === 'partNumber') isPartNumber = true;
        if (header === 'name') isName = true;
        if (header === 'costPriceDaily') isCostDaily = true;
        if (header === 'costPriceDailyIncGST') isCostDaily = true;
        if (header === 'priceRRP') isPriceRRP = true;
        if (header === 'priceRRPExcGST') isPriceRRP = true;
        if (header != null && header !== '' && header !== 'partNumber')
            additionalHeader = true;
    });

    // Check if import instructions include updating, if so check that at least one field to update has been checked
    if (
        (params.importInstructions === 'addOnly' ||
            params.importInstructions === 'updateAndAdd') &&
        (!isPartNumber || !isName || !isCostDaily || !isPriceRRP)
    ) {
        params.showSnackbar(
            'Part Number, Name, Cost Price Daily and RRP headers must be selected. ',
            '',
            'error'
        );
        return;
    }

    if (
        params.importInstructions === 'updateOnly' &&
        (!isPartNumber || !additionalHeader)
    ) {
        params.showSnackbar(
            'Please select a Part Number header and additional fields to update.',
            '',
            'error'
        );
        return;
    }

    if (params.selectedSite.length === 0) {
        params.showSnackbar(
            'Please select one or more Site to update.',
            '',
            'error'
        );
        return;
    }
    let siteIds = params.selectedSite.map((x) => x.id);
    // Remove the first and last 10 items from the original import data array
    var splicedImportData = params.importData.slice(
        10,
        params.importData.length - 10
    );

    // Create a new array the joins the edited preview data and the spliced import data
    var joinedImportData = [
        ...params.previewData.first10,
        ...splicedImportData,
        ...params.previewData.last10
    ];

    // Assign each rows data to the selected header value
    joinedImportData.forEach((row) => {
        Object.keys(row).forEach((line) => {
            if (
                params.selectedHeaders[line] === null ||
                params.selectedHeaders[line] === ''
            ) {
                params.selectedHeaders[line] = 'ignore';
            }
            Object.defineProperty(
                row,
                params.selectedHeaders[line],
                Object.getOwnPropertyDescriptor(row, line)
            );
            delete row[line];
        });
    });

    joinedImportData.forEach((row) => {
        if (row.tradePrice) {
            row.tradePrice = row.tradePrice.replace(/[^0-9.]+/g, '');
        }
        if (row.tradePriceIncGST) {
            row.tradePriceIncGST = row.tradePriceIncGST.replace(
                /[^0-9.]+/g,
                ''
            );
        }
        if (row.priceRRP) {
            row.priceRRP = row.priceRRP.replace(/[^0-9.]+/g, '');
        }
        if (row.priceRRPExcGST) {
            row.priceRRPExcGST = row.priceRRPExcGST.replace(/[^0-9.]+/g, '');
        }
        if (row.costPriceDaily) {
            row.costPriceDaily = row.costPriceDaily.replace(/[^0-9.]+/g, '');
        }
        if (row.costPriceDailyIncGST) {
            row.costPriceDailyIncGST = row.costPriceDailyIncGST.replace(
                /[^0-9.]+/g,
                ''
            );
        }
        if (row.costPriceStock) {
            row.costPriceStock = row.costPriceStock.replace(/[^0-9.]+/g, '');
        }
        if (row.costPriceStockIncGST) {
            row.costPriceStockIncGST = row.costPriceStockIncGST.replace(
                /[^0-9.]+/g,
                ''
            );
        }
    });

    params.setImporting(true);

    api.post(`importSupplierFile/${params.selectedSupplier.id}`, {
        importInstructions: params.importInstructions,
        selectedSite: {
            SupplierId: params.selectedSupplier.id,
            code: params.selectedSupplier.code,
            name: params.selectedSupplier.name
        },
        siteIds: siteIds,
        data: joinedImportData
    }).then((res) => {
        if (res.status === 200) {
            let data = res.data;
            params.setImporting(false);
            if (params.importInstructions === 'addOnly') {
                params.showSnackbar(
                    `Import job complete. Added ${data.added} Parts. ${data.ignored} Parts ignored.`
                );
            }
            if (params.importInstructions === 'updateOnly') {
                params.showSnackbar(
                    `Import job complete. Updated ${data.updated} Parts. ${data.ignored} Parts ignored.`
                );
            }
            if (params.importInstructions === 'updateAndAdd') {
                params.showSnackbar(
                    `Import job complete. Added ${data.added} Parts. Updated ${data.updated} Parts. ${data.ignored} Parts ignored.`
                );
            }
        } else {
            params.setImporting(false);
            if (res.data.message) {
                params.showSnackbar(res.data.message, '', 'error');
            } else {
                params.showSnackbar(
                    'Something has gone wrong, please check the file you are trying to import.',
                    '',
                    'error'
                );
            }
        }
    });
};

export default PrepareData;
