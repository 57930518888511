// IMPROTS
import { useEffect, useState } from 'react';
import { TableRow, IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { Dayjs } from 'dayjs';

// COMPONENTS
import { withSnackbar } from '../../global/WrappingSnackbar';
import Paper from '../../global/Paper';
import InvoiceHeader from './InvoiceHeader';
import PageWrapper from '../../global/PageWrapper';
import DataCell from '../../global/tableComponents/DataCell';
import DataTable from '../../global/tableComponents/DataTable';
import DeleteFab from '../../global/DeleteFab';
import Fab from '../../global/Fab';

// LOGIC
import SubmitCreditInvoice from './logic/SubmitCreditInvoice';
import { CurrencyFormatter } from '../../global/logic/Formatters';
import GetCreditInvoice from './logic/GetCreditInvoice';
import UpdateCreditInvoice from './logic/UpdateCreditInvoice';
import DeleteCreditInvoice from './logic/DeleteCreditInvoice';

// INTERFACES
import {
    Creditor,
    Customer,
    Site
} from '../../global/interfaces/GeneralInterface';
import { Account } from '../../global/interfaces/AdminInterface';
import {
    PurchaseOrder,
    Service
} from '../../global/interfaces/ServiceInterface';
import { Vehicle } from '../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Account?: Account;
    Debtor?: Customer;
    Vehicle?: Vehicle;
    PurchaseOrder?: PurchaseOrder;
    type: string;
    documentReference: string;
    documentDate: Dayjs;
    dueDate: Dayjs;
    GSTtype: string;
    documentTotal: string;
    StockInvoiceId?: number;
}
interface InvoiceLine {
    id: number;
    Account?: Account;
    Debtor?: Customer;
    Vehicle?: Vehicle;
    Service?: Service;
    amount: string;
    description: string;
}

const CreditInvoice = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const [responseCode, setResponseCode] = useState<number>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([]);
    const [invoiceObject, setInvoiceObject] = useState<InvoiceObject>({
        Creditor: null,
        Site: null,
        Account: null,
        Debtor: null,
        Vehicle: null,
        PurchaseOrder: null,
        type: 'Account',
        documentReference: null,
        documentDate: null,
        dueDate: null,
        GSTtype: null,
        documentTotal: null
    });

    const columns = [
        { id: 0, label: '', width: 325 },
        { id: 1, label: 'Description', width: 325 },
        { id: 2, label: 'Amount', width: 250 },
        { id: 3, label: '', width: 100 }
    ];

    useEffect(() => {
        if (id) {
            GetCreditInvoice(
                parseInt(id),
                setInvoiceObject,
                setInvoiceLines,
                setDisabled
            );
        }
    }, [id]);

    // Display the appropiate label depending on the entity
    const getEntityName = (line: InvoiceLine) => {
        if (line?.Account) return line?.Account?.name;
        else if (line?.Debtor) return line?.Debtor?.fullName;
        else if (line?.Vehicle) return line?.Vehicle?.vehicleName;
        else if (line?.Service) return line?.Service?.id;
        else return 'Misc';
    };

    // Handle removing a line from the invoice
    const removeLine = (lineId: number) => {
        let currentLines = [...invoiceLines];
        currentLines = currentLines.filter((x) => x.id !== lineId);
        setInvoiceLines(currentLines);
    };

    // Get the total of all the entered lines
    const totalLines = () => {
        let total = 0;
        invoiceLines.forEach((line) => {
            total += parseFloat(line.amount);
        });
        return total;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">
                {id ? `Credit Invoice #${id}` : 'Enter Credit Invoice'}
            </Typography>
            <br />
            <InvoiceHeader
                id={parseInt(id)}
                invoiceObject={invoiceObject}
                setInvoiceObject={setInvoiceObject}
                invoiceLines={invoiceLines}
                setInvoiceLines={setInvoiceLines}
                responseCode={responseCode}
                setResponseCode={setResponseCode}
                showSnackbar={showSnackbar}
                disabled={disabled}
            />
            <br />
            {invoiceLines.length > 0 && (
                <Paper>
                    <DataTable columns={columns}>
                        {invoiceLines.map((line) => (
                            <TableRow>
                                <DataCell>{getEntityName(line)}</DataCell>
                                <DataCell>{line.description}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.amount)}
                                </DataCell>
                                <DataCell>
                                    {id ? null : (
                                        <IconButton
                                            onClick={() => removeLine(line.id)}
                                        >
                                            <Close />
                                        </IconButton>
                                    )}
                                </DataCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <DataCell></DataCell>
                            <DataCell>
                                <b>Total</b>
                            </DataCell>
                            <DataCell>
                                <b>{CurrencyFormatter(totalLines())}</b>
                            </DataCell>
                            <DataCell></DataCell>
                        </TableRow>
                    </DataTable>
                </Paper>
            )}

            <DeleteFab
                onClick={() =>
                    DeleteCreditInvoice(
                        id,
                        invoiceObject.Creditor.id,
                        showSnackbar
                    )
                }
            />

            <Fab
                noSession={true}
                editIcon={disabled}
                onClick={() => {
                    if (disabled) {
                        setDisabled(false);
                    } else {
                        if (id) {
                            UpdateCreditInvoice(
                                id,
                                invoiceObject,
                                invoiceLines,
                                totalLines(),
                                responseCode,
                                showSnackbar
                            );
                        } else {
                            SubmitCreditInvoice(
                                invoiceObject,
                                invoiceLines,
                                totalLines(),
                                responseCode,
                                showSnackbar
                            );
                        }
                    }
                }}
            />
        </PageWrapper>
    );
};

export default withSnackbar(CreditInvoice);
