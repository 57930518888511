import { Step } from '../../../interface';

export const GSTReportsSteps: Step[] = [
    {
        label: 'In the sidebar, navigate to Admin -> Reporting to open the Ora Generated Reports page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-01.png'
    },
    {
        label: 'Under the GST Reports tab, select one or multiple sites from the Site dropdown list.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/GST-02.png'
    },
    {
        label: 'Select a reporting start date and end date for your desired report period.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/GST-03.png'
    },
    {
        label: 'The "Generate Report" button will be disabled until at least one site and both dates are selected. Once these are set, click the button to generate the GST report.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/GST-04.png'
    },
    {
        label: 'The report will be displayed transaction data and amounts for each GST category, such as G1, G2, G3, etc. Click on any hyperlinked data to view associated details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/GST-05.png'
    },
    {
        label: 'Clicking on an ID will open the transaction details page in a new tab.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/GST-06.png'
    }
];
