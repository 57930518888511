// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Divider,
    IconButton,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import Paper from '../../../global/Paper';
// INTERFACES
import { Till } from '../../../global/interfaces/GeneralInterface';

interface SiteProps {
    tills: Till[];
    setTills: Dispatch<SetStateAction<Till[]>>;
    siteId: number;
    readOnly: boolean;
}

const Tills = ({ tills, setTills, siteId, readOnly }: SiteProps) => {
    const columns = [
        { id: 0, label: 'Name' },
        { id: 1, label: 'Code' }
    ];

    const AddTill = () => {
        let tempTills = [...tills];
        let id =
            tempTills.length > 0 ? tempTills[tempTills.length - 1].id + 1 : 1;
        tempTills.push({ id: id, name: null, SiteId: siteId });
        setTills(tempTills);
    };

    const EditTill = (newValue: string, tillId: number, field: string) => {
        let tempTills = [...tills];
        let index = tempTills.findIndex((x) => x.id === tillId);
        let till = { ...tempTills[index] };
        till[field] = newValue;

        tempTills[index] = till;
        setTills(tempTills);
    };

    const RemoveTill = (tillId: number) => {
        let tempTills = tills.filter((item) => item.id !== tillId);
        setTills(tempTills);
    };

    return (
        <Paper textAlign="center">
            <Typography variant="h6" align="left">
                Tills
            </Typography>
            <Divider /> <br />
            <DataTable columns={columns} cypressLabel="viewSiteTillTable">
                {tills?.map((till, index) => (
                    <TableRow key={index}>
                        <DataCell width="100">
                            {readOnly ? (
                                <Typography data-cy={`tillNameField-${index}`}>{till?.name}</Typography>
                            ) : (
                                <TextField
                                    data-cy={`tillNameField-${index}`}
                                    fullWidth
                                    size="small"
                                    variant="standard"
                                    value={till?.name}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'center' }
                                    }}
                                    onChange={(e) =>
                                        EditTill(
                                            e.target.value,
                                            till?.id,
                                            'name'
                                        )
                                    }
                                />
                            )}
                        </DataCell>
                        <DataCell width="100">
                            {readOnly ? (
                                <Typography data-cy={`tillCodeField-${index}`}>{till?.code}</Typography>
                            ) : (
                                <TextField
                                    data-cy={`tillCodeField-${index}`}
                                    fullWidth
                                    size="small"
                                    variant="standard"
                                    value={till?.code}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'center' }
                                    }}
                                    onChange={(e) =>
                                        EditTill(
                                            e.target.value,
                                            till?.id,
                                            'code'
                                        )
                                    }
                                />
                            )}
                        </DataCell>
                        {readOnly ? null : (
                            <DataCell width="40">
                                <Tooltip placement="left" title="Remove Till">
                                    <IconButton
                                        data-cy={`viewSiteRemoveTillIconButton-${till.id}`}
                                        onClick={() => RemoveTill(till?.id)}
                                    >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </DataCell>
                        )}
                    </TableRow>
                ))}
            </DataTable>
            {readOnly ? null : (
                <IconButton
                    onClick={() => AddTill()}
                    data-cy="viewSiteAddTillIconButton"
                >
                    <Add />
                </IconButton>
            )}
        </Paper>
    );
};

export default Tills;
