// IMPORTS
import { Button, Tooltip } from '@mui/material';
// LOGIC
import PDFJobCard from '../logic/PDFJobCard';
import PDFCustomerInvoice from '../logic/PDFCustomerInvoice';
// INTERFACES
import {
    CustomerVehicle,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
interface PrintButtonsProps {
    serviceDetails: Service;
    selectedCustomer: Customer;
    selectedInsurer: Customer;
    vehicleType: string;
    selectedVehicle: Vehicle | CustomerVehicle;
    partSales: Order[];
}
const PrintButtons = ({
    serviceDetails,
    selectedCustomer,
    vehicleType,
    selectedVehicle,
    partSales,
    selectedInsurer
}: PrintButtonsProps) => {
    return (
        <>
            <Button
                fullWidth
                variant="contained"
                onClick={() =>
                    PDFJobCard(
                        serviceDetails,
                        selectedCustomer,
                        vehicleType,
                        selectedVehicle
                    )
                }
            >
                Job Card
            </Button>
            <br />
            <br />
            {serviceDetails.serviceComplete === 'Incomplete' ? (
                <Tooltip
                    title={
                        'Service cannot be incomplete to print a Customer Invoice.'
                    }
                    placement="left"
                >
                    <span>
                        <Button
                            fullWidth
                            disabled={true}
                            variant="contained"
                            onClick={() =>
                                PDFCustomerInvoice(
                                    serviceDetails,
                                    selectedCustomer,
                                    vehicleType,
                                    selectedVehicle,
                                    partSales,
                                    false
                                )
                            }
                        >
                            Customer Invoice
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() =>
                        PDFCustomerInvoice(
                            serviceDetails,
                            selectedCustomer,
                            vehicleType,
                            selectedVehicle,
                            partSales,
                            false
                        )
                    }
                >
                    Customer Invoice
                </Button>
            )}
            <br />
            <br />
            {serviceDetails.jobType === 'Insurance' ? (
                <>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            PDFCustomerInvoice(
                                serviceDetails,
                                selectedInsurer,
                                vehicleType,
                                selectedVehicle,
                                partSales,
                                true,
                                true
                            )
                        }
                    >
                        Insurer Quote
                    </Button>
                    <br />
                    <br />
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            PDFCustomerInvoice(
                                serviceDetails,
                                selectedInsurer,
                                vehicleType,
                                selectedVehicle,
                                partSales,
                                true
                            )
                        }
                    >
                        Insurer Invoice
                    </Button>
                </>
            ) : null}
        </>
    );
};

export default PrintButtons;
