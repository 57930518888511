// LOGIC
import ValidateService from './ValidateService';
import api from '../../../../../api';
// INTERFACES
import { Customer, User } from '../../../global/interfaces/GeneralInterface';
import {
    CustomerVehicle,
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Order } from '../../../global/interfaces/PartsInterface';

const HandleSubmitService = (
    selectedCustomer: Customer,
    selectedInsurer: Customer,
    vehicleType: string,
    selectedVehicle: Vehicle | CustomerVehicle,
    selectedTech: User,
    serviceDetails: Service,
    partSales: Order[],
    sublets: PurchaseOrder[],
    showSnackbar: showSnackbar
) => {
    let validated = ValidateService(
        selectedCustomer,
        selectedInsurer,
        vehicleType,
        selectedVehicle,
        serviceDetails,
        partSales,
        sublets,
        showSnackbar
    );

    if (validated) {
        let obj = {
            selectedCustomer: selectedCustomer,
            selectedInsurer: selectedInsurer,
            vehicleType: vehicleType,
            selectedVehicle: selectedVehicle,
            selectedTech: selectedTech,
            serviceDetails: serviceDetails
        };
        api.post(
            `createNewService?SiteId=${localStorage.getItem('SiteId')}`,
            obj
        ).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Service submitted successfully.',
                    'The page will now redirect you to the booking table.'
                );
                window.location.href = '/service/bookings';
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }
};

export default HandleSubmitService;
