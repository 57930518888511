const FilterOraTransactions = (
    oraFilter,
    reconciliationDetails,
    setReconciliationDetails,
    reconciliationDetailsUnaltered
) => {
    let currentUnreconciledOra = [
        ...reconciliationDetails.oraTransactionsUnreconciled
    ];
    let currentReconciledOra = [
        ...reconciliationDetails.oraTransactionsReconciled
    ];

    if (oraFilter === 'tillReconciliation') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.TillReconciliationId
        );
    } else if (oraFilter === 'manualTransaction') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.ManualTransactionId
        );
    } else if (oraFilter === 'debtorPayment') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.DebtorPaymentId
        );
    } else if (oraFilter === 'creditorPayrun') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.PaymentRunId
        );
    } else if (oraFilter === 'directDeposit') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.ServiceId || x.VehicleSaleId || x.OrderId
        );
    } else if (oraFilter === 'stockPurchase') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.TradeId
        );
    } else if (oraFilter === 'trustReceipt') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.TrustReceiptId
        );
    } else if (oraFilter === 'warranty') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.ServiceWarrantyId
        );
    } else if (oraFilter === 'rebate') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.VehicleRebateId
        );
    } else if (oraFilter === 'stripe') {
        currentUnreconciledOra = currentUnreconciledOra.filter(
            (x) => x.StripePaymentId
        );
    }

    if (oraFilter == null) {
        let unfilteredLines = [];
        for (let line of reconciliationDetailsUnaltered.oraTransactionsUnreconciled) {
            let canBePushed = true;
            if (
                line.TillReconciliationId &&
                currentReconciledOra.filter(
                    (x) => x.TillReconciliationId === line.TillReconciliationId
                ).length > 0
            ) {
                canBePushed = false;
            } else if (
                line.ManualTransactionId &&
                currentReconciledOra.filter(
                    (x) => x.ManualTransactionId === line.ManualTransactionId
                ).length > 0
            ) {
                canBePushed = false;
            } else if (
                line.DebtorPaymentId &&
                currentReconciledOra.filter(
                    (x) => x.DebtorPaymentId === line.DebtorPaymentId
                ).length > 0
            ) {
                canBePushed = false;
            } else if (
                line.PaymentRunId &&
                currentReconciledOra.filter(
                    (x) => x.PaymentRunId === line.PaymentRunId
                ).length > 0
            ) {
                canBePushed = false;
            } else if (
                (line.ServiceId &&
                    currentReconciledOra.filter(
                        (x) => x.ServiceId === line.ServiceId
                    ).length > 0) ||
                (line.VehicleSaleId &&
                    currentReconciledOra.filter(
                        (x) => x.VehicleSaleId === line.VehicleSaleId
                    ).length > 0) ||
                (line.OrderId &&
                    currentReconciledOra.filter(
                        (x) => x.OrderId === line.OrderId
                    ).length > 0)
            ) {
                canBePushed = false;
            } else if (
                line.TradeId &&
                currentReconciledOra.filter((x) => x.TradeId === line.TradeId)
                    .length > 0
            ) {
                canBePushed = false;
            } else if (
                line.TrustReceiptId &&
                currentReconciledOra.filter(
                    (x) => x.TrustReceiptId === line.TrustReceiptId
                ).length > 0
            ) {
                canBePushed = false;
            } else if (
                line.ServiceWarrantyId &&
                currentReconciledOra.filter(
                    (x) => x.ServiceWarrantyId === line.ServiceWarrantyId
                ).length > 0
            ) {
                canBePushed = false;
            } else if (
                line.VehicleRebateId &&
                currentReconciledOra.filter(
                    (x) => x.VehicleRebateId === line.VehicleRebateId
                ).length > 0
            ) {
                canBePushed = false;
            } else if (
                line.StripePaymentId &&
                currentReconciledOra.filter(
                    (x) => x.StripePaymentId === line.StripePaymentId
                ).length > 0
            ) {
                canBePushed = false;
            }

            if (canBePushed) {
                unfilteredLines.push(line);
            }
        }
        setReconciliationDetails({
            ...reconciliationDetails,
            oraTransactionsUnreconciled: unfilteredLines
        });
    } else {
        setReconciliationDetails({
            ...reconciliationDetails,
            oraTransactionsUnreconciled: currentUnreconciledOra
        });
    }
};

export default FilterOraTransactions;
