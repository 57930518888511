// IMPORTS
import { useState, useEffect, useContext } from 'react';
import { Typography, TableRow, Chip, Dialog } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { SiteContext } from '../../../main/Main';
// COMPONENTS
import VehicleSaleTableFilter from './components/VehicleSaleTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import EmptyVehicleTableDialogContent from '../emptyTableDialog/EmptyVehicleTableDialogContent';
import { ImportVehicleCSVContent } from '../emptyTableDialog/ImportVehicleCSVContent';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import { DateFormatter } from '../../global/logic/Formatters';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { VehicleSale } from '../../global/interfaces/VehicleSaleInterface';
import { Site } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    vehicleSaleFilter: {
        user: [],
        site: [],
        status: [],
        saleStartDate: null,
        saleEndDate: null,
        finalisedStartDate: null,
        finalisedEndDate: null,
        archived: false,
        fullyPaid: false
    }
};
const clearedSort = ['id', 'DESC'];

const VehicleSaleTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const history = useHistory();
    const siteDetails = useContext<Site>(SiteContext);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [emptyField, setEmptyField] = useState<string>('');
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(
            JSON.stringify({
                vehicleSaleFilter: {
                    user: [],
                    site: [
                        {
                            id: parseInt(localStorage.getItem('SiteId')),
                            name: ''
                        }
                    ],
                    status: [{ value: 'Sale' }, { value: 'Quote' }],
                    saleStartDate: null,
                    saleEndDate: null,
                    finalisedStartDate: null,
                    finalisedEndDate: null,
                    archived: false,
                    fullyPaid: false
                }
            })
        )
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<VehicleSale[]>([]);

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Sale',
            onClick: () => history.push('/vehicles/newContract')
        }
    ];

    // Columns for the data table
    const columns =
        siteDetails.vehicleTypeSold === 'Motorcycles'
            ? [
                  { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
                  { id: 1, label: 'Stock Number', width: 100 },
                  { id: 2, label: 'Vehicle', width: 100 },
                  { id: 3, label: 'Short VIN', width: 100 },
                  { id: 4, label: 'Sold', width: 100 },
                  { id: 5, label: 'Finalised', width: 100 },
                  { id: 6, label: 'Customer', width: 100 },
                  { id: 7, label: 'User', width: 100 },
                  { id: 8, label: 'Location', width: 100 },
                  { id: 9, label: 'Status', width: 100 }
              ]
            : [
                  { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
                  { id: 1, label: 'Stock Number', width: 100 },
                  { id: 2, label: 'Vehicle', width: 100 },
                  { id: 4, label: 'Sold', width: 100 },
                  { id: 5, label: 'Finalised', width: 100 },
                  { id: 6, label: 'Customer', width: 100 },
                  { id: 7, label: 'User', width: 100 },
                  { id: 8, label: 'Location', width: 100 },
                  { id: 9, label: 'Status', width: 100 }
              ];

    // Once the current site details have been obtained set the filter to some default values
    useEffect(() => {
        let newFilter = {
            user: [],
            site: [
                {
                    id: parseInt(localStorage.getItem('SiteId')),
                    name: siteDetails?.name
                }
            ],
            status: [{ value: 'Sale' }, { value: 'Quote' }],
            saleStartDate: null,
            saleEndDate: null,
            finalisedStartDate: null,
            finalisedEndDate: null,
            archived: false,
            fullyPaid: false
        };
        setFilter({ ...filter, vehicleSaleFilter: newFilter });
        // eslint-disable-next-line
    }, [siteDetails]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let user =
            filter.vehicleSaleFilter.user.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleSaleFilter.user,
                      filterStart: '&user=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let site =
            filter.vehicleSaleFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleSaleFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let status =
            filter.vehicleSaleFilter.status.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleSaleFilter.status,
                      filterStart: '&status=',
                      selector: 'value'
                  })
                : '';

        let saleStartDate =
            filter.vehicleSaleFilter.saleStartDate !== null &&
            dayjs(filter.vehicleSaleFilter.saleStartDate).isValid()
                ? `&saleStartDate=${dayjs(filter.vehicleSaleFilter.saleStartDate).toISOString()}`
                : '';
        let saleEndDate =
            filter.vehicleSaleFilter.saleEndDate !== null &&
            dayjs(filter.vehicleSaleFilter.saleEndDate).isValid()
                ? `&saleEndDate=${dayjs(filter.vehicleSaleFilter.saleEndDate).toISOString()}`
                : '';
        let finalisedStartDate =
            filter.vehicleSaleFilter.finalisedStartDate !== null &&
            dayjs(filter.vehicleSaleFilter.finalisedStartDate).isValid()
                ? `&finalisedStartDate=${dayjs(filter.vehicleSaleFilter.finalisedStartDate).toISOString()}`
                : '';
        let finalisedEndDate =
            filter.vehicleSaleFilter.finalisedEndDate !== null &&
            dayjs(filter.vehicleSaleFilter.finalisedEndDate).isValid()
                ? `&finalisedEndDate=${dayjs(filter.vehicleSaleFilter.finalisedEndDate).toISOString()}`
                : '';
        let archived = `&archived=${filter.vehicleSaleFilter.archived}`;
        let fullyPaid = `&fullyPaid=${filter.vehicleSaleFilter.fullyPaid}`;

        let apiString = `${user}${site}${status}${saleStartDate}${saleEndDate}${finalisedStartDate}${finalisedEndDate}${archived}${fullyPaid}&checkEmpty=true`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.vehicleSaleFilter.user.length > 0 ||
            filter.vehicleSaleFilter.site.length > 0 ||
            filter.vehicleSaleFilter.status.length > 0 ||
            filter.vehicleSaleFilter.saleStartDate != null ||
            filter.vehicleSaleFilter.saleEndDate != null ||
            filter.vehicleSaleFilter.finalisedStartDate != null ||
            filter.vehicleSaleFilter.finalisedEndDate != null ||
            filter.vehicleSaleFilter.archived
        ) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Vehicle Sales</Typography>
            <br />
            <TableSearch
                searchTitle="Search Sales"
                showFilter={true}
                showPagination={
                    filter.vehicleSaleFilter.fullyPaid ? false : true
                }
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="vehicleSaleSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
                setIsEmpty={setIsEmpty}
                setEmptyField={setEmptyField}
            >
                <VehicleSaleTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() =>
                                    history.push(
                                        `/vehicles/viewContract/${row.id}`
                                    )
                                }
                            >
                                {row.id}
                            </DataCellColoured>
                            {row.Vehicle?.stockNumber ? (
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            `/vehicles/view/${row.VehicleId}`,
                                            '_blank'
                                        )
                                    }
                                >
                                    {row.Vehicle?.stockNumber}
                                </DataCellColoured>
                            ) : (
                                <DataCell>ORDER</DataCell>
                            )}

                            <DataCell>
                                {row.Vehicle
                                    ? row.Vehicle?.Make?.name +
                                      ' ' +
                                      row.Vehicle?.Model?.name +
                                      ' ' +
                                      (row.Vehicle?.Series
                                          ? row.Vehicle?.Series?.name
                                          : '')
                                    : row.NewVehicle?.Make?.name +
                                      ' ' +
                                      row.NewVehicle?.Model?.name +
                                      ' ' +
                                      (row.NewVehicle?.Series
                                          ? row.NewVehicle?.Series?.name
                                          : '')}
                            </DataCell>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                                <DataCell>
                                    {row.Vehicle
                                        ? '...' + row.Vehicle?.vin?.slice(-6)
                                        : ''}
                                </DataCell>
                            ) : null}
                            <DataCell>
                                {row.saleDate
                                    ? DateFormatter(row.saleDate)
                                    : ''}
                            </DataCell>
                            <DataCell>
                                {row.finalisedDate
                                    ? DateFormatter(row.finalisedDate)
                                    : ''}
                            </DataCell>

                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/viewCustomer/${row.CustomerId}`,
                                        '_blank'
                                    )
                                }
                            >
                                {row.Customer?.firstName}{' '}
                                {row.Customer?.lastName}
                            </DataCellColoured>
                            <DataCell>
                                {row.User?.firstName} {row.User?.lastName}
                            </DataCell>
                            <DataCell>{row.Site?.name}</DataCell>
                            <DataCell>
                                <Chip
                                    label={row.saleStatus}
                                    variant="outlined"
                                    color={
                                        row.saleStatus === 'Finalised'
                                            ? 'success'
                                            : row.saleStatus === 'Sale'
                                              ? 'warning'
                                              : 'error'
                                    }
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Dialog
                open={isEmpty}
                onClose={() => {
                    setIsEmpty(false);
                    setEmptyField('');
                }}
                maxWidth="md"
                fullWidth
            >
                {emptyField === 'Vehicle' ? (
                    <EmptyVehicleTableDialogContent
                        setIsEmpty={setIsEmpty}
                        setEmptyField={setEmptyField}
                    />
                ) : null}
            </Dialog>

            <Dialog
                open={!isEmpty && emptyField.length > 0}
                onClose={() => setEmptyField('')}
                maxWidth="xl"
                fullWidth
            >
                {emptyField === 'Vehicle' ? (
                    <ImportVehicleCSVContent showSnackbar={showSnackbar} />
                ) : null}
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(VehicleSaleTable);
