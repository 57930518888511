import DocumentationPage from '../DocumentationPage';
import {
    InvoiceStockOrderSteps,
    CreateNewStockInvoiceSteps
} from '../data/stockControl/enterStockInvoice';

const EnterStockInvoiceSupportPage = () => {
    const contentSections = [
        {
            id: 'enter-stock-invoice',
            title: 'Invoice an existing Stock Order',
            href: '/support/inventory/stockInvoiceNoPO/#enter-stock-invoice',
            description:
                'To invoice an existing Stock Order, follow these outlined steps:',
            steps: InvoiceStockOrderSteps
        },
        {
            id: 'create-new-stock-invoice',
            title: 'Manually create a Stock Invoice',
            href: '/support/inventory/stockInvoiceNoPO/#create-new-stock-invoice',
            description:
                'If you want to manually create a Stock Invoice, follow these outlined steps:',
            steps: CreateNewStockInvoiceSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Enter Stock Invoice"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default EnterStockInvoiceSupportPage;
