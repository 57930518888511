import { Typography } from '@mui/material';
import PageWrapper from '../global/PageWrapper';
import Tabs from '../global/Tabs';
import Dashboard from './components/Dashboard/Dashboard';
import PickAndPack from './components/PickAndPack/PickAndPack';
import PendingAndDispatched from './components/PendingAndDispatched/PendingAndDispatched';

const OnlineOrders = () => {
  const tabContent = [
    { id: 0, title: 'Dashboard', content: <Dashboard /> },
    { id: 1, title: 'Pick & Pack', content: <PickAndPack /> },
    { id: 2, title: 'Pending & Dispatched', content: <PendingAndDispatched /> }
  ];

  return (
    <PageWrapper>
      <Typography variant="h4">Online Orders</Typography>
      <br />
      <Tabs tabContent={tabContent} />
    </PageWrapper>
  );
};

export default OnlineOrders;
