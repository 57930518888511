// IMPORTS
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
// LOGIC
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import RoleCheck from '../../../../global/RoleCheck';
interface VehicleDetailsProps {
  selectedVehicle: Vehicle;
  addedCostsTotal: any;
}

const VehicleDetails = ({
  selectedVehicle,
  addedCostsTotal
}: VehicleDetailsProps) => {
  const [viewPricing, setViewPricing] = useState<boolean>(false);

  const ViewPricingButton = () => {
    if (viewPricing) {
      return (
        <Tooltip placement="right" title="Hide Pricing Details">
          <IconButton onClick={() => setViewPricing(false)}>
            <VisibilityOff />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip placement="right" title="Show Pricing Details">
          <IconButton onClick={() => setViewPricing(true)}>
            <Visibility />
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <>
      <Typography variant="h6">
        Vehicle Details{' '}
        <RoleCheck
          permission="sales_manager, assign_superusers"
          component={<ViewPricingButton />}
        />
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body2">
            <b>Stock Number</b>
            <br />
            {selectedVehicle?.stockNumber}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            <b>Vehicle</b>
            <br />
            {selectedVehicle?.condition} {selectedVehicle?.year.format('YYYY')}{' '}
            {selectedVehicle?.Make?.name} {selectedVehicle?.Model?.name}{' '}
            {selectedVehicle?.Series?.name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            <b>Location</b>
            <br />
            {selectedVehicle?.Site?.name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            <b>RRP</b>
            <br />
            {CurrencyFormatter(selectedVehicle?.priceRRP)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            <b>Base Cost (EX. GST)</b>
            <br />
            {viewPricing ? (
              CurrencyFormatter(selectedVehicle?.costPrice)
            ) : (
              <br />
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            <b>Admin Load</b>
            <br />
            {viewPricing ? (
              CurrencyFormatter(selectedVehicle?.adminLoadCost)
            ) : (
              <br />
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            <b>Service Costs</b>
            <br />
            {viewPricing ? (
              CurrencyFormatter(addedCostsTotal?.services)
            ) : (
              <br />
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            <b>Extra Costs</b>
            <br />
            {viewPricing ? (
              CurrencyFormatter(
                addedCostsTotal?.sales + addedCostsTotal.invoices
              )
            ) : (
              <br />
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default VehicleDetails;
