// IMPORTS
import { useEffect, useMemo, useState } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { Dayjs } from 'dayjs';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import PartSalesCounter from './components/PartSaleCounter';
import PartSaleRevenueOverview from './components/PartSaleRevenueOverview';
import SiteAndDateHeader from '../../global/dashboard/SiteAndDateHeader';
import Paper from '../../global/Paper';
import MonthlyMetricsComparisonTable from '../../global/dashboard/MonthlyMetricsComparisonTable';
// LOGIC
import { CurrencyFormatter, ToFixed } from '../../global/logic/Formatters';
import CalculatePartSalesRevenue from './logic/CalculatePartSalesRevenue';
import getDashboardData from '../../global/dashboard/getDashboardData';
import {
    getComparisonDates,
    getDefaultDateRange
} from '../../global/dashboard/getMonthDatesAndBusinessDays';
// INTERFACES
import { Order } from '../../global/interfaces/PartsInterface';
import { Site } from '../../global/interfaces/GeneralInterface';

const PartsDashboard = () => {
    const { firstDayOfCurrentMonth, today } = getDefaultDateRange();

    // loading state
    const [isLoading, setIsLoading] = useState<Boolean>(true);

    // header: type; date; site
    const [selectedSite, setSelectedSite] = useState<Site[]>([]);
    const [compareType, setCompareType] = useState<'Month' | 'Year'>('Month');
    const [selectedStartDate, setSelectedStartDate] = useState<Dayjs>(
        firstDayOfCurrentMonth
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Dayjs>(today);

    // get corresponding compareStartDate and compareEndDate based on compareType and selectedStartDate and selectedEndDate
    const { compareStartDate, compareEndDate } = useMemo(() => {
        return getComparisonDates(
            compareType,
            selectedStartDate,
            selectedEndDate
        );
    }, [compareType, selectedStartDate, selectedEndDate]);

    // part sales data
    const [partSalesSelectedMonth, setPartSalesSelectedMonth] = useState<
        Order[]
    >([]);
    const [partSalesComparedMonth, setPartSalesComparedMonth] = useState<
        Order[]
    >([]);

    // fetch part sales data in date range
    const fetchPartSalesInDateRange = async () => {
        try {
            setIsLoading(true);

            if (
                selectedSite.length > 0 &&
                selectedStartDate.isValid() &&
                selectedEndDate.isValid() &&
                selectedStartDate.isBefore(selectedEndDate)
            ) {
                const result = await getDashboardData({
                    type: 'partSales',
                    startDate: selectedStartDate.toString(),
                    endDate: selectedEndDate.toString(),
                    selectedSite
                });

                setPartSalesSelectedMonth(result);

                const comparedResult = await getDashboardData({
                    type: 'partSales',
                    startDate: compareStartDate.toString(),
                    endDate: compareEndDate.toString(),
                    selectedSite
                });

                setPartSalesComparedMonth(comparedResult);
            }
        } catch (error) {
            console.error('Error fetching part sales data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchPartSalesInDateRange();
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line
    }, [
        selectedSite,
        selectedStartDate,
        selectedEndDate,
        compareType,
        compareStartDate,
        compareEndDate
    ]);

    const {
        partProformaOrderData: partProformaOrderDataSelectedMonth,
        partSalesOrderData: partSalesOrderDataSelectedMonth,
        partCreditOrderData: partCreditOrderDataSelectedMonth,
        totalSalesRevenue: selectedMonthFinalisedRevenue,
        totalSalesCost: selectedMonthFinalisedCost
    } = CalculatePartSalesRevenue(partSalesSelectedMonth);

    const {
        partProformaOrderData: partProformaOrderDataComparedMonth,
        partSalesOrderData: partSalesOrderDataComparedMonth,
        partCreditOrderData: partCreditOrderDataComparedMonth,
        totalSalesRevenue: comparedMonthFinalisedRevenue,
        totalSalesCost: comparedMonthFinalisedCost
    } = CalculatePartSalesRevenue(partSalesComparedMonth);

    // prepare data for MonthlyMetricsComparisonTable
    const RevenueComparisonData = {
        'Financial Comparison': [
            {
                id: 1,
                label: 'Finalised Revenue',
                selectedMonth: CurrencyFormatter(selectedMonthFinalisedRevenue),
                comparedMonth: CurrencyFormatter(comparedMonthFinalisedRevenue),
                difference: CurrencyFormatter(
                    selectedMonthFinalisedRevenue -
                        comparedMonthFinalisedRevenue
                )
            },
            {
                id: 2,
                label: 'Finalised Cost',
                selectedMonth: CurrencyFormatter(selectedMonthFinalisedCost),
                comparedMonth: CurrencyFormatter(comparedMonthFinalisedCost),
                difference: CurrencyFormatter(
                    selectedMonthFinalisedCost - comparedMonthFinalisedCost
                )
            },
            {
                id: 3,
                label: 'Gross Profit',
                selectedMonth: CurrencyFormatter(
                    selectedMonthFinalisedRevenue - selectedMonthFinalisedCost
                ),
                comparedMonth: CurrencyFormatter(
                    comparedMonthFinalisedRevenue - comparedMonthFinalisedCost
                ),
                difference: CurrencyFormatter(
                    selectedMonthFinalisedRevenue -
                        selectedMonthFinalisedCost -
                        (comparedMonthFinalisedRevenue -
                            comparedMonthFinalisedCost)
                )
            },
            {
                id: 4,
                label: 'Average Margin',
                selectedMonth:
                    (
                        ((selectedMonthFinalisedRevenue -
                            selectedMonthFinalisedCost) /
                            selectedMonthFinalisedRevenue) *
                        100
                    ).toFixed(2) + '%',
                comparedMonth:
                    (
                        ((comparedMonthFinalisedRevenue -
                            comparedMonthFinalisedCost) /
                            comparedMonthFinalisedRevenue) *
                        100
                    ).toFixed(2) + '%',
                difference:
                    ToFixed(
                        ((selectedMonthFinalisedRevenue -
                            selectedMonthFinalisedCost) /
                            selectedMonthFinalisedRevenue) *
                            100 -
                            ((comparedMonthFinalisedRevenue -
                                comparedMonthFinalisedCost) /
                                comparedMonthFinalisedRevenue) *
                                100
                    ) + '%'
            }
        ]
    };

    const OrderComparisonData = {
        'Order Comparison': [
            {
                id: 1,
                label: 'Proforma Orders',
                selectedMonth: partProformaOrderDataSelectedMonth.length,
                comparedMonth: partProformaOrderDataComparedMonth.length,
                difference:
                    partProformaOrderDataSelectedMonth.length -
                    partProformaOrderDataComparedMonth.length
            },
            {
                id: 2,
                label: 'Sales Orders',
                selectedMonth: partSalesOrderDataSelectedMonth.length,
                comparedMonth: partSalesOrderDataComparedMonth.length,
                difference:
                    partSalesOrderDataSelectedMonth.length -
                    partSalesOrderDataComparedMonth.length
            },
            {
                id: 3,
                label: 'Credited Orders',
                selectedMonth: partCreditOrderDataSelectedMonth.length,
                comparedMonth: partCreditOrderDataComparedMonth.length,
                difference:
                    partCreditOrderDataSelectedMonth.length -
                    partCreditOrderDataComparedMonth.length
            }
        ]
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Dashboard</Typography>
            <br />
            <SiteAndDateHeader
                department="parts"
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                compareType={compareType}
                setCompareType={setCompareType}
            />
            <br />
            {isLoading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : (
                <>
                    <PartSaleRevenueOverview
                        selectedMonthFinalisedCost={selectedMonthFinalisedCost}
                        selectedMonthFinalisedRevenue={
                            selectedMonthFinalisedRevenue
                        }
                        comparedMonthFinalisedCost={comparedMonthFinalisedCost}
                        comparedMonthFinalisedRevenue={
                            comparedMonthFinalisedRevenue
                        }
                        compareType={compareType}
                        compareStartDate={compareStartDate}
                        compareEndDate={compareEndDate}
                    />
                    <br />
                    <PartSalesCounter
                        partProformaOrderDataSelectedMonth={
                            partProformaOrderDataSelectedMonth
                        }
                        partSalesOrderDataSelectedMonth={
                            partSalesOrderDataSelectedMonth
                        }
                        partCreditOrderDataSelectedMonth={
                            partCreditOrderDataSelectedMonth
                        }
                        partProformaOrderDataComparedMonth={
                            partProformaOrderDataComparedMonth
                        }
                        partSalesOrderDataComparedMonth={
                            partSalesOrderDataComparedMonth
                        }
                        partCreditOrderDataComparedMonth={
                            partCreditOrderDataComparedMonth
                        }
                    />
                    <br />
                    <MonthlyMetricsComparisonTable
                        data={RevenueComparisonData}
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        compareType={compareType}
                        compareStartDate={compareStartDate}
                        compareEndDate={compareEndDate}
                    />
                    <br />
                    <MonthlyMetricsComparisonTable
                        data={OrderComparisonData}
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        compareType={compareType}
                        compareStartDate={compareStartDate}
                        compareEndDate={compareEndDate}
                    />
                </>
            )}
        </PageWrapper>
    );
};

export default PartsDashboard;
