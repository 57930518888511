import { Dispatch, SetStateAction } from 'react';

// LOGIC
import api from '../../../../../api';
import ValidationManualInvoice from './ValidationManualInvoice';
import PrintGeneratedInvoice from './PrintGeneratedInvoice';

// INTERFACES
import {
    Creditor,
    Customer
} from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const SubmitManualInvoice = (
    body: [string, number, number, number, number][],
    text: string,
    creditorData: Creditor,
    customerData: Customer,
    SiteId: number,
    setValidationData: Dispatch<
        SetStateAction<{
            customerOrCreditorError: boolean;
            siteError: boolean;
            descriptionError: boolean;
            quantityError: boolean;
            amountError: boolean;
        }>
    >,
    showSnackbar: showSnackbar
) => {
    // Validation
    const [validationData, errorMessage] = ValidationManualInvoice(
        body,
        creditorData,
        customerData,
        SiteId
    );

    if (errorMessage.length > 0) {
        setValidationData(validationData);
        showSnackbar(
            'There is some problem with the entered data.',
            errorMessage,
            'error'
        );
        return;
    }

    let requestObject = {
        creditorData: creditorData,
        customerData: customerData,
        additionalText: text,
        invoiceLines: body,
        SiteId: SiteId
    };

    api.post('manualInvoice', requestObject).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Manual Invoice successfully generated.',
                'A PDF version of the invoice should pop-up shortly.'
            );
            PrintGeneratedInvoice(
                creditorData,
                customerData,
                body,
                text,
                SiteId,
                res.data.id
            );
        } else {
            showSnackbar(
                "Manual Invoice couldn't be generated.",
                "Please check your data, refresh the page and try again. If it still doesn't work, contact the Ora team.",
                'error'
            );
        }
    });
};

export default SubmitManualInvoice;
