import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';

// INTERFACES
import { GeneralJournalLineTemp, Part } from '../GeneralJournalUI';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleSubmit = (
    setParts: Dispatch<SetStateAction<Part[]>>,
    entryLines: GeneralJournalLineTemp[],
    setEntryLines: Dispatch<SetStateAction<GeneralJournalLineTemp[]>>,
    parts: Part[],
    reference: string,
    showSnackbar: showSnackbar
) => {
    // Check that there is a description for each entry
    let lines = entryLines.map((line) => {
        line.description === ''
            ? (line.descriptionError = true)
            : (line.descriptionError = false);
        return line;
    });
    setEntryLines(lines);
    let noDescription = entryLines.some((line) => line.descriptionError);
    let noDebit = entryLines.some((line) => line.debitError);
    let noCredit = entryLines.some((line) => line.creditError);

    // If we didn't find any error, proceed
    if (!noDescription && !noDebit && !noCredit) {
        let debitAmount = 0;
        let creditAmount = 0;
        entryLines.forEach((entry) => {
            if (entry.debit > 0) {
                debitAmount += entry.debit;
            } else {
                creditAmount += entry.credit;
            }
        });

        // Round the debit amount and credit amount
        debitAmount = Math.round((debitAmount + Number.EPSILON) * 100) / 100;
        creditAmount = Math.round((creditAmount + Number.EPSILON) * 100) / 100;

        if (debitAmount !== creditAmount) {
            showSnackbar(
                'Debit and Credit do not balance. Please check your data and try again.',
                '',
                'error'
            );
            return;
        } else {
            api.post(`accountTransactionNextGen?handleBalanceSheet=${true}`, {
                transactionLines: entryLines,
                parts: parts,
                SiteId: parseInt(localStorage.getItem('SiteId')),
                reference: reference
            }).then((res) => {
                if (res.status === 200) {
                    showSnackbar('General Accounting Entry Successful.');
                    setEntryLines([]);
                    setParts([]);
                }
            });
        }
    }
};

export default HandleSubmit;
