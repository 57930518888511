import { CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// COMPONENTS
import Paper from '../../../global/Paper';
import Counters from './Counters';
import ViewOrders from './ViewOrders';

// LOGIC
import GetAllPendingDispatchedOrders from '../../logic/PendingAndDispatched/GetAllPendingDispatchedOrders';

// INTERFACES
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
interface GroupedOnlineOrder {
  oraOrder: Order;
  netoOrder: OnlineOrder;
}
interface GroupedOnlineOrders {
  pending: GroupedOnlineOrder[];
  dispatched: GroupedOnlineOrder[];
}
const PendingAndDispatched = () => {
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [selectedGroupOrders, setSelectedGroupOrders] = useState<
    GroupedOnlineOrder[]
  >([]);
  const [selectedGroupTitle, setSelectedGroupTitle] =
    useState<string>('Pending');
  const [pendingDispatchedOrders, setPendingDispatchedOrders] =
    useState<GroupedOnlineOrders>({
      pending: [],
      dispatched: []
    });

  useEffect(() => {
    GetAllPendingDispatchedOrders(
      setPendingDispatchedOrders,
      setSelectedGroupOrders,
      setPageLoading
    );
  }, []);

  return (
    <>
      {pageLoading ? (
        <Paper>
          <Typography textAlign="center">
            <CircularProgress />
          </Typography>
        </Paper>
      ) : (
        <>
          <Counters
            pendingDispatchedOrders={pendingDispatchedOrders}
            setSelectedGroupOrders={setSelectedGroupOrders}
            selectedGroupTitle={selectedGroupTitle}
            setSelectedGroupTitle={setSelectedGroupTitle}
          />
          <br />
          <ViewOrders
            selectedGroupOrders={selectedGroupOrders}
            setSelectedGroupOrders={setSelectedGroupOrders}
            selectedGroupTitle={selectedGroupTitle}
            pendingDispatchedOrders={pendingDispatchedOrders}
            setPendingDispatchedOrders={setPendingDispatchedOrders}
          />
        </>
      )}
    </>
  );
};

export default PendingAndDispatched;
