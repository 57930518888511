// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { PictureAsPdf, PointOfSale, Refresh } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import TransactionsDrawerContent from './TransactionsDrawerContent';
import TillSelector from '../TillSelector';
import Drawer from '../../../../global/Drawer';
// LOGIC
import GetAllSiteTills from '../../../../global/databaseLogic/GetAllSiteTills';
import PDFTillReconciliationReport from '../../logic/PDFTillReconciliationReport';
// INTERFACES
import { Till } from '../../../../global/interfaces/GeneralInterface';
import { TillReconciliation } from '../../../../global/interfaces/AdminInterface';
import { DateFormatter } from '../../../../global/logic/Formatters';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import GetTillTransactions from '../../logic/GetTillTransactions';

interface HeaderProps {
    tillReconciliation: TillReconciliation;
    setTillReconciliation: Dispatch<SetStateAction<TillReconciliation>>;
    selectedTill: number;
    setSelectedTill: Dispatch<SetStateAction<number>>;
    showSnackbar: showSnackbar;
}
const Header = ({
    tillReconciliation,
    setTillReconciliation,
    selectedTill,
    setSelectedTill,
    showSnackbar
}: HeaderProps) => {
    const [tills, setTills] = useState<Till[]>([]);
    const [transactionsDrawerOpen, setTransactionDrawerOpen] =
        useState<boolean>(false);

    useEffect(() => {
        GetAllSiteTills(parseInt(localStorage.getItem('SiteId')), setTills);
    }, []);

    useEffect(() => {
        setTillReconciliation({ ...tillReconciliation, TillId: tills[0]?.id });
        // eslint-disable-next-line
    }, [tills]);

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={6} textAlign="center">
                        <TillSelector
                            selectedTillId={selectedTill}
                            setSelectedTillId={setSelectedTill}
                            selectedDate={null}
                        />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2} textAlign="right">
                        <IconButton
                            onClick={() =>
                                GetTillTransactions(
                                    selectedTill,
                                    tillReconciliation,
                                    setTillReconciliation
                                )
                            }
                        >
                            <Refresh />
                        </IconButton>
                        <Tooltip title="Export PDF">
                            <IconButton
                                onClick={() =>
                                    PDFTillReconciliationReport(
                                        parseInt(
                                            localStorage.getItem('SiteId')
                                        ),
                                        showSnackbar
                                    )
                                }
                            >
                                <PictureAsPdf />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Transactions">
                            <IconButton
                                onClick={() => setTransactionDrawerOpen(true)}
                            >
                                <PointOfSale />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>

                <Drawer
                    openDrawer={transactionsDrawerOpen}
                    setOpenDrawer={setTransactionDrawerOpen}
                    title="Transactions"
                    subTitle={DateFormatter(String(new Date(Date.now())))}
                >
                    <TransactionsDrawerContent
                        tillReconciliation={tillReconciliation}
                    />
                </Drawer>
            </Paper>
        </>
    );
};

export default Header;
