// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TableRow } from '@mui/material';
import moment from 'moment';
// COMPONENTS
import Fab from '../Fab';
import DataTable from '../tableComponents/DataTable';
import DataCell from '../tableComponents/DataCell';
import RemoveLine from './paymentTableComponents/RemoveLine';
import PaymentMethod from './paymentTableComponents/PaymentMethod';
import ReceivedAmount from './paymentTableComponents/ReceivedAmount';
import Till from './paymentTableComponents/Till';
import PaymentDate from './paymentTableComponents/PaymentDate';
// LOGIC
import GetAllTills from '../databaseLogic/GetAllTilles';
// INTERFACES
import { Customer, PaymentLine, User } from '../interfaces/GeneralInterface';
import { Order } from '../interfaces/PartsInterface';
import { showSnackbar } from '../interfaces/GlobalInterface';
import { VehicleSale } from '../interfaces/VehicleSaleInterface';
import { Service } from '../interfaces/ServiceInterface';

interface PaymentTableProps {
    multipleCustomers?: boolean;
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    payingCustomer: Customer;
    currentUser: User;
    SubmitPayment?: () => void;
    showSubmitButton?: boolean;
    disabled?: boolean;
    isFinalised?: boolean;
    showSnackbar: showSnackbar;
    handleAddPayment?: (paymentAmount: string, paymentType?: string) => any;
    saleDetails?: Order;
    oldSale?: Order;
    vehicleSaleDetails?: VehicleSale;
    serviceDetails?: Service;
}

const PaymentTable = ({
    vehicleSaleDetails,
    serviceDetails,
    multipleCustomers,
    paymentLines,
    setPaymentLines,
    saleDetails,
    oldSale,
    payingCustomer,
    currentUser,
    SubmitPayment,
    showSubmitButton,
    disabled,
    isFinalised,
    showSnackbar,
    handleAddPayment
}: PaymentTableProps) => {
    const [tills, setTills] = useState([]);
    const [loading, setLoading] = useState(true);

    // Once all the tills are retrieved complete the loading
    useEffect(() => {
        if (tills.length > 0) {
            setLoading(false);
        }
    }, [tills]);

    // Get the payment methods
    useEffect(() => {
        GetAllTills(setTills);
    }, []);

    var columns = [];

    if (multipleCustomers) {
        columns = [
            { id: 0, label: 'Customer', width: 100 },
            { id: 1, label: 'Method', width: 200 },
            { id: 2, label: 'Received', width: 100 },
            { id: 5, label: 'Till', width: 150 },
            { id: 6, label: 'Date', width: 300 },
            { id: 7, label: ' ', width: 50 }
        ];
    } else {
        columns = [
            { id: 0, label: 'Method', width: 200 },
            { id: 1, label: 'Received', width: 150 },
            { id: 4, label: 'Till', width: 200 },
            { id: 5, label: 'Date', width: 220 },
            { id: 6, label: ' ', width: 100 }
        ];
    }

    return (
        <>
            {!loading ? (
                <DataTable columns={columns} cypressLabel="paymentDataTable">
                    {paymentLines.map((payment, index) => (
                        <TableRow data-cy={'paymentDataTableRow_' + index}>
                            {multipleCustomers ? (
                                <DataCell>
                                    {payment.payingCustomerName}
                                </DataCell>
                            ) : null}

                            <PaymentMethod
                                paymentLines={paymentLines}
                                setPaymentLines={setPaymentLines}
                                saleDetails={saleDetails}
                                vehicleSaleDetails={vehicleSaleDetails}
                                serviceDetails={serviceDetails}
                                payment={payment}
                                showSnackbar={showSnackbar}
                                payingCustomer={payingCustomer}
                                handleAddPayment={handleAddPayment}
                                disabled={
                                    (moment(payment.timestamp).isAfter(
                                        moment('04/01/2024', 'DD/MM/YYYY')
                                    ) &&
                                        payment.tillReconciled) ||
                                    disabled ||
                                    isFinalised
                                }
                            />

                            <ReceivedAmount payment={payment} />

                            <Till
                                payment={payment}
                                paymentLines={paymentLines}
                                setPaymentLines={setPaymentLines}
                                saleDetails={saleDetails}
                                vehicleSaleDetails={vehicleSaleDetails}
                                serviceDetails={serviceDetails}
                                showSnackbar={showSnackbar}
                                tills={tills}
                                disabled={
                                    (moment(payment.timestamp).isAfter(
                                        moment('04/01/2024', 'DD/MM/YYYY')
                                    ) &&
                                        payment.tillReconciled) ||
                                    disabled ||
                                    isFinalised
                                }
                            />

                            <PaymentDate
                                payment={payment}
                                paymentLines={paymentLines}
                                setPaymentLines={setPaymentLines}
                                saleDetails={saleDetails}
                                oldSale={oldSale}
                                vehicleSaleDetails={vehicleSaleDetails}
                                serviceDetails={serviceDetails}
                                showSnackbar={showSnackbar}
                                disabled={
                                    (moment(payment.timestamp).isAfter(
                                        moment('04/01/2024', 'DD/MM/YYYY')
                                    ) &&
                                        payment.tillReconciled) ||
                                    disabled ||
                                    isFinalised
                                }
                            />

                            <RemoveLine
                                payment={payment}
                                disabled={
                                    (moment(payment.timestamp).isAfter(
                                        moment('04/01/2024', 'DD/MM/YYYY')
                                    ) &&
                                        payment.tillReconciled) ||
                                    disabled
                                }
                                showTillReconciled={
                                    moment(payment.timestamp).isAfter(
                                        moment('04/01/2024', 'DD/MM/YYYY')
                                    ) && payment.tillReconciled
                                }
                                paymentLines={paymentLines}
                                setPaymentLines={setPaymentLines}
                                currentUser={currentUser}
                                saleDetails={saleDetails}
                                vehicleSaleDetails={vehicleSaleDetails}
                                serviceDetails={serviceDetails}
                                showSnackbar={showSnackbar}
                            />
                        </TableRow>
                    ))}
                </DataTable>
            ) : null}

            {showSubmitButton ? (
                <Fab editIcon={false} onClick={() => SubmitPayment()} />
            ) : null}
        </>
    );
};

export default PaymentTable;
