// IMPORTS
import { useState } from 'react';
import { Typography, TableRow, TableCell } from '@mui/material';
import { Add, FileDownload } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
// LOGIC
import HandleDownloadResults from '../../global/logic/HandleDownloadResults';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { ServiceCode } from '../../global/interfaces/ServiceInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {};
const clearedSort = ['id', 'DESC'];

const ServiceCodeTable = () => {
    const history = useHistory();
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<ServiceCode[]>([]);

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Code',
            onClick: () => history.push('/service/codes/add')
        },
        {
            icon: <FileDownload />,
            name: 'Export Current Results',
            onClick: () => handleDownloadResults()
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'Name', width: 200 },
        { id: 2, label: 'Standard Labour Charge', width: 100 },
        { id: 3, label: 'Standard Duration (hour)', width: 100 },
        { id: 4, label: 'Job Description', width: 300 },
        { id: 5, label: 'Invoice Description', width: 300 }
    ];

    // Handle downloading the csv
    const handleDownloadResults = () => {
        HandleDownloadResults(
            sort,
            'serviceCodeSearchNextGen',
            handleRequestCreate(),
            'OraServiceCodeResults.csv',
            [
                'ID',
                'Code',
                'Standard Labour Charge',
                'Job Card Description',
                'Invoice Description'
            ],
            [
                'id',
                'serviceCode',
                'servicePrice',
                'jobCardDescription',
                'invoiceDescription'
            ]
        );
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        return '';
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        return false;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Service Codes</Typography>
            <br />
            <TableSearch
                searchTitle="Search Service Code"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="serviceCodeSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            ></TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() =>
                                    history.push(
                                        `/service/codes/view/${row.id}`
                                    )
                                }
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>{row.serviceCode}</DataCell>
                            <DataCell>{row.servicePrice}</DataCell>
                            <DataCell>
                                {row.serviceDuration
                                    ? `${row.serviceDuration > 0 ? Math.floor(row.serviceDuration).toString() + ' hour(s)' : ''} ${row.serviceDuration > 0 ? ((row.serviceDuration * 60) % 60) + ' minute(s)' : ''}`
                                    : ''}
                            </DataCell>
                            {/* Using standard table cell so that the descriptions are left aligned */}
                            <TableCell>{row.jobCardDescription}</TableCell>
                            <TableCell>{row.invoiceDescription}</TableCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </PageWrapper>
    );
};

export default ServiceCodeTable;
