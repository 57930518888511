// IMPORTS
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
// LOGIC
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
import { Voucher } from '../../../global/interfaces/PartsInterface';
import { Customer } from '../../../global/interfaces/GeneralInterface';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import api from '../../../../../api';

const PDFVoucherInvoice = async (VoucherId: number) => {
    let voucher: Voucher;
    let customer: Customer;
    let showCustomerDetails = true;

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Get the voucher details
    await api.get(`/voucher/${VoucherId}`).then((res) => {
        voucher = res.data;

        // Store the customer in one field so we don't need to constantly check where the customer details are coming from
        if (voucher.CustomerId) {
            customer = voucher.Customer;
        } else if (voucher.OrderId) {
            customer = voucher.Order.Customer;
        } else if (voucher.VehicleSaleId) {
            customer = voucher.VehicleSale.Customer;
        } else {
            showCustomerDetails = false;
        }
    });

    // Header for the invoice
    await InvoiceHeader(
        doc,
        parseInt(localStorage.getItem('SiteId')),
        'Voucher Invoice',
        `Voucher #${VoucherId}`,
        showCustomerDetails,
        true,
        false,
        customer,
        voucher.User,
        voucher.createdAt
    );

    // Table continaing the voucher lines
    let header = [['Quantity', 'Product', 'Code', 'Total Price', 'Balance']];
    let body = [
        [
            '1',
            'Voucher',
            voucher.voucherCode,
            CurrencyFormatter(voucher.totalPrice),
            CurrencyFormatter(voucher.currentBalance)
        ]
    ];

    let lastYcoordinates = 82;

    doc.setFont('helvetica', 'bold').setFontSize(12);
    doc.text('Gift Voucher', 17, lastYcoordinates + 13);

    InvoiceTable(doc, lastYcoordinates + 18, header, body);

    // Table containing the payment lines
    if (voucher.paymentLines.length > 0) {
        let paymentHeader = [['Payment Method', 'Payment Date', 'Amount']];
        let paymentBody = [];

        voucher.paymentLines.forEach((payment) => {
            if (payment.removed === true) return;
            paymentBody.push([
                payment.type.toUpperCase(),
                DateFormatter(payment.date.toString()),
                payment.amountUsed
                    ? CurrencyFormatter(payment.amountUsed)
                    : CurrencyFormatter(payment.amount)
            ]);
        });

        lastYcoordinates = (doc as any).lastAutoTable.finalY;

        doc.setFont('helvetica', 'bold').setFontSize(12);
        doc.text('Payments', 17, lastYcoordinates + 8);

        InvoiceTable(doc, lastYcoordinates + 13, paymentHeader, paymentBody);
    }

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFVoucherInvoice;
