// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleDefaultBinLocation = (
    BinLocationId: number,
    showSnackbar: showSnackbar,
    setSort: Dispatch<SetStateAction<string[]>>,
    setDefaultDialogOpen: Dispatch<SetStateAction<boolean>>,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.put(
        `defaultBinLocation/${BinLocationId}?SiteId=${localStorage.getItem('SiteId')}`
    ).then((res) => {
        if (res.status === 200) {
            showSnackbar('Bin Location was successfully made the new default.');
            setSort(['id', 'DESC']);
            setDefaultDialogOpen(false);
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Whoops! It looks like something went wrong on our end.',
                'Please contact you IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default HandleDefaultBinLocation;
