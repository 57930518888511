// IMPORTS
import { Grid, MenuItem, TextField } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
// COMPONENTS
import TillSelector from '../../../global/paymentComponents/TillSelector';
// INTERFACES
import { Settings } from '../../../global/interfaces/GeneralInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
// LOGIC
import { DateFormatter } from '../../../global/logic/Formatters';
import Paper from '../../../global/Paper';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        quantityReturned?: number; // used in crediting order
        backorderQuantityReturned?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        amountRefunded?: number; // used in crediting order
        // USED ONLY HERE
        refundableQuantity?: number;
        refundableBackorderQuantity?: number;
        quantityReturning?: number;
        backorderQuantityReturning?: number;
        amountRefunding?: number;
    }[];
}

interface CreditProps {
    saleDetails: TempOrder;
    settings: Settings;
    selectedTill: number;
    returnMethod: string;
    returnReason: string;
    setReturnMethod: Dispatch<SetStateAction<string>>;
    setReturnReason: Dispatch<SetStateAction<string>>;
    setSelectedTill: Dispatch<SetStateAction<number>>;
}

/**
 * CreditDetails
 * Show important credit details
 * @author Estienne
 * @param CreditProps
 * @returns {JSX} the till, refund method, date and reason for credit
 */
const CreditDetails = ({
    saleDetails,
    settings,
    selectedTill,
    returnMethod,
    returnReason,
    setReturnMethod,
    setReturnReason,
    setSelectedTill
}: CreditProps) => {
    return (
        <Paper>
            {saleDetails.ServiceId ? (
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Reason for return"
                            value={returnReason}
                            onChange={(e) => setReturnReason(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            label="Date Created"
                            value={DateFormatter(saleDetails.createdAt)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <TillSelector
                            selectedTillId={selectedTill}
                            setSelectedTillId={setSelectedTill}
                            department="parts"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={5} justifyContent={'center'}>
                        <TextField
                            fullWidth
                            select
                            disabled={returnMethod === ''}
                            size="small"
                            label="Refund Method"
                            value={returnMethod}
                            onChange={(e) => setReturnMethod(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        >
                            {settings?.paymentMethods?.map((payment) =>
                                payment.value === 'account' ? null : (
                                    <MenuItem value={payment.value}>
                                        {payment.name}
                                    </MenuItem>
                                )
                            )}
                            {saleDetails?.Customer?.debtor ? (
                                <MenuItem value={'account'}>ACCOUNT</MenuItem>
                            ) : null}
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            label="Date Created"
                            value={DateFormatter(saleDetails.createdAt)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            data-cy="reasonForReturnTextField"
                            size="small"
                            label="Reason for return"
                            value={returnReason}
                            onChange={(e) => setReturnReason(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
};

export default CreditDetails;
