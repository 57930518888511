import { Box, Divider, TableRow, Typography } from '@mui/material';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../../../global/logic/Formatters';
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCell from '../../../../../global/tableComponents/DataCell';
import dayjs from 'dayjs';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';

export const PaymentLiabilityDrawerContent = ({
    selectedSite,
    queryDate,
    paymentLiabilityData
}) => {
    const columns =
        typeof selectedSite === 'string'
            ? [
                  { id: 0, label: 'Entity ID', width: 200 },
                  { id: 1, label: 'Date Created' },
                  { id: 2, label: 'Type' },
                  { id: 3, label: 'Site' },
                  { id: 4, label: 'Liability' }
              ]
            : [
                  { id: 0, label: 'Entity ID', width: 200 },
                  { id: 1, label: 'Date Created' },
                  { id: 2, label: 'Type' },
                  { id: 3, label: 'Liability' }
              ];

    const getLink = (entity) => {
        if (entity.status) {
            return `/inventory/viewSale/${entity.id}`;
        } else if (entity.jobType) {
            return `/service/viewBooking/${entity.id}`;
        } else {
            return `/vehicles/viewContract/${entity.id}`;
        }
    };

    return (
        <Box sx={{ width: '40vw', padding: '20px' }}>
            <Typography variant="h4">
                {typeof selectedSite === 'string'
                    ? selectedSite
                    : selectedSite.name}{' '}
                - {queryDate.format('DD/MM/YYYY')}
            </Typography>
            <Typography>Payment Liability Breakdown</Typography>
            <Box height={'10px'}></Box>
            <Divider></Divider>
            <Box height="20px"></Box>
            <DataTable columns={columns}>
                {typeof selectedSite === 'string'
                    ? paymentLiabilityData?.map((site) => {
                          return site?.map((entity) => (
                              <TableRow>
                                  <DataCellColoured
                                      handleClick={() =>
                                          window.open(getLink(entity), '_blank')
                                      }
                                  >
                                      {entity.id}
                                  </DataCellColoured>
                                  <DataCell>
                                      {DateFormatter(entity.createdAt)}
                                  </DataCell>
                                  <DataCell>
                                      {entity.status
                                          ? 'P&A Order'
                                          : entity.jobType
                                            ? 'Service'
                                            : 'Vehicle Sale'}
                                  </DataCell>
                                  <DataCell>{entity.Site.name}</DataCell>
                                  <DataCell>
                                      {CurrencyFormatter(
                                          entity.paymentLines
                                              .filter((x) =>
                                                  dayjs(x.date).isBefore(
                                                      dayjs(queryDate)
                                                  )
                                              )
                                              .reduce(
                                                  (acc, x) => acc + x.amount,
                                                  0
                                              )
                                      )}
                                  </DataCell>
                              </TableRow>
                          ));
                      })
                    : paymentLiabilityData?.map((entity) => (
                          <TableRow>
                              <DataCellColoured
                                  handleClick={() =>
                                      window.open(getLink(entity), '_blank')
                                  }
                              >
                                  {entity.id}
                              </DataCellColoured>
                              <DataCell>
                                  {DateFormatter(entity.createdAt)}
                              </DataCell>
                              <DataCell>
                                  {entity.status
                                      ? 'P&A Order'
                                      : entity.jobType
                                        ? 'Service'
                                        : 'Vehicle Sale'}
                              </DataCell>
                              <DataCell>
                                  {CurrencyFormatter(
                                      entity.paymentLines
                                          .filter((x) =>
                                              dayjs(x.date).isBefore(
                                                  dayjs(queryDate)
                                              )
                                          )
                                          .reduce((acc, x) => acc + x.amount, 0)
                                  )}
                              </DataCell>
                          </TableRow>
                      ))}
            </DataTable>
        </Box>
    );
};
