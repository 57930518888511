// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Site } from '../interfaces/GeneralInterface';

/*  GetAllSites
    Gets all the sites from the server and sets it into the given react state
    params:
        - setSites: the state you wish to save the sites array to
    returns:
        - all the sites from the database as a json object
*/
const GetAllOnlineSites = (setSites: Dispatch<SetStateAction<Site[]>>) => {
  api.get('allOnlineSites').then((res) => {
    setSites(res.data);
  });
};

export default GetAllOnlineSites;
