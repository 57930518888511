import { Paper } from '@material-ui/core';
import { Typography } from '@mui/material';
import { useEffect } from 'react';

interface SignatureDisplayProps {
    name: string;
    setTempSignatureDataUrl: React.Dispatch<React.SetStateAction<string>>;
}

const SelectSignature = ({
    name,
    setTempSignatureDataUrl
}: SignatureDisplayProps) => {
    const selectedFont = '"Great Vibes", cursive';
    //initial font size
    let fontSize = 50;

    // generate sig data url from text amd selected font
    const generateSignatureDataUrl = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (context) {
            // Initial canvas size
            canvas.width = 500;
            canvas.height = 150;

            let fontSize = Math.floor(canvas.height / 2); // Initial font size
            context.font = `${fontSize}px ${selectedFont}`;

            // Measure text width
            let metrics = context.measureText(name);

            // Adjust font size to fit the width
            while (metrics.width > canvas.width - 20 && fontSize > 1) {
                fontSize--;
                context.font = `${fontSize}px ${selectedFont}`;
                metrics = context.measureText(name);
            }

            // Clear canvas before drawing
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = '#000';

            // Draw text at the bottom-left corner
            const textX = 0; // Left
            const textY = (canvas.height + fontSize) / 2; // Vertical center

            context.fillText(name, textX, textY);

            // Return data URL
            return canvas.toDataURL();
        }
    };

    useEffect(() => {
        const dataUrl = generateSignatureDataUrl();
        setTempSignatureDataUrl(dataUrl);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {/* <Button>Change Style</Button> */}
            <Paper elevation={3} style={{ padding: 30 }}>
                <Typography
                    variant="h5"
                    style={{
                        fontFamily: selectedFont,
                        fontSize: `${fontSize}px`
                    }}
                >
                    {name}
                </Typography>
            </Paper>
            <Typography variant="caption">
                By selecting Add, I confirm that this is the electronic version
                of my signature.
            </Typography>
        </>
    );
};

export default SelectSignature;
