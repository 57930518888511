import DocumentationPage from '../../DocumentationPage';
import {
    UploadTransactionFileSteps,
    ReconciliatorSteps,
    ReconciliationHistoryteps
} from '../../data/admin/bankReconciliation';

const BankReconciliationSupportPage = () => {
    const contentSections = [
        {
            id: 'upload-transaction-file',
            title: 'Upload Transaction File',
            href: '/support/admin/bankReconciliation/#upload-transaction-file',
            description:
                'From uploading a bank transaction file to deleting bank transactions or entire transaction files, follow these steps:',
            steps: UploadTransactionFileSteps
        },
        {
            id: 'bank-reconciliator',
            title: 'Bank Reconciliator',
            href: '/support/admin/bankReconciliation/#bank-reconciliator',
            description:
                'After uploading a bank transaction file, follow these steps to reconcile transactions:',
            steps: ReconciliatorSteps
        },
        {
            id: 'reconciliation-history',
            title: 'Reconciliation History',
            href: '/support/admin/bankReconciliation/#reconciliation-history',
            description:
                'View your reconciliation history on each day with these steps:',
            steps: ReconciliationHistoryteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Bank Reconciliation"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default BankReconciliationSupportPage;
