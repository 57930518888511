import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Buffer } from 'buffer';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
const PrintPostageLabel = (
    selectedOrder: GroupedOnlineOrder,
    showSnackbar: showSnackbar,
    setLabelLoading: Dispatch<SetStateAction<boolean>>
) => {
    setLabelLoading(true);
    api.post(`/printProductionPostageLabel`, {
        selectedOrder: selectedOrder,
        SiteId: localStorage.getItem('SiteId'),
        responseType: 'blob'
    }).then(async (res) => {
        // Aus post api returned an error trying to either create shipment or label
        if (res.status === 400) {
            showSnackbar(
                'Aus Post Label Error!',
                res.data.errorMessage,
                'error'
            );
            setLabelLoading(false);
        } else if (res.status === 500) {
            showSnackbar(
                'Whoops! Something went wrong on our end!',
                'Please contact your IT department.',
                'error'
            );
            setLabelLoading(false);
        } else {
            const file = new Blob([Buffer.from(res.data, 'base64')], {
                type: 'application/pdf'
            });
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            let iframe = document.createElement('iframe'); //load content in an iframe to print later
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = fileURL;
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1);
            };
            setLabelLoading(false);
        }
    });
};

export default PrintPostageLabel;
