const renderDealerPlatePdf = async (pdfDocCopy, formData) => {
    const pages = pdfDocCopy.getPages();
    const firstPage = pages[0];

    if (formData.personalisedPlate) {
        firstPage.drawText(formData.personalisedPlate, {
            x: 53,
            y: 508,
            size: 9
        });
    }

    if (formData.reasonOption === 'remove') {
        firstPage.drawText('X', {
            x: 54,
            y: 470,
            size: 9
        });
    }

    if (formData.reasonOption === 'attach') {
        firstPage.drawText('X', {
            x: 54,
            y: 453,
            size: 9
        });
    }

    if (formData.reasonOption === 'replace') {
        firstPage.drawText('X', {
            x: 54,
            y: 436,
            size: 9
        });
    }

    if (formData.registeredOption === 'yes') {
        firstPage.drawText('X', {
            x: 72,
            y: 320,
            size: 9
        });
    }

    if (formData.registeredOption === 'no') {
        firstPage.drawText('X', {
            x: 72,
            y: 303,
            size: 9
        });
    }

    if (formData.cancelOption === 'yes') {
        firstPage.drawText('X', {
            x: 72,
            y: 270,
            size: 9
        });
    }

    if (formData.cancelOption === 'no') {
        firstPage.drawText('X', {
            x: 72,
            y: 253,
            size: 9
        });
    }

    if (formData.noticeOption === 'yes') {
        firstPage.drawText('X', {
            x: 72,
            y: 207,
            size: 9
        });
    }

    if (formData.noticeOption === 'no') {
        firstPage.drawText('X', {
            x: 72,
            y: 190,
            size: 9
        });
    }

    if (formData.differentOwnerOption === 'yes') {
        firstPage.drawText('X', {
            x: 72,
            y: 145,
            size: 9
        });
    }

    if (formData.differentOwnerOption === 'no') {
        firstPage.drawText('X', {
            x: 72,
            y: 128,
            size: 9
        });
    }

    const secondPage = pages[1];
    if (formData.authorityOption === 'attach') {
        secondPage.drawText('X', {
            x: 54,
            y: 751,
            size: 9
        });
    }

    if (formData.authorityOption === 'remove') {
        secondPage.drawText('X', {
            x: 54,
            y: 721,
            size: 9
        });
    }

    if (formData.secondPlateOwnerName) {
        secondPage.drawText(formData.secondPlateOwnerName, {
            x: 35,
            y: 640,
            size: 9
        });
    }

    const pdfBytes = await pdfDocCopy.save();

    return pdfBytes;
};
export default renderDealerPlatePdf;
