import { Step } from '../../interface';

export const CreateBackordersSteps: Step[] = [
    {
        label: 'Select the Sale type as either Proforma or Quote in the Orderlines tab. Note: An order with backorders cannot be a Sale.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/add-backorder-01.png'
    },
    {
        label: "Add the part to the order line. In the B/O (Back Order) column, enter the backorder quantity and select the backorder type as either 'Daily' or 'Stock'. For example, add a backorder quantity of 10 with the 'Daily' backorder type for part 666666.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/add-backorder-02.png'
    },
    {
        label: "After submitting the order, the backorder will be created. You can view the backorder on the backorder page by clicking 'Parts & Accessories' -> 'Backorder' from the sidebar.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/add-backorder-03.png'
    },
    {
        label: "You can find the newly created backorder on the backorder page. Its current status will be 'OPEN' and it will be linked to Proforma #73619.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/add-backorder-04.png'
    },
    {
        label: "You can also view the backorder in the creditor details page. Navigate to 'Admin' -> 'Creditors' -> 'View Creditor' -> 'Backorders'. Its status is 'OPEN' and it is linked to Proforma #73619. Additionally, you can view all backorders for parts from this supplier.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/add-backorder-05.png'
    },
    {
        label: "You can also bulk update backorder statuses. By selecting the backorders, an 'Edit' button will appear once at least one backorder is selected. Clicking the 'Edit' button will open a dialog to bulk update the status of the selected backorders.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/add-backorder-06.png'
    },
    {
        label: (
            <>
                You can cancel a backorder by either: crediting the Proforma (if
                there was any payment), or simply remove the Backorder Quantity
                in the Orderlines of the Proforma. Refer to{' '}
                <a
                    href="/support/inventory/sales/#sales-credit-invoice"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Sales Credit Invoice
                </a>{' '}
                documentation for more information on Crediting.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/add-backorder-07.png'
    }
];

export const OrderBackordersSteps: Step[] = [
    {
        label: "Navigate to 'Stock Control' > 'New Stock Order' via the sidebar to access the New Stock Order page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/order-backorder-01.png'
    },
    {
        label: "Under the 'Customer Backorder' tab, you will see all OPEN backorders that need to be ordered from each supplier. Locate the supplier linked with the backorder you want to order. For example, Brisbane Motorcycles Windsor is selected here.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/order-backorder-02.png'
    },
    {
        label: "Click the 'Move Backorders to Stock Order' button to transfer the all open backorders from the selected supplier to the stock order. You can repeat this step for any Supplier you want to order from",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/order-backorder-03.png'
    },
    {
        label: (
            <>
                Upon returning to the Stock Order tab can see that all the parts
                on backorders have been added to the Stock Order. If you did the
                previous step for more than one Supplier you should see multiple
                table (one per supplier). Ora can create multiple Stock Order at
                once. You can then proceed as you would normally when creating
                Stock Order, for more details: refer to{' '}
                <a
                    href="/support/inventory/stockorder"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Create Stock Order
                </a>{' '}
                .
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/order-backorder-04.png'
    },
    // {
    //     label: "Remove any parts you do not want to order at the moment by clicking the 'X' button.",
    //     imgPath:
    //         'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/order-backorder-05.png'
    // },
    // {
    //     label: "Select the parts you want to order by using the checkbox on the left and adjust the quantity if needed. After reviewing the stock order, click the 'Submit Order With Selected Parts' button to submit the stock order.",
    //     imgPath:
    //         'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/order-backorder-06.png'
    // },
    // {
    //     label: "The stock order is now created. You can view the newly created stock order #9443 on the stock order page by clicking 'Stock Control' -> 'Stock Orders' from the sidebar.",
    //     imgPath:
    //         'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/order-backorder-07.png'
    // },
    {
        label: "After creating the Stock Order, returning to the backorder page will show that the backorders status have changed to 'ORDERED', and they now have a Stock Order ID.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/order-backorder-08.png'
    },
    {
        label: "If you return to the creditor details page, you will see the backorder status has also changed to 'ORDERED'. When you will invoice the stock, Ora will automatically assign the parts to OPEN and ORDERED backorders, and close them as soon as they are filled.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/order-backorder-09.png'
    }
];

export const ReceiveBackordersSteps: Step[] = [
    {
        label: "Navigate to 'Stock Control' > 'Stock Orders' via the sidebar to access the Stock Order page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/receive-order-01.png'
    },
    {
        label: 'Locate the stock order you want to receive. For example, receive the newly created stock order #9443. Click the Order ID to open the Invoice Order page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/receive-order-02.png'
    },
    {
        label: 'In the Invoice Order #9442, enter the quantity you received for each part, document the cost, and calculate the line total.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/receive-order-03.png'
    },
    {
        label: 'To confirm receipt of the stock order, add document details including the reference number, date, total amount, and freight cost. Ensure that the Document Total equals the sum of all line totals plus freight. When the totals match, the Difference will show as $0.00.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/receive-order-04.png'
    },
    {
        label: "After entering all required information, click the 'Submit' button to save the stock order.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/receive-order-05.png'
    },
    {
        label: "If you return to the backorder page, you will see the backorder status has changed to 'CLOSED'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/receive-order-06.png'
    },
    {
        label: "If you go back to the creditor details page, you will also see the backorder status has changed to 'CLOSED'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/backorders/receive-order-07.png'
    }
];
