// IMPORTS
import { TableRow, Typography } from '@mui/material';

// COMPONENTS
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../../global/tableComponents/DataCell';

// LOGIC
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';

interface TrustReceiptContentProps {
    selectedObject;
}

const TrustReceiptContent = ({ selectedObject }: TrustReceiptContentProps) => {
    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Vehicle' },
        { id: 2, label: 'Trust Receipt' },
        { id: 3, label: 'Amount' }
    ];

    return (
        <>
            <Typography variant="body1">
                <b>Document Total:</b>{' '}
                {CurrencyFormatter(selectedObject.amount)}
            </Typography>
            <Typography variant="body1">
                <b>Date Paid:</b> {selectedObject.date}
            </Typography>
            <Typography variant="body1">
                <b>Paid by:</b> {selectedObject.submittedBy}
            </Typography>

            <DataTable columns={columns}>
                {selectedObject.vehicleLines.map((line) => (
                    <TableRow>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(
                                    `/vehicles/view/${line.VehicleId}`,
                                    '_blank'
                                )
                            }
                        >
                            {line.VehicleId}
                        </DataCellColoured>
                        <DataCell>
                            {/* access data from old api --- to be removed*/}
                            {/* {line?.Vehicle?.year} {line?.Vehicle?.Make?.name}{' '}
                            {line?.Vehicle?.Model?.name} */}
                            {/* access data from new api */}
                            {line?.VehicleYear} {line?.MakeName}{' '}
                            {line?.ModelName}
                        </DataCell>
                        {/* access data from old api --- to be removed*/}
                        {/* <DataCell>{line?.Vehicle?.trustReceipt}</DataCell> */}
                        <DataCell>{line?.VehicleTrustReceipt}</DataCell>
                        <DataCell>{CurrencyFormatter(line.amount)}</DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </>
    );
};

export default TrustReceiptContent;
