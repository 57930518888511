import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { TestRide } from '../../../global/interfaces/VehicleSaleInterface';
import dayjs from 'dayjs';

const SubmitTestRideAgreement = (
    leadId: number,
    testRideData: TestRide,
    name: string,
    phoneNumber: string,
    modelOfInterestId: number,
    riderSignature: string,
    witnessSignature: string,
    setTestRideError: Dispatch<
        SetStateAction<{
            nameError: boolean;
            addressError: boolean;
            phoneNumberError: boolean;
            modelIdError: boolean;
            testRideRegoError: boolean;
            driverLicenceNumberError: boolean;
            driverLicenceClassError: boolean;
            testRideDateError: boolean;
            departureTimeError: boolean;
            returnTimeError: boolean;
            riderSignatureError: boolean;
            witnessSignatureError: boolean;
        }>
    >,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    // Validate the details entered in the test ride agreement
    let errorCheck = false;
    let errorObj = {
        nameError: false,
        addressError: false,
        phoneNumberError: false,
        modelIdError: false,
        testRideRegoError: false,
        driverLicenceNumberError: false,
        driverLicenceClassError: false,
        testRideDateError: false,
        departureTimeError: false,
        returnTimeError: false,
        riderSignatureError: false,
        witnessSignatureError: false
    };

    if (!name) {
        errorObj.nameError = true;
        errorCheck = true;
    }
    if (!testRideData.address) {
        errorObj.addressError = true;
        errorCheck = true;
    }
    if (!phoneNumber) {
        errorObj.phoneNumberError = true;
        errorCheck = true;
    }
    if (!modelOfInterestId) {
        errorObj.modelIdError = true;
        errorCheck = true;
    }
    if (!testRideData.testRideRegistration) {
        errorObj.testRideRegoError = true;
        errorCheck = true;
    }
    if (!testRideData.driverLicenceNumber) {
        errorObj.driverLicenceNumberError = true;
        errorCheck = true;
    }
    if (!testRideData.driverLicenceClass) {
        errorObj.driverLicenceClassError = true;
        errorCheck = true;
    }
    if (!testRideData.date) {
        errorObj.testRideDateError = true;
        errorCheck = true;
    }
    if (!testRideData.departureTime) {
        errorObj.departureTimeError = true;
        errorCheck = true;
    }
    if (!testRideData.returnTime) {
        errorObj.returnTimeError = true;
        errorCheck = true;
    }
    if (!riderSignature) {
        errorObj.riderSignatureError = true;
        errorCheck = true;
    }
    if (!witnessSignature) {
        errorObj.witnessSignatureError = true;
        errorCheck = true;
    }

    if (errorCheck) {
        setTestRideError(errorObj);
        setButtonLoading(false);
        return;
    } else {
        setTestRideError(errorObj);
        let departureTime = dayjs(testRideData.departureTime).format('HH:mm');
        let returnTime = dayjs(testRideData.returnTime).format('HH:mm');
        var queryObj = {
            leadId: leadId,
            name: name,
            testRideData: testRideData,
            phoneNumber: phoneNumber,
            departureTime: departureTime,
            returnTime: returnTime,
            modelOfInterestId: modelOfInterestId,
            riderSignature: riderSignature,
            witnessSignature: witnessSignature
        };

        api.post('testRideNextGen', queryObj).then((res) => {
            if (res.status === 200) {
                window.location.reload();
            } else {
                setButtonLoading(false);
            }
        });
    }
};

export default SubmitTestRideAgreement;
