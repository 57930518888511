import jsPDF from 'jspdf';
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import {
    Creditor,
    Customer
} from '../../../global/interfaces/GeneralInterface';

const PrintGeneratedInvoice = async (
    creditorData: Creditor,
    customerData: Customer,
    body,
    text: string,
    SiteId: number,
    selectedInvoiceId?: number
) => {
    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Header for the invoice
    await InvoiceHeader(
        doc,
        SiteId,
        `Tax Invoice${selectedInvoiceId ? ' M' + selectedInvoiceId.toString() : ''}`,
        ``,
        false,
        true,
        true,
        null,
        null,
        String(new Date(Date.now()))
    );

    let lastYCoordinates = 40;

    if (creditorData?.id) {
        doc.setFont('helvetica', 'bold');
        doc.text(creditorData.name, 13, lastYCoordinates + 5);
        doc.setFont('helvetica', 'normal');
        doc.text(`ABN: ${creditorData.abn}`, 13, lastYCoordinates + 10);
        let addressLine1 = `${creditorData.addressLine1 ?? ''}`;
        let area = `${creditorData.suburb ?? ''} ${creditorData.postcode ?? ''} ${creditorData.state ?? ''}`;
        doc.text(addressLine1, 13, lastYCoordinates + 15);
        doc.text(area, 13, lastYCoordinates + 20);
        if (creditorData.phoneNumber) {
            doc.text(
                `Phone: ${creditorData.phoneNumber}`,
                13,
                lastYCoordinates + 25
            );
        }
    } else if (customerData?.id) {
        doc.setFont('helvetica', 'bold');
        doc.text(
            customerData.firstName + ' ' + customerData.lastName,
            13,
            lastYCoordinates + 5
        );
        doc.setFont('helvetica', 'normal');
        let addressLine1 = `${customerData.addressLine1 ?? ''}`;
        let area = `${customerData.suburb ?? ''} ${customerData.postcode ?? ''} ${customerData.state ?? ''}`;
        doc.text(addressLine1, 13, lastYCoordinates + 10);
        doc.text(area, 13, lastYCoordinates + 15);
        if (customerData.phoneNumber) {
            doc.text(
                `Phone: ${customerData.phoneNumber}`,
                13,
                lastYCoordinates + 20
            );
        }
    }

    lastYCoordinates = 82;

    let newBody = [];
    let totalAmount = 0;
    let totalGST = 0;
    let total = 0;
    // Format body with currency formatter and calculate totals
    for (let line of body) {
        let newLine = [];
        for (const [index, item] of line.entries()) {
            if (typeof item === 'string') {
                newLine.push(item);
            } else {
                if (index === 1) {
                    newLine.push(item);
                }
                if (index === 2) {
                    newLine.push(CurrencyFormatter(item));
                    totalAmount += item;
                } else if (index === 3) {
                    newLine.push(CurrencyFormatter(item));
                    totalGST += item;
                } else if (index === 4) {
                    newLine.push(CurrencyFormatter(item));
                    total += item;
                }
            }
        }
        newBody.push(newLine);
    }
    newBody.push([
        '',
        'Total:',
        CurrencyFormatter(totalAmount),
        CurrencyFormatter(totalGST),
        CurrencyFormatter(total)
    ]);

    let tableHeaders = [
        [
            'Description',
            'Quantity',
            'Amount (Ex. GST)',
            'GST',
            'Total (Inc. GST)'
        ]
    ];
    autoTable(doc, {
        head: tableHeaders,
        body: newBody,
        startY: lastYCoordinates + 15,
        theme: 'grid',
        headStyles: {
            fillColor: [19, 65, 123],
            textColor: 'white',
            lineColor: [19, 65, 123]
        },
        margin: { bottom: 20 },
        styles: { lineColor: [201, 201, 201], lineWidth: 0.1 },
        willDrawCell: function (data) {
            var rows = data.table.body;
            if (data.row.index === rows.length - 1) {
                doc.setFont('helvetica', 'bold');
            }
        }
    });

    lastYCoordinates = (doc as any).lastAutoTable.finalY;

    let split = doc.splitTextToSize(text, 165);
    // doc.setFont("helvetica", "normal").setFontSize(10);
    doc.text(split, 15, lastYCoordinates + 10);

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PrintGeneratedInvoice;
