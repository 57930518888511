import api from '../../../../../api';

const updateTradeInStatementStatus = async (
    tradeInId: number,
    status: string | null
) => {
    const res = await api.put(`updateTradeInStatementStatus`, {
        tradeInId,
        status
    });

    return res;
};

export default updateTradeInStatementStatus;
