import { DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

// COMPONENT
import GetMakes from '../../../../global/databaseLogic/GetMakes';
import Autocomplete from '../../../../global/Autocomplete';

// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Make } from '../../../../global/interfaces/VehicleSaleInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import CreateModel from '../../logic/CreateModel';

const AddModelDialogContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [makeList, setMakeList] = useState<Make[]>([]);
    const [selectedMake, setSelectedMake] = useState<Make>();
    const [name, setName] = useState<string>('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        GetMakes(setMakeList);
    }, []);

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            size="small"
                            options={makeList}
                            useTwoOptionLabels={true}
                            primaryOptionLabel={'id'}
                            secondaryOptionLabel={'name'}
                            textfieldLabel="Make"
                            selectedValue={selectedMake}
                            handleSelectedValueChange={(event) => {
                                setSelectedMake(event);
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            size="small"
                            label="New Model Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={buttonLoading}
                    variant="contained"
                    disabled={!selectedMake || name.length === 0}
                    onClick={() =>
                        CreateModel(
                            selectedMake,
                            name,
                            setButtonLoading,
                            showSnackbar
                        )
                    }
                >
                    Submit New Model
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default AddModelDialogContent;
