// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
  Collapse,
  Divider,
  IconButton,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Add, Close, ExpandLess, ExpandMore } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
import DataCell from '../../../global/tableComponents/DataCell';
import DataTable from '../../../global/tableComponents/DataTable';
// INTERFACES
import { Settings } from '../../../global/interfaces/GeneralInterface';

interface SettingsProps {
  settings: Settings; // The company settings
  setSettings: Dispatch<SetStateAction<Settings>>;
  disabled: boolean;
}

const Departments = ({ settings, setSettings, disabled }: SettingsProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const AddDepartment = () => {
    let tempDepartments = [...settings.departments];
    let id =
      tempDepartments.length > 0
        ? tempDepartments[tempDepartments.length - 1].id + 1
        : 1;
    tempDepartments.push({ id: id, name: null });
    setSettings({ ...settings, departments: tempDepartments });
  };

  const EditDepartment = (newValue: string, departmentId: number) => {
    let tempDepartments = [...settings.departments];
    let index = tempDepartments.findIndex((x) => x.id === departmentId);
    let method = { ...tempDepartments[index] };
    method.name = newValue.toUpperCase();

    tempDepartments[index] = method;
    setSettings({ ...settings, departments: tempDepartments });
  };

  const RemoveDepartment = (departmentId: number) => {
    let tempDepartment = [...settings.departments];
    tempDepartment = tempDepartment.filter((item) => item.id !== departmentId);
    setSettings({ ...settings, departments: tempDepartment });
  };

  return (
    <Paper textAlign="center">
      <Typography variant="h6" align="left">
        Departments
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Typography>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <DataTable columns={[]}>
          {settings?.departments.map((department) => (
            <TableRow>
              <DataCell width="100%">
                {disabled ? (
                  department.name
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={department.name}
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    onChange={(e) =>
                      EditDepartment(e.target.value, department.id)
                    }
                  />
                )}
              </DataCell>
              {disabled ? null : (
                <DataCell width="40">
                  <Tooltip placement="left" title="Remove Method">
                    <IconButton onClick={() => RemoveDepartment(department.id)}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                </DataCell>
              )}
            </TableRow>
          ))}
        </DataTable>
        {disabled ? null : (
          <IconButton onClick={() => AddDepartment()}>
            <Add />
          </IconButton>
        )}
      </Collapse>
    </Paper>
  );
};

export default Departments;
