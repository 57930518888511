// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Checkbox,
    DialogContentText,
    FormControlLabel,
    Grid
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';

interface AccountTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const accountTypeOptions = [
    { id: 0, label: 'Static', value: 'NOT VISIBLE' },
    { id: 1, label: 'Revenue', value: 'Revenue' },
    { id: 2, label: 'Expense', value: 'Expense' },
    { id: 3, label: 'Current Asset', value: 'Asset (Current)' },
    { id: 4, label: 'Non-Current Asset', value: 'Asset (Non-Current)' },
    { id: 5, label: 'Current Liability', value: 'Liability (Current)' },
    { id: 6, label: 'Non-Current Liability', value: 'Liability (Non-Current)' }
];

const AccountTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: AccountTableFilterProps) => {
    const [accountTypes, setAccountTypes] = useState(accountTypeOptions);
    const [sitesUnaltered, setSitesUnaltered] = useState<Site[]>([]);
    const [sites, setSites] = useState<Site[]>([]);

    // Get all the sites to display in the dropdown
    useEffect(() => {
        GetAllSites(setSitesUnaltered);
        GetAllSites(setSites);
    }, []);

    // Check if the filter is active, if it isn't reset the filter options
    // This is primarily for when a user clicks the clear filter button, we want all options
    // back in the dropdown
    useEffect(() => {
        if (!isFilterActive()) {
            setAccountTypes(accountTypeOptions);
            setSites(sitesUnaltered);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.accountFilter;
        newFilter[property] = value;
        setFilter({ ...filter, accountFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Account Create Date Start Range"
                            value={filter.accountFilter.startDate}
                            onChange={(newValue) =>
                                handleUpdateFilter('startDate', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Account Create Date End Range"
                            value={filter.accountFilter.endDate}
                            minDate={filter.accountFilter.startDate}
                            onChange={(newValue) =>
                                handleUpdateFilter('endDate', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={accountTypes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Account Type"
                        selectedValues={filter.accountFilter.type}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = accountTypeOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setAccountTypes(currentOptions);
                            handleUpdateFilter('type', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.accountFilter.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sitesUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.name !== val.name
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.accountFilter.unreconciled}
                                onChange={(e) =>
                                    handleUpdateFilter(
                                        'unreconciled',
                                        e.target.checked
                                    )
                                }
                            />
                        }
                        label="Hide fully reconciled Accounts"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AccountTableFilter;
