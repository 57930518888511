// IMPORTS
import { useState } from 'react';
import {
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Drawer,
    Box,
    Divider,
    IconButton
} from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import { Dayjs } from 'dayjs';
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../../global/tableComponents/DataCell';

interface VehiclesProps {
    vehicleData: {
        VehicleSaleId: number;
        adminLoadCost: number;
        customerName: string;
        deliveryPrice: number;
        extraCost: number;
        rebatePrice: number;
        serviceCosts: number;
        stockNumber: string;
        totalCost: number;
        totalSale: number;
        vehicleCondition: string;
        vehicleCostPrice: number;
        vehicleSalePrice: number;
        warrantyCost: number;
    }[];
    vehicleValues: {
        adminLoadCost: number;
        adminLoadCounter: number;
        adminLoadFromUsed: number;
        adminLoadFromNew: number;
        dofCounter: number;
        dofPrice: number;
        warrantyRevenueFromUsed: number;
        DOFFromUsed: number;
        warrantyRevenueFromNew: number;
        DOFFromNew: number;
        newCost: number;
        newRevenue: number;
        newTotal: number;
        usedCost: number;
        usedRevenue: number;
        usedTotal: number;
        warrantyCost: number;
        warrantyCostFromUsed: number;
        warrantyCostFromNew: number;
        warrantyCounter: number;
        warrantyPrice: number;
        adminLoadCounterUsed: number;
        adminLoadCounterNew: number;
        warrantyCounterUsed: number;
        warrantyCounterNew: number;
        dofCounterUsed: number;
        dofCounterNew: number;
    };
    wrongVehicleData: {
        VehicleSaleId: number;
        customerName: string;
        message: string;
        stockNumber: string;
        vehicleCondition: string;
    }[];
    queryDate: {
        startDate: Dayjs;
        endDate: Dayjs;
    };
}

const Vehicles = (props: VehiclesProps) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState('');

    const columns = [
        { id: 0, label: 'Sale ID', width: 20 },
        { id: 1, label: 'Stock Number', width: 20 },
        { id: 2, label: 'Customer', width: 20 },
        { id: 3, label: 'Total Cost (Ex GST)', width: 250 },
        { id: 4, label: 'Total Revenue (Ex GST)' },
        { id: 5, label: 'Gross Profit' }
    ];

    const costPriceString = (costDetails) => {
        let stringArray = [
            `Vehicle: ${CurrencyFormatter(costDetails.vehicleCostPrice)}`
        ];

        if (costDetails.adminLoadCost) {
            stringArray.push(
                '+ Admin Load: ' + CurrencyFormatter(costDetails.adminLoadCost)
            );
        }
        if (costDetails.serviceCosts) {
            stringArray.push(
                '+ Service Costs: ' +
                    CurrencyFormatter(costDetails.serviceCosts)
            );
        }
        if (costDetails.extraCosts) {
            stringArray.push(
                '+ Extra Costs: ' + CurrencyFormatter(costDetails.extraCosts)
            );
        }
        if (costDetails.warrantyCost) {
            stringArray.push(
                '+ Warranty Cost: ' +
                    CurrencyFormatter(costDetails.warrantyCost)
            );
        }
        if (costDetails.csmAllowance) {
            stringArray.push(
                '+ CSM Allowance: ' +
                    CurrencyFormatter(costDetails.csmAllowance)
            );
        }
        if (costDetails.rebatePrice) {
            stringArray.push(
                '- Rebate: ' + CurrencyFormatter(costDetails.rebatePrice)
            );
        }
        if (costDetails.totalInvoice) {
            stringArray.push(
                '- General Invoices: ' +
                    CurrencyFormatter(costDetails.totalInvoice)
            );
        }

        return stringArray;
    };

    let vehicleTotal =
        props.vehicleValues.newTotal + props.vehicleValues.usedTotal;
    let vehicleCost =
        props.vehicleValues.newCost + props.vehicleValues.usedCost;
    let vehicleRevenue =
        props.vehicleValues.newRevenue +
        props.vehicleValues.usedRevenue +
        props.vehicleValues.dofPrice +
        props.vehicleValues.warrantyPrice +
        props.vehicleValues.adminLoadCost;

    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        <b>Vehicles</b>
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {vehicleTotal}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {CurrencyFormatter(vehicleCost)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {CurrencyFormatter(vehicleRevenue)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {CurrencyFormatter(vehicleRevenue - vehicleCost)}
                    </Typography>
                </TableCell>
                <TableCell>
                    {props.wrongVehicleData.length > 0 ? (
                        <IconButton
                            onClick={() => {
                                setDrawerOpen(true);
                                setDrawerTitle('Missing Vehicle Sale Data');
                            }}
                        >
                            <PriorityHighIcon
                                color="warning"
                                fontSize="small"
                            />
                        </IconButton>
                    ) : null}
                </TableCell>
            </TableRow>

            {vehicleTotal > 0 ? (
                <>
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                <b>New Vehicles</b>
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.vehicleValues.newTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.vehicleValues.newTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('New Vehicle Sale Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.vehicleValues.newTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.vehicleValues.newCost -
                                        props.vehicleValues.warrantyCostFromNew
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.vehicleValues.newRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.vehicleValues.newRevenue -
                                        (props.vehicleValues.newCost -
                                            props.vehicleValues
                                                .warrantyCostFromNew)
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    {/* USED VEHICLES */}
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                <b>Used Vehicles</b>
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.vehicleValues.usedTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.vehicleValues.usedTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Used Vehicle Sale Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.vehicleValues.usedTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.vehicleValues.usedCost -
                                        props.vehicleValues.warrantyCostFromUsed
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.vehicleValues.usedRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.vehicleValues.usedRevenue -
                                        (props.vehicleValues.usedCost -
                                            props.vehicleValues
                                                .warrantyCostFromUsed)
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    {props.vehicleValues.warrantyCost ? (
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    Warranty
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {props.vehicleValues.warrantyCounter}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {CurrencyFormatter(
                                        props.vehicleValues.warrantyCost
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {CurrencyFormatter(
                                        props.vehicleValues.warrantyPrice
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {CurrencyFormatter(
                                        props.vehicleValues.warrantyPrice -
                                            props.vehicleValues.warrantyCost
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    ) : null}

                    {props.vehicleValues.dofPrice ? (
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    DOF
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {props.vehicleValues.dofCounter}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    -
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {CurrencyFormatter(
                                        props.vehicleValues.dofPrice
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {CurrencyFormatter(
                                        props.vehicleValues.dofPrice
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    ) : null}

                    {props.vehicleValues.adminLoadCost ? (
                        <TableRow>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    Admin Load
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {props.vehicleValues.adminLoadCounter}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    -
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {CurrencyFormatter(
                                        props.vehicleValues.adminLoadCost
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2" align="center">
                                    {CurrencyFormatter(
                                        props.vehicleValues.adminLoadCost
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    ) : null}
                </>
            ) : null}

            <Drawer
                anchor={'right'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                style={{ zIndex: 1200 }}
            >
                <Box
                    sx={{ width: '40vw', padding: '20px' }}
                    role="presentation"
                >
                    <Typography variant="h4">{drawerTitle}</Typography>
                    <Typography>
                        {props.queryDate.startDate?.format('DD MMMM YYYY')} -{' '}
                        {props.queryDate.endDate?.format('DD MMMM YYYY')}
                    </Typography>
                    <Box height={'10px'}></Box>
                    <Divider></Divider>
                    <Box height="20px"></Box>
                    {drawerTitle === 'New Vehicle Sale Data' ||
                    drawerTitle === 'Used Vehicle Sale Data' ? (
                        <>
                            <DataTable columns={columns}>
                                {props.vehicleData.map((sale) =>
                                    drawerTitle === 'New Vehicle Sale Data' &&
                                    sale.vehicleCondition === 'New' ? (
                                        <TableRow>
                                            <DataCellColoured
                                                handleClick={() =>
                                                    window.open(
                                                        `/vehicles/viewContract/${sale.VehicleSaleId}`,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                {sale.VehicleSaleId}
                                            </DataCellColoured>
                                            <DataCell>
                                                {sale.stockNumber}
                                            </DataCell>
                                            <DataCell>
                                                {sale.customerName}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(
                                                    sale.totalCost
                                                )}
                                                <br />
                                                {costPriceString(sale).map(
                                                    (line) => (
                                                        <Typography
                                                            variant="caption"
                                                            display={'block'}
                                                        >
                                                            {line}
                                                        </Typography>
                                                    )
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(
                                                    sale.totalSale
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(
                                                    sale.totalSale -
                                                        sale.totalCost
                                                )}
                                            </DataCell>
                                        </TableRow>
                                    ) : drawerTitle ===
                                          'Used Vehicle Sale Data' &&
                                      sale.vehicleCondition === 'Used' ? (
                                        <TableRow>
                                            <DataCellColoured
                                                handleClick={() =>
                                                    window.open(
                                                        `/vehicles/viewContract/${sale.VehicleSaleId}`,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                {sale.VehicleSaleId}
                                            </DataCellColoured>
                                            <DataCell>
                                                {sale.stockNumber}
                                            </DataCell>
                                            <DataCell>
                                                {sale.customerName}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(
                                                    sale.totalCost
                                                )}
                                                <br />
                                                {costPriceString(sale).map(
                                                    (line) => (
                                                        <Typography
                                                            variant="caption"
                                                            display={'block'}
                                                        >
                                                            {line}
                                                        </Typography>
                                                    )
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(
                                                    sale.totalSale
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(
                                                    sale.totalSale -
                                                        sale.totalCost
                                                )}
                                            </DataCell>
                                        </TableRow>
                                    ) : null
                                )}
                            </DataTable>
                        </>
                    ) : (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="15%">
                                            <strong>Sale ID</strong>
                                        </TableCell>
                                        <TableCell width="15%">
                                            <strong>Stock Number</strong>
                                        </TableCell>
                                        <TableCell width="15%">
                                            <strong>Customer Name</strong>
                                        </TableCell>
                                        <TableCell width="70%">
                                            <strong>Information</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {props.wrongVehicleData.map((sale) => (
                                    <TableRow>
                                        <TableCell
                                            className="table-link"
                                            onClick={() =>
                                                window.open(
                                                    `/vehicles/viewContract/${sale.VehicleSaleId}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {sale.VehicleSaleId}
                                        </TableCell>
                                        <TableCell>
                                            {sale.stockNumber}
                                        </TableCell>
                                        <TableCell>
                                            {sale.customerName}
                                        </TableCell>
                                        <TableCell>{sale.message}</TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default Vehicles;
