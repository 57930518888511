// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { DialogContentText, Grid } from '@mui/material';
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';

// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';

// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
interface CustomerBackorderTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

interface StatusFilter {
    id: number;
    label: string;
    value: string;
}

interface OrderTypeFilter {
    id: number;
    label: string;
    value: string;
}

const statusOptions = [
    { id: 0, label: 'ORDERED', value: 'ORDERED' },
    { id: 1, label: 'OPEN', value: 'OPEN' },
    { id: 2, label: 'CLOSED', value: 'CLOSED' }
];

const orderTypeOptions = [
    { id: 0, label: 'Daily', value: 'daily' },
    { id: 1, label: 'Stock', value: 'stock' }
];

const CustomerBackorderTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: CustomerBackorderTableFilterProps) => {
    const [sites, setSites] = useState<Site[]>([]);
    const [status, setStatus] = useState<StatusFilter[]>([
        { id: 1, label: 'OPEN', value: 'OPEN' }
    ]);
    const [orderType, setOrderType] = useState<OrderTypeFilter[]>([]);

    useEffect(() => {
        GetAllSites(setSites);
    }, []);

    useEffect(() => {
        if (!isFilterActive()) {
            setStatus([]);
            setSites([]);
            setOrderType([]);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.customerBackorderFilter;
        newFilter[property] = value;
        setFilter({ ...filter, customerBackorderFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={statusOptions}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Status"
                        selectedValues={filter.customerBackorderFilter.status}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = status;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setStatus(currentOptions);
                            handleUpdateFilter('status', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    {/* Site Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.customerBackorderFilter.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sites;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    {/* Site Filter */}
                    <AutocompleteMultiple
                        options={orderTypeOptions}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Order Type"
                        selectedValues={
                            filter.customerBackorderFilter.orderType
                        }
                        handleSelectedValueChange={(event) => {
                            let currentOptions = orderType;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setOrderType(currentOptions);
                            handleUpdateFilter('orderType', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default CustomerBackorderTableFilter;
