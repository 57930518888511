// IMPORTS
import { useState } from 'react';
import {
    Divider,
    IconButton,
    Table,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
// LOGIC
import {
    CurrencyFormatter,
    ToFixed
} from '../../../../global/logic/Formatters';
import CalculateMargin from '../../../../global/logic/CalculateMargin';
import {
    Balance,
    DueNow,
    FreightTotal,
    PartsTotal,
    PaymentTotal,
    SaleTotal,
    TotalCost,
    VoucherTotal
} from '../../logic/CalculateTotals';
// INTERFACES
import { Order } from '../../../../global/interfaces/PartsInterface';
import { PaymentLine } from '../../../../global/interfaces/GeneralInterface';

interface TotalFormProps {
    saleDetails: Order;
    extraCosts: { id: number; type: string; amount: string }[];
    paymentForm?: boolean;
    paymentLines?: PaymentLine[];
    selectedType: string;
}
const TotalForm = ({
    saleDetails,
    extraCosts,
    paymentForm,
    paymentLines,
    selectedType
}: TotalFormProps) => {
    const [showMargin, setShowMargin] = useState(false);

    return (
        <>
            <Paper>
                <Typography variant="h6">Totals</Typography>
                <Divider />
                <Table size="small">
                    <TableRow>
                        <TableCell
                            width="70%"
                            align="left"
                            sx={{ borderBottom: 'none' }}
                        >
                            Parts
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            {CurrencyFormatter(
                                PartsTotal(
                                    saleDetails,
                                    saleDetails.status === 'Sale' ? true : false
                                )
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                            Freight
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            {CurrencyFormatter(FreightTotal(extraCosts))}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left">Vouchers</TableCell>
                        <TableCell
                            align="left"
                            sx={{ paddingRight: 0, paddingLeft: 0 }}
                        >
                            {CurrencyFormatter(VoucherTotal(extraCosts))}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                            <b>Subtotal</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            <b>
                                {CurrencyFormatter(
                                    SaleTotal(
                                        saleDetails,
                                        extraCosts,
                                        saleDetails.status === 'Sale'
                                            ? true
                                            : false
                                    )
                                )}
                            </b>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left">Paid</TableCell>
                        <TableCell
                            align="left"
                            sx={{ paddingRight: 0, paddingLeft: 0 }}
                        >
                            {CurrencyFormatter(
                                selectedType === 'contract'
                                    ? saleDetails.amountPaid
                                    : PaymentTotal(
                                          paymentLines,
                                          saleDetails.status === 'Sale'
                                              ? true
                                              : false
                                      )
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="left"
                            sx={{ borderBottom: 'none', paddingBottom: 0 }}
                        >
                            {<b>Balance</b>}
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0,
                                paddingBottom: 0
                            }}
                        >
                            <b>
                                {CurrencyFormatter(
                                    Balance(
                                        saleDetails,
                                        extraCosts,
                                        paymentLines
                                    )
                                )}
                            </b>
                            {showMargin ? (
                                <IconButton
                                    onClick={() => setShowMargin(false)}
                                >
                                    <ExpandLess fontSize="small" />
                                </IconButton>
                            ) : (
                                <IconButton onClick={() => setShowMargin(true)}>
                                    <ExpandMore fontSize="small" />
                                </IconButton>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableCell
                        align="left"
                        sx={{ borderBottom: 'none', paddingBottom: 0 }}
                    >
                        {<b>Due Now</b>}
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{
                            borderBottom: 'none',
                            paddingRight: 0,
                            paddingLeft: 0,
                            paddingBottom: 0
                        }}
                    >
                        <b>
                            {CurrencyFormatter(
                                DueNow(
                                    selectedType,
                                    saleDetails,
                                    extraCosts,
                                    paymentLines,
                                    saleDetails.status === 'Sale' ? true : false
                                )
                            )}
                        </b>
                    </TableCell>
                    {showMargin ? (
                        <>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Total Cost
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {CurrencyFormatter(
                                        TotalCost(
                                            saleDetails,
                                            saleDetails.status === 'Sale'
                                                ? true
                                                : false
                                        )
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Gross Profit
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {CurrencyFormatter(
                                        SaleTotal(
                                            saleDetails,
                                            extraCosts,
                                            saleDetails.status === 'Sale'
                                                ? true
                                                : false
                                        ) /
                                            1.1 -
                                            TotalCost(
                                                saleDetails,
                                                saleDetails.status === 'Sale'
                                                    ? true
                                                    : false
                                            )
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Margin
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {ToFixed(
                                        CalculateMargin(
                                            SaleTotal(
                                                saleDetails,
                                                extraCosts,
                                                saleDetails.status === 'Sale'
                                                    ? true
                                                    : false
                                            ),
                                            TotalCost(
                                                saleDetails,
                                                saleDetails.status === 'Sale'
                                                    ? true
                                                    : false
                                            )
                                        )
                                    )}
                                    %
                                </TableCell>
                            </TableRow>
                        </>
                    ) : null}
                </Table>
            </Paper>
        </>
    );
};

export default TotalForm;
