// IMPORTS
import { useState } from 'react';
import { Button, TextField, Grid } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';

const EnterIdComponent = () => {
    const [idTextField, setIdTextField] = useState<string>('');

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Service ID"
                        value={idTextField}
                        onChange={(e) => setIdTextField(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                window.location.href =
                                    '/service/payment/' + idTextField;
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={12} textAlign="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            (window.location.href =
                                '/service/payment/' + idTextField)
                        }
                    >
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EnterIdComponent;
