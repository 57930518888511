import { useState, useRef, useEffect } from 'react';
import {
    CircularProgress,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {
    DatePicker,
    LocalizationProvider,
    TimePicker
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SignatureCanvas from 'react-signature-canvas';

// LOGIC
import SubmitTestRideAgreement from '../../logic/SubmitTestRideAgreement';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import GetTestRideAgreement from '../../logic/GetTestRideAgreement';

// INTERFACES
import { TestRide } from '../../../../global/interfaces/VehicleSaleInterface';

const TestRideForm = (props) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [testRideData, setTestRideData] = useState<TestRide>({
        name:
            props?.customerDetails?.firstName +
            ' ' +
            props?.customerDetails?.lastName,
        phoneNumber: props?.customerDetails?.phoneNumber,
        address: null,
        testRideRegistration: null,
        driverLicenceClass: null,
        driverLicenceNumber: null,
        date: null,
        departureTime: null,
        returnTime: null
    });
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [pageLoading, setPageLoading] = useState<boolean>(false);

    const [testRideError, setTestRideError] = useState<{
        nameError: boolean;
        addressError: boolean;
        phoneNumberError: boolean;
        modelIdError: boolean;
        testRideRegoError: boolean;
        driverLicenceNumberError: boolean;
        driverLicenceClassError: boolean;
        testRideDateError: boolean;
        departureTimeError: boolean;
        returnTimeError: boolean;
        riderSignatureError: boolean;
        witnessSignatureError: boolean;
    }>({
        nameError: false,
        addressError: false,
        phoneNumberError: false,
        modelIdError: false,
        testRideRegoError: false,
        driverLicenceNumberError: false,
        driverLicenceClassError: false,
        testRideDateError: false,
        departureTimeError: false,
        returnTimeError: false,
        riderSignatureError: false,
        witnessSignatureError: false
    });

    const [riderSignature, setRiderSignature] = useState<string>();
    const [witnessSignature, setWitnessSignature] = useState<string>();

    useEffect(() => {
        if (props.id) {
            setPageLoading(true);
            GetTestRideAgreement(
                props.id,
                setTestRideData,
                sigCanvas,
                sigCanvas2,
                setPageLoading,
                setDisabled
            );
        }
    }, [props.id]);

    let sigCanvas = useRef<SignatureCanvas>(null);
    let sigCanvas2 = useRef<SignatureCanvas>(null);

    const formatIntoPngRider = () => {
        if (sigCanvas.current) {
            const dataURL = sigCanvas.current.toDataURL();
            setRiderSignature(dataURL);
            return dataURL;
        }
    };

    const formatIntoPngWitness = () => {
        if (sigCanvas2.current) {
            const dataURL = sigCanvas2.current.toDataURL();
            setWitnessSignature(dataURL);
            return dataURL;
        }
    };

    const clearSignatureRider = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    const clearSignatureWitness = () => {
        if (sigCanvas2.current) {
            sigCanvas2.current.clear();
        }
    };

    return pageLoading ? (
        <Grid container spacing={1}>
            <Grid item xs={12} textAlign="center">
                <CircularProgress />
            </Grid>
        </Grid>
    ) : (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Full Name"
                        required
                        disabled={disabled}
                        value={testRideData.name}
                        error={testRideError.nameError}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) =>
                            props.setCustomerDetails({
                                ...props.customerDetails,
                                name: event.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Address"
                        disabled={disabled}
                        value={testRideData.address}
                        InputLabelProps={{ shrink: true }}
                        error={testRideError.addressError}
                        onChange={(e) => {
                            setTestRideData({
                                ...testRideData,
                                address: e.target.value
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Phone Number"
                        disabled={disabled}
                        value={props.customerDetails.phoneNumber}
                        InputLabelProps={{ shrink: true }}
                        error={testRideError.phoneNumberError}
                        onChange={(event) =>
                            props.setCustomerDetails({
                                ...props.customerDetails,
                                phoneNumber: event.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Model Interested In"
                        disabled
                        error={testRideError.modelIdError}
                        InputLabelProps={{ shrink: true }}
                        value={
                            props.selectedMake.name +
                            ' ' +
                            props.selectedModel.name
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Test Ride Registration"
                        disabled={disabled}
                        value={testRideData.testRideRegistration}
                        InputLabelProps={{ shrink: true }}
                        error={testRideError.testRideRegoError}
                        onChange={(e) => {
                            setTestRideData({
                                ...testRideData,
                                testRideRegistration: e.target.value
                            });
                        }}
                    />
                </Grid>
            </Grid>

            <br />
            <Typography display="inline">I, </Typography>
            <Typography
                display="inline"
                fontStyle="italic"
                fontWeight="fontWeightBold"
            >
                {props.customerDetails.firstName +
                    ' ' +
                    props.customerDetails.lastName}
            </Typography>
            <Typography display="inline">
                , certify that I am the holder of a valid Driver Licence:
            </Typography>
            <br />
            <br />
            <Grid container spacing={1} textAlign="center">
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Driver Licence Number"
                        value={testRideData.driverLicenceNumber}
                        disabled={disabled}
                        error={testRideError.driverLicenceNumberError}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setTestRideData({
                                ...testRideData,
                                driverLicenceNumber: e.target.value
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Driver Licence Class"
                        disabled={disabled}
                        value={testRideData.driverLicenceClass}
                        InputLabelProps={{ shrink: true }}
                        error={testRideError.driverLicenceClassError}
                        onChange={(e) => {
                            setTestRideData({
                                ...testRideData,
                                driverLicenceClass: e.target.value
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Test Ride Date"
                            disabled={disabled}
                            value={testRideData.date}
                            onChange={(newValue) => {
                                setTestRideData({
                                    ...testRideData,
                                    date: newValue
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: testRideError.testRideDateError,
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <TimePicker
                            label="Departure Time"
                            disabled={disabled}
                            ampm={true}
                            value={testRideData.departureTime}
                            onChange={(newValue) =>
                                setTestRideData({
                                    ...testRideData,
                                    departureTime: newValue
                                })
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: testRideError.departureTimeError,
                                    placeholder: 'Departure Time',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <TimePicker
                            label="Return Time"
                            disabled={disabled}
                            ampm={true}
                            value={testRideData.returnTime}
                            onChange={(newValue) =>
                                setTestRideData({
                                    ...testRideData,
                                    returnTime: newValue
                                })
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: testRideError.returnTimeError,
                                    placeholder: 'Return Time',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <br />

            <Typography fontStyle="italic">
                I agree to ride the above mentioned motorcycle in a responsible
                manner and abide by Queensland Road Regulations (including the
                payment of any red light/speed camera infringements incurred
                during the test ride). I accept liability for any damage caused
                while the motorcycle is in my custody, and pay the insurance
                excess listed below should it be required after my ride.
            </Typography>
            <br />
            <Typography align="center" fontWeight="bold">
                Excess: All Riders $2,500
            </Typography>

            <Grid container spacing={0}>
                <Grid item xs={6} textAlign="center">
                    Rider Signature:{' '}
                    <IconButton
                        disabled={disabled}
                        onClick={() => clearSignatureRider()}
                    >
                        <ClearIcon />
                    </IconButton>
                </Grid>

                <Grid item xs={6} textAlign="center">
                    Witness Signature:{' '}
                    <IconButton
                        disabled={disabled}
                        onClick={() => clearSignatureWitness()}
                    >
                        <ClearIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={6} textAlign="center">
                    <SignatureCanvas
                        penColor="black"
                        disabled={disabled}
                        canvasProps={{
                            width: 430,
                            height: 200,
                            className: 'sigCanvas',
                            style: {
                                border: testRideError.riderSignatureError
                                    ? '2px solid red'
                                    : '1px solid black'
                            }
                        }}
                        ref={sigCanvas}
                        onEnd={() => formatIntoPngRider()}
                    />
                </Grid>
                <Grid item xs={6} textAlign="center">
                    <SignatureCanvas
                        penColor="black"
                        disabled={disabled}
                        canvasProps={{
                            width: 400,
                            height: 200,
                            className: 'sigCanvas',
                            style: {
                                border: testRideError.witnessSignatureError
                                    ? '2px solid red'
                                    : '1px solid black'
                            }
                        }}
                        ref={sigCanvas2}
                        onEnd={() => formatIntoPngWitness()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid xs={12} textAlign="center">
                    {disabled ? null : (
                        <LoadingButton
                            variant="contained"
                            loading={buttonLoading}
                            onClick={() => {
                                SubmitTestRideAgreement(
                                    props.id,
                                    testRideData,
                                    props.customerDetails.firstName +
                                        props.customerDetails.lastName,
                                    props.customerDetails.phoneNumber,
                                    props.selectedModel.id,
                                    riderSignature,
                                    witnessSignature,
                                    setTestRideError,
                                    setButtonLoading
                                );
                            }}
                        >
                            Submit Test Ride Agreement
                        </LoadingButton>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default TestRideForm;
