const BlankCustomerBox = (doc) => {
  doc.setFont('helvetica', 'normal').setFontSize(7);
  doc.rect(10, 70, 93, 60); // The box around the customer details
  doc.text('Salesperson: ', 11, 75);
  doc.line(55, 70, 55, 78); // Vertical line between sales person and LMD
  doc.text('Dealer', 56, 73.5);
  doc.text('Licencee No.', 56, 76);
  doc.line(10, 78, 103, 78); // Horizontal line below sales person and LMD

  doc.setFont('helvetica', 'bold').setFontSize(9);
  doc.text("PURCHASER'S DETAILS", 11, 82);
  doc.line(10, 88, 103, 88); // Horizontal line below customer name
  doc.line(10, 95, 103, 95); // Horizontal line below address line 1

  doc.setFont('helvetica', 'normal').setFontSize(7);
  doc.text('Postcode:', 70, 100);
  doc.line(10, 102, 103, 102); // Horizontal line below addres line 2 and postcode

  doc.text('Telephone No:', 11, 107);
  doc.line(10, 109, 103, 109); // Horizontal line below phone number

  doc.text('Email:', 11, 114);
  doc.line(10, 116, 103, 116); // Horizontal line below email address

  doc.text('Date of', 11, 119);
  doc.text('Birth:', 11, 122);
  doc.line(55, 116, 55, 123); // Vertical line between dob and licence number
  doc.text("Driver's", 56, 119);
  doc.text('License No:', 56, 122);
  doc.line(10, 123, 103, 123); // Horizontal line below dob and licence number

  doc.text('ABN:', 11, 128);
};

export default BlankCustomerBox;
