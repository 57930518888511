import api from '../../../../api';

interface GetTagsProps {
    DocumentId: string;
    SignerId: string;
}

export const GetLinkedTags = async ({ DocumentId, SignerId }: GetTagsProps) => {
    const res = await api.get(`/getTags/${DocumentId}/${SignerId}`);
    return res.data;
};
