const TaxFileNumberValidation = (taxFileNumber: string) => {
    if (taxFileNumber) {
        if (taxFileNumber.length !== 9) {
            return {
                error: true,
                message: 'Tax File Number Invalid.',
                subMessage: 'Tax file number should be 9 digits long.'
            };
        }

        let weightArray = [1, 4, 3, 7, 5, 8, 6, 9, 10];
        let total = 0;

        for (let i = 0; i < 9; i++) {
            let character = parseInt(taxFileNumber.charAt(i));
            let weight = weightArray[i];

            total += character * weight;
        }

        if (total % 11 !== 0) {
            return {
                error: true,
                message: 'Tax File Number Invalid.',
                subMessage: 'Tax file number does not exist.'
            };
        }
    }

    return { error: false, message: '', subMessage: '' };
};

export default TaxFileNumberValidation;
