// IMPORTS
import api from '../../../../../api';
// LOGIC
import ValidateFields from './ValidateFields';
// INTERFACES
import {
    Customer,
    PaymentLine
} from '../../../global/interfaces/GeneralInterface';
import {
    TradeIn,
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Order } from '../../../global/interfaces/PartsInterface';

const UpdateVehicleSale = (
    id: number,
    saleDetails: VehicleSale,
    selectedCustomer: Customer,
    selectedVehicle: Vehicle,
    paymentLines: PaymentLine[],
    linkedTrades: TradeIn[],
    linkedVouchers: any[],
    linkedSales: Order[],
    showSnackbar: showSnackbar,
    gstType: string
) => {
    let isValid = ValidateFields(
        id,
        saleDetails,
        selectedCustomer,
        selectedVehicle,
        paymentLines,
        linkedTrades,
        linkedVouchers,
        showSnackbar,
        gstType
    );

    if (isValid) {
        let obj = {
            saleDetails: saleDetails,
            selectedCustomer: selectedCustomer,
            selectedVehicle: selectedVehicle,
            paymentLines: paymentLines,
            linkedTrades: linkedTrades,
            linkedVouchers: linkedVouchers,
            linkedSales: linkedSales,
            gstType: gstType
        };
        api.put(`updateVehicleSale/${id}`, obj).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Vehicle sale successfully updated.',
                    'The page will now refresh.'
                );
                window.location.reload();
            } else {
                if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact the IT department.',
                        'error'
                    );
                } else {
                    showSnackbar(
                        res.data.split('|')[0],
                        res.data.split('|')[1],
                        'error'
                    );
                }
            }
        });
    }
};

export default UpdateVehicleSale;
