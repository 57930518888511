// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Button, TextField } from '@mui/material';
// LOGIC
import HandleLogin from '../logic/HandleLogin';

interface LoginContentProps {
    loginDetails: LoginDetails;
    setLoginDetails: Dispatch<SetStateAction<LoginDetails>>;
    setPasswordChange: Dispatch<SetStateAction<boolean>>;
    setToken;
}

interface LoginDetails {
    username: string;
    password: string;
    usernameError: boolean;
    passwordError: boolean;
    usernameErrorMessage: string;
    passwordErrorMessage: string;
}

const LoginContent = ({
    loginDetails,
    setLoginDetails,
    setPasswordChange,
    setToken
}: LoginContentProps) => {
    return (
        <>
            <TextField
                data-cy={'usernameField'}
                required
                fullWidth
                label="Username"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={loginDetails.username}
                onChange={(e) =>
                    setLoginDetails({
                        ...loginDetails,
                        username: e.target.value
                    })
                }
                onKeyPress={(e) => {
                    if (e.key === 'Enter')
                        HandleLogin(
                            e,
                            loginDetails,
                            setLoginDetails,
                            setPasswordChange,
                            setToken
                        );
                }}
                error={loginDetails.usernameError}
                helperText={
                    loginDetails.usernameError
                        ? loginDetails.usernameErrorMessage
                        : ''
                }
            />
            <TextField
                required
                fullWidth
                data-cy={'passwordField'}
                label="Password"
                margin="normal"
                type="password"
                InputLabelProps={{ shrink: true }}
                value={loginDetails.password}
                onChange={(e) =>
                    setLoginDetails({
                        ...loginDetails,
                        password: e.target.value
                    })
                }
                onKeyPress={(e) => {
                    if (e.key === 'Enter')
                        HandleLogin(
                            e,
                            loginDetails,
                            setLoginDetails,
                            setPasswordChange,
                            setToken
                        );
                }}
                error={loginDetails.passwordError}
                helperText={
                    loginDetails.passwordError
                        ? loginDetails.passwordErrorMessage
                        : ''
                }
            />
            <Button
                fullWidth
                data-cy={'submitLoginButton'}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) =>
                    HandleLogin(
                        e,
                        loginDetails,
                        setLoginDetails,
                        setPasswordChange,
                        setToken
                    )
                }
            >
                Sign In
            </Button>
        </>
    );
};

export default LoginContent;
