// IMPORTS
import { useState, useEffect } from 'react';
import { Typography, TableRow, Chip, IconButton, Tooltip } from '@mui/material';
import {
    Add,
    CarRental,
    ContentPaste,
    FileDownload,
    OpenInNew
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import ServiceTableFilter from './components/ServiceTableFilter';
// LOGIC
import { DateFormatter } from '../../global/logic/Formatters';
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import GetSite from '../../global/databaseLogic/GetSite';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { Service } from '../../global/interfaces/ServiceInterface';
import { Site } from '../../global/interfaces/GeneralInterface';
import HandleOpenROReport from './logic/HandleOpenROReport';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    serviceFilter: {
        startDate: null,
        endDate: null,
        site: [],
        status: [],
        type: [],
        user: [],
        archived: false
    }
};
const clearedSort = ['id', 'DESC'];

const BookingTable = () => {
    const history = useHistory();
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(
            JSON.stringify({
                ...clearedFilter,
                serviceFilter: {
                    ...clearedFilter.serviceFilter,
                    site: [{ id: localStorage.getItem('SiteId') }]
                }
            })
        )
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<Service[]>([]);
    const [siteDetails, setSiteDetails] = useState<Site>();

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Booking',
            onClick: () => history.push('/service/newBooking')
        },
        {
            icon: <CarRental />,
            name: 'Loan Vehicles',
            onClick: () => (window.location.href = '/service/loanVehicles')
        },
        {
            icon: <ContentPaste />,
            name: 'Service Codes',
            onClick: () => (window.location.href = '/service/codes')
        },
        {
            icon: <FileDownload />,
            name: 'Open RO Report',
            onClick: () => HandleOpenROReport(filter.serviceFilter.site)
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 75 },
        { id: 1, label: 'Type', name: 'jobType', sort: true, width: 75 },
        { id: 2, label: 'Date', name: 'jobStart', sort: true, width: 75 },
        { id: 3, label: 'Customer Name', width: 200 },
        { id: 4, label: 'Vehicle', width: 200 },
        { id: 5, label: 'Registration / Stock Number', width: 200 },
        { id: 6, label: 'Location', name: 'SiteId', sort: true, width: 100 },
        {
            id: 7,
            label: 'Assigned Tech',
            name: 'TechId',
            sort: true,
            width: 150
        },
        {
            id: 8,
            label: 'Status',
            name: 'serviceComplete',
            sort: true,
            width: 100
        },
        { id: 9, label: '' }
    ];

    // Get the details of the current site
    useEffect(() => {
        GetSite(parseInt(localStorage.getItem('SiteId')), setSiteDetails);
    }, []);

    // Once the current site details have been obtained set the filter to some default values
    useEffect(() => {
        let newFilter = {
            startDate: null,
            endDate: null,
            site: [
                {
                    id: parseInt(localStorage.getItem('SiteId')),
                    name: siteDetails?.name
                }
            ],
            status: [
                { id: 0, label: 'Incomplete', value: 'Incomplete' },
                { id: 1, label: 'Complete', value: 'Complete' }
            ],
            type: [],
            user: [],
            archived: false
        };
        setFilter({ ...filter, serviceFilter: newFilter });
        // eslint-disable-next-line
    }, [siteDetails]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let status =
            filter.serviceFilter.status.length > 0
                ? ConvertArrayToFilter({
                      array: filter.serviceFilter.status,
                      filterStart: '&status=',
                      selector: 'value'
                  })
                : '';
        let type =
            filter.serviceFilter.type.length > 0
                ? ConvertArrayToFilter({
                      array: filter.serviceFilter.type,
                      filterStart: '&type=',
                      selector: 'value'
                  })
                : '';
        let site =
            filter.serviceFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.serviceFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let user =
            filter.serviceFilter.user.length > 0
                ? ConvertArrayToFilter({
                      array: filter.serviceFilter.user,
                      filterStart: '&user=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let startDate =
            filter.serviceFilter.startDate !== null &&
            dayjs(filter.serviceFilter.startDate).isValid()
                ? `&startDate=${dayjs(filter.serviceFilter.startDate).toISOString()}`
                : '';
        let endDate =
            filter.serviceFilter.endDate !== null &&
            dayjs(filter.serviceFilter.endDate).isValid()
                ? `&endDate=${dayjs(filter.serviceFilter.endDate).toISOString()}`
                : '';
        let archived = `&archived=${filter.serviceFilter.archived}`;

        let apiString = `${status}${type}${site}${user}${startDate}${endDate}${archived}`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.serviceFilter.startDate != null ||
            filter.serviceFilter.endDate != null ||
            filter.serviceFilter.site.length > 0 ||
            filter.serviceFilter.user.length > 0 ||
            filter.serviceFilter.status.length > 0 ||
            filter.serviceFilter.type.length > 0 ||
            filter.serviceFilter.archived
        ) {
            isActive = true;
        }

        return isActive;
    };

    const getVehicleName = (row: Service) => {
        if (row.VehicleId) {
            return row.Vehicle;
        } else {
            return row.CustomerVehicle;
        }
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Bookings</Typography>
            <br />
            <TableSearch
                searchTitle="Search Bookings"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="serviceSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <ServiceTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() =>
                                    history.push(
                                        `/service/viewBooking/${row.id}`
                                    )
                                }
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>{row.jobType}</DataCell>
                            <DataCell>{DateFormatter(row.jobStart)}</DataCell>
                            <DataCell>
                                {row?.Customer?.companyName
                                    ? row?.Customer?.companyName?.toUpperCase()
                                    : row.jobType !== 'Internal'
                                      ? row?.Customer?.firstName?.toUpperCase() +
                                        ' ' +
                                        row?.Customer?.lastName?.toUpperCase()
                                      : ''}
                            </DataCell>
                            <DataCell>
                                {getVehicleName(row)?.Make?.name}{' '}
                                {getVehicleName(row)?.Model?.name}{' '}
                                {getVehicleName(row)?.SeriesId
                                    ? getVehicleName(row)?.Series?.name
                                    : ''}
                            </DataCell>
                            <DataCell>
                                {row.jobType === 'Internal'
                                    ? row?.Vehicle?.stockNumber
                                    : row?.CustomerVehicle?.registrationNumber?.toUpperCase()}
                            </DataCell>
                            <DataCell>{row?.Site?.name}</DataCell>
                            <DataCell>
                                {row?.Tech?.firstName} {row?.Tech?.lastName}
                            </DataCell>
                            <DataCell>
                                <Chip
                                    variant="outlined"
                                    label={row.serviceComplete}
                                    color={
                                        row.serviceComplete === 'Incomplete'
                                            ? 'error'
                                            : row.serviceComplete === 'Complete'
                                              ? 'warning'
                                              : 'success'
                                    }
                                />
                            </DataCell>
                            <DataCell>
                                {row.jobType === 'Retail' ||
                                (row.jobType === 'Insurance' &&
                                    row.CustomerId) ||
                                (row.jobType === 'Warranty' &&
                                    row.CustomerId) ? (
                                    <Tooltip title="Open Payments">
                                        <IconButton
                                            onClick={() =>
                                                window.open(
                                                    `/service/payment/${row.id}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            <OpenInNew />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </PageWrapper>
    );
};

export default BookingTable;
