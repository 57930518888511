// IMPORTS
import { useState, useEffect } from 'react';
import { Grid, Typography, TextField, TableRow } from '@mui/material';
// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import GetAllAvailableVehicles from '../../../global/databaseLogic/GetAllAvailableVehicles';
import SubmitTransfer from '../logic/SubmitTransfer';
import CompleteTransfer from '../logic/CompleteTransfer';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import Autocomplete from '../../../global/Autocomplete';
import Fab from '../../../global/Fab';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
// INTERFACES
import { Site } from '../../../global/interfaces/GeneralInterface';
import {
    Vehicle,
    VehicleTransfer
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface NewTransferDrawerContentProps {
    transferData?: VehicleTransfer;
    preAddedSiteFrom?: Site;
    preAddedVehicle?: Vehicle;
    showSnackbar: showSnackbar;
}

const NewTransferDrawerContent = ({
    transferData,
    preAddedSiteFrom,
    preAddedVehicle,
    showSnackbar
}: NewTransferDrawerContentProps) => {
    const [allSitesUnaltered, setAllSitesUnaltered] = useState<Site[]>([]);
    const [allSites, setAllSites] = useState<Site[]>([]);
    const [allVehicles, setAllVehicles] = useState([]);
    const [newTransfer, setNewTransfer] = useState<boolean>(true);

    const [newTransferData, setNewTransferData] = useState({
        id: null,
        siteFrom: null,
        siteTo: null,
        vehicle: null,
        notes: null,
        status: 'PENDING'
    });

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    // Get all the sites
    useEffect(() => {
        GetAllSites(setAllSites);
        GetAllSites(setAllSitesUnaltered);

        if (transferData?.id) {
            setNewTransfer(false);
            transferData.Vehicle.vehicleName =
                transferData.Vehicle?.year +
                ' ' +
                transferData.Vehicle?.Make?.name +
                ' ' +
                transferData.Vehicle?.Model?.name;
            setNewTransferData({
                ...transferData,
                id: transferData.id,
                siteFrom: transferData.SiteFrom,
                siteTo: transferData.SiteTo,
                vehicle: transferData.Vehicle,
                notes: transferData.notes,
                status: transferData.status
            });
        }

        if (preAddedSiteFrom) {
            let vehicleName =
                preAddedVehicle?.year +
                ' ' +
                preAddedVehicle?.Make?.name +
                ' ' +
                preAddedVehicle?.Model?.name;
            let vehicleObject = {
                stockNumber: preAddedVehicle.stockNumber,
                vin: preAddedVehicle.vin,
                vehicleName: vehicleName,
                id: preAddedVehicle.id
            };
            setNewTransferData({
                ...transferData,
                id: null,
                siteFrom: preAddedSiteFrom,
                siteTo: null,
                vehicle: vehicleObject,
                notes: null,
                status: null
            });
        }
        // eslint-disable-next-line
    }, []);

    const columns = [
        { id: 1, label: 'Stock Number' },
        { id: 2, label: 'Make / Model' },
        { id: 3, label: 'VIN' }
    ];

    // Get all the vehicles from the selected from site
    useEffect(() => {
        if (newTransferData.siteFrom) {
            GetAllAvailableVehicles(
                setAllVehicles,
                newTransferData.siteFrom.id
            );
        }
    }, [newTransferData.siteFrom]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Autocomplete
                        isDisabled={!newTransfer}
                        options={allSites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Transfer To"
                        selectedValue={newTransferData?.siteTo}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = allSitesUnaltered.filter(
                                (x) => x.id !== event.id
                            );
                            setAllSites(currentOptions);
                            setNewTransferData({
                                ...newTransferData,
                                siteTo: event
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        isDisabled={!newTransfer}
                        options={allSites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Transfer From"
                        selectedValue={newTransferData?.siteFrom}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = allSitesUnaltered.filter(
                                (x) => x.id !== event.id
                            );
                            setAllSites(currentOptions);
                            setNewTransferData({
                                ...newTransferData,
                                siteFrom: event
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>

                {!newTransferData.siteFrom || !newTransferData.siteTo ? (
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Please select the transfer sites before proceeding.
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Autocomplete
                                isDisabled={!newTransfer}
                                options={allVehicles}
                                useTwoOptionLabels={true}
                                primaryOptionLabel={'stockNumber'}
                                secondaryOptionLabel={'vehicleName'}
                                textfieldLabel="Vehicle Stock Number"
                                selectedValue={newTransferData.vehicle}
                                handleSelectedValueChange={(event) => {
                                    setNewTransferData({
                                        ...newTransferData,
                                        vehicle: event
                                    });
                                }}
                                handleInputValueChange={() => null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DataTable columns={columns}>
                                <TableRow>
                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(
                                                `/vehicles/view/${newTransferData.vehicle?.stockNumber}`
                                            )
                                        }
                                    >
                                        {newTransferData.vehicle?.stockNumber}
                                    </DataCellColoured>
                                    <DataCell>
                                        {newTransferData.vehicle?.vehicleName}
                                    </DataCell>
                                    <DataCell>
                                        {newTransferData.vehicle?.vin}
                                    </DataCell>
                                </TableRow>
                            </DataTable>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={newTransferData.status === 'COMPLETE'}
                                fullWidth
                                multiline
                                rows={5}
                                label="Transfer Notes"
                                value={newTransferData.notes}
                                onChange={(e) =>
                                    setNewTransferData({
                                        ...newTransferData,
                                        notes: e.target.value
                                    })
                                }
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </>
                )}

                <br />
                {!newTransferData.vehicle ? null : (
                    <>
                        {!newTransfer &&
                        newTransferData.status !== 'COMPLETE' ? (
                            <Grid item xs={12}>
                                <Typography align="center">
                                    <LoadingButton
                                        loading={buttonLoading}
                                        variant="contained"
                                        onClick={() =>
                                            CompleteTransfer({
                                                transferId: newTransferData.id,
                                                notes: newTransferData.notes,
                                                newSiteId:
                                                    newTransferData.siteTo.id,
                                                VehicleId:
                                                    newTransferData.vehicle.id,
                                                setButtonLoading:
                                                    setButtonLoading,
                                                showSnackbar: showSnackbar
                                            })
                                        }
                                    >
                                        Mark Transfer As Complete
                                    </LoadingButton>
                                </Typography>
                            </Grid>
                        ) : null}

                        {newTransfer ? (
                            <Fab
                                editIcon={false}
                                onClick={() =>
                                    SubmitTransfer(
                                        {
                                            siteFrom: newTransferData.siteFrom,
                                            siteTo: newTransferData.siteTo,
                                            vehicleId:
                                                newTransferData.vehicle.id,
                                            notes: newTransferData.notes
                                        },
                                        showSnackbar
                                    )
                                }
                            />
                        ) : null}
                    </>
                )}
            </Grid>
        </>
    );
};

export default withSnackbar(NewTransferDrawerContent);
