const FilterBankTransactionsV2 = (
    bankFilter,
    reconciliationDetails,
    setReconciliationDetails,
    setSelectedOraUnrecTab,
    setSelectedBankUnrecTab,
    setSelectedOraRecTab,
    setSelectedBankRecTab,
    reconciliationDetailsUnaltered
) => {
    let currentUnreconciledBankWeekly = JSON.parse(
        JSON.stringify([
            ...reconciliationDetailsUnaltered.bankTransactionsUnreconciled
        ])
    );
    let currentReconciled = reconciliationDetails.bankTransactionsReconciled;

    for (let week of currentUnreconciledBankWeekly) {
        let currentLines = JSON.parse(JSON.stringify(week.lines));
        if (bankFilter === 'automaticDrawing') {
            week.lines = currentLines.filter((x) =>
                x.reference.toLowerCase().includes('automatic drawing')
            );
        } else if (bankFilter === 'transferDebits') {
            week.lines = currentLines.filter((x) =>
                x.reference.toLowerCase().includes('transfer debits')
            );
        } else if (bankFilter === 'transferCredits') {
            week.lines = currentLines.filter((x) =>
                x.reference.toLowerCase().includes('transfer credits')
            );
        } else if (bankFilter === 'interbankCredit') {
            week.lines = currentLines.filter((x) =>
                x.reference.toLowerCase().includes('inter-bank credit')
            );
        } else if (bankFilter === 'creditAdjustment') {
            week.lines = currentLines.filter((x) =>
                x.reference.toLowerCase().includes('credit adjustment')
            );
        } else if (bankFilter === 'deposit') {
            week.lines = currentLines.filter((x) =>
                x.reference.toLowerCase().includes('deposit')
            );
        } else if (bankFilter === 'flexipay') {
            week.lines = currentLines.filter((x) =>
                x.reference.toLowerCase().includes('flexipay')
            );
        }
    }

    for (let week of currentUnreconciledBankWeekly) {
        // For each week, check every lines for reconciled ones
        let currentWeek = week.week;
        let linesToKeep = [];
        for (let line of week.lines) {
            if (
                currentReconciled.findIndex((x) => x.week === currentWeek) ===
                -1
            ) {
                linesToKeep.push(line);
            } else if (
                !currentReconciled[
                    currentReconciled.findIndex((x) => x.week === currentWeek)
                ].lines.includes(
                    (x) => x.BankTransactionId === line.BankTransactionId
                )
            ) {
                linesToKeep.push(line);
            }
        }

        week.lines = linesToKeep;

        if (week.lines.length === 0) {
            currentUnreconciledBankWeekly.splice(
                currentUnreconciledBankWeekly.findIndex(
                    (x) => x.week === week.week
                ),
                1
            );
        }
    }

    setReconciliationDetails({
        ...reconciliationDetails,
        bankTransactionsUnreconciled: currentUnreconciledBankWeekly
    });
};

export default FilterBankTransactionsV2;
