// IMPORTS
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Grid, Box, Paper } from '@material-ui/core';
import LinkIcon from '@mui/icons-material/Link';

// COMPONENTS
import TableOfContents from './TableOfContents';
import GuideStepper from './GuideStepper';

// INTERFACE
import { ContentSection } from './interface';

interface DocumentationPageProps {
    pageTitle: string;
    sections: ContentSection[];
}

const DocumentationPage = ({ pageTitle, sections }: DocumentationPageProps) => {
    const location = useLocation();

    // Reset activeSection and scroll to top when navigating to a new page
    useEffect(() => {
        setActiveSection('');
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // get the base href based on the current pathname
    const baseHref = location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname;

    const currentSection = location.hash.replace('#', '');
    const [activeSection, setActiveSection] = useState('');

    //get all headings for table of contents
    const headings = document.getElementsByClassName('heading');

    // if the current page is under '/support' and have a #, scrool smooth to matching element
    useEffect(() => {
        if (location.pathname.startsWith('/support')) {
            if (location.hash) {
                const element = document.getElementById(currentSection);
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }
        }
    }, [location, currentSection]);

    useEffect(() => {
        const handleScroll = () => {
            let current = '';
            Array.from(headings).forEach((h) => {
                const section = document.getElementById(h.id);
                if (section) {
                    const rect = section.getBoundingClientRect();
                    if (rect.top <= 200) {
                        current = h.id;
                    }
                }
            });
            if (current !== activeSection) {
                setActiveSection(current);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [headings, activeSection]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'left',
                        margin: '1rem'
                    }}
                >
                    <Typography
                        variant="h4"
                        id={pageTitle.toLowerCase().replace(/\s+/g, '-')}
                    >
                        {pageTitle}{' '}
                        <a
                            href={`${baseHref}/#${pageTitle.toLowerCase().replace(/\s+/g, '-')}`}
                            aria-labelledby={pageTitle
                                .toLowerCase()
                                .replace(/\s+/g, '-')}
                        >
                            <LinkIcon />
                        </a>
                    </Typography>
                    <br />
                    {sections.map((section, index) => (
                        <Grid item key={index} style={{ marginBottom: '50px' }}>
                            <Typography
                                id={section.id}
                                variant="h5"
                                className="heading"
                                style={{
                                    paddingTop: '90px',
                                    marginTop: '-64px'
                                }}
                            >
                                {section.title}{' '}
                                <a
                                    href={`${baseHref}/#${section.id}`}
                                    aria-labelledby={section.id}
                                >
                                    <LinkIcon />
                                </a>
                            </Typography>
                            <p>{section.description}</p>
                            {section.steps && (
                                <Paper>
                                    <GuideStepper steps={section.steps} />
                                </Paper>
                            )}
                        </Grid>
                    ))}
                </Box>
            </Grid>
            <Grid item xs={3}>
                <TableOfContents
                    baseHref={baseHref}
                    headings={headings}
                    currentSection={activeSection}
                />
            </Grid>
        </Grid>
    );
};

export default DocumentationPage;
