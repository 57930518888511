import { Dispatch, SetStateAction } from "react"
import api from "../../../../api"
import { Registration } from "../interfaces/VehicleSaleInterface"

const GetFutureQLDRegistration = (setFutureRegistrations: Dispatch<SetStateAction<Registration[]>>) => {
    api.get('qldFutureRegistration').then(res => {
        setFutureRegistrations(res.data)
    })
}

export default GetFutureQLDRegistration