// IMPORTS
import { Dispatch, SetStateAction, useContext } from 'react';
import {
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { SiteContext } from '../../../../main/Main';
// COMPONENTS
import CustomerTable from '../../../global/customerFormComponents/CustomerTable';
import Paper from '../../../global/Paper';
import ServiceForm from './SelectEntityForms/ServiceForm';
import VehicleContractForm from './SelectEntityForms/VehicleContractForm';
import VehicleForm from './SelectEntityForms/VehicleForm';
// INTERFACES
import { Customer, Site } from '../../../global/interfaces/GeneralInterface';
import {
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';

interface SelectCustomerProps {
    readOnly: boolean;
    selectedEntity: Customer | VehicleSale | Vehicle | Service;
    setSelectedEntity: Dispatch<
        SetStateAction<Customer | VehicleSale | Vehicle | Service>
    >;
    selectedType: string;
    setSelectedType: Dispatch<SetStateAction<string>>;
    associatedOnlineOrder: OnlineOrder;
    setAssociatedOnlineOrder: Dispatch<SetStateAction<OnlineOrder>>;
    saleDetails: Order;
    setSaleDetails: Dispatch<SetStateAction<Order>>;
    oldSale: Order;
}

const SelectCustomer = ({
    saleDetails,
    setSaleDetails,
    oldSale,
    readOnly,
    selectedEntity,
    setSelectedEntity,
    selectedType,
    setSelectedType,
    associatedOnlineOrder,
    setAssociatedOnlineOrder
}: SelectCustomerProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const handleChangeType = (
        event: React.MouseEvent<HTMLElement>,
        newType: string | null
    ) => {
        if (newType != null) {
            setSelectedType(newType);
            setSelectedEntity(null);
        }
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            fullWidth
                            value={selectedType}
                            onChange={handleChangeType}
                            disabled={
                                readOnly ||
                                oldSale.status === 'Sale' ||
                                (oldSale.status === 'Proforma' &&
                                    oldSale.paymentLines.length > 0)
                            }
                            size="small"
                        >
                            <ToggleButton
                                value="customer"
                                data-cy="selectCustomer"
                            >
                                Customer
                            </ToggleButton>
                            <ToggleButton
                                value="contract"
                                data-cy="selectContract"
                            >
                                {siteDetails.vehicleTypeSold === 'Motorcycles'
                                    ? 'Vehicle Contract'
                                    : 'Bicycle Sale'}
                            </ToggleButton>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                                <ToggleButton
                                    value="vehicle"
                                    data-cy="selectVehicle"
                                >
                                    Vehicle
                                </ToggleButton>
                            ) : null}
                            <ToggleButton
                                value="service"
                                data-cy="selectService"
                            >
                                {siteDetails.vehicleTypeSold === 'Motorcycles'
                                    ? 'Service'
                                    : 'Service Job'}
                            </ToggleButton>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                                <>
                                    <ToggleButton
                                        value="workshop"
                                        data-cy="selectWorkshop"
                                    >
                                        Workshop
                                    </ToggleButton>
                                    <ToggleButton
                                        value="sales"
                                        data-cy="selectSales"
                                    >
                                        Sales
                                    </ToggleButton>
                                    <ToggleButton
                                        value="online"
                                        data-cy="selectOnline"
                                    >
                                        Online Order
                                    </ToggleButton>
                                </>
                            ) : null}
                            <ToggleButton value="guest" data-cy="selectGuest">
                                Guest
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    {oldSale.status === 'Proforma' &&
                    oldSale.paymentLines.length > 0 ? (
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                The type of customer and the customer/entity
                                itself cannot be changed because payment(s) have
                                been received.
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>
            </Paper>
            <br />
            {selectedType === 'customer' && (
                <CustomerTable
                    readOnly={
                        readOnly ||
                        oldSale.status === 'Sale' ||
                        (oldSale.paymentLines.length > 0 &&
                            oldSale.status === 'Proforma')
                    }
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                    includeShippingDrawer={false}
                    associatedOnlineOrder={associatedOnlineOrder}
                    setAssociatedOnlineOrder={setAssociatedOnlineOrder}
                />
            )}
            {selectedType === 'contract' && (
                <VehicleContractForm
                    readOnly={
                        readOnly ||
                        oldSale.status === 'Sale' ||
                        (oldSale.paymentLines.length > 0 &&
                            oldSale.status === 'Proforma')
                    }
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                />
            )}
            {selectedType === 'vehicle' && (
                <VehicleForm
                    readOnly={
                        readOnly ||
                        oldSale.status === 'Sale' ||
                        (oldSale.paymentLines.length > 0 &&
                            oldSale.status === 'Proforma')
                    }
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                />
            )}
            {selectedType === 'service' && (
                <ServiceForm
                    readOnly={
                        readOnly ||
                        oldSale.status === 'Sale' ||
                        (oldSale.paymentLines.length > 0 &&
                            oldSale.status === 'Proforma')
                    }
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                />
            )}
            {selectedType === 'online' && (
                <CustomerTable
                    readOnly={
                        readOnly ||
                        oldSale.status === 'Sale' ||
                        (oldSale.paymentLines.length > 0 &&
                            oldSale.status === 'Proforma')
                    }
                    saleDetails={saleDetails}
                    setSaleDetails={setSaleDetails}
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                    includeShippingDrawer={true}
                    associatedOnlineOrder={associatedOnlineOrder}
                    setAssociatedOnlineOrder={setAssociatedOnlineOrder}
                />
            )}
        </>
    );
};

export default SelectCustomer;
