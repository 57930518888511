import { Step } from '../../interface';

export const RebateReconciliatorSteps: Step[] = [
    {
        label: 'In the sidebar, navigate to Admin -> Reconciliators -> Rebate Reconciliation to open the Rebate Reconciliation page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/rebateReconciliator/rebate-recon-01.png'
    },
    {
        label: 'The Rebate Reconciliation page displays four tables: Reconciled Rebate, Reconciled Vehicle Sale, Unreconciled Rebate, and Unreconciled Vehicle Sale. The Reconciled tables show lists of rebates and sales that will be reconciled when you submit the form. The Unreconciled tables list rebates and sales that are waiting to be reconciled.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/rebateReconciliator/rebate-recon-02.png'
    },
    {
        label: 'To reconcile an item in the Unreconciled Rebates table, click the up arrow icon on the right side of the row. This will move the rebate or sale to the corresponding Reconciled table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/rebateReconciliator/rebate-recon-03.png'
    },
    {
        label: 'To move a Vehicle Sale or a Rebate to Reconciled Ora must find a matching entry (if you move the Vehicle Sale it will look for the rebate and vice versa), Ora uses the Vehicle Stock Number to match Vehicle Sale and Vehicle Rebate. If it cannot find a match, an error message will be displayed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/rebateReconciliator/rebate-recon-04.png'
    },
    {
        label: 'If Ora finds a match, the rebate and sale will automatically be moved to the Reconciled tables. Ensure that these two matching records have the same stock number and amount. If the amounts are not equal, you cannot submit the reconciliation.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/rebateReconciliator/rebate-recon-05.png'
    },
    {
        label: (
            <>
                If you wish to add a rebate for that vehicle sale, create a
                rebate invoice via Admin - Enter Invoice. Refer to{' '}
                <a
                    href="/support/admin/enterInvoice/#enter-vehicle-rebate-invoice"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Enter Rebate Invoice
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/rebateReconciliator/rebate-recon-07.png'
    },
    {
        label: 'After moving all items to be reconciled into the Reconciled tables, click the "Save" button to submit the reconciliation.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/rebateReconciliator/rebate-recon-08.png'
    },
    {
        label: 'A success message "Reconciliation saved successfully" will appear if the reconciliation process is successful, and the reconciled rebates and sales will be removed from the Reconciled tables.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/rebateReconciliator/rebate-recon-09.png'
    }
];
