import api from '../../../../../../../api';
import CombineBalanceSheetLines from './CombineBalanceSheetLines';
import { BalanceSheetLine } from '../interface/BalanceSheetInterface';
import { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';

const CalculateBalanceSheet = (
    queryDate: Dayjs,
    setAllLines: Dispatch<SetStateAction<BalanceSheetLine[]>>,
    setShowSiteSelector: Dispatch<SetStateAction<boolean>>,
    setSingleLine: Dispatch<SetStateAction<BalanceSheetLine>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setProgress: Dispatch<SetStateAction<number>>,
    setProgressDetails: Dispatch<SetStateAction<string[]>>
) => {
    setLoading(true);

    let vehicleData = [];
    let inventoryOther = [];
    let floorplanLiability = [];
    let debtorPayment = [];
    let customerDeposit = [];
    let pendingPayrun = [];
    let accounts = [];
    let giftVoucher = [];

    let counter = 0;
    let progressArray = [];

    api.get(`balanceSheetNextGenInventoryVehicle?date=${queryDate}`).then(
        (res) => {
            counter += 1;
            setProgress((counter / 8) * 100);
            progressArray.push('Vehicle');
            setProgressDetails(progressArray);
            vehicleData = res.data;
            api.get(`balanceSheetNextGenInventoryOther?date=${queryDate}`).then(
                (res) => {
                    counter += 1;
                    setProgress((counter / 8) * 100);
                    progressArray.push('Stock');
                    setProgressDetails(progressArray);
                    inventoryOther = res.data;
                    api.get(
                        `balanceSheetNextGenFloorplanLiability?date=${queryDate}`
                    ).then((res) => {
                        counter += 1;
                        setProgress((counter / 8) * 100);
                        progressArray.push('Floorplan');
                        setProgressDetails(progressArray);
                        floorplanLiability = res.data;
                        api.get(
                            `balanceSheetNextGenDebtorPayment?date=${queryDate}`
                        ).then((res) => {
                            counter += 1;
                            setProgress((counter / 8) * 100);
                            progressArray.push('Debtor');
                            setProgressDetails(progressArray);
                            debtorPayment = res.data;
                            api.get(
                                `balanceSheetNextGenCustomerDeposit?date=${queryDate}`
                            ).then((res) => {
                                counter += 1;
                                setProgress((counter / 8) * 100);
                                progressArray.push('Deposit');
                                setProgressDetails(progressArray);
                                customerDeposit = res.data;
                                api.get(
                                    `balanceSheetNextGenPendingPayrun?date=${queryDate}`
                                ).then((res) => {
                                    counter += 1;
                                    setProgress((counter / 8) * 100);
                                    progressArray.push('Payrun');
                                    setProgressDetails(progressArray);
                                    pendingPayrun = res.data;
                                    api.get(
                                        `balanceSheetNextGenAccounts?date=${queryDate}`
                                    ).then((res) => {
                                        counter += 1;
                                        setProgress((counter / 8) * 100);
                                        progressArray.push('Accounts');
                                        setProgressDetails(progressArray);
                                        accounts = res.data;
                                        api.get(
                                            `balanceSheetNextGenGiftVoucher?date=${queryDate}`
                                        ).then((res) => {
                                            counter += 1;
                                            setProgress((counter / 8) * 100);
                                            progressArray.push('GiftVoucher');
                                            setProgressDetails(progressArray);
                                            giftVoucher = res.data;

                                            let balanceSheetLines = [];

                                            for (
                                                let i = 0;
                                                i < vehicleData.length;
                                                i++
                                            ) {
                                                balanceSheetLines.push({
                                                    SiteId: i + 1,
                                                    cashAtBankTotal: 0,
                                                    newVehiclesTotal:
                                                        vehicleData[i][0],
                                                    newVehiclesData:
                                                        vehicleData[i][1],
                                                    usedVehiclesTotal:
                                                        vehicleData[i][2],
                                                    usedVehiclesData:
                                                        vehicleData[i][3],
                                                    floorplanLiabilityTotal:
                                                        floorplanLiability[
                                                            i
                                                        ][0],
                                                    floorplanLiabilityData:
                                                        floorplanLiability[
                                                            i
                                                        ][1],
                                                    stockAdjustmentTotal: 0,
                                                    stockAdjustmentData: [],
                                                    averageCostAdjustmentTotal: 0,
                                                    averageCostAdjustmentData:
                                                        [],
                                                    stripeFeesTotal: 0,
                                                    stripeFeesData: [],
                                                    rebateBalanceTotal: 0,
                                                    rebateBalanceData: [],
                                                    registrationClearingTotal: 0,
                                                    registrationClearingData:
                                                        [],
                                                    accountLines: accounts[i],
                                                    cashHoldingTotal: 0,
                                                    stockOnHandTotal:
                                                        inventoryOther[i][0],
                                                    pendingDebtorPayment:
                                                        debtorPayment[i][0],
                                                    debtorPaymentData:
                                                        debtorPayment[i][1],
                                                    giftVoucherData: [],
                                                    giftVoucher: 0,
                                                    pendingPayrunData: [],
                                                    pendingPayrun: 0,
                                                    paymentLiabilityPA:
                                                        customerDeposit[i][0],
                                                    paymentLiabilityVehicleSale:
                                                        customerDeposit[i][1],
                                                    paymentLiabilityService:
                                                        customerDeposit[i][2],
                                                    paymentLiabilityDataPA:
                                                        customerDeposit[i][3],
                                                    paymentLiabilityDataVehicleSale:
                                                        customerDeposit[i][4],
                                                    paymentLiabilityDataService:
                                                        customerDeposit[i][5]
                                                });
                                                if (
                                                    i ===
                                                    vehicleData.length - 1
                                                ) {
                                                    balanceSheetLines[
                                                        i
                                                    ].pendingPayrunData =
                                                        pendingPayrun[1];
                                                    balanceSheetLines[
                                                        i
                                                    ].pendingPayrun =
                                                        pendingPayrun[0];
                                                    balanceSheetLines[
                                                        i
                                                    ].giftVoucherData =
                                                        giftVoucher[1];
                                                    balanceSheetLines[
                                                        i
                                                    ].giftVoucher =
                                                        giftVoucher[0];
                                                }
                                            }
                                            setShowSiteSelector(true);
                                            setLoading(false);
                                            setAllLines(balanceSheetLines);

                                            CombineBalanceSheetLines(
                                                balanceSheetLines,
                                                setSingleLine
                                            );
                                        });
                                    });
                                });
                            });
                        });
                    });
                }
            );
        }
    );

    //Promise.all(promiseArray).then(() => {});
};

export default CalculateBalanceSheet;
