// IMPORTS
import { Dispatch, SetStateAction } from 'react'
// LOGIC
import api from '../../../../api'
// INTERFACES
import { Account } from '../interfaces/AdminInterface'

export const GetAllAccountsCategorySpecific = (setAccounts: Dispatch<SetStateAction<Account[]>>, category: string) => {
    api.get('accountsCategorySpecific?category=' + (category ?? 'null')).then(res => {
        setAccounts(res.data)
    })
}