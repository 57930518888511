// IMPORTS
import { useState, useEffect } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { useParams, Prompt } from 'react-router-dom';
// COMPONENTS
import Fab from '../../global/Fab';
import Paper from '../../global/Paper';
import PageWrapper from '../../global/PageWrapper';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import GetServiceCode from './logic/GetServiceCode';
import HandleEditServiceCode from './logic/HandleEditServiceCode';
import HandleAddServiceCode from './logic/HandleAddServiceCode';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { ServiceCode } from '../../global/interfaces/ServiceInterface';

const ViewServiceCode = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const [shouldBlockNavigation, setShouldBlockNavigation] =
        useState<boolean>(false);

    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [serviceCode, setServiceCode] = useState<ServiceCode>({
        serviceCode: null,
        servicePrice: null,
        jobCardDescription: null,
        invoiceDescription: null,
        serviceDuration: null
    });

    useEffect(() => {
        if (id) {
            GetServiceCode(parseInt(id), setServiceCode);
        } else {
            setReadOnly(false);
        }
    }, [id]);

    const handleSubmit = () => {
        if (id) {
            if (readOnly) {
                setReadOnly(false);
                setShouldBlockNavigation(true);
            } else {
                HandleEditServiceCode(parseInt(id), serviceCode, showSnackbar);
            }
        } else {
            HandleAddServiceCode(serviceCode, showSnackbar);
        }
    };

    return (
        <PageWrapper>
            <Prompt
                when={shouldBlockNavigation}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <Typography variant="h4" align="left">
                {id ? serviceCode?.serviceCode : 'New Service Code'}
            </Typography>
            <br />

            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            disabled={readOnly}
                            label="Service Code"
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.serviceCode}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    serviceCode: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            disabled={readOnly}
                            label="Labour Charge"
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.servicePrice}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    servicePrice: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            type="number"
                            disabled={readOnly}
                            label="Labour Duration"
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.serviceDuration}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    serviceDuration: parseFloat(e.target.value)
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            multiline
                            rows={15}
                            disabled={readOnly}
                            label="Job Card Description"
                            helperText="This is the text that will be prefilled into the job card."
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.jobCardDescription}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    jobCardDescription: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            multiline
                            rows={15}
                            disabled={readOnly}
                            label="Customer Invoice Description"
                            helperText="This is the text that will be prefilled into the customer invoice."
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.invoiceDescription}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    invoiceDescription: e.target.value
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Fab editIcon={readOnly} onClick={() => handleSubmit()} />
        </PageWrapper>
    );
};

export default withSnackbar(ViewServiceCode);
