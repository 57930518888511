// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
// LOGIC
import GetCompanyDetails from '../databaseLogic/GetCompanyDetails';

interface PaymentMethodSelectorProps {
    paymentMethod: string;
    setPaymentMethod: Dispatch<SetStateAction<string>>;
    isCustomerDebtor: boolean;
    disabled?: boolean;
}

const PaymentMethodSelector = ({
    paymentMethod,
    setPaymentMethod,
    isCustomerDebtor,
    disabled
}: PaymentMethodSelectorProps) => {
    const [settings, setSettings] = useState(null);

    // Get the payment methods
    useEffect(() => {
        GetCompanyDetails(
            parseInt(localStorage.getItem('SiteId')),
            setSettings
        );
    }, []);

    return (
        <>
            <TextField
                fullWidth
                select
                disabled={disabled}
                size="small"
                label="Payment Method"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
            >
                {settings?.paymentMethods?.map((method) => {
                    if (method.value === 'account' && !isCustomerDebtor) {
                        return null;
                    } else {
                        return (
                            <MenuItem value={method.value}>
                                {method.name}
                            </MenuItem>
                        );
                    }
                })}
            </TextField>
        </>
    );
};

export default PaymentMethodSelector;
