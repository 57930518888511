import { Dispatch, SetStateAction } from 'react';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface AddVehicleToListParams {
    newVehicle: Vehicle;
    specificInputs: {
        makeInput: string;
        modelInput: string;
        seriesInput: string;
        fuelTypeInput: string;
    };
    listOfVehicles: Vehicle[];
    gstMethod: string;
    setNewVehicle: Dispatch<SetStateAction<Vehicle>>;
    setListOfVehicles: Dispatch<SetStateAction<Vehicle[]>>;
    setAddVehicleOpen: Dispatch<SetStateAction<boolean>>;
    setSpecificInputs: Dispatch<
        SetStateAction<{
            makeInput: string;
            modelInput: string;
            seriesInput: string;
            fuelTypeInput: string;
        }>
    >;
    showSnackbar: showSnackbar;
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>;
}

const AddBicyclesToList = async (params: AddVehicleToListParams) => {
    const requiredFields = ['Make', 'Model', 'condition', 'year', 'costPrice'];

    for (const field of requiredFields) {
        if (!params.newVehicle[field]) {
            params.showSnackbar(
                'Missing required fields!',
                `You must enter a ${field} for the vehicle.`,
                'error'
            );
            return;
        }
    }

    var oldList = params.listOfVehicles;

    if (oldList.length === 0) {
        params.newVehicle.id = 0;
    } else {
        params.newVehicle.id = oldList.at(-1)['id'] + 1;
    }
    params.newVehicle.freight = 0;
    params.newVehicle.gst = 0;

    params.gstMethod === 'Excluding'
        ? (params.newVehicle.gst =
              parseFloat(params.newVehicle.costPrice) * 0.1)
        : (params.newVehicle.gst =
              parseFloat(params.newVehicle.costPrice) -
              parseFloat(params.newVehicle.costPrice) / 1.1);

    oldList.push(params.newVehicle);
    params.setListOfVehicles(oldList);
    params.setAddVehicleOpen(false);

    params.setNewVehicle({
        Make: null,
        Model: null,
        Series: null,
        FuelType: null,
        year: null,
        cylinders: null,
        costPrice: null,
        registrationDueDate: null,
        complianceDate: '',
        vin: null,
        DriveType: null,
        BodyType: null,
        condition: 'New',
        engineNumber: '',
        learnerApproved: false,
        registrationNumber: '',
        transmission: 'Manual',
        odometer: null,
        engineSize: '',
        gears: null,
        priceRRP: null,
        adminLoadCost: null,
        trustReceipt: null,
        colour: null
    });
    params.setSpecificInputs({
        makeInput: '',
        modelInput: '',
        seriesInput: '',
        fuelTypeInput: ''
    });
    params.setShouldBlockNavigation(true);
};

export default AddBicyclesToList;
