// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Description,
    Dns,
    Edit,
    FolderOpen,
    Receipt,
    Save,
    Source,
    Textsms,
    MarkEmailRead,
    EditNote
} from '@mui/icons-material';
// INTERFACES
import { VehicleSale } from '../../../global/interfaces/VehicleSaleInterface';
import GetForm7 from '../logic/GetForm7';

const SpeedDialList = (
    readOnly: boolean,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    setLogsDrawerOpen: Dispatch<SetStateAction<boolean>>,
    saleDetails: VehicleSale,
    handleSubmitSale: () => void,
    isFinalised: boolean,
    setTextDrawerOpen: Dispatch<SetStateAction<boolean>>,
    handlePrintContract: (fillContract: boolean) => void,
    setTaxInvoiceDialogOpen: Dispatch<SetStateAction<boolean>>,
    setPrintLOADialogOpen: Dispatch<SetStateAction<boolean>>,
    setSignLOADialogOpen: Dispatch<SetStateAction<boolean>>,
    handleClickSignContract?: () => void
) => {
    let actionList = [];

    let editItem = {
        icon: <Edit />,
        name: 'Edit',
        onClick: () => {
            setReadOnly(false);
        }
    };
    let editItemPermission = {
        icon: <Edit />,
        name: 'Edit',
        onClick: () => {
            setReadOnly(false);
        },
        permission: 'edit_finaliseDateVehicleSale'
    };
    let saveItem = {
        icon: <Save />,
        name: 'Save',
        onClick: () => handleSubmitSale()
    };
    let textItem = {
        icon: <Textsms />,
        name: 'Text Customer',
        onClick: () => setTextDrawerOpen(true)
    };
    let filledContractItem = {
        icon: <Source />,
        name: 'Filled Contract',
        onClick: () => handlePrintContract(true)
    };
    let blankContractItem = {
        icon: <FolderOpen />,
        name: 'Blank Contract',
        onClick: () => handlePrintContract(false)
    };
    let taxInvoiceItem = {
        icon: <Receipt />,
        name: 'Tax Invoice',
        onClick: () => setTaxInvoiceDialogOpen(true)
    };

    let logsItem = {
        icon: <Dns />,
        name: 'Logs',
        onClick: () => setLogsDrawerOpen(true)
    };
    let form7Item = {
        icon: <Description />,
        name: 'Form 7',
        onClick: () => GetForm7(saleDetails)
    };
    let printLOAItem = {
        icon: <MarkEmailRead />,
        name: 'Print LOA',
        onClick: () => setPrintLOADialogOpen(true)
    };

    let signLOAItem = {
        icon: <EditNote />,
        name: 'Sign LOA',
        onClick: () => setSignLOADialogOpen(true)
    };

    let signContractItem = {
        icon: <Description />,
        name: 'Sign Contract',
        onClick: () => handleClickSignContract()
    };

    if (isFinalised && readOnly) {
        actionList.push(editItemPermission);
    } else if (readOnly) {
        actionList.push(editItem);
    } else {
        actionList.push(saveItem);
    }

    if (saleDetails?.id) {
        if (readOnly) {
            actionList.push(taxInvoiceItem);
            actionList.push(signContractItem);
            actionList.push(filledContractItem);
            actionList.push(blankContractItem);
            actionList.push(printLOAItem);
            actionList.push(signLOAItem);
        }

        actionList.push(textItem);
        actionList.push(logsItem);

        if (
            saleDetails.Site.state === 'NSW' &&
            saleDetails.saleStatus === 'Finalised'
        ) {
            actionList.push(form7Item);
        }
    }

    return actionList;
};

export default SpeedDialList;
