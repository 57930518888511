import api from '../../../../../api';
import moment from 'moment';
import { User } from '../../../global/interfaces/GeneralInterface';
import { Dayjs } from 'dayjs';

const ExportToCsv = (
    selectedUser: User[],
    exportPeriod: { startDate: Dayjs; endDate: Dayjs }
) => {
    // Create an array of just the user ids for the backend
    let userArray = selectedUser.map((user) => {
        return user.id;
    });

    // Create an object for the backend
    let queryParams = {
        startDate: exportPeriod.startDate.toISOString(),
        endDate: exportPeriod.endDate.toISOString(),
        selectedUser: userArray
    };

    api.get(`timeclockReportNextGen`, { params: queryParams }).then((res) => {
        let timeClockData = res.data;

        var rows = [];

        rows.push(['Name', 'Date', 'Time On', 'Time Off', 'Total Hours']);

        selectedUser.forEach((user) => {
            timeClockData.forEach((day) => {
                if (day.User.id === user.id) {
                    let clockOn = moment(day.clockOn, 'HH:mm:ss a');
                    let clockOff = moment(day.clockOff, 'HH:mm:ss a');
                    let timeDiff = clockOff.diff(clockOn, 'minutes');

                    let row = [
                        `${day.User.firstName} ${day.User.lastName}`,
                        day.date,
                        day.clockOn ? clockOn.format('HH:mm') : null,
                        day.clockOff ? clockOff.format('HH:mm') : null,
                        day.clockOn && day.clockOff ? timeDiff / 60 : null
                    ];
                    rows.push(row);
                }
            });
        });

        let csvContent = 'data:text/csv;charset=utf-8,';
        rows.forEach(function (rowArray) {
            let row = rowArray.join(',');
            csvContent += row + '\r\n';
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute(
            'download',
            `TimeClock_${moment(queryParams.startDate).format('DD_MM_YYYY')}_to_${moment(queryParams.endDate).format('DD_MM_YYYY')}`
        );
        document.body.appendChild(link);
        link.click();
    });
};

export default ExportToCsv;
