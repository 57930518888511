// IMPORTS
import { useEffect, useState } from 'react';
import {
    Box,
    Chip,
    Collapse,
    Divider,
    Grid,
    IconButton,
    TableRow,
    Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';

// LOGIC
import GetLogs from '../../../global/logic/GetLogs';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import {
    MiscTotal,
    PartTotal,
    PaymentTotal,
    ServiceBalance
} from '../logic/GetTotals';
import { isNumber } from '../../../global/logic/globalValidation/NumericalValidation';
interface LogsDrawerContentProps {
    id: number;
}

const LogsDrawerContent = ({ id }: LogsDrawerContentProps) => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        GetLogs('service', id, setLogs);
    }, [id]);

    const controlCollapse = (log, index, selector) => {
        let currentLogs = [...logs];
        currentLogs[index][selector] = !currentLogs[index][selector];
        setLogs(currentLogs);
    };

    const isInfoChanged = (
        currentLogService,
        previousLogService,
        fieldName
    ) => {
        return currentLogService[fieldName] !== previousLogService[fieldName];
    };

    const renderChip = (
        value,
        chipColor: 'default' | 'primary' | 'error' | 'success' = 'default'
    ) => (
        <Chip
            label={isNumber(value) ? `#${value}` : value}
            size="small"
            color={chipColor}
        />
    );

    const renderInfoTypography = (label, value, renderComponent) => (
        <Box mb={1}>
            <Typography
                variant="body1"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5em'
                }}
            >
                {label}: {renderComponent(value)}
            </Typography>
        </Box>
    );

    const renderInfoComponent = (
        currentLogService,
        previousLogService,
        fieldName
    ) => {
        const hasInfoChanged = isInfoChanged(
            currentLogService,
            previousLogService,
            fieldName
        );
        const currentValue = currentLogService[fieldName];
        const previousValue = previousLogService[fieldName];

        return hasInfoChanged ? (
            <span style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                {previousValue !== null && (
                    <>
                        {renderChip(previousValue)}
                        <ArrowForwardIcon fontSize="small" color="success" />
                    </>
                )}
                {renderChip(currentValue, 'success')}
            </span>
        ) : (
            <>{isNumber(currentValue) ? `#${currentValue}` : currentValue}</>
        );
    };

    const calculatePriceChanged = (currentLog, previousLog, type) => {
        switch (type) {
            case 'totalPrice':
                return (
                    currentLog.Service.totalPrice -
                    previousLog.Service.totalPrice
                );
            case 'partsTotal':
                return (
                    PartTotal(currentLog.Service, currentLog.partSales)
                        .partsTotal -
                    PartTotal(previousLog.Service, previousLog.partSales)
                        .partsTotal
                );
            case 'subletTotal':
                return (
                    currentLog.Service?.subletTotal -
                    previousLog.Service?.subletTotal
                );
            case 'MiscTotal':
                return (
                    MiscTotal(currentLog.Service) -
                    MiscTotal(previousLog.Service)
                );
            case 'labourCost':
                return (
                    currentLog.Service?.labourCost -
                    previousLog.Service?.labourCost
                );
            case 'GST':
                return (
                    parseFloat(currentLog.Service?.totalPrice) / 11 -
                    parseFloat(previousLog.Service?.totalPrice) / 11
                );

            case 'Subtotal':
                return (
                    currentLog.Service?.totalPrice -
                    previousLog.Service?.totalPrice
                );
            case 'Paid':
                return (
                    PaymentTotal(currentLog.Service?.paymentLines) -
                    PaymentTotal(previousLog.Service?.paymentLines)
                );

            case 'Balance':
                return (
                    ServiceBalance(currentLog.Service) -
                    ServiceBalance(previousLog.Service)
                );
            default:
                return 0;
        }
    };

    const renderPriceChangeComponent = (currentLog, previousLog, type) => {
        const priceChange = calculatePriceChanged(
            currentLog,
            previousLog,
            type
        );
        if (priceChange !== 0) {
            return (
                <Chip
                    icon={
                        priceChange > 0 ? (
                            <ArrowUpwardIcon style={{ fontSize: 10 }} />
                        ) : (
                            <ArrowDownwardIcon style={{ fontSize: 10 }} />
                        )
                    }
                    label={`${priceChange.toFixed(0)}`}
                    size="small"
                    color={priceChange > 0 ? 'success' : 'error'}
                    sx={{ height: '15px', marginLeft: '2px' }}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Grid container gap={3}>
            {logs.length > 0 ? (
                logs?.map((log, index) => (
                    <Grid item key={index} xs={12} md={12} lg={12}>
                        <Box mb={1}>
                            <Typography variant="h6">
                                <b>
                                    Updated on {DateFormatter(log.date)} by{' '}
                                    {log.UserName}
                                </b>
                            </Typography>
                        </Box>
                        {renderInfoTypography(
                            'Job Type',
                            log.Service?.jobType,
                            (value) =>
                                index < logs.length - 1
                                    ? renderInfoComponent(
                                          log.Service,
                                          logs[index + 1].Service,
                                          'jobType'
                                      )
                                    : value
                        )}

                        {renderInfoTypography(
                            'Status',
                            log.Service?.jobType,
                            (value) =>
                                index < logs.length - 1
                                    ? renderInfoComponent(
                                          log.Service,
                                          logs[index + 1].Service,
                                          'serviceComplete'
                                      )
                                    : value
                        )}

                        {log.Service?.CustomerId &&
                            renderInfoTypography(
                                'Customer',
                                log.Service?.CustomerId,
                                (value) =>
                                    index < logs.length - 1
                                        ? renderInfoComponent(
                                              log.Service,
                                              logs[index + 1].Service,
                                              'CustomerId'
                                          )
                                        : value
                            )}

                        {log.Service?.InsurerId &&
                            renderInfoTypography(
                                'Insurer',
                                log.Service?.InsurerId,
                                (value) =>
                                    index < logs.length - 1
                                        ? renderInfoComponent(
                                              log.Service,
                                              logs[index + 1].Service,
                                              'InsurerId'
                                          )
                                        : value
                            )}

                        {log.Service?.CustomerVehicleId
                            ? renderInfoTypography(
                                  'Customer Vehicle',
                                  log.Service?.CustomerVehicleId,
                                  (value) =>
                                      index < logs.length - 1
                                          ? renderInfoComponent(
                                                log.Service,
                                                logs[index + 1].Service,
                                                'CustomerVehicleId'
                                            )
                                          : value
                              )
                            : renderInfoTypography(
                                  'Stock Vehicle',
                                  log.Service?.VehicleId,
                                  (value) =>
                                      index < logs.length - 1
                                          ? renderInfoComponent(
                                                log.Service,
                                                logs[index + 1].Service,
                                                'VehicleId'
                                            )
                                          : value
                              )}

                        <Box mb={1}>
                            <Typography
                                variant="body1"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5em'
                                }}
                            >
                                Service Total:{' '}
                                {CurrencyFormatter(log.Service?.totalPrice)}
                                {index < logs.length - 1 &&
                                    renderPriceChangeComponent(
                                        log,
                                        logs[index + 1],
                                        'totalPrice'
                                    )}
                            </Typography>
                        </Box>
                        <Typography variant="subtitle1">
                            <b>Totals</b>
                            <IconButton
                                onClick={() =>
                                    controlCollapse(log, index, 'partsOpen')
                                }
                            >
                                {log.partsOpen ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </IconButton>
                        </Typography>

                        <Collapse
                            in={log.partsOpen}
                            timeout="auto"
                            unmountOnExit
                        >
                            <DataTable columns={[]}>
                                <TableRow>
                                    <DataCell align="left">Parts</DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            PartTotal(
                                                log.Service,
                                                log.partSales
                                            ).partsTotal
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'partsTotal'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">Sublets</DataCell>

                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            log.Service?.subletTotal
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'subletTotal'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        Miscellaneous
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            MiscTotal(log.Service)
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'MiscTotal'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">Labor</DataCell>

                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            log.Service?.labourCost
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'labourCost'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b>GST</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            parseFloat(
                                                log.Service?.totalPrice
                                            ) / 11
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'GST'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b>Subtotal</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            log.Service?.totalPrice
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'Subtotal'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b>Paid</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            PaymentTotal(
                                                log.Service?.paymentLines
                                            )
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'Paid'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b>Balance</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        <b>
                                            {CurrencyFormatter(
                                                ServiceBalance(log.Service)
                                            )}
                                            {index < logs.length - 1 &&
                                                renderPriceChangeComponent(
                                                    log,
                                                    logs[index + 1],
                                                    'Balance'
                                                )}
                                        </b>
                                    </DataCell>
                                </TableRow>
                            </DataTable>
                        </Collapse>
                        <br />
                        <Divider />
                    </Grid>
                ))
            ) : (
                <Typography variant="body1">No logs recorded.</Typography>
            )}
        </Grid>
    );
};

export default LogsDrawerContent;
