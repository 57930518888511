//AIO Client-side Validation Helpers

const isNonZeroNumber = (value) => {
    if (value === '') return false;
    if (value === undefined) return false;
    if (value === null) return false;
    let numbersRegex = new RegExp(/^[0-9]*$/);
    if (numbersRegex.test(String(value)) === false) return false;
    if (parseFloat(value) === 0) return false;
    return true;
};

const isNumber = (value) => {
    if (value === '') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    let numbersRegex = new RegExp(/^-?[0-9]*$/);
    if (numbersRegex.test(String(value)) === false) return false;
    return true;
};

const isPositiveNumber = (value) => {
    if (value === '') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    let numbersRegex = new RegExp(/^-?[0-9]*$/);
    if (numbersRegex.test(String(value)) === false) return false;
    return true;
};

const isNegativeNumber = (value) => {
    if (value === '') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    let numbersRegex = new RegExp(/^-[0-9]*$/);
    if (numbersRegex.test(String(value)) === false) return false;
    return true;
};

const isFloat = (value) => {
    if (value === '') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    let numbersRegex = new RegExp(/^-?\d+(\.\d+)?$/);
    if (numbersRegex.test(String(value)) === false) return false;
    return true;
};

const isPositiveFloat = (value) => {
    if (value === '') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    let numbersRegex = new RegExp(/^\d+(\.\d+)?$/);
    if (numbersRegex.test(String(value)) === false) return false;
    return true;
};

const isNegativeFloat = (value) => {
    if (value === '') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    let numbersRegex = new RegExp(/^-\d+(\.\d+)?$/);
    if (numbersRegex.test(String(value)) === false) return false;
    return true;
};

const isCurrency = (value) => {
    if (value === '') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    let numbersRegex = new RegExp(/^-?\d*[.,]?\d{0,2}$/);
    if (numbersRegex.test(String(value)) === false) return false;
    return true;
};

const isPositiveCurrency = (value) => {
    if (value === '') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    let numbersRegex = new RegExp(/^\d*[.,]?\d{0,2}$/);
    if (numbersRegex.test(String(value)) === false) return false;
    return true;
};

const isNegativeCurrency = (value) => {
    if (value === '') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    let numbersRegex = new RegExp(/^-\d*[.,]?\d{0,2}$/);
    if (numbersRegex.test(String(value)) === false) return false;
    return true;
};

const isEmpty = (value) => {
    if (value === '') return true;
    if (value === null) return true;
    return false;
};

const isZero = (value) => {
    if (Number(value) === 0) return true;
    return false;
};

const isNonZeroFloat = (value) => {
    if (value === '') return false;
    if (value === undefined) return false;
    if (value === null) return false;
    let numbersRegex = new RegExp(/^-?\d*[.]?\d*$/);
    if (numbersRegex.test(String(value)) === false) return false;
    if (parseFloat(value) === 0) return false;
    return true;
};

/**
 * Field Validation Reducer
 *
 * @param {object} fieldData - Object containing the current state
 * @return {boolean} returns true if error is flagged in state.
 **/

const isAnyErrors = (fieldData) => {
    for (const value of fieldData) {
        if (value.error === true) return true;
    }
    return false;
};

/**
 * Field Validation Reducer
 *
 * @param {object} state - Object containing the current state
 * 
 * @param {object} action - Object containing the dispatch object for state change
 * @example Directly update the state for key "suppliers" with incoming payLoad
 * {
        type: "SET", 
        key: "suppliers",  
        payLoad: {error: true, message: "Please select at least 1 option"}
    }
 * 
 * @return {object} containing the updated state to render
**/

const FieldValidationReducer = (state, action) => {
    let isValid = false;
    let message = '';
    if (action.required && isEmpty(action.payLoad)) {
        message = 'Field is required.';
        return {
            ...state,
            ...{
                [action.key]: { error: true, message: message },
                changed: true
            }
        };
    }
    switch (action.type) {
        case 'SET':
            return {
                ...state,
                ...{ [action.key]: { ...action.payLoad }, changed: true }
            };
        case 'NUMBER':
            isValid = isNumber(action.payLoad);
            if (!isValid) message = 'Value is not a valid number';
            return {
                ...state,
                ...{
                    [action.key]: { error: !isValid, message: message },
                    changed: true
                }
            };
        case 'POSITIVE_NUMBER':
            isValid = isPositiveNumber(action.payLoad);
            if (!isValid) message = 'Value is not a valid positive number';
            return {
                ...state,
                ...{
                    [action.key]: { error: !isValid, message: message },
                    changed: true
                }
            };
        case 'NEGATIVE_NUMBER':
            isValid = isNegativeNumber(action.payLoad);
            if (!isValid) message = 'Value is not a valid negative number';
            return {
                ...state,
                ...{
                    [action.key]: { error: !isValid, message: message },
                    changed: true
                }
            };
        case 'FLOAT':
            isValid = isFloat(action.payLoad);
            if (!isValid) message = 'Value is not a valid input.';
            return {
                ...state,
                ...{
                    [action.key]: { error: !isValid, message: message },
                    changed: true
                }
            };
        case 'POSITIVE_FLOAT':
            isValid = isPositiveFloat(action.payLoad);
            if (!isValid) message = 'Value is not a valid input.';
            return {
                ...state,
                ...{
                    [action.key]: { error: !isValid, message: message },
                    changed: true
                }
            };

        case 'NEGATIVE_FLOAT':
            isValid = isNegativeFloat(action.payLoad);
            if (!isValid) message = 'Value is not a valid input.';
            return {
                ...state,
                ...{
                    [action.key]: { error: !isValid, message: message },
                    changed: true
                }
            };
        case 'CURRENCY':
            isValid = isCurrency(action.payLoad);
            if (!isValid) message = 'Value is not a valid currency value';
            return {
                ...state,
                ...{
                    [action.key]: { error: !isValid, message: message },
                    changed: true
                }
            };

        case 'POSITIVE_CURRENCY':
            isValid = isPositiveCurrency(action.payLoad);
            if (!isValid) message = 'Value is not a valid currency value';
            return {
                ...state,
                ...{
                    [action.key]: { error: !isValid, message: message },
                    changed: true
                }
            };

        case 'NEGATIVE_CURRENCY':
            isValid = isNegativeCurrency(action.payLoad);
            if (!isValid) message = 'Value is not a valid currency value';
            return {
                ...state,
                ...{
                    [action.key]: { error: !isValid, message: message },
                    changed: true
                }
            };
        case 'URL':
            //TODO
            break;
        case 'EMAIL':
            //TODO
            break;
        default:
            return {
                ...state,
                ...{
                    [action.key]: { error: false, message: '' },
                    changed: true
                }
            };
    }
};

export {
    isNumber,
    isPositiveNumber,
    isNegativeNumber,
    isNonZeroNumber,
    isFloat,
    isPositiveFloat,
    isNegativeFloat,
    isCurrency,
    isPositiveCurrency,
    isNegativeCurrency,
    isEmpty,
    isNonZeroFloat,
    isAnyErrors,
    isZero,
    FieldValidationReducer
    // validateArrayOfObjects,
    // isFloat,
};
