import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Autocomplete from './Autocomplete';

import api from '../../../api';
import { Creditor } from './interfaces/GeneralInterface';

interface CreditorSelectorParams {
  fullWidth?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  isDisabled?: boolean;
  label?: string;
  size?: 'small' | 'medium';
  selectedSupplier: number;
  setSelectedSupplier: Dispatch<SetStateAction<number>>;
}

const CreditorSelector = ({
  fullWidth,
  required,
  error,
  helperText,
  isDisabled,
  label,
  size,
  selectedSupplier,
  setSelectedSupplier
}: CreditorSelectorParams) => {
  const [creditorList, setCreditorList] = useState([]);
  const [holdingSupplier, setHoldingSupplier] = useState<Creditor>();

  useEffect(() => {
    api.get('/accounts/supplier').then((res) => {
      let creditorData = res.data;
      setCreditorList(creditorData);
    });
  }, []);

  return (
    <Autocomplete
      size={size ?? 'small'}
      isRequired={required ?? false}
      options={creditorList}
      useTwoOptionLabels={false}
      primaryOptionLabel="name"
      textfieldLabel="Creditor"
      isDisabled={isDisabled}
      selectedValue={holdingSupplier}
      handleSelectedValueChange={(newValue) => {
        setHoldingSupplier(newValue);
        setSelectedSupplier(newValue.id);
      }}
      handleInputValueChange={() => null}
    />
  );
};

export default CreditorSelector;
