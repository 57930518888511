// IMPORTS
import { useEffect, useState } from 'react';
import {
    CircularProgress,
    Dialog,
    Fab,
    Grid,
    Pagination,
    TableRow,
    TextField
} from '@mui/material';
import { Search, UploadFile } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import FileEditorContent from './FileEditorContent';
import TransactionsTable from './TransactionsTable';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
// LOGIC
import GetTransactionFiles from '../../logic/BankTransactions/GetTransactionFiles';
import ImportTransactionFile from '../../logic/BankTransactions/ImportTransactionFile';
// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const BankTransactions = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [transactionFiles, setTransactionFiles] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [importFile, setImportFile] = useState(null);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
    const [fileName, setFileName] = useState<string>('');
    const [searchString, setSearchString] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const limit = 10;

    const columns = [
        { id: 0, label: 'Transactions', with: 100 },
        { id: 1, label: 'ID - File Name', width: 300 }
    ];

    useEffect(() => {
        GetTransactionFiles(
            false,
            searchString,
            currentPage,
            limit,
            setTotalPages,
            setCurrentPage,
            setDisabled,
            setTransactionFiles
        );
        // eslint-disable-next-line
    }, [currentPage]);

    const handlePageChange = (value: number) => {
        setCurrentPage(value - 1);
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            disabled={disabled}
                            size="small"
                            label="Search File Name / ID"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                startAdornment: <Search color="disabled" />
                            }}
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !disabled) {
                                    GetTransactionFiles(
                                        true,
                                        searchString,
                                        currentPage,
                                        limit,
                                        setTotalPages,
                                        setCurrentPage,
                                        setDisabled,
                                        setTransactionFiles
                                    );
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {disabled ? <CircularProgress /> : null}
                    </Grid>
                    <Grid item xs={7}>
                        <Pagination
                            disabled={disabled}
                            count={totalPages}
                            page={currentPage + 1}
                            onChange={(e, page) => handlePageChange(page)}
                            color="primary"
                            sx={{ display: 'flex', justifyContent: 'right' }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Paper>
                        <DataTable columns={columns}>
                            {transactionFiles.map((line, index) => (
                                <TableRow
                                    selected={index === selectedIndex}
                                    onClick={() => setSelectedIndex(index)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <DataCellColoured
                                        handleClick={() =>
                                            setSelectedIndex(index)
                                        }
                                    >
                                        {line.transactions.length}
                                    </DataCellColoured>
                                    <DataCell>
                                        {'ID: ' +
                                            line.file.id +
                                            ' - ' +
                                            line.file.fileName.replaceAll(
                                                '%20',
                                                ' '
                                            )}
                                    </DataCell>
                                </TableRow>
                            ))}
                        </DataTable>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <TransactionsTable
                        transactionFiles={transactionFiles}
                        setTransactionFiles={setTransactionFiles}
                        selectedIndex={selectedIndex}
                        showSnackbar={showSnackbar}
                    />
                </Grid>
            </Grid>
            <br />
            <br />
            <input
                accept=".csv"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                value=""
                onChange={(e) =>
                    ImportTransactionFile(
                        e.target.files[0],
                        setImportFile,
                        setSelectedHeaders,
                        showSnackbar,
                        setFileName
                    )
                }
            />
            <label htmlFor="raised-button-file">
                <div
                    style={{
                        margin: 0,
                        top: 'auto',
                        right: 20,
                        bottom: 20,
                        left: 'auto',
                        position: 'fixed',
                        zIndex: 1
                    }}
                >
                    <Fab color="primary" aria-label="add" component="span">
                        <UploadFile />
                    </Fab>
                </div>
            </label>

            <Dialog
                open={importFile !== null}
                onClose={() => setImportFile(null)}
                maxWidth="xl"
                fullWidth
            >
                <FileEditorContent
                    fileName={fileName}
                    importFile={importFile}
                    setImportFile={setImportFile}
                    selectedHeaders={selectedHeaders}
                    setSelectedHeaders={setSelectedHeaders}
                    showSnackbar={showSnackbar}
                />
            </Dialog>
        </>
    );
};

export default withSnackbar(BankTransactions);
