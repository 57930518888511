// IMPORTS
import {
    Autocomplete,
    Chip,
    TextField,
    Typography,
    useTheme
} from '@mui/material';

interface AutocompleteMultipleProps {
    options: any[]; // The array containing the options in the dropdown
    fullWidth?: boolean; // Change whether the component is fullWidth or not (default to false)
    size?: 'small' | 'medium'; // Change the size of the component, default to small
    useTwoOptionLabels: boolean; // Whether you want to display one or two options in the dropdown label
    primaryOptionLabel: string; // The primary option for the dropdown label
    secondaryOptionLabel?: string; // If useTwoOptionLabels is true, the secondary option for the dropdown label
    textfieldLabel: string; // Label that displays on the textfield component
    textfieldVariant?: any; // Outlined or Standard
    isError?: boolean; // Textfield error prop
    helperText?: string; // Textfield helperText prop
    isRequired?: boolean; // Is a value required for submission?
    isDisabled?: boolean; // Is the component disabled?
    selectedValues: any; // The currently-rendered values
    includeBar?: boolean; // If using two option label values to have them seperated by a bar (if not just uses space)
    inputValue?: string; // This is only used when we want to start being able to add items through this field (currently does not handle this)
    isFreeSolo?: boolean; // If true, removes the dropdown functionality
    notClearable?: boolean; // If true, removes the 'clear all' button
    handleSelectedValueChange: (newValue: any, event: any) => any;
    handleInputValueChange?: (newInputValue: any, event: any) => any;
    cypressLabel?: string;
}

const AutocompleteMultiple = ({
    options,
    fullWidth = false,
    size = 'small',
    selectedValues,
    handleInputValueChange,
    includeBar,
    handleSelectedValueChange,
    inputValue,
    primaryOptionLabel,
    secondaryOptionLabel,
    useTwoOptionLabels,
    textfieldLabel,
    textfieldVariant = 'outlined',
    isError,
    helperText,
    isRequired,
    isDisabled,
    isFreeSolo,
    notClearable,
    cypressLabel
}: AutocompleteMultipleProps) => {
    const currentMode = useTheme().palette.mode;
    return (
        <Autocomplete
            data-cy={cypressLabel ? cypressLabel : ''}
            size={size}
            multiple
            fullWidth={fullWidth}
            autoHighlight
            freeSolo={isFreeSolo}
            disabled={isDisabled}
            disableClearable={notClearable}
            options={options ? options : []}
            value={selectedValues}
            inputValue={inputValue}
            onChange={(event, newValue) => {
                handleSelectedValueChange(newValue, event);
            }}
            onInputChange={(event, newInputValue) =>
                handleInputValueChange(newInputValue, event)
            }
            getOptionLabel={(option) =>
                useTwoOptionLabels
                    ? option[primaryOptionLabel] +
                          ' - ' +
                          option[secondaryOptionLabel] || ''
                    : option[primaryOptionLabel] || ''
            }
            renderOption={(props, option) => {
                return useTwoOptionLabels ? (
                    <Typography
                        {...props}
                        sx={{ display: 'flex' }}
                        data-cy={
                            cypressLabel ? `${cypressLabel}-${option?.id}` : ''
                        }
                    >
                        <span>{option[primaryOptionLabel]}</span>&nbsp;-&nbsp;
                        <span
                            style={{
                                color:
                                    currentMode === 'dark'
                                        ? '#C0BBBB'
                                        : '#505050',
                                fontSize: '14px'
                            }}
                        >
                            {option[secondaryOptionLabel]}
                        </span>
                    </Typography>
                ) : (
                    <Typography
                        {...props}
                        sx={{ display: 'flex' }}
                        data-cy={
                            cypressLabel ? `${cypressLabel}-${option?.id}` : ''
                        }
                    >
                        <span>{option[primaryOptionLabel]}</span>
                    </Typography>
                );
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        variant="filled"
                        size="small"
                        label={
                            useTwoOptionLabels
                                ? includeBar
                                    ? option[primaryOptionLabel] +
                                      ' | ' +
                                      option[secondaryOptionLabel]
                                    : option[primaryOptionLabel] +
                                      ' ' +
                                      option[secondaryOptionLabel]
                                : option[primaryOptionLabel]
                        }
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={textfieldLabel}
                    InputLabelProps={{ shrink: true }}
                    variant={textfieldVariant}
                    error={isError}
                    disabled={isDisabled}
                    helperText={isError ? helperText : null}
                    required={isRequired}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true
                    }}
                />
            )}
        />
    );
};

export default AutocompleteMultiple;
