import DocumentationPage from '../DocumentationPage';
import {
    ManageBookingsSteps,
    EditRetailBookingSteps
} from '../data/service/bookings';

const BookingsSupportPage = () => {
    const contentSections = [
        {
            id: 'bookings',
            title: 'Manage service bookings',
            href: '/support/service/bookings/#bookings',
            description:
                'To manage existing service bookings, follow these outlined steps:',
            steps: ManageBookingsSteps
        },
        {
            id: 'edit-retail-booking',
            title: 'Edit a service booking',
            href: '/support/service/bookings/#edit-retail-booking',
            description:
                'To edit an existing booking, follow these outlined steps:',
            steps: EditRetailBookingSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Bookings"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default BookingsSupportPage;
