import { Dispatch, SetStateAction } from 'react';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';

const RemoveVehicle = (
    vehicle: Vehicle,
    listOfVehicles: Vehicle[],
    setListOfVehicles: Dispatch<SetStateAction<Vehicle[]>>
) => {
    setListOfVehicles(listOfVehicles.filter((x) => x.id !== vehicle.id));
};

export default RemoveVehicle;
