import { useForm, Controller } from 'react-hook-form';
import {
    Typography,
    Button,
    Paper,
    Grid,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import { USE_PURPOSE_OPTIONS } from '../../../esign/DefaultFormValues';
import { DialogTitle } from '@material-ui/core';
import { useState } from 'react';

const DealerLOAOrgForm = ({
    formData,
    setFormData,
    onDealerLoaFormSubmit,
    customer
}) => {
    const { control, watch, handleSubmit } = useForm({
        defaultValues: {
            ...formData
        }
    });

    const [hasPreviewed, setHasPreviewed] = useState(false);

    // when click 'Preview Changes' button, display the form data on corresponding PDF fields
    const onPreviewChanges = async (data) => {
        await onDealerLoaFormSubmit(data, 'preview');
        setHasPreviewed(true);
    };

    // when click 'Email Customer' button, open a dialog to confirm sending email to the customer
    const [openEmailDialog, setOpenEmailDialog] = useState(false);

    const onOpenEmailDialog = () => {
        setOpenEmailDialog(true);
    };

    const handleClickSendEmail = async (data) => {
        await onDealerLoaFormSubmit(data, 'upload');
    };

    return (
        <>
            <Paper elevation={3} sx={{ padding: 3 }}>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                1. Vehicle details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="commonDueDate"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            label="Common Due Date (if applicable)"
                                            value={dayjs(field.value)}
                                            onChange={field.onChange}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: false
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/* // 2. Registered operator/s details */}
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                2. Registered operator/s details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    The registered operator is:
                                </Typography>
                                <Controller
                                    name="operatorOption"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            name="operatorOption"
                                        >
                                            <FormControlLabel
                                                value="company"
                                                control={<Radio />}
                                                label="Registered company"
                                            />
                                            <FormControlLabel
                                                value="dealer"
                                                control={<Radio />}
                                                label="Licenced motor dealer"
                                            />
                                            <FormControlLabel
                                                value="business"
                                                control={<Radio />}
                                                label="Registered business"
                                            />
                                            <FormControlLabel
                                                value="trust"
                                                control={<Radio />}
                                                label="Trust"
                                            />
                                            <FormControlLabel
                                                value="government"
                                                control={<Radio />}
                                                label="Government body"
                                            />
                                            <FormControlLabel
                                                value="incorporated"
                                                control={<Radio />}
                                                label="Incorporated association"
                                            />
                                            <FormControlLabel
                                                value="other"
                                                control={<Radio />}
                                                label="Other"
                                            />
                                        </RadioGroup>
                                    )}
                                />
                            </Grid>
                            {watch('operatorOption') === 'other' && (
                                <Grid item xs={12}>
                                    <Controller
                                        name="otherTypeValue"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Please specify"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name="organisationACN"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Organisation’s registration number (for example, the ACN)"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={10}>
                            <Controller
                                name="vehicleGarageAddress"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Vehicle garage address (must be in Qld, if the same as residential address write ‘as above’)"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controller
                                name="vehicleGaragePostcode"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Postcode"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                name="hasSecondOperator"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                            />
                                        }
                                        label={
                                            <Typography variant="body1">
                                                Second Registered Operator (if
                                                applicable)
                                            </Typography>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {watch('hasSecondOperator') && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        Role type:
                                    </Typography>
                                    <Controller
                                        name="roleTypeOption"
                                        control={control}
                                        render={({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                row
                                                aria-label="roleTypeOption"
                                                name="roleTypeOption"
                                            >
                                                <FormControlLabel
                                                    value="operator"
                                                    control={<Radio />}
                                                    label="Registered operator"
                                                />
                                                <FormControlLabel
                                                    value="agent"
                                                    control={<Radio />}
                                                    label="Paying agent"
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        The registered operator is:
                                    </Typography>
                                    <Controller
                                        name="secondOperatorOption"
                                        control={control}
                                        render={({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                row
                                                name="secondOperatorOption"
                                            >
                                                <FormControlLabel
                                                    value="company"
                                                    control={<Radio />}
                                                    label="Registered company"
                                                />
                                                <FormControlLabel
                                                    value="individual"
                                                    control={<Radio />}
                                                    label="Individual"
                                                />
                                                <FormControlLabel
                                                    value="other"
                                                    control={<Radio />}
                                                    label="Other"
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                    {watch('secondOperatorOption') ===
                                        'other' && (
                                        <Controller
                                            name="otherTypeValue2"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    label="Please specify"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="crn"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="CRN"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <Controller
                                            name="driverLicenseExpiry2"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    format="DD/MM/YYYY"
                                                    label="Driver Licence expiry date (if applicable)"
                                                    value={dayjs(field.value)}
                                                    onChange={field.onChange}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: false
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Individual’s given/family names or Organisation name"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="registrationNumber"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Organisation’s registration number (if applicable)"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}

                        {/* // 4. Personalised/customised plates */}
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                4. Personalised/customised plates
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                Are existing Personalised Plates to be attached?
                            </Typography>
                            <Controller
                                name="attachPlateOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        name="attachPlateOption"
                                    >
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        {watch('attachPlateOption') === 'yes' && (
                            <Grid item xs={12}>
                                <Controller
                                    name="plateNumber"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label="Plate number"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        )}

                        {/* // 5. Plate Type to be used for vehicle registration? */}
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                5. Plate Type to be used for vehicle
                                registration?
                            </Typography>
                            <Controller
                                name="plateTypeOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        name="plateTypeOption"
                                    >
                                        <FormControlLabel
                                            value="general"
                                            control={<Radio />}
                                            label="General issue (maroon on white)"
                                        />
                                        <FormControlLabel
                                            value="sequential"
                                            control={<Radio />}
                                            label="Sequential Classic (white on black)"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* // 6. Purpose of use */}
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                6. Purpose of use
                            </Typography>
                            <Controller
                                name="purposeOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        name="purposeOption"
                                    >
                                        {USE_PURPOSE_OPTIONS.map((option) => (
                                            <FormControlLabel
                                                key={option.value}
                                                value={option.value}
                                                control={<Radio />}
                                                label={option.label}
                                            />
                                        ))}
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* // 7. Concession details */}
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                7. Concession details
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                Are you applying for concessional registration?
                            </Typography>
                            <Controller
                                name="concessionOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        name="concessionOption"
                                    >
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* // 8. Duty */}
                        <Grid item xs={12}>
                            <Typography variant="h6">8. Duty</Typography>
                            <br />
                            <Typography variant="body1">
                                Are you exempt from paying vehicle registration
                                duty?{' '}
                            </Typography>
                            <Controller
                                name="dutyExemptOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        name="dutyExemptOption"
                                    >
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        {watch('dutyExemptOption') === 'yes' && (
                            <Grid item xs={12}>
                                <Controller
                                    name="exemptionDesc"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            label="Please provide exemption description"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        )}

                        {/* // 9. Compulsory Third Party (CTP) insurance */}
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                9. Compulsory Third Party (CTP) insurance
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                There are three licensed CTP insurers in Qld.
                                You need to select your CTP insurer from the
                                list below. I/We have independently chosen as
                                the CTP insurer:
                            </Typography>
                            <Controller
                                name="insurerOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        name="insurerOption"
                                    >
                                        <FormControlLabel
                                            value="Allianz"
                                            control={<Radio />}
                                            label="Allianz"
                                        />
                                        <FormControlLabel
                                            value="Suncorp"
                                            control={<Radio />}
                                            label="Suncorp"
                                        />

                                        <FormControlLabel
                                            value="QBE"
                                            control={<Radio />}
                                            label="QBE"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Are you entitled to claim the Input Tax Credit
                                Entitlement (ITCE)?
                            </Typography>
                            <Controller
                                name="ITCEOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        name="ITCEOption"
                                    >
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio />}
                                            label="No"
                                        />
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        {/* // buttons to preview, save and email the form data */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="error">
                                * Always preview the changes before emailing the
                                customer.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                            <Button
                                onClick={handleSubmit(onPreviewChanges)}
                                variant="contained"
                                sx={{ my: 3 }}
                            >
                                Preview Changes
                            </Button>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                            <Button
                                onClick={handleSubmit(onOpenEmailDialog)}
                                variant="contained"
                                sx={{ my: 3 }}
                                disabled={!hasPreviewed}
                            >
                                Email Customer
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Dialog open={openEmailDialog}>
                <DialogTitle>Email Customer</DialogTitle>
                <DialogContent>
                    An email with a link to sign will be sent to{' '}
                    <b>{customer?.name}</b> at the following email address:{' '}
                    <b>{customer?.email}</b>.
                    <br /> <br /> Always preview the document before sending an
                    email to the customer.{' '}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEmailDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit(handleClickSendEmail)}
                    >
                        Send Email
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DealerLOAOrgForm;
