// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';

const CheckDocumentReference = (
  documentReference: string,
  setResponseCode: Dispatch<SetStateAction<number>>
) => {
  api.put(`checkDocumentReference/${documentReference}`).then((res) => {
    setResponseCode(res.status);
  });
};

export default CheckDocumentReference;
