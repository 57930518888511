import api from '../../../../api';

const GetAllVehicleSaleWithUnrecDirectDeposit = (setVehicleSaleList) => {
    api.get('vehicleSaleWithUnrecDirectDeposit').then((res) => {
        if (res.status === 200) {
            setVehicleSaleList(res.data);
        }
    });
};

export default GetAllVehicleSaleWithUnrecDirectDeposit;
