// INTERFACES
import {
    Settings,
    Site,
    Till
} from '../../../global/interfaces/GeneralInterface';
import { ShippingMethod } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import api from '../../../../../api';
import ValidateSiteFields from '../../../global/logic/generalValidation/ValidateSiteFields';

interface EditProps {
    site: Site;
    settings: Settings[];
    tills: Till[];
    shippingMethods: ShippingMethod[];
    showSnackbar: showSnackbar;
}

const HandleUpdateSite = async ({
    site,
    settings,
    tills,
    shippingMethods,
    showSnackbar
}: EditProps) => {
    let { verified, message, subMessage } = await ValidateSiteFields(
        site,
        tills,
        shippingMethods
    );
    if (verified === false) {
        showSnackbar(message, subMessage, 'error');
    } else {
        // Update the site
        api.put(`updateSiteNextGen/`, {
            site,
            settings,
            tills,
            shippingMethods
        }).then((res) => {
            if (res.status === 409) {
                // An existing Site Packing Priority was given
                showSnackbar(
                    'Conflicting information',
                    `There is already a site with a Site Packing Priority of ${site.sitePackingPriority}.`,
                    'error'
                );
            } else if (res.status === 500) {
                // An unknown error occurred
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            } else if (res.status === 200) {
                // Site successfully updaated
                showSnackbar('Site updated successfully');
                setTimeout(() => window.location.reload(), 500);
            }
        });
    }
};

export default HandleUpdateSite;
