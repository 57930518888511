// IMPROTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import Dayjs from 'dayjs';

// COMPONENTS
import Autocomplete from '../../../../global/Autocomplete';
import Paper from '../../../../global/Paper';
// LOGIC
import GetAllSuppliers from '../../../../global/databaseLogic/GetAllSuppliers';
import CheckDocumentReference from '../../../../global/logic/CheckDocumentReference';
import GetAllSites from '../../../../global/databaseLogic/GetAllSites';
import GetAllVehiclesNoFilter from '../../../../global/databaseLogic/GetAllVehiclesNoFilter';

// INTERFACES
import { Creditor, Site } from '../../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Vehicle?: Vehicle;
    documentReference: string;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
}
interface InvoiceLine {
    id: number;
    Vehicle?: Vehicle;
    amount: string;
    description: string;
}
interface InvoiceHeaderProps {
    invoiceObject: InvoiceObject;
    setInvoiceObject: Dispatch<SetStateAction<InvoiceObject>>;
    invoiceLines: InvoiceLine[];
    setInvoiceLines: Dispatch<SetStateAction<InvoiceLine[]>>;
    responseCode: number;
    setResponseCode: Dispatch<SetStateAction<number>>;
    showSnackbar: showSnackbar;
}
const GSTTypes = [
    { value: 'G1' },
    { value: 'G2' },
    { value: 'G3' },
    { value: 'G10' },
    { value: 'G11' },
    { value: 'No GST' }
];

const InvoiceHeader = ({
    invoiceObject,
    setInvoiceObject,
    invoiceLines,
    setInvoiceLines,
    responseCode,
    setResponseCode,
    showSnackbar
}: InvoiceHeaderProps) => {
    const [creditors, setCreditors] = useState<Creditor[]>([]);
    const [sites, setSites] = useState<Site[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [lineAmount, setLineAmount] = useState('');
    const [lineDescription, setLineDescription] = useState('');

    // Get all the creditors, sites and vehicles
    useEffect(() => {
        GetAllSuppliers(setCreditors);
        GetAllSites(setSites);
        GetAllVehiclesNoFilter(setVehicles);
    }, []);

    // Once all the sites have been loaded default the site selector to the current site
    useEffect(() => {
        if (sites.length > 0) {
            let currentSite = sites.filter(
                (x) => x.id === parseInt(localStorage.getItem('SiteId'))
            );
            setInvoiceObject({ ...invoiceObject, Site: currentSite[0] });
        }
        // eslint-disable-next-line
    }, [sites]);

    // Handle adding a line to the invoice
    const addToInvoiceLines = (lineAmount: string, lineDescription: string) => {
        // If no vehicle, description of amount has been entered display an error
        if (!invoiceObject.Vehicle) {
            showSnackbar(
                'There is Missing Data!',
                'Please select a vehicle for the line.',
                'error'
            );
            return;
        } else if (!lineDescription) {
            showSnackbar(
                'There is Missing Data!',
                'Please enter a description for the line.',
                'error'
            );
            return;
        } else if (!lineAmount) {
            showSnackbar(
                'There is Missing Data!',
                'Please enter an amount for the line.',
                'error'
            );
            return;
        }

        // Get the current lines and calculate a new ID
        let currentLines = [...invoiceLines];
        let newId =
            currentLines.length > 0
                ? currentLines[currentLines.length - 1].id + 1
                : 1;

        // Generate the new line
        let newLine = {
            id: newId,
            Vehicle: invoiceObject.Vehicle,
            amount: lineAmount,
            description: lineDescription
        };

        // Push the new line and update the state
        currentLines.push(newLine);
        setInvoiceLines(currentLines);

        // Clear the old line data
        setInvoiceObject({ ...invoiceObject, Vehicle: null });
        setLineAmount('');
        setLineDescription('');
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Autocomplete
                            size="small"
                            options={creditors}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Creditor *"
                            selectedValue={invoiceObject.Creditor}
                            handleSelectedValueChange={(newValue) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    Creditor: newValue
                                })
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            error={responseCode === 400 ? true : false}
                            helperText={
                                responseCode === 400
                                    ? 'Document Reference Already Exists.'
                                    : ''
                            }
                            size="small"
                            label="Document Reference *"
                            InputLabelProps={{ shrink: true }}
                            value={invoiceObject.documentReference}
                            onChange={(e) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    documentReference: e.target.value
                                })
                            }
                            onBlur={(e) =>
                                CheckDocumentReference(
                                    e.target.value,
                                    setResponseCode
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Document Date *"
                                value={Dayjs(invoiceObject.documentDate)}
                                onChange={(newValue) => {
                                    setInvoiceObject({
                                        ...invoiceObject,
                                        documentDate: newValue.toISOString()
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Due Date"
                                value={Dayjs(invoiceObject.dueDate)}
                                onChange={(newValue) =>
                                    setInvoiceObject({
                                        ...invoiceObject,
                                        dueDate: newValue.toISOString()
                                    })
                                }
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            options={sites}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Site *"
                            selectedValue={invoiceObject.Site}
                            handleSelectedValueChange={(newValue) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    Site: newValue
                                })
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            select
                            size="small"
                            label="GST Type *"
                            value={invoiceObject.GSTtype}
                            onChange={(e) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    GSTtype: e.target.value
                                })
                            }
                            InputLabelProps={{ shrink: true }}
                        >
                            {GSTTypes.map((type) => (
                                <MenuItem value={type.value}>
                                    {type.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Document Total *"
                            InputLabelProps={{ shrink: true }}
                            value={invoiceObject.documentTotal}
                            onChange={(e) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    documentTotal: e.target.value
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            options={vehicles}
                            useTwoOptionLabels={true}
                            primaryOptionLabel="stockNumber"
                            secondaryOptionLabel="vehicleName"
                            textfieldLabel="Vehicle *"
                            selectedValue={invoiceObject.Vehicle}
                            handleSelectedValueChange={(newValue) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    Vehicle: newValue
                                })
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Line Description *"
                            InputLabelProps={{ shrink: true }}
                            value={lineDescription}
                            onChange={(e) => setLineDescription(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Line Amount *"
                            InputLabelProps={{ shrink: true }}
                            value={lineAmount}
                            onChange={(e) => setLineAmount(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align="center">
                            <Button
                                variant="contained"
                                onClick={() =>
                                    addToInvoiceLines(
                                        lineAmount,
                                        lineDescription
                                    )
                                }
                            >
                                Add Line
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default InvoiceHeader;
