// IMPORTS
import { List, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { SubMenuProps } from '../../global/interfaces/GlobalInterface';

const OnlineSubMenu = ({ isSupportPage }: SubMenuProps) => {
    const currentMode = useTheme().palette.mode;
    return (
        <>
            <List
                sx={{
                    backgroundColor:
                        currentMode === 'dark' ? '#3b3b3b' : '#dddddd'
                }}
            >
                {!isSupportPage && (
                    <ListItemButton
                        component={Link}
                        to="/online/orders"
                        sx={{ fontSize: '13px' }}
                    >
                        <ListItemText
                            inset
                            primaryTypographyProps={{ variant: 'caption' }}
                        >
                            Online Orders
                        </ListItemText>
                    </ListItemButton>
                )}

                {!isSupportPage && (
                    <ListItemButton
                        component={Link}
                        to={'/online/paymentManagement'}
                        sx={{ fontSize: '13px' }}
                    >
                        <ListItemText
                            inset
                            primaryTypographyProps={{ variant: 'caption' }}
                        >
                            Payment Management
                        </ListItemText>
                    </ListItemButton>
                )}
            </List>
        </>
    );
};

export default OnlineSubMenu;
