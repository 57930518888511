// IMPORTS
import { TableRow, Typography } from '@mui/material';
// COMPONENTS
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCell from '../../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
// LOGIC
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';

interface VehicleRebateContentProps {
    selectedObject;
}

const VehicleRebateContent = ({
    selectedObject
}: VehicleRebateContentProps) => {
    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Vehicle' },
        { id: 2, label: 'Amount' }
    ];

    return (
        <>
            <Typography variant="body1">
                <b>Account:</b> {selectedObject.reference}
            </Typography>
            <Typography variant="body1">
                <b>Date Entered:</b> {selectedObject.date}
            </Typography>
            <Typography variant="body1">
                <b>Amount:</b> {CurrencyFormatter(selectedObject.amount)}
            </Typography>
            <br />
            <Typography variant="body1">
                <b>Date Submitted:</b> {selectedObject.dateSubmitted}
            </Typography>
            {selectedObject.submittedBy && (
                <Typography variant="body1">
                    <b>Submitted by:</b> {selectedObject.submittedBy}
                </Typography>
            )}
            <br />
            <DataTable columns={columns}>
                {selectedObject.rebateLines.map((line) => (
                    <TableRow>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(
                                    `/vehicles/view/${line.VehicleId}`,
                                    '_blank'
                                )
                            }
                        >
                            {line.VehicleId}
                        </DataCellColoured>
                        <DataCell>
                            {line?.Vehicle?.year} {line?.Vehicle?.Make?.name}{' '}
                            {line?.Vehicle?.Model?.name}
                        </DataCell>
                        <DataCell>{CurrencyFormatter(line.amount)}</DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </>
    );
};

export default VehicleRebateContent;
