// INTERFACES
import { Dispatch, SetStateAction } from 'react';
import { GeneralJournalLineTemp } from '../GeneralJournalUI';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const AddEntryLine = (
    newEntry: any,
    entryLines: GeneralJournalLineTemp[],
    setEntryLines: Dispatch<SetStateAction<GeneralJournalLineTemp[]>>,
    showSnackbar: showSnackbar
) => {
    if (!newEntry.AccountId) {
        showSnackbar(
            'No account selected.',
            'Please select an account to continue.',
            'error'
        );
        return;
    }

    if (!newEntry.description) {
        showSnackbar(
            'No reference entered.',
            'Please enter a reference to continue.',
            'error'
        );
        return;
    } else if (!newEntry.dateEntered) {
        showSnackbar(
            'No date received selected.',
            'Please select a date received to continue.',
            'error'
        );
        return;
    } else if (
        (!newEntry.debit && !newEntry.credit) ||
        (parseFloat(newEntry.debit) === 0 && parseFloat(newEntry.credit) === 0)
    ) {
        showSnackbar(
            'No debit or credit amount entered.',
            'Please enter a debit or credit amount to continue.',
            'error'
        );
        return;
    }

    let currentGeneralJournal = [...entryLines];
    let highestId = 0;
    for (let transaction of currentGeneralJournal) {
        if (transaction.rowId > highestId) {
            highestId = transaction.rowId;
        }
    }

    currentGeneralJournal.push({
        ...newEntry,
        rowId: highestId + 1,
        debit: parseFloat(newEntry.debit),
        credit: parseFloat(newEntry.credit)
    });
    setEntryLines(currentGeneralJournal);
};

export default AddEntryLine;
