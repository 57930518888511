const GetStockDetails = (type, partDetails, selectedSite) => {
    if (type === 'In Bin') {
        let qty = partDetails?.Stock?.reduce((a, b) => {
            if (b.SiteId === selectedSite) {
                return b.qtyOnHand + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    } else if (type === 'Reserved') {
        let qty = partDetails?.reservedData?.reduce((a, b) => {
            if (b.SiteId === selectedSite) {
                let tempQty = 0;
                for (let line of b.orderLines) {
                    if (line.UnitId === partDetails.id) {
                        tempQty += parseInt(line.quantity);
                    }
                }
                return tempQty + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    } else if (type === 'Incoming') {
        let qty = partDetails?.incomingData?.reduce((a, b) => {
            if (b.SiteId === selectedSite) {
                let tempQty = 0;
                for (let line of b.orderLines) {
                    if (line.UnitId === partDetails.id) {
                        tempQty += line.quantity;
                    }
                }
                return tempQty + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    } else if (type === 'Returned') {
        let qty = partDetails?.creditedData?.reduce((a, b) => {
            if (b.site_id === selectedSite) {
                let tempQty = 0;
                for (let line of b.credit_lines) {
                    if (line.unitId === partDetails.id) {
                        tempQty += line.returned;
                    }
                }
                return tempQty + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    } else if (type === 'Transfer In') {
        let qty = partDetails?.transferData?.reduce((a, b) => {
            if (b.SiteToId === selectedSite) {
                let tempQty = 0;
                for (let line of b.transferLines) {
                    if (line.UnitId === partDetails.id) {
                        tempQty += line.quantitySent;
                    }
                }
                return tempQty + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    } else if (type === 'Transfer Out') {
        let qty = partDetails?.transferData?.reduce((a, b) => {
            if (b.SiteFromId === selectedSite) {
                let tempQty = 0;
                for (let line of b.transferLines) {
                    if (line.UnitId === partDetails.id) {
                        tempQty += line.quantitySent;
                    }
                }
                return tempQty + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    } else if (type === 'Supplier') {
        let qty = partDetails?.Stock?.reduce((a, b) => {
            if (b.SiteId === selectedSite) {
                return b.supplier + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    } else if (type === 'Online Reserved') {
        let qty = partDetails?.Stock?.reduce((a, b) => {
            if (b.SiteId === selectedSite) {
                return b.onlineReserved + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    } else if (type === 'Stock Min') {
        let qty = partDetails?.Stock?.reduce((a, b) => {
            if (b.SiteId === selectedSite) {
                return b.minStock + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    } else if (type === 'Stock Max') {
        let qty = partDetails?.Stock?.reduce((a, b) => {
            if (b.SiteId === selectedSite) {
                return b.maxStock + a;
            } else {
                return a;
            }
        }, 0);
        return qty;
    }
    return 0;
};

export default GetStockDetails;
