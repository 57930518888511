import dayjs, { Dayjs } from 'dayjs';

import api from '../../../../../api';

import { Customer } from '../../../global/interfaces/GeneralInterface';
import { Make, Model } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const UpdateLead = (
    customerDetails:
        | Customer
        | {
              name: string;
              phoneNumber: string;
              email: string;
              suburb: string;
              occupation: string;
          },
    bikeCondition: string,
    selectedMake: Make,
    selectedModel: Model,
    weeklyBudget: number,
    enquirySource: string,
    pickupDate: Dayjs,
    reason: string,
    setCustomerErrors,
    financeDetails,
    tradeInMake: Make,
    tradeInModel: Model,
    tradeInDetails,
    setTradeInErrors,
    notes: string,
    newNotes: string,
    showSnackbar: showSnackbar,
    selectedEnquiryId: number,
    status: string
) => {
    let customerErrorObj = {
        nameError: false,
        contactDetailsError: false,
        enquirySourceError: false,
        makeError: false
    };
    let tradeInErrorObj = {
        makeError: false,
        modelError: false,
        yearError: false
    };
    if (
        !customerDetails.hasOwnProperty('id') &&
        !customerDetails.hasOwnProperty('name')
    ) {
        customerErrorObj.nameError = true;
    }
    if (!customerDetails.phoneNumber && !customerDetails.email) {
        customerErrorObj.contactDetailsError = true;
    }
    if (!enquirySource) {
        customerErrorObj.enquirySourceError = true;
    }
    if (!selectedMake) {
        customerErrorObj.makeError = true;
    }

    if (tradeInMake?.name || tradeInModel?.name) {
        if (!tradeInMake) {
            tradeInErrorObj.makeError = true;
        }
        if (!tradeInModel) {
            tradeInErrorObj.modelError = true;
        }
        if (!tradeInDetails.year) {
            tradeInErrorObj.yearError = true;
        }
    }

    if (
        Object.values(customerErrorObj).every((value) => value === false) &&
        Object.values(tradeInErrorObj).every((value) => value === false)
    ) {
        let financeObject = {
            currentWeeklyRepayment: financeDetails.weeklyRepayment,
            amountOwing: financeDetails.amountOwing,
            remainingTerms: financeDetails.remainingTerms
        };
        let tradeInObject = {};
        if (tradeInMake || tradeInModel || tradeInDetails.year) {
            tradeInObject = {
                makeId: tradeInMake.id,
                modelId: tradeInModel.id,
                year: parseInt(dayjs(tradeInDetails.year).format('YYYY')),
                registration: tradeInDetails.registration,
                registrationExpiration: tradeInDetails.registrationExpiration,
                colour: tradeInDetails.colour,
                odometer: tradeInDetails.odometer,
                hours: tradeInDetails.hours,
                vin: tradeInDetails.vin,
                engineNumber: tradeInDetails.engineNumber
            };
        }

        let queryObj = {
            leadId: selectedEnquiryId,
            status: status,
            Customer: customerDetails,
            bikeCondition: bikeCondition,
            selectedMake: selectedMake,
            selectedModel: selectedModel,
            enquirySource: enquirySource,
            weeklyBudget: weeklyBudget,
            pickupDate: pickupDate,
            reason: reason,
            financeData: financeObject,
            tradeInData: tradeInObject,
            oldNotes: notes,
            newNotes: newNotes,
            SiteId: parseInt(localStorage.getItem('SiteId'))
        };
        api.put(`leadNextGen/${queryObj.leadId}`, queryObj).then((res) => {
            if (res.status === 200) {
                showSnackbar('Enquiry updated successfully.');
                window.location.reload();
            } else {
                showSnackbar('Enquiry Not Updated.', '', 'error');
            }
        });
    } else {
        if (
            customerErrorObj.nameError ||
            customerErrorObj.contactDetailsError
        ) {
            showSnackbar('Must select a customer.', '', 'error');
        } else {
            showSnackbar(
                "Some data are missing, please check the different tab to see what's missing.",
                '',
                'error'
            );
        }
        setCustomerErrors(customerErrorObj);
        setTradeInErrors(tradeInErrorObj);
        return;
    }
};

export default UpdateLead;
