import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import { OnlineOrder, Order } from '../../global/interfaces/PartsInterface';
import { Dispatch, SetStateAction, useState } from 'react';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import SaveOrderNotes from '../logic/Dashboard/SaveOrderNotes';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}

interface NotesDialogContentProps {
    selectedOrder: GroupedOnlineOrder;
    setNotesDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
    handleUpdateAllStatesNotes: (
        newNotes: string,
        OrderId: any,
        onlineOrderNewNotes: string
    ) => void;
}

const NotesDialogContent = ({
    handleUpdateAllStatesNotes,
    selectedOrder,
    setNotesDialogOpen,
    showSnackbar
}: NotesDialogContentProps) => {
    const [orderNotes, setOrderNotes] = useState<string>(
        selectedOrder.oraOrder.notes
    );
    const [onlineOrderNotes, setOnlineOrderNotes] = useState<string>(
        selectedOrder.netoOrder.internalNotes
    );

    return (
        <>
            <DialogTitle>
                Notes for P&A Order #{selectedOrder.oraOrder.id}
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    multiline
                    rows={6}
                    value={orderNotes}
                    onChange={(e) => setOrderNotes(e.target.value)}
                />
            </DialogContent>
            <DialogTitle>
                Notes for Online Order #{selectedOrder.oraOrder.OnlineOrderId}
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    multiline
                    rows={6}
                    value={onlineOrderNotes}
                    onChange={(e) => setOnlineOrderNotes(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setNotesDialogOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() =>
                        SaveOrderNotes(
                            selectedOrder.oraOrder.id,
                            orderNotes,
                            selectedOrder.netoOrder.OnlineOrderId,
                            onlineOrderNotes,
                            setNotesDialogOpen,
                            handleUpdateAllStatesNotes,
                            showSnackbar
                        )
                    }
                >
                    Save Notes
                </Button>
            </DialogActions>
        </>
    );
};

export default NotesDialogContent;
