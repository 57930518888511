// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Specific, Site } from '../interfaces/GeneralInterface';

interface SpecificsList {
  makes?: Specific[];
  models?: Specific[];
  series?: Specific[];
  aspirations?: Specific[];
  fuelTypes?: Specific[];
  bodyTypes?: Specific[];
  driveTypes?: Specific[];
  sites?: Site[];
}

const GetAllVehicleSpecifics = (
  setAllVehicleSpecifics: Dispatch<SetStateAction<SpecificsList>>,
  specificsToGet: string[]
) => {
  api
    .get(`allVehicleSpecifics?specificsToGet=[${specificsToGet}]`)
    .then((res) => {
      setAllVehicleSpecifics(res.data);
    });
};

export default GetAllVehicleSpecifics;
