// LOGIC
import api from '../../../../api';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

const SetUserUpdated = (showSnackbar: showSnackbar) => {
    api.get(`markUserAsUpdated`).then((res) => {
        if (res.status === 200) {
            showSnackbar('Ora version successfully updated.');

            setTimeout(function () {
                window.location.reload();
            }, 2000);
        }
    });
};

export default SetUserUpdated;
