// IMPORTS
import { TableRow, Typography, Divider } from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import {
  DateFormatter,
  CurrencyFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import { Invoice } from '../../../../global/interfaces/PartsInterface';

interface InvoicesProps {
  invoices: Invoice[];
}

const Invoices = ({ invoices }: InvoicesProps) => {
  const columns = [
    { id: 0, label: 'Invoice ID' },
    { id: 1, label: 'Reference' },
    { id: 2, label: 'Date' },
    { id: 3, label: 'Invoice Total' },
    { id: 4, label: 'Creditor' },
    { id: 5, label: 'Location' },
    { id: 6, label: 'User' }
  ];

  return (
    <Paper>
      <Typography variant="h5" sx={{ fontSize: '25px' }}>
        Invoices
      </Typography>
      <br />
      <Divider />
      <DataTable columns={columns}>
        {invoices.map((invoice) => (
          <TableRow>
            <DataCellColoured
              handleClick={() =>
                window.open(`/vehicles/viewVehicleInvoice/${invoice.id}`)
              }
            >
              {invoice?.id}
            </DataCellColoured>
            <DataCell>{invoice?.VehicleInvoice?.documentReference}</DataCell>
            <DataCell>
              {DateFormatter(invoice?.VehicleInvoice?.invoiceDate)}
            </DataCell>
            <DataCell>
              {CurrencyFormatter(invoice?.VehicleInvoice?.documentTotal)}
            </DataCell>
            <DataCell>{invoice?.VehicleInvoice?.Supplier?.name}</DataCell>
            <DataCell>{invoice?.VehicleInvoice?.Site?.name}</DataCell>
            <DataCell>
              {invoice?.VehicleInvoice?.User?.firstName}{' '}
              {invoice?.VehicleInvoice?.User?.lastName}
            </DataCell>
          </TableRow>
        ))}
      </DataTable>
    </Paper>
  );
};

export default Invoices;
