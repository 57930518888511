import { BaseSyntheticEvent, useState } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import MakeTable from './components/MakeTable';
import ModelTable from './components/ModelTable';
import SeriesTable from './components/SeriesTable';
import CategoriesTable from './components/CategoriesTable';
import BrandTable from './components/BrandTable';

// LOGIC
import Paper from '../../global/Paper';

const SpecificsUI = () => {
    const [selectedSpecific, setSelectedSpecific] = useState<string>('make');

    const handleSpecificChange = (e: BaseSyntheticEvent) => {
        setSelectedSpecific(e.target.value);
    };

    const displayTable = () => {
        if (selectedSpecific === 'make') {
            return <MakeTable />;
        } else if (selectedSpecific === 'model') {
            return <ModelTable />;
        } else if (selectedSpecific === 'series') {
            return <SeriesTable />;
        } else if (selectedSpecific === 'categories') {
            return <CategoriesTable />;
        } else if (selectedSpecific === 'brands') {
            return <BrandTable />;
        }
    };

    return (
        <PageWrapper>
            <Typography title="Specifics" variant="h4">
                Specifics
            </Typography>
            <br />
            <Paper>
                <ToggleButtonGroup
                    size="small"
                    color="primary"
                    exclusive
                    fullWidth
                    value={selectedSpecific}
                    onChange={handleSpecificChange}
                >
                    <ToggleButton value="make">Makes</ToggleButton>
                    <ToggleButton value="model">Models</ToggleButton>
                    <ToggleButton value="series">Series</ToggleButton>
                    <ToggleButton value="categories">Categories</ToggleButton>
                    <ToggleButton value="brands">Brands</ToggleButton>
                </ToggleButtonGroup>
            </Paper>
            <br />
            {displayTable()}
        </PageWrapper>
    );
};

export default SpecificsUI;
