// IMPORTS
// eslint-disable-next-line
import jsPDF from "jspdf"; // !DO NOT REMOVE
// LOGIC
import api from "../../../../../api";

// INTERFACES
import { Settings, Site } from "../../../global/interfaces/GeneralInterface";

const TransferSlipHeader = async (doc, SiteFromId: number, SiteToId: number, pageTitle: string, subtitle: string) => {
    let settingsFrom: Settings;
    let settingsTo: Settings
    let siteFrom: Site;
    let siteTo: Site;

    // Get the settings in order to get company details and logo
    await api.get(`settingsNextGen/${SiteFromId}?includeImage=true`).then(async (res) => {
        settingsFrom = res.data.settings;
        siteFrom = res.data.site;

        // Create and format an image object
        let image = null;
        let newImg = document.createElement("img");
        newImg.src = res.data.image;
        newImg.className = "logo";
        image = newImg;

        await api.get(`settingsNextGen/${SiteToId}?includeImage=false`).then((res) => {
            settingsTo = res.data.settings
            siteTo = res.data.site

            doc.addImage(image, 15, 15, 80, 15); // Put image on page

            // Page title
            doc.setFont("helvetica", "bold").setFontSize(24);
            doc.text(pageTitle, 12, 40);

            // Page subtitle
            doc.setFontSize(14);
            doc.text(subtitle, 13, 48);

            doc.setFont("helvetica", "bold")

            // Site To Company Details
            doc.text('From', 15, 55, {align: "left"})
            doc.setFont("helvetica", "normal").text(settingsFrom?.companyName, 15, 60, { align: "left" });
            doc.text(siteFrom.name, 15, 65, { align: "left" });

            let companyAddressLine1 = siteFrom?.address ? siteFrom?.address + "," : "";
            let companySuburb = siteFrom?.suburb ? siteFrom?.suburb + " " : "";
            let companyPostcode = siteFrom?.postcode ? siteFrom?.postcode + ", " : "";
            let companyCountry = siteFrom?.country ? siteFrom?.country : "";

            doc.text(`Address: ${companyAddressLine1}`, 15, 70, { align: "left" });
            doc.text(`${companySuburb}${companyPostcode}${companyCountry}`, 15, 75, { align: "left" });
            doc.text(`Phone: ${siteFrom.phoneNumber}`, 15, 80, { align: "left" });

            // Site From Company Details
            doc.setFont("helvetica", "bold").text('To', 195, 55, {align: "right"})
            doc.setFont("helvetica", "normal").text(settingsTo?.companyName, 195, 60, { align: "right" });
            doc.text(siteTo.name, 195, 65, { align: "right" });

            companyAddressLine1 = siteTo?.address ? siteTo?.address + "," : "";
            companySuburb = siteTo?.suburb ? siteTo?.suburb + " " : "";
            companyPostcode = siteTo?.postcode ? siteTo?.postcode + ", " : "";
            companyCountry = siteTo?.country ? siteTo?.country : "";

            doc.text(`Address: ${companyAddressLine1}`, 195, 70, { align: "right" });
            doc.text(`${companySuburb}${companyPostcode}${companyCountry}`, 195, 75, { align: "right" });
            doc.text(`Phone: ${siteTo.phoneNumber}`, 195, 80, { align: "right" });
        });
    });
};

export default TransferSlipHeader;
