//IMPORTS
import { useState, useEffect } from 'react';
import { TableRow, Typography } from '@material-ui/core';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import Drawer from '../../../global/Drawer';
import StockTransferDrawerContent from '../../../stock/stockTransferTable/components/StockTransferDrawerContent';
// LOGIC
import GetMovement from '../logic/GetMovement';
import { Customer } from '../../../global/interfaces/GeneralInterface';
import Paper from '../../../global/Paper';
import { DateFormatter } from '../../../global/logic/Formatters';
import { StockTransfer } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface MovementProps {
    partId: number;
    selectedSite: number;
    showSnackbar: showSnackbar;
}
interface MovementInterface {
    id: number;
    type: string;
    quantity: number;
    Customer?: Customer;
    date: string;
    link: string;
    Transfer?: StockTransfer;
}

const Movement = ({ partId, selectedSite, showSnackbar }: MovementProps) => {
    const [movement, setMovement] = useState<MovementInterface[]>();
    const [transferDrawerOpen, setTransferDrawerOpen] =
        useState<boolean>(false);
    const [selectedTransfer, setSelectedTransfer] = useState<StockTransfer>();

    useEffect(() => {
        GetMovement(partId, selectedSite, setMovement);
    }, [partId, selectedSite]);

    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Type' },
        { id: 2, label: 'Quantity' },
        { id: 3, label: 'Customer' },
        { id: 4, label: 'Date' }
    ];

    const handleClick = (row) => {
        if (row.type === 'TRANSFER') {
            setSelectedTransfer(row.Transfer);
            setTransferDrawerOpen(true);
        } else {
            window.open(row.link, '_blank');
        }
    };

    return (
        <>
            <Paper>
                {movement && movement.length > 0 ? (
                    <DataTable columns={columns}>
                        {movement?.map((row) => (
                            <TableRow key={row.id} className="row">
                                <DataCellColoured
                                    handleClick={() => handleClick(row)}
                                >
                                    {' '}
                                    {row.id}{' '}
                                </DataCellColoured>
                                <DataCell>{row.type}</DataCell>
                                <DataCell>{row.quantity}</DataCell>
                                <DataCell>
                                    {row.Customer
                                        ? row.Customer?.companyName
                                            ? row.Customer?.companyName
                                            : row.Customer?.firstName +
                                              ' ' +
                                              row.Customer?.lastName
                                        : ''}
                                </DataCell>
                                <DataCell>{DateFormatter(row.date)}</DataCell>
                            </TableRow>
                        ))}
                    </DataTable>
                ) : (
                    <Typography variant="body1" align="center">
                        No movement recorded for this part.
                    </Typography>
                )}
            </Paper>

            {/* Stock Transfer Drawer */}
            <Drawer
                openDrawer={transferDrawerOpen}
                setOpenDrawer={setTransferDrawerOpen}
                title={'Stock Transfer'}
                subTitle={`Transfer #${selectedTransfer?.id} - ${selectedTransfer?.status}`}
                width="40vw"
            >
                <StockTransferDrawerContent
                    showSnackbar={showSnackbar}
                    selectedTransfer={selectedTransfer}
                    setSelectedTransfer={setSelectedTransfer}
                />
            </Drawer>
        </>
    );
};

export default Movement;
