// INTERFACES
import {
    Credit,
    Order,
    Voucher
} from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import api from '../../../../../api';
import { CurrencyFormatter, ToFixed } from '../../../global/logic/Formatters';
import ValidateCreditSale from './ValidateCreditSale';
import PDFVoucherInvoice from '../../voucher/logic/PDFVoucherInvoice';
import CreditPDF from './CreditPDF';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        refundAmount?: string;
        returnedQuantity?: number; // used in crediting order
        returnedBackorderQuantity?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        voucherCode?: string;
    }[];
}

interface CreditProps {
    saleDetails: TempOrder;
    creditDetails: Credit;
    freightRefunding: string;
    vouchers: { voucher: Voucher; qtyReturning: number }[];
    returnReason: string;
    returnMethod: string;
    selectedTill: number;
    showSnackbar: showSnackbar;
}

/**
 * HandleSubmitCredit
 * Submit the credit to the backend
 * @author Estienne
 * @params CreditProps
 */
const HandleSubmitCredit = async ({
    saleDetails,
    creditDetails,
    freightRefunding,
    vouchers,
    returnReason,
    returnMethod,
    selectedTill,
    showSnackbar
}: CreditProps) => {
    let totalRefund =
        saleDetails.orderLines
            .map((x) =>
                !x.voucherCode ? parseFloat(x.refundAmount ?? '0') : 0
            )
            .reduce((acc, value) => acc + value, 0) +
        parseFloat(freightRefunding ?? '0') +
        vouchers
            .map((x) => (x.qtyReturning === 1 ? x.voucher.totalPrice : 0))
            .reduce((acc, value) => acc + value, 0);

    if (returnReason == null || returnReason === '') {
        showSnackbar('Please enter a reason for the refund.', '', 'error');
        return;
    }

    let totalPayment = saleDetails.paymentLines.reduce(
        (acc, value) => acc + value.amount,
        0
    );

    if (totalRefund > totalPayment) {
        showSnackbar(
            `The total amount refunded (${CurrencyFormatter(totalRefund)}) is superior to the total payment taken from the Customer (${CurrencyFormatter(totalPayment)}).`,
            '',
            'error'
        );
        return;
    }

    // Make sure the user inputs are valid
    let validated = ValidateCreditSale(
        saleDetails,
        freightRefunding,
        vouchers,
        returnReason,
        false
    );

    if (validated.validated === false) {
        showSnackbar(validated.message, '', 'error');
    } else {
        await api
            .post('/creditNextGen', {
                OrderId: saleDetails?.id,
                referenceType: creditDetails.referenceType,
                referenceId: creditDetails.id,
                SiteId: localStorage.getItem('SiteId'),
                TillId: selectedTill,
                orderLines: saleDetails.orderLines,
                amountRefunded: ToFixed(totalRefund),
                freightRefunded: ToFixed(parseFloat(freightRefunding)),
                vouchers: vouchers,
                reasonForCredit: returnReason,
                refundMethod: returnMethod,
                CustomerId: saleDetails.CustomerId,
                saleStatus: saleDetails.status
            })
            .then(async (res) => {
                if (res.status === 200) {
                    showSnackbar('Credit processed successfully.');
                    // If refunding to a voucher, print the voucher PDF
                    if (returnMethod === 'voucher') {
                        await PDFVoucherInvoice(res.data.voucher[0].id);
                    }
                    // Print the credit PDF
                    await CreditPDF({
                        siteId: parseInt(localStorage.getItem('SiteId')),
                        saleDetails: saleDetails,
                        customerDetails: saleDetails.Customer,
                        vouchers: res.data.creditedVouchers,
                        allCredits: false,
                        credit: res.data.credit
                    });
                    window.location.href = '/inventory/sales';
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact your IT department.',
                        'error'
                    );
                }
            });
    }
};

export default HandleSubmitCredit;
