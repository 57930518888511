import {
    CircularProgress,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import Paper from '../../../../global/Paper';
import { useEffect, useState } from 'react';
import GetReconciliations from '../../logic/History/GetReconciliations';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
import CalculateTotals from '../../logic/Reconciliator/CalculateTotals';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import { Close } from '@mui/icons-material';

interface HistoryProps {
    viewingDate: string;
    viewingId: string;
}
const History = ({ viewingDate, viewingId }: HistoryProps) => {
    const [reconciliations, setReconciliations] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedTransaction, setSelectedTransaction] = useState({
        id: null,
        dmsLines: [],
        bankLines: []
    });
    const [collapseOpen, setCollapseOpen] = useState<boolean>(false);
    const [selectedRebate, setSelectedRebate] = useState({
        VehicleRebateId: null,
        rebateLines: []
    });

    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Submitted By' },
        { id: 2, label: 'Time' },
        { id: 3, label: 'Total' }
    ];

    const dmsColumns = [
        { id: 1, label: 'Date' },
        { id: 2, label: 'Type' },
        { id: 3, label: 'Reference' },
        { id: 4, label: 'Amount' }
    ];

    const bankColumns = [
        { id: 1, label: 'Date' },
        { id: 2, label: 'Reference' },
        { id: 3, label: 'Amount' }
    ];

    useEffect(() => {
        setSelectedDate(dayjs(viewingDate));
        GetReconciliations(dayjs(viewingDate), setLoading, setReconciliations);
    }, [viewingDate]);

    useEffect(() => {
        GetReconciliations(selectedDate, setLoading, setReconciliations);
    }, [selectedDate]);

    useEffect(() => {
        if (viewingDate && reconciliations.length > 0) {
            let specificReconciliation = reconciliations.filter(
                (x) => x.id === viewingId
            );
            if (specificReconciliation.length === 1) {
                setSelectedTransaction(specificReconciliation[0]);
                setDialogOpen(true);
            }
        }
        // eslint-disable-next-line
    }, [reconciliations]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Paper>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                disabled={loading}
                                disableHighlightToday={true}
                                disableFuture={true}
                                value={selectedDate}
                                onChange={(newValue) =>
                                    setSelectedDate(newValue)
                                }
                            />
                        </LocalizationProvider>
                    </Paper>
                </Grid>
                <Grid item xs={9} textAlign="center">
                    <Paper>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Typography align="left" variant="h6">
                                    <b>
                                        {dayjs(selectedDate).format(
                                            'DD/MM/YYYY'
                                        )}{' '}
                                        Reconciliations
                                    </b>
                                </Typography>
                                <DataTable columns={columns}>
                                    {reconciliations.map((line) => (
                                        <TableRow>
                                            <DataCellColoured
                                                handleClick={() => {
                                                    setSelectedTransaction(
                                                        line
                                                    );
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                {line.id}
                                            </DataCellColoured>
                                            <DataCell>
                                                {line.User.firstName}{' '}
                                                {line.User.lastName}
                                            </DataCell>
                                            <DataCell>
                                                {dayjs(line.createdAt).format(
                                                    'h:mm A'
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(
                                                    CalculateTotals(
                                                        line.dmsLines
                                                    )
                                                )}
                                            </DataCell>
                                        </TableRow>
                                    ))}
                                </DataTable>
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <DialogTitle>
                    <Grid container spacing={2} textAlign="left">
                        <Grid item xs={11}>
                            Reconciliation #{selectedTransaction?.id}{' '}
                            Transactions
                            <br />
                            <Typography variant="subtitle2">
                                Total:{' '}
                                {CurrencyFormatter(
                                    CalculateTotals(
                                        selectedTransaction?.dmsLines
                                    )
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} textAlign="right">
                            <IconButton onClick={() => setDialogOpen(false)}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <DataTable columns={dmsColumns}>
                                {selectedTransaction?.dmsLines.map((item) =>
                                    item.VehicleRebateId ? (
                                        <>
                                            <TableRow>
                                                <DataCell>
                                                    <b>
                                                        {!item.date.includes(
                                                            '/'
                                                        )
                                                            ? dayjs(
                                                                  item.date
                                                              ).format(
                                                                  'DD/MM/YYYY'
                                                              )
                                                            : item.date}
                                                    </b>
                                                </DataCell>
                                                <DataCellColoured
                                                    handleClick={() => {
                                                        if (collapseOpen) {
                                                            setSelectedRebate({
                                                                VehicleRebateId:
                                                                    null,
                                                                rebateLines: []
                                                            });
                                                            setCollapseOpen(
                                                                false
                                                            );
                                                        } else {
                                                            setSelectedRebate(
                                                                item
                                                            );
                                                            setCollapseOpen(
                                                                true
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {item.TillReconciliationId ? (
                                                        <Typography variant="body2">
                                                            {item.siteName}{' '}
                                                            <br />
                                                            Till -{' '}
                                                            {item.tillName}
                                                        </Typography>
                                                    ) : item.VehicleRebateId ? (
                                                        <Typography variant="body2">
                                                            {`Vehicle Rebate #${item.VehicleRebateId}`}
                                                        </Typography>
                                                    ) : (
                                                        item.type
                                                    )}
                                                </DataCellColoured>
                                                <DataCell>
                                                    {item.reference}
                                                </DataCell>
                                                <DataCell>
                                                    {CurrencyFormatter(
                                                        item.amount
                                                    )}
                                                </DataCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        paddingBottom: 0,
                                                        paddingTop: 0
                                                    }}
                                                    colSpan={4}
                                                >
                                                    <Collapse
                                                        in={
                                                            collapseOpen &&
                                                            selectedRebate.VehicleRebateId ===
                                                                item.VehicleRebateId
                                                        }
                                                        unmountOnExit
                                                    >
                                                        {selectedRebate.rebateLines.map(
                                                            (line) => (
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        color: '#9370DB'
                                                                    }}
                                                                    textAlign="right"
                                                                    onClick={() =>
                                                                        window.open(
                                                                            'admin/viewRebateInvoice/' +
                                                                                selectedRebate.VehicleRebateId,
                                                                            '_blank'
                                                                        )
                                                                    }
                                                                >
                                                                    {`${line.description} - ${CurrencyFormatter(line.amount)}`}
                                                                </Typography>
                                                            )
                                                        )}
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ) : (
                                        <TableRow>
                                            <DataCell>
                                                <b>
                                                    {!item.date.includes('/')
                                                        ? dayjs(
                                                              item.date
                                                          ).format('DD/MM/YYYY')
                                                        : item.date}
                                                </b>
                                            </DataCell>
                                            <DataCell>
                                                {item.TillReconciliationId ? (
                                                    <Typography variant="body2">
                                                        {item.siteName} <br />
                                                        Till - {item.tillName}
                                                    </Typography>
                                                ) : (
                                                    item.type
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {item.reference}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(item.amount)}
                                            </DataCell>
                                        </TableRow>
                                    )
                                )}
                            </DataTable>
                        </Grid>
                        <Grid item xs={6}>
                            <DataTable columns={bankColumns}>
                                {selectedTransaction?.bankLines.map((item) => (
                                    <TableRow>
                                        <DataCell>
                                            <b>
                                                {item.date
                                                    ? item.date
                                                    : item.dateReceived}
                                            </b>
                                        </DataCell>
                                        <DataCell>{item.reference}</DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(item.amount)}
                                        </DataCell>
                                    </TableRow>
                                ))}
                            </DataTable>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default History;
