// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';
// LOGIC
import {
    ValidateCustomerType,
    ValidatePhone,
    ValidateEmail,
    ValidateLicence,
    ValidateABN,
    ValidatePaymentTerms,
    ValidateTradeDiscount
} from '../../../global/logic/customerValidation/ValidateCustomerFields';
import { AreEmpty } from '../../../global/logic/globalValidation/ValidateFields';

/**
 * ValidateCustomer
 * Verify the data to be updated
 * @param customer the customer
 * @returns a boolean validity status + an error message/sub-message
 */
const ValidateCustomer = (customer: Customer) => {
    let validType = ValidateCustomerType(customer?.customerType ?? '');
    let validEmail = ValidateEmail(customer?.email ?? '');
    let validPhone = ValidatePhone(customer?.phoneNumber ?? '');
    let licence = customer?.driverLicenceNumber?.replaceAll(' ', '');
    let validLicence = ValidateLicence(licence);
    let abn = customer?.abn;
    let validABN = ValidateABN(abn);
    let tradeDiscount = customer?.tradeDiscount;
    let validTradeDiscount = ValidateTradeDiscount(tradeDiscount);
    let validPaymentTerms = customer.debtor
        ? ValidatePaymentTerms(customer.paymentTerms)
        : {
              verified: false,
              message: 'Customer is not a debtor.',
              subMessage: ''
          };

    // Are email and Phone Number filled?
    if (AreEmpty([customer?.phoneNumber])) {
        return {
            verified: false,
            message: 'Make sure all required fields are filled',
            subMessage: 'Phone Number is required.'
        };
    }
    // If either the full name or the company filled?
    if (
        AreEmpty([customer?.firstName, customer?.lastName]) &&
        AreEmpty([customer?.companyName])
    ) {
        return {
            verified: false,
            message: 'Make sure all required fields are filled.',
            subMessage: 'A customer needs either a full name or a company name.'
        };
    }

    // Is the customer type valid?
    else if (validType.verified === false) {
        return {
            verified: false,
            message: validType.message,
            subMessage: validType.subMessage
        };
    }
    // If the customer is a trade customer, do they have a discount percentage?
    else if (
        customer.customerType === 'Trade' &&
        tradeDiscount !== 0 &&
        AreEmpty([customer?.tradeDiscount])
    ) {
        return {
            verified: false,
            message: 'Make sure all required fields are filled.',
            subMessage:
                'All trade customers need a valid trade discount percentage'
        };
    }
    // Did they entered an email? Is the email valid?
    else if (
        customer?.email != null &&
        customer.email !== '' &&
        validEmail.verified === false
    ) {
        return {
            verified: false,
            message: validEmail.message,
            subMessage: validEmail.subMessage
        };
    }
    // Is the phone number valid?
    else if (validPhone.verified === false) {
        return {
            verified: false,
            message: validPhone.message,
            subMessage: validPhone.subMessage
        };
    }
    // Did they enter a licence number? Is it valid?
    else if (
        licence != null &&
        licence !== '' &&
        validLicence.verified === false
    ) {
        return {
            verified: false,
            message: validLicence.message,
            subMessage: validLicence.subMessage
        };
    }
    // Did they enter an ABN? Is it valid?
    else if (abn != null && abn !== '' && validABN.verified === false) {
        return {
            verified: false,
            message: validABN.message,
            subMessage: validABN.subMessage
        };
    }
    // Did they enter a trade discount?
    else if (tradeDiscount && validTradeDiscount.verified === false) {
        return {
            verified: false,
            message: validTradeDiscount.message,
            subMessage: validTradeDiscount.subMessage
        };
    }
    // Is the customer a debtor? Do they have valid payment terms?
    else if (customer.debtor && validPaymentTerms.verified === false) {
        return {
            verified: false,
            message: validPaymentTerms.message,
            subMessage: validPaymentTerms.subMessage
        };
    }
    // All fields are valid
    else {
        return { verified: true, message: '', subMessage: '' };
    }
};

export default ValidateCustomer;
