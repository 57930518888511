import { Step } from '../../interface';

export const ServiceCalendarSteps: Step[] = [
    {
        label: "Navigate to the service calendar by selecting 'Service' > 'Calendar' from the sidebar.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-01.png'
    },
    {
        label: 'The calendar page shows bookings in a default "Fortnight" view from 7 AM to 6 PM each day.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-02.png'
    },
    {
        label: 'Hover a timeslot with a single booking to view details such as date, time, technician and customer contact information.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-03.png'
    },
    {
        label: 'Click the Printer icon to print the service Job Card.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-04.png'
    },
    {
        label: 'Click the list icon to display job codes for the current booking.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-05.png'
    },
    {
        label: 'Double-click a booking to open its details. Click the Edit button from speed dial menu to make changes or click X to close the dialog.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-06.png'
    },
    {
        label: 'For multiple bookings in a timeslot, the number of bookings is displayed. Click the timeslot to open a job list drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-07.png'
    },
    {
        label: 'Click on a job ID to see detailed booking information. This will open the booking details dialog.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-08.png'
    },
    {
        label: 'Click the "Edit" button from speed dial menu to update booking details or click X to close the booking details window.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-09.png'
    },
    {
        label: 'Double-click an empty timeslot to create a new booking, this will open the New Booking dialog with the selected date and time pre-filled',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-10.png'
    },
    {
        label: 'On the new booking page, follow the steps in the "Create Service Booking" documentation to add a new booking. Click X to close the dialog.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-11.png'
    },
    {
        label: 'Use the "Next Week" and "Previous Week" buttons to navigate bookings in the upcoming or previous fortnight.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-12.png'
    },
    {
        label: 'Change the calendar view as needed. In "Month" view, days show the number of bookings.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-13.png'
    },
    {
        label: "Click on a day's booking number to view the job list for that day.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-14.png'
    },
    {
        label: 'Use the same steps as in the fortnight view for viewing or updating booking details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-15.png'
    },
    {
        label: 'Double-click on the empty place on a day to create a new booking for that day. This will open the New Booking dialog.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-16.png'
    },
    {
        label: 'Click Next Month or Previous Month to navigate to bookings in the following or preceding month.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-17.png'
    },
    {
        label: 'Select the "Week" view to see bookings from 7 AM to 6 PM each day of the current week. Repeat the fortnight view steps for viewing, updating, or creating bookings.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-18.png'
    },
    {
        label: 'In the "Tech" view, you can see the bookings assigned to each technician for each day. Only technicians with bookings in selected week are displayed. Repeat the fortnight view steps for viewing or updating booking details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-19.png'
    },
    {
        label: 'The Tech Selection button open a drawer when you can filter the technicians.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-20.png'
    },
    {
        label: 'To create a new booking for a technician, double-click on a timeslot under their name. The New Booking dialog opens with the technician preselected.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/calendar/calendar-21.png'
    }
];
