// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';

const HandleCheckNegative = (
  UnitId: number,
  setDisabledCostAdjust: Dispatch<SetStateAction<boolean>>
) => {
  api.get(`checkOnNegativeOrderNextGen/${UnitId}`).then((res) => {
    if (res.data.value) {
      setDisabledCostAdjust(true);
    } else {
      setDisabledCostAdjust(false);
    }
  });
};

export default HandleCheckNegative;
