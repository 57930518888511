// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Delete,
    Dns,
    Edit,
    Receipt,
    Save,
    Textsms,
    Print
} from '@mui/icons-material';

// LOGIC
import PrintLabel from '../../../global/logic/PrintLabel';

// INTERFACES
import {
    Order,
    StockTransfer
} from '../../../global/interfaces/PartsInterface';

const SpeedDialList = (
    id: any,
    readOnly: boolean,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    setLogsDrawerOpen: Dispatch<SetStateAction<boolean>>,
    selectedType: string,
    saleDetails: Order,
    handleSubmitSale: () => void,
    setTextDrawerOpen: Dispatch<SetStateAction<boolean>>,
    setNotesDialogOpen: Dispatch<SetStateAction<boolean>>,
    setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>,
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>,
    handlePrintInvoice: (
        hasCustomer: boolean,
        saleData: Order,
        redirectId?: number | boolean,
        vouchers?: any[]
    ) => void,
    setStockTransferData?: Dispatch<SetStateAction<StockTransfer>>,
    setStockTransferDrawerOpen?: Dispatch<SetStateAction<boolean>>
) => {
    let actionList = [];
    let hasCustomer =
        selectedType === 'customer' ||
        selectedType === 'online' ||
        selectedType === 'contract' ||
        selectedType === 'service'
            ? true
            : false;

    let editItem = {
        icon: <Edit />,
        name: 'Edit',
        onClick: () => {
            setReadOnly(false);
            setShouldBlockNavigation(true);
        },
        cypressLabel: 'edit_PA_Order'
    };
    let editItemPermissions = {
        icon: <Edit />,
        name: 'Edit',
        onClick: () => {
            setReadOnly(false);
            setShouldBlockNavigation(true);
        },
        permission: 'edit_internal_sale'
    };
    let saveItem = {
        icon: <Save />,
        name: 'Save',
        onClick: () => handleSubmitSale(),
        cypressLabel: 'submitPASale'
    };
    let deleteItem = {
        icon: <Delete />,
        name: 'Delete',
        permission: 'delete_proforma',
        onClick: () => setDeleteDialogOpen(true)
    };
    let receiptItem = {
        icon: <Receipt />,
        name: 'Print Invoice',
        onClick: () => handlePrintInvoice(hasCustomer, saleDetails)
    };
    let textItem = {
        icon: <Textsms />,
        name: 'Text Customer',
        onClick: () => setTextDrawerOpen(true)
    };
    let logsItem = {
        icon: <Dns />,
        name: 'Logs',
        onClick: () => setLogsDrawerOpen(true)
    };
    let reprintLabel = {
        icon: <Print />,
        name: 'Reprint label(s) for this order',
        onClick: () => {
            let labelData = [];
            for (let line of saleDetails.orderLines) {
                labelData.push({
                    partNumber: line.partNumber,
                    priceRRP: line.priceRRP,
                    name: line.name,
                    binLocation: '',
                    backorderId: saleDetails.id,
                    customerName:
                        saleDetails?.Customer?.firstName +
                        ' ' +
                        saleDetails?.Customer?.lastName,
                    phoneNumber: saleDetails?.Customer?.phoneNumber
                });
            }
            PrintLabel(labelData);
        }
    };

    const isQuantity = () => {
        for (let line of saleDetails.orderLines) {
            if (parseInt(line.quantity) > 0) {
                return true;
            }
        }
        return false;
    };

    if (readOnly) {
        actionList.push(editItem);
    } else if (
        readOnly &&
        selectedType !== 'customer' &&
        selectedType !== 'online' &&
        selectedType !== 'guest'
    ) {
        actionList.push(editItemPermissions);
    } else if (!readOnly) {
        actionList.push(saveItem);
    }

    if (id && readOnly) {
        actionList.push(receiptItem);
        actionList.push(reprintLabel);
    }

    if (
        id &&
        (selectedType === 'customer' ||
            selectedType === 'online' ||
            selectedType === 'contract')
    ) {
        actionList.push(textItem);
    }

    if (saleDetails.status !== 'Sale' && id) {
        actionList.push(deleteItem);
    }

    if (id) {
        actionList.push(logsItem);
    }

    if (isQuantity) {
        actionList.push({
            icon: <Dns />,
            name: 'Stock Transfer',
            onClick: () => {
                let stockTransfer = {
                    SiteFrom: {},
                    SiteTo: {},
                    notes: '',
                    transferLines: []
                };
                for (let line of saleDetails.orderLines) {
                    if (parseInt(line.quantity) > 0) {
                        stockTransfer.transferLines.push({
                            UnitId: line.UnitId,
                            partNumber: line.partNumber,
                            name: line.name,
                            quantitySent: line.quantity
                        });
                    }
                }
                setStockTransferData(stockTransfer);
                setStockTransferDrawerOpen(true);
            }
        });
    }

    return actionList;
};

export default SpeedDialList;
