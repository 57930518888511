import { Step } from '../../interface';

export const EnterGenericInvoiceSteps: Step[] = [
    {
        label: `In the sidebar, click Admin > Enter Invoice to navigate to the Enter Admin Invoice page`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-01.png'
    },
    {
        label: `In the Generic Invoice tab, all required fields are marked with an asterisk (*). If you submit the invoice without filling these out, an error message will be displayed indicating what is missing.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-02.png'
    },
    {
        label: `If you do not specify a due date, the system will automatically calculate it based on the creditor's payment terms. For example, if the creditor has End of Month (EOM) payment terms and the invoice is dated April 23, 2024, the due date will be set to May 31, 2024.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-03.png'
    },
    {
        label: (
            <>
                You can update the creditor payment terms in the Creditor
                details page under the Payment Details section. Refer to{' '}
                <a
                    href="/support/admin/creditors/#view-and-update-creditor"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    View and update creditor details
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-04.png'
    },
    {
        label: `Click the 'Add Line' button to add one or more invoice lines.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-05.png'
    },
    {
        label: `Ensure the total of these lines matches the Document Total. If there is a discrepancy, an error message will be displayed upon submission.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-06.png'
    },
    {
        label: `After successfully adding an invoice, a message 'Invoice Submitted Successfully' will appear, and the page will automatically refresh.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-07.png'
    }
];

export const EnterRegistationInvoiceSteps: Step[] = [
    {
        label: `In the sidebar, click Admin > Enter Invoice to navigate to the Enter Admin Invoice page`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-01.png'
    },
    {
        label: `In the Registration tab, all required fields are marked with an asterisk (*). If you submit the invoice without filling these out, an error message will be displayed indicating what is missing.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/reg-invoice-02.png'
    },
    {
        label: `If you do not specify a due date, the system will automatically calculate it based on the creditor's payment terms. For example, if the creditor has End of Month (EOM) payment terms and the invoice is dated April 23, 2024, the due date will be set to May 31, 2024.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/reg-invoice-03.png'
    },
    {
        label: (
            <>
                You can update the creditor payment terms in the Creditor
                details page under the Payment Details section. Refer to{' '}
                <a
                    href="/support/admin/creditors/#view-and-update-creditor"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    View and update creditor details
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-04.png'
    },
    {
        label: `Select a vehicle, fill in the line description and amount, then click the 'Add Line' button to add the invoice line. Repeat to add more lines`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/reg-invoice-05.png'
    },
    {
        label: `Ensure the total of these lines matches the Document Total. If there is a discrepancy, an error message will be displayed upon submission.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/reg-invoice-06.png'
    },
    {
        label: `After successfully adding an invoice, a message 'Invoice Submitted Successfully' will appear, and the page will automatically refresh.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/reg-invoice-07.png'
    }
];

export const EnterVehicleRebateInvoiceSteps: Step[] = [
    {
        label: `In the sidebar, click Admin > Enter Invoice to navigate to the Enter Admin Invoice page`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-01.png'
    },
    {
        label: `In the Rebates tab, all required fields are marked with an asterisk (*). If you submit the invoice without filling these out, an error message will be displayed indicating what is missing.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/rebate-invoice-02.png'
    },
    {
        label: "If you select a creditor, a 'Push Straight to Bank Reconciliation' toggle will appear. By default, this toggle is unchecked, which results in the creation of a standard invoice that is accessible under the 'Invoices' tab on the Creditor details page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-08.png'
    },
    {
        label: `If the 'Push Straight to Bank Reconciliation' toggle is activated, the invoice will not link to the creditor. Instead, it will directly appear in the 'Reconciliator' tab in the 'Unreconciled Ora' table in the Bank Reconciliation page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-09.png'
    },
    {
        label: `If you do not specify a due date, the system will automatically calculate it based on the creditor's payment terms. For example, if the creditor has End of Month (EOM) payment terms and the invoice is dated April 23, 2024, the due date will be set to May 31, 2024.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/rebate-invoice-025.png'
    },
    {
        label: (
            <>
                You can update the creditor payment terms in the Creditor
                details page under the Payment Details section. Refer to{' '}
                <a
                    href="/support/admin/creditors/#view-and-update-creditor"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    View and update creditor details
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-04.png'
    },
    {
        label: "Use the 'Stock and VIN' toggle to search for vehicles. Set it to 'Stock #' to search by stock number, or switch it to 'VIN' to search by VIN.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-10.png'
    },
    {
        label: "To add an invoice line, select a vehicle, enter a line amount and a description, and click the 'Add Line' button. Repeat these steps to add more lines.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/rebate-invoice-08.png'
    },
    {
        label: `Ensure the total of these lines matches the Document Total. If there is a discrepancy, an error message will be displayed upon submission.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/rebate-invoice-09.png'
    },
    {
        label: `After successfully adding an invoice, a message 'Invoice Submitted Successfully' will appear, and the page will automatically refresh.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/rebate-invoice-10.png'
    }
];

export const EnterWarrantyInvoiceSteps: Step[] = [
    {
        label: `In the sidebar, click Admin > Enter Invoice to navigate to the Enter Admin Invoice page`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-01.png'
    },
    {
        label: `In the Warranties tab, all required fields are marked with an asterisk (*). If you submit the invoice without filling these out, an error message will be displayed indicating what is missing.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/warranty-invoice-02.png'
    },
    {
        label: `If you do not specify a due date, the system will automatically calculate it based on the creditor's payment terms. For example, if the creditor has End of Month (EOM) payment terms and the invoice is dated April 23, 2024, the due date will be set to May 31, 2024.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/warranty-invoice-03.png'
    },
    {
        label: (
            <>
                You can update the creditor payment terms in the Creditor
                details page under the Payment Details section. Refer to{' '}
                <a
                    href="/support/admin/creditors/#view-and-update-creditor"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    View and update creditor details
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-04.png'
    },
    {
        label: "Use the 'Service ID and VIN' toggle to search for services. Set it to 'Service ID' to search by service id, or switch it to 'VIN' to search vehicle VIN. Selecting a Service in the list will create a line in the table below, multiple lines can be created by selecting multiple services.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/warranty-invoice-05.png'
    },
    {
        label: 'After selecting a service, the service payment details will be displayed. Enter the amount you are paying.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/warranty-invoice-06.png'
    },
    {
        label: `Ensure the total amount paying matches the Document Total. If there is a discrepancy, an error message will be displayed upon submission.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/warranty-invoice-07.png'
    },
    {
        label: `After successfully adding an invoice, a message 'Invoice Submitted Successfully' will appear, and the page will automatically refresh.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/warranty-invoice-08.png'
    }
];

export const EnterTrustReceiptInvoiceSteps: Step[] = [
    {
        label: `In the sidebar, click Admin > Enter Invoice to navigate to the Enter Admin Invoice page`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-01.png'
    },
    {
        label: `In the Trust Receipt tab, all required fields are marked with an asterisk (*). If you submit the invoice without filling these out, an error message will be displayed indicating what is missing.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/trust-invoice-02.png'
    },
    {
        label: "Use the 'Stock and VIN' toggle to search for vehicles. Set it to 'Stock #' to search by stock number, or switch it to 'VIN' to search by short VIN. Selecting a vehicle in the dropdown list will add it to the table below, multiple lines can be created by selecting multiple vehicles..",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/trust-invoice-03.png'
    },
    {
        label: `If you do not specify a due date, the system will automatically calculate it based on the creditor's payment terms. For example, if the creditor has End of Month (EOM) payment terms and the invoice is dated April 23, 2024, the due date will be set to May 31, 2024.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/trust-invoice-04.png'
    },
    {
        label: (
            <>
                You can update the creditor payment terms in the Creditor
                details page under the Payment Details section. Refer to{' '}
                <a
                    href="/support/admin/creditors/#view-and-update-creditor"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    View and update creditor details
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-04.png'
    },
    {
        label: 'After selecting a vehicle, the vehicle payment details will be displayed. Enter the amount you are paying.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/trust-invoice-06.png'
    },
    {
        label: `Ensure the total amount paying matches the Document Total. If there is a discrepancy, an error message will be displayed upon submission.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/trust-invoice-07.png'
    },
    {
        label: `After successfully adding an invoice, a message 'Invoice Submitted Successfully' will appear, and the page will automatically refresh.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/trust-invoice-08.png'
    }
];

export const ViewEditGenericInvoiceSteps: Step[] = [
    {
        label: `To check a newly added Account Invoice, navigate by clicking on Admin -> Creditors from the sidebar. In the Creditors page, find the Creditor you just selected when entering the invoice. In the Creditor details page, under the Invoices tab, you will see all associated invoices with the creditor.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/edit-invoice-01.png'
    },
    {
        label: `To edit an Invoice, click on the Invoice ID which will redirect you to the Invoice's detail page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/edit-invoice-02.png'
    },
    {
        label: `In the Invoice's detail page, you can edit the Invoice Lines by clicking the Edit icon. After making the necessary changes, click the 'Save' icon to submit the updates.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/edit-invoice-03.png'
    },
    {
        label: `In the Invoice's detail page, you have the option to delete the Invoice by clicking the 'Delete' icon. Be aware that the deleting an invoice is irreversible.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/edit-invoice-04.png'
    },
    {
        label: `You can find Invoices associated with a vehicle by clicking on 'Vehicle Sales' -> 'Vehicle Sticj' from the sidebar. In the Vehicle Stock page, find the vehicle and click on its Stock Number to be redirect to the Vehicle detail page, in the Added Costs tab, you will find the Invoices table displaying all invoices associated to this vehicle.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/edit-invoice-06.png'
    },
    {
        label: `For a Trust Receipt, you can only find it on the Bank Reconciliation page in the Unreconciled Ora table.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-09.png'
    }
];
