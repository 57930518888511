// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Grid,
    Typography,
    Divider,
    TextField,
    FormControlLabel,
    Checkbox,
    MenuItem
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../../global/Paper';
import Autocomplete from '../../../../global/Autocomplete';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { Specific } from '../../../../global/interfaces/GeneralInterface';
import { Site } from '../../../../global/interfaces/GeneralInterface';

interface SpecificsList {
    makes?: Specific[];
    models?: Specific[];
    series?: Specific[];
    aspirations?: Specific[];
    fuelTypes?: Specific[];
    bodyTypes?: Specific[];
    driveTypes?: Specific[];
    sites?: Site[];
}

interface ManufacturerDetailsProps {
    vehicle: Vehicle;
    setVehicle: Dispatch<SetStateAction<Vehicle>>;
    specifics: SpecificsList;
    readOnly: boolean;
}

const ManufacturerMotorcyclesDetails = ({
    vehicle,
    setVehicle,
    specifics,
    readOnly
}: ManufacturerDetailsProps) => {
    return (
        <Paper>
            <Typography variant="h6">Manufacturer Details</Typography>
            <Divider />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        isDisabled={readOnly}
                        options={specifics?.makes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Make"
                        selectedValue={vehicle?.Make}
                        handleSelectedValueChange={(event) => {
                            setVehicle({
                                ...vehicle,
                                Make: event,
                                MakeId: event.id
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        isDisabled={readOnly}
                        options={specifics?.models}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Model"
                        selectedValue={vehicle?.Model}
                        handleSelectedValueChange={(event) => {
                            setVehicle({
                                ...vehicle,
                                Model: event,
                                ModelId: event.id
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        isDisabled={readOnly}
                        options={specifics?.series}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Series"
                        selectedValue={vehicle?.Series}
                        handleSelectedValueChange={(event) => {
                            setVehicle({
                                ...vehicle,
                                Series: event,
                                SeriesId: event.id
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            disabled={readOnly}
                            format="YYYY"
                            views={['year']}
                            label="Year Model"
                            value={vehicle?.year}
                            onChange={(newValue) => {
                                setVehicle({
                                    ...vehicle,
                                    year: newValue
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Engine Size"
                        value={vehicle?.engineSize}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setVehicle({
                                ...vehicle,
                                engineSize: e.target.value
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        size="small"
                        isDisabled={readOnly}
                        options={specifics?.fuelTypes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Fuel Type"
                        selectedValue={vehicle?.FuelType}
                        handleSelectedValueChange={(event) => {
                            setVehicle({
                                ...vehicle,
                                FuelType: event,
                                FuelTypeId: event.id
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        select
                        size="small"
                        label="Transmission"
                        value={vehicle?.transmission}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setVehicle({
                                ...vehicle,
                                transmission: e.target.value
                            });
                        }}
                    >
                        <MenuItem value={'Manual'}>Manual</MenuItem>
                        <MenuItem value={'Automatic'}>Automatic</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        isDisabled={readOnly}
                        options={specifics?.bodyTypes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Body Type"
                        selectedValue={vehicle?.BodyType}
                        handleSelectedValueChange={(event) => {
                            setVehicle({
                                ...vehicle,
                                BodyType: event,
                                BodyTypeId: event.id
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        isDisabled={readOnly}
                        options={specifics?.driveTypes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Drive Type"
                        selectedValue={vehicle?.DriveType}
                        handleSelectedValueChange={(event) => {
                            setVehicle({
                                ...vehicle,
                                DriveType: event,
                                DriveTypeId: event.id
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Colour"
                        value={vehicle?.colour}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setVehicle({ ...vehicle, colour: e.target.value });
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Cylinders"
                        value={vehicle?.cylinders}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setVehicle({
                                ...vehicle,
                                cylinders: e.target.value
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Gears"
                        value={vehicle?.gears}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setVehicle({ ...vehicle, gears: e.target.value });
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Seats"
                        value={vehicle?.seats}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setVehicle({ ...vehicle, seats: e.target.value });
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={readOnly}
                                checked={vehicle?.learnerApproved}
                                onChange={(e) =>
                                    setVehicle({
                                        ...vehicle,
                                        learnerApproved: e.target.checked
                                    })
                                }
                            />
                        }
                        label="Learner Approved"
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};
export default ManufacturerMotorcyclesDetails;
