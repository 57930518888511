import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    TextField
} from '@mui/material';
import Autocomplete from '../../../global/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

// LOGIC
import GetAllSuppliers from '../../../global/databaseLogic/GetAllSuppliers';
import HandleAddPart from '../../logic/Dashboard/HandleAddPart';

// INTERFACE
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    OnlineOrder,
    Order,
    PartOrderline
} from '../../../global/interfaces/PartsInterface';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
interface AddPartDialogContentProps {
    missingParts: GroupedOnlineOrder[];
    setMissingParts: Dispatch<SetStateAction<GroupedOnlineOrder[]>>;
    selectedOrder: GroupedOnlineOrder;
    orderline: PartOrderline;
    setAddPartDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}
const clearedNewPart = {
    partNumber: null,
    name: null,
    supplier: {},
    costPriceDaily: null,
    priceRRP: null
};
const AddPartDialogContent = ({
    missingParts,
    setMissingParts,
    selectedOrder,
    orderline,
    setAddPartDialogOpen,
    showSnackbar
}: AddPartDialogContentProps) => {
    const [suppliers, setSuppliers] = useState<Creditor[]>([]);
    const [newPart, setNewPart] = useState(clearedNewPart);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        setNewPart({
            ...newPart,
            partNumber: orderline.partNumber,
            name: orderline.name,
            costPriceDaily: orderline.costPriceDaily,
            priceRRP: orderline.pricePaid
        });
        GetAllSuppliers(setSuppliers);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Part Number"
                            InputLabelProps={{ shrink: true }}
                            value={newPart.partNumber}
                            onChange={(e) =>
                                setNewPart({
                                    ...newPart,
                                    partNumber: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            label="Name"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            value={newPart.name}
                            onChange={(e) =>
                                setNewPart({ ...newPart, name: e.target.value })
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={suppliers}
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            textfieldLabel={'Supplier'}
                            textfieldVariant={'outlined'}
                            selectedValue={newPart.supplier}
                            handleSelectedValueChange={(newValue) =>
                                setNewPart({ ...newPart, supplier: newValue })
                            }
                            handleInputValueChange={(newInputValue) => null}
                            canSearch={false}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Daily Cost Price"
                            InputLabelProps={{ shrink: true }}
                            value={newPart.costPriceDaily}
                            onChange={(e) =>
                                setNewPart({
                                    ...newPart,
                                    costPriceDaily: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="RRP"
                            InputLabelProps={{ shrink: true }}
                            value={newPart.priceRRP}
                            onChange={(e) =>
                                setNewPart({
                                    ...newPart,
                                    priceRRP: e.target.value
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setAddPartDialogOpen(false)}>
                    Cancel
                </Button>
                <LoadingButton
                    loading={buttonLoading}
                    variant="contained"
                    onClick={() =>
                        HandleAddPart(
                            missingParts,
                            setMissingParts,
                            newPart,
                            selectedOrder,
                            orderline,
                            setAddPartDialogOpen,
                            setButtonLoading,
                            showSnackbar
                        )
                    }
                >
                    Add Part
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default AddPartDialogContent;
