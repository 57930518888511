// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { TableRow, Chip, IconButton } from '@mui/material';
import { RadioButtonUnchecked, CheckCircle } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
import TableSearch from '../../../../global/tableComponents/TableSearch';
// INTERFACES
import { Service } from '../../../../global/interfaces/ServiceInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedSort = ['id', 'DESC'];

interface ServiceFormProps {
    readOnly: boolean;
    selectedEntity: Service;
    setSelectedEntity: Dispatch<SetStateAction<Service>>;
}

const ServiceForm = ({
    readOnly,
    selectedEntity,
    setSelectedEntity
}: ServiceFormProps) => {
    const [resultsList, setResultsList] = useState<Service[]>([]);
    const [resultsListAltered, setResultsListAltered] = useState<Service[]>([]);

    // Columns for the data table
    const columns = [
        { id: 0, label: '', width: 100 },
        { id: 1, label: 'ID', width: 100 },
        { id: 2, label: 'Type', width: 100 },
        { id: 3, label: 'Customer / Stock Number', width: 300 },
        { id: 4, label: 'Vehicle', width: 300 },
        { id: 5, label: 'Assigned Tech', width: 200 },
        { id: 6, label: 'Customer Email', width: 100 },
        { id: 7, label: 'Customer Phone', width: 100 },
        { id: 8, label: 'Status', width: 100 }
    ];

    useEffect(() => {
        if (selectedEntity?.id) {
            let singleRow = [];
            singleRow.push(selectedEntity);
            setResultsListAltered(singleRow);
        } else {
            setResultsListAltered(resultsList);
        }
    }, [selectedEntity, resultsList]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        return '';
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Services"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={clearedSort}
                handleRequestCreate={handleRequestCreate}
                route="serviceSearchNextGen"
                setResultsList={setResultsList}
                customLimit={10}
                disableSearch={selectedEntity?.id ? true : false}
            />

            <Paper>
                <DataTable columns={columns}>
                    {resultsListAltered.map((row) => (
                        <TableRow key={row.id}>
                            <DataCell>
                                {selectedEntity?.id ? (
                                    <IconButton
                                        onClick={() => setSelectedEntity(null)}
                                        disabled={readOnly}
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => setSelectedEntity(row)}
                                    >
                                        <RadioButtonUnchecked />
                                    </IconButton>
                                )}
                            </DataCell>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/service/viewBooking/${row.id}`,
                                        '_blank'
                                    )
                                }
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>{row.jobType}</DataCell>
                            {row.jobType !== 'Internal' ? (
                                <>
                                    <DataCell>
                                        {row.Customer?.companyName != null
                                            ? row.Customer?.companyName
                                            : row.Customer?.firstName +
                                              ' ' +
                                              row.Customer?.lastName}
                                        <br />
                                        {row.Customer?.abn
                                            ? `ABN: ${row.Customer?.abn}`
                                            : ''}
                                    </DataCell>
                                    <DataCell>
                                        {row.CustomerVehicle?.year.format(
                                            'YYYY'
                                        )}{' '}
                                        {row.CustomerVehicle?.Make?.name}{' '}
                                        {row.CustomerVehicle?.Model?.name}{' '}
                                        {row.CustomerVehicle?.Series?.name}
                                    </DataCell>
                                </>
                            ) : (
                                <>
                                    <DataCell>
                                        #{row.Vehicle?.stockNumber}
                                    </DataCell>
                                    <DataCell>
                                        {row.Vehicle?.condition.toUpperCase()}{' '}
                                        {row.Vehicle?.Make?.name}{' '}
                                        {row.Vehicle?.Model?.name}{' '}
                                        {row.Vehicle?.Series?.name}
                                    </DataCell>
                                </>
                            )}
                            <DataCell>
                                {row.Tech?.firstName} {row.Tech?.lastName}
                            </DataCell>
                            <DataCell>{row?.Customer?.email}</DataCell>
                            <DataCell>{row?.Customer?.phoneNumber}</DataCell>
                            <DataCell>
                                <Chip
                                    label={row.serviceComplete}
                                    variant="outlined"
                                    color={
                                        row.serviceComplete === 'Finalised'
                                            ? 'success'
                                            : row.serviceComplete === 'Complete'
                                              ? 'warning'
                                              : 'error'
                                    }
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </>
    );
};

export default ServiceForm;
