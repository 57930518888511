// INTERFACES
import { Service } from '../../../global/interfaces/ServiceInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
import { ToFixed } from '../../../global/logic/Formatters';

/**
 * TotalAddedCosts
 * Summate the added costs on a service
 * @author Estienne
 * @param addedCosts the added costs of a service
 * @returns the sum of all added costs
 */
function TotalAddedCosts(addedCosts: any[]) {
  // Return 0 if there are no added costs
  if (addedCosts === null || addedCosts === undefined) return 0;
  if (addedCosts.length === 0) return 0;

  let totalPrice = 0;

  // Summate the added costs
  addedCosts.forEach((part) => {
    totalPrice += parseFloat(part.rowTotal);
  });

  return totalPrice;
}

/**
 * TotalMiscCosts
 * Summate the miscellaneous costs on a service
 * @author Estienne
 * @param miscCosts the misc costs of a service
 * @returns the sum of all misc costs
 */
function TotalMiscCosts(miscCosts: any[]) {
  // Return 0 if there are no miscellaneous costs
  if (miscCosts === null || miscCosts === undefined) return 0;
  if (miscCosts.length === 0) return 0;

  let totalPrice = 0;

  // Summate the miscellanous costs
  miscCosts.forEach((part) => {
    totalPrice += parseFloat(part.amount);
  });

  return totalPrice;
}

/**
 * TotalOrderParts
 * Summate the parts costs on all orders for a service
 * (there can be multiple orders linked to the same service)
 * @author Estienne
 * @param orders an array of orders
 * @returns the sum of all parts on all given orders
 */
function TotalOrderParts(service: Service, orders: Order[]) {
  let totalPrice = 0;

  // For each order linked to the given service
  orders.forEach((order) => {
    // For each order line in the order
    order?.orderLines.forEach((line) => {
      totalPrice += line.priceCharged
        ? parseFloat(line.priceCharged) * 1.1 // Prioritise priceCharged
        : parseFloat(line.pricePaid) * 1.1 * parseFloat(line.quantity); // Settle for pricePaid
    });
  });

  totalPrice = ToFixed(totalPrice);

  return totalPrice;
}

export const ServiceTotals = (service: Service, orderData?: Order[]) => {
  let totals = {
    labour: service?.labourCost,
    sundries: service?.sundries,
    environmentalLevy: service?.environmentalLevy,
    partsTotal: TotalAddedCosts(service?.addedCosts),
    miscTotal: TotalMiscCosts(service?.miscCosts),
    subletTotal: 0
  };

  if (orderData) {
    totals.partsTotal += TotalOrderParts(service, orderData);
  }

  return totals;
};

/**
 * CalculateServiceTotal
 * Calculate the total cost of a service
 * @author Estienne
 * @param service a customer's service
 * @param orderData (optional) an array of orders associated with the given service
 * @returns the total cost of a service
 */
export const ServiceTotal = (service: Service, orderData?: Order[]) => {
  let labourCost = parseFloat(service?.labourCost);
  let sundries = parseFloat(service?.sundries); // String in database
  let environmentalLevy = parseFloat(service?.environmentalLevy); // String in database
  let addedCosts = TotalAddedCosts(service?.addedCosts);
  let miscCosts = TotalMiscCosts(service?.miscCosts);
  let orderParts = 0;
  if (orderData) {
    orderParts = TotalOrderParts(service, orderData);
  }

  let totalCost =
    labourCost +
    sundries +
    environmentalLevy +
    addedCosts +
    miscCosts +
    orderParts;

  return totalCost;
};

/**
 * TotalAmountPaid
 * Calculate the total amount paid for a service
 * @author Estienne
 * @param service a customer's service
 * @param orderData (optional) an array of orders associated with the given service
 * @returns the total amount paid for a service
 */
export const TotalAmountPaid = (service: Service, orderData?: Order[]) => {
  let orderAmountPaid = 0;
  let serviceAmountPaid = 0;
  let servicePaymentLines = service.paymentLines;

  // Are there any orders?
  if (orderData) {
    if (orderData.length > 0) {
      // Summate the amount paid on each order
      orderData.forEach((order) => {
        orderAmountPaid += Number(order.amountPaid);
      });
    }
  }

  // Are there any payment lines?
  if (servicePaymentLines.length > 0) {
    // Summate the amount from each line
    servicePaymentLines.forEach((line) => {
      serviceAmountPaid += line.amount;
    });
  }

  // Add together the amounts
  let totalAmountPaid = orderAmountPaid + serviceAmountPaid;
  return totalAmountPaid;
};
