import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const SaveOrderNotes = (
    OrderID: number,
    orderNotes: string,
    OnlineOrderID: string,
    onlineOrderNotes: string,
    setNotesDialogOpen: Dispatch<SetStateAction<boolean>>,
    handleUpdateAllStatesNotes: (
        newNotes: string,
        OrderId: any,
        onlineOrderNewNotes: string
    ) => void,
    showSnackbar: showSnackbar
) => {
    api.put(`/updateOrderNotes`, {
        OrderID: OrderID,
        notes: orderNotes,
        OnlineOrderID: OnlineOrderID,
        onlineOrderNotes: onlineOrderNotes
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Notes successfully updated.');
            setNotesDialogOpen(false);

            handleUpdateAllStatesNotes(orderNotes, OrderID, onlineOrderNotes);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default SaveOrderNotes;
