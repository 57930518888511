// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { CurrencyExchange, Delete, Save } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
import Fab from '../../../global/Fab';
import SpeedDial from '../../../global/SpeedDial';
// LOGIC
import HandleUpdatePurchaseOrder from '../logic/HandleUpdatePurchaseOrder';
import HandleSubmitPurchaseOrder from '../logic/HandleSubmitPurchaseOrder';
import GetAllCreditors from '../../../global/databaseLogic/GetAllCreditors';
import GetAllServices from '../../../global/databaseLogic/GetAllServices';
import HandleDeletePurchaseOrder from '../logic/HandleDeletePurchaseOrder';
// INTERFACES
import {
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { StockInvoice } from '../../../global/interfaces/PartsInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface PurchaseOrderDrawerContentProps {
    selectedPurchaseOrder?: PurchaseOrder;
    setSelectedPurchaseOrder?: Dispatch<SetStateAction<PurchaseOrder>>;
    insideService?: boolean;
    purchaseOrders?: PurchaseOrder[];
    setPurchaseOrders?: Dispatch<SetStateAction<PurchaseOrder[]>>;
    setDrawerOpen?: Dispatch<SetStateAction<boolean>>;
    serviceDetails?: Service;
    setServiceDetails?: Dispatch<SetStateAction<Service>>;
    showSnackbar: showSnackbar;
}
const PurchaseOrderDrawerContent = ({
    selectedPurchaseOrder,
    setSelectedPurchaseOrder,
    insideService,
    purchaseOrders,
    setPurchaseOrders,
    setDrawerOpen,
    serviceDetails,
    setServiceDetails,
    showSnackbar
}: PurchaseOrderDrawerContentProps) => {
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [creditors, setCreditors] = useState<Creditor[]>([]);
    const [services, setServices] = useState<Service[]>([]);

    const [isInvoice, setIsInvoice] = useState<boolean>(false);
    const [invoiceDetails, setInvoiceDetails] = useState<StockInvoice>({});

    let actionsList = [
        { icon: <Save />, name: 'Save', onClick: () => handleFabClick() },
        {
            icon: <CurrencyExchange />,
            name: 'Convert to Invoice',
            onClick: () => {
                setIsInvoice(true);
                setInvoiceDetails({
                    ...invoiceDetails,
                    Service: selectedPurchaseOrder.Service,
                    ServiceId: selectedPurchaseOrder.ServiceId,
                    gstMethod: selectedPurchaseOrder.includingGST
                        ? 'including'
                        : 'excluding',
                    documentReference: selectedPurchaseOrder.orderReference,
                    documentTotal: selectedPurchaseOrder.amount.toString()
                });
            },
            noDisplay: !selectedPurchaseOrder?.id
        },
        {
            icon: <Delete />,
            name: 'Delete',
            onClick: () => handleDeletePurchaseOrder()
        }
    ];

    useEffect(() => {
        GetAllCreditors(setCreditors);
        GetAllServices(setServices);
        if (selectedPurchaseOrder?.id) {
            setReadOnly(true);
        }
        // eslint-disable-next-line
    }, []);

    const handleDeletePurchaseOrder = () => {
        if (insideService) {
            HandleDeletePurchaseOrder(
                selectedPurchaseOrder.id,
                showSnackbar,
                true,
                purchaseOrders,
                setPurchaseOrders,
                setDrawerOpen,
                serviceDetails,
                setServiceDetails
            );
        } else {
            HandleDeletePurchaseOrder(selectedPurchaseOrder.id, showSnackbar);
        }
    };

    const handleFabClick = () => {
        if (readOnly) {
            setReadOnly(false);
        } else if (selectedPurchaseOrder?.id) {
            if (insideService) {
                HandleUpdatePurchaseOrder(
                    selectedPurchaseOrder,
                    showSnackbar,
                    isInvoice,
                    invoiceDetails,
                    true,
                    setReadOnly,
                    purchaseOrders,
                    setPurchaseOrders,
                    serviceDetails,
                    setServiceDetails
                );
            } else {
                HandleUpdatePurchaseOrder(
                    selectedPurchaseOrder,
                    showSnackbar,
                    isInvoice,
                    invoiceDetails
                );
            }
        } else {
            if (insideService) {
                HandleSubmitPurchaseOrder(
                    selectedPurchaseOrder,
                    setSelectedPurchaseOrder,
                    showSnackbar,
                    true,
                    purchaseOrders,
                    setPurchaseOrders,
                    setDrawerOpen,
                    serviceDetails,
                    setServiceDetails
                );
            } else {
                HandleSubmitPurchaseOrder(
                    selectedPurchaseOrder,
                    setSelectedPurchaseOrder,
                    showSnackbar
                );
            }
        }
    };

     return (
         <>
             {selectedPurchaseOrder?.InvoiceId &&
             selectedPurchaseOrder.Invoice ? (
                 <>
                     <Typography>
                         Invoice #
                         <Typography
                             sx={{ color: '#9370DB', cursor: 'pointer' }}
                             onClick={() =>
                                 window.open(
                                     `/inventory/viewInvoice/${selectedPurchaseOrder?.InvoiceId}`,
                                     '_blank'
                                 )
                             }
                         >
                             {selectedPurchaseOrder?.InvoiceId}
                         </Typography>
                     </Typography>
                     <br />
                 </>
             ) : null}
             <Grid container spacing={2}>
                 <Grid item xs={6}>
                     <Autocomplete
                         size="small"
                         isDisabled={readOnly}
                         options={services}
                         useTwoOptionLabels={true}
                         primaryOptionLabel="id"
                         secondaryOptionLabel="jobType"
                         textfieldLabel="Service"
                         selectedValue={selectedPurchaseOrder?.Service}
                         handleSelectedValueChange={(newValue) => {
                             setSelectedPurchaseOrder({
                                 ...selectedPurchaseOrder,
                                 Service: newValue,
                                 ServiceId: newValue.id,
                                 includingGST:
                                     newValue.jobType === 'Internal'
                                         ? false
                                         : selectedPurchaseOrder.includingGST
                             });
                         }}
                         handleInputValueChange={() => null}
                     />
                 </Grid>
                 <Grid item xs={6}>
                     {isInvoice ? (
                         // FOR INVOICE
                         <FormControl>
                             <RadioGroup
                                 row
                                 value={invoiceDetails.gstMethod}
                                 onChange={(e) => {
                                     setInvoiceDetails({
                                         ...invoiceDetails,
                                         gstMethod: e.target.value
                                     });
                                 }}
                             >
                                 <FormControlLabel
                                     value="including"
                                     control={<Radio disabled={readOnly} />}
                                     label="INC. GST"
                                 />
                                 <FormControlLabel
                                     value="excluding"
                                     control={<Radio disabled={readOnly} />}
                                     label="EX. GST"
                                 />
                             </RadioGroup>
                         </FormControl>
                     ) : (
                         // FOR PURCHASE ORDERS
                         <RadioGroup
                             row
                             value={selectedPurchaseOrder.includingGST}
                             onChange={(e) => {
                                 setSelectedPurchaseOrder({
                                     ...selectedPurchaseOrder,
                                     includingGST: e.target.value === 'true'
                                 });
                             }}
                         >
                             <FormControlLabel
                                 value={'true'}
                                 control={
                                     <Radio
                                         disabled={
                                             readOnly ||
                                             !selectedPurchaseOrder.ServiceId ||
                                             selectedPurchaseOrder.Service
                                                 ?.jobType === 'Internal'
                                         }
                                     />
                                 }
                                 label="INC. GST"
                             />
                             <FormControlLabel
                                 value={'false'}
                                 control={
                                     <Radio
                                         disabled={
                                             readOnly ||
                                             !selectedPurchaseOrder.ServiceId
                                         }
                                     />
                                 }
                                 label="EX. GST"
                             />
                         </RadioGroup>
                     )}
                 </Grid>
                 <Grid item xs={12}>
                     {isInvoice ? (
                         <TextField
                             fullWidth
                             disabled={readOnly}
                             size="small"
                             label={`Document Total ${invoiceDetails.gstMethod === 'including' ? '(INC. GST)' : '(EX. GST)'}`}
                             type="number"
                             InputLabelProps={{ shrink: true }}
                             value={invoiceDetails?.documentTotal}
                             onChange={(e) =>
                                 setInvoiceDetails({
                                     ...invoiceDetails,
                                     documentTotal: e.target.value
                                 })
                             }
                         />
                     ) : (
                         <TextField
                             fullWidth
                             disabled={readOnly}
                             size="small"
                             label={`Amount ${selectedPurchaseOrder.includingGST ? '(INC. GST)' : '(EX. GST)'}`}
                             type="number"
                             InputLabelProps={{ shrink: true }}
                             value={selectedPurchaseOrder?.amount}
                             onChange={(e) =>
                                 setSelectedPurchaseOrder({
                                     ...selectedPurchaseOrder,
                                     amount: parseFloat(e.target.value)
                                 })
                             }
                         />
                     )}
                 </Grid>
                 <Grid item xs={12}>
                     <Autocomplete
                         size="small"
                         isDisabled={readOnly}
                         options={creditors}
                         useTwoOptionLabels={false}
                         primaryOptionLabel="name"
                         textfieldLabel="Creditor"
                         selectedValue={selectedPurchaseOrder?.Supplier}
                         handleSelectedValueChange={(newValue) =>
                             setSelectedPurchaseOrder({
                                 ...selectedPurchaseOrder,
                                 Supplier: newValue,
                                 SupplierId: newValue.id
                             })
                         }
                         handleInputValueChange={() => null}
                     />
                 </Grid>
                 <Grid item xs={12}>
                     {isInvoice ? (
                         <TextField
                             fullWidth
                             disabled={readOnly}
                             size="small"
                             label="Document Reference"
                             InputLabelProps={{ shrink: true }}
                             value={invoiceDetails?.documentReference}
                             onChange={(e) =>
                                 setInvoiceDetails({
                                     ...invoiceDetails,
                                     documentReference: e.target.value
                                 })
                             }
                         />
                     ) : (
                         <TextField
                             fullWidth
                             disabled={readOnly}
                             size="small"
                             label="Order Reference"
                             InputLabelProps={{ shrink: true }}
                             value={selectedPurchaseOrder?.orderReference}
                             onChange={(e) =>
                                 setSelectedPurchaseOrder({
                                     ...selectedPurchaseOrder,
                                     orderReference: e.target.value
                                 })
                             }
                         />
                     )}
                 </Grid>
                 {isInvoice ? (
                     <>
                         <Grid item xs={6}>
                             <LocalizationProvider
                                 dateAdapter={AdapterDayjs}
                                 adapterLocale="en-gb"
                             >
                                 <DatePicker
                                     disabled={readOnly}
                                     format="DD/MM/YYYY"
                                     label="Document Date *"
                                     value={
                                         typeof invoiceDetails?.invoiceDate ==
                                         'string'
                                             ? dayjs(
                                                   invoiceDetails?.invoiceDate
                                               )
                                             : invoiceDetails?.invoiceDate
                                     }
                                     onChange={(newValue) => {
                                         setInvoiceDetails({
                                             ...invoiceDetails,
                                             invoiceDate: newValue.toISOString()
                                         });
                                     }}
                                     slotProps={{
                                         textField: {
                                             fullWidth: true,
                                             error: false,
                                             size: 'small',
                                             InputLabelProps: { shrink: true }
                                         }
                                     }}
                                 />
                             </LocalizationProvider>
                         </Grid>
                     </>
                 ) : null}
             </Grid>

             {readOnly || !selectedPurchaseOrder?.id ? (
                 <Fab
                     noSession={true}
                     editIcon={readOnly}
                     onClick={() => handleFabClick()}
                 />
             ) : (
                 <SpeedDial actionsList={actionsList} />
             )}
         </>
     );
};

export default withSnackbar(PurchaseOrderDrawerContent);
