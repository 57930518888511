import api from '../../../../../../../api';
import { Dayjs } from 'dayjs';
import { Site } from '../../../../../global/interfaces/GeneralInterface';
import { Dispatch, SetStateAction } from 'react';
import {
    CalculatedAccount,
    OraAccount
} from '../interface/TrialBalanceInterfaces';

const CalculateTrialBalance = (
    startDate: Dayjs,
    endDate: Dayjs,
    site: string | Site,
    setTrialBalanceData: Dispatch<
        SetStateAction<{
            oraAccount: OraAccount[];
            calculatedAccount: CalculatedAccount[];
        }>
    >,
    setLoading: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    api.get(
        `trialBalance?site=${typeof site === 'string' ? site : site.id}&startDate=${startDate}&endDate=${endDate}`
    ).then((res) => {
        setTrialBalanceData(res.data);
        setLoading(false);
    });
};

export default CalculateTrialBalance;
