// IMPORTS
import { Dispatch, SetStateAction } from 'react'
// LOGIC
import api from '../../../../api'
// INTERFACES
import { Invoice } from '../interfaces/PartsInterface'

const GetAllPaymentRunForSupplier = (CreditorId: number, setPaymentRuns: Dispatch<SetStateAction<{id: number, invoices: Invoice[]}[]>>) => {
    api.get('payRunBySupplierId/' + CreditorId.toString()).then(res => {
        setPaymentRuns(res.data)
    })
}

export default GetAllPaymentRunForSupplier