// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { PaymentLine } from '../interfaces/GeneralInterface';
import { showSnackbar } from '../interfaces/GlobalInterface';
import { ToFixed } from './Formatters';

const ValidateVoucher = (
    paymentAmount: number,
    voucherCode: string,
    selectedTillId: number,
    paymentLines: PaymentLine[],
    paymentMethod: string,
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>,
    setPaymentMethod: Dispatch<SetStateAction<string>>,
    setVoucherCode: Dispatch<SetStateAction<string>>,
    showSnackbar: showSnackbar
) => {
    let currentPaymentLines = [...paymentLines];

    // Check that there is a valid payment amount entered (we allow negatives here for dodgy refunds)
    if (!paymentAmount || paymentAmount === 0) {
        showSnackbar(
            'Please enter a valid payment amount to continue.',
            '',
            'error'
        );
        return;
    }

    // Check if there has been a till selected
    if (!selectedTillId) {
        showSnackbar('Please select a till to continue.', '', 'error');
        return;
    }

    api.get(`getVoucherBalance/${voucherCode.toUpperCase()}`).then((res) => {
        if (res.status === 404) {
            // voucher code not found in database
            showSnackbar(
                'Voucher with that code not found.',
                'Please check the code you have entered.',
                'error'
            );
            return;
        } else if (res.status === 500) {
            // Backend error
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            return;
        } else {
            // Voucher code was found
            let balance = res.data;

            // Check that the voucher actually has some money on it
            if (balance <= 0) {
                showSnackbar(
                    'This voucher has no remaining funds.',
                    '',
                    'error'
                );
                return;
            }

            // If we are using an amount entered by the user then check the voucher has enough to cover it
            if (balance < paymentAmount) {
                showSnackbar(
                    'This voucher does not have enough funds to cover the entered amount.',
                    '',
                    'error'
                );
                return;
            }

            // Get the current date and time in our timezone
            var tzOffset = new Date().getTimezoneOffset() * 60000;
            var localIsoTime = new Date(Date.now() - tzOffset)
                .toISOString()
                .slice(0, -1);

            // Calculate the new row id
            // If there is no orderlines simply set it to 0
            // Otherwise set it to the last id in the orderlines plus 1
            let rowId: number;
            if (currentPaymentLines.length > 0) {
                rowId = currentPaymentLines.at(-1)['id'] + 1;
            } else {
                rowId = 0;
            }

            // Make the payment row
            var newRow = {
                id: rowId,
                type: paymentMethod,
                amountReceived: ToFixed(paymentAmount), // This is how much we physically received from the customer
                amount: ToFixed(paymentAmount), // This is how much we tilled (this is just the amount received minus any change)
                change: 0, // The change we gave the customer
                TillId: selectedTillId,
                date: localIsoTime.split('T')[0],
                voucher: voucherCode
            };

            currentPaymentLines.push(newRow);

            setPaymentLines(currentPaymentLines);
            setVoucherCode('');
            setPaymentMethod('cash');
        }
    });
};

export default ValidateVoucher;
