// IMPROTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import Dayjs from 'dayjs';

// COMPONENTS
import Autocomplete from '../../../../global/Autocomplete';
import Paper from '../../../../global/Paper';
import SiteSelector from '../../../../global/SiteSelector';
// LOGIC
import GetAllSuppliers from '../../../../global/databaseLogic/GetAllSuppliers';
import GetAllAccounts from '../../../../global/databaseLogic/GetAllAccounts';
import GetAllWarrantyServices from '../../logic/GetAllWarrantyServices';
import CheckDocumentReference from '../../../../global/logic/CheckDocumentReference';
// INTERFACES
import { Creditor, Site } from '../../../../global/interfaces/GeneralInterface';
import { Account } from '../../../../global/interfaces/AdminInterface';
import { Service } from '../../../../global/interfaces/ServiceInterface';

interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Account: Account;
    Service?: Service;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
    documentReference: string;
}
interface InvoiceLine {
    id: number;
    Service?: Service;
    amount: string;
    description: string;
}
interface InvoiceHeaderProps {
    selectedSite: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
    invoiceObject: InvoiceObject;
    setInvoiceObject: Dispatch<SetStateAction<InvoiceObject>>;
    invoiceLines: InvoiceLine[];
    setInvoiceLines: Dispatch<SetStateAction<InvoiceLine[]>>;
    responseCode: number;
    setResponseCode: Dispatch<SetStateAction<number>>;
    isDisabled?: boolean;
}

const InvoiceHeader = ({
    selectedSite,
    setSelectedSite,
    invoiceObject,
    setInvoiceObject,
    invoiceLines,
    setInvoiceLines,
    responseCode,
    setResponseCode,
    isDisabled
}: InvoiceHeaderProps) => {
    const [creditors, setCreditors] = useState<Creditor[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [searchVin, setSearchVin] = useState<boolean>(false);

    // Get all the creditors, sites, vehicles and accounts
    useEffect(() => {
        GetAllSuppliers(setCreditors);
        GetAllWarrantyServices(setServices);
        GetAllAccounts(setAccounts);
    }, []);

    useEffect(() => {
        if (invoiceObject.Service?.id) {
            // Get the current lines and calculate a new ID
            let currentLines = [...invoiceLines];
            let newId =
                currentLines.length > 0
                    ? currentLines[currentLines.length - 1].id + 1
                    : 1;

            // Generate the new line
            let newLine = {
                id: newId,
                Service: invoiceObject.Service,
                amount: invoiceObject.Service.totalPrice,
                description: null
            };

            // Push the new line and update the state
            currentLines.push(newLine);
            setInvoiceLines(currentLines);
            setInvoiceObject({ ...invoiceObject, Service: null });
        }
        // eslint-disable-next-line
    }, [invoiceObject.Service]);

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            isDisabled={
                                (invoiceObject.Account ? true : false) ||
                                isDisabled
                            }
                            options={creditors}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Creditor *"
                            selectedValue={invoiceObject.Creditor}
                            handleSelectedValueChange={(newValue) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    Creditor: newValue
                                })
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={2.5}>
                        <Autocomplete
                            size="small"
                            isDisabled={
                                (invoiceObject.Creditor ? true : false) ||
                                isDisabled
                            }
                            options={accounts}
                            useTwoOptionLabels={true}
                            primaryOptionLabel="name"
                            secondaryOptionLabel="siteName"
                            textfieldLabel="Account *"
                            selectedValue={invoiceObject.Account}
                            handleSelectedValueChange={(newValue) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    Account: newValue
                                })
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={2.5}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Document Reference"
                            error={responseCode === 400 ? true : false}
                            helperText={
                                responseCode === 400
                                    ? 'Document Reference Already Exists.'
                                    : ''
                            }
                            InputLabelProps={{ shrink: true }}
                            value={invoiceObject.documentReference}
                            onChange={(e) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    documentReference: e.target.value
                                })
                            }
                            onBlur={(e) =>
                                CheckDocumentReference(
                                    e.target.value,
                                    setResponseCode
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={isDisabled}
                                format="DD/MM/YYYY"
                                label="Document Date *"
                                value={Dayjs(invoiceObject.documentDate)}
                                onChange={(newValue) => {
                                    setInvoiceObject({
                                        ...invoiceObject,
                                        documentDate: newValue.toISOString()
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={isDisabled}
                                format="DD/MM/YYYY"
                                label="Due Date"
                                value={Dayjs(invoiceObject.dueDate)}
                                onChange={(newValue) => {
                                    setInvoiceObject({
                                        ...invoiceObject,
                                        dueDate: newValue.toISOString()
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={2}>
                        <SiteSelector
                            variant="outlined"
                            disabled={false}
                            fullWidth={true}
                            viewPartUI={false}
                            label="Site"
                            size="small"
                            selectedSite={selectedSite}
                            setSelectedSite={setSelectedSite}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            disabled={isDisabled}
                            size="small"
                            label="Document Total * (INC. GST)"
                            InputLabelProps={{ shrink: true }}
                            value={invoiceObject.documentTotal}
                            onChange={(e) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    documentTotal: e.target.value
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>

            {isDisabled ? null : (
                <>
                    <br />
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={2} textAlign="center">
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <Typography>Service ID</Typography>
                                    <Switch
                                        checked={searchVin}
                                        onChange={(e) =>
                                            setSearchVin(e.target.checked)
                                        }
                                    />
                                    <Typography>VIN</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    size="small"
                                    options={services}
                                    useTwoOptionLabels={true}
                                    primaryOptionLabel={
                                        searchVin ? 'vehicleVin' : 'stringId'
                                    }
                                    secondaryOptionLabel={'vehicleName'}
                                    textfieldLabel="Service *"
                                    selectedValue={invoiceObject.Service}
                                    handleSelectedValueChange={(newValue) =>
                                        setInvoiceObject({
                                            ...invoiceObject,
                                            Service: newValue
                                        })
                                    }
                                    handleInputValueChange={() => null}
                                />
                            </Grid>
                            <Grid item xs={5}></Grid>
                        </Grid>
                    </Paper>
                </>
            )}
        </>
    );
};

export default InvoiceHeader;
