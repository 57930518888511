// IMPORTS
import { useEffect, useState } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';

// LOGIC
import { GetAllAccountsCategorySpecific } from '../../../global/databaseLogic/GetAllAccountsCategorySpecific';
import { HandleMergeAccount } from '../logic/HandleMergeAccount';

// INTERFACES
import { Account } from '../../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface AccountMergeDialogContentProps {
    selectedAccount: Account;
    showSnackbar: showSnackbar;
}

export const AccountMergeDialogContent = ({
    selectedAccount,
    showSnackbar
}: AccountMergeDialogContentProps) => {
    const [accountList, setAccountList] = useState<Account[]>([]);
    const [accountToMergeInto, setAccountToMergeInto] = useState<Account>(null);

    useEffect(() => {
        GetAllAccountsCategorySpecific(
            setAccountList,
            selectedAccount?.AccountCategory?.name
        );
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <DialogTitle>{`Merge ${selectedAccount.name} into another account.`}</DialogTitle>
            <DialogContent>
                {selectedAccount?.AccountCategory?.name?.length > 0 ? (
                    <DialogContentText>
                        You can only merge this account into an account of the
                        same type ({selectedAccount.AccountCategory.name}).
                    </DialogContentText>
                ) : null}
                <DialogContentText>
                    Please select the account you want to merge the current one
                    into:
                </DialogContentText>
                <br />
                <Autocomplete
                    size="small"
                    options={accountList.filter(
                        (x) => x.id !== selectedAccount.id
                    )}
                    useTwoOptionLabels={true}
                    primaryOptionLabel="name"
                    secondaryOptionLabel="id"
                    textfieldLabel="Account *"
                    selectedValue={accountToMergeInto}
                    includeBar={true}
                    handleSelectedValueChange={(newValue) =>
                        setAccountToMergeInto(newValue)
                    }
                    handleInputValueChange={() => null}
                />
            </DialogContent>
            <DialogActions>
                <Button>CANCEL</Button>
                <Button
                    variant="contained"
                    disabled={accountToMergeInto == null}
                    onClick={() =>
                        HandleMergeAccount(
                            selectedAccount.id,
                            accountToMergeInto.id,
                            showSnackbar
                        )
                    }
                >
                    PROCEED
                </Button>
            </DialogActions>
        </>
    );
};
