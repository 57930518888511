// IMPROTS
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Fab from '../Fab';
import { withSnackbar } from '../WrappingSnackbar';
// LOGIC
import HandleUpdateCustomer from '../../general/customer/logic/HandleUpdateCustomer';
import CheckCustomerPhoneNumber from '../../general/customer/logic/CheckCustomerPhoneNumber';
// INTERFACE
import { Customer } from '../interfaces/GeneralInterface';
import { showSnackbar } from '../interfaces/GlobalInterface';

interface CustomerDrawerContentProps {
    customer: Customer;
    setCustomer: Dispatch<SetStateAction<Customer>>;
    setSelectedEntity?;
    resultsList?;
    setResultsList?;
    refreshOnUpdate?: boolean;
    setCustomerDrawerOpen?: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const CustomerDrawerContent = ({
    customer,
    setCustomer,
    setSelectedEntity,
    resultsList,
    setResultsList,
    refreshOnUpdate = false,
    setCustomerDrawerOpen,
    showSnackbar
}: CustomerDrawerContentProps) => {
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [responseCode, setResponseCode] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!customer?.id) {
            setReadOnly(false);
        }
        // eslint-disable-next-line
    }, []);

    const handleFabClick = () => {
        if (loading) return;

        if (readOnly) {
            setReadOnly(false);
        } else {
            setLoading(true);
            HandleUpdateCustomer({
                customerInfo: customer,
                showSnackbar: showSnackbar,
                refresh: refreshOnUpdate,
                setReadOnly: setReadOnly,
                resultsList: resultsList,
                setResultsList: setResultsList,
                setSelectedEntity: setSelectedEntity,
                setCustomerDrawerOpen: setCustomerDrawerOpen
            });
        }
    };

    return (
        <>
            <Typography variant="h6">Basic Details</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        data-cy="customerTypeSelector"
                        disabled={readOnly}
                        fullWidth
                        select
                        size="small"
                        label="Customer Type"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.customerType}
                        onChange={(e) =>
                            setCustomer({
                                ...customer,
                                customerType: e.target.value
                            })
                        }
                    >
                        <MenuItem value="Retail" data-cy="retailMenuItem">
                            Retail
                        </MenuItem>
                        <MenuItem value="Trade" data-cy="tradeMenuItem">
                            Trade
                        </MenuItem>
                    </TextField>
                </Grid>
                {readOnly ? (
                    <Grid item xs={6}>
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            size="small"
                            label="Full Name"
                            InputLabelProps={{ shrink: true }}
                            value={
                                customer?.firstName + ' ' + customer?.lastName
                            }
                        />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={3}>
                            <TextField
                                data-cy="customerFirstName"
                                disabled={readOnly}
                                fullWidth
                                size="small"
                                label="First Name"
                                InputLabelProps={{ shrink: true }}
                                value={customer?.firstName}
                                onChange={(e) =>
                                    setCustomer({
                                        ...customer,
                                        firstName: e.target.value
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                data-cy="customerLastName"
                                disabled={readOnly}
                                fullWidth
                                size="small"
                                label="Last Name"
                                InputLabelProps={{ shrink: true }}
                                value={customer?.lastName}
                                onChange={(e) =>
                                    setCustomer({
                                        ...customer,
                                        lastName: e.target.value
                                    })
                                }
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={6}>
                    <TextField
                        data-cy="companyName"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Company Name"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.companyName}
                        onChange={(e) =>
                            setCustomer({
                                ...customer,
                                companyName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        data-cy="phoneNumber"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Phone Number"
                        helperText={
                            responseCode === 400
                                ? 'Customer Phone Number Already Exists.'
                                : ''
                        }
                        InputLabelProps={{ shrink: true }}
                        value={customer?.phoneNumber}
                        onChange={(e) =>
                            setCustomer({
                                ...customer,
                                phoneNumber: e.target.value
                            })
                        }
                        onBlur={(e) =>
                            CheckCustomerPhoneNumber(
                                e.target.value,
                                setResponseCode
                            )
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        data-cy="emailAddress"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Email Address"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.email}
                        onChange={(e) =>
                            setCustomer({ ...customer, email: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        data-cy="ABN"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="ABN"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.abn}
                        onChange={(e) =>
                            setCustomer({ ...customer, abn: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            label="Date of Birth"
                            disabled={readOnly}
                            value={
                                Object.hasOwn(customer, 'dateOfBirth')
                                    ? dayjs(customer?.dateOfBirth)
                                    : null
                            }
                            onChange={(newValue) =>
                                setCustomer({
                                    ...customer,
                                    dateOfBirth: dayjs(newValue).toISOString()
                                })
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true },
                                    inputProps: {
                                        style: { textAlign: 'center' }
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        data-cy="driverLicenceNumber"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Driver Licence Number"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.driverLicenceNumber}
                        onChange={(e) =>
                            setCustomer({
                                ...customer,
                                driverLicenceNumber: e.target.value
                            })
                        }
                    />
                </Grid>
            </Grid>
            <br />
            <Typography variant="h6">Address Details</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        data-cy="addressLine1"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Address Line 1"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.addressLine1}
                        onChange={(e) =>
                            setCustomer({
                                ...customer,
                                addressLine1: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        data-cy="addressLine2"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Address Line 2"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.addressLine2}
                        onChange={(e) =>
                            setCustomer({
                                ...customer,
                                addressLine2: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4.5}>
                    <TextField
                        data-cy="suburb"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Suburb"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.suburb}
                        onChange={(e) =>
                            setCustomer({ ...customer, suburb: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <TextField
                        data-cy="postcode"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Postcode"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.postcode}
                        onChange={(e) =>
                            setCustomer({
                                ...customer,
                                postcode: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <TextField
                        data-cy="state"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="State"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.state}
                        onChange={(e) =>
                            setCustomer({ ...customer, state: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <TextField
                        data-cy="country"
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Country"
                        InputLabelProps={{ shrink: true }}
                        value={customer?.country}
                        onChange={(e) =>
                            setCustomer({
                                ...customer,
                                country: e.target.value
                            })
                        }
                    />
                </Grid>
            </Grid>
            <br />
            <Typography variant="h6">Options</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                disabled={readOnly}
                                checked={customer?.international ? true : false}
                                onChange={(e) =>
                                    setCustomer({
                                        ...customer,
                                        international: e.target.checked
                                    })
                                }
                            />
                        }
                        label="International"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                disabled={readOnly}
                                checked={customer?.gstFree ? true : false}
                                onChange={(e) =>
                                    setCustomer({
                                        ...customer,
                                        gstFree: e.target.checked
                                    })
                                }
                            />
                        }
                        label="GST Free"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                disabled={readOnly}
                                checked={customer?.gstRegistered ? true : false}
                                onChange={(e) =>
                                    setCustomer({
                                        ...customer,
                                        gstRegistered: e.target.checked
                                    })
                                }
                            />
                        }
                        label="GST Registered"
                    />
                </Grid>
                {customer.customerType === 'Trade' ? (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size="small"
                            type="number"
                            variant="outlined"
                            disabled={readOnly}
                            label="Trade Discount"
                            value={customer?.tradeDiscount}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setCustomer({
                                    ...customer,
                                    tradeDiscount: parseFloat(e.target.value)
                                })
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Chip
                                            size="small"
                                            disabled={readOnly}
                                            color={
                                                customer?.discountType ===
                                                'Trade'
                                                    ? 'success'
                                                    : 'warning'
                                            }
                                            label={
                                                customer?.discountType ===
                                                'Trade'
                                                    ? 'Cost +'
                                                    : 'Retail -'
                                            }
                                            clickable={true}
                                            onClick={() => {
                                                if (
                                                    customer?.discountType ===
                                                    'Trade'
                                                ) {
                                                    setCustomer({
                                                        ...customer,
                                                        discountType: 'Retail'
                                                    });
                                                } else {
                                                    setCustomer({
                                                        ...customer,
                                                        discountType: 'Trade'
                                                    });
                                                }
                                            }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        ></TextField>
                    </Grid>
                ) : null}
            </Grid>

            <Fab
                cypressLabel="submitCustomer"
                editIcon={readOnly}
                onClick={() => handleFabClick()}
                noSession={true}
            />
        </>
    );
};

export default withSnackbar(CustomerDrawerContent);
