import Paper from '../../../global/Paper';
import { Typography, Button, Grid, useTheme } from '@mui/material';

const VehicleSaleCounter = ({
    allPendingManufacturerOrdersData,
    currentMonthFinalisedVehicleSalesData,
    currentMonthSoldVehicleSalesData,
    // setBreakdownData,
    setDataType
}) => {
    const currentMode = useTheme().palette.mode;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Finalised</Typography>
                        <Button
                            onClick={() => {
                                // setBreakdownData(
                                //     currentMonthFinalisedVehicleSalesData
                                // );
                                setDataType('finalised');
                            }}
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {currentMonthFinalisedVehicleSalesData.length}{' '}
                            </Typography>
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Sale</Typography>
                        <Button
                            onClick={() => {
                                // setBreakdownData(
                                //     currentMonthFinalisedVehicleSalesData
                                // );
                                setDataType('sale');
                            }}
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {currentMonthSoldVehicleSalesData.length}{' '}
                            </Typography>
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">
                            All Pending Manufacturer Orders
                        </Typography>
                        <Button
                            onClick={() => {
                                // setBreakdownData(
                                //     allPendingManufacturerOrdersData
                                // );
                                setDataType('manufacturer');
                            }}
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {allPendingManufacturerOrdersData.length}{' '}
                            </Typography>
                        </Button>
                        <Typography variant="h5"></Typography>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default VehicleSaleCounter;
