import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const CreateModel = (
    selectedMake,
    name: string,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    api.post('model', { name: name, MakeId: selectedMake.id }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Successfully created model.');
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar('Model already exists.', '', 'error');
        } else {
            showSnackbar(
                'Something went wrong, refresh the page and try again.',
                '',
                'error'
            );
        }
        setButtonLoading(false);
    });
};

export default CreateModel;
