// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';

const CountOnlineOrders = (
  setOnlineOrderCount: Dispatch<SetStateAction<number>>
) => {
  api
    .get(`countOnlineOrders?SiteId=${localStorage.getItem('SiteId')}`)
    .then((res) => {
      setOnlineOrderCount(res.data.totalCount);
    });
};

export default CountOnlineOrders;
