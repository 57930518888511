import { Dayjs } from 'dayjs';

/**
 * ValidateEmpty
 * Check if any of the provided values are null
 * @param fields An array of values to check
 * @returns valid A non-null boolean
 */
export const ValidateEmpty = (fields: any[]) => {
    let valid = true;

    // Iterate through the provided values
    // and check if any are null
    fields.forEach((field) => {
        if (field == null || field === undefined) {
            valid = false;
        }
    });

    return valid;
};

/**
 * ValidateOdometer
 * Check that the provided Odometer value is valid
 * @param odometer the vehicle's odometer
 * @returns valid a non-null boolean
 */
export const ValidateOdometer = (odometer: string) => {
    let valid = true;

    if (odometer !== '' && !Number(odometer)) {
        if (odometer !== '0') {
            valid = false;
        }
    }

    return valid;
};

/**
 * ValidatePrice
 * Check that the provided RRP is valid
 * @param price the vehicle's RRP
 * @returns a non-null boolean
 */
export const ValidateRRP = (price: number) => {
    let valid = true;

    if (!Number(price)) {
        valid = false;
    }

    if (price < 0) {
        valid = false;
    }

    return valid;
};

/**
 * ValidateTyping
 * Check if any of the provided values are not the correct type
 * @param fields An array of values to check
 * @param type The desired type of the provided values
 * @returns valid A non-null boolean
 */
export const ValidateTyping = (fields: any[], type: string) => {
    let valid = true;

    fields.forEach((field) => {
        if (typeof field != type) {
            valid = false;
        }
    });

    return valid;
};

/**
 * ValidateVin
 * Check if the provided VIN is valid
 * @param vin The vehicle's VIN
 * @returns "valid" or an error message
 */
export const ValidateVin = (vin: string) => {
    if (vin === undefined) {
        return 'VIN is undefined.';
    }

    // A VIN is always 17 characters long
    if (vin.length !== 17) {
        return 'VIN must be 17 characters long.';
    }

    // VIN must contain alphanumeric characters only
    if (!vin.match(/^[0-9a-z]+$/i)) {
        return 'VIN must only contain alphanumeric characters.';
    }

    // VIN cannot contain I, O, or Q
    if (
        vin.toUpperCase().includes('I') ||
        vin.toUpperCase().includes('O') ||
        vin.toUpperCase().includes('Q')
    ) {
        return 'VIN cannot contain I, O, or Q.';
    }

    // The last 3 characters of a VIN must be numbers
    let lastThree = vin.substring(vin.length - 3);
    if (!Number(lastThree) && lastThree !== '000') {
        return 'The last 3 characters of a VIN must be numbers';
    }

    return 'valid';
};

/**
 * ValidateYear
 * Check if the provided year is valid
 * @param year The vehicle's year model
 * @returns A string: 'valid' or an error message
 */
export const ValidateYear = (year: Dayjs) => {
    // The year must be four characters
    if (year.format('YYYY').length !== 4) {
        return 'Year Model must be in yyyy format.';
    }

    return 'valid';
};
