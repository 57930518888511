import DocumentationPage from '../DocumentationPage';
import {
    EnterGenericInvoiceSteps,
    EnterRegistationInvoiceSteps,
    EnterVehicleRebateInvoiceSteps,
    EnterWarrantyInvoiceSteps,
    EnterTrustReceiptInvoiceSteps,
    ViewEditGenericInvoiceSteps
} from '../data/admin/enterInvoiceSteps';

const EnterInvoiceSupportPage = () => {
    const contentSections = [
        {
            id: 'enter-generic-invoice',
            title: 'Enter Generic Invoice',
            href: '/support/admin/enterInvoice/#enter-generic-invoice',
            description:
                'To enter a generic invoice for account, vehicle or service, follow these steps:',
            steps: EnterGenericInvoiceSteps
        },
        {
            id: 'enter-registration-invoice',
            title: 'Enter Registration Invoice',
            href: '/support/admin/enterInvoice/#enter-registration-invoice',
            description:
                'To enter a registration invoice for vehicles, follow these steps:',
            steps: EnterRegistationInvoiceSteps
        },
        {
            id: 'enter-vehicle-rebate-invoice',
            title: 'Enter Vehicle Rebate Invoice',
            href: '/support/admin/enterInvoice/#enter-vehicle-rebate-invoice',
            description:
                'To enter a vehicle rebate invoice for vehicles, follow these steps:',
            steps: EnterVehicleRebateInvoiceSteps
        },
        {
            id: 'enter-warranty-invoice',
            title: 'Enter Warranty Invoice',
            href: '/support/admin/enterInvoice/#enter-warranty-invoice',
            description:
                'To enter a warranty invoice for services, follow these steps:',
            steps: EnterWarrantyInvoiceSteps
        },
        {
            id: 'enter-trust-receipt-invoice',
            title: 'Enter Trust Receipt Invoice',
            href: '/support/admin/enterInvoice/#enter-trust-receipt-invoice',
            description:
                'To enter a trust receipt invoice for vehicles, follow these steps:',
            steps: EnterTrustReceiptInvoiceSteps
        },
        {
            id: 'edit-invoice',
            title: 'View And Edit Invoice',
            href: '/support/admin/enterInvoice/#edit-invoice',
            description:
                'To view or edit a specific invoice, follow these steps:',
            steps: ViewEditGenericInvoiceSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Enter Admin Invoice"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default EnterInvoiceSupportPage;
