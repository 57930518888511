// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
// LOGIC
import GetAllSiteTills from '../databaseLogic/GetAllSiteTills';
// INTERFACES
import { Till, User, UserSettings } from '../interfaces/GeneralInterface';
import GetUserSettings from '../databaseLogic/GetUserSettings';

interface TillSelectorProps {
    selectedTillId: number;
    setSelectedTillId: Dispatch<SetStateAction<number>>;
    department: string;
    size?: 'small' | 'medium';
    disabled?: boolean;
    hideLabel?: boolean;
}

const TillSelector = ({
    selectedTillId,
    setSelectedTillId,
    department,
    size,
    disabled,
    hideLabel
}: TillSelectorProps) => {
    const [tills, setTills] = useState<Till[]>([]);
    const [userSettings, setUserSettings] = useState<UserSettings>({});
    const [userDetails, setUserDetails] = useState<User>({});

    useEffect(() => {
        GetAllSiteTills(parseInt(localStorage.getItem('SiteId')), setTills);
        GetUserSettings(setUserSettings, setUserDetails);
    }, []);

    useEffect(() => {
        if (
            userDetails?.SiteId === parseInt(localStorage.getItem('SiteId')) &&
            userSettings?.TillId
        ) {
            setSelectedTillId(userSettings?.TillId);
        } else if (tills.length > 0) {
            // If the department is service then see if there is a service till
            if (department === 'service') {
                tills.forEach((till) => {
                    if (till.name.toLowerCase().includes('service')) {
                        setSelectedTillId(till.id);
                    }
                });
            }

            // If the department is parts or if the department is service and no service till was found
            if (
                department === 'parts' ||
                (department === 'service' && !selectedTillId)
            ) {
                tills.forEach((till) => {
                    if (till.name.toLowerCase().includes('parts')) {
                        setSelectedTillId(till.id);
                    }
                });
            }

            // If the department is parts or service and there is still no selected till id then check for an accessories till
            if (
                (department === 'parts' || department === 'service') &&
                !selectedTillId
            ) {
                tills.forEach((till) => {
                    if (till.name.toLowerCase().includes('accessories')) {
                        setSelectedTillId(till.id);
                    }
                });
            }
            // If it is the sales department
            if (department === 'sales') {
                tills.forEach((till) => {
                    if (till.name.toLowerCase().includes('bike')) {
                        setSelectedTillId(till.id);
                    }
                });
            }
            // If it is in the admin department
            if (department === 'admin') {
                for (let till of tills) {
                    if (till.name.toLowerCase().includes('finance')) {
                        setSelectedTillId(till.id);
                        break;
                    }
                }
            }

            // If all else fails just set the till to the first till in the list
            if (!selectedTillId) {
                setSelectedTillId(tills[0].id);
            }
        }
        // eslint-disable-next-line
    }, [tills, userDetails, userSettings]);

    return (
        <>
            <TextField
                fullWidth
                select
                disabled={disabled}
                size={size ?? 'small'}
                variant="outlined"
                label={hideLabel ? '' : 'Till'}
                value={selectedTillId}
                onChange={(e) => setSelectedTillId(parseInt(e.target.value))}
                InputLabelProps={{ shrink: true }}
            >
                {tills.map((till) => (
                    <MenuItem value={till.id}>{till.name}</MenuItem>
                ))}
            </TextField>
        </>
    );
};

export default TillSelector;
