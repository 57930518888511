import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Dayjs } from 'dayjs';

interface CreditingPartsLine {
    partNumber: string;
    name: string;
    unitId: number;
    costPriceDaily: number;
    quantity: number;
    lineTotal: number;
    qtyError: boolean;
    lineTotalError: boolean;
}

const SubmitCreditInvoice = (
    id: number,
    selectedSupplier: Creditor,
    selectedSite: number,
    reference: string,
    received: Dayjs,
    due: Dayjs,
    creditingParts: CreditingPartsLine[],
    freight: number,
    isGSTIncluded: boolean,
    notes: string,
    setCreditingParts: Dispatch<SetStateAction<CreditingPartsLine[]>>,
    showSnackbar: showSnackbar,
    setLoading: Dispatch<SetStateAction<boolean>>
) => {
    if (!selectedSupplier) {
        showSnackbar('You must select a creditor to continue.', '', 'error');
        return;
    }
    if (!selectedSite) {
        showSnackbar('You must select a site to continue.', '', 'error');
        return;
    }
    if (!reference) {
        showSnackbar('You must enter a reference to continue.', '', 'error');
        return;
    }
    if (!received) {
        showSnackbar(
            'You must enter a date received to continue.',
            '',
            'error'
        );
        return;
    }
    if (!due) {
        showSnackbar('You must enter a date due to continue.', '', 'error');
        return;
    }

    let isError = false;
    for (let part of creditingParts) {
        if (!part.quantity || part.quantity === 0) {
            isError = true;
            part.qtyError = true;
            return;
        }
        if (!part.lineTotal || part.lineTotal === 0) {
            isError = true;
            part.qtyError = true;
            return;
        }
    }

    if (isError) {
        showSnackbar(
            'You must enter a quantity and price for each line item.',
            '',
            'error'
        );
        setCreditingParts(creditingParts);
        return;
    }
    setLoading(true);
    api.post('/creditStockInvoiceNextGen', {
        id: id ?? null,
        supplierId: selectedSupplier.id,
        siteId: selectedSite,
        documentReference: reference,
        notes: notes,
        due: due.toISOString(),
        received: received.toISOString(),
        creditingParts: creditingParts,
        isGSTIncluded: isGSTIncluded,
        freight: freight
    }).then((res) => {
        if (res.status === 200) {
            if (!id) {
                showSnackbar('Credit invoice submitted successfully.');
                window.location.reload();
            } else {
                showSnackbar('Credit invoice updated successfully.');
                window.location.reload();
            }
        } else {
            if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact the IT department.',
                    'error'
                );
                setLoading(false);
            } else {
                showSnackbar(
                    res.data.split('|')[0],
                    res.data.split('|')[1],
                    'error'
                );
                setLoading(false);
            }
        }
    });
};

export default SubmitCreditInvoice;
