import { Check } from '@mui/icons-material';
import {
    CircularProgress,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    LinearProgress,
    Table,
    TableCell,
    TableRow,
    Typography,
    useTheme
} from '@mui/material';
import dayjs from 'dayjs';

export const DataRetrievalDialogContent = ({
    currentPeriods,
    ytdPeriods,
    lastYearPeriods,
    lastYearYTDPeriods,
    progress
}) => {
    const currentMode = useTheme().palette.mode;
    return (
        <>
            <DialogTitle>Retrieving Data for PNL</DialogTitle>
            <LinearProgress variant="determinate" value={progress} />
            <DialogContent>
                <Grid container spacing={0}>
                    <Grid item xs={2.925}>
                        <Table>
                            Current Period
                            {currentPeriods.map((period) => (
                                <>
                                    <TableRow>
                                        <TableCell rowSpan={5}>
                                            <Typography textAlign={'center'}>
                                                {dayjs(period.startDate).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </Typography>{' '}
                                            <Typography textAlign={'center'}>
                                                -
                                            </Typography>{' '}
                                            <Typography textAlign={'center'}>
                                                {dayjs(period.endDate).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.vehicleDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Vehicle Sale Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.paDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            P&A Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.serviceDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Service Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell size="small">
                                            {period.miscDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Misc. Data
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </Table>
                    </Grid>
                    <Grid item xs={0.1}>
                        <Divider
                            sx={{ marginRight: 0.7 }}
                            orientation="vertical"
                        />
                    </Grid>
                    <Grid item xs={2.925}>
                        <Table>
                            Year to Date
                            {ytdPeriods.map((period) => (
                                <>
                                    <TableRow>
                                        <TableCell rowSpan={5}>
                                            <Typography textAlign={'center'}>
                                                {dayjs(period.startDate).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </Typography>{' '}
                                            <Typography textAlign={'center'}>
                                                -
                                            </Typography>{' '}
                                            <Typography textAlign={'center'}>
                                                {dayjs(period.endDate).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.vehicleDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Vehicle Sale Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.paDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            P&A Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.serviceDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Service Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell size="small">
                                            {period.miscDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Misc. Data
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </Table>
                    </Grid>
                    <Grid item xs={0.1}>
                        <Divider
                            sx={{ marginRight: 0.7 }}
                            orientation="vertical"
                        />
                    </Grid>
                    <Grid item xs={2.925}>
                        <Table>
                            Last Year
                            {lastYearPeriods.map((period) => (
                                <>
                                    <TableRow>
                                        <TableCell rowSpan={5}>
                                            <Typography textAlign={'center'}>
                                                {dayjs(period.startDate).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </Typography>{' '}
                                            <Typography textAlign={'center'}>
                                                -
                                            </Typography>{' '}
                                            <Typography textAlign={'center'}>
                                                {dayjs(period.endDate).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.vehicleDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Vehicle Sale Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.paDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            P&A Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.serviceDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Service Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell size="small">
                                            {period.miscDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Misc. Data
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </Table>
                    </Grid>
                    <Grid item xs={0.1}>
                        <Divider
                            sx={{ marginRight: 0.7 }}
                            orientation="vertical"
                        />
                    </Grid>
                    <Grid item xs={2.925}>
                        <Table>
                            Last Year YTD
                            {lastYearYTDPeriods.map((period) => (
                                <>
                                    <TableRow>
                                        <TableCell rowSpan={5}>
                                            <Typography textAlign={'center'}>
                                                {dayjs(period.startDate).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </Typography>{' '}
                                            <Typography textAlign={'center'}>
                                                -
                                            </Typography>{' '}
                                            <Typography textAlign={'center'}>
                                                {dayjs(period.endDate).format(
                                                    'DD MMM YYYY'
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.vehicleDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Vehicle Sale Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.paDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            P&A Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                            size="small"
                                        >
                                            {period.serviceDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Service Data
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell size="small">
                                            {period.miscDataCollected ? (
                                                <Check
                                                    style={{ color: 'green' }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    sx={{
                                                        color:
                                                            currentMode ===
                                                            'dark'
                                                                ? '#8d65dd'
                                                                : '#9370DB'
                                                    }}
                                                    size="15px"
                                                />
                                            )}{' '}
                                            Misc. Data
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </Table>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};
