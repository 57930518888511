import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { PaymentRun } from '../../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const HandleUnreconcilePayrun = (
    payrun: PaymentRun,
    setUnreconcileDialogOpen: Dispatch<SetStateAction<boolean>>,
    setMatchedBankLines: Dispatch<SetStateAction<any[]>>,
    BankTransactionId: number = null,
    showSnackbar: showSnackbar
) => {
    if (BankTransactionId == null) {
        api.put('/unreconcilePayrun/' + payrun.id).then((res) => {
            if (Array.isArray(res.data)) {
                setMatchedBankLines(res.data);
                setUnreconcileDialogOpen(true);
            } else if (res.status === 200) {
                showSnackbar(
                    'Payment Run successfully unreconciled.',
                    'The page will now refresh.'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    } else {
        api.put(
            `/unreconcilePayrun/${payrun.id}/BankTransactionId=${BankTransactionId}`
        ).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Payment Run successfully unreconciled.',
                    'The page will now refresh.'
                );
                setTimeout(() => window.location.reload(), 1000);
            } else {
                showSnackbar(
                    'Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }
};
