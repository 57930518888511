// IMPORTS
import { useState } from 'react';
import {
    Typography,
    TableRow,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Grid,
    Table,
    TableCell,
    Button,
    DialogActions
} from '@mui/material';
import {
    Add,
    Inventory,
    Download,
    Upload,
    Summarize,
    Info,
    Compress,
    East
} from '@mui/icons-material';

import { useHistory } from 'react-router-dom';
import {
    Unit,
    BinLocation,
    Stock
} from '../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../global/logic/Formatters';

// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import Drawer from '../../global/Drawer';
import BinLocationDrawerContent from './components/BinLocationDrawerContent';

// LOGIC
import NegativeStockReport from './logic/NegativeStockReport';
import AddPartDialogContent from '../../global/AddPartDialogContent';
import StockDrawerContent from './components/StockDrawerContent';
import PartSearch from '../../global/PartSearch';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import MergeParts from './logic/MergeParts';
import SelectSiteDialogContent from '../../global/SelectSiteDialogContent';
import { ImportStockLevelCSVContent } from './components/ImportStockLevelCSVContent';
import { withSnackbar } from '../../global/WrappingSnackbar';

// Default values for the filter and sort for initial set state and clear filter
const clearedSort = ['id', 'DESC'];

interface SelectedPart {
    unitData: Unit;
    stockData: Stock;
}

interface TempUnit extends Unit {
    nbOfProforma: number;
    nbOfStockOrder: number;
}

const InventoryTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    let params = new URLSearchParams(window.location.search);
    const history = useHistory();
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<TempUnit[]>([]);

    const [binLocationDrawerIsOpen, setBinLocationDrawerIsOpen] =
        useState<boolean>(false);
    const [addPartDialogOpen, setAddPartDialogOpen] = useState<boolean>(false);

    // Stock Drawer
    const [stockDrawerOpen, setStockDrawerOpen] = useState<boolean>(false);
    const [selectedPart, setSelectedPart] = useState<TempUnit>();

    // Merge Part Dialog
    const [mergePartDialogOpen, setMergePartDialogOpen] =
        useState<boolean>(false);
    const [partToDelete, setPartToDelete] = useState<SelectedPart>();
    const [partToMergeInto, setPartToMergeInto] = useState<SelectedPart>();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [mergeConfirmed, setMergeConfirmed] = useState<boolean>(false);

    //site select dialog
    const [selectSiteDialogOpen, setSelectSiteDialogOpen] =
        useState<boolean>(false);

    // Boolean that check if there is at least one unit in the DB
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Inventory />,
            name: 'Bin Locations',
            onClick: () => setBinLocationDrawerIsOpen(true)
        },
        {
            icon: <Upload />,
            name: 'Import Supplier Files',
            onClick: () => history.push('/inventory/importSupplierFiles'),
            permission: 'import_supplierFile'
        },
        {
            icon: <Upload />,
            name: 'Import Stock Levels',
            onClick: () => setImportDialogOpen(true)
        },
        {
            icon: <Summarize />,
            name: 'Negative Stock Report',
            onClick: () => NegativeStockReport(),
            permission: 'inventory_report'
        },
        {
            icon: <Add />,
            name: 'Add Part',
            onClick: () => setAddPartDialogOpen(true)
        },
        {
            icon: <Compress />,
            name: 'Merge Parts',
            onClick: () => setMergePartDialogOpen(true),
            permission: 'merge_parts'
        },
        {
            icon: <Download />,
            name: 'Export SOH Results',
            onClick: () => setSelectSiteDialogOpen(true)
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'Part No.', width: 250 },
        { id: 1, label: 'Name', width: 250 },
        { id: 2, label: 'RRP', width: 150 },
        { id: 3, label: 'Daily Cost', width: 150 },
        { id: 4, label: 'Supplier', width: 200 },
        { id: 5, label: 'Bin Location', width: 200 },
        { id: 6, label: 'Stock', width: 100 }
    ];

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let apiString = `&checkEmpty=true`;
        return apiString;
    };

    // Get the name of the supplier first in the list of suppliers (as first supplier is primary supplier)
    const getSupplierName = (array: Unit['SupplierIds']) => {
        let newArray = array.filter(
            (x) => x.SiteId === parseInt(localStorage.getItem('SiteId'))
        );
        if (newArray.length === 0) {
            return array[0].name;
        }
        return newArray[0].name;
    };

    // Get the name of the bin location
    const getBinName = (array: BinLocation[]) => {
        let newArray = array.filter(
            (x) => x.SiteId === parseInt(localStorage.getItem('SiteId'))
        );
        if (newArray.length === 0) {
            return null;
        }
        return newArray[0].name;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Inventory</Typography>
            <br />
            <TableSearch
                searchTitle="Search Inventory"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="inventorySearchNextGen"
                setResultsList={setResultsList}
                parentSearchString={params.get('partNumber') || ''}
                setIsEmpty={setIsEmpty}
            ></TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() => {
                                    history.push(`/inventory/view/${row.id}`);
                                }}
                            >
                                {row.partNumber}
                            </DataCellColoured>
                            <DataCell>
                                {row.name.length > 25 ? (
                                    <Tooltip placement="top" title={row.name}>
                                        <Typography variant="body2">{`${row.name.slice(0, 25)}...`}</Typography>
                                    </Tooltip>
                                ) : (
                                    row.name
                                )}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(row.priceRRP)}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(row.costPriceDaily)}
                            </DataCell>
                            <DataCell>
                                {getSupplierName(row.SupplierIds)}
                            </DataCell>
                            <DataCell>
                                {getBinName(row.BinLocationIds)}
                            </DataCell>
                            <DataCell>
                                <IconButton
                                    onClick={() => {
                                        setSelectedPart(row);
                                        setStockDrawerOpen(true);
                                    }}
                                >
                                    <Info />
                                </IconButton>
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            {/* Drawer for bin location data */}
            <Drawer
                openDrawer={binLocationDrawerIsOpen}
                setOpenDrawer={setBinLocationDrawerIsOpen}
                title="Bin Locations"
                subTitle=""
                width="35vw"
            >
                <BinLocationDrawerContent />
            </Drawer>

            <Dialog
                open={addPartDialogOpen}
                onClose={() => setAddPartDialogOpen(false)}
            >
                <DialogTitle>Add Part</DialogTitle>
                <br />
                <AddPartDialogContent
                    showSnackbar={showSnackbar}
                    setNewPartDialogOpen={setAddPartDialogOpen}
                />
            </Dialog>

            <Dialog
                open={mergePartDialogOpen}
                onClose={() => setMergePartDialogOpen(false)}
                maxWidth={'lg'}
                fullWidth={true}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={5} textAlign={'center'}>
                            <b>Part to delete</b>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={5} textAlign={'center'}>
                            <b>Part to merge into</b>
                        </Grid>
                        <Grid item xs={5}>
                            <PartSearch HandleAdd={setPartToDelete} />
                        </Grid>
                        <Grid item xs={2} textAlign={'center'}>
                            <East sx={{ marginTop: 1 }} />
                        </Grid>
                        <Grid item xs={5}>
                            <PartSearch HandleAdd={setPartToMergeInto} />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sx={{ maxHeight: '60vh', overflowY: 'scroll' }}
                        >
                            {partToDelete?.unitData?.id ? (
                                <Table>
                                    <TableRow>
                                        <TableCell width={100}>
                                            Part Number:
                                        </TableCell>
                                        <TableCell>
                                            {partToDelete.unitData.partNumber}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Part Name:
                                        </TableCell>
                                        <TableCell>
                                            {partToDelete.unitData.name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Price RRP:
                                        </TableCell>
                                        <TableCell>
                                            {CurrencyFormatter(
                                                partToDelete.unitData.priceRRP
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Cost Price Daily
                                        </TableCell>
                                        <TableCell>
                                            {CurrencyFormatter(
                                                partToDelete.unitData
                                                    .costPriceDaily
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Cost Price Average
                                        </TableCell>
                                        <TableCell>
                                            {CurrencyFormatter(
                                                partToDelete.stockData
                                                    .costPriceAverage
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Bin Locations
                                        </TableCell>
                                        <TableCell>
                                            {getBinName(
                                                partToDelete.unitData
                                                    .BinLocationIds
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Suppliers
                                        </TableCell>
                                        <TableCell>
                                            {getSupplierName(
                                                partToDelete.unitData
                                                    .SupplierIds
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Quantity On Hand
                                        </TableCell>
                                        <TableCell>
                                            {partToDelete.stockData.qtyOnHand}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Creation Date:
                                        </TableCell>
                                        <TableCell>
                                            {DateFormatter(
                                                partToDelete.unitData.createdAt
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Last Update:
                                        </TableCell>
                                        <TableCell>
                                            {DateFormatter(
                                                partToDelete.unitData.updatedAt
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            ) : null}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sx={{ maxHeight: '60vh', overflowY: 'scroll' }}
                        >
                            {partToMergeInto?.unitData?.id ? (
                                <Table>
                                    <TableRow>
                                        <TableCell width={100}>
                                            Part Number:
                                        </TableCell>
                                        <TableCell>
                                            {
                                                partToMergeInto.unitData
                                                    .partNumber
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Part Name:
                                        </TableCell>
                                        <TableCell>
                                            {partToMergeInto.unitData.name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Price RRP:
                                        </TableCell>
                                        <TableCell>
                                            {CurrencyFormatter(
                                                partToMergeInto.unitData
                                                    .priceRRP
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Cost Price Daily
                                        </TableCell>
                                        <TableCell>
                                            {CurrencyFormatter(
                                                partToMergeInto.unitData
                                                    .costPriceDaily
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Cost Price Average
                                        </TableCell>
                                        <TableCell>
                                            {CurrencyFormatter(
                                                partToMergeInto.stockData
                                                    .costPriceAverage
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Bin Locations
                                        </TableCell>
                                        <TableCell>
                                            {getBinName(
                                                partToMergeInto.unitData
                                                    .BinLocationIds
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Suppliers
                                        </TableCell>
                                        <TableCell>
                                            {getSupplierName(
                                                partToMergeInto.unitData
                                                    .SupplierIds
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Quantity On Hand
                                        </TableCell>
                                        <TableCell>
                                            {
                                                partToMergeInto.stockData
                                                    .qtyOnHand
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Creation Date:
                                        </TableCell>
                                        <TableCell>
                                            {DateFormatter(
                                                partToMergeInto.unitData
                                                    .createdAt
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={100} height={40}>
                                            Last Update:
                                        </TableCell>
                                        <TableCell>
                                            {DateFormatter(
                                                partToMergeInto.unitData
                                                    .updatedAt
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            ) : null}
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12} textAlign={'center'}>
                            {mergeConfirmed ? (
                                <LoadingButton
                                    loading={buttonLoading}
                                    variant="contained"
                                    disabled={
                                        !partToDelete?.unitData?.id ||
                                        !partToMergeInto?.unitData?.id
                                    }
                                    onClick={() =>
                                        MergeParts(
                                            partToDelete,
                                            partToMergeInto,
                                            setButtonLoading,
                                            showSnackbar
                                        )
                                    }
                                >
                                    Merge parts
                                </LoadingButton>
                            ) : (
                                <Button
                                    disabled={
                                        !partToDelete?.unitData?.id ||
                                        !partToMergeInto?.unitData?.id
                                    }
                                    onClick={() => {
                                        showSnackbar(
                                            `You are about to merge part ${partToDelete.unitData.partNumber} into part ${partToMergeInto.unitData.partNumber}`,

                                            'If you are sure you selected the correct parts, click the Merge Parts button again.',
                                            'warning'
                                        );
                                        setMergeConfirmed(true);
                                    }}
                                >
                                    Merge Parts
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                open={selectSiteDialogOpen}
                onClose={() => setSelectSiteDialogOpen(false)}
            >
                <DialogTitle>Select Site</DialogTitle>
                <SelectSiteDialogContent
                    setSelectSiteDialogOpen={setSelectSiteDialogOpen}
                />
            </Dialog>

            <Dialog
                open={isEmpty}
                onClose={() => setIsEmpty(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>No part created yet</DialogTitle>
                <DialogContent>
                    <Typography>
                        Ora couldn't find any part in the database. You can
                        import Supplier Price File directly into Ora to create
                        the parts.
                    </Typography>
                    <Typography>
                        You can also manually create part(s), simply close this
                        dialog and click on the pop-up menu in the bottom right
                        of the page and click on "Add part".
                    </Typography>
                    <Typography variant="caption">
                        If you have already created part(s) and this dialog
                        opened by mistake, please contact the Ora development
                        team.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => setIsEmpty(false)}
                    >
                        Close Dialog
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsEmpty(false);
                            window.location.href =
                                '/inventory/importSupplierFiles';
                        }}
                    >
                        Import Supplier Price File
                    </Button>
                </DialogActions>
            </Dialog>

            <Drawer
                openDrawer={stockDrawerOpen}
                setOpenDrawer={setStockDrawerOpen}
                title={`Stock for: ${selectedPart?.partNumber}`}
                subTitle=""
                width="35vw"
            >
                <StockDrawerContent unit={selectedPart} />
            </Drawer>

            <Dialog
                open={importDialogOpen}
                onClose={() => setImportDialogOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <ImportStockLevelCSVContent showSnackbar={showSnackbar} />
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(InventoryTable);
