import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Category, Unit } from "../../../global/interfaces/PartsInterface";

interface TempCategory extends Category {
    nbOfUnit: number
}

const HandleCategoryClick = (category: TempCategory, setSelectedCategory: Dispatch<SetStateAction<TempCategory>>, setOpenDialog: Dispatch<SetStateAction<boolean>>, setListofUnit: Dispatch<SetStateAction<Unit[]>>, setFilterListOfUnit: Dispatch<SetStateAction<Unit[]>>, setFilterValue: Dispatch<SetStateAction<string>>, setLoading: Dispatch<SetStateAction<boolean>>) => {
    setLoading(true)
    api.get('unitFromCategories/' + category.id).then(res => {
        setListofUnit(res.data)
        setFilterListOfUnit(res.data)
        setSelectedCategory(category)
        setFilterValue('')
        setLoading(false)
        setOpenDialog(true)
    })
}

export default HandleCategoryClick;