import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import SignTag from './tag/SignTag';
import SignDateTag from './tag/SignDateTag';
import TextfieldTag from './tag/TextfieldTag';
import CheckboxTag from './tag/CheckboxTag';

import { Signer, TAG_ACTIONS, Template } from '../EsignInterface';

import { GetTagTemplates } from '../logic/HandleTemplate';
import MyTemplateDialog from './MyTemplateDialog';
import TextTag from './tag/TextTag';
import MySignatureTag from './tag/MySignatureTag';

interface EsignTagBarProps {
    currentSignerId: string;
    setCurrentSignerId: Dispatch<SetStateAction<string>>;
    selectedSigners: Signer[];
    handleClickSaveAsTemplate: () => void;
    handleClickFinishAndSend: () => void;
    handleSelectTagTemplate: (template: Template) => void;
}

const EsignTagBar = ({
    currentSignerId,
    setCurrentSignerId,
    selectedSigners,
    handleClickSaveAsTemplate,
    handleClickFinishAndSend,
    handleSelectTagTemplate
}: EsignTagBarProps) => {
    const [tagTemplates, setTagTemplates] = useState<Template[]>([]);

    const getTagTemplates = async () => {
        const result = await GetTagTemplates();
        setTagTemplates(result);
    };

    useEffect(() => {
        getTagTemplates();
    }, []);

    const [openTagTemplateDialog, setOpenTagTemplateDialog] =
        useState<boolean>(false);

    const handleSignerChange = (event: SelectChangeEvent<string>) => {
        setCurrentSignerId(event.target.value as string);
    };

    return (
        <Grid
            item
            container
            spacing={2}
            style={{ position: 'sticky', top: 60, zIndex: 1000 }}
        >
            <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="space-between"
            >
                <Grid item container xs={8} spacing={2} alignItems="center">
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpenTagTemplateDialog(true);
                            }}
                        >
                            Tag Template
                        </Button>
                    </Grid>
                    <Grid item>
                        <SignTag action={TAG_ACTIONS.VIEW} />
                    </Grid>
                    <Grid item>
                        <TextfieldTag action={TAG_ACTIONS.VIEW} />
                    </Grid>
                    <Grid item>
                        <SignDateTag action={TAG_ACTIONS.VIEW} />
                    </Grid>
                    <Grid item>
                        <CheckboxTag action={TAG_ACTIONS.VIEW} />
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    xs={4}
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={handleClickSaveAsTemplate}
                        >
                            Save as Template
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={handleClickFinishAndSend}
                        >
                            Finish and Send
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            {/* content block: TextTag and MySignatureTag */}
            <Grid item xs={12} container spacing={2} alignItems="center">
                <Grid item>
                    <TextTag action={TAG_ACTIONS.VIEW} />
                </Grid>
                <Grid item>
                    <MySignatureTag action={TAG_ACTIONS.VIEW} />
                </Grid>
                <Grid item>
                    <FormControl variant="outlined" size="small">
                        <InputLabel
                            id="signer-select-label"
                            sx={{ color: 'primary.main' }}
                        >
                            Select Signer
                        </InputLabel>
                        <Select
                            value={currentSignerId}
                            onChange={handleSignerChange}
                            label="Select Signer"
                            sx={{
                                backgroundColor: 'white',
                                color: 'primary.main'
                            }}
                        >
                            {selectedSigners.map((signer) => (
                                <MenuItem
                                    key={signer.externalId}
                                    value={signer.externalId}
                                >
                                    {signer.fullName + ': ' + signer.email}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {/* Dialog for Tag Template */}
            <MyTemplateDialog
                type="tag"
                open={openTagTemplateDialog}
                setOpen={setOpenTagTemplateDialog}
                myTemplates={tagTemplates}
                handleSelectTemplate={handleSelectTagTemplate}
            />
        </Grid>
    );
};
export default EsignTagBar;
