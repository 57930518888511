import api from '../../../../../../api';

const GetTransactions = (
    reconciliationDetails,
    setReconciliationDetails,
    setLoading
) => {
    setLoading(true);
    api.get('bankReconciliationTransactionsNextGenNew').then((res) => {
        let oraTransactionsUnreconciled = [];
        let oraTransactionsReconciled = [];
        let bankTransactionsUnreconciled = [];
        let bankTransactionsReconciled = [];

        res.data.formattedTillReconciliations.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedManualTransactions.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedTrustReceipts.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedDebtorPayments.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedTradeIns.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedVehicleDirectDeposits.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedServiceDirectDeposits.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedPartsDirectDeposits.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedPaymentRuns.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedStripePayments.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedServiceWarranties.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedVehicleRebates.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedDirectVehicleRebates.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });

        res.data.formattedBankTransactions.forEach((item) => {
            let referenceCompare = item.reference
                ? item.reference.toLowerCase()
                : '';
            let matchingOraTransactions = oraTransactionsUnreconciled.filter(
                (x) =>
                    x.amount === item.amount &&
                    referenceCompare.includes(
                        x.labelCompare ? x.labelCompare.toLowerCase() : ''
                    )
            );
            if (matchingOraTransactions.length === 1) {
                bankTransactionsReconciled.push(item);
                oraTransactionsReconciled.push(matchingOraTransactions[0]);
                oraTransactionsUnreconciled =
                    oraTransactionsUnreconciled.filter(
                        (x) => x !== matchingOraTransactions[0]
                    );
            } else {
                bankTransactionsUnreconciled.push(item);
            }
        });

        setReconciliationDetails({
            ...reconciliationDetails,
            oraTransactionsReconciled: oraTransactionsReconciled,
            oraTransactionsUnreconciled: oraTransactionsUnreconciled,
            bankTransactionsReconciled: bankTransactionsReconciled,
            bankTransactionsUnreconciled: bankTransactionsUnreconciled
        });

        setLoading(false);
    });
};

export default GetTransactions;
