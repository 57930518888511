// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Settings, Site } from '../interfaces/GeneralInterface';

/**
 * GetSetting
 * Gets the company settings of the site the user is logged into
 * @author Pierre
 * @param SiteId the id of the site within the company
 * @param setSetting react state to save the site details to
 */
const GetSetting = (
  SiteId: number,
  setSetting: Dispatch<SetStateAction<{ site: Site; settings: Settings }>>
) => {
  api.get(`settingsNextGen/${SiteId}`).then((res) => {
    setSetting(res.data);
  });
};

export default GetSetting;
