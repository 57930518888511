// IMPORTS
// eslint-disable-next-line
import jsPDF from 'jspdf'; // !DO NOT REMOVE
// eslint-disable-next-line
import autoTable from 'jspdf-autotable';
import { Settings } from '../interfaces/GeneralInterface';
import api from '../../../../api';

const InvoiceFooter = async (doc, SiteId) => {
    let settings: Settings;

    // Get the settings in order to get company details and logo
    await api.get(`settingsNextGen/${SiteId}?includeImage=true`).then((res) => {
        settings = res.data.settings;

        doc.setFont('helvetica', 'bold').setFontSize(6);
        doc.text(
            'Quoted price valid at date of issue. ' +
                'Subject to alteration due to factory price changes without notice.',
            13,
            276
        );

        doc.setFont('helvetica', 'normal').setFontSize(6);
        doc.text(
            'In accordance with the terms of the credit application all goods are to remain the property of ' +
                settings?.companyName +
                ' until all goods are paid for in full.',
            13,
            279
        );

        doc.setFont('helvetica', 'bold').setFontSize(6);
        doc.text('ALL HELMETS ARE NOT REFUNDABLE OR EXCHANGABLE.', 13, 282);

        doc.setFont('helvetica', 'normal').setFontSize(6);
        doc.text(
            ' For the full details of our Returns Policy, please visit our website.',
            74,
            282
        );

        doc.setFont('helvetica', 'bold').setFontSize(6);
        doc.text(
            'For the full details of our Privacy Policy, please visit our website.',
            13,
            285
        );
    });
};

export default InvoiceFooter;
