import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const HandleDeletePurchaseOrder = (
    PurchaseOrderId: number,
    showSnackbar: showSnackbar,
    noRefresh?: boolean,
    purchaseOrders?,
    setPurchaseOrders?,
    setDrawerOpen?,
    serviceDetails?,
    setServiceDetails?
) => {
    api.delete(`deletePurchaseOrder/${PurchaseOrderId}`).then((res) => {
        if (res.status === 200) {
            if (noRefresh) {
                let currentPurchaseOrders = [...purchaseOrders];
                currentPurchaseOrders = currentPurchaseOrders.filter(
                    (x) => x.id !== PurchaseOrderId
                );
                setPurchaseOrders(currentPurchaseOrders);
                setServiceDetails({
                    ...serviceDetails,
                    subletTotal: res.data.newSubletCharge
                });
                setDrawerOpen(false);
            } else {
                window.location.reload();
            }
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleDeletePurchaseOrder;
