import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const UpdateStockOrderToInvoiced = (id: number, showSnackbar: showSnackbar) => {
    api.put('/invoicedStockOrder/' + id.toString()).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Stock Order successfully marked as invoiced',
                'The page will now reload'
            );
            setTimeout(() => window.location.reload(), 2000);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact the Ora development team.'
            );
        }
    });
};

export default UpdateStockOrderToInvoiced;
