// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import {
  VehicleRegistrationLine,
  VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';

interface RegistrationData {
  Registration?: VehicleRegistrationLine;
  VehicleSale?: VehicleSale;
}

const GetRegoReconciliationLines = (
  setRegistrationData: Dispatch<SetStateAction<RegistrationData[]>>,
  setReconciledRegistrationData: Dispatch<SetStateAction<RegistrationData[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  setLoading(true);
  api.get('regoReconciliationLines').then((res) => {
    setRegistrationData(res.data.unreconciledRegistration);
    setReconciledRegistrationData(res.data.reconciledRegistration);
    setLoading(false);
  });
};

export default GetRegoReconciliationLines;
