import dayjs from 'dayjs';
import { BankTransaction } from '../../../../global/interfaces/AdminInterface';
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';
import api from '../../../../../../api';
import { Dispatch, SetStateAction } from 'react';

const CreateVehicleSaleDirectDeposit = (
    transaction: BankTransaction,
    vehicleSale: VehicleSale,
    tillId: number,
    setIsNewLine: Dispatch<SetStateAction<boolean>>,
    setSelectedVehicleSale: Dispatch<SetStateAction<VehicleSale>>,
    setSelectedPaymentLine: Dispatch<SetStateAction<number>>
) => {
    api.get('currentuser').then((res) => {
        let user = res.data;
        let newLine = {
            id: vehicleSale.paymentLines.length + 1,
            date: dayjs().format('YYYY-MM-DD'),
            type: 'directDeposit',
            user: `${user.firstName} ${user.lastName}`,
            TillId: tillId,
            amount: transaction.amount,
            change: 0,
            timestamp: dayjs().toISOString(),
            amountReceived: transaction.amount
        };

        let currentVehicleSale = { ...vehicleSale };
        currentVehicleSale.paymentLines.push(newLine);
        setIsNewLine(true);
        setSelectedPaymentLine(currentVehicleSale.paymentLines.length);
        setSelectedVehicleSale(currentVehicleSale);
    });
};

export default CreateVehicleSaleDirectDeposit;
