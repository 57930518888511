// IMPORTS
import { Dispatch, SetStateAction } from 'react'
// LOGIC
import api from '../../../../api'
// INTERFACES
import { Vehicle } from '../interfaces/VehicleSaleInterface'

const GetAllVehiclesNoFilter = (setVehicles: Dispatch<SetStateAction<Vehicle[]>>) => {
    api.get('allVehiclesNoFilter').then(res => {
        setVehicles(res.data)
    })
}

export default GetAllVehiclesNoFilter