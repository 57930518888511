// IMPORTS
import { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    TableRow,
    Pagination,
    Tooltip,
    IconButton,
    Dialog,
    Fab,
    Checkbox,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Chip
} from '@mui/material';
import { AttachMoney, Balance, Done, Menu, Print } from '@mui/icons-material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import TableSearch from '../../global/tableComponents/TableSearch';
import DataCell from '../../global/tableComponents/DataCell';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataTable from '../../global/tableComponents/DataTable';
import PaymentDialogContent from './components/PaymentDialogContent';
import InvoiceDialogContent from './components/InvoiceDialogContent';
import LineMenu from './components/LineMenu';
import RoleCheck from '../../global/RoleCheck';
import Drawer from '../../global/Drawer';
// LOGIC
import {
    DateFormatter,
    CurrencyFormatter
} from '../../global/logic/Formatters';
import PDFDebtorBalanceReport from './logic/PDFDebtorBalanceReport';
import moment from 'moment';
// INTERFACES
import { DebtorPayment } from '../../global/interfaces/GeneralInterface';
import ManuallySetPaymentAsPaid from './logic/ManuallySetPaymentAsPaid';
import { withSnackbar } from '../../global/WrappingSnackbar';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {};

interface SelectedDebtor {
    CustomerId: number;
    CustomerDetails: {
        name: string;
        firstName: string;
        lastName: string;
        companyName: string;
        phoneNumber: string;
        abn: string;
        paymentTerms: string;
        accountLimit: string;
        addressLine1: string;
        suburb: string;
        state: string;
        postcode: string;
        email: string;
    };
    rows: any[];
}
const sort = ['id', 'DESC'];
const DebtorReconciliationTable = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [resultsList, setResultsList] = useState([]);
    const [selectedDebtor, setSelectedDebtor] = useState<SelectedDebtor>({
        CustomerId: null,
        CustomerDetails: {
            name: null,
            firstName: null,
            lastName: null,
            companyName: null,
            phoneNumber: null,
            abn: null,
            paymentTerms: null,
            accountLimit: null,
            addressLine1: null,
            suburb: null,
            state: null,
            postcode: null,
            email: null
        },
        rows: []
    });
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState<boolean>(false);
    const [selectedInvoices, setSelectedInvoices] = useState<DebtorPayment[]>(
        []
    );
    const [numberOfDebtor, setNumberOfDebtor] = useState<number>(0);
    const [totalBalance, setTotalBalance] = useState<number>(0);

    // Payment History Drawer State
    const [openPaymentHistoryDrawer, setOpenPaymentHistoryDrawer] =
        useState<boolean>(false);
    const [paymentHistoryContent, setPaymentHistoryContent] =
        useState<DebtorPayment>({});

    // Manually set debtor payment to paid
    const [openManuallyPayDialog, setOpenManuallyPayDialog] =
        useState<boolean>(false);

    const customerColumns = [
        { id: 0, label: 'Name' },
        { id: 1, label: 'Unpaid Invoices' },
        { id: 2, label: 'Total Unpaid' }
    ];

    const paymentColumns = [
        { id: 0, label: '', width: 50 },
        { id: 1, label: 'ID', width: 50 },
        { id: 2, label: 'Amount', width: 200 },
        { id: 3, label: 'Remaining', width: 200 },
        { id: 4, label: 'Invoice Number', width: 150 },
        { id: 5, label: 'Invoice Type', width: 200 },
        { id: 6, label: 'Submitted By', width: 150 },
        { id: 7, label: 'Date', width: 100 },
        { id: 8, label: '', width: 50 }
    ];

    const drawerColumns = [
        { id: 0, label: 'Payment ID' },
        { id: 1, label: 'Payment Amount', width: 150 },
        { id: 2, label: 'Payment Date', width: 200 }
    ];

    const dialogColumns = [
        { id: 0, label: 'Invoice Number' },
        { id: 1, label: 'Amount' }
    ];

    // When a new debtor is selected change the paginated rows
    useEffect(() => {
        setPaginatedRows(selectedDebtor.rows.slice(0, 20));
        setSelectedInvoices([]);
    }, [selectedDebtor]);

    // When the page initially loads set the paginated rows
    useEffect(() => {
        if (resultsList.length > 0 && selectedDebtor.CustomerId == null) {
            setSelectedDebtor(resultsList[0]);
            setPaginatedRows(resultsList[0].rows.slice(0, 20));
        }
        // eslint-disable-next-line
    }, [resultsList]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        return '';
    };

    // Get the balance of all the transactions
    const getBalance = (debtor = null) => {
        let total = 0;
        if (debtor == null) {
            selectedDebtor.rows.forEach((payment) => {
                total += payment.amountDue - getAmountPaid(payment);
            });
        } else {
            debtor.rows.forEach((payment) => {
                total += payment.amountDue - getAmountPaid(payment);
            });
        }
        return total;
    };

    // Handle changing the navigation of the invoice rows
    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        let newPage = value - 1;
        let offset = newPage * 20;

        setCurrentPage(value - 1);
        setPaginatedRows(selectedDebtor.rows.slice(offset, offset + 20));
    };

    /**
     * handleCheckRow
     * Add/remove a row from selected invoices
     * @author Pierre
     * @param checkValue the state of the checkbox
     * @param row the row being checked/unchecked
     */
    const handleCheckRow = (checkValue: boolean, row: any) => {
        let tempInvoices = [...selectedInvoices];

        if (checkValue) {
            tempInvoices.push(row);
        } else {
            tempInvoices = tempInvoices.filter((x) => x.id !== row.id);
        }

        setSelectedInvoices(tempInvoices);
    };

    // Get the amount paid for a specific debtor payment
    const getAmountPaid = (row) => {
        let total = 0;
        for (let line of row.paymentHistory) {
            total += line.amount;
        }
        return total;
    };

    // Get Balance for for all debtor payment selected
    const getSelectedInvoicesBalance = () => {
        let total = 0;
        for (let line of selectedInvoices) {
            total += line.amountDue - getAmountPaid(line);
        }
        return total;
    };

    // Check if a debtor payment is selected
    const checkIfSelected = (row) => {
        for (let line of selectedInvoices) {
            if (row.id === line.id) {
                return true;
            }
        }
        return false;
    };

    const getNumberOfUnpaid = (debtor) => {
        let total = 0;
        for (let row of debtor.rows) {
            if (row.amountDue - getAmountPaid(row) !== 0 && row.amountDue > 0) {
                total += 1;
            }
        }
        return total;
    };

    return (
        <>
            <PageWrapper>
                <Typography variant="h4">Debtor Reconciliation</Typography>
                <br />

                <TableSearch
                    searchTitle="Search Debtor"
                    showFilter={false}
                    showPagination={true}
                    showPaper={true}
                    clearedFilter={clearedFilter}
                    sort={sort}
                    customLimit={15}
                    handleRequestCreate={handleRequestCreate}
                    route="debtorPaymentSearchNextGen"
                    setResultsList={setResultsList}
                    setTotal={setTotalBalance}
                    setNumberOfItems={setNumberOfDebtor}
                ></TableSearch>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Paper>
                            <DataTable columns={customerColumns}>
                                {resultsList.map((result) => (
                                    <TableRow
                                        selected={
                                            result.CustomerId ===
                                            selectedDebtor.CustomerId
                                        }
                                    >
                                        <DataCellColoured
                                            handleClick={() =>
                                                setSelectedDebtor(result)
                                            }
                                        >
                                            {result.CustomerDetails.name}
                                        </DataCellColoured>
                                        <DataCell>
                                            {getNumberOfUnpaid(result)}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                getBalance(result)
                                            )}
                                        </DataCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <DataCell colSpan={2}>
                                        Total for the {numberOfDebtor} creditors
                                        in this list:
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(totalBalance)}
                                    </DataCell>
                                </TableRow>
                            </DataTable>
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper>
                            <Grid container spacing={2}>
                                <Grid item xs={8} alignItems="top">
                                    <Typography variant="h5" align="left">
                                        {selectedDebtor.CustomerDetails.name}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        align="left"
                                    >
                                        <b>
                                            Balance:{' '}
                                            {CurrencyFormatter(getBalance())}
                                        </b>
                                        <Tooltip
                                            title="Print Account Statement"
                                            placement="bottom"
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    setInvoiceDialogOpen(true)
                                                }
                                            >
                                                <Print />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                    {selectedInvoices.length > 0 ? (
                                        <Typography
                                            variant="subtitle1"
                                            align="left"
                                        >
                                            <b>
                                                Total for Selected Invoices:{' '}
                                                {CurrencyFormatter(
                                                    getSelectedInvoicesBalance()
                                                )}
                                            </b>
                                            <Tooltip
                                                title="Make Account Payment"
                                                placement="bottom"
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        setPaymentDialogOpen(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <AttachMoney />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                                title="Manually set to paid"
                                                placement="bottom"
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setOpenManuallyPayDialog(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <Done />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                    ) : null}
                                </Grid>
                                <Grid item xs={4}>
                                    <Pagination
                                        count={Math.ceil(
                                            selectedDebtor.rows.length / 20
                                        )}
                                        page={currentPage + 1}
                                        onChange={handlePageChange}
                                        color="primary"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'right'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <DataTable columns={paymentColumns}>
                                {paginatedRows?.map((transaction) => (
                                    <TableRow>
                                        <DataCell>
                                            <Checkbox
                                                size="small"
                                                disabled={
                                                    transaction.amountDue ===
                                                    getAmountPaid(transaction)
                                                }
                                                checked={checkIfSelected(
                                                    transaction
                                                )}
                                                onChange={(e) =>
                                                    handleCheckRow(
                                                        e.target.checked,
                                                        transaction
                                                    )
                                                }
                                            />
                                        </DataCell>
                                        <DataCell>{transaction.id}</DataCell>

                                        <DataCell>
                                            {CurrencyFormatter(
                                                transaction.amountDue
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {getAmountPaid(transaction) ===
                                            0 ? (
                                                <Chip
                                                    variant="outlined"
                                                    color={
                                                        transaction?.Order
                                                            ?.status ===
                                                            'Sale' &&
                                                        transaction.amountDue >
                                                            0
                                                            ? 'error'
                                                            : transaction.amountDue <
                                                                0
                                                              ? 'success'
                                                              : 'warning'
                                                    }
                                                    label={
                                                        (transaction?.Order
                                                            ?.status ===
                                                            'Sale' ||
                                                            transaction?.Service
                                                                ?.serviceComplete ===
                                                                'Finalised') &&
                                                        transaction.amountDue >
                                                            0
                                                            ? 'Unpaid'
                                                            : transaction.amountDue <
                                                                0
                                                              ? 'Refund Pending'
                                                              : `${transaction?.Order?.id > 0 ? transaction.Order.status : transaction?.Service?.serviceComplete}`
                                                    }
                                                />
                                            ) : transaction.amountDue ===
                                              getAmountPaid(transaction) ? (
                                                <Chip
                                                    variant="outlined"
                                                    color="success"
                                                    label={
                                                        transaction.amountDue >
                                                        0
                                                            ? 'Paid'
                                                            : 'Refunded'
                                                    }
                                                />
                                            ) : transaction.amountDue > 0 ? (
                                                <Chip
                                                    variant="outlined"
                                                    color="warning"
                                                    label={`${CurrencyFormatter(transaction.amountDue - getAmountPaid(transaction))}`}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </DataCell>

                                        {transaction.StockInvoiceId ? (
                                            <DataCellColoured
                                                handleClick={() =>
                                                    window.open(
                                                        '/inventory/stockinvoiced/view/' +
                                                            transaction.StockInvoiceId,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                {transaction.StockInvoiceId}
                                            </DataCellColoured>
                                        ) : transaction.OrderId ? (
                                            <DataCellColoured
                                                handleClick={() =>
                                                    window.open(
                                                        '/inventory/viewSale/' +
                                                            transaction.OrderId,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                {transaction.OrderId}
                                            </DataCellColoured>
                                        ) : (
                                            <DataCellColoured
                                                handleClick={() =>
                                                    window.open(
                                                        '/service/viewBooking/' +
                                                            transaction.ServiceId,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                {transaction.ServiceId}
                                            </DataCellColoured>
                                        )}

                                        <DataCell>
                                            {transaction.StockInvoiceId
                                                ? transaction.StockInvoice
                                                      ?.documentReference
                                                : transaction.OrderId
                                                  ? transaction.amountDue < 0
                                                      ? 'P&A Credit'
                                                      : transaction?.Order
                                                              ?.status ===
                                                          'Sale'
                                                        ? 'P&A Sale'
                                                        : 'P&A Proforma'
                                                  : transaction.amountDue < 0
                                                    ? 'Account Payment'
                                                    : transaction.ServiceId
                                                      ? 'Service'
                                                      : 'Misc'}
                                        </DataCell>
                                        <DataCell>
                                            {transaction?.User?.firstName}{' '}
                                            {transaction?.User?.lastName}
                                        </DataCell>
                                        <DataCell>
                                            {!transaction.StockInvoiceId &&
                                            !transaction.OrderId &&
                                            transaction.amountDue < 0
                                                ? // If it's an account payment, show the date it was paid not the date the line was created (could be backdating stuff)
                                                  // Also, because of the timezone we are in the entered date are set to the date before
                                                  // Need to test in the afternoon to see if the date entered is still wrong or not
                                                  moment(
                                                      transaction.paymentDueDate
                                                  )
                                                      .add(1, 'day')
                                                      .format('DD/MM/YYYY')
                                                : transaction?.Order
                                                        ?.finalisedAt
                                                  ? DateFormatter(
                                                        transaction?.Order
                                                            ?.finalisedAt
                                                    )
                                                  : transaction.ServiceId
                                                    ? transaction.Service
                                                          ?.finalisedDate
                                                        ? DateFormatter(
                                                              transaction
                                                                  .Service
                                                                  .finalisedDate
                                                          )
                                                        : 'Not finalised'
                                                    : 'Proforma'}
                                        </DataCell>
                                        {!transaction.StockInvoiceId &&
                                        !transaction.OrderId &&
                                        transaction.amountDue < 0 ? (
                                            <RoleCheck
                                                permission="edit_debtorPayment"
                                                component={
                                                    <DataCell>
                                                        <LineMenu
                                                            row={transaction}
                                                            showSnackbar={
                                                                showSnackbar
                                                            }
                                                            setOpenPaymentHistoryDrawer={
                                                                setOpenPaymentHistoryDrawer
                                                            }
                                                            setPaymentHistoryContent={
                                                                setPaymentHistoryContent
                                                            }
                                                        />
                                                    </DataCell>
                                                }
                                            />
                                        ) : transaction.amountDue > 0 &&
                                          transaction.paymentHistory.length >
                                              0 ? (
                                            <DataCell>
                                                <IconButton
                                                    onClick={() => {
                                                        setPaymentHistoryContent(
                                                            transaction
                                                        );
                                                        setOpenPaymentHistoryDrawer(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <Menu />
                                                </IconButton>
                                            </DataCell>
                                        ) : (
                                            <DataCell></DataCell>
                                        )}
                                    </TableRow>
                                ))}
                            </DataTable>
                        </Paper>
                    </Grid>
                </Grid>

                {/* Make a payment dialog */}
                <Dialog
                    open={paymentDialogOpen}
                    onClose={() => setPaymentDialogOpen(false)}
                    fullWidth
                    maxWidth={'md'}
                >
                    <PaymentDialogContent
                        selectedDebtor={selectedDebtor}
                        balance={getSelectedInvoicesBalance()}
                        selectedInvoices={selectedInvoices}
                        setPaymentDialogOpen={setPaymentDialogOpen}
                    />
                </Dialog>

                {/* Manually pay dialog */}
                <Dialog
                    open={openManuallyPayDialog}
                    onClose={() => setOpenManuallyPayDialog(false)}
                    fullWidth
                    maxWidth={'md'}
                >
                    <>
                        <DialogTitle>
                            You are about to set the following Debtor Payment as
                            paid:
                        </DialogTitle>
                        <DialogContent>
                            <DataTable columns={dialogColumns}>
                                {selectedInvoices.map((invoice) => (
                                    <TableRow>
                                        <DataCellColoured
                                            handleClick={() =>
                                                window.open(
                                                    '/inventory/viewSale/' +
                                                        invoice.OrderId,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {invoice.OrderId}
                                        </DataCellColoured>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                invoice.amountDue
                                            )}
                                        </DataCell>
                                    </TableRow>
                                ))}
                            </DataTable>
                            <br />
                            This cannot be reversed.
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => setOpenManuallyPayDialog(false)}
                            >
                                CLOSE
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    ManuallySetPaymentAsPaid(
                                        selectedInvoices,
                                        showSnackbar
                                    )
                                }
                            >
                                Proceed
                            </Button>
                        </DialogActions>
                    </>
                </Dialog>

                {/* Generate account statement / invoice dialog */}
                <Dialog
                    open={invoiceDialogOpen}
                    onClose={() => setInvoiceDialogOpen(false)}
                    fullWidth
                    maxWidth={'md'}
                >
                    <InvoiceDialogContent
                        selectedDebtor={selectedDebtor}
                        setInvoiceDialogOpen={setInvoiceDialogOpen}
                    />
                </Dialog>

                <Drawer
                    openDrawer={openPaymentHistoryDrawer}
                    setOpenDrawer={setOpenPaymentHistoryDrawer}
                    width="20vw"
                    title="Payment History"
                    subTitle={`Debtor Payment #${paymentHistoryContent?.id} - Total: ${CurrencyFormatter(Math.abs(paymentHistoryContent.amountDue))}`}
                >
                    <DataTable columns={drawerColumns}>
                        {paymentHistoryContent?.paymentHistory?.map((line) => (
                            <TableRow>
                                {line.paymentRemaining ? null : (
                                    <>
                                        <DataCell>
                                            {line.paymentId
                                                ? line.paymentId
                                                : line?.User?.id
                                                  ? `Set as Paid by: ${line.User.firstName} ${line.User.lastName}`
                                                  : ''}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(line.amount)}
                                        </DataCell>
                                        <DataCell>
                                            {DateFormatter(line.paymentDate)}
                                        </DataCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </DataTable>
                </Drawer>
            </PageWrapper>

            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <Fab color="primary" onClick={(e) => PDFDebtorBalanceReport()}>
                    <Balance />
                </Fab>
            </div>
        </>
    );
};

export default withSnackbar(DebtorReconciliationTable);
