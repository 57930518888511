import api from '../../../../api';

const GetAllTechs = (setTechList) => {
    api.get(`serviceTechNextGen?siteId=${localStorage.getItem('SiteId')}`).then(
        (res) => {
            setTechList(res.data);
        }
    );
};

export default GetAllTechs;
