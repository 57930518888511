// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';

// INTERFACES
import {
    HondaInvoicePartLine,
    StockInvoice
} from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const ImportHondaInvoice = (
    HondaInvoiceId: string,
    invoiceData: StockInvoice,
    setInvoiceData: Dispatch<SetStateAction<StockInvoice>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setImportHondaInvoiceOpen: Dispatch<SetStateAction<boolean>>,
    setPartNotFound: Dispatch<SetStateAction<HondaInvoicePartLine[]>>,
    setPartNotFoundDrawerOpen: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setLoading(true);
    api.get(
        `getHondaInvoice/${HondaInvoiceId}?SiteId=${localStorage.getItem('SiteId')}`
    ).then((res) => {
        if (res.status === 204) {
            showSnackbar(
                'There is no Honda API set up for this site.',

                'Please check you are logged into the correct site or contact IT about setting up a Honda API for this site.',
                'error'
            );
            setLoading(false);
            return;
        }

        if (typeof res.data == 'string') {
            showSnackbar(
                'Honda could not find an invoice with that ID.',
                'Please check you have the correct ID.',
                'error'
            );
            setLoading(false);
            return;
        }
        // Update the invoice object
        setInvoiceData({
            ...invoiceData,
            orderLines: res.data.orderLines,
            Supplier: res.data.supplier,
            SupplierId: res.data.supplier.id,
            freight: res.data.invoice.Freight,
            documentTotal: res.data.invoice.GrandTotal.toString(),
            gstMethod: 'excluding',
            documentReference: HondaInvoiceId,
            invoiceDate:
                res.data.invoice.InvoiceDate.slice(0, 4) +
                '-' +
                res.data.invoice.InvoiceDate.slice(4, 6) +
                '-' +
                res.data.invoice.InvoiceDate.slice(6)
        });
        localStorage.setItem(
            'stockInvoiceData',
            JSON.stringify({
                ...invoiceData,
                orderLines: res.data.orderLines,
                Supplier: res.data.supplier,
                SupplierId: res.data.supplier.id,
                freight: res.data.invoice.Freight,
                documentTotal: res.data.invoice.GrandTotal,
                gstMethod: 'excluding',
                documentReference: HondaInvoiceId,
                invoiceDate:
                    res.data.invoice.InvoiceDate.slice(0, 4) +
                    '-' +
                    res.data.invoice.InvoiceDate.slice(4, 6) +
                    '-' +
                    res.data.invoice.InvoiceDate.slice(6)
            })
        );

        setLoading(false);
        setImportHondaInvoiceOpen(false);

        if (res.data.partsNotInOra.length > 0) {
            showSnackbar(
                `${res.data.partsNotInOra.length} missing parts in Ora!`,
                'Please add the parts using the drawer to continue.',
                'warning'
            );
            setPartNotFound(res.data.partsNotInOra);
            setPartNotFoundDrawerOpen(true);
        }
    });
};

export default ImportHondaInvoice;
