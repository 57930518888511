import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';

import Papa from 'papaparse';
import DataTable from '../../../global/tableComponents/DataTable';
import { Clear } from '@mui/icons-material';
import { StockLevelCSVExplanation } from './StockLevelCSVExplanation';
import { ImportStockLevelFromCSV } from '../logic/ImportStockLevelFromCSV';
import LoadingButton from '@mui/lab/LoadingButton';
import { ToFixed } from '../../../global/logic/Formatters';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const ImportStockLevelCSVContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [importedData, setImportedData] = useState([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [importedDataFilename, setImportedDataFilename] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [timeRemaining, setTimeRemaining] = useState<string>('');

    var headerOptions = [
        { id: 0, label: 'Part Number', value: 'partNumber' },
        { id: 1, label: 'Quantity On Hand', value: 'qtyOnHand' },
        { id: 3, label: 'Maximum Stock', value: 'maxStock' },
        { id: 4, label: 'Minimum Stock', value: 'minStock' },
        { id: 5, label: 'Site', value: 'siteName' }
    ];

    const parseCSV = (e) => {
        Papa.parse(e, {
            header: false,
            skipEmptyLines: 'greedy',
            complete: (results) => {
                var newData = [];
                let maxLength = 0;
                results.data.forEach((row, i) => {
                    newData.push(row);
                    maxLength = maxLength < row.length ? row.length : maxLength;
                });
                setImportedData(newData);

                let newHeaders = new Array(maxLength);
                newHeaders.fill('');
                setHeaders(newHeaders);
            }
        });
    };

    const importFile = (e) => {
        setImportedDataFilename(e.target.files[0].name);
        parseCSV(e.target.files[0]);
    };

    const deleteColumn = (index) => {
        let currentHeaders = [...headers];
        let currentLines = [...importedData];

        currentHeaders.splice(index, 1);
        for (let item of currentLines) {
            item.splice(index, 1);
        }

        setHeaders(currentHeaders);
        setImportedData(currentLines);
    };

    const deleteLine = (index) => {
        let currentLines = [...importedData];
        currentLines.splice(index, 1);
        setImportedData(currentLines);
    };

    const updateColumnsHeaders = (value, index) => {
        let currentHeaders = [...headers];
        currentHeaders[index] = value;
        setHeaders(currentHeaders);
    };

    const checkDisabled = () => {
        if (
            headers.includes('partNumber') &&
            headers.includes('qtyOnHand') &&
            headers.includes('siteName')
        ) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <DialogTitle>Import Stock Levels CSV</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign={'center'}>
                        {importedData.length > 0 ? (
                            importedDataFilename + ' uploaded.'
                        ) : (
                            <>
                                <input
                                    onChange={(e) => importFile(e)}
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="outlined" component="span">
                                        Upload CSV
                                    </Button>
                                </label>
                            </>
                        )}
                    </Grid>
                    {loading ? (
                        <>
                            <Grid item xs={2} alignItems={'center'}>
                                Upload Progress:{' '}
                            </Grid>
                            <Grid
                                item
                                xs={10}
                                alignItems={'center'}
                                textAlign="center"
                            >
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={{ mt: 1 }}
                                />{' '}
                                {`${ToFixed(progress)}% - Approximate time remaining: ${timeRemaining}`}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    If Ora cannot match a part number from your
                                    CSV with an existing part number in the
                                    database, a CSV will be generated with
                                    suggested Part Number that you can use to
                                    upload the stock for the ignored lines. If a
                                    line appears in the CSV without a suggested
                                    Part Number, it means that Ora couldn't find
                                    any matches.
                                </Typography>
                            </Grid>
                        </>
                    ) : null}
                    {importedData.length > 0 && !loading ? (
                        <Grid item xs={12}>
                            <Grid container spacing={0} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Use the below interface to select which
                                        columns in the CSV correspond to which
                                        Stock's property. You must at least
                                        select: Part Number, Quantity On Hand
                                        and Site Name. The import button will be
                                        greyed out until you have selected these
                                        headers.
                                    </Typography>
                                    <Typography>
                                        Using the{' '}
                                        <Clear
                                            style={{ verticalAlign: 'middle' }}
                                        />{' '}
                                        button will delete the corresponding
                                        row/column from the imported data.
                                    </Typography>
                                    <Typography>
                                        This can be used to delete headers for
                                        example.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : importedData.length === 0 ? (
                        <StockLevelCSVExplanation />
                    ) : null}

                    <Grid item xs={12}>
                        <DataTable columns={[]}>
                            {importedData.length > 0 && !loading ? (
                                <>
                                    <TableRow>
                                        {headers.map((item, index) => (
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        deleteColumn(index)
                                                    }
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        {headers.map((item, index) => (
                                            <TableCell>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    value={item}
                                                    onChange={(e) => {
                                                        updateColumnsHeaders(
                                                            e.target.value,
                                                            index
                                                        );
                                                    }}
                                                >
                                                    {headerOptions.map(
                                                        (option) => (
                                                            <MenuItem
                                                                value={
                                                                    option.value
                                                                }
                                                                disabled={headers.includes(
                                                                    option.value
                                                                )}
                                                            >
                                                                {option.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    {importedData
                                        .slice(0, 5)
                                        .map((line, index) => (
                                            <TableRow>
                                                {line.map((item) => (
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                ))}
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteLine(index)
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    <TableRow>
                                        {headers.map((item) => (
                                            <TableCell>...</TableCell>
                                        ))}
                                        <TableCell>...</TableCell>
                                    </TableRow>
                                    {importedData
                                        .slice(
                                            importedData.length - 5,
                                            importedData.length
                                        )
                                        .map((line) => (
                                            <TableRow>
                                                {line.map((item) => (
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                ))}
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteLine(
                                                                importedData.findIndex(
                                                                    (x) =>
                                                                        x[0] ===
                                                                            line[0] &&
                                                                        x[1] ===
                                                                            line[1]
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </>
                            ) : null}
                        </DataTable>
                    </Grid>
                </Grid>
            </DialogContent>
            {importedData.length > 0 ? (
                <DialogActions>
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        disabled={checkDisabled()}
                        onClick={() =>
                            ImportStockLevelFromCSV(
                                importedData,
                                headers,
                                showSnackbar,
                                setLoading,
                                setProgress,
                                setTimeRemaining
                            )
                        }
                    >
                        Import Stock Levels
                    </LoadingButton>
                </DialogActions>
            ) : null}
        </>
    );
};
