import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    TextField
} from '@mui/material';
import Autocomplete from './Autocomplete';
import HandleAddPart from './logic/HandleAddPart';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Creditor } from './interfaces/GeneralInterface';
import GetAllSuppliers from './databaseLogic/GetAllSuppliers';
import { showSnackbar } from './interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const clearedNewPart = {
    partNumber: null,
    name: null,
    supplier: {},
    costPriceDaily: null,
    priceRRP: null
};

interface AddPartDialogContentProps {
    showSnackbar: showSnackbar;
    setNewPartDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const AddPartDialogContent = (props: AddPartDialogContentProps) => {
    const [suppliers, setSuppliers] = useState<Creditor[]>([]);
    const [newPart, setNewPart] = useState(clearedNewPart);
    const [newPartErrors, setNewPartErrors] = useState({
        partNumber: false,
        name: false,
        supplier: false,
        costPrice: false,
        rrp: false
    });
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        GetAllSuppliers(setSuppliers);
    }, []);

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Part Number"
                            InputLabelProps={{ shrink: true }}
                            value={newPart.partNumber}
                            onChange={(e) =>
                                setNewPart({
                                    ...newPart,
                                    partNumber: e.target.value
                                })
                            }
                            error={newPartErrors.partNumber}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            label="Name"
                            InputLabelProps={{ shrink: true }}
                            value={newPart.name}
                            onChange={(e) =>
                                setNewPart({ ...newPart, name: e.target.value })
                            }
                            error={newPartErrors.name}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            options={suppliers}
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            textfieldLabel={'Supplier'}
                            textfieldVariant={'outlined'}
                            selectedValue={newPart.supplier}
                            handleSelectedValueChange={(newValue) =>
                                setNewPart({ ...newPart, supplier: newValue })
                            }
                            handleInputValueChange={(newInputValue) => null}
                            canSearch={false}
                            isError={newPartErrors.supplier}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Daily Cost Price"
                            InputLabelProps={{ shrink: true }}
                            value={newPart.costPriceDaily}
                            onChange={(e) =>
                                setNewPart({
                                    ...newPart,
                                    costPriceDaily: e.target.value.replace(
                                        ',',
                                        ''
                                    )
                                })
                            }
                            error={newPartErrors.costPrice}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="RRP"
                            InputLabelProps={{ shrink: true }}
                            value={newPart.priceRRP}
                            onChange={(e) =>
                                setNewPart({
                                    ...newPart,
                                    priceRRP: e.target.value.replace(',', '')
                                })
                            }
                            error={newPartErrors.rrp}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setNewPartDialogOpen(false)}>
                    Cancel
                </Button>
                <LoadingButton
                    loading={buttonLoading}
                    variant="contained"
                    onClick={() =>
                        HandleAddPart(
                            newPart,
                            setNewPartErrors,
                            props.showSnackbar,
                            setButtonLoading,
                            null,
                            props.setNewPartDialogOpen,
                            null,
                            null
                        )
                    }
                >
                    Add Part
                </LoadingButton>
            </DialogActions>
        </>
    );
};
export default AddPartDialogContent;
