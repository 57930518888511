// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Circle } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// COMPONENTS
import Paper from '../../../global/Paper';
import Header from './HistoryComponents/Header';
import CashDenominations from './ReconciliationComponents/CashDenominations';
import OtherPaymentDenominations from './ReconciliationComponents/OtherPaymentDenominations';
import Drawer from '../../../global/Drawer';
import TransactionsDrawerContent from './ReconciliationComponents/TransactionsDrawerContent';
import RoleCheck from '../../../global/RoleCheck';
import Fab from '../../../global/Fab';
// LOGIC
import GetMonthlyIndicators from '../logic/GetMonthlyIndicators';
import GetTillTransactions from '../logic/GetTillTransactions';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import HandleSubmitReconciliation from '../logic/HandleSubmitReconciliation';
import { DateFormatter } from '../../../global/logic/Formatters';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        top: 7,
        border: `2.5px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}));

function ServerDay(props: PickersDayProps<Dayjs> & { highlightedDays? }) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    let line = highlightedDays.filter((x) => x.date === props.day.date());
    const isSelected =
        !props.outsideCurrentMonth &&
        props.day <= dayjs().subtract(1, 'day') &&
        line.length > 0;

    if (line[0]?.color === 'error') {
        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                invisible={!isSelected}
                variant="dot"
                color={line[0]?.color}
            >
                <PickersDay
                    {...other}
                    outsideCurrentMonth={outsideCurrentMonth}
                    day={day}
                />
            </Badge>
        );
    } else {
        return (
            <StyledBadge
                key={props.day.toString()}
                overlap="circular"
                invisible={!isSelected}
                badgeContent={line[0]?.count}
                color={line[0]?.color}
            >
                <PickersDay
                    {...other}
                    outsideCurrentMonth={outsideCurrentMonth}
                    day={day}
                />
            </StyledBadge>
        );
    }
}

interface HistoryProps {
    tillReconciliation: any;
    setTillReconciliation: Dispatch<SetStateAction<any>>;
    showSnackbar: showSnackbar;
}
const History = ({
    tillReconciliation,
    setTillReconciliation,
    showSnackbar
}: HistoryProps) => {
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
        dayjs().subtract(1, 'day')
    );
    const [selectedSite, setSelectedSite] = useState<number>(
        parseInt(localStorage.getItem('SiteId'))
    );
    const [selectedTill, setSelectedTill] = useState<number>(0);
    const [highlightedDays, setHighlightedDays] = useState([]);
    const [transactionsDrawerOpen, setTransactionDrawerOpen] =
        useState<boolean>(false);
    const [calendarLoading, setCalendarLoading] = useState<boolean>(false);

    useEffect(() => {
        GetMonthlyIndicators(
            selectedSite,
            selectedDate,
            setHighlightedDays,
            setCalendarLoading
        );
        // eslint-disable-next-line
    }, [selectedSite]);

    useEffect(() => {
        if (selectedTill) {
            GetTillTransactions(
                selectedTill,
                tillReconciliation,
                setTillReconciliation,
                selectedDate
            );
        }
        // eslint-disable-next-line
    }, [selectedTill, selectedDate, selectedSite]);

    const handleFabClick = () => {
        if (readOnly) {
            setReadOnly(false);
        } else {
            HandleSubmitReconciliation(
                tillReconciliation,
                selectedSite,
                selectedTill,
                showSnackbar,
                selectedDate
            );
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Paper>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                disabled={calendarLoading}
                                disableHighlightToday={true}
                                disableFuture={true}
                                value={selectedDate}
                                onChange={(newValue) =>
                                    setSelectedDate(newValue)
                                }
                                slots={{ day: ServerDay }}
                                slotProps={{ day: { highlightedDays } as any }}
                                onMonthChange={(month) =>
                                    GetMonthlyIndicators(
                                        selectedSite,
                                        month,
                                        setHighlightedDays,
                                        setCalendarLoading
                                    )
                                }
                                shouldDisableDate={(day) =>
                                    day.format('DD/MM/YYYY') ===
                                    dayjs(Date.now()).format('DD/MM/YYYY')
                                }
                            />
                        </LocalizationProvider>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography variant="caption">
                                    <Circle
                                        sx={{ fontSize: '10px' }}
                                        color="error"
                                    />{' '}
                                    <i>All tills missing a reconciliation</i>
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    <Circle
                                        sx={{ fontSize: '10px' }}
                                        color="warning"
                                    />{' '}
                                    <i>Some tills missing a reconciliation</i>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {calendarLoading ? (
                                    <Typography align="center">
                                        <CircularProgress />
                                    </Typography>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <Header
                        selectedSite={selectedSite}
                        setSelectedSite={setSelectedSite}
                        selectedTill={selectedTill}
                        setSelectedTill={setSelectedTill}
                        selectedDate={selectedDate}
                        setTransactionDrawerOpen={setTransactionDrawerOpen}
                        showSnackbar={showSnackbar}
                    />
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <CashDenominations
                                tillReconciliation={tillReconciliation}
                                setTillReconciliation={setTillReconciliation}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <OtherPaymentDenominations
                                tillReconciliation={tillReconciliation}
                                setTillReconciliation={setTillReconciliation}
                                readOnly={readOnly}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <RoleCheck
                permission="edit_tillRec"
                component={
                    <Fab editIcon={readOnly} onClick={() => handleFabClick()} />
                }
            />

            <Drawer
                openDrawer={transactionsDrawerOpen}
                setOpenDrawer={setTransactionDrawerOpen}
                title="Transactions"
                subTitle={DateFormatter(String(selectedDate))}
            >
                <TransactionsDrawerContent
                    tillReconciliation={tillReconciliation}
                />
            </Drawer>
        </>
    );
};

export default History;
