import dayjs from 'dayjs';

const renderDealerVehicleSaleContract = async (
    pdfDocCopy,
    formData,
    hasTradeIn,
    vehicleCondition
) => {
    const pages = pdfDocCopy.getPages();
    const firstPage = pages[0];
    const secondPage = pages[1];

    // ============================  Purchaser credit fields on the first page ============================
    if (formData.creditOption === 'noCreditNeeded') {
        firstPage.drawText('X', {
            x: 313,
            y: 435,
            size: 6
        });
    }
    if (formData.creditOption === 'arrangeCredit') {
        firstPage.drawText('X', { x: 313, y: 423, size: 6 });
    }
    if (formData.creditOption === 'authorizeDealerCredit') {
        firstPage.drawText('X', { x: 313, y: 412, size: 6 });
    }

    if (formData.contact) {
        firstPage.drawText(formData.contact, { x: 345, y: 356, size: 6 });
    }

    if (formData.phone) {
        firstPage.drawText(formData.phone, { x: 350, y: 340, size: 7 });
    }

    if (formData.premium) {
        firstPage.drawText(formData.premium, { x: 490, y: 340, size: 7 });
    }

    // ============================  Delivery receipt on the first page ============================
    if (formData.deliveryDate) {
        const date = dayjs(formData.deliveryDate);
        const day = date.date().toString().padStart(2, '0');
        const month = (date.month() + 1).toString().padStart(2, '0');
        const year = date.year().toString();

        firstPage.drawText(day, {
            x: 350,
            y: 280,
            size: 9
        });

        firstPage.drawText(month, {
            x: 430,
            y: 280,
            size: 9
        });

        firstPage.drawText(year, {
            x: 520,
            y: 280,
            size: 9
        });
    }

    if (formData.purchaserSigDataURL) {
        const purchaserSigImage = await pdfDocCopy.embedPng(
            formData.purchaserSigDataURL
        );
        firstPage.drawImage(purchaserSigImage, {
            x: 380,
            y: 260,
            width: 100,
            height: 20
        });
    }

    if (formData.witnessSigDataURL) {
        const witnessSigImage = await pdfDocCopy.embedPng(
            formData.witnessSigDataURL
        );
        firstPage.drawImage(witnessSigImage, {
            x: 360,
            y: 240,
            width: 100,
            height: 20
        });
    }

    //  ============================  Dealer declaration fields on the second page ============================
    if (hasTradeIn) {
        if (formData.mainTaxInvoiceOption === 'provideTaxInvoice') {
            secondPage.drawText('X', { x: 41, y: 304, size: 6 });
            secondPage.drawText(formData.abnForGst, {
                x: 400,
                y: 305,
                size: 7
            });
        }
        if (formData.mainTaxInvoiceOption === 'notProvideTaxInvoice') {
            secondPage.drawText('X', { x: 41, y: 284, size: 6 });
            if (formData.subTaxInvoiceOption === 'isIndividual') {
                secondPage.drawText('X', { x: 55, y: 267, size: 6 });
            }
            if (formData.subTaxInvoiceOption === 'notRegisteredForGST') {
                secondPage.drawText('X', { x: 55, y: 253, size: 6 });
                secondPage.drawText(formData.abnNotForGST, {
                    x: 150,
                    y: 254,
                    size: 7
                });
            }
        }

        if (formData.declarationWitnessSigDataURL) {
            const declarationWitnessSigImage = await pdfDocCopy.embedPng(
                formData.declarationWitnessSigDataURL
            );
            secondPage.drawImage(declarationWitnessSigImage, {
                x: 350,
                y: 100,
                width: 100,
                height: 20
            });
        }

        if (formData.dealerAcceptDate) {
            const date = dayjs(formData.dealerAcceptDate);
            const day = date.date().toString().padStart(2, '0');
            const month = (date.month() + 1).toString().padStart(2, '0');
            const year = date.year().toString();

            secondPage.drawText(day, {
                x: 120,
                y: 85,
                size: 10
            });

            secondPage.drawText(month, {
                x: 240,
                y: 85,
                size: 10
            });

            secondPage.drawText(year, {
                x: 360,
                y: 85,
                size: 10
            });
        }
    }

    if (vehicleCondition === 'Used') {
        // ============================  WrittenOffForm on Third Page ============================
        const thirdPage = pages[2];
        if (formData.writtenOffOption === 'isRepairableWriteOff') {
            thirdPage.drawText('X', { x: 110, y: 717, size: 15 });
        }

        if (formData.writtenOffOption === 'isStatutoryWriteOff') {
            thirdPage.drawText('X', { x: 266, y: 717, size: 15 });
        }

        // ============================  CoolingOffForm on Third Page ============================
        if (formData.coolingOffOption === 'hasCoolingOffPeriod') {
            thirdPage.drawText('X', { x: 386, y: 620, size: 15 });
        }
        if (formData.coolingOffOption === 'noCoolingOffPeriod') {
            thirdPage.drawText('X', { x: 431, y: 620, size: 15 });
        }

        if (formData.coolingOffPeriodStart) {
            const start = dayjs(formData.coolingOffPeriodStart);
            const minutes = start.minute().toString().padStart(2, '0');
            const hours = start.hour().toString().padStart(2, '0');
            const day = start.date().toString().padStart(2, '0');
            const month = (start.month() + 1).toString().padStart(2, '0');
            const year = start.year().toString();

            thirdPage.drawText(minutes, {
                x: 238,
                y: 410,
                size: 12
            });

            thirdPage.drawText(hours, {
                x: 292,
                y: 410,
                size: 12
            });

            thirdPage.drawText(day, {
                x: 339,
                y: 410,
                size: 12
            });

            thirdPage.drawText(month, {
                x: 388,
                y: 410,
                size: 12
            });

            thirdPage.drawText(year, {
                x: 435,
                y: 410,
                size: 12
            });
        }

        if (formData.coolingOffPeriodEnd) {
            const end = dayjs(formData.coolingOffPeriodEnd);
            const day = end.date().toString().padStart(2, '0');
            const month = (end.month() + 1).toString().padStart(2, '0');
            const year = end.year().toString();

            thirdPage.drawText(day, {
                x: 285,
                y: 380,
                size: 12
            });

            thirdPage.drawText(month, {
                x: 335,
                y: 380,
                size: 12
            });

            thirdPage.drawText(year, {
                x: 385,
                y: 380,
                size: 12
            });
        }

        // ============================  Statutory Warranty on Fourth Page ============================
        const fourthPage = pages[3];
        if (formData.statutoryWarrantyOption === 'hasWarranty') {
            fourthPage.drawText('X', { x: 78, y: 725, size: 15 });
        }

        if (formData.statutoryWarrantyOption === 'noWarranty') {
            fourthPage.drawText('X', { x: 266, y: 725, size: 15 });
        }

        if (formData.warrantorName) {
            fourthPage.drawText(formData.warrantorName, {
                x: 110,
                y: 680,
                size: 12
            });
        }

        if (formData.warrantorAddress) {
            fourthPage.drawText(formData.warrantorAddress, {
                x: 110,
                y: 630,
                size: 12
            });
        }

        if (formData.warrantorSuburb) {
            fourthPage.drawText(formData.warrantorSuburb, {
                x: 135,
                y: 610,
                size: 12
            });
        }

        if (formData.warrantorState) {
            const characters = formData.warrantorState.split('');
            // ensure characters are spaced out evenly
            let xOffset = 350;
            const spacing = 20;
            characters.forEach((char) => {
                fourthPage.drawText(char, {
                    x: xOffset,
                    y: 605,
                    size: 12
                });
                xOffset += spacing; // add xOffset for next char
            });
        }

        if (formData.warrantorPostcode) {
            const characters = formData.warrantorPostcode.split('');
            // ensure characters are spaced out evenly
            let xOffset = 460;
            const spacing = 20;
            characters.forEach((char) => {
                fourthPage.drawText(char, {
                    x: xOffset,
                    y: 605,
                    size: 12
                });
                xOffset += spacing; // add xOffset for next char
            });
        }

        if (formData.warrantorPhone) {
            fourthPage.drawText(formData.warrantorPhone, {
                x: 135,
                y: 570,
                size: 12
            });
        }

        if (formData.warrantorEmail) {
            fourthPage.drawText(formData.warrantorEmail, {
                x: 335,
                y: 570,
                size: 12
            });
        }

        if (formData.buyerPossessionDate) {
            const date = dayjs(formData.buyerPossessionDate);
            const day = date.date().toString().padStart(2, '0');
            const month = (date.month() + 1).toString().padStart(2, '0');
            const year = date.year().toString();

            fourthPage.drawText(day, {
                x: 265,
                y: 395,
                size: 12
            });

            fourthPage.drawText(month, {
                x: 315,
                y: 395,
                size: 12
            });

            fourthPage.drawText(year, {
                x: 365,
                y: 395,
                size: 12
            });
        }

        // ============================  Motor Dealer/Chattel Auctioneer Declaration on Sixth page ============================
        const sixthPage = pages[5];
        if (formData.noPriorContract) {
            sixthPage.drawText('X', { x: 52, y: 719, size: 10 });
        }
        if (formData.notPersuadeCoolingOff) {
            sixthPage.drawText('X', { x: 52, y: 699, size: 10 });
        }
        if (formData.hasStatutoryWarranty) {
            sixthPage.drawText('X', { x: 52, y: 679, size: 10 });
        }
        if (formData.noStatutoryWarranty) {
            sixthPage.drawText('X', { x: 52, y: 659, size: 10 });
        }
        if (formData.notPersuadeWarranty) {
            sixthPage.drawText('X', { x: 52, y: 639, size: 10 });
        }
        if (formData.informedBuyer) {
            sixthPage.drawText('X', { x: 52, y: 619, size: 10 });
        }

        if (formData.dealerSignDate) {
            const date = dayjs(formData.dealerSignDate);
            const day = date.date().toString().padStart(2, '0');
            const month = (date.month() + 1).toString().padStart(2, '0');
            const year = date.year().toString();

            sixthPage.drawText(day, {
                x: 110,
                y: 580,
                size: 12
            });

            sixthPage.drawText(month, {
                x: 150,
                y: 580,
                size: 12
            });

            sixthPage.drawText(year, {
                x: 200,
                y: 580,
                size: 12
            });
        }

        if (formData.dealerSigDataURL) {
            const dealerSigImage = await pdfDocCopy.embedPng(
                formData.dealerSigDataURL
            );
            sixthPage.drawImage(dealerSigImage, {
                x: 370,
                y: 550,
                width: 150,
                height: 40
            });
        }
    }

    const pdfBytes = await pdfDocCopy.save();
    return pdfBytes;
};

export default renderDealerVehicleSaleContract;
