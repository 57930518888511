import {
    AccountTransactionLine,
    AverageCostAdjust,
    ManualTransaction
} from '../../../../../global/interfaces/AdminInterface';
import { Site } from '../../../../../global/interfaces/GeneralInterface';
import { StockInvoice } from '../../../../../global/interfaces/PartsInterface';

interface CalculateRebatesAndExpensesParams {
    (
        averageCostAdjusts: AverageCostAdjust[],
        manualTransaction: ManualTransaction[],
        generalJournal: AccountTransactionLine[],
        invoices: StockInvoice[],
        totalStripeFee: number,
        selectedSite: Site[]
    ): [
        {
            amount: number;
            description: string;
            date: string;
            accountId: number;
            accountName: string;
            accountCategory: string;
        }[],
        {
            amount: number;
            description: string;
            date: string;
            accountId: number;
            accountName: string;
            accountCategory: string;
        }[]
    ];
}

const CalculateRebatesAndExpenses: CalculateRebatesAndExpensesParams = (
    averageCostAdjusts,
    manualTransaction,
    generalJournal,
    invoices,
    totalStripeFee,
    selectedSite
) => {
    let rebates = [];
    let expenses = [];

    for (let adjustment of averageCostAdjusts) {
        expenses.push({
            amount: adjustment.adjustmentValue ?? 0,
            description: 'For Part Number: ' + adjustment?.Unit?.partNumber,
            date: adjustment.createdAt,
            accountName: 'Average Cost Adjustment'
        });
    }

    for (let entry of manualTransaction) {
        let amount =
            entry.gstType === 'N/T'
                ? Math.abs(entry.amount)
                : Math.abs(entry.amount) / 1.1;

        if (entry?.Account?.type === 'Revenue') {
            if (
                selectedSite.filter((x) => x.id === entry?.Account?.SiteId)
                    .length > 0
            ) {
                rebates.push({
                    amount: entry.amount < 0 ? amount : -amount,
                    description: entry?.Account?.name + ' - ' + entry.reference,
                    date: entry.dateReceived,
                    url: '/admin/viewManualTransaction/' + entry.id,
                    accountId: entry.Account.id,
                    accountName: entry.Account.name,
                    accountCategory: entry.Account?.AccountCategory?.name,
                    reconciled: entry.reconciled ?? false
                });
            }
        } else if (entry?.Account?.type === 'Expense') {
            if (
                selectedSite.filter((x) => x.id === entry?.Account?.SiteId)
                    .length > 0
            ) {
                expenses.push({
                    amount: entry.amount < 0 ? -amount : amount,
                    description: entry?.Account?.name + ' - ' + entry.reference,
                    date: entry.dateReceived,
                    url: '/admin/viewManualTransaction/' + entry.id,
                    accountId: entry.Account.id,
                    accountName: entry.Account.name,
                    accountCategory: entry.Account?.AccountCategory?.name,
                    reconciled: entry.reconciled ?? false
                });
            }
        }
    }

    for (let entry of generalJournal) {
        if (entry?.Account?.type === 'Revenue') {
            let amount =
                entry.gstType === 'NT' || entry.gstType === 'N/T'
                    ? parseFloat(entry.debit) > 0
                        ? -Math.abs(parseFloat(entry.debit))
                        : parseFloat(entry.credit)
                    : parseFloat(entry.debit) > 0
                      ? -Math.abs(parseFloat(entry.debit)) / 1.1
                      : parseFloat(entry.credit) / 1.1;

            if (
                selectedSite.filter((x) => x.id === entry?.Account?.SiteId)
                    .length > 0
            ) {
                rebates.push({
                    amount: amount,
                    description:
                        entry?.Account?.name + ' - ' + entry.description,
                    date: entry.dateEntered,
                    url:
                        '/admin/generalAccountingEntry/' +
                        entry.AccountTransactionId,
                    accountId: entry.Account.id,
                    accountName: entry.Account.name,
                    accountCategory: entry.Account?.AccountCategory?.name
                });
            }
        } else if (entry?.Account?.type === 'Expense') {
            let amount =
                entry.gstType === 'NT' || entry.gstType === 'N/T'
                    ? parseFloat(entry.debit) > 0
                        ? parseFloat(entry.debit)
                        : -Math.abs(parseFloat(entry.credit))
                    : parseFloat(entry.debit) > 0
                      ? parseFloat(entry.debit) / 1.1
                      : -Math.abs(parseFloat(entry.credit)) / 1.1;

            if (
                selectedSite.filter((x) => x.id === entry?.Account?.SiteId)
                    .length > 0
            ) {
                expenses.push({
                    amount: amount,
                    description:
                        entry?.Account?.name + ' - ' + entry.description,
                    date: entry.dateEntered,
                    url:
                        '/admin/generalAccountingEntry/' +
                        entry.AccountTransactionId,
                    accountId: entry.Account.id,
                    accountName: entry.Account.name,
                    accountCategory: entry.Account?.AccountCategory?.name
                });
            }
        }
    }

    // Go through the lines from the Stock Invoice table
    for (let invoice of invoices) {
        let amount =
            invoice.gstMethod === 'including' ||
            invoice.gstType === 'Capital Purchase' ||
            invoice.gstType === 'Non-Capital Purchase'
                ? parseFloat(invoice.documentTotal) / 1.1
                : parseFloat(invoice.documentTotal);

        let allReconciled = true;
        let paid = 0;
        if (invoice.Invoice.paymentHistory?.length > 0) {
            for (let payment of invoice.Invoice.paymentHistory) {
                // If the payment has not been removed, add the amount to the total paid, and check if it has been reconciled
                if (payment.removed !== true) {
                    if (
                        payment.PaymentRunId &&
                        payment.PaymentRunId != null &&
                        !payment.reconciled
                    ) {
                        allReconciled = false;
                    }
                    paid += payment.amount;
                }
            }
        }

        if (invoice?.Account?.type === 'Revenue') {
            if (
                selectedSite.filter((x) => x.id === invoice?.Account?.SiteId)
                    .length > 0
            ) {
                rebates.push({
                    amount: parseFloat(invoice.documentTotal),
                    description:
                        invoice?.Account?.name +
                        ' - ' +
                        invoice.documentReference,
                    date: invoice.invoiceDate,
                    accountId: invoice.Account.id,
                    accountName: invoice.Account.name,
                    url: '/inventory/viewInvoice/' + invoice?.Invoice?.id,
                    accountCategory: invoice.Account?.AccountCategory?.name,
                    reconciled: allReconciled && paid >= amount
                });
            }
        } else if (invoice?.Account?.type === 'Expense') {
            if (
                selectedSite.filter((x) => x.id === invoice?.Account?.SiteId)
                    .length > 0
            ) {
                expenses.push({
                    amount: amount,
                    description:
                        invoice?.Account?.name +
                        ' - ' +
                        invoice.documentReference,
                    date: invoice.invoiceDate,
                    url: '/inventory/viewInvoice/' + invoice?.Invoice?.id,
                    accountId: invoice.Account.id,
                    accountName: invoice.Account.name,
                    accountCategory: invoice.Account?.AccountCategory?.name,
                    reconciled: allReconciled && paid >= amount
                });
            }
        } else {
            invoice?.orderLines.forEach((line) => {
                let amount =
                    invoice.gstMethod === 'including' ||
                    invoice.gstType === 'Capital Purchase' ||
                    invoice.gstType === 'Non-Capital Purchase'
                        ? parseFloat(line.amount) / 1.1
                        : parseFloat(line.amount);

                if (line?.Account?.type === 'Revenue') {
                    if (
                        selectedSite.filter(
                            (x) => x.id === line?.Account?.SiteId
                        ).length > 0
                    ) {
                        rebates.push({
                            // amount: parseFloat(invoice.documentTotal),
                            amount:
                                invoice.invoiceType === 'Credit'
                                    ? Math.abs(parseFloat(line.amount))
                                    : parseFloat(line.amount),
                            description:
                                line?.Account?.name +
                                ' - ' +
                                invoice.documentReference,
                            date: invoice.invoiceDate,
                            url:
                                '/inventory/viewInvoice/' +
                                invoice?.Invoice?.id,
                            accountId: line.Account.id,
                            accountName: line.Account.name,
                            accountCategory:
                                line.Account?.AccountCategory?.name,
                            reconciled: allReconciled && paid >= amount
                        });
                    }
                } else if (line?.Account?.type === 'Expense') {
                    if (
                        selectedSite.filter(
                            (x) => x.id === line?.Account?.SiteId
                        ).length > 0
                    ) {
                        expenses.push({
                            // amount: parseFloat(invoice.documentTotal),
                            amount: amount,
                            description:
                                line?.Account?.name +
                                ' - ' +
                                invoice.documentReference,
                            date: invoice.invoiceDate,
                            url:
                                '/inventory/viewInvoice/' +
                                invoice?.Invoice?.id,
                            accountId: line.Account.id,
                            accountName: line.Account.name,
                            accountCategory:
                                line.Account?.AccountCategory?.name,
                            reconciled: allReconciled && paid >= amount
                        });
                    }
                }
            });
        }
    }

    let stripeFeeLine = {
        amount: totalStripeFee,
        description: 'Stripe Fees',
        accountCategory: 'Stripe Fees',
        accountName: 'Stripe Fees'
    };

    expenses.push(stripeFeeLine);

    return [rebates, expenses];
};

export default CalculateRebatesAndExpenses;
