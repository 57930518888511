import moment from 'moment';

const HandleMoveAllTransactionV2 = (
    type: string,
    reconciliationDetails,
    setReconciliationDetails
) => {
    let oraTransactionsReconciled = JSON.parse(
        JSON.stringify([...reconciliationDetails.oraTransactionsReconciled])
    );
    let bankTransactionsReconciled = JSON.parse(
        JSON.stringify([...reconciliationDetails.bankTransactionsReconciled])
    );
    let oraTransactionsUnreconciled = JSON.parse(
        JSON.stringify([...reconciliationDetails.oraTransactionsUnreconciled])
    );
    let bankTransactionsUnreconciled = JSON.parse(
        JSON.stringify([...reconciliationDetails.bankTransactionsUnreconciled])
    );

    // If we are moving an ora transaction
    if (type === 'ora') {
        for (let week of oraTransactionsReconciled) {
            let index = oraTransactionsUnreconciled.findIndex(
                (x) => x.week === week.week
            );
            if (index !== -1) {
                for (let item of week.lines) {
                    oraTransactionsUnreconciled[index].lines.unshift(item);
                }
            } else {
                oraTransactionsUnreconciled.push(week);
            }
        }
        oraTransactionsUnreconciled = oraTransactionsUnreconciled
            .filter((x) => x.week !== 'Invalid Date')
            .sort((a, b) =>
                moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                    ? 1
                    : -1
            );
        oraTransactionsReconciled = [];
    }

    // If we are moving a bank transaction
    else if (type === 'bank') {
        for (let week of bankTransactionsReconciled) {
            let index = bankTransactionsUnreconciled.findIndex(
                (x) => x.week === week.week
            );
            if (index !== -1) {
                for (let item of week.lines) {
                    bankTransactionsUnreconciled[index].lines.unshift(item);
                }
            } else {
                bankTransactionsUnreconciled.push(week);
            }
        }
        bankTransactionsUnreconciled = bankTransactionsUnreconciled
            .filter((x) => x.week !== 'Invalid Date')
            .sort((a, b) =>
                moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                    ? 1
                    : -1
            );
        bankTransactionsReconciled = [];
    }

    setReconciliationDetails({
        ...reconciliationDetails,
        oraTransactionsReconciled: oraTransactionsReconciled,
        bankTransactionsReconciled: bankTransactionsReconciled,
        oraTransactionsUnreconciled: oraTransactionsUnreconciled,
        bankTransactionsUnreconciled: bankTransactionsUnreconciled
    });
};

export default HandleMoveAllTransactionV2;
