// IMPORTS
import { Typography } from '@mui/material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Tabs from '../../global/Tabs';
import BankTransactions from './components/BankTransactions/BankTransactions';
import Reconciliator from './components/Reconciliator/Reconciliator';
import History from './components/History/History';
import ReconciliatorV2 from './components/Reconciliator/ReconciliatorV2';

const BankReconciliation = () => {
    const viewingDate = window.location.pathname.split('/')[3];
    const viewingId = window.location.pathname.split('/')[4];

    const tabContent = [
        {
            id: 0,
            title: 'Bank Transactions',
            content: <BankTransactions />
        },
        {
            id: 1,
            title: 'Reconciliator',
            content: <Reconciliator />
        },
        {
            id: 2,
            title: 'Reconciliator V2',
            content: <ReconciliatorV2 />
        },
        {
            id: 3,
            title: 'History',
            content: <History viewingDate={viewingDate} viewingId={viewingId} />
        }
    ];

    return (
        <PageWrapper>
            <Typography variant="h4">Bank Reconciliation</Typography>
            <br />
            <Tabs tabContent={tabContent} presetValue={viewingDate ? 2 : 0} />
        </PageWrapper>
    );
};

export default BankReconciliation;
