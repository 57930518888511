import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Stock } from '../../../global/interfaces/PartsInterface';

const GetOtherSiteStock = (
    UnitId: number,
    setOtherSiteStock: Dispatch<SetStateAction<Stock[]>>
) => {
    api.get(`stock/${UnitId}`).then((res) => {
        let stockData: Stock[] = res.data;

        // Filter stock data from current Site and also make sure we don't push a line for the same site twice
        // Prevent showing duplicate stock line
        let otherSiteStock = [];
        let alreadyInStock = [];
        stockData.forEach((line) => {
            if (
                line.SiteId !== parseInt(localStorage.getItem('SiteId')) &&
                !alreadyInStock.includes(line.SiteId)
            ) {
                otherSiteStock.push(line);
                alreadyInStock.push(line.SiteId);
            }
        });
        setOtherSiteStock(otherSiteStock);
    });
};

export default GetOtherSiteStock;
