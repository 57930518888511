import { Dispatch, SetStateAction } from "react";
import { Table } from "../interfaces/StockOrderInterface"

const RemoveUnselectedParts = (tables: Table[], setTables: Dispatch<SetStateAction<Table[]>>) => {
    var currentTables = [...tables];
    var newTables = [];

    currentTables.forEach(row => {
        var newOrderLines = [];

        row.orderLines.forEach(item => {
            if (item.selected) {
                newOrderLines.push(item)
            }
        })

        if (newOrderLines.length > 0) {
            row.orderLines = newOrderLines
            newTables.push(row)
        }
    })

    setTables(newTables)
}

export default RemoveUnselectedParts;