import { DialogContent, DialogTitle, TableRow } from '@mui/material';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import DataTable from '../../../global/tableComponents/DataTable';
import { useEffect, useState } from 'react';
import GetOrderlineStock from '../../logic/Dashboard/GetOrderlineStock';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}

interface Column {
    id: number;
    label: string;
    width: number;
    name: string;
}

interface StockDialogContentProps {
    selectedOrder: GroupedOnlineOrder;
}
const StockDialogContent = ({ selectedOrder }: StockDialogContentProps) => {
    const [columns, setColumns] = useState<Column[]>([]);
    const [partLines, setPartLines] = useState([]);

    useEffect(() => {
        GetOrderlineStock(selectedOrder, setColumns, setPartLines);
        // eslint-disable-next-line
    }, []);

    const renderSiteStock = (part) => {
        let returnObject = [];

        for (let i = 1; i < columns.length; i++) {
            returnObject.push(<DataCell>{part[columns[i].name]}</DataCell>);
        }

        return returnObject;
    };

    return (
        <>
            <DialogTitle>
                Stock for Order #{selectedOrder.oraOrder.OnlineOrderId}
            </DialogTitle>
            <DialogContent>
                <DataTable columns={columns}>
                    {partLines.map((part) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/inventory/view/${part.UnitId}`,
                                        '_blank'
                                    )
                                }
                            >
                                {part.partNumber}
                            </DataCellColoured>
                            {renderSiteStock(part).map((item) => item)}
                        </TableRow>
                    ))}
                </DataTable>
            </DialogContent>
        </>
    );
};

export default StockDialogContent;
