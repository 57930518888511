import { useState } from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@material-ui/lab/Alert';

export const withSnackbar = (WrappedComponent, cypressLabel = '') => {
    return (props) => {
        const [open, setOpen] = useState<boolean>(false);
        const [message, setMessage] = useState<string>('');
        const [subMessage, setSubMessage] = useState<string>('');
        const [severity, setSeverity] =
            useState<string>('success'); /** error | warning | info */

        const showMessage = (
            message: string,
            subMessage: string = '',
            severity: string = 'success'
        ) => {
            setMessage(message);
            setSubMessage(subMessage);
            setSeverity(severity);
            setOpen(true);
        };

        return (
            <>
                <WrappedComponent {...props} showSnackbar={showMessage} />
                <MuiSnackbar
                    open={open}
                    //   data-cy={cypressLabel ? cypressLabel : ''}
                    autoHideDuration={4000}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Put the snackbar at the top middle of the page
                    sx={{ whiteSpace: 'pre-line' }} // This is what allows the multiline in the snackbar as MUI does not default allow it
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={() => setOpen(false)}
                        severity={
                            severity !== 'success' &&
                            severity !== 'warning' &&
                            severity !== 'error'
                                ? 'info'
                                : severity
                        }
                    >
                        <b>{message}</b>
                        {subMessage ? `\n${subMessage}` : null}
                    </Alert>
                </MuiSnackbar>
            </>
        );
    };
};
