// IMPORTS
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
// LOGIC
import { DateFormatter } from '../../../global/logic/Formatters';

// Create a pdf pick slip when a backorder has been altered (either fully or partially filled)
const PDFPickSlip = async (pickSlipData) => {
    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    for (let order of pickSlipData) {
        doc.addPage();

        // Header for the invoice
        await InvoiceHeader(
            doc,
            parseInt(localStorage.getItem('SiteId')),
            `Order #${order.id} Pick Slip`,
            DateFormatter(String(new Date(Date.now()))),
            true,
            true,
            order.Customer
        );

        let lastYcoordinates = 82;

        let header = [
            [
                'Part Number',
                'Name',
                'Bin Location',
                'Quantity',
                'Backorder Quantity'
            ]
        ];
        let body = [];

        order.orderLines.forEach((line) => {
            let row = [
                line.partNumber,
                line.name,
                line.BinLocationIds.length > 0
                    ? line.BinLocationIds[0].name
                    : '',
                line.quantity,
                line.backorderQuantity
            ];
            body.push(row);
        });

        InvoiceTable(doc, lastYcoordinates + 18, header, body);
    }

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFPickSlip;
