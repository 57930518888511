import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { PurchaseOrder } from '../interfaces/ServiceInterface';

const GetAllPurchaseOrders = (
  setPurchaseOrders: Dispatch<SetStateAction<PurchaseOrder[]>>
) => {
  api.get('allPurchaseOrders').then((res) => {
    setPurchaseOrders(res.data);
  });
};

export default GetAllPurchaseOrders;
