// IMPROTS
import { useEffect, useState } from 'react';
import { TableRow, Chip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import Paper from '../../../../global/Paper';
import InvoiceHeader from './InvoiceHeader';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../../global/tableComponents/DataTable';
import Fab from '../../../../global/Fab';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
import PageWrapper from '../../../../global/PageWrapper';
// LOGIC
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import SubmitServiceWarranty from '../../logic/SubmitServiceWarranty';
import GetServiceWarrantyInvoice from '../../logic/GetServiceWarrantyInvoice';
// INTERFACES
import { Creditor, Site } from '../../../../global/interfaces/GeneralInterface';
import { Account } from '../../../../global/interfaces/AdminInterface';
import { Service } from '../../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Account: Account;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
    documentReference: string;
}
interface InvoiceLine {
    id: number;
    Service?: Service;
    amount: string;
    description: string;
}

const ViewServiceWarrantyInvoice = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const { id } = useParams<{ id: string }>();
    const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false);
    const [responseCode, setResponseCode] = useState<number>(null);
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([]);
    const [selectedSite, setSelectedSite] = useState<number>(
        parseInt(localStorage.getItem('SiteId'))
    );
    const [invoiceObject, setInvoiceObject] = useState<InvoiceObject>({
        Creditor: null,
        Site: null,
        Account: null,
        documentDate: null,
        dueDate: null,
        GSTtype: 'G1',
        documentTotal: null,
        documentReference: ''
    });

    const columns = [
        { id: 0, label: 'ID', width: 100 },
        { id: 3, label: 'Status', width: 100 },
        { id: 1, label: 'Vehicle', width: 400 },
        { id: 2, label: 'Amount', width: 100 },
        { id: 4, label: '', width: 100 }
    ];

    useEffect(() => {
        if (id) {
            GetServiceWarrantyInvoice(
                id,
                setInvoiceObject,
                setInvoiceLines,
                setSelectedSite
            );
        }
    }, [id]);

    // Get the total of all the entered lines
    const totalLines = () => {
        let total = 0;
        invoiceLines.forEach((line) => {
            total += parseFloat(line.amount);
        });
        return total;
    };

    const handleSubmitServiceWarranty = () => {
        if (!isSubmittingInvoice) {
            SubmitServiceWarranty(
                invoiceObject,
                invoiceLines,
                totalLines(),
                responseCode,
                selectedSite,
                showSnackbar,
                setIsSubmittingInvoice
            );
        }
    };

    const getVehicleName = (line: InvoiceLine) => {
        const vehicle = line.Service?.CustomerVehicleId
            ? line.Service.CustomerVehicle
            : line.Service?.Vehicle;

        if (vehicle) {
            const { year, Make, Model } = vehicle;
            return `${year} ${Make.name} ${Model.name}`;
        }

        return '';
    };

    const getVehicleVin = (line: InvoiceLine) => {
        const vehicle = line.Service?.CustomerVehicleId
            ? line.Service.CustomerVehicle
            : line.Service?.Vehicle;
        return vehicle?.vin || '';
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Service Warranty #{id}</Typography>
            <br />
            <InvoiceHeader
                invoiceObject={invoiceObject}
                setInvoiceObject={setInvoiceObject}
                invoiceLines={invoiceLines}
                setInvoiceLines={setInvoiceLines}
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                responseCode={responseCode}
                setResponseCode={setResponseCode}
                isDisabled={true}
            />
            <br />
            {invoiceLines.length > 0 && (
                <Paper>
                    <DataTable columns={columns}>
                        {invoiceLines.map((line) => (
                            <TableRow>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            `/service/viewBooking/${line.Service.id}`
                                        )
                                    }
                                >
                                    {line.Service?.id}
                                    <br />
                                </DataCellColoured>
                                <DataCell>
                                    <Chip
                                        size="small"
                                        variant="outlined"
                                        label={line.Service?.serviceComplete}
                                        color={
                                            line.Service?.serviceComplete ===
                                            'Incomplete'
                                                ? 'error'
                                                : line.Service
                                                        ?.serviceComplete ===
                                                    'Complete'
                                                  ? 'warning'
                                                  : 'success'
                                        }
                                    ></Chip>
                                </DataCell>
                                <DataCell>
                                    {getVehicleName(line)}
                                    <br />
                                    <span
                                        style={{
                                            color: '#555',
                                            fontSize: '12px'
                                        }}
                                    >
                                        VIN: {getVehicleVin(line)}
                                    </span>
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.amount)}
                                </DataCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <DataCell colSpan={2}></DataCell>
                            <DataCell>
                                <b>Total</b>
                            </DataCell>
                            <DataCell>
                                <b>{CurrencyFormatter(totalLines())}</b>
                            </DataCell>
                            <DataCell></DataCell>
                        </TableRow>
                    </DataTable>
                </Paper>
            )}

            <Fab editIcon={false} onClick={handleSubmitServiceWarranty} />
        </PageWrapper>
    );
};

export default withSnackbar(ViewServiceWarrantyInvoice);
