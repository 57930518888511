import { Divider, Grid, Typography } from '@mui/material';

const line = (title, description, required = false) => {
    return (
        <>
            <Grid item xs={3}>
                {title}
            </Grid>
            <Grid item xs={1} textAlign={'center'}>
                {required ? 'X' : null}
            </Grid>
            <Grid item xs={8}>
                <Typography variant="caption">{description}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </>
    );
};

export const StockLevelCSVExplanation = () => {
    return (
        <Grid item xs={12}>
            <Divider />
            <Grid container spacing={0} alignItems={'center'}>
                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                    <Typography>
                        Ora accepts the following value to be in the CSV in
                        order to create the Stock Level:
                    </Typography>
                </Grid>
                <Grid item xs={3} textAlign={'center'}>
                    Property
                </Grid>
                <Grid item xs={1} textAlign={'center'}>
                    Required
                </Grid>
                <Grid item xs={8} textAlign={'center'}>
                    Description
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                {line(
                    'Part Number',
                    'Must match one of the existing Part Number.',
                    true
                )}
                {line('Quantity On Hand', 'Must be a number.', true)}
                {line('Site', 'Must match the name of an existing Site.', true)}
                {line('Maximum Stock', 'Must be a number.')}
                {line('Minimum Stock', 'Must be a number.')}
            </Grid>
        </Grid>
    );
};
