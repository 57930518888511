// IMPORTS
import { Dispatch, SetStateAction } from 'react';

// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { DebtorPayment } from '../../../global/interfaces/GeneralInterface';

// LOGIC
import api from '../../../../../api';

/**
 * GetDebtorPayments
 * Front-end API request for a customer's debtor information
 * @param id the customer's ID
 * @param numRows the number of rows per page for a customer's debtor payments
 * @param setDebtorOwing setter for amount owing
 * @param setNextDueDate setter for next due date
 * @param setCustomerInfo setter for Customer data
 * @param customerInfo Customer data
 * @param setPayments setter for debtor payments
 * @param setPaymentsPage setter for current page of debtor payments
 * @param setNumPayments setter for total number of payments
 */
const GetDebtorPayments = (
  id: string,
  numRows: number,
  setDebtorOwing: Dispatch<SetStateAction<number>>,
  setNextDueDate: Dispatch<SetStateAction<any>>,
  setCustomerInfo: Dispatch<SetStateAction<Customer>>,
  customerInfo: Customer,
  setPayments: Dispatch<SetStateAction<any>>,
  setPaymentsPage: Dispatch<SetStateAction<DebtorPayment[]>>,
  setNumPayments: Dispatch<SetStateAction<number>>
) => {
  api.get(`debtorPayments/${id}`).then((res) => {
    setDebtorOwing(res.data.balance);
    setNextDueDate(res.data.dueDate);

    // Paginate the account payments
    let paymentsPages = []; // Slice the results into 'pages'
    let debtorPayments = res.data.debtorPayments.reverse();
    for (let i = 0; i < debtorPayments.length; i += numRows) {
      let paymentPage = debtorPayments.slice(i, i + numRows);
      paymentsPages.push(paymentPage);
    }

    // Still have to do this since we're still playing
    // along with SelectedDebtor interface
    setCustomerInfo({ ...customerInfo, debtorPayments: debtorPayments });

    setPayments(paymentsPages);
    setPaymentsPage(paymentsPages[0]);
    setNumPayments(debtorPayments.length);
  });
};

export default GetDebtorPayments;
