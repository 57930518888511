import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';

const getVehicleStatus = (vehicle: Vehicle, vehicleMovement?: any) => {
    if (vehicle.sold) {
        return 'Sold';
    }

    if (vehicleMovement) {
        if (vehicleMovement.sales.length > 0) {
            return vehicleMovement.sales[0].id;
        } else if (vehicle.available) {
            return 'Available';
        } else if (vehicleMovement.trades.length > 0) {
            return 'Pending Finalise';
        }
    } else {
        if (vehicle.VehicleSaleId) {
            return vehicle.VehicleSaleId;
        } else if (vehicle.available) {
            return 'Available';
        } else if (vehicle.TradeInId) {
            return 'Pending Finalise';
        }
    }
    return 'Unavailable';
};

export default getVehicleStatus
