import { Box, Divider, TableRow, Typography } from '@mui/material';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../../../global/logic/Formatters';
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCell from '../../../../../global/tableComponents/DataCell';
import { Invoice } from '../../../../../global/interfaces/PartsInterface';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
import dayjs from 'dayjs';

export const PendingPayrunDrawerContent = ({
    selectedSite,
    queryDate,
    pendingPayrun
}) => {
    const columns = [
        { id: 0, label: 'Invoice ID', width: 200 },
        { id: 1, label: 'Date Created' },
        { id: 2, label: 'Type' },
        { id: 3, label: 'Unpaid Amount' }
    ];

    const getPaymentAmount = (invoice: Invoice) => {
        let total = 0;
        for (let payment of invoice.paymentHistory) {
            if (
                dayjs(payment.date).isBefore(queryDate) &&
                payment.removed !== true
            ) {
                total += payment.amount;
            }
        }
        return total;
    };

    const getInvoiceType = (invoice) => {
        let type =
            invoice.StockInvoiceId && invoice.StockInvoice.linkedOrders != null
                ? 'Stock Invoice'
                : invoice.StockInvoiceId &&
                    invoice.StockInvoice.linkedOrders != null
                  ? 'Admin Invoice'
                  : invoice.VehicleInvoiceId
                    ? 'Vehicle Invoice'
                    : invoice.CreditInvoiceId
                      ? 'Credit Invoice'
                      : invoice.VehicleRegistrationId
                        ? 'Vehicle Registration'
                        : invoice.VehicleRebateId
                          ? 'vehicle Rebate'
                          : invoice.ServiceWarrantyId
                            ? 'Service Warrant'
                            : 'Misc';

        return type;
    };

    const getDocumentTotal = (invoice) => {
        let documentTotal = invoice.StockInvoiceId
            ? invoice.StockInvoice.documentTotal
            : invoice.VehicleInvoiceId
              ? invoice.VehicleInvoice.documentTotal
              : invoice.CreditInvoiceId
                ? invoice.CreditInvoice.documentTotal
                : invoice.VehicleRegistrationId
                  ? invoice.VehicleRegistration.documentTotal
                  : invoice.VehicleRebateId
                    ? invoice.VehicleRebate.documentTotal
                    : invoice.ServiceWarrantyId
                      ? invoice.ServiceWarranty.documentTotal
                      : 0;

        return documentTotal;
    };

    const getInvoiceDate = (invoice) => {
        let date = invoice.StockInvoiceId
            ? invoice.StockInvoice.invoiceDate
            : invoice.VehicleInvoiceId
              ? invoice.VehicleInvoice.invoiceDate
              : invoice.CreditInvoiceId
                ? invoice.CreditInvoice.invoiceDate
                : invoice.VehicleRegistrationId
                  ? invoice.VehicleRegistration.invoiceDate
                  : invoice.VehicleRebateId
                    ? invoice.VehicleRebate.dateReceived
                    : invoice.ServiceWarrantyId
                      ? invoice.ServiceWarranty.dateReceived
                      : '';

        return date;
    };

    const getGrandTotal = (invoices) => {
        let total = 0;
        for (let invoice of invoices) {
            if (getDocumentTotal(invoice) > getPaymentAmount(invoice)) {
                total += getDocumentTotal(invoice) - getPaymentAmount(invoice);
            }
        }
        return total;
    };

    const getLink = (invoice) => {
        let link = invoice.StockInvoiceId
            ? '/inventory/viewInvoice/' + invoice.id
            : invoice.VehicleInvoiceId
              ? '/vehicles/viewVehicleInvoice/' + invoice.VehicleInvoiceId
              : invoice.CreditInvoiceId
                ? '/inventory/creditInvoice/view' + invoice.CreditInvoiceId
                : invoice.VehicleRegistrationId
                  ? null
                  : invoice.VehicleRebateId
                    ? '/admin/viewRebateInvoice/:id' + invoice.VehicleRebateId
                    : invoice.ServiceWarrantyId
                      ? '/admin/viewServiceWarrantyInvoice/' +
                        invoice.ServiceWarrantyId
                      : null;

        return link;
    };

    return (
        <Box sx={{ width: '40vw', padding: '20px' }}>
            <Typography variant="h4">
                {queryDate.format('DD/MM/YYYY')}
            </Typography>
            <Typography>Pending Payment Runs</Typography>
            <Box height={'10px'}></Box>
            <Divider></Divider>
            <Box height="20px"></Box>
            {pendingPayrun.length === 0 ? (
                <Typography>
                    There are no Payment Run that haven't been reconciled prior
                    to the date you selected.
                </Typography>
            ) : (
                <DataTable columns={columns}>
                    {pendingPayrun.map((invoice) => {
                        if (
                            getDocumentTotal(invoice) -
                                getPaymentAmount(invoice) !==
                                0 &&
                            getDocumentTotal(invoice) >
                                getPaymentAmount(invoice)
                        ) {
                            return (
                                <TableRow>
                                    {getLink(invoice) != null ? (
                                        <DataCellColoured
                                            handleClick={() =>
                                                window.open(
                                                    getLink(invoice),
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {invoice.id}
                                        </DataCellColoured>
                                    ) : (
                                        <DataCell>{invoice.id}</DataCell>
                                    )}

                                    <DataCell>
                                        {DateFormatter(getInvoiceDate(invoice))}
                                    </DataCell>
                                    <DataCell>
                                        {getInvoiceType(invoice)}
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            getDocumentTotal(invoice) -
                                                getPaymentAmount(invoice)
                                        )}
                                    </DataCell>
                                </TableRow>
                            );
                        } else {
                            return null;
                        }
                    })}
                    <TableRow>
                        <DataCell></DataCell>
                        <DataCell></DataCell>
                        <DataCell>
                            <strong>Total</strong>
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(getGrandTotal(pendingPayrun))}
                        </DataCell>
                    </TableRow>
                </DataTable>
            )}
        </Box>
    );
};
