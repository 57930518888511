// IMPORTS
import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';

// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import GetUser from '../../global/databaseLogic/GetUser';
import { DailyTaskList, User } from '../../global/interfaces/GeneralInterface';
import GetDailyTaskList from './logic/GetDailyTaskList';
import TaskItem from './components/TaskItem';
import handleUpdateTaskList from './logic/HandleUpdateTaskList';

const DailyTaskListTabContent = () => {
    const currentSiteId = localStorage.getItem('SiteId');
    const [currentUser, setCurrentUser] = useState<User>();
    const [dailyTaskList, setDailyTaskList] = useState<DailyTaskList>();

    useEffect(() => {
        GetUser(0, setCurrentUser);
    }, []);

    useEffect(() => {
        if (currentUser && currentSiteId) {
            GetDailyTaskList({ currentSiteId, currentUser, setDailyTaskList });
        }
    }, [currentUser, currentSiteId]);

    const toggleCompleted = (taskId: number) => {
        const updatedTaskItems = dailyTaskList?.dailyTaskItems?.map(
            (taskItem) => {
                if (taskItem.id === taskId) {
                    return { ...taskItem, status: !taskItem.status };
                }
                return taskItem;
            }
        );

        setDailyTaskList((prevState) => {
            const updatedDailyTaskList = {
                ...prevState,
                dailyTaskItems: updatedTaskItems
            };
            handleUpdateTaskList(updatedDailyTaskList);
            return updatedDailyTaskList;
        });
    };

    return (
        <PageWrapper>
            <Paper>
                <Grid container spacing={2}>
                    {dailyTaskList?.dailyTaskItems?.length > 0 ? (
                        dailyTaskList?.dailyTaskItems?.map((taskItem) => (
                            <TaskItem
                                key={taskItem.id}
                                taskItem={taskItem}
                                toggleCompleted={toggleCompleted}
                            />
                        ))
                    ) : (
                        <Grid item>
                            <Typography>
                                You have not been assigned any daily tasks yet.
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </PageWrapper>
    );
};

export default DailyTaskListTabContent;



