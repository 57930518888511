import { Dispatch, SetStateAction } from 'react';
import { Table } from '../interfaces/StockOrderInterface';
import api from '../../../../../api';

const AddPartToStockOrder = (
    part,
    selectedSupplier,
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    orderType: string
) => {
    let partData = part.unitData;
    let stockData = part.stockData;
    let type = orderType.toLowerCase().split(',');

    let rrp = selectedSupplier?.RRP ? selectedSupplier.RRP : partData.priceRRP;
    let cpd = selectedSupplier?.CPD
        ? selectedSupplier.CPD
        : partData.costPriceDaily;

    type.forEach((orderType) => {
        let newPart = {
            id: partData.id,
            partNumber: partData.partNumber,
            name: partData.name,
            quantity: 1,
            supplier: selectedSupplier.SupplierId,
            costPrice: cpd,
            rowTotal: cpd,
            gst: cpd * 1.1 - cpd,
            rrp: rrp,
            minStock: stockData.minStock,
            maxStock: stockData.maxStock,
            qtyOnHand: stockData.qtyOnHand,
            selected: true,
            OrderType: orderType,
            rowId: null
        };

        let currentTables = tables;
        let supplierId = selectedSupplier.SupplierId;
        let tableExists = false;

        for (let i = 0; i < currentTables.length; i++) {
            const table = currentTables[i];
            if (
                table.supplierId === supplierId &&
                table.OrderType === orderType
            ) {
                tableExists = true;
                newPart.rowId = currentTables[i].orderLines.length + 1;
                currentTables[i].orderLines.push(newPart);
            }
        }

        if (!tableExists) {
            api.get('creditorNextGen/' + supplierId).then((res) => {
                let supplier = res.data;
                newPart.rowId = 1;
                let newTable = {
                    tableId: currentTables.length + 1,
                    supplierId: supplierId,
                    orderReference: '',
                    supplierName: selectedSupplier.name,
                    Supplier: supplier,
                    totalCost: partData.costPriceDaily,
                    orderLines: [newPart],
                    OrderType: orderType
                };
                currentTables.push(newTable);
                setTables([...currentTables]);
            });
        }

        setTables([...currentTables]);
    });
};

export default AddPartToStockOrder;
