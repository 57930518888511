import DocumentationPage from '../DocumentationPage';
import { AddCreditorSteps, PrintAccountStatementsSteps, ViewEditCreditorSteps } from '../data/admin/creditors';

const CreditorsSupportPage = () => {
    const contentSections = [
        {
            id: 'add-creditor',
            title: 'Add new creditor',
            href: '/support/admin/creditors/#add-creditor',
            description: 'To create new creditor, follow these steps:',
            steps: AddCreditorSteps
        },
        {
            id: 'view-and-update-creditor',
            title: 'View and update creditor details',
            href: '/support/admin/creditors/#view-and-update-creditor',
            description:
                'To view or update details of an existing creditor, follow these steps:',
            steps: ViewEditCreditorSteps
        },
        {
            id: 'print-account-statement',
            title: 'Print account statement',
            href: '/support/admin/creditors/#print-account-statement',
            description:
                'This function prints all invoices associated with a creditor within a selected date range. To print an account statement, follow these steps:',
            steps: PrintAccountStatementsSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Creditors"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default CreditorsSupportPage;