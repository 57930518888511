import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Checkbox,
    Table,
    TableHead,
    TableBody,
    TableRow,
    Typography,
    TextField,
    IconButton,
    Tooltip,
    Grid,
    Box
} from '@mui/material';
import { Clear, LibraryAddCheck } from '@mui/icons-material';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';

// COMPONENTS
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import SubmissionButtons from './SubmissionButtons';
import Paper from '../../../../global/Paper';
import Drawer from '../../../../global/Drawer';

// LOGIC
import HandleQuantityChange from '../../logic/HandleQuantityChange';
import HandleOrderReferenceChange from '../../logic/HandleOrderReferenceChange';
import HandleRemoveLine from '../../logic/HandleRemoveLine';
import CalculateSupplierTotalCost from '../../logic/CalculateSupplierTotalCost';
import HandleSelectSupplier from '../../logic/HandleSelectSupplier';
import HandleCheckboxSelect from '../../logic/HandleCheckboxSelect';
import HandleSupplierRemove from '../../logic/HandleSupplierRemove';
import GetPartSalesHistory from '../../logic/GetPartSalesHistory';
import GetOtherSiteStock from '../../logic/GetOtherSiteStock';

// INTERFACE
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Stock } from '../../../../global/interfaces/PartsInterface';
interface OrderLinesTableProps {
    selectedSite: number;
    monthList: { month: string; qtySold: number }[];
    setMonthList: Dispatch<
        SetStateAction<{ month: string; qtySold: number }[]>
    >;
    tables: TableInterface[];
    setTables: Dispatch<SetStateAction<TableInterface[]>>;
    staging: boolean;
    setStaging: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>;
}

// HAD TO RECREATE IT WITH DIFFERENT NAME BECAUSE OF USING MUI TABLE (same name)
interface TableInterface {
    OrderType: string;
    orderLines: TableOrderLine[];
    orderReference: string;
    supplierId: number;
    supplierName: string;
    tableId: number;
    totalCost: number;
}

interface TableOrderLine {
    OrderType: string; // probably not useful
    costPrice: number;
    gst: number;
    id: number;
    maxStock: number; // probably not used anymore
    minStock: number; // probably not used anymore
    name: string;
    partNumber: string;
    qtyOnHand: number;
    quantity: number;
    rowId: number;
    rowTotal: number;
    rrp: number;
    selected: boolean;
    supplier: any; // don't know what it's for

    linkedOrders?: number[];
    linkedOrdersQuantity?: number[];
    orderId?: number;
    costPriceDaily?: number;
    costPriceAverage?: number;
    supplierName?: string;
}

const OrderLinesTable = (props: OrderLinesTableProps) => {
    const columns = [
        { id: 1, label: <strong>Part Number</strong>, width: 20 },
        { id: 2, label: <strong>Name</strong> },
        { id: 3, label: <strong>Stock</strong> },
        { id: 4, label: <strong>Cost</strong> },
        { id: 5, label: <strong>Total</strong> },
        { id: 6, label: <strong>Quantity</strong> },
        { id: 7, label: <strong>BO</strong> }
    ];

    const backorderColumns = [
        { id: 0, label: 'Order ID' },
        { id: 1, label: 'Quantity Ordered' }
    ];

    const [focusPosition, setFocusPosition] = useState<number>(0); // Keep track of the focusPosition
    const [textFieldList, setTextFieldList] = useState<number[]>([]);
    const [updating, setUpdating] = useState<boolean>(false); // Keep track if we are making change in the Textfield
    const [otherSiteStock, setOtherSiteStock] = useState<Stock[]>([]);
    const [reverse, setReverse] = useState<boolean>(false);

    const [backorderDrawerOpen, setBackorderDrawerOpen] =
        useState<boolean>(false);
    const [selectedPart, setSelectedPart] = useState<any>({ linkedOrders: [] });

    const handleKeyPressed = (e) => {
        // If we detect an ArrowDown, we add one to the focus position (we go down one line in the table)
        if (e.code === 'ArrowDown') {
            setUpdating(false);
            setFocusPosition(focusPosition + 1);
            // If we try to go down on the last line, just setFocusPosition to the last line
            if (focusPosition + 1 > textFieldList.at(-1)) {
                setFocusPosition(textFieldList.at(-1));
            }
        }
        // If we detect an ArrowUp, we substract one to the focus position (we go up one line in the table)
        else if (e.code === 'ArrowUp') {
            setUpdating(false);
            setFocusPosition(focusPosition - 1);
            // Prevent focusPosition going in the negative index
            if (focusPosition - 1 < 0) {
                setFocusPosition(0);
            }
        }
        // If we click on one of the Textfield, setFocusPosition to this one
        else if (e.type === 'click') {
            setUpdating(false);
            setFocusPosition(parseInt(e.target.id));
        }
    };

    // Get the partSalesHistory when we focus on a new Textfield
    const handleOnFocus = (
        selectedSite,
        rowId,
        setMonthList,
        rowPartNumber,
        e
    ) => {
        // Use of parseInt for e.target.id to prevent getting partSalesHistory when e.target.id is an empty string
        // if (focusPosition === parseInt(e.target.id)) {
        GetPartSalesHistory(selectedSite, rowId, setMonthList);
        GetOtherSiteStock(rowId, setOtherSiteStock);
        // }
    };

    const handleOpenDrawer = (line) => {
        setSelectedPart(line);
        setBackorderDrawerOpen(true);
    };

    // UseEffect used to create a list of index for the Textfield
    useEffect(() => {
        let tempTextFieldList = [];
        let counter = 0;
        for (let x = 0; x < props.tables.length; x++) {
            let table = props.tables[x];
            for (let i = 0; i < table.orderLines.length; i++) {
                tempTextFieldList.push(counter);
                counter++;
            }
        }
        setTextFieldList(tempTextFieldList);
    }, [props.tables]);

    // Initialize counter, will be used in the JSX to assign IDs to each Textfield by going through the textFieldList
    let counter = 0;

    return props.tables.length > 0 ? (
        <>
            <Grid container spacing={1} style={{ height: 'fit-content' }}>
                <Grid item xs={3}>
                    <Paper textAlign="center">
                        <Table size="small">
                            <TableHead>
                                <DataCell align="center">
                                    <b>Site</b>
                                </DataCell>
                                <DataCell align="center">
                                    <b>Qty on Hand</b>
                                </DataCell>
                            </TableHead>
                            <TableBody>
                                {otherSiteStock?.map((row) => (
                                    <TableRow>
                                        <DataCell align="center">
                                            {row['Site.name']}
                                        </DataCell>
                                        <DataCell align="center">
                                            {row.qtyOnHand}
                                        </DataCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableHead>
                                <TableRow>
                                    <DataCell align="center">
                                        <b>Month</b>
                                    </DataCell>
                                    <DataCell align="center">
                                        <b>Qty Sold</b>
                                    </DataCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.monthList?.map((row) => (
                                    <TableRow>
                                        <DataCell align="center">
                                            {row.month}
                                        </DataCell>
                                        <DataCell align="center">
                                            {row.qtySold}
                                        </DataCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                {/* Grid below is set to a maxHeight and can therefore be scrolled without scrolling the entire page */}
                <Grid
                    item
                    xs={9}
                    style={{
                        overflowY: 'scroll',
                        maxHeight: '80vh'
                    }}
                >
                    <Paper textAlign="center">
                        <SubmissionButtons
                            tables={props.tables}
                            setTables={props.setTables}
                            selectedSite={props.selectedSite}
                            showSnackbar={props.showSnackbar}
                            staging={props.staging}
                            setStaging={props.setStaging}
                            reverse={reverse}
                            setReverse={setReverse}
                        />
                        <br />
                        {props?.tables?.map((table) => {
                            let lines;
                            if (reverse) {
                                lines = table?.orderLines?.slice(0).reverse();
                            } else {
                                lines = table?.orderLines;
                            }

                            return (
                                <Box>
                                    <Typography variant="h5">
                                        {table.supplierName} -{' '}
                                        {CurrencyFormatter(
                                            CalculateSupplierTotalCost(
                                                props.tables,
                                                table.supplierId,
                                                table.OrderType
                                            )
                                        )}{' '}
                                        - {table?.OrderType?.toUpperCase()}
                                    </Typography>
                                    <br />
                                    <DataTable
                                        columns={[
                                            {
                                                id: 0,
                                                label: (
                                                    <IconButton
                                                        onClick={() =>
                                                            HandleSelectSupplier(
                                                                table.tableId,
                                                                props.tables,
                                                                props.setTables
                                                            )
                                                        }
                                                    >
                                                        <LibraryAddCheck />
                                                    </IconButton>
                                                )
                                            },
                                            ...columns,
                                            {
                                                id: 11,
                                                label: (
                                                    <IconButton
                                                        onClick={() =>
                                                            HandleSupplierRemove(
                                                                table.supplierId,
                                                                props.tables,
                                                                props.setTables,
                                                                table.OrderType
                                                            )
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                )
                                            }
                                        ]}
                                    >
                                        {lines.map((row) => {
                                            return (
                                                <TableRow key={row.rowId}>
                                                    <DataCell>
                                                        <Checkbox
                                                            color="primary"
                                                            checked={
                                                                row.selected
                                                            }
                                                            onChange={() =>
                                                                HandleCheckboxSelect(
                                                                    row.rowId,
                                                                    table.tableId,
                                                                    props.tables,
                                                                    props.setTables
                                                                )
                                                            }
                                                        />
                                                    </DataCell>
                                                    <DataCellColoured
                                                        handleClick={() =>
                                                            window.open(
                                                                `/inventory/view/${row.id}`,
                                                                '_blank'
                                                            )
                                                        }
                                                    >
                                                        {row.partNumber}
                                                    </DataCellColoured>

                                                    <Tooltip
                                                        title={row.name}
                                                        placement="top"
                                                    >
                                                        <DataCell>
                                                            {row.name.length > 9
                                                                ? row.name.substring(
                                                                      0,
                                                                      9
                                                                  ) + '...'
                                                                : row.name}
                                                        </DataCell>
                                                    </Tooltip>

                                                    <DataCell>
                                                        {row.qtyOnHand}
                                                    </DataCell>
                                                    <DataCell>
                                                        {row.costPrice
                                                            ? CurrencyFormatter(
                                                                  row.costPrice
                                                              )
                                                            : row.costPriceDaily
                                                              ? CurrencyFormatter(
                                                                    row.costPriceDaily
                                                                )
                                                              : CurrencyFormatter(
                                                                      row.costPriceAverage
                                                                  )
                                                                ? CurrencyFormatter(
                                                                      row.costPriceAverage
                                                                  )
                                                                : CurrencyFormatter(
                                                                      row.rrp
                                                                  )}
                                                    </DataCell>
                                                    <DataCell>
                                                        {row.costPrice
                                                            ? CurrencyFormatter(
                                                                  row.costPrice *
                                                                      row.quantity
                                                              )
                                                            : row.costPriceDaily
                                                              ? CurrencyFormatter(
                                                                    row.costPriceDaily *
                                                                        row.quantity
                                                                )
                                                              : row.costPriceAverage
                                                                ? CurrencyFormatter(
                                                                      row.costPriceAverage *
                                                                          row.quantity
                                                                  )
                                                                : CurrencyFormatter(
                                                                      row.rrp *
                                                                          row.quantity
                                                                  )}
                                                    </DataCell>
                                                    <DataCell>
                                                        <TextField
                                                            id={textFieldList[
                                                                counter
                                                            ]?.toString()}
                                                            value={row.quantity}
                                                            size="small"
                                                            autoComplete="off"
                                                            focused={
                                                                textFieldList[
                                                                    counter
                                                                ] ===
                                                                focusPosition
                                                            }
                                                            inputRef={(
                                                                input
                                                            ) => {
                                                                if (
                                                                    focusPosition ===
                                                                    input?.id
                                                                ) {
                                                                    input.focus();
                                                                    if (
                                                                        updating ===
                                                                        false
                                                                    ) {
                                                                        input.select();
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) =>
                                                                HandleQuantityChange(
                                                                    props.tables,
                                                                    props.setTables,
                                                                    table.supplierId,
                                                                    table.OrderType,
                                                                    row.rowId,
                                                                    e,
                                                                    props.showSnackbar,
                                                                    setUpdating
                                                                )
                                                            }
                                                            onKeyDown={(e) => {
                                                                handleKeyPressed(
                                                                    e
                                                                );
                                                                handleOnFocus(
                                                                    props.selectedSite,
                                                                    row.id,
                                                                    props.setMonthList,
                                                                    row.partNumber,
                                                                    e
                                                                );
                                                            }}
                                                            onClick={(e) => {
                                                                handleKeyPressed(
                                                                    e
                                                                );
                                                                handleOnFocus(
                                                                    props.selectedSite,
                                                                    row.id,
                                                                    props.setMonthList,
                                                                    row.partNumber,
                                                                    e
                                                                );
                                                            }}
                                                        />{' '}
                                                        <br />
                                                        <div
                                                            style={{
                                                                display: 'none'
                                                            }}
                                                        >
                                                            {counter++}
                                                        </div>
                                                    </DataCell>
                                                    {row?.linkedOrders?.length >
                                                    0 ? (
                                                        <DataCellColoured
                                                            handleClick={() =>
                                                                handleOpenDrawer(
                                                                    row
                                                                )
                                                            }
                                                        >
                                                            {
                                                                row
                                                                    ?.linkedOrders
                                                                    ?.length
                                                            }
                                                        </DataCellColoured>
                                                    ) : (
                                                        <DataCell>
                                                            {
                                                                row
                                                                    ?.linkedOrders
                                                                    ?.length
                                                            }
                                                        </DataCell>
                                                    )}

                                                    <DataCell>
                                                        <IconButton
                                                            onClick={() =>
                                                                HandleRemoveLine(
                                                                    props.tables,
                                                                    props.setTables,
                                                                    table.tableId,
                                                                    row.rowId
                                                                )
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    </DataCell>
                                                </TableRow>
                                            );
                                        })}
                                    </DataTable>
                                    <br />
                                    <TextField
                                        variant="outlined"
                                        label="Order Reference"
                                        fullWidth
                                        value={table.orderReference}
                                        onChange={(e) =>
                                            HandleOrderReferenceChange(
                                                props.tables,
                                                props.setTables,
                                                table.supplierId,
                                                e,
                                                setFocusPosition
                                            )
                                        }
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <br />
                                    <br />
                                </Box>
                            );
                        })}
                        <br />
                        <SubmissionButtons
                            tables={props.tables}
                            setTables={props.setTables}
                            selectedSite={props.selectedSite}
                            showSnackbar={props.showSnackbar}
                            staging={props.staging}
                            setStaging={props.setStaging}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Drawer
                openDrawer={backorderDrawerOpen}
                setOpenDrawer={setBackorderDrawerOpen}
                title={`Backorder for ${selectedPart.partNumber}`}
                subTitle={''}
            >
                <DataTable columns={backorderColumns}>
                    {selectedPart?.linkedOrders.map((order, index) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/inventory/viewSale/${order}`,
                                        '_blank'
                                    )
                                }
                            >
                                {order}
                            </DataCellColoured>
                            <DataCell>
                                {selectedPart.linkedOrdersQuantity[index]}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Drawer>
        </>
    ) : null;
};

export default OrderLinesTable;
