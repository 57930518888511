// IMPORTS
import { useEffect, useState } from 'react';
import {
    Dialog,
    Typography,
    debounce,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import PartsTable from './components/PartsTable';
import GeneralEntryTable from './components/GeneralEntryTable';
import GeneralJournalHeader from './components/GeneralJournalHeader';
import Fab from '../../global/Fab';
import PageDoesNotExist from '../../global/PageDoesNotExist';
import RoleCheck from '../../global/RoleCheck';
import SpeedDial from '../../global/SpeedDial';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import GetGeneralJournal from './logic/GetGeneralJournal';
import HandleSubmit from './logic/HandleSubmit';
import HandleUpdate from './logic/HandleUpdate';
import { DeleteGeneralJournalEntry } from './logic/DeleteGeneralJournalEntry';
// INTERFACES
import { GeneralJournalLine } from '../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

export interface Part {
    accountName: string;
    accountId: number;
    accountType: string;
    partNumber: string;
    name: string;
    costPriceDaily: number;
    priceRRP: number;
    quantity: number;
    unitId: number;
    createdAt: number;
    gstType: string;
    dateEntered?: string;
}
export interface GeneralJournalLineTemp extends GeneralJournalLine {
    rowId?: number;
    accountName: string;
    descriptionError?: boolean;
    debitError?: boolean;
    creditError?: boolean;
    debitAmount: number;
    creditAmount: number;
}

const GeneralJournalUI = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const [readOnly, setReadOnly] = useState<boolean>(false);

    const [entryLines, setEntryLines] = useState<GeneralJournalLineTemp[]>([]);
    const [parts, setParts] = useState<Part[]>([]);
    const [reference, setReference] = useState<string>('');
    const [invalid, setInvalid] = useState<boolean>(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const actionsList = [
        {
            icon: <Edit />,
            name: 'Edit',
            onClick: () => setReadOnly(false)
        },
        {
            icon: <Delete />,
            name: 'Delete',
            onClick: () => {
                setDeleteDialogOpen(true);
            }
        }
    ];

    useEffect(() => {
        if (id) {
            GetGeneralJournal(
                parseInt(id),
                setReference,
                setEntryLines,
                setInvalid
            );
            setReadOnly(true);
        }
    }, [id]);

    const submitButton = debounce(() => {
        if (id?.length > 0) {
            HandleUpdate(
                parseInt(id),
                entryLines,
                setEntryLines,
                reference,
                showSnackbar
            );
        } else {
            HandleSubmit(
                setParts,
                entryLines,
                setEntryLines,
                parts,
                reference,
                showSnackbar
            );
        }
    }, 300);

    return (
        <PageWrapper>
            {invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4" align="left">
                        {`General Journal ${id?.length > 0 ? '#' + id : 'Entry'}`}
                    </Typography>
                    <br />
                    <GeneralJournalHeader
                        id={parseInt(id)}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                        entryLines={entryLines}
                        setEntryLines={setEntryLines}
                        showSnackbar={showSnackbar}
                    />
                    <br />
                    {parts.length > 0 && (
                        <>
                            <PartsTable parts={parts} setParts={setParts} />
                            <br />
                        </>
                    )}

                    {entryLines.length > 0 && (
                        <>
                            <GeneralEntryTable
                                id={parseInt(id)}
                                entryLines={entryLines}
                                setEntryLines={setEntryLines}
                                reference={reference}
                                setReference={setReference}
                                readOnly={readOnly}
                            />
                            <br />
                        </>
                    )}

                    <Dialog
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                    >
                        <DialogTitle>Delete General Journal Entry</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Deleting this General Journal Entry cannot be
                                undone. Do you wish to proceed?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setDeleteDialogOpen(false)}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() =>
                                    DeleteGeneralJournalEntry(
                                        parseInt(id),
                                        showSnackbar
                                    )
                                }
                                variant={'contained'}
                            >
                                Proceed
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {(entryLines.length > 0 || parts.length > 0) &&
                        !readOnly && (
                            <Fab
                                editIcon={false}
                                noSession={true}
                                onClick={submitButton}
                            />
                        )}
                    {readOnly && (
                        <RoleCheck
                            permission="delete_generalJournal"
                            component={<SpeedDial actionsList={actionsList} />}
                        />
                    )}
                </>
            )}
        </PageWrapper>
    );
};

export default withSnackbar(GeneralJournalUI);
