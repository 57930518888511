import api from '../../../../../../api';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';
import {
    BankTransaction,
    TransactionFile
} from '../../../../global/interfaces/AdminInterface';

const ReconcileVehicleSaleDirectDeposit = (
    sale: VehicleSale,
    lineId: number,
    bankFile: TransactionFile,
    bankTransaction: BankTransaction,
    isNewLine: boolean,
    showSnackbar: showSnackbar
) => {
    api.post('/reconcileVehicleSaleDirectDeposit', {
        sale: sale,
        paymentLineId: lineId,
        bankFile: bankFile,
        bankTransaction: bankTransaction,
        isNewLine: isNewLine
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Transaction Reconciled');
            setTimeout(() => window.location.reload(), 1000);
        } else {
            showSnackbar(
                'Something went wrong',
                'Please contact the Ora Development Team.',
                'error'
            );
        }
    });
};

export default ReconcileVehicleSaleDirectDeposit;
