// IMPORTS
import { Dispatch, SetStateAction } from "react";
import { Typography, Grid, TextField } from "@mui/material";

// COMPONENTS
import Autocomplete from "../../../global/Autocomplete";

// LOGIC
import FilterMakeList from "../logic/FilterMakeList";
import FilterModelList from "../logic/FilterModelList";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// INTERFACES
import { Make, Model, Series, FuelType, DriveType, BodyType, Vehicle } from "../../../global/interfaces/VehicleSaleInterface";
import { Creditor } from "../../../global/interfaces/GeneralInterface";

interface NewVehicleFormProps {
    specificLists: { Make: Make[]; Model: Model[]; Series: Series[]; FuelType: FuelType[]; Creditors: Creditor[]; DriveType: DriveType[]; BodyType: BodyType[] };
    setSpecificLists: Dispatch<SetStateAction<{ Make: Make[]; Model: Model[]; Series: Series[]; FuelType: FuelType[]; Creditors: Creditor[]; DriveType: DriveType[]; BodyType: BodyType[] }>>;
    specificInputs: { makeInput: string; modelInput: string; seriesInput: string; fuelTypeInput: string };
    setSpecificInputs: Dispatch<SetStateAction<{ makeInput: string; modelInput: string; seriesInput: string; fuelTypeInput: string }>>;
    newVehicle: Vehicle
    setNewVehicle: Dispatch<SetStateAction<Vehicle>>;
    gstMethod: string;
}

const NewBicyclesForm = ({ specificLists, setSpecificLists, specificInputs, setSpecificInputs, newVehicle, setNewVehicle, gstMethod }: NewVehicleFormProps) => {

    return (
        <>
            <br />
            <Typography variant="subtitle1">Required Details</Typography><br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        options={specificLists?.Make}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={"name"}
                        textfieldLabel="Make"
                        selectedValue={newVehicle?.Make}
                        handleSelectedValueChange={(nextValue) => {
                            FilterMakeList({
                                nextValue: nextValue,
                                specificLists: specificLists,
                                setSpecificLists: setSpecificLists,
                                newVehicle: newVehicle,
                                setNewVehicle: setNewVehicle,
                                specificInputs: specificInputs,
                                setSpecificInputs: setSpecificInputs,
                            });
                        }}
                        handleInputValueChange={(newInputValue) => {
                            setSpecificInputs({ ...specificInputs, makeInput: newInputValue });
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        options={specificLists?.Model}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={"name"}
                        textfieldLabel="Model"
                        selectedValue={newVehicle?.Model}
                        handleSelectedValueChange={(nextValue) => {
                            FilterModelList({
                                nextValue: nextValue,
                                specificLists: specificLists,
                                setSpecificLists: setSpecificLists,
                                newVehicle: newVehicle,
                                setNewVehicle: setNewVehicle,
                                specificInputs: specificInputs,
                                setSpecificInputs: setSpecificInputs,
                            });
                        }}
                        handleInputValueChange={(newInputValue) => {
                            setSpecificInputs({ ...specificInputs, modelInput: newInputValue });
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        options={specificLists?.Series}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={"name"}
                        textfieldLabel="Series"
                        selectedValue={newVehicle?.Series}
                        handleSelectedValueChange={(nextValue) => {
                            setNewVehicle({ ...newVehicle, Series: nextValue });
                        }}
                        handleInputValueChange={(newInputValue) => {
                            setSpecificInputs({ ...specificInputs, seriesInput: newInputValue });
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DatePicker
                            label="Year Model"
                            views={['year']}
                            format='YYYY'
                            value={newVehicle?.year}
                            onChange={(newValue) => setNewVehicle({ ...newVehicle, year: newValue })}
                            slotProps={{ textField: { fullWidth: true, error: false, size: 'small', InputLabelProps: { shrink: true } } }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label={gstMethod === "Including" ? "Base Cost (Inc. GST)" : "Base Cost (Ex. GST)" }
                        name="costPrice"
                        type="number"
                        value={newVehicle?.costPrice}
                        onChange={(e) => setNewVehicle({ ...newVehicle, costPrice: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <br />
            <Typography variant="subtitle1">Specific Details</Typography><br />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Colour"
                        name="colour"
                        value={newVehicle?.colour}
                        onChange={(e) => setNewVehicle({ ...newVehicle, colour: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="RRP"
                        name="priceRRP"
                        type="number"
                        value={newVehicle?.priceRRP}
                        onChange={(e) => setNewVehicle({ ...newVehicle, priceRRP: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Admin Load Cost"
                        name="adminLoadCost"
                        type="number"
                        value={newVehicle?.adminLoadCost}
                        onChange={(e) => setNewVehicle({ ...newVehicle, adminLoadCost: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default NewBicyclesForm;
