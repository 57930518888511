import moment from 'moment';

const HandleMoveTransactionV2 = (
    direction: string,
    type: string,
    week: string,
    index: number,
    reconciliationDetails,
    setReconciliationDetails,
    currentTab,
    setCurrentTab
) => {
    let oraTransactionsReconciled = JSON.parse(
        JSON.stringify([...reconciliationDetails.oraTransactionsReconciled])
    );
    let bankTransactionsReconciled = JSON.parse(
        JSON.stringify([...reconciliationDetails.bankTransactionsReconciled])
    );
    let oraTransactionsUnreconciled = JSON.parse(
        JSON.stringify([...reconciliationDetails.oraTransactionsUnreconciled])
    );
    let bankTransactionsUnreconciled = JSON.parse(
        JSON.stringify([...reconciliationDetails.bankTransactionsUnreconciled])
    );

    // If we are moving a transaction up
    if (direction === 'up') {
        // If we are moving an ora transaction
        if (type === 'ora') {
            // Get the index corresponding to the week in the unreconciled transactions and in the reconciled transactions
            let weekIndex = oraTransactionsUnreconciled.findIndex(
                (x) => x.week === week
            );
            let weekIndexReconciled = oraTransactionsReconciled.findIndex(
                (x) => x.week === week
            );
            // Get the relevant object
            let movingObject = JSON.parse(
                JSON.stringify(oraTransactionsUnreconciled[weekIndex])
            );

            // If we found one in the reconciled, then we push the new line in
            if (weekIndexReconciled !== -1) {
                oraTransactionsReconciled[weekIndexReconciled].lines.push(
                    movingObject.lines[index]
                );
            }
            // Otherwise we create the object
            else {
                oraTransactionsReconciled.push({
                    week: movingObject.week,
                    lines: [movingObject.lines[index]]
                });
                // We sort to put the new object in the correct position
                oraTransactionsReconciled.sort((a, b) =>
                    moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                        ? 1
                        : -1
                );
            }
            oraTransactionsUnreconciled[weekIndex].lines =
                oraTransactionsUnreconciled[weekIndex].lines.filter(
                    (x) => x.id !== movingObject.lines[index].id
                );
            if (oraTransactionsUnreconciled[weekIndex].lines.length === 0) {
                oraTransactionsUnreconciled.splice(weekIndex, 1);
                if (movingObject.week === currentTab) {
                    setCurrentTab(oraTransactionsUnreconciled[0].week);
                }
            }

            setReconciliationDetails({
                oraTransactionsReconciled: oraTransactionsReconciled,
                bankTransactionsReconciled: bankTransactionsReconciled,
                oraTransactionsUnreconciled: oraTransactionsUnreconciled,
                bankTransactionsUnreconciled: bankTransactionsUnreconciled
            });
        }

        // If we are moving a bank transaction
        else if (type === 'bank') {
            // Get the index corresponding to the week in the unreconciled transactions and in the reconciled transactions
            let weekIndex = bankTransactionsUnreconciled.findIndex(
                (x) => x.week === week
            );
            let weekIndexReconciled = bankTransactionsReconciled.findIndex(
                (x) => x.week === week
            );
            // Get the relevant object
            let movingObject = JSON.parse(
                JSON.stringify(bankTransactionsUnreconciled[weekIndex])
            );

            // If we found one in the reconciled, then we push the new line in
            if (weekIndexReconciled !== -1) {
                bankTransactionsReconciled[weekIndexReconciled].lines.push(
                    movingObject.lines[index]
                );
            }
            // Otherwise we create the object
            else {
                bankTransactionsReconciled.push({
                    week: movingObject.week,
                    lines: [movingObject.lines[index]]
                });
                // We sort to put the new object in the correct position
                bankTransactionsReconciled.sort((a, b) =>
                    moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                        ? 1
                        : -1
                );
            }
            bankTransactionsUnreconciled[weekIndex].lines =
                bankTransactionsUnreconciled[weekIndex].lines.filter(
                    (x) => x.id !== movingObject.lines[index].id
                );
            if (bankTransactionsUnreconciled[weekIndex].lines.length === 0) {
                bankTransactionsUnreconciled.splice(weekIndex, 1);
                if (movingObject.week === currentTab) {
                    setCurrentTab(bankTransactionsUnreconciled[0].week);
                }
            }
            setReconciliationDetails({
                ...reconciliationDetails,
                oraTransactionsReconciled: oraTransactionsReconciled,
                bankTransactionsReconciled: bankTransactionsReconciled,
                oraTransactionsUnreconciled: oraTransactionsUnreconciled,
                bankTransactionsUnreconciled: bankTransactionsUnreconciled
            });
        }
    }

    // If we are moving a transaction down
    else if (direction === 'down') {
        // If we are moving an ora transaction
        if (type === 'ora') {
            // Get the index corresponding to the week in the unreconciled transactions and in the reconciled transactions
            let weekIndex = oraTransactionsUnreconciled.findIndex(
                (x) => x.week === week
            );
            let weekIndexReconciled = oraTransactionsReconciled.findIndex(
                (x) => x.week === week
            );
            // Get the relevant object
            let movingObject = JSON.parse(
                JSON.stringify(oraTransactionsReconciled[weekIndexReconciled])
            );

            // If we found one in the unreconciled, then we push the new line in
            if (weekIndex !== -1) {
                oraTransactionsUnreconciled[weekIndex].lines.push(
                    movingObject.lines[index]
                );
            }
            // Otherwise we create the object
            else {
                oraTransactionsUnreconciled.push({
                    week: movingObject.week,
                    lines: [movingObject.lines[index]]
                });
                // We sort to put the new object in the correct position
                oraTransactionsUnreconciled.sort((a, b) =>
                    moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                        ? 1
                        : -1
                );
            }
            // We set the new lines for the reconciled by filtering out the moving one
            oraTransactionsReconciled[weekIndexReconciled].lines =
                oraTransactionsReconciled[weekIndexReconciled].lines.filter(
                    (x) => x.id !== movingObject.lines[index].id
                );
            // If it results with zero lines for the week, we splice off that week off the array
            if (
                oraTransactionsReconciled[weekIndexReconciled].lines.length ===
                0
            ) {
                oraTransactionsReconciled.splice(weekIndexReconciled, 1);
                if (movingObject.week === currentTab) {
                    setCurrentTab(oraTransactionsReconciled[0]?.week || '');
                }
            }

            setReconciliationDetails({
                oraTransactionsReconciled: oraTransactionsReconciled,
                bankTransactionsReconciled: bankTransactionsReconciled,
                oraTransactionsUnreconciled: oraTransactionsUnreconciled,
                bankTransactionsUnreconciled: bankTransactionsUnreconciled
            });
        }

        // If we are moving a bank transaction
        else if (type === 'bank') {
            // Get the index corresponding to the week in the unreconciled transactions and in the reconciled transactions
            let weekIndex = bankTransactionsUnreconciled.findIndex(
                (x) => x.week === week
            );
            let weekIndexReconciled = bankTransactionsReconciled.findIndex(
                (x) => x.week === week
            );
            // Get the relevant object
            let movingObject = JSON.parse(
                JSON.stringify(bankTransactionsReconciled[weekIndexReconciled])
            );

            // If we found one in the unreconciled, then we push the new line in
            if (weekIndex !== -1) {
                bankTransactionsUnreconciled[weekIndex].lines.push(
                    movingObject.lines[index]
                );
            }
            // Otherwise we create the object
            else {
                bankTransactionsUnreconciled.push({
                    week: movingObject.week,
                    lines: [movingObject.lines[index]]
                });
                // We sort to put the new object in the correct position
                bankTransactionsUnreconciled.sort((a, b) =>
                    moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                        ? 1
                        : -1
                );
            }
            // We set the new lines for the reconciled by filtering out the moving one
            bankTransactionsReconciled[weekIndexReconciled].lines =
                bankTransactionsReconciled[weekIndexReconciled].lines.filter(
                    (x) => x.id !== movingObject.lines[index].id
                );
            // If it results with zero lines for the week, we splice off that week off the array
            if (
                bankTransactionsReconciled[weekIndexReconciled].lines.length ===
                0
            ) {
                bankTransactionsReconciled.splice(weekIndexReconciled, 1);
                if (movingObject.week === currentTab) {
                    setCurrentTab(bankTransactionsReconciled[0]?.week || '');
                }
            }

            setReconciliationDetails({
                oraTransactionsReconciled: oraTransactionsReconciled,
                bankTransactionsReconciled: bankTransactionsReconciled,
                oraTransactionsUnreconciled: oraTransactionsUnreconciled,
                bankTransactionsUnreconciled: bankTransactionsUnreconciled
            });
        }
    }
};

export default HandleMoveTransactionV2;
