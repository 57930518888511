import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Creditor } from "../../../global/interfaces/GeneralInterface";
import { BodyType, DriveType, FuelType, Make, Model, Series, Vehicle } from "../../../global/interfaces/VehicleSaleInterface";

interface FilterListParams {
    nextValue: Make;
    specificLists: { Make: Make[]; Model: Model[]; Series: Series[]; FuelType: FuelType[]; Creditors: Creditor[]; DriveType: DriveType[]; BodyType: BodyType[] };
    setSpecificLists: Dispatch<SetStateAction<{ Make: Make[]; Model: Model[]; Series: Series[]; FuelType: FuelType[]; Creditors: Creditor[]; DriveType: DriveType[]; BodyType: BodyType[] }>>;
    newVehicle: Vehicle
    setNewVehicle: Dispatch<SetStateAction<Vehicle>>;
    specificInputs: { makeInput: string; modelInput: string; seriesInput: string; fuelTypeInput: string };
    setSpecificInputs: Dispatch<SetStateAction<{ makeInput: string; modelInput: string; seriesInput: string; fuelTypeInput: string }>>;
}

const FilterMakeList = (params: FilterListParams) => {
    if (params.nextValue == null) {
        params.setNewVehicle({ ...params.newVehicle, Make: null, Model: null, Series: null });
        params.setSpecificInputs({ ...params.specificInputs, makeInput: "", modelInput: "", seriesInput: "" });
        return;
    }
    api.get(`vehicleSpecificsListsNextGen?type=make&id=${params?.nextValue?.id || 0}`).then((res) => {
        params.setSpecificLists({ ...params.specificLists, Model: res.data });
    });
    params.setNewVehicle({ ...params.newVehicle, Make: params.nextValue, Model: null, Series: null });
    params.setSpecificInputs({ ...params.specificInputs, modelInput: "", seriesInput: "" });

};

export default FilterMakeList;
