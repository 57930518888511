import api from '../../../../../api';
import { Buffer } from 'buffer';

const PrintTestPostageLabel = () => {
    api.post(`/printTestPostageLabel`, {
        headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob'
    }).then((res) => {
        const file = new Blob([Buffer.from(res.data, 'base64')], {
            type: 'application/pdf'
        });
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        let iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = fileURL;
        iframe.onload = function () {
            setTimeout(function () {
                iframe.focus();
                iframe.contentWindow.print();
            }, 1);
        };
    });
};

export default PrintTestPostageLabel;
