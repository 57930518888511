// IMPROTS
import { useState } from 'react';
import { TableRow, IconButton, Chip, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import InvoiceHeader from './InvoiceHeader';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../../global/tableComponents/DataTable';
import Fab from '../../../../global/Fab';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
// LOGIC
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
// INTERFACES
import { Creditor, Site } from '../../../../global/interfaces/GeneralInterface';
import { Account } from '../../../../global/interfaces/AdminInterface';
import { Service } from '../../../../global/interfaces/ServiceInterface';
import SubmitServiceWarranty from '../../logic/SubmitServiceWarranty';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Account: Account;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
    documentReference: string;
}
interface InvoiceLine {
    id: number;
    Service?: Service;
    amount: string;
    description: string;
}

const ServiceWarranty = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false);
    const [responseCode, setResponseCode] = useState<number>(null);
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([]);
    const [selectedSite, setSelectedSite] = useState<number>(
        parseInt(localStorage.getItem('SiteId'))
    );
    const [invoiceObject, setInvoiceObject] = useState<InvoiceObject>({
        Creditor: null,
        Site: null,
        Account: null,
        documentDate: null,
        dueDate: null,
        GSTtype: 'G1',
        documentTotal: null,
        documentReference: ''
    });

    const columns = [
        { id: 0, label: 'ID', width: 100 },
        { id: 1, label: 'Status', width: 100 },
        { id: 2, label: 'Vehicle', width: 400 },
        { id: 3, label: 'Service Total', width: 100 },
        { id: 4, label: 'Amount Paying', width: 100 },
        { id: 5, label: '', width: 100 }
    ];

    // Handle removing a line from the invoice
    const removeLine = (lineId: number) => {
        let currentLines = [...invoiceLines];
        currentLines = currentLines.filter((x) => x.id !== lineId);
        setInvoiceLines(currentLines);
    };

    // Get the total of all the entered lines
    const totalLines = () => {
        let total = 0;
        invoiceLines.forEach((line) => {
            total += parseFloat(line.amount);
        });
        return total;
    };

    const totalServiceLines = () => {
        let total = 0;
        invoiceLines.forEach((line) => {
            total += parseFloat(line?.Service?.totalPrice);
        });
        return total;
    };

    const editLine = (index: number, selector: string, value: string) => {
        let currentLines = [...invoiceLines];
        currentLines[index][selector] = value;
        setInvoiceLines(currentLines);
    };

    const handleSubmitServiceWarranty = () => {
        if (!isSubmittingInvoice) {
            SubmitServiceWarranty(
                invoiceObject,
                invoiceLines,
                totalLines(),
                responseCode,
                selectedSite,
                showSnackbar,
                setIsSubmittingInvoice
            );
        }
    };

    return (
        <>
            <InvoiceHeader
                invoiceObject={invoiceObject}
                setInvoiceObject={setInvoiceObject}
                invoiceLines={invoiceLines}
                setInvoiceLines={setInvoiceLines}
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                responseCode={responseCode}
                setResponseCode={setResponseCode}
            />
            <br />
            {invoiceLines.length > 0 && (
                <Paper>
                    <DataTable columns={columns}>
                        {invoiceLines.map((line, index) => (
                            <TableRow>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            `/service/viewBooking/${line.Service.id}`
                                        )
                                    }
                                >
                                    {line.Service?.id}
                                    <br />
                                </DataCellColoured>
                                <DataCell>
                                    <Chip
                                        size="small"
                                        variant="outlined"
                                        label={line.Service?.serviceComplete}
                                        color={
                                            line.Service?.serviceComplete ===
                                            'Incomplete'
                                                ? 'error'
                                                : line.Service
                                                        ?.serviceComplete ===
                                                    'Complete'
                                                  ? 'warning'
                                                  : 'success'
                                        }
                                    ></Chip>
                                </DataCell>
                                <DataCell>
                                    {line.Service?.vehicleName}
                                    <br />
                                    <span
                                        style={{
                                            color: '#555',
                                            fontSize: '12px'
                                        }}
                                    >
                                        VIN: {line.Service?.vehicleVin}
                                    </span>
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        line.Service?.totalPrice
                                    )}
                                </DataCell>
                                <DataCell>
                                    <TextField
                                        variant="standard"
                                        size="small"
                                        value={line.amount}
                                        onChange={(e) =>
                                            editLine(
                                                index,
                                                'amount',
                                                e.target.value
                                            )
                                        }
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                </DataCell>
                                <DataCell>
                                    <IconButton
                                        onClick={() => removeLine(line.id)}
                                    >
                                        <Close />
                                    </IconButton>
                                </DataCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <DataCell colSpan={2}></DataCell>
                            <DataCell>
                                <b>Total</b>
                            </DataCell>
                            <DataCell>
                                <b>{CurrencyFormatter(totalServiceLines())}</b>
                            </DataCell>
                            <DataCell>
                                <b>{CurrencyFormatter(totalLines())}</b>
                            </DataCell>
                            <DataCell></DataCell>
                        </TableRow>
                    </DataTable>
                </Paper>
            )}

            <Fab editIcon={false} onClick={handleSubmitServiceWarranty} />
        </>
    );
};

export default withSnackbar(ServiceWarranty);
