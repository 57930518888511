import api from '../../../../../../api';

const GetSpecificTillTransactions = (
  TillId: number,
  date: string,
  transactionType: string,
  setTransactions
) => {
  api
    .get(
      `specificTillTransactions/${TillId}?date=${date}&transactionType=${transactionType}`
    )
    .then((res) => {
      setTransactions(res.data);
    });
};

export default GetSpecificTillTransactions;
