// IMPORTS
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
// COMPONENTS
import Paper from '../../../global/Paper';
import Autocomplete from '../../../global/Autocomplete';
//INTERFACES
import { Specific } from '../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import GetAllMakes from '../../../global/databaseLogic/GetAllMakes';
import GetAllModels from '../../../global/databaseLogic/GetAllModels';
import GetVehicleResults from '../logic/GetVehicleResults';
import { AreEmpty } from '../../../global/logic/globalValidation/ValidateFields';
import { YearFormatter } from '../../../global/logic/Formatters';

interface FilterProps {
    vehicleResults: Vehicle[];
    setVehicleResults: Dispatch<SetStateAction<Vehicle[]>>;
    showSnackbar: showSnackbar;
    editAll: boolean;
    setEditAll: Dispatch<SetStateAction<boolean>>;
}

/**
 * UpdateVehicleRRPsFilter
 * The filter UI for Update Vehicle RRPs
 * @author Estienne
 * @param FilterProps
 */
const UpdateVehicleRRPsFilter = ({
    setVehicleResults,
    showSnackbar,
    editAll,
    setEditAll
}: FilterProps) => {
    const [makes, setMakes] = useState<Specific[]>([]);
    const [selectedMake, setSelectedMake] = useState<Specific>();
    const [allModels, setAllModels] = useState<Specific[]>([]);
    const [models, setModels] = useState<Specific[]>([]);
    const [selectedModel, setSelectedModel] = useState<Specific>();
    const [yearStart, setYearStart] = useState<Date>(null);
    const [yearEnd, setYearEnd] = useState<Date>(null);

    /**
     * useEffect
     * Get all vehicle makes and models
     */
    useEffect(() => {
        GetAllMakes(setMakes);
        GetAllModels(setAllModels);
        GetAllModels(setModels);
    }, []);

    /**
     * handleSearch
     * Get the search results
     * @author Estienne
     * @param make the specific bike make
     * @param model the specified bike model
     * @param yearStart the lower-limit year
     * @param yearEnd the upper-limit year
     */
    const handleSearch = (
        make: Specific,
        model: Specific,
        yearStart: Date,
        yearEnd: Date
    ) => {
        let yearStartFormat = YearFormatter(yearStart);
        let yearEndFormat = YearFormatter(yearEnd);

        GetVehicleResults(
            make.id,
            model.id,
            yearStartFormat,
            yearEndFormat,
            setVehicleResults,
            showSnackbar
        );
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        {/* Make */}
                        <Autocomplete
                            size="small"
                            options={makes}
                            textfieldLabel="Make"
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            selectedValue={selectedMake}
                            handleSelectedValueChange={(newValue) => {
                                setSelectedMake(newValue);
                                if (newValue !== null) {
                                    let newModelOptions = [];
                                    let filteredModels = allModels.filter(
                                        (x) => x.MakeId === newValue.id
                                    );
                                    filteredModels.forEach((model) => {
                                        newModelOptions.push(model);
                                    });
                                    setModels(newModelOptions);
                                } else {
                                    setModels(allModels);
                                }
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {/* Model */}
                        <Autocomplete
                            size="small"
                            options={models}
                            textfieldLabel="Model"
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            selectedValue={selectedModel}
                            handleSelectedValueChange={(newValue) =>
                                setSelectedModel(newValue)
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="YYYY"
                                views={['year']}
                                label="Year Start Range"
                                value={yearStart}
                                onChange={(newValue) => setYearStart(newValue)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1.5}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="YYYY"
                                views={['year']}
                                label="Year End Range"
                                value={yearEnd}
                                onChange={(newValue) => setYearEnd(newValue)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        {/* Edit all visible */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={editAll ? true : false}
                                    onChange={(e) => {
                                        if (editAll === false) {
                                            setEditAll(true);
                                        } else {
                                            setEditAll(false);
                                        }
                                    }}
                                />
                            }
                            label="Edit all visible"
                        />
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                        {/* Find vehicles */}
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                if (
                                    AreEmpty([
                                        selectedMake,
                                        selectedModel,
                                        yearStart,
                                        yearEnd
                                    ])
                                ) {
                                    showSnackbar(
                                        'All filter fields need to be filled before finding vehicles.',
                                        '',
                                        'error'
                                    );
                                } else {
                                    handleSearch(
                                        selectedMake,
                                        selectedModel,
                                        yearStart,
                                        yearEnd
                                    );
                                }
                            }}
                        >
                            Find Vehicles
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default UpdateVehicleRRPsFilter;
