// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { ServiceCode } from '../../../global/interfaces/ServiceInterface';

const GetServiceCode = (
    ServiceCodeId: number,
    setServiceCode: Dispatch<SetStateAction<ServiceCode>>
) => {
    api.get(`serviceCodeNextGen/${ServiceCodeId}`).then((res) => {
        setServiceCode(res.data);
    });
};

export default GetServiceCode;
