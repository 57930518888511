import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../../../api';
import { showSnackbar } from '../../../../../global/interfaces/GlobalInterface';
import { Dayjs } from 'dayjs';

const GetCommitPNLData = (
    month: Dayjs,
    setCommitPNLUI: Dispatch<SetStateAction<boolean>>,
    setCommitPNLData,
    setCommitPNLDialog: Dispatch<SetStateAction<boolean>>,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    api.get('commitPNL?month=' + month.toISOString()).then((res) => {
        if (res.status === 200) {
            setCommitPNLData(res.data);
            setCommitPNLUI(true);
            setCommitPNLDialog(false);
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default GetCommitPNLData;
