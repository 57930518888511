// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Checkbox,
    IconButton,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import { Close, Replay } from '@mui/icons-material';
// COMPONENTS
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import Drawer from '../../../global/Drawer';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
// INTERFACES
import { StockInvoice } from '../../../global/interfaces/PartsInterface';

interface InvoiceHeaderProps {
    invoiceData: StockInvoice;
    setInvoiceData: Dispatch<SetStateAction<StockInvoice>>;
    reverseOrder: string[];
    setReverseOrder: Dispatch<SetStateAction<string[]>>;
}

const InvoiceLines = ({
    invoiceData,
    setInvoiceData,
    reverseOrder,
    setReverseOrder
}: InvoiceHeaderProps) => {
    const [qtyInvoicedPresent, setQtyInvoicedPresent] =
        useState<boolean>(false);
    const [backorderDrawerOpen, setBackorderDrawerOpen] =
        useState<boolean>(false);
    const [selectedPart, setSelectedPart] = useState(null);

    const [columns, setColumns] = useState([
        { id: 0, label: 'Backorders', width: 50 },
        { id: 1, label: 'Part Number', width: 100 },
        { id: 2, label: 'Name', width: 150 },
        { id: 3, label: 'Ordered', width: 100 },
        { id: 4, label: 'Received', width: 100 },
        { id: 5, label: 'In Pack', width: 50 },
        { id: 6, label: 'Cost on File', width: 100 },
        { id: 7, label: 'Document Cost', width: 100 },
        { id: 8, label: 'Line Total', width: 100 },
        { id: 9, label: 'Print Label', width: 100 },
        { id: 10, label: '', width: 50, name: '', sort: true }
    ]);

    useEffect(() => {
        for (let line of invoiceData.orderLines) {
            if (line.quantityInvoiced > 0) {
                setQtyInvoicedPresent(true);
                break;
            }
        }
        // eslint-disable-next-line
    }, [invoiceData]);

    useEffect(() => {
        if (qtyInvoicedPresent) {
            setColumns([
                { id: 0, label: 'Backorders', width: 50 },
                { id: 1, label: 'Part Number', width: 100 },
                { id: 2, label: 'Name', width: 150 },
                { id: 3, label: 'Ordered', width: 100 },
                { id: 4, label: 'Already Invoiced', width: 100 },
                { id: 5, label: 'Received', width: 100 },
                { id: 6, label: 'In Pack', width: 50 },
                { id: 7, label: 'Cost on File', width: 100 },
                { id: 8, label: 'Document Cost', width: 100 },
                { id: 9, label: 'Line Total', width: 100 },
                { id: 10, label: 'Print Label', width: 100 },
                { id: 10, label: '', width: 50, name: '', sort: true }
            ]);
        } else {
            setColumns([
                { id: 0, label: 'Backorders', width: 50 },
                { id: 1, label: 'Part Number', width: 100 },
                { id: 2, label: 'Name', width: 150 },
                { id: 3, label: 'Ordered', width: 100 },
                { id: 4, label: 'Received', width: 100 },
                { id: 5, label: 'In Pack', width: 50 },
                { id: 6, label: 'Cost on File', width: 100 },
                { id: 7, label: 'Document Cost', width: 100 },
                { id: 8, label: 'Line Total', width: 100 },
                { id: 9, label: 'Print Label', width: 100 },
                { id: 10, label: '', width: 50, name: '', sort: true }
            ]);
        }
    }, [qtyInvoicedPresent]);

    useEffect(() => {
        setInvoiceData({
            ...invoiceData,
            orderLines: JSON.parse(
                JSON.stringify(invoiceData.orderLines)
            ).toReversed()
        });
        // eslint-disable-next-line
    }, [reverseOrder]);

    const backorderColumns = [
        { id: 0, label: 'Order ID' },
        { id: 1, label: 'Quantity Ordered' },
        { id: 2, label: 'Customer' },
        { id: 3, label: 'Date Placed' }
    ];

    const handleOpenDrawer = (line) => {
        setSelectedPart(line);
        setBackorderDrawerOpen(true);
    };

    const handleLineChange = (newValue, id, selector) => {
        let currentOrderLines = [...invoiceData?.orderLines];
        let row =
            currentOrderLines[currentOrderLines.findIndex((x) => x.id === id)];

        row[selector] = newValue;

        if (selector === 'quantityReceived' && row.itemPrice) {
            let newTotal = (
                parseFloat(newValue) * parseFloat(row.itemPrice)
            ).toFixed(2);
            row.lineTotal = String(newTotal);
        } else if (selector === 'itemPrice' && row.quantityReceived) {
            let newTotal = (
                parseFloat(newValue) * parseFloat(row.quantityReceived)
            ).toFixed(2);
            row.lineTotal = String(newTotal);
        } else if (selector === 'lineTotal' && row.quantityReceived) {
            let newTotal = (
                parseFloat(newValue) / parseFloat(row.quantityReceived)
            ).toFixed(2);
            row.itemPrice = String(newTotal);
        }

        currentOrderLines[currentOrderLines.findIndex((x) => x.id === id)] =
            row;
        setInvoiceData({ ...invoiceData, orderLines: currentOrderLines });
        localStorage.setItem(
            'stockInvoiceData',
            JSON.stringify({ ...invoiceData, orderLines: currentOrderLines })
        );
    };

    return (
        <>
            <DataTable
                columns={columns}
                sort={reverseOrder}
                setSort={setReverseOrder}
            >
                {invoiceData.orderLines.map((line) => (
                    <>
                        <TableRow
                            sx={{
                                fontStyle: line.invoiced ? 'italic' : 'normal'
                            }}
                        >
                            {line.removed ? (
                                <DataCell textDecoration="line-through">
                                    {line.customerBackorders.length}
                                </DataCell>
                            ) : (
                                <DataCellColoured
                                    handleClick={() => handleOpenDrawer(line)}
                                >
                                    {line.customerBackorders.length}
                                </DataCellColoured>
                            )}
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        '/inventory/view/' + line.UnitId,
                                        '_blank'
                                    )
                                }
                                textDecoration={
                                    line.removed ? 'line-through' : ''
                                }
                            >
                                {line.partNumber}
                            </DataCellColoured>
                            {line.name.length > 15 ? (
                                <Tooltip title={line.name} placement="top">
                                    <DataCell
                                        textDecoration={
                                            line.removed ? 'line-through' : ''
                                        }
                                    >
                                        {line.name.slice(0, 15)}...
                                    </DataCell>
                                </Tooltip>
                            ) : (
                                <DataCell
                                    textDecoration={
                                        line.removed ? 'line-through' : ''
                                    }
                                >
                                    {line.name}
                                </DataCell>
                            )}
                            <DataCell
                                textDecoration={
                                    line.removed ? 'line-through' : ''
                                }
                            >
                                {line.quantityOrdered}
                            </DataCell>
                            {qtyInvoicedPresent ? (
                                <DataCell
                                    textDecoration={
                                        line.removed ? 'line-through' : ''
                                    }
                                >
                                    {line.quantityInvoiced}
                                </DataCell>
                            ) : null}
                            <DataCell
                                textDecoration={
                                    line.removed ? 'line-through' : ''
                                }
                            >
                                {line.invoiced ? (
                                    '-'
                                ) : line.removed ? (
                                    line.quantityReceived ?? 0
                                ) : (
                                    <TextField
                                        variant="standard"
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        value={line.quantityReceived ?? ''}
                                        onChange={(e) =>
                                            handleLineChange(
                                                e.target.value,
                                                line.id,
                                                'quantityReceived'
                                            )
                                        }
                                        error={line.quantityError}
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                <Checkbox
                                    disabled={line.removed || line.invoiced}
                                    checked={line.isPack}
                                    onChange={(e) =>
                                        handleLineChange(
                                            e.target.checked,
                                            line.id,
                                            'isPack'
                                        )
                                    }
                                />
                            </DataCell>
                            <DataCell
                                textDecoration={
                                    line.removed ? 'line-through' : ''
                                }
                            >
                                {CurrencyFormatter(line.costOnFile)}
                            </DataCell>
                            <DataCell
                                textDecoration={
                                    line.removed ? 'line-through' : ''
                                }
                            >
                                {line.invoiced ? (
                                    '-'
                                ) : line.removed ? (
                                    CurrencyFormatter(line.lineTotal)
                                ) : (
                                    <TextField
                                        variant="standard"
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        value={line.itemPrice ?? ''}
                                        onChange={(e) =>
                                            handleLineChange(
                                                e.target.value,
                                                line.id,
                                                'itemPrice'
                                            )
                                        }
                                        error={line.priceError}
                                    />
                                )}
                            </DataCell>
                            <DataCell
                                textDecoration={
                                    line.removed ? 'line-through' : ''
                                }
                            >
                                {line.invoiced ? (
                                    '-'
                                ) : line.removed ? (
                                    CurrencyFormatter(line.lineTotal)
                                ) : (
                                    <TextField
                                        variant="standard"
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        value={line.lineTotal ?? ''}
                                        onChange={(e) =>
                                            handleLineChange(
                                                e.target.value,
                                                line.id,
                                                'lineTotal'
                                            )
                                        }
                                        error={line.priceError}
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                <Checkbox
                                    disabled={line.removed || line.invoiced}
                                    checked={line.printLabel}
                                    onChange={(e) =>
                                        handleLineChange(
                                            e.target.checked,
                                            line.id,
                                            'printLabel'
                                        )
                                    }
                                />
                            </DataCell>
                            <DataCell>
                                {line.invoiced ? null : line.removed ? (
                                    <IconButton
                                        onClick={() =>
                                            handleLineChange(
                                                false,
                                                line.id,
                                                'removed'
                                            )
                                        }
                                    >
                                        <Replay />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() =>
                                            handleLineChange(
                                                true,
                                                line.id,
                                                'removed'
                                            )
                                        }
                                    >
                                        <Close />
                                    </IconButton>
                                )}
                            </DataCell>
                        </TableRow>
                        {line.isPack && !line.removed ? (
                            <TableRow>
                                <DataCell colSpan={4}></DataCell>
                                <DataCell align="right">Qty in Pack: </DataCell>
                                <DataCell>
                                    <TextField
                                        variant="standard"
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        value={line.packQty}
                                        onChange={(e) =>
                                            handleLineChange(
                                                e.target.value,
                                                line.id,
                                                'packQty'
                                            )
                                        }
                                    />
                                </DataCell>
                                <DataCell colSpan={5}></DataCell>
                            </TableRow>
                        ) : null}
                    </>
                ))}
            </DataTable>

            <Drawer
                openDrawer={backorderDrawerOpen}
                setOpenDrawer={setBackorderDrawerOpen}
                title={`Backorder for ${selectedPart?.partNumber}`}
                subTitle={`Quantity Received: ${selectedPart?.quantityReceived ?? 0}`}
            >
                <DataTable columns={backorderColumns}>
                    {selectedPart?.customerBackorders.map((order) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/inventory/viewSale/${order.OrderId}`,
                                        '_blank'
                                    )
                                }
                            >
                                {order.OrderId}
                            </DataCellColoured>
                            <DataCell>{order.backorderQuantity}</DataCell>
                            <DataCell>
                                {order?.Customer?.companyName
                                    ? order?.Customer?.companyName.toUpperCase()
                                    : order?.Customer?.firstName.toUpperCase() +
                                      ' ' +
                                      order?.Customer?.lastName.toUpperCase()}
                            </DataCell>
                            <DataCell>
                                {DateFormatter(order?.createdAt)}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Drawer>
        </>
    );
};

export default InvoiceLines;
