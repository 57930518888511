import { Dispatch, SetStateAction } from 'react';
import { DialogActions, DialogContent, TextField } from '@mui/material';

// INTERFACE
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Make } from '../../../../global/interfaces/VehicleSaleInterface';
import UpdateMakeName from '../../logic/UpdateMakeName';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const EditMakeDialogContent = ({
    showSnackbar,
    selectedMake,
    setSelectedMake
}: {
    showSnackbar: showSnackbar;
    selectedMake: Make;
    setSelectedMake: Dispatch<SetStateAction<Make>>;
}) => {
    return (
        <>
            <DialogContent>
                <TextField
                    fullWidth
                    size="small"
                    value={selectedMake?.name}
                    onChange={(e) =>
                        setSelectedMake({
                            ...selectedMake,
                            name: e.target.value
                        })
                    }
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    onClick={() => UpdateMakeName(selectedMake, showSnackbar)}
                >
                    Update Make Name
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default EditMakeDialogContent;
