// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
// COMPONENTS
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import HandleResetPassword from './logic/HandleResetPassword';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

interface ResetPasswordProps {
    setPasswordChangeDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const ResetPassword = ({
    setPasswordChangeDialogOpen,
    showSnackbar
}: ResetPasswordProps) => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [passwordData, setPasswordData] = useState<{
        old: string;
        new: string;
        confirm: string;
    }>({
        old: '',
        new: '',
        confirm: ''
    });
    const [errors, setErrors] = useState<{
        old: boolean;
        new: boolean;
        confirm: boolean;
        match: boolean;
    }>({
        old: false,
        new: false,
        confirm: false,
        match: false
    });

    return (
        <>
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}></Grid>

                    <Grid item xs={2}></Grid>
                    <Grid item xs={8} textAlign={'center'}>
                        <TextField
                            fullWidth
                            type="password"
                            label="Current Password"
                            InputLabelProps={{ shrink: true }}
                            error={errors.old ? true : false}
                            helperText={
                                errors.old
                                    ? 'Please enter your current password into this field.'
                                    : null
                            }
                            value={passwordData.old}
                            onChange={(e) =>
                                setPasswordData({
                                    ...passwordData,
                                    old: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}></Grid>

                    <Grid item xs={2}></Grid>
                    <Grid item xs={8} textAlign={'center'}>
                        <TextField
                            fullWidth
                            type="password"
                            label="New Password"
                            InputLabelProps={{ shrink: true }}
                            error={
                                errors.new ? true : errors.match ? true : false
                            }
                            helperText={
                                errors.new
                                    ? 'Please enter your new password into this field.'
                                    : null
                            }
                            value={passwordData.new}
                            onChange={(e) =>
                                setPasswordData({
                                    ...passwordData,
                                    new: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}></Grid>

                    <Grid item xs={2}></Grid>
                    <Grid item xs={8} textAlign={'center'}>
                        <TextField
                            fullWidth
                            type="password"
                            label="Confirm New Password"
                            InputLabelProps={{ shrink: true }}
                            error={
                                errors.confirm
                                    ? true
                                    : errors.match
                                      ? true
                                      : false
                            }
                            helperText={
                                errors.confirm
                                    ? 'Please enter your new password into this field to confirm.'
                                    : errors.match
                                      ? 'New passwords do not match.'
                                      : null
                            }
                            value={passwordData.confirm}
                            onChange={(e) =>
                                setPasswordData({
                                    ...passwordData,
                                    confirm: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => setPasswordChangeDialogOpen(false)}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        HandleResetPassword(
                            passwordData,
                            setErrors,
                            setButtonLoading,
                            showSnackbar
                        )
                    }
                >
                    Change Password
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default withSnackbar(ResetPassword);
