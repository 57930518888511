// INTERFACES
import { Settings } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    Ctp,
    Registration
} from '../../../global/interfaces/VehicleSaleInterface';
// LOGIC
import api from '../../../../../api';
import {
    ValidateAccountNumber,
    ValidateACN,
    ValidateBSB,
    ValidateLMD,
    ValidatePaymentMethods,
    ValidateWarranties
} from '../../../global/logic/settingsValidation/ValidateCompanyFields';
import { ValidateABN } from '../../../global/logic/customerValidation/ValidateCustomerFields';
import { AreEmpty } from '../../../global/logic/globalValidation/ValidateFields';
import dayjs from 'dayjs';

interface SettingsProps {
    settings: Settings;
    state: string;
    registrations: Registration[];
    futureRegistrations: Registration[];
    futureRegoChange: boolean;
    ctp: Ctp[];
    futureCtp: Ctp[];
    futureCtpChange: boolean;
    showSnackbar: showSnackbar;
}

/**
 * Verification
 * Validate all provided data before updating the database
 * @author Estienne
 * @param settings All company settings data
 * @returns an object containing a validity status and an error message/subMessage
 */
function Verification(settings: Settings) {
    let validABN = ValidateABN(settings?.abn);
    let validACN = ValidateACN(settings?.acn);
    let validBSB = ValidateBSB(settings?.directDepositAccountBsb);
    let validAccountNumber = ValidateAccountNumber(
        settings?.directDepositAccountNumber
    );
    let validPaymentMethods = ValidatePaymentMethods(settings?.paymentMethods);
    let validWarranties = ValidateWarranties(settings?.warrantyData);

    // Are all required Business Details fields filled?
    if (
        AreEmpty([
            settings.companyName,
            settings.legalEntityName,
            settings.abn,
            settings.directDepositAccountName,
            settings.bankName,
            settings.directDepositAccountBsb,
            settings.directDepositAccountNumber
        ])
    ) {
        return {
            verified: false,
            message: 'Make sure all required fields are filled.'
        };
    }

    //  Do all fields contain valid data?
    else if (validABN.verified === false) {
        return {
            verified: false,
            message: validABN.message,
            subMessage: validABN.subMessage
        };
    } else if (validACN.verified === false) {
        return {
            verified: false,
            message: validACN.message,
            subMessage: validACN.subMessage
        };
    } else if (validAccountNumber.verified === false) {
        return {
            verified: false,
            message: validAccountNumber.message,
            subMessage: validAccountNumber.subMessage
        };
    } else if (validBSB.verified === false) {
        return {
            verified: false,
            message: validBSB.message,
            subMessage: validBSB.subMessage
        };
    } else if (settings?.lmd && ValidateLMD(settings?.lmd).verified === false) {
        return {
            verified: false,
            message: 'Invalid LMD.',
            subMessage: 'LMD must be a valid number.'
        };
    } else if (validPaymentMethods.verified === false) {
        return {
            verified: false,
            message: validPaymentMethods.message,
            subMessage: validPaymentMethods.subMessage
        };
    } else if (validWarranties.verified === false) {
        return {
            verified: false,
            message: validWarranties.message,
            subMessage: validWarranties.subMessage
        };
    } else {
        return { verified: true, message: '', subMessage: '' };
    }
}

/**
 * HandleUpdateCompany
 * Send the information to the server
 * @author Estienne
 * @param SettingsProps
 */
const HandleUpdateCompany = ({
    settings,
    state,
    registrations,
    futureRegistrations,
    futureRegoChange,
    ctp,
    futureCtp,
    futureCtpChange,
    showSnackbar
}: SettingsProps) => {
    let verification = Verification(settings);

    if (verification.verified === true) {
        if (futureRegistrations.length > 0) {
            // Check that the effective dates entered by the user are later than the current day
            if (
                dayjs(futureRegistrations[0].effectiveDate).isBefore(
                    dayjs().hour(23).minute(59)
                )
            ) {
                showSnackbar(
                    'Invalid Future Registration Effective Date',
                    'Effective Date must be later than today, it will implement the new prices on the effective date at 0:01am',
                    'error'
                );
                return;
            }
        }
        if (futureCtp.length > 0) {
            if (
                dayjs(futureCtp[0].effectiveDate).isBefore(
                    dayjs().hour(23).minute(59)
                )
            ) {
                showSnackbar(
                    'Invalid Future CTP Effective Date',
                    'Effective Date must be later than today, it will implement the new prices on the effective date at 0:01am',
                    'error'
                );
                return;
            }
        }

        api.put(`settingsNextGen/${settings.id}`, {
            settings: settings,
            state: state,
            registrations: registrations,
            futureRegistrations: futureRegistrations,
            futureRegoChange: futureRegoChange,
            ctp: ctp,
            futureCtp: futureCtp,
            futureCtpChange: futureCtpChange
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar('Company successfully updated.');
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    } else {
        showSnackbar(verification.message, verification.subMessage, 'error');
    }
};

export default HandleUpdateCompany;
