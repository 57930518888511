import {
    AccessTime,
    Build,
    ExitToApp,
    Notes,
    Person,
    Print
} from '@mui/icons-material';
import {
    CardContent,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import dayjs from 'dayjs';
import PDFJobCard from '../../newBooking/logic/PDFJobCard';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { Dispatch, SetStateAction } from 'react';

const ServiceCardContent = ({
    selectedJob,
    setDescriptionDialog
}: {
    selectedJob: Service;
    setDescriptionDialog: Dispatch<SetStateAction<boolean>>;
}) => {
    const handlePrintJobCard = (job) => {
        let customer = job.Customer;
        let vehicle = null;
        let vehicleType = '';
        if (job.CustomerVehicleId) {
            vehicle = job.CustomerVehicle;
            vehicleType = 'customer';
        } else {
            vehicle = job.Vehicle;
        }
        PDFJobCard(job, customer, vehicleType, vehicle);
    };
    return (
        <CardContent>
            <Grid container spacing={1} textAlign="left" alignItems="center">
                <Grid item xs={7.5}>
                    <Typography sx={{ fontSize: 18 }}>
                        Service #{selectedJob.id} - {selectedJob.jobType}
                    </Typography>
                </Grid>
                <Grid item xs={1.5}>
                    <Tooltip title="Go to Booking Page" placement="top">
                        <IconButton
                            onClick={(e) => {
                                window.location.href =
                                    '/service/viewBooking/' +
                                    selectedJob.id.toString();
                            }}
                        >
                            <ExitToApp />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={1.5}>
                    <Tooltip title="Print Job Card" placement="top">
                        <IconButton
                            onClick={(e) => {
                                handlePrintJobCard(selectedJob);
                            }}
                        >
                            <Print />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={1.5}>
                    <Tooltip title="Open Job Description" placement="top">
                        <IconButton
                            onClick={(e) => {
                                setDescriptionDialog(true);
                            }}
                        >
                            <Notes />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={1}>
                    <AccessTime />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Typography sx={{ fontSize: 14 }}>
                        {dayjs(selectedJob.jobStart).format(
                            'dddd, MMMM D, YYYY'
                        )}{' '}
                        <br />
                        {dayjs(selectedJob.jobStart).format('h:mm A')} -{' '}
                        {dayjs(selectedJob.jobFinish).format('h:mm A')}
                    </Typography>
                </Grid>
                {selectedJob?.Tech?.id ? (
                    <>
                        <Grid item xs={1}>
                            <Build />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <Typography
                                sx={{
                                    fontSize: 14
                                }}
                            >
                                <strong>
                                    {selectedJob.Tech?.firstName}{' '}
                                    {selectedJob.Tech?.lastName}
                                </strong>
                            </Typography>
                        </Grid>
                    </>
                ) : null}
                {selectedJob.jobType === 'Retail' ||
                selectedJob.jobType === 'Insurance' ||
                selectedJob.jobType === 'Warranty' ||
                selectedJob.jobType === 'Rework' ? (
                    <>
                        <Grid item xs={1}>
                            <Person />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            {selectedJob.Customer?.phoneNumber &&
                            selectedJob.Customer?.email ? (
                                <Typography
                                    sx={{
                                        fontSize: 14
                                    }}
                                >
                                    <strong>
                                        {selectedJob.Customer?.firstName}{' '}
                                        {selectedJob.Customer?.lastName}
                                    </strong>
                                    <br />
                                    <strong>Ph:</strong>{' '}
                                    {selectedJob.Customer.phoneNumber}
                                    <br />
                                    <strong>@:</strong>{' '}
                                    {selectedJob.Customer.email}
                                </Typography>
                            ) : selectedJob.Customer?.phoneNumber ? (
                                <Typography
                                    sx={{
                                        fontSize: 14
                                    }}
                                >
                                    <strong>
                                        {selectedJob.Customer?.firstName}{' '}
                                        {selectedJob.Customer?.lastName}
                                    </strong>
                                    <br />
                                    Ph: {selectedJob.Customer.phoneNumber}
                                </Typography>
                            ) : selectedJob.Customer?.email ? (
                                <Typography
                                    sx={{
                                        fontSize: 14
                                    }}
                                >
                                    <strong>
                                        {selectedJob.Customer?.firstName}{' '}
                                        {selectedJob.Customer?.lastName}
                                    </strong>
                                    <br />
                                    @: {selectedJob.Customer.email}
                                </Typography>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </CardContent>
    );
};
export default ServiceCardContent;
