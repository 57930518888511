// IMPORTS
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import RoleCheck from '../global/RoleCheck';

// VEHICLE
import VehicleTable from '../vehicle/vehicleTable/VehicleTable';
import Vehicle from '../vehicle/vehicle/Vehicle';
import ManufacturerVehicleTable from '../vehicle/manufacturerVehicleTable/ManufacturerVehicleTable';
import VehicleTransferTable from '../vehicle/vehicleTransferTable/VehicleTransferTable';
import InvoiceVehicle from '../vehicle/invoiceVehicle/InvoiceVehicle';
import UpdateVehicleRRPs from '../vehicle/updateVehicleRRP/UpdateVehicleRRPs';
import ViewVehicleInvoice from '../vehicle/invoiceVehicle/ViewVehicleInvoice';

// SALE
import NewVehicleSale from '../vehicle/newVehicleSale/NewVehicleSale';
import VehicleSaleTable from '../vehicle/vehicleSalesTable/VehicleSaleTable';

// TRADE
import TradeVehicle from '../vehicle/tradeVehicle/TradeVehicle';

// LEADS MANAGEMENT
import LeadsManagement from '../vehicle/leadsManagement/LeadsManagement';
import ManagerDashboard from '../vehicle/leadsManagement/components/ManagerDashboard';

// VEHICLE DASHBOARD
import VehicleSaleDashboard from '../vehicle/dashboard/VehicleSaleDashboard';

const VehicleRouter = () => {
  let { path } = useRouteMatch();

  return (
      <Switch>
          <Route path={`${path}/dashboard`}>
              <VehicleSaleDashboard />
          </Route>
          <Route path={`${path}/stock`}>
              <VehicleTable />
          </Route>
          <Route path={`${path}/view/:id`}>
              <Vehicle />
          </Route>
          <Route path={`${path}/manufacturerVehicles`}>
              <ManufacturerVehicleTable />
          </Route>
          <Route path={`${path}/vehicletransfers`}>
              <VehicleTransferTable />
          </Route>
          <Route path={`${path}/invoiceVehicles`}>
              <InvoiceVehicle />
          </Route>
          <Route path={`${path}/viewVehicleInvoice/:id`}>
              <ViewVehicleInvoice />
          </Route>
          <Route path={`${path}/updatePrices`}>
              <UpdateVehicleRRPs />
          </Route>

          {/* SALE */}
          <Route path={`${path}/viewContract/:id`}>
              <NewVehicleSale />
          </Route>
          <Route path={`${path}/newContract`} key={new Date().getTime()}>
              <NewVehicleSale />
          </Route>
          <Route path={`${path}/sales`}>
              <VehicleSaleTable />
          </Route>

          {/* TRADE */}
          <Route path={`${path}/viewTrade/:id`}>
              <TradeVehicle />
          </Route>
          <Route path={`${path}/newTrade`}>
              <TradeVehicle />
          </Route>

          {/* NOT IN TS */}
          <Route path={`${path}/leadsmanagement/dashboard`}>
              <RoleCheck
                  component={<ManagerDashboard />}
                  permission={'access_leadManager'}
                  pageError
              />
          </Route>
          <Route path={`${path}/leadsmanagement/`}>
              <LeadsManagement />
          </Route>
      </Switch>
  );
};

export default VehicleRouter;
