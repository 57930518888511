// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { PictureAsPdf, PointOfSale } from '@mui/icons-material';
// COMPONENTS
import TillSelector from '../TillSelector';
import Paper from '../../../../global/Paper';
import SiteSelector from '../../../../global/SiteSelector';
import PDFTillReconciliationReport from '../../logic/PDFTillReconciliationReport';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

interface HeaderProps {
    selectedSite: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
    selectedTill: number;
    setSelectedTill: Dispatch<SetStateAction<number>>;
    selectedDate;
    setTransactionDrawerOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}
const Header = ({
    selectedSite,
    setSelectedSite,
    selectedTill,
    setSelectedTill,
    selectedDate,
    setTransactionDrawerOpen,
    showSnackbar
}: HeaderProps) => {
    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={4} textAlign="center">
                        <SiteSelector
                            variant="outlined"
                            label="Site"
                            disabled={false}
                            fullWidth={true}
                            size="small"
                            viewPartUI={false}
                            selectedSite={selectedSite}
                            setSelectedSite={setSelectedSite}
                        />
                    </Grid>
                    <Grid item xs={6} textAlign="center">
                        <TillSelector
                            label="Reconciliations"
                            selectedTillId={selectedTill}
                            setSelectedTillId={setSelectedTill}
                            selectedDate={selectedDate}
                            selectedSite={selectedSite}
                        />
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                        <Tooltip title="Export PDF">
                            <IconButton
                                onClick={() =>
                                    PDFTillReconciliationReport(
                                        selectedSite,
                                        showSnackbar,
                                        selectedDate
                                    )
                                }
                            >
                                <PictureAsPdf />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Transactions">
                            <IconButton
                                onClick={() => setTransactionDrawerOpen(true)}
                            >
                                <PointOfSale />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default Header;
