import api from '../../../../../api';

const CheckCustomerVehicleVIN = (vin, setExistingVin) => {
    api.get('/checkVinExistsCustomerVehicle/' + vin).then((res) => {
        if (res.status === 400) {
            setExistingVin(true);
        } else if (res.status === 200) {
            setExistingVin(false);
        }
    });
};

export default CheckCustomerVehicleVIN;
