import api from '../../../../api';

export const GetDocumentUrl = async (documentId: string) => {
    const res = await api.get(`/getDocumentUrl/${documentId}`);
    return res.data;
};

export const GetUserDocuments = async (status: string) => {
    const res = await api.get(`/getUserDocuments/${status}`);
    return res.data;
};

export const CreateDocumentFromTemplate = async (key: string) => {
    const res = await api.post('/createDocumentFromTemplate', {
        key
    });

    return res.data;
};
