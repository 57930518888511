// IMPROTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Autocomplete from '../../../../global/Autocomplete';
import Paper from '../../../../global/Paper';
// LOGIC
import GetUnpaidTrusts from '../../logic/GetUnpaidTrusts';
import CheckDocumentReference from '../../../../global/logic/CheckDocumentReference';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { ToFixed } from '../../../../global/logic/Formatters';
interface InvoiceLine {
    id: number;
    Vehicle?: Vehicle;
    baseCost?: string;
    paid?: string;
    amount: string;
}

interface InvoiceHeaderProps {
    invoiceLines: InvoiceLine[];
    setInvoiceLines: Dispatch<SetStateAction<InvoiceLine[]>>;
    trustReceipt;
    setTrustReceipt;
    responseCode: number;
    setResponseCode: Dispatch<SetStateAction<number>>;
}

const InvoiceHeader = ({
    invoiceLines,
    setInvoiceLines,
    trustReceipt,
    setTrustReceipt,
    responseCode,
    setResponseCode
}: InvoiceHeaderProps) => {
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
    const [searchVin, setSearchVin] = useState<boolean>(true);

    // Get all the unpaid trusts
    useEffect(() => {
        GetUnpaidTrusts(setVehicles);
    }, []);

    // Handle adding a line to the invoice
    const addToInvoiceLines = (vehicle: Vehicle) => {
        // Get the current lines and calculate a new ID
        let currentLines = [...invoiceLines];
        let newId =
            currentLines.length > 0
                ? currentLines[currentLines.length - 1].id + 1
                : 1;

        let trustPaidAmount = parseFloat(vehicle.trustPaidAmount)
            ? parseFloat(vehicle.trustPaidAmount)
            : 0;
        let amountPaying = ToFixed(
            parseFloat(vehicle.costPrice) * 1.1 - trustPaidAmount
        );

        // Generate the new line
        let newLine = {
            id: newId,
            Vehicle: vehicle,
            baseCost: String(vehicle.costPrice),
            paid: String(trustPaidAmount),
            amount: String(amountPaying)
        };

        // Push the new line and update the state
        currentLines.push(newLine);
        setInvoiceLines(currentLines);

        // Clear the old line data
        setSelectedVehicle(null);
    };

    let calculatedTotal = 0;

    invoiceLines.forEach((line) => {
        calculatedTotal += parseFloat(line.amount);
        if (line.amount === '' || parseFloat(line.amount) <= 0) {
            return;
        }
    });

    const difference = trustReceipt.documentTotal - calculatedTotal;

    return (
        <>
            <Paper>
                <Grid container spacing={2} justifyContent={'flex-end'}>
                    <Grid item xs={2} textAlign="center">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>STOCK #</Typography>
                            <Switch
                                checked={searchVin}
                                onChange={(e) => setSearchVin(e.target.checked)}
                            />
                            <Typography>VIN</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            options={vehicles}
                            useTwoOptionLabels={true}
                            primaryOptionLabel={
                                searchVin ? 'shortVin' : 'stockNumber'
                            }
                            secondaryOptionLabel="vehicleName"
                            textfieldLabel="Vehicle *"
                            selectedValue={selectedVehicle}
                            handleSelectedValueChange={(newValue) => {
                                addToInvoiceLines(newValue);
                                setSelectedVehicle(newValue);
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Document Reference *"
                            error={responseCode === 400 ? true : false}
                            helperText={
                                responseCode === 400
                                    ? 'Document Reference Already Exists.'
                                    : ''
                            }
                            InputLabelProps={{ shrink: true }}
                            value={trustReceipt.documentReference}
                            onChange={(e) => {
                                setTrustReceipt({
                                    ...trustReceipt,
                                    documentReference: e.target.value
                                });
                            }}
                            onBlur={(e) =>
                                CheckDocumentReference(
                                    e.target.value,
                                    setResponseCode
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Document Date *"
                                value={trustReceipt.documentDate}
                                onChange={(newValue) => {
                                    setTrustReceipt({
                                        ...trustReceipt,
                                        documentDate: newValue
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Due Date"
                                value={trustReceipt.dueDate}
                                onChange={(newValue) => {
                                    setTrustReceipt({
                                        ...trustReceipt,
                                        dueDate: newValue
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {trustReceipt.documentTotal && difference !== 0 && (
                        <Grid item xs={4} alignItems="center">
                            <Typography color="error" textAlign="right">
                                Discrepancy to Trust Receipt Totals: $
                                {difference.toFixed(2)}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Document Total * (INC. GST)"
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            value={trustReceipt.documentTotal}
                            onChange={(e) => {
                                setTrustReceipt({
                                    ...trustReceipt,
                                    documentTotal: e.target.value
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default InvoiceHeader;
