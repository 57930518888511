import api from '../../../../api';
import { Tag } from '../EsignInterface';

interface CreateEsignTemplateProps {
    name: string;
    documentKey: string;
    documentId: string;
    tags: Tag[];
}

export const CreateTemplate = async ({
    name,
    documentKey,
    documentId,
    tags
}: CreateEsignTemplateProps) => {
    const res = await api.post('/createTemplate', {
        name,
        documentKey,
        documentId,
        tags
    });
    return res;
};

export const GetDocumentTemplates = async () => {
    const res = await api.get('/getDocumentTemplates');
    return res.data;
};

export const GetTagTemplates = async () => {
    const res = await api.get('/getTagTemplates');
    return res.data;
};
