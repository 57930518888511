// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Order } from '../interfaces/PartsInterface';

const GetOrders = (
  orderType: string,
  partId: number,
  selectedSite: number,
  setProformas: Dispatch<SetStateAction<Order[]>>
) => {
  api
    .get(
      `getOrdersNextGen?orderType=${orderType}&UnitId=${partId}&SiteId=${selectedSite}`
    )
    .then((res) => {
      setProformas(res.data);
    });
};

export default GetOrders;
