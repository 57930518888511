// IMPORTS
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
// COMPONENTS
import TermsAndConditions from './ContractComponents/TermsAndConditions';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter,
    MonthYearFormatter
} from '../../../global/logic/Formatters';
import api from '../../../../../api';
import {
    PartTotals,
    TotalPaid,
    TradeTotals,
    GovernmentCharges
} from './TotalFunctions';
// INTERFACES
import {
    TradeIn,
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import {
    Customer,
    PaymentLine,
    Settings,
    Site
} from '../../../global/interfaces/GeneralInterface';
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';
import Header from './ContractComponents/Header';
import BlankCustomerBox from './ContractComponents/BlankCustomerBox';
import BlankVehicleBox from './ContractComponents/BlankVehicleBox';
import BlankTableTotals from './ContractComponents/BlankTableTotals';
import BlankCreditBox from './ContractComponents/BlankCreditBox';
import BlankDeliveryBox from './ContractComponents/BlankDeliveryBox';
import BlankTradeBox from './ContractComponents/BlankTradeBox';
import FilledTrades from './ContractComponents/FilledTrades';
import UsedDeclarations from './ContractComponents/UsedDeclarations';
import { Discount } from './TotalFunctions';
import { UploadFileToS3 } from '../../../esign/logic/S3FileService';

const PDFContract = async (
    saleDetails: VehicleSale,
    selectedCustomer: Customer,
    selectedVehicle: Vehicle,
    linkedSales: Order[],
    linkedTrades: TradeIn[],
    linkedVouchers: Voucher[],
    paymentLines: PaymentLine[],
    fillInformation: boolean,
    base64Image: string | null,
    uploadToS3?: boolean
) => {
    let settings: Settings;
    let site: Site;
    let image = null;

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Get the settings in order to get company details and logo
    await api
        .get(
            `settingsNextGen/${localStorage.getItem('SiteId')}?includeImage=true`
        )
        .then((res) => {
            settings = res.data.settings;
            site = res.data.site;
            // Create and format an image object
            let newImg = document.createElement('img');
            newImg.src = res.data.image;
            newImg.className = 'logo';
            image = newImg;
        });

    /* ----------------------------- Document Header ---------------------------- */
    Header(doc, image, saleDetails, settings, site);
    /* ----------------------------- Document Header ---------------------------- */
    Header(doc, image, saleDetails, settings, site);

    /* ---------------------------- Customer Details ---------------------------- */
    BlankCustomerBox(doc);
    /* ---------------------------- Customer Details ---------------------------- */
    BlankCustomerBox(doc);

    // Fill customer information if required
    if (fillInformation) {
        doc.setFont('helvetica', 'normal').setFontSize(9);
        doc.text(
            saleDetails?.User?.firstName + ' ' + saleDetails?.User?.lastName,
            26,
            75
        );
        // Fill customer information if required
        if (fillInformation) {
            doc.setFont('helvetica', 'normal').setFontSize(9);
            doc.text(
                saleDetails?.User?.firstName +
                    ' ' +
                    saleDetails?.User?.lastName,
                26,
                75
            );

            if (settings.lmd) {
                doc.text(settings.lmd, 102, 76, { align: 'right' });
            }
            if (settings.lmd) {
                doc.text(settings.lmd, 102, 76, { align: 'right' });
            }

            if (selectedCustomer.companyName) {
                doc.text(selectedCustomer.companyName, 11, 86);
            } else {
                doc.text(
                    selectedCustomer.firstName +
                        ' ' +
                        selectedCustomer?.lastName,
                    11,
                    86
                );
            }
            if (selectedCustomer.companyName) {
                doc.text(selectedCustomer.companyName, 11, 86);
            } else {
                doc.text(
                    selectedCustomer.firstName +
                        ' ' +
                        selectedCustomer?.lastName,
                    11,
                    86
                );
            }

            if (selectedCustomer.addressLine1) {
                doc.text(selectedCustomer.addressLine1, 11, 93);
            }
            if (selectedCustomer.addressLine1) {
                doc.text(selectedCustomer.addressLine1, 11, 93);
            }

            if (selectedCustomer.addressLine2 && selectedCustomer.suburb) {
                doc.text(
                    selectedCustomer.addressLine2 +
                        ' ' +
                        selectedCustomer.suburb,
                    11,
                    100
                );
            } else if (selectedCustomer.addressLine2) {
                doc.text(selectedCustomer.addressLine2, 11, 100);
            } else if (selectedCustomer.suburb) {
                doc.text(selectedCustomer.suburb, 11, 100);
            }
            if (selectedCustomer.addressLine2 && selectedCustomer.suburb) {
                doc.text(
                    selectedCustomer.addressLine2 +
                        ' ' +
                        selectedCustomer.suburb,
                    11,
                    100
                );
            } else if (selectedCustomer.addressLine2) {
                doc.text(selectedCustomer.addressLine2, 11, 100);
            } else if (selectedCustomer.suburb) {
                doc.text(selectedCustomer.suburb, 11, 100);
            }

            if (selectedCustomer.postcode) {
                doc.text(selectedCustomer.postcode, 82, 100);
            }
            if (selectedCustomer.postcode) {
                doc.text(selectedCustomer.postcode, 82, 100);
            }

            if (selectedCustomer.phoneNumber) {
                doc.text(selectedCustomer.phoneNumber, 30, 107);
            }
            if (selectedCustomer.phoneNumber) {
                doc.text(selectedCustomer.phoneNumber, 30, 107);
            }

            if (selectedCustomer.email) {
                doc.text(selectedCustomer.email, 19, 114);
            }
            if (selectedCustomer.email) {
                doc.text(selectedCustomer.email, 19, 114);
            }

            if (selectedCustomer.driverLicenceNumber) {
                doc.text(selectedCustomer.driverLicenceNumber, 71, 122);
            }
            if (selectedCustomer.driverLicenceNumber) {
                doc.text(selectedCustomer.driverLicenceNumber, 71, 122);
            }

            if (selectedCustomer.dateOfBirth) {
                let dob = moment(selectedCustomer.dateOfBirth);
                doc.text(dob.format('DD MMM YYYY'), 19, 122);
            }

            if (selectedCustomer.abn) {
                doc.text(selectedCustomer.abn, 19, 128);
            }
        }

        /* ----------------------------- Vehicle Details ---------------------------- */
        BlankVehicleBox(doc);

        // Fill vehicle details if required
        if (fillInformation) {
            doc.setFont('helvetica', 'normal').setFontSize(9);
            doc.text(
                selectedVehicle.Make?.name + ' ' + selectedVehicle.Model.name,
                119,
                81
            );

            if (selectedVehicle?.condition) {
                doc.text(selectedVehicle?.condition, 122, 87);
            }

            if (selectedVehicle.BodyTypeId) {
                doc.text(selectedVehicle.BodyType.name, 119, 94);
            }

            if (selectedVehicle.registrationNumber) {
                doc.text(selectedVehicle.registrationNumber, 171, 94);
            }

            if (selectedVehicle.vin) {
                doc.text(selectedVehicle.vin, 130, 101);
            }

            if (selectedVehicle.engineNumber) {
                doc.text(selectedVehicle.engineNumber, 130, 107.5);
            }

            if (selectedVehicle.year) {
                doc.text(selectedVehicle.year.format('YYYY'), 117, 115);
            }

            if (selectedVehicle.complianceDate) {
                doc.text(
                    MonthYearFormatter(selectedVehicle.complianceDate),
                    170,
                    115
                );
            }

            if (selectedVehicle.odometer) {
                doc.text(selectedVehicle.odometer, 148, 121, {
                    align: 'right'
                });
            }

            if (selectedVehicle.colour) {
                doc.text(selectedVehicle.colour, 166, 121);
            }

            if (selectedVehicle.stockNumber) {
                doc.text(selectedVehicle.stockNumber, 121, 128);
            }
        }

        /* ------------------------------ Prices Table ------------------------------ */
        const headers = [['Item', 'Description', 'Unit Price']];
        let bikeBody = [
            selectedVehicle.stockNumber,
            (selectedVehicle.condition ?? 'New') +
                ' ' +
                selectedVehicle.year +
                ' ' +
                selectedVehicle.Make?.name +
                ' ' +
                selectedVehicle.Model?.name,
            CurrencyFormatter(saleDetails.vehicleSalePrice)
        ];
        let tableBody = [bikeBody];

        if (linkedSales.length > 0) {
            let addOnBody = [
                'Add Ons',
                'See Appendix One For Details',
                CurrencyFormatter(PartTotals(linkedSales).totalPartPrice)
            ];
            tableBody.push(addOnBody);
        }

        if (linkedTrades.length > 0) {
            let tradeBody = [
                'Trades',
                'See Appendix Three For Details',
                CurrencyFormatter(
                    TradeTotals(linkedTrades, saleDetails.gstType)
                )
            ];
            tableBody.push(tradeBody);
        }

        doc.rect(10, 135, 93, 148);
        doc.setFont('helvetica', 'bold')
            .setFontSize(9)
            .text('PRICES AND TERMS OF SETTLEMENT', 56.5, 139.5, {
                align: 'center'
            });
        doc.line(10, 142, 103, 142);

        let lastYcoordinates;

        if (fillInformation) {
            autoTable(doc, {
                body: tableBody,
                head: headers,
                startY: 143,
                margin: { bottom: 20, left: 12 },
                theme: 'striped',
                tableWidth: 88,
                headStyles: { fillColor: 'white', textColor: 'black' }
            });

            lastYcoordinates = (doc as any).lastAutoTable.finalY + 3;
        } else {
            lastYcoordinates = 170;
        }

        BlankTableTotals(doc, saleDetails, lastYcoordinates);

        if (fillInformation) {
            let totalPayments = 0;
            paymentLines.forEach((line) => {
                if (!line.removed) {
                    totalPayments += line.amount;
                }
            });

            doc.setFont('helvetica', 'normal').setFontSize(9).setTextColor(30);
            if (saleDetails.registrationPrice) {
                doc.text(
                    CurrencyFormatter(saleDetails.registrationPrice.toString()),
                    102,
                    lastYcoordinates + 2,
                    { align: 'right' }
                );
            } else {
                doc.text(CurrencyFormatter('0'), 102, lastYcoordinates + 2, {
                    align: 'right'
                });
            }
            if (saleDetails.ctpPrice) {
                doc.text(
                    CurrencyFormatter(saleDetails.ctpPrice.toString()),
                    102,
                    lastYcoordinates + 6,
                    { align: 'right' }
                );
            } else {
                doc.text(CurrencyFormatter('0'), 102, lastYcoordinates + 6, {
                    align: 'right'
                });
            }
            if (saleDetails.transferPrice) {
                doc.text(
                    CurrencyFormatter(saleDetails.transferPrice.toString()),
                    102,
                    lastYcoordinates + 10,
                    { align: 'right' }
                );
            } else {
                doc.text(CurrencyFormatter('0'), 102, lastYcoordinates + 10, {
                    align: 'right'
                });
            }
            if (saleDetails.stampDutyPrice && !saleDetails.excludeStampDuty) {
                doc.text(
                    CurrencyFormatter(saleDetails.stampDutyPrice.toString()),
                    102,
                    lastYcoordinates + 14,
                    { align: 'right' }
                );
            } else {
                doc.text(CurrencyFormatter('0'), 102, lastYcoordinates + 14, {
                    align: 'right'
                });
            }
            if (saleDetails.deliveryPrice) {
                doc.text(
                    CurrencyFormatter(saleDetails.deliveryPrice.toString()),
                    102,
                    lastYcoordinates + 18,
                    { align: 'right' }
                );
            } else {
                doc.text(CurrencyFormatter('0'), 102, lastYcoordinates + 18, {
                    align: 'right'
                });
            }
            if (saleDetails.insurancePrice) {
                doc.text(
                    CurrencyFormatter(saleDetails.insurancePrice.toString()),
                    102,
                    lastYcoordinates + 22,
                    { align: 'right' }
                );
            } else {
                doc.text(CurrencyFormatter('0'), 102, lastYcoordinates + 22, {
                    align: 'right'
                });
            }
            if (saleDetails.warrantyPrice) {
                doc.text(
                    CurrencyFormatter(saleDetails.warrantyPrice.toString()),
                    102,
                    lastYcoordinates + 26,
                    { align: 'right' }
                );
            } else {
                doc.text(CurrencyFormatter('0'), 102, lastYcoordinates + 26, {
                    align: 'right'
                });
            }
            doc.text(
                CurrencyFormatter(Discount(saleDetails)),
                102,
                lastYcoordinates + 30,
                { align: 'right' }
            );
            doc.setFont('helvetica', 'bold').text(
                saleDetails.excludeGST
                    ? CurrencyFormatter(saleDetails.totalPurchasePrice)
                    : CurrencyFormatter(
                          (parseFloat(saleDetails.totalPurchasePrice) -
                              GovernmentCharges(saleDetails)) /
                              1.1
                      ),
                102,
                lastYcoordinates + 34,
                { align: 'right' }
            );
            doc.text(
                CurrencyFormatter(parseFloat(saleDetails.totalPurchasePrice)),
                102,
                lastYcoordinates + 38,
                { align: 'right' }
            );
            doc.setFont('helvetica', 'normal').text(
                CurrencyFormatter(
                    TradeTotals(linkedTrades, saleDetails.gstType)
                ),
                102,
                lastYcoordinates + 42,
                { align: 'right' }
            );
            doc.setFont('helvetica', 'bold').text(
                CurrencyFormatter(
                    saleDetails.excludeGST
                        ? parseFloat(saleDetails.totalPurchasePrice) / 1.1 -
                              TradeTotals(linkedTrades, saleDetails.gstType)
                        : parseFloat(saleDetails.totalPurchasePrice) -
                              TradeTotals(linkedTrades, saleDetails.gstType)
                ),
                102,
                lastYcoordinates + 46,
                { align: 'right' }
            );

            doc.setFont('helvetica', 'bold')
                .setFontSize(9)
                .setTextColor(0, 0, 0);
            doc.text(
                CurrencyFormatter(totalPayments),
                102,
                lastYcoordinates + 52,
                {
                    align: 'right'
                }
            );

            doc.setFont('helvetica', 'normal').setFontSize(9).setTextColor(30);
            doc.text(
                CurrencyFormatter(
                    saleDetails.excludeGST
                        ? (parseFloat(saleDetails.totalPurchasePrice) -
                              TradeTotals(linkedTrades, saleDetails.gstType)) /
                              1.1 -
                              totalPayments
                        : parseFloat(saleDetails.totalPurchasePrice) -
                              TradeTotals(linkedTrades, saleDetails.gstType) -
                              totalPayments
                ),
                102,
                lastYcoordinates + 56,
                { align: 'right' }
            );
            doc.text(
                settings.directDepositAccountName,
                102,
                lastYcoordinates + 68,
                {
                    align: 'right'
                }
            );
            doc.text(
                settings.directDepositAccountBsb,
                102,
                lastYcoordinates + 72,
                {
                    align: 'right'
                }
            );
            doc.text(
                settings.directDepositAccountNumber,
                102,
                lastYcoordinates + 76,
                {
                    align: 'right'
                }
            );
        }
        /* ----------------------------- Puchaser Credit ---------------------------- */
        BlankCreditBox(doc);

        /* ---------------------------- Delivery Receipt ---------------------------- */
        BlankDeliveryBox(
            doc,
            fillInformation,
            saleDetails.contractNotes,
            base64Image
        );

        doc.addPage();
        doc.setPage(2);

        /* -------------------------- Terms and Conditions -------------------------- */
        TermsAndConditions(doc, fillInformation, base64Image);

        let pageCount = 2;

        if (fillInformation) {
            // prefill purchaser name
            doc.setFont('helvetica', 'normal').setFontSize(6).setTextColor(30);

            if (selectedCustomer.companyName) {
                doc.text(selectedCustomer.companyName, 20, 218);
            } else {
                doc.text(
                    selectedCustomer.firstName +
                        ' ' +
                        selectedCustomer?.lastName,
                    20,
                    218
                );
            }
        }

        // Add the used vehicle declarations if the vehicle is used; if vehicle condition is New or doesn't exist(manufacturer), skip
        if (selectedVehicle.condition === 'Used') {
            pageCount = UsedDeclarations(
                doc,
                pageCount,
                fillInformation,
                settings,
                base64Image
            );
        }

        /* ------------------------------ Appendix One ------------------------------ */
        if (linkedSales.length > 0 && fillInformation) {
            doc.addPage();
            pageCount += 1;
            doc.setPage(pageCount);

            doc.setFont('helvetica', 'bold')
                .setFontSize(17)
                .setTextColor(0, 0, 0);
            doc.text('Appendix One - Add-Ons', 20, 20);

            const appendixOneHeader = [
                ['QTY', 'Part Number', 'Name', 'Unit Price', 'Line Total']
            ];
            const appendixOneBody = [];

            linkedSales.forEach((sale) => {
                sale.orderLines.forEach((line) => {
                    appendixOneBody.push([
                        line.quantity,
                        line.partNumber,
                        line.name,
                        CurrencyFormatter(line.pricePaid),
                        CurrencyFormatter(
                            parseFloat(line.pricePaid) * parseInt(line.quantity)
                        )
                    ]);
                });
            });

            appendixOneBody.push([
                '',
                '',
                '',
                'Total',
                CurrencyFormatter(PartTotals(linkedSales).totalPartPrice)
            ]);

            autoTable(doc, {
                body: appendixOneBody,
                head: appendixOneHeader,
                startY: 23,
                margin: { bottom: 20, left: 12 },
                theme: 'striped',
                headStyles: { fillColor: 'white', textColor: 'black' }
            });
        }

        /* ------------------------------ Appendix Two ------------------------------ */
        if (paymentLines.length > 0 && fillInformation) {
            if (linkedSales.length === 0) {
                doc.addPage();
                pageCount += 1;
                doc.setPage(pageCount);
                lastYcoordinates = 20;
            } else {
                lastYcoordinates = (doc as any).lastAutoTable.finalY + 10;
            }

            doc.setFont('helvetica', 'bold')
                .setFontSize(17)
                .setTextColor(0, 0, 0);
            doc.text('Appendix Two - Payments', 20, lastYcoordinates);

            const appendixThreeHeader = [['Method', 'Amount', 'Date']];
            const appendixThreeBody = [];

            paymentLines.forEach((payment) => {
                if (!payment.removed) {
                    appendixThreeBody.push([
                        payment.type.toUpperCase(),
                        CurrencyFormatter(payment.amount),
                        `${DateFormatter(payment.date)} ${moment(payment.timestamp).format('h:mm A')}`
                    ]);
                }
            });

            appendixThreeBody.push([
                '',
                'Total',
                CurrencyFormatter(TotalPaid(paymentLines))
            ]);

            autoTable(doc, {
                body: appendixThreeBody,
                head: appendixThreeHeader,
                startY: lastYcoordinates + 10,
                margin: { bottom: 20, left: 12 },
                theme: 'striped',
                headStyles: { fillColor: 'white', textColor: 'black' }
            });
        }

        lastYcoordinates = 27;
        /* ------------------------------ Appendix Three ------------------------------ */
        if (linkedTrades.length > 0 && fillInformation) {
            doc.addPage();
            pageCount += 1;
            doc.setPage(pageCount);
            lastYcoordinates = 30;

            doc.setFont('helvetica', 'bold')
                .setFontSize(17)
                .setTextColor(0, 0, 0);
            doc.text('Appendix Three - Trades', 20, 20);

            linkedTrades.forEach((trade, index) => {
                if (index === 0) {
                    BlankTradeBox(doc, lastYcoordinates);
                    if (fillInformation) {
                        FilledTrades(doc, lastYcoordinates, trade);
                    }
                } else if (index % 5 === 0) {
                    doc.addPage();
                    pageCount += 1;
                    doc.setPage(pageCount);

                    lastYcoordinates = 27;
                    BlankTradeBox(doc, lastYcoordinates);
                    if (fillInformation) {
                        FilledTrades(doc, lastYcoordinates, trade);
                    }
                } else {
                    lastYcoordinates += 63;
                    BlankTradeBox(doc, lastYcoordinates);
                    if (fillInformation) {
                        FilledTrades(doc, lastYcoordinates, trade);
                    }
                }
            });
        }

        lastYcoordinates = 27;

        if (uploadToS3) {
            var base64data = doc.output('datauristring'); // base64 string
            UploadFileToS3({
                base64data: base64data,
                folderName: 'vehicleSaleContracts',
                fileName: saleDetails.id
            });
        } else {
            // Open the pdf
            window.open(doc.output('bloburl'));
        }
    }
};

export default PDFContract;
