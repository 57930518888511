import { VehicleSale } from '../../../global/interfaces/VehicleSaleInterface';

export const TaxableAmount = (vehicleSale: VehicleSale) => {
  let taxableAmount = 0;

  // If no on-road costs
  if (!vehicleSale.includesOnRoadCosts) {
    taxableAmount =
      (parseFloat(vehicleSale.totalPurchasePrice) -
        parseFloat(vehicleSale.stampDutyPrice) -
        parseFloat(vehicleSale.transferPrice)) /
      1.1;
  } else {
    taxableAmount = parseFloat(vehicleSale.totalPurchasePrice) / 1.1;
  }

  return taxableAmount;
};

/**
 * TotalPayments
 * Total all payments made for a vehicle sale
 * @author Estienne
 * @param vehicleSale
 * @returns the sum of all payments
 */
export const TotalPayments = (vehicleSale: VehicleSale) => {
  let totalPayments = 0;

  vehicleSale?.paymentLines.forEach((line) => {
    if (!line.removed) {
      totalPayments += line.amount;
    }
  });

  return totalPayments;
};

/**
 * SaleTotal
 * Get the total cost of the sale
 * @param vehicleSale A singular vehicle sale
 * @returns the total cost of the sale
 */
export const SaleTotal = (vehicleSale: VehicleSale) => {
  let saleTotal = 0;

  // Does the sale exclude GST?
  if (vehicleSale.excludeGST) {
    saleTotal =
      TaxableAmount(vehicleSale) - parseFloat(vehicleSale.tradeInPrice);
  } else {
    saleTotal =
      parseFloat(vehicleSale.totalPurchasePrice) -
      parseFloat(vehicleSale.tradeInPrice);
  }

  return saleTotal;
};
