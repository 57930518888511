// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    TextField,
    Checkbox,
    Grid,
    FormControlLabel,
    MenuItem,
    InputAdornment,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent
} from '@mui/material';
// INTERFACES
import {
    Vehicle,
    VehicleSale
} from '../../../../global/interfaces/VehicleSaleInterface';
import { TableView } from '@mui/icons-material';
import QLDRegistration from './QLDRegistration';
import QLDCtp from './QLDCtp';
import GetSite from '../../../../global/databaseLogic/GetSite';
import { Site } from '../../../../global/interfaces/GeneralInterface';
import NSWRegistration from './NSWRegistration';
import NSWCtpDialog from './NSWCtpDialog';
import QLDStampDuty from './QLDStampDuty';
import NSWStampDuty from './NSWStampDuty';

interface GovernmentChargesProps {
    readOnly: boolean;
    saleDetails: VehicleSale;
    setSaleDetails: Dispatch<SetStateAction<VehicleSale>>;
    selectedVehicle: Vehicle;
}

const GovernmentCharges = ({
    readOnly,
    saleDetails,
    setSaleDetails,
    selectedVehicle
}: GovernmentChargesProps) => {
    const [registrationDialogOpen, setRegistrationDialogOpen] =
        useState<boolean>(false);
    const [ctpDialogOpen, setCtpDialogOpen] = useState<boolean>(false);
    const [stampDutyDialogOpen, setStampDutyDaialogOpen] =
        useState<boolean>(false);
    const [siteDetails, setSiteDetails] = useState<Site>();

    useEffect(() => {
        GetSite(parseInt(localStorage.getItem('SiteId')), setSiteDetails);
    }, []);

    const lengthOptions = [
        { value: 'None' },
        { value: '3 Months' },
        { value: '6 Months' },
        { value: '12 Months' }
    ];
    const typeOptions = [{ value: 'Personal' }, { value: 'Business' }];
    const seatOptions = [
        { value: '1', label: 'Single' },
        { value: '2', label: 'Dual' }
    ];

    const handleChangeRegistrationLength = (value) => {
        if (value === 'None') {
            setSaleDetails({
                ...saleDetails,
                registrationLength: value,
                registrationType: null,
                registrationSeats: null
            });
        } else {
            setSaleDetails({ ...saleDetails, registrationLength: value });
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        select
                        size="small"
                        label="Registration Length"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.registrationLength}
                        onChange={(e) =>
                            handleChangeRegistrationLength(e.target.value)
                        }
                    >
                        {lengthOptions.map((line) => (
                            <MenuItem value={line.value}>{line.value}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={
                            readOnly ||
                            saleDetails.registrationLength === 'None'
                        }
                        fullWidth
                        select
                        size="small"
                        label="Registration Type"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.registrationType}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                registrationType: e.target.value
                            })
                        }
                    >
                        {typeOptions.map((line) => (
                            <MenuItem value={line.value}>{line.value}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={
                            readOnly ||
                            saleDetails.registrationLength === 'None'
                        }
                        fullWidth
                        select
                        size="small"
                        label="Registration Seats"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.registrationSeats}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                registrationSeats: e.target.value
                            })
                        }
                    >
                        {seatOptions.map((line) => (
                            <MenuItem value={line.value}>{line.label}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={
                            readOnly ||
                            saleDetails.registrationLength === 'None'
                        }
                        fullWidth
                        size="small"
                        type="number"
                        label="Registration Total"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.registrationPrice}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                registrationPrice: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment:
                                siteDetails?.state === 'NSW' ? null : (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={readOnly}
                                            onClick={() =>
                                                setRegistrationDialogOpen(true)
                                            }
                                        >
                                            <TableView />
                                        </IconButton>
                                    </InputAdornment>
                                )
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={
                            readOnly ||
                            saleDetails.registrationLength === 'None'
                        }
                        fullWidth
                        size="small"
                        label="CTP"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.ctpPrice}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                ctpPrice: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={readOnly}
                                        onClick={() => setCtpDialogOpen(true)}
                                    >
                                        <TableView />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Transfer Fee"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.transferPrice}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                transferPrice: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        disabled={saleDetails?.excludeStampDuty || readOnly}
                        size="small"
                        type="number"
                        label="Stamp Duty"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.stampDutyPrice}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                stampDutyPrice: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={readOnly}
                                        onClick={() =>
                                            setStampDutyDaialogOpen(true)
                                        }
                                    >
                                        <TableView />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={readOnly}
                                checked={saleDetails?.excludeStampDuty}
                                onChange={(e) =>
                                    setSaleDetails({
                                        ...saleDetails,
                                        excludeStampDuty: e.target.checked
                                    })
                                }
                            />
                        }
                        label="Remove Stamp Duty"
                    />
                </Grid>
            </Grid>
            <Dialog
                open={registrationDialogOpen}
                onClose={() => setRegistrationDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    {siteDetails?.state} Registration Prices
                </DialogTitle>
                <DialogContent>
                    {siteDetails?.state === 'QLD' ? (
                        <QLDRegistration />
                    ) : (
                        <NSWRegistration />
                    )}
                </DialogContent>
            </Dialog>

            <Dialog
                open={ctpDialogOpen}
                onClose={() => setCtpDialogOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <DialogContent>
                    {siteDetails?.state === 'QLD' ? (
                        <QLDCtp />
                    ) : (
                        <NSWCtpDialog
                            length={saleDetails.registrationLength}
                            selectedType={saleDetails.registrationType}
                            saleDetails={saleDetails}
                            setSaleDetails={setSaleDetails}
                            setCtpDialogOpen={setCtpDialogOpen}
                        />
                    )}
                </DialogContent>
            </Dialog>

            <Dialog
                open={stampDutyDialogOpen}
                onClose={() => setStampDutyDaialogOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <DialogContent>
                    {siteDetails?.state === 'QLD' ? (
                        <QLDStampDuty
                            saleDetails={saleDetails}
                            setSaleDetails={setSaleDetails}
                        />
                    ) : (
                        <NSWStampDuty
                            saleDetails={saleDetails}
                            setSaleDetails={setSaleDetails}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default GovernmentCharges;
