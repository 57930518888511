// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { DebtorPayment } from '../../../global/interfaces/GeneralInterface';

/*  HandleSubmitPayment
    Handles submitting a debtor payment to the database
    params:
        - CustomerId: The ID of the customer
        - amountPaying: The amount being paid
        - datePaying: The date the payment is being receipted
        - selectedInvoices: all the invoices the payment is for
        - setErrors: React state setter for the errors
        - setSnackbar: React state setter for the snackbar
*/
const HandleSubmitPayment = (
    CustomerId: number,
    amountPaying: number,
    datePaying: string,
    selectedInvoices: DebtorPayment[],
    setErrors: Dispatch<SetStateAction<{ amount: boolean; date: boolean }>>,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    let isError = false;
    let errors = { amount: false, date: false };

    if (!amountPaying || amountPaying === 0) {
        isError = true;
        errors.amount = true;
    }
    if (!datePaying || datePaying === '') {
        isError = true;
        errors.date = true;
    }

    if (!isError) {
        let strippedAmountPaying = parseFloat(
            String(amountPaying).replace(',', '')
        );
        api.post('debtorPayment', {
            CustomerId: CustomerId,
            amountPaying: strippedAmountPaying,
            datePaying: datePaying,
            selectedInvoices: selectedInvoices
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Payment Submitted!',
                    'The page will now refresh.'
                );
                setTimeout(() => window.location.reload(), 1000);
            } else {
                showSnackbar(
                    'Whoops! It seems like something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
                setButtonLoading(false);
            }
        });
    } else {
        setErrors(errors);
        showSnackbar(
            'Whoops! It seems like you have missed some data.',
            'Please fill out the highlighted fields before proceeding.',
            'error'
        );
        setButtonLoading(false);
    }
};

export default HandleSubmitPayment;
