import { Dispatch, SetStateAction } from "react";
import { Vehicle } from "../../../global/interfaces/VehicleSaleInterface";

const HandleFreightChange = (value: string, vehicle: Vehicle, listOfVehicles: Vehicle[], setListOfVehicles: Dispatch<SetStateAction<Vehicle[]>>, gstMethod: string) => {
    var index = listOfVehicles.findIndex(x => x.id === vehicle.id);
    let rows = [...listOfVehicles];
    let row = { ...rows[index] };

    row.freight = parseFloat(value);
    gstMethod === "Excluding"
        ? row.gst = (parseFloat(row.costPrice) + parseFloat(value)) * 0.1
        : row.gst = (parseFloat(row.costPrice) + parseFloat(value)) - ((parseFloat(row.costPrice) + parseFloat(value)) / 1.1)

    rows[index] = row;
    setListOfVehicles(rows)
}

export default HandleFreightChange;