import api from '../../../../../../../api';
import CalculateParts from './CalculateParts';
import CalculateVehicleSales from './CalculateVehicleSales';
import CalculateService from './CalculateService';
import CalculateRebatesAndExpenses from './CalculateRebatesAndExpenses';

import { CalculatePNLReportingParams } from '../interface/PNLInterfaces';

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const getAllInterval = (originalStartDate, endDate) => {
    let intervals = [];

    let done = false;
    let startDate = originalStartDate;
    while (!done) {
        if (startDate.isBefore(endDate)) {
            let endOfMonths = startDate.clone().endOf('month');
            // If the current end of months is after the end date, we went too far and reached the last interval
            if (
                endOfMonths.isAfter(endDate) ||
                endOfMonths.isSame(endDate, 'day')
            ) {
                intervals.push({
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD')
                });
                done = true;
            }
            // Otherwise we push in the interval the current month, and update startDate to be the first day of the new month
            else {
                intervals.push({
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endOfMonths.format('YYYY-MM-DD')
                });
                startDate = endOfMonths.clone().add(1, 'day').startOf('month');
            }
        } else {
            done = true;
        }
    }

    return intervals;
};

const CalculatePNLReporting = async (params: CalculatePNLReportingParams) => {
    params.setLoading(true);
    params.setButtonLoading(true);

    var vehicleSales = [];
    var orders = [];
    var services = [];
    var registration = [];
    var generalJournal = [];
    var invoices = [];
    var manualTransaction = [];
    var averageCostAdjusts = [];
    var totalStripeFee = 0;
    var siteData = null;

    let intervals = getAllInterval(
        params.queryDate.startDate,
        params.queryDate.endDate
    );

    let promiseArray = [];
    for await (let interval of intervals) {
        promiseArray.push(
            api
                .get(
                    `VehicleSalePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    vehicleSales.push(res.data);
                })
        );
        promiseArray.push(
            api
                .get(
                    `OrderPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    orders.push(res.data);
                })
        );
        promiseArray.push(
            api
                .get(
                    `ServicePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    services.push(res.data);
                })
        );
        promiseArray.push(
            api
                .get(
                    `MiscPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    registration.push(res.data.registrationCosts);
                    generalJournal.push(res.data.generalJournal);
                    invoices.push(res.data.invoices);
                    manualTransaction.push(res.data.manualTransaction);
                    averageCostAdjusts.push(res.data.averageCostAdjusts);
                    totalStripeFee += res.data.totalStripeFee;
                    siteData = res.data.sites;
                })
        );
    }

    Promise.all(promiseArray).then(() => {
        vehicleSales = vehicleSales.flat();
        orders = orders.flat();
        services = services.flat();
        registration = registration.flat();
        generalJournal = generalJournal.flat();
        invoices = invoices.flat();
        manualTransaction = manualTransaction.flat();
        averageCostAdjusts = averageCostAdjusts.flat();

        CalculateVehicleSales(
            vehicleSales,
            registration,
            siteData,
            params.setVehicleData,
            params.setVehicleValues,
            params.setWrongVehicleData
        );
        CalculateParts(
            orders,
            params.setPartData,
            params.setPartValues,
            params.setWrongPartData
        );
        CalculateService(
            services,
            params.setServiceData,
            params.setServiceValues,
            params.setWrongServiceData
        );

        let [rebates, expenses] = CalculateRebatesAndExpenses(
            averageCostAdjusts,
            manualTransaction,
            generalJournal,
            invoices,
            totalStripeFee,
            params.selectedSite
        );

        let groupedExpenses = groupBy(expenses, 'accountCategory');
        let finalExpense = {};
        for (let key of Object.keys(groupedExpenses)) {
            finalExpense[key] = groupBy(
                groupedExpenses[key] ?? [],
                'accountName'
            );
        }

        let groupedRebates = groupBy(rebates, 'accountCategory');
        let finalRebates = {};
        for (let key of Object.keys(groupedRebates)) {
            finalRebates[key] = groupBy(
                groupedRebates[key] ?? [],
                'accountName'
            );
        }

        params.setLoading(false);
        params.setButtonLoading(false);
        params.setExpenses(finalExpense);
        params.setRebates(finalRebates);
    });
};

export default CalculatePNLReporting;
