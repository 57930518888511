import { User } from '../../interfaces/GeneralInterface';
import { showSnackbar } from '../../interfaces/GlobalInterface';
import TaxFileNumberValidation from './taxFileNumberValidation';

const ValidateUserFields = (
    userDetails: User,
    responseCode: number,
    showSnackbar: showSnackbar
) => {
    let validateTaxFileNumber = {
        error: false,
        message: '',
        subMessage: ''
    };

    if (
        userDetails.taxFileNumber &&
        Object.hasOwn(userDetails.taxFileNumber, 'number') &&
        userDetails?.taxFileNumber?.number.length > 0
    ) {
        validateTaxFileNumber = TaxFileNumberValidation(
            userDetails?.taxFileNumber?.number
        );
    }

    // Check that all required fields have been entered
    if (!userDetails.firstName || userDetails.firstName === '') {
        showSnackbar(
            'First name is required.',
            'Please enter a first name to continue.',
            'error'
        );
        return false;
    } else if (!userDetails.lastName || userDetails.lastName === '') {
        showSnackbar(
            'Last name is required.',
            'Please enter a last name to continue.',
            'error'
        );
        return false;
    } else if (!userDetails.username || userDetails.username === '') {
        showSnackbar(
            'Username is required.',
            'Please enter a username to continue.',
            'error'
        );
        return false;
    } else if (responseCode === 400) {
        showSnackbar(
            'Username is taken.',
            'Please enter a different username to continue.',
            'error'
        );
        return false;
    } else if (!userDetails.department || userDetails.department === '') {
        showSnackbar(
            'Department is required.',
            'Please select a department to continue.',
            'error'
        );
        return false;
    } else if (!userDetails.staffEmail || userDetails.staffEmail === '') {
        showSnackbar(
            'Staff email is required.',
            'Please enter a staff email to continue.',
            'error'
        );
        return false;
    } else if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
            userDetails.staffEmail
        )
    ) {
        showSnackbar(
            'Invalid staff email.',
            'Emails should be in the format example@email.com',
            'error'
        );
        return false;
    } else if (!userDetails.SiteId) {
        showSnackbar(
            'Primary store is required.',
            'Please select a primary store to continue.',
            'error'
        );
        return false;
    } else if (
        userDetails.phoneNumber &&
        !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(
            userDetails.phoneNumber
        )
    ) {
        showSnackbar(
            'Invalid phone number.',
            'Phone numbers should contain only numbers.',
            'error'
        );
        return false;
    } else if (
        userDetails.email &&
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userDetails.email)
    ) {
        showSnackbar(
            'Invalid personal email.',
            'Emails should be in the format example@email.com',
            'error'
        );
        return false;
    } else if (
        userDetails.annualSalary &&
        isNaN(parseFloat(userDetails.annualSalary))
    ) {
        showSnackbar(
            'Invalid annual salary.',
            'Annual salary should be a number with no puncuation.',
            'error'
        );
        return false;
    } else if (
        userDetails.hourlyRate &&
        isNaN(parseFloat(userDetails.hourlyRate))
    ) {
        showSnackbar(
            'Invalid hourly rate.',
            'Hourly rate should be a number with no punctuation.',
            'error'
        );
        return false;
    } else if (
        userDetails.standardHours &&
        isNaN(parseFloat(userDetails.standardHours))
    ) {
        showSnackbar(
            'Invalid standard weekly hours.',
            'Standard weekly hours should be a number with no punctuation.',
            'error'
        );
        return false;
    } else if (validateTaxFileNumber.error) {
        showSnackbar(
            validateTaxFileNumber.message,
            validateTaxFileNumber.subMessage,
            'error'
        );
        return false;
    } else if (
        userDetails.taxFileNumber &&
        !validateTaxFileNumber.error &&
        Object.hasOwn(userDetails.taxFileNumber, 'number') &&
        userDetails?.taxFileNumber?.number.length !== 0 &&
        userDetails?.taxFileNumber?.dateProvided != null &&
        !userDetails?.taxFileNumber?.dateProvided.isValid()
    ) {
        showSnackbar(
            'TFN Date Provided field empty or incorrectly filled.',
            'Please enter when the TFN was provided.',
            'error'
        );
        return false;
    } else if (
        userDetails?.bankAccount?.bsb &&
        (userDetails?.bankAccount?.bsb.length !== 6 ||
            isNaN(parseFloat(userDetails?.bankAccount?.bsb)))
    ) {
        showSnackbar(
            'Invalid BSB.',
            'BSB should be 6 numbers with no punctuation.',
            'error'
        );
        return false;
    } else if (
        userDetails?.bankAccount?.number &&
        isNaN(parseFloat(userDetails?.bankAccount?.number))
    ) {
        showSnackbar(
            'Invalid account number.',
            'Account number should be numbers with no punctuation.',
            'error'
        );
        return false;
    } else if (
        userDetails?.bankAccount?.bsb.length > 0 &&
        userDetails?.bankAccount?.number.length > 0 &&
        userDetails?.bankAccount?.name.length === 0
    ) {
        showSnackbar(
            'Invalid bank name.',
            'Please provide a bank name.',
            'error'
        );
        return false;
    } else if (
        userDetails?.emergencyContact?.fullName.length > 0 ||
        userDetails?.emergencyContact?.phoneNumber.length > 0 ||
        userDetails?.emergencyContact?.relation.length > 0
    ) {
        if (
            !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(
                userDetails?.emergencyContact?.phoneNumber
            )
        ) {
            showSnackbar(
                'Invalid emergency contact phone number.',
                'Phone numbers should contain only numbers.',
                'error'
            );
            return false;
        } else if (userDetails?.emergencyContact?.fullName.length === 0) {
            showSnackbar(
                'Invalid emergency contact name.',
                'Please enter an emergency contact name.',
                'error'
            );
            return false;
        } else if (userDetails?.emergencyContact?.relation.length === 0) {
            showSnackbar(
                'Invalid emergency contact relation.',
                'Please enter an emergency contact relation.',
                'error'
            );
            return false;
        } else {
            return true;
        }
    } else {
        return true;
    }
};

export default ValidateUserFields;
