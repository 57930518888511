import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const AddPartsToCategory = (
    categoryId: number,
    unitIds: number[],
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.put('addPartsToCategory', {
        categoryId: categoryId,
        unitIds: unitIds
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Parts successfully added to the Category',
                'The page will now reload.'
            );
            setTimeout(() => window.location.reload(), 1000);
        } else {
            showSnackbar(
                'Something went wrong.',
                'Refresh the page and try again.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default AddPartsToCategory;
