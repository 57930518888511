// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const ResetUserPassword = (
    UserId: number,
    firstName: string,
    staffEmail: string,
    showSnackbar: showSnackbar
) => {
    api.post(
        `resetUserPassword/${UserId}?SiteId=${localStorage.getItem('SiteId')}`,
        { firstName: firstName, staffEmail: staffEmail }
    ).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Password Reset',
                'New password was sent to user staff email.'
            );
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default ResetUserPassword;
