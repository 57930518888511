// IMPORTS
import { Chip } from '@mui/material';

const ChipArray = (props) => {
    return (
        <>
            {props.chipData.map((data) => (
                <Chip
                    key={data.SupplierId}
                    label={`${props.siteData[props.siteData.findIndex((x) => Number(x.id) === Number(data.SiteId))]?.code}: ${data[props.labelType]}`}
                    className="chip-array"
                    onClick={() => props.handleClick(data.SupplierId)}
                />
            ))}
        </>
    );
};

export default ChipArray;
