import DocumentationPage from '../DocumentationPage';
import { NewStockOrderSteps } from '../data/stockControl/newStockOrder';

const NewStockOrderSupportPage = () => {
    const contentSections = [
        {
            id: 'new-stock-order',
            title: 'New Stock Order',
            href: '/support/inventory/stockorder/#new-stock-order',
            description:
                'To create a new Stock Order follow these outlined steps:',
            steps: NewStockOrderSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="New Stock Order"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default NewStockOrderSupportPage;
