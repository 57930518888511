// IMPORTS
import { ReactNode } from 'react';
import { Grid, Typography, Chip } from '@mui/material';

interface PageHeaderProps {
  variant:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2';
  title: string;
  subTitleChip?: string;
  subTitleChipColour?: 'success' | 'error' | 'warning' | 'info';
  children: ReactNode;
}

const PageHeader = ({
  variant,
  title,
  subTitleChip,
  subTitleChipColour,
  children
}: PageHeaderProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography align="left" variant={variant}>
          {title}
        </Typography>
        {subTitleChip ? (
          <Chip
            variant="outlined"
            label={subTitleChip}
            color={subTitleChipColour}
          />
        ) : null}
        <br />
      </Grid>
      <Grid item xs={8} sx={{ textAlign: 'right' }}>
        {children}
        <br />
      </Grid>
    </Grid>
  );
};

export default PageHeader;
