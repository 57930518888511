// IMPORTS
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

/**
 * HandleReadyInvoices
 * Ready/Un-Ready Credit Invoices
 * @author Estienne
 * @param invoices the selected invoices
 * @param paymentStatus the current payment status ('ready', 'notready' or null)
 * @param setSnackbar state setter for snackbar to show success/error message
 */
const HandleReadyInvoices = (
    invoices: any[],
    paymentStatus: string,
    showSnackbar: showSnackbar
) => {
    let newPaymentStatus = '';
    if (paymentStatus === 'notready') {
        newPaymentStatus = 'ready';
    } else if (paymentStatus === 'ready') {
        newPaymentStatus = 'notready';
    }

    api.put(
        `creditInvoicePaymentStatus?paymentStatus=${newPaymentStatus}`,
        invoices
    ).then((res) => {
        if (res.status === 200) {
            if (paymentStatus === 'ready') {
                showSnackbar('Invoices readied');
                window.location.reload();
            } else {
                showSnackbar('Invoices unreadied');
                window.location.reload();
            }
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleReadyInvoices;
