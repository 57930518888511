// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
import TemplateValidation from './TemplateValidation';
// INTERFACES
import { Template } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const AddNewTemplate = (
    id: number,
    type: string,
    template: Template,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    setTemplateError: Dispatch<
        SetStateAction<{
            subject: boolean;
            department: boolean;
            messageBody: boolean;
            type: boolean;
        }>
    >
) => {
    setLoading(true);
    setReadOnly(true);

    // Some basic validation
    let isError = TemplateValidation(
        template,
        setLoading,
        setReadOnly,
        showSnackbar,
        setTemplateError
    );

    if (!isError) {
        api.put(`updateTemplate/${id}?type=${type}`, template).then((res) => {
            if (res.status === 200) {
                // Success
                showSnackbar('Template updated successfully!');
                setReadOnly(true);
                setLoading(false);
            } else {
                // Misc server error
                showSnackbar(
                    'Whoops! Something is wrong here!',

                    'Please contact your IT department to get this issue resolved.',
                    'error'
                );
                setReadOnly(false);
                setLoading(false);
            }
        });
    }
};

export default AddNewTemplate;
