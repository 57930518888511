import {
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import Paper from '../../../../global/Paper';
import { Close } from '@mui/icons-material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import CalculateTotalsV2 from '../../logic/Reconciliator/CalculateTotalsV2';
import FilterBankTransactionsV2 from '../../logic/Reconciliator/FilterBankTransactionsV2';
import FilterOraTransactionsV2 from '../../logic/Reconciliator/FilterOraTransactionsV2';

interface HeaderProps {
    reconciliationDetails;
    setReconciliationDetails;
    setSelectedOraUnrecTab: Dispatch<SetStateAction<string>>;
    setSelectedBankUnrecTab: Dispatch<SetStateAction<string>>;
    setSelectedOraRecTab: Dispatch<SetStateAction<string>>;
    setSelectedBankRecTab: Dispatch<SetStateAction<string>>;
    reconciliationDetailsUnaltered;
    refreshLoading: boolean;
    readOnly: boolean;
}
const HeaderV2 = ({
    reconciliationDetails,
    setReconciliationDetails,
    setSelectedOraUnrecTab,
    setSelectedBankUnrecTab,
    setSelectedOraRecTab,
    setSelectedBankRecTab,
    reconciliationDetailsUnaltered,
    refreshLoading,
    readOnly
}: HeaderProps) => {
    const [oraFilter, setOraFilter] = useState(null);
    const [bankFilter, setBankFilter] = useState(null);

    const oraFilterOptions = [
        { id: 0, label: 'Till Reconciliation', value: 'tillReconciliation' },
        { id: 1, label: 'Manual Transaction', value: 'manualTransaction' },
        { id: 2, label: 'Debtor Payments', value: 'debtorPayment' },
        { id: 3, label: 'Creditor Payrun', value: 'creditorPayrun' },
        { id: 4, label: 'Direct Deposit', value: 'directDeposit' },
        { id: 5, label: 'Used Stock Purchase', value: 'stockPurchase' },
        { id: 6, label: 'Trust Receipt', value: 'trsutReceipt' },
        { id: 7, label: 'Service Warranty', value: 'warranty' },
        { id: 8, label: 'Vehicle Rebate', value: 'rebate' },
        { id: 9, label: 'Stripe Payment', value: 'stripe' }
    ];

    const bankFilterOptions = [
        { id: 0, label: 'Automatic Drawing', value: 'automaticDrawing' },
        { id: 1, label: 'Transfer Debits', value: 'transferDebits' },
        { id: 2, label: 'Transfer Credits', value: 'transferCredits' },
        { id: 3, label: 'Inter-Bank Credit', value: 'interbankCredit' },
        { id: 4, label: 'Credit Adjustment', value: 'creditAdjustment' },
        { id: 5, label: 'Deposit', value: 'deposit' },
        { id: 6, label: 'Flexipay', value: 'flexipay' }
    ];

    useEffect(() => {
        FilterOraTransactionsV2(
            oraFilter,
            reconciliationDetails,
            setReconciliationDetails,
            setSelectedOraUnrecTab,
            setSelectedBankUnrecTab,
            setSelectedOraRecTab,
            setSelectedBankRecTab,
            reconciliationDetailsUnaltered
        );
        // eslint-disable-next-line
    }, [oraFilter]);

    useEffect(() => {
        FilterBankTransactionsV2(
            bankFilter,
            reconciliationDetails,
            setReconciliationDetails,
            setSelectedOraUnrecTab,
            setSelectedBankUnrecTab,
            setSelectedOraRecTab,
            setSelectedBankRecTab,
            reconciliationDetailsUnaltered
        );
        // eslint-disable-next-line
    }, [bankFilter]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} textAlign="center">
                    <Paper>
                        <Typography
                            variant="h6"
                            sx={{
                                color:
                                    CalculateTotalsV2(
                                        reconciliationDetails.oraTransactionsReconciled
                                    ) ===
                                    CalculateTotalsV2(
                                        reconciliationDetails.bankTransactionsReconciled
                                    )
                                        ? 'green'
                                        : 'red'
                            }}
                        >
                            {CurrencyFormatter(
                                CalculateTotalsV2(
                                    reconciliationDetails.oraTransactionsReconciled
                                )
                            )}
                        </Typography>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={11} textAlign="center">
                                <TextField
                                    disabled={refreshLoading}
                                    select
                                    fullWidth
                                    size="small"
                                    label="Ora Filter"
                                    InputLabelProps={{ shrink: true }}
                                    value={oraFilter}
                                    onChange={(e) =>
                                        setOraFilter(e.target.value)
                                    }
                                >
                                    {oraFilterOptions.map((option) => (
                                        <MenuItem value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Clear Filters" placement="top">
                                    <IconButton
                                        disabled={refreshLoading}
                                        onClick={(e) => setOraFilter(null)}
                                    >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={6} textAlign="center">
                    <Paper>
                        <Typography
                            variant="h6"
                            sx={{
                                color:
                                    CalculateTotalsV2(
                                        reconciliationDetails.oraTransactionsReconciled
                                    ) ===
                                    CalculateTotalsV2(
                                        reconciliationDetails.bankTransactionsReconciled
                                    )
                                        ? 'green'
                                        : 'red'
                            }}
                        >
                            {CurrencyFormatter(
                                CalculateTotalsV2(
                                    reconciliationDetails.bankTransactionsReconciled
                                )
                            )}
                        </Typography>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={11} textAlign="center">
                                <TextField
                                    disabled={refreshLoading}
                                    select
                                    fullWidth
                                    size="small"
                                    label="Bank Filter"
                                    InputLabelProps={{ shrink: true }}
                                    value={bankFilter}
                                    onChange={(e) =>
                                        setBankFilter(e.target.value)
                                    }
                                >
                                    {bankFilterOptions.map((option) => (
                                        <MenuItem value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Clear Filters" placement="top">
                                    <IconButton
                                        disabled={refreshLoading}
                                        onClick={(e) => setBankFilter(null)}
                                    >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default HeaderV2;
