import api from '../../../../../../../api';
import { Dayjs } from 'dayjs';
import CalculateParts from './CalculateParts';
import CalculateVehicleSales from './CalculateVehicleSales';
import CalculateService from './CalculateService';

import CalculateRebatesAndExpenses from './CalculateRebatesAndExpenses';
import PrintPNLReport from './PrintPNLReport';
import { Dispatch, SetStateAction } from 'react';
import { Site } from '../../../../../global/interfaces/GeneralInterface';

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};
function ConvertTimestampsToPreviousYear(startDate, endDate) {
    // Get same date range but for last year
    let startLastYearMonth = startDate.clone().subtract(1, 'year');
    let endLastYearMonth = endDate.clone().subtract(1, 'year');

    // Get the end date of the financial year for the startDate
    // It'll be used to know if we are before or after the EOFY
    let financialYearEnd = startDate.clone().month(6).date(1).startOf('day');

    let startYTD;
    let startLastYearYTD;
    let endLastYearYTD;

    if (
        startDate.isBefore(financialYearEnd) &&
        !startDate.isSame(financialYearEnd, 'day')
    ) {
        // YTD is everything for the current financial year. If start date is 01/06/2023, then the YTD date range should be:
        // Start: 01/07/2022
        // End: current end date
        startYTD = startDate
            .clone()
            .subtract(1, 'year')
            .month(6)
            .date(1)
            .startOf('day');

        // Last Year YTD is the previous financial year. If start date is 01/05/2023 and end date 31/05/2023, then the Last Year YTD date range should be:
        // Start: 01/07/2021
        // End: 31/05/2022
        startLastYearYTD = startDate
            .clone()
            .subtract(2, 'year')
            .month(6)
            .date(1)
            .startOf('day');
        endLastYearYTD = endDate.clone().subtract(1, 'year').endOf('day');
    } else {
        // We are after the 1st of July, so the beginning of YTD is in the current year
        startYTD = startDate.clone().month(6).date(1).startOf('day');

        startLastYearYTD = startDate
            .clone()
            .subtract(1, 'year')
            .month(6)
            .date(1)
            .startOf('day');
        endLastYearYTD = endDate.clone().subtract(1, 'year').endOf('day');
    }

    return [
        { startDate: startDate, endDate: endDate },
        { startDate: startYTD, endDate: endDate },
        { startDate: startLastYearMonth, endDate: endLastYearMonth },
        { startDate: startLastYearYTD, endDate: endLastYearYTD }
    ];
}

const getAllInterval = (
    originalStartDate,
    endDate,
    setPeriods: Dispatch<SetStateAction<any>>
) => {
    let intervals = [];

    let done = false;
    let startDate = originalStartDate;
    while (!done) {
        if (startDate.isBefore(endDate)) {
            let endOfMonths = startDate.clone().endOf('month');
            // If the current end of months is after the end date, we went too far and reached the last interval
            if (
                endOfMonths.isAfter(endDate) ||
                endOfMonths.isSame(endDate, 'day')
            ) {
                intervals.push({
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                    vehicleDataCollected: false,
                    paDataCollected: false,
                    serviceDataCollected: false,
                    miscDataCollected: false
                });
                done = true;
            }
            // Otherwise we push in the interval the current month, and update startDate to be the first day of the new month
            else {
                intervals.push({
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endOfMonths.format('YYYY-MM-DD'),
                    vehicleDataCollected: false,
                    paDataCollected: false,
                    serviceDataCollected: false,
                    miscDataCollected: false
                });
                startDate = endOfMonths.clone().add(1, 'day').startOf('month');
            }
        } else {
            done = true;
        }
    }
    setPeriods(intervals);
    return intervals;
};

const CalculatePNLStatement = async (params: {
    queryDate: { startDate: Dayjs; endDate: Dayjs };
    selectedSite: Site[];
    setLoading: Dispatch<SetStateAction<boolean>>;
    currentPeriods: any[];
    setCurrentPeriods: Dispatch<SetStateAction<any[]>>;
    setYtdPeriods: Dispatch<SetStateAction<any[]>>;
    setLastYearPeriods: Dispatch<SetStateAction<any[]>>;
    setLastYearYTDPeriods: Dispatch<SetStateAction<any[]>>;
    setLoadingDialog: Dispatch<SetStateAction<boolean>>;
    setButtonLoading: Dispatch<SetStateAction<boolean>>;
    setPDFLoading: Dispatch<SetStateAction<boolean>>;
}) => {
    params.setLoading(true);
    params.setButtonLoading(true);
    let periods = ConvertTimestampsToPreviousYear(
        params.queryDate.startDate,
        params.queryDate.endDate
    );
    let currentInterval = getAllInterval(
        periods[0].startDate,
        periods[0].endDate,
        params.setCurrentPeriods
    );
    let ytd = getAllInterval(
        periods[1].startDate,
        periods[1].endDate,
        params.setYtdPeriods
    );
    let lastYear = getAllInterval(
        periods[2].startDate,
        periods[2].endDate,
        params.setLastYearPeriods
    );
    let lastYearYtd = getAllInterval(
        periods[3].startDate,
        periods[3].endDate,
        params.setLastYearYTDPeriods
    );
    params.setLoadingDialog(true);

    var vehicleSales = [];
    var orders = [];
    var services = [];
    var registration = [];
    var generalJournal = [];
    var invoices = [];
    var manualTransaction = [];
    var averageCostAdjusts = [];
    var totalStripeFee = 0;
    var siteData = null;
    var settingsData = null;

    var previousMonthVehicleSales = [];
    var previousMonthRegistrationCosts = [];
    var previousMonthOrders = [];
    var previousMonthServices = [];
    var previousMonthAverageCostAdjusts = [];
    var previousMonthManualTransaction = [];
    var previousMonthGeneralJournal = [];
    var previousMonthInvoices = [];
    var previousMonthTotalStripeFee = 0;

    var ytdMonthVehicleSales = [];
    var ytdMonthRegistrationCosts = [];
    var ytdMonthOrders = [];
    var ytdMonthServices = [];
    var ytdMonthAverageCostAdjusts = [];
    var ytdMonthManualTransaction = [];
    var ytdMonthGeneralJournal = [];
    var ytdMonthInvoices = [];
    var ytdMonthTotalStripeFee = 0;

    var lastYearYtdMonthVehicleSales = [];
    var lastYearYtdMonthRegistrationCosts = [];
    var lastYearYtdMonthOrders = [];
    var lastYearYtdMonthServices = [];
    var lastYearYtdMonthAverageCostAdjusts = [];
    var lastYearYtdMonthManualTransaction = [];
    var lastYearYtdMonthGeneralJournal = [];
    var lastYearYtdMonthInvoices = [];
    var lastYearYtdMonthTotalStripeFee = 0;

    let promiseArray = [];
    for await (let [index, interval] of currentInterval.entries()) {
        promiseArray.push(
            api
                .get(
                    `VehicleSalePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    vehicleSales.push(res.data);
                    let currentPeriod = [...currentInterval];
                    currentPeriod[index].vehicleDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `OrderPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    orders.push(res.data);
                    let currentPeriod = [...currentInterval];
                    currentPeriod[index].paDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `ServicePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    services.push(res.data);

                    let currentPeriod = [...currentInterval];
                    currentPeriod[index].serviceDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `MiscPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    registration.push(res.data.registrationCosts);
                    generalJournal.push(res.data.generalJournal);
                    invoices.push(res.data.invoices);
                    manualTransaction.push(res.data.manualTransaction);
                    averageCostAdjusts.push(res.data.averageCostAdjusts);
                    totalStripeFee += res.data.totalStripeFee;
                    siteData = res.data.sites;

                    let currentPeriod = [...currentInterval];
                    currentPeriod[index].miscDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
    }
    for await (let [index, interval] of lastYear.entries()) {
        promiseArray.push(
            api
                .get(
                    `VehicleSalePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    previousMonthVehicleSales.push(res.data);

                    let currentPeriod = [...lastYear];
                    currentPeriod[index].vehicleDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `OrderPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    previousMonthOrders.push(res.data);

                    let currentPeriod = [...lastYear];
                    currentPeriod[index].paDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `ServicePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    previousMonthServices.push(res.data);

                    let currentPeriod = [...lastYear];
                    currentPeriod[index].serviceDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `MiscPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    previousMonthRegistrationCosts.push(
                        res.data.registrationCosts
                    );
                    previousMonthGeneralJournal.push(res.data.generalJournal);
                    previousMonthInvoices.push(res.data.invoices);
                    previousMonthManualTransaction.push(
                        res.data.manualTransaction
                    );
                    previousMonthAverageCostAdjusts.push(
                        res.data.averageCostAdjusts
                    );
                    previousMonthTotalStripeFee += res.data.totalStripeFee;
                    siteData = res.data.sites;
                    settingsData = res.data.settingsData;

                    let currentPeriod = [...lastYear];
                    currentPeriod[index].miscDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
    }
    for await (let [index, interval] of ytd.entries()) {
        promiseArray.push(
            api
                .get(
                    `VehicleSalePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    ytdMonthVehicleSales.push(res.data);

                    let currentPeriod = [...ytd];
                    currentPeriod[index].vehicleDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `OrderPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    ytdMonthOrders.push(res.data);

                    let currentPeriod = [...ytd];
                    currentPeriod[index].paDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `ServicePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    ytdMonthServices.push(res.data);

                    let currentPeriod = [...ytd];
                    currentPeriod[index].serviceDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `MiscPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    ytdMonthRegistrationCosts.push(res.data.registrationCosts);
                    ytdMonthGeneralJournal.push(res.data.generalJournal);
                    ytdMonthInvoices.push(res.data.invoices);
                    ytdMonthManualTransaction.push(res.data.manualTransaction);
                    ytdMonthAverageCostAdjusts.push(
                        res.data.averageCostAdjusts
                    );
                    ytdMonthTotalStripeFee += res.data.totalStripeFee;
                    siteData = res.data.sites;

                    let currentPeriod = [...ytd];
                    currentPeriod[index].miscDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
    }
    for await (let [index, interval] of lastYearYtd.entries()) {
        promiseArray.push(
            api
                .get(
                    `VehicleSalePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    lastYearYtdMonthVehicleSales.push(res.data);

                    let currentPeriod = [...lastYearYtd];
                    currentPeriod[index].vehicleDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `OrderPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    lastYearYtdMonthOrders.push(res.data);

                    let currentPeriod = [...lastYearYtd];
                    currentPeriod[index].paDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `ServicePNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    lastYearYtdMonthServices.push(res.data);

                    let currentPeriod = [...lastYearYtd];
                    currentPeriod[index].serviceDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
        promiseArray.push(
            api
                .get(
                    `MiscPNLReportingNextGen?statement=false&startDate=${interval.startDate}&endDate=${interval.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
                )
                // eslint-disable-next-line
                .then(async (res) => {
                    lastYearYtdMonthRegistrationCosts.push(
                        res.data.registrationCosts
                    );
                    lastYearYtdMonthGeneralJournal.push(
                        res.data.generalJournal
                    );
                    lastYearYtdMonthInvoices.push(res.data.invoices);
                    lastYearYtdMonthManualTransaction.push(
                        res.data.manualTransaction
                    );
                    lastYearYtdMonthAverageCostAdjusts.push(
                        res.data.averageCostAdjusts
                    );
                    lastYearYtdMonthTotalStripeFee += res.data.totalStripeFee;
                    siteData = res.data.sites;

                    let currentPeriod = [...lastYearYtd];
                    currentPeriod[index].miscDataCollected = true;
                    params.setCurrentPeriods(currentPeriod);
                })
        );
    }

    Promise.all(promiseArray).then(() => {
        params.setLoadingDialog(false);
        params.setPDFLoading(true);
        vehicleSales = vehicleSales.flat();
        orders = orders.flat();
        services = services.flat();
        registration = registration.flat();
        generalJournal = generalJournal.flat();
        invoices = invoices.flat();
        manualTransaction = manualTransaction.flat();
        averageCostAdjusts = averageCostAdjusts.flat();

        previousMonthVehicleSales = previousMonthVehicleSales.flat();
        previousMonthOrders = previousMonthOrders.flat();
        previousMonthServices = previousMonthServices.flat();
        previousMonthRegistrationCosts = previousMonthRegistrationCosts.flat();
        previousMonthGeneralJournal = previousMonthGeneralJournal.flat();
        previousMonthInvoices = previousMonthInvoices.flat();
        previousMonthManualTransaction = previousMonthManualTransaction.flat();
        previousMonthAverageCostAdjusts =
            previousMonthAverageCostAdjusts.flat();

        ytdMonthVehicleSales = ytdMonthVehicleSales.flat();
        ytdMonthOrders = ytdMonthOrders.flat();
        ytdMonthServices = ytdMonthServices.flat();
        ytdMonthRegistrationCosts = ytdMonthRegistrationCosts.flat();
        ytdMonthGeneralJournal = ytdMonthGeneralJournal.flat();
        ytdMonthInvoices = ytdMonthInvoices.flat();
        ytdMonthManualTransaction = ytdMonthManualTransaction.flat();
        ytdMonthAverageCostAdjusts = ytdMonthAverageCostAdjusts.flat();

        lastYearYtdMonthVehicleSales = lastYearYtdMonthVehicleSales.flat();
        lastYearYtdMonthOrders = lastYearYtdMonthOrders.flat();
        lastYearYtdMonthServices = lastYearYtdMonthServices.flat();
        lastYearYtdMonthRegistrationCosts =
            lastYearYtdMonthRegistrationCosts.flat();
        lastYearYtdMonthGeneralJournal = lastYearYtdMonthGeneralJournal.flat();
        lastYearYtdMonthInvoices = lastYearYtdMonthInvoices.flat();
        lastYearYtdMonthManualTransaction =
            lastYearYtdMonthManualTransaction.flat();
        lastYearYtdMonthAverageCostAdjusts =
            lastYearYtdMonthAverageCostAdjusts.flat();

        let vehicleValues = CalculateVehicleSales(
            vehicleSales,
            registration,
            siteData,
            null,
            null,
            null
        );
        let partValues = CalculateParts(orders);
        let serviceValues = CalculateService(services);

        let previousVehicleValues = CalculateVehicleSales(
            previousMonthVehicleSales,
            previousMonthRegistrationCosts,
            siteData,
            null,
            null,
            null
        );
        let previousPartValues = CalculateParts(previousMonthOrders);
        let previousServiceValues = CalculateService(previousMonthServices);

        let ytdMonthVehicleValues = CalculateVehicleSales(
            ytdMonthVehicleSales,
            ytdMonthRegistrationCosts,
            siteData,
            null,
            null,
            null
        );
        let ytdMonthPartValues = CalculateParts(ytdMonthOrders);
        let ytdMonthServiceValues = CalculateService(ytdMonthServices);

        let lastYearYtdMonthVehicleValues = CalculateVehicleSales(
            lastYearYtdMonthVehicleSales,
            lastYearYtdMonthRegistrationCosts,
            siteData,
            null,
            null,
            null
        );
        let lastYearYtdMonthPartValues = CalculateParts(lastYearYtdMonthOrders);
        let lastYearYtdMonthServiceValues = CalculateService(
            lastYearYtdMonthServices
        );

        let [rebates, expenses] = CalculateRebatesAndExpenses(
            averageCostAdjusts,
            manualTransaction,
            generalJournal,
            invoices,
            totalStripeFee,
            params.selectedSite
        );
        let [previousRebates, previousExpenses] = CalculateRebatesAndExpenses(
            previousMonthAverageCostAdjusts,
            previousMonthManualTransaction,
            previousMonthGeneralJournal,
            previousMonthInvoices,
            previousMonthTotalStripeFee,
            params.selectedSite
        );
        let [ytdMonthRebates, ytdMonthExpenses] = CalculateRebatesAndExpenses(
            ytdMonthAverageCostAdjusts,
            ytdMonthManualTransaction,
            ytdMonthGeneralJournal,
            ytdMonthInvoices,
            ytdMonthTotalStripeFee,
            params.selectedSite
        );
        let [lastYearYtdMonthRebates, lastYearYtdMonthExpenses] =
            CalculateRebatesAndExpenses(
                lastYearYtdMonthAverageCostAdjusts,
                lastYearYtdMonthManualTransaction,
                lastYearYtdMonthGeneralJournal,
                lastYearYtdMonthInvoices,
                lastYearYtdMonthTotalStripeFee,
                params.selectedSite
            );

        let vehicleSellingExpenses = groupBy(
            groupBy(expenses, 'accountCategory')['Vehicle Selling Expenses'] ??
                [],
            'accountName'
        );
        let employmentExpenses = groupBy(
            groupBy(expenses, 'accountCategory')['Employment Expenses'] ?? [],
            'accountName'
        );
        let generalExpenses = groupBy(
            groupBy(expenses, 'accountCategory')['General Expenses'] ?? [],
            'accountName'
        );
        let indirectExpenses = groupBy(
            groupBy(expenses, 'accountCategory')['Indirect Expenses'] ?? [],
            'accountName'
        );
        let otherDirectExpenses = groupBy(
            groupBy(expenses, 'accountCategory')['Other Direct Expenses'] ?? [],
            'accountName'
        );
        let otherIncome = groupBy(
            groupBy(rebates, 'accountCategory')['Other Income'] ?? [],
            'accountName'
        );
        let directorsEmoluments = groupBy(
            groupBy(expenses, 'accountCategory')['Directors Emoluments'] ?? [],
            'accountName'
        );
        let nonOperatingExpenses = groupBy(
            groupBy(expenses, 'accountCategory')['Non Operating Expenses'] ??
                [],
            'accountName'
        );

        let previousVehicleSellingExpenses = groupBy(
            groupBy(previousExpenses, 'accountCategory')[
                'Vehicle Selling Expenses'
            ] ?? [],
            'accountName'
        );
        let previousEmploymentExpenses = groupBy(
            groupBy(previousExpenses, 'accountCategory')[
                'Employment Expenses'
            ] ?? [],
            'accountName'
        );
        let previousGeneralExpenses = groupBy(
            groupBy(previousExpenses, 'accountCategory')['General Expenses'] ??
                [],
            'accountName'
        );
        let previousIndirectExpenses = groupBy(
            groupBy(previousExpenses, 'accountCategory')['Indirect Expenses'] ??
                [],
            'accountName'
        );
        let previousOtherDirectExpenses = groupBy(
            groupBy(previousExpenses, 'accountCategory')[
                'Other Direct Expenses'
            ] ?? [],
            'accountName'
        );
        let previousOtherIncome = groupBy(
            groupBy(previousRebates, 'accountCategory')['Other Income'] ?? [],
            'accountName'
        );
        let previousDirectorsEmoluments = groupBy(
            groupBy(previousExpenses, 'accountCategory')[
                'Directors Emoluments'
            ] ?? [],
            'accountName'
        );
        let previousNonOperatingExpenses = groupBy(
            groupBy(previousExpenses, 'accountCategory')[
                'Non Operating Expenses'
            ] ?? [],
            'accountName'
        );

        let ytdMonthVehicleSellingExpenses = groupBy(
            groupBy(ytdMonthExpenses, 'accountCategory')[
                'Vehicle Selling Expenses'
            ] ?? [],
            'accountName'
        );
        let ytdMonthEmploymentExpenses = groupBy(
            groupBy(ytdMonthExpenses, 'accountCategory')[
                'Employment Expenses'
            ] ?? [],
            'accountName'
        );
        let ytdMonthGeneralExpenses = groupBy(
            groupBy(ytdMonthExpenses, 'accountCategory')['General Expenses'] ??
                [],
            'accountName'
        );
        let ytdMonthIndirectExpenses = groupBy(
            groupBy(ytdMonthExpenses, 'accountCategory')['Indirect Expenses'] ??
                [],
            'accountName'
        );
        let ytdMonthOtherDirectExpenses = groupBy(
            groupBy(ytdMonthExpenses, 'accountCategory')[
                'Other Direct Expenses'
            ] ?? [],
            'accountName'
        );
        let ytdMonthOtherIncome = groupBy(
            groupBy(ytdMonthRebates, 'accountCategory')['Other Income'] ?? [],
            'accountName'
        );
        let ytdMonthDirectorsEmoluments = groupBy(
            groupBy(ytdMonthExpenses, 'accountCategory')[
                'Directors Emoluments'
            ] ?? [],
            'accountName'
        );
        let ytdMonthNonOperatingExpenses = groupBy(
            groupBy(ytdMonthExpenses, 'accountCategory')[
                'Non Operating Expenses'
            ] ?? [],
            'accountName'
        );

        let lastYearYtdMonthVehicleSellingExpenses = groupBy(
            groupBy(lastYearYtdMonthExpenses, 'accountCategory')[
                'Vehicle Selling Expenses'
            ] ?? [],
            'accountName'
        );
        let lastYearYtdMonthEmploymentExpenses = groupBy(
            groupBy(lastYearYtdMonthExpenses, 'accountCategory')[
                'Employment Expenses'
            ] ?? [],
            'accountName'
        );
        let lastYearYtdMonthGeneralExpenses = groupBy(
            groupBy(lastYearYtdMonthExpenses, 'accountCategory')[
                'General Expenses'
            ] ?? [],
            'accountName'
        );
        let lastYearYtdMonthIndirectExpenses = groupBy(
            groupBy(lastYearYtdMonthExpenses, 'accountCategory')[
                'Indirect Expenses'
            ] ?? [],
            'accountName'
        );
        let lastYearYtdMonthOtherDirectExpenses = groupBy(
            groupBy(lastYearYtdMonthExpenses, 'accountCategory')[
                'Other Direct Expenses'
            ] ?? [],
            'accountName'
        );
        let lastYearYtdMonthOtherIncome = groupBy(
            groupBy(lastYearYtdMonthRebates, 'accountCategory')[
                'Other Income'
            ] ?? [],
            'accountName'
        );
        let lastYearYtdMonthDirectorsEmoluments = groupBy(
            groupBy(lastYearYtdMonthExpenses, 'accountCategory')[
                'Directors Emoluments'
            ] ?? [],
            'accountName'
        );
        let lastYearYtdMonthNonOperatingExpenses = groupBy(
            groupBy(lastYearYtdMonthExpenses, 'accountCategory')[
                'Non Operating Expenses'
            ] ?? [],
            'accountName'
        );

        PrintPNLReport({
            queryDate: params.queryDate,
            selectedSite: params.selectedSite,
            siteData: siteData,
            settingData: settingsData,

            vehicleSellingExpenses: vehicleSellingExpenses,
            employmentExpenses: employmentExpenses,
            generalExpenses: generalExpenses,
            indirectExpenses: indirectExpenses,
            otherDirectExpenses: otherDirectExpenses,
            otherIncome: otherIncome,
            directorsEmoluments: directorsEmoluments,
            nonOperatingExpenses: nonOperatingExpenses,

            previousVehicleSellingExpenses: previousVehicleSellingExpenses,
            previousEmploymentExpenses: previousEmploymentExpenses,
            previousGeneralExpenses: previousGeneralExpenses,
            previousIndirectExpenses: previousIndirectExpenses,
            previousOtherDirectExpenses: previousOtherDirectExpenses,
            previousOtherIncome: previousOtherIncome,
            previousDirectorsEmoluments: previousDirectorsEmoluments,
            previousNonOperatingExpenses: previousNonOperatingExpenses,

            ytdMonthVehicleSellingExpenses: ytdMonthVehicleSellingExpenses,
            ytdMonthEmploymentExpenses: ytdMonthEmploymentExpenses,
            ytdMonthGeneralExpenses: ytdMonthGeneralExpenses,
            ytdMonthIndirectExpenses: ytdMonthIndirectExpenses,
            ytdMonthOtherDirectExpenses: ytdMonthOtherDirectExpenses,
            ytdMonthOtherIncome: ytdMonthOtherIncome,
            ytdMonthDirectorsEmoluments: ytdMonthDirectorsEmoluments,
            ytdMonthNonOperatingExpenses: ytdMonthNonOperatingExpenses,

            lastYearYtdMonthVehicleSellingExpenses:
                lastYearYtdMonthVehicleSellingExpenses,
            lastYearYtdMonthEmploymentExpenses:
                lastYearYtdMonthEmploymentExpenses,
            lastYearYtdMonthGeneralExpenses: lastYearYtdMonthGeneralExpenses,
            lastYearYtdMonthIndirectExpenses: lastYearYtdMonthIndirectExpenses,
            lastYearYtdMonthOtherDirectExpenses:
                lastYearYtdMonthOtherDirectExpenses,
            lastYearYtdMonthOtherIncome: lastYearYtdMonthOtherIncome,
            lastYearYtdMonthDirectorsEmoluments:
                lastYearYtdMonthDirectorsEmoluments,
            lastYearYtdMonthNonOperatingExpenses:
                lastYearYtdMonthNonOperatingExpenses,

            vehicleValues: vehicleValues,
            previousVehicleValues: previousVehicleValues,
            ytdMonthVehicleValues: ytdMonthVehicleValues,
            lastYearYtdMonthVehicleValues: lastYearYtdMonthVehicleValues,

            partValues: partValues,
            previousPartValues: previousPartValues,
            ytdMonthPartValues: ytdMonthPartValues,
            lastYearYtdMonthPartValues: lastYearYtdMonthPartValues,

            serviceValues: serviceValues,
            previousServiceValues: previousServiceValues,
            ytdMonthServiceValues: ytdMonthServiceValues,
            lastYearYtdMonthServiceValues: lastYearYtdMonthServiceValues
        });
        params.setLoading(false);
        params.setButtonLoading(false);
        params.setPDFLoading(false);
    });
};

export default CalculatePNLStatement;
