// IMPORTS
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
    Table,
    TableRow,
    TableCell,
    TextField,
    Typography
} from '@mui/material';

// LGOIC
import GetStockDetails from '../../logic/GetStockDetails';

// INTERFACES
import {
    Order,
    StockOrder,
    StockTransfer,
    Unit
} from '../../../../global/interfaces/PartsInterface';
import { CreditInvoice } from '../../../../global/interfaces/AdminInterface';

interface StockDetails {
    minStock: number;
    maxStock: number;
}

interface TempUnit extends Unit {
    reservedData: Order[];
    transferData: StockTransfer[];
    incomingData: StockOrder[];
    creditedData: CreditInvoice[];
}

interface StockTableProps {
    partDetails: TempUnit;
    readOnly: boolean;
    selectedSite: number;
    stockDetails: StockDetails;
    setStockDetails: Dispatch<SetStateAction<StockDetails>>;
    setOpenDrawer: Dispatch<SetStateAction<boolean>>;
    setTitle: Dispatch<SetStateAction<string>>;
}

const StockTable = ({
    partDetails,
    selectedSite,
    readOnly,
    stockDetails,
    setStockDetails,
    setOpenDrawer,
    setTitle
}: StockTableProps) => {
    // Get the stock values for the sites
    const getSiteStock = (siteType: string) => {
        if (partDetails && partDetails?.Stock?.length > 0 && selectedSite) {
            if (siteType === 'current') {
                let siteAverageCost = partDetails?.Stock.filter(
                    (x) => x.SiteId === selectedSite
                );
                return siteAverageCost[0].qtyOnHand;
            } else if (siteType === 'other') {
                let siteAverageCost = partDetails?.Stock.filter(
                    (x) => x.SiteId !== selectedSite
                );
                let total = 0;
                siteAverageCost.forEach((value: { qtyOnHand: number }) => {
                    total += value.qtyOnHand;
                });
                return total;
            } else {
                let siteAverageCost = partDetails?.Stock.filter(
                    (x) => x.SiteId === selectedSite
                );
                return siteAverageCost[0];
            }
        } else {
            return {
                qtyOnHand: 0,
                qtyReserved: 0,
                qtyIncoming: 0,
                qtyReturned: 0,
                qtyTransferIn: 0,
                qtyTransferOut: 0,
                supplier: 0,
                onlineReserved: 0,
                minStock: 0,
                maxStock: 0
            };
        }
    };

    // Get the total stock across all sites
    const getAllSiteStock = () => {
        if (partDetails && partDetails?.Stock?.length > 0 && selectedSite) {
            let siteAverageCost = partDetails?.Stock.filter(
                (x) => x.SiteId === selectedSite
            );
            return siteAverageCost[0];
        } else {
            return {
                qtyOnHand: 0,
                qtyReserved: 0,
                qtyIncoming: 0,
                qtyReturned: 0,
                qtyTransferIn: 0,
                qtyTransferOut: 0,
                supplier: 0,
                onlineReserved: 0,
                minStock: 0,
                maxStock: 0
            };
        }
    };

    // Labels and values for the stock rows
    const [stockLabels, setStockLabels] = useState([
        { id: 1, label: 'In Bin', value: getAllSiteStock().qtyOnHand },
        {
            id: 2,
            label: 'Reserved',
            value: getAllSiteStock().qtyReserved,
            clickable: 'reserved'
        },
        {
            id: 3,
            label: 'Incoming',
            value: getAllSiteStock().qtyIncoming,
            clickable: 'incoming'
        },
        {
            id: 4,
            label: 'Returned',
            value: getAllSiteStock().qtyReturned,
            clickable: 'returned'
        },
        {
            id: 5,
            label: 'Transfer In',
            value: getAllSiteStock().qtyTransferIn,
            clickable: 'transferIn'
        },
        {
            id: 6,
            label: 'Transfer Out',
            value: getAllSiteStock().qtyTransferOut,
            clickable: 'transferOut'
        },
        { id: 7, label: 'Supplier', value: getAllSiteStock().supplier },
        {
            id: 8,
            label: 'Online Reserved',
            value: getAllSiteStock().onlineReserved
        },
        {
            id: 9,
            label: 'Stock Min',
            value: getAllSiteStock().minStock,
            edit: true,
            name: 'minStock'
        },
        {
            id: 10,
            label: 'Stock Max',
            value: getAllSiteStock().maxStock,
            edit: true,
            name: 'maxStock'
        }
    ]);

    // Update the state everytime we change parts or site
    useEffect(() => {
        setStockLabels([
            { id: 1, label: 'In Bin', value: getAllSiteStock().qtyOnHand },
            {
                id: 2,
                label: 'Reserved',
                value: getAllSiteStock().qtyReserved,
                clickable: 'reserved'
            },
            {
                id: 3,
                label: 'Incoming',
                value: getAllSiteStock().qtyIncoming,
                clickable: 'incoming'
            },
            {
                id: 4,
                label: 'Returned',
                value: getAllSiteStock().qtyReturned,
                clickable: 'returned'
            },
            {
                id: 5,
                label: 'Transfer In',
                value: getAllSiteStock().qtyTransferIn,
                clickable: 'transferIn'
            },
            {
                id: 6,
                label: 'Transfer Out',
                value: getAllSiteStock().qtyTransferOut,
                clickable: 'transferOut'
            },
            { id: 7, label: 'Supplier', value: getAllSiteStock().supplier },
            {
                id: 8,
                label: 'Online Reserved',
                value: getAllSiteStock().onlineReserved
            },
            {
                id: 9,
                label: 'Stock Min',
                value: getAllSiteStock().minStock,
                edit: true,
                name: 'minStock'
            },
            {
                id: 10,
                label: 'Stock Max',
                value: getAllSiteStock().maxStock,
                edit: true,
                name: 'maxStock'
            }
        ]);
        // eslint-disable-next-line
    }, [partDetails, selectedSite]);

    return (
        <>
            <Table>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant="h6">{`Stock On Hand: ${getSiteStock('current')} | Other Site: ${getSiteStock('other')}`}</Typography>
                    </TableCell>
                </TableRow>
                {stockLabels?.map((row, index) => (
                    <TableRow>
                        <TableCell
                            id={row.clickable ? 'table-link' : ''}
                            onClick={() => {
                                switch (row.clickable) {
                                    case 'reserved':
                                        setTitle('Reserved');
                                        break;
                                    case 'incoming':
                                        setTitle('Incoming');
                                        break;
                                    case 'returned':
                                        setTitle('Returned');
                                        break;
                                    case 'transferIn':
                                        setTitle('Transfer In');
                                        break;
                                    case 'transferOut':
                                        setTitle('Transfer Out');
                                        break;
                                }
                            }}
                        >
                            {row.label}
                        </TableCell>
                        <TableCell>
                            {!row.edit || readOnly ? (
                                // <TextField fullWidth size="small" variant={"standard"} InputProps={{ disableUnderline: true }} disabled value={row.value ?? 0} />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant={'standard'}
                                    InputProps={{ disableUnderline: true }}
                                    disabled
                                    value={GetStockDetails(
                                        row.label,
                                        partDetails,
                                        selectedSite
                                    )}
                                />
                            ) : (
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant={'outlined'}
                                    value={row.value ?? 0}
                                    onChange={(e) => {
                                        setStockDetails({
                                            ...stockDetails,
                                            [row.name]: parseInt(e.target.value)
                                        });
                                        let tempStockLabels = JSON.parse(
                                            JSON.stringify(stockLabels)
                                        );
                                        tempStockLabels[index].value = parseInt(
                                            e.target.value
                                        );
                                        setStockLabels(tempStockLabels);
                                    }}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </>
    );
};

export default StockTable;
