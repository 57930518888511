import { TableRow } from '@mui/material';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../../global/logic/Formatters';
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';

interface Transaction {
    id?: string;
    url?: string;
    date?: string;
    reference?: string;
    user?: string;
    amount?: number;
    invoiceDate?: string;
    reconciled?: boolean;
    paymentHistoryFullyPaid?: boolean;
    paymentHistoryReconciled?: boolean;
}

const columns = [
    { id: 0, label: 'ID' },
    { id: 1, label: 'Date' },
    { id: 2, label: 'Reference' },
    { id: 3, label: 'User/Customer' },
    { id: 4, label: 'Amount' }
];

const AccountTransactionTable = ({selectedAccount}) => {
    return (
        <DataTable columns={columns}>
            {selectedAccount?.transactions?.map((transaction: Transaction) => (
                <TableRow>
                    {transaction.url ? (
                        <DataCellColoured
                            handleClick={() =>
                                window.open(transaction.url, '_blank')
                            }
                        >
                            {transaction.id}
                        </DataCellColoured>
                    ) : (
                        <DataCell>{transaction.id}</DataCell>
                    )}
                    <DataCell>
                        {transaction.invoiceDate
                            ? DateFormatter(transaction.invoiceDate)
                            : DateFormatter(transaction.date)}
                    </DataCell>
                    <DataCell>{transaction.reference}</DataCell>
                    <DataCell>{transaction.user}</DataCell>
                    <DataCell>
                        {CurrencyFormatter(-transaction.amount)}
                    </DataCell>
                    {transaction.id.includes('_MT') &&
                    transaction.reconciled ? (
                        <DataCell>
                            <CheckCircleOutline
                                sx={{
                                    color: 'green'
                                }}
                            />
                        </DataCell>
                    ) : transaction.id.includes('_SI') &&
                      transaction.reconciled ? (
                        <DataCell>
                            <CheckCircleOutline
                                sx={{
                                    color: 'green'
                                }}
                            />
                        </DataCell>
                    ) : selectedAccount &&
                      selectedAccount?.transactions?.filter((x) => x.reconciled)
                          .length > 0 ? (
                        <DataCell>
                            <CancelOutlined
                                sx={{
                                    color: 'red'
                                }}
                            />
                        </DataCell>
                    ) : null}
                </TableRow>
            ))}
            <TableRow>
                <DataCell colSpan={3}></DataCell>
                <DataCell>
                    <b>TOTAL</b>
                </DataCell>
                {console.log(selectedAccount)}
                <DataCell>
                    <b>
                        {CurrencyFormatter(
                            selectedAccount.type.includes('Liability') &&
                                !Number.isNaN(selectedAccount.initialBalance)
                                ? selectedAccount.initialBalance -
                                      selectedAccount.accountTotal
                                : selectedAccount.type.includes('Asset') &&
                                    !Number.isNaN(
                                        selectedAccount.initialBalance
                                    )
                                  ? selectedAccount.initialBalance - // Is now a subtraction because James looks online and : "Debit for an Asset is making the account go up", debit are negative in the DB
                                    selectedAccount.accountTotal
                                  : selectedAccount.accountTotal
                        )}
                    </b>
                </DataCell>
                {selectedAccount &&
                selectedAccount?.transactions?.filter((x) => x.reconciled)
                    .length > 0 ? (
                    <DataCell></DataCell>
                ) : null}
            </TableRow>
        </DataTable>
    );
};

export default AccountTransactionTable;
