import { Typography } from '@mui/material';
import Tabs from '../../global/Tabs';
import DailyTaskListTabContent from './DailyTaskList';
import DailyTaskSettingsTabContent from './DailyTaskSettings';
import RoleCheck from '../../global/RoleCheck';
import { useEffect, useState } from 'react';
import { User } from '../../global/interfaces/GeneralInterface';
import GetUser from '../../global/databaseLogic/GetUser';

const DailyTaskPage = () => {
    const currentSiteId = localStorage.getItem('SiteId');
    const [currentUser, setCurrentUser] = useState<User>();

    useEffect(() => {
        GetUser(0, setCurrentUser);
    }, []);

    const dailyTaskListTab = {
        id: 0,
        title: 'Daily Task ',
        content: <DailyTaskListTabContent />
    };

    const dailyTaskSettingsTab = {
        id: 1,
        title: 'Daily Task Settings',
        content: (
            <RoleCheck
                permission={
                    'admin_manager,it_manager,parts_manager,sales_manager,service_manager'
                }
                component={<DailyTaskSettingsTabContent />}
                pageError
            />
        )
    };

    const tabContent =
        currentUser && currentUser.SiteId === parseInt(currentSiteId)
            ? [dailyTaskListTab, dailyTaskSettingsTab]
            : [];

    return (
        <div id="table">
            <br />
            <Typography variant="h4" align="left">
                Daily Tasks
            </Typography>
            <br />
            {tabContent.length > 0 ? (
                <RoleCheck
                    permission={
                        'admin_manager,it_manager,parts_manager,sales_manager,service_manager'
                    }
                    component={<Tabs tabContent={tabContent} />}
                    nullComponent={<DailyTaskListTabContent />}
                    pageError
                />
            ) : (
                <Typography>
                    Please switch to the {currentUser?.Site?.name} site to view
                    your daily tasks.
                </Typography>
            )}
        </div>
    );
};

export default DailyTaskPage;
