// IMPORTS
import { useEffect, useRef, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    DialogTitle
} from '@mui/material';
import { useParams, Prompt } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Tabs from '../../global/Tabs';
import SelectCustomer from './components/SelectCustomer';
import Orderlines from './components/Orderlines';
import Payment from './components/Payment';
import SpeedDial from '../../global/SpeedDial';
import SpeedDialList from './components/SpeedDialList';
import NotesDialogContent from './components/NotesDialogContent';
import Drawer from '../../global/Drawer';
import TextMessageDrawerContent from '../../global/TextMessageDrawerContent';
import LogsDrawerContent from './components/LogsDrawerContent';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import StockTransferFromOrderContentDrawer from './components/StockTransferFromOrderContentDrawer';
import Paper from '../../global/Paper';
import PageDoesNotExist from '../../global/PageDoesNotExist';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import PDFInvoice from './logic/PDFInvoice';
import GetSaleDetails from './logic/GetSaleDetails';
import HandleSubmitSale from './logic/submitFunctions/HandleSubmitSale';
import HasBackorder from './logic/HasBackorder';
import HandleUpdateSale from './logic/updateFunctions/HandleUpdateSale';
import HandleDeleteSale from './logic/HandleDeleteSale';
import { PaymentTotal, SaleTotal } from './logic/CalculateTotals';
import UpdateExtraCostsOnStatusChange from './logic/UpdateExtraCostsOnStatusChange';
// INTERFACES
import { PaymentLine } from '../../global/interfaces/GeneralInterface';
import {
    OnlineOrder,
    Order,
    StockOrder,
    StockTransfer
} from '../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { VehicleSale } from '../../global/interfaces/VehicleSaleInterface';

const NewSale = ({
    showSnackbar,
    vehicleSaleId,
    vehicleSaleDetails
}: {
    showSnackbar: showSnackbar;
    vehicleSaleId: number;
    vehicleSaleDetails: VehicleSale;
}) => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState(false);

    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [selectedSite, setSelectedSite] = useState<number>(
        parseInt(localStorage.getItem('SiteId'))
    );
    const [saleDetails, setSaleDetails] = useState<Order>({
        orderLines: [],
        totalPrice: 0,
        totalCost: 0,
        totalMargin: 0,
        PONumber: null,
        status: 'Sale',
        notes: null,
        customerNotes: null,
        displayRRP: false,
        paymentLines: []
    });

    const [selectedType, setSelectedType] = useState<string>('customer');
    const [selectedEntity, setSelectedEntity] = useState<any>(null);
    const [associatedOnlineOrder, setAssociatedOnlineOrder] =
        useState<OnlineOrder>({});
    const [extraCosts, setExtraCosts] = useState<
        {
            id: number;
            type: string;
            amount: string;
            onlineVoucherCode?: string;
            VoucherId?: number;
            code?: string;
        }[]
    >([]);
    const [paymentLines, setPaymentLines] = useState<PaymentLine[]>([]);
    const [notesDialogOpen, setNotesDialogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [textDrawerOpen, setTextDrawerOpen] = useState<boolean>(false);
    const [logsDrawerOpen, setLogsDrawerOpen] = useState<boolean>(false);
    const [stockOrders, setStockOrders] = useState<StockOrder[]>([]);

    // Integrated Stock Transfer Drawer
    const [stockTransferDrawerOpen, setStockTransferDrawerOpen] =
        useState<boolean>(false);
    const [stockTransferData, setStockTransferData] = useState<StockTransfer>(
        {}
    );
    const [invalid, setInvalid] = useState<boolean>(false);

    const [oldSale, setOldSale] = useState<Order>({
        orderLines: [],
        totalPrice: 0,
        totalCost: 0,
        totalMargin: 0,
        PONumber: null,
        status: 'Proforma',
        notes: null,
        customerNotes: null,
        displayRRP: false,
        paymentLines: []
    });

    useEffect(() => {
        if (id && (vehicleSaleId === null || vehicleSaleId === undefined)) {
            GetSaleDetails(
                parseInt(id),
                setSaleDetails,
                setOldSale,
                setSelectedType,
                setSelectedEntity,
                setAssociatedOnlineOrder,
                setExtraCosts,
                setPaymentLines,
                setStockOrders,
                setLoading,
                setReadOnly,
                showSnackbar,
                setInvalid,
                setSelectedSite
            );
        } else if (vehicleSaleId) {
            setSelectedType('contract');
            setSelectedEntity(vehicleSaleDetails);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (!id && selectedType === 'contract') {
            setSaleDetails({ ...saleDetails, status: 'Proforma' });
        } else if (vehicleSaleId) {
            setSaleDetails({ ...saleDetails, status: 'Proforma' });
        }
        if (selectedType === 'guest' && saleDetails.status !== 'Sale') {
            setSaleDetails({ ...saleDetails, status: 'Sale' });
        }
        // eslint-disable-next-line
    }, [selectedType]);

    useEffect(() => {
        setSaleDetails({ ...saleDetails, SiteId: selectedSite });
        // eslint-disable-next-line
    }, [selectedSite]);

    useEffect(() => {
        let hasBackorder = HasBackorder(saleDetails);
        if (hasBackorder && saleDetails.status === 'Sale') {
            setSaleDetails({ ...saleDetails, status: 'Proforma' });
        }
        // eslint-disable-next-line
    }, [saleDetails.orderLines]);

    //Watch changes in saleDetails.status, only when it is Sale, it can have Voucher record in ExtraCosts
    //When creating new sale, if change status from Sale to Performa/Quote, clear all vouchers in extraCosts
    const preStatusRef = useRef(saleDetails.status);
    useEffect(() => {
        if (
            !id &&
            preStatusRef.current === 'Sale' &&
            saleDetails.status !== 'Sale' &&
            extraCosts.length > 0
        ) {
            UpdateExtraCostsOnStatusChange({
                status: saleDetails.status,
                extraCosts,
                setExtraCosts,
                showSnackbar
            });
        }
        // eslint-disable-next-line
    }, [saleDetails.status]);

    // Watch changes in the paymentLines when it's a proforma.
    // If the total payment is enough to cover the amount, show a snackbar to suggest turning it into a sale
    useEffect(() => {
        if (saleDetails.status === 'Proforma') {
            let saleTotal = SaleTotal(saleDetails, extraCosts);
            let paymentTotal = PaymentTotal(paymentLines);

            let isBackorderPresent = saleDetails.orderLines.some(
                (x) => parseInt(x.backorderQuantity) > 0
            );

            if (
                paymentTotal >= saleTotal &&
                paymentTotal > 0 &&
                !isBackorderPresent
            ) {
                showSnackbar(
                    'This Proforma has no open backorders and has been paid in full.',
                    'If the customer has collected all the parts, please consider turning this Proforma into a Sale.',
                    'info'
                );
            }
        }
        // eslint-disable-next-line
    }, [paymentLines, saleDetails]);

    // Handles submitting a sale
    const handleSubmitSale = () => {
        if (id && (vehicleSaleId === null || vehicleSaleId === undefined)) {
            HandleUpdateSale(
                parseInt(id),
                selectedType,
                selectedEntity,
                saleDetails,
                extraCosts,
                paymentLines,
                associatedOnlineOrder,
                handlePrintInvoice,
                showSnackbar
            );
        } else {
            HandleSubmitSale(
                selectedType,
                selectedEntity,
                saleDetails,
                extraCosts,
                paymentLines,
                associatedOnlineOrder,
                handlePrintInvoice,
                showSnackbar
            );
        }
    };

    const handlePrintInvoice = (
        hasCustomer: boolean,
        saleData: Order,
        redirectId?: number | boolean,
        vouchers?: any[]
    ) => {
        let customerData =
            selectedType === 'customer' || selectedType === 'online'
                ? selectedEntity
                : selectedEntity?.Customer;
        let SiteId = id
            ? saleDetails.SiteId
            : parseInt(localStorage.getItem('SiteId'));
        PDFInvoice(
            SiteId,
            hasCustomer,
            paymentLines,
            saleData,
            customerData,
            extraCosts,
            redirectId,
            vouchers
        ).then((returnedValue) => {
            if (returnedValue) {
                if (redirectId) {
                    window.location.href = '/inventory/sales';
                } else {
                    window.location.reload();
                }
            } else {
                showSnackbar(
                    "Order was successfully submitted, but the invoice couldn't be generated",
                    'Try refreshing the page and use the print invoice button.',
                    'warning'
                );
            }
        });
    };

    let customerTab = {
        id: 0,
        cypressLabel: 'selectCustomerTab',
        title: 'Select Customer',
        content: (
            <>
                <SelectCustomer
                    readOnly={readOnly}
                    saleDetails={saleDetails}
                    setSaleDetails={setSaleDetails}
                    oldSale={oldSale}
                    selectedEntity={selectedEntity}
                    setSelectedEntity={setSelectedEntity}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    associatedOnlineOrder={associatedOnlineOrder}
                    setAssociatedOnlineOrder={setAssociatedOnlineOrder}
                />
                {id &&
                (selectedEntity?.id ||
                    selectedType === 'workshop' ||
                    selectedType === 'sales' ||
                    selectedType === 'guest') ? (
                    <SpeedDial
                        actionsList={SpeedDialList(
                            parseInt(id),
                            readOnly,
                            setReadOnly,
                            setLogsDrawerOpen,
                            selectedType,
                            saleDetails,
                            handleSubmitSale,
                            setTextDrawerOpen,
                            setNotesDialogOpen,
                            setDeleteDialogOpen,
                            setShouldBlockNavigation,
                            handlePrintInvoice,
                            setStockTransferData,
                            setStockTransferDrawerOpen
                        )}
                    />
                ) : null}
            </>
        )
    };

    let orderLinesTab = {
        id: 1,
        cypressLabel: 'orderlinesTab',
        title: 'Orderlines',
        content: (
            <>
                <Orderlines
                    id={parseInt(id)}
                    readOnly={readOnly}
                    saleDetails={saleDetails}
                    setSaleDetails={setSaleDetails}
                    oldSale={oldSale}
                    selectedType={selectedType}
                    selectedEntity={selectedEntity}
                    extraCosts={extraCosts}
                    setExtraCosts={setExtraCosts}
                    showSnackbar={showSnackbar}
                    paymentLines={paymentLines}
                    stockOrders={stockOrders}
                    setNotesDialogOpen={setNotesDialogOpen}
                    selectedSite={selectedSite}
                    setSelectedSite={setSelectedSite}
                />
                <SpeedDial
                    cypressLabel="PAorderLinesSpeedDial"
                    actionsList={SpeedDialList(
                        parseInt(id),
                        readOnly,
                        setReadOnly,
                        setLogsDrawerOpen,
                        selectedType,
                        saleDetails,
                        handleSubmitSale,
                        setTextDrawerOpen,
                        setNotesDialogOpen,
                        setDeleteDialogOpen,
                        setShouldBlockNavigation,
                        handlePrintInvoice,
                        setStockTransferData,
                        setStockTransferDrawerOpen
                    )}
                />
            </>
        )
    };

    let paymentTab = {
        id: 2,
        cypressLabel: 'paymentTab',
        title: 'Payment',
        content: (
            <>
                <Payment
                    id={parseInt(id)}
                    readOnly={readOnly}
                    saleDetails={saleDetails}
                    setSaleDetails={setSaleDetails}
                    oldSale={oldSale}
                    extraCosts={extraCosts}
                    selectedType={selectedType}
                    selectedEntity={selectedEntity}
                    paymentLines={paymentLines}
                    setPaymentLines={setPaymentLines}
                    showSnackbar={showSnackbar}
                />
                <SpeedDial
                    cypressLabel="PApaymentSpeedDial"
                    actionsList={SpeedDialList(
                        parseInt(id),
                        readOnly,
                        setReadOnly,
                        setLogsDrawerOpen,
                        selectedType,
                        saleDetails,
                        handleSubmitSale,
                        setTextDrawerOpen,
                        setNotesDialogOpen,
                        setDeleteDialogOpen,
                        setShouldBlockNavigation,
                        handlePrintInvoice,
                        setStockTransferData,
                        setStockTransferDrawerOpen
                    )}
                />
            </>
        )
    };

    let tabContent = [];

    if (
        (selectedType === 'customer' && saleDetails.status !== 'Quote') ||
        (selectedType === 'guest' && saleDetails.status === 'Sale')
    ) {
        tabContent = [customerTab, orderLinesTab, paymentTab];
    } else {
        tabContent = [customerTab, orderLinesTab];
    }

    return (
        <PageWrapper>
            {loading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Prompt
                        when={shouldBlockNavigation}
                        message="You have unsaved changes, are you sure you want to leave?"
                    />
                    <Typography variant="h4">
                        {vehicleSaleId
                            ? `Sale to Contract #${vehicleSaleId}`
                            : id
                              ? `${saleDetails.status} #${id}`
                              : 'New Sale'}
                    </Typography>
                    <br />
                    <Tabs
                        tabContent={tabContent}
                        presetValue={vehicleSaleId ? 1 : 0}
                    />
                </>
            )}

            <Dialog
                open={notesDialogOpen}
                onClose={() => setNotesDialogOpen(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Sale Notes</DialogTitle>
                <DialogContent>
                    <NotesDialogContent
                        saleDetails={saleDetails}
                        setSaleDetails={setSaleDetails}
                        readOnly={readOnly}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNotesDialogOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Delete {saleDetails?.status}?</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to delete{' '}
                        {saleDetails?.status} #{id}?In doing so you will lose
                        all data associated permanently.
                    </Typography>
                    <br />
                    <Typography variant="body1" textAlign="center">
                        <b>This cannot be undone.</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Close
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            HandleDeleteSale(
                                parseInt(id),
                                paymentLines,
                                showSnackbar,
                                setButtonLoading
                            )
                        }
                    >
                        {'Delete ' + saleDetails.status}
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Drawer
                openDrawer={textDrawerOpen}
                setOpenDrawer={setTextDrawerOpen}
                title="New Text Message"
                subTitle={
                    selectedEntity?.firstName + ' ' + selectedEntity?.lastName
                }
            >
                <TextMessageDrawerContent
                    id={parseInt(id)}
                    department="Parts"
                    customerName={
                        selectedType === 'contract'
                            ? selectedEntity?.Customer?.companyName
                                ? selectedEntity?.Customer?.companyName
                                : selectedEntity?.Customer?.firstName +
                                  selectedEntity?.Customer?.lastName
                            : selectedEntity?.companyName
                              ? selectedEntity?.companyName
                              : selectedEntity?.firstName +
                                ' ' +
                                selectedEntity?.lastName
                    }
                    customerPhone={
                        selectedType === 'contract'
                            ? selectedEntity?.Customer?.phoneNumber
                            : selectedEntity?.phoneNumber
                    }
                    showSnackbar={showSnackbar}
                    onlineOrderNumber={associatedOnlineOrder?.OnlineOrderId}
                    proformaNumber={id}
                />
            </Drawer>

            <Drawer
                openDrawer={logsDrawerOpen}
                setOpenDrawer={setLogsDrawerOpen}
                title="Change History"
                subTitle=""
                width="40vw"
            >
                <LogsDrawerContent id={parseInt(id)} />
            </Drawer>

            <Drawer
                openDrawer={stockTransferDrawerOpen}
                setOpenDrawer={setStockTransferDrawerOpen}
                title="Stock Transfer"
                subTitle=""
                width="40vw"
            >
                <StockTransferFromOrderContentDrawer
                    transferData={stockTransferData}
                    setTransferData={setStockTransferData}
                    showSnackbar={showSnackbar}
                />
            </Drawer>
        </PageWrapper>
    );
};

export default withSnackbar(NewSale);
