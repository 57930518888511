// IMPORTS
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
// COMPONENTS
import Fab from '../../global/Fab';
import PageWrapper from '../../global/PageWrapper';
import GeneralDetails from './components/GeneralDetails';
import BusinessHours from './components/BusinessHours';
import Tills from './components/Tills';
import OnlineOrders from './components/OnlineOrders';
import { withSnackbar } from '../../global/WrappingSnackbar';
import ExtraSettings from './components/ExtraSettings';
import Paper from '../../global/Paper';
import PageDoesNotExist from '../../global/PageDoesNotExist';
import RoleCheck from '../../global/RoleCheck';
import SpeedDial from '../../global/SpeedDial';
// INTERFACES
import {
    Settings,
    Site,
    Specific,
    Till
} from '../../global/interfaces/GeneralInterface';
import { ShippingMethod } from '../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
// LOGIC
import HandleUpdateSite from './logic/HandleUpdateSite';
import getAllSiteSettingsById from './logic/GetAllSiteSettings';
import getInitialSiteSettings from './logic/GetInitialSiteSettings';
import HandleAddSite from './logic/HandleAddSite';

const ViewSite = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const [settings, setSettings] = useState<Settings[]>([]);
    const [site, setSite] = useState<Site>({});
    const [tills, setTills] = useState<Till[]>([]);
    const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>(
        []
    );
    const [brandList, setBrandList] = useState<Specific[]>([]);
    const [siteBrands, setSiteBrands] = useState<Specific[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(true);

    const actionsList = [
        {
            icon: <Edit />,
            name: 'Edit Site',
            onClick: () => setReadOnly(false),
            cypressLabel: 'editSiteButton'
        }
    ];

    useEffect(() => {
        if (id) {
            getAllSiteSettingsById(
                parseInt(id),
                setSite,
                setTills,
                setShippingMethods,
                setBrandList,
                setSettings,
                setSiteBrands,
                setLoading,
                setInvalid
            );
        } else {
            getInitialSiteSettings(setBrandList, setSettings, setLoading);
            setReadOnly(false);
        }
    }, [id]);

    return (
        <PageWrapper>
            {loading ? (
                <Paper>
                    <Typography textAlign="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4">
                        {id ? site?.name : 'New Site'}
                    </Typography>

                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <GeneralDetails
                                siteInfo={site}
                                setSiteInfo={setSite}
                                settings={settings}
                                readOnly={readOnly}
                            />
                            <br />
                            <BusinessHours
                                siteInfo={site}
                                setSiteInfo={setSite}
                                readOnly={readOnly}
                            />
                            <br />
                            <ExtraSettings
                                settings={settings}
                                siteInfo={site}
                                setSiteInfo={setSite}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Tills
                                tills={tills}
                                setTills={setTills}
                                siteId={parseInt(id)}
                                readOnly={readOnly}
                            />
                            <br />
                            <OnlineOrders
                                siteInfo={site}
                                setSiteInfo={setSite}
                                shippingMethods={shippingMethods}
                                setShippingMethods={setShippingMethods}
                                brandList={brandList}
                                setBrandList={setBrandList}
                                siteBrands={siteBrands}
                                setSiteBrands={setSiteBrands}
                                readOnly={readOnly}
                            />
                        </Grid>
                    </Grid>
                    <RoleCheck
                        permission="configure_sites"
                        component={
                            <>
                                {readOnly ? (
                                    <SpeedDial
                                        actionsList={actionsList}
                                        cypressLabel="viewSiteSpeedDial"
                                    />
                                ) : (
                                    <Fab
                                        cypressLabel="submitSiteFAB"
                                        editIcon={false}
                                        loading={loading}
                                        onClick={() => {
                                            id
                                                ? HandleUpdateSite({
                                                      site,
                                                      settings,
                                                      tills,
                                                      shippingMethods,
                                                      showSnackbar
                                                  })
                                                : HandleAddSite(
                                                      site,
                                                      settings,
                                                      tills,
                                                      shippingMethods,
                                                      setLoading,
                                                      setReadOnly,
                                                      showSnackbar
                                                  );
                                        }}
                                    />
                                )}
                            </>
                        }
                    />
                </>
            )}
        </PageWrapper>
    );
};

export default withSnackbar(ViewSite);
