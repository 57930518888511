import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from 'react';
import {
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// COMPONENTS
import Autocomplete from '../../../../global/Autocomplete';

// LOGIC
import GetAllMakes from '../../../../global/databaseLogic/GetAllMakes';
import GetAllModels from '../../../../global/databaseLogic/GetAllModels';

// INTERFACES
import {
    Make,
    Model
} from '../../../../global/interfaces/VehicleSaleInterface';
import { Dayjs } from 'dayjs';
import { SiteContext } from '../../../../../main/Main';
import { Site } from '../../../../global/interfaces/GeneralInterface';
interface VehicleDetailsProps {
    bikeCondition: string;
    setBikeCondition: Dispatch<SetStateAction<string>>;
    selectedMake: Make;
    setSelectedMake: Dispatch<SetStateAction<Make>>;
    selectedModel: Model;
    setSelectedModel: Dispatch<SetStateAction<Make>>;
    weeklyBudget: number;
    setWeeklyBudget: Dispatch<SetStateAction<number>>;
    enquirySource: string;
    setEnquirySource: Dispatch<SetStateAction<string>>;
    pickupDate: Dayjs;
    setPickupDate: Dispatch<SetStateAction<Dayjs>>;
    reason: string;
    setReason: Dispatch<SetStateAction<string>>;
    errors: {
        nameError: boolean;
        contactDetailsError: boolean;
        enquirySourceError: boolean;
        makeError: boolean;
    };
    setErrors: Dispatch<
        SetStateAction<{
            nameError: boolean;
            contactDetailsError: boolean;
            enquirySourceError: boolean;
            makeError: boolean;
        }>
    >;
    disabled: boolean;
}

const VehicleDetails = (props: VehicleDetailsProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const [makeList, setMakeList] = useState<Make[]>([]);
    const [modelList, setModelList] = useState<Model[]>([]);
    const sourceList =
        siteDetails.vehicleTypeSold === 'Motorcycles'
            ? [
                  { value: 'BIKESALES' },
                  { value: 'FACEBOOK' },
                  { value: 'ONLINE' },
                  { value: 'PHONE' },
                  { value: 'RETURN' },
                  { value: 'SOCIAL MEDIA' },
                  { value: 'WALK IN' },
                  { value: 'OTHER' }
              ]
            : [
                  { value: 'FACEBOOK' },
                  { value: 'ONLINE' },
                  { value: 'PHONE' },
                  { value: 'RETURN' },
                  { value: 'SOCIAL MEDIA' },
                  { value: 'WALK IN' },
                  { value: 'OTHER' }
              ];

    useEffect(() => {
        GetAllMakes(setMakeList);
        GetAllModels(setModelList);
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Autocomplete
                    options={makeList}
                    isDisabled={props.disabled}
                    isError={props.errors.makeError}
                    useTwoOptionLabels={false}
                    primaryOptionLabel="name"
                    textfieldLabel="Make"
                    selectedValue={props.selectedMake}
                    handleSelectedValueChange={(newValue) => {
                        props.setSelectedMake(newValue);
                        if (newValue == null) {
                            props.setSelectedModel(null);
                        }
                    }}
                    handleInputValueChange={() => null}
                />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    options={modelList.filter(
                        (x) => x.MakeId === props?.selectedMake?.id
                    )}
                    isDisabled={props.disabled || !props?.selectedMake?.id}
                    useTwoOptionLabels={false}
                    primaryOptionLabel="name"
                    textfieldLabel="Model"
                    selectedValue={props.selectedModel}
                    handleSelectedValueChange={(newValue) => {
                        props.setSelectedModel(newValue);
                    }}
                    handleInputValueChange={() => null}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography align="center">
                    <FormControl disabled={props.disabled}>
                        <RadioGroup
                            defaultValue="new"
                            value={props.bikeCondition}
                            onChange={(event) =>
                                props.setBikeCondition(event.target.value)
                            }
                            row
                        >
                            <FormControlLabel
                                value="new"
                                control={<Radio />}
                                label="New"
                                disabled={props.disabled}
                            />
                            <FormControlLabel
                                value="used"
                                control={<Radio />}
                                label="Used"
                                disabled={props.disabled}
                            />
                        </RadioGroup>
                    </FormControl>
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    disabled={props.disabled}
                    type="number"
                    label="Weekly Budget"
                    value={props.weeklyBudget === 0 ? null : props.weeklyBudget}
                    onChange={(event) => {
                        props.setWeeklyBudget(parseFloat(event.target.value));
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    select
                    fullWidth
                    required
                    disabled={props.disabled}
                    error={props.errors.enquirySourceError}
                    label="Enquiry Source"
                    value={props.enquirySource || ''}
                    onChange={(event) => {
                        props.setEnquirySource(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                >
                    {sourceList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.value}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                >
                    <DatePicker
                        format="DD/MM/YYYY"
                        label="Ideal Pickup Date"
                        disabled={props.disabled}
                        value={props.pickupDate}
                        onChange={(newValue) => {
                            props.setPickupDate(newValue);
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: false,
                                InputLabelProps: { shrink: true }
                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Why not today?"
                    disabled={props.disabled}
                    value={props.reason || ''}
                    onChange={(event) => {
                        props.setReason(event.target.value);
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        </Grid>
    );
};

export default VehicleDetails;
