import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import ClearIcon from '@mui/icons-material/Clear';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
    nameOnCard: yup.string().required('Full Name on Card is required'),
    bankName: yup.string().required('Bank Name is required'),
    bsb: yup.string().required('BSB Number is required'),
    accountNo: yup.string().required('Account Number is required'),
    fullName: yup.string().required('Full Name is required'),
    vendorSigDataURL: yup.mixed().required('Signature is required')
});

interface CustomerBankDetailsFormProps {
    onVendorFormSubmit: (formData: any, submitType: any) => Promise<void>;
    buttonLoading: boolean;
}

const CustomerBankDetailsForm = ({
    onVendorFormSubmit,
    buttonLoading
}: CustomerBankDetailsFormProps) => {
    const {
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            nameOnCard: '',
            bankName: '',
            bsb: '',
            accountNo: '',
            fullName: '',
            vendorSigDataURL: null
        },
        mode: 'onTouched'
    });

    let vendorSigCanvas = useRef<SignatureCanvas>(null);

    const clearSignature = () => {
        vendorSigCanvas.current.clear();
        setValue('vendorSigDataURL', null);
    };

    const saveSignature = () => {
        const dataURL = vendorSigCanvas.current.toDataURL();
        setValue('vendorSigDataURL', dataURL);
    };

    const handlePreviewChanges = (data) => {
        onVendorFormSubmit(data, 'preview');
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Paper
                elevation={3}
                sx={{
                    padding: 3,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 'bold' }}
                            >
                                Bank Details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="nameOnCard"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Full Name on Card"
                                        variant="outlined"
                                        error={!!errors.nameOnCard}
                                        helperText={errors.nameOnCard?.message}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="bankName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Bank Name"
                                        variant="outlined"
                                        error={!!errors.bankName}
                                        helperText={errors.bankName?.message}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="bsb"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="BSB Number"
                                        variant="outlined"
                                        error={!!errors.bsb}
                                        helperText={errors.bsb?.message}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="accountNo"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Account Number"
                                        variant="outlined"
                                        error={!!errors.accountNo}
                                        helperText={errors.accountNo?.message}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                I
                                <Controller
                                    name="fullName"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            variant="filled"
                                            size="small"
                                            sx={{ width: '30vw', mx: 1 }}
                                            error={!!errors.fullName}
                                            helperText={
                                                errors.fullName?.message
                                            }
                                        />
                                    )}
                                />
                                hereby declare and confirm that these are my
                                correct bank account details for payment. I also
                                acknowledge and take full responsibility that
                                should the above numbers for incorrect, that
                                Brisbane Motorcycles has transferred according
                                to my instructions and will have no liability
                                should the funds be transferred into an
                                incorrect account.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            SIGNATURE:{' '}
                            <IconButton onClick={clearSignature}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="vendorSigDataURL"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <SignatureCanvas
                                            penColor="black"
                                            disabled={false}
                                            canvasProps={{
                                                className: 'sigCanvas',
                                                style: {
                                                    border: '1px solid black',
                                                    minWidth: 300,
                                                    width: '30vw',
                                                    minHeight: 100,
                                                    height: '15vw'
                                                }
                                            }}
                                            ref={vendorSigCanvas}
                                            // save user signature dataURL to form data
                                            onEnd={saveSignature}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                        {/* // buttons to preview and email the form data */}
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Button
                                onClick={handleSubmit(handlePreviewChanges)}
                                variant="contained"
                                disabled={watch('vendorSigDataURL') === null}
                            >
                                Preview Signature
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
};

export default CustomerBankDetailsForm;
