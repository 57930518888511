// IMPORTS
import {
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
    Table,
    TableRow,
    TableCell
} from '@mui/material';
// INTERFACES
import { Unit } from '../../../global/interfaces/PartsInterface';
import { Creditor } from '../../../global/interfaces/GeneralInterface';
interface Props {
    dialogSupplier: number;
    partDetails: Unit;
    supplierList: Creditor[];
}

const SupplierDialogContent = (props: Props) => {
    let selectedSupplier = props.supplierList.filter(
        (x) => x.id === props.dialogSupplier
    )[0];

    return (
        <>
            <DialogTitle>
                {selectedSupplier.code} - {selectedSupplier.name}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="body1">
                            <b>Phone Numbers</b>
                        </Typography>
                        <br />
                        <Table>
                            {selectedSupplier.contactDetails['phone'].map(
                                (phone) => (
                                    <TableRow>
                                        <TableCell>{phone.reference}</TableCell>
                                        <TableCell>{phone.value}</TableCell>
                                    </TableRow>
                                )
                            )}
                        </Table>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1">
                            <b>Email Addresses</b>
                        </Typography>
                        <br />
                        <Table>
                            {selectedSupplier.contactDetails['email'].map(
                                (email) => (
                                    <TableRow>
                                        <TableCell>{email.reference}</TableCell>
                                        <TableCell>{email.value}</TableCell>
                                    </TableRow>
                                )
                            )}
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        {selectedSupplier.representative ? (
                            <>
                                <Typography variant="body1">
                                    <b>Representative: </b>
                                    {selectedSupplier.representative}
                                </Typography>
                                <br />
                            </>
                        ) : null}
                        {selectedSupplier.addressLine1 ? (
                            <Typography variant="body1">
                                <b>Address: </b>
                                {selectedSupplier.addressLine1 ?? ''}{' '}
                                {selectedSupplier.suburb ?? ''}{' '}
                                {selectedSupplier.postcode ?? ''}{' '}
                                {selectedSupplier.country ?? ''}
                            </Typography>
                        ) : null}
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
};

export default SupplierDialogContent;
