// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { TextField, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../global/Paper';

// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import TotalValues from '../logic/TotalValues';

// INTERFACES
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import CheckDocumentReference from '../../../global/logic/CheckDocumentReference';

interface VehicleInvoiceDetailsProps {
    listOfVehicles: Vehicle[];
    totalOnInvoice: number;
    setTotalOnInvoice: Dispatch<SetStateAction<number>>;
    documentReference: string;
    setDocumentReference: Dispatch<SetStateAction<string>>;
    invoiceDate: string;
    setInvoiceDate: Dispatch<SetStateAction<string>>;
    responseCode: number;
    setResponseCode: Dispatch<SetStateAction<number>>;
    gstMethod: string;
}

/**
 * calculateDifference
 * Calculate the difference value according to GST type
 * @author Estienne
 * @param documentTotal
 * @param enteredTotal
 * @param gst
 * @param gstMethod
 * @returns the difference between the document total and entered total, or the entered total and the GST
 */
const calculateDifference = (
    documentTotal: number,
    enteredTotal: number,
    gst: number,
    gstMethod: string
) => {
    if (gstMethod === 'Including') {
        return CurrencyFormatter(documentTotal - enteredTotal);
    } else if (gstMethod === 'Excluding') {
        return CurrencyFormatter(enteredTotal + gst - documentTotal);
    }
};

const VehicleInvoiceDetails = ({
    listOfVehicles,
    totalOnInvoice,
    setTotalOnInvoice,
    documentReference,
    setDocumentReference,
    invoiceDate,
    setInvoiceDate,
    responseCode,
    setResponseCode,
    gstMethod
}: VehicleInvoiceDetailsProps) => {
    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Document Reference"
                        InputLabelProps={{ shrink: true }}
                        value={documentReference}
                        onChange={(e) => setDocumentReference(e.target.value)}
                        onBlur={(e) =>
                            CheckDocumentReference(
                                e.target.value,
                                setResponseCode
                            )
                        }
                        error={responseCode === 400 ? true : false}
                        helperText={
                            responseCode === 400
                                ? 'Document Reference Already Exists.'
                                : ''
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Document Date"
                            value={invoiceDate}
                            onChange={(newValue) => {
                                setInvoiceDate(newValue);
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        type="number"
                        size="small"
                        label="Document Total"
                        InputLabelProps={{ shrink: true }}
                        value={totalOnInvoice}
                        onChange={(e) =>
                            setTotalOnInvoice(parseFloat(e.target.value))
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        disabled
                        size="small"
                        label="Entered Total"
                        InputLabelProps={{ shrink: true }}
                        value={CurrencyFormatter(
                            TotalValues('cost', listOfVehicles)
                        )}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        fullWidth
                        disabled
                        size="small"
                        label="GST"
                        InputLabelProps={{ shrink: true }}
                        value={CurrencyFormatter(
                            TotalValues('gst', listOfVehicles)
                        )}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        fullWidth
                        disabled
                        size="small"
                        label="Difference"
                        value={calculateDifference(
                            totalOnInvoice,
                            TotalValues('cost', listOfVehicles),
                            TotalValues('gst', listOfVehicles),
                            gstMethod
                        )}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default VehicleInvoiceDetails;
