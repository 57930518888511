import { Step } from '../../interface';

export const LoginSteps: Step[] = [
    {
        label: "Enter your username and password, then click 'Log In'. If incorrect credentials are entered, an error message will appear.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/login-01.png'
    },
    {
        label: 'After a successful login, the homepage will appear. To log out, click the Exit icon.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/login-02.png'
    },
    {
        label: 'To reset your password, click the three dots icon in the header, then select "Change Password". This will open the Reset Password dialog.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/login-03.png'
    },
    {
        label: 'Enter your current password, new password, and confirm the new password. Click "Change Password" to finalize the changes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/login-04.png'
    }
];

export const HomePageSteps: Step[] = [
    {
        label: 'Navigate to "Overview" via the sidebar to access the homepage.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-01.png'
    },
    {
        label: 'Click to open or close the sidebar.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-02.png'
    },
    {
        label: 'To switch sites, click the Location icon in the header and select the desired site from the dropdown list.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-03.png'
    },
    {
        label: 'To clock on or off, click the Clock icon in the header to open the time clock dialog. On your first login of every day Ora will ask you to clock on.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-04.png'
    },
    {
        label: 'To update your personal settings, click the Settings icon in the header to access the personal settings page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-05.png'
    },
    {
        label: 'On the personal settings page, you can update your default till, switch between light/dark mode, and click "Save" to apply changes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-06.png'
    },
    {
        label: 'Click the Task icon in the header to navigate to the daily tasks page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-07.png'
    },
    {
        label: 'On the daily tasks page, view tasks assigned to your current site and department, and check tasks to mark them as complete.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-08.png'
    },
    {
        label: 'Department managers can add, update, or delete tasks for their department under the "Daily Task Settings" tab.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-09.png'
    },
    {
        label: 'Click the three dots icon in the header to access: User Management, Site Settings, Company Settings, Specific (Make, Model, Part Category, ...) Management Function, Templates for Text and Email, and changing your password.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/home-10.png'
    }
];

export const SpecificsManagementSteps: Step[] = [
    {
        label: 'To access the Specifics Management page, click the three dots icon in the header, then select "Specifics Management."',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-01.png'
    },
    {
        label: 'On the Specifics Management page, you can view and update the following specifics: makes, models, series, categories and brands.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-02.png'
    },
    {
        label: 'Use the search bar and press Enter to search for a specific item.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-03.png'
    },
    {
        label: 'Click the edit icon to begin updating the specific name, or click the trash icon to delete the specific. Note: specifics with linked vehicles cannot be deleted.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-04.png'
    },
    {
        label: 'In each row, click "Models Available" to view the models under the specific, or click "Vehicles Available" to view the vehicles under the specific.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-05.png'
    },
    {
        label: 'To add a new specific, click "Add Make/Model/Series/Category/Brand" in the speed dial menu.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-06.png'
    },
    {
        label: 'To merge existing makes, click "Merge Make" in the speed dial menu. This will open a dialog to select the make to merge.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-07.png'
    },
    {
        label: 'Select the merge parent and the duplicate make to merge. The duplicate is the one to be merged into the parent.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-08.png'
    },
    {
        label: 'Then click "Merge Makes" to apply the changes. The duplicate will be deleted and all models, series and vehicles under the duplicate will be moved to the parent.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-09.png'
    },
    {
        label: 'To import models from a CSV file, click "Import Models" in the speed dial menu. This will open a dialog to upload a CSV file.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-10.png'
    },
    {
        label: 'The only format currently supported is a CSV with a single column containing the names of the models. After selecting the make and uploading the file, click "Import Models."',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-11.png'
    },
    {
        label: 'You will find the imported models in the models table and also in the "Models Available" column under the selected make.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/specific-12.png'
    }
];

export const ManageUsersSteps: Step[] = [
    {
        label: 'To access the Users page, click the three dots icon in the header and select "Users."',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/users-01.png'
    },
    {
        label: 'On the Users page you can search for a specific user, by entering the user name in the search bar and pressing Enter.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/users-02.png'
    },
    {
        label: 'To add a new user, click the "Add User" button in the speed dial menu to open the New User page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/users-03.png'
    },
    {
        label: 'On the New User page, enter the user basic details, including full name, a unique username, department, staff email, and primary store. Indicate employment status. Personal details are optional. Click "Save" to proceed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/users-04.png'
    },
    {
        label: "You can find the newly created user on the Users page. Click on the user ID to open the user details page, where you can view and update the user's details.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/users-05.png'
    },
    {
        label: 'Click "Edit User" in the speed dial menu to enter the edit mode. Click the Save button to save the changes you made.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/users-06.png'
    },
    {
        label: 'Click "Reset Password" in the speed dial menu to reset the user password. The user will receive an email with their new password.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/users-07.png'
    },
    {
        label: 'Click "User Permissions" in the speed dial menu to view and update user permissions. A new user by default has no permissions. You can copy permissions from another user or apply permissions from a template.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/users-08.png'
    },
    {
        label: 'After user permissions are updated, click "Update Permissions" to apply the changes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/users-09.png'
    }
];

export const ManageSitesSteps: Step[] = [
    {
        label: 'To access the Sites page, click the three dots icon in the header, then select "Sites."',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/sites-01.png'
    },
    {
        label: 'The Sites page list all sites. To search for a specific site, enter the site code in the search bar and press Enter.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/sites-02.png'
    },
    {
        label: 'Click on the site code to open the site details page, where you can view and update site details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/sites-03.png'
    },
    {
        label: 'Click "Edit Site" in the speed dial menu to begin updating site details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/sites-04.png'
    },
    {
        label: 'Make the necessary changes and click "Save" to apply the updates.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/sites-05.png'
    }
];

export const CompanySettingsSteps: Step[] = [
    {
        label: 'To access the Company Settings page, click the three dots icon in the header, then select "Company Settings."',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/company-01.png'
    },
    {
        label: 'On the Company Settings page, you can view and update company business details, payment methods, sale warranties, and departments.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/company-02.png'
    },
    {
        label: 'You can also add or update current and future registration prices and CTP prices for Queensland and New South Wales. The Effective Date can be used to tell Ora when to replace the current price with the ones entered in the future prices section.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/company-03.png'
    },
    {
        label: 'These prices will be used in calculating guidance for registration and CTP prices when creating a vehicle sale. Refer to the government website for the latest prices.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/company-04.png'
    },
    {
        label: 'Click "Save" to apply the changes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/company-05.png'
    }
];

export const ManageEmailTextTemplatesSteps: Step[] = [
    {
        label: 'To access the Email & Text Templates page, click the three dots icon in the header, then select "Email & Text Templates."',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/template-01.png'
    },
    {
        label: 'The Email & Text Templates page displays all email and text templates. To search for a specific template, enter the title/subject or department in the search bar and press Enter.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/template-02.png'
    },
    {
        label: 'Click the Title/Subject to open the template details page, where you can view and update the template.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/template-03.png'
    },
    {
        label: 'Click the Edit icon to begin updating the template. Update the title, department, and message body as needed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/template-04.png'
    },
    {
        label: 'To insert dynamic fields that adjust based on store, sale, user details, etc., click "Available Codes" to add or update a code in the message body.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/template-05.png'
    },
    {
        label: 'Check what the message will look like in the Message Body Preview box. Click "Save" to apply the changes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/template-06.png'
    },
    {
        label: 'To add a new template, click "Add Template" in the speed dial menu. Follow the same steps as above to create a new template.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/template-07.png'
    },
    {
        label: 'Templates will be used when sending emails or text messages to customers for sales, services, or other purposes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/overview/template-08.png'
    }
];
