import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { GeneralJournalLine } from "../../../global/interfaces/AdminInterface";

const GetGeneralJournal = (id: number, setReference: Dispatch<SetStateAction<string>>, setEntryLines: Dispatch<SetStateAction<GeneralJournalLine[]>>, setInvalid: Dispatch<SetStateAction<boolean>>) => {
    api.get(`accountTransactionNextGen/${id}`).then((res) => {
        if (res.status === 200) {
            setReference(res.data[0]?.AccountTransaction?.reference);
            setEntryLines(res.data);
        } else if (res.status === 404) {
            setInvalid(true);
        }
    });
};

export default GetGeneralJournal;