import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const PrintManifest = (
    setManifestLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setManifestLoading(true);
    api.post(
        `printOnlineManifest?SiteId=${localStorage.getItem('SiteId')}`
    ).then((res) => {
        if (res.status === 400) {
            showSnackbar(
                'Aus Post Label Error!',
                res.data.errorMessage,
                'error'
            );
            setManifestLoading(false);
        }
        if (res.status === 200) {
            setManifestLoading(false);
            let data = res.data;
            let urlIndex = data.indexOf('/manifests');
            window.open(res.data.substring(urlIndex), '_blank');
        } else if (res.status === 403) {
            showSnackbar(
                'One or more of these orders already have a shipment lodged with Aus Post.',
                'Please refer to the eParcel Portal for more information.',
                'error'
            );
            setManifestLoading(false);
        } else if (res.status === 402) {
            showSnackbar(
                'One or more of these orders already have a shipment lodged with Aus Post.',
                'Please refer to the eParcel Portal for more information.',
                'error'
            );
            setManifestLoading(false);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setManifestLoading(false);
        }
    });
};

export default PrintManifest;
