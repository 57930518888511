import { TableRow } from '@mui/material';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';

interface Transaction {
    id?: string;
    url?: string;
    date?: string;
    reference?: string;
    user?: string;
    amount?: number;
    invoiceDate?: string;
    reconciled?: boolean;
    paymentHistoryFullyPaid?: boolean;
    paymentHistoryReconciled?: boolean;
}

const AccountDrawerOnlineOrdersDetailed = ({
    transactions
}: {
    transactions: Transaction[];
}) => {
    return (
        <>
            {transactions
                .filter((x) => x.id.includes('_OO'))
                .map((transaction) => (
                    <TableRow>
                        {transaction.url ? (
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(transaction.url, '_blank')
                                }
                            >
                                {transaction.id}
                            </DataCellColoured>
                        ) : (
                            <DataCell>{transaction.id}</DataCell>
                        )}
                        <DataCell>
                            {transaction.invoiceDate
                                ? DateFormatter(transaction.invoiceDate)
                                : DateFormatter(transaction.date)}
                        </DataCell>
                        <DataCell>{transaction.reference}</DataCell>
                        <DataCell>Ora Online</DataCell>
                        <DataCell>
                            {CurrencyFormatter(-transaction.amount)}
                        </DataCell>
                        <DataCell></DataCell>
                    </TableRow>
                ))}
        </>
    );
};

export default AccountDrawerOnlineOrdersDetailed;
