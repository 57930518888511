import {
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    useContext
} from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// COMPONENTS
import Autocomplete from '../../../../global/Autocomplete';

// LOGIC
import GetAllMakes from '../../../../global/databaseLogic/GetAllMakes';
import GetAllModels from '../../../../global/databaseLogic/GetAllModels';

// INTERFACES
import {
    Make,
    Model
} from '../../../../global/interfaces/VehicleSaleInterface';
import { Dayjs } from 'dayjs';
import { SiteContext } from '../../../../../main/Main';
import { Site } from '../../../../global/interfaces/GeneralInterface';
interface TradeInDetailsProps {
    tradeInMake: Make;
    setTradeInMake: Dispatch<SetStateAction<Make>>;
    tradeInModel: Model;
    setTradeInModel: Dispatch<SetStateAction<Model>>;
    tradeInDetails: {
        year: Dayjs;
        registration: string;
        registrationExpiration: Dayjs;
        colour: string;
        odometer: number;
        hours: number;
        vin: string;
        engineNumber: string;
    };
    setTradeInDetails: Dispatch<
        SetStateAction<{
            year: Dayjs;
            registration: string;
            registrationExpiration: Dayjs;
            colour: string;
            odometer: number;
            hours: number;
            vin: string;
            engineNumber: string;
        }>
    >;
    tradeInErrors: {
        makeError: boolean;
        modelError: boolean;
        yearError: boolean;
        odometerError: boolean;
    };
    setTradeInErrors: Dispatch<
        SetStateAction<{
            makeError: boolean;
            modelError: boolean;
            yearError: boolean;
            odometerError: boolean;
        }>
    >;
    disabled: boolean;
}

const TradeInDetails = (props: TradeInDetailsProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const [makeList, setMakeList] = useState<Make[]>([]);
    const [modelList, setModelList] = useState<Model[]>([]);

    useEffect(() => {
        GetAllMakes(setMakeList);
        GetAllModels(setModelList);
    }, []);

    return (
        <div>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Trade-In Details
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Autocomplete
                        options={makeList}
                        isDisabled={props.disabled}
                        isError={props.tradeInErrors.makeError}
                        useTwoOptionLabels={false}
                        primaryOptionLabel="name"
                        textfieldLabel="Make"
                        selectedValue={props.tradeInMake}
                        handleSelectedValueChange={(newValue) => {
                            props.setTradeInMake(newValue);
                            if (newValue == null) {
                                props.setTradeInModel(null);
                            }
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        options={modelList.filter(
                            (x) => x.MakeId === props?.tradeInMake?.id
                        )}
                        isDisabled={props.disabled || !props?.tradeInMake?.id}
                        isError={props.tradeInErrors.modelError}
                        useTwoOptionLabels={false}
                        primaryOptionLabel="name"
                        textfieldLabel="Model"
                        selectedValue={props.tradeInModel}
                        handleSelectedValueChange={(newValue) => {
                            props.setTradeInModel(newValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="YYYY"
                            views={['year']}
                            view="year"
                            label="Year *"
                            disabled={props.disabled}
                            value={props.tradeInDetails.year}
                            onChange={(newValue) => {
                                props.setTradeInDetails({
                                    ...props.tradeInDetails,
                                    year: newValue
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                    <>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Registration"
                                disabled={props.disabled}
                                value={props.tradeInDetails.registration || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) =>
                                    props.setTradeInDetails({
                                        ...props.tradeInDetails,
                                        registration: event.target.value
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    label="Registration Expiration"
                                    disabled={props.disabled}
                                    value={
                                        props.tradeInDetails
                                            .registrationExpiration
                                    }
                                    onChange={(newValue) => {
                                        props.setTradeInDetails({
                                            ...props.tradeInDetails,
                                            registrationExpiration: newValue
                                        });
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                            InputLabelProps: { shrink: true }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </>
                ) : null}

                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        label="Colour"
                        disabled={props.disabled}
                        value={props.tradeInDetails.colour || ''}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) =>
                            props.setTradeInDetails({
                                ...props.tradeInDetails,
                                colour: event.target.value
                            })
                        }
                    />
                </Grid>
                {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                    <>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Odometer"
                                type="number"
                                disabled={props.disabled}
                                value={props.tradeInDetails.odometer || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) =>
                                    props.setTradeInDetails({
                                        ...props.tradeInDetails,
                                        odometer: parseInt(event.target.value)
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Hours"
                                type="number"
                                disabled={props.disabled}
                                value={props.tradeInDetails.hours || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) =>
                                    props.setTradeInDetails({
                                        ...props.tradeInDetails,
                                        hours: parseInt(event.target.value)
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="VIN"
                                disabled={props.disabled}
                                value={props.tradeInDetails.vin || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) =>
                                    props.setTradeInDetails({
                                        ...props.tradeInDetails,
                                        vin: event.target.value
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Engine Number"
                                disabled={props.disabled}
                                value={props.tradeInDetails.engineNumber || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) =>
                                    props.setTradeInDetails({
                                        ...props.tradeInDetails,
                                        engineNumber: event.target.value
                                    })
                                }
                            />
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </div>
    );
};

export default TradeInDetails;
