import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface DeletePaymentRunProps {
    PaymentRunId: number;
    deleteReason: string;
    showSnackbar: showSnackbar;
}

const DeletePaymentRun = ({
    PaymentRunId,
    deleteReason,
    showSnackbar
}: DeletePaymentRunProps) => {
    api.delete(`paymentRun/${PaymentRunId}`, {
        data: { deleteReason: deleteReason }
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Payment run deleted', 'The page will now refresh.');
            setTimeout(() => window.location.reload(), 1000);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default DeletePaymentRun;
