import { Dispatch, SetStateAction } from 'react';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { ValidateVin } from '../../../global/logic/vehicleValidation/ValidateVehicleFields';

interface AddVehicleToListParams {
    newVehicle: Vehicle;
    specificInputs: {
        makeInput: string;
        modelInput: string;
        seriesInput: string;
        fuelTypeInput: string;
    };
    listOfVehicles: Vehicle[];
    gstMethod: string;
    setNewVehicle: Dispatch<SetStateAction<Vehicle>>;
    setListOfVehicles: Dispatch<SetStateAction<Vehicle[]>>;
    setAddVehicleOpen: Dispatch<SetStateAction<boolean>>;
    setSpecificInputs: Dispatch<
        SetStateAction<{
            makeInput: string;
            modelInput: string;
            seriesInput: string;
            fuelTypeInput: string;
        }>
    >;
    showSnackbar: showSnackbar;
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>;
}

const AddMotorcyclesToList = (params: AddVehicleToListParams) => {
    const {
        newVehicle,
        listOfVehicles,
        gstMethod,
        setNewVehicle,
        setListOfVehicles,
        setAddVehicleOpen,
        setSpecificInputs,
        setShouldBlockNavigation,
        showSnackbar
    } = params;

    const requiredFields = [
        'Make',
        'Model',
        'year',
        'FuelType',
        'cylinders',
        'vin',
        'costPrice'
    ];

    for (const field of requiredFields) {
        if (!newVehicle[field]) {
            showSnackbar(
                'Missing required fields!',
                `You must enter a ${field} for the vehicle.`,
                'error'
            );
            return;
        }
    }

    let validateVin = ValidateVin(newVehicle.vin);
    if (validateVin !== 'valid') {
        showSnackbar(validateVin, '', 'error');
        return;
    }

    var oldList = listOfVehicles;

    if (oldList.length === 0) {
        newVehicle.id = 0;
    } else {
        newVehicle.id = oldList.at(-1)['id'] + 1;
    }
    newVehicle.freight = 0;
    newVehicle.gst = 0;

    gstMethod === 'Excluding'
        ? (newVehicle.gst = parseFloat(newVehicle.costPrice) * 0.1)
        : (newVehicle.gst =
              parseFloat(newVehicle.costPrice) -
              parseFloat(newVehicle.costPrice) / 1.1);

    oldList.push(newVehicle);
    setListOfVehicles(oldList);
    setAddVehicleOpen(false);

    setNewVehicle({
        Make: null,
        Model: null,
        Series: null,
        FuelType: null,
        year: null,
        cylinders: null,
        costPrice: null,
        registrationDueDate: null,
        complianceDate: '',
        vin: null,
        DriveType: null,
        BodyType: null,
        condition: 'New',
        engineNumber: '',
        learnerApproved: false,
        registrationNumber: '',
        transmission: 'Manual',
        odometer: null,
        engineSize: '',
        gears: null,
        priceRRP: null,
        adminLoadCost: null,
        trustReceipt: null,
        colour: null
    });
    setSpecificInputs({
        makeInput: '',
        modelInput: '',
        seriesInput: '',
        fuelTypeInput: ''
    });
    setShouldBlockNavigation(true);
};

export default AddMotorcyclesToList;
