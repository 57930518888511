// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { DialogContentText, Grid } from '@mui/material';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';

// LOGIC
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';
import GetAllSites from '../../../global/databaseLogic/GetAllSites';

interface VehicleTransferTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const statusOptions = [
    { id: 0, label: 'PENDING', value: 'PENDING' },
    { id: 1, label: 'COMPLETE', value: 'COMPLETE' }
];

const VehicleTransferTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: VehicleTransferTableFilterProps) => {
    const [sites, setSites] = useState<Site[]>([]);
    const [status, setStatus] = useState(statusOptions);

    useEffect(() => {
        GetAllSites(setSites);
    }, []);

    useEffect(() => {
        if (!isFilterActive()) {
            setSites([]);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.vehicleTransferFilter;
        newFilter[property] = value;
        setFilter({ ...filter, vehicleTransferFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    {/* Status Filter */}
                    <AutocompleteMultiple
                        options={status}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Status"
                        selectedValues={filter.vehicleTransferFilter.status}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = statusOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                                setStatus(currentOptions);
                            });

                            handleUpdateFilter('status', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    {/* Site Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site From"
                        selectedValues={filter.vehicleTransferFilter.siteFrom}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sites;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('siteFrom', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    {/* Site Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site To"
                        selectedValues={filter.vehicleTransferFilter.siteTo}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sites;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('siteTo', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default VehicleTransferTableFilter;
