import api from '../../../../../api';

const handleUpdateTaskList = (dailyTaskListData) => {
    api.put('/updateDailyTaskList/', {
        dailyTaskListData
    }).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('Task was not checked.');
        }
    });
};

export default handleUpdateTaskList;
