// IMPORTS
import { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { Edit, FilterList } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import Paper from '../../../global/Paper';
import DataCell from '../../../global/tableComponents/DataCell';
import Drawer from '../../../global/Drawer';
import PageWrapper from '../../../global/PageWrapper';
import MuiFab from '@mui/material/Fab';
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
import RoleCheck from '../../../global/RoleCheck';
// LOGIC
import BulkUpdateCustomerBackorders from '../logic/BulkUpdateCustomerBackorders';
import GetAllCustomerBackorderForSupplier from '../../../global/databaseLogic/GetAllCustomerBackorderForSupplier';
import { DateFormatter } from '../../../global/logic/Formatters';
// INTERFACES
import {
    CustomerBackorder,
    StockOrder
} from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import CheckPermission from '../../../global/logic/CheckPermission';

interface CustomerBackordersTabProps {
    CreditorId: number;
    showSnackbar: showSnackbar;
}

// Constant rows per page variable
const rowsPerPage = 10;

const CustomerBackordersTab = ({
    CreditorId,
    showSnackbar
}: CustomerBackordersTabProps) => {
    const status = [
        { label: 'OPEN' },
        { label: 'ORDERED' },
        { label: 'CANCELLED' },
        { label: 'CLOSED' }
    ];

    //General state
    const [customerBackorders, setCustomerBackorders] = useState<
        CustomerBackorder[]
    >([]);
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);

    // Bulk Close state
    const [selectedBackorders, setSelectedBackorders] = useState<number[]>([]);
    const [openUpdateBackorderDialog, setOpenUpdateBackorderDialog] =
        useState<boolean>(false);
    const [selectedUpdateStatus, setSelectedUpdateStatus] =
        useState<string>('CLOSED');

    // Drawer State
    const [openStockOrderDrawer, setOpenStockOrderDrawer] =
        useState<boolean>(false);
    const [selectedStockOrder, setSelectedStockOrder] = useState<StockOrder>({
        id: null,
        createdAt: '',
        orderType: ''
    });

    // Filter State
    const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
    const [statusOption, setStatusOption] =
        useState<{ label: string }[]>(status);
    const [selectedStatus, setSelectedStatus] = useState<{ label: string }[]>([
        { label: 'OPEN' },
        { label: 'ORDERED' }
    ]);

    const columns = CheckPermission({
        permission: 'update_Backorders',
        toReturnIfAuthorized: [
            { id: 0, label: '', width: 50 },
            { id: 1, label: 'Order ID', width: 100 },
            { id: 2, label: 'Status', width: 100 },
            { id: 3, label: 'Part Number', width: 150 },
            { id: 4, label: 'Part Name', width: 200 },
            { id: 5, label: 'Quantity', width: 70 },
            { id: 6, label: 'Stock Order ID', width: 70 },
            { id: 7, label: 'User', width: 100 }
        ],
        toReturnIfUnauthorized: [
            { id: 1, label: 'Order ID', width: 100 },
            { id: 2, label: 'Status', width: 100 },
            { id: 3, label: 'Part Number', width: 150 },
            { id: 4, label: 'Part Name', width: 200 },
            { id: 5, label: 'Quantity', width: 70 },
            { id: 6, label: 'Stock Order ID', width: 70 },
            { id: 7, label: 'User', width: 100 }
        ]
    });

    const stockOrderColumns = [
        { id: 0, label: 'Part Number', width: 100 },
        { id: 1, label: 'Quantity', width: 100 },
        { id: 2, label: 'Backorders', width: 150 }
    ];

    useEffect(() => {
        GetAllCustomerBackorderForSupplier(
            CreditorId,
            selectedStatus,
            setCustomerBackorders,
            setLoading
        );
    }, [selectedStatus, CreditorId]);

    // Handle changing the page
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleSelectBackorders = (backorderId, checked) => {
        let currentSelection = [...selectedBackorders];
        if (checked) {
            currentSelection.push(backorderId);
        } else {
            currentSelection = currentSelection.filter(
                (x) => x !== backorderId
            );
        }
        setSelectedBackorders(currentSelection);
    };

    return (
        <>
            {loading ? (
                <PageWrapper textAlign="center">
                    <CircularProgress />
                </PageWrapper>
            ) : (
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant="h5">
                                Backorders
                                <IconButton
                                    onClick={() => setOpenFilterDialog(true)}
                                >
                                    <FilterList />
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Pagination
                                color="primary"
                                count={
                                    customerBackorders.length <= rowsPerPage
                                        ? 1
                                        : Math.ceil(
                                              customerBackorders.length /
                                                  rowsPerPage
                                          )
                                }
                                page={page}
                                onChange={handleChange}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'right'
                                }}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <DataTable columns={columns}>
                        {customerBackorders
                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                            .map((backorder) => (
                                <TableRow>
                                    <RoleCheck
                                        permission={'update_Backorders'}
                                        component={
                                            <DataCell>
                                                <Checkbox
                                                    checked={selectedBackorders.includes(
                                                        backorder.id
                                                    )}
                                                    onChange={(e) =>
                                                        handleSelectBackorders(
                                                            backorder.id,
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </DataCell>
                                        }
                                    />
                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(
                                                `/inventory/viewSale/${backorder.OrderId}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        {backorder.OrderId}
                                    </DataCellColoured>
                                    <DataCell>
                                        <Chip
                                            variant="outlined"
                                            label={backorder.status}
                                            color={
                                                backorder.status === 'OPEN'
                                                    ? 'error'
                                                    : backorder.status ===
                                                        'ORDERED'
                                                      ? 'warning'
                                                      : 'success'
                                            }
                                        />
                                    </DataCell>
                                    <DataCell>{backorder.partNumber}</DataCell>
                                    <DataCell>{backorder.Unit?.name}</DataCell>
                                    <DataCell>
                                        {backorder.backorderQuantity}
                                    </DataCell>
                                    <DataCellColoured
                                        handleClick={() => {
                                            setSelectedStockOrder(
                                                backorder.StockOrder
                                            );
                                            setOpenStockOrderDrawer(true);
                                        }}
                                    >
                                        {backorder.StockOrderId}
                                    </DataCellColoured>
                                    <DataCell>
                                        {backorder.User?.firstName}{' '}
                                        {backorder.User?.lastName}
                                    </DataCell>
                                </TableRow>
                            ))}
                    </DataTable>
                </Paper>
            )}

            {/* STOCK ORDER DRAWER */}
            <Drawer
                openDrawer={openStockOrderDrawer}
                setOpenDrawer={setOpenStockOrderDrawer}
                title={`Stock Order #${selectedStockOrder.id}`}
                subTitle={`Ordered: ${DateFormatter(selectedStockOrder.createdAt)} | Type: ${selectedStockOrder.orderType.toUpperCase()}`}
            >
                <DataTable columns={stockOrderColumns}>
                    {selectedStockOrder?.orderLines?.map((line) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        '/inventory/viewPart/' + line.id,
                                        '_blank'
                                    )
                                }
                            >
                                {line.partNumber}
                            </DataCellColoured>
                            <DataCell>{line.quantity}</DataCell>
                            <DataCell>{line.linkedOrders}</DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Drawer>

            {/* BACKORDER STATUS FILTER DIALOG */}
            <Dialog
                open={openFilterDialog}
                onClose={() => setOpenFilterDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={statusOption}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Backorder Status"
                        selectedValues={selectedStatus}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = status;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.label !== val.label
                                );
                            });

                            setStatusOption(currentOptions);
                            setSelectedStatus(newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </DialogContent>
            </Dialog>

            {/* BACKORDER BULK UPDATE DIALOG */}
            <Dialog
                open={openUpdateBackorderDialog}
                onClose={() => setOpenUpdateBackorderDialog(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Status to apply
                        </InputLabel>
                        <Select
                            value={selectedUpdateStatus}
                            size="small"
                            label="Status to apply"
                            onChange={(e) =>
                                setSelectedUpdateStatus(e.target.value)
                            }
                        >
                            <MenuItem value={'OPEN'}>OPEN</MenuItem>
                            <MenuItem value={'CANCELLED'}>CANCELLED</MenuItem>
                            <MenuItem value={'CLOSED'}>CLOSED</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography variant="caption">
                        This action cannot be reversed.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => setOpenUpdateBackorderDialog(false)}
                    >
                        CLOSE
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            BulkUpdateCustomerBackorders(
                                selectedBackorders,
                                selectedUpdateStatus,
                                showSnackbar
                            );
                        }}
                    >
                        PROCEED
                    </Button>
                </DialogActions>
            </Dialog>

            {/* FAB */}
            {selectedBackorders.length > 0 ? (
                <Tooltip title={'Bulk update backorders statuses'}>
                    <div
                        style={{
                            margin: 0,
                            top: 'auto',
                            right: 20,
                            bottom: 20,
                            left: 'auto',
                            position: 'fixed',
                            zIndex: 1
                        }}
                    >
                        <MuiFab
                            color="primary"
                            aria-label="add"
                            onClick={() => setOpenUpdateBackorderDialog(true)}
                        >
                            <Edit />
                        </MuiFab>
                    </div>
                </Tooltip>
            ) : null}
        </>
    );
};

export default CustomerBackordersTab;
