import dayjs from 'dayjs';

const renderCustomerConditionPdf = async (pdfDocCopy, formData) => {
    const pages = pdfDocCopy.getPages();
    const firstPage = pages[0];

    if (formData.listFaults !== '') {
        const lines = formData.listFaults.split('\n');
        lines.forEach((line, index) => {
            firstPage.drawText(line, {
                x: 80,
                y: 470 - 15 * index,
                size: 12
            });
        });
    }

    if (formData.vendorSigDataURL) {
        const vendorSigImage = await pdfDocCopy.embedPng(
            formData.vendorSigDataURL
        );
        firstPage.drawImage(vendorSigImage, {
            x: 130,
            y: 300,
            width: 150,
            height: 50
        });
    }

    firstPage.drawText(dayjs().format('DD/MM/YYYY'), {
        x: 150,
        y: 265,
        size: 12
    });

    const pdfBytes = await pdfDocCopy.save();
    return pdfBytes;
};

export default renderCustomerConditionPdf;
