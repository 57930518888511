import BankTransactionContent from '../../components/Reconciliator/DrawerContent/BankTransactionContent';
import ManualTransactionContent from '../../components/Reconciliator/DrawerContent/ManualTransactionContent';
import PartsDirectDepositContent from '../../components/Reconciliator/DrawerContent/PartsDirectDepositContent';
import PayrunContent from '../../components/Reconciliator/DrawerContent/PayrunContent';
import ServiceDirectDepositContent from '../../components/Reconciliator/DrawerContent/ServiceDirectDepositContent';
import ServiceWarrantyContent from '../../components/Reconciliator/DrawerContent/ServiceWarrantyContent';
import TillReconciliationContent from '../../components/Reconciliator/DrawerContent/TillReconciliationContent';
import TradeInContent from '../../components/Reconciliator/DrawerContent/TradeInContent';
import TrustReceiptContent from '../../components/Reconciliator/DrawerContent/TrustReceiptContent';
import VehicleDirectDepositContent from '../../components/Reconciliator/DrawerContent/VehicleDirectDepositContent';
import VehicleRebateContent from '../../components/Reconciliator/DrawerContent/VehicleRebateContent';

const RenderDrawerContent = (
    type: string,
    selectedType: string,
    selectedObject
) => {
    if (selectedType === 'till') {
        if (type === 'title') {
            return `Till Reconciliation #${selectedObject.id}`;
        } else if (type === 'subtitle') {
            return `${selectedObject.date} - ${selectedObject.siteName} ${selectedObject.type}`;
        } else {
            return (
                <TillReconciliationContent selectedObject={selectedObject} />
            );
        }
    } else if (selectedType === 'manual') {
        if (type === 'title') {
            return `Manual Transaction #${selectedObject.id}`;
        } else if (type === 'subtitle') {
            return `${selectedObject.date} - ${selectedObject.siteName ? selectedObject.siteName + '-' : ''} ${selectedObject.type}`;
        } else {
            return <ManualTransactionContent selectedObject={selectedObject} />;
        }
    } else if (selectedType === 'trust') {
        if (type === 'title') {
            return 'Trust Receipt';
        } else if (type === 'subtitle') {
            return `${selectedObject.date} - ${selectedObject.siteName ? selectedObject.siteName + '-' : ''} ${selectedObject.type}`;
        } else {
            return <TrustReceiptContent selectedObject={selectedObject} />;
        }
    } else if (selectedType === 'trade') {
        if (type === 'title') {
            return 'Used Stock Purchase';
        } else if (type === 'subtitle') {
            return `${selectedObject.date} - ${selectedObject.reference}`;
        } else {
            return <TradeInContent selectedObject={selectedObject} />;
        }
    } else if (selectedType === 'vehicle') {
        if (type === 'title') {
            return 'Vehicle Sale Direct Deposit';
        } else if (type === 'subtitle') {
            return `${selectedObject.date} - ${selectedObject.type}`;
        } else {
            return (
                <VehicleDirectDepositContent selectedObject={selectedObject} />
            );
        }
    } else if (selectedType === 'service') {
        if (type === 'title') {
            return 'Service Direct Deposit';
        } else if (type === 'subtitle') {
            return `${selectedObject.date} - ${selectedObject.type}`;
        } else {
            return (
                <ServiceDirectDepositContent selectedObject={selectedObject} />
            );
        }
    } else if (selectedType === 'parts') {
        if (type === 'title') {
            return 'Parts Direct Deposit';
        } else if (type === 'subtitle') {
            return `${selectedObject.date} - ${selectedObject.type}`;
        } else {
            return (
                <PartsDirectDepositContent selectedObject={selectedObject} />
            );
        }
    } else if (selectedType === 'payrun') {
        if (type === 'title') {
            return `Creditor Payrun #${selectedObject.id}`;
        } else if (type === 'subtitle') {
            return `${selectedObject.date} - ${selectedObject.reference}`;
        } else {
            return <PayrunContent selectedObject={selectedObject} />;
        }
    } else if (selectedType === 'bank') {
        if (type === 'title') {
            return `Bank Transaction #${selectedObject.id}`;
        } else if (type === 'subtitle') {
            return `${selectedObject.date}`;
        } else {
            return <BankTransactionContent selectedObject={selectedObject} />;
        }
    } else if (selectedType === 'warranty') {
        if (type === 'title') {
            return 'Service Warranty';
        } else if (type === 'subtitle') {
            return `${selectedObject.date}`;
        } else {
            return <ServiceWarrantyContent selectedObject={selectedObject} />;
        }
    } else if (selectedType === 'rebate') {
        if (type === 'title') {
            return 'Vehicle Rebate';
        } else if (type === 'subtitle') {
            return `${selectedObject.date}`;
        } else {
            return <VehicleRebateContent selectedObject={selectedObject} />;
        }
    }
};

export default RenderDrawerContent;
