// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Vehicle } from '../interfaces/VehicleSaleInterface';
import dayjs from 'dayjs';

const GetVehicleDetails = (
  VehicleId: number,
  setVehicleDetails: Dispatch<SetStateAction<Vehicle>>,
  setLoading?: Dispatch<SetStateAction<boolean>>,
  setInvalid?: Dispatch<SetStateAction<boolean>>
) => {
  if (setLoading) {
    setLoading(true);
  }
  api.get(`vehicleDetails/${VehicleId}`).then((res) => {
    if (res.status === 204 && setInvalid) {
      setInvalid(true);
    }
    let vehicle = res.data
    vehicle.year = dayjs(vehicle.year)
    setVehicleDetails(vehicle);

    if (setLoading) {
      setLoading(false);
    }
  });
};

export default GetVehicleDetails;
