// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Grid,
    Typography,
    Divider,
    TextField,
    Tooltip,
    Chip,
    Stack,
    Switch,
    MenuItem
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../../global/Paper';
import Autocomplete from '../../../../global/Autocomplete';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { Specific } from '../../../../global/interfaces/GeneralInterface';
import { Site } from '../../../../global/interfaces/GeneralInterface';
import RoleCheck from '../../../../global/RoleCheck';
import Drawer from '../../../../global/Drawer';
import TrustDrawerContent from './TrustDrawerContent';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { ToFixed } from '../../../../global/logic/Formatters';

interface SpecificsList {
    makes?: Specific[];
    models?: Specific[];
    series?: Specific[];
    aspirations?: Specific[];
    fuelTypes?: Specific[];
    bodyTypes?: Specific[];
    driveTypes?: Specific[];
    sites?: Site[];
}

interface VehicleDetailsProps {
    vehicle: Vehicle;
    setVehicle: Dispatch<SetStateAction<Vehicle>>;
    specifics: SpecificsList;
    readOnly: boolean;
    showSnackbar: showSnackbar;
}

const MotorcyclesVehicleDetails = ({
    vehicle,
    setVehicle,
    specifics,
    readOnly,
    showSnackbar
}: VehicleDetailsProps) => {
    const [trustDrawerOpen, setTrustDrawerOpen] = useState<boolean>(false);

    return (
        <Paper>
            <Typography variant="h6">Vehicle Details</Typography>
            <Divider />
            <br />
            <Grid container spacing={2}>
                <Tooltip
                    title={
                        !readOnly
                            ? 'Location can not be edited here, to change site create a vehicle transfer.'
                            : ''
                    }
                    placement="top"
                >
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            isDisabled
                            options={specifics?.sites}
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            textfieldLabel="Location"
                            selectedValue={vehicle?.Site}
                            handleSelectedValueChange={() => null}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                </Tooltip>
                <Grid item xs={3}>
                    <Tooltip
                        title={
                            !readOnly
                                ? 'Condition can not be edited. If there has been a mistake contact your admin department.'
                                : ''
                        }
                        placement="top"
                    >
                        <RoleCheck
                            permission="edit_vehicleCondition"
                            component={
                                <TextField
                                    disabled={readOnly}
                                    fullWidth
                                    select
                                    size="small"
                                    label="Condition"
                                    value={vehicle?.condition}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        setVehicle({
                                            ...vehicle,
                                            condition: e.target.value
                                        });
                                    }}
                                >
                                    <MenuItem key={1} value="New">
                                        New
                                    </MenuItem>
                                    <MenuItem key={2} value="Used">
                                        Used
                                    </MenuItem>
                                    <MenuItem key={3} value="Demo">
                                        Demo
                                    </MenuItem>
                                </TextField>
                            }
                            nullComponent={
                                <TextField
                                    disabled
                                    fullWidth
                                    size="small"
                                    label="Condition"
                                    value={vehicle?.condition}
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Odometer"
                        value={vehicle?.odometer}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setVehicle({ ...vehicle, odometer: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            disabled={readOnly}
                            format="MM/YYYY"
                            views={['month', 'year']}
                            label="Compliance Date"
                            value={dayjs(vehicle?.complianceDate)}
                            onChange={(newValue) => {
                                setVehicle({
                                    ...vehicle,
                                    complianceDate:
                                        dayjs(newValue).toISOString()
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Registration"
                        value={vehicle?.registrationNumber}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setVehicle({
                                ...vehicle,
                                registrationNumber: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            disabled={readOnly}
                            format="DD/MM/YYYY"
                            label="Registration Due"
                            value={dayjs(vehicle?.registrationDueDate)}
                            onChange={(newValue) => {
                                setVehicle({
                                    ...vehicle,
                                    registrationDueDate:
                                        dayjs(newValue).toISOString()
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <RoleCheck
                        permission="edit_trustReceipt"
                        component={
                            <TextField
                                disabled={readOnly}
                                fullWidth
                                size="small"
                                label="Trust Receipt"
                                value={vehicle?.trustReceipt}
                                onChange={(e) =>
                                    setVehicle({
                                        ...vehicle,
                                        trustReceipt: e.target.value
                                    })
                                }
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <Chip
                                            onClick={() =>
                                                setTrustDrawerOpen(true)
                                            }
                                            label={
                                                vehicle?.trustPaid &&
                                                parseFloat(
                                                    vehicle?.trustPaidAmount
                                                ) >=
                                                    ToFixed(
                                                        parseFloat(
                                                            vehicle.costPrice
                                                        ) * 1.1
                                                    )
                                                    ? 'Paid'
                                                    : 'Unpaid'
                                            }
                                            variant="outlined"
                                            color={
                                                vehicle?.trustPaid &&
                                                parseFloat(
                                                    vehicle?.trustPaidAmount
                                                ) >=
                                                    parseFloat(
                                                        vehicle.costPrice
                                                    ) *
                                                        1.1
                                                    ? 'success'
                                                    : 'warning'
                                            }
                                        />
                                    )
                                }}
                            />
                        }
                        nullComponent={
                            <Tooltip
                                title={
                                    !readOnly
                                        ? 'Trust Receipt can not be edited. If there has been a mistake contact your admin department.'
                                        : ''
                                }
                                placement="top"
                            >
                                <TextField
                                    disabled
                                    fullWidth
                                    size="small"
                                    label="Trust Receipt"
                                    value={vehicle?.trustReceipt}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <Chip
                                                onClick={() =>
                                                    vehicle?.trustPaid
                                                        ? setTrustDrawerOpen(
                                                              true
                                                          )
                                                        : null
                                                }
                                                label={
                                                    vehicle?.trustPaid
                                                        ? 'Paid'
                                                        : 'Unpaid'
                                                }
                                                variant="outlined"
                                                color={
                                                    vehicle?.trustPaid
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                            />
                                        )
                                    }}
                                />
                            </Tooltip>
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="VIN"
                        value={vehicle?.vin}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setVehicle({ ...vehicle, vin: e.target.value });
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Engine Number"
                        value={vehicle?.engineNumber}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setVehicle({
                                ...vehicle,
                                engineNumber: e.target.value
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Tooltip
                        title={
                            !readOnly
                                ? 'This value is calculated and cannot be edited.'
                                : ''
                        }
                        placement="top"
                    >
                        <TextField
                            disabled
                            fullWidth
                            size="small"
                            label="Age"
                            value={vehicle?.age}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={2}>
                    <Stack direction="row" alignItems="center">
                        <Typography
                            color={readOnly ? 'rgba(0, 0, 0, 0.38)' : ''}
                        >
                            Current
                        </Typography>
                        <Switch
                            disabled={readOnly}
                            checked={vehicle?.aged}
                            onChange={(e) =>
                                setVehicle({
                                    ...vehicle,
                                    aged: e.target.checked
                                })
                            }
                        />
                        <Typography
                            color={readOnly ? 'rgba(0, 0, 0, 0.38)' : ''}
                        >
                            Aged
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Drawer
                openDrawer={trustDrawerOpen}
                setOpenDrawer={setTrustDrawerOpen}
                title="Trust Payment Details"
                subTitle={`Trust #${vehicle?.trustReceipt}`}
            >
                <TrustDrawerContent
                    VehicleId={vehicle.id}
                    showSnackbar={showSnackbar}
                />
            </Drawer>
        </Paper>
    );
};
export default MotorcyclesVehicleDetails;
