// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Grid,
    TableRow,
    TextField,
    Typography,
    Switch,
    Stack,
    IconButton,
    Tooltip
} from '@mui/material';
import { Clear, Replay } from '@mui/icons-material';
// COMPONENTS
import PartSearch from '../../../global/PartSearch';
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
// LOGIC
import HandleAddPart from '../logic/HandleAddPart';
import DataCell from '../../../global/tableComponents/DataCell';
import RoleCheck from '../../../global/RoleCheck';
import HandleChangePartCount from '../logic/HandleChangePartCount';
import ChangeBinLocationSwitch from '../logic/ChangeBinLocationSwitch';
import HandleRemoveRow from '../logic/HandleRemoveRow';
import { CurrencyFormatter } from '../../../global/logic/Formatters';

interface StocktakeDetails {
    countedParts: any[];
    uncountedParts: any[];
    movedBins: any[];
    notes: string;
    review: boolean;
}

interface PartListProps {
    stocktakeDetails: StocktakeDetails;
    setStocktakeDetails: Dispatch<SetStateAction<StocktakeDetails>>;
    pageType: string;
}

const PartList = ({
    stocktakeDetails,
    setStocktakeDetails,
    pageType
}: PartListProps) => {
    const [binLocationSwitch, setBinLocationSwitch] = useState<boolean>(true);

    const columns = [
        { id: 0, label: 'Part Number', width: 100 },
        { id: 1, label: 'Name', width: 250 },
        { id: 2, label: 'Expected', width: 100 },
        { id: 3, label: 'Counted', width: 100 },
        { id: 4, label: 'Variance', width: 100 },
        { id: 5, label: 'Average Cost Price', width: 150 },
        { id: 6, label: 'Proformas', width: 200 },
        { id: 7, label: '', width: 100 }
    ];

    const movedBinReviewColumns = [
        { id: 0, label: 'Part Number', width: 100 },
        { id: 1, label: 'Name', width: 250 },
        { id: 2, label: 'Expected', width: 100 },
        { id: 3, label: 'Counted', width: 100 },
        { id: 4, label: 'Variance', width: 100 },
        { id: 5, label: 'Average Cost Price', width: 150 },
        {
            id: 6,
            width: 200,
            label: (
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>
                        <i>Append</i>
                    </Typography>
                    <Switch
                        checked={binLocationSwitch}
                        onChange={(e) =>
                            ChangeBinLocationSwitch(
                                stocktakeDetails,
                                setStocktakeDetails,
                                setBinLocationSwitch,
                                0,
                                e.target.checked
                            )
                        }
                    />
                    <Typography>
                        <i>Replace</i>
                    </Typography>
                </Stack>
            )
        },
        { id: 7, label: '', width: 100 }
    ];

    const handleAddPart = (unitDetails) => {
        HandleAddPart(unitDetails, stocktakeDetails, setStocktakeDetails);
    };

    const DataTableContent = (part, showExtraColumns) => {
        return (
            <TableRow>
                <DataCellColoured
                    textDecoration={part.removed ? 'line-through black' : ''}
                    handleClick={() => null}
                >
                    {part.partNumber}
                </DataCellColoured>
                <DataCell
                    textDecoration={part.removed ? 'line-through black' : ''}
                >
                    {part.name}
                </DataCell>
                <DataCell
                    textDecoration={part.removed ? 'line-through black' : ''}
                >
                    {part.expected}
                </DataCell>
                <DataCell
                    textDecoration={part.removed ? 'line-through black' : ''}
                >
                    {(pageType === 'Counted' || pageType === 'Uncounted') &&
                    !stocktakeDetails.review &&
                    !part.removed ? (
                        <TextField
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'center' }
                            }} // Center align the value
                            variant="standard"
                            type="number"
                            onChange={(e) =>
                                HandleChangePartCount(
                                    stocktakeDetails,
                                    setStocktakeDetails,
                                    pageType,
                                    part.id,
                                    'counted',
                                    e.target.value
                                )
                            }
                            value={part.counted}
                        />
                    ) : (
                        part.counted
                    )}
                </DataCell>
                <DataCell
                    textDecoration={part.removed ? 'line-through black' : ''}
                >
                    {part.counted - part.expected}
                </DataCell>
                <DataCell
                    textDecoration={part.removed ? 'line-through black' : ''}
                >
                    {pageType === 'Counted' &&
                    !stocktakeDetails.review &&
                    !part.removed ? (
                        <RoleCheck
                            component={
                                <TextField
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'center' }
                                    }} // Center align the value
                                    variant="standard"
                                    onChange={(e) =>
                                        HandleChangePartCount(
                                            stocktakeDetails,
                                            setStocktakeDetails,
                                            pageType,
                                            part.id,
                                            'averageCostPrice',
                                            e.target.value
                                        )
                                    }
                                    value={part.averageCostPrice}
                                    type="number"
                                />
                            }
                            nullComponent={CurrencyFormatter(
                                part.averageCostPrice
                            )}
                            permission={'edit_averagePriceStocktake'}
                        />
                    ) : (
                        CurrencyFormatter(part.averageCostPrice)
                    )}
                </DataCell>
                {!showExtraColumns ? (
                    <DataCell
                        textDecoration={
                            part.removed ? 'line-through black' : ''
                        }
                    >
                        {part.proformas
                            ? part.proformas.map((proforma) => (
                                  <p>
                                      Order #{proforma.OrderId} x
                                      {proforma.quantity}
                                      <br />
                                  </p>
                              ))
                            : null}
                    </DataCell>
                ) : (
                    <>
                        <DataCell
                            textDecoration={
                                part.removed ? 'line-through black' : ''
                            }
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                            >
                                <Typography>
                                    <i>Append</i>
                                </Typography>
                                <Switch
                                    disabled={part.removed}
                                    checked={part.binLocationSwitch}
                                    onChange={(e) =>
                                        ChangeBinLocationSwitch(
                                            stocktakeDetails,
                                            setStocktakeDetails,
                                            setBinLocationSwitch,
                                            part.id,
                                            e.target.checked
                                        )
                                    }
                                />
                                <Typography>
                                    <i>Replace</i>
                                </Typography>
                            </Stack>
                        </DataCell>
                    </>
                )}
                <DataCell align="right">
                    {part.removed ? (
                        <Tooltip title="Restore Row" placement="left">
                            <IconButton
                                onClick={() =>
                                    HandleRemoveRow(
                                        stocktakeDetails,
                                        setStocktakeDetails,
                                        part.id,
                                        false
                                    )
                                }
                            >
                                <Replay />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Remove Row" placement="left">
                            <IconButton
                                onClick={() =>
                                    HandleRemoveRow(
                                        stocktakeDetails,
                                        setStocktakeDetails,
                                        part.id,
                                        true
                                    )
                                }
                            >
                                <Clear />
                            </IconButton>
                        </Tooltip>
                    )}
                </DataCell>
            </TableRow>
        );
    };

    return (
        <>
            {pageType === 'Counted' && !stocktakeDetails.review ? (
                <>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <PartSearch
                                    HandleAdd={handleAddPart}
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <br />
                </>
            ) : null}
            <Paper>
                {pageType === 'Counted' ? (
                    <DataTable columns={columns}>
                        {stocktakeDetails.countedParts.map((part) =>
                            DataTableContent(part, false)
                        )}
                    </DataTable>
                ) : null}
                {pageType === 'Uncounted' ? (
                    <DataTable columns={columns}>
                        {stocktakeDetails.uncountedParts.map((part) =>
                            DataTableContent(part, false)
                        )}
                        <TableRow>
                            <DataCell colSpan={7}>
                                <Typography variant="subtitle2">
                                    <i>
                                        Please note that parts left here will be
                                        submitted with a count of 0.
                                    </i>
                                </Typography>
                            </DataCell>
                        </TableRow>
                    </DataTable>
                ) : null}
                {pageType === 'Variance' ? (
                    <DataTable columns={columns}>
                        <>
                            {stocktakeDetails.countedParts.map((part) =>
                                part.counted !== part.expected
                                    ? DataTableContent(part, false)
                                    : null
                            )}
                            {stocktakeDetails.uncountedParts.map((part) =>
                                part.counted !== part.expected
                                    ? DataTableContent(part, false)
                                    : null
                            )}
                        </>
                    </DataTable>
                ) : null}
                {pageType === 'Moved Bins' && !stocktakeDetails.review ? (
                    <DataTable columns={columns}>
                        {stocktakeDetails.movedBins.map((part) =>
                            DataTableContent(part, false)
                        )}
                    </DataTable>
                ) : pageType === 'Moved Bins' && stocktakeDetails.review ? (
                    <DataTable columns={movedBinReviewColumns}>
                        <>
                            {stocktakeDetails.movedBins.map((part) =>
                                DataTableContent(part, true)
                            )}
                        </>
                    </DataTable>
                ) : null}
            </Paper>
        </>
    );
};

export default PartList;
