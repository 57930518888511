// IMPORTS
import { Dispatch, SetStateAction } from 'react';
//INTERFACES
import {
    Settings,
    Specific,
    Site,
    Till
} from '../../../global/interfaces/GeneralInterface';
import { ShippingMethod } from '../../../global/interfaces/PartsInterface';
// LOGIC
import api from '../../../../../api';

const getAllSiteSettingsById = (
    SiteId: number,
    setSite: Dispatch<SetStateAction<Site>>,
    setTills: Dispatch<SetStateAction<Till[]>>,
    setShippingMethods: Dispatch<SetStateAction<ShippingMethod[]>>,
    setBrandList: Dispatch<SetStateAction<Specific[]>>,
    setSettings: Dispatch<SetStateAction<Settings[]>>,
    setSiteBrands: Dispatch<SetStateAction<Specific[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setInvalid?: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);

    api.get(`getAllSiteSettings/${SiteId}`).then((res) => {
        if (res.status === 204 && setInvalid) {
            setInvalid(true);
        } else {
            // The dropdown for a site's prioritised
            // brands requires an object array.
            // (res.data.site.siteBrands is a string array)
            let siteBrands = [];
            let site = res.data.site;
            let brands = res.data.brands;
            if (site.siteBrands == null) {
                site.siteBrands = [];
            }
            if (site.siteBrands && site.siteBrands.length > 0) {
                for (let brand of site.siteBrands) {
                    let brandObject = brands.filter(
                        (brandOption) => brandOption.name === brand.name
                    );
                    siteBrands.push(brandObject[0]);
                }
            }
            let currentSetting =
                res.data.settings[
                    res.data.settings.findIndex(
                        (x: Settings) => x.id === site.SettingId
                    )
                ];
            site.Setting = currentSetting;
            site.handlesOnlineOrders =
                site.handlesOnlineOrders == null
                    ? false
                    : site.handlesOnlineOrders;
            setSite(site);
            setTills(res.data.tills);
            setShippingMethods(res.data.shippingMethods);
            setBrandList(brands);
            setSiteBrands(siteBrands);
            setSettings(res.data.settings);
        }

        setLoading(false);
    });
};

export default getAllSiteSettingsById;
