// IMPORTS
import { useEffect, useState } from 'react';
import { Typography, CircularProgress, Chip } from '@mui/material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import CreditorDetails from './components/CreditorDetails';
import OpenAndClosedInvoices from './components/OpenAndClosedInvoices';
import PaymentRuns from './components/PaymentRun';
import CreditInvoices from './components/CreditInvoices';
import Tabs from '../../global/Tabs';
import CustomerBackordersTab from './components/CustomerBackordersTab';
// LOGIC
import GetCreditor from '../../global/databaseLogic/GetCreditor';
// INTERFACES
import { Creditor } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import PageDoesNotExist from '../../global/PageDoesNotExist';
import { withSnackbar } from '../../global/WrappingSnackbar';

const ViewCreditor = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    let { id } = useParams<{ id: string }>();
    const [creditorDetails, setCreditorDetails] = useState<Creditor>();
    const [loading, setLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);

    useEffect(() => {
        GetCreditor(id, setCreditorDetails, setLoading, setInvalid);
    }, [id]);

    const tabContent = [
        {
            id: 0,
            title: 'Details',
            content: (
                <CreditorDetails
                    creditorDetails={creditorDetails}
                    setCreditorDetails={setCreditorDetails}
                    showSnackbar={showSnackbar}
                />
            )
        },
        {
            id: 1,
            title: 'Invoices',
            content: (
                <OpenAndClosedInvoices
                    CreditorId={parseInt(id)}
                    showSnackbar={showSnackbar}
                />
            )
        },
        {
            id: 2,
            title: 'Payment',
            content: (
                <PaymentRuns
                    CreditorId={parseInt(id)}
                    showSnackbar={showSnackbar}
                />
            )
        },
        {
            id: 3,
            title: 'Credited Invoices',
            content: <CreditInvoices CreditorId={parseInt(id)} />
        },
        {
            id: 4,
            title: 'Backorders',
            content: (
                <CustomerBackordersTab
                    CreditorId={parseInt(id)}
                    showSnackbar={showSnackbar}
                />
            )
        }
    ];

    return (
        <PageWrapper>
            {loading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4" align="left">
                        {creditorDetails?.name}
                    </Typography>
                    <Chip
                        variant="filled"
                        label={'Code: ' + creditorDetails?.code}
                    />

                    <Tabs tabContent={tabContent} />
                </>
            )}
        </PageWrapper>
    );
};

export default withSnackbar(ViewCreditor);
