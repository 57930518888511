import { useParams } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';

import { GetFileFromS3, UploadFileToS3 } from '../../esign/logic/S3FileService';
import { PDFDocument } from 'pdf-lib';
import AdditionalDealerForms from './components/AdditionalDealerForms';
import PageWrapper from '../../global/PageWrapper';
import { EmailSignUrl } from './logic/EmailContract';
import renderDealerVehicleSaleContract from './logic/renderDealerVehicleSaleContract';
import { withSnackbar } from '../../global/WrappingSnackbar';
import GetDocumentInfo from '../../esign/logic/GetDocumentInfo';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DealerDocumentStatusHeader from '../../esign/components/DocumentStatusHeader';

const DealerSignContract = ({ showSnackbar }) => {
    let { id: vehicleSaleId } = useParams<{ id: string }>();

    const [vehicleCondition, setVehicleCondition] = useState(null);
    const [hasTradeIn, setHasTradeIn] = useState(false);

    const [customerInfo, setCustomerInfo] = useState(null);
    const [contractStatus, setcontractStatus] = useState(null);

    const getContractInfo = async () => {
        const res = await GetDocumentInfo({
            documentType: 'contract',
            vehicleSaleId,
            showSnackbar
        });
        setCustomerInfo(res.customerInfo);
        setcontractStatus(res.contractStatus);
        setVehicleCondition(res.vehicleCondition);
        setHasTradeIn(res.hasTradeIn);
    };

    useEffect(() => {
        if (vehicleSaleId) {
            getContractInfo();
        }
        // eslint-disable-next-line
    }, [vehicleSaleId]);

    const [pdfDoc, setPdfDoc] = useState<PDFDocument>(null);
    const [pdfUrl, setPdfUrl] = useState<string>(null);

    const LoadContractPDF = () => {
        GetFileFromS3({
            folderName: 'vehicleSaleContracts',
            fileName: parseInt(vehicleSaleId),
            setPdfDoc: setPdfDoc,
            setPdfUrl: setPdfUrl
        });
    };

    useEffect(() => {
        LoadContractPDF();
        // eslint-disable-next-line
    }, [vehicleSaleId]);

    const updatePdfUrl = (pdfBlob) => {
        const newPdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(newPdfUrl);
    };

    const baseURL = window.location.origin;
    const customerSignUrl = `${baseURL}/esign/vehicle-contract/${vehicleSaleId}`;
    const customerSignDeliveryUrl = `${baseURL}/esign/vehicle-contract/${vehicleSaleId}?delivery=true`;

    const handleEmailSignUrl = (pdfBlob) => {
        // Convert blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
            const base64data = reader.result as string;
            // Upload the updated contract to S3
            UploadFileToS3({
                base64data: base64data,
                folderName: 'vehicleSaleContracts',
                fileName: parseInt(vehicleSaleId),
                showSnackbar: showSnackbar
            })
                .then(() => {
                    EmailSignUrl({
                        type: 'vehicle-contract',
                        id: parseInt(vehicleSaleId),
                        customerSignUrl: customerSignUrl,
                        newStatus: 'waitingSignature',
                        showSnackbar: showSnackbar
                    });
                })
                .catch((error) => {
                    showSnackbar(
                        'File upload failed',
                        'Please try again later.',
                        'error'
                    );
                    throw error;
                });
        };
    };

    // display the form data on corresponding PDF fields
    const onDealerFormsSubmit = async (formData, submitType) => {
        if (!pdfDoc) return;

        // Make a copy of original document, so everytime formData changes, we draw text on the original document
        const pdfDocCopy = await PDFDocument.load(await pdfDoc.save());

        const pdfBytes = await renderDealerVehicleSaleContract(
            pdfDocCopy,
            formData,
            hasTradeIn,
            vehicleCondition
        );

        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Update the PDF URL to reflect changes
        if (submitType === 'preview') {
            updatePdfUrl(pdfBlob);
        }

        // Upload the updated PDF to S3
        if (submitType === 'upload') {
            handleEmailSignUrl(pdfBlob);
        }
    };

    // when click 'Email Delivery Receipt to Customer' button, open a dialog to confirm sending email to the customer
    const [openEmailDialog, setOpenEmailDialog] = useState(false);

    const handleClickSendEmail = () => {
        EmailSignUrl({
            type: 'vehicle-contract',
            id: parseInt(vehicleSaleId),
            customerSignUrl: customerSignDeliveryUrl,
            newStatus: 'waitingDeliverySignature',
            showSnackbar: showSnackbar
        });
        setOpenEmailDialog(false);
    };

    return (
        <PageWrapper>
            <Box
                sx={{
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <DealerDocumentStatusHeader
                    documentType="Contract"
                    documentId={vehicleSaleId}
                    documentStatus={contractStatus}
                />
                {/* only display the deliver receipt button if the contract status is 'customerSigned' */}
                {contractStatus === 'customerSigned' && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpenEmailDialog(true);
                        }}
                    >
                        Email Delivery Receipt to Customer
                    </Button>
                )}
            </Box>
            <Grid container spacing={2}>
                {!contractStatus && vehicleCondition && (
                    <Grid item xs={12} sm={6}>
                        <AdditionalDealerForms
                            onDealerFormsSubmit={onDealerFormsSubmit}
                            vehicleCondition={vehicleCondition}
                            customer={customerInfo}
                            contractStatus={contractStatus}
                            hasTradeIn={hasTradeIn}
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={!contractStatus ? 6 : 12}>
                    {pdfUrl ? (
                        <Box sx={{ padding: 2 }} width="100%" height="200vh">
                            <iframe
                                src={pdfUrl + '#view=Fit'}
                                width="100%"
                                height="100%"
                                title={`Contract #${vehicleSaleId}`}
                            ></iframe>
                        </Box>
                    ) : (
                        <Box
                            sx={{ padding: 2 }}
                            width="100%"
                            height="100%"
                            textAlign={'center'}
                        >
                            <CircularProgress />
                            <Typography variant="h5">
                                Loading contract...
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Dialog open={openEmailDialog}>
                <DialogTitle>Email Customer</DialogTitle>
                <DialogContent>
                    An email will be sent to <b>{customerInfo?.name}</b> at the
                    following email address: <b>{customerInfo?.email}</b> <br />{' '}
                    Always preview the document before sending an email to the
                    customer.{' '}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEmailDialog(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleClickSendEmail}>
                        Send Email
                    </Button>
                </DialogActions>
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(DealerSignContract);
