// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleDeletePart = (
    partId: number,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.delete('inventory/' + partId).then((res) => {
        if (res.status === 200) {
            showSnackbar('Part successfully deleted.');
            window.location.href = '/inventory/search';
        } else if (res.status === 405) {
            showSnackbar(
                'The part could not be deleted because it has movement or stock.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default HandleDeletePart;
