import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../../api';

const GetReconciliations = (
  selectedDate,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setReconciliations
) => {
  setLoading(true);
  api.get(`reconciledTransactions?date=${selectedDate}`).then((res) => {
    setReconciliations(res.data);
    setLoading(false);
  });
};

export default GetReconciliations;
