import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Site } from '../../../global/interfaces/GeneralInterface';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
const MoveOrder = (
    selectedOrder: GroupedOnlineOrder,
    newSite: Site,
    newStatus: string,
    handleUpdateAllStatesMovement: (
        newStatus: string,
        newSite: Site,
        OrderId: any
    ) => void,
    setMoveDialogOpen: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    api.put('moveOnlineOrder', {
        selectedOrder: selectedOrder,
        newSite: newSite,
        newStatus: newStatus
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Order moved successfully.');
            handleUpdateAllStatesMovement(
                newStatus,
                newSite,
                selectedOrder.oraOrder.id
            );
            setMoveDialogOpen(false);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default MoveOrder;
