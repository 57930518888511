import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../../api';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const UploadTransactionFile = (
    importFile,
    selectedHeaders,
    fileName: string,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    let missingHeader = false;
    let datePresent = false;
    let referencePresent = false;
    let debitPresent = false;
    let creditPresent = false;

    selectedHeaders.forEach((header) => {
        if (header === '') {
            missingHeader = true;
        } else if (header === 'date') {
            datePresent = true;
        } else if (header === 'reference') {
            referencePresent = true;
        } else if (header === 'debitAmount') {
            debitPresent = true;
        } else if (header === 'creditAmount') {
            creditPresent = true;
        }
    });
    if (missingHeader) {
        showSnackbar(
            'Missing header!',
            'Please ensure all columns have an assigned header value (or are removed if not applicable).',
            'error'
        );
        setButtonLoading(false);
    } else if (!datePresent) {
        showSnackbar(
            'Missing date!',
            'Please ensure that a column has been assigned to the date value.',
            'error'
        );
        setButtonLoading(false);
    } else if (!referencePresent) {
        showSnackbar(
            'Missing reference!',
            'Please ensure that a column has been assigned to the reference value.',
            'error'
        );
        setButtonLoading(false);
    } else if (!debitPresent) {
        showSnackbar(
            'Missing debit!',
            'Please ensure that a column has been assigned to the debit value.',
            'error'
        );
        setButtonLoading(false);
    } else if (!creditPresent) {
        showSnackbar(
            'Missing credit!',
            'Please ensure that a column has been assigned to the credit value.',
            'error'
        );
        setButtonLoading(false);
    } else {
        let newImportFile = [...importFile];
        newImportFile = newImportFile.filter((obj) => obj['removed'] !== true);
        newImportFile.forEach((row) => {
            Object.keys(row).forEach((line) => {
                Object.defineProperty(
                    row,
                    selectedHeaders[line],
                    Object.getOwnPropertyDescriptor(row, line)
                );
                delete row[line];
            });
        });

        api.post('newTransactionFile', {
            file: newImportFile,
            fileName: fileName
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'File uploaded successfully.',
                    'The page will now refresh.'
                );
                window.location.reload();
                setButtonLoading(false);
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
                setButtonLoading(false);
            }
        });
    }
};

export default UploadTransactionFile;
