// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

// LOGIC
import RegenerateStockOrderFileRouter from '../logic/RegenerateStockOrderRouter/RegenerateStockOrderFileRouter';
import OrderSummaryToPDF from '../logic/OrderSummaryToPDF';
import UpdateStockOrderToInvoiced from '../logic/UpdateStockOrderToInvoiced';

// INTERFACES
import { StockOrder } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface StockOrderMenuProps {
    row: StockOrder;
    setOpenDrawer: Dispatch<SetStateAction<boolean>>;
    setOrderPreview: Dispatch<SetStateAction<StockOrder>>;
    showSnackbar: showSnackbar;
}

export default function StockOrderMenu(props: StockOrderMenuProps) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon></MoreVertIcon>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem
                    onClick={() => {
                        UpdateStockOrderToInvoiced(
                            props.row.id,
                            props.showSnackbar
                        );
                    }}
                >
                    Set as Invoiced
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        props.setOrderPreview(props.row);
                        props.setOpenDrawer(true);
                    }}
                >
                    Preview Order
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        OrderSummaryToPDF(
                            `assets/stockorders/Order${props.row.id}.pdf`,
                            props.row.orderLines,
                            props.row.id,
                            props.row.SiteId,
                            props.row.Supplier.name,
                            props.row.createdAt
                        )
                    }
                >
                    Print Stock Order Summary
                </MenuItem>
                {props?.row?.Api?.canGenerateOrderFile ? (
                    <MenuItem
                        onClick={() =>
                            RegenerateStockOrderFileRouter(props.row)
                        }
                    >
                        Regenerate Stock Order File
                    </MenuItem>
                ) : null}
            </Menu>
        </div>
    );
}
