import { Typography, useTheme } from '@mui/material';

interface ClickableTypographyInterface {
    string: string;
    link: string;
    variant:
        | 'body1'
        | 'body2'
        | 'button'
        | 'caption'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'inherit'
        | 'overline'
        | 'subtitle1'
        | 'subtitle2';
    display?: string;
}

const ClickableTypography = ({
    string,
    link,
    variant,
    display = 'inline-block'
}: ClickableTypographyInterface) => {
    const currentMode = useTheme().palette.mode;
    return (
        <Typography
            variant={variant}
            sx={{
                color: currentMode === 'dark' ? '#a88ce2' : '#9370DB',
                cursor: 'pointer',
                display: display
            }}
            onClick={() => window.open(link, '_blank')}
        >
            {string}
        </Typography>
    );
};

export default ClickableTypography;
