import DocumentationPage from '../DocumentationPage';
import {
    CreateBackordersSteps,
    OrderBackordersSteps
    // ReceiveBackordersSteps
} from '../data/parts/backorders';

const BackorderSupportPage = () => {
    const contentSections = [
        {
            id: 'add-customer-part-sale',
            title: 'Create backorder in a customer part sale',
            href: '/support/inventory/backorders/#add-customer-part-sale',
            description: (
                <>
                    Customer backorders are created in Parts & Accessories
                    Sales. To create a new sale, refer to{' '}
                    <a
                        href="/support/inventory/newSale/#add-customer-part-sale"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="useful-link"
                    >
                        Add new parts sale for a customer
                    </a>{' '}
                    . To add a backorder in part sale orderlines, follow the
                    steps below:
                </>
            ),
            steps: CreateBackordersSteps
        },
        {
            id: 'order-backorders',
            title: 'Order backorders from supplier',
            href: '/support/inventory/backorders/#order-backorders',
            description:
                'Once backorders are created, you can order the parts from supplier by using the New Stock Order page. Follow the steps below to order backorder parts:',
            steps: OrderBackordersSteps
        }
        // {
        //     id: 'receive-backorders',
        //     title: 'Receive backorders from supplier',
        //     href: '/support/inventory/backorders/#receive-backorders',
        //     description:
        //         'Upon receiving your stock orderAfter ordering backorder parts from the supplier, we need to receive the parts. Follow the steps below to receive backorder parts:',
        //     steps: ReceiveBackordersSteps
        // }
    ];

    return (
        <DocumentationPage
            pageTitle="Backorders"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default BackorderSupportPage;
