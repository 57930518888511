// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    TableRow,
    TextField,
    IconButton,
    Typography,
    MenuItem
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
// INTERFACES
import { Order } from '../../../../global/interfaces/PartsInterface';
interface MiscellaneousCostsProps {
    readOnly: boolean;
    saleDetails: Order;
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
        refunded?: boolean;
    }[];
    setExtraCosts: Dispatch<
        SetStateAction<
            {
                id: number;
                type: string;
                amount: string;
                onlineVoucherCode?: string;
                VoucherId?: number;
                code?: string;
                refunded?: boolean;
            }[]
        >
    >;
    selectedType: string;
    setSaleDetails: Dispatch<SetStateAction<Order>>;
}

/**
 * MiscellaneousCosts
 * Extra added costs in a sale's Orderlines tab
 * @author Sienna
 * @param MiscellaneousCostsProps
 * @returns the Added Costs table
 */
const MiscellaneousCosts = ({
    readOnly,
    saleDetails,
    extraCosts,
    setExtraCosts,
    selectedType,
    setSaleDetails
}: MiscellaneousCostsProps) => {
    let columns = [];

    if (selectedType === 'online') {
        columns = [
            { id: 0, label: 'Cost Type', width: 500 },
            { id: 1, label: 'Amount', width: 200 },
            { id: 2, label: 'Online Voucher Code', width: 300 },
            { id: 3, label: '', width: 10 }
        ];
    } else {
        columns = [
            { id: 0, label: 'Cost Type', width: 500 },
            { id: 1, label: 'Amount', width: 500 },
            { id: 2, label: '', width: 10 }
        ];
    }

    // Handles adding a new extra cost
    const addNewExtraCost = () => {
        let currentExtraCosts = [...extraCosts];

        // Get the new row ID in the same way as parts
        let rowId: number;
        if (currentExtraCosts.length > 0) {
            rowId = currentExtraCosts.at(-1)['id'] + 1;
        } else {
            rowId = 0;
        }

        // Push the new empty row
        currentExtraCosts.push({
            id: rowId,
            type: '',
            amount: '0'
        });

        setExtraCosts(currentExtraCosts);
    };

    // Handles changing the type and the amount in the extra costs array
    const handleChangeValue = (id: number, selector: string, value: string) => {
        let currentExtraCosts = [...extraCosts];
        let index = currentExtraCosts.findIndex((x) => x.id === id);

        currentExtraCosts[index][selector] = value;

        setExtraCosts(currentExtraCosts);
        let totalPrice = 0;
        for (let line of saleDetails.orderLines) {
            totalPrice += line.totalPrice;
        }
        for (let cost of currentExtraCosts) {
            totalPrice += parseFloat(cost.amount);
        }
        setSaleDetails({ ...saleDetails, totalPrice: totalPrice });
    };

    // Get the options list for extra costs, I have done it this way so in the future if any other
    // extra costs need to be added, they can easily just be appended to the list
    const getTypeOptions = (id: number) => {
        let options = [];

        // If the selected type is guest then we cannot sell a voucher as we need customer details for that
        if (
            saleDetails.status === 'Sale' &&
            (selectedType === 'customer' ||
                selectedType === 'online' ||
                selectedType === 'contract')
        ) {
            options.push({ value: 'voucher', label: 'Voucher' });
        }

        // Filter through the current extra costs and see if freight is already been selected
        // We need to check that the row id does not match the current row as this ensures the
        // one row will maintain the value. If we do not include the id check, the row selected
        // will clear the freight value
        let freightAlreadyUsed = extraCosts.filter(
            (x) => x.type === 'freight' && x.id !== id
        );

        // If freight has not yet been selected, push it in to the options list
        if (freightAlreadyUsed.length < 1) {
            options.push({ value: 'freight', label: 'Freight' });
        }

        return options;
    };

    const handleRemoveRow = (id: number) => {
        let currentExtraCosts = [...extraCosts];
        currentExtraCosts = currentExtraCosts.filter((x) => x.id !== id);
        setExtraCosts(currentExtraCosts);
    };

    return (
        <>
            <Paper>
                <Typography variant="h6">Added Costs</Typography>
                <DataTable columns={columns}>
                    {extraCosts.map((row) => (
                        <TableRow>
                            <DataCell>
                                <TextField
                                    select
                                    fullWidth
                                    variant="standard"
                                    disabled={readOnly}
                                    value={row.type}
                                    onChange={(e) =>
                                        handleChangeValue(
                                            row.id,
                                            'type',
                                            e.target.value
                                        )
                                    }
                                    inputProps={{
                                        style: { textAlign: 'center' }
                                    }}
                                >
                                    {getTypeOptions(row.id).map((option) => (
                                        <MenuItem value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </DataCell>
                            <DataCell>
                                {row.refunded ? (
                                    `Refunded ($${row.amount})`
                                ) : (
                                    <TextField
                                        fullWidth
                                        type="number"
                                        variant="standard"
                                        disabled={readOnly}
                                        value={row.amount}
                                        onChange={(e) =>
                                            handleChangeValue(
                                                row.id,
                                                'amount',
                                                e.target.value
                                            )
                                        }
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                )}
                            </DataCell>
                            {selectedType === 'online' ? (
                                <DataCell>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        variant="standard"
                                        disabled={readOnly}
                                        value={row.onlineVoucherCode}
                                        onChange={(e) =>
                                            handleChangeValue(
                                                row.id,
                                                'onlineVoucherCode',
                                                e.target.value
                                            )
                                        }
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                </DataCell>
                            ) : null}
                            <DataCell>
                                <IconButton
                                    size="small"
                                    disabled={readOnly}
                                    onClick={() => handleRemoveRow(row.id)}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </DataCell>
                        </TableRow>
                    ))}
                    {readOnly ? null : (
                        <TableRow>
                            <DataCell
                                colSpan={selectedType === 'online' ? 4 : 3}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => addNewExtraCost()}
                                >
                                    <Add fontSize="small" />
                                </IconButton>
                            </DataCell>
                        </TableRow>
                    )}
                </DataTable>
            </Paper>
        </>
    );
};

export default MiscellaneousCosts;
