import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import PageWrapper from '../../../../global/PageWrapper';
import { PDFDocument } from 'pdf-lib';
import DealerVendorStatementForm from '../dealerForms/DealerVendorStatementForm';
import renderDealerVendorPdf from '../../logic/renderDealerVendorPdf';

const DealerSignVendorStatement = ({
    formData,
    setFormData,
    pdfDoc,
    pdfUrl,
    setPdfUrl,
    contractStatus,
    setVendorStatementBytes
}) => {
    let { id: vehicleId } = useParams<{ id: string }>();

    // display the form data on corresponding PDF fields
    const onDealerVendorStatementSubmit = async (formData, submitType) => {
        if (!pdfDoc) return;

        // Make a copy of original document, so everytime formData changes, we start from the original document
        const pdfDocCopy = await PDFDocument.load(await pdfDoc.save());

        const pdfBytes = await renderDealerVendorPdf(pdfDocCopy, formData);

        setVendorStatementBytes(pdfBytes);

        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        if (submitType === 'preview') {
            setPdfUrl(URL.createObjectURL(pdfBlob));
        }
    };

    return (
        <PageWrapper>
            <Grid container spacing={2}>
                {!contractStatus && (
                    <Grid item xs={12} sm={6}>
                        <DealerVendorStatementForm
                            formData={formData}
                            setFormData={setFormData}
                            onDealerVendorStatementSubmit={
                                onDealerVendorStatementSubmit
                            }
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={contractStatus ? 12 : 6}>
                    {pdfUrl ? (
                        <Box width="100%" height="200vh">
                            <iframe
                                src={pdfUrl + '#view=Fit'}
                                width="100%"
                                height="100%"
                                title={`Vendor Statement #${vehicleId}`}
                            >
                                {' '}
                                Loading PDF...
                            </iframe>
                        </Box>
                    ) : (
                        <Box width="100%" height="100%" textAlign={'center'}>
                            {contractStatus ? (
                                <Typography variant="h5">
                                    No File Found
                                </Typography>
                            ) : (
                                <>
                                    <CircularProgress />
                                    <Typography variant="h5">
                                        Loading form...
                                    </Typography>
                                </>
                            )}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default DealerSignVendorStatement;
