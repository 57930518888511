import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

const EmptyVehicleTableDialogContent = ({
    setIsEmpty,
    setEmptyField
}: {
    setIsEmpty: Dispatch<SetStateAction<boolean>>;
    setEmptyField: Dispatch<SetStateAction<string>>;
}) => {
    return (
        <>
            <DialogTitle>No vehicle created yet</DialogTitle>
            <DialogContent>
                <Typography>
                    Ora couldn't find any vehicle in the database. Do you wish
                    to import vehicle(s) through a CSV?
                </Typography>
                <Typography>
                    You can also manually create vehicle(s), simply close this
                    dialog and click on the pop-up menu in the bottom right of
                    the page and click on "Add Vehicle" which will redirect you
                    to the Invoice Vehicle page.
                </Typography>
                <Typography variant="caption">
                    If you have already created vehicle(s) and this dialog
                    opened by mistake, please contact the Ora development team.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setIsEmpty(false);
                        setEmptyField('');
                    }}
                >
                    Close Dialog
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setIsEmpty(false);
                        setEmptyField('Vehicle');
                    }}
                >
                    Open CSV Import Function
                </Button>
            </DialogActions>
        </>
    );
};

export default EmptyVehicleTableDialogContent;
