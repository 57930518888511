// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Filter } from '../interfaces/FilterInterface';

interface HandleClearFilterProps {
  setFilter: Dispatch<SetStateAction<Filter>>;
  clearedFilter: Filter;
}

/*  HandleClearFilter
    Resets the filter with the given cleared filter
    params:
        - setFilter: the react state setter for the filter
        - clearedFilter: the json object containing a cleared filter
*/
const HandleClearFilter = ({
  setFilter,
  clearedFilter
}: HandleClearFilterProps) => {
  setFilter(clearedFilter);
};

export default HandleClearFilter;
