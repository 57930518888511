import { Step } from '../../interface';

export const ManageVehicleStockSteps: Step[] = [
    {
        label: "Navigate to 'Vehicle Sales' > 'Stock Vehicles' from the sidebar to access the Vehicle Stock page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-vehicle-01.png'
    },
    {
        label: (
            <>
                To add a new vehicle, click 'Add Vehicle' in the speed dial menu
                menu to open the New Vehicle page. Refer to{' '}
                <a
                    href="/support/vehicles/invoiceVehicles/#add-vehicle-invoice"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Add new vehicle invoice
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-vehicle-02.png'
    },
    {
        label: "To edit an existing vehicle, click on the vehicle's stock number to access the Vehicle Details page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-vehicle-03.png'
    },
    {
        label: "On the Vehicle Details page, you can modify the vehicle information by clicking 'Edit Vehicle' or delete the vehicle by clicking Delete Vehicle in the speed dial menu.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-vehicle-04.png'
    }
];

export const ManageManufacturerVehicleStockSteps: Step[] = [
    {
        label: "Open the 'Vehicle Sales' > 'Stock Vehicles' from the sidebar to access the Vehicle stock page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-vehicle-01.png'
    },
    {
        label: 'Click Manufacturer Vehicles in the speed dial menu to open the Manufacturer Vehicles page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-manu-02.png'
    },
    {
        label: 'This page display all Manufacturer Vehicle that can be added into a Vehicle Sale. To add a new manufacturer vehicle, click Add Manufacturer Vehicle in the speed dial menu. This will open the New Manufacturer Vehicle drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-manu-03.png'
    },
    {
        label: 'Fill in all necessary fields in the New Manufacturer Vehicle drawer, then click on the Save button to submit the new manufacturer vehicle.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-manu-04.png'
    },
    {
        label: 'You can find the newly added manufacturer vehicle on the Manufacturer Vehicles page. Click on the vehicle ID to view detailed vehicle information.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-manu-05.png'
    }
];

export const SearchExportVehicleStockSteps: Step[] = [
    {
        label: "Open the 'Vehicle Sales' > 'Stock Vehicles' from the sidebar to access the Vehicle Stock page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/stock-vehicle-01.png'
    },
    {
        label: 'To search for a specific vehicle, enter keywords such as vehicle stock number, short VIN, make, model, series, or age in the search bar and press Enter.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/search-vehicle-02.png'
    },
    {
        label: 'To filter vehicles, click the filter icon to open the Filter dialog. You can filter vehicles by condition, make, model, and site (default set to the site you are logged in), among other criteria. Combine multiple filters to refine your search.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/search-vehicle-03.png'
    },
    {
        label: 'To export all vehicles into a CSV file, click Export Current Results in the speed dial menu. This will generate a CSV file including all vehicles that match your current filter settings.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/search-vehicle-04.png'
    },
    {
        label: 'To export an age stock report, click Age Stock Report in the speed dial menu. This action will generate an age stock report in CSV format, including all vehicles that match your current filter settings.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/stockVehicles/search-vehicle-05.png'
    }
];
