import { Dispatch, SetStateAction } from 'react';
import { StockInvoice } from '../../../global/interfaces/PartsInterface';
import api from '../../../../../api';

// Get the details of a given stock order and prefill the invoice with the lines
const GetStockOrder = (
    StockOrderId: number,
    invoiceData: StockInvoice,
    setInvoiceData: Dispatch<SetStateAction<StockInvoice>>
) => {
    api.get(
        `stockOrderDetails/${StockOrderId}?SiteId=${localStorage.getItem('SiteId')}`
    ).then((res) => {
        let detailedLines = res.data.detailedLines;
        let orderLines = [];
        let linkedOrders = [];

        // Prefill supplier
        let supplier = res.data.stockOrder.Supplier;
        let supplierId = supplier.id;
        let siteId = parseInt(localStorage.getItem('SiteId'));

        // Go throught the stock order lines and create invoice lines for each of them
        detailedLines.forEach((line) => {
            let backorders = line.backorders;
            backorders.forEach((order) => {
                linkedOrders.push(order);
            });

            let rrp =
                line.unit.SupplierIds.length > 0 &&
                line.unit.SupplierIds.filter(
                    (x) => x.SiteId === siteId && x.SupplierId === supplierId
                ).length > 0 &&
                line.unit.SupplierIds.filter(
                    (x) => x.SiteId === siteId && x.SupplierId === supplierId
                )[0].hasOwnProperty('RRP')
                    ? line.unit.SupplierIds.filter(
                          (x) =>
                              x.SiteId === siteId && x.SupplierId === supplierId
                      )[0].RRP
                    : line.unit.priceRRP;
            let cpd =
                line.unit.SupplierIds.length > 0 &&
                line.unit.SupplierIds.filter(
                    (x) => x.SiteId === siteId && x.SupplierId === supplierId
                ).length > 0 &&
                line.unit.SupplierIds.filter(
                    (x) => x.SiteId === siteId && x.SupplierId === supplierId
                )[0].hasOwnProperty('CPD')
                    ? line.unit.SupplierIds.filter(
                          (x) =>
                              x.SiteId === siteId && x.SupplierId === supplierId
                      )[0].CPD
                    : line.unit.costPriceDaily;

            let newLine = {
                id: orderLines.length,
                UnitId: line.unit.id,
                partNumber: line.unit.partNumber,
                name: line.unit.name,
                costOnFile: cpd,
                itemPrice: null,
                lineTotal: null,
                quantityOrdered: line.order.quantity,
                quantityInvoiced: line.order.qtyInvoiced,
                quantityReceived: null,
                customerBackorders: backorders,
                quantityError: false,
                priceError: false,
                printLabel: true,
                isPack: line.unit.canBePack ? line.unit.canBePack : false,
                packQty: line.unit.canBePack ? '0' : null,
                gstFree: line.unit.gstFree,
                priceRRP: rrp,
                binLocation: line.unit?.BinLocationIds[0]?.name,
                addedPart: false,
                removed: false,
                invoiced: line.order.invoiced ? line.order.invoiced : false
            };

            orderLines.push(newLine);
        });
        localStorage.setItem(
            'stockInvoiceData',
            JSON.stringify({
                ...invoiceData,
                orderLines: orderLines,
                linkedOrders: linkedOrders,
                Supplier: supplier,
                SupplierId: supplier.id
            })
        );
        setInvoiceData({
            ...invoiceData,
            orderLines: orderLines,
            linkedOrders: linkedOrders,
            Supplier: supplier,
            SupplierId: supplier.id
        });
    });
};

export default GetStockOrder;
