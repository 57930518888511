// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { TableRow, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import {
    Check,
    RadioButtonUnchecked,
    Reorder,
    CheckCircle,
    LocalShipping,
    Add
} from '@mui/icons-material';
// COMPONENTS
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import Drawer from '../Drawer';
import CustomerDrawerContent from './CustomerDrawerContent';
import ShippingDrawerContent from './ShippingDrawerContent';
import Fab from '../Fab';

// LOGIC

// INTERFACES
import { Customer } from '../../global/interfaces/GeneralInterface';
import { OnlineOrder, Order } from '../interfaces/PartsInterface';
import { VehicleSale } from '../interfaces/VehicleSaleInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedSort = ['id', 'DESC'];

interface CustomerTableProps {
    readOnly: boolean;
    selectedEntity: Customer;
    setSelectedEntity: Dispatch<SetStateAction<Customer>>;
    includeShippingDrawer?: boolean;
    associatedOnlineOrder?: OnlineOrder;
    setAssociatedOnlineOrder?: Dispatch<SetStateAction<OnlineOrder>>;
    saleDetails?: Order;
    setSaleDetails?: Dispatch<SetStateAction<Order>>;
    customRoute?: string;
    vehicleSaleDetails?: VehicleSale;
}

const clearedCustomer = {
    firstName: null,
    lastName: null,
    email: null,
    customerType: 'Retail',
    addressLine1: null,
    addressLine2: null,
    suburb: null,
    postcode: null,
    state: null,
    country: null,
    phoneNumber: null,
    companyName: null,
    abn: null,
    dateOfBirth: null,
    driverLicenceNumber: null,
    debtor: false,
    accountLimit: null,
    paymentTerms: null,
    gstFree: null,
    international: null
};
const CustomerTable = ({
    saleDetails,
    setSaleDetails,
    readOnly,
    selectedEntity,
    setSelectedEntity,
    includeShippingDrawer,
    associatedOnlineOrder,
    setAssociatedOnlineOrder,
    customRoute,
    vehicleSaleDetails
}: CustomerTableProps) => {
    const [resultsList, setResultsList] = useState<Customer[]>([]);
    const [resultsListAltered, setResultsListAltered] = useState<Customer[]>(
        []
    );
    const [shippingDrawerOpen, setShippingDrawerOpen] =
        useState<boolean>(false);
    const [customerDrawerOpen, setCustomerDrawerOpen] =
        useState<boolean>(false);
    const [viewCustomer, setViewCustomer] = useState<Customer>(clearedCustomer);
    const [onlineOrderSuccess, setOnlineOrderSuccess] =
        useState<boolean>(false);
    const [initialLoad, setInitialLoad] = useState(true);

    // Columns for the data table
    const columns = [
        { id: 0, label: '', width: 100 },
        { id: 1, label: 'ID', width: 100 },
        { id: 2, label: 'Personal / Company Name', width: 300 },
        { id: 3, label: 'Email', width: 200 },
        { id: 4, label: 'Phone Number', width: 200 },
        { id: 5, label: 'Debtor', width: 100 },
        { id: 6, label: 'Type', width: 100 },
        { id: 7, label: '', width: 100 }
    ];

    // This clears the customer data when you close the drawer so it is fresh for every set of details
    useEffect(() => {
        if (!customerDrawerOpen) {
            setViewCustomer(clearedCustomer);
        }
    }, [customerDrawerOpen]);

    // This clears the shipping data when you close the drawer so it is fresh for every set of details
    useEffect(() => {
        if (includeShippingDrawer) {
            if (initialLoad) {
                setInitialLoad(false);
            } else if (
                !shippingDrawerOpen &&
                !onlineOrderSuccess &&
                !selectedEntity?.id
            ) {
                setAssociatedOnlineOrder(null);
            }
        }
        // eslint-disable-next-line
    }, [shippingDrawerOpen]);

    useEffect(() => {
        // If someone has been selected make it the only visible option
        if (selectedEntity?.id) {
            let singleRow = [];
            singleRow.push(selectedEntity);
            setResultsListAltered(singleRow);
        } else {
            setResultsListAltered(resultsList);
        }
    }, [selectedEntity, resultsList]);

    // useEffect(() => {
    //     if (!customerDrawerOpen && viewCustomer?.id) {
    //         let currentResults = [...resultsList];
    //         let index = currentResults.findIndex(x => x.id == viewCustomer?.id);
    //         currentResults[index] = viewCustomer
    //         setResultsList(currentResults)
    //     }
    // }, [customerDrawerOpen])

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        return '';
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Customers"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={clearedSort}
                handleRequestCreate={handleRequestCreate}
                route={customRoute ? customRoute : 'customerSearchNextGen'}
                setResultsList={setResultsList}
                customLimit={10}
                disableSearch={selectedEntity?.id ? true : false}
            />

            <Paper>
                {vehicleSaleDetails?.paymentLines?.length > 0 ? (
                    <Typography variant="caption">
                        The customer cannot be changed because payment(s) have
                        been received.
                    </Typography>
                ) : null}
                <DataTable columns={columns} cypressLabel="selectCustomerTable">
                    {resultsListAltered.map((row, index) => (
                        <TableRow key={row.id}>
                            <DataCell>
                                {selectedEntity?.id ? (
                                    <IconButton
                                        data-cy={`selectCustomerTableSelectedRow`}
                                        onClick={() => setSelectedEntity(null)}
                                        disabled={readOnly}
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        data-cy={`selectCustomerTableRow-${index}`}
                                        onClick={() => setSelectedEntity(row)}
                                        disabled={readOnly}
                                    >
                                        <RadioButtonUnchecked />
                                    </IconButton>
                                )}
                            </DataCell>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/viewCustomer/${row.id}`,

                                        '_blank'
                                    )
                                }
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>
                                {row.companyName != null &&
                                row.companyName !== ''
                                    ? row?.companyName?.toUpperCase()
                                    : row?.firstName?.toUpperCase() +
                                      ' ' +
                                      row?.lastName?.toUpperCase()}
                                <br />
                                {row.abn ? `ABN: ${row.abn}` : ''}
                            </DataCell>

                            <DataCell>{row.email}</DataCell>
                            <DataCell>{row.phoneNumber}</DataCell>
                            <DataCell>{row.debtor ? <Check /> : null}</DataCell>
                            <DataCell>
                                <Chip
                                    label={row.customerType}
                                    variant="outlined"
                                    color={
                                        row.customerType === 'Retail'
                                            ? 'success'
                                            : 'warning'
                                    }
                                />
                            </DataCell>
                            <DataCell>
                                <Tooltip title="More Details" placement="left">
                                    <IconButton
                                        onClick={() => {
                                            setViewCustomer(row);
                                            setCustomerDrawerOpen(true);
                                        }}
                                    >
                                        <Reorder />
                                    </IconButton>
                                </Tooltip>
                                {includeShippingDrawer ? (
                                    <Tooltip
                                        title="Shipping Details"
                                        placement="left"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setShippingDrawerOpen(true);
                                            }}
                                        >
                                            <LocalShipping />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={customerDrawerOpen}
                setOpenDrawer={setCustomerDrawerOpen}
                title={
                    viewCustomer?.id
                        ? viewCustomer?.companyName
                            ? viewCustomer?.companyName
                            : `${viewCustomer?.firstName} ${viewCustomer?.lastName}`
                        : 'New Customer'
                }
                subTitle={
                    viewCustomer?.id ? `Customer ID: ${viewCustomer?.id}` : ''
                }
                width="40vw"
            >
                <CustomerDrawerContent
                    customer={viewCustomer}
                    setCustomer={setViewCustomer}
                    setSelectedEntity={setSelectedEntity}
                    resultsList={resultsListAltered}
                    setResultsList={setResultsListAltered}
                    setCustomerDrawerOpen={setCustomerDrawerOpen}
                />
            </Drawer>

            <Drawer
                openDrawer={shippingDrawerOpen}
                setOpenDrawer={setShippingDrawerOpen}
                title="Shipping Details"
                subTitle={''}
                width="40vw"
            >
                <ShippingDrawerContent
                    associatedOnlineOrder={associatedOnlineOrder}
                    setAssociatedOnlineOrder={setAssociatedOnlineOrder}
                    setOnlineOrderSuccess={setOnlineOrderSuccess}
                    saleDetails={saleDetails}
                    setSaleDetails={setSaleDetails}
                />
            </Drawer>

            {readOnly || selectedEntity?.id ? null : (
                <Fab
                    cypressLabel="addCustomerOpenDrawerFAB"
                    customIcon={<Add />}
                    onClick={() => setCustomerDrawerOpen(true)}
                    noSession={true}
                />
            )}
        </>
    );
};

export default CustomerTable;
