import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Registration } from '../../../global/interfaces/VehicleSaleInterface';
import moment from 'moment';

const CreateRegistrationPrice = (
    state: string,
    current: string,
    setRegistrations: Dispatch<SetStateAction<Registration[]>>
) => {
    if (current === 'current') {
        let tempRego = [
            {
                state: state,
                vehicleType: 'Motorcycle',
                length: '6',
                prices: [
                    {
                        total: 3,
                        plateFee: 1,
                        trafficFee: 1,
                        motorVehicleFee: 1
                    }
                ],
                effectiveDate: null,
                stampDuty: [
                    {
                        definition: '1-4 Cylinders',
                        percentage: 3
                    },
                    {
                        definition: '5-6 Cylinders',
                        percentage: 3
                    },
                    {
                        definition: '7+ Cylinders',
                        percentage: 3
                    }
                ],
                outdated: null,
                endDate: null
            },
            {
                state: state,
                vehicleType: 'Motorcycle',
                length: '12',
                prices: [
                    {
                        total: 3,
                        plateFee: 1,
                        trafficFee: 1,
                        motorVehicleFee: 1
                    }
                ],
                effectiveDate: null,
                stampDuty: [
                    {
                        definition: '1-4 Cylinders',
                        percentage: 3
                    },
                    {
                        definition: '5-6 Cylinders',
                        percentage: 3
                    },
                    {
                        definition: '7+ Cylinders',
                        percentage: 3
                    }
                ],
                outdated: null,
                endDate: null
            }
        ];
        api.post('registrations', tempRego).then((res) => {
            setRegistrations(tempRego);
        });
    } else {
        let tempRego = [
            {
                state: state,
                vehicleType: 'Motorcycle',
                length: '6',
                prices: [
                    {
                        total: 3,
                        plateFee: 1,
                        trafficFee: 1,
                        motorVehicleFee: 1
                    }
                ],
                effectiveDate: moment().add(1, 'year').toISOString(),
                stampDuty: [
                    {
                        definition: '1-4 Cylinders',
                        percentage: 3
                    },
                    {
                        definition: '5-6 Cylinders',
                        percentage: 3
                    },
                    {
                        definition: '7+ Cylinders',
                        percentage: 3
                    }
                ],
                outdated: null,
                endDate: null
            },
            {
                state: state,
                vehicleType: 'Motorcycle',
                length: '12',
                prices: [
                    {
                        total: 3,
                        plateFee: 1,
                        trafficFee: 1,
                        motorVehicleFee: 1
                    }
                ],
                effectiveDate: moment().add(1, 'year').toISOString(),
                stampDuty: [
                    {
                        definition: '1-4 Cylinders',
                        percentage: 3
                    },
                    {
                        definition: '5-6 Cylinders',
                        percentage: 3
                    },
                    {
                        definition: '7+ Cylinders',
                        percentage: 3
                    }
                ],
                outdated: null,
                endDate: null
            }
        ];
        api.post('registrations', tempRego).then((res) => {
            setRegistrations(tempRego);
        });
    }
};

export default CreateRegistrationPrice;
