import DocumentationPage from '../DocumentationPage';
import {
    AddCustomerSteps,
    ExportCustomersSteps,
    MergeCustomersSteps,
    SearchCustomersSteps,
    UpdateCustomerSteps
} from '../data/customers/customer';

const CustomersSupportPage = () => {
    const contentSections = [
        {
            id: 'add-customer',
            title: 'Add new customer',
            href: '/support/customers/#add-customer',
            description: 'To create new customer, follow these steps:',
            steps: AddCustomerSteps
        },
        {
            id: 'update-customer',
            title: 'Update existing customer',
            href: '/support/customers/#update-customer',
            description: 'To edit customer, follow these steps:',
            steps: UpdateCustomerSteps
        },
        {
            id: 'search-customer',
            title: 'Search customer',
            href: '/support/customers/#search-customer',
            description:
                'To search customer or filter customers, follow these steps:',
            steps: SearchCustomersSteps
        },
        {
            id: 'merge-customer',
            title: 'Merge existing customers',
            href: '/support/customers/#merge-customer',
            description: 'To merge customers, follow these steps:',
            steps: MergeCustomersSteps
        },
        {
            id: 'export-customer',
            title: 'Export customer to CSV',
            href: '/support/customers/#export-customer',
            description:
                'To export current customer result into a CSV file, follow these steps:',
            steps: ExportCustomersSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Customers"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default CustomersSupportPage;
