import { Button, Typography, useTheme } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { useState } from 'react';
import { CurrencyFormatter, DateSafeFormatter } from '../logic/Formatters';
import { Dayjs } from 'dayjs';

interface DataChangeIndicatorProps {
    currentValue: number;
    previousValue: number;
    category?: string;
    dataType?: 'number' | 'currency';
    compareStartDate?: Dayjs;
    compareEndDate?: Dayjs;
}

const DataChangeIndicator = ({
    currentValue,
    previousValue,
    category,
    dataType,
    compareStartDate,
    compareEndDate
}: DataChangeIndicatorProps) => {
    const currentMode = useTheme().palette.mode;

    let difference = currentValue - previousValue;

    const [showDetails, setShowDetails] = useState<boolean>(false);
    const handleClick = () => {
        setShowDetails(!showDetails);
    };

    const getDateRangeString = () => {
        if (compareStartDate && compareEndDate) {
            return `${DateSafeFormatter(
                compareStartDate.toString()
            )} to ${DateSafeFormatter(compareEndDate.toString())}`;
        }
    };

    return (
        <>
            <Button
                onClick={handleClick}
                style={{
                    color: currentMode === 'dark' ? '#a88ce2' : '#9370DB'
                }}
            >
                {difference > 0 ? (
                    <TrendingUpIcon color="success" />
                ) : difference < 0 ? (
                    <TrendingDownIcon color="error" />
                ) : (
                    <TrendingFlatIcon />
                )}{' '}
                {dataType === 'number'
                    ? Number.isInteger(difference)
                        ? difference
                        : difference.toFixed(2)
                    : CurrencyFormatter(difference)}
            </Button>
            {showDetails && (
                <Typography variant="body2" color="textSecondary">
                    During the same date period ({getDateRangeString()}
                    ), the total finalised {category} was{' '}
                    <strong>
                        {dataType === 'number'
                            ? Number.isInteger(previousValue)
                                ? previousValue
                                : previousValue.toFixed(2)
                            : CurrencyFormatter(previousValue)}
                    </strong>
                    .
                </Typography>
            )}
        </>
    );
};

export default DataChangeIndicator;
