// IMPORTS
import { useState } from 'react';
import { Grid, TableRow, Tooltip } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';
// COMPONENTS
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import TransactionDrawer from './TransactionDrawer';
import Paper from '../../../global/Paper';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import GroupBy from '../../../global/logic/GroupBy';
import { BankTransaction } from '../../../global/interfaces/AdminInterface';
import dayjs, { Dayjs } from 'dayjs';

// INTERFACES
interface BankTransactionFileTableParams {
    transactionsInFiles: BankTransaction[];
    firstDayOfCurrentMonth: Dayjs;
    today: Dayjs;
}

const BankTransactionFileTable = ({
    transactionsInFiles,
    firstDayOfCurrentMonth,
    today
}: BankTransactionFileTableParams) => {
    const columns = [
        { id: 0, label: 'File Name' },
        { id: 1, label: 'Total Transactions' },
        { id: 2, label: 'Reconciled Transactions' },
        { id: 3, label: 'Unreconciled Transactions' },
        {
            id: 4,
            label: 'Total Amount'
        },
        {
            id: 5,
            label: 'Reconciled Amount'
        },
        {
            id: 6,
            label: 'Unreconciled Amount'
        },
        { id: 7, label: 'Unreconciled Transaction Percentage' }
    ];

    const groupedTransactions = GroupBy(
        transactionsInFiles,
        'TransactionFileId'
    );

    const calculateAmount = (transactions) => {
        return transactions.reduce((acc, transaction) => {
            return acc + transaction.amount;
        }, 0);
    };

    // check if the file contains transaction which dataReceived is in the current month period
    const isDateInRange = (dateReceived) => {
        let date = dayjs(dateReceived, 'DD/MM/YYYY');
        return date.isBefore(today) && date.isAfter(firstDayOfCurrentMonth);
    };

    // click on transaction count cell to open drawer
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [drawerTitle, setDrawerTitle] = useState<string>('');
    const [drawerData, setDrawerData] = useState<BankTransaction[]>([]);

    const handleOpenTransactionDrawer = (title, data) => {
        setDrawerOpen(true);
        setDrawerTitle(title);
        setDrawerData(data);
    };

    return (
        <Grid item xs={12} md={6}>
            <Paper>
                <DataTable columns={columns}>
                    {groupedTransactions.map((item) => {
                        const fileName =
                            item[0].TransactionFile.fileName.replaceAll(
                                '%20',
                                ' '
                            );
                        const reconciledTransactions = item.filter(
                            (transaction) => transaction.reconciled === true
                        );
                        const unreconciledTransactions = item.filter(
                            (transaction) =>
                                transaction.reconciled === false ||
                                transaction.reconciled === null
                        );
                        const hasOutOfRangeTransactions = item.some(
                            (transaction) =>
                                !isDateInRange(transaction.dateReceived)
                        );

                        return (
                            <TableRow>
                                <DataCell>
                                    {fileName}{' '}
                                    {hasOutOfRangeTransactions && (
                                        <Tooltip title="This file contains transactions from other months">
                                            <PriorityHigh
                                                color="warning"
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    )}
                                </DataCell>

                                <DataCellColoured
                                    handleClick={() =>
                                        handleOpenTransactionDrawer(
                                            fileName,
                                            item
                                        )
                                    }
                                >
                                    {item.length}
                                </DataCellColoured>

                                <DataCellColoured
                                    handleClick={() =>
                                        handleOpenTransactionDrawer(
                                            'Reconciled Transactions - ' +
                                                fileName,
                                            reconciledTransactions
                                        )
                                    }
                                >
                                    {reconciledTransactions.length}
                                </DataCellColoured>
                                <DataCellColoured
                                    handleClick={() =>
                                        handleOpenTransactionDrawer(
                                            'Unreconciled Transactions - ' +
                                                fileName,
                                            unreconciledTransactions
                                        )
                                    }
                                >
                                    {unreconciledTransactions.length}
                                </DataCellColoured>
                                <DataCell>
                                    {CurrencyFormatter(calculateAmount(item))}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        calculateAmount(reconciledTransactions)
                                    )}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        calculateAmount(
                                            unreconciledTransactions
                                        )
                                    )}
                                </DataCell>
                                <DataCell>
                                    {(
                                        (unreconciledTransactions.length /
                                            item.length) *
                                        100
                                    ).toFixed(2)}
                                    %
                                </DataCell>
                            </TableRow>
                        );
                    })}
                </DataTable>
            </Paper>
            <TransactionDrawer
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                drawerTitle={drawerTitle}
                drawerData={drawerData}
            />
        </Grid>
    );
};

export default BankTransactionFileTable;
