// IMPORTS
import { Dispatch, SetStateAction } from 'react'
// LOGIC
import api from '../../../../api'
// INTERFACES
import { CustomerBackorder } from '../interfaces/PartsInterface'


const GetAllCustomerBackorderForSupplier = (id: number, status: {label:string}[], setCustomerBackorders: Dispatch<SetStateAction<CustomerBackorder[]>>, setLoading?: Dispatch<SetStateAction<boolean>>) => {
    if (setLoading) {
        setLoading(true)
    }
    let statusArray = []
    for (let item of status) {
        statusArray.push(item.label)
    }
    api.get(`customerBackordersSupplier/${id.toString()}?status=${statusArray.toString()}`).then(res => {
        setCustomerBackorders(res.data)
        if (setLoading) {
            setLoading(false)
        }
    })
}

export default GetAllCustomerBackorderForSupplier