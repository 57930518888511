// IMPORTS
import { Chip, TableRow, Tooltip, Typography } from '@mui/material';
// COMPONENTS
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
// LOGIC
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
// INTERFACES
import { Order } from '../../../../global/interfaces/PartsInterface';

interface CSMDrawerContentProps {
    selectedSale: Order;
}
const CSMDrawerContent = ({ selectedSale }: CSMDrawerContentProps) => {
    const columns = [
        { id: 1, label: 'Part Number', width: 150 },
        { id: 2, label: 'Name', width: 200 },
        { id: 3, label: 'Qty', width: 100 },
        { id: 4, label: 'Backorder', width: 100 },
        { id: 5, label: 'Unit Price', width: 100 },
        { id: 6, label: 'Total', width: 100 }
    ];

    return (
        <>
            <DataTable columns={columns}>
                {selectedSale.orderLines.map((line) => {
                    if (selectedSale.LinkedSaleId || !line.inactive) {
                        return (
                            <TableRow>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            `inventory/view/${line.UnitId}`
                                        )
                                    }
                                >
                                    {line.partNumber}
                                </DataCellColoured>
                                <DataCell>
                                    {line.voucherCode ? (
                                        <Tooltip title={line.name}>
                                            <Typography variant="body2">
                                                Voucher
                                            </Typography>
                                        </Tooltip>
                                    ) : line.name.length > 20 ? (
                                        <Tooltip title={line.name}>
                                            <Typography variant="body2">{`${line.name.slice(0, 20)}...`}</Typography>
                                        </Tooltip>
                                    ) : (
                                        <Typography variant="body2">
                                            {line.name}
                                        </Typography>
                                    )}
                                </DataCell>
                                <DataCell>
                                    {line.voucherCode ? 1 : line.quantity}
                                </DataCell>
                                <DataCell>
                                    {line.voucherCode
                                        ? 0
                                        : line.backorderQuantity}
                                </DataCell>
                                <DataCell>
                                    {line.voucherCode ? (
                                        CurrencyFormatter(line.totalPrice)
                                    ) : (
                                        <Tooltip
                                            placement="bottom"
                                            title={
                                                <span
                                                    style={{
                                                        whiteSpace:
                                                            'break-spaces'
                                                    }}
                                                >
                                                    {/* This needs to be on one line due to the spacing within the tooltip */}
                                                    {/* Please don't seperate it out onto multiple lines */}
                                                    {`RRP: ${CurrencyFormatter(line.priceRRP)}\n${line.isTradePrice ? 'Trade RRP: ' + CurrencyFormatter(line.tradeRRP) + '\n' : ''}Average Cost: ${CurrencyFormatter(line.costPriceAverage)}\nDaily Cost: ${CurrencyFormatter(line.costPriceDaily)}\nMargin: ${line.margin}%`}
                                                </span>
                                            }
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: line.inactive
                                                        ? '#808080'
                                                        : ''
                                                }}
                                            >
                                                {CurrencyFormatter(
                                                    line.pricePaid
                                                )}
                                            </Typography>
                                        </Tooltip>
                                    )}
                                </DataCell>
                                <DataCell>
                                    {line.voucherCode ? (
                                        CurrencyFormatter(line.totalPrice)
                                    ) : (
                                        <Tooltip
                                            placement="bottom"
                                            title={`Discount: ${CurrencyFormatter(line.priceRRP - parseFloat(line.pricePaid))}`}
                                        >
                                            <span>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: line.inactive
                                                            ? '#808080'
                                                            : ''
                                                    }}
                                                >
                                                    {CurrencyFormatter(
                                                        line.totalPrice
                                                    )}
                                                </Typography>
                                                {line.gstFree ? (
                                                    <Chip
                                                        label="Ex. GST"
                                                        size="small"
                                                        variant="outlined"
                                                        color="primary"
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </Tooltip>
                                    )}
                                </DataCell>
                            </TableRow>
                        );
                    } else {
                        return null;
                    }
                })}
            </DataTable>
        </>
    );
};

export default CSMDrawerContent;
