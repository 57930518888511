// IMPORTS
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
    Checkbox,
    DialogContentText,
    Grid,
    FormControlLabel,
    FormGroup
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// LOGIC
import GetAllMakes from '../../../global/databaseLogic/GetAllMakes';
import GetAllModels from '../../../global/databaseLogic/GetAllModels';
import GetAllSeries from '../../../global/databaseLogic/GetAllSeries';
// INTERFACES
import { Specific } from '../../../global/interfaces/GeneralInterface';
import { Filter } from '../../../global/interfaces/FilterInterface';

interface ManufacturerVehicleFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

let makeOptions = [];
let modelOptions = [];
let seriesOptions = [];

/**
 * Manufacturer Vehicle Filter
 * @author Estienne
 * @returns {React} a component containing all filtering
 * options for the Manufacturer Vehicle Table
 */
const ManufacturerVehicleFilter = ({
    filter,
    setFilter,
    isFilterActive
}: ManufacturerVehicleFilterProps) => {
    const [makesUnaltered, setMakesUnaltered] =
        useState<Specific[]>(makeOptions);
    const [modelsUnaltered, setModelsUnaltered] =
        useState<Specific[]>(modelOptions);
    const [seriesUnaltered, setSeriesUnaltered] =
        useState<Specific[]>(seriesOptions);

    const [makes, setMakes] = useState<Specific[]>(makeOptions);
    const [models, setModels] = useState<Specific[]>(modelOptions);
    const [series, setSeries] = useState<Specific[]>(seriesOptions);

    // Set both the clean and altered
    // make/model/series arrays
    useEffect(() => {
        GetAllMakes(setMakes);
        GetAllMakes(setMakesUnaltered);
        GetAllModels(setModels);
        GetAllModels(setModelsUnaltered);
        GetAllSeries(setSeries);
        GetAllSeries(setSeriesUnaltered);
    }, []);

    // If no filters are selected,
    // set clean options
    useEffect(() => {
        if (!isFilterActive()) {
            GetAllMakes(setMakesUnaltered);
            GetAllModels(setModelsUnaltered);
            GetAllSeries(setSeriesUnaltered);
        }
        // eslint-disable-next-line
    }, [filter]);

    /**
     * handleUpdateFilter
     * Handles the updating of the filter object
     * @author Estienne
     * @param property the filter property to be updated
     * @param value the value to be applied
     */
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.manufacturerVehicleFilter;
        newFilter[property] = value;
        setFilter({ ...filter, manufacturerVehicleFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {/* Make Filter */}
                    <AutocompleteMultiple
                        options={makes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Makes"
                        selectedValues={filter.manufacturerVehicleFilter.make}
                        handleSelectedValueChange={(event) => {
                            let currentMakeOptions = makesUnaltered;
                            let currentModelOptions = modelsUnaltered;
                            let newModelOptions = [];
                            let newFilterValue = [];

                            // Add/remove the value from the selected filter
                            // Also add/remove the selected option from the list
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentMakeOptions = currentMakeOptions.filter(
                                    (x) => x.id !== val.id
                                );
                                let filteredModels = currentModelOptions.filter(
                                    (x) => x.MakeId === val.id
                                );
                                filteredModels.forEach((model) => {
                                    newModelOptions.push(model);
                                });
                            });

                            setMakes(currentMakeOptions);
                            setModels(newModelOptions);
                            handleUpdateFilter('make', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Model Filter */}
                    <AutocompleteMultiple
                        options={models}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Model"
                        selectedValues={filter.manufacturerVehicleFilter.model}
                        handleSelectedValueChange={(event) => {
                            let currentModelOptions = modelsUnaltered;
                            let newFilterValue = [];

                            // Add/remove the value from the selected filter
                            // Also add/remove the selected option from the list
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentModelOptions =
                                    currentModelOptions.filter(
                                        (x) => x.id !== val.id
                                    );
                            });

                            setModels(currentModelOptions);
                            handleUpdateFilter('model', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Series Filter */}
                    <AutocompleteMultiple
                        options={series}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Series"
                        selectedValues={filter.manufacturerVehicleFilter.series}
                        handleSelectedValueChange={(event) => {
                            let currentSeriesOptions = seriesUnaltered;
                            let newFilterValue = [];

                            // Add/remove the value from the selected filter
                            // Also add/remove the selected option from the list
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentSeriesOptions =
                                    currentSeriesOptions.filter(
                                        (x) => x.id !== val.id
                                    );
                            });

                            setModels(currentSeriesOptions);
                            handleUpdateFilter('series', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="YYYY"
                            views={['year']}
                            label="Year Start Range"
                            value={filter.manufacturerVehicleFilter.yearStart}
                            onChange={(newValue) =>
                                handleUpdateFilter('yearStart', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="YYYY"
                            views={['year']}
                            label="Year End Range"
                            value={filter.manufacturerVehicleFilter.yearEnd}
                            onChange={(newValue) =>
                                handleUpdateFilter('yearEnd', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        filter.manufacturerVehicleFilter
                                            .learnerApproved
                                    }
                                    onChange={(e) =>
                                        handleUpdateFilter(
                                            'learnerApproved',
                                            e.target.checked
                                        )
                                    }
                                />
                            }
                            label="Learner Approved"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </>
    );
};

export default ManufacturerVehicleFilter;
