import { Step } from '../../interface';

export const NewRetailBookingSteps: Step[] = [
    {
        label: "Click 'Service' > 'New Booking' in the sidebar to navigate to the new booking page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-01.png'
    },
    {
        label: 'Select the service type you need.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-02.png'
    },
    {
        label: 'Search for the customer by entering their ID, name, email, or phone number in the search bar. Click the radio button next to the customer name to select them.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-03.png'
    },
    {
        label: 'If you selected an Insurance Job, the Select Insurer tab will let you search for the insurer by entering their ID, name, email, or phone number in the search bar. Click the radio button next to the insurer name to select them.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-insurance-04.png'
    },
    {
        label:
            'Next, under the Select Vehicles tab, select a customer vehicle from existing customer vehicles or create a new vehicle for this customer by clicking on the ' +
            " button. If you haven't selected a Retail Job, you can also select a Vehicle in stock through the Stock Vehicles button",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-04.png'
    },
    {
        label: 'View details of the selected vehicle by clicking on the list button. This will open the vehicle details drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-05.png'
    },
    {
        label: 'Edit the vehicle details by clicking on the edit button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-06.png'
    },
    {
        label: 'The Select Tech lets you select a technician from the list. Search by ID, name, or email, and click the radio button next to the technician name to select them. You will only see technicians from your current site.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-07.png'
    },
    {
        label: 'The Details tab lets you enter the following: service status as "Incomplete" or "Complete" ("Finalised" cannot be selected for a new booking).',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-08.png'
    },
    {
        label: 'Select the booking start and finish date and time.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-09.png'
    },
    {
        label: 'Select a Job Code from the dropdown list if appropriate. For every job code selected, the Job Card Description and Customer Invoice Description will be auto-populated based on the job code. Edit the description if needed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-10.png'
    },
    {
        label: 'The Charges tab lets you add parts, fluids, and other charges as required. To add parts, enter a part number and barcode, then press "Enter" to add the part to the service. In the Parts table, adjust the quantity and charge if needed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-11.png'
    },
    {
        label: (
            <>
                Select fluid from the dropdown list. Fluid will be added to the
                Fluids table. Adjust the litres quantity and charge if needed.
                To add more parts to this dropdown you need to edit a part
                details and tick the Fluid checkbox (see{' '}
                <a
                    href="/support/inventory/search/#update-part"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Update Existing Part
                </a>
                .)
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-12.png'
    },
    {
        label: 'Add miscellaneous charges if applicable by clicking the "+" button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-13.png'
    },
    {
        label: 'Fill in the labor charge, adjust sublet charge, environmental levy, and sundries if needed. The service total will be recalculated with every changes. If you selected an Insurance job, this section will have an additional Customer Excess field. Making any changes to the Service total will update the Labour to account for the changes (increasing the service total will increase the labour) in order to keep the total consistent with each individual charges.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-14.png'
    },
    {
        label: 'Click "Save" in the speed dial menu to save the booking.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-15.png'
    },
    {
        label: 'You will find the booking under the "Service" > "Booking" page. Click the ID to view the booking details or update the booking.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-16.png'
    }
];

export const NewInsuranceBookingSteps: Step[] = [
    {
        label: "Click 'Service' > 'New Booking' in the sidebar to navigate to the new booking page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-01.png'
    },
    {
        label: 'Select the service type as "Insurance".',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-insurance-02.png'
    },

    {
        label: 'Search for the customer by entering their ID, name, email, or phone number in the search bar. Click the radio button next to the customer name to select them.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-insurance-03.png'
    },
    {
        label: 'Under the Select Insurer tab, search for the insurer by entering their ID, name, email, or phone number in the search bar. Click the radio button next to the insurer name to select them.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-insurance-04.png'
    },
    {
        label: 'Next, under the "Select Vehicles" tab, select a vehicle from existing customer vehicles, from stock vehicles, or create a new vehicle for this customer by clicking on the "+" button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-insurance-05.png'
    },
    {
        label: 'Now select a technician from the list. Search by ID, name, or email, and click the radio button next to the technician name to select them. You will only see technicians from your current login site.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-07.png'
    },
    {
        label: 'Under the "Details" tab, fill in booking details. First, select the service status as "Incomplete" or "Complete". You cannot select "Finalised" status for a new booking.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-08.png'
    },
    {
        label: 'Select the booking start and finish date and time.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-09.png'
    },
    {
        label: 'Select a Job Code from the dropdown list. For every job code selected, the Job Card Description and Customer Invoice Description will be auto-populated based on the job code. Edit the description if needed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-10.png'
    },
    {
        label: 'Under the "Charges" tab, you can add parts, fluids, and other charges as required. To add parts, enter a part number and barcode, then press "Enter" to add the part to the service. In the Parts table, adjust the quantity and charge if needed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-11.png'
    },
    {
        label: 'Select fluid from the dropdown list. Fluid will be added to the Fluids table. Adjust the litres quantity and charge if needed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-12.png'
    },
    {
        label: 'Add miscellaneous charges if applicable by clicking the "+" button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-13.png'
    },
    {
        label: 'Fill in the labor charge, adjust sublet charge, environmental levy, and sundries if needed. A service total will be auto-calculated. ',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-14.png'
    },
    {
        label: 'Click "Save" from speed dial menu to save the booking.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-retail-15.png'
    },
    {
        label: 'You will find the booking under the "Service" > "Booking" page. Click the ID to view the booking details or update the booking.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/newBooking/new-insurance-06.png'
    }
];
