import { Step } from '../../interface';

export const AddNewVoucherSteps: Step[] = [
    {
        label: `On the Vouchers page, click ‘Add Gift Voucher’ to be redirected to a New Sale page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-01.png'
    },
    {
        label: `On the New Sale page, select the Customer or Vehicle Contract type. These are the only type currently supported for Gift Voucher`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-02.png'
    },
    {
        label: `Choose a Customer/Vehicle Sale by selecting the corresponding radio button.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-03.png'
    },
    {
        label: `Under the Orderlines tab, set the sale status to Sale. Vouchers can't be created for Proforma or Quote.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-04.png'
    },
    {
        label: `In the Added Costs table, add a voucher and enter the amount. Multiple vouchers can be added to a single order.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-05.png'
    },
    {
        label: `Add the Payment in the Payment tab.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-06.png'
    },
    {
        label: `Click the Save Icon to create the sale and the Gift Voucher. A Tax Invoice PDF will be generated in a new window and the Voucher will appear with its balance and code.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-07.png'
    },
    {
        label: `All created Vouchers can be viewed in the Vouchers page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-08.png'
    },
    {
        label: `By clicking on a voucher ID in the Voucher Table, you can view the Voucher code and any payment made using the Voucher.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-09.png'
    },
    {
        label: `To reprint a specific voucher, e.g., #535, click the ‘Printer’ icon. A PDF with the voucher code and current balance will open in a new window.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-voucher-10.png'
    }
];

export const PayWithVoucherSteps: Step[] = [
    {
        label: `In the Payment Method dropdown menu, select VOUCHER.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/use-voucher-01.png'
    },
    {
        label: `Enter the Voucher code and press Enter.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/use-voucher-02.png'
    },
    {
        label: `If the code does not exist, you will see an error message: 'Voucher with that code not found.'`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/use-voucher-03.png'
    },
    {
        label: `If the code does exist, a Voucher Details drawer will appear, displaying the owning customer, the full value of the voucher, and the remaining balance. Adjust the amount to use and press Enter, or click Add Payment.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/use-voucher-04.png'
    },
    {
        label: `The voucher is now added as payment. For example, $88 will be deducted from the voucher balance once the order is saved.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/use-voucher-06.png'
    }
];
