// COMPONENTS
import Trades from './Trades';
import Sales from './Sales';
import Transfers from './Transfers';
import Invoices from './Invoices';


// INTERFACES
import {
  TradeIn,
  Vehicle,
  VehicleSale,
  VehicleTransfer
} from '../../../../global/interfaces/VehicleSaleInterface';
import { Invoice } from '../../../../global/interfaces/PartsInterface';

interface HistoryProps {
  vehicle: Vehicle;
  vehicleMovement: VehicleMovement;
}

interface VehicleMovement {
  invoices: Invoice[];
  trades: TradeIn[];
  transfers: VehicleTransfer[];
  sales: VehicleSale[];
}

const History = ({ vehicle, vehicleMovement }: HistoryProps) => {
  return (
    <>
      {vehicleMovement.invoices.length > 0 ? (
        <>
          <Invoices invoices={vehicleMovement.invoices} />
          <br />
        </>
      ) : null}

      {vehicleMovement.trades.length > 0 ? (
        <>
          <Trades trades={vehicleMovement.trades} />
          <br />
        </>
      ) : null}

      {vehicleMovement.transfers.length > 0 ? (
        <>
          <Transfers transfers={vehicleMovement.transfers} vehicle={vehicle} />{' '}
          <br />
        </>
      ) : null}

      {vehicleMovement.sales.length > 0 ? (
        <>
          <Sales sales={vehicleMovement.sales} />
          <br />
        </>
      ) : null}
    </>
  );
};
export default History;
