import DocumentationPage from '../DocumentationPage';
import {
    LoginSteps,
    ManageUsersSteps,
    ManageSitesSteps,
    CompanySettingsSteps,
    HomePageSteps,
    ManageEmailTextTemplatesSteps,
    SpecificsManagementSteps
} from '../data/overview/overview';

const OverviewSupportPage = () => {
    const contentSections = [
        {
            id: 'login',
            title: 'Login',
            href: '/support/#login',
            description:
                'To log in, log out, and reset password, follow these steps:',
            steps: LoginSteps
        },
        {
            id: 'home',
            title: 'Home Page',
            href: '/support/#home',
            description:
                'To navigate the home page and access various features, such as site switching, clocking on and off, updating your personal settings, setting up daily tasks and more options, follow these steps:',
            steps: HomePageSteps
        },
        {
            id: 'manage-users',
            title: 'Manage users',
            href: '/support/#manage-users',
            description:
                'To add new users, update existing users, reset user passwords, update user permissions, and more, follow these steps:',
            steps: ManageUsersSteps
        },
        {
            id: 'manage-site-settings',
            title: 'Manage site settings',
            href: '/support/#manage-site-settings',
            description:
                'To view and update existing site settings, follow these steps:',
            steps: ManageSitesSteps
        },
        {
            id: 'company-settings',
            title: 'Company settings',
            href: '/support/#company-settings',
            description:
                'To view and update company settings including business details, payment methods, sale warranties, departments, current and future registration/CTP prices, follow these steps:',
            steps: CompanySettingsSteps
        },
        {
            id: 'specifics-management',
            title: 'Specifics management',
            href: '/support/#specifics-management',
            description:
                'To manage specifics, such as make, model, series, etc, follow these steps:',
            steps: SpecificsManagementSteps
        },
        {
            id: 'manage-email-text-templates',
            title: 'Manage email & text templates',
            href: '/support/#manage-email-text-templates',
            description:
                'To update existing email and text templates and create new templates, follow these steps:',
            steps: ManageEmailTextTemplatesSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Overview"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default OverviewSupportPage;
