import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import { CalculateServiceParams } from '../interface/PNLInterfaces';

const CalculateService: CalculateServiceParams = (
    services,
    setServiceData,
    setServiceValues,
    setWrongServiceData
) => {
    var serviceData = [];
    var wrongServiceData = [];
    var serviceValues = {
        retailTotal: 0,
        retailCost: 0,
        retailRevenue: 0,
        internalTotal: 0,
        internalCost: 0,
        internalRevenue: 0,
        warrantyTotal: 0,
        warrantyCost: 0,
        warrantyRevenue: 0,
        insuranceTotal: 0,
        insuranceCost: 0,
        insuranceRevenue: 0
    };

    services.forEach((service) => {
        let totalServiceCost = 0;
        let servicePartRevenue = 0;

        if (!service.labourCost) {
            let saleLine = {
                ServiceId: service.id,
                orderType: 'Service',
                serviceType: service.jobType,
                message:
                    'Labour charge is empty, please set it to 0 if no charge.',
                submessage: ''
            };
            wrongServiceData.push(saleLine);
        }

        if (!service.sundries) {
            let saleLine = {
                ServiceId: service.id,
                orderType: 'Service',
                serviceType: service.jobType,
                message:
                    'Sundries charge is empty, please set it to 0 if no charge.',
                submessage: ''
            };
            wrongServiceData.push(saleLine);
        }

        if (!service.environmentalLevy || service.environmentalLevy === ' ') {
            let saleLine = {
                ServiceId: service.id,
                orderType: 'Service',
                serviceType: service.jobType,
                message:
                    'Environmental Levy is empty, please set it to 0 if no charge.',
                submessage: ''
            };
            wrongServiceData.push(saleLine);
        }

        if (service.orderData.length > 0) {
            service.orderData.forEach((order) => {
                order.orderLines.forEach((line) => {
                    totalServiceCost += line.gstFree
                        ? parseFloat(line.pricePaid) * parseFloat(line.quantity)
                        : (parseFloat(line.pricePaid) / 1.1) *
                          parseFloat(line.quantity);
                    servicePartRevenue += line.gstFree
                        ? parseFloat(line.priceCharged ?? line.pricePaid) *
                          parseFloat(line.quantity)
                        : (parseFloat(line.priceCharged ?? line.pricePaid) /
                              1.1) *
                          parseFloat(line.quantity);
                });
            });
        }

        if (service.addedCosts.length > 0) {
            let totalPartRevenue = 0;

            service.addedCosts.forEach((part) => {
                if (part.partType === 'fluid') {
                    if (!part.costPerLitre || !part.pricePaid) {
                        let message =
                            !part.costPerLitre && !part.pricePaid
                                ? 'No cost per litre or sale price entered.'
                                : !part.pricePaid
                                  ? 'No sale price entered.'
                                  : 'No cost per liter price entered';
                        let saleLine = {
                            ServiceId: service.id,
                            orderType: 'Service',
                            serviceType: service.jobType,
                            message: message,
                            costAverage: part.costPriceAverage,
                            costDaily: part.costPriceDaily,
                            submessage: ''
                        };
                        wrongServiceData.push(saleLine);
                    } else {
                        if (service.jobType === 'Internal') {
                            totalServiceCost +=
                                parseFloat(part.costPerLitre) *
                                parseFloat(part.quantity);
                            totalPartRevenue +=
                                parseFloat(part.pricePaid) *
                                parseFloat(part.quantity);
                        } else {
                            totalServiceCost +=
                                parseFloat(part.costPerLitre) *
                                parseFloat(part.quantity);
                            totalPartRevenue +=
                                parseFloat(part.pricePaid) *
                                parseFloat(part.quantity) *
                                1.1;
                        }
                    }
                } else {
                    if (!part.costPriceAverage || !part.pricePaid) {
                        let message =
                            !part.costPriceAverage && !part.pricePaid
                                ? 'No average cost or sale price entered.'
                                : !part.pricePaid
                                  ? 'No sale price entered.'
                                  : 'No average cost price entered';
                        let saleLine = {
                            ServiceId: service.id,
                            orderType: 'Service',
                            serviceType: service.jobType,
                            message: message,
                            costAverage: part.costPriceAverage,
                            costDaily: part.costPriceDaily,
                            submessage: !part.costPriceAverage
                                ? 'Daily Cost: ' +
                                  CurrencyFormatter(part.costPriceDaily)
                                : ''
                        };
                        wrongServiceData.push(saleLine);
                    }
                    let averageCheck = Math.abs(
                        parseFloat(part.costPriceAverage) -
                            parseFloat(part.costPriceDaily)
                    );

                    if (averageCheck >= parseFloat(part.costPriceDaily) * 2) {
                        let saleLine = {
                            ServiceId: service.id,
                            orderType: 'Service',
                            serviceType: service.jobType,
                            message:
                                'Cost price average is signficantly larger than cost price daily.',
                            submessage:
                                'Daily: ' +
                                CurrencyFormatter(part.costPriceDaily) +
                                ', Average: ' +
                                CurrencyFormatter(part.costPriceAverage)
                        };
                        wrongServiceData.push(saleLine);
                    }

                    if (service.jobType === 'Internal') {
                        if (!part.costPriceAverage && !part.costPriceDaily) {
                            totalServiceCost = part.totalCost;
                        } else {
                            totalServiceCost +=
                                parseFloat(
                                    part.costPriceAverage ?? part.costPriceDaily
                                ) * parseFloat(part.quantity);
                        }
                        totalPartRevenue +=
                            parseFloat(part.pricePaid) *
                            parseFloat(part.quantity);
                    } else {
                        totalServiceCost += part.totalCost
                            ? parseFloat(part.totalCost)
                            : part.costPriceAverage
                              ? parseFloat(part.costPriceAverage) *
                                parseFloat(part.quantity)
                              : part.costPriceDaily
                                ? parseFloat(part.costPriceDaily) *
                                  parseFloat(part.quantity)
                                : 0;
                        totalPartRevenue += part.rowTotal
                            ? parseFloat(part.rowTotal) / 1.1
                            : part.totalPrice
                              ? part.totalPrice
                              : parseFloat(part.pricePaid) *
                                parseFloat(part.quantity);
                    }
                }
            });
            servicePartRevenue += totalPartRevenue;
        }

        let labourCharge =
            service.labourCost && service.labourCost !== ' '
                ? parseFloat(service.labourCost)
                : 0;
        let environmentalLevy =
            service.environmentalLevy && service.environmentalLevy !== ' '
                ? parseFloat(service.environmentalLevy)
                : 0;
        let sundries =
            service.sundries && service.sundries !== ' '
                ? parseFloat(service.sundries)
                : 0;
        let subletTotal = service.subletTotal
            ? parseFloat(service.subletTotal)
            : 0;

        if (service.serviceWarranties.length > 0) {
            for (let line of service.serviceWarranties) {
                totalServiceCost -= line.amount;
            }
        }

        let miscCostRevenue = 0;
        if (service.miscCosts.length > 0) {
            for (let misc of service.miscCosts) {
                miscCostRevenue += parseFloat(misc.amount);
            }
        }

        let totalServiceRevenue =
            (servicePartRevenue ?? 0) +
            labourCharge +
            environmentalLevy +
            sundries +
            subletTotal +
            miscCostRevenue;

        if (service.serviceInvoices) {
            service.serviceInvoices.forEach((invoice) => {
                totalServiceCost += parseFloat(
                    invoice?.StockInvoice?.documentTotal
                );
            });
        }

        let saleLine = {
            ServiceId: service.id,
            serviceType: service.jobType,
            totalCost: totalServiceCost,
            totalSale: totalServiceRevenue,
            customerName: null,
            vehicleStockNumber: null
        };

        if (service.jobType === 'Retail') {
            if (service['Customer.customerType'] !== 'Retail') {
                saleLine.customerName = service['Customer.companyName'];
            } else {
                if (
                    service['Customer.firstName'] &&
                    service['Customer.lastName']
                ) {
                    saleLine.customerName =
                        service['Customer.firstName'].charAt(0) +
                        '. ' +
                        service['Customer.lastName'];
                }
            }

            serviceValues.retailTotal += 1;
            serviceValues.retailCost += totalServiceCost;
            serviceValues.retailRevenue += totalServiceRevenue;
        }
        if (service.jobType === 'Internal') {
            saleLine.vehicleStockNumber = service['Vehicle.stockNumber'];

            serviceValues.internalTotal += 1;
            serviceValues.internalCost += totalServiceCost;
            serviceValues.internalRevenue += totalServiceRevenue;
        }
        if (service.jobType === 'Warranty') {
            saleLine.customerName =
                service['Customer.firstName']?.charAt(0) +
                '. ' +
                service['Customer.lastName'];

            serviceValues.warrantyTotal += 1;
            serviceValues.warrantyCost += totalServiceCost;
            serviceValues.warrantyRevenue += totalServiceRevenue;
        }
        if (service.jobType === 'Insurance') {
            saleLine.customerName = service['Customer.firstName']
                ? service['Customer.firstName'].charAt(0) +
                  '. ' +
                  service['Customer.lastName']
                : 'Missing Customer Name';

            serviceValues.insuranceTotal += 1;
            serviceValues.insuranceCost += totalServiceCost;
            serviceValues.insuranceRevenue += totalServiceRevenue;
        }

        serviceData.push(saleLine);
    });

    if (setServiceData) setServiceData(serviceData);
    if (setServiceValues) setServiceValues(serviceValues);
    if (setWrongServiceData) setWrongServiceData(wrongServiceData);

    return serviceValues;
};

export default CalculateService;
