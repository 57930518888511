// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Unit } from '../../../global/interfaces/PartsInterface';

/*  HandleResetAverageCost
    Resets the average cost to the daily cost
    params:
        - partId: The id of the unit being adjusted
        - unitData: The object containing the unit details
        - setSnackbar: The setter state for the snackbar to send user feedback
*/
const HandleResetAverageCost = (
    partId: number,
    unitData: Unit,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.put('resetAverageCost', {
        id: partId,
        costPriceDaily: unitData.costPriceDaily,
        siteId: parseInt(localStorage.getItem('SiteId'))
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Average cost reset to daily cost.',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! It appears something has gone wrong.',

                'If this problem persists please contact your IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default HandleResetAverageCost;
