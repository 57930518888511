// IMPORTS
import jsPDF from "jspdf";

// LOGIC
import { DateFormatter } from "../../../global/logic/Formatters";
import TransferSlipHeader from "./TransferSlipHeader";
import InvoiceTable from "../../../global/invoiceComponents/InvoiceTable";
import { StockTransfer } from "../../../global/interfaces/PartsInterface";

const GenerateTransferSlip = async (transferData: StockTransfer) => {

    var doc = new jsPDF({
        orientation: "p",
        format: "a4",
    });

    // Header for the invoice
    await TransferSlipHeader(
        doc,
        transferData.SiteFromId,
        transferData.SiteToId,
        'Stock Transfer #' + transferData.id,
        DateFormatter(String(new Date(Date.now()))),
    )

    let header = [['Part Number', 'Name', 'Quantity Sent']]
    let body = []
    for (let line of transferData.transferLines) {
        body.push([line.partNumber, line.name, line.quantitySent.toString()])
    }

    InvoiceTable(doc, 90, header, body)

    // Open the pdf
    window.open(doc.output("bloburl"));
}

export default GenerateTransferSlip