// IMPORTS
import { Dispatch, SetStateAction } from 'react';
//INTERFACES
import {
    Settings,
    Specific
} from '../../../global/interfaces/GeneralInterface';
// LOGIC
import api from '../../../../../api';

const getInitialSiteSettings = (
    setBrandList: Dispatch<SetStateAction<Specific[]>>,
    setSettings: Dispatch<SetStateAction<Settings[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    api.get(`getInitialSiteSettings`).then((res) => {
        setBrandList(res.data.brands);
        setSettings(res.data.settings);
        setLoading(false);
    });
};

export default getInitialSiteSettings;
