// IMPORTS
import { useState } from 'react';
import {
    Typography,
    TableRow,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Chip
} from '@mui/material';
import { Clear, PriceCheck, Print } from '@mui/icons-material';

// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import Drawer from '../../global/Drawer';
import ManualInvoiceDrawerContent from './components/ManualInvoiceDrawerContent';
import { withSnackbar } from '../../global/WrappingSnackbar';

// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../global/logic/Formatters';
import DeleteManualInvoice from './logic/DeleteManualInvoice';
import ReprintGeneratedInvoice from './logic/ReprintGeneratedInvoice';
import UpdatePaidStatus from './logic/UpdatePaidStatus';

// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { ManualInvoice } from '../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = { accountFilter: { type: [], site: [] } };
const clearedSort = ['id', 'DESC'];

const ManualInvoiceTable = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<ManualInvoice[]>([]);

    // Drawer State
    const [selectedInvoice, setSelectedInvoice] = useState<ManualInvoice>();
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    // Dialog State
    const [openDeletionDrawer, setOpenDeletionDrawer] =
        useState<boolean>(false);

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'Creditor/Customer' },
        { id: 2, label: 'Total (Inc. GST)' },
        { id: 3, label: 'Date' },
        { id: 4, label: 'Location' },
        { id: 5, label: 'Payment Status' },
        { id: 6, label: '' }
    ];

    const getInvoiceTotal = (row) => {
        let total = 0;
        for (let line of row.ManualInvoiceLines) {
            total += line.amount;
        }
        return total * 1.1;
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let apiString = '';
        return apiString;
    };

    // Checks if any options for filter have been selected and return result

    return (
        <PageWrapper>
            <Typography variant="h4">Manually Generated Invoice</Typography>
            <br />
            <TableSearch
                searchTitle="Search Invoice (ID, Creditor/Customer, Site)"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="manualInvoiceSearchNextGen"
                setResultsList={setResultsList}
            />

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={false}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() => {
                                    setSelectedInvoice(row);
                                    setOpenDrawer(true);
                                }}
                            >
                                M{row.id}
                            </DataCellColoured>
                            <DataCell>
                                {row.SupplierId
                                    ? row.Supplier.name
                                    : row.Customer.firstName +
                                      ' ' +
                                      row.Customer.lastName}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(getInvoiceTotal(row))}
                            </DataCell>
                            <DataCell>{DateFormatter(row.createdAt)}</DataCell>
                            <DataCell>{row?.Site?.name}</DataCell>
                            <DataCell>
                                {row.manuallySetAsPaid ? (
                                    <Chip
                                        variant="outlined"
                                        color="success"
                                        label="Paid"
                                    />
                                ) : (
                                    <IconButton
                                        onClick={() => {
                                            UpdatePaidStatus(
                                                row.id,
                                                showSnackbar
                                            );
                                        }}
                                    >
                                        <PriceCheck />
                                    </IconButton>
                                )}
                            </DataCell>
                            <DataCell>
                                <IconButton
                                    onClick={() => {
                                        ReprintGeneratedInvoice(row);
                                    }}
                                >
                                    <Print />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        setSelectedInvoice(row);
                                        setOpenDeletionDrawer(true);
                                    }}
                                >
                                    <Clear />
                                </IconButton>
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                title={`Invoice M${selectedInvoice?.id}`}
                subTitle={`For ${selectedInvoice?.CustomerId ? `Customer: ${selectedInvoice?.Customer?.firstName} ${selectedInvoice?.Customer?.lastName}` : `Creditor: ${selectedInvoice?.Supplier?.name}`}`}
                width="40vw"
            >
                <ManualInvoiceDrawerContent
                    selectedInvoice={selectedInvoice}
                    showSnackbar={showSnackbar}
                />
            </Drawer>

            <Dialog
                open={openDeletionDrawer}
                onClose={() => setOpenDeletionDrawer(false)}
            >
                <DialogTitle>
                    You are about to delete invoice M{selectedInvoice?.id}.
                </DialogTitle>
                <DialogContent>
                    This action is irreversible, please make sure before
                    deleting this invoice.
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => setOpenDeletionDrawer(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() =>
                            DeleteManualInvoice(
                                selectedInvoice?.id,
                                showSnackbar
                            )
                        }
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(ManualInvoiceTable);
