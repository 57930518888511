import { Dispatch, SetStateAction } from 'react';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import api from '../../../../../api';

interface GroupedOnlineOrder {
  oraOrder: Order;
  netoOrder: OnlineOrder;
}
interface GroupedOnlineOrders {
  pending: GroupedOnlineOrder[];
  dispatched: GroupedOnlineOrder[];
}

const GetAllPendingDispatchedOrders = (
  setPendingDispatchedOrders: Dispatch<SetStateAction<GroupedOnlineOrders>>,
  setSelectedGroupOrders: Dispatch<SetStateAction<GroupedOnlineOrder[]>>,
  setPageLoading: Dispatch<SetStateAction<boolean>>
) => {
  setPageLoading(true);
  api
    .get(`allPendingDispatchedOrders?SiteId=${localStorage.getItem('SiteId')}`)
    .then((res) => {
      setPendingDispatchedOrders(res.data);
      setSelectedGroupOrders(res.data.pending);
      setPageLoading(false);
    });
};

export default GetAllPendingDispatchedOrders;
