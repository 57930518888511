import {
    Grid,
    TableRow,
    Typography,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import GroupBy from '../../../global/logic/GroupBy';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import { useState } from 'react';
import BookingBreakdownDrawer from './BookingBreakdownDrawer';
import BookingCharts from './BookingCharts';
import { DashboardService } from '../../../global/dashboard/interface';

interface PieData {
    id: string | number;
    label: string;
    total: number;
    value: string | number;
}

interface ServiceData {
    ServiceId: number;
    TechId: number;
    serviceComplete: string;
    serviceType: string;
    totalCost: number;
    totalSale: number;
    customerName: string;
    vehicleStockNumber: string;
    totalPrice?: number;
    totalPayment?: number;
}

interface BookingBreakdownTableParams {
    incompleteServiceData: ServiceData[];
    completeServiceData: ServiceData[];
    finalisedServiceData: ServiceData[];
    selectedMonthFinalisedRevenue: number;
    bookingsSelectedMonth: DashboardService[];
}

const BookingBreakdownTable = ({
    incompleteServiceData,
    completeServiceData,
    finalisedServiceData,
    selectedMonthFinalisedRevenue,
    bookingsSelectedMonth
    // currentMonthRevenue
}: BookingBreakdownTableParams) => {
    const serviceData = [
        ...incompleteServiceData,
        ...completeServiceData,
        ...finalisedServiceData
    ];

    const [selectedStatus, setSelectedStatus] = useState('Finalised');

    let typeJobCountPieData: PieData[] = [];
    let typeRevenuePieData: PieData[] = [];
    let techJobCountPieData: PieData[] = [];
    let techRevenuePieData: PieData[] = [];

    const technicianBrkColumns = [
        { id: 0, label: 'Technician' },
        { id: 1, label: 'Assigned Jobs' },
        { id: 2, label: 'Complete Jobs' },
        { id: 3, label: 'Finalised Jobs' },
        { id: 4, label: 'Total Finalised Revenue' },
        { id: 5, label: 'Total Finalised Cost' },
        { id: 6, label: 'Gross Profit' },
        { id: 7, label: 'Average Revenue Per Job' }
    ];

    const jobTypeBrkColumns = [
        { id: 0, label: 'Job Type' },
        { id: 1, label: 'Job Count' },
        { id: 2, label: 'Total Revenue' },
        { id: 3, label: 'Total Cost' },
        { id: 4, label: 'Gross Profit' },
        { id: 5, label: 'Average Revenue' },
        { id: 6, label: 'Average Gross Profit' },
        { id: 7, label: 'Average Margin' }
    ];

    // group bookings by technician object
    const bookingsGroupdByTech = GroupBy(bookingsSelectedMonth, 'Tech');

    // group bookings by job type
    const bookingGroupedByType = GroupBy(bookingsSelectedMonth, 'jobType');

    // calculate how many jobs, revenue, cost for each type, and status
    const getTypeRevenue = (jobType: string, jobStatus: string) => {
        let totalJobs = 0;
        let totalRevenue = 0;
        let totalCost = 0;

        serviceData.forEach((service) => {
            if (
                service.serviceType === jobType &&
                service.serviceComplete === jobStatus
            ) {
                totalJobs++;

                totalRevenue += service.totalSale;
                totalCost += service.totalCost;
            }
        });

        return {
            totalJobs,
            totalRevenue,
            totalCost
        };
    };

    // prepare data for pie chart: percentage of job count for each type
    typeJobCountPieData = Object.keys(bookingGroupedByType).map(
        (key, index) => {
            const total = bookingGroupedByType[key].length;
            const percentage = (
                (total / bookingsSelectedMonth.length) *
                100
            ).toFixed(2);
            return {
                id: key,
                label: key,
                total: total,
                value: percentage
            };
        }
    );

    // prepare data for pie chart: percentage of revenue for each type
    typeRevenuePieData = Object.keys(bookingGroupedByType).map((key, index) => {
        const { totalRevenue: totalFinalisedRevenue } = getTypeRevenue(
            key,
            'Finalised'
        );

        const percentage = (
            (totalFinalisedRevenue / selectedMonthFinalisedRevenue) *
            100
        ).toFixed(2);

        return {
            id: key,
            label: key,
            total: totalFinalisedRevenue,
            value: percentage
        };
    });

    // calculate how many jobs, revenue, cost, finalised jobs, complete jobs for each technician
    const getTechnicianRevenue = (TechId: number) => {
        let totalFinalisedRevenue = 0;
        let totalFinalisedCost = 0;
        let totalJobs = 0;
        let totalFinalisedJobs = 0;
        let totalCompleteJobs = 0;

        serviceData.forEach((service) => {
            if (
                service.TechId === TechId ||
                (service.TechId === null && !TechId)
            ) {
                totalJobs++;
                if (service.serviceComplete === 'Finalised') {
                    totalFinalisedJobs++;
                    // only add revenue and cost for finalised jobs for now
                    totalFinalisedRevenue += service.totalSale;
                    totalFinalisedCost += service.totalCost;
                } else if (service.serviceComplete === 'Complete') {
                    totalCompleteJobs++;
                }
            }
        });

        return {
            totalFinalisedRevenue,
            totalFinalisedCost,
            totalJobs,
            totalCompleteJobs,
            totalFinalisedJobs
        };
    };

    // prepare data for pie chart: percentage of job count for each technician
    techJobCountPieData = Object.keys(bookingsGroupdByTech).map(
        (key, index) => {
            const techData = JSON.parse(key);
            const { totalCompleteJobs, totalFinalisedJobs } =
                getTechnicianRevenue(techData?.id);
            const totalCompleteAndFinalisedJobs =
                totalCompleteJobs + totalFinalisedJobs;
            const monthlyCompleteAndFinalisedJobs =
                bookingsSelectedMonth.filter(
                    (booking) =>
                        booking.serviceComplete === 'Complete' ||
                        booking.serviceComplete === 'Finalised'
                );
            const percentage = (
                (totalCompleteAndFinalisedJobs /
                    monthlyCompleteAndFinalisedJobs.length) *
                100
            ).toFixed(2);
            return {
                id: techData?.id
                    ? `${techData.firstName} ${techData.lastName}`
                    : 'Unassigned',
                label: techData?.id
                    ? `${techData.firstName} ${techData.lastName}`
                    : 'Unassigned',
                total: totalCompleteAndFinalisedJobs,
                value: percentage
            };
        }
    );

    // prepare data for pie chart: percentage of revenue for each technician
    techRevenuePieData = Object.keys(bookingsGroupdByTech).map((key, index) => {
        const techData = JSON.parse(key);
        const { totalFinalisedRevenue } = getTechnicianRevenue(techData?.id);

        const percentage = (
            (totalFinalisedRevenue / selectedMonthFinalisedRevenue) *
            100
        ).toFixed(2);

        return {
            id: techData?.id
                ? `${techData.firstName} ${techData.lastName}`
                : 'Unassigned',
            label: techData?.id
                ? `${techData.firstName} ${techData.lastName}`
                : 'Unassigned',
            total: totalFinalisedRevenue,
            value: percentage
        };
    });

    // tech table total row
    let techGrandTotalJobs = 0;
    let techGrandTotalCompleteJobs = 0;
    let techGrandTotalFinalisedJobs = 0;
    let techGrandTotalFinalisedRevenue = 0;
    let techGrandTotalFinalisedCost = 0;

    // type table total row
    let typeGrandTotalJobs = 0;
    let typeGrandTotalFinalisedJobs = 0;
    let typeGrandTotalFinalisedRevenue = 0;
    let typeGrandTotalFinalisedCost = 0;

    // click on job count cell to open drawer
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState('');
    const [drawerData, setDrawerData] = useState([]);

    const handleOpenBookingBreakdownDrawer = (
        serviceType = null,
        selectedStatus = null,
        techId = null,
        serviceComplete = null
    ) => {
        setDrawerOpen(true);

        let filteredData = serviceData;
        let title = 'All Service Data';

        if (serviceType && selectedStatus) {
            filteredData = filteredData.filter(
                (booking) =>
                    booking.serviceType === serviceType &&
                    booking.serviceComplete === selectedStatus
            );
            title = `${serviceType} Service Data`;
        }

        if (techId) {
            filteredData = filteredData.filter(
                (booking) => booking.TechId === techId
            );
            if (serviceComplete) {
                filteredData = filteredData.filter(
                    (booking) => booking.serviceComplete === serviceComplete
                );
                title = `${techId} ${serviceComplete} Service Data`;
            } else {
                title = `${techId} Service Data`;
            }
        } else if (serviceComplete) {
            filteredData = filteredData.filter(
                (booking) => booking.serviceComplete === serviceComplete
            );
            title = `${serviceComplete} Service Data`;
        }

        setDrawerTitle(title);
        setDrawerData(filteredData);
    };

    return (
        <>
            <Grid container spacing={2}>
                <BookingCharts
                    typePieData={typeJobCountPieData}
                    title="Total Complete & Finalised Job Counts By Type (%)"
                />
                <BookingCharts
                    typePieData={typeRevenuePieData}
                    title="Total Finalised Revenue By Type (%)"
                />
            </Grid>
            <br />

            <Grid item xs={12} md={6}>
                <Paper>
                    <Typography variant="h6">Job Type Breakdown</Typography>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <FormControlLabel
                                value="Incomplete"
                                control={<Radio size="small" />}
                                label="Incomplete"
                            />
                            <FormControlLabel
                                value="Complete"
                                control={<Radio size="small" />}
                                label="Complete"
                            />
                            <FormControlLabel
                                value="Finalised"
                                control={<Radio size="small" />}
                                label="Finalised"
                            />
                        </RadioGroup>
                    </FormControl>
                    <DataTable columns={jobTypeBrkColumns}>
                        {Object.entries(bookingGroupedByType).map(
                            ([jobType], index) => {
                                const { totalRevenue, totalCost, totalJobs } =
                                    getTypeRevenue(jobType, selectedStatus);

                                typeGrandTotalJobs += totalJobs;
                                typeGrandTotalFinalisedJobs += totalJobs;
                                typeGrandTotalFinalisedRevenue += totalRevenue;
                                typeGrandTotalFinalisedCost += totalCost;

                                return (
                                    <TableRow key={index}>
                                        <DataCell>{jobType} </DataCell>
                                        <DataCellColoured
                                            handleClick={() =>
                                                handleOpenBookingBreakdownDrawer(
                                                    jobType,
                                                    selectedStatus
                                                )
                                            }
                                        >
                                            {totalJobs}
                                        </DataCellColoured>
                                        {/* revenue */}
                                        <DataCell>
                                            {CurrencyFormatter(totalRevenue)}
                                        </DataCell>
                                        {/* cost */}
                                        <DataCell>
                                            {CurrencyFormatter(totalCost)}
                                        </DataCell>
                                        {/* gross profit */}
                                        <DataCell>
                                            {CurrencyFormatter(
                                                totalRevenue - totalCost
                                            )}
                                        </DataCell>
                                        {/* average finalised revenue */}
                                        <DataCell>
                                            {' '}
                                            {CurrencyFormatter(
                                                totalRevenue / totalJobs
                                            )}{' '}
                                        </DataCell>
                                        {/* average gross profit */}
                                        <DataCell>
                                            {' '}
                                            {CurrencyFormatter(
                                                (totalRevenue - totalCost) /
                                                    totalJobs
                                            )}{' '}
                                        </DataCell>
                                        {/* margin */}
                                        <DataCell>
                                            {(
                                                ((totalRevenue - totalCost) /
                                                    totalRevenue) *
                                                100
                                            ).toFixed(2)}
                                            %
                                        </DataCell>
                                    </TableRow>
                                );
                            }
                        )}
                        <TableRow key={'total'}>
                            <DataCell>Total </DataCell>
                            <DataCellColoured
                                handleClick={() =>
                                    handleOpenBookingBreakdownDrawer(null)
                                }
                            >
                                {typeGrandTotalJobs}
                            </DataCellColoured>
                            <DataCell>
                                {CurrencyFormatter(
                                    typeGrandTotalFinalisedRevenue
                                )}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(typeGrandTotalFinalisedCost)}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    typeGrandTotalFinalisedRevenue -
                                        typeGrandTotalFinalisedCost
                                )}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    typeGrandTotalFinalisedRevenue /
                                        typeGrandTotalFinalisedJobs
                                )}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    (typeGrandTotalFinalisedRevenue -
                                        typeGrandTotalFinalisedCost) /
                                        typeGrandTotalFinalisedJobs
                                )}
                            </DataCell>
                            <DataCell>
                                {(
                                    ((typeGrandTotalFinalisedRevenue -
                                        typeGrandTotalFinalisedCost) /
                                        typeGrandTotalFinalisedRevenue) *
                                    100
                                ).toFixed(2)}
                                %
                            </DataCell>
                        </TableRow>
                    </DataTable>
                </Paper>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <BookingCharts
                    typePieData={techJobCountPieData}
                    title="Total Complete & Finalised Job Counts By Technician (%)"
                />
                <BookingCharts
                    typePieData={techRevenuePieData}
                    title="Total Finalised Revenue By Technician (%)"
                />
            </Grid>
            <br />
            <Grid item xs={12} md={6}>
                <Paper>
                    <Typography variant="h6">Technician Breakdown</Typography>
                    <DataTable columns={technicianBrkColumns}>
                        {Object.entries(bookingsGroupdByTech).map(
                            ([Tech], index) => {
                                // Parse the Tech object string back to an object
                                const techData = JSON.parse(Tech);
                                const {
                                    totalFinalisedRevenue,
                                    totalFinalisedCost,
                                    totalJobs,
                                    totalCompleteJobs,
                                    totalFinalisedJobs
                                } = getTechnicianRevenue(techData?.id);

                                techGrandTotalJobs += totalJobs;
                                techGrandTotalCompleteJobs += totalCompleteJobs;
                                techGrandTotalFinalisedJobs +=
                                    totalFinalisedJobs;
                                techGrandTotalFinalisedRevenue +=
                                    totalFinalisedRevenue;
                                techGrandTotalFinalisedCost +=
                                    totalFinalisedCost;

                                return (
                                    <TableRow key={index}>
                                        <DataCell>
                                            {techData
                                                ? techData.firstName +
                                                  ' ' +
                                                  techData.lastName
                                                : 'Unassigned'}{' '}
                                        </DataCell>
                                        <DataCellColoured
                                            handleClick={() =>
                                                handleOpenBookingBreakdownDrawer(
                                                    null,
                                                    null,
                                                    techData?.id,
                                                    null
                                                )
                                            }
                                        >
                                            {totalJobs}
                                        </DataCellColoured>
                                        <DataCellColoured
                                            handleClick={() =>
                                                handleOpenBookingBreakdownDrawer(
                                                    null,
                                                    null,
                                                    techData?.id,
                                                    'Complete'
                                                )
                                            }
                                        >
                                            {totalCompleteJobs}
                                        </DataCellColoured>
                                        <DataCellColoured
                                            handleClick={() =>
                                                handleOpenBookingBreakdownDrawer(
                                                    null,
                                                    null,
                                                    techData?.id,
                                                    'Finalised'
                                                )
                                            }
                                        >
                                            {totalFinalisedJobs}
                                        </DataCellColoured>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                totalFinalisedRevenue
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                totalFinalisedCost
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                totalFinalisedRevenue -
                                                    totalFinalisedCost
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                totalFinalisedRevenue /
                                                    totalFinalisedJobs
                                            )}
                                        </DataCell>
                                    </TableRow>
                                );
                            }
                        )}
                        <TableRow key={'total'}>
                            <DataCell>Total </DataCell>
                            <DataCellColoured
                                handleClick={() =>
                                    handleOpenBookingBreakdownDrawer(
                                        null,
                                        null,
                                        null,
                                        null
                                    )
                                }
                            >
                                {techGrandTotalJobs}
                            </DataCellColoured>
                            <DataCellColoured
                                handleClick={() =>
                                    handleOpenBookingBreakdownDrawer(
                                        null,
                                        null,
                                        null,
                                        'Complete'
                                    )
                                }
                            >
                                {techGrandTotalCompleteJobs}
                            </DataCellColoured>
                            <DataCellColoured
                                handleClick={() =>
                                    handleOpenBookingBreakdownDrawer(
                                        null,
                                        null,
                                        null,
                                        'Finalised'
                                    )
                                }
                            >
                                {techGrandTotalFinalisedJobs}
                            </DataCellColoured>
                            <DataCell>
                                {CurrencyFormatter(
                                    techGrandTotalFinalisedRevenue
                                )}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(techGrandTotalFinalisedCost)}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    techGrandTotalFinalisedRevenue -
                                        techGrandTotalFinalisedCost
                                )}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    techGrandTotalFinalisedRevenue /
                                        techGrandTotalFinalisedJobs
                                )}
                            </DataCell>
                        </TableRow>
                    </DataTable>
                </Paper>
                <BookingBreakdownDrawer
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    drawerTitle={drawerTitle}
                    drawerData={drawerData}
                />
            </Grid>
        </>
    );
};

export default BookingBreakdownTable;
