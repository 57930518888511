import DocumentationPage from '../DocumentationPage';
import {
    AddPartSteps,
    BinLocations,
    DeletePartSteps,
    ExportPartsSteps,
    ImportSupplierFile,
    MergePartsSteps,
    SearchPartsSteps,
    StockAdjustments,
    TransferPartSteps,
    UpdatePartSteps
} from '../data/inventory/inventory';

const InventorySupportPage = () => {
    const contentSections = [
        {
            id: 'add-part',
            title: 'Add new part',
            href: '/support/inventory/search/#add-part',
            description: 'To add a new part, follow these steps:',
            steps: AddPartSteps
        },
        {
            id: 'update-part',
            title: 'Update existing part',
            href: '/support/inventory/search/#update-part',
            description:
                'To edit details of an existing part, follow these steps:',
            steps: UpdatePartSteps
        },
        {
            id: 'delete-part',
            title: 'Delete existing part',
            href: '/support/inventory/search/#delete-part',
            description: 'To delete an existing part, follow these steps:',
            steps: DeletePartSteps
        },
        {
            id: 'search-part',
            title: 'Search part',
            href: '/support/inventory/search/#search-part',
            description: 'To search or filter parts, follow these steps:',
            steps: SearchPartsSteps
        },
        {
            id: 'merge-part',
            title: 'Merge existing parts',
            href: '/support/inventory/search/#merge-part',
            description: 'To merge two existing part, follow these steps:',
            steps: MergePartsSteps
        },
        {
            id: 'transfer-part',
            title: 'Transfer parts',
            href: '/support/inventory/search/#transfer-part',
            description:
                'To transfer parts between different sites, follow these steps: ',
            steps: TransferPartSteps
        },
        {
            id: 'export-part',
            title: 'Export parts to CSV',
            href: '/support/inventory/search/#export-part',
            description:
                'To export current part result into a CSV file, follow these steps:',
            steps: ExportPartsSteps
        },
        {
            id: 'import-supplier-file',
            title: 'Import supplier files',
            href: '/support/inventory/search/#import-supplier-file',
            description: `One way to bulk update parts from a specific supplier is to use the Import Supplier File feature. This allows us to bulk update existing parts from a supplier, add new parts from a supplier, or both update existing parts and add new parts from a supplier.`,
            steps: ImportSupplierFile
        },
        {
            id: 'stock-adjustment',
            title: 'Stock Adjustment',
            href: '/support/inventory/search/#stock-adjustment',
            description:
                'The Stock Adjustment feature allows you to manually update the stock levels of specific parts. This tool provides options to adjust stock quantities in your inventory, modify average costs, and review all historical adjustments.',
            steps: StockAdjustments
        },
        {
            id: 'bin-location',
            title: 'Bin Locations',
            href: '/support/inventory/search/#bin-location',
            description:
                'The Bin Locations feature allows you to manage and configure the default for a specific site, to set up a default bin or delete bin, follow these steps:',
            steps: BinLocations
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Inventory"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default InventorySupportPage;
