import { TradeIn } from '../../../../global/interfaces/VehicleSaleInterface';
import { DateFormatter } from '../../../../global/logic/Formatters';

const FilledTrades = (doc, lastYcoordinates, trade: TradeIn) => {
  doc.setFont('helvetica', 'normal').setFontSize(7);
  doc.text(
    trade.Vehicle?.Make?.name + ' ' + trade?.Vehicle?.Model?.name,
    35,
    lastYcoordinates + 9
  );
    console.log(trade)
  if (trade?.Vehicle?.BodyType) {
    doc.text(trade?.Vehicle?.BodyType?.name, 38, lastYcoordinates + 15);
  }

  if (
    trade?.Vehicle?.registrationDueDate &&
    trade?.Vehicle?.registrationNumber
  ) {
    doc.text(
      trade?.Vehicle?.registrationNumber +
        ' Expiring ' +
        DateFormatter(trade?.Vehicle?.registrationDueDate),
      126,
      lastYcoordinates + 15
    );
  } else if (trade?.Vehicle?.registrationDueDate) {
    doc.text(
      'Expiring ' + DateFormatter(trade?.Vehicle?.registrationDueDate),
      126,
      lastYcoordinates + 15
    );
  } else if (trade?.Vehicle?.registrationNumber) {
    doc.text(trade?.Vehicle?.registrationNumber, 126, lastYcoordinates + 15);
  }

  doc.text(trade?.Vehicle?.vin, 45, lastYcoordinates + 21);

  if (trade?.Vehicle?.engineNumber) {
    doc.text(trade?.Vehicle?.engineNumber, 130, lastYcoordinates + 21);
  }

  doc.text(trade?.Vehicle?.year.format('YYYY'), 40, lastYcoordinates + 27);

  if (trade?.Vehicle?.complianceDate) {
    doc.text(
      DateFormatter(trade?.Vehicle?.complianceDate),
      107,
      lastYcoordinates + 27
    );
  }

  if (trade?.Vehicle?.odometer) {
    doc
      .text(trade?.Vehicle?.odometer, 153, lastYcoordinates + 27)
      .text('kms', 188, lastYcoordinates + 27, { align: 'right' });
  }

  if (trade?.Vehicle?.colour) {
    doc.text(trade?.Vehicle?.colour, 33, lastYcoordinates + 33);
  }
};

export default FilledTrades;
