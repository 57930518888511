import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const DeleteManualInvoice = (id: number, showSnackbar: showSnackbar) => {
    api.delete(`manualInvoice/${id}`).then((res) => {
        if (res.status === 200) {
            showSnackbar('Manual Invoice successfully deleted.');
            window.location.reload();
        } else {
            showSnackbar(
                'Something went wrong on our end.',
                'This manual invoice was not updated.',
                'error'
            );
        }
    });
};

export default DeleteManualInvoice;
