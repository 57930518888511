import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { StockTransfer } from '../../../global/interfaces/PartsInterface';
import api from '../../../../../api';

// Submit the new transfer to the database
const DeleteTransfer = (
    transferData: StockTransfer,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    // Send to the database
    api.delete('stockTransferNextGen/' + transferData.id).then((res) => {
        if (res.status === 200) {
            window.location.reload();
        } else {
            showSnackbar(
                'Something went wrong when updating the stock transfer.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default DeleteTransfer;
