import {
    Typography,
    TableRow,
    TableCell,
    Drawer,
    Box,
    Table,
    TableHead,
    Divider
} from '@mui/material';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import { BreakdownDrawerProps } from '../../../global/dashboard/interface';

const BookingBreakdownDrawer = ({
    drawerData: serviceData,
    drawerTitle,
    drawerOpen,
    setDrawerOpen
}: BreakdownDrawerProps) => {
    const isWaitingPayment = drawerTitle === 'Waiting Payment';

    const ServiceRow = ({ service }) => {
        const handleOpen = () => {
            window.open(`/service/viewBooking/${service.ServiceId}`, '_blank');
        };

        return (
            <TableRow>
                <TableCell className="table-link" onClick={handleOpen}>
                    {service.ServiceId}
                </TableCell>
                <TableCell>
                    {service.customerName || service.vehicleStockNumber}
                </TableCell>
                {!isWaitingPayment && (
                    <>
                        <TableCell>
                            {CurrencyFormatter(service.totalCost)}
                        </TableCell>
                        <TableCell>
                            {CurrencyFormatter(service.totalSale)}
                        </TableCell>
                        <TableCell>
                            {CurrencyFormatter(
                                service.totalSale - service.totalCost
                            )}
                        </TableCell>
                    </>
                )}

                {isWaitingPayment && (
                    <>
                        <TableCell>{service.serviceComplete}</TableCell>
                        <TableCell>
                            {CurrencyFormatter(service.totalPrice)}
                        </TableCell>
                        <TableCell>
                            {CurrencyFormatter(service.totalPayment)}
                        </TableCell>
                        <TableCell>{service.serviceType}</TableCell>

                        {/* <TableCell>
                            {CurrencyFormatter(
                                service.totalPrice - service.totalPayment
                            )}
                        </TableCell> */}
                    </>
                )}
            </TableRow>
        );
    };

    return (
        <Drawer
            anchor={'right'}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            style={{ zIndex: 1200 }}
        >
            <Box sx={{ width: '40vw', padding: '20px' }} role="presentation">
                <Typography variant="h4">{drawerTitle}</Typography>
                <Box height={'10px'}></Box>
                <Divider></Divider>
                <Box height="20px"></Box>
                <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width="15%">
                                    <strong>Service ID</strong>
                                </TableCell>

                                <TableCell width="15%">
                                    <strong>Customer / Stock No</strong>
                                </TableCell>

                                {!isWaitingPayment && (
                                    <>
                                        <TableCell width="35%">
                                            <strong>Total Cost (Ex GST)</strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>
                                                Total Revenue (Ex GST)
                                            </strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>Gross Profits</strong>
                                        </TableCell>
                                    </>
                                )}

                                {isWaitingPayment && (
                                    <>
                                        <TableCell width="35%">
                                            <strong>Service Status</strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>Total Price</strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>Total Payment</strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>Job Typpe</strong>
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        {serviceData.map((service) => {
                            return (
                                <ServiceRow
                                    key={service.ServiceId}
                                    service={service}
                                />
                            );
                        })}
                    </Table>
                </>
            </Box>
        </Drawer>
    );
};

export default BookingBreakdownDrawer;
