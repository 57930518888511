import { Box, Divider, TableRow, Typography } from '@mui/material';
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../../global/tableComponents/DataCell';

export const DebtorPaymentDrawerContent = ({
    selectedSite,
    queryDate,
    debtorPayments
}) => {
    const columns = [
        { id: 0, label: 'Debtor Payment ID', width: 200 },
        { id: 1, label: 'Order ID' },
        { id: 2, label: 'Amount' },
        { id: 3, label: 'Amount Paid' }
    ];
    return (
        <Box sx={{ width: '40vw', padding: '20px' }}>
            <Typography variant="h4">
                {typeof selectedSite != 'string' ? selectedSite.name : ''} -{' '}
                {queryDate.format('DD/MM/YYYY')}
            </Typography>
            <Typography>New Vehicle Stock Breakdown</Typography>
            <Box height={'10px'}></Box>
            <Divider></Divider>
            <Box height="20px"></Box>
            <DataTable columns={columns}>
                {debtorPayments.map((payment) => (
                    <TableRow>
                        <DataCell>{payment.id}</DataCell>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(
                                    '/inventory/viewSale/' +
                                        payment.OrderId.toString(),
                                    '_blank'
                                )
                            }
                        >
                            {payment.OrderId}
                        </DataCellColoured>
                        <DataCell>
                            {CurrencyFormatter(payment.amountDue)}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(
                                payment.paymentHistory
                                    .map((x) => x.amount)
                                    .reduce((acc, value) => acc + value, 0)
                            )}
                        </DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </Box>
    );
};
