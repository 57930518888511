import api from '../../../../../api';
import { Dayjs } from 'dayjs';

const GetManagerDashboardData = (
    startDate: Dayjs,
    endDate: Dayjs,
    dateCheck: string,
    setStatusData,
    setSourceData,
    setMakeData,
    setMakeBreakdown,
    setEmployeeBreakdown,
    setTotalEnquiry
) => {
    api.get(
        `managerDashboardData?&siteId=${localStorage.getItem('SiteId')}${
            startDate && endDate && dateCheck === 'specificTime'
                ? `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
                : startDate && dateCheck === 'specificTime'
                  ? `&startDate= ${startDate.toISOString()}`
                  : endDate && dateCheck === 'specificTime'
                    ? `&endDate=${endDate.toISOString()}`
                    : ''
        }`
    ).then((res) => {
        setStatusData(res.data.statusObj);
        setSourceData(res.data.sourceObj);
        setMakeData(res.data.makeObj);
        setMakeBreakdown(res.data.makeBrkdownData);
        setEmployeeBreakdown(res.data.employeeBrkdownData);
        setTotalEnquiry(res.data.totalCount);
    });
};

export default GetManagerDashboardData;
