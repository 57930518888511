// LOGIC
import api from '../../../../api';

/*  GetAllUserPermissions
    Gets all the permissions for a given user ID
    params:
        - UserId: if set to 0 will default to current user, otherwise 
        will use the id entered
        - setPermission: the react state to save the user permissions to
    returns:
        - all the permissions for the given user in a json format
*/
const GetAllPermissions = (setPermissions) => {
  api.get(`allPermissions`).then((res) => {
    setPermissions(res.data);
  });
};

export default GetAllPermissions;
