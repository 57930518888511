import { useState } from 'react';
import { TableRow, TextField, Typography } from '@mui/material';

// COMPONENT
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import Fab from '../../../global/Fab';

// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import UpdateManualInvoice from '../logic/UpdateManualInvoice';

// INTERFACE
import { ManualInvoice } from '../../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const ManualInvoiceDrawerContent = ({
    selectedInvoice,
    showSnackbar
}: {
    selectedInvoice: ManualInvoice;
    showSnackbar: showSnackbar;
}): JSX.Element => {
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [manualInvoiceData, setManualInvoiceData] = useState<ManualInvoice>({
        ...selectedInvoice
    });

    // Columns for the drawer table
    const drawerColumns = [
        { id: 0, label: 'Description', width: 150 },
        { id: 1, label: 'Quantity', width: 100 },
        { id: 2, label: 'Amount (Ex. GST)', width: 150 },
        { id: 3, label: 'GST' },
        { id: 4, label: 'Total (Inc. GST)' }
    ];

    const handleLineChange = (newValue, lineIndex, type) => {
        let currentLines = [...manualInvoiceData.ManualInvoiceLines];
        currentLines[lineIndex][type] =
            type === 'quantity' || type === 'amount'
                ? parseFloat(newValue)
                : newValue;
        setManualInvoiceData({
            ...manualInvoiceData,
            ManualInvoiceLines: currentLines
        });
    };

    return (
        <>
            <DataTable columns={drawerColumns}>
                {manualInvoiceData?.ManualInvoiceLines?.map((line, index) => (
                    <TableRow>
                        {readOnly ? (
                            <>
                                <DataCell>{line.description}</DataCell>
                                <DataCell>{line.quantity}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.amount)}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.amount * 0.1)}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        line.amount * line.quantity * 1.1
                                    )}
                                </DataCell>
                            </>
                        ) : (
                            <>
                                <DataCell>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        value={line.description}
                                        onChange={(e) => {
                                            handleLineChange(
                                                e.target.value,
                                                index,
                                                'description'
                                            );
                                        }}
                                    />
                                </DataCell>
                                <DataCell>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={line.quantity}
                                        onChange={(e) => {
                                            handleLineChange(
                                                e.target.value,
                                                index,
                                                'quantity'
                                            );
                                        }}
                                    />
                                </DataCell>
                                <DataCell>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={line.amount}
                                        onChange={(e) => {
                                            handleLineChange(
                                                e.target.value,
                                                index,
                                                'amount'
                                            );
                                        }}
                                    />
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.amount * 0.1)}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        line.amount * line.quantity * 1.1
                                    )}
                                </DataCell>
                            </>
                        )}
                    </TableRow>
                ))}
            </DataTable>

            <br />
            <Typography variant="body2">Additional text</Typography>

            <TextField
                fullWidth
                multiline
                disabled={readOnly}
                value={manualInvoiceData.additionalText}
                placeholder="Text that will appears at the bottom of the invoice (not required)"
                onChange={(e) => {
                    setManualInvoiceData({
                        ...manualInvoiceData,
                        additionalText: e.target.value
                    });
                }}
            />

            <Fab
                editIcon={readOnly}
                noSession
                onClick={() => {
                    readOnly
                        ? setReadOnly(false)
                        : UpdateManualInvoice(manualInvoiceData, showSnackbar);
                }}
            />
        </>
    );
};

export default ManualInvoiceDrawerContent;
