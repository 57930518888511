// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { CustomerVehicle } from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleUpdateCustomerVehicle = (
    selectedCustomerVehicle: CustomerVehicle,
    showSnackbar: showSnackbar,
    setReadOnly: Dispatch<SetStateAction<boolean>>
) => {
    if (!selectedCustomerVehicle.MakeId) {
        showSnackbar('You must select a make for the vehicle.', '', 'error');
        return;
    }
    if (!selectedCustomerVehicle.ModelId) {
        showSnackbar('You must select a model for the vehicle.', '', 'error');
        return;
    }
    if (!selectedCustomerVehicle.year) {
        showSnackbar(
            'You must select a year model for the vehicle.',
            '',
            'error'
        );
        return;
    }

    api.put(
        `updateCustomerVehicle/${selectedCustomerVehicle.id}`,
        selectedCustomerVehicle
    ).then((res) => {
        showSnackbar('Customer vehicle Successfully Updated.');
        setReadOnly(true);
    });
};

export default HandleUpdateCustomerVehicle;
