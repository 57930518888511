// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TableRow, TextField, Typography } from '@mui/material';
// COMPONENTS
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import Paper from '../../../global/Paper';
// INTERFACES
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import CreditSingleOrderline from './CreditSingleOrderline';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        returnedQuantity?: number; // used in crediting order
        returnedBackorderQuantity?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        refundAmount?: string; // used in crediting order
        amountRefunded?: number; // used in crediting order
        voucherCode?: string;
    }[];
}

interface CreditProps {
    saleDetails: TempOrder;
    setSaleDetails: Dispatch<SetStateAction<TempOrder>>;
    freightRefunding: string;
    vouchers: { voucher: Voucher; qtyReturning: number }[];
    setVouchers: Dispatch<
        SetStateAction<{ voucher: Voucher; qtyReturning: number }[]>
    >;
    setFreightRefunding: Dispatch<SetStateAction<string>>;
    setCreditDrawerId: Dispatch<SetStateAction<number | string>>;
    setOpenCreditDrawer: Dispatch<SetStateAction<boolean>>;
    amountRefunding: number;
    setAmountRefunding: Dispatch<SetStateAction<number>>;
    readOnly: boolean;
    showSnackbar: showSnackbar;
    creditable: boolean;
}

/**
 * CreditOrderlines
 * Show all refundable and refunded parts for an sale
 * @author Estienne
 * @param CreditProps
 * @returns {JSX} the sale's parts and other refundable costs
 */
const CreditOrderlines = ({
    saleDetails,
    setSaleDetails,
    freightRefunding,
    vouchers,
    setVouchers,
    setFreightRefunding,
    setCreditDrawerId,
    setOpenCreditDrawer,
    readOnly,
    showSnackbar,
    creditable
}: CreditProps) => {
    const [columns, setColumns] = useState([
        { id: 0, label: 'Part Number' },
        { id: 1, label: 'Name' },
        { id: 2, label: 'Price Paid' },
        { id: 3, label: 'Qty' },
        { id: 5, label: 'Qty Returning' },
        { id: 7, label: 'Refund Amount' }
    ]);

    useEffect(() => {
        if (saleDetails.status === 'Proforma' && readOnly) {
            if (
                saleDetails.orderLines.findIndex(
                    (x) => parseInt(x.quantity) < 0
                ) !== -1 ||
                saleDetails.freightRefunded > 0
            ) {
                setColumns([
                    { id: 0, label: 'Part Number' },
                    { id: 1, label: 'Name' },
                    { id: 2, label: 'Price Paid' },
                    { id: 3, label: 'Qty' },
                    { id: 4, label: 'Backorder Qty' },
                    { id: 5, label: 'Refund Amount' }
                ]);
            } else {
                setColumns([
                    { id: 0, label: 'Part Number' },
                    { id: 1, label: 'Name' },
                    { id: 2, label: 'Price Paid' },
                    { id: 3, label: 'Qty' },
                    { id: 4, label: 'Backorder Qty' }
                ]);
            }
        } else if (saleDetails.status === 'Proforma' && !readOnly) {
            setColumns([
                { id: 0, label: 'Part Number' },
                { id: 1, label: 'Name' },
                { id: 2, label: 'Unit Price Paid' },
                { id: 3, label: 'Qty' },
                { id: 4, label: 'Backorder Qty' },
                { id: 5, label: 'Qty Returning' },
                { id: 6, label: 'Backorder Qty Returning' },
                { id: 7, label: 'Refund Amount' }
            ]);
        } else if (
            saleDetails.status === 'Sale' &&
            saleDetails.orderLines.findIndex(
                (x) => parseInt(x.quantity) < 0
            ) !== -1 &&
            readOnly
        ) {
            setColumns([
                { id: 0, label: 'Part Number' },
                { id: 1, label: 'Name' },
                { id: 2, label: 'Price Paid' },
                { id: 3, label: 'Qty' },
                { id: 7, label: 'Refund Amount' }
            ]);
        } else if (
            saleDetails.status === 'Sale' &&
            saleDetails.orderLines.findIndex(
                (x) => parseInt(x.quantity) < 0
            ) !== -1 &&
            !readOnly
        ) {
            setColumns([
                { id: 0, label: 'Part Number' },
                { id: 1, label: 'Name' },
                { id: 2, label: 'Price Paid' },
                { id: 3, label: 'Qty' },
                { id: 4, label: 'Qty Returned' },
                { id: 7, label: 'Refund Amount' }
            ]);
        }
        // eslint-disable-next-line
    }, [saleDetails.status, readOnly]);

    /**
     * freightRefundable
     * Make sure freight is present and refundable
     * @author Estienne
     * @param sale the currently-viewed sale
     * @returns true if freight is refundable
     */
    const freightRefundable = (sale: TempOrder) => {
        if (sale.freight != null) {
            if (sale.freightRefunded != null) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    /**
     * handleUpdateVouchers
     * Update the quantity of a voucher being returned
     * @author Estienne
     * @param newValue the voucher quantity being returned
     * @param id the id of the voucher being updated
     */
    const handleUpdateVouchers = (newValue: number, id: number) => {
        if (newValue > 1) {
            showSnackbar(
                'Can only enter 1 or 0 in the Voucher quantity to refund',
                '',
                'warning'
            );
            let tempVouchers = [...vouchers];
            let index = tempVouchers.findIndex((x) => x.voucher.id === id);
            let voucher = { ...tempVouchers[index] };
            voucher.qtyReturning = 0;
            tempVouchers[index] = voucher;
            setVouchers(tempVouchers);
        } else {
            let tempVouchers = [...vouchers];
            let index = tempVouchers.findIndex((x) => x.voucher.id === id);
            let voucher = { ...tempVouchers[index] };
            voucher.qtyReturning = newValue;
            tempVouchers[index] = voucher;
            setVouchers(tempVouchers);
        }
    };

    const handleFieldChange = (rowId, value, field) => {
        let currentOrderlines = [...saleDetails.orderLines];
        for (let line of currentOrderlines) {
            if (line.rowId === rowId) {
                if (
                    (field === 'returnedQuantity' &&
                        parseInt(value) <= parseInt(line.quantity)) ||
                    (field === 'returnedBackorderQuantity' &&
                        parseInt(value) <= parseInt(line.backorderQuantity))
                ) {
                    line[field] = parseInt(value);
                    let returnedQuantity = line.returnedQuantity ?? 0;
                    let returnedBackorderQuantity =
                        line.returnedBackorderQuantity ?? 0;
                    line.refundAmount = (
                        (returnedQuantity + returnedBackorderQuantity) *
                        parseFloat(line.pricePaid)
                    ).toString();
                } else if (
                    (field === 'returnedQuantity' &&
                        parseInt(value) > parseInt(line.quantity)) ||
                    (field === 'returnedBackorderQuantity' &&
                        parseInt(value) > parseInt(line.backorderQuantity))
                ) {
                    showSnackbar(
                        'Cannot return more than the original quantity or backorder quantity',
                        '',
                        'warning'
                    );
                    line[field] = 0;
                } else if (field === 'refundAmount') {
                    if (parseFloat(value) > line.totalPrice) {
                        showSnackbar(
                            'Cannot more than the original total price.',
                            '',
                            'warning'
                        );
                        line[field] = 0;
                    } else {
                        line[field] = value;
                    }
                }
            }
        }

        setSaleDetails({ ...saleDetails, orderLines: currentOrderlines });
    };

    return (
        <Paper>
            <DataTable columns={columns} cypressLabel="creditSaleDataTable">
                {freightRefundable(saleDetails) ? (
                    <>
                        <DataCell />
                        <DataCell>{'FREIGHT'}</DataCell>
                        <DataCell>
                            {CurrencyFormatter(saleDetails?.freight)}
                        </DataCell>
                        <DataCell>N/A</DataCell>
                        {saleDetails.status === 'Proforma' && (
                            <DataCell>N/A</DataCell>
                        )}
                        {saleDetails.status === 'Proforma' && (
                            <DataCell>N/A</DataCell>
                        )}
                        <DataCell>N/A</DataCell>
                        <DataCell>
                            <TextField
                                type="number"
                                size="small"
                                variant="standard"
                                value={freightRefunding}
                                onChange={(e) => {
                                    if (
                                        parseFloat(e.target.value) >
                                        saleDetails.freight
                                    ) {
                                        showSnackbar(
                                            'Cannot refund more than the freight was originally in the sale.',
                                            '',
                                            'warning'
                                        );
                                    } else {
                                        setFreightRefunding(e.target.value);
                                    }
                                }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                            />
                        </DataCell>
                    </>
                ) : null}
                {vouchers.map((row, index) => {
                    return (
                        <TableRow key={index}>
                            {row.voucher.CreditId === null ? (
                                <>
                                    <DataCell />
                                    <DataCell>{`VOUCHER #${row.voucher.id}`}</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            row.voucher.totalPrice
                                        )}
                                    </DataCell>
                                    <DataCell>{'1'}</DataCell>
                                    {saleDetails.status === 'Proforma' && (
                                        <DataCell>N/A</DataCell>
                                    )}
                                    {saleDetails.status === 'Proforma' && (
                                        <DataCell>N/A</DataCell>
                                    )}
                                    <DataCell>
                                        <TextField
                                            type="number"
                                            size="small"
                                            variant="standard"
                                            value={row.qtyReturning}
                                            onChange={(e) =>
                                                handleUpdateVouchers(
                                                    parseInt(e.target.value),
                                                    row.voucher.id
                                                )
                                            }
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    </DataCell>
                                    <DataCell>
                                        {row.qtyReturning
                                            ? CurrencyFormatter(
                                                  row.qtyReturning *
                                                      row.voucher.totalPrice
                                              )
                                            : ''}
                                    </DataCell>
                                </>
                            ) : (
                                ''
                            )}
                        </TableRow>
                    );
                })}

                {saleDetails.orderLines.map((line) => (
                    <CreditSingleOrderline
                        orderline={line}
                        readOnly={readOnly}
                        creditable={creditable}
                        saleDetails={saleDetails}
                        handleFieldChange={handleFieldChange}
                    />
                ))}
                {saleDetails?.freight && saleDetails?.freightRefunded ? (
                    <>
                        <DataCellColoured
                            handleClick={() => {
                                setCreditDrawerId('FREIGHT');
                                setOpenCreditDrawer(true);
                            }}
                        >
                            {'FREIGHT'}
                        </DataCellColoured>
                        <DataCell>
                            <Typography
                                variant="body2"
                                sx={{ color: '#808080' }}
                            >
                                {'FREIGHT'}
                            </Typography>
                        </DataCell>
                        <DataCell>
                            <Typography
                                variant="body2"
                                sx={{ color: '#808080' }}
                            >
                                {CurrencyFormatter(saleDetails?.freight)}
                            </Typography>
                        </DataCell>
                        <DataCell />
                        {saleDetails.orderLines.findIndex(
                            (x) => parseInt(x.quantity) < 0
                        ) !== -1 ||
                        (saleDetails.status === 'Proforma' &&
                            readOnly) ? null : (
                            <DataCell />
                        )}
                        {(saleDetails.orderLines.findIndex(
                            (x) => parseInt(x.quantity) < 0
                        ) !== -1 &&
                            readOnly) ||
                        (saleDetails.status === 'Proforma' &&
                            readOnly) ? null : (
                            <DataCell />
                        )}
                        {saleDetails.orderLines.findIndex(
                            (x) => parseInt(x.quantity) < 0
                        ) !== -1 ? null : (
                            <DataCell />
                        )}
                        <DataCell>
                            {!readOnly &&
                            saleDetails.freightRefunded !==
                                saleDetails.freight ? (
                                <TextField
                                    value={freightRefunding}
                                    size="small"
                                    onChange={(e) => {
                                        setFreightRefunding(e.target.value);
                                    }}
                                />
                            ) : (
                                <Typography
                                    variant="body2"
                                    sx={{ color: '#808080' }}
                                >
                                    {CurrencyFormatter(
                                        saleDetails?.freightRefunded
                                    )}
                                </Typography>
                            )}
                        </DataCell>
                    </>
                ) : null}
                {vouchers.map((row, index) => {
                    return (
                        <TableRow key={index}>
                            {row.voucher.CreditId !== null ? (
                                <>
                                    <DataCellColoured
                                        handleClick={() => {
                                            setCreditDrawerId(
                                                row.voucher.CreditId
                                            );
                                            setOpenCreditDrawer(true);
                                        }}
                                    >
                                        {`VOUCHER #${row.voucher.id}`}
                                    </DataCellColoured>
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {`VOUCHER #${row.voucher.id}`}
                                        </Typography>
                                    </DataCell>
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {CurrencyFormatter(
                                                row.voucher.totalPrice
                                            )}
                                        </Typography>
                                    </DataCell>
                                    <DataCell />
                                    {/* THIS ONE ALSO CHECKS FOR READ ONLY IN CASE WE ARE IN A SALE IN EDIT MODE (WHICH RETURNS THE "QTY RETURNED" COLUMN) */}
                                    {saleDetails.orderLines.findIndex(
                                        (x) => parseInt(x.quantity) < 0
                                    ) !== -1 && readOnly ? null : row.voucher
                                          .refunded ? (
                                        <DataCell>1</DataCell>
                                    ) : null}
                                    {saleDetails.orderLines.findIndex(
                                        (x) => parseInt(x.quantity) < 0
                                    ) !== -1 ? null : (
                                        <DataCell />
                                    )}
                                    {saleDetails.orderLines.findIndex(
                                        (x) => parseInt(x.quantity) < 0
                                    ) !== -1 ? null : (
                                        <DataCell />
                                    )}
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {CurrencyFormatter(
                                                row.voucher.totalPrice
                                            )}
                                        </Typography>
                                    </DataCell>
                                </>
                            ) : (
                                ''
                            )}
                        </TableRow>
                    );
                })}

                <TableRow>
                    <DataCell />
                    <DataCell />
                    <DataCell />
                    {saleDetails.status === 'Proforma' && !readOnly && (
                        <DataCell />
                    )}
                    {saleDetails.status === 'Proforma' && !readOnly && (
                        <DataCell />
                    )}
                    {saleDetails.orderLines.findIndex(
                        (x) => parseInt(x.quantity) < 0
                    ) !== -1 && readOnly ? null : (
                        <DataCell />
                    )}
                    <DataCell>Total Refund</DataCell>
                    <DataCell>
                        {CurrencyFormatter(
                            saleDetails.orderLines
                                .map((x) =>
                                    !x.voucherCode &&
                                    (parseInt(x.quantity) < 0 ||
                                        parseInt(x.backorderQuantity) < 0)
                                        ? x.refundAmount
                                            ? parseFloat(x.refundAmount)
                                            : x.amountRefunded
                                        : 0
                                )
                                .reduce((acc, value) => acc + value, 0) +
                                (readOnly
                                    ? saleDetails.freightRefunded
                                    : parseFloat(freightRefunding) ?? 0) +
                                vouchers
                                    .map((x) =>
                                        x.qtyReturning === 1 ||
                                        x.voucher.refunded
                                            ? x.voucher.totalPrice
                                            : 0
                                    )
                                    .reduce((acc, value) => acc + value, 0)
                        )}
                    </DataCell>
                </TableRow>
            </DataTable>
        </Paper>
    );
};

export default CreditOrderlines;
