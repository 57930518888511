import { TradeIn } from '../../../global/interfaces/VehicleSaleInterface';
import { ToFixed } from '../../../global/logic/Formatters';

const TotalTrades = (tradeVehicles: TradeIn[]) => {
  let total =
    tradeVehicles.length > 0
      ? tradeVehicles
          .map((item) => parseFloat(item.tradeInPrice))
          .reduce((acc, amount) => acc + amount)
      : 0;
  return ToFixed(total);
};

export default TotalTrades;
