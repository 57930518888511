import { Grid, TableRow, Typography, useTheme } from '@mui/material';
import Paper from '../../../global/Paper';
import { Pie } from '@nivo/pie';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';

const DataDisplay = ({
    statusData,
    sourceData,
    makeData,
    makeBreakdown,
    employeeBreakdown,
    setSelectedFilter,
    setActiveDrawerOpen
}) => {
    const makeBrkColumns = [
        { id: 0, label: 'Make' },
        { id: 1, label: 'Total' },
        { id: 2, label: 'Active' },
        { id: 3, label: 'Lost' },
        { id: 4, label: 'Sold' },
        { id: 5, label: 'Sell %' },
        { id: 6, label: '' }
    ];

    const employeeBrkColumns = [
        { id: 0, label: 'Username' },
        { id: 1, label: 'Total' },
        { id: 2, label: 'Active' },
        { id: 3, label: 'Sold' },
        { id: 4, label: 'Sell %' },
        // { id: 5, label: "Form %" },
        // { id: 6, label: "Finance %" },
        { id: 7, label: '' }
    ];

    let currentMode = useTheme().palette.mode;

    const theme = {
        text: {
            fontSize: 11,
            fill: '#ffffff',
            outlineWidth: 0,
            outlineColor: '#ffffff'
        },
        axis: {
            domain: {
                line: {
                    stroke: '#ffffff',
                    strokeWidth: 1
                }
            },
            legend: {
                text: {
                    fontSize: 12,
                    fill: '#ffffff',
                    outlineWidth: 0,
                    outlineColor: '#ffffff'
                }
            },
            ticks: {
                line: {
                    stroke: '#ffffff',
                    strokeWidth: 1
                },
                text: {
                    fontSize: 11,
                    fill: '#ffffff',
                    outlineWidth: 1,
                    outlineColor: '#ffffff'
                }
            }
        },
        labels: {
            text: {
                fill: '#ffffff'
            }
        },
        grid: {
            line: {
                stroke: '#ffffff',
                strokeWidth: 1
            }
        },
        legends: {
            title: {
                text: {
                    fontSize: 11,
                    fill: '#ffffff',
                    outlineWidth: 0,
                    outlineColor: '#ffffff'
                }
            },
            text: {
                fontSize: 11,
                fill: '#ffffff',
                outlineWidth: 0,
                outlineColor: '#ffffff'
            },
            ticks: {
                line: {},
                text: {
                    fontSize: 10,
                    fill: '#ffffff',
                    outlineWidth: 0,
                    outlineColor: '#ffffff'
                }
            }
        },
        annotations: {
            text: {
                fontSize: 13,
                fill: '#ffffff',
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1
            },
            link: {
                stroke: '#ffffff',
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1
            },
            outline: {
                stroke: '#ffffff',
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1
            },
            symbol: {
                fill: '#ffffff',
                outlineWidth: 2,
                outlineColor: '#ffffff',
                outlineOpacity: 1
            }
        },
        tooltip: {
            container: {
                background: '#000000',
                fontSize: 12
            },
            basic: {},
            chip: {},
            table: {},
            tableCell: {},
            tableCellValue: {}
        }
    };
    return (
        <>
            <Grid item xs={6} md={4}>
                <Paper>
                    <Typography variant="subtitle1">
                        Enquiry Type (%)
                    </Typography>
                    <br />
                    {statusData.length > 0 ? (
                        <Pie
                            data={statusData}
                            height={200}
                            width={400}
                            margin={{ top: 15, bottom: 20 }}
                            sortByValue={true}
                            innerRadius={0.7}
                            colors={{ scheme: 'nivo' }}
                            theme={currentMode === 'dark' ? theme : null}
                            enableArcLabels={true}
                            arcLabelsRadiusOffset={1.5}
                            enableArcLinkLabels={false}
                            legends={[
                                {
                                    anchor: 'left',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 0,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemsSpacing: 0,
                                    symbolSize: 20,
                                    itemDirection: 'left-to-right'
                                }
                            ]}
                        />
                    ) : (
                        <Typography variant="caption">None to show.</Typography>
                    )}
                </Paper>
            </Grid>

            <Grid item xs={6} md={4}>
                <Paper>
                    <Typography variant="subtitle1">Enquiry Source</Typography>
                    <br />
                    {statusData.length > 0 ? (
                        <Pie
                            data={sourceData}
                            height={200}
                            width={400}
                            margin={{ top: 15, bottom: 20 }}
                            sortByValue={true}
                            innerRadius={0.7}
                            colors={{ scheme: 'nivo' }}
                            theme={currentMode === 'dark' ? theme : null}
                            enableArcLabels={true}
                            arcLabelsRadiusOffset={1.5}
                            enableArcLinkLabels={false}
                            legends={[
                                {
                                    anchor: 'left',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 0,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemsSpacing: 0,
                                    symbolSize: 20,
                                    itemDirection: 'left-to-right'
                                }
                            ]}
                        />
                    ) : (
                        <Typography variant="caption">None to show.</Typography>
                    )}
                </Paper>
            </Grid>
            <Grid item xs={6} md={4}>
                <Paper>
                    <Typography variant="subtitle1">Enquiry Makes</Typography>
                    <br />
                    {statusData.length > 0 ? (
                        <Pie
                            data={makeData}
                            height={200}
                            width={400}
                            margin={{ top: 15, bottom: 20 }}
                            sortByValue={true}
                            innerRadius={0.7}
                            colors={{ scheme: 'nivo' }}
                            theme={currentMode === 'dark' ? theme : null}
                            enableArcLabels={true}
                            arcLabelsRadiusOffset={1.5}
                            enableArcLinkLabels={false}
                            legends={[
                                {
                                    anchor: 'left',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 0,
                                    itemWidth: 100,
                                    itemHeight: 20,
                                    itemsSpacing: 0,
                                    symbolSize: 20,
                                    itemDirection: 'left-to-right'
                                }
                            ]}
                        />
                    ) : (
                        <Typography variant="caption">None to show.</Typography>
                    )}
                </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
                <Paper>
                    <Typography variant="h6">Make Breakdown</Typography>
                    <DataTable columns={makeBrkColumns}>
                        {makeBreakdown.map((make) => (
                            <TableRow>
                                <DataCell> {make.name} </DataCell>
                                <DataCell> {make.total} </DataCell>
                                <DataCell> {make.active} </DataCell>
                                <DataCell> {make.lost} </DataCell>
                                <DataCell> {make.sold} </DataCell>
                                <DataCell>
                                    {' '}
                                    {((make.sold / make.total) * 100).toFixed(
                                        1
                                    )}{' '}
                                </DataCell>
                                <DataCellColoured
                                    handleClick={() => {
                                        setSelectedFilter({
                                            enquiryStatus: [
                                                { type: 'ACTIVE' },
                                                { type: 'PENDING' },
                                                { type: 'SOLD' },
                                                { type: 'LOST' }
                                            ],
                                            assignedUser: [],
                                            startDate: null,
                                            endDate: null,
                                            make: [
                                                { id: make.id, name: make.name }
                                            ],
                                            sites: []
                                        });
                                        setActiveDrawerOpen(true);
                                    }}
                                >
                                    VIEW
                                </DataCellColoured>
                            </TableRow>
                        ))}
                    </DataTable>
                </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
                <Paper>
                    <Typography variant="h6">Employee Breakdown</Typography>
                    <DataTable columns={employeeBrkColumns}>
                        {employeeBreakdown.map((employee) => (
                            <TableRow>
                                <DataCell>
                                    {' '}
                                    {employee.username.toUpperCase()}{' '}
                                </DataCell>
                                <DataCell> {employee.total} </DataCell>
                                <DataCell> {employee.active} </DataCell>
                                <DataCell> {employee.sold} </DataCell>
                                <DataCell>
                                    {' '}
                                    {(
                                        (employee.sold / employee.total) *
                                        100
                                    ).toFixed(1)}{' '}
                                </DataCell>
                                {/* <DataCell> {(((employee.form ?? 0) / employee.total) * 100).toFixed(1)} </DataCell>
                                <DataCell> {((employee.finance / employee.total) * 100).toFixed(1)} </DataCell> */}
                                <DataCellColoured
                                    handleClick={() => {
                                        setSelectedFilter({
                                            enquiryStatus: [
                                                { type: 'ACTIVE' },
                                                { type: 'PENDING' },
                                                { type: 'SOLD' },
                                                { type: 'LOST' }
                                            ],
                                            assignedUser: [
                                                {
                                                    id: employee.id,
                                                    type: employee.username
                                                }
                                            ],
                                            startDate: null,
                                            endDate: null,
                                            make: [],
                                            sites: []
                                        });
                                        setActiveDrawerOpen(true);
                                    }}
                                >
                                    VIEW
                                </DataCellColoured>
                            </TableRow>
                        ))}
                    </DataTable>
                </Paper>
            </Grid>
        </>
    );
};

export default DataDisplay;
