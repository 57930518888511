// IMPORTS
import { Dispatch, SetStateAction } from 'react';

/**
 * SliceAndSet
 * Slice data into pages and set the pagination data
 * @author Estienne
 * @param data the data to slice
 * @param setPages the pages setter
 * @param setCurrentPage the current page setter
 * @param setNumResults the setter to number of results
 */
const SliceAndSet = (
  data: any,
  numRows: number,
  setPages: Dispatch<SetStateAction<any>>,
  setCurrentPage: Dispatch<SetStateAction<any>>,
  setNumResults: Dispatch<SetStateAction<number>>
) => {
  // Paginate the data
  let pages: any[] = [];
  for (let i = 0; i < data.length; i += numRows) {
    let page = data.slice(i, i + numRows);
    pages.push(page);
  }
  setPages(pages); // Set the array of pages
  setCurrentPage(pages[0]); // Set the current page
  setNumResults(data.length); // Set the number of results
};

export default SliceAndSet;
