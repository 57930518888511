// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
import TemplateValidation from './TemplateValidation';
// INTERFACES
import { Template } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const AddNewTemplate = (
    template: Template,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    setTemplateError: Dispatch<
        SetStateAction<{
            subject: boolean;
            department: boolean;
            messageBody: boolean;
            type: boolean;
        }>
    >
) => {
    setLoading(true);
    setReadOnly(true);

    // Check if there are any basic errors with the entered data
    let isError = TemplateValidation(
        template,
        setLoading,
        setReadOnly,
        showSnackbar,
        setTemplateError
    );

    if (!isError) {
        // If there are no errors send to the backend
        api.post('addNewTemplate', template).then((res) => {
            if (res.status === 400) {
                // Missing Data
                showSnackbar(
                    'Whoops! There appears to be some missing information!',
                    'Please ensure you have filled out all required fields and then try again.',
                    'error'
                );
                setReadOnly(false);
                setLoading(false);
            } else if (res.status === 500) {
                // Incorrect Data
                showSnackbar(
                    'Whoops! Our servers say there is something wrong with your data!',

                    'Please ensure you have filled out all fields correctly and then try again.',
                    'error'
                );
                setReadOnly(false);
                setLoading(false);
            } else if (res.status === 200) {
                // Successful
                showSnackbar('Template submitted successfully!');
                setReadOnly(false);
                setLoading(false);
                window.location.href = `/viewTemplate/${res.data.id}`;
            } else {
                // Misc Server Error
                showSnackbar(
                    'Whoops! Something is wrong here!',
                    'Please contact your IT department to get this issue resolved.',
                    'error'
                );
                setReadOnly(false);
                setLoading(false);
            }
        });
    }
};

export default AddNewTemplate;
