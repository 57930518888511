import { Dispatch, SetStateAction } from "react";
import api from "../../../../api";
import { Vehicle } from "../interfaces/VehicleSaleInterface";

const GetAllAvailableVehicles = (setAllVehicles: Dispatch<SetStateAction<Vehicle[]>>, SiteId: number) => {
    api.get(`availableVehicles?SiteId=${SiteId}`).then(res => {
        setAllVehicles(res.data)
    })
}

export default GetAllAvailableVehicles;