// IMPORTS
import {
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    useContext
} from 'react';
import {
    Checkbox,
    DialogContentText,
    FormControlLabel,
    Grid
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';

// LOGIC
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import GetMakes from '../../../global/databaseLogic/GetMakes';
import GetModels from '../../../global/databaseLogic/GetModels';
import { SiteContext } from '../../../../main/Main';

interface VehicleTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const conditionOptions = [
    { id: 0, label: 'New', value: 'New' },
    { id: 1, label: 'Used', value: 'Used' },
    { id: 2, label: 'Demo', value: 'Demo' }
];

const VehicleTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: VehicleTableFilterProps) => {
    const [sites, setSites] = useState<Site[]>([]);
    const [condition, setCondition] = useState([]);
    const [make, setMake] = useState([]);
    const [selectedMake, setSelectedMake] = useState([]);
    const [model, setModel] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const siteDetails = useContext<Site>(SiteContext);

    useEffect(() => {
        GetAllSites(setSites);
        GetMakes(setMake);
        GetModels(setModel);
    }, []);

    useEffect(() => {
        if (!isFilterActive()) {
            setCondition(conditionOptions);
            setSites([]);
            setSelectedMake([]);
            setModel([]);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.vehicleFilter;
        newFilter[property] = value;
        setFilter({ ...filter, vehicleFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                {siteDetails &&
                    siteDetails.vehicleTypeSold === 'Motorcycles' && (
                        <Grid item xs={4}>
                            {/* Bike Condition Filter */}
                            <AutocompleteMultiple
                                options={conditionOptions}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'label'}
                                textfieldLabel="Condition"
                                selectedValues={filter.vehicleFilter.condition}
                                handleSelectedValueChange={(event) => {
                                    let currentOptions = condition;
                                    let newFilterValue = [];

                                    // Add or remove the value from the selected value
                                    // Also add or remove the selected option from the options list so it can not be added more than once
                                    event.forEach((val, index) => {
                                        newFilterValue.push(val);
                                        currentOptions = currentOptions.filter(
                                            (x) => x.id !== val.id
                                        );
                                    });

                                    setCondition(currentOptions);
                                    handleUpdateFilter(
                                        'condition',
                                        newFilterValue
                                    );
                                }}
                                handleInputValueChange={() => null}
                            />
                        </Grid>
                    )}
                <Grid item xs={4}>
                    {/* Make Filter */}
                    <AutocompleteMultiple
                        options={make}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Make"
                        selectedValues={filter.vehicleFilter.make}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = selectedMake;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSelectedMake(currentOptions);
                            handleUpdateFilter('make', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Model Filter */}
                    <AutocompleteMultiple
                        options={model}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Model"
                        selectedValues={filter.vehicleFilter.model}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = selectedModel;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSelectedModel(currentOptions);
                            handleUpdateFilter('model', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Site Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.vehicleFilter.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sites;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="YYYY"
                            views={['year']}
                            label="Year Start Range"
                            value={filter.vehicleFilter.startYear}
                            onChange={(newValue) => {
                                handleUpdateFilter('startYear', newValue);
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="YYYY"
                            views={['year']}
                            label="Year End Range (Excluding)"
                            value={filter.vehicleFilter.endYear}
                            onChange={(newValue) => {
                                handleUpdateFilter('endYear', newValue);
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.vehicleFilter.available}
                                onChange={(e) => {
                                    handleUpdateFilter(
                                        'available',
                                        e.target.checked
                                    );
                                }}
                            />
                        }
                        label="Available"
                    />
                    {siteDetails &&
                        siteDetails.vehicleTypeSold === 'Motorcycles' && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            filter.vehicleFilter.learnerApproved
                                        }
                                        onChange={(e) => {
                                            handleUpdateFilter(
                                                'learnerApproved',
                                                e.target.checked
                                            );
                                        }}
                                    />
                                }
                                label="Learner Approved"
                            />
                        )}
                </Grid>
            </Grid>
        </>
    );
};

export default VehicleTableFilter;
