// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    DialogActions,
    Button,
    Grid
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
// LOGIC
import api from '../../../../../api';
import moment from 'moment';
// COMPONENTS
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { DebtorPayment } from '../../../global/interfaces/GeneralInterface';

const LineMenu = ({
    row,
    showSnackbar,
    setOpenPaymentHistoryDrawer,
    setPaymentHistoryContent
}: {
    row: any;
    showSnackbar: showSnackbar;
    setOpenPaymentHistoryDrawer: Dispatch<SetStateAction<boolean>>;
    setPaymentHistoryContent: Dispatch<SetStateAction<DebtorPayment>>;
}) => {
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [paymentAmount, setPaymentAmount] = useState<number>(null);
    const [paymentDate, setPaymentDate] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        setPaymentAmount(Math.abs(row.amountDue));
        setPaymentDate(moment(row.paymentDueDate).add(1, 'day'));
    }, [row]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        api.delete('debtorPayment/' + row.id).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Payment deleted successfully',
                    'The page will now refresh.'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'It looks like something went wrong on our end.',
                    'Please contact your IT team.',
                    'error'
                );
            }
        });
    };

    const submitChanges = () => {
        setButtonLoading(true);
        api.put('debtorPayment/' + row.id, {
            paymentAmount: paymentAmount,
            paymentDate: moment(paymentDate)
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Payment updated successfully',
                    'The page will now refresh.',
                    'success'
                );
                window.location.reload();
            } else {
                setButtonLoading(false);
                if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact the IT department.',
                        'error'
                    );
                } else {
                    showSnackbar(
                        res.data.split('|')[0],
                        res.data.split('|')[1],
                        'error'
                    );
                }
            }
        });
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        setEditOpen(true);
                        handleClose();
                    }}
                >
                    Edit Payment
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleDelete();
                        handleClose();
                    }}
                >
                    Delete Payment
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setPaymentHistoryContent(row);
                        setOpenPaymentHistoryDrawer(true);
                        handleClose();
                    }}
                >
                    Payment History
                </MenuItem>
            </Menu>

            <Dialog
                open={editOpen}
                onClose={() => setEditOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Edit Payment Details</DialogTitle>
                <DialogContent>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                disabled
                                type="number"
                                label="Payment Amount"
                                variant="outlined"
                                value={Math.abs(paymentAmount)}
                                onChange={(e) =>
                                    setPaymentAmount(
                                        Math.abs(parseFloat(e.target.value))
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                type="date"
                                label="Payment Date"
                                InputLabelProps={{ shrink: true }}
                                value={paymentDate}
                                onChange={(e) => setPaymentDate(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditOpen(false)}>Cancel</Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() => submitChanges()}
                    >
                        Submit Changes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LineMenu;
