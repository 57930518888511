import { Dispatch, SetStateAction } from 'react';
import { BalanceSheetLine } from '../interface/BalanceSheetInterface';

const CombineBalanceSheetLines = (
    lines: BalanceSheetLine[],
    setSingleLine: Dispatch<SetStateAction<BalanceSheetLine>>
) => {
    let cashAtBankTotal = 0;
    let newVehiclesTotal = 0;
    let newVehiclesData = [];
    let usedVehiclesTotal = 0;
    let usedVehiclesData = [];
    let floorplanLiabilityTotal = 0;
    let floorplanLiabilityData = [];
    let stockAdjustmentTotal = 0;
    let stockAdjustmentData = [];
    let averageCostAdjustmentTotal = 0;
    let averageCostAdjustmentData = [];
    let stripeFeesTotal = 0;
    let stripeFeesData = [];
    let rebateBalanceTotal = 0;
    let rebateBalanceData = [];
    let registrationClearingTotal = 0;
    let registrationClearingData = [];
    let accountLines = [];
    let cashHoldingTotal = 0;
    let stockOnHandTotal = 0;
    let pendingDebtorPayment = 0;
    let debtorPaymentData = [];
    let pendingPayrun = 0;
    let pendingPayrunData = [];
    let giftVoucher = 0;
    let giftVoucherData = [];
    let paymentLiabilityPA = 0;
    let paymentLiabilityVehicleSale = 0;
    let paymentLiabilityService = 0;
    let paymentLiabilityDataPA = [];
    let paymentLiabilityDataVehicleSale = [];
    let paymentLiabilityDataService = [];

    lines.forEach((line) => {
        cashAtBankTotal += line.cashAtBankTotal;
        newVehiclesTotal += line.newVehiclesTotal;
        usedVehiclesTotal += line.usedVehiclesTotal;
        floorplanLiabilityTotal += line.floorplanLiabilityTotal;
        stockAdjustmentTotal += line.stockAdjustmentTotal;
        averageCostAdjustmentTotal += line.averageCostAdjustmentTotal;
        stripeFeesTotal += line.stripeFeesTotal;
        rebateBalanceTotal += line.rebateBalanceTotal;
        registrationClearingTotal += line.registrationClearingTotal;
        cashHoldingTotal += line.cashHoldingTotal;
        stockOnHandTotal += line.stockOnHandTotal;
        pendingDebtorPayment += line.pendingDebtorPayment;
        pendingPayrun += line.pendingPayrun;
        giftVoucher += line.giftVoucher;
        paymentLiabilityPA += line.paymentLiabilityPA;
        paymentLiabilityVehicleSale += line.paymentLiabilityVehicleSale;
        paymentLiabilityService += line.paymentLiabilityService;

        newVehiclesData.push([...line.newVehiclesData]);
        usedVehiclesData.push([...line.usedVehiclesData]);
        floorplanLiabilityData.push([...line.floorplanLiabilityData]);
        stockAdjustmentData.push([...line.stockAdjustmentData]);
        averageCostAdjustmentData.push([...line.averageCostAdjustmentData]);
        stripeFeesData.push([...line.stripeFeesData]);
        rebateBalanceData.push([...line.rebateBalanceData]);
        registrationClearingData.push([...line.registrationClearingData]);
        debtorPaymentData.push([...line.debtorPaymentData]);
        pendingPayrunData.push([...line.pendingPayrunData]);
        giftVoucherData.push([...line.giftVoucherData]);
        paymentLiabilityDataPA.push([...line.paymentLiabilityDataPA]);
        paymentLiabilityDataVehicleSale.push([
            ...line.paymentLiabilityDataVehicleSale
        ]);
        paymentLiabilityDataService.push([...line.paymentLiabilityDataService]);

        if (accountLines.length === 0) {
            accountLines = line.accountLines;
        } else {
            let currentAccountValues = [...accountLines];
            line.accountLines.forEach((value) => {
                let found = false;

                currentAccountValues.forEach((row) => {
                    if (row.AccountId === value.AccountId) {
                        found = true;
                        row.total = row.total + value.total;
                    }
                });

                if (!found) {
                    currentAccountValues.push(value);
                }
            });
            accountLines = currentAccountValues;
        }
    });

    let singleLine = {
        cashAtBankTotal: cashAtBankTotal,
        newVehiclesTotal: newVehiclesTotal,
        newVehiclesData: newVehiclesData,
        usedVehiclesTotal: usedVehiclesTotal,
        usedVehiclesData: usedVehiclesData,
        floorplanLiabilityTotal: floorplanLiabilityTotal,
        floorplanLiabilityData: floorplanLiabilityData,
        stockAdjustmentTotal: stockAdjustmentTotal,
        stockAdjustmentData: stockAdjustmentData,
        averageCostAdjustmentTotal: averageCostAdjustmentTotal,
        averageCostAdjustmentData: averageCostAdjustmentData,
        stripeFeesTotal: stripeFeesTotal,
        stripeFeesData: stripeFeesData,
        rebateBalanceTotal: rebateBalanceTotal,
        rebateBalanceData: rebateBalanceData,
        registrationClearingTotal: registrationClearingTotal,
        registrationClearingData: registrationClearingData,
        accountLines: accountLines,
        cashHoldingTotal: cashHoldingTotal,
        stockOnHandTotal: stockOnHandTotal,
        pendingDebtorPayment: pendingDebtorPayment,
        debtorPaymentData: debtorPaymentData,
        pendingPayrun: pendingPayrun,
        pendingPayrunData: pendingPayrunData,
        giftVoucher: giftVoucher,
        giftVoucherData: giftVoucherData,
        paymentLiabilityPA: paymentLiabilityPA,
        paymentLiabilityVehicleSale: paymentLiabilityVehicleSale,
        paymentLiabilityService: paymentLiabilityService,
        paymentLiabilityDataPA: paymentLiabilityDataPA,
        paymentLiabilityDataVehicleSale: paymentLiabilityDataVehicleSale,
        paymentLiabilityDataService: paymentLiabilityDataService
    };

    setSingleLine(singleLine);
};

export default CombineBalanceSheetLines;
