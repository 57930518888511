// IMPORTS
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import InvoiceHeader from './components/InvoiceHeader';
import InvoiceTable from './components/InvoiceTable';
import Fab from '../../global/Fab';
// LOGIC
import SubmitCreditInvoice from './logic/SubmitCreditInvoice';
import GetCreditInvoice from './logic/GetCreditInvoice';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Creditor } from '../../global/interfaces/GeneralInterface';
import GetCreditor from '../../global/databaseLogic/GetCreditor';
import { Dayjs } from 'dayjs';
import { withSnackbar } from '../../global/WrappingSnackbar';

interface CreditingPartsLine {
    partNumber: string;
    name: string;
    unitId: number;
    costPriceDaily: number;
    quantity: number;
    lineTotal: number;
    qtyError: boolean;
    lineTotalError: boolean;
}

const CreditInvoiceUI = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>('');

    const [selectedSite, setSelectedSite] = useState<number>(null);
    const [reference, setReference] = useState<string>('');
    const [selectedSupplier, setSelectedSupplier] = useState<number>(null);
    const [selectedSupplierData, setSelectedSupplierData] = useState<Creditor>(
        {}
    );
    const [documentDate, setDocumentDate] = useState<Dayjs>(null);
    const [dueDate, setDueDate] = useState<Dayjs>(null);
    const [creditingParts, setCreditingParts] = useState<CreditingPartsLine[]>(
        []
    );
    const [freight, setFreight] = useState<string>('0');
    const [isGSTIncluded, setIsGSTIncluded] = useState<boolean>(true);
    const [isReconciled, setIsReconciled] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id) {
            GetCreditInvoice(
                id,
                setSelectedSupplier,
                setSelectedSite,
                setReference,
                setDocumentDate,
                setDueDate,
                setNotes,
                setCreditingParts,
                setFreight,
                setIsReconciled,
                setReadOnly
            );
        } else {
            setReadOnly(false);
        }
    }, [id]);

    useEffect(() => {
        if (selectedSupplier) {
            GetCreditor(
                String(selectedSupplier),
                setSelectedSupplierData,
                setLoading
            );
        }
    }, [selectedSupplier]);

    const handleFabClick = () => {
        if (readOnly) {
            setReadOnly(false);
        } else {
            SubmitCreditInvoice(
                parseInt(id),
                selectedSupplierData,
                selectedSite,
                reference,
                documentDate,
                dueDate,
                creditingParts,
                parseFloat(freight),
                isGSTIncluded,
                notes,
                setCreditingParts,
                showSnackbar,
                setLoading
            );
        }
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Credit Stock Invoice</Typography>
            <br />

            <InvoiceTable
                selectedSupplier={selectedSupplier}
                setSelectedSupplier={setSelectedSupplier}
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                selectedSupplierData={selectedSupplierData}
                creditingParts={creditingParts}
                setCreditingParts={setCreditingParts}
                isGSTIncluded={isGSTIncluded}
                setIsGSTIncluded={setIsGSTIncluded}
                showSnackbar={showSnackbar}
                readOnly={readOnly}
            />
            <br />
            <InvoiceHeader
                selectedSupplier={selectedSupplier}
                setSelectedSupplier={setSelectedSupplier}
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                reference={reference}
                setReference={setReference}
                documentDate={documentDate}
                setDocumentDate={setDocumentDate}
                dueDate={dueDate}
                setDueDate={setDueDate}
                readOnly={readOnly}
                freight={freight}
                setFreight={setFreight}
                isGSTIncluded={isGSTIncluded}
                creditingParts={creditingParts}
            />

            {creditingParts.length > 0 && !isReconciled ? (
                <Fab
                    noSession={true}
                    editIcon={readOnly}
                    onClick={() => handleFabClick()}
                    loading={loading}
                />
            ) : null}
        </PageWrapper>
    );
};

export default withSnackbar(CreditInvoiceUI);
