import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';

import Papa from 'papaparse';
import DataTable from '../../../global/tableComponents/DataTable';
import { Clear } from '@mui/icons-material';
import { ImportAccountFromCSV } from '../logic/ImportAccountFromCSV';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const ImportAccountCSVContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [importedData, setImportedData] = useState([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [importedDataFilename, setImportedDataFilename] = useState([]);

    var headerOptions = [
        { id: 0, label: 'Account Name', value: 'name' },
        { id: 1, label: 'Initial Balance', value: 'initialBalance' },
        { id: 2, label: 'Type', value: 'type' },
        { id: 3, label: 'Site ID', value: 'SiteId' },
        { id: 4, label: 'Category', value: 'accountCategoryName' }
    ];

    const parseCSV = (e) => {
        Papa.parse(e, {
            header: false,
            skipEmptyLines: 'greedy',
            complete: (results) => {
                var newData = [];
                let maxLength = 0;
                results.data.forEach((row, i) => {
                    newData.push(row);
                    maxLength = maxLength < row.length ? row.length : maxLength;
                });
                setImportedData(newData);

                let newHeaders = new Array(maxLength);
                newHeaders.fill('');
                setHeaders(newHeaders);
            }
        });
    };

    const importFile = (e) => {
        setImportedDataFilename(e.target.files[0].name);
        parseCSV(e.target.files[0]);
    };

    const checkType = (index) => {
        let incorrectType = false;
        let correctType = [
            'Expense',
            'Revenue',
            'Asset (Current)',
            'Asset (Non-Current)',
            'Liability (Current)',
            'Liability (Non-Current)'
        ];
        for (let item of importedData) {
            if (correctType.indexOf(item[index]) === -1) {
                incorrectType = true;
            }
        }
        return incorrectType;
    };

    const deleteColumn = (index) => {
        let currentHeaders = [...headers];
        let currentLines = [...importedData];

        currentHeaders.splice(index, 1);
        for (let item of currentLines) {
            item.splice(index, 1);
        }

        setHeaders(currentHeaders);
        setImportedData(currentLines);
    };

    const deleteLine = (index) => {
        let currentLines = [...importedData];
        currentLines.splice(index, 1);
        setImportedData(currentLines);
    };

    const updateColumnsHeaders = (value, index) => {
        let currentHeaders = [...headers];
        currentHeaders[index] = value;
        setHeaders(currentHeaders);
    };

    return (
        <>
            <DialogTitle>Import Account CSV</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign={'center'}>
                        {importedData.length > 0 ? (
                            importedDataFilename + ' uploaded.'
                        ) : (
                            <>
                                <input
                                    onChange={(e) => importFile(e)}
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="outlined" component="span">
                                        Upload CSV
                                    </Button>
                                </label>
                            </>
                        )}
                    </Grid>
                    {importedData.length > 0 ? (
                        <Grid item xs={12}>
                            <Grid container spacing={0} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Use the below interface to select which
                                        columns in the CSV correspond to which
                                        account's property. You must at least
                                        select: Account Name and Account Type.
                                        The import button will be greyed out
                                        until you have at least these two
                                        headers.
                                    </Typography>
                                    <Typography>
                                        Using the{' '}
                                        <Clear
                                            style={{ verticalAlign: 'middle' }}
                                        />{' '}
                                        button will tell Ora to delete the
                                        corresponding row/column from the
                                        imported data.
                                    </Typography>
                                    <Typography>
                                        This can be used to delete headers for
                                        example.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Divider />
                            <Grid container spacing={0} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Ora expects the following value to be in
                                        the CSV in order to create the Accounts:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    Account Name (required)
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="caption">
                                        Can be any string of characters.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    Account Type (required)
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="caption">
                                        The only values currently supported by
                                        Ora for the Type column are the
                                        following: Expense, Revenue, Asset
                                        (Current), Asset (Non-Current),
                                        Liability (Current), Liability
                                        (Non-Current). If Ora detects an
                                        incompatible value in a column it will
                                        not let you to select the Type header.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    Initial Balance
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="caption">
                                        Must be a number.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    Site ID
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="caption">
                                        Ora expected the Site column to use the
                                        Site ID (that can be found in the Sites
                                        page of Ora)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={4}>
                                    Account Category
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="caption">
                                        The only values currently supported by
                                        Ora for the Category column are the
                                        following: Non Operating Expenses,
                                        Directors Emoluments, Other Income,
                                        Other Direct Expenses, Indirect
                                        Expenses, General Expenses, Employment
                                        Expenses, Vehicle Selling Expenses. If a
                                        line has an empty value, not Category
                                        will be assigned to the Account.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <DataTable columns={[]}>
                            {importedData.length > 0 ? (
                                <>
                                    <TableRow>
                                        {headers.map((item, index) => (
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        deleteColumn(index)
                                                    }
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        {headers.map((item, index) => (
                                            <TableCell>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    value={item}
                                                    onChange={(e) => {
                                                        updateColumnsHeaders(
                                                            e.target.value,
                                                            index
                                                        );
                                                    }}
                                                >
                                                    {headerOptions.map(
                                                        (option) => (
                                                            <MenuItem
                                                                value={
                                                                    option.value
                                                                }
                                                                disabled={
                                                                    headers.includes(
                                                                        option.value
                                                                    ) ||
                                                                    (checkType(
                                                                        index
                                                                    ) &&
                                                                        option.value ===
                                                                            'type')
                                                                }
                                                            >
                                                                {option.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    {importedData
                                        .slice(0, 5)
                                        .map((line, index) => (
                                            <TableRow>
                                                {line.map((item) => (
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                ))}
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteLine(index)
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    <TableRow>
                                        {headers.map((item) => (
                                            <TableCell>...</TableCell>
                                        ))}
                                        <TableCell>...</TableCell>
                                    </TableRow>
                                    {importedData
                                        .slice(
                                            importedData.length - 5,
                                            importedData.length
                                        )
                                        .map((line) => (
                                            <TableRow>
                                                {line.map((item) => (
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                ))}
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteLine(
                                                                importedData.findIndex(
                                                                    (x) =>
                                                                        x[0] ===
                                                                            line[0] &&
                                                                        x[1] ===
                                                                            line[1]
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </>
                            ) : null}
                        </DataTable>
                    </Grid>
                </Grid>
            </DialogContent>
            {importedData.length > 0 ? (
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={
                            !headers.includes('name') ||
                            !headers.includes('type')
                        }
                        onClick={() =>
                            ImportAccountFromCSV(
                                importedData,
                                headers,
                                showSnackbar
                            )
                        }
                    >
                        Import Account(s)
                    </Button>
                </DialogActions>
            ) : null}
        </>
    );
};
