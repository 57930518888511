import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import SignatureCanvas from 'react-signature-canvas';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useRef } from 'react';

const DeliveryReceiptForm = ({ control, setValue }) => {
    let purchaserSigCanvas = useRef<SignatureCanvas>(null);
    let witnessSigCanvas = useRef<SignatureCanvas>(null);

    const clearSignature = (canvasName: string) => {
        if (canvasName === 'purchaserSigCanvas' && purchaserSigCanvas.current) {
            purchaserSigCanvas.current.clear();
        } else if (
            canvasName === 'witnessSigCanvas' &&
            witnessSigCanvas.current
        ) {
            witnessSigCanvas.current.clear();
        }
    };

    const handleSaveSignature = (canvasName: string) => {
        if (canvasName === 'purchaserSigCanvas' && purchaserSigCanvas.current) {
            const dataURL = purchaserSigCanvas.current.toDataURL();
            setValue('purchaserSigDataURL', dataURL);
        } else if (
            canvasName === 'witnessSigCanvas' &&
            witnessSigCanvas.current
        ) {
            const dataURL = witnessSigCanvas.current.toDataURL();
            setValue('witnessSigDataURL', dataURL);
        }
    };

    return (
        <Grid container spacing={2} margin={'10px'}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    DELIVERY RECEIPT
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        name="deliveryDate"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Date"
                                value={dayjs(field.value)}
                                onChange={field.onChange}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false
                                    }
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
                SIGNATURE OF THE PURCHASER :{' '}
                <IconButton
                    onClick={() => clearSignature('purchaserSigCanvas')}
                >
                    <ClearIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Controller
                    name="purchaserSigDataURL"
                    control={control}
                    render={({ field }) => (
                        <SignatureCanvas
                            penColor="black"
                            disabled={false}
                            canvasProps={{
                                width: 430,
                                height: 200,
                                className: 'sigCanvas',
                                style: {
                                    border: '1px solid black'
                                }
                            }}
                            ref={purchaserSigCanvas}
                            onEnd={() =>
                                handleSaveSignature('purchaserSigCanvas')
                            }
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                SIGNATURE OF THE WITNESS :{' '}
                <IconButton onClick={() => clearSignature('witnessSigCanvas')}>
                    <ClearIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Controller
                    name="witnessSigDataURL"
                    control={control}
                    render={({ field }) => (
                        <SignatureCanvas
                            penColor="black"
                            disabled={false}
                            canvasProps={{
                                width: 430,
                                height: 200,
                                className: 'sigCanvas',
                                style: {
                                    border: '1px solid black'
                                }
                            }}
                            ref={witnessSigCanvas}
                            onEnd={() =>
                                handleSaveSignature('witnessSigCanvas')
                            }
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
            </Grid>
        </Grid>
    );
};

export default DeliveryReceiptForm;
