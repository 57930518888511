import api from '../../../../../api';
import { showSnackbar } from '../../interfaces/GlobalInterface';
import { PaymentLine } from '../../interfaces/GeneralInterface';
import { Order } from '../../interfaces/PartsInterface';
import { VehicleSale } from '../../interfaces/VehicleSaleInterface';
import { Service } from '../../interfaces/ServiceInterface';

const UnreconcileTransaction = (
    payment: PaymentLine,
    saleDetails: Order,
    vehicleSaleDetails: VehicleSale,
    serviceDetails: Service,
    showSnackbar: showSnackbar
) => {
    let query = '';
    if (saleDetails?.id) {
        query = `OrderId=${saleDetails?.id}&PaymentId=${payment.id}`;
    } else if (vehicleSaleDetails?.id) {
        query = `VehicleSaleId=${vehicleSaleDetails?.id}&PaymentId=${payment.id}`;
    } else if (serviceDetails?.id) {
        query = `ServiceId=${serviceDetails?.id}&PaymentId=${payment.id}`;
    }

    api.put(`unreconcileTransaction?${query}`).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Transaction Unreconciled',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default UnreconcileTransaction;
