import jsPDF from "jspdf";
import { CurrencyFormatter } from "../../../../../../global/logic/Formatters"

interface NetDivisionalProfitParams {
    (
        doc: jsPDF,
        totalDE: { current: number; ytd: number; previousYear: number; previousYearYtd: number },
        netOperationalProfit: {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        },
        afterDE: number
    ): [
        number,
        {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        }
    ];
}

const NetDivisionalProfit: NetDivisionalProfitParams = (doc, totalDE, netOperationalProfit, afterDE) => {
    let netDivisionalProfit = {
        current: netOperationalProfit.current - totalDE.current,
        ytd: netOperationalProfit.ytd - totalDE.ytd,
        previousYear: netOperationalProfit.previousYear - totalDE.previousYear,
        previousYearYtd: netOperationalProfit.previousYearYtd - totalDE.previousYearYtd
    }
    // doc.setFont('helvetica', 'bold').text('TOTAL OTHER INCOME', 10, afterDE)
    // doc.text(CurrencyFormatter(totalDE.current), 74, afterDE, {align:'right'})
    // doc.text(CurrencyFormatter(totalDE.ytd), 107, afterDE, {align:'right'})
    // doc.text(CurrencyFormatter(totalDE.previousYear), 140, afterDE, {align:'right'})
    // doc.text(CurrencyFormatter(totalDE.previousYearYtd ), 173, afterDE, {align:'right'})

    doc.setFont('helvetica', 'bold').text('NET DIVISIONAL PROFIT', 10, afterDE + 4)
    doc.text(CurrencyFormatter(netDivisionalProfit.current), 74, afterDE + 4, {align:'right'})
    doc.text(CurrencyFormatter(netDivisionalProfit.ytd), 107, afterDE + 4, {align:'right'})
    doc.text(CurrencyFormatter(netDivisionalProfit.previousYear), 140, afterDE + 4, {align:'right'})
    doc.text(CurrencyFormatter(netDivisionalProfit.previousYearYtd), 173, afterDE + 4, {align:'right'})

    doc.line(10, afterDE + 5, 200, afterDE + 5)

    return [afterDE + 8, netDivisionalProfit]
}

export default NetDivisionalProfit