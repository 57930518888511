import {
    IconButton,
    MenuItem,
    Switch,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Add, Clear, Edit, SettingsBackupRestore } from '@mui/icons-material';

// COMPONENT
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import Fab from '../../global/Fab';

// LOGIC
import GetAllSites from '../../global/databaseLogic/GetAllSites';
import GetAllIPWhitelist from '../../global/databaseLogic/GetAllIPWhitelist';
import SubmitIPWhitelist from './logic/SubmitIPWhitelist';

// INTERFACES
import { Site } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

interface IPData {
    active: boolean;
    mask: string;
    SiteId: number;
    readOnly: boolean;
    newLine?: boolean;
    updated?: boolean;
    deleted?: boolean;

    // Error handling
    maskError?: boolean;
    siteError?: boolean;
}

const IPWhitelistUI = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [ipData, setIpData] = useState<IPData[]>([]);
    const [siteList, setSiteList] = useState<Site[]>([]);
    const [changed, setChanged] = useState<boolean>(false);

    useEffect(() => {
        GetAllSites(setSiteList);
        GetAllIPWhitelist(setIpData);
    }, []);

    const columns = [
        { id: 0, label: 'Active' },
        { id: 1, label: 'Site', width: 200 },
        { id: 2, label: 'Mask' },
        { id: 4, label: '' }
    ];

    // Add a line to the table, add the newLine property to the new object
    // It will be used to separate from the already existing line when submitting the form
    const handleAddLine = () => {
        let currentIPData = [...ipData];
        currentIPData.push({
            active: false,
            SiteId: null,
            mask: '',
            readOnly: false,
            newLine: true
        });
        setChanged(true);
        setIpData(currentIPData);
    };

    const handleEditLine = (index: number) => {
        let currentIPData = [...ipData];
        currentIPData[index].readOnly = false;
        currentIPData[index].updated = true;
        setChanged(true);
        setIpData(currentIPData);
    };

    const handleDeleteLine = (index: number) => {
        let currentIPData = [...ipData];
        currentIPData[index].deleted = true;
        setIpData(currentIPData);
    };

    const handleUndeleteLine = (index: number) => {
        let currentIPData = [...ipData];
        currentIPData[index].deleted = false;
        setIpData(currentIPData);
    };

    const handleDataChange = (value, index: number, type: string) => {
        let currentIPData = [...ipData];
        currentIPData[index][type] = value;
        if (!currentIPData[index].newLine) {
            currentIPData[index].updated = true;
        }
        setIpData(currentIPData);
        setChanged(true);
    };

    return (
        <PageWrapper>
            <Typography variant="h4">IP Whitelist</Typography>
            <br />
            <Paper>
                <DataTable columns={columns}>
                    {ipData.map((line, index) =>
                        line.deleted ? (
                            <TableRow sx={{ stroke: 1 }}>
                                <DataCell>
                                    <Switch
                                        checked={line.active}
                                        disabled={true}
                                        onChange={(e) =>
                                            handleDataChange(
                                                e.target.checked,
                                                index,
                                                'active'
                                            )
                                        }
                                    />
                                </DataCell>
                                <DataCell>
                                    <Typography
                                        variant="body1"
                                        sx={{ textDecoration: 'line-through' }}
                                    >
                                        {siteList.length > 0
                                            ? siteList.filter(
                                                  (x) => x.id === line.SiteId
                                              )[0].name
                                            : ''}
                                    </Typography>
                                </DataCell>
                                <DataCell>
                                    <Typography
                                        variant="body1"
                                        sx={{ textDecoration: 'line-through' }}
                                    >
                                        {line.mask}
                                    </Typography>
                                </DataCell>
                                <DataCell>
                                    <IconButton
                                        onClick={() =>
                                            handleUndeleteLine(index)
                                        }
                                    >
                                        <SettingsBackupRestore />
                                    </IconButton>
                                </DataCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <DataCell>
                                    <Switch
                                        checked={line.active}
                                        disabled={line.readOnly}
                                        onChange={(e) =>
                                            handleDataChange(
                                                e.target.checked,
                                                index,
                                                'active'
                                            )
                                        }
                                    />
                                </DataCell>
                                <DataCell>
                                    {line.readOnly ? (
                                        <Typography variant="body1">
                                            {siteList.length > 0
                                                ? siteList.filter(
                                                      (x) =>
                                                          x.id === line.SiteId
                                                  )[0].name
                                                : ''}
                                        </Typography>
                                    ) : (
                                        <TextField
                                            fullWidth
                                            select
                                            disabled={line.readOnly}
                                            variant={'outlined'}
                                            label={'Site'}
                                            error={line.siteError}
                                            helperText={
                                                line.siteError
                                                    ? 'A site must be selected.'
                                                    : ''
                                            }
                                            value={line.SiteId}
                                            onChange={(e) =>
                                                handleDataChange(
                                                    e.target.value,
                                                    index,
                                                    'SiteId'
                                                )
                                            }
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        >
                                            {siteList.map((site) => (
                                                <MenuItem value={site.id}>
                                                    {site.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </DataCell>
                                <DataCell>
                                    {line.readOnly ? (
                                        <Typography variant="body1">
                                            {line.mask}
                                        </Typography>
                                    ) : (
                                        <TextField
                                            size="small"
                                            fullWidth
                                            disabled={line.readOnly}
                                            error={line.maskError}
                                            helperText={
                                                line.maskError
                                                    ? 'The mask must be composed 4 parts separated by a ".", each part must be either 1 to 3 digit or a single lowercase "x".'
                                                    : ''
                                            }
                                            value={line.mask}
                                            onChange={(e) =>
                                                handleDataChange(
                                                    e.target.value,
                                                    index,
                                                    'mask'
                                                )
                                            }
                                            inputProps={{
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    )}
                                </DataCell>
                                <DataCell>
                                    {line.readOnly ? (
                                        <IconButton
                                            onClick={() =>
                                                handleEditLine(index)
                                            }
                                        >
                                            <Edit />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            onClick={() =>
                                                handleDeleteLine(index)
                                            }
                                        >
                                            <Clear />
                                        </IconButton>
                                    )}
                                </DataCell>
                            </TableRow>
                        )
                    )}

                    <TableRow>
                        <DataCell colSpan={4}>
                            <IconButton onClick={() => handleAddLine()}>
                                <Add />
                            </IconButton>
                        </DataCell>
                    </TableRow>
                </DataTable>
                <br />
                <Typography variant="caption" display="block">
                    Mask format should follow IP address standards and uses x as
                    wildcards. Example: 192.168.x.x will let anyone with an
                    address starting with 192.168 clock on and off.
                </Typography>
                <Typography variant="caption" display="block">
                    Note that unless your business has a static IP address, the
                    frequent changes of IP could prevent your users from
                    clocking on/off and will require the IT staff to update this
                    list every time it changes. It might be ideal to refrain
                    from using this feature unless you can have a static
                    address.
                </Typography>
            </Paper>
            {changed ? (
                <Fab
                    editIcon={false}
                    onClick={() =>
                        SubmitIPWhitelist(ipData, setIpData, showSnackbar)
                    }
                />
            ) : null}
            ∂∂∂
        </PageWrapper>
    );
};

export default withSnackbar(IPWhitelistUI);
