// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import { Order } from '../../../global/interfaces/PartsInterface';
import { ToFixed } from '../../../global/logic/Formatters';
// INTERFACES
import CalculateMargin from '../../../global/logic/CalculateMargin';
import { Creditor } from '../../../global/interfaces/GeneralInterface';

const HandleAddPart = (
    selectedPart,
    saleDetails: Order,
    setSaleDetails: Dispatch<SetStateAction<Order>>,
    selectedType: string,
    selectedEntity,
    selectedSupplier?: Creditor
) => {
    // The unit and stock data sent from the part search component
    let unitData = selectedPart.unitData;
    let stockData = selectedPart.stockData;

    // Get the current orderlines so we can edit them
    let currentOrderLines = [...saleDetails.orderLines];

    // Calculate the new row id
    // If there is no orderlines simply set it to 0
    // Otherwise set it to the last id in the orderlines plus 1
    let rowId: number;
    if (currentOrderLines.length > 0) {
        rowId = currentOrderLines.at(-1)['rowId'] + 1;
    } else {
        rowId = 0;
    }

    // If the part has a cost price average, then we will use it as our base cost
    // Otherwise, we will use cost price daily as the backup
    let costPrice = stockData.costPriceAverage
        ? parseFloat(stockData.costPriceAverage)
        : selectedSupplier?.CPD
          ? selectedSupplier?.CPD
          : unitData.costPriceDaily;

    // Calculate how much the customer will be paying for the part
    // If they are gst exempt this is the RRP minus GST
    // Otherwise it is just the RRP
    let pricePaid: number;
    let isGstFree = false;

    let cpd = selectedSupplier?.CPD ? selectedSupplier.CPD : parseFloat(unitData.costPriceDaily);
    let rrp = selectedSupplier?.RRP ? selectedSupplier.RRP : parseFloat(unitData.priceRRP);

    if (selectedType === 'service' || selectedType === 'workshop') {
        pricePaid = rrp / 1.1;
        isGstFree = true;
    } else if (
        !unitData.gstFree ||
        selectedType === 'guest' ||
        (selectedType === 'customer' &&
            (!selectedEntity?.gstFree || !selectedEntity?.international))
    ) {
        pricePaid = rrp;
    } else {
        pricePaid = rrp / 1.1;
        isGstFree = true;
    }

    // Check if the customer is a trade customer
    // If they are then we need to set a trade price as they receive a discount
    // If they are not then we can just leave trade price
    let isTradePrice: boolean;
    let customerObject =
        selectedType === 'customer' ? selectedEntity : selectedEntity?.Customer;
    if (
        (selectedType === 'customer' &&
            customerObject?.customerType === 'Trade') ||
        ((selectedType === 'service' || selectedType === 'contract') &&
            customerObject?.customerType === 'Trade')
    ) {
        isTradePrice = true;
        if (unitData.costPriceTrade) {
            // If the part has a specified trade price use that
            pricePaid = parseFloat(unitData.costPriceTrade);
        } else {
            // Otherwise use the discount from the selected entity
            if (selectedEntity?.Customer?.discountType === 'Cost') {
                // If cost + discount
                pricePaid =
                    costPrice +
                    costPrice *
                        (parseFloat(customerObject?.tradeDiscount) / 100);
            } else {
                // If retail - discount
                pricePaid =
                    pricePaid -
                    pricePaid *
                        (parseFloat(customerObject?.tradeDiscount) / 100);
            }
        }
    }

    // Calculate the margin
    let margin = CalculateMargin(pricePaid, costPrice);

    // Now we create the line item
    let newRow = {
        rowId: rowId,
        UnitId: unitData.id,
        partNumber: unitData.partNumber,
        name: unitData.name,
        SOH: stockData.qtyOnHand,
        RES: stockData.qtyReserved,
        quantity: '1', // line quantity
        backorderQuantity: '0', // line backorder quantity
        priceRRP: rrp,
        pricePaid: String(ToFixed(pricePaid)),
        totalPrice: ToFixed(pricePaid),
        costPriceAverage: stockData?.costPriceAverage
            ? parseFloat(stockData?.costPriceAverage)
            : 0,
        costPriceDaily: cpd,
        totalCost: costPrice,
        margin: ToFixed(margin),
        BinLocationIds: unitData.BinLocationIds,
        gstFree: isGstFree,
        tradeRRP: ToFixed(pricePaid),
        isTradePrice: isTradePrice,
        selectedSupplier: selectedSupplier
    };

    currentOrderLines.push(newRow);

    // Calculate the new total cost and total price for the order
    let totalPrice = 0;
    let totalCost = 0;
    currentOrderLines.forEach((line) => {
        totalPrice += line.totalPrice;
        totalCost += line.totalCost;
    });

    let totalMargin = CalculateMargin(totalPrice, totalCost);

    setSaleDetails({
        ...saleDetails,
        orderLines: currentOrderLines,
        totalPrice: ToFixed(totalPrice),
        totalCost: ToFixed(totalCost),
        totalMargin: ToFixed(totalMargin)
    });
};

export default HandleAddPart;
