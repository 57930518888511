import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Make, Vehicle } from "../../../global/interfaces/VehicleSaleInterface";

const HandleMakeNbVehicleClick = (make: Make, setSelectedMake: Dispatch<SetStateAction<Make>>, setOpenDialog: Dispatch<SetStateAction<boolean>>, setListofVehicles: Dispatch<SetStateAction<Vehicle[]>>, setFilterListOfVehicles: Dispatch<SetStateAction<Vehicle[]>>, setFilterValue: Dispatch<SetStateAction<string>>) => {
    api.get('vehiclesFromMake/' + make.id).then(res => {
        setListofVehicles(res.data)
        setFilterListOfVehicles(res.data)
        setSelectedMake(make)
        setFilterValue('')
        setOpenDialog(true)
    })
}

export default HandleMakeNbVehicleClick;