// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import { ToFixed } from '../../../global/logic/Formatters';
// INTERFACES
import {
    Customer,
    PaymentLine,
    User
} from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface AddPaymentProps {
    selectedTill: number;
    showSnackbar: showSnackbar;
    paymentAmount: number;
    paymentMethod: string;
    totalPrice: number;
    paymentRows: PaymentLine[];
    setPaymentRows: Dispatch<SetStateAction<PaymentLine[]>>;
    multipleCustomers?: boolean;
    payingCustomer?: Customer;
    paymentTypeService?: string;
    currentUser: User;
}

const AddPayment = ({
    selectedTill,
    showSnackbar,
    paymentAmount,
    paymentMethod,
    totalPrice,
    paymentRows,
    setPaymentRows,
    multipleCustomers,
    payingCustomer,
    paymentTypeService,
    currentUser
}: AddPaymentProps) => {
    // Check if there has been a till selected
    if (!selectedTill) {
        showSnackbar('Please select a till to continue.', '', 'error');
        return;
    }
    if (!paymentAmount || paymentAmount === 0) {
        showSnackbar(
            'Please enter a valid payment amount to continue.',
            '',
            'error'
        );
        return;
    }

    let currentPaymentLines = [...paymentRows];

    // Get the current date and time in our timezone
    var tzOffset = new Date().getTimezoneOffset() * 60000;
    var localIsoTime = new Date(Date.now() - tzOffset)
        .toISOString()
        .slice(0, -1);

    // Get the change
    let change = 0;

    if (
        paymentMethod === 'cash' &&
        paymentAmount > 0 &&
        paymentAmount > totalPrice
    ) {
        let current = currentPaymentLines.reduce((a, b) => {
            if (b.removed !== true) {
                return b.amountReceived - b.change + a;
            } else {
                return a;
            }
        }, 0);
        let value =
            Math.round((totalPrice - paymentAmount - current) * 100) / 100;
        change = Math.abs(ToFixed(value));
    }

    let rowId: number;
    if (currentPaymentLines.length > 0) {
        rowId = currentPaymentLines.at(-1)['id'] + 1;
    } else {
        rowId = 0;
    }

    var newRow = {
        id: rowId,
        type: paymentMethod,
        amountReceived: paymentAmount, // This is how much we physically received from the customer
        amount:
            paymentTypeService === 'deposit'
                ? paymentAmount
                : ToFixed(paymentAmount - change), // This is how much we tilled (this is just the amount received minus any change)
        change: paymentTypeService === 'deposit' ? 0 : change, // The change we gave the customer
        TillId: selectedTill,
        date: localIsoTime.split('T')[0],
        timestamp: localIsoTime,
        payingCustomerId: multipleCustomers ? payingCustomer.id : null,
        payingCustomerName: multipleCustomers
            ? payingCustomer.companyName
                ? payingCustomer.companyName
                : payingCustomer.firstName + ' ' + payingCustomer.lastName
            : null,
        user: currentUser.firstName + ' ' + currentUser.lastName
    };

    currentPaymentLines.push(newRow);
    setPaymentRows(currentPaymentLines);
};

export default AddPayment;
