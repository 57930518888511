// IMPORTS
import { useState, useEffect } from 'react';
import { CircularProgress, Dialog, Typography } from '@mui/material';
import {
    Edit,
    AddTask,
    LocalShipping,
    Print,
    Inventory,
    Delete
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import PageHeader from '../../global/PageHeader';
import SiteSelector from '../../global/SiteSelector';
import SpeedDial from '../../global/SpeedDial';
import Tabs from '../../global/Tabs';
import Fab from '../../global/Fab';
import Drawer from '../../global/Drawer';
import ManuallyAdjustStockDialog from './components/ManualllyAdjustStockDialog';
import StockTransferDrawerContent from '../../stock/stockTransferTable/components/StockTransferDrawerContent';
import OnlineReservedStockDialog from './components/OnlineReservedStockDialog';
import PartDetails from './components/PartDetails';
import Proformas from './components/Proformas';
import StocktakeHistory from './components/StocktakeHistory';
import Movement from './components/Movement';
import DeletePartDialog from './components/DeletePartDialog';
import Paper from '../../global/Paper';
import PageDoesNotExist from '../../global/PageDoesNotExist';
import SupplierSummary from './components/SupplierSummary';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import GetPartDetails from '../../global/logic/GetPartDetails';
import HandleEditClick from './logic/HandleEditClick';
import HandleCheckNegative from './logic/HandleCheckNegative';
import PrintLabel from '../../global/logic/PrintLabel';
// INTERFACES
import {
    Order,
    StockAdjustment,
    StockOrder,
    StockTransfer,
    Unit
} from '../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { CreditInvoice } from '../../global/interfaces/AdminInterface';
interface StockDetails {
    minStock: number;
    maxStock: number;
}

interface TempUnit extends Unit {
    reservedData: Order[];
    transferData: StockTransfer[];
    incomingData: StockOrder[];
    creditedData: CreditInvoice[];
}

const ViewPart = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    let { partId } = useParams<{ partId: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [partDetails, setPartDetails] = useState<TempUnit>();
    const [stockDetails, setStockDetails] = useState<StockDetails>({
        minStock: 0,
        maxStock: 0
    });

    const [selectedSite, setSelectedSite] = useState<number>(
        parseInt(localStorage.getItem('SiteId')!)
    );
    const [readOnly, setReadOnly] = useState(true);

    const [stockDialogOpen, setStockDialogOpen] = useState<boolean>(false);
    const [disableStockAdjust, setDisableStockAdjust] =
        useState<boolean>(false);
    const [stockAdjust, setStockAdjust] = useState<StockAdjustment>({
        adjustmentValue: 0,
        adjustmentCost: 0,
        adjustmentDescription: '',
        adjustAverage: true
    });

    const [transferDrawerOpen, setTransferDrawerOpen] =
        useState<boolean>(false);
    const [deletePartDialogOpen, setDeletePartDialogOpen] =
        useState<boolean>(false);

    const [onlineStockDialogOpen, setOnlineStockDialogOpen] =
        useState<boolean>(false);
    const [onlineReservedValue, setOnlineReservedValue] = useState<number>(0);

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Edit />,
            name: 'Edit Part Details',
            onClick: () =>
                HandleEditClick(
                    readOnly,
                    setReadOnly,
                    selectedSite,
                    showSnackbar,
                    partId,
                    partDetails,
                    stockDetails
                ),
            permission: 'edit_parts'
        },
        {
            icon: <AddTask />,
            name: 'Manually Adjust Stock',
            onClick: () => handleAdjustCostOpen(),
            permission: 'adjust_stock',
            noDisplay:
                selectedSite !== parseInt(localStorage.getItem('SiteId')!)
        },
        {
            icon: <Inventory />,
            name: 'Return Online Reserved to Stock',
            onClick: () => setOnlineStockDialogOpen(true),
            permission: 'adjust_onlineReserved',
            noDisplay:
                selectedSite !== parseInt(localStorage.getItem('SiteId')!)
        },
        {
            icon: <LocalShipping />,
            name: 'Transfer Part',
            onClick: () => setTransferDrawerOpen(true)
        },
        {
            icon: <Print />,
            name: 'Print Label',
            onClick: () =>
                PrintLabel(
                    [
                        {
                            partNumber: partDetails.partNumber,
                            name: partDetails.name,
                            priceRRP: partDetails.priceRRP,
                            binLocation: partDetails?.BinLocationIds[0]?.name
                        }
                    ],
                    showSnackbar
                )
        },
        {
            icon: <Delete />,
            name: 'Delete Part',
            onClick: () => setDeletePartDialogOpen(true),
            permission: 'delete_part'
        }
    ];

    // Get all the data regarding the selected part
    // Get all the options for the specifics to show in the dropdowns
    useEffect(() => {
        GetPartDetails({
            id: parseInt(partId),
            setPartDetails: setPartDetails,
            setStockDetails: setStockDetails,
            SiteId: selectedSite,
            setLoading: setLoading,
            setInvalid
        });
        // eslint-disable-next-line
    }, [partId]);

    // Handle opening the adjust cost dialog opening
    const handleAdjustCostOpen = () => {
        setStockDialogOpen(true);
        HandleCheckNegative(parseInt(partId), setDisableStockAdjust);
    };

    const tabContent = [
        {
            id: 0,
            title: 'Part Details',
            content: (
                <PartDetails
                    partDetails={partDetails}
                    setPartDetails={setPartDetails}
                    readOnly={readOnly}
                    selectedSite={selectedSite}
                    stockDetails={stockDetails}
                    setStockDetails={setStockDetails}
                    showSnackbar={showSnackbar}
                />
            )
        },
        {
            id: 1,
            title: 'Proformas',
            content: (
                <Proformas
                    partId={parseInt(partId!)}
                    selectedSite={selectedSite}
                />
            )
        },
        {
            id: 2,
            title: 'Stocktakes',
            content: (
                <StocktakeHistory
                    partId={parseInt(partId!)}
                    selectedSite={selectedSite}
                />
            )
        },
        {
            id: 3,
            title: 'Movement',
            content: (
                <Movement
                    partId={parseInt(partId!)}
                    selectedSite={selectedSite}
                    showSnackbar={showSnackbar}
                />
            )
        },
        {
            id: 4,
            title: 'Supplier(s) Pricing',
            content: (
                <SupplierSummary
                    partDetails={partDetails}
                    setPartDetails={setPartDetails}
                    siteId={selectedSite}
                    readOnly={readOnly}
                />
            )
        }
    ];

    return (
        <PageWrapper>
            {loading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <PageHeader variant="h4" title={partDetails?.partNumber}>
                        {partDetails?.Stock ? (
                            <SiteSelector
                                variant="outlined"
                                label=""
                                size="small"
                                disabled={!readOnly}
                                selectedSite={selectedSite}
                                setSelectedSite={setSelectedSite}
                                fullWidth={false}
                                viewPartUI={true}
                                stockData={partDetails?.Stock}
                            />
                        ) : null}
                    </PageHeader>

                    <Tabs tabContent={tabContent} />

                    {readOnly ? (
                        <SpeedDial actionsList={actionsList} />
                    ) : (
                        <Fab
                            editIcon={false}
                            onClick={() =>
                                HandleEditClick(
                                    readOnly,
                                    setReadOnly,
                                    selectedSite,
                                    showSnackbar,
                                    partId,
                                    partDetails,
                                    stockDetails
                                )
                            }
                        />
                    )}

                    {/* Delete Part Dialog */}
                    <Dialog
                        open={deletePartDialogOpen}
                        onClose={() => setDeletePartDialogOpen(false)}
                    >
                        <DeletePartDialog
                            partId={partId}
                            partNumber={partDetails?.partNumber}
                            showSnackbar={showSnackbar}
                        />
                    </Dialog>

                    {/* Manually Adjust Stock Dialog */}
                    <Dialog
                        open={stockDialogOpen}
                        onClose={() => setStockDialogOpen(false)}
                    >
                        <ManuallyAdjustStockDialog
                            partId={parseInt(partId)}
                            partDetails={partDetails}
                            stockAdjust={stockAdjust}
                            setStockAdjust={setStockAdjust}
                            disabledCostAdjust={disableStockAdjust}
                            setStockDialog={setStockDialogOpen}
                            showSnackbar={showSnackbar}
                        />
                    </Dialog>

                    {/* Return Online Reserved Stock Dialog */}
                    <Dialog
                        open={onlineStockDialogOpen}
                        onClose={() => setOnlineStockDialogOpen(false)}
                    >
                        <OnlineReservedStockDialog
                            partId={parseInt(partId)}
                            partDetails={partDetails}
                            onlineReservedValue={onlineReservedValue}
                            setOnlineReservedValue={setOnlineReservedValue}
                            setOnlineStock={setOnlineStockDialogOpen}
                            showSnackbar={showSnackbar}
                        />
                    </Dialog>

                    {/* Stock Transfer Drawer */}
                    <Drawer
                        openDrawer={transferDrawerOpen}
                        setOpenDrawer={setTransferDrawerOpen}
                        title={'Stock Transfer'}
                        subTitle={'New Stock Transfer'}
                        width="40vw"
                    >
                        <StockTransferDrawerContent
                            partDetails={partDetails}
                            showSnackbar={showSnackbar}
                        />
                    </Drawer>
                </>
            )}
        </PageWrapper>
    );
};

export default withSnackbar(ViewPart);
