const HandleMoveAllTransaction = (
    type: string,
    reconciliationDetails,
    setReconciliationDetails
) => {
    let oraTransactionsReconciled = [
        ...reconciliationDetails.oraTransactionsReconciled
    ];
    let bankTransactionsReconciled = [
        ...reconciliationDetails.bankTransactionsReconciled
    ];
    let oraTransactionsUnreconciled = [
        ...reconciliationDetails.oraTransactionsUnreconciled
    ];
    let bankTransactionsUnreconciled = [
        ...reconciliationDetails.bankTransactionsUnreconciled
    ];

    // If we are moving an ora transaction
    if (type === 'ora') {
        oraTransactionsReconciled.forEach((item) => {
            oraTransactionsUnreconciled.unshift(item);
        });
        oraTransactionsReconciled = [];
    }

    // If we are moving a bank transaction
    else if (type === 'bank') {
        bankTransactionsReconciled.forEach((item) => {
            bankTransactionsUnreconciled.unshift(item);
        });
        bankTransactionsReconciled = [];
    }

    setReconciliationDetails({
        ...reconciliationDetails,
        oraTransactionsReconciled: oraTransactionsReconciled,
        bankTransactionsReconciled: bankTransactionsReconciled,
        oraTransactionsUnreconciled: oraTransactionsUnreconciled,
        bankTransactionsUnreconciled: bankTransactionsUnreconciled
    });
};

export default HandleMoveAllTransaction;
