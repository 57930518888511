import { useState, useEffect } from 'react';
import { Typography, Grid, Box, Paper } from '@mui/material';
// INTERFACES
import LoginContent from './components/LoginContent';
import ChangePasswordContent from './components/ChangePasswordContent';
import GetCompanyLogo from '../../global/databaseLogic/GetCompanyLogo';

interface LoginDetails {
    username: string;
    password: string;
    usernameError: boolean;
    passwordError: boolean;
    usernameErrorMessage: string;
    passwordErrorMessage: string;
}

const Login = ({ setToken }) => {
    const [companyLogo, setCompanyLogo] = useState();
    const [passwordChange, setPasswordChange] = useState<boolean>(false);
    const [loginDetails, setLoginDetails] = useState<LoginDetails>({
        username: '',
        password: '',
        usernameError: false,
        passwordError: false,
        usernameErrorMessage: '',
        passwordErrorMessage: ''
    });

    useEffect(() => {
        setToken(null);
        // If there is no Site ID, two possibility
        // This is a fresh Ora instance and onboarding hasn't happened yet
        // In which case Main.js have a function to redirect to /onboarding
        // This is a new user
        // In which case we default ot the logo of Site #1
        GetCompanyLogo(
            parseInt(localStorage.getItem('SiteId') ?? '1'),
            setCompanyLogo
        );
        // eslint-disable-next-line
    }, []);

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                item
                xs={false}
                sm={false}
                md={7}
                sx={{
                    backgroundImage: `url(https://source.unsplash.com/random/?motorcycles})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light'
                            ? t.palette.grey[50]
                            : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            />
            <Grid
                item
                xs={12}
                sm={12}
                md={5}
                component={Paper}
                elevation={6}
                square
            >
                <Box
                    sx={{
                        my: 15,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            mx: 20,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '50%'
                        }}
                    >
                        <img alt="Ora DMS" src={companyLogo} width="100%" />
                    </Box>
                    <Box
                        component="form"
                        noValidate
                        sx={{ mt: 1, width: '75%' }}
                    >
                        {passwordChange ? (
                            <ChangePasswordContent
                                loginDetails={loginDetails}
                                oldPassword={loginDetails.password}
                            />
                        ) : (
                            <LoginContent
                                loginDetails={loginDetails}
                                setLoginDetails={setLoginDetails}
                                setPasswordChange={setPasswordChange}
                                setToken={setToken}
                            />
                        )}
                    </Box>

                    <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                        sx={{ position: 'absolute', bottom: '1%' }}
                    >
                        Copyright © OraDMS {new Date().getFullYear()}.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Login;
