// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
  MenuItem,
  Grid,
  TextField,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Button } from '@mui/material';
import { InfoOutlined } from '@material-ui/icons';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

// LOGIC
import GenerateStockOrder from '../../logic/GenerateStockOrder';
import { Table } from '../../interfaces/StockOrderInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import CreditorSelector from '../../../../global/CreditorSelector';

interface GenerateOrderConditionsProps {
  generateOrderErrors: {
    noCovPeriod: boolean;
    noSalesStart: boolean;
    noSalesEnd: boolean;
  };
  setGenerateOrderErrors: Dispatch<
    SetStateAction<{
      noCovPeriod: boolean;
      noSalesStart: boolean;
      noSalesEnd: boolean;
    }>
  >;
  coveragePeriod: number;
  setCoveragePeriod: Dispatch<SetStateAction<number>>;
  coveragePeriodType: string;
  setCoveragePeriodType: Dispatch<SetStateAction<string>>;
  orderPeriod: { startDate: Date; endDate: Date };
  setOrderPeriod: Dispatch<SetStateAction<{ startDate: Date; endDate: Date }>>;
  setOrderOpen: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setClicked: Dispatch<SetStateAction<boolean>>;
  tables: Table[];
  setTables: Dispatch<SetStateAction<Table[]>>;
  selectedSite: number;
}

const GenerateOrderConditions = (props: GenerateOrderConditionsProps) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectedSupplier, setSelectedSupplier] = useState<number>(null);
  const [orderMinStock, setOrderMinStock] = useState<boolean>(false);

  return (
    <>
      <DialogTitle>Suggested Stock Order Parameters</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the following parameters to generate a suggested stock
          order.
        </DialogContentText>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              error={props.generateOrderErrors.noCovPeriod ? true : false}
              helperText={
                props.generateOrderErrors.noCovPeriod
                  ? 'Please enter a valid coverage period.'
                  : null
              }
              label="Coverage Period"
              value={props.coveragePeriod}
              onChange={(e) =>
                props.setCoveragePeriod(parseInt(e.target.value))
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              label="Coverage Period Type"
              value={props.coveragePeriodType}
              onChange={(e) => props.setCoveragePeriodType(e.target.value)}
              InputProps={{
                endAdornment: (
                  <Tooltip
                    style={{ paddingRight: '10%' }}
                    title="How long you would like the order to cover."
                  >
                    <InfoOutlined />
                  </Tooltip>
                )
              }}
            >
              <MenuItem value={'day'}>Days</MenuItem>
              <MenuItem value={'week'}>Weeks</MenuItem>
              <MenuItem value={'month'}>Months</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <CreditorSelector
              size="medium"
              fullWidth
              selectedSupplier={selectedSupplier}
              setSelectedSupplier={setSelectedSupplier}
            />
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="Sales History Start Date"
                    value={props.orderPeriod.startDate}
                    onChange={(newValue) =>
                      props.setOrderPeriod({
                        ...props.orderPeriod,
                        startDate: newValue
                      })
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: false,
                        size: 'small',
                        InputLabelProps: { shrink: true }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="Sales History End Date"
                    value={props.orderPeriod.endDate}
                    onChange={(newValue) =>
                      props.setOrderPeriod({
                        ...props.orderPeriod,
                        endDate: newValue
                      })
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: false,
                        size: 'small',
                        InputLabelProps: { shrink: true }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={orderMinStock}
                      onChange={(e) => setOrderMinStock(e.target.checked)}
                    />
                  }
                  label="Order Stock Below Minimum"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => props.setOrderOpen(false)}>
          Close
        </Button>
        <LoadingButton
          loading={buttonLoading}
          color="primary"
          variant="contained"
          onClick={() => {
            GenerateStockOrder(
              props.tables,
              props.setTables,
              props.setLoading,
              props.coveragePeriod,
              props.coveragePeriodType,
              props.orderPeriod,
              props.generateOrderErrors,
              props.setGenerateOrderErrors,
              props.setOrderOpen,
              props.setClicked,
              selectedSupplier,
              orderMinStock,
              props.selectedSite,
              setButtonLoading
            );
          }}
        >
          Generate Order
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default GenerateOrderConditions;
