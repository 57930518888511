// LOGIC
import { PaymentTotal } from '../CalculateTotals';
import api from '../../../../../../api';
// INTERFACES
import { PaymentLine } from '../../../../global/interfaces/GeneralInterface';
import { Order } from '../../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const UpdateServiceSale = (
    id: number,
    selectedEntity: any,
    saleDetails: Order,
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[],
    paymentLines: PaymentLine[],
    handlePrintInvoice: (
        hasCustomer: boolean,
        saleData: Order,
        redirectId?: number | boolean,
        vouchers?: any[]
    ) => void,
    showSnackbar: showSnackbar
) => {
    let obj = {
        saleDetails: saleDetails,
        extraCosts: extraCosts,
        paymentLines: paymentLines,
        selectedEntity: selectedEntity,
        paymentTotal: PaymentTotal(paymentLines)
    };

    api.put(`updateServiceSale/${id}?SiteId=${saleDetails.SiteId}`, obj).then(
        (res) => {
            if (res.status === 200) {
                showSnackbar('Sale Updated!', 'The page will now refresh.');
                handlePrintInvoice(true, res.data.saleData);
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        }
    );
};

export default UpdateServiceSale;
