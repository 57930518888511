// IMPORTS
import { useState } from 'react';
import { Typography, TableRow, Chip, IconButton } from '@mui/material';
import { Add, Print } from '@mui/icons-material';
// COMPONENTS
import StockTransferTableFilter from './components/StockTransferTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import Drawer from '../../global/Drawer';
import StockTransferDrawerContent from './components/StockTransferDrawerContent';
import { withSnackbar } from '../../global/WrappingSnackbar';

// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import GenerateTransferSlip from './logic/GenerateTransferSlip';

// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { StockTransfer } from '../../global/interfaces/PartsInterface';
import { DateFormatter } from '../../global/logic/Formatters';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    stockTransferFilter: {
        status: [],
        siteFrom: [],
        siteTo: [],
        requestedUser: [],
        fulfilledUser: [],
        completedUser: [],
        hasOrders: null
    }
};
const clearedSort = ['id', 'DESC'];

const StockTransferTable = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [filter, setFilter] = useState<Filter>({
        stockTransferFilter: {
            status: [
                { label: 'INCOMPLETE', value: 'INCOMPLETE' },
                { label: 'PENDING', value: 'PENDING' }
            ],
            siteFrom: [],
            siteTo: [],
            requestedUser: [],
            fulfilledUser: [],
            completedUser: [],
            hasOrders: null
        }
    });
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<StockTransfer[]>([]);
    const [openTransferDrawer, setOpenTransferDrawer] = useState(false);
    const [selectedTransfer, setSelectedTransfer] = useState<StockTransfer>();

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'New Stock Transfer',
            onClick: () => {
                setOpenTransferDrawer(true);
                setSelectedTransfer(null);
            }
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'From' },
        { id: 2, label: 'To' },
        { id: 4, label: 'Received By' },
        { id: 5, label: 'Sent On' },
        { id: 6, label: 'Received On' },
        { id: 7, label: 'Order ID' },
        { id: 8, label: 'Status' },
        { id: 9, label: '' }
    ];

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let status =
            filter.stockTransferFilter.status.length > 0
                ? ConvertArrayToFilter({
                      array: filter.stockTransferFilter.status,
                      filterStart: '&status=',
                      selector: 'value'
                  })
                : '';
        let siteFrom =
            filter.stockTransferFilter.siteFrom.length > 0
                ? ConvertArrayToFilter({
                      array: filter.stockTransferFilter.siteFrom,
                      filterStart: '&siteFrom=',
                      selector: 'id'
                  })
                : '';
        let siteTo =
            filter.stockTransferFilter.siteTo.length > 0
                ? ConvertArrayToFilter({
                      array: filter.stockTransferFilter.siteTo,
                      filterStart: '&siteTo=',
                      selector: 'id'
                  })
                : '';
        let requestedUser =
            filter.stockTransferFilter.requestedUser.length > 0
                ? ConvertArrayToFilter({
                      array: filter.stockTransferFilter.requestedUser,
                      filterStart: '&requestedUser=',
                      selector: 'id'
                  })
                : '';
        let completedUser =
            filter.stockTransferFilter.completedUser.length > 0
                ? ConvertArrayToFilter({
                      array: filter.stockTransferFilter.completedUser,
                      filterStart: '&completedUser=',
                      selector: 'id'
                  })
                : '';
        let hasOrders = filter.stockTransferFilter.hasOrders
            ? '&hasOrders=true'
            : '';

        let apiString = `${status}${siteFrom}${siteTo}${requestedUser}${completedUser}${hasOrders}`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (filter.stockTransferFilter.status.length > 0) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Stock Transfer</Typography>
            <br />
            <TableSearch
                searchTitle="Search Stock Transfer"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="stockTransferSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <StockTransferTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() => {
                                    setOpenTransferDrawer(true);
                                    setSelectedTransfer(row);
                                }}
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>{row.SiteFrom.name}</DataCell>
                            <DataCell>{row.SiteTo.name}</DataCell>
                            <DataCell>
                                {row?.CompletedUser?.firstName}{' '}
                                {row?.CompletedUser?.lastName}
                            </DataCell>
                            <DataCell>{DateFormatter(row.createdAt)}</DataCell>
                            <DataCell>
                                {row.completedAt
                                    ? DateFormatter(row.completedAt)
                                    : ''}
                            </DataCell>
                            <DataCell>{row.OrderId}</DataCell>
                            <DataCell>
                                <Chip
                                    variant="outlined"
                                    label={row.status}
                                    color={
                                        row.status === 'COMPLETE'
                                            ? 'success'
                                            : 'warning'
                                    }
                                />
                            </DataCell>
                            <DataCell>
                                <IconButton
                                    onClick={() => GenerateTransferSlip(row)}
                                >
                                    <Print />
                                </IconButton>
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={openTransferDrawer}
                setOpenDrawer={setOpenTransferDrawer}
                title={'Stock Transfer'}
                subTitle={
                    selectedTransfer === null
                        ? 'New Stock Transfer'
                        : `Transfer #${selectedTransfer?.id} - ${selectedTransfer?.status}`
                }
                width="40vw"
            >
                <StockTransferDrawerContent
                    selectedTransfer={selectedTransfer}
                    setSelectedTransfer={setSelectedTransfer}
                    showSnackbar={showSnackbar}
                />
            </Drawer>
        </PageWrapper>
    );
};

export default withSnackbar(StockTransferTable);
