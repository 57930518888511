import { Step } from '../../../interface';

export const BalanceSheetSteps: Step[] = [
    {
        label: 'In the sidebar, navigate to Admin -> Reporting to open the Ora Generated Reports page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-01.png'
    },
    {
        label: 'Under the Balance Sheet tab, select the balance sheet date.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/balanceSheet-02.png'
    },
    {
        label: 'Click the "Generate Report" button to generate the balance sheet report.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/balanceSheet-03.png'
    },
    {
        label: 'The balance sheet will show assets and liabilities for all sites. Select which site you want to view by select a site from the dropdown list.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/balanceSheet-04.png'
    },
    {
        label: 'Click on hyperlinked data to view associated details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/balanceSheet-05.png'
    },
    {
        label: 'Click download to download the report in CSV format.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/balanceSheet-06.png'
    }
];
