import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs, { Dayjs } from "dayjs";
import api from "../../../../../api";
import { Dispatch, SetStateAction } from "react";
import { Customer, User } from "../../../global/interfaces/GeneralInterface";
import { Make, Model } from "../../../global/interfaces/VehicleSaleInterface";
dayjs.extend(customParseFormat);

const GetLead = (
    id: number,
    setSelectedEnquiryId: Dispatch<SetStateAction<{id: number, user: User | string, status: string}>>,
    setCustomerDetails: Dispatch<SetStateAction<Customer | { name: string; suburb: string; occupation: number; phoneNumber: number; email: number }>>,
    setBikeCondition: Dispatch<SetStateAction<string>>,
    setSelectedMake: Dispatch<SetStateAction<Make>>,
    setSelectedModel: Dispatch<SetStateAction<Model>>,
    setWeeklyBudget: Dispatch<SetStateAction<number>>,
    setEnquirySource: Dispatch<SetStateAction<string>>,
    setPickupDate: Dispatch<SetStateAction<Dayjs>>,
    setReason: Dispatch<SetStateAction<string>>,
    setFinanceDetails: Dispatch<SetStateAction<{weeklyRepayment: number,
    amountOwing: number,
    remainingTerms: number}>>,
    setTradeInMake: Dispatch<SetStateAction<Make>>,
    setTradeInModel: Dispatch<SetStateAction<Model>>,
    setTradeInDetails: Dispatch<SetStateAction<{
        year: Dayjs
        registration: string,
        registrationExpiration: Dayjs
        colour: string,
        odometer: number,
        hours: number,
        vin: string,
        engineNumber: string,
    }>>,
    setNotes: Dispatch<SetStateAction<string>>,
    setDisabled: Dispatch<SetStateAction<boolean>>
) => {
    api.get(`leadNextGen/${id}`).then((res) => {
        let lead = res.data;

        setSelectedEnquiryId({
            id: id,
            user: lead.assignedUser,
            status: lead.status
        })
        // Set the customer's details
        if (lead.CustomerId) {
            setCustomerDetails(lead.Customer);
        } else {
            setCustomerDetails({
                name: lead.name,
                suburb: lead.suburb,
                occupation: lead.occupation,
                phoneNumber: lead.phoneNumber,
                email: lead.email,
            });
        }

        setBikeCondition(lead.bikeCondition);
        setSelectedMake(lead.Make);
        setSelectedModel(lead.Model);
        setWeeklyBudget(lead.weeklyBudget);
        setEnquirySource(lead.enquirySource);
        setPickupDate(lead.idealPickupDate ? dayjs(lead.idealPickupDate).add(1, "day") : null); // Need to add a day because of timezone shenanigans
        setReason(lead.reason);

        // Set the finance details
        setFinanceDetails({
            weeklyRepayment: lead.currentWeeklyRepayment,
            amountOwing: lead.amountOwing,
            remainingTerms: lead.remainingTerms,
        });

        // Set the trade in details
        setTradeInMake(lead.tradeInMake);
        setTradeInModel(lead.tradeInModel);
        setTradeInDetails({
            year: lead?.tradeInYear ? dayjs(lead.tradeInYear.toString(), "YYYY") : null,
            registration: lead.tradeInRegistration,
            registrationExpiration: lead?.tradeInRegistrationExpiration ? dayjs(lead.tradeInRegistrationExpiration).add(1, "days") : null, // Need to add a day because of timezone shenanigans
            colour: lead.tradeInColour,
            odometer: lead.tradeInOdometer,
            hours: lead.tradeInHours,
            vin: lead.tradeInVin,
            engineNumber: lead.tradeInEngineNumber,
        });

        // Set the notes
        setNotes(lead.notes);

        setDisabled(true);
    });
};

export default GetLead;
