import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { Add, Delete, Edit, UploadFile } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import ModelDrawerContent from './ModelComponent/ModelDrawerContent';
import AddModelDialogContent from './ModelComponent/AddModelDialogContent';
import TableSearch from '../../../global/tableComponents/TableSearch';
import SpeedDial from '../../../global/SpeedDial';
import Drawer from '../../../global/Drawer';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Paper from '../../../global/Paper';
import { ImportModelDialogContent } from './ModelComponent/ImportModelDialogContent';
import { withSnackbar } from '../../../global/WrappingSnackbar';
// LOGIC
import HandleModelIdClick from '../logic/HandleModelIdClick';
import EditModelDialogContent from './ModelComponent/EditModelDialogContent';
import DeleteModel from '../logic/DeleteModel';
// INTERFACES
import {
    Make,
    Model,
    Vehicle
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface TempModel extends Model {
    Make: Make;
    nbOfVehicle: number;
}

const clearedSort = ['$Make.name$', 'ASC'];

const ModelTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [sort, setSort] = useState(clearedSort);
    const [modelList, setModelList] = useState<TempModel[]>([]);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedModel, setSelectedModel] = useState<TempModel>();
    const [listOfVehicles, setListOfVehicles] = useState<Vehicle[]>([]);
    const [filterListOfVehicles, setFilterListOfVehicles] = useState<Vehicle[]>(
        []
    );
    const [filterValue, setFilterValue] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [addModelDialog, setAddModelDialog] = useState<boolean>(false);
    const [editModelDialog, setEditModelDialog] = useState<boolean>(false);
    const [deleteModelDialog, setDeleteModelDialog] = useState<boolean>(false);

    // Import Models Dialog State
    const [importModelsDialog, setImportModelsDialog] =
        useState<boolean>(false);

    const columns = [
        { id: 1, label: 'Make', width: 250 },
        { id: 2, label: 'Model', width: 250 },
        { id: 3, label: 'Vehicles Available', width: 150 },
        { id: 4, label: '', width: 10 },
        { id: 5, label: '', width: 10 }
    ];

    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Model',
            onClick: () => setAddModelDialog(true)
        },
        {
            icon: <UploadFile />,
            name: 'Import Models',
            onClick: () => setImportModelsDialog(true)
        }
    ];

    const handleRequestCreate = () => {
        let apiString = ``;
        return apiString;
    };

    const handleFilterValueChange = (value: string) => {
        setFilterValue(value.toUpperCase());
        setFilterListOfVehicles(
            listOfVehicles.filter((x) =>
                x.stockNumber.includes(value.toUpperCase())
            )
        );
        setPage(1);
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Model"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="modelSpecificTable"
                setResultsList={setModelList}
            />

            <Paper>
                <DataTable columns={columns} sort={sort} setSort={setSort}>
                    {modelList.map((model) => (
                        <TableRow>
                            <DataCell>{model?.Make?.name}</DataCell>
                            <DataCell>{model.name}</DataCell>
                            <DataCellColoured
                                handleClick={() => {
                                    HandleModelIdClick(
                                        model,
                                        setSelectedModel,
                                        setOpenDialog,
                                        setListOfVehicles,
                                        setFilterListOfVehicles,
                                        setFilterValue
                                    );
                                }}
                            >
                                {model.nbOfVehicle}
                            </DataCellColoured>
                            <DataCell align="right">
                                <Tooltip
                                    title="Edit Model Name"
                                    placement="top"
                                >
                                    <IconButton
                                        onClick={() => {
                                            setEditModelDialog(true);
                                            setSelectedModel(model);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </DataCell>
                            <DataCell align="left">
                                {model.nbOfVehicle === 0 ? (
                                    <Tooltip
                                        title="Delete Model"
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setDeleteModelDialog(true);
                                                setSelectedModel(model);
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={openDialog}
                setOpenDrawer={setOpenDialog}
                title={selectedModel?.name + ' Vehicles'}
                subTitle=""
                width="40vw"
            >
                <ModelDrawerContent
                    filterValue={filterValue}
                    filterListOfVehicles={filterListOfVehicles}
                    page={page}
                    setPage={setPage}
                    handleFilterValueChange={handleFilterValueChange}
                />
            </Drawer>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={addModelDialog}
                onClose={() => {
                    setAddModelDialog(false);
                }}
            >
                <DialogTitle>Add New Model</DialogTitle>
                <AddModelDialogContent showSnackbar={showSnackbar} />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={editModelDialog}
                onClose={() => {
                    setEditModelDialog(false);
                }}
            >
                <DialogTitle>Edit Model Name</DialogTitle>
                <EditModelDialogContent
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                    showSnackbar={showSnackbar}
                />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={deleteModelDialog}
                onClose={() => {
                    setDeleteModelDialog(false);
                }}
            >
                <DialogTitle>Delete {selectedModel?.name}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to delete the model{' '}
                        {selectedModel?.name}? This will also remove any series
                        assigned to it.
                        <br />
                        <b>This cannot be undone.</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleteModelDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            DeleteModel(
                                selectedModel,
                                showSnackbar,
                                setButtonLoading
                            )
                        }
                    >
                        Delete Model
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={importModelsDialog}
                onClose={() => setImportModelsDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Import Models from CSV</DialogTitle>
                <ImportModelDialogContent
                    showSnackbar={showSnackbar}
                    setImportModelsDialog={setImportModelsDialog}
                />
            </Dialog>

            <SpeedDial actionsList={actionsList} />
        </>
    );
};
export default withSnackbar(ModelTable);
