import { Dispatch, SetStateAction } from 'react';
import { Grid, TextField, Typography, InputAdornment } from '@mui/material';

interface FinanceDetailsProps {
  financeDetails: {
    weeklyRepayment: number;
    amountOwing: number;
    remainingTerms: number;
  };
  setFinanceDetails: Dispatch<
    SetStateAction<{
      weeklyRepayment: number;
      amountOwing: number;
      remainingTerms: number;
    }>
  >;
  disabled: boolean;
}

const FinanceDetails = (props: FinanceDetailsProps) => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Finance Details
      </Typography>
      <Grid container spacing={2} textAlign="center">
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Current Weekly Repayments"
            disabled={props.disabled}
            type="number"
            value={props.financeDetails.weeklyRepayment}
            onChange={(event) =>
              props.setFinanceDetails({
                ...props.financeDetails,
                weeklyRepayment: parseFloat(event.target.value)
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Amount Owing"
            disabled={props.disabled}
            type="number"
            value={props.financeDetails.amountOwing}
            onChange={(event) =>
              props.setFinanceDetails({
                ...props.financeDetails,
                amountOwing: parseFloat(event.target.value)
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Remaining Term (years)"
            disabled={props.disabled}
            type="number"
            value={props.financeDetails.remainingTerms || ''}
            onChange={(event) =>
              props.setFinanceDetails({
                ...props.financeDetails,
                remainingTerms: parseFloat(event.target.value)
              })
            }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FinanceDetails;
