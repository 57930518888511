import { Step } from '../../interface';

export const ManageBookingsSteps: Step[] = [
    {
        label: "Click 'Service' > 'Bookings' in the sidebar to navigate to the bookings page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/bookings-01.png'
    },
    {
        label: 'To search for a booking, enter the booking ID, customer name, or vehicle registration in the search bar and then press Enter.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/bookings-02.png'
    },
    {
        label: 'To filter bookings, click the "Filter" button. A filter drawer will open, allowing you to filter by date, status, job type, site, and technician. You can combine multiple filters to narrow down your search.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/bookings-03.png'
    },
    {
        label: 'To view service details, click on the booking ID in the list. This will open the booking details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/bookings-04.png'
    },
    {
        label: (
            <>
                To make a payment for a service, click the "Open Payments"
                button. This will navigate to the payments page for that
                service. Refer to{' '}
                <a
                    href="/support/service/payment"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Service Payment
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/bookings-05.png'
    },
    {
        label: 'To export all open service bookings, click the "Open RO Report" button in the speed dial menu. A CSV file will be downloaded.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/bookings-06.png'
    },
    {
        label: 'To manage service codes, click "Service Codes" in the speed dial menu. This will navigate to the service codes page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/bookings-07.png'
    }
];

export const EditRetailBookingSteps: Step[] = [
    {
        label: 'To edit a booking, first navigate to the bookings page by clicking "Service" -> "Bookings". Then, click on the desired booking ID in the list to access the booking details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-01.png'
    },
    {
        label: 'Click the "Edit" button to modify the booking details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-02.png'
    },
    {
        label: 'You can update the service type (Retail, Internal, Insurance, Warranty, Rework or Quote) in the Select Customer Tab. The Vehicle can be changed in the Select Vehicle Tab, the Technician assigned to the job can be changed in the Select Tech tab. The Details tab let you update the booking status. Note: You can only update the status to "Finalised" if the booking has been fully paid.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-03.png'
    },
    {
        label: 'In the Charges tab, you can add parts and fluid using the two fields at the top of the page. This tab also display any parts sold to this booking through the Parts & Accessories department. You can also add sublets to the booking. In the Sublets table, click the "+" button to open the New Purchase Order drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-04.png'
    },
    {
        label: 'Fill in the purchase order details and click "Save" to save the purchase order. A new line will be added in the Sublets table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-05.png'
    },
    {
        label: 'Click the Save button in the speed dial menu to save your changes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-06.png'
    },
    {
        label: 'You can print the Job Card by clicking the "Job Card" button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-07.png'
    },
    {
        label: 'Once a Service is Complete, you can print a Customer Invoice by clicking the "Customer Invoice" button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-08.png'
    },
    {
        label: 'You can view the change history of the booking by clicking "Logs" in the speed dial menu.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-09.png'
    },
    {
        label: 'In the Change History drawer, you will see every update made to the booking details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-10.png'
    },
    {
        label: 'You can send a text to the customer by clicking "Text Customer" in the speed dial menu. This will open a New Text Message drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-11.png'
    },
    {
        label: "The customer's name and phone number will be auto-populated. Select a template from the dropdown list, and the message will be populated automatically. You can also type the message yourself.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-12.png'
    },
    {
        label: 'Remember to replace [VEHICLE] in the message body. After reviewing, click "Send Text" to send the text message.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-13.png'
    },
    {
        label: 'To delete a booking, click "Delete" in the speed dial menu. A confirmation dialog will open.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-14.png'
    },
    {
        label: 'Click "Delete Booking" to confirm the deletion of the booking. Be aware that this action is irreversible.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/bookings/edit-retail-15.png'
    }
];
