import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Table,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import Paper from '../../../../../global/Paper';
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import Parts from './Parts';
import Vehicles from './Vehicles';
import Service from './Service';
import { Check, Clear, ExpandMore } from '@mui/icons-material';

import { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';

interface ReportsProps {
    queryDate: { startDate: Dayjs; endDate: Dayjs };
    partValues: {
        onlineCost: number;
        onlineRevenue: number;
        onlineTotal: number;
        partCost: number;
        partRevenue: number;
        partTotal: number;
        saleCost: number;
        saleRevenue: number;
        saleTotal: number;
        serviceCost: number;
        serviceRevenue: number;
        serviceTotal: number;
        vehicleCost: number;
        vehicleRevenue: number;
        vehicleTotal: number;
    };
    partData: {
        OnlineOrderId: number;
        OrderId: number;
        ServiceId: number;
        VehicleId: number;
        VehicleSaleId: number;
        customerName: string;
        numberParts: number;
        orderType: string;
        totalCost: number;
        totalSale: number;
    }[];
    wrongPartData: {
        OrderId: number;
        costAverage: number;
        costDaily: number;
        message: string;
        orderType: string;
        submessage: string;
        partNumber: string;
    }[];
    vehicleData: {
        VehicleSaleId: number;
        adminLoadCost: number;
        customerName: string;
        deliveryPrice: number;
        extraCost: number;
        rebatePrice: number;
        serviceCosts: number;
        stockNumber: string;
        totalCost: number;
        totalSale: number;
        vehicleCondition: string;
        vehicleCostPrice: number;
        vehicleSalePrice: number;
        warrantyCost: number;
    }[];
    vehicleValues: {
        adminLoadCost: number;
        adminLoadCounter: number;
        adminLoadFromUsed: number;
        adminLoadFromNew: number;
        dofCounter: number;
        dofPrice: number;
        warrantyRevenueFromUsed: number;
        DOFFromUsed: number;
        warrantyRevenueFromNew: number;
        DOFFromNew: number;
        newCost: number;
        newRevenue: number;
        newTotal: number;
        usedCost: number;
        usedRevenue: number;
        usedTotal: number;
        warrantyCost: number;
        warrantyCostFromUsed: number;
        warrantyCostFromNew: number;
        warrantyCounter: number;
        warrantyPrice: number;
        adminLoadCounterUsed: number;
        adminLoadCounterNew: number;
        warrantyCounterUsed: number;
        warrantyCounterNew: number;
        dofCounterUsed: number;
        dofCounterNew: number;
    };
    wrongVehicleData: {
        VehicleSaleId: number;
        customerName: string;
        message: string;
        stockNumber: string;
        vehicleCondition: string;
    }[];
    serviceData: {
        ServiceId: number;
        customerName: string;
        serviceType: string;
        totalCost: number;
        totalSale: number;
        vehicleStockNumber: string;
    }[];
    wrongServiceData: {
        ServiceId: number;
        costAverage: number;
        costDaily: number;
        message: string;
        orderType: string;
        serviceType: string;
        submessage: string;
    }[];
    serviceValues: {
        insuranceCost: number;
        insuranceRevenue: number;
        insuranceTotal: number;
        internalCost: number;
        internalRevenue: number;
        internalTotal: number;
        retailCost: number;
        retailRevenue: number;
        retailTotal: number;
        warrantyCost: number;
        warrantyRevenue: number;
        warrantyTotal: number;
    };
    rebates: {
        [key: string]: {
            [key: string]: {
                accountId: number;
                accountName: string;
                amount: number;
                date: string;
                description: string;
                reconciled: boolean;
                url: string;
            }[];
        };
    };
    expenses: {
        [key: string]: {
            [key: string]: {
                accountId: number;
                accountName: string;
                amount: number;
                date: string;
                description: string;
                reconciled: boolean;
                url: string;
            }[];
        };
    };

    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    setSelectedAccount: Dispatch<
        SetStateAction<
            {
                accountId: number;
                accountName: string;
                amount: number;
                date: string;
                description: string;
                url: string;
            }[]
        >
    >;
    setSelectedAccountName: Dispatch<SetStateAction<string>>;
}
const Reports = (props: ReportsProps) => {
    const getTotal = (type) => {
        let total = 0;

        let cost =
            props.vehicleValues.usedCost +
            props.vehicleValues.newCost +
            props.partValues.partCost +
            props.partValues.vehicleCost +
            props.partValues.serviceCost +
            props.partValues.saleCost +
            props.partValues.onlineCost +
            props.serviceValues.retailCost +
            props.serviceValues.internalCost +
            props.serviceValues.warrantyCost +
            props.serviceValues.insuranceCost;

        let revenue =
            props.vehicleValues.usedRevenue +
            props.vehicleValues.newRevenue +
            props.vehicleValues.dofPrice +
            props.vehicleValues.warrantyPrice +
            props.vehicleValues.adminLoadCost +
            props.partValues.partRevenue +
            props.partValues.vehicleRevenue +
            props.partValues.serviceRevenue +
            props.partValues.saleRevenue +
            props.partValues.onlineRevenue +
            props.serviceValues.retailRevenue +
            props.serviceValues.internalRevenue +
            props.serviceValues.warrantyRevenue +
            props.serviceValues.insuranceRevenue;

        let grossProfit = revenue - cost;
        let netProfit =
            revenue +
            getExpenseAndRebateTotal(props.rebates) -
            (cost + getExpenseAndRebateTotal(props.expenses));

        if (type === 'cost') {
            total = cost;
        } else if (type === 'revenue') {
            total = revenue;
        } else if (type === 'gross') {
            total = grossProfit;
        } else if (type === 'net') {
            total = netProfit;
        }

        return total;
    };

    const getExpenseAndRebateTotal = (array) => {
        let total = 0;
        let listOfKeys = Object.keys(array);
        for (let key of listOfKeys) {
            total += getArrayTotal(array[key]);
        }
        return total;
    };

    const getArrayTotal = (array) => {
        let total = 0;
        let listOfKeys = Object.keys(array);
        for (let key of listOfKeys) {
            for (let line of array[key]) {
                total += parseFloat(line.amount);
            }
        }

        return total;
    };

    const totalArray = (array) => {
        let total = 0;
        for (let transaction of array) {
            total += transaction.amount;
        }
        return total;
    };

    return (
        <>
            <Paper>
                <Table size="small">
                    <TableRow>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                <b>Total Cost</b>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                <b>Total Revenue</b>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                <b>Gross Profit</b>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                {CurrencyFormatter(getTotal('cost'))}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                {CurrencyFormatter(getTotal('revenue'))}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                {CurrencyFormatter(getTotal('gross'))}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </Paper>
            <br />
            <Paper>
                <Table size="small">
                    <TableRow>
                        <TableCell width="30%"></TableCell>
                        <TableCell width="10%">
                            <Typography variant="h6" align="center">
                                Total Sales
                            </Typography>
                        </TableCell>
                        <TableCell width="20%">
                            <Typography variant="h6" align="center">
                                Cost
                            </Typography>
                        </TableCell>
                        <TableCell width="20%">
                            <Typography variant="h6" align="center">
                                Revenue
                            </Typography>
                        </TableCell>
                        <TableCell width="20%">
                            <Typography variant="h6" align="center">
                                Gross Profits
                            </Typography>
                        </TableCell>
                        <TableCell width="5%"></TableCell>
                    </TableRow>

                    <Parts
                        partData={props.partData}
                        wrongPartData={props.wrongPartData}
                        partValues={props.partValues}
                        queryDate={props.queryDate}
                    />
                    <Vehicles
                        vehicleData={props.vehicleData}
                        vehicleValues={props.vehicleValues}
                        wrongVehicleData={props.wrongVehicleData}
                        queryDate={props.queryDate}
                    />
                    <Service
                        serviceData={props.serviceData}
                        wrongServiceData={props.wrongServiceData}
                        serviceValues={props.serviceValues}
                        queryDate={props.queryDate}
                    />
                </Table>
            </Paper>

            <br />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Accordion>
                        <AccordionSummary expandIcon={null}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                        <b>Other Expenses</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant="subtitle1">
                                        {CurrencyFormatter(
                                            getExpenseAndRebateTotal(
                                                props.expenses
                                            )
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <ExpandMore />
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table size="small">
                                {Object.keys(props.expenses).map((category) => (
                                    <>
                                        <Accordion>
                                            <AccordionSummary expandIcon={null}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="subtitle1">
                                                            <b>
                                                                {category ===
                                                                'undefined'
                                                                    ? 'No Category'
                                                                    : category}
                                                            </b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="subtitle1">
                                                            {CurrencyFormatter(
                                                                getArrayTotal(
                                                                    props
                                                                        .expenses[
                                                                        category
                                                                    ]
                                                                )
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <ExpandMore />
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Table size="small">
                                                    <TableRow>
                                                        <TableCell>
                                                            <strong>
                                                                Entity
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>
                                                                Amount
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>
                                                                Reconciled
                                                            </strong>
                                                        </TableCell>
                                                    </TableRow>
                                                    {Object.keys(
                                                        props.expenses[category]
                                                    ).map((account) => (
                                                        <TableRow>
                                                            <TableCell
                                                                id="table-link"
                                                                onClick={() => {
                                                                    props.setDrawerOpen(
                                                                        true
                                                                    );
                                                                    props.setSelectedAccount(
                                                                        props
                                                                            .expenses[
                                                                            category
                                                                        ][
                                                                            account
                                                                        ]
                                                                    );
                                                                    props.setSelectedAccountName(
                                                                        account
                                                                    );
                                                                }}
                                                            >
                                                                <Typography variant="body2">
                                                                    {account}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="body2">
                                                                    {CurrencyFormatter(
                                                                        totalArray(
                                                                            props
                                                                                .expenses[
                                                                                category
                                                                            ][
                                                                                account
                                                                            ]
                                                                        )
                                                                    )}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                {props.expenses[
                                                                    category
                                                                ][account].some(
                                                                    (x) =>
                                                                        !x.reconciled
                                                                ) ? (
                                                                    <Clear
                                                                        style={{
                                                                            color: 'red'
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Check
                                                                        style={{
                                                                            color: 'green'
                                                                        }}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                ))}
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={6}>
                    <Accordion>
                        <AccordionSummary expandIcon={null}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                        <b>Other Rebates</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant="subtitle1">
                                        {CurrencyFormatter(
                                            getExpenseAndRebateTotal(
                                                props.rebates
                                            )
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <ExpandMore />
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table size="small">
                                {Object.keys(props.rebates).map((category) => (
                                    <>
                                        <Accordion>
                                            <AccordionSummary expandIcon={null}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="subtitle1">
                                                            <b>
                                                                {category ===
                                                                'undefined'
                                                                    ? 'No Category'
                                                                    : category}
                                                            </b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="subtitle1">
                                                            {CurrencyFormatter(
                                                                getArrayTotal(
                                                                    props
                                                                        .rebates[
                                                                        category
                                                                    ]
                                                                )
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <ExpandMore />
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Table size="small">
                                                    <TableRow>
                                                        <TableCell>
                                                            <strong>
                                                                Entity
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>
                                                                Amount
                                                            </strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <strong>
                                                                Reconciled
                                                            </strong>
                                                        </TableCell>
                                                    </TableRow>
                                                    {Object.keys(
                                                        props.rebates[category]
                                                    ).map((account) => (
                                                        <TableRow>
                                                            <TableCell
                                                                id="table-link"
                                                                onClick={() => {
                                                                    props.setDrawerOpen(
                                                                        true
                                                                    );
                                                                    props.setSelectedAccount(
                                                                        props
                                                                            .rebates[
                                                                            category
                                                                        ][
                                                                            account
                                                                        ]
                                                                    );
                                                                    props.setSelectedAccountName(
                                                                        account
                                                                    );
                                                                }}
                                                            >
                                                                <Typography variant="body2">
                                                                    {account}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="body2">
                                                                    {CurrencyFormatter(
                                                                        totalArray(
                                                                            props
                                                                                .rebates[
                                                                                category
                                                                            ][
                                                                                account
                                                                            ]
                                                                        )
                                                                    )}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                {props.rebates[
                                                                    category
                                                                ][account].some(
                                                                    (x) =>
                                                                        !x.reconciled
                                                                ) ? (
                                                                    <Clear
                                                                        style={{
                                                                            color: 'red'
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Check
                                                                        style={{
                                                                            color: 'green'
                                                                        }}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </Table>
                                            </AccordionDetails>
                                        </Accordion>
                                    </>
                                ))}
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <br />
            <Paper>
                <Table size="small">
                    <TableRow>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                <b>Net Profit</b>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                {CurrencyFormatter(getTotal('net'))}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </Paper>
            <br />
        </>
    );
};

export default Reports;
