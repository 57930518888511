import { StockOrder } from '../../../global/interfaces/PartsInterface';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import { TableRow } from '@mui/material';

interface OrderPreviewDrawerContentProps {
  order: StockOrder;
}

const OrderPreviewDrawerContent = ({
  order
}: OrderPreviewDrawerContentProps) => {
  const columns = [
    { id: 0, label: 'Part Number', width: 100 },
    { id: 1, label: 'Part Name', width: 200 },
    { id: 2, label: 'Cost On File', width: 100 },
    { id: 3, label: 'Quantity', width: 100 }
  ];
  return (
    <DataTable columns={columns}>
      {order.orderLines.map((line) => (
        <TableRow>
          <DataCellColoured
            handleClick={() =>
              window.open(`/inventory/view/${line.id}`, '_blank')
            }
          >
            {line.partNumber}
          </DataCellColoured>
          <DataCell>{line.name}</DataCell>
          <DataCell>{line.costPriceAverage}</DataCell>
          <DataCell>{line.quantity}</DataCell>
        </TableRow>
      ))}
    </DataTable>
  );
};

export default OrderPreviewDrawerContent;
