import { Grid, Typography, CircularProgress } from '@mui/material';

const FileLoadingPage = () => {
    return (
        <Grid container spacing={2} sx={{ margin: 10, textAlign: 'center' }}>
            <Grid item xs={12}>
                <CircularProgress size={100} sx={{ color: 'primary.main' }} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" align="center">
                    Loading document
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" align="center">
                    Please wait while the document is loading. This usually
                    takes just a few seconds. If nothing happens for a while,
                    try refreshing the page.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default FileLoadingPage;
