import io from "socket.io-client";

const location = window.location.href
let BASE_URL = ""

if (location.includes('localhost')) BASE_URL = "http://localhost:8080/"
if (location.includes('staging.oradms.com')) BASE_URL = "https://staging.oradms.com/"
if (location.includes('bmc.oradms.com')) BASE_URL = "https://bmc.oradms.com/"
if (location.includes('scm.oradms.com')) BASE_URL = "https://scm.oradms.com/"
if (location.includes('scsr.oradms.com')) BASE_URL = "https://scsr.oradms.com/"
if (location.includes('ft1.oradms.com')) BASE_URL = "https://ft1.oradms.com/api/"
if (BASE_URL === "") BASE_URL = `https://${location.replace('http://', '').replace('https://', '').split('/')[0]}/api`

// disable socket for customer sign pages
let socket;
if (!location.includes('/esign/')) {
    socket = io(BASE_URL);
} 

export default socket;


