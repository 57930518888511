// IMPORTS
import jsPDF from 'jspdf';
// LOGIC
import { CurrencyFormatter } from './Formatters';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

/**
 * Create a PDF with the labels.
 *
 * @param {array} labelData The labels to print. Each label correspond to an element which must have the following JSON structure:
 *          {partNumber: string, priceRRP: float, name: string, binLocation: string}
 * Facultative attributes:
 *          - backorderId: int. If the part is assigned to a backorder
 *          - onlineOrderId: string. If the part is assigned to an online order
 *          - serviceId: int. If the part is assigned to a service
 */

interface LabelData {
    onlineOrderId?: number;
    backorderId?: number;
    serviceId?: number;
    vehicleId?: number;
    stockNumber?: string;
    partNumber: string;
    priceRRP: number;
    name: string;
    binLocation: string;
    customerName?: string;
    phoneNumber?: string;
}

const PrintLabel = (labelData: LabelData[], showSnackbar?: showSnackbar) => {
    try {
        var doc = new jsPDF({ orientation: 'l', unit: 'mm', format: [76, 48] });
        doc.setFontSize(8);
        var newImg = document.createElement('img');
        let pageCount = 1;
        labelData.forEach((data) => {
            if (data.onlineOrderId) {
                newImg.src = `https://bmc.oradms.com/api/barcode/${data.partNumber.replace(/\//g, '%2F')}`;
                doc.addImage(newImg, 14, 2, 48, 14);
                doc.text(data.partNumber, 38, 18, { align: 'center' });
                doc.text(
                    data.name.length > 33
                        ? data.name.substring(0, 33) + '...'
                        : data.name,
                    38,
                    21.5,
                    { align: 'center' }
                );
                doc.text(data.customerName, 38, 25, { align: 'center' });
                doc.text(data.phoneNumber, 38, 28.5, { align: 'center' });
                doc.text('ONLINE ORDER ID: ' + data.onlineOrderId, 38, 32, {
                    align: 'center'
                });
                doc.text(CurrencyFormatter(data.priceRRP), 38, 35.5, {
                    align: 'center'
                });
                doc.addPage();
            } else if (data.backorderId && data.backorderId != null) {
                newImg.src = `https://bmc.oradms.com/api/barcode/${data.partNumber.replace(/\//g, '%2F')}`;
                doc.addImage(newImg, 14, 2, 48, 14);
                doc.text(data.partNumber, 38, 18, { align: 'center' });
                doc.text(
                    data.name.length > 33
                        ? data.name.substring(0, 33) + '...'
                        : data.name,
                    38,
                    21.5,
                    { align: 'center' }
                );
                if (data.customerName.length > 0) {
                    doc.text(data.customerName, 38, 25, { align: 'center' });
                } else if (data.stockNumber) {
                    doc.text('Backorder for Vehicle', 38, 25, {
                        align: 'center'
                    });
                } else if (data.serviceId) {
                    doc.text('Backorder for Service', 38, 25, {
                        align: 'center'
                    });
                }

                if (data.phoneNumber) {
                    doc.text(data.phoneNumber, 38, 28.5, { align: 'center' });
                }
                doc.text(
                    data.serviceId
                        ? 'SERVICE ID: ' + data.serviceId
                        : data.vehicleId
                          ? 'STOCK #: ' + data.stockNumber
                          : data.backorderId
                            ? 'ORDER ID: ' + data.backorderId
                            : data.binLocation
                              ? data.binLocation
                              : 'No BinLocation registered for this part',
                    38,
                    32,
                    { align: 'center' }
                );
                doc.text(CurrencyFormatter(data.priceRRP), 38, 35.5, {
                    align: 'center'
                });
                doc.addPage();
            } else {
                newImg.src = `https://bmc.oradms.com/api/barcode/${data.partNumber.replace(/\//g, '%2F')}`;
                doc.addImage(newImg, 14, 2, 48, 14);
                doc.text(data.partNumber, 38, 18, { align: 'center' });
                doc.text(
                    data.name.length > 33
                        ? data.name.substring(0, 33) + '...'
                        : data.name,
                    38,
                    21.5,
                    { align: 'center' }
                );
                doc.text(
                    data.serviceId
                        ? 'SERVICE ID: ' + data.serviceId
                        : data.onlineOrderId
                          ? 'ONLINE ORDER ID: ' + data.onlineOrderId
                          : data.backorderId
                            ? 'ORDER ID: ' + data.backorderId
                            : data.binLocation
                              ? data.binLocation
                              : 'No BinLocation registered for this part',
                    38,
                    25,
                    { align: 'center' }
                );
                doc.text(CurrencyFormatter(data.priceRRP), 38, 28.5, {
                    align: 'center'
                });
                doc.addPage();
            }
            pageCount += 1;
        });

        // Delete the blank page at the end
        doc.deletePage(pageCount);

        window.open(doc.output('bloburl'));
    } catch (error) {
        console.error(error);
        showSnackbar(
            'Something went wrong when generating the label PDF file.',
            '',
            'error'
        );
    }
};

export default PrintLabel;
