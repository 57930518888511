import { Chip } from '@mui/material';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import getVehicleStatus from '../../vehicle/logic/GetVehicleStatus';

export const renderVehicleStatus = (
    vehicle: Vehicle,
    vehicleMovement?: any
) => {
    const status = getVehicleStatus(vehicle, vehicleMovement);

    if (
        status === 'Sold' ||
        status === 'Available' ||
        status === 'Unavailable' ||
        status === 'Pending Finalise'
    ) {
        return (
            <Chip
                label={status}
                variant="outlined"
                color={
                    status === 'Sold'
                        ? 'error'
                        : status === 'Available'
                          ? 'success'
                          : 'default'
                }
            />
        );
    } else {
        return `Attached Sale ID: #${status}`;
    }
};
