// IMPORTS
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import api from '../../../../../api';
import {
    Discount,
    GovernmentCharges,
    PartTotals,
    TotalPaid,
    TradeTotals
} from './TotalFunctions';
// INTERFACES
import {
    TradeIn,
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import {
    Customer,
    PaymentLine,
    Settings,
    Site
} from '../../../global/interfaces/GeneralInterface';
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';

const PDFTaxInvoice = async (
    paymentType: string,
    saleDetails: VehicleSale,
    selectedCustomer: Customer,
    selectedVehicle: Vehicle,
    linkedSales: Order[],
    linkedTrades: TradeIn[],
    linkedVouchers: Voucher[],
    paymentLines: PaymentLine[]
) => {
    let settings: Settings;
    let site: Site;
    let image = null;

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Get the settings in order to get company details and logo
    await api
        .get(
            `settingsNextGen/${localStorage.getItem('SiteId')}?includeImage=true`
        )
        .then((res) => {
            settings = res.data.settings;
            site = res.data.site;

            // Create and format an image object
            let newImg = document.createElement('img');
            newImg.src = res.data.image;
            newImg.className = 'logo';
            image = newImg;
        });

    /* ----------------------------- Document Header ---------------------------- */
    doc.addImage(image, 10, 15, 70, 20); // Put image on page

    // PDF title
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(13);
    doc.text(`MOTOR VEHICLE SALE TAX INVOICE`, 10, 40);

    doc.setFont('helvetica', 'bold').text('Sale #' + saleDetails.id, 10, 45);
    if (saleDetails.finalisedDate) {
        doc.setFont('helvetica', 'normal')
            .setFontSize(11)
            .text(
                `Sale Finalised Date: ${DateFormatter(saleDetails.finalisedDate)}`,
                10,
                50
            );
    }

    // Dealership details (ABN, address, etc)
    doc.setFont('helvetica', 'normal').setFontSize(11);
    doc.text(settings.companyName, 199, 20, { align: 'right' });
    doc.text(site.address, 199, 25, { align: 'right' });
    doc.text(site.suburb + ' ' + site.state + ' ' + site.postcode, 199, 30, {
        align: 'right'
    });
    doc.text('Phone: ' + site.phoneNumber, 199, 35, { align: 'right' });
    doc.setFont('helvetica', 'bold').text('ABN: ' + settings.abn, 199, 40, {
        align: 'right'
    });
    doc.setFont('helvetica', 'normal').text(settings.websiteURL, 199, 45, {
        align: 'right'
    });

    /* ---------------------------- Customer Details ---------------------------- */
    doc.setFont('helvetica', 'normal').setFontSize(7);
    doc.rect(10, 53, 93, 60); // The box around the customer details
    doc.text('Salesperson: ', 11, 58);
    doc.line(55, 53, 55, 61); // Vertical line between sales person and LMD
    doc.text('Dealer', 56, 56.5);
    doc.text('Licencee No.', 56, 59);
    doc.line(10, 61, 103, 61); // Horizontal line below sales person and LMD

    doc.setFont('helvetica', 'bold').setFontSize(9);
    doc.text("PURCHASER'S DETAILS", 11, 65);
    doc.line(10, 71, 103, 71); // Horizontal line below customer name
    doc.line(10, 78, 103, 78); // Horizontal line below address line 1

    doc.setFont('helvetica', 'normal').setFontSize(7);
    doc.text('Postcode:', 70, 83);
    doc.line(10, 85, 103, 85); // Horizontal line below addres line 2 and postcode

    doc.text('Telephone No:', 11, 90);
    doc.line(10, 92, 103, 92); // Horizontal line below phone number

    doc.text('Email:', 11, 97);
    doc.line(10, 99, 103, 99); // Horizontal line below email address

    doc.text('Date of', 11, 102);
    doc.text('Birth:', 11, 105);
    doc.line(55, 99, 55, 106); // Vertical line between dob and licence number
    doc.text("Driver's", 56, 102);
    doc.text('License No:', 56, 105);
    doc.line(10, 106, 103, 106); // Horizontal line below dob and licence number

    doc.text('ABN:', 11, 111);

    // Fill customer information if required
    doc.setFont('helvetica', 'normal').setFontSize(9);
    doc.text(
        saleDetails?.User?.firstName + ' ' + saleDetails?.User?.lastName,
        26,
        58
    );

    if (settings.lmd) {
        doc.text(settings.lmd, 102, 59, { align: 'right' });
    }

    if (selectedCustomer.companyName) {
        doc.text(selectedCustomer.companyName, 11, 69);
    } else {
        doc.text(
            selectedCustomer.firstName + ' ' + selectedCustomer?.lastName,
            11,
            69
        );
    }

    if (selectedCustomer.addressLine1) {
        doc.text(selectedCustomer.addressLine1, 11, 76);
    }

    if (selectedCustomer.addressLine2 && selectedCustomer.suburb) {
        doc.text(
            selectedCustomer.addressLine2 + ' ' + selectedCustomer.suburb,
            11,
            83
        );
    } else if (selectedCustomer.addressLine2) {
        doc.text(selectedCustomer.addressLine2, 11, 83);
    } else if (selectedCustomer.suburb) {
        doc.text(selectedCustomer.suburb, 11, 83);
    }

    if (selectedCustomer.postcode) {
        doc.text(selectedCustomer.postcode, 82, 83);
    }

    if (selectedCustomer.phoneNumber) {
        doc.text(selectedCustomer.phoneNumber, 30, 90);
    }

    if (selectedCustomer.email) {
        doc.text(selectedCustomer.email, 19, 97);
    }

    if (selectedCustomer.driverLicenceNumber) {
        doc.text(selectedCustomer.driverLicenceNumber, 71, 105);
    }

    if (selectedCustomer.dateOfBirth) {
        let dob = moment(selectedCustomer.dateOfBirth);
        doc.text(dob.format('DD MMM YYYY'), 19, 105);
    }

    if (selectedCustomer.abn) {
        doc.text(selectedCustomer.abn, 19, 111);
    }

    /* ----------------------------- Vehicle Details ---------------------------- */
    doc.setFont('helvetica', 'bold').setFontSize(9);
    doc.rect(107, 53, 93, 60); // The box around vehicle details

    doc.text('VEHICLE BEING PURCHASED', 155, 57, { align: 'center' });
    doc.line(107, 59, 200, 59); // Horizontal line below title

    doc.setFont('helvetica', 'normal').setFontSize(7);
    doc.text('Vehicle:', 109, 63);
    doc.line(107, 64, 200, 64); // Horizontal line below vehicle description

    doc.text('Condition:', 109, 70);
    doc.line(107, 72, 200, 72); // Horizontal line below condition

    doc.text('Body', 109, 76);
    doc.text('Type:', 109, 78);
    doc.line(155, 72, 155, 79); // Vertical line between body type and registration number
    doc.text('Registration', 156, 76);
    doc.text('Number:', 156, 78);
    doc.line(107, 79, 200, 79); // Horizontal line below body type and registration number

    doc.text('VIN / Chassis No:', 109, 84);
    doc.line(107, 86, 200, 86); // Horizontal line below vin

    doc.text('Engine Number:', 109, 91);
    doc.line(107, 93, 200, 93); // Horizontal line below engine number

    doc.text('Model', 109, 97);
    doc.text('Year:', 109, 99);
    doc.line(155, 93, 155, 100); // Vertical line between year and compliance
    doc.text('Compliance', 156, 97);
    doc.text('Plate Date:', 156, 99);
    doc.line(107, 100, 200, 100); // Horizontal line below year and compliance

    doc.text('Odometer', 109, 103).text('kms', 154, 103, { align: 'right' });
    doc.text('Reading:', 109, 106).text('mls', 154, 106, { align: 'right' });
    doc.line(155, 100, 155, 107); // Vertical line between odometer and colour
    doc.text('Body', 156, 103);
    doc.text('Colour:', 156, 106);
    doc.line(107, 107, 200, 107); // Horizontal line below odometer and colour

    doc.text('Stock No:', 109, 111);
    doc.line(155, 107, 155, 113); // Vertical line between stock number and delivery date
    doc.text('Est. Delivery Date:', 156, 111);

    // Fill vehicle details if required
    doc.setFont('helvetica', 'normal').setFontSize(9);
    doc.text(
        selectedVehicle.Make?.name + ' ' + selectedVehicle.Model.name,
        119,
        63
    );

    if (selectedVehicle?.condition) {
        doc.text(selectedVehicle.condition, 122, 70);
    }

    if (selectedVehicle.BodyTypeId) {
        doc.text(selectedVehicle.BodyType.name, 119, 77);
    }

    if (selectedVehicle.registrationNumber) {
        doc.text(selectedVehicle.registrationNumber, 171, 77);
    }

    if (selectedVehicle.vin) {
        doc.text(selectedVehicle.vin, 130, 84);
    }

    if (selectedVehicle.engineNumber) {
        doc.text(selectedVehicle.engineNumber, 130, 91);
    }

    if (selectedVehicle.year) {
        doc.text(selectedVehicle.year.format('YYYY'), 117, 98);
    }

    if (selectedVehicle.complianceDate) {
        doc.text(DateFormatter(selectedVehicle.complianceDate), 170, 98);
    }

    if (selectedVehicle.odometer) {
        doc.text(selectedVehicle.odometer, 148, 104, { align: 'right' });
    }

    if (selectedVehicle.colour) {
        doc.text(selectedVehicle.colour, 166, 104);
    }

    if (selectedVehicle.stockNumber) {
        doc.text(selectedVehicle.stockNumber, 121, 111);
    }

    doc.rect(10, 120, 190, 127);
    doc.setFont('helvetica', 'bold')
        .setFontSize(9)
        .text('PRICES AND SETTLEMENT DETAILS', 105, 125, { align: 'center' });
    doc.line(10, 128, 200, 128);

    let tableBody = [
        ['Item', 'Price'],
        ['Add-Ons:', CurrencyFormatter(PartTotals(linkedSales).totalPartPrice)],
        ['Vehicle Price:', CurrencyFormatter(saleDetails.vehicleSalePrice)],
        [
            'Dealer Origination Fees:',
            CurrencyFormatter(saleDetails.insurancePrice)
        ],
        ['Warranty:', CurrencyFormatter(saleDetails.warrantyPrice)],
        ['Delivery:', CurrencyFormatter(saleDetails.deliveryPrice)],
        [
            'Government Charges:',
            CurrencyFormatter(GovernmentCharges(saleDetails))
        ],
        ['Discount:', CurrencyFormatter(Discount(saleDetails))],
        [
            'Total Purchase Price:',
            CurrencyFormatter(saleDetails.totalPurchasePrice)
        ],
        [
            'GST:',
            CurrencyFormatter(
                saleDetails.excludeGST
                    ? 0
                    : (parseFloat(saleDetails.totalPurchasePrice) -
                          GovernmentCharges(saleDetails)) /
                          11
            )
        ],
        [
            'Taxable Amount (EX. GST):',
            CurrencyFormatter(
                saleDetails.excludeGST
                    ? parseFloat(saleDetails.totalPurchasePrice)
                    : (parseFloat(saleDetails.totalPurchasePrice) -
                          GovernmentCharges(saleDetails)) /
                          1.1
            )
        ],
        ['Deposit:', CurrencyFormatter(saleDetails.depositPrice)],
        [
            'Trade Allowance:',
            CurrencyFormatter(TradeTotals(linkedTrades, saleDetails.gstType))
        ]
    ];

    if (paymentType === 'deposit') {
        tableBody.push([
            'Amount Paid:',
            CurrencyFormatter(saleDetails.depositPrice)
        ]);
        tableBody.push([
            'Balance',
            CurrencyFormatter(
                parseFloat(saleDetails.totalPurchasePrice) -
                    parseFloat(saleDetails.depositPrice) -
                    TradeTotals(linkedTrades, saleDetails.gstType)
            )
        ]);
    } else {
        tableBody.push([
            'Amount Paid:',
            CurrencyFormatter(TotalPaid(paymentLines))
        ]);
        tableBody.push([
            'Balance',
            CurrencyFormatter(
                parseFloat(saleDetails.totalPurchasePrice) -
                    TotalPaid(paymentLines) -
                    TradeTotals(linkedTrades, saleDetails.gstType)
            )
        ]);
    }

    autoTable(doc, {
        startY: 131,
        tableWidth: 185,
        body: tableBody,
        theme: 'striped',
        columnStyles: { 1: { halign: 'left' } },
        willDrawCell: function (data) {
            var rows = data.table.body;
            if (
                data.row.index === rows.length - 1 ||
                data.row.index === 8 ||
                data.row.index === 9 ||
                data.row.index === 10 ||
                data.row.index === 0
            ) {
                doc.setFont('helvetica', 'bold').setTextColor(0);
            }
        }
    });

    let lastYcoordinates = (doc as any).lastAutoTable.finalY + 7;
    doc.setFont('helvetica', 'bold').setFontSize(10);
    doc.text('Bank Details:', 10, lastYcoordinates);
    doc.text('BSB: ', 10, lastYcoordinates + 7);
    doc.text('Account Number: ', 10, lastYcoordinates + 12);
    doc.text('Account Name: ', 10, lastYcoordinates + 17);
    doc.text(
        'Please allow at least 1 business day for money to appear in account. ',
        10,
        lastYcoordinates + 24
    );

    doc.setFont('helvetica', 'normal').setFontSize(10);
    doc.text(settings.directDepositAccountBsb, 50, lastYcoordinates + 7);
    doc.text(settings.directDepositAccountNumber, 50, lastYcoordinates + 12);
    doc.text(settings.directDepositAccountName, 50, lastYcoordinates + 17);

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFTaxInvoice;
