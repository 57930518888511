// IMPORTS
import { Typography } from '@mui/material';
import { useState } from 'react';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Tabs from '../../global/Tabs';
import SelectInvoices from './components/SelectInvoices';
import FinaliseAndPay from './components/FinaliseAndPay';
import CreditorPayments from './components/CreditorPayments';
import { withSnackbar } from '../../global/WrappingSnackbar';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

/**
 * CreditorReconciliation
 * The overarching page containing all creditor reconciliation content
 * @author Estienne
 * @returns The parent UI content for Creditor Reconciliation
 */
const CreditorReconciliation = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [readyInvoices, setReadyInvoices] = useState([]);

    const unconfirmedInvoicesTab = {
        id: 0,
        title: 'Unconfirmed Invoices',
        content: (
            <SelectInvoices
                setReadyInvoices={setReadyInvoices}
                paymentStatus={'notready'}
                showSnackbar={showSnackbar}
            />
        )
    };

    const readyInvoicesTab = {
        id: 1,
        title: 'Ready Invoices',
        content: (
            <SelectInvoices
                setReadyInvoices={setReadyInvoices}
                readyInvoices={readyInvoices}
                paymentStatus={'ready'}
                showSnackbar={showSnackbar}
            />
        )
    };

    const finaliseTab = {
        id: 2,
        title: 'Finalise & Pay',
        content: (
            <FinaliseAndPay
                readyInvoices={readyInvoices}
                showSnackbar={showSnackbar}
            />
        )
    };

    const creditorPaymentsTab = {
        id: 3,
        title: 'Creditor Payments',
        content: <CreditorPayments />
    };

    const tabContent = [
        unconfirmedInvoicesTab,
        readyInvoicesTab,
        finaliseTab,
        creditorPaymentsTab
    ];

    return (
        <PageWrapper>
            <Typography variant="h4">Creditor Reconciliation</Typography>
            <br />
            <Tabs tabContent={tabContent} />
        </PageWrapper>
    );
};

export default withSnackbar(CreditorReconciliation);
