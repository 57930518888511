// IMPROTS
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    TableRow,
    Typography
} from '@mui/material';
import { Delete } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import InvoiceHeader from './InvoiceHeader';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../../global/tableComponents/DataTable';
import PageWrapper from '../../../../global/PageWrapper';
import RoleCheck from '../../../../global/RoleCheck';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
// LOGIC
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import GetVehicleRebateInvoice from '../../logic/GetVehicleRebateInvoice';
import DeleteVehicleRebate from '../../logic/DeleteVehicleRebate';
// INTERFACES
import { Creditor, Site } from '../../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { Account } from '../../../../global/interfaces/AdminInterface';
import PageDoesNotExist from '../../../../global/PageDoesNotExist';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Vehicle?: Vehicle;
    Account: Account;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
    documentReference: string;
    bypassCredRec?: boolean;
}
interface InvoiceLine {
    id: number;
    Vehicle?: Vehicle;
    amount: string;
    description: string;
}

const ViewRebateInvoice = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const { id } = useParams<{ id: string }>();
    const [responseCode, setResponseCode] = useState<number>();
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([]);
    const [invoiceObject, setInvoiceObject] = useState<InvoiceObject>({
        Creditor: null,
        Site: null,
        Vehicle: null,
        Account: null,
        documentDate: null,
        dueDate: null,
        GSTtype: 'G1',
        documentTotal: null,
        documentReference: null,
        bypassCredRec: false
    });

    const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);

    const columns = [
        { id: 0, label: 'Stock Number', width: 150 },
        { id: 1, label: 'Vehicle', width: 300 },
        { id: 2, label: 'Description', width: 300 },
        { id: 3, label: 'Amount', width: 100 }
    ];

    useEffect(() => {
        GetVehicleRebateInvoice(id, setInvoiceObject, setInvoiceLines);
    }, [id]);

    // Get the total of all the entered lines
    const totalLines = () => {
        let total = 0;
        invoiceLines.forEach((line) => {
            total += parseFloat(line.amount);
        });
        return total;
    };

    return (
        <PageWrapper>
            {!invoiceObject.Account && !invoiceObject.Creditor ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4">Vehicle Rebate #{id}</Typography>
                    <br />
                    <InvoiceHeader
                        invoiceObject={invoiceObject}
                        setInvoiceObject={setInvoiceObject}
                        invoiceLines={invoiceLines}
                        setInvoiceLines={setInvoiceLines}
                        responseCode={responseCode}
                        setResponseCode={setResponseCode}
                        showSnackbar={showSnackbar}
                        isDisabled={true}
                    />
                    <br />
                    {invoiceLines.length > 0 && (
                        <Paper>
                            <DataTable columns={columns}>
                                {invoiceLines.map((line) => (
                                    <TableRow>
                                        <DataCellColoured
                                            handleClick={() =>
                                                window.open(
                                                    `/vehicles/view/${line.Vehicle.id}`
                                                )
                                            }
                                        >
                                            {line.Vehicle?.stockNumber}
                                        </DataCellColoured>
                                        <DataCell>
                                            {line.Vehicle?.year}{' '}
                                            {line.Vehicle?.Make?.name}{' '}
                                            {line.Vehicle?.Model?.name}{' '}
                                            {line.Vehicle?.Series?.name}
                                            <br />
                                            <span
                                                style={{
                                                    color: '#555',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                VIN: {line.Vehicle?.vin}
                                            </span>
                                        </DataCell>
                                        <DataCell>{line.description}</DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(line.amount)}
                                        </DataCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <DataCell colSpan={2}></DataCell>
                                    <DataCell>
                                        <b>Total</b>
                                    </DataCell>
                                    <DataCell>
                                        <b>{CurrencyFormatter(totalLines())}</b>
                                    </DataCell>
                                </TableRow>
                            </DataTable>
                        </Paper>
                    )}

                    <RoleCheck
                        permission={'delete_vehicleRebate'}
                        component={
                            <div
                                style={{
                                    margin: 0,
                                    top: 'auto',
                                    right: 20,
                                    bottom: 20,
                                    left: 'auto',
                                    position: 'fixed',
                                    zIndex: 1
                                }}
                            >
                                <Fab
                                    color="primary"
                                    variant="extended"
                                    onClick={() => {
                                        setWarningDialogOpen(true);
                                    }}
                                >
                                    <Delete />
                                    Delete
                                </Fab>
                            </div>
                        }
                    />

                    <Dialog
                        open={warningDialogOpen}
                        onClose={() => {
                            setWarningDialogOpen(false);
                        }}
                    >
                        <DialogTitle>Delete Vehicle Rebate #{id}</DialogTitle>
                        <DialogContent>
                            Are you sure you want to delete this vehicle rebate?
                            This action is irreversible.
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setWarningDialogOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    DeleteVehicleRebate(id, showSnackbar);
                                }}
                            >
                                Delete Vehicle Rebate
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </PageWrapper>
    );
};

export default withSnackbar(ViewRebateInvoice);
