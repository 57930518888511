// IMPORTS
import { useState } from 'react';
import { Autocomplete, TableRow, TextField, Typography } from '@mui/material';
import moment from 'moment';
// COMPONENTS
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
interface TransactionsDrawerContentProps {
    tillReconciliation: any;
}
const TransactionsDrawerContent = ({
    tillReconciliation
}: TransactionsDrawerContentProps) => {
    const [selectedMethods, setSelectedMethods] = useState([]);

    const transactionColumns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Department' },
        { id: 2, label: 'Time' },
        { id: 3, label: 'User' },
        { id: 4, label: 'Amount' }
    ];

    const filterOutTypes = () => {
        let tillLines = [...tillReconciliation.tillLines];
        tillLines = tillLines.filter(
            (x) => x.type !== 'account' && x.type !== 'directDeposit'
        );
        return tillLines;
    };

    const getOptionLabel = (option) => {
        if (option.transactions.length > 0) {
            return option.label + ' (' + option.transactions.length + ')';
        } else {
            return option.label;
        }
    };

    const handleChange = (e, v) => {
        let newSelectedMethods = [];

        v.forEach((item) => {
            newSelectedMethods.push(item);
        });

        setSelectedMethods(newSelectedMethods);
    };

    return (
        <>
            <Autocomplete
                multiple
                options={filterOutTypes()}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={(option, value) =>
                    option.type === value.type
                }
                value={selectedMethods}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Payment Methods"
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
            <br />
            {selectedMethods.map((method) => (
                <>
                    <Typography variant="subtitle1">
                        {method.label}{' '}
                        {method.transactions.length > 0
                            ? '(' + method.transactions.length + ')'
                            : ''}
                    </Typography>
                    <DataTable columns={transactionColumns}>
                        {method?.transactions.map((transaction) => {
                            return (
                                <TableRow>
                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(
                                                `${transaction.urlPrefix}${transaction.OrderId}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        {transaction.OrderId}
                                    </DataCellColoured>
                                    <DataCell>{transaction.orderType}</DataCell>
                                    <DataCell>
                                        {moment(transaction.timestamp).format(
                                            'h:mm A'
                                        )}
                                    </DataCell>
                                    <DataCell>{transaction.user}</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(transaction.amount)}
                                    </DataCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <DataCell colSpan={3}></DataCell>
                            <DataCell>
                                <b>Total</b>
                            </DataCell>
                            <DataCell>
                                <b>{CurrencyFormatter(method.expected)}</b>
                            </DataCell>
                        </TableRow>
                    </DataTable>
                    <br />
                    <br />
                </>
            ))}
        </>
    );
};

export default TransactionsDrawerContent;
