// IMPORTS
import { ReactNode } from 'react';
import MuiPaper from '@mui/material/Paper';

interface PaperProps {
    children: ReactNode;
    textAlign?: string;
    overflowX?: string;
}

// Wraps the child component in a Paper with padding around the edges
const Paper = ({ children, textAlign, overflowX }: PaperProps) => {
    return (
        <MuiPaper
            sx={{
                padding: '20px',
                textAlign: textAlign ?? '',
                overflowX: overflowX ?? ''
            }}
        >
            {children}
        </MuiPaper>
    );
};

export default Paper;
