import { Switch, Route } from 'react-router-dom';

// SUPPORT ROUTES
import OverviewSupportPage from '../documentation/overview';
import AccountSupportPage from '../documentation/admin/account/account';
import ReportingSupportPage from '../documentation/admin/reporting/reporting';
import EnterInvoiceSupportPage from '../documentation/admin/enterInvoice';
import CreditorsSupportPage from '../documentation/admin/creditors';
import CreditInvoiceSupportPage from '../documentation/admin/creditInvoice';
import GeneralJournalSupportPage from '../documentation/admin/generalJournal';
import ManualTransactionSupportPage from '../documentation/admin/manualTransaction';
import BankReconciliationSupportPage from '../documentation/admin/reconciliators/bankReconciliation';
import DebtorReconciliationSupportPage from '../documentation/admin/reconciliators/debtorReconciliation';
import CreditorReconciliationSupportPage from '../documentation/admin/reconciliators/creditorReconciliation';
import RebateReconciliatorSupportPage from '../documentation/admin/reconciliators/rebateReconciliator';
import RegoReconciliatorSupportPage from '../documentation/admin/reconciliators/regoReconciliator';
import TimeClockSupportPage from '../documentation/admin/timeClock';
import CustomersSupportPage from '../documentation/customers/customers';
import InventorySupportPage from '../documentation/inventory/inventory';
import NewSaleSupportPage from '../documentation/parts/newSale';
import SalesSupportPage from '../documentation/parts/sales';
import VouchersSupportPage from '../documentation/parts/vouchers';
import BackorderSupportPage from '../documentation/parts/backorders';
import NewVehicleSaleSupportPage from '../documentation/vehicleSales/newVehicleSale';
import NewVehicleTradeSupportPage from '../documentation/vehicleSales/newVehicleTrade';
import VehicleSalesSupportPage from '../documentation/vehicleSales/vehicleSales';
import InvoiceVehiclesSupportPage from '../documentation/vehicleSales/invoiceVehicles';
import StockVehiclesSupportPage from '../documentation/vehicleSales/stockVehicles';
import VehicleTransfersSupportPage from '../documentation/vehicleSales/transfers';
import LeadsManagementSupportPage from '../documentation/vehicleSales/leadsManagement';
import NewStockOrderSupportPage from '../documentation/stockControl/newStockOrder';
import EnterStockInvoiceSupportPage from '../documentation/stockControl/enterStockInvoice';
import CreditStockInvoiceSupportPage from '../documentation/stockControl/creditStockInvoice';
import StockOrderSupportPage from '../documentation/stockControl/stockOrder';
import StockTransfersSupportPage from '../documentation/stockControl/stockTransfers';
import CalendarSupportPage from '../documentation/service/calendar';
import BookingsSupportPage from '../documentation/service/bookings';
import PurchaseOrderSupportPage from '../documentation/service/purchaseOrder';
import NewBookingSupportPage from '../documentation/service/newBooking';
import ServicePaymentSupportPage from '../documentation/service/payment';
import TillReconciliationSupportPage from '../documentation/tillReconciliation/tillReconciliation';

const DocumentationRouter = () => {
    return (
        <>
            <Switch>
                {/* SUPPORT ROUTERS */}
                {/* Support admin accounts page */}
                <Route path="/support/admin/accounts">
                    <AccountSupportPage />
                </Route>

                {/* Support admin reporting page */}
                <Route path="/support/admin/reporting">
                    <ReportingSupportPage />
                </Route>

                {/* Support admin creditors page */}
                <Route path="/support/admin/creditors">
                    <CreditorsSupportPage />
                </Route>

                {/* Support admin enter invoice page */}
                <Route path="/support/admin/enterInvoice">
                    <EnterInvoiceSupportPage />
                </Route>

                <Route path="/support/admin/creditInvoice">
                    <CreditInvoiceSupportPage />
                </Route>

                <Route path="/support/admin/generalAccountingEntry">
                    <GeneralJournalSupportPage />
                </Route>

                <Route path="/support/admin/manualTransaction">
                    <ManualTransactionSupportPage />
                </Route>

                <Route path="/support/admin/bankReconciliation">
                    <BankReconciliationSupportPage />
                </Route>

                <Route path="/support/admin/creditorReconciliation">
                    <CreditorReconciliationSupportPage />
                </Route>

                <Route path="/support/admin/debtorReconciliation">
                    <DebtorReconciliationSupportPage />
                </Route>

                <Route path="/support/admin/rebateReconciliation">
                    <RebateReconciliatorSupportPage />
                </Route>

                <Route path="/support/admin/registrationReconciliation">
                    <RegoReconciliatorSupportPage />
                </Route>

                <Route path="/support/admin/timeclock">
                    <TimeClockSupportPage />
                </Route>

                {/* Support Customers page */}
                <Route path="/support/customers">
                    <CustomersSupportPage />
                </Route>

                {/* Support Inventory search page */}
                <Route path="/support/inventory/search">
                    <InventorySupportPage />
                </Route>

                {/*  Part & Accessories Support page */}
                <Route path="/support/inventory/sales">
                    <SalesSupportPage />
                </Route>

                <Route path="/support/inventory/newSale">
                    <NewSaleSupportPage />
                </Route>

                <Route path="/support/inventory/vouchers">
                    <VouchersSupportPage />
                </Route>

                <Route path="/support/inventory/backorders">
                    <BackorderSupportPage />
                </Route>

                {/*  Stock Control Support page */}
                <Route path="/support/inventory/stockorders">
                    <StockOrderSupportPage />
                </Route>
                <Route path="/support/inventory/stockorder">
                    <NewStockOrderSupportPage />
                </Route>

                <Route path="/support/inventory/stockInvoicePO">
                    <EnterStockInvoiceSupportPage />
                </Route>

                <Route path="/support/inventory/stockInvoiceNoPO">
                    <EnterStockInvoiceSupportPage />
                </Route>

                <Route path="/support/inventory/creditInvoice">
                    <CreditStockInvoiceSupportPage />
                </Route>

                <Route path="/support/inventory/stocktransfers">
                    <StockTransfersSupportPage />
                </Route>

                {/*  Vehicle Sales Support page */}
                <Route path="/support/vehicles/invoiceVehicles">
                    <InvoiceVehiclesSupportPage />
                </Route>

                <Route path="/support/vehicles/stock">
                    <StockVehiclesSupportPage />
                </Route>
                <Route path="/support/vehicles/sales">
                    <VehicleSalesSupportPage />
                </Route>

                <Route path="/support/vehicles/newContract">
                    <NewVehicleSaleSupportPage />
                </Route>

                <Route path="/support/vehicles/newTrade">
                    <NewVehicleTradeSupportPage />
                </Route>

                <Route path="/support/vehicles/vehicletransfers">
                    <VehicleTransfersSupportPage />
                </Route>

                <Route path="/support/vehicles/leadsmanagement">
                    <LeadsManagementSupportPage />
                </Route>

                {/*  Service Support page */}
                <Route path="/support/service/calendar">
                    <CalendarSupportPage />
                </Route>

                <Route path="/support/service/bookings">
                    <BookingsSupportPage />
                </Route>

                <Route path="/support/service/newBooking">
                    <NewBookingSupportPage />
                </Route>

                <Route path="/support/service/purchaseOrders">
                    <PurchaseOrderSupportPage />
                </Route>

                <Route path="/support/service/payment">
                    <ServicePaymentSupportPage />
                </Route>

                {/*  Till Reconciliation Support page */}
                <Route path="/support/tillReconciliation">
                    <TillReconciliationSupportPage />
                </Route>

                {/* Support Home page */}
                <Route path="/support/">
                    <OverviewSupportPage />
                </Route>
            </Switch>
        </>
    );
};

export default DocumentationRouter;
