// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
import CalculateMargin from '../../../global/logic/CalculateMargin';
// INTERFACES
import {
    OnlineOrder,
    Order,
    StockOrder
} from '../../../global/interfaces/PartsInterface';
import {
    Customer,
    PaymentLine
} from '../../../global/interfaces/GeneralInterface';
import {
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import dayjs from 'dayjs';

const GetSaleDetails = (
    SaleId: number,
    setSaleDetails: Dispatch<SetStateAction<Order>>,
    setOldSale: Dispatch<SetStateAction<Order>>,
    setSelectedType: Dispatch<SetStateAction<string>>,
    setSelectedEntity: Dispatch<
        SetStateAction<Customer | VehicleSale | Vehicle | Service>
    >,
    setAssociatedOnlineOrder: Dispatch<SetStateAction<OnlineOrder>>,
    setExtraCosts: Dispatch<
        SetStateAction<
            {
                id: number;
                type: string;
                amount: string;
                onlineVoucherCode?: string;
                VoucherId?: number;
                code?: string;
            }[]
        >
    >,
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>,
    setStockOrders: Dispatch<SetStateAction<StockOrder[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    setInvalid: Dispatch<SetStateAction<boolean>>,
    setSelectedSite: Dispatch<SetStateAction<number>>
) => {
    setLoading(true);
    api(`saleDetails/${SaleId}`).then((res) => {
        if (res.status === 204) {
            showSnackbar(
                'Sale does not exist.',
                'Please double check your URL and try again.',
                'warning'
            );
            setLoading(false);
            setInvalid(true);
        } else {
            let saleDetails = res.data.saleDetails;
            let vouchers = res.data.vouchers;
            let stockOrders = res.data.stockOrders;

            // Set the stock orders
            setStockOrders(stockOrders);

            // Set the payment lines
            setPaymentLines(saleDetails.paymentLines);

            // Set the site
            setSelectedSite(saleDetails.SiteId);

            // Create the extra costs for freight and vouchers
            let extraCosts = [];
            if (saleDetails.freight) {
                if (saleDetails.freightRefunded) {
                    extraCosts.push({
                        id: 0,
                        type: 'freight',
                        amount: String(
                            saleDetails.freight - saleDetails.freightRefunded
                        )
                    });
                } else {
                    extraCosts.push({
                        id: 0,
                        type: 'freight',
                        amount: String(saleDetails.freight)
                    });
                }
            }

            vouchers.forEach((voucher) => {
                extraCosts.push({
                    id: 0,
                    type: 'voucher',
                    amount: String(voucher.totalPrice),
                    code: voucher.voucherCode,
                    VoucherId: voucher.id,
                    refunded: voucher.refunded
                });
            });

            setExtraCosts(extraCosts);

            // Go through the orderlines and calculate the total price and total cost
            let totalPrice = 0;
            let totalCost = 0;
            saleDetails.orderLines.forEach((line) => {
                totalPrice += parseFloat(line.totalPrice);
                totalCost += parseFloat(line.totalCost);
            });
            saleDetails.totalPrice = totalPrice;
            saleDetails.totalCost = totalCost;
            saleDetails.totalMargin = CalculateMargin(totalPrice, totalCost);

            // Determine the sale type and assign the entity
            if (saleDetails.OnlineOrderId) {
                setSelectedType('online');
                setSelectedEntity(saleDetails.Customer);
                setAssociatedOnlineOrder(res.data.associatedOnlineOrder);
            } else if (saleDetails.CustomerId === 0) {
                setSelectedType('guest');
            } else if (saleDetails.CustomerId) {
                setSelectedType('customer');
                setSelectedEntity(saleDetails.Customer);
            } else if (saleDetails.VehicleSaleId) {
                setSelectedType('contract');
                setSelectedEntity(saleDetails.VehicleSale);
            } else if (saleDetails.VehicleId) {
                setSelectedType('vehicle');
                setSelectedEntity(saleDetails.Vehicle);
            } else if (saleDetails.ServiceId) {
                setSelectedType('service');
                let newSaleDetails = JSON.parse(
                    JSON.stringify(saleDetails.Service)
                );
                if (newSaleDetails.CustomerVehicle?.id) {
                    newSaleDetails.CustomerVehicle.year = dayjs(
                        newSaleDetails.CustomerVehicle.year
                    );
                    setSelectedEntity(newSaleDetails);
                } else if (newSaleDetails.Vehicle?.id) {
                    newSaleDetails.Vehicle.year = dayjs(
                        newSaleDetails.Vehicle?.year
                    );
                    setSelectedEntity(newSaleDetails);
                }
            } else if (saleDetails.internalType === 1) {
                setSelectedType('workshop');
            } else if (saleDetails.internalType === 2) {
                setSelectedType('sales');
            }

            setSaleDetails(saleDetails);
            setOldSale(saleDetails);
            setLoading(false);
            setReadOnly(true);
        }
    });
};

export default GetSaleDetails;
