import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface TransferData {
    siteFrom: number;
    siteTo: number;
    vehicleId: number;
    notes: string;
}

const SubmitTransfer = (
    transferData: TransferData,
    showSnackbar: showSnackbar
) => {
    if (transferData.siteTo === transferData.siteFrom) {
        showSnackbar('You selected the same site twice.', '', 'error');
    } else {
        api.post('vehicleTransferNextGen', transferData).then((res) => {
            if (res.status === 200) {
                showSnackbar('Transfer successful.');
                window.location.href = '/vehicles/vehicletransfers';
            } else {
                showSnackbar(
                    'Transfer not successful. Please check your data or try again later.',
                    '',
                    'error'
                );
            }
        });
    }
};

export default SubmitTransfer;
