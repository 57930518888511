import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { TextMessage } from '../interfaces/GeneralInterface';

const GetPreviousTextMessages = (
    type: string,
    id: number,
    setPreviousTextMessages: Dispatch<SetStateAction<TextMessage[]>>
) => {
    let queryString = '';
    if (type === 'Parts') {
        queryString = `OrderId=${id}`;
    } else if (type === 'Service') {
        queryString = `ServiceId=${id}`;
    } else if (type === 'Sales') {
        queryString = `VehicleSaleId=${id}`;
    } else if (type === 'Customer') {
        queryString = `CustomerId=${id}`;
    }
    api.get(`previousTextMessages?${queryString}`).then((res) => {
        setPreviousTextMessages(res.data);
    });
};

export default GetPreviousTextMessages;
