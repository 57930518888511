import { Step } from '../../interface';

export const AddCreditorSteps: Step[] = [
    {
        label: `In the sidebar, click Admin > Creditors`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/add-creditor-01.png'
    },
    {
        label: `On the Creditors page, click the 'Add Creditor' button in the speed dial.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/add-creditor-02.png'
    },
    {
        label: `The 'New Creditor' page will open. Enter the necessary information. Name, Code, Phone Number, and Email Address are required fields and must contain valid data. Other fields are optional. If you choose to fill them, ensure the data is valid.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/add-creditor-03.png'
    },
    {
        label: `Click the 'Save' icon to add the new creditor. If there are any errors, an error message will appear at the top of the screen indicating what needs to be corrected. Upon successful creation, a success message will be displayed.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/add-creditor-04.png'
    }
];

export const ViewEditCreditorSteps: Step[] = [
    {
        label: `Click the Code of the creditor to be redirected to the creditor details page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/edit-creditor-01.png'
    },
    {
        label: `This page displays all the details about a creditors as well as their associated invoices, payment runs, credit invoices, and backorders..`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/edit-creditor-02.png'
    },
    {
        label: `In the Details tab, you can update the creditor details by clicking the 'Edit' button in the speed dial.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/edit-creditor-03.png'
    },
    {
        label: `Click the 'Save' icon to save changes.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/edit-creditor-04.png'
    },
    {
        label: `In the Invoices tab, you can update the payment due date by clicking the Calendar icon and selecting a new date.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/edit-creditor-05.png'
    },
    {
        label: `In the Payments tab, you can unreconcile a reconciled payment run by clicking the 'Reverse' icon. Or regenerate a payment remittance by clicking the 'Printer' icon, which will automatically open a Payment Remittance PDF file in a new window.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/edit-creditor-06.png'
    },
    {
        label: `In the Backorders tab, you can bulk update backorder status. Select one or multiple orders by clicking the corresponding checkboxes.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/edit-creditor-07.png'
    },
    {
        label: `Click the Edit icon to open a status update dialog. `,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/edit-creditor-08.png'
    },
    {
        label: `Select the desired status. Click the Proceed button when you are certain about the update. Note that this action cannot be reversed.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/edit-creditor-10.png'
    }
];

export const PrintAccountStatementsSteps: Step[] = [
    {
        label: `On the creditor details page, click the 'Print Account Statement' icon in the speed dial to open the 'Print Account Statement' dialog.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/print-statement-01.png'
    },
    {
        label: `In the dialog, select a start date and an end date. If no dates are selected, the statement will include all transactions. If only an end date is selected, it will include transactions up to that date. If only a start date is selected, it will include transactions from that date to today.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/print-statement-02.png'
    },
    {
        label: `Click the 'GENERATE STATEMENT' button. The account statement will then open in a new window as a PDF file.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditors/print-statement-03.png'
    }
];
