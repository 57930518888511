// IMPORTS
import { useState } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
// LOGIC
import HandleDeletePart from '../logic/HandleDeletePart';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface DeletePartDialogProps {
    partId: string;
    partNumber: string;
    showSnackbar: showSnackbar;
}
const DeletePartDialog = ({
    partId,
    partNumber,
    showSnackbar
}: DeletePartDialogProps) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    return (
        <>
            <DialogTitle>
                Are you sure you would like to delete {partNumber}?
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    This action cannot be undone and all data linked with the
                    part will be lost.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button>Cancel</Button>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        HandleDeletePart(
                            parseInt(partId),
                            showSnackbar,
                            setButtonLoading
                        )
                    }
                >
                    Delete Part
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default DeletePartDialog;
