import { TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
interface NotesProps {
  newNotes: string;
  setNewNotes: Dispatch<SetStateAction<string>>;
  disabled: boolean;
  notes: string;
}

const Notes = (props: NotesProps) => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        New Notes
      </Typography>
      <TextField
        variant="outlined"
        multiline
        rows="5"
        label="Note"
        fullWidth
        InputLabelProps={{ shrink: true }}
        value={props.newNotes || ''}
        onChange={(e) => props.setNewNotes(e.target.value)}
        sx={{ width: '100%', marginBottom: 1 }}
        disabled={props.disabled}
      />
      {props.notes ? (
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Previous Notes
          </Typography>
          {props.notes.split('|')?.map((noteLine) => (
            <Typography align="left" marginLeft={25} marginBottom={1}>
              {noteLine}
            </Typography>
          ))}
        </>
      ) : null}
    </>
  );
};

export default Notes;
