// IMPORTS
import { List, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { SubMenuProps } from '../../global/interfaces/GlobalInterface';

const PartsSubMenu = ({ isSupportPage }: SubMenuProps) => {
    const currentMode = useTheme().palette.mode;
    return (
        <>
            <List
                sx={{
                    backgroundColor:
                        currentMode === 'dark' ? '#3b3b3b' : '#dddddd'
                }}
            >
                <ListItemButton
                    component={Link}
                    data-cy={'salesTableButton'}
                    to={
                        isSupportPage
                            ? '/support/inventory/sales'
                            : '/inventory/sales'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Sales
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    data-cy={'newSaleButton'}
                    to={
                        isSupportPage
                            ? '/support/inventory/newSale'
                            : '/inventory/newSale'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        New Sale
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/inventory/vouchers'
                            : '/inventory/vouchers'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Vouchers
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/inventory/backorders'
                            : '/inventory/backorders'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                        data-cy={'backorderSidebarButton'}
                    >
                        Backorders
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/inventory/dashboard'
                            : '/inventory/dashboard'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Dashboard
                    </ListItemText>
                </ListItemButton>
            </List>
        </>
    );
};

export default PartsSubMenu;
