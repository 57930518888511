/**
 * AreEmpty
 * Check if any of the provided values are empty/null/undefined
 * @author Estienne
 * @param fields An array of values to check
 * @returns true if empty/null/undefined, false otherwise
 */
export const AreEmpty = (fields: any[]) => {
    let empty = false;

    // Iterate through the provided values
    // and check if any are null
    fields.forEach((field) => {
        if (field == null || field === undefined || field === '') {
            empty = true;
        }
    });

    return empty;
};

/**
 * NoNumbers
 * Check to see if any strings contain numbers
 * @author Estienne
 * @param fields an array of strings
 * @returns true if no numbers
 */
export const NoNumbers = (fields: string[]) => {
    let noNumbers = true;

    fields.forEach((field) => {
        if (/\d/.test(field)) {
            noNumbers = false;
        }
    });

    return noNumbers;
};

/**
 * NoDudInfo
 * Check fields to see if any contain just a single character
 * @author Estienne
 * @param fields an array of strings
 * @return true if no dud info
 */
export const NoDudInfo = (fields: string[]) => {
    let noDudInfo = true;

    fields.forEach((field) => {
        if (field.length === 1) {
            noDudInfo = false;
        }
    });

    return noDudInfo;
};
