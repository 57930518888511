// IMPROTS
import { Dispatch, SetStateAction } from 'react';
interface StocktakeDetails {
    countedParts: any[];
    uncountedParts: any[];
    movedBins: any[];
    notes: string;
    review: boolean;
}

/*
    Handles changing the switch to decide whether a new part in a bin location should be added to the array
    or replace the current bin location in the array.

    This functions handles both the global switch and the individual line switches
*/
const ChangeBinLocationSwitch = (
    stocktakeDetails: StocktakeDetails,
    setStocktakeDetails: Dispatch<SetStateAction<StocktakeDetails>>,
    setBinLocationSwitch: Dispatch<SetStateAction<boolean>>,
    UnitId: number,
    value: any
) => {
    // Save the current lines
    let currentLines = [...stocktakeDetails.movedBins];

    // If the unit ID is 0 it means the global switch has been clicked and all lines need to be adjusted
    if (UnitId === 0) {
        // Go through each row and set the switch value to the global switch value
        currentLines.forEach((row) => {
            row.binLocationSwitch = value;
        });
        setBinLocationSwitch(value);
    }

    // Otherwise only change the switch value for the line
    else {
        let index = currentLines.findIndex((x) => x.id === UnitId);
        let row = { ...currentLines[index] };

        row.binLocationSwitch = value;
        currentLines[index] = row;
    }

    setStocktakeDetails({ ...stocktakeDetails, movedBins: currentLines });
};

export default ChangeBinLocationSwitch;
