import moment from 'moment';
import { saveAs } from 'file-saver';
import { StockOrder } from '../../../../global/interfaces/PartsInterface';

const RegenerateYamahaOrderFile = (row: StockOrder) => {
    let date = moment(row.createdAt);
    let spaceChar = ' ';
    let zeroChar = '0';
    let trimmedOrderRef = row.orderReference.substring(0, 15);

    // Header
    let textFile = `1${row.Api.additionalDetails.dealerCode}${spaceChar.repeat(4)}${trimmedOrderRef}${spaceChar.repeat(15 - trimmedOrderRef.length)}${date.format('YYYYMMDD')}${row.orderType === 'stock' ? 'S' : 'D'}${spaceChar.repeat(20)}${spaceChar.repeat(8)}${spaceChar.repeat(25)}${spaceChar.repeat(25)}N${spaceChar.repeat(
        3
    )}${spaceChar.repeat(33)}\n`;

    let totalQty = 0;
    // Order Details Line
    for (let line of row.orderLines) {
        textFile = textFile.concat(
            `2${row.Api.additionalDetails.dealerCode}${spaceChar.repeat(4)}${trimmedOrderRef}${spaceChar.repeat(15 - trimmedOrderRef.length)}${line.partNumber}${spaceChar.repeat(20 - line.partNumber.length)}${zeroChar.repeat(7 - line.quantity.toString().length)}${line.quantity.toString()}${spaceChar.repeat(97)}\n`
        );
        totalQty += line.quantity;
    }

    // Order Trailer Record
    textFile = textFile.concat(
        `9${row.Api.additionalDetails.dealerCode}${spaceChar.repeat(4)}${trimmedOrderRef}${spaceChar.repeat(15 - trimmedOrderRef.length)}${zeroChar.repeat(5 - row.orderLines.length.toString().length)}${row.orderLines.length}${zeroChar.repeat(9 - totalQty.toString().length)}${totalQty}${spaceChar.repeat(110)}`
    );

    // Save the file
    const file = new Blob([textFile], { type: 'text/plain' });
    saveAs(
        file,
        `YAMAHA_ORDER${trimmedOrderRef ? `_${trimmedOrderRef}` : ''}_${date.format('YYMMDDhhmmss')}.001`
    );
};
export default RegenerateYamahaOrderFile;
