// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
  Divider,
  Grid,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import {
  CurrencyFormatter,
  ToFixed
} from '../../../../global/logic/Formatters';
import CalculateCashTotals from '../../logic/CaluclateCashTotals';
import GetDifferenceString from '../../logic/GetDifferenceString';
interface CashDenominationsProps {
  tillReconciliation: any;
  setTillReconciliation: Dispatch<SetStateAction<any>>;
  readOnly: boolean;
}

const CashDenominations = ({
  readOnly,
  tillReconciliation,
  setTillReconciliation
}: CashDenominationsProps) => {
  const columns = [
    { id: 0, label: 'Denomination', textAlign: 'left', width: 500 },
    { id: 1, label: 'Count', width: 150 },
    { id: 1, label: 'Total', width: 200 },
    { id: 1, label: 'Banking', width: 150 }
  ];

  const denominations = [
    { label: '$100', name: 'oneHundredDollars', value: 100 },
    { label: '$50', name: 'fiftyDollars', value: 50 },
    { label: '$20', name: 'twentyDollars', value: 20 },
    { label: '$10', name: 'tenDollars', value: 10 },
    { label: '$5', name: 'fiveDollars', value: 5 },
    { label: '$2', name: 'twoDollars', value: 2 },
    { label: '$1', name: 'oneDollar', value: 1 },
    { label: '50c', name: 'fiftyCents', value: 0.5 },
    { label: '20c', name: 'twentyCents', value: 0.2 },
    { label: '10c', name: 'tenCents', value: 0.1 },
    { label: '5c', name: 'fiveCents', value: 0.05 }
  ];

  const handleUpdateCash = (index: number, selector: string, value: string) => {
      let currentCash = [...tillReconciliation.cash];

      currentCash[index][selector] = value;

      if (selector === 'quantity') {
          if (value === '' || !Number.isInteger(parseFloat(value))) {
              currentCash[index].count = '';
          } else {
              currentCash[index].count = ToFixed(
                  parseInt(value) * currentCash[index].value
              );
          }
      } else if (selector === 'count') {
          let quantity = parseFloat(value) / currentCash[index].value;
          if (Number.isInteger(quantity) && quantity !== 0) {
              currentCash[index].quantity = quantity;
          } else {
              currentCash[index].quantity = '';
          }
      }

      let currentTillLines = [...tillReconciliation.tillLines];
      let cashTillLine = currentTillLines.filter((x) => x.type === 'cash');
      cashTillLine[0].actual = CalculateCashTotals(
          tillReconciliation.cash
      ).countedTotal;

      setTillReconciliation({
          ...tillReconciliation,
          cash: currentCash,
          tillLines: currentTillLines
      });
  };

  const getCashTillLine = () => {
      let cashTillLine = tillReconciliation.tillLines.filter(
          (x) => x.type === 'cash'
      );
      return cashTillLine[0];
  };

  const updateCashTillLine = (selector: string, value: string) => {
      let currentTillLines = [...tillReconciliation.tillLines];
      let cashTillLine = currentTillLines.filter((x) => x.type === 'cash');
      cashTillLine[0][selector] = value;

      setTillReconciliation({
          ...tillReconciliation,
          tillLines: currentTillLines
      });
  };

  return (
      <>
          <Paper>
              <Grid container spacing={2}>
                  <Grid item xs={9}>
                      <Typography variant="subtitle1">CASH</Typography>
                      <Typography variant="subtitle2">
                          Expected:{' '}
                          {CurrencyFormatter(getCashTillLine()?.expected)}&nbsp;
                          {GetDifferenceString(getCashTillLine())}
                      </Typography>
                      <br />
                  </Grid>
                  <Grid item xs={3} textAlign="right">
                      <Typography variant="caption" sx={{ color: '#808080' }}>
                          Cashbag Number
                      </Typography>
                      <TextField
                          disabled={readOnly}
                          fullWidth
                          size="small"
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                          value={tillReconciliation.cashbagNumber || ''}
                          onChange={(e) =>
                              setTillReconciliation({
                                  ...tillReconciliation,
                                  cashbagNumber: e.target.value
                              })
                          }
                          InputProps={{
                              startAdornment: (
                                  <InputAdornment position="start">
                                      #
                                  </InputAdornment>
                              )
                          }}
                          inputProps={{ min: 0, style: { textAlign: 'right' } }}
                      />
                  </Grid>
              </Grid>
              <Divider />
              <DataTable columns={columns}>
                  {denominations.map((row, index) => (
                      <TableRow>
                          <DataCell align="left">{row.label}</DataCell>
                          <DataCell>
                              <TextField
                                  disabled={readOnly}
                                  fullWidth
                                  size="small"
                                  autoComplete="off" // This stops the browser from giving prompts from previous entries
                                  InputProps={{
                                      inputProps: {
                                          style: { textAlign: 'center' }
                                      }
                                  }}
                                  value={
                                      tillReconciliation.cash[index].quantity
                                  }
                                  onChange={(e) =>
                                      handleUpdateCash(
                                          index,
                                          'quantity',
                                          e.target.value
                                      )
                                  }
                              />
                          </DataCell>
                          <DataCell>
                              <TextField
                                  disabled={readOnly}
                                  fullWidth
                                  size="small"
                                  variant="standard"
                                  type="number"
                                  autoComplete="off" // This stops the browser from giving prompts from previous entries
                                  InputProps={{
                                      startAdornment: (
                                          <InputAdornment position="start">
                                              $
                                          </InputAdornment>
                                      )
                                  }}
                                  value={tillReconciliation.cash[index].count}
                                  onChange={(e) =>
                                      handleUpdateCash(
                                          index,
                                          'count',
                                          e.target.value
                                      )
                                  }
                              />
                          </DataCell>
                          <DataCell>
                              <TextField
                                  disabled={readOnly}
                                  fullWidth
                                  size="small"
                                  autoComplete="off" // This stops the browser from giving prompts from previous entries
                                  InputProps={{
                                      inputProps: {
                                          style: { textAlign: 'center' }
                                      }
                                  }}
                                  value={tillReconciliation.cash[index].banking}
                                  onChange={(e) =>
                                      handleUpdateCash(
                                          index,
                                          'banking',
                                          e.target.value
                                      )
                                  }
                              />
                          </DataCell>
                      </TableRow>
                  ))}
                  <TableRow>
                      <TableCell
                          colSpan={2}
                          sx={{ borderBottom: 'none', paddingTop: '10px' }}
                      >
                          <TextField
                              disabled={readOnly}
                              fullWidth
                              size="small"
                              label="Notes"
                              variant="standard"
                              helperText=" "
                              InputLabelProps={{ shrink: true }}
                              value={getCashTillLine()?.notes}
                              onChange={(e) =>
                                  updateCashTillLine('notes', e.target.value)
                              }
                          />
                      </TableCell>
                      <TableCell
                          sx={{ borderBottom: 'none', paddingTop: '10px' }}
                      >
                          <TextField
                              fullWidth
                              disabled
                              size="small"
                              variant="standard"
                              label="Counted Total"
                              helperText=" "
                              InputLabelProps={{ shrink: true }}
                              value={
                                  CalculateCashTotals(tillReconciliation.cash)
                                      .countedTotal
                              }
                              InputProps={{
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          $
                                      </InputAdornment>
                                  )
                              }}
                          />
                      </TableCell>
                      <TableCell
                          sx={{ borderBottom: 'none', paddingTop: '10px' }}
                      >
                          <TextField
                              fullWidth
                              disabled
                              size="small"
                              variant="standard"
                              label="Banking Total"
                              InputLabelProps={{ shrink: true }}
                              helperText={`Float: ${CurrencyFormatter(CalculateCashTotals(tillReconciliation.cash).floatTotal)}`}
                              value={
                                  CalculateCashTotals(tillReconciliation.cash)
                                      .bankingTotal
                              }
                              InputProps={{
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          $
                                      </InputAdornment>
                                  )
                              }}
                          />
                      </TableCell>
                  </TableRow>
              </DataTable>
          </Paper>
      </>
  );
};

export default CashDenominations;
