import { Divider, Grid, Typography } from '@mui/material';

const line = (title, description) => {
    return (
        <>
            <Grid item xs={4}>
                {title}
            </Grid>
            <Grid item xs={8}>
                <Typography variant="caption">
                    {description}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </>
    );
};

export const CustomerCSVExplanation = () => {
    return (
        <Grid item xs={12}>
            <Divider />
            <Grid container spacing={0} alignItems={'center'}>
                <Grid item xs={12} sx={{marginBottom: '10px'}}>
                    <Typography>
                        Ora accepts the following value to be in the CSV in
                        order to create the Customer:
                    </Typography>
                </Grid>
                {line('First Name (required if company name is not provided)', 'Can be any string of characters.')}
                {line('Last Name (required if company name is not provided)', 'Can be any string of characters.')}
                {line('Company Name (required if first name and last name is not provided)', 'Can be any string of characters.')}
                {line('ABN', 'Must be 11 digits.')}
                {line('Customer Type', 'Can only be: Retail or Trade. If this is not present Ora will default to Retail for every customer.')}
                {line('Phone Number', 'Can be any string of characters.')}
                {line('Email', 'Can be any string of characters.')}
                {line('Date of Birth', 'Must follow DD/MM/YYYY format.')}
                {line('Driver Licence Number', 'Can be any string of characters.')}
                {line('Address Line 1', 'Can be any string of characters.')}
                {line('Address Line 2', 'Can be any string of characters.')}
                {line('Suburb', 'Can be any string of characters.')}
                {line('Postcode', 'Must be 4 digits.')}
                {line('State', 'Can be any string of characters.')}
                {line('Country', 'Can be any string of characters.')}
            </Grid>
        </Grid>
    );
};
