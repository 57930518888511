// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

// Submits a customer merge
const SubmitMergeDuplicates = (
    showSnackbar: showSnackbar,
    customerKeepId?: number,
    customerDeleteId?: number,
    duplicateCustomers?: { key: string; mergeId: number; rows: Customer[] }[]
) => {
    // If doing a specific customer merge
    if (customerKeepId) {
        api.post('removeDuplicateCustomers?specificMerge=true', {
            customerKeepId: customerKeepId,
            customerDeleteId: customerDeleteId
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Customers Successfully Merged.',
                    'The page will now refresh.'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }
    // Otherwise if using the big list to merge
    else {
        api.post('removeDuplicateCustomers', duplicateCustomers).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Customers Successfully Merged.',
                    'The page will now refresh.'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }
};

export default SubmitMergeDuplicates;
