import { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';

const GetMonthlyIndicators = (
    SiteId: number,
    date: Dayjs | null,
    setHighlightedDays: Dispatch<SetStateAction<number[]>>,
    setCalendarLoading: Dispatch<SetStateAction<boolean>>
) => {
    setCalendarLoading(true);
    api.get(`monthlyTillRecIndicator?SiteId=${SiteId}&date=${date}`).then(
        (res) => {
            setHighlightedDays(res.data);
            setCalendarLoading(false);
        }
    );
};

export default GetMonthlyIndicators;
