// IMPORTS
import { ReactNode } from 'react';
import TableCell from '@mui/material/TableCell';

interface PaperProps {
  colSpan?: number;
  rowSpan?: number;
  align?: string;
  textDecoration?: string;
  handleClick?: any;
  children?: ReactNode;
  width?: string;
  maxWidth?: string;
  color?: string;
  cypressLabel?: string
}

// This is a standard table cell with center alignment
const DataCell = ({
  colSpan,
  rowSpan,
  align,
  textDecoration,
  handleClick,
  children,
  width,
  maxWidth,
  color,
  cypressLabel
}: PaperProps) => {
  return (
    <TableCell
      width={width ? width : ''}
      colSpan={colSpan ? colSpan : 1}
      rowSpan={rowSpan ? rowSpan : 1}
      onClick={handleClick ?? null}
      data-cy={cypressLabel ? cypressLabel : ''}
      sx={{
        textDecoration: textDecoration ? textDecoration : 'none',
        textAlign: align ? align : 'center',
        cursor: handleClick ? 'pointer' : 'default',
        color: color ? color : 'default',
        maxWidth: maxWidth ? maxWidth : ''
      }}
    >
      {children}
    </TableCell>
  );
};

export default DataCell;
