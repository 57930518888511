// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
  DialogContentText,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';

interface CustomerTableFilterProps {
  filter: Filter;
  setFilter: Dispatch<SetStateAction<Filter>>;
  isFilterActive?: () => any;
}

const VoucherTableFilter = ({
  filter,
  setFilter,
  isFilterActive
}: CustomerTableFilterProps) => {
  // Handle updating the filter object
  const handleUpdateFilter = (property: any, value: any) => {
    let newFilter = filter.voucherFilter;
    newFilter[property] = value;
    setFilter({ ...filter, voucherFilter: newFilter });
  };

  return (
    <>
      <DialogContentText id="alert-dialog-description">
        If nothing specified in the filter all types will be included.
      </DialogContentText>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          {/* The debtor, phone number and email checkbox filters */}
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.voucherFilter.hasBalance}
                  onChange={(e) =>
                    handleUpdateFilter('hasBalance', e.target.checked)
                  }
                />
              }
              label="Has Balance Remaining"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.voucherFilter.hasLinkedOrder}
                  onChange={(e) =>
                    handleUpdateFilter('hasLinkedOrder', e.target.checked)
                  }
                />
              }
              label="Has A Linked Sale"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default VoucherTableFilter;
