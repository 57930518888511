// IMPORTS
import { TableRow, Typography, Divider } from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import {
  DateFormatter,
  CurrencyFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import { TradeIn } from '../../../../global/interfaces/VehicleSaleInterface';

interface TradesProps {
  trades: TradeIn[];
}

const Trades = ({ trades }: TradesProps) => {
  const columns = [
    { id: 0, label: 'Trade ID' },
    { id: 1, label: 'Customer' },
    { id: 2, label: 'Date' },
    { id: 3, label: 'Trade Total' },
    { id: 5, label: 'Location' },
    { id: 6, label: 'User' }
  ];

  return (
    <Paper>
      <Typography variant="h5" sx={{ fontSize: '25px' }}>
        Trades
      </Typography>
      <br />
      <Divider />
      <DataTable columns={columns}>
        {trades.map((trade) => (
          <TableRow>
            <DataCellColoured
              handleClick={() =>
                window.open(`/vehicles/viewTrade/${trade.id}`, '_blank')
              }
            >
              {trade.id}
            </DataCellColoured>
            <DataCell>
              {trade?.Customer?.companyName
                ? trade?.Customer?.companyName
                : trade?.Customer?.firstName + ' ' + trade?.Customer?.lastName}
            </DataCell>
            <DataCell>
              {DateFormatter(
                trade?.tradeInDate ??
                  trade?.VehicleSale?.finalisedDate ??
                  trade.createdAt
              )}
            </DataCell>
            <DataCell>{CurrencyFormatter(trade?.tradeInPrice)}</DataCell>
            <DataCell>{trade?.Site?.name}</DataCell>
            <DataCell>
              {trade?.User?.firstName} {trade?.User?.lastName}
            </DataCell>
          </TableRow>
        ))}
      </DataTable>
    </Paper>
  );
};

export default Trades;
