import { TableRow, TextField } from '@mui/material';
import DataCell from '../../../global/tableComponents/DataCell';
import { CurrencyFormatter } from '../../../global/logic/Formatters';

const CreditSingleOrderline = ({
    orderline,
    readOnly,
    creditable,
    saleDetails,
    handleFieldChange
}) => {
    // Check if that orderline is for a part that has already been credited
    // If it's the case we can find in the saleDetails orderline a line with a returned quantity, and the current orderline has a positive quantity (if it was negative it means we got the refund line created previously)
    const previouslyCredited = saleDetails.orderLines
        .filter((x) => x.UnitId === orderline.UnitId)
        .some(
            (x) =>
                x.returnedQuantity > 0 &&
                orderline.quantity > 0 &&
                orderline.amountRefunded > 0
        );
    if (!orderline.voucherCode) {
        return (
            <TableRow>
                <DataCell>{orderline.partNumber}</DataCell>
                <DataCell>{orderline.name}</DataCell>
                <DataCell>{orderline.pricePaid}</DataCell>
                <DataCell>{orderline.quantity}</DataCell>
                {saleDetails.status === 'Proforma' && (
                    <DataCell>{orderline.backorderQuantity}</DataCell>
                )}
                {readOnly ? (
                    saleDetails.orderLines.findIndex(
                        (x) => parseInt(x.quantity) < 0
                    ) !== -1 || saleDetails.freightRefunded > 0 ? (
                        <DataCell>
                            {CurrencyFormatter(orderline.amountRefunded)}
                        </DataCell>
                    ) : null
                ) : previouslyCredited ||
                  (!creditable && orderline.quantity < 0) ? (
                    <>
                        <DataCell>
                            {orderline.quantity < 0
                                ? 'N/A'
                                : orderline.returnedQuantity}
                        </DataCell>
                        {saleDetails.status === 'Proforma' && (
                            <DataCell>
                                {orderline.returnedBackorderQuantity}
                            </DataCell>
                        )}
                        <DataCell>
                            {CurrencyFormatter(orderline.amountRefunded)}
                        </DataCell>
                    </>
                ) : (
                    <>
                        <DataCell>
                            {orderline.quantity < 0 ? (
                                orderline.returnedQuantity
                            ) : (
                                <TextField
                                    size="small"
                                    value={orderline.returnedQuantity}
                                    onChange={(e) =>
                                        handleFieldChange(
                                            orderline.rowId,
                                            e.target.value,
                                            'returnedQuantity'
                                        )
                                    }
                                />
                            )}
                        </DataCell>
                        {saleDetails.status === 'Proforma' && (
                            <DataCell>
                                <TextField
                                    size="small"
                                    value={orderline.returnedBackorderQuantity}
                                    onChange={(e) =>
                                        handleFieldChange(
                                            orderline.rowId,
                                            e.target.value,
                                            'returnedBackorderQuantity'
                                        )
                                    }
                                />
                            </DataCell>
                        )}
                        <DataCell>
                            <TextField
                                size="small"
                                type="number"
                                value={orderline.refundAmount}
                                onChange={(e) =>
                                    handleFieldChange(
                                        orderline.rowId,
                                        e.target.value,
                                        'refundAmount'
                                    )
                                }
                            />
                        </DataCell>
                    </>
                )}
            </TableRow>
        );
    } else {
        return null;
    }
};

export default CreditSingleOrderline;
