// IMPORTS
import { Dispatch, SetStateAction } from "react";

// LOGIC
import api from "../../../../api";
import { Brand } from "../interfaces/PartsInterface";

/**
 * GetBrands
 * Get all brands from the server
 * @author Pierre
 * @param setBrands state setter for the brands array
 */
const GetBrands = (setBrands: Dispatch<SetStateAction<Brand[]>>) => {
    api.get("brandsNextGen").then((res) => {
        setBrands(res.data);
    });
};

export default GetBrands;
