// IMPORTS
import { ReactNode, Dispatch, SetStateAction } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

interface DrawerProps {
    openDrawer: boolean;
    setOpenDrawer: Dispatch<SetStateAction<boolean>>;
    title: string;
    subTitle: string;
    width?: string;
    children: ReactNode;
    zIndex?: number;
    onCloseFunction?: () => void;
}

const Drawer = ({
    openDrawer,
    setOpenDrawer,
    title,
    subTitle,
    width,
    children,
    zIndex = 1100,
    onCloseFunction
}: DrawerProps) => {
    return (
        <MuiDrawer
            anchor={'right'}
            open={openDrawer}
            onClose={() => {
                onCloseFunction ? onCloseFunction() : setOpenDrawer(false);
            }}
            sx={{ zIndex: zIndex }}
        >
            <Box
                sx={{ width: width ? width : '30vw', padding: '20px' }}
                role="presentation"
            >
                <Typography variant="h4">{title}</Typography>
                <Typography>{subTitle}</Typography>
                <br />
                <Divider></Divider>
                <br />
                {children}
            </Box>
        </MuiDrawer>
    );
};

export default Drawer;
