import { PaymentLine } from '../../../global/interfaces/GeneralInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
import {
    TradeIn,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { ToFixed } from '../../../global/logic/Formatters';

/**
 * handlePageChange
 * Change the page of results
 * @author Pierre
 * @param linkedSales an order array
 * @return totalPartCost, totalPartPrice both numbers
 */
export function PartTotals(linkedSales: Order[]) {
    let totalPartCost = 0;
    let totalPartPrice = 0;

    // Go through each sale in the array
    linkedSales?.forEach((sale) => {
        // Go through each orderlines
        sale.orderLines?.forEach((line) => {
            if (!line.inactive) {
                // Initialize costPrice as cost price average if available, otherwise cost price daily
                let costPrice = line.costPriceAverage
                    ? line.costPriceAverage
                    : line.costPriceDaily;

                totalPartCost +=
                    (parseInt(line.quantity) +
                        parseInt(line.backorderQuantity)) *
                    costPrice;

                totalPartPrice +=
                    (parseInt(line.quantity) +
                        parseInt(line.backorderQuantity)) *
                    parseFloat(line.pricePaid);
            }
        });
    });

    return {
        totalPartCost: ToFixed(totalPartCost),
        totalPartPrice: ToFixed(totalPartPrice)
    };
}

export function VoucherTotals(linkedVouchers: any[]) {
    let voucherTotal = 0;

    linkedVouchers?.forEach((voucher) => {
        voucherTotal += parseFloat(voucher.totalPrice);
    });

    return ToFixed(voucherTotal);
}

// tradeAllowance = tradeInPrice includes GST - finance payout price
export function TradeTotals(linkedTrades: TradeIn[], gstType: string) {
    let tradeTotal = 0;

    linkedTrades?.forEach((trade) => {
        const payoutPrice = parseFloat(trade.Vehicle.payoutPrice) || 0;
        const tradeInPrice = parseFloat(trade.tradeInPrice);

        let difference =
            (gstType === 'EX' ? tradeInPrice * 1.1 : tradeInPrice) -
            payoutPrice;

        tradeTotal += difference;
    });

    return ToFixed(tradeTotal);
}

export function VehicleCharges(saleDetails: VehicleSale) {
    let vehicleCharges =
        parseFloat(saleDetails.vehicleSalePrice) +
        parseFloat(saleDetails.warrantyPrice) +
        parseFloat(saleDetails.insurancePrice) +
        parseFloat(saleDetails.deliveryPrice);

    return ToFixed(vehicleCharges);
}

export function GovernmentCharges(saleDetails: VehicleSale) {
    let governmentCharges = 0;
    if (saleDetails.includesOnRoadCosts) {
        if (saleDetails.registrationPrice) {
            governmentCharges += parseFloat(saleDetails.registrationPrice);
        }
        if (saleDetails.ctpPrice) {
            governmentCharges += parseFloat(saleDetails.ctpPrice);
        }
        if (saleDetails.transferPrice) {
            governmentCharges += parseFloat(saleDetails.transferPrice);
        }
        if (saleDetails.stampDutyPrice && !saleDetails.excludeStampDuty) {
            governmentCharges += parseFloat(saleDetails.stampDutyPrice);
        }
    }

    return ToFixed(governmentCharges);
}

export function Discount(saleDetails: VehicleSale) {
    let vehicleCharges = VehicleCharges(saleDetails);
    let governmentCharges = GovernmentCharges(saleDetails);

    let discount =
        vehicleCharges +
        governmentCharges -
        parseFloat(saleDetails.totalPurchasePrice);

    if (discount < 0) {
        return 0;
    } else {
        return ToFixed(discount);
    }
}

export function TotalPaid(paymentLines: PaymentLine[]) {
    let paymentTotal = 0;

    paymentLines?.forEach((payment) => {
        if (!payment.removed) {
            paymentTotal += payment.amount;
        }
    });

    return ToFixed(paymentTotal);
}

export function NeedToPayAddOns(linkedSales: Order[]) {
    let totalOwing = 0;

    linkedSales?.forEach((sale) => {
        totalOwing += sale.amountOwed;
    });

    if (totalOwing > 0) {
        return true;
    } else {
        return false;
    }
}
