// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import CalculateMargin from '../../../global/logic/CalculateMargin';
import { ToFixed } from '../../../global/logic/Formatters';
// INTERFACES
import { Order, OrderLine } from '../../../global/interfaces/PartsInterface';

const AdjustLineValue = (
    rowId: number,
    selector: string,
    value: any,
    gstFree: boolean,
    saleDetails: Order,
    setSaleDetails: Dispatch<SetStateAction<Order>>,
    line: OrderLine
) => {
    let currentOrderLines = [...saleDetails.orderLines];
    // Old Online Orders didn't have a rowId
    let index;
    if (rowId) {
        index = currentOrderLines.findIndex(
            (x) => x.rowId === rowId && x.UnitId === line.UnitId
        );
    } else {
        index = currentOrderLines.findIndex((x) => x.UnitId === line.UnitId);
    }
    // Adjust the value for the given selctor
    if (selector === 'pricePaid' && value === '') {
        currentOrderLines[index][selector] = '0';
    } else {
        currentOrderLines[index][selector] = value;
    }

    // Reset the adjusted line total price, cost and margin
    let costPrice = currentOrderLines[index].costPriceAverage
        ? currentOrderLines[index].costPriceAverage
        : currentOrderLines[index].costPriceDaily;

    if (selector !== 'pricePaid') {
        currentOrderLines[index].pricePaid = String(
            ToFixed(parseFloat(currentOrderLines[index].pricePaid))
        );
    }
    currentOrderLines[index].totalPrice = ToFixed(
        (parseInt(currentOrderLines[index].quantity) +
            parseInt(currentOrderLines[index].backorderQuantity)) *
            parseFloat(currentOrderLines[index].pricePaid)
    );
    currentOrderLines[index].totalCost = ToFixed(
        (parseInt(currentOrderLines[index].quantity) +
            parseInt(currentOrderLines[index].backorderQuantity)) *
            costPrice
    );
    currentOrderLines[index].margin = ToFixed(
        CalculateMargin(
            currentOrderLines[index].totalPrice,
            currentOrderLines[index].totalCost
        )
    );
    currentOrderLines[index].gstFree = gstFree;

    // Calculate the new total cost and total price for the order
    let totalPrice = 0;
    let totalCost = 0;
    currentOrderLines.forEach((line) => {
        totalPrice += line.totalPrice;
        totalCost += line.totalCost;
    });

    let totalMargin = CalculateMargin(totalPrice, totalCost);

    setSaleDetails({
        ...saleDetails,
        orderLines: currentOrderLines,
        totalPrice: ToFixed(totalPrice),
        totalCost: ToFixed(totalCost),
        totalMargin: ToFixed(totalMargin)
    });
};

export default AdjustLineValue;
