import { Dispatch, SetStateAction } from 'react';
import {
    Stock,
    StockTransfer,
    Unit
} from '../../../global/interfaces/PartsInterface';

// Handles adding a part to the stock transfer
const AddPartToStockTransfer = (
    unitDetails: { unitData: Unit; stockData: Stock[] },
    transferData: StockTransfer,
    setTransferData: Dispatch<SetStateAction<StockTransfer>>
) => {
    // Get the current transfer data and the unit data that was sent from the the part search
    let newTransferData = JSON.parse(JSON.stringify(transferData));
    let unitData = unitDetails.unitData;

    // Check if the part is already on the transfer, if it is just add to the sent number
    for (let unit of newTransferData.transferLines) {
        if (unit.id === unitData.id) {
            unit.quantitySent += 1;
            setTransferData(newTransferData);
            return;
        }
    }

    // Add the updated line to the transfer lines object
    newTransferData.transferLines.push({
        UnitId: unitData.id,
        name: unitData.name,
        partNumber: unitData.partNumber,
        quantitySent: 0
    });

    setTransferData(newTransferData);
};

export default AddPartToStockTransfer;
