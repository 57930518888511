import DocumentationPage from '../DocumentationPage';
import {
    SubmitTillReconciliationSteps,
    CheckTillReconciliationHistorySteps
} from '../data/tillReconciliation/tillReconciliation';

const TillReconciliationSupportPage = () => {
    const contentSections = [
        {
            id: 'submit-till-reconciliation',
            title: 'Submit Till Reconciliation',
            href: '/support/tillReconciliation/#submit-till-reconciliation',
            description:
                'To complete the Till Reconciliation process, follow these outlined steps:',
            steps: SubmitTillReconciliationSteps
        },
        {
            id: 'check-till-reconciliation-history',
            title: 'Check Till Reconciliation History',
            href: '/support/tillReconciliation/#check-till-reconciliation-history',
            description:
                'To view the Till Reconciliation History, follow these steps:',
            steps: CheckTillReconciliationHistorySteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Till Reconciliation"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default TillReconciliationSupportPage;
