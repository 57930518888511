// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { TextField, Grid, InputAdornment, Chip, useTheme } from '@mui/material';
// INTERFACES
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';
interface PurchaseDetailsProps {
    readOnly: boolean;
    saleDetails: VehicleSale;
    setSaleDetails: Dispatch<SetStateAction<VehicleSale>>;
}

const PurchaseDetails = ({
    readOnly,
    saleDetails,
    setSaleDetails
}: PurchaseDetailsProps) => {
    const currentTheme = useTheme().palette.mode;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        type="number"
                        label="Total Purchase Price"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.totalPurchasePrice}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                totalPurchasePrice: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Chip
                                        label={
                                            saleDetails.excludeGST
                                                ? 'EX. GST'
                                                : 'INC. GST'
                                        }
                                        variant={
                                            currentTheme === 'dark'
                                                ? 'outlined'
                                                : 'filled'
                                        }
                                        size="small"
                                        color={
                                            saleDetails.excludeGST
                                                ? 'error'
                                                : 'success'
                                        }
                                        onClick={() =>
                                            setSaleDetails({
                                                ...saleDetails,
                                                excludeGST:
                                                    !saleDetails.excludeGST
                                            })
                                        }
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        type="number"
                        label="Deposit Amount"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.depositPrice}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                depositPrice: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        multiline
                        rows={7}
                        size="small"
                        type="number"
                        label="Internal Notes"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.notes}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                notes: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        multiline
                        rows={7}
                        size="small"
                        type="number"
                        label="Contract Notes"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.contractNotes}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                contractNotes: e.target.value
                            })
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PurchaseDetails;
