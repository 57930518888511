import * as DateHolidays from 'date-holidays';
import dayjs, { Dayjs } from 'dayjs';
const hd = new DateHolidays.default();
hd.init('AU', 'QLD');

// this function is to check if a date is a public holiday in QLD, Australia
const isPublicHoliday = (date) => {
    const holidays = hd.getHolidays(date.year());
    const formattedDate = date.format('YYYY-MM-DD');
    return holidays.some((holiday) => holiday.date === formattedDate);
};

// this function is to get business days of a date range
// currently, it only counts the days from Monday to Saturday, excluding Sunday and public holidays
const countBusinessDays = (startDate, endDate) => {
    let count = 0;
    let curDate = dayjs(startDate);
    while (curDate <= endDate) {
        if (curDate.day() !== 0 && !isPublicHoliday(curDate)) {
            count++;
        }
        curDate = curDate.add(1, 'day');
    }

    return count;
};

const getDefaultDateRange = () => {
    const today = dayjs();
    const endOfToday = today.endOf('day');

    // first day of current and last month
    const firstDayOfCurrentMonth = today.startOf('month');

    // count business days of current and last month
    return {
        firstDayOfCurrentMonth: firstDayOfCurrentMonth,
        today: endOfToday
    };
};

const getCorrespondingDayOfLastMonth = (currentDate) => {
    const lastMonth = currentDate.subtract(1, 'month');
    const correspondingDayOfLastMonth = lastMonth.endOf('day');

    if (correspondingDayOfLastMonth.month() !== lastMonth.month()) {
        correspondingDayOfLastMonth.endOf('month');
    }

    return correspondingDayOfLastMonth;
};

const getCorrespondingDayOfLastYear = (currentDate) => {
    const lastYear = currentDate.subtract(1, 'year');
    const correspondingDayOfLastYear = lastYear.endOf('day');

    if (correspondingDayOfLastYear.year() !== lastYear.year()) {
        correspondingDayOfLastYear.endOf('year');
    }

    return correspondingDayOfLastYear;
};

const getComparisonDates = (
    compareType: 'Month' | 'Year',
    selectedStartDate: Dayjs,
    selectedEndDate: Dayjs
): { compareStartDate: Dayjs; compareEndDate: Dayjs } => {
    let start: Dayjs;
    let end: Dayjs;

    if (compareType === 'Year') {
        start = getCorrespondingDayOfLastYear(selectedStartDate);
        end = getCorrespondingDayOfLastYear(selectedEndDate);
    } else {
        start = getCorrespondingDayOfLastMonth(selectedStartDate);
        end = getCorrespondingDayOfLastMonth(selectedEndDate);
    }

    return { compareStartDate: start, compareEndDate: end };
};

export {
    countBusinessDays,
    getDefaultDateRange,
    getCorrespondingDayOfLastMonth,
    getCorrespondingDayOfLastYear,
    getComparisonDates
};
