import { Typography, Grid, TextField } from '@mui/material'

const Step4 = (props) => {
    return (
        <>
            <Typography variant="button"><b>Please enter an admin user. This user will have access to all parts of Ora and will be the main person managing business details.</b></Typography><br /><br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        fullWidth variant="outlined"
                        label="First Name" required
                        InputLabelProps={{ shrink: true }}
                        value={props.user?.firstName}
                        error={props.stepValidation.firstName.error}
                        helperText={
                            props.stepValidation.firstName.error
                                ? props.stepValidation.firstName
                                      .message
                                : ''
                        }
                        onChange={(e) => props.setUser({ ...props.user, firstName: e.target.value })}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth variant="outlined"
                        label="Last Name" required
                        InputLabelProps={{ shrink: true }}
                        value={props.user?.lastName}
                        error={props.stepValidation.lastName.error}
                        helperText={
                            props.stepValidation.lastName.error
                                ? props.stepValidation.lastName
                                      .message
                                : ''
                        }
                        onChange={(e) => props.setUser({ ...props.user, lastName: e.target.value })}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth variant="outlined"
                        label="Username" required
                        InputLabelProps={{ shrink: true }}
                        value={props.user?.username}
                        error={props.stepValidation.username.error}
                        helperText={
                            props.stepValidation.username.error
                                ? props.stepValidation.username
                                      .message
                                : ''
                        }
                        onChange={(e) => props.setUser({ ...props.user, username: e.target.value })}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth variant="outlined"
                        label="Password" type="password" required
                        InputLabelProps={{ shrink: true }}
                        value={props.user?.password}
                        error={props.stepValidation.password.error}
                        helperText={
                            props.stepValidation.password.error
                                ? props.stepValidation.password
                                      .message
                                : ''
                        }
                        onChange={(e) => props.setUser({ ...props.user, password: e.target.value })}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth variant="outlined"
                        label="Email Address" required
                        InputLabelProps={{ shrink: true }}
                        value={props.user?.email}
                        error={props.stepValidation.email.error}
                        helperText={
                            props.stepValidation.email.error
                                ? props.stepValidation.email
                                      .message
                                : ''
                        }
                        onChange={(e) => props.setUser({ ...props.user, email: e.target.value })}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default Step4;