import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';

const HandleAssignedUserChange = (
    selectedUser: number,
    selectedEnquiry: any,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    let queryObj = {
        newUserId: selectedUser,
        selectedEnquiry: Object.keys(selectedEnquiry)
    };

    api.put('updateAssignedUserLeadsNextGen', queryObj).then((res) => {
        if (res.status === 200) {
            window.location.reload();
        } else {
            setButtonLoading(false);
        }
    });
};

export default HandleAssignedUserChange;
