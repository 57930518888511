import { Dispatch, SetStateAction, useState } from 'react';
import { DialogActions, DialogContent, TextField } from '@mui/material';

// INTERFACE
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import {
    Make,
    Model
} from '../../../../global/interfaces/VehicleSaleInterface';
import UpdateModelName from '../../logic/UpdateModelName';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const EditModelDialogContent = ({
    showSnackbar,
    selectedModel,
    setSelectedModel
}: {
    showSnackbar: showSnackbar;
    selectedModel: Model;
    setSelectedModel: Dispatch<SetStateAction<Make>>;
}) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    return (
        <>
            <DialogContent>
                <TextField
                    fullWidth
                    size="small"
                    value={selectedModel?.name}
                    onChange={(e) =>
                        setSelectedModel({
                            ...selectedModel,
                            name: e.target.value
                        })
                    }
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        UpdateModelName(
                            selectedModel,
                            showSnackbar,
                            setButtonLoading
                        )
                    }
                >
                    Update Model Name
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default EditModelDialogContent;
