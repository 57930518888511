import DocumentationPage from '../DocumentationPage';
import {
    NewRetailBookingSteps
    // NewInsuranceBookingSteps
} from '../data/service/newBooking';

const NewBookingSupportPage = () => {
    const contentSections = [
        {
            id: 'new-retail-booking',
            title: 'Create a new Service Booking',
            href: '/support/service/newBooking/#new-retail-booking',
            description:
                'To create a new service booking, follow these outlined steps:',
            steps: NewRetailBookingSteps
        }
        // {
        //     id: 'new-insurance-booking',
        //     title: 'Create a new Insurance Service Booking',
        //     href: '/support/service/newBooking/#new-insurance-booking',
        //     description:
        //         'To create a new Insurance service booking, follow these outlined steps:',
        //     steps: NewInsuranceBookingSteps
        // }
    ];

    return (
        <DocumentationPage
            pageTitle="New Booking"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default NewBookingSupportPage;
