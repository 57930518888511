// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
import ValidateUserFields from '../../../global/logic/generalValidation/ValidateUserFields';
// INTERFACES
import { User } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleAddUser = (
    userDetails: User,
    responseCode: number,
    setLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setLoading(true);
    let userFieldsValidated = ValidateUserFields(
        userDetails,
        responseCode,
        showSnackbar
    );

    if (userFieldsValidated) {
        api.post(`addNewUser`, userDetails).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'User created.',

                    'Their password will be emailed to the entered staff email.'
                );
                setTimeout(
                    () => (window.location.href = '/viewUser/' + res.data),
                    1500
                );
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
                setLoading(false);
            }
        });
    } else {
        setLoading(false);
    }
};

export default HandleAddUser;
