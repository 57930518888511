// IMPORT
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';

// Check if the entered creditor code is available
const CheckCodeAvailable = (
    code: string,
    setResponseCode: Dispatch<SetStateAction<number>>,
    showSnackbar
) => {
    api.get(`checkCreditorCodeAvailable/${code}`).then((res) => {
        if (res.status === 400) {
            showSnackbar(
                'That creditor code has already been used.',
                'Please enter a new code.',
                'warning'
            );
            setResponseCode(400);
        } else {
            setResponseCode(200);
        }
    });
};

export default CheckCodeAvailable;
