// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Unit } from '../interfaces/PartsInterface';

const GetPartSearchList = (
  searchString: string,
  setPartOptions: Dispatch<SetStateAction<Unit[]>>,
  onlyFluids?: boolean
) => {
  api
    .get(
      `inventorySearchNextGen?searchString=${searchString.replace('&', '')}&SiteId=${localStorage.getItem('SiteId')}&currentPage=0&limit=10`
    )
    .then((res) => {
      if (onlyFluids) {
        let data = res.data.rows;
        let returnObj = [];
        data.forEach((line) => {
          if (line.fluid) {
            returnObj.push(line);
          }
        });

        setPartOptions(returnObj);
      } else {
        setPartOptions(res.data.rows);
      }
    });
};

export default GetPartSearchList;
