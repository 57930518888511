import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';
import { ManualTransaction } from '../../../global/interfaces/AdminInterface';

const HandleSubmitManualTransaction = (
    manualTransactions: ManualTransaction[],
    showSnackbar: showSnackbar
) => {
    if (manualTransactions.length === 0) {
        showSnackbar(
            'You must enter at least one transaction line to continue.',
            '',
            'error'
        );
        return;
    }

    api.post('newManualTransaction', manualTransactions).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Manual transactions submitted successfully.',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact the IT department.',
                    'error'
                );
            } else {
                showSnackbar(
                    res.data.split('|')[0],
                    res.data.split('|')[1],
                    'error'
                );
            }
        }
    });
};

export default HandleSubmitManualTransaction;
