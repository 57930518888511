import { Dispatch, SetStateAction } from "react"
import api from "../../../../api"
import { Registration } from "../interfaces/VehicleSaleInterface"

const GetNSWRegistration = (setRegistrations: Dispatch<SetStateAction<Registration[]>>) => {
    api.get('nswRegistration').then(res => {
        setRegistrations(res.data)
    })
}

export default GetNSWRegistration