import CreateNewPage from './CreateNewPage';
import CategoryLine from './CategoryLine';
import DepartmentalProfit from './DepartmentalProfit';
import NetDealershipProfit from './NetDealershipProfit';
import NetDivisionalProfit from './NetDivisionalProfit';
import NetOperatingProfit from './NetOperatingProfit';
import OperationalProfit from './OperatingProfit';
import jsPDF from 'jspdf';
import { PrintPNLReportParams } from '../../interface/PNLInterfaces';

const PNLExpenditure = (
    doc: jsPDF,
    params: PrintPNLReportParams,
    totalSale: number,
    previousTotalSale: number,
    ytdTotalSale: number,
    lastYearYtdTotalSale: number,
    profitFromTrading: number,
    previousYearProfitFromTrading: number,
    ytdMonthProfitFromTrading: number,
    lastYearYtdProfitFromTrading: number
) => {
    doc.setFont('helvetica', 'bold');
    doc.text('EXPENDITURE', 10, 67);

    let latestY = 67;
    doc.line(10, latestY + 1, 200, latestY + 1);
    doc.setFont('helvetica', 'normal');

    let [afterVSE, totalVSE] = CategoryLine(
        doc,
        params,
        totalSale,
        previousTotalSale,
        ytdTotalSale,
        lastYearYtdTotalSale,
        'Vehicle Selling Expenses',
        params.vehicleSellingExpenses,
        params.previousVehicleSellingExpenses,
        params.ytdMonthVehicleSellingExpenses,
        params.lastYearYtdMonthVehicleSellingExpenses,
        latestY
    );

    let [afterEE, totalEE] = CategoryLine(
        doc,
        params,
        totalSale,
        previousTotalSale,
        ytdTotalSale,
        lastYearYtdTotalSale,
        'Employment Expenses',
        params.employmentExpenses,
        params.previousEmploymentExpenses,
        params.ytdMonthEmploymentExpenses,
        params.lastYearYtdMonthEmploymentExpenses,
        afterVSE
    );

    let [afterGE, totalGE] = CategoryLine(
        doc,
        params,
        totalSale,
        previousTotalSale,
        ytdTotalSale,
        lastYearYtdTotalSale,
        'General Expenses',
        params.generalExpenses,
        params.previousGeneralExpenses,
        params.ytdMonthGeneralExpenses,
        params.lastYearYtdMonthGeneralExpenses,
        afterEE
    );

    // TOTAL EXPENSES AND DEPARTMENTAL PROFIT
    // DepartmentalProfit is going to write a line at afterGE+1 and another at afterGE+5
    // Need to make sure they won't be written after threshold
    if (afterGE + 10 > 254) {
        CreateNewPage(doc, params);
        afterGE = 35;
    }
    let [afterDepartmentalProfit, departmentalProfit] = DepartmentalProfit(
        doc,
        totalVSE,
        totalEE,
        totalGE,
        profitFromTrading,
        previousYearProfitFromTrading,
        ytdMonthProfitFromTrading,
        lastYearYtdProfitFromTrading,
        afterGE + 1
    );

    let [afterIE, totalIE] = CategoryLine(
        doc,
        params,
        totalSale,
        previousTotalSale,
        ytdTotalSale,
        lastYearYtdTotalSale,
        'Indirect Expenses',
        params.indirectExpenses,
        params.previousIndirectExpenses,
        params.ytdMonthIndirectExpenses,
        params.lastYearYtdMonthIndirectExpenses,
        afterDepartmentalProfit
    );
    let [afterODE, totalODE] = CategoryLine(
        doc,
        params,
        totalSale,
        previousTotalSale,
        ytdTotalSale,
        lastYearYtdTotalSale,
        'Other Direct Expenses',
        params.otherDirectExpenses,
        params.previousOtherDirectExpenses,
        params.ytdMonthOtherDirectExpenses,
        params.lastYearYtdMonthOtherDirectExpenses,
        afterIE
    );

    // TOTAL INDIRECT EXPENSES AND OPERATIONAL PROFIT
    if (afterODE + 5 > 254) {
        CreateNewPage(doc, params);
        afterODE = 35;
    }
    let [afterOperationalProfit, operationalProfit] = OperationalProfit(
        doc,
        totalIE,
        totalODE,
        departmentalProfit,
        afterODE + 1
    );
    let [afterOI, totalOI] = CategoryLine(
        doc,
        params,
        totalSale,
        previousTotalSale,
        ytdTotalSale,
        lastYearYtdTotalSale,
        'Other Income',
        params.otherIncome,
        params.previousOtherIncome,
        params.ytdMonthOtherIncome,
        params.lastYearYtdMonthOtherIncome,
        afterOperationalProfit
    );

    // TOTAL OTHER INCOME AND NET OPERATING PROFIT
    if (afterOI + 5 > 254) {
        CreateNewPage(doc, params);
        afterOI = 35;
    }

    let [afterNetOperationalProfit, netOperationalProfit] = NetOperatingProfit(
        doc,
        totalOI,
        operationalProfit,
        afterOI + 1
    );
    let [afterDE, totalDE] = CategoryLine(
        doc,
        params,
        totalSale,
        previousTotalSale,
        ytdTotalSale,
        lastYearYtdTotalSale,
        'Directors Emoluments',
        params.directorsEmoluments,
        params.previousDirectorsEmoluments,
        params.ytdMonthDirectorsEmoluments,
        params.lastYearYtdMonthDirectorsEmoluments,
        afterNetOperationalProfit
    );

    // NET DIVISIONAL PROFIT
    if (afterDE + 1 > 254) {
        CreateNewPage(doc, params);
        afterDE = 35;
    }
    let [afterDivisionalProfit, netDivisionalProfit] = NetDivisionalProfit(
        doc,
        totalDE,
        netOperationalProfit,
        afterDE + 1
    );
    let [afterNOE, totalNOE] = CategoryLine(
        doc,
        params,
        totalSale,
        previousTotalSale,
        ytdTotalSale,
        lastYearYtdTotalSale,
        'Non Operating Expenses',
        params.nonOperatingExpenses,
        params.previousNonOperatingExpenses,
        params.ytdMonthNonOperatingExpenses,
        params.lastYearYtdMonthNonOperatingExpenses,
        afterDivisionalProfit
    );

    // NET DEALERSHIP PROFIT
    if (afterNOE + 1 > 254) {
        CreateNewPage(doc, params);
        afterNOE = 35;
    }
    NetDealershipProfit(doc, totalNOE, netDivisionalProfit, afterNOE);
};

export default PNLExpenditure;
