import { useEffect, useState } from 'react';

import {
    VehicleRebateLine,
    VehicleSale
} from '../../global/interfaces/VehicleSaleInterface';

import GetRebateReconciliationData from './logic/GetRebateReconciliationData';
import { Grid, IconButton, TableRow, Typography } from '@mui/material';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import Fab from '../../global/Fab';
import DataCell from '../../global/tableComponents/DataCell';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { CurrencyFormatter } from '../../global/logic/Formatters';
import SubmitRebateReconciliation from './logic/SubmitRebateReconciliation';
import PageWrapper from '../../global/PageWrapper';
import { withSnackbar } from '../../global/WrappingSnackbar';

const RebateReconciliation = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [rebateList, setRebateList] = useState<VehicleRebateLine[]>([]);
    const [vehicleSaleList, setVehicleSaleList] = useState<VehicleSale[]>([]);
    const [reconciledRebateList, setReconciledRebateList] = useState<
        VehicleRebateLine[]
    >([]);
    const [reconciledVehicleSaleList, setReconciledVehicleSaleList] = useState<
        VehicleSale[]
    >([]);

    useEffect(() => {
        GetRebateReconciliationData(setRebateList, setVehicleSaleList);
    }, []);

    const rebateColumns = [
        { id: 0, label: '' },
        { id: 1, label: 'Stock Number' },
        { id: 2, label: 'Vehicle' },
        { id: 3, label: 'Description' },
        { id: 4, label: 'Amount' }
    ];

    const saleColumns = [
        { id: 0, label: '', width: 2 },
        { id: 1, label: 'Sale ID' },
        { id: 2, label: 'Stock Number' },
        { id: 3, label: 'Vehicle' },
        { id: 4, label: 'Amount' }
    ];

    const reconcileRebate = (VehicleId: number) => {
        let tempRebateList = rebateList.filter(
            (x) => x.VehicleId !== VehicleId
        );
        let tempVehicleSaleList = vehicleSaleList.filter(
            (x) => x.VehicleId !== VehicleId
        );
        let tempReconciledRebateList = JSON.parse(
            JSON.stringify(reconciledRebateList)
        );
        let tempReconciledVehicleSaleList = JSON.parse(
            JSON.stringify(reconciledVehicleSaleList)
        );

        let rebateIndex = rebateList.findIndex(
            (x) => x.VehicleId === VehicleId
        );
        let saleIndex = vehicleSaleList.findIndex(
            (x) => x.VehicleId === VehicleId
        );
        if (rebateIndex === -1 || saleIndex === -1) {
            showSnackbar(
                "Can't find match",
                `We couldn't find a ${rebateIndex === -1 ? 'rebate' : 'vehicle sale'} for the vehicle you are trying to reconcile`,
                'error'
            );
            return;
        }

        tempReconciledRebateList.push(rebateList[rebateIndex]);
        tempReconciledVehicleSaleList.push(vehicleSaleList[saleIndex]);

        setRebateList(tempRebateList);
        setVehicleSaleList(tempVehicleSaleList);
        setReconciledRebateList(tempReconciledRebateList);
        setReconciledVehicleSaleList(tempReconciledVehicleSaleList);
    };

    const unreconcileRebate = (VehicleId: number) => {
        let tempRebateList = JSON.parse(JSON.stringify(rebateList));
        let tempVehicleSaleList = JSON.parse(JSON.stringify(vehicleSaleList));
        let tempReconciledRebateList = reconciledRebateList.filter(
            (x) => x.VehicleId !== VehicleId
        );
        let tempReconciledVehicleSaleList = reconciledVehicleSaleList.filter(
            (x) => x.VehicleId !== VehicleId
        );

        let rebateIndex = reconciledRebateList.findIndex(
            (x) => x.VehicleId === VehicleId
        );
        let saleIndex = reconciledVehicleSaleList.findIndex(
            (x) => x.VehicleId === VehicleId
        );

        tempRebateList.push(reconciledRebateList[rebateIndex]);
        tempVehicleSaleList.push(reconciledVehicleSaleList[saleIndex]);

        setRebateList(tempRebateList);
        setVehicleSaleList(tempVehicleSaleList);
        setReconciledRebateList(tempReconciledRebateList);
        setReconciledVehicleSaleList(tempReconciledVehicleSaleList);
    };

    const totalReconciledRebate = () => {
        let total = 0;
        for (let rebate of reconciledRebateList) {
            total += rebate.amount;
        }
        return total;
    };

    const totalReconciledVehicleSale = () => {
        let total = 0;
        for (let sale of reconciledVehicleSaleList) {
            total += parseFloat(sale.rebatePrice);
        }
        return total;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Rebate Reconciliator</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Paper>
                        <Typography>Reconciled Rebate</Typography>
                        <DataTable columns={rebateColumns}>
                            {reconciledRebateList.map((rebate) => (
                                <TableRow>
                                    <DataCell>
                                        <IconButton
                                            onClick={() => {
                                                unreconcileRebate(
                                                    rebate.VehicleId
                                                );
                                            }}
                                        >
                                            <ExpandMore />
                                        </IconButton>
                                    </DataCell>
                                    <DataCell>
                                        {rebate.Vehicle.stockNumber}
                                    </DataCell>
                                    <DataCell>
                                        {rebate.Vehicle?.Make?.name}{' '}
                                        {rebate.Vehicle?.Model?.name}{' '}
                                        {rebate.Vehicle?.Series?.name}
                                    </DataCell>
                                    <DataCell>{rebate.description}</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(rebate.amount)}
                                    </DataCell>
                                </TableRow>
                            ))}
                            {reconciledRebateList.length > 0 ? (
                                <TableRow>
                                    <DataCell colSpan={3}></DataCell>
                                    <DataCell>Total</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            totalReconciledRebate()
                                        )}
                                    </DataCell>
                                </TableRow>
                            ) : null}
                        </DataTable>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <Typography>Reconciled Vehicle Sale</Typography>
                        <DataTable columns={saleColumns}>
                            {reconciledVehicleSaleList.map((sale) => (
                                <TableRow>
                                    <DataCell>
                                        <IconButton
                                            onClick={() => {
                                                unreconcileRebate(
                                                    sale.VehicleId
                                                );
                                            }}
                                        >
                                            <ExpandMore />
                                        </IconButton>
                                    </DataCell>
                                    <DataCell>{sale.id}</DataCell>
                                    <DataCell>
                                        {sale.Vehicle.stockNumber}
                                    </DataCell>
                                    <DataCell>
                                        {sale.Vehicle?.Make?.name}{' '}
                                        {sale.Vehicle?.Model?.name}{' '}
                                        {sale.Vehicle?.Series?.name}
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(sale.rebatePrice)}
                                    </DataCell>
                                </TableRow>
                            ))}
                            {reconciledVehicleSaleList.length > 0 ? (
                                <TableRow>
                                    <DataCell colSpan={3}></DataCell>
                                    <DataCell>Total</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            totalReconciledVehicleSale()
                                        )}
                                    </DataCell>
                                </TableRow>
                            ) : null}
                        </DataTable>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <Typography>Unreconciled Rebate</Typography>
                        <DataTable columns={rebateColumns}>
                            {rebateList.map((rebate) => (
                                <TableRow>
                                    <DataCell>
                                        <IconButton
                                            onClick={() => {
                                                reconcileRebate(
                                                    rebate.VehicleId
                                                );
                                            }}
                                        >
                                            <ExpandLess />
                                        </IconButton>
                                    </DataCell>
                                    <DataCell>
                                        {rebate.Vehicle.stockNumber}
                                    </DataCell>
                                    <DataCell>
                                        {rebate.Vehicle?.Make?.name}{' '}
                                        {rebate.Vehicle?.Model?.name}{' '}
                                        {rebate.Vehicle?.Series?.name}
                                    </DataCell>
                                    <DataCell>{rebate.description}</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(rebate.amount)}
                                    </DataCell>
                                </TableRow>
                            ))}
                        </DataTable>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <Typography>Unreconciled Vehicle Sale</Typography>
                        <DataTable columns={saleColumns}>
                            {vehicleSaleList.map((sale) => (
                                <TableRow>
                                    <DataCell>
                                        <IconButton
                                            onClick={() => {
                                                reconcileRebate(sale.VehicleId);
                                            }}
                                        >
                                            <ExpandLess />
                                        </IconButton>
                                    </DataCell>
                                    <DataCell>{sale.id}</DataCell>
                                    <DataCell>
                                        {sale.Vehicle?.stockNumber}
                                    </DataCell>
                                    <DataCell>
                                        {sale.Vehicle?.Make?.name}{' '}
                                        {sale.Vehicle?.Model?.name}{' '}
                                        {sale.Vehicle?.Series?.name}
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(sale.rebatePrice)}
                                    </DataCell>
                                </TableRow>
                            ))}
                        </DataTable>
                    </Paper>
                </Grid>
            </Grid>

            <Fab
                editIcon={false}
                onClick={() =>
                    SubmitRebateReconciliation(
                        reconciledRebateList,
                        reconciledVehicleSaleList,
                        showSnackbar
                    )
                }
            />
        </PageWrapper>
    );
};

export default withSnackbar(RebateReconciliation);
