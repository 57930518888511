import api from '../../../../api';
import { showSnackbar } from '../interfaces/GlobalInterface';

const HandleTextCustomer = (
    id: number,
    textMessage,
    showSnackbar: showSnackbar,
    refresh,
    setRefresh,
    setTextMessage,
    setButtonLoading,
    department
) => {
    setButtonLoading(true);
    if (!textMessage.customerName) {
        showSnackbar(
            'You must provide a name to address the text message to.',
            '',
            'error'
        );
        setButtonLoading(false);
        return;
    }
    if (!textMessage.customerPhone) {
        showSnackbar(
            'You must provide a phoneNumber to send the text message to.',
            '',
            'error'
        );
        setButtonLoading(false);
        return;
    }
    if (!textMessage.message) {
        showSnackbar(
            'You must provide a message to include in the text message.',
            '',
            'error'
        );
        setButtonLoading(false);
        return;
    }
    let obj = {
        phoneNumberTo: textMessage.customerPhone,
        messageBody: textMessage.message,
        OrderId: null,
        ServiceId: null,
        VehicleSaleId: null
    };

    if (department === 'Parts') {
        obj.OrderId = id;
    } else if (department === 'Service') {
        obj.ServiceId = id;
    } else if (department === 'Sales') {
        obj.VehicleSaleId = id;
    }
    api.post(
        `sendTextMessage?SiteId=${localStorage.getItem('SiteId')}`,
        obj
    ).then((res) => {
        if (res.status === 200) {
            showSnackbar(`Text message sent to ${textMessage.customerName}`);
            setRefresh(!refresh);
            setTextMessage({ ...textMessage, message: null });
            setButtonLoading(false);
        }
    });
};

export default HandleTextCustomer;
