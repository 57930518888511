import { DashboardService } from '../../../global/dashboard/interface';

interface ServiceData {
    ServiceId: number;
    TechId: number;
    serviceComplete: string;
    serviceType: string;
    totalCost: number;
    totalSale: number;
    customerName: string;
    vehicleStockNumber: string;
    totalPrice?: number;
    totalPayment?: number;
}

const GetCustomerOrStockNumber = (service: DashboardService) => {
    let result = null;

    if (service.jobType === 'Retail') {
        if (service.Customer?.customerType !== 'Retail') {
            result = service.Customer?.companyName;
        } else {
            if (service.Customer?.firstName && service.Customer?.lastName) {
                result =
                    service.Customer.firstName.charAt(0) +
                    '. ' +
                    service.Customer.lastName;
            }
        }
    } else if (service.jobType === 'Internal') {
        result = service.Vehicle?.stockNumber;
    } else if (service.jobType === 'Warranty') {
        result =
            service.Customer?.firstName?.charAt(0) +
            '. ' +
            service.Customer?.lastName;
    } else if (service.jobType === 'Insurance') {
        result = service.Customer?.firstName
            ? service.Customer?.firstName.charAt(0) +
              '. ' +
              service.Customer?.lastName
            : 'Missing Customer Name';
    }

    return result;
};

const GetSaleLine = (service: DashboardService) => {
    let totalServiceCost = 0;
    let servicePartRevenue = 0;

    if (service.orderData?.length > 0) {
        service.orderData.forEach((order) => {
            order.orderLines.forEach((line) => {
                totalServiceCost += line.gstFree
                    ? parseFloat(line.pricePaid) * parseFloat(line.quantity)
                    : (parseFloat(line.pricePaid) / 1.1) *
                      parseFloat(line.quantity);
                servicePartRevenue += line.gstFree
                    ? parseFloat(line.priceCharged ?? line.pricePaid) *
                      parseFloat(line.quantity)
                    : (parseFloat(line.priceCharged ?? line.pricePaid) / 1.1) *
                      parseFloat(line.quantity);
            });
        });
    }

    if (service.addedCosts?.length > 0) {
        let totalPartRevenue = 0;

        service.addedCosts.forEach((part) => {
            if (part.partType === 'fluid') {
                if (service.jobType === 'Internal') {
                    totalServiceCost +=
                        parseFloat(part.costPerLitre) *
                        parseFloat(part.quantity);
                    totalPartRevenue +=
                        parseFloat(part.pricePaid) * parseFloat(part.quantity);
                } else {
                    totalServiceCost +=
                        parseFloat(part.costPerLitre) *
                        parseFloat(part.quantity);
                    totalPartRevenue +=
                        parseFloat(part.pricePaid) *
                        parseFloat(part.quantity) *
                        1.1;
                }
            } else {
                if (service.jobType === 'Internal') {
                    if (!part.costPriceAverage && !part.costPriceDaily) {
                        totalServiceCost = part.totalCost;
                    } else {
                        totalServiceCost +=
                            parseFloat(
                                part.costPriceAverage ?? part.costPriceDaily
                            ) * parseFloat(part.quantity);
                    }
                    totalPartRevenue +=
                        parseFloat(part.pricePaid) * parseFloat(part.quantity);
                } else {
                    totalServiceCost += part.totalCost
                        ? parseFloat(part.totalCost)
                        : part.costPriceAverage
                          ? parseFloat(part.costPriceAverage) *
                            parseFloat(part.quantity)
                          : part.costPriceDaily
                            ? parseFloat(part.costPriceDaily) *
                              parseFloat(part.quantity)
                            : 0;
                    totalPartRevenue += part.rowTotal
                        ? parseFloat(part.rowTotal) / 1.1
                        : part.totalPrice
                          ? part.totalPrice
                          : parseFloat(part.pricePaid) *
                            parseFloat(part.quantity);
                }
            }
        });
        servicePartRevenue += totalPartRevenue;
    }

    let labourCharge =
        service.labourCost && service.labourCost !== ' '
            ? parseFloat(service.labourCost)
            : 0;
    let environmentalLevy =
        service.environmentalLevy && service.environmentalLevy !== ' '
            ? parseFloat(service.environmentalLevy)
            : 0;
    let sundries =
        service.sundries && service.sundries !== ' '
            ? parseFloat(service.sundries)
            : 0;
    let subletTotal = service.subletTotal ? parseFloat(service.subletTotal) : 0;

    if (service.serviceWarranties?.length > 0) {
        for (let line of service.serviceWarranties) {
            totalServiceCost -= line.amount;
        }
    }

    let miscCostRevenue = 0;
    if (service.miscCosts?.length > 0) {
        for (let misc of service.miscCosts) {
            miscCostRevenue += parseFloat(misc.amount);
        }
    }

    let totalServiceRevenue =
        (servicePartRevenue ?? 0) +
        labourCharge +
        environmentalLevy +
        sundries +
        subletTotal +
        miscCostRevenue;

    if (service.serviceInvoices?.length > 0) {
        service.serviceInvoices.forEach((invoice) => {
            totalServiceCost += parseFloat(
                invoice?.StockInvoice?.documentTotal
            );
        });
    }

    let saleLine = {
        ServiceId: service.id,
        TechId: service.TechId,
        serviceComplete: service.serviceComplete,
        serviceType: service.jobType,
        totalCost: totalServiceCost,
        totalSale: totalServiceRevenue,
        customerName: GetCustomerOrStockNumber(service),
        vehicleStockNumber: GetCustomerOrStockNumber(service)
    };

    // check how much payment has been received for the service
    // if the total payment is less than the total revenue, add to notFullyPaidServices
    let totalServicePayment = 0;
    let notFullyPaid = [];

    let notFullyPaidLine = {
        ServiceId: service.id,
        TechId: service.TechId,
        serviceComplete: service.serviceComplete,
        serviceType: service.jobType,
        totalPrice: service.totalPrice,
        totalPayment: null,
        customerName: GetCustomerOrStockNumber(service),
        vehicleStockNumber: GetCustomerOrStockNumber(service)
    };

    if (service.paymentLines?.length > 0) {
        for (let payment of service.paymentLines) {
            if (payment.removed === false || !payment.removed) {
                totalServicePayment += payment.amount;
            }
        }
    }

    // unpaid service should ignore all Internal service (Internal, Rework
    if (
        service.jobType !== 'Internal' &&
        service.jobType !== 'Rework' &&
        (service.serviceComplete === 'Complete' ||
            service.serviceComplete === 'Finalised') &&
        totalServicePayment < Number(service.totalPrice)
    ) {
        notFullyPaidLine.totalPayment = totalServicePayment;
        notFullyPaid.push(notFullyPaidLine);
    }

    return {
        saleLine,
        totalServiceCost,
        totalServiceRevenue,
        notFullyPaid
    };
};

const CalculateBookingRevenue = (services: DashboardService[]) => {
    var incompleteServiceData: ServiceData[] = [];
    var completeServiceData: ServiceData[] = [];
    var finalisedServiceData: ServiceData[] = [];
    var notFullyPaidServicesData: ServiceData[] = [];

    let totalFinalisedRevenue = 0;
    let totalFinalisedCost = 0;

    const incompleteServices = services.filter(
        (service) => service.serviceComplete === 'Incomplete'
    );

    const completeServices = services.filter(
        (service) => service.serviceComplete === 'Complete'
    );

    const finalisedServices = services.filter(
        (service) => service.serviceComplete === 'Finalised'
    );

    incompleteServices.forEach((service) => {
        const { saleLine } = GetSaleLine(service);

        incompleteServiceData.push(saleLine);
    });

    completeServices.forEach((service) => {
        const { saleLine, notFullyPaid } = GetSaleLine(service);

        completeServiceData.push(saleLine);
        notFullyPaidServicesData = [
            ...notFullyPaidServicesData,
            ...notFullyPaid
        ];
    });

    finalisedServices.forEach((service) => {
        const {
            saleLine,
            totalServiceCost,
            totalServiceRevenue,
            notFullyPaid
        } = GetSaleLine(service);

        totalFinalisedRevenue += totalServiceRevenue;
        totalFinalisedCost += totalServiceCost;
        finalisedServiceData.push(saleLine);
        notFullyPaidServicesData = [
            ...notFullyPaidServicesData,
            ...notFullyPaid
        ];
    });

    return {
        incompleteServiceData,
        completeServiceData,
        finalisedServiceData,
        notFullyPaidServicesData,
        totalFinalisedRevenue,
        totalFinalisedCost
    };
};
export default CalculateBookingRevenue;
