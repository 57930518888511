// IMPORTS
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
// COMPONENTS
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
// LOGIC
import api from '../../../../../api';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { Dispatch, SetStateAction } from 'react';

const PDFCreditorStatement = async (
    Creditor: Creditor,
    startDate: string,
    endDate: string,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    let accountStatementRows = null;
    await api
        .get(
            `creditorAccountStatement/${Creditor.id}?startDate=${startDate}&endDate=${endDate}`
        )
        .then((res) => {
            accountStatementRows = res.data;
        });

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Header for the invoice
    await InvoiceHeader(
        doc,
        parseInt(localStorage.getItem('SiteId')),
        'Account Statement',
        `Creditor #${Creditor.id}`,
        true,
        true,
        false,
        Creditor,
        null,
        String(new Date(Date.now()))
    );

    // Table continaing the payment lines
    let header = [
        [
            'Invoice ID',
            'Document Reference',
            'Document Total',
            'Amount Paid',
            'Balance',
            'Due Date'
        ]
    ];
    let body = [];

    accountStatementRows.rows.forEach((row) => {
        let line = [
            row.id,
            row.documentReference,
            CurrencyFormatter(row.documentTotal),
            CurrencyFormatter(row.amountPaid),
            CurrencyFormatter(row.documentTotal - row.amountPaid),
            row.dueDate ? DateFormatter(row.dueDate) : ''
        ];
        body.push(line);
    });

    let lastYcoordinates = 82;

    doc.setFont('helvetica', 'bold').setFontSize(12);

    if (startDate && endDate) {
        doc.text(
            'Invoices from ' + startDate + ' to ' + endDate,
            17,
            lastYcoordinates + 13
        );
    } else if (startDate) {
        doc.text('Invoices from ' + startDate, 17, lastYcoordinates + 13);
    } else if (endDate) {
        doc.text('Invoices to ' + endDate, 17, lastYcoordinates + 13);
    } else {
        doc.text('Invoices', 17, lastYcoordinates + 13);
    }

    doc.text(
        'Balance ' + CurrencyFormatter(accountStatementRows.balance),
        155,
        lastYcoordinates + 13
    );

    InvoiceTable(doc, lastYcoordinates + 18, header, body);
    setButtonLoading(false);
    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFCreditorStatement;
