import { BaseSyntheticEvent, Dispatch, SetStateAction } from 'react';
import {
  Grid,
  Pagination,
  TableRow,
  TextField
} from '@mui/material';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';

// COMPONENT
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';


// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';

interface MakeDialogContentProps {
  filterValue: string;
  filterListOfVehicles: Vehicle[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  handleFilterValueChange: (value: string, type: string) => void;
}

const MakeDrawerContent = ({
  filterValue,
  filterListOfVehicles,
  page,
  setPage,
  handleFilterValueChange
}: MakeDialogContentProps) => {
  const rowsPerPage = 20;
  const dialogColumns = [
    { id: 0, label: 'Stock Number' },
    { id: 1, label: 'Model' },
    { id: 2, label: 'Condition' },
    { id: 3, label: 'Cost Price' }
  ];

  const handleChangePage = (e: BaseSyntheticEvent, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            size="small"
            label="Model / Stock Number"
            value={filterValue}
            onChange={(e) => handleFilterValueChange(e.target.value, 'vehicle')}
          />
        </Grid>
        <Grid item xs={7} textAlign="right">
          <Pagination
            color="primary"
            count={
              filterListOfVehicles.length <= rowsPerPage
                ? 1
                : Math.ceil(filterListOfVehicles.length / rowsPerPage)
            }
            page={page}
            siblingCount={0}
            boundaryCount={1}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'right', marginTop: 0.5 }}
          />
        </Grid>
      </Grid>
      <br />
      <DataTable columns={dialogColumns}>
        {filterListOfVehicles
          .slice((page - 1) * rowsPerPage, page * rowsPerPage)
          .map((vehicle) => (
            <TableRow>
              <DataCellColoured
                handleClick={() =>
                  window.open(`vehicles/view/${vehicle.stockNumber}`, '_blank')
                }
              >
                {vehicle.stockNumber}
              </DataCellColoured>
              <DataCell>{vehicle.Model.name}</DataCell>
              <DataCell>{vehicle?.condition?.toUpperCase()}</DataCell>
              <DataCell>{CurrencyFormatter(vehicle.costPrice)}</DataCell>
            </TableRow>
          ))}
      </DataTable>
    </>
  );
};

export default MakeDrawerContent;
