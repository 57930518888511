//  LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Unit } from '../../../global/interfaces/PartsInterface';

interface HandleEditProps {
    partId: number;
    unitData: Unit;
    stockDetails: StockDetails;
    showSnackbar: showSnackbar;
}

interface StockDetails {
    minStock: number;
    maxStock: number;
}

const HandleEdit = ({
    partId,
    unitData,
    stockDetails,
    showSnackbar
}: HandleEditProps) => {
    let data = unitData;

    // Validate the data for missing or invalid data
    if (data.name === '' || data.name == null) {
        showSnackbar(
            'Invalid Name.',
            'Please enter a valid name for the part to continue.',
            'error'
        );
        return;
    } else if (data.SupplierIds == null || data.SupplierIds.length < 1) {
        showSnackbar(
            'Invalid Supplier.',

            'Please select at least one supplier for the part to continue.',
            'error'
        );
        return;
    }
    // else if (data.priceRRP == null || data.priceRRP <= 0) {
    //   setSnackbar({
    //     open: true,
    //     severity: 'error',
    //     message: 'Invalid RRP.',
    //     subMessage: 'Please enter a valid RRP for the part to continue.'
    //   });
    //   return;
    // } else if (data.costPriceDaily == null || data.costPriceDaily <= 0) {
    //   setSnackbar({
    //     open: true,
    //     severity: 'error',
    //     message: 'Invalid Daily Cost.',
    //     subMessage: 'Please enter a valid daily cost for the part to continue.'
    //   });
    //   return;
    // } else if (data.costPriceStock == null || data.costPriceStock <= 0) {
    //   setSnackbar({
    //     open: true,
    //     severity: 'error',
    //     message: 'Invalid Stock Cost.',
    //     subMessage: 'Please enter a valid stock cost for the part to continue.'
    //   });
    //   return;
    // }
    else {
        data.name = data.name.toUpperCase(); // Make the name upper case for databse

        api.put(`inventory/${partId}`, {
            unitDetails: unitData,
            stockDetails: stockDetails,
            SiteId: localStorage.getItem('SiteId')
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Part Successfully updated.',
                    'The page will now refresh.'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }
};

export default HandleEdit;
