import { Dispatch, SetStateAction } from "react"
import api from "../../../../api"
import { Ctp } from "../interfaces/VehicleSaleInterface"

const GetFutureNSWCtp = (setCtps: Dispatch<SetStateAction<Ctp[]>>) => {
    api.get('nswFutureCtp').then(res => {
        setCtps(res.data)
    })
}

export default GetFutureNSWCtp