// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {
    AccountBalance,
    CheckBox,
    CheckBoxOutlineBlank,
    Delete,
    DirectionsCar,
    Restore,
    Save
} from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
import Paper from '../../../global/Paper';
import Fab from '../../../global/Fab';
import SpeedDial from '../../../global/SpeedDial';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
// LOGIC
import GetAllAccounts from '../../../global/databaseLogic/GetAllAccounts';
import GetAllVehicles from '../../../global/databaseLogic/GetAllVehicles';
import GetManualTransaction from '../logic/GetManualTransaction';
import HandleUpdateManualTransaction from '../logic/HandleUpdateManualTransaction';
import GetAllAccountsNested from '../../../global/databaseLogic/GetAllAccountsNested';
import HandleAddItem from '../logic/HandleAddItem';
import HandleDeleteManualTransaction from '../logic/HandleDeleteManualTransaction';
import HandleUnreconcileTransaction from '../logic/HandleUnreconcileTransaction';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import HandleUnreconcileTransactionManualSelection from '../logic/HandleUnreconcileTransactionManualSelection';
// INTERFACES
import {
    Account,
    ManualTransaction
} from '../../../global/interfaces/AdminInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface HeaderProps {
    id: number;
    readOnly: boolean;
    setReadOnly: Dispatch<SetStateAction<boolean>>;
    manualTransactions;
    setManualTransactions;
    showSnackbar: showSnackbar;
}

const Header = ({
    id,
    manualTransactions,
    setManualTransactions,
    showSnackbar,
    readOnly,
    setReadOnly
}: HeaderProps) => {
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [selectedType, setSelectedType] = useState<string>('account');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [manualTransaction, setManualTransaction] =
        useState<ManualTransaction>({
            AccountId: null,
            Account: null,
            Vehicle: null,
            VehicleId: null,
            reference: null,
            dateReceived: null,
            debit: null,
            credit: null,
            gstType: 'N/T',
            reconciled: null
        });
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    // Unreconciliation selection dialog
    const [unreconciliationDialogOpen, setUnreconciliationDialogOpen] =
        useState<boolean>(false);
    const [reconciliationId, setReconciliationId] = useState<number>(null);
    const [bankLines, setBankLines] = useState<
        {
            BankTransactionId: number;
            amount: number;
            reference: string;
            date: string;
        }[]
    >([]);
    const [selectedBankLines, setSelectedBankLines] = useState<number[]>([]);

    useEffect(() => {
        if (id) {
            GetManualTransaction(id, setManualTransaction);
            setReadOnly(true);
            GetAllAccountsNested(setAccounts);
        } else {
            GetAllAccounts(setAccounts);
        }
        GetAllVehicles(setVehicles);
        // eslint-disable-next-line
    }, []);

    const getSelectedTotal = () => {
        let total = 0;
        for (let id of selectedBankLines) {
            let line = bankLines.find((x) => x.BankTransactionId === id);
            if (line != null) {
                total += line.amount;
            }
        }
        return total;
    };

    const checkAmountMatch = () => {
        let totalSelected = getSelectedTotal();
        if (manualTransaction.debit > 0) {
            return (
                Math.abs(totalSelected) === Math.abs(manualTransaction.debit)
            );
        } else {
            return (
                Math.abs(totalSelected) === Math.abs(manualTransaction.credit)
            );
        }
    };

    const actionsList = [
        {
            id: 0,
            icon: <Save />,
            name: 'Save',
            onClick: () =>
                HandleUpdateManualTransaction(
                    id,
                    manualTransaction,
                    selectedType,
                    showSnackbar
                )
        },
        {
            id: 1,
            icon: <Restore />,
            name: 'Unreconcile',
            noDisplay: !manualTransaction.reconciled,
            onClick: () => setDialogOpen(true),
            permission: 'unreconcileTransaction'
        },
        {
            id: 2,
            icon: <Delete />,
            name: 'Delete',
            noDisplay: manualTransaction.reconciled,
            onClick: () => setDeleteDialogOpen(true)
        }
    ];

    const gstTypes = [
        { value: 'G1' },
        { value: 'G2' },
        { value: 'G3' },
        { value: 'G10' },
        { value: 'G11' },
        { value: 'N/T' }
    ];

    const columns = [
        { id: 0, label: '' },
        { id: 1, label: 'Bank Transaction ID' },
        { id: 2, label: 'Reference' },
        { id: 3, label: 'Amount' },
        { id: 4, label: 'Date' }
    ];

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3} textAlign="right">
                        {selectedType === 'account' ||
                        manualTransaction.AccountId ? (
                            id == null ? (
                                <Autocomplete
                                    isDisabled={id ? true : false}
                                    size="small"
                                    options={accounts}
                                    useTwoOptionLabels={true}
                                    primaryOptionLabel="name"
                                    secondaryOptionLabel={
                                        id ? 'Site' : 'siteName'
                                    }
                                    useNestedObject={id ? true : false}
                                    secondaryOptionLabelNest={'name'}
                                    textfieldLabel="Account"
                                    selectedValue={manualTransaction?.Account}
                                    handleSelectedValueChange={(newValue) =>
                                        setManualTransaction({
                                            ...manualTransaction,
                                            Account: newValue,
                                            AccountId: newValue.id
                                        })
                                    }
                                    handleInputValueChange={() => null}
                                    includeTextfieldIcon={true}
                                    // textfieldIcon={
                                    //     <Tooltip title="Swap to Vehicle">
                                    //         <IconButton
                                    //             size="small"
                                    //             disabled={id ? true : false}
                                    //             onClick={() => {
                                    //                 setSelectedType('vehicle');
                                    //                 setManualTransaction({
                                    //                     ...manualTransaction,
                                    //                     AccountId: null,
                                    //                     Account: null
                                    //                 });
                                    //             }}
                                    //         >
                                    //             <AccountBalance fontSize="small" />
                                    //         </IconButton>
                                    //     </Tooltip>
                                    // }
                                />
                            ) : (
                                <Autocomplete
                                    isDisabled={readOnly}
                                    size="small"
                                    options={accounts}
                                    useTwoOptionLabels={true}
                                    primaryOptionLabel="name"
                                    secondaryOptionLabel="siteName"
                                    textfieldLabel="Account"
                                    selectedValue={manualTransaction?.Account}
                                    handleSelectedValueChange={(newValue) =>
                                        setManualTransaction({
                                            ...manualTransaction,
                                            Account: newValue,
                                            AccountId: newValue.id
                                        })
                                    }
                                    handleInputValueChange={() => null}
                                    includeTextfieldIcon={true}
                                    textfieldIcon={
                                        <Tooltip title="Swap to Vehicle">
                                            <IconButton
                                                size="small"
                                                disabled={id ? true : false}
                                                onClick={() => {
                                                    setSelectedType('vehicle');
                                                    setManualTransaction({
                                                        ...manualTransaction,
                                                        AccountId: null,
                                                        Account: null
                                                    });
                                                }}
                                            >
                                                <AccountBalance fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                            )
                        ) : (
                            <Autocomplete
                                isDisabled={id ? true : false}
                                size="small"
                                options={vehicles}
                                useTwoOptionLabels={true}
                                primaryOptionLabel="stockNumber"
                                secondaryOptionLabel={'vin'}
                                textfieldLabel="Vehicle"
                                selectedValue={manualTransaction?.Vehicle}
                                handleSelectedValueChange={(newValue) =>
                                    setManualTransaction({
                                        ...manualTransaction,
                                        Vehicle: newValue,
                                        VehicleId: newValue.id
                                    })
                                }
                                handleInputValueChange={() => null}
                                includeTextfieldIcon={true}
                                textfieldIcon={
                                    <Tooltip title="Swap to Account">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedType('account');
                                                setManualTransaction({
                                                    ...manualTransaction,
                                                    VehicleId: null,
                                                    Vehicle: null
                                                });
                                            }}
                                        >
                                            <DirectionsCar fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                        )}
                    </Grid>
                    <Grid item xs={2.5}>
                        <TextField
                            disabled={readOnly}
                            label="Reference"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            fullWidth
                            value={manualTransaction?.reference}
                            onChange={(e) =>
                                setManualTransaction({
                                    ...manualTransaction,
                                    reference: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={readOnly}
                                format="DD/MM/YYYY"
                                label="Date Received"
                                value={Dayjs(manualTransaction?.dateReceived)}
                                onChange={(newValue) => {
                                    setManualTransaction({
                                        ...manualTransaction,
                                        dateReceived:
                                            Dayjs(newValue).toISOString()
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            disabled={
                                manualTransaction?.credit > 0 ||
                                readOnly ||
                                manualTransaction.reconciled
                                    ? true
                                    : false
                            }
                            label="Debit Amount"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            type="number"
                            fullWidth
                            helperText={
                                manualTransaction.reconciled
                                    ? 'This transaction has been reconciled'
                                    : ''
                            }
                            value={manualTransaction?.debit}
                            onChange={(e) =>
                                setManualTransaction({
                                    ...manualTransaction,
                                    debit: parseFloat(e.target.value)
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            disabled={
                                manualTransaction?.debit > 0 ||
                                readOnly ||
                                manualTransaction.reconciled
                                    ? true
                                    : false
                            }
                            label="Credit Amount"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            type="number"
                            fullWidth
                            value={manualTransaction?.credit}
                            onChange={(e) =>
                                setManualTransaction({
                                    ...manualTransaction,
                                    credit: parseFloat(e.target.value)
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            disabled={readOnly}
                            label="GST"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            fullWidth
                            select
                            value={manualTransaction?.gstType}
                            onChange={(e) =>
                                setManualTransaction({
                                    ...manualTransaction,
                                    gstType: e.target.value
                                })
                            }
                        >
                            {gstTypes.map((item) => (
                                <MenuItem value={item.value}>
                                    {item.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {!id ? (
                        <Grid item xs={12} textAlign="center">
                            <Button
                                variant="contained"
                                onClick={() =>
                                    HandleAddItem(
                                        selectedType,
                                        manualTransaction,
                                        manualTransactions,
                                        setManualTransactions,
                                        showSnackbar
                                    )
                                }
                            >
                                Add Line
                            </Button>
                        </Grid>
                    ) : !readOnly ? (
                        <SpeedDial actionsList={actionsList} />
                    ) : (
                        <Fab
                            editIcon={true}
                            onClick={() => setReadOnly(false)}
                        />
                    )}
                </Grid>
            </Paper>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Unreconcile Transaction?</DialogTitle>
                <DialogContent>
                    Are you sure you would like to unreconcile this transaction?
                    This cannot be undone.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Close</Button>
                    <LoadingButton
                        loading={buttonLoading}
                        variant="contained"
                        onClick={() =>
                            HandleUnreconcileTransaction(
                                id,
                                setButtonLoading,
                                setUnreconciliationDialogOpen,
                                setReconciliationId,
                                setBankLines,
                                showSnackbar
                            )
                        }
                    >
                        Unreconcile Transaction
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Delete Transaction?</DialogTitle>
                <DialogContent>
                    Are you sure you would like to delete this transaction? This
                    cannot be undone.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Close</Button>
                    <LoadingButton
                        loading={buttonLoading}
                        variant="contained"
                        onClick={() =>
                            HandleDeleteManualTransaction(
                                id,
                                manualTransaction,
                                setButtonLoading,
                                showSnackbar
                            )
                        }
                    >
                        Unreconcile Transaction
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={unreconciliationDialogOpen}
                onClose={() => setUnreconciliationDialogOpen(false)}
                maxWidth="lg"
            >
                <DialogTitle>
                    Ora could not find a match for the Manual Transaction
                </DialogTitle>
                <DialogContent>
                    Please select the line(s) corresponding to the Manual
                    Transaction.
                    <br />
                    <Typography
                        variant="body1"
                        style={{ color: checkAmountMatch() ? 'green' : 'red' }}
                    >
                        MT Amount:{' '}
                        {manualTransaction.debit > 0
                            ? CurrencyFormatter(manualTransaction.debit)
                            : CurrencyFormatter(manualTransaction.credit)}
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{ color: checkAmountMatch() ? 'green' : 'red' }}
                    >
                        Selected bank transactions total:{' '}
                        {CurrencyFormatter(Math.abs(getSelectedTotal()))}
                    </Typography>
                    <DataTable columns={columns}>
                        {bankLines.map((line) => (
                            <TableRow>
                                <DataCell>
                                    {selectedBankLines.includes(
                                        line.BankTransactionId
                                    ) ? (
                                        <IconButton
                                            onClick={() => {
                                                let newSelectedLines = [
                                                    ...selectedBankLines.filter(
                                                        (x) =>
                                                            x !==
                                                            line.BankTransactionId
                                                    )
                                                ];
                                                setSelectedBankLines(
                                                    newSelectedLines
                                                );
                                            }}
                                        >
                                            <CheckBox />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            onClick={() => {
                                                let newSelectedLines = [
                                                    ...selectedBankLines
                                                ];
                                                newSelectedLines.push(
                                                    line.BankTransactionId
                                                );
                                                setSelectedBankLines(
                                                    newSelectedLines
                                                );
                                            }}
                                        >
                                            <CheckBoxOutlineBlank />
                                        </IconButton>
                                    )}
                                </DataCell>
                                <DataCell>{line.BankTransactionId}</DataCell>
                                <DataCell>{line.reference}</DataCell>
                                <DataCell>{line.amount}</DataCell>
                                <DataCell>{line.date}</DataCell>
                            </TableRow>
                        ))}
                    </DataTable>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setUnreconciliationDialogOpen(false)}
                    >
                        CLOSE
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!checkAmountMatch()}
                        onClick={() =>
                            HandleUnreconcileTransactionManualSelection(
                                manualTransaction.id,
                                reconciliationId,
                                selectedBankLines,
                                showSnackbar
                            )
                        }
                    >
                        Reconcile
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Header;
