import api from '../../../../../../api';

const RefreshTransactions = (
    reconciliationDetails,
    reconciliationDetailsUnltered,
    setReconciliationDetailsUnaltered,
    setLoading
) => {
    setLoading(true);
    api.get('bankReconciliationTransactionsNextGenNew').then((res) => {
        // Save the current reconciled transactions
        let currentOraReconciled = [
            ...reconciliationDetails.oraTransactionsReconciled
        ];
        let currentBankReconciled = [
            ...reconciliationDetails.bankTransactionsReconciled
        ];

        let oraTransactionsUnreconciled = [];
        let bankTransactionsUnreconciled = [];

        // Put all the new transactions into unreconciled
        res.data.formattedTillReconciliations.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedManualTransactions.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedTrustReceipts.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedDebtorPayments.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedTradeIns.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedVehicleDirectDeposits.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedServiceDirectDeposits.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedPartsDirectDeposits.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedPaymentRuns.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedStripePayments.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedServiceWarranties.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedVehicleRebates.forEach((item) => {
            oraTransactionsUnreconciled.push(item);
        });
        res.data.formattedBankTransactions.forEach((item) => {
            bankTransactionsUnreconciled.push(item);
        });

        // Now go through the reconciled transactions and remove them from unreconciled
        for (let item of currentOraReconciled) {
            oraTransactionsUnreconciled = oraTransactionsUnreconciled.filter(
                (x) => x !== item
            );
        }

        // Do the same for bank transactions
        for (let item of currentBankReconciled) {
            bankTransactionsUnreconciled = bankTransactionsUnreconciled.filter(
                (x) => x.BankTransactionId !== item.BankTransactionId
            );
        }

        setReconciliationDetailsUnaltered({
            ...reconciliationDetailsUnltered,
            oraTransactionsReconciled: currentOraReconciled,
            oraTransactionsUnreconciled: oraTransactionsUnreconciled,
            bankTransactionsReconciled: currentBankReconciled,
            bankTransactionsUnreconciled: bankTransactionsUnreconciled
        });

        setLoading(false);
    });
};

export default RefreshTransactions;
