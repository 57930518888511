import * as yup from 'yup';
import { Settings } from '../../../OraNextGen/global/interfaces/GeneralInterface';
import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../OraNextGen/global/interfaces/GlobalInterface';

//site validate schema
let companyError = {
    companyName: {
        error: false,
        message: ''
    },
    legalEntityName: {
        error: false,
        message: ''
    },
    abn: {
        error: false,
        message: ''
    },
    acn: {
        error: false,
        message: ''
    },
    directDepositAccountName: {
        error: false,
        message: ''
    },
    directDepositAccountBsb: {
        error: false,
        message: ''
    },
    directDepositAccountNumber: {
        error: false,
        message: ''
    },
    lmd: {
        error: false,
        message: ''
    },
    logoUrl: {
        error: false,
        message: ''
    }
};
const companySchema = yup.object().shape({
    companyName: yup.string().required(() => {
        companyError = {
            ...companyError,
            companyName: { error: true, message: 'Company Name is required.' }
        };
        return 'Company Name is required.';
    }),
    legalEntityName: yup.string().required(() => {
        companyError = {
            ...companyError,
            legalEntityName: {
                error: true,
                message: 'Legal Entity Name is required.'
            }
        };
        return 'Legal Entity Name is required.';
    }),
    abn: yup
        .string()
        .required(() => {
            companyError = {
                ...companyError,
                abn: { error: true, message: 'ABN is required.' }
            };
            return 'ABN is required.';
        })
        .test('isValidABN', 'Invalid ABN.', (value, ctx) => {
            let abn = value.replaceAll(' ', '');
            // Is the ABN 11 characters long?
            if (abn.length !== 11) {
                companyError = {
                    ...companyError,
                    abn: {
                        error: true,
                        message: 'ABN must contain exactly 11 digits.'
                    }
                };
                return ctx.createError({
                    message: 'ABN must contain exactly 11 digits.'
                });
            }

            // Is the ABN a number?
            if (!Number(abn)) {
                companyError = {
                    ...companyError,
                    abn: {
                        error: true,
                        message: 'ABN must only contain numerical characters.'
                    }
                };
                return ctx.createError({
                    message: 'ABN must only contain numerical characters.'
                });
            }

            // Is the first digit NOT 0?
            let firstCharacter = abn.slice(0, 1);
            if (firstCharacter === '0') {
                companyError = {
                    ...companyError,
                    abn: {
                        error: true,
                        message: 'The first digit of an ABN cannot be 0.'
                    }
                };
                return ctx.createError({
                    message: 'The first digit of an ABN cannot be 0.'
                });
            }

            // 1. Subtract 1 from the first character in the string
            let newFirstCharacter = Number(firstCharacter) - 1;

            // 2. Form the new ABN
            let theRest = abn.slice(1);
            let newABN = newFirstCharacter + theRest;

            // 3. Multiply each number in the new ABN by its weighting factor
            // and then sum it
            let weightingFactors = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
            let summedNumbers = 0;

            for (let i = 0; i < newABN.length; i++) {
                let multipliedDigit =
                    Number(newABN.charAt(i)) * weightingFactors[i];
                summedNumbers += multipliedDigit;
            }

            // 4. Check if the summed numbers / 89 gives a remainder of 0
            if (summedNumbers % 89 !== 0) {
                companyError = {
                    ...companyError,
                    abn: { error: true, message: 'ABN failed verification.' }
                };
                return ctx.createError({
                    message: 'ABN failed verification.'
                });
            }
            return true;
        }),
    acn: yup.string().test('isValidACN', 'Invalid ACN', (value, ctx) => {
        if (value && value.length > 0) {
            let acn = value.replaceAll(/\s/g, '');

            // PRE-VALIDATION CHECKS: Check that the input satisfies the basic ACN characteristics
            // Is the ACN 9 characters long?
            if (acn.length !== 9) {
                companyError = {
                    ...companyError,
                    acn: {
                        error: true,
                        message: 'ACN must contain exactly 9 digits.'
                    }
                };
                return ctx.createError({
                    message: 'ACN must contain exactly 9 digits.'
                });
            }

            // Is the ACN a number?
            else if (!Number(acn)) {
                companyError = {
                    ...companyError,
                    acn: {
                        error: true,
                        message: 'ACN mmust only contain numeric characters.'
                    }
                };
                return ctx.createError({
                    message: 'ACN mmust only contain numeric characters.'
                });
            }

            // 1. Multiple the first 8 digits by their "weighting factor"
            // 2. Add the multiplied numbers together
            let weightingFactors = [8, 7, 6, 5, 4, 3, 2, 1];
            let summedNumbers = 0;

            for (let i = 0; i < acn.length - 1; i++) {
                let multipliedDigit =
                    Number(acn.charAt(i)) * weightingFactors[i];
                summedNumbers += multipliedDigit;
            }

            // 3. Divide the summed number by 10 and take the remainder
            let sumDivision = (summedNumbers / 10).toString();
            let calculatedCheckDigit = null;

            // If there's a remainder, subtract it from 10
            if (sumDivision.includes('.')) {
                let remainder: string | number = sumDivision.substring(
                    sumDivision.indexOf('.') + 1
                );
                remainder = parseInt(remainder[0]);

                calculatedCheckDigit = (10 - remainder).toString();
            }

            // If there's no remainder, make the calculated check digit 0
            else {
                calculatedCheckDigit = '0';
            }

            // If the calculated check digit matches the last
            // character in the ACN, then the ACN is valid
            if (calculatedCheckDigit !== acn[8]) {
                companyError = {
                    ...companyError,
                    acn: { error: true, message: 'ACN failed validation.' }
                };
                return ctx.createError({
                    message: 'ACN failed validation.'
                });
            }

            return true;
        }
        return true;
    }),
    directDepositAccountName: yup.string(),
    directDepositAccountBsb: yup
        .string()
        .test('isValidBSB', 'Invalid BSB', (value, ctx) => {
            if (value && value.length > 0) {
                let bsb = value;

                // Is the BSB in the correct format?
                if (bsb.includes('-') || bsb.includes(' ')) {
                    if (bsb.charAt(3) !== '-' && bsb.charAt(3) !== ' ') {
                        companyError = {
                            ...companyError,
                            directDepositAccountBsb: {
                                error: true,
                                message:
                                    'BSB must follow the format "xxxxxx" or "xxx-xxx".'
                            }
                        };
                        return ctx.createError({
                            message:
                                'BSB must follow the format "xxxxxx" or "xxx-xxx".'
                        });
                    }
                }

                bsb = bsb.replaceAll(/-/g, ''); // Strip hyphen
                bsb = bsb.replaceAll(/\s/g, ''); // Strip whitespaces

                // Is the BSB 6 characters long?
                if (bsb.length !== 6) {
                    companyError = {
                        ...companyError,
                        directDepositAccountBsb: {
                            error: true,
                            message: 'BSB must contain exactly 6 characters.'
                        }
                    };
                    return ctx.createError({
                        message: 'BSB must contain exactly 6 characters.'
                    });
                }

                // Is BSB a valid number?
                else if (bsb.includes('e') || bsb.includes('.')) {
                    companyError = {
                        ...companyError,
                        directDepositAccountBsb: {
                            error: true,
                            message: 'BSB must only include numeric characters.'
                        }
                    };
                    return ctx.createError({
                        message: 'BSB must only include numeric characters.'
                    });
                } else if (!Number(bsb)) {
                    companyError = {
                        ...companyError,
                        directDepositAccountBsb: {
                            error: true,
                            message: 'BSB must be a valid number.'
                        }
                    };
                    return ctx.createError({
                        message: 'BSB must be a valid number.'
                    });
                }
                return true;
            }
            return true;
        }),
    directDepositAccountNumber: yup
        .string()
        .test(
            'isValidAccountNumber',
            'Invalid Account Number',
            (value, ctx) => {
                if (value && value.length > 0) {
                    let accountNumber = value.replaceAll(/-/g, ''); // Strip hyphen
                    accountNumber = accountNumber.replaceAll(/\s/g, ''); // Strip whitespaces

                    // Does the account number contain any valid non-numeric number characters?
                    if (
                        accountNumber.includes('e') ||
                        accountNumber.includes('.')
                    ) {
                        companyError = {
                            ...companyError,
                            directDepositAccountNumber: {
                                error: true,
                                message:
                                    'Bank account number must only include numeric characters.'
                            }
                        };
                        return ctx.createError({
                            message:
                                'Bank account number must only include numeric characters.'
                        });
                    }

                    // Is account number a valid number?
                    if (!Number(accountNumber)) {
                        companyError = {
                            ...companyError,
                            directDepositAccountNumber: {
                                error: true,
                                message:
                                    'Bank account number must only contain numeric characters.'
                            }
                        };
                        return ctx.createError({
                            message:
                                'Bank account number must only contain numeric characters.'
                        });
                    }
                    return true;
                }
                return true;
            }
        ),
    lmd: yup.string().test('isValidLMD', 'Invalid LMD', (value, ctx) => {
        let lmd = value;
        if (lmd && lmd.length > 0) {
            if (lmd.includes('e') || lmd.includes('.') || lmd.includes('-')) {
                companyError = {
                    ...companyError,
                    lmd: {
                        error: true,
                        message: 'LMD must only include numeric characters.'
                    }
                };
                return ctx.createError({
                    message: 'LMD must only include numeric characters.'
                });
            } else if (!Number(lmd)) {
                companyError = {
                    ...companyError,
                    lmd: { error: true, message: 'LMD must be a valid number' }
                };
                return ctx.createError({
                    message: 'LMD must be a valid number'
                });
            }
            return true;
        }
        return true;
    }),
    logoUrl: yup.string()
});

// Combine the schemas for the complete validation
const validationSchema = yup.object().shape({
    company: companySchema
});

const ValidateStep1 = async (
    company: Settings,
    setActiveStep: Dispatch<SetStateAction<number>>,
    setStepValidation,
    showSnackbar: showSnackbar
) => {
    try {
        await validationSchema.validate({ company }, { abortEarly: false });
        setStepValidation(companyError);
        setActiveStep(1);
    } catch (error) {
        setStepValidation(companyError);
        showSnackbar(
            error.errors.join('\n'),
            'Please correct the errors listed above.',
            'error'
        );
        return {
            verified: false,
            message: error.errors.join('\n'),
            subMessage: 'Please correct the errors listed above.'
        };
    }
};

export default ValidateStep1;
