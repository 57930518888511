import DocumentationPage from '../DocumentationPage';
import {
    AddNewCustomerSaleSteps,
    AddNewVehicleContractSalesSteps
} from '../data/parts/newSale';

const NewSaleSupportPage = () => {
    const contentSections = [
        {
            id: 'add-customer-part-sale',
            title: 'Create a new Parts & Accessories Sale',
            href: '/support/inventory/newSale/#add-customer-part-sale',
            description:
                'To add a new parts sale for a customer, follow these steps:',
            steps: AddNewCustomerSaleSteps
        },
        {
            id: 'add-vehicle-contract-part-sale',
            title: 'Create a new Parts & Accessories Sale for a Vehicle Sale',
            href: '/support/inventory/newSale/#add-vehicle-contract-part-sale',
            description:
                'To create a new Parts & Accessories Sale for an existing Vehicle Sale (to attach accessories to a vehicle sale for example), follow these steps:',
            steps: AddNewVehicleContractSalesSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Parts Sales"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default NewSaleSupportPage;
