import { Step } from '../../interface';

export const AddPartSteps: Step[] = [
    {
        label: `Click 'Inventory' in the sidebar.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/add-part-01.png'
    },
    {
        label: `Hover the speed dial and click 'Add Part' to open the Add Part page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/add-part-02.png'
    },
    {
        label: `Complete all fields and then Click 'Add Part'.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/add-part-03.png'
    },
    {
        label: `If there are invalid or missing fields, an error message will be displayed the necessary corrections. After adding the part, a confirmation message 'Part added successfully' will appear.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/add-part-04.png'
    }
];

export const DeletePartSteps: Step[] = [
    {
        label: `Click the Part ID to open the View Part Page. `,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-01.png'
    },
    {
        label: `On the View Part page, click the 'Delete Part' button in the speed dial menu. A confirmation dialog will appear.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/delete-part-02.png'
    },
    {
        label: `Click 'Delete Part' button if wish to contine, be aware that the delete action cannot be undone and all data linked with the part will be lost.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/delete-part-03.png'
    }
];

export const TransferPartSteps: Step[] = [
    {
        label: "You can transfer a part in the View Part page or the Stock Transfer page. Let's start with the first one, which is in the View Part page. Click the Part ID to open the View Part Page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-01.png'
    },
    {
        label: "On the View Part page, click the 'Transfer Part' button in the speed dial menu. A Stock Transfer drawer will appear.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/transfer-part-02.png'
    },
    {
        label: 'In the Stock Transfer drawer, select which site to transfer the part from, and which site the part is transferring to, fill in the Qty Sent field. The quantity must be a positive integer, then submit the transfer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/transfer-part-03.png'
    },
    {
        label: 'You can always view the transfer history later. Click the Transfer Out or Transfer In button in the Part Details. This will open a drawer showing details of the transfer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/transfer-part-04.png'
    },
    {
        label: "Another way to transfer stock is to go to the 'Stock Control' in the sidebar, click 'Stock Transfers'. This will open the Stock Transfer page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/transfer-part-05.png'
    },
    {
        label: "Click the 'New Stock Transfer' button in speed dial menu. This will open the Stock Transfer drawer. Then follow the same steps as above to create a new stock transfer.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/transfer-part-06.png'
    }
];

export const UpdatePartSteps: Step[] = [
    {
        label: `Click the Part ID to open the part details page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-01.png'
    },
    {
        label: `Now you are viewing the part details on your current site. To view inventory from other sites, select the desired site. `,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-02.png'
    },
    {
        label: `Note: Parts from other sites are read-only. To edit them you need to switch to this site first.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-03.png'
    },
    {
        label: ` To update part details, click the 'Edit Part Details' button.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-04.png'
    },
    {
        label: `In the Details table, changes to general settings (e.g., Supercession, Name, Supplier Barcode, Brand, Fluid) apply across all sites, while site-specific settings (e.g., Bin Location, Supplier, Primary Supplier) apply only to the current site. `,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-05.png'
    },
    {
        label: `Begin with the Supercession field: Supercession indicates that a supplier has discontinued the part or replaced it with a new one. For instance, for part #0123456789, input the superseding part number 2D0005714 in the Supersession field, indicating that #0123456789 has been replaced by 2D0005714.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-06.png'
    },
    {
        label: `When a part is superceded, adding the original part to an order will trigger a dialog warning about the supersession, allowing you to select the new part. `,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-07.png'
    },
    {
        label: `You can indicate that a part is a fluid by using the "Fluid" switch, if on a 'Volume' field appears, commonly used for specifying oil volumes. In the example below, setting the volume as 2000mL means that when invoicing a quantity of 1 for this part you actually invoiced 2L. You can uses this to invoice drums and let Ora handle the actual quantity.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-08.png'
    },
    {
        label: `For parts created at a specific site, the Bin Location defaults to the site's default bin. In this example, I created Part 0123456789 at Site Windsor. When you view the part details on the Windsor site, you will notice that its Bin Location is set to 'BUYIN,' which is the default bin for Site Windsor.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-09.png'
    },
    {
        label: `When viewed at other sites, the Bin Location field may be empty. For example, if you switch to the Ipswich site, you will observe that the Bin Location field is empty for Ipswich. Here you can add more bin locations for this part in Windsor.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-10.png'
    },
    {
        label: `In the Supplier field, you can view suppliers for all sites but only edit those supplying the current site. Ensure each part has at least one supplier.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-11.png'
    },
    {
        label: `In the Primary Supplier field, toggle on to select a primary supplier from the list of suppliers to the current site.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-12.png'
    },
    {
        label: `When adding a part with multiple suppliers to an orderline, a dialog will appear allowing you to choose a supplier. The primary supplier is marked as 'Primary'.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-13.png'
    },
    {
        label: `The Lowest Pricing table displays the lowest prices from all suppliers across all sites for RRP/Daily Cost/Stock Cost/Trade Stock.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-14.png'
    },
    {
        label: `Under the Supplier(s) Pricing tab, view and update pricing for each supplier.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-15.png'
    },
    {
        label: `The Stock On Hand table shows stock levels for the selected site and allows you to set minimum and maximum stock levels.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-16.png'
    },
    {
        label: `Under the Proformas / Stocktakes / Movement tab, view all associated records for the selected site. These records are read-only.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-17.png'
    },
    {
        label: `After making necessary changes, click 'Save'. A confirmation message 'Part updated successfully' will be displayed.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-18.png'
    }
];

export const SearchPartsSteps: Step[] = [
    {
        label: `To search inventory, enter a part ID or part number in the Search Inventory text field and press Enter. Results will appear below.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/search-part-01.png'
    }
];

export const MergePartsSteps: Step[] = [
    {
        label: `Click the 'Merge Parts' icon in the speed dial menu. This action will open a merge part dialog.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/merge-part-01.png'
    },
    {
        label: `First, identify which part you want to delete by searching for the part name or barcode (e.g., #0123456789), and press Enter. Repeat this process for the 'Part to merge into' field (e.g., #9876543210). The part details will be displayed accordingly.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/merge-part-02.png'
    },
    {
        label: `Click the 'Merge Parts' button. A warning message will appear: 'You are about to merge part 0123456789 into part 9876543210. If you are sure you selected the correct parts, click the Merge Parts button again.' Confirm the merge by clicking the 'Merge Parts' button again.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/merge-part-03.png'
    },
    {
        label: `After the merge, all associated data (such as stock transfers and orders) from part #0123456789 will be transferred to part #9876543210. A confirmation message 'Parts successfully merged.' will appear.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/merge-part-04.png'
    }
];

export const ExportPartsSteps: Step[] = [
    {
        label: `To export all part results to a CSV file, click 'Export Current Results' from speed dial menu, the results will automatically download to your device.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/export-part-01.png'
    },
    {
        label: `To export parts that have a negative stock into a CSV file, click 'Negative Stock Report' from speed dial menu, the results will automatically download to your device. `,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/export-part-02.png'
    }
];

export const ImportSupplierFile: Step[] = [
    {
        label: "To begin importing supplier files, select 'Import Supplier Files' in the speed dial menu to open a new page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-01.png'
    },
    {
        label: 'Choose from three options: Update Existing Parts Only, Add New Parts Only, or Both. Each option requires different fields. Let us start with the option Update Existing Parts Only.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-02.png'
    },
    {
        label: 'Select a supplier. If the supplier is already linked to the part, it will update; otherwise, it will add the supplier as new.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-03.png'
    },
    {
        label: "Click 'UPLOAD CSV/XLSX' to choose a file from your device.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-04.png'
    },
    {
        label: 'Preview the first and last ten rows of your file. Remove unwanted rows by clicking the X icon.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-05.png'
    },
    {
        label: "Select the column headers to define which part fields to update. For 'Update Existing Parts Only', select Part Number and at least one additional field. Here I selected  (Exc. GST).",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-06.png'
    },
    {
        label: 'For clarity, using part CR2 as an example, you can see its current supplier and RRP in the Lowest Pricing table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-07.png'
    },
    {
        label: "Press 'Upload File' to start the import. Error messages will alert you to any missing fields.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-08.png'
    },
    {
        label: 'Upon successful import, a message will confirm the update and provide details of the changes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-09.png'
    },
    {
        label: 'Post-import, view the updated details for CR2, now including an additional supplier and updated RRP.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-10.png'
    },
    {
        label: "For 'Add New Parts Only' and 'Update Existing Parts and Add New Parts', select additional headers such as Part Number, Name, and Cost Prices. These fields are necessary for creating new parts or updating existing ones.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/import-supplier-file-11.png'
    }
];

export const StockAdjustments: Step[] = [
    {
        label: `To manually adjust stock for a specific part, navigate to the part details page and click 'Manually Adjust Stock' in the speed dial menu.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/adjust-part-stock-01.png'
    },
    {
        label: `Fill in all required fields, including the 'New Stock Level', which is the updated quantity of parts in the bin, and specify the reason for the adjustment.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/adjust-part-stock-02.png'
    },
    {
        label: `If 'Adjust Average Cost' is not checked, the current average cost will remain unchanged. If it is checked, a new average cost will be calculated based on the new stock level.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/adjust-part-stock-03.png'
    },
    {
        label: `To view the history of all stock adjustments, click 'Stock Adjustments' in the speed dial menu on the Inventory Page. This action will direct you to the Stock Adjustments page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/adjust-part-stock-04.png'
    }
];

export const BinLocations: Step[] = [
    {
        label: `To set up a default bin for a specific site, navigate to the inventory page and click 'Bin Locations' in the speed dial menu. This action will open a bin location drawer.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/bin-location-01.png'
    },
    {
        label: `You can see the current default bin for the site you are logged in at is marked as 'default'. In this example, the bin is labeled 'BUYIN'.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/bin-location-02.png'
    },
    {
        label: `To change the default bin location, locate the bin you wish to set as default, 'BOO1' in this example, and click the 'Swap' icon next to it.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/bin-location-03.png'
    },
    {
        label: `A warning dialog will appear, stating 'All new parts created with no specified bin location will be sorted into this default bin.' Click the 'CHANGE DEFAULT' button if you wish to proceed.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/bin-location-04.png'
    },
    {
        label: `After confirming, you will see that 'BOO1' becomes the new default bin.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/bin-location-05.png'
    },
    {
        label: `To delete a bin, click the 'Delete' icon next to it.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/bin-location-06.png'
    },
    {
        label: `A warning dialog will appear, stating 'All parts assigned to this bin location will be removed from it. This action cannot be undone.' Click the 'DELETE' button if you wish to proceed.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/bin-location-07.png'
    }
];
