// LOGIC
import api from '../../../../../api';

// Just refreshes the page for the user
const TriggerUpdateRefresh = () => {
  api.put('triggerUserRefresh').then((res) => {
    window.location.reload();
  });
};

export default TriggerUpdateRefresh;
