import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import SerieDrawerContent from './SerieComponent/SerieDrawerContent';
import AddSerieDialogContent from './SerieComponent/AddSerieDialogContent';
import TableSearch from '../../../global/tableComponents/TableSearch';
import SpeedDial from '../../../global/SpeedDial';
import Drawer from '../../../global/Drawer';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Paper from '../../../global/Paper';
import { withSnackbar } from '../../../global/WrappingSnackbar';
// LOGIC
import HandleSeriesIdClick from '../logic/HandleSeriesIdClick';
import EditSeriesDialogContent from './SerieComponent/EditSeriesDialogContent';
import DeleteSerie from '../logic/DeleteSerie';
// INTERFACES
import {
    Make,
    Model,
    Series,
    Vehicle
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
interface TempSeries extends Series {
    Make: Make;
    Model: Model;
    nbOfVehicle: number;
}

const clearedSort = ['$Make.name$', 'ASC'];

const SeriesTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [sort, setSort] = useState(clearedSort);
    const [seriesList, setSeriesList] = useState<TempSeries[]>([]);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedSerie, setSelectedSerie] = useState<TempSeries>();
    const [listOfVehicles, setListOfVehicles] = useState<Vehicle[]>([]);
    const [filterListOfVehicles, setFilterListOfVehicles] = useState<Vehicle[]>(
        []
    );
    const [filterValue, setFilterValue] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [addSeriesDialog, setAddSeriesDialog] = useState<boolean>(false);
    const [editSeriesDialog, setEditSeriesDialog] = useState<boolean>(false);
    const [deleteSeriesDialog, setDeleteSeriesDialog] =
        useState<boolean>(false);

    const columns = [
        { id: 1, label: 'Make', width: 250 },
        { id: 2, label: 'Model', width: 250 },
        { id: 3, label: 'Series', width: 250 },
        { id: 4, label: 'Vehicles Available', width: 150 },
        { id: 5, label: '', width: 10 },
        { id: 6, label: '', width: 10 }
    ];

    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Series',
            onClick: () => setAddSeriesDialog(true)
        }
    ];

    const handleRequestCreate = () => {
        let apiString = ``;
        return apiString;
    };

    const handleFilterValueChange = (value: string) => {
        setFilterValue(value.toUpperCase());
        setFilterListOfVehicles(
            listOfVehicles.filter((x) =>
                x.stockNumber.includes(value.toUpperCase())
            )
        );
        setPage(1);
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Series"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="seriesSpecificTable"
                setResultsList={setSeriesList}
            />

            <Paper>
                <DataTable columns={columns} sort={sort} setSort={setSort}>
                    {seriesList.map((series) => (
                        <TableRow>
                            <DataCell>{series?.Make?.name}</DataCell>
                            <DataCell>{series?.Model?.name}</DataCell>
                            <DataCell>{series.name}</DataCell>
                            <DataCellColoured
                                handleClick={() => {
                                    HandleSeriesIdClick(
                                        series,
                                        setSelectedSerie,
                                        setOpenDialog,
                                        setListOfVehicles,
                                        setFilterListOfVehicles,
                                        setFilterValue
                                    );
                                }}
                            >
                                {series.nbOfVehicle}
                            </DataCellColoured>
                            <DataCell align="right">
                                <Tooltip
                                    title="Edit Series Name"
                                    placement="top"
                                >
                                    <IconButton
                                        onClick={() => {
                                            setEditSeriesDialog(true);
                                            setSelectedSerie(series);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </DataCell>
                            <DataCell align="left">
                                {series.nbOfVehicle === 0 ? (
                                    <Tooltip
                                        title="Delete Series"
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setDeleteSeriesDialog(true);
                                                setSelectedSerie(series);
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={openDialog}
                setOpenDrawer={setOpenDialog}
                title={selectedSerie?.name + ' Vehicles'}
                subTitle=""
                width="40vw"
            >
                <SerieDrawerContent
                    filterValue={filterValue}
                    filterListOfVehicles={filterListOfVehicles}
                    page={page}
                    setPage={setPage}
                    handleFilterValueChange={handleFilterValueChange}
                />
            </Drawer>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={addSeriesDialog}
                onClose={() => {
                    setAddSeriesDialog(false);
                }}
            >
                <DialogTitle>Add New Series</DialogTitle>
                <AddSerieDialogContent showSnackbar={showSnackbar} />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={editSeriesDialog}
                onClose={() => {
                    setEditSeriesDialog(false);
                }}
            >
                <DialogTitle>Edit Series Name</DialogTitle>
                <EditSeriesDialogContent
                    selectedSeries={selectedSerie}
                    setSelectedSeries={setSelectedSerie}
                    showSnackbar={showSnackbar}
                />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={deleteSeriesDialog}
                onClose={() => {
                    setDeleteSeriesDialog(false);
                }}
            >
                <DialogTitle>Delete {selectedSerie?.name}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to delete the series{' '}
                        {selectedSerie?.name}?<br />
                        <b>This cannot be undone.</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleteSeriesDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            DeleteSerie(
                                selectedSerie,
                                showSnackbar,
                                setButtonLoading
                            )
                        }
                    >
                        Delete Model
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <SpeedDial actionsList={actionsList} />
        </>
    );
};
export default withSnackbar(SeriesTable);
