import { IconButton, Typography } from '@mui/material';
import SignatureIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, DragEvent, SetStateAction, useState } from 'react';
import NewAddSignatureDialog from '../NewAddSignatureDialog';
import TagWrapper from './TagWrapper';
import { Tag, TAG_ACTIONS } from '../../EsignInterface';

interface SignTagProps {
    action: string;
    tag?: Tag;
    fontSize?: string;
    iconSize?: string;
    hasLabel?: boolean;
    removeTag?: () => void;
    setTags?: Dispatch<SetStateAction<Tag[]>>;
}

const SignTag = ({
    action,
    tag,
    fontSize,
    iconSize,
    hasLabel,
    removeTag,
    setTags
}: SignTagProps) => {
    const isFillable = action === TAG_ACTIONS.FILL;
    const isResizable = action === TAG_ACTIONS.MODIFY;
    const isViewOnly = action === TAG_ACTIONS.VIEW;
    const shouldDisplayLabel =
        isViewOnly || isFillable || (isResizable && hasLabel);

    const dragStartHandler = (event: DragEvent<HTMLDivElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();

        // calculate the offset of the mouse cursor from the top left corner
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        // get the signature field size
        const width = rect.width;
        const height = rect.height;

        // set the data transfer object for dragging
        event.dataTransfer.setData('type', `signature`);
        event.dataTransfer.setData('offsetX', offsetX.toString());
        event.dataTransfer.setData('offsetY', offsetY.toString());
        event.dataTransfer.setData('width', width.toString());
        event.dataTransfer.setData('height', height.toString());

        // if tag is not undefined, means the tag is already in the canvas, set the tempId to the dataTransfer
        event.dataTransfer.setData(
            'tempId',
            tag !== undefined ? tag.tempId.toString() : ''
        );
    };

    // add signature
    const [openSignatureDialog, setOpenSignatureDialog] =
        useState<boolean>(false);
    const [signatureDataUrl, setSignatureDataUrl] = useState<string | null>(
        null
    );

    const handleOnClickSignatureTag = (tag: Tag) => {
        const localSigDataUrl = localStorage.getItem(tag.SignerId.toString());

        if (localSigDataUrl !== null && localSigDataUrl !== '') {
            setSignatureDataUrl(localSigDataUrl);

            setTags((prevTags) => {
                return prevTags.map((t) => {
                    if (t.id === tag.id) {
                        return { ...t, inputData: localSigDataUrl };
                    }
                    return t;
                });
            });
        } else {
            setOpenSignatureDialog(true);
        }
    };

    return (
        <>
            <TagWrapper
                isViewOnly={isViewOnly}
                isResizable={isResizable}
                dragStartHandler={dragStartHandler}
                onClick={
                    isFillable
                        ? () => handleOnClickSignatureTag(tag)
                        : undefined
                }
            >
                {signatureDataUrl ? (
                    <img
                        src={signatureDataUrl}
                        alt="signature"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain'
                        }}
                    />
                ) : (
                    <>
                        <SignatureIcon
                            sx={{
                                color: 'primary.main',
                                fontSize: iconSize
                            }}
                        />
                        {shouldDisplayLabel && (
                            <Typography
                                sx={{
                                    fontSize: fontSize,
                                    flexGrow: 0.8
                                }}
                            >
                                Signature
                            </Typography>
                        )}

                        {isResizable && removeTag && (
                            <IconButton
                                onClick={removeTag}
                                size="small"
                                sx={{
                                    color: 'primary.main'
                                }}
                            >
                                <CloseIcon sx={{ fontSize: fontSize }} />
                            </IconButton>
                        )}
                    </>
                )}
            </TagWrapper>
            <NewAddSignatureDialog
                openSignatureDialog={openSignatureDialog}
                setOpenSignatureDialog={setOpenSignatureDialog}
                tag={tag}
                name={tag?.Signer?.fullName}
                setSignatureDataUrl={setSignatureDataUrl}
                setTags={setTags}
            />
        </>
    );
};

export default SignTag;
