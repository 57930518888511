import { useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { Add, Archive, Delete, Edit } from '@mui/icons-material';
// COMPONENTS
import TableSearch from '../../../global/tableComponents/TableSearch';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import Drawer from '../../../global/Drawer';
import SpeedDial from '../../../global/SpeedDial';
import BrandDialogContent from './BrandComponent/BrandDialogContent';
import AddPartsToBrandDrawer from './BrandComponent/AddPartsToBrandDrawer';
import AddBrandDialogContent from './BrandComponent/AddBrandDialogContent';
import EditBrandDialogContent from './BrandComponent/EditBrandDialogContent';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { withSnackbar } from '../../../global/WrappingSnackbar';
// LOGIC
import HandleBrandClick from '../logic/HandleBrandClick';
import DeleteBrand from '../logic/DeleteBrand';
// INTERFACES
import { Brand, Unit } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import Paper from '../../../global/Paper';
interface TempBrand extends Brand {
    nbOfUnit: number;
}

const clearedSort = ['name', 'ASC'];

const BrandTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [sort, setSort] = useState(clearedSort);
    const [brandList, setBrandList] = useState<TempBrand[]>([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [openBrandDialog, setOpenBrandDialog] = useState<boolean>(false);
    const [selectedBrand, setSelectedBrand] = useState<TempBrand>();
    const [listOfUnit, setListOfUnit] = useState<Unit[]>([]);
    const [filterListOfUnit, setFilterListOfUnit] = useState<Unit[]>([]);
    const [filterValue, setFilterValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [openAddBrandDialog, setOpenAddBrandDialog] =
        useState<boolean>(false);
    const [openEditBrandDialog, setOpenEditBrandDialog] =
        useState<boolean>(false);
    const [openDeleteBrandDialog, setOpenDeleteBrandDialog] =
        useState<boolean>(false);
    const [openAddPartsToBrandDrawer, setOpenAddPartsToBrandDrawer] =
        useState<boolean>(false);

    const columns = [
        { id: 0, label: 'Brand', width: 250 },
        { id: 1, label: 'Number of Parts assigned', width: 250 },
        { id: 3, label: '', width: 10 },
        { id: 4, label: '', width: 10 }
    ];

    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Brand',
            onClick: () => setOpenAddBrandDialog(true)
        },
        {
            icon: <Archive />,
            name: 'Add Parts to a Brand',
            onClick: () => setOpenAddPartsToBrandDrawer(true)
        }
    ];

    const handleRequestCreate = () => {
        let apiString = ``;
        return apiString;
    };

    const handleFilterValueChange = (value: string) => {
        setFilterValue(value.toUpperCase());
        setFilterListOfUnit(
            listOfUnit.filter((x) => x.partNumber.includes(value.toUpperCase()))
        );
        setPage(1);
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Brand"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="brandSpecificTable"
                setResultsList={setBrandList}
            />

            <Paper>
                <DataTable columns={columns} sort={sort} setSort={setSort}>
                    {brandList.map((brand) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() => {
                                    HandleBrandClick(
                                        brand,
                                        setSelectedBrand,
                                        setOpenBrandDialog,
                                        setListOfUnit,
                                        setFilterListOfUnit,
                                        setFilterValue,
                                        setLoading
                                    );
                                }}
                            >
                                {brand.name}
                            </DataCellColoured>
                            <DataCell>{brand.nbOfUnit}</DataCell>
                            <DataCell align="right">
                                <Tooltip title="Edit Make Name" placement="top">
                                    <IconButton
                                        onClick={() => {
                                            setOpenEditBrandDialog(true);
                                            setSelectedBrand(brand);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </DataCell>
                            <DataCell align="left">
                                {brand.nbOfUnit === 0 ? (
                                    <Tooltip
                                        title="Delete Make"
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setOpenDeleteBrandDialog(true);
                                                setSelectedBrand(brand);
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            {loading ? (
                <Dialog open={loading}>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            ) : (
                <Drawer
                    openDrawer={openBrandDialog}
                    setOpenDrawer={setOpenBrandDialog}
                    title={'Parts in ' + selectedBrand?.name}
                    subTitle=""
                    width="40vw"
                >
                    <BrandDialogContent
                        selectedBrand={selectedBrand}
                        filterValue={filterValue}
                        filterListOfUnit={filterListOfUnit}
                        page={page}
                        setPage={setPage}
                        setSelectedBrand={setSelectedBrand}
                        handleFilterValueChange={handleFilterValueChange}
                    />
                </Drawer>
            )}

            <Drawer
                openDrawer={openAddPartsToBrandDrawer}
                setOpenDrawer={setOpenAddPartsToBrandDrawer}
                title={'Add Parts to a Brand'}
                subTitle=""
                width="40vw"
            >
                <AddPartsToBrandDrawer showSnackbar={showSnackbar} />
            </Drawer>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={openAddBrandDialog}
                onClose={() => {
                    setOpenAddBrandDialog(false);
                }}
            >
                <DialogTitle>Add New Brand</DialogTitle>
                <AddBrandDialogContent showSnackbar={showSnackbar} />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={openEditBrandDialog}
                onClose={() => {
                    setOpenEditBrandDialog(false);
                }}
            >
                <DialogTitle>Edit Make Name</DialogTitle>
                <EditBrandDialogContent
                    selectedBrand={selectedBrand}
                    setSelectedBrand={setSelectedBrand}
                    showSnackbar={showSnackbar}
                />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={openDeleteBrandDialog}
                onClose={() => {
                    setOpenDeleteBrandDialog(false);
                }}
            >
                <DialogTitle>Delete {selectedBrand?.name}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to delete the Brand{' '}
                        {selectedBrand?.name}?<br />
                        <b>This cannot be undone.</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDeleteBrandDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            DeleteBrand(
                                selectedBrand,
                                setButtonLoading,
                                showSnackbar
                            )
                        }
                    >
                        Delete Brand
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <SpeedDial actionsList={actionsList} />
        </>
    );
};
export default withSnackbar(BrandTable);
