import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";

const CheckCustomerPhoneNumber = (
    phoneNumber: string,
    setResponseCode: Dispatch<SetStateAction<number>>
) => {
    api.get(`checkPhoneNumber?phoneNumber=${phoneNumber}`).then((res) => {
        setResponseCode(res.status);
    });
};

export default CheckCustomerPhoneNumber;