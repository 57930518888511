import { DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

// COMPONENT
import Autocomplete from '../../../../global/Autocomplete';

// LOGIC
import GetMakes from '../../../../global/databaseLogic/GetMakes';
import GetModels from '../../../../global/databaseLogic/GetModels';

// INTERFACE
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import {
    Make,
    Model
} from '../../../../global/interfaces/VehicleSaleInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import CreateSeries from '../../logic/CreateSeries';

const AddSerieDialogContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [makeList, setMakeList] = useState<Make[]>([]);
    const [modelList, setModelList] = useState<Model[]>([]);
    const [selectedMake, setSelectedMake] = useState<Make>();
    const [selectedModel, setSelectedModel] = useState<Model>();
    const [name, setName] = useState<string>('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        GetMakes(setMakeList);
        GetModels(setModelList);
    }, []);

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={3.5}>
                        <Autocomplete
                            size="small"
                            options={makeList}
                            useTwoOptionLabels={true}
                            primaryOptionLabel={'id'}
                            secondaryOptionLabel={'name'}
                            textfieldLabel="Make"
                            selectedValue={selectedMake}
                            handleSelectedValueChange={(event) => {
                                setSelectedMake(event);
                                setModelList(
                                    modelList.filter(
                                        (x) => x.MakeId === event.id
                                    )
                                );
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={3.5}>
                        <Autocomplete
                            size="small"
                            isDisabled={selectedMake ? false : true}
                            options={modelList}
                            useTwoOptionLabels={true}
                            primaryOptionLabel={'id'}
                            secondaryOptionLabel={'name'}
                            textfieldLabel="Model"
                            selectedValue={selectedModel}
                            handleSelectedValueChange={(event) => {
                                setSelectedModel(event);
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            size="small"
                            disabled={
                                selectedMake && selectedModel ? false : true
                            }
                            label="New Series Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={buttonLoading}
                    variant="contained"
                    disabled={
                        !selectedMake || !selectedModel || name.length === 0
                    }
                    onClick={() =>
                        CreateSeries(
                            selectedMake,
                            selectedModel,
                            name,
                            setButtonLoading,
                            showSnackbar
                        )
                    }
                >
                    Submit New Series
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default AddSerieDialogContent;
