import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography
} from '@mui/material';

// COMPONENTS
import RoleCheck from '../../../../global/RoleCheck';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../../global/logic/Formatters';

// LOGIC
import UnreconcileTransaction from '../../../../global/logic/UnreconcileTransaction';
import DeleteTrustReceipt from '../../logic/DeleteTrustReceipt';
import GetTrustDetails from '../../logic/GetTrustDetails';

// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { VehicleTrustPayment } from '../../../../global/interfaces/VehicleSaleInterface';

interface TrustDrawerContentProps {
    VehicleId: number;
    showSnackbar: showSnackbar;
}
const TrustDrawerContent = ({
    VehicleId,
    showSnackbar
}: TrustDrawerContentProps) => {
    const [trustDetails, setTrustDetails] = useState<VehicleTrustPayment[]>([]);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [selectedTrustId, setSelectedTrustId] = useState<number>(null);
    const [selectedVehicleId, setSelectedVehicleId] = useState<number>(null);

    useEffect(() => {
        GetTrustDetails(VehicleId, setTrustDetails);
    }, [VehicleId]);

    return (
        <>
            {trustDetails?.map((trust) => (
                <>
                    <Typography variant="body1">
                        <b>Trust Payment ID: </b>
                        {trust?.id}
                    </Typography>
                    <Typography variant="body1">
                        <b>Date Paid: </b>
                        {DateFormatter(trust?.createdAt)}
                    </Typography>
                    <Typography variant="body1">
                        <b>Amount Paid: </b>
                        {CurrencyFormatter(trust?.amount)}
                    </Typography>
                    <Typography variant="body1">
                        <b>Paid By: </b>
                        {trust['User.firstName']} {trust['User.lastName']}
                    </Typography>
                    <br />
                    <Typography
                        variant="body1"
                        color={trust?.reconciled ? 'green' : 'error'}
                    >
                        <b>
                            {trust?.reconciled
                                ? 'RECONCILED'
                                : 'NOT RECONCILED'}
                        </b>
                    </Typography>
                    {trust?.reconciled ? (
                        <>
                            <Typography variant="body1">
                                <b>Date Reconciled: </b>
                                {DateFormatter(
                                    trust?.reconcileDetails?.createdAt
                                )}
                            </Typography>
                            <Typography variant="body1">
                                <b>Reconciled By: </b>
                                {trust?.reconcileDetails?.User?.firstName}{' '}
                                {trust?.reconcileDetails?.User?.lastName}
                            </Typography>
                            <RoleCheck
                                permission="unreconcileTransaction, assign_superuser"
                                component={
                                    <>
                                        <br />
                                        <Button
                                            onClick={() => {
                                                setDialogOpen(true);
                                                setSelectedTrustId(trust.id);
                                                setSelectedVehicleId(
                                                    trust.VehicleId
                                                );
                                            }}
                                            variant="outlined"
                                        >
                                            Unreconcile
                                        </Button>
                                    </>
                                }
                            />
                        </>
                    ) : (
                        <Button
                            onClick={() => {
                                setDeleteDialogOpen(true);
                                setSelectedTrustId(trust.id);
                                setSelectedVehicleId(trust.VehicleId);
                            }}
                            variant="outlined"
                        >
                            Delete
                        </Button>
                    )}
                    <br />
                    <br />
                    <Divider />
                    <br />
                </>
            ))}

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                maxWidth="sm"
                fullWidth
                sx={{ zIndex: 10000 }}
            >
                <DialogTitle>Unreconcile Transaction</DialogTitle>
                <DialogContent>
                    Are you sure you would like to manually unreconcile this
                    transaction?{' '}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Close</Button>
                    <Button
                        variant="contained"
                        onClick={() =>
                            UnreconcileTransaction(
                                `TrustId=${selectedTrustId}&vehicleId=${selectedVehicleId}`,
                                showSnackbar
                            )
                        }
                    >
                        Unreconcile Transaction
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Delete Transaction</DialogTitle>
                <DialogContent>
                    Are you sure you would like to delete this transaction?{' '}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() =>
                            DeleteTrustReceipt(
                                selectedTrustId,
                                selectedVehicleId,
                                showSnackbar
                            )
                        }
                    >
                        Delete Transaction
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TrustDrawerContent;
