import { Step } from '../../interface';

export const AddNewVehicleTradeSteps: Step[] = [
    {
        label: "Navigate to 'Vehicle Sales' > 'New Trade' through the sidebar to access the New Trade page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newTrade/new-trade-01.png'
    },
    {
        label: 'The Select Customer tab lets you select an existing Customer list or create a new one.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newTrade/new-trade-02.png'
    },
    {
        label:
            'In the Trade Vehicles tab, click the ' +
            ' button to open the New Trade Vehicle dialog.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newTrade/new-trade-03.png'
    },
    {
        label: "Fill in all required fields, then click the 'Add Trade' button. If any required fields are missing, an error message will appear indicating what needs to be filled in.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newTrade/new-trade-04.png'
    },
    {
        label: "Select 'INC. GST' or 'EX. GST' to specify whether the base cost includes or excludes GST. Review the GST details and the trade totals in the Total table.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newTrade/new-trade-05.png'
    },
    {
        label: "You can add one or more vehicles in the trade. After confirmation, Click 'Save' to submit the trade.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newTrade/new-trade-06.png'
    },
    {
        label: 'After submission, find the newly added vehicle on the Stock Vehicles page. Click the ID to view and edit vehicle details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newTrade/new-trade-07.png'
    }
];
