import DocumentationPage from '../DocumentationPage';
import { CreditStockInvoiceSteps } from '../data/stockControl/creditStockInvoice';

const CreditStockInvoiceSupportPage = () => {
    const contentSections = [
        {
            id: 'credit-stock-invoice',
            title: 'Credit a Stock Invoice',
            href: '/support/inventory/creditInvoice/#credit-stock-invoice',
            description:
                'Credit Stock Invoice is used when returning parts to a supplier. Follow these outlined steps:',
            steps: CreditStockInvoiceSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Credit Stock Invoice"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default CreditStockInvoiceSupportPage;
