const Header = (
    doc,
    image,
    saleDetails,
    settings,
    site,
    hideDisclaimer?,
    customeHeader?
) => {
    doc.addImage(image, 10, 15, 70, 20); // Put image on page

    // PDF title
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(13);
    doc.text(
        customeHeader
            ? customeHeader
            : saleDetails.saleStatus === 'Quote'
              ? `MOTOR VEHICLE SALE QUOTE`
              : `MOTOR VEHICLE SALE CONTRACT`,
        10,
        40
    );

    doc.setFont('helvetica', 'bold').text('Sale #' + saleDetails.id, 10, 45);

    // Dealership details (ABN, address, etc)
    doc.setFont('helvetica', 'normal').setFontSize(11);
    doc.text(settings.companyName, 199, 20, { align: 'right' });
    doc.text(site.address, 199, 25, { align: 'right' });
    doc.text(site.name + ' ' + site.state + ' ' + site.postcode, 199, 30, {
        align: 'right'
    });
    doc.text('Phone: ' + site.phoneNumber, 199, 35, { align: 'right' });
    doc.setFont('helvetica', 'bold').text('ABN: ' + settings.abn, 199, 40, {
        align: 'right'
    });
    doc.setFont('helvetica', 'normal').text(
        settings?.websiteURL ?? '',
        199,
        45,
        { align: 'right' }
    );

    // First Disclaimer
    if (!hideDisclaimer) {
        doc.setFont('helvetica', 'italic')
            .setFontSize(11.5)
            .text(
                'The Purchaser agrees to buy from the Dealer and the Dealer agrees to sell to the Purchaser, the motor',
                10,
                60
            );
        doc.text(
            'vehicle described as the "Vehicle Being Purchased" on the Terms and Conditions set out in this contract.',
            10,
            65
        );
    }
};

export default Header;
