// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Table,
    TableRow,
    TableCell,
    TextField,
    IconButton,
    Checkbox,
    Typography
} from '@mui/material';
import { RestartAlt } from '@mui/icons-material';
// COMPONENTS
import RoleCheck from '../../../../global/RoleCheck';
// INTERFACES
import { Unit } from '../../../../global/interfaces/PartsInterface';

interface PricingTableProps {
    partDetails: Unit;
    setPartDetails: Dispatch<SetStateAction<Unit>>;
    readOnly: boolean;
    selectedSite: number;
    setResetAverageCostOpen: Dispatch<SetStateAction<boolean>>;
}

const PricingTable = ({
    partDetails,
    setPartDetails,
    readOnly,
    selectedSite,
    setResetAverageCostOpen
}: PricingTableProps) => {
    const findLowest = (type: string) => {
        // type:
        /* RRP, CPD, CPS, TRRP */
        if (type === 'rrp') {
            if (partDetails?.SupplierIds.length > 0) {
                let lowest = null;
                let supplierName = '';
                for (let supplier of partDetails.SupplierIds) {
                    if (supplier.RRP < lowest || lowest == null) {
                        lowest = supplier.RRP;
                        supplierName = supplier.code;
                    }
                }

                if (lowest && supplierName.length > 0) {
                    return supplierName + ': $' + lowest;
                } else {
                    return partDetails?.priceRRP;
                }
            } else {
                return partDetails?.priceRRP;
            }
        } else if (type === 'cpd') {
            if (partDetails?.SupplierIds.length > 0) {
                let lowest = null;
                let supplierName = '';
                for (let supplier of partDetails.SupplierIds) {
                    if (supplier.CPD < lowest || lowest == null) {
                        lowest = supplier.CPD;
                        supplierName = supplier.code;
                    }
                }

                if (lowest && supplierName.length > 0) {
                    return supplierName + ': $' + lowest;
                } else {
                    return partDetails?.costPriceDaily;
                }
            } else {
                return partDetails?.costPriceDaily;
            }
        } else if (type === 'cps') {
            if (partDetails?.SupplierIds.length > 0) {
                let lowest = null;
                let supplierName = '';
                for (let supplier of partDetails.SupplierIds) {
                    if (supplier.CPS < lowest || lowest == null) {
                        lowest = supplier.CPS;
                        supplierName = supplier.code;
                    }
                }
                if (lowest && supplierName.length > 0) {
                    return supplierName + ': $' + lowest;
                } else {
                    return partDetails?.costPriceStock;
                }
            } else {
                return partDetails?.costPriceStock;
            }
        } else if (type === 'trrp') {
            if (partDetails?.SupplierIds.length > 0) {
                let lowest = null;
                let supplierName = '';
                for (let supplier of partDetails.SupplierIds) {
                    if (supplier.tradePriceRRP < lowest || lowest == null) {
                        lowest = supplier.tradePriceRRP;
                        supplierName = supplier.code;
                    }
                }
                if (lowest && supplierName.length > 0) {
                    return supplierName + ': $' + lowest;
                } else {
                    return partDetails?.costPriceTrade;
                }
            } else {
                return partDetails?.costPriceTrade;
            }
        }
    };

    // Labels and values for the pricing rows
    const pricingRows = [
        {
            id: 0,
            name: 'priceRRP',
            label: 'RRP',
            value: findLowest('rrp'),
            helperText: 'Please enter a valid RRP.'
        },
        {
            id: 0,
            name: 'costPriceDaily',
            label: 'Daily Cost',
            value: findLowest('cpd'),
            helperText: 'Please enter a valid daily cost price.'
        },
        {
            id: 0,
            name: 'costPriceStock',
            label: 'Stock Cost',
            value: findLowest('cps'),
            helperText: 'Please enter a valid Stock Cost price.'
        },
        {
            id: 0,
            name: 'costPriceTrade',
            label: 'Trade Stock',
            value: findLowest('trrp')
        },
        {
            id: 0,
            name: 'costPriceVIP',
            label: 'VIP Cost',
            value: partDetails?.costPriceVIP
        }
    ];

    // Get the current average cost for the selected site
    const getSiteAverageCost = () => {
        if (partDetails && partDetails?.Stock?.length > 0 && selectedSite) {
            let siteAverageCost = partDetails?.Stock.filter(
                (x) => x.SiteId === selectedSite
            );
            return siteAverageCost[0].costPriceAverage;
        } else {
            return 0;
        }
    };

    return (
        <Table>
            <TableRow>
                <TableCell colSpan={2}>
                    <Typography variant="h6">Lowest Pricing ($)</Typography>
                </TableCell>
            </TableRow>
            {pricingRows?.map((row) => (
                <TableRow>
                    <TableCell>{row.label}</TableCell>
                    <TableCell>
                        <TextField
                            fullWidth
                            size="small"
                            variant={'standard'}
                            disabled
                            InputProps={{ disableUnderline: true }}
                            value={row.value}
                        />
                    </TableCell>
                </TableRow>
            ))}
            <TableRow>
                <TableCell>
                    Average Cost &nbsp;&nbsp;&nbsp;
                    {selectedSite ===
                    parseInt(localStorage.getItem('SiteId')) ? (
                        <RoleCheck
                            component={
                                <IconButton
                                    onClick={() => {
                                        setResetAverageCostOpen(true);
                                    }}
                                >
                                    <RestartAlt />
                                </IconButton>
                            }
                            permission="reset_AverageCost"
                        />
                    ) : null}
                </TableCell>
                <TableCell>{getSiteAverageCost()}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>GST Free</TableCell>
                <TableCell>
                    <Checkbox
                        disabled={readOnly}
                        checked={partDetails?.gstFree ? true : false}
                        onChange={(e) =>
                            setPartDetails({
                                ...partDetails,
                                gstFree: e.target.checked
                            })
                        }
                    />
                </TableCell>
            </TableRow>
        </Table>
    );
};

export default PricingTable;
