// IMPORTS
import dayjs, { Dayjs } from 'dayjs';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import './customCalendar.css';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { Card, Dialog, DialogContent, DialogTitle } from '@mui/material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import FortnightView from './testComponents/FortnightView';
import CalendarToolbar from './testComponents/CalendarToolbar';
import JobDialog from './components/JobDialog';
import ServiceCardContent from './testComponents/ServiceCardContent';
import TechView from './testComponents/TechView';
import Drawer from '../../global/Drawer';
import TechDrawerTest from './testComponents/TechDrawerTest';
// LOGIC
import GetAllTechs from '../../global/databaseLogic/GetAllTechs';
import GetCalendarData from './testLogic/GetCalendarData';
import HandleDnDService from './testLogic/HandleDnDService';
import PrintDailyJobCard from './testComponents/PrintDailyJobCard';
// INTERFACES
import { Service } from '../../global/interfaces/ServiceInterface';
import { User } from '../../global/interfaces/GeneralInterface';

const localizer = dayjsLocalizer(dayjs);

const ServiceCalendarRBCDarkMode = () => {
    const [selectedView, setSelectedView] = useState('fortnight');
    const [date, setDate] = useState(dayjs());
    const [listOfJobs, setListOfJobs] = useState<Service[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string[]>([
        'Incomplete',
        'Complete'
    ]);
    const [searchString, setSearchString] = useState<string>(null);

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [techList, setTechList] = useState<User[]>([]);
    const [techArray, setTechArray] = useState<
        { resourceId: number; resourceTitle: string }[]
    >([]);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogChildren, setDialogChildren] = useState<ReactNode>(null);

    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [selectedJob, setSelectedJob] = useState<Service>({});
    const [descriptionDialog, setDescriptionDialog] = useState<boolean>(false);

    const [openJobCardPrint, setOpenJobCardPrint] = useState<boolean>(false);
    const [selectedDay, setSelectedDay] = useState<Dayjs>(dayjs());

    const [clickTime, setClickTime] = useState<number>(null);

    const DnDCalendar = withDragAndDrop(Calendar);
    useEffect(() => {
        GetCalendarData(
            selectedView,
            date,
            selectedStatus,
            searchString,
            setListOfJobs
        );
        GetAllTechs(setTechList);
        // eslint-disable-next-line
    }, []);

    const startDate = dayjs(date).startOf('week').hour(8).toDate();
    const endDate = dayjs(date).startOf('week').hour(19).toDate();

    const formats = useMemo(
        () => ({
            eventTimeRangeFormat: () => ''
        }),
        []
    );

    const handleSlotClick = (event) => {
        if (tooltipOpen) {
            setTooltipOpen(false);
            setSelectedJob({});
        }
        const click = performance.now();
        if (click - clickTime < 250) {
            setDialogChildren(
                <JobDialog
                    selectedId={null}
                    setEventDialog={setOpenDialog}
                    startDate={dayjs(event.slots[0]).minute(0)}
                    endDate={dayjs(event.slots[0]).add(1, 'hour').minute(0)}
                />
            );
            setTimeout(() => setOpenDialog(true), 250);
        } else {
            setClickTime(click);
        }
    };

    return (
        <PageWrapper>
            <div className="dark">
                <DnDCalendar
                    style={{ height: '90vh', width: '80vw' }}
                    localizer={localizer}
                    views={{
                        fortnight: FortnightView,
                        month: true,
                        tech: TechView
                    }}
                    defaultView="fortnight"
                    view={selectedView}
                    date={date}
                    min={startDate}
                    max={endDate}
                    startAccessor="start"
                    endAccessor="end"
                    resources={selectedView === 'tech' ? techArray : null}
                    resourceIdAccessor={
                        selectedView === 'tech' ? 'resourceId' : null
                    }
                    resourceTitleAccessor={
                        selectedView === 'tech' ? 'resourceTitle' : null
                    }
                    events={listOfJobs}
                    timeslots={4}
                    step={15}
                    selectable
                    onSelectEvent={(e) => {
                        setTooltipOpen(true);
                        setSelectedJob(e.jobData);
                    }}
                    onSelectSlot={(e) => {
                        handleSlotClick(e);
                    }}
                    popup
                    onDrillDown={(date: Date) => {
                        if (selectedView === 'month') {
                            console.log(date);
                        } else {
                            setSelectedDay(dayjs(date));
                            setOpenJobCardPrint(true);
                        }
                    }}
                    onDoubleClickEvent={(event) => {
                        setDialogChildren(
                            <JobDialog
                                selectedId={event.id}
                                setEventDialog={setOpenDialog}
                            />
                        );
                        setOpenDialog(true);
                    }}
                    onEventDrop={({
                        event,
                        start,
                        end,
                        resourceId,
                        isAllDay
                    }) => {
                        HandleDnDService(
                            event.id,
                            dayjs(start),
                            dayjs(end),
                            listOfJobs,
                            setListOfJobs,
                            isAllDay,
                            resourceId,
                            techList,
                            false
                        );
                    }}
                    onEventResize={({ event, start, end }) => {
                        HandleDnDService(
                            event.id,
                            dayjs(start),
                            dayjs(end),
                            listOfJobs,
                            setListOfJobs,
                            false,
                            event.resourceId,
                            techList,
                            true
                        );
                    }}
                    components={{
                        toolbar: (props) => (
                            <CalendarToolbar
                                {...props}
                                setDate={setDate}
                                setSelectedView={setSelectedView}
                                selectedStatus={selectedStatus}
                                setSelectedStatus={setSelectedStatus}
                                setSearchString={setSearchString}
                                setListOfJobs={setListOfJobs}
                                techList={techList}
                                setOpenDrawer={setOpenDrawer}
                                setTechArray={setTechArray}
                            />
                        )
                    }}
                    formats={formats}
                    eventPropGetter={(
                        event: {
                            id: number;
                            status: string;
                            title: string;
                            start: Date;
                            end: Date;
                            TechId: number;
                        },
                        start: Date,
                        end: Date,
                        isSelected: boolean
                    ) => {
                        let style = {
                            border: '1px solid #555',
                            background: 'rgb(102, 127, 161)',
                            fontSize: '12px',
                            verticalAlign: 'text-bottom',
                            textAlign: 'left',
                            paddingLeft: 0,
                            whiteSpace: 'break-spaces'
                        };

                        if (event.status === 'Complete') {
                            style.background = '#f57c00';
                            style.border = '1px solid #555';
                        } else if (event.status === 'Finalised') {
                            style.background = '#3fb73f';
                            style.border = '1px solid #555';
                        }
                        return {
                            className: '',
                            style: style
                        };
                    }}
                />

                <Dialog
                    fullScreen
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                    }}
                >
                    {dialogChildren}
                </Dialog>

                <Dialog
                    open={openJobCardPrint}
                    onClose={() => setOpenJobCardPrint(false)}
                >
                    <PrintDailyJobCard
                        selectedDay={selectedDay}
                        listOfJobs={listOfJobs}
                        setOpenJobCardPrint={setOpenJobCardPrint}
                    />
                </Dialog>

                <Dialog
                    open={descriptionDialog}
                    onClose={() => {
                        setDescriptionDialog(false);
                    }}
                >
                    <DialogTitle>Job #{selectedJob.id}</DialogTitle>
                    <DialogContent>{selectedJob.jobDescription}</DialogContent>
                </Dialog>

                <div
                    style={{
                        visibility: tooltipOpen ? 'visible' : 'hidden',
                        top: 'auto',
                        right: 25,
                        bottom: 25,
                        left: 'auto',
                        position: 'fixed',
                        width: 350
                    }}
                >
                    <Card sx={{ minWidth: 350 }}>
                        <ServiceCardContent
                            selectedJob={selectedJob}
                            setDescriptionDialog={setDescriptionDialog}
                        />
                    </Card>
                </div>

                <Drawer
                    openDrawer={openDrawer}
                    setOpenDrawer={setOpenDrawer}
                    title={'Technician List'}
                    subTitle={''}
                >
                    <TechDrawerTest
                        techList={techList}
                        selectedTech={techArray}
                        setSelectedTech={setTechArray}
                    />
                </Drawer>
            </div>
        </PageWrapper>
    );
};

export default ServiceCalendarRBCDarkMode;
