import { Dispatch, SetStateAction, useState } from 'react';
import {
    Button,
    Divider,
    Typography,
    TextField,
    MenuItem,
    IconButton,
    FormGroup,
    FormControlLabel,
    Switch,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    FormControl,
    FormHelperText
} from '@mui/material';
import { Add, Close } from '@material-ui/icons';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

// LOGIC
import SaveApi from '../logic/SaveApi';
import {
    Creditor,
    OrderFileGenerator,
    Site
} from '../../../global/interfaces/GeneralInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface ApiDialogContentProps {
    selectedApi: SelectedApi;
    setSelectedApi: Dispatch<SetStateAction<SelectedApi>>;
    siteList: Site[];
    supplierList: Creditor[];
    generatorList: OrderFileGenerator[];
    edit: boolean;
}

interface SelectedApi {
    id?: number;
    name: string;
    nameError: boolean;
    description: string;
    descriptionError: boolean;
    Site: Site;
    siteError: boolean;
    key: string;
    Supplier: Creditor;
    supplierError: boolean;
    canGenerateOrderFile: boolean;
    canOrderWithAPI: boolean;
    Generator: OrderFileGenerator;
    generatorError: boolean;
    OrderApi: OrderFileGenerator;
    OrderApiError: boolean;
    detailsList: {};

    OrderFileGeneratorId?: number;
    ApiOrderId?: number;
    additionalDetails?: {};
}

const ApiDialogContent = (props: ApiDialogContentProps) => {
    const [addLineOpen, setAddLineOpen] = useState<boolean>(false);
    const [newKey, setNewKey] = useState<string>('');
    const [buttonLoading, setButtonLoading] = useState(false);

    return (
        <DialogContent>
            <Typography variant="h5" align="center">
                {props.edit ? 'Update API Details' : 'Add New API Details'}
            </Typography>
            <br />
            <Grid container spacing={2}>
                <Grid xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="API Name"
                        value={props.selectedApi.name}
                        error={props.selectedApi.nameError}
                        helperText={
                            props.selectedApi.nameError
                                ? 'A name is required.'
                                : ''
                        }
                        onChange={(e) => {
                            props.setSelectedApi({
                                ...props.selectedApi,
                                name: e.target.value
                            });
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid xs={8}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Description"
                        value={props.selectedApi.description}
                        error={props.selectedApi.descriptionError}
                        helperText={
                            props.selectedApi.descriptionError
                                ? 'A description is required.'
                                : ''
                        }
                        onChange={(e) => {
                            props.setSelectedApi({
                                ...props.selectedApi,
                                description: e.target.value
                            });
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        select
                        value={props.selectedApi.Site.id}
                        error={props.selectedApi.siteError}
                        helperText={
                            props.selectedApi.siteError
                                ? 'A site is required.'
                                : ''
                        }
                        label="Site"
                        onChange={(e) => {
                            props.setSelectedApi({
                                ...props.selectedApi,
                                Site: props.siteList.find(
                                    (x) => x.id === parseInt(e.target.value)
                                )
                            });
                        }}
                        InputLabelProps={{ shrink: true }}
                    >
                        {props.siteList.map((site) => (
                            <MenuItem value={site.id}>{site.name}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Key"
                        value={props.selectedApi.key}
                        onChange={(e) => {
                            props.setSelectedApi({
                                ...props.selectedApi,
                                key: e.target.value
                            });
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                {/* <Grid xs={4} /> */}
                <Grid xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        select
                        value={props.selectedApi.Supplier.id}
                        label="Supplier"
                        onChange={(e) => {
                            props.setSelectedApi({
                                ...props.selectedApi,
                                Supplier: props.supplierList.find(
                                    (x) => x.id === parseInt(e.target.value)
                                )
                            });
                        }}
                        InputLabelProps={{ shrink: true }}
                    >
                        {props.supplierList.map((supplier) => (
                            <MenuItem value={supplier.id}>
                                {supplier.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {/* <Grid xs={4} /> */}
                {props.selectedApi.Supplier.id ? (
                    <>
                        <Grid xs={3}>
                            <FormGroup>
                                <FormControl
                                    error={props.selectedApi.supplierError}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    props.selectedApi
                                                        .canGenerateOrderFile
                                                }
                                                onChange={(e) => {
                                                    props.setSelectedApi({
                                                        ...props.selectedApi,
                                                        canGenerateOrderFile:
                                                            e.target.checked
                                                    });
                                                }}
                                            />
                                        }
                                        label="Can Generate Order File?"
                                    />
                                    <FormHelperText>
                                        {props.selectedApi.supplierError
                                            ? 'You need to select at least a Order Generator or an Order API'
                                            : ''}
                                    </FormHelperText>
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        {props.selectedApi.canGenerateOrderFile ? (
                            <Grid xs={3}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    select
                                    value={props.selectedApi.Generator.id}
                                    error={props.selectedApi.generatorError}
                                    helperText={
                                        props.selectedApi.generatorError
                                            ? 'A format is required.'
                                            : ''
                                    }
                                    label="Generator Function"
                                    onChange={(e) => {
                                        props.setSelectedApi({
                                            ...props.selectedApi,
                                            Generator: props.generatorList.find(
                                                (x) =>
                                                    x.id ===
                                                    parseInt(e.target.value)
                                            )
                                        });
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    {props.generatorList.map((generator) => (
                                        <MenuItem value={generator.id}>
                                            {generator.generatorName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        ) : (
                            <Grid xs={3}>{null}</Grid>
                        )}
                        <Grid xs={3}>
                            <FormGroup>
                                <FormControl
                                    error={props.selectedApi.supplierError}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    props.selectedApi
                                                        .canOrderWithAPI
                                                }
                                                onChange={(e) => {
                                                    props.setSelectedApi({
                                                        ...props.selectedApi,
                                                        canOrderWithAPI:
                                                            e.target.checked
                                                    });
                                                }}
                                            />
                                        }
                                        label="Can order via API?"
                                    />
                                    <FormHelperText>
                                        {props.selectedApi.supplierError
                                            ? 'You need to select at least a Order Generator or an Order API'
                                            : ''}
                                    </FormHelperText>
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        {props.selectedApi.canOrderWithAPI ? (
                            <Grid xs={3}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    select
                                    value={props.selectedApi.OrderApi.id}
                                    error={props.selectedApi.OrderApiError}
                                    helperText={
                                        props.selectedApi.OrderApiError
                                            ? 'A format is required.'
                                            : ''
                                    }
                                    label="API Order Function"
                                    onChange={(e) => {
                                        props.setSelectedApi({
                                            ...props.selectedApi,
                                            OrderApi: props.generatorList.find(
                                                (x) =>
                                                    x.id ===
                                                    parseInt(e.target.value)
                                            )
                                        });
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                >
                                    {props.generatorList.map((generator) =>
                                        generator.apiOrderName ? (
                                            <MenuItem value={generator.id}>
                                                {generator.apiOrderName}
                                            </MenuItem>
                                        ) : null
                                    )}
                                </TextField>
                            </Grid>
                        ) : null}
                    </>
                ) : null}
            </Grid>
            <br />
            <Divider></Divider>
            <br />
            <Typography variant="h5" align="center">
                Additional Details
                <Tooltip title="Add Line" placement="top">
                    <IconButton onClick={() => setAddLineOpen(true)}>
                        <Add />
                    </IconButton>
                </Tooltip>
            </Typography>
            <br />
            <Grid container spacing={2} textAlign="center">
                {Object.keys(props.selectedApi.detailsList).map(
                    (key, index) => (
                        <>
                            <Grid xs={5.5}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    disabled
                                    label="Detail Key"
                                    value={key}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label="Value"
                                    value={props.selectedApi.detailsList[key]}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        props.setSelectedApi({
                                            ...props.selectedApi,
                                            detailsList: {
                                                ...props.selectedApi
                                                    .detailsList,
                                                [key]: e.target.value
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid xs={0.5}>
                                <IconButton
                                    onClick={() => {
                                        let temp = JSON.parse(
                                            JSON.stringify(
                                                props.selectedApi.detailsList
                                            )
                                        );
                                        delete temp[key];
                                        props.setSelectedApi({
                                            ...props.selectedApi,
                                            detailsList: temp
                                        });
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </>
                    )
                )}

                <Grid display="flex" xs={12} justifyContent="right">
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            SaveApi(
                                props.selectedApi,
                                props.setSelectedApi,
                                setButtonLoading
                            )
                        }
                    >
                        Save
                    </LoadingButton>
                </Grid>
            </Grid>
            <Dialog
                open={addLineOpen}
                onClose={() => {
                    setAddLineOpen(false);
                }}
            >
                <DialogContent>
                    <TextField
                        label="New Key"
                        value={newKey}
                        onChange={(e) => {
                            setNewKey(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            props.setSelectedApi({
                                ...props.selectedApi,
                                detailsList: {
                                    ...props.selectedApi.detailsList,
                                    [newKey]: ''
                                }
                            });
                            setNewKey('');
                            setAddLineOpen(false);
                        }}
                    >
                        Add Line
                    </Button>
                </DialogActions>
            </Dialog>
        </DialogContent>
    );
};

export default ApiDialogContent;
