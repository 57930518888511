// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
// LOGIC
import api from '../../../../../api';

/**
 * GetVehicleResults
 * Get the search results for the Update Vehicle RRPs page
 * @author Estienne
 * @param makeId the queried vehicle make
 * @param modelId the queried vehicle model
 * @param yearStart the lower-limit year filter
 * @param yearEnd the upper-limit year filter
 * @param setResults the React state setter for the vehicle results
 */
const GetVehicleResults = (
    makeId: number,
    modelId: number,
    yearStart: string,
    yearEnd: string,
    setResults: Dispatch<SetStateAction<Vehicle[]>>,
    showSnackbar: showSnackbar
) => {
    let apiString = `&sort=id,DESC&make=${makeId}&model=${modelId}&yearStart=${yearStart}&yearEnd=${yearEnd}`;
    api.get(`vehicleModelSearch?${apiString}`).then((res) => {
        if (res.data.length <= 0) {
            showSnackbar(
                'There are no vehicles that match your current search parameters.',
                'Please update your parameters and try again.',
                'warning'
            );
        } else {
            setResults(res.data);
        }
    });
};

export default GetVehicleResults;
