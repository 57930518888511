// IMPORTS
// eslint-disable-next-line
import jsPDF from 'jspdf'; // !DO NOT REMOVE
// LOGIC
import api from '../../../../api';
import { DateFormatter } from '../logic/Formatters';
// INTERFACES
import { Settings, Customer, Site, User } from '../interfaces/GeneralInterface';

const InvoiceHeader = async (
    doc,
    SiteId: number,
    pageTitle: string,
    subtitle: string,
    showCustomerDetails: boolean,
    showCompanyDetails: boolean,
    showBankDetails: boolean,
    customerData?: Customer,
    userData?: User,
    dateFinalised?: string,
    PONumber?: string
) => {
    let settings: Settings;
    let site: Site;

    // Get the settings in order to get company details and logo
    await api.get(`settingsNextGen/${SiteId}?includeImage=true`).then((res) => {
        settings = res.data.settings;
        site = res.data.site;

        // Create and format an image object
        let image = null;
        let newImg = document.createElement('img');
        newImg.src = res.data.image;
        newImg.className = 'logo';
        image = newImg;

        doc.addImage(image, 15, 15, 80, 15); // Put image on page

        // Page title
        doc.setFont('helvetica', 'bold').setFontSize(24);
        doc.text(pageTitle, 12, 40);

        // Page subtitle
        doc.setFontSize(14);
        doc.text(subtitle, 13, 48);

        doc.setFont('helvetica', 'normal').setFontSize(10);

        let nextY = 55;

        // Customer Details
        if (showCustomerDetails && customerData) {
            // Use company name if applicable
            if (customerData?.companyName) {
                doc.text(`Customer: ${customerData?.companyName}`, 13, nextY, {
                    align: 'left'
                });
            }

            // If no company name then use first and last name if available
            else if (customerData?.firstName && customerData?.lastName) {
                // If both first and last name
                doc.text(
                    `Customer: ${customerData?.firstName} ${customerData?.lastName}`,
                    13,
                    nextY,
                    { align: 'left' }
                );
            } else if (customerData?.firstName) {
                // If only first name
                doc.text(`Customer: ${customerData?.firstName}`, 13, nextY, {
                    align: 'left'
                });
            } else if (customerData?.lastName) {
                // If only last name
                doc.text(`Customer: ${customerData?.lastName}`, 13, nextY, {
                    align: 'left'
                });
            }

            nextY = 60; // Some next fields are only displayed if they exist, so need a marker for y coord.

            // If there is an abn
            if (customerData?.abn) {
                doc.text(`ABN: ${customerData?.abn}`, 13, nextY, {
                    align: 'left'
                });
                nextY = nextY + 5; // Increment the y coord.
            }

            // If there is a phone number
            if (customerData.phoneNumber) {
                doc.text(
                    `Phone Number: ${customerData.phoneNumber}`,
                    13,
                    nextY,
                    {
                        align: 'left'
                    }
                );
            }

            if (customerData.addressLine1 || customerData.suburb) {
                let addressLine1 = customerData.addressLine1
                    ? customerData.addressLine1 + ','
                    : '';
                let suburb = customerData.suburb
                    ? customerData.suburb + ' '
                    : '';
                let postcode = customerData.postcode
                    ? customerData.postcode + ', '
                    : '';
                let country = customerData.country ? customerData.country : '';

                doc.text(`Address: ${addressLine1}`, 13, nextY + 5, {
                    align: 'left'
                });
                doc.text(
                    `               ${suburb}${postcode}${country}`,
                    13,
                    nextY + 10,
                    { align: 'left' }
                );
            }
        }

        if (dateFinalised) {
            doc.text(`Date: ${DateFormatter(dateFinalised)}`, 13, nextY + 15, {
                align: 'left'
            });
        }

        if (PONumber) {
            doc.text(`PO Number: ${PONumber}`, 13, nextY + 20, {
                align: 'left'
            });
        }

        if (userData) {
            doc.text(
                `Employee: ${userData.firstName} ${userData.lastName.slice(0, 1).toUpperCase()}.`,
                13,
                nextY + 25,
                { align: 'left' }
            );
        }

        // Company Details
        if (showCompanyDetails) {
            doc.text(settings?.companyName, 195, 55, { align: 'right' });
            doc.text('ABN: ' + settings?.abn, 195, 60, { align: 'right' });

            let companyAddressLine1 = site?.address ? site?.address + ',' : '';
            let companySuburb = site?.suburb ? site?.suburb + ' ' : '';
            let companyPostcode = site?.postcode ? site?.postcode + ', ' : '';
            let companyCountry = site?.country ? site?.country : '';

            doc.text(`Address: ${companyAddressLine1}`, 195, 65, {
                align: 'right'
            });
            doc.text(
                `${companySuburb}${companyPostcode}${companyCountry}`,
                195,
                70,
                {
                    align: 'right'
                }
            );
            doc.text(`Phone: ${site.phoneNumber}`, 195, 75, { align: 'right' });

            if (showBankDetails) {
                doc.text(
                    `Acc. Name: ${settings?.directDepositAccountName}`,
                    195,
                    80,
                    {
                        align: 'right'
                    }
                );
                doc.text(
                    `BSB: ${settings?.directDepositAccountBsb} Acc. Num: ${settings?.directDepositAccountNumber}`,
                    195,
                    85,
                    { align: 'right' }
                );
            }
        }
    });
};

export default InvoiceHeader;
