import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';
import { Series } from '../../../global/interfaces/VehicleSaleInterface';

const DeleteSerie = (
    selectedSerie: Series,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.delete('serie/' + selectedSerie.id).then((res) => {
        if (res.status === 200) {
            showSnackbar('Serie successfully deleted.');
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar(
                'Unable to delete Serie.',
                'There are stock vehicles registered with this serie.',
                'error'
            );
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Something went wrong, please refresh the page and try again.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default DeleteSerie;
