import { Step } from '../../interface';

export const StockTransferSteps: Step[] = [
    {
        label: "Click 'Stock Control' > 'Stock Transfers' in the sidebar to navigate to the Stock Transfer page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockTransfers/transfer-01.png'
    },
    {
        label: 'Enter the Order ID or Site name, then press Enter to search for specific Stock Transfers.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockTransfers/transfer-02.png'
    },
    {
        label: 'Click the filter icon to open the filter panel. Select options in the Filter & Sort dialog to filter the Stock Transfers. You can combine multiple filters to narrow down the search results.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockTransfers/transfer-03.png'
    },
    {
        label: 'Click New Stock Transfer in the speed dial menu to open the Stock Transfer drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockTransfers/transfer-04.png'
    },
    {
        label: 'In the Stock Transfer drawer, select the "From" and "To" Sites, then enter the part number or barcode and press Enter to add parts to the transfer. Adjust the quantity sent as needed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockTransfers/transfer-05.png'
    },
    {
        label: 'Click Submit Transfer to create the transfer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockTransfers/transfer-06.png'
    },
    {
        label: 'The newly created Stock Transfer can be viewed in the Stock Transfer list with a default PENDING status. Click on the ID to view the Stock Transfer details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockTransfers/transfer-07.png'
    },
    {
        label: 'In the Stock Transfer details drawer, adjust the quantity received if necessary, then click Transfer Parts to complete the transfer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockTransfers/transfer-08.png'
    }
];
