// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    TextField,
    DialogActions,
    Button
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
// LOGIC
import RemoveLineItem from '../../logic/RemoveLineItem';
import AdjustLineValue from '../../logic/AdjustLineValue';
// INTERFACES
import { Order } from '../../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import PrintLabel from '../../../../global/logic/PrintLabel';

interface OrderlineMenuProps {
    row;
    disabled: boolean;
    saleDetails: Order;
    setSaleDetails: Dispatch<SetStateAction<Order>>;
    showSnackbar: showSnackbar;
}
const OrderlineMenu = ({
    row,
    disabled,
    saleDetails,
    setSaleDetails,
    showSnackbar
}: OrderlineMenuProps) => {
    const [discountOpen, setDiscountOpen] = useState<boolean>(false);
    const [discountValue, setDiscountValue] = useState<string>('0');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const calculateDiscount = () => {
        if (parseFloat(discountValue) === 0) {
            showSnackbar(
                'Invalid discount value.',
                'Please enter a number between -100 and 100, excluding 0.',
                'error'
            );
            return;
        }

        let discountedPrice =
            parseFloat(row.pricePaid) -
            row.pricePaid * (parseFloat(discountValue) / 100);
        AdjustLineValue(
            row.rowId,
            'pricePaid',
            String(discountedPrice),
            row.gstFree,
            saleDetails,
            setSaleDetails,
            row
        );
        setDiscountOpen(false);
        handleClose();
    };

    const ReprintLabel = (row) => {
        let labelData = [
            {
                partNumber: row.partNumber,
                priceRRP: row.priceRRP,
                name: row.name,
                binLocation: '',
                backorderId: saleDetails.id,
                customerName:
                    saleDetails?.Customer?.firstName +
                    ' ' +
                    saleDetails?.Customer?.lastName,
                phoneNumber: saleDetails?.Customer?.phoneNumber
            }
        ];
        PrintLabel(labelData);
    };

    return (
        <>
            <IconButton disabled={disabled} onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        ReprintLabel(row);
                    }}
                >
                    Reprint Label
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        RemoveLineItem(row.rowId, saleDetails, setSaleDetails);
                        handleClose();
                    }}
                >
                    Remove Part
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        AdjustLineValue(
                            row.rowId,
                            'pricePaid',
                            row.priceRRP,
                            row.gstFree,
                            saleDetails,
                            setSaleDetails,
                            row
                        );
                        handleClose();
                    }}
                >
                    Original RRP
                </MenuItem>
                {!row.gstFree ? (
                    <MenuItem
                        onClick={() => {
                            AdjustLineValue(
                                row.rowId,
                                'pricePaid',
                                String(parseFloat(row.pricePaid) / 1.1),
                                true,
                                saleDetails,
                                setSaleDetails,
                                row
                            );
                            handleClose();
                        }}
                    >
                        Remove GST
                    </MenuItem>
                ) : (
                    <MenuItem
                        onClick={() => {
                            AdjustLineValue(
                                row.rowId,
                                'pricePaid',
                                String(parseFloat(row.pricePaid) * 1.1),
                                false,
                                saleDetails,
                                setSaleDetails,
                                row
                            );
                            handleClose();
                        }}
                    >
                        Add GST
                    </MenuItem>
                )}
                <MenuItem onClick={() => setDiscountOpen(true)}>
                    Majoration/Discount
                </MenuItem>
            </Menu>

            <Dialog
                open={discountOpen}
                onClose={() => setDiscountOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Line Majoration/Discount</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Please enter a percentage between -100% and 100%
                        (positive value for discount).
                    </Typography>
                    <br />
                    <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        value={discountValue}
                        onChange={(e) => setDiscountValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDiscountOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => calculateDiscount()}
                    >
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OrderlineMenu;
