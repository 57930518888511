// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IconButton, TableRow, Typography } from '@mui/material';
import {
    Add,
    CheckCircle,
    Notes,
    RadioButtonUnchecked
} from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import Fab from '../../../../global/Fab';
import Drawer from '../../../../global/Drawer';
import CustomerVehicleDrawer from './CustomerVehicleDrawer';
// LOGIC
import GetCustomerVehicles from '../../logic/GetCustomerVehicles';
// INTERFACES
import { CustomerVehicle } from '../../../../global/interfaces/ServiceInterface';
import { Customer } from '../../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { YearFormatter } from '../../../../global/logic/Formatters';
interface CustomerVehicleFormProps {
    readOnly: boolean;
    selectedVehicle: Vehicle | CustomerVehicle;
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle | CustomerVehicle>>;
    selectedCustomer: Customer;
}
const CustomerVehicleForm = ({
    readOnly,
    selectedVehicle,
    setSelectedVehicle,
    selectedCustomer
}: CustomerVehicleFormProps) => {
    const [resultsList, setResultsList] = useState<CustomerVehicle[]>([]);
    const [resultsListAltered, setResultsListAltered] = useState<
        CustomerVehicle[]
    >([]);
    const [customerVehicleDrawerOpen, setCustomerVehicleDrawerOpen] =
        useState<boolean>(false);
    const [selectedCustomerVehicle, setSelectedCustomerVehicle] =
        useState<CustomerVehicle>();
    const [vehicleAdded, setVehicleAdded] = useState(false);

    const columns = [
        { id: 0, label: '', width: 100 },
        { id: 1, label: 'Vehicle', width: 300 },
        { id: 2, label: 'Registration', width: 100 },
        { id: 3, label: 'VIN', width: 100 },
        { id: 4, label: 'Odometer', width: 100 },
        { id: 5, label: '', width: 10 }
    ];

    useEffect(() => {
        if (selectedCustomer?.id) {
            GetCustomerVehicles(selectedCustomer?.id, setResultsList);
            GetCustomerVehicles(selectedCustomer?.id, setResultsListAltered);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedVehicle?.id) {
            let singleRow = [];
            singleRow.push(selectedVehicle);
            setResultsListAltered(singleRow);
        } else {
            setResultsListAltered(resultsList);
        }
    }, [selectedVehicle, resultsList]);

    useEffect(() => {
        if (!customerVehicleDrawerOpen && selectedCustomerVehicle?.id) {
            let currentResults = [...resultsList];
            let index = currentResults.findIndex(
                (x) => x.id === selectedCustomerVehicle?.id
            );
            currentResults[index] = selectedCustomerVehicle;
            setResultsList(currentResults);
            setSelectedCustomerVehicle(null);
        }
        // eslint-disable-next-line
    }, [customerVehicleDrawerOpen]);

    useEffect(() => {
        if (vehicleAdded) {
            let currentResults = [...resultsList];
            currentResults.push(selectedCustomerVehicle);
            setResultsList(currentResults);
            setSelectedVehicle(selectedCustomerVehicle);
        }
        // eslint-disable-next-line
    }, [vehicleAdded]);

    return (
        <>
            <Paper>
                {!selectedCustomer?.id ? (
                    <Typography align="center">
                        Please select a customer to view / add associated
                        vehicles.
                    </Typography>
                ) : (
                    <>
                        <DataTable columns={columns}>
                            {selectedVehicle?.id ? (
                                <TableRow>
                                    <DataCell>
                                        <IconButton
                                            onClick={() =>
                                                setSelectedVehicle(null)
                                            }
                                            disabled={readOnly}
                                        >
                                            <CheckCircle />
                                        </IconButton>
                                    </DataCell>
                                    <DataCell>
                                        {YearFormatter(selectedVehicle.year)}{' '}
                                        {selectedVehicle.Make?.name}{' '}
                                        {selectedVehicle.Model?.name}{' '}
                                        {selectedVehicle.Series?.name}
                                    </DataCell>
                                    <DataCell>
                                        {selectedVehicle.registrationNumber}
                                    </DataCell>
                                    <DataCell>
                                        {selectedVehicle.vin
                                            ? selectedVehicle.vin.toUpperCase()
                                            : null}
                                    </DataCell>
                                    <DataCell>
                                        {selectedVehicle.odometer}
                                    </DataCell>
                                    <DataCell>
                                        <IconButton
                                            onClick={() => {
                                                setCustomerVehicleDrawerOpen(
                                                    true
                                                );
                                                setSelectedCustomerVehicle(
                                                    selectedVehicle
                                                );
                                            }}
                                        >
                                            <Notes />
                                        </IconButton>
                                    </DataCell>
                                </TableRow>
                            ) : (
                                resultsListAltered.map((row) => (
                                    <TableRow key={row.id}>
                                        <DataCell>
                                            <IconButton
                                                onClick={() =>
                                                    setSelectedVehicle(row)
                                                }
                                            >
                                                <RadioButtonUnchecked />
                                            </IconButton>
                                        </DataCell>
                                        <DataCell>
                                            {YearFormatter(row.year)}{' '}
                                            {row.Make?.name} {row.Model?.name}{' '}
                                            {row.Series?.name}
                                        </DataCell>
                                        <DataCell>
                                            {row.registrationNumber}
                                        </DataCell>
                                        <DataCell>
                                            {row.vin
                                                ? row.vin.toUpperCase()
                                                : null}
                                        </DataCell>
                                        <DataCell>{row.odometer}</DataCell>
                                        <DataCell>
                                            <IconButton
                                                onClick={() => {
                                                    setCustomerVehicleDrawerOpen(
                                                        true
                                                    );
                                                    setSelectedCustomerVehicle(
                                                        row
                                                    );
                                                }}
                                            >
                                                <Notes />
                                            </IconButton>
                                        </DataCell>
                                    </TableRow>
                                ))
                            )}
                        </DataTable>
                        {!selectedVehicle?.id && (
                            <Fab
                                customIcon={<Add />}
                                onClick={() =>
                                    setCustomerVehicleDrawerOpen(true)
                                }
                                noSession={true}
                            />
                        )}
                    </>
                )}
            </Paper>

            <Drawer
                openDrawer={customerVehicleDrawerOpen}
                setOpenDrawer={setCustomerVehicleDrawerOpen}
                title={
                    selectedCustomerVehicle?.id
                        ? `${YearFormatter(selectedCustomerVehicle?.year)} ${selectedCustomerVehicle?.Make?.name} ${selectedCustomerVehicle?.Model?.name} ${selectedCustomerVehicle?.Series ? selectedCustomerVehicle?.Series?.name : ''}`
                        : 'New Vehicle'
                }
                subTitle={`Customer: ${selectedCustomer?.companyName ? selectedCustomer?.companyName : selectedCustomer?.firstName + ' ' + selectedCustomer?.lastName}`}
                zIndex={1300}
            >
                <CustomerVehicleDrawer
                    selectedCustomer={selectedCustomer}
                    selectedCustomerVehicle={selectedCustomerVehicle}
                    setSelectedCustomerVehicle={setSelectedCustomerVehicle}
                    setVehicleAdded={setVehicleAdded}
                />
            </Drawer>
        </>
    );
};

export default CustomerVehicleForm;
