// IMPORTS
import { Dispatch, SetStateAction } from 'react'
// LOGIC
import api from '../../../../api'
// INTERFACES
import { AccountCategory } from '../interfaces/AdminInterface'

const GetAllAccountCategories = (setAccountCategories: Dispatch<SetStateAction<AccountCategory[]>>) => {
    api.get('accountCategories').then(res => {
        setAccountCategories(res.data)
    })
}

export default GetAllAccountCategories