import { Step } from '../../interface';

export const DebtorReconciliationSteps: Step[] = [
    {
        label: 'When selecting Account as the payment method during a sale, it adds the payment for the invoice on the Debtor Reconciliation page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-01.png'
    },
    {
        label: 'In the sidebar, click Admin -> Reconciliators -> Debtor Reconciliation to navigate to the Debtor Reconciliation page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-02.png'
    },
    {
        label: "Enter the debtor's name in the search bar and press Enter to search.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-03.png'
    },
    {
        label: "Click on the debtor's name to view all their payments.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-04.png'
    },
    {
        label: 'Each payment created using "Account" as a payment method will be displayed as Unpaid, partially paid (showing remaining amount), or Paid.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-05.png'
    },
    {
        label: 'Click the checkbox to select the payments you want to update. After selecting at least one payment, the "Make Account Payment" and "Manually Set to Paid" icons will appear.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-06.png'
    },
    {
        label: 'Click the $ icon: a dialog will appear allowing you to add an Account Payment.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-07.png'
    },
    {
        label: 'Enter the payment amount and date, then click "Submit Payment" to process the payment.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-08.png'
    },
    {
        label: 'The status of the payment you selected will be updated to show the remaining amount to pay, and a new line is created with a negative value indicating the payment.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-09.png'
    },
    {
        label: 'Click the three dots icon; a dialog will appear allowing you to edit/delete the payment or view payment history.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-10.png'
    },
    {
        label: 'Click the check icon to manually set the invoice to Paid; a confirmation dialog will appear.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-11.png'
    },
    {
        label: 'Click "Proceed" to confirm. The invoice status will change to Paid, but note that no new payment line will be recorded and this action cannot be undone.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-12.png'
    },
    {
        label: 'The invoice status will change to Paid.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-13.png'
    },
    {
        label: "You can find the Account Payment History in the Account Terms & Payments tab of that Customer's Page, in can be accessed through the Customer Table.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-14.png'
    },
    {
        label: 'You can also print an account statement and email it to the debtor. Clicking the Printer icon will open a print statement dialog.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-15.png'
    },
    {
        label: 'Select the desired month, decide whether to display proforma orders, and click "Generate Statement" to produce the statement, or click "Email To Customer" to send the statement via email to the customer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-16.png'
    },
    {
        label: 'You can also generate a debtor balance report; a PDF file will be downloaded automatically.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/debtorReconciliation/debtor-recon-17.png'
    }
];
