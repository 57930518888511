import api from '../../../../../api';
// INTERFACES
import { Invoice } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleEditInvoice = (
    InvoiceId: number,
    invoiceDetails: Invoice,
    showSnackbar: showSnackbar
) => {
    api.put(`editInvoiceDetails/${InvoiceId}`, invoiceDetails).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Invoice successfully updated.',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleEditInvoice;
