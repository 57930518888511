// IMPORTS
import { useState } from 'react';
import {
    Typography,
    TableRow,
    TableCell,
    IconButton,
    Drawer,
    Box,
    Table,
    TableHead,
    Divider,
    Tooltip
} from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import { Dayjs } from 'dayjs';

interface PartsProps {
    partData: {
        OnlineOrderId: number;
        OrderId: number;
        ServiceId: number;
        VehicleId: number;
        VehicleSaleId: number;
        customerName: string;
        numberParts: number;
        orderType: string;
        totalCost: number;
        totalSale: number;
        serviceType?: string;
    }[];
    wrongPartData: {
        OrderId: number;
        costAverage: number;
        costDaily: number;
        message: string;
        orderType: string;
        submessage: string;
        partNumber: string;
    }[];
    partValues: {
        onlineCost: number;
        onlineRevenue: number;
        onlineTotal: number;
        partCost: number;
        partRevenue: number;
        partTotal: number;
        saleCost: number;
        saleRevenue: number;
        saleTotal: number;
        serviceCost: number;
        serviceRevenue: number;
        serviceTotal: number;
        vehicleCost: number;
        vehicleRevenue: number;
        vehicleTotal: number;
    };
    queryDate: { startDate: Dayjs; endDate: Dayjs };
}

const Parts = (props: PartsProps) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState('');

    const GetWrongPartMessage = (id) => {
        let returnMessage = '';
        for (let wrongPart of props.wrongPartData.filter(
            (x) => x.OrderId === id
        )) {
            if (returnMessage.length === 0) {
                returnMessage = `${wrongPart.message} for: ${wrongPart.partNumber}`;
            } else {
                returnMessage = `${returnMessage}. ${wrongPart.message} for: ${wrongPart.partNumber}`;
            }
        }
        return returnMessage;
    };

    let partTotal =
        props.partValues.partTotal +
        props.partValues.saleTotal +
        props.partValues.serviceTotal +
        props.partValues.onlineTotal +
        props.partValues.vehicleTotal;
    let partCost =
        props.partValues.partCost +
        props.partValues.saleCost +
        props.partValues.serviceCost +
        props.partValues.onlineCost +
        props.partValues.vehicleCost;
    let partRevenue =
        props.partValues.partRevenue +
        props.partValues.saleRevenue +
        props.partValues.serviceRevenue +
        props.partValues.onlineRevenue +
        props.partValues.vehicleRevenue;
    return (
        <>
            <TableRow>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        <b>Parts & Accessories</b>
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {partTotal}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {CurrencyFormatter(partCost)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {CurrencyFormatter(partRevenue)}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle1" align="center">
                        {CurrencyFormatter(partRevenue - partCost)}
                    </Typography>
                </TableCell>
                <TableCell>
                    {props.wrongPartData.length > 0 ? (
                        <IconButton
                            onClick={() => {
                                setDrawerOpen(true);
                                setDrawerTitle('Missing Part Data');
                            }}
                        >
                            <PriorityHigh color="warning" fontSize="small" />
                        </IconButton>
                    ) : null}
                </TableCell>
            </TableRow>

            {partTotal > 0 ? (
                <>
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                Parts
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.partValues.partTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.partValues.partTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Parts Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.partValues.partTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(props.partValues.partCost)}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.partRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.partRevenue -
                                        props.partValues.partCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                Vehicle
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.partValues.vehicleTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.partValues.vehicleTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Vehicle Parts Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.partValues.vehicleTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.vehicleCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.vehicleRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.vehicleRevenue -
                                        props.partValues.vehicleCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                Sales
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.partValues.saleTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.partValues.saleTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Sales Parts Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.partValues.saleTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(props.partValues.saleCost)}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.saleRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.saleRevenue -
                                        props.partValues.saleCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                Service
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.partValues.serviceTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.partValues.serviceTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Service Parts Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.partValues.serviceTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.serviceCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.serviceRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.serviceRevenue -
                                        props.partValues.serviceCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                Online
                            </Typography>
                        </TableCell>
                        <TableCell
                            id={
                                props.partValues.onlineTotal > 0
                                    ? 'table-link'
                                    : ''
                            }
                            onClick={() => {
                                setDrawerOpen(
                                    props.partValues.onlineTotal > 0
                                        ? true
                                        : false
                                );
                                setDrawerTitle('Online Parts Data');
                            }}
                        >
                            <Typography variant="subtitle2" align="center">
                                {props.partValues.onlineTotal}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(props.partValues.onlineCost)}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.onlineRevenue
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" align="center">
                                {CurrencyFormatter(
                                    props.partValues.onlineRevenue -
                                        props.partValues.onlineCost
                                )}
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </>
            ) : null}

            <Drawer
                anchor={'right'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                style={{ zIndex: 1200 }}
            >
                <Box
                    sx={{ width: '40vw', padding: '20px' }}
                    role="presentation"
                >
                    <Typography variant="h4">{drawerTitle}</Typography>
                    <Typography>
                        {props.queryDate.startDate?.format('DD MMMM YYYY')} -{' '}
                        {props.queryDate.endDate?.format('DD MMMM YYYY')}
                    </Typography>
                    <Box height={'10px'}></Box>
                    <Divider></Divider>
                    <Box height="20px"></Box>
                    {drawerTitle === 'Parts Data' ||
                    drawerTitle === 'Service Parts Data' ||
                    drawerTitle === 'Vehicle Parts Data' ||
                    drawerTitle === 'Sales Parts Data' ||
                    drawerTitle === 'Online Parts Data' ? (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {drawerTitle ===
                                        'Service Parts Data' ? (
                                            <TableCell width="15%">
                                                <strong>Service ID</strong>
                                            </TableCell>
                                        ) : (
                                            <TableCell width="15%">
                                                <strong>Order ID</strong>
                                            </TableCell>
                                        )}
                                        {drawerTitle === 'Parts Data' ? null : (
                                            <TableCell width="15%">
                                                <strong>
                                                    {drawerTitle ===
                                                    'Service Parts Data'
                                                        ? 'Order ID'
                                                        : drawerTitle ===
                                                            'Vehicle Parts Data'
                                                          ? 'Vehicle ID'
                                                          : drawerTitle ===
                                                              'Sales Parts Data'
                                                            ? 'Sale ID'
                                                            : 'Online Order ID'}
                                                </strong>
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <strong>Number of Parts</strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>Total Cost (Ex GST)</strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>
                                                Total Revenue (Ex GST)
                                            </strong>
                                        </TableCell>
                                        <TableCell width="35%">
                                            <strong>Gross Profits</strong>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>

                                {props.partData.map((sale) => {
                                    if (
                                        drawerTitle === 'Vehicle Parts Data' &&
                                        sale.orderType === 'Vehicle'
                                    ) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/inventory/viewSale/${sale.OrderId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {sale.OrderId}
                                                </TableCell>
                                                <TableCell>
                                                    {sale.VehicleId}
                                                </TableCell>
                                                <TableCell>
                                                    {sale.numberParts}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale -
                                                            sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                {GetWrongPartMessage(
                                                    sale.OrderId
                                                ).length > 0 ? (
                                                    <TableCell>
                                                        <Tooltip
                                                            title={GetWrongPartMessage(
                                                                sale.OrderId
                                                            )}
                                                        >
                                                            <PriorityHigh
                                                                color="warning"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell></TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    } else if (
                                        drawerTitle === 'Service Parts Data' &&
                                        sale.orderType === 'Service'
                                    ) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/service/viewBooking/${sale.ServiceId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {sale.ServiceId}
                                                </TableCell>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/inventory/viewSale/${sale.OrderId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {sale.OrderId}
                                                </TableCell>
                                                <TableCell>
                                                    {sale.numberParts}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale -
                                                            sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                {GetWrongPartMessage(
                                                    sale.OrderId
                                                ).length > 0 ? (
                                                    <TableCell>
                                                        <Tooltip
                                                            title={GetWrongPartMessage(
                                                                sale.OrderId
                                                            )}
                                                        >
                                                            <PriorityHigh
                                                                color="warning"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell></TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    } else if (
                                        drawerTitle === 'Sales Parts Data' &&
                                        sale.orderType === 'Sale'
                                    ) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/inventory/viewSale/${sale.OrderId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {sale.OrderId}
                                                </TableCell>
                                                <TableCell>
                                                    {sale.VehicleSaleId}
                                                </TableCell>
                                                <TableCell>
                                                    {sale.numberParts}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale -
                                                            sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                {GetWrongPartMessage(
                                                    sale.OrderId
                                                ).length > 0 ? (
                                                    <TableCell>
                                                        <Tooltip
                                                            title={GetWrongPartMessage(
                                                                sale.OrderId
                                                            )}
                                                        >
                                                            <PriorityHigh
                                                                color="warning"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell></TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    } else if (
                                        drawerTitle === 'Online Parts Data' &&
                                        sale.orderType === 'Online'
                                    ) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/inventory/viewSale/${sale.OrderId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {sale.OrderId}
                                                </TableCell>
                                                <TableCell>
                                                    {sale.OnlineOrderId}
                                                </TableCell>
                                                <TableCell>
                                                    {sale.numberParts}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale -
                                                            sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                {GetWrongPartMessage(
                                                    sale.OrderId
                                                ).length > 0 ? (
                                                    <TableCell>
                                                        <Tooltip
                                                            title={GetWrongPartMessage(
                                                                sale.OrderId
                                                            )}
                                                        >
                                                            <PriorityHigh
                                                                color="warning"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell></TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    } else if (
                                        drawerTitle === 'Parts Data' &&
                                        sale.orderType === 'Part Sale'
                                    ) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className="table-link"
                                                    onClick={() =>
                                                        window.open(
                                                            `/inventory/viewSale/${sale.OrderId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {sale.OrderId}
                                                </TableCell>
                                                <TableCell>
                                                    {sale.numberParts}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale
                                                    )}{' '}
                                                </TableCell>
                                                <TableCell>
                                                    {' '}
                                                    {CurrencyFormatter(
                                                        sale.totalSale -
                                                            sale.totalCost
                                                    )}{' '}
                                                </TableCell>
                                                {GetWrongPartMessage(
                                                    sale.OrderId
                                                ).length > 0 ? (
                                                    <TableCell>
                                                        <Tooltip
                                                            title={GetWrongPartMessage(
                                                                sale.OrderId
                                                            )}
                                                        >
                                                            <PriorityHigh
                                                                color="warning"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell></TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Table>
                        </>
                    ) : (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="15%">
                                            <strong>Sale ID</strong>
                                        </TableCell>
                                        <TableCell width="15%">
                                            <strong>Sale Type</strong>
                                        </TableCell>
                                        <TableCell width="70%">
                                            <strong>Information</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {props.wrongPartData.map((sale) => (
                                    <TableRow>
                                        <TableCell
                                            className="table-link"
                                            onClick={() =>
                                                window.open(
                                                    `/inventory/viewSale/${sale.OrderId}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {sale.OrderId}
                                        </TableCell>
                                        <TableCell>{sale.orderType}</TableCell>
                                        <TableCell>
                                            {sale.message}
                                            <br />
                                            <Typography variant="caption">
                                                {sale.submessage}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default Parts;
