import dayjs from 'dayjs';
import {
    ValidateABN,
    ValidateEmail
} from '../../../global/logic/customerValidation/ValidateCustomerFields';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const checkEmail = (index, importedData, showSnackbar: showSnackbar) => {
    for (let [id, item] of importedData.entries()) {
        if (item[index].length > 0) {
            let validEmail = ValidateEmail(item[index] ?? '');
            if (validEmail.verified === false) {
                showSnackbar(
                    `Invalid email found at line: ${id + 1}`,
                    validEmail.subMessage + '\nEmail found: ' + item[index],
                    'error'
                );
                return validEmail;
            }
        }
    }
    return { verified: true };
};

export const checkABN = (index, importedData, showSnackbar: showSnackbar) => {
    for (let [id, item] of importedData.entries()) {
        let validABN = ValidateABN(item[index] ?? '');
        if (validABN.verified === false) {
            showSnackbar(
                `Invalid ABN found at line: ${id + 1}`,
                validABN.subMessage,
                'error'
            );
            return validABN;
        }
    }
    return { verified: true };
};

export const checkCustomerType = (
    index,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (item[index] !== 'Retail' && item[index] !== 'Trade') {
            showSnackbar(
                `Invalid Customer Type found at line: ${id + 1}`,
                'Customer Type must be "Retail" or "Trade"',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkPostcode = (
    index,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (
            item[index].length > 0 &&
            (item[index].length !== 4 || Number.isNaN(parseFloat(item[index])))
        ) {
            showSnackbar(
                `Invalid Postcode found at line: ${id + 1}`,
                'Postcode must be 4 digits.',
                'error'
            );
            return { valid: false, index: index };
        }
    }
    return { valid: true };
};

export const checkDOB = (index, importedData, showSnackbar: showSnackbar) => {
    for (let [id, item] of importedData.entries()) {
        let currentDate = item[index];
        if (currentDate) {
            let date = item[index].split('/');
            if (date.length !== 3) {
                showSnackbar(
                    `Invalid Date of Birth found at line: ${id + 1}`,
                    'Date of Birth must be in the following format: DD/MM/YYYY',
                    'error'
                );
                return { valid: false, index: index };
            } else {
                let day = date[0].length === 1 ? '0' + date[0] : date[0];
                let month = date[1].length === 1 ? '0' + date[1] : date[1];
                let year = date[2];
                if (year.length !== 4) {
                    showSnackbar(
                        `Invalid Date of Birth found at line: ${id + 1}`,
                        'Year must be 4 digits',
                        'error'
                    );
                    return { valid: false, index: index };
                }
                if (!dayjs(`${day}/${month}/${year}`).isValid()) {
                    showSnackbar(
                        `Invalid Date of Birth found at line: ${id + 1}`,
                        `Date of Birth found is: ${day}/${month}/${year}. If this is a the correct format, please contact the Ora Team.`,
                        'error'
                    );
                    return { valid: false, index: index };
                }
            }
        }
    }
    return { valid: true };
};
