import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import jsPDF from 'jspdf';
import PrintSingleJobCard from '../logic/PrintSingleJobCard';
import api from '../../../../../api';
import { Dispatch, SetStateAction } from 'react';

const PrintDailyJobCard = ({
    selectedDay,
    listOfJobs,
    setOpenJobCardPrint
}: {
    selectedDay: Dayjs;
    listOfJobs: any[];
    setOpenJobCardPrint: Dispatch<SetStateAction<boolean>>;
}) => {
    const getDailyJob = () => {
        let dailyJobs = listOfJobs.filter((x) =>
            dayjs(x.start).isSame(selectedDay, 'day')
        );
        return dailyJobs;
    };

    const PrintAllJobCard = async () => {
        let doc = new jsPDF();
        let page = 1;
        await api
            .get(
                `settingsNextGen/${localStorage.getItem('SiteId')}?includeImage=true`
            )
            .then(async (res) => {
                let settings = res.data.settings;
                let site = res.data.site;

                // Create and format an image object
                let newImg = document.createElement('img');
                newImg.src = res.data.image;
                newImg.className = 'logo';
                let image = newImg;

                for await (let job of getDailyJob()) {
                    let vehicleType =
                        job.jobData.CustomerVehicleId != null
                            ? 'customer'
                            : 'stock';
                    let vehicle =
                        job.jobData.CustomerVehicleId != null
                            ? job.jobData.CustomerVehicle
                            : job.jobData.Vehicle;
                    page = await PrintSingleJobCard(
                        doc,
                        job.jobData,
                        job.jobData.Customer,
                        vehicleType,
                        vehicle,
                        page,
                        settings,
                        site,
                        image
                    );
                    page += 1;
                    doc.addPage();
                    doc.setPage(page);
                }
                doc.deletePage(page);
                window.open(doc.output('bloburl'));
                setOpenJobCardPrint(false);
            });
    };

    return (
        <>
            <DialogTitle>
                Print All Job Card for: {selectedDay.format('dddd DD/MM/YYYY')}
            </DialogTitle>
            <DialogContent>
                Do you wish the print the {getDailyJob().length} Job Card?
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => setOpenJobCardPrint(false)}
                >
                    Cancel
                </Button>
                <Button variant="contained" onClick={() => PrintAllJobCard()}>
                    Print Job Card
                </Button>
            </DialogActions>
        </>
    );
};

export default PrintDailyJobCard;
