// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';

// This function gets a list of all the duplicate customers that have the same selected key
const GetDuplicateCustomers = (
  duplicateChoice: string,
  setDuplicateCustomers: Dispatch<
    SetStateAction<{ key: string; mergeId: number; rows: Customer[] }[]>
  >,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  setLoading(true);
  api
    .get(`findDuplicateCustomers?duplicateChoice=${duplicateChoice}`)
    .then((res) => {
      setDuplicateCustomers(res.data);
    });
};

export default GetDuplicateCustomers;
