// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';

const MergeQuantity = (
    mergeFrom: string,
    rowId: number,
    saleDetails: Order,
    setSaleDetails: Dispatch<SetStateAction<Order>>
) => {
    // Get the line being adjusted
    let currentOrderLines = [...saleDetails.orderLines];
    let index = currentOrderLines.findIndex((x) => x.rowId === rowId);

    // If we are merging from quantity, add the backorder and quantity values together and replace backorderQuantity
    if (mergeFrom === 'quantity') {
        currentOrderLines[index].backorderQuantity = String(
            parseInt(currentOrderLines[index].backorderQuantity) +
                parseInt(currentOrderLines[index].quantity)
        );
        currentOrderLines[index].quantity = '0';
    }

    // Otherwise we are merging from backorder, add the backorder and quantity values together and replace quantity
    else {
        currentOrderLines[index].quantity = String(
            parseInt(currentOrderLines[index].backorderQuantity) +
                parseInt(currentOrderLines[index].quantity)
        );
        currentOrderLines[index].backorderQuantity = '0';
    }

    setSaleDetails({ ...saleDetails, orderLines: currentOrderLines });
};

export default MergeQuantity;
