// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { PaymentLine } from '../../../global/interfaces/GeneralInterface';

const HandleDeleteService = (
    id: number,
    paymentLines: PaymentLine[],
    showSnackbar: showSnackbar
) => {
    if (paymentLines.length > 0) {
        showSnackbar(
            'Unable to delete booking.',
            'Please remove any remaining payments to delete.',
            'error'
        );
    }

    api.delete(
        `deleteService/${id}?SiteId=${localStorage.getItem('SiteId')}`
    ).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Booking Deleted',
                'You will now be redirected to the bookings table.'
            );
            window.location.href = '/service/bookings';
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact the IT department.',
                'error'
            );
        }
    });
};

export default HandleDeleteService;
