// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
import { Order, Unit } from '../../../global/interfaces/PartsInterface';

const GetDrawerContent = (
    drawerType: string,
    partUnit: Unit,
    siteId: number,
    setDrawerContent: Dispatch<SetStateAction<any>>
) => {
    api.get(
        `getPartDrawerContent/${drawerType}?UnitId=${partUnit.id}&SiteId=${siteId}`
    ).then((res) => {
        if (drawerType === 'Reserved') {
            // If it's reserved, we need to filter out every order where the parts was collected (so not reserved anymore, it's already been taken out of stock)
            let data: Order[] = res.data;
            let newData = [];
            for (let order of data) {
                let newOrderLines = order.orderLines.filter(
                    (x) => x.UnitId === partUnit.id && !x.collecting
                );

                if (newOrderLines.length > 0) {
                    newData.push({ ...order, orderLines: newOrderLines });
                }
            }
            setDrawerContent(newData);
        } else {
            setDrawerContent(res.data);
        }
    });
};

export default GetDrawerContent;
