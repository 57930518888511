const ClearTokenOnExit = () => {
    const handleUnload = () => {
        localStorage.removeItem('token');
    };

    // const handleVisibilityChange = () => {
    //     if (document.visibilityState === 'hidden') {
    //         localStorage.removeItem('token'); // Remove token when tab becomes inactive, for example, when user switches to another tab
    //     }
    // };

    // Attach the event listener
    window.addEventListener('beforeunload', handleUnload);
    // document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener
    return () => {
        window.removeEventListener('beforeunload', handleUnload);
        // document.removeEventListener(
        //     'visibilitychange',
        //     handleVisibilityChange
        // );
    };
};

export default ClearTokenOnExit;
