import { Registration } from "../interfaces/VehicleSaleInterface";

const CalculateStampDuty = (registrationData: Registration[], cylinders: string, totalPrice: number) => {
    var stampDuty = 0;
    var roundedPrice = Math.round(totalPrice);

    if (parseInt(cylinders) <= 4) {
        registrationData.forEach(line => {
            line.stampDuty.forEach(duty => {
                if (duty.definition === '1-4 Cylinders') {
                    stampDuty = duty.percentage * (roundedPrice / 100);
                }
            })
        })
    }
    else if (parseInt(cylinders) <= 6 && parseInt(cylinders) >= 5) {
        registrationData.forEach(line => {
            line.stampDuty.forEach(duty => {
                if (duty.definition === '5-6 Cylinders') {
                    stampDuty = duty.percentage * (roundedPrice / 100);
                }
            })
        })
    }

    else if (parseInt(cylinders) >= 7) {
        registrationData.forEach(line => {
            line.stampDuty.forEach(duty => {
                if (duty.definition === '7+ Cylinders') {
                    stampDuty = duty.percentage * (roundedPrice / 100);
                }
            })
        })
    }

    var roundedStampDuty = Math.round(stampDuty);

    return roundedStampDuty
}

export default CalculateStampDuty;