import { Typography, Grid } from '@mui/material';
import Paper from '../../../global/Paper';
import PieChart from '../../../global/dashboard/PieChart';

const BookingCharts = ({ typePieData, title }) => {
    return (
        <Grid item xs={6} textAlign="center">
            <Paper>
                <Typography variant="subtitle1">{title} </Typography>
                <br />
                {typePieData.length > 0 ? (
                    <PieChart data={typePieData}></PieChart>
                ) : (
                    <Typography variant="caption">None to show.</Typography>
                )}
            </Paper>
        </Grid>
    );
};

export default BookingCharts;
