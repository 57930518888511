import { Divider, Grid, Typography } from '@mui/material';

const line = (title, description) => {
    return (
        <>
            <Grid item xs={4}>
                {title}
            </Grid>
            <Grid item xs={8}>
                <Typography variant="caption">{description}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </>
    );
};

export const CreditorCSVExplanation = () => {
    return (
        <Grid item xs={12}>
            <Divider />
            <Grid container spacing={0} alignItems={'center'}>
                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                    <Typography>
                        Ora accepts the following value to be in the CSV in
                        order to create the Creditor:
                    </Typography>
                </Grid>
                {line(
                    'Code (required)',
                    'Must be 3 characters (example: HON for Honda).'
                )}
                {line('Name (required)', 'Can be any string of characters.')}
                {line('ABN', 'Must be 11 digits.')}
                {line('Phone Number', 'Can be any string of characters.')}
                {line('Email', 'Can be any string of characters.')}
                {line('Address Line', 'Can be any string of characters.')}
                {line('Suburb', 'Can be any string of characters.')}
                {line('State', 'Can be any string of characters.')}
                {line('Postcode', 'Must be 4 digits.')}
                {line('Country', 'Can be any string of characters.')}
                {line(
                    'Representative',
                    'Name of the sales representative you are in contact with. Can be any string of characters.'
                )}
                {line(
                    'Account Name (recommended)',
                    "Name of the creditor's bank account. Can be any string of characters."
                )}
                {line(
                    'BSB (recommended)',
                    "BSB of the creditor's bank account. Can be any string of characters."
                )}
                {line(
                    'Account Number (recommended)',
                    "Account Number of the creditor's bank account. Can be any string of characters."
                )}
                {line(
                    'Default Reference (recommended)',
                    'Default reference that will appear on any bank transaction. Can be any string of characters.'
                )}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '10px' }}>
                <Typography variant="caption">
                    The recommended fields are used in the Creditor
                    Reconciliation page of Ora to handle invoice payment. Each
                    field is used to generate an ABA file that can be uploaded
                    to your bank portal.
                </Typography>
            </Grid>
        </Grid>
    );
};
