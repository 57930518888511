import { ManualInvoice } from '../../../global/interfaces/AdminInterface';
import PrintGeneratedInvoice from './PrintGeneratedInvoice';

const ReprintGeneratedInvoice = (selectedInvoice: ManualInvoice) => {
    let creditorData = selectedInvoice.Supplier;
    let customerData = selectedInvoice.Customer;
    let body = [];
    let text = selectedInvoice.additionalText;
    let SiteId = selectedInvoice.SiteId;

    for (let line of selectedInvoice.ManualInvoiceLines) {
        body.push([
            line.description,
            line.quantity,
            line.amount,
            line.amount * 0.1,
            line.amount * line.quantity * 1.1
        ]);
    }

    PrintGeneratedInvoice(
        creditorData,
        customerData,
        body,
        text,
        SiteId,
        selectedInvoice.id
    );
};

export default ReprintGeneratedInvoice;
