import { Step } from '../../interface';

export const AddAccountSteps: Step[] = [
    {
        label: `In the sidebar, click Admin > Accounts`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/add-01.png'
    },
    {
        label: `Hover the speed dial menu and click 'Add Account'`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/add-02.png'
    },
    {
        label: 'Enter the account name',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/add-03.png'
    },
    {
        label: 'Click the "Account Type" field and select a type from the dropdown menu',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/add-04.png'
    },
    {
        label: 'Click the "Site" field and select a site from the dropdown menu',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/add-05.png'
    },
    {
        label: 'Click the ‘Save’ icon to add the new account. ',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/add-06.png'
    },
    {
        label: `After the account is added, you can view its details by clicking on the account ID`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/update-01.png'
    }
];

export const UpdateAccountSteps: Step[] = [
    {
        label: `Click the account ID`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/update-01.png'
    },
    {
        label: `Click the Edit icon in the speed dial menu`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/update-02.png'
    },
    {
        label: `Make the necessary changes and click the Save icon`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/update-03.png'
    }
];

export const DeleteAccountSteps: Step[] = [
    {
        label: `Click the account ID`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/update-01.png'
    },
    {
        label: `Click the Edit icon in the speed dial menu`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/update-02.png'
    },
    {
        label: `Click the Delete icon`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/delete-03.png'
    }
];

export const SearchAccountsSteps: Step[] = [
    {
        label: `To search accounts by keywords, click the Search Account field, enter the account name, ID, type, or location. Press Enter. The search results will be displayed`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/search-01.png'
    },
    {
        label: `To filter accounts, click the Filter icon. Filters are currently supported for Account Create Date, Account Type, and Site. You can select a single filter or combine multiple filters. You also have the option to hide fully reconciled accounts.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/search-02.png'
    },
    {
        label: `To remove a filter, click the X icon next to the filter. Alternatively, refresh the page to clear all filters.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/search-03.png'
    }
];

export const MergeAccountsSteps: Step[] = [
    {
        label: `Click the account ID of the account you wish to merge (for example, #289 Test Account 1). This will open the account detail drawer.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/merge-01.png'
    },
    {
        label: `Click the Merge icon. This will open a merge account dialog.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/merge-02.png'
    },
    {
        label: `Click the Account field to select the account into which you want to merge the current one. You can search by account name or ID. For example, select # Test Account 2.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/merge-03.png'
    },
    {
        label: `Click the 'Proceed' button if you are sure about the merge. This process will delete the initial account you selected (#289 Test Account 1) but preserve and transfer all its associated data to the account you selected in the Dialog (#292 Test Account 2). `,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/merge-04.png'
    }
];

export const ExportAccountsSteps: Step[] = [
    {
        label: `To export account results to a CSV file, simply click the 'Export Current Results' icon, the results will automatically download to your device.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/accounts/export-01.png'
    }
];
