import { Box, CircularProgress, Typography } from '@mui/material';
import PageWrapper from '../../../../global/PageWrapper';

const DealerSignBankDetails = ({ pdfUrl, contractStatus }) => {
    return (
        <PageWrapper>
            {pdfUrl ? (
                <Box width="100%" height="200vh">
                    <iframe
                        src={pdfUrl + '#view=Fit'}
                        width="100%"
                        height="100%"
                        title={'Bank Details'}
                    >
                        {' '}
                        Loading PDF...
                    </iframe>
                </Box>
            ) : (
                <Box width="100%" height="100%" textAlign={'center'}>
                    {contractStatus ? (
                        <Typography variant="h5">No File Found</Typography>
                    ) : (
                        <>
                            <CircularProgress />
                            <Typography variant="h5">
                                Loading form...
                            </Typography>
                        </>
                    )}
                </Box>
            )}
        </PageWrapper>
    );
};

export default DealerSignBankDetails;
