import { Dispatch, SetStateAction } from 'react';
import { Series } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const UpdateSerieName = (
    serie: Series,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.put('serieName/' + serie.id, {
        newName: serie.name.toUpperCase()
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Serie name has been updated.');
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar('Serie already exist.', '', 'error');
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Something went wrong, please refresh the page and try again.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default UpdateSerieName;
