import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { TradeIn } from '../../../global/interfaces/VehicleSaleInterface';
import Paper from '../../../global/Paper';
import {
    Chip,
    Dialog,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Table,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import {
    CurrencyFormatter,
    YearFormatter
} from '../../../global/logic/Formatters';
import TradeForm from './TradeForm';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import TotalTrades from '../logic/TotalTrades';
import moment from 'moment';
import { Site } from '../../../global/interfaces/GeneralInterface';
import { SiteContext } from '../../../../main/Main';

interface TradeVehiclesProps {
    id: number;
    readOnly: boolean;
    tradeVehicles: TradeIn[];
    setTradeVehicles: Dispatch<SetStateAction<TradeIn[]>>;
    showSnackbar: showSnackbar;
    gstType: string;
    setGstType: Dispatch<SetStateAction<string>>;
}

const TradeVehicles = ({
    id,
    readOnly,
    tradeVehicles,
    setTradeVehicles,
    showSnackbar,
    gstType,
    setGstType
}: TradeVehiclesProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const [vehicleFormOpen, setVehicleFormOpen] = useState<boolean>(false);

    let tradeTotal = TotalTrades(tradeVehicles);

    let columns = [];

    if (id) {
        columns =
            siteDetails.vehicleTypeSold === 'Motorcycles'
                ? [
                      { id: 0, label: 'ID', width: 100 },
                      { id: 1, label: 'Vehicle', width: 600 },
                      {
                          id: 2,
                          label: `Base Cost ${gstType === 'INC' ? '(INC. GST)' : '(EX. GST)'}`,
                          width: 400
                      },
                      { id: 3, label: 'VIN', width: 400 },
                      { id: 4, label: 'Registration', width: 400 },
                      { id: 5, label: 'Availability', width: 200 }
                  ]
                : [
                      { id: 0, label: 'ID', width: 100 },
                      { id: 1, label: 'Vehicle', width: 600 },
                      {
                          id: 2,
                          label: `Base Cost ${gstType === 'INC' ? '(INC. GST)' : '(EX. GST)'}`,
                          width: 400
                      },
                      { id: 5, label: 'Availability', width: 200 }
                  ];
    } else {
        columns =
            siteDetails.vehicleTypeSold === 'Motorcycles'
                ? [
                      { id: 1, label: 'Vehicle', width: 600 },
                      {
                          id: 2,
                          label: `Base Cost ${gstType === 'INC' ? '(INC. GST)' : '(EX. GST)'}`,
                          width: 400
                      },
                      { id: 3, label: 'VIN', width: 400 },
                      { id: 4, label: 'Registration', width: 400 },
                      { id: 5, label: 'Availability', width: 200 },
                      { id: 6, label: '', width: 100 }
                  ]
                : [
                      { id: 1, label: 'Vehicle', width: 600 },
                      {
                          id: 2,
                          label: `Base Cost ${gstType === 'INC' ? '(INC. GST)' : '(EX. GST)'}`,
                          width: 400
                      },
                      { id: 5, label: 'Availability', width: 200 },
                      { id: 6, label: '', width: 100 }
                  ];
    }

    const removeTradeVehicle = (lineIndex: number) => {
        const newTradeList = [];

        tradeVehicles.forEach((item, index) => {
            if (index !== lineIndex) {
                newTradeList.push(item);
            }
        });

        setTradeVehicles(newTradeList);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Typography variant="h6">
                                    Trades
                                    {!readOnly ? (
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                setVehicleFormOpen(true)
                                            }
                                        >
                                            <Add fontSize="small" />
                                        </IconButton>
                                    ) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} textAlign="right">
                                <FormControl disabled={readOnly}>
                                    <RadioGroup
                                        row
                                        value={gstType}
                                        onChange={(e) =>
                                            setGstType(e.target.value)
                                        }
                                    >
                                        <FormControlLabel
                                            value="INC"
                                            control={<Radio />}
                                            label="INC. GST"
                                        />
                                        <FormControlLabel
                                            value="EX"
                                            control={<Radio />}
                                            label="EX. GST"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {tradeVehicles.length > 0 ? (
                            <DataTable columns={columns}>
                                {tradeVehicles.map((trade, index) => (
                                    <TableRow>
                                        {id ? (
                                            <DataCellColoured
                                                handleClick={() =>
                                                    window.open(
                                                        `/vehicles/view/${trade?.Vehicle?.id}`,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                {trade?.Vehicle?.id}
                                            </DataCellColoured>
                                        ) : null}
                                        <DataCell>
                                            {YearFormatter(
                                                trade?.Vehicle?.year
                                            )}{' '}
                                            {trade?.Vehicle?.Make?.name}{' '}
                                            {trade?.Vehicle?.Model?.name}{' '}
                                            {trade?.Vehicle?.Series?.name}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                trade?.tradeInPrice
                                            )}
                                        </DataCell>
                                        {siteDetails.vehicleTypeSold ===
                                        'Motorcycles' ? (
                                            <>
                                                <DataCell>
                                                    {trade?.Vehicle?.vin}
                                                </DataCell>
                                                {trade?.Vehicle
                                                    ?.registrationNumber ? (
                                                    <DataCell>
                                                        {
                                                            trade?.Vehicle
                                                                ?.registrationNumber
                                                        }{' '}
                                                        {trade?.Vehicle
                                                            ?.registrationDueDate ? (
                                                            <>
                                                                <br /> Due:{' '}
                                                                {moment(
                                                                    trade
                                                                        ?.Vehicle
                                                                        ?.registrationDueDate
                                                                ).format(
                                                                    'DD/MM/YYYY'
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell></DataCell>
                                                )}
                                            </>
                                        ) : null}

                                        <DataCell>
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                label={
                                                    trade?.Vehicle?.available
                                                        ? 'Available'
                                                        : 'Not Available'
                                                }
                                                color={
                                                    trade?.Vehicle?.available
                                                        ? 'success'
                                                        : 'error'
                                                }
                                            />
                                        </DataCell>
                                        {!id ? (
                                            <DataCell>
                                                <IconButton
                                                    disabled={
                                                        trade?.Vehicle
                                                            ?.available ||
                                                        readOnly
                                                    }
                                                    onClick={() =>
                                                        removeTradeVehicle(
                                                            index
                                                        )
                                                    }
                                                >
                                                    <Close />
                                                </IconButton>
                                            </DataCell>
                                        ) : null}
                                    </TableRow>
                                ))}
                            </DataTable>
                        ) : (
                            <Typography variant="body1">
                                No vehicles entered on this trade.
                            </Typography>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper>
                        <Typography variant="h6">Total</Typography>
                        <Divider />
                        <Table size="small">
                            <TableRow>
                                <TableCell
                                    width="70%"
                                    align="left"
                                    sx={{ borderBottom: 0 }}
                                >
                                    {' '}
                                    Trades Total (EX. GST)
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        borderBottom: 0,
                                        paddingRight: 0,
                                        paddingLeft: 0
                                    }}
                                >
                                    {gstType === 'INC'
                                        ? CurrencyFormatter(tradeTotal / 1.1)
                                        : CurrencyFormatter(tradeTotal)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    width="70%"
                                    align="left"
                                    sx={{ borderBottom: 0 }}
                                >
                                    GST
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        borderBottom: 0,
                                        paddingRight: 0,
                                        paddingLeft: 0
                                    }}
                                >
                                    {gstType === 'INC'
                                        ? CurrencyFormatter(tradeTotal / 11)
                                        : CurrencyFormatter(
                                              (tradeTotal * 1.1) / 11
                                          )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width="70%" align="left">
                                    <b>Trades Total (INC. GST)</b>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ paddingRight: 0, paddingLeft: 0 }}
                                >
                                    <b>
                                        {gstType === 'INC'
                                            ? CurrencyFormatter(tradeTotal)
                                            : CurrencyFormatter(
                                                  tradeTotal * 1.1
                                              )}
                                    </b>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                open={vehicleFormOpen}
                onClose={() => setVehicleFormOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <TradeForm
                    trades={tradeVehicles}
                    setTrades={setTradeVehicles}
                    setVehicleFormOpen={setVehicleFormOpen}
                    showSnackbar={showSnackbar}
                    gstType={gstType}
                />
            </Dialog>
        </>
    );
};

export default TradeVehicles;
