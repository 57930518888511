// INTERFACES
import {
    Customer,
    PaymentLine
} from '../../../global/interfaces/GeneralInterface';
import {
    TradeIn,
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { TotalPaid, TradeTotals } from './TotalFunctions';
import { ToFixed } from '../../../global/logic/Formatters';

const ValidateFields = (
    id: number,
    saleDetails: VehicleSale,
    selectedCustomer: Customer,
    selectedVehicle: Vehicle,
    paymentLines: PaymentLine[],
    linkedTrades: TradeIn[],
    linkedVouchers: any[],
    showSnackbar: showSnackbar,
    gstType: string
) => {
    let isValid = true;

    // Check if a customer has been selected
    if (!selectedCustomer?.id) {
        showSnackbar(
            'A customer is required to create a new contract.',
            '',
            'error'
        );
        isValid = false;
    }

    // Check if a vehicle has been selected
    if (!selectedVehicle?.id) {
        showSnackbar(
            'A vehicle is required to create a new contract.',
            '',
            'error'
        );
        isValid = false;
    }

    // Check that there is a vehicle price entered
    if (
        parseFloat(saleDetails.vehicleSalePrice) <= 0 ||
        saleDetails.vehicleSalePrice === ''
    ) {
        showSnackbar(
            'Invalid vehicle price!',
            'Vehicle price must be greater than 0.',
            'error'
        );
        isValid = false;
    }

    // If a warrantly length was selected a valid warranty charge must be entered
    if (
        saleDetails.warrantyLength &&
        (parseFloat(saleDetails.warrantyPrice) <= 0 ||
            saleDetails.warrantyPrice === '')
    ) {
        showSnackbar(
            'Invalid warranty price!',
            'Warranty price must be greater than 0.',
            'error'
        );
        isValid = false;
    }

    // If a dof was entered it must be greater than 0
    if (
        parseFloat(saleDetails.insurancePrice) < 0 ||
        saleDetails.insurancePrice === ''
    ) {
        showSnackbar(
            'Invlaid DOF Price!',
            'DOF price must be 0 or greater.',
            'error'
        );
        isValid = false;
    }

    // If a delivery price was entered it must be greater than 0
    if (
        parseFloat(saleDetails.deliveryPrice) < 0 ||
        saleDetails.deliveryPrice === ''
    ) {
        showSnackbar(
            'Invalid delivery price!',
            'Delivery price must be 0 or greater.',
            'error'
        );
        isValid = false;
    }

    // If on road costs are included
    if (saleDetails.includesOnRoadCosts) {
        // If there is a registration length make sure there is a valid type and seats selected
        if (
            saleDetails.registrationLength !== 'None' &&
            (!saleDetails.registrationType || !saleDetails.registrationSeats)
        ) {
            showSnackbar(
                'Invalid registration details!',

                'Need to select both registration type and number of seats.',
                'error'
            );
            isValid = false;
        }

        // If there is a registration length make sure that the registration price is valid
        if (
            saleDetails.registrationLength !== 'None' &&
            (parseFloat(saleDetails.registrationPrice) <= 0 ||
                saleDetails.registrationPrice === '')
        ) {
            showSnackbar(
                'Invalid registration price!',
                'Registration price must be 0 or greater.',
                'error'
            );
            isValid = false;
        }

        // If there is a registration length make sure that the ctp price is valid
        if (
            saleDetails.registrationLength !== 'None' &&
            (parseFloat(saleDetails.ctpPrice) <= 0 ||
                saleDetails.ctpPrice === '')
        ) {
            showSnackbar(
                'Invalid CTP price!',
                'CTP price must be 0 or greater.',
                'error'
            );
            isValid = false;
        }

        // If there isn't a registration length make sure that the registration price is valid (must be 0)
        if (
            saleDetails.registrationLength === 'None' &&
            parseFloat(saleDetails.registrationPrice) !== 0
        ) {
            showSnackbar(
                'Invalid registration price!',
                'Registration price must be 0.',
                'error'
            );
            isValid = false;
        }

        // If the stamp duty is being included then you must enter a valid stamp duty price
        if (
            !saleDetails.excludeStampDuty &&
            (parseFloat(saleDetails.stampDutyPrice) < 0 ||
                saleDetails.stampDutyPrice === '')
        ) {
            showSnackbar(
                'Invalid stamp duty price!',
                'Stamp duty price must be 0 or greater.',
                'error'
            );
            isValid = false;
        }

        // If a transfer fee was entered it must be greater than or equal to 0
        if (
            parseFloat(saleDetails.transferPrice) < 0 ||
            saleDetails.transferPrice === ''
        ) {
            showSnackbar(
                'Invalid transfer price!',
                'Transfer price must be 0 or greater.',
                'error'
            );
            isValid = false;
        }
    }

    // Check that there is a vehicle price entered
    if (
        parseFloat(saleDetails.totalPurchasePrice) <= 0 ||
        saleDetails.totalPurchasePrice === '' ||
        isNaN(parseFloat(saleDetails.totalPurchasePrice))
    ) {
        showSnackbar(
            'Invalid total purchase price!',
            'Total purchase price must be 0 or greater.',
            'error'
        );
        isValid = false;
    }

    // If a deposit was entered it must be greater than or equal to 0
    if (
        parseFloat(saleDetails.depositPrice) < 0 ||
        saleDetails.depositPrice === ''
    ) {
        showSnackbar(
            'Invalid deposit price!',
            'Deposit price must be 0 or greater.',
            'error'
        );
        isValid = false;
    }

    // If it is a finalised sale the total paid needs to match the total purchase price
    if (saleDetails.saleStatus === 'Finalised') {
        let totalPaid = TotalPaid(paymentLines);

        if (
            totalPaid <
            ToFixed(
                parseFloat(saleDetails.totalPurchasePrice) -
                    TradeTotals(linkedTrades, gstType)
            )
        ) {
            showSnackbar(
                'The sale cannot be marked as finalised without full payment.',
                '',
                'error'
            );
            isValid = false;
        }
    }

    // If the sale has been marked as lost then there must be a reason why entered
    if (
        saleDetails.saleStatus === 'Lost' &&
        (!saleDetails.reasonLost || saleDetails.reasonLost === '')
    ) {
        showSnackbar(
            'You must provide details about why the sale was lost.',
            '',
            'error'
        );
        isValid = false;
    }

    if (linkedVouchers.length > 0) {
        if (paymentLines.length === 0) {
            showSnackbar(
                'The sale cannot be submitted without full payment for the Gift Voucher.',
                '',
                'error'
            );
            isValid = false;
        } else if (paymentLines.length > 0) {
            let totalPaid = TotalPaid(paymentLines);
            let totalVoucher = linkedVouchers.reduce(
                (acc, val) => acc + parseFloat(val.totalPrice),
                0
            );

            if (totalPaid < totalVoucher) {
                showSnackbar(
                    'The sale cannot be submitted without full payment for the Gift Voucher.',
                    '',
                    'error'
                );
                isValid = false;
            }
        }
    }

    return isValid;
};

export default ValidateFields;
