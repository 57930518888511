import jsPDF from "jspdf";
import { CurrencyFormatter } from "../../../../../../global/logic/Formatters";

interface DepartmentalProfitParams {
    (
        doc: jsPDF,
        totalVSE: { current: number; ytd: number; previousYear: number; previousYearYtd: number },
        totalEE: {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        },
        totalGE: {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        },
        profitFromTrading: number,
        previousYearProfitFromTrading: number,
        ytdMonthProfitFromTrading: number,
        lastYearYtdProfitFromTrading: number,
        afterGE: number
    ): [
        number,
        {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        }
    ];
}

const DepartmentalProfit: DepartmentalProfitParams = (doc, totalVSE, totalEE, totalGE, profitFromTrading, previousYearProfitFromTrading, ytdMonthProfitFromTrading, lastYearYtdProfitFromTrading, afterGE) => {
    let departmentalProfit = {
        current: profitFromTrading - (totalVSE.current + totalEE.current + totalGE.current),
        ytd: ytdMonthProfitFromTrading - (totalVSE.ytd + totalEE.ytd + totalGE.ytd),
        previousYear: previousYearProfitFromTrading - (totalVSE.previousYear + totalEE.previousYear + totalGE.previousYear),
        previousYearYtd: lastYearYtdProfitFromTrading - (totalVSE.previousYearYtd + totalEE.previousYearYtd + totalGE.previousYearYtd),
    };
    doc.setFont("helvetica", "bold").text("TOTAL EXPENSES", 10, afterGE);
    doc.text(CurrencyFormatter(totalVSE.current + totalEE.current + totalGE.current), 74, afterGE, { align: "right" });
    doc.text(CurrencyFormatter(totalVSE.ytd + totalEE.ytd + totalGE.ytd), 107, afterGE, { align: "right" });
    doc.text(CurrencyFormatter(totalVSE.previousYear + totalEE.previousYear + totalGE.previousYear), 140, afterGE, { align: "right" });
    doc.text(CurrencyFormatter(totalVSE.previousYearYtd + totalEE.previousYearYtd + totalGE.previousYearYtd), 173, afterGE, { align: "right" });

    doc.setFont("helvetica", "bold").text("DEPARTMENTAL PROFIT", 10, afterGE + 4);
    doc.text(CurrencyFormatter(departmentalProfit.current), 74, afterGE + 4, { align: "right" });
    doc.text(CurrencyFormatter(departmentalProfit.ytd), 107, afterGE + 4, { align: "right" });
    doc.text(CurrencyFormatter(departmentalProfit.previousYear), 140, afterGE + 4, { align: "right" });
    doc.text(CurrencyFormatter(departmentalProfit.previousYearYtd), 173, afterGE + 4, { align: "right" });

    doc.line(10, afterGE + 5, 200, afterGE + 5);

    return [afterGE + 8, departmentalProfit];
};

export default DepartmentalProfit;
