import { Dialog, DialogTitle, Typography } from '@mui/material';
import {
    OnlineOrder,
    Order,
    PartOrderline
} from '../../../global/interfaces/PartsInterface';
import { Dispatch, SetStateAction, useState } from 'react';
import AddPartDialogContent from './AddPartDialogContent';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}

interface MissingPartsDrawerContentProps {
    missingParts: GroupedOnlineOrder[];
    setMissingParts: Dispatch<SetStateAction<GroupedOnlineOrder[]>>;
    showSnackbar: showSnackbar;
}
const MissingPartsDrawerContent = ({
    missingParts,
    setMissingParts,
    showSnackbar
}: MissingPartsDrawerContentProps) => {
    const [addPartDialogOpen, setAddPartDialogOpen] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<GroupedOnlineOrder>();
    const [orderline, setOrderline] = useState<PartOrderline>(null);

    const handleSelectLine = (orderline, order) => {
        setOrderline(orderline);
        setSelectedOrder(order);
        setAddPartDialogOpen(true);
    };

    return (
        <>
            {missingParts.map((order) => (
                <>
                    <Typography variant="subtitle1">
                        <b>
                            {order?.netoOrder?.OnlineOrderId} |{' '}
                            {order?.netoOrder?.shippingFirstName}{' '}
                            {order?.netoOrder?.shippingLastName}
                        </b>
                    </Typography>
                    <Typography variant="subtitle2">
                        {order?.netoOrder?.shippingOption}
                    </Typography>
                    {order.oraOrder.orderLines.map((line) => (
                        <>
                            {line.needAdding ? (
                                <>
                                    <Typography
                                        variant="body1"
                                        onClick={() =>
                                            handleSelectLine(line, order)
                                        }
                                        sx={{ color: 'red', cursor: 'pointer' }}
                                    >
                                        <b>
                                            {line.quantity} x {line.partNumber}
                                        </b>{' '}
                                        | {line.name}
                                    </Typography>
                                </>
                            ) : (
                                <Typography variant="body1">
                                    <b>
                                        {line.quantity} x {line.partNumber}
                                    </b>{' '}
                                    | {line.name}
                                </Typography>
                            )}
                        </>
                    ))}
                </>
            ))}

            <Dialog
                open={addPartDialogOpen}
                onClose={() => setAddPartDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Add Part</DialogTitle>
                <br />
                <AddPartDialogContent
                    missingParts={missingParts}
                    setMissingParts={setMissingParts}
                    selectedOrder={selectedOrder}
                    orderline={orderline}
                    setAddPartDialogOpen={setAddPartDialogOpen}
                    showSnackbar={showSnackbar}
                />
            </Dialog>
        </>
    );
};

export default withSnackbar(MissingPartsDrawerContent);
