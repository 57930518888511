// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Account } from '../interfaces/AdminInterface';

const GetAllAccountsNested = (
  setAccounts: Dispatch<SetStateAction<Account[]>>
) => {
  api.get('allAccounts').then((res) => {
    setAccounts(res.data);
  });
};

export default GetAllAccountsNested;
