import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const DealerDeclarationForm = ({ control, setValue }) => {
    return (
        <Grid container spacing={2} margin={'10px'}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Motor Dealer/Chattel Auctioneer Declaration
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="noPriorContract"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="There is no prior contract with another buyer to purchase this vehicle (motor dealer only)."
                        />
                    )}
                />

                <Controller
                    name="notPersuadeCoolingOff"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="I have not attempted to persuade the buyer to forfeit their cooling-off rights (motor dealer only)."
                        />
                    )}
                />

                <Controller
                    name="hasStatutoryWarranty"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="This motor vehicle has a statutory warranty."
                        />
                    )}
                />
                <Controller
                    name="noStatutoryWarranty"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="This motor vehicle does not have a statutory warranty."
                        />
                    )}
                />

                <Controller
                    name="notPersuadeWarranty"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="I have not attempted to persuade the buyer to forfeit their statutory warranty."
                        />
                    )}
                />

                <Controller
                    name="informedBuyer"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="I have informed the buyer if the vehicle is an unregistered written-off vehicle or was previously written off."
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default DealerDeclarationForm;
