import { CurrencyFormatter } from '../../../global/logic/Formatters';
import { StampDutyVehicleType } from '../type/vehicle';

const calculateQLDStampDuty = (
    vehiclePrice: string,
    vehicleType: StampDutyVehicleType,
    isHeavyVehicle: boolean
) => {
    const DUTYRATES = {
        light: {
            hybrid_electric: { threshold: 100000, lowRate: 2, highRate: 4 },
            one_to_four_cylinders: {
                threshold: 100000,
                lowRate: 3,
                highRate: 5
            },
            five_or_six_cylinders: {
                threshold: 100000,
                lowRate: 3.5,
                highRate: 5.5
            },
            seven_or_more_cylinders: {
                threshold: 100000,
                lowRate: 4,
                highRate: 6
            }
        },
        heavy: {
            hybrid_electric: { rate: 2 },
            one_to_four_cylinders: { rate: 3 },
            five_or_six_cylinders: { rate: 3.5 },
            seven_or_more_cylinders: { rate: 4 }
        }
    };

    let stampDuty;

    if (isHeavyVehicle) {
        const rate = DUTYRATES.heavy[vehicleType].rate;
        stampDuty = CurrencyFormatter((Number(vehiclePrice) / 100) * rate);
    } else {
        const { threshold, lowRate, highRate } = DUTYRATES.light[vehicleType];
        if (Number(vehiclePrice) <= threshold) {
            stampDuty = CurrencyFormatter(
                (Number(vehiclePrice) / 100) * lowRate
            );
        } else {
            stampDuty = CurrencyFormatter(
                (Number(vehiclePrice) / 100) * highRate
            );
        }
    }

    return stampDuty;
};

const calculateNSWStampDuty = (vehiclePrice: string) => {
    let stampDuty;

    if (Number(vehiclePrice) <= 44999) {
        stampDuty = CurrencyFormatter((Number(vehiclePrice) / 100) * 3);
    } else {
        stampDuty = CurrencyFormatter(
            1350 + ((Number(vehiclePrice) - 45000) / 100) * 5
        );
    }

    return stampDuty;
};

export { calculateQLDStampDuty, calculateNSWStampDuty };
