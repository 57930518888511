// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, CircularProgress, DialogActions, Grid } from '@mui/material';
// COMPONENTS
import PrepareDuplicates from './PrepareDuplicates';
import DuplicateList from './DuplicateList';
// LOGIC
import SubmitMergeDuplicates from '../logic/SubmitMergeDuplicates';
// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface MergeCustomerDialogProps {
    setMergeDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const MergeCustomerDialog = ({
    setMergeDialogOpen,
    showSnackbar
}: MergeCustomerDialogProps) => {
    const [duplicateCustomers, setDuplicateCustomers] = useState<
        { key: string; mergeId: number; rows: Customer[] }[]
    >([]);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    return (
        <>
            {duplicateCustomers.length > 0 ? (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ maxHeight: '75vh' }}>
                            <DuplicateList
                                duplicateCustomers={duplicateCustomers}
                                setDuplicateCustomers={setDuplicateCustomers}
                                submitLoading={submitLoading}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <DialogActions>
                                <Button
                                    disabled={submitLoading}
                                    onClick={() => setMergeDialogOpen(false)}
                                >
                                    Cancel
                                </Button>
                                {submitLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setSubmitLoading(true);
                                            SubmitMergeDuplicates(
                                                showSnackbar,
                                                null,
                                                null,
                                                duplicateCustomers
                                            );
                                        }}
                                    >
                                        Submit Merge
                                    </Button>
                                )}
                            </DialogActions>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <PrepareDuplicates
                    setDuplicateCustomers={setDuplicateCustomers}
                    showSnackbar={showSnackbar}
                />
            )}
        </>
    );
};

export default withSnackbar(MergeCustomerDialog);
