// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Collapse,
    Divider,
    IconButton,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Add, Close, ExpandLess, ExpandMore } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
import DataCell from '../../../global/tableComponents/DataCell';
import DataTable from '../../../global/tableComponents/DataTable';
// INTERFACES
import { Settings } from '../../../global/interfaces/GeneralInterface';

interface SettingsProps {
    settings: Settings; // The company settings
    setSettings: Dispatch<SetStateAction<Settings>>;
    disabled: boolean;
}

/**
 * VehicleSaleWarranties
 * UI for Vehicle Sale Warranties on the Company Settings page
 * @author Estienne
 * @param SettingsProps
 */
const VehicleSaleWarranties = ({
    settings,
    setSettings,
    disabled
}: SettingsProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const columns = [
        { id: 0, label: 'Length (years)' },
        { id: 1, label: 'Cost' }
    ];

    const AddWarranty = () => {
        let tempWarranties = [...settings.warrantyData];
        let id =
            tempWarranties.length > 0
                ? tempWarranties[tempWarranties.length - 1].id + 1
                : 1;
        tempWarranties.push({ id: id, length: null, costPrice: null });
        setSettings({ ...settings, warrantyData: tempWarranties });
    };

    const EditWarranty = (
        newValue: number,
        warrantyId: number,
        field: string
    ) => {
        let tempWarranties = [...settings.warrantyData];
        let index = tempWarranties.findIndex((x) => x.id === warrantyId);
        let warranty = { ...tempWarranties[index] };
        if (field === 'length') {
            warranty.length = newValue;
        } else if (field === 'cost') {
            warranty.costPrice = newValue;
        }

        tempWarranties[index] = warranty;
        setSettings({ ...settings, warrantyData: tempWarranties });
    };

    const RemoveWarranty = (warrantyId: number) => {
        let tempWarranties = [...settings.warrantyData];
        tempWarranties = tempWarranties.filter(
            (item) => item.id !== warrantyId
        );
        setSettings({ ...settings, warrantyData: tempWarranties });
    };

    return (
        <Paper textAlign="center">
            <Typography variant="h6" align="left">
                Sale Warranties
                <IconButton onClick={() => setOpen(!open)}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Typography>
            <Divider />

            <Collapse in={open} timeout="auto" unmountOnExit>
                <DataTable columns={columns}>
                    {settings?.warrantyData.map((warranty) => (
                        <TableRow>
                            <DataCell width="100">
                                {disabled ? (
                                    warranty.length
                                ) : (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        variant="standard"
                                        value={warranty.length}
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: 'center' }
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === '')
                                                EditWarranty(
                                                    null,
                                                    warranty.id,
                                                    'length'
                                                );
                                            else
                                                EditWarranty(
                                                    parseInt(e.target.value),
                                                    warranty.id,
                                                    'length'
                                                );
                                        }}
                                    />
                                )}
                            </DataCell>
                            <DataCell width="100">
                                {disabled ? (
                                    warranty.costPrice
                                ) : (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        variant="standard"
                                        value={warranty.costPrice}
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: 'center' }
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === '')
                                                EditWarranty(
                                                    null,
                                                    warranty.id,
                                                    'cost'
                                                );
                                            else
                                                EditWarranty(
                                                    parseInt(e.target.value),
                                                    warranty.id,
                                                    'cost'
                                                );
                                        }}
                                    />
                                )}
                            </DataCell>
                            {disabled ? null : (
                                <DataCell width="40">
                                    <Tooltip
                                        placement="left"
                                        title="Remove Warranty"
                                    >
                                        <IconButton
                                            onClick={() =>
                                                RemoveWarranty(warranty.id)
                                            }
                                        >
                                            <Close />
                                        </IconButton>
                                    </Tooltip>
                                </DataCell>
                            )}
                        </TableRow>
                    ))}
                </DataTable>
                {disabled ? null : (
                    <IconButton onClick={() => AddWarranty()}>
                        <Add />
                    </IconButton>
                )}
            </Collapse>
        </Paper>
    );
};

export default VehicleSaleWarranties;
