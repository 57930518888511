import { DebtorPayment } from '../../../global/interfaces/GeneralInterface';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const ManuallySetPaymentAsPaid = (
    selectedInvoices: DebtorPayment[],
    showSnackbar: showSnackbar
) => {
    api.put('debtorPaymentAsPaid', selectedInvoices).then((res) => {
        if (res.status === 200) {
            showSnackbar('Debtor Payment successfully updated.');
            window.location.reload();
        } else {
            showSnackbar(
                'Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default ManuallySetPaymentAsPaid;
