// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Checkbox,
    DialogContentText,
    FormControlLabel,
    Grid
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import GetAllUsers from '../../../global/databaseLogic/GetAllUsers';
// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site, User } from '../../../global/interfaces/GeneralInterface';

interface ServiceTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const jobTypeOptions = [
    { id: 0, label: 'Retail', value: 'Retail' },
    { id: 1, label: 'Internal', value: 'Internal' },
    { id: 2, label: 'Warranty', value: 'Warranty' },
    { id: 3, label: 'Insurance', value: 'Insurance' }
];

const serviceCompleteOptions = [
    { id: 0, label: 'Incomplete', value: 'Incomplete' },
    { id: 1, label: 'Complete', value: 'Complete' },
    { id: 2, label: 'Finalised', value: 'Finalised' }
];

let siteOptions = [];
let userOptions = [];

const ServiceTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: ServiceTableFilterProps) => {
    const [jobTypes, setJobTypes] = useState(jobTypeOptions);
    const [serviceCompletes, setServiceCompletes] = useState(
        serviceCompleteOptions
    );

    const [sitesUnaltered, setSitesUnaltered] = useState<Site[]>(siteOptions);
    const [usersUnaltered, setUsersUnaltered] = useState<User[]>(userOptions);
    const [sites, setSites] = useState<Site[]>(siteOptions);
    const [users, setUsers] = useState<User[]>(userOptions);

    // Get the initial dropdown values for dynamic fields
    useEffect(() => {
        GetAllSites(setSites);
        GetAllUsers(setUsers, '?department=service');

        GetAllSites(setSitesUnaltered);
        GetAllUsers(setUsersUnaltered, '?department=service');
    }, []);

    // When the filter is cleared reset all the options
    useEffect(() => {
        if (!isFilterActive()) {
            setJobTypes(jobTypeOptions);
            setServiceCompletes(serviceCompleteOptions);
            setSites(siteOptions);
            setUsers(userOptions);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.serviceFilter;
        newFilter[property] = value;
        setFilter({ ...filter, serviceFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Booking Start Range"
                            value={filter.serviceFilter.startDate}
                            onChange={(newValue) =>
                                handleUpdateFilter('startDate', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Booking End Range"
                            value={filter.serviceFilter.endDate}
                            onChange={(newValue) =>
                                handleUpdateFilter('endDate', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.serviceFilter.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sitesUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={jobTypes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Job Type"
                        selectedValues={filter.serviceFilter.type}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = jobTypeOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setJobTypes(currentOptions);
                            handleUpdateFilter('type', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={serviceCompletes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Job Status"
                        selectedValues={filter.serviceFilter.status}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = serviceCompleteOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setServiceCompletes(currentOptions);
                            handleUpdateFilter('status', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={users}
                        useTwoOptionLabels={true}
                        includeBar={false}
                        primaryOptionLabel={'firstName'}
                        secondaryOptionLabel={'lastName'}
                        textfieldLabel="Assigned Tech"
                        selectedValues={filter.serviceFilter.user}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = usersUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setUsers(currentOptions);
                            handleUpdateFilter('user', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.serviceFilter.archived}
                                onChange={(e) =>
                                    handleUpdateFilter(
                                        'archived',
                                        e.target.checked
                                    )
                                }
                            />
                        }
                        label="Include Archived Results"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ServiceTableFilter;
