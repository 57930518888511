// IMPORTS
import { useState } from 'react';
import { Typography } from '@mui/material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import RoleCheck from '../../global/RoleCheck';
import Tabs from '../../global/Tabs';
import Reconciliation from './components/Reconciliation';
import History from './components/History';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

const initialCash = [
    {
        name: 'oneHundredDollars',
        value: 100,
        count: '',
        quantity: '',
        banking: ''
    },
    { name: 'fiftyDollars', value: 50, count: '', quantity: '', banking: '' },
    { name: 'twentyDollars', value: 20, count: '', quantity: '', banking: '' },
    { name: 'tenDollars', value: 10, count: '', quantity: '', banking: '' },
    { name: 'fiveDollars', value: 5, count: '', quantity: '', banking: '' },
    { name: 'twoDollars', value: 2, count: '', quantity: '', banking: '' },
    { name: 'oneDollar', value: 1, count: '', quantity: '', banking: '' },
    { name: 'fiftyCents', value: 0.5, count: '', quantity: '', banking: '' },
    { name: 'twentyCents', value: 0.2, count: '', quantity: '', banking: '' },
    { name: 'tenCents', value: 0.1, count: '', quantity: '', banking: '' },
    { name: 'fiveCents', value: 0.05, count: '', quantity: '', banking: '' }
];

const TillReconciliation = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [tillReconciliation, setTillReconciliation] = useState({
        tillLines: [],
        cash: [...initialCash],
        cashbagNumber: '',
        envelopeNumber: ''
    });

    const tabContent = [
        {
            id: 0,
            title: 'Reconciliation',
            content: (
                <Reconciliation
                    tillReconciliation={tillReconciliation}
                    setTillReconciliation={setTillReconciliation}
                    showSnackbar={showSnackbar}
                />
            )
        },
        {
            id: 1,
            title: 'History',
            content: (
                <History
                    tillReconciliation={tillReconciliation}
                    setTillReconciliation={setTillReconciliation}
                    showSnackbar={showSnackbar}
                />
            )
        }
    ];

    return (
        <PageWrapper>
            <Typography variant="h4">Till Reconciliation</Typography>
            <br />
            <RoleCheck
                permission="access_till"
                component={<Tabs tabContent={tabContent} />}
                nullComponent={
                    <Reconciliation
                        tillReconciliation={tillReconciliation}
                        setTillReconciliation={setTillReconciliation}
                        showSnackbar={showSnackbar}
                    />
                }
            />
        </PageWrapper>
    );
};

export default withSnackbar(TillReconciliation);
