import { TableRow } from '@mui/material';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import { DateFormatter } from '../../../global/logic/Formatters';

interface StockDrawerContentProps {
    title: string;
    drawerContent: any;
    UnitId: number;
}

const StockDrawerContent = ({
    title,
    drawerContent,
    UnitId
}: StockDrawerContentProps) => {
    let columns;
    switch (title) {
        case 'Reserved': {
            columns = [
                { id: 0, label: 'Order ID' },
                { id: 1, label: 'Customer' },
                { id: 2, label: 'Quantity' }
            ];
            break;
        }
        case 'Incoming': {
            columns = [
                { id: 0, label: 'Stock Order ID' },
                { id: 1, label: 'Supplier' },
                { id: 2, label: 'Quantity' },
                { id: 3, label: 'Date' }
            ];
            break;
        }
        case 'Returned': {
            columns = [
                { id: 0, label: 'Invoice ID' },
                { id: 1, label: 'Supplier' },
                { id: 2, label: 'Quantity' },
                { id: 3, label: 'Date' }
            ];
            break;
        }
        case 'Transfer In': {
            columns = [
                { id: 0, label: 'Transfer ID' },
                { id: 1, label: 'Site From' },
                { id: 2, label: 'Site To' },
                { id: 3, label: 'Quantity' }
            ];
            break;
        }
        case 'Transfer Out': {
            columns = [
                { id: 0, label: 'Transfer ID' },
                { id: 1, label: 'Site From' },
                { id: 2, label: 'Site To' },
                { id: 3, label: 'Quantity' }
            ];
            break;
        }
    }

    /**
     * findQuantity
     * Summate the stock quantity involved in a movement
     * @author Pierre
     * @param orderlines the orderlines of the movement
     * @returns the quantity of the viewed part involved in each movement
     */
    const findQuantity = (orderlines: any[]) => {
        let qty = 0;
        for (let line of orderlines) {
            if (
                line.UnitId === UnitId ||
                line.unitId === UnitId ||
                line.id === UnitId
            ) {
                if (typeof line.quantity == 'string') {
                    qty += parseInt(line.quantity);
                } else if (line.quantitySent) {
                    qty += line.quantitySent;
                } else {
                    qty += line.quantity;
                }
            }
        }

        return qty;
    };

    return (
        <>
            <DataTable columns={columns}>
                {title === 'Reserved'
                    ? drawerContent.map((item) => (
                          <TableRow>
                              <DataCellColoured
                                  handleClick={() =>
                                      window.open(
                                          '/inventory/viewSale/' + item.id,
                                          '_blank'
                                      )
                                  }
                              >
                                  {item.id}
                              </DataCellColoured>
                              <DataCell>
                                  {item.CustomerId
                                      ? item.Customer.firstName +
                                        ' ' +
                                        item.Customer.lastName
                                      : item.ServiceId
                                        ? item.Service.id
                                        : item.VehicleId
                                          ? item.Vehicle.id
                                          : item.VehicleSaleId
                                            ? item.VehicleSale.id
                                            : item.internalType === 1
                                              ? 'Workshop'
                                              : item.internalType === 2
                                                ? 'Sale'
                                                : ''}
                              </DataCell>
                              <DataCell>
                                  {findQuantity(item.orderLines)}
                              </DataCell>
                          </TableRow>
                      ))
                    : title === 'Incoming'
                      ? drawerContent.map((item) => (
                            <TableRow>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            '/inventory/stockInvoicePO/' +
                                                item.id,
                                            '_blank'
                                        )
                                    }
                                >
                                    {item.id}
                                </DataCellColoured>
                                <DataCell>{item.Supplier.name}</DataCell>
                                <DataCell>
                                    {findQuantity(item.orderLines)}
                                </DataCell>
                                <DataCell>
                                    {DateFormatter(item.createdAt)}
                                </DataCell>
                            </TableRow>
                        ))
                      : title === 'Returned'
                        ? drawerContent.map((item) => (
                              <TableRow>
                                  <DataCell>{item.id}</DataCell>
                                  <DataCell>{item.Supplier.name}</DataCell>
                                  <DataCell>
                                      {findQuantity(item.credit_lines)}
                                  </DataCell>
                                  <DataCell>
                                      {DateFormatter(item.createdAt)}
                                  </DataCell>
                              </TableRow>
                          ))
                        : title === 'Transfer In'
                          ? drawerContent.map((item) => (
                                <TableRow>
                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(
                                                '/inventory/stocktransfers',
                                                '_blank'
                                            )
                                        }
                                    >
                                        {item.id}
                                    </DataCellColoured>
                                    <DataCell>{item.SiteFrom.name}</DataCell>
                                    <DataCell>{item.SiteTo.name}</DataCell>
                                    <DataCell>
                                        {findQuantity(item.transferLines)}
                                    </DataCell>
                                </TableRow>
                            ))
                          : title === 'Transfer Out'
                            ? drawerContent.map((item) => (
                                  <TableRow>
                                      <DataCellColoured
                                          handleClick={() =>
                                              window.open(
                                                  '/inventorystocktransfers',
                                                  '_blank'
                                              )
                                          }
                                      >
                                          {item.id}
                                      </DataCellColoured>
                                      <DataCell>{item.SiteFrom.name}</DataCell>
                                      <DataCell>{item.SiteTo.name}</DataCell>
                                      <DataCell>
                                          {findQuantity(item.transferLines)}
                                      </DataCell>
                                  </TableRow>
                              ))
                            : null}
            </DataTable>
        </>
    );
};

export default StockDrawerContent;
