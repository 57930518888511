import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Table } from "../interfaces/StockOrderInterface"

const GenerateStockOrder = (tables: Table[], setTables: Dispatch<SetStateAction<Table[]>>, setLoading: Dispatch<SetStateAction<boolean>>, coveragePeriod: number, coveragePeriodType: string, orderPeriod: {startDate: Date, endDate: Date}, generateOrderErrors: {noCovPeriod: boolean, noSalesStart: boolean, noSalesEnd: boolean}, setGenerateOrderErrors: Dispatch<SetStateAction<{noCovPeriod: boolean, noSalesStart: boolean, noSalesEnd: boolean}>>, setOrderOpen: Dispatch<SetStateAction<boolean>>, setClicked: Dispatch<SetStateAction<boolean>>, selectedSupplier: number, orderMinStock: boolean, selectedSite: number, setButtonLoading: Dispatch<SetStateAction<boolean>>) => {
    setButtonLoading(true)
    var noCovPeriod = false;
    var noSalesStart = false;
    var noSalesEnd = false;

    if (coveragePeriod == null) noCovPeriod = true;
    if (orderPeriod.startDate == null) noSalesStart = true;
    if (orderPeriod.endDate == null) noSalesEnd = true;

    setGenerateOrderErrors({
        ...generateOrderErrors,
        noCovPeriod: noCovPeriod,
        noSalesStart: noSalesStart,
        noSalesEnd: noSalesEnd,
    })

    if (!noCovPeriod && !noSalesStart && !noSalesEnd) {
        setOrderOpen(false)
        setLoading(true)
        setClicked(true)


        api.get(`generateStockOrderNextGen?SiteId=${selectedSite}&coveragePeriod=${coveragePeriod}&coverageType=${coveragePeriodType}&orderPeriodStart=${orderPeriod.startDate}&orderPeriodEnd=${orderPeriod.endDate}&selectedSupplier=${selectedSupplier}&orderMinStock=${orderMinStock}`).then(res => {
            let currentTables = tables;
            let recommendedOrders = res.data.recommendedOrders;
            let lessThanMinStock = res.data.lessThanMinStock;

            for (let i = 0; i < recommendedOrders.length; i++) {
                let part = recommendedOrders[i];
       
                if (part.quantityNeededToOrder <= 0 || part.qtyOnHand < 0) continue; 

                let table = currentTables.find(table=> table.supplierId === part.partData.SupplierIds[0].SupplierId);
                if(!table) {
                    currentTables.push({
                        tableId: currentTables.length > 0 ? currentTables[currentTables.length - 1].tableId + 1 : 1,
                        supplierId: part.partData.SupplierIds[0].SupplierId,
                        orderReference: '',
                        supplierName: part.partData.SupplierIds[0].name,
                        totalCost: part.costPriceDaily,
                        orderLines: [],
                        OrderType: 'stock'
                    });
                        table = currentTables.at(-1);
                }
                let costPrice = part.partData.costPriceDaily;
                let orderline = table.orderLines.find(line=> line.id === part.UnitId);
                if(!orderline) {
                    let newPart = {
                        id: part.UnitId,
                        rowId: table.orderLines.length + 1,
                        partNumber: part.partData.partNumber,
                        name: part.partData.name,
                        quantity: part.quantityNeededToOrder,
                        supplier: table.supplierId,
                        costPrice: costPrice,
                        rowTotal: costPrice,
                        gst: (costPrice * 1.1) - costPrice,
                        rrp: part.partData.priceRRP,
                        minStock: part.partData.minStock,
                        maxStock: part.partData.maxStock,
                        qtyOnHand: part.quantityOnHand,
                        selected: false,
                        OrderType: 'stock'
                    }
                    table.orderLines.push(newPart);
                } else {
                    orderline.quantity += part.quantityNeededToOrder;
                }
 
            }

            for (let k = 0; k < lessThanMinStock.length; k++) {
                let part = lessThanMinStock[k];
                if ((part.minStock < part.qtyOnHand) || part.qtyOnHand < 0) continue;
                let table = currentTables.find(table=> table.supplierId === part["Unit.SupplierIds"][0].SupplierId);
                if(!table) {
                    currentTables.push({
                        tableId: currentTables.length > 0 ? currentTables[currentTables.length - 1].tableId + 1 : 1,
                        supplierId: part["Unit.SupplierIds"][0].SupplierId,
                        orderReference: '',
                        supplierName: part["Unit.SupplierIds"][0].name,
                        totalCost: part['Unit.costPriceDaily'],
                        orderLines: [],
                        OrderType: 'stock'
                    })
                    table = currentTables.at(-1);
                }
                let newCostPrice = part['Unit.costPriceDaily'];
                let orderline = table.orderLines.find(line=> line.id === part.UnitId);
                if(!orderline) {
                    let newPart = {
                        id: part.UnitId,
                        rowId: table.orderLines.length + 1,
                        partNumber: part['Unit.partNumber'],
                        name: part['Unit.name'],
                        quantity: part.minStock - part.qtyOnHand,
                        supplier: table.supplierId,
                        costPrice: newCostPrice,
                        rowTotal: newCostPrice,
                        gst: (newCostPrice * 1.1) - newCostPrice,
                        rrp: part['Unit.priceRRP'],
                        minStock: part.minStock,
                        maxStock: part.maxStock,
                        qtyOnHand: part.qtyOnHand,
                        selected: false,
                        OrderType: 'stock'
                    }
                    table.orderLines.push(newPart);
                } else {
                    orderline.quantity += part.minStock - part.qtyOnHand;
                }
                
                

            }

            setTables([...currentTables]);
            setLoading(false);
            setButtonLoading(false)
        })
    }
}

export default GenerateStockOrder;