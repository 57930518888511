const RemoveLine = (typeArray, rowId, previewData, setPreviewData) => {
    var newArray = [];

    if (typeArray === 'first') {
        Object.keys(previewData.first10).forEach((line) => {
            if (line !== rowId) {
                newArray.push(previewData.first10[line]);
            }
        });

        setPreviewData({ ...previewData, first10: newArray });
    } else {
        Object.keys(previewData.last10).forEach((line) => {
            if (line !== rowId) {
                newArray.push(previewData.last10[line]);
            }
        });

        setPreviewData({ ...previewData, last10: newArray });
    }
};

export default RemoveLine;
