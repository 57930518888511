// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    TableRow,
    TextField,
    IconButton,
    Tooltip,
    Typography,
    Chip,
    Switch,
    Checkbox
} from '@mui/material';
import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import OrderlineMenu from './OrderlineMenu';
import GlobalOrderlineMenu from './GlobalOrderlineMenu';
import RoleCheck from '../../../../global/RoleCheck';
// LOGIC
import AdjustLineValue from '../../logic/AdjustLineValue';
import MergeQuantity from '../../logic/MergeQuantity';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import {
    Order,
    OrderLine,
    StockOrder
} from '../../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import Drawer from '../../../../global/Drawer';
import StockOrderDrawerContent from './StockOrderDrawerContent';
import ClickableTypography from '../../../../global/ClickableTypography';

interface OrderlineTableProps {
    id: number;
    readOnly: boolean;
    oldSale: Order;
    saleDetails: Order;
    setSaleDetails: Dispatch<SetStateAction<Order>>;
    showSnackbar: showSnackbar;
    selectedType: string;
    stockOrders: StockOrder[];
}

const OrderlineTable = ({
    id,
    readOnly,
    oldSale,
    saleDetails,
    setSaleDetails,
    showSnackbar,
    selectedType,
    stockOrders
}: OrderlineTableProps) => {
    const [stockOrderDrawerOpen, setStockOrderDrawerOpen] =
        useState<boolean>(false);
    const [selectedStockOrder, setSelectedStockOrder] = useState<StockOrder>();

    let innerWidth = window.innerWidth;

    let columns = [
        { id: 1, label: 'Part Number', width: 150 },
        { id: 2, label: 'Name', width: 200 },
        { id: 3, label: 'Bins', width: 100 },
        { id: 4, label: 'Stock', width: 100 },
        { id: 5, label: 'Qty', width: 100 },
        { id: 6, label: '', width: 100 },
        { id: 7, label: 'B/O', width: 100 },
        { id: 8, label: 'Unit Price', width: 100 },
        { id: 9, label: 'Total', width: 100 },
        {
            id: 10,
            label: (
                <GlobalOrderlineMenu
                    saleDetails={saleDetails}
                    setSaleDetails={setSaleDetails}
                    showSnackbar={showSnackbar}
                />
            ),
            width: 75
        }
    ];

    if (innerWidth > 1500) {
        //Guest sale do not have B/O column and merge quantity icon button column
        if (selectedType === 'guest') {
            columns = columns.filter(
                (column) => column.label !== 'B/O' && column.label !== ''
            );
        }
    } else {
        //if innerWidth smaller than 1500, do not display Name and merge quantity icon button
        columns = columns.filter(
            (column) => column.label !== 'Name' && column.label !== ''
        );
        //Guest sale do not have B/O column and merge quantity icon button column
        if (selectedType === 'guest') {
            columns = columns.filter((column) => column.label !== 'B/O');
        }
    }

    if (
        !readOnly &&
        saleDetails.status === 'Proforma' &&
        (selectedType !== 'contract' || (id && selectedType === 'contract'))
    ) {
        columns.unshift({ id: 0, label: 'Collecting', width: 50 });
    }

    const handleSortPartOrderlines = () => {
        let sortedOrderlines = [];
        const partOrderlines = saleDetails.orderLines.filter(
            (x) => x.partNumber
        );
        let activeLines = partOrderlines.filter((x) => !x.inactive);
        let inactiveLines = partOrderlines.filter((x) => x.inactive);

        activeLines.forEach((line) => {
            sortedOrderlines.push(line);
        });
        inactiveLines.forEach((line) => {
            sortedOrderlines.push(line);
        });
        return sortedOrderlines;
    };

    const sortedPartOrderlines = handleSortPartOrderlines();

    const markAsCollecting = (
        rowId: number,
        value: boolean,
        line: OrderLine
    ) => {
        let currentOrderLines = [...saleDetails.orderLines];
        let index = currentOrderLines.findIndex(
            (x) => x.rowId === rowId && x.UnitId === line.UnitId
        );

        // Cannot mark a part that has 0 quantity as collecting
        if (parseInt(currentOrderLines[index].quantity) === 0) {
            showSnackbar(
                'No qty entered.',

                'You cannot mark a part as collected by customer with no qty.',
                'error'
            );
        } else {
            currentOrderLines[index].collecting = value;
            setSaleDetails({ ...saleDetails, orderLines: currentOrderLines });
        }
    };

    const findStockOrderId = (UnitId: number) => {
        let stockOrderId = null;
        let invoiced = false;

        stockOrders.forEach((stockOrder) => {
            let filteredLine = stockOrder.orderLines.filter(
                (x) => x.id === UnitId && x.orderId === id
            );

            if (filteredLine.length > 0) {
                stockOrderId = stockOrder.id;
                invoiced = stockOrder.invoiced;
            }
        });
        return { stockOrderId: stockOrderId, invoiced: invoiced };
    };

    const openStockOrderDrawer = (StockOrderId: number) => {
        let stockOrder = stockOrders.filter((x) => x.id === StockOrderId);
        setSelectedStockOrder(stockOrder[0]);
        setStockOrderDrawerOpen(true);
    };

    const getBinLocationsData = (binLocations) => {
        for (let binLocation of binLocations) {
            if (binLocation.latest) {
                return `${binLocation?.name}, +${binLocations.length - 1}`;
            }
        }
        return `${binLocations[binLocations.length - 1]?.name}, +${binLocations.length - 1}`;
    };

    return (
        <>
            <Paper>
                <Typography variant="h6">Parts</Typography>
                <DataTable cypressLabel="orderlinesTable" columns={columns}>
                    {sortedPartOrderlines.length > 0 &&
                        sortedPartOrderlines.map((line, index) => (
                            <TableRow
                                key={index + line.UnitId}
                                data-cy={'orderlineRow_' + index}
                            >
                                {!readOnly &&
                                saleDetails.status === 'Proforma' &&
                                (selectedType !== 'contract' ||
                                    (id && selectedType === 'contract')) ? (
                                    //  Part Collecting Checkbox DataCell
                                    <DataCell>
                                        {line.inactive ? (
                                            <Checkbox
                                                checked={true}
                                                disabled={true}
                                            />
                                        ) : (
                                            <Checkbox
                                                checked={
                                                    line.collecting
                                                        ? true
                                                        : false
                                                }
                                                data-cy={
                                                    'collectingCheckbox' + index
                                                }
                                                onChange={(e) =>
                                                    markAsCollecting(
                                                        line.rowId,
                                                        e.target.checked,
                                                        line
                                                    )
                                                }
                                            />
                                        )}
                                    </DataCell>
                                ) : null}

                                {/* Only display partNumber cell when innerWidth <= 1500
                            Display partNumber and partName cell when innerWidth>1500 */}
                                {innerWidth <= 1500 ? (
                                    // Part Number DataCell
                                    <DataCell>
                                        <Tooltip
                                            title={line.name}
                                            placement="bottom"
                                        >
                                            <ClickableTypography
                                                variant="body2"
                                                link={`inventory/view/${line.UnitId}`}
                                                string={line.partNumber}
                                            />
                                        </Tooltip>
                                        {stockOrders.length > 0 &&
                                        findStockOrderId(line.UnitId) !=
                                            null ? (
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: findStockOrderId(
                                                        line.UnitId
                                                    ).invoiced
                                                        ? 'green'
                                                        : 'red'
                                                }}
                                            >
                                                SO #
                                                <Typography
                                                    variant="caption"
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        openStockOrderDrawer(
                                                            findStockOrderId(
                                                                line.UnitId
                                                            ).stockOrderId
                                                        )
                                                    }
                                                >
                                                    {
                                                        findStockOrderId(
                                                            line.UnitId
                                                        ).stockOrderId
                                                    }
                                                </Typography>
                                            </Typography>
                                        ) : null}
                                        {((stockOrders.length > 0 &&
                                            findStockOrderId(line.UnitId) ==
                                                null) ||
                                            stockOrders.length === 0) &&
                                        parseInt(line.backorderQuantity) > 0 &&
                                        selectedType === 'Proforma' ? (
                                            <Typography
                                                variant="caption"
                                                sx={{ color: 'red' }}
                                            >
                                                NOT ORDERED
                                            </Typography>
                                        ) : null}
                                    </DataCell>
                                ) : (
                                    <>
                                        {/* Part Number DataCell */}
                                        <DataCell>
                                            <ClickableTypography
                                                variant="body2"
                                                link={`inventory/view/${line.UnitId}`}
                                                string={line.partNumber}
                                            />
                                            {stockOrders.length > 0 &&
                                            findStockOrderId(line.UnitId) !=
                                                null ? (
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        color: findStockOrderId(
                                                            line.UnitId
                                                        ).invoiced
                                                            ? 'green'
                                                            : 'red'
                                                    }}
                                                >
                                                    SO #
                                                    <Typography
                                                        variant="caption"
                                                        sx={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() =>
                                                            openStockOrderDrawer(
                                                                findStockOrderId(
                                                                    line.UnitId
                                                                ).stockOrderId
                                                            )
                                                        }
                                                    >
                                                        {
                                                            findStockOrderId(
                                                                line.UnitId
                                                            ).stockOrderId
                                                        }
                                                    </Typography>
                                                </Typography>
                                            ) : null}
                                            {((stockOrders.length > 0 &&
                                                findStockOrderId(line.UnitId) ==
                                                    null) ||
                                                stockOrders.length === 0) &&
                                            parseInt(line.backorderQuantity) >
                                                0 &&
                                            selectedType === 'Proforma' ? (
                                                <Typography
                                                    variant="caption"
                                                    sx={{ color: 'red' }}
                                                >
                                                    NOT ORDERED
                                                </Typography>
                                            ) : null}
                                        </DataCell>

                                        {/* Part Name DataCell */}
                                        <DataCell>
                                            {line.name.length > 20 ? (
                                                <Tooltip title={line.name}>
                                                    <Typography
                                                        variant="body2"
                                                        sx={{
                                                            color: line.inactive
                                                                ? '#808080'
                                                                : ''
                                                        }}
                                                    >{`${line.name.slice(0, 20)}...`}</Typography>
                                                </Tooltip>
                                            ) : (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: line.inactive
                                                            ? '#808080'
                                                            : ''
                                                    }}
                                                >
                                                    {line.name}
                                                </Typography>
                                            )}
                                        </DataCell>
                                    </>
                                )}

                                {/* Bins Data */}
                                <DataCell>
                                    {line.BinLocationIds ? (
                                        <Tooltip
                                            title={line.BinLocationIds.map(
                                                (bin) => bin.name + ', '
                                            )}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: line.inactive
                                                        ? '#808080'
                                                        : ''
                                                }}
                                            >
                                                {line.BinLocationIds.length > 1
                                                    ? // ? `${line.BinLocationIds[0]?.name}, +${line.BinLocationIds.length - 1}`
                                                      getBinLocationsData(
                                                          line.BinLocationIds
                                                      )
                                                    : line.BinLocationIds[0]
                                                          ?.name}
                                            </Typography>
                                        </Tooltip>
                                    ) : null}
                                </DataCell>

                                {/* Stock data */}
                                <DataCell>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: line.inactive
                                                ? '#808080'
                                                : ''
                                        }}
                                    >
                                        SOH: {line.SOH}
                                        <br />
                                        RES: {line.RES}
                                    </Typography>
                                </DataCell>

                                {/* Quantity data */}
                                <DataCell>
                                    {line.inactive ? (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: line.inactive
                                                    ? '#808080'
                                                    : ''
                                            }}
                                        >
                                            {line.quantity}
                                        </Typography>
                                    ) : (
                                        <RoleCheck
                                            permission={'sale_to_proforma'}
                                            component={
                                                <TextField
                                                    fullWidth
                                                    data-cy={
                                                        'quantityField-' +
                                                        line.rowId?.toString()
                                                    }
                                                    type="number"
                                                    disabled={
                                                        readOnly ||
                                                        (saleDetails.status ===
                                                            'Sale' &&
                                                            oldSale.status ===
                                                                'Sale')
                                                    }
                                                    variant="standard"
                                                    value={line.quantity}
                                                    onChange={(e) =>
                                                        AdjustLineValue(
                                                            line.rowId,
                                                            'quantity',
                                                            e.target.value,
                                                            line.gstFree,
                                                            saleDetails,
                                                            setSaleDetails,
                                                            line
                                                        )
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            }
                                            nullComponent={
                                                <TextField
                                                    fullWidth
                                                    data-cy={
                                                        'quantityField-' +
                                                        line.rowId?.toString()
                                                    }
                                                    type="number"
                                                    disabled={
                                                        readOnly ||
                                                        oldSale.status ===
                                                            'Sale'
                                                    }
                                                    variant="standard"
                                                    value={line.quantity}
                                                    onChange={(e) =>
                                                        AdjustLineValue(
                                                            line.rowId,
                                                            'quantity',
                                                            e.target.value,
                                                            line.gstFree,
                                                            saleDetails,
                                                            setSaleDetails,
                                                            line
                                                        )
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                </DataCell>

                                {/* backorderQuantity Data Cell
                            Guest Sale, no backorderQuantity data or merge quantity
                            innerWidth <= 1500, no merge quantity  */}
                                {selectedType === 'guest' ? null : (
                                    <>
                                        {innerWidth <= 1500 ? null : (
                                            // Merge Quantity IconButton Data Cell
                                            <DataCell>
                                                {line.inactive ? null : line.quantity >
                                                  line.backorderQuantity ? (
                                                    <IconButton
                                                        size="small"
                                                        disabled={
                                                            readOnly ||
                                                            (saleDetails.status ===
                                                                'Sale' &&
                                                                oldSale.status ===
                                                                    'Sale')
                                                        }
                                                        data-cy={
                                                            'moveQtyToBOButton' +
                                                            index
                                                        }
                                                        onClick={() =>
                                                            MergeQuantity(
                                                                'quantity',
                                                                line.rowId,
                                                                saleDetails,
                                                                setSaleDetails
                                                            )
                                                        }
                                                    >
                                                        <ArrowCircleRight fontSize="small" />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        size="small"
                                                        disabled={
                                                            readOnly ||
                                                            oldSale.status ===
                                                                'Sale'
                                                        }
                                                        data-cy={
                                                            'moveQtyToQtyButton' +
                                                            index
                                                        }
                                                        onClick={() =>
                                                            MergeQuantity(
                                                                'backorder',
                                                                line.rowId,
                                                                saleDetails,
                                                                setSaleDetails
                                                            )
                                                        }
                                                    >
                                                        <ArrowCircleLeft fontSize="small" />
                                                    </IconButton>
                                                )}
                                            </DataCell>
                                        )}

                                        {/* Backorder Quantity Data Cell */}
                                        <DataCell>
                                            {line.inactive ? (
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: line.inactive
                                                            ? '#808080'
                                                            : ''
                                                    }}
                                                >
                                                    {line.backorderQuantity}
                                                </Typography>
                                            ) : (
                                                <>
                                                    <RoleCheck
                                                        permission={
                                                            'sale_to_proforma'
                                                        }
                                                        component={
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                data-cy="boQtyField_permission"
                                                                disabled={
                                                                    readOnly ||
                                                                    (saleDetails.status ===
                                                                        'Sale' &&
                                                                        oldSale.status ===
                                                                            'Sale')
                                                                }
                                                                variant="standard"
                                                                value={
                                                                    line.backorderQuantity
                                                                }
                                                                onChange={(e) =>
                                                                    AdjustLineValue(
                                                                        line.rowId,
                                                                        'backorderQuantity',
                                                                        e.target
                                                                            .value,
                                                                        line.gstFree,
                                                                        saleDetails,
                                                                        setSaleDetails,
                                                                        line
                                                                    )
                                                                }
                                                                inputProps={{
                                                                    style: {
                                                                        textAlign:
                                                                            'center'
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        nullComponent={
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                data-cy="boQtyField_noPermission"
                                                                disabled={
                                                                    readOnly ||
                                                                    oldSale.status ===
                                                                        'Sale'
                                                                }
                                                                variant="standard"
                                                                value={
                                                                    line.backorderQuantity
                                                                }
                                                                onChange={(e) =>
                                                                    AdjustLineValue(
                                                                        line.rowId,
                                                                        'backorderQuantity',
                                                                        e.target
                                                                            .value,
                                                                        line.gstFree,
                                                                        saleDetails,
                                                                        setSaleDetails,
                                                                        line
                                                                    )
                                                                }
                                                                inputProps={{
                                                                    style: {
                                                                        textAlign:
                                                                            'center'
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    {parseInt(
                                                        line.backorderQuantity
                                                    ) > 0 ? (
                                                        <>
                                                            <Typography variant="caption">
                                                                D
                                                            </Typography>
                                                            <Switch
                                                                size="small"
                                                                disabled={
                                                                    readOnly ||
                                                                    oldSale.status ===
                                                                        'Sale'
                                                                }
                                                                checked={
                                                                    line.stockOrderType
                                                                }
                                                                onChange={(e) =>
                                                                    AdjustLineValue(
                                                                        line.rowId,
                                                                        'stockOrderType',
                                                                        e.target
                                                                            .checked,
                                                                        line.gstFree,
                                                                        saleDetails,
                                                                        setSaleDetails,
                                                                        line
                                                                    )
                                                                }
                                                            />
                                                            <Typography variant="caption">
                                                                S
                                                            </Typography>
                                                        </>
                                                    ) : null}
                                                </>
                                            )}
                                        </DataCell>
                                    </>
                                )}

                                {/* Unit Price Data */}
                                <DataCell>
                                    {line.inactive ? (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: line.inactive
                                                    ? '#808080'
                                                    : ''
                                            }}
                                        >
                                            {CurrencyFormatter(
                                                (line.quantity < 0 ||
                                                    line.backorderQuantity <
                                                        0) &&
                                                    line.amountRefunded > 0
                                                    ? Math.abs(
                                                          line.amountRefunded /
                                                              (line.quantity +
                                                                  line.backorderQuantity)
                                                      )
                                                    : line.pricePaid
                                            )}
                                        </Typography>
                                    ) : (
                                        <RoleCheck
                                            permission={'sale_to_proforma'}
                                            component={
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    disabled={
                                                        readOnly ||
                                                        (saleDetails.status ===
                                                            'Sale' &&
                                                            oldSale.status ===
                                                                'Sale') ||
                                                        (line.quantity < 0 &&
                                                            line.amountRefunded >
                                                                0)
                                                    }
                                                    variant="standard"
                                                    value={
                                                        (line.quantity < 0 ||
                                                            line.backorderQuantity <
                                                                0) &&
                                                        line.amountRefunded > 0
                                                            ? Math.abs(
                                                                  line.amountRefunded /
                                                                      (line.quantity +
                                                                          line.backorderQuantity)
                                                              )
                                                            : line.pricePaid
                                                    }
                                                    onChange={(e) =>
                                                        AdjustLineValue(
                                                            line.rowId,
                                                            'pricePaid',
                                                            e.target.value,
                                                            line.gstFree,
                                                            saleDetails,
                                                            setSaleDetails,
                                                            line
                                                        )
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            }
                                            nullComponent={
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    disabled={
                                                        readOnly ||
                                                        oldSale.status ===
                                                            'Sale'
                                                    }
                                                    variant="standard"
                                                    value={
                                                        (line.quantity < 0 ||
                                                            line.backorderQuantity <
                                                                0) &&
                                                        line.amountRefunded > 0
                                                            ? Math.abs(
                                                                  line.amountRefunded /
                                                                      (line.quantity +
                                                                          line.backorderQuantity)
                                                              )
                                                            : line.pricePaid
                                                    }
                                                    onChange={(e) =>
                                                        AdjustLineValue(
                                                            line.rowId,
                                                            'pricePaid',
                                                            e.target.value,
                                                            line.gstFree,
                                                            saleDetails,
                                                            setSaleDetails,
                                                            line
                                                        )
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                </DataCell>

                                {/* Total Price Data */}
                                <DataCell>
                                    <Tooltip
                                        placement="bottom"
                                        title={
                                            <span
                                                style={{
                                                    whiteSpace: 'break-spaces'
                                                }}
                                            >
                                                {/* This needs to be on one line due to the spacing within the tooltip */}
                                                {/* Please don't seperate it out onto multiple lines */}
                                                {`RRP: ${CurrencyFormatter(line.priceRRP)}\n${line.isTradePrice ? 'Trade RRP: ' + CurrencyFormatter(line.tradeRRP) + '\n' : ''}Average Cost: ${CurrencyFormatter(
                                                    line.costPriceAverage
                                                )}\nDaily Cost: ${CurrencyFormatter(line.costPriceDaily)}\nMargin: ${line.margin}%\nDiscount: ${CurrencyFormatter(line.priceRRP - parseFloat(line.pricePaid))}`}
                                            </span>
                                        }
                                    >
                                        <span>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: line.inactive
                                                        ? '#808080'
                                                        : ''
                                                }}
                                            >
                                                {CurrencyFormatter(
                                                    (line.quantity < 0 ||
                                                        line.backorderQuantity <
                                                            0) &&
                                                        line.amountRefunded > 0
                                                        ? Math.abs(
                                                              line.amountRefunded /
                                                                  (line.quantity +
                                                                      line.backorderQuantity)
                                                          )
                                                        : line.totalPrice
                                                )}
                                            </Typography>
                                            {line.gstFree ? (
                                                <Chip
                                                    label="Ex. GST"
                                                    size="small"
                                                    variant="outlined"
                                                    color="primary"
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    </Tooltip>
                                </DataCell>

                                {/* OrderLine Menu Data */}
                                <DataCell>
                                    {line.inactive ? (
                                        <Typography
                                            variant="caption"
                                            sx={{ color: '#808080' }}
                                        >
                                            Sale Id:{' '}
                                            <Typography
                                                variant="caption"
                                                color="primary"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    window.open(
                                                        `/inventory/viewSale/${line.linkedSaleId}`,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                {line.linkedSaleId}
                                            </Typography>
                                        </Typography>
                                    ) : (
                                        <OrderlineMenu
                                            row={line}
                                            disabled={
                                                readOnly ||
                                                oldSale.status === 'Sale'
                                            }
                                            saleDetails={saleDetails}
                                            setSaleDetails={setSaleDetails}
                                            showSnackbar={showSnackbar}
                                        />
                                    )}
                                </DataCell>
                            </TableRow>
                        ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={stockOrderDrawerOpen}
                setOpenDrawer={setStockOrderDrawerOpen}
                title={`Stock Order #${selectedStockOrder?.id}`}
                subTitle={`Placed On ${DateFormatter(selectedStockOrder?.createdAt)} by ${selectedStockOrder?.User?.firstName} ${selectedStockOrder?.User?.lastName}`}
            >
                <StockOrderDrawerContent
                    selectedStockOrder={selectedStockOrder}
                />
            </Drawer>
        </>
    );
};

export default OrderlineTable;
