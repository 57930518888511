// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../global/Paper';
// LOGIC
import CheckDocumentReference from '../../../global/logic/CheckDocumentReference';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// INTERFACES
import {
    MultipleStockInvoice,
    StockInvoice
} from '../../../global/interfaces/PartsInterface';
import {
    calculateGstTotal,
    calculateDifference,
    calculateEnteredTotal,
    calculateEnteredTotalMultipleStockOrder,
    calculateGstTotalMultipleStockOrder,
    calculateDifferenceMultipleStockOrder
} from '../logic/CalculateValues';
interface InvoiceFooterProps {
    invoiceData: StockInvoice;
    setInvoiceData: Dispatch<SetStateAction<StockInvoice>>;
    isMultipleInvoice: boolean;
    multipleInvoiceData: MultipleStockInvoice;
    setMultipleInvoiceData: Dispatch<SetStateAction<MultipleStockInvoice>>;
    invoiceError: string;
    responseCode: number;
    setResponseCode: Dispatch<SetStateAction<number>>;
}

const InvoiceFooter = ({
    invoiceData,
    setInvoiceData,
    isMultipleInvoice,
    multipleInvoiceData,
    setMultipleInvoiceData,
    invoiceError,
    responseCode,
    setResponseCode
}: InvoiceFooterProps) => {
    const [dateWarning, setDateWarning] = useState<string>('');

    const handleDateChange = (newValue: dayjs.Dayjs) => {
        const daysDiffToToday = dayjs().diff(newValue, 'day');

        if (daysDiffToToday > 30) {
            setDateWarning(`${daysDiffToToday} Days Away From Today.`);
        } else {
            setDateWarning('');
        }
        if (isMultipleInvoice) {
            setMultipleInvoiceData({
                ...multipleInvoiceData,
                invoiceDate: newValue.toISOString()
            });
        } else {
            setInvoiceData({
                ...invoiceData,
                invoiceDate: newValue.toISOString()
            });
        }
        localStorage.setItem(
            'stockInvoiceData',
            JSON.stringify({
                ...invoiceData,
                invoiceDate: newValue.toISOString()
            })
        );
    };
    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Document Reference *"
                            InputLabelProps={{ shrink: true }}
                            value={
                                isMultipleInvoice
                                    ? multipleInvoiceData.documentReference
                                    : invoiceData.documentReference
                            }
                            onChange={(e) => {
                                if (isMultipleInvoice) {
                                    setMultipleInvoiceData({
                                        ...multipleInvoiceData,
                                        documentReference: e.target.value
                                    });
                                } else {
                                    setInvoiceData({
                                        ...invoiceData,
                                        documentReference: e.target.value
                                    });
                                }
                                localStorage.setItem(
                                    'stockInvoiceData',
                                    JSON.stringify({
                                        ...invoiceData,
                                        documentReference: e.target.value
                                    })
                                );
                            }}
                            onBlur={(e) =>
                                CheckDocumentReference(
                                    e.target.value,
                                    setResponseCode
                                )
                            }
                            error={
                                responseCode === 400 ||
                                invoiceError === 'documentReference'
                                    ? true
                                    : false
                            }
                            helperText={
                                responseCode === 400
                                    ? 'Document Reference Already Exists.'
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Document Date *"
                                value={
                                    isMultipleInvoice
                                        ? dayjs(
                                              multipleInvoiceData?.invoiceDate
                                          )
                                        : dayjs(invoiceData?.invoiceDate)
                                }
                                onChange={(newValue) => {
                                    handleDateChange(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        helperText: dateWarning,
                                        size: 'small',
                                        InputLabelProps: { shrink: true },
                                        FormHelperTextProps: {
                                            style: { color: 'red' }
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Document Total *"
                            InputLabelProps={{ shrink: true }}
                            value={
                                isMultipleInvoice
                                    ? multipleInvoiceData.documentTotal
                                    : invoiceData.documentTotal
                            }
                            onChange={(e) => {
                                if (isMultipleInvoice) {
                                    setMultipleInvoiceData({
                                        ...multipleInvoiceData,
                                        documentTotal: e.target.value
                                    });
                                } else {
                                    setInvoiceData({
                                        ...invoiceData,
                                        documentTotal: e.target.value
                                    });
                                }
                                localStorage.setItem(
                                    'stockInvoiceData',
                                    JSON.stringify({
                                        ...invoiceData,
                                        documentTotal: e.target.value
                                    })
                                );
                            }}
                            error={
                                invoiceError === 'documentTotal' ||
                                invoiceError === 'balance'
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Freight *"
                            InputLabelProps={{ shrink: true }}
                            value={
                                isMultipleInvoice
                                    ? multipleInvoiceData.freight
                                    : invoiceData.freight
                            }
                            onChange={(e) => {
                                if (isMultipleInvoice) {
                                    setMultipleInvoiceData({
                                        ...multipleInvoiceData,
                                        freight: e.target.value
                                    });
                                } else {
                                    setInvoiceData({
                                        ...invoiceData,
                                        freight: e.target.value
                                    });
                                }
                                localStorage.setItem(
                                    'stockInvoiceData',
                                    JSON.stringify({
                                        ...invoiceData,
                                        freight: e.target.value
                                    })
                                );
                            }}
                            error={invoiceError === 'freight'}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled
                            fullWidth
                            size="small"
                            label="Entered Total"
                            InputLabelProps={{ shrink: true }}
                            value={
                                isMultipleInvoice
                                    ? CurrencyFormatter(
                                          calculateEnteredTotalMultipleStockOrder(
                                              multipleInvoiceData?.orderLines,
                                              multipleInvoiceData.freight
                                          )
                                      )
                                    : CurrencyFormatter(
                                          calculateEnteredTotal(
                                              invoiceData.orderLines,
                                              invoiceData.freight
                                          )
                                      )
                            }
                            className={
                                invoiceError === 'balance'
                                    ? 'muiTextfieldDisabledError'
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            disabled
                            fullWidth
                            size="small"
                            label="GST"
                            InputLabelProps={{ shrink: true }}
                            value={
                                isMultipleInvoice
                                    ? CurrencyFormatter(
                                          calculateGstTotalMultipleStockOrder(
                                              multipleInvoiceData?.orderLines,
                                              multipleInvoiceData.gstMethod,
                                              multipleInvoiceData.freight
                                          )
                                      )
                                    : CurrencyFormatter(
                                          calculateGstTotal(
                                              invoiceData.orderLines,
                                              invoiceData.gstMethod,
                                              invoiceData.freight
                                          )
                                      )
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            disabled
                            fullWidth
                            size="small"
                            label="Difference"
                            InputLabelProps={{ shrink: true }}
                            value={
                                isMultipleInvoice
                                    ? CurrencyFormatter(
                                          calculateDifferenceMultipleStockOrder(
                                              multipleInvoiceData?.orderLines,
                                              multipleInvoiceData.gstMethod,
                                              multipleInvoiceData.freight,
                                              multipleInvoiceData.documentTotal
                                          )
                                      )
                                    : CurrencyFormatter(
                                          calculateDifference(
                                              invoiceData.orderLines,
                                              invoiceData.gstMethod,
                                              invoiceData.freight,
                                              invoiceData.documentTotal
                                          )
                                      )
                            }
                            error={invoiceError === 'balance'}
                            className={
                                invoiceError === 'balance'
                                    ? 'muiTextfieldDisabledError'
                                    : ''
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default InvoiceFooter;
