export const safeCalculateAmountDiff = (
    a: string | number | null | undefined,
    b: string | number | null | undefined
): number => {
    const numA: number =
        a === null || a === undefined
            ? 0
            : typeof a === 'string'
              ? parseFloat(a)
              : a;

    const numB: number =
        b === null || b === undefined
            ? 0
            : typeof b === 'string'
              ? parseFloat(b)
              : b;

    if (isNaN(numA) && isNaN(numB)) {
        throw new Error('Both values are not valid numbers.');
    } else if (isNaN(numA)) {
        throw new Error('The first value is not a valid number.');
    } else if (isNaN(numB)) {
        throw new Error('The second value is not a valid number.');
    }

    return parseFloat((numA - numB).toFixed(2));
};
