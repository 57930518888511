import api from '../../../../api';
import { Site } from '../interfaces/GeneralInterface';

interface GetDashboardDataProps {
    type: string;
    startDate: string;
    endDate: string;
    selectedSite: Site[];
}

const getDashboardData = async ({
    type,
    startDate,
    endDate,
    selectedSite
}: GetDashboardDataProps) => {
    const siteIds = selectedSite.map((site) => site.id).join(',');
    const url = `/dashboard/${type}?startDate=${startDate}&endDate=${endDate}&SiteId=${siteIds}`;

    try {
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export default getDashboardData;
