import moment from 'moment';
import { saveAs } from 'file-saver';
import api from '../../../../../../api';
import CalculateStockOrderTotalCost from '../CalculateStockOrderTotalCost';

import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Api } from '../../../../global/interfaces/GeneralInterface';
interface Table {
    OrderType: string;
    orderLines: TableOrderLine[];
    orderReference: string;
    supplierId: number;
    supplierName: string;
    tableId: number;
    totalCost: number;
}

interface TableOrderLine {
    OrderType: string; // probably not useful
    costPrice: number;
    gst: number;
    id: number;
    maxStock: number; // probably not used anymore
    minStock: number; // probably not used anymore
    name: string;
    partNumber: string;
    qtyOnHand: number;
    quantity: number;
    rowId: number;
    rowTotal: number;
    rrp: number;
    selected: boolean;
    supplier: any; // don't know what it's for

    linkedOrders?: number[];
    linkedOrdersQuantity?: number[];
    orderId?: number;
    costPriceDaily?: number;
    costPriceAverage?: number;
    supplierName?: string;
}

const GenerateHondaTXTOrder = (
    obj: { generator: Api; table: Table },
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    showSnackbar: showSnackbar
) => {
    let today = moment();
    let hondaTable = obj.table;
    let generator = obj.generator;
    let blankString = ' ';
    let zeroString = '0';

    // first line of the txt file is the header
    let txtFile = `HOND${generator.additionalDetails.digitCode}                ${today.format('YYMMDDhhmmss')}OROH          ${hondaTable.orderReference}${blankString.repeat(15 - hondaTable.orderReference.length)}  ${blankString.repeat(20)}${hondaTable.OrderType === 'daily' ? 'D' : 'S'}${blankString.repeat(35)}0001   \n`;

    // For each orderlines, add a line to the text file
    for (let x = 0; x < hondaTable.orderLines.length; x++) {
        let line = hondaTable.orderLines[x];

        if (x === hondaTable.orderLines.length - 1) {
            txtFile = txtFile.concat(
                `HOND${generator.additionalDetails.digitCode}                ${today.format('YYMMDDhhmmss')}OROL${line.partNumber}${blankString.repeat(20 - line.partNumber.length)}${zeroString.repeat(11 - Math.round(line.quantity * 100).toString().length)}${Math.round(line.quantity * 100).toString()}Y${blankString.repeat(51)}1${zeroString.repeat(
                    3 - x.toString().length
                )}${(x + 1).toString()}Y  `
            );
        } else {
            txtFile = txtFile.concat(
                `HOND${generator.additionalDetails.digitCode}                ${today.format('YYMMDDhhmmss')}OROL${line.partNumber}${blankString.repeat(20 - line.partNumber.length)}${zeroString.repeat(11 - Math.round(line.quantity * 100).toString().length)}${Math.round(line.quantity * 100).toString()}Y${blankString.repeat(51)}1${zeroString.repeat(
                    3 - x.toString().length
                )}${(x + 1).toString()}   \n`
            );
        }
    }

    // Save the file
    const file = new Blob([txtFile], { type: 'text/plain' });
    saveAs(
        file,
        `HONDA_ORDER${hondaTable.orderReference ? `_${hondaTable.orderReference}` : ''}_${today.format('YYMMDDhhmmss')}.txt`
    );

    // Remove the generated order, so that the user can still submit the others orders without redoing the whole stock order
    let newTables = [];
    let orderedTables = [];
    tables.forEach((table) => {
        if (
            table.supplierName !== hondaTable.supplierName ||
            table.OrderType !== hondaTable.OrderType
        ) {
            newTables.push(table);
        } else {
            orderedTables.push(table);
        }
    });
    setTables(newTables);

    // Send the Stock Order to the database
    var postObj = {
        tables: orderedTables,
        totalCost: CalculateStockOrderTotalCost(orderedTables).toFixed(2),
        SiteId: localStorage.getItem('SiteId'),
        generated: false,
        txtGenerated: true
    };

    api.post(`stockorder?emailed=false`, postObj).then((res) => {
        if (res.status === 200) {
            showSnackbar('Order .txt file generated');
        } else {
            showSnackbar(
                'Order not submitted. Please check your data or try again later.',
                '',
                'error'
            );
        }
    });
};

export default GenerateHondaTXTOrder;
