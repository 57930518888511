// IMPORTS
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import {
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';

// COMPONENTS
import Fab from '../../global/Fab';
import PageWrapper from '../../global/PageWrapper';
import BusinessDetails from './components/BusinessDetails';
import PaymentMethods from './components/PaymentMethods';
import VehicleSaleWarranties from './components/VehicleSaleWarranties';
import RegistrationPrice from './components/RegistrationPrice';
import FutureRegistrationPrice from './components/FutureRegistrationPrice';
import CtpPrice from './components/CtpPrice';
import FutureCtpPrice from './components/FutureCtpPrice';
import Paper from '../../global/Paper';
import Departments from './components/Departments';
import { withSnackbar } from '../../global/WrappingSnackbar';

//LOGIC
import GetCompanyDetails from '../../global/databaseLogic/GetCompanyDetails';
import HandleUpdateCompany from './logic/HandleUpdateCompany';
import GetQLDRegistration from '../../global/databaseLogic/GetQLDRegistration';
import GetFutureQLDRegistration from '../../global/databaseLogic/GetFutureQLDRegistration';
import GetQLDCtp from '../../global/databaseLogic/GetQLDCtp';
import GetFutureQLDCtp from '../../global/databaseLogic/GetFutureQLDCtp';
import GetNSWCtp from '../../global/databaseLogic/GetNSWCtp';
import GetFutureNSWCtp from '../../global/databaseLogic/GetFutureNSWCtp';
import GetNSWRegistration from '../../global/databaseLogic/GetNSWRegistration';
import GetFutureNSWRegistration from '../../global/databaseLogic/GetFutureNSWRegistration';

//INTERFACES
import {
    Ctp,
    Registration
} from '../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Site } from '../../global/interfaces/GeneralInterface';

/**
 * ViewCompany
 * The overarching page for Company Settings
 * @author Estienne
 */
const ViewCompany = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [settings, setSettings] = useState(null); // Company settings
    const [site, setSite] = useState<Site>(null);
    const [selectedState, setSelectedState] = useState<string>('');

    const [registrations, setRegistrations] = useState<Registration[]>([]);
    const [futureRegistrations, setFutureRegistrations] = useState<
        Registration[]
    >([]);
    const [futureRegoChange, setFutureRegoChange] = useState<boolean>(false);

    const [ctp, setCtp] = useState<Ctp[]>([]);
    const [futureCtp, setFutureCtp] = useState<Ctp[]>([]);
    const [futureCtpChange, setFutureCtpChange] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);

    /**
     * useEffect
     * Get the company settings
     */
    useEffect(() => {
        GetCompanyDetails(
            parseInt(localStorage.getItem('SiteId')),
            setSettings,
            setSite
        );
        setLoading(false);
    }, []);

    useEffect(() => {
        if (site) {
            setSelectedState(site.state);
        }
    }, [site]);

    useEffect(() => {
        if (selectedState.length > 0) {
            if (selectedState === 'NSW') {
                GetNSWCtp(setCtp);
                GetFutureNSWCtp(setFutureCtp);
                GetNSWRegistration(setRegistrations);
                GetFutureNSWRegistration(setFutureRegistrations);
            } else if (selectedState === 'QLD') {
                GetQLDCtp(setCtp);
                GetFutureQLDCtp(setFutureCtp);
                GetQLDRegistration(setRegistrations);
                GetFutureQLDRegistration(setFutureRegistrations);
            }
        }
    }, [selectedState]);

    const handleStateChange = (e: BaseSyntheticEvent) => {
        setSelectedState(e.target.value);
    };

    return (
        <PageWrapper>
            {!loading ? (
                <>
                    <Typography variant="h4" align="left">
                        Company Settings
                    </Typography>
                    <br></br>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <BusinessDetails
                                        settings={settings}
                                        setSettings={setSettings}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <br />
                                <Grid item xs={12}>
                                    <Paper>
                                        <ToggleButtonGroup
                                            size="small"
                                            color="primary"
                                            exclusive
                                            fullWidth
                                            value={selectedState}
                                            onChange={handleStateChange}
                                        >
                                            <ToggleButton value="QLD">
                                                QLD
                                            </ToggleButton>
                                            <ToggleButton value="NSW">
                                                NSW
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <RegistrationPrice
                                        registrations={registrations}
                                        setRegistrations={setRegistrations}
                                        state={selectedState}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FutureRegistrationPrice
                                        registrations={futureRegistrations}
                                        setRegistrations={
                                            setFutureRegistrations
                                        }
                                        setFutureRegoChange={
                                            setFutureRegoChange
                                        }
                                        state={selectedState}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={selectedState === 'NSW' ? 12 : 6}
                                >
                                    <CtpPrice
                                        ctp={ctp}
                                        setCtp={setCtp}
                                        state={selectedState}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={selectedState === 'NSW' ? 12 : 6}
                                >
                                    <FutureCtpPrice
                                        ctp={futureCtp}
                                        setCtp={setFutureCtp}
                                        setFutureCtpChange={setFutureCtpChange}
                                        state={selectedState}
                                        disabled={disabled}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <PaymentMethods
                                settings={settings}
                                setSettings={setSettings}
                                disabled={disabled}
                            />
                            <br />
                            <VehicleSaleWarranties
                                settings={settings}
                                setSettings={setSettings}
                                disabled={disabled}
                            />
                            <br />
                            <Departments
                                settings={settings}
                                setSettings={setSettings}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                ''
            )}

            <Fab
                editIcon={disabled}
                onClick={() => {
                    disabled
                        ? setDisabled(false)
                        : HandleUpdateCompany({
                              settings,
                              state: selectedState,
                              registrations,
                              futureRegistrations,
                              futureRegoChange,
                              ctp,
                              futureCtp,
                              futureCtpChange,
                              showSnackbar
                          });
                }}
            />
        </PageWrapper>
    );
};

export default withSnackbar(ViewCompany);
