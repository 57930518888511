// IMPORTS
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
// LOGIC
import api from '../../../../../api';
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import moment from 'moment';

interface CreditorPayments {
    id: number;
    name: string;
    totalDue: number;
    undated: number;
    dueThisMonth: number;
    dueAfterThisMonth: number;
    overdueLastMonth: number;
    overDueBeforeLastMonth: number;
}

const PDFCreditorBalanceReport = async () => {
    let creditorPayments: CreditorPayments[];

    const caluclateTotals = (returnType) => {
        let totalDue = 0;
        let undated = 0;
        let dueThisMonth = 0;
        let dueAfterThisMonth = 0;
        let overdueLastMonth = 0;
        let overDueBeforeLastMonth = 0;

        creditorPayments.forEach((payment) => {
            totalDue += payment.totalDue;
            undated += payment.undated;
            dueThisMonth += payment.dueThisMonth;
            dueAfterThisMonth += payment.dueAfterThisMonth;
            overdueLastMonth += payment.overdueLastMonth;
            overDueBeforeLastMonth += payment.overDueBeforeLastMonth;
        });

        if (returnType === 'totalDue') {
            return totalDue;
        } else if (returnType === 'undated') {
            return undated;
        } else if (returnType === 'dueThisMonth') {
            return dueThisMonth;
        } else if (returnType === 'dueAfterThisMonth') {
            return dueAfterThisMonth;
        } else if (returnType === 'overdueLastMonth') {
            return overdueLastMonth;
        } else if (returnType === 'overDueBeforeLastMonth') {
            return overDueBeforeLastMonth;
        }
    };

    await api.get('overdueCreditorPayments').then((res) => {
        creditorPayments = res.data;
        creditorPayments.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
    });

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Header for the invoice
    await InvoiceHeader(
        doc,
        parseInt(localStorage.getItem('SiteId')),
        'Creditor Balance Statement',
        moment().format('MMMM YYYY'),
        false,
        true,
        false
    );

    let lastYcoordinates = 82;

    let body = [];

    creditorPayments.forEach((creditor) => {
        let row = [
            creditor.name.toUpperCase(),
            CurrencyFormatter(creditor.totalDue),
            CurrencyFormatter(creditor.dueThisMonth),
            CurrencyFormatter(creditor.dueAfterThisMonth),
            CurrencyFormatter(creditor.overdueLastMonth),
            CurrencyFormatter(creditor.overDueBeforeLastMonth),
            CurrencyFormatter(creditor.undated)
        ];
        body.push(row);
    });

    body.push([
        'Totals',
        CurrencyFormatter(caluclateTotals('totalDue')),
        CurrencyFormatter(caluclateTotals('dueAfterThisMonth')),
        CurrencyFormatter(caluclateTotals('dueThisMonth')),
        CurrencyFormatter(caluclateTotals('overdueLastMonth')),
        CurrencyFormatter(caluclateTotals('overDueBeforeLastMonth')),
        CurrencyFormatter(caluclateTotals('undated'))
    ]);

    autoTable(doc, {
        head: [
            [
                'Name',
                'Total Due',
                'Due Later',
                'Due This Month',
                'Due Last Month',
                'Due Earlier',
                'Undated'
            ]
        ],
        body: body,
        theme: 'grid',
        headStyles: {
            fillColor: [19, 65, 123],
            textColor: 'white',
            lineColor: [19, 65, 123]
        },
        startY: lastYcoordinates,
        margin: { bottom: 20 },
        styles: { lineColor: [201, 201, 201], lineWidth: 0.1 },
        willDrawCell: function (data) {
            var rows = data.table.body;
            if (data.row.index === rows.length - 1) {
                doc.setFont('helvetica', 'bold');
            }
        }
    });

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFCreditorBalanceReport;
