import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const AddPartsToBrand = (
    brandId: number,
    unitIds: number[],
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.put('addPartsToBrand', { brandId: brandId, unitIds: unitIds }).then(
        (res) => {
            if (res.status === 200) {
                window.location.reload();
            } else {
                showSnackbar(
                    'Something went wrong.',
                    'Refresh the page and try again.',
                    'error'
                );
                setButtonLoading(false);
            }
        }
    );
};

export default AddPartsToBrand;
