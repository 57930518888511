// IMPROTS
import { Dispatch, SetStateAction } from 'react';
import {
    FormGroup,
    Grid,
    FormControlLabel,
    Radio,
    TextField,
    TableRow,
    IconButton
} from '@mui/material';
import { Close } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import PartSearch from '../../../global/PartSearch';
import CreditorSelector from '../../../global/CreditorSelector';
import SiteSelector from '../../../global/SiteSelector';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import {
    handleQuantityChange,
    handleLineTotalChange,
    removePart
} from '../logic/CreditInvoiceLogic';
// INTERFACES
import { Stock, Unit } from '../../../global/interfaces/PartsInterface';
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface CreditingPartsLine {
    partNumber: string;
    name: string;
    unitId: number;
    costPriceDaily: number;
    quantity: number;
    lineTotal: number;
    qtyError: boolean;
    lineTotalError: boolean;
}

interface InvoiceTableProps {
    selectedSupplierData: Creditor;
    creditingParts: CreditingPartsLine[];
    setCreditingParts: Dispatch<SetStateAction<CreditingPartsLine[]>>;
    isGSTIncluded: boolean;
    setIsGSTIncluded: Dispatch<SetStateAction<boolean>>;
    selectedSupplier: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
    selectedSite: number;
    setSelectedSupplier: Dispatch<SetStateAction<number>>;
    readOnly: boolean;
    showSnackbar: showSnackbar;
}

const InvoiceTable = ({
    selectedSite,
    setSelectedSite,
    selectedSupplier,
    setSelectedSupplier,
    selectedSupplierData,
    creditingParts,
    setCreditingParts,
    showSnackbar,
    isGSTIncluded,
    setIsGSTIncluded,
    readOnly
}: InvoiceTableProps) => {
    const columns = [
        { id: 0, label: 'Part Number', width: 300 },
        { id: 1, label: 'Part Name', width: 500 },
        { id: 2, label: 'Cost on File', width: 200 },
        { id: 3, label: 'Quantity', width: 200 },
        { id: 4, label: 'Line Total', width: 200 },
        { id: 5, label: '', width: 50 }
    ];

    const addPart = (
        e: { unitData: Unit; stockData: Stock },
        selectedSupplier
    ) => {
        if (selectedSupplier) {
            if (selectedSupplierData.type !== 'internal') {
                let isDifferentSupplier =
                    Number(selectedSupplier.SupplierId) !==
                    Number(selectedSupplierData.id);
                if (isDifferentSupplier) {
                    showSnackbar(
                        'Part not supplied by ' + selectedSupplierData.name,
                        '',
                        'error'
                    );
                    return;
                }
            }

            let cpd = selectedSupplier?.CPD
                ? selectedSupplier.CPD
                : e.unitData.costPriceDaily;

            setCreditingParts([
                ...creditingParts,
                {
                    partNumber: e.unitData.partNumber,
                    name: e.unitData.name,
                    unitId: e.unitData.id,
                    costPriceDaily: cpd,
                    quantity: 0,
                    lineTotal: 0,
                    qtyError: false,
                    lineTotalError: false
                }
            ]);
        } else {
            if (selectedSupplierData.type !== 'internal') {
                let isDifferentSupplier = e.unitData.SupplierIds.some(
                    (supplier) =>
                        Number(supplier.SupplierId) !==
                        Number(selectedSupplierData.id)
                );
                if (isDifferentSupplier) {
                    showSnackbar(
                        'Part not supplied by ' + selectedSupplierData.name,
                        '',
                        'error'
                    );
                    return;
                }
            }
            setCreditingParts([
                ...creditingParts,
                {
                    partNumber: e.unitData.partNumber,
                    name: e.unitData.name,
                    unitId: e.unitData.id,
                    costPriceDaily: e.unitData.costPriceDaily,
                    quantity: 0,
                    lineTotal: 0,
                    qtyError: false,
                    lineTotalError: false
                }
            ]);
        }
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <PartSearch
                            HandleAdd={addPart}
                            isDisabled={selectedSupplierData.id ? false : true}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CreditorSelector
                            isDisabled={readOnly}
                            selectedSupplier={selectedSupplier}
                            setSelectedSupplier={setSelectedSupplier}
                            label="Creditor"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SiteSelector
                            fullWidth
                            variant="outlined"
                            label="Site"
                            selectedSite={selectedSite}
                            setSelectedSite={setSelectedSite}
                            viewPartUI={false}
                            disabled={readOnly}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormGroup row>
                            <FormControlLabel
                                label="INC. GST"
                                control={
                                    <Radio
                                        checked={isGSTIncluded}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            setIsGSTIncluded(e.target.checked)
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="EX. GST"
                                control={
                                    <Radio
                                        checked={!isGSTIncluded}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            setIsGSTIncluded(!e.target.checked)
                                        }
                                    />
                                }
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <br />
                <DataTable columns={columns}>
                    {creditingParts.map((part, index) => (
                        <TableRow>
                            <DataCell>{part.partNumber}</DataCell>
                            <DataCell>{part.name}</DataCell>
                            <DataCell>
                                {CurrencyFormatter(part.costPriceDaily)}
                            </DataCell>
                            <DataCell>
                                {readOnly ? (
                                    part.quantity
                                ) : (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        variant="standard"
                                        value={part.quantity}
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        error={part.qtyError}
                                        onChange={(e) => {
                                            handleQuantityChange(
                                                e.target.value,
                                                index,
                                                creditingParts,
                                                setCreditingParts
                                            );
                                        }}
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                {readOnly ? (
                                    CurrencyFormatter(part.lineTotal)
                                ) : (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        variant="standard"
                                        value={part.lineTotal}
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        error={part.lineTotalError}
                                        onChange={(e) => {
                                            handleLineTotalChange(
                                                e.target.value,
                                                index,
                                                creditingParts,
                                                setCreditingParts
                                            );
                                        }}
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                {readOnly ? null : (
                                    <IconButton
                                        onClick={() => {
                                            removePart(
                                                index,
                                                creditingParts,
                                                setCreditingParts
                                            );
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                )}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </>
    );
};

export default InvoiceTable;
