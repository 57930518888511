import React, { CSSProperties, useEffect, useState } from 'react';
import GetLogs from '../../../global/logic/GetLogs';
import {
    Box,
    Chip,
    Collapse,
    Divider,
    Grid,
    IconButton,
    TableRow,
    Typography
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { isNumber } from '../../../global/logic/globalValidation/NumericalValidation';

interface LogsDrawerContentProps {
    id: number;
}

const LogsDrawerContent = ({ id }: LogsDrawerContentProps) => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        GetLogs('parts', id, setLogs);
    }, [id]);

    const partsColumns = [
        { id: 0, label: 'Part Number' },
        { id: 1, label: 'Qty' },
        { id: 2, label: 'Backorder Qty' },
        { id: 3, label: 'Unit Price' },
        // linked sale column
        { id: 4, label: 'Linked Sale', width: 75 }
    ];

    const paymentColumns = [
        { id: 0, label: 'Type' },
        { id: 1, label: 'Amount' },
        // linked sale column
        { id: 2, label: 'Linked Sale', width: 75 }
    ];

    const controlCollapse = (log, index, selector) => {
        let currentLogs = [...logs];
        currentLogs[index][selector] = !currentLogs[index][selector];
        setLogs(currentLogs);
    };

    //calculate total price change bewteen current order and previous order
    const calculatePriceChange = (currentLog, previousLog, type) => {
        switch (type) {
            case 'totalPrice':
                const currentPrice =
                    parseFloat(currentLog?.Order?.amountPaid) +
                    parseFloat(currentLog?.Order?.amountOwed) +
                    parseFloat(currentLog?.Order?.freight ?? 0);
                const previousPrice =
                    parseFloat(previousLog?.Order?.amountPaid) +
                    parseFloat(previousLog?.Order?.amountOwed) +
                    parseFloat(previousLog?.Order?.freight ?? 0);
                return currentPrice - previousPrice;

            case 'freight':
                return currentLog?.Order?.freight - previousLog?.Order?.freight;

            default:
                return 0;
        }
    };

    //render price change indicator component
    const renderPriceChangeComponent = (currentLog, previousLog, type) => {
        const priceChange = calculatePriceChange(currentLog, previousLog, type);

        if (priceChange !== 0) {
            return (
                <Chip
                    icon={
                        priceChange > 0 ? (
                            <ArrowUpwardIcon style={{ fontSize: 10 }} />
                        ) : (
                            <ArrowDownwardIcon style={{ fontSize: 10 }} />
                        )
                    }
                    label={`${priceChange.toFixed(0)}`}
                    size="small"
                    color={priceChange > 0 ? 'success' : 'error'}
                    sx={{ height: '15px', marginLeft: '4px' }}
                />
            );
        } else {
            return null;
        }
    };

    //combine current log orderlines and previous log orderlines
    const getCombinedLogOrderlines = (
        currentLogOrderlines,
        previousLogOrderlines
    ) => {
        const combinedOrderlines = currentLogOrderlines
            .concat(previousLogOrderlines)
            .filter((item, index, self) => {
                return (
                    index ===
                    self.findIndex(
                        (i) =>
                            i.rowId === item.rowId &&
                            i.UnitId === item.UnitId &&
                            parseInt(i.quantity) === parseInt(item.quantity)
                    )
                );
            });

        return combinedOrderlines;
    };

    //check if the part is newly added
    const isNewPartAdd = (rowId, partUnitId, previousLogOrderlines) => {
        return !previousLogOrderlines.some(
            (item) => item.UnitId === partUnitId && item.rowId === rowId
        );
    };

    //check if exsiting part was removed from previous log
    const isExistingPartRemoved = (rowId, partUnitId, currentLogOrderlines) => {
        return !currentLogOrderlines.some(
            (item) => item.UnitId === partUnitId && item.rowId === rowId
        );
    };

    //highlight newly added or removed part
    const setRowColour = (isNewPartAdd, isExistingPartRemoved) => {
        let color = null;
        if (isNewPartAdd) {
            color = 'success.main';
        }

        if (isExistingPartRemoved) {
            color = 'text.disabled';
        }

        return color;
    };

    //calculate part quanlity/ backorderQuanlity change bewteen current order and previous order
    const renderPartQuantityChangeComponent = (
        partUnitId,
        currentLogOrderlines,
        previousLogOrderlines,
        quantityType
    ) => {
        const currentPart = currentLogOrderlines.find(
            (item) => item.UnitId === partUnitId
        );
        const previousPart = previousLogOrderlines.find(
            (item) => item.UnitId === partUnitId
        );

        if (currentPart && previousPart) {
            const quantityChange =
                currentPart[quantityType] - previousPart[quantityType];

            if (quantityChange !== 0) {
                return (
                    <Chip
                        icon={
                            quantityChange > 0 ? (
                                <ArrowUpwardIcon style={{ fontSize: 10 }} />
                            ) : (
                                <ArrowDownwardIcon style={{ fontSize: 10 }} />
                            )
                        }
                        label={`${quantityChange}`}
                        size="small"
                        color={quantityChange > 0 ? 'success' : 'error'}
                        sx={{ height: '15px', marginLeft: '2px' }}
                    />
                );
            } else {
                return null;
            }
        }
    };

    //get combined payment lines
    const getCombinedLogPaymentlines = (
        currentLogPaymentlines,
        previousLogPaymentlines
    ) => {
        const previousActivePaymentlines = previousLogPaymentlines.filter(
            (item) => item.removed !== true
        );
        const combinedPaymentlines = currentLogPaymentlines
            .concat(previousActivePaymentlines)
            .filter((item, index, self) => {
                return index === self.findIndex((i) => i.id === item.id);
            });

        return combinedPaymentlines;
    };

    //check if payment line is newly added
    const isNewlyAddedPayment = (timestamp, previousLogPaymentlines) => {
        return !previousLogPaymentlines.some(
            (item) => item.timestamp === timestamp
        );
    };

    //check if payment type changed
    const isPaymentTypeChanged = (line, previousLogPaymentlines) => {
        const previousPayment = previousLogPaymentlines[line.id];
        return previousPayment ? line.type !== previousPayment.type : false;
    };

    const isInfoChanged = (currentLogOrder, previousLogOrder, fieldName) => {
        return currentLogOrder[fieldName] !== previousLogOrder[fieldName];
    };

    const renderInfoTypography = (label, value, renderComponent) => (
        <Box mb={1}>
            <Typography
                variant="body1"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5em'
                }}
            >
                {label}: {renderComponent ? renderComponent(value) : value}
            </Typography>
        </Box>
    );

    const renderChip = (
        value,
        chipColor: 'default' | 'primary' | 'error' | 'success' = 'default'
    ) => (
        <Chip
            label={isNumber(value) ? `#${value}` : value}
            size="small"
            color={chipColor}
        />
    );

    const renderInfoComponent = (
        currentLogOrder,
        previousLogOrder,
        fieldName
    ) => {
        const hasInfoChanged = isInfoChanged(
            currentLogOrder,
            previousLogOrder,
            fieldName
        );
        const currentValue = currentLogOrder[fieldName];
        const previousValue = previousLogOrder[fieldName];

        return hasInfoChanged ? (
            <span style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                {previousValue !== null && (
                    <>
                        {renderChip(previousValue)}
                        <ArrowForwardIcon fontSize="small" color="success" />
                    </>
                )}
                {currentValue !== null && renderChip(currentValue, 'success')}
            </span>
        ) : (
            <>{isNumber(currentValue) ? `#${currentValue}` : currentValue}</>
        );
    };

    const Strikethrough: React.FC<{
        children: React.ReactNode;
        isRemoved: boolean;
    }> = ({ children, isRemoved }) => {
        if (!isRemoved) {
            return <>{children}</>;
        }

        const lineStyle: CSSProperties = {
            textDecoration: 'line-through',
            position: 'relative'
        };

        const coverLineStyle: CSSProperties = {
            position: 'absolute',
            height: '1px',
            width: '100%',
            backgroundColor: 'currentColor',
            top: '50%',
            transform: 'translateY(-50%)'
        };

        return (
            <div style={lineStyle}>
                {children}
                <div style={coverLineStyle} />
            </div>
        );
    };

    return (
        <Grid container gap={3}>
            {logs.length > 0 ? (
                logs?.map((log, index) => (
                    <Grid item key={index} xs={12} md={12} lg={12}>
                        {index === logs.length - 1 ? (
                            <Box mb={1}>
                                <Typography variant="h6">
                                    <b>
                                        Created on {DateFormatter(log.date)} by{' '}
                                        {log.UserName}
                                    </b>
                                </Typography>
                            </Box>
                        ) : (
                            <Box mb={1}>
                                <Typography variant="h6">
                                    <b>
                                        Updated on {DateFormatter(log.date)} by{' '}
                                        {log.UserName}
                                    </b>
                                </Typography>
                            </Box>
                        )}
                        {renderInfoTypography(
                            'Status',
                            log.Order?.status,
                            (value) =>
                                index < logs.length - 1
                                    ? renderInfoComponent(
                                          log.Order,
                                          logs[index + 1].Order,
                                          'status'
                                      )
                                    : value
                        )}
                        {log.Order?.CustomerId &&
                            renderInfoTypography(
                                'Customer',
                                log.Order?.CustomerId,
                                (value) =>
                                    index < logs.length - 1
                                        ? renderInfoComponent(
                                              log.Order,
                                              logs[index + 1].Order,
                                              'CustomerId'
                                          )
                                        : value
                            )}
                        {log.Order?.VehicleSaleId &&
                            renderInfoTypography(
                                'Contract',
                                log.Order?.VehicleSaleId,
                                (value) =>
                                    index < logs.length - 1
                                        ? renderInfoComponent(
                                              log.Order,
                                              logs[index + 1].Order,
                                              'VehicleSaleId'
                                          )
                                        : value
                            )}
                        {log.Order?.VehicleId &&
                            renderInfoTypography(
                                'Vehicle',
                                log.Order?.VehicleId,
                                (value) =>
                                    index < logs.length - 1
                                        ? renderInfoComponent(
                                              log.Order,
                                              logs[index + 1].Order,
                                              'VehicleId'
                                          )
                                        : value
                            )}
                        {log.Order?.ServiceId &&
                            renderInfoTypography(
                                'Service',
                                log.Order?.ServiceId,
                                (value) =>
                                    index < logs.length - 1
                                        ? renderInfoComponent(
                                              log.Order,
                                              logs[index + 1].Order,
                                              'ServiceId'
                                          )
                                        : value
                            )}
                        {log.Order?.internalType &&
                            log.Order?.internalType === 1 &&
                            renderInfoTypography('Customer', 'Workshop', null)}
                        {log.Order?.internalType &&
                            log.Order?.internalType === 2 &&
                            renderInfoTypography(
                                'Customer',
                                'Sales Department',
                                null
                            )}
                        {log?.Order?.PONumber &&
                            renderInfoTypography(
                                'PO Number',
                                log.Order?.PONumber,
                                (value) =>
                                    index < logs.length - 1
                                        ? renderInfoComponent(
                                              log.Order,
                                              logs[index + 1].Order,
                                              'PONumber'
                                          )
                                        : value
                            )}
                        {/* show freight change */}
                        <Box mb={1}>
                            {log?.Order?.freight && (
                                <Typography
                                    variant="body1"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '3'
                                    }}
                                >
                                    <span
                                        style={{
                                            display: 'flex',
                                            gap: 3,
                                            alignItems: 'center'
                                        }}
                                    >
                                        Freight:{' '}
                                        {CurrencyFormatter(log?.Order?.freight)}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'freight'
                                            )}
                                    </span>
                                </Typography>
                            )}
                        </Box>
                        {/* show total price change */}
                        <Box mb={1}>
                            <Typography
                                variant="body1"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '3'
                                }}
                            >
                                <span
                                    style={{
                                        display: 'flex',
                                        gap: 3,
                                        alignItems: 'center'
                                    }}
                                >
                                    Total Price:{' '}
                                    {CurrencyFormatter(
                                        parseFloat(log?.Order?.amountPaid) +
                                            parseFloat(log?.Order?.amountOwed) +
                                            parseFloat(log?.Order?.freight ?? 0)
                                    )}
                                    {index < logs.length - 1 &&
                                        renderPriceChangeComponent(
                                            log,
                                            logs[index + 1],
                                            'totalPrice'
                                        )}
                                </span>
                            </Typography>
                        </Box>
                        {/* show parts change */}
                        <Typography variant="body1">
                            <b>Parts</b>
                            <IconButton
                                onClick={() =>
                                    controlCollapse(log, index, 'partsOpen')
                                }
                            >
                                {log.partsOpen ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </IconButton>
                        </Typography>
                        <Collapse
                            in={log.partsOpen}
                            timeout="auto"
                            unmountOnExit
                        >
                            <DataTable columns={partsColumns}>
                                {index < logs.length - 1
                                    ? getCombinedLogOrderlines(
                                          log.Order.orderLines,
                                          logs[index + 1].Order.orderLines
                                      ).map((line) => {
                                          const isNewlyAdded = isNewPartAdd(
                                              line.rowId,
                                              line.UnitId,
                                              logs[index + 1].Order.orderLines
                                          );

                                          const isRemoved =
                                              isExistingPartRemoved(
                                                  line.rowId,
                                                  line.UnitId,
                                                  log.Order.orderLines
                                              );

                                          return (
                                              <TableRow
                                                  key={line.UnitId}
                                                  sx={{
                                                      backgroundColor:
                                                          setRowColour(
                                                              isNewlyAdded,
                                                              isRemoved
                                                          )
                                                  }}
                                              >
                                                  <DataCell
                                                      color={
                                                          line.inactive &&
                                                          !isNewlyAdded &&
                                                          !isRemoved
                                                              ? '#808080'
                                                              : null
                                                      }
                                                  >
                                                      <Strikethrough
                                                          children={
                                                              line.partNumber
                                                          }
                                                          isRemoved={isRemoved}
                                                      ></Strikethrough>
                                                  </DataCell>
                                                  <DataCell
                                                      color={
                                                          line.inactive &&
                                                          !isNewlyAdded &&
                                                          !isRemoved
                                                              ? '#808080'
                                                              : null
                                                      }
                                                  >
                                                      <Strikethrough
                                                          children={
                                                              line.quantity
                                                          }
                                                          isRemoved={isRemoved}
                                                      ></Strikethrough>
                                                      {index <
                                                          logs.length - 1 &&
                                                          renderPartQuantityChangeComponent(
                                                              line.UnitId,
                                                              log.Order
                                                                  .orderLines,
                                                              logs[index + 1]
                                                                  .Order
                                                                  .orderLines,
                                                              'quantity'
                                                          )}
                                                  </DataCell>
                                                  <DataCell
                                                      color={
                                                          line.inactive &&
                                                          !isNewlyAdded &&
                                                          !isRemoved
                                                              ? '#808080'
                                                              : null
                                                      }
                                                  >
                                                      <Strikethrough
                                                          children={
                                                              line.backorderQuantity
                                                          }
                                                          isRemoved={isRemoved}
                                                      ></Strikethrough>
                                                      {index <
                                                          logs.length - 1 &&
                                                          renderPartQuantityChangeComponent(
                                                              line.UnitId,
                                                              log.Order
                                                                  .orderLines,
                                                              logs[index + 1]
                                                                  .Order
                                                                  .orderLines,
                                                              'backorderQuantity'
                                                          )}
                                                  </DataCell>
                                                  <DataCell
                                                      color={
                                                          line.inactive &&
                                                          !isNewlyAdded &&
                                                          !isRemoved
                                                              ? '#808080'
                                                              : null
                                                      }
                                                  >
                                                      <Strikethrough
                                                          children={CurrencyFormatter(
                                                              line.pricePaid
                                                          )}
                                                          isRemoved={isRemoved}
                                                      ></Strikethrough>

                                                      {index <
                                                          logs.length - 1 &&
                                                          renderPartQuantityChangeComponent(
                                                              line.UnitId,
                                                              log.Order
                                                                  .orderLines,
                                                              logs[index + 1]
                                                                  .Order
                                                                  .orderLines,
                                                              'pricePaid'
                                                          )}
                                                  </DataCell>

                                                  <DataCell>
                                                      {line.inactive ? (
                                                          <Typography
                                                              variant="caption"
                                                              color="primary"
                                                              sx={{
                                                                  cursor: 'pointer'
                                                              }}
                                                              onClick={() =>
                                                                  window.open(
                                                                      `/inventory/viewSale/${line.linkedSaleId}`,
                                                                      '_blank'
                                                                  )
                                                              }
                                                          >
                                                              {
                                                                  line.linkedSaleId
                                                              }
                                                          </Typography>
                                                      ) : null}
                                                  </DataCell>
                                              </TableRow>
                                          );
                                      })
                                    : log.Order?.orderLines.map((line) => (
                                          <TableRow key={line.UnitId}>
                                              <DataCell
                                                  color={
                                                      line.inactive
                                                          ? '#808080'
                                                          : null
                                                  }
                                              >
                                                  {line.partNumber}
                                              </DataCell>
                                              <DataCell
                                                  color={
                                                      line.inactive
                                                          ? '#808080'
                                                          : null
                                                  }
                                              >
                                                  {' '}
                                                  {line.quantity}
                                              </DataCell>
                                              <DataCell
                                                  color={
                                                      line.inactive
                                                          ? '#808080'
                                                          : null
                                                  }
                                              >
                                                  {' '}
                                                  {line.backorderQuantity}
                                              </DataCell>
                                              <DataCell
                                                  color={
                                                      line.inactive
                                                          ? '#808080'
                                                          : null
                                                  }
                                              >
                                                  {' '}
                                                  {CurrencyFormatter(
                                                      line.pricePaid
                                                  )}
                                              </DataCell>
                                              <DataCell>
                                                  {line.inactive ? (
                                                      <Typography
                                                          variant="caption"
                                                          color="primary"
                                                          sx={{
                                                              cursor: 'pointer'
                                                          }}
                                                          onClick={() =>
                                                              window.open(
                                                                  `/inventory/viewSale/${line.linkedSaleId}`,
                                                                  '_blank'
                                                              )
                                                          }
                                                      >
                                                          {line.linkedSaleId}
                                                      </Typography>
                                                  ) : null}
                                              </DataCell>
                                          </TableRow>
                                      ))}
                            </DataTable>
                        </Collapse>
                        <br />
                        {log?.Order?.paymentLines.length > 0 && (
                            <>
                                <Typography variant="body1">
                                    <b>Payments</b>
                                    <IconButton
                                        onClick={() =>
                                            controlCollapse(
                                                log,
                                                index,
                                                'paymentsOpen'
                                            )
                                        }
                                    >
                                        {log.paymentsOpen ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )}
                                    </IconButton>
                                </Typography>
                                <Collapse
                                    in={log.paymentsOpen}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <DataTable columns={paymentColumns}>
                                        {index < logs.length - 1
                                            ? getCombinedLogPaymentlines(
                                                  log.Order.paymentLines,
                                                  logs[index + 1].Order
                                                      .paymentLines
                                              ).map((line) => {
                                                  const isNewlyAdded =
                                                      isNewlyAddedPayment(
                                                          line.timestamp,
                                                          logs[index + 1].Order
                                                              .paymentLines
                                                      );

                                                  const isTypeChanged =
                                                      isPaymentTypeChanged(
                                                          line,
                                                          logs[index + 1].Order
                                                              .paymentLines
                                                      );

                                                  const isRemoved =
                                                      line.removed;

                                                  return (
                                                      <TableRow
                                                          key={line.timestamp}
                                                          sx={{
                                                              backgroundColor:
                                                                  setRowColour(
                                                                      isNewlyAdded,
                                                                      isRemoved
                                                                  )
                                                          }}
                                                      >
                                                          <DataCell
                                                              color={
                                                                  line.inactive &&
                                                                  !isNewlyAdded &&
                                                                  !isRemoved
                                                                      ? '#808080'
                                                                      : null
                                                              }
                                                          >
                                                              <Strikethrough
                                                                  children={
                                                                      isTypeChanged ? (
                                                                          <Chip
                                                                              label={
                                                                                  line.type
                                                                              }
                                                                              size="small"
                                                                              color="primary"
                                                                          />
                                                                      ) : (
                                                                          line.type
                                                                      )
                                                                  }
                                                                  isRemoved={
                                                                      line.removed
                                                                  }
                                                              ></Strikethrough>
                                                          </DataCell>
                                                          <DataCell
                                                              color={
                                                                  line.inactive &&
                                                                  !isNewlyAdded &&
                                                                  !isRemoved
                                                                      ? '#808080'
                                                                      : null
                                                              }
                                                          >
                                                              <Strikethrough
                                                                  children={CurrencyFormatter(
                                                                      line.amount
                                                                  )}
                                                                  isRemoved={
                                                                      line.removed
                                                                  }
                                                              ></Strikethrough>
                                                          </DataCell>
                                                          <DataCell>
                                                              {line.inactive ? (
                                                                  <Typography
                                                                      variant="caption"
                                                                      color="primary"
                                                                      sx={{
                                                                          cursor: 'pointer'
                                                                      }}
                                                                      onClick={() =>
                                                                          window.open(
                                                                              `/inventory/viewSale/${line.linkedSaleId}`,
                                                                              '_blank'
                                                                          )
                                                                      }
                                                                  >
                                                                      {
                                                                          line.linkedSaleId
                                                                      }
                                                                  </Typography>
                                                              ) : null}
                                                          </DataCell>
                                                      </TableRow>
                                                  );
                                              })
                                            : log.Order.paymentLines.map(
                                                  (line) => (
                                                      <TableRow
                                                          key={line.timestamp}
                                                      >
                                                          <DataCell
                                                              color={
                                                                  line.inactive
                                                                      ? '#808080'
                                                                      : null
                                                              }
                                                          >
                                                              {line.type}
                                                          </DataCell>
                                                          <DataCell
                                                              color={
                                                                  line.inactive
                                                                      ? '#808080'
                                                                      : null
                                                              }
                                                          >
                                                              {CurrencyFormatter(
                                                                  line.amount
                                                              )}
                                                          </DataCell>
                                                          <DataCell>
                                                              {line.inactive ? (
                                                                  <Typography
                                                                      variant="caption"
                                                                      color="primary"
                                                                      sx={{
                                                                          cursor: 'pointer'
                                                                      }}
                                                                      onClick={() =>
                                                                          window.open(
                                                                              `/inventory/viewSale/${line.linkedSaleId}`,
                                                                              '_blank'
                                                                          )
                                                                      }
                                                                  >
                                                                      {
                                                                          line.linkedSaleId
                                                                      }
                                                                  </Typography>
                                                              ) : null}
                                                          </DataCell>
                                                      </TableRow>
                                                  )
                                              )}
                                    </DataTable>
                                </Collapse>
                            </>
                        )}
                        <br />
                        <Divider />
                        <br />
                    </Grid>
                ))
            ) : (
                <Typography variant="body1">No logs recorded.</Typography>
            )}
        </Grid>
    );
};

export default LogsDrawerContent;
