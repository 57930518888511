// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { PaymentLine } from '../../../global/interfaces/GeneralInterface';

const HandleDeleteSale = (
    id: number,
    paymentLines: PaymentLine[],
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    // If the sum of payment lines is 0, we can delete the proforma, it's been refunded
    if (paymentLines.length > 0) {
        let totalPayment = 0;
        for (let line of paymentLines) {
            if (!line.removed) {
                totalPayment +=
                    typeof line.amount == 'string'
                        ? parseFloat(line.amount)
                        : line.amount;
            }
        }

        if (totalPayment !== 0) {
            showSnackbar(
                'Unable to delete proforma.',
                'Please remove any remaining payments to delete.',
                'error'
            );
            setButtonLoading(false);
            return;
        }
    }

    api.put(
        `deleteProforma/${id}?SiteId=${localStorage.getItem('SiteId')}`
    ).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Proforma Deleted',
                'You will now be redirected to the sales table.'
            );
            window.location.href = '/inventory/sales';
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact the IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default HandleDeleteSale;
