import { Dispatch, SetStateAction } from 'react';
import { Till } from '../interfaces/GeneralInterface';
import api from '../../../../api';

const GetAllTills = (setTills: Dispatch<SetStateAction<Till[]>>) => {
  api.get(`allTillsNextGen/`).then((res) => {
    setTills(res.data);
  });
};

export default GetAllTills;
