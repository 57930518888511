// LOGIC
import {
    ValidateABN,
    ValidateEmail,
    ValidatePhone,
    ValidatePostcode,
    ValidateStateCountry
} from '../customerValidation/ValidateCustomerFields';
import {
    ValidateAccountNumber,
    ValidateBSB
} from '../settingsValidation/ValidateCompanyFields';
import { AreEmpty, NoDudInfo } from '../globalValidation/ValidateFields';
// INTERFACES
import { Creditor } from '../../interfaces/GeneralInterface';
import { showSnackbar } from '../../interfaces/GlobalInterface';

/**
 * ValidateCreditorFields
 * Validates all data entered into required and extra creditor fields
 * @author Sienna
 * @param creditorDetails the creditor
 * @param responseCode the provided creditor code
 * @param setSnackbar state setter for snackbar
 * @returns true if all fields are valid
 */
const ValidateCreditorFields = (
    creditorDetails: Creditor,
    responseCode: number,
    showSnackbar: showSnackbar
) => {
    let validPhone = { verified: true, message: '', subMessage: '' };
    // Validate phone numbers entered
    creditorDetails.contactDetails.phone.forEach((phone) => {
        if (AreEmpty([phone.reference])) {
            validPhone = {
                verified: false,
                message: 'Invalid phone number.',
                subMessage: 'Phone number reference cannot be left empty.'
            };
        } else if (AreEmpty([phone.value])) {
            validPhone = {
                verified: false,
                message: 'Invalid phone number.',
                subMessage: 'Phone number value cannot be left empty.'
            };
        } else {
            let phoneValidated = ValidatePhone(phone.value);
            validPhone = phoneValidated;
        }
    });

    let validEmail = { verified: true, message: '', subMessage: '' };
    // Validate email addresses entered
    creditorDetails.contactDetails.email.forEach((email) => {
        if (AreEmpty([email.reference])) {
            validEmail = {
                verified: false,
                message: 'Email address reference cannot be left empty.',
                subMessage: ''
            };
        } else if (AreEmpty([email.value])) {
            validEmail = {
                verified: false,
                message: 'Email address value cannot be left empty.',
                subMessage: ''
            };
        } else {
            let emailValidated = ValidateEmail(email.value);
            validEmail = emailValidated;
        }
    });

    // If the response code is 400 it means the code has already been taken and need to decalre a new one
    if (responseCode === 400) {
        showSnackbar(
            'The entered code has already been assigned, please enter a different code.',
            '',
            'error'
        );
        return false;
    }

    // If there is no code entered or the value is a dud
    if (
        AreEmpty([creditorDetails.code]) ||
        !NoDudInfo([creditorDetails.code]) ||
        creditorDetails.code.length !== 3
    ) {
        showSnackbar(
            'You must provide a code that is 3 characters long.',
            '',
            'error'
        );
        return false;
    }

    // If there is no name entered or the value is a dud
    if (
        AreEmpty([creditorDetails.name]) ||
        !NoDudInfo([creditorDetails.name])
    ) {
        showSnackbar(
            'You must provide a name that is more than 1 character.',
            '',
            'error'
        );
        return false;
    }

    // If an abn has been provided check it is a valid abn
    if (creditorDetails.abn) {
        let abnValidated = ValidateABN(creditorDetails.abn);
        if (abnValidated.verified === false) {
            showSnackbar(abnValidated.subMessage, '', 'error');
            return false;
        }
    }

    // If a representative has been provided check that it is valid
    if (
        creditorDetails.representative &&
        !NoDudInfo([creditorDetails.representative])
    ) {
        showSnackbar(
            'If entering a representative, you must enter more than 1 character.',
            '',
            'error'
        );
        return false;
    }

    // If a street address has been provided check that it is valid
    if (
        creditorDetails.addressLine1 &&
        !NoDudInfo([creditorDetails.addressLine1])
    ) {
        showSnackbar(
            'If entering a street address, you must enter more than 1 character.',
            '',
            'error'
        );
        return false;
    }

    // If a suburb  has been provided check that it is valid
    if (creditorDetails.suburb && !NoDudInfo([creditorDetails.suburb])) {
        showSnackbar(
            'If entering a suburb, you must enter more than 1 character.',
            '',
            'error'
        );
        return false;
    }

    // If a postcode has been provided check it is a valid postcode
    if (creditorDetails.postcode) {
        let postcodeValidated = ValidatePostcode(creditorDetails.postcode);
        if (postcodeValidated.verified === false) {
            showSnackbar(postcodeValidated.subMessage, '', 'error');
            return false;
        }
    }

    // If a state has been provided check it is a valid state
    if (creditorDetails.state) {
        let stateValidated = ValidateStateCountry(creditorDetails.state);
        if (stateValidated.verified === false) {
            showSnackbar(stateValidated.subMessage, '', 'error');
            return false;
        }
    }

    // If a country has been provided check it is a valid country
    if (creditorDetails.country) {
        let countryValidated = ValidateStateCountry(creditorDetails.country);
        if (countryValidated.verified === false) {
            showSnackbar(countryValidated.subMessage, '', 'error');
            return false;
        }
    }

    // If a default payment reference has been provided check that it is valid
    if (
        creditorDetails?.paymentDetails?.defaultReference &&
        !NoDudInfo([creditorDetails?.paymentDetails?.defaultReference])
    ) {
        showSnackbar(
            'If entering a default payment reference, you must enter more than 1 character.',
            '',
            'error'
        );
        return false;
    }

    // If an account name has been provided check that it is valid
    if (
        creditorDetails?.paymentDetails?.accountName &&
        !NoDudInfo([creditorDetails?.paymentDetails?.accountName])
    ) {
        showSnackbar(
            'If entering an account name, you must enter more than 1 character.',
            '',
            'error'
        );
        return false;
    }

    // If a bsb has been provided check that it is valid
    if (creditorDetails?.paymentDetails?.bsb) {
        let bsbValidated = ValidateBSB(creditorDetails.paymentDetails.bsb);
        if (bsbValidated.verified === false) {
            showSnackbar(
                bsbValidated.message,
                bsbValidated.subMessage,
                'error'
            );
            return false;
        }
    }

    // If an account number has been provided check that it is valid
    if (creditorDetails?.paymentDetails?.accountNumber) {
        let accountNumberValidated = ValidateAccountNumber(
            creditorDetails.paymentDetails.accountNumber
        );
        if (accountNumberValidated.verified === false) {
            showSnackbar(
                accountNumberValidated.message,
                accountNumberValidated.subMessage,
                'error'
            );
            return false;
        }
    }

    // If there is an invalid phone number
    if (validPhone.verified === false) {
        showSnackbar(validPhone.message, validPhone.subMessage, 'error');
        return false;
    }

    // If there is an invalid email address
    if (validEmail.verified === false) {
        showSnackbar(validEmail.message, validEmail.subMessage, 'error');
        return false;
    }

    return true;
};

export default ValidateCreditorFields;
