import { TableRow, Typography } from '@mui/material';
import { StockOrder } from '../../../../global/interfaces/PartsInterface';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';

interface StockOrderDrawerContentProps {
  selectedStockOrder: StockOrder;
}

const StockOrderDrawerContent = ({
  selectedStockOrder
}: StockOrderDrawerContentProps) => {
  const columns = [
    { id: 0, label: 'Part Number' },
    { id: 1, label: 'Quantity' }
  ];
  return (
    <>
      <Typography variant="body1">
        <b>Supplier: </b>
        {selectedStockOrder?.Supplier?.name}
      </Typography>
      <Typography variant="body1">
        <b>Order Type: </b>
        {selectedStockOrder?.orderType.toUpperCase()}
      </Typography>
      <Typography variant="body1">
        <b>Order Reference: </b>
        {selectedStockOrder?.orderReference}
      </Typography>
      <Typography variant="body1">
        <b>Order Total: </b>
        {CurrencyFormatter(selectedStockOrder?.totalCost)}
      </Typography>
      {selectedStockOrder?.invoiced ? (
        <Typography variant="body1" color="green">
          <b>INVOICED</b>
        </Typography>
      ) : null}
      <br />
      <DataTable columns={columns}>
        {selectedStockOrder.orderLines.map((row) => (
          <TableRow>
            <DataCell>{row.partNumber}</DataCell>
            <DataCell>{row.quantity}</DataCell>
          </TableRow>
        ))}
      </DataTable>
    </>
  );
};

export default StockOrderDrawerContent;
