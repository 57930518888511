// function to render date on canvas
interface RenderDateProps {
    canvas: HTMLCanvasElement;
    date: string;
    left: number;
    top: number;
    scalingRatio: number;
}

export const RenderDate = ({
    canvas,
    date,
    left,
    top,
    scalingRatio
}: RenderDateProps) => {
    const context = canvas.getContext('2d');
    context.font = `${70 / scalingRatio}px Arial`;
    // context.fillStyle = 'black';
    const x = context.canvas.width * left;
    const y = context.canvas.height * top + 140 / scalingRatio;
    context.fillText(date, x, y);
};

// Function to load image and draw it on canvas
interface LoadImageProps {
    canvas: HTMLCanvasElement;
    sigDataUrl: string;
    left: number;
    top: number;
    scalingRatio: number;
}
export const LoadImage = ({
    canvas,
    sigDataUrl,
    left,
    top,
    scalingRatio
}: LoadImageProps): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!canvas || !sigDataUrl) {
            reject('Canvas or Image URL is not valid.');
            return;
        }

        const img = new Image();
        img.src = sigDataUrl;
        img.onload = () => {
            const context = canvas.getContext('2d');
            let imageWidth = 600 / scalingRatio;
            let imageHeight = 200 / scalingRatio;

            const x = context.canvas.width * left;
            const y = context.canvas.height * top - imageHeight / 2;
            context.drawImage(img, x, y, imageWidth, imageHeight);
            resolve();
        };
        img.onerror = () => reject('Error loading image');
    });
};
