import { Grid, TableRow } from '@mui/material';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { useState } from 'react';
import {
    Document,
    DOCUMENT_STATUS,
    EsignTableColumn,
    Signer,
    Tag
} from '../EsignInterface';

// COMPONENTS
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import { DateFormatter } from '../../global/logic/Formatters';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import TableSearch from '../../global/tableComponents/TableSearch';

interface FinalisedSignTabContentProps {
    showSnackbar: showSnackbar;
}

const clearedSort = ['id', 'DESC'];

const FinalisedSignTabContent = ({
    showSnackbar
}: FinalisedSignTabContentProps) => {
    const [resultsList, setResultsList] = useState<Document[]>([]);

    // Columns for the data table
    const columns: EsignTableColumn[] = [
        { id: 0, label: 'ID', name: 'id', width: 100 },
        { id: 1, label: 'Signer', width: 400 },
        { id: 3, label: 'Document', width: 300 },
        { id: 4, label: 'Signed at', width: 200 }
    ];

    const handleRequestCreate = () => {
        let apiString = `&status=${DOCUMENT_STATUS.SIGNED}&checkEmpty=true`;
        return apiString;
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TableSearch
                    searchTitle="Search by id, signer name, email or document name"
                    showFilter={false}
                    showPagination={true}
                    showPaper={true}
                    sort={clearedSort}
                    handleRequestCreate={handleRequestCreate}
                    route="esignDocumentSearch"
                    setResultsList={setResultsList}
                ></TableSearch>

                <Paper>
                    <DataTable columns={columns}>
                        {resultsList.map((row) => {
                            const uniqueSigners = row.Tags.reduce(
                                (acc: Signer[], tag: Tag) => {
                                    if (
                                        !acc.some(
                                            (signer: Signer) =>
                                                signer.externalId ===
                                                tag.SignerId
                                        )
                                    ) {
                                        acc.push(tag.Signer);
                                    }
                                    return acc;
                                },
                                []
                            );

                            return (
                                <TableRow key={row.id}>
                                    <DataCell>{row.id}</DataCell>
                                    <DataCell>
                                        {uniqueSigners.map((signer: Signer) => (
                                            <div key={signer.externalId}>
                                                {signer.fullName}:{' '}
                                                {signer.email}
                                            </div>
                                        ))}
                                    </DataCell>

                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(row.signedUrl, '_blank')
                                        }
                                    >
                                        {row.fileName}
                                    </DataCellColoured>
                                    <DataCell>
                                        {DateFormatter(row.signedAt)}
                                    </DataCell>
                                </TableRow>
                            );
                        })}
                    </DataTable>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default FinalisedSignTabContent;
