// IMPORTS
import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { Add, Compress, Delete, Edit } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import TableSearch from '../../../global/tableComponents/TableSearch';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import SpeedDial from '../../../global/SpeedDial';
import AddMakeDialogContent from './MakeComponent/AddMakeDialogContent';
import MergeMakeDialogContent from './MakeComponent/MergeMakeDialogContent';
import Drawer from '../../../global/Drawer';
import ModelForMakeDrawerContent from './MakeComponent/ModelForMakeDrawerContent';
import MakeDrawerContent from './MakeComponent/MakeDrawerContent';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Paper from '../../../global/Paper';
// LOGIC
import HandleMakeNbVehicleClick from '../logic/HandleMakeNbVehicleClick';
import HandleMakeNbModelClick from '../logic/HandleMakeNbModelClick';
import EditMakeDialogContent from './MakeComponent/EditMakeDialogContent';
import DeleteMake from '../logic/DeleteMake';
// INTERFACES
import {
    Make,
    Model,
    Series,
    Vehicle
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface TempMake extends Make {
    nbOfModel: number;
    nbOfVehicle: number;
}

interface TempModel extends Model {
    Series: Series[];
}

const clearedSort = ['name', 'ASC'];

const MakeTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [sort, setSort] = useState(clearedSort);
    const [makeList, setMakeList] = useState<TempMake[]>([]);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedMake, setSelectedMake] = useState<TempMake>();
    const [listOfVehicles, setListOfVehicles] = useState<Vehicle[]>([]);
    const [filterListOfVehicles, setFilterListOfVehicles] = useState<Vehicle[]>(
        []
    );
    const [filterValue, setFilterValue] = useState<string>('');
    const [openModelDialog, setOpenModelDialog] = useState<boolean>(false);
    const [listOfModels, setListOfModels] = useState<TempModel[]>([]);
    const [filterListOfModels, setFilterListOfModels] = useState<TempModel[]>(
        []
    );
    const [page, setPage] = useState<number>(1);
    const [openMergeDialog, setOpenMergeDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [addMakeDialog, setAddMakeDialog] = useState<boolean>(false);

    const columns = [
        { id: 0, label: 'Make', width: 250 },
        { id: 1, label: 'Models Available', width: 250 },
        { id: 2, label: 'Vehicles Available', width: 250 },
        { id: 3, label: '', width: 10 },
        { id: 4, label: '', width: 10 }
    ];

    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Make',
            onClick: () => setAddMakeDialog(true)
        },
        {
            icon: <Compress />,
            name: 'Merge Make',
            onClick: () => setOpenMergeDialog(true)
        }
    ];

    const handleRequestCreate = () => {
        let apiString = ``;
        return apiString;
    };

    const handleFilterValueChange = (value: string, type: string) => {
        setFilterValue(value.toUpperCase());
        setPage(1);
        if (type === 'vehicle') {
            setFilterListOfVehicles(
                listOfVehicles.filter(
                    (x) =>
                        x.stockNumber.includes(value.toUpperCase()) ||
                        x.Model.name.includes(value.toUpperCase())
                )
            );
        } else if (type === 'model') {
            setFilterListOfModels(
                listOfModels.filter((x) => x.name.includes(value.toUpperCase()))
            );
        }
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Make"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="makeSpecificTable"
                setResultsList={setMakeList}
            />

            <Paper>
                <DataTable columns={columns} sort={sort} setSort={setSort}>
                    {makeList.map((make) => (
                        <TableRow>
                            <DataCell>{make.name}</DataCell>
                            <DataCellColoured
                                handleClick={() => {
                                    HandleMakeNbModelClick(
                                        make,
                                        setSelectedMake,
                                        setOpenModelDialog,
                                        setListOfModels,
                                        setFilterListOfModels,
                                        setFilterValue
                                    );
                                }}
                            >
                                {make.nbOfModel}
                            </DataCellColoured>
                            <DataCellColoured
                                handleClick={() => {
                                    HandleMakeNbVehicleClick(
                                        make,
                                        setSelectedMake,
                                        setOpenDialog,
                                        setListOfVehicles,
                                        setFilterListOfVehicles,
                                        setFilterValue
                                    );
                                }}
                            >
                                {make.nbOfVehicle}
                            </DataCellColoured>
                            <DataCell align="right">
                                <Tooltip title="Edit Make Name" placement="top">
                                    <IconButton
                                        onClick={() => {
                                            setOpenEditDialog(true);
                                            setSelectedMake(make);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </DataCell>
                            <DataCell align="left">
                                {make.nbOfVehicle === 0 ? (
                                    <Tooltip
                                        title="Delete Make"
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setOpenDeleteDialog(true);
                                                setSelectedMake(make);
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={openDialog}
                setOpenDrawer={setOpenDialog}
                title={selectedMake?.name + ' Vehicles'}
                subTitle=""
                width="40vw"
            >
                <MakeDrawerContent
                    filterValue={filterValue}
                    filterListOfVehicles={filterListOfVehicles}
                    page={page}
                    setPage={setPage}
                    handleFilterValueChange={handleFilterValueChange}
                />
            </Drawer>

            <Drawer
                openDrawer={openModelDialog}
                setOpenDrawer={setOpenModelDialog}
                title={'Model for ' + selectedMake?.name}
                subTitle=""
                width="40vw"
            >
                <ModelForMakeDrawerContent
                    filterValue={filterValue}
                    filterListOfModels={filterListOfModels}
                    page={page}
                    setPage={setPage}
                    handleFilterValueChange={handleFilterValueChange}
                />
            </Drawer>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={addMakeDialog}
                onClose={() => {
                    setAddMakeDialog(false);
                }}
            >
                <DialogTitle>Add New Make</DialogTitle>
                <AddMakeDialogContent showSnackbar={showSnackbar} />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={openMergeDialog}
                onClose={() => {
                    setOpenMergeDialog(false);
                }}
            >
                <DialogTitle>Merge Makes</DialogTitle>
                <MergeMakeDialogContent showSnackbar={showSnackbar} />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={openEditDialog}
                onClose={() => {
                    setOpenEditDialog(false);
                }}
            >
                <DialogTitle>Edit Make Name</DialogTitle>
                <EditMakeDialogContent
                    selectedMake={selectedMake}
                    setSelectedMake={setSelectedMake}
                    showSnackbar={showSnackbar}
                />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
            >
                <DialogTitle>Delete {selectedMake?.name}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to delete the make{' '}
                        {selectedMake?.name}? This will also remove any models
                        or series assigned to it.
                        <br />
                        <b>This cannot be undone.</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            DeleteMake(
                                selectedMake,
                                showSnackbar,
                                setButtonLoading
                            )
                        }
                    >
                        Delete Make
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <SpeedDial actionsList={actionsList} />
        </>
    );
};
export default withSnackbar(MakeTable);
