// LOGIC
import api from '../../../../api';

const GetAllIPWhitelist = (setIPWhitelistData) => {
    api.get('allIPWhitelist').then(res => {
        let ipData = [...res.data]

        for (let line of ipData) {
            line.mask = line.mask.replace(' ', '')
            line.readOnly = true
        }
        setIPWhitelistData(ipData)
    })
}

export default GetAllIPWhitelist