import api from '../../../../../api';
import { DailyTask } from '../../../global/interfaces/GeneralInterface';

interface HandleSaveTaskProps {
    taskData: DailyTask;
}

const handleSaveTask = async (props: HandleSaveTaskProps) => {
    const { taskData } = props;

    try {
        const response = taskData.id
            ? await api.put('/updateDailyTask', taskData)
            : await api.post('/addDailyTask', taskData);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Task was not saved.');
        }
    } catch (error) {
        console.log(error);
    }
};

export default handleSaveTask;
