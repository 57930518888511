// IMPORTS
import { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    TableRow,
    IconButton,
    Tooltip,
    Button
} from '@mui/material';
import { Print } from '@mui/icons-material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import TableSearch from '../../global/tableComponents/TableSearch';
import DataCell from '../../global/tableComponents/DataCell';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataTable from '../../global/tableComponents/DataTable';
// LOGIC
import PDFStocktakeReport from './logic/PDFStocktakeReport';
import {
    DateFormatter,
    TitleCaseFormatter
} from '../../global/logic/Formatters';
// INTERFACES
import { Stocktake } from '../../global/interfaces/PartsInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedSort = ['name', 'ASC'];

interface SelectedBinLocation {
    id: number;
    name: string;
    stocktakeDate: string;
    rows: Stocktake[];
}

const StocktakeTable = () => {
    const [resultsList, setResultsList] = useState([]);
    const [selectedBinLocation, setSelectedBinLocation] =
        useState<SelectedBinLocation>({
            id: null,
            name: null,
            stocktakeDate: null,
            rows: []
        });

    const binLocationColumns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Name' },
        { id: 2, label: 'Stocktakes' }
    ];

    const stocktakeColumns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Date Completed' },
        { id: 2, label: 'Completed By' },
        { id: 3, label: 'Parts Counted' },
        { id: 4, label: 'Notes' },
        { id: 5, label: '' }
    ];

    // When the results list is populated, set the selected bin location to the first one by default
    useEffect(() => {
        if (resultsList.length > 0) {
            setSelectedBinLocation(resultsList[0]);
        }
    }, [resultsList]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let apiString = `&SiteId=${localStorage.getItem('SiteId')}`;
        return apiString;
    };

    return (
        <>
            <PageWrapper>
                <Typography variant="h4">Stocktake</Typography>
                <br />

                <TableSearch
                    searchTitle="Search Bin Location"
                    showFilter={false}
                    showPagination={true}
                    showPaper={true}
                    sort={clearedSort}
                    handleRequestCreate={handleRequestCreate}
                    route="binLocationStocktakeSearchNextGen"
                    setResultsList={setResultsList}
                ></TableSearch>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Paper>
                            <DataTable columns={binLocationColumns}>
                                {resultsList.map((result) => (
                                    <TableRow
                                        selected={
                                            result.id === selectedBinLocation.id
                                        }
                                    >
                                        <DataCellColoured
                                            handleClick={() =>
                                                setSelectedBinLocation(result)
                                            }
                                        >
                                            {result.id}
                                        </DataCellColoured>
                                        <DataCell>
                                            {TitleCaseFormatter(result.name)}
                                        </DataCell>
                                        <DataCell>
                                            {result.rows.length}
                                        </DataCell>
                                    </TableRow>
                                ))}
                            </DataTable>
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper>
                            <Grid container spacing={2}>
                                <Grid item xs={8} alignItems="top">
                                    <Typography variant="h5" align="left">
                                        {selectedBinLocation.name}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        align="left"
                                    >
                                        {selectedBinLocation.rows.length > 0 ? (
                                            <b>
                                                Last Stocktaked:{' '}
                                                {DateFormatter(
                                                    selectedBinLocation.rows[0]
                                                        .createdAt
                                                )}
                                            </b>
                                        ) : (
                                            <b>
                                                No stocktakes completed for this
                                                bin location
                                            </b>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align="right">
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                (window.location.href = `/inventory/newstocktake/${selectedBinLocation.id}`)
                                            }
                                        >
                                            New Stocktake
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                            {selectedBinLocation.rows.length > 0 ? (
                                <DataTable columns={stocktakeColumns}>
                                    {selectedBinLocation?.rows?.map(
                                        (stocktake) => (
                                            <TableRow>
                                                <DataCell>
                                                    {stocktake.id}
                                                </DataCell>
                                                <DataCell>
                                                    {DateFormatter(
                                                        stocktake.createdAt
                                                    )}
                                                </DataCell>
                                                <DataCell>
                                                    {stocktake?.User?.firstName
                                                        ? TitleCaseFormatter(
                                                              stocktake?.User
                                                                  ?.firstName
                                                          )
                                                        : null}
                                                    {stocktake?.User?.firstName
                                                        ? TitleCaseFormatter(
                                                              stocktake?.User
                                                                  ?.lastName
                                                          )
                                                        : null}
                                                </DataCell>
                                                <DataCell>
                                                    {
                                                        stocktake.stocktakeLines
                                                            .length
                                                    }
                                                </DataCell>
                                                <DataCell>
                                                    {stocktake.notes}
                                                </DataCell>
                                                <DataCell>
                                                    <Tooltip
                                                        title="Print Summary"
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            onClick={() =>
                                                                PDFStocktakeReport(
                                                                    stocktake
                                                                )
                                                            }
                                                        >
                                                            <Print />
                                                        </IconButton>
                                                    </Tooltip>
                                                </DataCell>
                                            </TableRow>
                                        )
                                    )}
                                </DataTable>
                            ) : null}
                        </Paper>
                    </Grid>
                </Grid>
            </PageWrapper>
        </>
    );
};

export default StocktakeTable;
