// IMPORTS
import { TableRow, Typography } from '@mui/material';
import moment from 'moment';
// COMPONENTS
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../../global/tableComponents/DataCell';
// LOGIC
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';

interface PayrunContentProps {
  selectedObject;
}

const PayrunContent = ({ selectedObject }: PayrunContentProps) => {
  const columns = [
    { id: 0, label: 'Invoice ID' },
    { id: 1, label: 'Document Reference' },
    { id: 2, label: 'Creditor' },
    { id: 3, label: 'Amount Paid' }
  ];

  return (
    <>
      <Typography variant="body1">
        <b>Amount:</b> {CurrencyFormatter(selectedObject.amount)}
      </Typography>
      <Typography variant="body1">
        <b>Date Paid:</b> {selectedObject.date}{' '}
        {moment(selectedObject.timestamp).format('h:MM A')}
      </Typography>
      <Typography variant="body1">
        <b>Submitted by:</b> {selectedObject.submittedBy}
      </Typography>
      <br />

      <DataTable columns={columns}>
        {selectedObject.invoices.map((line) => (
          <TableRow>
            <DataCellColoured
              handleClick={() =>
                window.open(`/inventory/viewInvoice/${line.id}`, '_blank')
              }
            >
              {line.id}
            </DataCellColoured>
            <DataCell>
              {line.StockInvoiceId
                ? line?.StockDocumentReference
                : line?.CreditDocumentReference}
            </DataCell>
            <DataCell>{line?.SupplierName}</DataCell>
            <DataCell>{CurrencyFormatter(line.paymentAmount)}</DataCell>
          </TableRow>
        ))}
      </DataTable>
    </>
  );
};

export default PayrunContent;
