// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import { Chip, IconButton, TableRow, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
import Drawer from '../../../../global/Drawer';
import PurchaseOrderDrawerContent from '../../../purchaseOrders/components/PurchaseOrderDrawerContent';
// LOGIC
import {
    DateFormatter,
    CurrencyFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import {
    PurchaseOrder,
    Service
} from '../../../../global/interfaces/ServiceInterface';
interface SubletsProps {
    id: number;
    readOnly: boolean;
    serviceDetails: Service;
    setServiceDetails: Dispatch<SetStateAction<Service>>;
    sublets: PurchaseOrder[];
    setSublets: Dispatch<SetStateAction<PurchaseOrder[]>>;
}
const Sublets = ({
    id,
    readOnly,
    serviceDetails,
    sublets,
    setSublets,
    setServiceDetails
}: SubletsProps) => {
    const [purchaseOrderDrawerOpen, setPurchaseOrderDrawerOpen] =
        useState<boolean>(false);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
        useState<PurchaseOrder>();

    const subletColumns = [
        { id: 0, label: 'ID', width: 50 },
        { id: 2, label: 'Creditor', width: 100 },
        { id: 3, label: 'Order Reference', width: 200 },
        { id: 4, label: 'Amount', width: 150 },
        { id: 5, label: 'Date Created', width: 100 },
        { id: 7, label: 'User', width: 100 },
        { id: 8, label: 'Status', width: 100 }
    ];

    const handleAddSublet = () => {
        setSelectedPurchaseOrder({
            ServiceId: id,
            Service: serviceDetails,
            includingGST: false
        });
        setPurchaseOrderDrawerOpen(true);
    };

    return (
        <>
            {id ? (
                <>
                    <Paper>
                        <Typography variant="h6">
                            Sublets
                            {!readOnly &&
                                serviceDetails.serviceComplete !==
                                    'Finalised' && (
                                    <IconButton
                                        onClick={() => handleAddSublet()}
                                    >
                                        <Add />
                                    </IconButton>
                                )}
                        </Typography>
                        <DataTable columns={subletColumns}>
                            {sublets.map((sublet) => (
                                <TableRow>
                                    {sublet.InvoiceId && sublet.Invoice ? (
                                        <DataCellColoured
                                            handleClick={() =>
                                                window.open(
                                                    `/inventory/viewInvoice/${sublet.InvoiceId}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {sublet.InvoiceId}
                                        </DataCellColoured>
                                    ) : (
                                        <DataCellColoured
                                            handleClick={() => {
                                                setSelectedPurchaseOrder(
                                                    sublet
                                                );
                                                setPurchaseOrderDrawerOpen(
                                                    true
                                                );
                                            }}
                                        >
                                            {sublet.id}
                                        </DataCellColoured>
                                    )}
                                    <DataCell>{sublet.Supplier?.name}</DataCell>
                                    <DataCell>{sublet.orderReference}</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(sublet.amount) +
                                            ` ${serviceDetails.jobType === 'Internal' ? '(EX. GST)' : sublet.includingGST ? '(INC. GST)' : '(EX. GST)'}`}
                                    </DataCell>
                                    <DataCell>
                                        {DateFormatter(sublet.createdAt)}
                                    </DataCell>
                                    <DataCell>
                                        {sublet.User?.firstName}{' '}
                                        {sublet.User?.lastName}
                                    </DataCell>
                                    <DataCell>
                                        <Chip
                                            variant="outlined"
                                            label={
                                                sublet.InvoiceId &&
                                                sublet.Invoice
                                                    ? 'Invoiced'
                                                    : 'Pending'
                                            }
                                            color={
                                                sublet.InvoiceId &&
                                                sublet.Invoice
                                                    ? 'success'
                                                    : 'warning'
                                            }
                                        />
                                    </DataCell>
                                </TableRow>
                            ))}
                        </DataTable>
                    </Paper>
                    <br />
                </>
            ) : null}

            <Drawer
                openDrawer={purchaseOrderDrawerOpen}
                setOpenDrawer={setPurchaseOrderDrawerOpen}
                title={
                    selectedPurchaseOrder?.id
                        ? 'Purchase Order #' + selectedPurchaseOrder?.id
                        : 'New Purchase Order'
                }
                subTitle={
                    selectedPurchaseOrder?.id
                        ? 'Service #' + selectedPurchaseOrder?.ServiceId
                        : ''
                }
            >
                <PurchaseOrderDrawerContent
                    selectedPurchaseOrder={selectedPurchaseOrder}
                    setSelectedPurchaseOrder={setSelectedPurchaseOrder}
                    insideService={true}
                    purchaseOrders={sublets}
                    setPurchaseOrders={setSublets}
                    setDrawerOpen={setPurchaseOrderDrawerOpen}
                    serviceDetails={serviceDetails}
                    setServiceDetails={setServiceDetails}
                />
            </Drawer>
        </>
    );
};

export default Sublets;
