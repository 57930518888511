import { useParams } from 'react-router-dom';

import { Checkbox, Typography } from '@mui/material';
import PageWrapper from '../../global/PageWrapper';
import Tabs from '../../global/Tabs';

import DealerSignBankDetails from './components/dealerTabContents/DealerSignBankDetails';
import DealerSignVendorStatement from './components/dealerTabContents/DealerSignVendorStatement';
import DealerSignConditionDeclaration from './components/dealerTabContents/DealerSignConditionDeclaration';
import DealerSignPersonalisedPlate from './components/dealerTabContents/DealerSignPersonalisedPlate';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import EmailCustomerDialog from './components/EmailCustomerDialog';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';
import { PDFDocument } from 'pdf-lib';
import {
    GetFileFromS3,
    GetFileUrlsFromS3Folder
} from '../../esign/logic/S3FileService';
import {
    DEFAULT_PERSONALISED_PLATE_FORM_VALUES,
    DEFAULT_VENDOR_STATEMENT_FORM_VALUES
} from '../../esign/DefaultFormValues';
import api from '../../../../api';
import DealerDocumentStatusHeader from '../../esign/components/DocumentStatusHeader';
import getVendorStatementInfo from './logic/getVendorStatementInfo';

const DealerTradeDocManager = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const { id: vehicleId } = useParams<{ id: string }>();

    // when click 'Email Customer' button, open a dialog to confirm sending email to the customer
    const [openEmailDialog, setOpenEmailDialog] = useState(false);

    const [customer, setCustomer] = useState(null);
    const [contractStatus, setContractStatus] = useState(null);

    const [filesToUpload, setFilesToUpload] = useState<
        { fileName: string; pdfBytes: Uint8Array }[]
    >([]);

    // Vendor Statement
    const [vendorStatementPdfDoc, setVendorStatementPdfDoc] =
        useState<PDFDocument>(null);
    const [vendorStatementPdfUrl, setVendorStatementPdfUrl] =
        useState<string>(null);
    const [vendorStatementBytes, setVendorStatementBytes] = useState(null);
    const [vendorStatementFormData, setVendorStatementFormData] = useState(
        DEFAULT_VENDOR_STATEMENT_FORM_VALUES
    );

    // Personalised Plate
    const [personalisedPlatePdfDoc, setPersonalisedPlatePdfDoc] =
        useState<PDFDocument>(null);
    const [personalisedPlatePdfUrl, setPersonalisedPlatePdfUrl] =
        useState<string>(null);
    const [personalisedPlateBytes, setPersonalisedPlateBytes] = useState(null);
    const [personalisedPlateFormData, setPersonalisedPlateFormData] = useState(
        DEFAULT_PERSONALISED_PLATE_FORM_VALUES
    );

    // bank details
    const [bankPdfUrl, setBankPdfUrl] = useState('');
    const [bankDetailsBytes, setBankDetailsBytes] = useState(null);

    // condition declaration
    const [conditionPdfUrl, setConditionPdfUrl] = useState('');
    const [conditionDeclarationBytes, setConditionDeclarationBytes] =
        useState(null);

    const LoadFilesFromS3Folder = async () => {
        const fileUrls = await GetFileUrlsFromS3Folder(
            `vehicleTrades/${vehicleId}`,
            showSnackbar
        );

        for (let file of fileUrls) {
            switch (file.key) {
                case 'VendorStatement.pdf':
                    setVendorStatementPdfUrl(file.url);
                    break;
                case 'PersonalisedPlate.pdf':
                    setPersonalisedPlatePdfUrl(file.url);
                    break;
                case 'BankDetails.pdf':
                    setBankPdfUrl(file.url);
                    break;
                case 'ConditionDeclaration.pdf':
                    setConditionPdfUrl(file.url);
                    break;
                default:
                    break;
            }
        }
    };

    const LoadVendorStatementPDF = () => {
        GetFileFromS3({
            folderName: `vehicleTrades/${vehicleId}`,
            fileName: 'VendorStatement',
            setPdfDoc: setVendorStatementPdfDoc,
            setPdfUrl: setVendorStatementPdfUrl,
            showSnackbar: showSnackbar,
            setVendorStatementBytes: setVendorStatementBytes
        });
    };

    const getPrefilledPersonalisedPlatePdf = async () => {
        // Fetch the existing PDF
        try {
            const response = await api.get(
                `/prefilledDocument/personalisedPlate/${vehicleId}`,
                {
                    responseType: 'arraybuffer'
                }
            );

            const arrayBuffer = response.data;
            const pdfDoc = await PDFDocument.load(arrayBuffer);
            setPersonalisedPlatePdfDoc(pdfDoc);

            const pdfBytes = await pdfDoc.save();
            setPersonalisedPlateBytes(pdfBytes);

            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

            const newPdfUrl = URL.createObjectURL(blob);
            setPersonalisedPlatePdfUrl(newPdfUrl);
        } catch (error) {
            console.error('Failed to fetch PDF: ', error);
        }
    };

    const getPrefilledBankPdf = async () => {
        try {
            const response = await api.get(
                `/prefilledDocument/bankDetails/${vehicleId}`,
                {
                    responseType: 'arraybuffer'
                }
            );

            const arrayBuffer = response.data;

            const pdfBytes = new Uint8Array(arrayBuffer);
            setBankDetailsBytes(pdfBytes);

            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            const newPdfUrl = URL.createObjectURL(blob);
            setBankPdfUrl(newPdfUrl);
        } catch (error) {
            console.error('Failed to fetch PDF: ', error);
        }
    };

    const getPrefilledConditionPdf = async () => {
        // Fetch the existing PDF
        try {
            const response = await api.get(
                `/prefilledDocument/conditionDeclaration/${vehicleId}`,
                {
                    responseType: 'arraybuffer'
                }
            );

            const arrayBuffer = response.data;
            const pdfBytes = new Uint8Array(arrayBuffer);
            setConditionDeclarationBytes(pdfBytes);

            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            const newPdfUrl = URL.createObjectURL(blob);
            setConditionPdfUrl(newPdfUrl);
        } catch (error) {
            console.error('Failed to fetch PDF: ', error);
        }
    };

    const getContractInfo = async () => {
        const result = await getVendorStatementInfo(vehicleId, showSnackbar);

        setCustomer(result.customerInfo);
        setContractStatus(result.statementStatus);

        // If the contract is already signed or waiting for signature, load the existing PDF from S3 bucket
        // Otherwise, fetch the original PDF template with the form fields prefilled
        if (
            result.statementStatus === 'vendorSigned' ||
            result.statementStatus === 'waitingSignature'
        ) {
            await LoadFilesFromS3Folder();
        } else {
            LoadVendorStatementPDF();
            await getPrefilledPersonalisedPlatePdf();
            await getPrefilledBankPdf();
            await getPrefilledConditionPdf();
        }
    };

    useEffect(() => {
        getContractInfo();
        // eslint-disable-next-line
    }, [vehicleId]);

    const handleSelectFileToUpload = (fileName, isChecked, pdfBytes) => {
        if (isChecked) {
            // Add to the upload list
            setFilesToUpload((prev) => [
                ...prev,
                {
                    fileName,
                    pdfBytes
                }
            ]);
        } else {
            // Remove from the upload list
            setFilesToUpload((prev) =>
                prev.filter((item) => item.fileName !== fileName)
            );
        }
    };

    // when pdf bytes change, update pdfBytes in the filesToUpload list, so that we can upload the latest pdf to S3
    useEffect(() => {
        setFilesToUpload((prev) =>
            prev.map((item) => {
                switch (item.fileName) {
                    case 'VendorStatement':
                        if (vendorStatementBytes) {
                            return { ...item, pdfBytes: vendorStatementBytes };
                        }
                        break;
                    case 'PersonalisedPlate':
                        if (personalisedPlateBytes) {
                            return {
                                ...item,
                                pdfBytes: personalisedPlateBytes
                            };
                        }
                        break;
                    case 'BankDetail':
                        if (bankDetailsBytes) {
                            return { ...item, pdfBytes: bankDetailsBytes };
                        }
                        break;
                    case 'ConditionDeclaration':
                        if (conditionDeclarationBytes) {
                            return {
                                ...item,
                                pdfBytes: conditionDeclarationBytes
                            };
                        }
                        break;
                    default:
                        return item;
                }
                return item;
            })
        );
    }, [
        vendorStatementBytes,
        personalisedPlateBytes,
        bankDetailsBytes,
        conditionDeclarationBytes
    ]);

    const tabContent = [
        {
            id: 0,
            title: (
                <>
                    {!contractStatus && (
                        <Checkbox
                            onChange={(e) =>
                                handleSelectFileToUpload(
                                    'VendorStatement',
                                    e.target.checked,
                                    vendorStatementBytes
                                )
                            }
                        />
                    )}

                    <Typography> Vendor Statement</Typography>
                </>
            ),
            content: (
                <DealerSignVendorStatement
                    formData={vendorStatementFormData}
                    setFormData={setVendorStatementFormData}
                    pdfDoc={vendorStatementPdfDoc}
                    pdfUrl={vendorStatementPdfUrl}
                    setPdfUrl={setVendorStatementPdfUrl}
                    contractStatus={contractStatus}
                    setVendorStatementBytes={setVendorStatementBytes}
                />
            )
        },
        {
            id: 1,
            title: (
                <>
                    {!contractStatus && (
                        <Checkbox
                            onChange={(e) =>
                                handleSelectFileToUpload(
                                    'PersonalisedPlate',
                                    e.target.checked,
                                    personalisedPlateBytes
                                )
                            }
                        />
                    )}
                    <Typography>Personalised Plate</Typography>
                </>
            ),
            content: (
                <DealerSignPersonalisedPlate
                    formData={personalisedPlateFormData}
                    setFormData={setPersonalisedPlateFormData}
                    pdfDoc={personalisedPlatePdfDoc}
                    pdfUrl={personalisedPlatePdfUrl}
                    setPdfUrl={setPersonalisedPlatePdfUrl}
                    contractStatus={contractStatus}
                    setPersonalisedPlateBytes={setPersonalisedPlateBytes}
                />
            )
        },
        {
            id: 2,
            title: (
                <>
                    {!contractStatus && (
                        <Checkbox
                            onChange={(e) =>
                                handleSelectFileToUpload(
                                    'BankDetails',
                                    e.target.checked,
                                    bankDetailsBytes
                                )
                            }
                        />
                    )}
                    <Typography>Bank Details</Typography>
                </>
            ),
            content: (
                <DealerSignBankDetails
                    pdfUrl={bankPdfUrl}
                    contractStatus={contractStatus}
                />
            )
        },
        {
            id: 3,
            title: (
                <>
                    {!contractStatus && (
                        <Checkbox
                            onChange={(e) =>
                                handleSelectFileToUpload(
                                    'ConditionDeclaration',
                                    e.target.checked,
                                    conditionDeclarationBytes
                                )
                            }
                        />
                    )}
                    <Typography>Condition Declaration</Typography>
                </>
            ),
            content: (
                <DealerSignConditionDeclaration
                    pdfUrl={conditionPdfUrl}
                    contractStatus={contractStatus}
                />
            )
        }
    ];

    return (
        <PageWrapper>
            <>
                {contractStatus ? (
                    <DealerDocumentStatusHeader
                        documentType="Vendor Statement"
                        documentId={vehicleId}
                        documentStatus={contractStatus}
                    />
                ) : (
                    <Typography variant="h4">
                        {`Vendor Statement #${vehicleId}`}{' '}
                        <LoadingButton
                            loading={false}
                            variant="contained"
                            onClick={() => {
                                setOpenEmailDialog(true);
                            }}
                        >
                            Email to Customer
                        </LoadingButton>
                    </Typography>
                )}

                <Tabs tabContent={tabContent} />

                <EmailCustomerDialog
                    openEmailDialog={openEmailDialog}
                    setOpenEmailDialog={setOpenEmailDialog}
                    customer={customer}
                    filesToUpload={filesToUpload}
                    showSnackbar={showSnackbar}
                />
            </>
        </PageWrapper>
    );
};

export default withSnackbar(DealerTradeDocManager);
