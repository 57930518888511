// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    DialogContentText,
    Grid,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';

interface CustomerTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
    hideDebtor?: boolean;
}

const customerTypeOptions = [
    { id: 0, label: 'Retail', value: 'Retail' },
    { id: 1, label: 'Trade', value: 'Trade' }
];

const CustomerTableFilter = ({
    filter,
    setFilter,
    isFilterActive,
    hideDebtor
}: CustomerTableFilterProps) => {
    const [customerTypes, setCustomerTypes] = useState(customerTypeOptions);

    // Check if the filter is active, if it isn't reset the filter options
    // This is primarily for when a user clicks the clear filter button, we want all options
    // back in the dropdown
    useEffect(() => {
        if (!isFilterActive()) {
            setCustomerTypes(customerTypeOptions);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.customerFilter;
        newFilter[property] = value;
        setFilter({ ...filter, customerFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={customerTypes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Customer Type"
                        selectedValues={filter.customerFilter.customerType}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = customerTypeOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setCustomerTypes(currentOptions);
                            handleUpdateFilter('customerType', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={8}>
                    {/* The debtor, phone number and email checkbox filters */}
                    <FormGroup row>
                        {hideDebtor ? null : (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filter.customerFilter.debtor}
                                        onChange={(e) =>
                                            handleUpdateFilter(
                                                'debtor',
                                                e.target.checked
                                            )
                                        }
                                    />
                                }
                                label="Debtor"
                            />
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        filter.customerFilter.hasPhoneNumber
                                    }
                                    onChange={(e) =>
                                        handleUpdateFilter(
                                            'hasPhoneNumber',
                                            e.target.checked
                                        )
                                    }
                                />
                            }
                            label="Has Phone Number"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter.customerFilter.hasEmail}
                                    onChange={(e) =>
                                        handleUpdateFilter(
                                            'hasEmail',
                                            e.target.checked
                                        )
                                    }
                                />
                            }
                            label="Has Email"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </>
    );
};

export default CustomerTableFilter;
