import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import { CalculatePartsParams } from '../interface/PNLInterfaces';

const CalculateParts: CalculatePartsParams = (
    orders,
    setPartData,
    setPartValues,
    setWrongPartData
) => {
    var partData = [];
    var wrongPartData = [];
    var partValues = {
        partTotal: 0,
        partCost: 0,
        partRevenue: 0,
        vehicleTotal: 0,
        vehicleCost: 0,
        vehicleRevenue: 0,
        serviceTotal: 0,
        serviceCost: 0,
        serviceRevenue: 0,
        saleTotal: 0,
        saleCost: 0,
        saleRevenue: 0,
        onlineTotal: 0,
        onlineCost: 0,
        onlineRevenue: 0
    };

    orders.forEach((order) => {
        let totalOrderCost = 0;
        let totalOrderRevenue = 0;
        let orderType = order.VehicleSaleId
            ? 'Sale'
            : order.ServiceId
              ? 'Service'
              : order.VehicleId
                ? 'Vehicle'
                : order.OnlineOrderId
                  ? 'Online'
                  : 'Part Sale';

        const validOrderLines = order.LinkedSaleId
            ? order.orderLines // if an order has a LinkedSaleId, it is a split sale, then all the order lines are valid
            : order.orderLines.filter((line) => !line.linkedSaleId); // if an order do not have a LinkedSaleId, it is the origin sale, should exclude the order lines that are linked to other sales

        for (const part of validOrderLines) {
            if (
                !part.costPriceAverage ||
                !part.pricePaid ||
                part.pricePaid === 'NaN'
            ) {
                let message =
                    !part.costPriceAverage && !part.pricePaid
                        ? 'No average cost or sale price entered'
                        : !part.pricePaid || part.pricePaid === 'NaN'
                          ? 'No sale price entered'
                          : 'No average cost price entered';
                let saleLine = {
                    OrderId: order.id,
                    orderType: orderType,
                    message: message,
                    partNumber: part.partNumber,
                    costAverage: part.costPriceAverage,
                    costDaily: part.costPriceDaily,
                    submessage: !part.costPriceAverage
                        ? 'Daily Cost: ' +
                          CurrencyFormatter(part.costPriceDaily)
                        : ''
                };
                wrongPartData.push(saleLine);
            }

            let averageCheck = Math.abs(
                part.costPriceAverage - part.costPriceDaily
            );

            if (averageCheck >= part.costPriceDaily * 2) {
                let saleLine = {
                    OrderId: order.id,
                    orderType: orderType,
                    partNumber: part.partNumber,
                    message:
                        'Cost price average is signficantly larger than cost price daily',
                    submessage:
                        'Daily: ' +
                        CurrencyFormatter(part.costPriceDaily) +
                        ', Average: ' +
                        CurrencyFormatter(part.costPriceAverage)
                };
                wrongPartData.push(saleLine);
            }

            if (part.voucherCode) {
                totalOrderCost += parseFloat(part.totalPrice.toString());
                if (
                    order.internalType ||
                    order.ServiceId ||
                    order.VehicleId ||
                    part.gstFree ||
                    order['Customer.international'] ||
                    order['Customer.gstFree']
                ) {
                    totalOrderRevenue += parseFloat(part.totalPrice.toString());
                } else {
                    totalOrderRevenue +=
                        parseFloat(part.totalPrice.toString()) / 1.1;
                }
            } else {
                totalOrderCost +=
                    (part.costPriceAverage ?? part.costPriceDaily) *
                    parseFloat(part.quantity);
                if (
                    order.internalType ||
                    order.ServiceId ||
                    order.VehicleId ||
                    part.gstFree ||
                    order['Customer.international'] ||
                    order['Customer.gstFree']
                ) {
                    if (order.OnlineOrderId) {
                        const totalPayment = order.paymentLines.reduce(
                            (acc, payment) => acc + Number(payment.amount),
                            0
                        );
                        totalOrderRevenue += totalPayment;
                        break; // break the loop to avoid adding the same payment multiple times
                    } else {
                        totalOrderRevenue +=
                            parseFloat(
                                part.pricePaid === 'NaN'
                                    ? part.priceRRP.toString()
                                    : part.pricePaid
                            ) * parseFloat(part.quantity);
                    }
                } else {
                    if (order.OnlineOrderId) {
                        const totalPayment = order.paymentLines.reduce(
                            (acc, payment) => acc + Number(payment.amount),
                            0
                        );
                        totalOrderRevenue += totalPayment / 1.1;
                        break; // break the loop to avoid adding the same payment multiple times
                    } else {
                        totalOrderRevenue +=
                            (parseFloat(
                                part.pricePaid === 'NaN'
                                    ? part.priceRRP.toString()
                                    : part.pricePaid
                            ) *
                                parseFloat(part.quantity)) /
                            1.1;
                    }
                }
            }
        }

        let saleLine = {
            OrderId: order.id,
            orderType: orderType,
            totalCost: totalOrderCost,
            totalSale: totalOrderRevenue,
            numberParts: validOrderLines.length,
            VehicleSaleId: null,
            VehicleId: null,
            ServiceId: null,
            OnlineOrderId: null,
            customerName: null
        };

        if (order.VehicleSaleId) {
            partValues.saleTotal += 1;
            partValues.saleCost += totalOrderCost;
            partValues.saleRevenue += totalOrderRevenue;

            saleLine.VehicleSaleId = order.VehicleSaleId;
        } else if (order.VehicleId) {
            partValues.vehicleTotal += 1;
            partValues.vehicleCost += totalOrderCost;
            partValues.vehicleRevenue += totalOrderRevenue;

            saleLine.VehicleId = order.VehicleId;
        } else if (order.ServiceId) {
            partValues.serviceTotal += 1;
            partValues.serviceCost += totalOrderCost;
            partValues.serviceRevenue += totalOrderRevenue;

            saleLine.ServiceId = order.ServiceId;
        } else if (order.OnlineOrderId) {
            partValues.onlineTotal += 1;
            partValues.onlineCost += totalOrderCost;
            partValues.onlineRevenue += totalOrderRevenue;

            saleLine.OnlineOrderId = order.OnlineOrderId;
        } else {
            partValues.partTotal += 1;
            partValues.partCost += totalOrderCost;
            partValues.partRevenue += totalOrderRevenue;

            saleLine.customerName = order['Customer.firstName']
                ? order['Customer.firstName'].charAt(0) +
                  '. ' +
                  order['Customer.lastName']
                : order['Customer.lastName'];
        }

        if (
            Number.isNaN(partValues.partCost) ||
            Number.isNaN(partValues.partRevenue) ||
            Number.isNaN(partValues.saleCost) ||
            Number.isNaN(partValues.saleRevenue) ||
            Number.isNaN(partValues.vehicleCost) ||
            Number.isNaN(partValues.vehicleRevenue) ||
            Number.isNaN(partValues.serviceCost) ||
            Number.isNaN(partValues.serviceRevenue) ||
            Number.isNaN(partValues.onlineCost) ||
            Number.isNaN(partValues.onlineRevenue)
        ) {
            return false;
        }

        partData.push(saleLine);
    });

    if (setPartData) setPartData(partData);
    if (setPartValues) setPartValues(partValues);
    if (setWrongPartData) setWrongPartData(wrongPartData);
    return partValues;
};

export default CalculateParts;
