// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup
} from '@mui/material';
// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
import PartSearch from '../../../global/PartSearch';
// LOGIC
import GetAllSuppliers from '../../../global/databaseLogic/GetAllSuppliers';
import AddPartToInvoice from '../logic/AddPartToInvoice';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import {
    MultipleStockInvoice,
    StockInvoice
} from '../../../global/interfaces/PartsInterface';
import SiteSelector from '../../../global/SiteSelector';

interface InvoiceHeaderProps {
    id: string;
    invoiceData: StockInvoice;
    isMultipleInvoice: boolean;
    multipleInvoiceData: MultipleStockInvoice;
    setMultipleInvoiceData: Dispatch<SetStateAction<MultipleStockInvoice>>;
    selectedSite: number;
    setInvoiceData: Dispatch<SetStateAction<StockInvoice>>;
    setSelectedSite: Dispatch<SetStateAction<number>>;
    reverseOrder: string[];
}

/**
 * InvoiceHeader
 * Header UI for Stock Invoices
 * @author Sienna
 * @param InvoiceHeaderProps
 * @returns {JSX} the stock invoice header
 */
const InvoiceHeader = ({
    id,
    invoiceData,
    isMultipleInvoice,
    multipleInvoiceData,
    setMultipleInvoiceData,
    selectedSite,
    setInvoiceData,
    setSelectedSite,
    reverseOrder
}: InvoiceHeaderProps) => {
    const [creditors, setCreditors] = useState<Creditor[]>();

    useEffect(() => {
        GetAllSuppliers(setCreditors);
    }, []);

    // Update invoice data's site when selectedSite is updated
    useEffect(() => {
        if (isMultipleInvoice) {
            setMultipleInvoiceData({
                ...multipleInvoiceData,
                SiteId: selectedSite
            });
        } else {
            setInvoiceData({ ...invoiceData, SiteId: selectedSite });
        }
        // eslint-disable-next-line
    }, [selectedSite]);

    const handleAdd = (unitDetails, selectedSupplier) => {
        AddPartToInvoice(
            unitDetails,
            selectedSupplier,
            invoiceData,
            setInvoiceData,
            reverseOrder
        );
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <PartSearch
                        HandleAdd={handleAdd}
                        Supplier={
                            isMultipleInvoice
                                ? multipleInvoiceData.Supplier
                                : invoiceData.Supplier
                        }
                        size="small"
                        isDisabled={
                            isMultipleInvoice
                                ? true
                                : invoiceData.Supplier
                                  ? false
                                  : true
                        }
                        checkBackorders={true}
                        SelectedSite={selectedSite}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        isDisabled={
                            invoiceData.orderLines.length > 0 ||
                            multipleInvoiceData?.orderLines?.length > 0
                                ? true
                                : false
                        }
                        size="small"
                        options={creditors}
                        useTwoOptionLabels={false}
                        primaryOptionLabel="name"
                        textfieldLabel="Creditor"
                        selectedValue={invoiceData.Supplier}
                        handleSelectedValueChange={(newValue) => {
                            if (newValue == null) {
                                setInvoiceData({
                                    ...invoiceData,
                                    Supplier: null,
                                    SupplierId: null
                                });
                                localStorage.setItem(
                                    'stockInvoiceData',
                                    JSON.stringify({
                                        ...invoiceData,
                                        Supplier: null,
                                        SupplierId: null
                                    })
                                );
                            } else {
                                setInvoiceData({
                                    ...invoiceData,
                                    Supplier: newValue,
                                    SupplierId: newValue.id
                                });
                                localStorage.setItem(
                                    'stockInvoiceData',
                                    JSON.stringify({
                                        ...invoiceData,
                                        Supplier: newValue,
                                        SupplierId: newValue.id
                                    })
                                );
                            }
                        }}
                        handleInputValueChange={(newValue) => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SiteSelector
                        size="small"
                        label="Site"
                        variant="outlined"
                        disabled={!id ? false : true}
                        selectedSite={selectedSite}
                        setSelectedSite={setSelectedSite}
                        fullWidth={true}
                        viewPartUI={false}
                    />
                </Grid>
                <Grid item xs={2} textAlign="left">
                    <FormControl>
                        <RadioGroup
                            row
                            value={
                                isMultipleInvoice
                                    ? multipleInvoiceData.gstMethod
                                    : invoiceData.gstMethod
                            }
                            onChange={(e) => {
                                if (isMultipleInvoice) {
                                    setMultipleInvoiceData({
                                        ...multipleInvoiceData,
                                        gstMethod: e.target.value
                                    });
                                } else {
                                    setInvoiceData({
                                        ...invoiceData,
                                        gstMethod: e.target.value
                                    });
                                }
                                localStorage.setItem(
                                    'stockInvoiceData',
                                    JSON.stringify({
                                        ...invoiceData,
                                        gstMethod: e.target.value
                                    })
                                );
                            }}
                        >
                            <FormControlLabel
                                value="including"
                                control={<Radio />}
                                label="INC. GST"
                            />
                            <FormControlLabel
                                value="excluding"
                                control={<Radio />}
                                label="EX. GST"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default InvoiceHeader;
