const ValidationIPWhistelist = (ipData, setIpData) => {
    let newIPData = [...ipData]
    let errorPresent = false
    for (let line of newIPData) {
        // Reset line in case it's the second time we check the data
        line.siteError = false
        line.maskError = false

        // Check if a site has been selected
        if (line.SiteId == null) {
            line.siteError = true
            errorPresent = true
        }
        
        // Check that the mask follow the this regex rule:
        let regex = /(?:(?:(?:[0-9]|x){1,3}\.){3}(?:[0-9]|x){1,3})/g
        if (!regex.test(line.mask)) {
            // There is something wrong with the mask
            line.maskError = true
            errorPresent = true
        } else {
            // Check that if an x is present, it's alone and not with numbers
            let maskComponent = line.mask.split('.')
            let numberRegex = /[0-9]/g
            let previousComponent = ''
            for (let component of maskComponent) {
                if (component.includes('x') && numberRegex.test(component)) {
                    line.maskError = true
                    errorPresent = true
                } else if (!component.includes('x') && previousComponent.includes('x')) {
                    // If the current component doesn't contains an x
                    // AND the previous component contains one
                    // E.G: 192.x.0.2
                    line.maskError = true
                    errorPresent = true
                }
                previousComponent += component
            }
        }
    }

    setIpData(newIPData)
    return errorPresent
}

export default ValidationIPWhistelist