import { Step } from '../../interface';

export const NewStockOrderSteps: Step[] = [
    {
        label: "Click 'Stock Control' > 'New Stock Order' in the sidebar to navigate to the new stock order page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-01.png'
    },
    {
        label: 'Enter the part number or barcode, then press Enter to add the part to the order. You can also tick if the part needs to go into a Daily, Stock or Preorder Stock Order.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-02.png'
    },
    {
        label: 'If a part has a single supplier, it will automatically be added to the order. If there are multiple suppliers, a popup will appear for you to select the desired supplier.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-03.png'
    },
    {
        label: "Adjust the quantities as needed. Every time you click into another quantity field, the table on the left will update to show you the parts' sale history for the previous year. Once you have finished adjusting the quantities, click the Submit Order With Selected Parts button to navigate to the order summary page (note that only the parts with a ticked checkbox will be added to the Stock Order).",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-04.png'
    },
    {
        label: 'You can also import Customer Backorders, click the “Import Backorders” button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-07.png'
    },
    {
        label: 'All Customer Backorders will be added to the Stock Order, to only add Backorders for a specific Supplier, select the desired Supplier in the Creditor field. Select the parts to order by checking the boxes next to the part numbers',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-08.png'
    },
    {
        label: 'You can also view and import Customer Backorders to the Stock Order through the Customer Backorders tab.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-11.png'
    },
    {
        label: 'In the Customer Backorder tab, select the desired creditor in the list by clicking their ID.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-12.png'
    },
    {
        label: 'Click the Move Backorders to Stock Order button to transfer all backorders of the selected creditor to the stock order. Repeat this step for other creditors if necessary.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-13.png'
    },
    {
        label: 'Use the Check icon to select or deselect all parts, or the delete icon to remove all unselected parts.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-09.png'
    },
    {
        label: 'Review the order and choose between submitting it via Ora Submission or Email Submission. Ora Submission will only create the order in Ora, while Email Submission will also send an email to all listed creditors. Ora will create one Stock Order per Supplier per Type (so if you created a Daily Stock Order and a Stock Stock Order for the same Supplier, Ora will create two separate Stock Orders.) Upon successful submission, a success message will appear and the Stock Orders will be created.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-05.png'
    },
    {
        label: 'The created Stock Orders will now appears in the Stock Orders page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/newStockOrder/new-stock-order-06.png'
    }
];
