// IMPORTS
import { TableRow, Typography } from '@mui/material';
// COMPONENTS
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../../global/tableComponents/DataCell';
// LOGIC
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';

interface ServiceWarrantyContentProps {
    selectedObject;
}

const ServiceWarrantyContent = ({
    selectedObject
}: ServiceWarrantyContentProps) => {
    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Service' },
        { id: 2, label: 'Amount' }
    ];

    const getVehicleDetails = (line) => {
        if (line.VehicleId) {
            return `${line.Vehicle.year} ${line.Vehicle.Make.name} ${line.Vehicle.Model.name}`;
        } else if (line.CustomerVehicleId) {
            return `${line.CustomerVehicle.year} ${line.CustomerVehicle.Make.name} ${line.CustomerVehicle.Model.name}`;
        }
    };

    return (
        <>
            <Typography variant="body1">
                <b>Reference:</b> {selectedObject.reference}
            </Typography>
            <Typography variant="body1">
                <b>Date Entered:</b> {selectedObject.date}
            </Typography>
            <Typography variant="body1">
                <b>Amount:</b> {CurrencyFormatter(selectedObject.amount)}
            </Typography>
            <br />
            <Typography variant="body1">
                <b>Date Submitted:</b> {selectedObject.dateSubmitted}
            </Typography>
            {selectedObject.submittedBy && (
                <Typography variant="body1">
                    <b>Submitted by:</b> {selectedObject.submittedBy}
                </Typography>
            )}
            <br />
            <DataTable columns={columns}>
                {selectedObject.warrantyLines.map((line) => (
                    <TableRow>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(
                                    `/service/viewBooking/${line.ServiceId}`,
                                    '_blank'
                                )
                            }
                        >
                            {line.ServiceId}
                        </DataCellColoured>
                        <DataCell>
                            {line?.Service?.jobType} - {getVehicleDetails(line)}
                        </DataCell>
                        <DataCell>{CurrencyFormatter(line.amount)}</DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </>
    );
};

export default ServiceWarrantyContent;
