// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Unit } from '../../../global/interfaces/PartsInterface';

const GetAllFluids = (setFluids: Dispatch<SetStateAction<Unit[]>>) => {
  api.get('allFluids').then((res) => {
    let currentOptions = [...res.data];
    currentOptions.push({
      partNumber: 'E10 PETROL',
      name: 'E10',
      partType: 'fuel'
    });
    currentOptions.push({
      partNumber: '91 PETROL',
      name: 'Standard Unleaded Petrol',
      partType: 'fuel'
    });
    currentOptions.push({
      partNumber: '95 PETROL',
      name: 'Octane Unleaded Petrol',
      partType: 'fuel'
    });
    currentOptions.push({
      partNumber: '98 PETROL',
      name: 'Premium Octane Unleaded Petrol',
      partType: 'fuel'
    });
    currentOptions.push({
      partNumber: 'DIESEL',
      name: 'Diesel',
      partType: 'fuel'
    });

    setFluids(currentOptions);
  });
};

export default GetAllFluids;
