// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Typography,
    Table,
    TableRow,
    TableCell,
    Drawer,
    Box,
    Divider,
    DialogContent,
    DialogActions,
    Dialog,
    CircularProgress,
    Backdrop
} from '@mui/material';
import {
    DateFormatter,
    CurrencyFormatter,
    ToFixed
} from '../../../../global/logic/Formatters';
import { Check, Clear, Save } from '@mui/icons-material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

// COMPONENTS
import ParametersSelection from '../ParametersSelection';
import SpeedDial from '../../../../global/SpeedDial';
import Reports from './components/Reports';
import { DataRetrievalDialogContent } from './components/DataRetrievalDialogContent';
import CommitPNLUI from './components/CommitPNLUI';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Paper from '../../../../global/Paper';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import { withSnackbar } from '../../../../global/WrappingSnackbar';

// LOGIC
import CalculatePNLReporting from './logic/CalculatePNLReporting';
import CalculatePNLStatement from './logic/CalculatePNLStatement';
import GetCommitPNLData from './logic/GetCommitPNLData';

// INTERFACE
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Site } from '../../../../global/interfaces/GeneralInterface';

interface PNLReportingProps {
    selectedSite: Site[];
    setSelectedSite: Dispatch<SetStateAction<Site[]>>;
    queryDate: { startDate: Dayjs; endDate: Dayjs };
    setQueryDate: Dispatch<
        SetStateAction<{ startDate: Dayjs; endDate: Dayjs }>
    >;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const PNLReporting = (props: PNLReportingProps) => {
    const [expenses, setExpenses] = useState<{
        [key: string]: {
            [key: string]: {
                accountId: number;
                accountName: string;
                amount: number;
                date: string;
                description: string;
                reconciled: boolean;
                url: string;
            }[];
        };
    }>({});
    const [rebates, setRebates] = useState<{
        [key: string]: {
            [key: string]: {
                accountId: number;
                accountName: string;
                amount: number;
                date: string;
                description: string;
                reconciled: boolean;
                url: string;
            }[];
        };
    }>({});
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [currentPeriods, setCurrentPeriods] = useState([]);
    const [ytdPeriods, setYtdPeriods] = useState([]);
    const [lastYearPeriods, setLastYearPeriods] = useState([]);
    const [lastYearYTDPeriods, setLastYearYTDPeriods] = useState([]);
    const [loadingDialog, setLoadingDialog] = useState<boolean>(false);
    const [pdfLoading, setPDFLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);

    const getProgress = (oldCounter) => {
        let numberOfItem =
            currentPeriods.length * 4 +
            ytdPeriods.length * 4 +
            lastYearPeriods.length * 4 +
            lastYearYTDPeriods.length * 4;
        let counter = 0;

        counter += currentPeriods.filter(
            (x) => x.vehicleDataCollected === true
        ).length;
        counter += currentPeriods.filter(
            (x) => x.paDataCollected === true
        ).length;
        counter += currentPeriods.filter(
            (x) => x.serviceDataCollected === true
        ).length;
        counter += currentPeriods.filter(
            (x) => x.miscDataCollected === true
        ).length;

        counter += ytdPeriods.filter(
            (x) => x.vehicleDataCollected === true
        ).length;
        counter += ytdPeriods.filter((x) => x.paDataCollected === true).length;
        counter += ytdPeriods.filter(
            (x) => x.serviceDataCollected === true
        ).length;
        counter += ytdPeriods.filter(
            (x) => x.miscDataCollected === true
        ).length;

        counter += lastYearPeriods.filter(
            (x) => x.vehicleDataCollected === true
        ).length;
        counter += lastYearPeriods.filter(
            (x) => x.paDataCollected === true
        ).length;
        counter += lastYearPeriods.filter(
            (x) => x.serviceDataCollected === true
        ).length;
        counter += lastYearPeriods.filter(
            (x) => x.miscDataCollected === true
        ).length;

        counter += lastYearYTDPeriods.filter(
            (x) => x.vehicleDataCollected === true
        ).length;
        counter += lastYearYTDPeriods.filter(
            (x) => x.paDataCollected === true
        ).length;
        counter += lastYearYTDPeriods.filter(
            (x) => x.serviceDataCollected === true
        ).length;
        counter += lastYearYTDPeriods.filter(
            (x) => x.miscDataCollected === true
        ).length;

        let progress = ToFixed(counter / numberOfItem) * 100;

        if (progress > oldCounter) {
            setProgress(progress);
        }
    };

    useEffect(() => {
        getProgress(progress);
        // eslint-disable-next-line
    }, [currentPeriods, ytdPeriods, lastYearPeriods, lastYearYTDPeriods]);

    const [vehicleData, setVehicleData] = useState<
        {
            VehicleSaleId: number;
            adminLoadCost: number;
            customerName: string;
            deliveryPrice: number;
            extraCost: number;
            rebatePrice: number;
            serviceCosts: number;
            stockNumber: string;
            totalCost: number;
            totalSale: number;
            vehicleCondition: string;
            vehicleCostPrice: number;
            vehicleSalePrice: number;
            warrantyCost: number;
        }[]
    >([]);
    const [wrongVehicleData, setWrongVehicleData] = useState<
        {
            VehicleSaleId: number;
            customerName: string;
            message: string;
            stockNumber: string;
            vehicleCondition: string;
        }[]
    >([]);
    const [vehicleValues, setVehicleValues] = useState<{
        adminLoadCost: number;
        adminLoadCounter: number;
        dofCounter: number;
        adminLoadFromUsed: number;
        adminLoadFromNew: number;
        dofPrice: number;
        newCost: number;
        newRevenue: number;
        newTotal: number;
        usedCost: number;
        usedRevenue: number;
        usedTotal: number;
        warrantyCost: number;
        warrantyCounter: number;
        warrantyCostFromUsed: number;
        warrantyCostFromNew: number;
        warrantyPrice: number;
        warrantyRevenueFromUsed: number;
        DOFFromUsed: number;
        warrantyRevenueFromNew: number;
        DOFFromNew: number;
        adminLoadCounterUsed: number;
        adminLoadCounterNew: number;
        warrantyCounterUsed: number;
        warrantyCounterNew: number;
        dofCounterUsed: number;
        dofCounterNew: number;
    }>({
        adminLoadCost: 0,
        adminLoadCounter: 0,
        adminLoadFromUsed: 0,
        adminLoadFromNew: 0,
        dofCounter: 0,
        dofPrice: 0,
        newCost: 0,
        newRevenue: 0,
        newTotal: 0,
        usedCost: 0,
        usedRevenue: 0,
        usedTotal: 0,
        warrantyCost: 0,
        warrantyCounter: 0,
        warrantyCostFromUsed: 0,
        warrantyCostFromNew: 0,
        warrantyPrice: 0,
        warrantyRevenueFromUsed: 0,
        DOFFromUsed: 0,
        warrantyRevenueFromNew: 0,
        DOFFromNew: 0,
        adminLoadCounterUsed: 0,
        adminLoadCounterNew: 0,
        warrantyCounterUsed: 0,
        warrantyCounterNew: 0,
        dofCounterUsed: 0,
        dofCounterNew: 0
    });

    const [partData, setPartData] = useState<
        {
            OnlineOrderId: number;
            OrderId: number;
            ServiceId: number;
            VehicleId: number;
            VehicleSaleId: number;
            customerName: string;
            numberParts: number;
            orderType: string;
            totalCost: number;
            totalSale: number;
        }[]
    >([]);
    const [wrongPartData, setWrongPartData] = useState<
        {
            OrderId: number;
            costAverage: number;
            costDaily: number;
            message: string;
            orderType: string;
            submessage: string;
            partNumber: string;
        }[]
    >([]);
    const [partValues, setPartValues] = useState<{
        onlineCost: number;
        onlineRevenue: number;
        onlineTotal: number;
        partCost: number;
        partRevenue: number;
        partTotal: number;
        saleCost: number;
        saleRevenue: number;
        saleTotal: number;
        serviceCost: number;
        serviceRevenue: number;
        serviceTotal: number;
        vehicleCost: number;
        vehicleRevenue: number;
        vehicleTotal: number;
    }>({
        onlineCost: 0,
        onlineRevenue: 0,
        onlineTotal: 0,
        partCost: 0,
        partRevenue: 0,
        partTotal: 0,
        saleCost: 0,
        saleRevenue: 0,
        saleTotal: 0,
        serviceCost: 0,
        serviceRevenue: 0,
        serviceTotal: 0,
        vehicleCost: 0,
        vehicleRevenue: 0,
        vehicleTotal: 0
    });

    const [serviceData, setServiceData] = useState<
        {
            ServiceId: number;
            customerName: string;
            serviceType: string;
            totalCost: number;
            totalSale: number;
            vehicleStockNumber: string;
        }[]
    >([]);
    const [wrongServiceData, setWrongServiceData] = useState<
        {
            ServiceId: number;
            costAverage: number;
            costDaily: number;
            message: string;
            orderType: string;
            serviceType: string;
            submessage: string;
        }[]
    >([]);
    const [serviceValues, setServiceValues] = useState<{
        insuranceCost: number;
        insuranceRevenue: number;
        insuranceTotal: number;
        internalCost: number;
        internalRevenue: number;
        internalTotal: number;
        retailCost: number;
        retailRevenue: number;
        retailTotal: number;
        warrantyCost: number;
        warrantyRevenue: number;
        warrantyTotal: number;
    }>({
        insuranceCost: 0,
        insuranceRevenue: 0,
        insuranceTotal: 0,
        internalCost: 0,
        internalRevenue: 0,
        internalTotal: 0,
        retailCost: 0,
        retailRevenue: 0,
        retailTotal: 0,
        warrantyCost: 0,
        warrantyRevenue: 0,
        warrantyTotal: 0
    });

    const [commitPNLDialog, setCommitPNLDialog] = useState<boolean>(false);
    const [commitPNLMonth, setCommitPNLMonth] = useState<Dayjs>(dayjs());
    const [commitPNLUI, setCommitPNLUI] = useState<boolean>(false);
    const [commitPNLData, setCommitPNLData] = useState([]);

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [selectedAccountName, setSelectedAccountName] = useState<string>('');
    const [selectedAccount, setSelectedAccount] = useState<
        {
            accountId: number;
            accountName: string;
            amount: number;
            date: string;
            description: string;
            reconciled: boolean;
            url: string;
        }[]
    >([]);

    const actionsList = [
        {
            name: 'Commit PNL',
            icon: <Save />,
            onClick: () => {
                setCommitPNLDialog(true);
            }
        }
    ];

    const GenerateReport = () => {
        CalculatePNLReporting({
            queryDate: props.queryDate,
            selectedSite: props.selectedSite,
            setLoading: props.setLoading,
            setButtonLoading: setButtonLoading,

            setExpenses: setExpenses,
            setRebates: setRebates,

            vehicleData: vehicleData,
            vehicleValues: vehicleValues,
            wrongVehicleData: wrongVehicleData,
            setVehicleData: setVehicleData,
            setVehicleValues: setVehicleValues,
            setWrongVehicleData: setWrongVehicleData,

            setPartData: setPartData,
            setWrongPartData: setWrongPartData,
            setPartValues: setPartValues,

            setServiceData: setServiceData,
            setWrongServiceData: setWrongServiceData,
            setServiceValues: setServiceValues
        });
    };

    const PrintPNL = () => {
        CalculatePNLStatement({
            queryDate: props.queryDate,
            selectedSite: props.selectedSite,
            setLoading: props.setLoading,
            currentPeriods: currentPeriods,
            setCurrentPeriods: setCurrentPeriods,
            setYtdPeriods: setYtdPeriods,
            setLastYearPeriods: setLastYearPeriods,
            setLastYearYTDPeriods: setLastYearYTDPeriods,
            setLoadingDialog: setLoadingDialog,
            setButtonLoading: setButtonLoading,
            setPDFLoading: setPDFLoading
        });
    };

    return (
        <>
            <ParametersSelection
                selectedSite={props.selectedSite}
                setSelectedSite={props.setSelectedSite}
                queryDate={props.queryDate}
                setQueryDate={props.setQueryDate}
                GenerateReport={GenerateReport}
                PrintPNL={PrintPNL}
                includeAllOption={true}
                vehicleValues={vehicleValues}
                partValues={partValues}
                serviceValues={serviceValues}
                expenses={expenses}
                rebates={rebates}
                buttonLoading={buttonLoading}
                printPNLOption={true}
            />
            <br />

            {commitPNLUI ? (
                <CommitPNLUI
                    commitPNLData={commitPNLData}
                    commitPNLMonth={commitPNLMonth}
                    showSnackbar={props.showSnackbar}
                />
            ) : (
                <Reports
                    queryDate={props.queryDate}
                    partValues={partValues}
                    partData={partData}
                    wrongPartData={wrongPartData}
                    vehicleData={vehicleData}
                    vehicleValues={vehicleValues}
                    wrongVehicleData={wrongVehicleData}
                    serviceData={serviceData}
                    wrongServiceData={wrongServiceData}
                    serviceValues={serviceValues}
                    rebates={rebates}
                    expenses={expenses}
                    setDrawerOpen={setDrawerOpen}
                    setSelectedAccount={setSelectedAccount}
                    setSelectedAccountName={setSelectedAccountName}
                />
            )}

            <Drawer
                anchor={'right'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                style={{ zIndex: 1200 }}
            >
                <Box
                    sx={{ width: '30vw', padding: '20px' }}
                    role="presentation"
                >
                    <Typography variant="h4">{selectedAccountName}</Typography>
                    <br />
                    <Divider />
                    <br />
                    <Table size="small">
                        <TableRow>
                            <TableCell>
                                <strong>Transaction</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Amount</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Date</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Reconciled</strong>
                            </TableCell>
                        </TableRow>
                        {selectedAccount.map((line) => (
                            <TableRow>
                                {line.url ? (
                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(line.url, '_blank')
                                        }
                                    >
                                        {line.description}
                                    </DataCellColoured>
                                ) : (
                                    <TableCell>{line.description}</TableCell>
                                )}
                                <TableCell>
                                    {CurrencyFormatter(line.amount)}
                                </TableCell>
                                <TableCell>
                                    {DateFormatter(line.date)}
                                </TableCell>
                                <TableCell>
                                    {line.reconciled ? (
                                        <Check style={{ color: 'green' }} />
                                    ) : (
                                        <Clear style={{ color: 'red' }} />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </Box>
            </Drawer>

            <Dialog
                open={commitPNLDialog}
                onClose={() => setCommitPNLDialog(false)}
            >
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                            defaultValue={dayjs()}
                            views={['month', 'year']}
                            onChange={(value) => setCommitPNLMonth(value)}
                            openTo="month"
                        />
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={buttonLoading}
                        onClick={() =>
                            GetCommitPNLData(
                                commitPNLMonth,
                                setCommitPNLUI,
                                setCommitPNLData,
                                setCommitPNLDialog,
                                setButtonLoading,
                                props.showSnackbar
                            )
                        }
                    >
                        Commit PNL
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth maxWidth="xl" open={loadingDialog}>
                <DataRetrievalDialogContent
                    currentPeriods={currentPeriods}
                    ytdPeriods={ytdPeriods}
                    lastYearPeriods={lastYearPeriods}
                    lastYearYTDPeriods={lastYearYTDPeriods}
                    progress={progress}
                />
            </Dialog>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={pdfLoading}
                onClick={() => setPDFLoading(false)}
            >
                <Paper>
                    <CircularProgress color="inherit" />
                    <br />
                    Creation of the PDF in progress, please wait.
                </Paper>
            </Backdrop>

            {commitPNLUI ? null : <SpeedDial actionsList={actionsList} />}
        </>
    );
};

export default withSnackbar(PNLReporting);
