const HandleMoveTransaction = (
    direction: string,
    type: string,
    index: number,
    reconciliationDetails,
    setReconciliationDetails
) => {
    let oraTransactionsReconciled = [
        ...reconciliationDetails.oraTransactionsReconciled
    ];
    let bankTransactionsReconciled = [
        ...reconciliationDetails.bankTransactionsReconciled
    ];
    let oraTransactionsUnreconciled = [
        ...reconciliationDetails.oraTransactionsUnreconciled
    ];
    let bankTransactionsUnreconciled = [
        ...reconciliationDetails.bankTransactionsUnreconciled
    ];

    // If we are moving a transaction up
    if (direction === 'up') {
        // If we are moving an ora transaction
        if (type === 'ora') {
            let item = oraTransactionsUnreconciled[index];
            oraTransactionsReconciled.push(item);
            oraTransactionsUnreconciled = oraTransactionsUnreconciled.filter(
                (x) => x !== item
            );
        }

        // If we are moving a bank transaction
        else if (type === 'bank') {
            let item = bankTransactionsUnreconciled[index];
            bankTransactionsReconciled.push(item);
            bankTransactionsUnreconciled = bankTransactionsUnreconciled.filter(
                (x) => x !== item
            );
        }
    }

    // If we are moving a transaction down
    else if (direction === 'down') {
        // If we are moving an ora transaction
        if (type === 'ora') {
            let item = oraTransactionsReconciled[index];
            oraTransactionsUnreconciled.unshift(item);
            oraTransactionsReconciled = oraTransactionsReconciled.filter(
                (x) => x !== item
            );
        }

        // If we are moving a bank transaction
        else if (type === 'bank') {
            let item = bankTransactionsReconciled[index];
            bankTransactionsUnreconciled.unshift(item);
            bankTransactionsReconciled = bankTransactionsReconciled.filter(
                (x) => x !== item
            );
        }
    }

    setReconciliationDetails({
        ...reconciliationDetails,
        oraTransactionsReconciled: oraTransactionsReconciled,
        bankTransactionsReconciled: bankTransactionsReconciled,
        oraTransactionsUnreconciled: oraTransactionsUnreconciled,
        bankTransactionsUnreconciled: bankTransactionsUnreconciled
    });
};

export default HandleMoveTransaction;
