import { TableRow } from '@material-ui/core';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import { Typography } from '@mui/material';
import DataCell from '../../../global/tableComponents/DataCell';

const ProformaDrawerContent = (props) => {
    const columns = [
        { id: 0, label: 'Order ID' },
        { id: 1, label: 'Quantity' },
        { id: 2, label: 'Backorder Quantity' }
    ];
    return props.proformaData.map((part) => (
        <>
            <Typography variant="h5">{part.partNumber}</Typography>
            <DataTable columns={columns}>
                {part.proformas.map((proforma) => (
                    <TableRow>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(
                                    'inventory/viewSale/' + proforma.id,
                                    '_blank'
                                )
                            }
                        >
                            Part Number: {proforma.id}
                        </DataCellColoured>
                        <DataCell>
                            {
                                proforma.orderLines.filter(
                                    (x) => x.partNumber === part.partNumber
                                )[0]?.quantity
                            }
                        </DataCell>
                        <DataCell>
                            {
                                proforma.orderLines.filter(
                                    (x) => x.partNumber === part.partNumber
                                )[0]?.backorderQuantity
                            }
                        </DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </>
    ));
};

export default ProformaDrawerContent;
