import DocumentationPage from '../DocumentationPage';
import { AddNewVehicleTradeSteps } from '../data/vehicleSales/newTrade';

const NewVehicleTradeSupportPage = () => {
    const contentSections = [
        {
            id: 'add-vehicle-trade',
            title: 'Add a new Trade-In',
            href: '/support/vehicles/newTrade/#add-vehicle-trade',
            description: 'To add a new Trade-In, follow these steps:',
            steps: AddNewVehicleTradeSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="New Trade"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default NewVehicleTradeSupportPage;
