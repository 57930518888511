//IMPORTS
import React, { useState, useEffect } from 'react';
import {
  Button,
  Autocomplete,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Divider,
  DialogActions,
  DialogContentText,
  Popper,
  debounce
} from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

//API
import api from '../../../../../api';

// INTERFACE
import { User } from '../../../global/interfaces/GeneralInterface';
import { Dayjs } from 'dayjs';
interface ExportTimeClockDialogProps {
  handleSubmit: (
    selectedUser: User[],
    exportPeriod: {
      startDate: Dayjs;
      endDate: Dayjs;
    }
  ) => void;
}

const ExportTimeClockDialog = (props: ExportTimeClockDialogProps) => {
  const [exportPeriod, setExportPeriod] = useState({
    startDate: null,
    endDate: null
  });
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const autoCompleteChange = (event, value) => {
    setSelectedUser(value);
  };

  const onClick2 = debounce(() => {
    props.handleSubmit(selectedUser, exportPeriod);
  }, 300);

  useEffect(() => {
    api.get('usersNextGen/department').then((res) => {
      let tempUserList = [];
      res.data.forEach((user) => {
        user.checked = false;
        tempUserList.push(user);
      });
      setUserList(tempUserList);
    });
  }, []);

  return (
    <div>
      <DialogTitle> Export Time Clock Data </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {' '}
          Enter the start and end date of the data you would like to export
        </DialogContentText>
        <br />
        <Grid container spacing={2}>
          {/* START DATE PICKER */}
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                format="DD/MM/YYYY"
                label="Export Period Start Date"
                value={exportPeriod.startDate}
                onChange={(newValue) => {
                  setExportPeriod({ ...exportPeriod, startDate: newValue });
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: false,
                    size: 'small',
                    InputLabelProps: { shrink: true }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          {/* END DATE PICKER */}
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                format="DD/MM/YYYY"
                label="Export Period End Date"
                value={exportPeriod.endDate}
                onChange={(newValue) => {
                  setExportPeriod({ ...exportPeriod, endDate: newValue });
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: false,
                    size: 'small',
                    InputLabelProps: { shrink: true }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>

        <Divider></Divider>
        <br />

        {/* USER SELECTOR */}
        <DialogContentText>
          Select the users you would like to export
        </DialogContentText>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              onChange={autoCompleteChange}
              options={userList}
              groupBy={(option) => option.department}
              getOptionLabel={(option) =>
                option.firstName + ' ' + option.lastName
              }
              filterSelectedOptions
              disableCloseOnSelect
              autoComplete
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Users"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
              // Setting height to 0 make the popper always showing beneath the autocomplete component
              PopperComponent={({ style, ...props }) => (
                <Popper {...props} style={{ ...style, height: 0 }} />
              )}
              ListboxProps={{ style: { maxHeight: '50vh' } }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClick2}>
          Download CSV
        </Button>
      </DialogActions>
    </div>
  );
};

export default ExportTimeClockDialog;
