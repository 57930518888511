import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { TradeIn } from '../../../global/interfaces/VehicleSaleInterface';
import { Customer } from '../../../global/interfaces/GeneralInterface';

const HandleGetTrades = (
  id: number,
  setTradeVehicles: Dispatch<SetStateAction<TradeIn[]>>,
  setSelectedCustomer: Dispatch<SetStateAction<Customer>>,
  setReadOnly: Dispatch<SetStateAction<boolean>>,
  setGstType: Dispatch<SetStateAction<string>>
) => {
  api.get(`getTrades/${id}`).then((res) => {
    setTradeVehicles(res.data);
    setGstType(res.data[0].gstType ?? 'EX');
    setSelectedCustomer(res.data[0].Customer);
    setReadOnly(true);
  });
};

export default HandleGetTrades;
