// IMPROTS
import { useState } from 'react';
import { TableRow, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import InvoiceHeader from './InvoiceHeader';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../../global/tableComponents/DataTable';
import Fab from '../../../../global/Fab';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
// LOGIC
import SubmitTrustReceipt from '../../logic/SubmitTrustReceipt';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
interface InvoiceLine {
    id: number;
    Vehicle?: Vehicle;
    baseCost?: string;
    paid?: string;
    amount: string;
}

const RebateInvoice = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false);
    const [responseCode, setResponseCode] = useState<number>(null);
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([]);
    const [trustReceipt, setTrustReceipt] = useState({
        documentReference: null,
        documentDate: null,
        dueDate: null,
        documentTotal: null
    });

    const columns = [
        { id: 0, label: 'Stock Number', width: 100 },
        { id: 1, label: 'Trust Receipt', width: 100 },
        { id: 2, label: 'Vehicle', width: 300 },
        { id: 3, label: 'Base Cost', width: 100 },
        { id: 4, label: 'GST', width: 100 },
        { id: 5, label: 'Total', width: 100 },
        { id: 6, label: 'Balance Due', width: 100 },
        { id: 7, label: 'Amount Paying', width: 200 },
        { id: 8, label: '', width: 50 }
    ];

    // Handle removing a line from the invoice
    const removeLine = (lineId: number) => {
        let currentLines = [...invoiceLines];
        currentLines = currentLines.filter((x) => x.id !== lineId);
        setInvoiceLines(currentLines);
    };

    // Handle changing the amount paying on the selected line
    const handleChangeAmount = (id: number, newValue: string) => {
        let currentLines = [...invoiceLines];
        let index = currentLines.findIndex((x) => x.id === id);
        let row = currentLines[index];

        row.amount = newValue.replace(',', '');

        currentLines[index] = row;
        setInvoiceLines(currentLines);
    };

    //Handle calculate total invoice amount
    const handleTotalInvoiceAmount = () => {
        return invoiceLines.reduce(
            (sum, line) => sum + parseFloat(line.amount),
            0
        );
    };

    //submit invoice
    const handleSubmitTrustReceipt = () => {
        if (!isSubmittingInvoice) {
            SubmitTrustReceipt(
                invoiceLines,
                trustReceipt,
                showSnackbar,
                setIsSubmittingInvoice,
                responseCode
            );
        }
    };

    return (
        <>
            <InvoiceHeader
                invoiceLines={invoiceLines}
                setInvoiceLines={setInvoiceLines}
                trustReceipt={trustReceipt}
                setTrustReceipt={setTrustReceipt}
                responseCode={responseCode}
                setResponseCode={setResponseCode}
            />
            <br />
            {invoiceLines.length > 0 && (
                <Paper>
                    <DataTable columns={columns}>
                        {invoiceLines.map((line) => (
                            <TableRow>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            `/vehicles/view/${line.Vehicle.id}`
                                        )
                                    }
                                >
                                    {line.Vehicle?.stockNumber}
                                </DataCellColoured>
                                <DataCell>
                                    {line.Vehicle?.trustReceipt}
                                </DataCell>
                                <DataCell>
                                    {line.Vehicle?.year}{' '}
                                    {line.Vehicle['Make.name']}{' '}
                                    {line.Vehicle['Model.name']}{' '}
                                    {line.Vehicle['Series.name']}
                                    <br />
                                    <span
                                        style={{
                                            color: '#555',
                                            fontSize: '12px'
                                        }}
                                    >
                                        VIN: {line.Vehicle?.vin}
                                    </span>
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.baseCost)}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        (parseFloat(line.baseCost) * 1.1) / 11
                                    )}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        parseFloat(line.baseCost) * 1.1
                                    )}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        parseFloat(line.baseCost) * 1.1 -
                                            parseFloat(line.paid)
                                    )}
                                </DataCell>
                                <DataCell>
                                    <TextField
                                        variant="standard"
                                        size="small"
                                        value={line.amount}
                                        onChange={(e) =>
                                            handleChangeAmount(
                                                line.id,
                                                e.target.value
                                            )
                                        }
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                </DataCell>
                                <DataCell>
                                    <IconButton
                                        onClick={() => removeLine(line.id)}
                                    >
                                        <Close />
                                    </IconButton>
                                </DataCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <DataCell colSpan={6} align="right">
                                <b>Trust Receipt Totals:</b>
                            </DataCell>
                            <DataCell>
                                <b>
                                    {CurrencyFormatter(
                                        handleTotalInvoiceAmount()
                                    )}
                                </b>
                            </DataCell>
                            <DataCell></DataCell>
                        </TableRow>
                    </DataTable>
                </Paper>
            )}

            <Fab
                noSession={true}
                editIcon={false}
                onClick={handleSubmitTrustReceipt}
            />
        </>
    );
};

export default withSnackbar(RebateInvoice);
