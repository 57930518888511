import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface GroupedOnlineOrders {
    pick: GroupedOnlineOrder[];
    pack: GroupedOnlineOrder[];
}
interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
const MoveOrderToPendingDispatch = (
    selectedOrder: GroupedOnlineOrder,
    pickPackOrders: GroupedOnlineOrders,
    setPickPackOrders: Dispatch<SetStateAction<GroupedOnlineOrders>>,
    setSelectedGroupOrders: Dispatch<SetStateAction<GroupedOnlineOrder[]>>,
    showSnackbar: showSnackbar
) => {
    api.put('moveOrderToPendingDispatch', selectedOrder).then((res) => {
        if (res.status === 200) {
            let currentPackOrders = [...pickPackOrders.pack];
            currentPackOrders = currentPackOrders.filter(
                (x) => x.oraOrder.id !== selectedOrder.oraOrder.id
            );

            setSelectedGroupOrders(currentPackOrders);
            setPickPackOrders({ ...pickPackOrders, pack: currentPackOrders });

            showSnackbar('Order moved to pending dispatch.');
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default MoveOrderToPendingDispatch;
