// IMPORTS
import api from '../../../../../api';
// LOGIC
import { ToFixed } from '../../../global/logic/Formatters';
// INTERFACES
import {
    Creditor,
    Customer,
    Site
} from '../../../global/interfaces/GeneralInterface';
import { Account } from '../../../global/interfaces/AdminInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { PurchaseOrder } from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Dayjs } from 'dayjs';

interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Account?: Account;
    Debtor?: Customer;
    Vehicle?: Vehicle;
    PurchaseOrder?: PurchaseOrder;
    type: string;
    documentReference: string;
    documentDate: Dayjs;
    dueDate: Dayjs;
    GSTtype: string;
    documentTotal: string;
}

interface InvoiceLine {
    id: number;
    Account?: Account;
    Debtor?: Customer;
    Vehicle?: Vehicle;
    PurchaseOrder?: PurchaseOrder;
    amount: string;
    description: string;
}

function SubmitCreditInvoice(
    invoiceObject: InvoiceObject,
    invoiceLines: InvoiceLine[],
    invoiceTotal: number,
    responseCode: number,
    showSnackbar: showSnackbar
) {
    if (!invoiceObject.Creditor) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please select the creditor on the invoice.',
            'error'
        );
        return;
    } else if (!invoiceObject.documentReference) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the document reference for the invoice.',
            'error'
        );
        return;
    } else if (!invoiceObject.documentDate) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the document date on the invoice.',
            'error'
        );
        return;
    } else if (!invoiceObject.GSTtype) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the GST type used on the invoice.',
            'error'
        );
        return;
    } else if (!invoiceObject.documentTotal) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the document total on the invoice.',
            'error'
        );
        return;
    } else if (invoiceLines.length === 0) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the invoice lines.',
            'error'
        );
        return;
    } else if (
        ToFixed(invoiceTotal) !== parseFloat(invoiceObject.documentTotal)
    ) {
        showSnackbar(
            'Whoops! Mismatched Data!',
            'Your line total and document total do not match.',
            'error'
        );
        return;
    } else if (responseCode === 400) {
        showSnackbar(
            'Whoops! Invalid Data!',
            'That document reference already exists on another invoice.',
            'error'
        );
        return;
    }

    api.post('adminCreditInvoice', {
        invoiceObject: invoiceObject,
        invoiceLines: invoiceLines
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Invoice Submitted Successfully.',
                'The page will now refresh.',
                'success'
            );
            window.location.reload();
        } else {
            if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact the IT department.',
                    'error'
                );
            } else {
                showSnackbar(
                    res.data.split('|')[0],
                    res.data.split('|')[1],
                    'error'
                );
            }
        }
    });
}

export default SubmitCreditInvoice;
