// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import CalculateMargin from '../../../global/logic/CalculateMargin';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';

const RemoveLineItem = (
    rowId: number,
    saleDetails: Order,
    setSaleDetails: Dispatch<SetStateAction<Order>>
) => {
    // First we are going to isolate the line that we
    let lineToRemove = saleDetails.orderLines.filter(
        (x) => x.rowId === rowId
    )[0];

    // Calculate the new total price, cost and margin
    let newTotalPrice = saleDetails.totalPrice - lineToRemove.totalPrice;
    let newTotalCost = saleDetails.totalCost - lineToRemove.totalCost;
    let newTotalMargin = CalculateMargin(newTotalPrice, newTotalCost);

    // Set the data
    setSaleDetails({
        ...saleDetails,
        orderLines: saleDetails.orderLines.filter((x) => x.rowId !== rowId),
        totalPrice: newTotalPrice,
        totalCost: newTotalCost,
        totalMargin: newTotalMargin
    });
};

export default RemoveLineItem;
