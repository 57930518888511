// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Service } from '../../../global/interfaces/ServiceInterface';

const GetVehicleServiceHistory = (
  id: number,
  setVehicleHistory: Dispatch<SetStateAction<Service[]>>
) => {
  api.get(`vehicleServiceHistory/${id}`).then((res) => {
    setVehicleHistory(res.data);
  });
};

export default GetVehicleServiceHistory;
