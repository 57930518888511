import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { PDFDocument } from 'pdf-lib';

interface UploadFileToS3Props {
    base64data: string;
    folderName: string;
    fileName: string | number;
    showSnackbar?: showSnackbar;
}

export const UploadFileToS3 = ({
    base64data,
    folderName,
    fileName,
    showSnackbar
}: UploadFileToS3Props) => {
    return api
        .post('/uploadFileToS3', {
            base64data,
            folderName,
            fileName
        })
        .then((response) => {
            if (response.status === 200) {
                return response.data; // Return data if success upload
            } else {
                throw new Error('Upload failed: ' + response);
            }
        })
        .catch((error) => {
            showSnackbar?.(
                'File upload failed',
                'Please try again later.',
                'error'
            );
            throw error;
        });
};

interface GetFileFromS3Props {
    folderName: string;
    fileName: string | number;
    setPdfDoc: Dispatch<SetStateAction<PDFDocument>>;
    setPdfUrl: Dispatch<SetStateAction<string>>;
    showSnackbar?: showSnackbar;
    setVendorStatementBytes?: React.Dispatch<any>;
}
export const GetFileFromS3 = ({
    folderName,
    fileName,
    setPdfDoc,
    setPdfUrl,
    showSnackbar,
    setVendorStatementBytes
}: GetFileFromS3Props) => {
    api.get(
        `/getFileFromS3?folder=${encodeURIComponent(folderName)}&file=${encodeURIComponent(fileName)}`,
        {
            responseType: 'blob'
        }
    ).then(async (response) => {
        if (response.status === 200 && response.data) {
            const arrayBuffer = await response.data.arrayBuffer();
            PDFDocument.load(arrayBuffer)
                .then((pdfDoc) => {
                    setPdfDoc(pdfDoc);
                    return pdfDoc.save();
                })
                .then((pdfBytes) => {
                    setVendorStatementBytes?.(pdfBytes);
                })
                .catch((error) => {
                    console.error('Error loading PDF document:', error);
                    showSnackbar?.(
                        'Failed to load PDF document.',
                        'Please try again later.',
                        'error'
                    );
                });
            const pdfBlob = new Blob([arrayBuffer], {
                type: 'application/pdf'
            });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfUrl);
        } else if (response.status === 404) {
            return;
        } else {
            showSnackbar?.(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export const GetFileUrlFromS3 = async (
    folderName: string,
    fileName: string | number,
    showSnackbar?: showSnackbar
) => {
    const response = await api.get(
        `/getFileUrlFromS3?folder=${encodeURIComponent(folderName)}&file=${encodeURIComponent(fileName)}`
    );
    if (response.status !== 200) {
        showSnackbar?.(
            'Whoops! Something went wrong on our end.',
            'Please contact your IT department.',
            'error'
        );
    } else {
        return response.data;
    }
};

export const GetFileUrlsFromS3Folder = async (
    folderName: string,
    showSnackbar: showSnackbar
) => {
    const response = await api.get(
        `/getFileUrlsFromS3Folder?folder=${encodeURIComponent(folderName)}`
    );
    if (response.status !== 200) {
        showSnackbar(
            'Whoops! Something went wrong on our end.',
            'Please contact your IT department.',
            'error'
        );
    } else {
        return response.data;
    }
};
