import { Grid, IconButton, TableRow, TextField } from '@mui/material';
import Autocomplete from '../../../global/Autocomplete';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Site } from '../../../global/interfaces/GeneralInterface';
import { StockTransfer } from '../../../global/interfaces/PartsInterface';
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import PartSearch from '../../../global/PartSearch';
import AddPartToStockTransfer from '../../../stock/stockTransferTable/logic/AddPartToStockTransfer';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import HandleRemoveParts from '../../../stock/stockTransferTable/logic/HandleRemoveParts';
import { Close } from '@mui/icons-material';
import SubmitTransfer from '../../../stock/stockTransferTable/logic/SubmitTransfer';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface StockTransferFromOrderContentDrawerProps {
    (props: {
        transferData: StockTransfer;
        setTransferData: Dispatch<SetStateAction<StockTransfer>>;
        showSnackbar: showSnackbar;
    }): JSX.Element;
}

const StockTransferFromOrderContentDrawer: StockTransferFromOrderContentDrawerProps =
    (props) => {
        const [siteListUnaltered, setSiteListUnaltered] = useState<Site[]>([]);
        const [siteList, setSiteList] = useState<Site[]>([]);
        const [buttonLoading, setButtonLoading] = useState<boolean>(false);

        useEffect(() => {
            GetAllSites(setSiteList);
            GetAllSites(setSiteListUnaltered);
        }, []);

        const columns = [
            { id: 0, label: 'Part Number' },
            { id: 1, label: 'Part Name', width: 250 },
            { id: 2, label: 'Qty Sent' },
            { id: 3, label: '' }
        ];

        const handleAddPart = (unitDetails) => {
            AddPartToStockTransfer(
                unitDetails,
                props.transferData,
                props.setTransferData
            );
        };

        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={siteList}
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            textfieldLabel="Transferred From"
                            selectedValue={props.transferData.SiteFrom}
                            handleSelectedValueChange={(event) => {
                                let currentOptions = siteListUnaltered.filter(
                                    (x) => x.id !== event.id
                                );
                                setSiteList(currentOptions);
                                props.setTransferData({
                                    ...props.transferData,
                                    SiteFrom: event
                                });
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={siteList}
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            textfieldLabel="Transferred To"
                            selectedValue={props.transferData.SiteTo}
                            handleSelectedValueChange={(event) => {
                                let currentOptions = siteListUnaltered.filter(
                                    (x) => x.id !== event.id
                                );
                                setSiteList(currentOptions);
                                props.setTransferData({
                                    ...props.transferData,
                                    SiteTo: event
                                });
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                        <PartSearch
                            HandleAdd={handleAddPart}
                            size="small"
                            isDisabled={
                                !props.transferData.SiteFrom.id ||
                                !props.transferData.SiteTo.id
                            }
                            SiteFrom={props.transferData.SiteFrom}
                            SiteTo={props.transferData.SiteTo}
                        />
                    </Grid>
                    <Grid item xs={12} />
                    {props.transferData.transferLines.length > 0 ? (
                        <Grid item xs={12}>
                            <DataTable columns={columns}>
                                {props.transferData.transferLines.map(
                                    (line, index) => (
                                        <TableRow>
                                            <DataCell>
                                                {line.partNumber}
                                            </DataCell>
                                            <DataCell>{line.name}</DataCell>
                                            <DataCell>
                                                <TextField
                                                    value={line.quantitySent}
                                                    size="small"
                                                    onChange={(e) => {
                                                        let newTransferLines = [
                                                            ...props
                                                                .transferData
                                                                .transferLines
                                                        ];
                                                        newTransferLines[
                                                            index
                                                        ].quantitySent =
                                                            parseInt(
                                                                e.target.value
                                                            );
                                                        props.setTransferData({
                                                            ...props.transferData,
                                                            transferLines:
                                                                newTransferLines
                                                        });
                                                    }}
                                                />
                                            </DataCell>
                                            <DataCell>
                                                <IconButton
                                                    onClick={() =>
                                                        HandleRemoveParts(
                                                            index,
                                                            props.transferData,
                                                            props.setTransferData
                                                        )
                                                    }
                                                >
                                                    <Close />
                                                </IconButton>
                                            </DataCell>
                                        </TableRow>
                                    )
                                )}
                            </DataTable>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <br />
                        <TextField
                            fullWidth
                            multiline
                            rows={5}
                            label="Notes"
                            InputLabelProps={{ shrink: true }}
                            value={props.transferData.notes}
                            onChange={(e) =>
                                props.setTransferData({
                                    ...props.transferData,
                                    notes: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <LoadingButton
                            loading={buttonLoading}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                SubmitTransfer(
                                    props.transferData,
                                    setButtonLoading,
                                    props.showSnackbar
                                );
                            }}
                        >
                            Submit Transfer
                        </LoadingButton>
                    </Grid>
                </Grid>
            </>
        );
    };

export default StockTransferFromOrderContentDrawer;
