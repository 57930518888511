import { Step } from '../../interface';

export const EditVehicleSalesSteps: Step[] = [
    {
        label: "Click 'Vehicle Sales' -> 'Sales' in the sidebar to navigate to the vehicle sales page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/edit-sale-01.png'
    },
    {
        label: "Click on the Vehicle Sale ID you wish to edit to access the sale's details page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/edit-sale-02.png'
    },
    {
        label: 'To edit the vehicle contract details, click the Edit button to enter the Edit mode.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/edit-sale-03.png'
    },
    {
        label: (
            <>
                You can now edit any field you wish, for more informations refer
                to{' '}
                <a
                    href="/support/vehicles/newContract/#add-vehicle-sale-contract"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Create a new Vehicle Sale Contract
                </a>{' '}
                documentation for more information. Depending on the Vehicle
                Sale status some fields may be locked (especially for Finalised
                Sale.)
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/edit-sale-04.png'
    },
    {
        label: 'Click the Save button after making all necessary changes to update the sale.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/edit-sale-05.png'
    }
];

export const SearchVehicleSalesSteps: Step[] = [
    {
        label: "Click 'Vehicle Sales' -> 'Sales' in the sidebar to navigate to the vehicle sales page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/search-vehicle-sales-01.png'
    },
    {
        label: 'Use the search bar to find a specific sale. Enter relevant keywords and press Enter on your keyboard. You can search by entering the stock number, VIN, make, model, or customer name in the search bar. ',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/search-vehicle-sales-02.png'
    },
    {
        label: 'Click the filter icon to open the filter dialog. You can filter vehicle sales by sale status, site, user (employee), start date, end date, and whether the sale is fully paid or not. You may use one filter or combine multiple filters to refine your search.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/search-vehicle-sales-03.png'
    }
];

export const ViewVehicleSaleLogSteps: Step[] = [
    {
        label: "Click 'Vehicle Sales' -> 'Sales' in the sidebar to navigate to the vehicle sales page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/edit-sale-01.png'
    },
    {
        label: "Click on the Sale ID you wish to see to access the sale's details page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/edit-sale-02.png'
    },
    {
        label: 'Click Logs in the speed dial menu, it will open a drawer show the history of changes made to the sale.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/view-sale-log-03.png'
    },
    {
        label: 'The logs includes details of the user who made the change, the date and time of the change, and the field that was updated.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/view-sale-log-04.png'
    }
];

export const TextCustomerSteps: Step[] = [
    {
        label: "Click 'Vehicle Sales' -> 'Sales' in the sidebar to navigate to the vehicle sales page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/edit-sale-01.png'
    },
    {
        label: "Click on a Sale ID to access the sale's details page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/edit-sale-02.png'
    },
    {
        label: 'Click Text Customer in the speed dial menu, it will open a drawer allow you to send a text message to customer. ',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/text-customer-03.png'
    },
    {
        label: 'The Customer name and phone number will be pre-filled. Select a template from the drop-down list or type a custom message.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/text-customer-04.png'
    },
    {
        label: 'Remember to edit the message as needed. Click on the Send Text button to send the message to the Customer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/sales/text-customer-05.png'
    }
];
