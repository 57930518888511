import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { ShippingMethod } from '../interfaces/PartsInterface';

const GetAllShippingMethods = (
  SiteId: number,
  setShippingMethods: Dispatch<SetStateAction<ShippingMethod[]>>
) => {
  api.get(`shippingMethods/${SiteId}`).then((res) => {
    setShippingMethods(res.data);
  });
};

export default GetAllShippingMethods;
