import DocumentationPage from '../DocumentationPage';
import {
    EditCustomerPartSalesSteps,
    EditVehicleContractPartSalesSteps,
    SalesCreditInvoiceSteps,
    SearchSalesSteps,
    TextCustomerPartSalesSteps,
    ViewPartSalesLogSteps
} from '../data/parts/sales';

const SalesSupportPage = () => {
    const contentSections = [
        {
            id: 'edit-sales',
            title: 'View and edit an existing order for customer',
            href: '/support/parts/sales/#edit-sales',
            description:
                'To view or edit details of an existing part sales for customer, follow these steps:',
            steps: EditCustomerPartSalesSteps
        },
        {
            id: 'edit-vehicle-contract-sales',
            title: 'View and edit an existing order for Vehicle Sale ',
            href: '/support/parts/sales/#edit-vehicle-contract-sales',
            description:
                'To view or edit details of an existing order for Vehicle Sale, follow these steps:',
            steps: EditVehicleContractPartSalesSteps
        },
        {
            id: 'search-sales',
            title: 'Search for Sales',
            href: '/support/parts/sales/#search-sales',
            description: 'To search or filter sales, follow these steps:',
            steps: SearchSalesSteps
        },
        {
            id: 'sales-credit-invoice',
            title: 'Credit/Refund a Sale or a Proforma',
            href: '/support/parts/sales/#sales-credit-invoice',
            description:
                'To credit/refund a Sale or a Proforma, follow these steps:',
            steps: SalesCreditInvoiceSteps
        },
        {
            id: 'view-part-sales-log',
            title: 'View an order update history',
            href: '/support/parts/sales/#view-part-sales-log',
            description:
                'To view the update history of an existing order, follow these steps:',
            steps: ViewPartSalesLogSteps
        },
        {
            id: 'text-customer-part-sales',
            title: 'Text a customer about their order',
            href: '/support/parts/sales/#text-customer-part-sales',
            description: 'To text customer about an order, follow these steps:',
            steps: TextCustomerPartSalesSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Sales"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default SalesSupportPage;
