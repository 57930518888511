// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Settings, Site } from '../interfaces/GeneralInterface';

/**
 * GetCompanyDetails
 * Gets the details of a given company
 * @author Sienna
 * @param SiteId the ID of the site within the company
 * @param setSettings state setter for company details
 * @param setSite state setter for site data
 */
const GetCompanyDetails = (
  SiteId: number,
  setSettings: Dispatch<SetStateAction<Settings[]>>,
  setSite?: Dispatch<SetStateAction<Site>>
) => {
  api.get(`settingsNextGen/${SiteId}`).then((res) => {
    setSettings(res.data.settings);
    if (setSite) setSite(res.data.site);
  });
};

export default GetCompanyDetails;
