import api from '../../../../../api';
import {
    VehicleRebateLine,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const SubmitRebateReconciliation = (
    reconciledRebateList: VehicleRebateLine[],
    reconciledVehicleSaleList: VehicleSale[],
    showSnackbar: showSnackbar
) => {
    let totalRebate = 0;
    let totalVehicleSale = 0;
    let rebateVehicleIds = [];
    let vehicleSaleVehicleIds = [];

    for (let rebate of reconciledRebateList) {
        totalRebate += rebate.amount;
        rebateVehicleIds.push(rebate.Vehicle.id);
    }

    for (let sale of reconciledVehicleSaleList) {
        totalVehicleSale += parseFloat(sale.rebatePrice);
        vehicleSaleVehicleIds.push(sale.VehicleId);
    }

    if (totalRebate !== totalVehicleSale) {
        showSnackbar(
            'Something went wrong.',
            'There is a difference between the rebate total and the vehicle sales total rebate price.',
            'error'
        );
        return;
    }

    if (
        rebateVehicleIds.sort().join(',') !==
        vehicleSaleVehicleIds.sort().join(',')
    ) {
        showSnackbar(
            'Something went wrong.',
            'There is a difference between the vehicles selected for rebate and the vehicles selected for vehicle sales',
            'error'
        );
        return;
    }

    api.post('rebateReconciliation', { rebateList: rebateVehicleIds }).then(
        (res) => {
            if (res.status === 200) {
                showSnackbar('Reconciliation successfully submitted');
                window.location.reload();
            }
        }
    );
};

export default SubmitRebateReconciliation;
