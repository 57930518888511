import moment from 'moment';
import { saveAs } from 'file-saver';
import api from '../../../../../../api';
import CalculateStockOrderTotalCost from '../CalculateStockOrderTotalCost';

import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Api } from '../../../../global/interfaces/GeneralInterface';
interface Table {
    OrderType: string;
    orderLines: TableOrderLine[];
    orderReference: string;
    supplierId: number;
    supplierName: string;
    tableId: number;
    totalCost: number;
}

interface TableOrderLine {
    OrderType: string; // probably not useful
    costPrice: number;
    gst: number;
    id: number;
    maxStock: number; // probably not used anymore
    minStock: number; // probably not used anymore
    name: string;
    partNumber: string;
    qtyOnHand: number;
    quantity: number;
    rowId: number;
    rowTotal: number;
    rrp: number;
    selected: boolean;
    supplier: any; // don't know what it's for

    linkedOrders?: number[];
    linkedOrdersQuantity?: number[];
    orderId?: number;
    costPriceDaily?: number;
    costPriceAverage?: number;
    supplierName?: string;
}

const GenerateYamahaOrderFile = (
    obj: { generator: Api; table: Table },
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    showSnackbar: showSnackbar
) => {
    let currentTable = obj.table;
    let generator = obj.generator;
    api.get(`site/${generator.SiteId}`).then((res) => {
        let today = moment();
        let spaceChar = ' ';
        let zeroChar = '0';
        let trimmedOrderRef = currentTable.orderReference.substring(0, 15);

        // Header
        let textFile = `1${generator.additionalDetails.dealerCode}${spaceChar.repeat(4)}${trimmedOrderRef}${spaceChar.repeat(15 - trimmedOrderRef.length)}${today.format('YYYYMMDD')}${currentTable.OrderType === 'stock' ? 'S' : 'D'}${spaceChar.repeat(20)}${spaceChar.repeat(8)}${spaceChar.repeat(25)}${spaceChar.repeat(25)}N${spaceChar.repeat(
            3
        )}${spaceChar.repeat(33)}\n`;

        // Address Override
        // textFile = textFile.concat(`A${generator.additionalDetails.dealerCode}${spaceChar.repeat(4)}${trimmedOrderRef}${spaceChar.repeat(15 - trimmedOrderRef.length)}${siteData.Setting.companyName}${spaceChar.repeat(30 - siteData.Setting.companyName.length)}${siteData.address}${spaceChar.repeat(25 - siteData.address.length)}${siteData.suburb + ' ' + siteData.state}${spaceChar.repeat(25 - (siteData.suburb + ' ' + siteData.state).length)}${spaceChar.repeat(25)}${siteData.postcode}${spaceChar.repeat(15)}\n`)

        let totalQty = 0;
        // Order Details Line
        for (let line of currentTable.orderLines) {
            textFile = textFile.concat(
                `2${generator.additionalDetails.dealerCode}${spaceChar.repeat(4)}${trimmedOrderRef}${spaceChar.repeat(15 - trimmedOrderRef.length)}${line.partNumber}${spaceChar.repeat(20 - line.partNumber.length)}${zeroChar.repeat(7 - line.quantity.toString().length)}${line.quantity.toString()}${spaceChar.repeat(97)}\n`
            );
            totalQty += line.quantity;
        }

        // Order Trailer Record
        textFile = textFile.concat(
            `9${generator.additionalDetails.dealerCode}${spaceChar.repeat(4)}${trimmedOrderRef}${spaceChar.repeat(15 - trimmedOrderRef.length)}${zeroChar.repeat(5 - currentTable.orderLines.length.toString().length)}${currentTable.orderLines.length}${zeroChar.repeat(9 - totalQty.toString().length)}${totalQty}${spaceChar.repeat(110)}`
        );

        // Save the file
        const file = new Blob([textFile], { type: 'text/plain' });
        saveAs(
            file,
            `YAMAHA_ORDER${trimmedOrderRef ? `_${trimmedOrderRef}` : ''}_${today.format('YYMMDDhhmmss')}.001`
        );

        // Remove the generated order, so that the user can still submit the others orders without redoing the whole stock order
        let newTables = [];
        let orderedTables = [];
        tables.forEach((table) => {
            if (
                table.supplierName !== currentTable.supplierName ||
                table.OrderType !== currentTable.OrderType
            ) {
                newTables.push(table);
            } else {
                orderedTables.push(table);
            }
        });
        setTables(newTables);

        // Send the Stock Order to the database
        var postObj = {
            tables: orderedTables,
            totalCost: CalculateStockOrderTotalCost(orderedTables).toFixed(2),
            SiteId: localStorage.getItem('SiteId'),
            generated: false,
            txtGenerated: true
        };

        api.post(`stockorder?emailed=false`, postObj).then((res) => {
            if (res.status === 200) {
                showSnackbar('Order file generated');
            } else {
                showSnackbar(
                    'Order not submitted. Please check your data or try again later.',
                    '',
                    'error'
                );
            }
        });
    });
};
export default GenerateYamahaOrderFile;
