// IMPORTS
import { useState, useEffect } from 'react';
import {
    CircularProgress,
    Grid,
    IconButton,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import Fab from '../../global/Fab';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import GetRegoReconciliationLines from './logic/GetRegoReconciliationLines';
import SubmitRegoReconciliation from './logic/SubmitRegoReconciliation';
// INTERFACES
import {
    VehicleRegistrationLine,
    VehicleSale
} from '../../global/interfaces/VehicleSaleInterface';
import { CurrencyFormatter } from '../../global/logic/Formatters';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Clear } from '@mui/icons-material';

interface RegistrationData {
    Registration?: VehicleRegistrationLine;
    VehicleSale?: VehicleSale;
}

const RegoReconciliation = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [registrationData, setRegistrationData] = useState<
        RegistrationData[]
    >([]);
    const [reconciledRegistrationData, setReconciledRegistrationData] =
        useState<RegistrationData[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const adminColumns = [
        { id: 0, label: 'Vehicle ID', width: 100 },
        { id: 1, label: 'Vehicle' },
        { id: 2, label: 'Customer' },
        { id: 3, label: 'Line Reference' },
        { id: 4, label: 'Admin Amount', width: 150 },
        { id: 5, label: 'Sale Amount', width: 150 },
        { id: 6, label: '', width: 100 }
    ];

    useEffect(() => {
        GetRegoReconciliationLines(
            setRegistrationData,
            setReconciledRegistrationData,
            setLoading
        );
    }, []);

    const getRegistrationPrice = (row) => {
        if (!row.VehicleSale?.includesOnRoadCosts) {
            if (row.VehicleSale?.excludeStampDuty) {
                return (
                    parseFloat(row.VehicleSale?.transferPrice) +
                    parseFloat(row.VehicleSale?.registrationPrice) +
                    parseFloat(row.VehicleSale?.ctpPrice)
                );
            } else {
                return (
                    parseFloat(row.VehicleSale?.stampDutyPrice) +
                    parseFloat(row.VehicleSale?.transferPrice) +
                    parseFloat(row.VehicleSale?.registrationPrice) +
                    parseFloat(row.VehicleSale?.ctpPrice)
                );
            }
        } else {
            return 0;
        }
    };

    const markAsReconciled = (row) => {
        let currentUnreconciled = [...registrationData];
        let currentReconciled = [...reconciledRegistrationData];

        if (row.Registration?.amount === getRegistrationPrice(row)) {
            currentReconciled.unshift(row);
            currentUnreconciled = currentUnreconciled.filter(
                (x) => x.Registration.id !== row.Registration.id
            );

            setReconciledRegistrationData(currentReconciled);
            setRegistrationData(currentUnreconciled);
        } else {
            showSnackbar(
                "Whoops! Can't move this line to reconciled!",
                'The admin amount does not match the sale amount.',
                'warning'
            );
        }
    };

    const markAsUnreconciled = (row) => {
        let currentUnreconciled = [...registrationData];
        let currentReconciled = [...reconciledRegistrationData];

        currentUnreconciled.unshift(row);
        currentReconciled = currentReconciled.filter(
            (x) => x.Registration.id !== row.Registration.id
        );

        setReconciledRegistrationData(currentReconciled);
        setRegistrationData(currentUnreconciled);
    };

    const clearList = (type) => {
        let currentUnreconciled = [...registrationData];
        let currentReconciled = [...reconciledRegistrationData];

        if (type === 'reconciled') {
            currentReconciled.forEach((item) => {
                currentUnreconciled.unshift(item);
            });

            currentReconciled = [];
        } else {
            currentUnreconciled.forEach((item) => {
                currentReconciled.unshift(item);
            });

            currentUnreconciled = [];
        }

        setReconciledRegistrationData(currentReconciled);
        setRegistrationData(currentUnreconciled);
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Rego Reconciliator</Typography>
            <br />
            {loading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : (
                <>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography variant="h5">RECONCILED</Typography>
                                <br />
                            </Grid>
                            <Grid item xs={2} textAlign="right">
                                <Tooltip title="Mark as Reconciled">
                                    <IconButton
                                        onClick={() => clearList('reconciled')}
                                    >
                                        <Clear />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <DataTable columns={adminColumns}>
                            {reconciledRegistrationData.map((row) => (
                                <TableRow>
                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(
                                                `/vehicles/view/${row.Registration?.VehicleId}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        {row.Registration?.VehicleId}
                                    </DataCellColoured>
                                    <DataCell>
                                        {row.Registration?.Vehicle?.year}{' '}
                                        {row.Registration?.Vehicle?.Make?.name}{' '}
                                        {row.Registration?.Vehicle?.Model?.name}{' '}
                                        {
                                            row.Registration?.Vehicle?.Series
                                                ?.name
                                        }
                                    </DataCell>
                                    {row.VehicleSale?.id ? (
                                        <DataCell>
                                            {row.VehicleSale?.Customer
                                                ?.companyName
                                                ? row.VehicleSale?.Customer?.companyName.toUpperCase()
                                                : row.VehicleSale?.Customer?.firstName.toUpperCase() +
                                                  ' ' +
                                                  row.VehicleSale?.Customer?.lastName.toUpperCase()}
                                        </DataCell>
                                    ) : (
                                        <DataCell>
                                            <b>No sale assigned to this bike</b>
                                        </DataCell>
                                    )}
                                    <DataCell>
                                        {row.Registration?.description}
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            row.Registration?.amount
                                        )}
                                    </DataCell>
                                    {row.VehicleSale?.id ? (
                                        <DataCell>
                                            {CurrencyFormatter(
                                                getRegistrationPrice(row)
                                            )}
                                        </DataCell>
                                    ) : (
                                        <DataCell>
                                            {CurrencyFormatter(0)}
                                        </DataCell>
                                    )}
                                    <DataCell>
                                        <Tooltip title="Mark as Reconciled">
                                            <IconButton
                                                onClick={() =>
                                                    markAsUnreconciled(row)
                                                }
                                            >
                                                <ExpandMore />
                                            </IconButton>
                                        </Tooltip>
                                    </DataCell>
                                </TableRow>
                            ))}
                        </DataTable>
                    </Paper>
                    <br />
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography variant="h5">
                                    UNRECONCILED
                                </Typography>
                                <br />
                            </Grid>
                            <Grid item xs={2} textAlign="right">
                                <Tooltip title="Mark as Reconciled">
                                    <IconButton
                                        onClick={() => clearList('reconciled')}
                                    >
                                        <Clear />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <DataTable columns={adminColumns}>
                            {registrationData.map((row) => (
                                <TableRow
                                    selected={
                                        row.Registration?.amount ===
                                        getRegistrationPrice(row)
                                    }
                                >
                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(
                                                `/vehicles/view/${row.Registration?.VehicleId}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        {row.Registration?.VehicleId}
                                    </DataCellColoured>
                                    <DataCell>
                                        {row.Registration?.Vehicle?.year}{' '}
                                        {row.Registration?.Vehicle?.Make?.name}{' '}
                                        {row.Registration?.Vehicle?.Model?.name}{' '}
                                        {
                                            row.Registration?.Vehicle?.Series
                                                ?.name
                                        }
                                    </DataCell>
                                    {row.VehicleSale?.id ? (
                                        <DataCell>
                                            {row.VehicleSale?.Customer
                                                ?.companyName
                                                ? row.VehicleSale?.Customer
                                                      ?.companyName
                                                : row.VehicleSale?.Customer?.firstName.toUpperCase() +
                                                  ' ' +
                                                  row.VehicleSale?.Customer?.lastName.toUpperCase()}
                                        </DataCell>
                                    ) : (
                                        <DataCell>
                                            <b>No sale assigned to this bike</b>
                                        </DataCell>
                                    )}
                                    <DataCell>
                                        {row.Registration?.description}
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            row.Registration?.amount
                                        )}
                                    </DataCell>
                                    {row.VehicleSale?.id ? (
                                        <DataCell>
                                            {CurrencyFormatter(
                                                getRegistrationPrice(row)
                                            )}
                                        </DataCell>
                                    ) : (
                                        <DataCell>
                                            {CurrencyFormatter(0)}
                                        </DataCell>
                                    )}
                                    <DataCell>
                                        <Tooltip title="Mark as Reconciled">
                                            <IconButton
                                                onClick={() =>
                                                    markAsReconciled(row)
                                                }
                                            >
                                                <ExpandLess />
                                            </IconButton>
                                        </Tooltip>
                                    </DataCell>
                                </TableRow>
                            ))}
                        </DataTable>
                    </Paper>
                </>
            )}

            <Fab
                editIcon={false}
                onClick={() =>
                    SubmitRegoReconciliation(
                        reconciledRegistrationData,
                        showSnackbar,
                        setLoading
                    )
                }
            />
        </PageWrapper>
    );
};

export default withSnackbar(RegoReconciliation);
