import api from '../../../../../api';

const HandleResultsList = (resultsList, setResultsList) => {
    api.get('apiWithSupplierNextGen').then((res) => {
        let updatedStockOrder = [];
        for (let stockOrder of resultsList) {
            for (let api of res.data) {
                if (
                    stockOrder.SupplierId === api.SupplierId &&
                    stockOrder.SiteId === api.SiteId
                ) {
                    stockOrder.Api = api;
                }
            }
            updatedStockOrder.push(stockOrder);
        }
        setResultsList(updatedStockOrder);
    });
};

export default HandleResultsList;
