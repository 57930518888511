// IMPORTS
import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Grid } from '@mui/material';

// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';

// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';

interface BreakdownTableFilterProps {
    vehicleSaleData: any;
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    dataType: string;
}

interface Option {
    id: number;
    name: string;
}

const conditionOptions = [
    { id: 0, label: 'New', value: 'New' },
    { id: 1, label: 'Used', value: 'Used' },
    { id: 2, label: 'Demo', value: 'Demo' }
];

const BreakdownTableFilter = ({
    vehicleSaleData,
    filter,
    setFilter,
    dataType
}: BreakdownTableFilterProps) => {
    const [makesUnaltered, setMakesUnaltered] = useState<Option[]>([]);
    const [modelsUnaltered, setModelsUnaltered] = useState<Option[]>([]);
    const [usersUnaltered, setUsersUnaltered] = useState<Option[]>([]);

    const [makeOptions, setMakeOptions] = useState<Option[]>([]);
    const [modelOptions, setModelOptions] = useState<Option[]>([]);
    const [userOptions, setUserOptions] = useState<Option[]>([]);

    const [condition, setCondition] = useState(conditionOptions);

    useEffect(() => {
        const makes = [];
        vehicleSaleData?.forEach((sale) => {
            if (!makes.some((make) => make.id === sale.vehicleMakeId)) {
                makes.push({
                    id: sale.vehicleMakeId,
                    name: sale.vehicleMake
                });
            }
        });
        setMakesUnaltered(makes);
        setMakeOptions(makes);

        const models = [];
        vehicleSaleData?.forEach((sale) => {
            if (!models.some((model) => model.id === sale.vehicleModelId)) {
                models.push({
                    id: sale.vehicleModelId,
                    name: sale.vehicleModel
                });
            }
        });
        setModelsUnaltered(models);
        setModelOptions(models);

        const users = [];
        vehicleSaleData?.forEach((sale) => {
            if (!users.some((user) => user.id === sale.UserId)) {
                users.push({
                    id: sale.UserId,
                    name: sale.userName
                });
            }
        });
        setUsersUnaltered(users);
        setUserOptions(users);
        // eslint-disable-next-line
    }, [vehicleSaleData]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.vehicleSaleBreakdownTableFilter;
        newFilter[property] = value;
        setFilter({ ...filter, vehicleSaleBreakdownTableFilter: newFilter });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={dataType === 'manufacturer' ? 6 : 4}>
                {/* User Filter */}
                <AutocompleteMultiple
                    options={userOptions}
                    useTwoOptionLabels={false}
                    primaryOptionLabel={'name'}
                    textfieldLabel="User"
                    selectedValues={filter.vehicleSaleBreakdownTableFilter.user}
                    handleSelectedValueChange={(event) => {
                        let currentOptions = usersUnaltered;
                        let newFilterValue = [];

                        // Add or remove the value from the selected value
                        // Also add or remove the selected option from the options list so it can not be added more than once
                        event.forEach((val, index) => {
                            newFilterValue.push(val);
                            currentOptions = currentOptions.filter(
                                (x) => x.id !== val.id
                            );
                        });

                        setUserOptions(currentOptions);
                        handleUpdateFilter('user', newFilterValue);
                    }}
                    handleInputValueChange={() => null}
                />
            </Grid>

            <Grid item xs={3}>
                {/* Make Filter */}
                <AutocompleteMultiple
                    options={makeOptions}
                    useTwoOptionLabels={false}
                    primaryOptionLabel={'name'}
                    textfieldLabel="Make"
                    selectedValues={filter.vehicleSaleBreakdownTableFilter.make}
                    handleSelectedValueChange={(event) => {
                        let currentOptions = makesUnaltered;
                        let newFilterValue = [];

                        // Add or remove the value from the selected value
                        // Also add or remove the selected option from the options list so it can not be added more than once
                        event.forEach((val, index) => {
                            newFilterValue.push(val);
                            currentOptions = currentOptions.filter(
                                (x) => x.id !== val.id
                            );
                        });

                        setMakeOptions(currentOptions);
                        handleUpdateFilter('make', newFilterValue);
                    }}
                    handleInputValueChange={() => null}
                />
            </Grid>

            <Grid item xs={3}>
                {/* Models*/}
                <AutocompleteMultiple
                    options={modelOptions}
                    useTwoOptionLabels={false}
                    primaryOptionLabel={'name'}
                    textfieldLabel="Model"
                    selectedValues={
                        filter.vehicleSaleBreakdownTableFilter.model
                    }
                    handleSelectedValueChange={(event) => {
                        let currentOptions = modelsUnaltered;
                        let newFilterValue = [];

                        // Add or remove the value from the selected value
                        // Also add or remove the selected option from the options list so it can not be added more than once
                        event.forEach((val, index) => {
                            newFilterValue.push(val);
                            currentOptions = currentOptions.filter(
                                (x) => x.id !== val.id
                            );
                        });

                        setModelOptions(currentOptions);
                        handleUpdateFilter('model', newFilterValue);
                    }}
                    handleInputValueChange={() => null}
                />
            </Grid>

            {dataType !== 'manufacturer' ? (
                <Grid item xs={2}>
                    {/* Bike Condition Filter */}
                    <AutocompleteMultiple
                        options={conditionOptions}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Condition"
                        selectedValues={
                            filter.vehicleSaleBreakdownTableFilter.condition
                        }
                        handleSelectedValueChange={(event) => {
                            let currentOptions = condition;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setCondition(currentOptions);
                            handleUpdateFilter('condition', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
};

export default BreakdownTableFilter;
