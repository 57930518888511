import DocumentationPage from '../DocumentationPage';
import { ServicePaymentSteps } from '../data/service/payment';

const ServicePaymentSupportPage = () => {
    const contentSections = [
        {
            id: 'service-payment',
            title: 'Service Payment',
            href: '/support/service/payment/#service-payment',
            description:
                'To finalise payment for a service booking, follow these outlined steps:',
            steps: ServicePaymentSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Payment"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default ServicePaymentSupportPage;
