import DocumentationPage from '../DocumentationPage';
import { TimeClockSteps } from '../data/admin/timeclock';

const TimeClockSupportPage = () => {
    const contentSections = [
        {
            id: 'time-clock',
            title: 'Time Clock',
            href: '/support/admin/timeclock/#time-clock',
            description:
                'To clock on/off and view time clock history records, follow these steps:',
            steps: TimeClockSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Time Clock"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default TimeClockSupportPage;
