import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Stock, Unit } from '../../../global/interfaces/PartsInterface';
import api from '../../../../../api';

const MergeParts = (
    partToDelete: { unitData: Unit; stockData: Stock },
    partToMergeInto: { unitData: Unit; stockData: Stock },
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    api.post('/mergeParts', {
        partToDelete: partToDelete,
        partToMergeInto: partToMergeInto
    }).then((res) => {
        if (res.status === 200) {
            setButtonLoading(false);
            showSnackbar('Parts successfully merged.');
        } else {
            showSnackbar(
                'Something went wrong.',
                'Try again later, or contact your IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default MergeParts;
