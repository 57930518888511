// IMPORTS
import { useEffect, useState } from 'react';
import {
    Button,
    Chip,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress
} from '@mui/material';
import { Edit } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
// COMPONENTS
import Details from './components/Details/Details';
import DebtorTab from './components/DebtorTab';
import PageWrapper from '../../global/PageWrapper';
import SalesTab from './components/SalesTab';
import Tabs from '../../global/Tabs';
import Paper from '../../global/Paper';
// LOGIC
import GetCustomer from '../../global/databaseLogic/GetCustomer';
import HandleUpdateCustomer from './logic/HandleUpdateCustomer';
// INTERFACES
import { Customer } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import PageDoesNotExist from '../../global/PageDoesNotExist';
import RoleCheck from '../../global/RoleCheck';
import { withSnackbar } from '../../global/WrappingSnackbar';

// Default customer parameters
// for 'Add Customer'
const clearedCustomer = {
    firstName: null,
    lastName: null,
    email: null,
    customerType: 'Retail',
    addressLine1: null,
    addressLine2: null,
    suburb: null,
    postcode: null,
    state: null,
    country: null,
    phoneNumber: null,
    companyName: null,
    abn: null,
    dateOfBirth: null,
    driverLicenceNumber: null,
    debtor: false,
    gstFree: false,
    international: false,
    discountType: 'Retail'
};

/**
 * ViewCustomer
 * The overarching page for ViewCustomer
 * @author Estienne
 * @returns All content for ViewCustomer
 */
const ViewCustomer = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const [customerInfo, setCustomerInfo] = useState<Customer>(clearedCustomer);

    const [disabled, setDisabled] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invalid, setInvalid] = useState(false);

    // A customer's basic details
    const basicTab = {
        id: 0,
        title: 'Details',
        content: (
            <Details
                customerInfo={customerInfo}
                setCustomerInfo={setCustomerInfo}
                disabled={disabled}
                setDisabled={setDisabled}
                showSnackbar={showSnackbar}
            />
        )
    };

    // A customer's sales and proformas
    const salesTab = {
        id: 1,
        title: 'Sales/Proformas',
        content: <SalesTab />
    };

    // A debtor's account terms and payments
    const debtorTab = {
        id: 2,
        title: 'Account Terms & Payments',
        content: (
            <DebtorTab
                customerInfo={customerInfo}
                setCustomerInfo={setCustomerInfo}
                showSnackbar={showSnackbar}
            />
        )
    };

    // Only show the debtor tab if a customer is a debtor
    const tabContent = id
        ? customerInfo?.debtor
            ? [basicTab, salesTab, debtorTab]
            : [basicTab, salesTab]
        : [basicTab];

    // Get the customer's information
    useEffect(() => {
        if (id)
            GetCustomer(
                id,
                setCustomerInfo,
                setLoading,
                showSnackbar,
                setInvalid
            );
        else setDisabled(false);
        // eslint-disable-next-line
    }, []);

    return (
        <PageWrapper>
            {loading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4">
                        {id
                            ? `${customerInfo?.firstName} ${customerInfo?.lastName}`
                            : 'Add Customer'}{' '}
                        {id ? (
                            <Chip label={'ID: ' + customerInfo?.id}></Chip>
                        ) : (
                            ''
                        )}
                    </Typography>
                    {id ? (
                        <RoleCheck
                            permission={'create_debtor'}
                            component={
                                <Chip
                                    variant={
                                        customerInfo?.debtor
                                            ? 'filled'
                                            : 'outlined'
                                    }
                                    label={
                                        customerInfo?.debtor
                                            ? 'Debtor'
                                            : 'Customer'
                                    }
                                    deleteIcon={<Edit />}
                                    onDelete={() => {
                                        setOpenDialog(true);
                                    }}
                                    color="primary"
                                />
                            }
                            nullComponent={
                                <Chip
                                    variant={
                                        customerInfo?.debtor
                                            ? 'filled'
                                            : 'outlined'
                                    }
                                    label={
                                        customerInfo?.debtor
                                            ? 'Debtor'
                                            : 'Customer'
                                    }
                                    color="primary"
                                />
                            }
                        />
                    ) : (
                        ''
                    )}

                    <Tabs tabContent={tabContent} />
                </>
            )}

            <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
                <DialogTitle>
                    {customerInfo?.debtor
                        ? 'Remove this debtor?'
                        : 'Make this customer a debtor?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {customerInfo?.debtor
                            ? 'Do you wish to revoke this debtor? By doing this they will no longer be able to make put purchases on their account to pay off.'
                            : 'Do you wish to make this customer a debtor? By doing this they will be given an account limit of $0 by default. This can be changed later.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setOpenDialog(false);
                            HandleUpdateCustomer({
                                customerInfo: customerInfo,
                                updateDebtor: true,
                                newDebtorStatus: !customerInfo.debtor,
                                showSnackbar: showSnackbar,
                                setReadOnly: setDisabled
                            });
                        }}
                    >
                        {customerInfo?.debtor
                            ? 'Remove Debtor'
                            : 'Make Customer Debtor'}
                    </Button>
                </DialogActions>
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(ViewCustomer);
