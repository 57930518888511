import { CurrencyFormatter, ToFixed } from '../../../global/logic/Formatters';
import { Typography } from '@mui/material';

const GetDifferenceString = (line, returnString?) => {
  if (line) {
    let difference =
      (line.actual ? ToFixed(parseFloat(line.actual)) : 0) -
      ToFixed(parseFloat(line.expected));

    if (difference > 0) {
      return (
        <Typography variant="caption" sx={{ color: '#ED6C02' }}>
          ⬆{CurrencyFormatter(ToFixed(difference))}
        </Typography>
      );
    } else if (difference < 0) {
      return (
        <Typography variant="caption" sx={{ color: '#D32F2F' }}>
          ⬇{CurrencyFormatter(ToFixed(difference))}
        </Typography>
      );
    } else {
      return (
        <Typography variant="caption" sx={{ color: '#808080' }}>
          {CurrencyFormatter(ToFixed(difference))}
        </Typography>
      );
    }
  } else {
    return '$0.00';
  }
};

export default GetDifferenceString;
