// IMPORTS
import { useState, useEffect } from 'react';
import { Card, CardHeader, Avatar, IconButton, Tooltip } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { Site, User } from '../../global/interfaces/GeneralInterface';
import GetCurrentSite from '../../global/logic/GetCurrentSite';
import GetUser from '../../global/databaseLogic/GetUser';
import { TitleCaseFormatter } from '../../global/logic/Formatters';

const AvatarCard = () => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [currentSite, setCurrentSite] = useState<Site>();

  useEffect(() => {
    GetCurrentSite(parseInt(localStorage.getItem('SiteId')), setCurrentSite);
    GetUser(0, setCurrentUser);
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  return (
    <>
      <Card elevation={0}>
        <CardHeader
          title={TitleCaseFormatter(
            currentUser?.firstName + ' ' + currentUser?.lastName
          )}
          titleTypographyProps={{ variant: 'body2' }}
          subheader={`${currentUser?.username} | ${currentSite?.name}`}
          subheaderTypographyProps={{ variant: 'caption' }}
          avatar={
            <Avatar>
              {currentUser?.firstName?.charAt(0)?.toUpperCase()}
            </Avatar>
          }
          action={
            <Tooltip title="Log Out" placement="left">
              <IconButton onClick={handleLogOut}>
                <Logout />
              </IconButton>
            </Tooltip>
          }
        />
      </Card>
    </>
  );
};

export default AvatarCard;
