import { Dispatch, SetStateAction, useState } from 'react';

// MUI
import {
    IconButton,
    Pagination,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import {
    CheckCircle,
    RadioButtonUnchecked,
    Reorder
} from '@mui/icons-material';

// COMPONENT
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import Drawer from '../../../global/Drawer';

// INTERFACE
import { StockOrder } from '../../../global/interfaces/PartsInterface';
interface Props {
    listOfStockOrder: StockOrder[];
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    selectedStockOrders: number[];
    setSelectedStockOrders: Dispatch<SetStateAction<number[]>>;
    setIsMultipleInvoice: Dispatch<SetStateAction<boolean>>;
    setMultipleInvoiceData;
}

const ImportStockOrderDialogContent = (props: Props) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [selectedStockOrder, setSelectedStockOrder] = useState<StockOrder>();

    const handlePageChange = (event: any, value: number) => {
        props.setPage(value);
    };

    const handleOpenOrderLines = (stockOrder: StockOrder) => {
        setSelectedStockOrder(stockOrder);
        setDrawerOpen(true);
    };

    const handleSelectStockOrder = (rowId: number, type: string) => {
        let currentSelectedStockOrder = [...props.selectedStockOrders];
        if (type === 'add') {
            currentSelectedStockOrder.push(rowId);
            props.setSelectedStockOrders(currentSelectedStockOrder);
        } else {
            currentSelectedStockOrder = currentSelectedStockOrder.filter(
                (x) => x !== rowId
            );
            props.setSelectedStockOrders(currentSelectedStockOrder);
        }
    };

    const stockOrderDialogColumns = [
        { id: 0, label: '', width: 50 },
        { id: 1, label: 'ID', width: 140 },
        { id: 2, label: 'Reference', width: 200 },
        { id: 3, label: 'Number of Parts', width: 140 },
        { id: 4, label: '', width: 100 }
    ];

    const orderLines = [
        { id: 0, label: 'Part Number' },
        { id: 1, label: 'Name' },
        { id: 2, label: 'Quantity' }
    ];

    return (
        <>
            <Pagination
                count={Math.ceil(props.listOfStockOrder.length / 8)}
                page={props.page}
                onChange={handlePageChange}
            />
            <DataTable columns={stockOrderDialogColumns}>
                {props.listOfStockOrder
                    .slice((props.page - 1) * 8, props.page * 8)
                    .map((stockOrder) => (
                        <TableRow>
                            <DataCell>
                                {props.selectedStockOrders.includes(
                                    stockOrder.id
                                ) ? (
                                    <IconButton
                                        onClick={() =>
                                            handleSelectStockOrder(
                                                stockOrder.id,
                                                'remove'
                                            )
                                        }
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() =>
                                            handleSelectStockOrder(
                                                stockOrder.id,
                                                'add'
                                            )
                                        }
                                    >
                                        <RadioButtonUnchecked />
                                    </IconButton>
                                )}
                            </DataCell>
                            <DataCell>{stockOrder.id}</DataCell>
                            {stockOrder.orderReference.length > 30 ? (
                                <DataCell>
                                    <Tooltip title={stockOrder.orderReference}>
                                        <Typography>{`${stockOrder.orderReference.substring(0, 30)}...`}</Typography>
                                    </Tooltip>
                                </DataCell>
                            ) : (
                                <DataCell>
                                    <Typography>
                                        {stockOrder.orderReference}
                                    </Typography>
                                </DataCell>
                            )}

                            <DataCell>{stockOrder.orderLines.length}</DataCell>
                            <DataCell>
                                <IconButton
                                    onClick={() => {
                                        handleOpenOrderLines(stockOrder);
                                    }}
                                >
                                    <Reorder />
                                </IconButton>
                            </DataCell>
                        </TableRow>
                    ))}
            </DataTable>

            <Drawer
                openDrawer={drawerOpen}
                setOpenDrawer={setDrawerOpen}
                title={'Stock Orderlines'}
                subTitle={`Stock Order #${selectedStockOrder?.id}`}
            >
                <DataTable columns={orderLines}>
                    {selectedStockOrder?.orderLines.map((line) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        '/inventory/view/' + line.id,
                                        '_blank'
                                    )
                                }
                            >
                                {line.partNumber}
                            </DataCellColoured>
                            <DataCell>{line.name}</DataCell>
                            <DataCell>{line.quantity}</DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Drawer>
        </>
    );
};

export default ImportStockOrderDialogContent;
