import { Typography, Grid } from '@mui/material';
import Paper from '../../../global/Paper';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import DataChangeIndicator from '../../../global/dashboard/DataChangeIndicator';
import { FinancialOverviewProps } from '../../../global/dashboard/interface';

const BookingRevenueOverview = ({
    selectedMonthFinalisedRevenue,
    selectedMonthFinalisedCost,
    comparedMonthFinalisedRevenue,
    comparedMonthFinalisedCost,
    compareStartDate,
    compareEndDate
}: FinancialOverviewProps) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">
                            Total Finalised Revenue
                        </Typography>
                        <Typography variant="h5">
                            {CurrencyFormatter(selectedMonthFinalisedRevenue)}
                        </Typography>
                        {
                            <DataChangeIndicator
                                currentValue={selectedMonthFinalisedRevenue}
                                previousValue={comparedMonthFinalisedRevenue}
                                category="revenue"
                                dataType="currency"
                                compareStartDate={compareStartDate}
                                compareEndDate={compareEndDate}
                            />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">
                            Total Finalised Cost
                        </Typography>
                        <Typography variant="h5">
                            {CurrencyFormatter(selectedMonthFinalisedCost)}
                        </Typography>
                        {
                            <DataChangeIndicator
                                currentValue={selectedMonthFinalisedCost}
                                previousValue={comparedMonthFinalisedCost}
                                category="cost"
                                dataType="currency"
                                compareStartDate={compareStartDate}
                                compareEndDate={compareEndDate}
                            />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Gross Profit</Typography>
                        <Typography variant="h5">
                            {CurrencyFormatter(
                                selectedMonthFinalisedRevenue -
                                    selectedMonthFinalisedCost
                            )}
                        </Typography>
                        {
                            <DataChangeIndicator
                                currentValue={
                                    selectedMonthFinalisedRevenue -
                                    selectedMonthFinalisedCost
                                }
                                previousValue={
                                    comparedMonthFinalisedRevenue -
                                    comparedMonthFinalisedCost
                                }
                                category="gross profit"
                                dataType="currency"
                                compareStartDate={compareStartDate}
                                compareEndDate={compareEndDate}
                            />
                        }
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default BookingRevenueOverview;
