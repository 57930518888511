// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Voucher } from '../../../global/interfaces/PartsInterface';

/**
 * GetVoucherTransactions
 * Get all transactions associated with a single voucher
 * @author Sienna
 * @param selectedVoucher the voucher needing transaction lines
 * @param setSelectedVoucher react state setter for the voucher
 */
const GetVoucherTransactions = (
  selectedVoucher: Voucher,
  setSelectedVoucher: Dispatch<SetStateAction<Voucher>>
) => {
  api
    .get(`voucherTransactionsNextGen/${selectedVoucher.voucherCode}`)
    .then((res) => {
      setSelectedVoucher({ ...selectedVoucher, transactionLines: res.data });
    });
};

export default GetVoucherTransactions;
