// IMPORTS
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
// COMPONENTS
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
// INTERFACE
import {
    Credit,
    Order,
    Voucher
} from '../../../global/interfaces/PartsInterface';
import { Customer } from '../../../global/interfaces/GeneralInterface';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import api from '../../../../../api';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        quantityReturned?: number; // used in crediting order
        backorderQuantityReturned?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        amountRefunded?: number; // used in crediting order
        // USED ONLY HERE
        refundableQuantity?: number;
        refundableBackorderQuantity?: number;
        quantityReturning?: number;
        backorderQuantityReturning?: number;
        amountRefunding?: number;
    }[];
}

interface CreditProps {
    siteId: number;
    saleDetails: TempOrder;
    customerDetails: Customer;
    vouchers: { voucher: Voucher; qtyReturning: number }[];
    allCredits: boolean;
    credit?: Credit;
}

/**
 * CreditPDF
 * Create a PDF for a single credit or the whole sale
 * @author Estienne
 * @param siteId the site the sale took place
 * @param saleDetails data for the sale
 * @param customerDetails data of the customer
 * @param allCredits a check for whether we are printing all credits
 * @param credit a singular credit on the sale
 */
const CreditPDF = async ({
    siteId,
    saleDetails,
    customerDetails,
    vouchers,
    allCredits,
    credit
}: CreditProps) => {
    // Create the document
    let doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Get all credits for the sale if necessary
    let credits;
    if (allCredits) {
        await api.get(`creditsByOrder/${saleDetails.id}`).then((res) => {
            credits = res.data;
        });
    }

    // Create the pdf header
    await InvoiceHeader(
        doc,
        siteId,
        !allCredits
            ? `Credit #${credit.id}`
            : `${saleDetails.OnlineOrderId ? 'Online' : saleDetails.status === 'Proforma' ? 'Proforma' : saleDetails.status === 'Sale' ? 'Sale' : 'Quote'} #${saleDetails.id}`,
        DateFormatter(!allCredits ? credit.createdAt : saleDetails.createdAt),
        customerDetails != null ? true : false,
        true,
        true,
        customerDetails,
        !allCredits ? credit.User : saleDetails.User,
        DateFormatter(!allCredits ? credit.createdAt : saleDetails.createdAt)
    );

    // Create the table column headings
    let saleColumns = [
        saleDetails.status === 'Proforma'
            ? ['Qty', 'Part Number', 'Part Name', 'Unit Price']
            : [
                  'Qty',
                  'Part Number',
                  'Part Name',
                  'Unit Price',
                  'GST',
                  'Line Total'
              ]
    ];
    let creditColumns = [
        saleDetails.status === 'Proforma'
            ? ['Qty', 'Part Number', 'Part Name', 'Unit Price']
            : [
                  'Qty',
                  'Part Number',
                  'Part Name',
                  'Unit Price',
                  'Amount Refunded'
              ]
    ];
    let saleBody = [];
    let backorderBody = [];
    let creditBody = [];
    let saleTotal = 0;
    let backorderTotal = 0;
    let pageCount = 1;

    /*
    ----------- CREATE SALE LINES ----------- 
    */
    saleDetails.orderLines.forEach((line) => {
        // Add regular sale lines
        if (parseInt(line.quantity) > 0) {
            saleTotal += parseInt(line.quantity) * parseFloat(line.pricePaid);
            let newRow =
                saleDetails.status === 'Proforma'
                    ? [
                          line.quantity,
                          line.partNumber,
                          line.name,
                          CurrencyFormatter(line.pricePaid)
                      ]
                    : [
                          line.quantity,
                          line.partNumber,
                          line.name,
                          CurrencyFormatter(line.pricePaid),
                          CurrencyFormatter(parseFloat(line.pricePaid) / 11),
                          CurrencyFormatter(
                              parseInt(line.quantity) *
                                  parseFloat(line.pricePaid)
                          )
                      ];

            saleBody.push(newRow);
        }

        // Add backorder lines
        if (parseInt(line.backorderQuantity) > 0) {
            backorderTotal +=
                parseInt(line.backorderQuantity) * parseFloat(line.pricePaid);
            let newRow =
                saleDetails.status === 'Proforma'
                    ? [
                          line.backorderQuantity,
                          line.partNumber,
                          line.name,
                          CurrencyFormatter(line.pricePaid)
                      ]
                    : [
                          line.backorderQuantity,
                          line.partNumber,
                          line.name,
                          CurrencyFormatter(line.pricePaid),
                          CurrencyFormatter(parseFloat(line.pricePaid) / 11),
                          CurrencyFormatter(
                              parseInt(line.backorderQuantity) *
                                  parseFloat(line.pricePaid)
                          )
                      ];
            backorderBody.push(newRow);
        }
    });

    // Add any vouchers to the sale
    vouchers.forEach((voucherObj) => {
        saleTotal += voucherObj.voucher.totalPrice;
        let newRow = [
            '1',
            '',
            `VOUCHER #${voucherObj.voucher.id}`,
            CurrencyFormatter(voucherObj.voucher.totalPrice),
            CurrencyFormatter(0),
            CurrencyFormatter(voucherObj.voucher.totalPrice)
        ];
        saleBody.push(newRow);
    });

    /*
    ----------- CREATE CREDIT LINES ----------- 
    */
    let totalRefunded = 0;
    // Only print credit lines for the selected credit
    if (!allCredits) {
        // Create a row for each credited part
        credit.Order.orderLines.forEach((line) => {
            if (line?.creditId === credit.id) {
                let quantity =
                    parseInt(line.quantity) < 0
                        ? Math.abs(parseInt(line.quantity))
                        : Math.abs(parseInt(line.backorderQuantity));
                let newRow =
                    saleDetails.status === 'Proforma'
                        ? [
                              quantity,
                              line.partNumber,
                              line.name,
                              CurrencyFormatter(line.priceRRP)
                          ]
                        : [
                              quantity,
                              line.partNumber,
                              line.name,
                              CurrencyFormatter(line.priceRRP),
                              CurrencyFormatter(line.amountRefunded)
                          ];
                creditBody.push(newRow);
            }
        });

        // Add a freight credit line if freight has been credited
        if (credit?.freightRefunded) {
            let newRow = [
                '',
                'FREIGHT',
                'FREIGHT',
                CurrencyFormatter(saleDetails?.freight),
                CurrencyFormatter(credit?.freightRefunded)
            ];
            creditBody.push(newRow);
        }

        // Add a credit line for each refunded voucher on this credit
        vouchers.forEach((voucherObj) => {
            if (voucherObj.voucher.CreditId === credit.id) {
                totalRefunded += voucherObj.voucher.totalPrice;
                let newRow = [
                    '1',
                    '',
                    `VOUCHER #${voucherObj.voucher.id}`,
                    CurrencyFormatter(voucherObj.voucher.totalPrice),
                    CurrencyFormatter(voucherObj.voucher.totalPrice)
                ];
                creditBody.push(newRow);
            }
        });
    }

    // Otherwise, show details for all credits
    else {
        credits[credits.length - 1].Order.orderLines.forEach((line) => {
            if (line.amountRefunded > 0) {
                totalRefunded +=
                    parseFloat(line.pricePaid) *
                    (parseInt(line.quantity) < 0
                        ? Math.abs(parseInt(line.quantity))
                        : Math.abs(parseInt(line.backorderQuantity)));
                let quantity =
                    parseInt(line.quantity) < 0
                        ? Math.abs(parseInt(line.quantity))
                        : Math.abs(parseInt(line.backorderQuantity));
                let newRow = [
                    quantity,
                    line.partNumber,
                    line.name,
                    CurrencyFormatter(line.priceRRP),
                    CurrencyFormatter(line.amountRefunded)
                ];
                creditBody.push(newRow);
            }
        });

        // Create a row for refunded credit
        if (saleDetails?.freightRefunded) {
            totalRefunded += saleDetails?.freightRefunded;
            let newRow = [
                '',
                '',
                'FREIGHT',
                CurrencyFormatter(saleDetails.freight),
                CurrencyFormatter(saleDetails.freightRefunded)
            ];
            creditBody.push(newRow);
        }

        // Create a row for each credited voucher
        vouchers.forEach((voucherObj) => {
            totalRefunded += voucherObj.voucher.totalPrice;
            let newRow = [
                '',
                '',
                `VOUCHER #${voucherObj.voucher.id}`,
                CurrencyFormatter(voucherObj.voucher.totalPrice),
                CurrencyFormatter(voucherObj.voucher.totalPrice)
            ];
            creditBody.push(newRow);
        });
    }

    /*
    ----------- SHOW SALE LINES ----------- 
    */

    let lastYCoordinates = 82;
    doc.setFont('helvetica', 'bold').setFontSize(12);

    if (saleBody.length > 0) {
        doc.text('Sale Lines', 17, lastYCoordinates + 13);
        InvoiceTable(doc, lastYCoordinates + 18, saleColumns, saleBody);

        lastYCoordinates = (doc as any).lastAutoTable.finalY;
        if (saleDetails.status !== 'Proforma') {
            let totalsContent = [['Sale Total', CurrencyFormatter(saleTotal)]];
            if (saleDetails?.freight) {
                totalsContent.push([
                    'Frieght Total',
                    CurrencyFormatter(saleDetails.freight)
                ]);
            }
            totalsContent.push([
                'Total',
                CurrencyFormatter(
                    saleDetails?.freight
                        ? saleTotal + saleDetails.freight
                        : saleTotal
                )
            ]);

            autoTable(doc, {
                body: totalsContent,
                startY: lastYCoordinates + 3,
                theme: 'plain',
                margin: { bottom: 20, left: 153 },
                willDrawCell: function (data) {
                    let rows = data.table.body;
                    if (data.row.index === rows.length - 1) {
                        doc.setFont('helvetica', 'bold');
                    }
                }
            });
        }

        if (saleDetails?.freight) {
            lastYCoordinates = lastYCoordinates + 13;
        }

        lastYCoordinates = lastYCoordinates + 13;

        // Move to next page if necessary
        if (lastYCoordinates > 300) {
            pageCount = pageCount + 1;
            doc.addPage();
            doc.setPage(pageCount);
        }
    }

    /*
    ----------- SHOW BACKORDER LINES ----------- 
    */
    if (backorderBody.length > 0) {
        doc.text('Backorder Lines', 17, lastYCoordinates + 13);
        InvoiceTable(doc, lastYCoordinates + 18, saleColumns, backorderBody);

        lastYCoordinates = (doc as any).lastAutoTable.finalY;
        if (saleDetails.status !== 'Proforma') {
            let totalsContent = [
                ['Backorder Total', CurrencyFormatter(backorderTotal)]
            ];

            autoTable(doc, {
                body: totalsContent,
                startY: lastYCoordinates + 3,
                theme: 'plain',
                margin: { bottom: 20, left: 153 }
            });
        }

        // Move to next page if necessary
        if (lastYCoordinates > 300) {
            pageCount = pageCount + 1;
            doc.addPage();
            doc.setPage(pageCount);
        }
    }

    /*
    ----------- SHOW CREDIT LINES ----------- 
    */
    doc.text('Credit Lines', 17, lastYCoordinates + 13);
    InvoiceTable(doc, lastYCoordinates + 18, creditColumns, creditBody);

    lastYCoordinates = (doc as any).lastAutoTable.finalY;
    if (saleDetails.status !== 'Proforma') {
        let totalsContent = [['Sale Total', CurrencyFormatter(saleTotal)]];

        if (saleDetails?.freight) {
            totalsContent.push([
                'Frieght Total',
                CurrencyFormatter(saleDetails.freight)
            ]);
        }

        totalsContent.push([
            'Credit Total',
            CurrencyFormatter(
                !allCredits ? credit.amountRefunded : totalRefunded
            )
        ]);
        totalsContent.push([
            'Total',
            CurrencyFormatter(
                (saleDetails?.freight
                    ? saleTotal + saleDetails.freight
                    : saleTotal) -
                    (!allCredits ? credit.amountRefunded : totalRefunded)
            )
        ]);

        autoTable(doc, {
            body: totalsContent,
            startY: lastYCoordinates + 3,
            theme: 'plain',
            margin: { bottom: 20, left: 153 },
            styles: { halign: 'right' },
            willDrawCell: function (data) {
                let rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    doc.setFont('helvetica', 'bold');
                }
            }
        });
    }

    // Move to next page if necessary
    if (lastYCoordinates > 300) {
        pageCount = pageCount + 1;
        doc.addPage();
        doc.setPage(pageCount);
    }

    /*
    ----------- CREATE PAYMENT LINES ----------- 
    */
    lastYCoordinates = (doc as any).lastAutoTable.finalY;

    if (saleDetails.paymentLines.length > 0) {
        let paymentColumns = [['Payment Method', 'Payment Date', 'Amount']];
        let paymentBody = [];

        if (!allCredits) {
            credit.Order.paymentLines.forEach((line) => {
                if (
                    !line.removed &&
                    !line.inactive &&
                    !saleDetails.LinkedSaleId
                ) {
                    if (line.id === 0 || line.creditId === credit.id) {
                        let newRow = [
                            line.type.toUpperCase(),
                            `${DateFormatter(line.date)} ${moment(line.timestamp).format('h:mm A')}`,
                            CurrencyFormatter(line.amount)
                        ];
                        paymentBody.push(newRow);
                    }
                }
            });
        } else {
            saleDetails.paymentLines.forEach((line) => {
                if (
                    !line.removed &&
                    !line.inactive &&
                    !saleDetails.LinkedSaleId
                ) {
                    let newRow = [
                        line.type.toUpperCase(),
                        `${DateFormatter(line.date)} ${moment(line.timestamp).format('h:mm A')}`,
                        CurrencyFormatter(line.amount)
                    ];
                    paymentBody.push(newRow);
                }
            });
        }

        /*
        ----------- SHOW PAYMENT LINES ----------- 
        */
        doc.text('Payment Lines', 17, lastYCoordinates + 13);
        InvoiceTable(doc, lastYCoordinates + 18, paymentColumns, paymentBody);
    }

    // Open PDF
    window.open(doc.output('bloburl'));
};

export default CreditPDF;
