import api from '../../../../../api';

const NegativeStockReport = () => {
  api
    .get(`negativeStockReport/${localStorage.getItem('SiteId')}`)
    .then((res) => {
      let data = res.data;

      let finalArray = [];
      finalArray.push([
        'Part Number',
        'Quantity On Hand',
        'Quantity Reserved',
        'Quantity Incoming',
        'Cost Price Daily',
        'Cost Price Average',
        'RRP',
        'Primary Bin Location'
      ]);
      for (let unit of data) {
        finalArray.push([
          unit.Unit.partNumber,
          unit.qtyOnHand,
          unit.qtyReserved,
          unit.qtyIncoming,
          unit.Unit.costPriceDaily,
          unit.costPriceAverage,
          unit.Unit.priceRRP,
          unit.Unit.BinLocationIds.length > 0
            ? unit.Unit.BinLocationIds[0].name
            : ''
        ]);
      }

      // Create the CSV file
      let csvContent = 'data:text/csv;charset=utf-8,';
      finalArray.forEach(function (rowArray) {
        let row = rowArray.join(',');
        csvContent += row + '\r\n';
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `NegativeStockReport`);
      document.body.appendChild(link);
      link.click();
    });
};

export default NegativeStockReport;
