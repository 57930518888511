// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { OnlinePayment } from '../interfaces/AdminInterface';

const GetAllOnlinePayments = (
    setOnlinePayment: Dispatch<SetStateAction<OnlinePayment[]>>
) => {
    api.get('onlinePayment').then((res) => {
        let data = res.data;
        for (let line of data) {
            if (line.Account?.Site?.name) {
                line.Account.siteName = line.Account.Site.name;
            }
        }
        setOnlinePayment(data);
    });
};

export default GetAllOnlinePayments;
