// IMPORTS
import { TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// COMPONENTS
import PageWrapper from '../global/PageWrapper';
import Paper from '../global/Paper';
import Autocomplete from '../global/Autocomplete';
import DataTable from '../global/tableComponents/DataTable';
import DataCell from '../global/tableComponents/DataCell';
import { withSnackbar } from '../global/WrappingSnackbar';
import Fab from '../global/Fab';

// LOGIC
import GetAllAccounts from '../global/databaseLogic/GetAllAccounts';
import HandleEditOnlinePayment from './logic/OnlinePayment/HandleEditOnlinePayment';
import GetAllOnlinePayments from '../global/databaseLogic/GetAllOnlinePayments';

// INTERFACES
import { Account } from '../global/interfaces/AdminInterface';
import { showSnackbar } from '../global/interfaces/GlobalInterface';

const OnlinePaymentManagement = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [accountList, setAccountList] = useState<Account[]>([]);
    const [paymentManagementData, setPaymentManagementData] = useState([]);

    useEffect(() => {
        GetAllAccounts(setAccountList);
        GetAllOnlinePayments(setPaymentManagementData);
    }, []);

    const getIndex = (paymentType) => {
        let index = paymentManagementData.findIndex(
            (x) => x.paymentType === paymentType
        );
        return index;
    };

    const handleAccountChange = (paymentType, account) => {
        let paymentData = [...paymentManagementData];
        let relevantPaymentData = paymentManagementData.filter(
            (x) => x.paymentType === paymentType
        );
        if (relevantPaymentData.length === 0) {
            paymentData.push({
                paymentType: paymentType,
                Account: account,
                AccountId: account.id
            });
        } else if (relevantPaymentData.length === 1) {
            let index = paymentData.findIndex(
                (x) => x.paymentType === paymentType
            );

            if (account == null) {
                paymentData[index] = {
                    ...paymentData[index],
                    Account: null,
                    AccountId: null
                };
            } else {
                paymentData[index] = {
                    ...paymentData[index],
                    Account: account,
                    AccountId: account.id
                };
            }
        }
        setPaymentManagementData(paymentData);
    };

    const maropostPaymentType = [
        { value: 'eBay Managed Payments' },
        { value: 'MasterCard' },
        { value: 'PayPal Express' },
        { value: 'Visa' },
        { value: 'Afterpay' },
        { value: 'Voucher / Rewards Points' },
        { value: 'American Express' },
        { value: 'zipPay' },
        { value: 'zipMoney' }
    ];

    const columns = [
        { id: 0, label: 'Payment Type' },
        { id: 1, label: 'Assigned Account' }
    ];

    return (
        <PageWrapper>
            <Typography variant="h4">Online Payment Management</Typography>
            <br />
            <Paper>
                Please assign an account to each payment type that might come
                from Maropost. Any payment from Maropost will be added to this
                account. <br /> If a Payment Type doesn't have an assigned
                account, it won't be added anywhere.
                <DataTable columns={columns}>
                    {maropostPaymentType.map((payment, index) => (
                        <TableRow>
                            <DataCell>{payment.value}</DataCell>
                            <DataCell>
                                <Autocomplete
                                    size="small"
                                    isDisabled={readOnly}
                                    options={accountList}
                                    selectedValue={
                                        getIndex(payment.value) !== -1
                                            ? paymentManagementData[
                                                  getIndex(payment.value)
                                              ]?.Account
                                            : null
                                    }
                                    handleSelectedValueChange={(event) => {
                                        handleAccountChange(
                                            payment.value,
                                            event
                                        );
                                    }}
                                    handleInputValueChange={() => void 0}
                                    textfieldLabel="Select Account"
                                    useTwoOptionLabels={true}
                                    primaryOptionLabel="name"
                                    secondaryOptionLabel="siteName"
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Fab
                editIcon={readOnly}
                onClick={() => {
                    if (readOnly) {
                        setReadOnly(false);
                    } else {
                        HandleEditOnlinePayment(
                            paymentManagementData,
                            showSnackbar
                        );
                    }
                }}
            />
        </PageWrapper>
    );
};

export default withSnackbar(OnlinePaymentManagement);
