import { Site } from '../../global/interfaces/GeneralInterface';
import api from '../../../../api';
import { Dispatch, SetStateAction } from 'react';

const GetCurrentSite = (
  siteID: number,
  setSite: Dispatch<SetStateAction<Site>>
) => {
  api.get(`site/${siteID}?includeSettings=true`).then((res) => {
    setSite(res.data);
  });
};

export default GetCurrentSite;
