// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Creditor } from '../interfaces/GeneralInterface';

const GetAllSuppliers = (
  setSuppliers: Dispatch<SetStateAction<Creditor[]>>
) => {
  api.get('suppliers').then((res) => {
    setSuppliers(res.data);
  });
};

export default GetAllSuppliers;
