import { Make } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const UpdateMakeName = (make: Make, showSnackbar: showSnackbar) => {
    api.put('makeName/' + make.id, { newName: make.name.toUpperCase() }).then(
        (res) => {
            if (res.status === 200) {
                showSnackbar('Make name has been updated.');
                window.location.reload();
            } else if (res.status === 400) {
                showSnackbar('Make already exist.', '', 'error');
            } else {
                showSnackbar(
                    'Something went wrong, please refresh the page and try again.',
                    '',
                    'error'
                );
            }
        }
    );
};

export default UpdateMakeName;
