// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    DialogContent,
    DialogTitle,
    TableRow,
    DialogActions,
    Button,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import dayjs from 'dayjs';
// COMPONENTS
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';

// LOGIC
import SubmitTimeClock from '../logic/SubmitTimeClock';
import { DateFormatter } from '../../global/logic/Formatters';

// INTERFACES
import { TimeClock } from '../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

interface TimeClocksProps {
    setTimeClockOpen: Dispatch<SetStateAction<boolean>>;
    currentSiteName: string;
    timeClocks: TimeClock[];
    refresh: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const TimeClocks = ({
    setTimeClockOpen,
    currentSiteName,
    timeClocks,
    refresh,
    setRefresh,
    showSnackbar
}: TimeClocksProps) => {
    const [clockStatus, setClockStatus] = useState<boolean>(true); // false is clock off, true is clock on
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const columns = [
        { id: 0, label: 'Time On', width: 300 },
        { id: 1, label: 'Time Off', width: 300 },
        { id: 2, label: 'Site', width: 300 }
    ];

    // useEffect(() => {
    //     GetTimeClocks(setTimeClocks);
    // }, [refresh]);

    useEffect(() => {
        if (timeClocks.length > 0) {
            if (timeClocks[timeClocks.length - 1].clockOff) {
                setClockStatus(true);
            } else {
                setClockStatus(false);
            }
        }
    }, [timeClocks]);

    return (
        <>
            <DialogTitle>
                Time Clocks - {DateFormatter(String(new Date()))}
            </DialogTitle>
            <DialogContent>
                <DataTable columns={columns}>
                    {timeClocks.map((row) => (
                        <TableRow>
                            <DataCell>
                                {dayjs(row.clockOn, 'hh:mm:ss').format(
                                    'hh:mma'
                                )}
                            </DataCell>
                            <DataCell>
                                {row.clockOff ? (
                                    dayjs(row.clockOff, 'hh:mm:ss').format(
                                        'hh:mma'
                                    )
                                ) : (
                                    <Typography color="red" variant="body2">
                                        {new Date().toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                        :00
                                    </Typography>
                                )}
                            </DataCell>
                            <DataCell>
                                {row.clockOff ? (
                                    row?.Site?.name
                                ) : (
                                    <Typography color="red" variant="body2">
                                        {row?.Site?.name}
                                    </Typography>
                                )}
                            </DataCell>
                        </TableRow>
                    ))}
                    {clockStatus ? (
                        <TableRow>
                            <DataCell>
                                <Typography color="red" variant="body2">
                                    {new Date().toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                    :00
                                </Typography>
                            </DataCell>
                            <DataCell></DataCell>
                            <DataCell>
                                <Typography color="red" variant="body2">
                                    {currentSiteName}
                                </Typography>
                            </DataCell>
                        </TableRow>
                    ) : null}
                </DataTable>
            </DialogContent>
            <DialogActions>
                {!clockStatus && (
                    <Button onClick={() => setTimeClockOpen(false)}>
                        Cancel
                    </Button>
                )}

                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        SubmitTimeClock(
                            new Date().toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit'
                            }),
                            refresh,
                            setRefresh,
                            setButtonLoading,
                            showSnackbar
                        )
                    }
                >
                    {clockStatus ? 'Clock On' : 'Clock Off'}
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default withSnackbar(TimeClocks);
