// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';

// Handles the customer search for the specific merge customer inputs
const SearchCustomer = (
  searchString: string,
  setCustomers: Dispatch<SetStateAction<Customer[]>>
) => {
  const sort = ['id', 'DESC'];
  const limit = 10;
  const currentPage = 0;
  api
    .get(
      `customerSearchNextGen?limit=${limit}&currentPage=${currentPage}&sort=${sort}&searchString=${searchString}`
    )
    .then((res) => {
      let rows = res.data.rows;
      // Put the customer full name in for the autocomplete option label
      rows.forEach((row) => {
        row.fullName = row.companyName
          ? row.companyName
          : row.firstName + ' ' + row.lastName;
      });
      setCustomers(rows);
    });
};

export default SearchCustomer;
