// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Grid,
    IconButton,
    TableRow,
    TextField,
    Typography,
    Dialog
} from '@mui/material';
import { Add } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
import CSMContent from './CSMComponents/CSMContent';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import TotalsForm from './TotalsForm';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';
import {
    TradeIn,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import CSMDialog from './CSMComponents/CSMDialog';

interface AddOnsProps {
    readOnly: boolean;
    saleDetails: VehicleSale;
    linkedSales: Order[];
    setLinkedSales: Dispatch<SetStateAction<Order[]>>;
    linkedVouchers: any[];
    setLinkedVouchers: Dispatch<SetStateAction<any[]>>;
    linkedTrades: TradeIn[];
    gstType: string;
}
const AddOns = ({
    readOnly,
    saleDetails,
    linkedSales,
    setLinkedSales,
    linkedVouchers,
    setLinkedVouchers,
    linkedTrades,
    gstType
}: AddOnsProps) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const voucherColumns = [
        { id: 0, label: 'Voucher', width: 600 },
        { id: 1, label: 'Amount', width: 400 }
    ];

    const addNewVoucher = () => {
        const currentVouchers = [...linkedVouchers];
        let newVoucher = {
            voucherCode: 'Voucher',
            totalPrice: '0'
        };
        currentVouchers.push(newVoucher);
        setLinkedVouchers(currentVouchers);
    };

    const handleUpdateVoucher = (index, value) => {
        const currentVouchers = [...linkedVouchers];
        currentVouchers[index].totalPrice = value;
        setLinkedVouchers(currentVouchers);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Paper>
                        <Typography variant="h6">
                            CSMs
                            {saleDetails.id ? (
                                <IconButton onClick={() => setDialogOpen(true)}>
                                    <Add />
                                </IconButton>
                            ) : null}
                        </Typography>
                        {linkedSales.length > 0 ? (
                            <CSMContent
                                linkedSales={linkedSales}
                                setLinkedSales={setLinkedSales}
                            />
                        ) : (
                            <Typography variant="body1">
                                No CSMs linked to this contract.
                            </Typography>
                        )}
                    </Paper>
                    <br />
                    <Paper>
                        <Typography variant="h6">Vouchers</Typography>
                        <DataTable columns={voucherColumns}>
                            {linkedVouchers.map((voucher, index) => (
                                <TableRow>
                                    <DataCell>{voucher.voucherCode}</DataCell>
                                    <DataCell>
                                        {voucher.voucherCode === 'Voucher' ||
                                        !readOnly ? (
                                            <TextField
                                                variant="standard"
                                                fullWidth
                                                size="small"
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }}
                                                value={voucher.totalPrice}
                                                onChange={(e) =>
                                                    handleUpdateVoucher(
                                                        index,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            CurrencyFormatter(
                                                voucher.totalPrice
                                            )
                                        )}
                                    </DataCell>
                                </TableRow>
                            ))}
                            {readOnly ? null : (
                                <TableRow>
                                    <DataCell colSpan={2}>
                                        <IconButton
                                            onClick={() => addNewVoucher()}
                                        >
                                            <Add />
                                        </IconButton>
                                    </DataCell>
                                </TableRow>
                            )}
                        </DataTable>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper>
                        <TotalsForm
                            saleDetails={saleDetails}
                            linkedSales={linkedSales}
                            linkedVouchers={linkedVouchers}
                            linkedTrades={linkedTrades}
                            gstType={gstType}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                fullScreen
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <CSMDialog
                    vehicleSaleId={saleDetails?.id}
                    saleDetails={saleDetails}
                    setDialogOpen={setDialogOpen}
                />
            </Dialog>
        </>
    );
};

export default AddOns;
