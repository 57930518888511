// IMPORTS
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
// LOGIC
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
import { CurrencyFormatter } from '../../../global/logic/Formatters';

const PDFStocktakeReport = async (stocktake) => {
    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Header for the invoice
    await InvoiceHeader(
        doc,
        parseInt(localStorage.getItem('SiteId')),
        'Stocktake Report',
        `Stocktake #${stocktake.id}`,
        false,
        true,
        false,
        {},
        stocktake.User,
        stocktake.createdAt
    );

    // Table continaing the voucher lines
    let header = [
        [
            'Part Number',
            'Expected',
            'Counted',
            'Counted Difference',
            'Variance',
            'Average Cost',
            'Total Cost'
        ]
    ];
    let body = [];

    let expectedTotal = 0;
    let countedTotal = 0;
    let varianceCountTotal = 0;
    let varianceDollarTotal = 0;
    let averageCostTotal = 0;

    stocktake.stocktakeLines.forEach((line) => {
        let varianceCount = parseInt(line.counted) - parseInt(line.expected);

        expectedTotal += parseInt(line.expected);
        countedTotal += parseInt(line.counted);
        varianceCountTotal += varianceCount;
        varianceDollarTotal +=
            varianceCount * parseFloat(line.averageCostPrice);
        averageCostTotal +=
            parseFloat(line.averageCostPrice) * parseInt(line.counted);

        body.push([
            line.partNumber,
            line.expected,
            line.counted,
            varianceCount,
            CurrencyFormatter(varianceCount * line.averageCostPrice),
            CurrencyFormatter(line.averageCostPrice),
            CurrencyFormatter(parseFloat(line.counted) * line.averageCostPrice)
        ]);
    });

    body.push([
        'TOTAL',
        expectedTotal,
        countedTotal,
        varianceCountTotal,
        CurrencyFormatter(varianceDollarTotal),
        '',
        CurrencyFormatter(averageCostTotal)
    ]);

    let lastYcoordinates = 82;

    doc.setFont('helvetica', 'bold').setFontSize(12);
    doc.text('Parts in Stocktake', 17, lastYcoordinates + 13);

    InvoiceTable(doc, lastYcoordinates + 18, header, body, true);

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFStocktakeReport;
