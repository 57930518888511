import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Make, Model, Series, FuelType, DriveType, BodyType } from "../../../global/interfaces/VehicleSaleInterface";
import { Creditor } from "../../../global/interfaces/GeneralInterface";

interface GetVehicleSpecificsListsParams {
    setSpecificLists: Dispatch<SetStateAction<{
        Make: Make[];
        Model: Model[];
        Series: Series[];
        FuelType: FuelType[];
        Creditors: Creditor[];
        DriveType: DriveType[];
        BodyType: BodyType[];
    }>>
}

const GetVehicleSpecificsLists = (params: GetVehicleSpecificsListsParams) => {
    api.get("vehicleSpecificsListsNextGen").then((res) => {
        params.setSpecificLists({
            Make: res.data.makes,
            Model: res.data.models,
            Series: res.data.series,
            FuelType: res.data.fuelTypes,
            Creditors: res.data.creditors.filter(x => x.type === 'finance'),
            DriveType: res.data.driveType,
            BodyType: res.data.bodyType,
        });
    });
};

export default GetVehicleSpecificsLists;
