// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import { Grid, TextField } from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
// LOGIC
import { MiscTotal, PartTotal, SubletTotal } from '../../logic/GetTotals';
import {
    CurrencyFormatter,
    ToFixed
} from '../../../../global/logic/Formatters';
// INTERFACES
import { Service } from '../../../../global/interfaces/ServiceInterface';
interface PricesProps {
    readOnly: boolean;
    serviceDetails: Service;
    setServiceDetails: Dispatch<SetStateAction<Service>>;
    sublets;
    partSales;
}

const Prices = ({
    readOnly,
    serviceDetails,
    setServiceDetails,
    sublets,
    partSales
}: PricesProps) => {
    const [subletWarning, setSubletWarning] = useState<boolean>(false);

    const checkSubletCharge = () => {
        let subletCalculatedTotal = SubletTotal(sublets);
        if (parseFloat(serviceDetails.subletTotal) < subletCalculatedTotal) {
            setSubletWarning(true);
        } else {
            setSubletWarning(false);
        }
    };

    const updateLabourCost = (newValue: string) => {
        let miscTotal = MiscTotal(serviceDetails);
        let partsTotal = PartTotal(serviceDetails, partSales).partsTotal;
        let labourCharge = parseFloat(newValue);
        let subletCharge = parseFloat(serviceDetails.subletTotal);

        // subletTotal in db is price include GST, others are price exclude GST
        let serviceTotal =
            subletCharge + (labourCharge + miscTotal + partsTotal) * 1.1;
        // serviceTotal = serviceTotal * 1.1;

        setServiceDetails({
            ...serviceDetails,
            labourCost: newValue,
            totalPrice: String(ToFixed(serviceTotal))
        });
    };

    const updateTotalPrice = (newTotalPrice) => {
        let serviceTotal = parseFloat(newTotalPrice);

        // Get the new service total and subtract the gst
        let newTotalExGst = serviceTotal / 1.1;

        // Then subtract out all the other costs to leave just the new labour cost
        let partsTotal = PartTotal(serviceDetails, partSales).partsTotal;
        let miscTotal = MiscTotal(serviceDetails);

        // subletTotal in db is include GST, but here need deduct sublet total exclude gst
        let newLabour = ToFixed(
            newTotalExGst -
                parseFloat(serviceDetails.subletTotal) / 1.1 -
                partsTotal -
                miscTotal
        );

        // This difference is the new labour total
        setServiceDetails({
            ...serviceDetails,
            totalPrice: String(newTotalPrice),
            labourCost: String(newLabour)
        });
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            size="small"
                            type="number"
                            label="Labour Charge (EX. GST)"
                            InputLabelProps={{ shrink: true }}
                            value={serviceDetails.labourCost}
                            onChange={(e) => updateLabourCost(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            size="small"
                            type="number"
                            label="Sublet Charge (EX. GST)"
                            error={subletWarning}
                            helperText={
                                subletWarning
                                    ? `Sublet charges is less than sublet cost (${CurrencyFormatter(SubletTotal(sublets))} Inc. GST).`
                                    : ''
                            }
                            InputLabelProps={{ shrink: true }}
                            value={ToFixed(
                                parseFloat(serviceDetails.subletTotal) / 1.1
                            )}
                            onChange={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                setServiceDetails({
                                    ...serviceDetails,
                                    subletTotal: inputValue
                                        ? ToFixed(inputValue * 1.1).toString()
                                        : '0'
                                });
                            }}
                            onBlur={() => {
                                checkSubletCharge();
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            size="small"
                            type="number"
                            label="Environmental Levy"
                            InputLabelProps={{ shrink: true }}
                            value={serviceDetails.environmentalLevy}
                            onChange={(e) =>
                                setServiceDetails({
                                    ...serviceDetails,
                                    environmentalLevy: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            size="small"
                            type="number"
                            label="Sundries"
                            InputLabelProps={{ shrink: true }}
                            value={serviceDetails.sundries}
                            onChange={(e) =>
                                setServiceDetails({
                                    ...serviceDetails,
                                    sundries: e.target.value
                                })
                            }
                        />
                    </Grid>
                    {serviceDetails.jobType === 'Insurance' && (
                        <Grid item xs={6}>
                            <TextField
                                disabled={readOnly}
                                fullWidth
                                size="small"
                                type="number"
                                label="Customer Excess"
                                InputLabelProps={{ shrink: true }}
                                value={serviceDetails.customerExcess}
                                onChange={(e) =>
                                    setServiceDetails({
                                        ...serviceDetails,
                                        customerExcess: e.target.value
                                    })
                                }
                            />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={serviceDetails.jobType === 'Insurance' ? 6 : 12}
                    >
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            size="small"
                            type="number"
                            label={
                                serviceDetails.jobType === 'Internal' ||
                                serviceDetails.jobType === 'Rework'
                                    ? 'Service Total (EX. GST)'
                                    : 'Service Total (INC. GST)'
                            }
                            InputLabelProps={{ shrink: true }}
                            value={serviceDetails.totalPrice}
                            onChange={(e) => updateTotalPrice(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default Prices;
