// IMPORTS
import { useState } from 'react';
import { Typography, TableRow } from '@mui/material';

// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import Drawer from '../../global/Drawer';
import CustomerBackorderTableFilter from '../../stock/customerBackorder/components/CustomerBackorderTableFilter';

// LOGIC
import { DateFormatter } from '../../global/logic/Formatters';
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';

// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import {
    CustomerBackorder,
    StockOrder
} from '../../global/interfaces/PartsInterface';
import { Creditor } from '../../global/interfaces/GeneralInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    customerBackorderFilter: { status: [], orderType: [], site: [] }
};
const clearedSort = ['id', 'DESC'];

/**
 * BackorderTable
 * Display all backorders in an organised table
 * @author Pierre
 * @returns {JSX} a table of backorders
 */
const BackorderTable = () => {
    const [filter, setFilter] = useState<Filter>({
        customerBackorderFilter: {
            status: [
                { value: 'OPEN', label: 'OPEN' },
                { value: 'ORDERED', label: 'ORDERED' }
            ],
            orderType: [],
            site: []
        }
    });
    const [sort, setSort] = useState<string[]>(clearedSort);
    const [resultsList, setResultsList] = useState<CustomerBackorder[]>([]);
    const [openStockOrderDrawer, setOpenStockOrderDrawer] =
        useState<boolean>(false);
    const [selectedStockOrder, setSelectedStockOrder] = useState<{
        StockOrder: StockOrder;
        Supplier: Creditor;
    }>({ StockOrder: null, Supplier: null });

    // Columns for the data table
    const columns = [
        { id: 0, label: 'Status', width: 100 },
        { id: 1, label: 'Part Number', width: 100 },
        { id: 2, label: 'Quantity', width: 70 },
        { id: 3, label: 'Supplier', width: 100 },
        { id: 4, label: 'Order ID', width: 100 },
        { id: 5, label: 'Stock Order ID', width: 100 },
        {
            id: 6,
            label: 'Date of Creation',
            name: 'createdAt',
            sort: true,
            width: 100
        },
        {
            id: 7,
            label: 'Stock Order Date',
            name: 'StockOrderId',
            sort: true,
            width: 100
        }
    ];

    const drawerColumns = [
        { id: 0, label: 'Part Number', width: 100 },
        { id: 1, label: 'Part Name', width: 200 },
        { id: 2, label: 'Quantity', width: 50 }
    ];

    const handleRequestCreate = () => {
        let status =
            filter.customerBackorderFilter.status.length > 0
                ? ConvertArrayToFilter({
                      array: filter.customerBackorderFilter.status,
                      filterStart: '&status=',
                      selector: 'value'
                  })
                : '';
        let site =
            filter.customerBackorderFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.customerBackorderFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let type =
            filter.customerBackorderFilter.orderType.length > 0
                ? ConvertArrayToFilter({
                      array: filter.customerBackorderFilter.orderType,
                      filterStart: '&orderType=',
                      selector: 'value'
                  })
                : '';

        var apiString = `${status}${site}${type}`;
        return apiString;
    };

    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.customerBackorderFilter.status.length > 0 ||
            filter.customerBackorderFilter.orderType.length > 0 ||
            filter.customerBackorderFilter.site.length > 0
        ) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Backorders</Typography>
            <br />
            <TableSearch
                searchTitle="Search Backorders (Part #, Order ID, Supplier Name)"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="customerBackorderTableSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <CustomerBackorderTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable columns={columns} sort={sort} setSort={setSort}>
                    {resultsList.map((row) => (
                        <TableRow key={row.id} data-cy={`backorderRow_${row.OrderId}`}>
                            <DataCell>{row.status}</DataCell>
                            <DataCell>{row.partNumber}</DataCell>
                            <DataCell>{row.backorderQuantity}</DataCell>
                            <DataCell>{row.Supplier?.name}</DataCell>

                            <DataCellColoured
                                cypressLabel={`backorderRow_${row.OrderId}_redirect`}
                                handleClick={() =>
                                    window.open(
                                        '/inventory/viewSale/' + row.OrderId,
                                        '_blank'
                                    )
                                }
                            >
                                {row.OrderId}
                            </DataCellColoured>
                            <DataCellColoured
                                handleClick={() => {
                                    setSelectedStockOrder({
                                        StockOrder: row.StockOrder,
                                        Supplier: row.Supplier
                                    });
                                    setOpenStockOrderDrawer(true);
                                }}
                            >
                                {row.StockOrderId ?? ''}
                            </DataCellColoured>
                            <DataCell>{DateFormatter(row.createdAt)}</DataCell>
                            <DataCell>
                                {row.StockOrder?.createdAt
                                    ? DateFormatter(row.StockOrder?.createdAt)
                                    : ''}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={openStockOrderDrawer}
                setOpenDrawer={setOpenStockOrderDrawer}
                title={
                    'Content of Stock Order #' +
                    selectedStockOrder.StockOrder?.id
                }
                subTitle={`${selectedStockOrder.Supplier?.name} -  Ordered on the: ${DateFormatter(selectedStockOrder?.StockOrder?.createdAt)}`}
            >
                <DataTable columns={drawerColumns}>
                    {selectedStockOrder.StockOrder?.orderLines.map((line) => (
                        <TableRow key={line.id}>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        '/inventory/view/' + line.id,
                                        '_blank'
                                    )
                                }
                            >
                                {line.partNumber}
                            </DataCellColoured>
                            <DataCell>{line.name}</DataCell>
                            <DataCell>{line.quantity}</DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Drawer>
        </PageWrapper>
    );
};

export default BackorderTable;
