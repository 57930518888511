// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { VehicleSale } from '../interfaces/VehicleSaleInterface';

const GetVehicleSaleForVehicle = (VehicleId: number, setVehicleSaleDetails: Dispatch<SetStateAction<VehicleSale>>) => {
    api.get(`vehicleSaleByVehicleId/${VehicleId}`).then(res => {
        if (res.status === 200) {
            setVehicleSaleDetails(res.data)
        }
    })
}

export default GetVehicleSaleForVehicle