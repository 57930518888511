// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Service } from '../../../global/interfaces/ServiceInterface';
import { Invoice, Order } from '../../../global/interfaces/PartsInterface';

interface VehicleAddedCosts {
  services: Service[];
  sales: Order[];
  invoices: Invoice[];
}

const GetVehicleAddedCosts = (
  VehicleId: number,
  setVehicleAddedCosts: Dispatch<SetStateAction<VehicleAddedCosts>>
) => {
  api.get(`vehicleAddedCosts/${VehicleId}`).then((res) => {
    setVehicleAddedCosts(res.data);
  });
};

export default GetVehicleAddedCosts;
