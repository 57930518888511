// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { DialogContentText, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import GetAllUsers from '../../../global/databaseLogic/GetAllUsers';
import GetAllCreditors from '../../../global/databaseLogic/GetAllCreditors';
// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';
import {
    Creditor,
    Site,
    User
} from '../../../global/interfaces/GeneralInterface';
interface PurchaseOrderTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

let siteOptions = [];
let userOptions = [];
let creditorOptions = [];

const PurchaseOrderTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: PurchaseOrderTableFilterProps) => {
    const [sitesUnaltered, setSitesUnaltered] = useState<Site[]>(siteOptions);
    const [usersUnaltered, setUsersUnaltered] = useState<User[]>(userOptions);
    const [creditorsUnaltered, setCreditorsUnaltered] =
        useState<Creditor[]>(creditorOptions);
    const [sites, setSites] = useState<Site[]>(siteOptions);
    const [users, setUsers] = useState<User[]>(userOptions);
    const [creditors, setCreditors] = useState<Creditor[]>(creditorOptions);

    // Get the initial dropdown values for dynamic fields
    useEffect(() => {
        GetAllSites(setSites);
        GetAllCreditors(setCreditors);
        GetAllUsers(setUsers, '?department=service');

        GetAllSites(setSitesUnaltered);
        GetAllCreditors(setCreditorsUnaltered);
        GetAllUsers(setUsersUnaltered, '?department=service');
    }, []);

    // When the filter is cleared reset all the options
    useEffect(() => {
        if (!isFilterActive()) {
            setSites(siteOptions);
            setUsers(userOptions);
            setCreditors(creditorOptions);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.purchaseOrderFilter;
        newFilter[property] = value;
        setFilter({ ...filter, purchaseOrderFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Purchase Order Start Range"
                            value={filter.purchaseOrderFilter.startDate}
                            onChange={(newValue) =>
                                handleUpdateFilter('startDate', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Purchase Order End Range"
                            value={filter.purchaseOrderFilter.endDate}
                            onChange={(newValue) =>
                                handleUpdateFilter('endDate', newValue)
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.purchaseOrderFilter.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sitesUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={creditors}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Creditors"
                        selectedValues={filter.purchaseOrderFilter.creditor}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = creditorsUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setCreditors(currentOptions);
                            handleUpdateFilter('creditor', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={users}
                        useTwoOptionLabels={true}
                        includeBar={false}
                        primaryOptionLabel={'firstName'}
                        secondaryOptionLabel={'lastName'}
                        textfieldLabel="User"
                        selectedValues={filter.purchaseOrderFilter.user}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = usersUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setUsers(currentOptions);
                            handleUpdateFilter('user', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default PurchaseOrderTableFilter;
