import { Step } from '../../interface';

export const NewPurchaseOrderSteps: Step[] = [
    {
        label: "Click 'Service' > 'Purchase Orders' in the sidebar to navigate to the Purchase Orders page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/po-01.png'
    },
    {
        label: 'Click New Purchase Order in the speed dial menu to open the new Purchase Order drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/po-02.png'
    },
    {
        label: 'Select the service you want to add a Purchase Order to from the dropdown list.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/po-03.png'
    },
    {
        label: 'Fill in the Purchase Order details: specify the amount, select a creditor from the dropdown list, and enter an order reference. Click the Save button to create the Purchase Order.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/po-04.png'
    },
    {
        label: 'The Purchase Order should now be in the Purchase Order list. Click the Purchase Order ID to view its details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/po-05.png'
    },
    {
        label: 'Click the Edit button to modify the Purchase Order details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/po-06.png'
    },
    {
        label: 'The Purchase Order should now be visible in the corresponding service "Sublets" table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/po-07.png'
    }
];

export const InvoicePurchaseOrderSteps: Step[] = [
    {
        label: 'A newly created Purchase Order has a default status of "Pending".',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-01.png'
    },
    {
        label: 'To invoice a Purchase Order, click "Admin" > "Enter Invoice" to navigate to the invoice page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-02.png'
    },
    {
        label: 'In the Generic Invoice tab, set the Invoice Type as Service.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-03.png'
    },
    {
        label: 'Select the Creditor, enter the document reference, the document date, GST type, site, and document total.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-04.png'
    },
    {
        label: 'Select the Purchase Order to invoice from the dropdown list. You can search by Purchase Order ID or booking ID.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-05.png'
    },
    {
        label: 'Fill in the invoice details. You can add multiple lines to the invoice. Ensure the sum of line totals and the document total are equal.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-06.png'
    },
    {
        label: 'Click the Save button to save the invoice.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-07.png'
    },
    {
        label: 'The status of the Purchase Order will change from "Pending" to "Invoiced".',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-08.png'
    },
    {
        label: 'You can find the newly created Purchase Order invoice under the "Creditor Invoices" tab, with a status of "Unpaid".',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-09.png'
    },
    {
        label: (
            <>
                To pay the invoice, click "Admin" - "Reconciliations" -
                "Creditor Reconciliation", locate the invoice to finalize and
                pay. Refer to{' '}
                <a
                    href="/support/admin/creditorReconciliation"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Creditor Reconciliation
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/purchaseOrders/invoice-po-10.png'
    }
];
