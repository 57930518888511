// IMPORTS
import { Typography } from '@mui/material';
import { Launch } from '@mui/icons-material';
// COMPONENTS
import Fab from '../../../../../global/Fab';
// LOGIC
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';

interface TradeInContentProps {
  selectedObject;
}

const TradeInContent = ({ selectedObject }: TradeInContentProps) => {
  return (
    <>
      <Typography variant="body1">
        <b>Vehicle:</b> {selectedObject.vehicleName}
      </Typography>
      <Typography variant="body1">
        <b>VIN:</b> {selectedObject.vehicleVin}
      </Typography>
      <br />
      <Typography variant="body1">
        <b>Amount:</b> {CurrencyFormatter(selectedObject.amount)}
      </Typography>
      <Typography variant="body1">
        <b>Date Bought:</b> {selectedObject.date}
      </Typography>
      <Typography variant="body1">
        <b>Bought by:</b> {selectedObject.submittedBy}
      </Typography>

      <Fab
        noSession={true}
        customIcon={<Launch />}
        onClick={() =>
          window.open(`/vehicles/view/${selectedObject.VehicleId}`, '_blank')
        }
      />
    </>
  );
};

export default TradeInContent;
