// IMPORTS
import { TableRow, Typography } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
// INTERFACES
import { Invoice } from '../../../global/interfaces/PartsInterface';
import { PaymentRun } from '../../../global/interfaces/AdminInterface';
import { Check, Clear } from '@mui/icons-material';
interface InvoicePaymentsProps {
    invoiceDetails: Invoice;
    payruns: { payrun: PaymentRun; payrunInvoices: Invoice[] }[];
}

const InvoicePayments = ({ invoiceDetails, payruns }: InvoicePaymentsProps) => {
    const columns = [
        { id: 0, label: 'Payment Run' },
        { id: 1, label: 'Amount (invoice)' },
        { id: 2, label: 'Amount (total)' },
        { id: 3, label: 'Date' },
        { id: 4, label: 'Reconciled' }
    ];

    const getTotalAmount = (payrunId) => {
        for (let payrun of payruns) {
            if (payrun.payrun.id === payrunId) {
                let total = 0;
                for (let invoice of payrun.payrunInvoices) {
                    let validPayment = invoice.paymentHistory.filter(
                        (x) => x.PaymentRunId === payrunId && x.removed !== true
                    );
                    total += validPayment[0].amount;
                }
                return CurrencyFormatter(total);
            }
        }
        return CurrencyFormatter(0);
    };

    const getReconciled = (row) => {
        if (payruns.length > 0 && row.PaymentRunId) {
            for (let payrun of payruns) {
                if (payrun.payrun.id === row.PaymentRunId) {
                    return payrun.payrun.reconciled;
                }
            }
        } else {
            return row.reconciled ? true : false;
        }
    };

    return (
        <Paper>
            <Typography variant="h6">Payments</Typography>
            <br />
            <DataTable columns={columns}>
                {invoiceDetails?.paymentHistory?.map((row) => {
                    if (row.removed) {
                        return null;
                    } else {
                        return (
                            <TableRow>
                                <DataCell>{row.PaymentRunId}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(row.amount)}
                                </DataCell>
                                <DataCell>
                                    {getTotalAmount(row.PaymentRunId)}
                                </DataCell>
                                <DataCell>{DateFormatter(row.date)}</DataCell>
                                <DataCell>
                                    {getReconciled(row) ? (
                                        <Check style={{ color: 'green' }} />
                                    ) : (
                                        <Clear style={{ color: 'red' }} />
                                    )}
                                </DataCell>
                            </TableRow>
                        );
                    }
                })}
            </DataTable>
        </Paper>
    );
};

export default InvoicePayments;
