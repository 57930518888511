import moment from 'moment';

const GroupTransactionByDate = (
    data,
    setReconciliationDetailsGroupedByWeek = null,
    setReconciliationUnalteredDetails = null,
    setSelectedOraUnrecTab = null,
    setSelectedBankUnrecTab = null,
    setSelectedOraRecTab = null,
    setSelectedBankRecTab = null
) => {
    let weeklyOraUnreconciled = [];
    let weeklyBankUnreconciled = [];
    let weeklyOraReconciled = [];
    let weeklyBankReconciled = [];

    for (let line of data.oraTransactionsUnreconciled) {
        let currentWeek = moment(line.date, 'DD/MM/YYYY').isValid()
            ? `${moment(line.date, 'DD/MM/YYYY').week().toString().length > 1 ? moment(line.date, 'DD/MM/YYYY').week().toString() : '0' + moment(line.date, 'DD/MM/YYYY').week().toString()}-${moment(line.date, 'DD/MM/YYYY').year()}`
            : 'Invalid Date';
        let index = weeklyOraUnreconciled.findIndex(
            (x) => x.week === currentWeek
        );
        if (index !== -1) {
            weeklyOraUnreconciled[index].lines.push(line);
        } else {
            weeklyOraUnreconciled.push({
                week: currentWeek,
                lines: [line]
            });
        }
    }

    for (let line of data.bankTransactionsUnreconciled) {
        let currentWeek = moment(line.date, 'DD/MM/YYYY').isValid()
            ? `${moment(line.date, 'DD/MM/YYYY').week().toString().length > 1 ? moment(line.date, 'DD/MM/YYYY').week().toString() : '0' + moment(line.date, 'DD/MM/YYYY').week().toString()}-${moment(line.date, 'DD/MM/YYYY').year()}`
            : 'Invalid Date';
        let index = weeklyBankUnreconciled.findIndex(
            (x) => x.week === currentWeek
        );
        if (index !== -1) {
            weeklyBankUnreconciled[index].lines.push(line);
        } else {
            weeklyBankUnreconciled.push({
                week: currentWeek,
                lines: [line]
            });
        }
    }

    for (let line of data.oraTransactionsReconciled) {
        let currentWeek = moment(line.date, 'DD/MM/YYYY').isValid()
            ? `${moment(line.date, 'DD/MM/YYYY').week().toString().length > 1 ? moment(line.date, 'DD/MM/YYYY').week().toString() : '0' + moment(line.date, 'DD/MM/YYYY').week().toString()}-${moment(line.date, 'DD/MM/YYYY').year()}`
            : 'Invalid Date';
        let index = weeklyOraReconciled.findIndex(
            (x) => x.week === currentWeek
        );
        if (index !== -1) {
            weeklyOraReconciled[index].lines.push(line);
        } else {
            weeklyOraReconciled.push({
                week: currentWeek,
                lines: [line]
            });
        }
    }

    for (let line of data.bankTransactionsReconciled) {
        let currentWeek = moment(line.date, 'DD/MM/YYYY').isValid()
            ? `${moment(line.date, 'DD/MM/YYYY').week().toString().length > 1 ? moment(line.date, 'DD/MM/YYYY').week().toString() : '0' + moment(line.date, 'DD/MM/YYYY').week().toString()}-${moment(line.date, 'DD/MM/YYYY').year()}`
            : 'Invalid Date';
        let index = weeklyBankReconciled.findIndex(
            (x) => x.week === currentWeek
        );
        if (index !== -1) {
            weeklyBankReconciled[index].lines.push(line);
        } else {
            weeklyBankReconciled.push({
                week: currentWeek,
                lines: [line]
            });
        }
    }

    // Sort by date descending, and remove invalid date from it
    let sortedWeeklyOraUnreconciled = weeklyOraUnreconciled
        .filter((x) => x.week !== 'Invalid Date')
        .sort((a, b) =>
            moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                ? 1
                : -1
        );
    let sortedWeeklyBankUnreconciled = weeklyBankUnreconciled
        .filter((x) => x.week !== 'Invalid Date')
        .sort((a, b) =>
            moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                ? 1
                : -1
        );
    let sortedWeeklyOraReconciled = weeklyOraReconciled
        .filter((x) => x.week !== 'Invalid Date')
        .sort((a, b) =>
            moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                ? 1
                : -1
        );
    let sortedWeeklyBankReconciled = weeklyBankReconciled
        .filter((x) => x.week !== 'Invalid Date')
        .sort((a, b) =>
            moment(b.week, 'ww-YYYY').isAfter(moment(a.week, 'ww-YYYY'))
                ? 1
                : -1
        );

    // Push invalid date at the end
    if (
        weeklyOraUnreconciled.findIndex((x) => x.week === 'Invalid Date') !== -1
    ) {
        sortedWeeklyOraUnreconciled.push(
            weeklyOraUnreconciled[
                weeklyOraUnreconciled.findIndex(
                    (x) => x.week === 'Invalid Date'
                )
            ]
        );
    }
    if (
        weeklyBankUnreconciled.findIndex((x) => x.week === 'Invalid Date') !==
        -1
    ) {
        sortedWeeklyBankUnreconciled.push(
            weeklyBankUnreconciled[
                weeklyBankUnreconciled.findIndex(
                    (x) => x.week === 'Invalid Date'
                )
            ]
        );
    }
    if (
        weeklyOraReconciled.findIndex((x) => x.week === 'Invalid Date') !== -1
    ) {
        sortedWeeklyOraReconciled.push(
            weeklyOraReconciled[
                weeklyOraReconciled.findIndex((x) => x.week === 'Invalid Date')
            ]
        );
    }
    if (
        weeklyBankReconciled.findIndex((x) => x.week === 'Invalid Date') !== -1
    ) {
        sortedWeeklyBankReconciled.push(
            weeklyBankReconciled[
                weeklyBankReconciled.findIndex((x) => x.week === 'Invalid Date')
            ]
        );
    }

    if (setReconciliationDetailsGroupedByWeek) {
        setReconciliationDetailsGroupedByWeek({
            oraTransactionsReconciled: sortedWeeklyOraReconciled,
            bankTransactionsReconciled: sortedWeeklyBankReconciled,
            oraTransactionsUnreconciled: sortedWeeklyOraUnreconciled,
            bankTransactionsUnreconciled: sortedWeeklyBankUnreconciled
        });
    }

    if (setReconciliationUnalteredDetails) {
        setReconciliationUnalteredDetails({
            oraTransactionsReconciled: sortedWeeklyOraReconciled,
            bankTransactionsReconciled: sortedWeeklyBankReconciled,
            oraTransactionsUnreconciled: sortedWeeklyOraUnreconciled,
            bankTransactionsUnreconciled: sortedWeeklyBankUnreconciled
        });
    }

    // Initialize to the first week of the array
    if (setSelectedOraUnrecTab)
        setSelectedOraUnrecTab(sortedWeeklyOraUnreconciled[0]?.week ?? '');
    if (setSelectedBankUnrecTab)
        setSelectedBankUnrecTab(sortedWeeklyBankUnreconciled[0]?.week ?? '');
    if (setSelectedOraRecTab)
        setSelectedOraRecTab(sortedWeeklyOraReconciled[0]?.week ?? '');
    if (setSelectedBankRecTab)
        setSelectedBankRecTab(sortedWeeklyBankReconciled[0]?.week ?? '');

    return {
        oraTransactionsReconciled: sortedWeeklyOraReconciled,
        bankTransactionsReconciled: sortedWeeklyBankReconciled,
        oraTransactionsUnreconciled: sortedWeeklyOraUnreconciled,
        bankTransactionsUnreconciled: sortedWeeklyBankUnreconciled
    };
};

export default GroupTransactionByDate;
