import { Step } from '../../interface';

export const CreditStockInvoiceSteps: Step[] = [
    {
        label: "Click 'Stock Control' > 'Credit Stock Invoice' in the sidebar to navigate to the credit stock invoice page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/creditStockInvoice/credit-stock-invoice-01.png'
    },
    {
        label: 'Select the site and supplier from the dropdown list.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/creditStockInvoice/credit-stock-invoice-02.png'
    },
    {
        label: 'Enter the part number or barcode and then press Enter to add the part to the list.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/creditStockInvoice/credit-stock-invoice-03.png'
    },
    {
        label: 'Enter the quantity to return, and confirm the line total. Repeat steps 3 and 4 to add more parts.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/creditStockInvoice/credit-stock-invoice-04.png'
    },
    {
        label: 'Enter the document details, including the document reference, date received, due date, and add freight if applicable. An Entered Total will be calculated automatically.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/creditStockInvoice/credit-stock-invoice-05.png'
    },
    {
        label: 'Click the Save button to credit the invoice.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/creditStockInvoice/credit-stock-invoice-06.png'
    },
    {
        label: "After the Credit Stock Invoice is created, it can be viewew in the Creditor's Credited Invoices tab, with a default pending status. Click the Invoice ID to access the invoice details page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/creditStockInvoice/credit-stock-invoice-07.png'
    },
    {
        label: (
            <>
                You then handle this Credited Stock Invoice as any other invoice
                through Creditor Reconciliation: navigate to Admin -
                Reconciliators - Creditor Reconciliation - Unconfirmed Invoices,
                and locate the invoice to finalize. Refer to{' '}
                <a
                    href="/support/admin/creditorReconciliation"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Creditor Reconciliation
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/creditStockInvoice/credit-stock-invoice-09.png'
    }
];
