import { Vehicle } from "../../../global/interfaces/VehicleSaleInterface";

const TotalValues = (type: string, listOfVehicles: Vehicle[]) => {
    var total = 0;

    listOfVehicles.forEach(row => {
        if (type === "gst") {
            total += row.gst
        } else if (type === "cost") {
            total += parseFloat(row.costPrice) + row.freight
        }
    })

    return total;
}

export default TotalValues