// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
// COMPONENTS
import Header from './ReconciliationComponents/Header';
import CashDenominations from './ReconciliationComponents/CashDenominations';
import OtherPaymentDenominations from './ReconciliationComponents/OtherPaymentDenominations';
import Fab from '../../../global/Fab';
// LOGICS
import HandleSubmitReconciliation from '../logic/HandleSubmitReconciliation';
import GetTillTransactions from '../logic/GetTillTransactions';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
interface ReconciliationProps {
    tillReconciliation: any;
    setTillReconciliation: Dispatch<SetStateAction<any>>;
    showSnackbar: showSnackbar;
}
const Reconciliation = ({
    tillReconciliation,
    setTillReconciliation,
    showSnackbar
}: ReconciliationProps) => {
    const [selectedTill, setSelectedTill] = useState<number>(0);

    useEffect(() => {
        GetTillTransactions(
            selectedTill,
            tillReconciliation,
            setTillReconciliation
        );
        // eslint-disable-next-line
    }, [selectedTill]);

    return (
        <>
            <Header
                tillReconciliation={tillReconciliation}
                setTillReconciliation={setTillReconciliation}
                selectedTill={selectedTill}
                setSelectedTill={setSelectedTill}
                showSnackbar={showSnackbar}
            />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <CashDenominations
                        tillReconciliation={tillReconciliation}
                        setTillReconciliation={setTillReconciliation}
                        readOnly={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <OtherPaymentDenominations
                        tillReconciliation={tillReconciliation}
                        setTillReconciliation={setTillReconciliation}
                        readOnly={false}
                    />
                </Grid>
            </Grid>

            <Fab
                noSession={true}
                editIcon={false}
                onClick={() =>
                    HandleSubmitReconciliation(
                        tillReconciliation,
                        parseInt(localStorage.getItem('SiteId')),
                        selectedTill,
                        showSnackbar
                    )
                }
            />
        </>
    );
};

export default Reconciliation;
