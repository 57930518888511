// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import {
    LibraryAddCheck,
    ChangeCircle,
    CheckBoxOutlineBlank,
    DisabledByDefault
} from '@mui/icons-material';

// LOGIC
import RemoveUnselectedParts from '../../logic/RemoveUnselectedParts';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Table } from '../../interfaces/StockOrderInterface';

interface SubmissionButtonsProps {
    tables: Table[];
    setTables: Dispatch<SetStateAction<Table[]>>;
    selectedSite: number;
    showSnackbar: showSnackbar;
    staging: boolean;
    setStaging: Dispatch<SetStateAction<boolean>>;
    reverse?: boolean;
    setReverse?: Dispatch<SetStateAction<boolean>>;
}

const SubmissionButtons = (props: SubmissionButtonsProps) => {
    const [boolType, setBoolType] = useState(true);

    const checkAnySelected = () => {
        var anySelected = false;
        props.tables.forEach((table) => {
            table.orderLines.forEach((part) => {
                if (part.selected) {
                    anySelected = true;
                }
            });
        });

        return anySelected;
    };

    const selections = () => {
        var currentTables = [...props.tables];

        currentTables.forEach((table) => {
            table.orderLines.forEach((part) => {
                part.selected = boolType;
            });
        });

        setBoolType(!boolType);
        props.setTables(currentTables);
    };

    return props.tables.length > 0 ? (
        <Grid container spacing={0}>
            <Grid item xs={2} textAlign="left">
                <Tooltip
                    title={boolType ? 'Select All Parts' : 'Deselect All Parts'}
                >
                    <IconButton onClick={() => selections()}>
                        {boolType ? (
                            <LibraryAddCheck />
                        ) : (
                            <CheckBoxOutlineBlank />
                        )}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Remove Unselected Parts.">
                    <IconButton
                        onClick={() =>
                            RemoveUnselectedParts(props.tables, props.setTables)
                        }
                    >
                        <DisabledByDefault />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Reverse Order Lines.">
                    <IconButton
                        onClick={() => props.setReverse(!props.reverse)}
                    >
                        <ChangeCircle />
                    </IconButton>
                </Tooltip>
            </Grid>

            <Grid item xs={7}></Grid>

            <Grid item xs={3} textAlign="right">
                <Button
                    variant="contained"
                    onClick={() =>
                        checkAnySelected()
                            ? props.setStaging(true)
                            : props.showSnackbar(
                                  'No parts selected, please select some parts to complete the order.',
                                  '',
                                  'error'
                              )
                    }
                >
                    Submit Order With Selected Parts
                </Button>
            </Grid>
        </Grid>
    ) : null;
};

export default SubmissionButtons;
