import { VehicleSale } from '../../../global/interfaces/VehicleSaleInterface';
import { ToFixed } from '../../../global/logic/Formatters';

const getGovernmentCharges = (saleDetails) => {
    let governmentCharges = 0;
    if (saleDetails.includesOnRoadCosts) {
        if (saleDetails.registrationPrice) {
            governmentCharges += parseFloat(saleDetails.registrationPrice);
        }
        if (saleDetails.ctpPrice) {
            governmentCharges += parseFloat(saleDetails.ctpPrice);
        }
        if (saleDetails.transferPrice) {
            governmentCharges += parseFloat(saleDetails.transferPrice);
        }
        if (saleDetails.stampDutyPrice && !saleDetails.excludeStampDuty) {
            governmentCharges += parseFloat(saleDetails.stampDutyPrice);
        }
    }

    return ToFixed(governmentCharges);
};

const CalculateVehicleSaleRevenue = (vehicleSales: VehicleSale[]) => {
    let vehicleSalesData = [];
    let totalRevenue = 0;
    // let totalFinalisedCost = 0;

    vehicleSales.forEach((vehicleSale) => {
        let vehicleNetSalePrice = 0;

        if (vehicleSale.excludeGST) {
            vehicleNetSalePrice = Number(vehicleSale.totalPurchasePrice);
        } else {
            let governmentCharges = getGovernmentCharges(vehicleSale);
            vehicleNetSalePrice =
                (Number(vehicleSale.totalPurchasePrice) - governmentCharges) /
                1.1;
        }
        let saleLine = {
            VehicleSaleId: vehicleSale.id,
            UserId: vehicleSale['User.id'],
            userName:
                vehicleSale['User.firstName'] +
                ' ' +
                vehicleSale['User.lastName'],
            isManufactureOrder: vehicleSale.manufacturerOrder,
            stockNumber: vehicleSale['Vehicle.stockNumber'],
            customerName:
                vehicleSale['Customer.firstName']?.charAt(0) +
                '. ' +
                vehicleSale['Customer.lastName'],

            vehicleCondition: vehicleSale['Vehicle.condition'],
            vehicleMakeId:
                vehicleSale['Vehicle.Make.id'] ||
                vehicleSale['NewVehicle.Make.id'],
            vehicleMake:
                vehicleSale['Vehicle.Make.name'] ||
                vehicleSale['NewVehicle.Make.name'],
            vehicleModelId:
                vehicleSale['Vehicle.Model.id'] ||
                vehicleSale['NewVehicle.Model.id'],
            vehicleModel:
                vehicleSale['Vehicle.Model.name'] ||
                vehicleSale['NewVehicle.Model.name'],
            finalisedDate: vehicleSale.finalisedDate,
            // vehicleCostPrice: vehicleSale["Vehicle.costPrice"] ? vehicleSale["Vehicle.costPrice"] : 0,
            vehicleSalePrice: vehicleSale.vehicleSalePrice ?? 0,

            adminLoadCost: vehicleSale['Vehicle.adminLoadCost'],
            // extraCost: getExtraCostsAllowance(vehicleSale["Vehicle.extraCosts"]),
            // serviceCosts: getServiceAllowance(vehicleSale?.vehicleServices),
            deliveryPrice: vehicleSale.deliveryPrice,
            // rebatePrice: rebatePrice > 0 ? rebatePrice / 1.1 : null,
            // warrantyCost: warrantyPrice,
            // csmAllowance: csmAllowance,
            // totalInvoice: totalInvoice,

            // totalCost: vehicleCostPrice + csmAllowance + totalInvoice - (sale.rebatePrice ? sale.rebatePrice / 1.1 : 0),
            totalSale: vehicleNetSalePrice // This is currently the total price put in by the user minus the government charges, and then GST is removed
        };

        vehicleSalesData.push(saleLine);
        totalRevenue += vehicleNetSalePrice;
        totalRevenue = ToFixed(totalRevenue);
    });

    return {
        vehicleSalesData,
        totalRevenue
        // totalFinalisedCost
    };
};

export default CalculateVehicleSaleRevenue;
