// LOGIC
import api from '../../../../api';

const GetAllModels = (setModels, makes?, MakeId?) => {
  if (makes && makes.length > 0) {
    let formattedMakes = [];

    makes.forEach((make) => {
      formattedMakes.push(make.id);
    });

    api.get(`allModels?${formattedMakes}`).then((res) => {
      setModels(res.data);
    });
  } else if (MakeId) {
    api.get(`allModels?MakeId=${MakeId}`).then((res) => {
      setModels(res.data);
    });
  } else {
    api.get(`allModels`).then((res) => {
      setModels(res.data);
    });
  }
};

export default GetAllModels;
