import React from 'react'; // do not remove this line

import DataTable from '../tableComponents/DataTable';
import DataCell from '../tableComponents/DataCell';
import Paper from '../Paper';
import { Grid, TableRow, Typography } from '@mui/material';
import { MonthComparisonMetric } from '../dashboard/interface';
import { Dayjs } from 'dayjs';
import { DateFormatter } from '../logic/Formatters';

interface DataProps {
    [key: string]: MonthComparisonMetric[];
}

interface MonthlyMetricsComparisonTableProps {
    data: DataProps;
    selectedStartDate: Dayjs;
    selectedEndDate: Dayjs;
    compareType: 'Month' | 'Year';
    compareStartDate: Dayjs;
    compareEndDate: Dayjs;
}

const MonthlyMetricsComparisonTable = ({
    data,
    selectedStartDate,
    selectedEndDate,
    compareStartDate,
    compareEndDate
}: MonthlyMetricsComparisonTableProps) => {
    const [title, metrics] = Object.entries(data)[0];

    const columns = [
        { id: 0, label: '' },
        {
            id: 1,
            label:
                DateFormatter(selectedStartDate.toString()) +
                '-' +
                DateFormatter(selectedEndDate.toString())
        },
        {
            id: 2,
            label:
                DateFormatter(compareStartDate.toString()) +
                '-' +
                DateFormatter(compareEndDate.toString())
        },
        { id: 3, label: 'Difference' }
    ];

    return (
        <Grid item xs={12} md={6}>
            <Paper>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {title}
                </Typography>
                <DataTable columns={columns}>
                    {metrics.map((item) => {
                        return (
                            <TableRow>
                                <DataCell>{item.label}</DataCell>
                                <DataCell>{item.selectedMonth}</DataCell>
                                <DataCell>{item.comparedMonth}</DataCell>
                                <DataCell>{item.difference}</DataCell>
                            </TableRow>
                        );
                    })}
                </DataTable>
            </Paper>
        </Grid>
    );
};

export default MonthlyMetricsComparisonTable;
