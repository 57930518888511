import { Dispatch, SetStateAction } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { TestRide } from '../../../global/interfaces/VehicleSaleInterface';
import api from '../../../../../api';
import dayjs from 'dayjs';

const GetTestRideAgreement = (
    leadId: number,
    setTestRideData: Dispatch<SetStateAction<TestRide>>,
    sigCanvas: SignatureCanvas,
    sigCanvas2: SignatureCanvas,
    setPageLoading: Dispatch<SetStateAction<boolean>>,
    setDisabled: Dispatch<SetStateAction<boolean>>
) => {
    api.get('testRideNextGen/' + leadId.toString()).then((res) => {
        setPageLoading(false);
        let leadData = res.data.leadData;

        if (leadData) {
            setTestRideData({
                name: leadData.name,
                address: leadData.address,
                testRideRegistration: leadData.testRideRegistration,
                driverLicenceClass: leadData.driverLicenceClass,
                driverLicenceNumber: leadData.driverLicenceNumber,
                date: dayjs(leadData.date),
                departureTime: dayjs()
                    .hour(leadData.departureTime.split(':')[0])
                    .minute(leadData.departureTime.split(':')[1]),
                returnTime: dayjs()
                    .hour(leadData.returnTime.split(':')[0])
                    .minute(leadData.returnTime.split(':')[1])
            });
            sigCanvas.current.fromDataURL(res.data.riderSignature);
            sigCanvas2.current.fromDataURL(res.data.witnessSignature);
            sigCanvas.current.off();
            sigCanvas2.current.off();

            setDisabled(true);
        } else {
            setPageLoading(false);
        }
    });
};

export default GetTestRideAgreement;
