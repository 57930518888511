import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const HandleMergeAccount = (
    mergedAccountId: number,
    targetAccountId: number,
    showSnackbar: showSnackbar
) => {
    api.put('mergeAccount', {
        mergedAccountId: mergedAccountId,
        targetAccountId: targetAccountId
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Account successfully merged.',
                'The page will now refresh.',
                'success'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};
