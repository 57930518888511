// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { CustomerVehicle } from '../../../global/interfaces/ServiceInterface';
import dayjs from 'dayjs';

const GetCustomerVehicles = (
  CustomerId: number,
  setVehicles: Dispatch<SetStateAction<CustomerVehicle[]>>
) => {
  api.get(`allCustomerVehicles/${CustomerId}`).then((res) => {
      let results = res.data;
      for (let row of results) {
          row.year = dayjs(row.year);
      }
      setVehicles(results);
  });
};

export default GetCustomerVehicles;
