// IMPORTS
import { useEffect, useState } from 'react';
import { TableRow, Typography } from '@mui/material';
import moment from 'moment';
// COMPONENTS
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../../global/tableComponents/DataCell';
// LOGIC
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import GetSpecificTillTransactions from '../../../logic/Reconciliator/GetSpecificTillTransactions';
interface TillReconciliationContentProps {
    selectedObject;
}

const TillReconciliationContent = ({
    selectedObject
}: TillReconciliationContentProps) => {
    const [transactions, setTransactions] = useState([]);

    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Department' },
        { id: 2, label: 'Time' },
        { id: 3, label: 'User' },
        { id: 4, label: 'Amount' }
    ];

    useEffect(() => {
        GetSpecificTillTransactions(
            selectedObject.TillId,
            selectedObject.createdAt,
            selectedObject.reference.toLowerCase(),
            setTransactions
        );
        // eslint-disable-next-line
    }, []);

    const getTransactionTotal = () => {
        let total =
            transactions.length > 0
                ? transactions
                      .map((item) => parseFloat(item.amount))
                      .reduce((acc, amount) => acc + amount)
                : 0;
        return total;
    };

    return (
        <>
            <Typography variant="body1">
                <b>Submitted by:</b> {selectedObject.submittedBy}
            </Typography>
            <Typography variant="body1">
                <b>Expected Amount:</b>{' '}
                {CurrencyFormatter(selectedObject.amount)}
            </Typography>
            <Typography variant="body1">
                <b>Counted Amount:</b>{' '}
                {CurrencyFormatter(selectedObject.countedAmount)}
            </Typography>
            <br />
            <DataTable columns={columns}>
                {transactions.map((line) => (
                    <TableRow>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(
                                    `${line.urlPrefix}${line.OrderId}`,
                                    '_blank'
                                )
                            }
                        >
                            {line.OrderId}
                        </DataCellColoured>
                        <DataCell>{line.orderType}</DataCell>
                        <DataCell>
                            {moment(line.timestamp).format('h:MM A')}
                        </DataCell>
                        <DataCell>{line.user}</DataCell>
                        <DataCell>{line.amount}</DataCell>
                    </TableRow>
                ))}
                <TableRow>
                    <DataCell colSpan={3}></DataCell>
                    <DataCell>
                        <b>Total</b>
                    </DataCell>
                    <DataCell>
                        <b>{CurrencyFormatter(getTransactionTotal())}</b>
                    </DataCell>
                </TableRow>
            </DataTable>
        </>
    );
};

export default TillReconciliationContent;
