// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    MenuItem,
    TableRow,
    TextField,
    Tooltip,
    useTheme
} from '@mui/material';
import { Close, Restore } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import UploadTransactionFile from '../../logic/BankTransactions/UploadTransactionFile';
// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface FileEditorContentProps {
    fileName: string;
    importFile;
    setImportFile;
    selectedHeaders: string[];
    setSelectedHeaders: Dispatch<SetStateAction<string[]>>;
    showSnackbar: showSnackbar;
}
const FileEditorContent = ({
    fileName,
    importFile,
    setImportFile,
    selectedHeaders,
    setSelectedHeaders,
    showSnackbar
}: FileEditorContentProps) => {
    const currentTheme = useTheme();
    const headerOptions = [
        { id: 0, label: 'Date', value: 'date' },
        { id: 1, label: 'Reference', value: 'reference' },
        { id: 2, label: 'Debit Amount', value: 'debitAmount' },
        { id: 3, label: 'Credit Amount', value: 'creditAmount' }
    ];
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const changeColumnHeaders = (index, selectedValue) => {
        var updatedHeaders = [...selectedHeaders];
        updatedHeaders[index] = selectedValue;
        setSelectedHeaders(updatedHeaders);
    };

    const removeTransactionLine = (id) => {
        var newArray = [];
        Object.keys(importFile).forEach((line) => {
            if (parseInt(line) === id) {
                importFile[line].removed
                    ? (importFile[line].removed = false)
                    : (importFile[line].removed = true);
            }
            return newArray.push(importFile[line]);
        });
        setImportFile(newArray);
    };

    const removeTransactionColumn = (index) => {
        let newHeaders = [];
        for (let i = 0; i < selectedHeaders.length; i++) {
            if (i !== index) {
                newHeaders.push(selectedHeaders[i]);
            }
        }

        let newImportFile = [];

        importFile.forEach((line) => {
            let newLine = { removed: false };
            let counter = 0;

            for (let i = 0; i < selectedHeaders.length; i++) {
                if (i !== index) {
                    newLine[counter] = line[i];
                    counter++;
                }
            }

            if (line.removed) {
                newLine.removed = true;
            }

            newImportFile.push(newLine);
        });

        setSelectedHeaders(newHeaders);
        setImportFile(newImportFile);
    };

    return (
        <>
            <DialogTitle>File Editor</DialogTitle>
            <DialogContent>
                Please remove any non-data rows, including any header rows,from
                the file and specify column headers.
                {importFile !== null && (
                    <DataTable columns={[]}>
                        <TableRow>
                            {selectedHeaders?.map((header, index) => (
                                <DataCell width="20%">
                                    <TextField
                                        size="small"
                                        select
                                        fullWidth
                                        value={header[index]}
                                        onChange={(e) =>
                                            changeColumnHeaders(
                                                index,
                                                e.target.value
                                            )
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        onClick={() =>
                                                            removeTransactionColumn(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    >
                                        {headerOptions.map((option) => (
                                            <MenuItem value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </DataCell>
                            ))}
                            <DataCell width="10%"></DataCell>
                        </TableRow>
                        {importFile.map((item, index) => (
                            <TableRow
                                sx={{
                                    backgroundColor: item['removed']
                                        ? currentTheme.palette.mode === 'dark'
                                            ? '#656565'
                                            : '#fff3e0'
                                        : ''
                                }}
                            >
                                {Object.keys(item).map((line, j) => {
                                    if (line !== 'removed') {
                                        return (
                                            <DataCell> {item[line]} </DataCell>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                                <DataCell>
                                    <Tooltip
                                        title={
                                            item['removed']
                                                ? 'Restore Row'
                                                : 'Remove Row'
                                        }
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={(e) =>
                                                removeTransactionLine(index)
                                            }
                                        >
                                            {item['removed'] ? (
                                                <Restore />
                                            ) : (
                                                <Close />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </DataCell>
                            </TableRow>
                        ))}
                    </DataTable>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setImportFile(null)}>Close</Button>
                <LoadingButton
                    loading={buttonLoading}
                    variant="contained"
                    onClick={() =>
                        UploadTransactionFile(
                            importFile,
                            selectedHeaders,
                            fileName,
                            setButtonLoading,
                            showSnackbar
                        )
                    }
                >
                    Upload Transactions
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default FileEditorContent;
