// IMPORTS
import { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
// COMPONENTS
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { withSnackbar } from '../../../global/WrappingSnackbar';
// LOGIC
import HandleResetPassword from '../../password/logic/HandleResetPassword';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface ChangePasswordContentProps {
    loginDetails: {
        username: string;
        password: string;
        usernameError: boolean;
        passwordError: boolean;
        usernameErrorMessage: string;
        passwordErrorMessage: string;
    };
    oldPassword: string;
    showSnackbar: showSnackbar;
}
const ChangePasswordContent = ({
    loginDetails,
    oldPassword,
    showSnackbar
}: ChangePasswordContentProps) => {
    const [passwordData, setPasswordData] = useState<{
        old: string;
        new: string;
        confirm: string;
    }>({
        old: null,
        new: null,
        confirm: null
    });
    const [errors, setErrors] = useState<{
        old: boolean;
        new: boolean;
        confirm: boolean;
        match: boolean;
    }>({
        old: false,
        new: false,
        confirm: false,
        match: false
    });
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
        setPasswordData({ ...passwordData, old: oldPassword });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <TextField
                required
                fullWidth
                label="New Password"
                margin="normal"
                type="password"
                InputLabelProps={{ shrink: true }}
                value={passwordData.new}
                onChange={(e) =>
                    setPasswordData({ ...passwordData, new: e.target.value })
                }
                error={errors.new ? true : errors.match ? true : false}
                helperText={
                    errors.new
                        ? 'Please enter your new password into this field.'
                        : null
                }
            />

            <TextField
                required
                fullWidth
                label="Confirm New Password"
                margin="normal"
                type="password"
                InputLabelProps={{ shrink: true }}
                value={passwordData.confirm}
                onChange={(e) =>
                    setPasswordData({
                        ...passwordData,
                        confirm: e.target.value
                    })
                }
                onKeyPress={(e) => {
                    if (e.key === 'Enter')
                        HandleResetPassword(
                            passwordData,
                            setErrors,
                            setButtonLoading,
                            showSnackbar,
                            loginDetails
                        );
                }}
                error={errors.confirm ? true : errors.match ? true : false}
                helperText={
                    errors.confirm
                        ? 'Please enter your new password into this field to confirm.'
                        : errors.match
                          ? 'New passwords do not match.'
                          : null
                }
            />

            <LoadingButton
                variant="contained"
                loading={buttonLoading}
                onClick={() =>
                    HandleResetPassword(
                        passwordData,
                        setErrors,
                        setButtonLoading,
                        showSnackbar,
                        loginDetails
                    )
                }
            >
                Change Password
            </LoadingButton>
        </>
    );
};

export default withSnackbar(ChangePasswordContent);
