import {
  Grid,
  Pagination,
  TableRow,
  TextField
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

// COMPONENT
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';

// INTERFACE
import { Brand, Unit } from '../../../../global/interfaces/PartsInterface';

interface TempBrand extends Brand {
  nbOfUnit: number;
}

interface CategoryDialogContentProps {
  selectedBrand: TempBrand;
  filterValue: string;
  filterListOfUnit: Unit[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSelectedBrand: Dispatch<SetStateAction<TempBrand>>;
  handleFilterValueChange: (value: string) => void;
}

const BrandDialogContent = ({
  selectedBrand,
  filterValue,
  filterListOfUnit,
  page,
  setPage,
  setSelectedBrand,
  handleFilterValueChange
}: CategoryDialogContentProps) => {
  // Dialog state
  const dialogColumns = [
    { id: 0, label: 'Part Number' },
    { id: 1, label: 'Part Name' }
  ];

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            size="small"
            label="Part Number"
            value={filterValue}
            onChange={(e) => handleFilterValueChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={7} textAlign="right">
          <Pagination
            color="primary"
            count={
              filterListOfUnit.length <= 10
                ? 1
                : Math.ceil(filterListOfUnit.length / 10)
            }
            page={page}
            siblingCount={0}
            boundaryCount={1}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'right', marginTop: 1.4 }}
          />
        </Grid>
      </Grid>
      <br />
      <DataTable columns={dialogColumns}>
        {filterListOfUnit.slice((page - 1) * 10, page * 10).map((unit) => (
          <TableRow>
            <DataCellColoured
              handleClick={() =>
                window.open('/inventory/view/' + unit.id, '_blank')
              }
            >
              {unit.partNumber}
            </DataCellColoured>
            <DataCell>{unit.name}</DataCell>
          </TableRow>
        ))}
      </DataTable>
    </>
  );
};

export default BrandDialogContent;
