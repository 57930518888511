// IMPORTS
import { useState, useEffect, useContext } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    debounce,
    DialogTitle
} from '@mui/material';
import { SiteContext } from '../../../main/Main';
import { Prompt } from 'react-router-dom';
// COMPONENTS
import Fab from '../../global/Fab';
import AddVehicleTable from './component/AddVehicleTable';
import VehicleInvoiceDetails from './component/VehicleInvoiceDetails';
import NewBicyclesForm from './component/NewBicyclesForm';
import NewMotorcyclesForm from './component/NewMotorcyclesForm';
import PageWrapper from '../../global/PageWrapper';
import EmptyMakeTableDialogContent from '../emptyTableDialog/EmptyMakeTableDialogContent';
import EmptyModelTableDialogContent from '../emptyTableDialog/EmptyModelTableDialogContent';
import { ImportMakeCSVContent } from '../emptyTableDialog/ImportMakeCSVContent';
import { ImportModelCSVContent } from '../emptyTableDialog/ImportModelCSVContent';
// LOGIC
import GetVehicleSpecificsLists from './logic/GetVehicleSpecificLists';
import AddMotorcyclesToList from './logic/AddMotorcyclesToList';
import TotalValues from './logic/TotalValues';
import SubmitNewVehicle from './logic/SubmitNewVehicle';
import AddBicyclesToList from './logic/AddBicyclesToList';
// INTERFACE
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import {
    BodyType,
    DriveType,
    FuelType,
    Make,
    Model,
    Series,
    Vehicle
} from '../../global/interfaces/VehicleSaleInterface';
import { Creditor, Site } from '../../global/interfaces/GeneralInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

const InvoiceVehicle = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [shouldBlockNavigation, setShouldBlockNavigation] =
        useState<boolean>(false);

    const currentSite = useContext<Site>(SiteContext);
    const [addVehicleOpen, setAddVehicleOpen] = useState<boolean>(false);

    const [emptyField, setEmptyField] = useState<string>('');

    const [listOfVehicles, setListOfVehicles] = useState<Vehicle[]>([]);
    const [selectedCreditor, setSelectedCreditor] = useState<Creditor>(null);
    const [selectedSite, setSelectedSite] = useState<number>(
        parseInt(localStorage.getItem('SiteId'))
    );
    const [documentReference, setDocumentReference] = useState<string>('');
    const [totalOnInvoice, setTotalOnInvoice] = useState<number>(0);
    const [gstMethod, setGstMethod] = useState<string>('Including');
    const [invoiceDate, setInvoiceDate] = useState<string>(null);
    const [responseCode, setResponseCode] = useState<number>(null);

    const [specificLists, setSpecificLists] = useState<{
        Make: Make[];
        Model: Model[];
        Series: Series[];
        FuelType: FuelType[];
        Creditors: Creditor[];
        DriveType: DriveType[];
        BodyType: BodyType[];
    }>({
        Make: [
            { id: 0, name: '' },
            { id: 0, name: '' }
        ], // Put two element in it to prevent dialog appearing on page laod
        Model: [
            { id: 0, name: '', MakeId: 0 },
            { id: 0, name: '', MakeId: 0 }
        ], // Put two element in it to prevent dialog appearing on page laod
        Series: [],
        FuelType: [],
        Creditors: [],
        DriveType: [],
        BodyType: []
    });

    const [specificInputs, setSpecificInputs] = useState<{
        makeInput: string;
        modelInput: string;
        seriesInput: string;
        fuelTypeInput: string;
    }>({
        makeInput: '',
        modelInput: '',
        seriesInput: '',
        fuelTypeInput: ''
    });
    const [newVehicle, setNewVehicle] = useState<Vehicle>({
        Make: null,
        Model: null,
        Series: null,
        FuelType: null,
        year: null,
        cylinders: null,
        costPrice: null,
        registrationDueDate: null,
        complianceDate: '',
        vin: null,
        DriveType: null,
        BodyType: null,
        condition: 'New',
        engineNumber: '',
        learnerApproved: false,
        registrationNumber: '',
        transmission: 'Manual',
        odometer: null,
        engineSize: null,
        gears: null,
        priceRRP: null,
        adminLoadCost: null,
        trustReceipt: null,
        colour: null
    });

    useEffect(() => {
        GetVehicleSpecificsLists({ setSpecificLists: setSpecificLists });
        updateGstMethod('Including');
        // eslint-disable-next-line
    }, []);

    const updateGstMethod = (value: string) => {
        listOfVehicles.forEach((row) => {
            if (value === 'Excluding') {
                row.gst = (parseFloat(row.costPrice) + row.freight) * 0.1;
            } else {
                row.gst =
                    parseFloat(row.costPrice) +
                    row.freight -
                    (parseFloat(row.costPrice) + row.freight) / 1.1;
            }
        });
        setGstMethod(value);
    };

    return (
        <PageWrapper>
            <Prompt
                when={shouldBlockNavigation}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <Typography variant="h4" align="left">
                Invoice Vehicles
            </Typography>
            <br />

            <AddVehicleTable
                specificLists={specificLists}
                setAddVehicleOpen={setAddVehicleOpen}
                listOfVehicles={listOfVehicles}
                setListOfVehicles={setListOfVehicles}
                selectedCreditor={selectedCreditor}
                setSelectedCreditor={setSelectedCreditor}
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                gstMethod={gstMethod}
                updateGstMethod={updateGstMethod}
            />
            <br />
            <VehicleInvoiceDetails
                listOfVehicles={listOfVehicles}
                totalOnInvoice={totalOnInvoice}
                setTotalOnInvoice={setTotalOnInvoice}
                documentReference={documentReference}
                setDocumentReference={setDocumentReference}
                invoiceDate={invoiceDate}
                setInvoiceDate={setInvoiceDate}
                responseCode={responseCode}
                setResponseCode={setResponseCode}
                gstMethod={gstMethod}
            />

            <Fab
                editIcon={false}
                noSession={true}
                onClick={() =>
                    SubmitNewVehicle(
                        listOfVehicles,
                        selectedCreditor,
                        selectedSite,
                        invoiceDate,
                        documentReference,
                        totalOnInvoice,
                        TotalValues('cost', listOfVehicles),
                        TotalValues('gst', listOfVehicles),
                        gstMethod,
                        responseCode,
                        showSnackbar
                    )
                }
            />

            <Dialog
                open={addVehicleOpen}
                onClose={() => setAddVehicleOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <DialogTitle>Vehicle Details</DialogTitle>
                <DialogContent>
                    {currentSite.vehicleTypeSold === 'Motorcycles' ? (
                        <NewMotorcyclesForm
                            specificLists={specificLists}
                            setSpecificLists={setSpecificLists}
                            specificInputs={specificInputs}
                            setSpecificInputs={setSpecificInputs}
                            newVehicle={newVehicle}
                            setNewVehicle={setNewVehicle}
                            gstMethod={gstMethod}
                        />
                    ) : currentSite.vehicleTypeSold === 'Bicycles' ? (
                        <NewBicyclesForm
                            specificLists={specificLists}
                            setSpecificLists={setSpecificLists}
                            specificInputs={specificInputs}
                            setSpecificInputs={setSpecificInputs}
                            newVehicle={newVehicle}
                            setNewVehicle={setNewVehicle}
                            gstMethod={gstMethod}
                        />
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => setAddVehicleOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={debounce(() => {
                            if (currentSite.vehicleTypeSold === 'Motorcycles') {
                                AddMotorcyclesToList({
                                    newVehicle: newVehicle,
                                    specificInputs: specificInputs,
                                    listOfVehicles: listOfVehicles,
                                    gstMethod: gstMethod,
                                    setNewVehicle: setNewVehicle,
                                    setListOfVehicles: setListOfVehicles,
                                    setAddVehicleOpen: setAddVehicleOpen,
                                    setSpecificInputs: setSpecificInputs,
                                    showSnackbar: showSnackbar,
                                    setShouldBlockNavigation:
                                        setShouldBlockNavigation
                                });
                            } else if (
                                currentSite.vehicleTypeSold === 'Bicycles'
                            ) {
                                AddBicyclesToList({
                                    newVehicle: newVehicle,
                                    specificInputs: specificInputs,
                                    listOfVehicles: listOfVehicles,
                                    gstMethod: gstMethod,
                                    setNewVehicle: setNewVehicle,
                                    setListOfVehicles: setListOfVehicles,
                                    setAddVehicleOpen: setAddVehicleOpen,
                                    setSpecificInputs: setSpecificInputs,
                                    showSnackbar: showSnackbar,
                                    setShouldBlockNavigation:
                                        setShouldBlockNavigation
                                });
                            }
                        }, 300)}
                    >
                        Add Vehicle
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={
                    (specificLists.Make.length === 0 ||
                        specificLists.Model.length === 0) &&
                    emptyField === ''
                }
                onClose={() => setEmptyField('noDialog')}
                maxWidth="md"
                fullWidth
            >
                {specificLists.Make.length === 0 ? (
                    <EmptyMakeTableDialogContent
                        setEmptyField={setEmptyField}
                    />
                ) : specificLists.Model.length === 0 ? (
                    <EmptyModelTableDialogContent
                        setEmptyField={setEmptyField}
                    />
                ) : null}
            </Dialog>

            <Dialog
                open={
                    (specificLists.Make.length === 0 ||
                        specificLists.Model.length === 0) &&
                    (emptyField === 'Make' || emptyField === 'Model')
                }
                onClose={() => setEmptyField('')}
                maxWidth="md"
                fullWidth
            >
                {emptyField === 'Make' ? (
                    <ImportMakeCSVContent showSnackbar={showSnackbar} />
                ) : emptyField === 'Model' ? (
                    <ImportModelCSVContent showSnackbar={showSnackbar} />
                ) : null}
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(InvoiceVehicle);
