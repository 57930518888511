import DocumentationPage from '../../DocumentationPage';
import { RegoConciliatorSteps } from '../../data/admin/regoReconciliator';

const RegoReconciliatorSupportPage = () => {
    const contentSections = [
        {
            id: 'rego-reconciliator',
            title: 'Rego Reconciliator',
            href: '/support/admin/registrationReconciliation/#rego-reconciliator',
            description:
                'To reconcile vehicle registrations, follow these steps:',
            steps: RegoConciliatorSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Rego Reconciliator"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default RegoReconciliatorSupportPage;
