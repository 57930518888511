import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TableRow,
    TextField,
    Typography
} from '@mui/material';

// COMPONENT
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import Autocomplete from '../../../global/Autocomplete';

// LOGIC
import GetAllSuppliers from '../../../global/databaseLogic/GetAllSuppliers';
import HandleAddPart from '../../../global/logic/HandleAddPart';
import ImportHondaInvoice from '../logic/ImportHondaInvoice';

// INTERFACES
import { Check } from '@mui/icons-material';
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import {
    HondaInvoicePartLine,
    StockInvoice
} from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface PartNotFoundDrawerContentProps {
    (props: {
        partList: HondaInvoicePartLine[];
        setPartList: Dispatch<SetStateAction<HondaInvoicePartLine[]>>;
        setPartNotFoundDrawerOpen: Dispatch<SetStateAction<boolean>>;
        hondaInvoiceId: string;
        invoiceData: StockInvoice;
        setInvoiceData: Dispatch<SetStateAction<StockInvoice>>;
        setLoading: Dispatch<SetStateAction<boolean>>;
        setImportHondaInvoiceOpen: Dispatch<SetStateAction<boolean>>;
        showSnackbar: showSnackbar;
    }): JSX.Element;
}

const PartNotFoundDrawerContent: PartNotFoundDrawerContentProps = (props) => {
    const [newPartDialogOpen, setNewPartDialogOpen] = useState<boolean>(false);
    const [newPart, setNewPart] = useState<{
        partNumber: any;
        name: any;
        supplier: {};
        costPriceDaily: any;
        priceRRP: any;
    }>({
        partNumber: null,
        name: null,
        supplier: {},
        costPriceDaily: null,
        priceRRP: null
    });
    const [newPartErrors, setNewPartErrors] = useState({
        partNumber: false,
        name: false,
        supplier: false,
        costPrice: false,
        rrp: false
    });

    const [hondaSupplierList, setHondaSupplierList] = useState<Creditor[]>([]);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        GetAllSuppliers(setHondaSupplierList);
    }, []);

    const columns = [
        { id: 0, label: 'Part Number' },
        { id: 1, label: 'Created' }
    ];

    return (
        <>
            <DataTable columns={columns}>
                {props.partList.map((part) => (
                    <TableRow>
                        <DataCellColoured
                            handleClick={() => {
                                setNewPart({
                                    partNumber: part.PartNumber,
                                    name: part.PartDescription,
                                    supplier: {},
                                    costPriceDaily: part.WholesalePrice,
                                    priceRRP: 0
                                });
                                setNewPartDialogOpen(true);
                            }}
                        >
                            {part.PartNumber}
                        </DataCellColoured>
                        <DataCell>{part.created ? <Check /> : ''}</DataCell>
                    </TableRow>
                ))}
            </DataTable>

            <br />

            <Grid container spacing={2} textAlign={'center'}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            ImportHondaInvoice(
                                props.hondaInvoiceId,
                                props.invoiceData,
                                props.setInvoiceData,
                                props.setLoading,
                                props.setImportHondaInvoiceOpen,
                                props.setPartList,
                                props.setPartNotFoundDrawerOpen,
                                props.showSnackbar
                            );
                            props.setPartNotFoundDrawerOpen(false);
                        }}
                    >
                        Import Invoice again
                    </Button>
                </Grid>
            </Grid>

            <Dialog
                open={newPartDialogOpen}
                onClose={() => setNewPartDialogOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Part Not Found</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        This part does not exist in Ora, to add it please
                        complete the below fields.
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Part Number"
                                InputLabelProps={{ shrink: true }}
                                value={newPart.partNumber}
                                onChange={(e) =>
                                    setNewPart({
                                        ...newPart,
                                        partNumber: e.target.value
                                    })
                                }
                                error={newPartErrors.partNumber}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label="Name"
                                InputLabelProps={{ shrink: true }}
                                value={newPart.name}
                                onChange={(e) =>
                                    setNewPart({
                                        ...newPart,
                                        name: e.target.value
                                    })
                                }
                                error={newPartErrors.name}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                options={hondaSupplierList.filter((x) =>
                                    x.name.includes('HONDA')
                                )}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'name'}
                                textfieldLabel={'Supplier'}
                                textfieldVariant={'outlined'}
                                selectedValue={newPart.supplier}
                                handleSelectedValueChange={(newValue) =>
                                    setNewPart({
                                        ...newPart,
                                        supplier: newValue
                                    })
                                }
                                handleInputValueChange={(newInputValue) => null}
                                canSearch={false}
                                isError={newPartErrors.supplier}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Daily Cost Price"
                                InputLabelProps={{ shrink: true }}
                                value={newPart.costPriceDaily}
                                onChange={(e) =>
                                    setNewPart({
                                        ...newPart,
                                        costPriceDaily: e.target.value
                                    })
                                }
                                error={newPartErrors.costPrice}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="RRP"
                                InputLabelProps={{ shrink: true }}
                                value={newPart.priceRRP}
                                onChange={(e) =>
                                    setNewPart({
                                        ...newPart,
                                        priceRRP: e.target.value
                                    })
                                }
                                error={newPartErrors.rrp}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewPartDialogOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={buttonLoading}
                        variant="contained"
                        onClick={() =>
                            HandleAddPart(
                                newPart,
                                setNewPartErrors,
                                props.showSnackbar,
                                setButtonLoading,
                                null,
                                setNewPartDialogOpen,
                                props.partList,
                                props.setPartList
                            )
                        }
                    >
                        Add Part
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PartNotFoundDrawerContent;
