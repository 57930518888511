import Papa from 'papaparse';
import api from '../../../../../../api';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Dispatch, SetStateAction } from 'react';

const ImportTransactionFile = (
    file,
    setImportFile,
    setSelectedHeaders: Dispatch<SetStateAction<string[]>>,
    showSnackbar: showSnackbar,
    setFileName: Dispatch<SetStateAction<string>>
) => {
    Papa.parse(file, {
        header: false,
        skipEmptyLines: 'greedy',
        complete: async (results) => {
            api.get(
                `checkTransactionFileExists?fileName=${encodeURIComponent(file.name)}`
            ).then((res) => {
                if (res.status === 200) {
                    // File already exists
                    showSnackbar(
                        'Unable to upload file.',
                        'File with that name has already been uploaded.',
                        'error'
                    );
                    return;
                } else if (res.status === 404) {
                    // File doesn't exsist
                    const lengths = results.data.map((a) => a.length);
                    const index = lengths.indexOf(Math.max(...lengths));

                    var newData = [];
                    results.data.forEach((row, i) => {
                        var newItem = {};
                        for (var j = 0; j < results.data[index].length; j++) {
                            newItem[j] = row[j] ?? '';
                        }
                        newData.push(newItem);
                    });

                    if (newData.length > 0) {
                        var headerColumns = new Array(
                            Object.keys(newData[index]).length
                        );

                        Object.keys(newData[index]).forEach((row) => {
                            headerColumns[row] = '';
                        });
                        setFileName(encodeURIComponent(file.name));
                        setImportFile(newData);
                        setSelectedHeaders(headerColumns);
                    }
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact your IT department.',
                        'error'
                    );
                    return;
                }
            });
        }
    });
};

export default ImportTransactionFile;
