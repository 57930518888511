import * as yup from 'yup';
import { Site } from '../../../OraNextGen/global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../OraNextGen/global/interfaces/GlobalInterface';

let siteError = {
    code: {
        error: false,
        message: ''
    },
    name: {
        error: false,
        message: ''
    },
    phoneNumber: {
        error: false,
        message: ''
    },
    email: {
        error: false,
        message: ''
    },
    address: {
        error: false,
        message: ''
    },
    suburb: {
        error: false,
        message: ''
    },
    state: {
        error: false,
        message: ''
    },
    country: {
        error: false,
        message: ''
    },
    postcode: {
        error: false,
        message: ''
    },
    vehicleTypeSold: {
        error: false,
        message: ''
    }
};

//site validate schema
const siteSchema = yup.object().shape({
    code: yup
        .string()
        .required(() => {
            siteError = {
                ...siteError,
                code: {
                    error: true,
                    message: 'Site code is required.'
                }
            };
            return 'Site code is required.';
        })
        .test('len', 'Site code must contain exactly 3 characters.', (val) => {
            if (val.length !== 3) {
                siteError = {
                    ...siteError,
                    code: {
                        error: true,
                        message: 'Site code must contain exactly 3 characters.'
                    }
                };
                return false;
            } else {
                return true;
            }
        }),
    name: yup.string().required(() => {
        siteError = {
            ...siteError,
            name: {
                error: true,
                message: 'Site name is required.'
            }
        };
        return 'Site name is required.';
    }),
    vehicleTypeSold: yup.string().required(() => {
        siteError = {
            ...siteError,
            vehicleTypeSold: {
                error: true,
                message: 'Vehicle Type is required.'
            }
        };
        return 'Vehicle Type is required.';
    }),
    phoneNumber: yup
        .string()
        .required(() => {
            siteError = {
                ...siteError,
                phoneNumber: {
                    error: true,
                    message: 'Phone number is required.'
                }
            };
            return 'Phone number is required.';
        })
        .test('isValidPhoneNumber', 'Invalid phone number.', (value) => {
            if (!value) return false; // Check if value is provided
            const phoneNumber = value.replace(/\s/g, '').replace(/-/g, '');

            const pattern = phoneNumber.startsWith('+')
                ? /^\+\d{11}$/ // International format: + followed by 11 digits
                : /^\d{10}$/; // Local format: 10 digits

            if (pattern.test(phoneNumber)) {
                return true;
            } else {
                siteError = {
                    ...siteError,
                    phoneNumber: {
                        error: true,
                        message: 'Invalid phone number.'
                    }
                };
                return false;
            }
        }),
    email: yup
        .string()
        .required(() => {
            siteError = {
                ...siteError,
                email: {
                    error: true,
                    message: 'Email is required.'
                }
            };
            return 'Email is required.';
        })
        .email(() => {
            siteError = {
                ...siteError,
                email: {
                    error: true,
                    message: 'Invalid email address.'
                }
            };
            return 'Invalid email address.';
        }),
    address: yup.string().required(() => {
        siteError = {
            ...siteError,
            address: {
                error: true,
                message: 'Address is required.'
            }
        };
        return 'Address is required.';
    }),
    suburb: yup.string().required(() => {
        siteError = {
            ...siteError,
            suburb: {
                error: true,
                message: 'Suburb is required.'
            }
        };
        return 'Suburb is required.';
    }),
    postcode: yup
        .string()
        .required(() => {
            siteError = {
                ...siteError,
                postcode: {
                    error: true,
                    message: 'Postcode is required.'
                }
            };
            return 'Postcode is required.';
        })
        .matches(/^\d{4}$/, () => {
            siteError = {
                ...siteError,
                postcode: {
                    error: true,
                    message: 'Invalid Australian postcode.'
                }
            };
            return 'Invalid Australian postcode.';
        }),
    state: yup
        .string()
        .required(() => {
            siteError = {
                ...siteError,
                state: {
                    error: true,
                    message: 'State is required.'
                }
            };
            return 'State is required.';
        })
        .matches(/^[A-Za-z]{2,3}$/, () => {
            siteError = {
                ...siteError,
                state: {
                    error: true,
                    message: 'State code must be 2-3 characters.'
                }
            };
            return 'State code must be 2-3 characters.';
        }),
    country: yup
        .string()
        .required(() => {
            siteError = {
                ...siteError,
                country: {
                    error: true,
                    message: 'Country is required.'
                }
            };
            return 'Country code is required.';
        })
        .matches(/^[A-Za-z]{2,3}$/, () => {
            siteError = {
                ...siteError,
                country: {
                    error: true,
                    message: 'Country code must be 2-3 characters.'
                }
            };
            return 'Country code must be 2-3 characters.';
        })
});

// Combine the schemas for the complete validation
const validationSchema = yup.object().shape({
    site: siteSchema
});

const ValidateStep2 = async (
    sites: Site[],
    setActiveStep,
    setStepValidation,
    showSnackbar: showSnackbar
) => {
    let siteCounter = 0;
    try {
        if (sites.length === 0) {
            showSnackbar('Please enter at least one site', '', 'error');
            return;
        }

        let listOfName = [];
        let listOfCode = [];
        for (let site of sites) {
            await validationSchema.validate({ site }, { abortEarly: false });
            listOfName.push(site.name);
            listOfCode.push(site.code);
            siteCounter += 1;
        }
        let uniqueNames = Array.from(new Set(listOfName));
        let uniqueCodes = Array.from(new Set(listOfCode));
        if (uniqueNames.length !== sites.length) {
            showSnackbar('Each accounts must have a unique name', '', 'error');
            return;
        }
        if (uniqueCodes.length !== sites.length) {
            showSnackbar('Each accounts must have a unique code', '', 'error');
            return;
        }
        setActiveStep(2);
    } catch (error) {
        let siteErrors = [];
        for (let i = 0; i < sites.length; i++) {
            siteErrors.push({
                code: {
                    error: false,
                    message: ''
                },
                name: {
                    error: false,
                    message: ''
                },
                phoneNumber: {
                    error: false,
                    message: ''
                },
                email: {
                    error: false,
                    message: ''
                },
                address: {
                    error: false,
                    message: ''
                },
                suburb: {
                    error: false,
                    message: ''
                },
                state: {
                    error: false,
                    message: ''
                },
                country: {
                    error: false,
                    message: ''
                },
                postcode: {
                    error: false,
                    message: ''
                },
                vehicleTypeSold: {
                    error: false,
                    message: ''
                }
            });
        }
        siteErrors[siteCounter] = siteError;
        setStepValidation(siteErrors);
        showSnackbar(
            error.errors.join('\n'),
            'Please correct the errors listed above.',
            'error'
        );
        return {
            verified: false,
            message: error.errors.join(' '),
            subMessage: 'Please correct the errors listed above.'
        };
    }
};

export default ValidateStep2;
