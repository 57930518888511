// IMPORTS
import { useState } from 'react';
import { Typography, TableRow, Chip } from '@mui/material';
import { Add, FileDownload } from '@mui/icons-material';
// COMPONENTS
import VehicleTransferTableFilter from './components/VehicleTransferTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import Drawer from '../../global/Drawer';
import NewTransferDrawerContent from './components/NewTransferDrawerContent';
// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import HandleDownloadResults from '../../global/logic/HandleDownloadResults';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { VehicleTransfer } from '../../global/interfaces/VehicleSaleInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    vehicleTransferFilter: {
        status: [],
        siteFrom: [],
        siteTo: []
    }
};
const clearedSort = ['id', 'DESC'];

const VehicleTransferTable = () => {
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<VehicleTransfer[]>([]);

    const [transferDrawer, setTransferDrawer] = useState<boolean>(false);
    const [transferData, setTransferData] = useState<VehicleTransfer>();

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'New Transfer',
            onClick: () => setTransferDrawer(true)
        },
        {
            icon: <FileDownload />,
            name: 'Export Current Results',
            onClick: () => handleDownloadResults()
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', sort: true, name: 'id', width: 100 },
        { id: 1, label: 'Stock Number', width: 150 },
        { id: 2, label: 'Vehicle', width: 250 },
        { id: 3, label: 'From', width: 100 },
        { id: 4, label: 'To', width: 100 },
        { id: 5, label: 'User', width: 150 },
        { id: 6, label: 'Status', width: 150 }
    ];

    // Handle downloading the csv
    const handleDownloadResults = () => {
        HandleDownloadResults(
            sort,
            'vehicleTransferSearchNextGen',
            handleRequestCreate(),
            'OraVehicleTransferResults.csv',
            [
                'Stock Number',
                'Site From',
                'Site To',
                'Make',
                'Model',
                'Series',
                'Year Model',
                'Condition',
                'VIN'
            ],
            [
                'Vehicle.stockNumber',
                'SiteFrom.name',
                'SiteTo.name',
                'Vehicle.Make.name',
                'Vehicle.Model.name',
                'Vehicle.Series.name',
                'Vehicle.year',
                'Vehicle.condition',
                'Vehicle.vin'
            ]
        );
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let status =
            filter.vehicleTransferFilter.status.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleTransferFilter.status,
                      filterStart: '&status=',
                      selector: 'value'
                  })
                : '';
        let siteTo =
            filter.vehicleTransferFilter.siteTo.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleTransferFilter.siteTo,
                      filterStart: '&siteTo=',
                      selector: 'id'
                  })
                : '';
        let siteFrom =
            filter.vehicleTransferFilter.siteFrom.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleTransferFilter.siteFrom,
                      filterStart: '&siteFrom=',
                      selector: 'id'
                  })
                : '';

        let apiString = `${status}${siteTo}${siteFrom}`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;
        if (
            filter.vehicleTransferFilter.status.length > 0 ||
            filter.vehicleTransferFilter.siteTo.length > 0 ||
            filter.vehicleTransferFilter.siteFrom.length > 0
        ) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Vehicle Transfers</Typography>
            <br />
            <TableSearch
                searchTitle="Search Vehicle Transfers"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="vehicleTransferSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <VehicleTransferTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() => {
                                    setTransferData(row);
                                    setTransferDrawer(true);
                                }}
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>{row.Vehicle?.stockNumber}</DataCell>
                            <DataCell>
                                {row.Vehicle?.year} {row.Vehicle?.Make?.name}{' '}
                                {row.Vehicle?.Model?.name}{' '}
                                {row.Vehicle?.Series?.name}
                            </DataCell>
                            <DataCell>{row.SiteFrom?.name}</DataCell>
                            <DataCell>{row.SiteTo?.name}</DataCell>
                            <DataCell>
                                {row?.SiteToUser?.firstName}{' '}
                                {row?.SiteToUser?.lastName}
                            </DataCell>
                            <DataCell>
                                <Chip
                                    variant="outlined"
                                    label={row?.status}
                                    color={
                                        row?.status === 'COMPLETE'
                                            ? 'success'
                                            : 'warning'
                                    }
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            {/* Drawer for new Transfer */}
            <Drawer
                openDrawer={transferDrawer}
                setOpenDrawer={setTransferDrawer}
                title={
                    transferData
                        ? `Transfer #${transferData.id}`
                        : 'New Transfer'
                }
                subTitle={transferData ? `Status: ${transferData.status}` : ''}
                width="40vw"
                onCloseFunction={() => {
                    setTransferDrawer(false);
                    setTransferData(null);
                }}
            >
                <NewTransferDrawerContent transferData={transferData} />
            </Drawer>
        </PageWrapper>
    );
};

export default VehicleTransferTable;
