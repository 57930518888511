// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { ManufacturerVehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleAddManufacturerVehicle = (
    selectedManufacturerVehicle: ManufacturerVehicle,
    showSnackbar: showSnackbar,
    noRefresh?: boolean,
    setReadOnly?: Dispatch<SetStateAction<boolean>>,
    setSelectedManufacturerVehicle?: Dispatch<
        SetStateAction<ManufacturerVehicle>
    >
) => {
    api.post(`addManufacturerVehicle`, selectedManufacturerVehicle).then(
        (res) => {
            if (res.status === 200) {
                // Success
                showSnackbar('Manufacturer Vehicle Successfully Added.');
                if (!noRefresh) {
                    window.location.reload();
                } else {
                    setSelectedManufacturerVehicle(res.data);
                    setReadOnly(true);
                }
            } else {
                // Misc Error
                showSnackbar(
                    'Whoops, something seems to have gone wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        }
    );
};

export default HandleAddManufacturerVehicle;
