import { SetStateAction, Dispatch } from 'react';
import api from '../../../../api';

const GetLogs = (
    department: string,
    ObjectId: number,
    setLogs: Dispatch<SetStateAction<{}[]>>
) => {
    let query = '';
    if (department === 'parts') {
        query = `OrderId=${ObjectId}`;
    }
    if (department === 'vehicle') {
        query = `VehicleSaleId=${ObjectId}`;
    }
    if (department === 'service') {
        query = `ServiceId=${ObjectId}`;
    }
    api.get(`logReports/${department}?${query}`).then((res) => {
        if (res.data && res.data.changes) {
            //ensure logs listed from time old to new
            const sortedLogs = res.data.changes.sort((log1, log2) => {
                const date1 = new Date(log1.date);
                const date2 = new Date(log2.date);
                return date2.getTime() - date1.getTime();
            });
            setLogs(sortedLogs);
        } else {
            setLogs([]);
        }
    });
};

export default GetLogs;
