// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { Api } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// COMPONENTS
import OrderLineHeader from './OrderLineComponents/OrderLineHeader';
import GenerateOrderConditions from './OrderLineComponents/GenerateOrderConditions';
import OrderLinesTable from './OrderLineComponents/OrderLineTable';
import OrderSummary from './OrderSummary';
// INTERFACES
import { Table } from '../interfaces/StockOrderInterface';

interface OrderLineFormProps {
    tables: Table[];
    setTables: Dispatch<SetStateAction<Table[]>>;
    selectedSite: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
    orderType: string[];
    setOrderType: Dispatch<SetStateAction<string[]>>;
    generatorList: Api[];
    setGeneratorList: Dispatch<SetStateAction<Api[]>>;
    showSnackbar: showSnackbar;
    selectedSupplier: number;
    setSelectedSupplier: Dispatch<SetStateAction<number>>;
    selectedSupplierError: boolean;
    setSelectedSupplierError: Dispatch<SetStateAction<boolean>>;
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>;
}

const OrderLineForm = ({
    tables,
    setTables,
    selectedSite,
    setSelectedSite,
    orderType,
    setOrderType,
    generatorList,
    setGeneratorList,
    showSnackbar,
    selectedSupplier,
    setSelectedSupplier,
    selectedSupplierError,
    setSelectedSupplierError,
    setShouldBlockNavigation
}: OrderLineFormProps) => {
    const [staging, setStaging] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [clicked, setClicked] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [orderOpen, setOrderOpen] = useState<boolean>(false);
    const [coveragePeriod, setCoveragePeriod] = useState<number>(1);
    const [coveragePeriodType, setCoveragePeriodType] =
        useState<string>('week');
    const [monthList, setMonthList] = useState<
        { month: string; qtySold: number }[]
    >([]);
    const [orderPeriod, setOrderPeriod] = useState<{
        startDate: Date;
        endDate: Date;
    }>({
        startDate: null,
        endDate: null
    });
    const [generateOrderErrors, setGenerateOrderErrors] = useState<{
        noCovPeriod: boolean;
        noSalesStart: boolean;
        noSalesEnd: boolean;
    }>({
        noCovPeriod: false,
        noSalesStart: false,
        noSalesEnd: false
    });
    return (
        <div>
            {staging ? (
                <OrderSummary
                    tables={tables}
                    setTables={setTables}
                    selectedSite={selectedSite}
                    generatorList={generatorList}
                    setGeneratorList={setGeneratorList}
                    setStaging={setStaging}
                    showSnackbar={showSnackbar}
                    setShouldBlockNavigation={setShouldBlockNavigation}
                />
            ) : (
                <>
                    <OrderLineHeader
                        clicked={clicked}
                        setOpen={setOpen}
                        setOrderOpen={setOrderOpen}
                        selectedSite={selectedSite}
                        setSelectedSite={setSelectedSite}
                        orderType={orderType}
                        setOrderType={setOrderType}
                        tables={tables}
                        setTables={setTables}
                        selectedSupplier={selectedSupplier}
                        setSelectedSupplier={setSelectedSupplier}
                        selectedSupplierError={selectedSupplierError}
                        setSelectedSupplierError={setSelectedSupplierError}
                        setShouldBlockNavigation={setShouldBlockNavigation}
                    />

                    <br />
                    {loading ? (
                        <CircularProgress className="progress-bar" />
                    ) : (
                        ''
                    )}
                    <OrderLinesTable
                        selectedSite={selectedSite}
                        monthList={monthList}
                        setMonthList={setMonthList}
                        tables={tables}
                        setTables={setTables}
                        staging={staging}
                        setStaging={setStaging}
                        showSnackbar={showSnackbar}
                        setShouldBlockNavigation={setShouldBlockNavigation}
                    />

                    <Dialog
                        onClose={() => setOrderOpen(false)}
                        open={orderOpen}
                    >
                        <GenerateOrderConditions
                            generateOrderErrors={generateOrderErrors}
                            setGenerateOrderErrors={setGenerateOrderErrors}
                            coveragePeriod={coveragePeriod}
                            setCoveragePeriod={setCoveragePeriod}
                            coveragePeriodType={coveragePeriodType}
                            setCoveragePeriodType={setCoveragePeriodType}
                            orderPeriod={orderPeriod}
                            setOrderPeriod={setOrderPeriod}
                            setOrderOpen={setOrderOpen}
                            setLoading={setLoading}
                            setClicked={setClicked}
                            tables={tables}
                            setTables={setTables}
                            selectedSite={selectedSite}
                        />
                    </Dialog>

                    <Dialog onClose={() => setOpen(false)} open={open}>
                        <DialogTitle>Generate Another Order?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You have already generated an order, are you
                                sure you would like to generate another order
                                and add it to the current order?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setOpen(false);
                                    setOrderOpen(true);
                                }}
                            >
                                Generate Order
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </div>
    );
};

export default OrderLineForm;
