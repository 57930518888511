// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import {
    StockAdjustment,
    Unit
} from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

/*  ManuallyAdjustStock
    Validates and manually adjusts the stock of a given part
    params:
        - UnitId: The id of the unit being adjusted
        - SiteId: The id of the current site
        - stockAdjust: The object containing the adjustment details 
        - unitData: The object containing the unit details
        - setSnackbar: The setter state for the snackbar to send user feedback
*/
const ManuallyAdjustStock = (
    UnitId: number,
    SiteId: number,
    stockAdjust: StockAdjustment,
    partDetails: Unit,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    let adjustmentCost: number = stockAdjust.adjustmentCost;

    // Make sure there is an adjustmentCost, if not take costPriceDaily if costPriceDaily doesn't exist: return snackbar error
    if (stockAdjust.adjustmentCost === 0) {
        if (partDetails.costPriceDaily) {
            adjustmentCost = partDetails.costPriceDaily;
        } else {
            showSnackbar(
                'Invalid Adjustment Cost.',
                'Please enter a valid adjustment cost to continue.',
                'error'
            );
            setButtonLoading(false);
            return;
        }
    }

    // Make sure there is a description
    if (
        stockAdjust.adjustmentDescription === '' ||
        stockAdjust.adjustmentDescription == null
    ) {
        showSnackbar(
            'Invalid Adjustment Reason.',
            'Please enter a valid reason for the adjustment.',
            'error'
        );
        setButtonLoading(false);
        return;
    }
    // Check if the parts is fluid, if so calculate the stock accordingly
    let finalStock = partDetails.fluid
        ? stockAdjust.adjustmentValue * partDetails.volume
        : stockAdjust.adjustmentValue;

    let obj = {
        UnitId: UnitId,
        SiteId: SiteId,
        newStock: finalStock,
        adjustmentReason: stockAdjust.adjustmentDescription,
        adjustmentCost: adjustmentCost,
        adjustAverage: stockAdjust.adjustAverage
    };

    api.post('newStockAdjustment', obj).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Manual stock adjustment successful.',
                'The window will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'It looks like something went wrong on our end.',
                'Please contact your IT team.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default ManuallyAdjustStock;
