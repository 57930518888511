import * as React from 'react';
import { Box, Avatar, Stack, Dialog, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const ImageDialog = ({ imgPath, open, handleClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <img
                src={imgPath}
                alt={imgPath}
                style={{ height: '100%', width: 'auto' }}
                onClick={handleClose}
            ></img>
        </Dialog>
    );
};

export default function GuideStepper({ steps }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;
    const [activeImage, setActiveImage] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = () => {
        activeStep === maxSteps - 1
            ? setActiveStep(0)
            : setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Paper sx={{ flexGrow: 1 }} elevation={3}>
            <Box
                sx={{
                    p: 2,
                    bgcolor: 'background.default'
                }}
            >
                <Stack spacing={2} direction="row" alignItems="center">
                    <Avatar>{activeStep + 1}</Avatar>
                    <Typography>{steps[activeStep].label}</Typography>
                </Stack>
            </Box>

            <Box sx={{ height: 500, width: '100%', p: 2, textAlign: 'center' }}>
                <img
                    src={steps[activeStep].imgPath}
                    alt={steps[activeStep].imgPath}
                    style={{ height: '100%', width: 'auto', maxHeight: 500 }}
                    onClick={() => {
                        setActiveImage(steps[activeStep].imgPath);
                        setOpen(true);
                    }}
                ></img>
            </Box>
            <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{ flexGrow: 1 }}
                nextButton={
                    <Button size="small" onClick={handleNext}>
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
            <ImageDialog
                imgPath={activeImage}
                open={open}
                handleClose={handleClose}
            />
        </Paper>
    );
}
