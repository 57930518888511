import React, { ChangeEvent, Dispatch, DragEvent, SetStateAction } from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CloseIcon from '@mui/icons-material/Close';
import { Tag, TAG_ACTIONS } from '../../EsignInterface';
import TagWrapper from './TagWrapper';

interface TextfieldTagProps {
    action: string;
    tag?: Tag;
    fontSize?: string;
    iconSize?: string;
    hasLabel?: boolean;
    removeTag?: () => void;
    setTags?: Dispatch<SetStateAction<Tag[]>>;
}

const TextfieldTag = ({
    action,
    tag,
    fontSize,
    iconSize,
    hasLabel,
    removeTag,
    setTags
}: TextfieldTagProps) => {
    const isFillable = action === TAG_ACTIONS.FILL;
    const isResizable = action === TAG_ACTIONS.MODIFY;
    const isViewOnly = action === TAG_ACTIONS.VIEW;
    const shouldDisplayLabel =
        isViewOnly || isFillable || (isResizable && hasLabel);

    const dragStartHandler = (event: DragEvent<HTMLDivElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();

        // calculate the offset of the mouse cursor from the top left corner
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        // get the signature field size
        const width = rect.width;
        const height = rect.height;

        // set the data transfer object for dragging
        event.dataTransfer.setData('type', 'textfield');
        event.dataTransfer.setData('offsetX', offsetX.toString());
        event.dataTransfer.setData('offsetY', offsetY.toString());
        event.dataTransfer.setData('width', width.toString());
        event.dataTransfer.setData('height', height.toString());

        // if tag is not undefined, means the tag is already in the canvas, set the tempId to the dataTransfer
        event.dataTransfer.setData(
            'tempId',
            tag !== undefined ? tag.tempId.toString() : ''
        );
    };

    const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTags((prevTags) => {
            return prevTags.map((t) => {
                if (t.id === tag.id) {
                    return { ...t, inputData: e.target.value };
                }
                return t;
            });
        });
    };

    return (
        <TagWrapper
            isViewOnly={isViewOnly}
            isResizable={isResizable}
            dragStartHandler={dragStartHandler}
        >
            {isFillable ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <TextField
                        placeholder="Text"
                        fullWidth
                        variant="standard"
                        InputProps={{
                            style: {
                                fontSize: fontSize
                            }
                        }}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottom: 'none'
                            },
                            '& .MuiInput-underline:hover:before': {
                                borderBottom: 'none !important'
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: 'none'
                            }
                        }} // remove the underline
                        onChange={handleTextFieldChange}
                    />
                </Box>
            ) : (
                <>
                    <TextFieldsIcon
                        sx={{
                            fontSize: iconSize
                        }}
                    />
                    {shouldDisplayLabel && (
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: fontSize,
                                flexGrow: 0.8
                            }}
                        >
                            Textfield
                        </Typography>
                    )}
                    {isResizable && removeTag && (
                        <IconButton
                            onClick={removeTag}
                            size="small"
                            sx={{
                                color: 'primary.main'
                            }}
                        >
                            <CloseIcon sx={{ fontSize: fontSize }} />
                        </IconButton>
                    )}
                </>
            )}
        </TagWrapper>
    );
};

export default TextfieldTag;
