import React, { useEffect, useState } from 'react';
import PageWrapper from '../../global/PageWrapper';
import { User } from '../../global/interfaces/GeneralInterface';
import GetUser from '../../global/databaseLogic/GetUser';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import Paper from '../../global/Paper';
import api from '../../../../api';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const HomePage = () => {
    const [currentUser, setCurrentUser] = useState<User>({});
    const [updatesContent, setUpdatesContent] = useState<string>('');

    useEffect(() => {
        GetUser(0, setCurrentUser);
        api.get('getHomeUpdates').then((res) => {
            if (res.data.length > 0) {
                setUpdatesContent(res.data[0].content);
            }
        });
    }, []);

    return (
        <PageWrapper>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h4" align="left">
                        Welcome Back, {currentUser.firstName}
                    </Typography>
                    <Typography variant="subtitle1" align="left">
                        Don't have a good day, have a great day.
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h4" align="right">
                        {moment().format('D MMMM YYYY')}
                    </Typography>
                    <Typography variant="subtitle1" align="right">
                        {moment().format('dddd')} Week {moment().format('W')}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Paper>
                        <iframe
                            src="https://indify.co/widgets/live/weather/uO3oWWB4KDtKfN414x8v"
                            frameBorder="0"
                            scrolling="no"
                            width="100%"
                            height="100%"
                            loading="eager"
                            title="brisbaneWeather"
                        ></iframe>
                        <style type="text/css">
                            iframe{' '}
                            {
                                'position: absolute; width: 100%; height: 100%; z-index: 999; '
                            }
                        </style>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <iframe
                            src="https://indify.co/widgets/live/weather/WBFi5XytoENuc23YiqeS"
                            frameBorder="0"
                            scrolling="no"
                            width="100%"
                            height="100%"
                            loading="eager"
                            title="sydneyWeather"
                        ></iframe>
                        <style type="text/css">
                            iframe{' '}
                            {
                                'position: absolute; width: 100%; height: 100%; z-index: 999; '
                            }
                        </style>
                    </Paper>
                </Grid>

                <Grid item xs>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">
                                <b>Latest Updates:</b>
                            </Typography>
                            <ReactMarkdown children={updatesContent} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/* <Button onClick={() => api.get("/test")}>test</Button> */}
        </PageWrapper>
    );
};

export default HomePage;
