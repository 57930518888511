import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';

const GetAllTasksForCurrentSite = (
    setTaskData: Dispatch<SetStateAction<any[]>>,
    currentSiteId: string
) => {
    api.get(`/dailytasks/${currentSiteId}`).then((res) => {
        setTaskData(res.data);
    });
};

export default GetAllTasksForCurrentSite;
