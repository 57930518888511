import {
    FormLabel,
    Grid,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography,
    styled
} from '@mui/material';
import Paper from '../../../global/Paper';
import {
    Till,
    User,
    UserSettings
} from '../../../global/interfaces/GeneralInterface';
import { Dispatch, SetStateAction } from 'react';

interface DefaultSettingsProps {
    userDetails: User;
    userSettings: UserSettings;
    setUserSettings: Dispatch<SetStateAction<UserSettings>>;
    currentUser: User;
    setCurrentUser: Dispatch<SetStateAction<User>>;
    tills: Till[];
    readOnly: boolean;
}

const DarkModeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#2c0f94',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff'
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#aab4be'
            }
        }
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2c0f94' : '#1fa1b8',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#ffdb38'
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
        }
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2
    }
}));

const DefaultSettings = ({
    userDetails,
    userSettings,
    setUserSettings,
    currentUser,
    setCurrentUser,
    tills,
    readOnly
}: DefaultSettingsProps) => {
    const HandleUpdateUserSettings = (checked) => {
        let user = JSON.parse(JSON.stringify(userSettings));
        user.darkMode = checked;
        setUserSettings(user);
    };

    return (
        <Paper>
            <Typography variant="h6">Defaults</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        disabled
                        size="small"
                        label="Assigned Site"
                        helperText="This is assigned by admin and can not be changed here."
                        value={userDetails?.Site?.name}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        select
                        fullWidth
                        disabled={readOnly}
                        size="small"
                        label="Default Till"
                        value={userSettings?.TillId}
                        onChange={(e) =>
                            setUserSettings({
                                ...userSettings,
                                TillId: parseInt(e.target.value)
                            })
                        }
                    >
                        {tills.map((till) => (
                            <MenuItem value={till.id}>{till.name}</MenuItem>
                        ))}
                        <MenuItem value={null}>NO DEFAULT</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <FormLabel disabled={readOnly}>Light Mode</FormLabel>
                        <DarkModeSwitch
                            checked={userSettings.darkMode}
                            disabled={readOnly}
                            onChange={(e) => {
                                setUserSettings({
                                    ...userSettings,
                                    darkMode: e.target.checked
                                });
                                HandleUpdateUserSettings(e.target.checked);
                            }}
                        />
                        <FormLabel disabled={readOnly}>Dark Mode</FormLabel>
                    </Stack>
                    <Typography variant="caption" color={'grey'}>
                        This function is currently in Beta. Please contact the development team if you noticed any glitches/bugs, or if a section of Ora isn't properly readable in dark mode.
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DefaultSettings;
