import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { TradeIn } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleRemoveTrade = (
    TradeId: number,
    linkedTrades: TradeIn[],
    setLinkedTrades: Dispatch<SetStateAction<TradeIn[]>>,
    selectedTradeType: string,
    setOpenTradeDialog: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    if (selectedTradeType === 'index') {
        let newArray = [];
        linkedTrades.forEach((item, index) => {
            if (index !== TradeId) {
                newArray.push(item);
            }
        });

        setLinkedTrades(newArray);
        setButtonLoading(false);
        setOpenTradeDialog(false);
    } else {
        api.put(`removeTrade/${TradeId}`).then((res) => {
            if (res.status === 200) {
                let tempArray = [...linkedTrades];
                tempArray = tempArray.filter((x) => x.id !== TradeId);
                setLinkedTrades(tempArray);

                showSnackbar('Trade removed from contract.');
                setOpenTradeDialog(false);
                setButtonLoading(false);
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
                setButtonLoading(false);
            }
        });
    }
};

export default HandleRemoveTrade;
