import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

// Submit the new transfer to the database
const SubmitTransfer = (
    transferData,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    // Validation for the sites
    if (
        !transferData.SiteFrom.id ||
        !transferData.SiteTo.id ||
        transferData.transferLines.length === 0 ||
        transferData.SiteFrom.id === transferData.SiteTo.id
    ) {
        showSnackbar(
            'Make sure you selected different sites and added parts to the transfer.',
            '',
            'error'
        );
        setButtonLoading(false);
        return;
    }

    // Validation for the quantities entered
    for (let line of transferData.transferLines) {
        if (line.quantitySent <= 0) {
            showSnackbar(
                `Part ${line.partNumber} doesn't have a quantity sent.`,
                '',
                'error'
            );
            setButtonLoading(false);
            return;
        }
    }

    // Send the api request
    api.post('stockTransferNextGen', transferData).then((res) => {
        if (res.status === 200) {
            window.location.reload();
        } else {
            showSnackbar(
                'Something went wrong when updating the stock transfer.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default SubmitTransfer;
