import DocumentationPage from '../DocumentationPage';
import { ManageStockOrdersSteps } from '../data/stockControl/stockOrders';

const StockOrderSupportPage = () => {
    const contentSections = [
        {
            id: 'manage-stock-orders',
            title: 'Manage Stock Orders',
            href: '/support/inventory/stockorders/#manage-stock-orders',
            description:
                'To search and filter Stock Orders, print Stock Order summaries and manually set Stock Order as invoiced, follow these outlined steps:',
            steps: ManageStockOrdersSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Stock Order"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default StockOrderSupportPage;
