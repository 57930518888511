import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../../../api';
import CalculateStampDuty from '../../../../../global/logic/CalculateStampDuty';
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import { Dayjs } from 'dayjs';
import { Service } from '../../../../../global/interfaces/ServiceInterface';
import { Registration } from '../../../../../global/interfaces/VehicleSaleInterface';
import {
    StaffOrderSales,
    StaffVehicleSales
} from '../interface/StaffSaleInterface';
import { Site } from '../../../../../global/interfaces/GeneralInterface';

interface CalculateStaffSalesReportingParams {
    (params: {
        queryDate: { startDate: Dayjs; endDate: Dayjs };
        selectedSite: Site[];
        setLoading: Dispatch<SetStateAction<boolean>>;
        setStaffVehicleSales: Dispatch<
            SetStateAction<
                {
                    user: {
                        id: string;
                        firstName: string;
                        lastName: string;
                    };
                    vehicleData: {
                        VehicleSaleId: number;
                        adminLoadCost: number;
                        customerName: string;
                        deliveryPrice: number;
                        extraCosts: number;
                        serviceCosts: number;
                        stockNumber: string;
                        totalCost: number;
                        totalSale: number;
                        vehicleCondition: string;
                        vehicleCostPrice: number;
                        vehicleSalePrice: number;
                    }[];
                    vehicleValues: {
                        newCost: number;
                        newRevenue: number;
                        newTotal: number;
                        usedCost: number;
                        usedRevenue: number;
                        usedTotal: number;
                    };
                    wrongVehicleData: any[];
                }[]
            >
        >;
        setStaffOrdersSales: Dispatch<
            SetStateAction<
                {
                    user: {
                        id: string;
                        firstName: string;
                        lastName: string;
                    };
                    orderValues: {
                        onlineCost: number;
                        onlineRevenue: number;
                        onlineTotal: number;
                        partCost: number;
                        partRevenue: number;
                        partTotal: number;
                        saleCost: number;
                        saleRevenue: number;
                        saleTotal: number;
                        serviceCost: number;
                        serviceRevenue: number;
                        serviceTotal: number;
                        vehicleCost: number;
                        vehicleRevenue: number;
                        vehicleTotal: number;
                    };
                    orderData: {
                        OnlineOrderId: number;
                        OrderId: number;
                        ServiceId: number;
                        VehicleId: number;
                        VehicleSaleId: number;
                        customerName: string;
                        numberParts: number;
                        orderType: string;
                        totalCost: number;
                        totalSale: number;
                    }[];
                    wrongOrderData: {
                        OrderId: number;
                        costAverage: number;
                        costDaily: number;
                        message: string;
                        orderType: string;
                        submessage: string;
                    }[];
                }[]
            >
        >;
    }): void;
}

const getExtraCostsAllowance = (extraCosts) => {
    var total = 0;
    extraCosts?.forEach((row) => {
        total += parseFloat(row.rowTotal);
    });
    return total;
};

const getServiceAllowance = (services: Service[]) => {
    var total = 0;
    services.forEach((service) => {
        var addedCostTotal = 0;
        service?.addedCosts?.forEach((row) => {
            addedCostTotal += parseFloat(row.rowTotal);
        });
        total +=
            parseFloat(service.sundries ?? '0') +
            parseFloat(service.labourCost ?? '0') +
            parseFloat(service.environmentalLevy ?? '0') +
            addedCostTotal;
    });
    return total;
};
const CalculateStaffSalesReporting: CalculateStaffSalesReportingParams = (
    params
) => {
    params.setLoading(true);

    api.get(
        `staffSalesReportingNextGen?startDate=${params.queryDate.startDate}&endDate=${params.queryDate.endDate}&SiteId=${params.selectedSite.map((x) => x.id).toString()}`
    ).then((res) => {
        let vehicleSales: StaffVehicleSales[] = res.data.vehicleSales;
        let orders: StaffOrderSales[] = res.data.orders;
        let registration: Registration[] = res.data.registrationCosts;

        let vehicleSalesFinal = [];
        let ordersFinal = [];

        Object.keys(vehicleSales).forEach((user) => {
            let userDetails = {
                id: user,
                firstName: vehicleSales[user][0]['User.firstName'],
                lastName: vehicleSales[user][0]['User.lastName']
            };

            let vehicleData = [];
            var wrongVehicleData = [];
            var vehicleValues = {
                usedTotal: 0,
                usedCost: 0,
                usedRevenue: 0,
                newTotal: 0,
                newCost: 0,
                newRevenue: 0
            };

            vehicleSales[user].forEach((sale) => {
                if (!sale.vehicleSalePrice || !sale['Vehicle.costPrice']) {
                    let message =
                        !sale.vehicleSalePrice && !sale['Vehicle.costPrice']
                            ? 'No cost or sale price entered for vehicle.'
                            : !sale.vehicleSalePrice
                              ? 'No sale price entered for vehicle.'
                              : 'No cost price entered for vehicle';
                    let saleLine = {
                        VehicleSaleId: sale.id,
                        customerName:
                            sale['Customer.firstName'].charAt(0) +
                            '. ' +
                            sale['Customer.lastName'],
                        stockNumber: sale['Vehicle.stockNumber'],
                        vehicleCondition: sale['Vehicle.condition'],
                        message: message
                    };
                    wrongVehicleData.push(saleLine);
                } else {
                    let vehicleCostPrice =
                        parseFloat(sale['Vehicle.costPrice']) / 1.1 +
                        parseFloat(sale['Vehicle.adminLoadCost'] ?? 0) +
                        getExtraCostsAllowance(sale['Vehicle.extraCosts']) +
                        getServiceAllowance(sale?.vehicleServices);
                    let vehicleSalePrice = 0;
                    let rebatePrice = 0;

                    if (sale.includesOnRoadCosts) {
                        if (sale.excludeGST) {
                            vehicleSalePrice = parseFloat(
                                sale.totalPurchasePrice
                            );
                        } else {
                            vehicleSalePrice =
                                parseFloat(sale.totalPurchasePrice) / 1.1;
                        }
                    } else {
                        if (sale.excludeGST) {
                            vehicleSalePrice =
                                parseFloat(sale.totalPurchasePrice) -
                                CalculateStampDuty(
                                    registration,
                                    sale['Vehicle.cylinders'],
                                    sale?.vehicleSalePrice
                                ) -
                                sale.ctpPrice -
                                sale.registrationPrice;
                        } else {
                            vehicleSalePrice =
                                (parseFloat(sale.totalPurchasePrice) -
                                    CalculateStampDuty(
                                        registration,
                                        sale['Vehicle.cylinders'],
                                        sale?.vehicleSalePrice
                                    ) -
                                    sale.ctpPrice -
                                    sale.registrationPrice) /
                                1.1;
                        }
                    }

                    if (sale.vehicleRebates) {
                        sale.vehicleRebates.forEach((rebate) => {
                            rebatePrice += parseFloat(rebate.amount);
                        });
                    }

                    let saleLine = {
                        VehicleSaleId: sale.id,
                        stockNumber: sale['Vehicle.stockNumber'],
                        customerName:
                            sale['Customer.firstName'].charAt(0) +
                            '. ' +
                            sale['Customer.lastName'],

                        vehicleCondition: sale['Vehicle.condition'],
                        vehicleCostPrice:
                            parseFloat(sale['Vehicle.costPrice']) / 1.1,
                        vehicleSalePrice: parseFloat(sale.vehicleSalePrice),

                        adminLoadCost: parseFloat(
                            sale['Vehicle.adminLoadCost']
                        ),
                        extraCosts: getExtraCostsAllowance(sale?.Vehicle),
                        serviceCosts: getServiceAllowance(
                            sale?.vehicleServices
                        ),
                        deliveryPrice: parseFloat(sale.deliveryPrice),

                        totalCost: vehicleCostPrice,
                        totalSale:
                            vehicleSalePrice -
                            parseFloat(sale.addOnPrice) / 1.1 -
                            parseFloat(sale.insurancePrice) / 1.1 -
                            parseFloat(sale.warrantyPrice) / 1.1 +
                            rebatePrice / 1.1
                    };

                    if (sale['Vehicle.condition'] === 'Used') {
                        vehicleValues.usedTotal += 1;
                        vehicleValues.usedCost += vehicleCostPrice;
                        vehicleValues.usedRevenue +=
                            vehicleSalePrice -
                            parseFloat(sale.addOnPrice) / 1.1 -
                            parseFloat(sale.insurancePrice) / 1.1 -
                            parseFloat(sale.warrantyPrice) / 1.1;
                    } else {
                        vehicleValues.newTotal += 1;
                        vehicleValues.newCost += vehicleCostPrice;
                        vehicleValues.newRevenue +=
                            vehicleSalePrice -
                            parseFloat(sale.addOnPrice) / 1.1 -
                            parseFloat(sale.insurancePrice) / 1.1 -
                            parseFloat(sale.warrantyPrice) / 1.1;
                    }

                    vehicleData.push(saleLine);
                }
            });

            let finalArray = {
                user: userDetails,
                vehicleData: vehicleData,
                vehicleValues: vehicleValues,
                wrongVehicleData: wrongVehicleData
            };

            vehicleSalesFinal.push(finalArray);
        });

        Object.keys(orders).forEach((user) => {
            if (user !== 'null') {
                let userDetails = {
                    id: user,
                    firstName: orders[user][0].User.firstName,
                    lastName: orders[user][0].User.lastName
                };

                var partData = [];
                var wrongPartData = [];
                var partValues = {
                    partTotal: 0,
                    partCost: 0,
                    partRevenue: 0,
                    vehicleTotal: 0,
                    vehicleCost: 0,
                    vehicleRevenue: 0,
                    serviceTotal: 0,
                    serviceCost: 0,
                    serviceRevenue: 0,
                    saleTotal: 0,
                    saleCost: 0,
                    saleRevenue: 0,
                    onlineTotal: 0,
                    onlineCost: 0,
                    onlineRevenue: 0
                };

                orders[user].forEach((order) => {
                    let totalOrderCost = 0;
                    let totalOrderRevenue = 0;
                    let orderType = order.VehicleSaleId
                        ? 'Sale'
                        : order.ServiceId
                          ? 'Service'
                          : order.VehicleId
                            ? 'Vehicle'
                            : order.OnlineOrderId
                              ? 'Online'
                              : 'Part Sale';
                    let continueWithSale = true;
                    order.orderLines.forEach((part) => {
                        if (!part.costPriceAverage || !part.pricePaid) {
                            let message =
                                !part.costPriceAverage && !part.pricePaid
                                    ? 'No average cost or sale price entered.'
                                    : !part.pricePaid
                                      ? 'No sale price entered.'
                                      : 'No average cost price entered';
                            let saleLine = {
                                OrderId: order.id,
                                orderType: orderType,
                                message: message,
                                costAverage: part.costPriceAverage,
                                costDaily: part.costPriceDaily,
                                submessage: !part.costPriceAverage
                                    ? 'Daily Cost: ' +
                                      CurrencyFormatter(part.costPriceDaily)
                                    : ''
                            };
                            wrongPartData.push(saleLine);
                            continueWithSale = false;
                        } else {
                            let averageCheck = Math.abs(
                                parseFloat(part.costPriceAverage) -
                                    parseFloat(part.costPriceDaily)
                            );

                            if (
                                averageCheck >=
                                parseFloat(part.costPriceDaily) * 2
                            ) {
                                let saleLine = {
                                    OrderId: order.id,
                                    orderType: orderType,
                                    message:
                                        'Cost price average is signficantly larger than cost price daily.',
                                    submessage:
                                        'Daily: ' +
                                        CurrencyFormatter(part.costPriceDaily) +
                                        ', Average: ' +
                                        CurrencyFormatter(part.costPriceAverage)
                                };
                                wrongPartData.push(saleLine);
                                continueWithSale = false;
                            } else {
                                totalOrderCost +=
                                    parseFloat(part.costPriceAverage) *
                                    parseInt(part.quantity);

                                if (order.VehicleId) {
                                    totalOrderRevenue +=
                                        parseFloat(part.pricePaid) *
                                        parseInt(part.quantity);
                                } else {
                                    totalOrderRevenue +=
                                        (parseFloat(part.pricePaid) *
                                            parseInt(part.quantity)) /
                                        1.1;
                                }
                            }
                        }
                    });

                    if (continueWithSale) {
                        let saleLine = {
                            OrderId: order.id,
                            orderType: orderType,
                            totalCost: totalOrderCost,
                            totalSale: totalOrderRevenue,
                            numberParts: order.orderLines.length,

                            VehicleSaleId: null,
                            VehicleId: null,
                            ServiceId: null,
                            OnlineOrderId: null,
                            customerName: null
                        };

                        if (order.VehicleSaleId) {
                            partValues.saleTotal += 1;
                            partValues.saleCost += totalOrderCost;
                            partValues.saleRevenue += totalOrderRevenue;

                            saleLine.VehicleSaleId = order.VehicleSaleId;
                        } else if (order.VehicleId) {
                            partValues.vehicleTotal += 1;
                            partValues.vehicleCost += totalOrderCost;
                            partValues.vehicleRevenue += totalOrderRevenue;

                            saleLine.VehicleId = order.VehicleId;
                        } else if (order.ServiceId) {
                            partValues.serviceTotal += 1;
                            partValues.serviceCost += totalOrderCost;
                            partValues.serviceRevenue += totalOrderRevenue;

                            saleLine.ServiceId = order.ServiceId;
                        } else if (order.OnlineOrderId) {
                            partValues.onlineTotal += 1;
                            partValues.onlineCost += totalOrderCost;
                            partValues.onlineRevenue += totalOrderRevenue;

                            saleLine.OnlineOrderId = order.OnlineOrderId;
                        } else {
                            partValues.partTotal += 1;
                            partValues.partCost += totalOrderCost;
                            partValues.partRevenue += totalOrderRevenue;

                            saleLine.customerName = order?.Customer?.firstName
                                ? order?.Customer?.firstName.charAt(0) +
                                  '. ' +
                                  order?.Customer?.lastName
                                : order?.Customer?.lastName;
                        }

                        partData.push(saleLine);
                    }
                });

                let finalArray = {
                    user: userDetails,
                    orderData: partData,
                    orderValues: partValues,
                    wrongOrderData: wrongPartData
                };

                ordersFinal.push(finalArray);
            }
        });

        params.setStaffVehicleSales(vehicleSalesFinal);
        params.setStaffOrdersSales(ordersFinal);
        params.setLoading(false);
    });
};

export default CalculateStaffSalesReporting;
