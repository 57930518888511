import api from '../../../../../api';

const GetManualTransaction = (id: number, setManualTransaction) => {
  api.get(`manualTransaction/${id}`).then((res) => {
    let data = res.data;

    if (data.amount < 0) {
      data.credit = Math.abs(data.amount);
      data.debit = 0;
    } else {
      data.debit = Math.abs(data.amount);
      data.credit = 0;
    }

    setManualTransaction(data);
  });
};

export default GetManualTransaction;
