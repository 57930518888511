// IMPORTS
// eslint-disable-next-line
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable';
import moment from 'moment';
// INTERFACES
import { Customer } from '../../../../global/interfaces/GeneralInterface';

const CustomerDetails = async (
    doc,
    site,
    settings,
    selectedCustomer: Customer
) => {
    doc.setFont('helvetica', 'bold').setFontSize(9);
    doc.text('CUSTOMER DETAILS', 11, 58);
    doc.rect(10, 53, 93, 60); // The box around the customer details
    doc.line(10, 61, 103, 61); // Horizontal line below customer details

    doc.setFont('helvetica', 'normal').setFontSize(7);
    doc.text('Full Name / Company Name:', 11, 64);
    doc.line(10, 71, 103, 71); // Horizontal line below customer name
    doc.line(10, 78, 103, 78); // Horizontal line below address line 1

    doc.text('Postcode:', 70, 83);
    doc.line(10, 85, 103, 85); // Horizontal line below addres line 2 and postcode

    doc.text('Telephone No:', 11, 90);
    doc.line(10, 92, 103, 92); // Horizontal line below phone number

    doc.text('Email:', 11, 97);
    doc.line(10, 99, 103, 99); // Horizontal line below email address

    doc.text('Date of', 11, 102);
    doc.text('Birth:', 11, 105);
    doc.line(55, 99, 55, 106); // Vertical line between dob and licence number
    doc.text("Driver's", 56, 102);
    doc.text('License No:', 56, 105);
    doc.line(10, 106, 103, 106); // Horizontal line below dob and licence number

    doc.text('ABN:', 11, 111);

    doc.setFont('helvetica', 'normal').setFontSize(9);
    if (selectedCustomer) {
        if (selectedCustomer.companyName) {
            doc.text(selectedCustomer.companyName, 11, 69);
        } else {
            doc.text(
                selectedCustomer.firstName + ' ' + selectedCustomer?.lastName,
                11,
                69
            );
        }

        if (selectedCustomer.addressLine1) {
            doc.text(selectedCustomer.addressLine1, 11, 76);
        }

        if (selectedCustomer.addressLine2 && selectedCustomer.suburb) {
            doc.text(
                selectedCustomer.addressLine2 + ' ' + selectedCustomer.suburb,
                11,
                83
            );
        } else if (selectedCustomer.addressLine2) {
            doc.text(selectedCustomer.addressLine2, 11, 83);
        } else if (selectedCustomer.suburb) {
            doc.text(selectedCustomer.suburb, 11, 83);
        }

        if (selectedCustomer.postcode) {
            doc.text(selectedCustomer.postcode, 82, 83);
        }

        if (selectedCustomer.phoneNumber) {
            doc.text(selectedCustomer.phoneNumber, 30, 90);
        }

        if (selectedCustomer.email) {
            doc.text(selectedCustomer.email, 19, 97);
        }

        if (selectedCustomer.driverLicenceNumber) {
            doc.text(selectedCustomer.driverLicenceNumber, 71, 105);
        }

        if (selectedCustomer.dateOfBirth) {
            let dob = moment(selectedCustomer.dateOfBirth);
            doc.text(dob.format('DD MMM YYYY'), 19, 105);
        }

        if (selectedCustomer.abn) {
            doc.text(selectedCustomer.abn, 19, 111);
        }
    } else {
        doc.text(settings.companyName, 11, 69);
        doc.text(site.address, 11, 76);
        doc.text(site.suburb + ' ' + site.state, 11, 83);
        doc.text(site.postcode, 82, 83);
        doc.text(site.phoneNumber, 30, 90);
        if (site.email) {
            doc.text(site.email, 19, 97);
        }
        if (settings.abn) {
            doc.text(settings.abn, 19, 111);
        }
    }

    return doc;
};

export default CustomerDetails;
