import { Dispatch, SetStateAction } from 'react';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import api from '../../../../../api';

interface GroupedOnlineOrder {
  oraOrder: Order;
  netoOrder: OnlineOrder;
}
interface GroupedOnlineOrders {
  pick: GroupedOnlineOrder[];
  pack: GroupedOnlineOrder[];
}

const GetAllPickPackOrders = (
  setPickPackOrders: Dispatch<SetStateAction<GroupedOnlineOrders>>,
  setSelectedGroupOrders: Dispatch<SetStateAction<GroupedOnlineOrder[]>>,
  setPageLoading: Dispatch<SetStateAction<boolean>>
) => {
  setPageLoading(true);
  api
    .get(`allPickPackOrders?SiteId=${localStorage.getItem('SiteId')}`)
    .then((res) => {
      setPickPackOrders(res.data);
      setSelectedGroupOrders(res.data.pick);
      setPageLoading(false);
    });
};

export default GetAllPickPackOrders;
