import { Dispatch, SetStateAction } from 'react';
import { DialogContent, Fab } from '@mui/material';

import { Close } from '@material-ui/icons';
import NewBooking from '../../newBooking/NewBooking';
import { Dayjs } from 'dayjs';

interface JobDialogProps {
    setEventDialog: Dispatch<SetStateAction<boolean>>;
    selectedDate?: any;
    selectedId?: number;
    techId?: number;
    startDate?: Dayjs;
    endDate?: Dayjs;
}

const JobDialog = ({
    setEventDialog,
    selectedId,
    techId,
    startDate,
    endDate
}: JobDialogProps) => {
    return (
        <DialogContent>
            {selectedId ? (
                <NewBooking selectId={selectedId} />
            ) : (
                <NewBooking
                    technicianId={techId}
                    startDate={
                        startDate.format('YYYY-MM-DD') +
                        'T' +
                        startDate.format('HH:mm')
                    }
                    endDate={
                        endDate.format('YYYY-MM-DD') +
                        'T' +
                        endDate.format('HH:mm')
                    }
                />
            )}
            <Fab
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 85,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
                onClick={() => {
                    setEventDialog(false);
                }}
            >
                <Close />
            </Fab>
        </DialogContent>
    );
};

export default JobDialog;
