// IMPORTS
import { TableRow, Typography, Divider, Chip } from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import {
    DateFormatter,
    CurrencyFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';

interface SalesProps {
    sales: VehicleSale[];
}

const Sales = ({ sales }: SalesProps) => {
    const columns = [
        { id: 0, label: 'Sale ID' },
        { id: 1, label: 'Status' },
        { id: 2, label: 'Customer' },
        { id: 3, label: 'Date' },
        { id: 4, label: 'Sale Total' },
        { id: 5, label: 'Location' },
        { id: 6, label: 'User' }
    ];

    return (
        <Paper>
            <Typography variant="h5" sx={{ fontSize: '25px' }}>
                Sales
            </Typography>
            <br />
            <Divider />
            <DataTable columns={columns}>
                {sales.map((sale) => (
                    <TableRow>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(`/vehicles/viewContract/${sale.id}`)
                            }
                        >
                            {sale.id}
                        </DataCellColoured>
                        <DataCell>
                            <Chip
                                variant="outlined"
                                label={sale?.saleStatus}
                                color={
                                    sale?.saleStatus === 'Sale'
                                        ? 'warning'
                                        : sale?.saleStatus === 'Finalised'
                                          ? 'success'
                                          : 'error'
                                }
                            />
                        </DataCell>
                        <DataCell>
                            {sale?.Customer?.companyName
                                ? sale?.Customer?.companyName
                                : sale?.Customer?.firstName +
                                  ' ' +
                                  sale?.Customer?.lastName}
                        </DataCell>
                        <DataCell>
                            {sale?.saleStatus === 'Quote'
                                ? DateFormatter(sale?.quoteDate)
                                : sale?.saleStatus === 'Sale'
                                  ? DateFormatter(sale?.saleDate)
                                  : sale?.saleStatus === 'Finalised'
                                    ? DateFormatter(sale?.finalisedDate)
                                    : DateFormatter(sale?.createdAt)}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(sale?.totalPurchasePrice)}
                        </DataCell>
                        <DataCell>{sale?.Site?.name}</DataCell>
                        <DataCell>
                            {sale?.User?.firstName} {sale?.User?.lastName}
                        </DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </Paper>
    );
};

export default Sales;
