/**
 * ValidateAccountNumber
 * Check for a valid bank account number
 * @author Estienne
 * @param accountNumber a bank account number
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateAccountNumber = (accountNumber: string) => {
    accountNumber = accountNumber.replace(/-/g, ''); // Strip hyphen
    accountNumber = accountNumber.replace(/\s/g, ''); // Strip whitespaces

    // Does the account number contain any valid non-numeric number characters?
    if (accountNumber.includes('e') || accountNumber.includes('.')) {
        return {
            verified: false,
            message: 'Invalid bank account number.',
            subMessage:
                'Bank account number must only include numeric characters.'
        };
    }

    // Is account number a valid number?
    if (!Number(accountNumber)) {
        return {
            verified: false,
            message: 'Invalid bank account number.',
            subMessage:
                'Bank account number must only contain numeric characters.'
        };
    } else {
        return { verified: true, message: '', subMessage: '' };
    }
};

/**
 * ValidateACN
 * Check for a valid ACN
 * @author Estienne
 * @param acn an ACN
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateACN = (acn: string) => {
    /**
     * To verify an ACN:
     *  1. Multiply the first 8 digits by a "weighting factor" based on its position as shown in the table below
     *  2. Sum the resulting 8 products
     *  3. Divide the summed value by 10, noting the remainder
     *  4. Subtract the remainder from 10
     *  4a. (if the remainder is 0, make the calculated check digit 0 instead of 10)
     *  5. If the number calculated in the last step matches the 9th number in the ACN, then the ACN is valid
     *
     * For example, to check if 009 749 964 is a valid ACN:
     *  1. Multiply the first 8 digits by their "weighting factor" to give 0 0 54 35 16 27 18 6
     *  2. Sum together the new numbers to give 156
     *  3. Dividing 156 by 10 gives 15 with a remainder of 6
     *  4. Subtract 6 from 10 to give 6
     *  5. Since the calculated check digit & the final ACN number is 6, the ACN is valid.
     *
     *  WEIGHTING FACTORS
     *  Position    Weighting
     *  1           8
     *  2           7
     *  3           6
     *  4           5
     *  5           4
     *  6           3
     *  7           2
     *  8           1
     *
     * Other ACN verification factors:
     *  - an ACN has 9 digits
     *  - an ACN only contains numeric characters
     */

    if (acn != null) {
        // PRE-VALIDATION: Remove any whitespaces from the ACN
        acn = acn.replace(/\s/g, '');

        // PRE-VALIDATION CHECKS: Check that the input satisfies the basic ACN characteristics
        // Is the ACN 9 characters long?
        if (acn.length !== 9) {
            return {
                verified: false,
                message: 'Invalid ACN.',
                subMessage: 'ACN must contain exactly 9 digits.'
            };
        }

        // Is the ACN a number?
        else if (!Number(acn)) {
            return {
                verified: false,
                message: 'Invalid ACN.',
                subMessage: 'ACN mmust only contain numeric characters.'
            };
        }

        // 1. Multiple the first 8 digits by their "weighting factor"
        // 2. Add the multiplied numbers together
        let weightingFactors = [8, 7, 6, 5, 4, 3, 2, 1];
        let summedNumbers = 0;

        for (let i = 0; i < acn.length - 1; i++) {
            let multipliedDigit = Number(acn.charAt(i)) * weightingFactors[i];
            summedNumbers += multipliedDigit;
        }

        // 3. Divide the summed number by 10 and take the remainder
        let sumDivision = (summedNumbers / 10).toString();
        let calculatedCheckDigit = null;

        // If there's a remainder, subtract it from 10
        if (sumDivision.includes('.')) {
            let remainder: string | number = sumDivision.substring(
                sumDivision.indexOf('.') + 1
            );
            remainder = parseInt(remainder[0]);

            calculatedCheckDigit = (10 - remainder).toString();
        }

        // If there's no remainder, make the calculated check digit 0
        else {
            calculatedCheckDigit = '0';
        }

        // If the calculated check digit matches the last
        // character in the ACN, then the ACN is valid
        if (calculatedCheckDigit === acn[8]) {
            return { verified: true, message: '', subMessage: '' };
        } else {
            return {
                verified: false,
                message: 'Invalid ACN.',
                subMessage: 'ACN failed validation.'
            };
        }
    }
};

/**
 * ValidateBSB
 * Check for a valid BSB
 * @author Estienne
 * @param bsb a given BSB
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateBSB = (bsb: string) => {
    // Is the BSB in the correct format?
    if (bsb.includes('-') || bsb.includes(' ')) {
        if (bsb.charAt(3) !== '-' && bsb.charAt(3) !== ' ') {
            return {
                verified: false,
                message: 'Invalid BSB.',
                subMessage: 'BSB must follow the format "xxxxxx" or "xxx-xxx".'
            };
        }
    }

    bsb = bsb.replace(/-/g, ''); // Strip hyphen
    bsb = bsb.replace(/\s/g, ''); // Strip whitespaces

    // Is the BSB 6 characters long?
    if (bsb.length !== 6) {
        return {
            verified: false,
            message: 'Invalid BSB.',
            subMessage: 'BSB must contain exactly 6 characters.'
        };
    }

    // Is BSB a valid number?
    else if (bsb.includes('e') || bsb.includes('.')) {
        return {
            verified: false,
            message: 'Invalid BSB.',
            subMessage: 'BSB must only include numeric characters.'
        };
    } else if (!Number(bsb)) {
        return {
            verified: false,
            message: 'Invalid BSB.',
            subMessage: 'BSB must be a valid number.'
        };
    } else {
        return { verified: true, message: '', subMessage: '' };
    }
};

/**
 * ValidateLMD
 * Check for a valid LMD
 * @author Estienne
 * @param lmd the business's motor dealer licence
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateLMD = (lmd: string) => {
    // Is the LMD a valid number?
    // (check for valid non-numeric number characters first)
    if (lmd.includes('e') || lmd.includes('.') || lmd.includes('-')) {
        return {
            verified: false,
            message: 'Invalid LMD.',
            subMessage: 'LMD must only include numeric characters.'
        };
    } else if (!Number(lmd)) {
        return {
            verified: false,
            message: 'Invalid LMD',
            subMessage: 'LMD must be a valid number'
        };
    } else {
        return { verified: true, message: '', subMessage: '' };
    }
};

/**
 * ValidatePaymentMethods
 * Make sure all payment methods in Company Settings are valid
 * @author Estienne
 * @param methods the given payment methods
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidatePaymentMethods = (
    methods: { id: number; name: string; value: string }[]
) => {
    let validation = { verified: true, message: '', subMessage: '' };

    // Is there at least one payment method?
    if (methods.length < 1) {
        validation = {
            verified: false,
            message: 'There must be at least one payment method.',
            subMessage: ''
        };
    } else {
        methods.every((method) => {
            // Are there any un-named methods?
            if (!method.name) {
                validation = {
                    verified: false,
                    message: 'All payment methods must have a valid name.',
                    subMessage: ''
                };
                return false;
            }

            // Are there any duplicate methods?
            let methodFilter = methods.filter((x) => x.name === method.name);
            if (methodFilter.length > 1) {
                validation = {
                    verified: false,
                    message: 'All payment methods must be unique.',
                    subMessage: `${method.name} is listed twice.`
                };
                return false;
            }

            return true;
        });
    }

    return validation;
};

/**
 * ValidateWarranties
 * Make sure all warranties in Company Settings are valid
 * @author Estienne
 * @param warranties the given warranties
 * @returns an object containing a validity status and an error message/subMessage
 */
export const ValidateWarranties = (
    warranties: {
        id: number;
        length: number;
        costPrice: number;
        customerCharge?: number;
    }[]
) => {
    let validation = { verified: true, message: '', subMessage: '' };

    // Does each warranty have a length and cost price?
    warranties.every((warranty) => {
        if (!warranty.length || !warranty.costPrice) {
            validation = {
                verified: false,
                message: 'All warranties must have a valid length and cost.',
                subMessage: ''
            };
            return false;
        }

        return true;
    });

    return validation;
};
