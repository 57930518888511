import DocumentationPage from '../DocumentationPage';
import { ServiceCalendarSteps } from '../data/service/calendar';

const CalendarSupportPage = () => {
    const contentSections = [
        {
            id: 'calendar',
            title: 'Service Calendar',
            href: '/support/service/calendar/#calendar',
            description:
                'The service calendar is where you can view and manage service bookings in a monthly, fortnightly, weekly or technician view. Follow these steps to navigate the service calendar:',
            steps: ServiceCalendarSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Calendar"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default CalendarSupportPage;
