// IMPORTS
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Vehicle } from '../../global/interfaces/VehicleSaleInterface';
// COMPONENTS
import Fab from '../../global/Fab';
import PageWrapper from '../../global/PageWrapper';
import UpdateVehicleRRPsFilter from './components/UpdateVehicleRRPsFilter';
import UpdateVehicleRRPsResults from './components/UpdateVehicleRRPsResults';
import handleUpdateVehicleRRPs from './logic/handleUpdateVehicleRRPs';
import { withSnackbar } from '../../global/WrappingSnackbar';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

/**
 * UpdateVehicleRRPs
 * The overarching page for Update Vehicle RRPs
 * @author Estienne
 */
const UpdateVehicleRRPs = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [vehicleResults, setVehicleResults] = useState<Vehicle[]>([]);

    const [showResults, setShowResults] = useState<boolean>(false);
    const [editAll, setEditAll] = useState<boolean>(false);

    /**
     * useEffect
     * Show the vehicle results table
     */
    useEffect(() => {
        if (vehicleResults.length > 0) {
            setShowResults(true);
        } else {
            setShowResults(false);
        }
    }, [vehicleResults]);

    return (
        <PageWrapper>
            <Typography variant="h4">Update Vehicle RRPs</Typography>
            <br />
            <UpdateVehicleRRPsFilter
                vehicleResults={vehicleResults}
                setVehicleResults={setVehicleResults}
                showSnackbar={showSnackbar}
                editAll={editAll}
                setEditAll={setEditAll}
            />
            <br />
            {showResults ? (
                <UpdateVehicleRRPsResults
                    vehicleResults={vehicleResults}
                    setVehicleResults={setVehicleResults}
                    editAll={editAll}
                />
            ) : (
                ''
            )}

            {showResults ? (
                <Fab
                    editIcon={false}
                    onClick={() =>
                        handleUpdateVehicleRRPs({
                            vehicleResults,
                            showSnackbar
                        })
                    }
                />
            ) : (
                ''
            )}
        </PageWrapper>
    );
};

export default withSnackbar(UpdateVehicleRRPs);
