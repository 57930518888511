// IMPORTS
import { Typography, TableRow } from '@mui/material';
// COMPONETNS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
// LOGIC
import {
  DateFormatter,
  CurrencyFormatter
} from '../../../global/logic/Formatters';
// INTERFACES
import { VoucherTransactionLine } from '../../../global/interfaces/PartsInterface';

interface TransactionDrawerItemProps {
  title: string;
  startLink: string;
  rows: VoucherTransactionLine[];
}

const TransactionDrawerItem = ({
  title,
  startLink,
  rows
}: TransactionDrawerItemProps) => {
  const columns = [
    { id: 0, label: 'ID' },
    { id: 1, label: 'Date' },
    { id: 2, label: 'Amount' }
  ];
  return (
    <>
      <Typography variant="h6">
        <b>{title}</b>
      </Typography>
      <DataTable columns={columns}>
        {rows?.map((row) => (
          <TableRow>
            <DataCellColoured
              handleClick={() => window.open(`${startLink}${row.id}`, '_blank')}
            >
              {row.id}
            </DataCellColoured>
            <DataCell>{DateFormatter(row.date)}</DataCell>
            <DataCell>{CurrencyFormatter(row.amount)}</DataCell>
          </TableRow>
        ))}
      </DataTable>
      <br />
      <br />
    </>
  );
};

export default TransactionDrawerItem;
