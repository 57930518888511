import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
    Grid,
    Box,
    InputBase,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
    DialogActions,
    ListItemButton,
    ListItemText
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Fuse from 'fuse.js';
import { documentationData } from '../data/documentationData';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
    }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        }
    }
}));

export default function DocumentationSearchBar() {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [results, setResults] = React.useState([]);
    const [openResultsDialog, setOpenResultsDialog] = React.useState(false);

    const flattenDocumentationData = documentationData.flatMap((page) =>
        page.sections.map((section) => ({
            id: section.id,
            title: section.title,
            description: section.description,
            href: section.href
        }))
    );

    const fuse = new Fuse(flattenDocumentationData, {
        keys: ['title', 'description', 'id'],
        includeScore: true,
        threshold: 0.4
    });

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            if (searchTerm.length > 2) {
                const filteredResults = fuse.search(searchTerm);
                // Sort results by score: the lower the score, the higher the relevance
                const sortedResults = filteredResults.sort(
                    (a, b) => a.score - b.score
                );
                setResults(sortedResults.map((result) => result.item));
                setOpenResultsDialog(true);
            } else {
                setResults([]);
                setOpenResultsDialog(true);
            }
        }
    };

    const handleClose = () => {
        setOpenResultsDialog(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleSearch}
                />
            </Search>
            <Dialog
                open={openResultsDialog}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        backgroundColor: '#f0f0f0'
                    }
                }}
            >
                <DialogTitle>Relevant search results</DialogTitle>
                <DialogContent>
                    {results.length > 0 ? (
                        <Grid container>
                            {results.map((item, index) => (
                                <Grid item xs={6} key={index}>
                                    <ListItemButton
                                        component="a"
                                        href={item.href}
                                    >
                                        <ListItemText
                                            primary={
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: 'black',
                                                            paddingRight: '8px'
                                                        }}
                                                    >
                                                        •
                                                    </span>{' '}
                                                    {item.title}
                                                </span>
                                            }
                                            primaryTypographyProps={{
                                                variant: 'body2'
                                            }}
                                        />
                                    </ListItemButton>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <DialogContentText>
                            No matching documentation found.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}


