import { Step } from '../../interface';

export const ManageVehicleTransfersSteps: Step[] = [
    {
        label: "Click 'Vehicle Sales' > 'Transfers' in the sidebar to navigate to the vehicle transfers page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/transfers/transfers-01.png'
    },
    {
        label: 'To create a new Vehicle Transfer, click New Transfer in the speed dial menu to open the New Transfer drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/transfers/transfers-02.png'
    },
    {
        label: 'Select the site to transfer from and the site to transfer to, then choose the vehicle to transfer from the dropdown menu. Click on the Save button to create the transfer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/transfers/transfers-03.png'
    },
    {
        label: "The new transfer will now be listed on the Vehicle Transfers page with the status set to 'Pending'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/transfers/transfers-04.png'
    },
    {
        label: 'To complete a pending transfer, click on the transfer ID to open the Transfer Details page. Then, click the Mark Transfer As Complete button to finalize the transfer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/transfers/transfers-05.png'
    },
    {
        label: 'To export transfer history, click the Export Current Result button in the speed dial menu to download the transfer history as a CSV file.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/transfers/transfers-06.png'
    }
];
