import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const DeleteCreditInvoice = (
    id: string,
    creditorId: number,
    showSnackbar: showSnackbar
) => {
    api.delete('adminCreditInvoice/' + id).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Credit Invoice successfully deleted',
                "You will now be redirect to the Creditor's page."
            );
            setTimeout(
                () =>
                    (window.location.href =
                        '/viewCreditor/' + creditorId.toString()),
                1000
            );
        } else if (res.status === 500) {
            showSnackbar(
                "Something went wrong and Ora couldn't delete the Credit Invoice",
                'Try again or contact the Ora Development Team.',
                'error'
            );
        }
    });
};

export default DeleteCreditInvoice;
