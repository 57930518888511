// LOGIC
import api from '../../../../api';

const GetAllMakes = (setMakes) => {
  api.get('allMakes').then((res) => {
    setMakes(res.data);
  });
};

export default GetAllMakes;
