import { Dispatch, SetStateAction, useState } from 'react';
import { DialogActions, DialogContent, TextField } from '@mui/material';

// INTERFACE
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Category } from '../../../../global/interfaces/PartsInterface';
import UpdateCategoryName from '../../logic/UpdateCategoryName';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const EditCategoryDialogContent = ({
    showSnackbar,
    selectedCategory,
    setSelectedCategory
}: {
    showSnackbar: showSnackbar;
    selectedCategory: Category;
    setSelectedCategory: Dispatch<SetStateAction<Category>>;
}) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    return (
        <>
            <DialogContent>
                <TextField
                    fullWidth
                    size="small"
                    value={selectedCategory?.name}
                    onChange={(e) =>
                        setSelectedCategory({
                            ...selectedCategory,
                            name: e.target.value
                        })
                    }
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        UpdateCategoryName(
                            selectedCategory,
                            showSnackbar,
                            setButtonLoading
                        )
                    }
                >
                    Update Category Name
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default EditCategoryDialogContent;
