import api from '../../../../../api';

const updateVehicleContractStatus = async (
    vehicelSaleId: number,
    status: string | null
) => {
    const res = await api.put(`updateVehicleSaleContractStatus`, {
        vehicelSaleId,
        status
    });

    return res;
};

export default updateVehicleContractStatus;
