// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MenuItem, TextField, Typography } from '@mui/material';
// LOGIC
import GetAllSiteTillsCustom from '../logic/GetAllSiteTillsCustom';
// INTERFACES
import { Till } from '../../../global/interfaces/GeneralInterface';

interface TillSelectorProps {
    selectedTillId: number;
    setSelectedTillId: Dispatch<SetStateAction<number>>;
    label?: string;
    selectedDate?;
    selectedSite?;
}

const TillSelector = ({
    selectedTillId,
    setSelectedTillId,
    label,
    selectedDate,
    selectedSite
}: TillSelectorProps) => {
    const [tills, setTills] = useState<Till[]>([]);

    useEffect(() => {
        if (selectedSite) {
            GetAllSiteTillsCustom(selectedSite, setTills, selectedDate);
        } else {
            GetAllSiteTillsCustom(
                parseInt(localStorage.getItem('SiteId')),
                setTills,
                selectedDate
            );
        }
    }, [selectedDate, selectedSite]);

    useEffect(() => {
        if (tills.length > 0) {
            setSelectedTillId(tills[0].id);
        }
        // eslint-disable-next-line
    }, [tills]);

    return (
        <>
            <TextField
                fullWidth
                select
                label={label ? label : ''}
                size={'small'}
                variant="outlined"
                value={selectedTillId}
                onChange={(e) => setSelectedTillId(parseInt(e.target.value))}
                InputLabelProps={{ shrink: true }}
            >
                {tills.map((till) => (
                    <MenuItem value={till.id}>
                        {till.name}
                        <Typography variant="caption" sx={{ color: '#808080' }}>
                            &nbsp;{till.reconciliationDetails}
                        </Typography>
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};

export default TillSelector;
