import { Dispatch, SetStateAction, useState } from 'react';
import { DialogActions, DialogContent, TextField } from '@mui/material';

// INTERFACE
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Series } from '../../../../global/interfaces/VehicleSaleInterface';
import UpdateSerieName from '../../logic/UpdateSerieName';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const EditSeriesDialogContent = ({
    showSnackbar,
    selectedSeries,
    setSelectedSeries
}: {
    showSnackbar: showSnackbar;
    selectedSeries: Series;
    setSelectedSeries: Dispatch<SetStateAction<Series>>;
}) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    return (
        <>
            <DialogContent>
                <TextField
                    fullWidth
                    size="small"
                    value={selectedSeries?.name}
                    onChange={(e) =>
                        setSelectedSeries({
                            ...selectedSeries,
                            name: e.target.value
                        })
                    }
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        UpdateSerieName(
                            selectedSeries,
                            showSnackbar,
                            setButtonLoading
                        )
                    }
                >
                    Update Series Name
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default EditSeriesDialogContent;
