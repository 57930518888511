import { ToFixed } from "../../../../global/logic/Formatters";

const CalculateTotalsV2 = (transactions) => {
    let total = 0;
    if (transactions.length > 0) {
        transactions.forEach(items => {
            items.lines.forEach(item => {
                if (!Number.isNaN(parseFloat(item.amount))) {
                    total += parseFloat(item.amount)                    
                }
            })
        })
    }
    return ToFixed(total)
}

export default CalculateTotalsV2