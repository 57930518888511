import { PDFDocument } from 'pdf-lib';
import { useEffect, useState } from 'react';
import { GetFileFromS3 } from '../../../../esign/logic/S3FileService';
import { useParams } from 'react-router-dom';
import PageWrapper from '../../../../global/PageWrapper';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import CustomerPersonalisedPlateForm from '../customerForms/CustomerPersonalisedPlateForm';
import renderCustomerPlatePdf from '../../logic/renderCustomerPlatePdf';

const CustomerSignPersonalisedPlate = ({
    setFilesToUpload,
    setEmailAttachments,
    setIsPreviewed
}) => {
    let { id: vehicleId } = useParams<{ id: string }>();

    const [pdfDoc, setPdfDoc] = useState<PDFDocument>(null);
    const [pdfUrl, setPdfUrl] = useState<string>(null);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const LoadContractPDF = () => {
        GetFileFromS3({
            folderName: `vehicleTrades/${vehicleId}`,
            fileName: 'PersonalisedPlate',
            setPdfDoc: setPdfDoc,
            setPdfUrl: setPdfUrl
        });
    };

    useEffect(() => {
        LoadContractPDF();
        // eslint-disable-next-line
    }, [vehicleId]);

    const handlePreviewChanges = (pdfBlob) => {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);

        // Convert blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
            const base64data = reader.result as string;

            setFilesToUpload((prev) => [
                ...prev,
                {
                    fileName: 'PersonalisedPlate',
                    base64data: base64data
                }
            ]);

            setEmailAttachments((prev) => {
                const updatedAttachments = { ...prev };
                updatedAttachments[`Personalised_Plate_#${vehicleId}`] =
                    base64data;

                return updatedAttachments;
            });
        };
    };

    // display the form data on corresponding PDF fields
    const onVendorFormSubmit = async (formData, submitType) => {
        if (!pdfDoc) return;

        // Make a copy of original document, so everytime formData changes, we start from the original document
        const pdfDocCopy = await PDFDocument.load(await pdfDoc.save());

        if (submitType === 'upload') {
            setButtonLoading(true);
        }

        const pdfBytes = await renderCustomerPlatePdf(pdfDocCopy, formData);

        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Update the PDF URL to reflect changes
        if (submitType === 'preview') {
            handlePreviewChanges(pdfBlob);
            setIsPreviewed(true);
        }
    };

    return (
        <PageWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {pdfUrl ? (
                        <Box sx={{ padding: 2 }} width="100%" height="160vh">
                            <iframe
                                src={pdfUrl + '#view=Fit'}
                                width="100%"
                                height="100%"
                                title={`Remove and/or Attach Personalised/Customised Number Plate/s Application #${vehicleId}`}
                            >
                                Loading PDF....
                            </iframe>
                        </Box>
                    ) : (
                        <Box
                            sx={{ padding: 2 }}
                            width="100%"
                            height="100%"
                            textAlign={'center'}
                        >
                            <CircularProgress />
                            <Typography variant="h5">
                                Loading PDF....
                            </Typography>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <CustomerPersonalisedPlateForm
                        onVendorFormSubmit={onVendorFormSubmit}
                        buttonLoading={buttonLoading}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
export default CustomerSignPersonalisedPlate;
