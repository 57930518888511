import { useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { Add, Archive, Delete, Edit } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import TableSearch from '../../../global/tableComponents/TableSearch';
import DataCell from '../../../global/tableComponents/DataCell';
import AddCategoryDialogContent from './CategoryComponent/AddCategoryDialogContent';
import CategoryDrawerContent from './CategoryComponent/CategoryDrawerContent';
import SpeedDial from '../../../global/SpeedDial';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import AddPartsToCategoryDrawer from './CategoryComponent/AddPartsToCategoryDrawer';
import Drawer from '../../../global/Drawer';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Paper from '../../../global/Paper';
// LOGIC
import DeleteCategory from '../logic/DeleteCategory';
import EditCategoryDialogContent from './CategoryComponent/EditCategoryDialogContent';
import HandleCategoryClick from '../logic/HandleCategoryClick';
// INTERFACES
import { Category, Unit } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';
interface TempCategory extends Category {
    nbOfUnit: number;
}

const clearedSort = ['name', 'ASC'];

const CategoriesTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [sort, setSort] = useState(clearedSort);
    const [categoriesList, setCategoriesList] = useState<TempCategory[]>([]);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [openCategoryDrawer, setOpenCategoryDrawer] =
        useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<TempCategory>();
    const [listOfUnit, setListOfUnit] = useState<Unit[]>([]);
    const [filterListOfUnit, setFilterListOfUnit] = useState<Unit[]>([]);
    const [filterValue, setFilterValue] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [openAddCategoryDialog, setOpenAddCategoryDialog] =
        useState<boolean>(false);
    const [openEditCategoryDialog, setOpenEditCategoryDialog] =
        useState<boolean>(false);
    const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] =
        useState<boolean>(false);
    const [openAddPartsToCategoryDrawer, setOpenAddPartsToCategoryDrawer] =
        useState<boolean>(false);

    const columns = [
        { id: 0, label: 'Category', width: 250 },
        { id: 1, label: 'Parent Category', width: 250 },
        { id: 2, label: 'Number of Parts assigned', width: 250 },
        { id: 3, label: '', width: 10 },
        { id: 4, label: '', width: 10 }
    ];

    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Category',
            onClick: () => setOpenAddCategoryDialog(true)
        },
        {
            icon: <Archive />,
            name: 'Add Parts to a Category',
            onClick: () => setOpenAddPartsToCategoryDrawer(true)
        }
    ];

    const handleRequestCreate = () => {
        let apiString = ``;
        return apiString;
    };

    const handleFilterValueChange = (value: string) => {
        setFilterValue(value.toUpperCase());
        setFilterListOfUnit(
            listOfUnit.filter((x) => x.partNumber.includes(value.toUpperCase()))
        );
        setPage(1);
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Category"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="categoriesSpecificTable"
                setResultsList={setCategoriesList}
            />

            <Paper>
                <DataTable columns={columns} sort={sort} setSort={setSort}>
                    {categoriesList.map((category) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() => {
                                    HandleCategoryClick(
                                        category,
                                        setSelectedCategory,
                                        setOpenCategoryDrawer,
                                        setListOfUnit,
                                        setFilterListOfUnit,
                                        setFilterValue,
                                        setLoading
                                    );
                                }}
                            >
                                {category.name}
                            </DataCellColoured>
                            <DataCell>{category.parent}</DataCell>
                            <DataCell>{category.nbOfUnit}</DataCell>
                            <DataCell align="right">
                                <Tooltip title="Edit Make Name" placement="top">
                                    <IconButton
                                        onClick={() => {
                                            setOpenEditCategoryDialog(true);
                                            setSelectedCategory(category);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </DataCell>
                            <DataCell align="left">
                                {category.nbOfUnit === 0 ? (
                                    <Tooltip
                                        title="Delete Make"
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setOpenDeleteCategoryDialog(
                                                    true
                                                );
                                                setSelectedCategory(category);
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            {loading ? (
                <Dialog open={loading}>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            ) : (
                <Drawer
                    openDrawer={openCategoryDrawer}
                    setOpenDrawer={setOpenCategoryDrawer}
                    title={selectedCategory?.name + ' Parts'}
                    subTitle=""
                    width="40vw"
                >
                    <CategoryDrawerContent
                        selectedCategory={selectedCategory}
                        filterValue={filterValue}
                        filterListOfUnit={filterListOfUnit}
                        page={page}
                        setPage={setPage}
                        setFilterValue={setFilterValue}
                        handleFilterValueChange={handleFilterValueChange}
                    />
                </Drawer>
            )}

            <Dialog
                maxWidth="sm"
                fullWidth
                open={openAddCategoryDialog}
                onClose={() => {
                    setOpenAddCategoryDialog(false);
                }}
            >
                <DialogTitle>New Category</DialogTitle>
                <AddCategoryDialogContent showSnackbar={showSnackbar} />
            </Dialog>

            <Drawer
                openDrawer={openAddPartsToCategoryDrawer}
                setOpenDrawer={setOpenAddPartsToCategoryDrawer}
                title={'Add Parts to a Category'}
                subTitle=""
                width="40vw"
            >
                <AddPartsToCategoryDrawer showSnackbar={showSnackbar} />
            </Drawer>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={openEditCategoryDialog}
                onClose={() => {
                    setOpenEditCategoryDialog(false);
                }}
            >
                <DialogTitle>Edit Category</DialogTitle>
                <EditCategoryDialogContent
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    showSnackbar={showSnackbar}
                />
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={openDeleteCategoryDialog}
                onClose={() => {
                    setOpenDeleteCategoryDialog(false);
                }}
            >
                <DialogTitle>Delete {selectedCategory?.name}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to delete this Category:{' '}
                        {selectedCategory?.name}?<br />
                        <b>This cannot be undone.</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDeleteCategoryDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            DeleteCategory(
                                selectedCategory,
                                showSnackbar,
                                setButtonLoading
                            )
                        }
                    >
                        Delete Category
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <SpeedDial actionsList={actionsList} />
        </>
    );
};
export default withSnackbar(CategoriesTable);
