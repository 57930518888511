// IMPORTS
import { useState, useEffect, useContext } from 'react';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Paper,
    CircularProgress
} from '@mui/material';
import { Edit, Delete, LocalShipping, Assignment } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Details from './components/Details/Details';
import Movement from './components/Movement/Movement';
import AddedCosts from './components/AddedCosts/AddedCosts';
import Tabs from '../../global/Tabs';
import Fab from '../../global/Fab';
import SpeedDial from '../../global/SpeedDial';
import Drawer from '../../global/Drawer';
import NewTransferDrawerContent from '../vehicleTransferTable/components/NewTransferDrawerContent';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import PageDoesNotExist from '../../global/PageDoesNotExist';
import { renderVehicleStatus } from '../vehicleTable/components/RenderVehicleStatus';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import GetVehicleDetails from '../../global/databaseLogic/GetVehicleDetails';
import GetAllVehicleSpecifics from '../../global/databaseLogic/GetAllVehicleSpecifics';
import HandleDeleteVehicle from './logic/HandleDeleteVehicle';
import GetVehicleMovement from './logic/GetVehicleMovement';
import HandleUpdateVehicle from './logic/HandleUpdateVehicle';
import PDFVendorStatement from '../newVehicleSale/logic/PDFVendorStatement';
// INTERFACES
import {
    TradeIn,
    Vehicle,
    VehicleSale,
    VehicleTransfer
} from '../../global/interfaces/VehicleSaleInterface';
import { Specific, User } from '../../global/interfaces/GeneralInterface';
import { Site } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Invoice } from '../../global/interfaces/PartsInterface';
import CheckCurrentUserSignature from '../../global/databaseLogic/CheckCurrentUserSignature';
import { UserContext } from '../../../main/Main';
import DealerDocumentActionDialog from '../../esign/components/DealerDocumentActionDialog';
import updateTradeInStatementStatus from '../signVendorStatement/logic/updateStatementStatus';

interface SpecificsList {
    makes?: Specific[];
    models?: Specific[];
    series?: Specific[];
    aspirations?: Specific[];
    fuelTypes?: Specific[];
    bodyTypes?: Specific[];
    driveTypes?: Specific[];
    sites?: Site[];
}

interface VehicleMovement {
    invoices: Invoice[];
    trades: TradeIn[];
    transfers: VehicleTransfer[];
    sales: VehicleSale[];
}

const ViewVehicle = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const currentUser = useContext<User>(UserContext);

    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [transferDrawerOpen, setTransferDrawerOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);

    const [vehicle, setVehicle] = useState<Vehicle>({
        Make: { name: '' },
        Model: { name: '' },
        Series: { name: '' },
        FuelType: { name: '' },
        BodyType: { name: '' },
        DriveType: { name: '' },
        Site: null,
        condition: ''
    });
    const [specifics, setSpecifics] = useState<SpecificsList>({
        makes: [],
        models: [],
        series: [],
        aspirations: [],
        fuelTypes: [],
        bodyTypes: [],
        driveTypes: [],
        sites: []
    });

    const [vehicleMovement, setVehicleMovement] = useState<VehicleMovement>({
        invoices: [],
        trades: [],
        transfers: [],
        sales: []
    });

    useEffect(() => {
        GetVehicleDetails(parseInt(id), setVehicle, setLoading, setInvalid);
        GetVehicleMovement(parseInt(id), setVehicleMovement);
        GetAllVehicleSpecifics(setSpecifics, [
            'makes',
            'models',
            'series',
            'aspirations',
            'fuelTypes',
            'bodyTypes',
            'driveTypes',
            'sites'
        ]);
    }, [id]);

    const [openDealerActionDialog, setOpenDealerActionDialog] = useState(false);

    const navigateToDealerSignVendorStatement = () => {
        window.open(`/docs/vendor-statement/${vehicle.id}`, '_blank');
    };

    const isWaitingSignature =
        vehicleMovement.trades[0]?.statementStatus === 'waitingSignature';
    const isVendorSigned =
        vehicleMovement.trades[0]?.statementStatus === 'vendorSigned';

    const handleOpenVehicleTradeForms = () => {
        // only  proceed to next step if the current step is completed
        CheckCurrentUserSignature({
            currentUser: currentUser,
            showSnackbar: showSnackbar,
            history: history
        }).then(async (base64Image) => {
            await PDFVendorStatement(
                vehicleMovement.trades[0].Customer,
                vehicleMovement.trades,
                base64Image as string,
                true,
                false
            );
            navigateToDealerSignVendorStatement();
        });
    };

    const handleClickStartOver = () => {
        CheckCurrentUserSignature({
            currentUser: currentUser,
            showSnackbar: showSnackbar,
            history: history
        }).then(async (base64Image) => {
            // update the trade in statement status to null
            await updateTradeInStatementStatus(
                vehicleMovement.trades[0]?.id,
                null
            );

            // re-generate a new vendor statement
            await PDFVendorStatement(
                vehicleMovement.trades[0].Customer,
                vehicleMovement.trades,
                base64Image as string,
                true,
                false
            );

            navigateToDealerSignVendorStatement();

            setOpenDealerActionDialog(false);
        });
    };

    const handleClickView = () => {
        navigateToDealerSignVendorStatement();
        setOpenDealerActionDialog(false);
    };

    const actionsList = [
        {
            icon: <Edit />,
            name: 'Edit Vehicle',
            onClick: () => setReadOnly(false)
        },
        {
            icon: <LocalShipping />,
            name: 'Transfer Vehicle',
            onClick: () => setTransferDrawerOpen(true)
        },
        {
            icon: <Delete />,
            name: 'Delete Vehicle',
            onClick: () => setDeleteDialogOpen(true),
            permission: 'delete_vehicle'
        }
    ];

    if (vehicleMovement?.trades?.length > 0) {
        actionsList.push({
            icon: <Assignment />,
            name: 'Vehicle Trade Forms',
            // when the status is waitingSignature or vendorSigned, open the dialog to allow user to view or start over; otherwise, open the trade forms
            onClick:
                isWaitingSignature || isVendorSigned
                    ? () => setOpenDealerActionDialog(true)
                    : handleOpenVehicleTradeForms
        });
    }

    const tabContent = [
        {
            id: 0,
            title: 'Details',
            content: (
                <Details
                    id={parseInt(id)}
                    vehicle={vehicle}
                    setVehicle={setVehicle}
                    specifics={specifics}
                    readOnly={readOnly}
                    showSnackbar={showSnackbar}
                />
            )
        },
        {
            id: 1,
            title: 'Movement',
            content: (
                <Movement vehicle={vehicle} vehicleMovement={vehicleMovement} />
            )
        },
        {
            id: 2,
            title: 'Added Costs',
            content: <AddedCosts vehicle={vehicle} />
        }
    ];

    return (
        <PageWrapper>
            {loading ? (
                <Paper>
                    <Typography textAlign="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4">{`#${vehicle?.stockNumber} - ${vehicle?.Make?.name} ${vehicle?.Model?.name}`}</Typography>
                    <Typography variant="subtitle1">
                        {renderVehicleStatus(vehicle, vehicleMovement)}
                    </Typography>

                    <Tabs tabContent={tabContent} />
                </>
            )}

            {readOnly ? (
                <SpeedDial actionsList={actionsList} />
            ) : (
                <Fab
                    editIcon={false}
                    onClick={() => HandleUpdateVehicle(vehicle, showSnackbar)}
                />
            )}

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Delete Stock #{vehicle?.stockNumber}?</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to delete this vehicle? This
                        action can not be undone and all data related to this
                        vehicle will also be lost.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Close
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            HandleDeleteVehicle(
                                parseInt(id),
                                vehicle,
                                vehicleMovement,
                                setButtonLoading,
                                showSnackbar
                            )
                        }
                    >
                        Delete Vehicle
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <DealerDocumentActionDialog
                dialogOpen={openDealerActionDialog}
                setDialogOpen={setOpenDealerActionDialog}
                status={vehicleMovement?.trades[0]?.statementStatus}
                handleClickView={handleClickView}
                handleClickStartOver={handleClickStartOver}
            />

            <Drawer
                openDrawer={transferDrawerOpen}
                setOpenDrawer={setTransferDrawerOpen}
                title="New Vehicle Transfer"
                subTitle={''}
                width="40vw"
            >
                <NewTransferDrawerContent
                    preAddedSiteFrom={vehicle?.Site}
                    preAddedVehicle={vehicle}
                />
            </Drawer>
        </PageWrapper>
    );
};

export default withSnackbar(ViewVehicle);
