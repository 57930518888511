// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import { ToFixed } from '../../../global/logic/Formatters';
// INTERFACES
import { PaymentLine, User } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const AddPaymentLine = (
    saleTotal: number,
    selectedTillId: number,
    paymentAmount: number,
    paymentLines: PaymentLine[],
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>,
    paymentMethod: string,
    currentUser: User,
    showSnackbar: showSnackbar
) => {
    // Check if there has been a till selected
    if (!selectedTillId) {
        showSnackbar('Please select a till to continue.', '', 'error');
        return;
    }

    // Check that there is a valid payment amount entered (we allow negatives here for dodgy refunds)
    if (!paymentAmount || paymentAmount === 0) {
        showSnackbar(
            'Please enter a valid payment amount to continue.',
            '',
            'error'
        );
        return;
    }

    // Get the current date and time in our timezone
    var tzOffset = new Date().getTimezoneOffset() * 60000;
    var localIsoTime = new Date(Date.now() - tzOffset)
        .toISOString()
        .slice(0, -1);

    // Get the change
    let change = 0;

    if (
        paymentMethod === 'cash' &&
        paymentAmount > 0 &&
        paymentAmount > saleTotal
    ) {
        let current = paymentLines.reduce((a, b) => {
            if (b.removed !== true) {
                return b.amountReceived - b.change + a;
            } else {
                return a;
            }
        }, 0);
        let value =
            Math.round((saleTotal - paymentAmount - current) * 100) / 100;
        change = Math.abs(ToFixed(value));
    }

    let currentPaymentLines = [...paymentLines];

    // Calculate the new row id
    // If there is no orderlines simply set it to 0
    // Otherwise set it to the last id in the orderlines plus 1
    let rowId: number;
    if (currentPaymentLines.length > 0) {
        rowId = currentPaymentLines.at(-1)['id'] + 1;
    } else {
        rowId = 0;
    }

    // Make the payment row
    var newRow = {
        id: rowId,
        type: paymentMethod,
        amountReceived: paymentAmount, // This is how much we physically received from the customer
        amount: ToFixed(paymentAmount - change), // This is how much we tilled (this is just the amount received minus any change)
        change: change, // The change we gave the customer
        TillId: selectedTillId,
        date: localIsoTime.split('T')[0],
        timestamp: localIsoTime,
        user: currentUser.firstName + ' ' + currentUser.lastName
    };

    currentPaymentLines.push(newRow);
    setPaymentLines(currentPaymentLines);
};

export default AddPaymentLine;
