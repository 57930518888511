// IMPORTS
import { Dispatch, SetStateAction } from "react";
// INTERFACES
import { Creditor } from "../interfaces/GeneralInterface";
// LOGIC
import api from "../../../../api";

const GetAllCreditors = (setCreditors: Dispatch<SetStateAction<Creditor[]>>) => {
    api.get(`creditorsNextGen`).then(res => {
        setCreditors(res.data);
    });
}

export default GetAllCreditors;