import { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import { Download } from '@mui/icons-material';

// COMPONENTS
import Paper from '../../../global/Paper';
import Drawer from '../../../global/Drawer';
import Fab from '../../../global/Fab';
import Counters from './Counters';
import ViewOrders from './ViewOrders';
import MissingPartsDrawerContent from './MissingPartsDrawerContent';

// LOGIC
import GetDashboardData from '../../logic/Dashboard/GetDashboardData';
import GetNetoOrders from '../../logic/Dashboard/GetNetoOrders';

// INTERFACE
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}

interface GroupedOnlineOrders {
    onHold: GroupedOnlineOrder[];
    onBackorder: GroupedOnlineOrder[];
    newOrders: GroupedOnlineOrder[];
    pendingDispatch: GroupedOnlineOrder[];
}

const Dashboard = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [ordersLoading, setOrdersLoading] = useState<boolean>(false);

    const [missingParts, setMissingParts] = useState<GroupedOnlineOrder[]>([]);
    const [missingPartsDrawerOpen, setMissingPartsDrawerOpen] =
        useState<boolean>(false);
    const [openDrawerDialog, setOpenDrawerDialog] = useState<boolean>(false);

    const [selectedGroupedOrder, setSelectedGroupedOrder] = useState<
        GroupedOnlineOrder[]
    >([]);
    const [groupedTitle, setGroupedTitle] = useState<string>('New Orders');
    const [groupedOrders, setGroupedOrders] = useState<GroupedOnlineOrders>({
        onHold: [],
        onBackorder: [],
        newOrders: [],
        pendingDispatch: []
    });

    useEffect(() => {
        GetDashboardData(
            setGroupedOrders,
            setSelectedGroupedOrder,
            setPageLoading
        );
    }, []);

    const handleUpdateAllStatesNotes = (
        newNotes: string,
        OrderId: any,
        onlineOrderNewNotes: string
    ) => {
        let currentSelectedGroup = [...selectedGroupedOrder];

        let index = currentSelectedGroup.findIndex(
            (x) => x.oraOrder.id === OrderId
        );

        currentSelectedGroup[index].oraOrder.notes = newNotes;
        currentSelectedGroup[index].netoOrder.internalNotes =
            onlineOrderNewNotes;

        if (groupedTitle === 'New Orders') {
            setGroupedOrders({
                ...groupedOrders,
                newOrders: currentSelectedGroup
            });
        } else if (groupedTitle === 'On Hold') {
            setGroupedOrders({
                ...groupedOrders,
                onHold: currentSelectedGroup
            });
        } else if (groupedTitle === 'On Backorder') {
            setGroupedOrders({
                ...groupedOrders,
                onBackorder: currentSelectedGroup
            });
        } else if (groupedTitle === 'Pending Dispatch') {
            setGroupedOrders({
                ...groupedOrders,
                pendingDispatch: currentSelectedGroup
            });
        }

        setSelectedGroupedOrder(currentSelectedGroup);
    };

    const handleUpdateAllStatesOrderlines = (
        newOrderLines,
        OrderId: any,
        moveOrder: boolean
    ) => {
        let currentSelectedGroup = [...selectedGroupedOrder];
        let index = currentSelectedGroup.findIndex(
            (x) => x.oraOrder.id === OrderId
        );
        currentSelectedGroup[index].oraOrder.orderLines = newOrderLines;

        if (moveOrder) {
            let currentBackorders = [...groupedOrders.onBackorder];
            currentBackorders.unshift(currentSelectedGroup[index]);

            currentSelectedGroup = currentSelectedGroup.filter(
                (x) => x.oraOrder.id !== OrderId
            );

            setGroupedOrders({
                ...groupedOrders,
                onBackorder: currentBackorders
            });
        }

        if (groupedTitle === 'New Orders') {
            setGroupedOrders({
                ...groupedOrders,
                newOrders: currentSelectedGroup
            });
        } else if (groupedTitle === 'On Hold') {
            setGroupedOrders({
                ...groupedOrders,
                onHold: currentSelectedGroup
            });
        }

        setSelectedGroupedOrder(currentSelectedGroup);
    };

    const handleUpdateAllStatesMovement = (
        newStatus: string,
        newSite: Site,
        OrderId: any
    ) => {
        let currentSelectedGroup = [...selectedGroupedOrder];

        let index = currentSelectedGroup.findIndex(
            (x) => x.oraOrder.id === OrderId
        );

        currentSelectedGroup[index].oraOrder.Site = newSite;
        currentSelectedGroup[index].oraOrder.SiteId = newSite.id;

        let currentNewOrders = [...groupedOrders.newOrders];
        let currentOnHoldOrders = [...groupedOrders.onHold];
        let currentOnBackorderOrders = [...groupedOrders.onBackorder];
        let currentPendingDispatchOrders = [...groupedOrders.pendingDispatch];

        if (currentSelectedGroup[index].netoOrder.orderStatus !== newStatus) {
            currentSelectedGroup[index].netoOrder.orderStatus = newStatus;

            if (newStatus === 'New') {
                currentNewOrders.push(currentSelectedGroup[index]);
            }
            if (newStatus === 'On Hold') {
                currentOnHoldOrders.push(currentSelectedGroup[index]);
            }
            if (newStatus === 'New Backorder') {
                currentOnBackorderOrders.push(currentSelectedGroup[index]);
            }
            if (newStatus === 'Pending Dispatch') {
                currentPendingDispatchOrders.push(currentSelectedGroup[index]);
            }

            currentSelectedGroup = currentSelectedGroup.filter(
                (x) => x.oraOrder.id !== OrderId
            );
        }

        if (groupedTitle === 'New Orders') {
            setGroupedOrders({
                ...groupedOrders,
                newOrders: currentSelectedGroup,
                onHold: currentOnHoldOrders,
                onBackorder: currentOnBackorderOrders,
                pendingDispatch: currentPendingDispatchOrders
            });
        } else if (groupedTitle === 'On Hold') {
            setGroupedOrders({
                ...groupedOrders,
                newOrders: currentNewOrders,
                onHold: currentSelectedGroup,
                onBackorder: currentOnBackorderOrders,
                pendingDispatch: currentPendingDispatchOrders
            });
        } else if (groupedTitle === 'On Backorder') {
            setGroupedOrders({
                ...groupedOrders,
                newOrders: currentNewOrders,
                onHold: currentOnHoldOrders,
                onBackorder: currentSelectedGroup,
                pendingDispatch: currentPendingDispatchOrders
            });
        } else if (groupedTitle === 'Pending Dispatch') {
            setGroupedOrders({
                ...groupedOrders,
                newOrders: currentNewOrders,
                onHold: currentOnHoldOrders,
                onBackorder: currentOnBackorderOrders,
                pendingDispatch: currentSelectedGroup
            });
        }

        setSelectedGroupedOrder(currentSelectedGroup);
    };

    return (
        <>
            {pageLoading ? (
                <Paper>
                    <Typography textAlign="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : (
                <>
                    <Counters
                        groupedOrders={groupedOrders}
                        setSelectedGroupedOrder={setSelectedGroupedOrder}
                        groupedTitle={groupedTitle}
                        setGroupedTitle={setGroupedTitle}
                    />
                    <br />
                    <ViewOrders
                        handleUpdateAllStatesMovement={
                            handleUpdateAllStatesMovement
                        }
                        handleUpdateAllStatesOrderlines={
                            handleUpdateAllStatesOrderlines
                        }
                        groupedTitle={groupedTitle}
                        handleUpdateAllStatesNotes={handleUpdateAllStatesNotes}
                        selectedGroupedOrder={selectedGroupedOrder}
                        groupedOrders={groupedOrders}
                        setGroupedOrders={setGroupedOrders}
                    />
                </>
            )}

            <Fab
                customIcon={<Download />}
                loading={ordersLoading}
                onClick={() =>
                    GetNetoOrders(
                        setMissingParts,
                        setMissingPartsDrawerOpen,
                        setOrdersLoading,
                        showSnackbar
                    )
                }
            />

            <Dialog
                open={openDrawerDialog}
                onClose={() => setOpenDrawerDialog(false)}
            >
                <DialogTitle>Close Missing Parts Drawer?</DialogTitle>
                <DialogContent>
                    Any orders that still have missing parts will not be saved
                    in Ora. You will need to pull the orders again to retrieve
                    their data.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDrawerDialog(false);
                        }}
                    >
                        Leave Open
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenDrawerDialog(false);
                            setMissingPartsDrawerOpen(false);
                            setOrdersLoading(false);
                        }}
                        variant="contained"
                    >
                        Close Drawer
                    </Button>
                </DialogActions>
            </Dialog>

            <Drawer
                openDrawer={missingPartsDrawerOpen}
                setOpenDrawer={setMissingPartsDrawerOpen}
                title="Orders Missing Parts"
                subTitle="These orders have parts on them that do not exist in Ora. Please add the parts so that the order can be created."
                onCloseFunction={() => setOpenDrawerDialog(true)}
            >
                <MissingPartsDrawerContent
                    missingParts={missingParts}
                    setMissingParts={setMissingParts}
                />
            </Drawer>
        </>
    );
};

export default withSnackbar(Dashboard);
