// IMPORTS
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import dayjs from 'dayjs';
// COMPONENTS
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
// LOGIC
import { TillReconciliation } from '../../../global/interfaces/AdminInterface';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const PDFTillReconciliationReport = async (
    SiteId: number,
    showSnackbar: showSnackbar,
    selectedDate?
) => {
    let tillReconciliation: TillReconciliation[];
    let formattedDate = selectedDate
        ? dayjs(selectedDate).format('DD/MM/YYYY')
        : moment().format('DD/MM/YYYY');

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    let query = '';

    if (selectedDate) {
        query = `&date=${selectedDate}`;
    }

    await api
        .get(`tillReconciliationReport?SiteId=${SiteId}${query}`)
        .then((res) => {
            tillReconciliation = res.data;
        });

    if (tillReconciliation.length === 0) {
        showSnackbar(
            'Report cannot be generated.',
            `No reconciliations have been submitted for ${formattedDate}`,
            'error'
        );
        return;
    } else {
        // Header for the invoice
        await InvoiceHeader(
            doc,
            parseInt(localStorage.getItem('SiteId')),
            'Till Reconciliation Report',
            formattedDate,
            false,
            false,
            false
        );

        let lastYcoordinates = 60;
        let sizeOfSection = 0;
        let pageCount = 1;

        // Each till section takes up 68 y coords, so need to check after each section if another will fit
        // or whether a new page needs to be added
        for (let i = 0; i < tillReconciliation.length; i++) {
            let reconciliation = tillReconciliation[i];
            let formattedUpdatedDate = moment(reconciliation.updatedAt).format(
                'DD/MM/YYYY h:MM A'
            );

            doc.setFont('helvetica', 'bold').setFontSize(12);
            doc.text(reconciliation?.Till?.name, 14, lastYcoordinates);
            doc.setFont('helvetica', 'normal').setFontSize(9);
            doc.text(
                `Updated ${formattedUpdatedDate} by ${reconciliation.User?.firstName} ${reconciliation.User?.lastName.charAt(0)}`,
                14,
                lastYcoordinates + 5
            );

            let header = [
                ['Payment Method', 'Expected', 'Counted', 'Difference']
            ];
            let body = [];

            reconciliation.tillLines.forEach((line) => {
                if (line.type !== 'directDeposit' && line.type !== 'account') {
                    let difference =
                        (line.actual ? line.actual : 0) - line.expected;
                    body.push([
                        line.label,
                        line.expected,
                        line.actual ? line.actual : 0,
                        difference
                    ]);
                }
            });

            InvoiceTable(doc, lastYcoordinates + 8, header, body);

            lastYcoordinates = (doc as any).lastAutoTable.finalY + 10;

            // If this is the first item then get the size of each till reconciliation section
            if (i === 0) {
                sizeOfSection = lastYcoordinates - 60;
            }

            if (
                lastYcoordinates + sizeOfSection > 300 &&
                i < tillReconciliation.length - 1
            ) {
                pageCount = pageCount + 1;
                doc.addPage();
                doc.setPage(pageCount);

                await InvoiceHeader(
                    doc,
                    parseInt(localStorage.getItem('SiteId')),
                    'Till Reconciliation Report',
                    formattedDate,
                    false,
                    false,
                    false
                );

                lastYcoordinates = 60;
            }
        }

        //  Open the pdf
        window.open(doc.output('bloburl'));
    }
};

export default PDFTillReconciliationReport;
