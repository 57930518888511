// LOGIC
import CalculateMargin from '../../../global/logic/CalculateMargin';
import { ToFixed } from '../../../global/logic/Formatters';

const AddPart = (
    selectedPart,
    supplierData,
    serviceDetails,
    setServiceDetails
) => {
    if (supplierData == null) {
        // The unit and stock data sent from the part search component
        let unitData = selectedPart.unitData;
        let stockData = selectedPart.stockData;

        let currentParts = [...serviceDetails.addedCosts];
        // Calculate the new row id
        // If there is no orderlines simply set it to 0
        // Otherwise set it to the last id in the orderlines plus 1
        let rowId: number;
        if (currentParts.length > 0) {
            rowId = currentParts.at(-1)['id'] + 1;
        } else {
            rowId = 0;
        }

        // If the part has a cost price average, then we will use it as our base cost
        // Otherwise, we will use cost price daily as the backup
        let costPrice = stockData.costPriceAverage
            ? parseFloat(stockData.costPriceAverage)
            : unitData.costPriceDaily;

        let newRow = {
            id: rowId,
            UnitId: unitData.id,
            partNumber: unitData.partNumber,
            name: unitData.name,
            SOH: stockData.qtyOnHand,
            RES: stockData.qtyReserved,
            incGst: true,
            quantity: '1',
            priceRRP: unitData.priceRRP,
            costPriceAverage: stockData.costPriceAverage,
            costPriceDaily: unitData.costPriceDaily,
            pricePaid: null,
            totalPrice: null,
            totalCost: null,
            margin: null,
            costPerLitre: null,
            rrpPerLitre: null,
            partType: null,
            isFuel: false
        };

        // If the entered part is a fluid need to calculate the costs per litre
        if (unitData.fluid) {
            newRow.partType = 'fluid';
            newRow.rrpPerLitre =
                parseFloat(unitData.priceRRP) /
                (parseFloat(unitData.volume) / 1000);
            newRow.costPerLitre =
                costPrice / (parseFloat(unitData.volume) / 1000);
            newRow.pricePaid = ToFixed(newRow.rrpPerLitre) / 1.1;
            newRow.totalPrice = ToFixed(newRow.rrpPerLitre) / 1.1;
            newRow.totalCost = newRow.costPerLitre;
        } else if (unitData.partType === 'fuel') {
            newRow.isFuel = true;
            newRow.partType = 'fluid';
            newRow.rrpPerLitre = 2.0;
            newRow.costPerLitre = 2.0;
            newRow.pricePaid = 2.0;
            newRow.totalPrice = 2.0;
            newRow.totalCost = 2.0;
        } else {
            newRow.partType = 'part';
            newRow.pricePaid = ToFixed(newRow.priceRRP / 1.1);
            newRow.totalPrice = ToFixed(newRow.priceRRP / 1.1);
            newRow.totalCost = costPrice;
        }

        newRow.margin = CalculateMargin(newRow.pricePaid, newRow.totalCost);

        currentParts.push(newRow);

        setServiceDetails({ ...serviceDetails, addedCosts: currentParts });
    } else {
        // The unit and stock data sent from the part search component
        let unitData = selectedPart.unitData;
        let stockData = selectedPart.stockData;

        let currentParts = [...serviceDetails.addedCosts];

        let rrp = supplierData?.RRP ? supplierData.RRP : unitData.priceRRP;
        let cpd = supplierData?.CPD
            ? supplierData.CPD
            : unitData.costPriceDaily;

        // Calculate the new row id
        // If there is no orderlines simply set it to 0
        // Otherwise set it to the last id in the orderlines plus 1
        let rowId: number;
        if (currentParts.length > 0) {
            rowId = currentParts.at(-1)['id'] + 1;
        } else {
            rowId = 0;
        }

        // If the part has a cost price average, then we will use it as our base cost
        // Otherwise, we will use cost price daily as the backup
        let costPrice = stockData.costPriceAverage
            ? parseFloat(stockData.costPriceAverage)
            : cpd;

        let newRow = {
            id: rowId,
            UnitId: unitData.id,
            partNumber: unitData.partNumber,
            name: unitData.name,
            SOH: stockData.qtyOnHand,
            RES: stockData.qtyReserved,
            incGst: true,
            quantity: '1',
            priceRRP: rrp,
            costPriceAverage: stockData.costPriceAverage,
            costPriceDaily: cpd,
            pricePaid: null,
            totalPrice: null,
            totalCost: null,
            margin: null,
            costPerLitre: null,
            rrpPerLitre: null,
            partType: null,
            isFuel: false
        };

        // If the entered part is a fluid need to calculate the costs per litre
        if (unitData.fluid) {
            newRow.partType = 'fluid';
            newRow.rrpPerLitre = rrp / (parseFloat(unitData.volume) / 1000);
            newRow.costPerLitre =
                costPrice / (parseFloat(unitData.volume) / 1000);
            newRow.pricePaid = ToFixed(newRow.rrpPerLitre) / 1.1;
            newRow.totalPrice = ToFixed(newRow.rrpPerLitre) / 1.1;
            newRow.totalCost = newRow.costPerLitre;
        } else if (unitData.partType === 'fuel') {
            newRow.isFuel = true;
            newRow.partType = 'fluid';
            newRow.rrpPerLitre = 2.0;
            newRow.costPerLitre = 2.0;
            newRow.pricePaid = 2.0;
            newRow.totalPrice = 2.0;
            newRow.totalCost = 2.0;
        } else {
            newRow.partType = 'part';
            newRow.pricePaid = ToFixed(newRow.priceRRP / 1.1);
            newRow.totalPrice = ToFixed(newRow.priceRRP / 1.1);
            newRow.totalCost = costPrice;
        }

        newRow.margin = CalculateMargin(newRow.pricePaid, newRow.totalCost);

        currentParts.push(newRow);

        setServiceDetails({ ...serviceDetails, addedCosts: currentParts });
    }
};

export default AddPart;
