import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TableRow,
    TextField,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import UpdateOrderlineStock from '../../logic/Dashboard/UpdateOrderlineStock';
import HandleCreateBackorders from '../../logic/Dashboard/HandleCreateBackorders';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
interface BackordersDialogContentProps {
    selectedOrder: GroupedOnlineOrder;
    setSelectedOrder: Dispatch<SetStateAction<GroupedOnlineOrder>>;
    setBackordersDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
    handleUpdateAllStatesOrderlines: (
        newOrderLines,
        OrderId: any,
        moveOrder: boolean
    ) => void;
    handleCloseBackorderDialog: () => void;
}
const BackordersDialogContent = ({
    handleCloseBackorderDialog,
    handleUpdateAllStatesOrderlines,
    showSnackbar,
    selectedOrder,
    setSelectedOrder,
    setBackordersDialogOpen
}: BackordersDialogContentProps) => {
    const [moveOrder, setMoveOrder] = useState<boolean>(true);

    const columns = [
        { id: 0, label: 'Part Number', width: 400 },
        { id: 1, label: 'Qty Bought', width: 200 },
        {
            id: 2,
            label: `Qty At ${selectedOrder?.oraOrder?.Site?.name}`,
            width: 200
        },
        { id: 3, label: 'Qty Backordering', width: 200 }
    ];

    useEffect(() => {
        UpdateOrderlineStock(selectedOrder, setSelectedOrder);
        // eslint-disable-next-line
    }, []);

    const handleUpdateBackorderQuantity = (newValue: string, index: number) => {
        let currentOrderLines = [...selectedOrder?.oraOrder?.orderLines];
        currentOrderLines[index].backorderQuantity = newValue;

        let currentOraOrder = selectedOrder.oraOrder;
        currentOraOrder.orderLines = currentOrderLines;
        setSelectedOrder({ ...selectedOrder, oraOrder: currentOraOrder });
    };

    return (
        <>
            <DialogTitle>Create Backorders for Order</DialogTitle>
            <DialogContent>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={moveOrder}
                            onChange={(e) => setMoveOrder(e.target.checked)}
                        />
                    }
                    label="Move order to On Backorder"
                />
                <br />
                <DataTable columns={columns}>
                    {selectedOrder?.oraOrder?.orderLines?.map((line, index) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/inventory/view/${line.UnitId}`,
                                        '_blank'
                                    )
                                }
                            >
                                {line.partNumber}
                            </DataCellColoured>
                            <DataCell>{line.quantity}</DataCell>
                            <DataCell>{line.SOH}</DataCell>
                            <DataCell>
                                <TextField
                                    fullWidth
                                    type="number"
                                    size="small"
                                    value={line.backorderQuantity}
                                    onChange={(e) =>
                                        handleUpdateBackorderQuantity(
                                            e.target.value,
                                            index
                                        )
                                    }
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseBackorderDialog()}>
                    Cancel
                </Button>
                <Button
                    onClick={() =>
                        HandleCreateBackorders(
                            selectedOrder,
                            moveOrder,
                            handleUpdateAllStatesOrderlines,
                            setBackordersDialogOpen,
                            showSnackbar
                        )
                    }
                    variant="contained"
                >
                    Place Backorders
                </Button>
            </DialogActions>
        </>
    );
};

export default BackordersDialogContent;
