import { DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import CreateCategory from '../../logic/CreateCategory';

const AddCategoryDialogContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [name, setName] = useState<string>('');
    const [parent, setParent] = useState<string>('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            fullWidth
                            label="New Category Parent Name"
                            value={parent}
                            onChange={(e) => {
                                setParent(e.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            size="small"
                            fullWidth
                            label="New Category Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    disabled={name.length === 0}
                    onClick={() =>
                        CreateCategory(
                            name,
                            parent,
                            setButtonLoading,
                            showSnackbar
                        )
                    }
                >
                    Submit New Category
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default AddCategoryDialogContent;
