// IMPORTS
import { Dispatch, SetStateAction, useContext } from 'react';
import {
    TextField,
    IconButton,
    Grid,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    TableRow
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// COMPONENTS
import SiteSelector from '../../../global/SiteSelector';
import DataTable from '../../../global/tableComponents/DataTable';
import Autocomplete from '../../../global/Autocomplete';
import Paper from '../../../global/Paper';
import DataCell from '../../../global/tableComponents/DataCell';
// LOGIC
import HandleFreightChange from '../logic/HandleFreightChange';
import RemoveVehicle from '../logic/RemoveVehicle';
// INTERFACES
import {
    BodyType,
    DriveType,
    FuelType,
    Make,
    Model,
    Series,
    Vehicle
} from '../../../global/interfaces/VehicleSaleInterface';
import { Creditor, Site } from '../../../global/interfaces/GeneralInterface';
import TotalValues from '../logic/TotalValues';
import { SiteContext } from '../../../../main/Main';

interface AddVehicleTableProps {
    specificLists: {
        Make: Make[];
        Model: Model[];
        Series: Series[];
        FuelType: FuelType[];
        Creditors: Creditor[];
        DriveType: DriveType[];
        BodyType: BodyType[];
    };
    setAddVehicleOpen: Dispatch<SetStateAction<boolean>>;
    listOfVehicles: Vehicle[];
    setListOfVehicles: Dispatch<SetStateAction<Vehicle[]>>;
    selectedCreditor: Creditor;
    setSelectedCreditor: Dispatch<SetStateAction<Creditor>>;
    selectedSite: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
    gstMethod: string;
    updateGstMethod: (value: string) => void;
}

const AddVehicleTable = ({
    specificLists,
    setAddVehicleOpen,
    listOfVehicles,
    setListOfVehicles,
    selectedCreditor,
    setSelectedCreditor,
    selectedSite,
    setSelectedSite,
    gstMethod,
    updateGstMethod
}: AddVehicleTableProps) => {
    const siteData = useContext<Site>(SiteContext);
    var columns =
        siteData.vehicleTypeSold === 'Motorcycles'
            ? [
                  { id: 0, label: 'Vehicle', width: 300 },
                  { id: 1, label: 'Year', width: 100 },
                  { id: 2, label: 'VIN', width: 100 },
                  { id: 3, label: 'Base Cost', width: 100 },
                  { id: 4, label: 'GST', width: 100 },
                  { id: 5, label: 'Freight', width: 100 },
                  { id: 6, label: '', width: 50 }
              ]
            : [
                  { id: 0, label: 'Vehicle', width: 300 },
                  { id: 1, label: 'Year', width: 100 },
                  { id: 3, label: 'Base Cost', width: 100 },
                  { id: 4, label: 'GST', width: 100 },
                  { id: 5, label: 'Freight', width: 100 },
                  { id: 6, label: '', width: 50 }
              ];

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Autocomplete
                        size="small"
                        isDisabled={listOfVehicles.length > 0 ? true : false}
                        isRequired={true}
                        options={specificLists?.Creditors}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Creditor"
                        selectedValue={selectedCreditor}
                        handleSelectedValueChange={(nextValue) =>
                            setSelectedCreditor(nextValue)
                        }
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SiteSelector
                        size="small"
                        label="Site"
                        variant="outlined"
                        disabled={false}
                        selectedSite={selectedSite}
                        setSelectedSite={setSelectedSite}
                        fullWidth={true}
                        viewPartUI={false}
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            value={gstMethod}
                            onChange={(e) => updateGstMethod(e.target.value)}
                        >
                            <FormControlLabel
                                value="Including"
                                control={<Radio color="primary" />}
                                label="INC. GST"
                            />
                            <FormControlLabel
                                value="Excluding"
                                control={<Radio color="primary" />}
                                label="EX. GST"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <DataTable columns={columns}>
                        {listOfVehicles.map((vehicle) => (
                            <TableRow key={vehicle.id}>
                                <DataCell>
                                    {vehicle?.Make?.name} {vehicle?.Model?.name}{' '}
                                    {vehicle?.Series?.name}
                                </DataCell>
                                <DataCell>
                                    {vehicle.year.format('YYYY')}
                                </DataCell>
                                {siteData.vehicleTypeSold === 'Motorcycles' ? (
                                    <DataCell>
                                        {vehicle.vin
                                            ? `...${vehicle.vin.slice(-6)}`
                                            : null}
                                    </DataCell>
                                ) : null}
                                <DataCell>
                                    {CurrencyFormatter(vehicle.costPrice)}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        TotalValues('gst', listOfVehicles)
                                    )}
                                </DataCell>
                                <DataCell>
                                    <TextField
                                        type="number"
                                        variant="standard"
                                        value={vehicle.freight}
                                        onChange={(e) =>
                                            HandleFreightChange(
                                                e.target.value,
                                                vehicle,
                                                listOfVehicles,
                                                setListOfVehicles,
                                                gstMethod
                                            )
                                        }
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                </DataCell>
                                <DataCell>
                                    <IconButton
                                        onClick={(e) =>
                                            RemoveVehicle(
                                                vehicle,
                                                listOfVehicles,
                                                setListOfVehicles
                                            )
                                        }
                                    >
                                        <Close />
                                    </IconButton>
                                </DataCell>
                            </TableRow>
                        ))}
                        {selectedCreditor?.id ? (
                            <TableRow>
                                <DataCell colSpan={11}>
                                    <IconButton
                                        onClick={() => setAddVehicleOpen(true)}
                                    >
                                        <Add />
                                    </IconButton>
                                </DataCell>
                            </TableRow>
                        ) : null}
                    </DataTable>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default AddVehicleTable;
