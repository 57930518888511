import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const HandleUpdateManualTransaction = (
    id: number,
    manualTransaction,
    selectedType: string,
    showSnackbar: showSnackbar
) => {
    if (selectedType === 'account' && !manualTransaction.AccountId) {
        showSnackbar(
            'No account selected.',
            'Please select an account to continue.',
            'error'
        );
        return;
    } else if (selectedType === 'vehicle' && !manualTransaction.VehicleId) {
        showSnackbar(
            'No vehicle selected.',
            'Please select a vehicle to continue.',
            'error'
        );
        return;
    }

    if (!manualTransaction.reference) {
        showSnackbar(
            'No reference entered.',
            'Please enter a reference to continue.',
            'error'
        );
        return;
    } else if (!manualTransaction.dateReceived) {
        showSnackbar(
            'No date received selected.',
            'Please select a date received to continue.',
            'error'
        );
        return;
    } else if (
        (!manualTransaction.debit && !manualTransaction.credit) ||
        (parseFloat(manualTransaction.debit) === 0 &&
            parseFloat(manualTransaction.credit) === 0)
    ) {
        showSnackbar(
            'No debit or credit amount entered.',
            'Please enter a debit or credit amount to continue.',
            'error'
        );
        return;
    }

    api.put(`updateManualTransaction/${id}`, manualTransaction).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Manual transaction updated successfully.',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact the IT department.',
                    'error'
                );
            } else {
                showSnackbar(
                    res.data.split('|')[0],
                    res.data.split('|')[1],
                    'error'
                );
            }
        }
    });
};

export default HandleUpdateManualTransaction;
