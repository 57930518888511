import {
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const StatutoryWarrantyForm = ({ control, watch }) => {
    return (
        <Grid container spacing={2} margin={'10px'}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Statutory Warranty
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    This motor vehicle (tick whichever applies):
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="statutoryWarrantyOption"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="hasWarranty"
                                control={<Radio />}
                                label="HAS a Statutory Warranty"
                            />
                            <FormControlLabel
                                value="noWarranty"
                                control={<Radio />}
                                label="DOES NOT have a Statutory Warranty"
                            />
                        </RadioGroup>
                    )}
                />
            </Grid>
            {/* //below fields only display when hasWarranty is selected */}
            {watch('statutoryWarrantyOption') === 'hasWarranty' && (
                <>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Name of warrantor (licensee who owns the vehicle at
                            the time of sale):
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...control.register('warrantorName')}
                            variant="outlined"
                            fullWidth
                            label="Name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Business address of warrantor (if different to
                            licensee):
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...control.register('warrantorAddress')}
                            variant="outlined"
                            fullWidth
                            label="Address"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            {...control.register('warrantorSuburb')}
                            variant="outlined"
                            fullWidth
                            label="Suburb"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            {...control.register('warrantorState')}
                            variant="outlined"
                            fullWidth
                            label="State"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            {...control.register('warrantorPostcode')}
                            variant="outlined"
                            fullWidth
                            label="Postcode"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Warrantor contact detail:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...control.register('warrantorPhone')}
                            variant="outlined"
                            fullWidth
                            label="Phone"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            {...control.register('warrantorEmail')}
                            variant="outlined"
                            fullWidth
                            label="Email"
                        />
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <Typography variant="body1">
                    The buyer took possession of the vehicle on:
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        name="buyerPossessionDate"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Date"
                                value={dayjs(field.value)}
                                onChange={field.onChange}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false
                                    }
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
            </Grid>
        </Grid>
    );
};

export default StatutoryWarrantyForm;
