import { Typography, Grid } from '@mui/material';
import Paper from '../../../global/Paper';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import DataChangeIndicator from '../../../global/dashboard/DataChangeIndicator';
import { FinancialOverviewProps } from '../../../global/dashboard/interface';

const PartSaleRevenueOverview = ({
    selectedMonthFinalisedRevenue,
    selectedMonthFinalisedCost,
    comparedMonthFinalisedCost,
    comparedMonthFinalisedRevenue,
    compareStartDate,
    compareEndDate
}: FinancialOverviewProps) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Total Revenue</Typography>
                        <Typography variant="h5">
                            {CurrencyFormatter(selectedMonthFinalisedRevenue)}
                        </Typography>
                        {
                            <DataChangeIndicator
                                currentValue={selectedMonthFinalisedRevenue}
                                previousValue={comparedMonthFinalisedRevenue}
                                category="revenue"
                                dataType="currency"
                                compareStartDate={compareStartDate}
                                compareEndDate={compareEndDate}
                            />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Total Cost</Typography>
                        <Typography variant="h5">
                            {CurrencyFormatter(selectedMonthFinalisedCost)}
                        </Typography>
                        {
                            <DataChangeIndicator
                                currentValue={selectedMonthFinalisedCost}
                                previousValue={comparedMonthFinalisedCost}
                                category="cost"
                                dataType="currency"
                                compareStartDate={compareStartDate}
                                compareEndDate={compareEndDate}
                            />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Gross Profit</Typography>
                        <Typography variant="h5">
                            {CurrencyFormatter(
                                selectedMonthFinalisedRevenue -
                                    selectedMonthFinalisedCost
                            )}
                        </Typography>
                        {
                            <DataChangeIndicator
                                currentValue={
                                    selectedMonthFinalisedRevenue -
                                    selectedMonthFinalisedCost
                                }
                                previousValue={
                                    comparedMonthFinalisedRevenue -
                                    comparedMonthFinalisedCost
                                }
                                category="gross profit"
                                dataType="currency"
                                compareStartDate={compareStartDate}
                                compareEndDate={compareEndDate}
                            />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Average Margin</Typography>
                        <Typography variant="h5">
                            {(
                                ((selectedMonthFinalisedRevenue -
                                    selectedMonthFinalisedCost) /
                                    selectedMonthFinalisedRevenue) *
                                100
                            ).toFixed(2)}
                            %
                        </Typography>
                        {
                            <DataChangeIndicator
                                currentValue={
                                    ((selectedMonthFinalisedRevenue -
                                        selectedMonthFinalisedCost) /
                                        selectedMonthFinalisedRevenue) *
                                    100
                                }
                                previousValue={
                                    ((comparedMonthFinalisedRevenue -
                                        comparedMonthFinalisedCost) /
                                        comparedMonthFinalisedRevenue) *
                                    100
                                }
                                category="average margin"
                                dataType="number"
                                compareStartDate={compareStartDate}
                                compareEndDate={compareEndDate}
                            />
                        }
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default PartSaleRevenueOverview;
