// IMPORTS
import { ReactNode } from 'react';
import Box from '@mui/material/Box';

interface PageWrapperProps {
    children: ReactNode;
    textAlign?: string;
    notVisible?: boolean;
}

// Sets the page up for content by aligning the text and giving it a margin
// This must be used in all parent components or the content will not be displayed correctly.
// Can be used in child components if you want a containered effect
const PageWrapper = ({ children, textAlign, notVisible }: PageWrapperProps) => {
    return notVisible ? (
        <>{children}</>
    ) : (
        <Box
            sx={{
                width: '100%',
                textAlign: textAlign ? textAlign : 'left',
                margin: '1rem'
            }}
        >
            {children}
        </Box>
    );
};

export default PageWrapper;
