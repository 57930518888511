// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';
// LOGIC
import GetSetting from '../../../../global/databaseLogic/GetSetting';
// INTERFACES
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';
import { Settings, Site } from '../../../../global/interfaces/GeneralInterface';
interface VehicleChargesProps {
    readOnly: boolean;
    saleDetails: VehicleSale;
    setSaleDetails: Dispatch<SetStateAction<VehicleSale>>;
}

const VehicleCharges = ({
    readOnly,
    saleDetails,
    setSaleDetails
}: VehicleChargesProps) => {
    const [settings, setSettings] = useState<{
        site: Site;
        settings: Settings;
    }>({ site: null, settings: null });

    useEffect(() => {
        GetSetting(parseInt(localStorage.getItem('SiteId')), setSettings);
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={saleDetails.saleStatus === 'Finalised' ? 6 : 12}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        type="number"
                        label="Vehicle Price"
                        InputLabelProps={{ shrink: true }}
                        value={saleDetails.vehicleSalePrice}
                        onChange={(e) =>
                            setSaleDetails({
                                ...saleDetails,
                                vehicleSalePrice: e.target.value
                            })
                        }
                    />
                </Grid>
                {saleDetails.saleStatus === 'Finalised' ? (
                    <Grid item xs={6}>
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            size="small"
                            type="number"
                            label="Rebate Price (INC. GST)"
                            InputLabelProps={{ shrink: true }}
                            value={saleDetails.rebatePrice}
                            onChange={(e) =>
                                setSaleDetails({
                                    ...saleDetails,
                                    rebatePrice: e.target.value
                                })
                            }
                        />
                    </Grid>
                ) : null}
                {settings?.site?.vehicleTypeSold === 'Motorcycles' ? (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                disabled={readOnly}
                                fullWidth
                                select
                                size="small"
                                label="Warranty Length"
                                InputLabelProps={{ shrink: true }}
                                value={saleDetails.warrantyLength}
                                onChange={(e) => {
                                    if (e.target.value == null) {
                                        setSaleDetails({
                                            ...saleDetails,
                                            warrantyLength: e.target.value,
                                            warrantyPrice: '0'
                                        });
                                    } else {
                                        setSaleDetails({
                                            ...saleDetails,
                                            warrantyLength: e.target.value
                                        });
                                    }
                                }}
                            >
                                {settings.settings?.warrantyData.map((line) => (
                                    <MenuItem value={line.length}>
                                        {line.length}{' '}
                                        {line.length === '1' ? 'Year' : 'Years'}
                                    </MenuItem>
                                ))}
                                <MenuItem value={null}>No Warranty</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                disabled={
                                    !saleDetails.warrantyLength || readOnly
                                }
                                size="small"
                                type="number"
                                label="Warranty Charge"
                                InputLabelProps={{ shrink: true }}
                                value={saleDetails.warrantyPrice}
                                onChange={(e) =>
                                    setSaleDetails({
                                        ...saleDetails,
                                        warrantyPrice: e.target.value
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                disabled={readOnly}
                                fullWidth
                                size="small"
                                type="number"
                                label="DOF"
                                InputLabelProps={{ shrink: true }}
                                value={saleDetails.insurancePrice}
                                onChange={(e) =>
                                    setSaleDetails({
                                        ...saleDetails,
                                        insurancePrice: e.target.value
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                disabled={readOnly}
                                fullWidth
                                size="small"
                                type="number"
                                label="Delivery"
                                InputLabelProps={{ shrink: true }}
                                value={saleDetails.deliveryPrice}
                                onChange={(e) =>
                                    setSaleDetails({
                                        ...saleDetails,
                                        deliveryPrice: e.target.value
                                    })
                                }
                            />
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </>
    );
};

export default VehicleCharges;
