import { Table } from "../interfaces/StockOrderInterface"

const CalculateStockOrderTotalCost = (tables: Table[]) => {
    var total = 0;

    tables.forEach(table => {
        table.orderLines.forEach(item => {
            total += item.rowTotal
        })
    })

    return total;
}

export default CalculateStockOrderTotalCost;