import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Ctp,
    VehicleSale
} from '../../../../global/interfaces/VehicleSaleInterface';
import GetNSWCtp from '../../../../global/databaseLogic/GetNSWCtp';
import DataTable from '../../../../global/tableComponents/DataTable';
import { TableRow } from '@mui/material';
import DataCell from '../../../../global/tableComponents/DataCell';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';

const NSWCtpDialog = ({
    length,
    selectedType,
    saleDetails,
    setSaleDetails,
    setCtpDialogOpen
}: {
    length: string;
    selectedType: string;
    saleDetails: VehicleSale;
    setSaleDetails: Dispatch<SetStateAction<VehicleSale>>;
    setCtpDialogOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const [ctpData, setCtpData] = useState<Ctp[]>([]);

    const columns = [
        { id: 0, label: 'Class', width: 50 },
        { id: 1, label: 'Vehicle Type', width: 50 },
        { id: 2, label: 'Year', width: 50 },
        { id: 3, label: 'Type', width: 50 },
        { id: 4, label: 'Metro', width: 50 },
        { id: 5, label: 'Outer Metro', width: 50 },
        { id: 6, label: 'Newcastle / Central Coast', width: 50 },
        { id: 7, label: 'Wollongong', width: 50 },
        { id: 8, label: 'Country', width: 50 }
    ];

    useEffect(() => {
        GetNSWCtp(setCtpData);
    }, []);

    return (
        <DataTable columns={columns}>
            {ctpData
                .filter((x) => parseInt(x.length) === parseInt(length))
                .map((line) => (
                    <>
                        <TableRow>
                            <DataCell rowSpan={3}>{line.class}</DataCell>
                            <DataCell rowSpan={3}>{line.className}</DataCell>
                        </TableRow>

                        {Object.keys(line.prices[0]).map((key) => (
                            <>
                                <TableRow
                                    selected={
                                        (parseInt(
                                            saleDetails.Vehicle.engineSize
                                        ) <= 225 &&
                                            line.class === '10d' &&
                                            ((parseInt(
                                                saleDetails.Vehicle.year.format(
                                                    'YYYY'
                                                )
                                            ) >= 2016 &&
                                                key === '2016 - 2022') ||
                                                (parseInt(
                                                    saleDetails.Vehicle.year.format(
                                                        'YYYY'
                                                    )
                                                ) <= 2015 &&
                                                    key === '2015 & prior'))) ||
                                        (parseInt(
                                            saleDetails.Vehicle.engineSize
                                        ) >= 226 &&
                                            parseInt(
                                                saleDetails.Vehicle.engineSize
                                            ) < 725 &&
                                            line.class === '10e' &&
                                            ((parseInt(
                                                saleDetails.Vehicle.year.format(
                                                    'YYYY'
                                                )
                                            ) >= 2016 &&
                                                key === '2016 - 2022') ||
                                                (parseInt(
                                                    saleDetails.Vehicle.year.format(
                                                        'YYYY'
                                                    )
                                                ) <= 2015 &&
                                                    key === '2015 & prior'))) ||
                                        (parseInt(
                                            saleDetails.Vehicle.engineSize
                                        ) >= 726 &&
                                            parseInt(
                                                saleDetails.Vehicle.engineSize
                                            ) < 1125 &&
                                            line.class === '10f' &&
                                            ((parseInt(
                                                saleDetails.Vehicle.year.format(
                                                    'YYYY'
                                                )
                                            ) >= 2016 &&
                                                key === '2016 - 2022') ||
                                                (parseInt(
                                                    saleDetails.Vehicle.year.format(
                                                        'YYYY'
                                                    )
                                                ) <= 2015 &&
                                                    key === '2015 & prior'))) ||
                                        (parseInt(
                                            saleDetails.Vehicle.engineSize
                                        ) >= 1126 &&
                                            parseInt(
                                                saleDetails.Vehicle.engineSize
                                            ) < 1325 &&
                                            line.class === '10g' &&
                                            ((parseInt(
                                                saleDetails.Vehicle.year.format(
                                                    'YYYY'
                                                )
                                            ) >= 2016 &&
                                                key === '2016 - 2022') ||
                                                (parseInt(
                                                    saleDetails.Vehicle.year.format(
                                                        'YYYY'
                                                    )
                                                ) <= 2015 &&
                                                    key === '2015 & prior'))) ||
                                        (parseInt(
                                            saleDetails.Vehicle.engineSize
                                        ) > 1325 &&
                                            line.class === '10h' &&
                                            ((parseInt(
                                                saleDetails.Vehicle.year.format(
                                                    'YYYY'
                                                )
                                            ) >= 2016 &&
                                                key === '2016 - 2022') ||
                                                (parseInt(
                                                    saleDetails.Vehicle.year.format(
                                                        'YYYY'
                                                    )
                                                ) <= 2015 &&
                                                    key === '2015 & prior')))
                                    }
                                >
                                    <DataCell>{key}</DataCell>
                                    {Object.keys(line.prices[0][key]).map(
                                        (type) => {
                                            if (
                                                type ===
                                                selectedType.toLowerCase()
                                            ) {
                                                return (
                                                    <>
                                                        <DataCell>
                                                            {type
                                                                .slice(0, 1)
                                                                .toUpperCase() +
                                                                type.slice(1)}
                                                        </DataCell>
                                                        {Object.keys(
                                                            line.prices[0][key][
                                                                type
                                                            ]
                                                        ).map((innerKey) => {
                                                            return (
                                                                <DataCell
                                                                    handleClick={() => {
                                                                        setSaleDetails(
                                                                            {
                                                                                ...saleDetails,
                                                                                ctpPrice:
                                                                                    line
                                                                                        .prices[0][
                                                                                        key
                                                                                    ][
                                                                                        type
                                                                                    ][
                                                                                        innerKey
                                                                                    ]
                                                                            }
                                                                        );
                                                                        setCtpDialogOpen(
                                                                            false
                                                                        );
                                                                    }}
                                                                >
                                                                    {CurrencyFormatter(
                                                                        line
                                                                            .prices[0][
                                                                            key
                                                                        ][type][
                                                                            innerKey
                                                                        ]
                                                                    )}
                                                                </DataCell>
                                                            );
                                                        })}
                                                    </>
                                                );
                                            } else {
                                                return null;
                                            }
                                        }
                                    )}
                                </TableRow>
                            </>
                        ))}
                    </>
                ))}
        </DataTable>
    );
};

export default NSWCtpDialog;
