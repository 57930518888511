// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Collapse,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// INTERFACES
import { Site, Specific } from '../../../global/interfaces/GeneralInterface';
import { ShippingMethod } from '../../../global/interfaces/PartsInterface';

interface SiteProps {
    siteInfo: Site;
    setSiteInfo: Dispatch<SetStateAction<Site>>;
    shippingMethods: ShippingMethod[];
    setShippingMethods: Dispatch<SetStateAction<ShippingMethod[]>>;
    brandList: Specific[];
    setBrandList: Dispatch<SetStateAction<Specific[]>>;
    siteBrands: Specific[];
    setSiteBrands: Dispatch<SetStateAction<Specific[]>>;
    readOnly: boolean;
}

const OnlineOrders = ({
    siteInfo,
    setSiteInfo,
    shippingMethods,
    setShippingMethods,
    brandList,
    setBrandList,
    siteBrands,
    setSiteBrands,
    readOnly
}: SiteProps) => {
    const EditBrandList = (value: any) => {
        let tempBrands = [...brandList];
        let newSiteBrands = [];
        value.forEach((brand, index) => {
            newSiteBrands.push(brand);
            tempBrands = tempBrands.filter((x) => x.name !== brand);
        });

        setBrandList(tempBrands);
        // setSiteBrands(value);
        setSiteInfo({ ...siteInfo, siteBrands: newSiteBrands });
    };

    const AddShippingMethod = (shippingMethod: string) => {
        let tempShippingMethods = [...shippingMethods];
        let id =
            tempShippingMethods.length > 0
                ? tempShippingMethods[tempShippingMethods.length - 1].id + 1
                : 1;
        tempShippingMethods.push({
            Site: siteInfo,
            SiteId: siteInfo.id,
            id: id,
            name: shippingMethod
        });
        setShippingMethods(tempShippingMethods);
    };

    return (
        <Paper textAlign="center">
            <Grid container spacing={2}>
                <FormGroup>
                    <FormControlLabel
                        label={
                            <Typography variant="h6">Online Orders</Typography>
                        }
                        labelPlacement="start"
                        control={
                            <Switch
                                disabled={readOnly}
                                checked={siteInfo?.handlesOnlineOrders}
                                onChange={(e) =>
                                    setSiteInfo({
                                        ...siteInfo,
                                        handlesOnlineOrders: e.target.checked
                                    })
                                }
                            />
                        }
                        sx={{
                            paddingTop: '5%'
                        }}
                    />
                </FormGroup>
            </Grid>
            <Divider /> <br />
            <Collapse sx={{ paddingTop: 1 }} in={siteInfo?.handlesOnlineOrders}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow={true}
                            enterDelay={500}
                            placement="top"
                            title="Designate which site is to be prioritised when packing online orders"
                        >
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                disabled={readOnly}
                                label={'Site Packing Priority'}
                                value={
                                    siteInfo?.sitePackingPriority != null
                                        ? siteInfo?.sitePackingPriority
                                        : ''
                                }
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setSiteInfo({
                                            ...siteInfo,
                                            sitePackingPriority: null
                                        });
                                    } else {
                                        setSiteInfo({
                                            ...siteInfo,
                                            sitePackingPriority: parseInt(
                                                e.target.value
                                            )
                                        });
                                    }
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow={true}
                            enterDelay={500}
                            placement="top"
                            title="Corresponding warehouse ID on your Ecommerce webstore"
                        >
                            <TextField
                                fullWidth
                                size="small"
                                type="number"
                                variant="outlined"
                                disabled={readOnly}
                                InputLabelProps={{ shrink: true }}
                                label={'Associated Online Warehouse'}
                                value={
                                    siteInfo?.netoWarehouseId != null
                                        ? siteInfo?.netoWarehouseId
                                        : ''
                                }
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setSiteInfo({
                                            ...siteInfo,
                                            netoWarehouseId: null
                                        });
                                    } else {
                                        setSiteInfo({
                                            ...siteInfo,
                                            netoWarehouseId: parseInt(
                                                e.target.value
                                            )
                                        });
                                    }
                                }}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
                <br></br>
                <Tooltip
                    arrow={true}
                    title="Set specific brands to be packed at this site"
                >
                    <div>
                        <AutocompleteMultiple
                            isDisabled={readOnly}
                            options={brandList || []}
                            notClearable={true}
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            textfieldLabel="Prioritised Brands"
                            selectedValues={
                                siteInfo?.siteBrands?.length
                                    ? siteInfo.siteBrands
                                    : []
                            }
                            handleSelectedValueChange={(value, event) =>
                                EditBrandList(value)
                            }
                            handleInputValueChange={() => null}
                        />
                    </div>
                </Tooltip>
                <br></br>
                <AutocompleteMultiple
                    options={[]}
                    isDisabled={readOnly}
                    isFreeSolo={true}
                    notClearable={true}
                    useTwoOptionLabels={false}
                    primaryOptionLabel={'name'}
                    textfieldLabel="Shipping Methods"
                    selectedValues={shippingMethods}
                    handleSelectedValueChange={(value, event) => {
                        if (event.type === 'keydown' && event.key === 'Enter') {
                            AddShippingMethod(value[value.length - 1]);
                        } else if (event.type === 'click') {
                            setShippingMethods(value);
                        }
                    }}
                    handleInputValueChange={() => null}
                />
            </Collapse>
        </Paper>
    );
};

export default OnlineOrders;
