import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    calculateDifference,
    calculateDifferenceMultipleStockOrder
} from './CalculateValues';

const CheckInvoiceData = (
    invoiceData,
    isMultipleInvoice,
    showSnackbar: showSnackbar,
    setInvoiceError
) => {
    if (isMultipleInvoice) {
        // Check if there is a document reference
        if (
            invoiceData.documentReference === '' ||
            invoiceData.documentReference == null
        ) {
            showSnackbar(
                'Please provide a valid document reference.',
                '',
                'error'
            );
            setInvoiceError('documentReference');
            return true;
        }

        // Check if there is an invoice date
        else if (
            invoiceData.invoiceDate === '' ||
            invoiceData.invoiceDate == null
        ) {
            showSnackbar('Please provide a valid document date.', '', 'error');
            setInvoiceError('documentDate');
            return true;
        }

        // Check if there is a document total
        else if (
            invoiceData.documentTotal === '' ||
            invoiceData.documentTotal == null ||
            invoiceData.documentTotal.includes(',')
        ) {
            showSnackbar(
                'Please provide a valid document total.',
                'Document total can not be empty or contains commas.',
                'error'
            );
            setInvoiceError('documentTotal');
            return true;
        }

        // Check if there is a freight cost
        else if (invoiceData.freight === '' || invoiceData.freight == null) {
            showSnackbar('Please provide a valid freight amount.', '', 'error');
            setInvoiceError('freight');
            return true;
        }

        // Check that the document total and orderline + freight total balances
        else if (
            Math.abs(
                calculateDifferenceMultipleStockOrder(
                    invoiceData.orderLines,
                    invoiceData.gstMethod,
                    invoiceData.freight,
                    invoiceData.documentTotal
                )
            ) > 0.01
        ) {
            showSnackbar(
                'Document Total and Entered Total do not match.',
                'Please double check your invoice.',
                'error'
            );
            setInvoiceError('balance');
            return true;
        }
    } else {
        // Check that there are orderLines
        if (invoiceData.orderLines.length === 0) {
            showSnackbar('Please provide at least one part.', '', 'error');
            return true;
        }

        // Check if there is a document reference
        if (
            invoiceData.documentReference === '' ||
            invoiceData.documentReference == null
        ) {
            showSnackbar(
                'Please provide a valid document reference.',
                '',
                'error'
            );
            setInvoiceError('documentReference');
            return true;
        }

        // Check if there is an invoice date
        else if (
            invoiceData.invoiceDate === '' ||
            invoiceData.invoiceDate == null
        ) {
            showSnackbar('Please provide a valid document date.', '', 'error');
            setInvoiceError('documentDate');
            return true;
        }

        // Check if there is a document total
        else if (
            invoiceData.documentTotal === '' ||
            invoiceData.documentTotal == null ||
            invoiceData.documentTotal.includes(',')
        ) {
            showSnackbar(
                'Please provide a valid document total.',

                'Document total can not be empty or contains commas.',
                'error'
            );
            setInvoiceError('documentTotal');
            return true;
        }

        // Check if there is a freight cost
        else if (invoiceData.freight === '' || invoiceData.freight == null) {
            showSnackbar('Please provide a valid freight amount.', '', 'error');
            setInvoiceError('freight');
            return true;
        }

        // Check that the document total and orderline + freight total balances
        else if (
            Math.abs(
                calculateDifference(
                    invoiceData.orderLines,
                    invoiceData.gstMethod,
                    invoiceData.freight,
                    invoiceData.documentTotal
                )
            ) > 0.01
        ) {
            showSnackbar(
                'Document Total and Entered Total do not match.',
                'Please double check your invoice.',
                'error'
            );
            setInvoiceError('balance');
            return true;
        }
    }
    return false;
};

export default CheckInvoiceData;
