// REACT IMPORTS
import {
    useState,
    useEffect,
    ReactNode,
    Dispatch,
    SetStateAction
} from 'react';
import {
    Grid,
    TextField,
    IconButton,
    CircularProgress,
    Pagination,
    Dialog,
    DialogTitle,
    DialogContent,
    Badge,
    Tooltip
} from '@mui/material';
import { FilterList, Close, FilterListOff } from '@mui/icons-material';
// COMPONENTS
import Paper from '../Paper';
// LOGIC
import api from '../../../../api';
import HandleClearFilter from '../logic/HandleClearFilter';
import { Filter } from '../interfaces/FilterInterface';
import dayjs from 'dayjs';

interface TableSearchProps {
    searchTitle: string; // The Textfield label
    showFilter: boolean; // Whether the show the filter button or not
    showPagination: boolean; // Whether to show the pagination or not
    showPaper: boolean; // Whether to put a paper background or not
    sort: string[] | []; // The sorting array
    children?: ReactNode; // The component containing the filter fields
    handleRequestCreate: () => any; // The function that combines all the filter values into a string for the api query
    route: string; // Backend route
    setResultsList: Dispatch<SetStateAction<any[]>>; // Array setter
    isFilterActive?: () => any; // Are there any filters selected? Controls the badge on the filter icon
    customLimit?: number;
    filter?: Filter;
    clearedFilter?: Filter;
    setFilter?: Dispatch<SetStateAction<Filter>>;
    disableSearch?: boolean;
    customComponent?: any; // Custom component to overwrite pagination (e.g. a button)
    parentSearchString?: string; // In case the parent component can prefill the searchString
    setTotal?: Dispatch<SetStateAction<number>>; // In case the parent component needs a total (data found in the response)
    setNumberOfItems?: Dispatch<SetStateAction<number>>; // In case the parent component needs to know how many items there is in the pagination
    cypressLabel?: string;
    setIsEmpty?: Dispatch<SetStateAction<boolean>>; // In case we need to know if the table in the DB is completely empty
    setEmptyField?: Dispatch<SetStateAction<string>>;
}

const TableSearch = ({
    disableSearch,
    customLimit,
    searchTitle,
    sort,
    clearedFilter,
    setFilter,
    showFilter,
    isFilterActive,
    showPagination,
    showPaper,
    filter,
    handleRequestCreate,
    route,
    setResultsList,
    children,
    customComponent,
    parentSearchString,
    setTotal,
    setNumberOfItems,
    cypressLabel,
    setIsEmpty,
    setEmptyField
}: TableSearchProps) => {
    const [searchString, setSearchString] = useState(parentSearchString ?? '');
    const [resultsLoading, setResultsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    let limit = customLimit ? customLimit : 20;

    // Funs the actual customer search with the search string and any filters sent from parent component
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleApiRoute();
        }, 1000);

        return () => clearTimeout(delayDebounceFn);

        // eslint-disable-next-line
    }, [filter, currentPage, sort]);

    const handleApiRoute = (resetPage?: boolean) => {
        setResultsLoading(true);
        let searchStringQuery = searchString
            ? `&searchString=${searchString}`
            : '';
        let filterStringQuery = handleRequestCreate();
        let apiString = `${route}?limit=${limit}&currentPage=${currentPage}&sort=${sort}${searchStringQuery}${filterStringQuery}`;

        api.get(apiString).then((res) => {
            if (res.data.count != null && res.data.count > 0)
                setTotalPages(Math.ceil(res.data.count / limit));
            setResultsLoading(false);
            if (
                route === 'vehicleSearchNextGen' ||
                route === 'manufacturerVehicleSearch' ||
                route === 'vehicleSaleSearchNextGen'
            ) {
                let currentResults = JSON.parse(JSON.stringify(res.data.rows));
                for (let row of currentResults) {
                    row.year = dayjs(row.year);
                }
                setResultsList(currentResults);
                if (setIsEmpty && res.data.empty) {
                    setIsEmpty(res.data.empty);
                    setEmptyField(res.data.emptyField);
                }
            } else if (route === 'serviceSearchNextGen') {
                let currentResults = JSON.parse(JSON.stringify(res.data.rows));
                for (let row of currentResults) {
                    if (row.CustomerVehicle) {
                        row.CustomerVehicle.year = dayjs(
                            row.CustomerVehicle.year
                        );
                    } else if (row.Vehicle) {
                        row.Vehicle.year = dayjs(row.Vehicle.year);
                    }
                }
                setResultsList(currentResults);
            } else if (route === 'esignDocumentSearch') {
                setResultsList(res.data.rows);
            } else {
                setResultsList(res.data.rows);
                if (setIsEmpty && res.data.empty) {
                    setIsEmpty(res.data.empty);
                }
            }
            setDisabled(false);
            if (resetPage) {
                setCurrentPage(0);
            }
            if (setTotal) {
                setTotal(res.data.total);
            }
            if (setNumberOfItems) {
                setNumberOfItems(res.data.count);
            }
        });
    };

    // Changes the page both forward and backwards
    // Don't ask how -1 goes forward and backwards, I am not sure, it just does
    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value - 1);
    };

    /**
     * FilterComponent
     * Conditional rendering of the filter button.
     * @author Sienna
     * @returns {JSX} the filter component
     */
    const FilterComponent = () => {
        // Show a loading circle when the query is running
        if (resultsLoading) {
            return <CircularProgress />;
        } else if (showFilter) {
            return (
                <IconButton
                    sx={{ verticalAlign: 'center' }}
                    onClick={() => setFilterDialogOpen(true)}
                >
                    <Badge
                        invisible={!isFilterActive()}
                        color="primary"
                        variant="dot"
                    >
                        <FilterList />
                    </Badge>
                </IconButton>
            );
        }
        return null;
    };

    /**
     * PaginationComponent
     * Show pagination
     * @author Sienna
     * @returns {JSX} pagination
     */
    const PaginationComponent = () => {
        return (
            showPagination && (
                <Pagination
                    disabled={resultsLoading || disableSearch}
                    count={totalPages}
                    page={currentPage + 1}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{ display: 'flex', justifyContent: 'right' }}
                />
            )
        );
    };

    // The actual component including the search bar, filter and pagination
    const ComponentContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        data-cy={cypressLabel ? cypressLabel : ''}
                        disabled={disableSearch}
                        size="small"
                        label={searchTitle}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ startAdornment: <Search color="disabled" /> }}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !disabled) {
                                setDisabled(true);
                                handleApiRoute(true);
                            }
                        }}
                    />
                </Grid>
                {resultsLoading || showFilter ? (
                    <Grid item xs={1}>
                        {FilterComponent()}
                    </Grid>
                ) : null}
                <Grid item xs={filter || resultsLoading ? 7 : 8}>
                    {customComponent ? customComponent : PaginationComponent()}
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            {showPaper ? (
                <Paper>{ComponentContent()}</Paper>
            ) : (
                ComponentContent()
            )}
            <br />
            <Dialog
                open={filterDialogOpen}
                onClose={() => setFilterDialogOpen(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    Filter & Sort Results
                    {/* Check if the isFilterActive function is provided before checking its value */}
                    {isFilterActive ? (
                        isFilterActive() ? (
                            <Tooltip
                                placement="right"
                                title="Clear all filters"
                            >
                                <IconButton
                                    onClick={() =>
                                        HandleClearFilter({
                                            setFilter: setFilter,
                                            clearedFilter: clearedFilter
                                        })
                                    }
                                >
                                    <FilterListOff />
                                </IconButton>
                            </Tooltip>
                        ) : null
                    ) : null}
                    <IconButton
                        onClick={() => setFilterDialogOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
            </Dialog>
        </>
    );
};

export default TableSearch;
