// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Grid,
    TableRow,
    Typography,
    IconButton,
    Chip,
    Tooltip,
    Pagination
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
// COMPONENTS
import Paper from '../../global/Paper';
import CustomerBackorderTableFilter from './components/CustomerBackorderTableFilter';
import PageWrapper from '../../global/PageWrapper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import TableSearch from '../../global/tableComponents/TableSearch';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import GetCurrentSite from '../../global/logic/GetCurrentSite';
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import HandleRemoveBackorder from './logic/HandleRemoveBackorder';
import ImportBackordersToTable from './logic/ImportBackordersToTable';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { Site } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Table } from '../stockOrder/interfaces/StockOrderInterface';

const clearedFilter = {
    customerBackorderFilter: { status: [], orderType: [], site: [] }
};

interface CustomerBackorderTableProps {
    tables: Table[];
    setTables: Dispatch<SetStateAction<Table[]>>;
    importedSupplier: number[];
    setImportedSupplier: Dispatch<SetStateAction<number[]>>;
    showSnackbar: showSnackbar;
}

const sort = ['id', 'DESC'];
const CustomerBackorderTable = (props: CustomerBackorderTableProps) => {
    const [filter, setFilter] = useState<Filter>({
        customerBackorderFilter: {
            status: [{ value: 'OPEN', label: 'OPEN' }],
            orderType: [],
            site: [{ id: parseInt(localStorage.getItem('SiteId')), name: '' }]
        }
    });
    const [resultsList, setResultsList] = useState([]);
    const [siteDetails, setSiteDetails] = useState<Site>(null);

    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [paginatedRows, setPaginatedRows] = useState([]);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const leftColumns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Name' },
        { id: 2, label: 'Orders' }
    ];

    const rightColumns = [
        { id: 0, label: 'Sale ID', width: 80 },
        { id: 1, label: 'Part Number', width: 250 },
        { id: 2, label: 'Quantity', width: 80 },
        { id: 3, label: 'Location', width: 150 },
        { id: 4, label: 'Type', width: 100 },
        { id: 5, label: 'Status', width: 100 },
        { id: 6, label: 'Stock Order', width: 250 },
        { id: 7, label: '' }
    ];

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        let newPage = value - 1;
        let offset = newPage * 20;

        setCurrentPage(value - 1);
        setPaginatedRows(
            resultsList[selectedIndex].rows.slice(offset, offset + 20)
        );
    };

    // Select the first supplier in the left table
    useEffect(() => {
        if (Object.keys(resultsList).length > 0) {
            setSelectedIndex(parseInt(Object.keys(resultsList)[0]));
            setPaginatedRows(resultsList[selectedIndex].rows.slice(0, 20));
        }
        // eslint-disable-next-line
    }, [resultsList]);

    // Get the site details
    useEffect(() => {
        GetCurrentSite(
            parseInt(localStorage.getItem('SiteId')),
            setSiteDetails
        );
    }, []);

    // When a new debtor is selected change the paginated rows
    useEffect(() => {
        setPaginatedRows(resultsList[selectedIndex]?.rows.slice(0, 20));
        // eslint-disable-next-line
    }, [selectedIndex]);

    // Preset the filter with some defaults
    useEffect(() => {
        if (siteDetails != null) {
            setFilter({
                customerBackorderFilter: {
                    status: [{ value: 'OPEN', label: 'OPEN' }],
                    orderType: [],
                    site: [{ id: siteDetails.id, name: siteDetails.name }]
                }
            });
        }
    }, [siteDetails]);

    const handleRequestCreate = () => {
        let status =
            filter.customerBackorderFilter.status.length > 0
                ? ConvertArrayToFilter({
                      array: filter.customerBackorderFilter.status,
                      filterStart: '&status=',
                      selector: 'value'
                  })
                : '';
        let site =
            filter.customerBackorderFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.customerBackorderFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let type =
            filter.customerBackorderFilter.orderType.length > 0
                ? ConvertArrayToFilter({
                      array: filter.customerBackorderFilter.orderType,
                      filterStart: '&orderType=',
                      selector: 'value'
                  })
                : '';

        var apiString = `${status}${site}${type}`;
        return apiString;
    };

    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.customerBackorderFilter.status.length > 0 ||
            filter.customerBackorderFilter.orderType.length > 0 ||
            filter.customerBackorderFilter.site.length > 0
        ) {
            isActive = true;
        }

        return isActive;
    };

    // Filter the results list to not show supplier that have already been imported to the stock order
    const handleResultsList = (data) => {
        setResultsList(
            data.filter((x) => !props.importedSupplier.includes(x.Supplier.id))
        );
    };

    // Get email from a  supplier
    const getEmail = (supplier) => {
        if (supplier?.id) {
            for (let i = 0; i < supplier.contactDetails['email'].length; i++) {
                if (
                    supplier.contactDetails['email'][i].reference === 'PRIMARY'
                ) {
                    return supplier.contactDetails['email'][i].value;
                }
            }
        }
    };

    let pageCount =
        resultsList[selectedIndex]?.rows.length <= 20
            ? 1
            : Math.ceil(resultsList[selectedIndex]?.rows.length / 20);

    return (
        <PageWrapper>
            <TableSearch
                searchTitle="Search Orders"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                sort={sort}
                clearedFilter={clearedFilter}
                handleRequestCreate={handleRequestCreate}
                route="customerBackorderSearchNextGen"
                setResultsList={handleResultsList}
                isFilterActive={isFilterActive}
            >
                <CustomerBackorderTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Grid container spacing={2}>
                {/* LEFT TABLE: LIST OF SUPPLIERS */}
                <Grid item xs={4}>
                    <Paper>
                        <DataTable columns={leftColumns}>
                            {resultsList.map((supplierBackorder, index) => (
                                <TableRow selected={selectedIndex === index}>
                                    <DataCellColoured
                                        handleClick={() =>
                                            setSelectedIndex(index)
                                        }
                                    >
                                        {supplierBackorder.Supplier.id}
                                    </DataCellColoured>
                                    <DataCell>
                                        {supplierBackorder.Supplier.name}
                                    </DataCell>
                                    <DataCell>
                                        {supplierBackorder.rows.length}
                                    </DataCell>
                                </TableRow>
                            ))}
                        </DataTable>
                    </Paper>
                </Grid>

                {/* RIGHT TABLE: LIST OF BACKORDERS OF CURRENT SELECTED SUPPLIER */}
                <Grid item xs={8}>
                    <Paper>
                        {selectedIndex >= 0 ? (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">
                                            {
                                                resultsList[selectedIndex]
                                                    ?.Supplier.name
                                            }
                                            <Tooltip
                                                title="Edit Supplier"
                                                placement="right"
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        window.open(
                                                            `/viewCreditor/${resultsList[selectedIndex].Supplier.id}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    <Edit fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                        <br />
                                        <Typography variant="subtitle2">
                                            {getEmail(
                                                resultsList[selectedIndex]
                                                    ?.Supplier
                                            )}
                                        </Typography>
                                    </Grid>

                                    {/* Only display button if we are viewing OPEN backorders */}
                                    {filter.customerBackorderFilter.status
                                        .length === 1 &&
                                    filter.customerBackorderFilter.status[0]
                                        .value === 'OPEN' ? (
                                        <>
                                            <Grid item xs={4}>
                                                <LoadingButton
                                                    loading={buttonLoading}
                                                    variant="contained"
                                                    onClick={() =>
                                                        ImportBackordersToTable(
                                                            resultsList[
                                                                selectedIndex
                                                            ].Supplier.id,
                                                            parseInt(
                                                                localStorage.getItem(
                                                                    'SiteId'
                                                                )
                                                            ),
                                                            props.tables,
                                                            props.setTables,
                                                            props.importedSupplier,
                                                            props.setImportedSupplier,
                                                            resultsList,
                                                            setResultsList,
                                                            setSelectedIndex,
                                                            setButtonLoading
                                                        )
                                                    }
                                                >
                                                    Move Backorders to Stock
                                                    Order
                                                </LoadingButton>
                                            </Grid>
                                        </>
                                    ) : null}

                                    {/* Grid get size 6 if the button above is not rendered */}
                                    <Grid
                                        item
                                        xs={
                                            filter.customerBackorderFilter
                                                .status.length === 1 &&
                                            filter.customerBackorderFilter
                                                .status[0].value === 'OPEN'
                                                ? 2
                                                : 6
                                        }
                                    >
                                        <Pagination
                                            count={pageCount}
                                            page={currentPage + 1}
                                            onChange={handlePageChange}
                                            color="primary"
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'right'
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <DataTable columns={rightColumns}>
                                    {paginatedRows?.map((row) => (
                                        <TableRow key={row.id}>
                                            <DataCellColoured
                                                handleClick={() =>
                                                    window.open(
                                                        `/inventory/viewSale/${row.OrderId}`,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                {row.OrderId}
                                            </DataCellColoured>
                                            <DataCell>
                                                {row.partNumber}
                                            </DataCell>
                                            <DataCell>
                                                {row.backorderQuantity}
                                            </DataCell>
                                            <DataCell>
                                                {row?.Site?.name}
                                            </DataCell>
                                            <DataCell>
                                                {row.OrderType?.toUpperCase()}
                                            </DataCell>
                                            <DataCell>
                                                <Chip
                                                    label={row.status}
                                                    variant="outlined"
                                                    color={
                                                        row.status === 'OPEN'
                                                            ? 'success'
                                                            : 'warning'
                                                    }
                                                />
                                            </DataCell>
                                            <DataCell>
                                                {row.StockOrderId ? (
                                                    <Typography
                                                        variant="body2"
                                                        noWrap
                                                    >
                                                        Stock Order ID:
                                                        <Typography
                                                            variant="body2"
                                                            color="primary"
                                                            display={'inline'}
                                                            sx={{
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() =>
                                                                window.open(
                                                                    `inventory/stockInvoicePO/${row.StockOrderId}`,
                                                                    '_blank'
                                                                )
                                                            }
                                                        >
                                                            &nbsp;
                                                            {row.StockOrderId}
                                                        </Typography>
                                                    </Typography>
                                                ) : null}
                                            </DataCell>
                                            <DataCell>
                                                {row.currentBackorderQuantity <=
                                                    0 && !row.StockOrderId ? (
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) =>
                                                            HandleRemoveBackorder(
                                                                row.id,
                                                                selectedIndex,
                                                                resultsList,
                                                                setResultsList,
                                                                props.showSnackbar
                                                            )
                                                        }
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                ) : null}
                                            </DataCell>
                                        </TableRow>
                                    ))}
                                </DataTable>
                            </>
                        ) : null}
                    </Paper>
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default withSnackbar(CustomerBackorderTable);
