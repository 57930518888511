import { Typography, Button, Grid, useTheme } from '@mui/material';
import Paper from '../../../global/Paper';
import PartSaleBreakdownDrawer from './PartSaleBreakdownDrawer';
import { useState } from 'react';
import DataChangeIndicator from '../../../global/dashboard/DataChangeIndicator';
import { Order } from '../../../global/interfaces/PartsInterface';

const PartSalesCounter = ({
    partProformaOrderDataSelectedMonth,
    partSalesOrderDataSelectedMonth,
    partCreditOrderDataSelectedMonth,
    partProformaOrderDataComparedMonth,
    partSalesOrderDataComparedMonth,
    partCreditOrderDataComparedMonth
}) => {
    const currentMode = useTheme().palette.mode;

    // click on job count cell to open drawer
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [drawerTitle, setDrawerTitle] = useState<string>('');
    const [drawerData, setDrawerData] = useState<Order[]>([]);

    const handleOpenPartSaleBreakdownDrawer = (title, data) => {
        setDrawerOpen(true);
        setDrawerTitle(title);
        setDrawerData(data);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">New Proforma Order</Typography>
                        <Button
                            onClick={() =>
                                handleOpenPartSaleBreakdownDrawer(
                                    'Proforma Order',
                                    partProformaOrderDataSelectedMonth
                                )
                            }
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {partProformaOrderDataSelectedMonth.length}
                            </Typography>
                        </Button>
                        {
                            <DataChangeIndicator
                                currentValue={
                                    partProformaOrderDataSelectedMonth.length
                                }
                                previousValue={
                                    partProformaOrderDataComparedMonth.length
                                }
                                category="proforma order count"
                                dataType="number"
                            />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">New Sale Order</Typography>
                        <Button
                            onClick={() =>
                                handleOpenPartSaleBreakdownDrawer(
                                    'Sales Order',
                                    partSalesOrderDataSelectedMonth
                                )
                            }
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {partSalesOrderDataSelectedMonth.length}
                            </Typography>
                        </Button>
                        {
                            <DataChangeIndicator
                                currentValue={
                                    partSalesOrderDataSelectedMonth.length
                                }
                                previousValue={
                                    partSalesOrderDataComparedMonth.length
                                }
                                category="sales order count"
                                dataType="number"
                            />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">New Credited Order</Typography>
                        <Button
                            onClick={() =>
                                handleOpenPartSaleBreakdownDrawer(
                                    'Credit Order',
                                    partCreditOrderDataSelectedMonth
                                )
                            }
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {partCreditOrderDataSelectedMonth.length}
                            </Typography>
                        </Button>
                        {
                            <DataChangeIndicator
                                currentValue={
                                    partCreditOrderDataSelectedMonth.length
                                }
                                previousValue={
                                    partCreditOrderDataComparedMonth.length
                                }
                                category="credit order count"
                                dataType="number"
                            />
                        }
                    </Paper>
                </Grid>
                <PartSaleBreakdownDrawer
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    drawerTitle={drawerTitle}
                    drawerData={drawerData}
                />
            </Grid>
        </>
    );
};

export default PartSalesCounter;
