import { Typography } from '@mui/material';
import PageWrapper from '../global/PageWrapper';
import { withSnackbar } from '../global/WrappingSnackbar';
import CreateSignTabContent from './components/CreateSignTabContent';
import Tabs from '../global/Tabs';
import PendingSignTabContent from './components/PendingSignTabContent';
import FinalisedSignTabContent from './components/FinalisedSignTagContent';
import { showSnackbar } from '../global/interfaces/GlobalInterface';

const EsignPage = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const createSignTab = {
        id: 0,
        title: 'New',
        content: <CreateSignTabContent showSnackbar={showSnackbar} />
    };

    const pendingSignTab = {
        id: 1,
        title: 'Pending',
        content: <PendingSignTabContent showSnackbar={showSnackbar} />
    };

    const finaliseTab = {
        id: 2,
        title: 'Finalised',
        content: <FinalisedSignTabContent showSnackbar={showSnackbar} />
    };

    const tabContent = [createSignTab, pendingSignTab, finaliseTab];

    return (
        <PageWrapper>
            <Typography variant="h4">Esign</Typography>
            <br />
            <Tabs tabContent={tabContent} />
        </PageWrapper>
    );
};

export default withSnackbar(EsignPage);
