// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Stock, Unit } from '../../../global/interfaces/PartsInterface';
interface StocktakeDetails {
    countedParts: any[];
    uncountedParts: any[];
    movedBins: any[];
    notes: string;
    review: boolean;
}
/* 
    When a part is added using the part search component, this is the function that is called
    to add it to the stocktake counted parts list.
*/
const HandleAddPart = (
    unitDetails: { unitData: Unit; stockData: Stock },
    stocktakeDetails: StocktakeDetails,
    setStocktakeDetails: Dispatch<SetStateAction<StocktakeDetails>>
) => {
    // The part search components sends back the unit and stock data
    let unitData = unitDetails.unitData;
    let stockData = unitDetails.stockData;

    // Get the current lines
    let currentCountedLines = stocktakeDetails.countedParts;
    let currentUncountedLines = stocktakeDetails.uncountedParts;
    let currentMovedBinLines = stocktakeDetails.movedBins;

    // Check to see if the part has already been counted
    let filteredCountedLines =
        currentCountedLines.length > 0
            ? currentCountedLines.filter((x) => x.id === unitData.id)
            : [];

    // If it has already been counted, we can just add one to the counted item in the object
    if (filteredCountedLines.length > 0) {
        // To do this add one to the count in the filtered object
        // Filter out the line in the original object ot remove it
        // Then unshift it back in to move it to the top
        filteredCountedLines[0].counted =
            parseFloat(filteredCountedLines[0].counted) + 1;
        currentCountedLines = currentCountedLines.filter(
            (x) => x.id !== unitData.id
        );
        currentCountedLines.unshift(filteredCountedLines[0]);
    }

    // If it has not been counted, we need to create a new line and put in the counted list
    else {
        let newStocktakeLine = {
            id: unitData.id,
            partNumber: unitData.partNumber,
            name: unitData.name,
            expected: stockData.qtyOnHand + stockData.qtyReserved,
            counted: 1,
            averageCostPrice: stockData.costPriceAverage,
            proformas: unitData.proformas,
            binLocationSwitch: true,
            removed: false
        };
        currentCountedLines.unshift(newStocktakeLine);

        // Check to see if the part is already in the bin location and is on the uncounted list
        let filteredUncountedLines = currentUncountedLines.filter(
            (x) => x.id === unitData.id
        );

        // If it on the uncounted list we need to remove it as it has now been counted
        if (filteredUncountedLines.length > 0) {
            currentUncountedLines = currentUncountedLines.filter(
                (x) => x.id !== unitData.id
            );
        }

        // If it is not on the uncounted list we need to put it into the moved bin list
        else {
            currentMovedBinLines.unshift(newStocktakeLine);
        }
    }

    // Reset all the states with the adjusted lists
    setStocktakeDetails({
        ...stocktakeDetails,
        countedParts: currentCountedLines,
        uncountedParts: currentUncountedLines,
        movedBins: currentMovedBinLines
    });
};

export default HandleAddPart;
