import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}

const GetNetoOrders = (
    setMissingParts: Dispatch<SetStateAction<GroupedOnlineOrder[]>>,
    setMissingPartsDrawerOpen: Dispatch<SetStateAction<boolean>>,
    setOrdersLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setOrdersLoading(true);
    api.get(
        `/retrieveNetoOrders?SiteId=${localStorage.getItem('SiteId')}`
    ).then((res) => {
        if (res.data.missingInfoLines.length > 0) {
            setMissingParts(res.data.missingInfoLines);
            setMissingPartsDrawerOpen(true);
        } else if (res.data.noOrders) {
            showSnackbar(
                'No new orders to pull at the moment.',
                'Please double check Maropost and contact IT if something is wrong.',
                'warning'
            );
            setOrdersLoading(false);
        } else {
            showSnackbar(
                'New Orders have been pulled.',
                `${res.data.newOrdersCounter} New Orders have been created - ${res.data.onHoldOrdersCounter} On Hold Orders have been created`
            );
            window.location.reload();
        }
    });
};

export default GetNetoOrders;
