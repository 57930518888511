// IMPORTS
import { Dispatch, SetStateAction, useContext } from 'react';
import { Grid } from '@mui/material';
// COMPONENTS
import FinanceDetails from './FinanceDetails';
import ManufacturerMotorcyclesDetails from './ManufacturerMotorcyclesDetails';
import ManufacturerBicyclesDetails from './ManufacturerBicyclesDetails';
import PricingDetails from './PricingDetails';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { Specific } from '../../../../global/interfaces/GeneralInterface';
import { Site } from '../../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { SiteContext } from '../../../../../main/Main';
import MotorcyclesVehicleDetails from './MotorcyclesVehicleDetails';
import BicyclesVehicleDetails from './BicyclesVehicleDetails';

interface SpecificsList {
    makes?: Specific[];
    models?: Specific[];
    series?: Specific[];
    aspirations?: Specific[];
    fuelTypes?: Specific[];
    bodyTypes?: Specific[];
    driveTypes?: Specific[];
    sites?: Site[];
}

interface DetailsProps {
    id: number;
    vehicle: Vehicle;
    setVehicle: Dispatch<SetStateAction<Vehicle>>;
    specifics: SpecificsList;
    readOnly: boolean;
    showSnackbar: showSnackbar;
}

const Details = ({
    id,
    vehicle,
    setVehicle,
    specifics,
    readOnly,
    showSnackbar
}: DetailsProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                        <ManufacturerMotorcyclesDetails
                            vehicle={vehicle}
                            setVehicle={setVehicle}
                            specifics={specifics}
                            readOnly={readOnly}
                        />
                    ) : (
                        <ManufacturerBicyclesDetails
                            vehicle={vehicle}
                            setVehicle={setVehicle}
                            specifics={specifics}
                            readOnly={readOnly}
                        />
                    )}
                </Grid>
                <Grid item xs={4}>
                    <PricingDetails
                        id={id}
                        vehicle={vehicle}
                        setVehicle={setVehicle}
                        specifics={specifics}
                        readOnly={readOnly}
                    />
                    <br />
                    {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                        <FinanceDetails
                            vehicle={vehicle}
                            setVehicle={setVehicle}
                            readOnly={readOnly}
                        />
                    ) : null}
                </Grid>
                <Grid item xs={8}>
                    {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                        <MotorcyclesVehicleDetails
                            vehicle={vehicle}
                            setVehicle={setVehicle}
                            specifics={specifics}
                            readOnly={readOnly}
                            showSnackbar={showSnackbar}
                        />
                    ) : (
                        <BicyclesVehicleDetails
                            vehicle={vehicle}
                            setVehicle={setVehicle}
                            specifics={specifics}
                            readOnly={readOnly}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
export default Details;
