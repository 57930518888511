import api from "../../../../../api"

const GetVehicleRebateInvoice = (id, setInvoiceObject, setInvoiceLines) => {
    api.get('rebateInvoice/' + id).then(res => {
        let invoiceData = res.data.invoiceData;
        if (invoiceData) {
            invoiceData.Creditor = res.data.invoiceData?.Supplier;
            invoiceData.documentTotal = res.data.invoiceData.documentTotal;
            invoiceData.documentDate = res.data.invoiceData.dateReceived;
            invoiceData.dueDate = res.data.invoiceData.dateDue;
            setInvoiceObject(invoiceData);
            setInvoiceLines(res.data.invoiceLines);
        }
       
    })
}

export default GetVehicleRebateInvoice