import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';

const CheckVehicleVin = (
  vin: string,
  setResponseCode: Dispatch<SetStateAction<number>>
) => {
  api.get(`checkVinExists/${vin}`).then((res) => {
    setResponseCode(res.status);
  });
};

export default CheckVehicleVin;
