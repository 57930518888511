import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField
} from '@mui/material';
// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
// LOGIC
import GetAllOnlineSites from '../../../global/databaseLogic/GetAllOnlineSites';
import MoveOrder from '../../logic/Dashboard/MoveOrder';
// INTERFACES
import { Site } from '../../../global/interfaces/GeneralInterface';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
interface MoveDialogContentProps {
    selectedOrder: GroupedOnlineOrder;
    showSnackbar: showSnackbar;
    setMoveDialogOpen: Dispatch<SetStateAction<boolean>>;
    handleUpdateAllStatesMovement: (
        newStatus: string,
        newSite: Site,
        OrderId: any
    ) => void;
}
const MoveDialogContent = ({
    handleUpdateAllStatesMovement,
    selectedOrder,
    showSnackbar,
    setMoveDialogOpen
}: MoveDialogContentProps) => {
    const [newSite, setNewSite] = useState<Site>({});
    const [newStatus, setNewStatus] = useState<string>('New');
    const [sites, setSites] = useState<Site[]>([]);

    const statusOptions = [
        { name: 'New' },
        { name: 'On Hold' },
        { name: 'New Backorder' },
        { name: 'Pick' },
        { name: 'Pack' },
        { name: 'Pending Dispatch' },
        { name: 'Dispatched' },
        { name: 'Pending Pickup' },
        { name: 'Collected' },
        { name: 'Cancelled' }
    ];

    useEffect(() => {
        GetAllOnlineSites(setSites);
        setNewSite(selectedOrder?.oraOrder?.Site);
        setNewStatus(selectedOrder?.netoOrder?.orderStatus);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <DialogTitle>Move Order</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            size="small"
                            options={sites}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Assigned Site"
                            selectedValue={newSite}
                            handleSelectedValueChange={(newValue) =>
                                setNewSite(newValue)
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            label="Order Status"
                            InputLabelProps={{ shrink: true }}
                            value={newStatus}
                            onChange={(e) => setNewStatus(e.target.value)}
                        >
                            {statusOptions.map((option) => (
                                <MenuItem value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setMoveDialogOpen(false)}>Cancel</Button>
                <Button
                    onClick={() =>
                        MoveOrder(
                            selectedOrder,
                            newSite,
                            newStatus,
                            handleUpdateAllStatesMovement,
                            setMoveDialogOpen,
                            showSnackbar
                        )
                    }
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
};

export default MoveDialogContent;
