import {
    Checkbox,
    Grid,
    IconButton,
    Pagination,
    TableRow,
    TextField
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

// COMPONENT
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import Autocomplete from '../../../../global/Autocomplete';

// LOGIC
import GetCategories from '../../../../global/databaseLogic/GetCategories';
import GetParts from '../../../../global/databaseLogic/GetParts';
import AddPartsToCategory from '../../logic/AddPartToCategory';

// INTERFACE
import { Category, Unit } from '../../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const AddPartsToCategoryDrawer = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    // Drawer state
    const [keyword, setKeyword] = useState<string>('');
    const [partsList, setPartsList] = useState<Unit[]>([]);
    const [checkedUnitId, setCheckedUnitId] = useState<number[]>([]);
    const [categoriesList, setCategoriesList] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [page, setPage] = useState<number>(1);
    const rowsPerPage = 20;
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        GetCategories(setCategoriesList);
    }, []);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    // If 'all' is given as unitId, check or uncheck all currently displayed part
    // Otherwise handle single part
    const handleCheckboxClick = (
        e: ChangeEvent<HTMLInputElement>,
        unitId: number | string
    ) => {
        let tempArray = JSON.parse(JSON.stringify(checkedUnitId));
        if (unitId === 'all') {
            if (e.target.checked) {
                for (let row of partsList.slice(
                    (page - 1) * rowsPerPage,
                    page * rowsPerPage
                )) {
                    tempArray.push(row.id);
                }
            } else {
                for (let row of partsList.slice(
                    (page - 1) * rowsPerPage,
                    page * rowsPerPage
                )) {
                    tempArray = tempArray.filter((x) => x !== row.id);
                }
            }
        } else {
            if (e.target.checked) {
                tempArray.push(unitId);
            } else {
                tempArray = tempArray.filter((x) => x !== unitId);
            }
        }

        setCheckedUnitId(tempArray);
    };

    // Check if all currently displayed parts have their checkbox checked and return true if so
    const checkCurrentPageCheckbox = () => {
        if (partsList.length === 0) {
            return false;
        }
        for (let item of partsList.slice(
            (page - 1) * rowsPerPage,
            page * rowsPerPage
        )) {
            if (!checkedUnitId.includes(item.id)) {
                return false;
            }
        }
        return true;
    };

    const dialogColumns = [
        {
            id: 0,
            label: (
                <IconButton>
                    <Checkbox
                        checked={checkCurrentPageCheckbox()}
                        onChange={(e) => {
                            handleCheckboxClick(e, 'all');
                        }}
                    />
                </IconButton>
            )
        },
        { id: 1, label: 'Part Number' },
        { id: 2, label: 'Part Name' }
    ];

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <Autocomplete
                        size="small"
                        options={categoriesList}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Category"
                        selectedValue={selectedCategory}
                        handleSelectedValueChange={(event) => {
                            setSelectedCategory(event);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        fullWidth
                        disabled={checkedUnitId.length === 0}
                        onClick={() =>
                            AddPartsToCategory(
                                selectedCategory.id,
                                checkedUnitId,
                                showSnackbar,
                                setButtonLoading
                            )
                        }
                    >
                        Save
                    </LoadingButton>
                </Grid>
                {selectedCategory?.id ? (
                    <>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Search SKU & Part Name"
                                value={keyword}
                                size="small"
                                onChange={(e) => {
                                    setKeyword(e.target.value);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter')
                                        GetParts(keyword, setPartsList);
                                }}
                            />
                        </Grid>
                        <Grid item xs={8} textAlign="right">
                            <Pagination
                                color="primary"
                                count={
                                    partsList.length <= rowsPerPage
                                        ? 1
                                        : Math.ceil(
                                              partsList.length / rowsPerPage
                                          )
                                }
                                page={page}
                                siblingCount={0}
                                boundaryCount={1}
                                onChange={handleChangePage}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                    marginTop: 0.5
                                }}
                            />
                        </Grid>
                    </>
                ) : null}
            </Grid>

            {selectedCategory?.id ? (
                <DataTable columns={dialogColumns}>
                    {partsList
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((unit) => (
                            <TableRow>
                                <DataCell>
                                    <Checkbox
                                        checked={checkedUnitId.includes(
                                            unit.id
                                        )}
                                        onChange={(e) => {
                                            handleCheckboxClick(e, unit.id);
                                        }}
                                        size="small"
                                    />
                                </DataCell>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            '/inventory/view/' + unit.id,
                                            '_blank'
                                        )
                                    }
                                >
                                    {unit.partNumber}
                                </DataCellColoured>
                                <DataCell>{unit.name}</DataCell>
                            </TableRow>
                        ))}
                </DataTable>
            ) : null}
        </>
    );
};

export default AddPartsToCategoryDrawer;
