import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import {
    VehicleTrustPayment,
    VehicleTrustReceipt
} from '../../../global/interfaces/VehicleSaleInterface';
import dayjs from 'dayjs';

const GetVehicleTrustReceipt = (
    id: string,
    setReceiptDetails: Dispatch<
        SetStateAction<{
            trustReceipt: VehicleTrustReceipt;
            trustPayment: VehicleTrustPayment[];
        }>
    >
) => {
    api.get('vehicleTrustReceipt/' + id).then((res) => {
        let trustPayment = res.data.trustPayment;
        let trustReceipt = res.data.trustReceipt;
        trustReceipt.documentDate = dayjs(trustReceipt.documentDate);
        if (trustReceipt.reconciled) {
            trustReceipt.reconciliationDetails.reconciliationDate = dayjs(trustReceipt.reconciliationDetails.createdAt)
        }

        setReceiptDetails({
            trustReceipt: trustReceipt,
            trustPayment: trustPayment
        });
    });
};

export default GetVehicleTrustReceipt;
