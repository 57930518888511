// COMPONENTS
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import Autocomplete from '../../../global/Autocomplete';
// LOGIC
import GetAllAccounts from '../../../global/databaseLogic/GetAllAccounts';
import GetAllCustomers from '../../../global/databaseLogic/GetAllCustomers';
import GetAllVehiclesNoFilter from '../../../global/databaseLogic/GetAllVehiclesNoFilter';
// INTERFACES
import { Account } from '../../../global/interfaces/AdminInterface';
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { PurchaseOrder } from '../../../global/interfaces/ServiceInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import GetAllPurchaseOrders from '../../../global/databaseLogic/GetAllPurchaseOrders';
// interface InvoiceObject {
//     id?: number;
//     type?: string;
//     documentReference?: string;
//     documentDate?: string;
//     dueDate?: string;
//     GSTtype?: string;
//     documentTotal?: string;
//     Creditor?: Creditor;
//     Site?: Site;
//     Account?: Account;
//     Debtor?: Customer;
//     Vehicle?: Vehicle;
//     PurchaseOrder?: PurchaseOrder;
// }
interface InvoiceEntitySelectorProps {
    invoiceType: string;
    rowId: Number;
    invoiceObject: any;
    setInvoiceObject: Dispatch<SetStateAction<any>>;
    handleChange: (
        newValue: any,
        id: any,
        type: any,
        invoiceDetails: any,
        setInvoiceDetails: any
    ) => void;
}

const InvoiceEntitySelector = ({
    invoiceType,
    rowId,
    invoiceObject,
    setInvoiceObject,
    handleChange
}: InvoiceEntitySelectorProps) => {
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [debtors, setDebtors] = useState<Customer[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [services, setServices] = useState<PurchaseOrder[]>([]);

    // Whenever the invoice type changes get the appropiate fields to fill the dropdowns
    useEffect(() => {
        if (invoiceType === 'Account') {
            GetAllAccounts(setAccounts);
        } else if (invoiceType === 'Debtor') {
            GetAllCustomers(setDebtors, true);
        } else if (invoiceType === 'Vehicle') {
            GetAllVehiclesNoFilter(setVehicles);
        } else if (invoiceType === 'Service') {
            GetAllPurchaseOrders(setServices);
        }
    }, [invoiceType]);

    const getSelectedEntity = () => {
        const orderline = invoiceObject.StockInvoice.orderLines.filter(
            (line) => line.id === rowId
        )[0];
        const selectedEntity = orderline[invoiceType];
        return selectedEntity;
    };

    return (
        <>
            {invoiceType === 'Account' ? (
                <Autocomplete
                    size="small"
                    options={accounts}
                    useTwoOptionLabels={true}
                    primaryOptionLabel="name"
                    secondaryOptionLabel="siteName"
                    textfieldLabel="Account *"
                    selectedValue={invoiceObject.Account || getSelectedEntity()}
                    includeBar={true}
                    handleSelectedValueChange={(newValue) =>
                        handleChange(
                            newValue,
                            rowId,
                            invoiceType,
                            invoiceObject,
                            setInvoiceObject
                        )
                    }
                    handleInputValueChange={() => null}
                />
            ) : null}
            {invoiceType === 'Debtor' ? (
                <Autocomplete
                    size="small"
                    options={debtors}
                    useTwoOptionLabels={true}
                    primaryOptionLabel="fullName"
                    secondaryOptionLabel="id"
                    textfieldLabel="Debtor *"
                    selectedValue={invoiceObject.Debtor || getSelectedEntity()}
                    includeBar={true}
                    handleSelectedValueChange={(newValue) =>
                        handleChange(
                            newValue,
                            rowId,
                            invoiceType,
                            invoiceObject,
                            setInvoiceObject
                        )
                    }
                    handleInputValueChange={() => null}
                />
            ) : null}
            {invoiceType === 'Vehicle' ? (
                <Autocomplete
                    size="small"
                    options={vehicles}
                    useTwoOptionLabels={true}
                    primaryOptionLabel="stockNumber"
                    secondaryOptionLabel="vehicleName"
                    textfieldLabel="Vehicle *"
                    selectedValue={invoiceObject.Vehicle || getSelectedEntity()}
                    includeBar={true}
                    handleSelectedValueChange={(newValue) =>
                        handleChange(
                            newValue,
                            rowId,
                            invoiceType,
                            invoiceObject,
                            setInvoiceObject
                        )
                    }
                    handleInputValueChange={() => null}
                />
            ) : null}
            {invoiceType === 'Service' ? (
                <Autocomplete
                    size="small"
                    options={services}
                    useTwoOptionLabels={true}
                    primaryOptionLabel="id"
                    secondaryOptionLabel="ServiceId"
                    textfieldLabel="Purchase Order *"
                    selectedValue={
                        invoiceObject.PurchaseOrder || getSelectedEntity()
                    }
                    includeBar={true}
                    handleSelectedValueChange={(newValue) =>
                        handleChange(
                            newValue,
                            rowId,
                            invoiceType,
                            invoiceObject,
                            setInvoiceObject
                        )
                    }
                    handleInputValueChange={() => null}
                />
            ) : null}
        </>
    );
};
export default InvoiceEntitySelector;
