// IMPORTS
import { useEffect, useState } from 'react';
import { Chip, Grid, Pagination, TableRow, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import Paper from '../../../global/Paper';
// LOGIC
import GetAllInvoices from '../logic/GetAllInvoices';
import UpdatePaymentDueDate from '../logic/UpdatePaymentDueDate';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
// INTERFACES
import { Invoice } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import Dayjs from 'dayjs';

interface OpenInvoicesProps {
    CreditorId: number;
    showSnackbar: showSnackbar;
}

// Constant variable for rows per page
const rowsPerPage = 10;

const OpenAndClosedInvoices = ({
    CreditorId,
    showSnackbar
}: OpenInvoicesProps) => {
    const [openInvoices, setOpenInvoices] = useState<Invoice[]>([]);
    const [closedInvoices, setClosedInvoices] = useState<Invoice[]>([]);
    const [openInvoicesPage, setOpenInvoicesPage] = useState(1);
    const [closedInvoicesPage, setClosedInvoicesPage] = useState(1);

    const columns = [
        { id: 0, label: 'Invoice ID', width: 100 },
        { id: 1, label: 'Status', width: 50 },
        { id: 2, label: 'Document Reference', width: 200 },
        { id: 3, label: 'Document Date', width: 100 },
        { id: 4, label: 'Document Total', width: 150 },
        { id: 5, label: 'Site', width: 150 },
        { id: 5, label: 'User', width: 200 },
        { id: 6, label: 'Due Date', width: 150 }
    ];

    useEffect(() => {
        GetAllInvoices(CreditorId, 'open', setOpenInvoices);
        GetAllInvoices(CreditorId, 'closed', setClosedInvoices);
    }, [CreditorId]);

    // Handle changing the page
    const handleOpenInvoicesChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setOpenInvoicesPage(value);
    };
    const handleClosedInvoicesChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setClosedInvoicesPage(value);
    };

    // Calculate the amount remaining to pay on an open invoice
    const caluclateRemaining = (invoice) => {
        let documentTotal = getDocumentTotal(invoice);
        let paidAmount = invoice.paymentHistoryTotal;

        let difference = documentTotal - paidAmount;

        return difference;
    };

    const getLink = (invoice) => {
        let url = '';
        if (
            invoice.invoiceType === 'StockInvoice' &&
            invoice?.AssociatedInvoice?.invoiceType === 'Credit'
        ) {
            url = `admin/creditInvoice/${invoice.AssociatedInvoice.id}`;
        } else if (
            invoice.invoiceType === 'StockInvoice' &&
            invoice?.AssociatedInvoice?.invoiceType !== 'Credit'
        ) {
            url = `/inventory/viewInvoice/${invoice.id}`;
        } else if (invoice.invoiceType === 'VehicleInvoice') {
            url = `/inventory/viewInvoice/${invoice.id}`;
        } else if (invoice.invoiceType === 'VehicleRegistration') {
            url = `/inventory/viewInvoice/${invoice.id}`;
        } else if (invoice.invoiceType === 'CreditInvoice') {
            url = `/inventory/viewInvoice/${invoice.id}`;
        } else if (invoice.invoiceType === 'ServiceWarranty') {
            url = `admin/viewServiceWarrantyInvoice/${invoice.AssociatedInvoice.id}`;
        } else if (invoice.invoiceType === 'VehicleRebate') {
            url = `admin/viewRebateInvoice/${invoice.AssociatedInvoice.id}`;
        }
        return url;
    };

    const getSite = (invoice) => {
        let site = '';
        if (invoice.AssociatedInvoice?.Site?.name) {
            site = invoice.AssociatedInvoice?.Site?.name;
        } else if (invoice.Site?.name) {
            site = invoice.Site?.name;
        }
        return site;
    };

    const getDocumentDate = (invoice) => {
        let date = '';
        if (invoice.AssociatedInvoice?.invoiceDate) {
            date = DateFormatter(invoice.AssociatedInvoice?.invoiceDate);
        } else if (invoice.AssociatedInvoice?.dateReceived) {
            date = DateFormatter(invoice.AssociatedInvoice?.dateReceived);
        } else {
            date = DateFormatter(invoice.createdAt);
        }
        return date;
    };

    const getDueDate = (invoice) => {
        return (
            invoice.paymentDueDate || invoice.AssociatedInvoice?.dateDue || ''
        );
    };

    const getDocumentTotal = (invoice) => {
        let total = 0;
        const documentTotal = invoice.AssociatedInvoice?.documentTotal || 0;

        // service warranty, vehicle rebate, and credit invoices are we get payment from the creditor, so we need to show the negative value
        // credit document total in database is already negative, so here only need to check other two
        if (
            invoice.invoiceType === 'ServiceWarranty' ||
            invoice.invoiceType === 'VehicleRebate'
        ) {
            total = -Math.abs(documentTotal);
        } else {
            total = documentTotal;
        }

        return total;
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="h5">Open Invoices</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Pagination
                            color="primary"
                            count={
                                openInvoices.length <= rowsPerPage
                                    ? 1
                                    : Math.ceil(
                                          openInvoices.length / rowsPerPage
                                      )
                            }
                            page={openInvoicesPage}
                            onChange={handleOpenInvoicesChange}
                            sx={{ display: 'flex', justifyContent: 'right' }}
                        />
                    </Grid>
                </Grid>

                <br />
                <DataTable columns={columns}>
                    {openInvoices
                        .slice(
                            (openInvoicesPage - 1) * rowsPerPage,
                            openInvoicesPage * rowsPerPage
                        )
                        .map((invoice) => (
                            <TableRow>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(getLink(invoice), '_blank')
                                    }
                                >
                                    {invoice.id}
                                </DataCellColoured>
                                <DataCell>
                                    <Chip
                                        variant="outlined"
                                        label={invoice.status}
                                        color={
                                            invoice.status === 'Partial'
                                                ? 'warning'
                                                : 'error'
                                        }
                                    />
                                </DataCell>
                                <DataCell>
                                    {
                                        invoice.AssociatedInvoice
                                            ?.documentReference
                                    }
                                </DataCell>
                                <DataCell>{getDocumentDate(invoice)}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        getDocumentTotal(invoice)
                                    )}
                                    <br />
                                    <Typography variant="caption">
                                        Remaining:{' '}
                                        {CurrencyFormatter(
                                            caluclateRemaining(invoice)
                                        )}
                                    </Typography>
                                </DataCell>
                                <DataCell>{getSite(invoice)}</DataCell>
                                <DataCell>
                                    {invoice.User?.firstName}{' '}
                                    {invoice.User?.lastName}
                                </DataCell>
                                <DataCell>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="en-gb"
                                    >
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            value={Dayjs(getDueDate(invoice))}
                                            onChange={(newValue) =>
                                                UpdatePaymentDueDate(
                                                    invoice.id,
                                                    Dayjs(newValue).format(
                                                        'YYYY-MM-DD'
                                                    ),
                                                    showSnackbar
                                                )
                                            }
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: false,
                                                    size: 'small',
                                                    InputLabelProps: {
                                                        shrink: true
                                                    }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </DataCell>
                            </TableRow>
                        ))}
                </DataTable>
            </Paper>
            <br />
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="h5">Closed Invoices</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Pagination
                            color="primary"
                            count={
                                closedInvoices.length <= rowsPerPage
                                    ? 1
                                    : Math.ceil(
                                          closedInvoices.length / rowsPerPage
                                      )
                            }
                            page={closedInvoicesPage}
                            onChange={handleClosedInvoicesChange}
                            sx={{ display: 'flex', justifyContent: 'right' }}
                        />
                    </Grid>
                </Grid>

                <br />
                <DataTable columns={columns}>
                    {closedInvoices
                        .slice(
                            (closedInvoicesPage - 1) * rowsPerPage,
                            closedInvoicesPage * rowsPerPage
                        )
                        .map((invoice) => (
                            <TableRow>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(getLink(invoice), '_blank')
                                    }
                                >
                                    {invoice.id}
                                </DataCellColoured>
                                <DataCell>
                                    <Chip
                                        variant="outlined"
                                        label="Paid"
                                        color="success"
                                    />
                                </DataCell>
                                <DataCell>
                                    {
                                        invoice.AssociatedInvoice
                                            ?.documentReference
                                    }
                                </DataCell>
                                <DataCell>{getDocumentDate(invoice)}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        invoice.AssociatedInvoice?.documentTotal
                                    )}
                                </DataCell>
                                <DataCell>{getSite(invoice)}</DataCell>
                                <DataCell>
                                    {invoice.User?.firstName}{' '}
                                    {invoice.User?.lastName}
                                </DataCell>
                                <DataCell>
                                    {DateFormatter(invoice.paymentDate)}
                                </DataCell>
                            </TableRow>
                        ))}
                </DataTable>
            </Paper>
        </>
    );
};

export default OpenAndClosedInvoices;
