// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    FormGroup,
    FormControlLabel,
    Grid,
    Typography,
    Divider,
    Radio,
    CircularProgress
} from '@mui/material';
// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
// LOGIC
import SearchCustomer from '../logic/SearchCustomer';
import GetDuplicateCustomers from '../logic/GetDuplicateCustomers';
import SubmitMergeDuplicates from '../logic/SubmitMergeDuplicates';
// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface PrepareDuplicatesProps {
    setDuplicateCustomers: Dispatch<
        SetStateAction<{ key: string; mergeId: number; rows: Customer[] }[]>
    >;
    showSnackbar: showSnackbar;
}

const PrepareDuplicates = ({
    setDuplicateCustomers,
    showSnackbar
}: PrepareDuplicatesProps) => {
    const [listOfCustomers, setListOfCustomers] = useState<Customer[]>([]);
    const [customerDelete, setCustomerDelete] = useState<Customer>();
    const [customerDeleteInput, setCustomerDeleteInput] = useState<string>('');
    const [customerKeep, setCustomerKeep] = useState<Customer>();
    const [customerKeepInput, setCustomerKeepInput] = useState<string>('');

    const [duplicateChoice, setDuplicateChoice] = useState<string>('');
    const [generateLoading, setGenerateLoading] = useState<boolean>(false);
    const [specificLoading, setSpecificLoading] = useState<boolean>(false);

    // Get all the customers for the delete customer input
    useEffect(() => {
        SearchCustomer(customerDeleteInput, setListOfCustomers);
    }, [customerDeleteInput]);

    // Get all the customers for the keep customer input
    useEffect(() => {
        SearchCustomer(customerKeepInput, setListOfCustomers);
    }, [customerKeepInput]);

    return (
        <>
            <Grid container spacing={2} textAlign="center">
                <Grid item xs={6}>
                    <Typography variant="body1" align="center">
                        <b>Find customers with the same:</b>
                    </Typography>
                    <FormGroup row sx={{ justifyContent: 'center' }}>
                        <FormControlLabel
                            label="Company Name"
                            control={
                                <Radio
                                    disabled={
                                        generateLoading || specificLoading
                                    }
                                    checked={duplicateChoice === 'company'}
                                    onChange={() =>
                                        setDuplicateChoice('company')
                                    }
                                />
                            }
                        />
                        <FormControlLabel
                            label="Full Name"
                            control={
                                <Radio
                                    disabled={
                                        generateLoading || specificLoading
                                    }
                                    checked={duplicateChoice === 'name'}
                                    onChange={() => setDuplicateChoice('name')}
                                />
                            }
                        />
                    </FormGroup>
                    <FormGroup row sx={{ justifyContent: 'center' }}>
                        <FormControlLabel
                            label="Email"
                            control={
                                <Radio
                                    disabled={
                                        generateLoading || specificLoading
                                    }
                                    checked={duplicateChoice === 'email'}
                                    onChange={() => setDuplicateChoice('email')}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Phone Number"
                            control={
                                <Radio
                                    disabled={
                                        generateLoading || specificLoading
                                    }
                                    checked={duplicateChoice === 'phone'}
                                    onChange={() => setDuplicateChoice('phone')}
                                />
                            }
                        />
                    </FormGroup>
                    <br />
                    {generateLoading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            disabled={specificLoading}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                GetDuplicateCustomers(
                                    duplicateChoice,
                                    setDuplicateCustomers,
                                    setGenerateLoading
                                )
                            }
                        >
                            Generate duplicate list
                        </Button>
                    )}
                </Grid>

                <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />

                <Grid item xs={6}>
                    <Typography variant="body1">
                        <b>Merge Specific Customers:</b>
                    </Typography>
                    <br />
                    <Autocomplete
                        isDisabled={generateLoading || specificLoading}
                        size="small"
                        options={listOfCustomers}
                        useTwoOptionLabels={true}
                        primaryOptionLabel="id"
                        secondaryOptionLabel="fullName"
                        textfieldLabel="Merge Customer"
                        placeholder="Enter customer ID or name"
                        isOpenOnInput={true}
                        selectedValue={customerDelete}
                        inputValue={customerDeleteInput}
                        handleSelectedValueChange={(newValue) =>
                            setCustomerDelete(newValue)
                        }
                        handleInputValueChange={(newValue) =>
                            setCustomerDeleteInput(newValue)
                        }
                    />
                    <br />
                    <Autocomplete
                        isDisabled={generateLoading || specificLoading}
                        size="small"
                        options={listOfCustomers}
                        useTwoOptionLabels={true}
                        primaryOptionLabel="id"
                        secondaryOptionLabel="fullName"
                        textfieldLabel="Into Customer"
                        placeholder="Enter customer ID or name"
                        isOpenOnInput={true}
                        selectedValue={customerKeep}
                        inputValue={customerKeepInput}
                        handleSelectedValueChange={(newValue) =>
                            setCustomerKeep(newValue)
                        }
                        handleInputValueChange={(newValue) =>
                            setCustomerKeepInput(newValue)
                        }
                    />
                    <br />
                    {specificLoading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            disabled={generateLoading}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setSpecificLoading(true);
                                SubmitMergeDuplicates(
                                    showSnackbar,
                                    customerKeep.id,
                                    customerDelete.id
                                );
                            }}
                        >
                            Merge Customers
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default PrepareDuplicates;
