// IMPORTS
import { useState } from 'react';
import { Typography, Button, Grid, useTheme } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
import BookingBreakdownDrawer from './BookingBreakdownDrawer';
import DataChangeIndicator from '../../../global/dashboard/DataChangeIndicator';
// LOGIC
import { countBusinessDays } from '../../../global/dashboard/getMonthDatesAndBusinessDays';
// INTERFACES
import { Service } from '../../../global/interfaces/ServiceInterface';
import { Dayjs } from 'dayjs';

interface ServiceData {
    ServiceId: number;
    TechId: number;
    serviceComplete: string;
    serviceType: string;
    totalCost: number;
    totalSale: number;
    customerName: string;
    vehicleStockNumber: string;
    totalPrice?: number;
    totalPayment?: number;
}

interface BookingCounterParams {
    incompleteServiceData: ServiceData[];
    completeServiceData: ServiceData[];
    finalisedServiceData: ServiceData[];
    notFullyPaidServicesData: ServiceData[];
    comparedMonthCompleteServiceData: ServiceData[];
    comparedMonthFinalisedServiceData: ServiceData[];
    selectedStartDate: Dayjs;
    selectedEndDate: Dayjs;
    compareStartDate: Dayjs;
    compareEndDate: Dayjs;
}

const BookingCounter = ({
    incompleteServiceData,
    completeServiceData,
    finalisedServiceData,
    notFullyPaidServicesData,
    comparedMonthCompleteServiceData,
    comparedMonthFinalisedServiceData,
    selectedStartDate,
    selectedEndDate,
    compareStartDate,
    compareEndDate
}: BookingCounterParams) => {
    const currentMode = useTheme().palette.mode;

    // const { businessDaysCountCurrentMonth, businessDaysCountLastMonth } =

    const currentMonthIncompleteService = incompleteServiceData.length;
    const currentMonthCompleteService = completeServiceData.length;
    const currentMonthFinalisedService = finalisedServiceData.length;
    const currentMonthWaitingPaymentService = notFullyPaidServicesData.length;
    const lastMonthCompleteService = comparedMonthCompleteServiceData.length;
    const lastMonthFinalisedService = comparedMonthFinalisedServiceData.length;

    const businessDaysCountCurrentMonth = countBusinessDays(
        selectedStartDate,
        selectedEndDate
    );
    const businessDaysCountLastMonth = countBusinessDays(
        compareStartDate,
        compareEndDate
    );

    const currentMonthAverageCompleteServicePerDay = (
        (currentMonthCompleteService + currentMonthFinalisedService) /
        businessDaysCountCurrentMonth
    ).toFixed(2);

    const lastMonthAverageCompleteServicePerDay = (
        (lastMonthCompleteService + lastMonthFinalisedService) /
        businessDaysCountLastMonth
    ).toFixed(2);

    // click on job count cell to open drawer
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [drawerTitle, setDrawerTitle] = useState<string>('');
    const [drawerData, setDrawerData] = useState<Service[]>([]);

    const handleOpenBookingBreakdownDrawer = (serviceComplete, serviceData) => {
        setDrawerOpen(true);
        setDrawerTitle(serviceComplete);
        setDrawerData(serviceData);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={2} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Incomplete</Typography>
                        <Button
                            onClick={() =>
                                handleOpenBookingBreakdownDrawer(
                                    'Incomplete',
                                    incompleteServiceData
                                )
                            }
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {currentMonthIncompleteService}{' '}
                            </Typography>
                        </Button>
                        <Typography variant="h5"></Typography>
                    </Paper>
                </Grid>
                <Grid item xs={2} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Complete</Typography>
                        <Button
                            onClick={() =>
                                handleOpenBookingBreakdownDrawer(
                                    'Complete',
                                    completeServiceData
                                )
                            }
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {currentMonthCompleteService}{' '}
                            </Typography>
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={2} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Finalised</Typography>
                        <Button
                            onClick={() =>
                                handleOpenBookingBreakdownDrawer(
                                    'Finalised',
                                    finalisedServiceData
                                )
                            }
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {currentMonthFinalisedService}{' '}
                            </Typography>
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={2} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Unpaid</Typography>
                        <Button
                            onClick={() =>
                                handleOpenBookingBreakdownDrawer(
                                    'Waiting Payment',
                                    notFullyPaidServicesData
                                )
                            }
                        >
                            {' '}
                            <Typography
                                variant="h5"
                                style={{
                                    color:
                                        currentMode === 'dark'
                                            ? '#a88ce2'
                                            : '#9370DB'
                                }}
                            >
                                {currentMonthWaitingPaymentService}
                            </Typography>
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={4} textAlign="center">
                    <Paper>
                        <Typography variant="h6">
                            Avg Daily Job Completions
                        </Typography>
                        <Button>
                            {' '}
                            <Typography variant="h5">
                                {currentMonthAverageCompleteServicePerDay}
                            </Typography>
                        </Button>
                        {
                            <DataChangeIndicator
                                currentValue={Number(
                                    currentMonthAverageCompleteServicePerDay
                                )}
                                previousValue={Number(
                                    lastMonthAverageCompleteServicePerDay
                                )}
                                category="service"
                                dataType="number"
                                compareStartDate={compareStartDate}
                                compareEndDate={compareEndDate}
                            />
                        }
                    </Paper>
                </Grid>

                <BookingBreakdownDrawer
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    drawerTitle={drawerTitle}
                    drawerData={drawerData}
                />
            </Grid>
        </>
    );
};

export default BookingCounter;
