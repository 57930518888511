import { Grid, Typography, TableRow } from '@mui/material';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import { DateSafeFormatter } from '../../../global/logic/Formatters';
import Paper from '../../../global/Paper';
import BreakdownTableFilter from './BreakdownTableFilter';
import { Filter } from '../../../global/interfaces/FilterInterface';
import { useEffect, useState } from 'react';

const clearedBreakdownTableFilter = {
    vehicleSaleBreakdownTableFilter: {
        user: [],
        make: [],
        model: [],
        condition: []
    }
};

const VehicleSaleBreakdownTable = ({ vehicleSaleData, dataType }) => {
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedBreakdownTableFilter))
    );
    const [filteredData, setFilteredData] = useState(vehicleSaleData);

    const columns = [
        { id: 0, label: 'Sale ID' },
        { id: 1, label: 'User' },
        { id: 2, label: 'Customer' },
        dataType === 'finalised' || dataType === 'sale'
            ? { id: 3, label: 'Stock Number' }
            : null,
        { id: 4, label: 'Make' },
        { id: 5, label: 'Model' },

        dataType === 'finalised' || dataType === 'sale'
            ? { id: 6, label: 'Condition' }
            : null,

        dataType === 'finalised' ? { id: 7, label: 'Finalised Date' } : null

        // { id: 3, label: 'Total Cost (Ex GST)', width: 250 },
        // { id: 4, label: 'Total Revenue (Ex GST)' }
        // { id: 5, label: 'Gross Profit' }
    ].filter(Boolean); // this will remove null values from the array, so we can conditionally render columns

    useEffect(() => {
        const { user, make, model, condition } =
            filter.vehicleSaleBreakdownTableFilter;

        let result = vehicleSaleData.filter((sale) => {
            // Check if has user filters and if they match
            const userMatch =
                user.length > 0 ? user.some((u) => u.id === sale.UserId) : true;

            // Check if hss make filters and if they match
            const makeMatch =
                make.length > 0
                    ? make.some((m) => m.id === sale.vehicleMakeId)
                    : true;

            // Check if has model filters and if they match
            const modelMatch =
                model.length > 0
                    ? model.some((m) => m.id === sale.vehicleModelId)
                    : true;

            // Check if has condition filters and if they match
            const conditionMatch =
                condition.length > 0
                    ? condition.some((c) => c.value === sale.vehicleCondition)
                    : true;

            return userMatch && makeMatch && modelMatch && conditionMatch;
        });

        setFilteredData(result);
    }, [filter, vehicleSaleData]);

    // set vehicleSaleBreakdownTableFilter to clearedBreakdownTableFilter when dataType changes
    useEffect(() => {
        setFilter(JSON.parse(JSON.stringify(clearedBreakdownTableFilter)));
    }, [dataType]);

    return (
        <Grid item xs={12}>
            <Paper>
                <Grid container spacing={2} xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {dataType === 'finalised'
                                ? 'Finalised Vehicle Sale'
                                : dataType === 'sale'
                                  ? 'Sold Vehicle Sale'
                                  : 'All Pending Manufacturer Orders'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <BreakdownTableFilter
                            vehicleSaleData={vehicleSaleData}
                            filter={filter}
                            setFilter={setFilter}
                            dataType={dataType}
                        />
                    </Grid>
                </Grid>
                <br />
                <DataTable columns={columns}>
                    {filteredData?.map((sale) => (
                        <TableRow key={sale.id}>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/vehicles/viewContract/${sale.VehicleSaleId}`,
                                        '_blank'
                                    )
                                }
                            >
                                {sale.VehicleSaleId}
                            </DataCellColoured>
                            <DataCell>{sale.userName}</DataCell>
                            <DataCell>
                                {sale.customerName.length > 12
                                    ? `${sale.customerName.substring(0, 12)}...`
                                    : sale.customerName}
                            </DataCell>

                            {(dataType === 'finalised' ||
                                dataType === 'sale') && (
                                <DataCell>{sale.stockNumber}</DataCell>
                            )}

                            <DataCell>{sale.vehicleMake}</DataCell>
                            <DataCell>{sale.vehicleModel}</DataCell>

                            {(dataType === 'finalised' ||
                                dataType === 'sale') && (
                                <DataCell>{sale.vehicleCondition}</DataCell>
                            )}
                            {dataType === 'finalised' && (
                                <DataCell>
                                    {DateSafeFormatter(sale.finalisedDate)}
                                </DataCell>
                            )}

                            {/* <DataCell>
                        {CurrencyFormatter(sale.totalCost)}
                        <br /> */}
                            {/* {costPriceString(sale).map((line) => (
                  <Typography variant="caption" display={'block'}>
                    {line}
                  </Typography>
                ))} */}
                            {/* </DataCell> */}
                            {/* <DataCell>
                                {CurrencyFormatter(sale.totalSale)}
                            </DataCell> */}
                            {/* <DataCell>
                        {CurrencyFormatter(sale.totalSale - sale.totalCost)}
                    </DataCell> */}
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </Grid>
    );
};

export default VehicleSaleBreakdownTable;
