import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomerSignVendorStatement from './components/customerTabContents/CustomerSignVendorStatement';
import {
    Box,
    Button,
    Step,
    StepLabel,
    Stepper,
    Typography
} from '@mui/material';
import CustomerSignPersonalisedPlate from './components/customerTabContents/CustomerSignPersonalisedPlate';
import PageWrapper from '../../global/PageWrapper';
import CustomerSignBankDetails from './components/customerTabContents/CustomerSignBankDetails';
import CustomerSignConditionDeclaration from './components/customerTabContents/CustomerSignConditionDeclaration';
import { EmailSignedPdf } from '../signContract/logic/EmailContract';
import { UploadFileToS3 } from '../../esign/logic/S3FileService';
import CustomerSubmitSuccessPage from '../../esign/components/CustomerSubmitSuccessPage';
import { LoadingButton } from '@mui/lab';
import { withSnackbar } from '../../global/WrappingSnackbar';

const CustomerTradeDocManager = ({ showSnackbar }) => {
    let { id: vehicleId } = useParams<{ id: string }>();
    const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const [emailAttachments, setEmailAttachments] = useState<{
        [key: string]: string;
    }>({});

    const [filesToUpload, setFilesToUpload] = useState<
        { fileName: string; base64data: string }[]
    >([]);

    const [activeStep, setActiveStep] = useState(0);
    const [isPreviewed, setIsPreviewed] = useState(false);

    const includeDocs = new URLSearchParams(window.location.search).get(
        'includeDocs'
    );

    const handleNext = () => {
        setIsPreviewed(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const decodedIncludeDocs = atob(includeDocs);

    let steps = [];

    if (decodedIncludeDocs.includes('VendorStatement')) {
        steps.push({
            id: 0,
            title: 'Vendor Statement',
            content: (
                <CustomerSignVendorStatement
                    setFilesToUpload={setFilesToUpload}
                    setEmailAttachments={setEmailAttachments}
                    setIsPreviewed={setIsPreviewed}
                />
            )
        });
    }

    if (decodedIncludeDocs.includes('PersonalisedPlate')) {
        steps.push({
            id: 1,
            title: 'Personalised Plate',
            content: (
                <CustomerSignPersonalisedPlate
                    setFilesToUpload={setFilesToUpload}
                    setEmailAttachments={setEmailAttachments}
                    setIsPreviewed={setIsPreviewed}
                />
            )
        });
    }

    if (decodedIncludeDocs.includes('BankDetails')) {
        steps.push({
            id: 2,
            title: 'Bank Details',
            content: (
                <CustomerSignBankDetails
                    setFilesToUpload={setFilesToUpload}
                    setEmailAttachments={setEmailAttachments}
                    setIsPreviewed={setIsPreviewed}
                />
            )
        });
    }

    if (decodedIncludeDocs.includes('ConditionDeclaration')) {
        steps.push({
            id: 3,
            title: 'Condition Declaration',
            content: (
                <CustomerSignConditionDeclaration
                    setFilesToUpload={setFilesToUpload}
                    setEmailAttachments={setEmailAttachments}
                    setIsPreviewed={setIsPreviewed}
                />
            )
        });
    }

    const handleEmailSignedDocuments = () => {
        setButtonLoading(true);

        // Create an array of promises for each file upload
        const uploadPromises = filesToUpload.map((file) => {
            return UploadFileToS3({
                base64data: file.base64data,
                folderName: `vehicleTrades/${vehicleId}`,
                fileName: file.fileName,
                showSnackbar: showSnackbar
            });
        });

        // Wait for all file uploads to complete
        Promise.all(uploadPromises)
            .then(() => {
                // All files uploaded successfully, proceed with sending the email
                const emailAttachmentsArray = Object.entries(
                    emailAttachments
                ).map(([fileName, base64data]) => ({
                    fileName,
                    base64data
                }));

                EmailSignedPdf({
                    type: 'vehicle-trade',
                    id: Number(vehicleId),
                    emailAttachments: emailAttachmentsArray,
                    newStatus: 'vendorSigned',
                    setIsSubmitSuccess: setIsSubmitSuccess
                });
            })
            .catch((error) => {
                showSnackbar(
                    'File upload failed',
                    'Please click the Submit button again to retry.',
                    'error'
                );
                console.error('Failed to upload files', error);
            })
            .finally(() => {
                // Stop the loading indicator in any case
                setButtonLoading(false);
            });
    };

    if (isSubmitSuccess) {
        localStorage.removeItem('token');
        return <CustomerSubmitSuccessPage />;
    }

    return (
        <PageWrapper>
            <Box sx={{ width: '100%', position: 'relative' }}>
                <Box sx={{ padding: 2 }}>
                    <Typography variant="body1">
                        Please review the documents below, already filled out
                        and signed by the Motor Dealer. Scroll to the designated
                        SIGNATURE area and use the 'Preview Signature' button to
                        view your signature on the document. After reviewing,
                        click 'Next' to proceed to the next document. Once all
                        documents are signed, click 'Submit' to send the signed
                        documents back to the Motor Dealer. A copy will also be
                        sent to your email.
                    </Typography>
                </Box>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((item) => (
                        <Step key={item.id}>
                            <StepLabel>{item.title}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box
                    sx={{
                        position: 'fixed',
                        top: '50%', // Center the buttons at half the viewport height
                        left: 20,
                        right: 20,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    {activeStep === steps.length - 1 ? (
                        <LoadingButton
                            loading={buttonLoading}
                            variant="contained"
                            disabled={!isPreviewed || buttonLoading}
                            onClick={handleEmailSignedDocuments}
                        >
                            Submit
                        </LoadingButton>
                    ) : (
                        <Button
                            variant="contained"
                            disabled={!isPreviewed}
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    )}
                </Box>
                <Box sx={{ mx: 20 }}>
                    {steps.length > 0 &&
                        steps[activeStep] &&
                        steps[activeStep].content}
                </Box>
            </Box>
        </PageWrapper>
    );
};

export default withSnackbar(CustomerTradeDocManager);
