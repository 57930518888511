// LOGIC
import api from '../../../../../api';
// INTERFACES
import { ServiceCode } from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleEditServiceCode = (
    ServiceCodeId: number,
    serviceCode: ServiceCode,
    showSnackbar: showSnackbar
) => {
    // Validate that a service code was entered
    if (!serviceCode.serviceCode || serviceCode.serviceCode === '') {
        showSnackbar(
            'Service code is empty.',
            'Please enter a valid service code to continue.',
            'error'
        );
        return;
    }
    // Validate that a service price was entered and that it was greater than or equal to 0
    if (
        !serviceCode.servicePrice ||
        serviceCode.servicePrice === '' ||
        parseFloat(serviceCode.servicePrice) < 0
    ) {
        showSnackbar(
            'Service price is invalid.',
            'Please enter a service price that is 0 or above.',
            'error'
        );
        return;
    }
    // Validate that a job card description was entered
    if (
        !serviceCode.jobCardDescription ||
        serviceCode.jobCardDescription === ''
    ) {
        showSnackbar(
            'Job card description is empty.',
            'Please enter a valid job card description to continue.',
            'error'
        );
        return;
    }
    // Validate that an invoice description was entered
    if (
        !serviceCode.invoiceDescription ||
        serviceCode.invoiceDescription === ''
    ) {
        showSnackbar(
            'Invoice description is empty.',
            'Please enter a valid invoice description to continue.',
            'error'
        );
        return;
    }

    api.put(`serviceCodeNextGen/${ServiceCodeId}`, serviceCode).then((res) => {
        if (res.status === 200) {
            showSnackbar('Service Code Successfully Updated.');
            setTimeout(() => window.location.reload(), 1500);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleEditServiceCode;
