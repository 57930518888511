import { Grid, TextField, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface CustomerDetailsProps {
  disabled: boolean;
  customerDetails: {
    name: string;
    phoneNumber: string;
    email: string;
    suburb: string;
    occupation: string;
  };
  setCustomerDetails: Dispatch<
    SetStateAction<{
      name: string;
      phoneNumber: string;
      email: string;
      suburb: string;
      occupation: string;
    }>
  >;
  errors: {
    nameError: boolean;
    contactDetailsError: boolean;
    enquirySourceError: boolean;
    makeError: boolean;
  };
}

const CustomerDetails = (props: CustomerDetailsProps) => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Customer Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <TextField
            fullWidth
            label="Name"
            required
            disabled={props.disabled}
            error={props.errors.nameError}
            value={props.customerDetails.name || ''}
            onChange={(event) =>
              props.setCustomerDetails({
                ...props.customerDetails,
                name: event.target.value
              })
            }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="Phone Number"
            disabled={props.disabled}
            value={props.customerDetails.phoneNumber || ''}
            error={props.errors.contactDetailsError}
            helperText={
              props.errors.contactDetailsError
                ? 'An email or a phone are required'
                : null
            }
            onChange={(event) =>
              props.setCustomerDetails({
                ...props.customerDetails,
                phoneNumber: event.target.value
              })
            }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            fullWidth
            label="Email"
            disabled={props.disabled}
            value={props.customerDetails.email || ''}
            error={props.errors.contactDetailsError}
            helperText={
              props.errors.contactDetailsError
                ? 'An email or a phone are required'
                : null
            }
            onChange={(event) =>
              props.setCustomerDetails({
                ...props.customerDetails,
                email: event.target.value
              })
            }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="Suburb"
            disabled={props.disabled}
            value={props.customerDetails.suburb || ''}
            onChange={(event) =>
              props.setCustomerDetails({
                ...props.customerDetails,
                suburb: event.target.value
              })
            }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            fullWidth
            label="Occupation"
            disabled={props.disabled}
            value={props.customerDetails.occupation || ''}
            onChange={(event) =>
              props.setCustomerDetails({
                ...props.customerDetails,
                occupation: event.target.value
              })
            }
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerDetails;
