// IMPORTS
import { Dispatch, SetStateAction, useContext } from 'react';
import {
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { SiteContext } from '../../../../../main/Main';
// COMPONENTS
import CustomerTable from '../../../../global/customerFormComponents/CustomerTable';
import Paper from '../../../../global/Paper';
// INTERFACES
import { Customer, Site } from '../../../../global/interfaces/GeneralInterface';
import { Service } from '../../../../global/interfaces/ServiceInterface';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';

interface SelectCustomerFormProps {
    readOnly: boolean;
    selectedCustomer: Customer;
    setSelectedCustomer: Dispatch<SetStateAction<Customer>>;
    serviceDetails: Service;
    setServiceDetails: Dispatch<SetStateAction<Service>>;
    setVehicleType: Dispatch<SetStateAction<string>>;
    setSelectedInsurer: Dispatch<SetStateAction<Customer>>;
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle>>;
}

const SelectCustomerForm = ({
    readOnly,
    selectedCustomer,
    setSelectedCustomer,
    serviceDetails,
    setServiceDetails,
    setVehicleType,
    setSelectedInsurer,
    setSelectedVehicle
}: SelectCustomerFormProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const handleChangeType = (
        event: React.MouseEvent<HTMLElement>,
        newType: string | null
    ) => {
        if (newType != null) {
            if (newType === 'Internal') {
                setVehicleType('stock');
            } else if (newType === 'Retail') {
                setVehicleType('customer');
            }
            setServiceDetails({ ...serviceDetails, jobType: newType });
            setSelectedVehicle(null);
            setSelectedCustomer(null);
            setSelectedInsurer(null);
        }
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            fullWidth
                            value={serviceDetails.jobType}
                            onChange={handleChangeType}
                            disabled={readOnly}
                            size="small"
                        >
                            <ToggleButton value="Retail">Retail</ToggleButton>
                            <ToggleButton value="Internal">
                                Internal
                            </ToggleButton>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                                <ToggleButton value="Insurance">
                                    Insurance
                                </ToggleButton>
                            ) : null}
                            <ToggleButton value="Warranty">
                                Warranty
                            </ToggleButton>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                                <>
                                    <ToggleButton value="Rework">
                                        Rework
                                    </ToggleButton>
                                    <ToggleButton value="Quote">
                                        Quote
                                    </ToggleButton>
                                </>
                            ) : null}
                        </ToggleButtonGroup>
                    </Grid>
                    {serviceDetails.paymentLines.length > 0 ? (
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                The type of customer and the customer/entity
                                itself cannot be changed because payment(s) have
                                been received.
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>
            </Paper>
            <br />
            {serviceDetails.jobType !== 'Internal' ? (
                <CustomerTable
                    readOnly={readOnly}
                    selectedEntity={selectedCustomer}
                    setSelectedEntity={setSelectedCustomer}
                    includeShippingDrawer={false}
                    customRoute="serviceCustomerSearch"
                />
            ) : null}
        </>
    );
};

export default SelectCustomerForm;
