import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
const HandleCreateBackorders = (
    selectedOrder: GroupedOnlineOrder,
    moveOrder: boolean,
    handleUpdateAllStatesOrderlines: (
        newOrderLines,
        OrderId: any,
        moveOrder: boolean
    ) => void,
    setBackordersDialogOpen: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    api.put(
        `createOnlineBackorders?moveOrder=${moveOrder}`,
        selectedOrder.oraOrder
    ).then((res) => {
        if (res.status === 200) {
            showSnackbar('Backorders have been created.');
            handleUpdateAllStatesOrderlines(
                res.data,
                selectedOrder.oraOrder.id,
                moveOrder
            );
            setBackordersDialogOpen(false);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT depatment.',
                'error'
            );
        }
    });
};

export default HandleCreateBackorders;
