import DocumentationPage from '../DocumentationPage';
import {
    AddNewVoucherSteps,
    PayWithVoucherSteps
} from '../data/parts/vouchers';

const VouchersSupportPage = () => {
    const contentSections = [
        {
            id: 'add-new-voucher',
            title: 'Add new gift voucher',
            href: '/support/inventory/vouchers/#add-new-voucher',
            description: 'To add new gift voucher, follow these steps:',
            steps: AddNewVoucherSteps
        },
        {
            id: 'pay-with-voucher',
            title: 'Make payment with gift voucher',
            href: '/support/inventory/vouchers/#pay-with-voucher',
            description:
                'To make payment with gift voucher, follow these steps:',
            steps: PayWithVoucherSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Vouchers"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default VouchersSupportPage;
