// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface CompleteTransferProps {
    transferId: number;
    notes: string;
    newSiteId: number;
    VehicleId: number;
    setButtonLoading: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}
const CompleteTransfer = ({
    transferId,
    notes,
    newSiteId,
    VehicleId,
    setButtonLoading,
    showSnackbar
}: CompleteTransferProps) => {
    setButtonLoading(true);
    let obj = {
        notes: notes,
        newSiteId: newSiteId,
        VehicleId: VehicleId
    };
    api.put(`completeVehicleTransfer/${transferId}`, obj).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Vehicle Transfer Complete',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something has gone wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default CompleteTransfer;
