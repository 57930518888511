// IMPORTS
import { useEffect, useState } from 'react';
import { TableRow, Typography } from '@mui/material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
// LOGIC
import { DateFormatter } from '../../../global/logic/Formatters';
import GetVehicleServiceHistory from '../logic/GetVehicleServiceHistory';
// INTERFACES
import { Service } from '../../../global/interfaces/ServiceInterface';
interface VehicleHistoryContentProps {
    VehicleId: number;
    ServiceId: number;
}

const VehicleHistoryContent = ({
    VehicleId,
    ServiceId
}: VehicleHistoryContentProps) => {
    const [vehicleHistory, setVehicleHistory] = useState<Service[]>([]);

    const columns = [
        { id: 0, label: 'Service ID' },
        { id: 1, label: 'Job Type' },
        { id: 2, label: 'Status' },
        { id: 3, label: 'Start Date' }
    ];

    useEffect(() => {
        GetVehicleServiceHistory(VehicleId, setVehicleHistory);
    }, [VehicleId]);

    return (
        <>
            {vehicleHistory.length === 0 ? (
                <Typography>No recorded service history.</Typography>
            ) : (
                <>
                    <DataTable columns={columns}>
                        {vehicleHistory.map((service) => (
                            <TableRow selected={service.id === ServiceId}>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            `/service/viewBooking/${service.id}`,
                                            '_blank'
                                        )
                                    }
                                >
                                    {service.id}
                                </DataCellColoured>
                                <DataCell>{service?.jobType}</DataCell>
                                <DataCell>{service?.serviceComplete}</DataCell>
                                <DataCell>
                                    {DateFormatter(service?.jobStart)}
                                </DataCell>
                            </TableRow>
                        ))}
                    </DataTable>
                </>
            )}
        </>
    );
};

export default VehicleHistoryContent;
