import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const ImportAccountFromCSV = (
    importData,
    headers,
    showSnackbar: showSnackbar
) => {
    let formattedArray = [];
    for (let item of importData) {
        let tempObject = {};
        for (let [index, header] of headers.entries()) {
            if (header === 'initialBalance') {
                tempObject[header] = parseFloat(item[index]);
            } else {
                tempObject[header] = item[index];
            }
        }
        formattedArray.push(tempObject);
    }

    api.post('importAccountCSV', formattedArray).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Account(s) successfully imported',
                'The page will now refresh.'
            );
            setTimeout(() => window.location.reload(), 1500);
        }
    });
};
