//IMPORTS
import { useEffect, useState } from 'react';
import { TableRow, Typography } from '@material-ui/core';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import Paper from '../../../global/Paper';
// LOGIC
import GetOrders from '../../../global/databaseLogic/GetOrders';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';

interface ProformaProps {
  partId: number;
  selectedSite: number;
}

const Proformas = ({ partId, selectedSite }: ProformaProps) => {
  const [proformas, setProformas] = useState<Order[]>();

  useEffect(() => {
    GetOrders('Proforma,Online Proforma', partId, selectedSite, setProformas);
  }, [partId, selectedSite]);

  const columns = [
    { id: 0, label: 'Order ID' },
    { id: 1, label: 'Customer' },
    { id: 2, label: 'Quantity' },
    { id: 3, label: 'Backorder Quantity' },
    { id: 4, label: 'Paid' },
    { id: 5, label: 'Balance' },
    { id: 6, label: 'User' }
  ];

  return (
    <>
      <Paper>
        {proformas && proformas.length > 0 ? (
          <DataTable columns={columns}>
            {proformas?.map((row) => (
              <TableRow key={row.id} className="row">
                <DataCellColoured
                  handleClick={() =>
                    (window.location.href = `inventory/viewSale/${row.id}`)
                  }
                >
                  {' '}
                  {row.id}{' '}
                </DataCellColoured>
                <DataCell>
                  {row.Customer?.companyName
                    ? row.Customer?.companyName
                    : row.Customer?.firstName + ' ' + row.Customer?.lastName}
                </DataCell>
                <DataCell>
                  {row?.orderLines?.map((order) =>
                    order.UnitId === partId ? order.quantity : null
                  )}
                </DataCell>
                <DataCell>
                  {row?.orderLines?.map((order) =>
                    order.UnitId === partId ? order.backorderQuantity : null
                  )}
                </DataCell>
                <DataCell>{CurrencyFormatter(row.amountPaid)}</DataCell>
                <DataCell>{CurrencyFormatter(row.amountOwed)}</DataCell>
                <DataCell>
                  {row.User?.firstName} {row.User?.lastName}
                </DataCell>
              </TableRow>
            ))}
          </DataTable>
        ) : (
          <Typography variant="body1" align="center">
            No current proformas for this part.
          </Typography>
        )}
      </Paper>
    </>
  );
};

export default Proformas;
