import { Dispatch, SetStateAction } from "react";
import api from "../../../../../../../api";
import {Dayjs} from 'dayjs'
import { Site } from "../../../../../global/interfaces/GeneralInterface";

interface CalculateGSTReportingParams {
    (queryDate: {startDate: Dayjs, endDate: Dayjs},
    selectedSite: Site[],
    setGstInformation: Dispatch<SetStateAction<{
        description: string;
        total: number;
        transactions: {
            customerGstFree: boolean;
            customerInternational: boolean;
            customerName: string;
            description: string;
            finalisedDate: string;
            gstAmount: number;
            id: number;
            link: string;
            reference: string;
            subReference: string;
            totalIncGst: number;
        }[];
        warningCounter: number;
    }[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>): void
}

const CalculateGSTReporting: CalculateGSTReportingParams = (queryDate, selectedSite, setGstInformation, setLoading) => {
    setLoading(true);
    api.get(`GSTReportingNextGen?SiteId=${ selectedSite.map(x => x.id).toString() }&startDate=${ queryDate.startDate }&endDate=${ queryDate.endDate }`).then(res => {
        setGstInformation(res.data);
        setLoading(false);
    });
};

export default CalculateGSTReporting;