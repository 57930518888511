import { Step } from '../../interface';

export const AddNewCustomerSaleSteps: Step[] = [
    {
        label: "Navigate to 'Parts & Accessories' > 'New Sale' via the sidebar to access the New Sale page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-01.png'
    },
    {
        label: `Select the 'Customer' sale type from the available options.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-02.png'
    },
    {
        label: 'Choose a customer by selecting the corresponding radio button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-03.png'
    },
    {
        label: "Under the 'Orderlines' tab, select the sale status from three options: SALE, PROFORMA, or QUOTE. Here 'PROFORMA' is selected.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-04.png'
    },
    {
        label: `The PO Number field can be used to assigned a Purchase Order Number to the sale.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-05.png'
    },
    {
        label: `You can change the Site via a dropdown menu if necessary.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-06.png'
    },
    {
        label: `Add internal or customer notes to the order as needed. Customer notes will print on the customer invoice.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-07.png'
    },
    {
        label: `To add parts, enter the part number or barcode in the "Enter Part Number / Barcode" field and press Enter.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-08.png'
    },
    {
        label: `If Ora couldn't find the part number, a dialog will appear, allowing you to add the part.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-09.png'
    },
    {
        label: `If the part is superceded, adding the original part to an order will trigger a dialog warning about the supersession, allowing you to select the new part. `,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/inventory/edit-part-07.png'
    },
    {
        label: "If multiple Suppliers provide this part to the current site, or if the part doesn't have a Supplier assigned in the current site but has Supplier assigned in other sites, a 'Select Part Suppliers' dialog will appear. This dialog displays the supplier names, supply sites, and pricing details. Primary suppliers are marked with a 'Primary' label. You can select the specific supplier you want to get the part from by clicking the corresponding icon. This choice will change the cost of the part, the RRP and the Supplier from which to Backorder.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-11.png'
    },
    {
        label: `You can edit the Quantity via the Qty field, the Backorder Quantity via the B/O field, this will create a Customer Backorder for the parts and the Unit Price. Below the B/O, you can set the backorder type as 'Daily' or 'Stock'.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-13.png'
    },
    {
        label: "In a Proforma, parts can be marked as 'Collecting' by checking the corresponding box, indicating that the Customer is collecting them. If a Customer is collecting parts, this will create a sale with the collected parts and leave the other parts in the Proforma. This prevents you from having to create a Proforma and a Sale manually.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-14.png'
    },
    {
        label: `Note: All parts can be marked as collected in a Proforma, but if the customer is taking all parts you mark the order as a 'Sale' instead.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-15.png'
    },
    {
        label: "Use the three-dot menu for additional options: 'Remove Parts' to clear all parts from the table, 'Original RRP' to reset all parts' unit prices to their original RRP, and 'Majoration/Discount' to apply a percentage discount or markup across all current order lines.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-16.png'
    },
    {
        label: 'Use the three-dot menu next to each part to edit specific details. Options include reprinting the part label, removing the part from the order lines, and updating the pricing. Changes apply only to the selected part.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-17.png'
    },
    {
        label: `In the Added Costs table, for a Proforma or Quote, can only add 'Freight'. In a Sale, can add both 'Voucher' and 'Freight'.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-18.png'
    },

    {
        label: "The Totals table provides a price summary of the order. 'Due Now' indicates the amount the customer needs to pay immediately. In this example, part 0123456789 is marked as collecting with a quantity of 2 and a unit price of $30, so the 'Due Now' amount is $60 since the Customer is leaving the store with these two parts.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-19.png'
    },
    {
        label: "In the Payment tab, you can add a payment by selecting the payment method, till, amount, and clicking 'Add'. If the amount paid is less than the Amount Due, you will see an error message indicating insufficient payment upon saving the order.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-20.png'
    },
    {
        label: "After entering all required information for the your order, click 'SAVE' to submit the order. Upon successful submission, a customer invoice PDF will automatically open in a new window.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-22.png'
    },
    {
        label: "From the previous example: the Sales page now display two new entries: one with the status 'Sale' and another as 'Proforma'. The Sale was created because parts were marked as 'Collecting' during the creation of the Proforma, if you go into the sale you will see that only collected parts are present and they display the ID of the Proforma where they were originally created. If you go into the proforma you will also see the parts, but they are greyed out and display the Sale ID where they are now located.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-23.png'
    },
    {
        label: 'The above steps are for adding a Proforma sale. To add a Quote or Sale, follow similar steps. Note that for a Quote, only the customer and order lines need to be selected, with no payment required.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-24.png'
    },
    {
        label: 'For a Sale, ensure that the total paid is equal to the sale total, otherwise you will see an error message indicating insufficient payment when you try to submit the order.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-25.png'
    }
];

export const AddNewVehicleContractSalesSteps: Step[] = [
    {
        label: "Navigate to 'Parts & Accessories' > 'New Sale' via the sidebar to access the New Sale page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/add-sale-01.png'
    },
    {
        label: `Select the 'Vehicle Contract' sale type from the available options.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/new-contract-sale-02.png'
    },
    {
        label: 'Choose a Vehicle Sale by selecting the corresponding radio button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/new-contract-sale-03.png'
    },
    {
        label: "Under the Orderlines tab, you will see that Proforma is selected by default, you won't be able to change this status until the Vehicle Sale has received Payment. If the Vehicle Sale has received enough payment to cover this Parts & Accessories Proforma, you can go into the Vehicle Sale's Payment tab and click ''Pay CSM'' and it will allocate the necessary amount from the Vehicle Sale to the Parts & Accessories Proforma, and you will be able to turn this Proforma into a Sale (this can't be reversed).",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/new-contract-sale-04.png'
    },
    {
        label: (
            <>
                Follow the same steps as you would when creating a Parts &
                Accessories Sale. Refer to{' '}
                <a
                    href="/support/inventory/newSale/#add-customer-part-sale"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Create a new Parts & Accessories Sale
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/new-contract-sale-05.png'
    },
    {
        label: 'After submission, you can find the newly added parts sale on the Sales page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/new-contract-sale-06.png'
    }
];
