// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Account } from '../../../global/interfaces/AdminInterface';

// Gets all the account transactions for a selected account and stores them for viewing in the drawer
const GetAllAccountTransactions = (
    selectedAccount: Account,
    setSelectedAccount: Dispatch<SetStateAction<Account>>
) => {
    if (selectedAccount?.id) {
        api.get(`getAccountTransactionsNextGen/${selectedAccount.id}`).then(
            (res) => {
                setSelectedAccount({
                    ...selectedAccount,
                    transactions: res.data.transactions,
                    accountTotal: res.data.accountTotal
                });
            }
        );
    } else {
        setSelectedAccount(null);
    }
};

export default GetAllAccountTransactions;
