// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { User } from '../interfaces/GeneralInterface';

/*  GetAllUsers
    Gets the details of all users
    params:
        - setUsers: react state to save the user details to
        - query: if you want to send extra parameters such as department
    returns:
        - the details of all the users in a json format
*/
const GetAllUsers = (
  setUsers: Dispatch<SetStateAction<User[]>>,
  query?: string
) => {
  api.get(`getAllUsers${query ? query : ''}`).then((res) => {
    setUsers(res.data);
  });
};

export default GetAllUsers;
