// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import {
    VehicleInvoiceLine,
    TradeIn,
    VehicleTransfer,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';

interface VehicleMovement {
    invoices: VehicleInvoiceLine[];
    trades: TradeIn[];
    transfers: VehicleTransfer[];
    sales: VehicleSale[];
}

const GetVehicleMovement = (
    VehicleId: number,
    setVehicleMovement: Dispatch<SetStateAction<VehicleMovement>>
) => {
    api.get(`vehicleMovement/${VehicleId}`).then((res) => {
        if (res.status === 200) {
            setVehicleMovement(res.data);
        }
    });
};

export default GetVehicleMovement;
