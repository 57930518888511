// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
interface StocktakeDetails {
    countedParts: any[];
    uncountedParts: any[];
    movedBins: any[];
    notes: string;
    review: boolean;
}

/*
    When the user manually changes the part or average cost using the textfields this handles updating that value
*/
const HandleChangePartCount = (
    stocktakeDetails: StocktakeDetails,
    setStocktakeDetails: Dispatch<SetStateAction<StocktakeDetails>>,
    pageType: string,
    UnitId: number,
    key: string,
    newValue: any
) => {
    // If on the uncounted page we need to update value as well as remove it from the uncounted page
    // and add it to the counted page
    if (pageType === 'Uncounted') {
        // Save the current options
        let currentUncountedLines = [...stocktakeDetails.uncountedParts];
        let currentCountedLines = [...stocktakeDetails.countedParts];

        // Find the correct row
        let index = currentUncountedLines.findIndex((x) => x.id === UnitId);
        let row = { ...currentUncountedLines[index] };

        // Update the value
        row[key] = parseFloat(newValue);
        currentUncountedLines[index] = row;

        // Add it to the top of the counted rows and remove it from the uncounted rows
        currentCountedLines.unshift(row);
        currentUncountedLines = stocktakeDetails.uncountedParts.filter(
            (x) => x.id !== UnitId
        );

        setStocktakeDetails({
            ...stocktakeDetails,
            countedParts: currentCountedLines,
            uncountedParts: currentUncountedLines
        });
    }

    // If on the counted page
    else {
        // Save the current rows
        let currentLines = [...stocktakeDetails.countedParts];

        // Find the correct row
        let index = currentLines.findIndex((x) => x.id === UnitId);
        let row = { ...currentLines[index] };

        // Update the value
        row[key] = parseFloat(newValue);
        currentLines[index] = row;

        setStocktakeDetails({
            ...stocktakeDetails,
            countedParts: currentLines
        });
    }
};

export default HandleChangePartCount;
