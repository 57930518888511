// IMPORTS
import { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Edit, Print, Save } from '@mui/icons-material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import InvoiceDetails from './components/InvoiceDetails';
import InvoiceLines from './components/InvoiceLines';
import InvoicePayments from './components/InvoicePayments';
import RoleCheck from '../../global/RoleCheck';
import Fab from '../../global/Fab';
import Paper from '../../global/Paper';
import SpeedDial from '../../global/SpeedDial';
// LOGIC
import GetInvoiceDetails from './logic/GetInvoiceDetails';
import HandleEditInvoice from './logic/HandleEditInvoice';
import HandleDeleteInvoice from './logic/HandleDeleteInvoice';
import PrintLabel from '../../global/logic/PrintLabel';
// INTERFACES
import { Invoice } from '../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import PageDoesNotExist from '../../global/PageDoesNotExist';
import { PaymentRun } from '../../global/interfaces/AdminInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

const ViewInvoice = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    let { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    const [invoiceDetails, setInvoiceDetails] = useState<Invoice>({});
    const [invoiceType, setInvoiceType] = useState({
        type: '',
        id: null,
        selector: ''
    });
    const [payrunDetails, setPayrunDetails] = useState<
        { payrun: PaymentRun; payrunInvoices: Invoice[] }[]
    >([]);
    const [deleteInvoiceDialogOpen, setDeleteInvoiceDialogOpen] =
        useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);

    const actionsList = [
        {
            icon: readOnly ? <Edit /> : <Save />,
            name: readOnly ? 'Edit' : 'Save',
            permission: 'edit_invoice',
            onClick: () => handleFabClick()
        },
        {
            icon: <Print />,
            name: 'Reprint Labels',
            onClick: () => handlePrintLabels()
        }
    ];

    useEffect(() => {
        GetInvoiceDetails(
            parseInt(id),
            setInvoiceDetails,
            setInvoiceType,
            setPayrunDetails,
            setLoading,
            showSnackbar,
            setInvalid
        );
        // eslint-disable-next-line
    }, [id]);

    /**
     * handleFabClick
     * Edit/Save functionality for speeddial edit option
     * @author Estienne
     */
    const handleFabClick = () => {
        if (readOnly) {
            setReadOnly(false);
        } else {
            HandleEditInvoice(parseInt(id), invoiceDetails, showSnackbar);
        }
    };

    const checkIfMultipleStockOrder = (orderLines) => {
        let isMultipleSO = false;
        if (orderLines && orderLines[0]?.StockOrderId) {
            isMultipleSO = true;
        }
        return isMultipleSO;
    };

    /**
     * handlePrintLabels
     * Reprint stock invoice labels
     * @author Estienne
     */
    const handlePrintLabels = () => {
        let labelData = [];
        // Create the label data
        if (checkIfMultipleStockOrder(invoiceDetails.StockInvoice.orderLines)) {
            invoiceDetails.StockInvoice.orderLines.forEach((orderline) => {
                orderline.orderLines.forEach((part) => {
                    for (let i = 0; i < parseInt(part.quantityReceived); i++) {
                        labelData.push({
                            partNumber: part.partNumber,
                            name: part.name,
                            binLocation: part?.binLocation,
                            priceRRP: part?.priceRRP
                        });
                    }
                });
            });
        } else {
            invoiceDetails.StockInvoice.orderLines.forEach((part) => {
                let customerBackorders = [];
                for (let backorder of part.customerBackorders) {
                    for (let i = 0; i < backorder.backorderQuantity; i++) {
                        customerBackorders.push(backorder);
                    }
                }
                for (let i = 0; i < parseInt(part.quantityReceived); i++) {
                    let backorderId =
                        i <= customerBackorders.length - 1
                            ? customerBackorders[i].OrderId
                            : null;

                    let customerName =
                        i <= customerBackorders.length - 1
                            ? customerBackorders[i].customerName
                            : null;

                    let phoneNumber =
                        i <= customerBackorders.length - 1 &&
                        customerBackorders[i].phoneNumber
                            ? customerBackorders[i].phoneNumber
                            : null;

                    let serviceId =
                        i <= customerBackorders.length - 1 &&
                        customerBackorders[i].serviceId
                            ? customerBackorders[i].serviceId
                            : null;
                    let vehicleId =
                        i <= customerBackorders.length - 1 &&
                        customerBackorders[i].vehicleId
                            ? customerBackorders[i].vehicleId
                            : null;
                    let stockNumber =
                        i <= customerBackorders.length - 1 &&
                        customerBackorders[i].stockNumber
                            ? customerBackorders[i].stockNumber
                            : null;

                    labelData.push({
                        partNumber: part.partNumber,
                        name: part.name,
                        binLocation: part?.binLocation,
                        priceRRP: part?.priceRRP,
                        backorderId: backorderId,
                        customerName: customerName,
                        phoneNumber: phoneNumber,
                        serviceId: serviceId,
                        vehicleId: vehicleId,
                        stockNumber: stockNumber
                    });
                }
            });
        }

        PrintLabel(labelData, showSnackbar);
    };

    return (
        <PageWrapper>
            {loading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress sx={{ textAlign: 'center' }} />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4">
                        {invoiceType.type} Invoice #{invoiceType.id}
                    </Typography>
                    <Typography variant="subtitle1">
                        {invoiceDetails?.Supplier?.name}
                    </Typography>
                    <br />
                    <InvoiceDetails
                        invoiceDetails={invoiceDetails}
                        setInvoiceDetails={setInvoiceDetails}
                        invoiceType={invoiceType}
                        readOnly={readOnly}
                        setDeleteInvoiceDialogOpen={setDeleteInvoiceDialogOpen}
                    />
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <InvoiceLines
                                disabled={readOnly}
                                invoiceDetails={invoiceDetails}
                                setInvoiceDetails={setInvoiceDetails}
                                invoiceType={invoiceType}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <InvoicePayments
                                invoiceDetails={invoiceDetails}
                                payruns={payrunDetails}
                            />
                        </Grid>
                    </Grid>

                    {invoiceDetails?.paymentHistory?.length < 1 ? (
                        invoiceType.type === 'Stock' ? (
                            <SpeedDial actionsList={actionsList} />
                        ) : (
                            <RoleCheck
                                permission="edit_invoice"
                                component={
                                    <Fab
                                        editIcon={readOnly}
                                        onClick={() => handleFabClick()}
                                    />
                                }
                            />
                        )
                    ) : invoiceType.type === 'Stock' ? (
                        <Fab
                            customIcon={<Print />}
                            onClick={() => handlePrintLabels()}
                        />
                    ) : (
                        <RoleCheck
                            permission="edit_invoice"
                            component={
                                <Fab
                                    editIcon={readOnly}
                                    onClick={() => handleFabClick()}
                                />
                            }
                        />
                    )}

                    <Dialog
                        open={deleteInvoiceDialogOpen}
                        onClose={() => setDeleteInvoiceDialogOpen(false)}
                    >
                        <DialogTitle>
                            Are you sure you would like to delete{' '}
                            {invoiceType.type} Invoice #{invoiceType.id}?
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">
                                Deleting this invoice will remove any stock that
                                was entered on it, both parts and vehicles
                                (where applicable).
                                <br />
                                <br />
                                <b>This action cannot be undone.</b>
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() =>
                                    setDeleteInvoiceDialogOpen(false)
                                }
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                loading={buttonLoading}
                                onClick={() =>
                                    HandleDeleteInvoice(
                                        parseInt(id),
                                        invoiceType.id,
                                        invoiceType.type,
                                        invoiceDetails.SupplierId,
                                        showSnackbar,
                                        setButtonLoading
                                    )
                                }
                            >
                                Delete Invoice
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </PageWrapper>
    );
};

export default withSnackbar(ViewInvoice);
