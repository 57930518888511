// IMPORTS
import { useState, useEffect, useContext } from 'react';
import { Typography, TableRow, Dialog } from '@mui/material';
import {
    Check,
    Add,
    FileDownload,
    PriceChange,
    Warehouse,
    ShowChart
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { SiteContext } from '../../../main/Main';

// COMPONENTS
import VehicleTableFilter from './components/VehicleTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import { ImportVehicleCSVContent } from '../emptyTableDialog/ImportVehicleCSVContent';
import RoleCheck from '../../global/RoleCheck';
import EmptyVehicleTableDialogContent from '../emptyTableDialog/EmptyVehicleTableDialogContent';
import { renderVehicleStatus } from './components/RenderVehicleStatus';

// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import HandleDownloadResults from '../../global/logic/HandleDownloadResults';
import CreateAgeStockReport from './logic/CreateAgeStockReport';
import getVehicleStatus from '../vehicle/logic/GetVehicleStatus';
import { CurrencyFormatter } from '../../global/logic/Formatters';

// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { Vehicle } from '../../global/interfaces/VehicleSaleInterface';
import { Site } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    vehicleFilter: {
        condition: [],
        make: [],
        model: [],
        site: [],
        startYear: null,
        endYear: null,
        available: null,
        learnerApproved: null
    }
};
const clearedSort = ['id', 'DESC'];

const VehicleTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const history = useHistory();
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<Vehicle[]>([]);
    const siteDetails = useContext<Site>(SiteContext);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [emptyField, setEmptyField] = useState<string>('');

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Vehicle',
            onClick: () => history.push('/vehicles/invoiceVehicles')
        },
        {
            icon: <FileDownload />,
            name: 'Export Current Results',
            onClick: () => handleDownloadResults()
        },
        {
            icon: <PriceChange />,
            name: 'Update Vehicle RRPs',
            onClick: () => history.push('/vehicles/updatePrices')
        },
        {
            icon: <Warehouse />,
            name: 'Manufacturer Vehicles',
            onClick: () => history.push('/vehicles/manufacturerVehicles')
        },
        {
            icon: <ShowChart />,
            name: 'Age Stock Report',
            onClick: () => CreateAgeStockReport(filter.vehicleFilter.site)
        }
    ];

    // Columns for the data table
    const columns =
        siteDetails.vehicleTypeSold === 'Motorcycles'
            ? [
                  {
                      id: 0,
                      label: 'Stock Number',
                      name: 'Stock Number',
                      width: 100
                  },
                  { id: 1, label: 'Make / Model / Series', width: 350 },
                  {
                      id: 2,
                      label: 'Year',
                      name: 'year',
                      sort: true,
                      width: 150
                  },
                  { id: 3, label: 'Condition', width: 100 },
                  { id: 4, label: 'Colour', width: 100 },
                  { id: 5, label: 'Short VIN', width: 100 },
                  { id: 6, label: 'Cost', width: 100 },
                  { id: 7, label: 'Age', name: 'age', sort: true, width: 100 },
                  { id: 8, label: 'Site', width: 200 },
                  { id: 9, label: 'Learner Approved', width: 100 },
                  { id: 10, label: 'Available', width: 100 }
              ]
            : [
                  {
                      id: 0,
                      label: 'Stock Number',
                      name: 'Stock Number',
                      width: 100
                  },
                  { id: 1, label: 'Make / Model / Series', width: 350 },
                  {
                      id: 2,
                      label: 'Year',
                      name: 'year',
                      sort: true,
                      width: 150
                  },
                  { id: 4, label: 'Colour', width: 100 },
                  { id: 6, label: 'Cost', width: 100 },
                  { id: 8, label: 'Site', width: 200 },
                  { id: 10, label: 'Available', width: 200 }
              ];

    // Once the current site details have been obtained set the filter to some default values
    useEffect(() => {
        let newFilter = {
            condition: [],
            make: [],
            model: [],
            site: [
                {
                    id: parseInt(localStorage.getItem('SiteId')),
                    name: siteDetails?.name
                }
            ],
            startYear: null,
            endYear: null,
            available: true,
            learnerApproved: null
        };
        setFilter({ ...filter, vehicleFilter: newFilter });
        // eslint-disable-next-line
    }, [siteDetails]);

    // Handle downloading the csv
    const handleDownloadResults = () => {
        HandleDownloadResults(
            sort,
            'vehicleSearchNextGen',
            handleRequestCreate(),
            'OraVehicleResults.csv',
            [
                'Stock Number',
                'Make',
                'Model',
                'Series',
                'Year Model',
                'Condition',
                'VIN',
                'Cost',
                'RRP',
                'Site'
            ],
            [
                'stockNumber',
                'Make.name',
                'Model.name',
                'Series.name',
                'year',
                'condition',
                'vin',
                'costPrice',
                'priceRRP',
                'SiteId'
            ]
        );
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let condition =
            filter.vehicleFilter.condition.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleFilter.condition,
                      filterStart: '&condition=',
                      selector: 'value'
                  })
                : '';
        let make =
            filter.vehicleFilter.make.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleFilter.make,
                      filterStart: '&make=',
                      selector: 'id'
                  })
                : '';
        let model =
            filter.vehicleFilter.model.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleFilter.model,
                      filterStart: '&model=',
                      selector: 'id'
                  })
                : '';
        let site =
            filter.vehicleFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.vehicleFilter.site,
                      filterStart: '&site=',
                      selector: 'id'
                  })
                : '';
        let modelYearStart =
            filter.vehicleFilter.startYear !== null &&
            filter.vehicleFilter.startYear.isValid()
                ? '&startYear=' + filter.vehicleFilter.startYear.toISOString()
                : '';
        let modelYearEnd =
            filter.vehicleFilter.endYear !== null &&
            filter.vehicleFilter.endYear.isValid()
                ? '&endYear=' + filter.vehicleFilter.endYear.toISOString()
                : '';
        let available = `&available=${filter.vehicleFilter.available ? 'true' : 'false'}`;
        let learnerApproved = `&learnerApproved=${filter.vehicleFilter.learnerApproved ? 'true' : 'false'}`;

        let apiString = `${condition}${make}${model}${site}${modelYearStart}${modelYearEnd}${available}${learnerApproved}&checkEmpty=true`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;
        if (
            filter.vehicleFilter.condition.length > 0 ||
            filter.vehicleFilter.make.length > 0 ||
            filter.vehicleFilter.model.length > 0 ||
            filter.vehicleFilter.site.length > 0 ||
            filter.vehicleFilter.startYear ||
            filter.vehicleFilter.endYear ||
            filter.vehicleFilter.available != null ||
            filter.vehicleFilter.learnerApproved != null
        ) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Vehicle</Typography>
            <br />
            <TableSearch
                searchTitle="Search Vehicle"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="vehicleSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
                setIsEmpty={setIsEmpty}
                setEmptyField={setEmptyField}
            >
                <VehicleTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() =>
                                    history.push(`/vehicles/view/${row.id}`)
                                }
                            >
                                {row.stockNumber}
                            </DataCellColoured>
                            <DataCell>
                                {row?.Make?.name} {row?.Model?.name}{' '}
                                {row?.Series?.name}
                            </DataCell>
                            <DataCell>{row.year.format('YYYY')}</DataCell>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' && (
                                <DataCell>{row.condition}</DataCell>
                            )}
                            <DataCell>{row.colour}</DataCell>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' && (
                                <DataCell>...{row?.vin?.slice(-6)}</DataCell>
                            )}
                            <DataCell>
                                <RoleCheck
                                    permission="sales_manager, assign_superusers"
                                    component={CurrencyFormatter(row.costPrice)}
                                />
                            </DataCell>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' && (
                                <DataCell>{row.age} days</DataCell>
                            )}
                            <DataCell>{row.Site.name}</DataCell>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' && (
                                <DataCell>
                                    {row.learnerApproved === true ? (
                                        <Check />
                                    ) : null}
                                </DataCell>
                            )}

                            {row.VehicleSaleId &&
                            getVehicleStatus(row) === row.VehicleSaleId ? (
                                <DataCellColoured
                                    handleClick={() =>
                                        history.push(
                                            `/vehicles/viewContract/${row.VehicleSaleId}`
                                        )
                                    }
                                >
                                    {renderVehicleStatus(row)}
                                </DataCellColoured>
                            ) : (
                                <DataCell>{renderVehicleStatus(row)}</DataCell>
                            )}
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Dialog
                open={isEmpty}
                onClose={() => {
                    setIsEmpty(false);
                    setEmptyField('');
                }}
                maxWidth="md"
                fullWidth
            >
                {emptyField === 'Vehicle' ? (
                    <EmptyVehicleTableDialogContent
                        setIsEmpty={setIsEmpty}
                        setEmptyField={setEmptyField}
                    />
                ) : null}
            </Dialog>

            <Dialog
                open={!isEmpty && emptyField.length > 0}
                onClose={() => setEmptyField('')}
                maxWidth="xl"
                fullWidth
            >
                {emptyField === 'Vehicle' ? (
                    <ImportVehicleCSVContent showSnackbar={showSnackbar} />
                ) : null}
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(VehicleTable);
