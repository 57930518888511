// IMPORTS
import { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import BookingCounter from './components/BookingCounter';
import BookingRevenueOverview from './components/BookingRevenueOverview';
import MonthlyMetricsComparisonTable from '../../global/dashboard/MonthlyMetricsComparisonTable';
import BookingBreakdownTable from './components/BookingBreakdownTable';
import getDashboardData from '../../global/dashboard/getDashboardData';
import SiteAndDateHeader from '../../global/dashboard/SiteAndDateHeader';
import Paper from '../../global/Paper';
// LOGIC
import { CurrencyFormatter } from '../../global/logic/Formatters';
import CalculateBookingRevenue from './logic/CalculateBookingRevenue';
import {
    getComparisonDates,
    getDefaultDateRange
} from '../../global/dashboard/getMonthDatesAndBusinessDays';
// INTERFACES
import { DashboardService } from '../../global/dashboard/interface';
import { Site } from '../../global/interfaces/GeneralInterface';
import { Dayjs } from 'dayjs';

const ServiceDashboard = () => {
    const { firstDayOfCurrentMonth, today } = getDefaultDateRange();

    // loading state
    const [isLoading, setIsLoading] = useState<Boolean>(true);

    // header: type; date; site
    const [selectedSite, setSelectedSite] = useState<Site[]>([]);
    const [compareType, setCompareType] = useState<'Month' | 'Year'>('Month');
    const [selectedStartDate, setSelectedStartDate] = useState<Dayjs>(
        firstDayOfCurrentMonth
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Dayjs>(today);

    // get corresponding compareStartDate and compareEndDate based on compareType and selectedStartDate and selectedEndDate
    const { compareStartDate, compareEndDate } = useMemo(() => {
        return getComparisonDates(
            compareType,
            selectedStartDate,
            selectedEndDate
        );
    }, [compareType, selectedStartDate, selectedEndDate]);

    // bookings data
    const [bookingsSelectedMonth, setBookingsSelectedMonth] = useState<
        DashboardService[]
    >([]);
    const [bookingsComparedMonth, setBookingsComparedMonth] = useState<
        DashboardService[]
    >([]);

    // fetch bookings data in date range
    const fetchBookingsInDateRange = async () => {
        try {
            setIsLoading(true);
            if (
                selectedSite.length > 0 &&
                selectedStartDate.isValid() &&
                selectedEndDate.isValid() &&
                selectedStartDate.isBefore(selectedEndDate)
            ) {
                const result = await getDashboardData({
                    type: 'bookings',
                    startDate: selectedStartDate.toString(),
                    endDate: selectedEndDate.toString(),
                    selectedSite
                });

                setBookingsSelectedMonth(result);

                const comparedResult = await getDashboardData({
                    type: 'bookings',
                    startDate: compareStartDate.toString(),
                    endDate: compareEndDate.toString(),
                    selectedSite
                });
                setBookingsComparedMonth(comparedResult);
            }
        } catch (error) {
            console.error('Error fetching booking data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchBookingsInDateRange();
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line
    }, [
        selectedSite,
        selectedStartDate,
        selectedEndDate,
        compareType,
        compareStartDate,
        compareEndDate
    ]);

    const {
        incompleteServiceData,
        completeServiceData,
        finalisedServiceData,
        notFullyPaidServicesData,
        totalFinalisedRevenue: selectedMonthRevenue,
        totalFinalisedCost: selectedMonthCost
    } = CalculateBookingRevenue(bookingsSelectedMonth);

    const {
        completeServiceData: comparedMonthCompleteServiceData,
        finalisedServiceData: comparedMonthFinalisedServiceData,
        totalFinalisedRevenue: comparedMonthRevenue,
        totalFinalisedCost: comparedMonthCost
    } = CalculateBookingRevenue(bookingsComparedMonth);

    // prepare data for MonthlyMetricsComparisonTable
    const RevenueComparisonData = {
        'Financial Comparison': [
            {
                id: 1,
                label: 'Finalised Revenue',
                selectedMonth: CurrencyFormatter(selectedMonthRevenue),
                comparedMonth: CurrencyFormatter(comparedMonthRevenue),
                difference: CurrencyFormatter(
                    selectedMonthRevenue - comparedMonthRevenue
                )
            },
            {
                id: 2,
                label: 'Finalised Cost',
                selectedMonth: CurrencyFormatter(selectedMonthCost),
                comparedMonth: CurrencyFormatter(comparedMonthCost),
                difference: CurrencyFormatter(
                    selectedMonthCost - comparedMonthCost
                )
            },
            {
                id: 3,
                label: 'Gross Profit',
                selectedMonth: CurrencyFormatter(
                    selectedMonthRevenue - selectedMonthCost
                ),
                comparedMonth: CurrencyFormatter(
                    comparedMonthRevenue - comparedMonthCost
                ),
                difference: CurrencyFormatter(
                    selectedMonthRevenue -
                        selectedMonthCost -
                        (comparedMonthRevenue - comparedMonthCost)
                )
            }
        ]
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Dashboard</Typography>
            <br />
            <SiteAndDateHeader
                department="service"
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                compareType={compareType}
                setCompareType={setCompareType}
            />
            <br />
            {isLoading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : (
                <>
                    <BookingRevenueOverview
                        selectedMonthFinalisedRevenue={selectedMonthRevenue}
                        selectedMonthFinalisedCost={selectedMonthCost}
                        comparedMonthFinalisedRevenue={comparedMonthRevenue}
                        comparedMonthFinalisedCost={comparedMonthCost}
                        compareType={compareType}
                        compareStartDate={compareStartDate}
                        compareEndDate={compareEndDate}
                    />
                    <br />
                    <BookingCounter
                        incompleteServiceData={incompleteServiceData}
                        completeServiceData={completeServiceData}
                        finalisedServiceData={finalisedServiceData}
                        notFullyPaidServicesData={notFullyPaidServicesData}
                        comparedMonthCompleteServiceData={
                            comparedMonthCompleteServiceData
                        }
                        comparedMonthFinalisedServiceData={
                            comparedMonthFinalisedServiceData
                        }
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        compareStartDate={compareStartDate}
                        compareEndDate={compareEndDate}
                    />
                    <br />
                    <MonthlyMetricsComparisonTable
                        data={RevenueComparisonData}
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        compareType={compareType}
                        compareStartDate={compareStartDate}
                        compareEndDate={compareEndDate}
                    />
                    <br />
                    <BookingBreakdownTable
                        incompleteServiceData={incompleteServiceData}
                        completeServiceData={completeServiceData}
                        finalisedServiceData={finalisedServiceData}
                        selectedMonthFinalisedRevenue={selectedMonthRevenue}
                        bookingsSelectedMonth={bookingsSelectedMonth}
                    />
                </>
            )}
        </PageWrapper>
    );
};

export default ServiceDashboard;
