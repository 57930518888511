import { Dispatch, SetStateAction } from 'react';
import { Grid, Pagination, TableRow, TextField } from '@mui/material';

// COMPONENT
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';

// INTERFACES
import {
    Model,
    Series
} from '../../../../global/interfaces/VehicleSaleInterface';

interface TempModel extends Model {
    Series: Series[];
}

interface ModelForMakeDialogContentProps {
    filterListOfModels: TempModel[];
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    filterValue: string;
    handleFilterValueChange: (value: string, type: string) => void;
}

const ModelForMakeDrawerContent = ({
    filterListOfModels,
    page,
    setPage,
    filterValue,
    handleFilterValueChange
}: ModelForMakeDialogContentProps) => {
    // Dialog state
    const rowsPerPage = 20;
    const dialogColumns = [
        { id: 0, label: 'Model' },
        { id: 1, label: 'Series' }
    ];

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const getSeries = (seriesArray) => {
        let seriesString = '';
        for (let serie of seriesArray) {
            if (seriesString.length === 0) {
                seriesString += serie.name;
            } else {
                seriesString += ' / ' + serie.name;
            }
        }
        return seriesString;
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        label="Model"
                        value={filterValue}
                        onChange={(e) =>
                            handleFilterValueChange(e.target.value, 'model')
                        }
                    />
                </Grid>
                <Grid item xs={7} textAlign="right">
                    <Pagination
                        color="primary"
                        count={
                            filterListOfModels.length <= rowsPerPage
                                ? 1
                                : Math.ceil(
                                      filterListOfModels.length / rowsPerPage
                                  )
                        }
                        page={page}
                        siblingCount={0}
                        boundaryCount={1}
                        onChange={handleChangePage}
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            marginTop: 0.5
                        }}
                    />
                </Grid>
            </Grid>
            <br />
            <DataTable columns={dialogColumns}>
                {filterListOfModels
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((model) => (
                        <TableRow>
                            <DataCell>{model.name}</DataCell>
                            <DataCell>
                                {model?.Series?.length > 0
                                    ? getSeries(model.Series)
                                    : ''}
                            </DataCell>
                        </TableRow>
                    ))}
            </DataTable>
        </>
    );
};

export default ModelForMakeDrawerContent;
