import {
    Typography,
    TableRow,
    TableCell,
    Drawer,
    Box,
    Table,
    TableHead,
    Divider
} from '@mui/material';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import { BreakdownDrawerProps } from '../../../global/dashboard/interface';

const PartSaleBreakdownDrawer = ({
    drawerData: salesData,
    drawerTitle,
    drawerOpen,
    setDrawerOpen
}: BreakdownDrawerProps) => {
    const isProformaOrder = drawerTitle === 'Proforma Order';
    const isCreditOrder = drawerTitle === 'Credit Order';
    const isSalesOrder = drawerTitle === 'Sales Order';

    return (
        <Drawer
            anchor={'right'}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            style={{ zIndex: 1200 }}
        >
            <Box sx={{ width: '40vw', padding: '20px' }} role="presentation">
                <Typography variant="h4">{drawerTitle}</Typography>
                <Box height={'10px'}></Box>
                <Divider></Divider>
                <Box height="20px"></Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="10%">
                                <strong>Order ID</strong>
                            </TableCell>
                            {isCreditOrder && (
                                <TableCell width="10%">
                                    <strong>Credit Id</strong>
                                </TableCell>
                            )}

                            <TableCell width="15%">
                                <strong>Number of Parts</strong>
                            </TableCell>
                            <TableCell
                                width="10%"
                                style={{
                                    wordWrap: 'break-word'
                                }}
                            >
                                <strong>Customer</strong>
                            </TableCell>

                            {isCreditOrder && (
                                <TableCell width="35%">
                                    <strong>Total Refund</strong>
                                </TableCell>
                            )}
                            {isSalesOrder && (
                                <TableCell width="30%">
                                    <strong>Total Revenue (Ex GST)</strong>
                                </TableCell>
                            )}

                            {isSalesOrder && (
                                <TableCell width="35%">
                                    <strong>Total Cost (Ex GST)</strong>
                                </TableCell>
                            )}

                            {isSalesOrder && (
                                <TableCell width="35%">
                                    <strong>Gross Profits</strong>
                                </TableCell>
                            )}

                            {isProformaOrder && (
                                <TableCell width="35%">
                                    <strong>Projected Revenue (Ex GST)</strong>
                                </TableCell>
                            )}

                            {isProformaOrder && (
                                <TableCell width="35%">
                                    <strong>Projected Cost (Ex GST)</strong>
                                </TableCell>
                            )}

                            {isProformaOrder && (
                                <TableCell width="35%">
                                    <strong>Projected Gross Profit</strong>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    {salesData.map((sale) => {
                        return (
                            <TableRow>
                                <TableCell
                                    className="table-link"
                                    onClick={() =>
                                        window.open(
                                            `/inventory/viewSale/${sale.OrderId}`,
                                            '_blank'
                                        )
                                    }
                                >
                                    {sale.OrderId}
                                </TableCell>
                                {isCreditOrder && (
                                    <TableCell
                                        className="table-link"
                                        onClick={() =>
                                            window.open(
                                                `/inventory/viewCredit/${sale.OrderId}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        {sale.CreditId}
                                    </TableCell>
                                )}

                                <TableCell>{sale.numberParts}</TableCell>
                                <TableCell>{sale.customerName}</TableCell>
                                {isSalesOrder && (
                                    <TableCell>
                                        {CurrencyFormatter(sale.totalSale)}
                                    </TableCell>
                                )}

                                {isSalesOrder && (
                                    <TableCell>
                                        {CurrencyFormatter(sale.totalCost)}
                                    </TableCell>
                                )}

                                {isSalesOrder && (
                                    <TableCell>
                                        {CurrencyFormatter(
                                            sale.totalSale - sale.totalCost
                                        )}
                                    </TableCell>
                                )}
                                {isCreditOrder && (
                                    <TableCell>
                                        {CurrencyFormatter(sale.totalRefund)}{' '}
                                    </TableCell>
                                )}
                                {isProformaOrder && (
                                    <TableCell>
                                        {CurrencyFormatter(
                                            sale.totalProjectedSale
                                        )}
                                    </TableCell>
                                )}
                                {isProformaOrder && (
                                    <TableCell>
                                        {CurrencyFormatter(
                                            sale.totalProjectedCost
                                        )}
                                    </TableCell>
                                )}
                                {isProformaOrder && (
                                    <TableCell>
                                        {CurrencyFormatter(
                                            sale.totalProjectedSale -
                                                sale.totalProjectedCost
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </Table>
            </Box>
        </Drawer>
    );
};

export default PartSaleBreakdownDrawer;
