import { useEffect, useState } from 'react';
// IMPORTS
import {
    Button,
    Grid,
    IconButton,
    Paper,
    Switch,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Autocomplete from '../../global/Autocomplete';
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import Drawer from '../../global/Drawer';
import CustomerTab from './components/CustomerTab';
import SiteSelector from '../../global/SiteSelector';

// LOGIC
import api from '../../../../api';
import { CurrencyFormatter, ToFixed } from '../../global/logic/Formatters';
import SubmitManualInvoice from './logic/SubmitManualInvoice';
import { withSnackbar } from '../../global/WrappingSnackbar';

// INTERFACES
import { Creditor, Customer } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Clear } from '@mui/icons-material';

const GenerateInvoice = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [body, setBody] = useState<
        [string, number, number, number, number][]
    >([['', 0, 0, 0, 0]]);
    const [text, setText] = useState<string>('');

    // Type selector
    const [creditorSelected, setCreditorSelected] = useState<boolean>(true);

    // Creditor
    const [creditorList, setCreditorList] = useState<Creditor[]>();
    const [creditorData, setCreditorData] = useState<Creditor>();

    // Customer
    const [openCustomerDrawer, setOpenCustomerDrawer] =
        useState<boolean>(false);
    const [customerData, setCustomerData] = useState<Customer>({ id: null });

    // Site
    const [selectedSiteId, setSelectedSiteId] = useState<number>();

    // Validation
    const [validationData, setValidationData] = useState<{
        customerOrCreditorError: boolean;
        siteError: boolean;
        descriptionError: boolean;
        quantityError: boolean;
        amountError: boolean;
    }>({
        customerOrCreditorError: false,
        siteError: false,
        descriptionError: false,
        quantityError: false,
        amountError: false
    });

    const columns = [
        { id: 0, label: 'Description' },
        { id: 1, label: 'Quantity', width: 100 },
        { id: 2, label: 'Amount (Ex. GST)' },
        { id: 3, label: 'GST' },
        { id: 4, label: 'Total (Inc. GST)' },
        { id: 5, label: '' }
    ];

    useEffect(() => {
        api.get('/accounts/supplier').then((res) => {
            setCreditorList(res.data);
        });
    }, []);

    const addLine = () => {
        let currentBody = JSON.parse(JSON.stringify(body));
        currentBody.push(['', 0, 0, 0, 0]);
        setBody(currentBody);
    };

    const deleteLine = (index) => {
        let currentBody = JSON.parse(JSON.stringify(body));
        currentBody.splice(index, 1);
        setBody(currentBody);
    };

    const handleLineChange = (newValue, lineIndex, boxIndex) => {
        let currentBody = JSON.parse(JSON.stringify(body));
        currentBody[lineIndex][boxIndex] =
            boxIndex === 0 ? newValue : parseFloat(newValue);

        if (currentBody[lineIndex][1] > 0 && currentBody[lineIndex][2] > 0) {
            currentBody[lineIndex][3] = ToFixed(
                currentBody[lineIndex][1] * currentBody[lineIndex][2] * 0.1
            );
            currentBody[lineIndex][4] = ToFixed(
                currentBody[lineIndex][1] * currentBody[lineIndex][2] * 1.1
            );
        }
        setBody(currentBody);
    };

    return (
        <>
            <PageWrapper>
                <Typography variant="h4">Generate Invoice</Typography>
                <br />
                <Grid container spacing={2}>
                    <Grid
                        item
                        textAlign="center"
                        sx={{ margin: 'auto' }}
                        xs={2}
                    >
                        <Paper>
                            <Typography variant="caption">Customer</Typography>
                            <Switch
                                checked={creditorSelected}
                                onChange={(e) => {
                                    setCreditorSelected(e.target.checked);
                                    if (e.target.checked) {
                                        setCustomerData({ id: null });
                                    } else {
                                        setCreditorData({ id: null });
                                    }
                                }}
                            />
                            <Typography variant="caption">Creditor</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={7}>
                        <Paper>
                            {creditorSelected ? (
                                <Autocomplete
                                    options={creditorList}
                                    isDisabled={customerData?.id > 0}
                                    useTwoOptionLabels={false}
                                    isError={
                                        validationData.customerOrCreditorError
                                    }
                                    size="small"
                                    primaryOptionLabel="name"
                                    textfieldLabel="Creditor"
                                    selectedValue={creditorData}
                                    handleSelectedValueChange={(newValue) => {
                                        if (newValue == null) {
                                            setCreditorData(undefined);
                                        } else {
                                            setCreditorData(newValue);
                                        }
                                    }}
                                    handleInputValueChange={() => null}
                                />
                            ) : (
                                <>
                                    <Button
                                        variant="outlined"
                                        onClick={() =>
                                            setOpenCustomerDrawer(true)
                                        }
                                        sx={{
                                            color: validationData.customerOrCreditorError
                                                ? 'red'
                                                : '',
                                            borderColor:
                                                validationData.customerOrCreditorError
                                                    ? 'red'
                                                    : ''
                                        }}
                                    >
                                        Select Customer
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;
                                    <Typography display={'inline'}>
                                        Selected Customer:{' '}
                                        {customerData?.firstName}{' '}
                                        {customerData?.lastName}
                                    </Typography>
                                </>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper>
                            <SiteSelector
                                label="Site"
                                variant="outlined"
                                fullWidth
                                size="small"
                                error={validationData.siteError}
                                disabled={false}
                                viewPartUI={false}
                                selectedSite={selectedSiteId}
                                setSelectedSite={setSelectedSiteId}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <br />

                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                        <Paper>
                            <DataTable columns={columns}>
                                {body.map((line, index) => (
                                    <TableRow>
                                        <DataCell>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                value={line[0]}
                                                error={
                                                    validationData.descriptionError
                                                }
                                                onChange={(e) => {
                                                    handleLineChange(
                                                        e.target.value,
                                                        index,
                                                        0
                                                    );
                                                }}
                                            />
                                        </DataCell>
                                        <DataCell>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                value={line[1]}
                                                error={
                                                    validationData.quantityError
                                                }
                                                type="number"
                                                onChange={(e) => {
                                                    handleLineChange(
                                                        e.target.value,
                                                        index,
                                                        1
                                                    );
                                                }}
                                            />
                                        </DataCell>
                                        <DataCell>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                value={line[2]}
                                                error={
                                                    validationData.amountError
                                                }
                                                type="number"
                                                onChange={(e) => {
                                                    handleLineChange(
                                                        e.target.value,
                                                        index,
                                                        2
                                                    );
                                                }}
                                            />
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(line[3])}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(line[4])}
                                        </DataCell>
                                        <DataCell>
                                            <IconButton
                                                onClick={() =>
                                                    deleteLine(index)
                                                }
                                            >
                                                <Clear />
                                            </IconButton>
                                        </DataCell>
                                    </TableRow>
                                ))}
                            </DataTable>
                            <Button
                                variant="outlined"
                                sx={{ marginTop: 0.5, marginBottom: 0.5 }}
                                onClick={() => addLine()}
                            >
                                Add Line
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper>
                            <TextField
                                fullWidth
                                multiline
                                value={text}
                                placeholder="Text that will appears at the bottom of the invoice (not required)"
                                onChange={(e) => {
                                    setText(e.target.value);
                                }}
                            />
                        </Paper>
                    </Grid>
                    {creditorData?.id || customerData?.id ? (
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    SubmitManualInvoice(
                                        body,
                                        text,
                                        creditorData,
                                        customerData,
                                        selectedSiteId,
                                        setValidationData,
                                        showSnackbar
                                    )
                                }
                            >
                                Submit Invoice
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>

                <Drawer
                    openDrawer={openCustomerDrawer}
                    setOpenDrawer={setOpenCustomerDrawer}
                    title={'Select Customer'}
                    subTitle={''}
                    width="40vw"
                >
                    <CustomerTab
                        customerDetails={customerData}
                        setCustomerDetails={setCustomerData}
                        disabled={false}
                    />
                </Drawer>
            </PageWrapper>
        </>
    );
};

export default withSnackbar(GenerateInvoice);
