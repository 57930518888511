import {
    CurrencyFormatter,
    ToFixed
} from '../../../../../../global/logic/Formatters';
import CreateNewPage from './CreateNewPage';

const getTotal = (array) => {
    let total = 0;
    if (!array) return 0;

    for (let line of array) {
        total += line.amount;
    }
    return total;
};

const getTotalOfCategory = (array) => {
    let total = 0;
    if (array) {
        for (let key of Object.keys(array)) {
            for (let line of array[key]) {
                total += line.amount;
            }
        }
    }
    return total;
};

interface CategoryLineInterface {
    (
        doc: any,
        params: any,
        totalSale: number,
        ytdTotalSale: number,
        previousTotalSale: number,
        lastYearYtdTotalSale: number,
        type: string,
        current: any,
        previousYear: any,
        ytd: any,
        previousYearYtd: any,
        y: number
    ): [
        number,
        {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        }
    ];
}

const CategoryLine: CategoryLineInterface = (
    doc,
    params,
    totalSale,
    previousTotalSale,
    ytdTotalSale,
    lastYearYtdTotalSale,
    type,
    current,
    previousYear,
    ytd,
    previousYearYtd,
    y
) => {
    let newY = y;

    // Check that the lines we are about to create won't overflow on the bottom margin
    // Otherwise, create a new page
    if (y + 4 + Object.keys(current ?? {}).length * 4 > 254) {
        CreateNewPage(doc, params);
        newY = 30;
    } else if (y + 4 + Object.keys(previousYear ?? {}).length * 4 > 254) {
        CreateNewPage(doc, params);
        newY = 30;
    } else if (y + 4 + Object.keys(ytd ?? {}).length * 4 > 254) {
        CreateNewPage(doc, params);
        newY = 30;
    } else if (y + 4 + Object.keys(previousYearYtd ?? {}).length * 4 > 254) {
        CreateNewPage(doc, params);
        newY = 30;
    }

    // Get an array of ALL the keys from every data object
    // In case there was an account with a transaction in one date range but not the others
    let listOfKeys = [];
    for (let key of Object.keys(current ?? {})) {
        if (!listOfKeys.includes(key)) listOfKeys.push(key);
    }
    for (let key of Object.keys(ytd ?? {})) {
        if (!listOfKeys.includes(key)) listOfKeys.push(key);
    }
    for (let key of Object.keys(previousYear ?? {})) {
        if (!listOfKeys.includes(key)) listOfKeys.push(key);
    }
    for (let key of Object.keys(previousYearYtd ?? {})) {
        if (!listOfKeys.includes(key)) listOfKeys.push(key);
    }

    newY += 4;

    doc.setFont('helvetica', 'bold').text(type, 10, newY);
    // LOOP through the keys
    for (let key of listOfKeys) {
        newY += 4;
        doc.setFont('helvetica', 'normal');
        if (
            getTotalOfCategory(current) ||
            getTotalOfCategory(ytd) ||
            getTotalOfCategory(previousYear) ||
            getTotalOfCategory(previousYearYtd)
        ) {
            doc.text(
                key.length > 27
                    ? key.substring(0, 27).toUpperCase() + '..'
                    : key.toUpperCase(),
                10,
                newY
            );
        }
        doc.text(CurrencyFormatter(getTotal(current?.[key])), 74, newY, {
            align: 'right'
        });
        if (totalSale !== 0) {
            doc.text(
                ToFixed(
                    (getTotal(current?.[key]) / totalSale) * 100
                ).toString() + '%',
                87,
                newY,
                { align: 'right' }
            );
        }

        doc.text(CurrencyFormatter(getTotal(ytd?.[key])), 107, newY, {
            align: 'right'
        });
        if (ytdTotalSale !== 0) {
            doc.text(
                ToFixed(
                    (getTotal(ytd?.[key]) / ytdTotalSale) * 100
                ).toString() + '%',
                120,
                newY,
                { align: 'right' }
            );
        }

        doc.text(CurrencyFormatter(getTotal(previousYear?.[key])), 140, newY, {
            align: 'right'
        });
        if (previousTotalSale !== 0) {
            doc.text(
                ToFixed(
                    (getTotal(previousYear?.[key]) / previousTotalSale) * 100
                ).toString() + '%',
                153,
                newY,
                { align: 'right' }
            );
        }

        doc.text(
            CurrencyFormatter(getTotal(previousYearYtd?.[key])),
            173,
            newY,
            { align: 'right' }
        );
        if (lastYearYtdTotalSale !== 0) {
            doc.text(
                ToFixed(
                    (getTotal(previousYearYtd?.[key]) / lastYearYtdTotalSale) *
                        100
                ).toString() + '%',
                186,
                newY,
                { align: 'right' }
            );
        }

        doc.text(
            Number.isFinite(
                ToFixed(
                    (getTotal(ytd?.[key]) / getTotal(previousYearYtd?.[key]) -
                        1) *
                        100
                )
            )
                ? ToFixed(
                      (getTotal(ytd?.[key]) / getTotal(previousYearYtd?.[key]) -
                          1) *
                          100
                  ).toString() + '%'
                : '',
            200,
            newY,
            { align: 'right' }
        );
    }
    newY += 4;

    // Display a total line if there is any line written before
    if (
        getTotalOfCategory(current) ||
        getTotalOfCategory(ytd) ||
        getTotalOfCategory(previousYear) ||
        getTotalOfCategory(previousYearYtd)
    ) {
        doc.text('Total', 54, newY, { align: 'right' });
    }

    let totals = { current: 0, ytd: 0, previousYear: 0, previousYearYtd: 0 };
    // Calculate total for each date range, and update the totals variable
    if (getTotalOfCategory(current)) {
        doc.text(CurrencyFormatter(getTotalOfCategory(current)), 74, newY, {
            align: 'right'
        });
        totals.current = getTotalOfCategory(current);
        if (totalSale !== 0) {
            doc.text(
                ToFixed(
                    (getTotalOfCategory(current) / totalSale) * 100
                ).toString() + '%',
                87,
                newY,
                { align: 'right' }
            );
        }
    }
    if (getTotalOfCategory(ytd)) {
        doc.text(CurrencyFormatter(getTotalOfCategory(ytd)), 107, newY, {
            align: 'right'
        });
        totals.ytd = getTotalOfCategory(ytd);
        if (ytdTotalSale !== 0) {
            doc.text(
                ToFixed(
                    (getTotalOfCategory(ytd) / ytdTotalSale) * 100
                ).toString() + '%',
                120,
                newY,
                { align: 'right' }
            );
        }
    }
    if (getTotalOfCategory(previousYear)) {
        doc.text(
            CurrencyFormatter(getTotalOfCategory(previousYear)),
            140,
            newY,
            { align: 'right' }
        );
        totals.previousYear = getTotalOfCategory(previousYear);
        if (previousTotalSale !== 0) {
            doc.text(
                ToFixed(
                    (getTotalOfCategory(previousYear) / previousTotalSale) * 100
                ).toString() + '%',
                153,
                newY,
                { align: 'right' }
            );
        }
    }
    if (getTotalOfCategory(previousYearYtd)) {
        doc.text(
            CurrencyFormatter(getTotalOfCategory(previousYearYtd)),
            173,
            newY,
            { align: 'right' }
        );
        totals.previousYearYtd = getTotalOfCategory(previousYearYtd);
        if (lastYearYtdTotalSale !== 0) {
            doc.text(
                ToFixed(
                    (getTotalOfCategory(previousYearYtd) /
                        lastYearYtdTotalSale) *
                        100
                ).toString() + '%',
                186,
                newY,
                { align: 'right' }
            );
        }
    }

    return [newY + 4, totals];
};

export default CategoryLine;
