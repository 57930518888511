import { Dispatch, SetStateAction } from 'react';
import { PaymentLine } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { PartTotals, TotalPaid } from './TotalFunctions';
import { Order } from '../../../global/interfaces/PartsInterface';
import api from '../../../../../api';

const HandlePayCSM = (
    id: number,
    paymentLines: PaymentLine[],
    linkedSales: Order[],
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    let totalPaid = TotalPaid(paymentLines);
    let csmTotal = PartTotals(linkedSales).totalPartPrice;

    // If there isn't enough money to cover the csm total alert the user
    if (csmTotal > totalPaid) {
        showSnackbar(
            'Not enough payment to cover Add-On total.',
            'Add-Ons must be paid in full.',
            'error'
        );
        setButtonLoading(false);
        return;
    } else {
        api.put(`handlePayCSM/${id}`).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Add-Ons have been fully paid.',
                    'The page will now refresh.'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
                setButtonLoading(false);
            }
        });
    }
};

export default HandlePayCSM;
