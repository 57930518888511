const BlankCreditBox = (doc) => {
  doc.setLineWidth(0.2).setDrawColor('black');
  doc.rect(107, 135, 93, 48); // The box around credit required
  doc
    .setFont('helvetica', 'bold')
    .setFontSize(9)
    .text('DOES THE PURCHASER REQUIRE CREDIT?', 109, 139);
  doc
    .setFont('helvetica', 'normal')
    .setFontSize(7)
    .text('(Tick as appropriate)', 199, 139, { align: 'right' });
  doc.setFontSize(6.5);

  doc.rect(110, 142, 2, 2); // The checkbox for the first point
  doc.text(
    'The Purchaser does not require credit for payment of the motor vehicle.',
    113,
    144
  );

  doc.rect(110, 146, 2, 2); // The checkbox for the second point
  doc.text('The Purchaser will arrange for credit.', 113, 148);

  doc.rect(110, 150, 2, 2); // The checkbox for the third point
  doc.text(
    'The Purchaser authorises the Dealer to arrange credit on behalf of the Purchaser.',
    113,
    152
  );

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(7)
    .text('SIGNATURE OF', 110, 157);
  doc.text('THE PURCHASER:', 110, 160);
  doc.line(107, 163, 200, 163); // Horizontal line below purchaser signature

  doc.setFont('helvetica', 'bold').text('CREDIT PROVIDER', 108, 166);
  doc.line(155, 163, 155, 183); // Vertical line between credit and insurance provider
  doc.text('INSURANCE PROVIDER', 156, 166);
  doc.line(107, 168, 200, 168); // Horizontal line below credit and insurance provider

  doc.setFontSize(6.5).text('CONTACT:', 108, 172).line(121, 172, 200, 172);
  doc.text('PHONE NO:', 108, 178).line(123, 178, 155, 178);
  doc.text('PREMIUM $:', 156, 178).line(171, 178, 200, 178);
};

export default BlankCreditBox;
