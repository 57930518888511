import api from '../../../../../../api';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const DeleteTransactionLine = (
    id: number,
    transactionFiles,
    setTransactionFiles,
    selectedIndex,
    showSnackar: showSnackbar
) => {
    api.delete(`deleteTransactionLine/${id}`).then((res) => {
        if (res.status === 200) {
            showSnackar('Transaction line deleted.', '');
            let currentTransactionFiles = [...transactionFiles];
            currentTransactionFiles[selectedIndex].transactions =
                currentTransactionFiles[selectedIndex].transactions.filter(
                    (x) => x.id !== id
                );
            setTransactionFiles(currentTransactionFiles);
        } else {
            showSnackar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default DeleteTransactionLine;
