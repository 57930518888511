import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Creditor } from "../../../global/interfaces/GeneralInterface";
import { BodyType, DriveType, FuelType, Make, Model, Series, Vehicle } from "../../../global/interfaces/VehicleSaleInterface";

interface FilterListParams {
    nextValue: Model;
    specificLists: { Make: Make[]; Model: Model[]; Series: Series[]; FuelType: FuelType[]; Creditors: Creditor[]; DriveType: DriveType[]; BodyType: BodyType[] };
    setSpecificLists: Dispatch<SetStateAction<{ Make: Make[]; Model: Model[]; Series: Series[]; FuelType: FuelType[]; Creditors: Creditor[]; DriveType: DriveType[]; BodyType: BodyType[] }>>;
    newVehicle: Vehicle
    setNewVehicle: Dispatch<SetStateAction<Vehicle>>;
    specificInputs: { makeInput: string; modelInput: string; seriesInput: string; fuelTypeInput: string };
    setSpecificInputs: Dispatch<SetStateAction<{ makeInput: string; modelInput: string; seriesInput: string; fuelTypeInput: string }>>;
}

const FilterModelList = (params: FilterListParams) => {
    if (params.nextValue == null) {
        params.setNewVehicle({ ...params.newVehicle, Model: null, Series: null });
        params.setSpecificInputs({ ...params.specificInputs, modelInput: "", seriesInput: "" });
        return;
    }
    api.get(`vehicleSpecificsListsNextGen?type=model&id=${params?.nextValue?.id || 0}`).then((res) => {
        params.setSpecificLists({ ...params.specificLists, Series: res.data });
    });
    params.setNewVehicle({ ...params.newVehicle, Model: params.nextValue, Series: null });
    params.setSpecificInputs({ ...params.specificInputs, seriesInput: "" });
};

export default FilterModelList;
