// IMPORTS
import { useState, useEffect } from 'react';
import { Typography, Divider, TableRow } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { Service } from '../../../global/interfaces/ServiceInterface';
import { Order, Invoice } from '../../../global/interfaces/PartsInterface';
import GetAddedCostsTotals from '../logic/GetAddedCostsTotals';
import DataCell from '../../../global/tableComponents/DataCell';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import DataTable from '../../../global/tableComponents/DataTable';

interface VehicleAddedCosts {
    services: Service[];
    sales: Order[];
    invoices: Invoice[];
}

interface TotalsProps {
    vehicleAddedCosts: VehicleAddedCosts;
    vehicle: Vehicle;
}

const Totals = ({ vehicleAddedCosts, vehicle }: TotalsProps) => {
    const [addedCostTotals, setAddedCostTotals] = useState({
        services: 0,
        sales: 0,
        invoices: 0
    });

    useEffect(() => {
        GetAddedCostsTotals(vehicle, vehicleAddedCosts, setAddedCostTotals);
        // eslint-disable-next-line
    }, [vehicleAddedCosts]);

    return (
        <Paper>
            <Typography variant="h5" sx={{ fontSize: '25px' }}>
                Total
            </Typography>
            <br />
            <Divider />
            <DataTable columns={[]}>
                <TableRow>
                    <DataCell>Admin Load</DataCell>
                    <DataCell>
                        {CurrencyFormatter(vehicle.adminLoadCost)}
                    </DataCell>
                </TableRow>
                <TableRow>
                    <DataCell>Services</DataCell>
                    <DataCell>
                        {CurrencyFormatter(addedCostTotals.services)}
                    </DataCell>
                </TableRow>
                <TableRow>
                    <DataCell>Sales</DataCell>
                    <DataCell>
                        {CurrencyFormatter(addedCostTotals.sales)}
                    </DataCell>
                </TableRow>
                <TableRow>
                    <DataCell>Invoices</DataCell>
                    <DataCell>
                        {CurrencyFormatter(addedCostTotals.invoices)}
                    </DataCell>
                </TableRow>
                <TableRow>
                    <DataCell>
                        <b>Total</b>
                    </DataCell>
                    <DataCell>
                        <b>
                            {CurrencyFormatter(
                                parseFloat(vehicle.adminLoadCost) +
                                    addedCostTotals.services +
                                    addedCostTotals.sales +
                                    addedCostTotals.invoices
                            )}
                        </b>
                    </DataCell>
                </TableRow>
            </DataTable>
        </Paper>
    );
};

export default Totals;
