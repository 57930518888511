const BlankTableTotals = (doc, saleDetails, lastYcoordinates) => {
  doc.setFont('helvetica', 'normal').setFontSize(9);

  saleDetails.registrationLength === 'None' || !saleDetails.includesOnRoadCosts
    ? doc.text('Registration:', 11, lastYcoordinates + 2)
    : doc.text(
        saleDetails.registrationLength +
          ' ' +
          saleDetails.registrationType +
          ' ' +
          saleDetails.registrationSeats +
          ' Seats:',
        11,
        lastYcoordinates + 2
      );
  doc.text('CTP', '11', lastYcoordinates + 6);
  doc.text('Plate Transfer:', 11, lastYcoordinates + 10);
  doc.text('Stamp Duty:', 11, lastYcoordinates + 14);
  doc.text('Delivery:', 11, lastYcoordinates + 18);
  doc.text('Dealer Origination Fee:', 11, lastYcoordinates + 22);
  doc.text('Warranty:', 11, lastYcoordinates + 26);
  doc.text('Less Discount:', 11, lastYcoordinates + 30);

  doc.setFont('helvetica', 'bold').setFontSize(9).setTextColor(0, 0, 0);
  doc.text('Total Settlement EX-GST:', 11, lastYcoordinates + 34);
  doc.text('Total Settlement Price:', 11, lastYcoordinates + 38);

  doc.setFont('helvetica', 'normal').setFontSize(9).setTextColor(30);
  doc.text('Trade-In Allowance:', 11, lastYcoordinates + 42);

  doc.setFont('helvetica', 'bold').setFontSize(9).setTextColor(0, 0, 0);
  doc.text('Total Payable Amount:', 11, lastYcoordinates + 48);

  // Banking Line
  doc.setFont('helvetica', 'bold').setFontSize(9).setTextColor(0, 0, 0);
  doc.text('Total Paid:', 11, lastYcoordinates + 52);

  doc.setFont('helvetica', 'normal').setFontSize(9).setTextColor(30);
  doc.text('Balance Payable:', 11, lastYcoordinates + 56);

  doc.setFont('helvetica', 'bold').setFontSize(9).setTextColor(0, 0, 0);
  doc.text('See Appendix Two For Details', 11, lastYcoordinates + 60);
  doc.text('Bank Details', 11, lastYcoordinates + 64);

  doc.setFont('helvetica', 'normal').setFontSize(9).setTextColor(30);
  doc.text('Name: ', 11, lastYcoordinates + 68);
  doc.text('BSB:', 11, lastYcoordinates + 72);
  doc.text('Account Number:', 11, lastYcoordinates + 76);
};

export default BlankTableTotals;
