// IMPORTS
import { useState } from 'react';
import { Typography, TableRow, Chip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import TemplateTableFilter from './components/TemplateTableFilter';
// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';

interface Result {
  id: number;
  subject: string;
  department: string;
  messageBody: string;
  type: string;
}

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = { templateFilter: { department: [], type: [] } };
const clearedSort = ['subject', 'ASC'];

const TemplateTable = () => {
  const history = useHistory();
  const [filter, setFilter] = useState<Filter>(
    JSON.parse(JSON.stringify(clearedFilter))
  );
  const [sort, setSort] = useState(clearedSort);
  const [resultsList, setResultsList] = useState<Result[]>([]);

  // Options for the speed dial
  const actionsList = [
    {
      icon: <Add />,
      name: 'Add Template',
      onClick: () => history.push('/addTemplate')
    }
  ];

  // Columns for the data table
  const columns = [
    { id: 0, label: 'Title / Subject', width: 200 },
    { id: 1, label: 'Department', width: 200 },
    { id: 2, label: 'Message Preview', width: 300 },
    { id: 3, label: 'Template Type', width: 100 }
  ];

  // Combines the selected filters and generates a string to put in the api request query
  const handleRequestCreate = () => {
    let department =
      filter.templateFilter.department.length > 0
        ? ConvertArrayToFilter({
            array: filter.templateFilter.department,
            filterStart: '&department=',
            selector: 'value'
          })
        : '';
    let type =
      filter.templateFilter.type.length > 0
        ? ConvertArrayToFilter({
            array: filter.templateFilter.type,
            filterStart: '&type=',
            selector: 'value'
          })
        : '';

    let apiString = `${department}${type}`;
    return apiString;
  };

  // Checks if any options for filter have been selected and return result
  const isFilterActive = () => {
    let isActive = false;

    if (
      filter.templateFilter.department.length > 0 ||
      filter.templateFilter.type.length > 0
    ) {
      isActive = true;
    }

    return isActive;
  };

  return (
    <PageWrapper>
      <Typography variant="h4">Email & Text Templates</Typography>
      <br />
      <TableSearch
        searchTitle="Search Templates"
        showFilter={true}
        showPagination={true}
        showPaper={true}
        filter={filter}
        setFilter={setFilter}
        clearedFilter={clearedFilter}
        sort={sort}
        handleRequestCreate={handleRequestCreate}
        route="templateSearchNextGen"
        setResultsList={setResultsList}
        isFilterActive={isFilterActive}
      >
        <TemplateTableFilter
          filter={filter}
          setFilter={setFilter}
          isFilterActive={isFilterActive}
        />
      </TableSearch>

      <Paper>
        <DataTable
          columns={columns}
          sort={sort}
          setSort={setSort}
          showSpeedDial={true}
          actionsList={actionsList}
        >
          {resultsList.map((row) => (
            <TableRow key={row.id}>
              <DataCellColoured
                handleClick={() =>
                  history.push(`/viewTemplate/${row.id}?type=${row.type}`)
                }
              >
                {row.subject}
              </DataCellColoured>
              <DataCell>{row.department}</DataCell>
              <DataCell>
                {row.messageBody
                  ? row.messageBody.substring(0, 50) + '...'
                  : ''}
              </DataCell>
              <DataCell>
                <Chip
                  label={row.type}
                  variant="outlined"
                  color={row.type === 'Email' ? 'warning' : 'success'}
                />
              </DataCell>
            </TableRow>
          ))}
        </DataTable>
      </Paper>
    </PageWrapper>
  );
};

export default TemplateTable;
