// IMPORTS
import { Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { ArrowRightAlt } from '@mui/icons-material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';

// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';

// INTERFACE
import { Site } from '../../../global/interfaces/GeneralInterface';

interface ParametersSelectionProps {
    selectedSite: Site[];
    setSelectedSite: Dispatch<SetStateAction<Site[]>>;
    queryDate: { startDate: Dayjs; endDate: Dayjs };
    setQueryDate: Dispatch<
        SetStateAction<{ startDate: Dayjs; endDate: Dayjs }>
    >;
    GenerateReport: () => void;
    PrintPNL?: () => void;
    includeAllOption: boolean;
    vehicleValues?: {
        adminLoadCost: number;
        adminLoadCounter: number;
        dofCounter: number;
        dofPrice: number;
        newCost: number;
        newRevenue: number;
        newTotal: number;
        usedCost: number;
        usedRevenue: number;
        usedTotal: number;
        warrantyCost: number;
        warrantyCounter: number;
        warrantyPrice: number;
    };
    partValues?: {
        onlineCost: number;
        onlineRevenue: number;
        onlineTotal: number;
        partCost: number;
        partRevenue: number;
        partTotal: number;
        saleCost: number;
        saleRevenue: number;
        saleTotal: number;
        serviceCost: number;
        serviceRevenue: number;
        serviceTotal: number;
        vehicleCost: number;
        vehicleRevenue: number;
        vehicleTotal: number;
    };
    serviceValues?: {
        insuranceCost: number;
        insuranceRevenue: number;
        insuranceTotal: number;
        internalCost: number;
        internalRevenue: number;
        internalTotal: number;
        retailCost: number;
        retailRevenue: number;
        retailTotal: number;
        warrantyCost: number;
        warrantyRevenue: number;
        warrantyTotal: number;
    };
    expenses?: {
        [key: string]: {
            [key: string]: {
                accountId: number;
                accountName: string;
                amount: number;
                date: string;
                description: string;
                reconciled: boolean;
                url: string;
            }[];
        };
    };
    rebates?: {
        [key: string]: {
            [key: string]: {
                accountId: number;
                accountName: string;
                amount: number;
                date: string;
                description: string;
                reconciled: boolean;
                url: string;
            }[];
        };
    };
    buttonLoading?: boolean;
    balanceSheetInformation?: boolean;
    printPNLOption?: boolean;
    gstInformation?: {
        description: string;
        total: number;
        transactions: any[];
    }[];
}

const ParametersSelection = (props: ParametersSelectionProps) => {
    const [siteList, setSiteList] = useState<Site[]>([]);
    const [unalteredSiteList, setUnalteredSiteList] = useState<Site[]>([]);

    useEffect(() => {
        GetAllSites(setSiteList);
        GetAllSites(setUnalteredSiteList);
    }, []);

    return (
        <Paper className="paper-padding">
            <Grid container spacing={2}>
                <Grid
                    xs={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <AutocompleteMultiple
                        options={siteList}
                        size="medium"
                        fullWidth={true}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Select Site(s)"
                        selectedValues={props.selectedSite}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = unalteredSiteList;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.name !== val.name
                                );
                            });

                            setSiteList(currentOptions);
                            props.setSelectedSite(newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid xs={5}>
                    <Grid container spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid
                                xs={5.5}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <DatePicker
                                    label="Reporting Start Date"
                                    format="DD/MM/YYYY"
                                    value={props.queryDate.startDate}
                                    onChange={(newValue) =>
                                        props.setQueryDate({
                                            ...props.queryDate,
                                            startDate: newValue.startOf('day')
                                        })
                                    }
                                    slotProps={{
                                        textField: {
                                            error: false,
                                            InputLabelProps: { shrink: true }
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={1}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ArrowRightAlt
                                    sx={{
                                        fontSize: '40px',
                                        marginTop: 1,
                                        marginBottom: 1
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={!props?.balanceSheetInformation ? 5.5 : 12}
                            >
                                <DatePicker
                                    label="Reporting End Date"
                                    format="DD/MM/YYYY"
                                    disabled={props.queryDate.startDate == null}
                                    minDate={props.queryDate.startDate}
                                    value={props.queryDate.endDate}
                                    onChange={(newValue) =>
                                        props.setQueryDate({
                                            ...props.queryDate,
                                            endDate: newValue.endOf('day')
                                        })
                                    }
                                    slotProps={{
                                        textField: {
                                            error: false,
                                            InputLabelProps: { shrink: true }
                                        }
                                    }}
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid
                    xs={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <LoadingButton
                        loading={props.buttonLoading}
                        disabled={
                            props.selectedSite.length === 0 ||
                            props.queryDate.startDate == null ||
                            props.queryDate.endDate == null
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.GenerateReport();
                        }}
                    >
                        Generate Report
                    </LoadingButton>
                </Grid>
                <Grid
                    xs={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    {props.printPNLOption ? (
                        <LoadingButton
                            fullWidth
                            disabled={
                                props.selectedSite.length === 0 ||
                                props.queryDate.startDate == null ||
                                props.queryDate.endDate == null
                            }
                            loading={props.buttonLoading}
                            variant="contained"
                            onClick={() => {
                                props.PrintPNL();
                            }}
                        >
                            Print PNL Statement
                        </LoadingButton>
                    ) : null}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ParametersSelection;
