import api from '../../../../../../api';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const QuickUpdateShowRRP = (
    id: number,
    showRRP: boolean,
    showSnackbar: showSnackbar
) => {
    api.put(`showRRPUpdate/${id}`, { showRRP: showRRP }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Show RRP on Invoice successfully updated.',
                'The page will now refresh.'
            );
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar(
                'Could not updated sale, please contact your manager or IT department.',
                res.data,
                'error'
            );
        }
    });
};

export default QuickUpdateShowRRP;
