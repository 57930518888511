// DATA
import {
    AddAccountSteps,
    UpdateAccountSteps,
    DeleteAccountSteps,
    SearchAccountsSteps,
    ExportAccountsSteps,
    MergeAccountsSteps
} from '../../data/admin/account';

import DocumentationPage from '../../DocumentationPage';

const AccountSupportPage = () => {
    const contentSections = [
        {
            id: 'add-account',
            title: 'Add an account',
            description: 'To create a new account, follow these steps:',
            href: '/support/admin/accounts/#add-account',
            steps: AddAccountSteps
        },
        {
            id: 'update-account',
            title: 'Update an account',
            description: 'To update an existing account, follow these steps:',
            href: '/support/admin/accounts/#update-account',
            steps: UpdateAccountSteps
        },
        {
            id: 'delete-account',
            title: 'Delete an account',
            href: '/support/admin/accounts/#delete-account',
            description:
                'Deleting an account will also remove all its associated transactions, invoices, and orders. To delete an existing account, follow these steps:',
            steps: DeleteAccountSteps
        },
        {
            id: 'merge-account',
            title: 'Merge accounts',
            href: '/support/admin/accounts/#merge-accounts',
            description:
                'You can merge two accounts, the account to be merged will be deleted, but all its associated transactions, orders, invoices etc will be transferred to the remaining account. To merge existing accounts, follow these steps:',
            steps: MergeAccountsSteps
        },
        {
            id: 'search-accounts',
            title: 'Search accounts',
            href: '/support/admin/accounts/#search-accounts',
            description:
                'Currently, the system supports searching by account name, account ID, account type, and location. Filters are currently supported for Account Create Date, Account Type, and Site. To search or filter accounts, follow these steps:',
            steps: SearchAccountsSteps
        },
        {
            id: 'export-account',
            title: 'Export accounts to CSV',
            href: '/support/admin/accounts/#export-account',
            description:
                'To export all accounts as a CSV file, follow these steps:',
            steps: ExportAccountsSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Accounts"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default AccountSupportPage;
