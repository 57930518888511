// IMPORTS
import {
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    useContext
} from 'react';
import { TableRow, Chip, IconButton } from '@mui/material';
import { RadioButtonUnchecked, CheckCircle } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
import TableSearch from '../../../../global/tableComponents/TableSearch';
// LOGIC
import {
    CurrencyFormatter,
    YearFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import RoleCheck from '../../../../global/RoleCheck';
import { Site } from '../../../../global/interfaces/GeneralInterface';
import { SiteContext } from '../../../../../main/Main';

// Default values for the filter and sort for initial set state and clear filter
const clearedSort = ['id', 'DESC'];

interface VehicleFormProps {
    readOnly: boolean;
    selectedEntity: Vehicle;
    setSelectedEntity: Dispatch<SetStateAction<Vehicle>>;
    removeSoldVehicles?: boolean;
    fromService?: boolean;
    fromVehicleSale?: boolean;
}

const VehicleForm = ({
    readOnly,
    selectedEntity,
    setSelectedEntity,
    removeSoldVehicles,
    fromService = false,
    fromVehicleSale = false
}: VehicleFormProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const [resultsList, setResultsList] = useState<Vehicle[]>([]);
    const [resultsListAltered, setResultsListAltered] = useState<Vehicle[]>([]);

    // Columns for the data table
    const columns =
        siteDetails.vehicleTypeSold === 'Motorcycles'
            ? [
                  { id: 0, label: '', width: 100 },
                  { id: 1, label: 'ID', width: 100 },
                  { id: 2, label: 'Stock Number', width: 100 },
                  { id: 3, label: 'Vehicle', width: 300 },
                  { id: 4, label: 'Short VIN', width: 100 },
                  { id: 5, label: 'Location', width: 200 },
                  { id: 6, label: 'RRP', width: 100 },
                  { id: 7, label: 'Base Cost', width: 100 },
                  { id: 8, label: 'Status', width: 100 }
              ]
            : [
                  { id: 0, label: '', width: 100 },
                  { id: 1, label: 'ID', width: 100 },
                  { id: 2, label: 'Stock Number', width: 100 },
                  { id: 3, label: 'Vehicle', width: 300 },
                  { id: 4, label: 'Colour', width: 100 },
                  { id: 5, label: 'Location', width: 200 },
                  { id: 6, label: 'RRP', width: 100 },
                  { id: 7, label: 'Base Cost', width: 100 },
                  { id: 8, label: 'Status', width: 100 }
              ];

    useEffect(() => {
        if (selectedEntity?.id) {
            let singleRow = [];
            singleRow.push(selectedEntity);
            setResultsListAltered(singleRow);
        } else {
            setResultsListAltered(resultsList);
        }
    }, [selectedEntity, resultsList]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        if (removeSoldVehicles) {
            return '&sold=false';
        } else {
            return '';
        }
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Vehicles"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={clearedSort}
                handleRequestCreate={handleRequestCreate}
                route="vehicleSearchNextGen"
                setResultsList={setResultsList}
                customLimit={10}
                disableSearch={selectedEntity?.id ? true : false}
            />

            <Paper>
                <DataTable columns={columns}>
                    {resultsListAltered.map((row) => (
                        <TableRow key={row.id}>
                            <DataCell>
                                {selectedEntity?.id ? (
                                    <IconButton
                                        onClick={() => setSelectedEntity(null)}
                                        disabled={readOnly}
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => setSelectedEntity(row)}
                                        disabled={
                                            fromVehicleSale && !row.available
                                        }
                                    >
                                        <RadioButtonUnchecked />
                                    </IconButton>
                                )}
                            </DataCell>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/vehicles/view/${row.id}`,
                                        '_blank'
                                    )
                                }
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>{row.stockNumber}</DataCell>
                            <DataCell>
                                {row.condition.toUpperCase()}{' '}
                                {YearFormatter(row.year)} {row.Make?.name}{' '}
                                {row.Model?.name} {row.Series?.name}
                            </DataCell>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                                <DataCell>
                                    {row.vin ? '...' + row?.vin?.slice(-6) : ''}
                                </DataCell>
                            ) : (
                                <DataCell>{row.colour}</DataCell>
                            )}

                            <DataCell>{row.Site?.name}</DataCell>
                            <DataCell>
                                {CurrencyFormatter(row.priceRRP)}
                            </DataCell>
                            <DataCell>
                                <RoleCheck
                                    permission="sales_manager, assign_superusers"
                                    component={CurrencyFormatter(row.costPrice)}
                                />
                            </DataCell>
                            <DataCell>
                                {row.available === false &&
                                row.sold === false &&
                                row.VehicleSaleId ? (
                                    <Chip
                                        label={
                                            'Selected in Sale: ' +
                                            row.VehicleSaleId.toString()
                                        }
                                        variant="outlined"
                                        onClick={() =>
                                            window.open(
                                                '/vehicles/viewContract/' +
                                                    row.VehicleSaleId,
                                                '_blank'
                                            )
                                        }
                                        color={'warning'}
                                    />
                                ) : row.available === false &&
                                  row.sold === false &&
                                  row.TradeInId ? (
                                    <Chip
                                        label={
                                            'Trade-In in Sale : ' +
                                            row.TradeInId.toString()
                                        }
                                        variant="outlined"
                                        onClick={() =>
                                            window.open(
                                                '/vehicles/viewContract/' +
                                                    row.TradeInId,
                                                '_blank'
                                            )
                                        }
                                        color={'warning'}
                                    />
                                ) : selectedEntity?.id ? (
                                    <Chip
                                        label={'Selected'}
                                        variant="outlined"
                                        color={'success'}
                                    />
                                ) : (
                                    <Chip
                                        label={
                                            row.available
                                                ? 'Available'
                                                : row.sold
                                                  ? 'Sold'
                                                  : row.VehicleSaleId
                                                    ? 'In Sale: ' +
                                                      row.VehicleSaleId.toString()
                                                    : 'Linked to a sale'
                                        }
                                        variant="outlined"
                                        color={
                                            row.available
                                                ? 'success'
                                                : row.sold
                                                  ? 'error'
                                                  : 'warning'
                                        }
                                    />
                                )}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </>
    );
};

export default VehicleForm;
