import jsPDF from 'jspdf';
import { PrintPNLReportParams } from '../../interface/PNLInterfaces';

const PNLHeader = (doc: jsPDF, params: PrintPNLReportParams) => {
    doc.setFont('helvetica', 'bold').setFontSize(10);
    let siteString = '';
    if (params.selectedSite.length === params.siteData.length) {
        siteString = 'CONSOLIDATED';
    } else {
        for (let site of params.selectedSite) {
            siteString =
                siteString.length > 0
                    ? `${siteString},  ${site.name}`
                    : site.name;
        }
    }
    doc.text('PROFIT AND LOSS STATEMENT', 105, 10, { align: 'center' });
    doc.text(params.settingData.companyName + ' - ' + siteString, 105, 15, {
        align: 'center'
    });

    // doc.text(`${DateFormatter(params.queryDate.startDate)} - ${DateFormatter(params.queryDate.endDate)}`, 105, 20, { align: "center" });
    doc.text(
        `${params.queryDate.startDate.format('DD MMMM YYYY')} - ${params.queryDate.endDate.format('DD MMMM YYYY')}`,
        105,
        20,
        { align: 'center' }
    );
    doc.setFontSize(7);
    doc.text('THIS YEAR', 88, 25, { align: 'center' });
    doc.text('LAST YEAR', 164, 25, { align: 'center' });

    doc.text('MONTH', 65, 30, { align: 'center' });
    doc.text('%', 81.5, 30, { align: 'center' });
    doc.text('YTD', 98, 30, { align: 'center' });
    doc.text('%', 114.5, 30, { align: 'center' });

    doc.text('MONTH', 131, 30, { align: 'center' });
    doc.text('%', 147.5, 30, { align: 'center' });
    doc.text('YTD', 164, 30, { align: 'center' });
    doc.text('%', 180.5, 30, { align: 'center' });

    doc.text('YTD', 193.5, 27, { align: 'center' });
    doc.text('vs LY', 193.5, 30, { align: 'center' });

    // HEADERS
    doc.line(55, 30, 55, 254);
    doc.line(75, 30, 75, 254);
    doc.line(88, 30, 88, 254);
    doc.line(108, 30, 108, 254);
    doc.line(121, 30, 121, 254);

    doc.line(141, 30, 141, 254);
    doc.line(154, 30, 154, 254);
    doc.line(174, 30, 174, 254);
    doc.line(187, 30, 187, 254);
};

export default PNLHeader;
