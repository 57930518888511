import { Checkbox, Grid, Typography } from '@mui/material';
import { DailyTask } from '../../../global/interfaces/GeneralInterface';

interface TaskItemProps {
    taskItem: DailyTask;
    toggleCompleted: (taskId: number) => void;
}

const TaskItem = (props: TaskItemProps) => {
    const { taskItem, toggleCompleted } = props;
    const handleChange = () => {
        toggleCompleted(taskItem?.id);
    };

    return (
        <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
            <Checkbox
                checked={taskItem.status}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography
                variant="body1"
                sx={{
                    textDecoration: taskItem.status ? 'line-through' : ''
                }}
            >
                {taskItem.description}
            </Typography>
        </Grid>
    );
};

export default TaskItem;
