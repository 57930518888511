// IMPORTS
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import RoleCheck from '../global/RoleCheck';

// MISC
import OnlineOrders from '../online/OnlineOrders';
import OnlinePaymentManagement from '../online/OnlinePaymentManagement';

const OnlineRouter = () => {
    let { path } = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${path}/orders`}>
                    <RoleCheck
                        component={<OnlineOrders />}
                        permission={'access_online'}
                        pageError
                    />
                </Route>
                <Route path={`${path}/paymentManagement`}>
                    <RoleCheck
                        component={<OnlinePaymentManagement />}
                        permission={'admin_manager'}
                        pageError
                    />
                </Route>
            </Switch>
        </>
    );
};

export default OnlineRouter;
