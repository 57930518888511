// IMPORTS
// eslint-disable-next-line
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable';
// LOGIC
import {
    DateSafeFormatter,
    YearFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';

const PDFJobCard = async (
    doc,
    vehicleType: string,
    selectedVehicle: Vehicle
) => {
    doc.setFont('helvetica', 'bold').setFontSize(9);
    doc.rect(107, 53, 93, 60); // The box around vehicle details

    doc.text('VEHICLE BEING SERVICED', 155, 57, { align: 'center' });
    doc.line(107, 59, 200, 59); // Horizontal line below title

    doc.setFont('helvetica', 'normal').setFontSize(7);
    doc.text('Vehicle:', 109, 63);
    doc.line(107, 66, 200, 66); // Horizontal line below vehicle description

    if (vehicleType === 'customer') {
        doc.text('Body Type:', 109, 70);
        doc.line(107, 72, 200, 72); // Horizontal line below body type

        doc.text('Registration', 109, 76);
        doc.text('Number:', 109, 78);
        doc.line(107, 79, 200, 79); // Horizontal line below registration number

        doc.text('VIN / Chassis No:', 109, 84);
        doc.line(107, 86, 200, 86); // Horizontal line below vin

        doc.text('Engine Number:', 109, 91);
        doc.line(107, 93, 200, 93); // Horizontal line below engine number

        doc.text('Model', 109, 97);
        doc.text('Year:', 109, 99);
        doc.line(107, 100, 200, 100); // Horizontal line below year

        doc.text('Odometer', 109, 103).text('kms', 195, 103, {
            align: 'right'
        });
        doc.text('Reading:', 109, 106).text('mls', 195, 106, {
            align: 'right'
        });
        doc.line(107, 107, 200, 107); // Horizontal line below odometer and colour

        doc.text('Body Colour:', 109, 111);
    } else {
        doc.text('Condition:', 109, 70);
        doc.line(107, 72, 200, 72); // Horizontal line below condition

        doc.text('Body', 109, 76);
        doc.text('Type:', 109, 78);
        doc.line(155, 72, 155, 79); // Vertical line between body type and registration number
        doc.text('Registration', 156, 76);
        doc.text('Number:', 156, 78);
        doc.line(107, 79, 200, 79); // Horizontal line below body type and registration number

        doc.text('VIN / Chassis No:', 109, 84);
        doc.line(107, 86, 200, 86); // Horizontal line below vin

        doc.text('Engine Number:', 109, 91);
        doc.line(107, 93, 200, 93); // Horizontal line below engine number

        doc.text('Model', 109, 97);
        doc.text('Year:', 109, 99);
        doc.line(155, 93, 155, 100); // Vertical line between year and compliance
        doc.text('Compliance', 156, 97);
        doc.text('Plate Date:', 156, 99);
        doc.line(107, 100, 200, 100); // Horizontal line below year and compliance

        doc.text('Odometer', 109, 103).text('kms', 154, 103, {
            align: 'right'
        });
        doc.text('Reading:', 109, 106).text('mls', 154, 106, {
            align: 'right'
        });
        doc.line(155, 100, 155, 107); // Vertical line between odometer and colour
        doc.text('Body', 156, 103);
        doc.text('Colour:', 156, 106);
        doc.line(107, 107, 200, 107); // Horizontal line below odometer and colour

        doc.text('Stock No:', 109, 111);
    }

    // Fill vehicle details if required
    doc.setFont('helvetica', 'normal').setFontSize(9);
    doc.text(
        selectedVehicle.Make?.name + ' ' + selectedVehicle.Model.name,
        119,
        63
    );

    if (vehicleType === 'customer') {
        if (selectedVehicle?.BodyTypeId) {
            doc.text(selectedVehicle?.BodyType.name, 122, 70);
        }

        if (selectedVehicle?.registrationNumber) {
            doc.text(selectedVehicle?.registrationNumber, 124, 77);
        }

        if (selectedVehicle?.vin) {
            doc.text(selectedVehicle?.vin, 130, 84);
        }

        if (selectedVehicle?.engineNumber) {
            doc.text(selectedVehicle?.engineNumber, 130, 91);
        }

        if (selectedVehicle?.year) {
            doc.text(YearFormatter(selectedVehicle?.year), 117, 98);
        }

        if (selectedVehicle?.odometer) {
            doc.text(selectedVehicle?.odometer, 188, 104, { align: 'right' });
        }

        if (selectedVehicle?.colour) {
            doc.text(selectedVehicle?.colour, 124, 111);
        }
    } else {
        if (selectedVehicle?.condition) {
            doc.text(selectedVehicle?.condition, 122, 70);
        }

        if (selectedVehicle?.BodyTypeId) {
            doc.text(selectedVehicle?.BodyType?.name, 119, 77);
        }
        if (selectedVehicle?.registrationNumber) {
            doc.text(selectedVehicle?.registrationNumber, 171, 77);
        }

        if (selectedVehicle?.vin) {
            doc.text(selectedVehicle?.vin, 130, 84);
        }

        if (selectedVehicle?.engineNumber) {
            doc.text(selectedVehicle?.engineNumber, 130, 91);
        }

        if (selectedVehicle?.year) {
            doc.text(selectedVehicle?.year, 117, 98);
        }
        if (selectedVehicle?.complianceDate) {
            doc.text(
                DateSafeFormatter(selectedVehicle?.complianceDate),
                170,
                98
            );
        }

        if (selectedVehicle?.odometer) {
            doc.text(selectedVehicle?.odometer, 148, 104, { align: 'right' });
        }
        if (selectedVehicle?.colour) {
            doc.text(selectedVehicle?.colour, 166, 104);
        }

        if (selectedVehicle?.stockNumber) {
            doc.text(selectedVehicle?.stockNumber, 121, 111);
        }
    }

    return doc;
};

export default PDFJobCard;
