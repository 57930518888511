import { Table, TableCell, TableRow, Typography } from '@mui/material';
import Paper from '../../../global/Paper';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import DataChangeIndicator from '../../../global/dashboard/DataChangeIndicator';
import { FinancialOverviewProps } from '../../../global/dashboard/interface';

const VehicleSaleRevenueOverview = ({
    selectedMonthFinalisedRevenue,
    comparedMonthFinalisedRevenue,
    compareStartDate,
    compareEndDate
}: FinancialOverviewProps) => {
    return (
        <Paper>
            <Table size="small">
                <TableRow>
                    <TableCell>
                        <Typography variant="h6" align="center">
                            <b>Total Finalised Revenue</b>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="h6" align="center">
                            {CurrencyFormatter(selectedMonthFinalisedRevenue)}
                            <br />
                            <DataChangeIndicator
                                currentValue={selectedMonthFinalisedRevenue}
                                previousValue={comparedMonthFinalisedRevenue}
                                category="revenue"
                                dataType="currency"
                                compareStartDate={compareStartDate}
                                compareEndDate={compareEndDate}
                            />
                        </Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </Paper>
    );
};

export default VehicleSaleRevenueOverview;
