// IMPORTS
import { ReactNode, useState, SyntheticEvent, useEffect } from 'react';
import { Icon } from '@mui/material';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabProps {
    children: ReactNode;
    index: number;
    value: number;
}

const Tab = ({ children, value, index, ...other }: TabProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <br />
                    {children}
                </Box>
            )}
        </div>
    );
};

interface TabContent {
    id: number;
    title: string | ReactNode;
    content: ReactNode;
    chipContent?: ReactNode;
    iconNumber?: string;
    cypressLabel?: string;
}
interface TabsInterface {
    tabContent: TabContent[];
    presetValue?: number;
}
const Tabs = ({ tabContent, presetValue }: TabsInterface) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (presetValue) {
            setValue(presetValue);
        }
    }, [presetValue]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <MuiTabs
                    variant="fullWidth"
                    value={value}
                    indicatorColor="primary"
                    onChange={handleChange}
                >
                    {tabContent?.map((tabName: TabContent) => (
                        <MuiTab
                            data-cy={
                                tabName.cypressLabel ? tabName.cypressLabel : ''
                            }
                            sx={{ marginBottom: 0 }}
                            key={tabName.id}
                            label={tabName.title}
                            icon={
                                tabName.iconNumber ? (
                                    <span
                                        style={{
                                            backgroundColor: '#555',
                                            marginBottom: 1,
                                            borderRadius: 4
                                        }}
                                    >
                                        <Icon
                                            sx={{
                                                width: '100%',
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                                color: '#fff',
                                                paddingTop: '4px',
                                                paddingBottom: '1.5px'
                                            }}
                                        >
                                            <span style={{ padding: '3px' }}>
                                                {tabName.iconNumber}
                                            </span>
                                        </Icon>
                                    </span>
                                ) : null
                            }
                            iconPosition="end"
                        />
                    ))}
                </MuiTabs>
            </Box>
            {tabContent?.map((tabItem: TabContent) => (
                <Tab key={tabItem.id} value={value} index={tabItem.id}>
                    {tabItem.content}
                </Tab>
            ))}
        </Box>
    );
};

export default Tabs;
