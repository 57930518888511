// IMPORTS
import { useState } from 'react';
import { Typography } from '@mui/material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import CreditorDetails from './components/CreditorDetails';
// INTERFACES
import { Creditor } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

const AddCreditor = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [creditorDetails, setCreditorDetails] = useState<Creditor>({
        contactDetails: {
            phone: [{ id: 1, reference: 'PRIMARY', value: '' }],
            email: [{ id: 1, reference: 'PRIMARY', value: '' }]
        },
        paymentDetails: {
            bsb: null,
            accountName: null,
            accountNumber: null,
            defaultReference: null
        }
    });

    return (
        <PageWrapper>
            <Typography variant="h4" align="left">
                New Creditor
            </Typography>
            <br />

            <CreditorDetails
                creditorDetails={creditorDetails}
                setCreditorDetails={setCreditorDetails}
                showSnackbar={showSnackbar}
            />
        </PageWrapper>
    );
};

export default withSnackbar(AddCreditor);
