import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleSubmitReconciliation = (
    tillReconciliation,
    selectedSite: number,
    selectedTill: number,
    showSnackbar: showSnackbar,
    selectedDate?
) => {
    let query = '';
    if (selectedDate) {
        query = `&date=${selectedDate}`;
    }

    if (tillReconciliation.cash.some((x) => parseInt(x.banking) > 0)) {
        if (
            tillReconciliation.cashbagNumber === '' ||
            tillReconciliation.cashbagNumber == null
        ) {
            showSnackbar(
                'Cashbag number is missing',

                'There is some cash in banking, a cashbag number is required.',
                'error'
            );
            return;
        }
    }

    api.post(`submitTillReconciliation?SiteId=${selectedSite}${query}`, {
        tillReconciliation: tillReconciliation,
        selectedTill: selectedTill
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Till reconciliation submitted.',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleSubmitReconciliation;
