// LOGIC
import { PaymentTotal } from '../CalculateTotals';
import api from '../../../../../../api';
// INTERFACES
import { PaymentLine } from '../../../../global/interfaces/GeneralInterface';
import { Order } from '../../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const SubmitCustomerSale = (
    selectedEntity: any,
    saleDetails: Order,
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[],
    paymentLines: PaymentLine[],
    handlePrintInvoice: (
        hasCustomer: boolean,
        saleData: Order,
        redirectId?: number | boolean,
        vouchers?: any[]
    ) => void,
    showSnackbar: showSnackbar
) => {
    let obj = {
        saleDetails: saleDetails,
        extraCosts: extraCosts,
        paymentLines: paymentLines,
        selectedEntity: selectedEntity,
        paymentTotal: PaymentTotal(paymentLines)
    };
    api.post(`createCustomerSale?SiteId=${saleDetails.SiteId}`, obj).then(
        (res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Sale Created!',
                    'You will now be redirected to the sale page.'
                );
                handlePrintInvoice(
                    true,
                    res.data.saleData,
                    res.data.id,
                    res.data.vouchers
                );
            } else {
                if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact the IT department.',
                        'error'
                    );
                } else {
                    showSnackbar(
                        res.data.split('|')[0],
                        res.data.split('|')[1],
                        'error'
                    );
                }
            }
        }
    );
};

export default SubmitCustomerSale;
