// IMPORTS
import { useState } from 'react';
import { TableRow, Typography, Divider } from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
import Drawer from '../../../../global/Drawer';
import NewTransferDrawerContent from '../../../vehicleTransferTable/components/NewTransferDrawerContent';
// LOGIC
import {
  DateFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import {
  VehicleTransfer,
  Vehicle
} from '../../../../global/interfaces/VehicleSaleInterface';

interface TransfersProps {
  transfers: VehicleTransfer[];
  vehicle: Vehicle;
}

const Transfers = ({ transfers, vehicle }: TransfersProps) => {
  const [transferDrawerOpen, setTransferDrawerOpen] = useState<boolean>(false);
  const [selectedTransfer, setSelectedTransfer] = useState<VehicleTransfer>();

  const columns = [
    { id: 0, label: 'Transfer ID' },
    { id: 1, label: 'Status' },
    { id: 2, label: 'Date' },
    { id: 3, label: 'From' },
    { id: 4, label: 'To' },
    { id: 5, label: 'User' }
  ];

  const handleLinkClick = (transfer) => {
    transfer.Vehicle = vehicle;
    setSelectedTransfer(transfer);
    setTransferDrawerOpen(true);
  };

  return (
    <>
      <Paper>
        <Typography variant="h5" sx={{ fontSize: '25px' }}>
          Transfers
        </Typography>
        <br />
        <Divider />
        <DataTable columns={columns}>
          {transfers.map((transfer) => (
            <TableRow>
              <DataCellColoured handleClick={() => handleLinkClick(transfer)}>
                {transfer.id}
              </DataCellColoured>
              <DataCell>{transfer.status}</DataCell>
              <DataCell>{DateFormatter(transfer.createdAt)}</DataCell>
              <DataCell>{transfer?.SiteFrom?.name}</DataCell>
              <DataCell>{transfer?.SiteTo?.name}</DataCell>
              <DataCell>
                {transfer?.SiteToUser?.firstName}{' '}
                {transfer?.SiteToUser?.lastName}
              </DataCell>
            </TableRow>
          ))}
        </DataTable>
      </Paper>

      <Drawer
        openDrawer={transferDrawerOpen}
        setOpenDrawer={setTransferDrawerOpen}
        title={`Transfer #${selectedTransfer?.id}`}
        subTitle={selectedTransfer ? `Status: ${selectedTransfer?.status}` : ''}
        width="40vw"
      >
        <NewTransferDrawerContent transferData={selectedTransfer} />
      </Drawer>
    </>
  );
};

export default Transfers;
