import { OnlineOrder } from '../../interfaces/PartsInterface';
import { showSnackbar } from '../../interfaces/GlobalInterface';
import { ValidateEmail, ValidatePhone } from './ValidateCustomerFields';

const ValidateShippingFields = (
    onlineOrder: OnlineOrder,
    showSnackbar: showSnackbar
) => {
    if (!onlineOrder.OnlineOrderId) {
        showSnackbar(
            'You must provide an Online Order ID.',
            'This will be the same as the Neto Order ID.',
            'error'
        );
        return false;
    }
    if (
        !onlineOrder.shippingFirstName &&
        !onlineOrder.shippingLastName &&
        !onlineOrder.shippingCompany
    ) {
        showSnackbar(
            'You must provide a first and last name or a company name.',
            'This is required for the postage label.',
            'error'
        );
        return false;
    }

    let emailValidation = ValidateEmail(onlineOrder.shippingEmail);
    if (!onlineOrder.shippingEmail) {
        showSnackbar(
            'You must provide an email address.',
            'This is required for order updates.',
            'error'
        );
        return false;
    }
    if (emailValidation.verified === false) {
        showSnackbar(
            emailValidation.message,
            emailValidation.subMessage,
            'error'
        );
        return false;
    }

    if (!onlineOrder.shippingPhoneNumber) {
        showSnackbar(
            'You must provide a phone number.',

            'This is required for order updates and the postage label.',
            'error'
        );
        return false;
    }
    let phoneValidation = ValidatePhone(onlineOrder.shippingPhoneNumber);
    if (phoneValidation.verified === false) {
        showSnackbar(
            phoneValidation.message,
            phoneValidation.subMessage,
            'error'
        );
        return false;
    }

    if (!onlineOrder.shippingAddressLine1) {
        showSnackbar(
            'You must provide an address line.',
            'This is required for the postage label.',
            'error'
        );
        return false;
    }
    if (!onlineOrder.shippingSuburb) {
        showSnackbar(
            'You must provide a urb for the address.',
            'This is required for the postage label.',
            'error'
        );
        return false;
    }
    if (!onlineOrder.shippingState) {
        showSnackbar(
            'error',
            'You must provide a state for the address.',
            'This is required for the postage label.'
        );
        return false;
    }
    if (!onlineOrder.shippingPostcode) {
        showSnackbar(
            'You must provide a postcode for the address.',
            'This is required for the postage label.',
            'error'
        );
        return false;
    }
    if (!onlineOrder.shippingCountry) {
        showSnackbar(
            'You must provide a country for the address.',
            'This is required for the postage label.',
            'error'
        );
        return false;
    }

    if (!onlineOrder.shippingOption) {
        showSnackbar(
            'You must provide a shipping option.',
            'This is required for Aus Post to send the package.',
            'error'
        );
        return false;
    }
    if (
        !onlineOrder.shippingTotal ||
        parseFloat(onlineOrder.shippingTotal) < 0
    ) {
        showSnackbar(
            'You must provide a shipping cost.',
            'This is required for Aus Post to send the package.',
            'error'
        );
        return false;
    }

    return true;
};

export default ValidateShippingFields;
