const renderCustomerVendorPdf = async (pdfDocCopy, formData) => {
    const pages = pdfDocCopy.getPages();
    const firstPage = pages[0];

    if (formData.vendorSigDataURL) {
        const vendorSigImage = await pdfDocCopy.embedPng(
            formData.vendorSigDataURL
        );
        firstPage.drawImage(vendorSigImage, {
            x: 400,
            y: 30,
            width: 100,
            height: 30
        });
    }

    const pdfBytes = await pdfDocCopy.save();
    return pdfBytes;
};

export default renderCustomerVendorPdf;
