// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    DialogTitle,
    DialogContent,
    Grid,
    IconButton,
    TextField,
    DialogActions,
    Button
} from '@mui/material';
import { Remove, Add } from '@mui/icons-material';
// LOGIC
import ManuallyAdjustOnlineReserved from '../logic/ManuallyAdjustOnlineReserved';
// INTERFACES
import { Unit } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface ManuallyAdjustStockDialogProps {
    partId: number;
    partDetails: Unit;
    onlineReservedValue: number;
    setOnlineReservedValue: Dispatch<SetStateAction<number>>;
    setOnlineStock: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const OnlineReservedStockDialog = ({
    partId,
    partDetails,
    onlineReservedValue,
    setOnlineReservedValue,
    setOnlineStock,
    showSnackbar
}: ManuallyAdjustStockDialogProps) => {
    const [qtyError, setQtyError] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        for (let stock of partDetails.Stock) {
            if (stock.SiteId === parseInt(localStorage.getItem('SiteId'))) {
                if (
                    onlineReservedValue > stock.onlineReserved ||
                    onlineReservedValue < 0
                ) {
                    setQtyError(true);
                } else {
                    setQtyError(false);
                }
            }
        }
        // eslint-disable-next-line
    }, [onlineReservedValue]);

    return (
        <>
            <DialogTitle>
                Return {partDetails.partNumber} Online Reserved to Stock
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}>
                        <IconButton
                            onClick={() =>
                                setOnlineReservedValue(onlineReservedValue - 1)
                            }
                        >
                            <Remove />
                        </IconButton>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            variant="standard"
                            sx={{ textAlign: 'center' }}
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'center' }
                            }}
                            value={onlineReservedValue}
                            error={qtyError}
                            helperText={
                                qtyError
                                    ? 'Return quantity cannot be more than what is on online reserved or negative.'
                                    : ''
                            }
                            onChange={(e) =>
                                setOnlineReservedValue(parseInt(e.target.value))
                            }
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            onClick={() =>
                                setOnlineReservedValue(onlineReservedValue + 1)
                            }
                        >
                            &nbsp;
                            <Add />
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => setOnlineStock(false)}>
                    Cancel
                </Button>
                {qtyError ? null : (
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            ManuallyAdjustOnlineReserved(
                                partId,
                                parseInt(localStorage.getItem('SiteId')),
                                onlineReservedValue,
                                showSnackbar,
                                setButtonLoading
                            )
                        }
                    >
                        Save
                    </LoadingButton>
                )}
            </DialogActions>
        </>
    );
};

export default OnlineReservedStockDialog;
