import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { ManualInvoice } from '../../../global/interfaces/AdminInterface';
import api from '../../../../../api';

const UpdateManualInvoice = (
    manualInvoiceData: ManualInvoice,
    showSnackbar: showSnackbar
) => {
    api.put('manualInvoice', manualInvoiceData).then((res) => {
        if (res.status === 200) {
            showSnackbar('Manual Invoice successfully updated.');
            window.location.reload();
        } else {
            showSnackbar(
                'Something went wrong on our end.',
                'This manual invoice was not updated.',
                'error'
            );
        }
    });
};

export default UpdateManualInvoice;
