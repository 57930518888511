// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { TimeClock } from '../../global/interfaces/AdminInterface';

const GetTimeClocks = (
  setTimeClocks: Dispatch<SetStateAction<TimeClock[]>>
) => {
  api.get(`getTimeClocks`).then((res) => {
    setTimeClocks(res.data);
  });
};

export default GetTimeClocks;
