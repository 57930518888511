// IMPORTS
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Prompt } from 'react-router-dom';
// COMPONENTS
import OrderLineForm from './components/OrderLineForm';
import CustomerBackorderTable from '../../stock/customerBackorder/CustomerBackorderTable';
import Tabs from '../../global/Tabs';
import PageWrapper from '../../global/PageWrapper';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Api } from '../../global/interfaces/GeneralInterface';
import { Table } from './interfaces/StockOrderInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

const StockOrderUI = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [shouldBlockNavigation, setShouldBlockNavigation] =
        useState<boolean>(false);
    const [tables, setTables] = useState<Table[]>([]);
    const [selectedSite, setSelectedSite] = useState<number>(
        parseInt(localStorage.getItem('SiteId'))
    );
    const [orderType, setOrderType] = useState<string[]>(['Daily']);
    const [selectedSupplier, setSelectedSupplier] = useState<number>(null);
    const [selectedSupplierError, setSelectedSupplierError] =
        useState<boolean>(false);
    const [generatorList, setGeneratorList] = useState<Api[]>([]);

    // Handle which supplier backorders have been imported to the table
    const [importedSupplier, setImportedSupplier] = useState<number[]>([]);

    useEffect(() => {
        if (tables.length > 0) {
            setShouldBlockNavigation(true);
        }
    }, [tables]);

    const tabContent = [
        {
            id: 0,
            title: 'Stock Order',
            content: (
                <OrderLineForm
                    tables={tables}
                    setTables={setTables}
                    selectedSite={selectedSite}
                    setSelectedSite={setSelectedSite}
                    orderType={orderType}
                    setOrderType={setOrderType}
                    generatorList={generatorList}
                    setGeneratorList={setGeneratorList}
                    showSnackbar={showSnackbar}
                    selectedSupplier={selectedSupplier}
                    setSelectedSupplier={setSelectedSupplier}
                    selectedSupplierError={selectedSupplierError}
                    setSelectedSupplierError={setSelectedSupplierError}
                    setShouldBlockNavigation={setShouldBlockNavigation}
                />
            )
        },
        {
            id: 1,
            title: 'Customer Backorder',
            content: (
                <CustomerBackorderTable
                    tables={tables}
                    setTables={setTables}
                    importedSupplier={importedSupplier}
                    setImportedSupplier={setImportedSupplier}
                />
            )
        }
    ];

    return (
        <PageWrapper>
            <Prompt
                when={shouldBlockNavigation}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <br />
            <Typography variant="h4" align="left">
                New Stock Order
            </Typography>
            <br />

            <Tabs tabContent={tabContent} />
        </PageWrapper>
    );
};

export default withSnackbar(StockOrderUI);
