import {
    Badge,
    Button,
    Checkbox,
    Dialog,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import {
    ArrowCircleLeft,
    ArrowCircleRight,
    LeakAdd,
    StickyNote2
} from '@mui/icons-material';
import { Dispatch, SetStateAction, useState } from 'react';

// COMPONENTS
import Paper from '../../../global/Paper';
import NotesDialogContent from '../NotesDialogContent';
import Fab from '../../../global/Fab';

// LOGIC
import MoveOrderToPick from '../../logic/PickAndPack/MoveOrderToPick';
import MoveOrderToPendingDispatch from '../../logic/PickAndPack/MoveOrderToPendingDispatch';
import PrintPostageLabel from '../../logic/PickAndPack/PrintPostageLabel';
import PrintTestPostageLabel from '../../logic/PickAndPack/PrintTestPostageLabel';
import MoveOrderToPack from '../../logic/PickAndPack/MoveOrderToPack';
import PrintMultiplePostageLabel from '../../logic/PickAndPack/PrintMultiplePostageLabel';

// INTERFACES
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';
interface GroupedOnlineOrders {
    pick: GroupedOnlineOrder[];
    pack: GroupedOnlineOrder[];
}
interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
interface ViewOrdersProps {
    selectedGroupOrders: GroupedOnlineOrder[];
    setSelectedGroupOrders: Dispatch<SetStateAction<GroupedOnlineOrder[]>>;
    selectedGroupTitle: string;
    pickPackOrders: GroupedOnlineOrders;
    setPickPackOrders: Dispatch<SetStateAction<GroupedOnlineOrders>>;
    showSnackbar: showSnackbar;
}

const ViewOrders = ({
    selectedGroupTitle,
    selectedGroupOrders,
    setSelectedGroupOrders,
    pickPackOrders,
    setPickPackOrders,
    showSnackbar
}: ViewOrdersProps) => {
    const [labelLoading, setLabelLoading] = useState<boolean>(false);

    const [selectedOrder, setSelectedOrder] = useState<GroupedOnlineOrder>();
    const [notesDialogOpen, setNotesDialogOpen] = useState<boolean>(false);
    const [checkedOrders, setCheckedOrders] = useState<GroupedOnlineOrder[]>(
        []
    );

    const handleOpenNotesDialog = (order: GroupedOnlineOrder) => {
        setSelectedOrder(order);
        setNotesDialogOpen(true);
    };

    const handleUpdateAllStatesNotes = (newNotes: string, OrderId: any) => {
        let currentSelectedGroup = [...selectedGroupOrders];

        let index = currentSelectedGroup.findIndex(
            (x) => x.oraOrder.id === OrderId
        );

        currentSelectedGroup[index].oraOrder.notes = newNotes;

        if (selectedGroupTitle === 'Pick') {
            setPickPackOrders({
                ...pickPackOrders,
                pick: currentSelectedGroup
            });
        } else if (selectedGroupTitle === 'Pack') {
            setPickPackOrders({
                ...pickPackOrders,
                pack: currentSelectedGroup
            });
        }

        setSelectedGroupOrders(currentSelectedGroup);
    };

    const handleCheckLine = (checked: boolean, line, order) => {
        let currentOrders = [...selectedGroupOrders];
        let orderIndex = currentOrders.findIndex(
            (x) => x.oraOrder.id === order.oraOrder.id
        );

        let currentOrderLines = [
            ...currentOrders[orderIndex].oraOrder.orderLines
        ];
        let orderlineIndex;
        // Old Online Orders didn't have a rowId
        if (line.rowId) {
            orderlineIndex = currentOrderLines.findIndex(
                (x) => x.rowId === line.rowId
            );
        } else {
            orderlineIndex = currentOrderLines.findIndex(
                (x) => x.UnitId === line.UnitId
            );
        }

        currentOrderLines[orderlineIndex].picked = checked;

        currentOrders[orderIndex].oraOrder.orderLines = currentOrderLines;

        setSelectedGroupOrders(currentOrders);
    };

    const checkOrderPicked = (orderlines) => {
        let allPicked = true;

        orderlines.forEach((line) => {
            if (!line.picked) {
                allPicked = false;
            }
        });

        return allPicked;
    };

    const handleCheckboxChange = (
        order: GroupedOnlineOrder,
        checked: boolean
    ) => {
        let currentCheckedOrders = [...checkedOrders];
        if (checked) {
            currentCheckedOrders.push(order);
        } else {
            currentCheckedOrders = currentCheckedOrders.filter(
                (x) => x.oraOrder.id !== order.oraOrder.id
            );
        }
        setCheckedOrders(currentCheckedOrders);
    };

    const checkIfChecked = (order: GroupedOnlineOrder) => {
        for (let checkedOrder of checkedOrders) {
            if (checkedOrder.oraOrder.id === order.oraOrder.id) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            {selectedGroupOrders?.map((order) => (
                <>
                    <Paper>
                        <Grid container spacing={2}>
                            {selectedGroupTitle === 'Pack' ? (
                                <Grid item xs={0.5}>
                                    <Checkbox
                                        checked={checkIfChecked(order)}
                                        onChange={(e) =>
                                            handleCheckboxChange(
                                                order,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </Grid>
                            ) : null}

                            <Grid
                                item
                                xs={selectedGroupTitle === 'Pack' ? 5 : 6}
                            >
                                <Typography
                                    variant="subtitle1"
                                    onClick={() =>
                                        window.open(
                                            `/inventory/viewSale/${order.oraOrder.id}`,
                                            '_blank'
                                        )
                                    }
                                >
                                    <b>
                                        {order?.netoOrder?.OnlineOrderId} |{' '}
                                        {order?.netoOrder?.shippingFirstName}{' '}
                                        {order?.netoOrder?.shippingLastName}
                                    </b>
                                </Typography>
                                <Typography variant="subtitle2">
                                    {order?.oraOrder?.Site?.name} |{' '}
                                    {order?.netoOrder?.shippingOption}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                                <Tooltip title="Order Notes" placement="top">
                                    <IconButton
                                        disabled={labelLoading}
                                        onClick={() =>
                                            handleOpenNotesDialog(order)
                                        }
                                    >
                                        <Badge
                                            variant="dot"
                                            color="primary"
                                            badgeContent=" "
                                            invisible={
                                                order.oraOrder.notes
                                                    ? false
                                                    : true
                                            }
                                        >
                                            <StickyNote2 />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                {selectedGroupTitle === 'Pack' ? (
                                    <Tooltip
                                        title="Move Order to Pick"
                                        placement="top"
                                    >
                                        <IconButton
                                            disabled={labelLoading}
                                            onClick={() =>
                                                MoveOrderToPick(
                                                    order,
                                                    setSelectedGroupOrders,
                                                    pickPackOrders,
                                                    setPickPackOrders,
                                                    showSnackbar
                                                )
                                            }
                                        >
                                            <ArrowCircleLeft />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                {checkOrderPicked(order.oraOrder.orderLines) &&
                                selectedGroupTitle === 'Pick' ? (
                                    <Tooltip
                                        title="Move Order to Pack"
                                        placement="top"
                                    >
                                        <IconButton
                                            disabled={labelLoading}
                                            onClick={() =>
                                                MoveOrderToPack(
                                                    order,
                                                    setSelectedGroupOrders,
                                                    pickPackOrders,
                                                    setPickPackOrders,
                                                    showSnackbar
                                                )
                                            }
                                            color="primary"
                                        >
                                            <ArrowCircleRight />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                {selectedGroupTitle === 'Pack' &&
                                order.netoOrder.ausPostShipmentId ? (
                                    <Tooltip
                                        title="Move Order to Pending Dispatch"
                                        placement="top"
                                    >
                                        <IconButton
                                            disabled={labelLoading}
                                            onClick={() =>
                                                MoveOrderToPendingDispatch(
                                                    order,
                                                    pickPackOrders,
                                                    setPickPackOrders,
                                                    setSelectedGroupOrders,
                                                    showSnackbar
                                                )
                                            }
                                            color="primary"
                                        >
                                            <ArrowCircleRight />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                {selectedGroupTitle === 'Pack' ? (
                                    order.netoOrder.ausPostShipmentId ? (
                                        <Button
                                            disabled={labelLoading}
                                            onClick={() =>
                                                PrintPostageLabel(
                                                    order,
                                                    showSnackbar,
                                                    setLabelLoading
                                                )
                                            }
                                            variant="outlined"
                                        >
                                            Reprint Label
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={labelLoading}
                                            onClick={() =>
                                                PrintPostageLabel(
                                                    order,
                                                    showSnackbar,
                                                    setLabelLoading
                                                )
                                            }
                                            variant="contained"
                                        >
                                            Print Label
                                        </Button>
                                    )
                                ) : null}
                            </Grid>
                        </Grid>
                        <br />
                        {order.oraOrder.orderLines.map((line) => (
                            <>
                                {selectedGroupTitle === 'Pick' ? (
                                    <>
                                        <Checkbox
                                            checked={line.picked ? true : false}
                                            onChange={(e) =>
                                                handleCheckLine(
                                                    e.target.checked,
                                                    line,
                                                    order
                                                )
                                            }
                                        />
                                        <Typography
                                            variant="body1"
                                            sx={{ display: 'inline' }}
                                        >
                                            {' '}
                                            <b>
                                                {line.quantity} x{' '}
                                                {line.partNumber}
                                            </b>{' '}
                                            | {line.name}
                                        </Typography>
                                        <br />
                                    </>
                                ) : (
                                    <Typography variant="body1">
                                        {' '}
                                        <b>
                                            {line.quantity} x {line.partNumber}
                                        </b>{' '}
                                        | {line.name}
                                    </Typography>
                                )}
                            </>
                        ))}
                    </Paper>
                    <br />
                </>
            ))}
            {checkedOrders.length > 1 ? (
                <Grid container spacing={2} textAlign={'center'}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={() =>
                                PrintMultiplePostageLabel(
                                    checkedOrders,
                                    showSnackbar,
                                    setLabelLoading
                                )
                            }
                        >
                            Print Labels for all selected orders
                        </Button>
                    </Grid>
                </Grid>
            ) : null}

            {selectedGroupTitle === 'Pack' ? (
                <Fab
                    loading={labelLoading}
                    noSession={true}
                    customIcon={<LeakAdd />}
                    label="Print Test Label"
                    onClick={() => PrintTestPostageLabel()}
                />
            ) : null}

            <Dialog
                open={notesDialogOpen}
                onClose={() => setNotesDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <NotesDialogContent
                    handleUpdateAllStatesNotes={handleUpdateAllStatesNotes}
                    selectedOrder={selectedOrder}
                    setNotesDialogOpen={setNotesDialogOpen}
                    showSnackbar={showSnackbar}
                />
            </Dialog>
        </>
    );
};

export default withSnackbar(ViewOrders);
