import BalanceSheetCSVContent from './BalanceSheetCSVContent';
import { BalanceSheetLine } from '../interface/BalanceSheetInterface';

import {Dayjs} from 'dayjs'
import { Site } from '../../../../../global/interfaces/GeneralInterface';

interface ExportBalanceSheetToCSVParams {
    (params: {queryDate: Dayjs,
    selectedSite: string | Site,
    singleLine: BalanceSheetLine}): void
}

const ExportBalanceSheetToCSV: ExportBalanceSheetToCSVParams = (params) => {
    let rows = [];

    let endDate = params.queryDate.format('DD-MM-YYYY')

    let filename = endDate + ".csv";

    rows = BalanceSheetCSVContent(params.singleLine, params.selectedSite);
    filename = "BalanceSheet_" + filename;
    
    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
};

export default ExportBalanceSheetToCSV;