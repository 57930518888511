import DocumentationPage from '../DocumentationPage';
import { AddManualTransactionSteps } from '../data/admin/manualTransaction';

const MannualTransactionSupportPage = () => {
    const contentSections = [
        {
            id: 'add-manual-transaction',
            title: 'Add new Manual Transaction',
            href: '/support/admin/manualTransaction/#add-manual-transaction',
            description: 'To add a new Manual Transaction, follow these steps:',
            steps: AddManualTransactionSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Manual Transaction"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default MannualTransactionSupportPage;
