const FilterBankTransactions = (
    bankFilter,
    reconciliationDetails,
    setReconciliationDetails,
    reconciliationDetailsUnltered
) => {
    let currentUnreconciledBank = [
        ...reconciliationDetailsUnltered.bankTransactionsUnreconciled
    ];

    if (bankFilter === 'automaticDrawing') {
        currentUnreconciledBank = currentUnreconciledBank.filter((x) =>
            x.reference.toLowerCase().includes('automatic drawing')
        );
    } else if (bankFilter === 'transferDebits') {
        currentUnreconciledBank = currentUnreconciledBank.filter((x) =>
            x.reference.toLowerCase().includes('transfer debits')
        );
    } else if (bankFilter === 'transferCredits') {
        currentUnreconciledBank = currentUnreconciledBank.filter((x) =>
            x.reference.toLowerCase().includes('transfer credits')
        );
    } else if (bankFilter === 'interbankCredit') {
        currentUnreconciledBank = currentUnreconciledBank.filter((x) =>
            x.reference.toLowerCase().includes('inter-bank credit')
        );
    } else if (bankFilter === 'creditAdjustment') {
        currentUnreconciledBank = currentUnreconciledBank.filter((x) =>
            x.reference.toLowerCase().includes('credit adjustment')
        );
    } else if (bankFilter === 'deposit') {
        currentUnreconciledBank = currentUnreconciledBank.filter((x) =>
            x.reference.toLowerCase().includes('deposit')
        );
    } else if (bankFilter === 'flexipay') {
        currentUnreconciledBank = currentUnreconciledBank.filter((x) =>
            x.reference.toLowerCase().includes('flexipay')
        );
    }

    // Then we need to delete every line that are already put in the reconciled section
    let finalUnreconcileBank = [];
    for (let line of currentUnreconciledBank) {
        if (
            reconciliationDetails.bankTransactionsReconciled.filter(
                (x) => x.BankTransactionId === line.BankTransactionId
            ).length === 0
        ) {
            finalUnreconcileBank.push(line);
        }
    }

    setReconciliationDetails({
        ...reconciliationDetails,
        bankTransactionsUnreconciled: finalUnreconcileBank
    });
};

export default FilterBankTransactions;
