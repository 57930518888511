// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Unit } from '../../global/interfaces/PartsInterface';

interface GetPartDetailsProps {
    SiteId: number;
    id: number;
    setPartDetails: Dispatch<SetStateAction<Unit>>;
    setStockDetails?: Dispatch<
        SetStateAction<{ minStock: number; maxStock: number }>
    >;
    setLoading?: Dispatch<SetStateAction<boolean>>;
    setInvalid?: Dispatch<SetStateAction<boolean>>;
}

const GetPartDetails = ({
    SiteId,
    id,
    setPartDetails,
    setStockDetails,
    setLoading,
    setInvalid
}: GetPartDetailsProps) => {
    if (setLoading) {
        setLoading(true);
    }
    let sendingSiteId =
        SiteId > 0 ? SiteId : parseInt(localStorage.getItem('SiteId'));
    api.get(`inventoryNextGen/${id}?SiteId=${sendingSiteId}`).then((res) => {
        if (res.status === 204 && setInvalid) {
            setInvalid(true);
        }
        let data = res.data;

        for (let i = 0; i < data.SupplierIds.length; i++) {
            if (typeof data.SupplierIds[i].SiteId === 'string') {
                data.SupplierIds[i].SiteId = parseInt(
                    data.SupplierIds[i].SiteId
                );
            }
            if (typeof data.SupplierIds[i].id === 'string') {
                data.SupplierIds[i].id = parseInt(data.SupplierIds[i].id);
            }
            if (typeof data.SupplierIds[i].SupplierId === 'string') {
                data.SupplierIds[i].SupplierId = parseInt(
                    data.SupplierIds[i].SupplierId
                );
            }
        }

        setPartDetails(data);
        setStockDetails({
            minStock: data.Stock.filter((x) => x.SiteId === sendingSiteId)[0]
                .minStock,
            maxStock: data.Stock.filter((x) => x.SiteId === sendingSiteId)[0]
                .maxStock
        });
        if (setLoading) {
            setLoading(false);
        }
    });
};

export default GetPartDetails;
