import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from '@mui/material';

const DealerLOAActionDialog = ({
    signLOADialogOpen,
    setSignLOADialogOpen,
    loaStatus,
    loadingButton,
    handleClickIndividualLOA,
    handleClickOrgLOA,
    handleClickViewLOA
}) => {
    const isSentOrSigned =
        loaStatus === 'customerSigned' || loaStatus === 'waitingSignature';

    return (
        <Dialog
            open={signLOADialogOpen}
            onClose={() => setSignLOADialogOpen(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Email LOA To Sign</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    {loaStatus === 'waitingSignature'
                        ? 'The document has been emailed to the customer for signature.'
                        : loaStatus === 'customerSigned'
                          ? 'The document has been signed by the customer.'
                          : '  Please select the type of customer to whom you are sending the LOA.'}
                </Typography>

                {isSentOrSigned && (
                    <Typography variant="subtitle2">
                        <br />
                        {`Click View Document to review the document.`}
                        <br />
                        Click Start Over to update the document and send a new
                        email.{' '}
                        <b>
                            Note this will erase all previous changes on the
                            document.
                        </b>
                    </Typography>
                )}

                <br />

                <Grid container spacing={2}>
                    <Grid item xs={isSentOrSigned ? 4 : 6} textAlign="center">
                        <LoadingButton
                            loading={loadingButton === 'individual'}
                            variant="outlined"
                            onClick={handleClickIndividualLOA}
                        >
                            {isSentOrSigned
                                ? 'Start Over Individuals'
                                : 'Individuals'}
                        </LoadingButton>
                    </Grid>

                    <Grid item xs={isSentOrSigned ? 4 : 6} textAlign="center">
                        <LoadingButton
                            loading={loadingButton === 'organisation'}
                            variant="outlined"
                            onClick={handleClickOrgLOA}
                        >
                            {isSentOrSigned
                                ? 'Start Over Organisations'
                                : 'Organisations'}
                        </LoadingButton>
                    </Grid>

                    {isSentOrSigned && (
                        <Grid item xs={4} textAlign="center">
                            <LoadingButton
                                loading={loadingButton === 'viewDocument'}
                                variant="contained"
                                onClick={handleClickViewLOA}
                            >
                                View Document
                            </LoadingButton>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DealerLOAActionDialog;
