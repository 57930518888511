import dayjs from 'dayjs';

const UsedDeclarations = (
    doc,
    pageCount,
    fillInformation,
    settings,
    base64Image
) => {
    doc.addPage();
    pageCount += 1;
    doc.setPage(pageCount);

    let userSignature = null;

    doc.rect(15, 16, 180, 12, 'F');
    doc.rect(15, 16, 180, 40);

    doc.setFont('helvetica', 'bold')
        .setFontSize(15)
        .setTextColor(240, 240, 240);
    doc.text('Written Off Vehicle', 17, 24, { align: 'left' });

    doc.setFont('helvetica', 'bold').setFontSize(12).setTextColor(0, 0, 0);
    doc.text(
        `If the vehicle is a written off vehicle the motor dealer must declare it here:`,
        110,
        33,
        { align: 'center' }
    );
    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('Repairable write-off', 50, 44);
    doc.text('Statutory write-off (unable to be registered again', 105, 44);

    doc.rect(38, 40, 5, 5);
    doc.rect(93, 40, 5, 5);

    // Cooling-Off Period
    doc.rect(15, 60, 180, 12, 'F');
    doc.rect(15, 60, 180, 200);
    doc.setLineWidth(0.3);
    doc.setDrawColor(150);
    doc.line(60, 72, 60, 260);

    // TITLE
    doc.setFont('helvetica', 'bold')
        .setFontSize(15)
        .setTextColor(240, 240, 240);
    doc.text('Cooling-Off Period', 17, 68, { align: 'left' });

    // SECTION 1, LEFT PART
    doc.setFont('helvetica', 'bold').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('Section 1', 17, 78);
    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('This section must be ', 17, 83);
    doc.text('completed before  ', 17, 88);
    doc.text('contract is signed.', 17, 93);

    doc.text('Note: A cooling-off ', 17, 103);
    doc.text('period does not apply to ', 17, 108);
    doc.text('the following types of ', 17, 113);
    doc.text('used motor vehicle:', 17, 118);
    doc.text('unregistered motor ', 20, 123);
    doc.text('vehicle that cannot be ', 20, 126);
    doc.text('registered in Queensland', 20, 129);
    doc.text('unregistered motor ', 20, 134);
    doc.text('vehicle intended for ', 20, 137);
    doc.text('wrecking or dismantling', 20, 140);
    doc.text('commercial vehicle', 20, 145);
    doc.text('caravan', 20, 150);

    doc.circle(18, 122.1, 0.5, 'F');
    doc.circle(18, 133.1, 0.5, 'F');
    doc.circle(18, 144.1, 0.5, 'F');
    doc.circle(18, 149.1, 0.5, 'F');

    // SECTION 1, RIGHT PART

    doc.text(
        'A cooling-off period applied to this vehicle?           Yes          No',
        65,
        78
    );
    doc.rect(136, 74, 5, 5);
    doc.rect(152, 74, 5, 5);

    doc.text(
        'A one day cooling-off period applied to this vehicle providing it is not a new vehicle, ',
        65,
        85
    );
    doc.text(
        'was not sold by auction and you (the buyer) do not take physical possession of ',
        65,
        89
    );
    doc.text(
        'the vehicle for a purpose other than a vehicle inspection or a test drive.',
        65,
        93
    );

    doc.setFont('helvetica', 'bold');
    doc.text(
        'To exercise your right to cancel this contract you must provide the dealer ',
        65,
        99
    );
    doc.text(
        'with written notification prior to the cooling-off period ending.',
        65,
        103
    );

    doc.setFont('helvetica', 'normal');
    doc.text(
        'Cancelling the contract within the cooling-off period may result in you forfeiting up ',
        65,
        109
    );
    doc.text(
        'to $100. All other monies paid must be refunded by the motor dealer.',
        65,
        113
    );

    doc.text(
        'The cooling-off period ends at 5:00pm the next business day (excludes ',
        77,
        119
    );
    doc.text(
        'Sunday and public holidays). If the motor dealer closes prior to 5:00pm, the ',
        77,
        123
    );
    doc.text(
        'cooling-off period is extended to next business day before the motor ',
        77,
        127
    );
    doc.text("dealer's normal closing time.", 77, 131);

    doc.text(
        'The cooling-off period ends when the buyer takes permanent physical ',
        77,
        137
    );
    doc.text(
        'possession of the vehicle (not for inspection/test drive).',
        77,
        141
    );

    doc.text('The cooling-off period:', 65, 147);
    doc.text(
        'Start Time _______ : _______ on _______ / _______ / ______________',
        65,
        154
    );
    doc.text('Ends at 5:00pm on _______ / _______ / ______________', 65, 164);

    doc.line(15, 167, 195, 167);

    // SECTION 2, LEFT PART

    doc.setFont('helvetica', 'bold').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('Section 2', 17, 174);
    doc.text('Cancellation of', 17, 180);
    doc.text('agreement during', 17, 184);
    doc.text('cooling-off period.', 17, 188);

    doc.text('To the buyer', 17, 195);

    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('To cancel your contract ', 17, 200);
    doc.text('during the cooling-off', 17, 203);
    doc.text('period, you may either', 17, 206);
    doc.text('deliver, email or fax this', 17, 209);
    doc.text('section or write a letter', 17, 212);
    doc.text('to the motor dealer', 17, 215);
    doc.text('within the one business', 17, 218);
    doc.text('day cooling-off period.', 17, 221);

    // SECTION 2, RIGHT PART

    doc.text(
        'I, ................................................................................................ (buyers name)',
        65,
        175
    );
    doc.text(
        'of ............................................. State                      Postcode',
        65,
        182
    );
    doc.rect(123, 177, 6, 6);
    doc.rect(130, 177, 6, 6);
    doc.rect(137, 177, 6, 6);
    doc.rect(159, 177, 6, 6);
    doc.rect(166, 177, 6, 6);
    doc.rect(173, 177, 6, 6);
    doc.rect(180, 177, 6, 6);
    doc.rect(187, 177, 6, 6);

    doc.text(
        'exercise my cooling-off rights during this cooling-off period and withdraw my ',
        65,
        190
    );
    doc.text(
        'intention to purchase the vehicle. I exercise my rights by signing this notice and ',
        65,
        195
    );
    doc.text('giving it to the dealer.', 65, 200);
    doc.text(
        'Buyers full name: ....................................................................................................',
        65,
        210
    );
    doc.text('Date: _______ / _______ / ______________   Signature:', 65, 217);

    // PAGE 3
    doc.addPage();
    pageCount += 1;
    doc.setPage(pageCount);

    // STATUTORY WARRANTY
    doc.rect(15, 16, 180, 12, 'F');
    doc.rect(15, 16, 180, 260);

    doc.setFont('helvetica', 'bold')
        .setFontSize(15)
        .setTextColor(240, 240, 240);
    doc.text('Statutory Warranty', 17, 23, { align: 'left' });

    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('This motor vehicle (tick whichever applies):', 22, 32);

    doc.setFont('helvetica', 'bold').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('HAS', 34, 40);
    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('a Statutory Warranty', 42, 40);
    doc.rect(27, 36.5, 5, 5);

    doc.setFont('helvetica', 'bold').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('DOES NOT', 100, 40);
    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('have a Statutory Warranty', 119, 40);
    doc.rect(93, 36.5, 5, 5);

    doc.text(
        'Name of warrantor (licensee who owns the vehicle at the time of sale):',
        22,
        50
    );
    doc.text(
        '..............................................................................................................................................................',
        23,
        58
    );

    doc.text(
        'Business address of warrantor (if different to licensee):',
        22,
        68
    );
    doc.text(
        '..............................................................................................................................................................',
        23,
        76
    );
    doc.text(
        'Suburb: ............................................................................. State                        Postcode',
        23,
        84
    );
    doc.rect(123, 79, 6, 6);
    doc.rect(130, 79, 6, 6);
    doc.rect(137, 79, 6, 6);
    doc.rect(160, 79, 6, 6);
    doc.rect(167, 79, 6, 6);
    doc.rect(174, 79, 6, 6);
    doc.rect(181, 79, 6, 6);
    doc.rect(188, 79, 6, 6);

    doc.text('Warrantor contact detail:', 23, 90);
    doc.text(
        'Phone: _____________________________         Email: _______________________________________',
        23,
        96
    );

    doc.text(
        'The warranty period for a warranted vehicle (a vehicle that on the day of its sale has an odometer reading of ',
        23,
        107
    );
    doc.text(
        'less than 160,000 km AND its build date is no more than 10 years before the day of its sale).',
        23,
        113
    );
    doc.text(
        'The warranty period STARTS when the buyer takes possession of the warranted vehicle and ENDS:',
        37,
        119
    );
    doc.text(
        'at 5:00pm on the first day that is not a Sunday or public holiday, three months after the day ',
        51,
        125
    );
    doc.text(
        "the vehicle is sold when the warrantor's place of business is open for business",
        51,
        131
    );
    doc.text(
        'at the time the vehicle travels 5,000km after it is sold; whichever happens first.',
        51,
        137
    );
    doc.text(
        'The warranty period is extended by 1 day for each day or part of a day the warranted vehicle is not in ',
        37,
        143
    );
    doc.text('the possession of the buyer of the vehicle.', 37, 149);

    doc.circle(35, 118.1, 0.5, 'F');
    doc.circle(49, 124.1, 0.4, 'F');
    doc.circle(49, 136.1, 0.4, 'F');
    doc.circle(35, 142.1, 0.5, 'F');

    doc.text(
        'The buyer took possession of the vehicle on _______ / _______ / ______________',
        23,
        158
    );

    doc.setFont('helvetica', 'bold').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('The statutory warranty covers', 23, 170);
    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('The warrantor of a warranted vehicle guarantees that-', 23, 176);
    doc.text(
        'the vehicle is free from defects at the time of taking possession and for the warranty period; and',
        37,
        181
    );
    doc.text(
        'defects in the vehicle reported during the warranty period will be repaired by the warrantor free of ',
        37,
        186
    );
    doc.text('charge.', 37, 191);
    doc.setFont('helvetica', 'bold').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('defects', 23, 196);
    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text(
        'does not include defects not covered by the statutory warranty',
        36.5,
        196
    );
    doc.text(
        '(* A warranted vehicle has a defect if a part of the vehicle does not perform its intended function OR has ',
        23,
        201
    );
    doc.text(
        'deteriorated to an extent where it can not reasonably be relied on to perform its intended function.)',
        23,
        206
    );

    doc.circle(35, 180.1, 0.5, 'F');
    doc.circle(35, 185.1, 0.5, 'F');

    doc.setFont('helvetica', 'bold').setFontSize(10).setTextColor(0, 0, 0);
    doc.text('Warranties and mandatory text', 22, 215);

    doc.setFont('helvetica', 'normal');
    doc.text(
        'From 1 January 2012, regulation 90 of the Competition and Consumer Regulations 2010 requires that ',
        22,
        219
    );
    doc.text(
        "any document that evidences a 'warranty against defects' must be transparent and state:",
        22,
        223
    );
    doc.text(
        'what the business giving the warranty must do if goods are faulty or defective (eg: repair or replace the',
        27,
        228
    );
    doc.text(' goods)', 27, 232);
    doc.text(
        'what the consumer must do to claim under the warranty (eg: not misuse the goods)',
        27,
        238
    );
    doc.text(
        'the name, business address, telephone number and email address (if any) of the business giving the',
        27,
        243
    );
    doc.text('warranty', 27, 247);
    doc.text(
        'the warranty period (ie: how long the warranty lasts for)',
        27,
        252
    );
    doc.text(
        'whether the business or the consumer is responsible for expenses associated with a warranty claim and ',
        27,
        258
    );
    doc.text(
        'how the consumer can claim back any expenses incurred and',
        27,
        262
    );
    doc.text(
        'that the benefits provided to the consumer by the warranty are in addition to other rights and remedies ',
        27,
        267
    );
    doc.text('available to the consumer under the law.', 27, 271);

    doc.circle(25, 227.1, 0.5, 'F');
    doc.circle(25, 237.1, 0.5, 'F');
    doc.circle(25, 242.1, 0.5, 'F');
    doc.circle(25, 251.1, 0.5, 'F');
    doc.circle(25, 257.1, 0.5, 'F');
    doc.circle(25, 266.1, 0.5, 'F');

    // PAGE 4
    doc.addPage();
    pageCount += 1;
    doc.setPage(pageCount);

    // STATUTORY WARRANTY CONTINUED
    doc.rect(15, 16, 180, 12, 'F');
    doc.rect(15, 16, 180, 250);

    doc.setFont('helvetica', 'bold')
        .setFontSize(15)
        .setTextColor(240, 240, 240);
    doc.text('Statutory Warranty Continued', 17, 23, { align: 'left' });

    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text(
        'Under regulation 90(1)(c) and (2) all warranties against defects must also state verbatim the following ',
        22,
        32
    );
    doc.text('prescribed text:', 22, 37);
    doc.text(
        "'Our goods come with guarantees that cannot be excluded under the Australian Consumer Law, You are ",
        22,
        41
    );
    doc.text(
        'entitled to a replacement or refund for a major failure and compensation for any other reasonable foreseeable ',
        22,
        45
    );
    doc.text(
        'loss or damage. You are also entitled to have the goods repaired or replaced if the goods fail to be of ',
        22,
        49
    );
    doc.text(
        "acceptable quality and the failure does not amount to a major failure'.",
        22,
        53
    );

    doc.setFont('helvetica', 'bold');
    doc.text('The statutory warranty DOES NOT cover:', 22, 59);

    doc.setFont('helvetica', 'normal');
    doc.text(
        "a defect in the vehicle's paintwork or upholstery that should have been apparent on any reasonable ",
        37,
        65
    );
    doc.text(
        'inspection of the vehicle before the time of taking possession;',
        37,
        70
    );
    doc.text('a defect after the time of taking possession-', 37, 75);
    doc.text(
        'arising from or incidental to any accidental damage to the vehicle; or',
        61,
        79
    );
    doc.text("arising from the buyer's misuse or negligence; or", 61, 83);
    doc.text(
        'in an accessory to the vehicle not fitted to the vehicle when sold to the buyer;',
        61,
        87
    );
    doc.text("a defect in the vehicle's fitted airbag", 37, 92);
    doc.text(
        "a defect in the vehicle's installed audio entertainment device; for example: a radio, tape recorder, CD ",
        37,
        97
    );
    doc.text(
        'player or a digital audio device, that performs a comparable function; and a defect in any of the ',
        37,
        101
    );
    doc.text('following-', 37, 106);

    doc.text('a tyre of tyre tube;', 61, 110);
    doc.text('a battery;', 61, 114);
    doc.text(
        'a light other than a warning light or a turn indicator light used as a hazard light',
        61,
        118
    );
    doc.text('a radiator hose', 61, 122);
    doc.text('a radio aerial or other aerial;', 61, 126);
    doc.text('spark plugs;', 61, 130);
    doc.text('distributor points;', 61, 134);
    doc.text('wiper rubbers;', 61, 138);
    doc.text('oil or an oil filter;', 61, 142);
    doc.text('a fuel filter or air filter;', 61, 146);
    doc.text('a hose for a heater unit.', 61, 150);

    doc.circle(35, 64.1, 0.5, 'F');
    doc.circle(35, 69.1, 0.5, 'F');
    doc.circle(35, 74.1, 0.5, 'F');
    doc.circle(59, 78.1, 0.4, 'F');
    doc.circle(59, 82.1, 0.4, 'F');
    doc.circle(59, 86.1, 0.4, 'F');
    doc.circle(35, 91.1, 0.5, 'F');
    doc.circle(35, 96.1, 0.5, 'F');

    doc.circle(59, 109.1, 0.4, 'F');
    doc.circle(59, 113.1, 0.4, 'F');
    doc.circle(59, 117.1, 0.4, 'F');
    doc.circle(59, 121.1, 0.4, 'F');
    doc.circle(59, 125.1, 0.4, 'F');
    doc.circle(59, 129.1, 0.4, 'F');
    doc.circle(59, 133.1, 0.4, 'F');
    doc.circle(59, 137.1, 0.4, 'F');
    doc.circle(59, 141.1, 0.4, 'F');
    doc.circle(59, 145.1, 0.4, 'F');
    doc.circle(59, 149.1, 0.4, 'F');

    doc.setFont('helvetica', 'bold');
    doc.text(
        'The following used vehicles are NOT covered by this statutory warranty:',
        22,
        156
    );
    doc.setFont('helvetica', 'normal');
    doc.text('an unregistered motor vehicle that is-', 37, 161);
    doc.text(
        'incapable of being registered in Queensland because of its design; or',
        61,
        165
    );
    doc.text('a written-off vehicle; or', 61, 169);
    doc.text(
        'a motor vehicle solid on consignment, unless the owner of the vehicle is a motor dealer or auctioneer',
        37,
        174
    );
    doc.text('; or', 37, 178);
    doc.text('a caravan; or', 37, 182);
    doc.text('a motorcycle; or', 37, 187);
    doc.text('a commercial vehicle.', 37, 192);

    doc.circle(35, 160.1, 0.5, 'F');
    doc.circle(59, 164.1, 0.4, 'F');
    doc.circle(59, 168.1, 0.4, 'F');
    doc.circle(35, 173.1, 0.5, 'F');
    doc.circle(35, 181.1, 0.5, 'F');
    doc.circle(35, 186.1, 0.5, 'F');
    doc.circle(35, 191.1, 0.5, 'F');

    doc.text(
        'A commercial vehicle is a vehicle built mainly for carrying or hauling goods or designed to carry more ',
        32,
        198
    );
    doc.text(
        'than nine persons. However, a utility with a normal load carrying capacity of one tonne or less is not a ',
        32,
        202
    );
    doc.text('commercial vehicle and has a Statutory Warranty.', 32, 206);

    doc.setFont('helvetica', 'bold');
    doc.text("Buyer's obligation regarding warranty", 22, 212);
    doc.setFont('helvetica', 'normal');
    doc.text(
        'If a repair is required under statutory warranty, the buyer must give written notice to the warrantor prior ',
        32,
        217
    );
    doc.text('to repair.', 32, 222);

    doc.text(
        'Delivery of motor vehicle when making claim on statutory warrantty- Once the buyer gives written notice ',
        32,
        227
    );
    doc.text(
        'of the defect to the warrantor, the buyer is advised that it is their obligation to deliver the motor vehicle ',
        32,
        231
    );
    doc.text(
        'that is to be repaired under the statutory warranty to the warrantor, or a qualified repairer nominated in ',
        32,
        235
    );
    doc.text(
        "writing by the warrantor, if the motor vehicle is within 200 kms of the warrantor's place of business.",
        32,
        239
    );

    doc.text(
        "If the motor vehicle is located more than 200 kms from the warrantor's place of business at the time the ",
        32,
        244
    );
    doc.text(
        'buyer gives written notice to the warrantor 0f the defect, the buyer must deliver the motor vehicle to the ',
        32,
        248
    );
    doc.text(
        'closest qualified repairer nominated in writing by the warrantor; or otherwise deliver the vehicle, at the ',
        32,
        252
    );
    doc.text(
        "warrantor's expense, to another qualified repairer nominated in writing by the warrantor.",
        32,
        256
    );

    // PAGE 5
    doc.addPage();
    pageCount += 1;
    doc.setPage(pageCount);

    // MOTOR DEALER/CHATTEL AUCTIONEER DECLARATION
    doc.rect(15, 16, 180, 12, 'F');
    doc.rect(15, 16, 180, 95);

    doc.setFont('helvetica', 'bold')
        .setFontSize(15)
        .setTextColor(240, 240, 240);
    doc.text('Motor Dealer/Chattel Auctioneer Declaration', 17, 23, {
        align: 'left'
    });

    doc.setFont('helvetica', 'normal').setFontSize(10).setTextColor(0, 0, 0);
    doc.text(
        ` I .............................................................................................................(motordealer/chattel auctioneer), declare:`,
        16,
        35
    );

    doc.text(
        'There is no prior contract with another buyer to purchase this vehicle (motor dealer only).',
        23,
        43
    );
    doc.text(
        'I have not attempted to persuade the buyer to forfeit their cooling-off rights (motor dealer only).',
        23,
        50
    );
    doc.text('This motor vehicle has a statutory warranty.', 23, 57);
    doc.text('This motor vehicle does not have a statutory warranty.', 23, 64);
    doc.text(
        'I have not attempted to persuade the buyer to forfeit their statutory warranty.',
        23,
        71
    );
    doc.text(
        'I have informed the buyer if the vehicle is an unregistered written-off vehicle or was previously written off.',
        23,
        78
    );
    doc.text(
        `Motor dealer/chattel auctioneer ....................................................................................................................

    Date _______ / _______ / ______________               Signature:`,
        23,
        85
    );

    // prefilled motor dealer/chattel auctioneer name/ signature / date
    if (fillInformation) {
        doc.text(settings.companyName, 23, 34);
        doc.text(settings.companyName, 75, 84);

        if (base64Image) {
            //declare time, day, month, year, default as current time
            let now = dayjs();
            let day = now.format('DD');
            let month = now.format('MM');
            let year = now.format('YYYY');
            doc.text(day, 40, 92);
            doc.text(month, 55, 92);
            doc.text(year, 75, 92);

            // witness signature
            let sigImg = document.createElement('img');
            sigImg.src = base64Image;
            sigImg.className = 'signature';
            userSignature = sigImg;
            doc.addImage(userSignature, 130, 90, 50, 20);
        }
    }

    // Check Box for Motor Dealer/Chattel Auctioneer Declaration
    doc.roundedRect(17.5, 40, 4, 4, 0.1, 0.1);
    doc.roundedRect(17.5, 47, 4, 4, 0.1, 0.1);
    doc.roundedRect(17.5, 54, 4, 4, 0.1, 0.1);
    doc.roundedRect(17.5, 61, 4, 4, 0.1, 0.1);
    doc.roundedRect(17.5, 68, 4, 4, 0.1, 0.1);
    doc.roundedRect(17.5, 75, 4, 4, 0.1, 0.1);
    return pageCount;
};

export default UsedDeclarations;
