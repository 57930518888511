// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Creditor } from '../interfaces/GeneralInterface';
// LOGIC
import api from '../../../../api';

/**
 * GetCreditors
 * Get the full details of creditors from an array of IDs
 * @author Estienne
 * @param creditorIds the IDs of the required creditors
 * @param setCreditors react state setter for Creditors
 */
const GetCreditors = (
  creditorIds: any,
  setCreditors: Dispatch<SetStateAction<Creditor[]>>
) => {
  api.get(`getCreditorsDetails/${creditorIds}`).then((res) => {
    setCreditors(res.data);
  });
};

export default GetCreditors;
