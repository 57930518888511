import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';
import { Brand } from '../../../global/interfaces/PartsInterface';

const DeleteBrand = (
    brand: Brand,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    api.delete('brand/' + brand.id).then((res) => {
        if (res.status === 200) {
            showSnackbar('success', 'Brand successfully deleted.');
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar(
                'Unable to delete Brand.',
                'There are parts registered with this brand.',
                'error'
            );
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Something went wrong, please refresh the page and try again.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default DeleteBrand;
