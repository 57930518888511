const BlankVehicleBox = (doc) => {
  doc.setFont('helvetica', 'bold').setFontSize(9);
  doc.rect(107, 70, 93, 60); // The box around vehicle details

  doc.text('VEHICLE BEING PURCHASED', 155, 74, { align: 'center' });
  doc.line(107, 76, 200, 76); // Horizontal line below title

  doc.setFont('helvetica', 'normal').setFontSize(7);
  doc.text('Vehicle:', 109, 80.75);
  doc.line(107, 82.75, 200, 82.75); // Horizontal line below vehicle description

  doc.text('Condition:', 109, 87.5);
  doc.line(107, 89.5, 200, 89.5); // Horizontal line below condition

  doc.text('Body', 109, 93);
  doc.text('Type:', 109, 95.25);
  doc.line(155, 89.5, 155, 96.25); // Vertical line between body type and registration number
  doc.text('Registration', 156, 93);
  doc.text('Number:', 156, 95.25);
  doc.line(107, 96.25, 200, 96.25); // Horizontal line below body type and registration number

  doc.text('VIN / Chassis No:', 109, 101);
  doc.line(107, 103, 200, 103); // Horizontal line below vin

  doc.text('Engine Number:', 109, 107.75);
  doc.line(107, 109.75, 200, 109.75); // Horizontal line below engine number

  doc.text('Model', 109, 113.25);
  doc.text('Year:', 109, 115.5);
  doc.line(155, 109.75, 155, 116.5); // Vertical line between year and compliance
  doc.text('Compliance', 156, 113.25);
  doc.text('Plate Date:', 156, 115.5);
  doc.line(107, 116.5, 200, 116.5); // Horizontal line below year and compliance

  doc.text('Odometer', 109, 120).text('kms', 154, 120, { align: 'right' });
  doc
    .text('Reading:', 109, 122.25)
    .text('mls', 154, 122.25, { align: 'right' });
  doc.line(155, 116.5, 155, 123.25); // Vertical line between odometer and colour
  doc.text('Body', 156, 120);
  doc.text('Colour:', 156, 122.25);
  doc.line(107, 123.25, 200, 123.25); // Horizontal line below odometer and colour

  doc.text('Stock No:', 109, 128);
  doc.line(155, 123.25, 155, 130); // Vertical line between stock number and delivery date
  doc.text('Est. Delivery Date:', 156, 128);
};

export default BlankVehicleBox;
