export const documentationData = [
    {
        pageTitle: 'Accounts',
        sections: [
            {
                id: 'add-account',
                title: 'Add an account',
                description: 'To create a new account, follow these steps:',
                href: '/support/admin/accounts/#add-account'
            },
            {
                id: 'update-account',
                title: 'Update an account',
                description:
                    'To update an existing account, follow these steps:',
                href: '/support/admin/accounts/#update-account'
            },
            {
                id: 'delete-account',
                title: 'Delete an account',
                description:
                    'Deleting an account will also remove all its associated transactions, invoices, and orders. To delete an existing account, follow these steps:',
                href: '/support/admin/accounts/#delete-account'
            },
            {
                id: 'merge-account',
                title: 'Merge accounts',
                description:
                    'You can merge two accounts, the account to be merged will be deleted, but all its associated transactions, orders, invoices etc will be transferred to the remaining account. To merge existing accounts, follow these steps:',
                href: '/support/admin/accounts/#merge-accounts'
            },
            {
                id: 'search-accounts',
                title: 'Search accounts',
                description:
                    'Currently, the system supports searching by account name, account ID, account type, and location. Filters are currently supported for Account Create Date, Account Type, and Site. To search or filter accounts, follow these steps:',
                href: '/support/admin/accounts/#search-accounts'
            },
            {
                id: 'export-account',
                title: 'Export accounts to CSV',
                description:
                    'To export all accounts as a CSV file, follow these steps:',
                href: '/support/admin/accounts/#export-account'
            }
        ]
    },
    {
        pageTitle: 'Enter Credit Invoice',
        sections: [
            {
                id: 'enter-credit-invoice',
                title: 'Enter New Credit Invoice',
                description:
                    'To enter a new credit invoice, follow these steps:',
                href: '/support/admin/creditInvoice/#enter-credit-invoice'
            },
            {
                id: 'view-edit-credit-invoice',
                title: 'View And Edit Credit Invoice',
                description:
                    'To view and edit credit invoice, follow these steps:',
                href: '/support/admin/creditInvoice/#view-edit-credit-invoice'
            }
        ]
    },
    {
        pageTitle: 'Creditors',
        sections: [
            {
                id: 'add-creditor',
                title: 'Add new creditor',
                description: 'To create new creditor, follow these steps:',
                href: '/support/admin/creditors/#add-creditor'
            },
            {
                id: 'view-and-update-creditor',
                title: 'View and update creditor details',
                description:
                    'To view or update details of an existing creditor, follow these steps:',
                href: '/support/admin/creditors/#view-and-update-creditor'
            },
            {
                id: 'print-account-statement',
                title: 'Print account statement',
                description:
                    'This function prints all invoices associated with a creditor within a selected date range. To print an account statement, follow these steps:',
                href: '/support/admin/creditors/#print-account-statement'
            }
        ]
    },
    {
        pageTitle: 'Enter Admin Invoice',
        sections: [
            {
                id: 'enter-generic-invoice',
                title: 'Enter Generic Invoice',
                description:
                    'To enter a generic invoice for account, vehicle or service, follow these steps:',
                href: '/support/admin/enterInvoice/#enter-generic-invoice'
            },
            {
                id: 'enter-registration-invoice',
                title: 'Enter Registration Invoice',
                description:
                    'To enter a registration invoice for vehicles, follow these steps:',
                href: '/support/admin/enterInvoice/#enter-registration-invoice'
            },
            {
                id: 'enter-vehicle-rebate-invoice',
                title: 'Enter Vehicle Rebate Invoice',
                description:
                    'To enter a vehicle rebate invoice for vehicles, follow these steps:',
                href: '/support/admin/enterInvoice/#enter-vehicle-rebate-invoice'
            },
            {
                id: 'enter-warranty-invoice',
                title: 'Enter Warranty Invoice',
                description:
                    'To enter a warranty invoice for services, follow these steps:',
                href: '/support/admin/enterInvoice/#enter-warranty-invoice'
            },
            {
                id: 'enter-trust-receipt-invoice',
                title: 'Enter Trust Receipt Invoice',
                description:
                    'To enter a trust receipt invoice for vehicles, follow these steps:',
                href: '/support/admin/enterInvoice/#enter-trust-receipt-invoice'
            },
            {
                id: 'edit-invoice',
                title: 'View And Edit Invoice',
                description:
                    'To view or edit a specific invoice, follow these steps:',
                href: '/support/admin/enterInvoice/#edit-invoice'
            }
        ]
    },
    {
        pageTitle: 'General Journal Entry',
        sections: [
            {
                id: 'add-general-journal-entry',
                title: 'Add new General Journal Entry',
                description:
                    'To add a new General Journal Entry, follow these steps:',
                href: '/support/admin/generalAccountingEntry/#add-general-journal-entry'
            }
        ]
    },
    {
        pageTitle: 'Manual Transaction',
        sections: [
            {
                id: 'add-manual-transaction',
                title: 'Add new Manual Transaction',
                description:
                    'To add a new Manual Transaction, follow these steps:',
                href: '/support/admin/manualTransaction/#add-manual-transaction'
            }
        ]
    },
    {
        pageTitle: 'Bank Reconciliation',
        sections: [
            {
                id: 'upload-transaction-file',
                title: 'Upload Transaction File',
                description:
                    'From uploading a bank transaction file to deleting bank transactions or entire transaction files, follow these steps:',
                href: '/support/admin/bankReconciliation/#upload-transaction-file'
            },
            {
                id: 'bank-reconciliator',
                title: 'Bank Reconciliator',
                description:
                    'After uploading a bank transaction file, follow these steps to reconcile transactions:',
                href: '/support/admin/bankReconciliation/#bank-reconciliator'
            },
            {
                id: 'reconciliation-history',
                title: 'Reconciliation History',
                description:
                    'View your reconciliation history on each day with these steps:',
                href: '/support/admin/bankReconciliation/#reconciliation-history'
            }
        ]
    },
    {
        pageTitle: 'Creditor Reconciliation',
        sections: [
            {
                id: 'creditor-reconciliation',
                title: 'Creditor Reconciliation',
                description:
                    'This guide outlines the steps for ready invoices (move invoice from Unconfirmed Invoices to Ready Invoices), unready invoices, finalize and pay for ready invoices, generate an ABA file, regenerate ABA file or payment remittance, and generate creditor balance report, .',
                href: '/support/admin/creditors/#add-creditor'
            }
        ]
    },
    {
        pageTitle: 'Debtor Reconciliation',
        sections: [
            {
                id: 'debtor-reconciliation',
                title: 'Debtor Reconciliation',
                description:
                    'To make account payments, manually mark invoices as paid, generate and email account statements, and produce Debtor Balance Statements, follow these steps:',
                href: '/support/admin/debtorReconciliation/#debtor-reconciliation'
            }
        ]
    },
    {
        pageTitle: 'Rebate Reconciliator',
        sections: [
            {
                id: 'rebate-reconciliator',
                title: 'Rebate Reconciliator',
                description:
                    'To reconcile vehicle rebate and reconcile vehicle sale, follow these steps:',
                href: '/support/admin/rebateReconciliation/#rebate-reconciliator'
            }
        ]
    },
    {
        pageTitle: 'Rego Reconciliator',
        sections: [
            {
                id: 'rego-reconciliator',
                title: 'Rego Reconciliator',
                description:
                    'To reconcile vehicle registrations, follow these steps:',
                href: '/support/admin/registrationReconciliation/#rego-reconciliator'
            }
        ]
    },
    {
        pageTitle: 'Ora Generated Reports',
        sections: [
            {
                id: 'pnl-reports',
                title: 'PNL Reports',
                description:
                    'To generate Profit & Loss (PnL) reports for one or more sites over a specified period and commit PnL data, follow these steps:',
                href: '/support/admin/reporting/#pnl-reports'
            },
            {
                id: 'gst-reports',
                title: 'GST Reports',
                description:
                    'To generate Goods and Services Tax (GST) reports for one or more sites over a specified period, follow these steps:',
                href: '/support/admin/reporting/#gst-reports'
            },
            {
                id: 'staff-sales-reports',
                title: 'Staff Sales Reports',
                description:
                    'To generate sales reports for staff members at one or more sites over a specified period, follow these steps:',
                href: '/support/admin/reporting/#staff-sales-reports'
            },
            {
                id: 'balance-sheet',
                title: 'Balance Sheet',
                description:
                    'To generate a balance sheet report for one or all sites over a specified period, follow these steps:',
                href: '/support/admin/reporting/#balance-sheet'
            }
        ]
    },
    {
        pageTitle: 'Time Clock',
        sections: [
            {
                id: 'time-clock',
                title: 'Time Clock',
                description:
                    'To clock on/off and view time clock history records, follow these steps:',
                href: '/support/admin/timeclock/#time-clock'
            }
        ]
    },
    {
        pageTitle: 'Customers',
        sections: [
            {
                id: 'add-customer',
                title: 'Add new customer',
                description: 'To create new customer, follow these steps:',
                href: '/support/customers/#add-customer'
            },
            {
                id: 'update-customer',
                title: 'Update existing customer',
                description: 'To edit customer, follow these steps:',
                href: '/support/customers/#update-customer'
            },
            {
                id: 'search-customer',
                title: 'Search customer',
                description:
                    'To search customer or filter customers, follow these steps:',
                href: '/support/customers/#search-customer'
            },
            {
                id: 'merge-customer',
                title: 'Merge existing customers',
                description: 'To merge customers, follow these steps:',
                href: '/support/customers/#merge-customer'
            },
            {
                id: 'export-customer',
                title: 'Export customer to CSV',
                description:
                    'To export current customer result into a CSV file, follow these steps:',
                href: '/support/customers/#export-customer'
            }
        ]
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: 'Inventory',
        sections: [
            {
                id: 'add-part',
                title: 'Add new part',
                description: 'To add a new part, follow these steps:',
                href: '/support/inventory/search/#add-part'
            },
            {
                id: 'update-part',
                title: 'Update existing part',
                description:
                    'To edit details of an existing part, follow these steps:',
                href: '/support/inventory/search/#update-part'
            },
            {
                id: 'delete-part',
                title: 'Delete existing part',
                description: 'To delete an existing part, follow these steps:',
                href: '/support/inventory/search/#delete-part'
            },
            {
                id: 'search-part',
                title: 'Search part',
                description: 'To search or filter parts, follow these steps:',
                href: '/support/inventory/search/#search-part'
            },
            {
                id: 'merge-part',
                title: 'Merge existing parts',
                description: 'To merge two existing part, follow these steps:',
                href: '/support/inventory/search/#merge-part'
            },
            {
                id: 'transfer-part',
                title: 'Transfer parts',
                description:
                    'To transfer parts between different sites, follow these steps: ',
                href: '/support/inventory/search/#transfer-part'
            },
            {
                id: 'export-part',
                title: 'Export parts to CSV',
                description:
                    'To export current part result into a CSV file, follow these steps:',
                href: '/support/inventory/search/#export-part'
            },
            {
                id: 'import-supplier-file',
                title: 'Import supplier files',
                href: '/support/inventory/search/#import-supplier-file'
            },
            {
                id: 'stock-adjustment',
                title: 'Stock Adjustment',
                description:
                    'The Stock Adjustment feature allows you to manually update the stock levels of specific parts. This tool provides options to adjust stock quantities in your inventory, modify average costs, and review all historical adjustments.',
                href: '/support/inventory/search/#stock-adjustment'
            },
            {
                id: 'bin-location',
                title: 'Bin Locations',
                description:
                    'The Bin Locations feature allows you to manage and configure the default for a specific site, to set up a default bin or delete bin, follow these steps:',
                href: '/support/inventory/search/#bin-location'
            }
        ]
    },
    {
        pageTitle: 'Overview',
        sections: [
            {
                id: 'login',
                title: 'Login',
                description:
                    'To log in, log out, and reset password, follow these steps:',
                href: '/support/#login'
            },
            {
                id: 'home',
                title: 'Home Page',
                description:
                    'To navigate the home page and access various features, such as site switching, clocking on and off, updating your personal settings, setting up daily tasks and more options, follow these steps:',
                href: '/support/#home'
            },
            {
                id: 'manage-users',
                title: 'Manage users',
                description:
                    'To add new users, update existing users, reset user passwords, update user permissions, and more, follow these steps:',
                href: '/support/#manage-users'
            },
            {
                id: 'manage-site-settings',
                title: 'Manage site settings',
                description:
                    'To view and update existing site settings, follow these steps:',
                href: '/support/#manage-site-settings'
            },
            {
                id: 'company-settings',
                title: 'Company settings',
                description:
                    'To view and update company settings including business details, payment methods, sale warranties, departments, current and future registration/CTP prices, follow these steps:',
                href: '/support/#company-settings'
            },
            {
                id: 'specifics-management',
                title: 'Specifics management',
                description:
                    'To manage specifics, such as make, model, series, etc, follow these steps:',
                href: '/support/#specifics-management'
            },
            {
                id: 'manage-email-text-templates',
                title: 'Manage email & text templates',
                description:
                    'To update existing email and text templates and create new templates, follow these steps:',
                href: '/support/#manage-email-text-templates'
            }
        ]
    },
    {
        pageTitle: 'Backorders',
        sections: [
            {
                id: 'add-customer-part-sale',
                title: 'Create backorder in a customer part sale',
                href: '/support/inventory/backorders/#add-customer-part-sale'
            },
            {
                id: 'order-backorders',
                title: 'Order backorders from supplier',
                description:
                    'After a customer places backorders, we need to order the parts from our supplier. Follow the steps below to order backorder parts:',
                href: '/support/inventory/backorders/#order-backorders'
            },
            {
                id: 'receive-backorders',
                title: 'Receive backorders from supplier',
                description:
                    'After ordering backorder parts from the supplier, we need to receive the parts. Follow the steps below to receive backorder parts:',
                href: '/support/inventory/backorders/#receive-backorders'
            }
        ]
    },
    {
        pageTitle: 'Parts Sales',
        sections: [
            {
                id: 'add-customer-part-sale',
                title: 'Add new parts sale for a customer',
                description:
                    'To add a new parts sale for a customer, follow these steps:',
                href: '/support/inventory/newSale/#add-customer-part-sale'
            },
            {
                id: 'add-vehicle-contract-part-sale',
                title: 'Add new part sale for a vehicle contract',
                description:
                    'To adda new parts sale for an existing vehicle contract, follow these steps:',
                href: '/support/inventory/newSale/#add-vehicle-contract-part-sale'
            }
        ]
    },
    {
        pageTitle: 'Sales',
        sections: [
            {
                id: 'edit-sales',
                title: 'View and edit existing part sale for customer',
                description:
                    'To view or edit details of an exsiting part sales for customer, follow these steps:',
                href: '/support/parts/sales/#edit-sales'
            },
            {
                id: 'edit-vehicle-contract-sales',
                title: 'View and edit existing part sale for vehicle contract ',
                description:
                    'To view or edit details of an exsiting part sales for vehicle contract, follow these steps:',
                href: '/support/parts/sales/#edit-vehicle-contract-sales'
            },
            {
                id: 'search-sales',
                title: 'Search sales',
                description: 'To search or filter sales, follow these steps:',
                href: '/support/parts/sales/#search-sales'
            },
            {
                id: 'sales-credit-invoice',
                title: 'Sales credit invoice',
                description:
                    'To add a credit invoice for a specific sales, follow these steps:',
                href: '/support/parts/sales/#sales-credit-invoice'
            },
            {
                id: 'view-part-sales-log',
                title: 'View part sales log',
                description:
                    'To view change history of an existing parts sale, follow these steps:',
                href: '/support/parts/sales/#view-part-sales-log'
            },
            {
                id: 'text-customer-part-sales',
                title: 'Text part sales to customer',
                description:
                    'To text customer about a part sale, follow these steps:',
                href: '/support/parts/sales/#text-customer-part-sales'
            }
        ]
    },
    {
        pageTitle: 'Vouchers',
        sections: [
            {
                id: 'add-new-voucher',
                title: 'Add new gift voucher',
                description: 'To add new gift voucher, follow these steps:',
                href: '/support/inventory/vouchers/#add-new-voucher'
            },
            {
                id: 'pay-with-voucher',
                title: 'Make payment with gift voucher',
                description:
                    'To make payment with gift voucher, follow these steps:',
                href: '/support/inventory/vouchers/#pay-with-voucher'
            }
        ]
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: 'Bookings',
        sections: [
            {
                id: 'bookings',
                title: 'Manage service bookings',
                description:
                    'To manage existing service bookings, follow these outlined steps:',
                href: '/support/service/bookings/#bookings'
            },
            {
                id: 'edit-retail-booking',
                title: 'Edit a retail service booking',
                description:
                    'To edit an existing retail booking, follow these outlined steps:',
                href: '/support/service/bookings/#edit-retail-booking'
            }
        ]
    },
    {
        pageTitle: 'Calendar',
        sections: [
            {
                id: 'calendar',
                title: 'Service Calendar',
                description:
                    'The service calendar is where you can view and manage service bookings in a monthly, fortnightly, weekly or technician view. Follow these steps to navigate the service calendar:',
                href: '/support/service/calendar/#calendar'
            }
        ]
    },
    {
        pageTitle: 'New Booking',
        sections: [
            {
                id: 'new-retail-booking',
                title: 'Create retail service booking',
                description:
                    'To create a new retail service booking, follow these outlined steps:',
                href: '/support/service/newBooking/#new-retail-booking'
            },
            {
                id: 'new-insurance-booking',
                title: 'Create insurance service booking',
                description:
                    'To create a new insurance service booking, follow these outlined steps:',
                href: '/support/service/newBooking/#new-insurance-booking'
            }
        ]
    },
    {
        pageTitle: 'Payment',
        sections: [
            {
                id: 'service-payment',
                title: 'Service Payment',
                description:
                    'To finalise payment for a service booking, follow these outlined steps:',
                href: '/support/service/payment/#service-payment'
            }
        ]
    },
    {
        pageTitle: 'Purchase Order',
        sections: [
            {
                id: 'new-purchase-order',
                title: 'Create a new purchase order',
                description:
                    'To create a new purchase order, follow these outlined steps:',
                href: '/support/service/purchaseOrders/#new-purchase-order'
            },
            {
                id: 'invoice-purchase-order',
                title: 'Invoice a purchase order',
                description:
                    'To invoice a purchase order, follow these outlined steps:',
                href: '/support/service/purchaseOrders/#invoice-purchase-order'
            }
        ]
    },
    {
        pageTitle: 'Credit Stock Invoice',
        sections: [
            {
                id: 'credit-stock-invoice',
                title: 'Credit stock invoice',
                description:
                    'To return parts to a supplier, we need to credit a new stock invoice. Follow these outlined steps:',
                href: '/support/inventory/creditInvoice/#credit-stock-invoice'
            }
        ]
    },
    {
        pageTitle: 'Enter Stock Invoice',
        sections: [
            {
                id: 'enter-stock-invoice',
                title: 'Invoice existing stock order',
                description:
                    'After placed stock orders have been received, you can enter the stock invoice to update the stock levels in the system. To invoice an existing stock order, follow these outlined steps:',
                href: '/support/inventory/stockInvoiceNoPO/#enter-stock-invoice'
            },
            {
                id: 'create-new-stock-invoice',
                title: 'Create new stock invoice',
                description:
                    'If you want to create a new stock invoice, follow these outlined steps:',
                href: '/support/inventory/stockInvoiceNoPO/#create-new-stock-invoice'
            }
        ]
    },
    {
        pageTitle: 'New Stock Order',
        sections: [
            {
                id: 'new-stock-order',
                title: 'New stock order',
                description:
                    "To create a new stock order, you can manually add parts, import all customer backorders, or move selected creditor's backorders to the stock order. Follow these outlined steps:",
                href: '/support/inventory/stockorder/#new-stock-order'
            }
        ]
    },
    {
        pageTitle: 'Stock Order',
        sections: [
            {
                id: 'manage-stock-orders',
                title: 'Manage stock orders',
                description:
                    'To search and filter stock orders, and print stock order summaries, follow these outlined steps:',
                href: '/support/inventory/stockorders/#manage-stock-orders'
            }
        ]
    },
    {
        pageTitle: 'Stock Transfers',
        sections: [
            {
                id: 'stock-transfer',
                title: 'Manage stock transfer',
                description:
                    'To manage existing stock transfers and create new ones, follow these outlined steps:',
                href: '/support/inventory/stocktransfers/#stock-transfer'
            }
        ]
    },
    {
        pageTitle: '',
        sections: []
    },
    {
        pageTitle: 'Till Reconciliation',
        sections: [
            {
                id: 'submit-till-reconciliation',
                title: 'Submit Till Reconciliation',
                description:
                    'To accurately complete the till reconciliation process, follow these outlined steps:',
                href: '/support/tillReconciliation/#submit-till-reconciliation'
            },
            {
                id: 'check-till-reconciliation-history',
                title: 'Check Till Reconciliation History',
                description:
                    'To view till reconciliation history, follow these steps:',
                href: '/support/tillReconciliation/#check-till-reconciliation-history'
            }
        ]
    },
    {
        pageTitle: 'Invoice vehicles',
        sections: [
            {
                id: 'add-vehicle-invoice',
                title: 'Add new vehicle invoice',
                description:
                    'To create a new vehicle invoice, follow these steps :',
                href: '/support/vehicles/invoiceVehicles/#add-vehicle-invoice'
            },
            {
                id: 'edit-vehicle-invoice',
                title: 'Edit vehicle invoice',
                description:
                    'To view or edit exsiting vehicle invoice, follow these steps :',
                href: '/support/vehicles/invoiceVehicles/#edit-vehicle-invoice'
            },
            {
                id: 'pay-vehicle-invoice',
                title: 'Pay vehicle invoice',
                href: '/support/vehicles/invoiceVehicles/#pay-vehicle-invoice'
            }
        ]
    },
    {
        pageTitle: 'Leads Management',
        sections: [
            {
                id: 'add-new-lead',
                title: 'Add new lead',
                description:
                    'Leads management is used to track and nurture potential sales opportunities, streamline communication, and enhance customer relationship management. To create a new lead, follow these steps:',
                href: '/support/vehicles/leadsmanagement/#add-new-lead'
            },
            {
                id: 'edit-lead',
                title: 'Edit lead',
                description:
                    'Edit lead details, update lead status, and assign employees to leads by following these steps:',
                href: '/support/vehicles/leadsmanagement/#edit-lead'
            }
        ]
    },
    {
        pageTitle: 'New Contract',
        sections: [
            {
                id: 'add-vehicle-sale-contract',
                title: 'Add new vehicle sale contract',
                description:
                    'To add a new vehicle sale contract, follow these steps:',
                href: '/support/vehicles/newContract/#add-vehicle-sale-contract'
            },
            {
                id: 'add-vehicle-quote-contract',
                title: 'Add new vehicle quote contract',
                description:
                    'To add a new vehicle quote contract, follow these steps:',
                href: '/support/vehicles/newContract/#add-vehicle-quote-contract'
            },
            {
                id: 'add-vehicle-finalised-contract',
                title: 'Add new vehicle finalised contract',
                description:
                    'To add a new vehicle sale contract, follow these steps:',
                href: '/support/vehicles/newContract/#add-vehicle-finalised-contract'
            },
            {
                id: 'lost-vehicle-contract',
                title: 'Lost vehicle contract',
                description:
                    'To mark a vehicle contract as lost, follow these steps:',
                href: '/support/vehicles/newContract/#lost-vehicle-contract'
            },
            {
                id: 'add-csms-to-contract',
                title: 'Add CSMs to a vehicle contract',
                description:
                    'To add CSMs to a vehicle contract, follow these steps:',
                href: '/support/vehicles/newContract/#add-csms-to-contract'
            }
        ]
    },
    {
        pageTitle: 'New Trade',
        sections: [
            {
                id: 'add-vehicle-trade',
                title: 'Add new vehicle trade',
                description: 'To add a new vehicle trade, follow these steps:',
                href: '/support/vehicles/newTrade/#add-vehicle-trade'
            }
        ]
    },
    {
        pageTitle: 'Stock vehicles',
        sections: [
            {
                id: 'manage-vehicle-stock',
                title: 'Manage vehicle stock',
                description: 'To manage vehicle stock, follow these steps :',
                href: '/support/vehicles/stock/#manage-vehicle-stock'
            },
            {
                id: 'manage-manufacturer-vehicle-stock',
                title: 'Manage manufacturer vehicle stock',
                description:
                    'To manage manufacturer vehicle stock, follow these steps :',
                href: '/support/vehicles/stock/#manage-manufacturer-vehicle-stock'
            },
            {
                id: 'search-export-vehicle-stock',
                title: 'Search and export vehicle stock',
                description:
                    'To search and export vehicle stock, follow these steps :',
                href: '/support/vehicles/stock/#search-export-vehicle-stock'
            }
        ]
    },
    {
        pageTitle: 'Vehicle transfers',
        sections: [
            {
                id: 'manage-vehicle-transfers',
                title: 'Manage vehicle transfers',
                description:
                    'To create new vehicle transfer, complete transfer or export history transfers, follow these steps:',
                href: '/support/vehicles/vehicletransfers/#manage-vehicle-transfers'
            }
        ]
    },
    {
        pageTitle: 'Vehicle Sales',
        sections: [
            {
                id: 'edit-vehicle-sales',
                title: 'View and edit existing vehicle sales',
                description:
                    'To view or edit details of an exsiting vehicle sales, follow these steps:',
                href: '/support/vehicles/sales/#edit-vehicle-sales'
            },
            {
                id: 'search-vehicle-sales',
                title: 'Search vehicle sales',
                description:
                    'To search or filter vehicle sales, follow these steps:',
                href: '/support/vehicles/sales/#search-vehicle-sales'
            },
            {
                id: 'view-vehicle-sale-log',
                title: 'View vehicle sale log',
                description:
                    'To view the history of changes made to a vehicle sale, follow these steps:',
                href: '/support/vehicles/sales/#view-vehicle-sale-log'
            },
            {
                id: 'text-customer',
                title: 'Text customer',
                description:
                    'To send a text message to a customer, follow these steps:',
                href: '/support/vehicles/sales/#text-customer'
            }
        ]
    }
];
