import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';

interface GroupedOnlineOrder {
  oraOrder: Order;
  netoOrder: OnlineOrder;
}
const UpdateOrderlineStock = (
  selectedOrder: GroupedOnlineOrder,
  setSelectedOrder: Dispatch<SetStateAction<GroupedOnlineOrder>>
) => {
  api
    .put(
      `getUpdatedOnlineStock/${selectedOrder.oraOrder.id}?SiteId=${selectedOrder.oraOrder.SiteId}`
    )
    .then((res) => {
      let newOrderLines = res.data.updatedOrderLines;

      let currentOraOrder = selectedOrder.oraOrder;

      newOrderLines.forEach((line) => {
        if (line.SOH < parseInt(line.quantity)) {
          line.backorderQuantity = line.quantity;
        }
      });

      currentOraOrder.orderLines = newOrderLines;
      setSelectedOrder({ ...selectedOrder, oraOrder: currentOraOrder });
    });
};

export default UpdateOrderlineStock;
