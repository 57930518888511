// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import HandleEdit from './HandleEdit';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Unit } from '../../../global/interfaces/PartsInterface';

interface StockDetails {
    minStock: number;
    maxStock: number;
}

// When the fab is clicked, if currently on readonly set it to false
// If read only is false handle updating the part
const HandleEditClick = (
    readOnly: boolean,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    selectedSite: number,
    showSnackbar: showSnackbar,
    partId: string,
    partDetails: Unit,
    stockDetails: StockDetails
) => {
    if (readOnly) {
        if (selectedSite === parseInt(localStorage.getItem('SiteId')!)) {
            setReadOnly(!readOnly);
        } else {
            showSnackbar(
                'Part details from other sites are read only!',
                'If you wish to edit other site parts, please login to the site.',
                'warning'
            );
        }
    } else {
        HandleEdit({
            partId: parseInt(partId),
            unitData: partDetails,
            stockDetails: stockDetails,
            showSnackbar: showSnackbar
        });
    }
};

export default HandleEditClick;
