import moment from 'moment';
import { saveAs } from 'file-saver';
import { StockOrder } from '../../../../global/interfaces/PartsInterface';

const RegenerateHondaOrderFile = (row: StockOrder) => {
    let date = moment(row.createdAt);
    let blankString = ' ';
    let zeroString = '0';

    // first line of the txt file is the header
    let txtFile = `HOND${row.Api.additionalDetails.digitCode}                ${date.format('YYMMDDhhmmss')}OROH          ${row.orderReference}${blankString.repeat(15 - row.orderReference.length)}  ${blankString.repeat(20)}${row.orderType === 'daily' ? 'D' : 'S'}${blankString.repeat(35)}0001   \n`;

    // For each orderlines, add a line to the text file
    for (let x = 0; x < row.orderLines.length; x++) {
        let line = row.orderLines[x];

        if (x === row.orderLines.length - 1) {
            txtFile = txtFile.concat(
                `HOND${row.Api.additionalDetails.digitCode}                ${date.format('YYMMDDhhmmss')}OROL${line.partNumber}${blankString.repeat(20 - line.partNumber.length)}${zeroString.repeat(11 - Math.round(line.quantity * 100).toString().length)}${Math.round(line.quantity * 100).toString()}Y${blankString.repeat(51)}1${zeroString.repeat(
                    3 - x.toString().length
                )}${(x + 1).toString()}Y  `
            );
        } else {
            txtFile = txtFile.concat(
                `HOND${row.Api.additionalDetails.digitCode}                ${date.format('YYMMDDhhmmss')}OROL${line.partNumber}${blankString.repeat(20 - line.partNumber.length)}${zeroString.repeat(11 - Math.round(line.quantity * 100).toString().length)}${Math.round(line.quantity * 100).toString()}Y${blankString.repeat(51)}1${zeroString.repeat(
                    3 - x.toString().length
                )}${(x + 1).toString()}   \n`
            );
        }
    }

    // Save the file
    const file = new Blob([txtFile], { type: 'text/plain' });
    saveAs(
        file,
        `HONDA_ORDER${row.orderReference ? `_${row.orderReference}` : ''}_${date.format('YYMMDDhhmmss')}.txt`
    );
};

export default RegenerateHondaOrderFile;
