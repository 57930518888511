import api from '../../../../../../api';
import GroupTransactionByDate from './GroupTransactionByDate';

const RefreshTransactionsV2 = (
    reconciliationDetails,
    reconciliationDetailsUnltered,
    setReconciliationDetailsUnaltered,
    setLoading
) => {
    setLoading(true);
    api.get('bankReconciliationTransactionsNextGen').then((res) => {
        // Save the current reconciled transactions
        let currentOraReconciled = [
            ...reconciliationDetails.oraTransactionsReconciled
        ];
        let currentBankReconciled = [
            ...reconciliationDetails.bankTransactionsReconciled
        ];

        let oraTransactionsUnreconciled = [];
        let oraTransactionsReconciled = [];
        let bankTransactionsUnreconciled = [];
        let bankTransactionsReconciled = [];

        for (let item of res.data.formattedTillReconciliations) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedManualTransactions) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedTrustReceipts) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedDebtorPayments) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedTradeIns) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedVehicleDirectDeposits) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedServiceDirectDeposits) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedPartsDirectDeposits) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedPaymentRuns) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedStripePayments) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedServiceWarranties) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedVehicleRebates) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedDirectVehicleRebates) {
            oraTransactionsUnreconciled.push(item);
        }

        for (let item of res.data.formattedBankTransactions) {
            bankTransactionsUnreconciled.push(item);
        }

        let weeklySortedData = GroupTransactionByDate({
            oraTransactionsReconciled: oraTransactionsReconciled,
            oraTransactionsUnreconciled: oraTransactionsUnreconciled,
            bankTransactionsReconciled: bankTransactionsReconciled,
            bankTransactionsUnreconciled: bankTransactionsUnreconciled
        });

        oraTransactionsUnreconciled =
            weeklySortedData.oraTransactionsUnreconciled;
        bankTransactionsUnreconciled =
            weeklySortedData.bankTransactionsUnreconciled;

        // Now go through the reconciled transactions and remove them from unreconciled
        for (let week of currentOraReconciled) {
            // Find the week index in the unreconciled transactions
            let index = oraTransactionsUnreconciled.findIndex(
                (x) => x.week === week.week
            );
            for (let line of week.lines) {
                // Find the index within the lines
                let indexToSplice = oraTransactionsUnreconciled[
                    index
                ].lines.findIndex((x) => x.id === line.id);
                // Splice the line
                oraTransactionsUnreconciled[index].lines =
                    oraTransactionsUnreconciled[index].lines.splice(
                        indexToSplice,
                        1
                    );
            }
        }

        // Same for bank transactions
        for (let week of currentBankReconciled) {
            // Find the week index in the unreconciled transactions
            let index = bankTransactionsUnreconciled.findIndex(
                (x) => x.week === week.week
            );
            for (let line of week.lines) {
                // Find the index within the lines
                let indexToSplice = bankTransactionsUnreconciled[
                    index
                ].lines.findIndex((x) => x.id === line.id);
                // Splice the line
                bankTransactionsUnreconciled[index].lines =
                    bankTransactionsUnreconciled[index].lines.splice(
                        indexToSplice,
                        1
                    );
            }
        }

        setReconciliationDetailsUnaltered({
            ...reconciliationDetailsUnltered,
            oraTransactionsReconciled: currentOraReconciled,
            oraTransactionsUnreconciled: oraTransactionsUnreconciled,
            bankTransactionsReconciled: currentBankReconciled,
            bankTransactionsUnreconciled: bankTransactionsUnreconciled
        });

        setLoading(false);
    });
};

export default RefreshTransactionsV2;
