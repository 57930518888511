// LOGIC
import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';

/*  HandleDownloadResults
    Downloads the current results of a table to a csv document
    params:
        - sort: the sort value you use in the table itself
        - route: the route for the backend search function, this is the same one that is sent to the table search component
        - handleRequestCreate: the value of the function that converts filters into an api query, this is the same one that
        is sent to the table search component
        - fileName: the name of the csv file including .csv
        - header: a string array containing the header values for the csv
        - rowValues: the values from the data to display in each data row (for example id, name, firstName etc.)
*/
const HandleDownloadResults = (
    sort: string[],
    route: string,
    handleRequestCreate: string,
    fileName: string,
    header: string[],
    rowValues: string[],
    setDialogOpen?: Dispatch<SetStateAction<boolean>>
) => {
    let params = new URLSearchParams(window.location.search);

    // Preparing the api call with appropiate queries
    let searchStringQuery = params.get('searchString')
        ? `&searchString=${params.get('searchString')}`
        : '';
    let filterStringQuery = handleRequestCreate;
    let apiString = `${route}?&sort=${sort}${searchStringQuery}${filterStringQuery}&export=true`;

    api.get(apiString).then((res) => {
        let data = res.data;
        let csvRows = [];
        // Header for the csv
        csvRows.push(header);

        // Puts the data into the csv
        data.rows.forEach((row) => {
            let csvRow = [];
            rowValues.forEach((value) => {
                // For each of the values specified in the rowValues array
                if (value.indexOf('.') === -1) {
                    csvRow.push(row[value]);
                } else {
                    // If the value has a "." in them, deconstruct it (for example "Make.name", need to get row['Make']['name'])
                    let values = value.split('.');
                    // Make sure the value we're looking for is not in a null object
                    if (values.length === 2) {
                        if (row[values[0]]) {
                            csvRow.push(row[values[0]][values[1]]);
                        } else {
                            csvRow.push('');
                        }
                    } else if (values.length === 3) {
                        if (row[values[0]][values[1]]) {
                            csvRow.push(row[values[0]][values[1]][values[2]]);
                        } else {
                            csvRow.push('');
                        }
                    }
                }
            });
            csvRows.push(csvRow);
        });

        // // Prepares and downloads the csv file
        // let csvContent = 'data:text/csv;charset=utf-8,';
        // csvRows.forEach(function (rowArray) {
        //     let row = rowArray.join(',');
        //     csvContent += row + '\r\n';
        // });

        // // Create an invisible button and click it to auto-download the csv file
        // var encodedUri = encodeURI(csvContent);
        // var link = document.createElement('a');
        // link.setAttribute('href', encodedUri);
        // link.setAttribute('download', fileName);
        // document.body.appendChild(link);
        // link.click();

        let csvContent = csvRows.map(rowArray => rowArray.join(',')).join('\r\n'); //transform an array of arrays into a CSV string
        let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }); //create a Blob from the CSV content 
        let url = URL.createObjectURL(blob);

        var link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
        URL.revokeObjectURL(url); // Free up memory


        if (setDialogOpen) {
            setDialogOpen(false);
        }
    });
};

export default HandleDownloadResults;
