// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleDeleteInvoice = (
    InvoiceId: number,
    AssociatedInvoiceId: number,
    AssociatedInvoiceType: string,
    SupplierId: number,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.delete(
        `deleteInvoice/${InvoiceId}?AssociatedInvoiceId=${AssociatedInvoiceId}&AssociatedInvoiceType=${AssociatedInvoiceType}`
    ).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Invoice successfully updated.',
                'The page will now refresh.'
            );
            window.location.href = `/viewCreditor/${SupplierId}`;
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default HandleDeleteInvoice;
