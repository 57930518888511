// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Invoice } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { PaymentRun } from '../../../global/interfaces/AdminInterface';

const GetInvoiceDetails = (
    InvoiceId: number,
    setInvoiceDetails: Dispatch<SetStateAction<Invoice>>,
    setInvoiceType: Dispatch<
        SetStateAction<{ type: string; id: number; selector: string }>
    >,
    setPaymentRun: Dispatch<
        SetStateAction<{ payrun: PaymentRun; payrunInvoices: Invoice[] }[]>
    >,
    setLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    setInvalid?: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    api.get(`invoiceDetails/${InvoiceId}`).then((res) => {
        if (res.status === 204 && setInvalid) {
            setInvalid(true);
            showSnackbar(
                'Invoice does not exist.',
                'Please double check your URL and try again.',
                'warning'
            );
        } else {
            let data = res.data.invoiceDetails;
            data.vehicleLines = res.data.vehicleLines;
            data.vehicleRegistrationLines = res.data.vehicleRegistrationLines;
            setInvoiceDetails(data);
            setPaymentRun(res.data.payrun);

            if (res.data.invoiceDetails.StockInvoiceId) {
                if (
                    res.data.invoiceDetails.StockInvoice.invoiceType === 'Admin'
                ) {
                    setInvoiceType({
                        type: 'Admin',
                        id: res.data.invoiceDetails.StockInvoiceId,
                        selector: 'StockInvoice'
                    });
                } else {
                    setInvoiceType({
                        type: 'Stock',
                        id: res.data.invoiceDetails.StockInvoiceId,
                        selector: 'StockInvoice'
                    });
                }
            } else if (res.data.invoiceDetails.VehicleInvoiceId) {
                setInvoiceType({
                    type: 'Vehicle',
                    id: res.data.invoiceDetails.VehicleInvoiceId,
                    selector: 'VehicleInvoice'
                });
            } else if (res.data.invoiceDetails.VehicleRegistrationId) {
                setInvoiceType({
                    type: 'Vehicle Registration',
                    id: res.data.invoiceDetails.VehicleRegistrationId,
                    selector: 'VehicleRegistration'
                });
            } else {
                setInvoiceType({ type: 'Misc', id: 0, selector: '' });
            }
        }
        setLoading(false);
    });
};

export default GetInvoiceDetails;
