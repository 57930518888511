import { Dispatch, SetStateAction } from 'react';
import { StockInvoice } from '../../../global/interfaces/PartsInterface';

// Validate that the lines on the invoice have a valid quantity and cost
const ValidateOrderLines = (
    invoiceData: StockInvoice,
    setInvoiceData: Dispatch<SetStateAction<StockInvoice>>
) => {
    let currentOrderLines = [...invoiceData.orderLines];
    let isError = false;

    currentOrderLines.forEach((line) => {
        if (!line.removed && !line.invoiced) {
            if (
                line.quantityReceived == null ||
                line.quantityReceived === '' ||
                isNaN(parseFloat(line.quantityReceived))
            ) {
                line.quantityError = true;
                isError = true;
            } else {
                line.quantityError = false;
            }

            if (
                line.itemPrice == null ||
                line.itemPrice === '' ||
                isNaN(parseFloat(line.itemPrice))
            ) {
                line.priceError = true;
                isError = true;
            } else {
                line.priceError = false;
            }
        }
    });
    setInvoiceData({ ...invoiceData, orderLines: currentOrderLines });
    return isError;
};

export default ValidateOrderLines;
