// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Chip,
    IconButton,
    TableRow,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import { Reorder } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
import Drawer from '../../../../global/Drawer';
import CSMDrawerContent from './CSMDrawerContent';
// LOGIC
import {
    CurrencyFormatter,
    ToFixed
} from '../../../../global/logic/Formatters';
// INTERFACES
import { Order } from '../../../../global/interfaces/PartsInterface';
import CalculateMargin from '../../../../global/logic/CalculateMargin';

interface CSMContentInterface {
    linkedSales: Order[];
    setLinkedSales: Dispatch<SetStateAction<Order[]>>;
}
const CSMContent = ({ linkedSales, setLinkedSales }: CSMContentInterface) => {
    const [saleDrawerOpen, setSaleDrawerOpen] = useState<boolean>(false);
    const [selectedSale, setSelectedSale] = useState<Order>();
    const currentMode = useTheme().palette.mode;
    const saleTableColumns = [
        { id: 0, label: 'ID', width: 100 },
        { id: 1, label: 'Total Cost', width: 200 },
        { id: 2, label: 'Total Price (EX. GST)', width: 200 },
        { id: 3, label: 'Total Price (INC. GST)', width: 200 },
        { id: 4, label: 'Total Margin', width: 200 },
        { id: 5, label: 'User', width: 100 },
        { id: 6, label: 'Status', width: 150 },
        { id: 7, label: '', width: 50 }
    ];

    const getPrices = (sale: Order, returnType: string) => {
        let totalCost = 0;
        let totalPrice = 0;

        sale.orderLines.forEach((line) => {
            if (!line.voucherCode) {
                const quantity = parseInt(line.quantity, 10);
                const backorderQuantity =
                    parseInt(line.backorderQuantity, 10) || 0;
                const costPrice = line.costPriceAverage || line.costPriceDaily;
                const pricePaid = parseFloat(line.pricePaid);

                if (!sale.LinkedSaleId && line.inactive) {
                    return;
                }

                totalCost += (quantity + backorderQuantity) * costPrice;

                totalPrice += (quantity + backorderQuantity) * pricePaid;
            }
        });

        if (returnType === 'cost') {
            return ToFixed(totalCost);
        } else if (returnType === 'ex') {
            return ToFixed(totalPrice / 1.1);
        } else {
            return ToFixed(totalPrice);
        }
    };

    const getPartList = (row) => {
        let partList = '';
        for (let line of row.orderLines) {
            partList =
                partList.length === 0
                    ? 'Parts: ' + line.partNumber
                    : partList + ', ' + line.partNumber;
        }
        return partList;
    };

    return (
        <>
            <DataTable columns={saleTableColumns}>
                {linkedSales.map((sale, index) => (
                    <TableRow>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(
                                    `/inventory/viewSale/${sale.id}`,
                                    '_blank'
                                )
                            }
                        >
                            <Tooltip title={getPartList(sale)} placement="top">
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        color:
                                            currentMode === 'dark'
                                                ? '#a88ce2'
                                                : '#9370DB'
                                    }}
                                    variant="inherit"
                                >
                                    {sale.id}
                                </Typography>
                            </Tooltip>
                        </DataCellColoured>
                        <DataCell>
                            {CurrencyFormatter(getPrices(sale, 'cost'))}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(getPrices(sale, 'ex'))}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(getPrices(sale, 'inc'))}
                        </DataCell>
                        <DataCell>
                            {ToFixed(
                                CalculateMargin(
                                    getPrices(sale, 'inc'),
                                    getPrices(sale, 'cost')
                                )
                            )}
                            %
                        </DataCell>
                        <DataCell>
                            {sale?.User?.firstName} {sale?.User?.lastName}
                        </DataCell>
                        <DataCell>
                            <Chip
                                variant="outlined"
                                label={sale?.status}
                                color={
                                    sale.status === 'Proforma'
                                        ? 'warning'
                                        : sale.status === 'Quote'
                                          ? 'error'
                                          : 'success'
                                }
                            />
                        </DataCell>
                        <DataCell>
                            <IconButton
                                onClick={() => {
                                    setSelectedSale(sale);
                                    setSaleDrawerOpen(true);
                                }}
                            >
                                <Reorder />
                            </IconButton>
                        </DataCell>
                    </TableRow>
                ))}
            </DataTable>

            <Drawer
                openDrawer={saleDrawerOpen}
                setOpenDrawer={setSaleDrawerOpen}
                title={`CSM #${selectedSale?.id} Orderlines`}
                subTitle={`${selectedSale?.status} last updated by ${selectedSale?.User?.firstName} ${selectedSale?.User?.lastName}`}
                width="40vw"
            >
                <CSMDrawerContent selectedSale={selectedSale} />
            </Drawer>
        </>
    );
};

export default CSMContent;
