// IMPORTS
import { useState, useEffect } from 'react';
import {
    Typography,
    TableRow,
    Chip,
    Tooltip,
    IconButton,
    useTheme
} from '@mui/material';
import { Add, CreditScore } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import SaleTableFilter from './components/SaleTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import GetSite from '../../global/databaseLogic/GetSite';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { Order } from '../../global/interfaces/PartsInterface';
import { Site } from '../../global/interfaces/GeneralInterface';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../global/logic/Formatters';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    saleTableFilter: {
        type: [],
        status: [],
        site: [],
        user: [],
        archived: false,
        partNumber: ''
    }
};
const clearedSort = ['id', 'DESC'];

const SaleTable = () => {
    const history = useHistory();
    const currentMode = useTheme().palette.mode;
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<Order[]>([]);
    const [siteDetails, setSiteDetails] = useState<Site>();

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Sale',
            onClick: () => history.push('/inventory/newSale')
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'Type', width: 100 },
        { id: 2, label: 'Customer', width: 300 },
        { id: 3, label: 'Total', width: 100 },
        { id: 4, label: 'Owing', width: 100 },
        { id: 5, label: 'Created', width: 100 },
        { id: 6, label: 'Finalised', width: 100 },
        { id: 7, label: 'User', width: 100 },
        { id: 8, label: 'Location', width: 100 },
        { id: 9, label: 'Status', width: 100 },
        { id: 10, label: '', width: 100 }
    ];

    // Get the details of the current site
    useEffect(() => {
        GetSite(parseInt(localStorage.getItem('SiteId')), setSiteDetails);
    }, []);

    // Once the current site details have been obtained set the filter to some default values
    useEffect(() => {
        let newFilter = {
            type: [],
            status: [],
            site: [
                {
                    id: parseInt(localStorage.getItem('SiteId')),
                    name: siteDetails?.name
                }
            ],
            user: [],
            partNumber: '',
            archived: false
        };
        setFilter({ ...filter, saleTableFilter: newFilter });
        // eslint-disable-next-line
    }, [siteDetails]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let type =
            filter.saleTableFilter.type.length > 0
                ? ConvertArrayToFilter({
                      array: filter.saleTableFilter.type,
                      filterStart: '&type=',
                      selector: 'value'
                  })
                : '';
        let status =
            filter.saleTableFilter.status.length > 0
                ? ConvertArrayToFilter({
                      array: filter.saleTableFilter.status,
                      filterStart: '&status=',
                      selector: 'value'
                  })
                : '';
        let site =
            filter.saleTableFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.saleTableFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let user =
            filter.saleTableFilter.user.length > 0
                ? ConvertArrayToFilter({
                      array: filter.saleTableFilter.user,
                      filterStart: '&user=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let partNumber = `&partNumber=${filter.saleTableFilter.partNumber.toUpperCase()}`;
        let archived = `&archived=${filter.saleTableFilter.archived}`;

        let apiString = `${type}${status}${site}${user}${archived}${partNumber}`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.saleTableFilter.type.length > 0 ||
            filter.saleTableFilter.status.length > 0 ||
            filter.saleTableFilter.site.length > 0 ||
            filter.saleTableFilter.user.length > 0 ||
            filter.saleTableFilter.archived
        ) {
            isActive = true;
        }

        return isActive;
    };

    const tableValues = (row) => {
        let type: string;
        let customer: string;
        let subCustomer: string;
        let total: number;

        // Normal Customer
        if (row.CustomerId) {
            type = 'CUSTOMER';
            customer = row.Customer.companyName
                ? row.Customer.companyName?.toUpperCase()
                : row?.Customer?.firstName?.toUpperCase() +
                  ' ' +
                  row?.Customer?.lastName?.toUpperCase();
            subCustomer = `Customer ID: ${row.CustomerId}`;
            total = parseFloat(row.amountPaid) + parseFloat(row.amountOwed);
        }

        // Guest
        if (row.CustomerId === 0) {
            type = 'GUEST';
            customer = 'GUEST';
            subCustomer = '';
            total = parseFloat(row.amountPaid) + parseFloat(row.amountOwed);
        }

        // Online Order
        if (row.OnlineOrderId) {
            type = 'ONLINE';
            customer = row.Customer.companyName
                ? row.Customer.companyName?.toUpperCase()
                : row?.Customer?.firstName?.toUpperCase() +
                  ' ' +
                  row?.Customer?.lastName?.toUpperCase();
            subCustomer = `Customer ID: ${row.CustomerId}`;
            total = parseFloat(row.amountPaid) + parseFloat(row.amountOwed);
        }

        // Vehicle Contract
        if (row.VehicleSaleId) {
            type = 'VEHICLE CONTRACT';
            customer = row.VehicleSale?.Customer.companyName
                ? row.VehicleSale?.Customer.companyName?.toUpperCase()
                : row?.VehicleSale?.Customer?.firstName?.toUpperCase() +
                  ' ' +
                  row?.VehicleSale?.Customer?.lastName?.toUpperCase();
            subCustomer = `Vehicle Sale ID: ${row.VehicleSaleId}`;
            total = parseFloat(row.amountPaid) + parseFloat(row.amountOwed);
        }

        // Vehicle Part
        if (row.VehicleId) {
            type = 'VEHICLE PART';
            customer = `Stock Number: ${row?.Vehicle?.stockNumber}`;
            subCustomer = `Vehicle ID: ${row.VehicleId}`;
            total = parseFloat(row.amountPaid) + parseFloat(row.amountOwed);
            row.amountOwed = 0;
        }

        // Service Part
        if (row.ServiceId) {
            type = 'SERVICE PART';
            customer =
                row?.Service?.jobType === 'Internal'
                    ? `Stock Number: ${row?.Service?.Vehicle?.stockNumber}`
                    : row?.Service?.Customer?.companyName
                      ? `${row?.Service?.Customer?.companyName}`
                      : `${row?.Service?.Customer?.firstName} ${row?.Service?.Customer?.lastName}`;
            subCustomer = `Service ID: ${row.ServiceId}`;
            total = parseFloat(row.amountPaid) + parseFloat(row.amountOwed);
        }

        // Workshop
        if (row.internalType === 1) {
            type = 'WORKSHOP';
            customer = `WORKSHOP`;
            subCustomer = '';
            total = parseFloat(row.amountPaid) + parseFloat(row.amountOwed);
            row.amountOwed = 0;
        }

        // Sales
        if (row.internalType === 2) {
            type = 'SALES';
            customer = `SALES`;
            subCustomer = '';
            total = parseFloat(row.amountPaid) + parseFloat(row.amountOwed);
            row.amountOwed = 0;
        }

        return { type, customer, subCustomer, total };
    };

    const getPartList = (row) => {
        let partList = '';
        const parts = row.orderLines.filter((line) => line.partNumber);
        for (let part of parts) {
            partList =
                partList.length === 0
                    ? 'Parts: ' + part.partNumber
                    : partList + ', ' + part.partNumber;
        }
        return partList;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Sales</Typography>
            <br />
            <TableSearch
                searchTitle="Search Sale"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="saleSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <SaleTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                    cypressLabel="paSalesTable"
                >
                    {resultsList.map((row) => {
                        let { type, customer, subCustomer, total } =
                            tableValues(row);
                        return (
                            <TableRow key={row.id}>
                                <DataCellColoured
                                    handleClick={() =>
                                        history.push(
                                            `/inventory/viewSale/${row.id}`
                                        )
                                    }
                                    cypressLabel=""
                                >
                                    <Tooltip
                                        title={getPartList(row)}
                                        placement="top"
                                    >
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                color:
                                                    currentMode === 'dark'
                                                        ? '#a88ce2'
                                                        : '#9370DB'
                                            }}
                                            variant="inherit"
                                        >
                                            {row.OnlineOrderId
                                                ? row.OnlineOrderId
                                                : row.id}
                                        </Typography>
                                    </Tooltip>
                                </DataCellColoured>

                                <DataCell>{type}</DataCell>
                                <DataCell>
                                    {customer}
                                    {subCustomer ? (
                                        <span
                                            style={{
                                                color:
                                                    currentMode === 'dark'
                                                        ? '#C0BBBB'
                                                        : '#555',
                                                fontSize: '12px'
                                            }}
                                        >
                                            <br /> {subCustomer}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </DataCell>
                                <DataCell>{CurrencyFormatter(total)}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(row.amountOwed)}
                                </DataCell>
                                <DataCell>
                                    {DateFormatter(row.createdAt)}
                                </DataCell>
                                <DataCell>
                                    {row.finalisedAt
                                        ? DateFormatter(row.finalisedAt)
                                        : ''}
                                </DataCell>
                                <DataCell>
                                    {row?.User?.firstName} {row?.User?.lastName}
                                </DataCell>
                                <DataCell>{row?.Site?.name}</DataCell>
                                <DataCell>
                                    <Chip
                                        variant="outlined"
                                        label={row.status}
                                        color={
                                            row.status === 'Quote'
                                                ? 'error'
                                                : row.status === 'Proforma' ||
                                                    row.status === 'Credited'
                                                  ? 'warning'
                                                  : row.status ===
                                                          'Online Proforma' ||
                                                      row.status ===
                                                          'Online Sale'
                                                    ? 'info'
                                                    : 'success'
                                        }
                                    />
                                </DataCell>
                                <DataCell>
                                    {(row.amountPaid === 0 &&
                                        row.ServiceId == null) ||
                                    row.VehicleId ||
                                    row.internalType ||
                                    total === 0 ? null : (
                                        <Tooltip
                                            title="Credit Order"
                                            placement="top"
                                        >
                                            <IconButton
                                                data-cy={`creditOrderButton_${row.id}`}
                                                onClick={(e) =>
                                                    history.push(
                                                        `/inventory/credit/${row.id}`
                                                    )
                                                }
                                            >
                                                <CreditScore />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </DataCell>
                            </TableRow>
                        );
                    })}
                </DataTable>
            </Paper>
        </PageWrapper>
    );
};

export default SaleTable;
