import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Tag, TEMPLATE_TYPES } from '../EsignInterface';
import { CreateTemplate } from '../logic/HandleTemplate';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

interface SaveTemplateDialogProps {
    documentKey: string;
    documentId: string;
    tags: Tag[];
    openSaveTemplateDialog: boolean;
    setOpenSaveTemplateDialog: (open: boolean) => void;
    showSnackbar: showSnackbar;
}

const SaveTemplateDialog = ({
    openSaveTemplateDialog,
    setOpenSaveTemplateDialog,
    documentKey,
    documentId,
    tags,
    showSnackbar
}: SaveTemplateDialogProps) => {
    const [templateName, setTemplateName] = useState<string>('');
    const [loadingButton, setLoadingButton] = useState<string | null>(null);

    const handleClickSaveTemplate = async (type: string) => {
        const res = await CreateTemplate({
            name: templateName,
            documentKey:
                type === TEMPLATE_TYPES.DOCUMENT_AND_TAGS ? documentKey : null,
            documentId:
                type === TEMPLATE_TYPES.DOCUMENT_AND_TAGS ? documentId : null,
            tags
        });

        if (res.status === 200) {
            showSnackbar(
                'Template saved successfully',
                'You can reuse this template for future documents',
                'success'
            );
        } else {
            showSnackbar(
                'Failed to save template',
                'Please try again later',
                'error'
            );
        }

        setLoadingButton(null);
        setOpenSaveTemplateDialog(false);
    };

    const onClose = () => {
        setOpenSaveTemplateDialog(false);
        setTemplateName('');
    };

    return (
        <Dialog open={openSaveTemplateDialog} onClose={onClose}>
            <DialogTitle>Save as Template</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            Enter a name for your template. This name will help
                            you identify the template later.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            label="Template Name"
                            InputLabelProps={{ shrink: true }}
                            value={templateName}
                            onChange={(e) => {
                                setTemplateName(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="caption">
                            Select "Save Document and Tags" to save both your
                            document and its associated tags. This allows you to
                            reuse the entire setup without re-uploading the
                            document or re-adding tags.
                        </Typography>
                        <br />
                        <Typography variant="caption">
                            Select "Save Tags Only" if you prefer to save just
                            the tags. This option is useful when you plan to use
                            these tags with different documents in the future.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6} textAlign="right">
                        <LoadingButton
                            loading={loadingButton === 'tag'}
                            variant="outlined"
                            onClick={() => {
                                handleClickSaveTemplate(
                                    TEMPLATE_TYPES.TAG_ONLY
                                );
                                setLoadingButton('tag');
                            }}
                        >
                            Save Tags Only
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={6} textAlign="left">
                        <LoadingButton
                            loading={loadingButton === 'document'}
                            variant="contained"
                            onClick={() => {
                                handleClickSaveTemplate(
                                    TEMPLATE_TYPES.DOCUMENT_AND_TAGS
                                );
                                setLoadingButton('document');
                            }}
                        >
                            Save Document and Tags
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default SaveTemplateDialog;
