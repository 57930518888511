// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    TextField,
    DialogActions,
    Button
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
// LOGIC
import CalculateMargin from '../../../../global/logic/CalculateMargin';
// INTERFACES
import { Order } from '../../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { ToFixed } from '../../../../global/logic/Formatters';
import RoleCheck from '../../../../global/RoleCheck';

interface GlobalOrderlineMenuProps {
    saleDetails: Order;
    setSaleDetails: Dispatch<SetStateAction<Order>>;
    showSnackbar: showSnackbar;
}
const GlobalOrderlineMenu = ({
    saleDetails,
    setSaleDetails,
    showSnackbar
}: GlobalOrderlineMenuProps) => {
    const [discountOpen, setDiscountOpen] = useState<boolean>(false);
    const [discountValue, setDiscountValue] = useState<string>('0');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const calculateDiscount = () => {
        // Validate the discount value
        if (parseFloat(discountValue) === 0) {
            showSnackbar(
                'Invalid discount value.',
                'Please enter a number between -100 and 100, excluding 0.',
                'error'
            );
            return;
        }

        // Loop through all the orderlines and apply the discount
        let currentOrderLines = [...saleDetails.orderLines];
        let totalPrice = 0;
        let totalCost = 0;

        currentOrderLines.forEach((line) => {
            let discountedPrice = ToFixed(
                parseFloat(line.pricePaid) -
                    parseFloat(line.pricePaid) *
                        (parseFloat(discountValue) / 100)
            );
            let costPrice = line.costPriceAverage
                ? line.costPriceAverage
                : line.costPriceDaily;

            line.pricePaid = String(discountedPrice);
            line.totalPrice = ToFixed(
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                    parseFloat(line.pricePaid)
            );
            line.totalCost = ToFixed(
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                    costPrice
            );
            line.margin = ToFixed(
                CalculateMargin(line.totalPrice, line.totalCost)
            );

            totalPrice += line.totalPrice;
            totalCost += line.totalCost;
        });

        let totalMargin = CalculateMargin(totalPrice, totalCost);

        setSaleDetails({
            ...saleDetails,
            orderLines: currentOrderLines,
            totalPrice: ToFixed(totalPrice),
            totalCost: ToFixed(totalCost),
            totalMargin: ToFixed(totalMargin)
        });
        setDiscountOpen(false);
        handleClose();
    };

    const removeAllRows = () => {
        setSaleDetails({
            ...saleDetails,
            orderLines: [],
            totalPrice: 0,
            totalCost: 0,
            totalMargin: 0
        });
        handleClose();
    };

    const allRowsOriginalRRP = () => {
        // Loop through all the orderlines and apply the discount
        let currentOrderLines = [...saleDetails.orderLines];
        let totalPrice = 0;
        let totalCost = 0;

        currentOrderLines.forEach((line) => {
            let costPrice = line.costPriceAverage
                ? line.costPriceAverage
                : line.costPriceDaily;

            line.pricePaid = String(line.priceRRP);
            line.totalPrice = ToFixed(
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                    parseFloat(line.pricePaid)
            );
            line.totalCost = ToFixed(
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                    costPrice
            );
            line.margin = ToFixed(
                CalculateMargin(line.totalPrice, line.totalCost)
            );

            totalPrice += line.totalPrice;
            totalCost += line.totalCost;
        });

        let totalMargin = CalculateMargin(totalPrice, totalCost);

        setSaleDetails({
            ...saleDetails,
            orderLines: currentOrderLines,
            totalPrice: ToFixed(totalPrice),
            totalCost: ToFixed(totalCost),
            totalMargin: ToFixed(totalMargin)
        });
        setDiscountOpen(false);
        handleClose();
    };

    const AddGst = () => {
        // Loop through all the orderlines and apply the discount
        let currentOrderLines = [...saleDetails.orderLines];
        let totalPrice = 0;
        let totalCost = 0;

        currentOrderLines.forEach((line) => {
            let incGSTPrice = ToFixed(parseFloat(line.pricePaid) * 1.1);
            let costPrice = line.costPriceAverage
                ? line.costPriceAverage
                : line.costPriceDaily;

            line.pricePaid = String(incGSTPrice);
            line.totalPrice = ToFixed(
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                    parseFloat(line.pricePaid)
            );
            line.totalCost = ToFixed(
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                    costPrice
            );
            line.margin = ToFixed(
                CalculateMargin(line.totalPrice, line.totalCost)
            );
            line.gstFree = false;

            totalPrice += line.totalPrice;
            totalCost += line.totalCost;
        });

        let totalMargin = CalculateMargin(totalPrice, totalCost);

        setSaleDetails({
            ...saleDetails,
            orderLines: currentOrderLines,
            totalPrice: ToFixed(totalPrice),
            totalCost: ToFixed(totalCost),
            totalMargin: ToFixed(totalMargin)
        });
        setDiscountOpen(false);
        handleClose();
    };

    const RemoveGST = () => {
        // Loop through all the orderlines and apply the discount
        let currentOrderLines = [...saleDetails.orderLines];
        let totalPrice = 0;
        let totalCost = 0;

        currentOrderLines.forEach((line) => {
            let incGSTPrice = ToFixed(parseFloat(line.pricePaid) / 1.1);
            let costPrice = line.costPriceAverage
                ? line.costPriceAverage
                : line.costPriceDaily;

            line.pricePaid = String(incGSTPrice);
            line.totalPrice = ToFixed(
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                    parseFloat(line.pricePaid)
            );
            line.totalCost = ToFixed(
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                    costPrice
            );
            line.margin = ToFixed(
                CalculateMargin(line.totalPrice, line.totalCost)
            );
            line.gstFree = true;

            totalPrice += line.totalPrice;
            totalCost += line.totalCost;
        });

        let totalMargin = CalculateMargin(totalPrice, totalCost);

        setSaleDetails({
            ...saleDetails,
            orderLines: currentOrderLines,
            totalPrice: ToFixed(totalPrice),
            totalCost: ToFixed(totalCost),
            totalMargin: ToFixed(totalMargin)
        });
        setDiscountOpen(false);
        handleClose();
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={() => removeAllRows()}>
                    Remove Parts
                </MenuItem>
                <MenuItem onClick={() => allRowsOriginalRRP()}>
                    Original RRP
                </MenuItem>
                <MenuItem onClick={() => setDiscountOpen(true)}>
                    Majoration/Discount
                </MenuItem>
                {saleDetails.orderLines.every((row) => row.gstFree) ? (
                    <RoleCheck
                        permission="assign_superuser" // Used assign_superuser because I don't want anyone to touch this button
                        component={
                            <MenuItem
                                onClick={() => {
                                    AddGst();
                                    handleClose();
                                }}
                            >
                                Add GST
                            </MenuItem>
                        }
                    />
                ) : saleDetails.orderLines.every((row) => !row.gstFree) ? (
                    <RoleCheck
                        permission="assign_superuser" // Used assign_superuser because I don't want anyone to touch this button
                        component={
                            <MenuItem
                                onClick={() => {
                                    RemoveGST();
                                    handleClose();
                                }}
                            >
                                Remove GST
                            </MenuItem>
                        }
                    />
                ) : null}
            </Menu>

            <Dialog
                open={discountOpen}
                onClose={() => setDiscountOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Global Majoration/Discount</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Please enter a percentage between -100% and 100%
                        (positive value for discount). This will be applied to
                        all the current orderlines.
                    </Typography>
                    <br />
                    <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        value={discountValue}
                        InputProps={{ endAdornment: '%' }}
                        onChange={(e) => setDiscountValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDiscountOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => calculateDiscount()}
                    >
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GlobalOrderlineMenu;
