import {
    Grid,
    Tooltip,
    Typography,
    IconButton,
    TableRow,
    Fab
} from '@mui/material';
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import {
    Close,
    ExpandMore,
    ExpandLess,
    InfoOutlined,
    PlaylistRemove,
    PlaylistAddCheck
} from '@mui/icons-material';
import DataCell from '../../../../global/tableComponents/DataCell';
import HandleMoveTransaction from '../../logic/Reconciliator/HandleMoveTransaction';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import Drawer from '../../../../global/Drawer';
import { useState } from 'react';
import RenderDrawerContent from '../../logic/Reconciliator/RenderDrawerContent';
import HandleMoveAllTransaction from '../../logic/Reconciliator/HandleMoveAllTransactions';

interface TablesProps {
    reconciliationDetails;
    setReconciliationDetails;
    refreshLoading: boolean;
    readOnly: boolean;
}
const Tables = ({
    reconciliationDetails,
    setReconciliationDetails,
    refreshLoading,
    readOnly
}: TablesProps) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<string>('');
    const [selectedObject, setSelectedObject] = useState(null);

    const oraColumns = [
        { id: 0, label: '', width: 50 },
        { id: 1, label: 'Date', width: 100 },
        { id: 3, label: 'Type', width: 300 },
        { id: 4, label: 'Reference', width: 400 },
        { id: 5, label: 'Amount', width: 100 },
        { id: 6, label: '', width: 50 }
    ];

    const bankColumns = [
        { id: 0, label: '', width: 50 },
        { id: 1, label: 'Date', width: 100 },
        { id: 2, label: 'Reference', width: 700 },
        { id: 3, label: 'Amount', width: 100 },
        { id: 4, label: '', width: 50 }
    ];

    const handleOraTransactionClick = (line) => {
        if (line.TillReconciliationId) {
            setSelectedType('till');
        } else if (line.ManualTransactionId) {
            setSelectedType('manual');
        } else if (line.TrustReceiptId) {
            setSelectedType('trust');
        } else if (line.TradeId) {
            setSelectedType('trade');
        } else if (line.VehicleSaleId) {
            setSelectedType('vehicle');
        } else if (line.ServiceId) {
            setSelectedType('service');
        } else if (line.OrderId) {
            setSelectedType('parts');
        } else if (line.PaymentRunId) {
            setSelectedType('payrun');
        } else if (line.BankTransactionId) {
            setSelectedType('bank');
        } else if (line.ServiceWarrantyId) {
            setSelectedType('warranty');
        } else if (line.VehicleRebateId) {
            setSelectedType('rebate');
        }

        setSelectedObject(line);
        setDrawerOpen(true);
    };

    const GetLocation = (id) => {
        let element = document.getElementById(id);
        const rect = element.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    };

    const ScrollToElement = (id) => {
        let coords = GetLocation(id);
        window.scrollTo({ top: coords.top, behavior: 'smooth' });
    };

    return (
        <>
            <span id="reconciled" />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography variant="h6">
                                    Reconciled Ora
                                </Typography>
                            </Grid>
                            <Grid item xs={2} textAlign="right">
                                <Tooltip
                                    title="Move all Ora transactions down"
                                    placement="left"
                                >
                                    <IconButton
                                        disabled={refreshLoading || readOnly}
                                        onClick={() =>
                                            HandleMoveAllTransaction(
                                                'ora',
                                                reconciliationDetails,
                                                setReconciliationDetails
                                            )
                                        }
                                    >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <DataTable columns={oraColumns}>
                            {reconciliationDetails.oraTransactionsReconciled.map(
                                (line, index) => (
                                    <TableRow>
                                        <DataCell align="left">
                                            <IconButton
                                                disabled={
                                                    refreshLoading || readOnly
                                                }
                                                onClick={() =>
                                                    HandleMoveTransaction(
                                                        'down',
                                                        'ora',
                                                        index,
                                                        reconciliationDetails,
                                                        setReconciliationDetails
                                                    )
                                                }
                                            >
                                                <ExpandMore />
                                            </IconButton>
                                        </DataCell>
                                        <DataCell>{line.date}</DataCell>
                                        <DataCell>
                                            {line.siteName ? (
                                                <Typography variant="body2">
                                                    {line.siteName}
                                                    <br />
                                                    {line.type}
                                                </Typography>
                                            ) : (
                                                line.type
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {line.reference.length > 20 ? (
                                                <Tooltip
                                                    title={line.reference}
                                                    placement="top"
                                                >
                                                    <Typography variant="body2">
                                                        {line.reference.slice(
                                                            0,
                                                            20
                                                        )}
                                                        ...
                                                    </Typography>
                                                </Tooltip>
                                            ) : (
                                                line.reference
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(line.amount)}
                                        </DataCell>
                                        <DataCell align="right">
                                            {line.DebtorPaymentId ||
                                            line.StripePaymentId ? null : (
                                                <IconButton
                                                    disabled={refreshLoading}
                                                    onClick={() =>
                                                        handleOraTransactionClick(
                                                            line
                                                        )
                                                    }
                                                >
                                                    <InfoOutlined />
                                                </IconButton>
                                            )}
                                        </DataCell>
                                    </TableRow>
                                )
                            )}
                        </DataTable>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography variant="h6">
                                    Reconciled Bank
                                </Typography>
                            </Grid>
                            <Grid item xs={2} textAlign="right">
                                <Tooltip
                                    title="Move all bank transactions down"
                                    placement="left"
                                >
                                    <IconButton
                                        disabled={refreshLoading || readOnly}
                                        onClick={() =>
                                            HandleMoveAllTransaction(
                                                'bank',
                                                reconciliationDetails,
                                                setReconciliationDetails
                                            )
                                        }
                                    >
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <DataTable columns={bankColumns}>
                            {reconciliationDetails.bankTransactionsReconciled.map(
                                (line, index) => (
                                    <TableRow>
                                        <DataCell>
                                            <IconButton
                                                disabled={
                                                    refreshLoading || readOnly
                                                }
                                                onClick={() =>
                                                    HandleMoveTransaction(
                                                        'down',
                                                        'bank',
                                                        index,
                                                        reconciliationDetails,
                                                        setReconciliationDetails
                                                    )
                                                }
                                            >
                                                <ExpandMore />
                                            </IconButton>
                                        </DataCell>
                                        <DataCell>{line.date}</DataCell>
                                        <DataCell>
                                            {line.reference.length > 40 ? (
                                                <Tooltip
                                                    title={line.reference}
                                                    placement="top"
                                                >
                                                    <Typography variant="body2">
                                                        {line.reference.slice(
                                                            0,
                                                            40
                                                        )}
                                                        ...
                                                    </Typography>
                                                </Tooltip>
                                            ) : (
                                                line.reference
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(line.amount)}
                                        </DataCell>
                                        <DataCell align="right">
                                            <IconButton
                                                disabled={refreshLoading}
                                                onClick={() =>
                                                    handleOraTransactionClick(
                                                        line
                                                    )
                                                }
                                            >
                                                <InfoOutlined />
                                            </IconButton>
                                        </DataCell>
                                    </TableRow>
                                )
                            )}
                        </DataTable>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <span id="unreconciled" />
                    <Paper>
                        <Typography variant="h6">Unreconciled Ora</Typography>
                        <br />
                        <DataTable columns={oraColumns}>
                            {reconciliationDetails.oraTransactionsUnreconciled.map(
                                (line, index) => (
                                    <TableRow>
                                        <DataCell align="left">
                                            <IconButton
                                                disabled={
                                                    refreshLoading || readOnly
                                                }
                                                onClick={() =>
                                                    HandleMoveTransaction(
                                                        'up',
                                                        'ora',
                                                        index,
                                                        reconciliationDetails,
                                                        setReconciliationDetails
                                                    )
                                                }
                                            >
                                                <ExpandLess />
                                            </IconButton>
                                        </DataCell>
                                        <DataCell>{line.date}</DataCell>
                                        <DataCell>
                                            {line.siteName ? (
                                                <Typography variant="body2">
                                                    {line.siteName}
                                                    <br />
                                                    {line.type}
                                                </Typography>
                                            ) : (
                                                line.type
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {line.reference.length > 20 ? (
                                                <Tooltip
                                                    title={line.reference}
                                                    placement="top"
                                                >
                                                    <Typography variant="body2">
                                                        {line.reference.slice(
                                                            0,
                                                            20
                                                        )}
                                                        ...
                                                    </Typography>
                                                </Tooltip>
                                            ) : (
                                                line.reference
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(line.amount)}
                                        </DataCell>
                                        <DataCell align="right">
                                            {line.DebtorPaymentId ||
                                            line.StripePaymentId ? null : (
                                                <IconButton
                                                    disabled={refreshLoading}
                                                    onClick={() =>
                                                        handleOraTransactionClick(
                                                            line
                                                        )
                                                    }
                                                >
                                                    <InfoOutlined />
                                                </IconButton>
                                            )}
                                        </DataCell>
                                    </TableRow>
                                )
                            )}
                        </DataTable>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <Typography variant="h6">Unreconciled Bank</Typography>
                        <br />
                        <DataTable columns={bankColumns}>
                            {reconciliationDetails.bankTransactionsUnreconciled.map(
                                (line, index) => (
                                    <TableRow>
                                        <DataCell>
                                            <IconButton
                                                disabled={
                                                    refreshLoading || readOnly
                                                }
                                                onClick={() =>
                                                    HandleMoveTransaction(
                                                        'up',
                                                        'bank',
                                                        index,
                                                        reconciliationDetails,
                                                        setReconciliationDetails
                                                    )
                                                }
                                            >
                                                <ExpandLess />
                                            </IconButton>
                                        </DataCell>
                                        <DataCell>{line.date}</DataCell>
                                        <DataCell>
                                            {line?.reference?.length > 40 ? (
                                                <Tooltip
                                                    title={line?.reference}
                                                    placement="top"
                                                >
                                                    <Typography variant="body2">
                                                        {line?.reference?.slice(
                                                            0,
                                                            40
                                                        )}
                                                        ...
                                                    </Typography>
                                                </Tooltip>
                                            ) : (
                                                line?.reference
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(line.amount)}
                                        </DataCell>
                                        <DataCell align="right">
                                            <IconButton
                                                disabled={refreshLoading}
                                                onClick={() =>
                                                    handleOraTransactionClick(
                                                        line
                                                    )
                                                }
                                            >
                                                <InfoOutlined />
                                            </IconButton>
                                        </DataCell>
                                    </TableRow>
                                )
                            )}
                        </DataTable>
                    </Paper>
                </Grid>
            </Grid>

            <Drawer
                openDrawer={drawerOpen}
                setOpenDrawer={setDrawerOpen}
                title={String(
                    RenderDrawerContent('title', selectedType, selectedObject)
                )}
                subTitle={String(
                    RenderDrawerContent(
                        'subtitle',
                        selectedType,
                        selectedObject
                    )
                )}
            >
                {RenderDrawerContent('content', selectedType, selectedObject)}
            </Drawer>

            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 145,
                    bottom: 16,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <Fab onClick={() => ScrollToElement('reconciled')}>
                    <PlaylistAddCheck />
                </Fab>
            </div>
            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 80,
                    bottom: 16,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <Fab onClick={() => ScrollToElement('unreconciled')}>
                    <PlaylistRemove />
                </Fab>
            </div>
        </>
    );
};

export default Tables;
