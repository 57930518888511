import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup
} from '@mui/material';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Site } from './interfaces/GeneralInterface';
import GetAllSites from './databaseLogic/GetAllSites';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { FileDateFormatter } from './logic/Formatters';
import api from '../../../api';

interface SelectSiteDialogContentProps {
    setSelectSiteDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const SelectSiteDialogContent = (props: SelectSiteDialogContentProps) => {
    const [sites, setSites] = useState<Site[]>([]);
    const [selectedSite, setSelectedSite] = useState<number[]>([]);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        GetAllSites(setSites);
    }, []);

    //select site
    const handleSiteSelectChange = (siteId: number, isChecked: boolean) => {
        setSelectedSite((prevState) => {
            if (isChecked) {
                return [...prevState, siteId];
            } else {
                return prevState.filter((id) => id !== siteId);
            }
        });
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        var siteFilter =
            selectedSite.length > 0
                ? selectedSite.map((siteId) => `&site=${siteId}`).join('')
                : '';
        var apiString = `${siteFilter}`;
        return apiString;
    };

    // Handle downloading the csv file
    const handleDownloadCSV = () => {
        setButtonLoading(true);
        let filterStringQuery = handleRequestCreate();
        let apiUrl = `getStockLines?&sort=${filterStringQuery}&export=true`;

        const fileDownloadDate = FileDateFormatter(new Date().toISOString());

        api.get(apiUrl, { responseType: 'blob' }) // Need to set responseType to 'blob' to handle the file download
            .then((response) => {
                // Create a URL for Blob
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;

                // Set the csv file name
                const filename = `OraPartSOHResults-${fileDownloadDate}.csv`; // Set a filename for the downloaded file
                link.setAttribute('download', filename); // Trigger the file download

                // trigger the download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                setButtonLoading(false);
            })
            .catch((error) => {
                console.error('File download error:', error);
                setButtonLoading(false);
            });
    };

    return (
        <>
            <DialogContent>
                {sites?.length ? (
                    <FormGroup row={true}>
                        {sites.map((site, index) => (
                            <FormControlLabel
                                key={index + site.id}
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            handleSiteSelectChange(
                                                site.id,
                                                e.target.checked
                                            )
                                        }
                                    />
                                }
                                label={site.name}
                            />
                        ))}
                    </FormGroup>
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setSelectSiteDialogOpen(false)}>
                    Cancel
                </Button>
                <LoadingButton
                    loading={buttonLoading}
                    disabled={selectedSite.length === 0}
                    variant="contained"
                    onClick={handleDownloadCSV}
                >
                    Download
                </LoadingButton>
            </DialogActions>
        </>
    );
};
export default SelectSiteDialogContent;
