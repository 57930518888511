import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { Template } from '../interfaces/GeneralInterface';

const GetTextMessageTemplates = (
  department: string,
  setTextTemplates: Dispatch<SetStateAction<Template[]>>
) => {
  api.get(`textMessageTemplates?department=${department}`).then((res) => {
    setTextTemplates(res.data);
  });
};

export default GetTextMessageTemplates;
