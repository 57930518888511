// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Account } from '../../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface AccountError {
    name: boolean;
    type: boolean;
}

// Adds a new account after some basic validation
const HandleAddAccount = (
    selectedAccount: Account,
    setAccountError: Dispatch<SetStateAction<AccountError>>,
    showSnackbar: showSnackbar
) => {
    let isError = false;
    const errors = {
        name: false,
        type: false
    };

    if (selectedAccount?.name == null || selectedAccount?.name === '') {
        isError = true;
        errors.name = true;
    }
    if (selectedAccount?.type == null || selectedAccount?.type === '') {
        isError = true;
        errors.type = true;
    }

    if (!isError) {
        api.post('addNewAccountNextGen', selectedAccount).then((res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Account successfully added',
                    'The page will now refresh.',
                    'success'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    } else {
        setAccountError(errors);
        showSnackbar(
            'Whoops! You have missing or invalid data.',
            'Please fill out the highlighted fields to continue.',
            'error'
        );
    }
};

export default HandleAddAccount;
