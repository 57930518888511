import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
interface GroupedOnlineOrders {
    pick: GroupedOnlineOrder[];
    pack: GroupedOnlineOrder[];
}

const MoveOrderToPick = (
    selectedOrder: GroupedOnlineOrder,
    setSelectedGroupOrders: Dispatch<SetStateAction<GroupedOnlineOrder[]>>,
    pickPackOrders: GroupedOnlineOrders,
    setPickPackOrders: Dispatch<SetStateAction<GroupedOnlineOrders>>,
    showSnackbar: showSnackbar
) => {
    api.put(
        `moveOrderToPick?SiteId=${selectedOrder.oraOrder.SiteId}`,
        selectedOrder
    ).then((res) => {
        if (res.status === 200) {
            let currentPickOrders = [...pickPackOrders.pick];
            let currentPackOrders = [...pickPackOrders.pack];

            let index = currentPackOrders.findIndex(
                (x) => x.oraOrder.id === selectedOrder.oraOrder.id
            );
            currentPackOrders[index].netoOrder.orderStatus = 'Pick';

            currentPickOrders.unshift(currentPackOrders[index]);
            currentPackOrders = currentPackOrders.filter(
                (x) => x.oraOrder.id !== selectedOrder.oraOrder.id
            );

            setSelectedGroupOrders(currentPackOrders);
            setPickPackOrders({
                ...pickPackOrders,
                pick: currentPickOrders,
                pack: currentPackOrders
            });

            showSnackbar('Order moved to pick.');
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default MoveOrderToPick;
