// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
import Autocomplete from '../../../global/Autocomplete';
// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
// INTERFACES
import { Site, User } from '../../../global/interfaces/GeneralInterface';
import CheckUsername from '../../../global/logic/CheckUsername';
import RoleCheck from '../../../global/RoleCheck';
interface StandardDetailsProps {
    userDetails: User;
    setUserDetails: Dispatch<SetStateAction<User>>;
    readOnly: boolean;
    responseCode: number;
    setResponseCode: Dispatch<SetStateAction<number>>;
}
const StandardDetails = ({
    userDetails,
    readOnly,
    setUserDetails,
    responseCode,
    setResponseCode
}: StandardDetailsProps) => {
    const [sites, setSites] = useState<Site[]>([]);
    const [unemployedDialogOpen, setUnemployedDialogOpen] = useState(false);
    const [superUserDialogOpen, setSuperUserDialogOpen] = useState(false);

    const departmentOptions = [
        { value: 'admin', label: 'Admin' },
        { value: 'IT', label: 'IT' },
        { value: 'parts', label: 'Parts & Accessories' },
        { value: 'sales', label: 'Sales' },
        { value: 'service', label: 'Service' }
    ];

    useEffect(() => {
        GetAllSites(setSites);
    }, []);

    return (
        <Paper>
            <Typography variant="h6">Basic Details</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        data-cy="viewUserFirstNameField"
                        fullWidth
                        size="small"
                        label="First Name"
                        InputLabelProps={{ shrink: true }}
                        disabled={readOnly}
                        value={userDetails?.firstName}
                        onChange={(e) =>
                            setUserDetails({
                                ...userDetails,
                                firstName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        data-cy="viewUserLastNameField"
                        fullWidth
                        size="small"
                        label="Last Name"
                        InputLabelProps={{ shrink: true }}
                        disabled={readOnly}
                        value={userDetails?.lastName}
                        onChange={(e) =>
                            setUserDetails({
                                ...userDetails,
                                lastName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        data-cy="viewUserUsernameField"
                        fullWidth
                        size="small"
                        label="Username"
                        InputLabelProps={{ shrink: true }}
                        disabled={readOnly}
                        value={userDetails?.username}
                        onChange={(e) =>
                            setUserDetails({
                                ...userDetails,
                                username: e.target.value
                            })
                        }
                        onBlur={(e) =>
                            CheckUsername(
                                userDetails.id,
                                e.target.value,
                                setResponseCode
                            )
                        }
                        error={responseCode === 400 ? true : false}
                        helperText={
                            responseCode === 400
                                ? 'Username Already Exists.'
                                : ''
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        data-cy="viewUserDepartmentField"
                        fullWidth
                        select
                        InputProps={{ style: { textAlign: 'center' } }}
                        size="small"
                        label="Department"
                        InputLabelProps={{ shrink: true }}
                        disabled={readOnly}
                        value={userDetails?.department}
                        onChange={(e) =>
                            setUserDetails({
                                ...userDetails,
                                department: e.target.value
                            })
                        }
                    >
                        {departmentOptions.map((option) => (
                            <MenuItem
                                value={option.value}
                                data-cy={`viewUserDepartment-${option.label}`}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        data-cy="viewUserStaffEmailField"
                        fullWidth
                        size="small"
                        label="Staff Email"
                        InputLabelProps={{ shrink: true }}
                        disabled={readOnly}
                        value={userDetails?.staffEmail}
                        onChange={(e) =>
                            setUserDetails({
                                ...userDetails,
                                staffEmail: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        cypressLabel="viewUserPrimaryStoreAutocomplete"
                        size="small"
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel="name"
                        textfieldLabel="Primary Store"
                        isDisabled={readOnly}
                        selectedValue={userDetails.Site}
                        handleSelectedValueChange={(newValue) =>
                            setUserDetails({
                                ...userDetails,
                                Site: newValue,
                                SiteId: newValue.id
                            })
                        }
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormGroup row>
                        <FormControlLabel
                            label="Employed"
                            control={
                                <Checkbox
                                    disabled={readOnly}
                                    data-cy={'viewUserEmployedCheckbox'}
                                    checked={userDetails?.employed}
                                    onChange={(e) =>
                                        !e.target.checked
                                            ? setUnemployedDialogOpen(true)
                                            : setUserDetails({
                                                  ...userDetails,
                                                  employed: e.target.checked
                                              })
                                    }
                                />
                            }
                        />

                        <RoleCheck
                            permission="assign_superuser"
                            component={
                                <FormControlLabel
                                    label="Super User"
                                    control={
                                        <Checkbox
                                            disabled={readOnly}
                                            checked={userDetails?.isGod}
                                            onChange={(e) =>
                                                e.target.checked
                                                    ? setSuperUserDialogOpen(
                                                          true
                                                      )
                                                    : setUserDetails({
                                                          ...userDetails,
                                                          isGod: e.target
                                                              .checked
                                                      })
                                            }
                                        />
                                    }
                                />
                            }
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <Dialog
                open={unemployedDialogOpen}
                onClose={() => setUnemployedDialogOpen(false)}
            >
                <DialogTitle>Set this user as unemployed?</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        By marking this user as unemployed they will lose access
                        to Ora. Any actions done by them within Ora (such as
                        sales) will remain and no data will be lost.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUnemployedDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        data-cy={'viewUserMarkAsUnemployedButton'}
                        onClick={() => {
                            setUserDetails({ ...userDetails, employed: false });
                            setUnemployedDialogOpen(false);
                        }}
                    >
                        Mark as Unemployed
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={superUserDialogOpen}
                onClose={() => setSuperUserDialogOpen(false)}
            >
                <DialogTitle>Set this user as a Super User?</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        By marking this user as a super user they will
                        immediately have all permissions and the ability to
                        override any permission in Ora.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSuperUserDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setUserDetails({ ...userDetails, isGod: true });
                            setSuperUserDialogOpen(false);
                        }}
                    >
                        Mark as Super User
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default StandardDetails;
