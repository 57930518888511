// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import {
    VehicleRegistrationLine,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface RegistrationData {
    Registration?: VehicleRegistrationLine;
    VehicleSale?: VehicleSale;
}

const SubmitRegoReconciliation = (
    reconciledRegistrationData: RegistrationData[],
    showSnackbar: showSnackbar,
    setLoading: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    api.post('regoReconciliation', reconciledRegistrationData).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Reconciliation submitted successfully.',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default SubmitRegoReconciliation;
