// IMPORTS
import { Typography } from '@mui/material';
// LOGIC
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';

interface BankTransactionContentProps {
  selectedObject;
}

const BankTransactionContent = ({
  selectedObject
}: BankTransactionContentProps) => {
  return (
    <>
      <Typography variant="body1">{selectedObject.reference}</Typography>
      <br />
      <Typography variant="body1">
        <b>Amount:</b> {CurrencyFormatter(selectedObject.amount)}
      </Typography>
      <Typography variant="body1">
        <b>Transaction Date:</b> {selectedObject.date}
      </Typography>
      <br />
      <Typography variant="body1">
        <b>File Name:</b> {selectedObject.fileName}
      </Typography>
      {selectedObject.submittedBy && (
        <Typography variant="body1">
          <b>Uploaded by:</b> {selectedObject.submittedBy}
        </Typography>
      )}
      <Typography variant="body1">
        <b>Uploaded on:</b> {selectedObject.submittedOn}
      </Typography>
    </>
  );
};

export default BankTransactionContent;
