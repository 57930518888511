// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Divider, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { Settings } from '../../../global/interfaces/GeneralInterface';

interface SettingsProps {
    settings: Settings; // The company settings
    setSettings: Dispatch<SetStateAction<Settings>>;
    disabled: boolean;
}

/**
 * BusinessDetails
 * The business details UI for Company Settings
 * @author Estienne
 * @param SettingsProps
 */
const BusinessDetails = ({
    settings,
    setSettings,
    disabled
}: SettingsProps) => {
    return (
        <Paper>
            <Typography variant="h6" align="left">
                Business Details
            </Typography>
            <Divider /> <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={disabled}
                        label="Company Name"
                        value={settings?.companyName}
                        error={settings?.companyName === '' ? true : false}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSettings({
                                ...settings,
                                companyName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={disabled}
                        label="Legal Entity Name"
                        value={settings?.legalEntityName}
                        error={settings?.legalEntityName === '' ? true : false}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSettings({
                                ...settings,
                                legalEntityName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={disabled}
                        label="ABN"
                        value={settings?.abn}
                        error={settings?.abn === '' ? true : false}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSettings({ ...settings, abn: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={disabled}
                        label="ACN"
                        value={settings?.acn}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSettings({ ...settings, acn: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={disabled}
                        label="Direct Deposit Account Name"
                        value={settings?.directDepositAccountName}
                        error={
                            settings?.directDepositAccountName === ''
                                ? true
                                : false
                        }
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSettings({
                                ...settings,
                                directDepositAccountName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={disabled}
                        label="Bank Name"
                        value={settings?.bankName}
                        error={settings?.bankName === '' ? true : false}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSettings({
                                ...settings,
                                bankName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={disabled}
                        label="Direct Deposit Account BSB"
                        value={settings?.directDepositAccountBsb}
                        error={
                            settings?.directDepositAccountBsb === ''
                                ? true
                                : false
                        }
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSettings({
                                ...settings,
                                directDepositAccountBsb: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={disabled}
                        label="Direct Deposit Account No."
                        value={settings?.directDepositAccountNumber}
                        error={
                            settings?.directDepositAccountNumber === ''
                                ? true
                                : false
                        }
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSettings({
                                ...settings,
                                directDepositAccountNumber: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        disabled={disabled}
                        label="LMD"
                        value={settings?.lmd}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSettings({ ...settings, lmd: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            label="LMD Expiry Date"
                            disabled={disabled}
                            value={dayjs(settings?.lmdExpiryDate)}
                            onChange={(newValue) =>
                                setSettings({
                                    ...settings,
                                    lmdExpiryDate: dayjs(newValue).toISOString()
                                })
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true },
                                    inputProps: {
                                        style: { textAlign: 'center' }
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default BusinessDetails;
