const GroupBy = (array, groupKey) => {
  return array.reduce(function (rv, x) {
      let key = x[groupKey];
      //convert to string when key is an object
      if (typeof key === 'object') {
          key = JSON.stringify(key);
      }

      (rv[key] = rv[key] || []).push(x);
      return rv;
  }, []);
};

export default GroupBy;
