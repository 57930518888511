import DocumentationPage from '../DocumentationPage';
import {
    ManageVehicleStockSteps,
    ManageManufacturerVehicleStockSteps,
    SearchExportVehicleStockSteps
} from '../data/vehicleSales/stockVehicles';

const StockVehiclesSupportPage = () => {
    const contentSections = [
        {
            id: 'search-export-vehicle-stock',
            title: 'Search and export Vehicle Stock',
            href: '/support/vehicles/stock/#search-export-vehicle-stock',
            description:
                'To search and export Vehicle Stock, follow these steps :',
            steps: SearchExportVehicleStockSteps
        },
        {
            id: 'manage-vehicle-stock',
            title: 'Manage Vehicle Stock',
            href: '/support/vehicles/stock/#manage-vehicle-stock',
            description: 'To manage Vehicle Stock, follow these steps :',
            steps: ManageVehicleStockSteps
        },
        {
            id: 'manage-manufacturer-vehicle-stock',
            title: 'Manage Manufacturer Vehicle',
            href: '/support/vehicles/stock/#manage-manufacturer-vehicle-stock',
            description: 'To manage Manufacturer Vehicle, follow these steps :',
            steps: ManageManufacturerVehicleStockSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Stock vehicles"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default StockVehiclesSupportPage;
