import jsPDF from 'jspdf';
import {
    CurrencyFormatter,
    ToFixed
} from '../../../../../../global/logic/Formatters';
import { PrintPNLReportParams } from '../../interface/PNLInterfaces';

const PNLSales = (doc: jsPDF, params: PrintPNLReportParams) => {
    doc.text('SALES', 10, 30);
    doc.setFont('helvetica', 'normal');
    doc.text('Sales - New Bikes', 10, 34);
    doc.text('Sales - Used Bikes', 10, 38);
    doc.text('Parts', 10, 42);
    doc.text('Service', 10, 46);
    doc.setFont('helvetica', 'bold').text('TOTAL SALES', 10, 50);
    doc.text('TOTAL COST OF GOODS SOLD', 10, 54);
    doc.text('GROSS PROFIT FROM TRADING', 10, 58);

    // SALES DATA
    doc.setFont('helvetica', 'normal').text(
        CurrencyFormatter(
            params.vehicleValues.newRevenue +
                params.vehicleValues.DOFFromNew +
                params.vehicleValues.adminLoadFromNew +
                params.vehicleValues.warrantyRevenueFromNew
        ),
        74,
        34,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.vehicleValues.usedRevenue +
                params.vehicleValues.DOFFromUsed +
                params.vehicleValues.adminLoadFromUsed +
                params.vehicleValues.warrantyRevenueFromUsed
        ),
        74,
        38,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.partValues.onlineRevenue +
                params.partValues.partRevenue +
                params.partValues.saleRevenue +
                params.partValues.serviceRevenue +
                params.partValues.vehicleRevenue
        ),
        74,
        42,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.serviceValues.internalRevenue +
                params.serviceValues.retailRevenue +
                params.serviceValues.insuranceRevenue +
                params.serviceValues.warrantyRevenue
        ),
        74,
        46,
        { align: 'right' }
    );
    doc.setFont('helvetica', 'bold').text(
        CurrencyFormatter(
            params.vehicleValues.newRevenue +
                params.vehicleValues.usedRevenue +
                params.vehicleValues.DOFFromNew +
                params.vehicleValues.adminLoadFromNew +
                params.vehicleValues.warrantyRevenueFromNew +
                params.vehicleValues.DOFFromUsed +
                params.vehicleValues.adminLoadFromUsed +
                params.vehicleValues.warrantyRevenueFromUsed +
                params.partValues.onlineRevenue +
                params.partValues.partRevenue +
                params.partValues.saleRevenue +
                params.partValues.serviceRevenue +
                params.partValues.vehicleRevenue +
                params.serviceValues.internalRevenue +
                params.serviceValues.retailRevenue +
                params.serviceValues.insuranceRevenue +
                params.serviceValues.warrantyRevenue
        ),
        74,
        50,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.vehicleValues.newCost +
                params.vehicleValues.usedCost +
                params.partValues.onlineCost +
                params.partValues.partCost +
                params.partValues.saleCost +
                params.partValues.serviceCost +
                params.partValues.vehicleCost +
                params.serviceValues.insuranceCost +
                params.serviceValues.internalCost +
                params.serviceValues.retailCost +
                params.serviceValues.warrantyCost
        ),
        74,
        54,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.vehicleValues.newRevenue +
                params.vehicleValues.usedRevenue +
                params.vehicleValues.DOFFromNew +
                params.vehicleValues.adminLoadFromNew +
                params.vehicleValues.warrantyRevenueFromNew +
                params.vehicleValues.DOFFromUsed +
                params.vehicleValues.adminLoadFromUsed +
                params.vehicleValues.warrantyRevenueFromUsed +
                params.partValues.onlineRevenue +
                params.partValues.partRevenue +
                params.partValues.saleRevenue +
                params.partValues.serviceRevenue +
                params.partValues.vehicleRevenue +
                params.serviceValues.internalRevenue +
                params.serviceValues.retailRevenue +
                params.serviceValues.insuranceRevenue +
                params.serviceValues.warrantyRevenue -
                (params.vehicleValues.newCost +
                    params.vehicleValues.usedCost +
                    params.partValues.onlineCost +
                    params.partValues.partCost +
                    params.partValues.saleCost +
                    params.partValues.serviceCost +
                    params.partValues.vehicleCost +
                    params.serviceValues.insuranceCost +
                    params.serviceValues.internalCost +
                    params.serviceValues.retailCost +
                    params.serviceValues.warrantyCost)
        ),
        74,
        58,
        { align: 'right' }
    );

    // SALES DATA PERCENTAGES
    doc.setFont('helvetica', 'normal').text(
        ToFixed(
            ((params.vehicleValues.newRevenue +
                params.vehicleValues.DOFFromNew +
                params.vehicleValues.adminLoadFromNew +
                params.vehicleValues.warrantyRevenueFromNew) /
                (params.vehicleValues.newRevenue +
                    params.vehicleValues.usedRevenue +
                    params.vehicleValues.DOFFromNew +
                    params.vehicleValues.adminLoadFromNew +
                    params.vehicleValues.warrantyRevenueFromNew +
                    params.vehicleValues.DOFFromUsed +
                    params.vehicleValues.adminLoadFromUsed +
                    params.vehicleValues.warrantyRevenueFromUsed +
                    params.partValues.onlineRevenue +
                    params.partValues.partRevenue +
                    params.partValues.saleRevenue +
                    params.partValues.serviceRevenue +
                    params.partValues.vehicleRevenue +
                    params.serviceValues.internalRevenue +
                    params.serviceValues.retailRevenue +
                    params.serviceValues.insuranceRevenue +
                    params.serviceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        87,
        34,
        { align: 'right' }
    );

    doc.text(
        ToFixed(
            ((params.vehicleValues.usedRevenue +
                params.vehicleValues.DOFFromUsed +
                params.vehicleValues.adminLoadFromUsed +
                params.vehicleValues.warrantyRevenueFromUsed) /
                (params.vehicleValues.newRevenue +
                    params.vehicleValues.usedRevenue +
                    params.vehicleValues.DOFFromNew +
                    params.vehicleValues.adminLoadFromNew +
                    params.vehicleValues.warrantyRevenueFromNew +
                    params.vehicleValues.DOFFromUsed +
                    params.vehicleValues.adminLoadFromUsed +
                    params.vehicleValues.warrantyRevenueFromUsed +
                    params.partValues.onlineRevenue +
                    params.partValues.partRevenue +
                    params.partValues.saleRevenue +
                    params.partValues.serviceRevenue +
                    params.partValues.vehicleRevenue +
                    params.serviceValues.internalRevenue +
                    params.serviceValues.retailRevenue +
                    params.serviceValues.insuranceRevenue +
                    params.serviceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        87,
        38,
        { align: 'right' }
    );
    doc.text(
        ToFixed(
            ((params.partValues.onlineRevenue +
                params.partValues.partRevenue +
                params.partValues.saleRevenue +
                params.partValues.serviceRevenue +
                params.partValues.vehicleRevenue) /
                (params.vehicleValues.newRevenue +
                    params.vehicleValues.usedRevenue +
                    params.vehicleValues.DOFFromNew +
                    params.vehicleValues.adminLoadFromNew +
                    params.vehicleValues.warrantyRevenueFromNew +
                    params.vehicleValues.DOFFromUsed +
                    params.vehicleValues.adminLoadFromUsed +
                    params.vehicleValues.warrantyRevenueFromUsed +
                    params.partValues.onlineRevenue +
                    params.partValues.partRevenue +
                    params.partValues.saleRevenue +
                    params.partValues.serviceRevenue +
                    params.partValues.vehicleRevenue +
                    params.serviceValues.internalRevenue +
                    params.serviceValues.retailRevenue +
                    params.serviceValues.insuranceRevenue +
                    params.serviceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        87,
        42,
        { align: 'right' }
    );
    doc.text(
        ToFixed(
            ((params.serviceValues.internalRevenue +
                params.serviceValues.retailRevenue +
                params.serviceValues.insuranceRevenue +
                params.serviceValues.warrantyRevenue) /
                (params.vehicleValues.newRevenue +
                    params.vehicleValues.usedRevenue +
                    params.vehicleValues.DOFFromNew +
                    params.vehicleValues.adminLoadFromNew +
                    params.vehicleValues.warrantyRevenueFromNew +
                    params.vehicleValues.DOFFromUsed +
                    params.vehicleValues.adminLoadFromUsed +
                    params.vehicleValues.warrantyRevenueFromUsed +
                    params.partValues.onlineRevenue +
                    params.partValues.partRevenue +
                    params.partValues.saleRevenue +
                    params.partValues.serviceRevenue +
                    params.partValues.vehicleRevenue +
                    params.serviceValues.internalRevenue +
                    params.serviceValues.retailRevenue +
                    params.serviceValues.insuranceRevenue +
                    params.serviceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        87,
        46,
        { align: 'right' }
    );

    doc.setFont('helvetica', 'bold').text('100.00%', 87, 50, {
        align: 'right'
    });
    doc.text(
        ToFixed(
            ((params.vehicleValues.newCost +
                params.vehicleValues.usedCost +
                params.partValues.onlineCost +
                params.partValues.partCost +
                params.partValues.saleCost +
                params.partValues.serviceCost +
                params.partValues.vehicleCost +
                params.serviceValues.insuranceCost +
                params.serviceValues.internalCost +
                params.serviceValues.retailCost +
                params.serviceValues.warrantyCost) /
                (params.vehicleValues.newRevenue +
                    params.vehicleValues.usedRevenue +
                    params.vehicleValues.DOFFromNew +
                    params.vehicleValues.adminLoadFromNew +
                    params.vehicleValues.warrantyRevenueFromNew +
                    params.vehicleValues.DOFFromUsed +
                    params.vehicleValues.adminLoadFromUsed +
                    params.vehicleValues.warrantyRevenueFromUsed +
                    params.partValues.onlineRevenue +
                    params.partValues.partRevenue +
                    params.partValues.saleRevenue +
                    params.partValues.serviceRevenue +
                    params.partValues.vehicleRevenue +
                    params.serviceValues.internalRevenue +
                    params.serviceValues.retailRevenue +
                    params.serviceValues.insuranceRevenue +
                    params.serviceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        87,
        54,
        { align: 'right' }
    );
    doc.text(
        ToFixed(
            ((params.vehicleValues.newRevenue +
                params.vehicleValues.usedRevenue +
                params.vehicleValues.DOFFromNew +
                params.vehicleValues.adminLoadFromNew +
                params.vehicleValues.warrantyRevenueFromNew +
                params.vehicleValues.DOFFromUsed +
                params.vehicleValues.adminLoadFromUsed +
                params.vehicleValues.warrantyRevenueFromUsed +
                params.partValues.onlineRevenue +
                params.partValues.partRevenue +
                params.partValues.saleRevenue +
                params.partValues.serviceRevenue +
                params.partValues.vehicleRevenue +
                params.serviceValues.internalRevenue +
                params.serviceValues.retailRevenue +
                params.serviceValues.insuranceRevenue +
                params.serviceValues.warrantyRevenue -
                (params.vehicleValues.newCost +
                    params.vehicleValues.usedCost +
                    params.partValues.onlineCost +
                    params.partValues.partCost +
                    params.partValues.saleCost +
                    params.partValues.serviceCost +
                    params.partValues.vehicleCost +
                    params.serviceValues.insuranceCost +
                    params.serviceValues.internalCost +
                    params.serviceValues.retailCost +
                    params.serviceValues.warrantyCost)) /
                (params.vehicleValues.newRevenue +
                    params.vehicleValues.usedRevenue +
                    params.vehicleValues.DOFFromNew +
                    params.vehicleValues.adminLoadFromNew +
                    params.vehicleValues.warrantyRevenueFromNew +
                    params.vehicleValues.DOFFromUsed +
                    params.vehicleValues.adminLoadFromUsed +
                    params.vehicleValues.warrantyRevenueFromUsed +
                    params.partValues.onlineRevenue +
                    params.partValues.partRevenue +
                    params.partValues.saleRevenue +
                    params.partValues.serviceRevenue +
                    params.partValues.vehicleRevenue +
                    params.serviceValues.internalRevenue +
                    params.serviceValues.retailRevenue +
                    params.serviceValues.insuranceRevenue +
                    params.serviceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        87,
        58,
        { align: 'right' }
    );

    // YTD SALE DATA
    doc.setFont('helvetica', 'normal').text(
        CurrencyFormatter(
            ToFixed(
                params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.DOFFromNew +
                    params.ytdMonthVehicleValues.adminLoadFromNew +
                    params.ytdMonthVehicleValues.warrantyRevenueFromNew
            )
        ),
        107,
        34,
        { align: 'right' }
    );
    doc.setFont('helvetica', 'normal').text(
        CurrencyFormatter(
            ToFixed(
                params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthVehicleValues.DOFFromUsed +
                    params.ytdMonthVehicleValues.adminLoadFromUsed +
                    params.ytdMonthVehicleValues.warrantyRevenueFromUsed
            )
        ),
        107,
        38,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            ToFixed(
                params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue
            )
        ),
        107,
        42,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            ToFixed(
                params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue
            )
        ),
        107,
        46,
        {
            align: 'right'
        }
    );
    doc.setFont('helvetica', 'bold').text(
        CurrencyFormatter(
            ToFixed(
                params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthVehicleValues.DOFFromNew +
                    params.ytdMonthVehicleValues.adminLoadFromNew +
                    params.ytdMonthVehicleValues.warrantyRevenueFromNew +
                    params.ytdMonthVehicleValues.DOFFromUsed +
                    params.ytdMonthVehicleValues.adminLoadFromUsed +
                    params.ytdMonthVehicleValues.warrantyRevenueFromUsed +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue
            )
        ),
        107,
        50,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            ToFixed(
                params.ytdMonthVehicleValues.newCost +
                    params.ytdMonthVehicleValues.usedCost +
                    params.ytdMonthPartValues.onlineCost +
                    params.ytdMonthPartValues.partCost +
                    params.ytdMonthPartValues.saleCost +
                    params.ytdMonthPartValues.serviceCost +
                    params.ytdMonthPartValues.vehicleCost +
                    params.ytdMonthServiceValues.insuranceCost +
                    (params.ytdMonthServiceValues.internalCost ?? 0) +
                    params.ytdMonthServiceValues.retailCost +
                    params.ytdMonthServiceValues.warrantyCost
            )
        ),
        107,
        54,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            ToFixed(
                params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthVehicleValues.DOFFromNew +
                    params.ytdMonthVehicleValues.adminLoadFromNew +
                    params.ytdMonthVehicleValues.warrantyRevenueFromNew +
                    params.ytdMonthVehicleValues.DOFFromUsed +
                    params.ytdMonthVehicleValues.adminLoadFromUsed +
                    params.ytdMonthVehicleValues.warrantyRevenueFromUsed +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue -
                    (params.ytdMonthVehicleValues.newCost +
                        params.ytdMonthVehicleValues.usedCost +
                        params.ytdMonthPartValues.onlineCost +
                        params.ytdMonthPartValues.partCost +
                        params.ytdMonthPartValues.saleCost +
                        params.ytdMonthPartValues.serviceCost +
                        params.ytdMonthPartValues.vehicleCost +
                        params.ytdMonthServiceValues.insuranceCost +
                        params.ytdMonthServiceValues.internalCost +
                        params.ytdMonthServiceValues.retailCost +
                        params.ytdMonthServiceValues.warrantyCost)
            )
        ),
        107,
        58,
        { align: 'right' }
    );

    // YTD SALES DATA PERCENTAGES
    doc.setFont('helvetica', 'normal').text(
        ToFixed(
            ((params.ytdMonthVehicleValues.newRevenue +
                params.ytdMonthVehicleValues.DOFFromNew +
                params.ytdMonthVehicleValues.adminLoadFromNew +
                params.ytdMonthVehicleValues.warrantyRevenueFromNew) /
                (params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthVehicleValues.DOFFromNew +
                    params.ytdMonthVehicleValues.adminLoadFromNew +
                    params.ytdMonthVehicleValues.warrantyRevenueFromNew +
                    params.ytdMonthVehicleValues.DOFFromUsed +
                    params.ytdMonthVehicleValues.adminLoadFromUsed +
                    params.ytdMonthVehicleValues.warrantyRevenueFromUsed +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        120,
        34,
        { align: 'right' }
    );
    doc.text(
        ToFixed(
            ((params.ytdMonthVehicleValues.usedRevenue +
                params.ytdMonthVehicleValues.DOFFromUsed +
                params.ytdMonthVehicleValues.adminLoadFromUsed +
                params.ytdMonthVehicleValues.warrantyRevenueFromUsed) /
                (params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthVehicleValues.DOFFromNew +
                    params.ytdMonthVehicleValues.adminLoadFromNew +
                    params.ytdMonthVehicleValues.warrantyRevenueFromNew +
                    params.ytdMonthVehicleValues.DOFFromUsed +
                    params.ytdMonthVehicleValues.adminLoadFromUsed +
                    params.ytdMonthVehicleValues.warrantyRevenueFromUsed +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        120,
        38,
        { align: 'right' }
    );
    doc.text(
        ToFixed(
            ((params.ytdMonthPartValues.onlineRevenue +
                params.ytdMonthPartValues.partRevenue +
                params.ytdMonthPartValues.saleRevenue +
                params.ytdMonthPartValues.serviceRevenue +
                params.ytdMonthPartValues.vehicleRevenue) /
                (params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthVehicleValues.DOFFromNew +
                    params.ytdMonthVehicleValues.adminLoadFromNew +
                    params.ytdMonthVehicleValues.warrantyRevenueFromNew +
                    params.ytdMonthVehicleValues.DOFFromUsed +
                    params.ytdMonthVehicleValues.adminLoadFromUsed +
                    params.ytdMonthVehicleValues.warrantyRevenueFromUsed +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        120,
        42,
        { align: 'right' }
    );
    doc.text(
        ToFixed(
            ((params.ytdMonthServiceValues.internalRevenue +
                params.ytdMonthServiceValues.retailRevenue +
                params.ytdMonthServiceValues.insuranceRevenue +
                params.ytdMonthServiceValues.warrantyRevenue) /
                (params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthVehicleValues.DOFFromNew +
                    params.ytdMonthVehicleValues.adminLoadFromNew +
                    params.ytdMonthVehicleValues.warrantyRevenueFromNew +
                    params.ytdMonthVehicleValues.DOFFromUsed +
                    params.ytdMonthVehicleValues.adminLoadFromUsed +
                    params.ytdMonthVehicleValues.warrantyRevenueFromUsed +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        120,
        46,
        { align: 'right' }
    );

    doc.setFont('helvetica', 'bold').text('100.00%', 120, 50, {
        align: 'right'
    });

    doc.text(
        ToFixed(
            ((params.ytdMonthVehicleValues.newCost +
                params.ytdMonthVehicleValues.usedCost +
                params.ytdMonthPartValues.onlineCost +
                params.ytdMonthPartValues.partCost +
                params.ytdMonthPartValues.saleCost +
                params.ytdMonthPartValues.serviceCost +
                params.ytdMonthPartValues.vehicleCost +
                params.ytdMonthServiceValues.insuranceCost +
                params.ytdMonthServiceValues.internalCost +
                params.ytdMonthServiceValues.retailCost +
                params.ytdMonthServiceValues.warrantyCost) /
                (params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthVehicleValues.DOFFromNew +
                    params.ytdMonthVehicleValues.adminLoadFromNew +
                    params.ytdMonthVehicleValues.warrantyRevenueFromNew +
                    params.ytdMonthVehicleValues.DOFFromUsed +
                    params.ytdMonthVehicleValues.adminLoadFromUsed +
                    params.ytdMonthVehicleValues.warrantyRevenueFromUsed +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        120,
        54,
        { align: 'right' }
    );
    doc.text(
        ToFixed(
            ((params.ytdMonthVehicleValues.newRevenue +
                params.ytdMonthVehicleValues.usedRevenue +
                params.ytdMonthVehicleValues.DOFFromNew +
                params.ytdMonthVehicleValues.adminLoadFromNew +
                params.ytdMonthVehicleValues.warrantyRevenueFromNew +
                params.ytdMonthVehicleValues.DOFFromUsed +
                params.ytdMonthVehicleValues.adminLoadFromUsed +
                params.ytdMonthVehicleValues.warrantyRevenueFromUsed +
                params.ytdMonthPartValues.onlineRevenue +
                params.ytdMonthPartValues.partRevenue +
                params.ytdMonthPartValues.saleRevenue +
                params.ytdMonthPartValues.serviceRevenue +
                params.ytdMonthPartValues.vehicleRevenue +
                params.ytdMonthServiceValues.internalRevenue +
                params.ytdMonthServiceValues.retailRevenue +
                params.ytdMonthServiceValues.insuranceRevenue +
                params.ytdMonthServiceValues.warrantyRevenue -
                (params.ytdMonthVehicleValues.newCost +
                    params.ytdMonthVehicleValues.usedCost +
                    params.ytdMonthPartValues.onlineCost +
                    params.ytdMonthPartValues.partCost +
                    params.ytdMonthPartValues.saleCost +
                    params.ytdMonthPartValues.serviceCost +
                    params.ytdMonthPartValues.vehicleCost +
                    params.ytdMonthServiceValues.insuranceCost +
                    params.ytdMonthServiceValues.internalCost +
                    params.ytdMonthServiceValues.retailCost +
                    params.ytdMonthServiceValues.warrantyCost)) /
                (params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthVehicleValues.DOFFromNew +
                    params.ytdMonthVehicleValues.adminLoadFromNew +
                    params.ytdMonthVehicleValues.warrantyRevenueFromNew +
                    params.ytdMonthVehicleValues.DOFFromUsed +
                    params.ytdMonthVehicleValues.adminLoadFromUsed +
                    params.ytdMonthVehicleValues.warrantyRevenueFromUsed +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue)) *
                100
        ).toString() + '%',
        120,
        58,
        { align: 'right' }
    );

    // PREVIOUS SALE DATA
    doc.setFont('helvetica', 'normal').text(
        CurrencyFormatter(
            params.previousVehicleValues.newRevenue +
                params.previousVehicleValues.DOFFromNew +
                params.previousVehicleValues.warrantyRevenueFromNew +
                params.previousVehicleValues.adminLoadFromNew
        ),
        140,
        34,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.previousVehicleValues.usedRevenue +
                params.previousVehicleValues.DOFFromUsed +
                params.previousVehicleValues.warrantyRevenueFromUsed +
                params.previousVehicleValues.adminLoadFromUsed
        ),
        140,
        38,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.previousPartValues.onlineRevenue +
                params.previousPartValues.partRevenue +
                params.previousPartValues.saleRevenue +
                params.previousPartValues.serviceRevenue +
                params.previousPartValues.vehicleRevenue
        ),
        140,
        42,
        {
            align: 'right'
        }
    );
    doc.text(
        CurrencyFormatter(
            params.previousServiceValues.internalRevenue +
                params.previousServiceValues.retailRevenue +
                params.previousServiceValues.insuranceRevenue +
                params.previousServiceValues.warrantyRevenue
        ),
        140,
        46,
        { align: 'right' }
    );
    doc.setFont('helvetica', 'bold').text(
        CurrencyFormatter(
            params.previousVehicleValues.newRevenue +
                params.previousVehicleValues.usedRevenue +
                params.previousVehicleValues.DOFFromNew +
                params.previousVehicleValues.warrantyRevenueFromNew +
                params.previousVehicleValues.adminLoadFromNew +
                params.previousVehicleValues.DOFFromUsed +
                params.previousVehicleValues.warrantyRevenueFromUsed +
                params.previousVehicleValues.adminLoadFromUsed +
                params.previousPartValues.onlineRevenue +
                params.previousPartValues.partRevenue +
                params.previousPartValues.saleRevenue +
                params.previousPartValues.serviceRevenue +
                params.previousPartValues.vehicleRevenue +
                params.previousServiceValues.internalRevenue +
                params.previousServiceValues.retailRevenue +
                params.previousServiceValues.insuranceRevenue +
                params.previousServiceValues.warrantyRevenue
        ),
        140,
        50,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.previousVehicleValues.newCost +
                params.previousVehicleValues.usedCost +
                params.previousPartValues.onlineCost +
                params.previousPartValues.partCost +
                params.previousPartValues.saleCost +
                params.previousPartValues.serviceCost +
                params.previousPartValues.vehicleCost +
                params.previousServiceValues.insuranceCost +
                params.previousServiceValues.internalCost +
                params.previousServiceValues.retailCost +
                params.previousServiceValues.warrantyCost
        ),
        140,
        54,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.previousVehicleValues.newRevenue +
                params.previousVehicleValues.usedRevenue +
                params.previousVehicleValues.DOFFromNew +
                params.previousVehicleValues.warrantyRevenueFromNew +
                params.previousVehicleValues.adminLoadFromNew +
                params.previousVehicleValues.DOFFromUsed +
                params.previousVehicleValues.warrantyRevenueFromUsed +
                params.previousVehicleValues.adminLoadFromUsed +
                params.previousPartValues.onlineRevenue +
                params.previousPartValues.partRevenue +
                params.previousPartValues.saleRevenue +
                params.previousPartValues.serviceRevenue +
                params.previousPartValues.vehicleRevenue +
                params.previousServiceValues.internalRevenue +
                params.previousServiceValues.retailRevenue +
                params.previousServiceValues.insuranceRevenue +
                params.previousServiceValues.warrantyRevenue -
                (params.previousVehicleValues.newCost +
                    params.previousVehicleValues.usedCost +
                    params.previousPartValues.onlineCost +
                    params.previousPartValues.partCost +
                    params.previousPartValues.saleCost +
                    params.previousPartValues.serviceCost +
                    params.previousPartValues.vehicleCost +
                    params.previousServiceValues.insuranceCost +
                    params.previousServiceValues.internalCost +
                    params.previousServiceValues.retailCost +
                    params.previousServiceValues.warrantyCost)
        ),
        140,
        58,
        { align: 'right' }
    );

    // PREVIOUS SALES DATA PERCENTAGES
    if (params.previousVehicleValues.newRevenue !== 0) {
        doc.text(
            ToFixed(
                ((params.previousVehicleValues.newRevenue +
                    params.previousVehicleValues.DOFFromNew +
                    params.previousVehicleValues.warrantyRevenueFromNew +
                    params.previousVehicleValues.adminLoadFromNew) /
                    (params.previousVehicleValues.newRevenue +
                        params.previousVehicleValues.usedRevenue +
                        params.previousVehicleValues.DOFFromNew +
                        params.previousVehicleValues.warrantyRevenueFromNew +
                        params.previousVehicleValues.adminLoadFromNew +
                        params.previousVehicleValues.DOFFromUsed +
                        params.previousVehicleValues.warrantyRevenueFromUsed +
                        params.previousVehicleValues.adminLoadFromUsed +
                        params.previousPartValues.onlineRevenue +
                        params.previousPartValues.partRevenue +
                        params.previousPartValues.saleRevenue +
                        params.previousPartValues.serviceRevenue +
                        params.previousPartValues.vehicleRevenue +
                        params.previousServiceValues.internalRevenue +
                        params.previousServiceValues.retailRevenue +
                        params.previousServiceValues.insuranceRevenue +
                        params.previousServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            153,
            34,
            { align: 'right' }
        );
    }
    if (params.previousVehicleValues.usedRevenue !== 0) {
        doc.text(
            ToFixed(
                ((params.previousVehicleValues.usedRevenue +
                    params.previousVehicleValues.DOFFromUsed +
                    params.previousVehicleValues.warrantyRevenueFromUsed +
                    params.previousVehicleValues.adminLoadFromUsed) /
                    (params.previousVehicleValues.newRevenue +
                        params.previousVehicleValues.usedRevenue +
                        params.previousVehicleValues.DOFFromNew +
                        params.previousVehicleValues.warrantyRevenueFromNew +
                        params.previousVehicleValues.adminLoadFromNew +
                        params.previousVehicleValues.DOFFromUsed +
                        params.previousVehicleValues.warrantyRevenueFromUsed +
                        params.previousVehicleValues.adminLoadFromUsed +
                        params.previousPartValues.onlineRevenue +
                        params.previousPartValues.partRevenue +
                        params.previousPartValues.saleRevenue +
                        params.previousPartValues.serviceRevenue +
                        params.previousPartValues.vehicleRevenue +
                        params.previousServiceValues.internalRevenue +
                        params.previousServiceValues.retailRevenue +
                        params.previousServiceValues.insuranceRevenue +
                        params.previousServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            153,
            38,
            { align: 'right' }
        );
    }
    if (
        params.previousPartValues.onlineRevenue +
            params.previousPartValues.partRevenue +
            params.previousPartValues.saleRevenue +
            params.previousPartValues.serviceRevenue +
            params.previousPartValues.vehicleRevenue !==
        0
    ) {
        doc.text(
            ToFixed(
                ((params.previousPartValues.onlineRevenue +
                    params.previousPartValues.partRevenue +
                    params.previousPartValues.saleRevenue +
                    params.previousPartValues.serviceRevenue +
                    params.previousPartValues.vehicleRevenue) /
                    (params.previousVehicleValues.newRevenue +
                        params.previousVehicleValues.usedRevenue +
                        params.previousVehicleValues.DOFFromNew +
                        params.previousVehicleValues.warrantyRevenueFromNew +
                        params.previousVehicleValues.adminLoadFromNew +
                        params.previousVehicleValues.DOFFromUsed +
                        params.previousVehicleValues.warrantyRevenueFromUsed +
                        params.previousVehicleValues.adminLoadFromUsed +
                        params.previousPartValues.onlineRevenue +
                        params.previousPartValues.partRevenue +
                        params.previousPartValues.saleRevenue +
                        params.previousPartValues.serviceRevenue +
                        params.previousPartValues.vehicleRevenue +
                        params.previousServiceValues.internalRevenue +
                        params.previousServiceValues.retailRevenue +
                        params.previousServiceValues.insuranceRevenue +
                        params.previousServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            153,
            42,
            { align: 'right' }
        );
    }
    if (
        params.previousServiceValues.internalRevenue +
            params.previousServiceValues.retailRevenue +
            params.previousServiceValues.insuranceRevenue +
            params.previousServiceValues.warrantyRevenue !==
        0
    ) {
        doc.text(
            ToFixed(
                ((params.previousServiceValues.internalRevenue +
                    params.previousServiceValues.retailRevenue +
                    params.previousServiceValues.insuranceRevenue +
                    params.previousServiceValues.warrantyRevenue) /
                    (params.previousVehicleValues.newRevenue +
                        params.previousVehicleValues.usedRevenue +
                        params.previousVehicleValues.DOFFromNew +
                        params.previousVehicleValues.warrantyRevenueFromNew +
                        params.previousVehicleValues.adminLoadFromNew +
                        params.previousVehicleValues.DOFFromUsed +
                        params.previousVehicleValues.warrantyRevenueFromUsed +
                        params.previousVehicleValues.adminLoadFromUsed +
                        params.previousPartValues.onlineRevenue +
                        params.previousPartValues.partRevenue +
                        params.previousPartValues.saleRevenue +
                        params.previousPartValues.serviceRevenue +
                        params.previousPartValues.vehicleRevenue +
                        params.previousServiceValues.internalRevenue +
                        params.previousServiceValues.retailRevenue +
                        params.previousServiceValues.insuranceRevenue +
                        params.previousServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            153,
            46,
            { align: 'right' }
        );
    }
    doc.setFont('helvetica', 'bold').text('100.00%', 153, 50, {
        align: 'right'
    });
    if (
        params.previousVehicleValues.newCost +
            params.previousVehicleValues.usedCost +
            params.previousPartValues.onlineCost +
            params.previousPartValues.partCost +
            params.previousPartValues.saleCost +
            params.previousPartValues.serviceCost +
            params.previousPartValues.vehicleCost +
            params.previousServiceValues.insuranceCost +
            params.previousServiceValues.internalCost +
            params.previousServiceValues.retailCost +
            params.previousServiceValues.warrantyCost !==
        0
    ) {
        doc.text(
            ToFixed(
                ((params.previousVehicleValues.newCost +
                    params.previousVehicleValues.usedCost +
                    params.previousPartValues.onlineCost +
                    params.previousPartValues.partCost +
                    params.previousPartValues.saleCost +
                    params.previousPartValues.serviceCost +
                    params.previousPartValues.vehicleCost +
                    params.previousServiceValues.insuranceCost +
                    params.previousServiceValues.internalCost +
                    params.previousServiceValues.retailCost +
                    params.previousServiceValues.warrantyCost) /
                    (params.previousVehicleValues.newRevenue +
                        params.previousVehicleValues.usedRevenue +
                        params.previousVehicleValues.DOFFromNew +
                        params.previousVehicleValues.warrantyRevenueFromNew +
                        params.previousVehicleValues.adminLoadFromNew +
                        params.previousVehicleValues.DOFFromUsed +
                        params.previousVehicleValues.warrantyRevenueFromUsed +
                        params.previousVehicleValues.adminLoadFromUsed +
                        params.previousPartValues.onlineRevenue +
                        params.previousPartValues.partRevenue +
                        params.previousPartValues.saleRevenue +
                        params.previousPartValues.serviceRevenue +
                        params.previousPartValues.vehicleRevenue +
                        params.previousServiceValues.internalRevenue +
                        params.previousServiceValues.retailRevenue +
                        params.previousServiceValues.insuranceRevenue +
                        params.previousServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            153,
            54,
            { align: 'right' }
        );
    }
    if (
        params.previousVehicleValues.newRevenue +
            params.previousVehicleValues.usedRevenue +
            params.previousVehicleValues.DOFFromNew +
            params.previousVehicleValues.warrantyRevenueFromNew +
            params.previousVehicleValues.adminLoadFromNew +
            params.previousVehicleValues.DOFFromUsed +
            params.previousVehicleValues.warrantyRevenueFromUsed +
            params.previousVehicleValues.adminLoadFromUsed +
            params.previousPartValues.onlineRevenue +
            params.previousPartValues.partRevenue +
            params.previousPartValues.saleRevenue +
            params.previousPartValues.serviceRevenue +
            params.previousPartValues.vehicleRevenue +
            params.previousServiceValues.internalRevenue +
            params.previousServiceValues.retailRevenue +
            params.previousServiceValues.insuranceRevenue +
            params.previousServiceValues.warrantyRevenue -
            (params.previousVehicleValues.newCost +
                params.previousVehicleValues.usedCost +
                params.previousPartValues.onlineCost +
                params.previousPartValues.partCost +
                params.previousPartValues.saleCost +
                params.previousPartValues.serviceCost +
                params.previousPartValues.vehicleCost +
                params.previousServiceValues.insuranceCost +
                params.previousServiceValues.internalCost +
                params.previousServiceValues.retailCost +
                params.previousServiceValues.warrantyCost) !==
        0
    ) {
        doc.text(
            ToFixed(
                ((params.previousVehicleValues.newRevenue +
                    params.previousVehicleValues.usedRevenue +
                    params.previousVehicleValues.DOFFromNew +
                    params.previousVehicleValues.warrantyRevenueFromNew +
                    params.previousVehicleValues.adminLoadFromNew +
                    params.previousVehicleValues.DOFFromUsed +
                    params.previousVehicleValues.warrantyRevenueFromUsed +
                    params.previousVehicleValues.adminLoadFromUsed +
                    params.previousPartValues.onlineRevenue +
                    params.previousPartValues.partRevenue +
                    params.previousPartValues.saleRevenue +
                    params.previousPartValues.serviceRevenue +
                    params.previousPartValues.vehicleRevenue +
                    params.previousServiceValues.internalRevenue +
                    params.previousServiceValues.retailRevenue +
                    params.previousServiceValues.insuranceRevenue +
                    params.previousServiceValues.warrantyRevenue -
                    (params.previousVehicleValues.newCost +
                        params.previousVehicleValues.usedCost +
                        params.previousPartValues.onlineCost +
                        params.previousPartValues.partCost +
                        params.previousPartValues.saleCost +
                        params.previousPartValues.serviceCost +
                        params.previousPartValues.vehicleCost +
                        params.previousServiceValues.insuranceCost +
                        params.previousServiceValues.internalCost +
                        params.previousServiceValues.retailCost +
                        params.previousServiceValues.warrantyCost)) /
                    (params.previousVehicleValues.newRevenue +
                        params.previousVehicleValues.usedRevenue +
                        params.previousVehicleValues.DOFFromNew +
                        params.previousVehicleValues.warrantyRevenueFromNew +
                        params.previousVehicleValues.adminLoadFromNew +
                        params.previousVehicleValues.DOFFromUsed +
                        params.previousVehicleValues.warrantyRevenueFromUsed +
                        params.previousVehicleValues.adminLoadFromUsed +
                        params.previousPartValues.onlineRevenue +
                        params.previousPartValues.partRevenue +
                        params.previousPartValues.saleRevenue +
                        params.previousPartValues.serviceRevenue +
                        params.previousPartValues.vehicleRevenue +
                        params.previousServiceValues.internalRevenue +
                        params.previousServiceValues.retailRevenue +
                        params.previousServiceValues.insuranceRevenue +
                        params.previousServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            153,
            58,
            { align: 'right' }
        );
    }

    // PREVIOUS YEAR YTD DATA
    doc.setFont('helvetica', 'normal').text(
        CurrencyFormatter(
            params.lastYearYtdMonthVehicleValues.newRevenue +
                params.lastYearYtdMonthVehicleValues.DOFFromNew +
                params.lastYearYtdMonthVehicleValues.warrantyRevenueFromNew +
                params.lastYearYtdMonthVehicleValues.adminLoadFromNew
        ),
        173,
        34,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.lastYearYtdMonthVehicleValues.usedRevenue +
                params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                params.lastYearYtdMonthVehicleValues.warrantyRevenueFromUsed +
                params.lastYearYtdMonthVehicleValues.adminLoadFromUsed
        ),
        173,
        38,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.lastYearYtdMonthPartValues.onlineRevenue +
                params.lastYearYtdMonthPartValues.partRevenue +
                params.lastYearYtdMonthPartValues.saleRevenue +
                params.lastYearYtdMonthPartValues.serviceRevenue +
                params.lastYearYtdMonthPartValues.vehicleRevenue
        ),
        173,
        42,
        {
            align: 'right'
        }
    );
    doc.text(
        CurrencyFormatter(
            params.lastYearYtdMonthServiceValues.internalRevenue +
                params.lastYearYtdMonthServiceValues.retailRevenue +
                params.lastYearYtdMonthServiceValues.insuranceRevenue +
                params.lastYearYtdMonthServiceValues.warrantyRevenue
        ),
        173,
        46,
        { align: 'right' }
    );
    doc.setFont('helvetica', 'bold').text(
        CurrencyFormatter(
            params.lastYearYtdMonthVehicleValues.newRevenue +
                params.lastYearYtdMonthVehicleValues.usedRevenue +
                params.lastYearYtdMonthVehicleValues.DOFFromNew +
                params.lastYearYtdMonthVehicleValues.warrantyRevenueFromNew +
                params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
                params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                params.lastYearYtdMonthVehicleValues.warrantyRevenueFromUsed +
                params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
                params.lastYearYtdMonthPartValues.onlineRevenue +
                params.lastYearYtdMonthPartValues.partRevenue +
                params.lastYearYtdMonthPartValues.saleRevenue +
                params.lastYearYtdMonthPartValues.serviceRevenue +
                params.lastYearYtdMonthPartValues.vehicleRevenue +
                params.lastYearYtdMonthServiceValues.internalRevenue +
                params.lastYearYtdMonthServiceValues.retailRevenue +
                params.lastYearYtdMonthServiceValues.insuranceRevenue +
                params.lastYearYtdMonthServiceValues.warrantyRevenue
        ),
        173,
        50,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.lastYearYtdMonthVehicleValues.newCost +
                params.lastYearYtdMonthVehicleValues.usedCost +
                params.lastYearYtdMonthPartValues.onlineCost +
                params.lastYearYtdMonthPartValues.partCost +
                params.lastYearYtdMonthPartValues.saleCost +
                params.lastYearYtdMonthPartValues.serviceCost +
                params.lastYearYtdMonthPartValues.vehicleCost +
                params.lastYearYtdMonthServiceValues.insuranceCost +
                params.lastYearYtdMonthServiceValues.internalCost +
                params.lastYearYtdMonthServiceValues.retailCost +
                params.lastYearYtdMonthServiceValues.warrantyCost
        ),
        173,
        54,
        { align: 'right' }
    );
    doc.text(
        CurrencyFormatter(
            params.lastYearYtdMonthVehicleValues.newRevenue +
                params.lastYearYtdMonthVehicleValues.usedRevenue +
                params.lastYearYtdMonthVehicleValues.DOFFromNew +
                params.lastYearYtdMonthVehicleValues.warrantyRevenueFromNew +
                params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
                params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                params.lastYearYtdMonthVehicleValues.warrantyRevenueFromUsed +
                params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
                params.lastYearYtdMonthPartValues.onlineRevenue +
                params.lastYearYtdMonthPartValues.partRevenue +
                params.lastYearYtdMonthPartValues.saleRevenue +
                params.lastYearYtdMonthPartValues.serviceRevenue +
                params.lastYearYtdMonthPartValues.vehicleRevenue +
                params.lastYearYtdMonthServiceValues.internalRevenue +
                params.lastYearYtdMonthServiceValues.retailRevenue +
                params.lastYearYtdMonthServiceValues.insuranceRevenue +
                params.lastYearYtdMonthServiceValues.warrantyRevenue -
                (params.lastYearYtdMonthVehicleValues.newCost +
                    params.lastYearYtdMonthVehicleValues.usedCost +
                    params.lastYearYtdMonthPartValues.onlineCost +
                    params.lastYearYtdMonthPartValues.partCost +
                    params.lastYearYtdMonthPartValues.saleCost +
                    params.lastYearYtdMonthPartValues.serviceCost +
                    params.lastYearYtdMonthPartValues.vehicleCost +
                    params.lastYearYtdMonthServiceValues.insuranceCost +
                    params.lastYearYtdMonthServiceValues.internalCost +
                    params.lastYearYtdMonthServiceValues.retailCost +
                    params.lastYearYtdMonthServiceValues.warrantyCost)
        ),
        173,
        58,
        { align: 'right' }
    );

    // PREVIOUS YEAR YTD DATA PERCENTAGES
    if (params.lastYearYtdMonthVehicleValues.newRevenue !== 0) {
        doc.text(
            ToFixed(
                ((params.lastYearYtdMonthVehicleValues.newRevenue +
                    params.lastYearYtdMonthVehicleValues.DOFFromNew +
                    params.lastYearYtdMonthVehicleValues
                        .warrantyRevenueFromNew +
                    params.lastYearYtdMonthVehicleValues.adminLoadFromNew) /
                    (params.lastYearYtdMonthVehicleValues.newRevenue +
                        params.lastYearYtdMonthVehicleValues.usedRevenue +
                        params.lastYearYtdMonthVehicleValues.DOFFromNew +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromNew +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
                        params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromUsed +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
                        params.lastYearYtdMonthPartValues.onlineRevenue +
                        params.lastYearYtdMonthPartValues.partRevenue +
                        params.lastYearYtdMonthPartValues.saleRevenue +
                        params.lastYearYtdMonthPartValues.serviceRevenue +
                        params.lastYearYtdMonthPartValues.vehicleRevenue +
                        params.lastYearYtdMonthServiceValues.internalRevenue +
                        params.lastYearYtdMonthServiceValues.retailRevenue +
                        params.lastYearYtdMonthServiceValues.insuranceRevenue +
                        params.lastYearYtdMonthServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            186,
            34,
            { align: 'right' }
        );
    }
    if (params.lastYearYtdMonthVehicleValues.usedRevenue !== 0) {
        doc.text(
            ToFixed(
                ((params.lastYearYtdMonthVehicleValues.usedRevenue +
                    params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                    params.lastYearYtdMonthVehicleValues
                        .warrantyRevenueFromUsed +
                    params.lastYearYtdMonthVehicleValues.adminLoadFromUsed) /
                    (params.lastYearYtdMonthVehicleValues.newRevenue +
                        params.lastYearYtdMonthVehicleValues.usedRevenue +
                        params.lastYearYtdMonthVehicleValues.DOFFromNew +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromNew +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
                        params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromUsed +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
                        params.lastYearYtdMonthPartValues.onlineRevenue +
                        params.lastYearYtdMonthPartValues.partRevenue +
                        params.lastYearYtdMonthPartValues.saleRevenue +
                        params.lastYearYtdMonthPartValues.serviceRevenue +
                        params.lastYearYtdMonthPartValues.vehicleRevenue +
                        params.lastYearYtdMonthServiceValues.internalRevenue +
                        params.lastYearYtdMonthServiceValues.retailRevenue +
                        params.lastYearYtdMonthServiceValues.insuranceRevenue +
                        params.lastYearYtdMonthServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            186,
            38,
            { align: 'right' }
        );
    }
    if (
        params.lastYearYtdMonthPartValues.onlineRevenue +
            params.lastYearYtdMonthPartValues.partRevenue +
            params.lastYearYtdMonthPartValues.saleRevenue +
            params.lastYearYtdMonthPartValues.serviceRevenue +
            params.lastYearYtdMonthPartValues.vehicleRevenue !==
        0
    ) {
        doc.text(
            ToFixed(
                ((params.lastYearYtdMonthPartValues.onlineRevenue +
                    params.lastYearYtdMonthPartValues.partRevenue +
                    params.lastYearYtdMonthPartValues.saleRevenue +
                    params.lastYearYtdMonthPartValues.serviceRevenue +
                    params.lastYearYtdMonthPartValues.vehicleRevenue) /
                    (params.lastYearYtdMonthVehicleValues.newRevenue +
                        params.lastYearYtdMonthVehicleValues.usedRevenue +
                        params.lastYearYtdMonthVehicleValues.DOFFromNew +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromNew +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
                        params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromUsed +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
                        params.lastYearYtdMonthPartValues.onlineRevenue +
                        params.lastYearYtdMonthPartValues.partRevenue +
                        params.lastYearYtdMonthPartValues.saleRevenue +
                        params.lastYearYtdMonthPartValues.serviceRevenue +
                        params.lastYearYtdMonthPartValues.vehicleRevenue +
                        params.lastYearYtdMonthServiceValues.internalRevenue +
                        params.lastYearYtdMonthServiceValues.retailRevenue +
                        params.lastYearYtdMonthServiceValues.insuranceRevenue +
                        params.lastYearYtdMonthServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            186,
            42,
            { align: 'right' }
        );
    }
    if (
        params.lastYearYtdMonthServiceValues.internalRevenue +
            params.lastYearYtdMonthServiceValues.retailRevenue +
            params.lastYearYtdMonthServiceValues.insuranceRevenue +
            params.lastYearYtdMonthServiceValues.warrantyRevenue !==
        0
    ) {
        doc.text(
            ToFixed(
                ((params.lastYearYtdMonthServiceValues.internalRevenue +
                    params.lastYearYtdMonthServiceValues.retailRevenue +
                    params.lastYearYtdMonthServiceValues.insuranceRevenue +
                    params.lastYearYtdMonthServiceValues.warrantyRevenue) /
                    (params.lastYearYtdMonthVehicleValues.newRevenue +
                        params.lastYearYtdMonthVehicleValues.usedRevenue +
                        params.lastYearYtdMonthVehicleValues.DOFFromNew +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromNew +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
                        params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromUsed +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
                        params.lastYearYtdMonthPartValues.onlineRevenue +
                        params.lastYearYtdMonthPartValues.partRevenue +
                        params.lastYearYtdMonthPartValues.saleRevenue +
                        params.lastYearYtdMonthPartValues.serviceRevenue +
                        params.lastYearYtdMonthPartValues.vehicleRevenue +
                        params.lastYearYtdMonthServiceValues.internalRevenue +
                        params.lastYearYtdMonthServiceValues.retailRevenue +
                        params.lastYearYtdMonthServiceValues.insuranceRevenue +
                        params.lastYearYtdMonthServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            186,
            46,
            { align: 'right' }
        );
    }
    doc.setFont('helvetica', 'bold').text('100.00%', 186, 50, {
        align: 'right'
    });
    if (
        params.lastYearYtdMonthVehicleValues.newCost +
            params.lastYearYtdMonthVehicleValues.usedCost +
            params.lastYearYtdMonthPartValues.onlineCost +
            params.lastYearYtdMonthPartValues.partCost +
            params.lastYearYtdMonthPartValues.saleCost +
            params.lastYearYtdMonthPartValues.serviceCost +
            params.lastYearYtdMonthPartValues.vehicleCost +
            params.lastYearYtdMonthServiceValues.insuranceCost +
            params.lastYearYtdMonthServiceValues.internalCost +
            params.lastYearYtdMonthServiceValues.retailCost +
            params.lastYearYtdMonthServiceValues.warrantyCost !==
        0
    ) {
        doc.text(
            ToFixed(
                ((params.lastYearYtdMonthVehicleValues.newCost +
                    params.lastYearYtdMonthVehicleValues.usedCost +
                    params.lastYearYtdMonthPartValues.onlineCost +
                    params.lastYearYtdMonthPartValues.partCost +
                    params.lastYearYtdMonthPartValues.saleCost +
                    params.lastYearYtdMonthPartValues.serviceCost +
                    params.lastYearYtdMonthPartValues.vehicleCost +
                    params.lastYearYtdMonthServiceValues.insuranceCost +
                    params.lastYearYtdMonthServiceValues.internalCost +
                    params.lastYearYtdMonthServiceValues.retailCost +
                    params.lastYearYtdMonthServiceValues.warrantyCost) /
                    (params.lastYearYtdMonthVehicleValues.newRevenue +
                        params.lastYearYtdMonthVehicleValues.usedRevenue +
                        params.lastYearYtdMonthVehicleValues.DOFFromNew +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromNew +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
                        params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromUsed +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
                        params.lastYearYtdMonthPartValues.onlineRevenue +
                        params.lastYearYtdMonthPartValues.partRevenue +
                        params.lastYearYtdMonthPartValues.saleRevenue +
                        params.lastYearYtdMonthPartValues.serviceRevenue +
                        params.lastYearYtdMonthPartValues.vehicleRevenue +
                        params.lastYearYtdMonthServiceValues.internalRevenue +
                        params.lastYearYtdMonthServiceValues.retailRevenue +
                        params.lastYearYtdMonthServiceValues.insuranceRevenue +
                        params.lastYearYtdMonthServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            186,
            54,
            { align: 'right' }
        );
    }
    if (
        params.lastYearYtdMonthVehicleValues.newRevenue +
            params.lastYearYtdMonthVehicleValues.usedRevenue +
            params.lastYearYtdMonthVehicleValues.DOFFromNew +
            params.lastYearYtdMonthVehicleValues.warrantyRevenueFromNew +
            params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
            params.lastYearYtdMonthVehicleValues.DOFFromUsed +
            params.lastYearYtdMonthVehicleValues.warrantyRevenueFromUsed +
            params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
            params.lastYearYtdMonthPartValues.onlineRevenue +
            params.lastYearYtdMonthPartValues.partRevenue +
            params.lastYearYtdMonthPartValues.saleRevenue +
            params.lastYearYtdMonthPartValues.serviceRevenue +
            params.lastYearYtdMonthPartValues.vehicleRevenue +
            params.lastYearYtdMonthServiceValues.internalRevenue +
            params.lastYearYtdMonthServiceValues.retailRevenue +
            params.lastYearYtdMonthServiceValues.insuranceRevenue +
            params.lastYearYtdMonthServiceValues.warrantyRevenue -
            (params.lastYearYtdMonthVehicleValues.newCost +
                params.lastYearYtdMonthVehicleValues.usedCost +
                params.lastYearYtdMonthPartValues.onlineCost +
                params.lastYearYtdMonthPartValues.partCost +
                params.lastYearYtdMonthPartValues.saleCost +
                params.lastYearYtdMonthPartValues.serviceCost +
                params.lastYearYtdMonthPartValues.vehicleCost +
                params.lastYearYtdMonthServiceValues.insuranceCost +
                params.lastYearYtdMonthServiceValues.internalCost +
                params.lastYearYtdMonthServiceValues.retailCost +
                params.lastYearYtdMonthServiceValues.warrantyCost) !==
        0
    ) {
        doc.text(
            ToFixed(
                ((params.lastYearYtdMonthVehicleValues.newRevenue +
                    params.lastYearYtdMonthVehicleValues.usedRevenue +
                    params.lastYearYtdMonthVehicleValues.DOFFromNew +
                    params.lastYearYtdMonthVehicleValues
                        .warrantyRevenueFromNew +
                    params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
                    params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                    params.lastYearYtdMonthVehicleValues
                        .warrantyRevenueFromUsed +
                    params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
                    params.lastYearYtdMonthPartValues.onlineRevenue +
                    params.lastYearYtdMonthPartValues.partRevenue +
                    params.lastYearYtdMonthPartValues.saleRevenue +
                    params.lastYearYtdMonthPartValues.serviceRevenue +
                    params.lastYearYtdMonthPartValues.vehicleRevenue +
                    params.lastYearYtdMonthServiceValues.internalRevenue +
                    params.lastYearYtdMonthServiceValues.retailRevenue +
                    params.lastYearYtdMonthServiceValues.insuranceRevenue +
                    params.lastYearYtdMonthServiceValues.warrantyRevenue -
                    (params.lastYearYtdMonthVehicleValues.newCost +
                        params.lastYearYtdMonthVehicleValues.usedCost +
                        params.lastYearYtdMonthPartValues.onlineCost +
                        params.lastYearYtdMonthPartValues.partCost +
                        params.lastYearYtdMonthPartValues.saleCost +
                        params.lastYearYtdMonthPartValues.serviceCost +
                        params.lastYearYtdMonthPartValues.vehicleCost +
                        params.lastYearYtdMonthServiceValues.insuranceCost +
                        params.lastYearYtdMonthServiceValues.internalCost +
                        params.lastYearYtdMonthServiceValues.retailCost +
                        params.lastYearYtdMonthServiceValues.warrantyCost)) /
                    (params.lastYearYtdMonthVehicleValues.newRevenue +
                        params.lastYearYtdMonthVehicleValues.usedRevenue +
                        params.lastYearYtdMonthVehicleValues.DOFFromNew +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromNew +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromNew +
                        params.lastYearYtdMonthVehicleValues.DOFFromUsed +
                        params.lastYearYtdMonthVehicleValues
                            .warrantyRevenueFromUsed +
                        params.lastYearYtdMonthVehicleValues.adminLoadFromUsed +
                        params.lastYearYtdMonthPartValues.onlineRevenue +
                        params.lastYearYtdMonthPartValues.partRevenue +
                        params.lastYearYtdMonthPartValues.saleRevenue +
                        params.lastYearYtdMonthPartValues.serviceRevenue +
                        params.lastYearYtdMonthPartValues.vehicleRevenue +
                        params.lastYearYtdMonthServiceValues.internalRevenue +
                        params.lastYearYtdMonthServiceValues.retailRevenue +
                        params.lastYearYtdMonthServiceValues.insuranceRevenue +
                        params.lastYearYtdMonthServiceValues.warrantyRevenue)) *
                    100
            ).toString() + '%',
            186,
            58,
            { align: 'right' }
        );
    }

    // YTD VS LAST YEAR
    doc.setFont('helvetica', 'normal');
    doc.text(
        Number.isFinite(
            ToFixed(
                (params.ytdMonthVehicleValues.newRevenue /
                    params.lastYearYtdMonthVehicleValues.newRevenue -
                    1) *
                    100
            )
        )
            ? ToFixed(
                  (params.ytdMonthVehicleValues.newRevenue /
                      params.lastYearYtdMonthVehicleValues.newRevenue -
                      1) *
                      100
              ).toString() + '%'
            : '',
        200,
        34,
        { align: 'right' }
    );
    doc.text(
        Number.isFinite(
            ToFixed(
                (params.ytdMonthVehicleValues.usedRevenue /
                    params.lastYearYtdMonthVehicleValues.usedRevenue -
                    1) *
                    100
            )
        )
            ? ToFixed(
                  (params.ytdMonthVehicleValues.usedRevenue /
                      params.lastYearYtdMonthVehicleValues.usedRevenue -
                      1) *
                      100
              ).toString() + '%'
            : '',
        200,
        38,
        { align: 'right' }
    );
    doc.text(
        Number.isFinite(
            ToFixed(
                ((params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue) /
                    (params.lastYearYtdMonthPartValues.onlineRevenue +
                        params.lastYearYtdMonthPartValues.partRevenue +
                        params.lastYearYtdMonthPartValues.saleRevenue +
                        params.lastYearYtdMonthPartValues.serviceRevenue +
                        params.lastYearYtdMonthPartValues.vehicleRevenue) -
                    1) *
                    100
            )
        )
            ? ToFixed(
                  ((params.ytdMonthPartValues.onlineRevenue +
                      params.ytdMonthPartValues.partRevenue +
                      params.ytdMonthPartValues.saleRevenue +
                      params.ytdMonthPartValues.serviceRevenue +
                      params.ytdMonthPartValues.vehicleRevenue) /
                      (params.lastYearYtdMonthPartValues.onlineRevenue +
                          params.lastYearYtdMonthPartValues.partRevenue +
                          params.lastYearYtdMonthPartValues.saleRevenue +
                          params.lastYearYtdMonthPartValues.serviceRevenue +
                          params.lastYearYtdMonthPartValues.vehicleRevenue) -
                      1) *
                      100
              ).toString() + '%'
            : '',
        200,
        42,
        { align: 'right' }
    );
    doc.text(
        Number.isFinite(
            ToFixed(
                ((params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue) /
                    (params.lastYearYtdMonthServiceValues.internalRevenue +
                        params.lastYearYtdMonthServiceValues.retailRevenue +
                        params.lastYearYtdMonthServiceValues.insuranceRevenue +
                        params.lastYearYtdMonthServiceValues.warrantyRevenue) -
                    1) *
                    100
            )
        )
            ? ToFixed(
                  ((params.ytdMonthServiceValues.internalRevenue +
                      params.ytdMonthServiceValues.retailRevenue +
                      params.ytdMonthServiceValues.insuranceRevenue +
                      params.ytdMonthServiceValues.warrantyRevenue) /
                      (params.lastYearYtdMonthServiceValues.internalRevenue +
                          params.lastYearYtdMonthServiceValues.retailRevenue +
                          params.lastYearYtdMonthServiceValues
                              .insuranceRevenue +
                          params.lastYearYtdMonthServiceValues
                              .warrantyRevenue) -
                      1) *
                      100
              ).toString() + '%'
            : '',
        200,
        46,
        { align: 'right' }
    );
    doc.setFont('helvetica', 'bold').text(
        Number.isFinite(
            ToFixed(
                ((params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue) /
                    (params.lastYearYtdMonthVehicleValues.newRevenue +
                        params.lastYearYtdMonthVehicleValues.usedRevenue +
                        params.lastYearYtdMonthPartValues.onlineRevenue +
                        params.lastYearYtdMonthPartValues.partRevenue +
                        params.lastYearYtdMonthPartValues.saleRevenue +
                        params.lastYearYtdMonthPartValues.serviceRevenue +
                        params.lastYearYtdMonthPartValues.vehicleRevenue +
                        params.lastYearYtdMonthServiceValues.internalRevenue +
                        params.lastYearYtdMonthServiceValues.retailRevenue +
                        params.lastYearYtdMonthServiceValues.insuranceRevenue +
                        params.lastYearYtdMonthServiceValues.warrantyRevenue) -
                    1) *
                    100
            )
        )
            ? ToFixed(
                  ((params.ytdMonthVehicleValues.newRevenue +
                      params.ytdMonthVehicleValues.usedRevenue +
                      params.ytdMonthPartValues.onlineRevenue +
                      params.ytdMonthPartValues.partRevenue +
                      params.ytdMonthPartValues.saleRevenue +
                      params.ytdMonthPartValues.serviceRevenue +
                      params.ytdMonthPartValues.vehicleRevenue +
                      params.ytdMonthServiceValues.internalRevenue +
                      params.ytdMonthServiceValues.retailRevenue +
                      params.ytdMonthServiceValues.insuranceRevenue +
                      params.ytdMonthServiceValues.warrantyRevenue) /
                      (params.lastYearYtdMonthVehicleValues.newRevenue +
                          params.lastYearYtdMonthVehicleValues.usedRevenue +
                          params.lastYearYtdMonthPartValues.onlineRevenue +
                          params.lastYearYtdMonthPartValues.partRevenue +
                          params.lastYearYtdMonthPartValues.saleRevenue +
                          params.lastYearYtdMonthPartValues.serviceRevenue +
                          params.lastYearYtdMonthPartValues.vehicleRevenue +
                          params.lastYearYtdMonthServiceValues.internalRevenue +
                          params.lastYearYtdMonthServiceValues.retailRevenue +
                          params.lastYearYtdMonthServiceValues
                              .insuranceRevenue +
                          params.lastYearYtdMonthServiceValues
                              .warrantyRevenue) -
                      1) *
                      100
              ).toString() + '%'
            : '',
        200,
        50,
        { align: 'right' }
    );

    doc.text(
        Number.isFinite(
            ToFixed(
                ((params.ytdMonthVehicleValues.newCost +
                    params.ytdMonthVehicleValues.usedCost +
                    params.ytdMonthPartValues.onlineCost +
                    params.ytdMonthPartValues.partCost +
                    params.ytdMonthPartValues.saleCost +
                    params.ytdMonthPartValues.serviceCost +
                    params.ytdMonthPartValues.vehicleCost +
                    params.ytdMonthServiceValues.insuranceCost +
                    params.ytdMonthServiceValues.internalCost +
                    params.ytdMonthServiceValues.retailCost +
                    params.ytdMonthServiceValues.warrantyCost) /
                    (params.lastYearYtdMonthVehicleValues.newCost +
                        params.lastYearYtdMonthVehicleValues.usedCost +
                        params.lastYearYtdMonthPartValues.onlineCost +
                        params.lastYearYtdMonthPartValues.partCost +
                        params.lastYearYtdMonthPartValues.saleCost +
                        params.lastYearYtdMonthPartValues.serviceCost +
                        params.lastYearYtdMonthPartValues.vehicleCost +
                        params.lastYearYtdMonthServiceValues.insuranceCost +
                        params.lastYearYtdMonthServiceValues.internalCost +
                        params.lastYearYtdMonthServiceValues.retailCost +
                        params.lastYearYtdMonthServiceValues.warrantyCost) -
                    1) *
                    100
            )
        )
            ? ToFixed(
                  ((params.ytdMonthVehicleValues.newCost +
                      params.ytdMonthVehicleValues.usedCost +
                      params.ytdMonthPartValues.onlineCost +
                      params.ytdMonthPartValues.partCost +
                      params.ytdMonthPartValues.saleCost +
                      params.ytdMonthPartValues.serviceCost +
                      params.ytdMonthPartValues.vehicleCost +
                      params.ytdMonthServiceValues.insuranceCost +
                      params.ytdMonthServiceValues.internalCost +
                      params.ytdMonthServiceValues.retailCost +
                      params.ytdMonthServiceValues.warrantyCost) /
                      (params.lastYearYtdMonthVehicleValues.newCost +
                          params.lastYearYtdMonthVehicleValues.usedCost +
                          params.lastYearYtdMonthPartValues.onlineCost +
                          params.lastYearYtdMonthPartValues.partCost +
                          params.lastYearYtdMonthPartValues.saleCost +
                          params.lastYearYtdMonthPartValues.serviceCost +
                          params.lastYearYtdMonthPartValues.vehicleCost +
                          params.lastYearYtdMonthServiceValues.insuranceCost +
                          params.lastYearYtdMonthServiceValues.internalCost +
                          params.lastYearYtdMonthServiceValues.retailCost +
                          params.lastYearYtdMonthServiceValues.warrantyCost) -
                      1) *
                      100
              ).toString() + '%'
            : '',
        200,
        54,
        { align: 'right' }
    );

    doc.text(
        Number.isFinite(
            ToFixed(
                ((params.ytdMonthVehicleValues.newRevenue +
                    params.ytdMonthVehicleValues.usedRevenue +
                    params.ytdMonthPartValues.onlineRevenue +
                    params.ytdMonthPartValues.partRevenue +
                    params.ytdMonthPartValues.saleRevenue +
                    params.ytdMonthPartValues.serviceRevenue +
                    params.ytdMonthPartValues.vehicleRevenue +
                    params.ytdMonthServiceValues.internalRevenue +
                    params.ytdMonthServiceValues.retailRevenue +
                    params.ytdMonthServiceValues.insuranceRevenue +
                    params.ytdMonthServiceValues.warrantyRevenue -
                    (params.ytdMonthVehicleValues.newCost +
                        params.ytdMonthVehicleValues.usedCost +
                        params.ytdMonthPartValues.onlineCost +
                        params.ytdMonthPartValues.partCost +
                        params.ytdMonthPartValues.saleCost +
                        params.ytdMonthPartValues.serviceCost +
                        params.ytdMonthPartValues.vehicleCost +
                        params.ytdMonthServiceValues.insuranceCost +
                        params.ytdMonthServiceValues.internalCost +
                        params.ytdMonthServiceValues.retailCost +
                        params.ytdMonthServiceValues.warrantyCost)) /
                    (params.lastYearYtdMonthVehicleValues.newRevenue +
                        params.lastYearYtdMonthVehicleValues.usedRevenue +
                        params.lastYearYtdMonthPartValues.onlineRevenue +
                        params.lastYearYtdMonthPartValues.partRevenue +
                        params.lastYearYtdMonthPartValues.saleRevenue +
                        params.lastYearYtdMonthPartValues.serviceRevenue +
                        params.lastYearYtdMonthPartValues.vehicleRevenue +
                        params.lastYearYtdMonthServiceValues.internalRevenue +
                        params.lastYearYtdMonthServiceValues.retailRevenue +
                        params.lastYearYtdMonthServiceValues.insuranceRevenue +
                        params.lastYearYtdMonthServiceValues.warrantyRevenue -
                        (params.lastYearYtdMonthVehicleValues.newCost +
                            params.lastYearYtdMonthVehicleValues.usedCost +
                            params.lastYearYtdMonthPartValues.onlineCost +
                            params.lastYearYtdMonthPartValues.partCost +
                            params.lastYearYtdMonthPartValues.saleCost +
                            params.lastYearYtdMonthPartValues.serviceCost +
                            params.lastYearYtdMonthPartValues.vehicleCost +
                            params.lastYearYtdMonthServiceValues.insuranceCost +
                            params.lastYearYtdMonthServiceValues.internalCost +
                            params.lastYearYtdMonthServiceValues.retailCost +
                            params.lastYearYtdMonthServiceValues
                                .warrantyCost)) -
                    1) *
                    100
            )
        )
            ? ToFixed(
                  ((params.ytdMonthVehicleValues.newRevenue +
                      params.ytdMonthVehicleValues.usedRevenue +
                      params.ytdMonthPartValues.onlineRevenue +
                      params.ytdMonthPartValues.partRevenue +
                      params.ytdMonthPartValues.saleRevenue +
                      params.ytdMonthPartValues.serviceRevenue +
                      params.ytdMonthPartValues.vehicleRevenue +
                      params.ytdMonthServiceValues.internalRevenue +
                      params.ytdMonthServiceValues.retailRevenue +
                      params.ytdMonthServiceValues.insuranceRevenue +
                      params.ytdMonthServiceValues.warrantyRevenue -
                      (params.ytdMonthVehicleValues.newCost +
                          params.ytdMonthVehicleValues.usedCost +
                          params.ytdMonthPartValues.onlineCost +
                          params.ytdMonthPartValues.partCost +
                          params.ytdMonthPartValues.saleCost +
                          params.ytdMonthPartValues.serviceCost +
                          params.ytdMonthPartValues.vehicleCost +
                          params.ytdMonthServiceValues.insuranceCost +
                          params.ytdMonthServiceValues.internalCost +
                          params.ytdMonthServiceValues.retailCost +
                          params.ytdMonthServiceValues.warrantyCost)) /
                      (params.lastYearYtdMonthVehicleValues.newRevenue +
                          params.lastYearYtdMonthVehicleValues.usedRevenue +
                          params.lastYearYtdMonthPartValues.onlineRevenue +
                          params.lastYearYtdMonthPartValues.partRevenue +
                          params.lastYearYtdMonthPartValues.saleRevenue +
                          params.lastYearYtdMonthPartValues.serviceRevenue +
                          params.lastYearYtdMonthPartValues.vehicleRevenue +
                          params.lastYearYtdMonthServiceValues.internalRevenue +
                          params.lastYearYtdMonthServiceValues.retailRevenue +
                          params.lastYearYtdMonthServiceValues
                              .insuranceRevenue +
                          params.lastYearYtdMonthServiceValues.warrantyRevenue -
                          (params.lastYearYtdMonthVehicleValues.newCost +
                              params.lastYearYtdMonthVehicleValues.usedCost +
                              params.lastYearYtdMonthPartValues.onlineCost +
                              params.lastYearYtdMonthPartValues.partCost +
                              params.lastYearYtdMonthPartValues.saleCost +
                              params.lastYearYtdMonthPartValues.serviceCost +
                              params.lastYearYtdMonthPartValues.vehicleCost +
                              params.lastYearYtdMonthServiceValues
                                  .insuranceCost +
                              params.lastYearYtdMonthServiceValues
                                  .internalCost +
                              params.lastYearYtdMonthServiceValues.retailCost +
                              params.lastYearYtdMonthServiceValues
                                  .warrantyCost)) -
                      1) *
                      100
              ).toString() + '%'
            : '',
        200,
        58,
        { align: 'right' }
    );
};

export default PNLSales;
