import { Dispatch, SetStateAction } from "react"
import api from "../../../../api"
import { Ctp } from "../interfaces/VehicleSaleInterface"

const GetFutureQLDCtp = (setCtps: Dispatch<SetStateAction<Ctp[]>>) => {
    api.get('qldFutureCtp').then(res => {
        setCtps(res.data)
    })
}

export default GetFutureQLDCtp