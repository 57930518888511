import { Dispatch, SetStateAction } from 'react';
import { Table } from '../interfaces/StockOrderInterface';

const HandleSupplierRemove = (
    supplierId: number,
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    orderType: string
) => {
    let rows = [...tables];
    let newRows = [];

    rows.forEach((row) => {
        if (row.supplierId === supplierId && row.OrderType === orderType) {
        } else {
            newRows.push(row);
        }
    });

    setTables(newRows);
};

export default HandleSupplierRemove;
