import { PDFDocument } from 'pdf-lib';
import PageWrapper from '../../../../global/PageWrapper';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import DealerPersonalisedPlateForm from '../dealerForms/DealerPersonalisedPlateForm';
import renderDealerPlatePdf from '../../logic/renderDealerPlatePdf';

const DealerSignPersonalisedPlate = ({
    formData,
    setFormData,
    pdfDoc,
    pdfUrl,
    setPdfUrl,
    contractStatus,
    setPersonalisedPlateBytes
}) => {
    const onDealerPersonalisedPlateForm = async (formData, submitType) => {
        if (!pdfDoc) return;

        // Make a copy of original document, so everytime formData changes, we start from the original document
        const pdfDocCopy = await PDFDocument.load(await pdfDoc.save());

        const pdfBytes = await renderDealerPlatePdf(pdfDocCopy, formData);

        setPersonalisedPlateBytes(pdfBytes);

        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        if (submitType === 'preview') {
            setPdfUrl(URL.createObjectURL(pdfBlob));
        }
    };

    return (
        <PageWrapper>
            <Grid container spacing={2}>
                {!contractStatus && (
                    <Grid item xs={12} sm={6}>
                        <DealerPersonalisedPlateForm
                            formData={formData}
                            setFormData={setFormData}
                            onDealerPersonalisedPlateForm={
                                onDealerPersonalisedPlateForm
                            }
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={contractStatus ? 12 : 6}>
                    {pdfUrl ? (
                        <Box width="100%" height="200vh">
                            <iframe
                                src={pdfUrl + '#view=Fit'}
                                width="100%"
                                height="100%"
                                title={`Personalised Plate`}
                            ></iframe>
                        </Box>
                    ) : (
                        <Box width="100%" height="100%" textAlign={'center'}>
                            {contractStatus ? (
                                <Typography variant="h5">
                                    No File Found
                                </Typography>
                            ) : (
                                <>
                                    <CircularProgress />
                                    <Typography variant="h5">
                                        Loading form...
                                    </Typography>
                                </>
                            )}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </PageWrapper>
    );
};
export default DealerSignPersonalisedPlate;
