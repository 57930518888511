import dayjs from 'dayjs';
import api from '../../../../api';

export const ExportTechTimeClock = (id: number) => {
    api.get(`getTechTimeClocks/${id.toString()}`).then((res) => {
        let data = JSON.parse(JSON.stringify(res.data));
        for (let line of data) {
            if (line.clockOn.includes('+10')) {
                let currentHour =
                    parseInt(line.clockOn.substring(0, 2)) + 10 > 24
                        ? (
                              parseInt(line.clockOn.substring(0, 2)) - 14
                          ).toString()
                        : (
                              parseInt(line.clockOn.substring(0, 2)) + 10
                          ).toString();
                currentHour =
                    currentHour.length === 1 ? '0' + currentHour : currentHour;

                let newHour = line.clockOn.replace(
                    line.clockOn.substring(0, 2),
                    currentHour
                );
                line.clockOn = dayjs(newHour, 'hh:mm:ss.SSSZZ');
            } else {
                line.clockOn = dayjs(line.clockOn, 'hh:mm:ss.SSSZZ');
            }

            if (line.clockOff) {
                if (line.clockOff.includes('+10')) {
                    let currentHour =
                        parseInt(line.clockOff.substring(0, 2)) + 10 > 24
                            ? (
                                  parseInt(line.clockOff.substring(0, 2)) - 14
                              ).toString()
                            : (
                                  parseInt(line.clockOff.substring(0, 2)) + 10
                              ).toString();
                    currentHour =
                        currentHour.length === 1
                            ? '0' + currentHour
                            : currentHour;

                    let newHour = line.clockOff.replace(
                        line.clockOff.substring(0, 2),
                        currentHour
                    );
                    line.clockOff = dayjs(newHour, 'hh:mm:ss.SSSZZ');
                } else {
                    line.clockOff = dayjs(line.clockOff, 'hh:mm:ss.SSSZZ');
                }
            }
        }

        let cleanedUpData = [];
        for (let line of data) {
            cleanedUpData.push([
                line.date,
                line.clockOn.format('HH:mm'),
                line.clockOff ? line.clockOff.format('HH:mm') : '',
                line.clockOff
                    ? dayjs(line.clockOff)
                          .diff(dayjs(line.clockOn), 'minute')
                          .toString()
                    : '',
                line.User.firstName + ' ' + line.User.lastName
            ]);
        }

        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent =
            csvContent +
            'Date,Clock On,Clock Off, Minutes worked,Technician' +
            '\r\n';
        cleanedUpData.forEach(function (rowArray) {
            let row = rowArray.join(',');
            csvContent += row + '\r\n';
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `TechTimeClock_Service_${id.toString()}`);
        document.body.appendChild(link);
        link.click();
    });
};
