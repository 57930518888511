import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import api from '../../../../../../api';

// COMPONENTS
import Autocomplete from '../../../../global/Autocomplete';

// LOGIC
import GetMakes from '../../../../global/databaseLogic/GetMakes';
import Papa from 'papaparse';

// INTERFACES
import { Make } from '../../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

export const ImportModelDialogContent = ({
    showSnackbar,
    setImportModelsDialog
}: {
    showSnackbar: showSnackbar;
    setImportModelsDialog: Dispatch<SetStateAction<boolean>>;
}) => {
    const [makeList, setMakeList] = useState<Make[]>([]);
    const [selectedMake, setSelectedMake] = useState<Make>(null);
    const [importedDataFilename, setImportedDataFilename] =
        useState<string>('');
    const [importedData, setImportedData] = useState<any[]>([]);

    useEffect(() => {
        GetMakes(setMakeList);
    }, []);

    const parseCSV = (e) => {
        Papa.parse(e, {
            header: false,
            skipEmptyLines: 'greedy',
            complete: (results) => {
                var newData = [];
                let moreThanOneColumn = false;
                results.data.forEach((row, i) => {
                    if (row.length > 1) {
                        moreThanOneColumn = true;
                    }
                    newData.push(row[0]);
                });
                if (moreThanOneColumn) {
                    setImportedData([]);
                    setImportedDataFilename('');
                    showSnackbar(
                        'There is more than one column in the uploaded CSV.',
                        'If the CSV has the correct format, please contact your IT department.',
                        'error'
                    );
                } else {
                    setImportedData(newData);
                }
            }
        });
    };

    const importFile = (e) => {
        setImportedDataFilename(e.target.files[0].name);
        parseCSV(e.target.files[0]);
    };

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            size="small"
                            options={makeList}
                            useTwoOptionLabels={true}
                            primaryOptionLabel={'id'}
                            secondaryOptionLabel={'name'}
                            textfieldLabel="Make"
                            selectedValue={selectedMake}
                            handleSelectedValueChange={(event) => {
                                setSelectedMake(event);
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {importedData.length > 0 ? (
                            importedDataFilename + ' selected'
                        ) : (
                            <>
                                <input
                                    onChange={(e) => importFile(e)}
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="outlined" component="span">
                                        Upload CSV
                                    </Button>
                                </label>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        The only format currently supported is a CSV with only
                        one column contains the name of the models.
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setSelectedMake(null);
                        setImportedData([]);
                        setImportedDataFilename('');
                        setImportModelsDialog(false);
                    }}
                >
                    CANCEL
                </Button>
                <Button
                    variant="contained"
                    disabled={selectedMake == null || importedData.length < 1}
                    onClick={() => {
                        api.post('/importModelFromCSV', {
                            csv: importedData,
                            makeId: selectedMake.id
                        }).then((res) => {
                            if (res.status === 200) {
                                showSnackbar(
                                    'Model(s) successfully created',
                                    'The page will now refresh.'
                                );
                                setTimeout(
                                    () => window.location.reload(),
                                    1500
                                );
                            }
                        });
                    }}
                >
                    Import Models
                </Button>
            </DialogActions>
        </>
    );
};
