import {
    Typography,
    TextField,
    MenuItem,
    TableRow,
    TableCell,
    IconButton
} from '@mui/material';
import { Add } from '@mui/icons-material';
import DataTable from '../../../OraNextGen/global/tableComponents/DataTable';

const Step3 = (props) => {
    const columns = [
        { id: 1, label: 'Code' },
        { id: 2, label: 'Name' },
        { id: 3, label: 'Site' }
    ];

    const AddTill = () => {
        let currentTills = [...props.tills];
        let id =
            props.tills.length > 0
                ? props.tills[props.tills.length - 1].id + 1
                : 1;

        let newTill = {
            id: id,
            code: null,
            name: null,
            siteName: null
        };

        let newStepValidation = [...props.stepValidation];
        newStepValidation.push({
            code: {
                error: false,
                message: ''
            },
            name: {
                error: false,
                message: ''
            },
            siteName: {
                error: false,
                message: ''
            }
        });
        props.setStepValidation(newStepValidation);

        currentTills.push(newTill);
        props.setTills(currentTills);
    };

    const EditTill = (rowId, name, value) => {
        var index = props.tills.findIndex((x) => x.id === rowId);
        let rows = [...props.tills];
        let row = { ...rows[index] };

        if (name === 'code') {
            row[name] = value.substring(0, 3);
        } else {
            row[name] = value;
        }

        rows[index] = row;
        props.setTills(rows);
    };

    return (
        <>
            <Typography variant="button">
                <b>
                    Please enter at least one till for each of the sites you
                    entered in the previous step.
                </b>
            </Typography>
            <br />
            <br />

            <DataTable columns={columns}>
                {props.tills.map((till, index) => (
                    <TableRow>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                value={till.code}
                                error={props.stepValidation[index].code.error}
                                helperText={
                                    props.stepValidation[index].code.error
                                        ? props.stepValidation[index].code
                                              .message
                                        : ''
                                }
                                onChange={(e) =>
                                    EditTill(till.id, 'code', e.target.value)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                value={till.name}
                                error={props.stepValidation[index].name.error}
                                helperText={
                                    props.stepValidation[index].name.error
                                        ? props.stepValidation[index].name
                                              .message
                                        : ''
                                }
                                onChange={(e) =>
                                    EditTill(till.id, 'name', e.target.value)
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                variant="standard"
                                select
                                value={till.siteName}
                                error={
                                    props.stepValidation[index].siteName.error
                                }
                                helperText={
                                    props.stepValidation[index].siteName.error
                                        ? props.stepValidation[index].siteName
                                              .message
                                        : ''
                                }
                                onChange={(e) =>
                                    EditTill(
                                        till.id,
                                        'siteName',
                                        e.target.value
                                    )
                                }
                            >
                                {props.sites.map((site) => (
                                    <MenuItem value={site.name}>
                                        {site.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell align="center" colSpan={9}>
                        <IconButton onClick={() => AddTill()}>
                            <Add />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </DataTable>
        </>
    );
};

export default Step3;
