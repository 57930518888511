// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { StockInvoice } from '../../../global/interfaces/PartsInterface';

const AddPartToInvoice = (
    unitDetails,
    selectedSupplier,
    invoiceData: StockInvoice,
    setInvoiceData: Dispatch<SetStateAction<StockInvoice>>,
    reverseOrder: string[]
) => {
    let unitData = unitDetails.unitData;

    // Save the current order lines
    let currentOrderLines = [...invoiceData.orderLines];

    // Add all the backorders into the stock invoice linked orders
    let backorders = unitData.proformas ?? [];
    let linkedOrders = [...invoiceData.linkedOrders];
    backorders.forEach((order) => {
        linkedOrders.push(order);
    });

    let supplierId = invoiceData.Supplier.id;
    let siteId = invoiceData.SiteId;

    let rrp =
        unitData.SupplierIds.length > 0 &&
        unitData.SupplierIds.filter(
            (x) => x.SiteId === siteId && x.SupplierId === supplierId
        ).length > 0 &&
        unitData.SupplierIds.filter(
            (x) => x.SiteId === siteId && x.SupplierId === supplierId
        )[0].hasOwnProperty('RRP')
            ? unitData.SupplierIds.filter(
                  (x) => x.SiteId === siteId && x.SupplierId === supplierId
              )[0].RRP
            : unitData.priceRRP;
    let cpd =
        unitData.SupplierIds.length > 0 &&
        unitData.SupplierIds.filter(
            (x) => x.SiteId === siteId && x.SupplierId === supplierId
        ).length > 0 &&
        unitData.SupplierIds.filter(
            (x) => x.SiteId === siteId && x.SupplierId === supplierId
        )[0].hasOwnProperty('CPD')
            ? unitData.SupplierIds.filter(
                  (x) => x.SiteId === siteId && x.SupplierId === supplierId
              )[0].CPD
            : unitData.costPriceDaily;

    // Create a new invoice line
    let newLine = {
        id: currentOrderLines.length,
        UnitId: unitData.id,
        partNumber: unitData.partNumber,
        name: unitData.name,
        costOnFile: cpd,
        itemPrice: null,
        lineTotal: null,
        quantityOrdered: 0,
        quantityReceived: null,
        customerBackorders: backorders,
        quantityError: false,
        priceError: false,
        printLabel: true,
        isPack: unitData.canBePack ? unitData.canBePack : false,
        packQty: unitData.canBePack ? '0' : null,
        gstFree: unitData.gstFree,
        priceRRP: rrp,
        binLocation: unitData?.BinLocationIds[0]?.name,
        addedPart: true,
        removed: false
    };

    // Push and save it
    if (reverseOrder[1] === 'ASC') {
        currentOrderLines.push(newLine);
    } else {
        currentOrderLines.unshift(newLine);
    }
    // Update the local storage
    localStorage.setItem(
        'stockInvoiceData',
        JSON.stringify({
            ...invoiceData,
            orderLines: currentOrderLines,
            linkedOrders: linkedOrders
        })
    );
    setInvoiceData({
        ...invoiceData,
        orderLines: currentOrderLines,
        linkedOrders: linkedOrders
    });
};

export default AddPartToInvoice;
