// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import { Checkbox, Divider, TableRow, Typography } from '@mui/material';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';

interface DuplicateListProps {
    duplicateCustomers: { key: string; mergeId: number; rows: Customer[] }[];
    setDuplicateCustomers: Dispatch<
        SetStateAction<{ key: string; mergeId: number; rows: Customer[] }[]>
    >;
    submitLoading: boolean;
}

const DuplicateList = ({
    duplicateCustomers,
    setDuplicateCustomers,
    submitLoading
}: DuplicateListProps) => {
    // This state needs to be here DO NOT REMOVE
    // It is here because when the duplicateCustomers state is updated it does not
    // trigger this page to refresh the state and therefore the checkboxes do not
    // display as checked. This forces this page to refresh the state and show the
    // updated values.
    const [test, setTest] = useState<boolean>(false);

    const columns = [
        { id: 0, label: 'ID', width: 100 },
        { id: 1, label: 'Company / Full Name', width: 300 },
        { id: 2, label: 'Email', width: 200 },
        { id: 3, label: 'Phone Number', width: 200 },
        { id: 4, label: 'Merge Into', width: 100 }
    ];

    // Handle what happens when a checkbox in the list is clicked
    const handleCheckbox = (group, id) => {
        // Create a temp array
        let currentDuplicateCustomers = duplicateCustomers;

        // Find the index of the checkbox row and save the group
        let index = currentDuplicateCustomers.findIndex(
            (x) => x.key === group.key
        );
        let currentGroup = currentDuplicateCustomers[index];

        // Update the group merge id and save it to the temp array
        currentGroup.mergeId = id;
        currentDuplicateCustomers[index] = currentGroup;

        setDuplicateCustomers(currentDuplicateCustomers);
        setTest(!test);
    };

    const getItemSize = (index) => {
        const rowHeight = 50; // height for each data row
        const numRows = duplicateCustomers[index].rows.length; // how many rows the customer group table has
        const totalRowHeight = numRows * rowHeight; // total height of all customer rows
        const baseHeight = 150; // height leave for typography,devider..

        return baseHeight + totalRowHeight; // Total height of each group section
    };

    const Row = ({ index, style }) => {
        const group = duplicateCustomers[index];
        return (
            <div style={style}>
                <Typography variant="body1">
                    <b>{group.key}</b>
                </Typography>
                <Divider />
                <DataTable columns={columns}>
                    {group.rows.map((row) => (
                        <TableRow
                            key={row.id}
                            selected={group.mergeId === row.id ? true : false}
                        >
                            <DataCell
                                textDecoration={
                                    group.mergeId && group.mergeId !== row.id
                                        ? 'line-through'
                                        : ''
                                }
                            >
                                {row.id}
                            </DataCell>
                            <DataCell
                                textDecoration={
                                    group.mergeId && group.mergeId !== row.id
                                        ? 'line-through'
                                        : ''
                                }
                            >
                                {row.companyName
                                    ? row.companyName
                                    : row.firstName + ' ' + row.lastName}
                            </DataCell>
                            <DataCell
                                textDecoration={
                                    group.mergeId && group.mergeId !== row.id
                                        ? 'line-through'
                                        : ''
                                }
                            >
                                {row.email}
                            </DataCell>
                            <DataCell
                                textDecoration={
                                    group.mergeId && group.mergeId !== row.id
                                        ? 'line-through'
                                        : ''
                                }
                            >
                                {row.phoneNumber}
                            </DataCell>
                            <DataCell>
                                <Checkbox
                                    disabled={submitLoading}
                                    checked={group.mergeId === row.id}
                                    onChange={() =>
                                        handleCheckbox(group, row.id)
                                    }
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
                <br />
                <br />
            </div>
        );
    };

    return (
        <div style={{ height: '70vh', width: '100%' }}>
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        height={height}
                        width={width}
                        itemCount={duplicateCustomers.length}
                        itemSize={getItemSize}
                    >
                        {Row}
                    </List>
                )}
            </AutoSizer>
        </div>
    );
};

export default DuplicateList;
