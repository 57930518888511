import { Dispatch, SetStateAction } from 'react';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { Dayjs } from 'dayjs';
import api from '../../../../../api';
import { User } from '../../../global/interfaces/GeneralInterface';

const HandleDnDService = (
    serviceId: string,
    newStartTime: Dayjs,
    newEndTime: Dayjs,
    events: any[],
    setListOfJobs: Dispatch<SetStateAction<Service[]>>,
    isAllDay: boolean,
    techId: number,
    techList: User[],
    isResize: boolean
) => {
    let listOfJobs = [...events];
    let jobIndex = listOfJobs.findIndex((x) => x.id === parseInt(serviceId));
    let currentJob = listOfJobs[jobIndex];

    if (isAllDay) {
        currentJob.jobStart = newStartTime.hour(8).toISOString();
        currentJob.start = newStartTime.hour(8).toDate();
        if (newEndTime.diff(newStartTime, 'hour') < 24) {
            currentJob.jobFinish = newEndTime
                .add(1, 'day')
                .hour(8)
                .toISOString();
            currentJob.end = newEndTime.add(1, 'day').hour(8).toDate();
        } else {
            currentJob.jobFinish = newEndTime.toISOString();
            currentJob.end = newEndTime.toDate();
        }
    } else if (isResize) {
        currentJob.jobStart = newStartTime.toISOString();
        currentJob.start = newStartTime.toDate();
        currentJob.jobFinish = newEndTime.toISOString();
        currentJob.end = newEndTime.toDate();
    } else if (newEndTime.diff(newStartTime, 'hour') >= 24) {
        currentJob.jobStart = newStartTime.toISOString();
        currentJob.start = newStartTime.toDate();
        currentJob.jobFinish = newStartTime.add(1, 'hour').toISOString();
        currentJob.end = newStartTime.add(1, 'hour').toDate();
    } else {
        currentJob.jobStart = newStartTime.toISOString();
        currentJob.start = newStartTime.toDate();
        currentJob.jobFinish = newEndTime.toISOString();
        currentJob.end = newEndTime.toDate();
    }

    if (techId != null) {
        let selectedTech = techList.find((x) => x.id === techId);
        currentJob.TechId = techId;
        currentJob.jobData.Tech = selectedTech;
        currentJob.Tech = selectedTech;
        currentJob.resourceId = techId;
        currentJob.resourceTitle =
            selectedTech.firstName +
            ' ' +
            selectedTech.lastName.charAt(0) +
            '.';
    }
    listOfJobs[jobIndex] = currentJob;
    api.put('/updateServiceDragAndDrop/' + serviceId, currentJob).then(() => {
        setListOfJobs(listOfJobs);
    });
};

export default HandleDnDService;
