import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CloseIcon from '@mui/icons-material/Close';

import { Tag, TAG_ACTIONS } from '../../EsignInterface';
import TagWrapper from './TagWrapper';
import { ChangeEvent, Dispatch, DragEvent, SetStateAction } from 'react';

interface CheckboxTagProps {
    action: string;
    tag?: Tag;
    fontSize?: string;
    iconSize?: string;
    hasLabel?: boolean;
    removeTag?: () => void;
    setTags?: Dispatch<SetStateAction<Tag[]>>;
}

const CheckboxTag = ({
    action,
    tag,
    fontSize,
    iconSize,
    hasLabel,
    removeTag,
    setTags
}: CheckboxTagProps) => {
    const isFillable = action === TAG_ACTIONS.FILL;
    const isResizable = action === TAG_ACTIONS.MODIFY;
    const isViewOnly = action === TAG_ACTIONS.VIEW;

    const dragStartHandler = (event: DragEvent<HTMLDivElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();

        // calculate the offset of the mouse cursor from the top left corner
        const offsetX = event.clientX - rect.left;
        const offsetY = event.clientY - rect.top;

        // get the signature field size
        const width = rect.height; // check box is a square, so make its width equal to its height
        const height = rect.height;

        // set the data transfer object for dragging
        event.dataTransfer.setData('type', 'checkbox');
        event.dataTransfer.setData('offsetX', offsetX.toString());
        event.dataTransfer.setData('offsetY', offsetY.toString());
        event.dataTransfer.setData('width', width.toString());
        event.dataTransfer.setData('height', height.toString());

        // if tag is not undefined, means the tag is already in the canvas, set the tempId to the dataTransfer
        event.dataTransfer.setData(
            'tempId',
            tag !== undefined ? tag.tempId.toString() : ''
        );
    };

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTags((prevTags) => {
            return prevTags.map((t) => {
                if (t.id === tag.id) {
                    return { ...t, inputData: e.target.checked.toString() }; // here need to convert boolean to string, because the inputData is string
                }
                return t;
            });
        });
    };

    return (
        <>
            {isFillable ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.main',
                        borderColor: 'primary.main',
                        borderWidth: 2,
                        borderStyle: 'solid',
                        cursor: 'default',
                        aspectRatio: '1 / 1',
                        height: '100%',
                        width: '100%'
                    }}
                >
                    <Checkbox
                        checked={tag.inputData === 'true'}
                        onChange={handleCheckboxChange}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: iconSize } }}
                    />
                </Box>
            ) : isViewOnly ? (
                <TagWrapper
                    isViewOnly={isViewOnly}
                    isResizable={isResizable}
                    dragStartHandler={dragStartHandler}
                >
                    <CheckBoxOutlinedIcon
                        sx={{
                            fontSize: iconSize
                        }}
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: fontSize,
                            flexGrow: 0.8
                        }}
                    >
                        Checkbox
                    </Typography>
                </TagWrapper>
            ) : isResizable && removeTag ? (
                <Box
                    draggable={isResizable}
                    onDragStart={dragStartHandler}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        color: 'primary.main',
                        borderColor: 'primary.main',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        cursor: 'move',
                        aspectRatio: '1 / 1',
                        height: '100%',
                        width: '100%'
                    }}
                >
                    <IconButton
                        onClick={removeTag}
                        size="small"
                        sx={{
                            color: 'primary.main'
                        }}
                    >
                        <CloseIcon sx={{ fontSize: fontSize }} />
                    </IconButton>
                </Box>
            ) : null}
        </>
    );
};

export default CheckboxTag;
