import { Dispatch, SetStateAction } from 'react';
import { Model } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const UpdateModelName = (
    model: Model,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.put('modelName/' + model.id, {
        newName: model.name.toUpperCase()
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Model name has been updated.');
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar('Model already exist.', '', 'error');
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Something went wrong, please refresh the page and try again.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default UpdateModelName;
