// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
  Collapse,
  Divider,
  IconButton,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Add, Close, ExpandLess, ExpandMore } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
import DataCell from '../../../global/tableComponents/DataCell';
import DataTable from '../../../global/tableComponents/DataTable';
// INTERFACES
import { Settings } from '../../../global/interfaces/GeneralInterface';
// LOGIC
import { ToCamelCase } from '../../../global/logic/Formatters';

interface SettingsProps {
  settings: Settings; // The company settings
  setSettings: Dispatch<SetStateAction<Settings>>;
  disabled: boolean;
}

/**
 * Payment Methods
 * UI for Payment Methods on the Company Settings page
 * @author Estienne
 * @param SettingsProps
 */
const PaymentMethods = ({ settings, setSettings, disabled }: SettingsProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const AddMethod = () => {
    let tempMethods = [...settings.paymentMethods];
    let id =
      tempMethods.length > 0 ? tempMethods[tempMethods.length - 1].id + 1 : 1;
    tempMethods.push({ id: id, name: null, value: null });
    setSettings({ ...settings, paymentMethods: tempMethods });
  };

  const EditMethod = (newValue: string, methodId: number) => {
    let tempMethods = [...settings.paymentMethods];
    let index = tempMethods.findIndex((x) => x.id === methodId);
    let method = { ...tempMethods[index] };
    method.name = newValue.toUpperCase();
    method.value = ToCamelCase(newValue);

    tempMethods[index] = method;
    setSettings({ ...settings, paymentMethods: tempMethods });
  };

  const RemoveMethod = (methodId: number) => {
    let tempMethods = [...settings.paymentMethods];
    tempMethods = tempMethods.filter((item) => item.id !== methodId);
    setSettings({ ...settings, paymentMethods: tempMethods });
  };

  return (
    <Paper textAlign="center">
      <Typography variant="h6" align="left">
        Payment Methods
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Typography>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <DataTable columns={[]}>
          {settings?.paymentMethods.map((method) => (
            <TableRow>
              <DataCell width="100%">
                {disabled ? (
                  method.name
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={method.name}
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    onChange={(e) => EditMethod(e.target.value, method.id)}
                  />
                )}
              </DataCell>
              {disabled ? null : (
                <DataCell width="40">
                  <Tooltip placement="left" title="Remove Method">
                    <IconButton onClick={() => RemoveMethod(method.id)}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                </DataCell>
              )}
            </TableRow>
          ))}
        </DataTable>
        {disabled ? null : (
          <IconButton onClick={() => AddMethod()}>
            <Add />
          </IconButton>
        )}
      </Collapse>
    </Paper>
  );
};

export default PaymentMethods;
