import { DialogActions, DialogContent, TextField } from '@mui/material';
import { useState } from 'react';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import CreateMake from '../../logic/CreateMake';

const AddMakeDialogContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [name, setName] = useState<string>('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    return (
        <>
            <DialogContent>
                <br />
                <TextField
                    fullWidth
                    size="small"
                    label="New Make Name"
                    InputLabelProps={{ shrink: true }}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    disabled={name.length === 0}
                    onClick={() =>
                        CreateMake(name, setButtonLoading, showSnackbar)
                    }
                >
                    Submit New Make
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default AddMakeDialogContent;
