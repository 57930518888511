// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import api from '../../../../../api';
import DownloadABA from './DownloadABA';

interface CreditorLine {
    Creditor: Creditor;
    Invoices: any[];
    InvoiceTotal: number;
    PaymentTotal: number;
    PaymentRemaining: number;
}

interface CreditorReconciliationProps {
    creditors?: CreditorLine[];
    setButtonLoading?: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
    payrunId?: number;
}

/**
 * HandleGenerateABA
 * Check that bank account information is present for each
 * creditor beforeproceeding with the ABA generation
 * @author Estienne
 * @param CreditorReconciliationProps
 */
const HandleGenerateABA = ({
    creditors,
    setButtonLoading,
    showSnackbar,
    payrunId
}: CreditorReconciliationProps) => {
    setButtonLoading(true);
    // If there's no payrunId, generate the ABA
    if (!payrunId) {
        // Check that each creditor has bank account information
        let missingCreditorData = false;
        creditors.every((creditor) => {
            if (
                !creditor.Creditor.paymentDetails.bsb ||
                !creditor.Creditor.paymentDetails.accountNumber
            ) {
                missingCreditorData = true;
                return false;
            }
            return true;
        });

        if (missingCreditorData) {
            showSnackbar(
                'One or more suppliers are missing payment information.',
                'Please add the missing details before proceeding.',
                'error'
            );
            setButtonLoading(false);
        } else {
            // Generate the ABA
            api.post(
                `generateABA?SiteId=${localStorage.getItem('SiteId')}`,
                creditors
            ).then((res) => {
                if (res.status === 200) {
                    // Download the payment file
                    api.get('downloadPaymentNextGen').then((res) => {
                        if (res.status === 200) {
                            DownloadABA(res.data);
                        }
                        setButtonLoading(false);
                    });
                } else {
                    showSnackbar(res.data, '', 'error');
                    setButtonLoading(false);
                }
            });
        }
    }

    // Otherwise, regenerate the ABA
    else {
        api.get(
            `regenerateABANextGen/${payrunId}?SiteId=${localStorage.getItem('SiteId')}`
        ).then((res) => {
            if (res.status === 200) {
                api.get('downloadPaymentNextGen').then((res) => {
                    if (res.status === 200) {
                        DownloadABA(res.data);
                    }
                    setButtonLoading(false);
                });
            }
        });
    }
};

export default HandleGenerateABA;
