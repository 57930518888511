// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Voucher } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const GetVoucher = (
    voucherCode: number | string,
    setVoucher: Dispatch<SetStateAction<Voucher>>,
    setVoucherDrawerOpen: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    api.get(`voucher/${voucherCode}?code=true`).then((res) => {
        if (res.status === 404) {
            // Code not found
            showSnackbar(
                'Voucher with that code not found.',
                'Please check the code you have entered.',
                'error'
            );
            return;
        } else {
            if (res.data.refunded) {
                // Voucher has been refunded and cannot be used for a payment anymore
                showSnackbar('This voucher has been refunded.', '', 'error');
            } else {
                setVoucher(res.data);
                setVoucherDrawerOpen(true);
            }
        }
    });
};

export default GetVoucher;
