import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const UpdateCategoryName = (
    category,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.put('categoryName/' + category.id, {
        newName: category.name.toUpperCase(),
        newParent: category.parent.toUpperCase()
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Category name and/or Category parent name has been updated.',
                'The page will now reload.'
            );
            setTimeout(() => window.location.reload(), 1000);
        } else {
            showSnackbar(
                'Something went wrong, please refresh the page and try again.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default UpdateCategoryName;
