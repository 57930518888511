import { Step } from '../../interface';

export const AddNewVehicleSaleContractSteps: Step[] = [
    {
        label: "Navigate to 'Vehicle Sales' > 'New Sale' via the sidebar to access the New Contract page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-01.png'
    },
    {
        label: 'The Select Customer tab lets you select an existing Customer list or create a new one.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-02.png'
    },
    {
        label: "The Select Vehicle tab lets you select a vehicle from the 'Stock Vehicles' list. Only vehicles whose status is marked as 'Available' can be selected.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-03.png'
    },
    {
        label: (
            <>
                The Add-Ons tab display all Parts & Accessories Sales linked to
                this contract. To add a Parts & Accessories Sale, refer to{' '}
                <a
                    href="/support/vehicles/newContract/#add-csms-to-contract"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Add CSMs to a Vehicle Sale
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-04.png'
    },
    {
        label: 'The Trade tab lets you add a new Trade-In.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-05.png'
    },
    {
        label: 'The Finalise tab lets you add all the other details about the sale. This is where you can pick the Sale Status (Quote, Sale, Finalised or Lost).',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-06.png'
    },
    {
        label: 'Clicking the eye icon to toggle the visibility of pricing details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-07.png'
    },
    {
        label: 'The Vehicle Charges section lets you enter the Vehicle Price (the price you wish to sell the bike for), warranty length, warranty price, DOF price, and delivery price if applicable.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-08.png'
    },
    {
        label: 'The Government Charges section lets you select if there are government charges or not.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-09.png'
    },
    {
        label: 'If Government Charges are applicable, fill in all required fields. Click the Calculator icon to view the guide charges for registration, CTP, and stamp duty fees.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-10.png'
    },
    {
        label: "The CTP Price are guidance but they can be wrong if they haven't been updated in Ora.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-11.png'
    },
    {
        label: "Check the 'Remove Stamp Duty' box if stamp duty is not applicable.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-12.png'
    },
    {
        label: 'In the Purchase Details table, enter the Total Purchase Price (the total amount the customer will pay), and toggle whether the price includes or excludes GST.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-13.png'
    },
    {
        label: 'If the Total Purchase Price is lower than the sum of the vehicle charges, government charges, and GST, a discount will be displayed. This will also be shown in the contract.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-14.png'
    },
    {
        label: "In the Payment tab, add payment lines by selecting the payment method, till, entering the amount, and clicking 'Add'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-finalise-10.png'
    },
    {
        label: 'Once the contract is created, you can view it on the Vehicle Sales page by clicking the ID to access contract details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-16.png'
    }
];

export const AddNewVehicleQuoteContractSteps: Step[] = [
    {
        label: "Navigate to 'Vehicle Sales' > 'New Sale' via the sidebar to access the New Contract page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-01.png'
    },
    {
        label: 'The Select Customer tab lets you select an existing Customer list or create a new one.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-02.png'
    },
    {
        label: "The Select Vehicle tab lets you select a vehicle from the 'Stock Vehicles' list. Only vehicles whose status is marked as 'Available' can be selected.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-03.png'
    },
    {
        label: (
            <>
                The Add-Ons tab display all Parts & Accessories Sales linked to
                this contract. To add a Parts & Accessories Sale, refer to{' '}
                <a
                    href="/support/vehicles/newContract/#add-csms-to-contract"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Add CSMs to a Vehicle Sale
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-04.png'
    },
    {
        label: 'The Trade tab lets you add a new Trade-In.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-05.png'
    },
    {
        label: 'The Finalise tab lets you add all the other details about the sale. This is where you can pick the Sale Status (Quote, Sale, Finalised or Lost).',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-06.png'
    },
    {
        label: 'Clicking the eye icon to toggle the visibility of pricing details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-07.png'
    },
    {
        label: 'The Vehicle Charges section lets you enter the Vehicle Price (the price you wish to sell the bike for), warranty length, warranty price, DOF price, and delivery price if applicable.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-08.png'
    },
    {
        label: 'The Government Charges section lets you select if there are government charges or not.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-09.png'
    },
    {
        label: 'If Government Charges are applicable, fill in all required fields. Click the Calculator icon to view the guide charges for registration, CTP, and stamp duty fees.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-10.png'
    },
    {
        label: "The CTP Price are guidance but they can be wrong if they haven't been updated in Ora.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-11.png'
    },
    {
        label: "Check the 'Remove Stamp Duty' box if stamp duty is not applicable.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-12.png'
    },
    {
        label: 'In the Purchase Details table, enter the Total Purchase Price (the total amount the customer will pay), and toggle whether the price includes or excludes GST.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-13.png'
    },
    {
        label: 'If the Total Purchase Price is lower than the sum of the vehicle charges, government charges, and GST, a discount will be displayed. This will also be shown in the contract.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-14.png'
    },
    {
        label: 'Once the contract is created, you can view it on the Vehicle Sales page by clicking the ID to access contract details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-quote-16.png'
    }
];

export const AddNewVehicleFinalisedContractSteps: Step[] = [
    {
        label: "Navigate to 'Vehicle Sales' > 'New Sale' via the sidebar to access the New Contract page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-01.png'
    },
    {
        label: 'Select from the existing customer list or create a new customer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-02.png'
    },
    {
        label: "Select a vehicle from the 'Stock Vehicles' list. All vehicles whose status is marked as 'Available' can be selected.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-finalise-03.png'
    },
    {
        label: (
            <>
                Under the 'ADD-ONS' tab, display all CSMs linked to this
                contract. To add a CSM, refer to{' '}
                <a
                    href="/support/vehicles/newContract/#add-csms-to-contract"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Add CSMs to a vehicle contract
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-04.png'
    },
    {
        label: "Under the 'TRADES' tab, add a new trade if the current customer has a vehicle to trade in, otherwise skip this step.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-sale-05.png'
    },
    {
        label: "Under the 'FINALISE' tab, choose the contract status as 'Finalised'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-finalise-06.png'
    },
    {
        label: 'Select the day the contract will be finalized.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-finalise-07.png'
    },
    {
        label: "Follow the same steps as the Quote/Sale contract to fill in the vehicle charges, government charges, and purchase details. Note, for a Finalised contract, there is an additional field for 'Rebate Price'. Fill in the rebate price if there is any rebate for this contract.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-finalise-08.png'
    },
    {
        label: "For a Finalised contract, payment is required. If you click save without adding payment, you will see an error message: 'The sale cannot be marked as finalised without full payment'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-finalise-09.png'
    },
    {
        label: "In the Payment tab, add payment lines by selecting the payment method, till, entering the amount, and clicking 'Add'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-finalise-10.png'
    },
    {
        label: "Now click 'Save' to submit the finalised contract.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-finalise-11.png'
    },
    {
        label: 'Once the contract is created, you can view the contract on the Vehicle Sales page. Click the ID to view contract details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/new-finalise-12.png'
    }
];

export const LostVehicleContractSteps: Step[] = [
    {
        label: "When selecting a vehicle from the 'Stock Vehicles' list, notice that some may be marked as 'Sold' or 'Selected in Sale.' This indicates the vehicle is part of a finalized contract or a sale contract. If the sale does not proceed, you can mark the contract as lost to free the vehicle for other sales.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/lost-contract-01.png'
    },
    {
        label: 'To mark a sale contract as lost, click the vehicle ID to access the vehicle details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/lost-contract-02.png'
    },
    {
        label: "In the vehicle details page, navigate to the 'Finalised' tab and click the 'Edit' button.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/lost-contract-03.png'
    },
    {
        label: "Click the 'Lost' button to change the contract's status to 'Lost'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/lost-contract-04.png'
    },
    {
        label: "You are required to provide a reason for the sale's failure. This detail will be recorded in the system.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/lost-contract-05.png'
    },
    {
        label: 'To mark a finalized contract as lost, you can add a negative payment amount to the contract to refund the customer if necessary.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/lost-contract-06.png'
    },
    {
        label: "Click 'Save' to finalize the submission of the lost contract.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/lost-contract-07.png'
    },
    {
        label: 'Once the contract is marked as lost, the vehicle will become available again for sale.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/lost-contract-08.png'
    }
];

export const AddCSMsToContractSteps: Step[] = [
    {
        label: "To add a CSM for contract #5370 for example, navigate to 'Parts & Accessories' > 'New Sale' via the sidebar to access the New Sale page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-02.png'
    },
    {
        label: 'Select the Vehicle Contract type, locate contract #5370, and select it.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-03.png'
    },
    {
        label: 'In the Orderlines tab, enter the part number or barcode and then press Enter to add the parts. The sale status is set by default to Proforma.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-04.png'
    },
    {
        label: 'Click the Save button to submit the parts sale.',
        imgPath:
            ' https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-05.png'
    },
    {
        label: 'The Sale should now appears in the Vehicle Sale Add-Ons tab, you can see that the CSM #73611 was linked to this vehicle contract #5370.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-06.png'
    },
    {
        label: "At this stage, #73611 has been linked to Vehicle Sale #5370, but it has not been paid. If you try to change the Parts & Accessories order status from Proforma to Sale, you will see an error message: 'CSM add-ons have not been paid!'",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-07.png'
    },
    {
        label: 'To pay for a CSM, in the Vehicle Sale page, in the Payment tab, you need enough payment to cover for the Add-Ons and you will see a button labeled Pay CSM.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-08.png'
    },
    {
        label: 'Click the Pay CSM button. It will use the money the customer paid to cover the Parts & Accessories Order. Currently, the amount paid is $100.00, which is not enought for the Add-Ons subtotal $116, so when you click the PAY ADD-ONS button, it will show an error message: "Not enough payment to cover Add-On total."',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-09.png'
    },
    {
        label: 'If you add another payment line to the contract, for example, adding a payment line of another $100.00, then click the Pay CSM button again, the Parts & Accessories order will be paid.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-10.png'
    },
    {
        label: 'Now that CSM #73611 has been fully paid, you can go to the Part & Accessories Order page to change the order status from Proforma to Sale.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/newSale/add-csm-11.png'
    }
];
