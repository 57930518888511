import api from '../../../../../api';
import { StandardFonts, PDFDocument } from 'pdf-lib';
import dayjs from 'dayjs';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// var fs = require('fs')

const GetForm7 = (saleDetails) => {
    api.get('/getForm').then(async (res) => {
        let site = saleDetails.Site
        const pdfDoc = await PDFDocument.load(res.data);
        let helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
        let page = pdfDoc.getPages()[0]
        

        //Dealer name
        let dealerName = site.Setting.companyName
        page.drawText(dealerName, {
            x: 84,
            y: 730.5,
            size: 9,
            font: helveticaBold
        })

        //Dealer Address
        let dealerAddress = site.address + ', ' + site.suburb + ' ' + site.state + ' ' + site.postcode
        page.drawText(dealerAddress, {
            x: 91,
            y: 715.5,
            size: 9,
            font: helveticaBold
        })

        //Motor Dealer Licence Number
        let dealerNumber = site.Setting.lmd
        page.drawText(dealerNumber, {
            x: 425,
            y: 730.5,
            size: 9,
            font: helveticaBold
        })

        // Date of manufacture
        let dateOfManufacture = "06/" + saleDetails.Vehicle.year.format('YYYY')
        page.drawText(dateOfManufacture, {
            x: 177,
            y: 677,
            size: 9,
            font: helveticaBold
        })
        
        // Make
        let make = saleDetails.Vehicle.Make.name ?? ''
        page.drawText(make, {
            x: 60,
            y: 662,
            size: 9,
            font: helveticaBold
        })
        
        // Odometer
        let odometer = saleDetails.Vehicle.odometer ?? ''
        page.drawText(odometer, {
            x: 382,
            y: 662,
            size: 9,
            font: helveticaBold
        })
        
        // Model
        let model = saleDetails.Vehicle.Model.name ?? ''
        page.drawText(model, {
            x: 60,
            y: 647,
            size: 9,
            font: helveticaBold
        })
        
        // VIN
        let vin = saleDetails.Vehicle?.vin ? saleDetails.Vehicle.vin : ''
        page.drawText(vin, {
            x: 387,
            y: 647,
            size: 9,
            font: helveticaBold
        })

        // Rego and Rego Expiry Date
        let regoNumber = saleDetails.Vehicle?.registrationNumber ? saleDetails?.Vehicle?.registrationNumber?.toUpperCase() : ''
        let regoExpiry = saleDetails.Vehicle?.registrationDueDate ? dayjs(saleDetails.Vehicle.registrationDueDate).format('DD/MM/YYYY') : ''
        page.drawText(regoNumber, {
            x: 150,
            y: 632.5,
            size: 9,
            font: helveticaBold
        })

        page.drawText('Expire: ' + regoExpiry, {
            x: 195,
            y: 632.5,
            size: 9,
        })

        // Engine Number
        let engineNumber = saleDetails.Vehicle.engineNumber ?? ''
        page.drawText(engineNumber, {
            x: 370,
            y: 632.5,
            size: 9,
            font: helveticaBold
        })

        // Date of Sale
        let dateOfSale = dayjs(saleDetails.finalisedDate).format('DD/MM/YYYY')
        page.drawText(dateOfSale, {
            x: 83,
            y: 237,
            size: 9,
            font: helveticaBold
        })

        // Sale Price (inc. gst)
        let salePrice = saleDetails.totalPurchasePrice.toString()
        page.drawText(CurrencyFormatter(salePrice), {
            x: 390,
            y: 237,
            size: 9,
            font: helveticaBold
        })

        const pdfBytes = await pdfDoc.save();
        var file = new Blob([pdfBytes], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    });
};

export default GetForm7;
