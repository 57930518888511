import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    Order,
    StockTransfer
} from '../../../global/interfaces/PartsInterface';
import PrintLabel from '../../../global/logic/PrintLabel';
import api from '../../../../../api';
import GenerateTransferSlip from './GenerateTransferSlip';

// Update / complete a transfer
const UpdateTransfer = (
    transferData: StockTransfer,
    setProformaDrawerOpen: Dispatch<SetStateAction<boolean>>,
    setProformaData: Dispatch<
        SetStateAction<
            {
                partNumber: string;
                proformaData: Order[];
            }[]
        >
    >,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    // Check that the sites and parts are still valid
    if (
        !transferData.SiteFrom.id ||
        !transferData.SiteFrom.id ||
        transferData.transferLines.length === 0 ||
        transferData.SiteFrom.id === transferData.SiteTo.id
    ) {
        showSnackbar(
            'Make sure you selected different sites and added parts to the transfer.',
            '',
            'error'
        );
        return;
    }
    setButtonLoading(true);

    // Send to the database
    api.put('stockTransferNextGen', transferData).then((res) => {
        if (res.status === 200) {
            PrintLabel(res.data.labelData, showSnackbar);
            setProformaData(res.data.proformaData);
            setProformaDrawerOpen(true);
            GenerateTransferSlip(transferData);
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Something went wrong when updating the stock transfer.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default UpdateTransfer;
