// IMPORTS
// eslint-disable-next-line
import jsPDF from 'jspdf'; // !DO NOT REMOVE
import autoTable from 'jspdf-autotable';

const InvoiceTable = async (
    doc,
    yCoord: number,
    header: string[][],
    body: string[][],
    lastRowBold?: boolean
) => {
    autoTable(doc, {
        head: header,
        body: body,
        startY: yCoord,
        theme: 'grid',
        headStyles: {
            fillColor: [19, 65, 123],
            textColor: 'white',
            lineColor: [19, 65, 123]
        },
        margin: { bottom: 20 },
        styles: { lineColor: [201, 201, 201], lineWidth: 0.1 },
        willDrawCell: function (data) {
            var rows = data.table.body;
            if (data.row.index === rows.length - 1 && lastRowBold) {
                doc.setFont('helvetica', 'bold').setTextColor(0);
            }
        }
    });
    return doc.lastAutoTable.finalY;
};

export default InvoiceTable;
