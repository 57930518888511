// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
// LOGIC
import { ValidateRRP } from '../../../global/logic/vehicleValidation/ValidateVehicleFields';
import api from '../../../../../api';

interface UpdateProps {
    vehicleResults: Vehicle[];
    showSnackbar: showSnackbar;
}

/**
 * validation
 * Check that each vehicle has a valid RRP
 * @author Estienne
 * @param vehicleResults the array of vehicles
 * @returns a non-null boolean
 */
const validation = (vehicleResults: Vehicle[]) => {
    let valid = true;
    vehicleResults.forEach((vehicle) => {
        if (!ValidateRRP(parseFloat(vehicle.priceRRP))) {
            valid = false;
        }
    });

    return valid;
};

/**
 * handleUpdateVehicleRRPs
 * Send the vehicle data to the database
 * @author Estienne
 * @param UpdateProps
 */
const handleUpdateVehicleRRPs = ({
    vehicleResults,
    showSnackbar
}: UpdateProps) => {
    if (validation(vehicleResults) === true) {
        api.put(`updateVehicle`, vehicleResults).then((res) => {
            if (res.status === 200) {
                showSnackbar('Vehicles successfully updated.');
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }
};

export default handleUpdateVehicleRRPs;
