// IMPORTS
import { useEffect, useState } from 'react';
import { TableRow, IconButton, Dialog, Tooltip } from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

// COMPONENTS
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import TaskDialogContent from './components/TaskDialogContent';
import { withSnackbar } from '../../global/WrappingSnackbar';

// LOGIC
import GetUser from '../../global/databaseLogic/GetUser';
import GetAllTasksForCurrentSite from './logic/GetAllTasksForCurrentSite';
import handleDeleteTask from './logic/HandleDeleteTask';

// INTERFACE
import { DailyTask, User } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

const clearedSort = ['department', 'DESC'];

const DailyTaskSettingsTabContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const currentSiteId = localStorage.getItem('SiteId');
    const [currentUser, setCurrentUser] = useState<User>();
    const [addTaskDialogOpen, setAddTaskDialogOpen] = useState<boolean>(false);
    const [dailyTasks, setDailyTasks] = useState<DailyTask[]>([]);
    const [selectedTask, setSelectedTask] = useState<DailyTask | null>(null);
    const [sort, setSort] = useState(clearedSort);

    useEffect(() => {
        GetUser(0, setCurrentUser);
    }, []);

    useEffect(() => {
        if (currentUser && currentSiteId) {
            GetAllTasksForCurrentSite(setDailyTasks, currentSiteId);
        }
    }, [currentUser, currentSiteId]);

    const columns = [
        { id: 0, label: 'Site' },
        { id: 1, label: 'Department', name: 'department', sort: true },
        { id: 2, label: 'Task Description' },
        { id: 3, label: 'CreatedBy' },
        { id: 4, label: 'UpdatedBy' },
        { id: 5, label: 'Edit' }
    ];

    const onSuccessSaveTask = (savedTask: DailyTask) => {
        const taskExists = dailyTasks.some((task) => task.id === savedTask.id);
        let updatedTasks;
        if (taskExists) {
            updatedTasks = dailyTasks.map((task) =>
                task.id === savedTask.id ? savedTask : task
            );
        } else {
            updatedTasks = [...dailyTasks, savedTask];
        }
        setDailyTasks([...updatedTasks]);
        setAddTaskDialogOpen(false);
        showSnackbar(
            selectedTask ? 'Daily task updated.' : 'Daily task added.'
        );
    };

    const onSuccessDeleteTask = (deletedTask: DailyTask) => {
        let updatedTasks;
        updatedTasks = dailyTasks.filter((task) => task.id !== deletedTask.id);
        setDailyTasks([...updatedTasks]);
        showSnackbar('Daily task deleted.');
    };

    const onError = (error: Error) => {
        showSnackbar(error.message, '', 'error');
    };

    const sortDailyTasks = (a: DailyTask, b: DailyTask) => {
        const column = sort[0];
        const sortOrder = sort[1];

        if (a[column] < b[column]) {
            return sortOrder === 'ASC' ? -1 : 1;
        }
        if (a[column] > b[column]) {
            return sortOrder === 'ASC' ? 1 : -1;
        }
        return 0;
    };

    return (
        <PageWrapper>
            <Paper>
                <Grid container spacing={2}>
                    <Grid xs={12} textAlign="center">
                        {dailyTasks.length > 0 && (
                            <DataTable
                                columns={columns}
                                sort={sort}
                                setSort={setSort}
                            >
                                {dailyTasks
                                    ?.sort(sortDailyTasks)
                                    .map((task, index) => (
                                        <TableRow key={task.id}>
                                            <DataCell>
                                                {task.Site?.name}
                                            </DataCell>
                                            <DataCell>
                                                {task.department}
                                            </DataCell>
                                            <DataCell>
                                                {task.description}
                                            </DataCell>
                                            <DataCell>
                                                {task.creator?.firstName}{' '}
                                                {task.creator?.lastName}
                                            </DataCell>
                                            <DataCell>
                                                {task.updator?.firstName}{' '}
                                                {task.updator?.lastName}
                                            </DataCell>

                                            <DataCell>
                                                <Tooltip title="Edit Task">
                                                    <IconButton
                                                        onClick={() => {
                                                            setSelectedTask(
                                                                task
                                                            );
                                                            setAddTaskDialogOpen(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete Task">
                                                    <IconButton
                                                        onClick={() => {
                                                            handleDeleteTask({
                                                                taskData: task
                                                            })
                                                                .then(
                                                                    (
                                                                        deletedTask
                                                                    ) => {
                                                                        onSuccessDeleteTask(
                                                                            deletedTask
                                                                        );
                                                                        setSelectedTask(
                                                                            null
                                                                        );
                                                                    }
                                                                )
                                                                .catch(
                                                                    (error) => {
                                                                        onError(
                                                                            error
                                                                        );
                                                                        setSelectedTask(
                                                                            null
                                                                        );
                                                                    }
                                                                );
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </DataCell>
                                        </TableRow>
                                    ))}
                            </DataTable>
                        )}

                        <Tooltip title="Add New Task">
                            <IconButton
                                onClick={() => setAddTaskDialogOpen(true)}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Paper>

            <Dialog
                onClose={() => setAddTaskDialogOpen(false)}
                open={addTaskDialogOpen}
                className="xl-dialog"
            >
                <TaskDialogContent
                    selectedTask={selectedTask}
                    setSelectedTask={setSelectedTask}
                    currentSiteId={currentSiteId}
                    currentUser={currentUser}
                    onSuccessSaveTask={onSuccessSaveTask}
                    onError={onError}
                ></TaskDialogContent>
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(DailyTaskSettingsTabContent);
