import api from '../../../../../api';

const GetServiceWarrantyInvoice = (
    id,
    setInvoiceObject,
    setInvoiceLines,
    setSelectedSite
) => {
    api.get('serviceWarranty/' + id)
        .then((res) => {
            if (res.data) {
                const {
                    serviceWarrantyInvoiceData,
                    invoiceData,
                    invoiceLines
                } = res.data;
                if (serviceWarrantyInvoiceData) {
                    const updatedData = {
                        ...serviceWarrantyInvoiceData,
                        Creditor: serviceWarrantyInvoiceData.Supplier,
                        documentTotal:
                            serviceWarrantyInvoiceData.documentTotal || 0,
                        documentDate: serviceWarrantyInvoiceData.dateReceived,
                        GSTtype: serviceWarrantyInvoiceData.gstType
                    };

                    setInvoiceObject(updatedData);
                }
                if (invoiceData) {
                    setSelectedSite(invoiceData.SiteId);
                }
                if (invoiceLines) {
                    setInvoiceLines(invoiceLines);
                }
            }
        })
        .catch((error) => {
            console.error('Failed to fetch invoice:', error);
        });
};

export default GetServiceWarrantyInvoice;
