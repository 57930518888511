// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
import PaymentTextField from '../../../global/paymentComponents/PaymentTextField';
import PaymentMethodSelector from '../../../global/paymentComponents/PaymentMethodSelector';
import TillSelector from '../../../global/paymentComponents/TillSelector';
import TotalForm from './OrderlineComponents/TotalForm';
import PaymentTable from '../../../global/paymentComponents/PaymentTable';
import VoucherDrawerContent from './VoucherDrawerContent';
import Drawer from '../../../global/Drawer';
// LOGIC
import GetUser from '../../../global/databaseLogic/GetUser';
import AddPaymentLines from '../logic/AddPaymentLines';
import { DueNow, PaymentTotal, SaleTotal } from '../logic/CalculateTotals';
import ValidateVoucher from '../../../global/logic/ValidateVoucher';
import GetVoucher from '../logic/GetVoucher';
// INTERFACES
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';
import { PaymentLine, User } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import QuickUpdateShowRRP from '../logic/updateFunctions/QuickUpdateShowRRP';

interface OrderlinesProps {
    id: number;
    readOnly: boolean;
    saleDetails: Order;
    setSaleDetails: Dispatch<SetStateAction<Order>>;
    oldSale: Order;
    extraCosts: { id: number; type: string; amount: string }[];
    selectedType: string;
    selectedEntity;
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    showSnackbar: showSnackbar;
}

const Payment = ({
    id,
    readOnly,
    saleDetails,
    setSaleDetails,
    oldSale,
    extraCosts,
    selectedType,
    selectedEntity,
    paymentLines,
    setPaymentLines,
    showSnackbar
}: OrderlinesProps) => {
    const [currentUser, setCurrentUser] = useState<User>(null);
    const [paymentMethod, setPaymentMethod] = useState<string>('cash');
    const [voucherCode, setVoucherCode] = useState<string>('');
    const [selectedTillId, setSelectedTillId] = useState<number>(null);
    const [voucher, setVoucher] = useState<Voucher>();
    const [voucherDrawerOpen, setVoucherDrawerOpen] = useState<boolean>(false);

    useEffect(() => {
        GetUser(0, setCurrentUser);
    }, []);

    const handleAddPayment = (paymentAmount: string) => {
        if (paymentMethod === 'voucher') {
            validateVoucher(paymentAmount);
            setVoucherDrawerOpen(false);
        } else {
            AddPaymentLines(
                SaleTotal(saleDetails, extraCosts),
                PaymentTotal(paymentLines),
                selectedTillId,
                parseFloat(paymentAmount),
                paymentLines,
                setPaymentLines,
                paymentMethod,
                currentUser,
                showSnackbar
            );
        }
    };

    const validateVoucher = (paymentAmount: string) => {
        ValidateVoucher(
            parseFloat(paymentAmount),
            voucherCode,
            selectedTillId,
            paymentLines,
            paymentMethod,
            setPaymentLines,
            setPaymentMethod,
            setVoucherCode,
            showSnackbar
        );
    };

    const isCustomerDebtor = () => {
        let isDebtor = false;
        if (
            (selectedType === 'customer' || selectedType === 'online') &&
            selectedEntity?.debtor
        ) {
            isDebtor = true;
        } else if (
            (selectedType === 'contract' || selectedType === 'service') &&
            selectedEntity?.Customer?.debtor
        ) {
            isDebtor = true;
        }

        return isDebtor;
    };

    const getCustomerObject = () => {
        let customerObject = null;

        if (selectedType === 'customer' || selectedType === 'online') {
            customerObject = selectedEntity;
        } else if (selectedType === 'contract' || selectedType === 'service') {
            customerObject = selectedEntity?.Customer;
        }

        return customerObject;
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <PaymentTextField
                            disabled={readOnly || oldSale.status === 'Sale'}
                            showButton={false}
                            hideAdornment={
                                paymentMethod === 'voucher' ? true : false
                            }
                            showPaymentTypes={false}
                            SubmitPayment={handleAddPayment}
                            initialValue={DueNow(
                                selectedType,
                                saleDetails,
                                extraCosts,
                                paymentLines
                            )}
                        />
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <TillSelector
                            selectedTillId={selectedTillId}
                            setSelectedTillId={setSelectedTillId}
                            department={'service'}
                            disabled={readOnly || oldSale.status === 'Sale'}
                        />
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <PaymentMethodSelector
                            disabled={readOnly || oldSale.status === 'Sale'}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            isCustomerDebtor={isCustomerDebtor()}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {paymentMethod === 'voucher' ? (
                            <TextField
                                fullWidth
                                size="small"
                                label="Voucher Code"
                                value={voucherCode}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) =>
                                    setVoucherCode(e.target.value.toUpperCase())
                                }
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter')
                                        GetVoucher(
                                            voucherCode.toUpperCase(),
                                            setVoucher,
                                            setVoucherDrawerOpen,
                                            showSnackbar
                                        );
                                }}
                            />
                        ) : null}
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2} textAlign="right">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={saleDetails.displayRRP}
                                    onChange={(e) => {
                                        if (readOnly) {
                                            QuickUpdateShowRRP(
                                                id,
                                                e.target.checked,
                                                showSnackbar
                                            );
                                        } else {
                                            setSaleDetails({
                                                ...saleDetails,
                                                displayRRP: e.target.checked
                                            });
                                        }
                                    }}
                                />
                            }
                            label="Show RRP on Invoice"
                        />
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Paper>
                        <PaymentTable
                            disabled={readOnly}
                            multipleCustomers={false}
                            paymentLines={paymentLines}
                            setPaymentLines={setPaymentLines}
                            payingCustomer={getCustomerObject()}
                            currentUser={currentUser}
                            showSubmitButton={false}
                            showSnackbar={showSnackbar}
                            handleAddPayment={handleAddPayment}
                            saleDetails={saleDetails}
                            oldSale={oldSale}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <TotalForm
                        saleDetails={saleDetails}
                        extraCosts={extraCosts}
                        paymentForm={true}
                        paymentLines={paymentLines}
                        selectedType={selectedType}
                    />
                </Grid>
            </Grid>

            <Drawer
                openDrawer={voucherDrawerOpen}
                setOpenDrawer={setVoucherDrawerOpen}
                title={`Voucher Details`}
                subTitle={`Voucher Code: ${voucherCode.toUpperCase()}`}
                width="20vw"
            >
                <VoucherDrawerContent
                    voucher={voucher}
                    handleAddPayment={handleAddPayment}
                />
            </Drawer>
        </>
    );
};

export default Payment;
