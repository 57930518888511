const BlankTradeBox = (doc, lastYcoordinates) => {
  doc.setLineWidth(0.2).setDrawColor('black');
  doc.rect(20, lastYcoordinates, 170, 53); // Box around the trade
  doc
    .setFont('helvetica', 'bold')
    .setFontSize(9)
    .text('TRADE-IN VEHICLE', 105, lastYcoordinates + 4, { align: 'center' });
  doc.line(20, lastYcoordinates + 6, 190, lastYcoordinates + 6); // Horizontal line under trade in title

  doc.setFont('helvetica', 'normal').setFontSize(7);
  doc.text('Vehicle:', 24, lastYcoordinates + 9);
  doc.line(20, lastYcoordinates + 11, 190, lastYcoordinates + 11); // Horizontal line under vehicle description

  doc.text('Body Type:', 24, lastYcoordinates + 15);
  doc.line(105, lastYcoordinates + 11, 105, lastYcoordinates + 17); // Vertical line between body type and registration
  doc.text('Registration:', 110, lastYcoordinates + 15);
  doc.line(20, lastYcoordinates + 17, 190, lastYcoordinates + 17); // Horizontal line under body type and registration

  doc.text('VIN / Chassis No:', 24, lastYcoordinates + 21);
  doc.line(105, lastYcoordinates + 17, 105, lastYcoordinates + 23); // Vertical line between vin and engine number
  doc.text('Engine Number:', 110, lastYcoordinates + 21);
  doc.line(20, lastYcoordinates + 23, 190, lastYcoordinates + 23); // Horizontal line under vin and engine number

  doc.text('Model Year:', 24, lastYcoordinates + 27);
  doc.line(83, lastYcoordinates + 23, 83, lastYcoordinates + 29); // Vertical line between condition and model year
  doc.text('Compliance Date:', 85, lastYcoordinates + 27);
  doc.line(138, lastYcoordinates + 23, 138, lastYcoordinates + 29); // Vertical line between model year and odometer
  doc
    .text('Odometer:', 140, lastYcoordinates + 27)
    .text('kms', 188, lastYcoordinates + 27, { align: 'right' });
  doc.line(20, lastYcoordinates + 29, 190, lastYcoordinates + 29); // Horizontal line under condition, model year and odometer

  doc.text('Colour:', 24, lastYcoordinates + 33);
  doc.line(83, lastYcoordinates + 29, 83, lastYcoordinates + 35); // Vertical line between colour and ppsr check
  doc
    .setFont('helvetica', 'bold')
    .text('PPSR Check:', 85, lastYcoordinates + 33);
  doc.line(138, lastYcoordinates + 29, 138, lastYcoordinates + 35); // Vertical line between ppsr check and date
  doc.setFont('helvetica', 'normal').text('Date:', 140, lastYcoordinates + 33);
  doc.line(20, lastYcoordinates + 35, 190, lastYcoordinates + 35); // Horizontal line under colour, ppsr check and date

  doc.text('Result:', 24, lastYcoordinates + 39);
  doc.line(20, lastYcoordinates + 41, 190, lastYcoordinates + 41); // Horizontal line under result

  doc
    .setFont('helvetica', 'bold')
    .text('ENCUMBRANCES:', 24, lastYcoordinates + 45);
  doc.line(55, lastYcoordinates + 41, 55, lastYcoordinates + 47); // Vertical line between encumberances and provider
  doc
    .setFont('helvetica', 'normal')
    .text('Provider:', 60, lastYcoordinates + 45);
  doc.line(20, lastYcoordinates + 47, 190, lastYcoordinates + 47); // Horizontal line under encumbrances and provider

  doc.text('A/C No.', 24, lastYcoordinates + 51);
  doc.line(83, lastYcoordinates + 47, 83, lastYcoordinates + 53); // Vertical line between acc number and payout
  doc.text('Payout: $', 85, lastYcoordinates + 51);
  doc.line(138, lastYcoordinates + 47, 138, lastYcoordinates + 53); // Vertical line between payout and expiry date
  doc.text('Expiry Date: ', 140, lastYcoordinates + 51);
};

export default BlankTradeBox;
