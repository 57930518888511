import { Step } from '../../interface';

export const EnterCreditInvoiceSteps: Step[] = [
    {
        label: `In the sidebar, click Admin > Credit Invoice`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditInvoice/enter-credit-01.png'
    },
    {
        label: `In the Enter Credit Invoice page, all required fields are marked with an asterisk (*). If you submit the invoice without filling these out, an error message will be displayed indicating what is missing.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditInvoice/enter-credit-02.png'
    },
    {
        label: `If you do not specify a due date, the system will automatically calculate it based on the creditor's payment terms. For example, if the creditor has End of Month (EOM) payment terms and the invoice is dated April 23, 2024, the due date will be set to May 31, 2024.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditInvoice/enter-credit-03.png'
    },
    {
        label: `You can update the creditor's payment terms in the Creditor details page under the Payment Details section.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/enterInvoice/enter-invoice-04.png'
    },
    {
        label: `Click the 'Add Line' button to add one or more invoice lines.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditInvoice/enter-credit-05.png'
    },
    {
        label: `Ensure the total of these lines matches the Document Total. If there is a discrepancy, an error message will be displayed upon submission.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditInvoice/enter-credit-06.png'
    },
    {
        label: `After successfully adding an invoice, a message 'Invoice Submitted Successfully' will appear, and the page will automatically refresh.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditInvoice/enter-credit-07.png'
    }
];

export const ViewEditCreditInvoiceSteps: Step[] = [
    {
        label: `To check a newly added Credit Invoice, navigate by clicking on Admin -> Creditors from the sidebar. In the creditors page, find the account where you just added an invoice. In the Creditor details page, under the Invoices tab, you will see all associated invoices with the creditor.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditInvoice/edit-credit-01.png'
    },
    {
        label: `To edit an Credit Invoice, click on the Invoice Id which will redirect you to the Invoice detail page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditInvoice/edit-credit-02.png'
    },
    {
        label: `On Invoice detail page, you can view the details of the invoice or edit them by clicking the Edit icon. After making the necessary changes, click the 'Save' icon to submit the updates. Currently you can only edit the Document Reference, the Document Date, the Document Due Date, the GST Type and the Site.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/creditInvoice/edit-credit-03.png'
    }
];
