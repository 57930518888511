import DocumentationPage from '../DocumentationPage';
import {
    AddVehicleInvoiceSteps,
    EditVehicleInvoiceSteps
    // PayVehicleInvoiceSteps
} from '../data/vehicleSales/invoiceVehicles';

const InvoiceVehiclesSupportPage = () => {
    const contentSections = [
        {
            id: 'add-vehicle-invoice',
            title: 'Add new Vehicle Invoice',
            href: '/support/vehicles/invoiceVehicles/#add-vehicle-invoice',
            description:
                'To create a new Vehicle Invoice, follow these steps :',
            steps: AddVehicleInvoiceSteps
        },
        {
            id: 'edit-vehicle-invoice',
            title: 'Edit Vehicle Invoice',
            href: '/support/vehicles/invoiceVehicles/#edit-vehicle-invoice',
            description:
                'To view or edit existing Vehicle Invoice, follow these steps :',
            steps: EditVehicleInvoiceSteps
        },
        {
            id: 'pay-vehicle-invoice',
            title: 'Pay Vehicle Invoice',
            href: '/support/vehicles/invoiceVehicles/#pay-vehicle-invoice',
            description: (
                <>
                    Vehicle Invoices are paid through Trust Payment, go to Admin
                    - Enter Invoice - Trust Receipt. For detailed instructions
                    on entering a trust receipt invoice, refer to{' '}
                    <a
                        href="/support/admin/enterInvoice/#enter-trust-receipt-invoice"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="useful-link"
                    >
                        Enter Trust Receipt Invoice
                    </a>{' '}
                    documentation.
                </>
            )
            // steps: PayVehicleInvoiceSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Invoice vehicles"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default InvoiceVehiclesSupportPage;
