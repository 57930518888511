// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Divider, Grid, TextField, Typography } from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
// INTERFACES
import { Customer } from '../../../../global/interfaces/GeneralInterface';

/**
 * BasicProps
 * Props sent through from Details.tsx
 */
interface BasicProps {
  customerInfo: Customer; // The customer's data
  setCustomerInfo: Dispatch<SetStateAction<Customer>>; // State setter for customerInfo
  disabled: boolean; // 'read-only' check
}

/**
 * AddressDetails
 * UI for a customer's address details in ViewCustomer's 'DETAILS' tab
 * @author Estienne
 * @param BasicProps
 * @returns The customer's address
 */
const AddressDetails = ({
  customerInfo,
  setCustomerInfo,
  disabled
}: BasicProps) => {
  return (
    <Paper>
      <Typography variant="h6">Address Details</Typography>
      <Divider />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            fullWidth
            size="small"
            label="Address Line 1"
            value={customerInfo?.addressLine1}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setCustomerInfo({
                ...customerInfo,
                addressLine1: e.target.value
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            fullWidth
            size="small"
            label="Address Line 2"
            value={customerInfo?.addressLine2}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setCustomerInfo({
                ...customerInfo,
                addressLine2: e.target.value
              });
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            disabled={disabled}
            fullWidth
            size="small"
            label="Suburb"
            value={customerInfo?.suburb}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setCustomerInfo({ ...customerInfo, suburb: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={disabled}
            fullWidth
            size="small"
            label="Postcode"
            value={customerInfo?.postcode}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setCustomerInfo({ ...customerInfo, postcode: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={disabled}
            fullWidth
            size="small"
            label="State"
            value={customerInfo?.state}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setCustomerInfo({ ...customerInfo, state: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={disabled}
            fullWidth
            size="small"
            label="Country"
            value={customerInfo?.country}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setCustomerInfo({ ...customerInfo, country: e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddressDetails;
