// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';

const GetUnpaidTrusts = (
  setUnpaidTrusts: Dispatch<SetStateAction<Vehicle[]>>
) => {
  api.get('unpaidTrusts').then((res) => {
    setUnpaidTrusts(res.data);
  });
};

export default GetUnpaidTrusts;
