import { GeneralJournalLineTemp } from "../GeneralJournalUI";

const TotalColumn = (entryLines: GeneralJournalLineTemp[], field: string) => {
    var total = 0;
    entryLines.forEach(row => {
        total += Number.isNaN(row[field]) ? 0 : typeof(row[field]) == 'string' ? parseFloat(row[field]) : row[field]
    })
    return total;
}   

export default TotalColumn;