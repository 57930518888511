// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Creditor } from '../interfaces/GeneralInterface';
// LOGIC
import api from '../../../../api';

const GetCreditor = (
  id: string,
  setCreditorDetails: Dispatch<SetStateAction<Creditor>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setInvalid?: Dispatch<SetStateAction<boolean>>
) => {
  setLoading(true);
  api.get(`creditorNextGen/${id}`).then((res) => {
    if (res.status === 204 && setInvalid) {
      setInvalid(true);
    }
    setCreditorDetails(res.data);
    setLoading(false);
  });
};

export default GetCreditor;
