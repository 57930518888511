// IMPORTS
import { useEffect, useState } from 'react';
import { Paper, TablePagination, TableRow, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
// LOGIC
import GetSales from '../logic/GetSales';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import { DateFormatter } from '../../../global/logic/Formatters';
import {
    ServiceTotal,
    TotalAmountPaid
} from '../../../service/servicePayment/logic/ServicePaymentCalculations';
import { SaleTotal, TotalPayments } from '../logic/VehicleSaleCalculations';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { VehicleSale } from '../../../global/interfaces/VehicleSaleInterface';

/**
 * SalesTab
 * UI content shown in the 'SALES/PROFORMAS' tab in ViewCustomer
 * @author Estienne
 * @returns The customer's part, service and vehicle sale details
 */
const SalesTab = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(true);

    // Parts table & pagination variables
    const [partSales, setPartSales] = useState<any>();
    const [partsPage, setPartsPage] = useState<Order[]>();
    const [partsPageNum, setPartsPageNum] = useState(0);
    const [numParts, setNumParts] = useState(0);

    // Services table & pagination variables
    const [services, setServices] = useState<any>();
    const [servicesPage, setServicesPage] =
        useState<[{ service: Service; orderData: Order[] }]>();
    const [servicesPageNum, setServicesPageNum] = useState(0);
    const [numServices, setNumServices] = useState(0);

    // Vehicle Sales table and pagination variables
    const [vehicleSales, setVehicleSales] = useState<any>();
    const [vehicleSalesPage, setVehicleSalesPage] = useState<VehicleSale[]>();
    const [vehicleSalesPageNum, setVehicleSalesPageNum] = useState(0);
    const [numVehicleSales, setNumVehicleSales] = useState(0);

    // Column headings
    const columnHeadings = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'User' },
        { id: 2, label: 'Status' },
        { id: 3, label: 'Site' },
        { id: 4, label: 'Total' },
        { id: 5, label: 'Paid' },
        { id: 6, label: 'Owed' },
        { id: 7, label: 'Date' }
    ];

    /**
     * useEffect
     * Get the customer's sales data
     * @author Sienna
     */
    useEffect(() => {
        GetSales(
            id,
            setPartSales,
            setPartsPage,
            setNumParts,
            setServices,
            setServicesPage,
            setNumServices,
            setVehicleSales,
            setVehicleSalesPage,
            setNumVehicleSales,
            setLoading
        );
    }, [id]);

    /**
     * handlePageChange
     * Pagination functionality for each sales table
     * @author Sienna
     * @param event the on-click event
     * @param newPage the new table page to be shown
     * @param department the department to check which table's page needs changing
     */
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
        department: string
    ) => {
        if (department === 'Parts') {
            setPartsPageNum(newPage);
            setPartsPage(partSales[newPage]);
        } else if (department === 'Service') {
            setServicesPageNum(newPage);
            setServicesPage(services[newPage]);
        } else if (department === 'VehicleSales') {
            setVehicleSalesPageNum(newPage);
            setVehicleSalesPage(vehicleSales[newPage]);
        }
    };

    return (
        <>
            <Paper className="paper-padding">
                <Typography variant="h5" align="left">
                    Parts
                </Typography>
                {!loading && numParts > 0 ? (
                    <DataTable columns={columnHeadings}>
                        {partsPage?.map((row) => (
                            <TableRow key={row.id}>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            '/inventory/viewSale/' + row.id,
                                            '_blank'
                                        )
                                    }
                                >
                                    {row.id}
                                </DataCellColoured>
                                <DataCell>
                                    {row['User.firstName']}{' '}
                                    {row['User.lastName']}
                                </DataCell>
                                <DataCell>{row?.status}</DataCell>
                                <DataCell>{row['Site.name']}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        parseFloat(row.amountPaid.toString()) +
                                            parseFloat(
                                                row.amountOwed.toString()
                                            )
                                    )}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(row?.amountPaid)}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(row?.amountOwed)}
                                </DataCell>
                                <DataCell>
                                    {DateFormatter(row?.createdAt)}
                                </DataCell>
                            </TableRow>
                        ))}
                        <TablePagination
                            count={numParts}
                            onPageChange={(e, newPage) =>
                                handleChangePage(e, newPage, 'Parts')
                            }
                            page={partsPageNum}
                            rowsPerPage={10}
                            rowsPerPageOptions={[]}
                        />
                    </DataTable>
                ) : (
                    <Typography align="left">
                        This customer does not have any part sales.
                    </Typography>
                )}
            </Paper>
            <br></br>
            <Paper className="paper-padding">
                <Typography variant="h5" align="left">
                    Services
                </Typography>
                {!loading && numServices > 0 ? (
                    <DataTable columns={columnHeadings}>
                        {servicesPage?.map((row) => (
                            <TableRow key={row.service.id}>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            '/service/viewBooking/' +
                                                row.service.id,
                                            '_blank'
                                        )
                                    }
                                >
                                    {row.service.id}
                                </DataCellColoured>
                                <DataCell>
                                    {row.service['User.firstName']}{' '}
                                    {row.service['User.lastName']}
                                </DataCell>
                                <DataCell>
                                    {row.service.serviceComplete}
                                </DataCell>
                                <DataCell>{row.service['Site.name']}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        ServiceTotal(row.service, row.orderData)
                                    )}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        TotalAmountPaid(
                                            row.service,
                                            row.orderData
                                        )
                                    )}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        ServiceTotal(
                                            row.service,
                                            row.orderData
                                        ) -
                                            TotalAmountPaid(
                                                row.service,
                                                row.orderData
                                            )
                                    )}
                                </DataCell>
                                <DataCell>
                                    {DateFormatter(row.service.createdAt)}
                                </DataCell>
                            </TableRow>
                        ))}
                        <TablePagination
                            count={numServices}
                            onPageChange={(e, newPage) =>
                                handleChangePage(e, newPage, 'Service')
                            }
                            page={servicesPageNum}
                            rowsPerPage={10}
                            rowsPerPageOptions={[]}
                        />
                    </DataTable>
                ) : (
                    <Typography align="left">
                        This customer does not have any service sales.
                    </Typography>
                )}
            </Paper>
            <br></br>
            <Paper className="paper-padding">
                <Typography variant="h5" align="left">
                    Vehicle Sales
                </Typography>
                {!loading && numVehicleSales > 0 ? (
                    <DataTable columns={columnHeadings}>
                        {vehicleSalesPage?.map((row) => (
                            <TableRow key={row.id}>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            '/vehicles/viewContract/' + row.id,
                                            '_blank'
                                        )
                                    }
                                >
                                    {row.id}
                                </DataCellColoured>
                                <DataCell>
                                    {row['User.firstName']}{' '}
                                    {row['User.lastName']}
                                </DataCell>
                                <DataCell>{row.saleStatus}</DataCell>
                                <DataCell>{row['Site.name']}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(SaleTotal(row))}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(TotalPayments(row))}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        SaleTotal(row) - TotalPayments(row)
                                    )}
                                </DataCell>
                                <DataCell>
                                    {row.saleStatus === 'Sold'
                                        ? DateFormatter(row.saleDate)
                                        : DateFormatter(row.quoteDate)}
                                </DataCell>
                            </TableRow>
                        ))}
                        <TablePagination
                            count={numVehicleSales}
                            onPageChange={(e, newPage) =>
                                handleChangePage(e, newPage, 'VehicleSales')
                            }
                            page={vehicleSalesPageNum}
                            rowsPerPage={10}
                            rowsPerPageOptions={[]}
                        />
                    </DataTable>
                ) : (
                    <Typography align="left">
                        This customer does not have any vehicle sales.
                    </Typography>
                )}
            </Paper>
        </>
    );
};

export default SalesTab;
