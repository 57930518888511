import api from "../../../../../api";

// Get the details of a given stock order and prefill the invoice with the lines
const GetMultipleStockOrder = async (StockOrderIds: number[], selectedSite: number) => {
    let returnArray = [];
    await api.get(`multipleStockOrderDetails?StockOrderIds=${StockOrderIds}&SiteId=${selectedSite}`).then((res) => {

        for (let stockOrder of res.data) {
            let detailedLines = stockOrder.detailedLines;
            let orderLines = [];
            let linkedOrders = [];

            // Go throught the stock order lines and create invoice lines for each of them
            for (let line of detailedLines) {
                let backorders = line.backorders;
                backorders.forEach((order) => {
                    linkedOrders.push(order);
                });

                let newLine = {
                    id: orderLines.length,
                    UnitId: line.unit.id,
                    partNumber: line.unit.partNumber,
                    name: line.unit.name,
                    costOnFile: line.unit.costPriceDaily,
                    itemPrice: null,
                    lineTotal: null,
                    quantityOrdered: line.order.quantity,
                    quantityReceived: null,
                    customerBackorders: backorders,
                    quantityError: false,
                    priceError: false,
                    printLabel: true,
                    isPack: line.unit.canBePack ? line.unit.canBePack : false,
                    packQty: line.unit.canBePack ? '0' : null,
                    gstFree: line.unit.gstFree,
                    priceRRP: line.unit.priceRRP,
                    binLocation: line.unit?.BinLocationIds[0]?.name,
                    addedPart: false,
                    removed: false,
                    quantityInvoiced: line.order.qtyInvoiced,
                    invoiced: line.order.invoiced ? line.order.invoiced : false
                };

                orderLines.push(newLine);
            }
            returnArray.push({
                StockOrderId: stockOrder.stockOrder.id,
                orderLines: orderLines
            });
        }
    });
    return returnArray;
};

const ImportMultipleStockOrder = (id, invoiceData, selectedStockOrders, selectedSite, setIsMultipleInvoice, setMultipleInvoiceData, setImportStockOrderDialogOpen) => {
    let newInvoiceData = JSON.parse(JSON.stringify(invoiceData));
    delete newInvoiceData.orderLines;

    let newOrderLines = [{ StockOrderId: parseInt(id), orderLines: invoiceData.orderLines }];

    GetMultipleStockOrder(selectedStockOrders, selectedSite).then((returnArray) => {
        for (let stockOrder of returnArray) {
            newOrderLines.push(stockOrder);
        }

        newInvoiceData.orderLines = newOrderLines;
        setIsMultipleInvoice(true);
        setMultipleInvoiceData(newInvoiceData);
        setImportStockOrderDialogOpen(false)
    });
};

export default ImportMultipleStockOrder;
