// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Invoice } from '../../../global/interfaces/PartsInterface';

// Gets all the invoices with a given status (open, closed, credit)
const GetAllInvoices = (
  CreditorId: number,
  invoiceType: string,
  setInvoices: Dispatch<SetStateAction<Invoice[]>>
) => {
  api
    .get(`getAllCreditorInvoices/${CreditorId}?invoiceType=${invoiceType}`)
    .then((res) => {
      setInvoices(res.data);
    });
};

export default GetAllInvoices;
