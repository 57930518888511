import { TableRow } from '@mui/material';
import DataCell from '../../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../../../global/tableComponents/DataTable';
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import dayjs from 'dayjs';

const CommitPNLDrawerContent = (props) => {
    const getLink = (row) => {
        if (props.drawerTitle === 'Parts and Accessories Direct Deposits') {
            return '/inventory/viewSale/' + row.id;
        } else if (props.drawerTitle === 'Vehicle Sales Direct Deposits') {
            return '/vehicles/viewContract/' + row.id;
        } else if (props.drawerTitle === 'Vehicle Sales Rebates') {
            return '/admin/viewRebateInvoice/' + row.id;
        } else if (props.drawerTitle === 'Vehicle Sales Trust Payments') {
            return '/vehicles/view/' + row.VehicleId;
        } else if (props.drawerTitle === 'Service Direct Deposits') {
            return '/service/viewBooking/' + row.id;
        } else if (props.drawerTitle === 'Service Warranties') {
            return '/admin/viewServiceWarrantyInvoice/' + row.id;
        } else if (props.drawerTitle === 'Service Sublets / Purchase Orders') {
            return '/service/purchaseOrders';
        } else if (props.drawerTitle === 'Credit Invoices') {
            return '/admin/creditInvoice/view/' + row.id;
        } else if (props.drawerTitle === 'Stock Invoices') {
            return '/inventory/viewInvoice/' + row.id;
        } else if (props.drawerTitle === 'General Invoices') {
            return '/inventory/viewInvoice/' + row.Invoice.id;
        } else if (props.drawerTitle === 'Vehicle Invoices') {
            return '/inventory/viewInvoice/' + row.Invoice.id;
        } else if (props.drawerTitle === 'Vehicle Registration Invoices') {
            return '/inventory/viewInvoice/' + row.Invoice.id;
        } else if (props.drawerTitle === 'Manual Transactions') {
            return '/admin/viewManualTransaction/' + row.id;
        } else if (props.drawerTitle === 'Debtor Payments') {
            return '/viewCustomer/' + row.CustomerId;
        } else if (props.drawerTitle === 'Trade Ins') {
            return '/vehicles/viewTrade/' + row.CustomerId;
        } else {
            return '';
        }
    };

    const getColumnOne = (row) => {
        if (props.drawerTitle === 'Vehicle Sales Trust Payments') {
            return row.vehicleSale.Vehicle.stockNumber;
        } else if (
            props.drawerTitle === 'General Invoices' ||
            props.drawerTitle === 'Vehicle Invoices' ||
            props.drawerTitle === 'Vehicle Registration Invoices'
        ) {
            return row.Invoice.id;
        } else if (props.drawerTitle === 'Debtor Payments') {
            return row.Customer.id;
        } else if (props.drawerTitle === 'Till Reconciliations') {
            return row.Site.code + ' - ' + row.Till.code;
        } else {
            return row.id;
        }
    };

    const getColumnTwo = (row) => {
        if (props.drawerTitle === 'Parts and Accessories Direct Deposits') {
            return <DataCell>{row.status}</DataCell>;
        } else if (props.drawerTitle === 'Vehicle Sales Direct Deposits') {
            return <DataCell>{row.saleStatus}</DataCell>;
        } else if (props.drawerTitle === 'Vehicle Sales Rebates') {
            return <DataCell>{row.VehicleRebateLines.length}</DataCell>;
        } else if (props.drawerTitle === 'Vehicle Sales Trust Payments') {
            return (
                <DataCellColoured
                    handleClick={() =>
                        window.open(
                            '/vehicles/viewContract/' + row.vehicleSale.id,
                            '_blank'
                        )
                    }
                >
                    {row.vehicleSale.id}
                </DataCellColoured>
            );
        } else if (props.drawerTitle === 'Service Direct Deposits') {
            return <DataCell>{row.serviceComplete}</DataCell>;
        } else if (props.drawerTitle === 'Service Warranties') {
            return <DataCell>{row.ServiceWarrantyLines.length}</DataCell>;
        } else if (props.drawerTitle === 'Service Sublets / Purchase Orders') {
            return (
                <DataCellColoured
                    handleClick={() =>
                        window.open(
                            '/service/viewBooking/' + row.ServiceId,
                            '_blank'
                        )
                    }
                >
                    {row.ServiceId}
                </DataCellColoured>
            );
        } else if (props.drawerTitle === 'Credit Invoices') {
            return <DataCell>{row.Supplier.name}</DataCell>;
        } else if (props.drawerTitle === 'Stock Invoices') {
            return <DataCell>{row.Supplier.name}</DataCell>;
        } else if (props.drawerTitle === 'General Invoices') {
            return <DataCell>{row.Supplier.name}</DataCell>;
        } else if (props.drawerTitle === 'Vehicle Invoices') {
            return <DataCell>{row.Supplier.name}</DataCell>;
        } else if (props.drawerTitle === 'Vehicle Registration Invoices') {
            return <DataCell>{row.Supplier.name}</DataCell>;
        } else if (props.drawerTitle === 'Manual Transactions') {
            return row.Supplier ? (
                <DataCell>{row.Supplier.name}</DataCell>
            ) : row.Account ? (
                <DataCell>{row.Account.name}</DataCell>
            ) : (
                <DataCellColoured
                    handleClick={() =>
                        window.open('/vehicles/view/' + row.VehicleId, '_blank')
                    }
                >
                    Vehicle Stock Number: {row.Vehicle.stockNumber}
                </DataCellColoured>
            );
        } else if (props.drawerTitle === 'Debtor Payments') {
            return (
                <DataCell>
                    {CurrencyFormatter(Math.abs(row.amountDue))}
                </DataCell>
            );
        } else if (props.drawerTitle === 'Trade Ins') {
            return (
                <DataCellColoured
                    handleClick={() =>
                        window.open('/viewCustomer/' + row.CustomerId, '_blank')
                    }
                >
                    {row.Customer.firstName + ' ' + row.Customer.lastName}
                </DataCellColoured>
            );
        } else if (props.drawerTitle === 'Till Reconciliations') {
            return (
                <DataCell>{dayjs(row.createdAt).format('DD-MM-YYYY')}</DataCell>
            );
        } else {
            return '';
        }
    };

    return (
        <DataTable columns={props.columns}>
            {props.drawerContent.map((transaction) => (
                <TableRow>
                    {getLink(transaction) !== '' ? (
                        <DataCellColoured
                            handleClick={() =>
                                window.open(getLink(transaction), '_blank')
                            }
                        >
                            {getColumnOne(transaction)}
                        </DataCellColoured>
                    ) : (
                        <DataCell>{getColumnOne(transaction)}</DataCell>
                    )}
                    {getColumnTwo(transaction)}
                </TableRow>
            ))}
        </DataTable>
    );
};

export default CommitPNLDrawerContent;
