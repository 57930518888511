import { Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Fab from '../../global/Fab';
import DefaultSettings from './components/DefaultSettings';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import GetUserSettings from '../../global/databaseLogic/GetUserSettings';
import HandleUpdateUserSettings from './logic/HandleUpdateUserSettings';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import {
    Till,
    User,
    UserSettings
} from '../../global/interfaces/GeneralInterface';

const UserSettingsPage = ({
    currentUser,
    setCurrentUser,
    showSnackbar
}: {
    currentUser: User;
    setCurrentUser: Dispatch<SetStateAction<User>>;
    showSnackbar: showSnackbar;
}) => {
    const [userSettings, setUserSettings] = useState<UserSettings>({
        TillId: null
    });
    const [userDetails, setUserDetails] = useState<User>({});
    const [tills, setTills] = useState<Till[]>([]);
    const [readOnly, setReadOnly] = useState<boolean>(true);

    useEffect(() => {
        GetUserSettings(setUserSettings, setUserDetails, setTills);
    }, []);

    const handleFabClick = () => {
        if (readOnly) {
            setReadOnly(false);
        } else {
            HandleUpdateUserSettings(userSettings, showSnackbar);
        }
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Personal Settings</Typography>
            <br />

            <DefaultSettings
                userDetails={userDetails}
                userSettings={userSettings}
                setUserSettings={setUserSettings}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                tills={tills}
                readOnly={readOnly}
            />

            <Fab
                noSession={true}
                editIcon={readOnly}
                onClick={() => handleFabClick()}
            />
        </PageWrapper>
    );
};

export default withSnackbar(UserSettingsPage);
