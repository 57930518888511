import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { EmailSignUrl } from '../../signContract/logic/EmailContract';
import { UploadFileToS3 } from '../../../esign/logic/S3FileService';
import { Check } from '@mui/icons-material';

import React, { useState } from 'react';
import { Close } from '@material-ui/icons';

interface UploadResult {
    success: boolean;
    fileName: string;
}

const EmailCustomerDialog = ({
    openEmailDialog,
    setOpenEmailDialog,
    customer,
    filesToUpload,
    showSnackbar
}) => {
    const { id: vehicleId } = useParams<{ id: string }>();

    // State to track upload status of each file
    const [uploadStatus, setUploadStatus] = useState({});

    const baseURL = window.location.origin;
    let customerSignUrl = `${baseURL}/esign/vendor-statement/${vehicleId}`;
    const fileNames = filesToUpload.map((file) => file.fileName);
    const encodedDocs = btoa(JSON.stringify(fileNames));
    customerSignUrl += `?includeDocs=${encodedDocs}`;

    // Function to upload a single file to S3
    const uploadPdfBytes = (file): Promise<UploadResult> => {
        return new Promise((resolve, reject) => {
            const pdfBlob = new Blob([file.pdfBytes], {
                type: 'application/pdf'
            });
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64data = reader.result as string;
                UploadFileToS3({
                    base64data: base64data,
                    folderName: `vehicleTrades/${vehicleId}`,
                    fileName: file.fileName,
                    showSnackbar: showSnackbar
                })
                    .then(() =>
                        resolve({ success: true, fileName: file.fileName })
                    )
                    .catch(() =>
                        reject({ success: false, fileName: file.fileName })
                    );
            };
            reader.readAsDataURL(pdfBlob);
        });
    };

    const handleEmailSignUrl = () => {
        // Convert the promises from uploadPdfBytes into an array
        const uploadPromises = filesToUpload.map((file) => {
            return uploadPdfBytes(file)
                .then((result) => {
                    setUploadStatus((prev) => ({
                        ...prev,
                        [result.fileName]: 'success'
                    }));
                    return result;
                })
                .catch((result) => {
                    setUploadStatus((prev) => ({
                        ...prev,
                        [result.fileName]: 'failed'
                    }));
                    showSnackbar(
                        `Failed to upload ${result.fileName}`,
                        'Please refresh the page and try again',
                        'error'
                    );
                    return result;
                });
        });

        // Wait for all uploads to complete
        Promise.all(uploadPromises).then((results) => {
            // Check if every file has been uploaded successfully
            const allFilesUploaded = results.every((result) => result.success);

            if (!allFilesUploaded) {
                showSnackbar(
                    'Failed to upload files',
                    'Please click the Send Email button again to retry uploading; if the issue persists, please refresh the page and try again',
                    'error'
                );
            } else {
                EmailSignUrl({
                    type: 'vehicle-trade',
                    id: parseInt(vehicleId),
                    customerSignUrl: customerSignUrl,
                    newStatus: 'waitingSignature',
                    showSnackbar: showSnackbar
                });
            }
        });
    };

    const StatusChip = ({ fileName }) => {
        const status = uploadStatus[fileName];
        if (status === 'success') {
            return <Check color="success" />;
        } else if (status === 'failed') {
            return <Close color="error" fontSize="small" />;
        } else {
            return null;
        }
    };

    return (
        <Dialog open={openEmailDialog}>
            <DialogTitle>Email Customer</DialogTitle>
            <DialogContent>
                Once click on Send Email, below documents will be uploaded. An
                email will be sent to <b>{customer?.name}</b> at the following
                email address: <b>{customer?.email}</b> with a link to sign
                below documents:
                <ul>
                    {filesToUpload.map((file) => (
                        <li key={file.fileName}>
                            {file.fileName}{' '}
                            <StatusChip fileName={file.fileName} />
                        </li>
                    ))}
                </ul>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenEmailDialog(false)}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleEmailSignUrl}>
                    Send Email
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default EmailCustomerDialog;