// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Typography,
    Button,
    TextField,
    TableRow,
    Chip,
    Paper,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    MenuItem,
    DialogActions
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import moment from 'moment';

// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import RoleCheck from '../../../global/RoleCheck';
import Filter from './Filter';

// LOGIC
import api from '../../../../../api';
import ConvertArrayToFilter from '../../../global/logic/ConvertArrayToFilter';

// INTERFACE
import { Site, User } from '../../../global/interfaces/GeneralInterface';
import { Lead, Make } from '../../../global/interfaces/VehicleSaleInterface';
import { Dayjs } from 'dayjs';
import LeadTableHeader from './LeadTableHeader';
import HandleAssignedUserChange from '../logic/HandleAssignedUserChange';
interface FilterProps {
    assignedUser: User[];
    enquiryStatus: { type: string }[];
    make: Make[];
    sites: Site[];
    startDate: Dayjs;
    endDate: Dayjs;
}
interface LeadsTableProps {
    filter: FilterProps;
    setFilter?: Dispatch<SetStateAction<FilterProps>>;
    type?: string;
    handleOpenEnquiry?: (id: number, user: User, status: string) => void;
}

const LeadsTable = (props: LeadsTableProps) => {
    let onManagementPage =
        window.location.pathname.split('/')[3] === 'dashboard';
    const [searchString, setSearchString] = useState<string>('');
    const [enquiryList, setEnquiryList] = useState<Lead[]>([]);
    const [selectedEnquiry, setSelectedEnquiry] = useState<Lead>({});
    const [isEnquirySelected, setIsEnquirySelected] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<number>(null);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Customer' },
        { id: 2, label: 'Vehicle' },
        { id: 4, label: 'Submission / Update Date' },
        { id: 5, label: 'Employee' }
    ];

    useEffect(() => {
        api.get('users/department').then((res) => {
            setUsers(res.data);
        });
        if (!window.location.href.includes('dashboard')) {
            props.setFilter({
                ...props.filter,
                enquiryStatus: [{ type: 'ACTIVE' }, { type: 'PENDING' }]
            });
        }
        // eslint-disable-next-line
    }, []);

    const handleCheckboxClick = (row) => {
        let temp = JSON.parse(JSON.stringify(selectedEnquiry));
        if (temp[row.id]) {
            temp[row.id] = false;
        } else {
            temp[row.id] = true;
        }

        setSelectedEnquiry(temp);

        for (let i in temp) {
            if (temp[i] === true) {
                setIsEnquirySelected(true);
                return;
            }
        }
        setIsEnquirySelected(false);
    };

    const handleRequestCreate = () => {
        let enquiryStatus =
            props.filter.enquiryStatus.length > 0
                ? ConvertArrayToFilter({
                      array: props.filter.enquiryStatus,
                      filterStart: '&enquiryStatus=',
                      selector: 'type'
                  })
                : '';
        let assignedUser =
            props.filter.assignedUser.length > 0
                ? ConvertArrayToFilter({
                      array: props.filter.assignedUser,
                      filterStart: '&assignedUser=',
                      selector: 'id'
                  })
                : '';
        let make =
            props.filter.make.length > 0
                ? ConvertArrayToFilter({
                      array: props.filter.make,
                      filterStart: '&make=',
                      selector: 'id'
                  })
                : '';
        let site =
            props.filter.sites.length > 0
                ? ConvertArrayToFilter({
                      array: props.filter.sites,
                      filterStart: '&site=',
                      selector: 'id'
                  })
                : '';
        let startDate = `&startDate=${props.filter.startDate !== null && props.filter.startDate.isValid() ? props.filter.startDate.toISOString() : ''}`;
        let endDate = `&endDate=${props.filter.endDate !== null && props.filter.endDate.isValid() ? props.filter.endDate.toISOString() : ''}`;

        var apiString = `${site}${enquiryStatus}${assignedUser}${make}${startDate}${endDate}`;
        return apiString;
    };

    return (
        <div>
            <LeadTableHeader
                route="leadSearchNextGen"
                searchLabel="Search Leads (Name, Email, Phone Number, Make, Model)"
                searchString={searchString}
                setSearchString={setSearchString}
                setDataList={setEnquiryList}
                SiteId={parseInt(localStorage.getItem('SiteId'))}
                filter={props.filter}
                handleRequestCreate={handleRequestCreate}
                isEnquirySelected={isEnquirySelected}
                setDialogOpen={setDialogOpen}
            >
                <Filter filter={props.filter} setFilter={props.setFilter} />
            </LeadTableHeader>

            {isEnquirySelected ? (
                <Typography align="right">
                    <Button color="primary" onClick={() => setDialogOpen(true)}>
                        Change Employees
                    </Button>
                    <br />
                </Typography>
            ) : (
                <br />
            )}

            <Paper>
                <DataTable columns={columns}>
                    {enquiryList?.map((row) => (
                        <TableRow key={row.id}>
                            {onManagementPage ? (
                                <DataCell>
                                    {row.id} <br />{' '}
                                    <Chip
                                        label={row.status}
                                        color={
                                            row.status === 'ACTIVE' ||
                                            row.status === 'SOLD'
                                                ? 'success'
                                                : row.status === 'PENDING'
                                                  ? 'warning'
                                                  : 'error'
                                        }
                                    />
                                </DataCell>
                            ) : (
                                <DataCellColoured
                                    handleClick={() => {
                                        props.handleOpenEnquiry(
                                            row.id,
                                            row.assignedUser,
                                            row.status
                                        );
                                    }}
                                >
                                    {row.id} <br />{' '}
                                    <Chip
                                        label={row.status}
                                        color={
                                            row.status === 'ACTIVE' ||
                                            row.status === 'SOLD'
                                                ? 'success'
                                                : row.status === 'PENDING'
                                                  ? 'warning'
                                                  : 'error'
                                        }
                                    />
                                </DataCellColoured>
                            )}

                            <DataCell align="left">
                                {row.CustomerId
                                    ? row.Customer.firstName +
                                      ' ' +
                                      row.Customer.lastName
                                    : row.name}
                                {row.phoneNumber ? (
                                    <>
                                        <br />
                                        <b>P:</b> {row.phoneNumber}
                                    </>
                                ) : null}
                                {row.email ? (
                                    <>
                                        <br />
                                        <b>@:</b> {row.email}
                                    </>
                                ) : null}
                            </DataCell>
                            <DataCell align="left">
                                {row?.Make?.name +
                                    ' ' +
                                    (row?.Model?.name ?? '')}
                                {row.idealPickupDate ? (
                                    <>
                                        <br />
                                        <b>Pickup:</b> {row.idealPickupDate}
                                    </>
                                ) : null}
                                <br />
                                <Typography variant="caption">
                                    {row['Site.name']}
                                </Typography>
                            </DataCell>
                            <DataCell align="left">
                                <b>Submitted:</b>{' '}
                                {moment(row.createdAt).format('DD/MM/YYYY')}
                                <br />
                                {row.lastUpdatedBy ? (
                                    <Typography variant="body2">
                                        <b>Updated:</b>{' '}
                                        {moment(row.updatedAt).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </Typography>
                                ) : null}
                            </DataCell>
                            <DataCell>
                                {row.assignedUser.firstName +
                                    ' ' +
                                    row.assignedUser.lastName}
                                <RoleCheck
                                    component={
                                        <>
                                            <br />
                                            <Checkbox
                                                checked={
                                                    selectedEnquiry[row.id]
                                                }
                                                onChange={() => {
                                                    handleCheckboxClick(row);
                                                }}
                                            />
                                        </>
                                    }
                                    nullComponent={null}
                                    permission="access_leadManager"
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Dialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>Change Assigned Users</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        fullWidth
                        label="Assign selected enquiries to:"
                        size="small"
                        value={selectedUser}
                        onChange={(event) => {
                            setSelectedUser(parseInt(event.target.value));
                        }}
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginTop: 0.6 }}
                    >
                        {users.map((option) =>
                            option.department === 'sales' ? (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.id} - {option.username}
                                </MenuItem>
                            ) : null
                        )}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setDialogOpen(false);
                        }}
                    >
                        Close
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() => {
                            HandleAssignedUserChange(
                                selectedUser,
                                selectedEnquiry,
                                setButtonLoading
                            );
                        }}
                    >
                        Assign Enquiries
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LeadsTable;
