import { Dispatch, SetStateAction } from 'react';
import {
    StockInvoice,
    StockOrder
} from '../../../global/interfaces/PartsInterface';
import api from '../../../../../api';

// Get the details of a given stock order and prefill the invoice with the lines
const GetStockOrderFromSupplier = (
    id: number,
    invoiceData: StockInvoice,
    setListOfStockOrder: Dispatch<SetStateAction<StockOrder[]>>,
    setImportStockOrderDialogOpen: Dispatch<SetStateAction<boolean>>,
    setLoading: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    api.get(
        `allIncompleteStockOrders?SiteId=${invoiceData.SiteId}&SupplierId=${invoiceData.SupplierId}`
    ).then((res) => {
        setListOfStockOrder(res.data.filter((x) => x.id !== id));
        setLoading(false);
        setImportStockOrderDialogOpen(true);
    });
};

export default GetStockOrderFromSupplier;
