import RegenerateHondaOrderFile from './RegenerateHondaOrderFile';
import RegenerateYamahaOrderFile from './RegenerateYamahaOrderFile';
import RegenerateKawasakiOrderFile from './RegenerateKawasakiOrderFile';

import { StockOrder } from '../../../../global/interfaces/PartsInterface';

const RegenerateStockOrderFileRouter = (stockOrder: StockOrder) => {
    if (stockOrder.Api.OrderFileGeneratorId === 1) {
        RegenerateHondaOrderFile(stockOrder);
    } else if (stockOrder.Api.OrderFileGeneratorId === 2) {
        RegenerateYamahaOrderFile(stockOrder);
    } else if (stockOrder.Api.OrderFileGeneratorId === 3) {
        RegenerateKawasakiOrderFile(stockOrder);
    }
};

export default RegenerateStockOrderFileRouter;
