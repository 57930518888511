import ValidationIPWhistelist from './ValidationIPWhitelist';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const setSnackbarAndReload = (
    newLines,
    updatedLines,
    deletedLines,
    snackbarMessage,
    snackbarSeverity,
    showSnackbar: showSnackbar
) => {
    showSnackbar(
        snackbarSeverity === 'success'
            ? `${newLines.length > 0 ? 'Creation' : ''}${updatedLines.length > 0 ? (newLines.length > 0 ? '/Update' : 'Update') : ''}${
                  deletedLines.length > 0
                      ? newLines.length > 0 || updatedLines.length > 0
                          ? '/Deletion'
                          : 'Deletion'
                      : ''
              } of IP Whitelist range is successful.`
            : snackbarMessage === 'warning'
              ? `${newLines.length > 0 ? 'Creation' : ''}${updatedLines.length > 0 ? (newLines.length > 0 ? '/Update' : 'Update') : ''}${
                    deletedLines.length > 0
                        ? newLines.length > 0 || updatedLines.length > 0
                            ? '/Deletion'
                            : 'Deletion'
                        : ''
                } of IP Whitelist range is partially successful.`
              : `${newLines.length > 0 ? 'Creation' : ''}${updatedLines.length > 0 ? (newLines.length > 0 ? '/Update' : 'Update') : ''}${
                    deletedLines.length > 0
                        ? newLines.length > 0 || updatedLines.length > 0
                            ? '/Deletion'
                            : 'Deletion'
                        : ''
                } of IP Whitelist range is unsuccessful.`,
        snackbarMessage,
        snackbarSeverity
    );
    if (snackbarSeverity === 'success') {
        window.location.reload();
    }
};

const SubmitIPWhitelist = (ipData, setIpData, showSnackbar: showSnackbar) => {
    let errorInData = ValidationIPWhistelist(ipData, setIpData);
    if (errorInData) {
        showSnackbar(
            'There is some issue with your data.',
            'Please check your input again and make sure everything is correctly filled out.',
            'error'
        );
        return;
    }

    // SEPARATE NEW LINE AND UPDATED LINE
    let newLines = [];
    let updatedLines = [];
    let deletedLines = [];
    for (let line of ipData) {
        if (line.newLine) {
            newLines.push(line);
        } else if (line.deleted && line.id) {
            deletedLines.push(line.id);
        } else if (line.updated) {
            updatedLines.push(line);
        }
    }

    // POST AND PUT
    let snackbarMessage = '';
    let snackbarSeverity = 'success';
    if (newLines.length > 0) {
        api.post('IPWhitelist', newLines).then((res) => {
            if (res.status === 200) {
                snackbarMessage = `${newLines.length} New IP Whitelist range(s) successfully created.`;
            } else {
                snackbarMessage =
                    'New IP Whitelist range(s) creation is unsuccessful.';
                snackbarSeverity = 'warning';
            }

            if (
                updatedLines.length > 0 &&
                updatedLines.filter((x) => x.updated).length > 0
            ) {
                api.put('IPWhitelist', updatedLines).then((res) => {
                    if (res.status === 200) {
                        snackbarMessage =
                            snackbarMessage.length > 0
                                ? `${snackbarMessage}\n${updatedLines.length} IP Whitelist range(s) successfully updated.`
                                : `${updatedLines.length} IP Whitelist range(s) successfully updated.`;
                    } else {
                        snackbarSeverity =
                            snackbarSeverity === 'warning'
                                ? 'error'
                                : 'warning';
                        snackbarMessage =
                            snackbarMessage.length > 0
                                ? `${snackbarMessage}\nExisting IP Whitelist range(s) update is unsuccessful.`
                                : `Existing IP Whitelist range(s) update is unsuccessful.`;
                    }

                    if (deletedLines.length > 0) {
                        api.delete(
                            'IPWhitelist?ids=' + deletedLines.toString()
                        ).then((res) => {
                            if (res.status === 200) {
                                snackbarMessage =
                                    snackbarMessage.length > 0
                                        ? `${snackbarMessage}\n${deletedLines.length} IP Whitelist range(s) successfully deleted.`
                                        : `${deletedLines.length} IP Whitelist range(s) successfully deleted.`;
                            } else {
                                snackbarSeverity =
                                    snackbarSeverity === 'warning'
                                        ? 'error'
                                        : 'warning';
                                snackbarMessage =
                                    snackbarMessage.length > 0
                                        ? `${snackbarMessage}\nIP Whitelist range(s) deletion is unsuccessful.`
                                        : `IP Whitelist range(s) deletion is unsuccessful.`;
                            }
                            setSnackbarAndReload(
                                newLines,
                                updatedLines,
                                deletedLines,
                                snackbarMessage,
                                snackbarSeverity,
                                showSnackbar
                            );
                        });
                    } else {
                        setSnackbarAndReload(
                            newLines,
                            updatedLines,
                            deletedLines,
                            snackbarMessage,
                            snackbarSeverity,
                            showSnackbar
                        );
                    }
                });
            } else if (deletedLines.length > 0) {
                api.delete('IPWhitelist?ids=' + deletedLines.toString()).then(
                    (res) => {
                        if (res.status === 200) {
                            snackbarMessage =
                                snackbarMessage.length > 0
                                    ? `${snackbarMessage}\n${deletedLines.length} IP Whitelist range(s) successfully deleted.`
                                    : `${deletedLines.length} IP Whitelist range(s) successfully deleted.`;
                        } else {
                            snackbarSeverity =
                                snackbarSeverity === 'warning'
                                    ? 'error'
                                    : 'warning';
                            snackbarMessage =
                                snackbarMessage.length > 0
                                    ? `${snackbarMessage}\nIP Whitelist range(s) deletion is unsuccessful.`
                                    : `IP Whitelist range(s) deletion is unsuccessful.`;
                        }
                        setSnackbarAndReload(
                            newLines,
                            updatedLines,
                            deletedLines,
                            snackbarMessage,
                            snackbarSeverity,
                            showSnackbar
                        );
                    }
                );
            } else {
                setSnackbarAndReload(
                    newLines,
                    updatedLines,
                    deletedLines,
                    snackbarMessage,
                    snackbarSeverity,
                    showSnackbar
                );
            }
        });
    } else if (
        updatedLines.length > 0 &&
        updatedLines.filter((x) => x.updated).length > 0
    ) {
        api.put('IPWhitelist', updatedLines).then((res) => {
            if (res.status === 200) {
                snackbarMessage =
                    snackbarMessage.length > 0
                        ? `${snackbarMessage}\n${updatedLines.length} IP Whitelist range(s) successfully updated.`
                        : `${updatedLines.length} IP Whitelist range(s) successfully updated.`;
            } else {
                snackbarSeverity =
                    snackbarSeverity === 'warning' ? 'error' : 'warning';
                snackbarMessage =
                    snackbarMessage.length > 0
                        ? `${snackbarMessage}\nExisting IP Whitelist range(s) update is unsuccessful.`
                        : `Existing IP Whitelist range(s) update is unsuccessful.`;
            }
            if (deletedLines.length > 0) {
                api.delete('IPWhitelist?ids=' + deletedLines.toString()).then(
                    (res) => {
                        if (res.status === 200) {
                            snackbarMessage =
                                snackbarMessage.length > 0
                                    ? `${snackbarMessage}\n${deletedLines.length} IP Whitelist range(s) successfully deleted.`
                                    : `${deletedLines.length} IP Whitelist range(s) successfully deleted.`;
                        } else {
                            snackbarSeverity =
                                snackbarSeverity === 'warning'
                                    ? 'error'
                                    : 'warning';
                            snackbarMessage =
                                snackbarMessage.length > 0
                                    ? `${snackbarMessage}\nIP Whitelist range(s) deletion is unsuccessful.`
                                    : `IP Whitelist range(s) deletion is unsuccessful.`;
                        }
                        setSnackbarAndReload(
                            newLines,
                            updatedLines,
                            deletedLines,
                            snackbarMessage,
                            snackbarSeverity,
                            showSnackbar
                        );
                    }
                );
            } else {
                setSnackbarAndReload(
                    newLines,
                    updatedLines,
                    deletedLines,
                    snackbarMessage,
                    snackbarSeverity,
                    showSnackbar
                );
            }
        });
    } else if (deletedLines.length > 0) {
        api.delete('IPWhitelist?ids=' + deletedLines.toString()).then((res) => {
            if (res.status === 200) {
                snackbarMessage =
                    snackbarMessage.length > 0
                        ? `${snackbarMessage}\n${deletedLines.length} IP Whitelist range(s) successfully deleted.`
                        : `${deletedLines.length} IP Whitelist range(s) successfully deleted.`;
            } else {
                snackbarSeverity =
                    snackbarSeverity === 'warning' ? 'error' : 'warning';
                snackbarMessage =
                    snackbarMessage.length > 0
                        ? `${snackbarMessage}\nIP Whitelist range(s) deletion is unsuccessful.`
                        : `IP Whitelist range(s) deletion is unsuccessful.`;
            }
            setSnackbarAndReload(
                newLines,
                updatedLines,
                deletedLines,
                snackbarMessage,
                snackbarSeverity,
                showSnackbar
            );
        });
    }
};

export default SubmitIPWhitelist;
