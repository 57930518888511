import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../../../api';
import { showSnackbar } from '../../../../../global/interfaces/GlobalInterface';
import { Dayjs } from 'dayjs';

const CommitPNL = (
    month: Dayjs,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    api.post('commitPNL', { month: month }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'PNL successfully committed.',
                `For: ${month.format('MMM YYYY')}`
            );
        } else {
            showSnackbar(
                'Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
        setButtonLoading(false);
    });
};

export default CommitPNL;
