// IMPORTS
import { useState, useEffect, useContext } from 'react';
import {
    Typography,
    Grid,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    TableRow
} from '@mui/material';
import { SiteContext } from '../../../main/Main';
import { useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// COMPONENTS
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import Paper from '../../global/Paper';
import PageWrapper from '../../global/PageWrapper';
// LOGIC
import GetVehicleInvoiceDetails from '../../global/databaseLogic/GetVehicleInvoiceDetails';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../global/logic/Formatters';
// INTERFACE
import {
    VehicleInvoice,
    VehicleInvoiceLine
} from '../../global/interfaces/VehicleSaleInterface';
import { Site } from '../../global/interfaces/GeneralInterface';

const ViewVehicleInvoice = () => {
    let { id } = useParams<{ id: string }>();
    const [invoiceDetails, setInvoiceDetails] = useState<VehicleInvoice>({});
    const [invoiceLines, setInvoiceLines] = useState<VehicleInvoiceLine[]>([]);
    const siteData = useContext<Site>(SiteContext);

    var columns =
        siteData.vehicleTypeSold === 'Motorcycles'
            ? [
                  { id: 0, label: 'Stock Number', width: 100 },
                  { id: 1, label: 'Vehicle', width: 200 },
                  { id: 2, label: 'VIN', width: 100 },
                  { id: 3, label: 'Base Cost (Ex. GST)', width: 100 },
                  { id: 4, label: 'GST', width: 100 },
                  { id: 5, label: 'Freight', width: 100 }
              ]
            : [
                  { id: 0, label: 'Vehicle', width: 300 },
                  { id: 1, label: 'Year', width: 100 },
                  { id: 3, label: 'Base Cost', width: 100 },
                  { id: 4, label: 'GST', width: 100 },
                  { id: 5, label: 'Freight', width: 100 },
                  { id: 6, label: '', width: 50 }
              ];

    useEffect(() => {
        GetVehicleInvoiceDetails(id, setInvoiceDetails, setInvoiceLines);
    }, [id]);

    return (
        <PageWrapper>
            <Typography variant="h4" align="left">
                Vehicle Invoice #{invoiceDetails?.id}
            </Typography>
            <Typography variant="caption">
                Entered by {invoiceDetails?.User?.firstName}{' '}
                {invoiceDetails?.User?.lastName} -{' '}
                {DateFormatter(invoiceDetails?.createdAt)}
            </Typography>
            <br />
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            label="Document Reference"
                            InputLabelProps={{ shrink: true }}
                            value={invoiceDetails.documentReference}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Document Date"
                                disabled
                                value={invoiceDetails.invoiceDate}
                                // onChange={(newValue) => {
                                //     setInvoiceDate(newValue);
                                // }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Document Total"
                            InputLabelProps={{ shrink: true }}
                            disabled
                            value={CurrencyFormatter(
                                invoiceDetails.documentTotal
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            label="GST"
                            InputLabelProps={{ shrink: true }}
                            value={CurrencyFormatter(
                                invoiceDetails.gstMethod === 'Excluding'
                                    ? (invoiceDetails.userTotal * 1.1) / 11
                                    : invoiceDetails.documentTotal
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <RadioGroup
                                row
                                value={invoiceDetails.gstMethod ?? null}
                            >
                                <FormControlLabel
                                    value="Including"
                                    control={<Radio disabled />}
                                    label="INC. GST"
                                />
                                <FormControlLabel
                                    value="Excluding"
                                    control={<Radio disabled />}
                                    label="EX. GST"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Paper>
                {invoiceLines.length > 0 ? (
                    <DataTable columns={columns}>
                        {invoiceLines.map((line) => (
                            <TableRow>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            '/vehicles/view/' +
                                                line.VehicleId.toString(),
                                            '_blank'
                                        )
                                    }
                                >
                                    {line.Vehicle.stockNumber}
                                </DataCellColoured>
                                <DataCell>
                                    {line.Vehicle.year}{' '}
                                    {line.Vehicle.Make?.name}{' '}
                                    {line.Vehicle.Model?.name}{' '}
                                    {line.Vehicle.Series?.name}
                                </DataCell>
                                <DataCell>{line.Vehicle.vin}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.Vehicle.costPrice)}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(
                                        (parseFloat(line.Vehicle.costPrice) *
                                            1.1) /
                                            11
                                    )}
                                </DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.freight)}
                                </DataCell>
                            </TableRow>
                        ))}
                    </DataTable>
                ) : (
                    <Typography>
                        There isn't any vehicle linked to this invoice.
                    </Typography>
                )}
            </Paper>
        </PageWrapper>
    );
};

export default ViewVehicleInvoice;
