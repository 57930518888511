// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Template } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const GetTemplate = (
    id: number,
    type: string,
    setTemplate: Dispatch<SetStateAction<Template>>,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    api.get(`template/${id}?type=${type}`).then((res) => {
        if (res.status === 404) {
            // Template not found
            showSnackbar(
                "Whoops! The template you are looking for doesn't exist!",
                'Head back to the search to find the one you are looking for!',
                'error'
            );
        } else if (res.status === 200) {
            // Success
            setTemplate(res.data.template);
            setReadOnly(true);
        } else {
            // Server error
            showSnackbar(
                'Whoops! Something is wrong here!',
                'Please contact your IT department to get this issue resolved.',
                'error'
            );
        }
    });
};

export default GetTemplate;
