import { Dispatch, SetStateAction } from "react";
import { Table } from "../interfaces/StockOrderInterface"

const HandleRemoveLine = (tables: Table[], setTables: Dispatch<SetStateAction<Table[]>>, tableId: number, lineId: number) => {
    var tableIndex = tables.findIndex(x => x.tableId === tableId);
    let rows = [...tables];
    let table = { ...rows[tableIndex] };

    if (table.orderLines.length === 1) {
        setTables(tables.filter(x => x.tableId !== tableId));
    } else {
        rows[tableIndex].orderLines = table.orderLines.filter(line => line.rowId !== lineId)
        setTables(rows)
    }
}

export default HandleRemoveLine;