// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Paper,
    CircularProgress,
    Typography,
    Table,
    TableRow,
    TableCell,
    Divider,
    Drawer,
    Box,
    TableHead,
    IconButton
} from '@mui/material';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import { PriorityHigh } from '@mui/icons-material';
import { Dayjs } from 'dayjs';

// COMPONENTS
import ParametersSelection from '../ParametersSelection';

// LOGIC
import CalculateGSTReporting from './logic/CalculateGSTReporting';
import { Site } from '../../../../global/interfaces/GeneralInterface';

interface GSTReportingProps {
    (props: {
        selectedSite: Site[];
        setSelectedSite: Dispatch<SetStateAction<Site[]>>;
        queryDate: { startDate: Dayjs; endDate: Dayjs };
        setQueryDate: Dispatch<
            SetStateAction<{ startDate: Dayjs; endDate: Dayjs }>
        >;
        loading: boolean;
        setLoading: Dispatch<SetStateAction<boolean>>;
    }): JSX.Element;
}

const GSTReporting: GSTReportingProps = (props) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [drawerTitle, setDrawerTitle] = useState<string>('');
    const [selectedTransactions, setSelectedTransactions] = useState<
        {
            customerGstFree: boolean;
            customerInternational: boolean;
            customerName: string;
            description: string;
            finalisedDate: string;
            gstAmount: number;
            id: number;
            link: string;
            reference: string;
            subReference: string;
            totalIncGst: number;
            gstFreeParts: { gstFreePart: string; partPrice: number }[];
        }[]
    >([]);
    const [gstInformation, setGstInformation] = useState<
        {
            description: string;
            total: number;
            transactions: {
                customerGstFree: boolean;
                warningFlag?: boolean;
                gstFreeParts: { gstFreePart: string; partPrice: number }[];
                customerInternational: boolean;
                customerName: string;
                description: string;
                finalisedDate: string;
                gstAmount: number;
                id: number;
                link: string;
                reference: string;
                subReference: string;
                totalIncGst: number;
            }[];
            warningCounter: number;
        }[]
    >([
        {
            description: 'G1 - Total Sales (Inc. GST)',
            total: 0,
            transactions: [],
            warningCounter: 0
        },
        {
            description: 'G2 - Export Sales',
            total: 0,
            transactions: [],
            warningCounter: 0
        },
        {
            description: 'G3 - Other GST Free Sales',
            total: 0,
            transactions: [],
            warningCounter: 0
        },
        {
            description: 'G10 - Capital Purchases (Inc. GST)',
            total: 0,
            transactions: [],
            warningCounter: 0
        },
        {
            description: 'G11 - Non-Capital Purchases (Inc. GST)',
            total: 0,
            transactions: [],
            warningCounter: 0
        },
        {
            description: '1A - GST on Sales',
            total: 0,
            transactions: [],
            warningCounter: 0
        },
        {
            description: '1B - GST on Purchases',
            total: 0,
            transactions: [],
            warningCounter: 0
        }
    ]);

    const GenerateReport = () => {
        CalculateGSTReporting(
            props.queryDate,
            props.selectedSite,
            setGstInformation,
            props.setLoading
        );
    };

    const handleOpenDrawer = (
        drawerTitle: string,
        transactions: {
            customerGstFree: boolean;
            warningFlag?: boolean;
            customerInternational: boolean;
            customerName: string;
            description: string;
            finalisedDate: string;
            gstAmount: number;
            id: number;
            link: string;
            reference: string;
            subReference: string;
            totalIncGst: number;
            gstFreeParts: { gstFreePart: string; partPrice: number }[];
        }[],
        warning: boolean
    ) => {
        if (warning) {
            setDrawerTitle('Corrupted Transaction for: ' + drawerTitle);
            setSelectedTransactions(transactions.filter((x) => x.warningFlag));
            setDrawerOpen(true);
        } else {
            setDrawerTitle(drawerTitle);
            setSelectedTransactions(transactions);
            setDrawerOpen(true);
        }
    };

    return (
        <>
            <ParametersSelection
                gstInformation={gstInformation}
                selectedSite={props.selectedSite}
                setSelectedSite={props.setSelectedSite}
                queryDate={props.queryDate}
                setQueryDate={props.setQueryDate}
                GenerateReport={GenerateReport}
                includeAllOption={true}
            />
            <br />

            {props.loading ? <CircularProgress /> : null}
            <Paper className="paper-padding">
                <Table size="small">
                    <TableRow>
                        <TableCell width="30%"></TableCell>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                Total Transactions
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                Total Amount
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>

                    {gstInformation.map((code) => (
                        <TableRow>
                            <TableCell>{code.description}</TableCell>
                            <TableCell
                                id="table-link"
                                onClick={() =>
                                    handleOpenDrawer(
                                        code.description,
                                        code.transactions,
                                        false
                                    )
                                }
                            >
                                <Typography variant="body2" align="center">
                                    {code.transactions.length}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body2" align="center">
                                    {CurrencyFormatter(code.total)}
                                </Typography>
                            </TableCell>
                            {code.warningCounter > 0 ? (
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            handleOpenDrawer(
                                                code.description,
                                                code.transactions,
                                                true
                                            );
                                        }}
                                    >
                                        <PriorityHigh
                                            color="warning"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </TableCell>
                            ) : (
                                <TableCell></TableCell>
                            )}
                        </TableRow>
                    ))}
                </Table>
            </Paper>

            <Drawer
                anchor={'right'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                style={{ zIndex: 1200 }}
            >
                <Box
                    sx={{ width: '35vw', padding: '20px' }}
                    role="presentation"
                >
                    <Typography variant="h4">{drawerTitle}</Typography>
                    <Typography>
                        {props.queryDate.startDate?.format('DD MMMM YYYY')} -{' '}
                        {props.queryDate.endDate?.format('DD MMMM YYYY')}
                    </Typography>
                    <Box height={'10px'}></Box>
                    <Divider></Divider>
                    <Box height="20px"></Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Reference</TableCell>
                                <TableCell>Total Inc. GST</TableCell>
                                <TableCell>Total Ex. GST</TableCell>
                                <TableCell>GST</TableCell>
                            </TableRow>
                        </TableHead>
                        {selectedTransactions.map((line) => (
                            <>
                                <TableRow>
                                    <TableCell
                                        id="table-link"
                                        onClick={() =>
                                            window.open(
                                                `${line.link}${line.id}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        {line.id}_{line.description}
                                    </TableCell>
                                    <TableCell>
                                        {line.subReference
                                            ? line.subReference + ': '
                                            : ''}{' '}
                                        {line.reference}
                                    </TableCell>
                                    <TableCell>
                                        {CurrencyFormatter(line.totalIncGst)}
                                    </TableCell>
                                    <TableCell>
                                        {CurrencyFormatter(
                                            line.totalIncGst - line.gstAmount
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {CurrencyFormatter(line.gstAmount)}
                                    </TableCell>
                                </TableRow>

                                {line.gstFreeParts
                                    ? line.gstFreeParts.map((part) => (
                                          <TableRow>
                                              <TableCell></TableCell>
                                              <TableCell>
                                                  <Typography variant="body2">
                                                      Part Number:{' '}
                                                  </Typography>
                                              </TableCell>
                                              <TableCell>
                                                  {part.gstFreePart}
                                              </TableCell>
                                              <TableCell>
                                                  {CurrencyFormatter(
                                                      part.partPrice
                                                  )}
                                              </TableCell>
                                              <TableCell>
                                                  {CurrencyFormatter(0)}
                                              </TableCell>
                                          </TableRow>
                                      ))
                                    : null}
                            </>
                        ))}
                    </Table>
                </Box>
            </Drawer>
        </>
    );
};

export default GSTReporting;
