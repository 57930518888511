// IMPORTS
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
// LOGIC
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import api from '../../../../../api';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';

interface DebtorPayments {
    id: number;
    fullName: string;
    totalDue: number;
    current: { thisMonth: number; days30: number };
    overdue: {
        lastMonth: number;
        days30: number;
        days60: number;
        days90: number;
    };
}

const PDFDebtorBalanceReport = async () => {
    let debtorPayments: DebtorPayments[];

    const calculateTotals = (returnType) => {
        let totalDue = 0;
        let currentThisMonth = 0;
        let currentDays30 = 0;
        let overdueLastMonth = 0;
        let overdueDays30 = 0;
        let overdueDays60 = 0;
        let overdueDays90 = 0;

        debtorPayments.forEach((payment) => {
            totalDue += payment.totalDue;
            currentThisMonth += payment.current.thisMonth;
            currentDays30 += payment.current.days30;
            overdueLastMonth += payment.overdue.lastMonth;
            overdueDays30 += payment.overdue.days30;
            overdueDays60 += payment.overdue.days60;
            overdueDays90 += payment.overdue.days90;
        });

        if (returnType === 'totalDue') {
            return totalDue;
        } else if (returnType === 'currentThisMonth') {
            return currentThisMonth;
        } else if (returnType === 'currentDays30') {
            return currentDays30;
        } else if (returnType === 'overdueLastMonth') {
            return overdueLastMonth;
        } else if (returnType === 'overdueDays30') {
            return overdueDays30;
        } else if (returnType === 'overdueDays60') {
            return overdueDays60;
        } else if (returnType === 'overdueDays90') {
            return overdueDays90;
        }
    };

    await api.get('overdueDebtorPayments').then((res) => {
        debtorPayments = res.data;
    });

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Header for the invoice
    await InvoiceHeader(
        doc,
        parseInt(localStorage.getItem('SiteId')),
        'Debtor Balance Statement',
        DateFormatter(String(new Date(Date.now()))),
        false,
        true,
        false
    );

    let lastYcoordinates = 82;

    let body = [];

    debtorPayments.forEach((debtor) => {
        let row = [
            debtor.id,
            debtor.fullName.toUpperCase(),
            CurrencyFormatter(debtor.totalDue),
            CurrencyFormatter(debtor.current.thisMonth),
            CurrencyFormatter(debtor.current.days30),
            CurrencyFormatter(debtor.overdue.lastMonth),
            CurrencyFormatter(debtor.overdue.days30),
            CurrencyFormatter(debtor.overdue.days60),
            CurrencyFormatter(debtor.overdue.days90)
        ];
        body.push(row);
    });

    body.push([
        '',
        'Totals',
        CurrencyFormatter(calculateTotals('totalDue')),
        CurrencyFormatter(calculateTotals('currentThisMonth')),
        CurrencyFormatter(calculateTotals('currentDays30')),
        CurrencyFormatter(calculateTotals('overdueLastMonth')),
        CurrencyFormatter(calculateTotals('overdueDays30')),
        CurrencyFormatter(calculateTotals('overdueDays60')),
        CurrencyFormatter(calculateTotals('overdueDays90'))
    ]);

    autoTable(doc, {
        head: [
            [
                { content: 'ID' },
                { content: 'Name' },
                { content: 'Total Due' },
                {
                    content: '------ CURRENT -------',
                    colSpan: 2,
                    styles: { halign: 'center' }
                },
                {
                    content: '------ OVERDUE -------',
                    colSpan: 4,
                    styles: { halign: 'center' }
                }
            ],
            [
                { content: '' },
                { content: '' },
                { content: '' },
                { content: 'This Month', styles: { halign: 'center' } },
                { content: '30+ Days', styles: { halign: 'center' } },
                { content: 'Last Month', styles: { halign: 'center' } },
                { content: '30+ Days', styles: { halign: 'center' } },
                { content: '60+ Days', styles: { halign: 'center' } },
                { content: '90+ Days', styles: { halign: 'center' } }
            ]
        ],
        body: body,
        theme: 'grid',
        headStyles: {
            fillColor: [19, 65, 123],
            textColor: 'white',
            lineColor: [19, 65, 123]
        },
        startY: lastYcoordinates,
        margin: { bottom: 20 },
        styles: { lineColor: [201, 201, 201], lineWidth: 0.1 }
    });

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFDebtorBalanceReport;
