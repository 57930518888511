import DocumentationPage from '../DocumentationPage';
import { AddGeneralJournalEntrySteps } from '../data/admin/generalJournalEntry';

const GeneralJournalSupportPage = () => {
    const contentSections = [
        {
            id: 'add-general-journal-entry',
            title: 'Add new General Journal Entry',
            href: '/support/admin/generalAccountingEntry/#add-general-journal-entry',
            description:
                'To add a new General Journal Entry, follow these steps:',
            steps: AddGeneralJournalEntrySteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="General Journal Entry"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default GeneralJournalSupportPage;
