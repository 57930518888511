import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Brand, Unit } from "../../../global/interfaces/PartsInterface";

interface TempBrand extends Brand {
    nbOfUnit: number
}

const HandleBrandClick = (brand: TempBrand, setSelectedBrand: Dispatch<SetStateAction<TempBrand>>, setOpenDialog: Dispatch<SetStateAction<boolean>>, setListofUnit: Dispatch<SetStateAction<Unit[]>>, setFilterListOfUnit: Dispatch<SetStateAction<Unit[]>>, setFilterValue: Dispatch<SetStateAction<string>>, setLoading: Dispatch<SetStateAction<boolean>>) => {
    setLoading(true)
    api.get('unitFromBrand/' + brand.id).then(res => {
        setListofUnit(res.data)
        setFilterListOfUnit(res.data)
        setSelectedBrand(brand)
        setFilterValue('')
        setLoading(false)
        setOpenDialog(true)
    })
}

export default HandleBrandClick;