// IMPORTS
import { Typography } from '@mui/material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Tabs from '../../global/Tabs';
import GenericInvoice from './components/GenericInvoice/GenericInvoice';
import RegistrationInvoice from './components/RegistrationInvoice/RegistrationInvoice';
import RebateInvoice from './components/RebateInvoice/RebateInvoice';
import TrustInvoice from './components/TrustInvoice/TrustInvoice';
import ServiceWarranty from './components/ServiceWarranty/ServiceWarranty';

const Invoice = () => {
    const tabContent = [
        { id: 0, title: 'Generic Invoice', content: <GenericInvoice /> },
        { id: 1, title: 'Registration', content: <RegistrationInvoice /> },
        { id: 2, title: 'Rebates', content: <RebateInvoice /> },
        { id: 3, title: 'Warranties', content: <ServiceWarranty /> },
        { id: 4, title: 'Trust Receipts', content: <TrustInvoice /> }
    ];

    return (
        <PageWrapper>
            <Typography variant="h4">Enter Admin Invoice</Typography>
            <br />
            <Tabs tabContent={tabContent} />
        </PageWrapper>
    );
};

export default Invoice;
