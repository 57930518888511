import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';
import { Category } from '../../../global/interfaces/PartsInterface';

const DeleteCategory = (
    category: Category,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.delete('category/' + category.id).then((res) => {
        if (res.status === 200) {
            showSnackbar('Category successfully deleted.');
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar(
                'Unable to delete Category.',
                'There are parts registered with this category.',
                'error'
            );
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Something went wrong, please refresh the page and try again.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default DeleteCategory;
