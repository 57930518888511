import api from '../../../../../api';

const GetBankTransactionDataInDateRange = async (
    startDate: string,
    endDate: string
) => {
    try {
        const response = await api.get(
            `/dashboard/bankTransactionFiles?startDate=${startDate}&endDate=${endDate}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export default GetBankTransactionDataInDateRange;
