import { Dispatch, SetStateAction } from 'react';
import { Till } from '../interfaces/GeneralInterface';
import api from '../../../../api';

const GetAllSiteTills = (
    SiteId: number,
    setTills: Dispatch<SetStateAction<Till[]>>
) => {
    api.get(`tillsNextGen/${SiteId}`).then((res) => {
        setTills(res.data);
    });
};

export default GetAllSiteTills;
