import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Make, Model, Series } from "../../../global/interfaces/VehicleSaleInterface";

interface TempModel extends Model {
    Series: Series[]
}

const HandleMakeNbModelClick = (make: Make, setSelectedMake: Dispatch<SetStateAction<Make>>, setOpenDialog: Dispatch<SetStateAction<boolean>>, setListofModels: Dispatch<SetStateAction<TempModel[]>>, setFilterListOfModels: Dispatch<SetStateAction<TempModel[]>>, setFilterValue: Dispatch<SetStateAction<string>>) => {
    api.get('modelsFromMake/' + make.id).then(res => {
        setListofModels(res.data)
        setFilterListOfModels(res.data)
        setSelectedMake(make)
        setFilterValue('')
        setOpenDialog(true)
    })
}

export default HandleMakeNbModelClick;