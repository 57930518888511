import DocumentationPage from '../../DocumentationPage';
import { DebtorReconciliationSteps } from '../../data/admin/debtorReconciliation';

const DebtorReconciliationSupportPage = () => {
    const contentSections = [
        {
            id: 'debtor-reconciliation',
            title: 'Debtor Reconciliation',
            href: '/support/admin/debtorReconciliation/#debtor-reconciliation',
            description:
                'To make account payments, manually mark invoices as paid, generate and email account statements, and produce Debtor Balance Statements, follow these steps:',
            steps: DebtorReconciliationSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Debtor Reconciliation"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default DebtorReconciliationSupportPage;
