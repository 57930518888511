// IMPORTS
import { List, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
// COMPONENTS
import RoleCheck from '../../global/RoleCheck';

const ITSubMenu = () => {
    const currentMode = useTheme().palette.mode;
    return (
        <>
            <List
                sx={{
                    backgroundColor:
                        currentMode === 'dark' ? '#3b3b3b' : '#dddddd'
                }}
            >
                {/* API SETTINGS */}
                <RoleCheck
                    permission={'configure_api'}
                    component={
                        <ListItemButton
                            component={Link}
                            to="/apiSettings"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                API Settings
                            </ListItemText>
                        </ListItemButton>
                    }
                />

                {/* IP WHITELIST */}
                <RoleCheck
                    permission={'access_ipWhitelist'}
                    component={
                        <ListItemButton
                            component={Link}
                            to="/ipWhitelist"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                IP Whitelist
                            </ListItemText>
                        </ListItemButton>
                    }
                />
            </List>
        </>
    );
};

export default ITSubMenu;
