import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const CreateCategory = (
    name: string,
    parent: string,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    api.post('category', { name: name, parent: parent }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Successfully created category.');
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar('Category already exists.', '', 'error');
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
        setButtonLoading(false);
    });
};

export default CreateCategory;
