// IMPORTS
import { useState } from 'react';
import {
    Chip,
    Grid,
    IconButton,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { Delete, InsertLink } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import PageWrapper from '../../../../global/PageWrapper';
import Drawer from '../../../../global/Drawer';
import ReconcileTransactionDrawer from './ReconcileTransactionDrawer';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../../global/logic/Formatters';
import DeleteTransactionLine from '../../logic/BankTransactions/DeleteTransactionLine';
import DeleteTransactionFile from '../../logic/BankTransactions/DeleteTransactionFile';
// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

interface TransactionsTableProps {
    transactionFiles;
    setTransactionFiles;
    selectedIndex: number;
    showSnackbar: showSnackbar;
}
const TransactionsTable = ({
    transactionFiles,
    setTransactionFiles,
    selectedIndex,
    showSnackbar
}: TransactionsTableProps) => {
    const [selectedTransaction, setSelectedTransaction] = useState<{
        reference: string;
        amount: number;
        dateReceived: string;
    }>({
        reference: '',
        amount: 0,
        dateReceived: ''
    });
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const columns = [
        { id: 0, label: 'Reference', with: 400 },
        { id: 1, label: 'Date', with: 100 },
        { id: 2, label: 'Amount', with: 100 },
        { id: 3, label: '', with: 100 },
        { id: 4, label: '', with: 100 },
        { id: 5, label: '', with: 100 }
    ];

    const checkAnyTransactionReconciled = (transactions) => {
        let anyReconciled = false;

        transactions?.forEach((line) => {
            if (line.reconciled) {
                anyReconciled = true;
            }
        });

        return anyReconciled;
    };
    console.log(selectedTransaction);
    return (
        <PageWrapper>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h6">
                            <b>
                                {transactionFiles[
                                    selectedIndex
                                ]?.file?.fileName?.replaceAll('%20', ' ')}
                            </b>
                        </Typography>
                        <Typography variant="subtitle1">
                            Uploaded on{' '}
                            {DateFormatter(
                                transactionFiles[selectedIndex]?.file?.createdAt
                            )}
                            {transactionFiles[selectedIndex]?.file?.User
                                ? ` by ${transactionFiles[selectedIndex]?.file?.User?.firstName + ' ' + transactionFiles[selectedIndex]?.file?.User?.lastName}`
                                : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                        {checkAnyTransactionReconciled(
                            transactionFiles[selectedIndex]?.transactions
                        ) ? (
                            <Tooltip
                                title="File contains reconciled transactions and can not be deleted."
                                placement="left"
                            >
                                <span>
                                    <IconButton disabled>
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title="Delete Transaction File"
                                placement="left"
                            >
                                <IconButton
                                    onClick={() =>
                                        DeleteTransactionFile(
                                            transactionFiles[selectedIndex]
                                                ?.file?.id,
                                            showSnackbar
                                        )
                                    }
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
                <DataTable columns={columns}>
                    {transactionFiles[selectedIndex]?.transactions.map(
                        (row) => (
                            <TableRow>
                                <DataCell align="left">
                                    {row.reference}
                                </DataCell>
                                <DataCell>{row.dateReceived}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(row.amount)}
                                </DataCell>
                                <DataCell>
                                    <Chip
                                        variant="outlined"
                                        color={
                                            row.reconciled ? 'success' : 'error'
                                        }
                                        label={
                                            row.reconciled
                                                ? 'Reconciled'
                                                : 'Not Reconciled'
                                        }
                                    />
                                </DataCell>
                                <DataCell>
                                    {!row.reconciled && (
                                        <Tooltip title="Reconcile Transaction Line">
                                            <IconButton
                                                onClick={() => {
                                                    setSelectedTransaction(row);
                                                    setOpenDrawer(true);
                                                }}
                                            >
                                                <InsertLink />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </DataCell>
                                <DataCell>
                                    {!row.reconciled && (
                                        <Tooltip title="Delete Transaction Line">
                                            <IconButton
                                                onClick={() =>
                                                    DeleteTransactionLine(
                                                        row.id,
                                                        transactionFiles,
                                                        setTransactionFiles,
                                                        selectedIndex,
                                                        showSnackbar
                                                    )
                                                }
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </DataCell>
                            </TableRow>
                        )
                    )}
                </DataTable>
            </Paper>
            <Drawer
                title={selectedTransaction.reference}
                subTitle={`Amount: ${CurrencyFormatter(selectedTransaction.amount)} - Date: ${selectedTransaction.dateReceived}`}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
            >
                <ReconcileTransactionDrawer
                    bankFile={transactionFiles[selectedIndex]}
                    selectedTransaction={selectedTransaction}
                    showSnackbar={showSnackbar}
                />
            </Drawer>
        </PageWrapper>
    );
};

export default TransactionsTable;
