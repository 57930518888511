import { IconButton, TableRow } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Close } from '@mui/icons-material';
import { ManualTransaction } from '../../../global/interfaces/AdminInterface';

import Paper from '../../../global/Paper';
import 'dayjs/locale/en-gb';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';

interface TransactionLinesProps {
    manualTransactions: ManualTransaction[];
    setManualTransactions: Dispatch<SetStateAction<ManualTransaction[]>>;
}

const TransactionLines = ({
    manualTransactions,
    setManualTransactions
}: TransactionLinesProps) => {
    const columns = [
        { id: 0, label: 'Entity', width: 300 },
        { id: 1, label: 'Reference', width: 300 },
        { id: 2, label: 'Date', width: 100 },
        { id: 3, label: 'Debit', width: 100 },
        { id: 4, label: 'Credit', width: 100 },
        { id: 5, label: 'GST', width: 100 },
        { id: 6, label: '', width: 50 }
    ];

    const handleDeleteLine = (rowId) => {
        let currentManualTransactions = [...manualTransactions];
        currentManualTransactions = currentManualTransactions.filter(
            (x) => x.id !== rowId
        );
        setManualTransactions(currentManualTransactions);
    };

    return (
        <>
            <Paper>
                <DataTable columns={columns}>
                    {manualTransactions.map((line) => (
                        <TableRow>
                            <DataCell>
                                {line.AccountId
                                    ? line.Account?.name
                                    : line.Vehicle?.stockNumber}
                            </DataCell>
                            <DataCell>{line.reference}</DataCell>
                            <DataCell>
                                {DateFormatter(line.dateReceived)}
                            </DataCell>
                            <DataCell>{CurrencyFormatter(line.debit)}</DataCell>
                            <DataCell>
                                {CurrencyFormatter(line.credit)}
                            </DataCell>
                            <DataCell>{line.gstType}</DataCell>
                            <DataCell>
                                <IconButton
                                    onClick={() => handleDeleteLine(line.id)}
                                >
                                    <Close />
                                </IconButton>
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </>
    );
};

export default TransactionLines;
