const VendorStatementHeader = (doc, image, saleDetails, settings, site) => {
    doc.addImage(image, 10, 5, 40, 10); // Put image on page

    // PDF title
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(10);
    doc.text("Vendor's Statement To Motor Dealer", 10, 22);

    doc.setFont('helvetica', 'bold').text('Sale #' + saleDetails.id, 10, 26);

    // Dealership details (ABN, address, etc)
    doc.setFont('helvetica', 'normal').setFontSize(8);
    doc.text(settings.companyName, 199, 10, { align: 'right' });
    doc.text(site.address, 199, 13, { align: 'right' });
    doc.text(site.name + ' ' + site.state + ' ' + site.postcode, 199, 16, {
        align: 'right'
    });
    doc.text('Phone: ' + site.phoneNumber, 199, 19, {
        align: 'right'
    });
    doc.setFont('helvetica', 'bold').text('ABN: ' + settings.abn, 199, 22, {
        align: 'right'
    });
    doc.setFont('helvetica', 'normal').text(
        settings?.websiteURL ?? '',
        199,
        25,
        { align: 'right' }
    );
};

export default VendorStatementHeader;
