// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Grid, TextField } from '@mui/material';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';

interface NotesDialogContentProps {
  saleDetails: Order;
  setSaleDetails: Dispatch<SetStateAction<Order>>;
  readOnly: boolean;
}

const NotesDialogContent = ({
  saleDetails,
  setSaleDetails,
  readOnly
}: NotesDialogContentProps) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={6}>
          <TextField
            disabled={readOnly}
            fullWidth
            multiline
            rows={7}
            label="Internal Notes"
            InputLabelProps={{ shrink: true }}
            value={saleDetails.notes}
            onChange={(e) =>
              setSaleDetails({ ...saleDetails, notes: e.target.value })
            }
            helperText="This is only visible within Ora."
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={readOnly}
            fullWidth
            multiline
            rows={7}
            label="Customer Notes"
            InputLabelProps={{ shrink: true }}
            value={saleDetails.customerNotes}
            onChange={(e) =>
              setSaleDetails({ ...saleDetails, customerNotes: e.target.value })
            }
            helperText="This is printed on the customer invoice."
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NotesDialogContent;
