import { Dispatch, SetStateAction } from "react";
import { Creditor, OrderFileGenerator, Site } from "../../../global/interfaces/GeneralInterface";

interface HandleAddApiParams {
    setSelectedApi: Dispatch<SetStateAction<SelectedApi>>,
    setEdit: Dispatch<SetStateAction<boolean>>,
    setAddOpen: Dispatch<SetStateAction<boolean>>
}

interface SelectedApi {
    id?: number
    name: string,
    nameError: boolean,
    description: string,
    descriptionError: boolean,
    Site: Site,
    siteError: boolean,
    key: string,
    Supplier: Creditor,
    supplierError: boolean,
    canGenerateOrderFile: boolean,
    canOrderWithAPI: boolean,
    Generator: OrderFileGenerator,
    generatorError: boolean,
    OrderApi: OrderFileGenerator,
    OrderApiError: boolean,
    detailsList: {}

    OrderFileGeneratorId?: number
    ApiOrderId?: number
    additionalDetails?: {}
}

const HandleAddApi = (params: HandleAddApiParams) => {

    params.setSelectedApi({
        name: "",
        nameError: false,
        description: "",
        descriptionError: false,
        Site: {},
        siteError: false,
        key: "",
        Supplier: {},
        supplierError: false,
        canGenerateOrderFile: false,
        canOrderWithAPI: false,
        Generator: {},
        generatorError: false,
        OrderApi: {},
        OrderApiError: false,
        detailsList: []
    })

    params.setEdit(false)
    params.setAddOpen(true);
};

export default HandleAddApi;