// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';
import {
    CustomerVehicle,
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Order } from '../../../global/interfaces/PartsInterface';

const ValidateService = (
    selectedCustomer: Customer,
    selectedInsurer: Customer,
    vehicleType: string,
    selectedVehicle: Vehicle | CustomerVehicle,
    serviceDetails: Service,
    partSales: Order[],
    sublets: PurchaseOrder[],
    showSnackbar: showSnackbar
) => {
    // If they have marked the service as finalised and there are incompelte sales
    // or purchase orders cannot process
    if (serviceDetails.serviceComplete === 'Finalised') {
        let salesFinalised = true;
        partSales.forEach((sale) => {
            if (sale.status !== 'Sale') {
                salesFinalised = false;
            }
        });

        if (!salesFinalised) {
            showSnackbar(
                'There are incomplete P&A sales, cannot finalise service. ',
                '',
                'error'
            );
            return false;
        }

        let subletsFinalised = true;
        sublets.forEach((sublet) => {
            if (!sublet.InvoiceId) {
                subletsFinalised = false;
            }
        });

        if (!subletsFinalised) {
            showSnackbar(
                'There are incomplete purchase orders, cannot finalise service. ',
                '',
                'error'
            );
            return false;
        }
    }

    // If it is a retail service then there has to be at least:
    // - A customer
    // - A customer vehicle
    // - A start date and time
    if (serviceDetails.jobType === 'Retail') {
        if (!selectedCustomer) {
            showSnackbar(
                'error',
                'You must select a customer for a retail service.'
            );
            return false;
        }
        if (!selectedVehicle || vehicleType !== 'customer') {
            showSnackbar(
                'You must select a customer vehicle for a retail service.',
                '',
                'error'
            );
            return false;
        }
        if (!serviceDetails.jobStart) {
            showSnackbar(
                'You must select a start time for the service booking.',
                '',
                'error'
            );
            return false;
        }
    }

    if (serviceDetails.jobType === 'Rework') {
        if (!selectedVehicle) {
            showSnackbar(
                'You must select either a customer or stock vehicle for a rework service.',
                '',
                'error'
            );
            return false;
        }
        if (!serviceDetails.jobStart) {
            showSnackbar(
                'You must select a start time for the service booking.',
                '',
                'error'
            );
            return false;
        }
    }

    // If it is an internal service then there has to be at least:
    // - A stock vehicle
    // - A start date and time
    if (serviceDetails.jobType === 'Internal') {
        if (!selectedVehicle || vehicleType !== 'stock') {
            showSnackbar(
                'You must select a stock vehicle for an internal service.',
                '',
                'error'
            );
            return false;
        }
        if (!serviceDetails.jobStart) {
            showSnackbar(
                'You must select a start time for the service booking.',
                '',
                'error'
            );
            return false;
        }
    }

    // If it is an insurance service then there has to be at least:
    // - An insurer selected
    // - A vehicle selected (customer or stock)
    // - A start date and time
    if (serviceDetails.jobType === 'Insurance') {
        if (!selectedInsurer) {
            showSnackbar(
                'You must select am insurer for an insurance service.',
                '',
                'error'
            );
            return false;
        }
        if (!selectedVehicle) {
            showSnackbar(
                'You must select a vehicle for an insurance service.',
                '',
                'error'
            );
            return false;
        }
        if (!serviceDetails.jobStart) {
            showSnackbar(
                'You must select a start time for the service booking.',
                '',
                'error'
            );
            return false;
        }
    }

    // If it is a warranty service then there has to be at least:
    // A vehicle selected (customer or stock)
    // A start date and time
    if (serviceDetails.jobType === 'Warranty') {
        if (!selectedVehicle) {
            showSnackbar(
                'You must select a vehicle for a warranty service.',
                '',
                'error'
            );
            return false;
        }
        if (!serviceDetails.jobStart) {
            showSnackbar(
                'You must select a start time for the service booking.',
                '',
                'error'
            );
            return false;
        }
    }

    // If an end time is selected it cannot be before the start time
    if (
        serviceDetails.jobFinish &&
        serviceDetails.jobStart &&
        serviceDetails.jobFinish < serviceDetails.jobStart
    ) {
        showSnackbar(
            'The finish time of the job cannot be before the start time.',
            '',
            'error'
        );
        return false;
    }

    if (
        parseFloat(serviceDetails.labourCost) < 0 ||
        serviceDetails.labourCost === ''
    ) {
        showSnackbar(
            'Invalid labour charge entered. Labour charge must be 0 or greater.',
            '',
            'error'
        );
        return false;
    }

    if (
        parseFloat(serviceDetails.subletTotal) < 0 ||
        serviceDetails.subletTotal === ''
    ) {
        showSnackbar(
            'Invalid sublet charge entered. Sublet charge must be 0 or greater.',
            '',
            'error'
        );
        return false;
    }

    if (
        parseFloat(serviceDetails.environmentalLevy) < 0 ||
        serviceDetails.environmentalLevy === ''
    ) {
        showSnackbar(
            'Invalid environmental levy entered. Environmental levy charge must be 0 or greater.',
            '',
            'error'
        );
        return false;
    }

    if (
        parseFloat(serviceDetails.sundries) < 0 ||
        serviceDetails.sundries === ''
    ) {
        showSnackbar(
            'Invalid sundries entered. Sundries charge must be 0 or greater.',
            '',
            'error'
        );
        return false;
    }

    if (
        parseFloat(serviceDetails.totalPrice) < 0 ||
        serviceDetails.totalPrice === ''
    ) {
        showSnackbar(
            'Invalid service total entered. Service total must be 0 or greater.',
            '',
            'error'
        );
        return false;
    }

    let validAddedCosts = true;

    serviceDetails.addedCosts.forEach((line) => {
        if (parseFloat(line.quantity) < 0 || line.quantity === '') {
            validAddedCosts = false;
        } else if (parseFloat(line.pricePaid) < 0 || line.pricePaid === '') {
            validAddedCosts = false;
        }

        if (
            line.partType === 'part' &&
            !Number.isInteger(parseFloat(line.quantity))
        ) {
            validAddedCosts = false;
        }
    });

    partSales.forEach((sale) => {
        sale.orderLines.forEach((line) => {
            if (parseFloat(line.priceCharged) < 0 || line.priceCharged === '') {
                validAddedCosts = false;
            }
        });
    });

    if (!validAddedCosts) {
        showSnackbar(
            'Invalid quantity / price entered for one or more parts.',
            'Both values must not be less than 0 and parts must not have a decimal quantity.',
            'error'
        );
        return false;
    }

    let validMiscCosts = true;
    serviceDetails.miscCosts.forEach((line) => {
        if (parseFloat(line.amount) < 0 || line.amount === '') {
            validMiscCosts = false;
        } else if (line.description === '') {
            validMiscCosts = false;
        }
    });

    if (!validMiscCosts) {
        showSnackbar(
            'error',

            'Invalid description / amount entered for one or more miscellaneous costs.'
        );
        return false;
    }

    return true;
};

export default ValidateService;
