import { Dispatch, SetStateAction } from "react";
import { Table } from "../interfaces/StockOrderInterface"

const HandleCheckboxSelect = (itemId: number, tableId: number, tables: Table[], setTables: Dispatch<SetStateAction<Table[]>>) => {
    var tableIndex = tables.findIndex(x => x.tableId === tableId);
    let rows = [...tables];
    let row = { ...rows[tableIndex] };

    var orderLines = row.orderLines;

    orderLines.forEach(item => {
        if (item.rowId === itemId) {
            item.selected = !item.selected
        }
    })

    rows[tableIndex] = row;
    setTables(rows)
}

export default HandleCheckboxSelect;