import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const ResetPrice = (serviceId: number, showSnackbar: showSnackbar) => {
    api.put('resetServiceSalePrice/' + serviceId).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Service updated successfully.',
                'The page will now refresh'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact the IT department.',
                'error'
            );
        }
    });
};

export default ResetPrice;
