import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const ImportStockLevelFromCSV = async (
    importedData: any[],
    headers: string[],
    showSnackbar: showSnackbar,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setProgress: Dispatch<SetStateAction<number>>,
    setTimeRemaining: Dispatch<SetStateAction<string>>
) => {
    setLoading(true);
    let proceed = true;
    let formattedArray = [];

    // Go through each line of the CSV
    for (let [id, item] of importedData.entries()) {
        let tempObject = {
            partNumber: null,
            qtyOnHand: null,
            siteName: null
        };

        // Go through each header/columns
        for (let [index, header] of headers.entries()) {
            let numberHeader = ['qtyOnHand', 'maxStock', 'minStock'];
            if (numberHeader.includes(header)) {
                tempObject[header] = parseInt(item[index]);
            } else {
                tempObject[header] = item[index];
            }
        }

        // Check that we have a first name last name combo or a company name
        // If user ticked ignore errors checkbox, ignore incorrect lines
        if (
            tempObject.partNumber.length > 0 &&
            !Number.isNaN(tempObject.qtyOnHand) &&
            tempObject.siteName.length > 0
        ) {
            formattedArray.push(tempObject);
        } else {
            proceed = false;
            showSnackbar(
                'A line has invalid data. Each line must have: a part number, a quantity on hand and a site',
                `Found at line ${id}. Part Number: ${tempObject.partNumber ?? ''}, Quantity on hand: ${tempObject.qtyOnHand ?? ''}, Site Name: ${tempObject.siteName ?? ''}`,
                'error'
            );
            setLoading(false);
            return;
        }
    }

    if (proceed) {
        let start = performance.now();
        let nextInteger = 1;
        let config = {
            onDownloadProgress: (progressEvent) => {
                // On each response from the backend: calculate the percentage of line that have been processed
                let counter =
                    progressEvent.event.currentTarget.response.split(
                        '}{'
                    ).length;
                let percentCompleted = Math.floor(
                    (counter * 100) / formattedArray.length
                );
                setProgress(percentCompleted);

                if (percentCompleted === nextInteger) {
                    // Calculate time it took to go to the current percentage
                    let time = (performance.now() - start) / 1000;
                    // Calculate remaining
                    let remaining = (100 - percentCompleted) / nextInteger;

                    // Calculate time remaining in sec
                    let timeRemaining = time * remaining;

                    let remainingTimeString = `${Math.floor(timeRemaining / 60)} minutes and ${Math.floor(timeRemaining % 60)} seconds`;
                    setTimeRemaining(remainingTimeString);
                    nextInteger += 1;
                }
            }
        };

        api.post('/importStockLevelCSV', formattedArray, config).then((res) => {
            // Once we get the complete data (as a JSON string) split it to get each separate JSON
            let splitted = res.data.split('}{');

            // Initialize an array with the headers that will be used to create a CSV with ignored lines
            let finalArray = [];
            let tempArray = [];
            for (let header of headers) {
                tempArray.push(header);
            }
            tempArray.push('Suggested Part Number');
            finalArray.push(tempArray);

            // For each ignored line parse the JSON and push the relevant data in the finalArray
            for (let item of splitted) {
                if (item.includes('suggestedPartNumber'))
                    if (item.slice(0, 1) === '{' && item.slice(-1) === '"') {
                        let newItem = JSON.parse(item + '}');
                        let tempArray = [];
                        // Reconstruct the temp array using the headers
                        for (let header of headers) {
                            let numberHeader = [
                                'qtyOnHand',
                                'maxStock',
                                'minStock'
                            ];
                            if (numberHeader.includes(header)) {
                                tempArray.push(parseInt(newItem[header]));
                            } else {
                                tempArray.push(newItem[header]);
                            }
                            tempArray.push(newItem.suggestedPartNumber);
                        }
                        finalArray.push(tempArray);
                    } else if (
                        item.slice(0, 1) === '"' &&
                        item.slice(-1) === '"'
                    ) {
                        let newItem = JSON.parse('{' + item + '}');
                        let tempArray = [];
                        for (let header of headers) {
                            let numberHeader = [
                                'qtyOnHand',
                                'maxStock',
                                'minStock'
                            ];
                            if (numberHeader.includes(header)) {
                                tempArray.push(parseInt(newItem[header]));
                            } else {
                                tempArray.push(newItem[header]);
                            }
                        }
                        tempArray.push(newItem.suggestedPartNumber);
                        finalArray.push(tempArray);
                    } else if (item.slice(-1) === '}') {
                        let newItem = JSON.parse('{' + item);
                        let tempArray = [];
                        for (let header of headers) {
                            let numberHeader = [
                                'qtyOnHand',
                                'maxStock',
                                'minStock'
                            ];
                            if (numberHeader.includes(header)) {
                                tempArray.push(parseInt(newItem[header]));
                            } else {
                                tempArray.push(newItem[header]);
                            }
                        }
                        tempArray.push(newItem.suggestedPartNumber);
                        finalArray.push(tempArray);
                    }
            }

            // If we have a finalArray of length > 1 (it gets initialized with 1 object) we create the CSV and download it
            if (finalArray.length > 1) {
                setLoading(false);
                showSnackbar(
                    `Stock Level successfully imported, but ${finalArray.length - 1} line(s) were ignored due to a non-matching Part Number`,

                    'A CSV should download now. It contains the data for the ignored parts with a column suggesting part numbers.'
                );

                // Create the CSV file
                let csvContent = 'data:text/csv;charset=utf-8,';
                finalArray.forEach(function (rowArray) {
                    let row = rowArray.join(',');
                    csvContent += row + '\r\n';
                });
                var encodedUri = encodeURI(csvContent);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', `NegativeStockReport`);
                document.body.appendChild(link);
                link.click();
            } else {
                // Otherwise simply refresh the page
                setLoading(false);
                showSnackbar(
                    'Stock Level successfully imported.',
                    'The page will now refresh.'
                );
                setTimeout(() => window.location.reload(), 1500);
            }
        });
    }
};
