import { ToFixed } from '../../../../global/logic/Formatters';

const CalculateTotals = (transactions) => {
  let total = 0;
  if (transactions.length > 0) {
    transactions.forEach((item) => {
      total += parseFloat(item.amount);
    });
  }
  return ToFixed(total);
};

export default CalculateTotals;
