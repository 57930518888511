import { Step } from '../../interface';

export const AddNewLeadSteps: Step[] = [
    {
        label: "Click 'Vehicle Sales' > 'Leads Management' in the sidebar to navigate to the Leads Management page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/new-lead-01.png'
    },
    {
        label: "To add a new Lead, click 'New Lead' in the speed dial menu to open the New Enquiry drawer.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/new-lead-02.png'
    },
    {
        label: 'The Customer tab lets you select an existing customer or create a new one by clicking the + button.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/new-lead-03.png'
    },
    {
        label: "The Vehicle tab lets you add details of the customer's vehicle of interest, including the vehicle's condition, make, and model. You can also add their Finance and Trade-In details in their respective tabs if needed.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/new-lead-04.png'
    },
    {
        label: 'The Test Ride tab will be disabled until the Lead is created. Once the Lead is created, the Test Ride tab will be enabled.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/new-lead-05.png'
    },
    {
        label: 'Click the Save button to create the Lead. If any required fields are missing, a warning message will appear.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/new-lead-06.png'
    },
    {
        label: "The new Lead will now be listed on the Leads Management page with the status set to 'Active'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/new-lead-07.png'
    }
];

export const EditLeadSteps: Step[] = [
    {
        label: "You can change one or more Leads' assigned employee by checking their employee checkbox. Then click the Change Employees button at the top of the table to open the Change Assigned Users dialog.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/edit-lead-01.png'
    },
    {
        label: 'To edit a Lead details, click on the Lead ID to open the Enquiry Details drawer.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/edit-lead-02.png'
    },
    {
        label: 'Update the Lead status by clicking the status chip and select a new status.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/edit-lead-03.png'
    },
    {
        label: 'Click the "Edit" button to enter edit mode. You can update any field that is not disabled.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/edit-lead-04.png'
    },
    {
        label: "To create a test ride, click the 'Test Ride' tab, fill in the test ride details, and then click the 'Submit Test Ride Agreement' button.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/edit-lead-05.png'
    },
    {
        label: 'Test ride details cannot be edited once the test ride is submitted.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/vehicleSales/leads/edit-lead-06.png'
    }
];
