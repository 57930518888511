// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Site } from '../interfaces/GeneralInterface';

/**
 * GetSite
 * Gets the details of a provided site
 * @author Sienna
 * @param SiteId The ID of the site within the company
 * @param setSite React state to save the site details to
 */
const GetSite = (SiteId: number, setSite: Dispatch<SetStateAction<Site>>) => {
  api.get(`singleSite/${SiteId}`).then((res) => {
    // Request return a 404 if there is no site in the database
    if (res.status === 404) {
      if (!window.location.href.includes('/onboarding')) {
        window.location.href = '/onboarding'
      }
    } else {
      setSite(res.data);
    }
  });
};

export default GetSite;
