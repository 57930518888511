// IMPORTS
import { useEffect, useState } from 'react';
import { TableRow, Typography } from '@mui/material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
// LOGIC
import GetCustomerServiceHistory from '../logic/GetCustomerServiceHistory';
// INTERFACES
import { Service } from '../../../global/interfaces/ServiceInterface';
import { DateFormatter, YearFormatter } from '../../../global/logic/Formatters';
interface CustomerHistoryContentProps {
    CustomerId: number;
    ServiceId: number;
}

const CustomerHistoryContent = ({
    CustomerId,
    ServiceId
}: CustomerHistoryContentProps) => {
    const [customerHistory, setCustomerHistory] = useState<Service[]>([]);

    const columns = [
        { id: 0, label: 'Service ID' },
        { id: 1, label: 'Vehicle' },
        { id: 2, label: 'Status' },
        { id: 3, label: 'Start Date' }
    ];

    useEffect(() => {
        GetCustomerServiceHistory(CustomerId, setCustomerHistory);
    }, [CustomerId]);

    return (
        <>
            {customerHistory.length === 0 ? (
                <Typography>No recorded service history.</Typography>
            ) : (
                <>
                    <DataTable columns={columns}>
                        {customerHistory.map((service) => (
                            <TableRow selected={service.id === ServiceId}>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            `/service/viewBooking/${service.id}`,
                                            '_blank'
                                        )
                                    }
                                >
                                    {service.id}
                                </DataCellColoured>
                                <DataCell>
                                    {service?.CustomerVehicleId &&
                                        YearFormatter(
                                            service?.CustomerVehicle?.year
                                        ) +
                                            ' ' +
                                            service?.CustomerVehicle?.Make
                                                ?.name +
                                            ' ' +
                                            service?.CustomerVehicle?.Model
                                                ?.name +
                                            ' ' +
                                            service?.CustomerVehicle?.Series
                                                ?.name}
                                    {service?.VehicleId &&
                                        YearFormatter(service?.Vehicle?.year) +
                                            ' ' +
                                            service?.Vehicle?.Make?.name +
                                            ' ' +
                                            service?.Vehicle?.Model?.name +
                                            ' ' +
                                            service?.Vehicle?.Series?.name}
                                </DataCell>
                                <DataCell>{service?.serviceComplete}</DataCell>
                                <DataCell>
                                    {DateFormatter(service?.jobStart)}
                                </DataCell>
                            </TableRow>
                        ))}
                    </DataTable>
                </>
            )}
        </>
    );
};

export default CustomerHistoryContent;
