import { Step } from '../../interface';

export const ManageStockOrdersSteps: Step[] = [
    {
        label: "Click 'Stock Control' > 'Stock Orders' in the sidebar to navigate to the Stock Order page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockOrders/stock-order-01.png'
    },
    {
        label: 'Enter the Order ID or Supplier name, then press Enter to search for specific Stock Orders.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockOrders/stock-order-02.png'
    },
    {
        label: 'Click the filter icon to open the filter panel. Select options in the Filter & Sort dialog to filter the Stock Orders. You can combine multiple filters to narrow down the search results.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockOrders/stock-order-03.png'
    },
    {
        label: (
            <>
                Click "Create Stock Order" in the speed dial menu to access the
                new Stock Order page. Refer to{' '}
                <a
                    href="/support/inventory/stockorder"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    New Stock Order
                </a>{' '}
                documentation for more information on creating a new stockorder.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockOrders/stock-order-04.png'
    },
    {
        label: (
            <>
                Click on the Order ID to access the Stock Invoice page. For more
                details on invoicing Stock Orders, refer to{' '}
                <a
                    href="/support/inventory/stockInvoiceNoPO/#enter-stock-invoice"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Invoice Existing Stock Order
                </a>{' '}
                documentation.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockOrders/stock-order-05.png'
    },
    {
        label: 'Click the three dots, then select "Preview Order" to view the order details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockOrders/stock-order-06.png'
    },
    {
        label: 'Click the three dots, then select "Print Stock Order Summary". A PDF file will be downloaded.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/stockOrders/stock-order-07.png'
    }
];
