// IMPORT
import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from 'react';
import {
    Checkbox,
    DialogTitle,
    Button,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    MenuItem
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { SiteContext } from '../../../../main/Main';
// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
// LOGIC
import GetAllVehicleSpecifics from '../../../global/databaseLogic/GetAllVehicleSpecifics';
import AddVehicleToList from '../logic/AddVehicleToList';
import CheckVehicleVin from '../../../global/logic/CheckVehicleVin';
// INTERFACE
import {
    TradeIn,
    Vehicle
} from '../../../global/interfaces/VehicleSaleInterface';
import { Site, Specific } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface TradeFormProps {
    trades: TradeIn[];
    setTrades: Dispatch<SetStateAction<TradeIn[]>>;
    setVehicleFormOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
    gstType?: string;
}

interface SpecificsList {
    makes?: Specific[];
    models?: Specific[];
    series?: Specific[];
    fuelTypes?: Specific[];
    bodyTypes?: Specific[];
    driveTypes?: Specific[];
}

const TradeForm = ({
    trades,
    setTrades,
    setVehicleFormOpen,
    showSnackbar,
    gstType
}: TradeFormProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const [responseCode, setResponseCode] = useState(200);
    const [newTrade, setNewTrade] = useState<Vehicle>({
        Make: null,
        Model: null,
        Series: null,
        FuelType: null,
        DriveType: null,
        BodyType: null,
        year: null,
        registrationDueDate: null,
        complianceDate: null
    });
    const [specificsUnaltered, setSpecificsUnaltered] = useState<SpecificsList>(
        {
            makes: [],
            models: [],
            series: [],
            fuelTypes: [],
            bodyTypes: [],
            driveTypes: []
        }
    );
    const [specifics, setSpecifics] = useState<SpecificsList>({
        makes: [],
        models: [],
        series: [],
        fuelTypes: [],
        bodyTypes: [],
        driveTypes: []
    });

    useEffect(() => {
        GetAllVehicleSpecifics(setSpecificsUnaltered, [
            'makes',
            'models',
            'series',
            'fuelTypes',
            'driveTypes',
            'bodyTypes'
        ]);
        GetAllVehicleSpecifics(setSpecifics, [
            'makes',
            'models',
            'series',
            'fuelTypes',
            'driveTypes',
            'bodyTypes'
        ]);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (responseCode === 400) {
            showSnackbar(
                'VIN already exists in Ora.',
                'Please double check you entered the correct VIN and the vehicle does not already exist in Ora.',
                'error'
            );
        }
        // eslint-disable-next-line
    }, [responseCode]);

    const handleFilterModels = (MakeId) => {
        if (!MakeId) {
            setSpecifics({ ...specifics, models: specificsUnaltered.models });
        } else {
            let currentModels = [...specificsUnaltered.models];
            currentModels = currentModels.filter((x) => x.MakeId === MakeId);
            setSpecifics({ ...specifics, models: currentModels });
        }
    };

    const handleFilterSeries = (ModelId) => {
        if (!ModelId) {
            setSpecifics({ ...specifics, series: specificsUnaltered.series });
        } else {
            let currentModels = [...specificsUnaltered.models];
            currentModels = currentModels.filter((x) => x.ModelId === ModelId);
            setSpecifics({ ...specifics, series: currentModels });
        }
    };

    return (
        <>
            <DialogTitle>New Trade Vehicle</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">Required Details</Typography>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            options={specifics.makes}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Make"
                            selectedValue={newTrade?.Make}
                            handleSelectedValueChange={(newValue) => {
                                if (!newValue) {
                                    setNewTrade({
                                        ...newTrade,
                                        Make: null,
                                        MakeId: null,
                                        Model: null,
                                        ModelId: null,
                                        Series: null,
                                        SeriesId: null
                                    });
                                    handleFilterModels(null);
                                } else {
                                    setNewTrade({
                                        ...newTrade,
                                        Make: newValue,
                                        MakeId: newValue.id
                                    });
                                    handleFilterModels(newValue.id);
                                }
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            options={specifics.models}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Model"
                            selectedValue={newTrade?.Model}
                            handleSelectedValueChange={(newValue) => {
                                if (!newValue) {
                                    setNewTrade({
                                        ...newTrade,
                                        Model: null,
                                        ModelId: null,
                                        Series: null,
                                        SeriesId: null
                                    });
                                    handleFilterSeries(null);
                                } else {
                                    setNewTrade({
                                        ...newTrade,
                                        Model: newValue,
                                        ModelId: newValue.id
                                    });
                                    handleFilterSeries(newValue.id);
                                }
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            options={specifics.series}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Series"
                            selectedValue={newTrade?.Series}
                            handleSelectedValueChange={(newValue) =>
                                setNewTrade({
                                    ...newTrade,
                                    Series: newValue,
                                    SeriesId: newValue?.id
                                })
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="YYYY"
                                views={['year']}
                                label="Year Model"
                                value={newTrade?.year}
                                onChange={(newValue) => {
                                    setNewTrade({
                                        ...newTrade,
                                        year: newValue
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                        <>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="VIN"
                                    InputLabelProps={{ shrink: true }}
                                    value={newTrade?.vin}
                                    onChange={(e) =>
                                        setNewTrade({
                                            ...newTrade,
                                            vin: e.target.value
                                        })
                                    }
                                    onBlur={(e) =>
                                        CheckVehicleVin(
                                            e.target.value,
                                            setResponseCode
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    size="small"
                                    options={specifics.fuelTypes}
                                    useTwoOptionLabels={false}
                                    primaryOptionLabel="name"
                                    textfieldLabel="Fuel Type"
                                    selectedValue={newTrade?.FuelType}
                                    handleSelectedValueChange={(newValue) => {
                                        setNewTrade({
                                            ...newTrade,
                                            FuelType: newValue,
                                            FuelTypeId: newValue?.id
                                        });
                                    }}
                                    handleInputValueChange={() => null}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Cylinders"
                                    type="number"
                                    InputLabelProps={{ shrink: true }}
                                    value={newTrade?.cylinders}
                                    onChange={(e) =>
                                        setNewTrade({
                                            ...newTrade,
                                            cylinders: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label={`Base Cost (${gstType}. GST)`}
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            value={newTrade?.costPrice}
                            onChange={(e) =>
                                setNewTrade({
                                    ...newTrade,
                                    costPrice: e.target.value
                                })
                            }
                        />
                    </Grid>
                </Grid>
                <br />
                <Typography variant="subtitle1">Specific Details</Typography>
                <br />
                <Grid container spacing={2}>
                    {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                        <>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Engine Number"
                                    InputLabelProps={{ shrink: true }}
                                    value={newTrade?.engineNumber}
                                    onChange={(e) =>
                                        setNewTrade({
                                            ...newTrade,
                                            engineNumber: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Odometer"
                                    type="number"
                                    InputLabelProps={{ shrink: true }}
                                    value={newTrade?.odometer}
                                    onChange={(e) =>
                                        setNewTrade({
                                            ...newTrade,
                                            odometer: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Registration Number"
                                    InputLabelProps={{ shrink: true }}
                                    value={newTrade?.registrationNumber}
                                    onChange={(e) =>
                                        setNewTrade({
                                            ...newTrade,
                                            registrationNumber: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        label="Registration Expiry"
                                        value={newTrade?.registrationDueDate}
                                        onChange={(newValue) => {
                                            setNewTrade({
                                                ...newTrade,
                                                registrationDueDate: newValue
                                            });
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: false,
                                                size: 'small',
                                                InputLabelProps: {
                                                    shrink: true
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={newTrade?.learnerApproved}
                                            onChange={(e) =>
                                                setNewTrade({
                                                    ...newTrade,
                                                    learnerApproved:
                                                        e.target.checked
                                                })
                                            }
                                        />
                                    }
                                    label="Learner Approved"
                                />
                            </Grid>
                        </>
                    ) : null}

                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Colour"
                            InputLabelProps={{ shrink: true }}
                            value={newTrade?.colour}
                            onChange={(e) =>
                                setNewTrade({
                                    ...newTrade,
                                    colour: e.target.value
                                })
                            }
                        />
                    </Grid>
                    {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                        <>
                            <Grid item xs={2}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    <DatePicker
                                        format="MM/YYYY"
                                        views={['month', 'year']}
                                        label="Compliance Date"
                                        value={newTrade?.complianceDate}
                                        onChange={(newValue) => {
                                            setNewTrade({
                                                ...newTrade,
                                                complianceDate: newValue
                                            });
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: false,
                                                size: 'small',
                                                InputLabelProps: {
                                                    shrink: true
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    select
                                    size="small"
                                    label="Transmission"
                                    InputLabelProps={{ shrink: true }}
                                    value={newTrade?.transmission}
                                    onChange={(e) => {
                                        setNewTrade({
                                            ...newTrade,
                                            transmission: e.target.value
                                        });
                                    }}
                                >
                                    <MenuItem value="Manual">Manual</MenuItem>
                                    <MenuItem value="Automatic">
                                        Automatic
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    label="Engine Size (in cc)"
                                    type="number"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    value={newTrade?.engineSize}
                                    onChange={(e) =>
                                        setNewTrade({
                                            ...newTrade,
                                            engineSize: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Autocomplete
                                    size="small"
                                    options={specifics.driveTypes}
                                    useTwoOptionLabels={false}
                                    primaryOptionLabel="name"
                                    textfieldLabel="Drive Type"
                                    selectedValue={newTrade?.DriveType}
                                    handleSelectedValueChange={(newValue) => {
                                        setNewTrade({
                                            ...newTrade,
                                            DriveType: newValue,
                                            DriveTypeId: newValue?.id
                                        });
                                    }}
                                    handleInputValueChange={() => null}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Autocomplete
                                    size="small"
                                    options={specifics.bodyTypes}
                                    useTwoOptionLabels={false}
                                    primaryOptionLabel="name"
                                    textfieldLabel="Body Type"
                                    selectedValue={newTrade?.BodyType}
                                    handleSelectedValueChange={(newValue) => {
                                        setNewTrade({
                                            ...newTrade,
                                            BodyType: newValue,
                                            BodyTypeId: newValue?.id
                                        });
                                    }}
                                    handleInputValueChange={() => null}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="PPSR Number"
                                    InputLabelProps={{ shrink: true }}
                                    value={newTrade?.ppsrNumber}
                                    onChange={(e) =>
                                        setNewTrade({
                                            ...newTrade,
                                            ppsrNumber: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                        </>
                    ) : null}

                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            size="small"
                            label="RRP"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            value={newTrade?.priceRRP}
                            onChange={(e) =>
                                setNewTrade({
                                    ...newTrade,
                                    priceRRP: e.target.value
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setVehicleFormOpen(false)}>Close</Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        AddVehicleToList(
                            newTrade,
                            showSnackbar,
                            trades,
                            setTrades,
                            setVehicleFormOpen,
                            siteDetails
                        );
                    }}
                >
                    Add Trade
                </Button>
            </DialogActions>
        </>
    );
};

export default TradeForm;
