import { Step } from '../../interface';

export const EditCustomerPartSalesSteps: Step[] = [
    {
        label: "Click 'Parts & Accessories' -> 'Sales' in the sidebar to navigate to the sales page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-01.png'
    },
    {
        label: "Click on the Sale's ID to access the sale details page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-02.png'
    },
    {
        label: "In the sale details page, click 'Edit' to start making changes. Note: A Sale is considered a finalised transaction so there will be almost nothing you can update after submitting an order as a Sale.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-03.png'
    },
    {
        label: "Different editing options are available based on the sale status (Sale, Proforma, or Quote). For Proforma and Quote you can: unselect the current customer and select a new customer by clicking the corresponding radio button. You can also change the sale type from 'Customer' to other available options. If any payment has been added, you won't be able to change the Customer.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-04.png'
    },
    {
        label: 'Under the Orderlines tab, for a Proforma or a Quote you can: update parts, add new parts, delete parts, mark parts as collected, update added costs, and change the status of the sale. Changing the status from Proforma to Sale indicates that all parts have been collected and requires full payment.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-05.png'
    },
    {
        label: 'In the Payment tab, add new payment lines, edit existing payment lines, or delete payment lines as needed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-06.png'
    },
    {
        label: 'Click the Save Icon after making all necessary changes to update the sale.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-07.png'
    },
    {
        label: 'To update an order with the Quote status, follow the same steps as above. You can change the status from Quote to Sale or Proforma. When changing to Proforma or Sale, ensure to fill in the required payment details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-08.png'
    }
];

export const EditVehicleContractPartSalesSteps: Step[] = [
    {
        label: "Click 'Parts & Accessories' -> 'Sales' in the sidebar to navigate to the sales page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-01.png'
    },
    {
        label: 'Click on the ID of the order you wish to edit to access the order details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-contract-sale-02.png'
    },
    {
        label: (
            <>
                For a Proforma associated with a Vehicle Sale, you can update
                the order just as you would for a Customer Order. However, you
                can only change the order status from Proforma to Sale after all
                parts associated with the vehicle contract have been fully paid
                as CSM add-ons. For payment instructions, refer to{' '}
                <a
                    href="/support/vehicles/newContract/#add-csms-to-contract"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Add CSMs to a vehicle contract
                </a>{' '}
                .
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-contract-sale-03.png'
    }
];

export const SearchSalesSteps: Step[] = [
    {
        label: "Click 'Parts & Accessories' -> 'Sales' in the sidebar to navigate to the sales page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-01.png'
    },
    {
        label: 'Use the search bar to search for a specific sale. Enter relevant keywords and press Enter on your keyboard. You can search by sale ID, PO number, customer ID, customer name, customer phone number, vehicle stock number, vehicle contract ID, or service ID.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/search-sale-02.png'
    },
    {
        label: 'Click the filter icon to open the filter dialog. You can filter sales by type, status, site, user (employee), or part number to find specific sales.You may use one filter or combine multiple filters to refine your search.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/search-sale-03.png'
    }
];

export const SalesCreditInvoiceSteps: Step[] = [
    {
        label: "Click 'Parts & Accessories' -> 'Sales' in the sidebar to navigate to the sales page. Only Sale and Proforma sales can be credited/refunded.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-01.png'
    },
    {
        label: 'Locate the order you want to credit/refund, then click on the corresponding Credit Order icon. This will redirect you to the credit invoice page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/credit-sale-02.png'
    },
    {
        label: 'In case of a Sale: fill the required fields, such as till, refund method, and reason for the return.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/credit-sale-03.png'
    },
    {
        label: 'Decide which parts and the quantity to be returned. Include any necessary adjustments for freight and vouchers, if applicable.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/credit-sale-04.png'
    },
    {
        label: 'Click the Save button to submit the credit/refund.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/credit-sale-05.png'
    },
    {
        label: 'Click the Credit Order icon of the corresponding sale again to view the credit invoice details. You will see that refunded items are greyed out, refunded parts have a negative quantity, and the amount is deducted from the total amount paid.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/credit-sale-06.png'
    },
    {
        label: "You can also print the credit invoice by clicking the 'Print' button.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/credit-sale-07.png'
    },
    {
        label: 'To credit/refund invoice for a Proforma, follow the same steps as before. The only difference is that for a Proforma, you have to enter a refund amount for each item and a total refund amount.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/credit-sale-08.png'
    }
];

export const TextCustomerPartSalesSteps: Step[] = [
    {
        label: "Click 'Parts & Accessories' -> 'Sales' in the sidebar to navigate to the sales page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-01.png'
    },
    {
        label: 'Click on the ID of the order you want to access.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-02.png'
    },
    {
        label: "In the order details page, click 'Text Customer' in the speed dial menu options. This action will open a New Text Message drawer.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/text-customer-03.png'
    },
    {
        label: "In the drawer, the customer's name and phone number will be pre-filled. You can select a message template from the dropdown list or type your own message.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/text-customer-04.png'
    },
    {
        label: 'Remember to edit the message if needed before sending.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/text-customer-05.png'
    },
    {
        label: "Click 'Send Text' to send the message to the customer.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/text-customer-06.png'
    }
];
export const ViewPartSalesLogSteps: Step[] = [
    {
        label: "Click 'Parts & Accessories' -> 'Sales' in the sidebar to navigate to the sales page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-01.png'
    },
    {
        label: 'Click on the ID of the order you want to access.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/edit-sale-02.png'
    },
    {
        label: 'In the order details page, click Logs in the speed dial menu options. This will open the order update history log.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/sale-logs-03.png'
    },
    {
        label: 'In the order log drawer, you can view the change history of the sale, including changes in status, customer details, total price, parts, and payments. For example, newly added parts or price increases will be highlighted in green.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/parts/sale-logs-04.png'
    }
];
