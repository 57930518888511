import { Typography } from '@mui/material';

const PageDoesNotExist = () => {
    return (
        <>
            <Typography textAlign={'center'}>
                <img
                    src={window.location.origin + '/404_background.png'}
                    style={{ height: '80vh' }}
                    alt="This page doesn't exist."
                />
            </Typography>
        </>
    );
};

export default PageDoesNotExist;
