import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import {
    TradeIn,
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import {
    Customer,
    PaymentLine
} from '../../../global/interfaces/GeneralInterface';
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import dayjs from 'dayjs';

const GetContractDetails = (
    id: number,
    setSaleDetails: Dispatch<SetStateAction<VehicleSale>>,
    setSelectedCustomer: Dispatch<SetStateAction<Customer>>,
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle>>,
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>,
    setLinkedSales: Dispatch<SetStateAction<Order[]>>,
    setLinkedTrades: Dispatch<SetStateAction<TradeIn[]>>,
    setLinkedVouchers: Dispatch<SetStateAction<Voucher[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setGstType: Dispatch<SetStateAction<string>>,
    showSnackbar: showSnackbar,
    setInvalid: Dispatch<SetStateAction<boolean>>,
    setOldSale?: Dispatch<SetStateAction<VehicleSale>>,
    setIsFinalsied?: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    api.get(`getContractDetails/${id}`).then((res) => {
        if (res.status === 204) {
            showSnackbar(
                'Sale does not exist.',
                'Please double check your URL and try again.',
                'warning'
            );
            setLoading(false);
            setInvalid?.(true);
        } else {
            let saleDetails = res.data.contractDetails;
            let vouchers = res.data.associatedVouchers;
            let trades = res.data.associatedTrades;
            let csms = res.data.associatedPartSales;
            setSaleDetails(saleDetails);
            setOldSale?.(saleDetails);
            setGstType?.(saleDetails.gstType);
            setSelectedCustomer(saleDetails?.Customer);
            setPaymentLines(saleDetails.paymentLines);

            if (saleDetails.NewVehicle) {
                let vehicle = saleDetails.NewVehicle;
                vehicle.year = dayjs(vehicle.year);
                setSelectedVehicle(vehicle);
            } else {
                let vehicle = saleDetails.Vehicle;
                vehicle.year = dayjs(vehicle.year);
                setSelectedVehicle(vehicle);
            }

            setLinkedSales(csms);

            if (trades.length > 0) {
                let currentTrades = [...trades];
                for (let trade of trades) {
                    trade.Vehicle.year = dayjs(trade.Vehicle.year);
                }
                setLinkedTrades(currentTrades);
            }
            setLinkedVouchers(vouchers);
            setLoading(false);

            if (saleDetails.saleStatus === 'Finalised') {
                setIsFinalsied?.(true);
            }
        }
    });
};

export default GetContractDetails;
