// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
// INTERFACES
import { Customer } from '../../../../global/interfaces/GeneralInterface';

/**
 * OptionProps
 * Parameters sent through from Details.tsx
 */
interface OptionProps {
  customerInfo: Customer; // The customer's data
  setCustomerInfo: Dispatch<SetStateAction<Customer>>; // State setter for customerInfo
  disabled: boolean; // 'read-only' check
}

/**
 * Options
 * UI for a customer's trade options in ViewCustomer's 'DETAILS' tab
 * @author Estienne
 * @param OptionProps
 * @returns The customer's options
 */
const Options = ({ customerInfo, setCustomerInfo, disabled }: OptionProps) => {
  return (
    <Paper>
      <Typography variant="h6">Options</Typography>
      <Divider />
      <br />
      <Grid container spacing={2} direction="row" textAlign="center">
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                disabled={disabled}
                checked={customerInfo?.international ? true : false}
                onChange={(e) =>
                  setCustomerInfo({
                    ...customerInfo,
                    international: e.target.checked
                  })
                }
              />
            }
            label="International"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                disabled={disabled}
                checked={customerInfo?.gstFree ? true : false}
                onChange={(e) =>
                  setCustomerInfo({
                    ...customerInfo,
                    gstFree: e.target.checked
                  })
                }
              />
            }
            label="GST Free"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                disabled={disabled}
                checked={customerInfo?.gstRegistered ? true : false}
                onChange={(e) =>
                  setCustomerInfo({
                    ...customerInfo,
                    gstRegistered: e.target.checked
                  })
                }
              />
            }
            label="GST Registered"
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse
            sx={{ paddingTop: 0.75 }}
            in={customerInfo?.customerType === 'Trade'}
          >
            <TextField
              fullWidth
              size="small"
              type="number"
              variant="outlined"
              disabled={disabled}
              label="Trade Discount"
              value={customerInfo?.tradeDiscount}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setCustomerInfo({
                  ...customerInfo,
                  tradeDiscount: parseFloat(e.target.value)
                })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Chip
                      size="small"
                      disabled={disabled}
                      color={
                        customerInfo?.discountType === 'Trade'
                          ? 'success'
                          : 'warning'
                      }
                      label={
                        customerInfo?.discountType === 'Trade'
                          ? 'Cost +'
                          : 'Retail -'
                      }
                      clickable={true}
                      onClick={() => {
                        if (customerInfo?.discountType === 'Trade') {
                          setCustomerInfo({
                            ...customerInfo,
                            discountType: 'Retail'
                          });
                        } else {
                          setCustomerInfo({
                            ...customerInfo,
                            discountType: 'Trade'
                          });
                        }
                      }}
                    />
                  </InputAdornment>
                )
              }}
            ></TextField>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Options;
