//IMPORTS
import { useState } from 'react';
import { Typography } from '@mui/material';
import { Dayjs } from 'dayjs';

// COMPONENTS
import Tabs from '../../global/Tabs';
import PNLReporting from './ReportingComponent/PNL/PNLReporting';
import GSTReporting from './ReportingComponent/GST/GSTReporting';
import StaffSalesReporting from './ReportingComponent/StaffSalesReports/StaffSalesReporting';
import BalanceSheet from './ReportingComponent/BalanceSheet/BalanceSheet';
import RoleCheck from '../../global/RoleCheck';
import TrialBalance from './ReportingComponent/TrialBalance/TrialBalance';
import { Site } from '../../global/interfaces/GeneralInterface';

// LOGIC

const ReportingUITS = () => {
  const [selectedSite, setSelectedSite] = useState<Site[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [queryDate, setQueryDate] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({
    startDate: null,
    endDate: null
  });

  const pnlReporting = {
    id: 0,
    title: 'PNL Reports',
    content: (
      <PNLReporting
        selectedSite={selectedSite}
        setSelectedSite={setSelectedSite}
        queryDate={queryDate}
        setQueryDate={setQueryDate}
        loading={loading}
        setLoading={setLoading}
      />
    )
  };
  const gstReporting = {
    id: 1,
    title: 'GST Reports',
    content: (
      <GSTReporting
        selectedSite={selectedSite}
        setSelectedSite={setSelectedSite}
        queryDate={queryDate}
        setQueryDate={setQueryDate}
        loading={loading}
        setLoading={setLoading}
      />
    )
  };

  const staffSalesReporting = {
    id: 2,
    title: 'Staff Sales Reports',
    content: (
      <StaffSalesReporting
        selectedSite={selectedSite}
        setSelectedSite={setSelectedSite}
        queryDate={queryDate}
        setQueryDate={setQueryDate}
        loading={loading}
        setLoading={setLoading}
      />
    )
  };

  const balanceSheetReporting = {
    id: 3,
    title: 'Balance Sheet',
    content: <BalanceSheet loading={loading} setLoading={setLoading} />
  };

  const trialBalance = {
    id: 4,
    title: 'Trial Balance',
    content: (
      <RoleCheck
        permission="access_trialBalance"
        component={<TrialBalance loading={loading} setLoading={setLoading} />}
        pageError
      />
    )
  };

  const tabContent = [
    pnlReporting,
    gstReporting,
    staffSalesReporting,
    balanceSheetReporting,
    trialBalance
  ];

  return (
    <div id="table">
      <br />
      <Typography variant="h4" align="left">
        Ora Generated Reports
      </Typography>
      <br />
      <Tabs tabContent={tabContent} />
    </div>
  );
};

export default ReportingUITS;
