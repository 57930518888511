// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Service } from '../interfaces/ServiceInterface';

const GetAllServices = (setServices: Dispatch<SetStateAction<Service[]>>) => {
  api.get('allServices').then((res) => {
    setServices(res.data);
  });
};

export default GetAllServices;
