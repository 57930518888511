import { Dispatch, SetStateAction } from 'react';
import {
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const HandleUpdatePurchaseOrder = (
    selectedPurchaseOrder: PurchaseOrder,
    showSnackbar: showSnackbar,
    isInvoice,
    invoiceDetails,
    noRefresh?: boolean,
    setReadOnly?: Dispatch<SetStateAction<boolean>>,
    purchaseOrders?,
    setPurchaseOrders?,
    serviceDetails?: Service,
    setServiceDetails?: Dispatch<SetStateAction<Service>>
) => {
    if (!selectedPurchaseOrder?.ServiceId) {
        showSnackbar(
            'You must select an RO that this purchase order links to.',
            '',
            'error'
        );
        return;
    }
    if (!selectedPurchaseOrder?.SupplierId) {
        showSnackbar(
            'You must select a Creditor that this purchase order links to.',
            '',
            'error'
        );
        return;
    }
    if (!selectedPurchaseOrder?.amount || selectedPurchaseOrder?.amount < 0) {
        showSnackbar(
            'You must enter a valid amount for the purchase order.',
            '',
            'error'
        );
        return;
    }
    if (!selectedPurchaseOrder?.orderReference) {
        showSnackbar(
            'You must enter an order reference the purchase order.',
            '',
            'error'
        );
        return;
    }

    if (isInvoice) {
        if (!invoiceDetails.documentReference) {
            showSnackbar(
                'You must enter a document reference the purchase order.',
                '',
                'error'
            );
            return;
        }

        if (!invoiceDetails.documentTotal) {
            showSnackbar(
                'You must enter a document total the purchase order.',
                '',
                'error'
            );
            return;
        }

        if (!invoiceDetails.invoiceDate) {
            showSnackbar(
                'You must enter an invoice date for the purchase order.',
                '',
                'error'
            );
            return;
        }
    }
    let obj = {
        selectedPurchaseOrder: selectedPurchaseOrder,
        isInvoice: isInvoice,
        invoiceDetails: invoiceDetails
    };
    api.put(
        `updatePurchaseOrder/${selectedPurchaseOrder.id}?SiteId=${localStorage.getItem('SiteId')}`,
        obj
    ).then((res) => {
        if (res.status === 200) {
            if (noRefresh) {
                let currentPurchaseOrders = [...purchaseOrders];
                let index = currentPurchaseOrders.findIndex(
                    (x) => x.id === selectedPurchaseOrder.id
                );
                currentPurchaseOrders[index] = selectedPurchaseOrder;
                setPurchaseOrders(currentPurchaseOrders);

                let currentServiceDetails = serviceDetails;
                currentServiceDetails.subletTotal = res.data.newSubletCharge;
                setServiceDetails({
                    ...serviceDetails,
                    subletTotal: res.data.newSubletCharge
                });

                setReadOnly(true);
            } else {
                window.location.reload();
            }
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleUpdatePurchaseOrder;
