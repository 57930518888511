// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Account } from '../../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface AccountError {
    name: boolean;
    type: boolean;
    initialBalance: boolean;
}

// Update account after some basic validation
const HandleUpdateAccount = (
    selectedAccount: Account,
    resultsList: Account[],
    setResultsList: Dispatch<SetStateAction<Account[]>>,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    setAccountError: Dispatch<SetStateAction<AccountError>>
) => {
    let newAccountList = JSON.parse(JSON.stringify(resultsList));
    let accountIndex = newAccountList.findIndex(
        (x) => x.id === selectedAccount.id
    );
    newAccountList[accountIndex] = selectedAccount;
    setResultsList(newAccountList);

    let isError = false;
    const errors = {
        name: false,
        type: false,
        initialBalance: false
    };

    if (selectedAccount?.name == null || selectedAccount?.name === '') {
        isError = true;
        errors.name = true;
    }

    if (!isError) {
        api.put('updateAccountNextGen', selectedAccount).then((res) => {
            if (res.status === 200) {
                showSnackbar('Account successfully updated.', '');
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    } else {
        setAccountError(errors);
        showSnackbar(
            'Whoops! You have missing or invalid data.',
            'Please fill out the highlighted fields to continue.',
            'error'
        );
    }
};

export default HandleUpdateAccount;
