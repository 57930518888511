import { CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// COMPONENTS
import Paper from '../../../global/Paper';
import Counters from './Counters';
import ViewOrders from './ViewOrders';

// LOGIC
import GetAllPickPackOrders from '../../logic/PickAndPack/GetAllPickPackOrders';

// INTERFACE
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';

interface GroupedOnlineOrder {
  oraOrder: Order;
  netoOrder: OnlineOrder;
}
interface GroupedOnlineOrders {
  pick: GroupedOnlineOrder[];
  pack: GroupedOnlineOrder[];
}
const PickAndPack = () => {
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [selectedGroupOrders, setSelectedGroupOrders] = useState<
    GroupedOnlineOrder[]
  >([]);
  const [selectedGroupTitle, setSelectedGroupTitle] = useState<string>('Pick');
  const [pickPackOrders, setPickPackOrders] = useState<GroupedOnlineOrders>({
    pick: [],
    pack: []
  });

  useEffect(() => {
    GetAllPickPackOrders(
      setPickPackOrders,
      setSelectedGroupOrders,
      setPageLoading
    );
  }, []);

  return (
    <>
      {pageLoading ? (
        <Paper>
          <Typography textAlign="center">
            <CircularProgress />
          </Typography>
        </Paper>
      ) : (
        <>
          <Counters
            pickPackOrders={pickPackOrders}
            setSelectedGroupOrders={setSelectedGroupOrders}
            selectedGroupTitle={selectedGroupTitle}
            setSelectedGroupTitle={setSelectedGroupTitle}
          />
          <br />
          <ViewOrders
            selectedGroupOrders={selectedGroupOrders}
            setSelectedGroupOrders={setSelectedGroupOrders}
            selectedGroupTitle={selectedGroupTitle}
            pickPackOrders={pickPackOrders}
            setPickPackOrders={setPickPackOrders}
          />
        </>
      )}
    </>
  );
};

export default PickAndPack;
