import {
  Grid,
  Pagination,
  TableRow,
  TextField,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

// COMPONENT
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';

// INTERFACE
import { Category, Unit } from '../../../../global/interfaces/PartsInterface';
interface TempCategory extends Category {
  nbOfUnit: number;
}

interface CategoryDialogContentProps {
  selectedCategory: TempCategory;
  filterValue: string;
  filterListOfUnit: Unit[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setFilterValue: Dispatch<SetStateAction<string>>;
  handleFilterValueChange: (value: string) => void;
}

const CategoryDrawerContent = ({
  selectedCategory,
  filterValue,
  filterListOfUnit,
  page,
  setPage,
  handleFilterValueChange
}: CategoryDialogContentProps) => {
  // Drawer state
  const rowsPerPage = 20;
  const dialogColumns = [
    { id: 0, label: 'Part Number' },
    { id: 1, label: 'Part Name' }
  ];

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            size="small"
            label="Part Number"
            value={filterValue}
            onChange={(e) => handleFilterValueChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={7} textAlign="right">
          <Pagination
            color="primary"
            count={
              filterListOfUnit.length <= rowsPerPage
                ? 1
                : Math.ceil(filterListOfUnit.length / rowsPerPage)
            }
            page={page}
            siblingCount={0}
            boundaryCount={1}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'right' }}
          />
        </Grid>
      </Grid>
      <br />
      <DataTable columns={dialogColumns}>
        {filterListOfUnit
          .slice((page - 1) * rowsPerPage, page * rowsPerPage)
          .map((unit) => (
            <TableRow>
              <DataCellColoured
                handleClick={() =>
                  window.open('/inventory/view/' + unit.id, '_blank')
                }
              >
                {unit.partNumber}
              </DataCellColoured>
              <DataCell>{unit.name}</DataCell>
            </TableRow>
          ))}
      </DataTable>
    </>
  );
};

export default CategoryDrawerContent;
