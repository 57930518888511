// IMPORTS
import { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    IconButton,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import MuiFab from '@mui/material/Fab';
import { Grading, SpeakerNotes, Save, Clear } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import PartList from './components/PartList';
import Tabs from '../../global/Tabs';
import SpeedDial from '../../global/SpeedDial';
// LOGIC
import GetNewStocktakeDetails from './logic/GetNewStocktakeDetails';
import HandleSubmitStocktake from './logic/HandleSubmitStocktake';

// INTERFACES
import { BinLocation } from '../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

interface StocktakeDetails {
    countedParts: any[];
    uncountedParts: any[];
    movedBins: any[];
    notes: string;
    review: boolean;
}

const Stocktake = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();

    const [notesOpen, setNotesOpen] = useState<boolean>(false);
    const [binLocationDetails, setBinLocationDetails] = useState<BinLocation>();
    const [stocktakeDetails, setStocktakeDetails] = useState<StocktakeDetails>({
        countedParts: [],
        uncountedParts: [],
        movedBins: [],
        notes: null,
        review: false
    });

    const actionsList = [
        {
            name: 'Save Stocktake',
            icon: <Save />,
            onClick: () =>
                HandleSubmitStocktake(
                    stocktakeDetails,
                    binLocationDetails,
                    showSnackbar
                )
        },
        {
            name: 'Back to Stocktake',
            icon: <Clear />,
            onClick: () =>
                setStocktakeDetails({ ...stocktakeDetails, review: false })
        }
    ];

    useEffect(() => {
        if (id) {
            GetNewStocktakeDetails(
                parseInt(id),
                setBinLocationDetails,
                stocktakeDetails,
                setStocktakeDetails
            );
        }
        // eslint-disable-next-line
    }, [id]);

    const getVarianceCount = () => {
        let variance = 0;

        stocktakeDetails.countedParts.forEach((line) => {
            if (line.counted !== line.expected) {
                variance += 1;
            }
        });

        stocktakeDetails.uncountedParts.forEach((line) => {
            if (line.counted !== line.expected) {
                variance += 1;
            }
        });

        return variance;
    };

    const tabContent = [
        {
            id: 0,
            title: 'Counted',
            iconNumber:
                stocktakeDetails.countedParts.length > 0
                    ? String(stocktakeDetails.countedParts.length)
                    : '',
            content: (
                <PartList
                    stocktakeDetails={stocktakeDetails}
                    setStocktakeDetails={setStocktakeDetails}
                    pageType="Counted"
                />
            )
        },
        {
            id: 1,
            title: 'Not Counted',
            iconNumber:
                stocktakeDetails.uncountedParts.length > 0
                    ? String(stocktakeDetails.uncountedParts.length)
                    : '',
            content: (
                <PartList
                    stocktakeDetails={stocktakeDetails}
                    setStocktakeDetails={setStocktakeDetails}
                    pageType="Uncounted"
                />
            )
        },
        {
            id: 2,
            title: 'Moved Bins',
            iconNumber:
                stocktakeDetails.movedBins.length > 0
                    ? String(stocktakeDetails.movedBins.length)
                    : '',
            content: (
                <PartList
                    stocktakeDetails={stocktakeDetails}
                    setStocktakeDetails={setStocktakeDetails}
                    pageType="Moved Bins"
                />
            )
        },
        {
            id: 3,
            title: 'Variance',
            iconNumber:
                getVarianceCount() > 0 ? String(getVarianceCount()) : '',
            content: (
                <PartList
                    stocktakeDetails={stocktakeDetails}
                    setStocktakeDetails={setStocktakeDetails}
                    pageType="Variance"
                />
            )
        }
    ];

    return (
        <PageWrapper>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    {stocktakeDetails.review ? (
                        <Typography variant="h4">Review Stocktake</Typography>
                    ) : (
                        <Typography variant="h4">New Stocktake</Typography>
                    )}

                    <Typography variant="h6">
                        Bin Location: {binLocationDetails?.name}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography align="right">
                        <IconButton onClick={() => setNotesOpen(true)}>
                            <SpeakerNotes />
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>

            <Tabs tabContent={tabContent} />

            <Dialog
                open={notesOpen}
                onClose={() => setNotesOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Stocktake Notes</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        value={stocktakeDetails.notes}
                        onChange={(e) =>
                            setStocktakeDetails({
                                ...stocktakeDetails,
                                notes: e.target.value
                            })
                        }
                    />
                    <Typography variant="subtitle2">
                        <i>Notes automatically save to stocktake.</i>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNotesOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            {stocktakeDetails.review ? (
                <SpeedDial actionsList={actionsList} />
            ) : (
                <div
                    style={{
                        margin: 0,
                        top: 'auto',
                        right: 20,
                        bottom: 20,
                        left: 'auto',
                        position: 'fixed',
                        zIndex: 1
                    }}
                >
                    <MuiFab
                        color="primary"
                        onClick={() => {
                            setStocktakeDetails({
                                ...stocktakeDetails,
                                review: true
                            });
                            showSnackbar(
                                'Please review the stocktake before submitting.',
                                '',
                                'warning'
                            );
                        }}
                    >
                        <Grading />
                    </MuiFab>
                </div>
            )}
        </PageWrapper>
    );
};

export default withSnackbar(Stocktake);
