// IMPORTS
import { Dispatch, SetStateAction } from 'react';

/**
 * CalculateAmountRemaining
 * Calculate the amount remaining for an invoice
 * @author Estienne
 * @param invoiceTotal the total amount required for the invoice
 * @param paymentAmount the amount currently being paid
 * @param paymentHistory a history of all payments previously made on the invoice
 * @returns the remaining amount required to be paid
 */
export const CalculateAmountRemaining = (
  invoiceTotal: number,
  paymentAmount: number,
  paymentHistory: any[]
) => {
  let amountRemaining = invoiceTotal;
  amountRemaining -= paymentAmount;
  if (paymentHistory?.length > 0) {
    paymentHistory.forEach((payment) => {
        if (payment.removed !== true) {
            amountRemaining -= payment.amount;
        } // If the payment has been removed, don't subtract the amount from the total
    });
  }

  return amountRemaining;
};

/**
 * GroupInvoices
 * Group the invoices by a specific parameter
 * @author Estienne
 * @param arr the array to be grouped
 * @param key the key to group the array by
 * @returns the array, grouped by the key
 */
export const GroupInvoices = (arr: any[], key: string) => {
  return arr.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, []);
};

/**
 * FilterInvoices
 * Filter the invoices by a specific site
 * @author Estienne
 * @param site the site to filter the grouped invoices by
 * @param groupedInvoices the invoices, grouped by site
 * @returns all the invoices from a specific site
 */
export const FilterInvoices = (site: string, groupedInvoices: any[]) => {
  let siteInvoices = [];
  groupedInvoices.forEach((siteGroup) => {
    siteGroup.forEach((invoice) => {
      if (invoice.Site.name === site) {
        siteInvoices.push(invoice);
      }
    });
  });

  return siteInvoices;
};

/**
 * PaginateInvoices
 * Get all invoices for a specific site and paginate the results
 * @author Estienne
 * @param invoices the invoices to be paginated
 * @param pageNumber the current page number
 * @param setPageCount state setter for the number of total pages
 * @param setPaginatedRows state setter for the paginated invoices
 */
export const PaginateInvoices = (
  invoices: any[],
  pageNumber: number,
  setPageCount: Dispatch<SetStateAction<number>>,
  setPaginatedRows: Dispatch<SetStateAction<any[]>>
) => {
  let offset = 0;
  let limit = 15;
  if (pageNumber < 2) {
    offset = 0;
  } else {
    offset = pageNumber * limit - limit;
  } // (e.g. offset = (2 * 20) - 20) = 20)
  let numPages = Math.ceil(invoices.length / limit); // (e.g. 0.4 pages = 1 page)
  setPageCount(numPages);
  setPaginatedRows(invoices.slice(offset, offset + limit));
};

/**
 * FilterAndPaginate
 * A combination of the above filter and pagination functions
 * @author Estienne
 * @param site the currently-selected site
 * @param groupedInvoices an array of invoices, grouped by site
 * @param pageNumber the current page number
 * @param setPageCount state setter for the number of pages for a site's invoice list
 * @param setPaginatedRows state setter for a site's invoices
 */
export const FilterAndPaginate = (
  site: string,
  groupedInvoices: any[],
  pageNumber: number,
  setPageCount: Dispatch<SetStateAction<number>>,
  setPaginatedRows: Dispatch<SetStateAction<any[]>>
) => {
  let filteredInvoices = FilterInvoices(site, groupedInvoices);
  PaginateInvoices(
    filteredInvoices,
    pageNumber,
    setPageCount,
    setPaginatedRows
  );
};
