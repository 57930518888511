import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const BulkUpdateCustomerBackorders = (
    backorderIds: number[],
    status: string,
    showSnackbar: showSnackbar
) => {
    api.put('bulkUpdateCustomerBackordersStatus', {
        backorderIds: backorderIds,
        status: status
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                backorderIds.length.toString() +
                    ' Customer Backorders updated.',
                'The page will now refresh.'
            );
            setTimeout(() => window.location.reload(), 1000);
        } else {
            showSnackbar(
                'Something went wrong, please refresh the page and try again.',
                "If it isn't resolved, please contact your IT department.",
                'error'
            );
        }
    });
};

export default BulkUpdateCustomerBackorders;
