// IMPORTS
import { useState, Dispatch, SetStateAction } from 'react';
import {
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    Typography,
    IconButton,
    Checkbox
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
// LOGIC
import PDFDebtorInvoice from '../logic/PDFDebtorInvoice';
import EmailCustomerInvoice from '../logic/EmailCustomerInvoice';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface SelectedDebtor {
    CustomerId: number;
    CustomerDetails: {
        name: string;
        firstName: string;
        lastName: string;
        companyName: string;
        phoneNumber: string;
        abn: string;
        paymentTerms: string;
        accountLimit: string;
        addressLine1: string;
        suburb: string;
        state: string;
        postcode: string;
        email: string;
    };
    rows: any[];
}

interface InvoiceDialogContentProps {
    selectedDebtor: SelectedDebtor;
    balance: number;
    setInvoiceDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const InvoiceDialogContent = ({
    selectedDebtor,
    setInvoiceDialogOpen,
    showSnackbar
}: InvoiceDialogContentProps) => {
    const [selectedMonth, setSelectedMonth] = useState<Dayjs>(dayjs());
    const [startDate, setStartDate] = useState<string>(
        dayjs().startOf('month').toISOString()
    );
    const [endDate, setEndDate] = useState<string>(
        dayjs().endOf('month').toISOString()
    );
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [displayProformaOnStatement, setDisplayProformaOnStatement] =
        useState<boolean>(false);

    // Checks the start and end date and grabs the transactions accordingly
    const handleGenerateInvoice = (type: string, displayProforma: boolean) => {
        setButtonLoading(true);
        let currentRows = [...selectedDebtor.rows];
        let totalDue = 0;

        let newSelectedDebtor = {
            CustomerId: selectedDebtor.CustomerId,
            CustomerDetails: selectedDebtor.CustomerDetails,
            rows: currentRows
        };

        if (type === 'email') {
            if (!newSelectedDebtor?.CustomerDetails?.email) {
                showSnackbar(
                    'Customer has no email registered.',
                    'Please give the customer a valid email address to continue.',
                    'error'
                );
                setButtonLoading(false);
            } else {
                EmailCustomerInvoice(
                    newSelectedDebtor,
                    totalDue,
                    startDate,
                    endDate,
                    displayProforma,
                    setButtonLoading,
                    showSnackbar
                );
            }
        } else {
            PDFDebtorInvoice(
                newSelectedDebtor,
                totalDue,
                startDate,
                endDate,
                displayProforma
            );
            setButtonLoading(false);
        }
    };

    return (
        <>
            <DialogTitle>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        Print Account Statement
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                        <IconButton onClick={() => setInvoiceDialogOpen(false)}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={8}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="MMMM YYYY"
                                label="Month"
                                value={selectedMonth}
                                views={['month', 'year']}
                                onChange={(newValue) => {
                                    setSelectedMonth(newValue);
                                    setStartDate(
                                        newValue.startOf('month').toISOString()
                                    );
                                    setEndDate(
                                        newValue.endOf('month').toISOString()
                                    );
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>
                            <Checkbox
                                checked={displayProformaOnStatement}
                                onChange={() =>
                                    setDisplayProformaOnStatement(
                                        !displayProformaOnStatement
                                    )
                                }
                            />{' '}
                            Display Proforma(s){' '}
                        </Typography>
                    </Grid>
                    {selectedDebtor.CustomerDetails.email ? (
                        <Typography variant="caption">
                            <i>
                                Clicking "Email to Customer" will send an email
                                with the statement to the following email
                                address:
                            </i>{' '}
                            {selectedDebtor.CustomerDetails.email}
                        </Typography>
                    ) : (
                        <Typography variant="caption">
                            <i>
                                The selected customer doesn't have an email
                                address. You won't be able to send them the
                                statement in an email.
                            </i>
                        </Typography>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={buttonLoading}
                    color="primary"
                    onClick={() =>
                        handleGenerateInvoice(
                            'email',
                            displayProformaOnStatement
                        )
                    }
                >
                    Email to Customer
                </LoadingButton>
                <LoadingButton
                    loading={buttonLoading}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        handleGenerateInvoice(
                            'statement',
                            displayProformaOnStatement
                        )
                    }
                >
                    Generate Statement
                </LoadingButton>
                {/* <LoadingButton loading={buttonLoading} variant="contained" color="primary" onClick={() => handleGenerateInvoice("invoice", displayProformaOnStatement)}>
                    Generate Invoice
                </LoadingButton> */}
            </DialogActions>
        </>
    );
};

export default withSnackbar(InvoiceDialogContent);
