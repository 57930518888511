import { Dispatch, SetStateAction } from 'react';
import { DialogContent, Fab } from '@mui/material';

import { Close } from '@material-ui/icons';

import NewSale from '../../../../parts/newSale/NewSale';
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';

interface JobDialogProps {
    vehicleSaleId: number;
    saleDetails: VehicleSale;
    setDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const CSMDialog = ({
    vehicleSaleId,
    saleDetails,
    setDialogOpen
}: JobDialogProps) => {
    return (
        <DialogContent>
            <NewSale
                vehicleSaleId={vehicleSaleId}
                vehicleSaleDetails={saleDetails}
            />
            <Fab
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 85,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
                onClick={() => {
                    setDialogOpen(false);
                }}
            >
                <Close />
            </Fab>
        </DialogContent>
    );
};

export default CSMDialog;
