// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface ManuallyAdjustOnlineReservedParams {
    (
        UnitId: number,
        SiteId: number,
        onlineQuantityAdjust: number,
        showSnackbar: showSnackbar,
        setButtonLoading: Dispatch<SetStateAction<boolean>>
    ): void;
}

/*  ManuallyAdjustStock
    Validates and manually adjusts the stock of a given part
    params:
        - UnitId: The id of the unit being adjusted
        - SiteId: The id of the current site
        - onlineQuantityAdjust: The object containing the adjustment details
        - setSnackbar: The setter state for the snackbar to send user feedback
*/
const ManuallyAdjustOnlineReserved: ManuallyAdjustOnlineReservedParams = (
    UnitId,
    SiteId,
    onlineQuantityAdjust,
    showSnackbar,
    setButtonLoading
) => {
    setButtonLoading(true);
    let obj = {
        UnitId: UnitId,
        SiteId: SiteId,
        adjustmentValue: onlineQuantityAdjust
    };
    api.put('onlineReservedStockAdjustment', obj).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Manual stock adjustment successful.',
                'The window will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'It looks like something went wrong on our end.',
                'Please contact your IT team.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default ManuallyAdjustOnlineReserved;
