import { Typography, Box } from '@material-ui/core';
import { List, ListItemButton, ListItemText } from '@mui/material';

interface TableOfContentsProps {
    baseHref: string;
    headings: HTMLCollectionOf<Element>;
    currentSection: string;
}
const TableOfContents = ({
    baseHref,
    headings,
    currentSection
}: TableOfContentsProps) => {
    return (
        <Box
            sx={{
                position: 'sticky',
                top: '20px',
                width: '100%',
                textAlign: 'left',
                margin: '1rem'
            }}
        >
            <Typography> CONTENTS</Typography>
            <List component="nav" aria-label="table of contents">
                {Array.from(headings).map((h) => (
                    <ListItemButton
                        key={h.id}
                        component="a"
                        href={`${baseHref}/#${h.id}`}
                        selected={currentSection === h.id}
                    >
                        <ListItemText
                            primary={h.textContent}
                            primaryTypographyProps={{ variant: 'body2' }}
                        />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );
};

export default TableOfContents;
