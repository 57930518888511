import { Dispatch, SetStateAction } from 'react';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import api from '../../../../../api';

interface GroupedOnlineOrder {
  oraOrder: Order;
  netoOrder: OnlineOrder;
}

interface GroupedOnlineOrders {
  onHold: GroupedOnlineOrder[];
  onBackorder: GroupedOnlineOrder[];
  newOrders: GroupedOnlineOrder[];
  pendingDispatch: GroupedOnlineOrder[];
}

const GetDashboardData = (
  setGroupedOrders: Dispatch<SetStateAction<GroupedOnlineOrders>>,
  setSelectedGroupedOrder: Dispatch<SetStateAction<GroupedOnlineOrder[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  setLoading(true);
  api.get('onlineDashboardData').then((res) => {
    setGroupedOrders(res.data);
    setSelectedGroupedOrder(res.data.newOrders);
    setLoading(false);
  });
};

export default GetDashboardData;
