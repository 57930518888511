import { Dispatch, SetStateAction } from 'react';
import {
    Creditor,
    OrderFileGenerator,
    Site
} from '../../../global/interfaces/GeneralInterface';

interface HandleSelectApiParams {
    index: number;
    apiData: SelectedApi[];
    generatorList: OrderFileGenerator[];
    setSelectedApi: Dispatch<SetStateAction<SelectedApi>>;
    setEdit: Dispatch<SetStateAction<boolean>>;
    setAddOpen: Dispatch<SetStateAction<boolean>>;
}

interface SelectedApi {
    id?: number;
    name: string;
    nameError: boolean;
    description: string;
    descriptionError: boolean;
    Site: Site;
    siteError: boolean;
    key: string;
    Supplier: Creditor;
    supplierError: boolean;
    canGenerateOrderFile: boolean;
    canOrderWithAPI: boolean;
    Generator: OrderFileGenerator;
    generatorError: boolean;
    OrderApi: OrderFileGenerator;
    OrderApiError: boolean;
    detailsList: {};

    OrderFileGeneratorId?: number;
    ApiOrderId?: number;
    additionalDetails?: {};
}

const HandleSelectApi = (params: HandleSelectApiParams) => {
    let currentIndexApiData = params.apiData[params.index];

    const generatorIdMatch = (x) =>
        x.id === currentIndexApiData.OrderFileGeneratorId;
    const apiIdMatch = (x) => x.id === currentIndexApiData.ApiOrderId;

    let temp = {};

    Object.keys(currentIndexApiData.additionalDetails).forEach((key) => {
        temp[key] = currentIndexApiData.additionalDetails[key];
    });

    params.setSelectedApi({
        id: currentIndexApiData.id,
        name: currentIndexApiData.name,
        nameError: false,
        description: currentIndexApiData.description,
        descriptionError: false,
        Site: currentIndexApiData.Site ?? {},
        siteError: false,
        key: currentIndexApiData.key,
        Supplier: currentIndexApiData.Supplier ?? {},
        supplierError: false,
        canGenerateOrderFile: currentIndexApiData.canGenerateOrderFile ?? false,
        Generator: currentIndexApiData.OrderFileGeneratorId
            ? params.generatorList.find(generatorIdMatch)
            : {},
        generatorError: false,
        canOrderWithAPI: currentIndexApiData.canOrderWithAPI ?? false,
        OrderApi: currentIndexApiData.ApiOrderId
            ? params.generatorList.find(apiIdMatch)
            : {},
        OrderApiError: false,
        detailsList: temp
    });

    params.setEdit(true);
    params.setAddOpen(true);
};

export default HandleSelectApi;
