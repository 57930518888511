import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import PDFMerger from 'pdf-merger-js/browser';
import { Buffer } from 'buffer';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
const PrintMultiplePostageLabel = (
    selectedOrders: GroupedOnlineOrder[],
    showSnackbar: showSnackbar,
    setLabelLoading: Dispatch<SetStateAction<boolean>>
) => {
    setLabelLoading(true);
    api.post(`/printMultipleProductionPostageLabel`, {
        selectedOrders: selectedOrders,
        SiteId: localStorage.getItem('SiteId'),
        responseType: 'blob'
    }).then(async (res) => {
        // Aus post api returned an error trying to either create shipment or label
        if (res.status === 400) {
            showSnackbar(
                'Aus Post Label Error!',
                res.data.errorMessage,
                'error'
            );
            setLabelLoading(false);
        } else if (res.status === 500) {
            showSnackbar(
                'Whoops! Something went wrong on our end!',
                'Please contact your IT department.',
                'error'
            );
            setLabelLoading(false);
        } else {
            // Create a print pop-up window for each label, the next one opening when the previous one is closed
            const render = async () => {
                const merger = new PDFMerger();

                for (const label of res.data) {
                    const file = new Blob([Buffer.from(label, 'base64')], {
                        type: 'application/pdf'
                    });
                    await merger.add(file);
                }
                const mergedPdf = await merger.saveAsBlob();
                const url = URL.createObjectURL(mergedPdf);

                return url;
            };

            let mergedPdf = await render();

            let iframe = document.createElement('iframe'); //load content in an iframe to print later
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = mergedPdf;
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1);
            };

            setLabelLoading(false);
        }
    });
};

export default PrintMultiplePostageLabel;
