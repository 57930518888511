// IMPORTS
import { Dispatch, SetStateAction } from "react";

// LOGIC
import api from "../../../../api";
import { Unit } from "../interfaces/PartsInterface";

/**
 * GetParts
 * Get all parts from the server
 * @author Pierre
 * @param keyword a given search string
 * @param setParts state setter for the retrieved parts
 */
const GetParts = (keyword: string, setParts: Dispatch<SetStateAction<Unit[]>>) => {
    api.get("parts/" + keyword).then((res) => {
        setParts(res.data);
    });
};

export default GetParts;
