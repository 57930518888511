import { Table, TableRow, TableCell, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { calculateQLDStampDuty } from '../../logic/CalculateStampDuty';
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';
import { StampDutyVehicleType } from '../../type/vehicle';

interface QLDStampDutyProps {
    saleDetails: VehicleSale;
    setSaleDetails: Dispatch<SetStateAction<VehicleSale>>;
}

const QLDStampDuty = ({ saleDetails, setSaleDetails }: QLDStampDutyProps) => {
    return (
        <>
            <Typography align="center" variant="h6">
                Estimate Stamp Duty
            </Typography>
            <br />

            <Table>
                <TableRow>
                    {/* <TableCell></TableCell> */}
                    <TableCell align="left" style={{ verticalAlign: 'top' }}>
                        <Typography variant="subtitle1">
                            <b>
                                Hybrid or Electric
                            </b>
                        </Typography>
                    </TableCell>
                    <TableCell align="left" style={{ verticalAlign: 'top' }}>
                        <Typography variant="subtitle1">
                            <b>
                                1 to 4 cylinders <br />
                            </b>
                        </Typography>
                    </TableCell>
                    <TableCell align="left" style={{ verticalAlign: 'top' }}>
                        <Typography variant="subtitle1">
                            <b>
                                5 or 6 cylinders <br />
                            </b>
                        </Typography>
                    </TableCell>
                    <TableCell align="left" style={{ verticalAlign: 'top' }}>
                        <Typography variant="subtitle1">
                            <b>7 or more cylinders</b>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    {/* <TableCell align="left" style={{ verticalAlign: 'top' }}>
                        <Typography variant="subtitle1">
                            <b>Light Vehicles</b>
                        </Typography>
                    </TableCell> */}
                    <TableCell align="left">
                        {calculateQLDStampDuty(
                            saleDetails.vehicleSalePrice,
                            StampDutyVehicleType.HybridElectric,
                            false
                        )}
                        <br />
                    </TableCell>
                    <TableCell align="left">
                        {calculateQLDStampDuty(
                            saleDetails.vehicleSalePrice,
                            StampDutyVehicleType.OneToFourCylinders,
                            false
                        )}
                        <br />
                    </TableCell>
                    <TableCell align="left">
                        {calculateQLDStampDuty(
                            saleDetails.vehicleSalePrice,
                            StampDutyVehicleType.FiveOrSixCylinders,
                            false
                        )}
                        <br />
                    </TableCell>
                    <TableCell align="left">
                        {calculateQLDStampDuty(
                            saleDetails.vehicleSalePrice,
                            StampDutyVehicleType.SevenOrMoreCylinders,
                            false
                        )}
                        <br />
                    </TableCell>
                    <TableCell align="left"></TableCell>
                </TableRow>
                {/* <TableRow>
                    <TableCell align="left">
                        <Typography variant="subtitle1">
                            <b>Heavy Vehicles</b>
                        </Typography>
                    </TableCell>
                    <TableCell align="left">
                        {calculateQLDStampDuty(
                            saleDetails.vehicleSalePrice,
                            StampDutyVehicleType.HybridElectric,
                            true
                        )}
                        <br />
                    </TableCell>
                    <TableCell align="left">
                        {calculateQLDStampDuty(
                            saleDetails.vehicleSalePrice,
                            StampDutyVehicleType.OneToFourCylinders,
                            true
                        )}
                        <br />
                    </TableCell>
                    <TableCell align="left">
                        {calculateQLDStampDuty(
                            saleDetails.vehicleSalePrice,
                            StampDutyVehicleType.FiveOrSixCylinders,
                            true
                        )}
                        <br />
                    </TableCell>
                    <TableCell align="left">
                        {calculateQLDStampDuty(
                            saleDetails.vehicleSalePrice,
                            StampDutyVehicleType.SevenOrMoreCylinders,
                            true
                        )}
                        <br />
                    </TableCell>
                </TableRow> */}
            </Table>
            <br />
            <Typography align="left">
                This estimator is a guide only—it is not a substitute for
                professional advice; cannot be used for transactions before 1
                July 2018; does not include Department of Transport and Main
                Roads registration fees.
            </Typography>
        </>
    );
};

export default QLDStampDuty;
