// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tooltip
} from '@mui/material';
import Paper from '../Paper';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ArrowRightAlt } from '@mui/icons-material';
// COMPONENTS
import AutocompleteMultiple from '../AutocompleteMultiple';
// LOGIC
import GetAllSites from '../databaseLogic/GetAllSites';
import { DateSafeFormatter } from '../logic/Formatters';
import { getDefaultDateRange } from './getMonthDatesAndBusinessDays';
import GetUser from '../databaseLogic/GetUser';
import GetAllUserPermissions from '../databaseLogic/GetAllUserPermissions';
// INTERFACES
import { Site, User } from '../interfaces/GeneralInterface';
import RoleCheck from '../RoleCheck';

interface SiteAndDateHeaderProps {
    department: string;
    selectedSite?: Site[];
    setSelectedSite?: Dispatch<SetStateAction<Site[]>>;
    selectedStartDate?: Dayjs;
    setSelectedStartDate?: Dispatch<SetStateAction<Dayjs>>;
    selectedEndDate?: Dayjs;
    setSelectedEndDate?: Dispatch<SetStateAction<Dayjs>>;
    compareType?: string;
    setCompareType?: Dispatch<SetStateAction<string>>;
}

const SiteAndDateHeader = ({
    department,
    selectedSite,
    setSelectedSite,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    compareType,
    setCompareType
}: SiteAndDateHeaderProps) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [isManager, setIsManager] = useState<boolean>(false);
    const [siteList, setSiteList] = useState<Site[]>([]);
    const [unalteredSiteList, setUnalteredSiteList] = useState<Site[]>([]);

    const { firstDayOfCurrentMonth, today } = getDefaultDateRange();

    useEffect(() => {
        GetAllSites(setSiteList);
        GetAllSites(setUnalteredSiteList);
        GetUser(0, (user: User) => {
            setCurrentUser(user);
            GetAllUserPermissions(0, (permissions) => {
                const isAdmin = permissions.includes('admin_manager');
                const isManager =
                    permissions.includes('admin_manager') ||
                    (department === 'service' &&
                        permissions.includes('service_manager')) ||
                    (department === 'sales' &&
                        permissions.includes('sales_manager')) ||
                    (department === 'parts' &&
                        permissions.includes('parts_manager'));
                setIsManager(isManager);
                if (user && selectedSite && setSelectedSite) {
                    if (isAdmin) {
                        GetAllSites(setSelectedSite);
                    } else {
                        setSelectedSite([user?.Site]);
                    }
                }
            });
        });

        // eslint-disable-next-line
    }, []);

    const handleSelectCompareType = (e) => {
        setCompareType(e.target.value);
    };

    return (
        <Paper>
            <Grid container spacing={2} alignItems="center">
                {isManager ? (
                    <Grid item xs={12} md={12} container alignItems="center">
                        {compareType && (
                            <Grid item xs={12} sm={2}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue={compareType}
                                        onChange={handleSelectCompareType}
                                    >
                                        <Tooltip title="Compare to same period last month">
                                            <FormControlLabel
                                                value="Month"
                                                control={<Radio size="small" />}
                                                label="Month"
                                            />
                                        </Tooltip>
                                        <Tooltip title="Compare to same period last year">
                                            <FormControlLabel
                                                value="Year"
                                                control={<Radio size="small" />}
                                                label="Year"
                                            />
                                        </Tooltip>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        )}

                        <Grid item xs={12} sm={compareType ? 5 : 6}>
                            {selectedStartDate && selectedEndDate ? (
                                <Grid container>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <Grid
                                            xs={5}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <DatePicker
                                                label="Start Date"
                                                format="DD/MM/YYYY"
                                                value={dayjs(selectedStartDate)}
                                                onChange={(newValue) => {
                                                    setSelectedStartDate(
                                                        newValue.startOf('day')
                                                    );
                                                    setSelectedEndDate(
                                                        newValue.endOf('month')
                                                    );
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        error: false,
                                                        InputLabelProps: {
                                                            shrink: true
                                                        }
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            xs={1}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <ArrowRightAlt
                                                sx={{
                                                    fontSize: '40px',
                                                    marginTop: 1,
                                                    marginBottom: 1
                                                }}
                                            />
                                        </Grid>
                                        <Grid xs={5}>
                                            <DatePicker
                                                label="End Date"
                                                format="DD/MM/YYYY"
                                                disabled={
                                                    selectedStartDate == null
                                                }
                                                minDate={dayjs(
                                                    selectedStartDate
                                                )}
                                                maxDate={dayjs(
                                                    selectedStartDate.endOf(
                                                        'month'
                                                    )
                                                )}
                                                value={dayjs(selectedEndDate)}
                                                onChange={(newValue) =>
                                                    setSelectedEndDate(
                                                        newValue.endOf('day')
                                                    )
                                                }
                                                slotProps={{
                                                    textField: {
                                                        error: false,
                                                        InputLabelProps: {
                                                            shrink: true
                                                        }
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                            ) : (
                                <Typography variant="h5">
                                    {DateSafeFormatter(
                                        firstDayOfCurrentMonth.toString()
                                    ) +
                                        ' - ' +
                                        DateSafeFormatter(today.toString())}
                                </Typography>
                            )}
                        </Grid>

                        {selectedSite && (
                            <Grid item xs={12} sm={compareType ? 5 : 6}>
                                <RoleCheck
                                    permission={'admin_manager'}
                                    component={
                                        <AutocompleteMultiple
                                            options={siteList}
                                            size="medium"
                                            fullWidth
                                            useTwoOptionLabels={false}
                                            primaryOptionLabel={'name'}
                                            textfieldLabel="Select Site(s)"
                                            selectedValues={[...selectedSite]}
                                            handleSelectedValueChange={(
                                                event
                                            ) => {
                                                let currentOptions =
                                                    unalteredSiteList;
                                                let newFilterValue = [];

                                                // Add or remove the value from the selected value
                                                // Also add or remove the selected option from the options list so it can not be added more than once
                                                event.forEach((val, index) => {
                                                    newFilterValue.push(val);
                                                    currentOptions =
                                                        currentOptions.filter(
                                                            (x) =>
                                                                x.name !==
                                                                val.name
                                                        );
                                                });

                                                setSiteList(currentOptions);
                                                setSelectedSite(newFilterValue);
                                            }}
                                            handleInputValueChange={() => null}
                                        />
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {DateSafeFormatter(
                                firstDayOfCurrentMonth.toString()
                            ) +
                                ' - ' +
                                DateSafeFormatter(today.toString())}{' '}
                            - {currentUser?.Site?.name}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default SiteAndDateHeader;
