// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { Divider, Grid, TextField, Typography } from '@mui/material';

interface AddressProps {
  creditorDetails: Creditor;
  setCreditorDetails: Dispatch<SetStateAction<Creditor>>;
  readOnly: boolean;
}

const Address = ({
  creditorDetails,
  setCreditorDetails,
  readOnly
}: AddressProps) => {
  return (
    <Paper>
      <Typography variant="h6">Address Details</Typography>
      <Divider />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            size="small"
            label="Address Line 1"
            value={creditorDetails?.addressLine1}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCreditorDetails({
                ...creditorDetails,
                addressLine1: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            disabled={readOnly}
            fullWidth
            size="small"
            label="Suburb"
            value={creditorDetails?.suburb}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCreditorDetails({ ...creditorDetails, suburb: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            size="small"
            label="Postcode"
            value={creditorDetails?.postcode}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCreditorDetails({
                ...creditorDetails,
                postcode: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            size="small"
            label="State"
            value={creditorDetails?.state}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCreditorDetails({ ...creditorDetails, state: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            size="small"
            label="Country"
            value={creditorDetails?.country}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setCreditorDetails({
                ...creditorDetails,
                country: e.target.value
              })
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Address;
