// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { TableRow, TextField } from '@mui/material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import Paper from '../../../global/Paper';
// INTERFACES
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';

// Props sent through from UpdateVehicleRRPs
interface ResultsProps {
  vehicleResults: Vehicle[];
  setVehicleResults: Dispatch<SetStateAction<Vehicle[]>>;
  editAll: boolean;
}

/**
 * UpdateVehicleRRPsResults
 * The results UI for Update Vehicle RRPs
 * @author Estienne
 * @param ResultsProps
 */
const UpdateVehicleRRPsResults = ({
  vehicleResults,
  setVehicleResults,
  editAll
}: ResultsProps) => {
  const columns = [
    { id: 0, label: 'Stock Number', width: 100 },
    { id: 1, label: 'Year', width: 100 },
    { id: 2, label: 'Make / Model / Series', width: 350 },
    { id: 3, label: 'Condition', width: 100 },
    { id: 4, label: 'RRP', width: 300 }
  ];

  /**
   * handleEditRRP
   * Edit the RRP of a vehicle(s)
   * @author Estienne
   * @param vehicleId
   * @param newPrice
   */
  const handleEditRRP = (vehicleId: number, newPrice: any) => {
    let tempVehicles = [...vehicleResults];

    // Set all the shown vehicles to the same price
    if (editAll) {
      tempVehicles.forEach((vehicle) => {
        vehicle.priceRRP = newPrice;
      });

      setVehicleResults(tempVehicles);
    }

    // Find the index of the current vehicle and set its new price
    else {
      let index = tempVehicles.findIndex((x) => x.id === vehicleId);
      let vehicle = { ...tempVehicles[index] };
      vehicle.priceRRP = newPrice;
      tempVehicles[index] = vehicle;
      setVehicleResults(tempVehicles);
    }
  };

  return (
    <Paper>
      <DataTable columns={columns}>
        {vehicleResults.map((row) => (
          <TableRow selected={editAll ? true : false}>
            <DataCell>{row?.stockNumber}</DataCell>
            <DataCell>{row?.year}</DataCell>
            <DataCell>
              {row['Make.name']} {row['Model.name']} {row['Series.name']}
            </DataCell>
            <DataCell>{row?.condition}</DataCell>
            <TextField
              fullWidth
              type="number"
              variant="standard"
              value={row?.priceRRP}
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                handleEditRRP(row.id, e.target.value);
              }}
            />
          </TableRow>
        ))}
      </DataTable>
    </Paper>
  );
};

export default UpdateVehicleRRPsResults;
