// IMPORTS
import { IconButton, MenuItem, TableRow, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";

// COMPONENTS
import Paper from "../../../global/Paper";
import DataTable from '../../../../OraNextGen/global/tableComponents/DataTable';
import DataCell from "../../../global/tableComponents/DataCell";

// LOGIC
import UpdateColumnHeaders from '../logic/UpdateColumnHeaders';
import RemoveLine from '../logic/RemoveLine';

const PreviewFile = (props) => {
    var headerOptions = [
        { id: 0, label: 'Part Number', value: 'partNumber' },
        { id: 1, label: 'Product Name', value: 'name' },
        { id: 3, label: 'RRP (Exc. GST)', value: 'priceRRPExcGST' },
        { id: 2, label: 'RRP (Inc. GST)', value: 'priceRRP' },
        { id: 4, label: 'Cost Price Daily (Exc. GST)', value: 'costPriceDaily' },
        { id: 5, label: 'Cost Price Daily (Inc. GST)', value: 'costPriceDailyIncGST' },
        { id: 6, label: 'Cost Price Stock (Exc. GST)', value: 'costPriceStock' },
        { id: 7, label: 'Cost Price Stock (Inc. GST)', value: 'costPriceStockIncGST' },
        { id: 8, label: 'Supplier Stock', value: 'supplierStock' },
        { id: 9, label: 'Supersession', value: 'supercessionPartNumber' },
        { id: 10, label: 'Description', value: 'description' },
        { id: 11, label: 'Barcode', value: 'barcode' },
        { id: 11, label: 'Trade Price (Inc. GST)', value: 'tradePriceIncGST' },
        { id: 11, label: 'Trade Price (Exc. GST)', value: 'tradePrice' },
        { id: 11, label: 'Ignore Column', value: 'ignore' }
    ]

    return (
        <Paper>
            <DataTable columns={[]}>
                <TableRow>
                    <DataCell>Row ID</DataCell>
                    {props.selectedHeaders.map((header, i) => (
                        <DataCell>
                            <TextField
                                select
                                fullWidth
                                error={props.selectedHeadersError}
                                value={header[i]}
                                onChange={(e) => {UpdateColumnHeaders(i, e.target.value, props.selectedHeaders, props.setSelectedHeaders);}}
                            >
                                {headerOptions.map(option => (
                                    <MenuItem value={option.value} disabled={props.selectedHeaders.includes(option.value) && option.value !== "ignore"}>{option.label}</MenuItem>
                                ))}
                            </TextField>
                        </DataCell>
                    ))}
                    <DataCell></DataCell>
                </TableRow>

                {props.previewData.first10.map((item, i) => (
                    <TableRow>
                        <DataCell>{i + 1}</DataCell>
                        {Object.keys(item).map(line => (
                            <DataCell>{item[line]}</DataCell>
                        ))}
                        <DataCell>
                            <IconButton onClick={(e) => RemoveLine('first', i, props.previewData, props.setPreviewData)}><Close /></IconButton>
                        </DataCell>
                    </TableRow>
                ))}

                {props.previewData.first10.length > 0 ?
                    <TableRow>
                        <DataCell colSpan={props.previewData.first10.length}>.<br />.<br />.</DataCell>
                    </TableRow>
                    : null}

                {props.previewData.last10.map((item, i) => (
                    <TableRow>
                        <DataCell>{props.importData.length - (props.previewData.last10.length - 1 - i)}</DataCell>
                        {Object.keys(item).map(line => (
                            <DataCell> {item[line]}</DataCell>
                        ))}
                        <DataCell>
                            <IconButton onClick={(e) => RemoveLine('last', i, props.previewData, props.setPreviewData)}><Close /></IconButton>
                        </DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </Paper>
    )
}

export default PreviewFile;