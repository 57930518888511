import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

const EmptyMakeTableDialogContent = ({
    setIsEmpty,
    setEmptyField
}: {
    setIsEmpty?: Dispatch<SetStateAction<boolean>>;
    setEmptyField?: Dispatch<SetStateAction<string>>;
}) => {
    return (
        <>
            <DialogTitle>No Make created yet</DialogTitle>
            <DialogContent>
                <Typography>
                    Ora couldn't find any make in the database. Do you wish to
                    import make(s) through a CSV?
                </Typography>
                <Typography>
                    You can also manually create make(s), simply close this
                    dialog and click on the 3 Vertical Dot icon in the top right
                    of the screen, and go to Specifics Management. From there
                    you will be able to create Make(s)
                </Typography>
                <Typography variant="caption">
                    If you have already created make(s) and this dialog opened
                    by mistake, please contact the Ora development team.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => {
                        if (setIsEmpty) {
                            setIsEmpty(false);
                        } else if (setEmptyField) {
                            setEmptyField('noDialog');
                        }
                    }}
                >
                    Close Dialog
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (setIsEmpty) {
                            setIsEmpty(false);
                        } else if (setEmptyField) {
                            setEmptyField('Make');
                        }
                    }}
                >
                    Open CSV Import Function
                </Button>
            </DialogActions>
        </>
    );
};

export default EmptyMakeTableDialogContent;
