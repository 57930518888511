// IMPORTS
import { useEffect, useState } from 'react';
import { TableRow } from '@mui/material';

// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';

// LOGIC

// INTERFACES
import GetStock from '../logic/GetStock';
import { CurrencyFormatter } from '../../../global/logic/Formatters';

const StockDrawerContent = (props) => {
    const [resultsList, setResultsList] = useState([]);
    useEffect(() => {
        GetStock(props.unit.id, setResultsList);
        // eslint-disable-next-line
    }, []);

    // Columns for the data table
    const columns = [
        { id: 0, label: 'Site', width: 100 },
        { id: 1, label: 'Qty On Hand' },
        { id: 2, label: 'Qty On Proforma' },
        { id: 3, label: 'Qty On Order' },
        { id: 4, label: 'Average Cost', width: 110 }
    ];

    return (
        <>
            <DataTable columns={columns}>
                {resultsList.map((row) => (
                    <TableRow>
                        <DataCell>{row.siteName}</DataCell>
                        <DataCell>{row.qtyOnHand}</DataCell>
                        <DataCell>{row.qtyOnProforma}</DataCell>
                        <DataCell>{row.qtyOnOrder}</DataCell>
                        <DataCell>
                            {CurrencyFormatter(row.costPriceAverage)}
                        </DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </>
    );
};

export default StockDrawerContent;
