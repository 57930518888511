import dayjs, { Dayjs } from 'dayjs';
import api from '../../../../../api';
import { Dispatch, SetStateAction } from 'react';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { User } from '../../../global/interfaces/GeneralInterface';

const GetCalendarData = (
    selectedView: string,
    date: Dayjs,
    status: string[],
    searchString: string,
    setListOfJobs: Dispatch<SetStateAction<Service[]>>,
    techList?: User[],
    setTechArray?: Dispatch<
        SetStateAction<{ resourceId: number; resourceTitle: string }[]>
    >
) => {
    api.get(
        `servicecalendar?siteId=${localStorage.getItem('SiteId')}&currentView=${selectedView}&date=${date.toISOString()}&status=${status.join(',')}&searchString=${searchString !== null ? searchString : ''}`
    ).then((res) => {
        let array = [];
        let techArray = [];
        for (let job of res.data) {
            array.push({
                id: job.id,
                title:
                    dayjs(job.jobStart).isSame(dayjs(job.jobFinish), 'day') &&
                    selectedView !== 'month'
                        ? `${job.id.toString().split('').join('\n')}`
                        : `${job.id.toString()} - ${dayjs(job.jobStart).format('DD/MM, hh:mm A')} - ${dayjs(job.jobFinish).format('hh:mma')}`,
                start: dayjs(job.jobStart).toDate(),
                end: dayjs(job.jobFinish).toDate(),
                resourceId: job.TechId,
                status: job.serviceComplete,
                jobData: job
            });
            if (selectedView === 'tech' && job.TechId) {
                let index = techArray.findIndex(
                    (x) => x.resourceId === job.TechId
                );
                if (index === -1) {
                    techArray.push({
                        resourceId: job.TechId,
                        resourceTitle:
                            job.Tech.firstName +
                            ' ' +
                            job.Tech.lastName.charAt(0) +
                            '.'
                    });
                }
            }
        }
        if (selectedView === 'tech' && setTechArray) {
            if (techArray.length === 0) {
                let tech = {
                    resourceId: techList[0].id,
                    resourceTitle:
                        techList[0].firstName +
                        ' ' +
                        techList[0].lastName.charAt(0) +
                        '.'
                };
                techArray.push(tech);
                setTechArray(techArray);
            } else {
                setTechArray(techArray);
            }
        }
        setListOfJobs(array);
    });
};
export default GetCalendarData;
