// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    MenuItem,
    Menu,
    Tooltip,
    Dialog,
    Badge,
    useTheme,
    Button
} from '@mui/material';
import {
    LocationOn,
    MoreVert,
    Schedule,
    Settings,
    SystemUpdateAlt,
    AssignmentTurnedIn
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
// COMPONENTS
import RoleCheck from '../global/RoleCheck';
import ResetPassword from '../general/password/ResetPassword';
import TimeClocks from './components/TimeClocks';
// LOGIC
import GetCompanyDetails from '../global/databaseLogic/GetCompanyDetails';
import SetUserUpdated from './logic/SetUserUpdated';
import GetAllSites from '../global/databaseLogic/GetAllSites';
// INTERFACES
import { Site } from '../global/interfaces/GeneralInterface';
import { showSnackbar } from '../global/interfaces/GlobalInterface';
import GetTimeClocks from './logic/GetTimeClocks';
import { TimeClock } from '../global/interfaces/AdminInterface';
import DocumentationSearchBar from '../documentation/search/documentationSearchBar';
import { withSnackbar } from '../global/WrappingSnackbar';

interface HeaderProps {
    showUpdates: boolean;
    setBackdropOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const Header = ({
    showUpdates,
    setBackdropOpen,
    showSnackbar
}: HeaderProps) => {
    const currentMode = useTheme().palette.mode;
    const [loading, setLoading] = useState(true); // New loading state
    const [hasClockedOnToday, setHasClockedOnToday] = useState<boolean | null>(
        null
    );
    const [timeClockOpen, setTimeClockOpen] = useState(false);
    const [timeClocks, setTimeClocks] = useState<TimeClock[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [siteDetails, setSiteDetails] = useState(null);
    const [allSites, setAllSites] = useState<Site[]>([]);
    const [anchorElOptions, setAnchorElOptions] = useState<null | HTMLElement>(
        null
    );
    const [anchorElSites, setAnchorElSites] = useState<null | HTMLElement>(
        null
    );
    const [passwordChangeDialogOpen, setPasswordChangeDialogOpen] =
        useState<boolean>(false);

    useEffect(() => {
        GetCompanyDetails(
            parseInt(localStorage.getItem('SiteId')),
            setCompanyDetails,
            setSiteDetails
        );
        GetAllSites(setAllSites);
    }, []);

    //load timeclocks and set the clock on and time clock open status
    useEffect(() => {
        setLoading(true);
        GetTimeClocks((timeClocksData) => {
            setTimeClocks(timeClocksData);
            if (timeClocksData && timeClocksData.length > 0) {
                setHasClockedOnToday(true);
                setTimeClockOpen(false);
            } else {
                setHasClockedOnToday(false);
                setTimeClockOpen(true);
            }
            setLoading(false);
        });
    }, [refresh]);

    const HandleChangeSite = (SiteId: string) => {
        localStorage.setItem('SiteId', SiteId);
        window.location.reload();
    };

    const handleUpdate = () => {
        setBackdropOpen(true);
        setTimeout(function () {
            SetUserUpdated(showSnackbar);
        }, 5000);
    };

    const handleToggleSidebar = () => {
        const sidebar = document.getElementById('sidebar');
        //if sidebar element already has className as 'open', toggle will remove className; it don't has, toggle will add className = 'open'
        sidebar.classList.toggle('open');
    };

    return (
        <>
            {window.location.href.includes('/support') ? (
                <AppBar
                    position="fixed"
                    sx={{
                        backgroundColor:
                            currentMode === 'dark' ? '#1f1d1d' : '#555'
                    }}
                >
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            sx={{ mr: 2 }}
                            onClick={handleToggleSidebar}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => (window.location.href = '/')}
                            >
                                Return to Website
                            </Button>
                        </Typography>
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{ flexGrow: 1, textAlign: 'center' }}
                        >
                            Ora User Documentation
                        </Typography>
                        <DocumentationSearchBar />
                    </Toolbar>
                </AppBar>
            ) : (
                <AppBar
                    position="fixed"
                    sx={{
                        backgroundColor:
                            currentMode === 'dark' ? '#1f1d1d' : '#555'
                    }}
                >
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            sx={{ mr: 2 }}
                            onClick={handleToggleSidebar}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1 }}
                        >
                            {window.outerWidth < 600
                                ? `${siteDetails?.name}`
                                : `${companyDetails?.companyName} - ${siteDetails?.name}`}
                        </Typography>

                        {showUpdates ? (
                            <Tooltip title="Update Ora" placement="bottom">
                                <IconButton
                                    size="large"
                                    onClick={() => handleUpdate()}
                                    color="inherit"
                                >
                                    <Badge color="primary" variant="dot">
                                        <SystemUpdateAlt />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        {/* CHANGE SITE */}
                        <Tooltip title="Change Site" placement="bottom">
                            <IconButton
                                data-cy={'headerSiteChangeIconButton'}
                                size="large"
                                aria-controls="change-site"
                                onClick={(e) =>
                                    setAnchorElSites(e.currentTarget)
                                }
                                color="inherit"
                            >
                                <LocationOn />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="more-options"
                            anchorEl={anchorElSites}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorElSites)}
                            onClose={() => setAnchorElSites(null)}
                        >
                            {allSites.map((site) => (
                                <MenuItem
                                    key={site.id}
                                    data-cy={`siteChangeMenuItem-${site.name}`}
                                    onClick={() =>
                                        HandleChangeSite(String(site.id))
                                    }
                                    selected={
                                        site.id ===
                                        parseInt(localStorage.getItem('SiteId'))
                                            ? true
                                            : false
                                    }
                                >
                                    <Typography textAlign="center">
                                        {site.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>

                        {/* TIME CLOCK */}
                        <Tooltip title="Time Clock" placement="bottom">
                            <IconButton
                                size="large"
                                onClick={() => setTimeClockOpen(true)}
                                color="inherit"
                            >
                                <Schedule />
                            </IconButton>
                        </Tooltip>

                        {/* USER SETTINGS */}
                        <Tooltip title="Personal Settings" placement="bottom">
                            <IconButton
                                size="large"
                                onClick={() =>
                                    (window.location.href = '/userSettings')
                                }
                                color="inherit"
                            >
                                <Settings />
                            </IconButton>
                        </Tooltip>

                        {/* DAILY TASKS */}
                        <Tooltip title="Daily Tasks" placement="bottom">
                            <IconButton
                                size="large"
                                onClick={() =>
                                    (window.location.href = '/dailytask')
                                }
                                color="inherit"
                            >
                                <AssignmentTurnedIn />
                            </IconButton>
                        </Tooltip>

                        {/* MORE OPTIONS */}
                        <Tooltip title="More Options" placement="bottom">
                            <IconButton
                                data-cy={'headerMoreOptionsIconButton'}
                                size="large"
                                aria-controls="more-options"
                                onClick={(e) =>
                                    setAnchorElOptions(e.currentTarget)
                                }
                                color="inherit"
                            >
                                <MoreVert />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="more-options"
                            anchorEl={anchorElOptions}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorElOptions)}
                            onClose={() => setAnchorElOptions(null)}
                        >
                            <MenuItem
                                data-cy={'headerUserMenuItem'}
                                onClick={() =>
                                    (window.location.href = '/configureUsers')
                                }
                            >
                                Users
                            </MenuItem>
                            <RoleCheck
                                permission={'configure_sites'}
                                component={
                                    <MenuItem
                                        data-cy={'headerSitesMenuItem'}
                                        onClick={() =>
                                            (window.location.href = '/sites')
                                        }
                                    >
                                        Sites
                                    </MenuItem>
                                }
                            />
                            <RoleCheck
                                permission={'configure_company'}
                                component={
                                    <MenuItem
                                        onClick={() =>
                                            (window.location.href =
                                                '/companySettings')
                                        }
                                    >
                                        Company Settings
                                    </MenuItem>
                                }
                            />
                            <RoleCheck
                                permission={'access_specifics'}
                                component={
                                    <MenuItem
                                        onClick={() =>
                                            (window.location.href =
                                                '/specifics')
                                        }
                                    >
                                        Specifics Management
                                    </MenuItem>
                                }
                            />
                            <MenuItem
                                onClick={() =>
                                    (window.location.href = '/templates')
                                }
                            >
                                Email & Text Templates
                            </MenuItem>
                            <MenuItem
                                onClick={() =>
                                    setPasswordChangeDialogOpen(true)
                                }
                            >
                                Change Password
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            )}
            {!loading && hasClockedOnToday !== null && (
                <>
                    <Dialog
                        open={!hasClockedOnToday || timeClockOpen}
                        onClose={() => {
                            if (hasClockedOnToday) {
                                setTimeClockOpen(false);
                            }
                        }}
                        disableEscapeKeyDown={!hasClockedOnToday}
                        maxWidth="md"
                        fullWidth
                    >
                        <TimeClocks
                            setTimeClockOpen={setTimeClockOpen}
                            currentSiteName={companyDetails?.site?.name}
                            timeClocks={timeClocks}
                            setHasClockedOnToday={setHasClockedOnToday}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                    </Dialog>
                    <Dialog
                        open={passwordChangeDialogOpen}
                        onClose={() => setPasswordChangeDialogOpen(false)}
                        maxWidth="sm"
                        fullWidth
                    >
                        <ResetPassword
                            setPasswordChangeDialogOpen={
                                setPasswordChangeDialogOpen
                            }
                        />
                    </Dialog>
                </>
            )}
        </>
    );
};

export default withSnackbar(Header);
