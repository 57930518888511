// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Creditor, Site } from '../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Account } from '../../../global/interfaces/AdminInterface';
import { ToFixed } from '../../../global/logic/Formatters';

interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Vehicle?: Vehicle;
    Account: Account;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
    documentReference: string;
}

interface InvoiceLine {
    id: number;
    Vehicle?: Vehicle;
    amount: string;
    description: string;
}

function SubmitRebateInvoice(
    invoiceObject: InvoiceObject,
    invoiceLines: InvoiceLine[],
    invoiceTotal: number,
    responseCode: number,
    showSnackbar: showSnackbar,
    setIsSubmittingInvoice: Dispatch<SetStateAction<boolean>>
) {
    setIsSubmittingInvoice(true);

    if (!invoiceObject.Creditor && !invoiceObject.Account) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please select a Creditor or an Account on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (!invoiceObject.documentDate) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the document date on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (!invoiceObject.GSTtype) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the gst type used on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (!invoiceObject.documentTotal) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the document total on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (invoiceLines.length === 0) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the invoice lines.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (
        ToFixed(invoiceTotal) !== parseFloat(invoiceObject.documentTotal)
    ) {
        showSnackbar(
            'Whoops! Mismatched Data!',
            'Your line total and document total do not match.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    } else if (responseCode === 400) {
        showSnackbar(
            'Whoops! Invalid Data!',
            'That document reference already exists on another invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    }

    api.post('rebateInvoice', {
        invoiceObject: invoiceObject,
        invoiceLines: invoiceLines,
        siteId: localStorage.getItem('SiteId')
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Invoice Submitted Successfully.',
                'The page will now refresh.'
            );

            // Set a timeout to reload the page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact the IT department.',
                    'error'
                );
            } else {
                showSnackbar(
                    res.data.split('|')[0],
                    res.data.split('|')[1],
                    'error'
                );
            }
            setIsSubmittingInvoice(false);
        }
    });
}

export default SubmitRebateInvoice;
