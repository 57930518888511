import { Site } from '../../../../../global/interfaces/GeneralInterface';
import { BalanceSheetLine } from '../interface/BalanceSheetInterface';

const BalanceSheetCSVContent: (
    singleLine: BalanceSheetLine,
    selectedSite: string | Site
) => any[] = (singleLine, selectedSite) => {
    let totalAsset = singleLine?.newVehiclesTotal + singleLine?.usedVehiclesTotal + singleLine?.stockOnHandTotal + singleLine?.pendingDebtorPayment
    let rows = [
        ['Assets', '', ''],
        ['Account Name', 'Site / Type', 'Balance'],
        [
            'Inventory New',
            typeof selectedSite != 'string'
                ? selectedSite?.name + '/ Current Asset'
                : 'All Sites / Current Asset',
            singleLine?.newVehiclesTotal
        ],
        [
            'Inventory Used',
            typeof selectedSite != 'string'
                ? selectedSite?.name + '/ Current Asset'
                : 'All Sites / Current Asset',
            singleLine?.usedVehiclesTotal
        ],
        [
            'Inventory Other',
            typeof selectedSite != 'string'
                ? selectedSite?.name + '/ Current Asset'
                : 'All Sites / Current Asset',
            singleLine?.stockOnHandTotal
        ],
        [
            'Pending Debtor Payment',
            typeof selectedSite != 'string'
                ? selectedSite?.name + '/ Current Asset'
                : 'All Sites / Current Asset',
            singleLine?.pendingDebtorPayment
        ]
    ];

    rows.push([]);

    singleLine?.accountLines?.forEach((row) => {
        if (
            (row.assetType === 'Asset (Current)' ||
                row.assetType === 'Asset (Non-Current)') &&
            (row.transactions.reduce(
                (acc, transaction) => acc + transaction.amount,
                0
            ) +
                row.initialBalance >
                0.01 ||
                row.transactions.reduce(
                    (acc, transaction) => acc + transaction.amount,
                    0
                ) +
                    row.initialBalance <
                    -0.01)
        ) {
            let line = [
                row.accountName,
                row.accountSite + ' / ' + row.assetType === 'Asset (Current)'
                    ? 'Current Asset'
                    : 'Non-Current Asset',
                row.initialBalance != null
                    ? row.initialBalance +
                      row.transactions.reduce(
                          (acc, transaction) => acc - transaction.amount,
                          0
                      )
                    : row.transactions.reduce(
                          (acc, transaction) => acc - transaction.amount,
                          0
                      )
            ];
            totalAsset += row.initialBalance != null
            ? row.initialBalance +
              row.transactions.reduce(
                  (acc, transaction) => acc - transaction.amount,
                  0
              )
            : row.transactions.reduce(
                  (acc, transaction) => acc - transaction.amount,
                  0
              )
            rows.push(line);
        }
    });
    rows.push(['Total Asset', totalAsset])
    rows.push([]);

let totalLiability = singleLine?.floorplanLiabilityTotal + singleLine?.paymentLiabilityPA + singleLine?.paymentLiabilityVehicleSale + singleLine?.paymentLiabilityService + singleLine?.pendingPayrun
    rows.push(['Liabilities', '', '']);
    rows.push(['Account Name', 'Site / Type', 'Balance']);
    rows.push([
        'Floorplan Liability',
        typeof selectedSite != 'string'
            ? selectedSite?.name + '/ Current Asset'
            : 'All Sites / Current Liability',
        singleLine?.floorplanLiabilityTotal
    ]);
    rows.push([
        'Parts & Accessories Customer Deposit',
        typeof selectedSite != 'string'
            ? selectedSite?.name + '/ Current Asset'
            : 'All Sites / Current Liability',
        singleLine?.paymentLiabilityPA
    ]);
    rows.push([
        'Vehicle Sale Customer Deposit',
        typeof selectedSite != 'string'
            ? selectedSite?.name + '/ Current Asset'
            : 'All Sites / Current Liability',
        singleLine?.paymentLiabilityVehicleSale
    ]);
    rows.push([
        'Service Customer Deposit',
        typeof selectedSite != 'string'
            ? selectedSite?.name + '/ Current Asset'
            : 'All Sites / Current Liability',
        singleLine?.paymentLiabilityService
    ]);
    rows.push([
        'Pending Payment Runs',
        typeof selectedSite != 'string'
            ? selectedSite?.name + '/ Current Asset'
            : 'All Sites / Current Liability',
        singleLine?.pendingPayrun
    ]);

    singleLine?.accountLines?.forEach((row) => {
        if (
            (row.assetType === 'Liability (Current)' ||
                row.assetType === 'Liability (Non-Current)') &&
            (row.transactions.reduce(
                (acc, transaction) => acc + transaction.amount,
                0
            ) +
                row.initialBalance >
                0.01 ||
                row.transactions.reduce(
                    (acc, transaction) => acc + transaction.amount,
                    0
                ) +
                    row.initialBalance <
                    -0.01)
        ) {
            let line = [
                row.accountName,
                row.accountSite + ' / ' + row.assetType ===
                'Liability (Current)'
                    ? 'Current Liability'
                    : 'Non-Current Liability',
                row.initialBalance != null
                    ? row.initialBalance -
                      row.transactions.reduce(
                          (acc, transaction) => acc + transaction.amount,
                          0
                      )
                    : row.transactions.reduce(
                          (acc, transaction) => acc + transaction.amount,
                          0
                      )
            ];
            totalLiability += row.initialBalance != null
            ? row.initialBalance -
              row.transactions.reduce(
                  (acc, transaction) => acc + transaction.amount,
                  0
              )
            : row.transactions.reduce(
                  (acc, transaction) => acc + transaction.amount,
                  0
              )
            rows.push(line);
        }
    });

    rows.push(['Total Liability', totalLiability])
    rows.push(['Total Balance', totalAsset - totalLiability])
    return rows;
};

export default BalanceSheetCSVContent;
