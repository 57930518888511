// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Divider, Grid, TextField, Typography } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { Site } from '../../../global/interfaces/GeneralInterface';
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';

interface SiteProps {
    siteInfo: Site;
    setSiteInfo: Dispatch<SetStateAction<Site>>;
    readOnly: boolean;
    settings;
}

const ExtraSettings = ({
    siteInfo,
    setSiteInfo,
    readOnly,
    settings
}: SiteProps) => {
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        if (siteInfo.Setting) {
            setDepartments(siteInfo.Setting.departments);
        }
        // eslint-disable-next-line
    }, [siteInfo.Setting]);

    return (
        <Paper>
            <Typography variant="h6" align="left">
                Extra Settings
            </Typography>
            <Divider /> <br />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AutocompleteMultiple
                        cypressLabel={'departmentSelector'}
                        isDisabled={readOnly || siteInfo.SettingId == null}
                        options={
                            Object.hasOwn(siteInfo, 'departments')
                                ? departments.filter(
                                      (x) =>
                                          !siteInfo.departments
                                              .map((x) => x.name)
                                              .includes(x.name)
                                  )
                                : departments || []
                        }
                        useTwoOptionLabels={false}
                        primaryOptionLabel="name"
                        textfieldLabel="Departments"
                        selectedValues={
                            Object.hasOwn(siteInfo, 'departments')
                                ? siteInfo?.departments
                                : []
                        }
                        isError={siteInfo.SettingId == null ? true : false}
                        helperText={
                            siteInfo.SettingId == null
                                ? 'Select a Company first.'
                                : ''
                        }
                        handleSelectedValueChange={(event) => {
                            let currentOptions = siteInfo?.SettingId
                                ? siteInfo.Setting.departments
                                : settings[0]?.departments;
                            let newFilterValue = [];

                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setDepartments(currentOptions);
                            setSiteInfo({
                                ...siteInfo,
                                departments: newFilterValue
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        data-cy="defaultSubletIncrease"
                        disabled={readOnly}
                        fullWidth
                        type="number"
                        size="small"
                        variant="outlined"
                        label="Default Sublet Increase (%)"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.defaultSubletIncrease}
                        onChange={(e) =>
                            setSiteInfo({
                                ...siteInfo,
                                defaultSubletIncrease: e.target.value
                            })
                        }
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ExtraSettings;
