import api from "../../../../../api"

const DeleteVehicleRebate = (id, setSnackbar) => {
    api.delete('rebateInvoice/' + id).then(res => {
        if (res.status === 200) {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Vehicle Rebate successfully deleted.',
                subMessage: ''
            });
            // Set a timeout to reload the page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Whoops! Something went wrong on our end.',
                subMessage: 'Please contact your IT department.'
            });
        }
    })
}

export default DeleteVehicleRebate