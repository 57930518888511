// IMPORTS
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Typography } from '@mui/material';
// COMPONENTS
import TransactionDrawerItem from './TransactionDrawerItem';
// INTERFACES
import { Voucher } from '../../../global/interfaces/PartsInterface';
// LOGIC
import GetVoucherTransactions from '../logic/GetVoucherTransactions';

interface TransactionDrawerContentProps {
    selectedVoucher: Voucher;
    setSelectedVoucher: Dispatch<SetStateAction<Voucher>>;
}

/**
 * TransactionDrawerContent
 * Retrieve and show the transaction history for a single voucher
 * @author Sienna
 * @param TransactionDrawerContentProps
 * @returns {JSX} the transaction history for the selected voucher
 */
const TransactionDrawerContent = ({
    selectedVoucher,
    setSelectedVoucher
}: TransactionDrawerContentProps) => {
    // Get the associated transactions to display
    useEffect(() => {
        GetVoucherTransactions(selectedVoucher, setSelectedVoucher);
        // eslint-disable-next-line
    }, [selectedVoucher.voucherCode]);

    // Check if there are any transactions to display in the drawer
    const checkIfTransactions = () => {
        if (
            selectedVoucher?.transactionLines?.vehicleSales.length === 0 &&
            selectedVoucher?.transactionLines?.services?.length === 0 &&
            selectedVoucher?.transactionLines?.orders?.length === 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            {checkIfTransactions() ? (
                <>
                    {selectedVoucher?.transactionLines?.orders.length > 0 ? (
                        <TransactionDrawerItem
                            title="P&A Sales"
                            startLink="/inventory/viewSale/"
                            rows={selectedVoucher?.transactionLines?.orders}
                        />
                    ) : null}
                    {selectedVoucher?.transactionLines?.vehicleSales.length >
                    0 ? (
                        <TransactionDrawerItem
                            title="Vehicle Sales"
                            startLink="/vehicles/viewContract/"
                            rows={
                                selectedVoucher?.transactionLines?.vehicleSales
                            }
                        />
                    ) : null}
                    {selectedVoucher?.transactionLines?.services.length > 0 ? (
                        <TransactionDrawerItem
                            title="Services"
                            startLink="/service/viewBooking/"
                            rows={selectedVoucher?.transactionLines?.services}
                        />
                    ) : null}
                </>
            ) : (
                <Typography variant="body2" align="center">
                    <b>No transaction have been made using this voucher yet.</b>
                </Typography>
            )}
        </>
    );
};

export default TransactionDrawerContent;
