export function PartsDefaultPermissions(setUserPermissions) {
  let tempArray = [];
  tempArray.push('import_supplierFile');
  tempArray.push('edit_invoice');
  tempArray.push('edit_parts');
  tempArray.push('delete_part');
  tempArray.push('delete_binLocation');
  tempArray.push('default_binLocation');
  tempArray.push('access_till');
  tempArray.push('access_creditors');
  tempArray.push('access_creditInvoice');
  tempArray.push('access_specifics');
  setUserPermissions(tempArray);
}

export function SalesDefaultPermissions(setUserPermissions) {
  let tempArray = [];
  tempArray.push('access_till');
  tempArray.push('access_creditors');
  tempArray.push('access_creditInvoice');
  tempArray.push('access_specifics');
  setUserPermissions(tempArray);
}

export function ServiceDefaultPermissions(setUserPermissions) {
  let tempArray = [];
  tempArray.push('service_note');
  tempArray.push('access_jobCode');
  tempArray.push('access_till');
  tempArray.push('access_creditors');
  tempArray.push('access_creditInvoice');
  tempArray.push('access_specifics');
  setUserPermissions(tempArray);
}

export function AdminDefaultPermissions(setUserPermissions) {
  let tempArray = [];
  tempArray.push('edit_creditor');
  tempArray.push('add_creditor');
  tempArray.push('configure_sites');
  tempArray.push('access_GJE');
  tempArray.push('access_accounts');
  tempArray.push('access_invoice');
  tempArray.push('edit_tillRec');
  tempArray.push('access_till');
  tempArray.push('access_creditors');
  tempArray.push('access_creditInvoice');
  tempArray.push('access_specifics');
  setUserPermissions(tempArray);
}
