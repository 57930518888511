// IMPORTS
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
import RoleCheck from '../../../global/RoleCheck';

// LOGIC
import api from '../../../../../api';
import GetAllMakes from '../../../global/databaseLogic/GetAllMakes';
import GetAllSites from '../../../global/databaseLogic/GetAllSites';

// INTERFACES
import { Site, User } from '../../../global/interfaces/GeneralInterface';
import { Make } from '../../../global/interfaces/VehicleSaleInterface';

const Filters = (props) => {
    const allEnquiryStatusOptions = [
        { type: 'ACTIVE' },
        { type: 'PENDING' },
        { type: 'SOLD' },
        { type: 'LOST' }
    ];
    const [enquiryStatusOptions, setEnquiryStatusOptions] = useState<
        { type: string }[]
    >([{ type: 'SOLD' }, { type: 'LOST' }]);

    const [make, setMake] = useState<Make[]>([]);
    const [filteredMake, setFilteredMake] = useState<Make[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [sites, setSites] = useState<Site[]>([]);
    const [filteredSites, setFilteredSites] = useState<Site[]>([]);

    useEffect(() => {
        api.get('users/department').then((res) => {
            let userArray = [];
            userArray.push({
                id: 0,
                username: 'No assigned user'
            });
            res.data.forEach((user) => {
                if (user.department === 'sales') {
                    userArray.push(user);
                }
            });
            setUsers(userArray);
        });
        GetAllMakes(setMake);
        GetAllSites(setSites);
    }, []);

    useEffect(() => {
        setFilteredUsers(users);
        let notCurrentSite = [];
        for (let site of sites) {
            if (parseInt(localStorage.getItem('SiteId')) === site.id) {
                props.setFilter({ ...props.filter, sites: [site] });
            } else {
                notCurrentSite.push(site);
            }
        }
        setFilteredSites(notCurrentSite);
        // eslint-disable-next-line
    }, [sites]);

    useEffect(() => {
        setFilteredMake(make);
    }, [make]);

    useEffect(() => {
        setFilteredUsers(users);
    }, [users]);

    return (
        <>
            <Grid container spacing={2} sx={{ marginTop: 0.6 }}>
                <Grid item xs={6}>
                    {/* STATUS FILTER */}
                    <AutocompleteMultiple
                        options={enquiryStatusOptions}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'type'}
                        textfieldLabel="Enquiry Status"
                        selectedValues={props.filter.enquiryStatus}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = allEnquiryStatusOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.type !== val.type
                                );
                            });

                            setEnquiryStatusOptions(currentOptions);
                            props.setFilter({
                                ...props.filter,
                                enquiryStatus: newFilterValue
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    {/* MAKE FILTER */}
                    <AutocompleteMultiple
                        options={filteredMake}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Make"
                        selectedValues={props.filter.make}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = make;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setFilteredMake(currentOptions);
                            props.setFilter({
                                ...props.filter,
                                make: newFilterValue
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            label="Submitted Date Start Range"
                            value={
                                props.filter.startDate
                                    ? props.filter.startDate
                                    : null
                            }
                            onChange={(newValue) => {
                                props.setFilter({
                                    ...props.filter,
                                    startDate: newValue
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            label="Submitted Date End Range"
                            value={
                                props.filter.endDate
                                    ? props.filter.endDate
                                    : null
                            }
                            onChange={(newValue) => {
                                props.setFilter({
                                    ...props.filter,
                                    endDate: newValue
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                    {/* SITE FILTER */}
                    <AutocompleteMultiple
                        options={filteredSites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={props.filter.sites}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sites;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setFilteredSites(currentOptions);
                            props.setFilter({
                                ...props.filter,
                                sites: newFilterValue
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <RoleCheck
                    component={
                        <Grid item xs={6}>
                            {/* USER FILTER */}
                            <AutocompleteMultiple
                                options={filteredUsers}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'username'}
                                textfieldLabel="Assigned User"
                                selectedValues={props.filter.assignedUser}
                                handleSelectedValueChange={(event) => {
                                    let currentOptions = users;
                                    let newFilterValue = [];

                                    // Add or remove the value from the selected value
                                    // Also add or remove the selected option from the options list so it can not be added more than once
                                    event.forEach((val, index) => {
                                        newFilterValue.push(val);
                                        currentOptions = currentOptions.filter(
                                            (x) => x.id !== val.id
                                        );
                                    });

                                    setFilteredUsers(currentOptions);
                                    props.setFilter({
                                        ...props.filter,
                                        assignedUser: newFilterValue
                                    });
                                }}
                                handleInputValueChange={() => null}
                            />
                        </Grid>
                    }
                    nullComponent={null}
                    permission="access_leadManager"
                />
            </Grid>
        </>
    );
};

export default Filters;
