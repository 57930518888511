// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { TimeClock } from '../../global/interfaces/AdminInterface';
import dayjs from 'dayjs';

const GetTechTimeClocks = (
    ServiceId: number,
    setTimeClocks: Dispatch<SetStateAction<TimeClock[]>>
) => {
    api.get(`getTechTimeClocks/${ServiceId}`).then((res) => {
        let data = JSON.parse(JSON.stringify(res.data));
        for (let line of data) {
            if (line.clockOn.includes('+10')) {
                let currentHour =
                    parseInt(line.clockOn.substring(0, 2)) + 10 > 24
                        ? (
                              parseInt(line.clockOn.substring(0, 2)) - 14
                          ).toString()
                        : (
                              parseInt(line.clockOn.substring(0, 2)) + 10
                          ).toString();
                currentHour =
                    currentHour.length === 1 ? '0' + currentHour : currentHour;

                let newHour = line.clockOn.replace(
                    line.clockOn.substring(0, 2),
                    currentHour
                );
                line.clockOn = dayjs(newHour, 'hh:mm:ss.SSSZZ');
            } else {
                let date = line.date.split('-');
                line.clockOn = dayjs(line.clockOn, 'hh:mm:ss:SSZ')
                    .year(parseInt(date[0]))
                    .month(parseInt(date[1]) - 1) // Dayjs month are 0 to 11
                    .date(parseInt(date[2]));
            }

            if (line.clockOff) {
                if (line.clockOff.includes('+10')) {
                    let currentHour =
                        parseInt(line.clockOff.substring(0, 2)) + 10 > 24
                            ? (
                                  parseInt(line.clockOff.substring(0, 2)) - 14
                              ).toString()
                            : (
                                  parseInt(line.clockOff.substring(0, 2)) + 10
                              ).toString();
                    currentHour =
                        currentHour.length === 1
                            ? '0' + currentHour
                            : currentHour;

                    let newHour = line.clockOff.replace(
                        line.clockOff.substring(0, 2),
                        currentHour
                    );
                    line.clockOff = dayjs(newHour, 'hh:mm:ss.SSSZZ');
                } else {
                    let date = line.date.split('-');
                    line.clockOff = dayjs(line.clockOff, 'hh:mm:ss:SSZ')
                        .year(parseInt(date[0]))
                        .month(parseInt(date[1]) - 1) // Dayjs month are 0 to 11
                        .date(parseInt(date[2]));
                }
            }
        }

        setTimeClocks(data);
    });
};

export default GetTechTimeClocks;
