// IMPORTS
import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    MenuItem,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
// COMPONENTS
import Autocomplete from '../../../../global/Autocomplete';
import AccountTransactionTable from './AccountTransactionTable';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import TillSelector from '../../../../global/paymentComponents/TillSelector';
// LOGIC
import GetAllVehicleSaleWithUnrecDirectDeposit from '../../../../global/databaseLogic/GetAllVehicleSaleWithUnrecDirectDeposit';
import GetAllAccountTransactions from '../../../account/logic/GetAllAccountTransactions';
import GetAllAccounts from '../../../../global/databaseLogic/GetAllAccounts';
import ReconcileVehicleSaleDirectDeposit from '../../logic/BankTransactions/ReconcileVehicleSaleDirectDeposit';
import ReconcileAccountTransaction from '../../logic/BankTransactions/ReconcileAccountTransaction';
import CreateVehicleSaleDirectDeposit from '../../logic/BankTransactions/CreateVehicleSaleDirectDeposit';
// INTERFACES
import {
    Account,
    BankTransaction,
    TransactionFile
} from '../../../../global/interfaces/AdminInterface';
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

interface ReconcileTransactionDrawerParams {
    bankFile: TransactionFile;
    selectedTransaction: BankTransaction;
    showSnackbar: showSnackbar;
}

const ReconcileTransactionDrawer = ({
    bankFile,
    selectedTransaction,
    showSnackbar
}: ReconcileTransactionDrawerParams) => {
    const [transactionType, setTransactionType] = useState<string>('Account');

    // Vehicle Sale State
    const [vehicleSaleList, setVehicleSaleList] = useState<VehicleSale[]>([]);
    const [selectedVehicleSale, setSelectedVehicleSale] =
        useState<VehicleSale>(null);
    const [selectedPaymentLine, setSelectedPaymentLine] =
        useState<number>(null);
    const [tillId, setTillId] = useState<number>(null);
    const [isNewLine, setIsNewLine] = useState<boolean>(false);

    // Account State
    const [selectedAccount, setSelectedAccount] = useState<Account>(null);
    const [accountList, setAccountList] = useState<Account[]>([]);
    const [reference, setReference] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<Dayjs>(null);
    const [gstType, setGstType] = useState<string>('');

    const vehicleSaleColumns = [
        { id: 0, label: '' },
        { id: 1, label: 'Amount' },
        { id: 2, label: 'Date' },
        { id: 3, label: 'User' }
    ];

    const gstTypeList = [
        { value: 'G1', label: 'G1' },
        { value: 'G2', label: 'G2' },
        { value: 'G3', label: 'G3' },
        { value: 'G10', label: 'G10' },
        { value: 'G11', label: 'G11' },
        { value: 'N/T', label: 'N/T' }
    ];

    useEffect(() => {
        if (transactionType === 'Account') {
            GetAllAccounts(setAccountList);
        } else if (transactionType === 'VehicleSale') {
            GetAllVehicleSaleWithUnrecDirectDeposit(setVehicleSaleList);
        }
    }, [transactionType]);

    const selectAccount = (e) => {
        if (transactionType === 'Account') {
            GetAllAccountTransactions(e, setSelectedAccount);
            setSelectedDate(
                dayjs(selectedTransaction.dateReceived, 'DD/MM/YYYY')
            );
        }
    };

    return (
        <>
            <ToggleButtonGroup
                fullWidth
                size="small"
                color="primary"
                value={transactionType}
                sx={{ marginBottom: 2 }}
                onChange={(e: BaseSyntheticEvent) => {
                    setTransactionType(e.target.value);
                }}
            >
                <ToggleButton value="Account">Account</ToggleButton>
                <ToggleButton value="VehicleSale">Vehicle Sale</ToggleButton>
            </ToggleButtonGroup>
            <br />

            {transactionType === 'Account' ? (
                <Grid
                    container
                    spacing={1}
                    textAlign="center"
                    alignItems={'center'}
                >
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            This will create a Manual Transaction in the
                            selected account with the reference and GST Type
                            entered below and directly reconcile it with the
                            transaction you have selected.
                        </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ marginBottom: 2 }}>
                        <Autocomplete
                            options={accountList}
                            selectedValue={selectedAccount}
                            handleSelectedValueChange={(event) => {
                                selectAccount(event);
                            }}
                            handleInputValueChange={() => void 0}
                            textfieldLabel="Select Account"
                            useTwoOptionLabels={true}
                            primaryOptionLabel="name"
                            secondaryOptionLabel="siteName"
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ marginBottom: 2 }}>
                        <Button
                            variant="contained"
                            disabled={
                                selectedAccount == null ||
                                reference.length === 0 ||
                                selectedDate == null ||
                                gstType.length === 0
                            }
                            sx={{ fontSize: 12 }}
                            onClick={() => {
                                ReconcileAccountTransaction(
                                    selectedAccount.id,
                                    reference,
                                    selectedDate,
                                    gstType,
                                    bankFile,
                                    selectedTransaction,
                                    showSnackbar
                                );
                            }}
                        >
                            Reconcile with this Account
                        </Button>
                    </Grid>

                    {selectedAccount?.transactions?.length > 0 && (
                        <>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Manual Transaction Reference"
                                    value={reference}
                                    onChange={(e) =>
                                        setReference(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        label="Manual Transaction Date"
                                        value={selectedDate}
                                        onChange={(newValue) =>
                                            setSelectedDate(newValue)
                                        }
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: false,
                                                size: 'small',
                                                InputLabelProps: {
                                                    shrink: true
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    select
                                    size="small"
                                    label="GST Type"
                                    value={gstType}
                                    onChange={(e) => setGstType(e.target.value)}
                                >
                                    {gstTypeList.map((gst) => (
                                        <MenuItem value={gst.value}>
                                            {gst.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <AccountTransactionTable
                                    selectedAccount={selectedAccount}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            ) : (
                <Grid
                    container
                    spacing={1}
                    textAlign="center"
                    alignItems={'center'}
                >
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            This will reconcile the transaction you selected
                            with a direct deposit from a Vehicle Sale.
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        {vehicleSaleList.length === 0 ? (
                            <CircularProgress />
                        ) : (
                            <Autocomplete
                                options={vehicleSaleList}
                                selectedValue={selectedVehicleSale}
                                handleSelectedValueChange={(event) => {
                                    setSelectedVehicleSale(event);
                                }}
                                handleInputValueChange={() => void 0}
                                textfieldLabel="Select Vehicle Sale"
                                useTwoOptionLabels={true}
                                primaryOptionLabel="id"
                                secondaryOptionLabel="stockNumber"
                            />
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            sx={{ fontSize: 12 }}
                            disabled={selectedPaymentLine == null}
                            onClick={() =>
                                ReconcileVehicleSaleDirectDeposit(
                                    selectedVehicleSale,
                                    selectedPaymentLine,
                                    bankFile,
                                    selectedTransaction,
                                    isNewLine,
                                    showSnackbar
                                )
                            }
                        >
                            Reconcile with this Direct Deposit
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {selectedVehicleSale?.id && (
                            <Typography variant="caption">
                                Select the direct deposit you want to reconcile
                                from the list below. If it doesn't exist use the
                                button to create one.
                            </Typography>
                        )}
                        {selectedVehicleSale?.paymentLines.length > 0 && (
                            <>
                                <DataTable columns={vehicleSaleColumns}>
                                    {selectedVehicleSale?.paymentLines?.map(
                                        (line) => (
                                            <TableRow>
                                                {line.type ===
                                                'directDeposit' ? (
                                                    <DataCell>
                                                        {selectedPaymentLine ===
                                                        line.id ? (
                                                            <IconButton
                                                                onClick={() => {
                                                                    setSelectedPaymentLine(
                                                                        null
                                                                    );
                                                                }}
                                                            >
                                                                <CheckCircle />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton
                                                                onClick={() => {
                                                                    setSelectedPaymentLine(
                                                                        line.id
                                                                    );
                                                                }}
                                                            >
                                                                <RadioButtonUnchecked />
                                                            </IconButton>
                                                        )}
                                                    </DataCell>
                                                ) : (
                                                    <DataCell></DataCell>
                                                )}

                                                <DataCell>
                                                    {CurrencyFormatter(
                                                        line.amount
                                                    )}
                                                </DataCell>
                                                <DataCell>
                                                    {dayjs(
                                                        line.timestamp
                                                    ).format(
                                                        'hh:mma - DD/MM/YYYY'
                                                    )}
                                                </DataCell>
                                                <DataCell>{line.user}</DataCell>
                                            </TableRow>
                                        )
                                    )}
                                </DataTable>
                            </>
                        )}
                    </Grid>
                    {selectedVehicleSale?.id && !isNewLine && (
                        <Grid item xs={4}>
                            <TillSelector
                                selectedTillId={tillId}
                                setSelectedTillId={setTillId}
                                department="admin"
                            />
                        </Grid>
                    )}
                    {selectedVehicleSale?.id && !isNewLine && (
                        <Grid item xs={8}>
                            <Button
                                variant="contained"
                                disabled={tillId == null}
                                onClick={() => {
                                    CreateVehicleSaleDirectDeposit(
                                        selectedTransaction,
                                        selectedVehicleSale,
                                        tillId,
                                        setIsNewLine,
                                        setSelectedVehicleSale,
                                        setSelectedPaymentLine
                                    );
                                }}
                            >
                                Add a new payment line
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

export default ReconcileTransactionDrawer;
