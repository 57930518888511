// LOGIC
import api from '../../../../../api';

// Get's all the emails from the User table that are unique
// Some users share a company email address, which is why we need to only get unique ones
const GetUniqueStaffEmails = (subject: string, body: string) => {
    api.get('uniqueStaffEmails').then((res) => {
        window.open(`mailto:${res.data}?subject=${subject}&body=${body}`);
    });
};

export default GetUniqueStaffEmails;
