// IMPORTS
import { useState } from 'react';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Header from './components/Header';
import TransactionLines from './components/TransactionLines';
import Fab from '../../global/Fab';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import HandleSubmitManualTransaction from './logic/HandleSubmitManualTransaction';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { ManualTransaction } from '../../global/interfaces/AdminInterface';

const ManualTransactionUI = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const { id } = useParams<{ id: string }>();
    const [manualTransactions, setManualTransactions] = useState<
        ManualTransaction[]
    >([]);
    const [readOnly, setReadOnly] = useState<boolean>(false);

    return (
        <PageWrapper>
            <Typography variant="h4">Manual Transaction</Typography>
            <br />
            <Header
                id={id ? parseInt(id) : null}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                manualTransactions={manualTransactions}
                setManualTransactions={setManualTransactions}
                showSnackbar={showSnackbar}
            />
            {!id ? (
                <>
                    <br />
                    <TransactionLines
                        manualTransactions={manualTransactions}
                        setManualTransactions={setManualTransactions}
                    />

                    <Fab
                        noSession={true}
                        editIcon={false}
                        onClick={() =>
                            HandleSubmitManualTransaction(
                                manualTransactions,
                                showSnackbar
                            )
                        }
                    />
                </>
            ) : null}
        </PageWrapper>
    );
};

export default withSnackbar(ManualTransactionUI);
