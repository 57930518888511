import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import DeletePaymentRun from '../logic/DeletePaymentRun';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface DeletePaymentRunDialogProps {
    deleteDialogOpen: boolean;
    setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
    deletePaymentRunId: number;
    showSnackbar: showSnackbar;
}

const DeletePaymentRunDialog = ({
    deleteDialogOpen,
    setDeleteDialogOpen,
    deletePaymentRunId,
    showSnackbar
}: DeletePaymentRunDialogProps) => {
    const [deleteReason, setDeleteReason] = useState('');

    return (
        <>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Delete Payment Run</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                {' '}
                                Are you sure to delete payment run #
                                {deletePaymentRunId} ? This cannot be undone.
                                <br />
                                Please provide a reason for the deletion before
                                proceeding.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label="Delete Reason"
                                InputLabelProps={{ shrink: true }}
                                value={deleteReason}
                                onChange={(e) => {
                                    setDeleteReason(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        disabled={deleteReason === ''}
                        onClick={() =>
                            DeletePaymentRun({
                                PaymentRunId: deletePaymentRunId,
                                deleteReason: deleteReason,
                                showSnackbar: showSnackbar
                            })
                        }
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withSnackbar(DeletePaymentRunDialog);
