import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography
} from '@mui/material';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import ClearIcon from '@mui/icons-material/Clear';

interface CustomerConditionDeclarationFormProps {
    onVendorFormSubmit: (formData: any, submitType: any) => Promise<void>;
    buttonLoading: boolean;
}

const CustomerConditionDeclarationForm = ({
    onVendorFormSubmit,
    buttonLoading
}: CustomerConditionDeclarationFormProps) => {
    const { control, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            listFaults: '',
            vendorSigDataURL: null
        }
    });

    let vendorSigCanvas = useRef<SignatureCanvas>(null);

    const clearSignature = () => {
        vendorSigCanvas.current.clear();
        setValue('vendorSigDataURL', null);
    };

    const saveSignature = () => {
        const dataURL = vendorSigCanvas.current.toDataURL();
        setValue('vendorSigDataURL', dataURL);
    };

    const handlePreviewChanges = (data) => {
        onVendorFormSubmit(data, 'preview');
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Paper
                elevation={3}
                sx={{
                    padding: 3,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 'bold' }}
                            >
                                Declaration of Condition
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Any faults of the bike are to be listed here,
                                and have already been discussed with the
                                Brisbane Motorcycles’ Bike Buyer –
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="listFaults"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="List any faults here"
                                        multiline
                                        rows={4}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            SIGNATURE:{' '}
                            <IconButton onClick={clearSignature}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="vendorSigDataURL"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <SignatureCanvas
                                            penColor="black"
                                            disabled={false}
                                            canvasProps={{
                                                className: 'sigCanvas',
                                                style: {
                                                    border: '1px solid black',
                                                    minWidth: 300,
                                                    width: '30vw',
                                                    minHeight: 100,
                                                    height: '15vw'
                                                }
                                            }}
                                            ref={vendorSigCanvas}
                                            // save user signature dataURL to form data
                                            onEnd={saveSignature}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                        {/* // buttons to preview and email the form data */}
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Button
                                onClick={handleSubmit(handlePreviewChanges)}
                                variant="contained"
                                disabled={watch('vendorSigDataURL') === null}
                            >
                                Preview Signature
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
};

export default CustomerConditionDeclarationForm;
