import { Dispatch, SetStateAction } from "react"
import api from "../../../../../api"
import dayjs, {Dayjs} from 'dayjs'

interface CreditingPartsLine {
    partNumber: string;
    name: string;
    unitId: number;
    costPriceDaily: number;
    quantity: number;
    lineTotal: number;
    qtyError: boolean;
    lineTotalError: boolean;
}

const GetCreditInvoice = (id: string, setSelectedSupplier: Dispatch<SetStateAction<number>>, setSelectedSite: Dispatch<SetStateAction<number>>, setReference: Dispatch<SetStateAction<string>>, setDocumentDate: Dispatch<SetStateAction<Dayjs>>, setDueDate: Dispatch<SetStateAction<Dayjs>>, setNotes: Dispatch<SetStateAction<string>>, setCreditingParts: Dispatch<SetStateAction<CreditingPartsLine[]>>, setFreight: Dispatch<SetStateAction<string>>, setIsReconciled: Dispatch<SetStateAction<boolean>>, setReadOnly: Dispatch<SetStateAction<boolean>>) => {
    api.get('creditStockInvoiceNextGen/' + id).then(res => {
        let data = res.data
        setSelectedSupplier(data.supplier_id)
        setSelectedSite(data.site_id)
        setReference(data.documentReference)
        setDocumentDate(dayjs(data.invoiceDate))
        setDueDate(dayjs(data.due))
        setNotes(data.notes)
        setCreditingParts(data.credit_lines)
        setFreight(data.freight)
        setIsReconciled(data.isReconciled)
        setReadOnly(true)
    })
}

export default GetCreditInvoice