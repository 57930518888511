// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    CarRepair,
    Delete,
    Dns,
    Download,
    Edit,
    Notes,
    Person,
    QueryBuilder,
    Save,
    Textsms
} from '@mui/icons-material';
// INTERFACES
import { Customer, User } from '../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { CustomerVehicle } from '../../../global/interfaces/ServiceInterface';
import { ExportTechTimeClock } from '../../../header/logic/ExportTechTimeClock';

const SpeedDialList = (
    id: number,
    readOnly: boolean,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    setLogsDrawerOpen: Dispatch<SetStateAction<boolean>>,
    selectedTech: User,
    setTimeClockOpen: Dispatch<SetStateAction<boolean>>,
    selectedVehicle: Vehicle | CustomerVehicle,
    vehicleType: string,
    setVehicleHistoryDrawerOpen: Dispatch<SetStateAction<boolean>>,
    selectedCustomer: Customer,
    setCustomerHistoryDrawerOpen: Dispatch<SetStateAction<boolean>>,
    setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>,
    setNotesDialogOpen: Dispatch<SetStateAction<boolean>>,
    setTextDrawerOpen: Dispatch<SetStateAction<boolean>>,
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>,
    handleSubmitService: () => void
) => {
    let actionList = [];

    let editItem = {
        icon: <Edit />,
        name: 'Edit',
        onClick: () => {
            setReadOnly(false);
            setShouldBlockNavigation(true);
        }
    };
    let saveItem = {
        icon: <Save />,
        name: 'Save',
        onClick: () => handleSubmitService()
    };
    let notesItem = {
        icon: <Notes />,
        name: 'Notes',
        onClick: () => setNotesDialogOpen(true)
    };
    let textItem = {
        icon: <Textsms />,
        name: 'Text Customer',
        onClick: () => setTextDrawerOpen(true)
    };
    let deleteItem = {
        icon: <Delete />,
        name: 'Delete',
        onClick: () => setDeleteDialogOpen(true)
    };
    let customerHistoryItem = {
        icon: <Person />,
        name: 'Customer History',
        onClick: () => setCustomerHistoryDrawerOpen(true)
    };
    let vehicleHistoryItem = {
        icon: <CarRepair />,
        name: 'Vehicle History',
        onClick: () => setVehicleHistoryDrawerOpen(true)
    };
    let techTimeClockItem = {
        icon: <QueryBuilder />,
        name: 'Tech Time Clock',
        onClick: () => setTimeClockOpen(true)
    };
    let techTimeClockExportItem = {
        icon: <Download />,
        name: 'Export Tech Time Clock',
        onClick: () => ExportTechTimeClock(id)
    };
    let logsItem = {
        icon: <Dns />,
        name: 'Logs',
        onClick: () => setLogsDrawerOpen(true)
    };

    if (id && readOnly) {
        actionList.push(editItem);
    } else if (!readOnly) {
        actionList.push(saveItem);
    }

    actionList.push(notesItem);

    if (selectedCustomer) {
        actionList.push(customerHistoryItem);
    }

    if (selectedVehicle && vehicleType === 'stock') {
        actionList.push(vehicleHistoryItem);
    }

    if (selectedTech) {
        actionList.push(techTimeClockItem);
        actionList.push(techTimeClockExportItem);
    }

    if (id) {
        actionList.push(textItem);
        actionList.push(logsItem);
        actionList.push(deleteItem);
    }

    return actionList;
};

export default SpeedDialList;
