// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Checkbox,
    DialogContentText,
    FormControlLabel,
    FormGroup,
    Grid
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import GetAllUsers from '../../../global/databaseLogic/GetAllUsers';
// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site, User } from '../../../global/interfaces/GeneralInterface';

interface CustomerTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const statusOptions = [
    { id: 0, label: 'Quote', value: 'Quote' },
    { id: 1, label: 'Sale', value: 'Sale' },
    { id: 2, label: 'Finalised', value: 'Finalised' },
    { id: 3, label: 'Lost', value: 'Lost' }
];

const VehicleSaleTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: CustomerTableFilterProps) => {
    const [statuses, setStatuses] = useState([
        { id: 2, label: 'Finalised', value: 'Finalised' },
        { id: 3, label: 'Lost', value: 'Lost' }
    ]);
    const [sitesUnaltered, setSitesUnaltered] = useState<Site[]>([]);
    const [sites, setSites] = useState<Site[]>([]);
    const [usersUnaltered, setUsersUnaltered] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    // Get all the sites to display in the dropdown
    useEffect(() => {
        GetAllSites(setSitesUnaltered);
        GetAllSites(setSites);
        GetAllUsers(setUsersUnaltered, '?department=sales');
        GetAllUsers(setUsers, '?department=sales');
    }, []);

    // Check if the filter is active, if it isn't reset the filter options
    // This is primarily for when a user clicks the clear filter button, we want all options
    // back in the dropdown
    useEffect(() => {
        if (!isFilterActive()) {
            setSites(sitesUnaltered);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.vehicleSaleFilter;
        newFilter[property] = value;
        setFilter({ ...filter, vehicleSaleFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={statuses}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'value'}
                        textfieldLabel="Status"
                        selectedValues={filter.vehicleSaleFilter.status}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = statusOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setStatuses(currentOptions);
                            handleUpdateFilter('status', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.vehicleSaleFilter.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sitesUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.name !== val.name
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={users}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="User"
                        selectedValues={filter.vehicleSaleFilter.user}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = usersUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setUsers(currentOptions);
                            handleUpdateFilter('user', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Sale Start Range"
                            value={filter.vehicleSaleFilter.saleStartDate}
                            onChange={(newValue) => {
                                handleUpdateFilter('saleStartDate', newValue);
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Sale End Range"
                            value={filter.vehicleSaleFilter.saleEndDate}
                            onChange={(newValue) => {
                                handleUpdateFilter('saleEndDate', newValue);
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Finalised Start Range"
                            value={filter.vehicleSaleFilter.finalisedStartDate}
                            onChange={(newValue) => {
                                handleUpdateFilter(
                                    'finalisedStartDate',
                                    newValue
                                );
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            label="Finalised End Range"
                            value={filter.vehicleSaleFilter.finalisedEndDate}
                            onChange={(newValue) => {
                                handleUpdateFilter(
                                    'finalisedEndDate',
                                    newValue
                                );
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter.vehicleSaleFilter.fullyPaid}
                                    onChange={(e) =>
                                        handleUpdateFilter(
                                            'fullyPaid',
                                            e.target.checked
                                        )
                                    }
                                />
                            }
                            label="Fully Paid"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filter.vehicleSaleFilter.archived}
                                    onChange={(e) =>
                                        handleUpdateFilter(
                                            'archived',
                                            e.target.checked
                                        )
                                    }
                                />
                            }
                            label="Include Archived Results"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </>
    );
};

export default VehicleSaleTableFilter;
