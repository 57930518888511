import DocumentationPage from '../../DocumentationPage';
import { RebateReconciliatorSteps } from '../../data/admin/rebateReconciliator';

const RebateReconciliatorSupportPage = () => {
    const contentSections = [
        {
            id: 'rebate-reconciliator',
            title: 'Rebate Reconciliator',
            href: '/support/admin/rebateReconciliation/#rebate-reconciliator',
            description:
                'To reconcile vehicle rebate and vehicle sale, follow these steps:',
            steps: RebateReconciliatorSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Rebate Reconciliator"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default RebateReconciliatorSupportPage;
