// IMPROTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Button,
    Grid,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import Dayjs from 'dayjs';

// COMPONENTS
import Autocomplete from '../../../../global/Autocomplete';
import Paper from '../../../../global/Paper';
// LOGIC
import GetAllSuppliers from '../../../../global/databaseLogic/GetAllSuppliers';
import GetAllNewVehicles from '../../../../global/databaseLogic/GetAllNewVehicles';
import GetAllAccounts from '../../../../global/databaseLogic/GetAllAccounts';
import GetAllSites from '../../../../global/databaseLogic/GetAllSites';
// INTERFACES
import { Creditor, Site } from '../../../../global/interfaces/GeneralInterface';
import { Account } from '../../../../global/interfaces/AdminInterface';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Vehicle?: Vehicle;
    Account: Account;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
    documentReference: string;
    bypassCredRec?: boolean;
}
interface InvoiceLine {
    id: number;
    Vehicle?: Vehicle;
    amount: string;
    description: string;
}
interface InvoiceHeaderProps {
    invoiceObject: InvoiceObject;
    setInvoiceObject: Dispatch<SetStateAction<InvoiceObject>>;
    invoiceLines: InvoiceLine[];
    setInvoiceLines: Dispatch<SetStateAction<InvoiceLine[]>>;
    responseCode: number;
    setResponseCode: Dispatch<SetStateAction<number>>;
    showSnackbar: showSnackbar;
    isDisabled?: boolean;
}

const InvoiceHeader = ({
    invoiceObject,
    setInvoiceObject,
    invoiceLines,
    setInvoiceLines,
    responseCode,
    setResponseCode,
    showSnackbar,
    isDisabled
}: InvoiceHeaderProps) => {
    const [creditors, setCreditors] = useState<Creditor[]>([]);
    const [sites, setSites] = useState<Site[]>([]);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [lineAmount, setLineAmount] = useState('');
    const [lineDescription, setLineDescription] = useState('');

    const [searchVin, setSearchVin] = useState<boolean>(true);

    // Get all the creditors, sites, vehicles and accounts
    useEffect(() => {
        GetAllSuppliers(setCreditors);
        GetAllSites(setSites);
        GetAllNewVehicles(setVehicles);
        GetAllAccounts(setAccounts);
    }, []);

    // Once all the sites have been loaded default the site selector to the current site
    useEffect(() => {
        if (sites.length > 0) {
            let currentSite = sites.filter(
                (x) => x.id === parseInt(localStorage.getItem('SiteId'))
            );
            setInvoiceObject({ ...invoiceObject, Site: currentSite[0] });
        }
        // eslint-disable-next-line
    }, [sites]);

    // Handle adding a line to the invoice
    const addToInvoiceLines = (lineAmount: string, lineDescription: string) => {
        // If no vehicle, description of amount has been entered display an error
        if (!invoiceObject.Vehicle) {
            showSnackbar(
                'Whoops! Missing Data!',
                'Please select a vehicle for the line.',
                'error'
            );
            return;
        } else if (!lineAmount) {
            showSnackbar(
                'Whoops! Missing Data!',
                'Please enter an amount for the line.',
                'error'
            );
            return;
        }

        // Get the current lines and calculate a new ID
        let currentLines = [...invoiceLines];
        let newId =
            currentLines.length > 0
                ? currentLines[currentLines.length - 1].id + 1
                : 1;

        // Generate the new line
        let newLine = {
            id: newId,
            Vehicle: invoiceObject.Vehicle,
            amount: lineAmount,
            description: lineDescription
        };

        // Push the new line and update the state
        currentLines.push(newLine);
        setInvoiceLines(currentLines);

        // Clear the old line data
        setInvoiceObject({ ...invoiceObject, Vehicle: null });
        setLineAmount('');
        setLineDescription('');
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            isDisabled={
                                (invoiceObject.Account ? true : false) ||
                                isDisabled
                            }
                            options={creditors}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Creditor *"
                            selectedValue={invoiceObject.Creditor}
                            handleSelectedValueChange={(newValue) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    Creditor: newValue
                                })
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={2.5}>
                        <Autocomplete
                            size="small"
                            isDisabled={
                                (invoiceObject.Creditor ? true : false) ||
                                isDisabled
                            }
                            options={accounts}
                            useTwoOptionLabels={true}
                            primaryOptionLabel="name"
                            secondaryOptionLabel="siteName"
                            textfieldLabel="Account *"
                            selectedValue={invoiceObject.Account}
                            handleSelectedValueChange={(newValue) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    Account: newValue
                                })
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={2.5}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Document Reference"
                            disabled={isDisabled}
                            InputLabelProps={{ shrink: true }}
                            value={invoiceObject.documentReference}
                            onChange={(e) => {
                                setInvoiceObject({
                                    ...invoiceObject,
                                    documentReference: e.target.value
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={isDisabled}
                                format="DD/MM/YYYY"
                                label="Document Date *"
                                value={Dayjs(invoiceObject.documentDate)}
                                onChange={(newValue) => {
                                    setInvoiceObject({
                                        ...invoiceObject,
                                        documentDate: newValue.toISOString()
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={isDisabled}
                                format="DD/MM/YYYY"
                                label="Due Date"
                                value={Dayjs(invoiceObject.dueDate)}
                                onChange={(newValue) =>
                                    setInvoiceObject({
                                        ...invoiceObject,
                                        dueDate: newValue.toISOString()
                                    })
                                }
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        {invoiceObject.Creditor ? (
                            <Typography>
                                <Switch
                                    checked={invoiceObject.bypassCredRec}
                                    disabled={isDisabled}
                                    onChange={() => {
                                        setInvoiceObject({
                                            ...invoiceObject,
                                            bypassCredRec:
                                                !invoiceObject.bypassCredRec
                                        });
                                    }}
                                />{' '}
                                Push straight to Bank Reconciliation
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            disabled={isDisabled}
                            size="small"
                            label="Document Total * (INC. GST)"
                            InputLabelProps={{ shrink: true }}
                            value={invoiceObject.documentTotal}
                            onChange={(e) =>
                                setInvoiceObject({
                                    ...invoiceObject,
                                    documentTotal: e.target.value
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>

            {isDisabled ? null : (
                <>
                    <br />
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={2.5} textAlign="center">
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <Typography>STOCK #</Typography>
                                    <Switch
                                        checked={searchVin}
                                        onChange={(e) =>
                                            setSearchVin(e.target.checked)
                                        }
                                    />
                                    <Typography>VIN</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    size="small"
                                    options={vehicles}
                                    useTwoOptionLabels={true}
                                    primaryOptionLabel={
                                        searchVin ? 'shortVin' : 'stockNumber'
                                    }
                                    secondaryOptionLabel="vehicleName"
                                    textfieldLabel="Vehicle *"
                                    selectedValue={invoiceObject.Vehicle}
                                    handleSelectedValueChange={(newValue) =>
                                        setInvoiceObject({
                                            ...invoiceObject,
                                            Vehicle: newValue
                                        })
                                    }
                                    handleInputValueChange={() => null}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Line Amount *"
                                    InputLabelProps={{ shrink: true }}
                                    value={lineAmount}
                                    onChange={(e) =>
                                        setLineAmount(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={2.5}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Line Description"
                                    InputLabelProps={{ shrink: true }}
                                    value={lineDescription}
                                    onChange={(e) =>
                                        setLineDescription(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography align="center">
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            addToInvoiceLines(
                                                lineAmount,
                                                lineDescription
                                            )
                                        }
                                    >
                                        Add Line
                                    </Button>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </>
            )}
        </>
    );
};

export default InvoiceHeader;
