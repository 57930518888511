import jsPDF from "jspdf";
import PNLSales from "./PNLReportFunction/PNLSales";
import PNLExpenditure from "./PNLReportFunction/PNLExpenditure";
import PNLHeader from "./PNLReportFunction/PNLHeader";
import { PrintPNLReportParams } from "../interface/PNLInterfaces";

const PrintPNLReport = (params: PrintPNLReportParams) => {
    // Sum of all revenue for selected date
    let totalSale = params.vehicleValues.newRevenue +
    params.vehicleValues.usedRevenue +
    params.vehicleValues.dofPrice +
    params.vehicleValues.warrantyPrice +
    params.vehicleValues.adminLoadCost +
    params.partValues.onlineRevenue +
    params.partValues.partRevenue +
    params.partValues.saleRevenue +
    params.partValues.serviceRevenue +
    params.partValues.vehicleRevenue +
    params.serviceValues.internalRevenue +
    params.serviceValues.retailRevenue +
    params.serviceValues.insuranceRevenue +
    params.serviceValues.warrantyRevenue

    // Sum of all cost for selected date
    let totalCOGS = params.vehicleValues.newCost +
    params.vehicleValues.usedCost +
    params.partValues.onlineCost +
    params.partValues.partCost +
    params.partValues.saleCost +
    params.partValues.serviceCost +
    params.partValues.vehicleCost +
    params.serviceValues.insuranceCost +
    params.serviceValues.internalCost +
    params.serviceValues.retailCost +
    params.serviceValues.warrantyCost

    // Sum of all revenue for previous year (same date range)
    let previousTotalSale = params.previousVehicleValues.newRevenue +
    params.previousVehicleValues.usedRevenue +
    params.previousVehicleValues.dofPrice +
    params.previousVehicleValues.warrantyPrice +
    params.previousVehicleValues.adminLoadCost +
    params.previousPartValues.onlineRevenue +
    params.previousPartValues.partRevenue +
    params.previousPartValues.saleRevenue +
    params.previousPartValues.serviceRevenue +
    params.previousPartValues.vehicleRevenue +
    params.previousServiceValues.internalRevenue +
    params.previousServiceValues.retailRevenue +
    params.previousServiceValues.insuranceRevenue +
    params.previousServiceValues.warrantyRevenue
    
    // Sum of all cost for previous year (same date range)
    let previousTotalCOGS = params.previousVehicleValues.newCost +
    params.previousVehicleValues.usedCost +
    params.previousPartValues.onlineCost +
    params.previousPartValues.partCost +
    params.previousPartValues.saleCost +
    params.previousPartValues.serviceCost +
    params.previousPartValues.vehicleCost +
    params.previousServiceValues.insuranceCost +
    params.previousServiceValues.internalCost +
    params.previousServiceValues.retailCost +
    params.previousServiceValues.warrantyCost

    // Sum of all revenue for year to date
    let ytdTotalSale = params.ytdMonthVehicleValues.newRevenue +
    params.ytdMonthVehicleValues.usedRevenue +
    params.ytdMonthVehicleValues.dofPrice +
    params.ytdMonthVehicleValues.warrantyPrice +
    params.ytdMonthVehicleValues.adminLoadCost +
    params.ytdMonthPartValues.onlineRevenue +
    params.ytdMonthPartValues.partRevenue +
    params.ytdMonthPartValues.saleRevenue +
    params.ytdMonthPartValues.serviceRevenue +
    params.ytdMonthPartValues.vehicleRevenue +
    params.ytdMonthServiceValues.internalRevenue +
    params.ytdMonthServiceValues.retailRevenue +
    params.ytdMonthServiceValues.insuranceRevenue +
    params.ytdMonthServiceValues.warrantyRevenue
    
    // Sum of all cost for previous year (same date range)
    let ytdTotalCOGS = params.ytdMonthVehicleValues.newCost +
    params.ytdMonthVehicleValues.usedCost +
    params.ytdMonthPartValues.onlineCost +
    params.ytdMonthPartValues.partCost +
    params.ytdMonthPartValues.saleCost +
    params.ytdMonthPartValues.serviceCost +
    params.ytdMonthPartValues.vehicleCost +
    params.ytdMonthServiceValues.insuranceCost +
    (params.ytdMonthServiceValues.internalCost ?? 0) +
    params.ytdMonthServiceValues.retailCost +
    params.ytdMonthServiceValues.warrantyCost

    // Sum of all revenue for previous year Year To Date
    let lastYearYtdTotalSale = params.lastYearYtdMonthVehicleValues.newRevenue +
    params.lastYearYtdMonthVehicleValues.usedRevenue +
    params.lastYearYtdMonthVehicleValues.dofPrice +
    params.lastYearYtdMonthVehicleValues.warrantyPrice +
    params.lastYearYtdMonthVehicleValues.adminLoadCost +
    params.lastYearYtdMonthPartValues.onlineRevenue +
    params.lastYearYtdMonthPartValues.partRevenue +
    params.lastYearYtdMonthPartValues.saleRevenue +
    params.lastYearYtdMonthPartValues.serviceRevenue +
    params.lastYearYtdMonthPartValues.vehicleRevenue +
    params.lastYearYtdMonthServiceValues.internalRevenue +
    params.lastYearYtdMonthServiceValues.retailRevenue +
    params.lastYearYtdMonthServiceValues.insuranceRevenue +
    params.lastYearYtdMonthServiceValues.warrantyRevenue

    // Sum of all cost for previous year Year To Date
    let lastYearYtdTotalCOGS = params.lastYearYtdMonthVehicleValues.newCost +
    params.lastYearYtdMonthVehicleValues.usedCost +
    params.lastYearYtdMonthPartValues.onlineCost +
    params.lastYearYtdMonthPartValues.partCost +
    params.lastYearYtdMonthPartValues.saleCost +
    params.lastYearYtdMonthPartValues.serviceCost +
    params.lastYearYtdMonthPartValues.vehicleCost +
    params.lastYearYtdMonthServiceValues.insuranceCost +
    params.lastYearYtdMonthServiceValues.internalCost +
    params.lastYearYtdMonthServiceValues.retailCost +
    params.lastYearYtdMonthServiceValues.warrantyCost

    let profitFromTrading = totalSale - totalCOGS
    let previousYearProfitFromTrading = previousTotalSale - previousTotalCOGS
    let ytdMonthProfitFromTrading = ytdTotalSale - ytdTotalCOGS
    let lastYearYtdProfitFromTrading = lastYearYtdTotalSale - lastYearYtdTotalCOGS

    var doc = new jsPDF();
    
    // Header
    PNLHeader(doc, params)

    // SALES
    PNLSales(doc, params);

    // EXPENSES
    PNLExpenditure(doc, params, totalSale, previousTotalSale, ytdTotalSale, lastYearYtdTotalSale, profitFromTrading, previousYearProfitFromTrading, ytdMonthProfitFromTrading, lastYearYtdProfitFromTrading)

    window.open(doc.output("bloburl"));
};
export default PrintPNLReport;
