import { Dispatch, SetStateAction, useState } from 'react';
import { DialogActions, DialogContent, TextField } from '@mui/material';

// INTERFACE
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Brand } from '../../../../global/interfaces/PartsInterface';
import UpdateBrandName from '../../logic/UpdateBrandName';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const EditBrandDialogContent = ({
    showSnackbar,
    selectedBrand,
    setSelectedBrand
}: {
    showSnackbar: showSnackbar;
    selectedBrand: Brand;
    setSelectedBrand: Dispatch<SetStateAction<Brand>>;
}) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    return (
        <>
            <DialogContent>
                <TextField
                    fullWidth
                    size="small"
                    value={selectedBrand?.name}
                    onChange={(e) =>
                        setSelectedBrand({
                            ...selectedBrand,
                            name: e.target.value
                        })
                    }
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        UpdateBrandName(
                            selectedBrand,
                            showSnackbar,
                            setButtonLoading
                        )
                    }
                >
                    Update Category Name
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default EditBrandDialogContent;
