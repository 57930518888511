import {
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';

const PurchaserCreditForm = ({ control }) => {
    return (
        <Grid container spacing={2} margin={'10px'}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom component="div">
                    DOES THE PURCHASER REQUIRE CREDIT?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="creditOption"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="noCreditNeeded"
                                control={<Radio />}
                                label="The Purchaser does not require credit for payment of the motor vehicle."
                            />
                            <FormControlLabel
                                value="arrangeCredit"
                                control={<Radio />}
                                label="The Purchaser will arrange for credit."
                            />
                            <FormControlLabel
                                value="authorizeDealerCredit"
                                control={<Radio />}
                                label="The Purchaser authorizes the Dealer to arrange credit on behalf of the Purchaser."
                            />
                        </RadioGroup>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                    CREDIT PROVIDER / INSURANCE PROVIDER
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Controller
                    name="contact"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Contact"
                            fullWidth
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Phone Number"
                            fullWidth
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name="premium"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Premium $"
                            fullWidth
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
            </Grid>
        </Grid>
    );
};

export default PurchaserCreditForm;
