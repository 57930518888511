// IMPORTS
import { useState } from 'react';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
// INTERFACES
import { Voucher } from '../../../global/interfaces/PartsInterface';

interface VoucherDrawerContentProps {
    voucher: Voucher;
    handleAddPayment: (paymentAmount: string, paymentType?: string) => any;
    hidePaymentAmount?: boolean;
}
const VoucherDrawerContent = ({
    voucher,
    handleAddPayment,
    hidePaymentAmount
}: VoucherDrawerContentProps) => {
    const [paymentAmount, setPaymentAmount] = useState<string>(
        String(voucher?.currentBalance)
    );
    return (
        <>
            <Typography variant="body1">
                <b>Customer: </b>
                {voucher?.Customer?.firstName +
                    ' ' +
                    voucher?.Customer?.lastName}
            </Typography>
            <Typography variant="body1">
                <b>Voucher Code: </b>
                {voucher?.voucherCode}
            </Typography>
            <Typography variant="body1">
                <b>Date Purchased: </b>
                {DateFormatter(voucher?.createdAt)}
            </Typography>
            <Typography variant="body1">
                <b>Full Value: </b>
                {CurrencyFormatter(voucher?.totalPrice)}
            </Typography>
            <br />
            <Typography variant="body1">
                <b>Balance Remaining: </b>
                {CurrencyFormatter(voucher?.currentBalance)}
            </Typography>
            <br />
            <Divider />
            <br />
            {hidePaymentAmount ? null : (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Amount to use"
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                value={paymentAmount}
                                onChange={(e) =>
                                    setPaymentAmount(e.target.value)
                                }
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter')
                                        handleAddPayment(paymentAmount);
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant="contained"
                                onClick={() => handleAddPayment(paymentAmount)}
                            >
                                Add Payment
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
            <br />
        </>
    );
};

export default VoucherDrawerContent;
