import { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { Send } from '@mui/icons-material';

// COMPONENTS
import Paper from '../../global/Paper';
import Autocomplete from '../../global/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import { withSnackbar } from '../../global/WrappingSnackbar';
import PreviewFile from './components/PreviewFile';
import AutocompleteMultiple from '../../global/AutocompleteMultiple';

// LOGIC
import GetAllCreditors from '../../global/databaseLogic/GetAllCreditors';
import SetupPreviewData from './logic/SetupPreviewData';
import PrepareData from './logic/PrepareData';
import ImportSupplierFile from './logic/ImportSupplierFile';
import GetAllSites from '../../global/databaseLogic/GetAllSites';

// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Creditor, Site } from '../../global/interfaces/GeneralInterface';

const ImportPriceFile = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [creditorsList, setCreditorsList] = useState<Creditor[]>([]);
    const [selectedSite, setSelectedSite] = useState<Site[]>([]);
    const [siteList, setSiteList] = useState<Site[]>([]);
    const [unalteredSiteList, setUnalteredSiteList] = useState<Site[]>([]);
    const [importing, setImporting] = useState<boolean>(false);
    const [selectedSupplier, setSelectedSupplier] = useState<Creditor>({});
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
    const [importInstructions, setImportInstructions] =
        useState<string>('updateOnly');
    const [importData, setImportData] = useState([]);
    const [previewData, setPreviewData] = useState({
        first10: [],
        last10: []
    });

    useEffect(() => {
        GetAllCreditors(setCreditorsList);
        GetAllSites(setSiteList);
        GetAllSites(setUnalteredSiteList);
    }, []);

    useEffect(() => {
        SetupPreviewData(importData, setPreviewData, setSelectedHeaders);
    }, [importData]);

    //reset data when finished importing
    useEffect(() => {
        if (!importing) {
            setImportData([]);
            setSelectedHeaders([]);
        }
    }, [importing]);

    return (
        <div id="table">
            <br />
            <Typography variant="h4" align="left">
                Import Supplier Files
            </Typography>
            <br />
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Autocomplete
                            size="small"
                            options={creditorsList}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Creditor *"
                            selectedValue={selectedSupplier}
                            handleSelectedValueChange={(newValue) =>
                                setSelectedSupplier(newValue)
                            }
                            handleInputValueChange={() => null}
                        />
                        <br />
                        <br />
                        {importData.length > 0 ? (
                            <LoadingButton
                                endIcon={<Send />}
                                loading={importing}
                                onClick={() =>
                                    PrepareData({
                                        importData: importData,
                                        previewData: previewData,
                                        selectedHeaders: selectedHeaders,
                                        selectedSupplier: selectedSupplier,
                                        importInstructions: importInstructions,
                                        selectedSite,
                                        showSnackbar: showSnackbar,
                                        setImporting
                                    })
                                }
                                variant="contained"
                            >
                                Upload File
                            </LoadingButton>
                        ) : (
                            <>
                                <input
                                    onChange={(e) =>
                                        ImportSupplierFile(e, setImportData)
                                    }
                                    accept=".csv, .xlsx"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="outlined" component="span">
                                        Upload CSV / XLSX
                                    </Button>
                                </label>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <AutocompleteMultiple
                            options={siteList}
                            size="small"
                            fullWidth={true}
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            textfieldLabel="Select Site(s)"
                            selectedValues={selectedSite}
                            handleSelectedValueChange={(event) => {
                                let currentOptions = unalteredSiteList;
                                let newFilterValue = [];

                                // Add or remove the value from the selected value
                                // Also add or remove the selected option from the options list so it can not be added more than once
                                event.forEach((val, index) => {
                                    newFilterValue.push(val);
                                    currentOptions = currentOptions.filter(
                                        (x) => x.name !== val.name
                                    );
                                });

                                setSiteList(currentOptions);
                                setSelectedSite(newFilterValue);
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <Typography variant="body1" align="left">
                                <b>Import Instructions</b>
                            </Typography>
                            <RadioGroup
                                value={importInstructions}
                                onChange={(e) =>
                                    setImportInstructions(e.target.value)
                                }
                            >
                                <FormControlLabel
                                    value="updateOnly"
                                    control={<Radio color="primary" />}
                                    label="Update Existing Parts Only"
                                />
                                <FormControlLabel
                                    value="addOnly"
                                    control={<Radio color="primary" />}
                                    label="Add New Parts Only"
                                />
                                <FormControlLabel
                                    value="updateAndAdd"
                                    control={<Radio color="primary" />}
                                    label="Update Existing Parts and Add New Parts"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            <br />
            {importData.length > 0 && !importing ? (
                <PreviewFile
                    importData={importData}
                    setImportData={setImportData}
                    previewData={previewData}
                    setPreviewData={setPreviewData}
                    selectedHeaders={selectedHeaders}
                    setSelectedHeaders={setSelectedHeaders}
                />
            ) : null}
        </div>
    );
};

export default withSnackbar(ImportPriceFile);
