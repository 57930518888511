import * as yup from 'yup';
import { Site } from '../../../OraNextGen/global/interfaces/GeneralInterface';
import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../OraNextGen/global/interfaces/GlobalInterface';

let tillError = {
    code: {
        error: false,
        message: ''
    },
    name: {
        error: false,
        message: ''
    },
    siteName: {
        error: false,
        message: ''
    }
};

//site validate schema
const tillSchema = yup.object().shape({
    code: yup.string().required(() => {
        tillError = {
            ...tillError,
            code: {
                error: true,
                message: 'Till code is required.'
            }
        };
        return 'Till code is required.';
    }),
    name: yup.string().required(() => {
        tillError = {
            ...tillError,
            name: {
                error: true,
                message: 'A Till name is required.'
            }
        };
        return 'A Till name is required.';
    }),
    siteName: yup.string().required(() => {
        tillError = {
            ...tillError,
            siteName: {
                error: true,
                message: 'A Site must be selected for each till(s).'
            }
        };
        return 'A Site must be selected for each till(s).';
    })
});

// Combine the schemas for the complete validation
const validationSchema = yup.object().shape({
    till: tillSchema
});

const ValidateStep3 = async (
    tills: any[],
    sites: Site[],
    setActiveStep: Dispatch<SetStateAction<number>>,
    setStepValidation,
    showSnackbar: showSnackbar
) => {
    let tillCounter = 0;
    try {
        if (tills.length === 0) {
            showSnackbar(
                'Please enter at least one till per site',
                '',
                'error'
            );
        }

        for (let till of tills) {
            await validationSchema.validate({ till }, { abortEarly: false });
            tillCounter += 1;
        }
        for (let site of sites) {
            let foundTill = tills.filter((x) => x.siteName === site.name);
            if (foundTill.length === 0) {
                showSnackbar(
                    'Please enter at least one till per site',
                    '',
                    'error'
                );
                return;
            }
        }

        setActiveStep(3);
    } catch (error) {
        let tillErrors = [];
        for (let i = 0; i < tills.length; i++) {
            tillErrors.push({
                name: {
                    error: false,
                    message: ''
                },
                siteName: {
                    error: false,
                    message: ''
                }
            });
        }
        tillErrors[tillCounter] = tillError;
        setStepValidation(tillErrors);
        showSnackbar(
            error.errors.join('\n'),
            'Please correct the errors listed above.',
            'error'
        );
        return {
            verified: false,
            message: error.errors.join(' '),
            subMessage: 'Please correct the errors listed above.'
        };
    }
};

export default ValidateStep3;
