import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Series, Vehicle } from "../../../global/interfaces/VehicleSaleInterface";

const HandleSeriesIdClick = (serie: Series, setSelectedMake: Dispatch<SetStateAction<Series>>, setOpenDialog: Dispatch<SetStateAction<boolean>>, setListofVehicles: Dispatch<SetStateAction<Vehicle[]>>, setFilterListOfVehicles: Dispatch<SetStateAction<Vehicle[]>>, setFilterValue: Dispatch<SetStateAction<string>>) => {
    api.get('vehiclesFromSerie/' + serie.id).then(res => {
        setListofVehicles(res.data)
        setFilterListOfVehicles(res.data)
    })
    setSelectedMake(serie)
    setFilterValue('')
    setOpenDialog(true)
}

export default HandleSeriesIdClick;