import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import {
    Creditor,
    OrderFileGenerator,
    Site
} from '../../../global/interfaces/GeneralInterface';
interface SelectedApi {
    id?: number;
    name: string;
    nameError: boolean;
    description: string;
    descriptionError: boolean;
    Site: Site;
    siteError: boolean;
    key: string;
    Supplier: Creditor;
    supplierError: boolean;
    canGenerateOrderFile: boolean;
    canOrderWithAPI: boolean;
    Generator: OrderFileGenerator;
    generatorError: boolean;
    OrderApi: OrderFileGenerator;
    OrderApiError: boolean;
    detailsList: {};

    OrderFileGeneratorId?: number;
    ApiOrderId?: number;
    additionalDetails?: {};
}

const SaveApi = (
    selectedApi: SelectedApi,
    setSelectedApi: Dispatch<SetStateAction<SelectedApi>>,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    let tempSelectedApi = JSON.parse(JSON.stringify(selectedApi));
    // VALIDATION
    let isError = false;

    // CHECK NAME
    if (selectedApi.name.length === 0 || selectedApi.name == null) {
        tempSelectedApi.nameError = true;
        isError = true;
    } else {
        tempSelectedApi.nameError = false;
    }

    // CHECK DESCRIPTION
    if (
        selectedApi.description.length === 0 ||
        selectedApi.description == null
    ) {
        tempSelectedApi.descriptionError = true;
        isError = true;
    } else {
        tempSelectedApi.descriptionError = false;
    }

    // CHECK IF SITE
    if (!selectedApi.Site.id) {
        tempSelectedApi.siteError = true;
        isError = true;
    } else {
        tempSelectedApi.siteError = false;
    }

    // IF SUPPLIER SELECTED MAKE SURE THEY CAN GENERATE ORDER FILE OR ORDER WITH API
    if (
        selectedApi.Supplier.id &&
        !(selectedApi.canGenerateOrderFile || selectedApi.canOrderWithAPI)
    ) {
        tempSelectedApi.supplierError = true;
        isError = true;
    } else {
        tempSelectedApi.supplierError = false;
    }

    // IF GENERATE ORDER FILE
    if (selectedApi.canGenerateOrderFile && !selectedApi.Generator.id) {
        tempSelectedApi.generatorError = true;
        isError = true;
    } else {
        tempSelectedApi.generatorError = false;
    }

    // IF ORDER VIA API
    if (selectedApi.canOrderWithAPI && !selectedApi.OrderApi.id) {
        tempSelectedApi.OrderApiError = true;
        isError = true;
    } else {
        tempSelectedApi.OrderApiError = false;
    }

    if (isError) {
        setSelectedApi(tempSelectedApi);
        setButtonLoading(false);
        return;
    }

    // UPDATE/SAVE DATA
    if (selectedApi.id) {
        api.put('/updateApi', selectedApi).then((res) => {
            if (res.status === 200) {
                window.location.reload();
            } else {
                setButtonLoading(false);
            }
        });
    } else {
        api.post('/addApi', selectedApi).then((res) => {
            if (res.status === 200) {
                window.location.reload();
            } else {
                setButtonLoading(false);
            }
        });
    }
};

export default SaveApi;
