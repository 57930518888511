import api from '../../../../../../api';
import GroupTransactionByDate from './GroupTransactionByDate';

const GetTransactionsV2 = (
    setReconciliationUnalteredDetails,
    setReconciliationDetailsGroupedByWeek,
    setSelectedOraUnrecTab,
    setSelectedBankUnrecTab,
    setSelectedOraRecTab,
    setSelectedBankRecTab,
    setLoading
) => {
    setLoading(true);
    api.get('bankReconciliationTransactionsNextGen').then((res) => {
        let oraTransactionsUnreconciled = [];
        let oraTransactionsReconciled = [];
        let bankTransactionsUnreconciled = [];
        let bankTransactionsReconciled = [];

        for (let item of res.data.formattedTillReconciliations) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedManualTransactions) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedTrustReceipts) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedDebtorPayments) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedTradeIns) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedVehicleDirectDeposits) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedServiceDirectDeposits) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedPartsDirectDeposits) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedPaymentRuns) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedStripePayments) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedServiceWarranties) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedVehicleRebates) {
            oraTransactionsUnreconciled.push(item);
        }
        for (let item of res.data.formattedDirectVehicleRebates) {
            oraTransactionsUnreconciled.push(item);
        }

        for (let item of res.data.formattedBankTransactions) {
            let referenceCompare = item.reference
                ? item.reference.toLowerCase()
                : '';
            let matchingOraTransactions = oraTransactionsUnreconciled.filter(
                (x) =>
                    x.amount === item.amount &&
                    referenceCompare.includes(
                        x.labelCompare ? x.labelCompare.toLowerCase() : ''
                    )
            );
            if (matchingOraTransactions.length === 1) {
                bankTransactionsReconciled.push(item);
                oraTransactionsReconciled.push(matchingOraTransactions[0]);
                oraTransactionsUnreconciled =
                    oraTransactionsUnreconciled.filter(
                        (x) => x !== matchingOraTransactions[0]
                    );
            } else {
                bankTransactionsUnreconciled.push(item);
            }
        }

        GroupTransactionByDate(
            {
                oraTransactionsReconciled: oraTransactionsReconciled,
                oraTransactionsUnreconciled: oraTransactionsUnreconciled,
                bankTransactionsReconciled: bankTransactionsReconciled,
                bankTransactionsUnreconciled: bankTransactionsUnreconciled
            },
            setReconciliationDetailsGroupedByWeek,
            setReconciliationUnalteredDetails,
            setSelectedOraUnrecTab,
            setSelectedBankUnrecTab,
            setSelectedOraRecTab,
            setSelectedBankRecTab
        );

        setLoading(false);
    });
};

export default GetTransactionsV2;
