// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
  Paper,
  CircularProgress,
  Table,
  TableRow,
  TableCell,
  Typography
} from '@mui/material';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import { Dayjs } from 'dayjs';

// COMPONENTS
import ParametersSelection from '../ParametersSelection';

// LOGIC
import CalculateStaffSalesReporting from './logic/CalculateStaffSalesReporting';
import { Site } from '../../../../global/interfaces/GeneralInterface';

interface StaffSalesReportingProps {
  (props: {
    selectedSite: Site[];
    setSelectedSite: Dispatch<SetStateAction<Site[]>>;
    queryDate: { startDate: Dayjs; endDate: Dayjs };
    setQueryDate: Dispatch<
      SetStateAction<{ startDate: Dayjs; endDate: Dayjs }>
    >;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
  }): JSX.Element;
}

const StaffSalesReporting: StaffSalesReportingProps = (props) => {
  const [staffVehicleSales, setStaffVehicleSales] = useState<
    {
      user: { id: string; firstName: string; lastName: string };
      vehicleData: {
        VehicleSaleId: number;
        adminLoadCost: number;
        customerName: string;
        deliveryPrice: number;
        extraCosts: number;
        serviceCosts: number;
        stockNumber: string;
        totalCost: number;
        totalSale: number;
        vehicleCondition: string;
        vehicleCostPrice: number;
        vehicleSalePrice: number;
      }[];
      vehicleValues: {
        newCost: number;
        newRevenue: number;
        newTotal: number;
        usedCost: number;
        usedRevenue: number;
        usedTotal: number;
      };
      wrongVehicleData: any[];
    }[]
  >([]);
  const [staffOrdersSales, setStaffOrdersSales] = useState<
    {
      user: { id: string; firstName: string; lastName: string };
      orderValues: {
        onlineCost: number;
        onlineRevenue: number;
        onlineTotal: number;
        partCost: number;
        partRevenue: number;
        partTotal: number;
        saleCost: number;
        saleRevenue: number;
        saleTotal: number;
        serviceCost: number;
        serviceRevenue: number;
        serviceTotal: number;
        vehicleCost: number;
        vehicleRevenue: number;
        vehicleTotal: number;
      };
      orderData: {
        OnlineOrderId: number;
        OrderId: number;
        ServiceId: number;
        VehicleId: number;
        VehicleSaleId: number;
        customerName: string;
        numberParts: number;
        orderType: string;
        totalCost: number;
        totalSale: number;
      }[];
      wrongOrderData: {
        OrderId: number;
        costAverage: number;
        costDaily: number;
        message: string;
        orderType: string;
        submessage: string;
      }[];
    }[]
  >([]);

  const GenerateReport = () => {
    CalculateStaffSalesReporting({
      queryDate: props.queryDate,
      selectedSite: props.selectedSite,
      setLoading: props.setLoading,

      setStaffVehicleSales: setStaffVehicleSales,
      setStaffOrdersSales: setStaffOrdersSales
    });
  };

  const getOrderTotals = (
    orderValues: {
      onlineCost: number;
      onlineRevenue: number;
      onlineTotal: number;
      partCost: number;
      partRevenue: number;
      partTotal: number;
      saleCost: number;
      saleRevenue: number;
      saleTotal: number;
      serviceCost: number;
      serviceRevenue: number;
      serviceTotal: number;
      vehicleCost: number;
      vehicleRevenue: number;
      vehicleTotal: number;
    },
    returnType: string
  ) => {
    let totalSales =
      orderValues.partTotal +
      orderValues.saleTotal +
      orderValues.serviceTotal +
      orderValues.onlineTotal +
      orderValues.vehicleTotal;
    let totalCost =
      orderValues.partCost +
      orderValues.saleCost +
      orderValues.serviceCost +
      orderValues.onlineCost +
      orderValues.vehicleCost;
    let totalRevenue =
      orderValues.partRevenue +
      orderValues.saleRevenue +
      orderValues.serviceRevenue +
      orderValues.onlineRevenue +
      orderValues.vehicleRevenue;
    let totalMargin = totalRevenue - totalCost;

    if (returnType === 'totalSales') {
      return totalSales;
    } else if (returnType === 'totalCost') {
      return totalCost;
    } else if (returnType === 'totalRevenue') {
      return totalRevenue;
    } else if (returnType === 'totalMargin') {
      return totalMargin;
    }
  };

  const getVehicleTotals = (
    orderValues: {
      newCost: number;
      newRevenue: number;
      newTotal: number;
      usedCost: number;
      usedRevenue: number;
      usedTotal: number;
    },
    returnType: string
  ) => {
    let totalSales = orderValues.newTotal + orderValues.usedTotal;
    let totalCost = orderValues.newCost + orderValues.usedCost;
    let totalRevenue = orderValues.newRevenue + orderValues.usedRevenue;
    let totalMargin = totalRevenue - totalCost;

    if (returnType === 'totalSales') {
      return totalSales;
    } else if (returnType === 'totalCost') {
      return totalCost;
    } else if (returnType === 'totalRevenue') {
      return totalRevenue;
    } else if (returnType === 'totalMargin') {
      return totalMargin;
    }
  };

  return (
    <>
      <ParametersSelection
        selectedSite={props.selectedSite}
        setSelectedSite={props.setSelectedSite}
        queryDate={props.queryDate}
        setQueryDate={props.setQueryDate}
        GenerateReport={GenerateReport}
        includeAllOption={true}
      />
      <br />

      {props.loading ? <CircularProgress /> : null}
      <Paper className="paper-padding">
        <Typography variant="h6" align="left">
          <b>Parts and Accessories</b>
        </Typography>
        <Table size="small">
          <TableRow>
            <TableCell width="30%"></TableCell>
            <TableCell width="10%">
              <Typography variant="h6" align="center">
                Total Sales
              </Typography>
            </TableCell>
            <TableCell width="20%">
              <Typography variant="h6" align="center">
                Cost
              </Typography>
            </TableCell>
            <TableCell width="20%">
              <Typography variant="h6" align="center">
                Revenue
              </Typography>
            </TableCell>
            <TableCell width="20%">
              <Typography variant="h6" align="center">
                Gross Profits
              </Typography>
            </TableCell>
            <TableCell width="5%"></TableCell>
          </TableRow>

          {staffOrdersSales.map((user) => (
            <TableRow>
              <TableCell>
                {user.user.firstName} {user.user.lastName ?? ''}
              </TableCell>
              <TableCell align="center">
                {getOrderTotals(user.orderValues, 'totalSales')}
              </TableCell>
              <TableCell align="center">
                {CurrencyFormatter(
                  getOrderTotals(user.orderValues, 'totalCost')
                )}
              </TableCell>
              <TableCell align="center">
                {CurrencyFormatter(
                  getOrderTotals(user.orderValues, 'totalRevenue')
                )}
              </TableCell>
              <TableCell align="center">
                {CurrencyFormatter(
                  getOrderTotals(user.orderValues, 'totalMargin')
                )}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Paper>
      <br />
      <Paper className="paper-padding">
        <Typography variant="h6" align="left">
          <b>Vehicle Sales</b>
        </Typography>
        <Table size="small">
          <TableRow>
            <TableCell width="30%"></TableCell>
            <TableCell width="10%">
              <Typography variant="h6" align="center">
                Total Sales
              </Typography>
            </TableCell>
            <TableCell width="20%">
              <Typography variant="h6" align="center">
                Cost
              </Typography>
            </TableCell>
            <TableCell width="20%">
              <Typography variant="h6" align="center">
                Revenue
              </Typography>
            </TableCell>
            <TableCell width="20%">
              <Typography variant="h6" align="center">
                Gross Profits
              </Typography>
            </TableCell>
            <TableCell width="5%"></TableCell>
          </TableRow>

          {staffVehicleSales.map((user) => (
            <TableRow>
              <TableCell>
                {user.user.firstName} {user.user.lastName ?? ''}
              </TableCell>
              <TableCell align="center">
                {getVehicleTotals(user.vehicleValues, 'totalSales')}
              </TableCell>
              <TableCell align="center">
                {CurrencyFormatter(
                  getVehicleTotals(user.vehicleValues, 'totalCost')
                )}
              </TableCell>
              <TableCell align="center">
                {CurrencyFormatter(
                  getVehicleTotals(user.vehicleValues, 'totalRevenue')
                )}
              </TableCell>
              <TableCell align="center">
                {CurrencyFormatter(
                  getVehicleTotals(user.vehicleValues, 'totalMargin')
                )}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Paper>
    </>
  );
};

export default StaffSalesReporting;
