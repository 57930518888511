// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Stocktake } from '../../../global/interfaces/PartsInterface';

const GetStocktakes = (
  partId: number,
  selectedSite: number,
  setStocktakes: Dispatch<SetStateAction<Stocktake[]>>
) => {
  api
    .get(`getStocktakesNextGen?UnitId=${partId}&SiteId=${selectedSite}`)
    .then((res) => {
      setStocktakes(res.data);
    });
};

export default GetStocktakes;
