import dayjs from 'dayjs';

const renderCustomerPlatePdf = async (pdfDocCopy, formData) => {
    const pages = pdfDocCopy.getPages();
    const secondPage = pages[1];

    if (formData.firstOwnerSigDataURL) {
        const firstOwnerSigImage = await pdfDocCopy.embedPng(
            formData.firstOwnerSigDataURL
        );
        secondPage.drawImage(firstOwnerSigImage, {
            x: 280,
            y: 665,
            width: 100,
            height: 25
        });

        secondPage.drawText(dayjs().format('DD/MM/YYYY'), {
            x: 480,
            y: 678,
            size: 9
        });
    }

    if (formData.secondOwnerSigDataURL) {
        const secondOwnerSigImage = await pdfDocCopy.embedPng(
            formData.secondOwnerSigDataURL
        );
        secondPage.drawImage(secondOwnerSigImage, {
            x: 280,
            y: 625,
            width: 100,
            height: 25
        });

        secondPage.drawText(dayjs().format('DD/MM/YYYY'), {
            x: 480,
            y: 640,
            size: 9
        });
    }

    if (formData.vendorSigDataURL) {
        const vendorSigImage = await pdfDocCopy.embedPng(
            formData.vendorSigDataURL
        );
        secondPage.drawImage(vendorSigImage, {
            x: 53,
            y: 240,
            width: 100,
            height: 25
        });

        secondPage.drawText(dayjs().format('DD/MM/YYYY'), {
            x: 260,
            y: 255,
            size: 9
        });
    }

    const pdfBytes = await pdfDocCopy.save();
    return pdfBytes;
};

export default renderCustomerPlatePdf;
