import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

// INTERFACE
import { User } from '../../../global/interfaces/GeneralInterface';

interface TechSelectionDrawerProps {
    techList: User[];
    selectedTech: { resourceId: number; resourceTitle: string }[];
    setSelectedTech: Dispatch<
        SetStateAction<{ resourceId: number; resourceTitle: string }[]>
    >;
}

const TechDrawerTest = ({
    techList,
    selectedTech,
    setSelectedTech
}: TechSelectionDrawerProps) => {
    const handleCheckboxChange = (e, tech) => {
        let newTechSelection = JSON.parse(JSON.stringify(selectedTech));
        if (e.target.checked) {
            // It got checked so we add it to the list of selected tech
            newTechSelection.push({
                resourceId: tech.id,
                resourceTitle:
                    tech.firstName + ' ' + tech.lastName.charAt(0) + '.'
            });
            setSelectedTech(newTechSelection);
        } else {
            // It got unchecked so we remove it from the list of selected tech
            let index = newTechSelection.findIndex(
                (x) => x.resourceId === tech.id
            );
            newTechSelection.splice(index, 1);
            setSelectedTech(newTechSelection);
        }
    };

    return (
        <>
            <FormGroup>
                {techList.map((tech) => {
                    return tech.employed ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedTech.some(
                                        (x) => x.resourceId === tech.id
                                    )}
                                    disabled={
                                        selectedTech.length >= 7
                                            ? selectedTech.some(
                                                  (x) =>
                                                      x.resourceId === tech.id
                                              )
                                                ? false
                                                : true
                                            : false
                                    }
                                    onChange={(e) => {
                                        handleCheckboxChange(e, tech);
                                    }}
                                />
                            }
                            label={`${tech.firstName} ${tech.lastName}`}
                        />
                    ) : null;
                })}
            </FormGroup>
        </>
    );
};

export default TechDrawerTest;
