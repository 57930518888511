import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../../api';

const GetTransactionFiles = (
  resetPage: boolean,
  searchString: string,
  currentPage: number,
  limit: number,
  setTotalPages: Dispatch<SetStateAction<number>>,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  setDisabled: Dispatch<SetStateAction<boolean>>,
  setTransactionFiles
) => {
  api
    .get(
      `transactionFiles?searchString=${searchString}&currentPage=${currentPage}&limit=${limit}`
    )
    .then((res) => {
      if (res.data.count != null && res.data.count > 0)
        setTotalPages(Math.ceil(res.data.count / limit));
      setTransactionFiles(res.data.rows);
      setDisabled(false);
      if (resetPage) {
        setCurrentPage(0);
      }
    });
};

export default GetTransactionFiles;
