// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

const SubmitTimeClock = (
    currentTime,
    refresh: boolean,
    setRefresh: Dispatch<SetStateAction<boolean>>,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    api.post(
        `submitTimeClock?SiteId=${localStorage.getItem('SiteId')}&currentTime=${currentTime}`
    ).then((res) => {
        if (res.status === 200) {
            setRefresh(!refresh);
            setButtonLoading(false);
        } else {
            setButtonLoading(false);
            showSnackbar(
                'You are not authorized to clock on/off from this location.',
                `Please ensure that you are connected to the company WiFi/Internet.\nIf you think you shouldn't have received this error message, please contact your IT department.\nYour current IP address is: ${res.data}.`,
                'error'
            );
        }
    });
};

export default SubmitTimeClock;
