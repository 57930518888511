import { rgb } from 'pdf-lib';
import {
    DateSafeFormatter,
    MonthYearFormatter
} from '../../../global/logic/Formatters';

const renderDealerVendorPdf = async (pdfDocCopy, formData) => {
    const pages = pdfDocCopy.getPages();
    const firstPage = pages[0];

    // customer acn
    if (formData.acn !== '') {
        firstPage.drawText(formData.acn, {
            x: 55,
            y: 572,
            size: 9
        });
    }

    //vehicle details
    if (formData.builtDate) {
        firstPage.drawText(
            MonthYearFormatter(formData.builtDate.toISOString()),
            {
                x: 350,
                y: 630,
                size: 9
            }
        );
    }

    if (formData.personalPlateOption === 'YES') {
        firstPage.drawText('X', {
            x: 369,
            y: 592,
            size: 9
        });
    }

    if (formData.personalPlateOption === 'NO') {
        firstPage.drawText('X', {
            x: 441,
            y: 592,
            size: 9
        });
    }

    if (formData.newRego !== '') {
        firstPage.drawText(formData.newRego, {
            x: 350,
            y: 572,
            size: 9
        });
    }

    // accessories
    if (formData.accessories !== '') {
        firstPage.drawText(formData.accessories, {
            x: 75,
            y: 488,
            size: 8
        });
    }

    if (formData.engineReplaceOption === 'NO') {
        firstPage.drawLine({
            start: { x: 90, y: 476 },
            end: { x: 105, y: 476 },
            thickness: 1
        });
    }

    if (formData.engineReplaceOption === 'YES') {
        firstPage.drawLine({
            start: { x: 110, y: 476 },
            end: { x: 140, y: 476 },
            thickness: 1
        });

        if (formData.engineReplaceDate) {
            firstPage.drawText(
                DateSafeFormatter(formData.engineReplaceDate.toISOString()),
                {
                    x: 450,
                    y: 475,
                    size: 8
                }
            );
        }
    }

    if (formData.odometerReplaceOption === 'NO') {
        firstPage.drawLine({
            start: { x: 100, y: 462 },
            end: { x: 115, y: 462 },
            thickness: 1
        });
    }

    if (formData.odometerReplaceOption === 'YES') {
        firstPage.drawLine({
            start: { x: 120, y: 462 },
            end: { x: 150, y: 462 },
            thickness: 1
        });
    }

    if (formData.concessionalRegistrationOption === 'NO') {
        firstPage.drawLine({
            start: { x: 150, y: 448 },
            end: { x: 165, y: 448 },
            thickness: 1
        });
    }

    if (formData.concessionalRegistrationOption === 'YES') {
        firstPage.drawLine({
            start: { x: 170, y: 448 },
            end: { x: 200, y: 448 },
            thickness: 1
        });
    }

    if (formData.airBagsActivatedOption === 'NO') {
        firstPage.drawLine({
            start: { x: 225, y: 434 },
            end: { x: 240, y: 434 },
            thickness: 1
        });
    }

    if (formData.airBagsActivatedOption === 'YES') {
        firstPage.drawLine({
            start: { x: 245, y: 434 },
            end: { x: 260, y: 434 },
            thickness: 1
        });
    }

    if (formData.modificationsApprovalOption === 'NO') {
        firstPage.drawLine({
            start: { x: 285, y: 420 },
            end: { x: 300, y: 420 },
            thickness: 1
        });
    }

    if (formData.modificationsApprovalOption === 'YES') {
        firstPage.drawLine({
            start: { x: 305, y: 420 },
            end: { x: 335, y: 420 },
            thickness: 1
        });
    }

    if (formData.currentWrittenOffRegisterOption === 'NO') {
        firstPage.drawLine({
            start: { x: 85, y: 397 },
            end: { x: 95, y: 397 },
            thickness: 1
        });
    }

    if (formData.currentWrittenOffRegisterOption === 'YES') {
        firstPage.drawLine({
            start: { x: 100, y: 397 },
            end: { x: 125, y: 397 },
            thickness: 1
        });
    }

    if (formData.previousWrittenOffRegisterOption === 'NO') {
        firstPage.drawLine({
            start: { x: 90, y: 383 },
            end: { x: 105, y: 383 },
            thickness: 1
        });
    }

    if (formData.previousWrittenOffRegisterOption === 'YES') {
        firstPage.drawLine({
            start: { x: 110, y: 383 },
            end: { x: 140, y: 383 },
            thickness: 1
        });
    }

    // section 2
    if (formData.withSecurityInterest === 'NO') {
        firstPage.drawCircle({
            x: 44,
            y: 368,
            size: 6,
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
            opacity: 0
        });
    }

    if (formData.withSecurityInterest === 'YES') {
        firstPage.drawCircle({
            x: 44,
            y: 348,
            size: 6,
            borderWidth: 1,
            borderColor: rgb(0, 0, 0),
            opacity: 0
        });

        if (formData.lenderName !== '') {
            firstPage.drawText(formData.lenderName, {
                x: 60,
                y: 333,
                size: 8
            });
        }

        if (formData.loanAmount !== '') {
            firstPage.drawText(formData.loanAmount, {
                x: 250,
                y: 333,
                size: 8
            });
        }
    }

    if (formData.payoutByDealer === 'NO') {
        firstPage.drawLine({
            start: { x: 123, y: 320 },
            end: { x: 133, y: 320 },
            thickness: 1
        });
    }

    if (formData.payoutByDealer === 'YES') {
        firstPage.drawLine({
            start: { x: 135, y: 320 },
            end: { x: 160, y: 320 },
            thickness: 1
        });
    }

    // section 4
    if (formData.taxiOrHireCarUsage === 'NO') {
        firstPage.drawLine({
            start: { x: 79, y: 292 },
            end: { x: 89, y: 292 },
            thickness: 1
        });
    }

    if (formData.taxiOrHireCarUsage === 'YES') {
        firstPage.drawLine({
            start: { x: 99, y: 292 },
            end: { x: 129, y: 292 },
            thickness: 1
        });
    }

    // section 6
    if (formData.auctionOption === 'NO') {
        firstPage.drawLine({
            start: { x: 73, y: 263 },
            end: { x: 83, y: 263 },
            thickness: 1
        });
    }

    if (formData.auctionOption === 'YES') {
        firstPage.drawLine({
            start: { x: 88, y: 263 },
            end: { x: 113, y: 263 },
            thickness: 1
        });

        if (formData.reservePrice !== '') {
            firstPage.drawText(formData.reservePrice, {
                x: 340,
                y: 262,
                size: 8
            });
        }
    }

    // section 8
    if (formData.gstRegistrationOption === 'NO') {
        firstPage.drawLine({
            start: { x: 205, y: 212 },
            end: { x: 225, y: 212 },
            thickness: 1
        });
    }

    if (formData.gstRegistrationOption === 'YES') {
        firstPage.drawLine({
            start: { x: 227, y: 212 },
            end: { x: 237, y: 212 },
            thickness: 1
        });
    }

    if (formData.taxableSupplyOption === 'NO') {
        firstPage.drawLine({
            start: { x: 450, y: 198 },
            end: { x: 470, y: 198 },
            thickness: 1
        });
    }

    if (formData.taxableSupplyOption === 'YES') {
        firstPage.drawLine({
            start: { x: 475, y: 198 },
            end: { x: 485, y: 198 },
            thickness: 1
        });
    }

    if (formData.gstFreeSupplyOption === 'NO') {
        firstPage.drawLine({
            start: { x: 280, y: 184 },
            end: { x: 300, y: 184 },
            thickness: 1
        });
    }

    if (formData.gstFreeSupplyOption === 'YES') {
        firstPage.drawLine({
            start: { x: 303, y: 184 },
            end: { x: 313, y: 184 },
            thickness: 1
        });
    }

    const pdfBytes = await pdfDocCopy.save();
    return pdfBytes;
};

export default renderDealerVendorPdf;
