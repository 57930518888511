import { BaseSyntheticEvent, Dispatch, SetStateAction } from "react";
import { Table } from "../interfaces/StockOrderInterface"

const HandleOrderReferenceChange = (tables: Table[], setTables: Dispatch<SetStateAction<Table[]>>, id: number, e: BaseSyntheticEvent, setFocusPosition: Dispatch<SetStateAction<number>>) => {
    let currentTables = tables;
    var index = currentTables.findIndex(x => x.supplierId === id);
    let rows = [...currentTables];
    let row = { ...rows[index] };
    row.orderReference = e.target.value;
    rows[index] = row;
    setTables(rows);
    setFocusPosition(99999999)
}

export default HandleOrderReferenceChange;