import GenerateHondaApiOrder from './API/GenerateHondaApiOrder';
import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Api } from '../../../global/interfaces/GeneralInterface';
import { Table } from '../interfaces/StockOrderInterface';

const OrderWithAPIRouter = (
    obj: { generator: Api; table: Table },
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    showSnackbar: showSnackbar
) => {
    if (obj.generator.OrderFileGeneratorId === 1) {
        GenerateHondaApiOrder(obj, tables, setTables, showSnackbar);
    }
};

export default OrderWithAPIRouter;
