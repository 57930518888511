// IMPORTS
import { useState, useEffect } from 'react';
import { Typography, TableRow, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
// COMPONENTS
import StockOrderTableFilter from './components/StockOrderTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import Drawer from '../../global/Drawer';
import OrderPreviewDrawerContent from './components/OrderPreviewDrawerContent';
import StockOrderMenu from './components/StockOrderMenu';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import GetSite from '../../global/databaseLogic/GetSite';
import HandleResultsList from './logic/HandleResultsList';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { StockOrder } from '../../global/interfaces/PartsInterface';
import { Site } from '../../global/interfaces/GeneralInterface';
import { DateFormatter } from '../../global/logic/Formatters';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    stockOrderFilter: {
        type: [],
        site: [],
        user: [],
        startDate: null,
        endDate: null,
        invoiced: false
    }
};
const clearedSort = ['id', 'DESC'];

const StockOrderTable = (props) => {
    const history = useHistory();
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<StockOrder[]>([]);
    const [resultsListUpdated, setResultsListUpdated] = useState<StockOrder[]>(
        []
    );
    const [siteDetails, setSiteDetails] = useState<Site>();

    // Drawer
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [orderPreview, setOrderPreview] = useState<StockOrder>();

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Create Stock Order',
            onClick: () => history.push('/inventory/stockorder')
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'Order ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'Order Date', width: 100 },
        { id: 2, label: 'Order Reference', width: 100 },
        { id: 3, label: 'Supplier', width: 100 },
        { id: 4, label: 'User', width: 100 },
        { id: 5, label: 'Site', width: 100 },
        { id: 6, label: '', width: 50 }
    ];

    // Get the details of the current site
    useEffect(() => {
        GetSite(parseInt(localStorage.getItem('SiteId')), setSiteDetails);
    }, []);

    // Once the current site details have been obtained set the filter to some default values
    useEffect(() => {
        let newFilter = {
            type: [],
            site: [
                {
                    id: parseInt(localStorage.getItem('SiteId')),
                    name: siteDetails?.name
                }
            ],
            user: [],
            startDate: null,
            endDate: null,
            invoiced: false
        };
        setFilter({ ...filter, stockOrderFilter: newFilter });
        // eslint-disable-next-line
    }, [siteDetails]);

    // Once we received resultsList, go through it to add API data if necessary
    useEffect(() => {
        HandleResultsList(resultsList, setResultsListUpdated);
    }, [resultsList]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let type =
            filter.stockOrderFilter.type.length > 0
                ? ConvertArrayToFilter({
                      array: filter.stockOrderFilter.type,
                      filterStart: '&type=',
                      selector: 'value'
                  })
                : '';
        let site =
            filter.stockOrderFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.stockOrderFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let user =
            filter.stockOrderFilter.user.length > 0
                ? ConvertArrayToFilter({
                      array: filter.stockOrderFilter.user,
                      filterStart: '&user=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let startDate =
            filter.stockOrderFilter.startDate !== null &&
            dayjs(filter.stockOrderFilter.startDate).isValid()
                ? `&startDate=${dayjs(filter.stockOrderFilter.startDate).toISOString()}`
                : '';
        let endDate =
            filter.stockOrderFilter.endDate !== null &&
            dayjs(filter.stockOrderFilter.endDate).isValid()
                ? `&endDate=${dayjs(filter.stockOrderFilter.endDate).toISOString()}`
                : '';
        let invoiced = filter.stockOrderFilter.invoiced
            ? '&invoiced=true'
            : '&invoiced=false';

        let apiString = `${type}${site}${user}${startDate}${endDate}${invoiced}`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.stockOrderFilter.type.length > 0 ||
            filter.stockOrderFilter.site.length > 0 ||
            filter.stockOrderFilter.user.length > 0
        ) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Stock Order</Typography>
            <br />
            <TableSearch
                searchTitle="Search Sale"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="stockOrderSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <StockOrderTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsListUpdated.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                <DataCellColoured
                                    handleClick={() =>
                                        history.push(
                                            `/inventory/stockInvoicePO/${row.id}`
                                        )
                                    }
                                >
                                    {row.id}
                                </DataCellColoured>
                                <DataCell>
                                    {DateFormatter(row.createdAt)}
                                </DataCell>
                                <DataCell>
                                    {row.orderReference.length > 40 ? (
                                        <Tooltip
                                            title={row.orderReference}
                                            placement="top"
                                        >
                                            <Typography>
                                                {row.orderReference.slice(
                                                    0,
                                                    40
                                                ) + '...'}
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        row.orderReference
                                    )}
                                </DataCell>
                                <DataCell>{row.Supplier.name}</DataCell>
                                <DataCell>
                                    {row.User.firstName} {row.User.lastName}
                                </DataCell>
                                <DataCell>{row.Site.name}</DataCell>
                                <DataCell>
                                    <StockOrderMenu
                                        row={row}
                                        setOrderPreview={setOrderPreview}
                                        setOpenDrawer={setOpenDrawer}
                                        showSnackbar={props.showSnackbar}
                                    />
                                </DataCell>
                            </TableRow>
                        );
                    })}
                </DataTable>
            </Paper>
            <Drawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                title={'Preview'}
                subTitle={'Order #' + orderPreview?.id}
                width={'40vw'}
            >
                <OrderPreviewDrawerContent order={orderPreview} />
            </Drawer>
        </PageWrapper>
    );
};

export default withSnackbar(StockOrderTable);
