import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const HandleDeleteAccount = (id: number, showSnackbar: showSnackbar) => {
    api.delete('account/' + id.toString()).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Account succesfully deleted.',
                'The page will now refresh.'
            );
            setTimeout(() => window.location.reload(), 1000);
        } else if (res.status === 405) {
            showSnackbar(res.data.message, res.data.subMessage, 'error');
        }
    });
};
