// IMPORTS
import { useState, Dispatch, SetStateAction } from 'react';
import {
    DialogTitle,
    DialogContent,
    Table,
    TableRow,
    TableCell,
    Grid,
    TextField,
    DialogActions,
    Button
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import HandleSubmitPayment from '../logic/HandleSubmitPayment';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

// INTERFACE
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { DebtorPayment } from '../../../global/interfaces/GeneralInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface SelectedDebtor {
    CustomerId: number;
    CustomerDetails: {
        name: string;
        firstName: string;
        lastName: string;
        companyName: string;
        phoneNumber: string;
        abn: string;
        paymentTerms: string;
        accountLimit: string;
        addressLine1: string;
        suburb: string;
        state: string;
        postcode: string;
    };
    rows: any[];
}

interface PaymentDialogContentProps {
    selectedDebtor: SelectedDebtor;
    balance: number;
    selectedInvoices: DebtorPayment[];
    setPaymentDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const PaymentDialogContent = ({
    selectedDebtor,
    balance,
    selectedInvoices,
    setPaymentDialogOpen,
    showSnackbar
}: PaymentDialogContentProps) => {
    const [amountPaying, setAmountPaying] = useState(null);
    const [datePaying, setDatePaying] = useState(null);
    const [errors, setErrors] = useState({
        amount: false,
        date: false
    });
    const [buttonLoading, setButtonLoading] = useState(false);

    return (
        <>
            <DialogTitle>Make Account Payment</DialogTitle>
            <DialogContent>
                <Table>
                    <TableRow>
                        <TableCell>
                            <b>Debtor Name:</b>
                        </TableCell>
                        <TableCell>
                            {selectedDebtor?.CustomerDetails.name}
                        </TableCell>
                        <TableCell>
                            <b>Balance Payable:</b>
                        </TableCell>
                        <TableCell>{CurrencyFormatter(balance)}</TableCell>
                    </TableRow>
                </Table>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Amount Paying"
                            InputLabelProps={{ shrink: true }}
                            value={amountPaying}
                            onChange={(e) => setAmountPaying(e.target.value)}
                            error={errors.amount}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Date Paid"
                                value={datePaying}
                                onChange={(newValue) => {
                                    setDatePaying(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => setPaymentDialogOpen(false)}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        HandleSubmitPayment(
                            selectedDebtor.CustomerId,
                            amountPaying,
                            datePaying,
                            selectedInvoices,
                            setErrors,
                            setButtonLoading,
                            showSnackbar
                        )
                    }
                >
                    Submit Payment
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default withSnackbar(PaymentDialogContent);
