// IMPORTS
import { Dispatch, SetStateAction, useContext } from 'react';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SiteContext } from '../../../../../main/Main';
// COMPONENTS
import Paper from '../../../../global/Paper';
import VehicleForm from '../../../../parts/newSale/components/SelectEntityForms/VehicleForm';
import CustomerVehicleForm from './CustomerVehicleForm';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import {
    CustomerVehicle,
    Service
} from '../.././../../global/interfaces/ServiceInterface';
import { Customer, Site } from '../../../../global/interfaces/GeneralInterface';
interface VehicleFormProps {
    readOnly: boolean;
    vehicleType: string;
    setVehicleType: Dispatch<SetStateAction<string>>;
    selectedVehicle: Vehicle | CustomerVehicle;
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle | CustomerVehicle>>;
    selectedCustomer: Customer;
    serviceDetails: Service;
}
const SelectVehicle = ({
    readOnly,
    vehicleType,
    setVehicleType,
    selectedVehicle,
    setSelectedVehicle,
    selectedCustomer,
    serviceDetails
}: VehicleFormProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            fullWidth
                            value={vehicleType}
                            onChange={(e, newType) => {
                                if (newType != null) {
                                    setVehicleType(newType);
                                    setSelectedVehicle(null);
                                }
                            }}
                            disabled={readOnly}
                            size="small"
                        >
                            <ToggleButton
                                value="customer"
                                disabled={
                                    serviceDetails.jobType === 'Internal'
                                        ? true
                                        : false
                                }
                            >
                                Customer{' '}
                                {siteDetails.vehicleTypeSold === 'Motorcycles'
                                    ? 'Vehicles'
                                    : 'Bicycles'}
                            </ToggleButton>
                            <ToggleButton
                                value="stock"
                                disabled={
                                    serviceDetails.jobType === 'Retail'
                                        ? true
                                        : false
                                }
                            >
                                Stock{' '}
                                {siteDetails.vehicleTypeSold === 'Motorcycles'
                                    ? 'Vehicles'
                                    : 'Bicycles'}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Paper>
            <br />
            {vehicleType === 'customer' ? (
                <CustomerVehicleForm
                    readOnly={readOnly}
                    selectedVehicle={selectedVehicle}
                    setSelectedVehicle={setSelectedVehicle}
                    selectedCustomer={selectedCustomer}
                />
            ) : (
                <VehicleForm
                    readOnly={readOnly}
                    selectedEntity={selectedVehicle}
                    setSelectedEntity={setSelectedVehicle}
                    removeSoldVehicles={true}
                    fromService={true}
                />
            )}
        </>
    );
};

export default SelectVehicle;
