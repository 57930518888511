// IMPORTS
import { Typography } from '@mui/material';
import { Launch } from '@mui/icons-material';
// COMPONENTS
import Fab from '../../../../../global/Fab';
// LOGIC
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';

interface ManualTransactionContentProps {
  selectedObject;
}

const ManualTransactionContent = ({
  selectedObject
}: ManualTransactionContentProps) => {
  return (
    <>
      <Typography variant="body1">{selectedObject.reference}</Typography>
      <br />
      <Typography variant="body1">
        <b>Amount:</b> {CurrencyFormatter(selectedObject.amount)}
      </Typography>
      <Typography variant="body1">
        <b>Transaction Date:</b> {selectedObject.date}
      </Typography>
      <br />
      <Typography variant="body1">
        <b>Date Entered:</b> {selectedObject.dateEntered}
      </Typography>
      <Typography variant="body1">
        <b>Entered by:</b> {selectedObject.submittedBy}
      </Typography>

      <Fab
        noSession={true}
        customIcon={<Launch />}
        onClick={() =>
          window.open(
            `/admin/viewManualTransaction/${selectedObject.ManualTransactionId}`,
            '_blank'
          )
        }
      />
    </>
  );
};

export default ManualTransactionContent;
