import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import api from '../../../../../../api';
import CalculateTotalsV2 from './CalculateTotalsV2';

const SubmitBankReconciliation = (
    reconciliationDetails,
    setRefreshLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    // Check that there are transactions on both sides to submit
    if (
        reconciliationDetails.oraTransactionsReconciled.length === 0 &&
        reconciliationDetails.bankTransactionsReconciled === 0
    ) {
        showSnackbar(
            'You must have at least one transaction reconciled to continue.',
            '',
            'error'
        );
        return;
    }

    // Check that the two totals balance
    if (
        CalculateTotalsV2(reconciliationDetails.bankTransactionsReconciled) !==
        CalculateTotalsV2(reconciliationDetails.oraTransactionsReconciled)
    ) {
        showSnackbar('Both sides must balance to continue.', '', 'error');
        return;
    }

    let lighterReconcileOra = [];

    for (let week of reconciliationDetails.oraTransactionsReconciled) {
        for (let transaction of week.lines) {
            if (transaction.TrustReceiptId) {
                delete transaction.vehicleLines;
                lighterReconcileOra.push(transaction);
            } else if (transaction.PaymentRunId) {
                delete transaction.invoices;
                lighterReconcileOra.push(transaction);
            } else {
                lighterReconcileOra.push(transaction);
            }
        }
    }

    let lighterBankReconciliationLines = [];
    for (let week of reconciliationDetails.bankTransactionsReconciled) {
        for (let transaction of week.lines) {
            lighterBankReconciliationLines.push(transaction);
        }
    }

    let requestObject = {
        oraTransactionsReconciled: lighterReconcileOra,
        bankTransactionsReconciled: lighterBankReconciliationLines
    };

    setRefreshLoading(true);
    api.post('newBankReconciliation', requestObject).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Bank Reconciliation submitted successfully.',
                'The page will now refresh.',
                'success'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setRefreshLoading(false);
        }
    });
};

export default SubmitBankReconciliation;
