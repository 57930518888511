import DocumentationPage from '../DocumentationPage';
import {
    AddNewLeadSteps,
    EditLeadSteps
} from '../data/vehicleSales/leadsManagement';

const LeadsManagementSupportPage = () => {
    const contentSections = [
        {
            id: 'add-new-lead',
            title: 'Add a new Lead',
            href: '/support/vehicles/leadsmanagement/#add-new-lead',
            description: 'To create a new Lead, follow these steps:',
            steps: AddNewLeadSteps
        },
        {
            id: 'edit-lead',
            title: 'Edit a Lead',
            href: '/support/vehicles/leadsmanagement/#edit-lead',
            description:
                'To edit a Lead details, and assign employees to Leads by following these steps:',
            steps: EditLeadSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Leads Management"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default LeadsManagementSupportPage;
