import { Dispatch, SetStateAction } from 'react';
import { ExtraCost } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface UpdateExtraCostsOnStatusChangeProps {
    status: string;
    showSnackbar: showSnackbar;
    extraCosts: ExtraCost[];
    setExtraCosts: Dispatch<SetStateAction<ExtraCost[]>>;
}

const UpdateExtraCostsOnStatusChange = ({
    status,
    extraCosts,
    setExtraCosts,
    showSnackbar
}: UpdateExtraCostsOnStatusChangeProps) => {
    const updatedExtraCosts = extraCosts.filter(
        (item) => item.type !== 'voucher'
    );

    setExtraCosts(updatedExtraCosts);
    showSnackbar(
        'Change status from Sale to ' + status + ' will clear all vouchers',
        '',
        'warning'
    );
};

export default UpdateExtraCostsOnStatusChange;
