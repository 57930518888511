import { Grid, Button, Typography } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';

interface DrawPadProps {
  sigCanvas: SignatureCanvas;
  pointsArray: any;
  setPointsArray: any;
}

const DrawPad = (props: DrawPadProps) => {
  const retrievePreviousDraw = () => {
    if (props.pointsArray) {
      props.sigCanvas.current.fromData(props.pointsArray);
    }
  };

  const clearDrawPad = () => {
    props.sigCanvas.current.clear();
  };

  return (
    <>
      <Grid container textAlign="center">
        <Grid xs={12}>
          <Typography variant="caption" sx={{ mb: 2 }}>
            This draw pad will not be saved. Leaving the enquiry, or submitting
            it will erase all information from this draw pad.
            <br />
            Make sure you don't need any information from this draw pad before
            submitting the enquiry or navigating to another page.
          </Typography>
          <br />
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: 700,
              height: 500,
              className: 'sigCanvas',
              style: { border: '1px solid black' }
            }}
            ref={props.sigCanvas}
            onEnd={() => props.setPointsArray(props.sigCanvas.current.toData())}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3} textAlign="left">
          <Button onClick={() => retrievePreviousDraw()}>
            Redraw previous drawing
          </Button>
        </Grid>
        <Grid item xs={3} textAlign="right">
          <Button onClick={() => clearDrawPad()}>Clear pad</Button>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </>
  );
};

export default DrawPad;
