// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Grid,
    Typography,
    Divider,
    TextField,
    Tooltip,
    MenuItem
} from '@mui/material';

import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../../global/Paper';
import Autocomplete from '../../../../global/Autocomplete';
// INTERFACES
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { Specific } from '../../../../global/interfaces/GeneralInterface';
import { Site } from '../../../../global/interfaces/GeneralInterface';
import RoleCheck from '../../../../global/RoleCheck';

interface SpecificsList {
    makes?: Specific[];
    models?: Specific[];
    series?: Specific[];
    aspirations?: Specific[];
    fuelTypes?: Specific[];
    bodyTypes?: Specific[];
    driveTypes?: Specific[];
    sites?: Site[];
}

interface VehicleDetailsProps {
    vehicle: Vehicle;
    setVehicle: Dispatch<SetStateAction<Vehicle>>;
    specifics: SpecificsList;
    readOnly: boolean;
}

const BicyclesVehicleDetails = ({
    vehicle,
    setVehicle,
    specifics,
    readOnly
}: VehicleDetailsProps) => {
    return (
        <Paper>
            <Typography variant="h6">Vehicle Details</Typography>
            <Divider />
            <br />
            <Grid container spacing={2}>
                <Tooltip
                    title={
                        !readOnly
                            ? 'Location can not be edited here, to change site create a vehicle transfer.'
                            : ''
                    }
                    placement="top"
                >
                    <Grid item xs={3}>
                        <Autocomplete
                            size="small"
                            isDisabled
                            options={specifics?.sites}
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            textfieldLabel="Location"
                            selectedValue={vehicle?.Site}
                            handleSelectedValueChange={() => null}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                </Tooltip>
                <Grid item xs={3}>
                    <Tooltip
                        title={
                            !readOnly
                                ? 'Condition can not be edited. If there has been a mistake contact your admin department.'
                                : ''
                        }
                        placement="top"
                    >
                        <RoleCheck
                            permission="edit_vehicleCondition"
                            component={
                                <TextField
                                    disabled={readOnly}
                                    fullWidth
                                    select
                                    size="small"
                                    label="Condition"
                                    value={vehicle?.condition}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        setVehicle({
                                            ...vehicle,
                                            condition: e.target.value
                                        });
                                    }}
                                >
                                    <MenuItem key={1} value="New">
                                        New
                                    </MenuItem>
                                    <MenuItem key={2} value="Used">
                                        Used
                                    </MenuItem>
                                    <MenuItem key={3} value="Demo">
                                        Demo
                                    </MenuItem>
                                </TextField>
                            }
                            nullComponent={
                                <TextField
                                    disabled
                                    fullWidth
                                    size="small"
                                    label="Condition"
                                    value={vehicle?.condition}
                                    InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    </Tooltip>
                </Grid>
                {/* <Grid item xs={2}>
                    <Tooltip title={!readOnly ? "This value is calculated and cannot be edited." : ""} placement="top">
                        <TextField disabled fullWidth size="small" label="Age" value={vehicle?.age} InputLabelProps={{ shrink: true }} />
                    </Tooltip>
                </Grid>
                <Grid item xs={2}>
                    <Stack direction="row" alignItems="center">
                        <Typography color={readOnly ? "rgba(0, 0, 0, 0.38)" : ""}>Current</Typography>
                        <Switch disabled={readOnly} checked={vehicle?.aged} onChange={(e) => setVehicle({ ...vehicle, aged: e.target.checked })} />
                        <Typography color={readOnly ? "rgba(0, 0, 0, 0.38)" : ""}>Aged</Typography>
                    </Stack>
                </Grid> */}
            </Grid>
        </Paper>
    );
};
export default BicyclesVehicleDetails;
