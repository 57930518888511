import { Button, Grid, Typography } from '@mui/material';
import Paper from '../../../global/Paper';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { Dispatch, SetStateAction } from 'react';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}

interface GroupedOnlineOrders {
    pick: GroupedOnlineOrder[];
    pack: GroupedOnlineOrder[];
}

interface CountersProps {
    pickPackOrders: GroupedOnlineOrders;
    setSelectedGroupOrders: Dispatch<SetStateAction<GroupedOnlineOrder[]>>;
    selectedGroupTitle: string;
    setSelectedGroupTitle: Dispatch<SetStateAction<String>>;
}

const Counters = ({
    pickPackOrders,
    setSelectedGroupOrders,
    selectedGroupTitle,
    setSelectedGroupTitle
}: CountersProps) => {
    const handleButtonClick = (title: string, array: GroupedOnlineOrder[]) => {
        setSelectedGroupOrders(array);
        setSelectedGroupTitle(title);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Pick</Typography>
                        <Typography variant="h5">
                            {pickPackOrders.pick.length}
                        </Typography>
                        <Button
                            disabled={
                                selectedGroupTitle === 'Pick' ? true : false
                            }
                            onClick={() =>
                                handleButtonClick('Pick', pickPackOrders.pick)
                            }
                        >
                            View Orders
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={6} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Pack</Typography>
                        <Typography variant="h5">
                            {pickPackOrders.pack.length}
                        </Typography>
                        <Button
                            disabled={
                                selectedGroupTitle === 'Pack' ? true : false
                            }
                            onClick={() =>
                                handleButtonClick('Pack', pickPackOrders.pack)
                            }
                        >
                            View Orders
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default Counters;
