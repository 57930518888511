import api from '../../../../../../api';
import { PaymentLine } from '../../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const QuickSaleUpdate = (
    id: number,
    paymentLines: PaymentLine[],
    showSnackbar: showSnackbar,
    type: string
) => {
    api.put(`quickSaleUpdate/${id}?type=${type}`, paymentLines).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Payment Lines Updated.',
                'The page will now refresh.'
            );
            setTimeout(() => window.location.reload(), 1500);
        } else if (res.status === 400) {
            showSnackbar(
                'Payment Lines were not updated, please contact your manager or IT department.',
                res.data,
                'error'
            );
        }
    });
};

export default QuickSaleUpdate;
