import { Step } from '../../interface';

export const TimeClockSteps: Step[] = [
    {
        label: 'If you have not clocked in for the day, the time clock dialog will appear after you log in, prompting you to clock in before starting work.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/timeclock/timeclock-01.png'
    },
    {
        label: 'To clock out, click the Clock icon in the header, then select "Clock Off." Note that you can clock in and out multiple times throughout the day.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/timeclock/timeclock-02.png'
    },
    {
        label: 'To view time clock records. In the sidebar, navigate to Admin -> Time Clock to access the Time Clock page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/timeclock/timeclock-03.png'
    },
    {
        label: 'This page displays time clock data including time on, time off, and duration for all employees across all sites. Search for a specific employee by entering the employee name in the search bar and pressing Enter.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/timeclock/timeclock-04.png'
    },
    {
        label: 'Filter the time clock data by date range, site, or user to refine the results.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/timeclock/timeclock-05.png'
    },
    {
        label: 'Click "Export Current Results" in the speed dial menu to download the time clock data in CSV format.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/timeclock/timeclock-06.png'
    }
];
