// This takes the message body and generates the message body preview by

import { Site } from '../../../global/interfaces/GeneralInterface';

// replacing the codes with sample data
const HandlePreviewReplacement = (message: string, siteData: Site) => {
    let currentMessage = message ? message : '';

    currentMessage = currentMessage.replaceAll('[customerName]', 'Frodo');
    currentMessage = currentMessage.replaceAll(
        '[businessStore]',
        siteData.name
    );
    currentMessage = currentMessage.replaceAll(
        '[businessPhoneNumber]',
        siteData.phoneNumber
    );
    currentMessage = currentMessage.replaceAll(
        '[salesBusinessHours]',
        `${siteData.salesBusinessHours}`
    );
    currentMessage = currentMessage.replaceAll(
        '[serviceBusinessHours]',
        `${siteData.serviceBusinessHours}`
    );
    currentMessage = currentMessage.replaceAll('[proformaNumber]', '1234');
    currentMessage = currentMessage.replaceAll('[serviceNumber]', '1234');
    currentMessage = currentMessage.replaceAll('[vehicleSaleNumber]', '1234');
    currentMessage = currentMessage.replaceAll('[onlineOrderNumber]', '1234');
    currentMessage = currentMessage.replaceAll(
        '[bikeMakeModel]',
        'Honda CBR250'
    );

    return currentMessage;
};

export default HandlePreviewReplacement;
