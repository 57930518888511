// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { TableRow, Chip, IconButton } from '@mui/material';
import { RadioButtonUnchecked, CheckCircle } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
import TableSearch from '../../../../global/tableComponents/TableSearch';
// INTERFACES
import { VehicleSale } from '../../../../global/interfaces/VehicleSaleInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedSort = ['id', 'DESC'];

interface VehicleContractFormProps {
    readOnly: boolean;
    selectedEntity: VehicleSale;
    setSelectedEntity: Dispatch<SetStateAction<VehicleSale>>;
}

const VehicleContractForm = ({
    readOnly,
    selectedEntity,
    setSelectedEntity
}: VehicleContractFormProps) => {
    const [resultsList, setResultsList] = useState<VehicleSale[]>([]);
    const [resultsListAltered, setResultsListAltered] = useState<VehicleSale[]>(
        []
    );

    // Columns for the data table
    const columns = [
        { id: 0, label: '', width: 100 },
        { id: 1, label: 'ID', width: 100 },
        { id: 2, label: 'Customer', width: 300 },
        { id: 3, label: 'Stock Number', width: 100 },
        { id: 4, label: 'Email', width: 200 },
        { id: 5, label: 'Phone Number', width: 200 },
        { id: 6, label: 'Customer Type', width: 100 },
        { id: 7, label: 'Sale Status', width: 100 }
    ];

    useEffect(() => {
        if (selectedEntity?.id) {
            let singleRow = [];
            singleRow.push(selectedEntity);
            setResultsListAltered(singleRow);
        } else {
            setResultsListAltered(resultsList);
        }
    }, [selectedEntity, resultsList]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        return '&status=Sale,Quote';
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Contracts"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                sort={clearedSort}
                handleRequestCreate={handleRequestCreate}
                route="vehicleSaleSearchNextGen"
                setResultsList={setResultsList}
                customLimit={10}
                disableSearch={selectedEntity?.id ? true : false}
            />

            <Paper>
                <DataTable columns={columns}>
                    {resultsListAltered.map((row) => (
                        <TableRow key={row.id}>
                            <DataCell>
                                {selectedEntity?.id ? (
                                    <IconButton
                                        onClick={() => setSelectedEntity(null)}
                                        disabled={readOnly}
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() => setSelectedEntity(row)}
                                    >
                                        <RadioButtonUnchecked />
                                    </IconButton>
                                )}
                            </DataCell>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/vehicles/viewContract/${row.id}`,
                                        '_blank'
                                    )
                                }
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>
                                {row.Customer?.companyName != null
                                    ? row.Customer?.companyName
                                    : row.Customer?.firstName +
                                      ' ' +
                                      row.Customer?.lastName}
                                <br />
                                {row.Customer?.abn
                                    ? `ABN: ${row.Customer?.abn}`
                                    : ''}
                            </DataCell>

                            <DataCell>
                                {row.Vehicle?.stockNumber
                                    ? row.Vehicle?.stockNumber
                                    : 'ORDER'}
                            </DataCell>
                            <DataCell>{row.Customer?.email}</DataCell>
                            <DataCell>{row.Customer?.phoneNumber}</DataCell>
                            <DataCell>
                                <Chip
                                    label={row.Customer?.customerType}
                                    variant="outlined"
                                    color={
                                        row.Customer?.customerType === 'Retail'
                                            ? 'success'
                                            : 'warning'
                                    }
                                />
                            </DataCell>
                            <DataCell>
                                <Chip
                                    label={row.saleStatus}
                                    variant="outlined"
                                    color={
                                        row.saleStatus === 'Finalised'
                                            ? 'success'
                                            : row.saleStatus === 'Sale'
                                              ? 'warning'
                                              : 'error'
                                    }
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </>
    );
};

export default VehicleContractForm;
