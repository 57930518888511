import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Order } from '../../../global/interfaces/PartsInterface';

const GetPartSalesHistory = (
    selectedSite: number,
    UnitId: number,
    setMonthList: Dispatch<SetStateAction<{ month: string; qtySold: number }[]>>
) => {
    api.get(`getPartSalesHistory/${UnitId}?SiteId=${selectedSite}`).then(
        (res) => {
            var orders: Order[] = res.data;
            var monthList = [];

            var monthNames = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ];
            var today = new Date();
            today.setDate(1);

            for (let i = 0; i <= 12; i++) {
                let qtySold = 0;
                orders.forEach((order) => {
                    var createdAt = order.createdAt.split('T')[0];
                    var monthCreatedAt = createdAt.split('-')[1];

                    if (
                        today.getMonth() + 1 === parseInt(monthCreatedAt) &&
                        today.getFullYear() ===
                            parseInt(createdAt.split('-')[0])
                    ) {
                        order.orderLines.forEach((row) => {
                            if (row.UnitId === UnitId) {
                                qtySold += parseInt(row.quantity);
                            }
                        });
                    }
                });

                var obj = {
                    month:
                        monthNames[today.getMonth()] +
                        ' ' +
                        today.getFullYear(),
                    qtySold: qtySold
                };
                monthList.push(obj);
                today.setMonth(today.getMonth() - 1);
            }

            setMonthList(monthList);
        }
    );
};

export default GetPartSalesHistory;
