import { Table } from "../interfaces/StockOrderInterface"

const CalculateSupplierTotalCost = (tables: Table[], id: number, OrderType: string) => {
    let currentTables = tables;
    var index = currentTables.findIndex(x => x.supplierId === id && x.OrderType === OrderType);
    let rows = [...currentTables];
    let row = { ...rows[index] };

    var total = 0;
    row.orderLines.forEach(item => {
        total += item.rowTotal
    })

    return total;
}

export default CalculateSupplierTotalCost;