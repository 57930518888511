// IMPORTS
import { useEffect, useState } from 'react';
import { Typography, TableRow } from '@mui/material';

// COMPONENTS
// import CustomerTableFilter from "./components/CustomerTableFilter";
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import StockAdjustmentTableFilter from './components/StockAdjustmentTableFilter';
// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import GetSite from '../../global/databaseLogic/GetSite';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { StockAdjustment } from '../../global/interfaces/PartsInterface';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../global/logic/Formatters';
import { Site } from '../../global/interfaces/GeneralInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = { stockAdjustmentFilter: { site: [] } };
const clearedSort = ['id', 'DESC'];

const StockAdjustmentTable = () => {
    const [filter, setFilter] = useState<Filter>({
        stockAdjustmentFilter: {
            site: [{ id: parseInt(localStorage.getItem('SiteId')), name: '' }]
        }
    });
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<StockAdjustment[]>([]);
    const [siteDetails, setSiteDetails] = useState<Site>();

    // Columns for the data table
    const columns = [
        { id: 1, label: 'Part Number', width: 300 },
        { id: 2, label: 'Name', width: 200 },
        { id: 3, label: 'Adjustment Quantity', width: 200 },
        { id: 4, label: 'Adjustment Price', width: 200 },
        { id: 5, label: 'Reference', width: 200 },
        { id: 6, label: 'Date', width: 100 },
        { id: 7, label: 'User', width: 100 },
        { id: 8, label: 'Location', width: 100 }
    ];

    useEffect(() => {
        GetSite(parseInt(localStorage.getItem('SiteId')), setSiteDetails);
    }, []);

    useEffect(() => {
        setFilter({
            ...filter,
            stockAdjustmentFilter: {
                site: [
                    {
                        id: parseInt(localStorage.getItem('SiteId')),
                        name: siteDetails?.name
                    }
                ]
            }
        });
        // eslint-disable-next-line
    }, [siteDetails]);

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let site =
            filter.stockAdjustmentFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.stockAdjustmentFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';

        let apiString = `${site}`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (filter.stockAdjustmentFilter.site.length > 0) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Stock Adjustments</Typography>
            <br />
            <TableSearch
                searchTitle="Search Adjustments"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="stockAdjustmentSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <StockAdjustmentTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable columns={columns} sort={sort} setSort={setSort}>
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCell>{row.Unit?.partNumber}</DataCell>
                            <DataCell>{row.Unit?.name}</DataCell>
                            <DataCell>{row.adjustmentValue}</DataCell>
                            <DataCell>
                                {CurrencyFormatter(row.adjustmentCost)}
                            </DataCell>
                            <DataCell>{row.adjustmentDescription}</DataCell>
                            <DataCell>{DateFormatter(row.createdAt)}</DataCell>
                            <DataCell>
                                {row.User?.firstName} {row?.User?.lastName}
                            </DataCell>
                            <DataCell>{row.Site?.name}</DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </PageWrapper>
    );
};

export default StockAdjustmentTable;
