// IMPORTS
import { useContext, useState } from 'react';
import { Box, Typography, Divider, Chip, Menu, MenuItem } from '@mui/material';
import { Add, Dashboard } from '@mui/icons-material';
import { Prompt } from 'react-router-dom';

// TS COMPONENTS
import LeadsTable from './components/LeadsTable';
import NewEnquiry from './components/NewEnquiry';
import SpeedDial from '../../global/SpeedDial';
import PageWrapper from '../../global/PageWrapper';
import Drawer from '../../global/Drawer';
import { withSnackbar } from '../../global/WrappingSnackbar';

// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Site, User } from '../../global/interfaces/GeneralInterface';
import { Make } from '../../global/interfaces/VehicleSaleInterface';
import { Dayjs } from 'dayjs';
import { UserContext } from '../../../main/Main';

interface Filter {
    assignedUser: User[];
    enquiryStatus: { type: string }[];
    make: Make[];
    sites: Site[];
    startDate: Dayjs;
    endDate: Dayjs;
}

const LeadsManagement = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const userData = useContext<User>(UserContext);
    const [shouldBlockNavigation, setShouldBlockNavigation] =
        useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [anchorElSettings, setAnchorElSettings] = useState(null);

    const [filter, setFilter] = useState<Filter>({
        enquiryStatus: [],
        assignedUser: [userData],
        startDate: null,
        endDate: null,
        make: [],
        sites: []
    });

    const [openNewEnquiry, setOpenNewEnquiry] = useState<boolean>(false);
    const [selectedEnquiryId, setSelectedEnquiryId] = useState<{
        id: number;
        user: User;
        status: string;
    }>({ id: null, user: null, status: null });

    const handleOpenEnquiry = (id: number, user: User, status: string) => {
        setSelectedEnquiryId({ id: id, user: user, status: status });
        setShouldBlockNavigation(true);
        setOpenNewEnquiry(true);
    };

    const handleOpenSettings = (e) => {
        setAnchorElSettings(e.currentTarget);
    };

    const handleCloseSettings = () => {
        setAnchorElSettings(null);
    };

    const actions = [
        {
            icon: <Add />,
            name: 'New Lead',
            onClick: () => {
                setSelectedEnquiryId({ id: null, user: null, status: null });
                setDisabled(false);
                setOpenNewEnquiry(true);
                setShouldBlockNavigation(true);
            }
        },
        {
            icon: <Dashboard />,
            name: 'Managers Dashboard',
            onClick: () =>
                (window.location.href = '/vehicles/leadsmanagement/dashboard'),
            permission: 'access_leadManager'
        }
    ];

    const statusOptions = [
        { value: 'ACTIVE', colour: 'success' },
        { value: 'PENDING', colour: 'warning' },
        { value: 'SOLD', colour: 'info' },
        { value: 'LOST', colour: 'error' }
    ];

    return (
        <PageWrapper>
            <Prompt
                when={shouldBlockNavigation}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <Typography variant="h4">Leads Management</Typography>
            <br />
            <LeadsTable
                filter={filter}
                setFilter={setFilter}
                type="Active"
                handleOpenEnquiry={handleOpenEnquiry}
            />

            <Drawer
                openDrawer={openNewEnquiry}
                setOpenDrawer={setOpenNewEnquiry}
                width="80vw"
                title={
                    selectedEnquiryId.id
                        ? 'Enquiry #' + selectedEnquiryId.id
                        : 'New Enquiry'
                }
                subTitle=""
            >
                {selectedEnquiryId.id ? (
                    <>
                        <Typography>
                            <Chip
                                disabled={disabled}
                                onClick={(e) => handleOpenSettings(e)}
                                label={selectedEnquiryId.status}
                                color={
                                    selectedEnquiryId.status === 'ACTIVE' ||
                                    selectedEnquiryId.status === 'SOLD'
                                        ? 'success'
                                        : selectedEnquiryId.status === 'PENDING'
                                          ? 'warning'
                                          : 'error'
                                }
                            />
                            &nbsp;&nbsp; Assigned To:{' '}
                            {selectedEnquiryId.user.firstName}{' '}
                            {selectedEnquiryId.user.lastName}
                        </Typography>

                        <Menu
                            sx={{ mt: '45px' }}
                            anchorEl={anchorElSettings}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorElSettings)}
                            onClose={handleCloseSettings}
                        >
                            {statusOptions.map((status) => {
                                if (status.value !== selectedEnquiryId.status) {
                                    return (
                                        <MenuItem
                                            value={status.value}
                                            color={status.colour}
                                        >
                                            <Chip
                                                onClick={(e) =>
                                                    setSelectedEnquiryId({
                                                        ...selectedEnquiryId,
                                                        status: status.value
                                                    })
                                                }
                                                label={status.value}
                                            />
                                        </MenuItem>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Menu>
                        <Box height={'10px'}></Box>
                        <Divider></Divider>
                        <Box height="20px"></Box>
                        <NewEnquiry
                            selectedEnquiryId={selectedEnquiryId.id}
                            setSelectedEnquiryId={setSelectedEnquiryId}
                            status={selectedEnquiryId.status}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            disabled={disabled}
                            setDisabled={setDisabled}
                            showSnackbar={showSnackbar}
                        />
                    </>
                ) : (
                    <NewEnquiry
                        selectedEnquiryId={selectedEnquiryId.id}
                        setSelectedEnquiryId={setSelectedEnquiryId}
                        status={selectedEnquiryId.status}
                        setShouldBlockNavigation={setShouldBlockNavigation}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        showSnackbar={showSnackbar}
                    />
                )}
            </Drawer>

            <SpeedDial actionsList={actions} />
        </PageWrapper>
    );
};

export default withSnackbar(LeadsManagement);
