// IMPORTS
import { useEffect, useState } from 'react';
import { Typography, TableRow, IconButton, Dialog } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

// COMPONENTS
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import ApiDialogContent from './components/ApiDialogContent';

// LOGIC
import HandleAddApi from './logic/HandleAddApi';
import HandleSelectApi from './logic/HandleSelectApi';
import GetLists from './logic/GetLists';

// INTERFACE
import {
    Creditor,
    OrderFileGenerator,
    Site
} from '../../global/interfaces/GeneralInterface';

interface SelectedApi {
    id?: number;
    name: string;
    nameError: boolean;
    description: string;
    descriptionError: boolean;
    Site: Site;
    siteError: boolean;
    key: string;
    Supplier: Creditor;
    supplierError: boolean;
    canGenerateOrderFile: boolean;
    canOrderWithAPI: boolean;
    Generator: OrderFileGenerator;
    generatorError: boolean;
    OrderApi: OrderFileGenerator;
    OrderApiError: boolean;
    detailsList: {};

    OrderFileGeneratorId?: number;
    ApiOrderId?: number;
    additionalDetails?: {};
}

const ApiUITS = () => {
    const [apiData, setApiData] = useState([]);
    const [siteList, setSiteList] = useState<Site[]>([]);
    const [supplierList, setSupplierList] = useState<Creditor[]>([]);
    const [generatorList, setGeneratorList] = useState<OrderFileGenerator[]>(
        []
    );

    const [addOpen, setAddOpen] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [selectedApi, setSelectedApi] = useState<SelectedApi>({
        name: '',
        nameError: false,
        description: '',
        descriptionError: false,
        Site: {},
        siteError: false,
        key: '',
        Supplier: {},
        supplierError: false,
        canGenerateOrderFile: false,
        canOrderWithAPI: false,
        Generator: {},
        generatorError: false,
        OrderApi: {},
        OrderApiError: false,
        detailsList: []
    });

    useEffect(() => {
        GetLists(setApiData, setSiteList, setSupplierList, setGeneratorList);
    }, []);

    const columns =
        window.outerWidth < 600
            ? [
                  { id: 1, label: 'Name' },
                  { id: 2, label: 'Site' }
              ]
            : [
                  { id: 1, label: 'Name' },
                  { id: 2, label: 'Description' },
                  { id: 3, label: 'Site' },
                  { id: 4, label: '' }
              ];

    return (
        <PageWrapper>
            <Typography variant="h4" align="left">
                API Settings
            </Typography>
            <br />
            <Paper>
                <Grid container spacing={2}>
                    <Grid xs={12} textAlign="center">
                        <DataTable columns={columns}>
                            {apiData?.map((apiRow, index) =>
                                window.outerWidth < 600 ? (
                                    <TableRow>
                                        <DataCellColoured
                                            handleClick={() => {
                                                HandleSelectApi({
                                                    index: index,
                                                    apiData: apiData,
                                                    generatorList:
                                                        generatorList,
                                                    setSelectedApi:
                                                        setSelectedApi,
                                                    setAddOpen: setAddOpen,
                                                    setEdit: setEdit
                                                });
                                            }}
                                        >
                                            {apiRow.name}
                                        </DataCellColoured>
                                        <DataCell>{apiRow.Site.name}</DataCell>
                                    </TableRow>
                                ) : (
                                    <TableRow>
                                        <DataCell>{apiRow.name}</DataCell>
                                        <DataCell>
                                            {apiRow.description}
                                        </DataCell>
                                        <DataCell>{apiRow.Site.name}</DataCell>
                                        <DataCell>
                                            <IconButton
                                                onClick={() =>
                                                    HandleSelectApi({
                                                        index: index,
                                                        apiData: apiData,
                                                        generatorList:
                                                            generatorList,
                                                        setSelectedApi:
                                                            setSelectedApi,
                                                        setAddOpen: setAddOpen,
                                                        setEdit: setEdit
                                                    })
                                                }
                                            >
                                                <Edit />
                                            </IconButton>
                                        </DataCell>
                                    </TableRow>
                                )
                            )}
                        </DataTable>
                        <IconButton
                            onClick={() =>
                                HandleAddApi({
                                    setSelectedApi: setSelectedApi,
                                    setAddOpen: setAddOpen,
                                    setEdit: setEdit
                                })
                            }
                        >
                            <Add />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>

            <Dialog
                onClose={() => setAddOpen(false)}
                open={addOpen}
                className="xl-dialog"
            >
                <ApiDialogContent
                    selectedApi={selectedApi}
                    setSelectedApi={setSelectedApi}
                    siteList={siteList}
                    supplierList={supplierList}
                    generatorList={generatorList}
                    edit={edit}
                />
            </Dialog>
        </PageWrapper>
    );
};

export default ApiUITS;
