// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
import Paper from '../../../global/Paper';
import AddEntryLine from '../logic/AddEntryLine';
// LOGIC
import GetAllAccounts from '../../../global/databaseLogic/GetAllAccounts';
import GetAllAccountsNested from '../../../global/databaseLogic/GetAllAccountsNested';
// INTERFACES
import {
    Account,
    GeneralJournalLine
} from '../../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface HeaderProps {
    id: number;
    readOnly: boolean;
    setReadOnly: Dispatch<SetStateAction<boolean>>;
    entryLines: GeneralJournalLineTemp[];
    setEntryLines: Dispatch<SetStateAction<GeneralJournalLineTemp[]>>;
    showSnackbar: showSnackbar;
}
export interface GeneralJournalLineTemp extends GeneralJournalLine {
    rowId?: number;
    accountName: string;
    descriptionError?: boolean;
    debitError?: boolean;
    creditError?: boolean;
    debitAmount: number;
    creditAmount: number;
}

const Header = ({
    id,
    entryLines,
    setEntryLines,
    showSnackbar,
    readOnly,
    setReadOnly
}: HeaderProps) => {
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [generalJournalTransaction, setGeneralJournalTransaction] = useState({
        AccountId: null,
        Account: null,
        description: null,
        dateEntered: null,
        debit: null,
        credit: null,
        gstType: 'N/T',
        reconciled: null
    });

    useEffect(() => {
        if (id) {
            setReadOnly(true);
            GetAllAccountsNested(setAccounts);
        } else {
            GetAllAccounts(setAccounts);
        }
        // eslint-disable-next-line
    }, [id]);

    // const actionsList = [
    //     { id: 0, icon: <Save />, name: 'Save', onClick: () => HandleUpdateManualTransaction(id, manualTransaction, selectedType, setSnackbar) },
    // ]

    const gstTypes = [
        { value: 'G1' },
        { value: 'G2' },
        { value: 'G3' },
        { value: 'G10' },
        { value: 'G11' },
        { value: 'N/T' }
    ];

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3} textAlign="right">
                        <Autocomplete
                            isDisabled={id ? true : false}
                            size="small"
                            options={accounts}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            secondaryOptionLabel={id ? 'Site' : 'siteName'}
                            useNestedObject={id ? true : false}
                            secondaryOptionLabelNest={'name'}
                            textfieldLabel="Account"
                            selectedValue={generalJournalTransaction?.Account}
                            handleSelectedValueChange={(newValue) => {
                                setGeneralJournalTransaction({
                                    ...generalJournalTransaction,
                                    Account: newValue,
                                    AccountId: newValue?.id
                                });
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={2.5}>
                        <TextField
                            disabled={id ? true : false}
                            label="Description"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            fullWidth
                            value={generalJournalTransaction?.description}
                            onChange={(e) =>
                                setGeneralJournalTransaction({
                                    ...generalJournalTransaction,
                                    description: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={id ? true : false}
                                format="DD/MM/YYYY"
                                label="Date Received"
                                value={Dayjs(
                                    generalJournalTransaction?.dateEntered
                                )}
                                onChange={(newValue) => {
                                    setGeneralJournalTransaction({
                                        ...generalJournalTransaction,
                                        dateEntered:
                                            Dayjs(newValue).toISOString()
                                    });
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            disabled={
                                parseFloat(generalJournalTransaction?.credit) >
                                    0 || id
                                    ? true
                                    : false
                                      ? true
                                      : false
                            }
                            label="Debit Amount"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            type="number"
                            fullWidth
                            value={generalJournalTransaction?.debit}
                            onChange={(e) =>
                                setGeneralJournalTransaction({
                                    ...generalJournalTransaction,
                                    debit: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            disabled={
                                parseFloat(generalJournalTransaction?.debit) >
                                    0 || id
                                    ? true
                                    : false
                                      ? true
                                      : false
                            }
                            label="Credit Amount"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            type="number"
                            fullWidth
                            value={generalJournalTransaction?.credit}
                            onChange={(e) =>
                                setGeneralJournalTransaction({
                                    ...generalJournalTransaction,
                                    credit: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            disabled={id ? true : false}
                            label="GST"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            fullWidth
                            select
                            value={generalJournalTransaction?.gstType}
                            onChange={(e) =>
                                setGeneralJournalTransaction({
                                    ...generalJournalTransaction,
                                    gstType: e.target.value
                                })
                            }
                        >
                            {gstTypes.map((item) => (
                                <MenuItem value={item.value}>
                                    {item.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {readOnly ? null : (
                        <Grid item xs={12} textAlign="center">
                            <Button
                                variant="contained"
                                onClick={() =>
                                    AddEntryLine(
                                        generalJournalTransaction,
                                        entryLines,
                                        setEntryLines,
                                        showSnackbar
                                    )
                                }
                            >
                                Add Line
                            </Button>
                        </Grid>
                    )}
                    {/* {!id
                        ? <Grid item xs={12} textAlign="center">
                            <Button variant="contained" onClick={() => HandleAddItem(selectedType, manualTransaction, manualTransactions, setManualTransactions, setSnackbar)}>Add Line</Button>
                        </Grid>
                        : !readOnly
                            ? <SpeedDial actionsList={actionsList} />
                            : <Fab editIcon={true} onClick={() => setReadOnly(false)} />
                    } */}
                </Grid>
            </Paper>
        </>
    );
};

export default Header;
