import { Step } from '../../interface';

export const AddManualTransactionSteps: Step[] = [
    {
        label: "In the sidebar, click 'Admin' > 'Manual Transaction' to navigate to the Manual Transaction page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/manualTransaction/mannualTransaction-01.png'
    },
    {
        label: "Select an account from the 'Account' dropdown list.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/manualTransaction/mannualTransaction-02.png'
    },
    {
        label: 'Add a reference, date received, and either a debit or credit amount. Ensure to clear the debit field before entering a credit, and vice versa.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/manualTransaction/mannualTransaction-03.png'
    },
    {
        label: "Click 'Add Line' to include it in the Manual Transaction. Repeat steps 2 and 3 to add more lines.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/manualTransaction/mannualTransaction-04.png'
    },
    {
        label: "Click 'Save' to submit the Manual Transaction. A success message will appear if the transaction is saved successfully.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/manualTransaction/mannualTransaction-05.png'
    },
    {
        label: "Find the newly created Manual Transaction in the Account's transactions list. Click its ID to navigate to the details page where you can update or delete the Manual Transaction.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/manualTransaction/mannualTransaction-06.png'
    },
    {
        label: "Also, locate the new Manual Transaction in the 'Unreconciled Ora' table on the bank reconciliation page, where it can be reconciled with a matching bank transaction record.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/manualTransaction/mannualTransaction-07.png'
    }
];
