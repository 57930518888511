import dayjs from 'dayjs';

const TermsAndConditions = (doc, fillInformation, base64Image) => {
    let userSignature = null;
    doc.setFont('helvetica', 'bold')
        .setFontSize(10)
        .text('TERMS AND CONDITIONS', 105, 20, { align: 'center' });
    doc.setFontSize(8).text(
        'Unless otherwise stated, the Purchaser and the Dealer agree as follows:',
        105,
        23,
        { align: 'center' }
    );
    doc.setFont('helvetica', 'normal').setFontSize(6);

    let string =
        'The Purchaser must pay to the Dealer the Balance Payable specified in the Price and Terms of Settlement and the Purchaser must deliver to the Dealer the trade-in vehicle (if any) described in this contract prior to taking delivery of the motor vehicle. The Balance Payable and all other prices set out in the Price and Terms of Settlement are based upon existing costs and circumstances. It is possible that these prices may increase prior to delivery of the motor vehicle. If any of the prices set out in the Price and Terms of Settlement increase prior to delivery of the motor vehicle, the Dealer will notify the Purchaser of any such increase. If the Purchaser accepts delivery of the motor vehicle or if the Purchaser fails to notify the Dealer within seven (7) days of notification by the Dealer of the increase that the Purchaser wishes to cancel its order and rescind this contract, the Purchaser acknowledges that the Purchaser will be deemed to have accepted the increase in prices set out in the notice from the Dealer.';
    doc.text('1.', 14, 30);
    let splittedText = doc.splitTextToSize(string, 85);
    doc.text(splittedText, 16, 30, { maxWidth: 85, align: 'justify' });

    string =
        'If the trade-in vehicle is not in substantially the same condition as it was in at the time of valuation by the Dealer, the Dealer may:';
    splittedText = doc.splitTextToSize(string, 85);
    doc.text('2.', 14, 61);
    doc.text(splittedText, 16, 61, { maxWidth: 85, align: 'justify' });

    string =
        'Reduce the amount specified in the Prices and Terms of Settlement as the trade-in Allowance to an amount determined by the Dealer in its sole discretion to be a fair valuation of the trade-in vehicle in its current condition before delivery of the motor vehicle to the Purchaser; or';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('a.', 17, 67);
    doc.text(splittedText, 22, 67, { maxWidth: 75, align: 'justify' });

    doc.text('b.', 17, 77);
    doc.text('Rescind the contract.', 22, 77);

    doc.text('The Purchaser may:', 17, 82);
    string =
        'Accept any new amount determined by the Dealer as being the trade-in Allowance; or';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('c.', 17, 87);
    doc.text(splittedText, 22, 87, { maxWidth: 75, align: 'justify' });

    doc.text('d.', 17, 92);
    doc.text('Rescind the contract', 22, 92);

    string =
        "The Purchaser must take delivery of the motor vehicle described in the contract from the Dealer at the Dealer's premises within seven (7) days of the date on which the Dealer notifies the Purchaser to take delivery of the motor vehicle.";
    splittedText = doc.splitTextToSize(string, 85);
    doc.text('3.', 14, 97);
    doc.text(splittedText, 16, 97, { maxWidth: 85, align: 'justify' });

    doc.text(
        '4. Title in the motor vehicle will pass to the Purchaser upon the latter of:',
        14,
        106
    );

    string =
        'Receipt in full by the Dealer of the amount set out in the Price and Terms of Settlement as the Balance Payable; or';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('a.', 17, 110);
    doc.text(splittedText, 22, 110, { maxWidth: 75, align: 'justify' });

    string =
        'The date on which the Dealer acquires clear and unencumbered title to the trade-in vehicle described in the contract.';
    splittedText = doc.splitTextToSize(string, 70);
    doc.text('b.', 17, 115);
    doc.text(splittedText, 22, 115, { maxWidth: 75, align: 'justify' });

    string =
        'Risk in the motor vehicle will pass to the Purchaser upon delivery of the motor vehicle to the Purchaser. The Purchaser must indemnify the Dealer from and against any and all claims, losses, damages, costs and expenses in any way connected with the possession and / or use of the motor vehicle by the Purchaser until title in the motor vehicle has passed to the Purchaser.';
    splittedText = doc.splitTextToSize(string, 85);
    doc.text('5.', 14, 121);
    doc.text(splittedText, 16, 121, { maxWidth: 85, align: 'justify' });

    doc.text(
        '6. In relation to the trade-in vehicle, the Purchaser warrants:',
        14,
        135
    );

    string =
        'That the Purchaser is either the owner of the trade-in vehicle or has the authority of the owner to transfer all right, title and interest in and to the trade-in vehicle to the Dealer;';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('a.', 17, 139);
    doc.text(splittedText, 22, 139, { maxWidth: 75, align: 'justify' });

    string =
        'The trade-in vehicle is either free from all encumbrances or the encumbrances in respect of the trade-in vehicle are limited to those encumbrances specified in this contract;';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('b.', 17, 147);
    doc.text(splittedText, 22, 147, { maxWidth: 75, align: 'justify' });

    string =
        'All of the particulars specified in this contract in relation to the trade-in vehicle are accurate;';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('c.', 17, 155);
    doc.text(splittedText, 22, 155, { maxWidth: 75, align: 'justify' });

    string =
        'The trade-in vehicle has never been used as a taxi, a rental vehicle or a hire car.';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('d.', 17, 160);
    doc.text(splittedText, 22, 160, { maxWidth: 75, align: 'justify' });

    doc.text('e.', 17, 163);
    doc.text('The trade-in vehicle has never been used in a flood;', 22, 163);

    string =
        'The trade-in vehicle has never been modified so far as to render the vehicle unregistrable or unroadworthy;';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('f.', 17, 166);
    doc.text(splittedText, 22, 166, { maxWidth: 75, align: 'justify' });

    doc.text('g.', 17, 171);
    doc.text(
        'The trade-in vehicle is not subject to any defect notice;',
        22,
        171
    );

    string =
        'There are no unpaid fines or other traffic infringement notices outstanding in respect of the trade-in vehicle;';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('h.', 112, 30);
    doc.text(splittedText, 117, 30, { maxWidth: 75, align: 'justify' });

    string =
        'The trade-in vehicle is registered and there are no circumstances which may cause the registration of the vehicle to be cancelled;';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('i.', 112, 35);
    doc.text(splittedText, 117, 35, { maxWidth: 75, align: 'justify' });

    string =
        'Registration of the trade-in vehicle is not subject to any concessional rebate which may have been granted to the registrant of the trade-in vehicle;';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('j.', 112, 40);
    doc.text(splittedText, 117, 40, { maxWidth: 75, align: 'justify' });

    string =
        'The Purchaser is not bankrupt and has not committed any act of bankruptcy which may affect title in and to the trade-in vehicle;';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('k.', 112, 45);
    doc.text(splittedText, 117, 45, { maxWidth: 75, align: 'justify' });

    string =
        'If the Purchaser is not the owner of the trade-in vehicle, the owner of the trade-in vehicle is not bankrupt, has not committed any act of bankruptcy, is not insolvent, has not had a receiver or a receiver and a manager or a controller or a liquidator appointed to its property; has not entered into a composition or arrangement for the benefit of its creditors and has not had an administrator appointed, such as to effect title in and to the trade-in vehicle.';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('l.', 112, 50);
    doc.text(splittedText, 117, 50, { maxWidth: 75, align: 'justify' });

    doc.text('7. If the Purchaser:', 109, 68);

    string =
        'Fails to provide clear and unencumbered title in and to the trade-in vehicle to the Dealer;';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('a.', 112, 72);
    doc.text(splittedText, 117, 72, { maxWidth: 75, align: 'justify' });

    doc.text('b.', 112, 77);
    doc.text(
        'Is in breach of any of its warranties with respect to the trade-in vehicle;',
        117,
        77
    );

    string =
        'Fails or refuses to take delivery of the motor vehicle in accordance with the terms and conditions set out in this contract; or';
    splittedText = doc.splitTextToSize(string, 75);
    doc.text('c.', 112, 80);
    doc.text(splittedText, 117, 80, { maxWidth: 75, align: 'justify' });

    doc.text('d.', 112, 85);
    doc.text(
        'Is otherwise in breach of its obligations under this contract;',
        117,
        85
    );

    string =
        'The Dealer may, in its sole discretion, terminate the contract immediately by written notice to the Purchaser in which case the Dealer or its agents may enter any premises of the Purchaser and may take possession of the motor vehicle and remove the motor vehicle from the premises without an order of the court. The Purchaser must reimburse the Dealer for any costs or expenses incurred by the Dealer in reposessing the motor vehicle. The Purchaser acknowledges that the Dealer may retain or recover as fair compensatory damages the deposit payd by the Purchaser in respect of the motor vehicle and an amount equal to 10% of the amount set out in the Price and Terms of Settlement as the Total Vehicle Price less the deposit paid.';
    splittedText = doc.splitTextToSize(string, 85);
    doc.text(splittedText, 112, 90, { maxWidth: 85, align: 'justify' });

    string =
        'If the Purchaser has made it known to the Dealer prior to entry into this contract that the Purchaser requires the provision of credit to pay for the motor vehicle and despite having taken reasonable steps to obtain credit, has failed to obtain credit, the Purchaser may within a reasonable period after signing the contract rescind the contract by notice in writing to the Dealer.';
    splittedText = doc.splitTextToSize(string, 85);
    doc.text('8.', 109, 114);
    doc.text(splittedText, 112, 114, { maxWidth: 85, align: 'justify' });

    string =
        'If the Purchaser lawfully rescinds this contract, the Dealer will refund to the Purchaser all amounts paid by the Purchaser to the Dealer under this contract less any amounts actually incurred by the Dealer in repairing or improving the trade-in vehicle and less any amounts paids by the Dealer to any third party to acquire clear and unencumbered title in and to the trade-in vehicle. If the Dealer has not disposed of the trade-in vehicle, the Dealer will return the trade-in vehicle to the Purchaser. If the Dealer has disposed of the trade-in vehicle, the Purchaser accepts that the realised value of the trade-in vehicle less any amounts paid by the Dealer to any third party to acquire clear and unencumbered title in and to the trade-in vehicle will constiture fair and reasonable compensation for the trade-in vehicle.';
    splittedText = doc.splitTextToSize(string, 85);
    doc.text('9.', 109, 128);
    doc.text(splittedText, 112, 128, { maxWidth: 85, align: 'justify' });

    string =
        'To the extend permitted by law, all warranties and conditions are excluded from this contract. If any term, condition or warranty is incorporated into this contract by operation of any law and that law prohibits the exclusion or modification of liability under any such term, condition or warranty, such term, condition or warranty will be deemed to be included in this contract.';
    splittedText = doc.splitTextToSize(string, 85);
    doc.text('10.', 109, 152);
    doc.text(splittedText, 112, 152, { maxWidth: 85, align: 'justify' });

    string =
        'This contract sets out the entire agreement between the parties. Any amendments or variation to this contract must be evidenced in writing and signed by the parties';
    splittedText = doc.splitTextToSize(string, 85);
    doc.text('11.', 109, 166);
    doc.text(splittedText, 112, 166, { maxWidth: 85, align: 'justify' });

    doc.setLineWidth(0.2).line(10, 175, 200, 175);
    // doc.setFont('helvetica', 'bold').setFontSize(8).text('Additional Comments:', 12, 178);

    // doc.line(10, 200, 200, 200);
    doc.setFontSize(9);
    doc.text(
        'Declaration for the Purposes of the PAYG (Witholding Tax) Legislation',
        105,
        178,
        { align: 'center' }
    );
    doc.setFont('helvetica', 'normal')
        .setFontSize(8)
        .text(
            '(Tick as applicable and initial deletions and deletions)',
            105,
            181,
            { align: 'center' }
        );

    doc.setFontSize(7);
    doc.text(
        'The supplier of the trade-in vehicle clearly declares that it:',
        12,
        186
    );
    doc.text(
        'Will provide a Tax Invoice for the supply of the above described trade-in vehicle as it holds an ABN which is ___________________________________ and',
        20,
        190
    );
    doc.text('it is registered for GST purposes; or', 20, 193);
    doc.text(
        'Will not be providing a Tax Invoice for the supply of the above described trade-in vehicle because either:',
        20,
        198
    );
    doc.text(
        'The supply is made by it as an individual and is wholly private or domestic in nature; or',
        25,
        203
    );
    doc.text(
        'It holds an ABN which is ______________________________, but is not registered for GST purposes.',
        25,
        208
    );

    doc.rect(14, 188, 2, 2);
    doc.rect(14, 195, 2, 2);
    doc.rect(19, 201, 2, 2);
    doc.rect(19, 206, 2, 2);

    doc.line(10, 211, 200, 211);

    doc.text(
        "I _______________________________________________ acknowledge that I have read and understood the motor dealer's / chattel auctioneer's declaration and agree that:",
        12,
        218
    );

    doc.text(
        'This contract is not binding on the dealer until it is accepted by the Motor Dealer',
        20,
        224
    );
    doc.text(
        'The prices, allowances and amounts shown in this contract may be adjusted in accordance with above.',
        20,
        229
    );
    doc.text(
        'The buyer is not under the age of 18 years, not bankrupt and has not committed an act of bankruptcy.',
        20,
        234
    );
    doc.text(
        'Prior to the buyer signing this contract, all material particulars have been inserted in this contract and the ',
        20,
        239
    );
    doc.text(
        'buyer has read and understands such particulars and terms and conditions set out on all pages to this ',
        20,
        242
    );
    doc.text(
        'contract, including the conditions outlined at the end.',
        20,
        245
    );

    doc.setFont('Symbol');
    doc.text('·', 15, 224);
    doc.text('·', 15, 229);
    doc.text('·', 15, 234);
    doc.text('·', 15, 239);

    doc.line(10, 247, 200, 247);

    doc.setFont('helvetica', 'bold');
    doc.text('DATED this _______________________________', 14, 251)
        .text('Day of _______________________', 72, 251)
        .text('Year _________________', 112, 251);
    doc.text('SIGNATURE OF', 14, 258);
    doc.text(
        'THE PURCHASER: _______________________________________  WITNESS:  __________________________',
        14,
        261
    );
    doc.text('ACCEPTED this _______________________', 14, 267)
        .text('Day of _______________________', 72, 267)
        .text('Year _________________', 112, 267);

    // prefill witness signature with current user signature; prefill date with current date
    if (fillInformation && base64Image) {
        //declare time, day, month, year, default as current time
        let now = dayjs();
        let day = now.format('DD');
        let month = now.format('MMMM');
        let year = now.format('YYYY');
        doc.text(day, 30, 251);
        doc.text(month, 85, 251);
        doc.text(year, 120, 251);

        // witness signature
        let sigImg = document.createElement('img');
        sigImg.src = base64Image;
        sigImg.className = 'signature';
        userSignature = sigImg;
        doc.addImage(userSignature, 105, 250, 45, 15);
    }

    doc.setFont('helvetica', 'bold').setFontSize(10);
    doc.text('WHEN SIGNED, THIS DOCUMENT BECOMES LEGALLY BINDING.', 105, 275, {
        align: 'center'
    });
    doc.text('PLEASE READ THE DOCUMENT CAREFULLY BEFORE YOU SIGN.', 105, 280, {
        align: 'center'
    });
};

export default TermsAndConditions;
