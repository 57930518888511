import { Dispatch, SetStateAction } from 'react';
import { MultipleStockInvoice } from '../../../global/interfaces/PartsInterface';

// Validate that the lines on the invoice have a valid quantity and cost
const ValidateMultipleOrderLines = (
    multipleInvoiceData: MultipleStockInvoice,
    setMultipleInvoiceData: Dispatch<SetStateAction<MultipleStockInvoice>>
) => {
    let newMultipleInvoiceData = JSON.parse(
        JSON.stringify(multipleInvoiceData)
    );
    let isError = false;
    for (let invoiceData of newMultipleInvoiceData.orderLines) {
        for (let line of invoiceData.orderLines) {
            if (!line.removed && !line.invoiced) {
                if (
                    line.quantityReceived == null ||
                    line.quantityReceived === '' ||
                    isNaN(parseFloat(line.quantityReceived))
                ) {
                    line.quantityError = true;
                    isError = true;
                } else {
                    line.quantityError = false;
                }

                if (
                    line.itemPrice == null ||
                    line.itemPrice === '' ||
                    isNaN(parseFloat(line.itemPrice))
                ) {
                    line.priceError = true;
                    isError = true;
                } else {
                    line.priceError = false;
                }
            }
        }
    }

    setMultipleInvoiceData(newMultipleInvoiceData);
    return isError;
};

export default ValidateMultipleOrderLines;
