// IMPORTS
import { useEffect, useState } from 'react';
// LOGIC
import GetAllUserPermissions from '../databaseLogic/GetAllUserPermissions';

interface CheckPermissionProps {
    permission: string;
    toReturnIfAuthorized: any;
    toReturnIfUnauthorized?: any;
}

const CheckPermission = ({
    permission,
    toReturnIfAuthorized,
    toReturnIfUnauthorized
}: CheckPermissionProps) => {
    const [display, setDisplay] = useState(false);
    const [permissions, setPermissions] = useState([]);

    // Get all the permissions for the current user (0 indicates current user)
    useEffect(() => {
        GetAllUserPermissions(0, setPermissions);
    }, [permission]);

    // Check if the current user has the specified permission
    useEffect(() => {
        let tempDisplay = false;
        let splitPermissions = permission.replaceAll(' ', '').split(',');

        for (let i = 0; i < splitPermissions.length; i++) {
            let item = String(splitPermissions[i]);
            if (permissions.includes(item)) {
                tempDisplay = true;
            }
        }

        setDisplay(tempDisplay);
        // eslint-disable-next-line
    }, [permissions]);

    return display ? toReturnIfAuthorized : toReturnIfUnauthorized;
};

export default CheckPermission;
