import {
    AppBar,
    Button,
    CircularProgress,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface CustomerAppBarProps {
    requiredFields: {
        id: number;
        ref: React.MutableRefObject<any>;
        required: boolean;
        signed: boolean;
    }[];
    handleClickSubmit: () => Promise<void>;
    loading: boolean;
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

const CustomerAppBar = ({
    requiredFields,
    handleClickSubmit,
    loading,
    step,
    setStep
}: CustomerAppBarProps) => {
    // When user click start button, scroll to the sign date field
    const handleClickStartOrNext = () => {
        if (step === requiredFields.length) {
            return;
        }
        requiredFields[step].ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });

        setStep(step + 1);
    };

    const StartOrNextButton = () => {
        return (
            <Button
                style={{
                    backgroundColor: '#FFD700',
                    color: '#000000'
                }}
                variant="contained"
                onClick={handleClickStartOrNext}
            >
                {step === 0 ? 'Start' : 'Next'}
            </Button>
        );
    };
    const SubmitButton = () => {
        return (
            <Tooltip title="By clicking submit, a signed copy of the document will be emailed to you and the dealer.">
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    style={{
                        backgroundColor: '#FFD700',
                        color: '#000000'
                    }}
                    variant="contained"
                    onClick={handleClickSubmit}
                    loadingPosition="end"
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                >
                    {loading ? '' : 'Submit'}
                </LoadingButton>
            </Tooltip>
        );
    };

    // check if all required fields are signed; if it is not required, can be skipped
    let allRequiredCompleted = requiredFields.every(
        (field) => !field.required || field.signed
    );

    return (
        <AppBar position="sticky" color="primary">
            <Toolbar>
                <Typography style={{ flexGrow: 0.7, textAlign: 'left' }}>
                    {allRequiredCompleted
                        ? "You have successfully signed. Click the 'Submit' button to send the document."
                        : step === 0
                          ? "Please review and sign the document below. Click the 'Start' button to begin."
                          : "Click the 'Next' button to proceed to the next section."}
                </Typography>
                {allRequiredCompleted ? (
                    <SubmitButton />
                ) : (
                    <StartOrNextButton />
                )}
            </Toolbar>
        </AppBar>
    );
};

export default CustomerAppBar;
