// IMPORTS
import { useState } from 'react';
import {
    Typography,
    TableRow,
    TableCell,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import { Add, FileDownload } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
// LOGIC
import HandleDownloadResults from '../../global/logic/HandleDownloadResults';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { ImportCreditorCSVContent } from './components/ImportCreditorCSVContent';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

interface Detail {
    id: number;
    value: string;
    reference: string;
}

interface ContactDetail {
    email: Detail[];
    phone: Detail[];
}

interface Result {
    id: number;
    code: string;
    name: string;
    abn: string;
    addressLine1: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    contactDetails: ContactDetail;
}

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {};
const clearedSort = ['code', 'ASC'];

const CreditorTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const history = useHistory();
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<Result[]>([]);

    // CSV Import Dialog
    const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);

    // Boolean that check if there is at least one account in the DB
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Creditor',
            onClick: () => history.push('/addCreditor')
        },
        {
            icon: <FileDownload />,
            name: 'Export Current Results',
            onClick: () => handleDownloadResults()
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'Code', name: 'code', sort: true, width: 75 },
        { id: 1, label: 'Name', name: 'name', sort: true, width: 300 },
        { id: 2, label: 'ABN', width: 75 },
        { id: 3, label: 'Phone Number', width: 100 },
        { id: 4, label: 'Email', width: 200 },
        { id: 5, label: 'Location', width: 250 }
    ];

    // Handle downloading the csv
    const handleDownloadResults = () => {
        HandleDownloadResults(
            sort,
            'creditorSearchNextGen',
            handleRequestCreate(),
            'OraCreditorResults.csv',
            [
                'ID',
                'Code',
                'Name',
                'ABN',
                'Phone Number',
                'Email',
                'Address Line 1',
                'Suburb',
                'State',
                'Postcode',
                'Country'
            ],
            [
                'id',
                'code',
                'name',
                'abn',
                'contactDetails.phone[0].value',
                'contactDetails.email[0].value',
                'addressLine1',
                'suburb',
                'state',
                'postcode',
                'country'
            ]
        );
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        return '&checkEmpty=true';
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        return false;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Creditors</Typography>
            <br />
            <TableSearch
                searchTitle="Search Creditor"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="creditorSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
                setIsEmpty={setIsEmpty}
            ></TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() =>
                                    history.push(`/viewCreditor/${row.id}`)
                                }
                            >
                                {row.code}
                            </DataCellColoured>
                            <TableCell sx={{ textAlign: 'left' }}>
                                {row.name}
                            </TableCell>
                            <DataCell>{row.abn}</DataCell>
                            <DataCell>
                                {row.contactDetails?.phone[0]?.value}
                            </DataCell>
                            <DataCell>
                                {row.contactDetails?.email[0]?.value}
                            </DataCell>
                            <DataCell>
                                {row.suburb} {row.state} {row.postcode}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Dialog
                open={isEmpty}
                onClose={() => setIsEmpty(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>No Creditor created yet</DialogTitle>
                <DialogContent>
                    <Typography>
                        Ora couldn't find any creditor in the database. Do you
                        wish to import creditor(s) through a CSV?
                    </Typography>
                    <Typography>
                        You can also manually create creditor(s), simply close
                        this dialog and click on the pop-up menu in the bottom
                        right of the page and click on "Add creditor".
                    </Typography>
                    <Typography variant="caption">
                        If you have already created creditor(s) and this dialog
                        opened by mistake, please contact the Ora development
                        team.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => setIsEmpty(false)}
                    >
                        Close Dialog
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsEmpty(false);
                            setImportDialogOpen(true);
                        }}
                    >
                        Import Creditor CSV
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={importDialogOpen}
                onClose={() => setImportDialogOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <ImportCreditorCSVContent showSnackbar={showSnackbar} />
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(CreditorTable);
