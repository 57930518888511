import api from '../../../../../api';
import moment from 'moment';

const CreateAgeStockReport = (sites) => {
    let siteIds = '';
    if (sites.length > 0) {
        for (let site of sites) {
            if (siteIds === '') {
                siteIds = site.id.toString();
            } else {
                siteIds = siteIds + ',' + site.id.toString();
            }
        }
    }

    api.get(`agedStockReport?SiteId=${siteIds}`).then((res) => {
        let data = res.data;

        let rows = [];
        let filename = 'AgedStockReport_' + moment().format('DD/MM/YYYY');

        rows.push([
            'ID',
            'Stock Number',
            'Year',
            'Make',
            'Model',
            'VIN',
            'Age (days)',
            'Base Cost',
            'Condition',
            'Status',
            'Site'
        ]);

        data.forEach((vehicle) => {
            let startDay = moment(vehicle.createdAt);
            let today = moment();
            let row = [
                vehicle.id,
                vehicle.stockNumber,
                vehicle.year,
                vehicle.Make?.name,
                vehicle.Model?.name,
                vehicle.vin,
                today.diff(startDay, 'days') + 1,
                vehicle.costPrice,
                vehicle.condition.split('|')[0],
                vehicle.condition.split('|')[1],
                vehicle.Site.name
            ];
            rows.push(row);
        });

        let csvContent = 'data:text/csv;charset=utf-8,';
        rows.forEach(function (rowArray) {
            let row = rowArray.join(',');
            csvContent += row + '\r\n';
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    });
};

export default CreateAgeStockReport;
