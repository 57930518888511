import { Step } from '../../interface';

export const AddCustomerSteps: Step[] = [
    {
        label: 'Click ‘Customer’ in the sidebar.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/add-customer-01.png'
    },
    {
        label: `Hover the speed dial and click 'Add Customer' to open the Add Customer page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/add-customer-02.png'
    },
    {
        label: 'On the Add Customer page, select the customer type and complete all required fields.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/add-customer-03.png'
    },
    {
        label: 'For Retail customers, enter Phone Number and either Full Name or Company Name. Other fields are optional but must be valid if filled.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/add-customer-04.png'
    },
    {
        label: 'For Trade customers, enter Phone Number, Full Name or Company Name, and a Trade Discount.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/add-customer-05.png'
    },
    {
        label: 'Set the Trade Discount as ‘Cost +’ or ‘Retail -’. For example, ‘Cost + 10%’ adds 10% to the total cost, while ‘Retail - 10%’ deducts 10% from the RRP.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/add-customer-06.png'
    },
    {
        label: 'Click ‘Save’. If there are invalid or missing fields, an error message will be displayed the necessary corrections.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/add-customer-07.png'
    },
    {
        label: `After adding the customer, a confirmation message 'Customer added successfully' will appear, followed by an automatic page refresh.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/add-customer-08.png'
    }
];

export const UpdateCustomerSteps: Step[] = [
    {
        label: `Click the Customer ID to open the View Customer Page. `,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-01.png'
    },
    {
        label: `On the View Customer page, to update client details, click the Edit button.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-02.png'
    },
    {
        label: `Make the necessary changes and click Save. A confirmation message 'Customer updated successfully' will be displayed.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-03.png'
    },
    {
        label: `To designate the customer as a Debtor, click the Edit button in the frame under his name. This assigns a default account limit of $0 to the debtor, which can be adjusted later.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-04.png'
    },
    {
        label: `To revoke debtor status, click the Edit button in the frame under his name again. By removing debtor status, they will no longer be able to make put purchases on their account to pay off.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-05.png'
    },
    {
        label: `For Debtor customers, there are additional details under 'Account Terms & Payments' on the View Customer page. In the Account Terms & Payments tab, you are able to update Payment Terms, print account statement, email account statement to customer and view linked Account Payments.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-06.png'
    },
    {
        label: 'To edit the Payment Terms, click the Edit icon. Adjust the debtor’s Account Limit and Payment Terms, then save to apply the changes.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-07.png'
    },
    {
        label: `To print an account statement or email the account statement to customer, click the 'Print' icon.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-08.png'
    },
    {
        label: 'Click "Generate Statement" to open the Account Statement PDF in a new window.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-10.png'
    },
    {
        label: 'Click "Email to Customer" to send the statement to the customer’s email address.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/edit-customer-09.png'
    }
];

export const SearchCustomersSteps: Step[] = [
    {
        label: `To search customers, enter a customer ID, first name, last name, company name, email, or phone number in the Search Customer text field and press Enter. Results will appear below.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/search-customer-01.png'
    },
    {
        label: `To apply filters, click the Filter icon. Supported filters include Customer Type, Debtor status, and presence of Phone Number or Email. Select and combine filters as needed.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/search-customer-02.png'
    },
    {
        label: `To remove a filter, click the X icon next to it. To clear all filters, click on the Reset Filter button next to the "Filter & Sort Results" Dialog title.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/search-customer-03.png'
    }
];

export const MergeCustomersSteps: Step[] = [
    {
        label: `Click the 'Merge Customers' icon in the speed dial menu to open the merge customer dialog.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/merge-customer-01.png'
    },
    {
        label: `There are two options for merging customers: bulk merge and specific merge. For a bulk merge, generate a list of duplicate customers and select the duplicates to merge. For a specific merge, manually select one customer to merge into another.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/merge-customer-02.png'
    },
    {
        label: `For specific merges, search for the customer to be deleted by name or ID (e.g., #115290), then select the customer to be kept (e.g., #115283).`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/merge-customer-03.png'
    },
    {
        label: `Click 'Merge Customers' to delete Customer #115290 and transfer all associated data (orders, services, payments) to Customer #115283.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/merge-customer-04.png'
    },
    {
        label: `For bulk merges based on attributes, select an attribute to check (e.g., same Phone Number) and click 'Generate Duplicate List'. This will group customers with the same phone number together.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/merge-customer-05.png'
    },
    {
        label: `In each group, tick the checkbox next to the customer to keep. All other customers in the group will be removed. Click 'Submit Merge' to complete the bulk merge and refresh the page.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/merge-customer-06.png'
    }
];

export const ExportCustomersSteps: Step[] = [
    {
        label: `To export customer results to a CSV file, simply click the 'Export Current Results' icon, the results will automatically download to your device.`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/customers/export-customer-01.png'
    }
];
