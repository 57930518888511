// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { ToFixed } from '../../../global/logic/Formatters';

interface InvoiceLine {
    id: number;
    Vehicle?: Vehicle;
    amount: string;
}

function SubmitTrustReceipt(
    invoiceLines: InvoiceLine[],
    trustReceipt,
    showSnackbar: showSnackbar,
    setIsSubmittingInvoice: Dispatch<SetStateAction<boolean>>,
    responseCode: number
) {
    setIsSubmittingInvoice(true);
    if (invoiceLines.length === 0) {
        showSnackbar(
            'Whoops! Missing Data!',
            'Please enter the invoice lines.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    }

    let calculatedTotal = 0;

    invoiceLines.forEach((line) => {
        calculatedTotal += parseFloat(line.amount);
        if (line.amount === '' || parseFloat(line.amount) <= 0) {
            showSnackbar(
                'There is Invalid Data!',
                'Please check your line amounts.',
                'error'
            );
            setIsSubmittingInvoice(false);
            return;
        }
    });

    if (
        !trustReceipt.documentReference ||
        trustReceipt.documentReference === ''
    ) {
        showSnackbar(
            'There is Missing Data!',
            'Please enter the document reference on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    }

    if (responseCode === 400) {
        showSnackbar(
            'There is Invalid Data!',
            'The document reference already exists on another invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    }

    if (!trustReceipt.documentDate || trustReceipt.documentDate === '') {
        showSnackbar(
            'There is Missing Data!',
            'Please enter the document date on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    }

    if (trustReceipt.documentTotal === '') {
        showSnackbar(
            'There is Missing Data!',
            'Please enter the document total on the invoice.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    }

    if (ToFixed(calculatedTotal) !== parseFloat(trustReceipt.documentTotal)) {
        showSnackbar(
            'There is Mismatching Data!',
            'Your line total and document total do not match.',
            'error'
        );
        setIsSubmittingInvoice(false);
        return;
    }

    api.post('trustInvoice', {
        invoiceLines: invoiceLines,
        dueDate: trustReceipt.dueDate,
        documentReference: trustReceipt.documentReference,
        documentDate: trustReceipt.documentDate,
        documentTotal: trustReceipt.documentTotal
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Invoice Submitted Successfully.',
                'The page will now refresh.',
                'success'
            );

            // Set a timeout to reload the page after 3 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact the IT department.',
                'error'
            );
            setIsSubmittingInvoice(false);
        }
    });
}

export default SubmitTrustReceipt;
