import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    Typography
} from '@mui/material';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import ClearIcon from '@mui/icons-material/Clear';

interface CustomerVendorStatementFormProps {
    onVendorFormSubmit: (formData: any, submitType: any) => Promise<void>;
}

const CustomerVendorStatementForm = ({
    onVendorFormSubmit
}: CustomerVendorStatementFormProps) => {
    const { control, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            // Vendor details
            vendorSigDataURL: null
        }
    });

    let vendorSigCanvas = useRef<SignatureCanvas>(null);

    const clearSignature = () => {
        vendorSigCanvas.current.clear();
        setValue('vendorSigDataURL', null);
    };

    const saveSignature = () => {
        const dataURL = vendorSigCanvas.current.toDataURL();
        setValue('vendorSigDataURL', dataURL);
    };

    const handlePreviewChanges = (data) => {
        onVendorFormSubmit(data, 'preview');
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Paper
                elevation={3}
                sx={{
                    padding: 3,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <form>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        I/We hereby certify that the above information supplied
                        is true and correct.
                    </Typography>
                    <Grid item xs={12}>
                        VENDOR SIGNATURE:{' '}
                        <IconButton onClick={clearSignature}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>
                    <Controller
                        name="vendorSigDataURL"
                        control={control}
                        render={({ field }) => (
                            <>
                                <SignatureCanvas
                                    penColor="black"
                                    disabled={false}
                                    canvasProps={{
                                        className: 'sigCanvas',
                                        style: {
                                            border: '1px solid black',
                                            minWidth: 300,
                                            width: '50vw',
                                            minHeight: 100,
                                            height: '15vw'
                                        }
                                    }}
                                    ref={vendorSigCanvas}
                                    // save user signature dataURL to form data
                                    onEnd={saveSignature}
                                />
                            </>
                        )}
                    />
                    {/* // buttons to preview and email the form data */}
                    <Grid item xs={12}>
                        <Button
                            onClick={handleSubmit(handlePreviewChanges)}
                            variant="contained"
                            disabled={watch('vendorSigDataURL') === null}
                        >
                            Preview Signature
                        </Button>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
};

export default CustomerVendorStatementForm;
