import {
    Grid,
    TextField,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormHelperText,
    Radio,
    FormControlLabel
} from '@mui/material';

const Step1 = (props) => {
    return (
        <>
            <Typography variant="button">
                <b>
                    Please enter your business details. Fields marked with a *
                    are required.
                </b>
            </Typography>
            <br />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        label="Company Name"
                        InputLabelProps={{ shrink: true }}
                        error={props.stepValidation.companyName.error}
                        helperText={
                            props.stepValidation.companyName.error
                                ? props.stepValidation.companyName.message
                                : ''
                        }
                        value={props.settings.companyName}
                        onChange={(e) =>
                            props.setSettings({
                                ...props.settings,
                                companyName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        label="Legal Entity Name"
                        InputLabelProps={{ shrink: true }}
                        error={props.stepValidation.legalEntityName.error}
                        helperText={
                            props.stepValidation.legalEntityName.error
                                ? props.stepValidation.legalEntityName.message
                                : ''
                        }
                        value={props.settings.legalEntityName}
                        onChange={(e) =>
                            props.setSettings({
                                ...props.settings,
                                legalEntityName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        label="ABN"
                        InputLabelProps={{ shrink: true }}
                        error={props.stepValidation.abn.error}
                        helperText={
                            props.stepValidation.abn.error
                                ? props.stepValidation.abn.message
                                : ''
                        }
                        value={props.settings.abn}
                        onChange={(e) =>
                            props.setSettings({
                                ...props.settings,
                                abn: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="ACN"
                        InputLabelProps={{ shrink: true }}
                        error={props.stepValidation.acn.error}
                        helperText={
                            props.stepValidation.acn.error
                                ? props.stepValidation.acn.message
                                : ''
                        }
                        value={props.settings.acn}
                        onChange={(e) =>
                            props.setSettings({
                                ...props.settings,
                                acn: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Direct Deposit Account Name"
                        InputLabelProps={{ shrink: true }}
                        error={
                            props.stepValidation.directDepositAccountName.error
                        }
                        helperText={
                            props.stepValidation.directDepositAccountName.error
                                ? props.stepValidation.directDepositAccountName
                                      .message
                                : ''
                        }
                        value={props.settings.directDepositAccountName}
                        onChange={(e) =>
                            props.setSettings({
                                ...props.settings,
                                directDepositAccountName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="BSB"
                        InputLabelProps={{ shrink: true }}
                        error={
                            props.stepValidation.directDepositAccountBsb.error
                        }
                        helperText={
                            props.stepValidation.directDepositAccountBsb.error
                                ? props.stepValidation.directDepositAccountBsb
                                      .message
                                : ''
                        }
                        value={props.settings.directDepositAccountBsb}
                        onChange={(e) =>
                            props.setSettings({
                                ...props.settings,
                                directDepositAccountBsb: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Acc Number"
                        InputLabelProps={{ shrink: true }}
                        error={
                            props.stepValidation.directDepositAccountNumber
                                .error
                        }
                        helperText={
                            props.stepValidation.directDepositAccountNumber
                                .error
                                ? props.stepValidation
                                      .directDepositAccountNumber.message
                                : ''
                        }
                        value={props.settings.directDepositAccountNumber}
                        onChange={(e) =>
                            props.setSettings({
                                ...props.settings,
                                directDepositAccountNumber: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Dealer Licence Number"
                        InputLabelProps={{ shrink: true }}
                        error={props.stepValidation.lmd.error}
                        helperText={
                            props.stepValidation.lmd.error
                                ? props.stepValidation.lmd.message
                                : ''
                        }
                        value={props.settings.lmd}
                        onChange={(e) =>
                            props.setSettings({
                                ...props.settings,
                                lmd: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        type="date"
                        variant="outlined"
                        label="DLM Expiry"
                        InputLabelProps={{ shrink: true }}
                        value={props.settings.lmdExpiry}
                        onChange={(e) =>
                            props.setSettings({
                                ...props.settings,
                                lmdExpiry: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={0}
                        alignItems={'center'}
                        sx={{
                            border: 2,
                            borderColor: 'black',
                            borderRadius: 2
                        }}
                    >
                        <Grid item xs={12} textAlign="center">
                            <b>Company Logo</b>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    Upload Method
                                </FormLabel>
                                <RadioGroup
                                    row={true}
                                    value={props.companyLogo.method}
                                    onChange={(e) =>
                                        props.setCompanyLogo({
                                            ...props.companyLogo,
                                            method: e.target.value
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        value="url"
                                        label="From URL"
                                        control={<Radio />}
                                    />
                                    <FormControlLabel
                                        value="upload"
                                        label="Upload"
                                        control={<Radio />}
                                    />
                                </RadioGroup>
                                <FormHelperText></FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} textAlign={'center'}>
                            {props.companyLogo.method === 'url' ? (
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    label="Logo URL"
                                    InputLabelProps={{ shrink: true }}
                                    error={props.stepValidation.logoUrl.error}
                                    helperText={
                                        props.stepValidation.logoUrl.error
                                            ? props.stepValidation.logoUrl
                                                  .message
                                            : ''
                                    }
                                    value={props.settings.logoUrl}
                                    onChange={(e) =>
                                        props.setSettings({
                                            ...props.settings,
                                            logoUrl: e.target.value
                                        })
                                    }
                                />
                            ) : (
                                <input
                                    type="file"
                                    name="companyLogo"
                                    onChange={async (e) => {
                                        let file = e.target.files[0];
                                        let picturePreview =
                                            URL.createObjectURL(
                                                e.target.files[0]
                                            );
                                        let img = document.createElement('img');
                                        img.onload = function () {
                                            props.setCompanyLogo({
                                                ...props.companyLogo,
                                                picturePreview: picturePreview,
                                                pictureAsFile: file,
                                                x: img.width,
                                                y: img.height
                                            });
                                        };
                                        img.src = picturePreview;
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={2} />
                        {props.companyLogo.method === 'upload' &&
                        props.companyLogo.picturePreview?.length > 0 ? (
                            <>
                                <Grid item xs={12}>
                                    Company Logo Preview:
                                </Grid>
                                <Grid item xs={12}>
                                    <img
                                        width={350}
                                        height={
                                            props.companyLogo.y *
                                            (350 / props.companyLogo.x)
                                        }
                                        src={props.companyLogo.picturePreview}
                                        alt="If this appears, the logo wasn't uploaded successfully"
                                    />
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Step1;
