// IMPORTS
import { Divider, Table, TableRow, Typography, TableCell } from '@mui/material';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import {
    Discount,
    GovernmentCharges,
    PartTotals,
    TotalPaid,
    TradeTotals,
    VehicleCharges,
    VoucherTotals
} from '../logic/TotalFunctions';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';
import {
    TradeIn,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { PaymentLine, Site } from '../../../global/interfaces/GeneralInterface';
import { useContext } from 'react';
import { SiteContext } from '../../../../main/Main';
interface TotalsFormProps {
    saleDetails: VehicleSale;
    linkedSales: Order[];
    linkedVouchers: any[];
    linkedTrades: TradeIn[];
    paymentForm?: boolean;
    paymentLines?: PaymentLine[];
    gstType: string;
}
const TotalsForm = ({
    saleDetails,
    linkedSales,
    linkedVouchers,
    linkedTrades,
    paymentForm,
    paymentLines,
    gstType
}: TotalsFormProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    let partTotals = PartTotals(linkedSales);
    let voucherTotals = VoucherTotals(linkedVouchers);
    let vehicleCharges = VehicleCharges(saleDetails);
    let governmentCharges = GovernmentCharges(saleDetails);
    let discount = Discount(saleDetails);
    let tradeTotals = TradeTotals(linkedTrades, gstType);
    let amountPaid = paymentForm ? TotalPaid(paymentLines) : 0;

    return (
        <>
            <Typography variant="h6">Total</Typography>
            <Divider />
            <Table size="small">
                <TableRow>
                    <TableCell
                        width="70%"
                        align="left"
                        sx={{ borderBottom: 'none' }}
                    >
                        Add-Ons
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{
                            borderBottom: 'none',
                            paddingRight: 0,
                            paddingLeft: 0
                        }}
                    >
                        {CurrencyFormatter(partTotals.totalPartPrice)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell width="70%" align="left">
                        Vouchers
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                        {CurrencyFormatter(voucherTotals)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell width="70%" align="left">
                        <b>Subtotal</b>
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                        <b>
                            {CurrencyFormatter(
                                partTotals.totalPartPrice + voucherTotals
                            )}
                        </b>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        width="70%"
                        align="left"
                        sx={{ borderBottom: 'none' }}
                    >
                        Vehicle Charges
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{
                            borderBottom: 'none',
                            paddingRight: 0,
                            paddingLeft: 0
                        }}
                    >
                        {CurrencyFormatter(vehicleCharges)}
                    </TableCell>
                </TableRow>
                {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                    <TableRow>
                        <TableCell
                            width="70%"
                            align="left"
                            sx={{ borderBottom: 'none' }}
                        >
                            Government Charges
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            {CurrencyFormatter(governmentCharges)}
                        </TableCell>
                    </TableRow>
                ) : null}

                <TableRow>
                    <TableCell
                        width="70%"
                        align="left"
                        sx={{ borderBottom: 'none' }}
                    >
                        GST
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{
                            borderBottom: 'none',
                            paddingRight: 0,
                            paddingLeft: 0
                        }}
                    >
                        {CurrencyFormatter(
                            saleDetails.excludeGST
                                ? 0
                                : (parseFloat(saleDetails.totalPurchasePrice) -
                                      governmentCharges) /
                                      11
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell width="70%" align="left">
                        Discount
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                        {CurrencyFormatter(discount)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        width="70%"
                        align="left"
                        sx={{ borderBottom: 'none' }}
                    >
                        <b>Total Purchase Price</b>
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{
                            borderBottom: 'none',
                            paddingRight: 0,
                            paddingLeft: 0
                        }}
                    >
                        {CurrencyFormatter(saleDetails.totalPurchasePrice)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell width="70%" align="left">
                        <b>Taxable Amount (EX. GST)</b>
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                        {CurrencyFormatter(
                            saleDetails.excludeGST
                                ? parseFloat(saleDetails.totalPurchasePrice)
                                : (parseFloat(saleDetails.totalPurchasePrice) -
                                      governmentCharges) /
                                      1.1
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        width="70%"
                        align="left"
                        sx={{ borderBottom: 'none' }}
                    >
                        Deposit
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{
                            borderBottom: 'none',
                            paddingRight: 0,
                            paddingLeft: 0
                        }}
                    >
                        {CurrencyFormatter(saleDetails.depositPrice)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell width="70%" align="left">
                        Trade Allowance
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                        {CurrencyFormatter(tradeTotals)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell width="70%" align="left">
                        <b>Amount Payable</b>
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{ paddingRight: 0, paddingLeft: 0 }}
                    >
                        {CurrencyFormatter(
                            parseFloat(saleDetails.totalPurchasePrice) -
                                parseFloat(saleDetails.depositPrice) -
                                tradeTotals
                        )}
                    </TableCell>
                </TableRow>
                {paymentForm ? (
                    <>
                        <TableRow>
                            <TableCell width="70%" align="left">
                                Amount Paid
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{ paddingRight: 0, paddingLeft: 0 }}
                            >
                                {CurrencyFormatter(amountPaid)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell width="70%" align="left">
                                <b>Balance</b>
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{ paddingRight: 0, paddingLeft: 0 }}
                            >
                                <b>
                                    {CurrencyFormatter(
                                        parseFloat(
                                            saleDetails.totalPurchasePrice
                                        ) -
                                            tradeTotals -
                                            amountPaid
                                    )}
                                </b>
                            </TableCell>
                        </TableRow>
                    </>
                ) : null}
            </Table>
        </>
    );
};

export default TotalsForm;
