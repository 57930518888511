// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Collapse,
    Divider,
    Grid,
    IconButton,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { DateFormatter } from '../../../global/logic/Formatters';
import { Add, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

// COMPONENTS
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';

// INTERFACES
import { Registration } from '../../../global/interfaces/VehicleSaleInterface';

// LOGIC
import CreateRegistrationPrice from '../logic/CreateRegistrationPrice';

interface RegistrationsProps {
    registrations: Registration[]; // The company settings
    setRegistrations: Dispatch<SetStateAction<Registration[]>>;
    state: string;
    disabled: boolean;
}

const RegistrationPrice = ({
    registrations,
    setRegistrations,
    state,
    disabled
}: RegistrationsProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const columns = [
        { id: 0, label: 'Length', width: 50 },
        { id: 1, label: 'Fees', width: 50, colSpan: 2 },
        { id: 3, label: 'Stamp Duty', width: 50, colSpan: 2 }
    ];

    const getSpecificFee = (array, index: number, label: string) => {
        let keys = Object.keys(array);
        keys = keys.filter((x) => x !== 'total');
        if (index === 0) {
            if (label === 'fee') {
                return array[keys[index]];
            }
            if (label === 'key') {
                return keys[index];
            } else {
                return 'Plate Fee';
            }
        } else if (index === 1) {
            if (label === 'fee') {
                return array[keys[index]];
            }
            if (label === 'key') {
                return keys[index];
            } else {
                return 'Traffic Fee';
            }
        } else if (index === 2) {
            if (label === 'fee') {
                return array[keys[index]];
            }
            if (label === 'key') {
                return keys[index];
            } else {
                return 'Motor Vehicle Fee';
            }
        }
    };

    const handleChangeFee = (value, key, length) => {
        let tempRegistrations = JSON.parse(JSON.stringify(registrations));
        let total = 0;
        tempRegistrations.forEach((registration) => {
            if (registration.length === length) {
                // Update selected prices
                registration.prices[0][key] = parseFloat(value);

                // Calculate new total
                total += parseFloat(value);
                for (let currentKey of Object.keys(registration.prices[0])) {
                    if (key !== currentKey && currentKey !== 'total') {
                        total += parseFloat(registration.prices[0][currentKey]);
                    }
                }
                registration.prices[0].total = total;
            }
        });
        setRegistrations(tempRegistrations);
    };

    const handleChangeStampDuty = (value, index, length) => {
        let tempRegistrations = JSON.parse(JSON.stringify(registrations));

        tempRegistrations.forEach((registration) => {
            if (registration.length === length) {
                registration.stampDuty[index]['percentage'] = parseFloat(value);
            }
        });
        setRegistrations(tempRegistrations);
    };

    const getEffectiveDate = () => {
        return registrations[0]?.createdAt;
    };

    return (
        <Paper>
            <Typography variant="h6" align="left" display="inline">
                Registration Prices
            </Typography>
            <IconButton
                size="small"
                onClick={() => setOpen(!open)}
                sx={{ marginBottom: 0.5 }}
            >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Collapse in={open}>
                {registrations.length === 0 ? (
                    <Grid container textAlign={'center'}>
                        <Grid item xs={12}>
                            <IconButton
                                onClick={() => {
                                    CreateRegistrationPrice(
                                        state,
                                        'current',
                                        setRegistrations
                                    );
                                }}
                            >
                                <Add />{' '}
                            </IconButton>
                        </Grid>
                    </Grid>
                ) : state === 'QLD' ? (
                    <>
                        <Typography variant="body1" align="left">
                            Effective Since: {DateFormatter(getEffectiveDate())}
                        </Typography>
                        <Divider /> <br />
                        <Grid container spacing={2}>
                            <DataTable columns={columns}>
                                {registrations.map((registration) => (
                                    <>
                                        <TableRow>
                                            <DataCell rowSpan={4}>
                                                {registration.length}
                                            </DataCell>
                                        </TableRow>
                                        {registration.stampDuty.map(
                                            (line, index) => (
                                                <TableRow>
                                                    {disabled ? (
                                                        <>
                                                            <DataCell>
                                                                {getSpecificFee(
                                                                    registration
                                                                        .prices[0],
                                                                    index,
                                                                    'label'
                                                                )}
                                                            </DataCell>
                                                            <DataCell>
                                                                {getSpecificFee(
                                                                    registration
                                                                        .prices[0],
                                                                    index,
                                                                    'fee'
                                                                )}
                                                            </DataCell>
                                                            <DataCell>
                                                                {
                                                                    line.definition
                                                                }
                                                            </DataCell>
                                                            <DataCell>
                                                                {
                                                                    line.percentage
                                                                }
                                                            </DataCell>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <DataCell>
                                                                {getSpecificFee(
                                                                    registration
                                                                        .prices[0],
                                                                    index,
                                                                    'label'
                                                                )}
                                                            </DataCell>
                                                            <DataCell>
                                                                <TextField
                                                                    size="small"
                                                                    variant="standard"
                                                                    type="number"
                                                                    value={getSpecificFee(
                                                                        registration
                                                                            .prices[0],
                                                                        index,
                                                                        'fee'
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeFee(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            getSpecificFee(
                                                                                registration
                                                                                    .prices[0],
                                                                                index,
                                                                                'key'
                                                                            ),
                                                                            registration.length
                                                                        )
                                                                    }
                                                                />
                                                            </DataCell>
                                                            <DataCell>
                                                                {
                                                                    line.definition
                                                                }
                                                            </DataCell>
                                                            <DataCell>
                                                                <TextField
                                                                    size="small"
                                                                    variant="standard"
                                                                    type="number"
                                                                    value={
                                                                        line.percentage
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeStampDuty(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            index,
                                                                            registration.length
                                                                        )
                                                                    }
                                                                />
                                                            </DataCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            )
                                        )}
                                    </>
                                ))}
                            </DataTable>
                        </Grid>
                    </>
                ) : state === 'NSW' ? (
                    <>
                        <Typography variant="body1" align="left">
                            Effective Since: {DateFormatter(getEffectiveDate())}
                        </Typography>
                        <Divider /> <br />
                        <Grid container spacing={2}>
                            <DataTable columns={columns}>
                                {registrations.map((registration) => (
                                    <>
                                        <TableRow>
                                            <DataCell rowSpan={4}>
                                                {registration.length}
                                            </DataCell>
                                        </TableRow>
                                        {registration.stampDuty.map(
                                            (line, index) => (
                                                <TableRow>
                                                    {disabled ? (
                                                        <>
                                                            <DataCell>
                                                                {getSpecificFee(
                                                                    registration
                                                                        .prices[0],
                                                                    index,
                                                                    'label'
                                                                )}
                                                            </DataCell>
                                                            <DataCell>
                                                                {getSpecificFee(
                                                                    registration
                                                                        .prices[0],
                                                                    index,
                                                                    'fee'
                                                                )}
                                                            </DataCell>
                                                            <DataCell>
                                                                {
                                                                    line.definition
                                                                }
                                                            </DataCell>
                                                            <DataCell>
                                                                {
                                                                    line.percentage
                                                                }
                                                            </DataCell>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <DataCell>
                                                                {getSpecificFee(
                                                                    registration
                                                                        .prices[0],
                                                                    index,
                                                                    'label'
                                                                )}
                                                            </DataCell>
                                                            <DataCell>
                                                                <TextField
                                                                    size="small"
                                                                    variant="standard"
                                                                    type="number"
                                                                    value={getSpecificFee(
                                                                        registration
                                                                            .prices[0],
                                                                        index,
                                                                        'fee'
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeFee(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            getSpecificFee(
                                                                                registration
                                                                                    .prices[0],
                                                                                index,
                                                                                'key'
                                                                            ),
                                                                            registration.length
                                                                        )
                                                                    }
                                                                />
                                                            </DataCell>
                                                            <DataCell>
                                                                {
                                                                    line.definition
                                                                }
                                                            </DataCell>
                                                            <DataCell>
                                                                <TextField
                                                                    size="small"
                                                                    variant="standard"
                                                                    type="number"
                                                                    value={
                                                                        line.percentage
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeStampDuty(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            index,
                                                                            registration.length
                                                                        )
                                                                    }
                                                                />
                                                            </DataCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            )
                                        )}
                                    </>
                                ))}
                            </DataTable>
                        </Grid>
                    </>
                ) : null}
            </Collapse>
        </Paper>
    );
};

export default RegistrationPrice;
