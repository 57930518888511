import SignatureCanvas from 'react-signature-canvas';
import api from '../../../../api';
import { Dispatch, SetStateAction } from 'react';

interface GetUserSignatureProps {
    id: number;
    sigCanvas?: SignatureCanvas;
    setSignatureDataUrl?: Dispatch<SetStateAction<string>>;
    setHasSignature?: Dispatch<SetStateAction<boolean>>;
}

const GetUserSignature = ({
    id,
    sigCanvas,
    setSignatureDataUrl
}: GetUserSignatureProps) => {
    api.get('userSignature/' + id.toString()).then((res) => {
        if (sigCanvas && sigCanvas.current != null) {
            sigCanvas.current.fromDataURL(res.data.userSignature);
        }

        if (setSignatureDataUrl) {
            setSignatureDataUrl(res.data.userSignature);
        }
    });
};

export default GetUserSignature;
