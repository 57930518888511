// IMPORTS
import {
    Context,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    Typography,
    TextField,
    DialogContent,
    Stack,
    Button,
    Grid
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Autocomplete from '../../../global/Autocomplete';
import {
    DailyTask,
    Department,
    Site,
    User
} from '../../../global/interfaces/GeneralInterface';
import { PermissionsContextType } from '../../../global/interfaces/GlobalInterface';
import { PermissionsContext } from '../../../../main/Main';

// LOGIC
import handleSaveTask from '../logic/HandleSaveTask';
import GetAllSites from '../../../global/databaseLogic/GetAllSites';

interface TaskDialogContentProps {
    selectedTask: DailyTask;
    setSelectedTask: Dispatch<SetStateAction<DailyTask>>;
    currentSiteId: string;
    currentUser: User;
    onSuccessSaveTask: (savedTask: any) => void;
    onError: (error: any) => void;
}

interface DepartmentOption {
    id: number;
    label: string;
    value: string;
    permission: string;
}

//existing departments
const allDepartmentsOptions = [
    { id: 0, label: 'Parts', value: 'parts', permission: 'parts_manager' },
    {
        id: 1,
        label: 'Service',
        value: 'service',
        permission: 'service_manager'
    },
    { id: 2, label: 'Sales', value: 'sales', permission: 'sales_manager' },
    { id: 3, label: 'Admin', value: 'admin', permission: 'admin_manager' },
    { id: 4, label: 'IT', value: 'IT', permission: 'it_manager' }
];

const taskSchema = yup.object({
    site: yup.string().required('Site is required'),
    department: yup.string().required('Department is required'),
    description: yup.string().required('Description is required')
});

const TaskDialogContent = (props: TaskDialogContentProps) => {
    const {
        selectedTask,
        setSelectedTask,
        currentSiteId,
        currentUser,
        onSuccessSaveTask,
        onError
    } = props;

    //get permissions for current user
    const { permissions } = useContext(
        PermissionsContext as unknown as Context<PermissionsContextType>
    );

    const [sites, setSites] = useState<Site[]>([]);
    useEffect(() => {
        GetAllSites(setSites);
    }, []);

    // get department options user has permission
    // set default selected department: the task department when editing; the current user's department when adding
    const [departmentOptions, setDepartmentOptions] = useState<Department[]>(
        []
    );

    const getSelectedDepartmentOption = (departmentName: string) => {
        return allDepartmentsOptions.find(
            (option) => option.value === departmentName
        );
    };

    const [selectedDepartment, setSelectedDepartment] =
        useState<DepartmentOption>(
            getSelectedDepartmentOption(
                selectedTask ? selectedTask.department : currentUser.department
            )
        );

    useEffect(() => {
        const departmentWithPermission = allDepartmentsOptions.filter(
            (option) => permissions.includes(option.permission)
        );
        setDepartmentOptions(departmentWithPermission);
        const defaultSelectDepartment = getSelectedDepartmentOption(
            selectedTask ? selectedTask.department : currentUser.department
        );
        setSelectedDepartment(defaultSelectDepartment);
    }, [currentUser, permissions, selectedTask]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        defaultValues: {
            site: currentSiteId,
            department: selectedTask
                ? selectedTask.department
                : currentUser.department,
            description: selectedTask ? selectedTask.description : ''
        },
        resolver: yupResolver(taskSchema),
        mode: 'onTouched'
    });

    const onSubmit = async (data) => {
        //if it is editing an exsiting task, pass form data and set lastUpdatedBy as currentUser
        //if it is adding a new task, set both createdBy and lastUpdatedBy as currentUser
        const taskData = selectedTask
            ? { ...selectedTask, ...data, lastUpdatedBy: currentUser.id }
            : {
                  ...data,
                  SiteId: data.site,
                  createdBy: currentUser.id,
                  lastUpdatedBy: currentUser.id
              };

        handleSaveTask({ taskData })
            .then((savedTask) => {
                onSuccessSaveTask(savedTask);
                setSelectedTask(null);
            })
            .catch((error) => {
                onError(error);
                setSelectedTask(null);
            });
    };

    return (
        <DialogContent>
            <Typography variant="h5" align="center">
                {selectedTask
                    ? 'Update Daily Task Details'
                    : 'Add New Daily Task'}
            </Typography>
            <br />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <Grid item xs={6}>
                        <Autocomplete
                            size="small"
                            options={sites}
                            useTwoOptionLabels={false}
                            primaryOptionLabel="name"
                            textfieldLabel="Site *"
                            isDisabled={true}
                            selectedValue={currentUser.Site}
                            handleSelectedValueChange={() => null}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            name="department"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    size="small"
                                    options={departmentOptions}
                                    useTwoOptionLabels={false}
                                    primaryOptionLabel={'value'}
                                    textfieldLabel="Department *"
                                    selectedValue={selectedDepartment}
                                    handleSelectedValueChange={(event) => {
                                        setSelectedDepartment(event);
                                    }}
                                    handleInputValueChange={field.onChange}
                                    inputValue={field.value}
                                    isError={!!errors.department}
                                    helperText={errors.department?.message}
                                />
                            )}
                        />
                    </Grid>

                    <TextField
                        size="small"
                        variant="outlined"
                        label="Description *"
                        {...register('description')}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        error={!!errors.description}
                        helperText={errors.description?.message}
                    />

                    <Button type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                </Stack>
            </form>
        </DialogContent>
    );
};

export default TaskDialogContent;
