// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TableRow,
    Typography
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import TotalsForm from './TotalsForm';
import TradeForm from '../../tradeVehicle/components/TradeForm';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';
import {
    TradeIn,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import HandleRemoveTrade from '../logic/HandleRemoveTrade';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
interface TradesProps {
    readOnly: boolean;
    saleDetails: VehicleSale;
    linkedSales: Order[];
    linkedVouchers: any[];
    linkedTrades: TradeIn[];
    setLinkedTrades: Dispatch<SetStateAction<TradeIn[]>>;
    showSnackbar: showSnackbar;
    gstType: string;
    setGstType: Dispatch<SetStateAction<string>>;
}
const Trades = ({
    readOnly,
    saleDetails,
    linkedSales,
    linkedVouchers,
    linkedTrades,
    setLinkedTrades,
    showSnackbar,
    gstType,
    setGstType
}: TradesProps) => {
    const [vehicleFormOpen, setVehicleFormOpen] = useState<boolean>(false);
    const [openTradeDialog, setOpenTradeDialog] = useState<boolean>(false);
    const [selectedTrade, setSelectedTrade] = useState<number>(null);
    const [selectedTradeType, setSelectedTradeType] = useState<string>(null);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const columns = [
        { id: 0, label: 'ID', width: 100 },
        { id: 1, label: 'Vehicle', width: 600 },
        { id: 2, label: `Base Cost (${gstType}. GST)`, width: 400 },
        { id: 3, label: 'VIN', width: 400 },
        { id: 4, label: 'Registration', width: 400 },
        { id: 5, label: 'Availability', width: 200 },
        { id: 6, label: '', width: 100 }
    ];

    const handleClickDelete = (TradeId: number, index: number) => {
        if (TradeId) {
            setSelectedTrade(TradeId);
            setSelectedTradeType('id');
        } else {
            setSelectedTrade(index);
            setSelectedTradeType('index');
        }

        setOpenTradeDialog(true);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Typography variant="h6">
                                    Trades
                                    {!readOnly ? (
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                setVehicleFormOpen(true)
                                            }
                                        >
                                            <Add fontSize="small" />
                                        </IconButton>
                                    ) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} textAlign="right">
                                <FormControl>
                                    <RadioGroup
                                        row
                                        value={gstType}
                                        onChange={(e) =>
                                            setGstType(e.target.value)
                                        }
                                    >
                                        <FormControlLabel
                                            value="INC"
                                            control={<Radio />}
                                            label="INC. GST"
                                        />
                                        <FormControlLabel
                                            value="EX"
                                            control={<Radio />}
                                            label="EX. GST"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {linkedTrades.length > 0 ? (
                            <DataTable columns={columns}>
                                {linkedTrades.map((trade, index) => (
                                    <TableRow>
                                        <DataCellColoured
                                            handleClick={() =>
                                                window.open(
                                                    `/vehicles/view/${trade?.Vehicle?.id}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {trade?.Vehicle?.id}
                                        </DataCellColoured>
                                        <DataCell>
                                            {trade?.Vehicle?.year.format(
                                                'YYYY'
                                            )}{' '}
                                            {trade?.Vehicle?.Make?.name}{' '}
                                            {trade?.Vehicle?.Model?.name}{' '}
                                            {trade?.Vehicle?.Series?.name}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                trade?.tradeInPrice
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {trade?.Vehicle?.vin}
                                        </DataCell>
                                        {trade?.Vehicle?.registrationNumber ? (
                                            <DataCell>
                                                {
                                                    trade?.Vehicle
                                                        ?.registrationNumber
                                                }
                                                <br />
                                                {trade?.Vehicle
                                                    ?.registrationDueDate
                                                    ? `Due: ${DateFormatter(trade?.Vehicle?.registrationDueDate)}`
                                                    : ''}
                                            </DataCell>
                                        ) : (
                                            <DataCell></DataCell>
                                        )}
                                        <DataCell>
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                label={
                                                    trade?.Vehicle?.available
                                                        ? 'Available'
                                                        : 'Not Available'
                                                }
                                                color={
                                                    trade?.Vehicle?.available
                                                        ? 'success'
                                                        : 'error'
                                                }
                                            />
                                        </DataCell>
                                        <DataCell>
                                            <IconButton
                                                disabled={
                                                    trade?.Vehicle?.available ||
                                                    readOnly
                                                }
                                                onClick={() =>
                                                    handleClickDelete(
                                                        trade?.id,
                                                        index
                                                    )
                                                }
                                            >
                                                <Close />
                                            </IconButton>
                                        </DataCell>
                                    </TableRow>
                                ))}
                            </DataTable>
                        ) : (
                            <Typography variant="body1">
                                No trades linked to this contract.
                            </Typography>
                        )}
                    </Paper>
                    <br />
                </Grid>
                <Grid item xs={3}>
                    <Paper>
                        <TotalsForm
                            saleDetails={saleDetails}
                            linkedSales={linkedSales}
                            linkedVouchers={linkedVouchers}
                            linkedTrades={linkedTrades}
                            gstType={gstType}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                open={vehicleFormOpen}
                onClose={() => setVehicleFormOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <TradeForm
                    trades={linkedTrades}
                    setTrades={setLinkedTrades}
                    setVehicleFormOpen={setVehicleFormOpen}
                    showSnackbar={showSnackbar}
                    gstType={gstType}
                />
            </Dialog>

            <Dialog
                open={openTradeDialog}
                onClose={() => setOpenTradeDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Remove Trade?</DialogTitle>
                <DialogContent>
                    Are you sure you would like to remove this trade in from the
                    contract? All details about the vehicle and the pricing will
                    be lost.
                    <br />
                    <b>This can not be undone.</b>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenTradeDialog(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            HandleRemoveTrade(
                                selectedTrade,
                                linkedTrades,
                                setLinkedTrades,
                                selectedTradeType,
                                setOpenTradeDialog,
                                showSnackbar,
                                setButtonLoading
                            )
                        }
                    >
                        Delete Trade
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Trades;
