// IMPORTS
import { useEffect, useState } from 'react';
import { Fab, Grid, IconButton, TableRow, Tooltip } from '@mui/material';
import {
    Add,
    CheckCircle,
    CheckCircleOutline,
    Reorder
} from '@mui/icons-material';

// COMPONENTS
import CustomerTableFilter from '../../../../general/customer/components/CustomerTableFilter';
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import TableSearch from '../../../../global/tableComponents/TableSearch';
import Drawer from '../../../../global/Drawer';
import CustomerDrawerContent from '../../../../global/customerFormComponents/CustomerDrawerContent';

// LOGIC
import ConvertArrayToFilter from '../../../../global/logic/ConvertArrayToFilter';

// INTERFACES
import { Filter } from '../../../../global/interfaces/FilterInterface';
import { Customer } from '../../../../global/interfaces/GeneralInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    customerFilter: {
        customerType: [],
        debtor: null,
        hasPhoneNumber: null,
        hasEmail: null
    }
};
const clearedSort = ['id', 'DESC'];
const clearedCustomer = {
    firstName: null,
    lastName: null,
    email: null,
    customerType: 'Retail',
    addressLine1: null,
    addressLine2: null,
    suburb: null,
    postcode: null,
    state: null,
    country: null,
    phoneNumber: null,
    companyName: null,
    abn: null,
    dateOfBirth: null,
    driverLicenceNumber: null,
    debtor: false,
    accountLimit: null,
    paymentTerms: null,
    gstFree: null,
    international: null
};

const CustomerTab = ({ customerDetails, setCustomerDetails, disabled }) => {
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<Customer[]>([]);
    const [resultsListAltered, setResultsListAltered] = useState<Customer[]>(
        []
    );
    const [customerDrawerOpen, setCustomerDrawerOpen] =
        useState<boolean>(false);
    const [viewCustomer, setViewCustomer] = useState<Customer>(clearedCustomer);

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'Personal / Company Name', width: 300 },
        { id: 2, label: 'Email', width: 200 },
        { id: 3, label: 'Phone Number', width: 200 },
        { id: 4, label: '' }
    ];

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let customerType =
            filter.customerFilter.customerType.length > 0
                ? ConvertArrayToFilter({
                      array: filter.customerFilter.customerType,
                      filterStart: '&customerType=',
                      selector: 'value'
                  })
                : '';
        let hasPhoneNumber =
            '&hasPhoneNumber=' + filter.customerFilter.hasPhoneNumber;
        let hasEmail = '&hasEmail=' + filter.customerFilter.hasEmail;

        let apiString = `${customerType}${hasPhoneNumber}${hasEmail}`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;
        if (
            filter.customerFilter.customerType.length > 0 ||
            filter.customerFilter.hasEmail != null ||
            filter.customerFilter.hasPhoneNumber != null
        ) {
            isActive = true;
        }
        return isActive;
    };

    useEffect(() => {
        // If someone has been selected make it the only visible option
        if (customerDetails?.id) {
            let singleRow = [];
            singleRow.push(customerDetails);
            setResultsListAltered(singleRow);
        } else {
            setResultsListAltered(resultsList);
        }
    }, [customerDetails, resultsList]);

    const handleSelectCustomer = (user) => {
        if (user.id === customerDetails.id) {
            setCustomerDetails({});
        } else {
            setCustomerDetails(user);
        }
    };

    const handleAddCustomer = (user) => {
        // Set Customer Details with the data from newly created customer
        // Reset filter which will trigger a refresh of the Customer Table
        // Close the drawer
        setCustomerDetails(user);
        setFilter({ ...clearedFilter });
        setCustomerDrawerOpen(false);
    };

    return (
        <>
            <Grid container spacing={1} style={{ height: 'fit-content' }}>
                <Grid item xs={12}>
                    <TableSearch
                        searchTitle="Search Customers"
                        showFilter={true}
                        showPagination={true}
                        showPaper={true}
                        filter={filter}
                        setFilter={setFilter}
                        clearedFilter={clearedFilter}
                        sort={sort}
                        handleRequestCreate={handleRequestCreate}
                        route="customerSearchNextGen"
                        setResultsList={setResultsList}
                        isFilterActive={isFilterActive}
                    >
                        <CustomerTableFilter
                            filter={filter}
                            setFilter={setFilter}
                            isFilterActive={isFilterActive}
                            hideDebtor={true}
                        />
                    </TableSearch>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        overflowY: 'scroll',
                        maxHeight: '55vh'
                    }}
                >
                    <Paper>
                        <DataTable
                            columns={columns}
                            sort={sort}
                            setSort={setSort}
                        >
                            {resultsListAltered.map((row) => (
                                <TableRow key={row.id}>
                                    <DataCell>
                                        <IconButton
                                            disabled={disabled}
                                            onClick={() =>
                                                handleSelectCustomer(row)
                                            }
                                        >
                                            {customerDetails.id === row.id ? (
                                                <CheckCircle />
                                            ) : (
                                                <CheckCircleOutline />
                                            )}
                                        </IconButton>
                                    </DataCell>
                                    <DataCell>
                                        {row.companyName != null &&
                                        row.companyName !== '' &&
                                        row.customerType === 'Trade'
                                            ? row.companyName
                                            : row.firstName +
                                              ' ' +
                                              row.lastName}
                                    </DataCell>
                                    <DataCell>{row.email}</DataCell>
                                    <DataCell>{row.phoneNumber}</DataCell>
                                    <DataCell>
                                        <IconButton
                                            onClick={() => {
                                                setViewCustomer(row);
                                                setCustomerDrawerOpen(true);
                                            }}
                                        >
                                            <Reorder />
                                        </IconButton>
                                    </DataCell>
                                </TableRow>
                            ))}
                        </DataTable>
                    </Paper>
                </Grid>
            </Grid>

            <Tooltip placement="left" title="Add Customer">
                <div
                    style={{
                        margin: 0,
                        top: 'auto',
                        right: 85,
                        bottom: 10,
                        left: 'auto',
                        position: 'fixed',
                        zIndex: 1
                    }}
                >
                    <Fab
                        color="primary"
                        onClick={(e) => setCustomerDrawerOpen(true)}
                    >
                        <Add />
                    </Fab>
                </div>
            </Tooltip>

            <Drawer
                openDrawer={customerDrawerOpen}
                setOpenDrawer={setCustomerDrawerOpen}
                title={viewCustomer?.id ? 'Update Customer' : 'New Customer'}
                subTitle={''}
                width="40vw"
            >
                <CustomerDrawerContent
                    customer={viewCustomer?.id ? viewCustomer : customerDetails}
                    setCustomer={
                        viewCustomer?.id ? setViewCustomer : setCustomerDetails
                    }
                    setSelectedEntity={handleAddCustomer}
                    refreshOnUpdate={true}
                />
            </Drawer>
        </>
    );
};

export default CustomerTab;
