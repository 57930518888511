import { Dispatch, SetStateAction } from 'react';
import {
    Typography,
    TableRow,
    TableCell,
    Drawer,
    Box,
    Table,
    TableHead,
    Divider,
    Chip
} from '@mui/material';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// INTERFACES
import { BankTransaction } from '../../../global/interfaces/AdminInterface';

interface TransactionDrawerParams {
    drawerData: BankTransaction[];
    drawerTitle: string;
    drawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const TransactionDrawer = ({
    drawerData: transactions,
    drawerTitle,
    drawerOpen,
    setDrawerOpen
}: TransactionDrawerParams) => {
    return (
        <Drawer
            anchor={'right'}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            style={{ zIndex: 1200 }}
        >
            <Box sx={{ width: '40vw', padding: '20px' }} role="presentation">
                <Typography variant="h5">{drawerTitle}</Typography>
                <Box height={'10px'}></Box>
                <Divider></Divider>
                <Box height="20px"></Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="15%">
                                <strong>Reference</strong>
                            </TableCell>
                            <TableCell width="15%">
                                <strong>Date</strong>
                            </TableCell>
                            <TableCell width="15%">
                                <strong>Amount</strong>
                            </TableCell>
                            <TableCell width="15%"></TableCell>
                        </TableRow>
                    </TableHead>
                    {transactions.map((transaction) => {
                        return (
                            <TableRow>
                                <TableCell>{transaction.reference}</TableCell>
                                <TableCell>
                                    {transaction.dateReceived}
                                </TableCell>
                                <TableCell>
                                    {CurrencyFormatter(transaction.amount)}
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        variant="outlined"
                                        color={
                                            transaction.reconciled
                                                ? 'success'
                                                : 'error'
                                        }
                                        label={
                                            transaction.reconciled
                                                ? 'Reconciled'
                                                : 'Not Reconciled'
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </Table>
            </Box>
        </Drawer>
    );
};

export default TransactionDrawer;
