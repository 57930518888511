import { Dispatch, SetStateAction } from 'react';
import { PurchaseOrder } from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const HandleSubmitPurchaseOrder = (
    selectedPurchaseOrder: PurchaseOrder,
    setSelectedPurchaseOrder: Dispatch<SetStateAction<PurchaseOrder>>,
    showSnackbar: showSnackbar,
    noRefresh?: boolean,
    purchaseOrders?,
    setPurchaseOrders?,
    setDrawerOpen?,
    serviceDetails?,
    setServiceDetails?
) => {
    if (!selectedPurchaseOrder?.ServiceId) {
        showSnackbar(
            'You must select an RO that this purchase order links to.',
            '',
            'error'
        );
        return;
    }
    if (!selectedPurchaseOrder?.SupplierId) {
        showSnackbar(
            'You must select a Creditor that this purchase order links to.',
            '',
            'error'
        );
        return;
    }
    if (!selectedPurchaseOrder?.amount || selectedPurchaseOrder?.amount < 0) {
        showSnackbar(
            'You must enter a valid amount for the purchase order.',
            '',
            'error'
        );
        return;
    }
    if (!selectedPurchaseOrder?.orderReference) {
        showSnackbar(
            'You must enter an order reference the purchase order.',
            '',
            'error'
        );
        return;
    }

    api.post(
        `newPurchaseOrder?SiteId=${localStorage.getItem('SiteId')}`,
        selectedPurchaseOrder
    ).then((res) => {
        if (res.status === 200) {
            setSelectedPurchaseOrder({
                ...selectedPurchaseOrder,
                id: res.data.id,
                UserId: res.data.UserId,
                User: res.data.User
            });
            if (noRefresh) {
                let currentPurchaseOrders = [...purchaseOrders];
                currentPurchaseOrders.unshift({
                    ...selectedPurchaseOrder,
                    id: res.data.id,
                    UserId: res.data.UserId,
                    User: res.data.User
                });
                setPurchaseOrders(currentPurchaseOrders);
                setServiceDetails({
                    ...serviceDetails,
                    subletTotal: res.data.newSubletCharge
                });
                setDrawerOpen(false);
            } else {
                window.location.reload();
            }
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleSubmitPurchaseOrder;
