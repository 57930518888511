import api from '../../../../api';
import { showSnackbar } from '../interfaces/GlobalInterface';

const UnreconcileTransaction = (query: string, showSnackbar: showSnackbar) => {
    api.put(`unreconcileTransaction?${query}`).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Transaction Unreconciled',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default UnreconcileTransaction;
