import { Step } from '../../interface';

export const ServicePaymentSteps: Step[] = [
    {
        label: "Click 'Service' > 'Payment' in the sidebar to navigate to the service payment page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/payment/payment-01.png'
    },
    {
        label: "Enter the service ID and press the 'Continue' button to load the payment details of that service.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/payment/payment-02.png'
    },
    {
        label: "The paying customer will be selected automatically. If it is an Insurance service type, the insurance company will be selected by default; you can change this to 'Customer' if needed.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/payment/payment-04.png'
    },
    {
        label: 'Select the payment method, till. Specify whether the payment is a deposit or a standard payment. If the job is Incomplete you can only select Deposit, clicking on Standard will open a dialog asking to set the booking as Complete',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/payment/payment-05.png'
    },
    {
        label: "Adjust the payment amount if needed. Click the 'Add' icon to add the payment line.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/payment/payment-06.png'
    },
    {
        label: "Click the 'Save' icon to save the payment. A success message will be displayed, and a payment receipt will be generated and opened in a new tab.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/payment/payment-07.png'
    },
    {
        label: "Once the service is fully paid, the service status will be automatically be updated to 'Finalised'.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/payment/payment-08.png'
    },
    {
        label: 'In the Bookings page, clicking the Open Payments button on a service line will also redirect you to the service payment page of that service.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/payment/payment-09.png'
    },
    {
        label: 'You can also find the Open Payments button on the booking details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/service/payment/payment-10.png'
    }
];
