// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Template } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const TemplateValidation = (
    template: Template,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    setTemplateError: Dispatch<
        SetStateAction<{
            subject: boolean;
            department: boolean;
            messageBody: boolean;
            type: boolean;
        }>
    >
) => {
    let isError = false;
    let templateError = {
        subject: false,
        department: false,
        messageBody: false,
        type: false
    };

    // Check if any required data is missing or incomplete
    if (template.subject === '' || !template.subject) {
        templateError.subject = true;
        isError = true;
    }
    if (template.department === '' || !template.department) {
        templateError.department = true;
        isError = true;
    }
    if (template.messageBody === '' || !template.messageBody) {
        templateError.messageBody = true;
        isError = true;
    }
    if (template.type === '' || !template.type) {
        templateError.type = true;
        isError = true;
    }

    if (!isError) {
        // If there is no error can continue on
        return false;
    } else {
        // If there is an error alert the user
        setTemplateError(templateError);
        showSnackbar(
            'Whoops! There appears to be some missing information!',

            'Please ensure you have filled out all required fields and then try again.',
            'error'
        );
        setReadOnly(false);
        setLoading(false);
        return true;
    }
};

export default TemplateValidation;
