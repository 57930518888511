import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Table } from '../../stockOrder/interfaces/StockOrderInterface';

const ImportBackordersToTable = async (
    selectedSupplier: number,
    selectedSite: number,
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    importedSupplier: number[],
    setImportedSupplier: Dispatch<SetStateAction<number[]>>,
    resultsList,
    setResultsList,
    setSelectedIndex: Dispatch<SetStateAction<number>>,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    await api
        .get(
            'generateBackorder?Ordered=false&site=' +
                selectedSite +
                '&Supplier=' +
                selectedSupplier
        )
        .then(async (res) => {
            let backorderLines = res.data;
            // Go through all backorders and check if there is already a table with the same supplier
            for (let backorder of backorderLines) {
                // Initialize backorderAdded, it will keep track of what we did while going through all the pre-existing tables
                let backorderAdded = false;
                for (let table of tables) {
                    // If a table with the same supplier exist, add the backorder to the orderLines
                    if (
                        backorder.supplier === table.supplierId &&
                        backorder.OrderType === table.OrderType
                    ) {
                        let orderIn = false;

                        for (let order of table.orderLines) {
                            // Go through all parts for the current supplier
                            if (!order.linkedOrders) {
                                // If linkedOrders doesn't exist yet, create it to store all orderId
                                order.linkedOrders = [];
                            }
                            if (!order.linkedOrdersQuantity) {
                                order.linkedOrdersQuantity = [];
                            }
                            if (backorder.id === order.id) {
                                // Check if the part is already in the table for this supplier

                                // Add the OrderId to the linkedOrders array
                                order.linkedOrders.push(backorder.orderId);
                                order.linkedOrdersQuantity.push(
                                    backorder.quantity
                                );

                                // Update quantity and total for this line
                                order.quantity += backorder.quantity;
                                let costPrice = order.costPriceDaily
                                    ? order.costPriceDaily
                                    : order.costPriceAverage;
                                order.rowTotal = order.quantity * costPrice;

                                // Set BackorderAdded to true to indicate that the supplier table was existent, and orderIn as true to indicate that the part was already present in the table
                                backorderAdded = true;
                                orderIn = true;
                                break;
                            }
                        }

                        if (orderIn === false) {
                            // Part not present in the supplier table, so we add it
                            let lineToPush = backorder;

                            // Create the linkedOrders array and push the current orderId in
                            lineToPush.linkedOrders = [];
                            lineToPush.linkedOrdersQuantity = [];
                            lineToPush.linkedOrders.push(backorder.orderId);
                            lineToPush.linkedOrdersQuantity.push(
                                backorder.quantity
                            );

                            // Create a new rowId for this line, set up to the current length of the table + 1
                            lineToPush.rowId =
                                table.orderLines[table.orderLines.length - 1]
                                    .rowId + 1;

                            // Calculate total for this row using costPrice and quantity of the backorder
                            let costPrice = backorder.costPriceDaily
                                ? backorder.costPriceDaily
                                : backorder.costPriceAverage;
                            lineToPush.rowTotal =
                                costPrice * backorder.quantity;

                            // Set backorderAdded to keep track that the supplier Table was existent
                            backorderAdded = true;

                            // Push the line to the table
                            table.orderLines.push(lineToPush);
                        }
                    }
                }

                // If no table was found, create a new table
                if (backorderAdded === false) {
                    let lineToPush = backorder;

                    // set rowId to 1 since it's the first line of this new table, and update rowTotal
                    lineToPush.rowId = 1;
                    let costPrice = backorder.costPriceDaily
                        ? backorder.costPriceDaily
                        : backorder.costPriceAverage;
                    lineToPush.rowTotal = costPrice * backorder.quantity;

                    // Create the linkedOrders array and push the current orderId in
                    lineToPush.linkedOrders = [];
                    lineToPush.linkedOrdersQuantity = [];
                    lineToPush.linkedOrders.push(lineToPush.orderId);
                    lineToPush.linkedOrdersQuantity.push(backorder.quantity);

                    await api
                        .get('creditorNextGen/' + lineToPush.supplier)
                        .then((res) => {
                            let supplier = res.data;

                            let newTable = {
                                tableId: tables.length + 1,
                                supplierId: lineToPush.supplier,
                                orderReference: '',
                                supplierName: backorder.supplierName,
                                Supplier: supplier,
                                totalCost: costPrice,
                                orderLines: [lineToPush],
                                OrderType: lineToPush.OrderType
                            };
                            // Push new tables to the list of tables
                            tables.push(newTable);
                        });
                }
            }
            setTables([...tables]); // Update the tables
            let tempImportedSupplier = importedSupplier;
            tempImportedSupplier.push(selectedSupplier);
            setImportedSupplier(tempImportedSupplier);
            setButtonLoading(false);
            setSelectedIndex(0);
            setResultsList(
                resultsList.filter(
                    (x) => !tempImportedSupplier.includes(x.Supplier.id)
                )
            );
        });
};

export default ImportBackordersToTable;
