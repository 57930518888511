import {
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';

const WithholdingDeclarationForm = ({ control, watch, setValue }) => {
    return (
        <Grid container spacing={2} margin={'10px'}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom component="div">
                    Declaration for the Purposes of the PAYG (Withholding Tax)
                    Legislation
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    (Tick as applicable and initial deletions and deletions)
                </Typography>
                <Typography variant="body1">
                    The supplier of the trade-in vehicle clearly declares that
                    it:
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="mainTaxInvoiceOption"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup {...field}>
                            <FormControlLabel
                                value="provideTaxInvoice"
                                control={<Radio />}
                                label="Will provide a Tax Invoice for the supply of the above described trade-in vehicle as it holds an ABN which is"
                            />
                            <TextField
                                {...control.register('abnForGst')}
                                variant="standard"
                                margin="dense"
                                placeholder="Enter ABN"
                            />

                            <Typography variant="body1">
                                and it is registered for GST purposes; or
                            </Typography>
                            <FormControlLabel
                                value="notProvideTaxInvoice"
                                control={<Radio />}
                                label="Will not be providing a Tax Invoice for the supply of the above described trade-in vehicle because either:"
                            />
                        </RadioGroup>
                    )}
                />

                {/* // only display below options when notProvideTaxInvoice is selected */}
                {watch('mainTaxInvoiceOption') === 'notProvideTaxInvoice' && (
                    <>
                        <Controller
                            name="subTaxInvoiceOption"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup {...field} sx={{ marginLeft: 4 }}>
                                    <FormControlLabel
                                        value="isIndividual"
                                        control={<Radio size="small" />}
                                        label="The supply is made by it as an individual and is wholly private or domestic in nature; or"
                                    />
                                    <FormControlLabel
                                        value="notRegisteredForGST"
                                        control={<Radio size="small" />}
                                        label="It holds an ABN which is"
                                    />
                                    <TextField
                                        {...control.register('abnNotForGST')}
                                        variant="standard"
                                        margin="dense"
                                        placeholder="Enter ABN"
                                    />
                                    <Typography variant="body1">
                                        but is not registered for GST purposes.
                                    </Typography>
                                </RadioGroup>
                            )}
                        />
                    </>
                )}
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
            </Grid>
        </Grid>
    );
};

export default WithholdingDeclarationForm;
