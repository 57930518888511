const CalculateMiscTotal = (service) => {
  let total = 0;

  // Add the sundries and environmental levy
  total += parseFloat(service.sundries) + parseFloat(service.environmentalLevy);

  // Loop through the misc costs
  service.miscCosts.forEach((cost) => {
    total += parseFloat(cost.amount);
  });

  return total;
};

export default CalculateMiscTotal;
