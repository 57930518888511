// IMPROTS
import { useState } from 'react';
import { TableRow, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import InvoiceHeader from './InvoiceHeader';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataTable from '../../../../global/tableComponents/DataTable';
import Fab from '../../../../global/Fab';

// LOGIC
import SubmitGenericInvoice from '../../logic/SubmitGenericInvoice';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
// INTERFACES
import { Creditor, Site } from '../../../../global/interfaces/GeneralInterface';
import { Account } from '../../../../global/interfaces/AdminInterface';
import {
    PurchaseOrder,
    Service
} from '../../../../global/interfaces/ServiceInterface';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Account?: Account;
    Vehicle?: Vehicle;
    PurchaseOrder?: PurchaseOrder;
    type: string;
    documentReference: string;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
}
interface InvoiceLine {
    id: number;
    Account?: Account;
    Vehicle?: Vehicle;
    Service?: Service;
    amount: string;
    description: string;
}

const GenericInvoice = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false);
    const [responseCode, setResponseCode] = useState<number>();

    const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([]);
    const [invoiceObject, setInvoiceObject] = useState<InvoiceObject>({
        Creditor: null,
        Site: null,
        Account: null,
        Vehicle: null,
        PurchaseOrder: null,
        type: 'Account',
        documentReference: null,
        documentDate: null,
        dueDate: null,
        GSTtype: null,
        documentTotal: null
    });

    const columns = [
        { id: 0, label: '', width: 325 },
        { id: 1, label: 'Description', width: 325 },
        { id: 2, label: 'Amount', width: 250 },
        { id: 3, label: '', width: 100 }
    ];

    // Display the appropiate label depending on the entity
    const getEntityName = (line: InvoiceLine) => {
        if (line?.Account) return line?.Account?.name;
        else if (line?.Vehicle) return line?.Vehicle?.vehicleName;
        else if (line?.Service) return line?.Service?.id;
        else return 'Misc';
    };

    // Handle removing a line from the invoice
    const removeLine = (lineId: number) => {
        let currentLines = [...invoiceLines];
        currentLines = currentLines.filter((x) => x.id !== lineId);
        setInvoiceLines(currentLines);
    };

    // Get the total of all the entered lines
    const totalLines = () => {
        let total = 0;
        invoiceLines.forEach((line) => {
            total += parseFloat(line.amount);
        });
        return total;
    };

    // submit the invoice only when isSubmittingInvoice is false
    const handleSubmitGenericInvoice = () => {
        if (!isSubmittingInvoice) {
            SubmitGenericInvoice(
                invoiceObject,
                invoiceLines,
                totalLines(),
                responseCode,
                showSnackbar,
                setIsSubmittingInvoice
            );
        }
    };

    return (
        <>
            <InvoiceHeader
                invoiceObject={invoiceObject}
                setInvoiceObject={setInvoiceObject}
                invoiceLines={invoiceLines}
                setInvoiceLines={setInvoiceLines}
                responseCode={responseCode}
                setResponseCode={setResponseCode}
                showSnackbar={showSnackbar}
            />
            <br />
            {invoiceLines.length > 0 && (
                <Paper>
                    <DataTable columns={columns}>
                        {invoiceLines.map((line) => (
                            <TableRow>
                                <DataCell>{getEntityName(line)}</DataCell>
                                <DataCell>{line.description}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.amount)}
                                </DataCell>
                                <DataCell>
                                    <IconButton
                                        onClick={() => removeLine(line.id)}
                                    >
                                        <Close />
                                    </IconButton>
                                </DataCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <DataCell></DataCell>
                            <DataCell>
                                <b>Total</b>
                            </DataCell>
                            <DataCell>
                                <b>{CurrencyFormatter(totalLines())}</b>
                            </DataCell>
                            <DataCell></DataCell>
                        </TableRow>
                    </DataTable>
                </Paper>
            )}

            <Fab
                noSession={true}
                editIcon={false}
                onClick={handleSubmitGenericInvoice}
            />
        </>
    );
};

export default withSnackbar(GenericInvoice);
