import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleResetPassword = (
    passwordData: { old: string; new: string; confirm: string },
    setErrors: Dispatch<
        SetStateAction<{
            old: boolean;
            new: boolean;
            confirm: boolean;
            match: boolean;
        }>
    >,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    loginDetails?: {
        username: string;
        password: string;
        usernameError: boolean;
        passwordError: boolean;
        usernameErrorMessage: string;
        passwordErrorMessage: string;
    }
) => {
    setButtonLoading(true);
    const newErrors = {
        old: false,
        new: false,
        confirm: false,
        match: false
    };

    if (passwordData.old === '' || passwordData.old == null)
        newErrors.old = true;
    if (passwordData.new === '' || passwordData.new == null)
        newErrors.new = true;
    if (passwordData.confirm === '' || passwordData.confirm == null)
        newErrors.confirm = true;
    if (passwordData.new !== passwordData.confirm) newErrors.match = true;

    setErrors(newErrors);

    if (
        !newErrors.old &&
        !newErrors.new &&
        !newErrors.confirm &&
        !newErrors.match
    ) {
        if (loginDetails) {
            api.post('changePasswordLogin', {
                passwordData: passwordData,
                loginDetails: loginDetails
            }).then((res) => {
                if (res.status === 200) {
                    showSnackbar('Password successfully updated.');
                    window.location.href = '/';
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact your IT department.',
                        'error'
                    );
                    setButtonLoading(false);
                }
            });
        } else {
            api.post('changePasswordDialog', passwordData).then((res) => {
                if (res.status === 200) {
                    showSnackbar('Password successfully updated.');
                    window.location.href = '/';
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact your IT department.',
                        'error'
                    );
                    setButtonLoading(false);
                }
            });
        }
    } else {
        setButtonLoading(false);
    }
};

export default HandleResetPassword;
