import DocumentationPage from '../DocumentationPage';
import {
    EditVehicleSalesSteps,
    SearchVehicleSalesSteps,
    ViewVehicleSaleLogSteps,
    TextCustomerSteps
} from '../data/vehicleSales/vehicleSales';

const VehicleSalesSupportPage = () => {
    const contentSections = [
        {
            id: 'search-vehicle-sales',
            title: 'Search Vehicle Sales',
            href: '/support/vehicles/sales/#search-vehicle-sales',
            description:
                'To search or filter Vehicle Sales, follow these steps:',
            steps: SearchVehicleSalesSteps
        },
        {
            id: 'edit-vehicle-sales',
            title: 'View and edit existing Vehicle Sales',
            href: '/support/vehicles/sales/#edit-vehicle-sales',
            description:
                'To view or edit details of an exsiting Vehicle Sales, follow these steps:',
            steps: EditVehicleSalesSteps
        },
        {
            id: 'view-vehicle-sale-log',
            title: 'View Vehicle Sale History',
            href: '/support/vehicles/sales/#view-vehicle-sale-log',
            description:
                'To view the history of changes made to a Vehicle Sale, follow these steps:',
            steps: ViewVehicleSaleLogSteps
        },
        {
            id: 'text-customer',
            title: 'Text customer',
            href: '/support/vehicles/sales/#text-customer',
            description:
                'To send a text message to a customer, follow these steps:',
            steps: TextCustomerSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Vehicle Sales"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default VehicleSalesSupportPage;
