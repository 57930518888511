import { Step } from '../../interface';

export const CreditorReconciliationSteps: Step[] = [
    {
        label: 'In the sidebar, click Admin -> Reconciliators -> Creditor Reconciliation to navigate to the Creditor Reconciliation page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-01.png'
    },
    {
        label: "Under the Unconfirmed Invoices tab, list all the unconfirmed invoices for each creditor. Enter the creditor's name in the search bar and press Enter to search.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-02.png'
    },
    {
        label: "Click on the creditor's name to view their unconfirmed invoices from each site.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-03.png'
    },
    {
        label: 'Click the checkbox to select the invoices to be moved to the Ready Invoices tab.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-04.png'
    },
    {
        label: 'Click "Ready Selected Invoices" in the speed dial menu to move the selected invoices to the Ready Invoices tab.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-05.png'
    },
    {
        label: "Under the Ready Invoices tab, list all the ready invoices for each creditor. Enter the creditor's name in the search bar and press Enter to search.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-06.png'
    },
    {
        label: 'You will find the two invoices moved from Unconfirmed Invoices tab. To move them back to the Unconfirmed Invoices tab, select invoices then click "Un-ready Selected Invoices" in the speed dial menu.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-07.png'
    },
    {
        label: 'If you wish to finalize the invoices, click the checkbox to select the invoices. Adjust the payment amount if needed. Repeat the process to finalise invocies from more creditors.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-08.png'
    },
    {
        label: 'In the Finalise & Pay tab, list all the finalized and to-be-paid invoices for each selected creditor. Ensure creditor has valid BSB, account number and reference. Click the Printer icon to print the Payment Remittance.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-09.png'
    },
    {
        label: "Click the 'Generate ABA' button to generate an ABA file for the selected invoices. The ABA file will be downloaded.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-10.png'
    },
    {
        label: 'After finalization, find the history of finalized invoices in the Creditor Payments tab.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-11.png'
    },
    {
        label: 'Here, you can regenerate the ABA file or payment remittance by clicking the corresponding buttons.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-12.png'
    },
    {
        label: 'Click the invoice ID to view the invoice details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-13.png'
    },
    {
        label: 'Generate a creditor balance report in the speed dial menu; a PDF file will be downloaded.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/creditorReconciliation/creditor-recon-14.png'
    }
];
