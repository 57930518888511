import DocumentationPage from '../../DocumentationPage';
import { CreditorReconciliationSteps } from '../../data/admin/creditorReconciliation';

const CreditorReconciliationSupportPage = () => {
    const contentSections = [
        {
            id: 'creditor-reconciliation',
            title: 'Creditor Reconciliation',
            href: '/support/admin/creditors/#add-creditor',
            description:
                'This guide outlines the steps for ready invoices (move invoice from Unconfirmed Invoices to Ready Invoices), unready invoices, finalize and pay for ready invoices, generate an ABA file, regenerate ABA file or payment remittance, and generate creditor balance report, .',
            steps: CreditorReconciliationSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Creditor Reconciliation"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default CreditorReconciliationSupportPage;
