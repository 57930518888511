import { Button, Grid, Typography } from '@mui/material';
import Paper from '../../../global/Paper';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { Dispatch, SetStateAction } from 'react';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}

interface GroupedOnlineOrders {
    onHold: GroupedOnlineOrder[];
    onBackorder: GroupedOnlineOrder[];
    newOrders: GroupedOnlineOrder[];
    pendingDispatch: GroupedOnlineOrder[];
}

interface CountersProps {
    groupedOrders: GroupedOnlineOrders;
    setSelectedGroupedOrder: Dispatch<SetStateAction<GroupedOnlineOrder[]>>;
    groupedTitle: string;
    setGroupedTitle: Dispatch<SetStateAction<String>>;
}

const Counters = ({
    groupedOrders,
    setSelectedGroupedOrder,
    groupedTitle,
    setGroupedTitle
}: CountersProps) => {
    const handleButtonClick = (title: string, array: GroupedOnlineOrder[]) => {
        setSelectedGroupedOrder(array);
        setGroupedTitle(title);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">New Orders</Typography>
                        <Typography variant="h5">
                            {groupedOrders.newOrders.length}
                        </Typography>
                        <Button
                            disabled={
                                groupedTitle === 'New Orders' ? true : false
                            }
                            onClick={() =>
                                handleButtonClick(
                                    'New Orders',
                                    groupedOrders.newOrders
                                )
                            }
                        >
                            View Orders
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">On Hold</Typography>
                        <Typography variant="h5">
                            {groupedOrders.onHold.length}
                        </Typography>
                        <Button
                            disabled={groupedTitle === 'On Hold' ? true : false}
                            onClick={() =>
                                handleButtonClick(
                                    'On Hold',
                                    groupedOrders.onHold
                                )
                            }
                        >
                            View Orders
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">On Backorder</Typography>
                        <Typography variant="h5">
                            {groupedOrders.onBackorder.length}
                        </Typography>
                        <Button
                            disabled={
                                groupedTitle === 'On Backorder' ? true : false
                            }
                            onClick={() =>
                                handleButtonClick(
                                    'On Backorder',
                                    groupedOrders.onBackorder
                                )
                            }
                        >
                            View Orders
                        </Button>
                    </Paper>
                </Grid>

                <Grid item xs={3} textAlign="center">
                    <Paper>
                        <Typography variant="h6">Pending Dispatch</Typography>
                        <Typography variant="h5">
                            {groupedOrders.pendingDispatch.length}
                        </Typography>
                        <Button
                            disabled={
                                groupedTitle === 'Pending Dispatch'
                                    ? true
                                    : false
                            }
                            onClick={() =>
                                handleButtonClick(
                                    'Pending Dispatch',
                                    groupedOrders.pendingDispatch
                                )
                            }
                        >
                            View Orders
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default Counters;
