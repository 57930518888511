import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';

// COMPONENT
import DataCell from '../../tableComponents/DataCell';
import RoleCheck from '../../RoleCheck';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// LOGIC
import { DateFormatter } from '../../logic/Formatters';
import QuickSaleUpdate from '../../../parts/newSale/logic/updateFunctions/QuickSaleUpdate';

// INTERFACE
import { PaymentLine } from '../../interfaces/GeneralInterface';
import { Order } from '../../interfaces/PartsInterface';
import { VehicleSale } from '../../interfaces/VehicleSaleInterface';
import { Service } from '../../interfaces/ServiceInterface';
import { showSnackbar } from '../../interfaces/GlobalInterface';

interface PaymentDateProps {
    payment: PaymentLine;
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    saleDetails: Order;
    oldSale: Order;
    vehicleSaleDetails: VehicleSale;
    serviceDetails: Service;
    showSnackbar: showSnackbar;
    disabled: boolean;
}
const PaymentDate = ({
    payment,
    paymentLines,
    setPaymentLines,
    saleDetails,
    oldSale,
    vehicleSaleDetails,
    serviceDetails,
    showSnackbar,
    disabled
}: PaymentDateProps) => {
    // Update a payment type
    const updatePaymentDate = (id: number, value: Dayjs) => {
        var index = paymentLines.findIndex((x) => x.id === id);
        let rows = [...paymentLines];
        let row = { ...rows[index] };

        row.date = value.format('YYYY-MM-DD');
        row.timestamp =
            value.format('YYYY-MM-DD') + 'T' + row.timestamp.split('T')[1];
        rows[index] = row;
        setPaymentLines(rows);

        if (
            saleDetails?.id &&
            saleDetails?.status === 'Sale' &&
            oldSale.status === 'Sale'
        ) {
            QuickSaleUpdate(saleDetails.id, rows, showSnackbar, 'part');
        } else if (
            vehicleSaleDetails?.id &&
            vehicleSaleDetails?.saleStatus === 'Finalised'
        ) {
            QuickSaleUpdate(
                vehicleSaleDetails.id,
                rows,
                showSnackbar,
                'vehicle'
            );
        } else if (
            serviceDetails?.id &&
            serviceDetails?.serviceComplete === 'Finalised'
        ) {
            QuickSaleUpdate(serviceDetails.id, rows, showSnackbar, 'service');
        }
    };

    return (
        <>
            <DataCell>
                {payment.type === 'account' ? (
                    saleDetails?.id ? (
                        saleDetails?.finalisedAt ? (
                            DateFormatter(saleDetails.finalisedAt)
                        ) : (
                            'N/A until this order is finalised'
                        )
                    ) : serviceDetails?.finalisedDate ? (
                        DateFormatter(serviceDetails.finalisedDate)
                    ) : (
                        'N/A until this service is finalised'
                    )
                ) : payment?.removed ? (
                    <>
                        {DateFormatter(String(payment?.removedOn))}
                        <br />
                        <span
                            style={{ color: 'grey' }}
                        >{`Removed By: ${payment?.removedBy}`}</span>
                    </>
                ) : (payment.type !== 'directDeposit' &&
                      payment.date !== moment().format('YYYY-MM-DD')) ||
                  payment.reconciled ? (
                    `${moment(payment.date).format('DD/MM/YYYY')} ${moment(payment.timestamp).format('h:mm A')}`
                ) : (
                    <RoleCheck
                        permission={'edit_salePayment'}
                        component={
                            <>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    <DatePicker
                                        disabled={
                                            disabled ||
                                            (payment.type !== 'directDeposit' &&
                                                payment.date !==
                                                    moment().format(
                                                        'YYYY-MM-DD'
                                                    ))
                                        }
                                        value={dayjs(payment?.date)}
                                        onChange={(newValue) =>
                                            updatePaymentDate(
                                                payment.id,
                                                newValue
                                            )
                                        }
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                error: false,
                                                size: 'small',
                                                InputLabelProps: {
                                                    shrink: true
                                                },
                                                inputProps: {
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                                <br />
                                {moment(payment.timestamp).format('h:mm A')}
                            </>
                        }
                        nullComponent={
                            <Typography
                                variant="body2"
                                sx={{
                                    color: payment.inactive ? '#808080' : ''
                                }}
                            >
                                {DateFormatter(String(payment?.date))}
                            </Typography>
                        }
                    />
                )}
            </DataCell>
        </>
    );
};

export default PaymentDate;
