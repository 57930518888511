// IMPORTS
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    DialogActions,
    Button,
    Grid,
    TextField,
    IconButton,
    Chip,
    CircularProgress
} from '@mui/material';
import { Done } from '@mui/icons-material';
// COMPONENTS
import { withSnackbar } from './WrappingSnackbar';
import Autocomplete from './Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
// LOGIC
import GetPartSearchList from './logic/GetPartSearchList';
import CheckPartSearchPart from './logic/CheckPartSearchPart';
import GetAllSuppliers from './databaseLogic/GetAllSuppliers';
import HandleAddPart from './logic/HandleAddPart';
import { CurrencyFormatter } from './logic/Formatters';
// INTERFACES
import { Creditor, Site } from './interfaces/GeneralInterface';
import { showSnackbar } from './interfaces/GlobalInterface';

interface PartSearchProps {
    HandleAdd: (selectedPart, supplier?: Creditor) => any;
    Supplier?: Creditor;
    size?: string;
    isDisabled?: boolean;
    checkBackorders?: boolean;
    useAutocomplete?: boolean;
    SiteFrom?: Site;
    SiteTo?: Site;
    SelectedSite?: number;
    showSnackbar: showSnackbar;
    cypressLabel?: string;
}

interface SelectedPart {
    unitData: { SupplierIds?: { SupplierId?: string }[]; partNumber?: string };
    stockData: {};
    supplierData?: Creditor;
}

const clearedNewPart = {
    partNumber: null,
    name: null,
    supplier: {},
    costPriceDaily: null,
    priceRRP: null
};

const PartSearch = ({
    HandleAdd,
    Supplier,
    size,
    isDisabled,
    checkBackorders,
    useAutocomplete,
    SiteFrom,
    SiteTo,
    SelectedSite,
    showSnackbar,
    cypressLabel
}: PartSearchProps) => {
    const currentSiteId = localStorage.getItem('SiteId');
    const [loading, setLoading] = useState<boolean>(false);
    const [partOptions, setPartOptions] = useState([]);

    const [selectedPart, setSelectedPart] = useState<SelectedPart>();

    const [selectedSupplier, setSelectedSupplier] = useState<Creditor>();

    const [partInput, setPartInput] = useState<string>('');
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const [selectSuppliersDialogOpen, setSelectSuppliersDialogOpen] =
        useState<boolean>(false);

    const [multiplePartsOptions, setMultiplePartsOptions] = useState([]);

    const filteredPartsOptions = multiplePartsOptions.filter((part) => {
        return part?.unitData?.SupplierIds?.some(
            (supplier) => String(supplier.SiteId) === currentSiteId
        );
    });
    const displayPartsOptions =
        filteredPartsOptions.length > 0
            ? filteredPartsOptions
            : multiplePartsOptions;

    const [supercessionDialogOpen, setSupercessionDialogOpen] =
        useState<boolean>(false);
    const [supercessionOptions, setSupercessionOptions] = useState([]);

    const [suppliers, setSuppliers] = useState<Creditor[]>([]);
    const [newPartDialogOpen, setNewPartDialogOpen] = useState<boolean>(false);
    const [newPart, setNewPart] = useState(clearedNewPart);
    const [newPartErrors, setNewPartErrors] = useState({
        partNumber: false,
        name: false,
        supplier: false,
        costPrice: false,
        rrp: false
    });

    useEffect(() => {
        GetAllSuppliers(setSuppliers);
        if (Supplier) {
            setNewPart({
                ...newPart,
                partNumber: partInput,
                supplier: Supplier
            });
        } else {
            setNewPart({ ...newPart, partNumber: partInput });
        }
        // eslint-disable-next-line
    }, [newPartDialogOpen]);

    useEffect(() => {
        if (!partInput.includes('|') && useAutocomplete) {
            GetPartSearchList(partInput, setPartOptions);
        }
        // eslint-disable-next-line
    }, [partInput]);

    useEffect(() => {
        if (selectedPart && selectedSupplier) {
            if (selectedPart.supplierData) {
                HandleAdd(selectedPart, selectedPart.supplierData);
            } else {
                HandleAdd(selectedPart, selectedSupplier);
            }
            setNewPartDialogOpen(false);
            setSelectSuppliersDialogOpen(false);
            setNewPart(clearedNewPart);
            setPartInput('');
            setSelectedPart(null);
        }
        // eslint-disable-next-line
    }, [selectedPart, selectedSupplier]);

    const lowestCPD = (part) => {
        let suppliers = part?.unitData?.SupplierIds;
        let lowestId = null;
        let CPD = null;
        for (let supplier of suppliers) {
            if (lowestId == null && supplier.CPD) {
                lowestId = supplier.id;
                CPD = supplier.CPD;
            } else if (CPD > supplier.CPD) {
                lowestId = supplier.id;
                CPD = supplier.CPD;
            }
        }
        return lowestId;
    };

    const lowestRRP = (part) => {
        let suppliers = part?.unitData?.SupplierIds;
        let lowestId = null;
        let RRP = null;
        for (let supplier of suppliers) {
            if (lowestId == null && supplier.RRP) {
                lowestId = supplier.id;
                RRP = supplier.RRP;
            } else if (RRP > supplier.RRP) {
                lowestId = supplier.id;
                RRP = supplier.RRP;
            }
        }
        return lowestId;
    };


    return (
        <>
            {useAutocomplete ? (
                <Autocomplete
                    isDisabled={isDisabled ?? false}
                    size={size ?? 'medium'}
                    options={partOptions}
                    useTwoOptionLabels={true}
                    primaryOptionLabel={'partNumber'}
                    secondaryOptionLabel={'name'}
                    textfieldLabel={'Enter Part Number / Barcode'}
                    textfieldVariant={'outlined'}
                    selectedValue={selectedPart}
                    inputValue={partInput}
                    handleSelectedValueChange={(newValue) =>
                        CheckPartSearchPart(
                            newValue.partNumber,
                            setSelectedPart,
                            setSelectSuppliersDialogOpen,
                            setMultiplePartsOptions,
                            setNewPartDialogOpen,
                            setSupercessionOptions,
                            setSupercessionDialogOpen,
                            setLoading,
                            newValue.SupplierIds[0].SupplierId,
                            undefined,
                            checkBackorders
                        )
                    }
                    handleInputValueChange={(newInputValue) =>
                        setPartInput(newInputValue)
                    }
                    canSearch={true}
                    handleSearchValue={() =>
                        CheckPartSearchPart(
                            partInput,
                            setSelectedPart,
                            setSelectSuppliersDialogOpen,
                            setMultiplePartsOptions,
                            setNewPartDialogOpen,
                            setSupercessionOptions,
                            setSupercessionDialogOpen,
                            setLoading,
                            Supplier?.id,
                            undefined,
                            checkBackorders
                        )
                    }
                />
            ) : loading ? (
                <CircularProgress />
            ) : (
                <TextField
                    autoFocus
                    fullWidth
                    data-cy={cypressLabel ? cypressLabel : ''}
                    disabled={isDisabled ?? false}
                    size="small"
                    label={'Enter Part Number / Barcode'}
                    value={partInput}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setPartInput(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            CheckPartSearchPart(
                                partInput,
                                setSelectedPart,
                                setSelectSuppliersDialogOpen,
                                setMultiplePartsOptions,
                                setNewPartDialogOpen,
                                setSupercessionOptions,
                                setSupercessionDialogOpen,
                                setLoading,
                                Supplier?.id,
                                SelectedSite,
                                checkBackorders,
                                setSelectedSupplier,
                                showSnackbar,
                                SiteFrom,
                                SiteTo
                            );
                        }
                            
                    }}
                />
            )}

            <Dialog
                open={selectSuppliersDialogOpen}
                onClose={() => setSelectSuppliersDialogOpen(false)}
            >
                <DialogTitle>Select Part Suppliers</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        {filteredPartsOptions.length > 1
                            ? ` There were multiple parts/suppliers with that part number found,
            please select the one you want below.`
                            : filteredPartsOptions.length === 0
                              ? `There are no supplier supply the part with that part number in current site. 
            Below, we display parts supplied in all sites.`
                              : ''}
                    </Typography>
                    <List dense>
                        {displayPartsOptions.map((part) => (
                            <React.Fragment key={part?.unitData?.partNumber}>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <b>
                                                {part?.unitData?.partNumber +
                                                    ' - ' +
                                                    part?.unitData?.name}
                                            </b>
                                        }
                                    />
                                </ListItem>
                                {part?.unitData?.SupplierIds?.map(
                                    (supplier, index) => (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            secondaryAction={
                                                <IconButton
                                                    edge="end"
                                                    aria-label="select"
                                                    onClick={() => {
                                                        HandleAdd(
                                                            part,
                                                            supplier
                                                        );
                                                        setSelectSuppliersDialogOpen(
                                                            false
                                                        );
                                                    }}
                                                ></IconButton>
                                            }
                                        >
                                            <ListItemText>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={1}>
                                                        <IconButton
                                                            onClick={() => {
                                                                HandleAdd(
                                                                    part,
                                                                    supplier
                                                                );
                                                                setSelectSuppliersDialogOpen(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            <Done />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <Grid item xs={12}>
                                                                {supplier.name +
                                                                    ' - '}{' '}
                                                                <i>
                                                                    {
                                                                        supplier.SiteName
                                                                    }
                                                                </i>
                                                                {supplier.isPrimary && (
                                                                    <Chip
                                                                        label="Primary"
                                                                        color="primary"
                                                                        size="small"
                                                                        style={{
                                                                            marginLeft: 4
                                                                        }}
                                                                    />
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Typography variant="caption">
                                                                    Cost Price
                                                                    Daily:{' '}
                                                                    {CurrencyFormatter(
                                                                        supplier.CPD ??
                                                                            part
                                                                                .unitData
                                                                                .costPriceDaily
                                                                    )}{' '}
                                                                    {lowestCPD(
                                                                        part
                                                                    ) ===
                                                                    supplier.id ? (
                                                                        <Chip
                                                                            label="Lowest"
                                                                            color="success"
                                                                            size="small"
                                                                            style={{
                                                                                marginLeft: 4
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Typography variant="caption">
                                                                    RRP:{' '}
                                                                    {CurrencyFormatter(
                                                                        supplier.RRP ??
                                                                            part
                                                                                .unitData
                                                                                .priceRRP
                                                                    )}{' '}
                                                                    {lowestRRP(
                                                                        part
                                                                    ) ===
                                                                    supplier.id ? (
                                                                        <Chip
                                                                            label="Lowest"
                                                                            color="success"
                                                                            size="small"
                                                                            style={{
                                                                                marginLeft: 4
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        </ListItem>
                                    )
                                )}
                                <br />
                            </React.Fragment>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>

            <Dialog
                open={supercessionDialogOpen}
                onClose={() => setSupercessionDialogOpen(false)}
            >
                <DialogTitle>Superceded Part Found</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        The part found for this part number has been superceded,
                        please select which parts you want to use below.
                    </Typography>
                    <List dense>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    HandleAdd(
                                        supercessionOptions[0],
                                        supercessionOptions[0]?.unitData
                                            ?.SupplierIds[0]
                                    );
                                    setSupercessionDialogOpen(false);
                                }}
                            >
                                <ListItemText
                                    primary={
                                        supercessionOptions[0]?.unitData
                                            ?.partNumber +
                                        ' - ' +
                                        supercessionOptions[0]?.unitData?.name
                                    }
                                    secondary={
                                        supercessionOptions[0]?.unitData
                                            ?.SupplierIds[0]?.name
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        {supercessionOptions[0]?.unitData.supercessionData.map(
                            (part) => (
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            HandleAdd(
                                                part,
                                                part?.unitData?.SupplierIds[0]
                                            );
                                            setSupercessionDialogOpen(false);
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                part?.unitData?.partNumber +
                                                ' - ' +
                                                part?.unitData?.name
                                            }
                                            secondary={
                                                part?.unitData?.SupplierIds[0]
                                                    ?.name
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )
                        )}
                    </List>
                </DialogContent>
            </Dialog>

            <Dialog
                open={newPartDialogOpen}
                onClose={() => setNewPartDialogOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Part Not Found</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        This part does not exist in Ora, to add it please
                        complete the below fields.
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Part Number"
                                InputLabelProps={{ shrink: true }}
                                value={newPart.partNumber}
                                onChange={(e) =>
                                    setNewPart({
                                        ...newPart,
                                        partNumber: e.target.value
                                    })
                                }
                                error={newPartErrors.partNumber}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label="Name"
                                InputLabelProps={{ shrink: true }}
                                value={newPart.name}
                                onChange={(e) =>
                                    setNewPart({
                                        ...newPart,
                                        name: e.target.value
                                    })
                                }
                                error={newPartErrors.name}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                options={suppliers}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'name'}
                                textfieldLabel={'Supplier'}
                                textfieldVariant={'outlined'}
                                selectedValue={newPart.supplier}
                                handleSelectedValueChange={(newValue) =>
                                    setNewPart({
                                        ...newPart,
                                        supplier: newValue
                                    })
                                }
                                handleInputValueChange={(newInputValue) => null}
                                canSearch={false}
                                isError={newPartErrors.supplier}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Daily Cost Price"
                                InputLabelProps={{ shrink: true }}
                                value={newPart.costPriceDaily}
                                onChange={(e) =>
                                    setNewPart({
                                        ...newPart,
                                        costPriceDaily: e.target.value
                                    })
                                }
                                error={newPartErrors.costPrice}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="RRP"
                                InputLabelProps={{ shrink: true }}
                                value={newPart.priceRRP}
                                onChange={(e) =>
                                    setNewPart({
                                        ...newPart,
                                        priceRRP: e.target.value
                                    })
                                }
                                error={newPartErrors.rrp}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewPartDialogOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            HandleAddPart(
                                newPart,
                                setNewPartErrors,
                                showSnackbar,
                                setButtonLoading,
                                setSelectedPart,
                                setButtonLoading
                            )
                        }
                    >
                        Add Part
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withSnackbar(PartSearch);
