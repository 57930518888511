import { Dispatch, SetStateAction } from "react";
import { Table } from "../interfaces/StockOrderInterface"

const HandleSelectSupplier = (tableId: number, tables: Table[], setTables: Dispatch<SetStateAction<Table[]>>) => {
    var tableIndex = tables.findIndex(x => x.tableId === tableId);
    let rows = [...tables];
    let row = { ...rows[tableIndex] };

    var orderLines = row.orderLines;

    var numberSelected = 0;
    orderLines.forEach(item => {
        if (item.selected) {
            numberSelected += 1;
        }
    })

    if (numberSelected === orderLines.length) {
        orderLines.forEach(item => {
            item.selected = false;
        })
    } else {
        orderLines.forEach(item => {
            item.selected = true;
        })
    }

    rows[tableIndex] = row;
    setTables(rows)
}

export default HandleSelectSupplier;