// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { SpeakerNotes, SpeakerNotesOff } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
// LOGIC
import GetDifferenceString from '../../logic/GetDifferenceString';
// INTERFACES
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
interface OtherPaymentDenominationsProps {
    tillReconciliation: any;
    setTillReconciliation: Dispatch<SetStateAction<any>>;
    readOnly: boolean;
}
const OtherPaymentDenominations = ({
    readOnly,
    tillReconciliation,
    setTillReconciliation
}: OtherPaymentDenominationsProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandedIndex, setExpandedIndex] = useState<number>();

    const updateCountedTotal = (index: number, value: string) => {
        let currentTillLines = [...tillReconciliation.tillLines];
        currentTillLines[index].actual = value;
        setTillReconciliation({
            ...tillReconciliation,
            tillLines: currentTillLines
        });
    };

    const updateTillLine = (index: number, selector: string, value: string) => {
        let currentTillLines = [...tillReconciliation.tillLines];
        currentTillLines[index][selector] = value;

        setTillReconciliation({
            ...tillReconciliation,
            tillLines: currentTillLines
        });
    };

    return (
        <>
            {tillReconciliation.tillLines.map((line, index) => {
                if (
                    line.type !== 'directDeposit' &&
                    line.type !== 'account' &&
                    line.type !== 'cash'
                ) {
                    return (
                        <>
                            <Paper>
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <Typography variant="subtitle1">
                                            {line.label}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Expected:{' '}
                                            {CurrencyFormatter(line.expected)}
                                            &nbsp;
                                            {GetDifferenceString(line)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        {line.type === 'cheque' ? (
                                            <TextField
                                                disabled={readOnly}
                                                fullWidth
                                                size="small"
                                                variant="standard"
                                                label="Envelope Number"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={
                                                    tillReconciliation.envelopeNumber ||
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    setTillReconciliation({
                                                        ...tillReconciliation,
                                                        envelopeNumber:
                                                            e.target.value
                                                    })
                                                }
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            #
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <TextField
                                            disabled={readOnly}
                                            fullWidth
                                            size="small"
                                            variant="standard"
                                            type="number"
                                            label="Counted Total"
                                            InputLabelProps={{ shrink: true }}
                                            autoComplete="off" // This stops the browser from giving prompts from previous entries
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        $
                                                    </InputAdornment>
                                                )
                                            }}
                                            value={line.actual}
                                            onChange={(e) =>
                                                updateCountedTotal(
                                                    index,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        {expandedIndex === index ? (
                                            <Tooltip
                                                title={`Close ${line.label} Notes`}
                                                placement="top"
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setExpandedIndex(null);
                                                        setExpanded(false);
                                                    }}
                                                >
                                                    <SpeakerNotesOff />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={`Open ${line.label} Notes`}
                                                placement="top"
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setExpandedIndex(index);
                                                        setExpanded(true);
                                                    }}
                                                >
                                                    <SpeakerNotes />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                                <Collapse
                                    in={expanded && expandedIndex === index}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <br />
                                    <TextField
                                        disabled={readOnly}
                                        multiline
                                        rows={3}
                                        fullWidth
                                        label="Notes"
                                        InputLabelProps={{ shrink: true }}
                                        value={line.notes}
                                        onChange={(e) =>
                                            updateTillLine(
                                                index,
                                                'notes',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Collapse>
                            </Paper>
                            <br />
                        </>
                    );
                } else {
                    return null;
                }
            })}
        </>
    );
};

export default OtherPaymentDenominations;
