// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
import PaymentTextField from '../../../global/paymentComponents/PaymentTextField';
import PaymentMethodSelector from '../../../global/paymentComponents/PaymentMethodSelector';
import TillSelector from '../../../global/paymentComponents/TillSelector';
import PaymentTable from '../../../global/paymentComponents/PaymentTable';
import VoucherDrawerContent from '../../../parts/newSale/components/VoucherDrawerContent';
import Drawer from '../../../global/Drawer';
import TotalsForm from './TotalsForm';
// LOGIC
import GetUser from '../../../global/databaseLogic/GetUser';
import ValidateVoucher from '../../../global/logic/ValidateVoucher';
import GetVoucher from '../../../parts/newSale/logic/GetVoucher';
import AddPaymentLine from '../logic/AddPaymentLine';
// INTERFACES
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';
import {
    Customer,
    PaymentLine,
    User
} from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    TradeIn,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import {
    NeedToPayAddOns,
    PartTotals,
    TotalPaid
} from '../logic/TotalFunctions';
import HandlePayCSM from '../logic/HandlePayCSM';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface OrderlinesProps {
    id: number;
    readOnly: boolean;
    isFinalised: boolean;
    saleDetails: VehicleSale;
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    selectedCustomer: Customer;
    showSnackbar: showSnackbar;
    linkedSales: Order[];
    linkedVouchers: Voucher[];
    linkedTrades: TradeIn[];
    gstType: string;
}

const Payment = ({
    id,
    gstType,
    readOnly,
    isFinalised,
    saleDetails,
    selectedCustomer,
    paymentLines,
    setPaymentLines,
    showSnackbar,
    linkedSales,
    linkedVouchers,
    linkedTrades
}: OrderlinesProps) => {
    const [currentUser, setCurrentUser] = useState<User>(null);
    const [paymentMethod, setPaymentMethod] = useState<string>('cash');
    const [voucherCode, setVoucherCode] = useState<string>('');
    const [selectedTillId, setSelectedTillId] = useState<number>(null);
    const [voucher, setVoucher] = useState<Voucher>();
    const [voucherDrawerOpen, setVoucherDrawerOpen] = useState<boolean>(false);
    const [splitCsmDialogOpen, setSplitCsmDialogOpen] =
        useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        GetUser(0, setCurrentUser);
    }, []);

    const handleAddPayment = (paymentAmount: string) => {
        if (paymentMethod === 'voucher') {
            validateVoucher(paymentAmount);
            setVoucherDrawerOpen(false);
        } else {
            AddPaymentLine(
                parseFloat(saleDetails.totalPurchasePrice),
                selectedTillId,
                parseFloat(paymentAmount),
                paymentLines,
                setPaymentLines,
                paymentMethod,
                currentUser,
                showSnackbar
            );
        }
    };

    const validateVoucher = (paymentAmount: string) => {
        ValidateVoucher(
            parseFloat(paymentAmount),
            voucherCode,
            selectedTillId,
            paymentLines,
            paymentMethod,
            setPaymentLines,
            setPaymentMethod,
            setVoucherCode,
            showSnackbar
        );
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <PaymentTextField
                            disabled={readOnly}
                            showButton={false}
                            showPaymentTypes={false}
                            SubmitPayment={handleAddPayment}
                            initialValue={
                                parseFloat(saleDetails.totalPurchasePrice) -
                                TotalPaid(paymentLines)
                            }
                        />
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <TillSelector
                            selectedTillId={selectedTillId}
                            setSelectedTillId={setSelectedTillId}
                            department={'sales'}
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                        <PaymentMethodSelector
                            disabled={readOnly}
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            isCustomerDebtor={false}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {paymentMethod === 'voucher' ? (
                            <TextField
                                fullWidth
                                size="small"
                                label="Voucher Code"
                                value={voucherCode}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setVoucherCode(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter')
                                        GetVoucher(
                                            voucherCode.toUpperCase(),
                                            setVoucher,
                                            setVoucherDrawerOpen,
                                            showSnackbar
                                        );
                                }}
                            />
                        ) : null}
                    </Grid>
                    {linkedSales.length > 0 &&
                    paymentLines.length > 0 &&
                    readOnly &&
                    NeedToPayAddOns(linkedSales) ? (
                        <Grid item xs={3} textAlign="right">
                            <Button
                                variant="contained"
                                onClick={() => setSplitCsmDialogOpen(true)}
                            >
                                Pay CSM
                            </Button>
                        </Grid>
                    ) : linkedSales.length > 0 &&
                      paymentLines.length > 0 &&
                      readOnly &&
                      !NeedToPayAddOns(linkedSales) ? (
                        <Grid item xs={3} textAlign="right">
                            <Button
                                variant="contained"
                                onClick={() => setSplitCsmDialogOpen(true)}
                            >
                                Re-Pay CSM
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>
            </Paper>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Paper>
                        <PaymentTable
                            disabled={readOnly}
                            isFinalised={isFinalised}
                            multipleCustomers={false}
                            paymentLines={paymentLines}
                            setPaymentLines={setPaymentLines}
                            payingCustomer={selectedCustomer}
                            currentUser={currentUser}
                            showSubmitButton={false}
                            showSnackbar={showSnackbar}
                            handleAddPayment={handleAddPayment}
                            vehicleSaleDetails={saleDetails}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper>
                        <TotalsForm
                            saleDetails={saleDetails}
                            linkedSales={linkedSales}
                            linkedVouchers={linkedVouchers}
                            linkedTrades={linkedTrades}
                            paymentForm={true}
                            paymentLines={paymentLines}
                            gstType={gstType}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Drawer
                openDrawer={voucherDrawerOpen}
                setOpenDrawer={setVoucherDrawerOpen}
                title={`Voucher Details`}
                subTitle={`Voucher Code: ${voucherCode.toUpperCase()}`}
                width="20vw"
            >
                <VoucherDrawerContent
                    voucher={voucher}
                    handleAddPayment={handleAddPayment}
                />
            </Drawer>

            <Dialog
                open={splitCsmDialogOpen}
                onClose={() => setSplitCsmDialogOpen(false)}
            >
                <DialogTitle>Pay Add-Ons?</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        The amount of{' '}
                        {CurrencyFormatter(
                            PartTotals(linkedSales).totalPartPrice
                        )}{' '}
                        will be used for the add-on sales.
                        <br />
                        <b>This cannot be reversed.</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSplitCsmDialogOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            HandlePayCSM(
                                id,
                                paymentLines,
                                linkedSales,
                                showSnackbar,
                                setButtonLoading
                            )
                        }
                    >
                        Pay Add-Ons
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Payment;
