// IMPORTS
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import RoleCheck from '../global/RoleCheck';

// PARTS
import InventoryTable from '../parts/inventoryTable/InventoryTable';
import ViewPart from '../parts/viewPart/ViewPart';
import ImportPriceFile from '../parts/importSupplierFile/ImportPriceFile';

// SALE
import SaleTable from '../parts/saleTable/SaleTable';
import NewSale from '../parts/newSale/NewSale';
import CreditSale from '../parts/creditSale/CreditSale';

// STOCK MANAGEMENT
import StockAdjustmentTable from '../stock/stockAdjustment/StockAdjustmentTable';
import StockOrderTable from '../stock/stockOrderTable/StockOrderTable';
import StockOrderUI from '../stock/stockOrder/StockOrderUI';
import NewStockInvoice from '../stock/stockInvoice/NewStockInvoice';
import StockTransferTable from '../stock/stockTransferTable/StockTransferTable';
import StocktakeTable from '../stock/stocktake/StocktakeTable';
import Stocktake from '../stock/stocktake/Stocktake';
import CreditInvoiceUI from '../stock/creditStockInvoice/CreditInvoiceUI';

// MISC
import VoucherTable from '../parts/voucher/VoucherTable';
import ViewInvoice from '../stock/viewInvoice/ViewInvoice';

// NOT IN TS
import BackorderTable from '../parts/backorderTable/BackorderTable';

// DASHBOARD
import PartsDashboard from '../parts/dashboard/PartsDashboard';

const PartsRouter = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            {/* DASHBOARD */}
            <Route path={`${path}/dashboard`}>
                <PartsDashboard />
            </Route>

            {/* PARTS */}
            <Route path={`${path}/search`}>
                <InventoryTable key={new Date().getTime()} />
            </Route>
            <Route path={`${path}/view/:partId`}>
                <ViewPart />
            </Route>

            {/* SALE */}
            <Route path={`${path}/sales`}>
                <SaleTable />
            </Route>
            <Route path={`${path}/newSale`} key={new Date().getTime()}>
                <NewSale />
            </Route>
            <Route path={`${path}/viewSale/:id`}>
                <NewSale />
            </Route>
            <Route path={`${path}/credit/:id`}>
                <CreditSale />
            </Route>
            <Route path={`${path}/viewCredit/:id`}>
                <CreditSale />
            </Route>

            {/* STOCK MANAGEMENT */}
            <Route path={`${path}/stockAdjustments`}>
                <StockAdjustmentTable />
            </Route>
            <Route path={`${path}/stockorders`}>
                <StockOrderTable />
            </Route>
            <Route path={`${path}/stockorder`} key={new Date().getTime()}>
                <StockOrderUI />
            </Route>
            <Route path={`${path}/stockInvoiceNoPO/`}>
                <NewStockInvoice />
            </Route>
            <Route path={`${path}/stockInvoicePO/:id`}>
                <NewStockInvoice />
            </Route>
            <Route path={`${path}/stocktransfers`}>
                <StockTransferTable />
            </Route>
            <Route path={`${path}/stocktake`}>
                <StocktakeTable />
            </Route>
            <Route path={`${path}/newstocktake/:id`}>
                <Stocktake />
            </Route>
            <Route path={`${path}/creditInvoice/view/:id`}>
                <RoleCheck
                    component={<CreditInvoiceUI />}
                    permission={'access_creditStockInvoice'}
                    pageError
                />
            </Route>
            <Route path={`${path}/creditInvoice`}>
                <RoleCheck
                    component={<CreditInvoiceUI />}
                    permission={'access_creditStockInvoice'}
                    pageError
                />
            </Route>

            {/* MISC */}
            <Route path={`${path}/vouchers`}>
                <VoucherTable />
            </Route>
            <Route path={`${path}/viewInvoice/:id`}>
                <ViewInvoice />
            </Route>
            <Route path={`${path}/backorders`}>
                <BackorderTable />
            </Route>

            {/* NOT IN TS */}
            <Route path={`${path}/importSupplierFiles`}>
                <RoleCheck
                    component={<ImportPriceFile />}
                    permission={'import_supplierFile'}
                    pageError
                />
            </Route>
        </Switch>
    );
};

export default PartsRouter;
