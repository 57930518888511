import { useState } from 'react';

import { TableRow, Typography } from '@mui/material';
import { FileDownload } from '@mui/icons-material';

// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';

//INTERFACE
import { TimeClock } from '../../global/interfaces/AdminInterface';
import { Filter } from '../../global/interfaces/FilterInterface';

//LOGIC
import HandleDownloadResults from '../../global/logic/HandleDownloadResults';
import TimeClockTableFilter from './components/TimeClockTableFilter';
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import dayjs from 'dayjs';

const TimeClockPage = () => {
    // Default values for the filter and sort for initial set state and clear filter
    const clearedFilter = {
        timeClockTableFilter: {
            user: [],
            site: [],
            startDate: null,
            endDate: null
        }
    };
    const clearedSort = ['id', 'DESC'];

    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<TimeClock[]>([]);
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );

    // Columns for the data table
    const columns = [
        { id: 0, label: 'Site', width: 200 },
        { id: 1, label: 'Name', width: 200 },
        { id: 2, label: 'Date', width: 200 },
        { id: 3, label: 'Time On', name: 'clockon', width: 200 },
        { id: 4, label: 'Time Off', name: 'clockoff', width: 200 },
        { id: 5, label: 'Duration', width: 200 }
    ];

    // Handle downloading the csv
    const handleDownloadResults = () => {
        HandleDownloadResults(
            sort,
            'timeClockSearchNextGen',
            handleRequestCreate(),
            'OraTimeClockResults.csv',
            ['Site', 'Name', 'Date', 'Time On', 'Time Off', 'Duration(mins)'],
            ['Site.name', 'userName', 'date', 'clockOn', 'clockOff', 'duration']
        );
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let user =
            filter.timeClockTableFilter.user.length > 0
                ? ConvertArrayToFilter({
                      array: filter.timeClockTableFilter.user,
                      filterStart: '&user=',
                      selector: 'id',
                      useId: true
                  })
                : '';

        let site =
            filter.timeClockTableFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.timeClockTableFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';

        let startDate =
            filter.timeClockTableFilter.startDate !== null &&
            dayjs(filter.timeClockTableFilter.startDate).isValid()
                ? `&startDate=${dayjs(filter.timeClockTableFilter.startDate).toISOString()}`
                : '';

        let endDate =
            filter.timeClockTableFilter.endDate !== null &&
            dayjs(filter.timeClockTableFilter.endDate).isValid()
                ? `&endDate=${dayjs(filter.timeClockTableFilter.endDate).toISOString()}`
                : '';

        let apiString = `${user}${site}${startDate}${endDate}`;

        return apiString;
    };

    // Options for the speed dial
    const actionsList = [
        {
            icon: <FileDownload />,
            name: 'Export Current Results',
            onClick: () => handleDownloadResults()
        }
    ];

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.timeClockTableFilter.user.length > 0 ||
            filter.timeClockTableFilter.site.length > 0 ||
            filter.timeClockTableFilter.startDate ||
            filter.timeClockTableFilter.endDate
        ) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Time Clock</Typography>
            <br />
            <TableSearch
                searchTitle="Search Time Clock (First Name, Last Name, Username, Site Name)"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="timeClockSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <TimeClockTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>
            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList?.map((row) => (
                        <TableRow key={row.id}>
                            <DataCell>{row?.Site?.name}</DataCell>
                            <DataCell>
                                {row?.User?.firstName} {row?.User?.lastName}
                            </DataCell>
                            <DataCell>{row?.date}</DataCell>
                            <DataCell>
                                {dayjs(row.clockOn, 'hh:mm:ss').format(
                                    'hh:mma'
                                )}
                            </DataCell>
                            <DataCell>
                                {row.clockOff
                                    ? dayjs(row.clockOff, 'hh:mm:ss').format(
                                          'hh:mma'
                                      )
                                    : ''}
                            </DataCell>
                            <DataCell>
                                {row?.duration ? `${row?.duration} min` : ''}{' '}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </PageWrapper>
    );
};

export default TimeClockPage;
