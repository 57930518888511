import { Step } from '../../../interface';

export const PNLReportsSteps: Step[] = [
    {
        label: 'In the sidebar, navigate to Admin -> Reporting to open the Ora Generated Reports page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-01.png'
    },
    {
        label: 'Select one or multiple sites from the Site dropdown list.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-02.png'
    },
    {
        label: 'Select a reporting start date and end date for your desired report period. ',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-03.png'
    },
    {
        label: 'The "Generate Report" button will be disabled until at least one site and both dates are selected. Click the button to generate the PNL report.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-04.png'
    },
    {
        label: 'The report will be displayed summary and detailed information. Click on any hyperlinked data to view associated sales, transactions, etc.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-05.png'
    },
    {
        label: 'A line marked with an Exclamation Mark icon indicates that some data might be missing (cost price average, rrp, ...).',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-06.png'
    },
    {
        label: 'Click the "Print PNL Statement" button to open a PROFIT AND LOSS STATEMENT PDF for the selected period in a new window. Note: It may take a while to generate.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-07.png'
    },
    {
        label: 'To commit the PNL, click "Commit PNL" in the speed dial menu. A dialog will open to select a month.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-08.png'
    },
    {
        label: 'Select a month and click the "Commit PNL" button to commit PNL data. ',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-09.png'
    },
    {
        label: 'The committed PNL data from all sites for the selected month will be displayed, showing whether transactions have been reconciled for each category. Click to view a list that includes all specific transactions that are not reconciled',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-10.png'
    },
    {
        label: "The 'COMMIT PNL' button will be disabled if any category is not fully reconciled. Once all categories are reconciled, the button will be clickable, allowing you to commit the PNL data. A success message will appear upon completion. Once a month has been commited, you won't be able to add transaction that would appear in the PNL in that month, effectively locking that month PNL.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reporting/PNL-11.png'
    }
];
