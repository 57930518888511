// IMPORTS
import { useState, useEffect } from 'react';
import {
  Typography,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import {
  BrowserUpdated,
  Add,
  GetApp,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import UserTableFilter from './components/UserTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import CompanyEmailDialogContent from './components/CompanyEmailDialogContent';
import ExportTimeClockDialog from './components/ExportTimeClockDialog';
// LOGIC
import GetUser from '../../global/databaseLogic/GetUser';
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import TriggerUpdateRefresh from './logic/TriggerUpdateRefresh';
import ExportToCsv from './logic/ExportTimeClockToCSV';
// INTERFACES
import { Site, User } from '../../global/interfaces/GeneralInterface';
import { Filter } from '../../global/interfaces/FilterInterface';
import { Dayjs } from 'dayjs';

interface Result {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  staffEmail: string;
  department: string;
  isGod: boolean;
  SiteId: number;
  Site: Site;
}

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
  userFilter: { department: [], site: [], employed: true }
};
const clearedSort = ['firstName', 'ASC'];

const UserTable = () => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState<User>({});

  const [filter, setFilter] = useState<Filter>(
    JSON.parse(JSON.stringify(clearedFilter))
  );
  const [sort, setSort] = useState(clearedSort);
  const [resultsList, setResultsList] = useState<Result[]>([]);

  const [companyEmailIsOpen, setCompanyEmailIsOpen] = useState<boolean>(false);

  const [exportTimeClockDialogOpen, setExportTimeClockDialogOpen] =
    useState<boolean>(false);

  // Options for the speed dial
  const actionsList = [
    {
      icon: <Add />,
      name: 'Add User',
      onClick: () => history.push('/addUser'),
      cypressLabel: 'addUserFAB'
    },
    {
      icon: <GetApp />,
      name: 'Export Time Clocks',
      onClick: () => setExportTimeClockDialogOpen(true),
      permission: 'export_userTimeClock'
    },
    {
      icon: <BrowserUpdated />,
      name: 'Trigger New Update',
      onClick: () => TriggerUpdateRefresh(),
      permission: 'new_update_trigger'
    }
  ];

  // Columns for the data table
  const columns = [
    { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
    { id: 1, label: 'Full Name', width: 200, sort: true, name: 'firstName' },
    { id: 2, label: 'Username', width: 200 },
    { id: 3, label: 'Staff Email', width: 300 },
    { id: 4, label: 'Department', name: 'department', sort: true, width: 100 },
    { id: 5, label: 'Site', name: 'SiteId', sort: true, width: 100 }
  ];

  // Get the current user
  useEffect(() => {
    GetUser(0, setCurrentUser);
  }, []);

  // Combines the selected filters and generates a string to put in the api request query
  const handleRequestCreate = () => {
    let department =
      filter.userFilter.department.length > 0
        ? ConvertArrayToFilter({
            array: filter.userFilter.department,
            filterStart: '&department=',
            selector: 'value'
          })
        : '';
    let site =
      filter.userFilter.site.length > 0
        ? ConvertArrayToFilter({
            array: filter.userFilter.site,
            filterStart: '&site=',
            selector: 'id'
          })
        : '';
    let employed = `&employed=${filter.userFilter.employed}`;

    let apiString = `${department}${site}${employed}`;
    return apiString;
  };

  // Checks if any options for filter have been selected and return result
  const isFilterActive = () => {
    let isActive = false;

    if (
      filter.userFilter.department.length > 0 ||
      filter.userFilter.site.length > 0
    ) {
      isActive = true;
    }

    return isActive;
  };

  const handleSubmit = (
    selectedUser: User[],
    exportPeriod: { startDate: Dayjs; endDate: Dayjs }
  ) => {
    ExportToCsv(selectedUser, exportPeriod);
  };

  return (
    <PageWrapper>
      <Typography variant="h4">Users</Typography>
      <br />
      <TableSearch
        cypressLabel='userTableSearch'
        searchTitle="Search Users"
        showFilter={true}
        showPagination={true}
        showPaper={true}
        filter={filter}
        setFilter={setFilter}
        clearedFilter={clearedFilter}
        sort={sort}
        handleRequestCreate={handleRequestCreate}
        route="userSearchNextGen"
        setResultsList={setResultsList}
        isFilterActive={isFilterActive}
      >
        <UserTableFilter
          filter={filter}
          setFilter={setFilter}
          isFilterActive={isFilterActive}
        />
      </TableSearch>

      <Paper>
        <DataTable
          cypressLabel='userTable'
          columns={columns}
          sort={sort}
          setSort={setSort}
          showSpeedDial={true}
          actionsList={actionsList}
        >
          {resultsList.map((row) => (
            <TableRow key={row.id}>
              {currentUser.isGod || (!currentUser.isGod && !row.isGod) ? (
                <DataCellColoured
                  handleClick={() => history.push(`/viewUser/${row.id}`)}
                  cypressLabel={`userTableUserId-${row.username}`}
                >
                  {row.id}
                </DataCellColoured>
              ) : (
                <DataCell>{row.id}</DataCell>
              )}
              <DataCell>{`${row.firstName} ${row.lastName}`} </DataCell>
              <DataCell>{row.username}</DataCell>
              <DataCell>{row.staffEmail}</DataCell>
              <DataCell>{row.department}</DataCell>
              <DataCell>{row.Site?.name}</DataCell>
            </TableRow>
          ))}
        </DataTable>
      </Paper>

      <Dialog
        open={companyEmailIsOpen}
        onClose={() => setCompanyEmailIsOpen(false)}
      >
        <DialogTitle>What sort of email would you like to send?</DialogTitle>
        <DialogContent>
          <CompanyEmailDialogContent />
        </DialogContent>
      </Dialog>

      <Dialog
        open={exportTimeClockDialogOpen}
        onClose={() => {
          setExportTimeClockDialogOpen(false);
        }}
        fullWidth
        PaperProps={{ sx: { position: 'fixed', top: 50 } }}
      >
        <ExportTimeClockDialog handleSubmit={handleSubmit} />
      </Dialog>
    </PageWrapper>
  );
};

export default UserTable;
