// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Grid, Typography, Divider, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'
import 'dayjs/locale/en-gb'
// COMPONENTS
import Paper from '../../../../global/Paper'
import Autocomplete from '../../../../global/Autocomplete';
// INTERFACES
import { Vehicle } from "../../../../global/interfaces/VehicleSaleInterface"
import { Specific } from '../../../../global/interfaces/GeneralInterface';
import { Site } from '../../../../global/interfaces/GeneralInterface';

interface SpecificsList {
    makes?: Specific[],
    models?: Specific[],
    series?: Specific[],
    aspirations?: Specific[],
    fuelTypes?: Specific[],
    bodyTypes?: Specific[],
    driveTypes?: Specific[],
    sites?: Site[],
}

interface ManufacturerDetailsProps {
    vehicle: Vehicle
    setVehicle: Dispatch<SetStateAction<Vehicle>>
    specifics: SpecificsList
    readOnly: boolean
}

const ManufacturerBicyclesDetails = ({ vehicle, setVehicle, specifics, readOnly }: ManufacturerDetailsProps) => {
    return (
        <Paper>
            <Typography variant="h6">Manufacturer Details</Typography>
            <Divider /><br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        isDisabled={readOnly}
                        options={specifics?.makes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={"name"}
                        textfieldLabel="Make"
                        selectedValue={vehicle?.Make}
                        handleSelectedValueChange={(event) => {
                            setVehicle({ ...vehicle, Make: event, MakeId: event.id })
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        isDisabled={readOnly}
                        options={specifics?.models}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={"name"}
                        textfieldLabel="Model"
                        selectedValue={vehicle?.Model}
                        handleSelectedValueChange={(event) => {
                            setVehicle({ ...vehicle, Model: event, ModelId: event.id })
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        size="small"
                        isDisabled={readOnly}
                        options={specifics?.series}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={"name"}
                        textfieldLabel="Series"
                        selectedValue={vehicle?.Series}
                        handleSelectedValueChange={(event) => {
                            setVehicle({ ...vehicle, Series: event, SeriesId: event.id })
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DatePicker
                            disabled={readOnly}
                            format='YYYY'
                            views={["year"]}
                            label="Year Model"
                            value={dayjs(vehicle?.year)}
                            onChange={(newValue) => { setVehicle({ ...vehicle, year: newValue }) }}
                            slotProps={{ textField: { fullWidth: true, error: false, size: 'small', InputLabelProps: { shrink: true } } }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Colour"
                        value={vehicle?.colour}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => { setVehicle({ ...vehicle, colour: e.target.value }) }}
                    />
                </Grid>
            </Grid>
        </Paper>

    )
}
export default ManufacturerBicyclesDetails