import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const DeleteGeneralJournalEntry = (
    id: number,
    showSnackbar: showSnackbar
) => {
    api.delete('accountTransaction/' + id.toString()).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'General Journal Entry deleted successfully.',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department',
                'error'
            );
        }
    });
};
