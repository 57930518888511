// IMPORTS
import { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Refresh, Save } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import SpeedDial from '../../../../global/SpeedDial';
import TablesV2 from './TablesV2';
import HeaderV2 from './HeaderV2';
// LOGIC
import GetTransactionsV2 from '../../logic/Reconciliator/GetTransactionsV2';
import RefreshTransactionsV2 from '../../logic/Reconciliator/RefreshTransactionsV2';
import SubmitBankReconciliationV2 from '../../logic/Reconciliator/SubmitBankReconciliationV2';
// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import Fab from '../../../../global/Fab';
import { withSnackbar } from '../../../../global/WrappingSnackbar';

const ReconciliatorV2 = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [outerWidth, setOuterWidth] = useState<number>(window.outerWidth);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
    const [reconciliationDetailsUnaltered, setReconciliationDetailsUnaltered] =
        useState({
            oraTransactionsReconciled: [],
            bankTransactionsReconciled: [],
            oraTransactionsUnreconciled: [],
            bankTransactionsUnreconciled: []
        });

    const [
        reconciliationDetailsGroupedByWeek,
        setReconciliationDetailsGroupedByWeek
    ] = useState({
        oraTransactionsReconciled: [],
        bankTransactionsReconciled: [],
        oraTransactionsUnreconciled: [],
        bankTransactionsUnreconciled: []
    });

    const [selectedOraUnrecTab, setSelectedOraUnrecTab] = useState('');
    const [selectedBankUnrecTab, setSelectedBankUnrecTab] = useState('');
    const [selectedOraRecTab, setSelectedOraRecTab] = useState('');
    const [selectedBankRecTab, setSelectedBankRecTab] = useState('');

    const actionsList = [
        {
            id: 0,
            name: 'Save',
            icon: <Save />,
            onClick: () =>
                SubmitBankReconciliationV2(
                    reconciliationDetailsGroupedByWeek,
                    setRefreshLoading,
                    showSnackbar
                )
        },
        {
            id: 1,
            name: 'Refresh Transactions',
            icon: <Refresh />,
            onClick: () =>
                RefreshTransactionsV2(
                    reconciliationDetailsGroupedByWeek,
                    reconciliationDetailsUnaltered,
                    setReconciliationDetailsGroupedByWeek,
                    setRefreshLoading
                )
        }
    ];

    useEffect(() => {
        GetTransactionsV2(
            setReconciliationDetailsUnaltered,
            setReconciliationDetailsGroupedByWeek,
            setSelectedOraUnrecTab,
            setSelectedBankUnrecTab,
            setSelectedOraRecTab,
            setSelectedBankRecTab,
            setLoading
        );
    }, []);

    function debounce(func) {
        var timer;
        return function (event) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(func, 300, event);
        };
    }

    window.addEventListener(
        'resize',
        debounce(function () {
            setOuterWidth(window.outerWidth);
        })
    );

    return (
        <>
            {loading ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                        <Paper>
                            <CircularProgress />
                        </Paper>
                    </Grid>
                </Grid>
            ) : (
                <>
                    {refreshLoading ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12} textAlign="center">
                                <Paper>
                                    <CircularProgress />
                                </Paper>
                                <br />
                            </Grid>
                        </Grid>
                    ) : null}
                    <HeaderV2
                        reconciliationDetails={
                            reconciliationDetailsGroupedByWeek
                        }
                        setReconciliationDetails={
                            setReconciliationDetailsGroupedByWeek
                        }
                        setSelectedOraUnrecTab={setSelectedOraUnrecTab}
                        setSelectedBankUnrecTab={setSelectedBankUnrecTab}
                        setSelectedOraRecTab={setSelectedOraRecTab}
                        setSelectedBankRecTab={setSelectedBankRecTab}
                        reconciliationDetailsUnaltered={
                            reconciliationDetailsUnaltered
                        }
                        refreshLoading={refreshLoading}
                        readOnly={readOnly}
                    />
                    <br />
                    <TablesV2
                        reconciliationDetails={
                            reconciliationDetailsGroupedByWeek
                        }
                        setReconciliationDetails={
                            setReconciliationDetailsGroupedByWeek
                        }
                        selectedOraUnrecTab={selectedOraUnrecTab}
                        selectedBankUnrecTab={selectedBankUnrecTab}
                        selectedOraRecTab={selectedOraRecTab}
                        selectedBankRecTab={selectedBankRecTab}
                        setSelectedOraUnrecTab={setSelectedOraUnrecTab}
                        setSelectedBankUnrecTab={setSelectedBankUnrecTab}
                        setSelectedOraRecTab={setSelectedOraRecTab}
                        setSelectedBankRecTab={setSelectedBankRecTab}
                        refreshLoading={refreshLoading}
                        readOnly={readOnly}
                        outerWidth={outerWidth}
                    />
                    {!refreshLoading ? (
                        readOnly ? (
                            <Fab
                                editIcon={true}
                                onClick={() => setReadOnly(false)}
                            />
                        ) : (
                            <SpeedDial actionsList={actionsList} />
                        )
                    ) : null}
                </>
            )}
        </>
    );
};

export default withSnackbar(ReconciliatorV2);
