import {
    Badge,
    Dialog,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import { StickyNote2, Task } from '@mui/icons-material';
import { Dispatch, SetStateAction, useState } from 'react';

// COMPONENTS
import Paper from '../../../global/Paper';
import NotesDialogContent from '../NotesDialogContent';
import Fab from '../../../global/Fab';
import { withSnackbar } from '../../../global/WrappingSnackbar';

// LOGIC
import PrintManifest from '../../logic/PendingAndDispatched/PrintManifest';

// INTERFACES
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
interface GroupedOnlineOrders {
    pending: GroupedOnlineOrder[];
    dispatched: GroupedOnlineOrder[];
}
interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}
interface ViewOrdersProps {
    selectedGroupOrders: GroupedOnlineOrder[];
    setSelectedGroupOrders: Dispatch<SetStateAction<GroupedOnlineOrder[]>>;
    selectedGroupTitle: string;
    pendingDispatchedOrders: GroupedOnlineOrders;
    setPendingDispatchedOrders: Dispatch<SetStateAction<GroupedOnlineOrders>>;
    showSnackbar: showSnackbar;
}

const ViewOrders = ({
    selectedGroupTitle,
    selectedGroupOrders,
    setSelectedGroupOrders,
    pendingDispatchedOrders,
    setPendingDispatchedOrders,
    showSnackbar
}: ViewOrdersProps) => {
    const [manifestLoading, setManifestLoading] = useState<boolean>(false);

    const [selectedOrder, setSelectedOrder] = useState<GroupedOnlineOrder>();
    const [notesDialogOpen, setNotesDialogOpen] = useState<boolean>(false);

    const handleOpenNotesDialog = (order: GroupedOnlineOrder) => {
        setSelectedOrder(order);
        setNotesDialogOpen(true);
    };

    const handleUpdateAllStatesNotes = (newNotes: string, OrderId: any) => {
        let currentSelectedGroup = [...selectedGroupOrders];

        let index = currentSelectedGroup.findIndex(
            (x) => x.oraOrder.id === OrderId
        );

        currentSelectedGroup[index].oraOrder.notes = newNotes;

        if (selectedGroupTitle === 'Pick') {
            setPendingDispatchedOrders({
                ...pendingDispatchedOrders,
                pending: currentSelectedGroup
            });
        } else if (selectedGroupTitle === 'Pack') {
            setPendingDispatchedOrders({
                ...pendingDispatchedOrders,
                dispatched: currentSelectedGroup
            });
        }

        setSelectedGroupOrders(currentSelectedGroup);
    };

    return (
        <>
            {selectedGroupOrders?.map((order) => (
                <>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    onClick={() =>
                                        window.open(
                                            `/inventory/viewSale/${order.oraOrder.id}`,
                                            '_blank'
                                        )
                                    }
                                >
                                    <b>
                                        {order?.netoOrder?.OnlineOrderId} |{' '}
                                        {order?.netoOrder?.shippingFirstName}{' '}
                                        {order?.netoOrder?.shippingLastName}
                                    </b>
                                </Typography>
                                <Typography variant="subtitle2">
                                    {order?.oraOrder?.Site?.name} |{' '}
                                    {order?.netoOrder?.shippingOption}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                                <Tooltip title="Order Notes" placement="top">
                                    <IconButton
                                        disabled={manifestLoading}
                                        onClick={() =>
                                            handleOpenNotesDialog(order)
                                        }
                                    >
                                        <Badge
                                            variant="dot"
                                            color="primary"
                                            badgeContent=" "
                                            invisible={
                                                order.oraOrder.notes
                                                    ? false
                                                    : true
                                            }
                                        >
                                            <StickyNote2 />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <br />
                        {order.oraOrder.orderLines.map((line) => (
                            <>
                                <Typography variant="body1">
                                    {' '}
                                    <b>
                                        {line.quantity} x {line.partNumber}
                                    </b>{' '}
                                    | {line.name}
                                </Typography>
                            </>
                        ))}
                    </Paper>
                    <br />
                </>
            ))}

            {selectedGroupTitle === 'Pending' ? (
                <Fab
                    loading={manifestLoading}
                    noSession={true}
                    customIcon={<Task />}
                    onClick={() =>
                        PrintManifest(setManifestLoading, showSnackbar)
                    }
                />
            ) : null}

            <Dialog
                open={notesDialogOpen}
                onClose={() => setNotesDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <NotesDialogContent
                    handleUpdateAllStatesNotes={handleUpdateAllStatesNotes}
                    selectedOrder={selectedOrder}
                    setNotesDialogOpen={setNotesDialogOpen}
                    showSnackbar={showSnackbar}
                />
            </Dialog>
        </>
    );
};

export default withSnackbar(ViewOrders);
