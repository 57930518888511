import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';

// INTERFACES
import { GeneralJournalLineTemp } from '../GeneralJournalUI';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleUpdate = (
    id: number,
    entryLines: GeneralJournalLineTemp[],
    setEntryLines: Dispatch<SetStateAction<GeneralJournalLineTemp[]>>,
    reference: string,
    showSnackbar: showSnackbar
) => {
    // Check that there is a description for each entry
    let lines = entryLines.map((line) => {
        line.description === ''
            ? (line.descriptionError = true)
            : (line.descriptionError = false);
        return line;
    });
    setEntryLines(lines);
    let noDescription = entryLines.some((line) => line.descriptionError);
    let noDebit = entryLines.some((line) => line.debitError);
    let noCredit = entryLines.some((line) => line.creditError);

    // If we didn't find any error, proceed
    if (!noDescription && !noDebit && !noCredit) {
        let debitAmount = 0;
        let creditAmount = 0;
        entryLines.forEach((entry) => {
            if (entry.debit > 0) {
                debitAmount += entry.debit;
            } else {
                creditAmount += entry.credit;
            }
        });

        // Round the debit amount and credit amount
        debitAmount = Math.round((debitAmount + Number.EPSILON) * 100) / 100;
        creditAmount = Math.round((creditAmount + Number.EPSILON) * 100) / 100;

        if (debitAmount !== creditAmount) {
            showSnackbar(
                'Debit and Credit do not balance. Please check your data and try again.',
                '',
                'error'
            );
            return;
        } else {
            api.put(`generalJournalTransactionNextGen`, {
                id: id,
                reference: reference,
                transactionLines: entryLines,
                SiteId: parseInt(localStorage.getItem('SiteId'))
            }).then((res) => {
                if (res.status === 200) {
                    showSnackbar('General Accounting Entry Successful.');
                }
            });
        }
    }
};

export default HandleUpdate;
