import api from '../../../../api';

const HandleVerifyCustomer = async (type, documentId, driverLicenseNumber) => {
    driverLicenseNumber = driverLicenseNumber.replace(/\s/g, '');

    let endpoint =
        type === 'vehicle-trade' ? '/verifyVendor' : '/verifyCustomer';

    const res = await api.post(`${endpoint}`, {
        type,
        documentId,
        driverLicenseNumber
    });
    if (res.status === 200) {
        const { verified, token } = res.data;
        return { verified, token };
    } else if (res.status === 400) {
        const { error } = res.data;
        return { error };
    }
};

export default HandleVerifyCustomer;
