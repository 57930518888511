import { Step } from '../../interface';

export const InvoiceStockOrderSteps: Step[] = [
    {
        label: 'Locate the Stock Order you wish to invoice on the Stock Order page and click its Order ID to navigate to the Invoice Order page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/invoice-order-01.png'
    },
    {
        label: 'The Creditor and Site are automatically populated from the stock order and cannot be changed.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/invoice-order-02.png'
    },
    {
        label: 'If a part has a linked backorder, you can view the backorder details by clicking on the backorder number.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/invoice-order-03.png'
    },
    {
        label: 'You can remove any parts that you do not want to include in the Stock Invoice by clicking the "X" button next to the part.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/invoice-order-04.png'
    },
    {
        label: 'You can also add new items to the Stock Invoice. Enter the part number or barcode and press Enter to add the part.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/invoice-order-05.png'
    },
    {
        label: 'You can enter the quantity received and the cost for each item. A line total will be automatically calculated. If you change the line total, the Document Cost will be adjusted.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/invoice-order-06.png'
    },
    {
        label: 'Enter the document details, including the document reference, date, total, and freight if applicable. Be aware that the document reference must be unique, and the total must match the sum of the line totals and freight.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/invoice-order-07.png'
    },
    {
        label: 'Click Submit in the speed dial menu to submit the invoice.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/invoice-order-08.png'
    },
    {
        label: 'The Stock Invoices can be found in the Creditor invoice list. Click the invoice ID to open the invoice details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/invoice-order-09.png'
    },
    {
        label: (
            <>
                To pay the invoice, navigate to Admin - Reconciliators -
                Creditor Reconciliation - Unconfirmed Invoices, and locate the
                invoice to finalize. Refer to{' '}
                <a
                    href="/support/admin/creditorReconciliation"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Creditor Reconciliation
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/creditStockInvoice/credit-stock-invoice-09.png'
    }
];

export const CreateNewStockInvoiceSteps: Step[] = [
    {
        label: "Click 'Stock Control' > 'Enter Stock Invoice' in the sidebar to navigate to the new Stock Invoice page.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/new-stock-invoice-01.png'
    },
    {
        label: 'Select the Creditor and Site for the new Stock Invoice.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/new-stock-invoice-02.png'
    },
    {
        label: (
            <>
                Enter a part number or barcode and press Enter to add the part
                to the Stock Invoice. If the part you entered isn't supplied by
                the selected Creditor, an error message will appears. If you
                shouldn't get this message, you need to go into the part details
                (through the Inventory page) and add the Supplier to the part
                (see:{' '}
                <a
                    href="/support/inventory/search/#update-part"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Update existing part
                </a>{' '}
                .)
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/new-stock-invoice-03.png'
    },
    {
        label: 'If the entered part does not exist, a "Part Not Found" dialog will appear allowing you to add the part to the system.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/new-stock-invoice-04.png'
    },
    {
        label: 'Enter the quantity received and the cost of each item. A line total will be calculated based on the quantity and cost. Changing the line total will adjust the Document Cost of the part.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/new-stock-invoice-07.png'
    },
    {
        label: 'Enter the document details, including the document reference, date, total, and freight if applicable. Be aware that the document reference must be unique, and the total must match the sum of the line totals and freight.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/new-stock-invoice-08.png'
    },
    {
        label: 'Click Submit in the speed dial menu to submit the invoice.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/new-stock-invoice-09.png'
    },
    {
        label: 'The Stock Invoices can be found in the Creditor invoice list. Click the invoice ID to open the invoice details page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/new-stock-invoice-10.png'
    },
    {
        label: (
            <>
                To pay the invoice, navigate to Admin - Reconciliators -
                Creditor Reconciliation - Unconfirmed Invoices, and locate the
                invoice to finalize. Refer to{' '}
                <a
                    href="/support/admin/creditorReconciliation"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="useful-link"
                >
                    Creditor Reconciliation
                </a>{' '}
                documentation for more information.
            </>
        ),
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/stockControl/invoiceStockOrder/new-stock-invoice-12.png'
    }
];
