import { Dispatch, SetStateAction } from 'react';
import { OnlineOrder } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import ValidateShippingFields from '../../../global/logic/customerValidation/ValidateShippingFields';
import api from '../../../../../api';

const HandleAddShippingDetails = (
    onlineOrder: OnlineOrder,
    showSnackbar: showSnackbar,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    setOnlineOrderSuccess: Dispatch<SetStateAction<boolean>>
) => {
    let validated = ValidateShippingFields(onlineOrder, showSnackbar);

    if (validated) {
        api.post('createShippingDetails', onlineOrder).then((res) => {
            if (res.status === 200) {
                showSnackbar('Shipping details created!');
                setReadOnly(true);
                setOnlineOrderSuccess(true);
            } else {
                showSnackbar(
                    'Whoops! Something went wrong our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }
};

export default HandleAddShippingDetails;
