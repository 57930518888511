// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleRemoveBackorder = (
    id: number,
    selectedIndex: number,
    resultsList: any[],
    setResultsList: Dispatch<SetStateAction<any[]>>,
    showSnackbar: showSnackbar
) => {
    api.put('/updateCustomerBackorderStatus', {
        id: id,
        status: 'CANCELLED'
    }).then((res) => {
        if (res.status === 200) {
            const updated = resultsList[selectedIndex].lines.filter(
                (item) => item.id !== id
            );
            setResultsList((prevState) => ({
                ...prevState,
                [selectedIndex]: { ...prevState[selectedIndex], lines: updated }
            }));

            showSnackbar('Backorder sucessfully cancelled.');
        } else {
            showSnackbar(
                'Whoops! Something seems to have gone wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleRemoveBackorder;
