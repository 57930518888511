// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { DialogContentText, Grid } from '@mui/material';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// LOGIC
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';
import GetAllSites from '../../../global/databaseLogic/GetAllSites';

interface StockAdjustmentTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const StockAdjustmentTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: StockAdjustmentTableFilterProps) => {
    const [sitesUnaltered, setSitesUnaltered] = useState<Site[]>([]);
    const [sites, setSites] = useState<Site[]>([]);

    useEffect(() => {
        GetAllSites(setSites);
        GetAllSites(setSitesUnaltered);
    }, []);

    useEffect(() => {
        if (!isFilterActive()) {
            setSites(sitesUnaltered);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.stockAdjustmentFilter;
        newFilter[property] = value;
        setFilter({ ...filter, stockAdjustmentFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    {/* Site Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.vehicleFilter?.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sitesUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default StockAdjustmentTableFilter;
