// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Checkbox,
    DialogContentText,
    FormControlLabel,
    Grid,
    TextField
} from '@mui/material';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';

// LOGIC
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site, User } from '../../../global/interfaces/GeneralInterface';
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import GetAllUsers from '../../../global/databaseLogic/GetAllUsers';

interface SaleTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const typeOptions = [
    { value: 'Customer' },
    { value: 'Online' },
    { value: 'Vehicle Contract' },
    { value: 'Vehicle Part' },
    { value: 'Sales Department' },
    { value: 'Service Part' },
    { value: 'Workshop' }
];
const statusOptions = [
    { value: 'Sale' },
    { value: 'Proforma' },
    { value: 'Quote' },
    { value: 'Online Sale' },
    { value: 'Online Proforma' },
    { value: 'Credited' }
];

const SaleTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: SaleTableFilterProps) => {
    const [sitesUnaltered, setSitesUnaltered] = useState<Site[]>([]);
    const [sites, setSites] = useState<Site[]>([]);
    const [usersUnaltered, setUsersUnaltered] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [types, setTypes] = useState(typeOptions);
    const [status, setStatus] = useState(statusOptions);

    useEffect(() => {
        GetAllSites(setSites);
        GetAllSites(setSitesUnaltered);
        GetAllUsers(setUsers);
        GetAllUsers(setUsersUnaltered);
    }, []);

    useEffect(() => {
        if (!isFilterActive()) {
            setSites(sitesUnaltered);
            setUsers(usersUnaltered);
            setTypes(typeOptions);
            setStatus(statusOptions);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.saleTableFilter;
        newFilter[property] = value;
        setFilter({ ...filter, saleTableFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {/* Sale Type Filter */}
                    <AutocompleteMultiple
                        options={types}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'value'}
                        textfieldLabel="Sale Type"
                        selectedValues={filter.saleTableFilter.type}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = typeOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setTypes(currentOptions);
                            handleUpdateFilter('type', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Sale Status Filter */}
                    <AutocompleteMultiple
                        options={status}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'value'}
                        textfieldLabel="Sale Status"
                        selectedValues={filter.saleTableFilter.status}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = statusOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setStatus(currentOptions);
                            handleUpdateFilter('status', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Site Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.saleTableFilter.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sitesUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* User Filter */}
                    <AutocompleteMultiple
                        options={users}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="User"
                        selectedValues={filter.saleTableFilter.user}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = usersUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setUsers(currentOptions);
                            handleUpdateFilter('user', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Part Number (Partial Part Number not supported)"
                        fullWidth
                        value={filter.saleTableFilter.partNumber}
                        size="small"
                        onChange={(e) =>
                            handleUpdateFilter('partNumber', e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.saleTableFilter.archived}
                                onChange={(e) =>
                                    handleUpdateFilter(
                                        'archived',
                                        e.target.checked
                                    )
                                }
                            />
                        }
                        label="Include Archived Results"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default SaleTableFilter;
