import api from '../../../../../api';
import CalculateStockOrderTotalCost from './CalculateStockOrderTotalCost';
import { Table } from '../interfaces/StockOrderInterface';
import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Creditor } from '../../../global/interfaces/GeneralInterface';

interface Email {
    id: number;
    reference: string;
    value: string;
}

const SubmitStockOrder = (
    tables: Table[],
    selectedSite: number,
    showSnackbar: showSnackbar,
    submissionType: string,
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    setDialogOpen?: Dispatch<SetStateAction<boolean>>,
    setDialogSupplier?: Dispatch<SetStateAction<Creditor>>,
    setMultipleEmailSupplier?: Dispatch<SetStateAction<Email[]>>
) => {
    setButtonLoading(true);
    var currentTables = [...tables];
    var anySelected = false;
    var selectedTables = [];

    for (let table of currentTables) {
        var newOrderLines = [];
        if (
            table.Supplier.contactDetails.email.length > 1 &&
            !table.selectedEmail &&
            submissionType === 'email'
        ) {
            setDialogSupplier(table.Supplier);
            setMultipleEmailSupplier(table.Supplier.contactDetails.email);
            setDialogOpen(true);
            setButtonLoading(false);
            return;
        }

        for (let row of table.orderLines.filter((x) => x?.selected === true)) {
            newOrderLines.push(row);
            anySelected = true;
        }

        if (newOrderLines.length > 0) {
            table.orderLines = newOrderLines;
            selectedTables.push(table);
        }
    }

    if (!anySelected) {
        showSnackbar(
            'There are no parts selected. Please select some parts to continue.',
            '',
            'error'
        );
        setButtonLoading(false);
        return;
    }

    var obj = {
        tables: selectedTables,
        totalCost: CalculateStockOrderTotalCost(tables).toFixed(2),
        SiteId: selectedSite,
        generated: false
    };

    api.post(
        `stockOrderNextGen?emailed=${submissionType === 'email' ? true : false}`,
        obj
    ).then((res) => {
        if (res.status === 200) {
            showSnackbar('Stock order submitted successfully.');
            setShouldBlockNavigation(false);
            window.location.reload();
        } else {
            showSnackbar(
                'Order not submitted. Please check your data or try again later.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default SubmitStockOrder;
