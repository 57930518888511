// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Grid } from '@mui/material';
// COMPONENTS
import OrderlineHeader from './OrderlineComponents/OrderlineHeader';
import OrderlineTable from './OrderlineComponents/OrderlineTable';
import TotalForm from './OrderlineComponents/TotalForm';
import MiscellaneousCosts from './OrderlineComponents/MiscellaneousCosts';
// INTERFACES
import { Order, StockOrder } from '../../../global/interfaces/PartsInterface';
import {
    Customer,
    PaymentLine
} from '../../../global/interfaces/GeneralInterface';
import {
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface OrderlinesProps {
    id: number;
    readOnly: boolean;
    saleDetails: Order;
    setSaleDetails: Dispatch<SetStateAction<Order>>;
    oldSale: Order;
    selectedType: string;
    selectedEntity: Customer | VehicleSale | Vehicle | Service;
    extraCosts: { id: number; type: string; amount: string }[];
    setExtraCosts: Dispatch<
        SetStateAction<{ id: number; type: string; amount: string }[]>
    >;
    showSnackbar: showSnackbar;
    paymentLines: PaymentLine[];
    stockOrders: StockOrder[];
    setNotesDialogOpen: Dispatch<SetStateAction<boolean>>;
    selectedSite: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
}

const Orderlines = ({
    id,
    readOnly,
    saleDetails,
    setSaleDetails,
    oldSale,
    selectedType,
    selectedEntity,
    extraCosts,
    setExtraCosts,
    showSnackbar,
    paymentLines,
    stockOrders,
    setNotesDialogOpen,
    selectedSite,
    setSelectedSite
}: OrderlinesProps) => {
    return (
        <>
            <OrderlineHeader
                id={id}
                readOnly={readOnly}
                oldSale={oldSale}
                saleDetails={saleDetails}
                setSaleDetails={setSaleDetails}
                selectedType={selectedType}
                selectedEntity={selectedEntity}
                showSnackbar={showSnackbar}
                setNotesDialogOpen={setNotesDialogOpen}
                extraCosts={extraCosts}
                setExtraCosts={setExtraCosts}
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
            />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <OrderlineTable
                        id={id}
                        readOnly={readOnly}
                        oldSale={oldSale}
                        saleDetails={saleDetails}
                        setSaleDetails={setSaleDetails}
                        selectedType={selectedType}
                        showSnackbar={showSnackbar}
                        stockOrders={stockOrders}
                    />
                    <br />
                    <MiscellaneousCosts
                        readOnly={readOnly || oldSale.status === 'Sale'}
                        saleDetails={saleDetails}
                        setSaleDetails={setSaleDetails}
                        extraCosts={extraCosts}
                        setExtraCosts={setExtraCosts}
                        selectedType={selectedType}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TotalForm
                        saleDetails={saleDetails}
                        extraCosts={extraCosts}
                        paymentLines={paymentLines}
                        selectedType={selectedType}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Orderlines;
