// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Typography,
    Divider,
    TextField,
    Tooltip,
    Table,
    TableRow,
    TableCell,
    IconButton
} from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
// INTERFACES
import {
    Vehicle,
    VehicleSale
} from '../../../../global/interfaces/VehicleSaleInterface';
import { Specific } from '../../../../global/interfaces/GeneralInterface';
import { Site } from '../../../../global/interfaces/GeneralInterface';
import RoleCheck from '../../../../global/RoleCheck';
import GetVehicleSaleForVehicle from '../../../../global/databaseLogic/GetVehicleSaleDetails';
import { VisibilityOff, Visibility } from '@mui/icons-material';

interface SpecificsList {
    makes?: Specific[];
    models?: Specific[];
    series?: Specific[];
    aspirations?: Specific[];
    fuelTypes?: Specific[];
    bodyTypes?: Specific[];
    driveTypes?: Specific[];
    sites?: Site[];
}

interface PricingDetailsProps {
    id: number;
    vehicle: Vehicle;
    setVehicle: Dispatch<SetStateAction<Vehicle>>;
    specifics: SpecificsList;
    readOnly: boolean;
}

const PricingDetails = ({
    id,
    vehicle,
    setVehicle,
    specifics,
    readOnly
}: PricingDetailsProps) => {
    const [viewPricing, setViewPricing] = useState<boolean>(false);
    const [vehicleSaleData, setVehicleSaleData] = useState<VehicleSale>(null);

    useEffect(() => {
        GetVehicleSaleForVehicle(id, setVehicleSaleData);
    }, [id, vehicle]);

    const ViewPricingButton = () => {
        if (viewPricing) {
            return (
                <Tooltip placement="right" title="Hide Pricing Details">
                    <IconButton onClick={() => setViewPricing(false)}>
                        <VisibilityOff />
                    </IconButton>
                </Tooltip>
            );
        } else {
            return (
                <Tooltip placement="right" title="Show Pricing Details">
                    <IconButton onClick={() => setViewPricing(true)}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            );
        }
    };

    return (
        <Paper>
            <Typography variant="h6">
                Pricing
                <RoleCheck
                    permission="sales_manager, assign_superusers, edit_basecost"
                    component={<ViewPricingButton />}
                />
            </Typography>
            <Divider />
            <Table>
                <TableRow>
                    <TableCell>RRP</TableCell>
                    <TableCell>
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            variant="standard"
                            size="small"
                            type="number"
                            value={vehicle?.priceRRP}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                setVehicle({
                                    ...vehicle,
                                    priceRRP: e.target.value
                                })
                            }
                            inputProps={{
                                min: 0,
                                style: { textAlign: 'center' }
                            }}
                        />
                    </TableCell>
                </TableRow>
                {viewPricing ? (
                    <>
                        <TableRow>
                            <TableCell>Base Cost</TableCell>
                            <TableCell>
                                {vehicle?.available ? (
                                    <RoleCheck
                                        permission="edit_basecost"
                                        component={
                                            <TextField
                                                disabled={readOnly}
                                                fullWidth
                                                size="small"
                                                variant="standard"
                                                type="number"
                                                value={vehicle?.costPrice}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onChange={(e) =>
                                                    setVehicle({
                                                        ...vehicle,
                                                        costPrice:
                                                            e.target.value
                                                    })
                                                }
                                                inputProps={{
                                                    min: 0,
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }}
                                            />
                                        }
                                        nullComponent={
                                            <Tooltip
                                                title={
                                                    !readOnly
                                                        ? 'This value is entered by admin at the time of invoice and cannot be edited.'
                                                        : ''
                                                }
                                                placement="top"
                                            >
                                                <TextField
                                                    disabled
                                                    fullWidth
                                                    size="small"
                                                    variant="standard"
                                                    value={vehicle?.costPrice}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    inputProps={{
                                                        min: 0,
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                ) : (
                                    <TextField
                                        disabled={readOnly}
                                        fullWidth
                                        size="small"
                                        variant="standard"
                                        type="number"
                                        value={vehicle?.costPrice}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) =>
                                            setVehicle({
                                                ...vehicle,
                                                costPrice: e.target.value
                                            })
                                        }
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Admin Load</TableCell>
                            <TableCell>
                                <RoleCheck
                                    permission="edit_basecost"
                                    component={
                                        <TextField
                                            disabled={readOnly}
                                            fullWidth
                                            size="small"
                                            variant="standard"
                                            type="number"
                                            value={vehicle?.adminLoadCost}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) =>
                                                setVehicle({
                                                    ...vehicle,
                                                    adminLoadCost:
                                                        e.target.value
                                                })
                                            }
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    }
                                    nullComponent={
                                        <Tooltip
                                            title={
                                                !readOnly
                                                    ? 'This value is entered by admin at the time of invoice and cannot be edited.'
                                                    : ''
                                            }
                                            placement="top"
                                        >
                                            <TextField
                                                disabled
                                                fullWidth
                                                size="small"
                                                variant="standard"
                                                value={vehicle?.adminLoadCost}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputProps={{
                                                    min: 0,
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Rebate</TableCell>
                            <TableCell>
                                <Tooltip
                                    title={
                                        !readOnly
                                            ? 'This value is pulled from the associated vehicle sale and cannot be edited here.'
                                            : ''
                                    }
                                    placement="top"
                                >
                                    <TextField
                                        disabled
                                        fullWidth
                                        value={vehicleSaleData?.rebatePrice}
                                        size="small"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </>
                ) : null}
            </Table>
        </Paper>
    );
};
export default PricingDetails;
