import { Dispatch, SetStateAction } from "react";
import api from "../../../../../api";
import { Api, Creditor, OrderFileGenerator, Site } from "../../../global/interfaces/GeneralInterface";

const GetLists = (setApiData: Dispatch<SetStateAction<Api[]>>, setSiteList: Dispatch<SetStateAction<Site[]>>, setSupplierList: Dispatch<SetStateAction<Creditor[]>>, setGeneratorList: Dispatch<SetStateAction<OrderFileGenerator[]>>) => {

    api.get("apis").then((res) => {
        setApiData(res.data);
    });
    api.get("sites").then((res) => {
        setSiteList(res.data);
    });
    api.get("suppliers").then((res) => {
        setSupplierList(res.data);
    });
    api.get("orderFileGeneratorNextGen").then((res) => {
        setGeneratorList(res.data);
    });
};

export default GetLists;
