// IMPORTS
import React, { useState } from 'react';
import {
    Paper,
    Grid,
    Typography,
    Drawer,
    Box,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    useTheme
} from '@mui/material';
import {
    DateFormatter,
    CurrencyFormatter
} from '../../../../../global/logic/Formatters';
import { BalanceSheetLine } from '../interface/BalanceSheetInterface';
import { Site } from '../../../../../global/interfaces/GeneralInterface';
import dayjs, { Dayjs } from 'dayjs';
import { InventoryOtherDrawerContent } from './InventoryOtherDrawerContent';
import { NewInventoryDrawerContent } from './NewInventoryDrawerContent';
import { UsedInventoryDrawerContent } from './UsedInventoryDrawerContent';
import { DebtorPaymentDrawerContent } from './DebtorPaymentDrawerContent';
import { PendingPayrunDrawerContent } from './PendingPayrunDrawerContent';
import { PaymentLiabilityDrawerContent } from './PaymentLiabilityDrawerContent';
import {
    CancelOutlined,
    Check,
    CheckCircleOutline,
    Clear
} from '@mui/icons-material';

interface BalanceSheetComponentsProps {
    (props: {
        singleLine: BalanceSheetLine;
        selectedSite: string | Site;
        queryDate: Dayjs;
    }): JSX.Element;
}

const BalanceSheetComponents: BalanceSheetComponentsProps = (props) => {
    const lightColorFromTheme = useTheme().palette.primary.light;
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [newInventoryDrawerOpen, setNewInventoryDrawerOpen] =
        useState<boolean>(false);
    const [usedInventoryDrawerOpen, setUsedInventoryDrawerOpen] =
        useState<boolean>(false);
    const [inventoryDrawerOpen, setInventoryDrawerOpen] =
        useState<boolean>(false);
    const [debtorPaymentDrawerOpen, setDebtorPaymentDrawerOpen] =
        useState<boolean>(false);
    const [pendingPayrunDrawerOpen, setPendingPayrunDrawerOpen] =
        useState<boolean>(false);
    const [paymentLiabilityDrawerOpen, setPaymentLiabilityDrawerOpen] =
        useState<boolean>(false);
    const [selectedCustomerDeposit, setSelectedCustomerDeposit] = useState([]);
    const [openTransactions, setOpenTransactions] = useState<{
        accountName: string;
        assetType: string;
        initialBalance?: number;
        transactions: {
            url: string;
            id: string;
            date: string;
            reference: string;
            user: string;
            amount: number;
        }[];
        total: string;
    }>({
        accountName: '',
        assetType: '',
        transactions: [],
        total: ''
    });

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    const getGlobalReconciledStatus = (transactions) => {
        let reconciled = true;
        for (let transaction of transactions) {
            if (!getReconciledStatus(transaction)) {
                reconciled = false;
            }
        }
        return reconciled;
    };

    const getReconciledStatus = (transaction) => {
        if (
            transaction.id.includes('SI') &&
            transaction.paymentHistory &&
            transaction.paymentHistory.length > 0
        ) {
            let statuses = transaction.paymentHistory.map((x) => x.reconciled);
            return statuses.some((x) => x === false) ? false : true;
        } else if (transaction.id.includes('MT')) {
            return transaction.reconciled;
        } else {
            return false;
        }
    };

    const getReconciledDate = (transaction) => {
        let date = dayjs();
        // exclude removed payments in transaction payment history
        let validPaymentHistory = transaction.paymentHistory?.filter(
            (x) => x.removed !== true
        );

        // if there are valid payment history lines, find the earliest date: either the bank reconciliation date or the payment date
        if (validPaymentHistory && validPaymentHistory.length > 0) {
            for (let line of validPaymentHistory) {
                if (
                    line.reconciled &&
                    line.bankRecDate &&
                    dayjs(line.bankRecDate).isBefore(date)
                ) {
                    date = dayjs(line.bankRecDate);
                } else if (dayjs(line.date).isBefore(date)) {
                    date = dayjs(line.date);
                }
            }
            return date.format('DD/MM/YYYY');
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Paper className="paper-padding">
                        <Typography variant="h6" align="left">
                            Assets
                        </Typography>
                        <br />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Name</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Site / Type</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Balance</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableRow>
                                <TableCell
                                    onClick={() =>
                                        typeof props.selectedSite != 'string'
                                            ? setNewInventoryDrawerOpen(true)
                                            : null
                                    }
                                    sx={{
                                        cursor:
                                            typeof props.selectedSite !=
                                            'string'
                                                ? 'pointer'
                                                : 'default',
                                        color:
                                            typeof props.selectedSite !=
                                            'string'
                                                ? lightColorFromTheme
                                                : null
                                    }}
                                >
                                    Inventory New
                                </TableCell>
                                <TableCell>
                                    {typeof props.selectedSite != 'string'
                                        ? props.selectedSite?.name
                                        : 'All Sites'}{' '}
                                    / Current Asset
                                </TableCell>
                                <TableCell>
                                    {CurrencyFormatter(
                                        props.singleLine?.newVehiclesTotal
                                    )}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    onClick={() =>
                                        typeof props.selectedSite != 'string'
                                            ? setUsedInventoryDrawerOpen(true)
                                            : null
                                    }
                                    sx={{
                                        cursor:
                                            typeof props.selectedSite !=
                                            'string'
                                                ? 'pointer'
                                                : 'default',
                                        color:
                                            typeof props.selectedSite !=
                                            'string'
                                                ? lightColorFromTheme
                                                : null
                                    }}
                                >
                                    Inventory Used
                                </TableCell>
                                <TableCell>
                                    {typeof props.selectedSite != 'string'
                                        ? props.selectedSite?.name
                                        : 'All Sites'}{' '}
                                    / Current Asset
                                </TableCell>
                                <TableCell>
                                    {CurrencyFormatter(
                                        props.singleLine?.usedVehiclesTotal
                                    )}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    onClick={() =>
                                        typeof props.selectedSite != 'string'
                                            ? setInventoryDrawerOpen(true)
                                            : null
                                    }
                                    sx={{
                                        cursor:
                                            typeof props.selectedSite !=
                                            'string'
                                                ? 'pointer'
                                                : 'default',
                                        color:
                                            typeof props.selectedSite !=
                                            'string'
                                                ? lightColorFromTheme
                                                : null
                                    }}
                                >
                                    Inventory Other
                                </TableCell>
                                <TableCell>
                                    {typeof props.selectedSite != 'string'
                                        ? props.selectedSite?.name
                                        : 'All Sites'}{' '}
                                    / Current Asset
                                </TableCell>
                                <TableCell>
                                    {CurrencyFormatter(
                                        props.singleLine?.stockOnHandTotal
                                    )}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    onClick={() =>
                                        typeof props.selectedSite != 'string'
                                            ? setDebtorPaymentDrawerOpen(true)
                                            : null
                                    }
                                    sx={{
                                        cursor:
                                            typeof props.selectedSite !=
                                            'string'
                                                ? 'pointer'
                                                : 'default',
                                        color:
                                            typeof props.selectedSite !=
                                            'string'
                                                ? lightColorFromTheme
                                                : null
                                    }}
                                >
                                    Pending Debtor Payment
                                </TableCell>
                                <TableCell>
                                    {typeof props.selectedSite != 'string'
                                        ? props.selectedSite?.name
                                        : 'All Sites'}{' '}
                                    / Current Asset
                                </TableCell>
                                <TableCell>
                                    {CurrencyFormatter(
                                        props.singleLine?.pendingDebtorPayment
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* <TableRow>
                <TableCell>Cash at Bank</TableCell>
                <TableCell>
                  {typeof props.selectedSite != 'string'
                    ? props.selectedSite?.name
                    : 'All Sites'}{' '}
                  / Current Asset
                </TableCell>
                <TableCell>
                  {CurrencyFormatter(props.singleLine?.cashAtBankTotal)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Cash Holding</TableCell>
                <TableCell>
                  {typeof props.selectedSite != 'string'
                    ? props.selectedSite?.name
                    : 'All Sites'}{' '}
                  / Current Asset
                </TableCell>
                <TableCell>
                  {CurrencyFormatter(props.singleLine?.cashHoldingTotal)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Stock Adjustment</TableCell>
                <TableCell>
                  {typeof props.selectedSite != 'string'
                    ? props.selectedSite?.name
                    : 'All Sites'}{' '}
                  / Current Asset
                </TableCell>
                <TableCell>
                  {CurrencyFormatter(props.singleLine?.stockAdjustmentTotal)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Vehicle Rebates</TableCell>
                <TableCell>
                  {typeof props.selectedSite != 'string'
                    ? props.selectedSite?.name
                    : 'All Sites'}{' '}
                  / Current Asset
                </TableCell>
                <TableCell>
                  {CurrencyFormatter(props.singleLine?.rebateBalanceTotal)}
                </TableCell>
              </TableRow> */}

                            {props.singleLine?.accountLines?.map((row) => {
                                if (
                                    (row.assetType === 'Asset (Current)' ||
                                        row.assetType ===
                                            'Asset (Non-Current)') &&
                                    (row.transactions.reduce(
                                        (acc, transaction) =>
                                            acc + transaction.amount,
                                        0
                                    ) +
                                        row.initialBalance >
                                        0.01 ||
                                        row.transactions.reduce(
                                            (acc, transaction) =>
                                                acc + transaction.amount,
                                            0
                                        ) +
                                            row.initialBalance <
                                            -0.01)
                                ) {
                                    return (
                                        <TableRow>
                                            <TableCell
                                                id="table-link"
                                                onClick={() => {
                                                    setDrawerOpen(true);
                                                    setOpenTransactions(row);
                                                }}
                                            >
                                                {row.AccountId} -{' '}
                                                {row.accountName}
                                            </TableCell>
                                            <TableCell>
                                                {row.accountSite}
                                                {row.assetType ===
                                                'Asset (Current)'
                                                    ? 'Current Asset'
                                                    : 'Non-Current Asset'}
                                            </TableCell>
                                            <TableCell>
                                                {CurrencyFormatter(
                                                    row.initialBalance != null
                                                        ? row.initialBalance +
                                                              row.transactions.reduce(
                                                                  (
                                                                      acc,
                                                                      transaction
                                                                  ) =>
                                                                      acc -
                                                                      transaction.amount,
                                                                  0
                                                              )
                                                        : row.transactions.reduce(
                                                              (
                                                                  acc,
                                                                  transaction
                                                              ) =>
                                                                  acc -
                                                                  transaction.amount,
                                                              0
                                                          )
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {getGlobalReconciledStatus(
                                                    row.transactions
                                                ) ? (
                                                    <CheckCircleOutline
                                                        style={{
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <CancelOutlined
                                                        style={{ color: 'red' }}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Table>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className="paper-padding">
                        <Typography variant="h6" align="left">
                            Liabilities
                        </Typography>
                        <br />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Name</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Site / Type</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Balance</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableRow>
                                <TableCell>Floorplan Liability</TableCell>
                                <TableCell>
                                    {typeof props.selectedSite != 'string'
                                        ? props.selectedSite?.name
                                        : 'All Sites'}{' '}
                                    / Current Asset
                                </TableCell>
                                <TableCell>
                                    {CurrencyFormatter(
                                        props.singleLine
                                            ?.floorplanLiabilityTotal
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    id="table-link"
                                    onClick={() => {
                                        setSelectedCustomerDeposit(
                                            props.singleLine
                                                ?.paymentLiabilityDataPA
                                        );
                                        setPaymentLiabilityDrawerOpen(true);
                                    }}
                                >
                                    Parts & Accessories Customer Deposit
                                </TableCell>
                                <TableCell>
                                    {typeof props.selectedSite != 'string'
                                        ? props.selectedSite?.name
                                        : 'All Sites'}{' '}
                                    / Current Asset
                                </TableCell>
                                <TableCell>
                                    {CurrencyFormatter(
                                        props.singleLine?.paymentLiabilityPA
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    id="table-link"
                                    onClick={() => {
                                        setSelectedCustomerDeposit(
                                            props.singleLine
                                                ?.paymentLiabilityDataVehicleSale
                                        );
                                        setPaymentLiabilityDrawerOpen(true);
                                    }}
                                >
                                    Vehicle Sale Customer Deposit
                                </TableCell>
                                <TableCell>
                                    {typeof props.selectedSite != 'string'
                                        ? props.selectedSite?.name
                                        : 'All Sites'}{' '}
                                    / Current Asset
                                </TableCell>
                                <TableCell>
                                    {CurrencyFormatter(
                                        props.singleLine
                                            ?.paymentLiabilityVehicleSale
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    id="table-link"
                                    onClick={() => {
                                        setSelectedCustomerDeposit(
                                            props.singleLine
                                                ?.paymentLiabilityDataService
                                        );
                                        setPaymentLiabilityDrawerOpen(true);
                                    }}
                                >
                                    Service Customer Deposit
                                </TableCell>
                                <TableCell>
                                    {typeof props.selectedSite != 'string'
                                        ? props.selectedSite?.name
                                        : 'All Sites'}{' '}
                                    / Current Asset
                                </TableCell>
                                <TableCell>
                                    {CurrencyFormatter(
                                        props.singleLine
                                            ?.paymentLiabilityService
                                    )}
                                </TableCell>
                            </TableRow>
                            {typeof props.selectedSite != 'string' ? null : (
                                <TableRow>
                                    <TableCell
                                        id="table-link"
                                        onClick={() => {
                                            setPendingPayrunDrawerOpen(true);
                                        }}
                                    >
                                        Pending Payment Runs
                                    </TableCell>
                                    <TableCell>
                                        All Sites / Current Asset
                                    </TableCell>
                                    <TableCell>
                                        {CurrencyFormatter(
                                            props.singleLine?.pendingPayrun
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                            {typeof props.selectedSite != 'string' ? null : (
                                <TableRow>
                                    <TableCell>Unused Gift Vouchers</TableCell>
                                    <TableCell>
                                        All Sites / Current Asset
                                    </TableCell>
                                    <TableCell>
                                        {CurrencyFormatter(
                                            props.singleLine?.giftVoucher
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}

                            {/* <TableRow>
                <TableCell>Registration Clearing</TableCell>
                <TableCell>
                  {typeof props.selectedSite != 'string'
                    ? props.selectedSite?.name
                    : 'All Sites'}{' '}
                  / Current Asset
                </TableCell>
                <TableCell>
                  {CurrencyFormatter(
                    props.singleLine?.registrationClearingTotal
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Average Cost Adjustments</TableCell>
                <TableCell>
                  {typeof props.selectedSite != 'string'
                    ? props.selectedSite?.name
                    : 'All Sites'}{' '}
                  / Current Asset
                </TableCell>
                <TableCell>
                  {CurrencyFormatter(
                    props.singleLine?.averageCostAdjustmentTotal
                  )}
                </TableCell>
              </TableRow> */}

                            {props.singleLine?.accountLines?.map((row) => {
                                if (
                                    (row.assetType === 'Liability (Current)' ||
                                        row.assetType ===
                                            'Liability (Non-Current)') &&
                                    (row.transactions.reduce(
                                        (acc, transaction) =>
                                            acc + transaction.amount,
                                        0
                                    ) +
                                        row.initialBalance >
                                        0.01 ||
                                        row.transactions.reduce(
                                            (acc, transaction) =>
                                                acc + transaction.amount,
                                            0
                                        ) +
                                            row.initialBalance <
                                            -0.01)
                                ) {
                                    return (
                                        <TableRow>
                                            <TableCell
                                                id="table-link"
                                                onClick={() => {
                                                    setDrawerOpen(true);
                                                    setOpenTransactions(row);
                                                }}
                                            >
                                                {row.AccountId} -{' '}
                                                {row.accountName}
                                            </TableCell>
                                            <TableCell>
                                                {row.accountSite}
                                                {row.assetType ===
                                                'Liability (Current)'
                                                    ? 'Current Liability'
                                                    : 'Non-Current Liability'}
                                            </TableCell>
                                            <TableCell>
                                                {CurrencyFormatter(
                                                    row.initialBalance != null
                                                        ? row.initialBalance -
                                                              row.transactions.reduce(
                                                                  (
                                                                      acc,
                                                                      transaction
                                                                  ) =>
                                                                      acc +
                                                                      transaction.amount,
                                                                  0
                                                              )
                                                        : row.transactions.reduce(
                                                              (
                                                                  acc,
                                                                  transaction
                                                              ) =>
                                                                  acc +
                                                                  transaction.amount,
                                                              0
                                                          )
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {getGlobalReconciledStatus(
                                                    row.transactions
                                                ) ? (
                                                    <CheckCircleOutline
                                                        style={{
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <CancelOutlined
                                                        style={{ color: 'red' }}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Table>
                    </Paper>
                </Grid>
            </Grid>

            <Drawer
                anchor={'right'}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{ width: '40vw', padding: '20px' }}
                    role="presentation"
                >
                    <Typography variant="h4">Account Transactions</Typography>
                    <Typography>
                        {openTransactions.accountName} -{' '}
                        {openTransactions.assetType}
                    </Typography>
                    {openTransactions.initialBalance ? (
                        <Typography>
                            Initial Balance:{' '}
                            {CurrencyFormatter(openTransactions.initialBalance)}
                        </Typography>
                    ) : null}
                    <Box height={'10px'}></Box>
                    <Divider></Divider>
                    <Box height="20px"></Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong>ID</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Date</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Reference</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>User</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Amount</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Reconciled</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Latest Reconcilation Date</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {openTransactions?.transactions?.map((transaction) => (
                            <TableRow>
                                <TableCell
                                    id="table-link"
                                    onClick={() =>
                                        window.open(transaction.url, '_blank')
                                    }
                                >
                                    {transaction.id}
                                </TableCell>
                                <TableCell>
                                    {DateFormatter(transaction.date)}
                                </TableCell>
                                <TableCell>{transaction.reference}</TableCell>
                                <TableCell>{transaction.user}</TableCell>
                                <TableCell>
                                    {CurrencyFormatter(
                                        openTransactions.assetType.includes(
                                            'Asset'
                                        )
                                            ? -transaction.amount
                                            : transaction.amount
                                    )}
                                </TableCell>
                                <TableCell>
                                    {getReconciledStatus(transaction) ? (
                                        <Check style={{ color: 'green' }} />
                                    ) : (
                                        <Clear style={{ color: 'red' }} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {getReconciledDate(transaction)}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={3}></TableCell>
                            <TableCell>
                                <b>TOTAL</b>
                            </TableCell>
                            <TableCell>
                                <b>
                                    {CurrencyFormatter(
                                        openTransactions.assetType.includes(
                                            'Asset'
                                        ) &&
                                            openTransactions.initialBalance !=
                                                null
                                            ? openTransactions.initialBalance +
                                                  openTransactions.transactions.reduce(
                                                      (acc, transaction) =>
                                                          acc -
                                                          transaction.amount,
                                                      0
                                                  )
                                            : openTransactions.assetType.includes(
                                                    'Liability'
                                                ) &&
                                                openTransactions.initialBalance !=
                                                    null
                                              ? openTransactions.initialBalance -
                                                openTransactions.transactions.reduce(
                                                    (acc, transaction) =>
                                                        acc +
                                                        transaction.amount,
                                                    0
                                                )
                                              : openTransactions.transactions.reduce(
                                                    (acc, transaction) =>
                                                        acc +
                                                        (openTransactions.assetType.includes(
                                                            'Asset'
                                                        )
                                                            ? -transaction.amount
                                                            : transaction.amount),
                                                    0
                                                )
                                    )}
                                </b>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </Table>
                </Box>
            </Drawer>

            <Drawer
                anchor="right"
                open={newInventoryDrawerOpen}
                onClose={() => setNewInventoryDrawerOpen(false)}
            >
                <NewInventoryDrawerContent
                    selectedSite={props.selectedSite}
                    queryDate={props.queryDate}
                    newVehicleData={props.singleLine?.newVehiclesData}
                />
            </Drawer>
            <Drawer
                anchor="right"
                open={usedInventoryDrawerOpen}
                onClose={() => setUsedInventoryDrawerOpen(false)}
            >
                <UsedInventoryDrawerContent
                    selectedSite={props.selectedSite}
                    queryDate={props.queryDate}
                    usedVehicleData={props.singleLine?.usedVehiclesData}
                />
            </Drawer>
            <Drawer
                anchor="right"
                open={inventoryDrawerOpen}
                onClose={() => setInventoryDrawerOpen(false)}
            >
                <InventoryOtherDrawerContent
                    selectedSite={props.selectedSite}
                    queryDate={props.queryDate}
                    singleLine={props.singleLine}
                />
            </Drawer>
            <Drawer
                anchor="right"
                open={debtorPaymentDrawerOpen}
                onClose={() => setDebtorPaymentDrawerOpen(false)}
            >
                <DebtorPaymentDrawerContent
                    selectedSite={props.selectedSite}
                    queryDate={props.queryDate}
                    debtorPayments={props.singleLine?.debtorPaymentData}
                />
            </Drawer>
            <Drawer
                anchor="right"
                open={pendingPayrunDrawerOpen}
                onClose={() => setPendingPayrunDrawerOpen(false)}
            >
                <PendingPayrunDrawerContent
                    selectedSite={props.selectedSite}
                    queryDate={props.queryDate}
                    pendingPayrun={
                        props.singleLine?.pendingPayrunData.filter(
                            (x) => x.length > 0
                        ).length > 0
                            ? props.singleLine?.pendingPayrunData.filter(
                                  (x) => x.length > 0
                              )[0]
                            : []
                    }
                />
            </Drawer>
            <Drawer
                anchor="right"
                open={paymentLiabilityDrawerOpen}
                onClose={() => setPaymentLiabilityDrawerOpen(false)}
            >
                <PaymentLiabilityDrawerContent
                    selectedSite={props.selectedSite}
                    queryDate={props.queryDate}
                    paymentLiabilityData={selectedCustomerDeposit}
                />
            </Drawer>
        </>
    );
};

export default BalanceSheetComponents;
