// IMPORTS
import { saveAs } from 'file-saver';

/**
 * DownloadABA
 * Save the generated ABA file
 * @author Estienne
 * @param file the file to be saved
 */
const DownloadABA = (file: any) => {
  let blob = new Blob([file], {
    type: 'text/plain;charset=utf-8'
  });

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let currentDate = `${date}-${month}-${year}`;

  saveAs(blob, `${currentDate}-CreditorPayment.ABA`);
  window.location.reload();
};

export default DownloadABA;
