import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';

import Papa from 'papaparse';

import { Cancel, CheckCircle, Clear } from '@mui/icons-material';
import DataTable from '../../global/tableComponents/DataTable';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import {
    checkBodyType,
    checkCondition,
    checkCostPrice,
    checkCylinders,
    checkDriveType,
    checkEngineSize,
    checkFuelType,
    checkLearnerApproved,
    checkOdometer,
    checkRRP,
    checkRegistrationDueDate,
    checkVin,
    checkYear
} from './logic/ImportVehicleValidation';
import { ImportVehicleFromCSV } from './logic/ImportVehicleFromCSV';

export const ImportVehicleCSVContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [importedData, setImportedData] = useState([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [importedDataFilename, setImportedDataFilename] = useState([]);

    var headerOptions = [
        { id: 0, label: 'Make Name', value: 'makeName' },
        { id: 1, label: 'Model Name', value: 'modelName' },
        { id: 2, label: 'Series Name', value: 'seriesName' },
        { id: 3, label: 'Condition', value: 'condition' },
        { id: 4, label: 'Odometer', value: 'odometer' },
        { id: 5, label: 'Engine Size', value: 'engineSize' },
        { id: 6, label: 'Model Year', value: 'year' },
        { id: 7, label: 'Cost Price (Ex. GST)', value: 'costPrice' },
        { id: 8, label: 'RRP', value: 'priceRRP' },
        { id: 9, label: 'Colour', value: 'colour' },
        { id: 10, label: 'Cylinders', value: 'cylinders' },
        { id: 11, label: 'Registration Number', value: 'registrationNumber' },
        {
            id: 12,
            label: 'Registration Due Date',
            value: 'registrationDueDate'
        },
        { id: 13, label: 'VIN', value: 'vin' },
        { id: 14, label: 'Engine Number', value: 'engineNumber' },
        { id: 15, label: 'Transmission', value: 'transmission' },
        { id: 16, label: 'Learner Approved', value: 'learnerApproved' },
        // { id: 17, label: 'Aspiration', value: 'AspirationId' }, // Only one in Ora currently (NATURALLY ASPIRATED) so it will default to this one
        { id: 18, label: 'Body Type', value: 'BodyType' },
        { id: 19, label: 'Drive Type', value: 'DriveType' },
        { id: 20, label: 'Fuel Type', value: 'FuelType' },
        { id: 21, label: 'Site Name', value: 'SiteName' }
    ];

    const parseCSV = (e) => {
        Papa.parse(e, {
            header: false,
            skipEmptyLines: 'greedy',
            complete: (results) => {
                var newData = [];
                let maxLength = 0;
                results.data.forEach((row, i) => {
                    newData.push(row);
                    maxLength = maxLength < row.length ? row.length : maxLength;
                });
                setImportedData(newData);

                let newHeaders = new Array(maxLength);
                newHeaders.fill('');
                setHeaders(newHeaders);
            }
        });
    };

    const line = (title, description, required = false) => {
        return (
            <>
                <Grid item xs={3}>
                    {title}
                </Grid>
                <Grid item xs={1} textAlign={'center'}>
                    {required ? 'X' : null}
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="caption">{description}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </>
        );
    };

    const importFile = (e) => {
        setImportedDataFilename(e.target.files[0].name);
        parseCSV(e.target.files[0]);
    };

    const deleteColumn = (index) => {
        let currentHeaders = [...headers];
        let currentLines = [...importedData];

        currentHeaders.splice(index, 1);
        for (let item of currentLines) {
            item.splice(index, 1);
        }

        setHeaders(currentHeaders);
        setImportedData(currentLines);
    };

    const deleteLine = (index) => {
        let currentLines = [...importedData];
        currentLines.splice(index, 1);
        setImportedData(currentLines);
    };

    const updateColumnsHeaders = (value: string, index: number) => {
        // Field to validate: condition, odometer, year, costprice, vin, cylinders, bodytype, drivetype, fueltype, rrp, engine size, registration due date,learner approved
        if (value === 'condition') {
            let valid = checkCondition(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'odometer') {
            let valid = checkOdometer(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'year') {
            let valid = checkYear(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'costPrice') {
            let valid = checkCostPrice(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'vin') {
            let valid = checkVin(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'cylinders') {
            let valid = checkCylinders(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'BodyType') {
            let valid = checkBodyType(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'DriveType') {
            let valid = checkDriveType(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'FuelType') {
            let valid = checkFuelType(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'rrp') {
            let valid = checkRRP(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'engineSize') {
            let valid = checkEngineSize(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'registrationDueDate') {
            let valid = checkRegistrationDueDate(
                index,
                importedData,
                showSnackbar
            );
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else if (value === 'learnerApproved') {
            let valid = checkLearnerApproved(index, importedData, showSnackbar);
            if (valid.valid) {
                let currentHeaders = [...headers];
                currentHeaders[index] = value;
                setHeaders(currentHeaders);
            }
        } else {
            let currentHeaders = [...headers];
            currentHeaders[index] = value;
            setHeaders(currentHeaders);
        }
    };

    const checkHeaders = () => {
        if (
            headers.includes('makeName') &&
            headers.includes('modelName') &&
            headers.includes('condition') &&
            headers.includes('odometer') &&
            headers.includes('year') &&
            headers.includes('costPrice') &&
            headers.includes('vin') &&
            headers.includes('cylinders') &&
            headers.includes('BodyType') &&
            headers.includes('DriveType') &&
            headers.includes('FuelType') &&
            headers.includes('SiteName')
        ) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <DialogTitle>Import Vehicle CSV</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign={'center'}>
                        {importedData.length > 0 ? (
                            importedDataFilename + ' uploaded.'
                        ) : (
                            <>
                                <input
                                    onChange={(e) => importFile(e)}
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="outlined" component="span">
                                        Upload CSV
                                    </Button>
                                </label>
                            </>
                        )}
                    </Grid>
                    {importedData.length > 0 ? (
                        <Grid item xs={12}>
                            <Grid container spacing={0} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Use the below interface to select which
                                        columns in the CSV correspond to which
                                        property. You must select the required
                                        columns:
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'makeName'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Make name
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'modelName'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Model name
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'condition'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Condition
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'odometer'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Odometer
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes('year') ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Model Year
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'costPrice'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Cost Price (Ex. GST)
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes('vin') ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                VIN
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'cylinders'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Cylinders
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'FuelType'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Fuel Type
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'DriveType'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Drive Type
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'BodyType'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Body Type
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            textAlign="center"
                                            alignItems="center"
                                            xs={2}
                                            sx={{ border: 1 }}
                                        >
                                            <Typography>
                                                {headers.includes(
                                                    'SiteName'
                                                ) ? (
                                                    <CheckCircle
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'green'
                                                        }}
                                                    />
                                                ) : (
                                                    <Cancel
                                                        style={{
                                                            fontSize: '14px',
                                                            marginRight: '2px',
                                                            color: 'firebrick'
                                                        }}
                                                    />
                                                )}
                                                Site Name
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography>
                                        The import button will be greyed out
                                        until you have selected all of them.
                                    </Typography>
                                    <Typography>
                                        Using the{' '}
                                        <Clear
                                            style={{ verticalAlign: 'middle' }}
                                        />{' '}
                                        button will tell Ora to delete the
                                        corresponding row/column from the
                                        imported data.
                                    </Typography>
                                    <Typography>
                                        This can be used to delete headers for
                                        example.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Grid container spacing={0} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <Typography>
                                        Ora expects the following value to be in
                                        the CSV in order to create the Vehicle:
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} textAlign={'center'}>
                                    Property
                                </Grid>
                                <Grid item xs={1} textAlign={'center'}>
                                    Required
                                </Grid>
                                <Grid item xs={8} textAlign={'center'}>
                                    Description
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                {line(
                                    'Make Name',
                                    "If it doesn't match the name of an existing Make, a new one will be created.",
                                    true
                                )}
                                {line(
                                    'Model Name',
                                    "If it doesn't match the name of an existing Model for the Vehicle Make, a new one will be created.",
                                    true
                                )}
                                {line(
                                    'Series Name',
                                    "If it doesn't match the name of an existing Series for the Vehicle Model, a new one will be created."
                                )}
                                {line(
                                    'Condition',
                                    'Must be "New", "Used" or "Demo".',
                                    true
                                )}
                                {line(
                                    'Odometer',
                                    'Must be number and correspond to kilometers.',
                                    true
                                )}
                                {line(
                                    'Model Year',
                                    'Must be a 4 digit number.',
                                    true
                                )}
                                {line(
                                    'Cost Price (Ex. GST)',
                                    'Must be number and must be the cost excluding GST.',
                                    true
                                )}
                                {line(
                                    'VIN',
                                    'Must be a valid VIN and must be unique (Ora will not accept two vehicles with the same VIN).',
                                    true
                                )}
                                {line('Cylinders', 'Must be a number.', true)}
                                {line(
                                    'Body Type',
                                    'Accepted string: "SCOOTER", "MOTORCYCLE", "QUAD" or "SXS". Anything else will cause Ora to ignore the line.',
                                    true
                                )}
                                {line(
                                    'Drive Type',
                                    'Accepted string: "REAR WHEEL", "CHAIN", "4 WHEEL", "ALL WHEEL" or "FRONT WHEEL". Anything else will cause Ora to ignore the line.',
                                    true
                                )}
                                {line(
                                    'Fuel Type',
                                    'Accepted string: "UNLEADED" or "ELECTRIC". Anything else will cause Ora to ignore the line.',
                                    true
                                )}
                                {line(
                                    'Site',
                                    'Must match one of the existing Site Name (see Site page). Anything else will cause Ora to ignore the line.',
                                    true
                                )}
                                {line(
                                    'Engine Size',
                                    'Must be a number and must be in cubic centimeters.'
                                )}
                                {line('RRP', 'Must be a number.')}
                                {line(
                                    'Colour',
                                    'Can be any string of characters.'
                                )}
                                {line(
                                    'Registration Number',
                                    'Can be any string of characters.'
                                )}
                                {line(
                                    'Registration Due Date',
                                    'Must be in the DD/MM/YYYY format.'
                                )}
                                {line(
                                    'Engine Number',
                                    'Can be any string of characters.'
                                )}
                                {line(
                                    'Transmission',
                                    'Must "Manual" or "Automatic", if there is an empty value Ora will default to Manual.'
                                )}
                                {line(
                                    'Learner Approved',
                                    'Must be 0 or 1. If the vehicle is learner approved this column should be equal to 1.'
                                )}
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <DataTable columns={[]}>
                            {importedData.length > 0 ? (
                                <>
                                    <TableRow>
                                        {headers.map((item, index) => (
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        deleteColumn(index)
                                                    }
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        {headers.map((item, index) => (
                                            <TableCell>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    size="small"
                                                    value={item}
                                                    onChange={(e) => {
                                                        updateColumnsHeaders(
                                                            e.target.value,
                                                            index
                                                        );
                                                    }}
                                                >
                                                    {headerOptions.map(
                                                        (option) => (
                                                            <MenuItem
                                                                value={
                                                                    option.value
                                                                }
                                                                disabled={headers.includes(
                                                                    option.value
                                                                )}
                                                            >
                                                                {option.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    {importedData
                                        .slice(0, 5)
                                        .map((line, index) => (
                                            <TableRow>
                                                {line.map((item) => (
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                ))}
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteLine(index)
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    <TableRow>
                                        {headers.map((item) => (
                                            <TableCell>...</TableCell>
                                        ))}
                                        <TableCell>...</TableCell>
                                    </TableRow>
                                    {importedData
                                        .slice(
                                            importedData.length - 5,
                                            importedData.length
                                        )
                                        .map((line) => (
                                            <TableRow>
                                                {line.map((item) => (
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                ))}
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteLine(
                                                                importedData.findIndex(
                                                                    (x) =>
                                                                        x[0] ===
                                                                            line[0] &&
                                                                        x[1] ===
                                                                            line[1]
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <Clear />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </>
                            ) : null}
                        </DataTable>
                    </Grid>
                </Grid>
            </DialogContent>
            {importedData.length > 0 ? (
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={checkHeaders()}
                        onClick={() =>
                            ImportVehicleFromCSV(
                                importedData,
                                headers,
                                showSnackbar
                            )
                        }
                    >
                        Import Vehicle(s)
                    </Button>
                </DialogActions>
            ) : null}
        </>
    );
};
