import { Dispatch, SetStateAction } from 'react';
import {
    MultipleStockInvoice,
    StockInvoice
} from '../../../global/interfaces/PartsInterface';
import ValidateOrderLines from './ValidateOrderLines';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';
import PrintLabel from '../../../global/logic/PrintLabel';
import PDFPickSlip from './PDFPickSlip';
import ValidateMultipleOrderLines from './ValidateMultipleOrderLines';
import CheckInvoiceData from './CheckInvoiceData';

const SubmitStockInvoice = (
    StockOrderId: number,
    isMultipleInvoice: boolean,
    invoiceData: StockInvoice,
    multipleInvoiceData: MultipleStockInvoice,
    setInvoiceData: Dispatch<SetStateAction<StockInvoice>>,
    setMultipleInvoiceData: Dispatch<SetStateAction<MultipleStockInvoice>>,
    setInvoiceError: Dispatch<SetStateAction<string>>,
    showSnackbar: showSnackbar
) => {
    let validateOrderLines;
    if (isMultipleInvoice) {
        validateOrderLines = ValidateMultipleOrderLines(
            multipleInvoiceData,
            setMultipleInvoiceData
        );
    } else {
        validateOrderLines = ValidateOrderLines(invoiceData, setInvoiceData);
    }

    // Check the orderlines and make sure they all contain a valid quantity and cost
    if (validateOrderLines) {
        showSnackbar(
            'There are some errors in your invoice lines.',
            'Errors have been highlighted in red.',
            'error'
        );
        return;
    }

    let isError = false;
    if (isMultipleInvoice) {
        isError = CheckInvoiceData(
            multipleInvoiceData,
            isMultipleInvoice,
            showSnackbar,
            setInvoiceError
        );
    } else {
        isError = CheckInvoiceData(
            invoiceData,
            isMultipleInvoice,
            showSnackbar,
            setInvoiceError
        );
    }

    if (isError) {
        return;
    }

    // Delete all removed line from the orderLines before sending to backend
    // Don't do it for multiple invoices, a deleted line is handled by the backend
    if (!isMultipleInvoice) {
        let finalOrderLines = invoiceData.orderLines.filter((x) => !x.removed);
        invoiceData.orderLines = finalOrderLines;
    }

    // Now submit the invoice

    if (isMultipleInvoice) {
        api.post(`newMultipleStockInvoice?SiteId=${invoiceData.SiteId}`, {
            invoiceData: multipleInvoiceData
        }).then((res) => {
            if (res.status === 200) {
                PrintLabel(res.data.labelData, showSnackbar);
                if (res.data.pickSlipData.length > 0) {
                    PDFPickSlip(res.data.pickSlipData);
                }
                // If it went fine, delete the orderlines from local storage
                localStorage.removeItem('stockInvoiceData');
                window.location.reload();
            } else {
                showSnackbar(
                    'Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    } else {
        api.post(`newStockInvoice?SiteId=${invoiceData.SiteId}`, {
            StockOrderId: StockOrderId,
            invoiceData: invoiceData
        }).then((res) => {
            if (res.status === 200) {
                PrintLabel(res.data.labelData, showSnackbar);
                if (res.data.pickSlipData.length > 0) {
                    PDFPickSlip(res.data.pickSlipData);
                }
                // If it went fine, delete the orderlines from local storage
                localStorage.removeItem('stockInvoiceData');
                window.location.reload();
            } else {
                showSnackbar(
                    'Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    }
};

export default SubmitStockInvoice;
