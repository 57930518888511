// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { IconButton, TableRow, TextField, Typography } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
// INTERFACES
import { Service } from '../../../../global/interfaces/ServiceInterface';
interface MiscellaneousProps {
    readOnly: boolean;
    serviceDetails: Service;
    setServiceDetails: Dispatch<SetStateAction<Service>>;
}

const Miscellaneous = ({
    readOnly,
    serviceDetails,
    setServiceDetails
}: MiscellaneousProps) => {
    const miscColumns = [
        { id: 0, label: 'Description', width: 500 },
        { id: 1, label: 'Amount (EX. GST)', width: 300 },
        { id: 2, label: '', width: 50 }
    ];

    const handleAddMiscCost = () => {
        let currentCosts = [...serviceDetails.miscCosts];
        let newLine = {
            id:
                currentCosts.length > 0
                    ? currentCosts[currentCosts.length - 1].id + 1
                    : 1,
            description: '',
            amount: '0'
        };
        currentCosts.push(newLine);
        setServiceDetails({ ...serviceDetails, miscCosts: currentCosts });
    };

    const handleEditMiscCost = (index, type, value) => {
        let currentCosts = [...serviceDetails.miscCosts];
        currentCosts[index][type] = value;
        setServiceDetails({ ...serviceDetails, miscCosts: currentCosts });
    };

    const handleRemoveMiscCost = (id: number) => {
        let currentCosts = [...serviceDetails.miscCosts];
        currentCosts = currentCosts.filter((x) => x.id !== id);
        setServiceDetails({ ...serviceDetails, miscCosts: currentCosts });
    };

    return (
        <>
            <Paper>
                <Typography variant="h6">
                    Miscellaneous
                    {!readOnly &&
                        serviceDetails.serviceComplete !== 'Finalised' && (
                            <IconButton
                                onClick={() => handleAddMiscCost()}
                                disabled={readOnly}
                            >
                                <Add />
                            </IconButton>
                        )}
                </Typography>
                {serviceDetails.miscCosts.length > 0 && (
                    <DataTable columns={miscColumns}>
                        {serviceDetails.miscCosts.map((line, index) => (
                            <TableRow>
                                <DataCell>
                                    {readOnly ? (
                                        line.description.toUpperCase()
                                    ) : (
                                        <TextField
                                            fullWidth
                                            disabled={readOnly}
                                            size="small"
                                            variant="standard"
                                            inputProps={{
                                                style: { textAlign: 'center' }
                                            }}
                                            value={line.description}
                                            onChange={(e) =>
                                                handleEditMiscCost(
                                                    index,
                                                    'description',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    )}
                                </DataCell>
                                <DataCell>
                                    {readOnly ? (
                                        CurrencyFormatter(line.amount)
                                    ) : (
                                        <TextField
                                            fullWidth
                                            disabled={readOnly}
                                            size="small"
                                            type="number"
                                            variant="standard"
                                            inputProps={{
                                                style: { textAlign: 'center' }
                                            }}
                                            value={line.amount}
                                            onChange={(e) =>
                                                handleEditMiscCost(
                                                    index,
                                                    'amount',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    )}
                                </DataCell>
                                <DataCell>
                                    {readOnly ? null : (
                                        <IconButton
                                            onClick={() =>
                                                handleRemoveMiscCost(line.id)
                                            }
                                        >
                                            <Close />
                                        </IconButton>
                                    )}
                                </DataCell>
                            </TableRow>
                        ))}
                    </DataTable>
                )}
            </Paper>
        </>
    );
};

export default Miscellaneous;
