const FilterOraTransactionsV2 = (oraFilter, reconciliationDetails, setReconciliationDetails, setSelectedOraUnrecTab, setSelectedBankUnrecTab, setSelectedOraRecTab, setSelectedBankRecTab, reconciliationDetailsUnaltered) => {
    let currentUnreconciledOraWeekly = JSON.parse(JSON.stringify([...reconciliationDetailsUnaltered.oraTransactionsUnreconciled]));
    let currentReconciled = reconciliationDetails.oraTransactionsReconciled;

    for (let week of currentUnreconciledOraWeekly) {
        let currentLines = JSON.parse(JSON.stringify(week.lines));
        if (oraFilter === 'tillReconciliation') {
            week.lines = currentLines.filter((x) => x.TillReconciliationId);
        } else if (oraFilter === 'manualTransaction') {
            week.lines = currentLines.filter((x) => x.ManualTransactionId);
        } else if (oraFilter === 'debtorPayment') {
            week.lines = currentLines.filter((x) => x.DebtorPaymentId);
        } else if (oraFilter === 'creditorPayrun') {
            week.lines = currentLines.filter((x) => x.PaymentRunId);
        } else if (oraFilter === 'directDeposit') {
            week.lines = currentLines.filter(
                (x) => x.ServiceId || x.VehicleSaleId || x.OrderId
            );
        } else if (oraFilter === 'stockPurchase') {
            week.lines = currentLines.filter((x) => x.TradeId);
        } else if (oraFilter === 'trustReceipt') {
            week.lines = currentLines.filter((x) => x.TrustReceiptId);
        } else if (oraFilter === 'warranty') {
            week.lines = currentLines.filter((x) => x.ServiceWarrantyId);
        } else if (oraFilter === 'rebate') {
            week.lines = currentLines.filter((x) => x.VehicleRebateId);
        } else if (oraFilter === 'stripe') {
            week.lines = currentLines.filter((x) => x.StripePaymentId);
        }
    }

    for (let week of currentUnreconciledOraWeekly) {
        // For each week, check every lines for reconciled ones
        let currentWeek = week.week;
        let linesToKeep = [];
        for (let line of week.lines) {
            if (
                currentReconciled.findIndex((x) => x.week === currentWeek) ===
                -1
            ) {
                linesToKeep.push(line);
            } else if (
                !currentReconciled[
                    currentReconciled.findIndex((x) => x.week === currentWeek)
                ].lines.includes((x) => x.id === line.id)
            ) {
                linesToKeep.push(line);
            }
        }

        week.lines = linesToKeep;

        if (week.lines.length === 0) {
            currentUnreconciledOraWeekly.splice(
                currentUnreconciledOraWeekly.findIndex(
                    (x) => x.week === week.week
                ),
                1
            );
        }
    }

    setReconciliationDetails({ ...reconciliationDetails, oraTransactionsUnreconciled: currentUnreconciledOraWeekly });
};

export default FilterOraTransactionsV2;