// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Unit } from '../../../global/interfaces/PartsInterface';

const EditBinLocation = (
    value,
    unitData: Unit,
    setUnitData: Dispatch<SetStateAction<Unit>>
) => {
    // Save the current bin locations to be able to edit
    let currentBinLocations = unitData.BinLocationIds;
    // Get the next available id
    let newId =
        currentBinLocations.length > 0
            ? currentBinLocations[currentBinLocations.length - 1].id + 1
            : 1;

    // For each of the current bin locations, check if any have been removed
    for (let option of currentBinLocations) {
        let exists = value.filter(
            (x) => x.BinLocationId === option.BinLocationId
        );
        if (exists.length === 0) {
            currentBinLocations = currentBinLocations.filter(
                (x) => x.BinLocationId !== option.BinLocationId
            );
        }
    }

    // For each of the options in the field check if there are any new ones and assign it a proper object
    for (let option of value) {
        if (!option.BinLocationId) {
            let exists = currentBinLocations.filter(
                (x) => x.BinLocationId === option.id
            );
            if (exists.length === 0) {
                let binLocationLine = {
                    id: newId,
                    name: option.name,
                    SiteId: parseInt(localStorage.getItem('SiteId')),
                    BinLocationId: option.id
                };
                currentBinLocations.push(binLocationLine);
            }
        }
    }

    setUnitData({ ...unitData, BinLocationIds: currentBinLocations });
};

export default EditBinLocation;
