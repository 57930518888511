// IMPORTS
import { useState, useEffect } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import { Prompt, useParams } from 'react-router-dom';
// COMPONENTS
import PaymentForm from './components/PaymentForm';
import PageWrapper from '../../global/PageWrapper';
import EnterIdComponent from './components/EnterIdComponent';
import Paper from '../../global/Paper';
import PageDoesNotExist from '../../global/PageDoesNotExist';
// LOGIC
import GetServiceDetails from './logic/GetServiceDetails';
import UpdateServiceToComplete from './logic/UpdateServiceToComplete';
// INTERFACE
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import {
    PurchaseOrder,
    Service
} from '../../global/interfaces/ServiceInterface';
import { PaymentLine } from '../../global/interfaces/GeneralInterface';
import { Order } from '../../global/interfaces/PartsInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

const ServicePayment = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const [shouldBlockNavigation, setShouldBlockNavigation] =
        useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);

    const [serviceDetails, setServiceDetails] = useState<Service>({
        paymentLines: [],
        addedCosts: [],
        miscCosts: []
    });
    const [paymentLines, setPaymentLines] = useState<PaymentLine[]>([]);
    const [sublets, setSublets] = useState<PurchaseOrder[]>([]);
    const [partSales, setPartSales] = useState<Order[]>([]);

    const [incompleteService, setIncompleteService] = useState<boolean>(false);
    const [incompleteServiceDialog, setIncompleteServiceDialog] =
        useState<boolean>(false);

    useEffect(() => {
        if (id) {
            GetServiceDetails(
                parseInt(id),
                setServiceDetails,
                setPaymentLines,
                setSublets,
                setPartSales,
                setLoading,
                setInvalid,
                setIncompleteService
            );
            setShouldBlockNavigation(true);
        }
    }, [id]);

    return (
        <PageWrapper>
            <Prompt
                when={shouldBlockNavigation}
                message="You have unsaved changes, are you sure you want to leave?"
            />

            {!id ? (
                <>
                    <Typography variant="h4" align="left">
                        Pay For Service
                    </Typography>
                    <br />
                    <EnterIdComponent />
                </>
            ) : loading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4" align="left">
                        Pay For Service #{id}
                    </Typography>
                    <br />
                    <PaymentForm
                        id={parseInt(id)}
                        serviceDetails={serviceDetails}
                        customer={serviceDetails?.Customer}
                        insurer={serviceDetails?.Insurer}
                        showSnackbar={showSnackbar}
                        partSales={partSales}
                        sublets={sublets}
                        paymentLines={paymentLines}
                        setPaymentLines={setPaymentLines}
                        incompleteService={incompleteService}
                        setIncompleteServiceDialog={setIncompleteServiceDialog}
                    />
                </>
            )}

            <Dialog
                open={incompleteServiceDialog}
                onClose={() => setIncompleteServiceDialog(false)}
            >
                <DialogTitle>Incomplete Service</DialogTitle>
                <DialogContent>
                    This service has an "Incomplete" status. To access the
                    payment page its status need to be set as "Complete". Do you
                    wish to set this service as "Complete"?
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => setIncompleteServiceDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            UpdateServiceToComplete(parseInt(id), showSnackbar);
                        }}
                    >
                        Set as complete
                    </Button>
                </DialogActions>
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(ServicePayment);
