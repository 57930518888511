// IMPORTS
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState
} from 'react';
import {
    Table,
    TableRow,
    TableCell,
    TextField,
    Switch,
    MenuItem,
    Grid,
    Typography,
    FormGroup,
    FormControlLabel,
    TableContainer,
    Chip,
    Autocomplete
} from '@mui/material';
// COMPONENTS
import ChipArray from '../ChipArray';
import AutocompleteMultiple from '../../../../global/AutocompleteMultiple';
import EditBinLocation from '../../logic/EditBinLocation';
import RoleCheck from '../../../../global/RoleCheck';
// INTERFACES
import {
    Unit,
    BinLocation
} from '../../../../global/interfaces/PartsInterface';
import {
    Specific,
    Creditor,
    Site
} from '../../../../global/interfaces/GeneralInterface';
import GetAllSites from '../../../../global/databaseLogic/GetAllSites';
interface DetailsTableProps {
    partDetails: Unit;
    setPartDetails: Dispatch<SetStateAction<Unit>>;
    readOnly: boolean;
    handleOnChange: { (fieldName: string, fieldValue: string | boolean): void };
    brandList: Specific[];
    binLocationData: BinLocation[];
    selectedSite: number;
    setCreditorDialogOpen: Dispatch<SetStateAction<boolean>>;
    setSelectedCreditor: Dispatch<SetStateAction<number | undefined>>;
    creditorData: Creditor[];
}

const DetailsTable = ({
    partDetails,
    setPartDetails,
    readOnly,
    handleOnChange,
    brandList,
    binLocationData,
    selectedSite,
    setCreditorDialogOpen,
    setSelectedCreditor,
    creditorData
}: DetailsTableProps) => {
    const currentSiteId = parseInt(localStorage.getItem('SiteId')!);
    const [siteData, setSiteData] = useState<Site[]>([]);
    useEffect(() => {
        GetAllSites(setSiteData);
    }, []);
    // Filter the binlocations to that it only shows the current sites in the dropdown
    let siteBinLocationData = binLocationData?.filter(
        (x) => Number(x.SiteId) === currentSiteId
    );

    // The selected bin locations for the current site
    const getSiteBins = () => {
        if (partDetails && partDetails?.BinLocationIds?.length > 0) {
            let siteBins = partDetails?.BinLocationIds.filter(
                (x) => Number(x.SiteId) === selectedSite
            );
            return siteBins;
        } else {
            return [];
        }
    };

    // The selected suppliers
    const getSiteSuppliers = () => {
        if (partDetails && partDetails?.SupplierIds?.length > 0) {
            let siteSuppliers = partDetails?.SupplierIds;
            return siteSuppliers;
        } else {
            return [];
        }
    };

    // get primary suppliers
    const [hasPrimarySupplier, setHasPrimarySupplier] = useState(false);
    const [primarySupplierId, setPrimarySupplierId] = useState(null);

    useEffect(() => {
        const primarySupplier = partDetails?.SupplierIds?.find(
            (supplier) =>
                supplier.isPrimary === true &&
                Number(supplier.SiteId) === selectedSite
        );
        if (primarySupplier) {
            setHasPrimarySupplier(true);
            setPrimarySupplierId(primarySupplier.id);
        } else {
            setHasPrimarySupplier(false);
            setPrimarySupplierId(null);
        }
    }, [partDetails?.SupplierIds, selectedSite]);

    //update selected primary supplier in partDetails
    const handlePrimarySupplierChange = (event, newValue) => {
        if (newValue) {
            setPrimarySupplierId(newValue.id);
            const updatedSuppliers = partDetails.SupplierIds.map(
                (supplier) => ({
                    ...supplier,
                    isPrimary: Number(supplier.id) === Number(newValue.id)
                })
            );
            setPartDetails({ ...partDetails, SupplierIds: updatedSuppliers });
        }
    };

    // Switch off toggle: remove isPrimary field in all SupplierIds
    const handlePrimaryToggleChange = (event) => {
        const { checked } = event.target;
        setHasPrimarySupplier(checked);
        if (!checked) {
            setPrimarySupplierId(null);
            const updatedSuppliers = partDetails.SupplierIds.map(
                (supplier) => ({
                    ...supplier,
                    isPrimary: false
                })
            );
            setPartDetails({ ...partDetails, SupplierIds: updatedSuppliers });
        }
    };

    return (
        <TableContainer style={{ maxWidth: '100vw' }}>
            <Table>
                <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant="h6">Details</Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Part Number</TableCell>
                    <TableCell>{partDetails?.partNumber}</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Supercession</TableCell>
                    {readOnly && (
                        <TableCell
                            className="table-link"
                            onClick={() => {
                                if (
                                    partDetails?.supercessionPartNumber &&
                                    readOnly
                                ) {
                                    return window.open(
                                        '/inventory/search?partNumber=' +
                                            partDetails?.supercessionPartNumber,
                                        '_blank'
                                    );
                                }
                            }}
                        >
                            {partDetails?.supercessionPartNumber}
                        </TableCell>
                    )}
                    {!readOnly && (
                        <TableCell>
                            <TextField
                                multiline
                                fullWidth
                                size="small"
                                variant={'outlined'}
                                disabled={readOnly}
                                value={partDetails?.supercessionPartNumber}
                                onChange={(e) =>
                                    handleOnChange(
                                        'supercessionPartNumber',
                                        e.target.value
                                    )
                                }
                            />
                        </TableCell>
                    )}
                </TableRow>

                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <TextField
                                fullWidth
                                size="small"
                                variant={'standard'}
                                disabled={true}
                                value={partDetails?.name}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                multiline
                                onChange={(e) =>
                                    handleOnChange('name', e.target.value)
                                }
                            />
                        ) : (
                            <TextField
                                fullWidth
                                size="small"
                                variant={'outlined'}
                                value={partDetails?.name}
                                multiline
                                onChange={(e) =>
                                    handleOnChange('name', e.target.value)
                                }
                            />
                        )}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Supplier Barcode</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <TextField
                                multiline
                                fullWidth
                                size="small"
                                variant={'standard'}
                                disabled
                                value={partDetails?.barcode}
                                InputProps={{ disableUnderline: true }}
                                onChange={(e) =>
                                    handleOnChange('barcode', e.target.value)
                                }
                            />
                        ) : (
                            <TextField
                                multiline
                                fullWidth
                                size="small"
                                variant={'outlined'}
                                value={partDetails?.barcode}
                                onChange={(e) =>
                                    handleOnChange('barcode', e.target.value)
                                }
                            />
                        )}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Stocked Item</TableCell>
                    <TableCell>
                        {readOnly ? (
                            partDetails?.stockedItem ? (
                                'Yes'
                            ) : (
                                'No'
                            )
                        ) : (
                            <Switch
                                checked={partDetails.stockedItem}
                                onChange={(e) =>
                                    handleOnChange(
                                        'stockedItem',
                                        e.target.checked
                                    )
                                }
                            />
                        )}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Brand</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <TextField
                                fullWidth
                                size="small"
                                variant={'standard'}
                                select={false}
                                disabled
                                value={partDetails?.Brand?.name}
                                InputProps={{ disableUnderline: true }}
                            ></TextField>
                        ) : (
                            <TextField
                                fullWidth
                                size="small"
                                variant={'outlined'}
                                select={true}
                                disabled
                                value={partDetails?.Brand?.name}
                                onChange={(e) =>
                                    handleOnChange('BrandId', e.target.value)
                                }
                            >
                                {brandList?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Fluid</TableCell>
                    <TableCell>
                        <Grid container columns={3} spacing={0}>
                            <Grid xs={1} display="flex">
                                {readOnly ? (
                                    partDetails?.fluid ? (
                                        <Typography>
                                            Volume: {partDetails.volume}mL
                                        </Typography>
                                    ) : (
                                        'No'
                                    )
                                ) : (
                                    <Switch
                                        checked={partDetails.fluid}
                                        onChange={(e) =>
                                            handleOnChange(
                                                'fluid',
                                                e.target.checked
                                            )
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid xs={2} display="flex" justifyContent="right">
                                {!readOnly && partDetails?.fluid ? (
                                    <TextField
                                        label="Volume (mL)"
                                        size="small"
                                        fullWidth
                                        variant={'outlined'}
                                        disabled={readOnly}
                                        value={partDetails?.volume}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) =>
                                            handleOnChange(
                                                'volume',
                                                e.target.value
                                            )
                                        }
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Bin Location</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <ChipArray
                                chipData={getSiteBins()}
                                labelType="name"
                                handleClick={() => null}
                                siteData={siteData}
                            />
                        ) : (
                            <AutocompleteMultiple
                                options={siteBinLocationData}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'name'}
                                textfieldLabel={'Bin Location'}
                                selectedValues={getSiteBins()}
                                handleSelectedValueChange={(e: ChangeEvent) => {
                                    EditBinLocation(
                                        e,
                                        partDetails,
                                        setPartDetails
                                    );
                                }}
                                handleInputValueChange={() => null}
                            />
                        )}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Supplier</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <ChipArray
                                chipData={getSiteSuppliers()}
                                labelType="name"
                                handleClick={(e: number) => {
                                    setCreditorDialogOpen(true);
                                    setSelectedCreditor(e);
                                }}
                                siteData={siteData}
                            />
                        ) : (
                            <AutocompleteMultiple
                                options={
                                    creditorData
                                        ? creditorData.filter(
                                              (option) =>
                                                  !partDetails.SupplierIds.some(
                                                      (selected) =>
                                                          Number(
                                                              selected.SiteId
                                                          ) === currentSiteId &&
                                                          Number(
                                                              selected.SupplierId
                                                          ) === option.id
                                                  )
                                          )
                                        : []
                                }
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'name'}
                                isDisabled={readOnly}
                                textfieldLabel={'Supplier'}
                                selectedValues={partDetails.SupplierIds.filter(
                                    (supplier) =>
                                        Number(supplier.SiteId) ===
                                        currentSiteId
                                )}
                                handleSelectedValueChange={(
                                    event: Creditor[]
                                ) => {
                                    var newObject: Creditor[] = [];
                                    var existingSupplierIdsNotDisplayed =
                                        partDetails.SupplierIds.filter(
                                            (supplier) =>
                                                Number(supplier.SiteId) !==
                                                currentSiteId
                                        );

                                    const maxId =
                                        existingSupplierIdsNotDisplayed.reduce(
                                            (max, item) =>
                                                Math.max(max, item.id),
                                            0
                                        );

                                    event.forEach(
                                        (val: Creditor, index: number) => {
                                            var newItem = {
                                                id: maxId + index + 1,
                                                code: val.code,
                                                name: val.name,
                                                SiteId: currentSiteId,
                                                SupplierId:
                                                    val.SupplierId ?? val.id,
                                                isPrimary: val.isPrimary
                                            };
                                            newObject.push(newItem);
                                        }
                                    );

                                    setPartDetails({
                                        ...partDetails,
                                        SupplierIds: [
                                            ...existingSupplierIdsNotDisplayed,
                                            ...newObject
                                        ]
                                    });
                                }}
                                handleInputValueChange={() => null}
                                textfieldVariant={'outlined'}
                            />
                        )}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Primary Supplier</TableCell>
                    <TableCell>
                        {readOnly ? (
                            hasPrimarySupplier ? (
                                <Chip
                                    label={
                                        partDetails?.SupplierIds?.find(
                                            (supplier) => supplier.isPrimary
                                        )?.name || 'Unknown'
                                    }
                                />
                            ) : (
                                ''
                            )
                        ) : (
                            <>
                                <Switch
                                    checked={hasPrimarySupplier}
                                    onChange={handlePrimaryToggleChange}
                                />
                                {hasPrimarySupplier && (
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        size="small"
                                        id="combo-box-demo"
                                        options={
                                            partDetails?.SupplierIds.filter(
                                                (supplier) =>
                                                    Number(supplier.SiteId) ===
                                                    currentSiteId
                                            ) || []
                                        }
                                        getOptionLabel={(option) =>
                                            option.name || ''
                                        }
                                        value={
                                            partDetails.SupplierIds.find(
                                                (supplier) =>
                                                    Number(supplier.id) ===
                                                    Number(primarySupplierId)
                                            ) || null
                                        }
                                        onChange={handlePrimarySupplierChange}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    {...getTagProps({ index })}
                                                    variant="filled"
                                                    size="small"
                                                    label={option.name}
                                                />
                                            ))
                                        }
                                        renderOption={(props, option) => (
                                            <Typography
                                                {...props}
                                                sx={{ display: 'flex' }}
                                            >
                                                <span>{option.name}</span>
                                            </Typography>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Primary Supplier"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            </>
                        )}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>Neto</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <>
                                <Typography variant="body2">
                                    {partDetails?.pushNeto
                                        ? partDetails.activeNeto
                                            ? 'Part is active on Neto.'
                                            : 'Part is inactive on Neto.'
                                        : 'Part is not synced with Neto.'}
                                </Typography>
                                {partDetails?.netoPartNumber ? (
                                    <Typography variant="body2">
                                        Neto SKU: {partDetails?.netoPartNumber}
                                    </Typography>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <RoleCheck
                                    component={
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            partDetails.pushNeto
                                                        }
                                                        onChange={(e) =>
                                                            setPartDetails({
                                                                ...partDetails,
                                                                pushNeto:
                                                                    e.target
                                                                        .checked,
                                                                activeNeto:
                                                                    false
                                                            })
                                                        }
                                                    />
                                                }
                                                label="Pushing to Neto"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            partDetails.activeNeto
                                                        }
                                                        onChange={(e) =>
                                                            setPartDetails({
                                                                ...partDetails,
                                                                activeNeto:
                                                                    e.target
                                                                        .checked
                                                            })
                                                        }
                                                    />
                                                }
                                                label="Active On Neto"
                                            />
                                        </FormGroup>
                                    }
                                    permission={'edit_partInNeto'}
                                    nullComponent={
                                        <Typography variant="body2">
                                            {partDetails?.pushNeto
                                                ? partDetails.activeNeto
                                                    ? 'Part is active on Neto.'
                                                    : 'Part is inactive on Neto.'
                                                : 'Part is not synced with Neto.'}
                                        </Typography>
                                    }
                                />
                                <br />
                                <RoleCheck
                                    component={
                                        readOnly ? (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                label="Neto SKU"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                variant={'standard'}
                                                disabled
                                                value={
                                                    partDetails?.netoPartNumber
                                                }
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                                onChange={(e) =>
                                                    setPartDetails({
                                                        ...partDetails,
                                                        netoPartNumber:
                                                            e.target.value
                                                    })
                                                }
                                            />
                                        ) : (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                label="Neto SKU"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                variant={'outlined'}
                                                disabled={readOnly}
                                                value={
                                                    partDetails?.netoPartNumber
                                                }
                                                onChange={(e) =>
                                                    setPartDetails({
                                                        ...partDetails,
                                                        netoPartNumber:
                                                            e.target.value
                                                    })
                                                }
                                            />
                                        )
                                    }
                                    permission={'edit_NetoSKU'}
                                    nullComponent={
                                        <Typography variant="body2">
                                            Neto SKU:{' '}
                                            {partDetails?.netoPartNumber}
                                        </Typography>
                                    }
                                />
                            </>
                        )}
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    );
};

export default DetailsTable;
