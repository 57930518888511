// IMPORTS
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import {
    TableRow,
    TextField,
    IconButton,
    MenuItem,
    Tooltip,
    Typography
} from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// INTERFACES
import { Part } from '../GeneralJournalUI';

interface PartsTableProps {
    parts: Part[];
    setParts: Dispatch<SetStateAction<Part[]>>;
}

export default function PartsTable(props: PartsTableProps) {
    const columns = [
        { id: 0, label: 'Entity', width: 150 },
        { id: 1, label: 'Part Number', width: 100 },
        { id: 2, label: 'Name', width: 150 },
        { id: 3, label: 'Date', width: 200 },
        { id: 4, label: 'Cost (EX. GST)', width: 150 },
        { id: 5, label: 'Total (Ex. GST)', width: 150 },
        { id: 6, label: 'Quantity', width: 100 },
        { id: 7, label: 'GST Type', width: 100 },
        { id: 8, label: 'GST Amount', width: 150 },
        { id: 9, label: '', width: 50 }
    ];

    const handleQuantityChange = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        index: number
    ) => {
        let value = Number.isNaN(parseInt(e.target.value))
            ? 0
            : parseInt(e.target.value);
        let currentTables = props.parts;
        let row = { ...currentTables[index] };
        let quantity = value;
        row.quantity = quantity;
        currentTables[index] = row;
        props.setParts([...currentTables]);
    };

    const handleRemoveRow = (index) => {
        let currentTables = JSON.parse(JSON.stringify(props.parts));
        currentTables.splice(index, 1);
        props.setParts(currentTables);
    };

    const handleGSTChange = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        index: number
    ) => {
        let currentTables = props.parts;
        let row = { ...currentTables[index] };
        row.gstType = e.target.value;
        currentTables[index] = row;
        props.setParts([...currentTables]);
    };

    const handleDateChange = (newValue, index: number) => {
        let currentTables = props.parts;
        let row = { ...currentTables[index] };
        row.dateEntered = newValue;
        currentTables[index] = row;
        props.setParts([...currentTables]);
    };

    return (
        <Paper>
            <DataTable columns={columns}>
                {props.parts.map((row, index) => (
                    <TableRow>
                        <DataCell>{row.accountName}</DataCell>
                        <DataCell>{row.partNumber}</DataCell>
                        <DataCell>
                            {row.name.length > 15 ? (
                                <Tooltip title={row.name}>
                                    <Typography variant="body2">
                                        {row.name.slice(0, 15)}...
                                    </Typography>
                                </Tooltip>
                            ) : (
                                row.name
                            )}
                        </DataCell>
                        <DataCell>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    value={row.dateEntered}
                                    onChange={(newValue) => {
                                        handleDateChange(newValue, index);
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                            size: 'small',
                                            InputLabelProps: { shrink: true }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(row.costPriceDaily)}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(
                                row.costPriceDaily * row.quantity
                            )}
                        </DataCell>
                        <DataCell>
                            <TextField
                                value={row.quantity}
                                onChange={(e) => handleQuantityChange(e, index)}
                                variant="outlined"
                            />
                        </DataCell>
                        <DataCell>
                            <TextField
                                select
                                fullWidth
                                value={row.gstType}
                                name="gstType"
                                onChange={(e) => handleGSTChange(e, index)}
                            >
                                <MenuItem value="G1">G1</MenuItem>
                                <MenuItem value="G2">G2</MenuItem>
                                <MenuItem value="G3">G3</MenuItem>
                                <MenuItem value="G10">G10</MenuItem>
                                <MenuItem value="G11">G11</MenuItem>
                                <MenuItem value="NT">NT</MenuItem>
                            </TextField>
                        </DataCell>
                        <DataCell>
                            {row.gstType === 'G2' ||
                            row.gstType === 'G3' ||
                            row.gstType === 'NT'
                                ? CurrencyFormatter(0)
                                : CurrencyFormatter(
                                      Math.abs(
                                          row.costPriceDaily * row.quantity
                                      ) / 10
                                  )}
                        </DataCell>
                        <DataCell>
                            <IconButton onClick={(e) => handleRemoveRow(index)}>
                                <Clear />
                            </IconButton>
                        </DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </Paper>
    );
}
