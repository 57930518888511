import { TempOrder } from '../../../global/dashboard/interface';

const CalculatePartSalesRevenue = (orders: TempOrder[]) => {
    var partProformaOrderData = [];
    var partSalesOrderData = [];
    var partCreditOrderData = [];

    let totalSalesRevenue = 0;
    let totalSalesCost = 0;
    let totalCreditRefund = 0;

    const proformaOrders = orders.filter(
        (order) => order.status === 'Proforma'
    );
    const salesOrders = orders.filter((order) => order.status === 'Sale');

    const creditOrders = orders.filter(
        (order) => order.linkedCredit?.length > 0
    );

    // for proforma order, we calculate the total cost and total revenue based on order lines
    // cost = costPriceAverage || part.costPriceDaily * quantity, this is how much it costs to buy the part
    // revenue = totalPrice * quantity, this is how much the customer will pay
    proformaOrders.forEach((order) => {
        let totalOrderCost = 0;
        let totalOrderRevenue = 0;
        order.orderLines.forEach((part) => {
            if (part.voucherCode) {
                totalOrderCost += parseFloat(part.totalPrice.toString());
                if (
                    order.internalType ||
                    order.ServiceId ||
                    order.VehicleId ||
                    part.gstFree ||
                    order['Customer.international'] ||
                    order['Customer.gstFree']
                ) {
                    totalOrderRevenue += parseFloat(part.totalPrice.toString());
                } else {
                    totalOrderRevenue +=
                        parseFloat(part.totalPrice.toString()) / 1.1;
                }
            } else {
                totalOrderCost +=
                    (part.costPriceAverage && part.costPriceAverage !== 0
                        ? part.costPriceAverage
                        : part.costPriceDaily) * parseFloat(part.quantity);
                if (
                    order.internalType ||
                    order.ServiceId ||
                    order.VehicleId ||
                    part.gstFree ||
                    order['Customer.international'] ||
                    order['Customer.gstFree']
                ) {
                    totalOrderRevenue +=
                        parseFloat(part.pricePaid) * parseFloat(part.quantity);
                } else {
                    totalOrderRevenue +=
                        (parseFloat(part.pricePaid) *
                            parseFloat(part.quantity)) /
                        1.1;
                }
            }
        });

        let proformaLine = {
            OrderId: order.id,
            orderStatus: order.status,
            totalProjectedCost: totalOrderCost,
            totalProjectedSale: totalOrderRevenue,
            numberParts: order.orderLines.length,
            // VehicleSaleId: null,
            // VehicleId: null,
            // ServiceId: null,
            // OnlineOrderId: null,
            customerName: null
        };

        proformaLine.customerName = order['Customer.firstName']
            ? order['Customer.firstName'].charAt(0) +
              '. ' +
              order['Customer.lastName']
            : order['Customer.lastName'];

        partProformaOrderData.push(proformaLine);
    });

    // for sales order, we calculate the total cost and total revenue based on orderlines
    // cost = costPriceAverage || part.costPriceDaily * quantity, this is how much it costs to buy the part
    // revenue = totalPrice * quantity, this is how much the customer paid. Since sale order is always fully paid, we can use orderlines in stead of paymentlines, this is easier to handle credit parts
    // In orderlines, if a part is credited, there will be a new line with negative quantity and negative totalPrice, thus the refund is deducted from the total revenue
    salesOrders.forEach((order) => {
        let totalOrderCost = 0;
        let totalOrderRevenue = 0;

        order.orderLines.forEach((part) => {
            if (part.voucherCode) {
                totalOrderCost += parseFloat(part.totalPrice.toString());
                if (
                    order.internalType ||
                    order.ServiceId ||
                    order.VehicleId ||
                    part.gstFree ||
                    order['Customer.international'] ||
                    order['Customer.gstFree']
                ) {
                    totalOrderRevenue += parseFloat(part.totalPrice.toString());
                } else {
                    totalOrderRevenue +=
                        parseFloat(part.totalPrice.toString()) / 1.1;
                }
            } else {
                totalOrderCost +=
                    (part.costPriceAverage && part.costPriceAverage !== 0
                        ? part.costPriceAverage
                        : part.costPriceDaily) * parseFloat(part.quantity);
                if (
                    order.internalType ||
                    order.ServiceId ||
                    order.VehicleId ||
                    part.gstFree ||
                    order['Customer.international'] ||
                    order['Customer.gstFree']
                ) {
                    totalOrderRevenue +=
                        parseFloat(part.pricePaid) * parseFloat(part.quantity);
                } else {
                    totalOrderRevenue +=
                        (parseFloat(part.pricePaid) *
                            parseFloat(part.quantity)) /
                        1.1;
                }
            }
        });

        totalSalesRevenue += totalOrderRevenue;
        totalSalesCost += totalOrderCost;

        let saleLine = {
            OrderId: order.id,
            orderStatus: order.status,
            totalCost: totalOrderCost,
            totalSale: totalOrderRevenue,
            numberParts: order.orderLines.length,
            // VehicleSaleId: null,
            // VehicleId: null,
            // ServiceId: null,
            // OnlineOrderId: null,
            customerName: null
        };

        saleLine.customerName = order['Customer.firstName']
            ? order['Customer.firstName'].charAt(0) +
              '. ' +
              order['Customer.lastName']
            : order['Customer.lastName'];

        partSalesOrderData.push(saleLine);
    });

    // for credit order, we calculate the total refund amount based on Credit orderLines amountRefunded
    creditOrders?.forEach((order) => {
        let totalRefundAmount = 0;
        let nbOfPart = 0;
        let creditIds = '';
        for (let credit of order.linkedCredit) {
            creditIds +=
                creditIds.length === 0
                    ? credit.id.toString()
                    : `, ${credit.id.toString()}`;
            for (let line of credit.orderLines) {
                nbOfPart += 1;
                totalRefundAmount += parseFloat(
                    line.amountRefunded !== undefined
                        ? line.amountRefunded.toString()
                        : '0'
                );
            }
        }

        totalCreditRefund += totalRefundAmount;

        let creditLine = {
            OrderId: order.id,
            CreditId: creditIds,
            orderStatus: order.status,
            totalRefund: totalRefundAmount,
            numberParts: nbOfPart,
            // VehicleSaleId: null,
            // VehicleId: null,
            // ServiceId: null,
            // OnlineOrderId: null,
            customerName: null
        };

        creditLine.customerName = order['Customer.firstName']
            ? order['Customer.firstName'].charAt(0) +
              '. ' +
              order['Customer.lastName']
            : order['Customer.lastName'];

        partCreditOrderData.push(creditLine);
    });

    return {
        partSalesOrderData,
        partCreditOrderData,
        partProformaOrderData,
        totalSalesRevenue,
        totalSalesCost,
        totalCreditRefund
    };
};

export default CalculatePartSalesRevenue;
