import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import api from '../../../../../api';
import { OrderLine } from '../../../global/interfaces/PartsInterface';

const headers = [['Part No.', 'Name', 'Quantity', 'Price incl. GST']];

function createInvoicePdf(
    filePath: string,
    orderLines: OrderLine[],
    rowId: number,
    siteId: number,
    supplierName: string,
    createdAt: string
) {
    api.get(`settingsNextGen/${siteId}?includeImage=true`).then((res) => {
        let siteData = res.data.site;
        let settingsData = res.data.settings;

        var newImg = document.createElement('img');
        newImg.src = res.data.image;
        newImg.className = 'logo';
        let image = newImg;

        try {
            var doc = new jsPDF();

            let bodyContentOuter = [];
            let bodyContentInner = [];
            let counter = 0;
            let totalCost = 0;

            // Go through all orderLine, adds up rowTotal to get totalCost
            // And add each part to the bodyContent of the future table. Change to a new array every 24 row
            for (let orderLine of orderLines) {
                totalCost += orderLine.rowTotal
                    ? orderLine.rowTotal
                    : orderLine.totalCost;
                bodyContentInner.push([
                    orderLine.partNumber,
                    orderLine.name,
                    orderLine.quantity,
                    CurrencyFormatter(
                        orderLine.rowTotal
                            ? orderLine.rowTotal
                            : orderLine.totalCost
                    )
                ]);
                counter++;
                if (counter === 24) {
                    bodyContentOuter.push(bodyContentInner);
                    bodyContentInner = [];
                    counter = 0;
                }
            }
            bodyContentOuter.push(bodyContentInner);

            // Check if the last bodyContentInner is not 24 row long. If it's not, add a row with the total
            // If it is 24 row long, create a new array and add the totalCost row
            if (bodyContentOuter[bodyContentOuter.length - 1].length < 24) {
                bodyContentOuter[bodyContentOuter.length - 1].push([
                    'Total',
                    '',
                    '',
                    CurrencyFormatter(totalCost)
                ]);
            } else {
                bodyContentInner = [];
                bodyContentInner.push([
                    '',
                    '',
                    'Total',
                    CurrencyFormatter(totalCost)
                ]);
                bodyContentOuter.push(bodyContentInner);
            }

            // Create a page for each table in bodyContentOuter
            for (let i = 0; i < bodyContentOuter.length; i++) {
                doc.addPage();
                doc.setPage(i + 1);

                doc.setFontSize(10);
                doc.text(
                    `
                    ${settingsData.legalEntityName}
                    ABN ${settingsData.abn} ACN ${settingsData.acn}

                    ${settingsData.companyName}
                    ${siteData.address}, ${siteData.suburb} ${siteData.state} ${siteData.postcode}
                `,
                    200,
                    10,
                    {
                        align: 'right'
                    }
                );

                doc.addImage(image, 'png', 10, 10, 90, 20);

                doc.setFontSize(20);
                doc.text(`Order Summary #${rowId}`, 10, 50);

                doc.setFontSize(15);
                doc.text(
                    `${siteData.name} - ${DateFormatter(createdAt)}`,
                    10,
                    60
                );
                doc.text(`Supplier: ${supplierName}`, 195, 60, {
                    align: 'right'
                });

                autoTable(doc, {
                    // startX: 11,
                    startY: 70,
                    head: headers,
                    body: bodyContentOuter[i],
                    theme: 'grid',
                    headStyles: {
                        fillColor: [19, 65, 123],
                        textColor: 'white',
                        lineColor: [19, 65, 123]
                    },
                    margin: { bottom: 20 },
                    styles: { lineColor: [201, 201, 201], lineWidth: 0.1 },
                    willDrawCell: function (data) {
                        var rows = data.table.body;
                        if (data.row.index === rows.length - 1) {
                            doc.setFont('helvetica', 'bold');
                        }
                    }
                });
            }

            // Delete the blank page at the end
            let pageCount = doc.internal.pages.length - 1;
            doc.deletePage(pageCount);

            // Add page number to each page
            for (let pageNb = 1; pageNb < pageCount; pageNb++) {
                doc.setPage(pageNb);
                doc.setFontSize(10);
                doc.text(`Page ${pageNb} of ${pageCount - 1}`, 180, 290);
            }

            doc.save(filePath);
            window.open(doc.output('bloburl'));
        } catch (error) {
            console.log(error);
        }
    });
}

export default createInvoicePdf;
