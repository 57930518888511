import moment from 'moment';
import { saveAs } from 'file-saver';
import { StockOrder } from '../../../../global/interfaces/PartsInterface';

const RegenerateKawasakiOrderFile = (row: StockOrder) => {
    let date = moment(row.createdAt);
    let blankString = ' ';
    let zeroString = '0';

    // first line of the txt file is the header
    let startString = `KAWA${row.Api.additionalDetails.dealerCode}${blankString.repeat(20 - row.Api.additionalDetails.dealerCode.length)}${date.format('YYMMDDhhmmss')}OR`;
    let txtFile = `${startString}OH          ${row.orderReference}${blankString.repeat(15 - row.orderReference.length)}${blankString.repeat(22)}${row.orderType === 'daily' ? 'D' : 'S'}${blankString.repeat(35)}0001 \n`;

    // For each orderlines, add a line to the text file
    for (let x = 0; x < row.orderLines.length; x++) {
        let line = row.orderLines[x];

        // If it's the last line, add a Y at the end of the string
        if (x === row.orderLines.length - 1) {
            txtFile = txtFile.concat(
                `${startString}OL${line.partNumber}${blankString.repeat(20 - line.partNumber.length)}${zeroString.repeat(11 - Math.round(line.quantity * 100).toString().length)}${Math.round(line.quantity * 100).toString()}Y${blankString.repeat(51)}1${zeroString.repeat(3 - (x + 1).toString().length)}${(x + 1).toString()}Y`
            );
        } else {
            txtFile = txtFile.concat(
                `${startString}OL${line.partNumber}${blankString.repeat(20 - line.partNumber.length)}${zeroString.repeat(11 - Math.round(line.quantity * 100).toString().length)}${Math.round(line.quantity * 100).toString()}Y${blankString.repeat(51)}1${zeroString.repeat(3 - (x + 1).toString().length)}${(x + 1).toString()} \n`
            );
        }
    }

    // Save the file
    const file = new Blob([txtFile], { type: 'text/plain' });
    saveAs(
        file,
        `KAWASAKI_ORDER${row.orderReference ? `_${row.orderReference}` : ''}_${date.format('YYMMDDhhmmss')}.txt`
    );
};

export default RegenerateKawasakiOrderFile;
