// IMPORTS
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import RoleCheck from '../global/RoleCheck';

// TABLES
import AccountTable from '../admin/account/AccountTable';

// INVOICE
import Invoice from '../admin/invoice/Invoice';
import ViewRebateInvoice from '../admin/invoice/components/RebateInvoice/ViewRebateInvoice';
import ViewServiceWarrantyInvoice from '../admin/invoice/components/ServiceWarranty/ViewServiceWarrantyInvoice';
import CreditInvoice from '../admin/creditInvoice/CreditInvoice';
import ViewTrustReceipt from '../admin/invoice/components/TrustInvoice/ViewTrustReceipt';

// RECONCILIATION
import DebtorReconciliationTable from '../admin/debtorReconciliation/DebtorReconciliationTable';
import RegoReconciliation from '../admin/regoReconciliation/RegoReconciliation';
import RebateReconciliation from '../admin/rebateReconciliation/RebateReconciliation';
import CreditorReconciliation from '../admin/creditorReconciliation/CreditorReconciliation';
import BankReconciliation from '../admin/bankReconciliation/BankReconciliation';

//TIMECLOCK
import TimeClockPage from '../admin/payroll/TimeClockPage';


// MISC
import ReportingUITS from '../admin/reporting/ReportingUI';
import ManualTransaction from '../admin/manualTransaction/ManualTransaction';
import GeneralJournalUI from '../admin/generalJournal/GeneralJournalUI';
import GenerateInvoice from '../admin/generateInvoice/GenerateInvoice';
import ManualInvoiceTable from '../admin/generateInvoice/ManualInvoiceTable';

// DASHBOARD
import AdminDashboard from '../admin/dashboard/AdminDashboard';

const AdminRouter = () => {
  let { path } = useRouteMatch();

  return (
      <>
          <Switch>
              {/* DASHBOARD */}
              <Route path={`${path}/dashboard`}>
                  <RoleCheck
                      component={<AdminDashboard />}
                      permission={'access_admin_dashboard'}
                      pageError
                  />
              </Route>
              {/* TABLES */}
              <Route path={`${path}/accounts`}>
                  <RoleCheck
                      component={<AccountTable />}
                      permission={'access_accounts'}
                      pageError
                  />
              </Route>

              {/* INVOICE */}
              <Route path={`${path}/enterInvoice`}>
                  <RoleCheck
                      component={<Invoice />}
                      permission={'access_invoice'}
                      pageError
                  />
              </Route>
              <Route path={`${path}/viewRebateInvoice/:id`}>
                  <ViewRebateInvoice />
              </Route>
              <Route path={`${path}/viewServiceWarrantyInvoice/:id`}>
                  <ViewServiceWarrantyInvoice />
              </Route>
              <Route path={`${path}/viewTrustReceipt/:id`}>
                <ViewTrustReceipt />
              </Route>
              <Route path={`${path}/creditInvoice/:id`}>
                  <CreditInvoice />
              </Route>
              <Route path={`${path}/creditInvoice`}>
                  <CreditInvoice />
              </Route>

              {/* RECONCILIATION */}
              <Route path={`${path}/debtorReconciliation`}>
                  <RoleCheck
                      component={<DebtorReconciliationTable />}
                      permission={'access_debtRec'}
                      pageError
                  />
              </Route>
              <Route path={`${path}/registrationReconciliation`}>
                  <RoleCheck
                      component={<RegoReconciliation />}
                      permission={'access_regoRec'}
                      pageError
                  />
              </Route>
              <Route path={`${path}/rebateReconciliation`}>
                  <RoleCheck
                      component={<RebateReconciliation />}
                      permission={'access_rebateRec'}
                      pageError
                  />
              </Route>
              <Route path={`${path}/creditorReconciliation`}>
                  <RoleCheck
                      component={<CreditorReconciliation />}
                      permission={'access_credRec'}
                      pageError
                  />
              </Route>
              <Route path={`${path}/bankReconciliation`}>
                  <RoleCheck
                      component={<BankReconciliation />}
                      permission={'access_bankRec'}
                      pageError
                  />
              </Route>

              {/* TIMECLOCK */}
              <Route path={`${path}/timeclock`}>
                  <RoleCheck
                      component={<TimeClockPage />}
                      permission={'access_timeclock'}
                      pageError
                  />
              </Route>

              {/* MISC */}
              <Route path={`${path}/reporting`}>
                  <RoleCheck
                      component={<ReportingUITS />}
                      permission={'access_reporting'}
                      pageError
                  />
              </Route>
              <Route path={`${path}/viewManualTransaction/:id`}>
                  <ManualTransaction />
              </Route>
              <Route path={`${path}/manualTransaction/`}>
                  <ManualTransaction />
              </Route>
              <Route path={`${path}/generalAccountingEntry/:id`}>
                  <RoleCheck
                      component={<GeneralJournalUI />}
                      permission={'access_GJE'}
                      pageError
                  />
              </Route>
              <Route path={`${path}/generalAccountingEntry`}>
                  <RoleCheck
                      component={<GeneralJournalUI />}
                      permission={'access_GJE'}
                      pageError
                  />
              </Route>
              <Route path={`${path}/generateInvoice`}>
                  <RoleCheck
                      component={<GenerateInvoice />}
                      permission={'generate_Invoice'}
                      pageError
                  />
              </Route>
              <Route path={`${path}/generateInvoiceTable`}>
                  <RoleCheck
                      component={<ManualInvoiceTable />}
                      permission={'generate_Invoice'}
                      pageError
                  />
              </Route>
          </Switch>
      </>
  );
};

export default AdminRouter;
