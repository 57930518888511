// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { AddCard } from '@mui/icons-material';

interface PaymentTextFieldProps {
    showPaymentTypes?: boolean;
    incompleteService?: boolean;
    setIncompleteServiceDialog?: Dispatch<SetStateAction<boolean>>;
    SubmitPayment: (paymentAmount, paymentType) => void;
    initialValue?: number;
    showButton?: boolean;
    disabled?: boolean;
    hideAdornment?: boolean;
}

const PaymentTextField = ({
    showPaymentTypes,
    incompleteService,
    setIncompleteServiceDialog,
    SubmitPayment,
    initialValue,
    showButton,
    disabled,
    hideAdornment = false
}: PaymentTextFieldProps) => {
    const [paymentAmount, setPaymentAmount] = useState<string>('0');
    const [paymentType, setPaymentType] = useState<string>('standard');

    useEffect(() => {
        if (initialValue) {
            setPaymentAmount(String(initialValue));
        }
        if (incompleteService) {
            setPaymentType('deposit');
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        data-cy="paymentAmountField"
                        disabled={disabled}
                        size="small"
                        label="Payment Amount"
                        value={paymentAmount}
                        variant="outlined"
                        onChange={(e) => setPaymentAmount(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter')
                                SubmitPayment(paymentAmount, paymentType);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            endAdornment: hideAdornment ? null : (
                                <IconButton
                                    disabled={disabled}
                                    data-cy="submitPaymentIconButton"
                                    onClick={() =>
                                        SubmitPayment(
                                            paymentAmount,
                                            paymentType
                                        )
                                    }
                                >
                                    <AddCard />
                                </IconButton>
                            )
                        }}
                    />
                </Grid>
                {showPaymentTypes ? (
                    <Grid item xs={12} textAlign="center">
                        <FormControl>
                            <RadioGroup
                                row
                                value={paymentType}
                                onChange={(e) => {
                                    if (!incompleteService) {
                                        setPaymentType(e.target.value);
                                    }
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            value="standard"
                                            color="primary"
                                            sx={
                                                incompleteService
                                                    ? { color: 'grey' }
                                                    : {}
                                            }
                                            onClick={() => {
                                                if (incompleteService) {
                                                    setIncompleteServiceDialog(
                                                        true
                                                    );
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={
                                                incompleteService
                                                    ? { color: 'grey' }
                                                    : {}
                                            }
                                        >
                                            Standard
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            value="deposit"
                                            color="primary"
                                        />
                                    }
                                    label={<Typography>Deposit</Typography>}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                ) : null}
                {showButton ? (
                    <Grid item xs={12} textAlign="center">
                        <Button
                            variant="outlined"
                            data-cy={'submitPaymentButton'}
                            color="primary"
                            onClick={(e) =>
                                SubmitPayment(paymentAmount, paymentType)
                            }
                        >
                            Add Payment
                        </Button>
                    </Grid>
                ) : null}
            </Grid>
        </>
    );
};

export default PaymentTextField;
