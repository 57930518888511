// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { User } from '../interfaces/GeneralInterface';
import dayjs from 'dayjs';

/*  GetUser
    Gets the details of a given user
    params:
        - UserId: if set to 0 will default to current user, otherwise 
        will use the id entered
        - setUser: react state to save the user details to
    returns:
        - the details of the given user in a json format
*/
const GetUser = (
    UserId: number,
    setUser: Dispatch<SetStateAction<User>>,
    setLoading?: Dispatch<SetStateAction<boolean>>,
    setInvalid?: Dispatch<SetStateAction<boolean>>
) => {
    if (setLoading) {
        setLoading(true);
    }
    api.get(`getSingleUser/${UserId}`).then((res) => {
        if (res.status === 204 && setInvalid) {
            setInvalid(true);
        } else {
            let userData = res.data;
            if (
                userData.taxFileNumber == null ||
                userData.taxFileNumber.length === 0
            ) {
                userData.taxFileNumber = { number: '', dateProvided: null };
            } else {
                userData.taxFileNumber.dateProvided = dayjs(
                    userData.taxFileNumber.dateProvided
                );
            }
            if (
                userData.superannuation == null ||
                userData.superannuation.length === 0
            ) {
                userData.superannuation = { company: '', accountNumber: '' };
            }
            if (
                userData.bankAccount == null ||
                userData.bankAccount.length === 0
            ) {
                userData.bankAccount = { name: '', bsb: '', number: '' };
            }
            if (
                userData.emergencyContact == null ||
                userData.emergencyContact.length === 0
            ) {
                userData.emergencyContact = {
                    fullName: '',
                    relation: '',
                    phoneNumber: ''
                };
            }
            setUser(userData);
        }

        if (setLoading) {
            setLoading(false);
        }
    });
};

export default GetUser;
