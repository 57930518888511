// IMPORTS
import { Dispatch, SetStateAction, useState } from 'react';
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
// LOGIC
import HandleResetAverageCost from '../logic/HandleResetAverageCost';
// INTERFACES
import { Unit } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface ResetAverageCostDialogProps {
    partId: number;
    unitData: Unit;
    setResetAverageCostDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const ResetAverageCostDialog = ({
    partId,
    unitData,
    setResetAverageCostDialogOpen,
    showSnackbar
}: ResetAverageCostDialogProps) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    return (
        <>
            <DialogTitle>
                Reset Average Cost of part {unitData.partNumber}
            </DialogTitle>
            <DialogContent>
                Are you sure you want to reset the average cost to the current
                daily cost ${unitData.costPriceDaily}? <br />
                <br />
                <b>This cannot be reversed.</b>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => setResetAverageCostDialogOpen(false)}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        HandleResetAverageCost(
                            partId,
                            unitData,
                            showSnackbar,
                            setButtonLoading
                        )
                    }
                >
                    Proceed
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default ResetAverageCostDialog;
