import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    ValidateABN,
    ValidateEmail,
    ValidatePhone
} from '../../../global/logic/customerValidation/ValidateCustomerFields';

export const checkEmail = (index, importedData, showSnackbar: showSnackbar) => {
    for (let [id, item] of importedData.entries()) {
        if (item[index].length > 0) {
            let validEmail = ValidateEmail(item[index] ?? '');
            if (validEmail.verified === false) {
                showSnackbar(
                    `Invalid email found at line: ${id + 1}`,
                    validEmail.subMessage + '\nEmail found: ' + item[index],
                    'error'
                );
                return validEmail;
            }
        }
    }
    return { verified: true };
};

export const checkPhoneNumber = (
    index,
    importedData,
    showSnackbar: showSnackbar
) => {
    for (let [id, item] of importedData.entries()) {
        if (item[index].length > 0) {
            let validEmail = ValidatePhone(item[index] ?? '');
            if (validEmail.verified === false) {
                showSnackbar(
                    `Invalid phone number found at line: ${id + 1}`,
                    validEmail.subMessage +
                        '\nPhone Number found: ' +
                        item[index],
                    'error'
                );
                return validEmail;
            }
        }
    }
    return { verified: true };
};

export const checkABN = (index, importedData, showSnackbar: showSnackbar) => {
    for (let [id, item] of importedData.entries()) {
        if (item[index].length > 0) {
            let validABN = ValidateABN(item[index]);
            if (validABN.verified === false) {
                showSnackbar(
                    `Invalid ABN found at line: ${id + 1}`,
                    validABN.subMessage + '\nABN found: ' + item[index],
                    'error'
                );
                return validABN;
            }
        }
    }
    return { verified: true };
};
