import { Divider, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Site, Template, TextMessage } from './interfaces/GeneralInterface';
import GetTextMessageTemplates from './databaseLogic/GetTextMessageTemplates';
import Autocomplete from './Autocomplete';
import GetPreviousTextMessages from './databaseLogic/GetPreviousTextMessages';
import {
    DateFormatter,
    DateTimeFormatter,
    TimestampFormatter
} from './logic/Formatters';
import { showSnackbar } from './interfaces/GlobalInterface';
import HandleTextCustomer from './logic/HandleTextCustomer';
import GetSite from './databaseLogic/GetSite';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface TextMessageDrawerContentProps {
    customerName: string;
    customerPhone: string;
    id: number;
    department: string;
    showSnackbar: showSnackbar;
    serviceStartingDateTime?: string;
    bikeMakeModel?: string;
    proformaNumber?: string;
    serviceNumber?: string;
    vehicleSaleNumber?: string;
    onlineOrderNumber?: string;
}
const TextMessageDrawerContent = ({
    id,
    department,
    customerName,
    customerPhone,
    serviceStartingDateTime,
    bikeMakeModel,
    serviceNumber,
    onlineOrderNumber,
    vehicleSaleNumber,
    proformaNumber,
    showSnackbar
}: TextMessageDrawerContentProps) => {
    const [refresh, setRefresh] = useState(true);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [siteDetails, setSiteDetails] = useState<Site>(null);
    const [textTemplates, setTextTemplates] = useState<Template[]>([]);
    const [previousTextMessages, setPreviousTextMessages] = useState<
        TextMessage[]
    >([]);
    const [selectedTemplate, setSelectedTemplate] = useState<Template>(null);
    const [textMessage, setTextMessage] = useState({
        customerName: null,
        customerPhone: null,
        message: null
    });

    useEffect(() => {
        GetTextMessageTemplates(department, setTextTemplates);
        GetPreviousTextMessages(department, id, setPreviousTextMessages);
        setTextMessage({
            ...textMessage,
            customerName: customerName,
            customerPhone: customerPhone
        });
        GetSite(parseInt(localStorage.getItem('SiteId')), setSiteDetails);
        // eslint-disable-next-line
    }, [refresh]);

    useEffect(() => {
        if (selectedTemplate) {
            let replacedTemplate = selectedTemplate.messageBody;
            replacedTemplate = replacedTemplate.replaceAll(
                '[customerName]',
                customerName
            );
            replacedTemplate = replacedTemplate.replaceAll(
                '[businessStore]',
                siteDetails?.name
            );
            replacedTemplate = replacedTemplate.replaceAll(
                '[businessPhoneNumber]',
                siteDetails?.phoneNumber
            );
            replacedTemplate = replacedTemplate.replaceAll(
                '[salesBusinessHours]',
                siteDetails?.salesBusinessHours
            );
            replacedTemplate = replacedTemplate.replaceAll(
                '[serviceBusinessHours]',
                siteDetails?.serviceBusinessHours
            );
            if (bikeMakeModel) {
                replacedTemplate = replacedTemplate.replaceAll(
                    '[bikeMakeModel]',
                    bikeMakeModel
                );
            }
            if (proformaNumber) {
                replacedTemplate = replacedTemplate.replaceAll(
                    '[proformaNumber]',
                    proformaNumber
                );
            }
            if (serviceNumber) {
                replacedTemplate = replacedTemplate.replaceAll(
                    '[serviceNumber]',
                    serviceNumber
                );
            }

            if (vehicleSaleNumber) {
                replacedTemplate = replacedTemplate.replaceAll(
                    '[vehicleSaleNumber]',
                    vehicleSaleNumber
                );
            }
            if (onlineOrderNumber) {
                replacedTemplate = replacedTemplate.replaceAll(
                    '[onlineOrderNumber]',
                    onlineOrderNumber
                );
            }

            if (serviceStartingDateTime) {
                replacedTemplate = replacedTemplate.replaceAll(
                    '[serviceStartingDateTime]',
                    DateTimeFormatter(serviceStartingDateTime)
                );
            }

            setTextMessage({ ...textMessage, message: replacedTemplate });
        }
        // eslint-disable-next-line
    }, [selectedTemplate]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        size="small"
                        options={textTemplates}
                        useTwoOptionLabels={false}
                        primaryOptionLabel="title"
                        textfieldLabel="Template"
                        selectedValue={selectedTemplate}
                        handleSelectedValueChange={(newValue) => {
                            setSelectedTemplate(newValue);
                        }}
                        handleInputValueChange={(newValue) => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Customer Name"
                        value={textMessage.customerName}
                        onChange={(e) =>
                            setTextMessage({
                                ...textMessage,
                                customerName: e.target.value
                            })
                        }
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Customer Phone"
                        value={textMessage.customerPhone}
                        onChange={(e) =>
                            setTextMessage({
                                ...textMessage,
                                customerPhone: e.target.value
                            })
                        }
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        rows={12}
                        label="Message Body"
                        value={textMessage.message}
                        onChange={(e) =>
                            setTextMessage({
                                ...textMessage,
                                message: e.target.value
                            })
                        }
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            HandleTextCustomer(
                                id,
                                textMessage,
                                showSnackbar,
                                refresh,
                                setRefresh,
                                setTextMessage,
                                setButtonLoading,
                                department
                            )
                        }
                    >
                        Send Text
                    </LoadingButton>
                </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            {previousTextMessages.length > 0 ? (
                <>
                    <Typography variant="h6">Previous Text Messages</Typography>
                    <br />
                    {previousTextMessages.map((text) => (
                        <>
                            <Typography variant="body2">
                                <b>Date Sent: </b>
                                {DateFormatter(text.createdAt)}
                            </Typography>
                            <Typography variant="body2">
                                <b>Phone Number:</b> {text.phoneNumberTo}
                            </Typography>
                            <Typography variant="body2">
                                {text.messageBody}
                            </Typography>
                            <br />
                            <br />
                        </>
                    ))}
                </>
            ) : null}
        </>
    );
};

export default TextMessageDrawerContent;
