import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

export const ImportCreditorFromCSV = (
    importedData,
    headers,
    showSnackbar: showSnackbar
) => {
    let formattedArray = [];
    // Go through each line of the CSV
    for (let item of importedData) {
        let tempObject = {
            type: 'standard',
            pushNeto: false,
            contactDetails: null
        };

        // Go through each header/columns
        for (let [index, header] of headers.entries()) {
            tempObject[header] = item[index];
        }

        if (headers.includes('email') && headers.includes('phoneNumber')) {
            tempObject.contactDetails = {
                email: [
                    {
                        id: 1,
                        value: item[headers.findIndex((x) => x === 'email')],
                        reference: 'PRIMARY'
                    }
                ],
                phone: [
                    {
                        id: 1,
                        value: item[
                            headers.findIndex((x) => x === 'phoneNumber')
                        ],
                        reference: 'PRIMARY'
                    }
                ]
            };
        }

        formattedArray.push(tempObject);
    }

    api.post('importCreditorCSV', formattedArray).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Creditor(s) successfully imported.',
                'The page will now refresh.'
            );
            setTimeout(() => window.location.reload(), 1500);
        }
    });
};
