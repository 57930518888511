import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface TagWrapperProps {
    children: ReactNode;
    isViewOnly: boolean;
    isResizable: boolean;
    dragStartHandler: (event: React.DragEvent) => void;
    onClick?: () => void;
}

const TagWrapper = ({
    children,
    isViewOnly,
    isResizable,
    dragStartHandler,
    onClick
}: TagWrapperProps) => {
    return (
        <Box
            draggable={isViewOnly || isResizable}
            onDragStart={dragStartHandler}
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: 'white',
                opacity: isViewOnly ? 1 : 0.7,
                color: 'primary.main',
                borderColor: 'primary.main',
                borderWidth: 1,
                borderStyle: 'solid',
                py: isViewOnly ? 1 : isResizable ? 0.5 : 0,
                px: isViewOnly ? 2 : isResizable ? 1 : 0,
                cursor: isViewOnly || isResizable ? 'move' : 'default',
                width: isViewOnly ? 'fit-content' : '100%',
                height: isViewOnly ? 'fit-content' : '100%',
                userSelect: 'none'
            }}
            onClick={onClick}
        >
            {children}
        </Box>
    );
};

export default TagWrapper;
