// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';

// COMPONENTS
import Paper from '../../../global/Paper';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';

interface CreditingPartsLine {
    partNumber: string;
    name: string;
    unitId: number;
    costPriceDaily: number;
    quantity: number;
    lineTotal: number;
    qtyError: boolean;
    lineTotalError: boolean;
}

interface InvoiceHeaderProps {
    selectedSupplier: number;
    setSelectedSupplier: Dispatch<SetStateAction<number>>;
    selectedSite: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
    reference: string;
    setReference: Dispatch<SetStateAction<string>>;
    documentDate: Dayjs;
    setDocumentDate: Dispatch<SetStateAction<Dayjs>>;
    dueDate: Dayjs;
    setDueDate: Dispatch<SetStateAction<Dayjs>>;
    readOnly: boolean;
    freight: string;
    setFreight: Dispatch<SetStateAction<string>>;
    isGSTIncluded: boolean;
    creditingParts: CreditingPartsLine[];
}

const InvoiceHeader = ({
    freight,
    isGSTIncluded,
    creditingParts,
    setFreight,
    selectedSupplier,
    setSelectedSupplier,
    selectedSite,
    setSelectedSite,
    reference,
    setReference,
    documentDate,
    setDocumentDate,
    dueDate,
    setDueDate,
    readOnly
}: InvoiceHeaderProps) => {
    const getGstValue = (type) => {
        let gstTotal;
        let partTotal = 0;
        if (creditingParts.length > 0) {
            partTotal = creditingParts
                .map((item) => item.lineTotal)
                .reduce((acc, amount) => acc + amount);
        }
        if (isGSTIncluded) {
            gstTotal =
                partTotal +
                parseFloat(freight) -
                (partTotal + parseFloat(freight)) / 1.1;
        } else {
            gstTotal = (partTotal + parseFloat(freight)) * 0.1;
        }

        if (type === 'part') {
            if (isGSTIncluded) {
                return partTotal + parseFloat(freight);
            } else {
                return (
                    partTotal +
                    parseFloat(freight) +
                    (partTotal + parseFloat(freight)) * 0.1
                );
            }
        } else {
            return gstTotal;
        }
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            label="Document Reference"
                            size="small"
                            value={reference}
                            onChange={(e) => setReference(e.target.value)}
                            disabled={readOnly}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={readOnly}
                                format="DD/MM/YYYY"
                                label="Date Received"
                                value={documentDate}
                                onChange={(newValue) => {
                                    setDocumentDate(dayjs(newValue));
                                }}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: false,
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={readOnly}
                                format="DD/MM/YYYY"
                                label="Due Date"
                                value={dueDate}
                                onChange={(newValue) => {
                                    setDueDate(dayjs(newValue));
                                }}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: false,
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            label="Freight"
                            size="small"
                            type="number"
                            value={freight}
                            onChange={(e) => {
                                setFreight(e.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            disabled
                            fullWidth
                            label="Entered Total"
                            size="small"
                            value={CurrencyFormatter(getGstValue('part'))}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextField
                            disabled
                            fullWidth
                            label="GST"
                            size="small"
                            value={CurrencyFormatter(getGstValue('gst'))}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default InvoiceHeader;
