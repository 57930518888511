// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { VehicleInvoice, VehicleInvoiceLine } from '../interfaces/VehicleSaleInterface';
import dayjs from 'dayjs';

const GetVehicleInvoiceDetails = (
    invoiceId: string,
    setVehicleInvoiceDetails: Dispatch<SetStateAction<VehicleInvoice>>,
    setVehicleInvoiceLines: Dispatch<SetStateAction<VehicleInvoiceLine[]>>
) => {
    api.get('vehicleInvoice/' + invoiceId).then((res) => {
        let invoiceDetails = res.data.invoiceDetails;
        invoiceDetails.invoiceDate = dayjs(invoiceDetails.invoiceDate);
        setVehicleInvoiceDetails(invoiceDetails);
        setVehicleInvoiceLines(res.data.invoiceLines);
    });
};

export default GetVehicleInvoiceDetails;
