// IMPORTS
import { useState } from 'react';
import {
    Divider,
    Grid,
    IconButton,
    Table,
    TableCell,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { ExpandLess, ExpandMore, PointOfSale } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import {
    MiscTotal,
    PartTotal,
    PaymentTotal,
    ServiceBalance,
    SubletTotal
} from '../logic/GetTotals';
import CalculateMargin from '../../../global/logic/CalculateMargin';
// INTERFACES
import { PaymentLine } from '../../../global/interfaces/GeneralInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
import {
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
interface TotalFormProps {
    serviceDetails: Service;
    sublets: PurchaseOrder[];
    partSales: Order[];
    paymentLines?: PaymentLine[];
    paymentForm?: boolean;
}
const TotalForm = ({
    sublets,
    serviceDetails,
    partSales,
    paymentLines,
    paymentForm
}: TotalFormProps) => {
    const [showMargin, setShowMargin] = useState(false);

    return (
        <>
            <Paper>
                <Grid container spacing={0}>
                    <Grid item xs={10}>
                        <Typography variant="h6">Totals</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                        {serviceDetails?.id && paymentForm ? (
                            <Tooltip title="Open Booking" placement="left">
                                <IconButton
                                    onClick={() =>
                                        window.open(
                                            `/service/viewBooking/${serviceDetails.id}`,
                                            '_blank'
                                        )
                                    }
                                    size="small"
                                >
                                    <PointOfSale />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Open Payments" placement="left">
                                <IconButton
                                    onClick={() =>
                                        window.open(
                                            `/service/payment/${serviceDetails.id}`,
                                            '_blank'
                                        )
                                    }
                                    size="small"
                                >
                                    <PointOfSale />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
                <Divider />
                <Table size="small">
                    <TableRow>
                        <TableCell
                            width="70%"
                            align="left"
                            sx={{ borderBottom: 'none' }}
                        >
                            Parts
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            {CurrencyFormatter(
                                PartTotal(serviceDetails, partSales).partsTotal
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                            Sublets
                        </TableCell>
                        {/* subletTotal in db is include GST, but here need diplay sublet charges exclude GST */}
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            {CurrencyFormatter(
                                parseFloat(serviceDetails.subletTotal) / 1.1
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                            Miscellaneous
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            {CurrencyFormatter(MiscTotal(serviceDetails))}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                            Labour
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            {CurrencyFormatter(serviceDetails.labourCost)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left">GST</TableCell>
                        <TableCell
                            align="left"
                            sx={{ paddingRight: 0, paddingLeft: 0 }}
                        >
                            {CurrencyFormatter(
                                serviceDetails.jobType === 'Internal' ||
                                    serviceDetails.jobType === 'Rework'
                                    ? 0
                                    : parseFloat(serviceDetails.totalPrice) / 11
                            )}
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                            <b>Total</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            <b>
                                {CurrencyFormatter(
                                    parseFloat(serviceDetails.totalPrice)
                                )}
                            </b>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left">Paid</TableCell>
                        <TableCell
                            align="left"
                            sx={{ paddingRight: 0, paddingLeft: 0 }}
                        >
                            {CurrencyFormatter(
                                PaymentTotal(
                                    paymentLines
                                        ? paymentLines
                                        : serviceDetails.paymentLines
                                )
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="left"
                            sx={{ borderBottom: 'none', paddingBottom: 0 }}
                        >
                            <b>Balance</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0,
                                paddingBottom: 0
                            }}
                        >
                            <b>
                                {CurrencyFormatter(
                                    ServiceBalance(
                                        serviceDetails,
                                        paymentLines
                                            ? paymentLines
                                            : serviceDetails.paymentLines
                                    )
                                )}
                            </b>
                            {showMargin ? (
                                <IconButton
                                    onClick={() => setShowMargin(false)}
                                >
                                    <ExpandLess fontSize="small" />
                                </IconButton>
                            ) : (
                                <IconButton onClick={() => setShowMargin(true)}>
                                    <ExpandMore fontSize="small" />
                                </IconButton>
                            )}
                        </TableCell>
                    </TableRow>
                    {showMargin ? (
                        <>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Part Cost
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {CurrencyFormatter(
                                        PartTotal(serviceDetails, partSales)
                                            .partsCost
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Sublet Cost
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {CurrencyFormatter(SubletTotal(sublets))}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Total Cost
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {CurrencyFormatter(
                                        PartTotal(serviceDetails, partSales)
                                            .partsCost + SubletTotal(sublets)
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Gross Profit
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {CurrencyFormatter(
                                        parseFloat(serviceDetails.totalPrice) -
                                            PartTotal(serviceDetails, partSales)
                                                .partsCost -
                                            SubletTotal(sublets)
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Margin
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {CalculateMargin(
                                        parseFloat(serviceDetails.totalPrice),
                                        PartTotal(serviceDetails, partSales)
                                            .partsCost + SubletTotal(sublets)
                                    )}
                                    %
                                </TableCell>
                            </TableRow>
                        </>
                    ) : null}
                </Table>
            </Paper>
        </>
    );
};

export default TotalForm;
