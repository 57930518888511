// IMPORTS
import jsPDF from 'jspdf';
// INTERFACES
import { PaymentRun } from '../../../global/interfaces/AdminInterface';

// LOGIC
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import GroupBy from '../../../global/logic/GroupBy';

interface CreditorReconciliationProps {
    paymentRun: PaymentRun;
    CreditorId?: number;
}

/**
 * RegeneratePaymentRemittance
 * Regenerate a payment remittance for a payment run
 * @author Pierre
 * @param paymentRun the payrun to regenerate
 */
const RegeneratePaymentRemittance = async ({
    paymentRun,
    CreditorId
}: CreditorReconciliationProps) => {
    let invoices = GroupBy(paymentRun.Invoices, 'SupplierId');
    let invoicesBySupplier = null;

    if (CreditorId) {
        invoicesBySupplier = [invoices[CreditorId]];
    } else {
        invoicesBySupplier = invoices.filter((element) => {
            if (Object.keys(element).length !== 0) {
                return true;
            }
            return false;
        });
    }

    for (let supplier of invoicesBySupplier) {
        let invoicesBySite = GroupBy(supplier, 'SiteId');
        invoicesBySite = invoicesBySite.filter((element) => {
            if (Object.keys(element).length !== 0) {
                return true;
            }
            return false;
        });

        let creditorData = supplier[0].Supplier;

        let doc = new jsPDF({
            orientation: 'p',
            format: 'a4'
        });

        // Set up the header
        await InvoiceHeader(
            doc,
            parseInt(localStorage.getItem('SiteId')),
            'Payment Remittance',
            DateFormatter(String(new Date(Date.now()))),
            false,
            true,
            false
        );
        // Set up the creditor's details in the header
        let lastYCoordinates = 50;
        doc.setFont('helvetica', 'bold');
        doc.text(creditorData.name, 15, lastYCoordinates + 5);
        doc.setFont('helvetica', 'normal');
        doc.text(`ABN: ${creditorData.abn}`, 15, lastYCoordinates + 10);
        let addressLine1 = `${creditorData.addressLine1 ?? ''}`;
        let area = `${creditorData.suburb ?? ''} ${creditorData.postcode ?? ''} ${creditorData.state ?? ''}`;
        doc.text(addressLine1, 15, lastYCoordinates + 15);
        doc.text(area, 15, lastYCoordinates + 20);
        if (creditorData.phoneNumber) {
            doc.text(
                `Phone: ${creditorData.phoneNumber}`,
                15,
                lastYCoordinates + 25
            );
        }

        lastYCoordinates = lastYCoordinates + 40;

        for (let invoices of invoicesBySite) {
            let header = [
                [
                    'Invoice ID',
                    'Document Reference',
                    'Document Date',
                    'Invoice Amount',
                    'Payment Amount',
                    'Amount Remaining'
                ]
            ];
            let body = [];
            doc.setFont('helvetica', 'bold').setFontSize(14);
            doc.text(invoices[0].Site.name, 15, lastYCoordinates);

            // Set up the table of invoices
            let totalDocument = 0;
            let totalPaid = 0;
            let totalRemaining = 0;

            for (let invoice of invoices) {
                let id = invoice.id;
                let reference = '';
                let invoiceTotal = '';
                if (invoice.StockInvoice) {
                    reference = invoice.StockInvoice.documentReference;
                    invoiceTotal = invoice.StockInvoice.documentTotal;
                } else if (invoice.CreditInvoice) {
                    reference = invoice.CreditInvoice.documentReference;
                    invoiceTotal = invoice.CreditInvoice.documentTotal;
                } else if (invoice.AssociatedInvoice) {
                    reference = invoice.AssociatedInvoice.documentReference;
                    invoiceTotal = invoice.AssociatedInvoice.documentTotal;
                }

                let invoiceDate = invoice?.AssociatedInvoice?.invoiceDate
                    ? invoice?.AssociatedInvoice?.invoiceDate
                    : invoice.paymentDate;
                let paymentAmount = invoice.paymentAmount;
                let paymentRemaining =
                    parseFloat(invoiceTotal) - parseFloat(paymentAmount);

                totalDocument += parseFloat(invoiceTotal);
                totalPaid += parseFloat(paymentAmount);
                totalRemaining +=
                    parseFloat(invoiceTotal) - parseFloat(paymentAmount);

                body.push([
                    id,
                    reference,
                    DateFormatter(invoiceDate),
                    CurrencyFormatter(invoiceTotal),
                    CurrencyFormatter(paymentAmount),
                    CurrencyFormatter(paymentRemaining)
                ]);
            }

            body.push([
                '',
                '',
                'Total:',
                CurrencyFormatter(totalDocument),
                CurrencyFormatter(totalPaid),
                CurrencyFormatter(totalRemaining)
            ]);
            InvoiceTable(doc, lastYCoordinates + 5, header, body); // Create the table
            lastYCoordinates = (doc as any).lastAutoTable.finalY + 10;
        }

        window.open(doc.output('bloburl')); // Open the payment remittance
    }
};

export default RegeneratePaymentRemittance;
