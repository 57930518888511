// IMPORTS & MUI
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, TableRow, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
// COMPONENTS
import Paper from '../../../../global/Paper';
import PageWrapper from '../../../../global/PageWrapper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import GetVehicleTrustReceipt from '../../logic/GetVehicleTrustReceipt';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
// INTERFACE
import {
    VehicleTrustPayment,
    VehicleTrustReceipt
} from '../../../../global/interfaces/VehicleSaleInterface';

const ViewTrustReceipt = () => {
    const { id } = useParams<{ id: string }>();
    const [receiptDetails, setReceiptDetails] = useState<{
        trustReceipt: VehicleTrustReceipt;
        trustPayment: VehicleTrustPayment[];
    }>({
        trustReceipt: {
            reconciled: false,
            documentReference: '',
            documentTotal: 0,
            documentDate: dayjs(),
            reconciliationDetails: null
        },
        trustPayment: []
    });

    const columns = [
        { id: 0, label: 'Stock Number' },
        { id: 1, label: 'Vehicle' },
        { id: 2, label: 'Trust Receipt' },
        { id: 4, label: 'Site' },
        { id: 5, label: 'Amount' }
    ];

    useEffect(() => {
        if (id) GetVehicleTrustReceipt(id, setReceiptDetails);
    }, [id]);

    return (
        <PageWrapper>
            <Typography variant="h4">Vehicle Trust Receipt #{id}</Typography>
            <br />
            <Paper>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            label="Document Reference"
                            value={
                                receiptDetails.trustReceipt.documentReference
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            label="Document Date"
                            value={receiptDetails.trustReceipt.documentDate.format(
                                'DD/MM/YYYY'
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            label="Document Total"
                            value={CurrencyFormatter(
                                receiptDetails.trustReceipt.documentTotal
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {receiptDetails.trustReceipt.reconciled
                            ? `Reconciled on the ${receiptDetails.trustReceipt.reconciliationDetails.reconciliationDate.format('DD/MM/YYYY')} by ${receiptDetails.trustReceipt.reconciliationDetails.User.firstName} ${receiptDetails.trustReceipt.reconciliationDetails.User.lastName}`
                            : 'Not Reconciled'}
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Paper>
                <DataTable columns={columns}>
                    {receiptDetails.trustPayment.map((payment) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        '/vehicles/view/' + payment.VehicleId,
                                        '_blank'
                                    )
                                }
                            >
                                {payment.Vehicle.stockNumber}
                            </DataCellColoured>
                            <DataCell>
                                {`${payment.Vehicle.year} ${payment.Vehicle.Make.name} ${payment.Vehicle.Model.name} ${payment.Vehicle.Series != null ? payment.Vehicle.Series.name : ''}`}
                                <br />
                                <span
                                    style={{
                                        color: '#555',
                                        fontSize: '12px'
                                    }}
                                >
                                    VIN: {payment.Vehicle?.vin}
                                </span>
                            </DataCell>
                            <DataCell>{payment.Vehicle.trustReceipt}</DataCell>
                            <DataCell>{payment.Vehicle.Site.name}</DataCell>
                            <DataCell>
                                {CurrencyFormatter(payment.amount)}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
        </PageWrapper>
    );
};

export default ViewTrustReceipt;
