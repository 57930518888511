const InsertNewHeader = (
    doc,
    image,
    serviceDetails,
    settings,
    site,
    pageCount,
    isInsurerQuote?: boolean
) => {
    // PDF title
    doc.addImage(image, 10, 15, 70, 20); // Put image on page
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(13);
    doc.text(`Service Order`, 10, 40);

    doc.setFont('helvetica', 'bold').text(
        (serviceDetails.jobType === 'Quote' || isInsurerQuote
            ? 'Quote #'
            : 'Tax Invoice #') + serviceDetails.id,
        10,
        45
    );

    // Dealership details (ABN, address, etc)
    doc.setFont('helvetica', 'normal').setFontSize(11);
    doc.text(settings.companyName, 199, 20, { align: 'right' });
    doc.text(site.address, 199, 25, { align: 'right' });
    doc.text(site.suburb + ' ' + site.state + ' ' + site.postcode, 199, 30, {
        align: 'right'
    });
    doc.text('Phone: ' + site.phoneNumber, 199, 35, { align: 'right' });
    doc.setFont('helvetica', 'bold').text('ABN: ' + settings.abn, 199, 40, {
        align: 'right'
    });
    doc.setFont('helvetica', 'normal').text(
        'Account Name: ' + settings.directDepositAccountName,
        199,
        45,
        {
            align: 'right'
        }
    );
    doc.setFont('helvetica', 'normal').text(
        'BSB: ' +
            settings.directDepositAccountBsb +
            ' Account Number: ' +
            settings.directDepositAccountNumber,
        199,
        50,
        { align: 'right' }
    );

    return doc;
};

export default InsertNewHeader;
