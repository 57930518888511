// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import { Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { Settings } from '../../../global/interfaces/GeneralInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';

interface SiteProps {
    siteInfo: Site;
    setSiteInfo: Dispatch<SetStateAction<Site>>;
    settings: Settings[];
    readOnly: boolean;
}

const GeneralDetails = ({
    siteInfo,
    setSiteInfo,
    settings,
    readOnly
}: SiteProps) => {
    return (
        <Paper>
            <Typography variant="h6" align="left">
                General Details
            </Typography>
            <Divider /> <br />
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <TextField
                        data-cy="siteCode"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label="Code"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.code}
                        onChange={(e) =>
                            setSiteInfo({ ...siteInfo, code: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        data-cy="siteName"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label="Name"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.name}
                        onChange={(e) =>
                            setSiteInfo({ ...siteInfo, name: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        data-cy="siteSettingId"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        select
                        value={siteInfo?.SettingId}
                        label="Company Name"
                        onChange={(e) =>
                            setSiteInfo({
                                ...siteInfo,
                                SettingId: parseInt(e.target.value),
                                Setting:
                                    settings[
                                        settings.findIndex(
                                            (x) =>
                                                x.id ===
                                                parseInt(e.target.value)
                                        )
                                    ]
                            })
                        }
                        InputLabelProps={{ shrink: true }}
                    >
                        {settings?.map((setting, index) => (
                            <MenuItem
                                key={index + setting?.id + setting?.companyName}
                                data-cy={`company-${setting.id}`}
                                value={setting?.id}
                            >
                                {setting?.companyName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4.5}>
                    <TextField
                        data-cy="sitePhoneNumber"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label="Phone Number"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.phoneNumber}
                        onChange={(e) =>
                            setSiteInfo({
                                ...siteInfo,
                                phoneNumber: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={7.5}>
                    <TextField
                        data-cy="siteEmailAddress"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label="Email Address"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.email}
                        onChange={(e) =>
                            setSiteInfo({ ...siteInfo, email: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={4.5}>
                    <TextField
                        data-cy="siteStreetAddress"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label="Street Address"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.address}
                        onChange={(e) =>
                            setSiteInfo({
                                ...siteInfo,
                                address: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        data-cy="siteSuburb"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label="Suburb"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.suburb}
                        onChange={(e) =>
                            setSiteInfo({ ...siteInfo, suburb: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <TextField
                        data-cy="sitePostcode"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label="Postcode"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.postcode}
                        onChange={(e) =>
                            setSiteInfo({
                                ...siteInfo,
                                postcode: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <TextField
                        data-cy="siteState"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label="State"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.state}
                        onChange={(e) =>
                            setSiteInfo({ ...siteInfo, state: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <TextField
                        data-cy="siteCountry"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        variant="outlined"
                        label="Country"
                        InputLabelProps={{ shrink: true }}
                        value={siteInfo?.country}
                        onChange={(e) =>
                            setSiteInfo({
                                ...siteInfo,
                                country: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        data-cy="siteVehicleTypeSelector"
                        size="small"
                        disabled={readOnly}
                        fullWidth
                        select
                        value={siteInfo?.vehicleTypeSold}
                        label="Type of Vehicle Sold"
                        onChange={(e) =>
                            setSiteInfo({
                                ...siteInfo,
                                vehicleTypeSold: e.target.value
                            })
                        }
                        InputLabelProps={{ shrink: true }}
                    >
                        <MenuItem
                            value={'Motorcycles'}
                            data-cy="vehicleTypeMenuItem-Motorcycles"
                        >
                            Motorcycles
                        </MenuItem>
                        <MenuItem value={'Bicycles'}>Bicycles</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default GeneralDetails;
