// IMPROTS
import { useState } from 'react';
import { TableRow, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import InvoiceHeader from './InvoiceHeader';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../../global/tableComponents/DataTable';
import Fab from '../../../../global/Fab';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
// LOGIC
import SubmitRebateInvoice from '../../logic/SubmitRebateInvoice';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
// INTERFACES
import { Creditor, Site } from '../../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { Account } from '../../../../global/interfaces/AdminInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Vehicle?: Vehicle;
    Account: Account;
    documentDate: string;
    dueDate: string;
    GSTtype: string;
    documentTotal: string;
    documentReference: string;
}
interface InvoiceLine {
    id: number;
    Vehicle?: Vehicle;
    amount: string;
    description: string;
}

const RebateInvoice = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false);
    const [responseCode, setResponseCode] = useState<number>();
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([]);
    const [invoiceObject, setInvoiceObject] = useState<InvoiceObject>({
        Creditor: null,
        Site: null,
        Vehicle: null,
        Account: null,
        documentDate: null,
        dueDate: null,
        GSTtype: 'G1',
        documentTotal: null,
        documentReference: null
    });

    const columns = [
        { id: 0, label: 'Stock Number', width: 100 },
        { id: 1, label: 'Vehicle', width: 300 },
        { id: 2, label: 'Description', width: 300 },
        { id: 3, label: 'Amount', width: 100 },
        { id: 4, label: '', width: 100 }
    ];

    // Handle removing a line from the invoice
    const removeLine = (lineId: number) => {
        let currentLines = [...invoiceLines];
        currentLines = currentLines.filter((x) => x.id !== lineId);
        setInvoiceLines(currentLines);
    };

    // Get the total of all the entered lines
    const totalLines = () => {
        let total = 0;
        invoiceLines.forEach((line) => {
            total += parseFloat(line.amount);
        });
        return total;
    };

    const handleSubmitRebateInvoice = () => {
        if (!isSubmittingInvoice) {
            SubmitRebateInvoice(
                invoiceObject,
                invoiceLines,
                totalLines(),
                responseCode,
                showSnackbar,
                setIsSubmittingInvoice
            );
        }
    };
    return (
        <>
            <InvoiceHeader
                invoiceObject={invoiceObject}
                setInvoiceObject={setInvoiceObject}
                invoiceLines={invoiceLines}
                setInvoiceLines={setInvoiceLines}
                responseCode={responseCode}
                setResponseCode={setResponseCode}
                showSnackbar={showSnackbar}
            />
            <br />
            {invoiceLines.length > 0 && (
                <Paper>
                    <DataTable columns={columns}>
                        {invoiceLines.map((line) => (
                            <TableRow>
                                <DataCellColoured
                                    handleClick={() =>
                                        window.open(
                                            `/vehicles/view/${line.Vehicle.id}`
                                        )
                                    }
                                >
                                    {line.Vehicle?.stockNumber}
                                </DataCellColoured>
                                <DataCell>
                                    {line.Vehicle?.year}{' '}
                                    {line.Vehicle['Make.name']}{' '}
                                    {line.Vehicle['Model.name']}{' '}
                                    {line.Vehicle['Series.name']}
                                    <br />
                                    <span
                                        style={{
                                            color: '#555',
                                            fontSize: '12px'
                                        }}
                                    >
                                        VIN: {line.Vehicle?.vin}
                                    </span>
                                </DataCell>
                                <DataCell>{line.description}</DataCell>
                                <DataCell>
                                    {CurrencyFormatter(line.amount)}
                                </DataCell>
                                <DataCell>
                                    <IconButton
                                        onClick={() => removeLine(line.id)}
                                    >
                                        <Close />
                                    </IconButton>
                                </DataCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <DataCell colSpan={2}></DataCell>
                            <DataCell>
                                <b>Total</b>
                            </DataCell>
                            <DataCell>
                                <b>{CurrencyFormatter(totalLines())}</b>
                            </DataCell>
                            <DataCell></DataCell>
                        </TableRow>
                    </DataTable>
                </Paper>
            )}

            <Fab
                noSession={true}
                editIcon={false}
                onClick={handleSubmitRebateInvoice}
            />
        </>
    );
};

export default withSnackbar(RebateInvoice);
