// IMPROTS// LOGIC
import api from '../../../../../api';
// INTERFACES
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Creditor } from '../../../global/interfaces/GeneralInterface';

const SubmitNewVehicle = (
    listOfVehicles: Vehicle[],
    selectedCreditor: Creditor,
    selectedSite: number,
    invoiceDate: string,
    documentReference: string,
    totalOnInvoice: number,
    expectedTotal: number,
    totalGst: number,
    gstMethod: string,
    responseCode: number,
    showSnackbar: showSnackbar
) => {
    // Check that all the required data is entered
    if (!selectedCreditor) {
        showSnackbar('A creditor must be selected.', '', 'error');
        return;
    }
    if (!documentReference || documentReference === '') {
        showSnackbar(
            'A document reference must be provided.',
            'This is usually found at the top of the invoice.',
            'error'
        );
        return;
    }
    if (!invoiceDate || invoiceDate === '') {
        showSnackbar(
            'A document date must be provided.',
            'This is usually found at the top of the invoice.',
            'error'
        );
        return;
    }
    if (!totalOnInvoice || totalOnInvoice <= 0) {
        showSnackbar(
            'A document total must be provided.',
            'This is usually found at the end of the invoice.',
            'error'
        );
        return;
    }

    // Check that the document reference doesn't already exist
    if (responseCode === 400) {
        showSnackbar(
            'Document Reference already exists.',
            'Please double check your reference.',
            'error'
        );
        return;
    }

    // Check that the document total and the expected total match
    let difference = 0;
    let expectedTotalCents = expectedTotal * 100;
    let totalOnInvoiceCents = totalOnInvoice * 100;
    let gstCents = totalGst * 100;

    if (gstMethod === 'Including') {
        difference = (expectedTotalCents - totalOnInvoiceCents) / 100;
    } else {
        difference =
            (totalOnInvoiceCents - (gstCents + expectedTotalCents)) / 100;
    }

    if (Math.abs(difference) > 0.01) {
        showSnackbar(
            'The document total and entered total do not match.',
            'Please double check your entries.',
            'error'
        );
        return;
    }

    // Create the object for the backend
    let obj = {
        listOfVehicles: listOfVehicles,
        documentTotal: totalOnInvoice,
        userTotal: expectedTotal,
        gstMethod: gstMethod,
        invoiceDate: invoiceDate,
        documentReference: documentReference,
        SiteId: selectedSite,
        SupplierId: selectedCreditor.id
    };

    // Send to backend
    api.post('vehicleInvoiceNextGen', obj).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Invoice successfully submitted.',
                'The page will now refresh.'
            );
            // Set a timeout to reload the page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        } else if (res.status === 409) {
            showSnackbar(res.data, '', 'error');
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default SubmitNewVehicle;
