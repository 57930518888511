// IMPORTS
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable'; // DO NOT REMOVE
import moment from 'moment';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter,
    MonthYearFormatter
} from '../../../global/logic/Formatters';
import api from '../../../../../api';
// INTERFACES
import { TradeIn } from '../../../global/interfaces/VehicleSaleInterface';
import {
    Customer,
    Settings,
    Site
} from '../../../global/interfaces/GeneralInterface';
import VendorStatementHeader from './ContractComponents/VendorStatementHeader';
import { UploadFileToS3 } from '../../../esign/logic/S3FileService';
import dayjs from 'dayjs';

const PDFVendorStatement = async (
    selectedCustomer: Customer,
    linkedTrades: TradeIn[],
    base64Image: string,
    uploadToS3?: boolean,
    redirect?: boolean
) => {
    let settings: Settings;
    let site: Site;
    let image = null;
    let userSignature = null;

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Get the settings in order to get company details and logo
    await api
        .get(
            `settingsNextGen/${localStorage.getItem('SiteId')}?includeImage=true`
        )
        .then((res) => {
            settings = res.data.settings;
            site = res.data.site;

            // Create and format an image object
            let newImg = document.createElement('img');
            newImg.src = res.data.image;
            newImg.className = 'logo';
            image = newImg;
        });

    let pageNumber = 1;

    linkedTrades.forEach((trade, index) => {
        if (index !== 0) {
            doc.addPage();
            pageNumber += 1;
            doc.setPage(pageNumber);
        }

        VendorStatementHeader(doc, image, trade, settings, site);

        /* ---------------------------- Customer Details ---------------------------- */
        doc.setFont('helvetica', 'normal').setFontSize(7);
        doc.rect(10, 30, 93, 67); // The box around the customer details
        doc.text('Salesperson: ', 11, 35);
        doc.line(55, 30, 55, 38); // Vertical line between sales person and LMD
        doc.text('Dealer', 56, 33.5);
        doc.text('Licence No.', 56, 36);
        doc.line(10, 38, 103, 38); // Horizontal line below sales person and LMD

        doc.setFont('helvetica', 'bold').setFontSize(9);
        doc.text('VENDOR("SELLER") DETAILS', 11, 42);
        doc.line(10, 48, 103, 48); // Horizontal line below customer name
        doc.line(10, 55, 103, 55); // Horizontal line below address line 1

        doc.setFont('helvetica', 'normal').setFontSize(7);
        doc.text('Postcode:', 70, 60);
        doc.line(10, 62, 103, 62); // Horizontal line below addres line 2 and postcode

        doc.text('Telephone No:', 11, 67);
        doc.line(10, 69, 103, 69); // Horizontal line below phone number

        doc.text('Email:', 11, 74);
        doc.line(10, 76, 103, 76); // Horizontal line below email address

        doc.text('Date of', 11, 79);
        doc.text('Birth:', 11, 82);
        doc.line(55, 76, 55, 83); // Vertical line between dob and licence number
        doc.text("Driver's", 56, 79);
        doc.text('License No:', 56, 82);
        doc.line(10, 83, 103, 83); // Horizontal line below dob and licence number

        doc.text('ABN:', 11, 88);
        doc.line(10, 90, 103, 90); // Horizontal line below abn
        doc.text('ACN:', 11, 95);

        // Fill customer information if required
        doc.setFont('helvetica', 'normal').setFontSize(9);
        doc.text(trade?.User?.firstName + ' ' + trade?.User?.lastName, 26, 35);

        if (settings?.lmd) {
            doc.text(settings.lmd, 102, 36, { align: 'right' });
        }

        if (selectedCustomer?.companyName) {
            doc.text(selectedCustomer?.companyName, 11, 46);
        } else {
            doc.text(
                selectedCustomer?.firstName + ' ' + selectedCustomer?.lastName,
                11,
                46
            );
        }

        if (selectedCustomer?.addressLine1) {
            doc.text(selectedCustomer?.addressLine1, 11, 53);
        }

        if (selectedCustomer?.addressLine2 && selectedCustomer?.suburb) {
            doc.text(
                selectedCustomer?.addressLine2 + ' ' + selectedCustomer?.suburb,
                11,
                60
            );
        } else if (selectedCustomer?.addressLine2) {
            doc.text(selectedCustomer?.addressLine2, 11, 60);
        } else if (selectedCustomer?.suburb) {
            doc.text(selectedCustomer?.suburb, 11, 60);
        }

        if (selectedCustomer?.postcode) {
            doc.text(selectedCustomer?.postcode, 82, 60);
        }

        if (selectedCustomer?.phoneNumber) {
            doc.text(selectedCustomer?.phoneNumber, 30, 67);
        }

        if (selectedCustomer?.email) {
            doc.text(selectedCustomer?.email, 19, 74);
        }

        if (selectedCustomer?.driverLicenceNumber) {
            doc.text(selectedCustomer?.driverLicenceNumber, 71, 82);
        }

        if (selectedCustomer?.dateOfBirth) {
            let dob = moment(selectedCustomer?.dateOfBirth);
            doc.text(dob.format('DD MMM YYYY'), 22, 82);
        }

        if (selectedCustomer?.abn) {
            doc.text(selectedCustomer?.abn, 19, 88);
        }

        /* ----------------------------- Vehicle Details ---------------------------- */
        doc.setFont('helvetica', 'bold').setFontSize(9);
        doc.rect(107, 30, 93, 67); // The box around vehicle details

        doc.text('DESCRIPTION OF USED MOTOR VEHICLE (TRADE-IN)', 109, 34);
        doc.line(107, 36, 200, 36); // Horizontal line below title

        doc.setFont('helvetica', 'normal').setFontSize(7);
        doc.text('Vehicle:', 109, 40);
        doc.line(107, 41, 200, 41); // Horizontal line below vehicle description

        doc.text('Registration', 109, 45);
        doc.text('Number:', 109, 48);
        doc.line(155, 41, 155, 84); // Vertical line between registration number and due date
        doc.text('Registration', 156, 45);
        doc.text('Due Date:', 156, 48);
        doc.line(107, 49, 200, 49); // Horizontal line below registration number and due date

        doc.text('VIN/', 109, 59);
        doc.text('Chassis:', 109, 62);
        doc.text('Transmission:', 156, 61);
        doc.line(107, 63, 200, 63); // Horizontal line below vin

        doc.text('Built', 109, 73);
        doc.text('Date:', 109, 76);
        doc.text('Compliance', 156, 73);
        doc.text('Plate Date:', 156, 76);
        doc.line(107, 77, 200, 77); // Horizontal line below year and compliance

        doc.text('Body', 109, 52);
        doc.text('Type:', 109, 55);
        doc.text('Body', 156, 52);
        doc.text('Colour:', 156, 55);
        doc.line(107, 56, 200, 56); // Horizontal line below body type and body color

        doc.text('Engine', 109, 66);
        doc.text('Number:', 109, 69);
        doc.text('Engine Size:', 156, 68);
        doc.line(107, 70, 200, 70); // Horizontal line below engine number

        doc.text('Odometer', 109, 80).text('kms', 154, 80, {
            align: 'right'
        });
        doc.text('Reading:', 109, 83).text('mls', 154, 83, {
            align: 'right'
        });
        doc.text('Stock', 156, 80);
        doc.text('No:', 156, 83);
        doc.line(107, 84, 200, 84); // Horizontal line below odometer and stock number

        doc.text('Personal Plate:', 109, 88);
        doc.rect(130, 86, 2, 2); // The checkbox for the first point
        doc.text('Yes', 134, 88);
        doc.rect(156, 86, 2, 2); // The checkbox for the second point
        doc.text('No', 160, 88);
        doc.line(107, 90, 200, 90); // Horizontal line below personal plate?

        doc.text('New Rego:', 109, 94);

        // Fill vehicle details if required
        doc.setFont('helvetica', 'normal').setFontSize(9);
        doc.text(
            trade?.Vehicle?.Make?.name + ' ' + trade?.Vehicle?.Model.name,
            119,
            40
        );

        if (trade?.Vehicle?.registrationNumber) {
            doc.text(trade?.Vehicle?.registrationNumber, 124, 47);
        }

        if (trade?.Vehicle?.registrationDueDate) {
            doc.text(
                DateFormatter(trade?.Vehicle?.registrationDueDate),
                171,
                47
            );
        }

        if (trade?.Vehicle?.BodyTypeId) {
            doc.text(trade?.Vehicle?.BodyType.name, 119, 54);
        }

        if (trade?.Vehicle?.colour) {
            doc.text(trade?.Vehicle?.colour, 171, 54);
        }

        if (trade?.Vehicle?.vin) {
            doc.text(trade?.Vehicle?.vin, 120, 61);
        }

        if (trade?.Vehicle?.transmission) {
            doc.text(trade?.Vehicle?.transmission, 173, 61);
        }

        if (trade?.Vehicle?.engineNumber) {
            doc.text(trade?.Vehicle?.engineNumber, 120, 68);
        }

        if (trade?.Vehicle?.engineSize) {
            doc.text(trade?.Vehicle?.engineSize, 171, 68);
        }

        if (trade?.Vehicle?.complianceDate) {
            doc.text(
                MonthYearFormatter(trade?.Vehicle?.complianceDate),
                173,
                75
            );
        }

        if (trade?.Vehicle?.odometer) {
            doc.text(trade?.Vehicle?.odometer, 148, 81, { align: 'right' });
        }

        if (trade?.Vehicle?.stockNumber) {
            doc.text(trade?.Vehicle?.stockNumber, 171, 81);
        }

        doc.rect(10, 100, 190, 10, 'F');

        doc.setFont('helvetica', 'bold')
            .setFontSize(13)
            .setTextColor(240, 240, 240);
        doc.text('Trade-In Statement', 82, 106, { align: 'left' });

        doc.setFont('helvetica', 'normal').setFontSize(7).setTextColor(0);
        doc.text(
            'I/We have this day sold the vehicle described above to the Motor Dealer shown together with all accessories (as listed), tyres, radio, tools, etc. for the sum of ',
            10,
            115
        );
        doc.text(
            '(inclusive of GST): ________________________________________________',
            10,
            120
        );
        doc.setFont('helvetica', 'bold').setFontSize(8);
        doc.text(CurrencyFormatter(trade.tradeInPrice), 35, 120);
        doc.setFont('helvetica', 'normal').setFontSize(7);
        doc.text(
            'Accessories:  _____________________________________________________________________________________________________________________________',
            10,
            125
        );

        doc.setFont('helvetica', 'normal').setFontSize(7);
        doc.text('1.', 10, 130);
        let text1 = '(a) The engine ';
        let text2 = ' HAS / HAS NOT ';
        let text3 =
            ' been replaced by/on behalf of the vendor. If replaced, the approximate date of replacement was: ________________________';
        let width1 = doc.getTextWidth(text1);
        let width2 = doc.getTextWidth(text2);
        doc.text(text1, 14, 130);
        doc.setFont('helvetica', 'bold').text(text2, 14 + width1, 130);
        doc.setFont('helvetica', 'normal').text(
            text3,
            14 + width1 + width2,
            130
        );

        let text4 = '(b) The odometer ';
        let text5 = ' HAS / HAS NOT ';
        let text6 =
            ' been replace by/on behalf of the vendor, and I confirm to the best of my knowledge that the odometer reading is correct.';
        let width4 = doc.getTextWidth(text4);
        let width5 = doc.getTextWidth(text5);
        doc.text(text4, 14, 135);
        doc.setFont('helvetica', 'bold').text(text5, 14 + width4, 135);
        doc.setFont('helvetica', 'normal').text(
            text6,
            14 + width4 + width5,
            135
        );

        let text7 = '(c) The Concessional Registration ';
        let text8 = ' HAS / HAS NOT ';
        let text9 = ' been claimed in respect of the vehicle by the vendor.';
        let width7 = doc.getTextWidth(text7);
        let width8 = doc.getTextWidth(text8);
        doc.text(text7, 14, 140);
        doc.setFont('helvetica', 'bold').text(text8, 14 + width7, 140);
        doc.setFont('helvetica', 'normal').text(
            text9,
            14 + width7 + width8,
            140
        );

        doc.text(
            '(d) If fitted with Air Bags, have the Air Bags been activated?',
            14,
            145
        );
        doc.setFont('helvetica', 'bold').text('YES / NO', 80, 145);

        doc.setFont('helvetica', 'normal');
        doc.text(
            "(e) That any conversion or modification from the manufacturer's specifications                               been approved by the Department of Transport and Main Roads and a ",
            14,
            150
        );
        doc.text(
            'Letter of Approval is held and modification plates are fixed to the vehicle.',
            14,
            153
        );
        doc.setFont('helvetica', 'bold').text('HAS / HAS NOT', 100, 150);

        doc.setFont('helvetica', 'normal');
        let text10 = '(f) The vehicle ';
        let text11 = ' IS / IS NOT ';
        let text12 = ' currently on the written off vehicle register.';
        let width10 = doc.getTextWidth(text10);
        let width11 = doc.getTextWidth(text11);
        doc.text(text10, 14, 158);
        doc.setFont('helvetica', 'bold').text(text11, 14 + width10, 158);
        doc.setFont('helvetica', 'normal').text(
            text12,
            14 + width10 + width11,
            158
        );

        let text13 = '(g) The vehicle ';
        let text14 = ' HAS / HAS NOT ';
        let text15 =
            ' been previously recorded on the written-off vehicle register.';
        let width13 = doc.getTextWidth(text13);
        let width14 = doc.getTextWidth(text14);
        doc.text(text13, 14, 163);
        doc.setFont('helvetica', 'bold').text(text14, 14 + width13, 163);
        doc.setFont('helvetica', 'normal').text(
            text15,
            14 + width13 + width14,
            163
        );

        doc.text(`2. `, 10, 168);
        let split = doc.splitTextToSize(
            `(a) The vehicle is the vendor's sole and absolutely unencumbered property and free from any Consumer Mortage, Bill of Sale, Hire Purchase Agreement, lien, charge or an adverse interest whatsoever, and no person or corporation has any rights, title or interest therein and the vendor has good right and title to sell same; or`,
            180
        );
        doc.text(split, 14, 168);

        split = doc.splitTextToSize(
            `(b) There is an encumbrance, charge, lien, or the other adverse interest whatsoever by the way of Bill of Sale/ Hire Purchase Agreement/ Other over the said vehicle `,
            190
        );
        doc.text(split, 14, 175);

        doc.text(
            `is to ________________________________________  for $ _________________________ (inclusive of GST)`,
            14,
            180
        );

        let text16 = `(c) The said encumbrance `;
        let text17 = ' IS / IS NOT ';
        let text18 = ' to be paid out by the Motor Dealer.';
        let width16 = doc.getTextWidth(text16);
        let width17 = doc.getTextWidth(text17);
        doc.text(text16, 14, 185);
        doc.setFont('helvetica', 'bold').text(text17, 14 + width16, 185);
        doc.setFont('helvetica', 'normal').text(
            text18,
            14 + width16 + width17,
            185
        );

        doc.text(
            '3. I am not bankrupt and have not committed any act of bankruptcy.',
            10,
            190
        );

        let text19 = '4. The vehicle ';
        let text20 = ' HAS / HAS NOT ';
        let text21 = ' been used as a Taxi or Hire Car.';
        let width19 = doc.getTextWidth(text19);
        let width20 = doc.getTextWidth(text20);
        doc.text(text19, 10, 195);
        doc.setFont('helvetica', 'bold').text(text20, 10 + width19, 195);
        doc.setFont('helvetica', 'normal').text(
            text21,
            10 + width19 + width20,
            195
        );

        doc.text(
            '5. That no Defect notice has been issued against this vehicle.',
            10,
            200
        );

        let text22 = '6. The vehicle ';
        let text23 = ' IS / IS NOT ';
        let text24 =
            ' to be sold at auction. The reserve price for the vehicle at auction is $_________________';
        let width22 = doc.getTextWidth(text22);
        let width23 = doc.getTextWidth(text23);
        doc.text(text22, 10, 205);
        doc.setFont('helvetica', 'bold').text(text23, 10 + width22, 205);
        doc.setFont('helvetica', 'normal').text(
            text24,
            10 + width22 + width23,
            205
        );

        split = doc.splitTextToSize(
            `7. In the interpretation of this document, the singular shall include the plural and any statement or warranty by or obligation upon more than one person shall bind them jointly and severally. `,
            185
        );
        doc.text(split, 10, 210);

        doc.setFont('helvetica', 'bold');
        doc.text('8. Goods and Services Tax Statement', 10, 218);
        doc.setFont('helvetica', 'normal');
        doc.text(
            '(i) I am registered or required to be registered for GST - ',
            10,
            223
        );
        doc.setFont('helvetica', 'bold').text('YES / NO', 73, 223);

        doc.setFont('helvetica', 'normal');
        doc.text(
            '(ii) The supply of the above mentioned vehicle by me represents a taxable supply by me in the course of an enterprise which I carry on - ',
            10,
            228
        );
        doc.setFont('helvetica', 'bold').text('YES / NO', 160, 228);

        doc.setFont('helvetica', 'normal').text(
            '(iii) The supply of the above mentioned vehicle by me is not a GST-free supply - ',
            10,
            233
        );
        doc.setFont('helvetica', 'bold').text('YES / NO', 100, 233);

        doc.setFont('helvetica', 'normal').text(
            '(iv) If this supply does not represent a taxable supply by me, I will provide the dealer with a valid tax invoice at the time of the Dealer signing this statement.',
            10,
            238
        );

        split = doc.splitTextToSize(
            '9. I understand that under section 218 of the Motor Dealers and Chattel Auctioneers Act 2014, I am liable to penalty if I willfully represent anything that is false or misleading about the total distance travelled by the above forementioned vehicle.',
            175
        );

        doc.setFont('helvetica', 'bold').text(split, 10, 243);

        doc.setFontSize(9);

        doc.setFont('helvetica', 'normal').text(
            'Declared at    _________________________   this    _______________     day of  _________________________   _______________',
            10,
            260
        );

        doc.setFont('helvetica', 'bold').text(
            'I/We hereby certify that the above information supplied is true and correct.',
            10,
            270
        );

        doc.setFont('helvetica', 'normal').text(
            'Witness Signature: ______________________________ ',
            10,
            285
        );
        doc.text(
            'Vendor("Seller") Signature: ______________________________ ',
            100,
            285
        );

        //declare time, day, month, year, default as current time
        let now = dayjs();
        let time = now.format('HH:mma');
        let day = now.format('DD');
        let month = now.format('MMMM');
        let year = now.format('YYYY');
        doc.text(time, 40, 260);
        doc.text(day, 100, 260);
        doc.text(month, 140, 260);
        doc.text(year, 180, 260);

        // witness signature
        let sigImg = document.createElement('img');
        sigImg.src = base64Image;
        sigImg.className = 'signature';
        userSignature = sigImg;
        doc.addImage(userSignature, 40, 270, 50, 20);
    });

    if (uploadToS3) {
        var base64data = doc.output('datauristring'); // base64 string
        UploadFileToS3({
            base64data: base64data,
            folderName: `vehicleTrades/${linkedTrades[0].VehicleId}`,
            fileName: 'VendorStatement'
        });
    } else {
        // Open the pdf
        window.open(doc.output('bloburl'));
    }

    if (redirect) {
        window.location.href = '/vehicles/stock';
    }
};

export default PDFVendorStatement;
