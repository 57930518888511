import { Typography } from '@material-ui/core';
import { Button, Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface DrawSignatureProps {
    setTempSignatureDataUrl: React.Dispatch<React.SetStateAction<string>>;
}

const DrawSignature = ({ setTempSignatureDataUrl }: DrawSignatureProps) => {
    const canvasRef = useRef(null);
    const paperRef = useRef(null);

    const [dimensions, setDimensions] = useState({ width: 450, height: 150 }); // Default canvas dimensions

    useEffect(() => {
        if (paperRef.current) {
            const { width, height } = paperRef.current.getBoundingClientRect();
            setDimensions({ width, height });
        }
    }, []);

    return (
        <>
            <Button onClick={() => canvasRef.current.clear()}>Clear</Button>
            <Paper elevation={3} ref={paperRef}>
                <SignatureCanvas
                    ref={canvasRef}
                    penColor={'black'}
                    canvasProps={{
                        width: dimensions.width,
                        height: dimensions.width / 4,
                        className: 'sigCanvas'
                    }}
                    onEnd={() => {
                        setTempSignatureDataUrl(canvasRef.current.toDataURL());
                    }}
                />
            </Paper>
            <Typography variant="caption">
                By selecting Add, I confirm that this is the electronic version
                of my signature.
            </Typography>
        </>
    );
};

export default DrawSignature;
