// IMPROTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
// COMPONENTS
import { withSnackbar } from '../WrappingSnackbar';
import Fab from '../Fab';
import Autocomplete from '../Autocomplete';
// LOGIC
import GetAllShippingMethods from '../databaseLogic/GetAllShippingMethods';
import HandleUpdateShippingDetails from '../../parts/newSale/logic/HandleUpdateShippingDetails';
import HandleAddShippingDetails from '../../parts/newSale/logic/HandleAddShippingDetails';
import GetAllSites from '../databaseLogic/GetAllSites';
// INTERFACE
import { showSnackbar } from '../interfaces/GlobalInterface';
import {
    OnlineOrder,
    Order,
    ShippingMethod
} from '../interfaces/PartsInterface';
import { Site } from '../interfaces/GeneralInterface';
import Tracking from './Tracking';

interface ShippingDrawerContentProps {
    associatedOnlineOrder: OnlineOrder;
    setAssociatedOnlineOrder: Dispatch<SetStateAction<OnlineOrder>>;
    setOnlineOrderSuccess: Dispatch<SetStateAction<boolean>>;
    saleDetails?: Order;
    setSaleDetails?: Dispatch<SetStateAction<Order>>;
    showSnackbar: showSnackbar;
}

const clearedOnlineOrder = {
    OnlineOrderId: null,
    orderStatus: null,
    shippingAddressLine1: null,
    shippingSuburb: null,
    shippingState: null,
    shippingCountry: null,
    shippingPhoneNumber: null,
    shippingEmail: null,
    dateInvoiced: null,
    deliveryInstructions: null,
    shippingOption: null,
    shippingTotal: null,
    shippingFirstName: null,
    shippingLastName: null,
    shippingCompany: null,
    shippingPostcode: null,
    shippingSignature: false
};

const ShippingDrawerContent = ({
    associatedOnlineOrder,
    setAssociatedOnlineOrder,
    setOnlineOrderSuccess,
    saleDetails,
    setSaleDetails,
    showSnackbar
}: ShippingDrawerContentProps) => {
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>(
        []
    );
    const [newOrder, setNewOrder] = useState<boolean>(false);
    const [sites, setSites] = useState<Site[]>([]);

    const orderStatusOptions = [
        { value: 'New' },
        { value: 'Pick' },
        { value: 'Pack' },
        { value: 'On Hold' },
        { value: 'New Backorder' },
        { value: 'Backorder Approved' },
        { value: 'Pending Dispatch' },
        { value: 'Dispatched' },
        { value: 'Pending Pickup' },
        { value: 'Collected' },
        { value: 'Cancelled' }
    ];

    useEffect(() => {
        if (!associatedOnlineOrder?.OnlineOrderId) {
            setReadOnly(false);
            setNewOrder(true);
            setAssociatedOnlineOrder(clearedOnlineOrder);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        GetAllShippingMethods(
            parseInt(localStorage.getItem('SiteId')),
            setShippingMethods
        );
        GetAllSites(setSites);
    }, []);

    const handleFabClick = () => {
        if (readOnly) {
            setReadOnly(false);
        } else if (newOrder) {
            HandleAddShippingDetails(
                associatedOnlineOrder,
                showSnackbar,
                setOnlineOrderSuccess,
                setReadOnly
            );
        } else {
            HandleUpdateShippingDetails(
                associatedOnlineOrder,
                showSnackbar,
                setOnlineOrderSuccess,
                setReadOnly,
                saleDetails.SiteId
            );
        }
    };

    return (
        <>
            <Autocomplete
                size="small"
                options={sites}
                useTwoOptionLabels={false}
                primaryOptionLabel="name"
                textfieldLabel="Pick Location"
                isDisabled={readOnly}
                selectedValue={saleDetails?.Site}
                handleSelectedValueChange={(newValue) =>
                    setSaleDetails({
                        ...saleDetails,
                        Site: newValue,
                        SiteId: newValue.id
                    })
                }
                handleInputValueChange={() => null}
            />
            <br />
            <Typography variant="h6">Basic Details</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        select
                        size="small"
                        label="Order Status"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.orderStatus}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                orderStatus: e.target.value
                            })
                        }
                    >
                        {orderStatusOptions.map((option) => (
                            <MenuItem value={option.value}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Online Order ID"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.OnlineOrderId}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                OnlineOrderId: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="First Name"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingFirstName}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingFirstName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Last Name"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingLastName}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingLastName: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Company Name"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingCompany}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingCompany: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Email Address"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingEmail}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingEmail: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Phone Number"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingPhoneNumber}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingPhoneNumber: e.target.value
                            })
                        }
                    />
                </Grid>
            </Grid>
            <br />
            <Typography variant="h6">Address Details</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Address Line"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingAddressLine1}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingAddressLine1: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Suburb"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingSuburb}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingSuburb: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="State"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingState}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingState: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={2.5}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Postcode"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingPostcode}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingPostcode: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Country"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingCountry}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingCountry: e.target.value
                            })
                        }
                    />
                </Grid>
            </Grid>
            <br />
            <Typography variant="h6">Shipping Details</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        select
                        size="small"
                        label="Shipping Option"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingOption}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingOption: e.target.value
                            })
                        }
                    >
                        {shippingMethods.map((method) => (
                            <MenuItem value={method.name}>
                                {method.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Total Shipping Cost"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.shippingTotal}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                shippingTotal: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                disabled={readOnly}
                                checked={
                                    associatedOnlineOrder?.shippingSignature
                                        ? true
                                        : false
                                }
                                onChange={(e) =>
                                    setAssociatedOnlineOrder({
                                        ...associatedOnlineOrder,
                                        shippingSignature: e.target.checked
                                    })
                                }
                            />
                        }
                        label="Signature on Delivery"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        size="small"
                        label="Delivery Instructions"
                        InputLabelProps={{ shrink: true }}
                        value={associatedOnlineOrder?.deliveryInstructions}
                        onChange={(e) =>
                            setAssociatedOnlineOrder({
                                ...associatedOnlineOrder,
                                deliveryInstructions: e.target.value
                            })
                        }
                    />
                </Grid>
                {associatedOnlineOrder?.orderStatus === 'Dispatched' && (
                    <Grid item xs={12}>
                        <Tracking
                            onlineOrderData={associatedOnlineOrder}
                            showSnackbar={showSnackbar}
                        />
                    </Grid>
                )}
            </Grid>

            <Fab
                editIcon={readOnly}
                onClick={() => handleFabClick()}
                noSession={true}
            />
        </>
    );
};

export default withSnackbar(ShippingDrawerContent);
