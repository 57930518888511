// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
// INTERFACES
import { Account } from '../interfaces/AdminInterface';

const GetAllAccounts = (setAccounts: Dispatch<SetStateAction<Account[]>>) => {
  api.get('accounts').then((res) => {
    setAccounts(res.data);
  });
};

export default GetAllAccounts;
