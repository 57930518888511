import { useEffect, useState } from 'react';
import { DialogActions, DialogContent, Grid, Typography } from '@mui/material';

// COMPONENT
import Autocomplete from '../../../../global/Autocomplete';

// LOGIC
import GetMakes from '../../../../global/databaseLogic/GetMakes';
import api from '../../../../../../api';

// INTERFACE
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Make } from '../../../../global/interfaces/VehicleSaleInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const MergeMakeDialogContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [makeList, setMakeList] = useState<Make[]>([]);
    const [receivingMake, setReceivingMake] = useState<Make>();
    const [toMergeMake, setToMergeMake] = useState<Make>();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    useEffect(() => {
        GetMakes(setMakeList);
    }, []);

    const MergeMake = () => {
        setButtonLoading(true);
        api.put('mergeMake', {
            receivingMake: receivingMake,
            toMergeMake: toMergeMake
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar('Makes successfully merged.');
                window.location.reload();
            } else if (res.status === 400) {
                showSnackbar(
                    "You can't select the same twice make when merging.",
                    '',
                    'error'
                );
                setButtonLoading(false);
            } else {
                showSnackbar(
                    'Something went wrong, refresh the page and try again.',
                    '',
                    'error'
                );
                setButtonLoading(false);
            }
        });
    };

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            size="small"
                            options={makeList}
                            useTwoOptionLabels={true}
                            primaryOptionLabel={'id'}
                            secondaryOptionLabel={'name'}
                            textfieldLabel="Merge Parent"
                            selectedValue={receivingMake}
                            handleSelectedValueChange={(event) => {
                                setReceivingMake(event);
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            size="small"
                            options={makeList.filter(
                                (x) => x.id !== receivingMake?.id
                            )}
                            isDisabled={receivingMake?.id ? false : true}
                            useTwoOptionLabels={true}
                            primaryOptionLabel={'id'}
                            secondaryOptionLabel={'name'}
                            textfieldLabel="Merge Duplicate"
                            selectedValue={toMergeMake}
                            handleSelectedValueChange={(event) => {
                                setToMergeMake(event);
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                </Grid>
                <Typography variant="caption">
                    Duplicate will be merged into parent.
                </Typography>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    disabled={receivingMake == null || toMergeMake == null}
                    onClick={() => MergeMake()}
                >
                    Merge Makes
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default MergeMakeDialogContent;
