import jsPDF from "jspdf";
import { CurrencyFormatter } from "../../../../../../global/logic/Formatters"

interface OperationalProfitParams {
    (
        doc: jsPDF,
        totalIE: { current: number; ytd: number; previousYear: number; previousYearYtd: number },
        totalODE: {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        },
        departmentalProfit: {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        },
        afterODE: number
    ): [
        number,
        {
            current: number;
            ytd: number;
            previousYear: number;
            previousYearYtd: number;
        }
    ];
}

const OperationalProfit: OperationalProfitParams = (doc, totalIE, totalODE, departmentalProfit, afterODE) => {
    let operationalProfit = {
        current: departmentalProfit.current - (totalIE.current + totalODE.current),
        ytd: departmentalProfit.ytd - (totalIE.ytd + totalODE.ytd),
        previousYear: departmentalProfit.previousYear - (totalIE.previousYear + totalODE.previousYear),
        previousYearYtd: departmentalProfit.previousYearYtd - (totalIE.previousYearYtd + totalODE.previousYearYtd)
    }
    doc.setFont('helvetica', 'bold').text('TOTAL INDIRECT EXPENSES', 10, afterODE)
    doc.text(CurrencyFormatter(totalIE.current + totalODE.current), 74, afterODE, {align:'right'})
    doc.text(CurrencyFormatter(totalIE.ytd + totalODE.ytd), 107, afterODE, {align:'right'})
    doc.text(CurrencyFormatter(totalIE.previousYear + totalODE.previousYear), 140, afterODE, {align:'right'})
    doc.text(CurrencyFormatter(totalIE.previousYearYtd + totalODE.previousYearYtd), 173, afterODE, {align:'right'})

    doc.setFont('helvetica', 'bold').text('OPERATIONAL PROFIT', 10, afterODE + 4)
    doc.text(CurrencyFormatter(operationalProfit.current), 74, afterODE + 4, {align:'right'})
    doc.text(CurrencyFormatter(operationalProfit.ytd), 107, afterODE + 4, {align:'right'})
    doc.text(CurrencyFormatter(operationalProfit.previousYear), 140, afterODE + 4, {align:'right'})
    doc.text(CurrencyFormatter(operationalProfit.previousYearYtd), 173, afterODE + 4, {align:'right'})

    doc.line(10, afterODE + 5, 200, afterODE + 5)

    return [afterODE + 8, operationalProfit]
}

export default OperationalProfit