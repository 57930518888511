import DocumentationPage from '../DocumentationPage';
import {
    NewPurchaseOrderSteps,
    InvoicePurchaseOrderSteps
} from '../data/service/purchaseOrder';

const PurchaseOrderSupportPage = () => {
    const contentSections = [
        {
            id: 'new-purchase-order',
            title: 'Create a new purchase order',
            href: '/support/service/purchaseOrders/#new-purchase-order',
            description:
                'To create a new purchase order, follow these outlined steps:',
            steps: NewPurchaseOrderSteps
        },
        {
            id: 'invoice-purchase-order',
            title: 'Invoice a purchase order',
            href: '/support/service/purchaseOrders/#invoice-purchase-order',
            description:
                'To invoice a purchase order, follow these outlined steps:',
            steps: InvoicePurchaseOrderSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Purchase Order"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default PurchaseOrderSupportPage;
