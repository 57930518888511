import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const DeleteTrustReceipt = (
    TrustId: number,
    VehicleId: number,
    showSnackbar: showSnackbar
) => {
    api.put(`/removeTrustReceipt/${TrustId}?VehicleId=${VehicleId}`).then(
        (res) => {
            if (res.status === 200) {
                showSnackbar(
                    'Transaction deleted',
                    'The page will now refresh.'
                );
                window.location.reload();
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        }
    );
};

export default DeleteTrustReceipt;
