// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
// LOGIC
import GetAllSites from '../global/databaseLogic/GetAllSites';
// INTERFACES

interface SiteSelectorProps {
    variant: 'outlined' | 'standard' | 'filled';
    label: string;
    disabled: boolean;
    fullWidth: boolean;
    size: 'medium' | 'small';
    error?: boolean;
    viewPartUI: boolean; // Check if we are in the view part ui component
    // Used by Site Selector to display SOH at selected site
    stockData?: {
        id?: number;
        partNumber?: string;
        qtyOnHand?: number;
        qtyReserved?: number;
        qtyIncoming?: number;
        qtyReturned?: number;
        qtyTransferIn?: number;
        qtyTransferOut?: number;
        SiteId?: number;
        UnitId?: number;
        supplier?: number;
        onlineReserved?: number;
        lastUpdatedBy?: number;
        maxStock?: number;
        minStock?: number;
        costPriceAverage?: number;
    }[];
    selectedSite: number | string;
    setSelectedSite: Dispatch<SetStateAction<number>>;
    includeAllOption?: boolean;
}

const SiteSelector = ({
    variant,
    label,
    disabled,
    fullWidth,
    size,
    error,
    viewPartUI,
    stockData,
    selectedSite,
    setSelectedSite,
    includeAllOption
}: SiteSelectorProps) => {
    const [siteData, setSiteData] = useState([]);

    useEffect(() => {
        GetAllSites(setSiteData);
    }, []);

    const handleChange = (e) => {
        setSelectedSite(e.target.value);
    };

    const getSiteStock = (SiteId: number) => {
        let newArray = stockData.filter((x) => x.SiteId === SiteId);
        return newArray[0];
    };

    if (viewPartUI) {
        return (
            <>
                <TextField
                    disabled={disabled}
                    fullWidth={
                        typeof fullWidth === 'undefined' ? true : fullWidth
                    }
                    error={error}
                    select
                    variant={variant}
                    label={label}
                    value={selectedSite}
                    onChange={handleChange}
                    size={size}
                    InputLabelProps={{ shrink: true }}
                >
                    {siteData.map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                            {site.name} - SOH:{' '}
                            {getSiteStock(site.id)?.qtyOnHand}
                        </MenuItem>
                    ))}
                </TextField>
            </>
        );
    } else {
        return (
            <TextField
                disabled={disabled}
                fullWidth={typeof fullWidth === 'undefined' ? true : fullWidth}
                select
                error={error}
                // InputProps={{ disableUnderline: true }}
                variant={variant ?? 'outlined'}
                label={label ?? 'Select Site'}
                value={selectedSite}
                onChange={handleChange}
                size={size}
                InputLabelProps={{ shrink: true }}
            >
                {siteData.map((site) => (
                    <MenuItem value={site.id}>{site.name}</MenuItem>
                ))}
                {includeAllOption ? (
                    <MenuItem value="all">All Sites</MenuItem>
                ) : null}
            </TextField>
        );
    }
};

export default SiteSelector;
