import {
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import Paper from '../../../../global/Paper';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import { Dispatch, SetStateAction } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';

interface FinanceDetailsProps {
    vehicle: Vehicle;
    setVehicle: Dispatch<SetStateAction<Vehicle>>;
    readOnly: boolean;
}

const FinanceDetails = ({
    vehicle,
    setVehicle,
    readOnly
}: FinanceDetailsProps) => {
    return (
        <Paper>
            <Typography variant="h6">Finance</Typography>
            <Divider />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        disabled={readOnly}
                        label="PPSR Number"
                        InputLabelProps={{ shrink: true }}
                        value={vehicle?.ppsrNumber}
                        onChange={(e) =>
                            setVehicle({
                                ...vehicle,
                                ppsrNumber: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="small"
                        disabled={readOnly}
                        label="Financer"
                        InputLabelProps={{ shrink: true }}
                        value={vehicle?.financer}
                        onChange={(e) =>
                            setVehicle({ ...vehicle, financer: e.target.value })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            disabled={readOnly}
                            format="DD/MM/YYYY"
                            label="Payout Date"
                            value={dayjs(vehicle?.payoutDate)}
                            onChange={(newValue) => {
                                setVehicle({
                                    ...vehicle,
                                    payoutDate: dayjs(newValue).toISOString()
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        size="small"
                        type="number"
                        disabled={readOnly}
                        label="Payout Price"
                        InputLabelProps={{ shrink: true }}
                        value={vehicle?.payoutPrice}
                        onChange={(e) =>
                            setVehicle({
                                ...vehicle,
                                payoutPrice: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel>Payout Paid By</FormLabel>
                        <RadioGroup
                            row
                            value={vehicle?.payoutBy}
                            onChange={(e) =>
                                setVehicle({
                                    ...vehicle,
                                    payoutBy: e.target.value
                                })
                            }
                        >
                            <FormControlLabel
                                value="financer"
                                control={<Radio disabled={readOnly} />}
                                label="Financer"
                            />
                            <FormControlLabel
                                value="dealer"
                                control={<Radio disabled={readOnly} />}
                                label="Dealer"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default FinanceDetails;
