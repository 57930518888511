import DocumentationPage from '../DocumentationPage';
import { ManageVehicleTransfersSteps } from '../data/vehicleSales/transfers';

const VehicleTransfersSupportPage = () => {
    const contentSections = [
        {
            id: 'manage-vehicle-transfers',
            title: 'Manage Vehicle Transfers',
            href: '/support/vehicles/vehicletransfers/#manage-vehicle-transfers',
            description:
                'To create a new Vehicle Transfer, complete a transfer or export a transfer history, follow these steps:',
            steps: ManageVehicleTransfersSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Vehicle transfers"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default VehicleTransfersSupportPage;
