// IMPORTS
import { Order } from '../../../global/interfaces/PartsInterface';

// Check if the orderlines contain any parts on backorder and returns
// a boolean
const HasBackorder = (saleDetails: Order) => {
  let hasBackorder = false;
  saleDetails.orderLines.forEach((line) => {
    if (parseInt(line.backorderQuantity) > 0) hasBackorder = true;
  });

  return hasBackorder;
};

export default HasBackorder;
