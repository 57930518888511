import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';

const HandleDeleteManualTransaction = (
    id: number,
    manualTransaction,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    if (manualTransaction.reconciled) {
        showSnackbar(
            'This transaction has been reconciled and can not be deleted.',
            '',
            'error'
        );
        setButtonLoading(false);
        return;
    }

    api.delete(`deleteManualTransaction/${id}`).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Transaction Deleted.',
                'You will now be redirected to accounts.'
            );
            window.location.href = '/admin/accounts';
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default HandleDeleteManualTransaction;
