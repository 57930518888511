import {
    IconButton,
    Table,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import Paper from '../../../../../global/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '../../../../../global/Drawer';
import { useState } from 'react';
import CommitPNLDrawerContent from './CommitPNLDrawerContent';
import CommitPNL from '../logic/CommitPNL';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const CommitPNLUI = (props) => {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [drawerTitle, setDrawerTitle] = useState<string>('');
    const [drawerSubtitle, setDrawerSubtitle] = useState<string>('');
    const [drawerContent, setDrawerContent] = useState<any[]>([]);
    const [drawerColumn, setDrawerColumn] = useState<any[]>([]);

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const isAllReconciled = () => {
        let allReconciled = true;
        for (let key of Object.keys(props.commitPNLData)) {
            if (!props.commitPNLData[key][0]) {
                allReconciled = false;
            }
        }
        return allReconciled;
    };

    return (
        <>
            <Paper>
                <Typography variant={'h5'}>
                    Committing PNL for:{' '}
                    {props.commitPNLMonth.format('MMMM YYYY')}
                </Typography>
                <br />
                <Table size="small">
                    {/* PARTS AND ACCESSORIES */}
                    <TableRow>
                        <TableCell>
                            Parts and Accessories Direct Deposits
                        </TableCell>
                        <TableCell>
                            {props.commitPNLData.partsData[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.partsData[1].length.toString() +
                                  ' sale(s) with unreconciled direct deposits'}
                        </TableCell>
                        {!props.commitPNLData.partsData[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle(
                                            'Parts and Accessories Direct Deposits'
                                        );
                                        setDrawerSubtitle(
                                            'All P&A sales with at least one unreconciled direct deposit'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData.partsData[1]
                                        );
                                        setDrawerColumn([
                                            { id: 0, label: 'Order ID' },
                                            { id: 1, label: 'Order Status' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* VEHICLE SALES */}
                    <TableRow>
                        <TableCell>Vehicle Sales Direct Deposits</TableCell>
                        <TableCell>
                            {props.commitPNLData.vehicleSaleDD[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.vehicleSaleDD[1].length.toString() +
                                  ' sale(s) with unreconciled direct deposits'}
                        </TableCell>
                        {!props.commitPNLData.vehicleSaleDD[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle(
                                            'Vehicle Sales Direct Deposits'
                                        );
                                        setDrawerSubtitle(
                                            'All sales with at least one unreconciled direct deposit'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData.vehicleSaleDD[1]
                                        );
                                        setDrawerColumn([
                                            { id: 0, label: 'Vehicle Sale ID' },
                                            { id: 1, label: 'Sale Status' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell>Vehicle Sales Rebates</TableCell>
                        <TableCell>
                            {props.commitPNLData.vehicleSaleRebates[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.vehicleSaleRebates[1].length.toString() +
                                  ' sale(s) with unreconciled rebates'}
                        </TableCell>
                        {!props.commitPNLData.vehicleSaleRebates[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('Vehicle Sales Rebates');
                                        setDrawerSubtitle(
                                            'All unreconciled rebates'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .vehicleSaleRebates[1]
                                        );
                                        setDrawerColumn([
                                            {
                                                id: 0,
                                                label: 'Vehicle Rebate ID'
                                            },
                                            {
                                                id: 1,
                                                label: 'Number of Vehicle'
                                            }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell>Vehicle Sales Trust Payment</TableCell>
                        <TableCell>
                            {props.commitPNLData.vehicleSaleTrustPayment[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.vehicleSaleTrustPayment[1].length.toString() +
                                  ' sale(s) with unreconciled trust payments'}
                        </TableCell>
                        {!props.commitPNLData.vehicleSaleTrustPayment[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle(
                                            'Vehicle Sales Trust Payments'
                                        );
                                        setDrawerSubtitle(
                                            'All unreconciled trust payments'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .vehicleSaleTrustPayment[1]
                                        );
                                        setDrawerColumn([
                                            {
                                                id: 0,
                                                label: 'Vehicle Stock Number'
                                            },
                                            { id: 1, label: 'Vehicle Sale ID' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* SERVICES */}
                    <TableRow>
                        <TableCell>Service Direct Deposits</TableCell>
                        <TableCell>
                            {props.commitPNLData.serviceDD[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.serviceDD[1].length.toString() +
                                  ' service(s) with unreconciled direct deposits'}
                        </TableCell>
                        {!props.commitPNLData.serviceDD[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle(
                                            'Service Direct Deposits'
                                        );
                                        setDrawerSubtitle(
                                            'All services with at least one unreconciled direct deposit'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData.serviceDD[1]
                                        );
                                        setDrawerColumn([
                                            { id: 0, label: 'Service ID' },
                                            { id: 1, label: 'Service Type' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell>Service Warranties</TableCell>
                        <TableCell>
                            {props.commitPNLData.serviceWarranties[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.serviceWarranties[1].length.toString() +
                                  ' service(s) with unreconciled warranties'}
                        </TableCell>
                        {!props.commitPNLData.serviceWarranties[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('Service Warranties');
                                        setDrawerSubtitle(
                                            'All unreconciled service warranties.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .serviceWarranties[1]
                                        );
                                        setDrawerColumn([
                                            {
                                                id: 0,
                                                label: 'Service Warranty ID'
                                            },
                                            {
                                                id: 1,
                                                label: 'Number of Service'
                                            }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell>Service Sublets / Purchase Orders</TableCell>
                        <TableCell>
                            {props.commitPNLData.servicePurchaseOrder[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.servicePurchaseOrder[1].length.toString() +
                                  ' service(s) with unreconciled purchase orders / sublets.'}
                        </TableCell>
                        {!props.commitPNLData.servicePurchaseOrder[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle(
                                            'Service Sublets / Purchase Orders'
                                        );
                                        setDrawerSubtitle(
                                            'All unreconciled service purchase orders.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .servicePurchaseOrder[1]
                                        );
                                        setDrawerColumn([
                                            {
                                                id: 0,
                                                label: 'Purchase Order ID'
                                            },
                                            { id: 1, label: 'Service ID' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* CREDIT INVOICE */}
                    <TableRow>
                        <TableCell>Credit Invoices</TableCell>
                        <TableCell>
                            {props.commitPNLData.creditInvoices[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.creditInvoices[1].length.toString() +
                                  ' unreconciled Credit Invoice(s)'}
                        </TableCell>
                        {!props.commitPNLData.creditInvoices[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('Credit Invoices');
                                        setDrawerSubtitle(
                                            'All unreconciled credit invoices.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .creditInvoices[1]
                                        );
                                        setDrawerColumn([
                                            {
                                                id: 0,
                                                label: 'Credit Invoice ID'
                                            },
                                            { id: 1, label: 'Supplier' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* STOCK INVOICES */}
                    <TableRow>
                        <TableCell>Stock Invoices</TableCell>
                        <TableCell>
                            {props.commitPNLData.stockInvoices[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.stockInvoices[1].length.toString() +
                                  ' unreconciled Stock Invoice(s)'}
                        </TableCell>
                        {!props.commitPNLData.stockInvoices[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('Stock Invoices');
                                        setDrawerSubtitle(
                                            'All unreconciled stock invoices.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData.stockInvoices[1]
                                        );
                                        setDrawerColumn([
                                            {
                                                id: 0,
                                                label: 'Stock Invoice ID'
                                            },
                                            { id: 1, label: 'Supplier' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* GENERAL INVOICES */}
                    <TableRow>
                        <TableCell>General Invoices</TableCell>
                        <TableCell>
                            {props.commitPNLData.generalInvoices[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.generalInvoices[1].length.toString() +
                                  ' unreconciled General Invoice(s)'}
                        </TableCell>
                        {!props.commitPNLData.generalInvoices[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('General Invoices');
                                        setDrawerSubtitle(
                                            'All unreconciled general invoices.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .generalInvoices[1]
                                        );
                                        setDrawerColumn([
                                            { id: 0, label: 'Invoice ID' },
                                            { id: 1, label: 'Supplier' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* VEHICLE INVOICES */}
                    <TableRow>
                        <TableCell>Vehicle Invoices</TableCell>
                        <TableCell>
                            {props.commitPNLData.vehicleInvoices[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.vehicleInvoices[1].length.toString() +
                                  ' unreconciled Vehicle Invoice(s)'}
                        </TableCell>
                        {!props.commitPNLData.vehicleInvoices[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('Vehicle Invoices');
                                        setDrawerSubtitle(
                                            'All unreconciled vehicle invoices.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .vehicleInvoices[1]
                                        );
                                        setDrawerColumn([
                                            { id: 0, label: 'Invoice ID' },
                                            { id: 1, label: 'Supplier' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* REGISTRATION INVOICES */}
                    <TableRow>
                        <TableCell>Registration Invoices</TableCell>
                        <TableCell>
                            {props.commitPNLData.vehicleRegistrations[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.vehicleRegistrations[1].length.toString() +
                                  ' unreconciled Registration Invoice(s)'}
                        </TableCell>
                        {!props.commitPNLData.vehicleRegistrations[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle(
                                            'Vehicle Registration Invoices'
                                        );
                                        setDrawerSubtitle(
                                            'All unreconciled vehicle registration invoices.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .vehicleRegistrations[1]
                                        );
                                        setDrawerColumn([
                                            { id: 0, label: 'Invoice ID' },
                                            { id: 1, label: 'Supplier' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* MANUAL TRANSACTIONS */}
                    <TableRow>
                        <TableCell>Manual Transactions</TableCell>
                        <TableCell>
                            {props.commitPNLData.manualTransactions[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.manualTransactions[1].length.toString() +
                                  ' unreconciled Manual Transaction(s)'}
                        </TableCell>
                        {!props.commitPNLData.manualTransactions[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('Manual Transactions');
                                        setDrawerSubtitle(
                                            'All unreconciled manual transactions.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .manualTransactions[1]
                                        );
                                        setDrawerColumn([
                                            {
                                                id: 0,
                                                label: 'Manual Transaction ID'
                                            },
                                            {
                                                id: 1,
                                                label: 'Account / Supplier / Vehicle'
                                            }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* DEBTOR PAYMENTS */}
                    <TableRow>
                        <TableCell>Debtor Payments</TableCell>
                        <TableCell>
                            {props.commitPNLData.debtorPayments[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.debtorPayments[1].length.toString() +
                                  ' unreconciled Debtor Payment(s)'}
                        </TableCell>
                        {!props.commitPNLData.debtorPayments[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('Debtor Payments');
                                        setDrawerSubtitle(
                                            'All unreconciled debtor payments.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData
                                                .debtorPayments[1]
                                        );
                                        setDrawerColumn([
                                            { id: 0, label: 'Customer ID' },
                                            { id: 1, label: 'Payment Amount' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* STRIPE PAYMENTS */}
                    <TableRow>
                        <TableCell>Stripe Payments</TableCell>
                        <TableCell>
                            {props.commitPNLData.stripePayments[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.stripePayments[1].length.toString() +
                                  ' unreconciled Stripe Payment(s)'}
                        </TableCell>
                        {!props.commitPNLData.stripePayments[0] ? (
                            <TableCell>
                                <IconButton size="small">
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* TRADE INS */}
                    <TableRow>
                        <TableCell>Trade Ins</TableCell>
                        <TableCell>
                            {props.commitPNLData.tradeIns[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.tradeIns[1].length.toString() +
                                  ' unreconciled Trade In(s)'}
                        </TableCell>
                        {!props.commitPNLData.tradeIns[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('Trade Ins');
                                        setDrawerSubtitle(
                                            'All unreconciled trade ins.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData.tradeIns[1]
                                        );
                                        setDrawerColumn([
                                            { id: 0, label: 'Trade ID' },
                                            { id: 1, label: 'Customer ID' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>

                    {/* TILL RECONCILIATIONS */}
                    <TableRow>
                        <TableCell>Till Reconciliations</TableCell>
                        <TableCell>
                            {props.commitPNLData.tillRecs[0]
                                ? 'All Reconciled'
                                : props.commitPNLData.tillRecs[1].length.toString() +
                                  ' unreconciled Till Reconciliation(s)'}
                        </TableCell>
                        {!props.commitPNLData.tillRecs[0] ? (
                            <TableCell>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDrawerTitle('Till Reconciliations');
                                        setDrawerSubtitle(
                                            'All unreconciled till reconciliations.'
                                        );
                                        setDrawerContent(
                                            props.commitPNLData.tillRecs[1]
                                        );
                                        setDrawerColumn([
                                            { id: 0, label: 'Site and Till' },
                                            { id: 1, label: 'Date' }
                                        ]);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <MenuIcon fontSize="small" />
                                </IconButton>
                            </TableCell>
                        ) : (
                            <TableCell></TableCell>
                        )}
                    </TableRow>
                </Table>
            </Paper>

            <br />

            <LoadingButton
                loading={buttonLoading}
                variant="contained"
                disabled={!isAllReconciled()}
                sx={{ marginBottom: 2 }}
                onClick={() => {
                    CommitPNL(
                        props.commitPNLMonth,
                        setButtonLoading,
                        props.showSnackbar
                    );
                }}
            >
                Commit PNL
            </LoadingButton>

            <br />

            <Drawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                title={drawerTitle}
                subTitle={drawerSubtitle}
                width={'40vw'}
            >
                <CommitPNLDrawerContent
                    drawerTitle={drawerTitle}
                    drawerContent={drawerContent}
                    columns={drawerColumn}
                />
            </Drawer>
        </>
    );
};

export default CommitPNLUI;
