import {
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';

import { Controller } from 'react-hook-form';

const CoolingOffForm = ({ control, watch }) => {
    return (
        <Grid container spacing={2} margin={'10px'}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom component="div">
                    Cooling-Off Period - Section 1
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    This section must be completed before contract is signed.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    A cooling-off period applied to this vehicle?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="coolingOffOption"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="hasCoolingOffPeriod"
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="noCoolingOffPeriod"
                                control={<Radio />}
                                label="No"
                            />
                        </RadioGroup>
                    )}
                />
            </Grid>
            {watch('coolingOffOption') === 'hasCoolingOffPeriod' && (
                <>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Cooling-Off Period Start Time
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="coolingOffPeriodStart"
                                control={control}
                                render={({ field }) => (
                                    <MobileDateTimePicker
                                        format="DD/MM/YYYY hh:mm a"
                                        label="Date and Time"
                                        value={dayjs(field.value)}
                                        onChange={field.onChange}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: false,
                                                InputProps: {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <AccessTimeIcon />
                                                        </InputAdornment>
                                                    )
                                                }
                                            }
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Ends at 5:00pm on
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="coolingOffPeriodEnd"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        label="Date"
                                        value={dayjs(field.value)}
                                        onChange={field.onChange}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: false
                                            }
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
            </Grid>
        </Grid>
    );
};

export default CoolingOffForm;
