// IMPROTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const UpdateUserPermissions = (
    UserId: number,
    userPermissions,
    showSnackbar: showSnackbar,
    setLoading: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    api.put(`updateUserPermissions/${UserId}`, userPermissions).then((res) => {
        if (res.status === 200) {
            showSnackbar('Permissions Updated.', 'The page will now refresh.');
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setLoading(false);
        }
    });
};

export default UpdateUserPermissions;
