// IMPORTS
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
    IconButton,
    Dialog,
    Paper,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Edit, Print, Done } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import InvoiceDialogContent from '../../../admin/debtorReconciliation/components/InvoiceDialogContent';
// LOGIC
import GetDebtorPayments from '../logic/GetDebtorPayments';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import HandleUpdateCustomer from '../logic/HandleUpdateCustomer';
// INTERFACES
import { Customer } from '../../../global/interfaces/GeneralInterface';
import { DebtorPayment } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

/**
 * DebtorProps
 * Parameters sent through from ViewCustomer.tsx
 */
interface DebtorProps {
    customerInfo: Customer; // The customer's data
    setCustomerInfo: Dispatch<SetStateAction<Customer>>; // State setter for customerInfo
    showSnackbar: showSnackbar; // Snackbar for the debtor tab
}

/**
 * DebtorTab
 * All content within ViewCustomer's 'Debtor' tab
 * @author Estienne
 * @param DebtorProps
 * @returns The customer's debtor information and payments
 */
const DebtorTab = ({
    customerInfo,
    setCustomerInfo,
    showSnackbar
}: DebtorProps) => {
    const { id } = useParams<{ id: string }>();
    const [debtorOwing, setDebtorOwing] = useState(0);
    const [nextDueDate, setNextDueDate] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    // Account Payments table & pagination variables
    const [payments, setPayments] = useState<any>();
    const [paymentsPage, setPaymentsPage] = useState<DebtorPayment[]>();
    const [paymentsPageNum, setPaymentsPageNum] = useState(0);
    const [numPayments, setNumPayments] = useState(0);
    const numRows = 10;

    // Account Terms column names
    const accountTermsColumns = [
        { id: 0, label: 'Account Limit' },
        { id: 1, label: 'Payment Terms' },
        { id: 2, label: 'Amount Owing' },
        { id: 3, label: 'Next Payment Due' },
        { id: 4, label: '' }
    ];

    // Account Payments column names
    const accountPaymentsColumns = [
        { id: 0, label: 'Payment ID' },
        { id: 1, label: 'Amount' },
        { id: 2, label: 'Invoice Number' },
        { id: 3, label: 'Invoice Reference' },
        { id: 4, label: 'Submitted By' },
        { id: 5, label: 'Date Submitted' }
    ];

    useEffect(() => {
        GetDebtorPayments(
            id,
            numRows,
            setDebtorOwing,
            setNextDueDate,
            setCustomerInfo,
            customerInfo,
            setPayments,
            setPaymentsPage,
            setNumPayments
        );
        setLoading(false); // Finished loading
        // eslint-disable-next-line
    }, [id]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPaymentsPageNum(newPage);
        setPaymentsPage(payments[newPage]);
    };

    // Play along with the SelectedDebtor interface
    // (for the time being)
    const selectedDebtor = {
        CustomerId: customerInfo.id,
        CustomerDetails: {
            name: `${customerInfo.firstName} ${customerInfo.lastName}`,
            firstName: customerInfo.firstName,
            lastName: customerInfo.lastName,
            companyName: customerInfo.companyName,
            phoneNumber: customerInfo.phoneNumber,
            abn: customerInfo.abn,
            paymentTerms: customerInfo.paymentTerms,
            accountLimit: `${customerInfo.accountLimit}`,
            addressLine1: customerInfo.addressLine1,
            suburb: customerInfo.suburb,
            state: customerInfo.state,
            postcode: customerInfo.postcode,
            email: customerInfo.email
        },
        rows: customerInfo.debtorPayments
    };

    return (
        <>
            <Paper className="paper-padding">
                <Typography variant="h5" align="left">
                    Account Terms
                </Typography>
                <DataTable columns={accountTermsColumns}>
                    <TableRow>
                        <DataCell>
                            {disabled ? (
                                CurrencyFormatter(customerInfo.accountLimit)
                            ) : (
                                <TextField
                                    type="number"
                                    size="small"
                                    variant="standard"
                                    value={customerInfo?.accountLimit}
                                    onChange={(e) =>
                                        setCustomerInfo({
                                            ...customerInfo,
                                            accountLimit: parseInt(
                                                e.target.value
                                            )
                                        })
                                    }
                                />
                            )}
                        </DataCell>
                        <DataCell>
                            {disabled ? (
                                customerInfo?.paymentTerms
                            ) : (
                                <TextField
                                    type="number"
                                    size="small"
                                    variant="standard"
                                    value={customerInfo?.paymentTerms}
                                    onChange={(e) =>
                                        setCustomerInfo({
                                            ...customerInfo,
                                            paymentTerms: e.target.value
                                        })
                                    }
                                />
                            )}{' '}
                            {customerInfo?.paymentTerms !== '1'
                                ? 'days'
                                : 'day'}
                        </DataCell>
                        <DataCell>{CurrencyFormatter(debtorOwing)}</DataCell>
                        <DataCell>
                            {nextDueDate ? DateFormatter(nextDueDate) : 'N/A'}
                        </DataCell>
                        <DataCell>
                            {disabled ? (
                                <>
                                    <Tooltip
                                        title="Edit Account Terms"
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={() => setDisabled(false)}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title="Print Account Statement"
                                        placement="bottom"
                                    >
                                        <IconButton
                                            onClick={() =>
                                                setInvoiceDialogOpen(true)
                                            }
                                        >
                                            <Print />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            ) : (
                                <Tooltip
                                    title="Finish Editing Account Terms"
                                    placement="top"
                                >
                                    <IconButton
                                        onClick={() =>
                                            HandleUpdateCustomer({
                                                customerInfo: customerInfo,
                                                showSnackbar: showSnackbar,
                                                refresh: true
                                            })
                                        }
                                    >
                                        <Done />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </DataCell>
                    </TableRow>
                </DataTable>
            </Paper>
            <br />
            <Paper className="paper-padding">
                <Typography variant="h5" align="left">
                    Account Payments
                </Typography>
                <DataTable columns={accountPaymentsColumns}>
                    {!loading
                        ? paymentsPage?.map((row) => (
                              <TableRow key={row.id}>
                                  <DataCell>{row.id}</DataCell>
                                  <DataCell>
                                      {CurrencyFormatter(row.amountDue)}
                                  </DataCell>
                                  {row.StockInvoiceId ? (
                                      <DataCellColoured
                                          handleClick={() =>
                                              window.open(
                                                  '/inventory/stockinvoiced/view/' +
                                                      row.StockInvoiceId,
                                                  '_blank'
                                              )
                                          }
                                      >
                                          {row.StockInvoiceId}
                                      </DataCellColoured>
                                  ) : (
                                      <DataCellColoured
                                          handleClick={() =>
                                              window.open(
                                                  '/inventory/viewSale/' +
                                                      row.OrderId,
                                                  '_blank'
                                              )
                                          }
                                      >
                                          {row.OrderId}
                                      </DataCellColoured>
                                  )}
                                  <DataCell>
                                      {row.StockInvoiceId
                                          ? row.StockInvoice?.documentReference
                                          : row.OrderId
                                            ? row.amountDue < 0
                                                ? 'P&A Credit'
                                                : 'P&A Sale'
                                            : row.amountDue < 0
                                              ? 'Account Payment'
                                              : 'Misc'}
                                  </DataCell>
                                  <DataCell>
                                      {row.User.firstName} {row.User.lastName}
                                  </DataCell>
                                  <DataCell>
                                      {DateFormatter(row.createdAt)}
                                  </DataCell>
                              </TableRow>
                          ))
                        : null}
                    <TablePagination
                        count={numPayments}
                        onPageChange={handleChangePage}
                        page={paymentsPageNum}
                        rowsPerPage={numRows}
                        rowsPerPageOptions={[]}
                    />
                </DataTable>
            </Paper>

            {/* Generate account statement / invoice dialog */}
            <Dialog
                open={invoiceDialogOpen}
                onClose={() => setInvoiceDialogOpen(false)}
                fullWidth
                maxWidth={'md'}
            >
                <InvoiceDialogContent
                    selectedDebtor={selectedDebtor}
                    setInvoiceDialogOpen={setInvoiceDialogOpen}
                />
            </Dialog>
        </>
    );
};

export default DebtorTab;
