// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Divider,
    MenuItem,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
// LOGIC
import CheckCodeAvailable from '../logic/CheckCodeAvailable';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface DetailsProps {
    creditorDetails: Creditor;
    setCreditorDetails: Dispatch<SetStateAction<Creditor>>;
    readOnly: boolean;
    setResponseCode: Dispatch<SetStateAction<number>>;
    showSnackbar: showSnackbar;
}

const Details = ({
    creditorDetails,
    setCreditorDetails,
    readOnly,
    setResponseCode,
    showSnackbar
}: DetailsProps) => {
    const typeOptions = [
        { value: 'standard', label: 'Standard' },
        { value: 'internal', label: 'Internal' },
        { value: 'finance', label: 'Finance' }
    ];

    return (
        <Paper>
            <Typography variant="h6">Basic Details</Typography>
            <Divider />
            <Table>
                <TableRow>
                    <TableCell width="100">Creditor Type</TableCell>
                    <TableCell width="200">
                        {readOnly ? (
                            <TextField
                                disabled
                                fullWidth
                                select
                                size="small"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    style: { textAlign: 'center' }
                                }}
                                InputLabelProps={{ shrink: true }}
                                value={creditorDetails?.type}
                                onChange={(e) =>
                                    setCreditorDetails({
                                        ...creditorDetails,
                                        type: e.target.value
                                    })
                                }
                            >
                                {typeOptions.map((option) => (
                                    <MenuItem value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        ) : (
                            <TextField
                                fullWidth
                                select
                                size="small"
                                variant="outlined"
                                InputProps={{ style: { textAlign: 'center' } }}
                                InputLabelProps={{ shrink: true }}
                                value={creditorDetails?.type}
                                onChange={(e) =>
                                    setCreditorDetails({
                                        ...creditorDetails,
                                        type: e.target.value
                                    })
                                }
                            >
                                {typeOptions.map((option) => (
                                    <MenuItem value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <TextField
                                disabled
                                fullWidth
                                variant="standard"
                                size="small"
                                InputProps={{ disableUnderline: true }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                                value={creditorDetails?.name}
                                onChange={(e) =>
                                    setCreditorDetails({
                                        ...creditorDetails,
                                        name: e.target.value
                                    })
                                }
                            />
                        ) : (
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                                value={creditorDetails?.name}
                                onChange={(e) =>
                                    setCreditorDetails({
                                        ...creditorDetails,
                                        name: e.target.value
                                    })
                                }
                            />
                        )}
                    </TableCell>
                </TableRow>
                {!creditorDetails?.id ? (
                    <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>
                            {readOnly ? (
                                <TextField
                                    disabled
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    InputProps={{ disableUnderline: true }}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'center' }
                                    }}
                                    value={creditorDetails?.code}
                                    onChange={(e) =>
                                        setCreditorDetails({
                                            ...creditorDetails,
                                            code: e.target.value
                                        })
                                    }
                                    onBlur={(e) =>
                                        CheckCodeAvailable(
                                            e.target.value,
                                            setResponseCode,
                                            showSnackbar
                                        )
                                    }
                                />
                            ) : (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: 'center' }
                                    }}
                                    value={creditorDetails?.code}
                                    onChange={(e) =>
                                        setCreditorDetails({
                                            ...creditorDetails,
                                            code: e.target.value
                                        })
                                    }
                                    onBlur={(e) =>
                                        CheckCodeAvailable(
                                            e.target.value,
                                            setResponseCode,
                                            showSnackbar
                                        )
                                    }
                                />
                            )}
                        </TableCell>
                    </TableRow>
                ) : null}
                <TableRow>
                    <TableCell>ABN</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <TextField
                                disabled
                                fullWidth
                                variant="standard"
                                size="small"
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                                value={creditorDetails?.abn}
                                onChange={(e) =>
                                    setCreditorDetails({
                                        ...creditorDetails,
                                        abn: e.target.value
                                    })
                                }
                            />
                        ) : (
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                                value={creditorDetails?.abn}
                                onChange={(e) =>
                                    setCreditorDetails({
                                        ...creditorDetails,
                                        abn: e.target.value
                                    })
                                }
                            />
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Representative</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <TextField
                                disabled
                                fullWidth
                                variant="standard"
                                size="small"
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                                value={creditorDetails?.representative}
                                onChange={(e) =>
                                    setCreditorDetails({
                                        ...creditorDetails,
                                        representative: e.target.value
                                    })
                                }
                            />
                        ) : (
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                                value={creditorDetails?.representative}
                                onChange={(e) =>
                                    setCreditorDetails({
                                        ...creditorDetails,
                                        representative: e.target.value
                                    })
                                }
                            />
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Notes</TableCell>
                    <TableCell>
                        {readOnly ? (
                            <TextField
                                disabled
                                fullWidth
                                variant={'standard'}
                                size="small"
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                                value={creditorDetails?.notes}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                                value={creditorDetails?.notes}
                                onChange={(e) =>
                                    setCreditorDetails({
                                        ...creditorDetails,
                                        notes: e.target.value
                                    })
                                }
                            />
                        )}
                    </TableCell>
                </TableRow>
            </Table>
        </Paper>
    );
};

export default Details;
