const SetupPreviewData = (importData, setPreviewData, setSelectedHeaders) => {
    if (importData.length > 0) {
        var headerColumns = new Array(Object.keys(importData[0]).length);

        Object.keys(importData[0]).forEach(row => {
            headerColumns[row] = ''
        })

        var newData = {
            first10: importData.slice(0, 10),
            last10: importData.slice(importData.length - 10, importData.length),
        };

        setPreviewData(newData);
        setSelectedHeaders(headerColumns)
    }
}

export default SetupPreviewData