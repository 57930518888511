import { Dispatch, SetStateAction, useState } from 'react';
import {
    Grid,
    Tooltip,
    Typography,
    IconButton,
    TableRow,
    Fab,
    Tabs,
    Tab,
    Divider,
    Paper,
    TableContainer
} from '@mui/material';
import {
    Close,
    ExpandMore,
    ExpandLess,
    InfoOutlined,
    PlaylistRemove,
    PlaylistAddCheck
} from '@mui/icons-material';
import moment from 'moment';

// COMPONENTS
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import Drawer from '../../../../global/Drawer';

// LOGIC
import RenderDrawerContent from '../../logic/Reconciliator/RenderDrawerContent';
import HandleMoveAllTransactionV2 from '../../logic/Reconciliator/HandleMoveAllTransactionsV2';
import HandleMoveTransactionV2 from '../../logic/Reconciliator/HandleMoveTransactionV2';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';

interface TablesProps {
    reconciliationDetails;
    setReconciliationDetails;
    selectedOraUnrecTab: string;
    selectedBankUnrecTab: string;
    selectedOraRecTab: string;
    selectedBankRecTab: string;
    setSelectedOraUnrecTab: Dispatch<SetStateAction<string>>;
    setSelectedBankUnrecTab: Dispatch<SetStateAction<string>>;
    setSelectedOraRecTab: Dispatch<SetStateAction<string>>;
    setSelectedBankRecTab: Dispatch<SetStateAction<string>>;
    refreshLoading: boolean;
    readOnly: boolean;
    outerWidth: number;
}
const TablesV2 = ({
    reconciliationDetails,
    setReconciliationDetails,
    selectedOraUnrecTab,
    selectedBankUnrecTab,
    selectedOraRecTab,
    selectedBankRecTab,
    setSelectedOraUnrecTab,
    setSelectedBankUnrecTab,
    setSelectedOraRecTab,
    setSelectedBankRecTab,
    refreshLoading,
    readOnly,
    outerWidth
}: TablesProps) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<string>('');
    const [selectedObject, setSelectedObject] = useState(null);

    const oraColumns = [
        { id: 0, label: '', width: 20 },
        { id: 1, label: 'Date', width: 80 },
        { id: 3, label: 'Type', width: 300 },
        { id: 4, label: 'Reference', width: 300 },
        { id: 5, label: 'Amount', width: 100 },
        { id: 6, label: '', width: 20 }
    ];

    const bankColumns = [
        { id: 0, label: '', width: 20 },
        { id: 1, label: 'Date', width: 100 },
        { id: 2, label: 'Reference', width: 600 },
        { id: 3, label: 'Amount', width: 100 },
        { id: 4, label: '', width: 20 }
    ];

    const handleOraTransactionClick = (line) => {
        if (line.TillReconciliationId) {
            setSelectedType('till');
        } else if (line.ManualTransactionId) {
            setSelectedType('manual');
        } else if (line.TrustReceiptId) {
            setSelectedType('trust');
        } else if (line.TradeId) {
            setSelectedType('trade');
        } else if (line.VehicleSaleId) {
            setSelectedType('vehicle');
        } else if (line.ServiceId) {
            setSelectedType('service');
        } else if (line.OrderId) {
            setSelectedType('parts');
        } else if (line.PaymentRunId) {
            setSelectedType('payrun');
        } else if (line.BankTransactionId) {
            setSelectedType('bank');
        } else if (line.ServiceWarrantyId) {
            setSelectedType('warranty');
        } else if (line.VehicleRebateId) {
            setSelectedType('rebate');
        }

        setSelectedObject(line);
        setDrawerOpen(true);
    };

    const GetLocation = (id) => {
        let element = document.getElementById(id);
        const rect = element.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    };

    const ScrollToElement = (id) => {
        let coords = GetLocation(id);
        window.scrollTo({ top: coords.top, behavior: 'smooth' });
    };

    return (
        <>
            <span id="reconciled" />
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Typography variant="h6">Reconciled Ora</Typography>
                </Grid>
                <Grid item xs={1} textAlign="right">
                    <Tooltip
                        title="Move all Ora transactions down"
                        placement="left"
                    >
                        <IconButton
                            disabled={refreshLoading || readOnly}
                            onClick={() =>
                                HandleMoveAllTransactionV2(
                                    'ora',
                                    reconciliationDetails,
                                    setReconciliationDetails
                                )
                            }
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="h6">Reconciled Bank</Typography>
                </Grid>
                <Grid item xs={1} textAlign="right">
                    <Tooltip
                        title="Move all Bank transactions down"
                        placement="left"
                    >
                        <IconButton
                            disabled={refreshLoading || readOnly}
                            onClick={() =>
                                HandleMoveAllTransactionV2(
                                    'bank',
                                    reconciliationDetails,
                                    setReconciliationDetails
                                )
                            }
                        >
                            <Close />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={outerWidth < 1750 ? 1 : 0.8}>
                    <Tabs
                        sx={{ maxHeight: '40vw' }}
                        value={selectedOraRecTab}
                        onChange={(e, newValue) =>
                            setSelectedOraRecTab(newValue)
                        }
                        variant="scrollable"
                        scrollButtons="auto"
                        orientation="vertical"
                    >
                        {reconciliationDetails?.oraTransactionsReconciled.map(
                            (week) => (
                                <Tab
                                    sx={{
                                        fontWeight:
                                            selectedOraRecTab === week.week
                                                ? 'bold'
                                                : '',
                                        fontSize: outerWidth < 1750 ? 12 : 14
                                    }}
                                    value={week?.week}
                                    label={
                                        moment(week?.week, 'ww-YYYY').isValid()
                                            ? `${moment(week?.week, 'ww-YYYY').endOf('week').format('DD/MM/YYYY')} - ${moment(week?.week, 'ww-YYYY').startOf('week').format('DD/MM/YYYY')}`
                                            : 'Invalid Date'
                                    }
                                />
                            )
                        )}
                    </Tabs>
                </Grid>
                <Grid item xs={outerWidth < 1750 ? 5 : 5.2}>
                    <Paper>
                        {selectedOraRecTab.length > 0 &&
                        reconciliationDetails.oraTransactionsReconciled.length >
                            0 ? (
                            <TableContainer style={{ maxHeight: '40vw' }}>
                                <DataTable columns={oraColumns}>
                                    {reconciliationDetails.oraTransactionsReconciled[
                                        reconciliationDetails.oraTransactionsReconciled.findIndex(
                                            (x) => x.week === selectedOraRecTab
                                        )
                                    ]?.lines?.map((line, index) => (
                                        <TableRow>
                                            <DataCell
                                                align="left"
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '50px'
                                                        : ''
                                                }
                                            >
                                                <IconButton
                                                    disabled={
                                                        refreshLoading ||
                                                        readOnly
                                                    }
                                                    size={
                                                        outerWidth < 1750
                                                            ? 'small'
                                                            : 'medium'
                                                    }
                                                    onClick={() =>
                                                        HandleMoveTransactionV2(
                                                            'down',
                                                            'ora',
                                                            selectedOraRecTab,
                                                            index,
                                                            reconciliationDetails,
                                                            setReconciliationDetails,
                                                            selectedOraRecTab,
                                                            setSelectedOraRecTab
                                                        )
                                                    }
                                                >
                                                    <ExpandMore
                                                        style={{ fontSize: 18 }}
                                                    />
                                                </IconButton>
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '70px'
                                                        : ''
                                                }
                                            >
                                                <Typography
                                                    variant="inherit"
                                                    sx={{
                                                        fontSize:
                                                            outerWidth < 1750
                                                                ? 12
                                                                : 14
                                                    }}
                                                >
                                                    {line.date}
                                                </Typography>
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '110px'
                                                        : ''
                                                }
                                            >
                                                {line.siteName ? (
                                                    <Typography
                                                        variant="inherit"
                                                        sx={{
                                                            fontSize:
                                                                outerWidth <
                                                                1750
                                                                    ? 12
                                                                    : 14
                                                        }}
                                                    >
                                                        {line.siteName}
                                                        <br />
                                                        {line.type}
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        variant="inherit"
                                                        sx={{
                                                            fontSize:
                                                                outerWidth <
                                                                1750
                                                                    ? 12
                                                                    : 14
                                                        }}
                                                    >
                                                        {line.type}
                                                    </Typography>
                                                )}
                                            </DataCell>
                                            <DataCell maxWidth="75px">
                                                {line.reference.length > 20 ? (
                                                    <Tooltip
                                                        title={line.reference}
                                                        placement="top"
                                                    >
                                                        <Typography
                                                            variant="inherit"
                                                            sx={{
                                                                fontSize:
                                                                    outerWidth <
                                                                    1750
                                                                        ? 12
                                                                        : 14
                                                            }}
                                                        >
                                                            {line.reference.slice(
                                                                0,
                                                                20
                                                            )}
                                                            ...
                                                        </Typography>
                                                    </Tooltip>
                                                ) : (
                                                    <Typography
                                                        variant="inherit"
                                                        sx={{
                                                            fontSize:
                                                                outerWidth <
                                                                1750
                                                                    ? 12
                                                                    : 14
                                                        }}
                                                    >
                                                        {line.reference}
                                                    </Typography>
                                                )}
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '70px'
                                                        : ''
                                                }
                                            >
                                                <Typography
                                                    variant="inherit"
                                                    sx={{
                                                        fontSize:
                                                            outerWidth < 1750
                                                                ? 12
                                                                : 14
                                                    }}
                                                >
                                                    {CurrencyFormatter(
                                                        line.amount
                                                    )}
                                                </Typography>
                                            </DataCell>
                                            <DataCell
                                                align="right"
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '50px'
                                                        : ''
                                                }
                                            >
                                                {line.DebtorPaymentId ||
                                                line.StripePaymentId ? null : (
                                                    <IconButton
                                                        disabled={
                                                            refreshLoading
                                                        }
                                                        onClick={() =>
                                                            handleOraTransactionClick(
                                                                line
                                                            )
                                                        }
                                                    >
                                                        <InfoOutlined
                                                            style={{
                                                                fontSize:
                                                                    outerWidth <
                                                                    1750
                                                                        ? 14
                                                                        : 18
                                                            }}
                                                        />
                                                    </IconButton>
                                                )}
                                            </DataCell>
                                        </TableRow>
                                    ))}
                                </DataTable>
                            </TableContainer>
                        ) : (
                            <DataTable columns={oraColumns}>
                                <></>
                            </DataTable>
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={outerWidth < 1750 ? 1 : 0.8}>
                    <Tabs
                        sx={{ maxHeight: '40vw' }}
                        value={selectedBankRecTab}
                        onChange={(e, newValue) =>
                            setSelectedBankRecTab(newValue)
                        }
                        variant="scrollable"
                        scrollButtons="auto"
                        orientation="vertical"
                    >
                        {reconciliationDetails?.bankTransactionsReconciled.map(
                            (week) => (
                                <Tab
                                    sx={{
                                        fontWeight:
                                            selectedBankRecTab === week.week
                                                ? 'bold'
                                                : '',
                                        fontSize: outerWidth < 1750 ? 12 : 14
                                    }}
                                    value={week?.week}
                                    label={
                                        moment(week?.week, 'ww-YYYY').isValid()
                                            ? `${moment(week?.week, 'ww-YYYY').endOf('week').format('DD/MM/YYYY')} - ${moment(week?.week, 'ww-YYYY').startOf('week').format('DD/MM/YYYY')}`
                                            : 'Invalid Date'
                                    }
                                />
                            )
                        )}
                    </Tabs>
                </Grid>
                <Grid item xs={outerWidth < 1750 ? 5 : 5.2}>
                    <Paper>
                        {selectedBankRecTab.length > 0 &&
                        reconciliationDetails.bankTransactionsReconciled
                            .length > 0 ? (
                            <TableContainer style={{ maxHeight: '40vw' }}>
                                <DataTable columns={bankColumns}>
                                    {reconciliationDetails.bankTransactionsReconciled[
                                        reconciliationDetails.bankTransactionsReconciled.findIndex(
                                            (x) => x.week === selectedBankRecTab
                                        )
                                    ]?.lines?.map((line, index) => (
                                        <TableRow>
                                            <DataCell
                                                align="left"
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '50px'
                                                        : ''
                                                }
                                            >
                                                <IconButton
                                                    disabled={
                                                        refreshLoading ||
                                                        readOnly
                                                    }
                                                    size={
                                                        outerWidth < 1750
                                                            ? 'small'
                                                            : 'medium'
                                                    }
                                                    onClick={() =>
                                                        HandleMoveTransactionV2(
                                                            'down',
                                                            'bank',
                                                            selectedBankRecTab,
                                                            index,
                                                            reconciliationDetails,
                                                            setReconciliationDetails,
                                                            selectedBankRecTab,
                                                            setSelectedBankRecTab
                                                        )
                                                    }
                                                >
                                                    <ExpandMore
                                                        style={{ fontSize: 18 }}
                                                    />
                                                </IconButton>
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '70px'
                                                        : ''
                                                }
                                            >
                                                <Typography
                                                    variant="inherit"
                                                    sx={{
                                                        fontSize:
                                                            outerWidth < 1750
                                                                ? 12
                                                                : 14
                                                    }}
                                                >
                                                    {line.date}
                                                </Typography>
                                            </DataCell>
                                            <DataCell maxWidth="75px">
                                                {line.reference.length > 40 ? (
                                                    <Tooltip
                                                        title={line.reference}
                                                        placement="top"
                                                    >
                                                        <Typography
                                                            variant="inherit"
                                                            sx={{
                                                                fontSize:
                                                                    outerWidth <
                                                                    1750
                                                                        ? 12
                                                                        : 14
                                                            }}
                                                        >
                                                            {line.reference.slice(
                                                                0,
                                                                40
                                                            )}
                                                            ...
                                                        </Typography>
                                                    </Tooltip>
                                                ) : (
                                                    <Typography
                                                        variant="inherit"
                                                        sx={{
                                                            fontSize:
                                                                outerWidth <
                                                                1750
                                                                    ? 12
                                                                    : 14
                                                        }}
                                                    >
                                                        {line.reference}
                                                    </Typography>
                                                )}
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '70px'
                                                        : ''
                                                }
                                            >
                                                <Typography
                                                    variant="inherit"
                                                    sx={{
                                                        fontSize:
                                                            outerWidth < 1750
                                                                ? 12
                                                                : 14
                                                    }}
                                                >
                                                    {CurrencyFormatter(
                                                        line.amount
                                                    )}
                                                </Typography>
                                            </DataCell>
                                            <DataCell
                                                align="right"
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '50px'
                                                        : ''
                                                }
                                            >
                                                {line.DebtorPaymentId ||
                                                line.StripePaymentId ? null : (
                                                    <IconButton
                                                        disabled={
                                                            refreshLoading
                                                        }
                                                        onClick={() =>
                                                            handleOraTransactionClick(
                                                                line
                                                            )
                                                        }
                                                    >
                                                        <InfoOutlined
                                                            style={{
                                                                fontSize:
                                                                    outerWidth <
                                                                    1750
                                                                        ? 14
                                                                        : 18
                                                            }}
                                                        />
                                                    </IconButton>
                                                )}
                                            </DataCell>
                                        </TableRow>
                                    ))}
                                </DataTable>
                            </TableContainer>
                        ) : (
                            <DataTable columns={bankColumns}>
                                <></>
                            </DataTable>
                        )}
                    </Paper>
                </Grid>
                <span id="unreconciled" />
                <Grid item xs={12} textAlign={'center'}>
                    <Divider />
                </Grid>
                <Grid item xs={6} textAlign={'center'}>
                    <Typography variant="h5">Unreconciled Ora</Typography>
                </Grid>
                <Grid item xs={6} textAlign={'center'}>
                    <Typography variant="h5">Unreconciled Bank</Typography>
                </Grid>
                <Grid item xs={outerWidth < 1750 ? 1 : 0.8}>
                    <Tabs
                        sx={{ maxHeight: '40vw' }}
                        value={selectedOraUnrecTab}
                        onChange={(e, newValue) =>
                            setSelectedOraUnrecTab(newValue)
                        }
                        variant="scrollable"
                        scrollButtons="auto"
                        orientation="vertical"
                    >
                        {reconciliationDetails?.oraTransactionsUnreconciled.map(
                            (week) => (
                                <Tab
                                    sx={{
                                        fontWeight:
                                            selectedOraUnrecTab === week.week
                                                ? 'bold'
                                                : '',
                                        fontSize: outerWidth < 1750 ? 12 : 14
                                    }}
                                    value={week?.week}
                                    label={
                                        moment(week?.week, 'ww-YYYY').isValid()
                                            ? `${moment(week?.week, 'ww-YYYY').endOf('week').format('DD/MM/YYYY')} - ${moment(week?.week, 'ww-YYYY').startOf('week').format('DD/MM/YYYY')}`
                                            : 'Invalid Date'
                                    }
                                />
                            )
                        )}
                    </Tabs>
                </Grid>
                <Grid item xs={outerWidth < 1750 ? 5 : 5.2}>
                    <Paper>
                        {selectedOraUnrecTab.length > 0 &&
                        reconciliationDetails.oraTransactionsUnreconciled
                            .length > 0 ? (
                            <TableContainer style={{ maxHeight: '40vw' }}>
                                <DataTable columns={oraColumns}>
                                    {reconciliationDetails.oraTransactionsUnreconciled[
                                        reconciliationDetails.oraTransactionsUnreconciled.findIndex(
                                            (x) =>
                                                x.week === selectedOraUnrecTab
                                        )
                                    ]?.lines?.map((line, index) => (
                                        <TableRow>
                                            <DataCell
                                                align="left"
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '50px'
                                                        : ''
                                                }
                                            >
                                                <IconButton
                                                    disabled={
                                                        refreshLoading ||
                                                        readOnly
                                                    }
                                                    size={
                                                        outerWidth < 1750
                                                            ? 'small'
                                                            : 'medium'
                                                    }
                                                    onClick={() =>
                                                        HandleMoveTransactionV2(
                                                            'up',
                                                            'ora',
                                                            selectedOraUnrecTab,
                                                            index,
                                                            reconciliationDetails,
                                                            setReconciliationDetails,
                                                            selectedOraUnrecTab,
                                                            setSelectedOraUnrecTab
                                                        )
                                                    }
                                                >
                                                    <ExpandLess
                                                        style={{ fontSize: 18 }}
                                                    />
                                                </IconButton>
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '70px'
                                                        : ''
                                                }
                                            >
                                                <Typography
                                                    variant="inherit"
                                                    sx={{
                                                        fontSize:
                                                            outerWidth < 1750
                                                                ? 12
                                                                : 14
                                                    }}
                                                >
                                                    {line.date}
                                                </Typography>
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '110px'
                                                        : ''
                                                }
                                            >
                                                {line.siteName ? (
                                                    <Typography
                                                        variant="inherit"
                                                        sx={{
                                                            fontSize:
                                                                outerWidth <
                                                                1750
                                                                    ? 12
                                                                    : 14
                                                        }}
                                                    >
                                                        {line.siteName}
                                                        <br />
                                                        {line.type}
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        variant="inherit"
                                                        sx={{
                                                            fontSize:
                                                                outerWidth <
                                                                1750
                                                                    ? 12
                                                                    : 14
                                                        }}
                                                    >
                                                        {line.type}
                                                    </Typography>
                                                )}
                                            </DataCell>
                                            <DataCell maxWidth="75px">
                                                {line.reference.length > 20 ? (
                                                    <Tooltip
                                                        title={line.reference}
                                                        placement="top"
                                                    >
                                                        <Typography
                                                            variant="inherit"
                                                            sx={{
                                                                fontSize:
                                                                    outerWidth <
                                                                    1750
                                                                        ? 12
                                                                        : 14
                                                            }}
                                                        >
                                                            {line.reference.slice(
                                                                0,
                                                                20
                                                            )}
                                                            ...
                                                        </Typography>
                                                    </Tooltip>
                                                ) : (
                                                    <Typography
                                                        variant="inherit"
                                                        sx={{
                                                            fontSize:
                                                                outerWidth <
                                                                1750
                                                                    ? 12
                                                                    : 14
                                                        }}
                                                    >
                                                        {line.reference}
                                                    </Typography>
                                                )}
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '70px'
                                                        : ''
                                                }
                                            >
                                                <Typography
                                                    variant="inherit"
                                                    sx={{
                                                        fontSize:
                                                            outerWidth < 1750
                                                                ? 12
                                                                : 14
                                                    }}
                                                >
                                                    {CurrencyFormatter(
                                                        line.amount
                                                    )}
                                                </Typography>
                                            </DataCell>
                                            <DataCell
                                                align="right"
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '50px'
                                                        : ''
                                                }
                                            >
                                                {line.DebtorPaymentId ||
                                                line.StripePaymentId ? null : (
                                                    <IconButton
                                                        disabled={
                                                            refreshLoading
                                                        }
                                                        onClick={() =>
                                                            handleOraTransactionClick(
                                                                line
                                                            )
                                                        }
                                                    >
                                                        <InfoOutlined
                                                            style={{
                                                                fontSize:
                                                                    outerWidth <
                                                                    1750
                                                                        ? 14
                                                                        : 18
                                                            }}
                                                        />
                                                    </IconButton>
                                                )}
                                            </DataCell>
                                        </TableRow>
                                    ))}
                                </DataTable>
                            </TableContainer>
                        ) : (
                            <DataTable columns={oraColumns}>
                                <></>
                            </DataTable>
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={outerWidth < 1750 ? 1 : 0.8}>
                    <Tabs
                        sx={{ maxHeight: '40vw' }}
                        value={selectedBankUnrecTab}
                        onChange={(e, newValue) =>
                            setSelectedBankUnrecTab(newValue)
                        }
                        variant="scrollable"
                        scrollButtons="auto"
                        orientation="vertical"
                    >
                        {reconciliationDetails?.bankTransactionsUnreconciled.map(
                            (week) => (
                                <Tab
                                    sx={{
                                        fontWeight:
                                            selectedBankUnrecTab === week.week
                                                ? 'bold'
                                                : '',
                                        fontSize: outerWidth < 1750 ? 12 : 14
                                    }}
                                    value={week?.week}
                                    label={
                                        moment(week?.week, 'ww-YYYY').isValid()
                                            ? `${moment(week?.week, 'ww-YYYY').endOf('week').format('DD/MM/YYYY')} - ${moment(week?.week, 'ww-YYYY').startOf('week').format('DD/MM/YYYY')}`
                                            : 'Invalid Date'
                                    }
                                />
                            )
                        )}
                    </Tabs>
                </Grid>
                <Grid item xs={outerWidth < 1750 ? 5 : 5.2}>
                    <Paper>
                        {selectedBankUnrecTab.length > 0 &&
                        reconciliationDetails.bankTransactionsUnreconciled
                            .length > 0 ? (
                            <TableContainer style={{ maxHeight: '40vw' }}>
                                <DataTable columns={bankColumns}>
                                    {reconciliationDetails.bankTransactionsUnreconciled[
                                        reconciliationDetails.bankTransactionsUnreconciled.findIndex(
                                            (x) =>
                                                x.week === selectedBankUnrecTab
                                        )
                                    ]?.lines?.map((line, index) => (
                                        <TableRow>
                                            <DataCell>
                                                <IconButton
                                                    disabled={
                                                        refreshLoading ||
                                                        readOnly
                                                    }
                                                    size={
                                                        outerWidth < 1750
                                                            ? 'small'
                                                            : 'medium'
                                                    }
                                                    onClick={() =>
                                                        HandleMoveTransactionV2(
                                                            'up',
                                                            'bank',
                                                            selectedBankUnrecTab,
                                                            index,
                                                            reconciliationDetails,
                                                            setReconciliationDetails,
                                                            selectedBankUnrecTab,
                                                            setSelectedBankUnrecTab
                                                        )
                                                    }
                                                >
                                                    <ExpandLess
                                                        style={{ fontSize: 18 }}
                                                    />
                                                </IconButton>
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '70px'
                                                        : ''
                                                }
                                            >
                                                <Typography
                                                    variant="inherit"
                                                    sx={{
                                                        fontSize:
                                                            outerWidth < 1750
                                                                ? 12
                                                                : 14
                                                    }}
                                                >
                                                    {line.date}
                                                </Typography>
                                            </DataCell>
                                            <DataCell maxWidth="75px">
                                                {line.reference.length > 40 ? (
                                                    <Tooltip
                                                        title={line.reference}
                                                        placement="top"
                                                    >
                                                        <Typography
                                                            variant="inherit"
                                                            sx={{
                                                                fontSize:
                                                                    outerWidth <
                                                                    1750
                                                                        ? 12
                                                                        : 14
                                                            }}
                                                        >
                                                            {line.reference.slice(
                                                                0,
                                                                40
                                                            )}
                                                            ...
                                                        </Typography>
                                                    </Tooltip>
                                                ) : (
                                                    <Typography
                                                        variant="inherit"
                                                        sx={{
                                                            fontSize:
                                                                outerWidth <
                                                                1750
                                                                    ? 12
                                                                    : 14
                                                        }}
                                                    >
                                                        {line.reference}
                                                    </Typography>
                                                )}
                                            </DataCell>
                                            <DataCell
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '70px'
                                                        : ''
                                                }
                                            >
                                                <Typography
                                                    variant="inherit"
                                                    sx={{
                                                        fontSize:
                                                            outerWidth < 1750
                                                                ? 12
                                                                : 14
                                                    }}
                                                >
                                                    {CurrencyFormatter(
                                                        line.amount
                                                    )}
                                                </Typography>
                                            </DataCell>
                                            <DataCell
                                                align="right"
                                                maxWidth={
                                                    outerWidth < 1750
                                                        ? '50px'
                                                        : ''
                                                }
                                            >
                                                {line.DebtorPaymentId ||
                                                line.StripePaymentId ? null : (
                                                    <IconButton
                                                        disabled={
                                                            refreshLoading
                                                        }
                                                        onClick={() =>
                                                            handleOraTransactionClick(
                                                                line
                                                            )
                                                        }
                                                    >
                                                        <InfoOutlined
                                                            style={{
                                                                fontSize:
                                                                    outerWidth <
                                                                    1750
                                                                        ? 14
                                                                        : 18
                                                            }}
                                                        />
                                                    </IconButton>
                                                )}
                                            </DataCell>
                                        </TableRow>
                                    ))}
                                </DataTable>
                            </TableContainer>
                        ) : (
                            <DataTable columns={bankColumns}>
                                <></>
                            </DataTable>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            <Drawer
                openDrawer={drawerOpen}
                setOpenDrawer={setDrawerOpen}
                title={String(
                    RenderDrawerContent('title', selectedType, selectedObject)
                )}
                subTitle={String(
                    RenderDrawerContent(
                        'subtitle',
                        selectedType,
                        selectedObject
                    )
                )}
            >
                {RenderDrawerContent('content', selectedType, selectedObject)}
            </Drawer>

            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 145,
                    bottom: 16,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <Fab onClick={() => ScrollToElement('reconciled')}>
                    <PlaylistAddCheck />
                </Fab>
            </div>
            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 80,
                    bottom: 16,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <Fab onClick={() => ScrollToElement('unreconciled')}>
                    <PlaylistRemove />
                </Fab>
            </div>
        </>
    );
};

export default TablesV2;
