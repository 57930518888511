// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
// LOGIC
import api from '../../../../../api';
import { ServiceTotal } from './GetTotals';
// INTERFACES
import {
    CustomerVehicle,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { Customer, User } from '../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import dayjs from 'dayjs';

const GetServiceDetails = (
    id: number,
    setOnLoad: Dispatch<SetStateAction<boolean>>,
    setServiceDetails: Dispatch<SetStateAction<Service>>,
    setSublets: Dispatch<SetStateAction<any[]>>,
    setPartSales: Dispatch<SetStateAction<Order[]>>,
    setSelectedTech: Dispatch<SetStateAction<User>>,
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle | CustomerVehicle>>,
    setVehicleType: Dispatch<SetStateAction<string>>,
    setSelectedInsurer: Dispatch<SetStateAction<Customer>>,
    setSelectedCustomer: Dispatch<SetStateAction<Customer>>,
    setReadOnly: Dispatch<SetStateAction<boolean>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar,
    setInvalid: Dispatch<SetStateAction<boolean>>
) => {
    api.get(`serviceNextGen/${id}`).then((res) => {
        if (res.status === 204) {
            showSnackbar(
                'Sale does not exist.',
                'Please double check your URL and try again.',
                'warning'
            );
            setLoading(false);
            setInvalid(true);
        } else {
            let serviceDetails = res.data.serviceDetails;
            let sublets = res.data.purchaseOrders;
            let partSales = res.data.partSales;
            if (serviceDetails.InsurerId) {
                setSelectedInsurer(serviceDetails.Insurer);
            }
            if (serviceDetails.CustomerId) {
                setSelectedCustomer(serviceDetails.Customer);
            }

            if (serviceDetails.VehicleId) {
                setVehicleType('stock');
                let vehicle = serviceDetails.Vehicle;
                vehicle.year = dayjs(serviceDetails.Vehicle.year);
                setSelectedVehicle(vehicle);
            } else if (serviceDetails.CustomerVehicleId) {
                setVehicleType('customer');
                let vehicle = serviceDetails.CustomerVehicle;
                vehicle.year = dayjs(serviceDetails.CustomerVehicle.year);
                setSelectedVehicle(vehicle);
            }

            if (serviceDetails.TechId) {
                setSelectedTech(serviceDetails.Tech);
            }

            partSales.forEach((sale) => {
                sale.orderLines.forEach((line) => {
                    if (!line.priceCharged) {
                        line.priceCharged = line.pricePaid;
                    }
                });
            });

            if (!serviceDetails.labourCost) {
                serviceDetails.labourCost = 0;
            }

            if (!serviceDetails.totalPrice) {
                serviceDetails.totalPrice = ServiceTotal(
                    serviceDetails,
                    sublets,
                    partSales
                );
            }

            serviceDetails.addedCosts.forEach((line) => {
                if (line.partType === 'fluid') {
                    line.quantity = parseFloat(line.quantity);
                }
            });

            serviceDetails.jobStart = String(
                moment(serviceDetails.jobStart).format('YYYY-MM-DDTHH:mm')
            );

            if (serviceDetails.jobFinish) {
                serviceDetails.jobFinish = String(
                    moment(serviceDetails.jobFinish).format('YYYY-MM-DDTHH:mm')
                );
            }

            if (!serviceDetails.jobCodes) {
                serviceDetails.jobCodes = [];
            }

            setServiceDetails(serviceDetails);
            setSublets(sublets);
            setPartSales(partSales);
            setReadOnly(true);
            setOnLoad(false);
        }
    });
};

export default GetServiceDetails;
