import api from "../../../../../api"

const GetDataFromLead = (leadId, setCustomerDetails) => {
    api.get('leadNextGen/' + leadId.toString()).then(res => {
        if (res.data?.Customer?.id) {
            setCustomerDetails(res.data.Customer)
        }
    })
}

export default GetDataFromLead