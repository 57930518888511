// IMPORTS
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import BankTransactionFileTable from './components/BankTransactionFileTable';
import UnreconciledTransactionOverview from './components/UnreconciledTransactionOverview';
import SiteAndDateHeader from '../../global/dashboard/SiteAndDateHeader';
// LOGIC
import GetBankTransactionDataInDateRange from './logic/GetBankTransactionDataInDateRange';
import { getDefaultDateRange } from '../../global/dashboard/getMonthDatesAndBusinessDays';
// INTERFACES
import { BankTransaction } from '../../global/interfaces/AdminInterface';
import { Dayjs } from 'dayjs';

const AdminDashboard = () => {
    const { firstDayOfCurrentMonth, today } = getDefaultDateRange();

    // header: type; date; site
    const [selectedStartDate, setSelectedStartDate] = useState<Dayjs>(
        firstDayOfCurrentMonth
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Dayjs>(today);

    const [
        unreconciledTransactionsSelectedMonth,
        setUnreconciledTransactionsSelectedMonth
    ] = useState<BankTransaction[]>([]);

    const [transactionsInFiles, setTransactionsInFiles] = useState<
        BankTransaction[]
    >([]);

    const fetchBankTransactionData = async () => {
        try {
            if (
                selectedStartDate.isValid() &&
                selectedEndDate.isValid() &&
                selectedStartDate.isBefore(selectedEndDate)
            ) {
                const { transactionsInDateRange, transactionsInFiles } =
                    await GetBankTransactionDataInDateRange(
                        selectedStartDate.toString(),
                        selectedEndDate.toString()
                    );

                setTransactionsInFiles(transactionsInFiles);

                setUnreconciledTransactionsSelectedMonth(
                    transactionsInDateRange.filter(
                        (transaction: BankTransaction) =>
                            transaction.reconciled !== true
                    )
                );
            }
        } catch (error) {
            console.error('Error fetching bank transactions data', error);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchBankTransactionData();
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line
    }, [selectedStartDate, selectedEndDate]);

    return (
        <PageWrapper>
            <Typography variant="h4">Dashboard</Typography>
            <br />
            <SiteAndDateHeader
                department="admin"
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
            />
            <br />
            <UnreconciledTransactionOverview
                unreconciledTransactionsSelectedMonth={
                    unreconciledTransactionsSelectedMonth
                }
            />

            <br />
            <BankTransactionFileTable
                transactionsInFiles={transactionsInFiles}
                firstDayOfCurrentMonth={firstDayOfCurrentMonth}
                today={today}
            />
        </PageWrapper>
    );
};
export default AdminDashboard;
