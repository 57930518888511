// IMPORTS
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
    Checkbox,
    DialogContentText,
    FormControlLabel,
    Grid
} from '@mui/material';
// COMPONENTS
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';
interface UserTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const departmentOptions = [
    { id: 0, label: 'Parts', value: 'parts' },
    { id: 1, label: 'Service', value: 'service' },
    { id: 2, label: 'Sales', value: 'sales' },
    { id: 3, label: 'Admin', value: 'admin' },
    { id: 4, label: 'IT', value: 'IT' }
];

let siteOptions = [];

const UserTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: UserTableFilterProps) => {
    const [departments, setDepartments] = useState(departmentOptions);
    const [sitesUnaltered, setSitesUnaltered] = useState<Site[]>(siteOptions);
    const [sites, setSites] = useState<Site[]>(siteOptions);

    useEffect(() => {
        GetAllSites(setSites);
        GetAllSites(setSitesUnaltered);
    }, []);

    useEffect(() => {
        if (!isFilterActive()) {
            setDepartments(departmentOptions);
            setSites(sitesUnaltered);
        }
        // eslint-disable-next-line
    }, [filter]);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.userFilter;
        newFilter[property] = value;
        setFilter({ ...filter, userFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={departments}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Department"
                        selectedValues={filter.userFilter.department}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = departmentOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.value !== val.value
                                );
                            });

                            setDepartments(currentOptions);
                            handleUpdateFilter('department', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    {/* Customer Type Filter */}
                    <AutocompleteMultiple
                        options={sites}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Site"
                        selectedValues={filter.userFilter.site}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sitesUnaltered;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSites(currentOptions);
                            handleUpdateFilter('site', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4} textAlign="left">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.userFilter.employed}
                                onChange={(e) =>
                                    handleUpdateFilter(
                                        'employed',
                                        e.target.checked
                                    )
                                }
                            />
                        }
                        label="Employed"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default UserTableFilter;
