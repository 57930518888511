import { Dispatch, SetStateAction } from "react"
import api from "../../../../api"
import { Registration } from "../interfaces/VehicleSaleInterface"

const GetFutureNSWRegistration = (setFutureRegistrations: Dispatch<SetStateAction<Registration[]>>) => {
    api.get('nswFutureRegistration').then(res => {
        setFutureRegistrations(res.data)
    })
}

export default GetFutureNSWRegistration