// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Sync } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { User } from '../../../global/interfaces/GeneralInterface';
interface EmploymentDetailsProps {
    userDetails: User;
    setUserDetails: Dispatch<SetStateAction<User>>;
    readOnly: boolean;
    selectedPaymentType: string;
    setSelectedPaymentType: Dispatch<SetStateAction<string>>;
}
const EmploymentDetails = ({
    userDetails,
    readOnly,
    setUserDetails,
    selectedPaymentType,
    setSelectedPaymentType
}: EmploymentDetailsProps) => {
    const employmentTypes = [
        { value: 'Full Time' },
        { value: 'Part Time' },
        { value: 'Casual' },
        { value: 'Contract' }
    ];

    return (
        <Paper>
            <Typography variant="h6">Employment Details</Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        select
                        InputProps={{ style: { textAlign: 'center' } }}
                        size="small"
                        label="Employment Type"
                        InputLabelProps={{ shrink: true }}
                        disabled={readOnly}
                        value={userDetails?.employmentType}
                        onChange={(e) =>
                            setUserDetails({
                                ...userDetails,
                                employmentType: e.target.value
                            })
                        }
                    >
                        {employmentTypes.map((option) => (
                            <MenuItem value={option.value}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    {selectedPaymentType === 'hourly' ? (
                        <TextField
                            fullWidth
                            size="small"
                            label="Hourly Rate"
                            InputLabelProps={{ shrink: true }}
                            disabled={readOnly}
                            value={userDetails?.hourlyRate}
                            onChange={(e) =>
                                setUserDetails({
                                    ...userDetails,
                                    hourlyRate: e.target.value
                                })
                            }
                            InputProps={{
                                endAdornment: readOnly ? null : (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title="Swap to Annual Salary"
                                            placement="top"
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    setSelectedPaymentType(
                                                        'salary'
                                                    )
                                                }
                                            >
                                                <Sync />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    ) : (
                        <TextField
                            fullWidth
                            size="small"
                            label="Annual Salary"
                            InputLabelProps={{ shrink: true }}
                            disabled={readOnly}
                            value={userDetails?.annualSalary}
                            onChange={(e) =>
                                setUserDetails({
                                    ...userDetails,
                                    annualSalary: e.target.value
                                })
                            }
                            InputProps={{
                                endAdornment: readOnly ? null : (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title="Swap to Hourly Rate"
                                            placement="top"
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    setSelectedPaymentType(
                                                        'hourly'
                                                    )
                                                }
                                            >
                                                <Sync />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Standard Weekly Hours"
                        InputLabelProps={{ shrink: true }}
                        disabled={readOnly}
                        value={userDetails?.standardHours}
                        onChange={(e) =>
                            setUserDetails({
                                ...userDetails,
                                standardHours: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={1.5}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            disabled={readOnly}
                            format="DD/MM/YYYY"
                            label="Date Started"
                            value={dayjs(userDetails?.dateStarted)}
                            onChange={(newValue) =>
                                setUserDetails({
                                    ...userDetails,
                                    dateStarted: dayjs(newValue).toISOString()
                                })
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={1.5}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            disabled={readOnly || userDetails.employed}
                            format="DD/MM/YYYY"
                            label="Date Finish"
                            value={userDetails?.terminationDate}
                            onChange={(newValue) =>
                                setUserDetails({
                                    ...userDetails,
                                    terminationDate: newValue
                                })
                            }
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EmploymentDetails;
