import { BaseSyntheticEvent, Dispatch, SetStateAction } from 'react';
import { Grid, Pagination, TableRow, TextField } from '@mui/material';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';

// COMPONENT
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';

// INTERFACE
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';

interface ModelDrawerContentProps {
    filterValue: string;
    filterListOfVehicles: Vehicle[];
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    handleFilterValueChange: (value: string) => void;
}

const ModelDrawerContent = ({
    filterListOfVehicles,
    filterValue,
    page,
    setPage,
    handleFilterValueChange
}: ModelDrawerContentProps) => {
    // Dialog state

    const dialogColumns = [
        { id: 0, label: 'Stock Number' },
        { id: 1, label: 'Series' },
        { id: 2, label: 'Condition' },
        { id: 3, label: 'Cost Price' }
    ];

    const handleChangePage = (e: BaseSyntheticEvent, newPage: number) => {
        setPage(newPage);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        label="Stock Number"
                        value={filterValue}
                        onChange={(e) =>
                            handleFilterValueChange(e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={7}>
                    <Pagination
                        color="primary"
                        count={
                            filterListOfVehicles.length <= 10
                                ? 1
                                : Math.ceil(filterListOfVehicles.length / 10)
                        }
                        page={page}
                        siblingCount={0}
                        boundaryCount={1}
                        onChange={handleChangePage}
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            marginTop: 1.4
                        }}
                    />
                </Grid>
            </Grid>
            <br />
            <DataTable columns={dialogColumns}>
                {filterListOfVehicles
                    .slice((page - 1) * 10, page * 10)
                    .map((vehicle) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `vehicles/view/${vehicle.stockNumber}`,
                                        '_blank'
                                    )
                                }
                            >
                                {vehicle.stockNumber}
                            </DataCellColoured>
                            <DataCell>
                                {vehicle?.Series?.name.length > 0
                                    ? vehicle?.Series?.name
                                    : '-'}
                            </DataCell>
                            <DataCell>
                                {vehicle?.condition?.toUpperCase()}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(vehicle.costPrice)}
                            </DataCell>
                        </TableRow>
                    ))}
            </DataTable>
        </>
    );
};

export default ModelDrawerContent;
