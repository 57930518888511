// IMPORTS
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Grid, Dialog } from '@mui/material';
// COMPONENTS
import PageWrapper from '../../../global/PageWrapper';
import Paper from '../../../global/Paper';
import DetailsTable from './partDetailsComponents/DetailsTable';
import PricingTable from './partDetailsComponents/PricingTable';
import StockTable from './partDetailsComponents/StockTable';
import SupplierDialogContent from './SupplierDialogContent';
import ResetAverageCostDialog from './ResetAverageCostDialog';
import Drawer from '../../../global/Drawer';
import StockDrawerContent from './StockDrawerContent';

// LOGIC
import GetSpecifics from '../../../global/logic/GetSpecifics';
import GetDrawerContent from '../logic/GetDrawerContent';

// INTERFACES
import {
    Unit,
    BinLocation,
    Order,
    StockTransfer,
    StockOrder
} from '../../../global/interfaces/PartsInterface';
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { CreditInvoice } from '../../../global/interfaces/AdminInterface';

interface StockDetails {
    minStock: number;
    maxStock: number;
}

interface TempUnit extends Unit {
    reservedData: Order[];
    transferData: StockTransfer[];
    incomingData: StockOrder[];
    creditedData: CreditInvoice[];
}
interface PartDetailsProps {
    partDetails: TempUnit;
    setPartDetails: Dispatch<SetStateAction<Unit>>;
    readOnly: boolean;
    selectedSite: number;
    stockDetails: StockDetails;
    setStockDetails: Dispatch<SetStateAction<StockDetails>>;
    showSnackbar: showSnackbar;
}

const PartDetails = ({
    partDetails,
    setPartDetails,
    readOnly,
    selectedSite,
    stockDetails,
    setStockDetails,
    showSnackbar
}: PartDetailsProps) => {
    const [resetAverageCostOpen, setResetAverageCostOpen] =
        useState<boolean>(false);
    const [creditorDialogOpen, setCreditorDialogOpen] =
        useState<boolean>(false);
    const [selectedCreditor, setSelectedCreditor] = useState<number>();

    const [binLocationData, setBinLocationData] = useState<BinLocation[]>();
    const [creditorData, setCreditorData] = useState<Creditor[]>();
    const [brandList, setBrandList] = useState([]);

    // Drawer
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [drawerContent, setDrawerContent] = useState<
        Order[] | StockTransfer[]
    >([]);

    // Get all the data regarding the selected part
    // Get all the options for the specifics to show in the dropdowns
    useEffect(() => {
        GetSpecifics({
            specific: 'BinLocation',
            setSpecificList: setBinLocationData,
            site: parseInt(localStorage.getItem('SiteId'))
        });
        GetSpecifics({ specific: 'Brand', setSpecificList: setBrandList });
        GetSpecifics({
            specific: 'Supplier',
            setSpecificList: setCreditorData
        });
    }, []);

    // At the change of drawer title, fetch the new data
    useEffect(() => {
        if (partDetails && title !== '') {
            GetDrawerContent(
                title,
                partDetails,
                selectedSite,
                setDrawerContent
            );
        }
        // eslint-disable-next-line
    }, [title]);

    // Once the drawer content data has been loaded we open the drawer
    useEffect(() => {
        // Prevent drawer from opening on initial rendering
        if (title.length > 0) {
            setOpenDrawer(true);
        }
        // eslint-disable-next-line
    }, [drawerContent]);

    // Handles changing the value in a textfield
    const handleOnChange = (
        fieldName: string,
        fieldValue: string | boolean
    ) => {
        setPartDetails({ ...partDetails, [fieldName]: fieldValue });
    };

    return (
        <PageWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Paper>
                        <DetailsTable
                            partDetails={partDetails}
                            setPartDetails={setPartDetails}
                            readOnly={readOnly}
                            handleOnChange={handleOnChange}
                            brandList={brandList}
                            binLocationData={binLocationData}
                            selectedSite={selectedSite}
                            setCreditorDialogOpen={setCreditorDialogOpen}
                            setSelectedCreditor={setSelectedCreditor}
                            creditorData={creditorData}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Paper>
                        <PricingTable
                            partDetails={partDetails}
                            setPartDetails={setPartDetails}
                            readOnly={readOnly}
                            selectedSite={selectedSite}
                            setResetAverageCostOpen={setResetAverageCostOpen}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Paper>
                        <StockTable
                            partDetails={partDetails}
                            readOnly={readOnly}
                            selectedSite={selectedSite}
                            stockDetails={stockDetails}
                            setStockDetails={setStockDetails}
                            setOpenDrawer={setOpenDrawer}
                            setTitle={setTitle}
                        />
                    </Paper>
                </Grid>
            </Grid>

            {/* View Supplier Details */}
            <Dialog
                open={creditorDialogOpen}
                onClose={() => setCreditorDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <SupplierDialogContent
                    dialogSupplier={selectedCreditor}
                    partDetails={partDetails}
                    supplierList={creditorData}
                />
            </Dialog>

            {/* Reset Average Cost Dialog */}
            <Dialog
                open={resetAverageCostOpen}
                onClose={() => setResetAverageCostOpen(false)}
            >
                <ResetAverageCostDialog
                    partId={partDetails?.id}
                    unitData={partDetails}
                    setResetAverageCostDialogOpen={setResetAverageCostOpen}
                    showSnackbar={showSnackbar}
                />
            </Dialog>

            <Drawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                title={title}
                subTitle={''}
            >
                <StockDrawerContent
                    title={title}
                    drawerContent={drawerContent}
                    UnitId={partDetails?.id}
                />
            </Drawer>
        </PageWrapper>
    );
};

export default PartDetails;
