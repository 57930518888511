// IMPORTS
import { Dispatch, SetStateAction, useContext } from 'react';
import { Grid, ToggleButtonGroup, ToggleButton } from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
import ManufacturerVehicleForm from './ManufacturerVehicleForm';
import VehicleForm from '../../../parts/newSale/components/SelectEntityForms/VehicleForm';
// INTERFACES
import {
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';
import { SiteContext } from '../../../../main/Main';

interface SelectVehicleProps {
    readOnly: boolean;
    selectedVehicle: Vehicle;
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle>>;
    saleDetails: VehicleSale;
    setSaleDetails: Dispatch<SetStateAction<VehicleSale>>;
}
const SelectVehicle = ({
    readOnly,
    selectedVehicle,
    setSelectedVehicle,
    saleDetails,
    setSaleDetails
}: SelectVehicleProps) => {
    const siteDetails = useContext<Site>(SiteContext);
    const handleChangeType = (
        event: React.MouseEvent<HTMLElement>,
        newType: string | null
    ) => {
        if (newType === 'manufacturer') {
            setSaleDetails({ ...saleDetails, manufacturerOrder: true });
        } else {
            setSaleDetails({ ...saleDetails, manufacturerOrder: false });
        }
        setSelectedVehicle(null);
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            fullWidth
                            value={
                                saleDetails.manufacturerOrder
                                    ? 'manufacturer'
                                    : 'stock'
                            }
                            onChange={handleChangeType}
                            disabled={readOnly}
                            size="small"
                        >
                            <ToggleButton value="stock">
                                Stock{' '}
                                {siteDetails.vehicleTypeSold === 'Motorcycles'
                                    ? 'Vehicle'
                                    : 'Bicycle'}
                            </ToggleButton>
                            <ToggleButton value="manufacturer">
                                Manufacturer Order
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Paper>
            <br />
            {saleDetails.manufacturerOrder ? (
                <ManufacturerVehicleForm
                    readOnly={readOnly}
                    selectedEntity={selectedVehicle}
                    setSelectedEntity={setSelectedVehicle}
                />
            ) : (
                <VehicleForm
                    readOnly={readOnly}
                    selectedEntity={selectedVehicle}
                    setSelectedEntity={setSelectedVehicle}
                    removeSoldVehicles={true}
                    fromVehicleSale={true}
                />
            )}
        </>
    );
};

export default SelectVehicle;
