import DocumentationPage from '../DocumentationPage';
import {
    AddNewVehicleSaleContractSteps,
    AddCSMsToContractSteps
    // AddNewVehicleQuoteContractSteps,
    // AddNewVehicleFinalisedContractSteps,
    // LostVehicleContractSteps,
} from '../data/vehicleSales/newSale';

const NewVehicleSaleSupportPage = () => {
    const contentSections = [
        {
            id: 'add-vehicle-sale-contract',
            title: 'Create a new Vehicle Sale Contract',
            href: '/support/vehicles/newContract/#add-vehicle-sale-contract',
            description:
                'To add a new Vehicle Sale Contract, follow these steps:',
            steps: AddNewVehicleSaleContractSteps
        },
        // {
        //     id: 'add-vehicle-quote-contract',
        //     title: 'Add new vehicle quote contract',
        //     href: '/support/vehicles/newContract/#add-vehicle-quote-contract',
        //     description:
        //         'To add a new vehicle quote contract, follow these steps:',
        //     steps: AddNewVehicleQuoteContractSteps
        // },
        // {
        //     id: 'add-vehicle-finalised-contract',
        //     title: 'Add new vehicle finalised contract',
        //     href: '/support/vehicles/newContract/#add-vehicle-finalised-contract',
        //     description:
        //         'To add a new Vehicle Sale Contract, follow these steps:',
        //     steps: AddNewVehicleFinalisedContractSteps
        // },
        // {
        //     id: 'lost-vehicle-contract',
        //     title: 'Lost vehicle contract',
        //     href: '/support/vehicles/newContract/#lost-vehicle-contract',
        //     description:
        //         'To mark a vehicle contract as lost, follow these steps:',
        //     steps: LostVehicleContractSteps
        // },
        {
            id: 'add-csms-to-contract',
            title: 'Add CSMs to a vehicle contract',
            href: '/support/vehicles/newContract/#add-csms-to-contract',
            description:
                'To add CSMs to a vehicle contract, follow these steps:',
            steps: AddCSMsToContractSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="New Contract"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default NewVehicleSaleSupportPage;
