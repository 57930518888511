// IMPORTS
import { useState, useEffect } from 'react';
import { Typography, TableRow, Chip } from '@mui/material';
import { Add } from '@mui/icons-material';
import dayjs from 'dayjs';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import PurchaseOrderTableFilter from './components/PurchaseOrderTableFilter';
import Drawer from '../../global/Drawer';
import PurchaseOrderDrawerContent from './components/PurchaseOrderDrawerContent';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../global/logic/Formatters';
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import GetSite from '../../global/databaseLogic/GetSite';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { PurchaseOrder } from '../../global/interfaces/ServiceInterface';
import { Site } from '../../global/interfaces/GeneralInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    purchaseOrderFilter: {
        startDate: null,
        endDate: null,
        site: [],
        creditor: [],
        user: []
    }
};
const clearedSort = ['id', 'DESC'];

const PurchaseOrderTable = () => {
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<PurchaseOrder[]>([]);
    const [siteDetails, setSiteDetails] = useState<Site>();

    const [purchaseOrderDrawerOpen, setPurchaseOrderDrawerOpen] =
        useState<boolean>(false);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
        useState<PurchaseOrder>();

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'New Purchase Order',
            onClick: () => {
                setSelectedPurchaseOrder({ includingGST: false });
                setPurchaseOrderDrawerOpen(true);
            }
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 50 },
        { id: 1, label: 'Service', width: 100 },
        { id: 2, label: 'Creditor', width: 100 },
        { id: 3, label: 'Order Reference', width: 200 },
        { id: 4, label: 'Amount', width: 100 },
        { id: 5, label: 'Date Created', width: 100 },
        { id: 6, label: 'Location', width: 100 },
        { id: 7, label: 'User', width: 100 },
        { id: 8, label: '', width: 100 }
    ];

    // Get the details of the current site
    useEffect(() => {
        GetSite(parseInt(localStorage.getItem('SiteId')), setSiteDetails);
    }, []);

    // Once the current site details have been obtained set the filter to some default values
    useEffect(() => {
        let newFilter = {
            startDate: null,
            endDate: null,
            site: [
                {
                    id: parseInt(localStorage.getItem('SiteId')),
                    name: siteDetails?.name
                }
            ],
            creditor: [],
            user: []
        };
        setFilter({ ...filter, purchaseOrderFilter: newFilter });
        // eslint-disable-next-line
    }, [siteDetails]);

    /**
     * handleRequestCreate
     * Combine the selected filters into a single API string
     * @author Sienna
     * @returns the API string to be sent to the backend
     */
    const handleRequestCreate = () => {
        let site =
            filter.purchaseOrderFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.purchaseOrderFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let user =
            filter.purchaseOrderFilter.user.length > 0
                ? ConvertArrayToFilter({
                      array: filter.purchaseOrderFilter.user,
                      filterStart: '&user=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let creditor =
            filter.purchaseOrderFilter.creditor.length > 0
                ? ConvertArrayToFilter({
                      array: filter.purchaseOrderFilter.creditor,
                      filterStart: '&supplier=',
                      selector: 'id',
                      useId: true
                  })
                : '';
        let startDate =
            filter.purchaseOrderFilter.startDate !== null &&
            dayjs(filter.purchaseOrderFilter.startDate).isValid()
                ? `&startDate=${dayjs(filter.purchaseOrderFilter.startDate).toISOString()}`
                : '';
        let endDate =
            filter.purchaseOrderFilter.endDate !== null &&
            dayjs(filter.purchaseOrderFilter.endDate).isValid()
                ? `&endDate=${dayjs(filter.purchaseOrderFilter.endDate).toISOString()}`
                : '';

        let apiString = `${creditor}${site}${user}${startDate}${endDate}`;
        return apiString;
    };

    /**
     * isFilterActive
     * Check if any options for the filter have been selected
     * @author Sienna
     * @returns true if any filters have been selected
     */
    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.purchaseOrderFilter.startDate != null ||
            filter.purchaseOrderFilter.endDate != null ||
            filter.purchaseOrderFilter.site.length > 0 ||
            filter.purchaseOrderFilter.user.length > 0 ||
            filter.purchaseOrderFilter.creditor.length > 0
        ) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Purchase Orders</Typography>
            <br />
            <TableSearch
                searchTitle="Search Purchase Orders"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="purchaseOrderSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <PurchaseOrderTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() => {
                                    setSelectedPurchaseOrder({
                                        ...row,
                                        includingGST:
                                            row.hasOwnProperty(
                                                'includingGST'
                                            ) && row.includingGST != null
                                                ? row.includingGST
                                                : false
                                    });
                                    setPurchaseOrderDrawerOpen(true);
                                }}
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/service/viewBooking/${row.ServiceId}`,
                                        '_blank'
                                    )
                                }
                            >
                                {row.ServiceId}
                            </DataCellColoured>
                            <DataCell>{row.Supplier?.name}</DataCell>
                            <DataCell>{row.orderReference}</DataCell>
                            <DataCell>{CurrencyFormatter(row.amount)}</DataCell>
                            <DataCell>{DateFormatter(row.createdAt)}</DataCell>
                            <DataCell>{row.Site?.name}</DataCell>
                            <DataCell>
                                {row.User?.firstName} {row.User?.lastName}
                            </DataCell>
                            <DataCell>
                                <Chip
                                    variant="outlined"
                                    label={
                                        row.InvoiceId ? 'Invoiced' : 'Pending'
                                    }
                                    color={
                                        row.InvoiceId ? 'success' : 'warning'
                                    }
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={purchaseOrderDrawerOpen}
                setOpenDrawer={setPurchaseOrderDrawerOpen}
                title={
                    selectedPurchaseOrder?.id
                        ? 'Purchase Order #' + selectedPurchaseOrder?.id
                        : 'New Purchase Order'
                }
                subTitle={
                    selectedPurchaseOrder?.id
                        ? 'Service #' + selectedPurchaseOrder?.ServiceId
                        : ''
                }
            >
                <PurchaseOrderDrawerContent
                    selectedPurchaseOrder={selectedPurchaseOrder}
                    setSelectedPurchaseOrder={setSelectedPurchaseOrder}
                />
            </Drawer>
        </PageWrapper>
    );
};

export default PurchaseOrderTable;
