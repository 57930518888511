// IMPORTS
// INTERFACES
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';
// LOGIC
import {
    CalculateAmountPaid,
    CalculateAmountRefunded
} from './CreditCalculations';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        quantityReturned?: number; // used in crediting order
        backorderQuantityReturned?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        amountRefunded?: number; // used in crediting order
        // USED ONLY HERE
        refundableQuantity?: number;
        refundableBackorderQuantity?: number;
        quantityReturning?: number;
        backorderQuantityReturning?: number;
        amountRefunding?: number;
    }[];
}

/**
 * ValidateCreditSale
 * Validate user-entered information before processing credit
 * @author Estienne
 * @param saleDetails all data associated with the order
 * @param freightRefunding any freight amount being refunded
 * @param vouchers all vouchers on the sale
 * @param returnReason the reason the customer wants to return the product
 * @returns a validity status + error message/subMessage
 */
const ValidateCreditSale = (
    saleDetails: TempOrder,
    freightRefunding: string,
    vouchers: { voucher: Voucher; qtyReturning: number }[],
    returnReason: string,
    isUpdate: boolean
) => {
    let freightPaid = saleDetails?.freight - saleDetails?.freightRefunded;
    let amountPaid = CalculateAmountPaid(saleDetails, freightPaid, vouchers);
    let amountRefunding = CalculateAmountRefunded(
        saleDetails.orderLines,
        freightRefunding,
        vouchers
    );
    let amountRefundUpdate = 0;
    amountRefundUpdate += saleDetails.orderLines.reduce(
        (acc, value) => (acc + value.amountRefunded ? value.amountRefunded : 0),
        0
    );

    // Is there a return reason?
    if (returnReason.length === 0) {
        return {
            validated: false,
            message: 'Please provide a reason for the return.'
        };
    }

    // Is there a refunded amount?
    else if (!isUpdate && !amountRefunding) {
        return {
            validated: false,
            message: 'Cannot process a credit with no refund amount.'
        };
    }

    // Are we refunding a valid amount?
    else if (amountRefunding > amountPaid || amountRefundUpdate > amountPaid) {
        return {
            validated: false,
            message: 'Cannot credit more than amount paid.'
        };
    } else if (
        (!isUpdate && amountRefunding <= 0) ||
        (isUpdate && amountRefundUpdate <= 0)
    ) {
        return {
            validated: false,
            message: 'Amount returned must be greater than 0.'
        };
    } else if (parseFloat(freightRefunding) < 0) {
        return {
            validated: false,
            message: 'Freight refund amount cannot be negative.'
        };
    }

    let totalQuantityReturning = 0;

    for (let line of saleDetails.orderLines) {
        // Increment the total returned quantity
        if (line.quantityReturning) {
            totalQuantityReturning += line.quantityReturning;
        }

        if (line.backorderQuantityReturning) {
            totalQuantityReturning += line.backorderQuantityReturning;
        }

        // Has a refund amount been entered on a line with no returned items?
        if (
            !line.quantityReturning &&
            !line.backorderQuantityReturning &&
            line.amountRefunding
        ) {
            return {
                validated: false,
                message: 'Item must be returned for funds to be refunded'
            };
        }

        // Is an item being returned with no refund amount?
        if (
            (line.quantityReturning || line.backorderQuantityReturning) &&
            !line.amountRefunding
        ) {
            return {
                validated: false,
                message: 'All returned items must have a refund amount.'
            };
        }

        // Are we refunding a valid amount PER ITEM?
        if (line.amountRefunding <= 0) {
            return {
                validated: false,
                message: 'Refunded amount for an item must be greater than 0.'
            };
        }

        let quantityReturning = line.quantityReturning
            ? line.quantityReturning
            : 0;
        let backorderQuantityReturning = line.backorderQuantityReturning
            ? line.backorderQuantityReturning
            : 0;
        let validRefundAmount =
            parseFloat(line.pricePaid) * quantityReturning +
            parseFloat(line.pricePaid) * backorderQuantityReturning;
        if (line.amountRefunding > validRefundAmount) {
            return {
                validated: false,
                message:
                    'Cannot refund more for an item than was originally paid'
            };
        }

        // Are we returning a valid amount of each item?
        if (line.quantityReturning > parseInt(line.quantity)) {
            return {
                validated: false,
                message: 'Cannot return more of an item than originally sold.'
            };
        }

        // Are we returning a valid amount of each backordered item?
        if (
            line.backorderQuantityReturning > parseInt(line.backorderQuantity)
        ) {
            return {
                validated: false,
                message: 'Cannot return more than originally ordered.'
            };
        }
    }

    // Are we returning more than one of a single voucher?
    let refundedVouchers = vouchers.filter(
        (voucherObj) => voucherObj.qtyReturning > 1
    );
    if (refundedVouchers.length > 0) {
        return {
            validated: false,
            message: 'Cannot return more than one of a single voucher.'
        };
    }

    // Are we returning at least one item?
    if (totalQuantityReturning <= 0 && parseFloat(freightRefunding) === 0) {
        return {
            validated: false,
            message: 'At least one item must be returned.'
        };
    }

    return { validated: true, message: '' };
};

export default ValidateCreditSale;
