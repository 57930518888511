// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    DialogActions,
    Button,
    Typography,
    FormControlLabel,
    Checkbox
} from '@mui/material';
// COMPONENTS
import RoleCheck from '../../../global/RoleCheck';
import ManuallyAdjustStock from '../logic/ManuallyAdjustStock';
// INTERFACES
import {
    Unit,
    StockAdjustment
} from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
interface ManuallyAdjustStockDialogProps {
    partId: number;
    partDetails: Unit;
    stockAdjust: StockAdjustment;
    setStockAdjust: Dispatch<SetStateAction<StockAdjustment>>;
    disabledCostAdjust: boolean;
    setStockDialog: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const ManuallyAdjustStockDialog = ({
    partId,
    partDetails,
    stockAdjust,
    setStockAdjust,
    disabledCostAdjust,
    setStockDialog,
    showSnackbar
}: ManuallyAdjustStockDialogProps) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        if (disabledCostAdjust) {
            setStockAdjust({
                ...stockAdjust,
                adjustmentCost: partDetails.costPriceDaily
            });
        } else {
            let currentStockLine = partDetails.Stock.filter(
                (x) => x.SiteId === parseInt(localStorage.getItem('SiteId'))
            );
            setStockAdjust({
                ...stockAdjust,
                adjustmentCost: currentStockLine[0].costPriceAverage
            });
        }
        // eslint-disable-next-line
    }, [disabledCostAdjust]);

    return (
        <>
            <DialogTitle>
                Manually Adjust {partDetails.partNumber} Stock Levels
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="New Stock Level"
                            variant="standard"
                            value={stockAdjust.adjustmentValue}
                            onChange={(e) =>
                                setStockAdjust({
                                    ...stockAdjust,
                                    adjustmentValue: parseInt(e.target.value)
                                })
                            }
                        />
                    </Grid>

                    <RoleCheck
                        component={
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    label="Cost to Invoice As"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={disabledCostAdjust ? true : false}
                                    helperText={
                                        disabledCostAdjust
                                            ? 'This part has no current average cost.'
                                            : ''
                                    }
                                    value={stockAdjust.adjustmentCost}
                                    onChange={(e) => {
                                        setStockAdjust({
                                            ...stockAdjust,
                                            adjustmentCost: parseFloat(
                                                e.target.value
                                            )
                                        });
                                    }}
                                />
                            </Grid>
                        }
                        nullComponent={
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    label="Cost to Invoice As"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    helperText={
                                        disabledCostAdjust
                                            ? 'This part has no current average cost.'
                                            : ''
                                    }
                                    value={stockAdjust.adjustmentCost}
                                    onChange={(e) => {
                                        setStockAdjust({
                                            ...stockAdjust,
                                            adjustmentCost: parseFloat(
                                                e.target.value
                                            )
                                        });
                                    }}
                                />
                            </Grid>
                        }
                        permission="change_costStockAdjust"
                    />

                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            fullWidth
                            label="Reason for Adjustment"
                            InputLabelProps={{ shrink: true }}
                            value={stockAdjust.adjustmentDescription}
                            onChange={(e) => {
                                setStockAdjust({
                                    ...stockAdjust,
                                    adjustmentDescription: e.target.value
                                });
                            }}
                        />
                    </Grid>

                    <RoleCheck
                        component={null}
                        nullComponent={
                            <Grid item xs={8}>
                                <Typography variant="body1" align="left">
                                    Average cost calculation will use: $
                                    {partDetails.costPriceDaily}
                                </Typography>
                            </Grid>
                        }
                        permission="change_costStockAdjust"
                    />
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={stockAdjust.adjustAverage}
                                    onChange={(e) =>
                                        setStockAdjust({
                                            ...stockAdjust,
                                            adjustAverage: e.target.checked
                                        })
                                    }
                                />
                            }
                            label="Adjust Average Cost"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => setStockDialog(false)}>
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={buttonLoading}
                    onClick={() =>
                        ManuallyAdjustStock(
                            partId,
                            parseInt(localStorage.getItem('SiteId')),
                            stockAdjust,
                            partDetails,
                            showSnackbar,
                            setButtonLoading
                        )
                    }
                >
                    Adjust Stock
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default ManuallyAdjustStockDialog;
