import { Box, Divider, TableRow, Typography } from '@mui/material';
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';
import DataTable from '../../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../../global/tableComponents/DataCell';

export const UsedInventoryDrawerContent = ({
    selectedSite,
    queryDate,
    usedVehicleData
}) => {
    const columns = [
        { id: 0, label: 'Stock Number' },
        { id: 1, label: 'Make / Model / Series' },
        { id: 2, label: 'Cost Price (Ex. GST)' },
        { id: 3, label: 'Admin Load Cost' },
        { id: 4, label: 'Added Costs' },
        { id: 5, label: 'Total Cost' }
    ];
    return (
        <Box sx={{ width: '40vw', padding: '20px' }}>
            <Typography variant="h4">
                {typeof selectedSite != 'string' ? selectedSite.name : ''} -{' '}
                {queryDate.format('DD/MM/YYYY')}
            </Typography>
            <Typography>Used Vehicle Stock Breakdown</Typography>
            <Box height={'10px'}></Box>
            <Divider></Divider>
            <Box height="20px"></Box>
            <DataTable columns={columns}>
                {usedVehicleData.map((vehicle) => (
                    <TableRow>
                        <DataCellColoured
                            handleClick={() =>
                                window.open(
                                    '/vehicles/view/' + vehicle.id.toString(),
                                    '_blank'
                                )
                            }
                        >
                            {vehicle.stockNumber}
                        </DataCellColoured>
                        <DataCell>
                            {vehicle?.year} {vehicle?.Make?.name}{' '}
                            {vehicle?.Model?.name} {vehicle?.Series?.name}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(vehicle.costPrice)}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(vehicle.adminLoadCost)}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(vehicle.addedCosts)}
                        </DataCell>
                        <DataCell>
                            {CurrencyFormatter(vehicle.totalVehicleCost)}
                        </DataCell>
                    </TableRow>
                ))}
            </DataTable>
        </Box>
    );
};
