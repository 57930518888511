// Calculate the total for gst appliable parts
export function calculateGstApplicableAmount(orderLines) {
  let hasGstTotal = 0;
  let gstFreeTotal = 0;

  // Go through each of the lines
  orderLines.forEach((line) => {
    if (!line.removed && !line.invoiced) {
      if (!line.gstFree) {
        // If the part is not marked as gst free
        hasGstTotal += parseFloat(line.lineTotal);
      } else {
        // If the part is marked as gst free
        gstFreeTotal += parseFloat(line.lineTotal);
      }
    }
  });

  return { hasGst: hasGstTotal, gstFree: gstFreeTotal };
}

// Calculate the gst appliable parts total for invoiced orderlines
export function calculateGstApplicableAmountInvoiced(orderLines) {
  let hasGstTotal = 0;
  let gstFreeTotal = 0;

  // Go through each of the lines
  orderLines.forEach((line) => {
    if (line.invoiced) {
      if (!line.gstFree) {
        // If the part is not marked as gst free
        hasGstTotal += parseFloat(line.lineTotal);
      } else {
        // If the part is marked as gst free
        gstFreeTotal += parseFloat(line.lineTotal);
      }
    }
  });

  return { hasGst: hasGstTotal, gstFree: gstFreeTotal };
}


// Calculate the Gst total
export function calculateGstTotal(orderLines, gstMethod, freight) {
  // Only want to use the gst applicable parts for this total
  let gstPartTotal = calculateGstApplicableAmount(orderLines).hasGst;
  let gstTotal = 0;

  if (gstMethod === 'including') {
    gstTotal =
      gstPartTotal +
      parseFloat(freight) -
      (gstPartTotal + parseFloat(freight)) / 1.1;
  } else {
    gstTotal = (gstPartTotal + parseFloat(freight)) * 0.1;
  }

  return gstTotal;
}

// Calculate the Gst total of invoiced orderlines
export function calculateGstTotalInvoiced(orderLines, gstMethod, freight) {
  // Only want to use the gst applicable parts for this total
  let gstPartTotal = calculateGstApplicableAmountInvoiced(orderLines).hasGst;
  let gstTotal = 0;

  if (gstMethod === 'including') {
    gstTotal =
      gstPartTotal +
      parseFloat(freight) -
      (gstPartTotal + parseFloat(freight)) / 1.1;
  } else {
    gstTotal = (gstPartTotal + parseFloat(freight)) * 0.1;
  }

  return gstTotal;
}

// Calculate the Gst total
export function calculateGstTotalMultipleStockOrder(
  orderLines,
  gstMethod,
  freight
) {
  // Only want to use the gst applicable parts for this total
  let gstPartTotal = 0;
  for (let order of orderLines) {
    gstPartTotal += calculateGstApplicableAmount(order.orderLines).hasGst;
  }
  let gstTotal = 0;

  if (gstMethod === 'including') {
    gstTotal =
      gstPartTotal +
      parseFloat(freight) -
      (gstPartTotal + parseFloat(freight)) / 1.1;
  } else {
    gstTotal = (gstPartTotal + parseFloat(freight)) * 0.1;
  }

  return gstTotal;
}

// Calculate the difference
export function calculateDifference(
    orderLines,
    gstMethod,
    freight,
    documentTotal
) {
    let gstPartTotal = calculateGstApplicableAmount(orderLines).hasGst;
    let gstFreeTotal = calculateGstApplicableAmount(orderLines).gstFree;

    let gstTotal = calculateGstTotal(orderLines, gstMethod, freight);
    let difference = 0;

    if (gstMethod === 'including') {
        difference =
            parseFloat(documentTotal) -
            (gstPartTotal + gstFreeTotal) -
            parseFloat(freight);
    } else {
        difference =
            parseFloat(documentTotal) -
            (gstPartTotal + gstFreeTotal + gstTotal) -
            parseFloat(freight);
    }

    return difference;
}

export function calculateDifferenceInvoiced(
    orderLines,
    gstMethod,
    freight,
    documentTotal
) {
    let gstPartTotal = calculateGstApplicableAmountInvoiced(orderLines).hasGst;
    let gstFreeTotal = calculateGstApplicableAmountInvoiced(orderLines).gstFree;

    let gstTotal = calculateGstTotalInvoiced(orderLines, gstMethod, freight);
    let difference = 0;

    if (gstMethod === 'including') {
        difference =
            parseFloat(documentTotal) -
            (gstPartTotal + gstFreeTotal) -
            parseFloat(freight);
    } else {
        difference =
            parseFloat(documentTotal) -
            (gstPartTotal + gstFreeTotal + gstTotal) -
            parseFloat(freight);
    }
    return difference;
}

// Calculate the difference
export function calculateDifferenceMultipleStockOrder(
  orderLines,
  gstMethod,
  freight,
  documentTotal
) {
  let gstPartTotal = 0;
  let gstFreeTotal = 0;
  let gstTotal = calculateGstTotalMultipleStockOrder(
    orderLines,
    gstMethod,
    freight
  );
  for (let order of orderLines) {
    gstPartTotal += calculateGstApplicableAmount(order.orderLines).hasGst;
    gstFreeTotal += calculateGstApplicableAmount(order.orderLines).gstFree;
    // gstTotal += calculateGstTotal(order.orderLines, gstMethod, freight)
  }

  let difference = 0;

  if (gstMethod === 'including') {
    difference =
      parseFloat(documentTotal) -
      (gstPartTotal + gstFreeTotal) -
      parseFloat(freight);
  } else {
    difference =
      parseFloat(documentTotal) -
      (gstPartTotal + gstFreeTotal + gstTotal) -
      parseFloat(freight);
  }

  return difference;
}

// Calculate the entered total
export function calculateEnteredTotal(orderLines, freight) {
  let enteredTotal =
    calculateGstApplicableAmount(orderLines).gstFree +
    calculateGstApplicableAmount(orderLines).hasGst +
    parseFloat(freight);
  return enteredTotal;
}

export function calculateEnteredTotalMultipleStockOrder(orderLines, freight) {
  let enteredTotal = parseFloat(freight);
  for (let order of orderLines) {
    enteredTotal +=
      calculateGstApplicableAmount(order.orderLines).gstFree +
      calculateGstApplicableAmount(order.orderLines).hasGst;
  }

  return enteredTotal;
}
