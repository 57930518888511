// IMPORTS
import { useState } from 'react';
import {
    TableRow,
    Chip,
    IconButton,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    DialogTitle,
    Fab,
    TableCell,
    TextField
} from '@mui/material';
import { Sync, Delete, Add } from '@mui/icons-material';
// COMPONENTS
import TableSearch from '../../../global/tableComponents/TableSearch';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import RoleCheck from '../../../global/RoleCheck';
// LOGIC
import HandleDeleteBinLocation from '../logic/HandleDeleteBinLocation';
import HandleDefaultBinLocation from '../logic/HandleDefaultBinLocation';
import HandleAddBinLocation from '../logic/HandleAddBinLocation';
// INTERFACES
import { BinLocation } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { withSnackbar } from '../../../global/WrappingSnackbar';

const clearedSort = ['name', 'ASC'];

const BinLocationDrawerContent = ({
    showSnackbar
}: {
    showSnackbar: showSnackbar;
}) => {
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<BinLocation[]>([]);
    const [defaultDialogOpen, setDefaultDialogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [selectedBinLocation, setSelectedBinLocation] = useState<BinLocation>(
        {}
    );
    const [addBinLocationDialogOpen, setAddBinLocationDialogOpen] =
        useState<boolean>(false);
    const [newBinLocationName, setNewBinLocationName] = useState<string>('');
    const [nameError, setNameError] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', width: 100 },
        { id: 1, label: 'Name' },
        { id: 2, label: '', width: 110 }
    ];

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let apiString = `&SiteId=${localStorage.getItem('SiteId')}`;
        return apiString;
    };

    return (
        <>
            <TableSearch
                searchTitle="Search Bin Location"
                showFilter={false}
                showPagination={true}
                showPaper={false}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="binLocationSearchNextGen"
                setResultsList={setResultsList}
            ></TableSearch>

            <DataTable
                columns={columns}
                sort={sort}
                setSort={setSort}
                showSpeedDial={false}
            >
                {resultsList.map((row) => (
                    <TableRow key={row.id} selected={row.default}>
                        <DataCell>{row.id}</DataCell>
                        <DataCell>{row.name}</DataCell>
                        <TableCell>
                            {row.default ? (
                                <Chip
                                    label="Default"
                                    variant="outlined"
                                    color="success"
                                />
                            ) : (
                                <>
                                    <RoleCheck
                                        component={
                                            <Tooltip
                                                title="Swap Bin Location"
                                                placement="left"
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedBinLocation(
                                                            row
                                                        );
                                                        setDefaultDialogOpen(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <Sync />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        permission="default_binLocation"
                                    />
                                    <RoleCheck
                                        component={
                                            <Tooltip
                                                title="Delete Bin Location"
                                                placement="top"
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedBinLocation(
                                                            row
                                                        );
                                                        setDeleteDialogOpen(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        permission="delete_binLocation"
                                    />
                                </>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </DataTable>

            {/* Change default bin location dialog */}
            <Dialog
                open={defaultDialogOpen}
                onClose={() => setDefaultDialogOpen(false)}
            >
                <DialogTitle>
                    Make {selectedBinLocation.name} the Default?
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to make{' '}
                        {selectedBinLocation.name} the new default bin location?
                        All new parts created with no bin location will be
                        sorted into this default one.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDefaultDialogOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            HandleDefaultBinLocation(
                                selectedBinLocation.id,
                                showSnackbar,
                                setSort,
                                setDefaultDialogOpen,
                                setButtonLoading
                            )
                        }
                    >
                        Change Default
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {/* Delete bin location dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Delete {selectedBinLocation.name}?</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to delete{' '}
                        {selectedBinLocation.name}? All parts assigned to this
                        bin location will be taken out of it. This action cannot
                        be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            HandleDeleteBinLocation(
                                selectedBinLocation.id,
                                showSnackbar,
                                setSort,
                                setDeleteDialogOpen,
                                setButtonLoading
                            )
                        }
                    >
                        Delete
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={(e) => setAddBinLocationDialogOpen(true)}
                >
                    <Add />
                </Fab>
            </div>

            <Dialog
                open={addBinLocationDialogOpen}
                onClose={() => setAddBinLocationDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>New Bin Location</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Enter the name of the new bin location below. The bin
                        location will automatically be assigned to your current
                        site.
                    </Typography>
                    <br />
                    <TextField
                        fullWidth
                        label="Name"
                        value={newBinLocationName}
                        onChange={(e) => setNewBinLocationName(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        error={nameError}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddBinLocationDialogOpen(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            HandleAddBinLocation(
                                newBinLocationName,
                                setNameError,
                                showSnackbar,
                                setSort,
                                setAddBinLocationDialogOpen,
                                setButtonLoading
                            )
                        }
                    >
                        Add Bin Location
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withSnackbar(BinLocationDrawerContent);
