// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import {
    Creditor,
    Customer,
    Site
} from '../../../global/interfaces/GeneralInterface';
import { Account } from '../../../global/interfaces/AdminInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { PurchaseOrder } from '../../../global/interfaces/ServiceInterface';
import dayjs, { Dayjs } from 'dayjs';

interface InvoiceObject {
    Creditor?: Creditor;
    Site?: Site;
    Account?: Account;
    Debtor?: Customer;
    Vehicle?: Vehicle;
    PurchaseOrder?: PurchaseOrder;
    type: string;
    documentReference: string;
    documentDate: Dayjs;
    dueDate: Dayjs;
    GSTtype: string;
    documentTotal: string;
    StockInvoiceId?: number;
}

interface InvoiceLine {
    id: number;
    Account?: Account;
    Debtor?: Customer;
    Vehicle?: Vehicle;
    PurchaseOrder?: PurchaseOrder;
    amount: string;
    description: string;
}

function GetCreditInvoice(
    id: number,
    setInvoiceObject: Dispatch<SetStateAction<InvoiceObject>>,
    setInvoiceLines: Dispatch<SetStateAction<InvoiceLine[]>>,
    setDisabled: Dispatch<SetStateAction<boolean>>
) {
    api.get('adminCreditInvoice/' + id).then((res) => {
        setInvoiceObject({
            Creditor: res.data.StockInvoice.Supplier,
            Site: res.data.StockInvoice.Site,
            Account: null,
            Debtor: null,
            Vehicle: null,
            PurchaseOrder: null,
            type: 'Account',
            documentReference: res.data.StockInvoice.documentReference,
            documentDate: dayjs(res.data.StockInvoice.invoiceDate),
            dueDate: dayjs(res.data.StockInvoice.dueDate),
            GSTtype: 'N/T',
            documentTotal: res.data.StockInvoice.documentTotal,
            StockInvoiceId: res.data.StockInvoice.id
        });
        setInvoiceLines(res.data.StockInvoice.orderLines);
        setDisabled(true);
    });
}

export default GetCreditInvoice;
