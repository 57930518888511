// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
import ValidateUserFields from '../../../global/logic/generalValidation/ValidateUserFields';
// INTERFACES
import { User } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleEditUser = (
    userDetails: User,
    responseCode: number,
    setLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setLoading(true);
    let userFieldsValidated = ValidateUserFields(
        userDetails,
        responseCode,
        showSnackbar
    );
    if (userFieldsValidated) {
        api.put(`updateUser/${userDetails.id}`, userDetails).then((res) => {
            if (res.status === 200) {
                showSnackbar('User updated.', 'The page will now refresh.');
                setTimeout(() => window.location.reload(), 1500);
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
                setLoading(false);
            }
        });
    } else {
        setLoading(false);
    }
};

export default HandleEditUser;
