// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Typography,
    DialogTitle,
    Grid,
    CircularProgress,
    Divider,
    Checkbox,
    FormControlLabel,
    DialogContent,
    Button,
    DialogActions,
    TextField,
    MenuItem,
    useTheme
} from '@mui/material';
// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
// LOGIC
import GetAllUsers from '../../../global/databaseLogic/GetAllUsers';
import UpdateUserPermissions from '../logic/UpdateUserPermissions';
import RoleCheck from '../../../global/RoleCheck';
import GetAllPermissions from '../../../global/databaseLogic/GetAllPermissions';
import GetAllUserPermissions from '../../../global/databaseLogic/GetAllUserPermissions';
// INTERFACES
import { User } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import GetUser from '../../../global/databaseLogic/GetUser';
import {
    PartsDefaultPermissions,
    SalesDefaultPermissions,
    ServiceDefaultPermissions,
    AdminDefaultPermissions
} from '../logic/PermissionTemplates';

interface PermissionDialogContentProps {
    UserId: number;
    setPermissionDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}
const PermissionDialogContent = ({
    UserId,
    setPermissionDialogOpen,
    showSnackbar
}: PermissionDialogContentProps) => {
    const [currentUser, setCurrentUser] = useState<User>();
    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const [userPermissions, setUserPermissions] = useState([]);
    const [selectedUser, setSelectedUser] = useState<User>(null);
    const [selectedUserPermissions, setSelectedUserPermissions] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');

    useEffect(() => {
        GetAllUsers(setUsers);
        GetAllUserPermissions(UserId, setUserPermissions);
        GetAllPermissions(setAllPermissions);
        GetUser(0, setCurrentUser);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedUser?.id) {
            setUserPermissions(selectedUserPermissions);
        }
        // eslint-disable-next-line
    }, [selectedUserPermissions]);

    const handleCheckboxChange = (permission, checked) => {
        if (checked) {
            if (!userPermissions.includes(permission)) {
                let tempArray = JSON.parse(JSON.stringify(userPermissions));
                tempArray.push(permission);
                setUserPermissions(tempArray);
            }
        } else {
            let tempArray = JSON.parse(JSON.stringify(userPermissions));
            let perm_id = tempArray.indexOf(permission);
            tempArray.splice(perm_id, 1);
            setUserPermissions(tempArray);
        }
    };

    const handleTemplateChange = (template: string) => {
        if (template === 'parts') {
            PartsDefaultPermissions(setUserPermissions);
        }
        if (template === 'sales') {
            SalesDefaultPermissions(setUserPermissions);
        }
        if (template === 'service') {
            ServiceDefaultPermissions(setUserPermissions);
        }
        if (template === 'admin') {
            AdminDefaultPermissions(setUserPermissions);
        }
    };

    const handleCopyPermissions = (id) => {
        GetAllUserPermissions(id, setSelectedUserPermissions);
    };

    const currentMode = useTheme().palette.mode;

    return (
        <>
            <DialogTitle>User Permissions</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            size="small"
                            isDisabled={loading}
                            options={users}
                            useTwoOptionLabels={false}
                            primaryOptionLabel={'name'}
                            textfieldLabel="Copy Permissions From"
                            selectedValue={selectedUser}
                            handleSelectedValueChange={(newValue) => {
                                setSelectedUser(newValue);
                                handleCopyPermissions(newValue?.id);
                            }}
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            label="Template"
                            value={selectedTemplate}
                            onChange={(e) => {
                                handleTemplateChange(e.target.value);
                                setSelectedTemplate(e.target.value);
                            }}
                        >
                            <MenuItem value="parts">
                                Standard Parts User
                            </MenuItem>
                            <MenuItem value="sales">
                                Standard Sales User
                            </MenuItem>
                            <MenuItem value="service">
                                Standard Service User
                            </MenuItem>
                            <MenuItem value="admin">
                                Standard Admin User
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <br />

                <RoleCheck
                    permission="parts_manager, assign_superuser"
                    component={
                        <>
                            <Typography
                                variant="h6"
                                align="left"
                                display="inline"
                            >
                                Parts and Accessories
                            </Typography>
                            <br />
                            <Grid container spacing={0}>
                                {allPermissions.map((permission) => {
                                    if (
                                        permission.department === 'parts' &&
                                        permission.permission !==
                                            'parts_manager'
                                    ) {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    } else if (
                                        permission.permission ===
                                            'parts_manager' &&
                                        currentUser?.isGod
                                    ) {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Grid>
                            <br />
                            <Divider />
                            <br />
                        </>
                    }
                />

                <RoleCheck
                    permission="sales_manager, assign_superuser"
                    component={
                        <>
                            <Typography
                                variant="h6"
                                align="left"
                                display="inline"
                            >
                                Sales
                            </Typography>
                            <br />
                            <Grid container spacing={0}>
                                {allPermissions.map((permission) => {
                                    if (
                                        permission.department === 'vehicle' &&
                                        permission.permission !==
                                            'sales_manager'
                                    ) {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    } else if (
                                        permission.permission ===
                                            'sales_manager' &&
                                        currentUser?.isGod
                                    ) {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Grid>
                            <br />
                            <Divider />
                            <br />
                        </>
                    }
                />

                <RoleCheck
                    permission="service_manager, assign_superuser"
                    component={
                        <>
                            <Typography
                                variant="h6"
                                align="left"
                                display="inline"
                            >
                                Service
                            </Typography>
                            <br />
                            <Grid container spacing={0}>
                                {allPermissions.map((permission) => {
                                    if (
                                        permission.department === 'service' &&
                                        permission.permission !==
                                            'service_manager'
                                    ) {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    } else if (
                                        permission.permission ===
                                            'service_manager' &&
                                        currentUser?.isGod
                                    ) {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Grid>
                            <br />
                            <Divider />
                            <br />
                        </>
                    }
                />

                <RoleCheck
                    permission="admin_manager, assign_superuser"
                    component={
                        <>
                            <Typography
                                variant="h6"
                                align="left"
                                display="inline"
                            >
                                Admin
                            </Typography>
                            <br />
                            <Grid container spacing={0}>
                                {allPermissions.map((permission) => {
                                    if (
                                        permission.department === 'admin' &&
                                        permission.permission !==
                                            'admin_manager' &&
                                        permission.permission !==
                                            'assign_superuser'
                                    ) {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    }
                                    if (
                                        permission.permission ===
                                            'admin_manager' &&
                                        currentUser?.isGod
                                    ) {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Grid>
                            <br />
                            <Divider />
                            <br />
                        </>
                    }
                />

                <RoleCheck
                    permission="parts_manager, service_manager, sales_manager, admin_manager, assign_superuser"
                    component={
                        <>
                            <Typography
                                variant="h6"
                                align="left"
                                display="inline"
                            >
                                General
                            </Typography>
                            <br />
                            <Grid container spacing={0}>
                                {allPermissions.map((permission) => {
                                    if (permission.department === 'general') {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Grid>
                            <br />
                            <Divider />
                            <br />
                        </>
                    }
                />

                <RoleCheck
                    permission="it_manager"
                    component={
                        <>
                            <Typography
                                variant="h6"
                                align="left"
                                display="inline"
                            >
                                IT
                            </Typography>
                            <br />
                            <Grid container spacing={0}>
                                {allPermissions.map((permission) => {
                                    if (permission.department === 'IT') {
                                        return (
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={loading}
                                                            style={
                                                                currentMode ===
                                                                'dark'
                                                                    ? {
                                                                          color: '#8d65dd'
                                                                      }
                                                                    : {
                                                                          color: '#9370DB'
                                                                      }
                                                            }
                                                            onChange={(e) =>
                                                                handleCheckboxChange(
                                                                    permission.permission,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                            checked={userPermissions.includes(
                                                                permission.permission
                                                            )}
                                                        />
                                                    }
                                                    label={permission.label}
                                                />
                                            </Grid>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </Grid>
                        </>
                    }
                />
            </DialogContent>
            <DialogActions>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Button onClick={() => setPermissionDialogOpen(false)}>
                            Close
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                UpdateUserPermissions(
                                    UserId,
                                    userPermissions,
                                    showSnackbar,
                                    setLoading
                                )
                            }
                        >
                            Update Permissions
                        </Button>
                    </>
                )}
            </DialogActions>
        </>
    );
};

export default PermissionDialogContent;
