import { Site, Till } from '../../interfaces/GeneralInterface';
import { ShippingMethod } from '../../interfaces/PartsInterface';
import * as yup from 'yup';

//site validate schema
const siteSchema = yup.object().shape({
    code: yup
        .string()
        .required('Site code is required.\n')
        .length(3, 'Site code must contain exactly 3 characters.\n'),
    name: yup.string().required('Site name is required.\n'),
    SettingId: yup.number().required('A company must be selected.\n'),
    phoneNumber: yup
        .string()
        .required('Phone number is required.\n')
        .test('isValidPhoneNumber', 'Invalid phone number.\n', (value) => {
            if (!value) return false; // Check if value is provided
            const phoneNumber = value.replace(/\s/g, '').replace(/-/g, '');

            const pattern = phoneNumber.startsWith('+')
                ? /^\+\d{11}$/ // International format: + followed by 11 digits
                : /^\d{10}$/; // Local format: 10 digits

            return pattern.test(phoneNumber);
        }),
    email: yup
        .string()
        .required('Email is required.\n')
        .email('Invalid email address.\n'),
    address: yup.string().required('Address is required.\n'),
    suburb: yup.string().required('Suburb is required.\n'),
    postcode: yup
        .string()
        .required('Postcode is required.\n')
        .matches(/^\d{4}$/, 'Invalid Australian postcode.\n'),
    state: yup
        .string()
        .required('State is required.\n')
        .matches(/^[A-Za-z]{2,3}$/, 'State code must be 2-3 characters.\n'),
    country: yup
        .string()
        .required('Country code is required.\n')
        .matches(/^[A-Za-z]{2,3}$/, 'Country code must be 2-3 characters.\n'),
    vehicleTypeSold: yup.string().required('A type of vehicle must be selected.\n'),
    handlesOnlineOrders: yup.boolean(),
    sitePackingPriority: yup.number().when('handlesOnlineOrders', {
        is: true,
        then: (schema) =>
            schema.required(
                'Site Packing Priority is required for sites handling online orders.\n'
            ),
        otherwise: (schema) => schema.notRequired()
    }),
    netoWarehouseId: yup.number().when('handlesOnlineOrders', {
        is: true,
        then: (schema) =>
            schema.required(
                'Site Packing Priority is required for sites handling online orders.\n'
            ),
        otherwise: (schema) => schema.notRequired()
    })
});

//tills validate schema
const tillItemSchema = yup.object().shape({
    code: yup
        .string()
        .required('Till code is required.\n')
        .max(4, 'Till code cannot contain more than 4 characters.\n'),
    name: yup.string().required('Till name is required.\n')
});
const tillsSchema = yup
    .array()
    .of(tillItemSchema)
    .min(1, 'A site must have at least one till.\n')
    .test('unique-till-codes', 'Till code must be unique.\n', (tills) => {
        const codes = tills.map((till) => till.code);
        return new Set(codes).size === codes.length;
    });

// Combine the schemas for the complete validation
const validationSchema = yup.object().shape({
    site: siteSchema,
    tills: tillsSchema
});

const ValidateSiteFields = async (
    site: Site,
    tills: Till[],
    shippingMethods: ShippingMethod[]
) => {
    try {
        await validationSchema.validate(
            { site, tills, shippingMethods },
            { abortEarly: false }
        );
        return { verified: true, message: '', subMessage: '' };
    } catch (error) {
        return {
            verified: false,
            message: error.errors.join(' '),
            subMessage: 'Please correct the errors listed above.'
        };
    }
};

export default ValidateSiteFields;
