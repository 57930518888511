// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    DialogContentText,
    Grid,
    FormControlLabel,
    Switch
} from '@mui/material';

// INTERFACES
import { Filter } from '../../../global/interfaces/FilterInterface';
import { Site, User } from '../../../global/interfaces/GeneralInterface';
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';

// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import GetAllUsers from '../../../global/databaseLogic/GetAllUsers';

interface StockTransferTableFilterProps {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    isFilterActive?: () => any;
}

const StockTransferTableFilter = ({
    filter,
    setFilter,
    isFilterActive
}: StockTransferTableFilterProps) => {
    const statusOptions = [
        { id: 0, label: 'COMPLETE', value: 'COMPLETE' },
        { id: 1, label: 'INCOMPLETE', value: 'INCOMPLETE' },
        { id: 1, label: 'PENDING', value: 'PENDING' },
        { id: 2, label: 'CANCELLED', value: 'CANCELLED' }
    ];
    const [statusList, setStatusList] = useState(statusOptions);
    const [sites, setSites] = useState<Site[]>([]);
    const [siteFromList, setSiteFromList] = useState<Site[]>([]);
    const [siteToList, setSiteToList] = useState<Site[]>([]);
    const [userList, setUserList] = useState<User[]>([]);

    useEffect(() => {
        GetAllSites(setSites);
        GetAllSites(setSiteFromList);
        GetAllSites(setSiteToList);
        GetAllUsers(setUserList);
    }, []);

    // Handle updating the filter object
    const handleUpdateFilter = (property: any, value: any) => {
        let newFilter = filter.stockTransferFilter;
        newFilter[property] = value;
        setFilter({ ...filter, stockTransferFilter: newFilter });
    };

    return (
        <>
            <DialogContentText id="alert-dialog-description">
                If nothing specified in the filter all types will be included.
            </DialogContentText>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <AutocompleteMultiple
                        options={statusList}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'label'}
                        textfieldLabel="Status"
                        selectedValues={filter.stockTransferFilter.status}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = statusOptions;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setStatusList(currentOptions);
                            handleUpdateFilter('status', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteMultiple
                        options={siteFromList}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Transferred From"
                        selectedValues={filter.stockTransferFilter.siteFrom}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sites;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSiteFromList(currentOptions);
                            handleUpdateFilter('siteFrom', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={4}>
                    <AutocompleteMultiple
                        options={siteToList}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Transferred To"
                        selectedValues={filter.stockTransferFilter.siteTo}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = sites;
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                                currentOptions = currentOptions.filter(
                                    (x) => x.id !== val.id
                                );
                            });

                            setSiteToList(currentOptions);
                            handleUpdateFilter('siteTo', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>

                {/* Completed By Filter */}
                <Grid item xs={3}>
                    <AutocompleteMultiple
                        options={userList}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'username'}
                        textfieldLabel="Completed By User"
                        selectedValues={
                            filter.stockTransferFilter.completedUser
                        }
                        handleSelectedValueChange={(event) => {
                            let newFilterValue = [];

                            // Add or remove the value from the selected value
                            // Also add or remove the selected option from the options list so it can not be added more than once
                            event.forEach((val, index) => {
                                newFilterValue.push(val);
                            });

                            handleUpdateFilter('completedUser', newFilterValue);
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={filter.stockTransferFilter.hasOrders}
                                onChange={(e) => {
                                    handleUpdateFilter(
                                        'hasOrders',
                                        e.target.checked
                                    );
                                }}
                            />
                        }
                        label={'Has Orders'}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default StockTransferTableFilter;
