// IMPORTS
import { useState } from 'react';
import {
    Typography,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import {
    Add,
    FileDownload,
    CheckCircleOutline,
    CancelOutlined,
    FileUpload
} from '@mui/icons-material';

// COMPONENTS
import AccountTableFilter from './components/AccountTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import Drawer from '../../global/Drawer';
import AccountDrawerContent from './components/AccountDrawerContent';
import { AccountMergeDialogContent } from './components/AccountMergeDialogContent';
import { withSnackbar } from '../../global/WrappingSnackbar';
import { ImportAccountCSVContent } from './components/ImportAccountCSVContent';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import HandleDownloadResults from '../../global/logic/HandleDownloadResults';

// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { Account } from '../../global/interfaces/AdminInterface';
import dayjs from 'dayjs';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    accountFilter: {
        type: [],
        site: [],
        startDate: null,
        endDate: null,
        unreconciled: false
    }
};
const clearedSort = ['id', 'DESC'];

const AccountTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<Account[]>([]);

    // Drawer state
    const [accountDrawerOpen, setAccountDrawerOpen] = useState<boolean>(false);
    const [selectedAccount, setSelectedAccount] = useState<Account>();

    // Dialog state
    const [mergeDialogOpen, setMergeDialogOpen] = useState<boolean>(false);

    // CSV Import Dialog
    const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);

    // Boolean that check if there is at least one account in the DB
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Account',
            cypressLabel: 'addAccountFAB',
            onClick: () => {
                setAccountDrawerOpen(true);
                setSelectedAccount(null);
            }
        },
        {
            icon: <FileDownload />,
            name: 'Export Current Results',
            onClick: () => handleDownloadResults()
        },
        {
            icon: <FileUpload />,
            name: 'Import Account CSV',
            onClick: () => setImportDialogOpen(true)
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'Name', width: 300 },
        { id: 2, label: 'Type', name: 'type', sort: true, width: 200 },
        { id: 3, label: 'Category', width: 200 },
        { id: 4, label: 'Location', width: 200 },
        { id: 5, label: 'All Reconciled', width: 50 }
    ];

    // Handle downloading the csv
    const handleDownloadResults = () => {
        HandleDownloadResults(
            sort,
            'accountSearchNextGen',
            handleRequestCreate(),
            'OraAccountResults.csv',
            ['ID', 'Name', 'Type', 'Location'],
            ['id', 'name', 'type', 'Site.name']
        );
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let type =
            filter.accountFilter.type.length > 0
                ? ConvertArrayToFilter({
                      array: filter.accountFilter.type,
                      filterStart: '&type=',
                      selector: 'value'
                  })
                : '';
        let site =
            filter.accountFilter.site.length > 0
                ? ConvertArrayToFilter({
                      array: filter.accountFilter.site,
                      filterStart: '&site=',
                      selector: 'id',
                      useId: true
                  })
                : '';

        let startDate =
            filter.accountFilter.startDate !== null &&
            dayjs(filter.accountFilter.startDate).isValid()
                ? `&startDate=${dayjs(filter.accountFilter.startDate).toISOString()}`
                : '';
        let endDate =
            filter.accountFilter.endDate !== null &&
            dayjs(filter.accountFilter.endDate).isValid()
                ? `&endDate=${dayjs(filter.accountFilter.endDate).toISOString()}`
                : '';

        let unreconciled = filter.accountFilter.unreconciled
            ? `&hideReconciled=true`
            : '';

        let apiString = `${type}${site}${startDate}${endDate}${unreconciled}&checkEmpty=true`;

        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.accountFilter.type.length > 0 ||
            filter.accountFilter.site.length > 0 ||
            filter.accountFilter.startDate ||
            filter.accountFilter.endDate
        ) {
            isActive = true;
        }

        return isActive;
    };

    const isAllReconciled = (transactions) => {
        if (transactions.length === 0) {
            return false;
        } else {
            for (let transaction of transactions) {
                if (transaction.id.includes('_MT') && !transaction.reconciled) {
                    return false;
                } else if (
                    transaction.id.includes('_SI') &&
                    !transaction.reconciled
                ) {
                    return false;
                }
            }
        }
        return true;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Accounts</Typography>
            <br />
            <TableSearch
                searchTitle="Search Account"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="accountSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
                setIsEmpty={setIsEmpty}
            >
                <AccountTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                    cypressLabel={'accountDataTable'}
                >
                    {filter.accountFilter.unreconciled
                        ? resultsList?.map((row) =>
                              !isAllReconciled(row.transactions) ? (
                                  <TableRow
                                      key={row.id}
                                      data-cy={`accountTableRow-${row.name}`}
                                  >
                                      <DataCellColoured
                                          handleClick={() => {
                                              setSelectedAccount(row);
                                              setAccountDrawerOpen(true);
                                          }}
                                      >
                                          {row.id}
                                      </DataCellColoured>
                                      <DataCell>{row.name}</DataCell>
                                      <DataCell>
                                          {row.type === 'NOT VISIBLE'
                                              ? 'Static'
                                              : row.type}
                                      </DataCell>
                                      <DataCell>
                                          {row.AccountCategory?.name}
                                      </DataCell>
                                      <DataCell>{row?.Site?.name}</DataCell>
                                      <DataCell>
                                          {isAllReconciled(row.transactions) ? (
                                              <CheckCircleOutline
                                                  sx={{ color: 'green' }}
                                              />
                                          ) : row.transactions.length > 0 ? (
                                              <CancelOutlined
                                                  sx={{ color: 'red' }}
                                              />
                                          ) : null}
                                      </DataCell>
                                  </TableRow>
                              ) : null
                          )
                        : resultsList?.map((row) => (
                              <TableRow
                                  key={row.id}
                                  data-cy={`accountTableRow-${row.name}`}
                              >
                                  <DataCellColoured
                                      cypressLabel={`accountTableRowIdButton-${row.name}`}
                                      handleClick={() => {
                                          setSelectedAccount(row);
                                          setAccountDrawerOpen(true);
                                      }}
                                  >
                                      {row.id}
                                  </DataCellColoured>
                                  <DataCell
                                      cypressLabel={`accountTableRowName-${row.name}`}
                                  >
                                      {row.name}
                                  </DataCell>
                                  <DataCell
                                      cypressLabel={`accountTableRowType-${row.name}`}
                                  >
                                      {row.type === 'NOT VISIBLE'
                                          ? 'Static'
                                          : row.type}
                                  </DataCell>
                                  <DataCell
                                      cypressLabel={`accountTableRowCategory-${row.name}`}
                                  >
                                      {row.AccountCategory?.name}
                                  </DataCell>
                                  <DataCell>{row?.Site?.name}</DataCell>
                                  <DataCell>
                                      {isAllReconciled(row.transactions) ? (
                                          <CheckCircleOutline
                                              sx={{ color: 'green' }}
                                          />
                                      ) : row.transactions.length > 0 ? (
                                          <CancelOutlined
                                              sx={{ color: 'red' }}
                                          />
                                      ) : null}
                                  </DataCell>
                              </TableRow>
                          ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={accountDrawerOpen}
                setOpenDrawer={setAccountDrawerOpen}
                title={
                    selectedAccount?.id
                        ? `${selectedAccount.id} - ${selectedAccount.name}`
                        : 'New Account'
                }
                subTitle={
                    selectedAccount?.id
                        ? `Location: ${selectedAccount.SiteId ? selectedAccount?.Site?.name : 'All Sites'} | Type: ${selectedAccount.type === 'NOT VISIBLE' ? 'Static' : selectedAccount.type}`
                        : ''
                }
                width="50vw"
            >
                <AccountDrawerContent
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    resultsList={resultsList}
                    setResultsList={setResultsList}
                    setMergeDialogOpen={setMergeDialogOpen}
                />
            </Drawer>

            <Dialog
                open={mergeDialogOpen}
                onClose={() => setMergeDialogOpen(false)}
                maxWidth="md"
                fullWidth
                PaperProps={{ sx: { mb: '20vw' } }}
            >
                <AccountMergeDialogContent
                    selectedAccount={selectedAccount}
                    showSnackbar={showSnackbar}
                />
            </Dialog>

            <Dialog
                open={importDialogOpen}
                onClose={() => setImportDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <ImportAccountCSVContent showSnackbar={showSnackbar} />
            </Dialog>

            <Dialog
                open={isEmpty}
                onClose={() => setIsEmpty(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>No account created yet</DialogTitle>
                <DialogContent>
                    <Typography>
                        Ora couldn't find any accounts in the database. Do you
                        wish to import account(s) through a CSV?
                    </Typography>
                    <Typography>
                        You can also manually create account(s), simply close
                        this dialog and click on the pop-up menu in the bottom
                        right of the page and click on "Add Account".
                    </Typography>
                    <Typography variant="caption">
                        If you have already created account(s) and this dialog
                        opened by mistake, please contact the Ora development
                        team.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => setIsEmpty(false)}
                    >
                        Close Dialog
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsEmpty(false);
                            setImportDialogOpen(true);
                        }}
                    >
                        Open CSV Import Function
                    </Button>
                </DialogActions>
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(AccountTable);
