import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { OnlineOrder, Order } from '../../../global/interfaces/PartsInterface';

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
}

interface Column {
    id: number;
    label: string;
    width: number;
    name: string;
}

const GetOrderlineStock = (
    selectedOrder: GroupedOnlineOrder,
    setColumns: Dispatch<SetStateAction<Column[]>>,
    setPartLines
) => {
    api.put('getOrderlineStock', selectedOrder.oraOrder.orderLines).then(
        (res) => {
            let columns = [
                { id: 0, label: 'Part Number', width: 50, name: 'Part Number' }
            ];

            let idCounter = 1;

            res.data.allSites.forEach((site) => {
                if (site.id === localStorage.getItem('SiteId')) {
                    columns.push({
                        id: idCounter,
                        label: `${site.name} (Current)`,
                        name: site.name,
                        width: 50
                    });
                } else if (site.id === selectedOrder.oraOrder.SiteId) {
                    columns.push({
                        id: idCounter,
                        label: `${site.name} (Assigned)`,
                        name: site.name,
                        width: 50
                    });
                } else {
                    columns.push({
                        id: idCounter,
                        label: site.name,
                        name: site.name,
                        width: 50
                    });
                }

                idCounter += 1;
            });

            setColumns(columns);
            setPartLines(res.data.stockLines);
        }
    );
};

export default GetOrderlineStock;
