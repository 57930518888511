// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Divider,
    Grid,
    Table,
    TableRow,
    Typography,
    TableCell,
    IconButton,
    TextField
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';

interface ContactProps {
    creditorDetails: Creditor;
    setCreditorDetails: Dispatch<SetStateAction<Creditor>>;
    readOnly: boolean;
}

const Contact = ({
    creditorDetails,
    setCreditorDetails,
    readOnly
}: ContactProps) => {
    // Edit the details of a single contact detail
    // Type is whether it is the phone or email object you are editing
    // Selector is the key in that object, so either reference or value
    // Id is the id of the row being edited
    // Value is the new value typed in by the user
    const editContactDetail = (
        type: string,
        selector: string,
        id: number,
        value: string
    ) => {
        let currentContactDetails = creditorDetails.contactDetails;
        let index = currentContactDetails[type].findIndex((x) => x.id === id); // Find the index via ID
        currentContactDetails[type][index][selector] = value; // Set the new value
        setCreditorDetails({
            ...creditorDetails,
            contactDetails: currentContactDetails
        });
    };

    // Add a new line to a contact detail
    // Type is whether it is the phone or email object you are editing
    const addContactDetail = (type: string) => {
        let currentContactDetails = creditorDetails.contactDetails;
        let newId =
            currentContactDetails[type][currentContactDetails[type].length - 1]
                .id + 1; // Caluclate the new id (last id + 1)
        let newItem = {
            // New Object
            id: newId,
            reference: '',
            value: ''
        };
        currentContactDetails[type].push(newItem); // Push it to the array
        setCreditorDetails({
            ...creditorDetails,
            contactDetails: currentContactDetails
        });
    };

    // Delete a contact detail
    // Type is whether it is the phone or email object you are editing
    // Id is the id of the row being edited
    const deleteContactDetails = (type: string, id: number) => {
        let currentContactDetails = creditorDetails.contactDetails;
        let currentContactDetail = [...currentContactDetails[type]]; // Need to use spread operator to seperate everything
        currentContactDetail = currentContactDetail.filter((x) => x.id !== id); // Filter out the line
        currentContactDetails[type] = currentContactDetail;
        setCreditorDetails({
            ...creditorDetails,
            contactDetails: currentContactDetails
        });
    };

    // A component to create the table as it is the same for both phone and email, just the reference to the object changes
    // Type is whether it is the phone or email object you are editing
    // Title is the heading to display at the top of the table
    const ContactTable = (type: string, title: string) => {
        return (
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h6">{title}</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                        {!readOnly ? (
                            <IconButton onClick={() => addContactDetail(type)}>
                                <Add />
                            </IconButton>
                        ) : null}
                    </Grid>
                </Grid>
                <Divider />
                <Table>
                    {creditorDetails?.contactDetails[type]?.map((item) => (
                        <TableRow>
                            <TableCell width="25%">
                                {item.id === 1 || readOnly ? (
                                    item.reference
                                ) : (
                                    <TextField
                                        disabled={readOnly}
                                        fullWidth
                                        size="small"
                                        variant="standard"
                                        InputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        value={item?.reference}
                                        onChange={(e) =>
                                            editContactDetail(
                                                type,
                                                'reference',
                                                item.id,
                                                e.target.value
                                            )
                                        }
                                    />
                                )}
                            </TableCell>
                            <TableCell width="70%">
                                {readOnly ? (
                                    item.value
                                ) : (
                                    <TextField
                                        disabled={readOnly}
                                        fullWidth
                                        size="small"
                                        variant="standard"
                                        InputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        value={item?.value}
                                        onChange={(e) =>
                                            editContactDetail(
                                                type,
                                                'value',
                                                item.id,
                                                e.target.value
                                            )
                                        }
                                    />
                                )}
                            </TableCell>
                            {item.id === 1 || readOnly ? null : (
                                <TableCell width="5%" align="right">
                                    <IconButton
                                        onClick={() =>
                                            deleteContactDetails(type, item.id)
                                        }
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </Table>
            </Paper>
        );
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {ContactTable('phone', 'Phone Numbers')}
            </Grid>
            <Grid item xs={6}>
                {ContactTable('email', 'Email Addresses')}
            </Grid>
        </Grid>
    );
};

export default Contact;
