// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TableRow, TextField, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'dayjs/locale/en-gb';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import Paper from '../../../global/Paper';
import Autocomplete from '../../../global/Autocomplete';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import TotalColumn from '../logic/TotalColumn';
import RemoveLine from '../logic/RemoveLine';
import GetAllAccounts from '../../../global/databaseLogic/GetAllAccounts';
// INTERFACES
import { GeneralJournalLineTemp } from '../GeneralJournalUI';
import { Account } from '../../../global/interfaces/AdminInterface';

interface GeneralEntryTableProps {
    id: number;
    entryLines: GeneralJournalLineTemp[];
    setEntryLines: Dispatch<SetStateAction<GeneralJournalLineTemp[]>>;
    reference: string;
    setReference: Dispatch<SetStateAction<string>>;
    readOnly: boolean;
}

export default function GeneralEntryTable(props: GeneralEntryTableProps) {
    const [accountList, setAccountList] = useState<Account[]>([]);

    useEffect(() => {
        GetAllAccounts(setAccountList);
    }, []);

    var columns = [
        { id: 1, label: 'Entity', width: 250 },
        { id: 2, label: 'Description', width: 250 },
        { id: 3, label: 'Date', width: 150 },
        { id: 4, label: 'Debit', width: 100 },
        { id: 5, label: 'Credit', width: 100 },
        { id: 6, label: 'GST Type', width: 100 },
        { id: 8, label: '', width: 50 }
    ];

    const updateEntry = (index: number, newValue: any, field: string) => {
        let currentRows = [...props.entryLines];
        if (field === 'account') {
            currentRows[index].Account = newValue;
            currentRows[index].AccountId = newValue.id;
        } else if (field === 'description') {
            currentRows[index].description = newValue;
        }

        props.setEntryLines(currentRows);
    };

    return (
        <Paper>
            <DataTable columns={columns}>
                {props.entryLines.map((row, index) => (
                    <TableRow key={row.id}>
                        {!props.readOnly ? (
                            <DataCell>
                                <Autocomplete
                                    options={accountList}
                                    useTwoOptionLabels={true}
                                    primaryOptionLabel={'name'}
                                    secondaryOptionLabel={'Site.name'}
                                    textfieldLabel={'Account'}
                                    selectedValue={row.Account}
                                    size="small"
                                    handleSelectedValueChange={(newValue) =>
                                        updateEntry(index, newValue, 'account')
                                    }
                                    handleInputValueChange={() => null}
                                />
                            </DataCell>
                        ) : (
                            <DataCell>{row.Account.name}</DataCell>
                        )}
                        {!props.readOnly ? (
                            <DataCell>
                                <TextField
                                    size="small"
                                    value={row.description}
                                    error={row.descriptionError}
                                    helperText={
                                        row.descriptionError
                                            ? 'Description is required.'
                                            : null
                                    }
                                    onChange={(newValue) =>
                                        updateEntry(
                                            index,
                                            newValue.target.value,
                                            'description'
                                        )
                                    }
                                />
                            </DataCell>
                        ) : (
                            <DataCell>{row.description}</DataCell>
                        )}
                        <DataCell>{DateFormatter(row.dateEntered)}</DataCell>

                        <DataCell>{CurrencyFormatter(row.debit)}</DataCell>
                        <DataCell>{CurrencyFormatter(row.credit)}</DataCell>
                        <DataCell>{row.gstType}</DataCell>
                        <DataCell>
                            {props.readOnly ? null : (
                                <IconButton
                                    onClick={() =>
                                        RemoveLine(
                                            props.entryLines,
                                            props.setEntryLines,
                                            row
                                        )
                                    }
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </DataCell>
                    </TableRow>
                ))}
                {props.entryLines.length > 0 ? (
                    <TableRow>
                        <DataCell colSpan={2}>
                            {props.readOnly ? (
                                <Typography>
                                    Reference: {props.reference}
                                </Typography>
                            ) : (
                                <TextField
                                    label="Reference"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    fullWidth
                                    value={props.reference}
                                    onChange={(e) =>
                                        props.setReference(e.target.value)
                                    }
                                />
                            )}
                        </DataCell>
                        <DataCell>
                            <b>Total</b>
                        </DataCell>
                        <DataCell>
                            <b>
                                {CurrencyFormatter(
                                    TotalColumn(props.entryLines, 'debit')
                                )}
                            </b>
                        </DataCell>
                        <DataCell>
                            <b>
                                {CurrencyFormatter(
                                    TotalColumn(props.entryLines, 'credit')
                                )}
                            </b>
                        </DataCell>

                        <DataCell colSpan={3}></DataCell>
                    </TableRow>
                ) : null}
            </DataTable>
        </Paper>
    );
}
