// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Drawer,
    Box,
    TextField,
    Paper,
    MenuItem,
    Grid,
    Table,
    TableRow,
    TableCell,
    Typography,
    TableHead
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

import {
    DateFormatter,
    CurrencyFormatter
} from '../../../../global/logic/Formatters';

// LOGIC
import CalculateTrialBalance from './logic/CalculateTrialBalance';
import GetAllSites from '../../../../global/databaseLogic/GetAllSites';
import dayjs, { Dayjs } from 'dayjs';
import { Site } from '../../../../global/interfaces/GeneralInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import {
    CalculatedAccount,
    OraAccount
} from './interface/TrialBalanceInterfaces';

interface TrialBalanceProps {
    (props: {
        loading: boolean;
        setLoading: Dispatch<SetStateAction<boolean>>;
    }): JSX.Element;
}

const TrialBalance: TrialBalanceProps = (props) => {
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState<Dayjs>(dayjs());
    const [selectedSite, setSelectedSite] = useState<string | Site>(
        'All Sites'
    );
    const [sites, setSites] = useState<Site[]>([]);

    const [trialBalanceData, setTrialBalanceData] = useState<{
        oraAccount: OraAccount[];
        calculatedAccount: CalculatedAccount[];
    }>({ oraAccount: [], calculatedAccount: [] });
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [drawerTitle, setDrawerTitle] = useState<string>('');
    const [drawerSubtitle, setDrawerSubtitle] = useState<string>('');
    const [drawerContent, setDrawerContent] = useState<any[]>([]);

    useEffect(() => {
        GetAllSites(setSites);
    }, []);

    const getTotal = (type: string) => {
        let total = 0;
        if (type === 'Expense') {
            for (let line of trialBalanceData.calculatedAccount) {
                if (line.expenseTotal) {
                    total += line.expenseTotal;
                }
            }
            for (let line of trialBalanceData.oraAccount) {
                if (line.assetType === type) {
                    total += line.total;
                }
            }
        } else {
            for (let line of trialBalanceData.calculatedAccount) {
                if (line.revenueTotal) {
                    total += line.revenueTotal;
                }
            }
            for (let line of trialBalanceData.oraAccount) {
                if (line.assetType === type) {
                    total += line.total;
                }
            }
        }

        return total;
    };

    const handleChangeSite = (value: string | number) => {
        if (value === 'All Sites') {
            setSelectedSite(value);
        } else {
            for (let site of sites) {
                if (site.id === value) {
                    setSelectedSite(site);
                }
            }
        }
    };

    const getTotalAddedCosts = (addedCosts) => {
        let total = 0;
        for (let cost of addedCosts) {
            total += cost.totalCost
                ? cost.totalCost
                : cost.costPriceAverage
                  ? cost.quantity * cost.costPriceAverage
                  : cost.quantity * cost.costPriceDaily;
        }
        return CurrencyFormatter(total);
    };

    const handleDrawerOpen = (line: CalculatedAccount) => {
        setDrawerTitle(line.name);
        setDrawerSubtitle(
            DateFormatter(startDate.toISOString()) +
                ' - ' +
                DateFormatter(endDate.toISOString())
        );
        setDrawerContent(line.transactions);
        setOpenDrawer(true);
    };

    return (
        <>
            <Paper className="paper-padding">
                <Grid container spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid item xs={2}>
                            <DatePicker
                                label="Start Date"
                                format="DD/MM/YYYY"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        variant: 'outlined',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                label="Start Date"
                                format="DD/MM/YYYY"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        variant: 'outlined',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={2}>
                        <TextField
                            select
                            fullWidth
                            label="Site"
                            value={
                                typeof selectedSite === 'string'
                                    ? selectedSite
                                    : selectedSite.id
                            }
                            onChange={(e) => handleChangeSite(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        >
                            {sites.map((site) => (
                                <MenuItem value={site.id}>{site.name}</MenuItem>
                            ))}
                            <MenuItem value="All Sites">All Sites</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3} textAlign="right">
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={props.loading}
                            onClick={() => {
                                CalculateTrialBalance(
                                    startDate,
                                    endDate,
                                    selectedSite,
                                    setTrialBalanceData,
                                    props.setLoading
                                );
                            }}
                        >
                            Generate Report
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Paper>
                <Table size="small">
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                <b>Expense</b>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" align="center">
                                <b>Revenue</b>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {trialBalanceData.calculatedAccount.map((line) =>
                        line.expenseTotal ||
                        line.revenueTotal ||
                        line.expenseTotal === 0 ||
                        line.revenueTotal === 0 ? (
                            <TableRow>
                                {line?.transactions?.length > 0 ? (
                                    <TableCell
                                        id="table-link"
                                        width="40%"
                                        onClick={() => {
                                            handleDrawerOpen(line);
                                        }}
                                    >
                                        <Typography align="center">
                                            {line.name}
                                        </Typography>
                                    </TableCell>
                                ) : (
                                    <TableCell width="40%">
                                        <Typography align="center">
                                            {line.name}
                                        </Typography>
                                    </TableCell>
                                )}
                                <TableCell width="35%">
                                    <Typography align="center">
                                        {CurrencyFormatter(line?.expenseTotal)}
                                    </Typography>
                                </TableCell>
                                <TableCell width="35%">
                                    <Typography align="center">
                                        {CurrencyFormatter(line?.revenueTotal)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : line.name === 'blank' ? (
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell>
                                    <Typography align="center">
                                        {line.name}
                                    </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Typography align="center">
                                        {CurrencyFormatter(line.amount)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    {trialBalanceData.oraAccount.map((line) => (
                        <TableRow>
                            <TableCell>
                                <Typography align="center">
                                    {line.accountName} - {line.assetType}
                                </Typography>
                            </TableCell>

                            <TableCell>
                                <Typography align="center">
                                    {line.assetType === 'Expense'
                                        ? CurrencyFormatter(line.total)
                                        : ''}
                                </Typography>
                            </TableCell>

                            <TableCell>
                                <Typography align="center">
                                    {line.assetType === 'Revenue'
                                        ? CurrencyFormatter(line.total)
                                        : ''}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell>
                            <Typography align="center">TOTAL</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography align="center">
                                {CurrencyFormatter(getTotal('Expense'))}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography align="center">
                                {CurrencyFormatter(
                                    Math.abs(getTotal('Revenue'))
                                )}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </Paper>
            <Drawer
                anchor={'right'}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                style={{ zIndex: 1200 }}
            >
                <Box
                    sx={{ width: '35vw', padding: '20px' }}
                    role="presentation"
                >
                    <Typography variant="h4">{drawerTitle}</Typography>
                    <Typography>{drawerSubtitle}</Typography>
                    <br />
                    <Table size="small">
                        {/* TABLE HEADER */}
                        <TableHead>
                            <TableRow>
                                {drawerTitle === 'Order Total Revenue' ? (
                                    <>
                                        <TableCell width="15%" align="center">
                                            <strong>Order ID</strong>
                                        </TableCell>
                                        <TableCell width="35%" align="center">
                                            <strong>Total Revenue</strong>
                                        </TableCell>
                                    </>
                                ) : null}
                                {drawerTitle === 'Stock Order Total Cost' ? (
                                    <>
                                        <TableCell width="30%">
                                            <strong>Stock Invoice ID</strong>
                                        </TableCell>
                                        <TableCell width="25%" align="center">
                                            <strong>Stock Order ID</strong>
                                        </TableCell>
                                        <TableCell width="25%" align="center">
                                            <strong>Nb of Parts</strong>
                                        </TableCell>
                                        <TableCell width="20%" align="center">
                                            <strong>Total Cost</strong>
                                        </TableCell>
                                    </>
                                ) : null}
                                {drawerTitle ===
                                'Vehicle Sale Total Revenue' ? (
                                    <>
                                        <TableCell width="20%">
                                            <strong>Vehicle Sale ID</strong>
                                        </TableCell>
                                        <TableCell width="30%" align="center">
                                            <strong>
                                                Vehicle Stock Number
                                            </strong>
                                        </TableCell>
                                        <TableCell width="25%" align="center">
                                            <strong>Total Revenue</strong>
                                        </TableCell>
                                    </>
                                ) : drawerTitle ===
                                  'Vehicle Sale Total Warranty Cost' ? (
                                    <>
                                        <TableCell width="20%">
                                            <strong>Vehicle Sale ID</strong>
                                        </TableCell>
                                        <TableCell width="30%" align="center">
                                            <strong>
                                                Vehicle Stock Number
                                            </strong>
                                        </TableCell>
                                        <TableCell width="25%" align="center">
                                            <strong>Total Revenue</strong>
                                        </TableCell>
                                    </>
                                ) : drawerTitle === 'Trade-Ins Total Cost' ? (
                                    <>
                                        <TableCell width="20%">
                                            <strong>
                                                Vehicle Sale ID / Trade-In ID
                                            </strong>
                                        </TableCell>
                                        <TableCell width="30%" align="center">
                                            <strong>
                                                Vehicle Stock Number
                                            </strong>
                                        </TableCell>
                                        <TableCell width="25%" align="center">
                                            <strong>Total Cost</strong>
                                        </TableCell>
                                    </>
                                ) : drawerTitle ===
                                  'Vehicle Invoice Total Cost' ? (
                                    <>
                                        <TableCell width="20%">
                                            <strong>Invoice ID</strong>
                                        </TableCell>
                                        <TableCell width="30%" align="center">
                                            <strong>Document Reference</strong>
                                        </TableCell>
                                        <TableCell width="25%" align="center">
                                            <strong>Total Cost</strong>
                                        </TableCell>
                                    </>
                                ) : drawerTitle === 'Service Job Revenue' ? (
                                    <>
                                        <TableCell width="20%">
                                            <strong>Service ID</strong>
                                        </TableCell>
                                        <TableCell width="30%" align="center">
                                            <strong>Service Type</strong>
                                        </TableCell>
                                        <TableCell width="25%" align="center">
                                            <strong>Total Revenue</strong>
                                        </TableCell>
                                    </>
                                ) : drawerTitle === 'Service Added Costs' ? (
                                    <>
                                        <TableCell width="20%">
                                            <strong>Service ID</strong>
                                        </TableCell>
                                        <TableCell width="30%" align="center">
                                            <strong>Service Type</strong>
                                        </TableCell>
                                        <TableCell width="25%" align="center">
                                            <strong>Total Cost</strong>
                                        </TableCell>
                                    </>
                                ) : drawerTitle === 'Sublets Total Cost' ? (
                                    <>
                                        <TableCell width="20%">
                                            <strong>Sublet Invoice ID</strong>
                                        </TableCell>
                                        <TableCell width="30%" align="center">
                                            <strong>Service Id</strong>
                                        </TableCell>
                                        <TableCell width="25%" align="center">
                                            <strong>Total Cost</strong>
                                        </TableCell>
                                    </>
                                ) : null}
                            </TableRow>
                        </TableHead>

                        {/* TABLE */}
                        {drawerContent.map((line) =>
                            drawerTitle === 'Order Total Revenue' ? (
                                <>
                                    <TableRow>
                                        <TableCell
                                            id="table-link"
                                            width="15%"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    '/inventory/viewSale/' +
                                                        line.id,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.id}
                                        </TableCell>
                                        <TableCell width="35%" align="center">
                                            {CurrencyFormatter(line.amountPaid)}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : drawerTitle === 'Stock Order Total Cost' ? (
                                <>
                                    <TableRow>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'inventory/viewInvoice/' +
                                                        line.id,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {line.StockOrderId}
                                        </TableCell>
                                        <TableCell align="center">
                                            {line.orderLines.length}
                                        </TableCell>
                                        <TableCell align="center">
                                            {CurrencyFormatter(
                                                line.documentTotal
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : drawerTitle === 'Vehicle Sale Total Revenue' ? (
                                <>
                                    <TableRow>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'vehicles/sale/' + line.id,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.id}
                                        </TableCell>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'vehicles/view/' +
                                                        line.VehicleId,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.Vehicle.stockNumber}
                                        </TableCell>
                                        <TableCell align="center">
                                            {CurrencyFormatter(
                                                line.vehicleSalePrice
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : drawerTitle ===
                              'Vehicle Sale Total Warranty Cost' ? (
                                <>
                                    <TableRow>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'vehicles/sale/' + line.id,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.id}
                                        </TableCell>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'vehicles/view/' +
                                                        line.VehicleId,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.Vehicle.stockNumber}
                                        </TableCell>
                                        <TableCell align="center">
                                            {CurrencyFormatter(
                                                line.warrantyCost
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : drawerTitle === 'Trade-Ins Total Cost' ? (
                                <>
                                    <TableRow>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    line.VehicleSaleId
                                                        ? 'vehicles/sale/' +
                                                              line.VehicleSaleId
                                                        : 'vehicles/viewTradeIn/' +
                                                              line.id,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.VehicleSaleId
                                                ? line.VehicleSaleId
                                                : line.id}
                                        </TableCell>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'vehicles/view/' +
                                                        line.VehicleId,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.Vehicle.stockNumber}
                                        </TableCell>
                                        <TableCell align="center">
                                            {CurrencyFormatter(
                                                line.tradeInPrice
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : drawerTitle === 'Vehicle Invoice Total Cost' ? (
                                <>
                                    <TableRow>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'inventory/viewInvoice/' +
                                                        line.id,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {line.documentReference}
                                        </TableCell>
                                        <TableCell align="center">
                                            {CurrencyFormatter(
                                                line.documentTotal
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : drawerTitle === 'Service Job Revenue' ? (
                                <>
                                    <TableRow>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'service/viewBooking/' +
                                                        line.id,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {line.jobType}
                                        </TableCell>
                                        <TableCell align="center">
                                            {CurrencyFormatter(
                                                line.totalPayment
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : drawerTitle === 'Service Added Costs' ? (
                                <>
                                    <TableRow>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'service/viewBooking/' +
                                                        line.id,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {line.jobType}
                                        </TableCell>
                                        <TableCell align="center">
                                            {getTotalAddedCosts(
                                                line.addedCosts
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : drawerTitle === 'Sublets Total Cost' ? (
                                <>
                                    <TableRow>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'inventory/viewInvoice/' +
                                                        line.invoiceId,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.id}
                                        </TableCell>
                                        <TableCell
                                            id="table-link"
                                            align="center"
                                            onClick={() =>
                                                window.open(
                                                    'service/viewBooking/' +
                                                        line.ServiceId,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.ServiceId}
                                        </TableCell>
                                        <TableCell align="center">
                                            {CurrencyFormatter(
                                                line.documentTotal
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : null
                        )}
                    </Table>
                </Box>
            </Drawer>
        </>
    );
};

export default TrialBalance;
