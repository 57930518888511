import api from '../../../../api';

const SetupOra = (params) => {
    if (params.companyLogo.method === 'url') {
        api.post('/uploadCompanyLogoURL', {
            url: params.settings.logoUrl
        }).then((res) => {
            if (res.status === 200) {
                api.post('setupOra?key=companyLogo', params).then((res) => {
                    if (res.status === 200) {
                        window.location = '/login';
                    }
                });
            } else {
                params.showSnackbar(
                    'Something went wrong with the Company Logo PNG',
                    'Please try to upload the file again, or try from an url.',
                    'error'
                );
                params.setLoading(false);
            }
        });
    } else {
        const form = new FormData();
        form.append('image', params.companyLogo.pictureAsFile, 'companyLogo');
        api.post('/uploadCompanyLogoPNG', form).then((res) => {
            if (res.status === 200) {
                api.post('setupOra?key=companyLogo', params).then((res) => {
                    if (res.status === 200) {
                        window.location = '/login';
                    }
                });
            } else {
                params.showSnackbar(
                    'Something went wrong with the Company Logo URL',
                    'Please try to upload the file again, or try by uploading a file.',
                    'error'
                );
                params.setLoading(false);
            }
        });
    }
};

export default SetupOra;
