import * as yup from 'yup';
import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../OraNextGen/global/interfaces/GlobalInterface';

let userError = {
    firstName: {
        error: false,
        message: ''
    },
    lastName: {
        error: false,
        message: ''
    },
    username: {
        error: false,
        message: ''
    },
    password: {
        error: false,
        message: ''
    },
    email: {
        error: false,
        message: ''
    }
};

//site validate schema
const userSchema = yup.object().shape({
    firstName: yup.string().required(() => {
        userError = {
            ...userError,
            firstName: {
                error: true,
                message: 'A first name is required.'
            }
        };
        return 'A first name is required.';
    }),
    lastName: yup.string().required(() => {
        userError = {
            ...userError,
            lastName: {
                error: true,
                message: 'A last name is required.'
            }
        };
        return 'A last name is required.';
    }),
    username: yup.string().required(() => {
        userError = {
            ...userError,
            username: {
                error: true,
                message: 'A username is required.'
            }
        };
        return 'A username is required.';
    }),
    password: yup.string().required(() => {
        userError = {
            ...userError,
            password: {
                error: true,
                message: 'A password is required'
            }
        };
        return 'A password is required.';
    }),
    email: yup
        .string()
        .required(() => {
            userError = {
                ...userError,
                email: {
                    error: true,
                    message: 'An email address is required.'
                }
            };
            return 'An email address is required.';
        })
        .email('Email format is incorrect')
});

// Combine the schemas for the complete validation
const validationSchema = yup.object().shape({
    user: userSchema
});

const ValidateStep4 = async (
    user: any,
    setActiveStep: Dispatch<SetStateAction<number>>,
    setStepValidation,
    showSnackbar: showSnackbar
) => {
    try {
        await validationSchema.validate({ user }, { abortEarly: false });
        setActiveStep(4);
    } catch (error) {
        setStepValidation(userError);
        showSnackbar(
            error.errors.join('\n'),
            'Please correct the errors listed above.',
            'error'
        );
        return {
            verified: false,
            message: error.errors.join(' '),
            subMessage: 'Please correct the errors listed above.'
        };
    }
};

export default ValidateStep4;
