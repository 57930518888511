import Papa from 'papaparse';
let XLSX = require('xlsx');

const ImportSupplierFile = (e, setImportData) => {
    let xlsxString =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    let csvString = 'text/csv';

    const readUploadFile = async (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const csv = XLSX.utils.sheet_to_csv(worksheet);
                parseCSV(csv);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    const parseCSV = (e) => {
        Papa.parse(e, {
            header: false,
            skipEmptyLines: 'greedy',
            complete: (results) => {
                var newData = [];
                results.data.forEach((row, i) => {
                    var newItem = {};
                    for (var j = 0; j < row.length; j++) {
                        newItem[j] = row[j];
                    }
                    newData.push(newItem);
                });
                setImportData(newData);
            }
        });
    };

    if (e.target.files[0].type === xlsxString) {
        readUploadFile(e);
    } else if (e.target.files[0].type === csvString) {
        parseCSV(e.target.files[0]);
    } else {
        return null;
    }
};

export default ImportSupplierFile;
