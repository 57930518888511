// IMPORTS
import { Dispatch, SetStateAction } from 'react';

// LOGIC
import api from '../../../../api';
import { Model } from '../interfaces/VehicleSaleInterface';

/**
 * GetModels
 * Get all models from the server and set them to the given React state
 * @author Pierre
 * @param setModels the state you wish to save the models array to
 * @returns all the models from the database as a JSON object
 */
const GetModels = (setModels: Dispatch<SetStateAction<Model[]>>) => {
  api.get('allModels').then((res) => {
    setModels(res.data);
  });
};

export default GetModels;
