// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import ValidateCreditorFields from '../../../global/logic/generalValidation/ValidateCreditorFields';
import api from '../../../../../api';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

// Handles validating and adding a new creditor
const HandleAddCreditor = (
    creditorDetails: Creditor,
    responseCode: number,
    showSnackbar: showSnackbar,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setReadOnly: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    setReadOnly(true);

    // Validate the data
    let validated = ValidateCreditorFields(
        creditorDetails,
        responseCode,
        showSnackbar
    );

    if (validated) {
        // Do some formatting for backend
        creditorDetails.code = creditorDetails.code.toUpperCase();
        creditorDetails.name = creditorDetails.name.toUpperCase();
        creditorDetails.suburb = creditorDetails.suburb
            ? creditorDetails.suburb.toUpperCase()
            : null;
        creditorDetails.state = creditorDetails.state
            ? creditorDetails.state.toUpperCase()
            : null;
        creditorDetails.country = creditorDetails.country
            ? creditorDetails.country.toUpperCase()
            : null;
        creditorDetails.type = !creditorDetails.type
            ? 'Standard'
            : creditorDetails.type;

        // Now send data to backend to update the database
        api.post(`addNewCreditor/`, creditorDetails).then((res) => {
            if (res.status === 200) {
                showSnackbar('Creditor successfully added.');
                window.location.href = '/viewCreditor/' + res.data;
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            }
        });
    } else {
        setLoading(false);
        setReadOnly(false);
    }
};

export default HandleAddCreditor;
