// IMPORTS
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import {
    Button,
    Checkbox,
    Typography,
    Grid,
    FormControl,
    FormControlLabel,
    FormGroup,
    CircularProgress
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
// COMPONENTS
import Paper from '../../../../global/Paper';
import SiteSelector from '../../../../global/SiteSelector';
import PartSearch from '../../../../global/PartSearch';
import CreditorSelector from '../../../../global/CreditorSelector';
import SpeedDial from '../../../../global/SpeedDial';
// LOGIC
import AddPartToStockOrder from '../../logic/AddPartToStockOrder';
import CalculateStockOrderTotalCost from '../../logic/CalculateStockOrderTotalCost';
import AddBackorderToStockOrder from '../../logic/AddBackorderToStockOrder';
import api from '../../../../../../api';
// INTERFACE
import { Table } from '../../interfaces/StockOrderInterface';
interface OrderLineHeaderProps {
    clicked: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setOrderOpen: Dispatch<SetStateAction<boolean>>;
    selectedSite: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
    orderType: string[];
    setOrderType: Dispatch<SetStateAction<string[]>>;
    tables: Table[];
    setTables: Dispatch<SetStateAction<Table[]>>;
    selectedSupplier: number;
    setSelectedSupplier: Dispatch<SetStateAction<number>>;
    selectedSupplierError: boolean;
    setSelectedSupplierError: Dispatch<SetStateAction<boolean>>;
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>;
}

const OrderLineHeader = (props: OrderLineHeaderProps) => {
    const [backorderLines, setBackorderLines] = useState([]);
    const [backorderIsLoading, setBackorderIsLoading] = useState(false);
    const [importedSupplier, setImportedSupplier] = useState([]);

    const actions = [
        {
            id: 0,
            icon: <Add />,
            name: 'Generate Stock Order',
            onClick: () => {
                props.clicked ? props.setOpen(true) : props.setOrderOpen(true);
            }
        }
    ];

    useEffect(() => {
        AddBackorderToStockOrder(backorderLines, props.tables, props.setTables);
        // eslint-disable-next-line
    }, [backorderLines]);

    const handleAddPartToOrder = (part, selectedSupplier) => {
        AddPartToStockOrder(
            part,
            selectedSupplier,
            props.tables,
            props.setTables,
            props.orderType.toString()
        );
    };

    const handleOrderTypeChange = (e) => {
        let orderTypeArray = JSON.parse(JSON.stringify(props.orderType));
        if (orderTypeArray.includes(e.target.value)) {
            orderTypeArray = orderTypeArray.filter((x) => x !== e.target.value);
        } else {
            orderTypeArray.push(e.target.value);
        }
        props.setOrderType(orderTypeArray);
    };

    const handleImportBackorders = () => {
        setBackorderIsLoading(true);
        api.get(
            `generateBackorderStockOrder?site=${props.selectedSite}&OrderType=${props.orderType.toString()}${props?.selectedSupplier ? '&Supplier=' + props?.selectedSupplier : ''}`
        ).then((res) => {
            setImportedSupplier([...importedSupplier, props?.selectedSupplier]);
            setBackorderLines(res.data); // Trigger the useEffect of this component, and therefore the addBackorderToOrder function
            setBackorderIsLoading(false);
        });
        props.setShouldBlockNavigation(true);
    };

    return (
        <Paper textAlign="center">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <PartSearch
                        HandleAdd={handleAddPartToOrder}
                        size="small"
                        checkBackorders={false}
                        isDisabled={backorderIsLoading}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SiteSelector
                        disabled={backorderIsLoading || props.tables.length > 0}
                        variant="outlined"
                        label="Select Site"
                        size="small"
                        selectedSite={props.selectedSite}
                        setSelectedSite={props.setSelectedSite}
                        fullWidth={true}
                        viewPartUI={false}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CreditorSelector
                        isDisabled={backorderIsLoading}
                        selectedSupplier={props.selectedSupplier}
                        setSelectedSupplier={props.setSelectedSupplier}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={{ paddingTop: 1 }}>
                        <b>Order Total Cost:</b>&nbsp;&nbsp;&nbsp;&nbsp;
                        {CurrencyFormatter(
                            CalculateStockOrderTotalCost(props.tables)
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl>
                        <FormGroup row>
                            <Typography display="inline" sx={{ paddingTop: 1 }}>
                                <b>Order Type:</b>&nbsp;&nbsp;
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={backorderIsLoading}
                                        checked={props.orderType.includes(
                                            'Daily'
                                        )}
                                        onChange={(e) => {
                                            handleOrderTypeChange(e);
                                        }}
                                        value="Daily"
                                    />
                                }
                                label="Daily"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={backorderIsLoading}
                                        checked={props.orderType.includes(
                                            'Stock'
                                        )}
                                        onChange={(e) => {
                                            handleOrderTypeChange(e);
                                        }}
                                        value="Stock"
                                    />
                                }
                                label="Stock"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={backorderIsLoading}
                                        checked={props.orderType.includes(
                                            'Preorder'
                                        )}
                                        onChange={(e) => {
                                            handleOrderTypeChange(e);
                                        }}
                                        value="Preorder"
                                    />
                                }
                                label="Preorder"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                    {backorderIsLoading === false ? (
                        <>
                            <Button
                                color="primary"
                                disabled={importedSupplier.includes(
                                    props?.selectedSupplier
                                )}
                                onClick={() => handleImportBackorders()}
                            >
                                {props?.selectedSupplier
                                    ? `Import backorders for selected supplier`
                                    : `Import backorders`}
                            </Button>
                        </>
                    ) : (
                        <CircularProgress />
                    )}
                </Grid>
            </Grid>
            {props.tables.length > 0 || backorderIsLoading ? null : (
                <SpeedDial actionsList={actions} />
            )}
        </Paper>
    );
};

export default OrderLineHeader;
