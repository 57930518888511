// IMPORTS
import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import {
    Grid,
    TextField,
    MenuItem,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
import dayjs from 'dayjs';
// COMPONENTS
import Autocomplete from '../../../../global/Autocomplete';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
// LOGIC
import GetAllVehicleSpecifics from '../../../../global/databaseLogic/GetAllVehicleSpecifics';
import GetAllModels from '../../../../global/databaseLogic/GetAllModels';
import GetAllSeries from '../../../../global/databaseLogic/GetAllSeries';
import HandleUpdateCustomerVehicle from '../../logic/HandleUpdateCustomerVehicle';
import HandleAddCustomerVehicle from '../../logic/HandleAddCustomerVehicle';
import CheckCustomerVehicleVIN from '../../logic/CheckCustomerVehicleVIN';
// INTERFACES
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import {
    Customer,
    Specific
} from '../../../../global/interfaces/GeneralInterface';
import { CustomerVehicle } from '../../../../global/interfaces/ServiceInterface';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface CustomerVehicleDrawerProps {
    selectedCustomer: Customer;
    selectedCustomerVehicle: CustomerVehicle;
    setSelectedCustomerVehicle: Dispatch<SetStateAction<CustomerVehicle>>;
    setVehicleAdded: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

interface SpecificsList {
    makes?: Specific[];
    fuelTypes?: Specific[];
    bodyTypes?: Specific[];
}

const CustomerVehicleDrawer = ({
    selectedCustomer,
    selectedCustomerVehicle,
    setSelectedCustomerVehicle,
    setVehicleAdded,
    showSnackbar
}: CustomerVehicleDrawerProps) => {
    const [readOnly, setReadOnly] = useState(false);
    const [models, setModels] = useState<Specific[]>([]);
    const [series, setSeries] = useState<Specific[]>([]);
    const [specifics, setSpecifics] = useState<SpecificsList>({
        makes: [],
        fuelTypes: [],
        bodyTypes: []
    });
    const [existingVin, setExistingVin] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    useEffect(() => {
        if (selectedCustomerVehicle?.id) {
            setReadOnly(true);
        } else {
            setSelectedCustomerVehicle({
                ...selectedCustomerVehicle,
                CustomerId: selectedCustomer.id
            });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Get all the initial values
        GetAllVehicleSpecifics(setSpecifics, [
            'makes',
            'bodyTypes',
            'fuelTypes'
        ]);
        GetAllModels(setModels);
        GetAllSeries(setSeries);
    }, [
        selectedCustomerVehicle?.FuelTypeId,
        selectedCustomerVehicle?.BodyTypeId
    ]);

    // Update the models options when a make is selected
    useEffect(() => {
        GetAllModels(setModels, [], selectedCustomerVehicle?.MakeId);
    }, [selectedCustomerVehicle?.MakeId]);

    // Update the series options when a model is selected
    useEffect(() => {
        GetAllSeries(setSeries, [], selectedCustomerVehicle?.ModelId);
    }, [selectedCustomerVehicle?.ModelId]);

    /**
     * handleFabClick
     * Verify and handle the data
     * entered into the drawer
     */
    const handleFabClick = () => {
        if (readOnly) {
            setReadOnly(false);
        } else if (existingVin) {
            setOpenDialog(true);
        } else if (selectedCustomerVehicle?.id) {
            HandleUpdateCustomerVehicle(
                selectedCustomerVehicle,
                showSnackbar,
                setReadOnly
            );
        } else {
            HandleAddCustomerVehicle(
                selectedCustomerVehicle,
                showSnackbar,
                setReadOnly,
                setSelectedCustomerVehicle,
                setVehicleAdded
            );
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Autocomplete
                        isDisabled={readOnly}
                        options={specifics.makes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Make"
                        selectedValue={selectedCustomerVehicle?.Make}
                        handleSelectedValueChange={(event) => {
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                MakeId: event?.id,
                                Make: event
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        isDisabled={readOnly}
                        options={models}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Model"
                        selectedValue={selectedCustomerVehicle?.Model}
                        handleSelectedValueChange={(event) => {
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                ModelId: event?.id,
                                Model: event
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        isDisabled={readOnly}
                        options={series}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Series"
                        selectedValue={selectedCustomerVehicle?.Series}
                        handleSelectedValueChange={(event) =>
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                SeriesId: event?.id,
                                Series: event
                            })
                        }
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="en-gb"
                    >
                        <DatePicker
                            disabled={readOnly}
                            format="YYYY"
                            views={['year']}
                            label="Year Model"
                            value={
                                selectedCustomerVehicle?.year
                                    ? dayjs(selectedCustomerVehicle.year)
                                    : null
                            }
                            onChange={(newValue) => {
                                setSelectedCustomerVehicle({
                                    ...selectedCustomerVehicle,
                                    year: newValue
                                });
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    size: 'small',
                                    InputLabelProps: { shrink: true }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        label="Registration"
                        value={selectedCustomerVehicle?.registrationNumber}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                registrationNumber: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        label="Odometer"
                        value={selectedCustomerVehicle?.odometer}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                odometer: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        error={existingVin}
                        helperText={
                            existingVin
                                ? 'A Customer Vehicle with this VIN already exists, proceeding will change the Customer linked to this Vehicle.'
                                : ''
                        }
                        label="VIN"
                        value={selectedCustomerVehicle?.vin}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                vin: e.target.value.toUpperCase()
                            });
                            CheckCustomerVehicleVIN(
                                e.target.value,
                                setExistingVin
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        select
                        label="Transmission"
                        value={selectedCustomerVehicle?.transmission}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                transmission: e.target.value
                            })
                        }
                    >
                        <MenuItem value="Manual">Manual</MenuItem>
                        <MenuItem value="Automatic">Automatic</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        isDisabled={readOnly}
                        options={specifics.bodyTypes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Body Type"
                        selectedValue={
                            specifics.bodyTypes.find(
                                (body) =>
                                    body.id ===
                                    selectedCustomerVehicle?.BodyTypeId
                            ) || null
                        }
                        handleSelectedValueChange={(event) => {
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                BodyTypeId: event?.id
                                // BodyType: event
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        isDisabled={readOnly}
                        options={specifics.fuelTypes}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Fuel Type"
                        selectedValue={
                            specifics.fuelTypes.find(
                                (fule) =>
                                    fule.id ===
                                    selectedCustomerVehicle?.FuelTypeId
                            ) || null
                        }
                        handleSelectedValueChange={(event) => {
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                FuelTypeId: event?.id,
                                FuelType: event
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        label="Colour"
                        value={selectedCustomerVehicle?.colour}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                colour: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={readOnly}
                        fullWidth
                        label="Engine Number"
                        value={selectedCustomerVehicle?.engineNumber}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setSelectedCustomerVehicle({
                                ...selectedCustomerVehicle,
                                engineNumber: e.target.value
                            })
                        }
                    />
                </Grid>
            </Grid>

            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={(e) => handleFabClick()}
                >
                    {readOnly ? <Edit /> : <Save />}
                </Fab>
            </div>

            {/* Warning dialog for the user: if they try to add a customer vehicle but the vin already exists
            It will simply change the CustomerId instead of creating another record. This means that if a vehicle is transferred to a new customer it will transfer with its service history */}
            <Dialog open={openDialog}>
                <DialogTitle>VIN already exists</DialogTitle>
                <DialogContent>
                    A vehicle with this VIN already exists. Proceeding will
                    transfer the vehicle from his current Customer to the one
                    you have currently selected. If you wish to transfer the
                    vehicle, click Proceed and submit the form again, otherwise
                    click Cancel and change the VIN.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>CANCEL</Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpenDialog(false);
                            setExistingVin(false);
                        }}
                    >
                        PROCEED
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withSnackbar(CustomerVehicleDrawer);
