// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import {
    Grid,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../global/Paper';
// INTERFACES
import { User } from '../../../global/interfaces/GeneralInterface';
interface EmploymentSpecificsProps {
    userDetails: User;
    setUserDetails: Dispatch<SetStateAction<User>>;
    readOnly: boolean;
    selectedPaymentType: string;
    setSelectedPaymentType: Dispatch<SetStateAction<string>>;
}
const EmploymentSpecifics = ({
    userDetails,
    readOnly,
    setUserDetails
}: EmploymentSpecificsProps) => {
    const handleChangeSpecific = (
        object: string,
        key: string,
        newValue: string
    ) => {
        let currentObject = userDetails[object];

        currentObject[key] = newValue;

        setUserDetails({ ...userDetails, [object]: currentObject });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Paper>
                    <Typography variant="h6">Tax Details</Typography>
                    <Table>
                        <TableRow>
                            <TableCell>Tax File Number</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    data-cy={'viewUserTFNField'}
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={readOnly}
                                    value={userDetails?.taxFileNumber?.number}
                                    onChange={(e) =>
                                        handleChangeSpecific(
                                            'taxFileNumber',
                                            'number',
                                            e.target.value
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Date Provided</TableCell>
                            <TableCell data-cy={'viewUserTFNDateCell'}>
                                <LocalizationProvider
                                    data-cy="localProvider"
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    {readOnly &&
                                    userDetails.taxFileNumber.dateProvided &&
                                    !userDetails.taxFileNumber.dateProvided.isValid() ? (
                                        ''
                                    ) : readOnly &&
                                      userDetails.taxFileNumber.dateProvided ? (
                                        <Typography>
                                            {userDetails.taxFileNumber.dateProvided.format(
                                                'DD/MM/YYYY'
                                            )}
                                        </Typography>
                                    ) : (
                                        <DatePicker
                                            disabled={readOnly}
                                            data-cy={'viewUserTFNDateField'}
                                            format="DD/MM/YYYY"
                                            value={
                                                userDetails?.taxFileNumber
                                                    ?.dateProvided
                                            }
                                            onChange={(newValue) => {
                                                let newTaxFileObject = {
                                                    dateProvided: newValue,
                                                    number:
                                                        userDetails
                                                            .taxFileNumber
                                                            ?.number ?? ''
                                                };
                                                setUserDetails({
                                                    ...userDetails,
                                                    taxFileNumber:
                                                        newTaxFileObject
                                                });
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: false,
                                                    size: 'small',
                                                    InputLabelProps: {
                                                        shrink: true
                                                    }
                                                }
                                            }}
                                        />
                                    )}
                                </LocalizationProvider>
                            </TableCell>
                        </TableRow>
                    </Table>
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper>
                    <Typography variant="h6">Superannuation Details</Typography>
                    <Table>
                        <TableRow>
                            <TableCell>Company</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={readOnly}
                                    value={userDetails?.superannuation?.company}
                                    onChange={(e) =>
                                        handleChangeSpecific(
                                            'superannuation',
                                            'company',
                                            e.target.value
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Account Number</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={readOnly}
                                    value={
                                        userDetails?.superannuation
                                            ?.accountNumber
                                    }
                                    onChange={(e) =>
                                        handleChangeSpecific(
                                            'superannuation',
                                            'accountNumber',
                                            e.target.value
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    </Table>
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper>
                    <Typography variant="h6">Bank Details</Typography>
                    <Table>
                        <TableRow>
                            <TableCell>Bank</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    data-cy={'viewUserBankNameField'}
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={readOnly}
                                    value={userDetails?.bankAccount?.name}
                                    onChange={(e) =>
                                        handleChangeSpecific(
                                            'bankAccount',
                                            'name',
                                            e.target.value
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Account BSB</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    data-cy={'viewUserBSBField'}
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={readOnly}
                                    value={userDetails?.bankAccount?.bsb}
                                    onChange={(e) =>
                                        handleChangeSpecific(
                                            'bankAccount',
                                            'bsb',
                                            e.target.value
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Account Number</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    data-cy={'viewUserAccountNumberField'}
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={readOnly}
                                    value={userDetails?.bankAccount?.number}
                                    onChange={(e) =>
                                        handleChangeSpecific(
                                            'bankAccount',
                                            'number',
                                            e.target.value
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    </Table>
                </Paper>
            </Grid>
            <Grid item xs={3}>
                <Paper>
                    <Typography variant="h6">Emergency Contact</Typography>
                    <Table>
                        <TableRow>
                            <TableCell>Full Name</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    data-cy={'viewUserEmergencyFullNameField'}
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={readOnly}
                                    value={
                                        userDetails?.emergencyContact?.fullName
                                    }
                                    onChange={(e) =>
                                        handleChangeSpecific(
                                            'emergencyContact',
                                            'fullName',
                                            e.target.value
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Relation</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    data-cy={'viewUserEmergencyRelationField'}
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={readOnly}
                                    value={
                                        userDetails?.emergencyContact?.relation
                                    }
                                    onChange={(e) =>
                                        handleChangeSpecific(
                                            'emergencyContact',
                                            'relation',
                                            e.target.value
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>
                                <TextField
                                    fullWidth
                                    data-cy={
                                        'viewUserEmergencyPhoneNumberField'
                                    }
                                    variant="standard"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    disabled={readOnly}
                                    value={
                                        userDetails?.emergencyContact
                                            ?.phoneNumber
                                    }
                                    onChange={(e) =>
                                        handleChangeSpecific(
                                            'emergencyContact',
                                            'phoneNumber',
                                            e.target.value
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default EmploymentSpecifics;
