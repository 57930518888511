// IMPORTS
import { Dispatch, SetStateAction } from 'react';

// LOGIC
import api from '../../../../api';
import { Make } from '../interfaces/VehicleSaleInterface';

/**
 * GetMakes
 * Get all makes from the server and set it into the given React state
 * @author Pierre
 * @param setMakes the state you wish to save the makes array to
 * @returns all makes from the database as a JSON object
 */
const GetMakes = (setMakes: Dispatch<SetStateAction<Make[]>>) => {
  api.get('allMakes').then((res) => {
    setMakes(res.data);
  });
};

export default GetMakes;
