// IMPORTS
import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import {
    ButtonGroup,
    Button,
    Typography,
    Fab,
    Tooltip,
    Grid,
    IconButton
} from '@mui/material';
import { Save, Edit, PointOfSale } from '@mui/icons-material';
import { Dayjs } from 'dayjs';

// COMPONENTS
import CustomerTab from './NewEnquiryComponents/CustomerTab';
import VehicleDetails from './NewEnquiryComponents/VehicleDetails';
import FinanceDetails from './NewEnquiryComponents/FinanceDetails';
import TradeInDetails from './NewEnquiryComponents/TradeInDetails';
import TestRideForm from './NewEnquiryComponents/TestRideForm';
import Notes from './NewEnquiryComponents/Notes';
import DrawPad from './NewEnquiryComponents/DrawPad';
import CustomerDetails from './NewEnquiryComponents/CustomerDetails';

// LOGIC
import SubmitLead from '../logic/SubmitLead';
import GetLead from '../logic/GetLead';
import UpdateLead from '../logic/UpdateLead';

// INTERFACES
import { Customer, User } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Make, Model } from '../../../global/interfaces/VehicleSaleInterface';

interface NewEnquiryProps {
    selectedEnquiryId: number;
    status: string;
    setSelectedEnquiryId: Dispatch<
        SetStateAction<{ id: number; user: User | string; status: string }>
    >;
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>;
    disabled: boolean;
    setDisabled: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const NewEnquiry = (props: NewEnquiryProps) => {
    let sigCanvas = useRef({});

    const [pointsArray, setPointsArray] = useState(null);
    const [notes, setNotes] = useState<string>('');
    const [newNotes, setNewNotes] = useState<string>('');

    const [selectedItem, setSelectedItem] = useState<string>('Customer');
    const [customerDetails, setCustomerDetails] = useState<
        | Customer
        | {
              name: string;
              phoneNumber: string;
              email: string;
              suburb: string;
              occupation: string;
          }
    >({});

    const [bikeCondition, setBikeCondition] = useState<string>('new');
    const [selectedMake, setSelectedMake] = useState<Make>();
    const [selectedModel, setSelectedModel] = useState<Model>();
    const [weeklyBudget, setWeeklyBudget] = useState<number>(0);
    const [enquirySource, setEnquirySource] = useState<string>(null);
    const [pickupDate, setPickupDate] = useState<Dayjs>(null);
    const [reason, setReason] = useState<string>(null);

    const [customerErrors, setCustomerErrors] = useState<{
        nameError: boolean;
        contactDetailsError: boolean;
        enquirySourceError: boolean;
        makeError: boolean;
    }>({
        nameError: false,
        contactDetailsError: false,
        enquirySourceError: false,
        makeError: false
    });

    const [financeDetails, setFinanceDetails] = useState<{
        weeklyRepayment: number;
        amountOwing: number;
        remainingTerms: number;
    }>({
        weeklyRepayment: null,
        amountOwing: null,
        remainingTerms: null
    });

    const [tradeInMake, setTradeInMake] = useState<Make>();
    const [tradeInModel, setTradeInModel] = useState<Model>();
    const [tradeInDetails, setTradeInDetails] = useState<{
        year: Dayjs;
        registration: string;
        registrationExpiration: Dayjs;
        colour: string;
        odometer: number;
        hours: number;
        vin: string;
        engineNumber: string;
    }>({
        year: null,
        registration: null,
        registrationExpiration: null,
        colour: null,
        odometer: null,
        hours: null,
        vin: null,
        engineNumber: null
    });

    const [tradeInErrors, setTradeInErrors] = useState<{
        makeError: boolean;
        modelError: boolean;
        yearError: boolean;
        odometerError: boolean;
    }>({
        makeError: false,
        modelError: false,
        yearError: false,
        odometerError: false
    });

    useEffect(() => {
        if (props.selectedEnquiryId) {
            GetLead(
                props.selectedEnquiryId,
                props.setSelectedEnquiryId,
                setCustomerDetails,
                setBikeCondition,
                setSelectedMake,
                setSelectedModel,
                setWeeklyBudget,
                setEnquirySource,
                setPickupDate,
                setReason,
                setFinanceDetails,
                setTradeInMake,
                setTradeInModel,
                setTradeInDetails,
                setNotes,
                props.setDisabled
            );
        }
        // eslint-disable-next-line
    }, [props.selectedEnquiryId]);

    const HandleFabClick = () => {
        if (props.disabled) {
            props.setDisabled(false);
            props.setShouldBlockNavigation(true);
        } else {
            if (props.selectedEnquiryId) {
                UpdateLead(
                    customerDetails,
                    bikeCondition,
                    selectedMake,
                    selectedModel,
                    weeklyBudget,
                    enquirySource,
                    pickupDate,
                    reason,
                    setCustomerErrors,
                    financeDetails,
                    tradeInMake,
                    tradeInModel,
                    tradeInDetails,
                    setTradeInErrors,
                    notes,
                    newNotes,
                    props.showSnackbar,
                    props.selectedEnquiryId,
                    props.status
                );
            } else {
                SubmitLead(
                    customerDetails,
                    bikeCondition,
                    selectedMake,
                    selectedModel,
                    weeklyBudget,
                    enquirySource,
                    pickupDate,
                    reason,
                    setCustomerErrors,
                    financeDetails,
                    tradeInMake,
                    tradeInModel,
                    tradeInDetails,
                    setTradeInErrors,
                    notes,
                    newNotes,
                    props.showSnackbar,
                    null,
                    null
                );
            }
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Typography align="center">
                        <ButtonGroup variant="outlined" size="large">
                            <Button
                                variant={
                                    selectedItem === 'Customer'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                onClick={() => setSelectedItem('Customer')}
                            >
                                Customer
                            </Button>
                            <Button
                                variant={
                                    selectedItem === 'Vehicle'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                onClick={() => setSelectedItem('Vehicle')}
                            >
                                Vehicle
                            </Button>
                            <Button
                                variant={
                                    selectedItem === 'Finance'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                onClick={() => setSelectedItem('Finance')}
                            >
                                Finance
                            </Button>
                            <Button
                                variant={
                                    selectedItem === 'TradeIn'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                onClick={() => setSelectedItem('TradeIn')}
                            >
                                Trade-In
                            </Button>
                            {props.selectedEnquiryId == null ? (
                                <Tooltip title="Test Ride is only available for existing lead. Save your current lead to get access to this tab.">
                                    <span>
                                        <Button
                                            disabled
                                            variant={
                                                selectedItem === 'TestRide'
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            onClick={() =>
                                                setSelectedItem('TestRide')
                                            }
                                        >
                                            Test Ride
                                        </Button>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Button
                                    variant={
                                        selectedItem === 'TestRide'
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    onClick={() => setSelectedItem('TestRide')}
                                >
                                    Test Ride
                                </Button>
                            )}
                            <Button
                                variant={
                                    selectedItem === 'Notes'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                onClick={() => setSelectedItem('Notes')}
                            >
                                Notes
                            </Button>
                            <Button
                                variant={
                                    selectedItem === 'DrawPad'
                                        ? 'contained'
                                        : 'outlined'
                                }
                                onClick={() => setSelectedItem('DrawPad')}
                            >
                                Draw Pad
                            </Button>
                        </ButtonGroup>
                    </Typography>
                </Grid>
                <Grid item xs={1} textAlign="right">
                    <IconButton
                        onClick={() =>
                            (window.location.href = `/vehicles/newContract?leadId=${props.selectedEnquiryId}`)
                        }
                    >
                        <PointOfSale />
                    </IconButton>
                </Grid>
            </Grid>
            <br />
            {selectedItem === 'Customer' ? (
                customerDetails.hasOwnProperty('occupation') ? (
                    <CustomerDetails
                        disabled={props.disabled}
                        customerDetails={
                            customerDetails as {
                                name: string;
                                phoneNumber: string;
                                email: string;
                                suburb: string;
                                occupation: string;
                            }
                        }
                        setCustomerDetails={setCustomerDetails}
                        errors={customerErrors}
                    />
                ) : (
                    <CustomerTab
                        customerDetails={customerDetails}
                        setCustomerDetails={setCustomerDetails}
                        disabled={props.disabled}
                    />
                )
            ) : null}

            {selectedItem === 'Vehicle' ? (
                <VehicleDetails
                    bikeCondition={bikeCondition}
                    setBikeCondition={setBikeCondition}
                    selectedMake={selectedMake}
                    setSelectedMake={setSelectedMake}
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                    weeklyBudget={weeklyBudget}
                    setWeeklyBudget={setWeeklyBudget}
                    enquirySource={enquirySource}
                    setEnquirySource={setEnquirySource}
                    pickupDate={pickupDate}
                    setPickupDate={setPickupDate}
                    reason={reason}
                    setReason={setReason}
                    errors={customerErrors}
                    setErrors={setCustomerErrors}
                    disabled={props.disabled}
                />
            ) : null}

            {selectedItem === 'Finance' ? (
                <FinanceDetails
                    financeDetails={financeDetails}
                    setFinanceDetails={setFinanceDetails}
                    disabled={props.disabled}
                />
            ) : null}

            {selectedItem === 'TradeIn' ? (
                <TradeInDetails
                    tradeInMake={tradeInMake}
                    setTradeInMake={setTradeInMake}
                    tradeInModel={tradeInModel}
                    setTradeInModel={setTradeInModel}
                    tradeInDetails={tradeInDetails}
                    setTradeInDetails={setTradeInDetails}
                    tradeInErrors={tradeInErrors}
                    setTradeInErrors={setTradeInErrors}
                    disabled={props.disabled}
                />
            ) : null}

            {selectedItem === 'TestRide' ? (
                <TestRideForm
                    id={props.selectedEnquiryId}
                    customerDetails={customerDetails}
                    setCustomerDetails={setCustomerDetails}
                    selectedMake={selectedMake}
                    selectedModel={selectedModel}
                />
            ) : null}

            {selectedItem === 'Notes' ? (
                <Notes
                    newNotes={newNotes}
                    setNewNotes={setNewNotes}
                    disabled={props.disabled}
                    notes={notes}
                />
            ) : null}

            {selectedItem === 'DrawPad' ? (
                <DrawPad
                    sigCanvas={sigCanvas}
                    pointsArray={pointsArray}
                    setPointsArray={setPointsArray}
                />
            ) : null}

            {selectedItem === 'TestRide' ? null : (
                <Tooltip placement="left" title="Save Enquiry">
                    <div
                        style={{
                            margin: 0,
                            top: 'auto',
                            right: 20,
                            bottom: 10,
                            left: 'auto',
                            position: 'fixed',
                            zIndex: 1
                        }}
                    >
                        <Fab color="primary" onClick={(e) => HandleFabClick()}>
                            {props.disabled ? <Edit /> : <Save />}
                        </Fab>
                    </div>
                </Tooltip>
            )}
        </>
    );
};

export default NewEnquiry;
