// LOGIC
import api from '../../../../../api';
// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

/**
 * UpdatePaymentDueDate
 * Updates the due date of an invoice
 * @author Sienna
 * @param InvoiceId the invoice to be updated
 * @param newValue the new due date
 * @param showSnackbar state setter for a snackbar to show status
 */
const UpdatePaymentDueDate = (
    InvoiceId: number,
    newValue: string,
    showSnackbar: showSnackbar
) => {
    api.put(`updatePaymentDueDate/${InvoiceId}`, {
        paymentDueDate: newValue
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Payment Due Date Updated.');
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default UpdatePaymentDueDate;
