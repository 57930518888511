import api from '../../../../../api';

const getVendorStatementInfo = async (vehicleId, showSnackbar) => {
    const res = await api.get('/getVendorStatementInfo/' + vehicleId);
    if (res.status === 200) {
        return res.data;
    } else {
        showSnackbar(
            'Whoops! Something went wrong on our end.',
            'Please contact your IT department.',
            'error'
        );
    }
};

export default getVendorStatementInfo;
