import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { Till, User, UserSettings } from '../interfaces/GeneralInterface';

const GetUserSettings = (
  setUserSettings: Dispatch<SetStateAction<UserSettings>>,
  setUserDetails?: Dispatch<SetStateAction<User>>,
  setTills?: Dispatch<SetStateAction<Till[]>>
) => {
  api.get('getUserSettings').then((res) => {
    if (setUserDetails) {
      setUserDetails(res.data.currentUser);
    }
    if (setTills) {
      setTills(res.data.tills);
    }
    setUserSettings(res.data.userSettings);
  });
};

export default GetUserSettings;
