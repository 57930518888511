// IMPORTS
import { Grid } from '@mui/material';
// LOGIC
import GetUniqueStaffEmails from '../logic/GetUniqueStaffEmails';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const CompanyEmailDialogContent = () => {
    const upgradePlanned =
        'Hi all,\n\nThere are planned upgrades for Ora scehduled for tonight from 8pm - 9pm. Please ensure you are not active during this time as data may not be saved.\n\nThank-you,\nThe Ora Team';
    const upgradeSuccessful =
        'Hi all,\n\nUpgrades made to Ora overnight have been successful. Please refresh your browsers to ensure you are on the latest version.\n\nThank-you,\nThe Ora Team';
    const upgradeComplication =
        'Hi all,\n\nDuring the planned upgrades last night, Ora faced some complications. As a result, some function may not be working as expected. Rest assured, the team is doing all they can to resolve this problem as quick as possible.\n\nThank-you for you patience,\nThe Ora Team';

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <LoadingButton
                        variant="outlined"
                        fullWidth
                        onClick={() =>
                            GetUniqueStaffEmails(
                                `Ora Planned Upgrades - ${new Date(Date.now())}`,
                                upgradePlanned
                            )
                        }
                    >
                        Planned Outage
                    </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton
                        variant="outlined"
                        fullWidth
                        onClick={() =>
                            GetUniqueStaffEmails(
                                'Ora Upgrade Successful',
                                upgradeSuccessful
                            )
                        }
                    >
                        Upgrade Successful
                    </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton
                        variant="outlined"
                        fullWidth
                        onClick={() =>
                            GetUniqueStaffEmails(
                                'Ora Upgrade Complications',
                                upgradeComplication
                            )
                        }
                    >
                        Upgrade Unsuccessful
                    </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton
                        variant="outlined"
                        fullWidth
                        onClick={() => GetUniqueStaffEmails('', '')}
                    >
                        Custom
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
};

export default CompanyEmailDialogContent;
