// IMPORTS
import { List, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { SubMenuProps } from '../../global/interfaces/GlobalInterface';
import RoleCheck from '../../global/RoleCheck';

const ServiceSubMenu = ({ isSupportPage }: SubMenuProps) => {
    const currentMode = useTheme().palette.mode;
    return (
        <>
            <List
                sx={{
                    backgroundColor:
                        currentMode === 'dark' ? '#3b3b3b' : '#dddddd'
                }}
            >
                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/service/calendar'
                            : '/service/calendar'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Calendar
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/service/bookings'
                            : '/service/bookings'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Bookings
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/service/newBooking'
                            : '/service/newBooking'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        New Booking
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/service/purchaseOrders'
                            : '/service/purchaseOrders'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Purchase Orders
                    </ListItemText>
                </ListItemButton>

                <ListItemButton
                    component={Link}
                    to={
                        isSupportPage
                            ? '/support/service/payment'
                            : '/service/payment'
                    }
                    sx={{ fontSize: '13px' }}
                >
                    <ListItemText
                        inset
                        primaryTypographyProps={{ variant: 'caption' }}
                    >
                        Payment
                    </ListItemText>
                </ListItemButton>

                <RoleCheck
                    permission={'admin_manager,service_manager'}
                    component={
                        <ListItemButton
                            component={Link}
                            to={
                                isSupportPage
                                    ? '/support/service/dashboard'
                                    : '/service/dashboard'
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemText
                                inset
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Dashboard
                            </ListItemText>
                        </ListItemButton>
                    }
                    nullComponent={null}
                />
            </List>
        </>
    );
};

export default ServiceSubMenu;
