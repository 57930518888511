// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';

const CheckUsername = (
  userId: number,
  username: string,
  setResponseCode: Dispatch<SetStateAction<number>>
) => {
  api.put(`checkUsernameAvailable/${username}?UserId=${userId}`).then((res) => {
    setResponseCode(res.status);
  });
};

export default CheckUsername;
