import { addSpacingToText } from '../../../global/logic/Formatters';

const renderDealerLOAIndivPdf = async (pdfDocCopy, formData) => {
    const pages = pdfDocCopy.getPages();
    const firstPage = pages[0];

    // 1. Vehicle details
    if (formData.commonDueDate) {
        const day = formData.commonDueDate.format('DD');
        const month = formData.commonDueDate.format('MM');
        const year = formData.commonDueDate.format('YY');
        firstPage.drawText(day, {
            x: 440,
            y: 695,
            size: 9
        });
        firstPage.drawText(month, {
            x: 465,
            y: 695,
            size: 9
        });
        firstPage.drawText(year, {
            x: 490,
            y: 695,
            size: 9
        });
    }

    // 2. Registered operator/s details
    if (formData.driverLicenseExpiryDate) {
        const day = formData.driverLicenseExpiryDate.format('DD');
        const month = formData.driverLicenseExpiryDate.format('MM');
        const year = formData.driverLicenseExpiryDate.format('YY');
        firstPage.drawText(day, {
            x: 50,
            y: 393,
            size: 9
        });

        firstPage.drawText(month, {
            x: 77,
            y: 393,
            size: 9
        });

        firstPage.drawText(year, {
            x: 105,
            y: 393,
            size: 9
        });
    }

    if (formData.vehicleGarageAddress) {
        firstPage.drawText(formData.vehicleGarageAddress, {
            x: 50,
            y: 230,
            size: 9
        });
    }

    if (formData.vehicleGaragePostcode !== '') {
        firstPage.drawText(formData.vehicleGaragePostcode, {
            x: 520,
            y: 230,
            size: 9
        });
    }

    // Second Registered Operator (if applicable)
    if (formData.hasSecondOperator) {
        if (formData.roleTypeOption === 'operator') {
            firstPage.drawText('X', {
                x: 390,
                y: 427,
                size: 9
            });
        }

        if (formData.roleTypeOption === 'agent') {
            firstPage.drawText('X', {
                x: 475,
                y: 427,
                size: 9
            });
        }

        if (formData.operatorOption === 'individual') {
            firstPage.drawText('X', {
                x: 347,
                y: 394,
                size: 9
            });
        }

        if (formData.operatorOption === 'company') {
            firstPage.drawText('X', {
                x: 462,
                y: 394,
                size: 9
            });
        }

        if (formData.crn !== '') {
            const spacedCrn = addSpacingToText(formData.crn, 4);
            firstPage.drawText(spacedCrn, {
                x: 310,
                y: 360,
                size: 9
            });
        }

        if (formData.driverLicenseExpiry2) {
            const day = formData.driverLicenseExpiry2.format('DD');
            const month = formData.driverLicenseExpiry2.format('MM');
            const year = formData.driverLicenseExpiry2.format('YY');
            firstPage.drawText(day, {
                x: 310,
                y: 330,
                size: 9
            });

            firstPage.drawText(month, {
                x: 340,
                y: 330,
                size: 9
            });

            firstPage.drawText(year, {
                x: 370,
                y: 330,
                size: 9
            });
        }

        if (formData.name !== '') {
            firstPage.drawText(formData.name, {
                x: 310,
                y: 295,
                size: 9
            });
        }

        if (formData.registrationNumber !== '') {
            firstPage.drawText(formData.registrationNumber, {
                x: 310,
                y: 265,
                size: 9
            });
        }
    }

    // 4. Personalised/customised plates
    if (formData.attachPlateOption === 'yes') {
        firstPage.drawText('X', {
            x: 143,
            y: 80,
            size: 9
        });

        if (formData.plateNumbe !== '') {
            firstPage.drawText(formData.plateNumber, {
                x: 170,
                y: 65,
                size: 9
            });
        }
    }

    if (formData.attachPlateOption === 'no') {
        firstPage.drawText('X', {
            x: 62,
            y: 80,
            size: 9
        });
    }

    const secondPage = pages[1];
    // 5. Plate Type to be used for vehicle registration?
    if (formData.plateTypeOption === 'general') {
        secondPage.drawText('X', {
            x: 183,
            y: 777,
            size: 9
        });
    }

    if (formData.plateTypeOption === 'sequential') {
        secondPage.drawText('X', {
            x: 360,
            y: 777,
            size: 9
        });
    }

    // 6. Purpose of use
    if (formData.purposeOption === 'private') {
        secondPage.drawText('X', {
            x: 188,
            y: 745,
            size: 9
        });
    }

    if (formData.purposeOption === 'dealer') {
        secondPage.drawText('X', {
            x: 274,
            y: 745,
            size: 9
        });
    }

    if (formData.purposeOption === 'taxi') {
        secondPage.drawText('X', {
            x: 352,
            y: 745,
            size: 9
        });
    }

    if (formData.purposeOption === 'limousine') {
        secondPage.drawText('X', {
            x: 449,
            y: 745,
            size: 9
        });
    }

    if (formData.purposeOption === 'farming') {
        secondPage.drawText('X', {
            x: 552,
            y: 745,
            size: 9
        });
    }
    if (formData.purposeOption === 'schoolActivities') {
        secondPage.drawText('X', {
            x: 188,
            y: 726,
            size: 9
        });
    }

    if (formData.purposeOption === 'driverTraining') {
        secondPage.drawText('X', {
            x: 274,
            y: 726,
            size: 9
        });
    }

    if (formData.purposeOption === 'nonProfit') {
        secondPage.drawText('X', {
            x: 352,
            y: 726,
            size: 9
        });
    }

    if (formData.purposeOption === 'publicPassenger') {
        secondPage.drawText('X', {
            x: 449,
            y: 726,
            size: 9
        });
    }

    if (formData.purposeOption === 'bookedHire') {
        secondPage.drawText('X', {
            x: 552,
            y: 726,
            size: 9
        });
    }

    if (formData.purposeOption === 'towTruck') {
        secondPage.drawText('X', {
            x: 188,
            y: 697,
            size: 9
        });
    }

    if (formData.purposeOption === 'transLink') {
        secondPage.drawText('X', {
            x: 274,
            y: 697,
            size: 9
        });
    }

    if (formData.purposeOption === 'commercial') {
        secondPage.drawText('X', {
            x: 352,
            y: 697,
            size: 9
        });
    }

    if (formData.purposeOption === 'rental') {
        secondPage.drawText('X', {
            x: 449,
            y: 697,
            size: 9
        });
    }

    if (formData.purposeOption === 'bookedHireRental') {
        secondPage.drawText('X', {
            x: 552,
            y: 697,
            size: 9
        });
    }

    if (formData.purposeOption === 'specialPurposeLimousine') {
        secondPage.drawText('X', {
            x: 188,
            y: 672,
            size: 9
        });
    }

    if (formData.purposeOption === 'emergency') {
        secondPage.drawText('X', {
            x: 274,
            y: 672,
            size: 9
        });
    }

    // 7. Concession details
    if (formData.concessionOption === 'no') {
        secondPage.drawText('X', {
            x: 65,
            y: 625,
            size: 9
        });
    }

    if (formData.concessionOption === 'yes') {
        secondPage.drawText('X', {
            x: 102,
            y: 625,
            size: 9
        });
    }

    // 8. Duty
    if (formData.dutyExemptOption === 'no') {
        secondPage.drawText('X', {
            x: 62,
            y: 517,
            size: 9
        });
    }

    if (formData.dutyExemptOption === 'yes') {
        secondPage.drawText('X', {
            x: 102,
            y: 517,
            size: 9
        });

        if (formData.exemptionDesc) {
            secondPage.drawText(formData.exemptionDesc, {
                x: 125,
                y: 500,
                size: 9
            });
        }
    }

    // 9. Compulsory Third Party (CTP) insurance
    if (formData.insurerOption === 'Allianz') {
        secondPage.drawText('X', {
            x: 77,
            y: 395,
            size: 9
        });
    }

    if (formData.insurerOption === 'Suncorp') {
        secondPage.drawText('X', {
            x: 135,
            y: 395,
            size: 9
        });
    }

    if (formData.insurerOption === 'QBE') {
        secondPage.drawText('X', {
            x: 175,
            y: 395,
            size: 9
        });
    }

    if (formData.ITCEOption === 'no') {
        secondPage.drawText('X', {
            x: 65,
            y: 342,
            size: 9
        });
    }

    if (formData.ITCEOption === 'yes') {
        secondPage.drawText('X', {
            x: 102,
            y: 342,
            size: 9
        });
    }

    const pdfBytes = await pdfDocCopy.save();
    return pdfBytes;
};

export default renderDealerLOAIndivPdf;
