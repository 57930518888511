import DocumentationPage from '../../DocumentationPage';
import { PNLReportsSteps } from '../../data/admin/reporting/PNLReportsSteps';
import { GSTReportsSteps } from '../../data/admin/reporting/GSTReportsSteps';
import { StaffSalesReportsSteps } from '../../data/admin/reporting/staffSalesReportsSteps';
import { BalanceSheetSteps } from '../../data/admin/reporting/balanceSheetSteps';

const ReportingSupportPage = () => {
    const contentSections = [
        {
            id: 'pnl-reports',
            title: 'PNL Reports',
            href: '/support/admin/reporting/#pnl-reports',
            description:
                'To generate Profit & Loss (PnL) reports for one or more sites over a specified period and commit PnL data, follow these steps:',
            steps: PNLReportsSteps
        },
        {
            id: 'gst-reports',
            title: 'GST Reports',
            href: '/support/admin/reporting/#gst-reports',
            description:
                'To generate Goods and Services Tax (GST) reports for one or more sites over a specified period, follow these steps:',
            steps: GSTReportsSteps
        },
        {
            id: 'staff-sales-reports',
            title: 'Staff Sales Reports',
            href: '/support/admin/reporting/#staff-sales-reports',
            description:
                'To generate sales reports for staff members at one or more sites over a specified period, follow these steps:',
            steps: StaffSalesReportsSteps
        },
        {
            id: 'balance-sheet',
            title: 'Balance Sheet',
            href: '/support/admin/reporting/#balance-sheet',
            description:
                'To generate a balance sheet report for one or all sites over a specified period, follow these steps:',
            steps: BalanceSheetSteps
        }
    ];

    return (
        <DocumentationPage
            pageTitle="Ora Generated Reports"
            sections={contentSections}
        ></DocumentationPage>
    );
};

export default ReportingSupportPage;
