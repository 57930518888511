// IMPORTS
import { TableRow, Typography, Divider } from '@mui/material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../../global/tableComponents/DataCell';
// LOGIC
import {
    DateFormatter,
    CurrencyFormatter
} from '../../../../global/logic/Formatters';
// INTERFACES
import { Invoice } from '../../../../global/interfaces/PartsInterface';
import {
    VehicleInvoice,
    VehicleInvoiceLine
} from '../../../../global/interfaces/VehicleSaleInterface';

interface TempVehicleInvoices extends VehicleInvoiceLine {
    Invoice: Invoice;
    VehicleInvoice: VehicleInvoice;
}
interface InvoicesProps {
    vehicleId: number;
    invoices: Invoice[];
    vehicleInvoices: TempVehicleInvoices[];
}

const Invoices = ({ vehicleId, invoices, vehicleInvoices }: InvoicesProps) => {
    const columns = [
        { id: 0, label: 'ID' },
        { id: 1, label: 'Date' },
        { id: 2, label: 'Total (Ex. GST)' },
        { id: 3, label: 'Reference' },
        { id: 4, label: 'Creditor' },
        { id: 5, label: 'User' }
    ];

    const getInvoiceTotal = (stockInvoice) => {
        let sumOfAmounts;

        if (stockInvoice.orderLines?.length > 0) {
            sumOfAmounts = stockInvoice.orderLines
                .filter((line) => line.Vehicle && line.Vehicle.id === vehicleId)
                .reduce((sum, line) => sum + parseFloat(line.amount), 0);
            if (
                stockInvoice.gstType === 'No GST' ||
                stockInvoice.gstType === 'N/T'
            ) {
                return CurrencyFormatter(sumOfAmounts);
            } else {
                return CurrencyFormatter(sumOfAmounts / 1.1);
            }
        } else {
            sumOfAmounts = stockInvoice.documentTotal;
            return CurrencyFormatter(sumOfAmounts);
        }
    };
    return (
        <Paper>
            <Typography variant="h5" sx={{ fontSize: '25px' }}>
                Invoices
            </Typography>
            <br />
            <Divider />
            {(!invoices || invoices.length === 0) &&
            (!vehicleInvoices || vehicleInvoices.length === 0) ? (
                <Typography variant="body1">
                    <br />
                    No invoices have been allocated to this vehicle.
                </Typography>
            ) : (
                <DataTable columns={columns}>
                    {invoices.map((invoice) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/inventory/viewInvoice/${invoice.id}`
                                    )
                                }
                            >
                                {invoice.id}
                            </DataCellColoured>
                            <DataCell>
                                {DateFormatter(
                                    invoice?.StockInvoice?.invoiceDate
                                )}
                            </DataCell>
                            <DataCell>
                                {getInvoiceTotal(invoice?.StockInvoice)}
                            </DataCell>
                            <DataCell>
                                {invoice?.StockInvoice?.documentReference}
                            </DataCell>
                            <DataCell>
                                {invoice?.StockInvoice?.Supplier?.name}
                            </DataCell>
                            <DataCell>
                                {invoice?.StockInvoice?.User?.firstName}{' '}
                                {invoice?.StockInvoice?.User?.lastName}
                            </DataCell>
                        </TableRow>
                    ))}
                    {vehicleInvoices.map((vehicleInvoice) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/inventory/viewInvoice/${vehicleInvoice.Invoice.id}`
                                    )
                                }
                            >
                                {vehicleInvoice.Invoice.id}
                            </DataCellColoured>
                            <DataCell>
                                {DateFormatter(
                                    vehicleInvoice?.VehicleInvoice?.invoiceDate
                                )}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    vehicleInvoice?.VehicleInvoice
                                        ?.documentTotal
                                )}
                            </DataCell>
                            <DataCell>
                                {
                                    vehicleInvoice?.VehicleInvoice
                                        ?.documentReference
                                }
                            </DataCell>
                            <DataCell>
                                {vehicleInvoice?.VehicleInvoice?.Supplier?.name}
                            </DataCell>
                            <DataCell>
                                {
                                    vehicleInvoice?.VehicleInvoice?.User
                                        ?.firstName
                                }{' '}
                                {vehicleInvoice?.VehicleInvoice?.User?.lastName}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            )}
        </Paper>
    );
};

export default Invoices;
