// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { BinLocation } from '../../../global/interfaces/PartsInterface';

interface StocktakeDetails {
  countedParts: any[];
  uncountedParts: any[];
  notes: string;
  review: boolean;
}

/*
    When starting a new stocktake this gets all the parts in a bin location with a qtyOnHand that is not 0
    and all the proformas associated with them.
*/
const GetNewStocktakeDetails = (
  BinLocationId: number,
  setBinLocationDetails: Dispatch<SetStateAction<BinLocation>>,
  stocktakeDetails: StocktakeDetails,
  setStocktakeDetails: Dispatch<SetStateAction<StocktakeDetails>>
) => {
  api
    .get(
      `newStocktakeDetails/${BinLocationId}?SiteId=${localStorage.getItem('SiteId')}`
    )
    .then((res) => {
      setBinLocationDetails(res.data.BinLocation);
      setStocktakeDetails({
        ...stocktakeDetails,
        uncountedParts: res.data.Parts
      });
    });
};

export default GetNewStocktakeDetails;
