// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import Dayjs from 'dayjs';

// COMPONENTS
import Details from './Details';
import Contact from './Contact';
import Address from './Address';
import Payment from './Payment';
import SpeedDial from '../../../global/SpeedDial';
import Fab from '../../../global/Fab';
// LOGIC
import HandleAddCreditor from '../logic/HandleAddCreditor';
import HandleEditCreditor from '../logic/HandleEditCreditor';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { Edit, Print } from '@mui/icons-material';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import PDFCreditorStatement from '../logic/PDFCreditorStatement';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface CreditorDetailsProps {
    creditorDetails: Creditor;
    setCreditorDetails: Dispatch<SetStateAction<Creditor>>;
    showSnackbar: showSnackbar;
}

const CreditorDetails = ({
    creditorDetails,
    setCreditorDetails,
    showSnackbar
}: CreditorDetailsProps) => {
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [responseCode, setResponseCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [printAccountStatementOpen, setPrintAccountStatementOpen] =
        useState<boolean>(false);
    const [statementDates, setStatementDates] = useState({
        start: null,
        end: null
    });
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const actionsList = [
        {
            icon: <Edit />,
            name: 'Edit Creditor',
            permission: 'edit_creditor',
            onClick: () => setReadOnly(false)
        },
        {
            icon: <Print />,
            name: 'Print Account Statement',
            onClick: () => setPrintAccountStatementOpen(true)
        }
    ];

    // If there is no creditor id set read only to false as we are adding
    useEffect(() => {
        if (!creditorDetails?.id) {
            setReadOnly(false);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Details
                        creditorDetails={creditorDetails}
                        setCreditorDetails={setCreditorDetails}
                        readOnly={readOnly}
                        setResponseCode={setResponseCode}
                        showSnackbar={showSnackbar}
                    />
                    <br />
                    <Contact
                        creditorDetails={creditorDetails}
                        setCreditorDetails={setCreditorDetails}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Address
                        creditorDetails={creditorDetails}
                        setCreditorDetails={setCreditorDetails}
                        readOnly={readOnly}
                    />
                    <br />
                    <Payment
                        creditorDetails={creditorDetails}
                        setCreditorDetails={setCreditorDetails}
                        readOnly={readOnly}
                    />
                </Grid>
            </Grid>

            {readOnly ? (
                <SpeedDial actionsList={actionsList} />
            ) : (
                <Fab
                    loading={loading}
                    editIcon={false}
                    onClick={() => {
                        creditorDetails?.id
                            ? HandleEditCreditor(
                                  creditorDetails,
                                  responseCode,
                                  showSnackbar,
                                  setLoading,
                                  setReadOnly
                              )
                            : HandleAddCreditor(
                                  creditorDetails,
                                  responseCode,
                                  showSnackbar,
                                  setLoading,
                                  setReadOnly
                              );
                    }}
                />
            )}

            <Dialog
                open={printAccountStatementOpen}
                onClose={() => setPrintAccountStatementOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Print Account Statement</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    label="Start Date Range"
                                    value={Dayjs(statementDates.start)}
                                    onChange={(newValue) =>
                                        setStatementDates({
                                            ...statementDates,
                                            start: newValue
                                                ? Dayjs(newValue).format(
                                                      'DD/MM/YYYY'
                                                  )
                                                : null
                                        })
                                    }
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                            size: 'small',
                                            InputLabelProps: { shrink: true }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>{' '}
                        <Grid item xs={6}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"
                            >
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    label="End Date Range"
                                    value={Dayjs(statementDates.end)}
                                    onChange={(newValue) =>
                                        setStatementDates({
                                            ...statementDates,
                                            end: newValue
                                                ? Dayjs(newValue).format(
                                                      'DD/MM/YYYY'
                                                  )
                                                : null
                                        })
                                    }
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                            size: 'small',
                                            InputLabelProps: { shrink: true }
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Typography variant="caption">
                        <i>
                            If no dates are selected, the entire statement with
                            all transactions will be produced.
                        </i>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => setPrintAccountStatementOpen(false)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={buttonLoading}
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            PDFCreditorStatement(
                                creditorDetails,
                                statementDates.start,
                                statementDates.end,
                                setButtonLoading
                            )
                        }
                    >
                        Generate Statement
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreditorDetails;
