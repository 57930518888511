import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';
import { Model } from '../../../global/interfaces/VehicleSaleInterface';

const DeleteModel = (
    selectedModel: Model,
    showSnackbar: showSnackbar,
    setButtonLoading: Dispatch<SetStateAction<boolean>>
) => {
    setButtonLoading(true);
    api.delete('model/' + selectedModel.id).then((res) => {
        if (res.status === 200) {
            showSnackbar('Model successfully deleted.');
            window.location.reload();
        } else if (res.status === 400) {
            showSnackbar(
                'Unable to delete Model.',

                'There are stock vehicles registered with this model.',
                'error'
            );
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Something went wrong, please refresh the page and try again.',
                '',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default DeleteModel;
