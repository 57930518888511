import {
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';

const WrittenOffForm = ({ control }) => {
    return (
        <Grid container spacing={2} margin={'10px'}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom component="div">
                    Written Off Vehicle
                </Typography>
                <Typography variant="body1">
                    If the vehicle is a written off vehicle the motor dealer
                    must declare it here:
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="writtenOffOption"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value="isRepairableWriteOff"
                                control={<Radio />}
                                label="Repairable write-off"
                            />
                            <FormControlLabel
                                value="isStatutoryWriteOff"
                                control={<Radio />}
                                label="Statutory write-off (unable to be registered again)"
                            />
                        </RadioGroup>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
            </Grid>
        </Grid>
    );
};

export default WrittenOffForm;
