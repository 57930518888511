import { Step } from '../../interface';

export const UploadTransactionFileSteps: Step[] = [
    {
        label: 'In the sidebar, navigate to Admin -> Reconciliators -> Bank Reconciliation.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-01.png'
    },
    {
        label: "Under the Bank Transactions tab, click the 'Upload' button to select a CSV file from your device.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-02.png'
    },
    {
        label: 'Upon file upload, the system will be displayed a preview of the file in the File Editor.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-03.png'
    },
    {
        label: 'Set the column headers by selecting the appropriate values from the dropdown for Date, Reference, Debit Amount, and Credit Amount.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-04.png'
    },
    {
        label: "Ensure all columns of the upload file have an assigned header value or are removed if not applicable. Click the 'X' button to remove a column.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-05.png'
    },
    {
        label: "After setting new headers for each column, remove the existing column headers from the uploaded file by clicking the 'X' button.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-06.png'
    },
    {
        label: "After setting the column headers, click the 'Upload Transactions' button to upload the transactions file.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-07.png'
    },
    {
        label: 'Find the newly uploaded file under the Bank Transactions tab and click its ID to display all transactions within the file.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-08.png'
    },
    {
        label: "All newly uploaded transactions are marked as 'Unreconciled'. Delete a transaction by clicking the trash icon.",
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-09.png'
    },
    {
        label: 'Delete the entire file by clicking the trash icon beside the file name.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-10.png'
    },
    {
        label: 'Search for a specific transaction file by typing the file name in the search bar and pressing Enter.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-11.png'
    }
];

export const ReconciliatorSteps: Step[] = [
    {
        label: 'In the sidebar, click Admin -> Reconciliators -> Bank Reconciliation to navigate to the Bank Reconciliation page.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-01.png'
    },
    {
        label: 'Under Reconciliator tab, the page displays four tables: Reconciled Ora, Reconciled Bank, Unreconciled Ora, and Unreconciled Bank. Ora will try to find matches between the Bank side and the Ora side and put them in the Reconciled section.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-02.png'
    },
    {
        label: 'Click the "x" icon to scroll to the Unreconciled table, or click the "check" icon to scroll to the Reconciled table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-03.png'
    },
    {
        label: 'Filter transactions in the Unreconciled Ora or Bank tables by transaction type.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-04.png'
    },
    {
        label: 'To find a specific transaction in the Unreconciled Bank table, use Ctrl + F and search for the transaction reference.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-05.png'
    },
    {
        label: 'Click the info icon on the right side of a transaction line to view its details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-06.png'
    },
    {
        label: 'To select or deselect transactions for reconciliation, click the Edit icon to enter edit mode.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-07.png'
    },
    {
        label: 'In the Reconciled table, click the down arrow icon on the left side of the transaction line to move the transaction to the Unreconciled table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-08.png'
    },
    {
        label: 'In the Unreconciled table, click the up arrow icon on the left side of the transaction line to move it to the Reconciled Bank table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-09.png'
    },
    {
        label: 'Reconciliation can only be submitted when the Ora balance and bank balance are equal. If not, an error message "Both sides must balance to continue." will appear.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-10.png'
    },
    {
        label: 'To make the balances equal, find a matching transaction in the Ora Unreconciled table and move it to the Ora Reconciled table.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-11.png'
    },
    {
        label: 'To submit the reconciliation, click "Save" in the speed dial menu. ',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-12.png'
    },
    {
        label: 'Locate reconciliation history by selecting a date in the calendar.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-13.png'
    },
    {
        label: 'Find the newly submitted reconciliation #1767 and click on the ID to view details.',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-14.png'
    }
];

export const ReconciliatorV2Steps: Step[] = [
    {
        label: `In the sidebar, click Admin -> Reconciliators -> Bank Reconciliation to navigate to the Bank Reconciliation page`,
        imgPath: '1'
    },
    {
        label: 'Under Reconciliator V2 tab, the page displays four tables: Reconciled Ora, Reconciled Bank, Unreconciled Ora, and Unreconciled Bank. Transactions in the Reconciled tables are ready to be reconciled upon submission, while those in the Unreconciled tables are not yet ready to reconcile.',
        imgPath: '2'
    },
    {
        label: 'Click the "x" icon to scroll to the Unreconciled table, or click the "check" icon to scroll to the Reconciled table.',
        imgPath: '3'
    },
    {
        label: 'Filter transactions in the Unreconciled Ora or Bank tables by transaction type.',
        imgPath: '4'
    },
    {
        label: 'To find a specific transaction in the Unreconciled Bank table, locate the transaction date and reference',
        imgPath: '5'
    },
    {
        label: 'Click the info icon on the right side of a transaction line to view its details.',
        imgPath: '6'
    },
    {
        label: 'To select or deselect transactions for reconciliation, click the Edit icon to enter edit mode.',
        imgPath: '7'
    },
    {
        label: 'In the Reconciled table, click the down arrow icon on the left side of the transaction line to move the transaction to the Unreconciled table.',
        imgPath: '8'
    },
    {
        label: 'In the Unreconciled table, click the up arrow icon on the left side of the transaction line to move it to the Reconciled Bank table.',
        imgPath: '9'
    },
    {
        label: 'Reconciliation can only be submitted when the Ora balance and bank balance are equal. If not, an error message "Both sides must balance to continue." will appear.',
        imgPath: ''
    },
    {
        label: 'To make the balances equal, find a matching transaction in the Ora Unreconciled table and move it to the Ora Reconciled table.',
        imgPath: 'h'
    },
    {
        label: 'To submit the reconciliation, click "Save" in the speed dial menu. ',
        imgPath: ''
    },
    {
        label: 'Locate reconciliation history by selecting a date in the calendar.',
        imgPath: ''
    },
    {
        label: 'Find the newly submitted reconciliation #1767 and click on the ID to view details.',
        imgPath: ''
    }
];

export const ReconciliationHistoryteps: Step[] = [
    {
        label: `In the sidebar, click Admin -> Reconciliators -> Bank Reconciliation to navigate to the Bank Reconciliation page`,
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/bank-trans-01.png'
    },
    {
        label: 'Under the Reconciliation History tab, the page displays a list of reconciliations submitted each day. You can find all reconciliations submitted each day by selecting a day in calendar',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-13.png'
    },
    {
        label: 'Click on the ID to view the details of the reconciliation',
        imgPath:
            'https://ora-documentation.s3.ap-southeast-2.amazonaws.com/admin/reconciliators/bankReconciliation/reconciliator-14.png'
    }
];
