import { useEffect, useState } from 'react';
import GetLogs from '../../../global/logic/GetLogs';
import {
    Box,
    Chip,
    Collapse,
    Divider,
    Grid,
    IconButton,
    TableRow,
    Typography
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    GovernmentCharges,
    PartTotals,
    TotalPaid,
    TradeTotals,
    VehicleCharges,
    VoucherTotals
} from '../logic/TotalFunctions';
import { isNumber } from '../../../global/logic/globalValidation/NumericalValidation';

interface LogsDrawerContentProps {
    id: number;
}

const LogsDrawerContent = ({ id }: LogsDrawerContentProps) => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        GetLogs('vehicle', id, setLogs);
    }, [id]);

    const controlCollapse = (log, index, selector) => {
        let currentLogs = [...logs];
        currentLogs[index][selector] = !currentLogs[index][selector];
        setLogs(currentLogs);
    };

    const calculatePriceChange = (currentLog, previousLog, type) => {
        switch (type) {
            case 'totalPartPrice':
                return (
                    PartTotals(currentLog.linkedSales).totalPartPrice -
                    PartTotals(previousLog.linkedSales).totalPartPrice
                );

            case 'subtotalPartPrice':
                return (
                    PartTotals(currentLog.linkedSales).totalPartPrice +
                    VoucherTotals(currentLog.linkedVouchers) -
                    PartTotals(previousLog.linkedSales).totalPartPrice +
                    VoucherTotals(previousLog.linkedVouchers)
                );

            case 'VehicleCharges':
                return (
                    VehicleCharges(currentLog.VehicleSale) -
                    VehicleCharges(previousLog.VehicleSale)
                );

            case 'GovernmentCharges':
                return (
                    GovernmentCharges(currentLog.VehicleSale) -
                    GovernmentCharges(previousLog.VehicleSale)
                );

            case 'GST':
                const currentGST = currentLog.VehicleSale.excludeGST
                    ? 0
                    : (parseFloat(currentLog.VehicleSale.totalPurchasePrice) -
                          GovernmentCharges(currentLog.VehicleSale)) /
                      11;
                const preGST = previousLog.VehicleSale.excludeGST
                    ? 0
                    : (parseFloat(previousLog.VehicleSale.totalPurchasePrice) -
                          GovernmentCharges(previousLog.VehicleSale)) /
                      11;

                return currentGST - preGST;

            case 'TaxableAmount':
                const currentTaxableAmount = currentLog.VehicleSale.excludeGST
                    ? parseFloat(currentLog.VehicleSale.totalPurchasePrice)
                    : (parseFloat(currentLog.VehicleSale.totalPurchasePrice) -
                          GovernmentCharges(currentLog.VehicleSale)) /
                      1.1;
                const preTaxableAmount = previousLog.VehicleSale.excludeGST
                    ? parseFloat(previousLog.VehicleSale.totalPurchasePrice)
                    : (parseFloat(previousLog.VehicleSale.totalPurchasePrice) -
                          GovernmentCharges(previousLog.VehicleSale)) /
                      1.1;
                return currentTaxableAmount - preTaxableAmount;

            case 'Deposit':
                return (
                    currentLog.VehicleSale?.depositPrice -
                    previousLog.VehicleSale?.depositPrice
                );

            case 'TradeTotals':
                return (
                    TradeTotals(currentLog.linkedTrades, currentLog.gstType) -
                    TradeTotals(previousLog.linkedTrades, previousLog.gstType)
                );

            case 'AmountPayable':
                const currentAmountPayable =
                    parseFloat(currentLog.VehicleSale.totalPurchasePrice) -
                    parseFloat(currentLog.VehicleSale.depositPrice) -
                    TradeTotals(currentLog.linkedTrades, currentLog.gstType);
                const previousAmountPayable =
                    parseFloat(previousLog.VehicleSale.totalPurchasePrice) -
                    parseFloat(previousLog.VehicleSale.depositPrice) -
                    TradeTotals(previousLog.linkedTrades, previousLog.gstType);
                return currentAmountPayable - previousAmountPayable;
            case 'AmountPaid':
                return (
                    TotalPaid(currentLog.VehicleSale?.paymentLines) -
                    TotalPaid(previousLog.VehicleSale?.paymentLines)
                );

            case 'TotalPaid':
                return (
                    TotalPaid(currentLog.VehicleSale?.paymentLines) -
                    TotalPaid(previousLog.VehicleSale?.paymentLines)
                );

            case 'Balance':
                const currentBalance =
                    parseFloat(currentLog.VehicleSale.totalPurchasePrice) -
                    TradeTotals(currentLog.linkedTrades, currentLog.gstType) -
                    TotalPaid(currentLog.VehicleSale?.paymentLines);
                const previousBalance =
                    parseFloat(previousLog.VehicleSale.totalPurchasePrice) -
                    TradeTotals(previousLog.linkedTrades, currentLog.gstType) -
                    TotalPaid(previousLog.VehicleSale?.paymentLines);
                return currentBalance - previousBalance;

            default:
                return 0;
        }
    };

    const renderPriceChangeComponent = (currentLog, previousLog, type) => {
        const priceChange = calculatePriceChange(currentLog, previousLog, type);
        if (priceChange !== 0) {
            return (
                <Chip
                    icon={
                        priceChange > 0 ? (
                            <ArrowUpwardIcon style={{ fontSize: 10 }} />
                        ) : (
                            <ArrowDownwardIcon style={{ fontSize: 10 }} />
                        )
                    }
                    label={`${priceChange.toFixed(0)}`}
                    size="small"
                    color={priceChange > 0 ? 'success' : 'error'}
                    sx={{ height: '15px', marginLeft: '4px' }}
                />
            );
        } else {
            return null;
        }
    };

    const isInfoChanged = (
        currentLogVehicleSale,
        previousLogVehicleSale,
        fieldName
    ) => {
        return (
            currentLogVehicleSale[fieldName] !==
            previousLogVehicleSale[fieldName]
        );
    };

    const renderInfoTypography = (label, value, renderComponent) => (
        <Box mb={1}>
            <Typography
                variant="body1"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5em'
                }}
            >
                {label}: {renderComponent(value)}
            </Typography>
        </Box>
    );

    const renderChip = (
        value,
        chipColor: 'default' | 'primary' | 'error' | 'success' = 'default'
    ) => (
        <Chip
            label={isNumber(value) ? `#${value}` : value}
            size="small"
            color={chipColor}
        />
    );

    const renderInfoComponent = (
        currentLogVehicleSale,
        previousLogVehicleSale,
        fieldName
    ) => {
        const hasInfoChanged = isInfoChanged(
            currentLogVehicleSale,
            previousLogVehicleSale,
            fieldName
        );
        const currentValue = currentLogVehicleSale[fieldName];
        const previousValue = previousLogVehicleSale[fieldName];

        return hasInfoChanged ? (
            <span style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                {previousValue !== null && (
                    <>
                        {renderChip(previousValue)}
                        <ArrowForwardIcon fontSize="small" color="success" />
                    </>
                )}
                {currentValue !== null && renderChip(currentValue, 'success')}
            </span>
        ) : (
            <>{isNumber(currentValue) ? `#${currentValue}` : currentValue}</>
        );
    };

    return (
        <Grid container gap={3}>
            {logs.length > 0 ? (
                logs?.map((log, index) => (
                    <Grid item key={index} xs={12} md={12} lg={12}>
                        <Box mb={1}>
                            <Typography variant="h6">
                                <b>
                                    Updated on {DateFormatter(log.date)} by{' '}
                                    {log.UserName}
                                </b>
                            </Typography>
                        </Box>
                        {renderInfoTypography(
                            'Status',
                            log.VehicleSale?.saleStatus,
                            (value) =>
                                index < logs.length - 1
                                    ? renderInfoComponent(
                                          log.VehicleSale,
                                          logs[index + 1].VehicleSale,
                                          'saleStatus'
                                      )
                                    : value
                        )}

                        {renderInfoTypography(
                            'Customer',
                            log.VehicleSale?.CustomerId,
                            (value) =>
                                index < logs.length - 1
                                    ? renderInfoComponent(
                                          log.VehicleSale,
                                          logs[index + 1].VehicleSale,
                                          'CustomerId'
                                      )
                                    : value
                        )}

                        {renderInfoTypography(
                            'Vehicle',
                            log.VehicleSale?.VehicleId,
                            (value) =>
                                index < logs.length - 1
                                    ? renderInfoComponent(
                                          log.VehicleSale,
                                          logs[index + 1].VehicleSale,
                                          'VehicleId'
                                      )
                                    : value
                        )}

                        <Typography variant="subtitle1">
                            <b>Totals</b>
                            <IconButton
                                onClick={() =>
                                    controlCollapse(log, index, 'partsOpen')
                                }
                            >
                                {log.partsOpen ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </IconButton>
                        </Typography>
                        <Collapse
                            in={log.partsOpen}
                            timeout="auto"
                            unmountOnExit
                        >
                            <DataTable columns={[]}>
                                <TableRow>
                                    <DataCell align="left">
                                        <b>Add-Ons</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {' '}
                                        {CurrencyFormatter(
                                            PartTotals(log.linkedSales)
                                                .totalPartPrice
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'totalPartPrice'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b>Subtotal</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            PartTotals(log.linkedSales)
                                                .totalPartPrice +
                                                VoucherTotals(
                                                    log.linkedVouchers
                                                )
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'subtotalPartPrice'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b>Vehicle Charges</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            VehicleCharges(log.VehicleSale)
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'VehicleCharges'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b> Government Charges</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            GovernmentCharges(log.VehicleSale)
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'GovernmentCharges'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b> GST</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            log.VehicleSale.excludeGST
                                                ? 0
                                                : (parseFloat(
                                                      log.VehicleSale
                                                          .totalPurchasePrice
                                                  ) -
                                                      GovernmentCharges(
                                                          log.VehicleSale
                                                      )) /
                                                      11
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'GST'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b> Taxable Amount (EX. GST)</b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            log.VehicleSale.excludeGST
                                                ? parseFloat(
                                                      log.VehicleSale
                                                          .totalPurchasePrice
                                                  )
                                                : (parseFloat(
                                                      log.VehicleSale
                                                          .totalPurchasePrice
                                                  ) -
                                                      GovernmentCharges(
                                                          log.VehicleSale
                                                      )) /
                                                      1.1
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'TaxableAmount'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b> Deposit </b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            log.VehicleSale?.depositPrice
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'Deposit'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b> Trade Allowance </b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            TradeTotals(
                                                log.linkedTrades,
                                                log.gstType
                                            )
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'TradeTotals'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b> Amount Payable </b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            parseFloat(
                                                log.VehicleSale
                                                    .totalPurchasePrice
                                            ) -
                                                parseFloat(
                                                    log.VehicleSale.depositPrice
                                                ) -
                                                TradeTotals(
                                                    log.linkedTrades,
                                                    log.gstType
                                                )
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'AmountPayable'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b> Amount Paid </b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            TotalPaid(
                                                log.VehicleSale?.paymentLines
                                            )
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'AmountPaid'
                                            )}
                                    </DataCell>
                                </TableRow>
                                <TableRow>
                                    <DataCell align="left">
                                        <b> Balance </b>
                                    </DataCell>
                                    <DataCell align="left">
                                        {CurrencyFormatter(
                                            parseFloat(
                                                log.VehicleSale
                                                    .totalPurchasePrice
                                            ) -
                                                TradeTotals(
                                                    log.linkedTrades,
                                                    log.gstType
                                                ) -
                                                TotalPaid(
                                                    log.VehicleSale
                                                        ?.paymentLines
                                                )
                                        )}
                                        {index < logs.length - 1 &&
                                            renderPriceChangeComponent(
                                                log,
                                                logs[index + 1],
                                                'Balance'
                                            )}
                                    </DataCell>
                                </TableRow>
                            </DataTable>
                        </Collapse>
                        <Divider />
                    </Grid>
                ))
            ) : (
                <Typography variant="body1">No logs recorded.</Typography>
            )}
        </Grid>
    );
};

export default LogsDrawerContent;
