import api from '../../../../../../api';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const DeleteTransactionFile = (id: number, showSnackbar: showSnackbar) => {
    api.delete(`deleteTransactionFile/${id}`).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Transaction file deleted successfully.',
                'The page will now refresh.',
                'success'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default DeleteTransactionFile;
