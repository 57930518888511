// IMPORTS
import { Delete } from '@mui/icons-material';
import MuiFab from '@mui/material/Fab';

interface FabProps {
    onClick: any; // What to do when the fab is clicked, is dependent on the component
    right?: number; // Optional: change position of the FAB
    bottom?: number; // Optional: change position of the FAB
    cypressLabel?: string; // Optional: change the cypress label for testing
    disabled?: boolean;
}

const DeleteFab = ({
    onClick,
    right = 90,
    bottom = 20,
    cypressLabel,
    disabled = false
}: FabProps) => {
    return (
        <div
            style={{
                margin: 0,
                top: 'auto',
                right: right,
                bottom: bottom,
                left: 'auto',
                position: 'fixed',
                zIndex: 1
            }}
        >
            <MuiFab
                color="primary"
                data-cy={cypressLabel ? cypressLabel : ''}
                aria-label="add"
                variant={'circular'}
                onClick={(e) => onClick()}
                disabled={disabled}
            >
                <Delete />
            </MuiFab>
        </div>
    );
};

export default DeleteFab;
