import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const EmailCustomerInvoice = (
    newSelectedDebtor: any,
    totalDue: number,
    startDate: string,
    endDate: string,
    displayProforma: boolean,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    api.put(`generateDebtorInvoice?SiteId=${localStorage.getItem('SiteId')}`, {
        newSelectedDebtor: newSelectedDebtor,
        totalDue: totalDue,
        startDate: startDate,
        endDate: endDate,
        displayProforma: displayProforma
    }).then((res) => {
        if (res.status === 200) {
            showSnackbar('Account statement emailed to customer.');
            setButtonLoading(false);
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default EmailCustomerInvoice;
